<template>
  <div>
    <v-file-input :label="label" v-if="type == 'file'" @change="processFile">
    </v-file-input>
    <v-text-field
      :label="label"
      type="text"
      v-if="type == 'string'"
      :readonly="access == 1"
      :suffix="showSuffix(access)"
      v-bind:value="value"
      @input="$emit('input', $event)"
    />
    <v-text-field
      :label="label"
      type="number"
      :readonly="access == 1"
      :suffix="showSuffix(access)"
      v-if="type == 'number'"
      :min="min"
      :max="max"
      v-bind:value="value"
      @input="$emit('input', $event)"
    />
    <v-textarea
      :label="label"
      v-if="type == 'textarea'"
      :suffix="showSuffix(access)"
      :readonly="access == 1"
      v-bind:value="value"
      @input="$emit('input', $event)"
    />

    <v-select
      :label="label"
      v-if="type == 'boolean'"
      v-bind:value="value"
      @change="$emit('input', $event)"
      :items="boolArray"
    >
    </v-select>

    <v-select
      :label="label"
      v-if="type == 'enum'"
      v-bind:value="value"
      @change="$emit('input', $event)"
      :items="items"
    >
    </v-select>

    <v-data-table v-if="type == 'keyvalue'" :headers="headers" :items="table_items">
      <template v-slot:top>
        <v-toolbar flat dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text">Headers</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="primary"
            mdi_icona="mdi-plus"
            testo_tooltip="Aggiornare"
            v-on:buttonClick="
              headerdialog = true;
              createHeaderForm();
            "
          />
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.key`]="{ item }">
        <v-text-field v-model="item.key" label="Key" placeholder="Key"></v-text-field>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <v-text-field
          v-model="item.value"
          label="Value"
          placeholder="value"
        ></v-text-field>
      </template>

      <template v-slot:[`item.delete`]="{ item }">
        <Button
          colore_icona="error"
          mdi_icona="mdi-window-close"
          testo_tooltip="Cancellare"
          v-on:buttonClick="deleteHeader(item)"
        />
      </template>
    </v-data-table>

    <!-- <v-file-input
    :label="label"
   ></v-file-input> -->
  </div>
</template>

<script>
import Button from "../../TOOLS/FormComponentPermissions/Button.vue";

export default {
  props: ["value", "type", "label", "options", "items", "max", "min", "access"],
  components: {
    Button,
  },
  data() {
    return {
      headers: [
        {
          text: "Key",
          value: "key",
          sortable: false,
        },
        {
          text: "Value",
          value: "value",
          sortable: false,
        },
        {
          value: "delete",
          sortable: false,
        },
      ],
      boolArray: [
        {
          text: "False",
          value: false,
        },
        {
          text: "True",
          value: true,
        },
      ],
      table_items: [],
    };
  },
  methods: {
    showSuffix(access) {
      switch (access) {
        case 1:
          return "readonly";
        case 2:
          return "mandatory";
      }
    },
    processFile(event) {
      const that = this;
      const reader = new FileReader();
      reader.onload = function () {
        that.$emit("input", reader.result);
        console.log(`reader.result`, reader.result);
      };
      reader.readAsDataURL(event);
    },
    createHeaderForm() {
      this.table_items.push({ value: null, key: null });
    },
    deleteHeader(item) {
      let i = this.table_items.findIndex((it) => it == item);
      this.table_items.splice(i, 1);
    },
  },
  mounted() {
    this.table_items = this.value ? this.value : [];
  },
};
</script>
