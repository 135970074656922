<template>
    <svg @click="$emit('click',$event)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" class="graph_icon">
        <path :d="icons[icon]" />
    </svg>
</template>
<script>
//  Source : https://icons8.it/icons/fluency-systems-regular
export default {
    name : 'GraphIcon',
    props: { icon : String },
    data(){
        return{
            icons : {
                apps : 'M4 3h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v4h4V5H5zm-1 8h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1zm1 2v4h4v-4H5zm9-12h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v4h4V5h-4zm4 10h-5a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v1h4v-4z',
                back : 'M6.414 13l5.293 5.293a1 1 0 0 1-1.414 1.414l-7-7C2.663 12.077 3.109 11 4 11h16a1 1 0 0 1 0 2H6.414zm3.922-8.747a1 1 0 1 1 1.328 1.494l-4.5 4a1 1 0 0 1-1.328-1.494l4.5-4z',
                bookmark : 'M6 18.826l5.35-4.585a1 1 0 0 1 1.357.052l3 3a1 1 0 0 1-1.414 1.414l-2.345-2.345-6.297 5.397A1 1 0 0 1 4 21V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-2 0V4H6v14.826z',
                check : 'M5 10.414l-1.293 1.293a1 1 0 1 1-1.414-1.414l2-2a1 1 0 0 1 1.414 0l3.433 3.433 10.323-6.57a1 1 0 0 1 1.244.137l1 1a1 1 0 0 1-.031 1.444l-12 11a1 1 0 0 1-1.435-.086l-3.316-3.868a1 1 0 0 1 1.519-1.302l2.642 3.083 8.684-7.96-8.233 5.24a1 1 0 0 1-1.244-.137L5 10.414z',
                close : 'M12 10.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 1 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586zm1.793 4.621a1 1 0 0 1 1.414-1.414l3.5 3.5a1 1 0 0 1-1.414 1.414l-3.5-3.5z',
                dashboard : 'M5 20a1 1 0 0 1-2 0v-6a1 1 0 0 1 2 0v6zm5 0a1 1 0 0 1-2 0v-9a1 1 0 0 1 2 0v9zm5 0a1 1 0 0 1-2 0v-8a1 1 0 0 1 2 0v8zm5 0a1 1 0 0 1-2 0V9a1 1 0 0 1 2 0v11zM4.625 10.78a1 1 0 1 1-1.25-1.56l5-4a1 1 0 0 1 .996-.148l4.512 1.804 5.562-3.708a1 1 0 0 1 1.11 1.664l-6 4a1 1 0 0 1-.926.096l-4.46-1.783-4.544 3.636z',
                delete : 'M17.004 9.91a1 1 0 1 1 1.992.18l-1 11A1 1 0 0 1 17 22H7a1 1 0 0 1-.996-.91l-1-11A1 1 0 0 1 6 9h9a1 1 0 0 1 0 2H7.095l.818 9h8.174l.917-10.09zM6 6v1a1 1 0 1 1-2 0V5a1 1 0 0 1 1-1h3.382l.724-1.447A1 1 0 0 1 10 2h4a1 1 0 0 1 .894.553L15.618 4H19a1 1 0 0 1 1 1v2a1 1 0 0 1-2 0V6h-3a1 1 0 0 1-.894-.553L13.382 4h-2.764l-.724 1.447A1 1 0 0 1 9 6H6z',
                edit : 'M14.793 11.793a1 1 0 0 1 1.414 1.414l-7.5 7.5A1 1 0 0 1 8 21H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 .293-.707l13-13a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L18 8.586 19.586 7 17 4.414l-12 12V19h2.586l7.207-7.207z',
                folder : 'M19 10H8a1 1 0 1 1 0-2h12a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h8a1 1 0 0 1 .707.293l2 2a1 1 0 1 1-1.414 1.414L11.586 5H5v14h14v-9z',
                gear : 'M13.325 15.775a4 4 0 1 1 1.662-1.114l1.314 1.843a5.267 5.267 0 0 1 2.289.236l1.058-1.765c-2.153-1.683-2.19-4.248 0-5.95l-1.05-1.75C15.9 8.248 13.522 6.864 13.076 4h-2.15C10.48 6.833 8.127 8.263 5.4 7.276l-1.05 1.75c2.154 1.682 2.19 4.247.001 5.95l1.05 1.75c2.699-.974 5.077.41 5.523 3.274H14a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1c0-2.344-1.5-3.217-3.524-2.12a1 1 0 0 1-1.334-.366l-2-3.335a1 1 0 0 1 .382-1.393c1.956-1.06 1.927-2.528 0-3.572a1 1 0 0 1-.382-1.393l2-3.335a1 1 0 0 1 1.334-.365C7.524 6.23 9 5.318 9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1c0 2.344 1.5 3.217 3.524 2.12a1 1 0 0 1 1.334.366l2 3.335a1 1 0 0 1-.382 1.393c-1.956 1.06-1.927 2.528 0 3.572a1 1 0 0 1 .382 1.393l-2 3.335a1 1 0 0 1-1.334.365c-.734-.397-1.664-.525-2.356-.307a1 1 0 0 1-1.115-.373l-1.728-2.424zM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
                home : 'M11 17.874A4.002 4.002 0 0 1 12 10a4 4 0 0 1 1 7.874V20h5v-8a1 1 0 0 1 1-1h.394L12 4.345 4.606 11H5a1 1 0 0 1 1 1v8h2a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1v-8H2c-.916 0-1.35-1.13-.669-1.743l10-9a1 1 0 0 1 1.338 0l10 9C23.35 11.87 22.916 13 22 13h-2v8a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-3.126zM12 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
                info : 'M12 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm8 2a8 8 0 1 0-16 0c0 3.092 1.708 5.77 4.438 7.101a1 1 0 0 1-.876 1.798C4.15 19.236 2 15.864 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a1 1 0 0 1-1-1v-7.001a1 1 0 1 1 2 0v5.94A8.001 8.001 0 0 0 20 12z',
                lock : 'M11 13.17V11a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h2V7a5 5 0 1 1 10 0 1 1 0 0 1-2 0 3 3 0 0 0-6 0v4a1 1 0 0 1-1 1H6v8h12v-8h-5v1.17a3.001 3.001 0 1 1-2 0zM12 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2z',
                move : 'M7.83 5H17a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H8v4h8a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h9V7H7.83a3.001 3.001 0 1 1 0-2zM5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm13.293 9.707a1 1 0 0 1 1.414-1.414l2 2a1 1 0 0 1 0 1.414l-2 2a1 1 0 0 1-1.414-1.414L19.586 18l-1.293-1.293z',
                picture : 'M9.662 17.75a1 1 0 0 1-1.324-1.5l8.355-7.378a1 1 0 0 1 1.33.006l2.646 2.378A1 1 0 0 1 21 12v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V5H5v14h14v-6.554l-1.651-1.485-7.687 6.789zM9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z',
                play : 'M7 5.79V16a1 1 0 0 1-2 0V4a1 1 0 0 1 1.524-.852l13 8a1 1 0 0 1 0 1.704l-13 8a1 1 0 0 1-1.048-1.704L17.092 12 7 5.79z',
                plus : 'M11 11V8a1 1 0 0 1 2 0v8a1 1 0 0 1-2 0v-3H8a1 1 0 0 1 0-2h3zm1-7a1 1 0 0 1 0-2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12a9.997 9.997 0 0 1 5.26-8.807 1 1 0 0 1 .95 1.76A8 8 0 1 0 12 4zm3 9a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2h-1z',
                print : 'M6 11V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7h2a1 1 0 0 1 1 1v8a1 1 0 0 1-2 0v-7H5v7a1 1 0 0 1-2 0v-8a1 1 0 0 1 1-1h2zm1 10a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2H7zm9-4a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2h-1zM8 5v6h8V5H8z',
                refresh : 'M5 19.551V21a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H6.262c1.53 1.236 3.641 2 5.738 2a8 8 0 0 0 8-8 1 1 0 0 1 2 0c0 5.523-4.477 10-10 10-2.549 0-5.107-.916-7-2.449zM17.738 6C16.208 4.764 14.097 4 12 4a8 8 0 0 0-8 8 1 1 0 0 1-2 0C2 6.477 6.477 2 12 2c2.549 0 5.107.916 7 2.449V3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1h-4a1 1 0 0 1 0-2h1.738z',
                remove : 'M12 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-6 6a1 1 0 1 1-1.414-1.414L10.586 12 8.293 9.707a1 1 0 0 1 1.414-1.414L12 10.586zM12 4a1 1 0 0 1 0-2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12a9.997 9.997 0 0 1 5.26-8.807 1 1 0 0 1 .95 1.76A8 8 0 1 0 12 4zm1.293 10.707a1 1 0 0 1 1.414-1.414l1 1a1 1 0 0 1-1.414 1.414l-1-1z',
                restore : 'M17.738 6C16.208 4.764 14.097 4 12 4a8 8 0 1 0 8 8 1 1 0 0 1 2 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2c2.549 0 5.107.916 7 2.449V3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1h-4a1 1 0 0 1 0-2h1.738z',
                search : 'M16.464 15.05l4.243 4.243a1 1 0 0 1-1.414 1.414l-4.904-4.904a1 1 0 0 1 0-1.414 5.5 5.5 0 1 0-2.404 1.407 1 1 0 1 1 .54 1.926 7.5 7.5 0 1 1 3.94-2.672z',
                swap : 'M20 16.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L18 16.586V6h-8a1 1 0 1 1 0-2h9a1 1 0 0 1 1 1v11.586zM4 7.414L2.707 8.707a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L6 7.414V18h8a1 1 0 0 1 0 2H5a1 1 0 0 1-1-1V7.414z',
                upload : 'M19 19v-2a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-3a1 1 0 0 1 2 0v2h14zM11 6.414l-4.293 4.293a1 1 0 1 1-1.414-1.414l6-6C11.923 2.663 13 3.109 13 4v12a1 1 0 0 1-2 0V6.414zm3.732 1.226a1 1 0 1 1 1.536-1.28l2.5 3a1 1 0 1 1-1.536 1.28l-2.5-3z',
            }
        }
    }
}
</script>
<style scoped>
    .graph_icon{
        color: inherit;
        position: relative;
    }
    .graph_icon > path{
        fill : currentColor;
    }
</style>