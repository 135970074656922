<template>
  <div style="width: 100%" class="pt-5">
    <!-- Div contenente lo sfondo (fisso) di questa videata -->
    <div class="sfondo" style="position: fixed; left: 0px; top: 0px"></div>
    <v-row v-if="menu_visible">
      <v-col cols="0" md="6" class="py-0"></v-col>
      <v-col class="pr-md-10 px-10 py-0" cols="12" md="6">
        <!-- Menu di selezione -->
        <Menu
          style="display: inline-block; width: 100%"
          @click_causali_fermo="
            (causali_fermo_visible = true), (menu_visible = false)
          "
          @click_causali_scarto="
            (causali_scarto_visible = true), (menu_visible = false)
          "
          @click_fase_standard="
            (fase_standard_visible = true), (menu_visible = false)
          "
          @click_ciclo_standard="
            (ciclo_standard_visible = true), (menu_visible = false)
          "
          @click_ricerca_magazzino="
            (ricerca_magazzino = true), (menu_visible = false)
          "
          @click_avanzamento_conferma="
            (avanzamento_conferma_visible = true), (menu_visible = false)
          "
          @click_part_program="
            (part_program_visible = true), (menu_visible = false)
          "
          @click_macchina_protocol="
            (macchina_protocol_visible = true), (menu_visible = false)
          "
          @click_macchina_part_program="
            (macchina_part_program = true), (menu_visible = false)
          "
          @click_gestione_reparto="
            (gestione_reparto_visible = true), (menu_visible = false)
          "
          v-if="menu_visible"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="causali_fermo_visible">
      <v-col cols="10" class="py-0">
        <TabellaCausaliFermo
          @click_chiudi="(causali_fermo_visible = false), (menu_visible = true)"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="causali_scarto_visible">
      <v-col cols="10" class="py-0">
        <TabellaCausaliScarto
          @click_chiudi="
            (causali_scarto_visible = false), (menu_visible = true)
          "
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="fase_standard_visible">
      <v-col cols="10" class="py-0">
        <TabellaFaseStandard
          @click_chiudi="(fase_standard_visible = false), (menu_visible = true)"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="ciclo_standard_visible">
      <v-col cols="10" class="py-0">
        <TabellaCicloStandard
          @click_chiudi="
            (ciclo_standard_visible = false), (menu_visible = true)
          "
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="ricerca_magazzino">
      <v-col cols="10" class="py-0">
        <RicercaMovimenti
          @click_chiudi="(ricerca_magazzino = false), (menu_visible = true)"
        />
      </v-col>
    </v-row>

    <v-row justify="center" v-if="macchina_protocol_visible">
      <v-col cols="10" class="py-0">
        <AanagraficaQuickConnextBox
          @click_chiudi="
            (macchina_protocol_visible = false), (menu_visible = true)
          "
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="macchina_part_program">
      <v-col cols="10" class="py-0">
        <PartProgramIntializer
          @click_chiudi="(macchina_part_program = false), (menu_visible = true)"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="gestione_reparto_visible">
      <v-col cols="10" class="py-0">
        <GestioneReparto
          @click_chiudi="
            (gestione_reparto_visible = false), (menu_visible = true)
          "
        />
      </v-col>
    </v-row>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Menu from "@/components/PRODUCTION/Impostazioni/Menu.vue";
import TabellaCausaliFermo from "@/components/PRODUCTION/Impostazioni/TabellaCausaliFermo.vue";
import TabellaCausaliScarto from "@/components/PRODUCTION/Impostazioni/TabellaCausaliScarto.vue";
import TabellaFaseStandard from "../../../components/PRODUCTION/Impostazioni/TabellaFaseStandardNew";
import TabellaCicloStandard from "../../../components/PRODUCTION/Impostazioni/TabellaCicloStandard.vue";
import RicercaMovimenti from "../../../components/PRODUCTION/Impostazioni/RicercaMovimenti.vue";
import AanagraficaQuickConnextBox from "@/components/PRODUCTION/Impostazioni/AanagraficaQuickConnextBox.vue";
import PartProgramIntializer from "@/components/PRODUCTION/Impostazioni/PartProgramIntializer.vue";
import GestioneReparto from "@/components/PRODUCTION/Impostazioni/GestioneReparto.vue";

export default {
  name: "Avanzamento",
  components: {
    AanagraficaQuickConnextBox,
	GestioneReparto,
    Menu,
	PartProgramIntializer,
    RicercaMovimenti,
	TabellaCausaliFermo,
    TabellaCausaliScarto,
    TabellaCicloStandard,
    TabellaFaseStandard,
  },
  data() {
    return {
      // Variabili d'appoggio

      // Modal
      avanzamento_conferma_visible: false,
      caricamento_dati: false,
      causali_fermo_visible: false,
      causali_scarto_visible: false,
      fase_standard_visible: false,
      ciclo_standard_visible: false,
      gestione_reparto_visible: false,
      macchina_part_program: false,
      macchina_protocol_visible: false,
      menu_visible: true,
      part_program_visible: false,
      ricerca_magazzino: false,
      // Array
    };
  },
  methods: {},
  mounted() {
    if (this.$route.params.comp == 4) {
      this.menu_visible = false;
      this.ciclo_standard_visible = true;
    }
  },
};
</script>
<style scoped>
.sfondo {
  width: 100vw !important;
  background-image: url("../../../assets/Ind.jpg");

  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100vh !important; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden !important; /* Resize the background image to cover the entire container */
}
</style>
