<template>
  <div style="width: 100%">
    <v-row> <Benestare /></v-row>
  </div>
</template>
<script>
// import TestaComune from "../../../components/BENESTARE/TestaComune.vue";
import Benestare from "../../../components/Qualita/Benestare/Benestare";
export default {
  components: {
    Benestare,
  },
  data() {
    return {};
  },
};
</script>
