var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.infoFile != null)?_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',[_vm._v("File: "+_vm._s(_vm.infoFile.nome_file))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.chiudiVisualizzaPdf()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,false,3018053152)},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12"}},[(_vm.infoFile.ordini_categoria != undefined)?_c('v-tabs',{attrs:{"vertical":""}},[_vm._l((_vm.infoFile.ordini_categoria
                .map(function (item) { return item.nome_categoria; })
                .filter(
                  function (value, index, categoryArray) { return categoryArray.indexOf(value) === index; }
                )),function(oggetto,i){return _c('v-tab',{key:i,staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(oggetto.replaceAll("_", " "))+" ")])}),_vm._l((_vm.infoFile.ordini_categoria
                .map(function (item) { return item.nome_categoria; })
                .filter(
                  function (value, index, categoryArray) { return categoryArray.indexOf(value) === index; }
                )),function(oggetto,i){return _c('v-tab-item',{key:i},[_c('v-card',{attrs:{"flat":""}},[(oggetto == 'DECORI')?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                      {
                        text: 'Elemento',
                        value: 'elemento',
                      } ],"items":_vm.infoFile.ordini_categoria.filter(
                        function (el) { return el.nome_categoria == oggetto &&
                          el.id_categoria_padre == null; }
                      ),"hide-default-footer":"","hide-default-header":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"elemento","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":[
                            { text: 'Codice', value: 'codice' },
                            {
                              text: 'Elemento',
                              value: 'elemento',
                            },
                            { text: 'Descrizione', value: 'des' },
                            { text: 'Informazioni', value: 'info' },
                            { text: 'Quantita', value: 'quantita' } ],"items":_vm.infoFile.ordini_categoria.filter(
                              function (el) { return el.nome_categoria == oggetto &&
                                el.id_categoria_padre ==
                                  item.id_ordine_categoria; }
                            )}})],1)]}}],null,true)})],1):(oggetto == 'APPROVAZIONI')?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                      { text: 'Elemento', value: 'elemento' },
                      {
                        text: 'Data',
                        value: 'data',
                      },
                      { text: 'Firma', value: 'firma' } ],"items":_vm.infoFile.ordini_categoria.filter(
                        function (el) { return el.nome_categoria == oggetto; }
                      )}})],1):(oggetto == 'ACCESSORI_NON_A_LISTINO_E_MODIFICHE')?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                      { text: 'Codice', value: 'codice' },
                      {
                        text: 'Elemento',
                        value: 'elemento',
                      },
                      { text: 'Informazioni', value: 'info' } ],"items":_vm.infoFile.ordini_categoria.filter(
                        function (el) { return el.nome_categoria == oggetto; }
                      )}})],1):_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                      { text: 'Codice', value: 'codice' },
                      {
                        text: 'Elemento',
                        value: 'elemento',
                      },
                      { text: 'Informazioni', value: 'info' },
                      { text: 'Quantita', value: 'quantita' } ],"items":_vm.infoFile.ordini_categoria.filter(
                        function (el) { return el.nome_categoria == oggetto; }
                      )}})],1)],1)],1)})],2):_vm._e()],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }