<template>
  <div>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="height: 80vh !important; width: 90vw !important"
      >
      </iframe>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogEliminaElemento"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">Eliminazione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di voler eliminare questo elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="green" dark @click="deletePack">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      :scrollable="false"
      v-model="dialoginfo"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="mt-n5 white--text">
            Dettaglio delle azioni per questa pagina
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialoginfo = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-list disabled>
            <v-subheader>Azioni</v-subheader>
            <v-list-item-group v-model="item" color="primary">
              <v-list-item v-for="(item, i) in actionItems" :key="i">
                <v-list-item-icon>
                  <v-icon :color="item.color" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="text-align: left !important"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogArticolo"
      width="900"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1">
          <v-toolbar-title class="white--text"> Articolo </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small @click="dialogArticolo = false">
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <div v-viewer class="images clearfix">
            <img
              :contain="true"
              aspect-ratio="1"
              :src="this.immagine_preview"
              class="white--text image align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogElimina"
      width="1100"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 ml-n2" primary-title>
          <v-toolbar-title class="white--text"> Articolo </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogElimina = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-img
          aspect-ratio="1"
          :src="selectedImage"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        />
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAllegati"
      width="500"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">
            Aggiungi Articolo Allegati
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                @click="
                  setImmagine();
                  dialogAllegati = false;
                "
              >
                <v-icon v-on="on" color="success" v-bind="attrs">
                  mdi-floppy
                </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogAllegati = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <!--<v-file-input
accept="image/*"
@change="getBase64"
class="mb-n3 mt-4 pb-0 pr-2"
label="Carica un'immagine (max 500 × 500 px)"
outlined
style="max-width:500px;min-width:325px;"
v-model="immagine"
></v-file-input>-->

          <v-text-field
            v-model="immagine_preview"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image/pdf"
            label="Allegato"
            prepend-icon="mdi-camera"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialog"
      width="1410"
      class="packagingModal"
      style="height: 90%"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">
            Packaging -- {{ this.data.cod_articolo }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="updatePackging" class="ml-auto" small>
                <v-icon v-on="on" v-bind="attrs" color="success">
                  mdi-floppy
                </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                @click="
                  closePackModal();
                  dialog = false;
                "
              >
                <v-icon v-on="on" v-bind="attrs" color="#c00000" depressed
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              :style="windowWidth > 960 ? 'border-right:1px solid black;' : ''"
              cols="12"
              md="5"
            >
              <v-form>
                <v-row class="">
                  <v-col sm="4" md="3">
                    <v-text-field
                      readonly
                      v-model="codice"
                      class
                      label="Codice"
                      placeholder="Codice"
                    ></v-text-field>
                  </v-col>

                  <v-col sm="8" md="9">
                    <v-autocomplete
                      v-model="ragione_sociale"
                      :items="anagraficaList"
                      label="Ragione_sociale"
                      placeholder="Ragione sociale"
                      item-text="ragione_sociale"
                      @change="updateCodice"
                      required
                      item-value="id_tabella"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col sm="6" md="4">
                    <v-text-field
                      type="number"
                      class="mt-n8"
                      v-model="qta_contenitore"
                      label="Q.ta Contenitore"
                      placeholder="Q.ta Contenitore"
                      min="0"
                      onkeydown="javascript: return event.keyCode == 69 ? false : true"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="6">
                    <v-autocomplete
                      class="mt-n8"
                      v-model="id_articolo_contenitore"
                      :items="contentoreList"
                      label="Contenitore"
                      placeholder="Contenitore"
                      item-text="des"
                      required
                      item-value="id_tabella"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="2" xs="2" sm="4" md="2">
                    <div style="margin-top: -2rem">
                      <span
                        style="
                          margin-left: -1rem;
                          margin-bottom: 1rem;
                          font-size: 12px;
                          color: rgba(0, 0, 0, 0.6);
                        "
                        >Impilabile</span
                      >
                      <v-switch
                        v-model="Impilabile"
                        class="mt-n1"
                        :false-value="0"
                        :true-value="1"
                      ></v-switch>
                    </div>
                  </v-col>
                  <!-- <v-col sm="6" md="2" style="margin-left:-2.4rem !important;">
                                    <v-text-field v-model="qty_imp" class="mt-n8" readonly label="Qtà impilabile" placeholder="Qtà impilabile"></v-text-field>
                                </v-col>-->

                  <v-col md="2" sm="4">
                    <v-text-field
                      type="number"
                      v-model="qta_min"
                      class="mt-n8"
                      label="Q.ta min"
                      placeholder="Q.ta min Sepdzione"
                      min="0"
                      onkeydown="javascript: return event.keyCode == 69 ? false : true"
                    ></v-text-field>
                  </v-col>

                  <v-col md="3" sm="4">
                    <v-text-field
                      type="number"
                      v-model="qta_imballo"
                      class="mt-n8"
                      label="Q.ta Imballo"
                      placeholder="Q.ta"
                      min="0"
                      onkeydown="javascript: return event.keyCode == 69 ? false : true"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col sm="4" md="2">
                                    <v-text-field v-model="pesso_netto" class="mt-n8" readonly label="Pesso netto" placeholder="Peso netto"></v-text-field>
                                </v-col>
                                <v-col sm="4" md="2">
                                    <v-text-field v-model="tara" class="mt-n8" readonly label="Tara" placeholder="Tara"></v-text-field>
                                </v-col>
                                <v-col sm="4" md="2">
                                    <v-text-field v-model="peso_lordo" class="mt-n8" readonly label="Pesso lordo" placeholder="Peso lordo"></v-text-field>
                                </v-col>

                                <v-col> </v-col>-->
                </v-row>
              </v-form>
              <v-row class="mt-n6 mt-sm-0 ml-2">
                <v-col class="text-left">
                  <h1 class="v-label">BOM imballo</h1>
                </v-col>

                <v-col>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="dialogRicerca = true"
                        class="mt-n3 ml-n12"
                        small
                      >
                        <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Aggiungi Distinta</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- :key="this.distintaItems" -->
              <v-treeview
                activatable
                shaped
                hoverable
                :items="this.distintaItems"
                item-key="id"
                content="6"
                open-on-click
                class="text-left ml-n7 mt-n5"
              >
                <template v-slot:prepend="{ item, open }">
                  <v-icon v-if="item.id == 0">{{
                    open ? "mdi-buffer" : "mdi-buffer"
                  }}</v-icon>
                  <v-icon v-if="item.id > 0">{{
                    open ? "mdi-text-short" : "mdi-text-short"
                  }}</v-icon>

                  <template v-if="item.id >= -1"
                    >N°{{ item.id }} {{ item.quantita }}
                    {{ item.cod_articolo }}</template
                  >
                </template>

                <template slot="append" slot-scope="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="showModal"
                        v-on="on"
                        v-if="item.id_rif == 1"
                        icon
                        color="#1a80b6"
                        small
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Inserisci Componente</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-if="item.id_rif == 0"
                        icon
                        color="#009999"
                        @click="updateItem(item)"
                        small
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Modifica Componente</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        v-show="item.id_rif == 0"
                        icon
                        color="red"
                        @click="deleteFolder(item)"
                        small
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancella Componente</span>
                  </v-tooltip>
                </template>
              </v-treeview>
            </v-col>

            <v-col
              :style="windowWidth > 960 ? 'border-right:1px solid black;' : ''"
              class="mt-1"
              md="5"
              cols="8"
            >
              <v-row>
                <v-col class="pt-6 mt-n8 mt-sm-0">
                  <v-list
                    max-width="525px"
                    max-height="300px"
                    class="overflow-y-auto mr-n2"
                    content-class="listtoscroll"
                    dense
                    flat
                  >
                    <header>Istruzioni imballo</header>
                    <v-list-item-group
                      class="text-left"
                      v-model="item"
                      color="primary"
                    >
                      <v-list-item
                        class="left ml-n6"
                        v-for="(item, i) in listItem"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-wrap">
                            <v-row>
                              <v-col class="mt-n3" md="10">
                                <h4 class="ml-2">
                                  <span>{{ item.indice }}</span
                                  >. {{ item.des_istruzione }}
                                </h4>
                              </v-col>
                              <v-col
                                class="mt-n3 ml-n2"
                                md="2"
                                style="float: right"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-on="on"
                                      v-bind="attrs"
                                      style="float: left"
                                      color="#009999"
                                      @click="edittodo(item)"
                                      >mdi-pencil</v-icon
                                    >
                                  </template>
                                  <span>Aggiornare</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      @click="delinstruction(item)"
                                      v-on="on"
                                      v-bind="attrs"
                                      color="red"
                                    >
                                      mdi-window-close
                                    </v-icon>
                                  </template>
                                  <span>Cancellare</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>

                  <v-row class="ml-n3 mt-n5">
                    <v-col md="8">
                      <v-text-field
                        type="text"
                        class="input-class"
                        v-model="des_istruzione"
                        label="Aggiungi Istruzioni"
                        placeholder="Aggiungi Istruzioni"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col class="ml-n8 mt-4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small @click="addistruzione">
                            <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                              >mdi-plus</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Aggiungi Istruzioni</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="2" cols="4" class="mt-n5 mt-sm-0">
              <v-btn
                style="margin-top: 2.7rem"
                height="30"
                small
                @click="openImgModal"
              >
                <v-icon color="blue"> mdi-paperclip </v-icon>
              </v-btn>
              <v-container class="mt-n2">
                <v-list content-class="listtoscroll" dense flat>
                  <v-list-item-group class="" color="primary">
                    <v-list-item v-for="(item, i) in immagineLista" :key="i">
                      <div
                        v-if="!isPdf(item.immagine)"
                        v-viewer
                        class="images clearfix"
                        style="
                          width: 250px;
                          <!-- border: solid 1px silver; -->
                          margin-top: 0.4rem;
                        "
                      >
                        <!-- style="height: 100px; width: 100% !important" -->
                        <img
                          :lazy-src="item.immagine"
                          aspect-ratio="1"
                          class="grey lighten-2 mb-2"
                          :src="item.immagine"
                          :id="'img' + i"
                          :ref="'img' + i"
                          @load="onLoadImage(i)"
                        />
                      </div>
                      <div
                        @click="openPdfAppenaCaricato(item.immagine)"
                        style="
                          height: 40px !important;

                          width: 250px !important;
                          margin-right: -10rem !important;
                        "
                        v-if="isPdf(item.immagine)"
                      >
                        <div style="text-align: center">
                          <v-spacer></v-spacer>
                          <v-icon
                            style="font-size: 8rem !important"
                            color="error"
                            x-large
                          >
                            mdi-file-pdf-box
                          </v-icon>
                        </div>
                      </div>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogRicerca"
      width="700"
    >
      <v-overlay :value="overlay" style="z-index: 10">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-card>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text"> Cerca Imballo </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="invertDialog" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="red"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-subtitle>
          <v-row>
            <v-col md="11">
              <v-text-field
                type="text"
                class="input-class"
                v-on:keydown.enter.prevent="searchImballo"
                v-model="cod_imballo"
                label="Cod.Imballo"
                placeholder="Inserisci il Codice"
                required
              ></v-text-field>
            </v-col>
            <v-btn class="mt-8" small @click="searchImballo">
              <v-icon
                class="m"
                color="#1a80b6"
                style="transition: none !important"
                size="24"
                >mdi-magnify</v-icon
              >
            </v-btn>
          </v-row>
        </v-card-subtitle>

        <v-card-text style="max-height: 450px; overflow-y: auto">
          <v-data-table
            :loading="true"
            v-if="this.items1.length > 0"
            :headers="headers1"
            :items="items1"
          >
            <v-progress-linear
              v-show="progressBar"
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template v-slot:[`item.actions`]="{ props }">
              <v-btn class="mx-2" small @click="onDetail(props.item)">
                <v-icon color="#009900" dark>mdi-check</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <form>
        <v-row class="justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card :elevation="hover ? 24 : 2">
                <v-row class="justify-center">
                  <v-col md="3" class="ml-2">
                    <v-autocomplete
                      v-model="data.id_articolo_contenitore_standard"
                      :items="contentoreList"
                      item-text="des"
                      required
                      :placeholder="des"
                      label="Contenitore"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col md="2" class="ml-3">
                    <v-text-field
                      type="number"
                      class="input-class"
                      v-model="data.qta_contenitore_standard"
                      label="Quantità"
                      min="0"
                      placeholder="Quantità"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <v-autocomplete
                      v-model="data.id_articolo_imballo_standard"
                      :items="imballoList"
                      item-text="des"
                      required
                      :placeholder="des"
                      label="Imballo"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col md="2">
                    <v-text-field
                      type="number"
                      class="input-class"
                      v-model="data.qta_imballo_standard"
                      label="Quantità"
                      min="0"
                      placeholder="Quantità"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-hover>
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                style="width: 100% !important"
                class="mt-2"
              >
                <v-toolbar dense class="indigo darken-1 headline" primary-title>
                  <v-toolbar-title class="white--text"
                    >Packaging</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="openModal" class="ml-2" small>
                        <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Aggiungi Packaging</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="dialoginfo = true"
                        v-on="on"
                        v-bind="attrs"
                        class="ml-4"
                        small
                      >
                        <v-icon color="primary">
                          mdi-information-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Dettaglio delle azioni</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-row>
                  <v-col md="12">
                    <v-data-table
                      :loading="loading1"
                      :items="items"
                      :headers="headers"
                    >
                      <v-progress-linear
                        v-show="progressBar1"
                        slot="progress"
                        color="blue"
                        indeterminate
                      ></v-progress-linear>
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="handleclick(item)"
                              small
                              style="float: left"
                            >
                              <v-icon v-on="on" v-bind="attrs" color="#009999"
                                >mdi-pencil</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Aggiornare</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:[`item.print`]="{}">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-n8" small style="float: left">
                              <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                                >mdi-printer</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Stampa</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="PackgingToDel(item)" small>
                              <v-icon v-on="on" v-bind="attrs" color="red">
                                mdi-window-close
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Cancellare</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-hover>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["conteCod", "imballoCod", "data"],
  data() {
    return {
      // Snackbar Variables
      windowWidth: window.innerWidth,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      // Pdf Dialog
      pdfdialog: false,
      // dialog for delete confirmation dialog
      dialogEliminaElemento: false,
      selectedPackaging: {},
      // Deleted Instruction Array
      deletedInstructions: [],

      // ACtion card heafer
      actionItems: [
        {
          id: 1,
          icon: "mdi-plus",
          text: "Per aggiungere una nuova Packaging",
          color: "#1a80b6",
        },
        {
          id: 2,
          icon: "mdi-pencil",
          text: "Per aggiornare Packaging",
          color: "#009999",
        },
        {
          id: 3,
          icon: "mdi-window-close",
          text: "Per eliminare una Packaging",
          color: "#c00000",
        },
      ],
      overlay: false,
      allegato: [],
      dialoginfo: false,
      progressBar: false,
      progressBar1: false,
      loading: false,
      loading1: false,
      dialogArticolo: false,
      dialogAllegati: false,
      dialogElimina: false,
      dialogRicerca: false,
      selectedImage: "",
      selectedPdf: "",
      item: "",
      codice: "",
      immagine: "",
      immagineLista: [],
      anagraficaList: [],
      distintaItems: [],
      cod_imballo: "",
      id_articolo_packaging: "",
      id_articolo_contenitore_standard: this.data,
      setVal: {},
      note: "",
      unita: "",
      id_articolo_contenitore: "",
      pesso_netto: 0,
      tara: "",
      peso_lordo: 0,
      qty_imp: "",
      Impilabile: false,
      // qta_imballo:"",
      des_imballo: "",
      des_contenitore: "",
      selcetedItem: {},
      ragione_sociale: "",
      // des_contenitore: "",
      id_anagrafica: 0,
      id_articolo_imballo: 0,
      qta_contenitore: 0,
      // des_imballo: "",
      qta_imballo: 0,
      qta_minima_spedizione: 0,
      ricerca: "",
      qta_min: 0,
      imballo_qty: 0,
      contemitore_qty: 0,
      tutti_fornitori: "",
      dialog: false,
      contentore_list: [],
      imballo_list: [],
      contentoreList: [],
      imballoList: [],
      items: [],
      items1: [],
      des_istruzione: "",
      cod_articolo: "",
      cod_articolo1: "",
      num_riga: 1,
      id_pack_istruzioni: null,
      selectedIns: {},
      immagine_preview: "",
      title: "Image Upload",
      imageName: "",
      imageUrl: "",
      imageFile: "",
      des: "",
      // packaging Table Header
      headers: [
        {
          value: "edit",
          align: "start",
          sortable: false,
        },
        {
          value: "print",
          sortable: false,
        },
        {
          text: "Ragione Sociale",

          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Tipo Contenitore",
          value: "des_contenitore",
          sortable: false,
        },
        {
          text: "Q.ta Contenitore",
          value: "qta_contenitore",
          sortable: false,
        },
        {
          text: "Tipo Imballo",
          value: "des_imballo",
          sortable: false,
        },
        {
          text: "Q.ta Imballo",
          value: "qta_imballo",
          sortable: false,
        },
        {
          text: "Q.ta Minima Spedizione",
          value: "qta_minima_spedizione",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      //Imballo Search table header
      headers1: [
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "Codice Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des Ariticolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Fam Articolo",
          value: "fam_articolo",
          sortable: false,
        },
      ],

      listItem: [],
      items2: [],
    };
  },
  computed: {
    // disabled() {
    //   return this.ragione_sociale.length < 1;
    // },
  },

  watch: {
    data(val) {
      this.$emit("update", val);
    },
  },

  methods: {
    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    // to open Modal for PDF
    openPdfAppenaCaricato(item) {
      console.log(item);
      if (item.startsWith("data:application/pdf")) {
        this.selectedPdf = item;
        this.pdfdialog = true;
      }
    },
    // In order to open pop up Modal
    PackgingToDel(item) {
      this.selectedPackaging = item;
      this.dialogEliminaElemento = true;
    },
    // TO clear item selected to delete when click Annulla button
    delSelectedItem() {
      this.dialogEliminaElemento = false;
      this.selectedPackaging = {};
    },
    setIndiceRicorsivo(array) {
      array.forEach((elem) => {
        elem.indice = array.indexOf(elem) + 1;
      });
    },
    clearImage() {
      this.dialogRicerca = false;
    },
    openImgModal() {
      this.dialogAllegati = true;
      this.immagine_preview = "";
      // alert(this.immagine_preview)
    },
    delImage(item) {
      let i = this.immagineLista.findIndex(
        (it) => it.id_pack_immagini === item.id_pack_immagini
      );
      this.immagineLista.splice(i, 1);
    },
    // To Delete a single instruction
    delinstruction(item) {
      //  console.log(item)

      if (item.id_pack_istruzioni > -1) {
        item.validita = 0;
        this.deletedInstructions.push(item);
        let i = this.listItem.findIndex((it) => it === item);

        this.listItem.splice(i, 1);
      } else {
        let i = this.listItem.findIndex((it) => it === item);

        this.listItem.splice(i, 1);
      }

      this.setIndiceRicorsivo(this.listItem);
    },
    // closing dialog
    invertDialog() {
      this.dialogRicerca = false;
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");

        // Create an image
        var img = new Image();

        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;

          // Draw the image
          ctx.drawImage(img, 0, 0);

          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };

        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
    },

    onPaste(e) {
      // console.log('OnPaste :>> ');
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;

        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
          }
        }
      }

      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        // console.log('object :>> ', object);
        this.immagine_preview = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name.toLowerCase()
      );
    },
    onLoadImage(i) {
      this.resizeImage(this.$refs["img" + i][0], "img" + i);
    },
    resizeImage(img, id) {
      if (img.width > img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    onFileChange(e) {
      // console.log("onFileChange")
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (this.hasExtension(files[0], [".jpeg", ".gif", ".png", ".jpg"])) {
        this.allegato = files[0];

        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;

        reader.onload = function () {
          that.immagine_preview = reader.result;
          let data = {};
          data.id_articolo_packaging =
            that.id_articolo_packaging != "" ? that.id_articolo_packaging : -1;
          data.id_pack_immagini = -1;
          data.id_utente = localStorage.id;
          data.immagine = that.immagine_preview;
          data.validita = 1;
          that.immagineLista.push(data);

          // var image = new Image();
          // image.src = event.target.result;
          // image.onload = function () {
          //   that.resizeImage(image, "img");
          // };

          that.dialogAllegati = false;
          return that.immagineLista;
        };

        reader.onerror = function (error) {
          throw error;
        };
      } else {
        this.dialogRicerca = false;
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = " Tipo di file non accettato.";
        this.snackbar_text_color = "white";
      }
    },

    setImage(it) {
      this.dialogArticolo = true;
      this.immagine_preview = it;
    },
    // Setting value of existing todo to update it.
    edittodo(item) {
      this.id_pack_istruzioni = item.id_pack_istruzioni;
      this.selectedIns = item;
      this.des_istruzione = item.des_istruzione;
    },
    // Setting Codice value
    updateCodice(item) {
      this.anagraficaList.map((ana) => {
        if (ana.ragione_sociale === item) {
          this.codice = ana.codice;
        }
      });
    },
    // In order to close Packaging modal
    closePackModal() {
      this.immagineLista = [];
      this.listItem = [];
      this.distintaItems = [];
      this.ragione_sociale = [];
      this.qty_imp = "";
      this.qta_contenitore = "";
      this.qta_imballo = "";
      this.qta_minima_spedizione = "";
      this.qta_min = "";
    },
    // in order to set Image
    selectImage(item) {
      this.dialogElimina = true;
      this.selectedImage = item.immagine;
    },
    // Getting the distina base of imballo item
    onDetail(item) {
      this.id_articolo_imballo = item.id_articolo;
      // console.log("Detail", item);

      let getdistintabase = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolidistintabase",
          id_articolo: item.id_articolo,
          id_utente: localStorage.id,
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
        },
      };

      this.$store.dispatch("api", getdistintabase).then((res) => {
        this.distintaItems = res.data;
        // console.log(this.distintaItems)
        this.des_imballo = this.distintaItems[0].name;
        if (this.distintaItems !== []) {
          this.dialogRicerca = false;
        }
      });
    },
    // In order to search imballo item to display distinta base
    searchImballo() {
      let imballoSearch = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          cod_articolo: "*",
          id_tipo: (this.id_tipo = "2293"),
          id_utente: localStorage.id,
        },
      };

      this.overlay = true;

      this.$store.dispatch("api", imballoSearch).then((res) => {
        this.overlay = false;
        this.items1 = res.data.lista;

        if (this.items1.length > 0) {
          this.progressBar = false;
        }
      });
    },
    // In order to clear form field when closing modal using x
    closeMainModal() {
      if (this.dialog === true) {
        this.dialog = false;
        this.distintaItems = [];
        this.qta_min = "";
        this.qta_imballo = "";
        this.qta_contenitore = "";
        this.des_contenitore = "";
        this.ragione_sociale = "";
        this.id_articolo_packaging = "";
      }
    },
    // In order to get single object detail from packaging list
    handleclick(val) {
      this.selcetedItem = val;
      this.dialog = true;

      this.qta_min = val.qta_minima_spedizione;
      this.qta_imballo = val.qta_imballo;
      this.qta_contenitore = val.qta_contenitore;
      this.des_contenitore = val.des_contenitore;
      this.ragione_sociale = val.ragione_sociale;
      this.id_articolo_packaging = val.id_articolo_packaging;
      this.codice = val.codice;
      this.id_articolo_imballo = val.id_articolo_imballo;
      this.id_articolo_contenitore = val.id_articolo_contenitore;
      //  Getting selected imballo articolo distinta base
      if (val.id_articolo_imballo != null) {
        let getdistintabase = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticolidistintabase",
            id_articolo: val.id_articolo_imballo,
            id_utente: localStorage.id,
            token: localStorage.getItem("user_token"),
            db_name: "quickproduction",
          },
        };

        this.$store.dispatch("api", getdistintabase).then((res) => {
          this.distintaItems = res.data;

          // this.distintaItems.map((el) => {
          //     if (el.children.length > 0) {
          //         el.children.map((el) => {
          //             this.ids.push(el.id);

          //             if (el.children.length > 0) {
          //                 el.children.map((el) => {
          //                     this.ids.push(el.id);
          //                 });
          //             }
          //         });
          //     }
          //     this.ids.push(el.id);

          // });
        });
      }
      // In order to list of packaging instructions
      if (val.nr_istruzioni !== 0) {
        let getintro = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticolipackagingistruzioni",
            id_articolo_packaging: val.id_articolo_packaging,
            id_utente: localStorage.id,
            token: localStorage.getItem("user_token"),
            db_name: "quickproduction",
          },
        };

        this.$store.dispatch("api", getintro).then((res) => {
          this.listItem = res.data;
          this.setIndiceRicorsivo(this.listItem);
          this.listItem.map((it, index, array) => {
            if (index === array.length - 1) {
              this.num_riga = it.num_riga;
            }
            this.num_riga++;
          });
        });
      }

      if (val.nr_immagini !== 0) {
        let getImmagini = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticolipackagingimmagini",
            token: localStorage.getItem("user_token"),
            id_articolo_packaging: val.id_articolo_packaging,
            id_utente: localStorage.id,
            db_name: "quickproduction",
          },
        };

        this.$store.dispatch("api", getImmagini).then((res) => {
          this.immagineLista = res.data;
        });
      }

      // this.des_contenitore = val.des_contenitore

      // this.ragione_sociale = val.ragione_sociale;
      // this.des_contenitore = val.des_contenitore;
      // this.qta_contenitore = val.qta_contenitore;
      // this.des_imballo = val.des_imballo;
      // this.qta_imballo = val.qta_imballo;
      // this.qta_minima_spedizione = val.qta_minima_spedizione;

      // this.id_articolo_packaging = val.id_articolo_packaging
      //  this.qta_minima_spedizione = val.qta_min;
      // this.id_anagrafica = val.anagrafica,
      // this.qta_imballo= val.qta;
      //  this.id_articolo_contenitore=val.id_articolo_contenitore
      //  this.id_articolo = val.id_articolo
    },

    // Open Add or edit packaging form
    openModal() {
      (this.dialog = true), (this.selcetedItem = {});
      this.num_riga = 1;
      this.qta_min = "";
      this.qta_imballo = "";
      this.qta_contenitore = "";
      this.des_contenitore = "";
      this.ragione_sociale = "";
      this.id_articolo_packaging = "";
      this.immagineLista = [];
      this.listItem = [];
      this.codice = "";
    },

    // SAving the images

    setImmagine() {
      const image = localStorage.getItem("ArticoloImage");
      let data = {};
      data.immagine = image;
      data.id_pack_immagini = -1;
      data.validita = 1;
      data.id_articolo_packaging =
        this.id_articolo_packaging !== "" ? this.id_articolo_packaging : -1;
      this.immagineLista.push(data);
      localStorage.removeItem("ArticoloImage");
      this.immagine = "";
    },
    // in order to change image into base64
    getBase64() {
      var dataBase64;
      var reader = new FileReader();
      reader.readAsDataURL(this.immagine);
      reader.onload = function () {
        dataBase64 = reader.result;
        localStorage.setItem("ArticoloImage", dataBase64);
      };
    },
    // Adding instruction to Packaging
    addistruzione() {
      if (this.id_pack_istruzioni) {
        // Procedura per modifica di istruzione già presente
        console.log("entrato 1: " + this.id_pack_istruzioni);
        const data = {};
        data.num_riga = this.selectedIns.num_riga;
        data.des_istruzione = this.des_istruzione;
        data.validita = 1;
        data.id_utente = localStorage.id;
        data.id_pack_istruzioni = this.selectedIns.id_pack_istruzioni;
        data.id_articolo_packaging =
          this.id_articolo_packaging !== "" ? this.id_articolo_packaging : -1;

        const i = this.listItem.findIndex((it) => it === this.selectedIns);
        this.listItem.splice(i, 1, data);
        this.selectedIns = {};
        this.id_pack_istruzioni = null;
        this.des_istruzione = "";
        this.setIndiceRicorsivo(this.listItem);
      } else {
        // Procedura per inserimento nuova istruzione
        console.log("entrato 2: " + this.id_pack_istruzioni);
        const data = {};
        data.num_riga = this.num_riga;
        data.des_istruzione = this.des_istruzione;
        data.validita = 1;
        data.id_utente = localStorage.id;
        data.id_pack_istruzioni = -1;
        data.id_articolo_packaging =
          this.id_articolo_packaging !== "" ? this.id_articolo_packaging : -1;
        if (data.des_istruzione === "") {
          return;
        }
        this.listItem.push(data);
        this.setIndiceRicorsivo(this.listItem);
        this.num_riga++;
        this.des_istruzione = "";
      }
    },
    // In order to delete single packaging
    deletePack() {
      let data = {};
      data.id_articolo_packaging = this.selectedPackaging.id_articolo_packaging;
      data.validita = 0;

      let delPackaging = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolopackaging",
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.id,
          id_articolo: this.data.id_articolo,
          articolo_packaging: [data],
          articolo_packaging_immagini: [],
          articolo_packaging_istruzioni: [],
        },
      };

      this.$store.dispatch("api", delPackaging).then(() => {
        this.snackbar = true;
        this.snackbar_background = "success";
        this.snackbar_icon = "mdi-check";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
      });
      let i = this.items.findIndex(
        (it) => it.id_articolo_packaging === data.id_articolo_packaging
      );

      this.items.splice(i, 1);
      this.dialogEliminaElemento = false;
      //  this.FetchPackaging()
    },
    // in order to create or update packaging
    updatePackging() {
      if (!this.selcetedItem.id_articolo_packaging) {
        // let data = {}
        let dataobj = {};
        // let articolo_packaging = []

        // if(this.id_articolo_contenitore === ''){
        //     return
        // }
        if (!this.id_articolo_imballo) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Il campo Imaballo è richiesto!";
          this.snackbar_text_color = "white";
          return;
        }

        if (this.qta_contenitore === "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Il campo Q.ta Contenitore è richiesto!";
          this.snackbar_text_color = "white";
          return;
        }

        if (this.qta_min === "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Il campo Qta Min è richiesto!";
          this.snackbar_text_color = "white";
          return;
        }

        dataobj.id_articolo_packaging = -1;
        dataobj.id_articolo = this.data.id_articolo;
        dataobj.id_articolo_contenitore = this.id_articolo_contenitore;
        dataobj.id_articolo_imballo = this.id_articolo_imballo;
        dataobj.qta_minima_spedizione = this.qta_min;

        dataobj.codice = this.codice;
        dataobj.qta_imballo = this.qta_imballo;
        dataobj.qta_contenitore = this.qta_contenitore;
        dataobj.validita = 1;

        dataobj.des_imballo = this.des_imballo;

        this.anagraficaList.map((an) => {
          if (an.ragione_sociale === this.ragione_sociale) {
            dataobj.ragione_sociale = an.ragione_sociale;
            dataobj.id_anagrafica = an.id_anagrafica;
          }
        });

        this.contentore_list.map((co) => {
          if (this.id_articolo_contenitore === co.cod_articolo) {
            dataobj.id_articolo_contenitore = co.id_articolo;
            dataobj.des_contenitore = co.cod_articolo;
            return dataobj.id_articolo_contenitore;
          }
        });

        let dataArray = [];
        dataArray.push(dataobj);

        let setPackaging = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolopackaging",
            db_name: "quickproduction",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.id,
            id_articolo: this.data.id_articolo,
            articolo_packaging: dataArray,
            articolo_packaging_immagini: this.immagineLista,
            articolo_packaging_istruzioni: this.listItem,
          },
        };

        this.$store.dispatch("api", setPackaging).then((res) => {
          dataobj.id_articolo_packaging = res.data;
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_text_color = "white";
          this.items.push(dataobj);
        });
      }
      if (this.selcetedItem.id_articolo_packaging) {
        let articolo_packaging = [];
        let dataobj = {};
        let data = {};

        dataobj.id_articolo_packaging = this.selcetedItem.id_articolo_packaging;
        dataobj.id_articolo_contenitore = this.id_articolo_contenitore;
        dataobj.validita = 1;
        dataobj.id_articolo_imballo = this.id_articolo_imballo;
        dataobj.id_articolo = this.data.id_articolo;
        dataobj.qta_minima_spedizione = this.qta_min;
        dataobj.qta_contenitore = this.qta_contenitore;
        dataobj.des_imballo = this.des_imballo;

        dataobj.codice = this.codice;

        dataobj.qta_imballo = this.qta_imballo;
        this.contentore_list.map((co) => {
          if (this.id_articolo_contenitore === co.cod_articolo) {
            dataobj.id_articolo_contenitore = co.id_articolo;
            dataobj.des_contenitore = co.cod_articolo;
            return dataobj.id_articolo_contenitore;
          }
        });
        this.anagraficaList.map((an) => {
          if (an.ragione_sociale === this.ragione_sociale) {
            dataobj.ragione_sociale = an.ragione_sociale;
            dataobj.id_anagrafica = an.id_anagrafica;
          }
        });

        articolo_packaging.push(dataobj);

        data.articolo_packaging = articolo_packaging;

        let dataArray = [];

        dataArray.push(dataobj);

        if (this.deletedInstructions.length > 0) {
          this.deletedInstructions.map((el) => {
            this.listItem.push(el);
          });
        }

        let setPackaging = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolopackaging",
            db_name: "quickproduction",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.id,
            id_articolo: this.data.id_articolo,
            articolo_packaging: dataArray,
            articolo_packaging_immagini: this.immagineLista,
            articolo_packaging_istruzioni: this.listItem,
          },
        };

        this.$store.dispatch("api", setPackaging).then(() => {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_text_color = "white";
        });

        let i = this.items.findIndex(
          (it) => it.id_articolo_packaging === dataobj.id_articolo_packaging
        );
        this.items.splice(i, 1, dataobj);
      }

      this.id_articolo_contenitore = "";
      this.qta_contenitore = 0;
      this.qta_imballo = 0;
      this.qta_min = 0;

      this.dialog = false;
    },
    searchPackaging() {
      let getPackaging = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_utente: localStorage.id,
          des_anagrafica: this.ragione_sociale,
        },
      };
      this.$store.dispatch("api", getPackaging).then((res) => {
        this.items1 = res.data;
      });
    },
    // Getting list of packaging to populate the table
    FetchPackaging() {
      this.loading1 = true;
      this.progressBar1 = true;
      let getPackaging = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolipackaging",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_utente: localStorage.id,
          id_articolo: this.data.id_articolo,
        },
      };

      this.$store.dispatch("api", getPackaging).then((res) => {
        this.items = res.data;

        if (this.items.length > 0) {
          this.progressBar1 = false;
          this.loading1 = false;
        }
        if (this.items.length === 0) {
          this.progressBar1 = false;
          this.loading1 = false;
        }

        this.items.map((it) => {
          this.contentore_list.map((co) => {
            if (it.des_contenitore === "") {
              it.des_contenitore = co.cod_articolo;
              return this.items;
            }
          });
        });
        // return this.items;
      });
    },
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.FetchPackaging();

    // In order to get anagrafica LIsta
    let getAnagrafica = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "getanagrafica",
        tipo: "F",
        token: localStorage.getItem("user_token"),
        db_name: "quickproduction",
        id_utente: localStorage.id,
      },
    };
    this.$store.dispatch("api", getAnagrafica).then((res) => {
      this.anagraficaList = res.data;
    });

    // In order to get Contentore List
    let search_Arti_req = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        db_name: "quickproduction",
        tipo: "tipoarticolo",
        filter: "val_txt='PACK' AND val_int = 0",
      },
    };

    this.$store.dispatch("api", search_Arti_req).then((res) => {
      this.contentoreList = res.data;
      // console.log('ContetoreList',this.contentoreList)
    });

    // In order to get Imballo Lista
    let search_Arti1_req = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        db_name: "quickproduction",
        tipo: "tipoarticolo",
        filter: "val_txt='PACK' AND val_int = 1",
      },
    };

    this.$store.dispatch("api", search_Arti1_req).then((res) => {
      this.imballoList = res.data;
      // console.log('This Imballo List',this.imballoList);
    });
    if (this.conteCod) {
      this.cod_articolo = this.conteCod;
      this.cod_articolo1 = "";
    }

    if (this.imballoCod) {
      this.cod_articolo1 = this.imballoCod;
      this.cod_articolo = "";
    }
  },
};
</script>

<style lang="css" scoped>
.artDialog {
  overflow-x: hidden;
}
.profile-pic {
  position: relative;
}

.profile-pic:hover .edit {
  display: block;
  z-index: 2;
}

.edit a {
  color: #000;
}

.imageContainer:hover .v-card--reveal {
  display: block;
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.listtoscroll ::-webkit-scrollbar-track {
  display: none !important;
}

.left {
  overflow-y: scroll; /* it works! */
}
</style>
