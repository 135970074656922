<template>
    <v-card class="pa-3">
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>


        <v-card-title> Istruzioni di Lavoro </v-card-title>
        <v-divider inset></v-divider>
        <br>
        <v-card-text>
            <v-row>
                <v-col cols="2">
                    <v-autocomplete
                        outlined
                        v-model="id_articolo_selected"
                        :items="articoli"
                        dense
                            item-text="cod_articolo"
                            item-value="id_articolo"
                        label="Articolo"
                        @change="cambiaSelezioneArticolo"
                    ></v-autocomplete>
                </v-col>


                <v-col cols="5" sm="5" md="5">
                    <v-text-field
                        label="Descrizione Articolo"
                        dense
                        v-model="des_articolo_selected"
                        outlined
                        readonly
                    ></v-text-field>
                </v-col>

                <v-col cols="5" sm="5" md="5">
                    <v-autocomplete
                        outlined
                        v-model="id_ciclo_selected"
                        :items="cicloLista"
                        dense
                            item-text="des"
                            item-value="id_ciclo_fasi"
                        label="Ciclo di Lavorazione"
                        @change="cambiaSelezioneCiclo"
                    >
                        <template slot="selection" slot-scope="data">
                            Cod. Fase: {{ data.item.cod_fase }} - {{ data.item.des }}  
                        </template>                    
                        <template slot="item" slot-scope="data">
                            Cod. Fase: {{ data.item.cod_fase }} - {{ data.item.des }}  
                        </template>
                    </v-autocomplete>
                </v-col>


                <v-col cols="12" sm="6" md="12" align="center">
                    <v-data-table
                    :headers="MacchinaHeaders"
                    :items="this.articolo_ciclo_fasi_macchina"
                    >
                    <template  v-slot:[`item.istruzioni`]="{ item }" >
                        <v-tooltip top>
                            <template v-slot:activator="{  }">
                                <v-btn  small  @click="istruzionilavoro(item)">
                                    <v-icon color="green"
                                    >mdi mdi-information-variant
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Istruzioni Lavoro</span>
                        </v-tooltip>

                    </template>
                    <template    v-slot:[`item.predefinito`]="{ item }">
                        <v-checkbox 
                        v-model="item.predefinito"
                        :value="item.predefinito"
                        readonly
                        >
                        </v-checkbox>
                    </template>
                    </v-data-table>   
                       
                </v-col>          
            </v-row>



                <!--Dialog Istruzioni di Lavoro -->
                <v-dialog persistent :hide-overlay="true" v-model="dialogIstruzioniLavoro" width="1200">
                <v-card>
                    <v-toolbar dense class="indigo darken-1" primary-title>
                    <v-toolbar-title class="white--text">Istruzioni Lavoro </v-toolbar-title>
                    <v-spacer></v-spacer>
                      <v-tooltip top>
                          <template v-slot:activator="{  }">
                              <v-btn  small  @click=" salvaIstruzioniLavoroMacchima()">
                                  <v-icon color="success"
                                  >mdi-floppy
                                  </v-icon>
                              </v-btn>
                          </template>
                          <span>Salva Istruzioni Lavoro</span>
                      </v-tooltip>                    

                    <v-spacer></v-spacer>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="chiudi_istruzioni" small>
                            <v-icon v-on="on" v-bind="attrs" depressed color="error">
                                mdi-window-close</v-icon
                            >
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card-text>
                      
                         

                        <v-row class="pa-5">
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="macchina_selezionata" label="Macchina" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="cod_articolo_selected" label="Articolo" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="fase_selected" label="Fase - Ciclo di Lavorazione" readonly></v-text-field>
                            </v-col>      
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="utente_ultimo_salvataggio" label="Utente ultimo salvataggio" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field v-model="data_modifica_istruzione" label="Data ultima modifica" readonly></v-text-field>
                            </v-col>                                                  
 
                        </v-row>

                        <v-divider/>
                    
                    <!-- datatable Itruzioni di Lavoro -->
                    <v-col cols="12" sm="12" md="12">
                        <template>
                            <v-data-table
                                :headers="headers_codici"
                                :items="filteredIstruzioniValidita"
                                hide-default-footer
                                :items-per-page="12000"
                                class="elevation-1"
                                                    >
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-app-bar-nav-icon></v-app-bar-nav-icon>
                                        <v-toolbar-title>Istruzioni Lavoro</v-toolbar-title>
                                        <v-divider
                                        class="mx-4"
                                        inset
                                        vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>

                                        <template>
                                            <v-sheet class="pa-5">
                                            <v-switch
                                                v-model="switch_fresa"
                                                inset
                                                :label="`Fresa: ${switch_fresa.toString()}`"
                                                @change="gestione_header"

                                            ></v-switch>
                                            </v-sheet>
                                        </template>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            v-model="des_lavoro"
                                            label="Descrizione Lavoro"
                                        >
                                        </v-text-field>
                                        <v-spacer></v-spacer>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn outlined small v-bind="attrs" v-on="on" @click="nuova_istruzione_lavoro">
                                                        <v-icon color="blue"
                                                        >mdi mdi-file-plus-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Nuova Istruzione di Lavoro</span>
                                            </v-tooltip>
                                    </v-toolbar>
                                </template>     
                                <template  v-slot:[`item.fr_etichetta`]="{ item }" >                                           
                                    <v-text-field
                                        v-model="item.fr_etichetta"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.fr_valore`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.fr_valore"
                                    ></v-text-field>        
                                </template>

                                <template  v-slot:[`item.mon`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.mon"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.grup`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.grup"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.n_car`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.n_car"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.ut_corr`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.ut_corr"
                                    ></v-text-field>        
                                </template> 
                                <template  v-slot:[`item.lavorazione`]="{ item }" >                    
                                    <v-select
                                    :items="tipo_lavorazione"
                                    item-text='descrizione'
                                    item-value='id'
                                    label="Tipo Lavorazione"
                                    v-model="item.id_tipo_lavorazione"
                                    ></v-select>      
                                </template>
                                <template  v-slot:[`item.des_lavorazione`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.des_lavorazione"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.inserto`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.inserto"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.durata`]="{ item }" > 
                                    <v-text-field
                                        v-model="item.durata"
                                    ></v-text-field>        
                                </template> 
                                <template  v-slot:[`item.actions`]="{ item }" >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                                <v-icon color="purple"
                                                >mdi-delete-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Elimina Associzione </span>
                                    </v-tooltip>
                                </template>      

                                <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="nuova_istruzione_lavoro"
                                    >
                                        Nuovo
                                    </v-btn>
                                </template>                                                                                                  

                            </v-data-table>
                        </template>       
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-textarea
                        outlined
                        name="input-7-4"
                        label="Note per Lavoro"
                        v-model="des_nota_lavoro"
                        ></v-textarea>
                    </v-col>
                    </v-card-text>
                </v-card>
                </v-dialog>

        </v-card-text>
    </v-card>

    
</template>

<script>
    import moment from "moment/moment";
    export default {
        data() {
            return {
                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",

                //data_bolla: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                //search: "",
                overlay: false,

                articoli:[],
                des_articolo_selected : "",
                id_articolo_selected: null,
                cod_articolo_selected:null,
                id_macchina: null,
                idciclo_fasi_macchina:null,

                fase_selected:null,
                cicloLista:[],
                id_ciclo_selected: null,
                articolo_ciclo_fasi_macchina:[],
                dialogIstruzioniLavoro:false,


            MacchinaHeaders: [
                {
                value: "istruzioni",
                sortable: false,
                },
                {
                text: "Predefinito",
                value: "predefinito",
                sortable: false,
                },
                {
                text: "Macchina",
                value: "des_macchina",
                sortable: false,
                },
                {
                text: "Cod. Macchina",
                value: "cod_macchina",
                sortable: false,
                },
                {
                text: "Tempo Setup",
                value: "tempo_setup",
                sortable: false,
                },
                {
                text: "UM Tempo",
                value: "des_um_tempo_setup",
                sortable: false,
                },
                {
                text: "UM Produzione",
                value: "des_um_produzione",
                sortable: false,
                },
                {
                text: "Min Pezzi",
                value: "pezzi_min",
                sortable: false,
                },
                {
                text: "Max Pezzi",
                value: "pezzi_max",
                sortable: false,
                },
                {
                text: "Unità Misura",
                value: "des_um_pezzi",
                sortable: false,
                },
            ],


            headers_codici: [
                { text: 'MON', value: 'mon' , sortable: false },
                { text: 'GRUP', value: 'grup' , sortable: false },
                { text: 'N CAR', value: 'n_car' , sortable: false },
                { text: 'UT. CORR.', value: 'ut_corr' , sortable: false },
                { text: 'TIPO LAVORAZIONE', value: 'lavorazione' , sortable: false, width: "20%" },
                { text: 'DES LAVORAZIONE', value: 'des_lavorazione' , sortable: false, width: "30%"},
                { text: 'INSERTO', value: 'inserto' , sortable: false },
                { text: 'DURATA', value: 'durata' , sortable: false },
                { text: '', value: 'actions',  width: "8%", sortable: false },
            ],                
            istruzioni:[],

            tipo_lavorazione: [
              {
                id: 0,
                descrizione: '',
              },
              {
                id: 1,
                descrizione: 'Lavorazione Unica',
              },
              {
                id: 2,
                descrizione: 'Lavorazione DX',
              },
              {
                id: 3,
                descrizione: 'Lavorazione SX',
              },          
            ],  
            des_lavoro: '',
            des_nota_lavoro: '',
            switch_fresa: false,

            macchina_selezionata:'',
            utente_ultimo_salvataggio: '' ,        
            data_modifica_istruzione : '',
            timestamp: '',

            id_istruzione_selected : null,
            id_ciclo_fasi_macchina_selected: null,
            id_macchina_selected: null,
            descrizione_selected: null,
            nota_Lavoro_selected: null,

            id_temp:0,

            };
        }, 

        computed: {
           filteredIstruzioniValidita() {
                return this.istruzioni.filter((i) => {
                    return (i.validita === null) || (i.validita === 1) || (i.validita === "");
                })
            },
        }, 
        

        mounted() {
            this.getArticoli();
        },

        methods: {

            getArticoli() {
                this.overlay = true;
                let search_Articolo = {
                controller: "Magazzino",
                method: "POST",
                richiesta: {
                    action: "getarticoli",
                    token: localStorage.getItem("user_token"),
                    cod_articolo: "*",
                    id_famiglia:  "",
                    id_tipo:  "",
                    id_tipo_fornitura: "",
                },
                };
                this.$store.dispatch("api", search_Articolo).then((res) => {
                    this.overlay = false;
                    this.articoli = res.data.lista;

                });
            },

            cambiaSelezioneArticolo(){
            this.id_ciclo_selected= -1
            let Articolo_selezionato = this.alasql(
                "SELECT * FROM ? WHERE id_articolo = " + this.id_articolo_selected ,
                [this.articoli]
            );
            this.des_articolo_selected = Articolo_selezionato[0].des_articolo
            this.id_articolo_selected = Articolo_selezionato[0].id_articolo
            this.cod_articolo_selected = Articolo_selezionato[0].cod_articolo
            this.getCicli()
            },

            getCicli() {
                this.cicloLista = [];
                let request = {
                    controller: "Magazzino",
                    method: "POST",
                    richiesta: {
                    action: "getarticoliciclo",
                    token: localStorage.getItem("user_token"),
                    id_articolo: this.id_articolo_selected
                    }
                };
                this.$store.dispatch("api", request).then((res) => {
                    const data = res.data;
                    data.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
                    this.cicloLista = data
                   // console.log("ciclo lista:", data)

                });
            },
            cambiaSelezioneCiclo(){
                let Ciclo_selezionato = this.alasql(
                    "SELECT * FROM ? WHERE id_ciclo_fasi = " + this.id_ciclo_selected ,
                    [this.cicloLista]
                );
              console.log("Ciclo Selezionato:",Ciclo_selezionato)
                this.getarticolicicloMacchina()
            },

            getarticolicicloMacchina() {
            let getarticoliciclofornitori = {
                controller: "Magazzino",
                method: "POST",
                richiesta: {
                action: "getarticoliciclomacchina",
                token: localStorage.getItem("user_token"),
                id_ciclo_fasi: this.id_ciclo_selected,
                id_articolo: this.id_articolo_selected,
                },
            };
            this.$store.dispatch("api", getarticoliciclofornitori).then((res) => {
                this.articolo_ciclo_fasi_macchina = res.data;
                 //console.log("Macchina:",res.data)
            });
            },            


            //Sezione Istruzioni di Lavoro

            getIstruzioniLavoro(id) {
              this.descrizione_selected= "",
              this.des_nota_lavoro= "",
              this.id_istruzione_selected = "", 
              this.des_lavoro = ""
              this.nota_Lavoro_selected= ""
              this.data_modifica_istruzione = ""  
              this.utente_ultimo_salvataggio = ""
              this.istruzioni =[]
              this.idciclo_fasi_macchina = id
              this.id_ciclo_fasi_macchina_selected = this.idciclo_fasi_macchina
              let request = {
                controller: "produzione",
                method: "POST",
                richiesta: {
                  action: "getistruzionilavoro",
                  token: localStorage.getItem("user_token"),
                  idciclo_fasi_macchina: id,
                },
              };
              //console.log("Request:",request)
                this.$store.dispatch("api", request).then((res) => {
                    if (res.status == 200){
                        if (res.data.istruzioni_lavoro.length > 0){
                            this.id_istruzione_selected = res.data.istruzioni_lavoro[0].id_istruzione
                            this.des_lavoro = this.descrizione_selected = res.data.istruzioni_lavoro[0].descrizione
                            this.des_nota_lavoro = this.nota_Lavoro_selected= res.data.istruzioni_lavoro[0].nota_Lavoro
                            this.data_modifica_istruzione = this.data_modifica_istruzione_selected = res.data.istruzioni_lavoro[0].data_modifica_istruzione
                            this.id_temp = res.data.istruzioni_lavoro[0].id_temp //id_temp della testa che coincide con id_istruzione
                            this.utente_ultimo_salvataggio = res.data.istruzioni_lavoro[0].nominativo
                            res.data.istruzioni_lavoro[0].istruzioni_lavoro_dettaglio.forEach((elem) => {
                            this.istruzioni.push ( Object.assign({}, elem))
                        });
                        }else{ 
                            this.id_temp =  moment().valueOf()
                            this.id_istruzione_selected = -1, // Se nuova istruzione genero un id_casuale per riferimento ai dettagli successivi
                            this.descrizione_selected = ""
                            this.nota_Lavoro_selected= ""
                            this.data_modifica_istruzione_selected = ""  
                            this.istruzioni = []                        
                        }
                    }
                   
                });
              },

            istruzionilavoro(item) {
              this.macchina_selezionata = this.articolo_ciclo_fasi_macchina[0].des_macchina
              this.fase_selected = this.cicloLista[0].cod_fase + " - " + this.cicloLista[0].des
              this.getIstruzioniLavoro(item.idciclo_fasi_macchina); 
              this.idciclo_fasi_macchina = item.idciclo_fasi_macchina
              this.id_macchina_selected = item.id_macchina
              this.id_articolo_selected = item.id_articolo
             // console.log ("idciclo_fasi_macchina", item.idciclo_fasi_macchina)
              this.dialogIstruzioniLavoro = true;
            },

            //istruzione lavoro dettaglio
            nuova_istruzione_lavoro(){
              this.istruzioni.push ({  
                    id_istruzione_dettaglio:-1,
                    id_istruzione : -1,
                    fr_etichetta: "",
                    fr_valore: "",
                    mon: "",
                    grup: "",
                    n_car: "",
                    ut_corr: "",
                    lavorazione: "",
                    des_lavorazione: "",
                    inserto: "",
                    durata: "",
                    id_temp_padre: this.id_temp,
                    id_temp: moment().valueOf(),
                    validita: 1,
                  });
            },  
            deleteItem(associazione) {
                let index = this.istruzioni.findIndex(item => item.id_istruzione_dettaglio === associazione.id_istruzione_dettaglio)

                if (this.istruzioni[index].id_istruzione_dettaglio != -1){
                    this.istruzioni[index].validita = 0 
                }else{
                    this.istruzioni.splice(index, 1)
                }
            },    
            //edit non serve perchè modifico in linea e mantengo i valori caricati

            salvaIstruzioniLavoroMacchima(){
                        let request = {
                            controller: "produzione",
                            method: "PUT",
                            richiesta: {
                                action: "setIstruzioni",
                                token: localStorage.getItem("user_token"),
                                istruzioni_lavoro : [
                                    {
                                        id_istruzione: this.id_istruzione_selected,
                                        idciclo_fasi_macchina: this.id_ciclo_fasi_macchina_selected,
                                        id_articolo: this.id_articolo_selected,
                                        id_macchina: this.id_macchina_selected,
                                        Descrizione: this.des_lavoro,
                                        Nota_Lavoro: this.des_nota_lavoro,
                                        id_temp: this.id_temp,
                                        istruzioni_lavoro_dettaglio: this.istruzioni,
                                    }
                                ]
                            },
                        }
                        this.$store.dispatch("api", request).then((res) => { 
                                    if (res.status == 200){
                                      this.dialogIstruzioniLavoro = false
                                    }      
                            });
            },

            chiudi_istruzioni(){
              this.dialogIstruzioniLavoro = false
            },

            gestione_header(){
              if(!this.switch_fresa) { 
                this.headers_codici = [
                  { text: 'MON', value: 'mon' , sortable: false },
                  { text: 'GRUP', value: 'grup' , sortable: false },
                  { text: 'N CAR', value: 'n_car' , sortable: false },
                  { text: 'UT. CORR.', value: 'ut_corr' , sortable: false },
                  { text: 'TIPO LAVORAZIONE', value: 'lavorazione' , sortable: false, width: "20%" },
                  { text: 'DES LAVORAZIONE', value: 'des_lavorazione' , sortable: false, width: "30%"},
                  { text: 'INSERTO', value: 'inserto' , sortable: false },
                  { text: 'DURATA', value: 'durata' , sortable: false },
                  { text: '', value: 'actions',  width: "8%", sortable: false },
                ]
              }else{
                this.headers_codici = [
                  { text: 'Fresa Etichetta', value: 'fr_etichetta', sortable: false },
                  { text: 'Fresa Valore', value: 'fr_valore', sortable: false },
                  { text: '', value: 'actions',  width: "8%", sortable: false },
                ]

              }

            },

            searchArticolo() {

                this.overlay = true;
                let search_Articolo = {
                controller: "Magazzino",
                method: "POST",
                richiesta: {
                    action: "getarticoli",
                    token: localStorage.getItem("user_token"),
                    cod_articolo: this.cod_articolo,
                    id_famiglia:  "",
                    id_tipo:  "",
                    id_tipo_fornitura: "",
                },
                };
                this.$store.dispatch("api", search_Articolo).then((res) => {
                    this.overlay = false;
                    this.articoli = res.data.lista;

                    if (this.articoli.length === 0) {
                        this.snackbar = true;
                        this.snackbar_background = "orange";
                        this.snackbar_icon = "mdi-alert";
                        this.snackbar_icon_color = "white";
                        this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
                        this.snackbar_text_color = "white";
                    }
                });
            },

        }

    }
</script>