<template>
  <div class="mainDash">
    <v-container id="app">
      <div style="position: absolute; top: 10px; right: 20px">
        v.{{ this.$store.state.versione }}
      </div>
      <svg id="icons">
        <symbol id="gestionesintetica" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M16.82,15.19L12.71,11.08C13.12,10.04 12.89,8.82 12.03,7.97C11.13,7.06 9.78,6.88 8.69,7.38L10.63,9.32L9.28,10.68L7.29,8.73C6.75,9.82 7,11.17 7.88,12.08C8.74,12.94 9.96,13.16 11,12.76L15.11,16.86C15.29,17.05 15.56,17.05 15.74,16.86L16.78,15.83C17,15.65 17,15.33 16.82,15.19Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="barche" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M3 13.5L11 2.03V13.5H3M12.5 13.5C13.85 9.75 13.67 4.71 12.5 1C17.26 2.54 20.9 8.4 20.96 13.5H12.5M21.1 17.08C20.69 17.72 20.21 18.27 19.65 18.74C19 18.45 18.42 18 17.96 17.5C16.47 19.43 13.46 19.43 11.97 17.5C10.5 19.43 7.47 19.43 6 17.5C5.5 18 4.95 18.45 4.3 18.74C3.16 17.8 2.3 16.46 2 15H21.94C21.78 15.75 21.5 16.44 21.1 17.08M20.96 23C19.9 23 18.9 22.75 17.96 22.25C16.12 23.25 13.81 23.25 11.97 22.25C10.13 23.25 7.82 23.25 6 22.25C4.77 22.94 3.36 23.05 2 23V21C3.41 21.05 4.77 20.9 6 20C7.74 21.25 10.21 21.25 11.97 20C13.74 21.25 16.2 21.25 17.96 20C19.17 20.9 20.54 21.05 21.94 21V23H20.96Z" />
            </svg>
          </g>
        </symbol>
        <symbol id="anagrafiche" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
            </svg>
          </g>
        </symbol>
        <symbol id="diario" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M16,4L9,8.04V15.96L16,20L23,15.96V8.04M16,6.31L19.8,8.5L16,10.69L12.21,8.5M0,7V9H7V7M11,10.11L15,12.42V17.11L11,14.81M21,10.11V14.81L17,17.11V12.42M2,11V13H7V11M4,15V17H7V15" />
            </svg>
          </g>
        </symbol>
        <symbol id="listabarche" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z" />
            </svg>
          </g>
        </symbol>
      </svg>
      <Radial_Menu
        ref="radialMenu"
        @clicked="menuClicked"
        @touchend="menuClicked"
        :menu-items="menuItems"
        :size="altezza - 55"
      ></Radial_Menu>
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        v-model="snackbar"
        :timeout="3000"
      >
        <v-icon :size="64">{{ snackbar_icon }}</v-icon>
        <h2 class="ml-16">{{ snackbar_text }}</h2>

        <v-btn
          @click="snackbar = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import RadialMenu from "./NavalRepairDashComp/ManutenzioneRadialMenu.vue";

export default {
  name: "app",
  data: function () {
    return {
      // Variabili d'appoggio
      altezza: 0,

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_text: "",

      // Array
      menuItems: [],
    };
  },
  computed: {
    id_profilo() {
      return localStorage.id_profilo;
    },
  },
  methods: {
    isModuloAutorizzato(id_modulo_menu) {
      let menu_presente = false;
      this.$store.state.menu_sx.forEach((elem) => {
        if (elem.id_modulo_menu === id_modulo_menu) {
          menu_presente = true;
        }
      });

      if (menu_presente === true) {
        return true;
      } else {
        return false;
      }
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
    menuClicked: function (menuItem) {
      this.$router.push(menuItem.path);
    },
    openMenu: function () {
      this.$refs.radialMenu.open();
    },
    closeMenu: function () {
      this.$refs.radialMenu.close();
    },
  },
  created() {
    // Popolo il radial menu.
    let menuArr = JSON.parse(localStorage.menu);

    for (let index = 0; index < menuArr.length; index++) {
      if (menuArr[index].text != "Manutenzione") {
        const element = menuArr[index];
        let strval = "";

        const newEl = {};

        newEl.id = element.des;
        strval = element.text.toLowerCase();

        newEl.title = strval.charAt(0).toUpperCase() + strval.slice(1);

        newEl.title.charAt(0).toUpperCase();

        newEl.icon = `#${element.des}`.toLowerCase().replaceAll(" ", "");

        newEl.path = element.path;
        if (newEl.id != "Naval Repair") {
          this.menuItems.push(newEl);
        }
      }
    }

    // if (localStorage.id_profilo != 3 && localStorage.id_profilo != 4) {
    //   this.menuItems = [
    //     {
    //       id: "Richieste",
    //       title: "Richieste",
    //       icon: "#richieste",
    //     },
    //     {
    //       id: "Interventi",
    //       title: "Gestione Intervento",
    //       icon: "#interventi",
    //     },
    //     {
    //       id: "Regole",
    //       title: "Regole",
    //       icon: "#regole",
    //     },
    //     {
    //       id: "Attivita",
    //       title: "Attività",
    //       icon: "#attivita",
    //     },
    //     // {
    //     //   id: "ManutenzioneMacchinari",
    //     //   title: "Gestione Macchinari",
    //     //   icon: "#manutenzionemacchinari",
    //     // },
    //     {
    //       id: "Anagrafiche",
    //       title: "Anagrafiche",
    //       icon: "#anagrafiche",
    //     },
    //     {
    //       id: "GestioneSediFornitori",
    //       title: "Gestione Luoghi d'Intervento/ Fornitori",
    //       icon: "#gestionesedifornitori",
    //     },
    //     {
    //       id: "ManutenzioneRecursiva",
    //       title: "Manutenzione Recursiva",
    //       icon: "#manutenzionerecursiva",
    //     },
    //   ];
    //   // } else {
    //   //   this.menuItems = [
    //   //     {
    //   //       id: "Interventi",
    //   //       title: "Gestione Intervento",
    //   //       icon: "#interventi",
    //   //     },
    //   //     {
    //   //       id: "Attivita",
    //   //       title: "Attività",
    //   //       icon: "#attivita",
    //   //     },
    //   //     {
    //   //       id: "Anagrafiche",
    //   //       title: "Anagrafiche",
    //   //       icon: "#anagrafiche",
    //   //     },
    //   //     {
    //   //       id: "GestioneSediFornitori",
    //   //       title: "Gestione Luoghi d'Intervento/ Fornitori",
    //   //       icon: "#gestionesedifornitori",
    //   //     },
    //   //   ];
    // }
  },
  mounted() {
    let that = this;

    this.altezza = window.innerHeight;

    window.addEventListener("resize", function () {
      that.altezza = window.innerHeight;
    });
  },
  components: {
    Radial_Menu: RadialMenu,
  },
  watch: {},
};
</script>

<style scoped>
.allegati {
  width: 1rem !important;
  height: 1rem !important;
}

.icons {
  width: 15rem !important;
  height: 5rem !important;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

#icons {
  display: none;
  size: 16px;
}

#app {
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: white;
}

div.info {
  margin: 15px 0;
}

div.info * {
  color: white;
  text-shadow: 1px 1px 0 #000000d0;
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.mainDash {
  width: 100% !important;
  background-image: url("../../assets/Ind.jpg");

  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100% !important; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden !important; /* Resize the background image to cover the entire container */
}
svg #shadow {
  transition: all 0.65s;
  opacity: 1;
}
svg:hover #shadow {
  opacity: 0;
}
symbol:hover {
  color: aliceblue;
  box-shadow: 1000px;
}
</style>
