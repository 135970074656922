<template>
  <div style="padding: 20px">
    <v-hover>
      <template v-slot="{ hover }">
        <v-card
          :elevation="hover ? 24 : 2"
        >
          <v-toolbar dense class="indigo darken-1 headline" primary-title>
            <v-toolbar-title class="white--text">Prelievo</v-toolbar-title>
             <v-spacer></v-spacer>
       <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
                  <v-icon color="error">mdi-keyboard-backspace</v-icon>
                </v-btn>
              </template>
              <span>Vai alla Dashboard</span>
            </v-tooltip>
          </v-toolbar>
          <v-container fluid>
            <v-row class="ml-1">
              <v-col md="1">
                <v-text-field
                  type="text"
                  class="input-class"
                  v-model="codice"
                  label="Codice"
                  placeholder="Codice"
                  required
                ></v-text-field>
              </v-col>

              <!--<v-col md="3">
          <v-btn
          tile
            class="mt-4" color="primary">
             <v-icon  size="24"  >
           mdi-magnify
           </v-icon>
          </v-btn>

          <v-btn
          tile
            class="mt-4 ml-4" color="primary">
             <v-icon  size="24"  >
           mdi-file-pdf
           </v-icon>
          </v-btn>
             <v-btn
          tile
            class="mt-4 ml-2" color="primary">
             <v-icon  size="24"  >
           mdi-printer
           </v-icon>
          </v-btn>

    </v-col>-->
              <v-col md="2" class="ml-4">
                <p class="ml=n12">
                  K49.3199.2[PRODUZIONE GERNERICA] LOTTO[20/F565] 8000 PEZZI
                  TOTALI
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-data-table
                  :hide-default-footer="true"
                  :items="items"
                  :headers="headers"
                >
                  <template v-slot:item.edit="">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-col v-on="on" v-bind="attrs">
                          <PrelievoModal />
                        </v-col>
                      </template>

                      <span>Dettaglio</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.dettagli="{}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-col class="ml-2" v-on="on" v-bind="attrs" md="4">
                          <v-icon
                            md="4"
                            style="float: left"
                            color="#c00000"
                            @click="handleClick(item)"
                            >mdi-window-close</v-icon
                          >
                        </v-col>
                      </template>
                      <span>Rimuovere Macchina </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
              <v-col md="2">
                <v-switch
                  v-model="componente_extra"
                  label="Componente Extra"
                  class="pa-3"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="mt-n8 ml-1">
              <v-col class="d-flex" cols="4" md="1">
                <v-text-field
                  clearable
                  type="text"
                  class="input-class"
                  v-model="cod_lotto"
                  placeholder="Cod. Lotto"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="4" md="1">
                <v-text-field
                  clearable
                  type="text"
                  class="input-class"
                  v-model="numero_pezzi"
                  placeholder="Nº Pezzi"
                  required
                >
                </v-text-field>
              </v-col>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    color="#1a80b6"
                    v-on="on"
                    v-bind="attrs"
                    dark
                    @click="onButtonClick(props.item)"
                    >mdi-plus</v-icon
                  >
                </template>
                <span>Aggungi Componente</span>
              </v-tooltip>
              <v-col md="12">
                <v-data-table
                  :hide-default-footer="true"
                  :items="items1"
                  :headers="headers1"
                >
                  <template v-slot:item.reso="props">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          v-on="on"
                          v-bind="attrs"
                          tile
                          color="primary"
                          @click="onButtonClick(props.item)"
                        >
                          <v-icon dark>mdi-restore</v-icon>
                        </v-btn>
                      </template>
                      <span>Dettagli</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
import PrelievoModal from "./PrelievoDetailModal";
export default {
  components: {
    PrelievoModal,
  },
  data() {
    return {
      cod_lotto: "",
      codice: "",
      numero_pezzi: "",
      componente_extra: 0,
      headers: [
        {
          value: "edit",
          sortable: false,
        },
        {
          text: "Coeff.",
          value: "coeff",
          sortable: false,
        },
        {
          text: "Cod. Componente",
          value: "cod_componente",
          sortable: false,
        },
        {
          text: "Des. Componente",
          value: "des_componente",
          sortable: false,
        },
        {
          text: "Q.ta in Produzione",
          value: "qty_prod",
          sortable: false,
        },
        {
          text: "Q.ta Fabbisogno",
          value: "qty_fabbisogno",
          sortable: false,
        },
        {
          text: "Q.ta Realizzabile",
          value: "qty_realizzabile",
          sortable: false,
        },
        {
          text: "",
          value: "dettagli",
        },
      ],
      headers1: [
        {
          text: "Rimuovi",
          value: "rimuovi",
          sortable: false,
        },
        {
          text: "Lotto",
          value: "lotto",
          sortable: false,
        },
        {
          text: "Componente",
          value: "componente",
          sortable: false,
        },
        {
          text: "Q.ta Impegnata",
          value: "qty_imp",
          sortable: false,
        },
        {
          text: "Q.ta Prelevata",
          value: "qty_prelevata",
          sortable: false,
        },
        {
          text: "Ubicazione",
          value: "ubicazione",
          sortable: false,
        },
        {
          text: "Magazzino",
          value: "magazzino",
          sortable: false,
        },
        {
          text: "Reso",
          value: "reso",
        },
      ],
      items: [
        {
          coeff: 4,
          cod_componente: "49.93.0021",
          des_componente: "FERMAGIOCIRCULUDIAM,8",
          qty_prod: 32000.0,
          qty_fabbisogno: 0.0,
          qty_realizzabile: 7000.0,
        },
      ],
      items1: [
        {
          lotto: "20VA442",
          componente: "49.93.0021",
          qty_imp: "32000.00",
          qty_prelevata: "8,000.00",
          ubicazione: "D01-1",
          magazzino: "Magazzino Sede",
        },
      ],
    };
  },
  methods:{
         gotoDashboard() {
      this.$router.push("/home/production");
    },
  }
};
</script>

<style css scoped>
</style>
