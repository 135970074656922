import CHART_COMMON from "./_common.js";
class common_data{
    rankIndexes = []
    common = null
}
class TABLE{
    rows  = {
        _parent : null, 
        _span : 0,
        _dataset : {},
        _macro : []
    }
    columns = {
        _parent : null,
        _id : 0,
    }
    col_id_count = 0
    COMMON_DATA = null
    constructor(common){this.COMMON_DATA = common;}

    _updateCount(element, maxspan = 0){        
        let element_span = 0;
        let keys = Object.keys(element);
        for(let i = 0; i< keys.length; i++){
            if(!this._iskey(keys[i])){
                let child_span = element[keys[i]]._span;
                if(!isNaN(child_span)){
                    element_span+= Number(child_span);
                }                
            }
        }
        element._span = Math.max(maxspan, element_span, 1);
        if(element._macro != undefined){
            element._span += element._macro.length;
        }    
        if(element._parent != null){
            this._updateCount(element._parent);
        }
        
    }
    _iskey(property){
        let keys = ['_id','_span','_parent','_pivot','_dataset','_name','_style','_macro','_cod','_hidden','_coords'];
        return keys.indexOf(property) >= 0;
    }
    
    addColumn(data,ref_col = this.columns,cod = null, plain = false, hidden = false){
        let name = (plain)?data:data.formatted;
        if(this._iskey(name)){name = name.toUpperCase();}

        if(ref_col[name]==undefined){
            this.col_id_count++;           
            ref_col[name] = {
                _id : this.col_id_count,
                _span : 1,
                _pivot : undefined,
                _parent : ref_col,
                _name : name,
                _coords : data.coords,
                _style : (plain)?null:data.itemStyle.table,
                _cod : cod,
                _hidden : hidden
            }            
        }
        this._updateCount(ref_col);
        return ref_col[name];
    }

    addCell(data,ref_cell = this.rows, hidden = false){

        let name = data.formatted;
        if(this._iskey(name)){name = name.toUpperCase();}

        if(ref_cell[name]==undefined){          
            ref_cell[name] = {
                _span : 1,
                _parent : ref_cell,
                _dataset : {},
                _name : name,
                _style : data.itemStyle.table,
                _coords : data.coords,
                _hidden : hidden,
                _macro : [],
            }
            ref_cell._macro = data.macro;            
        }
        this._updateCount(ref_cell);
        return ref_cell[name];
    }

    addDatum(datum, ref_cell, ref_col){ 
        let id = ref_col._id.toString();
        let dataset = ref_cell._dataset;  
        if(dataset[id]==undefined){dataset[id] = [];}
        dataset[id].push(datum);
        let span = dataset[id].length;
        this._updateCount(ref_cell,span);
    }

    col_sort(col_a, col_b){
        if(col_a._id < col_b._id){
            return -1;
        }else if(col_a._id > col_b._id){
            return 1;
        }else{
            return 0;
        }
    }

    build(){
        let table_ref = this;
        // Creazione array colonne
        let columns = [];
        let col_fields = Object.keys(this.columns);
        for(let i=0; i < col_fields.length; i++){
            if(!this._iskey(col_fields[i])){
                columns.push(this.columns[col_fields[i]]);
            }
        }
        // L'ordine di colonne viene ripristinato secondo l'ordine di arrivo dei dati
        columns.sort(this.col_sort);

        let head = {
            main_row : document.createElement('tr'),
            pivot_rows : [],
            pivot_title_span : 0,
            map : [],
            cod_map : []
        }

        // Costruzione della testata
        function buildPivot(obj,index=0){
            if(obj._pivot === undefined){
                let th = document.createElement('th');                
                th.setAttribute('data-col-id',obj._id);
                head.cod_map.push(obj._cod);
                head.main_row.appendChild(th);
                if(obj._hidden == true){
                    th.style.borderRightColor = 'transparent';
                }else{
                    th.innerText = obj._name;
                }
                return;
            }
            if(head.pivot_rows[index] == undefined){
                head.pivot_rows[index] = document.createElement('tr');
                if(head.pivot_title_span > 0){
                    let header = document.createElement('th');
                    header.innerText = obj._pivot;
                    header.setAttribute('colspan',head.pivot_title_span);
                    head.pivot_rows[index].appendChild(header);
                }
            }
            let th = document.createElement('th');
            th.innerText = obj._name;
            th.setAttribute('style',obj._style);
            th.setAttribute('colspan',obj._span);
            th.setAttribute('name','datum');
            //th.itemCoords = obj._coords;
            head.pivot_rows[index].appendChild(th);
            let keys = Object.keys(obj);
            index++;
            for(let i=0; i < keys.length; i++){
                if(!table_ref._iskey(keys[i])){
                    buildPivot(obj[keys[i]],index);
                }
            }
        }

        for(let i=0; i < columns.length; i++){
            if(columns[i]._pivot === undefined){
                head.pivot_title_span++;
                let th = document.createElement('th');
                if(columns[i]._hidden == true){
                    th.style.borderRightColor = 'transparent';
                }else{
                    th.innerText = columns[i]._name;
                }
                th.setAttribute('data-col-id',columns[i]._id); // !1
                head.cod_map.push(columns[i]._cod);
                head.main_row.appendChild(th);
            }else{
                buildPivot(columns[i]);
            }
        }

        if(head.pivot_rows.length <= 0){
            head.pivot_title_span = this.COMMON_DATA.rankIndexes.indexOf(3);
        }

        // definizione delle testate da non mostrare come fisse nello scroll del pivot
        for(let i= head.pivot_title_span; i < head.cod_map.length;i++){
            let column = head.main_row.children[i];
            if(column != undefined){
                column.setAttribute('name','datum');
            }            
        }
   
        let table = document.createElement('table');
        for(let i=0; i < head.pivot_rows.length;i++){table.appendChild(head.pivot_rows[i]);}
        table.appendChild(head.main_row);
        for(let i = 0; i < head.main_row.children.length; i++){
            let col_id = Number(head.main_row.children[i].getAttribute('data-col-id')); // !1
            head.map.push(col_id);
        }

        let body = {
            map : [],
            stops : {}
        }

        function buildEmptyRows(total_span){
            let rows = []
            for(let i=0;i<total_span;i++){
                let tr = document.createElement('tr');
                tr.style.display = 'none';
                rows.push(tr);
            }
            return rows;
        }

        function buildDataset(obj, rows, toprow){
            let v_span = obj._span;
            let dataset = obj._dataset;

            for(let i=0; i < v_span; i++){
                let data_row_map = [];
                for(let j= head.pivot_title_span; j < head.map.length; j++){
                    let td = document.createElement('td');
                    td.setAttribute('name','datum');
                    let target;
                    try{
                        target = dataset[head.map[j].toString()][i];
                        if(target == undefined){throw 'undef';}
                        td.innerHTML = target.formatted;
                        td.itemCoords = target.coords;
                        td.setAttribute('data-coords',JSON.stringify(target.coords));
                        //td.setAttribute('data-value',target.value);
                        data_row_map.push(target.value);
                        try{
                            td.setAttribute('style',target.itemStyle.table);                                                        
                        }catch(error){
                            target = undefined;
                        }                        
                    }catch(error){
                        td.setAttribute('style','background-color:#e6e6e6');
                        //td.setAttribute('data-undefined','true');
                        data_row_map.push(undefined);
                    }
                    rows[toprow+i].appendChild(td);                    
                }
                body.map.push(data_row_map);
            }
        }

        // Operazioni di confronto numerico
        let ops = [
            function(item,test){return (item < test);},
            function(item,test){return (item <= test);},
            function(item,test){return (item == test);},
            function(item,test){return (item >= test);},
            function(item,test){return (item > test);},
            function(item,test){return (item != test);}
        ];

        function fillRows(obj, rows, toprow = 0, col_index = 0){          

            if(obj._parent !=null){
                let td = document.createElement('td');
                table.is_aggregated |= obj._span > 1;
                td.setAttribute('rowspan',obj._span);
                if(obj._span <= 1){
                    td.itemCoords = {cod: obj._coords.cod, index : -1, value: obj._coords.value};
                }
                td.setAttribute('style',obj._style);
                if(obj._hidden != true){
                    td.innerText = obj._name;
                }
                rows[toprow].appendChild(td);                
            }
            // PREPARAZIONE CELLE MACRO IN TESTA
            for(let i=0; i < obj._macro.length; i++){
                if(obj._macro[i].targets == undefined){continue;}
                if(obj._macro[i].up != true){continue;}
                obj._macro[i].referenceRow = rows[toprow];
                toprow++;
            }

            if(obj._dataset == undefined){obj._dataset = {};}
            let keys = Object.keys(obj._dataset);
            if(keys.length > 0){
                buildDataset(obj,rows,toprow);
            }else{
                keys = Object.keys(obj);
                col_index++;
                for(let i=0;i<keys.length;i++){
                    if(table_ref._iskey(keys[i])){continue;}
                    let child = obj[keys[i]];
                    fillRows(child,rows,toprow,col_index);
                    toprow += child._span;
                }
            }

            // MACRO
            let offset = head.pivot_title_span;
            let macro_index = (offset+1)-col_index;
            if(body.stops[macro_index] == undefined){body.stops[macro_index] = {};}
            let stops = body.stops[macro_index];

            for(let i=0; i < obj._macro.length; i++){
                let macro = obj._macro[i];
                if(macro.targets == undefined){continue;}
                let macro_td = document.createElement('td');
                let row;
                if(macro.up == true){
                    row = macro.referenceRow;
                }else{
                    row = rows[toprow];
                    toprow++;
                }                  
                if(stops[i] == undefined){stops[i]=-1;}
                if(macro.use_parent==true){
                    macro_td.innerText = obj._name;
                }else{
                    macro_td.innerText = macro.text;
                }                
                macro_td.setAttribute('style',macro.compiled_style);
                macro_td.setAttribute('colspan',macro_index);
                row.appendChild(macro_td);

                // Riferimenti valori calcolati colonne
                let reference = {};
                let targets = Object.keys(macro.targets);                
                for(let j=0; j < targets.length; j++){
                    reference[targets[j]] = {
                        sum : 0,
                        count : 0
                    }
                }
                // Regole formattazione numerica                
                for(let j=head.pivot_title_span;j<head.map.length;j++){
                    let td = document.createElement('td');
                    td.setAttribute('name','datum');

                    let target = macro.targets[head.cod_map[j]];
                    let number_rule = (target.number==undefined)?{}:target.number;
                    // Target non esistente
                    if(target == undefined){
                        td.setAttribute('style','background-color:#e6e6e6');
                        row.appendChild(td);
                        continue;
                    }
                    
                    // Target non abilitato
                    if(target.enabled != true){
                        td.setAttribute('style','background-color:#e6e6e6');
                        row.appendChild(td);
                        continue;
                    }else{
                        let count = 0;
                        let sum = 0;
                        for(let k = body.map.length - 1; k > stops[i]; k--){
                            let datum = body.map[k][j-offset];
                            if(datum == undefined){continue;}
                            count++;
                            sum += datum;
                        }
                        // Aggiornamento riferimento
                        reference[head.cod_map[j]] = {
                            count : count,
                            sum : sum
                        }
                        // Calcolo dell'espressione                       
                        let formula = target.formula.replaceAll(' ','');
                        for(let k = 0; k < targets.length; k++){
                            formula = formula.replaceAll('C{'+targets[k]+'}',reference[targets[k]].count);
                            formula = formula.replaceAll('S{'+targets[k]+'}',reference[targets[k]].sum);
                        }
                        let value = 0;
                        try{
                            value = eval(formula);
                        }catch(error){
                            value = 0;
                        }
                        // Calcolo della formattazione
                        let style = '';
                        let formatter = '{valore}';
                        for(let k = 0; k < target.rules.length; k++){
                            let rule = target.rules[k];                            
                            if(rule.static == true){ // Regola statica
                                style = rule.compiled_style;
                                formatter = (rule.formatter==undefined)?formatter:rule.formatter;
                                break;
                            }else if(rule.range == true){ // Range
                                if( value >= Number(rule.start_ref) && value <= Number(rule.end_ref)){
                                    style = rule.compiled_style;
                                    formatter = (rule.formatter==undefined)?formatter:rule.formatter;
                                    break;
                                }
                            }else{ // Confronto
                                if(ops[rule.operation](value,Number(rule.start_ref))==true){
                                    style = rule.compiled_style;
                                    formatter = (rule.formatter==undefined)?formatter:rule.formatter;
                                    break;
                                }
                            }
                        }  
                        formatter = isFinite(value)?formatter:'';                                              
                        // Formattazione numerica                        
                        if(number_rule.divider==true){
                            value = table_ref.COMMON.dotNumber(value,number_rule.limit,number_rule.amount);
                        }else if(number_rule.limit == true){
                            value = value.toFixed(number_rule.amount).replaceAll('.',',');
                        }else{
                            value = value.toString().replaceAll('.',',');
                        }
                        // Creazione della cella
                        td.innerText = formatter.replaceAll('{valore}',value);                        
                        td.setAttribute('style',style);
                        row.appendChild(td);
                    }
                }
                stops[i] = body.map.length - 1;                
            }            
        }

        let rows = buildEmptyRows(this.rows._span);
        fillRows(this.rows,rows);
        for(let i = 0; i < rows.length; i++){table.appendChild(rows[i]);}
        
        table.setAttribute('class','graph_table graph_render_target');
        return table;
    }
}
class CHART_TABLE {
    COMMON = new CHART_COMMON();
    COMMON_DATA = new common_data();
    TABLE = new TABLE(this.COMMON_DATA);
    _target = null;
    _setlistener = null;

    constructor(target, listener){
        this._target = target;
        this.COMMON_DATA.common = this.COMMON;
        this._setlistener = listener;
    }

    setOptions(data){
        
        // Rimozione serie di formato
        let serie = this.COMMON.filterSerie(data.serie);

        // Ordinamento per rango
        this.COMMON_DATA.rankIndexes = [];
        this.TABLE.COMMON = this.COMMON;
        serie = this.rank(serie,this.COMMON_DATA.rankIndexes);
        
        this.cols = this.getField(serie,'nome');
        this.cods = this.getField(serie,'cod');
        this.COMMON_DATA.cods = this.cods;

        let processed = [];
        for(let i=0;i<serie.length;i++){
            // Decodifica dei parametri extra
            serie[i].extra = this.COMMON.getExtra(serie[i]);

            // Elaborazione della formattazione
            processed.push(this.COMMON.processSerie(serie[i]));
        }

        this.aggregate(this.transpose(processed));

        let table = this.TABLE.build();   
        let class_ref = this;

        requestAnimationFrame(function(){
            /*let clone = document.createElement('div');
            clone.setAttribute('class','table_clone');
            clone.appendChild(table.cloneNode(true));*/

            let div = document.createElement('div');
            div.setAttribute('class','graph_pivot_table graph_scrollbar_input');
            div.appendChild(table);
            /*div.appendChild(clone);

            div.addEventListener('scroll',function(){
                clone.style.left = div.scrollLeft + 'px';
            });*/
            
            class_ref._target.appendChild(div);          

            const rows = table.getElementsByTagName('tr');
            const r_length = rows.length;
            const title = data.nome;

            if(r_length < 100 || table.is_aggregated){
                for(let i = 0; i < r_length; i++){
                    rows[i].style.display = 'table-row';
                }
            }else{
                const d_rect = div.getBoundingClientRect();
                const bottom_line = d_rect.y + d_rect.height;
                let reference = 0,row, r_rect, last_reference;                

                for(let i = 0; i < r_length; i++){
                    row = rows[i];
                    if(row.style.display != 'none'){
                        row.is_head = true;
                    }else{
                        row.style.display = 'table-row';
                        r_rect = row.getBoundingClientRect();
                        if(r_rect.y + r_rect.height > bottom_line){
                            last_reference = i;
                            break;
                        }
                    }                        
                }
                data.nome = `${title} (${reference} - ${last_reference} di ${r_length-1})`;

                div.addEventListener('wheel',function(event){

                    event.preventDefault();
                    div.scrollTop = 0;
                    const d_rect = div.getBoundingClientRect();
                    const bottom_line = d_rect.y + d_rect.height;

                    if(event.deltaY > 0){                       
                        if(rows[r_length-1].style.display == 'none'){
                            let new_reference = Math.min(reference+3, r_length - 1);
                            for(let i = reference; i < r_length; i++){
                                row = rows[i];
                                if(row.is_head){
                                    row.style.display = 'table-row';
                                }else{
                                    if(i < new_reference){
                                        row.style.display = 'none';
                                    }else{
                                        row.style.display = 'table-row';
                                        r_rect = row.getBoundingClientRect();
                                        if(r_rect.y > bottom_line){ 
                                            row.style.display = 'none';
                                            last_reference = i;
                                            break; 
                                        }
                                    }
                                }                                
                            } 
                            reference = new_reference;                                   
                        }
                    }else if(event.deltaY < 0){
                        if(reference > 0){
                            let crossed = false;
                            let new_reference = Math.max(reference-3, 0);
                            for(let i = new_reference; i < r_length; i++){
                                row = rows[i];
                                if(row.is_head){
                                    row.style.display = 'table-row';
                                }else{
                                    if(i < reference){
                                        row.style.display = 'table-row';
                                    }else{
                                        if(crossed){
                                            if(row.style.display == 'none'){
                                                last_reference = i;
                                                break;
                                            }else{
                                                row.style.display = 'none';
                                            }
                                        }else{
                                            row.style.display = 'table-row';
                                            r_rect = row.getBoundingClientRect();
                                            if(r_rect.y > bottom_line){ 
                                                row.style.display = 'none';
                                                crossed = true; 
                                            }
                                        }                                            
                                    }
                                }                                
                            }
                            reference = new_reference;
                        }else{
                            reference = 0;
                        }
                    } 
                    data.nome = `${title} (${reference} - ${last_reference} di ${r_length-1})`;
                });
            }       
            
        })
        
        this._setlistener(function(event){
            const path = event.composedPath();            
            for(let i = 0; i < path.length; i++){
                if(path[i].tagName.toLowerCase() == 'body'){ return; }
                if(path[i].itemCoords != undefined){
                    return path[i].itemCoords;
                }
            }
            return null;
        });

        return null;
    }

    // Aggregazione gerarchica dei dati
    aggregate(data){  
        for(let i=0; i < data.length; i++){
            let ref_cell = this.TABLE.rows;
            let ref_col = this.TABLE.columns;
            for(let j=0;j<data[i].length; j++){
                switch(this.COMMON_DATA.rankIndexes[j]){
                    case 0:{
                        this.TABLE.addColumn(this.cols[j],ref_col,this.cods[j],true, true);
                        ref_cell = this.TABLE.addCell(data[i][j],ref_cell,true);                      
                    }
                    break;
                    case 1:{
                        this.TABLE.addColumn(this.cols[j],ref_col,this.cods[j],true);
                        ref_cell = this.TABLE.addCell(data[i][j],ref_cell);                      
                    }
                    break;
                    case 2:{
                        ref_col = this.TABLE.addColumn(data[i][j],ref_col);
                        ref_col._pivot = this.cols[j];
                    }
                    break;
                    case 3:{
                        for(; j < data[i].length; j++){
                            let col = this.TABLE.addColumn(this.cols[j],ref_col,this.cods[j],true);
                            this.TABLE.addDatum(data[i][j],ref_cell,col);
                        }
                    }
                    break;
                }
            }
        }
    }

    // Riordinamento delle serie in base al rango
    rank(series,rankIndexes){
        /* ranghi : 0 - serie aggregate, 1 - colonne pivot, 2 - serie non aggreagate */
        let ranks = [[],[],[],[]];
        for(let i=0; i < series.length; i++){
            switch(series[i].tipo_grafico){
                case 0:
                    ranks[0].push(series[i]);
                    break;
                case 1:
                    ranks[1].push(series[i]);
                    break;
                case 2:
                    ranks[2].push(series[i]);
                    break;
                case 3:
                default:
                    ranks[3].push(series[i]);                
                    break;
            }
        }
        let result = [];
        for(let i=0;i<ranks.length;i++){
            for(let j=0;j<ranks[i].length;j++){
                rankIndexes.push(i);
                result = result.concat(ranks[i][j]);
            }
        }
        return result;
    }

    // Recupero campi serie
    getField(series,field){
        let result = [];
        for(let i=0; i <series.length; i++){
            result.push(series[i][field]);
        }
        return result;
    }
    
    
    
    // Inversione righe / colonne
    transpose(data){
        if(data.length==0){return [[]];}
        if(data[0].length==0){return [[]];}
        let sorted = [];
        for(let j=0;j<data[0].length;j++){
            let item = [];
            for(let i=0;i<data.length;i++){
                item.push(data[i][j]);
            }
            sorted.push(item);
        }
        return sorted;
    }
}

export default CHART_TABLE;