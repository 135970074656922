<template>
  <div class="mt-n10 ml-n6" style="width: 100% !important">
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="display: block; height: 80vh !important; width: 90vw !important"
      ></iframe>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogDelete"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">
            Questo elemento verrà eliminato definitivamente
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="green" dark @click="deldisegno">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAllegatiErrore"
      width="400"
    >
      <v-card>
        <v-toolbar color="indigo darken-1" class="ModalColor" dense>
          <v-toolbar-title class="mt-n5 white--text"
            >Allegati Errore</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3>Il Tipo non è Accettato</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="clearImage"> Indietro</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogFirma"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">Firma Disegno</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Firmare il disegno selezionato?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" dark @click="dialogFirma = false">ANNULLA</v-btn>
          <v-btn color="green" dark @click="signdrawing">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      :scrollable="false"
      v-model="dialoginfo"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">
            Dettaglio delle azioni per questa pagina
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialoginfo = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-list disabled>
            <v-subheader>Azioni</v-subheader>
            <v-list-item-group v-model="item" color="primary">
              <v-list-item v-for="(item, i) in actionItems" :key="i">
                <v-list-item-icon>
                  <v-icon :color="item.color" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="text-align: left !important"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAllegati"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="mt-n5 white--text">Allegati</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogAllegati = false" small>
                <v-icon v-on="on" v-bind="attrs" color="#c00000" depressed
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image/pdf"
            label="File"
            prepend-icon="mdi-paperclip"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAddDisegno"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Disegno</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="slavaDesigno(item)" small>
                <v-icon v-on="on" v-bind="attrs" color="success"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="closeModal" small>
                <v-icon v-on="on" v-bind="attrs" color="#c00000" depressed
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-row class="py-0">
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                type="text"
                v-model="cod_alternativo"
                class="shrink"
                label="Codice Alternativo"
                placeholder="Codice Alt."
                required
                min="0"
                align="right"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2" class="py-0">
              <v-text-field
                type="number"
                label="Revisione"
                v-model="revisione_articolo"
                class="shrink"
                placeholder="Revisione Articolo"
                required
                min="0"
                align="right"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="2" class="py-0">
              <v-text-field
                type="text"
                v-model="indice_modifica"
                class="shrink"
                label="Indice"
                placeholder="Indice Modifica"
                required
                min="0"
                align="right"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="2" class="py-0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dum"
                    label="DUM"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dum"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col class="py-0 mt-4" cols="6" md="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on" @click="dialogAllegati = true">
                    <v-icon color="#1a80b6">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Nuovo Disegno</span>
              </v-tooltip>
            </v-col>
            <v-col class="py-0 mt-3" cols="6" md="1" v-if="disegno != ''">
              <!-- Card per le immagini -->
              <v-card v-if="!isPdf(disegno)" class="d-flex justify-center">
                <div v-viewer class="images clearfix d-flex align-items-center">
                  <img
                    id="immagine"
                    :src="disegno"
                    :lazy-src="disegno"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  />
                </div>
              </v-card>

              <!-- Card per i PDF -->
              <v-card
                @click="openPdfAppenaCaricato(disegno)"
                style="
                  height: 40px !important;

                  width: 80px !important;
                  margin-right: -10rem !important;
                "
                v-if="isPdf(disegno)"
              >
                <div style="text-align: center">
                  <v-spacer></v-spacer>
                  <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <form>
        <v-row>
          <v-col cols="12" md="8">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  style="width: 100%"
                  class="mr-4"
                  :elevation="hover ? 24 : 2"
                >
                  <v-toolbar color="indigo" dense>
                    <v-toolbar-title class="white--text"
                      >Disegno</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="handleclick(null)"
                          style=""
                          class="mr-5"
                          small
                        >
                          <v-icon
                            v-on="on"
                            v-bind="attrs"
                            style="color: #1a80b6"
                            >mdi-plus</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Aggiungi Disegno</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="dialoginfo = true"
                          v-on="on"
                          v-bind="attrs"
                          class="mr-5"
                          small
                        >
                          <v-icon color="primary">
                            mdi-information-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Dettaglio delle azioni</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-row>
                    <v-col md="12">
                      <v-data-table
                        :loading="loading"
                        :items="items"
                        :headers="headers"
                      >
                        <v-progress-linear
                          v-show="progressBar"
                          slot="progress"
                          color="blue"
                          indeterminate
                        ></v-progress-linear>
                        <template v-slot:[`item.firma`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                @click="signatureDisegno(item)"
                                small
                              >
                                <v-icon color="blue"
                                  >mdi-file-question-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Firma Disegno</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.edit`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="ml-n8"
                                v-on="on"
                                v-bind="attrs"
                                @click="handleclick(item)"
                                small
                              >
                                <v-icon color="#009999">mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Aggiornare</span>
                          </v-tooltip>
                        </template>

                        <template v-slot:[`item.revisione_articolo`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-on="on"
                                v-bind="attrs"
                                v-if="item.revisione_articolo >= 1"
                                >mdi-check-box-outline</v-icon
                              >
                              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                            </template>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.dum`]="{ item }">
                          {{ item.dum | formatDate }}
                        </template>

                        <template v-slot:[`item.disegno`]="{ item }">
                          <!-- Card per le immagini -->
                          <v-card
                            style="
                              height: 40px !important;

                              width: 80px !important;
                              margin-right: -10rem !important;
                            "
                            v-if="!isPdf(item.disegno)"
                          >
                            <div v-viewer class="images clearfix">
                              <img
                                style="
                                  height: 40px !important;
                                  display: block !important;

                                  width: 60px !important;
                                  margin-right: -10rem !important;
                                "
                                :src="item.disegno"
                                :lazy-src="item.disegno"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              />
                            </div>
                          </v-card>

                          <!-- Card per i PDF -->
                          <v-card
                            @click="openPdf(item)"
                            style="
                              height: 40px !important;

                              width: 80px !important;
                              margin-right: -10rem !important;
                            "
                            v-if="isPdf(item.disegno)"
                          >
                            <div style="text-align: center">
                              <v-spacer></v-spacer>
                              <v-icon color="error" x-large>
                                mdi-file-pdf-box
                              </v-icon>
                            </div>
                          </v-card>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="disegnoToDel(item)" small>
                                <v-icon v-on="on" v-bind="attrs" color="red">
                                  mdi-window-close
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Cancellare</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col cols="12" md="4">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 24 : 2"
                  style="width: 100vw !important"
                >
                  <v-toolbar color="indigo" dense>
                    <v-toolbar-title class="white--text"
                      >Specifiche Tecniche</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-row>
                    <v-col md="5" sm="4" class="ml-2">
                      <v-autocomplete
                        class="ml-1"
                        v-model="certificato_prodotto"
                        :items="certificato_prodotto_list"
                        placeholder="Certificato Prodotto"
                        required
                        item-text="des"
                        item-value="id_tabella"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col sm="4" md="3">
                      <v-text-field
                        type="text"
                        class="input-class"
                        v-model="data.timbro"
                        label="Codice Timbro"
                        placeholder="Codice Timbro"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col sm="3" md="3">
                      <v-text-field
                        type="text"
                        class="input-class"
                        v-model="data.prefisso_lotto"
                        label="Prefisso Lotto"
                        placeholder="Prefisso Lotto"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ml-2">
                    <v-col sm="3" md="4">
                      <v-checkbox
                        style="margin-top: -0.6rem"
                        v-model="data.gestione_lotto"
                        label="Gestione Lotti"
                      ></v-checkbox>
                    </v-col>
                    <v-col sm="3" md="4">
                      <v-checkbox
                        style="margin-top: -1rem"
                        v-model="data.prelievo_lotti_successivi"
                        label="Prelievo Lotti Successivi"
                      ></v-checkbox>
                    </v-col>
                    <v-col md="12">
                      <v-textarea
                        style="margin-left: -0.6rem"
                        outlined
                        v-model="data.specifiche"
                        label="Specifiche"
                        placeholder="Specifiche"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import panzoom from "panzoom";
export default {
  props: ["data"],

  components: {},

  data() {
    return {
      // Snackbar Variables//
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      pdfdialog: false,
      selectedPdf: "",
      //Delete confirmation dialog
      dialogDelete: false,
      deletedDisegno: {},
      selectedDisegno: "",
      singleItem: "",
      dialogAllegatiErrore: false,
      item: "",
      dum: new Date().toISOString().substr(0, 10),
      dialogAddDisegno: false,
      dialoginfo: false,
      dialogAllegati: false,
      dialogFirma: false,
      items: [],
      progressBar: false,
      loading: false,
      disegno: "",
      id_disegno: "",
      id_articolo: "",
      cod_alternativo: "",
      indice_modifica: "",
      menu1: false,
      revisione_articolo: "",
      certificato_prodotto: "",
      certificato_prodotto_list: [],

      allegato: [],
      // Table header for Disegno
      headers: [
        {
          text: "",
          value: "firma",
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
        {
          text: "Firma",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Data firma",
          value: "date_firma",
          sortable: false,
        },
        {
          text: "Cod. Alternativo",
          value: "cod_alternativo",
          sortable: false,
        },
        {
          text: "Revisione",
          value: "revisione_articolo",
          sortable: false,
        },
        {
          text: "Indice",
          value: "indice_modifica",
          sortable: false,
        },
        {
          text: "D.U.M",
          value: "_dum",
          sortable: false,
        },

        {
          text: "Allegati",
          value: "disegno",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      // header for Action MOdal
      actionItems: [
        {
          id: 1,
          icon: "mdi-plus",
          text: "Per aggiungere una nuova Disegno",
          color: "#1a80b6",
        },
        {
          id: 2,
          icon: "mdi-pencil",
          text: "Per aggiornare la Disegno",
          color: "#009999",
        },
        {
          id: 3,
          icon: "mdi-eye",
          text: "Per vedere l'allegato",
          color: "black",
        },
        {
          id: 4,
          icon: "mdi-window-close",
          text: "Per eliminare una disegno",
          color: "#c00000",
        },
      ],
    };
  },

  methods: {
    // To resize Images
    resizeImage(img, id) {
      if (img.width > img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    // TO zoom PDF
    onLoad() {
      panzoom(this.$refs.pdf);
    },
    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    openPdfAppenaCaricato(item) {
      console.log(item);
      if (item.startsWith("data:application/pdf")) {
        const byteCharacters = atob(item.substring(28));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        console.log(blobUrl);
        this.pdfdialog = true;
      }
    },
    openPdf(item) {
      console.log(item.disegno);
      const byteCharacters = atob(item.disegno.substring(28));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.selectedPdf = blobUrl;
      console.log(blobUrl);
      this.pdfdialog = true;
    },
    // Del Selected Item
    delSelectedItem() {
      this.deletedDisegno = {};
      this.dialogDelete = false;
    },
    // Delete Confirmation Dialog
    disegnoToDel(item) {
      this.dialogDelete = true;
      this.deletedDisegno = item;
    },
    // In order to sign disegno
    signdrawing() {
      let req = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setfirmadisegno",
          db_name: "quickproduction",
          id_utente: localStorage.id,
          token: localStorage.user_token,
          id_disegno: this.selectedDisegno,
        },
      };

      this.$store.dispatch("api", req).then(() => {
        this.snackbar = true;
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_background = "green darken-3";
        this.snackbar_icon = "mdi-check-circle";
        this.caricaDisegni();
      });
      this.dialogFirma = false;
    },
    // open firma disegno modal
    signatureDisegno(item) {
      this.dialogFirma = true;
      this.selectedDisegno = item.id_disegno;
    },

    clearImage() {
      this.dialogAllegati = false;
      this.dialogAllegatiErrore = false;
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");

        // Create an image
        var img = new Image();

        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;

          // Draw the image
          ctx.drawImage(img, 0, 0);

          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };

        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
    },
    onPaste(e) {
      // console.log("event t:", e)
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;

        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;

            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.disegno = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name.toLowerCase()
      );
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        this.hasExtension(files[0], [".jpeg", ".gif", ".png", ".jpg", ".pdf"])
      ) {
        this.allegato = files[0];

        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;

        reader.onload = function () {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          that.disegno = reader.result;
        };

        reader.onerror = function (error) {
          throw error;
        };
      } else {
        this.dialogAllegati = false;
        this.dialogAllegatiErrore = true;
      }
      this.dialogAllegati = false;
    },

    // Setting value to show in edit form of Disegno
    handleclick(item) {
      this.dialogAddDisegno = true;
      this.singleItem = item != null ? item : {};

      this.id_disegno = item != null ? item.id_disegno : "";
      this.cod_alternativo = item != null ? item.cod_alternativo : "";
      this.indice_modifica = item != null ? item.indice_modifica : "";
      this.revisione_articolo = item != null ? item.revisione_articolo : "";
      this.disegno = item != null ? item.disegno : "";
      let that = this;
      var image = new Image();
      image.src = this.disegno;
      image.onload = function () {
        that.resizeImage(image, "immagine");
      };
      this.dum =
        item != null
          ? moment(item._dum, "DD/MM/YYYY").format("YYYY-MM-DD")
          : "";
      this.tipo_disegno = item != null ? item.tipo_disegno : "";
      this.validita = item != null ? item.validita : 1;
      this.allegato = [];

      // console.log('this.dum :>> ', this.dum);
    },
    // when closing modal setting form values to empty
    closeModal() {
      this.id_disegno = "";
      this.cod_alternativo = "";
      this.indice_modifica = "";
      this.revisione_articolo = "";
      this.disegno = "";
      this.dum = "";
      this.dialogAddDisegno = false;
    },
    //In order to Delete single disegno
    deldisegno() {
      let data = {};
      data.id_disegno = this.deletedDisegno.id_disegno;
      data.validita = 0;
      let dataArray = [data];
      let delreq = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolodisegno",
          db_name: "quickproduction",
          id_utente: localStorage.id,
          id_articolo: this.data.id_articolo,
          articolo_disegni: dataArray,
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", delreq).then((res) => {
        if (res.data) {
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_background = "green darken-3";
          this.snackbar_icon = "mdi-check-circle";
        }
      });
      const i = this.items.findIndex((it) => it.id_disegno === data.id_disegno);
      this.items.splice(i, 1);
      this.dialogDelete = false;
    },

    // In order to save or update disegno
    slavaDesigno() {
      if (!this.id_disegno) {
        const data = {};
        if (this.cod_alternativo === "") {
          return;
        }
        if (this.indice_modifica === "") {
          return;
        }
        if (this.dum === "") {
          return;
        }
        data.id_disegno = -1;
        data.id_articolo = this.data.id_articolo;
        data.cod_alternativo = this.cod_alternativo;
        data.indice_modifica = this.indice_modifica;
        data.revisione_articolo = this.revisione_articolo;
        data.disegno = this.disegno;
        // data.dum = this.dum;
        data.dum = this.dum;
        data.validita = 1;
        data.tipo_disegno = 1;

        let dataArray = [];

        dataArray.push(data);

        let saveDesigno = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolodisegno",
            db_name: "quickproduction",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.id,
            id_articolo: this.data.id_articolo,
            articolo_disegni: dataArray,
          },
        };

        this.$store.dispatch("api", saveDesigno).then(() => {
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_background = "green darken-3";
          this.snackbar_icon = "mdi-check-circle";
          this.caricaDisegni();
        });
        this.dialogAddDisegno = false;

        //this.items.push(data);
        // localStorage.removeItem('dataBase')
      }

      if (this.singleItem.id_disegno) {
        const data = {};
        data.validita = 1;
        data.id_disegno = this.id_disegno;
        data.id_articolo = this.data.id_articolo;
        data.cod_alternativo = this.cod_alternativo;
        data.indice_modifica = this.indice_modifica;
        data.revisione_articolo = this.revisione_articolo;
        data.disegno = this.disegno;
        data.dum = this.dum;
        data.validita = 1;
        data.tipo_disegno = 1;
        let dataArray = [];

        dataArray.push(data);

        let saveDesigno = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolodisegno",
            db_name: "quickproduction",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.id,
            id_articolo: this.data.id_articolo,
            articolo_disegni: dataArray,
          },
        };
        // console.log("Update Disegno", data.id_disegno);
        this.$store.dispatch("api", saveDesigno).then(() => {
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_background = "green darken-3";
          this.snackbar_icon = "mdi-check-circle";
          this.caricaDisegni();
        });
        //  this.caricaDisegni();
        const i = this.items.findIndex(
          (it) => it.id_disegno === data.id_disegno
        );
        this.items.splice(i, 1, data);

        //  localStorage.removeItem('dataBase')
        this.dialogAddDisegno = false;
        this.id_disegno = "";
        this.cod_alternativo = "";
        this.indice_modifica = "";
        this.revisione_articolo = "";
        this.disegno = "";
        this.dum = "";
        this.tipo_disegno = "";
        this.validita = 0;
        this.singleItem = {};
      }
      this.items.map((it) => {
        var dum = moment(it.dum, "YYYY-MM-DD");
        it.dum = dum;
      });
    },
    // In order to get list of Disegni to populate the table
    caricaDisegni() {
      this.progressBar = true;
      this.loading = true;
      let disegnoReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolidisegno",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_utente: localStorage.getItem("id"),
          id_articolo: this.data.id_articolo,
        },
      };

      this.$store.dispatch("api", disegnoReq).then((res) => {
        this.items = res.data;

        if (this.items.length > 0) {
          this.progressBar = false;
          this.loading = false;
        } else {
          this.progressBar = false;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.caricaDisegni();
  },
};
</script>

<style css scoped></style>
