<template>
  <ListMagazzino />
</template>

<script>
import ListMagazzino from '@/components/MAGAZZINO/ListMagazzino.vue';

export default {
  name: 'HomeMagazzino',
  components: {
    ListMagazzino,
  },
}
</script>
