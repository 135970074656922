var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",staticStyle:{"width":"100% !important"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 24 : 2}},[_c('v-toolbar',{staticClass:"indigo darken-1 headline mb-6",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Allegati Lista")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"width":".3rem !important","background-color":"white"},attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"md":"4"}},'v-col',attrs,false),on),[_c('v-btn',{staticStyle:{"float":"left"},attrs:{"small":""},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-icon',{attrs:{"md":"4","color":"#1a80b6"}},[_vm._v("mdi-magnify")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio")])])],1)]}},{key:"item.delete",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"md":"4"}},'v-col',attrs,false),on),[_c('v-btn',{staticStyle:{"float":"left"},attrs:{"small":""}},[_c('v-icon',{attrs:{"md":"4","color":"#c00000"}},[_vm._v("mdi-window-close")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Per Rimuovere ")])])]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }