<template>
  <v-dialog persistent v-model="display" width="1200">
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      style="z-index: 99"
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar_step = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-card style="--accent: #990033">
      <v-toolbar color="var(--accent)" dense>
        <v-toolbar-title class="white--text"> Modifica Task </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="save" icon small v-bind="attrs" v-on="on">
              <v-icon color="white"> mdi-floppy </v-icon>
            </v-btn>
          </template>
          <span>Conferma</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-1"
              @click="display = false"
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Chiudi</span>
        </v-tooltip>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-tabs v-model="tab" vertical>
              <v-tab
                active-class="sfondoStandard white--text"
                class="py-0 my-0"
              >
                Generale
              </v-tab>
                <v-tab
                active-class="sfondoStandard white--text"
                class="py-0 my-0"
              >
                Risorse
              </v-tab>
                <v-tab
                active-class="sfondoStandard white--text"
                class="py-0 my-0"
              >
                Annotazioni
              </v-tab>
                <v-tab
                active-class="sfondoStandard white--text"
                class="py-0 my-0"
                v-if="template.id_task != -1"
              >
                Allegati
              </v-tab>
              <v-tabs-slider color="transparent"></v-tabs-slider>
            </v-tabs>
          </v-col>
          <v-col cols="9">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-container class="mt-0 pt-0">
                  <v-toolbar class="mb-5 mt-0" color="var(--accent)" dense>
                    <v-toolbar-title class="white--text">
                      Dati Generali
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-row align="start" justify="start">
                    <v-col align="start" cols="4" justify="start">
                      <v-text-field
                        dense
                        hide-details
                        label="Nome"
                        color="#990033"
                        outlined
                        v-model="template.titolo_task"
                      ></v-text-field>
                    </v-col>
                    <v-col align="start" cols="4" justify="start">
                      <v-text-field
                        dense
                        hide-details
                        label="Completamento %"
                        outlined
                        :min="0"
                        :max="100"
                        color="#990033"
                        type="number"
                        v-model="template.completamento"
                      ></v-text-field>
                    </v-col>
                    <v-col align="start" cols="4" justify="start">
                      <v-text-field
                        dense
                        hide-details
                        label="Descrizione"
                        outlined
                        color="#990033"
                        v-model="template.descrizione_task"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="DisplayRow()" align="start" justify="start">
                    <v-col align="start" cols="3" justify="start">
                      <v-menu
                        v-model="calendario_inizio_task"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="289"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            background-color="#ffffff"
                            dense
                            hide-details
                            label="Inizio Task"
                            outlined
                            readonly
                            color="#990033"
                            style="max-width: 200px"
                            v-model="inizio_task_formattato"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          @change="checkInizioFineTask('inizio')"
                          color="#990033"
                          v-model="template.inizio_task.day"
                          @input="calendario_inizio_task = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        ref="ora_inizio"
                        v-model="ora_inizio"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="template.inizio_task.hour"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="template.inizio_task.hour"
                            label="Ora Inizio"
                            outlined
                            dense
                            color="#990033"
                            hide-details
                            prepend-inner-icon="access_time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          format="ampm"
                          v-model="template.inizio_task.hour"
                          full-width
                          color="#990033"
                          @click:minute="
                            $refs.ora_inizio.save(template.inizio_task.hour)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col align="start" cols="3" justify="start">
                      <v-menu
                        v-model="calendario_fine_task"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="289"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            background-color="#ffffff"
                            dense
                            hide-details
                            label="Fine Task"
                            outlined
                            color="#990033"
                            readonly
                            style="max-width: 200px"
                            v-model="fine_task_formattato"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          @change="checkInizioFineTask('fine')"
                          color="#990033"
                          v-model="template.fine_task.day"
                          @input="calendario_fine_task = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        ref="ora_fine"
                        v-model="ora_fine"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="template.fine_task.hour"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="template.fine_task.hour"
                            label="Ora Fine"
                            outlined
                            dense
                            color="#990033"
                            hide-details
                            prepend-inner-icon="access_time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          format="ampm"
                          v-model="template.fine_task.hour"
                          full-width
                          color="#990033"
                          @click:minute="
                            $refs.ora_fine.save(template.fine_task.hour)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row align="start" justify="start">
                    <v-col align="start" cols="3" justify="start">
                      <v-menu
                        v-model="calendario_inizio_task_reale"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="289"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            background-color="#ffffff"
                            dense
                            hide-details
                            label="Inizio Reale"
                            outlined
                            color="#990033"
                            readonly
                            style="max-width: 200px"
                            v-model="inizio_task_reale_formattato"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          @change="checkInizioFineTaskReale('inizio')"
                          color="#990033"
                          v-model="template.inizio_task_reale.day"
                          @input="calendario_inizio_task_reale = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        ref="ora_inizio_reale"
                        v-model="ora_inizio_reale"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="template.inizio_task_reale.hour"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="template.inizio_task_reale.hour"
                            label="Ora Inizio Reale"
                            outlined
                            dense
                            color="#990033"
                            hide-details
                            prepend-inner-icon="access_time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          format="ampm"
                          v-model="template.inizio_task_reale.hour"
                          full-width
                          color="#990033"
                          @click:minute="
                            $refs.ora_inizio_reale.save(
                              template.inizio_task_reale.hour
                            )
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col align="start" cols="3" justify="start">
                      <v-menu
                        v-model="calendario_fine_task_reale"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="289"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            background-color="#ffffff"
                            dense
                            hide-details
                            label="Fine Reale"
                            outlined
                            color="#990033"
                            readonly
                            style="max-width: 200px"
                            v-model="fine_task_reale_formattato"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          @change="checkInizioFineTaskReale('fine')"
                          color="#990033"
                          v-model="template.fine_task_reale.day"
                          @input="calendario_fine_task_reale = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        ref="ora_fine_reale"
                        v-model="ora_fine_reale"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="template.fine_task_reale.hour"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="template.fine_task_reale.hour"
                            label="Ora Inizio Reale"
                            outlined
                            dense
                            color="#990033"
                            hide-details
                            prepend-inner-icon="access_time"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          format="ampm"
                          v-model="template.fine_task_reale.hour"
                          full-width
                          color="#990033"
                          @click:minute="
                            $refs.ora_fine_reale.save(
                              template.ora_fine_reale.hour
                            )
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container class="mt-0 pt-0">
                  <v-toolbar class="mb-2" color="var(--accent)" dense
                    ><v-toolbar-title class="white--text"
                      >Risorse</v-toolbar-title
                    ></v-toolbar
                  >
                  <v-row>
                    <v-col cols="2">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            color="var(--accent)"
                            @click="ApriDialogGestioneRisorse()"
                          >
                            <v-icon>mdi-account-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Aggiungi Risorsa</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        v-if="template != undefined"
                        :items="template.risorse.filter((f) => f.validita != 0)"
                        :headers="header_tbl_task"
                        fixed-header
                        class="elevation-3 pt-2"
                        no-data-text="Nessuna risorsa presente"
                      >
                        <template v-slot:[`item.modifica`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="clickModificaRisorsa(item)"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="var(--accent)">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Modifica Risorsa</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.elimina`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="clickEliminaRisorsa(item)"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="var(--accent)">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Elimina Risorsa</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container class="mt-0 pt-0">
                  <v-toolbar dense color="var(--accent)">
                    <v-toolbar-title class="white--text">
                      Annotazioni
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-textarea color="#990033" class="mt-3" outlined />
                </v-container>
              </v-tab-item>
              <v-tab-item v-if="template.id_task != -1">
                <v-container class="mt-0 pt-0">
                  <GestioneAllegato
                    controller_allegati="Global"
                    controller_caratteristiche_tabella="Gantt"
                    :id_riferimento="template.id_task"
                    ref="componenteAllegati"
                    @snackbarOpen="snackbarOpen"
                     @salva_allegati="salvaAllegati"
                    tipo_allegato="GANTT_TASK"
                    :show_close="false"
                    color="#990033"
                    toolbar_title="Allegati Task"
                    icon_color="white"
                    :show_save_button="true"
                  />
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="dialog_gestisci_risorsa" persistent width="800">
      <v-card style="--accent: #990033">
        <v-toolbar dark color="var(--accent)" dense>
          <v-toolbar-title class="white--text">
            {{ modifica_risorsa ? "Modifica risorsa" : "Aggiungi risorsa" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip v-if="!modifica_risorsa" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="aggiungiRisorsa()"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white"> mdi-account-plus </v-icon>
              </v-btn>
            </template>
            <span>Aggiungi risorsa</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="confermaModificaRisorsa()"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white"> mdi-account-cog </v-icon>
              </v-btn>
            </template>
            <span>Modifica risorsa</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-1"
                @click="chiudiDialogGestioneRisorsa()"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  dense
                  :items="risorse_disponibili"
                  item-text="nominativo"
                  item-value="id_personale"
                  return-object
                  v-model="risorsa"
                  color="var(--accent)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  label="Percentuale Unità"
                  :min="0"
                  :max="100"
                  v-model="percentuale_unita"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_conferma_elimina_risorsa"
      width="unset"
      persistent
    >
      <v-card style="--accent: #990033">
        <v-toolbar dark color="var(--accent)" dense>
          <v-toolbar-title style="font-size: 26px" class="white--text">
            Eliminazione risorsa
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-container>
            <v-row>
              <v-col cols="12">
                <span style="font-size: 22px"
                  >Sei sicuro di voler eliminare la risorsa selezionata?</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="4">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      @click="confermaEliminaRisorsa()"
                      icon
                      small
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon large color="var(--accent)">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      @click="dialog_conferma_elimina_risorsa = false"
                      icon
                      small
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon large color="var(--accent)">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--<div class="gantt_editor">
      <header>
        <h2>Modifica Task</h2>
        <GraphSVG name="close" @click="display = false" />
      </header>
      <main>
        <aside>
          <span />
          <nav>
            <span :data-active="tab == 0" @click="tab = 0">Generale</span>
          </nav>
        </aside>
        <main>
          <section v-if="tab == 0">
            <div>
              <h3>Nome</h3>
              <input type="text" v-model="template.titolo_task" />
            </div>
            <div>
              <h3>Completamento %</h3>
              <input type="number" v-model="template.completamento" />
            </div>
            <br />
            <GraphSVG
              :key="key"
              :data-selected="template.tipo == 1"
              :name="template.tipo == 1 ? 'check' : 'close'"
              @click="toggle_type"
            />
            <span>Milestone</span>
            <br />
            <div>
              <h3>Inizio Task</h3>
              <DateTimePicker v-model="template.inizio_task" :key="key" />
            </div>
            <div>
              <h3>Fine Task</h3>
              <DateTimePicker v-model="template.fine_task" :key="key" />
            </div>
            <br />
            <div>
              <h3>Inizio Reale</h3>
              <DateTimePicker v-model="template.inizio_task_reale" :key="key" />
            </div>
            <div>
              <h3>Fine Reale</h3>
              <DateTimePicker v-model="template.fine_task_reale" :key="key" />
            </div>
          </section>
          <section v-if="tab == 1">
            <nav>
              <h3>Cerca Risorsa</h3>
              <GraphSVG name="user_search" />
              <input type="text" />
            </nav>
            <ul>
              <li>
                <GraphSVG name="check" />
                <span>Risorsa 1</span>
              </li>
              <li>
                <GraphSVG name="check" />
                <span>Risorsa 1</span>
              </li>
            </ul>
          </section>
          <section v-if="tab == 2">
            <nav>
              <h3>Annotazioni</h3>
              <textarea />
            </nav>
          </section>
        </main>
      </main>
      <footer>
        <span @click="display = false">ANNULLA</span>
        <span @click="save">CONFERMA</span>
      </footer>
    </div>-->
  </v-dialog>
</template>
<script>
// import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
// import DateTimePicker from "@/components/GANTT/Components/DateTimePicker/DateTimePicker.vue";
import moment from "moment/moment";
import GanttTask from "@/components/GANTT/Classes/Task/Task.js";
import RisorsaTask from "@/components/GANTT/Classes/Task/risorsa_task.js";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import _ from "lodash";
export default {
  name: "GanttEditor",
  components: {
    GestioneAllegato,
    // GraphSVG,
    // DateTimePicker,
  },
  computed: {
    inizio_task_formattato() {
      return this.template.inizio_task != ""
        ? new Date(this.template.inizio_task.day).toLocaleDateString("it-IT", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : "";
    },
    inizio_task_reale_formattato() {
      return this.template.inizio_task_reale != ""
        ? new Date(this.template.inizio_task_reale.day).toLocaleDateString(
            "it-IT",
            { month: "2-digit", day: "2-digit", year: "numeric" }
          )
        : "";
    },
    fine_task_formattato() {
      return this.template.fine_task != ""
        ? new Date(this.template.fine_task.day).toLocaleDateString("it-IT", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : "";
    },
    fine_task_reale_formattato() {
      return this.template.fine_task_reale != ""
        ? new Date(this.template.fine_task_reale.day).toLocaleDateString(
            "it-IT",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }
          )
        : "";
    },
  },
  data() {
    return {
      calendario_inizio_task: false,
      calendario_inizio_task_reale: false,
      calendario_fine_task: false,
      calendario_fine_task_reale: false,
      display: false,
      ora_inizio: false,
      ora_fine: false,
      ora_inizio_reale: false,
      ora_fine_reale: false,
      dialog_gestisci_risorsa: false,
      dialog_conferma_elimina_risorsa: false,
      modifica_risorsa: false,
      risorsa: new RisorsaTask(),
      indice_risorsa: null,
      risorse_disponibili: [],
      risorse_disponibili_backup: [],
      risorse_task: [],
      percentuale_unita: 0,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      header_tbl_task: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Nome risorsa", sortable: true, value: "nominativo" },
        { text: "Unità", sortable: true, value: "percentuale_unita" },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      key: 0,
      tab: 0,
      target: null,
      tabs_editor: ["Generale", "Risorse", "Annotazioni", "Allegati"],
      template: {
        titolo_task: null,
        completamento: 0,
        inizio_task: new Date(),
        inizio_task_reale: new Date(),
        fine_task: new Date(),
        fine_task_reale: new Date(),
        risorse: [],
      },
    };
  },
  mounted() {
    this.getRisorse();
  },

  methods: {
    salvaAllegati() {
      console.log("entrato salvaAllegati");
      // Salvo gli allegati dell'intervento.
      this.$refs.componenteAllegati.id_riferimento_creazione_genitore = this.template.id_task;
      this.$refs.componenteAllegati.salvaTuttiAllegati();
      this.$emit("aggiornaProgetto", this.template.id_progetto)

    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    ApriDialogGestioneRisorse() {
      this.dialog_gestisci_risorsa = true;
      this.modifca_risorsa = false;
      this.percentuale_unita = 0;
      if (this.risorse_task.length != 0) {
        let array_tmp = [];
        this.risorse_disponibili.forEach((e) => {
          if (
            this.risorse_task.filter(
              (f) => f.id_personale == e.id_personale && f.validita != 0
            ).length == 0
          ) {
            array_tmp.push(e);
          }
        });
        this.risorse_disponibili = Array.from(array_tmp);
      }
    },
    aggiungiRisorsa() {
      this.modifica_risorsa = false;
      this.risorsa = new RisorsaTask(this.risorsa, this.template.id_temp);
      this.risorsa.percentuale_unita = this.percentuale_unita;
      let risorsa_tmp = Object.assign({}, this.risorsa);
      this.template.risorse.push(risorsa_tmp);
      this.dialog_gestisci_risorsa = false;
      this.risorsa = new RisorsaTask(this.template.id_task);
      this.percentuale_unita = 0;
    },
    clickModificaRisorsa(item) {
      this.risorse_disponibili = _.cloneDeep(this.risorse_disponibili_backup);
      this.indice_risorsa = this.template.risorse.indexOf(item);
      this.modifica_risorsa = true;
      this.risorsa = item;
      this.percentuale_unita = item.percentuale_unita;
      console.log("risorsa selezionata:", this.risorsa);
      this.dialog_gestisci_risorsa = true;
    },
    confermaModificaRisorsa() {
      this.risorsa = Object.assign(
        {},
        new RisorsaTask(this.risorsa, this.template.id_temp)
      );
      console.log("risorsa:", this.risorsa);
      this.risorsa.percentuale_unita = this.percentuale_unita;
      this.template.risorse[this.indice_risorsa] = Object.assign(
        {},
        this.risorsa
      );
      this.dialog_gestisci_risorsa = false;
    },
    clickEliminaRisorsa(item) {
      this.dialog_conferma_elimina_risorsa = true;
      this.indice_risorsa = this.template.risorse.indexOf(item);
    },
    confermaEliminaRisorsa() {
      this.dialog_conferma_elimina_risorsa = false;
      this.template.risorse[this.indice_risorsa].validita = 0;
    },
    chiudiDialogGestioneRisorsa() {
      this.modifica_risorsa = false;
      this.dialog_gestisci_risorsa = false;
      this.risorsa = new RisorsaTask(this.template.id_task);
      this.percentuale_unita = 0;
    },
    DisplayRow() {
      if (this.template.id_task == -1 || this.target == null) return true;
      return !this.target.hasChildren();
    },
    checkInizioFineTask(precedenza) {
      const inizio_tmp = new Date(this.template.inizio_task);
      const fine_tmp = new Date(this.template.fine_task);

      // Se inizio e fine sono incompatibili, li riallineo.

      if (inizio_tmp > fine_tmp) {
        // Controllo che l'inizio non sia successivo alla fine
        if (precedenza === "inizio") {
          this.template.fine_task = this.template.inizio_task;
        } else if (precedenza === "fine") {
          this.template.inizio_task = this.template.fine_task;
        }
      }
    },
    checkInizioFineTaskReale(precedenza) {
      const inizio_tmp = new Date(this.template.inizio_task_reale);
      const fine_tmp = new Date(this.template.fine_task_reale);

      // Se inizio e fine sono incompatibili, li riallineo.

      if (inizio_tmp > fine_tmp) {
        // Controllo che l'inizio non sia successivo alla fine
        if (precedenza === "inizio") {
          this.template.fine_task_reale = this.template.inizio_task_reale;
        } else if (precedenza === "fine") {
          this.template.inizio_task_reale = this.template.fine_task_reale;
        }
      }
    },

    show(item, is_new = false) {
      if (is_new) {
        console.log("NUOVO");
        const now = this.formatDate(new Date());
        Object.assign(this.template, {
          id_task: -1,
          id_progetto: item.data.id_progetto,
          id_task_padre: item.data.id_task,
          id_temp_padre:
            item.data.id_task == -1 ? item.data.id_temp : item.data.id_task,
          nesting: null,
          id_temp: moment().valueOf(),
          tipo: 0,
          inizio_task: {
            day: now.split(" ")[0],
            hour: now.split(" ")[1],
          },
          inizio_task_reale: {
            day: now.split(" ")[0],
            hour: now.split(" ")[1],
          },
          fine_task: {
            day: now.split(" ")[0],
            hour: now.split(" ")[1],
          },
          fine_task_reale: {
            day: now.split(" ")[0],
            hour: now.split(" ")[1],
          },
          titolo_task: null,
          validita: 1,
          note: null,
          completamento: 0,
          risorse: [],
        });
      } else {
        const inizio = this.formatDate(item.data.inizio_task);
        const fine = this.formatDate(item.data.fine_task);
        const inizio_reale = this.formatDate(item.data.inizio_task_reale);
        const fine_reale = this.formatDate(item.data.fine_task_reale);
        Object.assign(this.template, {
          id_task: item.data.id_task,
          id_progetto: item.data.id_progetto,
          id_task_padre: item.data.id_task_padre,
          nesting: item.data.nesting,
          tipo: item.data.tipo,
          id_temp:
            item.data.id_task == -1 ? item.data.id_temp : item.data.id_task,
          id_temp_padre:
            item.data.id_task == -1
              ? item.data.id_temp_padre
              : item.data.id_task_padre,
          allegati: item.data.allegati,
          inizio_task: {
            day: inizio.split(" ")[0],
            hour: inizio.split(" ")[1],
          },
          inizio_task_reale: {
            day: inizio_reale.split(" ")[0],
            hour: inizio_reale.split(" ")[1],
          },
          fine_task: {
            day: fine.split(" ")[0],
            hour: fine.split(" ")[1],
          },
          fine_task_reale: {
            day: fine_reale.split(" ")[0],
            hour: fine_reale.split(" ")[1],
          },
          descrizione_task: item.data.descrizione_task,
          titolo_task: item.data.titolo_task,
          risorse: item.data.risorse,
          validita: 1,
          note: null,
          completamento: item.data.completamento,
        });
      }
      console.log("template:", this.template);
      this.target = item;

      this.key++;
      this.display = true;
    },
    formatDate(date) {
      const year = String(date.getFullYear()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    setDateObject(arg_string, arg_date) {
      const date_tmp = new Date(arg_string);
      arg_date.setTime(date_tmp.getTime());
      console.log(arg_string);
    },

    getRisorse() {
      const that = this;
      const request = {
        controller: "Utente",
        body: {
          action: "getutentelist",
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (response) {
            that.risorse_disponibili = response.gp_personale_utenti;
            that.risorse_disponibili.forEach((e) => {
              e.nominativo = e.nome + " " + e.cognome;
            });
            that.risorse_disponibili_backup = _.cloneDeep(
              that.risorse_disponibili
            );
            console.log("risorse", that.risorse_disponibili);
          },
          error: function () {},
        },
      };
      this.$store.state.Network.AjaxRequest(request);
    },

    async save() {
      const template = this.template;
      console.log("template", template);
      const item = {
        id_task: template.id_task,
        id_progetto: template.id_progetto,
        id_task_padre: template.id_task_padre,
        id_temp_padre: template.id_temp_padre,
        id_temp: template.id_temp,
        nesting: template.nesting,
        tipo: template.tipo,
        inizio_task: template.inizio_task.day + " " + template.inizio_task.hour,
        inizio_task_reale:
          template.inizio_task_reale.day +
          " " +
          template.inizio_task_reale.hour,
        fine_task: template.fine_task.day + " " + template.fine_task.hour,
        fine_task_reale:
          template.fine_task_reale.day + " " + template.fine_task_reale.hour,
        titolo_task: template.titolo_task,
        risorse: template.risorse,
        descrizione_task: template.descrizione_task,
        validita: 1,
        note: null,
        completamento: template.completamento,
      };
      const that = this;
      // const request = {
      //   controller: "Gantt",
      //   body: {
      //     action: "setTask",
      //     token: localStorage.user_token,
      //     task: [item],
      //   },
      //   method: "PUT",
      //   on: {
      //     load: async function (response) {
      //       const task = response.task[0];
      //       console.log("taskkk", task);
      //       let state;
      //       if (that.$refs.componenteAllegati) {
      //         that.$refs.componenteAllegati.id_riferimento_creazione_genitore =
      //           task.id_task;
      //         state = await that.$refs.componenteAllegati.salvaTuttiAllegati();
      //       }
      //       let emit = function () {
      //         that.$emit("snackbarOpen", state);
      //         that.$emit("aggiornaProgetto", template.id_progetto);
      //       };
      //       if (item.id_task == -1) {
      //         const task_instance = new GanttTask(task);
      //         if (item.id_task_padre == null) {
      //           emit = function () {
      //             that.$emit("insert", task_instance);
      //           };
      //         } else {
      //           task_instance.addParent(that.target);
      //         }
      //       } else {
      //         that.target.importData(task);
      //       }
      //       emit();
      //       this.display = false;
      //     },
      //     error: function () {},
      //   },
      // };
      let emit = function () {};
      if (item.id_task == -1 && that.target.data.id_temp != item.id_temp) {
        const task_instance = new GanttTask(item);
        if (item.id_task_padre == null) {
          console.log("id_padrenull:", item);
          emit = function () {
            that.$emit("insert", task_instance);
          };
        } else {
          console.log("ENTRATOOOOOOOOOO", that.target, item);
          task_instance.addParent(that.target);
          emit = function () {
            that.$emit("update");
          };
        }
      } else if (that.target.data.id_temp == item.id_temp) {
        console.log("devo modificarlo", item);
        that.target.importData(item);
        emit = function () {
          that.$emit("update");
        };
      }
      emit();
      this.display = false;
      // this.$store.state.Network.AjaxRequest(request);
    },
    toggle_type() {
      this.key++;
      if (this.template.tipo == 1) {
        this.template.tipo = 0;
      } else {
        this.template.tipo = 1;
      }
    },
  },
};
</script>
<style scoped>
.gantt_editor {
  position: relative;
  background-color: white;
  width: 100%;
  --accent: #990033;
  max-height: 80vh;
  overflow: hidden;
  user-select: none;
}
.gantt_editor > header {
  height: 40px;
  width: 100%;
  position: relative;
  background-color: var(--accent);
  white-space: nowrap;
  padding: 0 10px;
  color: white;
}
.gantt_editor > header > h2 {
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  padding: 0;
  text-align: left;
  width: calc(100% - 24px);
}
.gantt_editor > header > svg {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
}

.gantt_editor > main {
  width: 100%;
  position: relative;
  height: calc(80vh - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}
.gantt_editor > main > aside {
  width: 150px;
  height: 100%;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
.gantt_editor > main > aside > span {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}
.gantt_editor > main > aside > nav {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 5px;
}
.gantt_editor > main > aside > nav > span {
  display: block;
  text-align: left;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 15px;
  color: var(--accent);
  transition: background-color 0.3s ease;
  border-radius: 5px;
  margin: 3px 0;
}

.gantt_editor > main > aside > nav > span:hover {
  background-color: rgba(153, 0, 51, 0.05);
}
.gantt_editor > main > aside > nav > span:active {
  transition: background-color 0s ease;
  background-color: rgba(153, 0, 51, 0.1);
}
.gantt_editor > main > aside > nav > span[data-active] {
  background-color: var(--accent);
  color: white;
}

.gantt_editor > main > main {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 150px);
}
.gantt_editor > main > main > section {
  width: 100%;
  padding: 15px;
}
.gantt_editor > main > main > section > svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  padding: 0;
  color: white;
  background-color: var(--accent);
  border: 1px solid var(--accent);
  border-radius: 3px;
  float: left;
  margin-left: calc((100% - 430px) / 2);
}
.gantt_editor > main > main > section > svg:not([data-selected]) {
  background-color: grey;
}

.gantt_editor > main > main > section > span {
  float: left;
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin-left: 15px;
}
.gantt_editor > main > main > section > div {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  border: 1px solid var(--accent);
  border-radius: 5px;
  position: relative;
  margin: 15px;
}
.gantt_editor > main > main > section > div > h3 {
  position: absolute;
  top: -8px;
  left: 20px;
  padding: 0 5px;
  margin: 0;
  line-height: 15px;
  font-size: 15px;
  background-color: white;
  color: var(--accent);
}
.gantt_editor > main > main > section > div > input {
  appearance: none;
  outline: none !important;
  width: 100%;
  padding: 10px;
  display: block;
}
.gantt_editor > main > main > section > div > div {
  margin: 10px 0;
  width: 100%;
  display: block;
}

.gantt_editor > footer {
  position: relative;
  height: 40px;
  padding: 0 10px;
}
.gantt_editor > footer > span {
  float: left;
  line-height: 30px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  padding: 0 10px;
}
.gantt_editor > footer > span:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.gantt_editor > footer > span:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.gantt_editor > footer > span:nth-of-type(2) {
  float: right;
  color: var(--accent);
}

.gantt_editor > main > main > section > nav {
  display: inline-block;
  vertical-align: middle;
  width: 430px;
  border: 1px solid var(--accent);
  border-radius: 5px;
  position: relative;
  margin: 15px;
  padding: 0 10px;
  position: relative;
}
.gantt_editor > main > main > section > nav > h3 {
  position: absolute;
  top: -8px;
  left: 20px;
  padding: 0 5px;
  margin: 0;
  line-height: 15px;
  font-size: 15px;
  background-color: white;
  color: var(--accent);
}
.gantt_editor > main > main > section > nav > input {
  appearance: none;
  outline: none !important;
  width: calc(100% - 24px);
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
}
.gantt_editor > main > main > section > nav > svg {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  padding: 0;
  margin: 0;
}
.gantt_editor > main > main > section > ul {
  width: 430px;
  padding: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  max-height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
}
.gantt_editor > main > main > section > ul > li {
  margin: 3px 0;
  display: block;
  padding: 0;
}
.gantt_editor > main > main > section > ul > li > svg {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  color: white;
  background-color: var(--accent);
  border: 1px solid var(--accent);
  border-radius: 3px;
}
.gantt_editor > main > main > section > ul > li > span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: calc(100% - 34px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}
.gantt_editor > main > main > section > nav > textarea {
  resize: none;
  width: 100%;
  display: block;
  margin: 10px 0;
  outline: none !important;
  height: 120px;
  position: relative;
  font-size: 15px;
}
.sfondoStandard {
  background-color: var(--accent);
}
</style>