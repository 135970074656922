<template>
    <div>
        <v-btn icon @click="openConnection">
            <v-icon>mdi-cog</v-icon>
        </v-btn>
    </div>
</template>
<script>
export default {
    name : 'PartProgramWS',
    data(){
        return{
            NETWORK : null
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
    },
    methods : {
        openConnection(){
            const request =  {
                controller : 'ConnextStream',
                body : {
                    action: "boxchannel",
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : function(response){                    
                       console.log(response);                    
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        }
    }
}
</script>