var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-radius":"0px"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Configurazione Etichetta ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaLabel}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-chip',{staticClass:"mr-2 py-0"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.fattore_ingrandimento)+" ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.ingrandisci}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-magnify-plus ")])],1)]}}])},[_c('span',[_vm._v("Ingrandisci")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.rimpicciolisci}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-magnify-minus ")])],1)]}}])},[_c('span',[_vm._v("Rimpicciolisci")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-keyboard-backspace ")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),_c('v-card-text',{staticClass:"mt-3",attrs:{"id":"mainCardText"}},_vm._l((_vm.report.report.filter(function (e) { return e.tipo === 'ReportPage'; })),function(elem0){return _c('div',{key:elem0.id_dettaglio,style:({
        border:
          elem0.paperHeight > 0 && elem0.paperWidth > 0
            ? ((1 * _vm.fattore_ingrandimento) + "px solid black")
            : '',
        display: 'inline-block',
        height: elem0.paperHeight * _vm.fattore_ingrandimento + 'mm',
        left: '0mm',
        marginBottom:
          elem0.bottom_margin === null
            ? '0px'
            : elem0.bottom_margin * _vm.fattore_ingrandimento + 'px',
        marginLeft:
          elem0.left_margin === null
            ? '0px'
            : elem0.left_margin * _vm.fattore_ingrandimento + 'px',
        marginRight:
          elem0.right_margin === null
            ? '0px'
            : elem0.right_margin * _vm.fattore_ingrandimento + 'px',
        marginTop:
          elem0.top_margin === null
            ? '0px'
            : elem0.top_margin * _vm.fattore_ingrandimento + 'px',
        overflow: 'hidden',
        position: 'relative',
        top: '0mm',
        width: elem0.paperWidth * _vm.fattore_ingrandimento + 'mm',
      }),attrs:{"id":"reportPage","name":elem0.name}},[_vm._l((elem0.sezione.filter(
          function (e) { return e.tipo === 'ReportTitleBand'; }
        )),function(elem1){return _c('div',{key:elem1.id_dettaglio,style:({
          border:
            elem1.width > 0 && elem1.height > 0
              ? ((1 * _vm.fattore_ingrandimento) + "px solid black")
              : '',
          display: 'inline-block',
          height:
            elem1.height === null
              ? '0px'
              : elem1.height * _vm.fattore_ingrandimento + 'px',
          left:
            elem1.left === null
              ? '0px'
              : elem1.left * _vm.fattore_ingrandimento + 'px',
          overflow: 'hidden',
          position: 'relative',
          top:
            elem1.top === null
              ? '0px'
              : elem1.top * _vm.fattore_ingrandimento + 'px',
          width:
            elem1.width === null
              ? '0px'
              : elem1.width * _vm.fattore_ingrandimento + 'px',
        }),attrs:{"name":elem1.name}})}),_vm._l((elem0.sezione.filter(
          function (e) { return e.tipo === 'PageHeaderBand'; }
        )),function(elem1){return _c('div',{key:elem1.id_dettaglio,style:({
          border:
            elem1.height > 0 && elem1.width > 0
              ? ((1 * _vm.fattore_ingrandimento) + "px solid black")
              : '',
          display: 'inline-block',
          height:
            elem1.height === null
              ? '0px'
              : elem1.height * _vm.fattore_ingrandimento + 'px',
          left:
            elem1.left === null ? '0px' : elem1.left * _vm.fattore_ingrandimento,
          marginBottom:
            elem1.bottom_margin === null
              ? '0px'
              : elem1.bottom_margin * _vm.fattore_ingrandimento + 'px',
          marginLeft:
            elem1.left_margin === null
              ? '0px'
              : elem1.left_margin * _vm.fattore_ingrandimento + 'px',
          marginRight:
            elem1.right_margin === null
              ? '0px'
              : elem1.right_margin * _vm.fattore_ingrandimento + 'px',
          marginTop:
            elem1.top_margin === null
              ? '0px'
              : elem1.top_margin * _vm.fattore_ingrandimento + 'px',
          overflow: 'hidden',
          position: 'relative',
          top: elem1.top === null ? '0px' : elem1.top * _vm.fattore_ingrandimento,
          width:
            elem1.width === null
              ? '0px'
              : elem1.width * _vm.fattore_ingrandimento + 'px',
        }),attrs:{"name":elem1.name}})}),_vm._l((elem0.sezione.filter(function (e) { return e.tipo === 'DataBand'; })),function(elem1){return _c('div',{key:elem1.id_dettaglio,style:({
          border:
            elem1.height > 0 && elem1.width > 0
              ? ((1 * _vm.fattore_ingrandimento) + "px solid black")
              : '',
          display: 'inline-block',
          height:
            elem1.height === null
              ? '0px'
              : elem1.height * _vm.fattore_ingrandimento + 'px',
          left:
            elem1.left === null
              ? '0px'
              : elem1.left * _vm.fattore_ingrandimento + 'px',
          marginBottom:
            elem1.bottom_margin === null
              ? '0px'
              : elem1.bottom_margin * _vm.fattore_ingrandimento + 'px',
          marginLeft:
            elem1.left_margin === null
              ? '0px'
              : elem1.left_margin * _vm.fattore_ingrandimento + 'px',
          marginRight:
            elem1.right_margin === null
              ? '0px'
              : elem1.right_margin * _vm.fattore_ingrandimento + 'px',
          marginTop:
            elem1.top_margin === null
              ? '0px'
              : elem1.top_margin * _vm.fattore_ingrandimento + 'px',
          overflow: 'hidden',
          position: 'absolute',
          top:
            elem1.top === null
              ? '0px'
              : elem1.top * _vm.fattore_ingrandimento + 'px',
          width:
            elem1.width === null
              ? '0px'
              : elem1.width * _vm.fattore_ingrandimento + 'px',
        }),attrs:{"name":elem1.name}},[_vm._l((elem1.oggetto.filter(
            function (e) { return e.tipo === 'BarcodeObject'; }
          )),function(elem2){return _c('div',{key:elem2.id_dettaglio,style:({
            border:
              elem2.height > 0 && elem2.width > 0
                ? ((1 * _vm.fattore_ingrandimento) + "px solid black")
                : '',
            display: 'inline-block',
            height:
              elem2.height === null
                ? '0px'
                : elem2.height * _vm.fattore_ingrandimento + 'px',
            left:
              elem2.left === null
                ? '0px'
                : elem2.left * _vm.fattore_ingrandimento + 'px',
            overflow: 'hidden',
            position: 'absolute',
            top:
              elem2.top === null
                ? '0px'
                : elem2.top * _vm.fattore_ingrandimento + 'px',
            width:
              elem2.width === null
                ? '0px'
                : elem2.width * _vm.fattore_ingrandimento + 'px',
          }),attrs:{"name":elem2.name}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(elem2.showText != 0),expression:"elem2.showText != 0"}]},[_vm._v(" "+_vm._s(elem2.text)+" ")])])}),_vm._l((elem1.oggetto.filter(
            function (e) { return e.tipo === 'TextObject'; }
          )),function(elem2){return _c('div',{key:elem2.id_dettaglio,style:({
            backgroundImage: ("url('data:image/jpeg;base64," + (elem2.fillImageData && elem2.fillImageData != null
                ? elem2.fillImageData
                : '') + "')"),
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            border:
              elem2.height > 0 && elem2.width > 0
                ? ((1 * _vm.fattore_ingrandimento) + "px dashed grey")
                : '',
            display: 'inline-block',
            fontFamily:
              elem2.font && elem2.font != null
                ? elem2.font.split(', ')[0]
                : 'Arial',
            fontSize:
              elem2.font && elem2.font != null
                ? elem2.font.split(', ').length >= 2
                  ? parseInt(elem2.font.split(', ')[1]) *
                      _vm.fattore_ingrandimento +
                    'pt'
                  : '10pt'
                : '10pt',
            fontWeight: elem2.font
              ? elem2.font.split('style=').length >= 2
                ? elem2.font.split('style=')[1]
                : 'normal'
              : 'normal',
            height:
              elem2.height === null
                ? '0px'
                : elem2.height * _vm.fattore_ingrandimento + 'px',
            left:
              elem2.left === null
                ? '0px'
                : elem2.left * _vm.fattore_ingrandimento + 'px',
            lineHeight: elem2.font.split(', ')[1],
            marginBottom:
              elem2.bottom_margin === null
                ? '0px'
                : elem2.bottom_margin + 'px',
            marginLeft:
              elem2.left_margin === null ? '0px' : elem2.left_margin + 'px',
            marginRight:
              elem2.right_margin === null ? '0px' : elem2.right_margin + 'px',
            marginTop:
              elem2.top_margin === null ? '0px' : elem2.top_margin + 'px',
            overflow: 'hidden',
            paddingLeft: '2px',
            paddingright: '2px',
            position: 'absolute',
            textAlign: 'left',
            top:
              elem2.top === null
                ? '0px'
                : elem2.top * _vm.fattore_ingrandimento + 'px',
            width:
              elem2.width === null
                ? '0px'
                : elem2.width * _vm.fattore_ingrandimento + 'px',
          }),attrs:{"name":elem2.name},on:{"contextmenu":function($event){return _vm.handleClickDestroTextObject($event, elem2)}}},[_vm._v(" "+_vm._s(elem2.fillImageData && elem2.fillImageData != null ? "" : elem2.text)+" "),_vm._l((elem2.formato.filter(
              function (e) { return e.tipo === 'Highlight'; }
            )),function(elem3){return _c('div',{key:elem3.id_dettaglio},_vm._l((elem3.condizione.filter(
                function (e) { return e.tipo === 'Condition'; }
              )),function(elem4){return _c('div',{key:elem4.id_dettaglio})}),0)})],2)}),_vm._l((elem1.oggetto.filter(
            function (e) { return e.tipo === 'PictureObject'; }
          )),function(elem2){return _c('div',{key:elem2.id_dettaglio,style:({
            backgroundImage: ("url('data:image/" + (elem2.imageFormat) + ";base64," + (elem2.image) + "')"),
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            border:
              elem2.height > 0 && elem2.width > 0
                ? ((1 * _vm.fattore_ingrandimento) + "px dashed grey")
                : '',
            display: 'inline-block',
            height:
              elem2.height === null
                ? '0px'
                : elem2.height * _vm.fattore_ingrandimento + 'px',
            left:
              elem2.left === null
                ? '0px'
                : elem2.left * _vm.fattore_ingrandimento + 'px',
            overflow: 'hidden',
            position: 'absolute',
            top:
              elem2.top === null
                ? '0px'
                : elem2.top * _vm.fattore_ingrandimento + 'px',
            width:
              elem2.width === null
                ? '0px'
                : elem2.width * _vm.fattore_ingrandimento + 'px',
          }),attrs:{"name":elem2.name}})}),_vm._l((elem1.oggetto.filter(
            function (e) { return e.tipo === 'LineObject'; }
          )),function(elem2){return _c('hr',{key:elem2.id_dettaglio,style:({
            backgroundColor: 'black',
            border: ((elem2.borderWidth === null
                ? 0
                : (elem2.borderWidth / 2) * _vm.fattore_ingrandimento) + "px solid black"),
            display: 'inline-block',
            height:
              elem2.height === null
                ? '0px'
                : elem2.height * _vm.fattore_ingrandimento + 'px',
            left:
              elem2.left === null
                ? '0px'
                : elem2.left * _vm.fattore_ingrandimento + 'px',
            position: 'absolute',
            top:
              elem2.top === null
                ? '0px'
                : elem2.top * _vm.fattore_ingrandimento + 'px',
            width:
              elem2.width === null
                ? '0px'
                : elem2.width * _vm.fattore_ingrandimento + 'px',
            zIndex: 9,
          }),attrs:{"name":elem2.name}})})],2)}),_vm._l((elem0.sezione.filter(
          function (e) { return e.tipo === 'PageFooterBand'; }
        )),function(elem1){return _c('div',{key:elem1.id_dettaglio,style:({
          border:
            elem1.width > 0 && elem1.height > 0
              ? ((1 * _vm.fattore_ingrandimento) + "px solid black")
              : '',
          display: 'inline-block',
          height:
            elem1.height === null
              ? '0px'
              : elem1.height * _vm.fattore_ingrandimento + 'px',
          left:
            elem1.left === null
              ? '0px'
              : elem1.left * _vm.fattore_ingrandimento + 'px',
          overflow: 'hidden',
          position: 'relative',
          top:
            elem1.top === null
              ? '0px'
              : elem1.top * _vm.fattore_ingrandimento + 'px',
          width:
            elem1.width === null
              ? '0px'
              : elem1.width * _vm.fattore_ingrandimento + 'px',
        }),attrs:{"name":elem1.name}})}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.menu_contestuale_visible),expression:"menu_contestuale_visible"}],staticStyle:{"position":"absolute","z-index":"10"},attrs:{"id":"menu_contestuale","width":"250px"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Menu Contestuale ")])],1),_c('v-list',[_c('v-list-item',{on:{"click":_vm.enterModEditObj}},[_c('v-list-item-title',[_vm._v(" Modifica Oggetto ")])],1)],1)],1)],2)}),0),_c('v-dialog',{model:{value:(_vm.modifica_dialog),callback:function ($$v) {_vm.modifica_dialog=$$v},expression:"modifica_dialog"}},[_c('ModificaOggetto',{attrs:{"elemento_sel":_vm.elemento_sel,"testata":_vm.report,"visible":_vm.modifica_dialog},on:{"chiudi_dialog":_vm.handleChiudiDialogModifica,"salvata_modifica":_vm.handleSalvataModifica}})],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }