<template>
  <v-card>
    <v-toolbar
      class="white--text"
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title dense>Gestione Lavorazione</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="clickChiudi" small>
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <!-- Descrizione di articolo e fase -->
      <v-container>
        <v-row align="center">
          <v-col cols="6">
            Cod. Articolo: <b>{{ fase_sel_tmp.cod_articolo }}</b>
          </v-col>
          <v-col cols="6">
            Work Order: <b>{{ fase_sel_tmp.cod_fase }}</b>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <!-- Campo di ricerca per macchina -->
      <v-container>
        <v-row align="center">
          <v-col cols="4">
            <v-autocomplete
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details
              :items="macchine"
              item-text="cod_macchina"
              item-value="cod_macchina"
              label="Cod. Macchina"
              outlined
              style="display: inline-block; max-width: 350px"
              v-model="cod_macchina_sel"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">{{ fase_sel_tmp.des_macchina }}</v-col>
          <v-col cols="4">
            <v-icon
              :style="{
                color:
                  fase_sel_tmp._start == null ||
                  (fase_sel_tmp._start != null && fase_sel_tmp._stop != null)
                    ? 'red'
                    : 'green',
              }"
              >mdi-circle</v-icon
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="fase_sel.note_tempi"
              placeholder="Note Tempi"
              label="Note Tempi"
              outlined
              :rows="4"
            >
            </v-textarea>
          </v-col>
          <v-col>
            <v-textarea
              v-model="fase_sel.note_workorder"
              placeholder="Note Tempi"
              label="Note Work Order"
              outlined
              :rows="4"
              readonly
            >
            </v-textarea>
          </v-col>
        </v-row>

        <!-- Campo di selezione dell'attività di produzione -->
        <v-row align="center">
          <v-col cols="6">
            <v-select
              background-color="#ffffff"
              class="my-1"
              dense
              hide-details="auto"
              item-text="des"
              item-value="id_tabella"
              :items="causali"
              label="Attività di Produzione"
              outlined
              v-model="causale_sel"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-btn
              style="width: 200px; height: 60px"
              @click="clickStartStop(fase_sel_tmp)"
              :color="
                fase_sel_tmp._start == null ||
                (fase_sel_tmp._start != null && fase_sel_tmp._stop != null)
                  ? 'success'
                  : 'error'
              "
              dark
              ><h1 style="width: 100px">
                {{
                  fase_sel_tmp._start == null ||
                  (fase_sel_tmp._start != null && fase_sel_tmp._stop != null)
                    ? "START"
                    : "STOP"
                }}
              </h1></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import * as moment from "moment/moment";
export default {
  name: "GestioneLavorazione",
  components: {},
  props: {
    fase_sel: { type: Object, default: () => {} },
    visibile: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      causale_sel: null,
      cod_lotto: "ab123",
      fase_sel_tmp: {},
      cod_macchina_sel: null,

      // Modal
      conferma_dialog_function_ko: null,
      conferma_dialog_function_ok: null,
      conferma_dialog_color: "",
      conferma_dialog_text: "",
      conferma_dialog_title: "",
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      causali: [],
      macchine: [],
    };
  },
  methods: {
    annullaCambioCodMacchina() {
      this.cod_macchina_sel = this.fase_sel_tmp.cod_macchina;
    },
    clickChiudi() {
      // Prima di chiudere, riporto tutto alla situazione iniziale.

      this.cod_macchina_sel = null;
      this.fase_sel_tmp = {};

      // Emetto l'evento di chiusura.
      this.$emit("click_chiudi");
    },
    clickStartStop(elem) {
      if (this.causale_sel === null) {
        this.snackbar = true;
        this.snackbar_color = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Seleziona l'attività di produzione";
        this.snackbar_text_color = "white";
        return;
      } else if (
        elem._start == null ||
        (elem._start != null && elem._stop != null)
      ) {
        // Se ho iniziato a lavorare, stabilisco che ora è
        let adesso = moment();
        let orario_attivazione =
          adesso.date() +
          "/" +
          adesso.month() +
          "/" +
          adesso.year() +
          " " +
          ("0" + adesso.hour()).substr(-2) +
          ":" +
          ("0" + adesso.minutes()).substr(-2);

        // Aggiorno il work order
        elem._start = orario_attivazione;
        elem._stop = null;

        // Mando al backend l'aggiornamento
        this.setCommessaTempi(elem, "1");
      } else {
        // Se ho finito di lavorare, stabilisco che ora è
        let adesso = moment();
        let orario_attivazione =
          adesso.date() +
          "/" +
          adesso.month() +
          "/" +
          adesso.year() +
          " " +
          ("0" + adesso.hour()).substr(-2) +
          ":" +
          ("0" + adesso.minutes()).substr(-2);

        // Aggiorno il Work Order
        elem._stop = orario_attivazione;

        // Mando al backend l'aggiornamento
        this.setCommessaTempi(elem, "0");

        // Riporto i campi di start e stop a null
        elem._start = null;
        elem._stop = null;
      }
    },
    getCausaliProduzione() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "PRODUZIONE_TEMPI",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          res.data.forEach((elem) => {
            this.causali.push(elem);
            if (elem.ordine === 1) {
              this.causale_sel = elem.id_tabella;
            }
          });
        }
      });
    },
    getMacchine() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistamacchine",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.macchine = [];
        res.data.forEach((elem) => {
          this.macchine.push(elem);
        });

        /* Ora ho un array di macchine pieno e, se è il caso,
         * posso usarlo per ricavare un cod_macchina. */

        /* In base alla commessa selezionata assegno il cod_macchina iniziale.
         * A seconda del punto in cui ho richiamato questo componente, il BE
         * potrebbe non passarmi un cod_macchina; in quel caso ho comunque un
         * id_macchina e vedo di ricavare il cod_macchina dall'id_macchina. */
        if (this.fase_sel_tmp.cod_macchina != undefined) {
          this.cod_macchina_sel = this.fase_sel_tmp.cod_macchina;
        } else {
          this.macchine.forEach((elem) => {
            if (elem.id_macchina === this.fase_sel_tmp.id_macchina) {
              this.fase_sel_tmp.cod_macchina = elem.cod_macchina;
              this.cod_macchina_sel = elem.cod_macchina;
            }
          });
        }
      });
    },
    setCommessaTempi(commessa, start_stop) {
      // Compongo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessatempi",
          token: localStorage.getItem("user_token"),
          id_commessa: commessa.id_commessa,
          id_commessa_dettaglio: commessa.id_commessa_dettaglio,
          id_macchina: commessa.id_macchina,
          start: start_stop,
          id_causale: this.causale_sel,
          // note_workorder: this.fase_sel.note_workorder,
          note: this.fase_sel.note_tempi,
        },
      };
      // Consegno la request e gestisco la response
      this.$store.dispatch("api", request).then(() => {
        /* Quando ho ricevuto la risposta lancio i segnali
         * di aggiornamento agli altri componenti. */
        if (start_stop == 0) {
          /* Se ho fatto uno stop lo segnalo al bundler
           * padre di questo componente. */
          this.$emit("click_stop", commessa);

          /* Mando un segnale anche alla tabella
           * dei tempi, che è sorella di questo componente. */

          // Genero un evento custom con il nome che mi serve.
          let evento = new CustomEvent("click_stop");

          // Consegno alla mia destinazione l'evento
          document
            .getElementById("listener_tempi_di_lavorazione")
            .dispatchEvent(evento);
        } else {
          /* Se invece ho cominciato a lavorare lo faccio
           * sapere al componente padre. */
          this.$emit("click_start");

          /* Mando un messaggio anche al componente TempiDiLavorazione
           * che è fratello di questo componente. */

          // Genero un evento custom con il nome che mi serve.
          let evento = new CustomEvent("click_start");

          // Consegno alla mia destinazione l'evento
          document
            .getElementById("listener_tempi_di_lavorazione")
            .dispatchEvent(evento);
        }
      });
    },
  },
  watch: {
    visibile: {
      immediate: true,
      handler() {
        if (this.visibile == true) {
          // Registro il codice della macchina del WO.
          this.cod_macchina_sel = this.fase_sel.cod_macchina;

          // Popolo l'array delle macchine disponibili
          this.getMacchine();

          console.log(this.fase_sel);

          // Popolo l'array delle causali
          this.getCausaliProduzione();
          // Se la fase che mi viene passata è diversa da null...
          if (this.fase_sel != null) {
            // ... Faccio una copia temporanea della commessa selezionata
            this.fase_sel_tmp = Object.assign({}, this.fase_sel);

            if (this.fase_sel._start === undefined) {
              /* Se il parametro _start di fase_sel non c'è
               * vuol dire che sto caricando l'oggetto dalla
               * tabella dei tempi, quindi ricavo lo start e stop
               * da variabili con nomi diversi. */
              this.fase_sel_tmp._start = this.fase_sel.ora_start;
              this.fase_sel_tmp._stop = this.fase_sel.ora_stop;
            }
          }
        }
      },
    },
    cod_macchina_sel: {
      immediate: true,
      handler() {
        this.macchine.forEach((elem) => {
          if (elem.cod_macchina == this.cod_macchina_sel) {
            this.fase_sel_tmp.des_macchina = elem.des_macchina;
          }
        });

        /* In base al cod_macchina selezionato dall'utente, ricavo
         *  l'id_macchina che servirà per la chiamata al backend. */
        this.macchine.forEach((elem) => {
          if (elem.cod_macchina == this.cod_macchina_sel) {
            this.fase_sel_tmp.id_macchina = elem.id_macchina;
          }
        });
      },
    },
  },

  mounted() {},
};
</script>
<style>
</style>