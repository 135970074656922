<template>
  <v-container fluid style="padding: 20px">
    <v-row>
      <v-col md="12" class="py-0">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-data-table
                class="elevation-1"
                :headers="headers.filter((el) => el.visible == 1)"
                :items="lista"
                :search="search"
                fixed-header
                height="70vh"
              >
                <template v-slot:top>
                  <v-toolbar
                    class="mb-2 white--text rounded-t"
                    color="indigo"
                    dense
                  >
                    <v-toolbar-title class="white--text"
                      >Lista Progetti</v-toolbar-title
                    >
                    <v-switch
                      class="pl-5 pt-5 white--text"
                      color="white"
                      v-model="assistenza"
                      :true-value="1"
                      :false-value="0"
                      :label="assistenza ? 'Esterni Assistenza' : 'Interni'"
                      @change="getCompetenze()"
                    ></v-switch>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="setCompetenze"
                          small
                        >
                          <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>
                      </template>
                      <span>Salva</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="gotoDashboard"
                          small
                        >
                          <v-icon color="error">mdi-window-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Vai alla Dashboard</span>
                    </v-tooltip>
                  </v-toolbar>
                  <span
                    ><v-chip
                      >NB. Tipo Profilo = 'Gestore' gestisce tutti i ticket assistenza, se si ha Tipo Profilo = 'Cliente' si possono
                      solo creare e vedere i propri ticket</v-chip
                    ></span
                  >
                </template>

                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      v-for="(col, columnIndex) in headers.filter(
                        (el) => el.visible == 1
                      )"
                      :key="columnIndex"
                    >
                      <div
                        v-if="
                          columnIndex ==
                          headers.filter((el) => el.visible == 1).length - 1
                        "
                        :style="{
                          textAlign: 'left',
                        }"
                      >
                        <span
                          v-if="item[col.value] == 1"
                          @click="item[col.value] = 0"
                          ><v-btn class="my-0 pa-1 green" small>Gestore</v-btn></span
                        >
                        <span v-else @click="item[col.value] = 1" color="white"
                          ><v-btn class="my-0 pa-1" small>Cliente</v-btn></span
                        >
                      </div>
                      <div
                        v-else-if="columnIndex > 0"
                        :style="{
                          textAlign: 'left',
                        }"
                      >
                        <v-icon
                          v-if="item[col.value] == 1"
                          @click="item[col.value] = 0"
                          >mdi-check-underline</v-icon
                        >
                        <v-icon
                          v-else
                          @click="item[col.value] = 1"
                          color="white"
                          >mdi-close</v-icon
                        >
                      </div>

                      <div
                        :style="{
                          textAlign: columnIndex === 1 ? 'center' : 'left',
                        }"
                        v-else
                      >
                        {{ item[col.value] }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<style scoped>
label {
  color: white !important;
}
.v-label {
  color: white !important;
}
.v-input .v-label {
  color: white !important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: white !important;
}
</style>
<script>
export default {
  name: "ProgettiTicket",
  data() {
    return {
      assistenza: 0,
      headers: [],
      lista: [],
      search: "",
      snackbar: false,
      snackbar_background: "success",
      snackbar_icon: "mdi-check",
      snackbar_icon_color: "white",
      snackbar_text: "Progetti salvati correttamente",
      snackbar_text_color: "white",
    };
  },
  mounted() {
    this.getCompetenze();
  },
  computed: {
    widthMin() {
      return window.innerWidth < 960 ? true : false;
    },
  },
  methods: {
    gotoDashboard() {
      this.$router.push("/home");
    },
    setCompetenze() {
      var setcompetenze = {
        controller: "Global",
        method: "PUT",
        richiesta: {
          action: "setprogettiticket",
          token: localStorage.user_token,
          tabella: this.lista,
          assistenza: this.assistenza,
        },
      };
      console.log(JSON.stringify(setcompetenze.richiesta));
      this.$store
        .dispatch("api", setcompetenze)
        .then((res) => {
          if (res.status == 200) {
            this.snackbar = true;
            this.getCompetenze();
          } else {
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_icon = "mdi-close";
            this.snackbar_icon_color = "white";
            this.snackbar_text = res.status + " " + res.statusText;
            this.snackbar_text_color = "white";
            this.getCompetenze();
          }
        })
        .catch((ex) => {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = ex;
          this.snackbar_text_color = "white";
          this.getCompetenze();
        });
    },
    getCompetenze() {
      var getprogetti = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getprogettiticket",
          token: localStorage.user_token,
          assistenza: this.assistenza,
        },
      };
      this.$store.dispatch("api", getprogetti).then((res) => {
        console.log("ew", res);
        this.lista = res.data.tabella_profili;
        this.headers = res.data.header;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.v-data-table > .v-data-table__wrapper > table {
  min-height: 100vh;
  // max-height: 100vh;
}
.v-data-table__wrapper {
  max-height: 70vh !important;
  overflow-y: visible;
}
</style>