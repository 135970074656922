<template>
  <div id="app">    
    <v-snackbar
      v-model="this.$store.state.snackbar.stato"
      :color="this.$store.state.snackbar.colore"
      >{{ this.$store.state.snackbar.messaggio }}</v-snackbar
    >
    <router-view />
    <vue-progress-bar></vue-progress-bar>
    <Network />
  </div>
</template>
<script>
import StandardClasses from "@/components/COMMON/StandardClasses.js";
import Network from "@/components/GRAPH/Generale/Componenti/Network/Network.vue";
export default {
  components : { Network },
  beforeCreate(){
    // Definizione di acluni metodi extra per le classi standard
    StandardClasses.setup();
  },
  methods: {
    chiudiSnackBar() { this.$store.state.snackbar.stato = false; },
  },
};
</script>
<style>
/* Bug di vuetify per le tabelle all' interno del v-tabs-items dove la larghezza viene sfalsata perchè non recepisce la larghezza del box padre */
.v-tabs--vertical .v-window__container {
  height: 100vh !important;
}
.v-tabs--vertical .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100% !important;
}
.v-tabs--vertical .v-window-item {
  height: auto;
}
/* 
  Rende disponibile la barra laterale visibile dello scroll solo se è elemento dentro html ma non dell' elemento piu esterno ovvero <html></html> 
  Cosi non ci sono problemi di spazi aggiuntivi laterali resi disponibili per visualizzare la scrollbar
*/
html ::-webkit-scrollbar {
  width: 7px;
  display: block;
}
html > *::-webkit-scrollbar {
  display: none;
}
/*
  END
  Rende disponibile la barra laterale visibile dello scroll solo se è elemento dentro html ma non dell' elemento piu esterno ovvero <html></html> 
  Cosi non ci sono problemi di spazi aggiuntivi laterali resi disponibili per visualizzare la scrollbar
*/
#app {
   overflow-y: auto!important; 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
label {
  width: -moz-available;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(54, 143, 245);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(54, 143, 245);
}

*:hover::-webkit-scrollbar {
  width: 7px;
}
</style>
