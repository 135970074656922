<template>
  <v-tabs class="ml-n5" dense vertical>
    <v-navigation-drawer permanent expand-on-hover>
      <v-tab class="tabs">
        <v-icon class="pr-4">mdi-check-box-multiple-outline</v-icon>
        CARATTERISTICHE
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-menu</v-icon> CATEGORIA
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-script</v-icon>
        SPECIFICHE
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-package-variant-closed</v-icon> PACKAGING
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-assistant</v-icon> ASSOCIAZIONI
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-text</v-icon> DISTINTA BASE
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-circle-slice-5</v-icon> CICLO PRODUZIONE
      </v-tab>

      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-store</v-icon> MAGAZZINO
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-view-carousel</v-icon> UTILIZZO
      </v-tab>
      <v-tab v-show="data.id_articolo" class="tabs">
        <v-icon class="pr-4">mdi-cash</v-icon> COSTO
      </v-tab>
    </v-navigation-drawer>

    <v-tab-item :transition="false" :reverse-transition="false">
      <Caratteristiche
        v-on:ValChanged="valChanged($event)"
        :CodEnable="this.CodEnable"
        :data="this.data"
      />
    </v-tab-item>
    <v-tab-item :transition="false" :reverse-transition="false">
      <v-card flat>
        <v-card-text>
          <Categoria />
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item
      style="width: 92vw !important"
      :transition="false"
      :reverse-transition="false"
    >
      <v-card flat>
        <v-card-text>
          <Specifiche :data="this.data" />
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item
      style="width: 89.8vw !important"
      :transition="false"
      :reverse-transition="false"
    >
      <v-card flat>
        <v-card-text>
          <Packaging :data="this.data" :conteCod="this.contentConte" :imballoCod="this.imballoCod" />
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item
      style="width: 89.8vw !important"
      :transition="false"
      :reverse-transition="false"
    >
      <v-card flat>
        <v-card-text>
          <!-- <Associations :data="this.data"  :key="this.data" /> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item
      style="width: 90.5vw !important"
      :transition="false"
      :reverse-transition="false"
    >
      <v-card flat>
        <v-card-text>
          <!-- <BasicDistinct :pdata="this.data" :key="this.data"/> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item
      style="width: 92vw !important"
      :transition="false"
      :reverse-transition="false"
    >
      <v-card flat>
        <v-card-text>
          <!-- <CicoloProdList :data="this.data" /> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
    <!--<v-tab-item  :transition="false" :reverse-transition="false">
        <v-card  flat>
            <v-card-text>
                <Commesse />
            </v-card-text>
        </v-card>
    </v-tab-item>-->
    <v-tab-item :transition="false" :reverse-transition="false">
      <v-card flat>
        <v-card-text>
          <!-- <Magazzino /> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item :transition="false" :reverse-transition="false">
      <v-card flat>
        <v-card-text>
          <!-- <Utilizzo :pdata="this.data" /> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item :transition="false" :reverse-transition="false">
      <v-card flat>
        <v-card-text>
          <!-- <Costo /> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item :transition="false" :reverse-transition="false">
      <v-card flat>
        <v-card-text>
          <!-- <DatiExtra /> -->
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Caratteristiche from "./Caratteristiche2";
import Categoria from "./Categoria2";
import Specifiche from "./Specifiche2";
import Packaging from "./Packaging2";
// import Associations from "./Associazioni";
// import BasicDistinct from "./DistintaBase";
// import ProductionCycle from "./CicloProduzione";
//import Commesse from "./Commesse";
// import Magazzino from "./Magazzino";
// import Utilizzo from "./Utlizzo";
// import Costo from "./Costo";
// import DatiExtra from "./DatiExtra";
// import CicoloProdList from '../CicoloFasi/ArticoloCicoloFasiList'

export default {
  name: "ArticoloDrawer",
  components: {
    Caratteristiche,
    Categoria,
    Specifiche,
    Packaging,
    // Associations,
    // BasicDistinct,
    // ProductionCycle,
    //Commesse,
    // Magazzino,
    // Utilizzo,
    // Costo,
    // DatiExtra,
    // CicoloProdList
  },
  props: ["data", "contentConte", "imballoCod", "CodEnable"],
  data() {
    return {
      overlay1: false,
      items: [],
    };
  },
  methods: {
    valChanged(val) {
      console.log();
      this.$emit("valChanged", val);
    },
    // updateDrawer(val) {
    // console.log("cambio")
    //     this.$emit('updateDrawer', val)
    // },
    updatePackging(val) {
      this.$emit("updatePackging", val);
    },
  },
};
</script>

<style css  scoped>
.tabs {
  justify-content: start !important;
}
</style>
