<template>
	<v-container fluid class="py-0">
		<!-- Ragione Sociale Search Dialog -->
		<v-dialog persistent v-model="dialogRicercaAnagraficha" width="700">
			<!-- <v-overlay :value="overlay" style="z-index: -99">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
			<v-card>
				<v-toolbar class="indigo darken-1 headline" primary-title>
					<v-toolbar-title class="white--text">Risultato della ricerca</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn @click="dialogRicercaAnagraficha = false" small>
								<v-icon v-on="on" v-bind="attrs" depressed color="error">mdi-window-close</v-icon>
							</v-btn>
						</template>
						<span>Cancellare </span>
					</v-tooltip>
				</v-toolbar>
				<v-card-text style="max-height: 600px; overflow-y: auto">
					<v-data-table :loading="false" :headers="RagioneSocialeHeaders" :items="searchRes">
						<v-progress-linear
							v-show="progressBar"
							slot="progress"
							color="blue"
							indeterminate
						></v-progress-linear>
						<template v-slot:[`item.actions`]="props">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" @click="onSetItem(props.item)" small>
										<v-icon class="mx-2" dark color="#009900">
											mdi-check
										</v-icon>
									</v-btn>
								</template>
								<span> imposta Fornitura </span>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- To show the alerts  -->
		<v-snackbar content-class="snackbar" :color="snackbar_background" right v-model="snackbar" :timeout="3000">
			<v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
			<h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
				{{ snackbar_text }}
			</h2>
			<v-btn
				@click="snackbar = false"
				fab
				dark
				text
				small
				style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
			>
				<v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
			</v-btn>
		</v-snackbar>
		<!-- In order to show pdf files -->
		<v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
			<iframe :src="this.selectedPdf" frameborder="0" style="height: 80vh !important; width: 90vw !important">
			</iframe>
		</v-dialog>
		<!-- confirmation to delete a sinlge Packaging-->
		<v-dialog content-class="artDialog" persistent v-model="dialogEliminaElemento" width="unset">
			<v-card>
				<v-toolbar dense class="indigo darken-1 headline">
					<v-toolbar-title class="white--text">Eliminazione</v-toolbar-title>
				</v-toolbar>
				<v-card-text class="mt-6">
					<h3 style="text-align: left !important">
						Sei sicuro di voler eliminare questo elemento?
					</h3>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" dark @click="delSelectedItem">ANNULLA</v-btn>
					<v-btn color="green" dark @click="deletePack">CONFERMA</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog content-class="artDialog" persistent v-model="dialogElimina" width="1100">
			<v-card>
				<v-toolbar dense class="indigo darken-1 ml-n2" primary-title>
					<v-toolbar-title class="white--text"> Articolo </v-toolbar-title>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn @click="dialogElimina = false" small>
								<v-icon v-on="on" v-bind="attrs" depressed color="error">mdi-window-close</v-icon>
							</v-btn>
						</template>
						<span>Cancellare</span>
					</v-tooltip>
				</v-toolbar>
				<v-img
					aspect-ratio="1"
					:src="selectedImage"
					class="white--text align-end"
					gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
				/>
				<v-card-text> </v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog content-class="artDialog" persistent v-model="dialogAllegati" width="500">
			<v-card>
				<v-toolbar dense class="indigo darken-1" primary-title>
					<v-toolbar-title class="white--text">
						Aggiungi Articolo Allegati
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-tooltip right>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								small
								@click="
									setImmagine();
									dialogAllegati = false;
								"
							>
								<v-icon v-on="on" color="success" v-bind="attrs">
									mdi-floppy
								</v-icon>
							</v-btn>
						</template>
						<span>Salva</span>
					</v-tooltip>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn @click="dialogAllegati = false" small>
								<v-icon v-on="on" v-bind="attrs" depressed color="error">mdi-window-close</v-icon>
							</v-btn>
						</template>
						<span>Cancellare</span>
					</v-tooltip>
				</v-toolbar>
				<v-card-text>
					<v-text-field
						v-model="immagine_preview"
						@paste="onPaste"
						accept="image/x-png,image/gif,image/jpeg,image/jpg,image/pdf"
						label="Allegato"
						prepend-icon="mdi-camera"
						@click:prepend="openImagePicker"
					></v-text-field>
					<input type="file" v-on:change="onFileChange" ref="allegato" name="allegato" class="d-none" />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Dialog to Create or Update Packaging -->
		<v-dialog content-class="artDialog" persistent v-model="dialog" width="1410" class="packagingModal">
			<v-overlay :value="overlay" style="z-index: 10">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-card>
				<v-toolbar dense class="indigo darken-1 headline">
					<v-toolbar-title class="white--text"> Packging -- {{ this.data.cod_articolo }} </v-toolbar-title>
					<v-spacer></v-spacer>
					<Button
						classe_bottone="ml-auto"
						colore_icona="success"
						idForm="ARTICOLO_PACKAGING"
						idOggetto="SALVA_PACKAGING_ARTICOLO"
						mdi_icona="mdi-floppy"
						testo_tooltip="Salva Packaging"
						v-on:buttonClick="updatePackging"
					/>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								small
								@click="
									closePackModal();
									dialog = false;
								"
							>
								<v-icon v-on="on" v-bind="attrs" color="error" depressed>mdi-window-close</v-icon>
							</v-btn>
						</template>
						<span>Cancellare</span>
					</v-tooltip>
				</v-toolbar>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12" sm="12" md="5" class="py-0">
								<v-form>
									<v-container>
										<v-row class="">
											<v-col sm="2" md="2" class="py-0">
												<TextField
													idForm="PACKAGING"
													idOggetto="PK_COD_ANAGRAFICA"
													label_input="Codice"
													palceholder_input="Codice"
													:vmodel="codice"
													v-on:changeText="(val) => (this.codice = val)"
												/>
											</v-col>
											<v-col sm="5" md="5" class="py-0">
												<TextField
													append_outer_icon="mdi-magnify"
													idForm="PACKAGING"
													idOggetto="PK_COD_ANAGRAFICA"
													label_input="Ragione sociale F:"
													palceholder_input="Ragione sociale Fornitori"
													:vmodel="ragione_sociale_f"
													v-on:iconClick="searchAnagrafica()"
													v-on:changeText="(val) => (this.ragione_sociale_f = val)"
												/>
											</v-col>
											<v-col sm="5" md="5" class="py-0">
												<TextField
													append_outer_icon="mdi-magnify"
													v-on:iconClick="searchragioneSociale()"
													idForm="PACKAGING"
													idOggetto="PK_COD_ANAGRAFICA"
													label_input="Ragione sociale C:"
													palceholder_input="Ragione sociale Clienti"
													:vmodel="ragione_sociale_c"
													v-on:changeText="(val) => (this.ragione_sociale_c = val)"
												/>
											</v-col>
											<v-col sm="6" md="4" class="py-0">
												<TextField
													idForm="PACKAGING"
													idOggetto="PK_QTA_CONTENITORE"
													label_input="Q.ta Contenitore"
													palceholder_input="Q.ta Contenitore"
													:vmodel="qta_contenitore"
													v-on:changeText="(val) => (this.qta_contenitore = val)"
													:min_input="0"
													type_input="number"
												/>
											</v-col>
											<v-col md="6" class="py-0">
												<v-autocomplete
													v-model="id_articolo_contenitore"
													:items="contentoreList"
													label="Contenitore"
													placeholder="contenitore"
													item-text="cod_articolo"
													required
													item-value="id_articolo"
												>
												</v-autocomplete>
											</v-col>
											<v-col cols="2" xs="2" sm="6" md="2" class="py-0">
												<div>
													<span
														style="
                              margin-left: -1rem;
                              margin-bottom: 1rem;
                              font-size: 12px;
                              color: rgba(0, 0, 0, 0.6);
                            "
														>Impilabile</span
													>
													<v-switch
														v-model="Impilabile"
														class="mt-n1"
														:false-value="0"
														:true-value="1"
													></v-switch>
												</div>
											</v-col>
											<v-col md="2" sm="4" class="py-0">
												<TextField
													idForm="PACKAGING"
													idOggetto="PK_QTA_MIN"
													label_input="Q.ta Min"
													palceholder_input="Q.ta Min"
													:vmodel="qta_min"
													v-on:changeText="(val) => (this.qta_min = val)"
													:min_input="0"
													type_input="number"
												/>
											</v-col>
											<v-col md="3" sm="4" class="py-0">
												<TextField
													idForm="PACKAGING"
													idOggetto="PK_QTA_IMBALLO"
													label_input="Q.ta Imballo"
													palceholder_input="Q.ta Imballo"
													:vmodel="qta_imballo"
													v-on:changeText="(val) => (this.qta_imballo = val)"
													:min_input="0"
													type_input="number"
												/>
											</v-col>
										</v-row>
									</v-container>
								</v-form>
								<v-container>
									<v-row class="py-0 ml-2">
										<v-col class="text-left">
											<h1 class="v-label">BOM imballo</h1>
										</v-col>
										<v-col>
											<v-spacer></v-spacer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn @click="dialogRicerca = true" class="mt-n3 ml-n12" small>
														<v-icon v-on="on" v-bind="attrs" color="#1a80b6"
															>mdi-plus</v-icon
														>
													</v-btn>
												</template>
												<span>Aggiungi Distinta</span>
											</v-tooltip>
										</v-col>
									</v-row>
								</v-container>
								<v-treeview
									activatable
									shaped
									hoverable
									:key="1"
									:items="this.distintaItems"
									item-key="id"
									content="6"
									open-on-click
									class="text-left ml-n7 mt-n5"
								>
									<template v-slot:prepend="{ item, open }">
										<v-icon v-if="item.id == 0">{{ open ? 'mdi-buffer' : 'mdi-buffer' }}</v-icon>
										<v-icon v-if="item.id > 0">{{
											open ? 'mdi-text-short' : 'mdi-text-short'
										}}</v-icon>
										<template v-if="item.id >= -1"
											>N°{{ item.id }} {{ item.quantita }} {{ item.cod_articolo }}</template
										>
									</template>
									<template slot="append" slot-scope="{ item }">
										<v-tooltip left>
											<template v-slot:activator="{ on }">
												<v-btn
													@click="showModal"
													v-on="on"
													v-if="item.id_rif == 1"
													icon
													color="#1a80b6"
													small
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</template>
											<span>Inserisci Componente</span>
										</v-tooltip>
									</template>
								</v-treeview>
							</v-col>
							<v-divider
								color="#1565C0"
								class="ml-n1"
								aria-orientation="vertical"
								:vertical="true"
							></v-divider>
							<v-col md="5" sm="12" cols="12">
								<v-container>
	<v-list class="overflow-y-auto"  style="max-height:230px;background-color:transparent;" dense flat>
									<header>Istruzioni imballo</header>
									<v-list-item-group class="text-left" v-model="item" color="primary">
										<v-list-item  v-for="(item, i) in listItem" :key="i">
											<v-list-item-content>
												<v-list-item-subtitle class="text-wrap">
													<v-container>
														<v-row>
															<v-col class="" md="10">
																<h4 class="">
																	<span>{{ item.indice }}</span
																	>. {{ item.des_istruzione }}
																</h4>
															</v-col>
															<v-col class="" md="2" style="float: right">
																<v-tooltip bottom>
																	<template v-slot:activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			style="float: left"
																			color="primary"
																			@click="edittodo(item)"
																			>mdi-pencil</v-icon
																		>
																	</template>
																	<span>Aggiornare</span>
																</v-tooltip>
																<v-tooltip bottom>
																	<template v-slot:activator="{ on, attrs }">
																		<v-icon
																			@click="delinstruction(item)"
																			v-on="on"
																			v-bind="attrs"
																			color="red"
																		>
																			mdi-window-close
																		</v-icon>
																	</template>
																	<span>Cancellare</span>
																</v-tooltip>
															</v-col>
														</v-row>
													</v-container>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list-item-group>
								</v-list>
								</v-container>

								<v-row class="d-flex justify-center">
									<v-col md="7">
										<TextField
											idForm="PACKAGING"
											idOggetto="PK_ISTRUZIONE"
											label_input="Aggiungi Istruzioni"
											palceholder_input="Aggiungi Istruzioni"
											:vmodel="des_istruzione"
											v-on:changeText="(val) => (this.des_istruzione = val)"
										/>
									</v-col>
									<v-col md="3" class="mt-4">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn small @click="addistruzione">
													<v-icon v-if="showsaveicon" color="success">mdi-floppy</v-icon>
													<v-icon
														v-if="!showsaveicon"
														v-on="on"
														v-bind="attrs"
														color="#1a80b6"
														>mdi-plus</v-icon
													>
												</v-btn>
											</template>
											<span v-if="!showsaveicon">Aggiungi Istruzioni</span>
											<span v-if="showsaveicon">aggiornamento delle istruzioni</span>
										</v-tooltip>
									</v-col>
								</v-row>
							</v-col>
							<v-divider color="#1565C0" aria-orientation="vertical" :vertical="true"></v-divider>
							<v-col md="2" sm="12" cols="12" class="mt-n5">
								<v-btn
									style="margin-top: 2.7rem; margin-left: 4rem !important"
									height="30"
									small
									@click="openImgModal"
								>
									<v-icon color="blue"> mdi-paperclip </v-icon>
								</v-btn>
								<v-container style="width:125% !important">
									<v-list class="overflow-y-auto" content-class="listtoscroll" dense flat>
										<v-list-item-group class="" color="primary">
											<v-list-item v-for="(item, i) in immagineLista" :key="i">
												<div
													v-if="!isPdf(item.immagine)"
													v-viewer
													class="images clearfix"
													style="
                            height: 100px;
                            border: solid 1px silver;
                            margin-top: 0.4rem;
                          "
												>
													<img
														:lazy-src="item.immagine"
														aspect-ratio="1"
														class="grey lighten-2 mb-2"
														style="height: 100px; width: 120% !important"
														:src="item.immagine"
													/>
												</div>
												<div
													@click="openPdfAppenaCaricato(item.immagine)"
													style="
                            height: 40px !important;
                            width: 250px !important;
                            margin-right: -10rem !important;
                          "
													v-if="isPdf(item.immagine)"
												>
													<div style="text-align: center">
														<v-spacer></v-spacer>
														<v-icon
															style="font-size: 8rem !important"
															color="error"
															x-large
														>
															mdi-file-pdf-box
														</v-icon>
													</div>
												</div>
												<v-btn class="ml-6" x-small>
													<v-icon @click="delimage(item)" color="error">mdi-window-close</v-icon>
												</v-btn>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-container>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions> </v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Search Dialog for imballo list -->
		<v-dialog content-class="artDialog" persistent v-model="dialogRicerca" width="700">
			<v-overlay :value="overlay" style="z-index: 10">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-card>
				<v-toolbar dense class="indigo darken-1 headline" primary-title>
					<v-toolbar-title class="white--text"> Cerca Imballo </v-toolbar-title>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn @click="invertDialog" small>
								<v-icon v-on="on" v-bind="attrs" depressed color="red">mdi-window-close</v-icon>
							</v-btn>
						</template>
						<span>Cancellare</span>
					</v-tooltip>
				</v-toolbar>
				<v-card-subtitle>
					<v-row>
						<v-col md="11">
							<v-text-field
								type="text"
								class="input-class"
								v-on:keydown.enter.prevent="searchImballo"
								v-model="cod_imballo"
								label="Cod.Imballo"
								placeholder="Inserisci il Codice"
								required
							></v-text-field>
						</v-col>
						<v-btn class="mt-8" small @click="searchImballo">
							<v-icon class="m" color="#1a80b6" style="transition: none !important" size="24"
								>mdi-magnify</v-icon
							>
						</v-btn>
					</v-row>
				</v-card-subtitle>
				<v-card-text style="max-height: 450px; overflow-y: auto">
					<v-data-table :loading="true" v-if="this.items1.length > 0" :headers="headers1" :items="items1">
						<v-progress-linear
							v-show="progressBar"
							slot="progress"
							color="blue"
							indeterminate
						></v-progress-linear>
						<template v-slot:[`item.actions`]="props">
							<v-btn class="mx-2" small @click="onDetail(props.item)">
								<v-icon color="#009900" dark>mdi-check</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		<form>
			<v-container>
				<v-row class="justify-center">
					<v-col cols="8">
						<v-hover>
							<template v-slot="{ hover }">
								<v-card :elevation="hover ? 24 : 2">
									<v-container>
										<v-row class="justify-center">
											<v-col md="3">
												<v-autocomplete
													v-model="data.id_articolo_contenitore_standard"
													:items="contentoreList"
													item-text="cod_articolo"
													item-value="id_articolo"
													required
													placeholder="Contenitore"
													label="Contenitore"
												>
												</v-autocomplete>
											</v-col>
											<v-col md="2">
												<TextField
													idForm="PACKAGING"
													idOggetto="PK_QTA_CONTENITORE_STD"
													label_input="Quantità"
													palceholder_input="Quantità"
													:vmodel="data.qta_contenitore_standard"
													v-on:changeText="(val) => (data.qta_contenitore_standard = val)"
													:min_input="0"
													type_input="number"
												/>
											</v-col>
											<v-col md="3">
												<v-autocomplete
													v-model="data.id_articolo_imballo_standard"
													:items="imballoList"
													item-text="cod_articolo"
													item-value="id_articolo"
													required
													placeholder="Imaballo"
													label="Imballo"
												>
												</v-autocomplete>
											</v-col>
											<v-col md="2">
												<TextField
													idForm="PACKAGING"
													idOggetto="PK_QTA_IMBALLO_STD"
													label_input="Quantità"
													palceholder_input="Quantità"
													:vmodel="data.qta_imballo_standard"
													v-on:changeText="(val) => (data.qta_imballo_standard = val)"
													:min_input="0"
													type_input="number"
												/>
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</template>
						</v-hover>
					</v-col>
					<v-col cols="12">
						<v-hover>
							<template v-slot="{ hover }">
								<v-card :elevation="hover ? 24 : 2" style="width: 100% !important">
									<v-toolbar dense class="indigo darken-1 headline" primary-title>
										<v-toolbar-title class="white--text">Packaging</v-toolbar-title>
										<v-spacer></v-spacer>
										<Button
											colore_icona="primary"
											idForm="ARTICOLO_PACKAGING"
											idOggetto="CREA_PACKAGING_ARTICOLO"
											mdi_icona="mdi-plus"
											testo_tooltip="Aggiungi Packaging"
											v-on:buttonClick="openModal"
										/>
										<v-spacer></v-spacer>
									</v-toolbar>
									<v-container>
										<v-row>
											<v-col md="12">
												<v-data-table :loading="loading1" :items="items" :headers="headers">
													<v-progress-linear
														v-show="progressBar1"
														slot="progress"
														color="blue"
														indeterminate
													></v-progress-linear>
													<template v-slot:[`item.edit`]="{ item }">
														<Button
															colore_icona="primary"
															idForm="ARTICOLO_PACKAGING"
															idOggetto="MODIFICA_PACKAGING_ARTICOLO"
															mdi_icona="mdi-pencil"
															style="float: left"
															testo_tooltip="Aggiornare"
															v-on:buttonClick="handleclick(item)"
														/>
													</template>
													<template v-slot:[`item.print`]="{}">
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn small style="float: left">
																	<v-icon v-on="on" v-bind="attrs" color="#1a80b6"
																		>mdi-printer</v-icon
																	>
																</v-btn>
															</template>
															<span>Stampa</span>
														</v-tooltip>
													</template>
													<template v-slot:[`item.actions`]="{ item }">
														<Button
															colore_icona="error"
															idForm="ARTICOLO_PACKAGING"
															idOggetto="ELIMINA_PACKAGING_ARTICOLO"
															mdi_icona="mdi-window-close"
															testo_tooltip="Cancellare"
															v-on:buttonClick="PackgingToDel(item)"
														/>
													</template>
												</v-data-table>
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</template>
						</v-hover>
					</v-col>
				</v-row>
			</v-container>
		</form>
		<!-- loader -->
		<v-overlay :value="overlay" style="">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</v-container>
</template>
<script>
import TextField from '@/components/TOOLS/FormComponentPermissions/TextField.vue';
import Button from '@/components/TOOLS/FormComponentPermissions/Button.vue';
export default {
	props: ['conteCod', 'imballoCod', 'data'],
	components: { Button, TextField },
	data() {
		return {
			// array for storing imballo and contentitore
			isFornitura: false,
			items_anagrafiche: [],
			Imb_Conte_list: [],
			deleteImages:[],
			ragione_sociale_c: '',
			dialogRicercaAnagraficha: false,

			// Snackbar Variables
			snackbar: false,
			snackbar_background: '',
			snackbar_icon: '',
			snackbar_icon_color: '',
			snackbar_text: '',
			snackbar_text_color: '',
			// Pdf Dialog
			pdfdialog: false,
			// dialog for delete confirmation dialog
			dialogEliminaElemento: false,
			selectedPackaging: {},
			// Deleted Instruction Array
			deletedInstructions: [],
			// ACtion card heafer
			overlay: false,
			allegato: [],
			progressBar: false,
			progressBar1: false,
			loading: false,
			loading1: false,
			dialogAllegati: false,
			dialogElimina: false,
			dialogRicerca: false,
			selectedImage: '',
			selectedPdf: '',
			item: '',
			codice: '',
			immagine: '',
			showsaveicon: false,
			immagineLista: [],
			anagraficaList: [],
			distintaItems: [],
			cod_imballo: '',
			id_articolo_packaging: '',
			id_articolo_contenitore_standard: this.data,
			id_articolo_contenitore: '',
			Impilabile: false,
			des_imballo: '',
			des_contenitore: '',
			selcetedItem: {},
			ragione_sociale_f: '',
			id_anagrafica: null,
			id_articolo_imballo: 0,
			qta_contenitore: 0,
			qta_imballo: 0,
			qta_minima_spedizione: 0,
			qta_min: 0,
			dialog: false,
			contentore_list: [],
			contentoreList: [],
			imballoList: [],
			items: [],
			items1: [],
			des_istruzione: '',
			cod_articolo: '',
			num_riga: 1,
			id_pack_istruzioni: null,
			selectedIns: {},
			immagine_preview: '',
			// packaging Table Header
			headers: [
				{
					value: 'edit',
					align: 'start',
					sortable: false,
				},
				{
					value: 'print',
					sortable: false,
				},
				{
					text: 'Ragione Sociale',
					value: 'ragione_sociale',
					sortable: false,
				},
				{
					text: 'Tipo Contenitore',
					value: 'des_contenitore',
					sortable: false,
				},
				{
					text: 'Q.ta Contenitore',
					value: 'qta_contenitore',
					sortable: false,
				},
				{
					text: 'Tipo Imballo',
					value: 'des_imballo',
					sortable: false,
				},
				{
					text: 'Q.ta Imballo',
					value: 'qta_imballo',
					sortable: false,
				},
				{
					text: 'Q.ta Minima Spedizione',
					value: 'qta_minima_spedizione',
					sortable: false,
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
				},
			],
			//Imballo Search table header
			headers1: [
				{
					text: '',
					value: 'actions',
					sortable: false,
				},
				{
					text: 'Codice Articolo',
					value: 'cod_articolo',
					sortable: false,
				},
				{
					text: 'Des Ariticolo',
					value: 'des_articolo',
					sortable: false,
				},
				{
					text: 'Fam Articolo',
					value: 'fam_articolo',
					sortable: false,
				},
			],
			listItem: [],
			items2: [],
			searchRes: [],
			// Table headers for Forntiura search Modal
			RagioneSocialeHeaders: [
				{
					align: 'start',
					text: '',
					value: 'actions',
					sortable: false,
				},
				{
					text: 'Tipo',
					value: '_tipo',
					sortable: false,
				},
				{
					text: 'Codice',
					value: 'codice',
					sortable: false,
				},
				{
					text: 'Ragione sociale',
					value: 'ragione_sociale',
					sortable: false,
				},
				{
					text: 'Indrizzo',
					value: 'indirizzo',
					sortable: false,
				},
			],
			item3: [],
		};
	},
	watch: {
		data(val) {
			this.$emit('update', val);
		},
	},
	methods: {
	// delete Immagine
        delimage(item){
			item.validita = 0
           this.deleteImages.push(item)
		let i = this.immagineLista.findIndex(it=>it==item)
		this.immagineLista.splice(i,1)
		},
		// setting the cliente or fronitore codice * ragion_sociale
		onSetItem(item) {
			this.id_anagrafica = item.id_anagrafica;
			if (!this.isFornitura) {
				this.ragione_sociale_c = item.ragione_sociale;
				this.codice = item.codice;

				this.ragione_sociale_f = '';
			} else {
				this.ragione_sociale_c = '';
				this.ragione_sociale_f = item.ragione_sociale;

				this.codice = item.codice;
			}
			this.dialogRicercaAnagraficha = false;
			this.isFornitura = false;
		},
		searchragioneSociale() {
			let searchReq = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getanagrafica',
					token: localStorage.getItem('user_token'),
					tipo: 'C',
					db_name: 'quickproduction',
					id_utente: localStorage.getItem('id'),
				},
			};
			// this.overlay = true;
			this.$store.dispatch('api', searchReq).then((res) => {
				this.searchRes = res.data;
				this.dialogRicercaAnagraficha = true;
				console.log('res.data :>> ', res.data);
			});
		},
		// In order to search Anagraficai
		searchAnagrafica() {
			let searchReq = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getragionesociale',
					cod_anagrafica: this.codice,
					des_anagrafica: this.ragione_sociale_c,
					tipo_anagrafica: 'F',
					token: localStorage.getItem('user_token'),
				},
			};
			this.overlay = true;
			this.$store.dispatch('api', searchReq).then((res) => {
				this.isFornitura = true;
				this.dialogRicercaAnagraficha = true;
				this.overlay = false;
				this.searchRes = res.data;
				if (this.searchRes.length === 0) {
					this.snackbar = true;
					this.snackbar_text = 'Ragione Sociale non è stato trovato. Riprova !';
					this.mainIcon = 'mdi-close-circle';
					this.color = 'red accent-4';
					this.progressBar = false;
					this.dialogRicercaAnagraficha = false;
				}
				if (this.searchRes.length > 0) {
					this.progressBar = true;
				}
			});
			this.client = false;
		},
		// PDF MOdal
		isPdf(elem) {
			if (elem) {
				if (elem.startsWith('data:application/pdf')) {
					return true;
				} else {
					return false;
				}
			}
		},
		// to open Modal for PDF
		openPdfAppenaCaricato(item) {
			if (item.startsWith('data:application/pdf')) {
				this.selectedPdf = item;
				this.pdfdialog = true;
			}
		},
		// In order to open pop up Modal
		PackgingToDel(item) {
			this.selectedPackaging = item;
			this.dialogEliminaElemento = true;
		},
		// TO clear item selected to delete when click Annulla button
		delSelectedItem() {
			this.dialogEliminaElemento = false;
			this.selectedPackaging = {};
		},
		setIndiceRicorsivo(array) {
			array.forEach((elem) => {
				elem.indice = array.indexOf(elem) + 1;
			});
		},
		clearImage() {
			this.dialogRicerca = false;
		},
		openImgModal() {
			this.dialogAllegati = true;
			this.immagine_preview = '';
			// alert(this.immagine_preview)
		},
		// To Delete a single instruction
		delinstruction(item) {
			if (this.showsaveicon) {
				this.showsaveicon = false;
			}
			if (item.id_pack_istruzioni > -1) {
				item.validita = 0;
				this.deletedInstructions.push(item);
				let i = this.listItem.findIndex((it) => it === item);
				this.listItem.splice(i, 1);
			} else {
				let i = this.listItem.findIndex((it) => it === item);
				this.listItem.splice(i, 1);
			}

			this.setIndiceRicorsivo(this.listItem);
		},
		// closing dialog
		invertDialog() {
			this.dialogRicerca = false;
		},
		retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
			if (pasteEvent.clipboardData == false) {
				if (typeof callback == 'function') {
					callback(undefined);
				}
			}
			var items = pasteEvent.clipboardData.items;
			if (items == undefined) {
				if (typeof callback == 'function') {
					callback(undefined);
				}
			}
			for (var i = 0; i < items.length; i++) {
				// Skip content if not image
				if (items[i].type.indexOf('image') == -1) continue;
				// Retrieve image on clipboard as blob
				var blob = items[i].getAsFile();
				// Create an abstract canvas and get context
				var mycanvas = document.createElement('canvas');
				var ctx = mycanvas.getContext('2d');
				// Create an image
				var img = new Image();
				// Once the image loads, render the img on the canvas
				img.onload = function() {
					// Update dimensions of the canvas with the dimensions of the image
					mycanvas.width = this.width;
					mycanvas.height = this.height;
					// Draw the image
					ctx.drawImage(img, 0, 0);
					// Execute callback with the base64 URI of the image
					if (typeof callback == 'function') {
						callback(mycanvas.toDataURL(imageFormat || 'image/png'));
					}
				};
				// Crossbrowser support for URL
				var URLObj = window.URL || window.webkitURL;
				// Creates a DOMString containing a URL representing the object given in the parameter
				// namely the original Blob
				img.src = URLObj.createObjectURL(blob);
			}
		},
		onPaste(e) {
			if (e.clipboardData != false) {
				var items = e.clipboardData.items;
				if (items != undefined) {
					for (var i = 0; i < items.length; i++) {
						// Skip content if not image
						if (items[i].type.indexOf('image') == -1) continue;
					}
				}
			}
			this.retrieveImageFromClipboardAsBase64(e, function(imageDataBase64) {
				this.immagine_preview = imageDataBase64;
			});
		},
		openImagePicker() {
			this.$refs.allegato.click();
		},
		hasExtension(file, exts) {
			return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(file.name);
		},
		onFileChange(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;
			if (this.hasExtension(files[0], ['.jpeg', '.gif', '.png', '.jpg'])) {
				this.allegato = files[0];
				var reader = new FileReader();
				reader.readAsDataURL(this.allegato);
				let that = this;
				reader.onload = function() {
					that.immagine_preview = reader.result;
					let data = {};
					data.id_articolo_packaging = that.id_articolo_packaging != '' ? that.id_articolo_packaging : -1;
					data.id_pack_immagini = -1;
					data.id_utente = localStorage.id;
					data.immagine = that.immagine_preview;
					data.validita = 1;
					that.immagineLista.push(data);
					that.dialogAllegati = false;
					return that.immagineLista;
				};
				reader.onerror = function(error) {
					throw error;
				};
			} else {
				this.dialogRicerca = false;
				this.snackbar = true;
				this.snackbar_background = 'orange';
				this.snackbar_icon = 'mdi-alert';
				this.snackbar_icon_color = 'white';
				this.snackbar_text = ' Tipo di file non accettato.';
				this.snackbar_text_color = 'white';
			}
		},
		// Setting value of existing todo to update it.
		edittodo(item) {
			this.id_pack_istruzioni = item.id_pack_istruzioni;
			this.showsaveicon = true;
			this.selectedIns = item;
			this.des_istruzione = item.des_istruzione;
		},
		// Setting Codice value
		updateCodice(item) {
			this.anagraficaList.map((ana) => {
				if (ana.ragione_sociale === item) {
					this.codice = ana.codice;
				}
			});
		},
		// In order to close Packaging modal
		closePackModal() {
			this.immagineLista = [];
			this.listItem = [];
			this.distintaItems = [];
			this.ragione_sociale_f = [];
			this.ragione_sociale_c = null;
			this.codice = null;
			this.qta_contenitore = '';
			this.qta_imballo = '';
			this.qta_minima_spedizione = '';
			this.qta_min = '';
		},
		// Getting the distina base of imballo item
		onDetail(item) {
			this.id_articolo_imballo = item.id_articolo;
			let getdistintabase = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getarticolidistintabase',
					id_articolo: item.id_articolo,
					token: localStorage.getItem('user_token'),
				},
			};
			this.$store.dispatch('api', getdistintabase).then((res) => {
				this.distintaItems = res.data;
				this.des_imballo = this.distintaItems[0].name;
				if (this.distintaItems !== []) {
					this.dialogRicerca = false;
				}
			});
		},
		// In order to search imballo item to display distinta base
		searchImballo() {
			console.log('this.imballoList[0] :>> ', this.imballoList[0]);
			let imballoSearch = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getarticoli',
					token: localStorage.getItem('user_token'),
					cod_articolo: '*',
					id_tipo: this.Imb_Conte_list[1].id_tabella,
				},
			};
			this.overlay = true;
			this.$store.dispatch('api', imballoSearch).then((res) => {
				this.overlay = false;
				this.items1 = res.data.lista;
				if (this.items1.length > 0) {
					this.progressBar = false;
				}
			});
		},
		// In order to clear form field when closing modal using x
		closeMainModal() {
			if (this.dialog === true) {
				this.dialog = false;
				this.distintaItems = [];
				this.qta_min = '';
				this.qta_imballo = '';
				this.qta_contenitore = '';
				this.des_contenitore = '';
				this.ragione_sociale_c = '';
				this.ragione_sociale_f = '';
				this.codice = '';
				this.id_articolo_packaging = '';
			}
		},
		// In order to get single object detail from packaging list
		handleclick(val) {
			this.selcetedItem = val;
			this.dialog = true;
			this.qta_min = val.qta_minima_spedizione;
			this.qta_imballo = val.qta_imballo;
			this.qta_contenitore = val.qta_contenitore;
			this.des_contenitore = val.des_contenitore;
			this.ragione_sociale_f = val.ragione_sociale;
			this.id_articolo_packaging = val.id_articolo_packaging;
			this.codice = val.codice;
			this.id_articolo_imballo = val.id_articolo_imballo;
			this.id_articolo_contenitore = val.id_articolo_contenitore;
			//  Getting selected imballo articolo distinta base
			if (val.id_articolo_imballo != null) {
				let getdistintabase = {
					controller: 'Magazzino',
					method: 'POST',
					richiesta: {
						action: 'getarticolidistintabase',
						id_articolo: val.id_articolo_imballo,
						token: localStorage.getItem('user_token'),
					},
				};
				this.$store.dispatch('api', getdistintabase).then((res) => {
					this.distintaItems = res.data;
				});
			}
			// In order to list of packaging instructions
			if (val.nr_istruzioni !== 0) {
				let getintro = {
					controller: 'Magazzino',
					method: 'POST',
					richiesta: {
						action: 'getarticolipackagingistruzioni',
						id_articolo_packaging: val.id_articolo_packaging,
						token: localStorage.getItem('user_token'),
					},
				};
				this.$store.dispatch('api', getintro).then((res) => {
					this.listItem = res.data;
					this.setIndiceRicorsivo(this.listItem);
					this.listItem.map((it, index, array) => {
						if (index === array.length - 1) {
							this.num_riga = it.num_riga;
						}
						this.num_riga++;
					});
				});
			}
			if (val.nr_immagini !== 0) {
				let getImmagini = {
					controller: 'Magazzino',
					method: 'POST',
					richiesta: {
						action: 'getarticolipackagingimmagini',
						token: localStorage.getItem('user_token'),
						id_articolo_packaging: val.id_articolo_packaging,
					},
				};
				this.$store.dispatch('api', getImmagini).then((res) => {
					this.immagineLista = res.data;
					console.log('this.imm :>> ', this.immagineLista);
				});
			}
		},
		// Open Add or edit packaging form
		openModal() {
			this.dialog = true;
			this.selcetedItem = {};
			this.num_riga = 1;
			this.qta_min = '';
			this.qta_imballo = '';
			this.qta_contenitore = '';
			this.des_contenitore = '';
			this.ragione_sociale_f = '';
			this.ragione_sociale_c = '';
			this.id_articolo_contenitore = '';
			this.id_articolo_imballo = '';
			this.id_articolo_packaging = '';
			this.immagineLista = [];
			this.listItem = [];
			this.codice = '';
		},
		// SAving the images
		setImmagine() {
			const image = localStorage.getItem('ArticoloImage');
			let data = {};
			data.immagine = image;
			data.id_pack_immagini = -1;
			data.validita = 1;
			data.id_articolo_packaging = this.id_articolo_packaging !== '' ? this.id_articolo_packaging : -1;
			this.immagineLista.push(data);
			localStorage.removeItem('ArticoloImage');
			this.immagine = '';
		},
		// in order to change image into base64
		getBase64() {
			var dataBase64;
			var reader = new FileReader();
			reader.readAsDataURL(this.immagine);
			reader.onload = function() {
				dataBase64 = reader.result;
				localStorage.setItem('ArticoloImage', dataBase64);
			};
		},
		// Adding instruction to Packaging
		addistruzione() {
			if (this.id_pack_istruzioni) {
				// Procedura per modifica di istruzione già presente
				console.log('entrato 1: ' + this.id_pack_istruzioni);
				const data = {};
				data.num_riga = this.selectedIns.num_riga;
				data.des_istruzione = this.des_istruzione;
				data.validita = 1;
				data.id_utente = localStorage.id;
				data.id_pack_istruzioni = this.selectedIns.id_pack_istruzioni;
				data.id_articolo_packaging = this.id_articolo_packaging !== '' ? this.id_articolo_packaging : -1;
				const i = this.listItem.findIndex((it) => it === this.selectedIns);
				this.listItem.splice(i, 1, data);
				this.selectedIns = {};
				this.id_pack_istruzioni = null;
				this.des_istruzione = '';
				this.setIndiceRicorsivo(this.listItem);
			} else {
				// Procedura per inserimento nuova istruzione
				console.log('entrato 2: ' + this.id_pack_istruzioni);
				const data = {};
				data.num_riga = this.num_riga;
				data.des_istruzione = this.des_istruzione;
				data.validita = 1;
				data.id_utente = localStorage.id;
				data.id_pack_istruzioni = -1;
				data.id_articolo_packaging = this.id_articolo_packaging !== '' ? this.id_articolo_packaging : -1;
				if (data.des_istruzione === '') {
					return;
				}
				this.listItem.push(data);
				this.setIndiceRicorsivo(this.listItem);
				this.num_riga++;
				this.des_istruzione = '';
			}
			this.showsaveicon = false;
		},
		// In order to delete single packaging
		deletePack() {
			this.overlay = true;
			let data = {};
			data.id_articolo_packaging = this.selectedPackaging.id_articolo_packaging;
			data.validita = 0;
			let delPackaging = {
				controller: 'Magazzino',
				method: 'PUT',
				richiesta: {
					action: 'setarticolopackaging',
					token: localStorage.getItem('user_token'),
					id_articolo: this.data.id_articolo,
					articolo_packaging: [data],
					articolo_packaging_immagini: [],
					articolo_packaging_istruzioni: [],
				},
			};
			this.$store.dispatch('api', delPackaging).then(() => {
				this.overlay = false;
				this.snackbar = true;
				this.snackbar_background = 'success';
				this.snackbar_icon = 'mdi-check';
				this.snackbar_icon_color = 'white';
				this.snackbar_text = 'Modifica effettuata con successo!';
				this.snackbar_text_color = 'white';
			});
			let i = this.items.findIndex((it) => it.id_articolo_packaging === data.id_articolo_packaging);
			this.items.splice(i, 1);
			this.dialogEliminaElemento = false;
			//  this.FetchPackaging()
		},
		// in order to create or update packaging
		updatePackging() {
			this.overlay = true;
			if (!this.selcetedItem.id_articolo_packaging) {
				let dataobj = {};
				if (!this.id_articolo_imballo) {
					this.snackbar = true;
					this.snackbar_background = 'orange';
					this.snackbar_icon = 'mdi-alert';
					this.snackbar_icon_color = 'white';
					this.snackbar_text = 'Il campo Imaballo è richiesto!';
					this.snackbar_text_color = 'white';
					return;
				}
				if (this.qta_contenitore === '') {
					this.snackbar = true;
					this.snackbar_background = 'orange';
					this.snackbar_icon = 'mdi-alert';
					this.snackbar_icon_color = 'white';
					this.snackbar_text = 'Il campo Q.ta Contenitore è richiesto!';
					this.snackbar_text_color = 'white';
					return;
				}
				if (this.qta_min === '') {
					this.snackbar = true;
					this.snackbar_background = 'orange';
					this.snackbar_icon = 'mdi-alert';
					this.snackbar_icon_color = 'white';
					this.snackbar_text = 'Il campo Qta Min è richiesto!';
					this.snackbar_text_color = 'white';
					return;
				}
				dataobj.id_articolo_packaging = -1;
				dataobj.id_articolo = this.data.id_articolo;
				dataobj.id_articolo_contenitore = this.id_articolo_contenitore;
				dataobj.id_articolo_imballo = this.id_articolo_imballo;
				dataobj.qta_minima_spedizione = this.qta_min;
				dataobj.codice = this.codice;
				dataobj.qta_imballo = this.qta_imballo;
				dataobj.qta_contenitore = this.qta_contenitore;
				dataobj.validita = 1;
				dataobj.des_imballo = this.des_imballo;
				dataobj.ragione_sociale = this.ragione_sociale_f ? this.ragione_sociale_f : this.ragione_sociale_c;
				dataobj.id_anagrafica = this.id_anagrafica;
				this.contentore_list.map((co) => {
					if (this.id_articolo_contenitore === co.cod_articolo) {
						dataobj.id_articolo_contenitore = co.id_articolo;
						dataobj.des_contenitore = co.cod_articolo;
						return dataobj.id_articolo_contenitore;
					}
				});
				let dataArray = [];
				dataArray.push(dataobj);
				if(this.deleteImages.length > 0){
					this.deleteImages.map(it=>{
						this.immagineLista.push(it)
					})
				}
				let setPackaging = {
					controller: 'Magazzino',
					method: 'PUT',
					richiesta: {
						action: 'setarticolopackaging',
						token: localStorage.getItem('user_token'),
						id_articolo: this.data.id_articolo,
						articolo_packaging: dataArray,
						articolo_packaging_immagini: this.immagineLista,
						articolo_packaging_istruzioni: this.listItem,
					},
				};
				this.$store.dispatch('api', setPackaging).then((res) => {
					if (res.status == 200) {
						this.overlay = false;
						this.FetchPackaging();
						this.snackbar = true;
						this.snackbar_background = 'success';
						this.snackbar_icon = 'mdi-check';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Modifica effettuata con successo!';
						this.snackbar_text_color = 'white';
					}
				});
			}
			if (this.selcetedItem.id_articolo_packaging) {
				let articolo_packaging = [];
				let dataobj = {};
				let data = {};
				dataobj.id_articolo_packaging = this.selcetedItem.id_articolo_packaging;
				dataobj.id_articolo_contenitore = this.id_articolo_contenitore;
				dataobj.validita = 1;
				dataobj.id_articolo_imballo = this.id_articolo_imballo;
				dataobj.id_articolo = this.data.id_articolo;
				dataobj.qta_minima_spedizione = this.qta_min;
				dataobj.qta_contenitore = this.qta_contenitore;
				dataobj.des_imballo = this.des_imballo;
				dataobj.codice = this.codice;
				dataobj.qta_imballo = this.qta_imballo;
				this.contentore_list.map((co) => {
					if (this.id_articolo_contenitore === co.cod_articolo) {
						dataobj.id_articolo_contenitore = co.id_articolo;
						dataobj.des_contenitore = co.cod_articolo;
						return dataobj.id_articolo_contenitore;
					}
				});
				dataobj.ragione_sociale = this.ragione_sociale_f ? this.ragione_sociale_f : this.ragione_sociale_c;
				dataobj.id_anagrafica = this.id_anagrafica;
				articolo_packaging.push(dataobj);
				data.articolo_packaging = articolo_packaging;
				let dataArray = [];
				dataArray.push(dataobj);
				if (this.deletedInstructions.length > 0) {
					this.deletedInstructions.map((el) => {
						this.listItem.push(el);
					});
				}
				if(this.deleteImages.length > 0){
					this.deleteImages.map(it=>{
						this.immagineLista.push(it)
					})
				}
				let setPackaging = {
					controller: 'Magazzino',
					method: 'PUT',
					richiesta: {
						action: 'setarticolopackaging',
						token: localStorage.getItem('user_token'),
						id_articolo: this.data.id_articolo,
						articolo_packaging: dataArray,
						articolo_packaging_immagini: this.immagineLista,
						articolo_packaging_istruzioni: this.listItem,
					},
				};
				this.$store.dispatch('api', setPackaging).then((res) => {
					if (res.status == 200) {
						this.overlay = false;
						this.FetchPackaging();
						this.snackbar = true;
						this.snackbar_background = 'success';
						this.snackbar_icon = 'mdi-check';
						this.snackbar_icon_color = 'white';
						this.snackbar_text = 'Modifica effettuata con successo!';
						this.snackbar_text_color = 'white';
					}
				});
			}
			this.id_articolo_contenitore = '';
			this.qta_contenitore = 0;
			this.qta_imballo = 0;
			this.qta_min = 0;
			this.dialog = false;
			this.id_anagrafica = null;
			this.ragione_sociale_c = '';
			this.ragione_sociale_f = '';
		},
		// Getting list of packaging to populate the table
		FetchPackaging() {
			this.loading1 = true;
			this.progressBar1 = true;
			let getPackaging = {
				controller: 'Magazzino',
				method: 'POST',
				richiesta: {
					action: 'getarticolipackaging',
					token: localStorage.getItem('user_token'),
					id_articolo: this.data.id_articolo,
				},
			};
			this.$store.dispatch('api', getPackaging).then((res) => {
				this.items = res.data;
				if (this.items.length > 0) {
					this.progressBar1 = false;
					this.loading1 = false;
				}
				if (this.items.length === 0) {
					this.progressBar1 = false;
					this.loading1 = false;
				}
				this.items.map((it) => {
					this.contentore_list.map((co) => {
						if (it.des_contenitore === '') {
							it.des_contenitore = co.cod_articolo;
							return this.items;
						}
					});
				});
			});
		},
	},
	mounted() {
		this.FetchPackaging();
		// In order to get anagrafica LIsta
		let getAnagrafica = {
			controller: 'Magazzino',
			method: 'POST',
			richiesta: {
				action: 'getanagrafica',
				tipo: 'F',
				token: localStorage.getItem('user_token'),
			},
		};
		this.$store.dispatch('api', getAnagrafica).then((res) => {
			this.anagraficaList = res.data;
		});
		// In order to get Contentore List
		let search_Arti_req = {
			controller: 'Magazzino',
			method: 'POST',
			richiesta: {
				action: 'gettabelle',
				token: localStorage.getItem('user_token'),
				tipo: 'tipoarticolo',
				filter: "val_txt='PACK'",
			},
		};
		this.$store.dispatch('api', search_Arti_req).then((res) => {
			this.Imb_Conte_list = res.data;
			this.Imb_Conte_list.map((it) => {
				if (it.val_int == 0) {
					let imballoSearch = {
						controller: 'Magazzino',
						method: 'POST',
						richiesta: {
							action: 'getarticoli',
							token: localStorage.getItem('user_token'),
							cod_articolo: '*',
							id_tipo: it.id_tabella,
						},
					};
					this.$store.dispatch('api', imballoSearch).then((res) => {
						this.overlay = false;
						this.contentoreList = res.data.lista;
					});
				}
				if (it.val_int == 1) {
					let imballoSearch = {
						controller: 'Magazzino',
						method: 'POST',
						richiesta: {
							action: 'getarticoli',
							token: localStorage.getItem('user_token'),
							cod_articolo: '*',
							id_tipo: it.id_tabella,
						},
					};
					this.$store.dispatch('api', imballoSearch).then((res) => {
						this.overlay = false;
						this.imballoList = res.data.lista;
					});
				}
			});
		});
	},
};
</script>
<style lang="css" scoped>
.artDialog {
	overflow-x: hidden;
}
.profile-pic {
	position: relative;
}
.profile-pic:hover .edit {
	display: block;
	z-index: 2;
}
.edit a {
	color: #000;
}
.imageContainer:hover .v-card--reveal {
	display: block;
	align-items: center;
	bottom: 0;
	justify-content: center;
	opacity: 1;
	position: absolute;
	width: 100%;
}
.listtoscroll ::-webkit-scrollbar-track {
	display: none !important;
	/* width: 150% !important; */
		overflow-y: scroll;
		background-color: transparent;
}
.left {
	overflow-y: scroll; /* it works! */
}

</style>
