<template>
  <div style="width: 100%" class="px-5">
    <v-overlay v-model="caricamento">
      <v-progress-circular
        indeterminate
        size="60"
        color="teal accent-2"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="2">
        <div class="glass px-5 mt-2">
          <v-switch
            color="teal accent-2"
            dark
            label="Nascondi completate"
            @change="filtraCompletati()"
            v-model="mostra_completati"
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="glass px-5 mt-2">
          <v-container>
            <v-row>
              <v-text-field
                color="teal accent-2"
                dark
                label="Cerca"
                v-model="filtro_title"
              >
              </v-text-field>

              <v-btn fab small class="mt-3 ml-3">
                <v-icon
                  :disabled="empty_field"
                  v-if="mostra_reset_filtro == false"
                  @click="filtraByTitle()"
                  >mdi-magnify</v-icon
                >
                <v-icon v-else class="" @click="resetFiltro()"
                  >mdi-filter-off-outline</v-icon
                >
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="glass px-5 mt-2">
          <v-select
            color="teal accent-2"
            dark
            label="Reparto"
            :items="ambiti"
            item-text="des"
            item-value="id_tabella"
            @change="filtraByAmbito()"
            v-model="ambito_sel"
          >
          </v-select>
        </div>
      </v-col>

      <v-col cols="1">
        <div class="glass mt-2" style="padding: 15px">
          <v-btn fab small>
            <v-icon @click="resetFiltro()">mdi-filter-off-outline</v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" class="pt-0" style="overflow-x: scroll"
        ><v-row style="width: max-content" :key="aggiornaTesta">
          <div
            class="pt-2"
            v-for="(item, index) in elementiTestata"
            :key="index"
          >
            <div
              v-if="index == 0"
              class="mb-4"
              style="
                border-bottom: 1px solid grey;
                height: 50px;
                background-color: #3f51b5;
                border-radius: 5px 0px 0px 5px;
              "
            >
              <h3 style="color: white" class="mr-10 ml-5 mt-8 pt-3">
                {{ item.cod_risorsa }}
              </h3>
            </div>
            <div
              v-if="index == elementiTestata.length - 1"
              class="mb-4"
              style="
                border-bottom: 1px solid grey;
                height: 50px;
                background-color: #3f51b5;
                border-radius: 0px 5px 5px 0px;
              "
            >
              <h3 style="color: white" class="mr-10 ml-5 mt-8 pt-3">
                {{ item.cod_risorsa }}
              </h3>
            </div>
            <div
              v-if="index != 0 && index != elementiTestata.length - 1"
              class="mb-4"
              style="
                border-bottom: 1px solid grey;
                height: 50px;
                background-color: #3f51b5;
              "
            >
              <h3 style="color: white" class="mr-10 ml-5 mt-8 pt-3">
                {{ item.cod_risorsa }}
              </h3>
            </div>
            <draggable
              v-model="item.dettagli"
              :group="item.id_gruppo"
              @start="drag = true"
              @end="(drag = false), assegnapriorita()"
              style="
                height: 100%;
                width: 250px;
                max-height: 85vh;
                overflow-y: scroll;
              "
            >
              <div
                v-for="(element, index) in item.dettagli"
                :key="index"
                :class="element.class"
                @dblclick="OpenDialogComponente(element)"
                style="
                  height: fit-content;
                  width: 80%;
                  border-radius: 10px;
                  padding: 10px;
                  margin-bottom: 10px;
                "
              >
                <span>
                  <b>{{ element.des_task }}</b>
                </span>
                <br />
                <!-- <i
                    v-if="element.des_task != null"
                    style="opacity: 0.7; font-size: 14px"
                    >{{ element.des_task }}
                  </i> -->
                <i
                  v-if="element.rif_task != null"
                  style="opacity: 0.7; font-size: 14px"
                  >{{ element.rif_task }}
                </i>
                <br />
                <i style="opacity: 0.7; font-size: 12px"
                  >{{ element.des_stato }}
                </i>
                <v-progress-linear
                  striped
                  :color="calcolaColore(element.percentuale_completamento)"
                  v-model="element.percentuale_completamento"
                  height="13"
                >
                  <strong
                    >{{ Math.ceil(element.percentuale_completamento) }}%</strong
                  >
                </v-progress-linear>
              </div>
            </draggable>
          </div>
        </v-row></v-col
      >
    </v-row>

    <!-- <v-dialog v-model="dialog_componente">
      <v-card v-show="dialog_componente">
        <v-toolbar dark color="indigo">
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab small><v-icon color="error">mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <component ref="componente" v-bind:is="Component"></component>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <component
      v-show="false"
      ref="componente"
      v-bind:is="Component"
    ></component>

    <component
      v-show="false"
      ref="componenteChild"
      v-bind:is="Component_child"
    ></component> -->

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="componente_dinamico_visible" class="ma-0 pa-0">
      <component
        class="ma-0 pa-0"
        :is="dynamic_component"
        :key="key_componente_dinamico"
        :props="items_moduli.length>0 ? items_moduli[0].props : {}"
        ref="componente_dinamico"
        @whoIsMyFather="IAmYourFather"
      />
    </v-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  name: "Plan",
  components: {
    draggable,
    Articolo: () => import("@/views/PRODUCTION/Articolo/Articolo.vue"),
    Macchina: () =>
      import("@/views/PRODUCTION/Macchine/UpdateVehicleDetail.vue"),
  },
  data() {
    return {
      mostra_completati: false,
      mostra_reset_filtro: false,
      dettagli: [],
      elementiTestata: [],
      filtro_title: "",
      elementiTestata_backup: [],
      dettagli_backup: [],
      margine: "0 auto",
      altezza: "100%",
      larghezza: "70%",
      id_gruppo_start: 0,
      id_gruppo_end: 0,
      componente_dinamico_visible: false,
      aggiornaTesta: 0,
      dialog_componente: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      parametro: localStorage.parametro_chiamata,
      empty_field: false,
      ambito_sel: null,
      dynamic_component: "",
      task_cliccato: null,
      key_componente_dinamico: 0,
      items_moduli: [],
      props_inizializzate: [],
      // nodo_sel: {
      //   modulo: "",
      //   path_componente: "",
      //   props: null,
      //   props_inizializzazione: [],
      //   props_url: null,
      // },
      Component:
        localStorage.parametro_chiamata == "PROD"
          ? () => import("../PRODUCTION/Produzione/DistintaBase.vue")
          : false,
      // Component_child:
      //   localStorage.parametro_chiamata != ""
      //     ? () => import(`${localStorage.getItem("path2")}`)
      //     : false,

      ambiti: [],
      Component_props: null,
      Component_child_props: null,

      caricamento: false,
      tipo_sel: 1,
    };
  },
  mounted() {
    // this.getdettaglio();
    this.getelementiTestata();
    this.getambiti();
    this.getModuliDinamici();
    // console.log("COMPONENT", this.Component);

    // setTimeout(() => {
    //   this.Component_props = this.$refs.componente.$props;
    // }, 1000);
  },
  watch: {
    filtro_title: {
      immediate: true,
      handler() {
        if (this.filtro_title == "" || this.filtro_title == null) {
          this.empty_field = true;
        } else {
          this.empty_field = false;
        }
      },
    },
    tipo_sel: {
      immediate: true,
      handler() {
        if (this.tipo_sel == 1) {
          this.getambiti();
        } else {
          this.gettabelle();
        }
      },
    },
  },
  methods: {
    IAmYourFather(arg = undefined) {
      // Passo le eventuali props da URL
      this.$refs.componente_dinamico.$route.params.orderId =
        this.task_cliccato.id_task;

      // Passo le eventuali props standard
      for (let prop in this.items_moduli[0].props) {
        this.$refs.componente_dinamico.$props[prop] = _.cloneDeep(
          this.items_moduli[0].props[prop]
        );
      }
      // Eseguo l'inizializzazione forzata come specificato dal figlio, se è necessario.
      arg != undefined ? arg.customHandler(this.props_inizializzate) : false;
    },
    OpenDialogComponente(elem) {
      this.task_cliccato = elem;
      this.props_inizializzate=[];
       this.items_moduli[0].props_inizializzate.forEach((prop) => {
        let myprop = Object.assign({}, JSON.parse(prop.obj));
        for (prop in myprop) {
          myprop[prop] = elem[prop];
        }
        this.props_inizializzate.push(Object.assign({}, myprop));
      });

      this.dynamic_component = localStorage.getItem("parametro_componente");

      this.componente_dinamico_visible = true;

      this.key_componente_dinamico = this.key_componente_dinamico === 0 ? 1 : 0;
    },
    getModuliDinamici() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getcomponenti",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.items_moduli = res.data.result.componenti.filter(
            (e) => e.des == localStorage.getItem("parametro_componente")
          );
          this.items_moduli.forEach((e) => {
            e.props != undefined ? e.props : {};
          });
          console.log("items_moduli", this.items_moduli);
        }
      });
    },
    filtraByAmbito() {
      this.elementiTestata = this.elementiTestata_backup.filter(
        (e) => e.id_gruppo == this.ambito_sel
      );
    },
    filtraByTitle() {
      this.elementiTestata.forEach((testata) => {
        console.log("", testata.dettagli);
        testata.dettagli = testata.dettagli.filter((dettaglio) =>
          dettaglio.des_task == null
            ? dettaglio.rif_task
                .toLowerCase()
                .includes(this.filtro_title.toLowerCase())
            : dettaglio.rif_task
                .toLowerCase()
                .includes(this.filtro_title.toLowerCase()) ||
              dettaglio.des_task
                .toLowerCase()
                .includes(this.filtro_title.toLowerCase())
        );
      });
      this.mostra_reset_filtro = true;
    },
    resetFiltro() {
      this.elementiTestata = _.cloneDeep(this.elementiTestata_backup);
      this.mostra_reset_filtro = false;
      this.mostra_completati = false;
      this.ambito_sel = null;
      this.aggiornaTesta++;
    },
    filtraCompletati() {
      if (this.mostra_completati) {
        this.elementiTestata.forEach((testata) => {
          console.log(testata.dettagli);
          testata.dettagli = testata.dettagli.filter(
            (dettaglio) => dettaglio.percentuale_completamento < 100
          );
        });
      } else {
        this.resetFiltro();
      }
    },
    calcolaColore(percentuale) {
      var colore = null;
      if (percentuale < 20) {
        colore = "red";
        return colore;
      } else if (percentuale >= 20 && percentuale < 70) {
        colore = "orange";
        return colore;
      } else if (percentuale >= 70 && percentuale < 100) {
        colore = "success";
        return colore;
      } else if (percentuale == 100) {
        colore = "green";
        return colore;
      }
    },

    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MACCHINA_REPARTO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.ambiti = res.data;
      });
    },
    getambiti() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MACCHINA_REPARTO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.ambiti = res.data;
        // Recupero dinamicamente gli id degli stati approvativi degli interventi
        // if (res.data.result != undefined) {
        //   this.ambiti = res.data.result.ambiti;
        // }
      });
    },
    // getdettaglio() {
    //   var request = {
    //     controller: "Modulo",
    //     method: "POST",
    //     richiesta: {
    //       action: "getattivitaview",
    //       id_utente: localStorage.id,
    //       token: localStorage.user_token,
    //       prefisso_utente: localStorage.prefisso_utente,
    //     },
    //   };
    //   this.$store.dispatch("api", request).then((res) => {
    //     if (res.status == 200) {
    //       console.log("res ge dettaglio:", res.data);
    //       this.dettagli = res.data.result.attivita;
    //       this.getelementiTestata();
    //     } else {
    //       this.$store.state.snackbar.stato = true;
    //       this.$store.state.snackbar.messaggio = "Nessun elemento trovato!";
    //       this.$store.state.snackbar.colore = "#FFB300";
    //       this.$store.state.snackbar.timeout = 2000;
    //     }
    //   });
    //},
    getelementiTestata() {
      var getRisorse = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getrisorse",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          parametro: this.parametro,
        },
      };
      this.$store.dispatch("api", getRisorse).then((res) => {
        if (res.status == 200) {
          let resTmp = res.data.result.risorse;
          console.log("Risorse", resTmp);
          this.elementiTestata = resTmp.filter((e) => e.id_risorsa > 0);
          this.elementiTestata_backup = resTmp.filter((e) => e.id_risorsa > 0);
          this.elementiTestata.forEach((e) => {
            e.dettagli = [];
          });

          console.log("RisorseTestata", this.elementiTestata);
          this.getdettagli();
          console.log("Fine GetRisorse");
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Nessun elemento trovato";
          this.snackbar_text_color = "white";
        }
      });
    },
    getdettagli() {
      this.caricamento = true;
      var request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettask",
          token: localStorage.user_token,
          id_stato: "",
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status == 200) {
            this.caricamento = false;
            console.log("TASK:", res.data);
            let tickets_tmp = res.data.result.task;
            this.elementiTestata.forEach((e) => {
              if (e.dettagli) {
                e.dettagli = [];
              }
            });
            this.elementiTestata_backup.forEach((e) => {
              if (e.dettagli_backup) {
                e.dettagli = [];
              }
            });
            this.elementiTestata.forEach((elementiTestata) => {
              tickets_tmp.forEach((task) => {
                if (elementiTestata.id_risorsa == task.id_risorsa) {
                  if (task.id_task != null) {
                    // if (ticket.numero.includes("TCK")) {
                    task.class = "draggableTicket mx-3 ml-5 mt-1";
                    // } else {
                    //   ticket.class = "draggabledettaglio mx-3 ml-5 m5-1";
                    // }
                  } else {
                    task.class = "draggableEmpty mx-3 ml-5 m5-1";
                  }
                  elementiTestata.dettagli.push(Object.assign({}, task));
                }
              });
            });
            this.elementiTestata_backup = _.cloneDeep(this.elementiTestata);

            this.elementiTestata.forEach((e) => {
              e.dettagli.sort((a, b) => a.priorita_fase - b.priorita_fase);
            });
            this.aggiornaTesta++;
          } else {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Nessun elemento trovato";
            this.snackbar_text_color = "white";
          }
        })
        .catch(() => {
          this.caricamento = false;
        });
    },
    assegnapriorita() {
      console.log("UpDate Priorita 0");
      for (let i = 0; i < this.elementiTestata.length; i++) {
        for (let j = 0; j < this.elementiTestata[i].dettagli.length; j++) {
          this.elementiTestata[i].dettagli[j].priorita_fase = j;
        }
      }
      console.log("UpDate Priorita 1", this.elementiTestata[0].dettagli);
      let dettagli_tmp = [];
      this.elementiTestata.forEach((e) => {
        if (e.dettagli.length != 0) {
          e.dettagli.forEach((f) => {
            f.id_risorsa = e.id_risorsa;
            f.tipo_risorsa = e.tipo_risorsa;
            let obj_tmp = Object.assign({}, f);
            dettagli_tmp.push(obj_tmp);
          });
        }
      });
      console.log("UpDate Priorita 2", this.elementiTestata[0].dettagli);
      // dettagli_tmp.forEach((e) => {
      //   e.numero = e.numero.substring(4);
      // });
      var request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "settaskpriorita",
          id_utente: localStorage.id,
          token: localStorage.user_token,
          prefisso_utente: localStorage.prefisso_utente,
          task: dettagli_tmp,
          parametro: this.parametro,
        },
      };
      console.log("UpDate Priorita", request);
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check-outline";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Ticket/dettaglio assegnato con successo!";
          this.snackbar_text_color = "white";
          this.getdettagli();
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Errore tecnico. Non è stato possibile salvare i dati!";
          this.snackbar_text_color = "white";
        }
      });
      console.log("elementiTestata AGGIORNATE:", this.elementiTestata);
      console.log("dettaglio RIMASTI:", this.dettagli);
      this.aggiornaTesta++;
      // chiamata assegnadettaglio al BE
    },
  },
};
</script>
<style >
.draggableTicket:hover {
  cursor: grab;
  box-shadow: 0px 0px 10px cornflowerblue;
  transition: 0.25s;
}
.draggableTicket:active {
  cursor: grabbing;
  box-shadow: 0px 0px 12px aqua;
  transition: 0.25s;
}

.draggableEmpty {
  background-color: rgb(134, 132, 132);
  transition: 0.25s;
  box-shadow: 0px 0px 3px grey;
}

.draggableTicket {
  background-color: bisque;
  transition: 0.25s;
  box-shadow: 0px 0px 3px grey;
}

.draggabledettaglio {
  background-color: lightgreen;
  transition: 0.25s;
  box-shadow: 0px 0px 3px grey;
}
.draggabledettaglio:hover {
  cursor: grab;
  box-shadow: 0px 0px 10px rgb(60, 212, 67);
  transition: 0.25s;
}
.draggabledettaglio:active {
  cursor: grabbing;
  box-shadow: 0px 0px 12px rgb(208, 255, 0);
  transition: 0.25s;
}
.glass {
  background: rgba(59, 59, 59, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
</style>