<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"
        >Seleziona Concessionari
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <v-btn @click="this.salvaConcessionari" small>
          <v-icon color="success"> mdi-floppy </v-icon>
        </v-btn>
      </v-tooltip>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text style="overflow-y: auto">
      <v-container>
        <v-row align="center" class="ma-0 pa-0" justify="center">
          <v-col align="center" cols="10" justify="center">
            <v-autocomplete
              v-model="concessionari"
              :items="concessionari_lista"
              label="Concessionari"
              placeholder="Seleziona"
              item-text="des"
              item-value="id_tabella"
              required
              chips
              deletable-chips
              multiple
              return-object
            >
              <template #label>
                <span class="mr-2">Concessionari</span>
                <span @click.stop style="min-width: 500px; pointer-events: all">
                  <Modules
                    v-on:dialogModules="gettabelle"
                    tipo="MAN_CONCESSIONARIO"
                    nome="Concessionaro"
                    setaction="settabella"
                    getaction="gettabella"
                    controller="Global"
                    :controllaCodice="true"
                    :filter="'validita = 1'"
                    :all_label="['Concessionario']"
                    :dati="[{ des: '' }]"
                  />
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "AnagraficheDettaglioConcessionari",
  components: {
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {
    anagrafica_sel: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
      concessionari: [],
      concessionari_lista: [],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          // Resetto le variabili di supporto
          if (this.anagrafica_sel.concessionari != undefined) {
            this.concessionari = Array.from(this.anagrafica_sel.concessionari);
            this.concessionari.forEach((c) => {
              c.id_tabella = c.id_concessionario;
            });
          }

          // Eseguo la logica di inizializzazione
          this.gettabelle();
        }
      },
    },
  },
  methods: {
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_CONCESSIONARIO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.data.length != 0) {
          this.concessionari_lista = res.data;
        }
      });
    },
    salvaConcessionari() {
      this.$emit("concessionari_aggiornati");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>