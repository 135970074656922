var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.read_only
        ? _vm.headers_allegati.filter(function (f) { return f.value != 'elimina'; })
        : _vm.headers_allegati,"items":_vm.lista_allegati.filter(function (el) { return el.validita != 0; }),"item-key":"id_allegato"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":_vm.color,"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.toolbar_title))]),_c('v-spacer'),(!_vm.read_only || _vm.consenti_upload === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(!_vm.read_only)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":_vm.icon_color == 'primary' ? false:true,"color":_vm.color == 'indigo' ? '' : _vm.color},on:{"click":_vm.aggiungiAllegato}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.icon_color}},[_vm._v("mdi-upload")])],1):_vm._e()]}}],null,false,374029110)},[_c('span',[_vm._v("Carica Allegato")])]):_vm._e(),(!_vm.read_only || _vm.consenti_upload === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.show_save_button === true && !_vm.read_only)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"small":"","disabled":_vm.lista_allegati.length === 0},on:{"click":_vm.clickSalvaAllegati}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1):_vm._e()]}}],null,false,2999755769)},[_c('span',[_vm._v("Salva Allegati")])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show_close),expression:"show_close"}],attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1)]},proxy:true},{key:"header.famiglia",fn:function(ref){return [_c('span',[_vm._v("Tipologia")]),_c('span',{staticStyle:{"min-width":"500px","pointer-events":"all"},on:{"click":function($event){$event.stopPropagation();}}},[_c('Modules',{attrs:{"tipo":"FAMIGLIA_ALLEGATI","nome":"Tipologia Allegati","setaction":"settabella","getaction":"gettabella","controller":"Global","filter":"validita = 1","bg_color":_vm.color,"icon_color":_vm.icon_color,"dati":[{ des: '' }]},on:{"dialogModules":_vm.getTabelle}})],1)]}},{key:"item.scarica",fn:function(ref){
        var item = ref.item;
return [(item.id_allegato > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","disabled":_vm.lista_src[_vm.lista_allegati.indexOf(item)] == null},on:{"click":function($event){_vm.DownloadFile(
                _vm.tipo_allegato +
                  '_' +
                  item.id_allegato.toString() +
                  '.' +
                  item.estensione.toString(),
                _vm.lista_download_files[_vm.lista_allegati.indexOf(item)]
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Scarica")])]):_vm._e()]}},{key:"item.famiglia",fn:function(ref){
              var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.famiglia_allegati_lista,"placeholder":"Seleziona","required":"","item-text":"des","item-value":"id_tabella"},on:{"change":function($event){_vm.famiglia_allegati_lista.filter(function (e) { return e.id_tabella == item.id_categoria
              ? (item.des_categoria = e.des)
              : null; }
          )}},model:{value:(item.id_categoria),callback:function ($$v) {_vm.$set(item, "id_categoria", $$v)},expression:"item.id_categoria"}})]}},{key:"item.des_allegato",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{ref:_vm.nome_form_chiamante +
          '_text_allegato' +
          _vm.lista_allegati.indexOf(item),attrs:{"placeholder":"Inserire","required":""},on:{"input":function($event){_vm.changeDes(
            _vm.nome_form_chiamante +
              '_text_allegato' +
              _vm.lista_allegati.indexOf(item)
          )},"change":function($event){_vm.changeDes(
            _vm.nome_form_chiamante +
              '_text_allegato' +
              _vm.lista_allegati.indexOf(item)
          )}},model:{value:(item.des_allegato),callback:function ($$v) {_vm.$set(item, "des_allegato", $$v)},expression:"item.des_allegato"}})]}},{key:"item.data_validita",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-calendar-today")])]},proxy:true}],null,true),model:{value:(item.data_validita),callback:function ($$v) {_vm.$set(item, "data_validita", $$v)},expression:"item.data_validita"}},on))]}}],null,true),model:{value:(_vm.date_validita_tabella[_vm.lista_allegati.indexOf(item)]),callback:function ($$v) {_vm.$set(_vm.date_validita_tabella, _vm.lista_allegati.indexOf(item), $$v)},expression:"date_validita_tabella[lista_allegati.indexOf(item)]"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_validita_tabella[_vm.lista_allegati.indexOf(item)] = false}},model:{value:(item.data_validita),callback:function ($$v) {_vm.$set(item, "data_validita", $$v)},expression:"item.data_validita"}})],1)]}},{key:"item.preview",fn:function(ref){
          var item = ref.item;
return [(_vm.lista_src[_vm.lista_allegati.indexOf(item)] == null)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[(
            (_vm.lista_allegati[_vm.lista_allegati.indexOf(item)].estensione !=
              undefined &&
              _vm.lista_allegati[
                _vm.lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'PNG') ||
            _vm.lista_allegati[
              _vm.lista_allegati.indexOf(item)
            ].estensione.toUpperCase() == 'JPEG' ||
            _vm.lista_allegati[
              _vm.lista_allegati.indexOf(item)
            ].estensione.toUpperCase() == 'JPG' ||
            _vm.lista_allegati[
              _vm.lista_allegati.indexOf(item)
            ].estensione.toUpperCase() == 'GIF'
          )?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}]},[_c('img',{ref:_vm.nome_form_chiamante +
              '_image_prev_allegato' +
              _vm.lista_allegati.indexOf(item),attrs:{"id":_vm.nome_form_chiamante +
              '_image_prev_allegato' +
              _vm.lista_allegati.indexOf(item),"src":_vm.lista_src[_vm.lista_allegati.indexOf(item)]},on:{"load":function($event){_vm.onLoadImage(_vm.lista_allegati.indexOf(item))}}})]):(
            _vm.lista_allegati[
              _vm.lista_allegati.indexOf(item)
            ].estensione.toUpperCase() == 'PDF'
          )?_c('v-icon',{staticClass:"justify-center d-flex",attrs:{"color":"error","x-large":""},on:{"click":function($event){_vm.openPdf(_vm.lista_src[_vm.lista_allegati.indexOf(item)])}}},[_vm._v(" mdi-file-pdf-box ")]):(
            _vm.lista_allegati[
              _vm.lista_allegati.indexOf(item)
            ].estensione.toUpperCase() == 'MP4' ||
            _vm.lista_allegati[
              _vm.lista_allegati.indexOf(item)
            ].estensione.toUpperCase() == 'MOV'
          )?_c('div',{on:{"click":function($event){_vm.openVideo(_vm.lista_src[_vm.lista_allegati.indexOf(item)])}}},[_c('v-icon',{staticClass:"justify-center d-flex",attrs:{"color":"error","x-large":""}},[_vm._v(" mdi-play-box ")]),_c('span',{staticClass:"justify-center d-flex"},[_vm._v(_vm._s(_vm.lista_allegati[ _vm.lista_allegati.indexOf(item) ].estensione.toUpperCase()))])],1):_c('div',[_c('v-icon',{staticClass:"justify-center d-flex",attrs:{"color":"error","x-large":""}},[_vm._v(" mdi-file ")]),_c('span',{staticClass:"justify-center d-flex"},[_vm._v(_vm._s(_vm.lista_allegati[ _vm.lista_allegati.indexOf(item) ].estensione.toUpperCase()))])],1)],1)]}},(!_vm.read_only)?{key:"item.elimina",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","disabled":_vm.read_only},on:{"click":function($event){_vm.removeAllegato(_vm.lista_allegati.indexOf(item))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina Allegato")])])]}}:null],null,true)}),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dettaglio_allegato_visible),callback:function ($$v) {_vm.dettaglio_allegato_visible=$$v},expression:"dettaglio_allegato_visible"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.color,"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Dettagli Allegato")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":_vm.icon_color == 'primary' ? false:true,"small":""},on:{"click":_vm.salvaAllegato}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.icon_color}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Seleziona File")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":_vm.icon_color == 'primary' ? false:true,"small":""},on:{"click":_vm.chiudiDialogAllegato}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.icon_color}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"pa-0 pt-3"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{ref:"allegato",staticClass:"d-none",attrs:{"counter":"","label":"File input","multiple":"","placeholder":"Seleziona i tuoi file","prepend-icon":"mdi-camera","show-size":1000},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('v-text-field',{attrs:{"dense":"","value":_vm.comp_change(_vm.files),"accept":"image/*","label":"Files","prepend-icon":"mdi-camera","clearable":""},on:{"paste":_vm.onPaste,"click:clear":_vm.clearFiles,"click:prepend":_vm.openImagePicker}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.videoPlayer),callback:function ($$v) {_vm.videoPlayer=$$v},expression:"videoPlayer"}},[_c('video',{staticStyle:{"max-height":"400px","background-color":"black"},attrs:{"width":"800","controls":""}},[_c('source',{attrs:{"src":_vm.linkVideo,"type":"video/mp4"}}),_vm._v(" Your browser does not support HTML video. ")])]),_c('v-dialog',{model:{value:(_vm.dialogPdf),callback:function ($$v) {_vm.dialogPdf=$$v},expression:"dialogPdf"}},[_c('iframe',{staticStyle:{"height":"90vh !important"},attrs:{"src":_vm.selectedPdf,"frameborder":"0"}})]),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }