<template>
  <div
    id="listener_articolo_details"
    class=""
    style="width: 90vw !important; height: 92vh !important"
  >
    <!-- Menu per la main dashboard dell'articolo dopo la selezione -->
    <v-toolbar dense color="indigo">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" small v-show="!showUpdate" @click="hideSearch">
            <v-icon v-on="on" v-bind="attrs" style="color: #1a80b6"
              >mdi-pencil</v-icon
            >
          </v-btn>
        </template>
        <span>Torna alla modifica dell' Articolo</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" small v-show="showUpdate" @click="showSearch">
            <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
              >mdi-find-replace</v-icon
            >
          </v-btn>
        </template>
        <span>Cerca un nuovo Articolo</span>
      </v-tooltip>

      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="opendialogInfo"
            v-on="on"
            v-bind="attrs"
            colsall
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>Dettaglio delle azioni</span>
      </v-tooltip> -->
      <v-tooltip v-if="saveButton" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" @click="salvaArticolo" small v-if="saveButton">
            <v-icon v-on="on" v-bind="attrs" color="success">mdi-floppy</v-icon>
          </v-btn>
        </template>
        <span>salva articolo</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goBack" small>
            <v-icon v-on="on" v-bind="attrs" color="red"
              >mdi-keyboard-backspace</v-icon
            >
          </v-btn>
        </template>
        <span>Indietro</span>
      </v-tooltip>
    </v-toolbar>

    <div class="ml-4">
      <v-form action>
        <v-container class="ml-2 mt-n5 mr-2" fluid>
          <v-row>
            <v-col cols="10" class>
              <v-row>
                <v-col class="py-0" cols="4" md="2">
                  <v-text-field
                    :disabled="disableCodArticle"
                    type="text"
                    v-on:keydown.enter.prevent="searchResult"
                    class="input-class"
                    v-model="article.cod_articolo"
                    label="Codice Articolo"
                    placeholder="Inserisci il Codice"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="1" v-if="showicon" class="py-0 align-end">
                  <v-icon
                    v-if="showicon && loading == false"
                    class="mt-6"
                    :disabled="disabledSearchIcon"
                    @click="searchResult"
                    color="#1a80b6"
                    size="24"
                    >mdi-magnify</v-icon
                  >
                  <v-progress-circular
                    v-else
                    indeterminate
                    size="25"
                  ></v-progress-circular>
                </v-col>

                <v-col class="py-0" cols="6" :md="!showicon ? 4 : 3">
                  <v-text-field
                    type="text"
                    class="input-class"
                    v-model="article.des_articolo"
                    :disabled="!disabledSearch"
                    label="Descrizione"
                    placeholder="Inserisci la Descrizione"
                    required
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" cols="4" md="2">
                  <v-autocomplete
                    v-model="article.id_tipo"
                    :items="tipo_list"
                    :disabled="!disabledSearch"
                    label="Tipo Articolo"
                    placeholder="Tipo Articolo"
                    item-text="des"
                    required
                    item-value="id_tabella"
                  >
                    <template #label>
                      <span class="">Tipo<strong>* </strong></span>
                      <span
                        @click.stop
                        style="min-width: 500px; pointer-events: all"
                      >
                        <Modules
                          v-on:dialogModules="aggiornaTipoArticolo"
                          :tipo="tipo1"
                          nome="Tipo Articolo"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="py-0" cols="4" md="2">
                  <v-autocomplete
                    v-model="article.id_famiglia"
                    :items="famiglia_list"
                    :disabled="!disabledSearch"
                    item-text="des"
                    required
                    item-value="id_tabella"
                    class="input-group--focused"
                    placeholder="Tipo Famiglia"
                    label="Tipo Famiglia"
                  >
                    <template #label>
                      <span class="">Famiglia<strong>* </strong></span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="aggiornaFamiglia"
                          :tipo="tipo2"
                          nome="Famiglia"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="py-0" md="2" cols="4">
                  <v-autocomplete
                    v-model="article.id_tipo_fornitura"
                    :items="fornitura_list"
                    item-text="des"
                    :disabled="!disabledSearch"
                    required
                    item-value="id_tabella"
                    placeholder="Tipo Fornitura"
                    label="Tipo Fornitura"
                  >
                    <template #label>
                      <span class="">Fornitura<strong>* </strong></span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="aggiornaFornitura"
                          :tipo="tipo3"
                          nome="Fornitura"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="py-0" cols="12">
                  <v-text-field
                    type="text"
                    class="input-class"
                    v-model="article.des_aggiuntiva"
                    :disabled="!disabledSearch"
                    label="Descrizione Aggiuntiva"
                    placeholder="Inserisci la Descrizione Aggiuntiva"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="mt-2 ml-n4" cols="2" v-if="disabledSearch">
              <v-row class="justify-center">
                <v-col class="" cols="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        colsall
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          dialogImage = true;
                          allegato = [];
                        "
                      >
                        <v-icon color="#1a80b6">mdi-paperclip</v-icon>
                      </v-btn>
                    </template>
                    <span>Aggiungi Immagine all'Articolo</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  class=""
                  cols="12"
                  v-if="
                    article.immagine_preview != null ||
                      article.immagine_preview != ''
                  "
                >
                  <v-card class="d-flex justify-center">
                    <div
                      v-viewer
                      class="images clearfix d-flex align-items-center"
                    >
                      <img
                        id="image_prev"
                        :src="article.immagine_preview"
                        :lazy-src="article.immagine_preview"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      />
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>

    <v-divider class="mt-n8"></v-divider>
    <!-- v-on:updatePackging="updatePackging($event)" -->
    <ArticoloDrawer
      contentConte=""
      imballoCod=""
      :key="this.disabledSearch.toString()"
      :data="this.article"
      :CodEnable="!this.disabledSearch"
    />

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogImage"
      width="500"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Allegati all'Articolo</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-n4" @click="dialogImage = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image"
            label="Immagine"
            prepend-icon="mdi-camera"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogSearch"
      width="80%"
    >
      <v-card>
        <articolo-search
          ref="searchComponent"
          @click_indietro="
            dialogSearch = !dialogSearch;
            visibleSearch = false;
          "
          :cod_articolo="article.cod_articolo"
          :visible="visibleSearch"
          gestione_click_indietro="back"
        />
      </v-card>
    </v-dialog>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import ArticoloDrawer from "./ArticoloDrawer/ArticoloDrawer2.vue";
import ArticoloSearch from "./ArticoloSearch.vue";

import Article from "@/classes/Article.js";
export default {
  name: "ArticoloDetails",
  // Child Components of Articolo Component
  components: {
    ArticoloDrawer,
    ArticoloSearch,
    Modules: () => import("../../../components/TOOLS/Modules"),
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      id_utente: localStorage.id_utente,
      list_articles: [],
      old_cod_articolo: "",
      article: new Article(
        this.$route.params.id == undefined ? -1 : this.$route.params.id,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        1
      ),
      saveButton: true,
      showUpdate: true,
      disabledSearch: true,
      disabledSearchIcon: false,
      disableCodArticle: true,
      showicon: false,
      tipo1: "TIPOARTICOLO",
      tipo2: "famiglia",
      tipo3: "FORNITURA_STANDARD",
      loading: false,

      // Modal
      visibleSearch: false,
      dialogImage: false,
      dialogSearch: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      oldCodArticolo: null,

      // Array
      tipo_list: [],
      famiglia_list: [],
      fornitura_list: [],
      allegato: [],
    };
  },
  methods: {
    getListArticoli(obj) {
      this.list_articles = obj.detail.items_nuovi;
    },
    goBack() {
      this.goToSearch();
    },
    goToSearch() {
      this.$router.push("/home/production/articolo").catch(() => {});
      this.article.fam_articolo = this.article.famiglia_articolo;
      this.list_articles.forEach((element, id) => {
        if (this.article.id_articolo == element.id_articolo) {
          this.list_articles[id] = this.article;
        } else if (
          this.article.id_articolo != -1 &&
          this.article.cod_articolo != "" &&
          this.old_cod_articolo != ""
        ) {
          this.list_articles.push(this.article);
        }
      });
      if (
        this.list_articles.length == 0 &&
        this.article.id_articolo != -1 &&
        this.article.old_cod_articolo != ""
      ) {
        this.list_articles.push(this.article);
      }

      let messaggio = {
        detail: {
          items_nuovi: this.list_articles,
        },
        src: null,
      };

      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("items_aggiornati", messaggio);

      /* Consegno alla mia destinazione l'evento con il
       * contenuto dell'array. */
      var searchInterval = setInterval(function() {
        if (document.getElementById("listener_articolo_search")) {
          clearInterval(searchInterval);
          document
            .getElementById("listener_articolo_search")
            .dispatchEvent(evento);
        }
      }, 500);
    },
    salvaArticolo() {
      let setArticolo = {
        controller: "Magazzino",
        method: "PUT",

        richiesta: {
          action: "setarticolo",
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.id,
          articolo: [this.article],
        },
      };
      this.$store
        .dispatch("api", setArticolo)
        .then((res) => {
          if (res.data) {
            this.snackbar = true;
            this.snackbar_background = "grey lighten-5";
            this.snackbar_icon = "mdi-check-circle";
            this.snackbar_icon_color = "indigo";
            this.snackbar_text =
              this.article.id_articolo == -1
                ? "Nuovo articolo salvato con successo!"
                : "Modifica effettuata con successo!";
            this.snackbar_text_color = "black";

            setTimeout(() => {
              this.goToSearch();
            }, 500);
          } else {
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_icon = "mdi-close";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Errore di salvataggio";
            this.snackbar_text_color = "white";
          }
        })
        .catch((ex) => {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = ex;
          this.snackbar_text_color = "white";
        });
    },
    searchResult() {
      this.loading = true;
      let search_recursive = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.article.cod_articolo,
          id_utente: this.id_utente,
        },
      };
      this.$store.dispatch("api", search_recursive).then((res) => {
        if (res.data.lista.length == 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Nessun articolo trovato per il codice inserito";
          this.snackbar_text_color = "white";
          this.loading = false;
        } else if (res.data.lista.length == 1) {
          var articoloPrimo = res.data.lista[0];

          let search_recursive = {
            controller: "Magazzino",
            method: "POST",
            richiesta: {
              action: "getarticoli",
              db_name: "quickproduction",
              token: localStorage.getItem("user_token"),
              id_articolo: articoloPrimo.id_articolo,
              id_utente: this.id_utente,
            },
          };
          this.$store.dispatch("api", search_recursive).then((res2) => {
            this.article = res2.data.articolo[0];

            this.oldCodArticolo = this.article.cod_articolo;
            var image = new Image();
            image.src = this.article.immagine_preview;
            let that = this;
            image.onload = function() {
              that.resizeImage(image, "image_prev");
            };
            this.loading = false;
          });
        } else {
          this.dialogSearch = true;
          this.loading = false;
          this.visibleSearch = true;
          // this.article.cod_articolo = '';
        }
      });
    },
    hideSearch() {
      this.showUpdate = true;
      this.saveButton = true;
      this.disabledSearch = true;
      this.showicon = false;
      this.disableCodArticle = true;
      this.article.cod_articolo = this.oldCodArticolo;
      var image = new Image();
      image.src = this.article.immagine_preview;
      let that = this;
      image.onload = function() {
        that.resizeImage(image, "image_prev");
      };
    },
    showSearch() {
      this.showUpdate = false;
      this.saveButton = false;
      this.disabledSearch = false;
      this.showicon = true;
      this.disableCodArticle = false;
      this.oldCodArticolo = this.article.cod_articolo;

      // this.article = new Article();
    },
    aggiornaTipoArticolo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: this.tipo1,
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.tipoList = res.data;
      });
    },
    aggiornaFamiglia() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: this.tipo2,
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.famiglia_list = res.data;
      });
    },
    aggiornaFornitura() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: this.tipo3,
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.fornitura_list = res.data;
      });
    },
    // Function to handle pasted url of image
    onPaste(e) {
      // console.log("event t:", e)
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;

        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;

            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function(imageDataBase64) {
        that.article.immagine_preview = imageDataBase64;
      });
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // Create an abstract canvas and get context
        var mycanvas = document.createElement("mycanvas");
        var ctx = mycanvas.getContext("2d");

        // Create an image
        var img = new Image();

        // Once the image loads, render the img on the canvas
        img.onload = function() {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;

          // Draw the image
          ctx.drawImage(img, 0, 0);

          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };

        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
      this.dialogImage = false;
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      // exts = exts.concat.apply(exts, exts.map(function(x){ return x.toUpperCase();}));
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name.toLowerCase()
      );
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (this.hasExtension(files[0], [".jpeg", ".gif", ".png", ".jpg"])) {
        this.allegato = files[0];

        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;

        reader.onload = function() {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function() {
            that.resizeImage(image, "image_prev");
          };
          that.article.immagine_preview = reader.result;
        };

        reader.onerror = function(error) {
          console.log("Error: ", error);
        };
      } else {
        this.dialogImage = false;
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Formato non supportato";
        this.snackbar_text_color = "white";
      }

      this.dialogImage = false;
    },
    inizializza() {
      // In order to get all the lists for select fields
      let fam_list = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: "'FAMIGLIA','TIPOARTICOLO','FORNITURA_STANDARD'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", fam_list).then((res) => {
        this.famiglia_list = res.data.FAMIGLIA;
        this.fornitura_list = res.data.FORNITURA_STANDARD;
        this.tipo_list = res.data.TIPOARTICOLO;
      });

      if (this.article.id_articolo != -1) {
        this.article.id_articolo =
          this.$route.params.id == undefined ? -1 : this.$route.params.id;
        let search_recursive = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticoli",
            db_name: "quickproduction",

            token: localStorage.getItem("user_token"),
            id_articolo: this.article.id_articolo,
            id_famiglia:
              this.article.id_famiglia != -1 ? this.article.id_famiglia : null,
            id_tipo: this.article.id_tipo != -1 ? this.article.id_tipo : null,
            id_utente: this.id_utente,
          },
        };
        this.$store.dispatch("api", search_recursive).then((res) => {
          var articoloDetails = res.data.articolo[0];
          this.article = articoloDetails;

          var image = new Image();
          image.src = this.article.immagine_preview;
          let that = this;
          image.onload = function() {
            that.resizeImage(image, "image_prev");
          };
          this.oldCodArticolo = this.article.cod_articolo;
        });
      } else {
        this.disableCodArticle = false;
      }
    },
    resizeImage(img, id) {
      if (document.getElementById(id) != null) {
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : width = height_nuova : height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(id).style.width = width_nuova + "px";
          document.getElementById(id).style.height = height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : width = height_nuova : height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(id).style.width = width_nuova + "px";
          document.getElementById(id).style.height = height_nuova + "px";
        } else {
          document.getElementById(id).style.width = img.width + "px";
          document.getElementById(id).style.height = img.height + "px";
        }
      }
    },
  },
  watch: {
    disableCodArticle: function() {
      if (this.article.id_articolo == -1) {
        this.disableCodArticle = false;
      }
    },
    "article.cod_articolo": function() {
      if (this.article.cod_articolo == "") {
        this.disabledSearchIcon = true;
      } else {
        this.disabledSearchIcon = false;
      }
    },
    "article.id_tipo_fornitura": function() {
      this.fornitura_list.forEach((el) => {
        if (el.id_tabella == this.article.id_tipo_fornitura) {
          this.article.fornitura_articolo = el.des;
        }
      });
    },
    "article.id_tipo": function() {
      this.tipo_list.forEach((el) => {
        if (el.id_tabella == this.article.id_tipo) {
          this.article.tipo_articolo = el.des;
        }
      });
    },
    "article.id_famiglia": function() {
      this.famiglia_list.forEach((el) => {
        if (el.id_tabella == this.article.id_famiglia) {
          this.article.famiglia_articolo = el.des;
        }
      });
    },
    dialogSearch: function() {
      if (this.dialogSearch == false) {
        this.inizializza();
      }
    },
  },
  computed: {},
  mounted() {
    document
      .getElementById("listener_articolo_details")
      .addEventListener("items_nuovi", this.getListArticoli);

    this.inizializza();
  },
};
</script>

<style lang="scss">
.cercaArt:hover {
  background-color: green($color: #000000) !important;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden !important;
}

.image {
  width: 100%;
  height: 100%;
}

.hoverDiv:hover {
  box-shadow: 20px 10px 100px rgba(33, 33, 33, 0.2);
  z-index: 9;
}

.ModalColor {
  background-color: red;
  color: red;
}
.artDialog {
  overflow-x: hidden;
}
@media (min-width: 300px) {
  .TipoList {
    margin-top: -12rem !important;
  }
}
</style>
