<template>
  <div class="ma-5">
    <v-data-table
      class="elevation-3"
      :expanded.sync="expanded_contratti"
      fixed-header
      :headers="headers_man_recursiva"
      height="70vh"
      :items="items_man_recursiva_sel"
      item-key="id_contratto"
      show-expand
      :single-expand="true"
    >
      <!-- Titolo della tabella -->
      <template v-slot:top>
        <v-toolbar class="elevation-3 mb-2" color="indigo" dense rounded="0">
          <v-toolbar-title class="white--text"
            >Contratti di Manutenzione</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <!-- Bottone per aggiungere un contratto -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickAggiungiContratto"
                color="blue-grey lighten-5"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Contratto</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="filtro_visible = true"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-filter</v-icon></v-btn
              >
            </template>
            <span>Visualizza Filtri</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
              >
            </template>
            <span>Resetta Filtro</span>
          </v-tooltip>
          <v-spacer></v-spacer>

          <!-- Bottone per tornare indietro -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="goToManutenzione" small v-bind="attrs" v-on="on">
                <v-icon color="error"> mdi-keyboard-backspace </v-icon>
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pt-5" v-if="filtro_visible">
          <v-row align="center" justify="center">
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="contratti_backup"
                item-text="cod_contratto"
                item-value="cod_contratto"
                label="Cod. Contratto"
                style="display: inline-block; max-width: 350px"
                v-model="cod_contratto_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      cod_contratto_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      cod_contratto_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="contratti_backup"
                item-text="ragione_sociale"
                item-value="ragione_sociale"
                label="Fornitore"
                style="display: inline-block; max-width: 350px"
                v-model="fornitore_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      fornitore_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      fornitore_search == "" ? "mdi-filter" : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="ambito_manutentivo_lista"
                item-text="des"
                item-value="id_tabella"
                label="Ambito Manutentivo"
                style="display: inline-block; max-width: 350px"
                v-model="ambito_manutentivo_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      ambito_manutentivo_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      ambito_manutentivo_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-menu
                v-model="calendario_data_contratto_inizio"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    label="Data Inizio Contratto"
                    style="display: inline-block; max-width: 350px"
                    v-model="data_contratto_inizio_search"
                    readonly
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="data_contratto_inizio_search = ''"
                        color="primary"
                        >{{
                          data_contratto_inizio_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  @change="filtra"
                  v-model="data_contratto_inizio_search"
                  @input="calendario_data_contratto_inizio = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" md="2">
              <v-menu
                v-model="calendario_data_contratto_fine"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    label="Data Fine Contratto"
                    style="display: inline-block; max-width: 350px"
                    v-model="data_contratto_fine_search"
                    readonly
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="data_contratto_fine_search = ''"
                        color="primary"
                        >{{
                          data_contratto_fine_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  @change="filtra"
                  v-model="data_contratto_fine_search"
                  @input="calendario_data_contratto_fine = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="[
                  { text: 'Si', value: 1 },
                  { text: 'No', value: 0 },
                ]"
                item-text="text"
                item-value="value"
                label="Rinnovo automatico"
                style="display: inline-block; max-width: 350px"
                v-model="rinnovo_automatico_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      rinnovo_automatico_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      rinnovo_automatico_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </template>

      <!-- Bottone di modifica dei contratti -->
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="clickModificaContratto(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Modifica Contratto</span>
        </v-tooltip>
        <v-tooltip v-if="item.strutture.length == 0" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="duplicaContrattoDa(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-text-box-multiple-outline </v-icon>
            </v-btn>
          </template>
          <span>Duplica dettagli da un altro contratto</span>
        </v-tooltip>
      </template>

      <!-- Colonna del flag di rinnovo automatico -->
      <template v-slot:[`item.rinnovo_automatico`]="{ item }">
        <v-icon color="error" v-if="item.rinnovo_automatico === false">
          mdi-close
        </v-icon>
        <v-icon color="success" v-if="item.rinnovo_automatico === true">
          mdi-check
        </v-icon>
      </template>
      <!-- Drill down delle strutture del contratto -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="pa-0"
          style="background-color: white"
          :colspan="headers.length"
        >
          <v-data-table
            class="elevation-3"
            fixed-header
            :headers="headers_strutture"
            max-height="70vh"
            hide-default-footer
            :items="item.strutture"
          >
            <template v-slot:top>
              <v-toolbar color="indigo" dense rounded="0">
                <v-toolbar-title class="white--text">
                  Sedi e Strutture del Contratto
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="clickAggiungiStruttura(item)"
                      color="blue-grey lighten-5"
                      small
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Struttura</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <!-- Bottone di modifica delle strutture. -->
            <template v-slot:[`item.modifica`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickModificaStruttura(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Modifica Struttura</span>
              </v-tooltip>
            </template>

            <!-- Colonna della q.tà degli interventi. -->
            <template v-slot:[`item.interventi`]="{ item }">
              {{ item.interventi ? item.interventi.length : 0 }}
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog_modifica_contratto" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Modifica Contratto
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaContratto('salva_contratto')"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva Contratto</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogContratto"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-row class="mx-5">
          <v-col cols="2">
            <v-text-field
              hide-details
              label="Cod. Contratto"
              v-model="contratto_sel.cod_contratto"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              :items="fornitore_lista"
              label="Fornitore"
              id="ambiti_manutentivi"
              placeholder="Seleziona"
              item-text="ragione_sociale"
              item-value="id_anagrafica"
              required
              return-object
              :disabled="contratto_sel.strutture.length > 0"
              @change="validaAmbitiManutentivi()"
              v-model="contratto_sel.fornitori"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              :items="ambito_manutentivo_lista"
              label="Ambito Manutentivo"
              placeholder="Seleziona"
              item-text="des"
              item-value="id_tabella"
              :disabled="
                !this.abilita_ambiti_manutentivi ||
                contratto_sel.strutture.length > 0
              "
              required
              v-model="contratto_sel.id_ambito_manutentivo"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="calendario_data_inizio"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  hide-details
                  label="Inizio Contratto"
                  style="display: inline-block; max-width: 350px"
                  v-model="contratto_sel.data_inizio"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="contratto_sel.data_inizio"
                @input="calendario_data_inizio = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="calendario_data_fine"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  hide-details
                  label="Fine Contratto"
                  style="display: inline-block; max-width: 350px"
                  v-model="contratto_sel.data_fine"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="contratto_sel.data_fine"
                @input="calendario_data_fine = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="contratto_sel.rinnovo_automatico"
              label="Rinnovo automatico"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_modifica_struttura" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Modifica Struttura
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaStruttura" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva Struttura</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogStruttura"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-col cols="2">
              <v-autocomplete
                @change="
                  associaMetadatiSede();
                  struttura_sel.id_struttura = null;
                  struttura_sel.des_struttura = null;
                  struttura_sel.indirizzo = '';
                  struttura_sel.referente = '';
                  struttura_sel.telefono = '';
                "
                :items="sede_lista"
                label="Sede"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                :readonly="struttura_sel.interventi.length > 0"
                :disabled="struttura_sel.interventi.length > 0 ? true : false"
                required
                v-model="struttura_sel.id_sede"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                @change="associaMetadatiStruttura"
                :items="
                  struttura_lista.filter(
                    (elem) => elem.val_int === struttura_sel.id_sede
                  )
                "
                label="Struttura"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                :readonly="struttura_sel.id_contratto_dettaglio > 0"
                :disabled="struttura_sel.interventi.length > 0 ? true : false"
                required
                v-model="struttura_sel.id_struttura"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                dense
                hide-details
                label="Indirizzo"
                readonly
                v-model="struttura_sel.indirizzo"
              ></v-text-field
            ></v-col>
            <v-col cols="2">
              <v-text-field
                dense
                hide-details
                label="Referente"
                readonly
                v-model="struttura_sel.referente"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                dense
                hide-details
                label="Telefono"
                readonly
                v-model="struttura_sel.telefono"
              ></v-text-field>
            </v-col>
            <v-col cols="2" id="listener_costo_stimato_struttura">
              <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_costo_stimato_struttura'"
                :label="'Costo da Contratto (€)'"
                id="input_costo_stimato_struttura"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          class="elevation-3"
          fixed-header
          :headers="headers_interventi_struttura"
          max-height="70vh"
          :items="struttura_sel.interventi"
        >
          <template v-slot:top>
            <v-toolbar color="indigo" dense rounded="0">
              <v-toolbar-title class="white--text">
                Interventi per Questa Struttura
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="aggiungiIntervento"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary"> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Intervento</span>
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaIntervento(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary"> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Modifica Intervento</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.num_attivita`]="{ item }">
            {{ item.attivita ? item.attivita.length : 0 }}
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_modifica_intervento" persistent>
      <v-overlay :value="caricamento_dati_intervento">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Modifica Intervento</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaIntervento()"
                :disabled="disabilita_salva_intervento"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva Intervento</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogIntervento"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-row class="mx-5" align="center" justify="center">
          <v-col cols="3">
            <v-text-field
              label="Referente"
              v-model="intervento_sel.des_referente"
            ></v-text-field>
          </v-col>
          <v-col cols="2" id="listener_costo_stimato">
            <!-- <InputSeparatore
              :decimali_fissi="true"
              :destinazione="'listener_costo_stimato'"
              :label="'Costo Stimato (€)'"
              id="input_costo_stimato"
            /> -->
            <v-text-field
              v-model="intervento_sel.costo_stimato"
              type="number"
              disabled
              label="Costo da Contratto(€)"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="intervento_sel.id_tipo_intervento"
              :items="ambito_manutentivo_lista"
              label="Ambito Manutentivo"
              placeholder="Seleziona"
              :disabled="intervento_sel.id_intervento == -1 ? false : true"
              item-text="des"
              item-value="id_tabella"
              readonly
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="3">
            <v-autocomplete
              v-model="id_tipo_manutenzione_ordinaria"
              :items="tipo_manutenzione_lista.filter((f) => f.cod == 'ORD')"
              disabled
              label="Tipo Manutenzione"
              placeholder="Seleziona"
              item-text="des"
              item-value="id_tabella"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="calendario_data_prevista_intervento"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  hide-details
                  label="Data prevista"
                  :disabled="intervento_sel.id_intervento == -1 ? false : true"
                  v-model="intervento_sel.data_prevista"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="contratto_sel.data_inizio"
                :max="contratto_sel.data_fine"
                v-model="intervento_sel.data_prevista"
                @input="calendario_data_prevista_intervento = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              :items="fornitore_lista"
              v-model="intervento_sel.fornitori"
              item-text="ragione_sociale"
              item-value="id_anagrafica"
              multiple
              label="Fornitore"
              readonly
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Annotazioni"
              v-model="intervento_sel.note"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-5" align="center" justify="center">
          <v-col cols="3">
            <v-text-field
              v-model="intervento_sel.frequenza"
              label="Frequenza"
              required
              @keypress="checkValidaFrequenza(intervento_sel.frequenza, $event)"
              :disabled="intervento_sel.id_intervento == -1 ? false : true"
              :min="1"
              :key="key_render_frequenza"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="intervento_sel.um_frequenza"
              :items="um_frequenza_lista"
              label="UM Frequenza"
              placeholder="Seleziona"
              :disabled="intervento_sel.id_intervento == -1 ? false : true"
              item-text="des"
              item-value="id_tabella"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              disabled
              v-model="intervento_sel.des_sede"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              disabled
              v-model="intervento_sel.des_struttura"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="intervento_sel.id_area"
              :items="lista_aree_by_struttura"
              item-text="des"
              item-value="id_tabella"
              label="Area"
              :disabled="intervento_sel.id_intervento == -1 ? false : true"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12">
            <DettaglioAttivita
              @aggiorna_attivita="aggiornaAttivita"
              :attivita="intervento_sel.attivita"
              :intervento="intervento_sel"
              :fornitori="fornitori_intervento"
              :ricorsivo="true"
              ref="dettaglio_attivita"
              :stato_approvato_id="intervento_sel.id_stato"
              :stato_rifiutato_id="null"
              :stato_sospeso_id="null"
              :data_contratto_inizio="contratto_sel.data_inizio"
              :data_contratto_fine="contratto_sel.data_fine"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_duplica_contratto" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Seleziona contratto da duplicare</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  ConfermaDuplicazioneContratto(contratto_duplicazione_sel)
                "
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva Intervento</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogDuplicazione()"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-row class="mx-5" align="center" justify="center">
          <v-col cols="3">
            <v-autocomplete
              v-model="contratto_duplicazione_sel"
              :items="contratti_possibili_per_duplicazione"
              label="Contratti"
              placeholder="Seleziona"
              item-text="cod_contratto"
              item-value="id_contratto"
              @change="CheckAutorizzazioniFornitori(contratto_duplicazione_sel)"
              return-object
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-container style="width: 100%" align="center" justify="center">
          <v-data-table
            class="elevation-3"
            fixed-header
            :headers="headers_strutture.filter((f) => f.text != 'Modifica')"
            max-height="70vh"
            hide-default-footer
            v-if="contratto_duplicazione_sel != null"
            :items="contratto_duplicazione_sel.strutture"
          >
            <template v-slot:top>
              <v-toolbar color="indigo" dense rounded="0">
                <v-toolbar-title class="white--text">
                  Sedi e Strutture del Contratto
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <!-- Bottone di modifica delle strutture. -->
            <!-- Colonna della q.tà degli interventi. -->
            <template v-slot:[`item.interventi`]="{ item }">
              {{ item.interventi ? item.interventi.length : 0 }}
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import Contract from "@/classes/Contract.js";
import DettaglioAttivita from "@/components/MANUTENZIONE/DettaglioAttivita.vue";
import InputSeparatore from "@/components/TOOLS/InputSeparatore.vue";
import Intervention from "@/classes/Intervention.js";
import moment from "moment/moment";

class Structure {
  constructor(arg) {
    if (arg != undefined) {
      this.des_sede = arg.des_sede != undefined ? arg.des_sede : "";
      this.des_struttura =
        arg.des_struttura != undefined ? arg.des_struttura : "";
      this.id_area = arg.id_area != undefined ? arg.id_area : null;
      this.id_contratto = arg.id_contratto != undefined ? arg.id_contratto : -1;
      this.id_contratto_dettaglio =
        arg.id_contratto_dettaglio != undefined
          ? arg.id_contratto_dettaglio
          : -1;
      this.id_sede = arg.id_sede != undefined ? arg.id_sede : null;
      this.id_struttura =
        arg.id_struttura != undefined ? arg.id_struttura : null;
      this.id_zona = arg.id_zona != undefined ? arg.id_zona : null;
      this.indirizzo = arg.indirizzo != undefined ? arg.indirizzo : "";
      this.interventi = arg.interventi != undefined ? arg.interventi : [];
      this.referente = arg.referente != undefined ? arg.referente : "";
      this.telefono = arg.telefono != undefined ? arg.telefono : "";
      this.costo_stimato =
        arg.costo_stimato != undefined ? arg.costo_stimato : 0;
      this.validita = arg.validita != undefined ? arg.validita : 1;
    } else {
      this.des_sede = "";
      this.des_struttura = "";
      this.id_area = null;
      this.id_contratto = -1;
      this.id_contratto_dettaglio = -1;
      this.id_sede = null;
      this.id_struttura = null;
      this.id_zona = null;
      this.indirizzo = "";
      this.interventi = [];
      this.referente = "";
      this.telefono = "";
      this.costo_stimato = 0;
      this.validita = 1;
    }
  }
}

export default {
  name: "ManutenzioneRecursiva",
  components: {
    DettaglioAttivita,
    InputSeparatore,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      autorizzazioni_fornitore_contratto: {
        sedi: [],
        strutture: [],
      },
      contratto_sel: new Contract(),
      contratti_possibili_per_duplicazione: [],
      contratto_duplicazione_sel: null,
      disabilita_salva_intervento: false,
      fornitori_tmp: [],

      intervento_sel: new Intervention(),
      aggiungi_struttura: false,
      struttura_sel: new Structure(),
      id_tipo_manutenzione_ordinaria: null,
      id_urgenza_ordinaria: null,

      // Modal
      calendario_data_fine: null,
      calendario_data_inizio: null,
      caricamento_dati: false,
      caricamento_dati_intervento:false,
      dialog_modifica_contratto: false,
      dialog_modifica_intervento: false,
      dialog_modifica_struttura: false,
      dialog_duplica_contratto: false,
      id_stato_operativo_in_lavorazione: null,
      id_stato_operativo_richiesto: null,
      cod_contratto_search: "",
      fornitore_search: "",
      calendario_data_contratto_inizio: false,
      calendario_data_prevista_intervento: false,
      data_contratto_inizio_search: "",
      data_contratto_fine_search: "",
      calendario_data_contratto_fine: false,
      ambito_manutentivo_search: "",
      rinnovo_automatico_search: "",
      filtro_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      ambito_manutentivo_lista: [],
      attivita_intervento: [],
      expanded_contratti: [],
      fornitore_lista: [],
      fornitori_intervento: [],
      headers_interventi_struttura: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Cod. Richiesta",
          value: "cod_richiesta",
          sortable: false,
        },
        {
          text: "N° Attività",
          sortable: false,
          value: "num_attivita",
          align: "center",
        },
        {
          text: "Ambito Manutentivo",
          sortable: false,
          value: "des_tipo_intervento",
        },
        {
          text: "Referente",
          sortable: false,
          value: "des_referente",
        },
        {
          text: "Costo Stimato (€)",
          sortable: false,
          value: "costo_stimato",
        },
        {
          text: "Regola",
          sortable: false,
          value: "cod_regola",
        },
        {
          text: "Tipo Manutenzione",
          value: "des_tipo_manutenzione",
          sortable: false,
        },
      ],
      headers_man_recursiva: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Cod. Contratto", sortable: true, value: "cod_contratto" },
        { text: "Fornitore", sortable: true, value: "ragione_sociale" },
        {
          text: "Ambito Manutentivo",
          sortable: true,
          value: "des_ambito_manutentivo",
        },
        { text: "Inizio", sortable: true, value: "data_inizio" },
        { text: "Scadenza", sortable: true, value: "data_fine" },
        {
          text: "Rinnovo Automatico",
          sortable: true,
          value: "rinnovo_automatico",
          align: "center",
        },
      ],
      headers_strutture: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Sede", sortable: false, value: "des_sede" },
        { text: "Struttura", sortable: false, value: "des_struttura" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "Referente", sortable: false, value: "referente" },
        { text: "Telefono", sortable: false, value: "telefono" },
        {
          text: "Q.tà Interventi",
          sortable: false,
          value: "interventi",
          align: "center",
        },
      ],
      reg: new RegExp("(^$)|(^[0-9]+$)"),
      key_render_frequenza: 0,
      items_man_recursiva: [],
      items_man_recursiva_sel: [],
      contratti_backup: [],
      sede_lista: [],
      stato_intervento_lista: [],
      struttura_lista: [],
      tipo_manutenzione_lista: [],
      um_frequenza_lista: [],
      urgenza_lista: [],
      abilita_ambiti_manutentivi: false,
      contatore: 0,
      lista_aree_by_struttura: [],
    };
  },
  computed: {},
  watch: {
    // dialog_modifica_intervento: {
    //   immediate: true,
    //   handler() {
    //     if (this.dialog_modifica_intervento == true) {
    //       let that = this;
    //       setTimeout(() => {
    //         // Do il valore iniziale all'input del costo stimato
    //         let messaggio = {
    //           detail: {
    //             numero: this.intervento_sel.costo_stimato,
    //           },
    //           src: null,
    //         };

    //         // Inserisco il messaggio in un evento custom.
    //         let evento = new CustomEvent("numero_iniziale", messaggio);

    //         // Consegno alla mia destinazione l'evento
    //         document
    //           .getElementById("listener_InputSeparatoreinput_costo_stimato")
    //           .dispatchEvent(evento);

    //         // Associo un listener per l'aggiornamento all'InputSeparatore del costo stimato
    //         document
    //           .getElementById("listener_costo_stimato")
    //           .addEventListener("nuovo_numero", function (elem) {
    //             that.intervento_sel.costo_stimato = elem.detail.numero;
    //           });
    //       }, 10);
    //     }
    //   },
    // },
    dialog_modifica_struttura: {
      immediate: true,
      handler() {
        if (this.dialog_modifica_struttura == true) {
          let that = this;
          setTimeout(() => {
            // Do il valore iniziale all'input del costo stimato
            let messaggio = {
              detail: {
                numero: this.struttura_sel.costo_stimato,
              },
              src: null,
            };

            // Inserisco il messaggio in un evento custom.
            let evento = new CustomEvent("numero_iniziale", messaggio);

            // Consegno alla mia destinazione l'evento
            document
              .getElementById(
                "listener_InputSeparatoreinput_costo_stimato_struttura"
              )
              .dispatchEvent(evento);

            // Associo un listener per l'aggiornamento all'InputSeparatore del costo stimato
            document
              .getElementById("listener_costo_stimato_struttura")
              .addEventListener("nuovo_numero", function (elem) {
                console.log("ENTRATO LISTENER PADRE:", elem.detail.numero);
                that.struttura_sel.costo_stimato = elem.detail.numero;
              });
          }, 10);
        }
      },
    },

    "intervento_sel.frequenza": function (NewValue) {
      if (this.reg.test(NewValue) == false) {
        this.key_render_frequenza++;
        NewValue = "";
        this.intervento_sel.frequenza = NewValue;
      } else {
        this.intervento_sel.frequenza = NewValue;
      }
    },
  },
  methods: {
    filtra() {
      let items_man_recursiva_tmp = [];
      this.items_man_recursiva.forEach((elem) => {
        items_man_recursiva_tmp.push(elem);
      });

      // Filtro il cod_richiesta
      if (this.cod_contratto_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_man_recursiva_tmp.forEach((elem) => {
          if (elem.cod_contratto == this.cod_contratto_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_man_recursiva_tmp = res;
      }

      if (this.ambito_manutentivo_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_man_recursiva_tmp.forEach((elem) => {
          if (elem.id_ambito_manutentivo == this.ambito_manutentivo_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_man_recursiva_tmp = res;
      }

      // Filtro il richiedente
      if (this.fornitore_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_man_recursiva_tmp.forEach((elem) => {
          if (elem.ragione_sociale == this.fornitore_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_man_recursiva_tmp = res;
      }

      // Filtro la data richiesta
      if (this.data_contratto_inizio_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Mi segno la data ricercata formattata in moment
        let data_ricercata = new moment(
          this.data_contratto_inizio_search.substring(0, 4) +
            "-" +
            this.data_contratto_inizio_search.substring(5, 7) +
            "-" +
            this.data_contratto_inizio_search.substring(8, 10)
        );
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_man_recursiva_tmp.forEach((elem) => {
          elem.data_js = new moment(elem.data_inizio);
          console.log("elem.data_js:", elem.data_js);
          if (elem.data_js.isSameOrAfter(data_ricercata)) {
            /* Dopo il confronto se c'è match elimino la data moment
             * per convenienza di BE e aggiungo l'oggetto all'array */
            delete elem.data_js;
            res.push(elem);
          } else {
            // Altrimenti non faccio niente, ma elimino comunque la data moment.
            res = [];
            delete elem.data_js;
          }
        });
        items_man_recursiva_tmp = res;
      }

      if (this.data_contratto_fine_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Mi segno la data ricercata formattata in moment
        let data_ricercata_fine = new moment(
          this.data_contratto_fine_search.substring(0, 4) +
            "-" +
            this.data_contratto_fine_search.substring(5, 7) +
            "-" +
            this.data_contratto_fine_search.substring(8, 10)
        );

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_man_recursiva_tmp.forEach((elem) => {
          elem.data_js = new moment(elem.data_fine);
          if (elem.data_js.isSameOrBefore(data_ricercata_fine)) {
            /* Dopo il confronto se c'è match elimino la data moment
             * per convenienza di BE e aggiungo l'oggetto all'array */
            delete elem.data_js;
            res.push(elem);
          } else {
            res = [];
            delete elem.data_js;
          }
        });
        items_man_recursiva_tmp = res;
      }

      if (this.rinnovo_automatico_search !== "") {
        console.log(
          "rinnovo automatico search:",
          this.rinnovo_automatico_search
        );
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_man_recursiva_tmp.forEach((elem) => {
          if (elem.rinnovo_automatico == this.rinnovo_automatico_search) {
            res.push(elem);
          }
        });
        // Modello la risposta provvisoria su "res".
        items_man_recursiva_tmp = res;
      }

      //if (items_man_recursiva_tmp.length != 0)
      this.items_man_recursiva_sel = items_man_recursiva_tmp;
      // else this.items_man_recursiva_sel = this.contratti_backup;
    },
    resetFiltro() {
      // Resetto i filtri
      this.cod_contratto = "";
      this.fornitore_search = "";
      this.data_contratto_inizio_search = "";
      this.data_contratto_fine_search = "";
      this.ambito_manutentivo_search = "";

      // Nascondo i filtri
      this.filtro_visible = false;

      // Ricarico la tabella
      this.items_man_recursiva_sel = Array.from(this.contratti_backup);
    },
    checkValidaFrequenza(frequenza, evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 190
      ) {
        evt.preventDefault();
      } else {
        if (parseInt(frequenza) < 0) {
          console.log("ENTRATOOOOO");
          this.intervento_sel.frequenza = 1;
        }
        return true;
      }

      console.log("FREQUENZA:", frequenza);
      console.log("FREQUENZA INTERVENTO:", this.intervento_sel.frequenza);
    },
    CheckAutorizzazioniFornitori(contratto_duplicazione_sel) {
      console.log(
        "AUTORIZZAZIONI FORNITORE SELEZIONATO:",
        this.autorizzazioni_fornitore_contratto
      );
      console.log(
        "CONTRATTO DUPLICAZIONE SELEZIONATO:",
        contratto_duplicazione_sel.strutture
      );
      let UnauthorizedSede = false;
      let UnauthorizedStruttura = false;

      contratto_duplicazione_sel.strutture.forEach((f) => {
        console.log(
          "FILTRO:",
          this.autorizzazioni_fornitore_contratto.sedi.filter(
            (sede) => f.id_sede == sede
          )
        );
        if (
          this.autorizzazioni_fornitore_contratto.sedi.filter(
            (sede) => f.id_sede == sede
          ).length == 0
        ) {
          UnauthorizedSede = true;
        }
      });

      contratto_duplicazione_sel.strutture.forEach((f) => {
        if (
          this.autorizzazioni_fornitore_contratto.strutture.filter(
            (struttura) => f.id_struttura == struttura
          ).length == 0
        ) {
          UnauthorizedStruttura = true;
        }
      });

      if (UnauthorizedSede) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il fornitore del contratto originario non è autorizzato per le sedi e strutture del contratto scelto per la duplicazione!";
        this.snackbar_text_color = "white";
      }
      if (!UnauthorizedSede && UnauthorizedStruttura) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il fornitore del contratto originario non è autorizzato per alcune strutture del contratto scelto per la duplicazione!";
        this.snackbar_text_color = "white";
      }
      if (!UnauthorizedSede && !UnauthorizedStruttura) {
        this.snackbar = true;
        this.snackbar_background = "success";
        this.snackbar_icon = "mdi-check-outline";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il fornitore del contratto originario è autorizzato per sedi e strutture del contratto scelto per la duplicazione!";
        this.snackbar_text_color = "white";
      }
    },
    ConfermaDuplicazioneContratto(contratto_da_duplicare) {
      let i = 0;
      let length = contratto_da_duplicare.strutture.length;
      console.log("CONTRATTO DA DUPLICARE:", contratto_da_duplicare);
      contratto_da_duplicare.strutture.forEach((struttura) => {
        struttura.id_contratto = this.contratto_sel.id_contratto;
        this.contratto_sel.strutture.push(struttura);
        if (i == length - 1) {
          console.log("ENTRATOOOOOOOOOO");
          this.salvaContratto("salva_contratto", true);
        }
        console.log("BLABLABLA");
        if (struttura.interventi.length > 0) {
          struttura.interventi.forEach((intervento) => {
            let intervento_decodificato = JSON.parse(
              decodeURIComponent(escape(atob(intervento.matrice)))
            );
            intervento_decodificato.fornitori = this.contratto_sel.fornitori;
            intervento_decodificato.fornitori[0]["id_fornitore_dettaglio"] = -1;
            this.intervento_sel = intervento_decodificato;

            this.setManutenzioneRicorsiva();
          });
        }
        i++;
      });
      console.log("CONTRATTO SEL DUPLICATO STRUTTURE:", this.contratto_sel);
      console.log("INTERVENTO DECODIFICATO:", this.intervento_sel);
    },
    duplicaContrattoDa(item) {
      this.contratto_sel = item;
      this.caricamento_dati = true;
      this.getAutorizzazioniByFornitori(this.contratto_sel.id_anagrafica, true);
      console.log("contratto selezionato per duplicazione:", item);
      this.contratti_possibili_per_duplicazione = this.items_man_recursiva.filter(
        (f) => f.id_contratto != item.id_contratto && f.strutture.length != 0
      );
      console.log(
        "CONTRATTI POSSIBILI PER DUPLICAZIONE",
        this.contratti_possibili_per_duplicazione
      );
      this.dialog_duplica_contratto = true;
    },

    chiudiDialogDuplicazione() {
      this.dialog_duplica_contratto = false;
      this.caricamento_dati = false;
    },

    getAnagraficaDettaglio(tipo, id) {
      /* Chiamata per ottenere l'array di sedi
       * del cliente con i relativi fornitori,
       * oppure il dettaglio di un fornitore in
       * termini di autorizzazioni e ambiti di
       * manutenzione disponibili. */
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getanagraficadettaglio",
          token: localStorage.user_token,
          id_anagrafica: id,
          tipo: tipo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          /* In caso stia chiedendo i dettagli degli ambiti
           * manutentivi e autorizzazioni di un fornitore,
           * valorizzo gli array debiti. */
          let res_tmp = res.data.result.fornitori[0].ambiti_manutenzione;
          let sup = [];
          let request = {
            controller: "Global",
            method: "POST",
            richiesta: {
              action: "gettabella",
              token: localStorage.getItem("user_token"),
              tipo: "MAN_AMBITO_MANUTENTIVO",
              filter: "validita = 1",
            },
          };

          this.$store.dispatch("api", request).then((response) => {
            this.ambito_manutentivo_lista =
              response.data != undefined ? response.data : [];
            console.log(
              "AMBITO MANUTENTIVO LISTA:",
              this.ambito_manutentivo_lista
            );
            res_tmp.forEach((e) => {
              this.ambito_manutentivo_lista.forEach((f) => {
                if (e.id_ambito_manutenzione == f.id_tabella) {
                  sup.push(f);
                }
              });
            });
            this.ambito_manutentivo_lista = sup;
          });
        }
      });
    },

    validaAmbitiManutentivi() {
      this.contratto_sel.id_ambito_manutentivo = null;
      if (
        this.contratto_sel.fornitori != null ||
        this.contratto_sel.fornitori != {}
      ) {
        this.abilita_ambiti_manutentivi = true;
        this.getAnagraficaDettaglio(
          "FORNITORE",
          this.contratto_sel.fornitori.id_anagrafica
        );
      } else {
        this.abilita_ambiti_manutentivi = false;
      }
    },
    aggiornaAttivita() {
      // Carico nell'intervento_sel le attività aggiornate.
      this.intervento_sel.attivita = this.$refs.dettaglio_attivita.items_attivita;

      // Faccio un check sugli identificativi delle attività
      this.intervento_sel.attivita.forEach((attivita) => {
        if (attivita.id_attivita < 0) {
          attivita.id_attivita = -1;
        }

        /* Per ogni attività, faccio un check sugli
         * identificativi dei suoi allegati. */
        if (attivita.allegati != undefined) {
          attivita.allegati.forEach((allegato) => {
            if (allegato.id_allegato < 0) {
              allegato.id_allegato = -1;
              allegato.id_riferimento = -1;
            }
          });
        }
      });
    },
    aggiungiIntervento() {
      this.getAreeByStruttura(this.struttura_sel.id_struttura);
      console.log("contratto_sel", this.contratto_sel);
      if (this.struttura_sel.costo_stimato == 0) {
        this.snackbar = true;
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire un costo da contratto valido!";
        this.snackbar_text_color = "white";
        this.snackbar_background = "orange";
        return;
      }
      // Preparo un nuovo intervento.
      this.intervento_sel = new Intervention();

      // Assegno all'intervento l'id_sede e l'id_struttura.
      this.intervento_sel.id_sede = this.struttura_sel.id_sede;
      this.intervento_sel.id_struttura = this.struttura_sel.id_struttura;

      // Assegno all'intervento la des_sede e la des_struttura.
      this.intervento_sel.des_sede = this.struttura_sel.des_sede;
      this.intervento_sel.des_struttura = this.struttura_sel.des_struttura;

      // Assegno il tipo manutenzione di default.
      this.intervento_sel.id_tipo_manutenzione = this.id_tipo_manutenzione_ordinaria;

      // Assegno l'ambito manutentivo in base al contratto.
      this.intervento_sel.id_tipo_intervento = this.contratto_sel.id_ambito_manutentivo;
      this.intervento_sel.costo_stimato = this.struttura_sel.costo_stimato;

      // Assegno il fornitore all'intervento in base al fornitore del contratto (ce n'è sempre solo uno).

      /* Resetto e ripopolo l'array fornitori_intervento che serve al
       * dettaglio_attivita per capire quali sono i fornitori dell'intervento. */
      this.fornitori_intervento = [];
      this.contratto_sel.fornitori.forEach((e) => {
        this.intervento_sel.fornitori.push(e);
        this.fornitori_intervento.push(e.id_anagrafica);
      });
      // this.fornitori_intervento.push(
      //   this.contratto_sel.fornitori[0].id_anagrafica
      // );

      // Apro il dialog della modifica dell'intervento.
      console.log("data_inizio_contratto:", this.contratto_sel.data_inizio);
      console.log("data_fine_contratto:", this.contratto_sel.data_fine);

      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getregolabyambitomanutentivo",
          token: localStorage.getItem("user_token"),
          id_ambito_manutentivo: this.contratto_sel.id_ambito_manutentivo,
        },
      };

      //this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Azzero le anagrafiche
        if (res.status === 200) {
          if (res.data.result == true) {
            this.dialog_modifica_intervento = true;
            this.disabilita_salva_intervento = false;
          } else {
            this.dialog_modifica_intervento = true;
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Nessuna regola presente per l'ambito manutentivo selezionato";
            this.snackbar_text_color = "white";
            this.disabilita_salva_intervento = true;
          }
        }
      });
    },
    associaIdUrgenza() {
      // Ciclo sullalista delle urgenze...
      this.urgenza_lista.forEach((urgenza) => {
        // E abbino la descrizione selezionata al suo identificativo.
        if (this.intervento_sel.des_urgenza === urgenza.des) {
          this.intervento_sel.id_urgenza = urgenza.id_tabella;
        }
      });
    },
    associaMetadatiSede() {
      this.sede_lista.forEach((sede) => {
        if (sede.id_tabella === this.struttura_sel.id_sede) {
          this.struttura_sel.des_sede = sede.des;
        }
      });
    },
    associaMetadatiStruttura() {
      this.struttura_lista.forEach((struttura) => {
        if (struttura.id_tabella === this.struttura_sel.id_struttura) {
          this.struttura_sel.des_struttura = struttura.des;
          this.struttura_sel.indirizzo = struttura.val_txt;
          this.struttura_sel.referente = struttura.des_lingua1;
          this.struttura_sel.telefono = struttura.des_lingua2;
        }
      });
    },
    caricaContratti() {
      // Apro l'overlay di caricamento
      this.caricamento_dati = true;

      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getcontrattilist",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          // Indipendentemente dallo stato della risposta, chiudo l'overlay di caricamento.
          this.caricamento_dati = false;

          if (res.status === 200) {
            // Azzero le anagrafiche
            this.items_man_recursiva = [];
            console.log("LISTA CONTRATTI:", res.data.result.contratti);
            // Popolo l'array dei contratti in base alla response
            res.data.result.contratti.forEach((contratto) => {
              // Genero un contratto temporaneo
              let contratto_tmp = new Contract(contratto);

              // Traduco il campo rinnovo automatico da numero a boolenano
              contratto_tmp.rinnovo_automatico =
                parseInt(contratto.rinnovo_automatico) === 0 ? false : true;

              // Per ogni sede...
              if (contratto.sedi != undefined) {
                contratto.sedi.forEach((sede) => {
                  // ... Ciclo sulle sue strutture...
                  sede.strutture.forEach((struttura) => {
                    /* E genero una struttura temporanea come serve al FE,
                     * riassemblando i dati della sede e della struttura. */
                    let struttura_tmp = new Structure(struttura);
                    struttura_tmp.des_sede = sede.des_sede;

                    // Aggiungo la struttura alle strutture del contratto.
                    contratto_tmp.strutture.push(struttura_tmp);
                  });
                });
              }

              /* Il BE può ritornare strutture che non appartengono al contratto,
               * per cui elimino le strutture che non appartengono a questo contratto.*/
              let strutture_tmp = contratto_tmp.strutture.filter(
                (strutt) => strutt.id_contratto === contratto_tmp.id_contratto
              );
              console.log("strutture_tmp", strutture_tmp.interventi);
              // let interventi_tmp = strutture_tmp.interventi.filter(f=>{f.id_contratto = strutture_tmp.id_contratto});
              // strutture_tmp.interventi=[];
              // strutture_tmp.interventi = interventi_tmp;
              contratto_tmp.strutture = [];
              contratto_tmp.strutture = strutture_tmp;
              contratto_tmp.strutture.forEach((e) => {
                let interventi_tmp = e.interventi.filter(
                  (f) => f.id_contratto == e.id_contratto
                );
                e.interventi = [];
                e.interventi = interventi_tmp;
              });

              // Aggiungo il contratto riassemblato all'array dei contratti totali.
              this.items_man_recursiva.push(contratto_tmp);
            });
            console.log("contratti_totali:", this.items_man_recursiva);
            this.items_man_recursiva.forEach((e) => {
              e.ragione_sociale = "";
              e.fornitori.forEach((k) => {
                if (e.fornitori.indexOf(k) < e.fornitori.length - 1) {
                  e.ragione_sociale += k.ragione_sociale + " ,";
                } else {
                  e.ragione_sociale += k.ragione_sociale;
                }
              });
            });
            // Inizialmente visualizzo tutti i dati in tabella
            this.items_man_recursiva.forEach((e) => {
              if (e.fornitori[0] != undefined) {
                e.id_anagrafica = e.fornitori[0].id_anagrafica;
              }
              console.log("id_anagrafica:", e.id_anagrafica);
            });
            this.items_man_recursiva_sel = this.items_man_recursiva;
            this.contratti_backup = [];

            this.items_man_recursiva.forEach((e) => {
              this.contratti_backup.push(e);
            });

            /* Resetto le selezioni. Questo serve perché anche altri metodi
             * chiedono di ricaricare i dati chiamano questo metodo, e voglio
             * assicurarmi che alla fine le selezioni siano pulite. */
            this.contratto_sel = new Contract();
            this.struttura_sel = new Structure();
            this.intervento_sel = new Intervention();
          } else {
            // Se c'è un errore stampo una snackbar
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Errore tecnico, non è stato possibile caricare i contratti.";
            this.snackbar_text_color = "white";
          }
        })
        .catch((e) => {
          console.log(e);
          this.caricamento_dati = false;
        });
    },
    caricaFornitori() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getanagraficalist",
          token: localStorage.getItem("user_token"),
          tipo: "FORNITORE",
        },
      };

      //this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Azzero le anagrafiche
        this.items_anagrafiche = [];

        // Popolo le anagrafiche in base alla response
        this.fornitore_lista = res.data.result;
      });
    },
    chiudiDialogContratto() {
      this.dialog_modifica_contratto = false;
      this.contratto_sel = new Contract();
    },
    chiudiDialogIntervento() {
      // Preparo un nuovo intervento resettato.
      this.intervento_sel = new Intervention();

      // Apro il dialog.
      this.dialog_modifica_intervento = false;
    },
    chiudiDialogStruttura() {
      // Chiudo il dialog della struttura.
      this.dialog_modifica_struttura = false;
      this.caricamento_dati = false;

      // Resetto l'oggetto struttura selezionata
      this.struttura_sel = new Structure();
    },
    clickAggiungiContratto() {
      // Resetto il contratto selezionato.
      this.contratto_sel = new Contract();

      // Apro il dialog
      this.dialog_modifica_contratto = true;
    },
    getAutorizzazioniByFornitori(id_fornitore, duplica) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getautorizzazionibyfornitore",
          token: localStorage.getItem("user_token"),
          id_fornitore: id_fornitore,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.autorizzazioni_fornitore_contratto.sedi = [];
          this.autorizzazioni_fornitore_contratto.strutture = [];
          if (duplica == undefined || !duplica) {
            this.dialog_modifica_struttura = true;
          }

          if (duplica != undefined && duplica) {
            this.dialog_duplica_contratto = true;
          }

          let autorizzazioni = res.data.result.autorizzazioni;
          console.log("LISTA SEDI:", this.sede_lista);
          console.log("LISTA STRUTTURE:", this.struttura_lista);
          autorizzazioni.forEach((e) => {
            this.autorizzazioni_fornitore_contratto.sedi.push(e.id_sede);
            this.autorizzazioni_fornitore_contratto.strutture.push(
              e.id_struttura
            );
          });
          this.autorizzazioni_fornitore_contratto.sedi = Array.from(
            new Set(this.autorizzazioni_fornitore_contratto.sedi)
          );
          this.autorizzazioni_fornitore_contratto.strutture = Array.from(
            new Set(this.autorizzazioni_fornitore_contratto.strutture)
          );
          console.log(
            "AUTORIZZAZIONI FINALI:",
            this.autorizzazioni_fornitore_contratto
          );

          let sedi_tmp = [];
          let strutture_tmp = [];
          this.sede_lista.forEach((e) => {
            sedi_tmp.push(e);
          });
          this.struttura_lista.forEach((e) => {
            strutture_tmp.push(e);
          });
          this.sede_lista = [];
          this.struttura_lista = [];

          sedi_tmp.forEach((f) => {
            this.autorizzazioni_fornitore_contratto.sedi.forEach((e) => {
              if (f.id_tabella == e) {
                this.sede_lista.push(f);
              }
            });
          });
          strutture_tmp.forEach((f) => {
            this.autorizzazioni_fornitore_contratto.strutture.forEach((e) => {
              if (f.id_tabella == e) {
                this.struttura_lista.push(f);
              }
            });
          });
        } else {
          this.caricamento_dati = false;
        }
      });
    },
    clickAggiungiStruttura(item) {
      this.aggiungi_struttura = true;
      console.log("ITEM", item);
      console.log("CONTRATTI LISTA:", this.items_man_recursiva);
      // Registro il contratto selezionato
      this.contratto_sel = new Contract(item);

      // Resetto la struttura selezionata.
      this.struttura_sel = new Structure();
      this.struttura_sel.id_contratto = this.contratto_sel.id_contratto;
      this.caricamento_dati = true;
      console.log("Nuova struttura:", this.struttura_sel);
      this.getAutorizzazioniByFornitori(item.id_anagrafica);
      // Apro il dialog.
    },
    clickModificaContratto(item) {
      console.log("ITEM SELEZIONATO:", item);
      // Resetto il contratto selezionato.
      // this.fornitori_tmp = item.fornitori
      this.contratto_sel = new Contract(item);
      this.contratto_sel.fornitori = item.fornitori[0];
      this.fornitori_tmp = item.fornitori[0];
      console.log("CONTRATTO SEL:", this.contratto_sel);

      //creo un clone dell'oggetto desiderato e lo pusho dentro l'array fornitori tmp
      // item.fornitori.forEach((e) => {
      //   this.fornitori_tmp.push(Object.assign({}, e));
      // });
      // for (let i = 0; i < item.fornitori.length; i++) {
      //  this.fornitori_tmp.push(Object.assign({}, item.fornitori[i]));

      // }
      console.log("fornitori_tmp", this.fornitori_tmp);

      // Apro il dialog di modifica dei contratti.
      this.dialog_modifica_contratto = true;
    },
    clickModificaIntervento(item) {
      // Registro l'intervento selezionato.
      this.intervento_sel = new Intervention(item);

      // Assegno il fornitore all'intervento in base al fornitore del contratto (ce n'è sempre solo uno).
      this.intervento_sel.fornitori.push(this.contratto_sel.fornitori[0]);

      // Assegno all'intervento l'id_sede e l'id_struttura.
      this.intervento_sel.id_sede = this.struttura_sel.id_sede;
      this.intervento_sel.id_struttura = this.struttura_sel.id_struttura;

      // Assegno all'intervento la des_sede e la des_struttura.
      this.intervento_sel.des_sede = this.struttura_sel.des_sede;
      this.intervento_sel.des_struttura = this.struttura_sel.des_struttura;

      // Apro il dialog di modifica dell'intervento;
      this.dialog_modifica_intervento = true;
    },
    getStrutturaDettaglio(id_contratto, id_sede, id_struttura) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getstrutturadettaglio",
          token: localStorage.getItem("user_token"),
          id_contratto: id_contratto,
          id_sede: id_sede,
          id_struttura: id_struttura,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.caricamento_dati = false;
          console.log("res.data", res.data.result);
          this.struttura_sel.costo_stimato = res.data.result[0].costo_stimato;
          console.log("STRUTTURA SELEZIONATA:", this.struttura_sel);
          this.dialog_modifica_struttura = true;
        } else {
          this.caricamento_dati = false;
        }
      });
    },
    clickModificaStruttura(item) {
      this.getAreeByStruttura(item.id_struttura);
      this.aggiungi_struttura = false;
      // Ricavo il contratto padre della struttura e lo seleziono.
      console.log("STRUTTURA SELEZIONATA:", item);
      console.log("I MIEI CONTRATTI:", this.items_man_recursiva);
      this.items_man_recursiva.forEach((contratto) => {
        if (contratto.id_contratto === item.id_contratto) {
          this.contratto_sel = new Contract(contratto);
        }
      });
      console.log("CONTRATTO SEL DOPO INIZIALIZZAZIONE:", this.contratto_sel);
      // Seleziono la struttura e apro il dialog di modifica.
      this.struttura_sel = new Structure(item);
      console.log("STRUTTURA SEL DOPO INIZIALIZZAZIONE", this.struttura_sel);
      this.caricamento_dati = true;
      this.getStrutturaDettaglio(
        item.id_contratto,
        item.id_sede,
        item.id_struttura
      );
    },
    getAreeByStruttura(id_struttura) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getareacentrocosto",
          token: localStorage.getItem("user_token"),
          id_struttura: id_struttura,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          console.log("res areeeee:", res.data);
          this.lista_aree_by_struttura = res.data.result.aree;
        }
      });
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_AMBITO_MANUTENTIVO','MAN_SEDE','MAN_STRUTTURA','MAN_TIPO_MANUTENZIONE','MAN_URGENZA','MAN_FREQUENZA','MAN_UM_FREQUENZA', 'MAN_STATO_INTERVENTO_OPERATIVO'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.ambito_manutentivo_lista =
          res.data.MAN_AMBITO_MANUTENTIVO != undefined
            ? res.data.MAN_AMBITO_MANUTENTIVO
            : [];
        console.log("AMBITO MANUTENTIVO LISTA:", this.ambito_manutentivo_lista);
        this.sede_lista =
          res.data.MAN_SEDE != undefined ? res.data.MAN_SEDE : [];

        this.struttura_lista =
          res.data.MAN_STRUTTURA != undefined ? res.data.MAN_STRUTTURA : [];

        this.tipo_manutenzione_lista =
          res.data.MAN_TIPO_MANUTENZIONE != undefined
            ? res.data.MAN_TIPO_MANUTENZIONE
            : [];
        console.log("TIPO MANUTENZIONE LISTA:", this.tipo_manutenzione_lista);
        this.tipo_manutenzione_lista.forEach((tipo) => {
          if (tipo.cod === "ORD") {
            this.id_tipo_manutenzione_ordinaria = tipo.id_tabella;
          }
        });

        this.urgenza_lista =
          res.data.MAN_URGENZA != undefined ? res.data.MAN_URGENZA : [];

        this.um_frequenza_lista =
          res.data.MAN_UM_FREQUENZA != undefined
            ? res.data.MAN_UM_FREQUENZA
            : [];

        // Registro gli id_stato_operativo_in_lavorazione e id_stato_operativo_richiesto
        this.stato_intervento_lista =
          res.data.MAN_STATO_INTERVENTO_OPERATIVO != undefined
            ? res.data.MAN_STATO_INTERVENTO_OPERATIVO
            : [];

        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "RICHIESTO") {
            this.id_stato_operativo_richiesto = elem.id_tabella;
          }
        });

        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "IN LAVORAZIONE") {
            this.id_stato_operativo_in_lavorazione = elem.id_tabella;
          }
        });

        // Ricavo l'id dell'urgenza ordinaria
        this.urgenza_lista.forEach((urgenza) => {
          if (urgenza.des === "BASSA") {
            this.id_urgenza_ordinaria = urgenza.id_tabella;
          }
        });
      });
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
    salvaContratto(arg, resizeFornitori) {
      // Faccio scattare l'overlay di caricamento

      console.log("ARGOMENTO:", arg);
      console.log("fornitori_tmp", this.fornitori_tmp);

      // Creo un oggetto temporaneo su cui modellare i dati del contratto
      let contratto_tmp = new Contract(this.contratto_sel);
      console.log("contratto_sel:", this.contratto_sel);

      // Modifico il contratto temporaneo secondo le necessità del BE...

      // Traduco il rinnovo automatico da booleano a numero
      contratto_tmp.rinnovo_automatico =
        contratto_tmp.rinnovo_automatico === true ? 1 : 0;

      /* Inserisco nel contratto temporaneo un array
       * contratto_dettaglio per comodità di BE*/
      contratto_tmp.contratto_dettaglio = [];
      contratto_tmp.fornitori_dettaglio = [];
      if (Array.isArray(this.contratto_sel.fornitori) == false) {
        this.contratto_sel.fornitori = [this.contratto_sel.fornitori];
      }
      this.contratto_sel.fornitori.forEach((f) => {
        this.fornitore_lista.forEach((e) => {
          if (e.id_anagrafica === f.id_anagrafica) {
            let fornitori_dettaglio = {
              id_contratto_dettaglio_fornitore:
                f.id_contratto_dettaglio_fornitore == undefined
                  ? -1
                  : f.id_contratto_dettaglio_fornitore,
              id_contratto: f.id_contratto == undefined ? -1 : f.id_contratto,
              id_anagrafica: f.id_anagrafica,
              validita: 1,
            };
            contratto_tmp.fornitori_dettaglio.push(fornitori_dettaglio);
          }
        });
      });
      if (
        this.fornitori_tmp != undefined &&
        contratto_tmp.contratto_dettaglio.length != 0
      ) {
        this.fornitori_tmp.validita = 0;
        if (
          this.fornitori_tmp.id_anagrafica !=
          contratto_tmp.fornitori_dettaglio[0].id_anagrafica
        ) {
          contratto_tmp.fornitori_dettaglio.push(this.fornitori_tmp);
        }
      }
      // this.fornitori_tmp.forEach((e) => {
      //   if (
      //     this.contratto_sel.fornitori.filter(
      //       (f) => f.id_anagrafica == e.id_anagrafica
      //     ).length == 0
      //   ) {
      //     let fornitori_dettaglio = {
      //       id_contratto_dettaglio_fornitore:
      //         e.id_contratto_dettaglio_fornitore == undefined
      //           ? -1
      //           : e.id_contratto_dettaglio_fornitore,
      //       id_contratto: e.id_contratto == undefined ? -1 : e.id_contratto,
      //       id_anagrafica: e.id_anagrafica,
      //       validita: 0,
      //     };
      //     contratto_tmp.fornitori_dettaglio.push(fornitori_dettaglio);
      //   }
      // });

      // Popolo il contratto_dettaglio con gli oggetti sede come li vuole il BE.

      this.contratto_sel.strutture.forEach((struttura) => {
        if (struttura.id_sede != null) {
          let struttura_modificata = {
            id_contratto: struttura.id_contratto,
            id_contratto_dettaglio: struttura.id_contratto_dettaglio,
            id_sede: struttura.id_sede,
            costo_stimato: struttura.costo_stimato,
            id_struttura: struttura.id_struttura,
            validita: struttura.validita,
          };
          console.log("entrato nope:", struttura);
          console.log("entrato yikes:", struttura_modificata);
          contratto_tmp.contratto_dettaglio.push(struttura_modificata);
          if (
            struttura.id_struttura != this.struttura_sel.id_struttura &&
            this.struttura_sel.id_sede != null
          ) {
            let struttura_nuova = {
              id_contratto: contratto_tmp.id_contratto,
              id_contratto_dettaglio: this.struttura_sel.id_contratto_dettaglio,
              id_sede: this.struttura_sel.id_sede,
              costo_stimato: this.struttura_sel.costo_stimato,
              id_struttura: this.struttura_sel.id_struttura,
              validita: this.struttura_sel.validita,
            };
            contratto_tmp.contratto_dettaglio.push(struttura_nuova);
          }
        }
      });
      if (this.contratto_sel.strutture.length == 0) {
        let obj_tmp = {
          id_contratto: this.struttura_sel.id_contratto,
          id_contatto_dettaglio: this.struttura_sel.id_contratto_dettaglio,
          id_sede: this.struttura_sel.id_sede,
          costo_stimato: this.struttura_sel.costo_stimato,
          id_struttura: this.struttura_sel.id_struttura,
          validtia: this.struttura_sel.validita,
        };
        console.log("OBJ TMP SEDE:", obj_tmp);
        if (
          this.struttura_sel.id_struttura != null &&
          this.struttura_sel.id_sede != null
        )
          contratto_tmp.contratto_dettaglio.push(obj_tmp);
      }
      console.log("entrato noice:", contratto_tmp.contratto_dettaglio);

      // Se la mia struttura selezionata non è già nel contratto, la aggiungo.
      // this.contratto_sel.strutture.forEach((elem) => {
      //   if (
      //     elem.id_contratto_dettaglio ===
      //     this.struttura_sel.id_contratto_dettaglio
      //   ) {
      //     id_contratto_dettaglio_ricorrente = true;
      //   }
      //   console.log("entrato contratto dettaglio");
      // });

      console.log("%c STRUTTURA SEL:", "color:pink", this.struttura_sel);
      // if (
      //   this.struttura_sel.id_struttura != null
      // ) {
      //   let struttura_modificata = {
      //     id_contratto: this.struttura_sel.id_contratto,
      //     id_contratto_dettaglio: this.struttura_sel.id_contratto_dettaglio,
      //     costo_stimato: this.struttura_sel.costo_stimato,
      //     id_sede: this.struttura_sel.id_sede,
      //     id_struttura: this.struttura_sel.id_struttura,
      //     validita: 1,
      //   };
      //   contratto_tmp.contratto_dettaglio.push(struttura_modificata);
      //   console.log("sono entrato 1:",struttura_modificata);
      // } else {
      //   console.log("non sono entrato");
      // }
      console.log("CONTRATTO TMP:", contratto_tmp);

      // Cancello il campo interventi che non serve più.
      delete contratto_tmp.interventi;
      delete contratto_tmp.strutture;
      delete contratto_tmp.fornitori;
      console.log(this.intervento_sel);
      console.log("CONTRATTO SEL:", this.contratto_sel);
      if (
        this.contratto_sel.data_inizio == "" ||
        this.contratto_sel.data_inizio == null ||
        this.contratto_sel.cod_contratto == "" ||
        this.contratto_sel.cod_contratto == null ||
        this.contratto_sel.data_fine == "" ||
        this.contratto_sel.data_fine == null ||
        this.contratto_sel.fornitori == {} ||
        this.contratto_sel.fornitori.length == 0 ||
        this.contratto_sel.fornitori == null ||
        this.contratto_sel.id_ambito_manutentivo == "" ||
        this.contratto_sel.id_ambito_manutentivo == null
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Compilare tutti i campi prima di salvare il contratto";
        this.snackbar_text_color = "white";
        return;
      }

      contratto_tmp.contratto_dettaglio.forEach((e) => {
        if (
          e.id_sede == this.struttura_sel.id_sede &&
          e.id_struttura == this.struttura_sel.id_struttura
        ) {
          e.costo_stimato = this.struttura_sel.costo_stimato;
        }
      });

      console.log("CONTRATTO TMP:", contratto_tmp);
      console.log("CONTRATTO SEL:", this.contratto_sel);
      if (
        contratto_tmp.fornitori_dettaglio.length == 2 &&
        resizeFornitori != undefined
      ) {
        contratto_tmp.fornitori_dettaglio.length = 1;
      }
      if (
        contratto_tmp.fornitori_dettaglio.length == 2 &&
        Array.isArray(contratto_tmp.fornitori_dettaglio[1]) == true
      ) {
        contratto_tmp.fornitori_dettaglio.pop();
      }
      //Compongo la request da mandare al BE.
      let fornitore_delete = {
        id_anagrafica: this.fornitori_tmp.id_anagrafica,
        id_contratto: this.fornitori_tmp.id_contratto,
        id_contratto_dettaglio_fornitore: this.fornitori_tmp
          .id_contratto_dettaglio_fornitore,
        valdita: 0,
      };
      if (
        contratto_tmp.fornitori_dettaglio.filter(
          (fornitore) =>
            fornitore.id_anagrafica == fornitore_delete.id_anagrafica
        ).length == 0 &&
        fornitore_delete.id_anagrafica
      ) {
        console.log(
          "ENTRATO GANG:",
          contratto_tmp.fornitori_dettaglio.push(fornitore_delete)
        );
      }
      contratto_tmp.id_anagrafica =
        contratto_tmp.fornitori_dettaglio[0].id_anagrafica;
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setcontratto",
          token: localStorage.user_token,
          contratto: [contratto_tmp],
        },
      };
      this.caricamento_dati = true;
      this.$store
        .dispatch("api", request)
        .then((res) => {
          // Indipendentemente dall stato della risposta, chiudo l'overlay di caricamento.
          this.caricamento_dati = false;
          if (res.status === 200) {
            // Chiudo il dialog di modifica del contratto.
            this.dialog_modifica_contratto = false;
            this.dialog_duplica_contratto = false;
            /* In base al contesto indicatomi dall'argomento di
             * funzione, eseguo le ulteriori operazioni richieste. */
            if (arg === "salva_contratto") {
              /* Se devo solo salvare il contratto, ho già eseguito
               * il salvataggio e non devo fare altro che ricaricare. */
              this.caricaContratti();
            } else if (arg === "salva_intervento") {
              console.log("entrato il salva intervento");
              /* Se devo salvare un intervento, faccio la chiamata debita
               * che alla fine ricaricherà i dati per conto proprio. */
              this.dialog_duplica_contratto = false;
              this.setManutenzioneRicorsiva();
            } else {
              /* Se l'argomento di funzione non corrisponde a nessuna delle
               * precedenti alternative, stampo un errore in console perché
               * così non va bene e lo devo sapere.*/
              console.log(
                "ERRORE: valore paramtero non corretto per la funzione salvaContratto."
              );
            }
          } else {
            // Se la chiamata al BE va storta, stampo una snackbar di errore.
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Errore tecnico, non è stato possibile salvare i dati.";
            this.snackbar_text_color = "white";
          }
        })
        .catch((e) => {
          console.log(e);
          this.snackbar = true;
          this.snackbar_background = "red";
          this.snackbar_icon = "mdi-close-outline";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Compilare tutti i campi prima di salvare l'intervento!";
          this.snackbar_text_color = "white";
          this.caricamento_dati = false;
        });
    },
    salvaIntervento() {
      // Aggiorno gli interventi presenti nella tabella.

      // Se l'intervento che sto salvando è nuovo...
      if (this.intervento_sel.id_intervento < 0) {
        this.intervento_sel.matrice = "";
        let copy = JSON.stringify(this.intervento_sel);
        this.intervento_sel.matrice = btoa(unescape(encodeURIComponent(copy)));
        /* Cerco il contratto che sto modificando (a cui appartiene
         *  l'intervento) e gli guardo dentro... */
        this.items_man_recursiva.forEach((contratto) => {
          if (contratto.id_contratto === this.intervento_sel.id_contratto) {
            // ... Ciclo sulle sue strutture...
            contratto.strutture.forEach((struttura) => {
              // ... Quando trovo la struttura che mi interessa, gli aggiungo l'intervento.
              if (struttura.id_struttura === this.intervento_sel.id_struttura) {
                struttura.interventi.push(this.intervento_sel);
              }
            });
          }
        });
      } else {
        // Ciclo sui contratti per trovare quello selezionato
        this.items_man_recursiva.forEach((contratto) => {
          if (contratto.id_contratto === this.contratto_sel.id_contratto) {
            // Quando trovo il contratto slezionato cerco la struttura selezionata.
            contratto.strutture.forEach((struttura) => {
              if (struttura.id_struttura === this.intervento_sel.id_struttura) {
                // Fra gli interventi di quella struttura cerco l'indice di quello che sto modificando.
                let i = struttura.interventi.findIndex(
                  (intervento) =>
                    intervento.id_intervento ===
                    this.intervento_sel.id_intervento
                );

                // Modifico l'intervento che devo modificare usando l'indice come chiave di accesso.
                struttura.interventi.splice(i, 1, this.struttura_sel);
              }
            });
          }
        });
      }

      // Chiudo il dialog di modifica della struttura.
      this.dialog_modifica_struttura = false;

      /* Eseguo il salvataggio sul BE chiamando il metodo
       * per salvare i contratti, che chiamerà la funzione
       * setManutenzioneRicorsiva, che poi aggiornerà i dati
       * della tabella e resetterà le selezioni. */
      console.log("INTERVENTO SEL", this.intervento_sel);
      this.salvaContratto("salva_intervento");
    },
    salvaStruttura() {
      let exit = false;
      // Se la struttura è nuova...
      if (this.struttura_sel.id_struttura < 0) {
        // Associo la descrizione sede.
        this.sede_lista.forEach((sede) => {
          if (sede.id_tabella === this.struttura_sel.id_sede) {
            this.struttura_sel.des_sede = sede.des;
          }
        });

        // Associo la descrizione della struttura.
        this.struttura_lista.forEach((struttura) => {
          if (struttura.id_tabella === this.struttura_sel.id_struttura) {
            this.struttura_sel.des_struttura = struttura.des;
          }
        });

        /* Cerco il contratto che sto modificando (a cui appartiene
         *  la struttura) e vado a inserirgliela dentro. */
        this.items_man_recursiva.forEach((contratto) => {
          if (contratto.id_contratto === this.contratto_sel.id_contratto) {
            contratto.strutture.push(this.struttura_sel);
          }
        });
      } else {
        this.contratto_sel.strutture.forEach((e) => {
          if (
            e.id_sede == this.struttura_sel.id_sede &&
            e.id_struttura == this.struttura_sel.id_struttura &&
            this.aggiungi_struttura == true
          ) {
            console.log("ENTRATO PER USCIRE AHAHA");
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Struttura già associata al contratto selezionato";
            this.snackbar_text_color = "white";
            exit = true;
            return;
          }
        });
        if (exit) return;
        // Ciclo sui contratti per trovare quello selezionato
        this.items_man_recursiva.forEach((contratto) => {
          if (contratto.id_contratto === this.contratto_sel.id_contratto) {
            // Fra le strutture di quel contratto cerco l'indice di quella che sto modificando
            let i = contratto.strutture.findIndex(
              (struttura) =>
                struttura.id_contratto === this.struttura_sel.id_contratto &&
                struttura.id_sede === this.struttura_sel.id_sede &&
                struttura.id_struttura === this.struttura_sel.id_struttura
            );
            // Modifico la struttura in esame usando l'indice come chiave di accesso
            contratto.strutture.splice(i, 1, this.struttura_sel);
          }
        });
      }

      /* Fin qui ho aggiornato la tabella contenente i dati totali:
       * ora copio l'aggiornamento sull'array dei dati visualizzati. */
      this.items_man_recursiva_sel = this.items_man_recursiva;

      // Chiudo il dialog di modifica della struttura.
      this.dialog_modifica_struttura = false;
      this.caricamento_dati = false;
      // Resetto la struttura selezionata.
      this.struttura_sel = new Structure();

      this.salvaContratto("salva_contratto");
    },
    setManutenzioneRicorsiva() {
      let id_urgenza = null;
      // Faccio scattare l'overlay di caricamento
      console.log("URGENZA LISTA:", this.urgenza_lista);
      this.urgenza_lista.forEach((e) => {
        if (e.cod.toString() == "LOW") {
          id_urgenza = e.id_tabella;
        }
      });

      if (
        this.intervento_sel.des_referente == "" ||
        this.intervento_sel.des_referente == null ||
        this.intervento_sel.costo_stimato == null ||
        this.intervento_sel.costo_stimato == "" ||
        this.intervento_sel.id_tipo_intervento == "" ||
        this.intervento_sel.id_tipo_intervento == null ||
        this.intervento_sel.id_tipo_manutenzione == null ||
        this.intervento_sel.id_tipo_manutenzione == "" ||
        this.intervento_sel.data_prevista == "" ||
        this.intervento_sel.data_prevista == null ||
        this.intervento_sel.fornitori.length == 0 ||
        this.intervento_sel.frequenza == null ||
        this.intervento_sel.frequenza == "" ||
        this.intervento_sel.um_frequenza == "" ||
        this.intervento_sel.um_frequenza == null
      ) {
        this.snackbar = true;
        this.snackbar_background = "red";
        this.snackbar_icon = "mdi-close-outline";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Compilare tutti i campi prima di salvare l'intervento!";
        this.snackbar_text_color = "white";
        return;
      }
      this.caricamento_dati_intervento = true;
      this.intervento_sel.id_urgenza = id_urgenza;
      // Assegno l'id_stato_operativo in base alla presenza o meno di attività.
      if (this.intervento_sel.attivita.length > 0) {
        this.intervento_sel.id_stato_operativo = this.id_stato_operativo_in_lavorazione;
        console.log("il " + this.id_stato_operativo_in_lavorazione);
      } else {
        this.intervento_sel.id_stato_operativo = this.id_stato_operativo_richiesto;
        console.log("r " + this.id_stato_operativo_richiesto);
      }

      /* Nell'intervento che devo salvare, cambio il nome del campo
       * id_anagrafica in id_fornitore per convenienza di BE. */
      this.intervento_sel.fornitori[0].id_fornitore = this.intervento_sel.fornitori[0].id_anagrafica;

      // Assegno all'intervento il debito id_contratto.
      this.intervento_sel.id_contratto = this.contratto_sel.id_contratto;

      // Cancello il campo id_anagrafica che tanto non serve più.
      delete this.intervento_sel.fornitori[0].id_anagrafica;

      /* Se il fornitore dell'intervento non ha ancora un id_fornitore_dettaglio
       * (perché è nuovo), aggiungo il campo e lo valorizzo a -1.*/
      if (
        this.intervento_sel.fornitori[0].id_fornitore_dettaglio === undefined
      ) {
        this.intervento_sel.fornitori[0].id_fornitore_dettaglio = -1;
      }

      // Aggiungo nel fornitore l'id dell'intervento di riferimento.
      this.intervento_sel.fornitori[0].id_intervento = -1;

      // Elimino consuntivi e fornitori se sono in modifica (al BE non servono).
      if (this.intervento_sel.id_intervento > 0) {
        delete this.intervento_sel.consuntivo;
        delete this.intervento_sel.fornitori;
      }

      // Salvo
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setmanutenzionericorsiva",
          id_struttura: this.intervento_sel.id_struttura,
          id_contratto: this.contratto_sel.id_contratto,
          token: localStorage.user_token,
          data_inizio_contratto: this.contratto_sel.data_inizio,
          data_fine_contratto: this.contratto_sel.data_fine,
          interventi: [this.intervento_sel],
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          // Indipendentemente dallo stato della risposta, chiudo l'overlay di caricamento.

          if (res.status === 200) {
            this.caricamento_dati_intervento= false;
            console.log("res.data = ", res.data);

            if (res.data.result != undefined) {
              let form_data = new FormData();
              console.log(JSON.stringify(res.data));
              res.data.result.allegati_attivita.forEach((all) => {
                this.intervento_sel.attivita.forEach((att) => {
                  att.lista_files.forEach((file) => {
                    if (all.path == file.name) {
                      //inserisco i file con nome_file = primarykey.estensione
                      form_data.append(
                        all.id_allegato.toString() +
                          "." +
                          all.estensione.toString(),
                        file
                      );
                    }
                  });
                });
              });

              console.log(JSON.stringify(form_data));
              console.log(form_data);
              new Promise((resolve, reject) => {
                axios
                  .post(
                    this.$store.state.config.ip + "api/Global/inserisci",
                    form_data
                  )
                  .then((result) => {
                    console.log(result);
                    resolve(result);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            }
            this.dialog_modifica_intervento = false;
            this.dialog_modifica_struttura = false;
            this.dialog_duplica_contratto = false;
            this.caricaContratti();

            // Chiudo le finestre attualmente aperte.
            // Ricarico i dati per aggiornare la tabella.
          } else {
            // Se la chiamata al BE va storta, stampo una snackbar di errore.
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Errore tecnico, non è stato possibile salvare i dati dell'intervento.";
            this.snackbar_text_color = "white";
          }
        })
        .catch((e) => {
          console.log(e);
          this.caricamento_dati = false;
        });
    },
  },
  created() {},
  mounted() {
    this.gettabelle();
    this.caricaFornitori();
    this.caricaContratti();
  },
};
</script>
<style scoped>
</style>