<template>
  <v-card class="elevation-0" id="listener_visualizzatore">
    <v-container>
      <!--x1 {{ click_down_x }} y1 {{ click_down_y }} x2 {{ click_up_x }} y2
          {{ click_up_y }}-->
      <v-row justify="center" align="center">
        <v-col
          cols="8"
          style="height: 337.5px; padding: 0px; text-align: center"
        >
          <svg
            id="svg"
            @mousedown="mouseDownCanvas($event)"
            @mousemove="mouseMoveCanvas($event)"
            @mouseup="mouseUpCanvas()"
            style="
              background-size: cover;
              display: inline-block;
              height: 337.5px;
              outline: 1px solid black;
              width: 450px;
            "
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs id="defs_svg"></defs>
          </svg>

          <!-- SVG che disegna la punta della freccia -->
          <figure>
            <svg>
              <marker
                id="arrowend"
                viewBox="0 0 24 24"
                refX="12"
                refY="12"
                markerWidth="6"
                markerHeight="6"
                orient="auto-start-reverse"
              >
                <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
              </marker>
            </svg>
          </figure>
        </v-col>
        <v-col cols="2" v-if="show_editor_tool_columns">
          <v-row
            v-if="show_editor_save_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="setImmagine()" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-floppy</v-icon>
                </v-btn>
              </template>
              <span>Salva</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_upload_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="caricaDaLocale()" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-upload</v-icon>
                </v-btn>
              </template>
              <span>Carica Immagine</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_camera_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="fotocamera_dialog = !fotocamera_dialog"
                  v-bind="attrs"
                  v-on="on"
                  medium
                >
                  <v-icon color="primary"> mdi-camera </v-icon>
                </v-btn>
              </template>
              <span>Cattura Immagine</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_small_size_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="width_draw = 2"
                  :color="width_draw === 2 ? 'primary' : ''"
                  :depressed="width_draw === 2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon :color="width_draw === 2 ? 'white' : 'primary'"
                    >mdi-size-s</v-icon
                  >
                </v-btn>
              </template>
              <span>Tratto Sottile</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_medium_size_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="width_draw = 5"
                  :color="width_draw === 5 ? 'primary' : ''"
                  :depressed="width_draw === 5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon :color="width_draw === 5 ? 'white' : 'primary'"
                    >mdi-size-m</v-icon
                  >
                </v-btn>
              </template>
              <span>Tratto Medio</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_large_size_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="width_draw = 10"
                  :color="width_draw === 10 ? 'primary' : ''"
                  :depressed="width_draw === 10"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon :color="width_draw === 10 ? 'white' : 'primary'"
                    >mdi-size-l</v-icon
                  >
                </v-btn>
              </template>
              <span>Tratto Largo</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_eraser_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clickPulisciEditor" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-eraser</v-icon>
                </v-btn>
              </template>
              <span>Pulisci Editor</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_color_picker_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <div
                  :style="{
                    backgroundColor: colore,
                    border: '1px solid white',
                    display: 'inline-block',
                    height: '35px',
                    width: '35px',
                    borderRadius: '5px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }"
                  v-bind="attrs"
                  v-on="on"
                ></div>
              </template>
              <v-color-picker v-model="colore" hide-inputs></v-color-picker>
            </v-menu>
          </v-row>
        </v-col>
        <v-col cols="2" v-if="show_editor_tool_columns">
          <v-row
            v-if="show_editor_crop_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickRitaglia"
                  :color="currentActiveMethod === 'ritaglia' ? 'primary' : ''"
                  :depressed="currentActiveMethod === 'ritaglia'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="
                      currentActiveMethod === 'ritaglia' ? 'white' : 'primary'
                    "
                    >mdi-crop</v-icon
                  >
                </v-btn>
              </template>
              <span>Ritaglia</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_arrow_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiFreccia"
                  :color="
                    currentActiveMethod === 'aggiungi_freccia' ? 'primary' : ''
                  "
                  :depressed="currentActiveMethod === 'aggiungi_freccia'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="
                      currentActiveMethod === 'aggiungi_freccia'
                        ? 'white'
                        : 'primary'
                    "
                    >mdi-arrow-down</v-icon
                  >
                </v-btn>
              </template>
              <span>Inserisci Freccia</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_square_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiQuadrato"
                  :color="
                    currentActiveMethod === 'aggiungi_quadrato' ? 'primary' : ''
                  "
                  :depressed="currentActiveMethod === 'aggiungi_quadrato'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="
                      currentActiveMethod === 'aggiungi_quadrato'
                        ? 'white'
                        : 'primary'
                    "
                    >mdi-square-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Inserisci Quadrato</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_circle_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiCerchio"
                  :color="
                    currentActiveMethod === 'aggiungi_cerchio' ? 'primary' : ''
                  "
                  :depressed="currentActiveMethod === 'aggiungi_cerchio'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="
                      currentActiveMethod === 'aggiungi_cerchio'
                        ? 'white'
                        : 'primary'
                    "
                    >mdi-circle-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Inserisci Cerchio</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_letter_case_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiTesto"
                  :color="
                    currentActiveMethod === 'aggiungi_testo' ? 'primary' : ''
                  "
                  :depressed="currentActiveMethod === 'aggiungi_testo'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="
                      currentActiveMethod === 'aggiungi_testo'
                        ? 'white'
                        : 'primary'
                    "
                    >mdi-format-letter-case</v-icon
                  >
                </v-btn>
              </template>
              <span>Inserisci Testo</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_free_draw_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiDisegnoLibero"
                  :color="
                    currentActiveMethod === 'aggiungi_disegno_libero'
                      ? 'primary'
                      : ''
                  "
                  :depressed="currentActiveMethod === 'aggiungi_disegno_libero'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="
                      currentActiveMethod === 'aggiungi_disegno_libero'
                        ? 'white'
                        : 'primary'
                    "
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>Disegno Libero</span>
            </v-tooltip>
          </v-row>

          <v-row
            v-if="show_editor_edit_back_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clickUndo" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-undo</v-icon>
                </v-btn>
              </template>
              <span>Annulla Modifica</span>
            </v-tooltip>
          </v-row>
          <v-row
            v-if="show_editor_edit_forward_button === true"
            align="center"
            justify="center"
            style="margin: 10px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clickRedo" v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-redo</v-icon>
                </v-btn>
              </template>
              <span>Ripristina Modifica</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- Input per il testo da agiungere al canvas -->
    <v-card
      class="elevation-3 px-5"
      id="container_input_canvas"
      :style="{
        borderRadius: 12 + 'px',
        backgroundColor: 'white',
        left: click_down_x + 'px',
        position: 'absolute',
        top: click_down_y + 'px',
        zIndex: 8,
      }"
      v-show="show_input_testo_canvas === true"
    >
      <div>
        <v-text-field
          id="input_testo_canvas"
          v-model="testo_canvas"
          style="z-index: 0"
        ></v-text-field>
      </div>
      <div>
        <v-row align="center">
          <v-col cols="9">
            Dimensione:
            <v-text-field
              class="mx-1"
              type="number"
              v-model="testo_canvas_dimensione"
              style="display: inline-block; width: 40px"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mx-2"
                  :style="{
                    borderRadius: 5 + 'px',
                    backgroundColor: testo_canvas_colore,
                    border: '1px solid white',
                    display: 'inline-block',
                    height: '28px',
                    width: '28px',
                  }"
                  v-bind="attrs"
                  v-on="on"
                ></div>
              </template>
              <v-color-picker
                v-model="testo_canvas_colore"
                hide-inputs
              ></v-color-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="upload_file_visible" width="50%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Carica Immagine</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiUploadFile" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="text-align: center" class="mt-5">
          <v-file-input
            accept="image/*"
            @change="uploadImage(img_upload_editor)"
            type="file"
            v-model="img_upload_editor"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fotocamera_dialog">
      <Camera
        @click_chiudi="fotocamera_dialog = false"
        @getimage="riceviImmagineDaCamera($event)"
        @scattata_foto="fotocamera_dialog = false"
        :visible="fotocamera_dialog"
      />
    </v-dialog>

    <v-dialog v-model="scegli_salvataggio_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Modalità di Salvataggio Immagine</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 20px">
            Vuoi sostituire la versione precedente (S) o salvare una nuova
            immagine (N)?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="setImmagineSostituzione"
                small
                v-bind="attrs"
                v-on="on"
              >
                S
              </v-btn>
            </template>
            <span>Sostituisci</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setImmagineNuova" small v-bind="attrs" v-on="on">
                N
              </v-btn>
            </template>
            <span>Nuovo Salvataggio</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Camera from "./Camera";
import moment from "moment/moment";
export default {
  name: "Visualizzatore",
  props: {
    destinazione: { type: String, default: "" },
    immagine_standard_editor: { type: String, default: "" },
    riferimento: { type: String, default: "" },
    show_editor_arrow_button: { type: Boolean, default: true },
    show_editor_camera_button: { type: Boolean, default: true },
    show_editor_circle_button: { type: Boolean, default: true },
    show_editor_color_picker_button: { type: Boolean, default: true },
    show_editor_crop_button: { type: Boolean, default: true },
    show_editor_edit_back_button: { type: Boolean, default: true },
    show_editor_edit_forward_button: { type: Boolean, default: true },
    show_editor_eraser_button: { type: Boolean, default: true },
    show_editor_free_draw_button: { type: Boolean, default: true },
    show_editor_large_size_button: { type: Boolean, default: true },
    show_editor_letter_case_button: { type: Boolean, default: true },
    show_editor_medium_size_button: { type: Boolean, default: true },
    show_editor_save_button: { type: Boolean, default: true },
    show_editor_small_size_button: { type: Boolean, default: true },
    show_editor_square_button: { type: Boolean, default: true },
    show_editor_tool_columns: { type: Boolean, default: true },
    show_editor_upload_button: { type: Boolean, default: true },
    visible: {type: Boolean, default: true },
  },
  components: { Camera },
  data() {
    return {
      // Variabili di supporto
      click_down_x: 0,
      click_down_y: 0,
      click_up_x: 0,
      click_up_y: 0,
      canvas: null,
      context: null,
      colore: "#000000",
      currentActiveMethod: null,
      id_img: null,
      mouseDrag: false,
      testo_canvas: "",
      testo_canvas_colore: "#000000",
      testo_canvas_dimensione: 18,
      img_upload_editor: null,
      sfondo_canvas: "",
      width_draw: 2,

      // Modal
      fotocamera_dialog: false,
      scegli_salvataggio_dialog: false,
      show_input_testo_canvas: false,
      upload_file_visible: false,

      // Array
      items_eliminati: [],
    };
  },
  methods: {
    aggiungiTesto() {
      document.getElementById("input_testo_canvas").blur();
      this.show_input_testo_canvas = false;

      // Valorizzo il testo del nodo testuale.
      let textNode = document.createTextNode(this.testo_canvas);

      // Creo un testo svg e valorizzo i suoi attributi.
      let text = document.createElementNS("http://www.w3.org/2000/svg", "text");

      text.setAttribute("font-size", this.testo_canvas_dimensione);
      text.setAttribute("stroke", this.testo_canvas_colore);
      text.setAttribute("fill", this.testo_canvas_colore);
      text.setAttribute(
        "stroke-width",
        this.width_draw === 2 ? 0.5 : this.width_draw === 5 ? 1 : 2
      );
      text.setAttribute("x", this.click_down_x);
      text.setAttribute("y", this.click_down_y);

      // Appendo il nodo testuale al testo svg e il testo svg all'elemento svg.
      text.appendChild(textNode);
      this.svg.appendChild(text);

      // Resetto il metodo attivo.
      this.currentActiveMethod = "";
    },
    caricaDaLocale() {
      this.upload_file_visible = true;
      this.img_upload_editor = null;
    },
    chiudiUploadFile() {
      this.upload_file_visible = false;
    },
    clickAggiungiCerchio() {
      if (this.currentActiveMethod != "aggiungi_cerchio") {
        this.currentActiveMethod = "aggiungi_cerchio";
      } else {
        this.currentActiveMethod = "";
      }
    },
    clickAggiungiDisegnoLibero() {
      if (this.currentActiveMethod != "aggiungi_disegno_libero") {
        this.currentActiveMethod = "aggiungi_disegno_libero";
      } else {
        this.currentActiveMethod = "";
      }
    },
    clickAggiungiFreccia() {
      if (this.currentActiveMethod != "aggiungi_freccia") {
        this.currentActiveMethod = "aggiungi_freccia";
      } else {
        this.currentActiveMethod = "";
      }
    },
    clickAggiungiQuadrato() {
      if (this.currentActiveMethod != "aggiungi_quadrato") {
        this.currentActiveMethod = "aggiungi_quadrato";
      } else {
        this.currentActiveMethod = "";
      }
    },
    clickAggiungiTesto() {
      if (this.currentActiveMethod != "aggiungi_testo") {
        this.currentActiveMethod = "aggiungi_testo";
      } else {
        this.currentActiveMethod = "";
        this.show_input_testo_canvas = false;
      }
    },
    clickPulisciEditor() {
      // Resetto la modalità dell'editor.
      this.currentActiveMethod = "";

      // Cancello tutto ciò che c'è nell'SVG.
      while (this.svg.lastChild) {
        this.svg.removeChild(this.svg.lastChild);
      }

      // Ripristino le defs.
      let defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
      defs.setAttribute("id", "defs_svg");
      this.svg.appendChild(defs);

      // Resetto dimensioni e sfondo dell'svg.
      this.svg.style.width = "450px";
      this.svg.style.height = "337.5px";
      this.svg.style.backgroundImage = "";
      this.svg.cursor_offset.x = 0;
      this.svg.cursor_offset.y = 0;

      this.svg.setAttribute("viewBox", `0 0 450 337.5`);
    },
    clickRedo() {
      this.currentActiveMethod = "";
      if (this.items_eliminati.length > 0) {
        const last_item = this.items_eliminati[this.items_eliminati.length - 1];
        if (last_item.cropping_data == undefined) {
          this.svg.appendChild(this.items_eliminati.pop());
        } else {
          this.items_eliminati.pop();
          const rect = this.svg.getBoundingClientRect();
          const new_offset = Object.assign({}, this.svg.cursor_offset);
          this.svg.setAttribute(
            "viewBox",
            `${last_item.cropping_data.offset_x} ${last_item.cropping_data.offset_y} ${last_item.cropping_data.width} ${last_item.cropping_data.height}`
          );

          this.svg.style.width = last_item.cropping_data.width + "px";
          this.svg.style.height = last_item.cropping_data.height + "px";

          this.svg.cursor_offset.x = last_item.cropping_data.offset_x;
          this.svg.cursor_offset.y = last_item.cropping_data.offset_y;

          last_item.cropping_data.offset_x = new_offset.x;
          last_item.cropping_data.offset_y = new_offset.y;

          last_item.cropping_data.width = rect.width;
          last_item.cropping_data.height = rect.height;
          this.svg.appendChild(last_item);
        }
      }
    },
    clickRitaglia() {
      if (this.currentActiveMethod != "ritaglia") {
        this.currentActiveMethod = "ritaglia";
      } else {
        this.currentActiveMethod = "";
      }
    },
    clickUndo() {
      this.currentActiveMethod = "";
      if (this.svg.childNodes.length > 0) {
        // Se non ho a che fare con un cropping (quindi è un elemento grafico puro)...
        const last_item = this.svg.childNodes[this.svg.childNodes.length - 1];
        if (last_item.cropping_data == undefined) {
          // Metto l'oggetto dentro il collettore di eliminati
          this.items_eliminati.push(last_item);
        } else {
          // Se invece è un cropping...

          // Mi faccio un oggetto da aggiungere agli eliminati
          const rect = this.svg.getBoundingClientRect();
          const new_offset = Object.assign({}, this.svg.cursor_offset);

          this.svg.setAttribute(
            "viewBox",
            `${last_item.cropping_data.offset_x} ${last_item.cropping_data.offset_y} ${last_item.cropping_data.width} ${last_item.cropping_data.height}`
          );

          this.svg.style.width = last_item.cropping_data.width + "px";
          this.svg.style.height = last_item.cropping_data.height + "px";

          this.svg.cursor_offset.x = last_item.cropping_data.offset_x;
          this.svg.cursor_offset.y = last_item.cropping_data.offset_y;

          last_item.cropping_data.offset_x = new_offset.x;
          last_item.cropping_data.offset_y = new_offset.y;

          last_item.cropping_data.width = rect.width;
          last_item.cropping_data.height = rect.height;
          this.items_eliminati.push(last_item);
        }

        this.svg.childNodes[this.svg.childNodes.length - 1].remove();
      }
    },
    mouseDownCanvas(e) {
      if (this.currentActiveMethod === "") {
        return;
      } else if (this.currentActiveMethod === "aggiungi_disegno_libero") {
        this.mouseDrag = true;
        this.click_down_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_down_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        let path = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );

        path.setAttribute("d", `M${this.click_down_x} ${this.click_down_y}`);
        path.setAttribute("fill", "none");
        path.setAttribute("stroke", this.colore);
        path.setAttribute("stroke-width", this.width_draw);

        this.drawingLine = path;
        this.svg.appendChild(this.drawingLine);
      } else if (this.currentActiveMethod === "aggiungi_testo") {
        this.click_down_x = Math.round(
          e.clientX -
            this.svg.getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_down_y = Math.round(
          e.clientY -
            this.svg.getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        this.show_input_testo_canvas = true;
        document.getElementById("container_input_canvas").style.top =
          this.click_down_x + "px";
        document.getElementById("container_input_canvas").style.left =
          this.click_down_y + "px";

        setTimeout(() => {
          document.getElementById("input_testo_canvas").focus();
        }, 500);
      } else if (this.currentActiveMethod === "aggiungi_freccia") {
        this.mouseDrag = true;

        this.click_down_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_down_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        let line = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "line"
        );

        let id_tmp = "marker_" + this.colore;
        let arrowend = document.getElementById(id_tmp);

        if (arrowend === null) {
          arrowend = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "marker"
          );

          arrowend.setAttribute("viewBox", "0 0 24 24");
          arrowend.setAttribute("refX", "12");
          arrowend.setAttribute("refY", "12");
          arrowend.setAttribute("markerWidth", "6");
          arrowend.setAttribute("markerHeight", "6");
          arrowend.setAttribute("orient", "auto-start-reverse");
          arrowend.setAttribute("stroke", this.colore);
          arrowend.setAttribute("id", id_tmp);
          let arrowpath = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
          );
          arrowpath.setAttribute("d", "M2,21L23,12L2,3V10L17,12L2,14V21Z");
          arrowpath.setAttribute("fill", this.colore);
          arrowend.appendChild(arrowpath);
          document.getElementById("defs_svg").appendChild(arrowend);
        }

        line.setAttribute("x1", Math.round(this.click_down_x));
        line.setAttribute("y1", Math.round(this.click_down_y));
        line.setAttribute("x2", Math.round(this.click_down_x));
        line.setAttribute("y2", Math.round(this.click_down_y));
        line.setAttribute("marker-end", "url(#" + id_tmp + ")");
        line.setAttribute("stroke", this.colore);
        line.setAttribute("stroke-width", this.width_draw);

        this.drawingLine = line;
        this.svg.appendChild(line);
      } else if (this.currentActiveMethod === "aggiungi_quadrato") {
        this.mouseDrag = true;

        this.click_down_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );

        this.click_down_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        let quadrato = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        quadrato.setAttribute("stroke", this.colore);
        quadrato.setAttribute("stroke-width", this.width_draw);
        quadrato.setAttribute("fill", "rgba(0,0,0,0)");
        quadrato.setAttribute("height", "0");
        quadrato.setAttribute("width", "0");
        quadrato.setAttribute("x", this.click_down_x);
        quadrato.setAttribute("y", this.click_down_y);

        this.drawingLine = quadrato;
        this.svg.appendChild(quadrato);
      } else if (this.currentActiveMethod === "aggiungi_cerchio") {
        this.mouseDrag = true;

        this.click_down_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );

        this.click_down_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        let cerchio = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "ellipse"
        );
        cerchio.setAttribute("stroke", this.colore);
        cerchio.setAttribute("stroke-width", this.width_draw);
        cerchio.setAttribute("fill", "rgba(0,0,0,0)");

        cerchio.setAttribute("cx", this.click_down_x);
        cerchio.setAttribute("cy", this.click_down_y);

        this.drawingLine = cerchio;
        this.svg.appendChild(cerchio);
      } else if (this.currentActiveMethod === "ritaglia") {
        this.mouseDrag = true;

        this.click_down_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );

        this.click_down_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        let quadrato = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        quadrato.setAttribute("stroke", "rgba(0,0,0,1)");
        quadrato.setAttribute("stroke-width", "1");
        quadrato.setAttribute("fill", "rgba(255,255,255,0.5)");
        quadrato.setAttribute("height", "0");
        quadrato.setAttribute("width", "0");
        quadrato.setAttribute("x", this.click_down_x);
        quadrato.setAttribute("y", this.click_down_y);

        this.drawingLine = quadrato;
        this.svg.appendChild(quadrato);
      }
    },
    mouseMoveCanvas(e) {
      if (this.currentActiveMethod === "") {
        return;
      } else if (
        this.currentActiveMethod === "aggiungi_disegno_libero" &&
        this.mouseDrag === true
      ) {
        this.click_up_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_up_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        this.drawingLine.setAttribute(
          "d",
          `${this.drawingLine.getAttribute("d")} L${this.click_up_x} ${
            this.click_up_y
          }`
        );

        this.click_down_x = this.click_up_x;
        this.click_down_y = this.click_up_y;
      } else if (
        this.currentActiveMethod === "aggiungi_freccia" &&
        this.mouseDrag === true
      ) {
        this.click_up_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_up_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        // Disegno frecce.
        this.drawingLine.setAttribute("x2", Math.round(this.click_up_x));
        this.drawingLine.setAttribute("y2", Math.round(this.click_up_y));
      } else if (
        this.currentActiveMethod === "aggiungi_quadrato" &&
        this.mouseDrag === true
      ) {
        this.click_up_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_up_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        // Disegno il quadrato.
        this.drawingLine.setAttribute(
          "width",
          this.click_down_x >= this.click_up_x
            ? Math.round(this.click_down_x - this.click_up_x)
            : Math.round(this.click_up_x - this.click_down_x)
        );
        this.drawingLine.setAttribute(
          "height",
          this.click_down_y >= this.click_up_y
            ? Math.round(this.click_down_y - this.click_up_y)
            : Math.round(this.click_up_y - this.click_down_y)
        );

        if (
          this.click_down_x > this.click_up_x &&
          this.click_down_y < this.click_up_y
        ) {
          this.drawingLine.setAttribute(
            "transform",
            `translate(-${Math.round(this.click_down_x - this.click_up_x)} 0)`
          );
        }

        if (
          this.click_down_x < this.click_up_x &&
          this.click_down_y > this.click_up_y
        ) {
          this.drawingLine.setAttribute(
            "transform",
            `translate(0 -${Math.round(this.click_down_y - this.click_up_y)})`
          );
        }

        if (
          this.click_down_x > this.click_up_x &&
          this.click_down_y > this.click_up_y
        ) {
          this.drawingLine.setAttribute(
            "transform",
            `translate(-${Math.round(
              this.click_down_x - this.click_up_x
            )} -${Math.round(this.click_down_y - this.click_up_y)})`
          );
        }
      } else if (
        this.currentActiveMethod === "aggiungi_cerchio" &&
        this.mouseDrag === true
      ) {
        this.click_up_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_up_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.x
        );

        // Disegno il cerchio.
        this.drawingLine.setAttribute(
          "rx",
          this.click_down_x >= this.click_up_x
            ? Math.round(this.click_down_x - this.click_up_x)
            : Math.round(this.click_up_x - this.click_down_x)
        );
        this.drawingLine.setAttribute(
          "ry",
          this.click_down_y >= this.click_up_y
            ? Math.round(this.click_down_y - this.click_up_y)
            : Math.round(this.click_up_y - this.click_down_y)
        );
      } else if (
        this.currentActiveMethod === "ritaglia" &&
        this.mouseDrag === true
      ) {
        this.click_up_x = Math.round(
          e.clientX -
            document.getElementById("svg").getBoundingClientRect().x +
            this.svg.cursor_offset.x
        );
        this.click_up_y = Math.round(
          e.clientY -
            document.getElementById("svg").getBoundingClientRect().y +
            this.svg.cursor_offset.y
        );

        // Disegno il quadrato.
        this.drawingLine.setAttribute(
          "width",
          this.click_down_x >= this.click_up_x
            ? Math.round(this.click_down_x - this.click_up_x)
            : Math.round(this.click_up_x - this.click_down_x)
        );
        this.drawingLine.setAttribute(
          "height",
          this.click_down_y >= this.click_up_y
            ? Math.round(this.click_down_y - this.click_up_y)
            : Math.round(this.click_up_y - this.click_down_y)
        );

        if (
          this.click_down_x > this.click_up_x &&
          this.click_down_y < this.click_up_y
        ) {
          this.drawingLine.setAttribute(
            "transform",
            `translate(-${Math.round(this.click_down_x - this.click_up_x)} 0)`
          );
        }

        if (
          this.click_down_x < this.click_up_x &&
          this.click_down_y > this.click_up_y
        ) {
          this.drawingLine.setAttribute(
            "transform",
            `translate(0 -${Math.round(this.click_down_y - this.click_up_y)})`
          );
        }

        if (
          this.click_down_x > this.click_up_x &&
          this.click_down_y > this.click_up_y
        ) {
          this.drawingLine.setAttribute(
            "transform",
            `translate(-${Math.round(
              this.click_down_x - this.click_up_x
            )} -${Math.round(this.click_down_y - this.click_up_y)})`
          );
        }
      }
    },
    mouseUpCanvas() {
      if (this.currentActiveMethod === "") {
        return;
      } else if (this.currentActiveMethod === "aggiungi_disegno_libero") {
        this.mouseDrag = false;
        delete this.drawingLine;
      } else if (this.currentActiveMethod === "aggiungi_freccia") {
        this.mouseDrag = false;
        delete this.drawingLine;
      } else if (this.currentActiveMethod === "aggiungi_quadrato") {
        this.mouseDrag = false;
        delete this.drawingLine;
      } else if (this.currentActiveMethod === "aggiungi_cerchio") {
        this.mouseDrag = false;
        delete this.drawingLine;
      } else if (this.currentActiveMethod === "ritaglia") {
        let width_nuova =
          this.click_up_x > this.click_down_x
            ? this.click_up_x - this.click_down_x
            : this.click_down_x - this.click_up_x;
        let height_nuova =
          this.click_up_y > this.click_down_y
            ? this.click_up_y - this.click_down_y
            : this.click_down_y - this.click_up_y;

        this.mouseDrag = false;

        this.svg.style.backgroundSize = `${this.svg.style.width} ${this.svg.style.height}`;
        this.svg.style.backgroundPosition = `-${
          this.click_down_x < this.click_up_x
            ? this.click_down_x
            : this.click_up_x
        }px -${
          this.click_down_y < this.click_up_y
            ? this.click_down_y
            : this.click_up_y
        }px`;
        this.svg.removeChild(this.drawingLine);
        delete this.drawingLine;

        /* Mi faccio un oggetto segnaposto in cui
         * registro i parametri attuali del canvas svg
         * in modo che se faccio un undo lo vedo e mi
         * regolo di conseguenza. */
        const crop_parameters = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "ellipse"
        );

        /* Aggiungo un cerchio farlocco invisibile in cui
         * indico i dati che sto modificando per il cropping. */
        crop_parameters.setAttribute("stroke", "rgba(0,0,0,0)");
        crop_parameters.setAttribute("stroke-width", "2");
        crop_parameters.setAttribute("fill", "rgba(0,0,0,0)");
        // crop_parameters.setAttribute("id", "cropping");

        /* cx = width attuale, cy = height attuale,
         * rx = decentramento orizzontale, ry = decentramento verticale. */
        crop_parameters.setAttribute("cx", `${this.svg.style.width}`);
        crop_parameters.setAttribute("cy", `${this.svg.style.height}`);
        crop_parameters.setAttribute("rx", "5");
        crop_parameters.setAttribute("ry", "5");
        const rect = this.svg.getBoundingClientRect();
        crop_parameters.cropping_data = {
          offset_x: this.svg.cursor_offset.x,
          offset_y: this.svg.cursor_offset.y,
          width: rect.width,
          height: rect.height,
        };

        const new_offset_x = Math.min(this.click_down_x, this.click_up_x);
        const new_offset_y = Math.min(this.click_down_y, this.click_up_y);

        this.svg.cursor_offset.x = new_offset_x;
        this.svg.cursor_offset.y = new_offset_y;

        this.svg.setAttribute(
          "viewBox",
          `${new_offset_x} ${new_offset_y} ${width_nuova} ${height_nuova}`
        );
        this.svg.style.width = width_nuova;
        this.svg.style.height = height_nuova;

        this.svg.appendChild(crop_parameters);
      }
    },
    ricaricaImmagine(messaggio) {
      this.clickPulisciEditor();

      let that = this;

      this.id_img = messaggio.detail.id_img;
      let immagine_da_ridimenionare = new Image();

      immagine_da_ridimenionare.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il canvas in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 450 x 337.5 px; se lo sono scalo le dimesioni.*/
        if (
          immagine_da_ridimenionare.width ===
            immagine_da_ridimenionare.height &&
          immagine_da_ridimenionare.width > 450
        ) {
          let width_nuova = 337.5;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova =
            (width_nuova * immagine_da_ridimenionare.height) /
            immagine_da_ridimenionare.width;
          that.svg.style.width = width_nuova;
          that.svg.style.height = height_nuova;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${width_nuova} ${height_nuova}`
          );
        } else if (
          immagine_da_ridimenionare.width > immagine_da_ridimenionare.height &&
          immagine_da_ridimenionare.width > 450
        ) {
          let width_nuova = 450;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova =
            (width_nuova * immagine_da_ridimenionare.height) /
            immagine_da_ridimenionare.width;
          that.svg.style.width = width_nuova;
          that.svg.style.height = height_nuova;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${width_nuova} ${height_nuova}`
          );
        } else if (
          immagine_da_ridimenionare.height > immagine_da_ridimenionare.width &&
          immagine_da_ridimenionare.height > 337.5
        ) {
          let height_nuova = 337.5;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova =
            (height_nuova * immagine_da_ridimenionare.width) /
            immagine_da_ridimenionare.height;

          that.svg.style.width = width_nuova;
          that.svg.style.height = height_nuova;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${width_nuova} ${height_nuova}`
          );
        } else {
          that.svg.style.width = immagine_da_ridimenionare.width;
          that.svg.style.height = immagine_da_ridimenionare.height;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${parseFloat(that.svg.style.width)} ${parseFloat(
              that.svg.style.height
            )}`
          );
        }

        // Associo l'immagine Blob allo sfondo.
        that.svg.style.backgroundImage =
          'url("' + immagine_da_ridimenionare.src + '")';
      };
      that.$nextTick(
        () => (immagine_da_ridimenionare.src = messaggio.detail.img)
      );
    },
    ricaricaImmagineStandardEditor(arg) {
      const arg2 = arg.split("#svgView");
      const matches = arg2[1].match(/[0-9 . ]+/g);

      this.clickPulisciEditor();

      let that = this;

      let immagine_da_ridimenionare = new Image();

      immagine_da_ridimenionare.onload = function () {
        immagine_da_ridimenionare.width = matches[2];
        immagine_da_ridimenionare.height = matches[3];

        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il canvas in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 450 x 337.5 px; se lo sono scalo le dimesioni.*/
        if (
          immagine_da_ridimenionare.width ===
            immagine_da_ridimenionare.height &&
          immagine_da_ridimenionare.width > 450
        ) {
          let width_nuova = 337.5;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova =
            (width_nuova * immagine_da_ridimenionare.height) /
            immagine_da_ridimenionare.width;
          that.svg.style.width = width_nuova;
          that.svg.style.height = height_nuova;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${width_nuova} ${height_nuova}`
          );
        } else if (
          immagine_da_ridimenionare.width > immagine_da_ridimenionare.height &&
          immagine_da_ridimenionare.width > 450
        ) {
          let width_nuova = 450;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova =
            (width_nuova * immagine_da_ridimenionare.height) /
            immagine_da_ridimenionare.width;
          that.svg.style.width = width_nuova;
          that.svg.style.height = height_nuova;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${width_nuova} ${height_nuova}`
          );
        } else if (
          immagine_da_ridimenionare.height > immagine_da_ridimenionare.width &&
          immagine_da_ridimenionare.height > 337.5
        ) {
          let height_nuova = 337.5;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova =
            (height_nuova * immagine_da_ridimenionare.width) /
            immagine_da_ridimenionare.height;

          that.svg.style.width = width_nuova;
          that.svg.style.height = height_nuova;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${width_nuova} ${height_nuova}`
          );
        } else {
          that.svg.style.width = immagine_da_ridimenionare.width;
          that.svg.style.height = immagine_da_ridimenionare.height;
          that.svg.setAttribute(
            "viewBox",
            `0 0 ${parseFloat(that.svg.style.width)} ${parseFloat(
              that.svg.style.height
            )}`
          );
        }

        // Associo l'immagine Blob allo sfondo.
        that.svg.style.backgroundImage =
          'url("' + immagine_da_ridimenionare.src + '")';
      };
      that.$nextTick(() => (immagine_da_ridimenionare.src = arg));
    },
    riceviImmagineDaCamera(arg) {
      this.svg.style.width = 450 + "px";
      this.svg.style.height = 337.5 + "px";

      let svgImg = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );

      svgImg.setAttribute("x", 0);
      svgImg.setAttribute("y", 0);
      svgImg.setAttribute("width", this.svg.style.width);
      svgImg.setAttribute("height", this.svg.style.height);
      svgImg.setAttribute("href", arg);

      this.svg.appendChild(svgImg);
    },
    setImmagine() {
      if (document.getElementById("listener_tabella_disegni")) {
        /* Se il campo id_immagine è null (quindi non sto sicuramente modificando
         * un'immagine esistente) salvo una nuova immagine. */
        if (this.id_img === null) {
          this.setImmagineNuova();
        } else {
          // Altrimenti chiedo all'utente se vuole salvare una nuova immagine o sostituirla
          this.scegli_salvataggio_dialog = true;
        }
      } else {
        this.svg.setAttribute(
          "viewBox",
          `0 0 ${this.svg.getBoundingClientRect().width}, ${
            this.svg.getBoundingClientRect().height
          }`
        );
        let dataUrl =
          "data:image/svg+xml; charset=utf8," +
          encodeURIComponent(this.svg.outerHTML) +
          `#svgView(viewBox(0, 0, ${this.svg.style.width}, ${this.svg.style.height})`;

        let messaggio = {
          detail: {
            id_img: moment().valueOf(),
            img: dataUrl,
            id: this.riferimento,
          },
          src: null,
        };

        // Inserisco il messaggio in un evento custom.
        let evento = new CustomEvent("nuova_img", messaggio);

        // Recapito l'evento alla destinazione fuori del componente.
        document.getElementById(this.destinazione).dispatchEvent(evento);
        this.$emit("salvata_immagine");
      }
    },
    setImmagineNuova() {
      this.svg.setAttribute(
        "viewBox",
        `0 0 ${this.svg.getBoundingClientRect().width}, ${
          this.svg.getBoundingClientRect().height
        }`
      );
      let that = this;
      let dataUrl =
        "data:image/svg+xml;charset=utf8,base64," +
        encodeURIComponent(this.svg.outerHTML);

      let canvas = document.createElement("canvas");
      canvas.width = this.svg.getBoundingClientRect().width * 2;
      canvas.height = this.svg.getBoundingClientRect().height * 2;

      let context = canvas.getContext("2d");

      // Create an image
      var img = new Image();

      // Once the image loads, render the img on the canvas
      img.onload = function () {
        // Draw the image
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        let messaggio = {
          detail: {
            id_img: moment().valueOf(),
            img: canvas.toDataURL(),
            id: that.riferimento,
          },
          src: null,
        };

        // Inserisco il messaggio in un evento custom.
        let evento = new CustomEvent("nuova_img", messaggio);

        // Recapito l'evento alla tabella dei diesgni, da cui sceglierò poi il migliore.
        document
          .getElementById("listener_tabella_disegni")
          .dispatchEvent(evento);
      };

      img.src = dataUrl;

      // Resetto l'id_immagine in caso abbia appena modificato un'immagine.
      this.id_img = null;

      // Pulisco l'area di disegno.
      this.clickPulisciEditor();

      // Chiudo il dialog di scelta del salvataggio che magari era stato aperto.
      this.scegli_salvataggio_dialog = false;
    },
    setImmagineSostituzione() {
      let dataUrl =
        "data:image/svg+xml; charset=utf8, " +
        encodeURIComponent(document.getElementById("svg").outerHTML);

      let messaggio = {
        detail: {
          id_img: this.id_img,
          img: dataUrl,
          id: this.riferimento,
        },
        src: null,
      };

      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("nuova_img", messaggio);

      // Recapito l'evento alla tabella dei diesgni, da cui sceglierò poi il migliore.
      document.getElementById("listener_tabella_disegni").dispatchEvent(evento);

      // Resetto l'id_immagine in caso abbia appena modificato un'immagine.
      this.id_img = null;

      // Pulisco l'area di disegno.
      this.clickPulisciEditor();

      // Chiudo il dialog di scelta del salvataggio.
      this.scegli_salvataggio_dialog = false;
    },
    uploadImage(val) {
      this.clickPulisciEditor();
      /* Se l'argomento è nullo non faccio niente
       * (magari ho appena pulito il campo del dialog). */
      if (val === null) return;

      /* Se invece l'argomento è un'immagine, comincio a eseguire
       * la logica di salvataggio per importarla nel canvas SVG
       * e chiudo il dialog di upload dell'immagine. */
      this.upload_file_visible = false;

      let that = this;

      let reader = new FileReader();

      reader.onload = function () {
        // Registro l'immagine come Base64.

        let immagine_da_ridimenionare = new Image();

        immagine_da_ridimenionare.onload = function () {
          /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
           * e dimensiono il canvas in maniera da accoglierla senza stretcharla.
           * Voglio che le dimensioni dell'immagine non siano mai
           * superiori a 450 x 337.5 px; se lo sono scalo le dimesioni.*/
          if (
            immagine_da_ridimenionare.width ===
              immagine_da_ridimenionare.height &&
            immagine_da_ridimenionare.width > 450
          ) {
            let width_nuova = 337.5;
            // Proporzione -> width_nuova : img.width = height_nuova : img.height
            let height_nuova =
              (width_nuova * immagine_da_ridimenionare.height) /
              immagine_da_ridimenionare.width;
            that.svg.style.width = width_nuova;
            that.svg.style.height = height_nuova;
            that.svg.setAttribute(
              "viewBox",
              `0 0 ${width_nuova} ${height_nuova}`
            );
          } else if (
            immagine_da_ridimenionare.width >
              immagine_da_ridimenionare.height &&
            immagine_da_ridimenionare.width > 450
          ) {
            let width_nuova = 450;
            // Proporzione -> width_nuova : img.width = height_nuova : img.height
            let height_nuova =
              (width_nuova * immagine_da_ridimenionare.height) /
              immagine_da_ridimenionare.width;
            that.svg.style.width = width_nuova;
            that.svg.style.height = height_nuova;
            immagine_da_ridimenionare.width = width_nuova;
            immagine_da_ridimenionare.height = height_nuova;
            that.svg.setAttribute(
              "viewBox",
              `0 0 ${width_nuova} ${height_nuova}`
            );
          } else if (
            immagine_da_ridimenionare.height >
              immagine_da_ridimenionare.width &&
            immagine_da_ridimenionare.height > 337.5
          ) {
            let height_nuova = 337.5;
            // Proporzione -> width_nuova : img.width = height_nuova : img.height
            let width_nuova =
              (height_nuova * immagine_da_ridimenionare.width) /
              immagine_da_ridimenionare.height;

            that.svg.style.width = width_nuova;
            that.svg.style.height = height_nuova;
            that.svg.setAttribute(
              "viewBox",
              `0 0 ${width_nuova} ${height_nuova}`
            );
          } else {
            that.svg.style.width = immagine_da_ridimenionare.width;
            that.svg.style.height = immagine_da_ridimenionare.height;
            that.svg.setAttribute(
              "viewBox",
              `0 0 ${parseFloat(that.svg.style.width)} ${parseFloat(
                that.svg.style.height
              )}`
            );
          }

          // Associo l'immagine allo sfondo.
          that.svg.style.backgroundImage =
            'url("' + immagine_da_ridimenionare.src + '")';

          // let svgImg = document.createElementNS(
          //   "http://www.w3.org/2000/svg",
          //   "image"
          // );

          // svgImg.setAttribute("x", 0);
          // svgImg.setAttribute("y", 0);
          // svgImg.setAttribute("z", -1);
          // svgImg.setAttribute("width", that.svg.style.width);
          // svgImg.setAttribute("height", that.svg.style.height);
          // svgImg.setAttribute("href", immagine_da_ridimenionare.src);

          // that.svg.appendChild(svgImg);
        };

        that.$nextTick(() => (immagine_da_ridimenionare.src = reader.result));
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

      reader.readAsDataURL(val);
    },
  },
  mounted() {
    let that = this;

    this.svg = document.getElementById("svg");
    this.svg.cursor_offset = { x: 0, y: 0 };

    document
      .getElementById("listener_visualizzatore")
      .addEventListener("ricarica_img", this.ricaricaImmagine);

    // Quando premo ENTER aggiungo il testo.
    document
      .getElementById("input_testo_canvas")

      .addEventListener("keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          that.aggiungiTesto();
        }
      });

    /* Se presente, carico l'immagine standard dell'editor
     * che viene passata in prop dal componente padre. */
    if (this.immagine_standard_editor != "") {
      this.ricaricaImmagineStandardEditor(this.immagine_standard_editor);
    }
  },
  watch: {
    visible:{immediate: true,
    handler(){
      if(this.visible===true){
        if (this.immagine_standard_editor != "") {
      this.ricaricaImmagineStandardEditor(this.immagine_standard_editor);
    }
      }
    }}
  },
};
</script>
<style scoped>
</style>