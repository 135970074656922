/* Questa classe fornisce l'oggetto di riferimento
 * per gli Interventi di Manutenzione all'interno del modulo
 * Manutenzione per EduCatt e altri. */

export default class Contract {
    constructor(arg) {
        if (arg != undefined) {
            this.cod_contratto = arg.cod_contratto != undefined ? arg.cod_contratto : "";
            this.data_fine = arg.data_fine != undefined ? arg.data_fine : "";
            this.data_inizio = arg.data_inizio != undefined ? arg.data_inizio : "";
            this.des_ambito_manutentivo = arg.des_ambito_manutentivo != undefined ? arg.des_ambito_manutentivo : "";
            this.fornitori = arg.fornitori != undefined ? arg.fornitori : [];
            this.id_ambito_manutentivo = arg.id_ambito_manutentivo != undefined ? arg.id_ambito_manutentivo : null;
            this.id_anagrafica = arg.id_anagrafica != undefined ? arg.id_anagrafica : null;
            this.id_contratto = arg.id_contratto != undefined ? arg.id_contratto : -1;
            this.ragione_sociale = arg.ragione_sociale != undefined ? arg.ragione_sociale : true;
            this.rinnovo_automatico = arg.rinnovo_automatico != undefined ? arg.rinnovo_automatico : true;
            this.strutture = arg.strutture != undefined ? arg.strutture : [];

        } else {
            this.cod_contratto = "";
            this.data_fine = "";
            this.data_inizio = "";
            this.des_ambito_manutentivo = "";
            this.fornitori = [];
            this.id_ambito_manutentivo = null;
            this.id_anagrafica = null;
            this.id_contratto = -1;
            this.ragione_sociale = "";
            this.rinnovo_automatico = true;
            this.strutture = [];
        }
    }
}