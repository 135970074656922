var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"listener_attivita"}},[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"ml-2"},[_vm._v("Risorse "+_vm._s(_vm.assistenza == 1 ? "Assistenza" : ""))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('AddResource',_vm._g(_vm._b({attrs:{"ticket":_vm.ticket,"assistenza":_vm.assistenza},on:{"dialogModule":_vm.emitActivities}},'AddResource',attrs,false),on))]}}])},[_c('span',[_vm._v("Crea Risorse")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.id_ticket != -1)?_c('AddActivity',_vm._g(_vm._b({attrs:{"item":{},"ticket":_vm.ticket,"assistenza":_vm.assistenza},on:{"dialogActivity":_vm.emitActivities}},'AddActivity',attrs,false),on)):_vm._e()]}}])},[_c('span',[_vm._v("Crea Attività")])]),_c('v-spacer'),(_vm.showDialog)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.closeActivities()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,false,3283657065)},[_c('span',[_vm._v("Indietro")])]):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan","id":"progress"}}):_vm._e(),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 mt-4",attrs:{"headers":_vm.headersRisorse,"items":_vm.items,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id_risorsa","show-expand":"","items-per-page":15},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.ore_assegnate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ore_assegnate)+" ")]}},{key:"item.numero_attivita",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.tickets_attivita != null) ? item.tickets_attivita.length : 0)+" ")]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){_vm.deleteItemRisorsa(_vm.items.indexOf(item))}}},[_vm._v(" mdi-close ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAttivita,"items":item.tickets_attivita,"item-key":"id_ticket_attivita","items-per-page":15,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('AddActivity',{attrs:{"assistenza":_vm.assistenza,"item":item},on:{"dialogActivity":_vm.emitActivities}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ore)+" "+_vm._s(item.unita_misura)+" ")]}},{key:"item.data_inizio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.data_inizio))+" ")]}},{key:"item.data_fine",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.data_fine))+" ")]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){_vm.deleteItemAttivita(_vm.attivita.indexOf(item))}}},[_vm._v(" mdi-close ")])]}}],null,true)})],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Spostamento")]),_c('v-card-text',[_vm._v("Sicuro di voler eliminare?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.modalDelete = false}}},[_vm._v("Non eliminare")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.deleteItem(_vm.attivita.indexOf(_vm.item))}}},[_vm._v("Elimina")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }