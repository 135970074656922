import Vue from 'vue';
import App from './App.vue';
import router from './router';
import moment from 'moment';
// GESTIONE PLUGIN
import { store } from './store';
import { auth } from './plugins/firebase';

//PLUGIN GRAFICI
import ECharts from 'vue-echarts';
Vue.component('v-chart', ECharts);

// GESTIONE WORKER
import {jWorkers} from '@/components/COMMON/JWorkers.js';
Vue.prototype.$worker = jWorkers;

//PLUGIN GRAFICA
import vuetify from './plugins/vuetify';
//PLUGIN QUERY SU DATA ARRAY
import alasqlJs from '../node_modules/alasql/dist/alasql.min.js';
//PLUGIN ZOOM CHAT
import VueZoomer from 'vue-zoomer';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import * as VueGoogleMaps from 'vue2-google-maps';

import axios from "axios";
import DatetimePicker from 'vuetify-datetime-picker'
import VueQrcodeReader from "vue-qrcode-reader";


Vue.use(DatetimePicker)
Vue.use(VueQrcodeReader);

Vue.use(VueZoomer);
Vue.use(Viewer)


//SCARICARE DATI IN FOGLI EXCEL, PER QUICKCHECK
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);

//BARRA PER IL CARICAMENTO
import VueProgressBar from 'vue-progressbar';
const options = {
  color: '#bffaf3',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'left',
  inverse: false,
};

Vue.use(VueProgressBar, options);

Vue.component('google-map', VueGoogleMaps.Map)
Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyBNH-zPacFsPV5MlWmr0E6FO_0tontcBp0',
    libraries: 'places',
    autobindAllEvents: true
  }
});

Vue.config.productionTip = false;
const alasql = {
  install() {
    Vue.prototype.alasql = alasqlJs;
  },
};
Vue.use(alasql);
Vue.use(moment);
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm');
  }
});
Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM HH:mm');
  }
});
Vue.filter('timestampToHour', function (value) {
  if (value) {
    var date = new Date(parseInt(value));
    return moment(String(date)).format('DD/MM HH:mm');
  }
});
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: (h) => h(App),
// }).$mount("#app");

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      auth,
      data() {
        return {
          ready: false,
        };
      },
      async beforeCreate() {
        // await this.$store.commit("setInfo", {
        //   config
        // });
        await new Promise((resolve, reject) => {
          axios
            .get("../../../config.json")
            .then((response) => {
              this.$store.commit("setInfo", response.data);
              resolve(response);
              return;
            })
            .catch((e) => {
              reject(e);
              console.log(e);
            });
        });


        // await this.$store.dispatch('getInfo');
        this.$mount('#app');
      },
      render: (h) => h(App),
    });
  }
});
