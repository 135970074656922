<template>
  <div class="">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <!-- absolute -->
        <v-btn
          bottom
          color="white"
          :disabled="disabled"
          small
          @click="attiva_telecamera"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-qrcode</v-icon>
        </v-btn>
      </template>
      <span>Scansiona il QR CODE</span>
    </v-tooltip>
    <v-dialog v-model="modal_scan_qr" persistent width="unset">
      <v-card>
        <qrcode-stream
          class="qr"
          :camera="camera"
          @decode="onDecode"
        ></qrcode-stream>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="chiudiModalQr">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DataMatrixReader",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // Variabili d'appoggio
    altezza_modal: "50",
    camera: "off",
    larghezza_modal: "50%",
    // Modal
    modal_scan_qr: false,
    // Array
  }),
  components: {},
  created() {},
  mounted() {},
  watch: {
    // window_width(newHeight) {
    //   if (newHeight <= 600) {
    //     this.larghezza_modal = "80%";
    //     this.altezza_modal = "80%";
    //   } else if (newHeight <= 900) {
    //     this.larghezza_modal = "70%";
    //     this.altezza_modal = "70%";
    //   } else if (newHeight <= 1200) {
    //     this.larghezza_modal = "65%";
    //     this.altezza_modal = "65%";
    //   } else if (newHeight <= 1600) {
    //     this.larghezza_modal = "50%";
    //     this.altezza_modal = "50%";
    //   } else {
    //     this.larghezza_modal = "100%";
    //     this.altezza_modal = "100%";
    //   }
    //   this.utente_anonimo =
    //     this.utente_sel.nominativo === "- Anonimo -" && this.check_anonimo == 1;
    // },
  },
  computed: {},
  methods: {
    attiva_telecamera() {
      this.modal_scan_qr = true;
      this.camera = "auto";
    },
    chiudiModalQr() {
      this.modal_scan_qr = false;
      this.camera = "off";
      this.$emit("clickChiudi");
    },
    onDecode(decodedString) {
      let that = this;
      setTimeout(() => {
        that.camera = "off";
      }, 10);
      this.modal_scan_qr = false;
      this.$emit(
        "detectQrCode",
        decodedString,
        "success",
        "mdi-check",
        "white",
        "white"
      );
    },
    onResize() {
      this.window_width = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.qr {
  max-width: min(90vw, 500px);
  max-height: min(90vh, 500px);
}
</style>