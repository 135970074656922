<template>
  <v-dialog :scrollable="true" persistent v-model="dialogModuli" width="1000">
    <template v-slot:activator="{ on }">
      <v-btn
        :style="
          background_icon == undefined || background_icon == false
            ? 'background-color:transparent; box-shadow:none; min-width:15px; height:15px'
            : ''
        "
        class="px-0"
        slot="badge"
        v-on="on"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              v-if="bg_color=='indigo'"
              :class="color == undefined ? 'blue--text' : color"
              >{{ icon == undefined ? "mdi-eye" : icon }}</v-icon
            >
            <v-icon
              v-on="on"
              v-bind="attrs"
              v-else
              :color="bg_color"
              >{{ icon == undefined ? "mdi-eye" : icon }}</v-icon
            >
          </template>
          <span>{{ nome }}</span>
        </v-tooltip>
      </v-btn>
    </template>
    <template>
      <div class="main-div ml-auto mr-auto" style="background-color: white">
        <v-toolbar :color="bg_color" dense>
          <v-container class="py-0">
            <v-row>
              <v-col cols="4" class="py-1 d-flex justify-start">
                <v-toolbar-title class="white--text">{{
                  nome
                }}</v-toolbar-title>
              </v-col>
              <v-col cols="4" class="py-1 d-flex justify-center">
                <AddModule
                  :assistenza="assistenza"
                  :item="{}"
                  :tipo="tipo"
                  :nome="nome"
                  :getaction="getaction"
                  :setaction="setaction"
                  :controller="controller"
                  :filter="filter"
                  :dati="dati"
                  :all_label="all_label"
                  :id_azienda="id_azienda"
                  :controllaCodice="controllaCodice"
                  v-on:dialogModule="emitDialog"
                  :bg_color="bg_color"
                  :icon_color="icon_color"
                />
              </v-col>
              <v-col cols="4" class="py-1 d-flex justify-end">
                <v-text-field
                  style="background-color: white !important"
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="label"
                  :placeholder="placeholder"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="px-0"
                      style="height: auto"
                      v-on="on"
                      v-bind="attrs"
                      :icon="icon_color == 'primary' ? false:true"
                      @click="closeModules()"
                    >
                      <v-icon :color="icon_color == 'primary' ? '#c00000' : icon_color" style="color: #c00000"
                        >mdi-keyboard-backspace</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Chiudi </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <v-container fluid> </v-container>
        <v-container
          style="max-height: 600px; overflow-y: auto"
          class="py-0 mt-n6"
          fluid
        >
          <v-row class="white">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="lista"
                :items-per-page="15"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.modifica`]="{ item }">
                  <AddModule
                    :assistenza="assistenza"
                    :item="item"
                    :tipo="tipo"
                    :nome="nome"
                    :getaction="getaction"
                    :setaction="setaction"
                    :controller="controller"
                    :filter="filter"
                    :dati="dati"
                    :risorsa_preferenziale="risorsa_preferenziale"
                    :all_label="all_label"
                    :id_azienda="id_azienda"
                    :controllaCodice="controllaCodice"
                    v-on:dialogModule="emitDialog"
                    :bg_color="bg_color"
                  :icon_color="icon_color"
                  />
                </template>
                <template v-slot:[`item.categoria`]="{ item }">
                  {{ item.dd }}
                </template>
                <template v-slot:[`item.elimina`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        @click="
                          modalDelete = true;
                          id = lista.indexOf(item);
                        "
                        small
                      >
                        <v-icon color="#c00000" medium>
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Cancellare</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>

        <v-dialog v-model="modalDelete" max-width="290">
          <v-card>
            <v-card-title class="headline">Spostamento</v-card-title>
            <v-card-text>Sicuro di voler eliminare?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="modalDelete = false"
                >Non eliminare</v-btn
              >

              <v-btn color="primary darken-1" text @click="deleteItem()"
                >Elimina</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import AddModule from "./AddModule";

function updateList(that) {
  var rich = {};
  if (that.filter != undefined) {
    if (that.addfields != undefined) {
      rich = {
        action: that.getaction,
        token: localStorage.user_token,
        tipo: that.tipo,
        filter: that.filter,
        addfields: that.addfields,
        assistenza: that.assistenza,
      };
    } else {
      rich = {
        action: that.getaction,
        token: localStorage.user_token,
        tipo: that.tipo,
        filter: that.filter,
        assistenza: that.assistenza,
      };
    }
  } else {
    rich = {
      action: that.getaction,
      token: localStorage.user_token,
      tipo: that.tipo,
      assistenza: that.assistenza,
    };
  }
  if (that.id_azienda != undefined) {
    rich["id_azienda"] = that.id_azienda;
  }
  var request = {
    controller: that.controller,
    method: "POST",
    richiesta: rich,
  };
  that.$store.dispatch("api", request).then((res) => {
    //console.log('this.headers--->', that.headers)
    that.headers = [];
    that.headers.push({
      text: "Modifica",
      value: "modifica",
    });
    that.headers.push({
      text: "Nome " + that.nome,
      value: "des",
      align: null,
      sortable: null,
      visible: 0,
    });
    if (that.tipo.includes("MAN_") && that.visualizzaCodice == true) {
      that.headers.push({
        text: "Codice " + that.nome,
        value: "des_lingua3",
        align: null,
        sortable: null,
        visible: 0,
      });
    }
    if (that.tipo == "FASE_causali" || that.tipo == "PRODUZIONE_CAUSALI") {
      that.headers.push({
        text: "Categoria " + that.nome,
        value: "categoria",
        align: null,
        sortable: null,
        visible: 0,
      });
    }
    that.headers.push({
      text: "Elimina",
      value: "elimina",
      align: "center",
    });

    if (that.getaction == "gettabella") {
      if (res.data != "") {
        that.lista = res.data;
      }
    } else {
      if (res.data != "") {
        that.lista = res.data;
      }
    }
  });
}

export default {
  name: "Modules",

  data: () => ({
    id: 0,
    des: "",
    placeholder: "",
    label: "",
    cod_moduli: "",
    dialogModuli: false,
    lista: [],
    headers: [],
    modalDelete: false,
    categories: [],
    search: "",
  }),

  components: {
    AddModule,
  },
  created() {},
  props: {
    tipo: String,
    nome: String,
    getaction: String,
    setaction: String,
    controller: String,
    filter: String,
    dati: Array,
    icon: String,
    color: String,
    background_icon: Boolean,
    addfields: String,
    id_azienda: String,
    risorsa_preferenziale: {
      type: Boolean,
      default: false,
    },
    assistenza: {
      type: Number,
      default: 0,
    },
    controllaCodice: {
      type: Boolean,
      default: false,
    },
    visualizzaCodice: {
      type: Boolean,
      default: false,
    },
    all_label: {
      type: Array,
      default: () => [],
    },
     toolbar_title:{
      type: String,
      default: "Documenti Associati"
    },
    bg_color:{
      type:String,
      default:"indigo"
    },
    icon_color:{
      type:String,
      default:"primary"
    }
  },
  watch: {
    dialogModuli: function (newM, oldM) {
      if (newM != oldM && newM != false) {
        updateList(this);
      } else {
        this.lista = [];
      }
    },
  },
  computed: {
    valid() {
      return this.des != "" ? true : false;
    },
  },
  methods: {
    deleteItem() {
      this.lista[this.id].validita = 0;

      let rich = {
        action: this.setaction,
        token: localStorage.user_token,
        id_utente: localStorage.id,
        tabella: [this.lista[this.id]],
        assistenza: this.assistenza,
      };
      if (this.setaction == "setambitobyazienda") {
        rich = {
          action: this.setaction,
          token: localStorage.user_token,
          tabella: [this.lista[this.id]],
          id_azienda: this.id_azienda,
        };
      }
      var removeItem = {
        controller: this.controller,
        method: "PUT",
        richiesta: rich,
      };
      console.log(JSON.stringify(removeItem.richiesta));
      this.$store.dispatch("api", removeItem).then();
      this.lista.splice(this.id, 1);
      this.modalDelete = false;
    },
    emitDialog() {
      updateList(this);
    },
    closeModules() {
      this.$emit("dialogModules", true);
      this.dialogModuli = false;
    },
  },
};
</script>
<style lang="scss">
.dialogLayout {
  overflow-y: hidden !important;
}
</style>