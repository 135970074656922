<template>
  <v-card>
    <v-toolbar class="white--text" color="indigo" flat>
      <v-toolbar-title>Consuntivi di Produzione</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Vai alla Dashboard</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text style="max-height:70vh; overflow-y:scroll">
      <v-col cols="12">
        <v-row justify="center">
          <v-btn class="elevation-5 ma-5" @click="clickPezzi" x-large>
            <span class="primary--text" style="font-size: 20px">Pezzi</span>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn class="elevation-5 ma-5" @click="clickTempi" x-large>
            <span class="primary--text" style="font-size: 20px">Tempi</span>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn class="elevation-5 ma-5" @click="clickScarti" x-large>
            <span class="primary--text" style="font-size: 20px">Scarti</span>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn class="elevation-5 ma-5" @click="clickCausaliFermo" x-large>
            <span class="primary--text" style="font-size: 20px">Fermi</span>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn class="elevation-5 ma-5" @click="clickContenitori" x-large>
            <span class="primary--text" style="font-size: 20px">Contenitori</span>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn class="elevation-5 ma-5" @click="clickAutocontrolli" x-large>
            <span class="primary--text" style="font-size: 20px">Autocontrolli</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    gotoDashboard() {
      this.$router.push("/home/production");
    },
    clickAutocontrolli(){
      this.$emit("click_autocontrolli");
    },
    clickContenitori(){
      this.$emit("click_contenitori");
    },
    clickPezzi() {
      this.$emit("click_pezzi");
    },
    clickTempi() {
      this.$emit("click_tempi");
    },
    clickScarti() {
      this.$emit("click_scarti");
    },
    clickCausaliFermo() {
      this.$emit("click_causali_fermo");
    },
  },
};
</script>
