<template>
  <div style="padding: 20px">
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card fluid id="listener_commessa_dashboard">
      <!-- To add Allegati in future -->
      <v-dialog
        persistent
        :hide-overlay="true"
        v-model="dialogAggiungiAllegati"
        width="500"
      >
        <v-card>
          <v-toolbar dense class="indigo darken-1" primary-title>
            <v-toolbar-title class="white--text"
              >Aggiungi Commessa Allegati</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small @click="dialogAggiungiAllegati = false">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    class=""
                    color="error"
                    >mdi-window-close</v-icon
                  >
                </v-btn>
              </template>
              <span>Cancellare </span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="immagine_preview"
              accept="image/*"
              label="Immagine"
              :placeholder="immagine_preview"
              prepend-icon="mdi-camera"
            ></v-text-field>
            <input type="file" ref="allegato" name="allegato" class="d-none" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="setImmagine()">
                  <v-icon v-on="on" color="#009900" v-bind="attrs">
                    mdi-floppy
                  </v-icon>
                </v-btn>
              </template>
              <span>Salva</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Maschera per le CICLO STD da generare -->
      <v-card>
        <v-toolbar primary-title dense color="indigo">
          <v-toolbar-title class="white--text">
            Ciclo Standard
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                style="color: #1a80b6; float: left !important"
                small
              >
                <v-icon v-on="on" v-bind="attrs">mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>Stampa</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                @click="dialogAggiungiAllegati = true"
                small
                style="float: left !important"
              >
                <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                  >mdi-paperclip</v-icon
                >
              </v-btn>
            </template>
            <span> Aggiungi Allegati</span>
          </v-tooltip>
          <Button
            classe_bottone="ml-2"
            colore_icona="success"
            idForm="IMPOSTAZIONI"
            idOggetto="SALVA_CICLO_STANDARD"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva Ciclo Std"
            v-on:buttonClick="salvaCiclo"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="float: left !important" @click="goBack" small>
                <v-icon v-on="on" v-bind="attrs" color="red"
                  >mdi-keyboard-backspace</v-icon
                >
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <form class="px-5">
          <v-container>
            <v-row>
              <v-col cols="3" jusify="center" class="py-0">
                <TextField
                  idForm="CICLO_STANDARD"
                  idOggetto="CS_DES_CICLO"
                  label_input="Des. Ciclo"
                  palceholder_input="Des. Ciclo"
                  :vmodel="des_ciclo"
                  v-on:changeText="(val) => (this.des_ciclo = val)"
                />
              </v-col>
            </v-row>
          </v-container>
        </form>
        <v-row>
          <v-container fluid class="py-0">
            <!-- Dialog to show list of fasi Standard Table in Ciclo Commessa Produzione -->
            <v-dialog v-model="dialogfasestandard1" width="75%">
              <v-card>
                <v-toolbar color="indigo" dense>
                  <v-toolbar-title class="white--text"
                    >Fasi Standard</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        @click="dialogfasestandard1 = false"
                        ><v-icon color="error">mdi-close</v-icon></v-btn
                      >
                    </template>
                    <span>Chiudi</span>
                  </v-tooltip>
                </v-toolbar>
                <v-card-text style="max-height: 600px; overflow-y: auto">
                  <v-data-table :headers="fasiHeaders" :items="fasiStdLista">
                    <template v-slot:[`item.edit`]="{ item }">
                      <v-col md="4">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              style="float: left"
                              @click="addToCicli(item)"
                              small
                            >
                              <v-icon color="primary">mdi-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Aggiungi</span>
                        </v-tooltip>
                      </v-col>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Dialog to show list of fasi standard Table in Ciclo Commessa Produzione -->
            <v-dialog v-model="dialogfasestandard" width="75%">
              <v-card>
                <v-toolbar color="indigo" dense>
                  <v-toolbar-title class="white--text"
                    >Fasi Standard</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn small @click="showFasi">
                    <v-icon color="success">mdi-floppy</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        @click="dialogfasestandard = false"
                        ><v-icon color="error">mdi-close</v-icon></v-btn
                      >
                    </template>
                    <span>Chiudi</span>
                  </v-tooltip>
                </v-toolbar>
                <v-card-text style="max-height: 600px; overflow-y: auto">
                  <v-data-table
                    v-model="selected"
                    item-key="des_fase_standard"
                    :headers="fasiHeaders"
                    :items="fasiStdLista"
                    :single-select="singleSelect"
                    show-select
                  >
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              content-class="artDialog"
              v-model="dialogCiclo"
              :scrollable="false"
              persistent
              width="unset"
            >
              <v-container fluid>
                <form>
                  <v-row>
                    <v-card>
                      <v-toolbar
                        dense
                        class="indigo darken-1"
                        primary-title
                        uppercase
                      >
                        <v-toolbar-title class="white--text">
                          Ciclo Fasi
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <Button
                          classe_bottone="mr-5"
                          colore_icona="primary"
                          idForm="IMPOSTAZIONI"
                          idOggetto="AGGIUNGI_FASE_STANDARD"
                          mdi_icona=" mdi-shape-circle-plus"
                          testo_tooltip="Aggiungi fase standard"
                          v-on:buttonClick="
                            getFaseStd();
                            dialogfasestandard1 = true;
                          "
                        />
                        <Button
                          colore_icona="success"
                          idForm="IMPOSTAZIONI"
                          idOggetto="SALVA_CICLO_FASE"
                          mdi_icona=" mdi-floppy"
                          testo_tooltip="Salva Fase"
                          v-on:buttonClick="UpdateCicloFasi"
                        />
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="closeDialog">
                              <v-icon
                                depressed
                                v-on="on"
                                v-bind="attrs"
                                color="error"
                                >mdi-window-close</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Cancellare</span>
                        </v-tooltip>
                      </v-toolbar>
                      <v-card-text
                        class=""
                        style="
                          max-height: 600px;
                          overflow-y: auto;
                          overflow-x: hidden;
                        "
                      >
                        <v-row class="">
                          <v-col md="1" cols="2" class="py-0">
                            <TextField
                              idForm="CICLO_STANDARD"
                              idOggetto="CS_COD_FASE"
                              label_input="Cod. Fase"
                              palceholder_input="Cod. Fase"
                              :vmodel="cod_fase"
                              v-on:changeText="(val) => (this.cod_fase = val)"
                              :min_input="0"
                              type_input="number"
                            />
                          </v-col>
                          <v-col md="2" cols="4" class="py-0">
                            <v-autocomplete
                              v-model="des1"
                              :items="produzione_list"
                              label="Tipo Produzione"
                              placeholder="Tipo Produzione"
                              item-text="des"
                              required
                            >
                              <template #label>
                                <span class=""
                                  >Tipo Produzione<strong>* </strong></span
                                >
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col class="py-0" md="1">
                            <div class="">
                              <span
                                style="
                                  font-size: 12px;
                                  color: rgba(0, 0, 0, 0.6);
                                "
                                >Prelievo Comp.
                              </span>
                              <v-switch
                                v-model="materia_prima"
                                style="margin-top: -0.3rem !important"
                                class="ml-10"
                                :false-value="0"
                                :true-value="1"
                              ></v-switch>
                            </div>
                          </v-col>
                          <v-col class="py-0 ml-n2" md="1">
                            <TextField
                              idForm="CICLO_STANDARD"
                              idOggetto="CS_TEMPO_STANDARD"
                              label_input="Tempo Standard"
                              palceholder_input="Tempo"
                              :vmodel="tempo_standard"
                              v-on:changeText="
                                (val) => (this.tempo_standard = val)
                              "
                              :min_input="0"
                              type_input="number"
                            />
                          </v-col>
                          <v-col class="py-0" md="1">
                            <v-autocomplete
                              style="width: 190px !important"
                              v-model="um_tempo"
                              :items="unita_misura_lead_time"
                              label="UM Tempo"
                              placeholder="UM Tempo"
                              item-text="des"
                              required
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="2" class="py-0" md="1">
                            <TextField
                              idForm="CICLO_STANDARD"
                              idOggetto="CS_PESO"
                              label_input="Peso"
                              palceholder_input="Peso"
                              :vmodel="peso"
                              v-on:changeText="(val) => (this.peso = val)"
                              :min_input="0"
                              type_input="number"
                            />
                          </v-col>
                          <v-col class="py-0" md="1">
                            <v-autocomplete
                              style=""
                              v-model="um_pezzo"
                              :items="unita_misura_peso"
                              label="UM Peso"
                              placeholder="UM Peso"
                              item-text="des"
                              required
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col md="3" class="py-0">
                            <v-autocomplete
                              v-model="des"
                              :items="fase_list"
                              label="Fase. Lavo"
                              placeholder="Fase. Lavo"
                              item-text="des"
                              required
                            >
                              <template #label>
                                <span class=""
                                  >Fase. Lavo<strong>* </strong></span
                                >
                                <a @click.stop style="pointer-events: all">
                                  <Modules
                                    @aggiornamento="aggiornaFase"
                                    v-on:dialogModules="aggiornaFase"
                                    tipo="FASE"
                                    nome="Fase"
                                    setaction="settabelle"
                                    getaction="gettabelle"
                                    controller="Magazzino"
                                    :dati="[{ des: '' }]"
                                  />
                                </a>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="2" sm="1" class="py-0 ml-n2" md="1">
                            <div class="">
                              <span
                                style="
                                  font-size: 12px;
                                  color: rgba(0, 0, 0, 0.6);
                                  margin-left: ;
                                  padding-left: -0.2rem;
                                "
                                >Fase Esterna
                              </span>
                              <v-switch
                                v-model="fase_esterna"
                                style="margin-top: -0.3rem !important"
                                class=""
                                :false-value="0"
                                :true-value="1"
                              ></v-switch>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="py-0" style="">
                            <Fornitura
                              FORM_ID="IMPOSTAZIONI"
                              SOTTO_FORM="CICLO_STANDARD"
                              :FORM_BUTTON_ARRAY="FORM_BUTTON_ARRAY"
                              v-on:updateFornitura="updateFornitura($event)"
                              :key="id_ciclostd_dettaglio"
                              :data="commessa_dettaglio_fornitori"
                              v-on:delFornitura="deleteFornituraLista($event)"
                            />
                          </v-col>
                          <v-col class="py-0" md="6">
                            <Macchina
                              FORM_ID="IMPOSTAZIONI"
                              SOTTO_FORM="CICLO_STANDARD"
                              :MACCHINA_BUTTON_ARRAY="MACCHINA_BUTTON_ARRAY"
                              v-on:updateMacchina="updateMacchina($event)"
                              :key="id_ciclostd_dettaglio"
                              :id_ciclostd_dettaglio="id_ciclostd_dettaglio"
                              :data="commessa_dettaglio_macchina"
                              v-on:delMacchina="delMacchina($event)"
                            />
                          </v-col>
                          <v-col class="py-0" md="6">
                            <Personale
                              FORM_ID="IMPOSTAZIONI"
                              SOTTO_FORM="CICLO_STANDARD"
                              :PERSONALE_BUTTON_ARRAY="PERSONALE_BUTTON_ARRAY"
                              v-on:updatePersonale="updatePersonale($event)"
                              :key="id_ciclostd_dettaglio"
                              :id_ciclostd_dettaglio="id_ciclostd_dettaglio"
                              :data="commessa_dettaglio_personale"
                              v-on:delPersonale="delPersonale($event)"
                            />
                          </v-col>
                          <v-col md="12" class="ml-1 py-0">
                            <v-textarea
                              rows="4"
                              v-model="note"
                              item-text="text"
                              label="Annotanzione"
                              placeholder="Annotanzione"
                              required
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </form>
              </v-container>
            </v-dialog>
            <form>
              <v-row>
                <v-col md="12">
                  <v-hover>
                    <template v-slot="{ hover }">
                      <v-card :elevation="hover ? 24 : 2">
                        <v-toolbar dense class="indigo darken-1" primary-title>
                          <v-toolbar-title class="white--text"
                            >Ciclo Fasi</v-toolbar-title
                          >
                          <v-spacer></v-spacer>
                          <Button
                            classe_bottone="ml-2"
                            colore_icona="error"
                            idForm="IMPOSTAZIONI"
                            idOggetto="CANCELLA_FASI"
                            mdi_icona="mdi-close-circle-multiple"
                            testo_tooltip="Cancella Fasi"
                            v-on:buttonClick="delAllFasi"
                          />
                          <v-spacer></v-spacer>
                          <Button
                            colore_icona="primary"
                            idForm="IMPOSTAZIONI"
                            idOggetto="CREA_CICLO_FASE"
                            mdi_icona="mdi-plus"
                            testo_tooltip="Nuova Fase"
                            v-on:buttonClick="opendialogciclo"
                          />
                          <Button
                            classe_bottone="ml-2"
                            colore_icona="primary"
                            idForm="IMPOSTAZIONI"
                            idOggetto="AGGIUNGI_FASE_STANDARD"
                            mdi_icona="mdi-shape-circle-plus"
                            testo_tooltip="Aggiungi fase standard"
                            v-on:buttonClick="
                              getFaseStd();
                              dialogfasestandard = true;
                            "
                          />
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-data-table
                          :loading="loadingCiclo"
                          :headers="CicloHeaders"
                          :items="cicloLista"
                        >
                          <v-progress-linear
                            v-show="progressBarCiclo"
                            slot="progress"
                            color="blue"
                            indeterminate
                          ></v-progress-linear>
                          <template v-slot:[`item.edit`]="{ item }">
                            <Button
                              classe_bottone="ml-2"
                              colore_icona="primary"
                              idForm="IMPOSTAZIONI"
                              idOggetto="MODIFICA_CICLO_FASE"
                              mdi_icona="mdi-pencil"
                              testo_tooltip="Aggiornare"
                              v-on:buttonClick="
                                handleClick(item);
                                id_ciclostd_dettaglio =
                                  item.id_ciclostd_dettaglio;
                              "
                            />
                          </template>
                          <template v-slot:[`item.materia_prima`]="{ item }">
                            <v-icon v-if="item.materia_prima == 1"
                              >mdi-check-underline</v-icon
                            >
                          </template>
                          <template v-slot:[`item.fase_esterna`]="{ item }">
                            <v-icon v-if="item.fase_esterna == 1"
                              >mdi-check-underline</v-icon
                            >
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <Button
                              colore_icona="error"
                              idForm="IMPOSTAZIONI"
                              idOggetto="ELIMINA_CICLO_FASE"
                              mdi_icona="mdi-window-close"
                              testo_tooltip="Cancellare"
                              v-on:buttonClick="delfasi(item)"
                            />
                          </template>
                        </v-data-table>
                      </v-card>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </form>
            <v-snackbar
              content-class="snackbar"
              :color="snackbar_background"
              right
              v-model="snackbar"
              :timeout="3000"
            >
              <v-icon :color="snackbar_icon_color" :size="64">{{
                snackbar_icon
              }}</v-icon>
              <h2
                :style="{ color: snackbar_text_color + '!important' }"
                class="ml-16"
              >
                {{ snackbar_text }}
              </h2>
              <v-btn
                @click="snackbar = false"
                fab
                dark
                text
                small
                style="
                  margin-top: -6rem;
                  float: right !important;
                  margin-right: -1rem !important;
                "
              >
                <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
              </v-btn>
            </v-snackbar>
          </v-container>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField";
import Fornitura from "../../../views/PRODUCTION/Common/Fornitura.vue";
import Macchina from "../../../views/PRODUCTION/Common/Macchina.vue";
import Personale from "../../../views/PRODUCTION/Common/Personale.vue";
export default {
  name: "cicloStd",
  components: {
    Modules: () => import("../../../components/TOOLS/Modules"),
    Button,
    TextField,
    Fornitura,
    Macchina,
    Personale,
  },
  data() {
    return {
      //   Extra
      FORM_BUTTON_ARRAY: [
        "CREA_CICLO_STANDARD_FORNITURA",
        "MODIFICA_CICLO_STANDARD_FORNITURA",
        "ELIMINA_CICLO_STANDARD_FORNITURA",
        "SALVA_CICLO_STANDARD_FORNITURA",
      ],
      MACCHINA_BUTTON_ARRAY: [
        "CREA_CICLO_STANDARD_MACCHINA",
        "MODIFICA_CICLO_STANDARD_MACCHINA",
        "ELIMINA_CICLO_STANDARD_MACCHINA",
        "SALVA_CICLO_STANDARD_MACCHINA",
      ],
      PERSONALE_BUTTON_ARRAY: [
        "CREA_CICLO_STANDARD_PERSONALE",
        "MODIFICA_CICLO_STANDARD_PERSONALE",
        "ELIMINA_CICLO_STANDARD_PERSONALE",
        "SALVA_CICLO_STANDARD_PERSONALE",
      ],
      main_info_object: {},
      cod_articolo_dialog: false,
      cod_articolo: null,
      dialogAllegati: false,
      immagine_preview: null,
      dialogAggiungiAllegati: false,
      dialogRicerca: false,
      headers: [],
      items: [],
      tableData: [],
      cod_ciclo: null,
      des_ciclo: null,
      //<==============================Ciclo Variables=====================>//
      temp_array: [],
      dialogSalvataggio: false,
      newArray: [],
      cicloLista1: [],
      cicloLista: [],
      tipo_produzione_list: [],
      fase_lavorazione_list: [],
      progressBarCiclo: false,
      loadingCiclo: false,
      dialogCiclo: false,
      id_ciclo_fasi: "",
      cod_fase: "",
      produzione_list: [],
      id_tipo_fase: "",
      id_fase: "",
      fase_list: [],
      search: "",
      peso: "",
      unita_misura_peso: [],
      des: "",
      des1: "",
      cod_macchina: null,
      costo_lavorazione: null,
      fase_esterna: 0,
      materia_prima: 0,
      tempo_standard: 0,
      id_um_tempo: "",
      des_um_tempo: "",
      id_um_pezzo: "",
      um_pezzo: "",
      singleitem: {},
      note: "",
      // Variable for pop modal for deletion
      dialogdeletecicloconfirmation: false,
      selectedCiclo: {},
      //<======== Fornitura Variables =====>//
      id_ciclostd_dettaglio: -1,
      commessa_dettaglio_fornitori: [],
      fornitoriLista: [],
      deletedFornitura: [],
      dataToUpdate: {},
      // Macchina Variables
      commessa_dettaglio_macchina: [],
      singleMacchina: {},
      deletedMacchina: [],
      // Personale Variables
      commessa_dettaglio_personale: [],
      deletedPersonale: [],
      unita_misura_lead_time: [],
      um_tempo: "",
      //  <=======================Generic Variables =====================>
      // Dailog Fase Standard
      deletedFasi: [],
      singleSelect: false,
      selected: [],
      dialogfasestandard: false,
      dialogfasestandard1: false,
      item: "",
      overlay: false,
      id_articolo: this.IdArticolo,
      validita: 1,
      // Confirmation Dialog
      dialogSavingConfirmation: false,
      // Snackbar Variables
      snackbar: false,
      mainIcon: "",
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      color: "",
      id_temp: "",
      id_temp_dett: "",
      // Fasi Standard List and Fasi standard Headers
      fasiStdLista: [],
      fasiHeaders: [],
      FaseStandDetail: null,
      // Header of ciclo table
      CicloHeaders: [
        {
          value: "edit",
        },
        {
          text: "Cod. Fase",
          value: "cod_fase",
          sortable: false,
        },
        {
          text: "Tipo Fase",
          value: "des1",
          sortable: false,
        },
        {
          text: "Prelievo Comp",
          value: "materia_prima",
          sortable: false,
        },
        {
          text: "Lavoro Esterna",
          value: "fase_esterna",
          sortable: false,
        },
        {
          text: "Fase Lavoro",
          value: "des",
          sortable: false,
        },
        {
          text: "Peso",
          value: "peso",
          sortable: false,
        },
        {
          text: "Lotto Prod",
          value: "lotto_produzione",
          sortable: false,
        },
        {
          text: "Ragione Sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Trasf. Diretto",
          value: "trasf_diretto",
          sortable: false,
        },
        {
          text: "Lead Time",
          value: "lead_time",
          sortable: false,
        },
        {
          text: "Lotto Lavoro",
          value: "lotto_lavorazione",
          sortable: false,
        },
        {
          text: "Costo Lavoro",
          value: "costo_lavorazione",
          sortable: false,
        },
        {
          text: "Tempo Standard",
          value: "tempo_standard",
          sortable: false,
        },
        {
          text: "Pezzi/h Min",
          value: "pezzi_ora_min",
          sortable: false,
        },
        {
          text: "Pezzi/h Max",
          value: "pezzi_ora_max",
          sortable: false,
        },
        {
          text: "Macchina",
          value: "codice",
          sortable: false,
        },
        {
          text: "Tempo Setup",
          value: "tempo_setup",
          sortable: false,
        },
        {
          text: "Attrezzatura",
          value: "id_attrezzatura",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    //  updating macchina Lista
    updatePersonale(val) {
      this.commessa_dettaglio_personale = val;
    },
    // Delete Macchina
    delPersonale(val) {
      this.deletedPersonale = val;
    },
    //  updating macchina Lista
    updateMacchina(val) {
      this.commessa_dettaglio_macchina = val;
    },
    // Delete Macchina
    delMacchina(val) {
      this.deletedMacchina = val;
    },
    // getting deleted array of fornitura
    deleteFornituraLista(val) {
      this.deletedFornitura = val;
    },
    // getting updated list of fornitura
    updateFornitura(val) {
      val.map((it) => {
        delete it.id_articolo;
        delete it.id_articolo_fornitura;
        delete it.id_ciclo_fasi;
        delete it.id_commessa_dettaglio;
        delete it.idciclo_fasi_fornitori;
      });
      this.fornitoriLista = val;
    },
    //in order to save ciclo std
    salvaCiclo() {
      this.overlay = true;
      if (this.deletedFornitura.length > 0) {
        this.deletedFornitura.map((it) => {
          this.cicloLista.map((ci) => {
            if (ci.id_ciclostd_dettaglio === it.id_ciclostd_dettaglio) {
              ci.ciclostd_dettaglio_fasi_fornitori.push(it);
            }
          });
        });
      }
      if (this.deletedMacchina.length > 0) {
        this.deletedMacchina.map((it) => {
          this.cicloLista.map((ci) => {
            if (ci.id_ciclostd_dettaglio === it.id_ciclostd_dettaglio) {
              ci.ciclostd_dettaglio_fasi_macchina.push(it);
            }
          });
        });
      }
      if (this.deletedPersonale.length > 0) {
        this.deletedPersonale.map((it) => {
          this.cicloLista.map((ci) => {
            if (ci.id_ciclostd_dettaglio === it.id_ciclostd_dettaglio) {
              ci.ciclostd_dettaglio_fasi_personale.push(it);
            }
          });
        });
      }
      if (this.$route.params.id > -1) {
        if (this.newArray.length > 0) {
          this.newArray.map((it) => {
            this.cicloLista.push(it);
          });
        }
        this.main_info_object.des_ciclo = this.des_ciclo;
        delete this.main_info_object.ciclostd_dettaglio;
        let mainData = {};
        mainData.ciclostd = [this.main_info_object];
        mainData.ciclostd_dettaglio = this.cicloLista;
        let setCicloStd = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setciclostandard",
            token: localStorage.getItem("user_token"),
            tabella: [mainData],
          },
        };
        this.$store.dispatch("api", setCicloStd).then(() => {
          this.overlay = false;
          this.snackbar = true;
          this.overlay = false;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.goBack();
        });
      } else {
        if (this.newArray.length > 0) {
          this.newArray.map((it) => {
            this.cicloLista.push(it);
          });
        }
        let data = {};
        data.id_ciclostd = -1;
        data.numero_ciclo = null;
        data.des_ciclo = this.des_ciclo;
        data.id_magazzino = null;
        data.quantita = null;
        data.data_ins = null;
        data.riferimento = null;
        data.note = null;
        data.barcode = null;
        data.id_stato = null;
        data.data_mod = null;
        data.id_utente = null;
        data.id_temp = moment().unix();
        data.validita = 1;
        let mainData = {};
        mainData.ciclostd = [data];
        mainData.ciclostd_dettaglio = this.cicloLista;
        let setCicloStd = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setciclostandard",
            token: localStorage.getItem("user_token"),
            tabella: [mainData],
          },
        };
        this.$store.dispatch("api", setCicloStd).then(() => {
          this.snackbar = true;
          this.overlay = false;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.goBack();
        });
      }
    },
    //   Go back to List page
    goBack() {
      this.$router.push({
        name: "Impostazioni",
        params: {
          comp: 4,
        },
      });
    },
    // showFasi
    delAllFasi() {
      this.newArray = this.cicloLista;
      this.newArray.map((it) => (it.validita = 0));
      this.cicloLista = [];
    },
    showFasi() {
      this.overlay = true;
      this.selected.map((it) => {
        let getArticoloStandardDettaglioFromFaseStandard = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getfasestd",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: it.id_ciclostd_dettaglio,
            // id_articolo: it.id_articolo,
          },
        };
        this.$store
          .dispatch("api", getArticoloStandardDettaglioFromFaseStandard)
          .then((res) => {
            this.overlay = false;
            this.FaseStandDetail = res.data.ciclostd_dettaglio[0];
            const i = this.cicloLista.find(
              (it) => it.cod_fase == this.FaseStandDetail.cod_fase
            );
            if (i) {
              this.FaseStandDetail.cod_fase++;
              let last_item = this.cicloLista[this.cicloLista.length - 1];
              let last_cod_fase = last_item.cod_fase;
              this.FaseStandDetail.cod_fase = last_cod_fase += 10;
              this.cicloLista.push(this.FaseStandDetail);
              this.cicloLista.sort(
                (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
              );
              return;
            } else {
              this.cicloLista.push(this.FaseStandDetail);
              this.cicloLista.sort(
                (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
              );
            }
          });
      });
      this.dialogfasestandard = false;
      this.selected = [];
    },
    // In order to get Fasi Standard
    getFaseStd() {
      this.overlay = true;
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getfasestd",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: "",
        },
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        this.overlay = false;
        const data = res.data.lista;
        this.fasiHeaders = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false,
        };
        this.fasiHeaders.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false,
        };
        this.fasiHeaders.push(data1);
        data.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        this.fasiStdLista = data;
        if (this.cicloLista.length > 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
        if (this.cicloLista.length === 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
      });
    },
    // In order to Add Fase Standard into Ciclo table
    addToCicli(item) {
      this.overlay = true;
      if (this.dialogCiclo === true) {
        let getFaseStandardDettaglio = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getfasestd",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: item.id_ciclostd_dettaglio,
            tipo_import: "",
          },
        };
        this.$store.dispatch("api", getFaseStandardDettaglio).then((res) => {
          this.overlay = false;
          this.handleClick(res.data.ciclostd_dettaglio[0]);
        });
      }
      this.dialogfasestandard1 = false;
    },
    aggiornaFase() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "FASE",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.fase_list = res.data;
        this.fase_list.map((el) => {
          this.fase_lavorazione_list.push(el.des);
        });
      });
    },
    delfasi(item) {
      if (item.id_ciclostd_dettaglio == -1) {
        let i = this.cicloLista.findIndex((it) => it == item);
        this.cicloLista.splice(i, 1);
      }
      if (item.id_ciclostd_dettaglio > 0) {
        item.validita = 0;
        this.newArray.push(item);
        let i = this.cicloLista.findIndex((it) => it == item);
        this.cicloLista.splice(i, 1);
      }
    },
    opendialogciclo() {
      this.id_temp = "";
      this.um_tempo = "";
      this.um_pezzo = "";
      this.cod_fase = "";
      this.codice = "";
      this.des1 = "";
      this.materia_prima = 0;
      this.tempo_standard = "";
      this.peso = "";
      this.des = "";
      this.fase_esterna = 0;
      this.id_ciclostd = "";
      this.dialogCiclo = true;
      this.note = "";
      this.singleitem = {};
      this.id_ciclostd_dettaglio = -1;
      this.commessa_dettaglio_fornitori = [];
      this.commessa_dettaglio_macchina = [];
      this.commessa_dettaglio_personale = [];
    },
    closeDialog() {
      this.dialogCiclo = false;
      this.codice = "";
      this.des1 = "";
      this.materia_prima = "";
      this.tempo_standard = "";
      this.des = "";
      this.fase_esterna = 0;
    },
    handleClick(value) {
      this.dialogCiclo = true;
      this.singleitem = value;
      this.commessa_dettaglio_fornitori =
        value.ciclostd_dettaglio_fasi_fornitori;
      this.id_ciclostd_dettaglio = value.id_ciclostd_dettaglio;
      this.cod_fase = this.singleitem.cod_fase;
      this.des1 = this.singleitem.des1;
      this.des = this.singleitem.des;
      this.materia_prima = this.singleitem.materia_prima;
      if (this.materia_prima == "1") {
        this.materia_prima = 1;
      }
      if (this.materia_prima == "0") {
        this.materia_prima = 0;
      }
      this.tempo_standard = this.singleitem.tempo_standard;
      this.peso = this.singleitem.peso;
      this.fase_esterna = this.singleitem.fase_esterna;
      if (this.fase_esterna == "1") {
        this.fase_esterna = 1;
      }
      if (this.fase_esterna == "0") {
        this.fase_esterna = 0;
      }
      this.id_ciclostd = this.singleitem.id_ciclostd;
      this.note = this.singleitem.note;
      this.id_temp = this.singleitem.id_temp;
      this.um_tempo = this.singleitem.um_tempo;
      this.um_pezzo = this.singleitem.um_pezzo;
      this.commessa_dettaglio_macchina = value.ciclostd_dettaglio_fasi_macchina;
      this.commessa_dettaglio_personale =
        value.ciclostd_dettaglio_fasi_personale;
    },
    cicloFasiEdit(item) {
      this.dialogFornitura = true;
      this.ragione_sociale = item.ragione_sociale;
      this.idciclo_fasi_fornitori = item.idciclo_fasi_macchina;
    },
    checkcodfase(item) {
      for (let index = 0; index < this.cicloLista.length; index++) {
        const element = this.cicloLista[index];
        if (element.cod_fase == item.cod_fase && element.id_ciclo_fasi == -1) {
          this.snackbar = true;
          this.snackbar_color = "error";
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-window-close";
          this.snackbar_text =
            "Non è possibile salvare due cicli con lo stesso codice fase";
          // this.dialogCiclo = true;
          return true;
        }
        if (
          element.cod_fase == item.cod_fase &&
          item.id_temp != element.id_temp
        ) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_color = "error";
          this.snackbar_icon = "mdi-window-close";
          this.snackbar_text =
            "Non è possibile salvare due cicli con lo stesso codice fase";
          return true;
        }
      }
      this.cicloLista &&
        this.cicloLista.forEach((it) => {
          if (it.cod_fase == item.cod_fase && it.id_ciclo_fasi == -1) {
            this.snackbar = true;
            this.snackbar_color = "error";
            this.snackbar_icon = "mdi-window-close";
            this.snackbar_text =
              "Non è possibile salvare due cicli con lo stesso codice fase";
            this.dialogCiclo = true;
            return true;
          }
          if (it.cod_fase == item.cod_fase && item.id_temp != it.id_temp) {
            this.snackbar = true;
            this.snackbar_color = "error";
            this.snackbar_icon = "mdi-window-close";
            this.snackbar_text =
              "Non è possibile salvare due cicli con lo stesso codice fase";
            return true;
          } else {
            if (!this.snackbar) {
              this.dialogCiclo = true;
            }
          }
        });
    },
    UpdateCicloFasi() {
      // Controllo che il Tipo Produzione e la Fase Lavorazione
      if (this.des1 == "" || this.des == "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Definire almeno il Tipo Produzione e la Fase Lavorazione prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }
      // Controllo che id_temp non sia valorizzato
      if (!this.singleitem.id_temp) {
        if (this.cod_fase == "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Inserire il Cod. Fase prima di salvare.";
          this.snackbar_text_color = "white";
          return;
        }
        // Se il controllo viene superato, procedo al salvataggio
        let data = {};
        data.id_ciclostd_dettaglio = -1;
        data.id_ciclostd = -1;
        data.des_fae_standard = null;
        data.data_pianificata = null;
        data.id_articolo = this.IdArticolo;
        this.produzione_list.map((prod) => {
          if (prod.des === this.des1) {
            data.id_tipo_fase = prod.id_tabella;
          }
        });
        data.materia_prima = this.materia_prima;
        data.fase_esterna = this.fase_esterna;
        data.cod_fase = this.cod_fase;
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
          }
        });
        data.peso = this.peso;
        data.lotto_produzione = null;
        data.id_macchina = null;
        data.file_inviato_macchina = null;
        data.id_attrezzatura = null;
        data.id_anagrafica = null;
        data.tempo_standard = this.tempo_standard;
        data.lead_time = null;
        data.lotto_lavorazione = null;
        data.costo_lavorazione = null;
        data.pezzi_ora_min = null;
        data.pezzi_ora_max = null;
        data.tempo_setup = null;
        if (this.um_pezzo == null || this.um_pezzo == "") {
          data.id_um_pezzo = null;
        }
        this.unita_misura_peso.map((it) => {
          if (it.des === this.um_pezzo) {
            data.id_um_pezzo = it.id_tabella;
          }
        });
        if (this.um_tempo == null || this.um_tempo == "") {
          data.id_um_tempo = null;
        }
        this.unita_misura_lead_time.map((mi) => {
          if (mi.des === this.um_tempo) {
            data.id_um_tempo = mi.id_tabella;
          }
        });
        data.note = this.note;
        data.riga_fase = null;
        data.riga = null;
        data.validita = 1;
        data.id_temp = moment().unix();
        data.des = this.des;
        data.des1 = this.des1;
        data.um_pezzo = this.um_pezzo;
        data.um_tempo = this.um_tempo;
        let val = this.checkcodfase(data);
        if (val) {
          return;
        }
        if (!this.fase_esterna) {
          var macchinawithpref = [];
          this.commessa_dettaglio_macchina.map((it) => {
            if (it.predefinito == 1) {
              macchinawithpref.push(it);
            }
          });
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una macchina predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_fornitori.map((it) => (it.predefinito = 0));
        }
        if (this.fase_esterna) {
          var fornitoriwithpref = [];
          this.commessa_dettaglio_fornitori.map((it) => {
            if (it.predefinito == 1) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una Fornitura predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_macchina.map((it) => (it.predefinito = 0));
        }
        data.ciclostd_dettaglio_fasi_personale = this.commessa_dettaglio_personale;
        data.ciclostd_dettaglio_fasi_macchina = this.commessa_dettaglio_macchina;
        data.ciclostd_dettaglio_fasi_fornitori = this.commessa_dettaglio_fornitori;
        this.dialog = false;
        if (this.snackbar) {
          return;
        }
        if (!this.snackbar) {
          this.cicloLista.push(data);
          this.dialogCiclo = false;
          this.cicloLista.sort(
            (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
          );
        }
      }
      // UPdate LOgic
      if (this.singleitem.id_temp) {
        if (this.cod_fase == "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Inserire il Cod. Fase prima di salvare.";
          this.snackbar_text_color = "white";
          return;
        }
        let data = {};
        data.id_ciclostd = this.singleitem.id_ciclostd;
        data.id_ciclostd_dettaglio = this.singleitem.id_ciclostd_dettaglio;
        data.data_pianificata = null;
        // data.id_articolo = this.IdArticolo;
        this.produzione_list.map((prod) => {
          if (prod.des === this.des1) {
            data.id_tipo_fase = prod.id_tabella;
          }
        });
        data.materia_prima = this.materia_prima;
        data.fase_esterna = this.fase_esterna;
        data.cod_fase = this.cod_fase;
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
            return data;
          }
        });
        data.peso = this.peso;
        data.lotto_produzione = null;
        data.id_macchina = null;
        data.file_inviato_macchina = null;
        data.id_attrezzatura = null;
        data.id_anagrafica = null;
        data.tempo_standard = this.tempo_standard;
        data.lead_time = null;
        data.lotto_lavorazione = null;
        data.costo_lavorazione = null;
        data.pezzi_ora_max = null;
        data.pezzi_ora_min = null;
        data.tempo_setup = null;
        if (!this.um_pezzo) {
          data.id_um_pezzo = null;
        }
        this.unita_misura_peso.map((it) => {
          if (it.des === this.um_pezzo) {
            data.id_um_pezzo = it.id_tabella;
          }
        });
        if (!this.um_tempo) {
          data.id_um_tempo = null;
        }
        this.unita_misura_lead_time.map((mi) => {
          if (mi.des === this.um_tempo) {
            data.id_um_tempo = mi.id_tabella;
          }
        });
        data.note = this.note;
        data.riga_fase = null;
        data.riga = null;
        data.validita = 1;
        data.id_temp = this.singleitem.id_temp;
        // data.id_temp_dettaglio = moment.unix();
        data.des = this.des;
        data.des1 = this.des1;
        data.um_pezzo = this.um_pezzo;
        data.um_tempo = this.um_tempo;
        let val = this.checkcodfase(data);
        if (val) {
          return;
        }
        if (this.deletedMacchina.length > 0) {
          this.$emit("delMacchina", this.deletedMacchina);
        }
        if (this.deletedPersonale.length > 0) {
          this.$emit("delPersonale", this.deletedPersonale);
        }
        if (this.deletedFornitura.length > 0) {
          this.$emit("delFornitura", this.deletedFornitura);
        }
        if (!this.fase_esterna) {
          let macchinawithpref = [];
          this.commessa_dettaglio_macchina.map((it) => {
            if (it.predefinito === 1) {
              macchinawithpref.push(it);
            }
          });
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una macchina predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          this.commessa_dettaglio_fornitori.map((it) => (it.predefinito = 0));
        }
        if (this.fase_esterna) {
          let fornitoriwithpref = [];
          this.commessa_dettaglio_fornitori.map((it) => {
            if (it.predefinito === 1) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "È necessario selezionare una fornitura predefinita.";
            this.snackbar_text_color = "white";
            return;
          }
          // this.commessa_dettaglio_macchina.map((it) => (it.predefinito = 0));
        }
        data.ciclostd_dettaglio_fasi_personale =
          this.commessa_dettaglio_personale.length === 0
            ? []
            : this.commessa_dettaglio_personale;
        data.ciclostd_dettaglio_fasi_macchina = this.commessa_dettaglio_macchina;
        data.ciclostd_dettaglio_fasi_fornitori = this.commessa_dettaglio_fornitori;
        if (!this.snackbar) {
          let i = this.cicloLista.findIndex(
            (it) => it.id_temp === data.id_temp
          );
          this.cicloLista.splice(i, 1, data);
          this.cicloLista.sort(
            (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
          );
          //   this.cicloData.commessa_dettaglio = this.cicloLista;
        }
        this.dialog = false;
        this.dialogCiclo = false;
      }
    },
  },
  mounted() {
    if (this.$route.params.id > -1) {
      this.overlay = true;
      let getCicloStd = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: this.$route.params.id,
          id_articolo: "",
          tipo_import: "",
        },
      };
      this.$store.dispatch("api", getCicloStd).then((res) => {
        this.main_info_object = res.data.ciclostd[0];
        this.cicloLista = this.main_info_object.ciclostd_dettaglio;
        this.cicloLista.sort(
          (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
        );
        this.des_ciclo = this.main_info_object.des_ciclo;
        this.overlay = false;
      });
    }
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'UNITA_MISURA','TIPO_PRODUZIONE','FASE'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array che ricevo dal BE per il porto
      // Gestisco l'array che ricevo dal BE per le unità di misura
      res.data.UNITA_MISURA.map((el) => {
        if (el.val_txt === "P") {
          this.unita_misura_peso.push(el);
        }
      });
      this.unita_misura_mod_list = res.data.UNITA_MISURA;
      this.unita_misura_mod_list.map((it) => {
        if (it.val_txt === "H") {
          this.unita_misura_lead_time.push(it);
        }
      });
      // Gestisco l'array che ricevo dal BE per il tipo produzione
      this.produzione_list = res.data.TIPO_PRODUZIONE;
      this.produzione_list.map((el) => {
        this.tipo_produzione_list.push(el.des);
      });
      // Gestisco l'array che ricevo dal BE per la fase
      this.fase_list = res.data.FASE;
      this.fase_list.map((el) => {
        this.fase_lavorazione_list.push(el.des);
      });
    });
  },
};
</script>

<style lang="scss">
.artDialog {
  overflow: hidden !important;
}
</style>
