export default class Worker {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "email", default_val: "" },
      { nome: "id_anagrafica", default_val: null },
      { nome: "id_anagrafica_contatto", default_val: -1 },
      { nome: "id_anagrafica_tipologia", default_val: "" },
      { nome: "indirizzo", default_val: "" },
      { nome: "nome_tipologia", default_val: "" },
      { nome: "nominativo", default_val: "" },
      { nome: "telefono", default_val: "" },
      { nome: "validita", default_val: 1 },
    ];

    return campi;
  }
}