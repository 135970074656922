var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"witdh":"unset"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Gestisci Causali di Fermo")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.clickChiudi}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"pa-1 mr-5",staticStyle:{"border-radius":"0px","display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"auto","item-text":"des","item-value":"id_tabella","items":_vm.fasi,"label":"Fase Lavorazione","outlined":""},model:{value:(_vm.fase_sel),callback:function ($$v) {_vm.fase_sel=$$v},expression:"fase_sel"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"mr-3"},[_c('b',[_vm._v("Causali generiche:")])]),(_vm.causali_generali.length == 0)?_c('span',[_vm._v(" (non ci sono causali generiche) ")]):_vm._e(),_vm._l((_vm.causali_generali),function(causale){return _c('span',{key:causale.id_tabella,staticClass:"mr-5"},[_vm._v(_vm._s(causale.des))])})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"mr-3"},[_c('b',[_vm._v("Causali specifiche:")])]),(_vm.causali_specifiche.length == 0)?_c('span',[_vm._v(" (non ci sono causali specifiche per questa fase) ")]):_vm._e(),_vm._l((_vm.causali_specifiche),function(causale){return _c('span',{key:causale.id_tabella,staticClass:"mr-5"},[_vm._v(_vm._s(causale.des))])})],2),_c('v-col',{attrs:{"cols":"1"}},[_c('Modules',{attrs:{"tipo":"FASE_causali","nome":"Causali Specifiche","setaction":"settabelle","getaction":"gettabelle","controller":"Magazzino","db_name":"quickproduction","addfields":",(select max(t.des) from tabella t where t.id_tabella=tabella.val_dbl) as dd","filter":'(val_int = ' + this.fase_sel + ') AND validita = 1 ',"dati":[
            { des: '' },
            { val_int: _vm.fase_sel },
            {
              categoria: [
                {
                  vmodel: 'val_dbl',
                  tipo: 'CATEGORIA_CAUSALI_FERMI',
                  nome: 'Categoria Causali Specifiche Fermi',
                  setaction: 'settabelle',
                  getaction: 'gettabelle',
                  controller: 'Magazzino',
                  filter: 'validita = 1',
                  dati: [{ des: '' }, { cod: '' }],
                } ],
            } ],"background_icon":true},on:{"dialogModules":_vm.getCausaliFermo}}),_c('Modules',{attrs:{"tipo":"FASE_causali","nome":"Causali Generiche","setaction":"settabelle","getaction":"gettabelle","controller":"Magazzino","db_name":"quickproduction","addfields":",(select max(t.des) from tabella t where t.id_tabella=tabella.val_dbl) as dd","filter":'(val_int IS null) AND validita = 1',"color":"red--text","background_icon":true,"dati":[
            { des: '' },
            {
              categoria: [
                {
                  vmodel: 'val_dbl',
                  tipo: 'CATEGORIA_CAUSALI_FERMI',
                  nome: 'Categoria Causali Generiche Fermi',
                  setaction: 'settabelle',
                  getaction: 'gettabelle',
                  controller: 'Magazzino',
                  filter: 'validita = 1',
                  dati: [{ des: '' }, { cod: '' }],
                } ],
            } ]},on:{"dialogModules":_vm.getCausaliFermo}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }