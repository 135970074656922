import CHART_COMMON from "./_common.js";
class CHART_2D_PIE {
    COMMON = new CHART_COMMON();
    options = {
        animation : false,
        legend: {
            orient: 'horizontal',
            top:10,
            right : 5,
            data: [],
            show: true,
        },
        tooltip : {
            show : true,
            formatter : function(data){
                if(data.length<=0){return;}
                let tooltip = '<div class="graph_tooltip">';
                tooltip+=   '<div><span style="background-color:'+data.color+'"></span>'+
                            '<span>'+data.name+':</span>'+
                            '<span>'+ data.data.formatted+'</span></div>';
                tooltip+='</div>';
                return tooltip;
            },
            backgroundColor : 'transparent',
        },
        series: [
            {
                name: '',
                label: {
                    position: 'outside',
                    fontSize: 15,
                    formatter: function(data){
                        return data.data.formatted +' ('+data.percent+'%)';
                    },
                    fontFamily: 'SEGOE UI'
                },
                avoidLabelOverlap : true,
                type: 'pie',
                radius: '85%',
                center: ['50%', '50%'],
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    setOptions(data){
        try{
            this.setSerie(data.serie);
            return this.options;
        }catch(error){
            return {};
        }        
    }

    setSerie(serie){
        serie = this.COMMON.filterSerie(serie);      
        for(let i=0; i < serie.length; i++){
            // No serie vuote
            if(serie[i].length <= 0){continue;}
            
            // Decodifica dei parametri extra
            serie[i].extra = this.COMMON.getExtra(serie[i]);

            // Per ogni serie viene considerato solo un elemento
            let item = this.COMMON.processSerie(serie[i]);
            this.options.series[0].data.push(item[0]);
        }
    }


    clickHandler(params) {
        return {
            seriesIndex: params.seriesIndex,
            dataIndex: params.dataIndex,
        }
    }
}

export default CHART_2D_PIE;