<template>
  <v-card>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="3" id="listener_consuntivi_massimaleMateriale">
          <!-- <v-text-field
            label="Massimale"
            v-model="massimale_tmp"
          ></v-text-field> -->
          <InputSeparatore
            :decimali_fissi="true"
            :destinazione="'listener_consuntivi_massimaleMateriale'"
            :label="'Massimale'"
            :id="'3'"
          />
        </v-col>
        <v-col cols="3" id="listener_consuntivoMateriale">
          <!-- <v-text-field
            disabled
            label="Consuntivo"
            v-model="consuntivo"
          ></v-text-field> -->
          <InputSeparatore
            :decimali_fissi="true"
            :disabled="true"
            :destinazione="'listener_consuntivoMateriale'"
            :label="'Consuntivo'"
            :id="'22'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers_materiale"
            :items="items_materiale.filter((elem) => elem.validita === 1)"
            class="elevation-1 mx-5"
          >
            <!-- Titolo tabella -->
            <template v-slot:top>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Spese per Materiali
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="aggiungiMateriale"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Materiale</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <!-- Bottone di aggiunta materiale -->
            <template v-slot:[`item.modifica`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickModificaMateriale(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Modifica</span>
              </v-tooltip>
            </template>

            <!-- Bottone di eliminazione materiale -->
            <template v-slot:[`item.elimina`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickElimina(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-close </v-icon>
                  </v-btn>
                </template>
                <span>Elimina</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="modifica_materiale_dialog" width="50%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Modifica Materiale</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaModificaMateriale"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiModificaMateriale"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-row align="center" justify="center">
            <v-col cols="8">
              <v-text-field
                label="Descrizione"
                v-model="materiale_sel.des_materiale"
                max-width="500px"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" id="listener_importoMateriale">
              <!-- <v-text-field
                label="Importo (€)"
                type="number"
                v-model="materiale_sel.costo_materiale"
                max-width="150px"
              >
              </v-text-field> -->
              <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_importoMateriale'"
                :label="'Importo (€)'"
                :id="'9'"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import moment from "moment/moment";
import InputSeparatore from "@/components/TOOLS/InputSeparatore.vue";

export default {
  name: "ConsuntiviMateriale",
  components: { InputSeparatore },
  props: {
    consuntivo_materiale: {
      type: Array,
      default: () => [],
    },
    id_consuntivo: { type: Number, default: 0 },
    massimale: { type: Number, default: 0 },
  },
  data() {
    return {
      // Variabili d'appoggio
      consuntivo: 0,
      massimale_tmp: 0,
      materiale_sel: {
        costo_materiale: 0,
        des_materiale: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_materiale: -1,
        validita: 1,
      },

      // Modal
      modifica_materiale_dialog: false,

      // Array
      headers_materiale: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Descrizione", sortable: false, value: "des_materiale" },
        { text: "Importo (€)", sortable: false, value: "costo_materiale" },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      items_materiale: [],
    };
  },
  computed: {},
  watch: {
    consuntivo_materiale: {
      immediate: true,
      handler() {
        this.items_materiale = [];
        this.consuntivo_materiale.forEach((elem) => {
          this.items_materiale.push(elem);
        });
        this.calcolaConsuntivo();
      },
    },
    consuntivo: {
      immediate: true,
      handler() {
        if (this.consuntivo != 0) {
          let messaggio = {
            detail: {
              numero: this.consuntivo,
            },
            src: null,
          };
          if (document.getElementById("listener_InputSeparatore4") == null) {
            setTimeout(() => {
              // Inserisco il messaggio in un evento custom.
              let evento = new CustomEvent("numero_iniziale", messaggio);

              // Consegno alla mia destinazione l'evento
              document
                .getElementById("listener_InputSeparatore4")
                .dispatchEvent(evento);
            }, 10);
          }
        }
      },
    },
    massimale: {
      immediate: true,
      handler() {
        this.massimale_tmp = this.massimale;
        if (this.massimale_tmp != 0) {
          let messaggio = {
            detail: {
              numero: this.massimale_tmp,
            },
            src: null,
          };
          if (document.getElementById("listener_InputSeparatore3") == null) {
            setTimeout(() => {
              // Inserisco il messaggio in un evento custom.
              let evento = new CustomEvent("numero_iniziale", messaggio);

              // Consegno alla mia destinazione l'evento
              document
                .getElementById("listener_InputSeparatore3")
                .dispatchEvent(evento);
            }, 10);
          }
        }
      },
    },
    "materiale_sel.costo_materiale": {
      immediate: true,
      handler() {
        if (
          this.materiale_sel.costo_materiale != 0 &&
          this.materiale_sel.costo_materiale != undefined
        ) {
          let messaggio = {
            detail: {
              numero: this.materiale_sel.costo_materiale,
            },
            src: null,
          };

          if (document.getElementById("listener_InputSeparatore9") == null) {
            setTimeout(() => {
              // Inserisco il messaggio in un evento custom.
              let evento = new CustomEvent("numero_iniziale", messaggio);

              // Consegno alla mia destinazione l'evento
              document
                .getElementById("listener_InputSeparatore9")
                .dispatchEvent(evento);
            }, 10);
          }
        }
      },
    },
    modifica_materiale_dialog: {
      immediate: true,
      handler() {
        if (this.modifica_materiale_dialog == true) {
          let that = this;
          setTimeout(() => {
            console.log("caricato");
            document
              .getElementById("listener_importoMateriale")
              .addEventListener("nuovo_numero", function (elem) {
                that.materiale_sel.costo_materiale = elem.detail.numero;
              });
          }, 10);
        }
      },
    },
  },
  methods: {
    aggiungiMateriale() {
      let messaggio = {
        detail: {
          numero: 0,
        },
        src: null,
      };

      setTimeout(() => {
        // Inserisco il messaggio in un evento custom.
        let evento = new CustomEvent("numero_iniziale", messaggio);

        // Consegno alla mia destinazione l'evento
        document
          .getElementById("listener_InputSeparatore9")
          .dispatchEvent(evento);
      }, 10);

      // Resetto il materiale selezionato
      let nuovo_materiale = {
        costo_materiale: 0,
        des_materiale: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_materiale: -1,
        validita: 1,
      };

      this.materiale_sel = Object.assign({}, nuovo_materiale);

      // Apro il dialog di modifica del materiale
      this.modifica_materiale_dialog = true;
    },
    calcolaConsuntivo() {
      this.consuntivo = 0;
      this.items_materiale
        .filter((elem) => elem.validita === 1)
        .forEach((elem) => {
          this.consuntivo += parseFloat(elem.costo_materiale);
        });

      let messaggio = {
        detail: {
          numero: this.consuntivo,
        },
        src: null,
      };
      if (document.getElementById("listener_InputSeparatore22") != null) {
        setTimeout(() => {
          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("numero_iniziale", messaggio);

          // Consegno alla mia destinazione l'evento
          document
            .getElementById("listener_InputSeparatore22")
            .dispatchEvent(evento);
        }, 10);
      }
    },
    chiudiModificaMateriale() {
      // Resetto il materiale selezionato
      let nuovo_materiale = {
        costo_materiale: 0,
        des_materiale: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_materiale: -1,
        validita: 1,
      };

      this.materiale_sel = Object.assign({}, nuovo_materiale);

      // Chiudo il dialog di modifica del materiale
      this.modifica_materiale_dialog = false;
    },
    clickElimina(arg) {
      this.items_materiale.forEach((mat) => {
        if (mat.id_consuntivo_materiale === arg.id_consuntivo_materiale) {
          mat.validita = 0;
        }
      });

      this.calcolaConsuntivo();
    },
    clickModificaMateriale(arg) {
      let messaggio = {
        detail: {
          numero: arg.costo_materiale,
        },
        src: null,
      };

      setTimeout(() => {
        // Inserisco il messaggio in un evento custom.
        let evento = new CustomEvent("numero_iniziale", messaggio);

        // Consegno alla mia destinazione l'evento
        document
          .getElementById("listener_InputSeparatore9")
          .dispatchEvent(evento);
      }, 10);

      // Assegno il materiale selezionato
      this.materiale_sel = Object.assign({}, arg);

      // Apro il dialog di modifica del materiale.
      this.modifica_materiale_dialog = true;
    },
    salvaModificaMateriale() {
      // Se l'oggetto è nuovo...
      if (this.materiale_sel.id_consuntivo_materiale === -1) {
        // ... Gli assegno un id semi-ufficiale...
        this.materiale_sel.id_consuntivo_materiale = -moment().valueOf();
        // ... E lo inserisco nell'array del materiale
        this.items_materiale.push(this.materiale_sel);
      } else {
        // Altrimenti apporto delle modifiche all'oggetto con id corrispondente
        this.items_materiale.forEach((mat) => {
          if (
            mat.id_consuntivo_materiale ===
            this.materiale_sel.id_consuntivo_materiale
          ) {
            mat.costo_materiale = this.materiale_sel.costo_materiale;
            mat.des_materiale = this.materiale_sel.des_materiale;
          }
        });
      }

      // Resetto il materiale selezionato
      let nuovo_materiale = {
        costo_materiale: 0,
        des_materiale: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_materiale: -1,
        validita: 1,
      };

      this.materiale_sel = Object.assign({}, nuovo_materiale);

      // Chiudo il dialog di modifica del materiale
      this.modifica_materiale_dialog = false;

      this.calcolaConsuntivo();
    },
  },
  created() {},
  mounted() {
    let that = this;
    document
      .getElementById("listener_consuntivi_massimaleMateriale")
      .addEventListener("nuovo_numero", function (elem) {
        that.massimale_tmp = elem.detail.numero;
      });
    document
      .getElementById("listener_consuntivoMateriale")
      .addEventListener("nuovo_numero", function (elem) {
        that.consuntivo = elem.detail.numero;
      });
  },
};
</script>
<style scoped>
</style>