<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-row style="margin-left: 10px">
          <v-text-field
            label="Cerca fornitore"
            outlined
            v-model="fornitore_cercato"
            dense
          >
          </v-text-field>
          <v-btn
            style="margin-left: 10px"
            @click="checkTextfield()"
            ><v-icon color="primary">mdi-magnify</v-icon></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <div v-if="dettaglio_fornitore == true">
      <v-row>
        <v-col cols="6">
          <v-row style="margin-left: 10px">
            <v-text-field
              label="Codice lotto"
              outlined
              v-model="codice_lotto"
              dense
              style="max-width: 300px"
            >
            </v-text-field>
            <v-text-field
              label="Numero pezzi"
              type="number"
              min="0"
              outlined
              v-model="numero_pezzi"
              dense
              style="max-width: 200px"
              class="ml-3"
            >
            </v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row style="margin-left: 10px">
            <v-autocomplete
              label="Scegli contenitore"
              outlined
              v-model="contenitore_scelto"
              dense
              style="max-width: 300px"
              :items="contenitori"
            >
            </v-autocomplete>
            <v-text-field
              label="Numero colli"
              type="number"
              min="0"
              outlined
              v-model="colle_scelto"
              dense
              style="max-width: 200px"
              class="ml-3"
            >
            </v-text-field>
            <v-btn
              style="margin-left: 10px"
              @click="checkTextfield()"
              ><v-icon color="primary">mdi-plus</v-icon></v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-3">
        <v-col cols="12">
          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            ><v-toolbar-title>Materiale in uscita</v-toolbar-title>
            <v-spacer></v-spacer> </v-toolbar
          ><v-data-table
            :headers="headers_uscita_materiale"
            :items="uscita_materiale"
            class="elevation-3"
          >
          </v-data-table
        ></v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialog_vedi_fornitori" width="800">
      <v-toolbar
        dark
        color="#3F51B5"
        style="border-radius: 5px 5px 0px 0px"
        class="elevation-0"
        ><v-toolbar-title>Seleziona fornitore</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn light  @click="dialog_vedi_fornitori = false"
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers_fornitori"
                :items="fornitori"
                :items-per-page="10"
              >
                <template v-slot:item.seleziona="{ item }">
                  <v-btn
                    @click="selezionaFornitore(item)"
                  >
                    <v-icon color="primary">mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
                  </v-btn>
                </template>
              </v-data-table></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contenitori: ["Grande", "Medio", "Piccolo", "contenimucche"],
      contenitore_scelto: null,
      colle_scelto: null,
      codice_lotto: null,
      numero_pezzi: null,
      dettaglio_fornitore: false,
      dialog_vedi_fornitori: false,
      fornitore_cercato: null,
      fornitore_selezionato: null,
      headers_uscita_materiale: [
        { text: "Codice lotto", value: "cod_lotto" },
        { text: "Codice articolo", value: "cod_articolo" },
        { text: "Num ordine", value: "num_ordine" },
        { text: "Data", value: "data" },
        { text: "Qta uscita", value: "qta_uscita" },
      ],
      uscita_materiale: [
        {
          cod_lotto: 5555,
          cod_articolo: 20456789,
          num_ordine: "O2020/19",
          data: "22/10/2020",
          qta_uscita: 20,
        },
      ],
      headers_fornitori: [
        {
          text: "Codice",
          value: "cod",
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
        },
        {
          text: "Località",
          value: "localita",
        },
        {
          text: "Seleziona",
          value: "seleziona",
        },
      ],
      fornitori: [
        {
          cod: 1,
          ragione_sociale: "pippo srl",
          localita: "gerusalemme",
        },
      ],
    };
  },
  methods: {
    checkTextfield() {
      if (this.fornitore_cercato == "" || this.fornitore_cercato == null) {
        this.$store.state.snackbar.stato = true;
        this.$store.state.snackbar.messaggio = "Compilare il campo di ricerca!";
        this.$store.state.snackbar.colore = "#E53935";
        this.$store.commit("close_snackbar");
      } else {
        this.dialog_vedi_fornitori = true;
      }
    },
    selezionaFornitore(item) {
      this.fornitore_cercato = item.ragione_sociale;
      this.dettaglio_fornitore = true;
      this.dialog_vedi_fornitori = false;
    },
  },
};
</script>