export default class OperatorDetail {
    constructor(arg) {
        if (arg != undefined) {
            this.id_consuntivo_personale_dettaglio = arg.id_consuntivo_personale_dettaglio != undefined ? arg.id_consuntivo_personale_dettaglio : -1;
            this.id_consuntivo_personale = arg.id_consuntivo_personale != undefined ? arg.id_consuntivo_personale : -1;
            this.fascia_oraria_inizio = arg.fascia_oraria_inizio != undefined ? arg.fascia_oraria_inizio : "";
            this.id_anagrafica_tipologia_fascia_oraria = arg.id_anagrafica_tipologia_fascia_oraria != undefined ? arg.id_anagrafica_tipologia_fascia_oraria : null;
            this.fascia_oraria_fine = arg.fascia_oraria_fine != undefined ? arg.fascia_oraria_fine : "";
            this.fascia_oraria_fine = arg.fascia_oraria_fine != undefined ? arg.fascia_oraria_fine : "";
            this.id_tipo_giorno = arg.id_tipo_giorno != undefined ? arg.id_tipo_giorno : null;
            this.des_tipo_giorno = arg.des_tipo_giorno != undefined ? arg.des_tipo_giorno : "";
            this.id_tipo_maggiorazione = arg.id_tipo_maggiorazione != undefined ? arg.id_tipo_maggiorazione : null;
            this.des_tipo_maggiorazione = arg.des_tipo_maggiorazione != undefined ? arg.des_tipo_maggiorazione : "";
            this.validita = arg.validita != undefined ? arg.validita : 1;
            this.costo = arg.costo != undefined ? arg.costo : 1;
            this.costo_effettivo = arg.costo_effettivo != undefined ? arg.costo_effettivo : 0;
            this.ore_lavorate = arg.ore_lavorate != undefined ? arg.ore_lavorate : 0;
            this.giorni = arg.giorni != undefined ? arg.giorni : [];
            this.riga = arg.riga != undefined ? arg.riga :null;
            this.giorno_lavorato = arg.giorno_lavorato != undefined ? arg.giorno_lavorato : ""
        } else {
            this.id_consuntivo_personale=-1
            this.id_consuntivo_personale_dettaglio = -1;
            this.fascia_oraria_inizio = "";
            this.fascia_oraria_fine = "";
            this.id_anagrafica_tipologia_fascia_oraria = null;
            this.fascia_oraria_fine = "";
            this.id_tipo_giorno = null;
            this.des_tipo_giorno = "";
            this.id_tipo_maggiorazione = null;
            this.des_tipo_maggiorazione = "";
            this.costo = 0;
            this.costo_effettivo =0;
            this.ore_lavorate = 0;
            this.validita = 1;
            this.riga = null;
            this.giorno_lavorato = "";
            this.giorni = [];
        }
    }
}