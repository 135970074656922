<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Modifica Elemento</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaModifica" small v-bind="attrs" v-on="on"
            ><v-icon color="success">mdi-floppy</v-icon></v-btn
          >
        </template>
        <span>Salva</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialogModifica" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text style="overflow-y: auto">
      <!-- Edit di Report Page -->
      <v-container v-if="elemento_sel_tmp.tipo === 'ReportPage'">
        <v-row>
          <v-col cols="3">
            <v-text-field label="Paper Width"></v-text-field>
          </v-col>
          <v-col cols="3" label="Paper Height">
            <v-text-field></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Font Family"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Font Size"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Report Title Band -->
      <v-container v-if="elemento_sel_tmp.tipo === 'TitleBand'">
        <v-row>
          <v-col cols="3">
            <v-text-field label="Width"></v-text-field>
          </v-col>
          <v-col cols="3" label="Height">
            <v-text-field></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Top"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Left"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Page Header Band -->
      <v-container v-if="elemento_sel_tmp.tipo === 'HeaderBand'">
        <v-row>
          <v-col cols="3">
            <v-text-field label="Width"></v-text-field>
          </v-col>
          <v-col cols="3" label="Height">
            <v-text-field></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Top"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Left"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Data Band -->
      <v-container v-if="elemento_sel_tmp.tipo === 'DataBand'">
        <v-row>
          <v-col cols="3">
            <v-text-field label="Data Source"></v-text-field>
          </v-col>
          <v-col cols="3" label="Width">
            <v-text-field></v-text-field>
          </v-col>
          <v-col cols="3" label="Height">
            <v-text-field></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Top"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Left"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Barcode Object -->
      <v-container v-if="elemento_sel_tmp.tipo === 'BarcodeObject'">
        <v-row>
          <v-col cols="3">
            <v-checkbox
              label="Allow Expression"
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="Autosize"
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Barcode"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="Barcode Calc Checksum"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Barcode Encoding"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Barcode Error Correction"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="Barcode Quiet Zone"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Text Column"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Width"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Height"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Left"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Top"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-checkbox label="Show Text"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Show Text"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Text Object -->
      <v-container v-if="elemento_sel_tmp.tipo === 'TextObject'">
        <v-row>
          <v-col cols="3">
            <v-autocomplete label="Fill"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Fill Color"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Fill Image Data"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Fill Image Width"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Fill Image Height"
              v-model="elemento_sel_tmp.fillImageHeight"
              :false-value="0"
              :true-value="1"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Fill Image Offset X"
              v-model="elemento_sel_tmp.fillImageOffsetX"
              :false-value="'0'"
              :true-value="1"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Fill Image Offset Y"
              v-model="elemento_sel_tmp.fillImageOffsetY"
              :false-value="'false'"
              :true-value="'true'"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="Fill Preserve Aspect Ratio"
              v-model="elemento_sel_tmp.fillPreserveAspectRatio"
              :false-value="0"
              :true-value="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Fill Wrap Mode"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Font Family"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Font Size"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Font Style"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Width"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Height"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Top"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Left"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Horizontal Align"></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Text"
              v-model="elemento_sel_tmp.text"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete label="Text Fill Color"></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Highlight -->
      <v-container v-if="elemento_sel_tmp.tipo === 'Highlight'">
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Condition -->
      <v-container v-if="elemento_sel_tmp.tipo === 'Condition'">
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>

      <!-- Edit di Page Footer Band -->
      <v-container v-if="elemento_sel_tmp.tipo === 'PageFooterBand'">
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    elemento_sel: { type: Object, default: () => {} },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      elemento_sel_tmp: new Object(),

      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.elemento_sel_tmp = Object.assign({}, this.elemento_sel);
        }
      },
    },
  },
  methods: {
    salvaModifica() {
     this.$emit("salvata_modifica",this.elemento_sel_tmp);
    },
    chiudiDialogModifica() {
      this.$emit("chiudi_dialog");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>