var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Part Program Sender")]),_c('v-spacer'),_c('Button',{attrs:{"colore_icona":"success","mdi_icona":"mdi-floppy","testo_tooltip":"Send Part Program"},on:{"buttonClick":_vm.sendPartProgram}}),_c('v-spacer'),_c('Button',{attrs:{"colore_icona":"error","mdi_icona":"mdi-window-close","testo_tooltip":"Cancellare"},on:{"buttonClick":function($event){return _vm.$emit('change')}}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.PPHeader,"items":_vm.PPItems},scopedSlots:_vm._u([{key:"item.default_value",fn:function(ref){
var item = ref.item;
return [_c('DynamicInputVue',{staticStyle:{"max-width":"270px"},attrs:{"type":_vm.getInputType(item.type)},model:{value:(item.default_value),callback:function ($$v) {_vm.$set(item, "default_value", $$v)},expression:"item.default_value"}})]}},{key:"item.boundaries",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.type == 2 || item.type < 13),expression:"item.type == 2 || item.type < 13"}]},[_vm._v("Min:"+_vm._s(item.boundaries.hard_min_value))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.type == 2 || item.type < 13),expression:"item.type == 2 || item.type < 13"}],staticClass:"ml-2"},[_vm._v("Max:"+_vm._s(item.boundaries.hard_max_value))])]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [(item.type == 2 || item.type < 13)?_c('v-text-field',{attrs:{"label":"Min","placeholder":"Min","type":"number"},model:{value:(item.boundaries.min_value),callback:function ($$v) {_vm.$set(item.boundaries, "min_value", $$v)},expression:"item.boundaries.min_value"}}):_vm._e()]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [(item.type == 2 || item.type < 13)?_c('v-text-field',{attrs:{"label":"Max","placeholder":"Max","type":"number"},model:{value:(item.boundaries.max_value),callback:function ($$v) {_vm.$set(item.boundaries, "max_value", $$v)},expression:"item.boundaries.max_value"}}):_vm._e()]}},{key:"item.access_type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.access_lista,"item-text":"name","item-value":"id"},model:{value:(item.access_type),callback:function ($$v) {_vm.$set(item, "access_type", $$v)},expression:"item.access_type"}})]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","width":"400"},model:{value:(_vm.alert.mostra),callback:function ($$v) {_vm.$set(_vm.alert, "mostra", $$v)},expression:"alert.mostra"}},[_c('v-card',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.alert.icona))])],1),_c('v-col',{attrs:{"cols":"11"}},[_c('h3',{staticStyle:{"word-break":"keep-all","text-align":"left","padding":"0 20px"}},[_vm._v(" "+_vm._s(_vm.alert.messaggio)+" ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.alert.mostra = false}}},[_vm._v("Ok")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }