<template>
  <div>
    <v-container fluid class="my-0 py-0">
      <v-card class="mt-3" :elevation="8">
        <TabellaRichieste
          :allow_cod_richiesta_custom="true"
          :allow_doppia_descrizione="true"
          :allow_update_ambiti_manutentivi_custom_dg_marine="true"
          :bacheca_intervento_visible="bacheca_intervento_visible"
          :bacheca_richiesta_visible="bacheca_richiesta_visible"
          :back_as_landing_page="true"
          :checkbox="'interno'"
          :checkbox_default_val="false"
          :cod_filtro_sede="'NAVE'"
          :concessionari_visible="true"
          :config_area="config_area"
          :config_macchinario="config_macchinario"
          :config_modello="config_modello"
          :config_sede="config_sede"
          :config_stanza="config_stanza"
          :config_stato="config_stato"
          :config_stato_dettaglio="config_stato_dettaglio"
          :config_struttura="config_struttura"
          :config_zona="config_zona"
          :dettaglio_step_visible="dettaglio_step_visible"
          :get_sedi_from_macchine="true"
          :go_back_visible="true"
          :headers_richieste_scelti="headers_richieste_scelti"
          :headers_interventi_scelti="headers_interventi_scelti"
          :icona_gestione_sedi_fornitori="'mdi-ferry'"
          :IsDgMarine="true"
          :preventivo_visible="true"
          :rotta_back="'/home/naval'"
          :show_invio_email="false"
          :stato_richiesta_visible="true"
          :stato_richiesta_2_visible="true"
          :tempo_stimato_visible="true"
          :tipo_getfornitori="tipo_getfornitori"
          :titolo_intervento_visible="true"
          :toolbar_style="'digitalmarine'"
          urgenza_richiesta_default_val="LOW"
        />
      </v-card>
    </v-container>
  </div>
</template>
<script>
import TabellaRichieste from "@/components/MANUTENZIONE/TabellaRichieste.vue";

export default {
  name: "GestioneSintetica",
  props: [],
  components: {
    TabellaRichieste,
  },
  data() {
    return {
      // Variabili d'appoggio

      bacheca_intervento_visible: true,
      bacheca_richiesta_visible: false,
      config_area: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      config_macchinario: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      config_modello: {
        label: {
          singolare: "Modello",
          plurale: "Modelli",
        },
        visibility: true,
      },
      config_sede: {
        label: { singolare: "Barca", plurale: "Barche" },
        visibility: true,
      },
      config_stanza: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      config_stato: {
        label: { singolare: "Stato", plurale: "Stati" },
        visibility: true,
      },
      config_stato_dettaglio: {
        label: { singolare: "Stato dettaglio", plurale: "Stati dettagli" },
        visibility: true,
      },
      config_struttura: {
        label: { singolare: "Zona Barca", plurale: "Zone Barche" },
        visibility: true,
      },
      config_zona: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      dettaglio_step_visible: false,
      tipo_getfornitori: "digitalmarine",
      // Modal

      // Array
      headers_interventi_scelti: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Titolo",
          sortable: false,
          value: "des_intervento",
        },
        {
          text: "Consuntivi",
          sortable: false,
          value: "consuntivi",
        },
        {
          text: "N° Attività",
          sortable: false,
          value: "attivita",
        },
        {
          text: "Cod. Intervento",
          sortable: false,
          value: "cod_intervento",
        },
        {
          text: "Ambito Manutentivo",
          sortable: false,
          value: "des_tipo_intervento",
        },
        {
          text: "Costo Stimato (€)",
          sortable: false,
          value: "costo_stimato",
        },
        {
          text: "Tipo Manutenzione",
          value: "des_tipo_manutenzione",
          sortable: false,
        },
        {
          text: "Data Prevista",
          value: "data_prevista",
          sortable: false,
        },
        {
          text: "Data Schedulata",
          value: "data_schedulata",
          sortable: false,
        },
        {
          text: "Stato Operazioni",
          value: "des_stato_operativo",
          sortable: false,
        },
      ],
      headers_richieste_scelti: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: true,
        },
        {
          text: "N. Claim",
          value: "cod_richiesta_custom",
          sortable: true,
        },
        {
          text: "Barca",
          value: "des_sede",
          sortable: true,
        },
        {
          text: "Zona Barca",
          value: "des_struttura",
          sortable: true,
        },
        {
          text: "Descrizione",
          value: "descrizione",
          sortable: true,
        },
        {
          text: "Stato",
          value: "des_stato",
          sortable: false,
          align: "center",
        },
        {
          text: "Stato Dettaglio",
          value: "des_stato_2",
          sortable: false,
          align: "center",
        },
        {
          text: "Data Richiesta",
          value: "data_richiesta",
          sortable: true,
        },
      ],
    };
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
