<template>
  <div class="pt-8 px-5">
    <ManutenzioneGestioneSediFornitori
      :back_as_landing_page="true"
      :checkbox_strutture_standard="true"
      :cod_filtro_sede="cod_filtro_sede"
      :config_sede="config_sede"
      :config_struttura="config_struttura"
      :config_area="config_area"
      :config_zona="config_zona"
      :config_stanza="config_stanza"
      :config_macchinario="config_macchinario"
      :config_modello="config_modello"
      :get_sedi_from_macchine="true"
      :rotta_back="'/home/naval'"
      :titolo_tabella_principale="'Lista Barche'"
    />
  </div>
</template>
<script>
import ManutenzioneGestioneSediFornitori from "@/components/MANUTENZIONE/ManutenzioneGestioneSediFornitori.vue";
export default {
  name: "DGGestioneLF",
  components: { ManutenzioneGestioneSediFornitori },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      cod_filtro_sede: 'NAVE',
      config_area: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      config_macchinario: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      config_modello: {
        label: {
          singolare: "Modello",
          plurale: "Modelli",
        },
        visibility: true,
      },
      config_sede: {
        label: { singolare: "Barca", plurale: "Barche" },
        visibility: true,
      },
      config_stanza: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      config_struttura: {
        label: { singolare: "Zona Barca", plurale: "Zone Barche" },
        visibility: true,
      },
      config_zona: {
        label: { singolare: "", plurale: "" },
        visibility: false,
      },
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>