<template>
  <div id="listener_carrello2" style="font-size: 20px; text-align: center">
    <v-card v-if="items_carrello.length == 0">
      <v-toolbar class="white--text" color="indigo" dense>
        <v-toolbar-title>Work Orders in Gestione</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
              <v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Vai alla Dashboard</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="mt-5" style="font-size: 20px; line-height: 20px"
        >Ciao {{ nome_utente }}, la lista dei Work Order è vuota: ne vuoi
        aggiungere uno?</v-card-text
      >
      <v-divider></v-divider>
      <!-- Bottone per aggiungere una commessa singola -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5 my-2"
            @click="clickAggiungi"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Work Order</span>
      </v-tooltip>

      <!-- Bottone per aggiungere multiregistrazione -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="my-2"
            @click="clickAggiungiMultiregistrazione"
            v-bind="attrs"
            v-if="multicommessa_visible === true"
            v-on="on"
          >
            <v-icon color="primary">mdi-inbox-multiple</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Multiregistrazione</span>
      </v-tooltip>
    </v-card>

    <v-data-table
      class="elevation-1"
      @click:row="clickCarrelloRow"
      :headers="headers_carrello"
      :items="items_carrello"
      v-if="items_carrello.length > 0"
      :item-class="evidenziaRitardo"
      style="font-size: 20px !important"
    >
      <!-- Titolo della tabella -->
      <template v-slot:top>
        <v-toolbar rounded class="white--text" color="indigo" dense>
          <v-toolbar-title>Work Orders in Gestione</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Bottone per aggiungere le commesse -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickAggiungi"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Work Order</span>
          </v-tooltip>

          <!-- Bottone per aggiungere multiregistrazione -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="primary--text"
                @click="clickAggiungiMultiregistrazione"
                small
                v-bind="attrs"
                v-if="multicommessa_visible === true"
                v-on="on"
              >
                <v-icon>mdi-inbox-multiple</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Multiregistrazione</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Vai alla Dashboard</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <!-- Header start/stop -->
      <template v-slot:[`header.start_stop`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header registra pezzi -->
      <template v-slot:[`header.registra`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header Dettagli -->
      <template v-slot:[`header.visualizza_dettagli`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header Allegato Controllo -->
      <template v-slot:[`header.visualizza_allegato_controllo`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header cod. articolo -->
      <template v-slot:[`header.cod_articolo`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header cod. commessa -->
      <template v-slot:[`header.cod_commessa`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header work order -->
      <template v-slot:[`header.cod_fase`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header cod. macchina -->
      <template v-slot:[`header.cod_macchina`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header lavorazione -->
      <template v-slot:[`header.des_fase`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header quantità totale -->
      <template v-slot:[`header.quantita`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header quantità prodotta -->
      <template v-slot:[`header.pezzi_registrati`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header quantità precedente -->
      <template v-slot:[`header.qta_precedente`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header ora start -->
      <template v-slot:[`header.ora_start`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header ora stop -->
      <template v-slot:[`header.ora_stop`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header ore registrate -->
      <template v-slot:[`header.ore_produzione`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header qta registrata -->
      <template v-slot:[`header.pezzi_produzione`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header produzione reale -->
      <template v-slot:[`header.pzhreale`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header tempo ciclo -->
      <template v-slot:[`header.tempo_ciclo`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Header tempo ciclo -->
      <template v-slot:[`header.nascondi`]="{ header }">
        <span style="font-size: 20px; font-weight: normal">{{
          header.text
        }}</span>
      </template>

      <!-- Pulsante per la registrazione dei tempi -->
      <template v-slot:[`item.start_stop`]="{ item }">
        <v-btn
          class="my-3"
          @click="switchStartStop(item)"
          :color="
            item.ora_start == null ||
            (item.ora_start != null && item.ora_stop != null)
              ? 'success'
              : 'error'
          "
          dark
          style="height: 80px"
          tile
          x-large
        >
          <v-icon style="font-size: 40px">
            {{
              item.ora_start == null ||
              (item.ora_start != null && item.ora_stop != null)
                ? "mdi-play"
                : "mdi-stop"
            }}
          </v-icon>
        </v-btn>
      </template>

      <!-- Pulsante per la registrazione dei pezzi -->
      <template v-slot:[`item.registra`]="{ item }">
        <v-btn
          class="my-3"
          @click="clickRegistraPezzi(item)"
          color="primary"
          dark
          style="height: 80px"
          x-large
          v-if="item.ora_start != null"
          >DICHIARA</v-btn
        >
      </template>

      <!-- Pulsante per la visualizzazione dei dettagli -->
      <template v-slot:[`item.visualizza_dettagli`]="{ item }">
        <v-btn
          class="my-3"
          @click="clickVisualizzaDisegno(item)"
          :color="
            item.id_disegno == null
              ? 'grey lighten-5'
              : item.view_disegno == true
              ? 'primary'
              : 'error'
          "
          dark
          style="height: 80px"
          x-large
        >
          <v-icon :color="item.id_disegno == null ? 'black' : 'white'">
            mdi-eye
          </v-icon>
        </v-btn>
      </template>

      <!-- Pulsante per la visualizzazione dell'allegato del controllo -->
      <template v-slot:[`item.visualizza_allegato_controllo`]="{ item }">
        <v-btn
          class="my-3"
          @click="clickVisualizzaAllegatoControllo(item)"
          :color="item.check_allegato == 0 ? 'grey lighten-5' : 'primary'"
          dark
          style="height: 80px"
          x-large
        >
          <v-icon :color="item.check_allegato == 0 ? 'black' : 'white'">
            mdi-check-decagram
          </v-icon>
        </v-btn>
      </template>

      <!-- Colonna cod. articolo -->
      <template v-slot:[`item.cod_articolo`]="{ item }">
        <span style="font-size: 20px">{{ item.cod_articolo }}</span>
      </template>

      <!-- Colonna cod. commessa -->
      <template v-slot:[`item.cod_commessa`]="{ item }">
        <span style="font-size: 20px">{{ item.cod_commessa }}</span>
      </template>

      <!-- Colonna work order -->
      <template v-slot:[`item.cod_fase`]="{ item }">
        <span style="font-size: 20px">{{ item.cod_fase }}</span>
      </template>

      <!-- Colonna cod. macchina -->
      <template v-slot:[`item.cod_macchina`]="{ item }">
        <span style="font-size: 20px">{{ item.cod_macchina }}</span>
      </template>

      <!-- Colonna quantità totale -->
      <template v-slot:[`item.quantita`]="{ item }">
        <span style="font-size: 20px">{{ item.quantita }}</span>
      </template>

      <!-- Colonna pezzi prodotti -->
      <template v-slot:[`item.pezzi_registrati`]="{ item }">
        <span style="font-size: 20px">{{ item.pezzi_registrati }}</span>
      </template>

      <!-- Colonna pezzi fase precedente -->
      <template v-slot:[`item.qta_precedente`]="{ item }">
        <span style="font-size: 20px">{{ item.qta_precedente }}</span>
      </template>

      <!-- Colonna lavorazione -->
      <template v-slot:[`item.des_fase`]="{ item }">
        <span style="font-size: 20px">{{ item.des_fase }}</span>
      </template>

      <!-- Colonna ora inizio -->
      <template v-slot:[`item.ora_start`]="{ item }">
        <span style="font-size: 20px">{{ item.ora_start }}</span>
      </template>

      <!-- Colonna ora fine -->
      <template v-slot:[`item.ora_stop`]="{ item }">
        <span style="font-size: 20px">{{ item.ora_stop }}</span>
      </template>

      <!-- Colonna ora registrazione -->
      <template v-slot:[`item.ore_produzione`]="{ item }">
        <span style="font-size: 20px">{{ item.ore_produzione }}</span>
      </template>

      <!-- Colonna qta registrata -->
      <template v-slot:[`item.pezzi_produzione`]="{ item }">
        <span style="font-size: 20px">{{ item.pezzi_produzione }}</span>
      </template>

      <!-- Colonna produzione reale -->
      <template v-slot:[`item.pzhreale`]="{ item }">
        <span style="font-size: 20px">{{ item.pzhreale }}</span>
      </template>

      <!-- Colonna tempo ciclo -->
      <template v-slot:[`item.tempo_ciclo`]="{ item }">
        <span style="font-size: 20px">{{
          formattaCifra(item.tempo_ciclo)
        }}</span>
      </template>

      <!-- Colonna di nascondimento -->
      <template v-slot:[`item.nascondi`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="clickNascondi(item)" small v-bind="attrs" v-on="on">
              <v-icon color="error"> mdi-eye-off </v-icon>
            </v-btn>
          </template>
          <span>Nascondi</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import * as moment from "moment/moment";

export default {
  name: "Carrello2",
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    headers_carrello: {
      type: Array,
      default: () => [],
    },
    items_carrello: { type: Array, default: () => [] },
    multicommessa_visible: {
      type: Boolean,
      default: true,
    },
    rotta_back: {
      type: String,
      default: "/home/production",
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      nome_utente: localStorage.getItem("nominativo"),

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      stato: true,
      // Array
    };
  },
  methods: {
    gotoDashboard() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        /* Emetto un evento per scatenare nel padre
         * la gestione dell'evento click indietro. */
        this.$emit("click_indietro");
      }
    },
    clickAggiungi() {
      this.$emit("click_aggiungi");
    },
    clickAggiungiMultiregistrazione() {
      this.$emit("click_aggiungi_multiregistrazione");
    },
    clickCarrelloRow(item) {
      this.$emit("click_row", item);
    },
    clickNascondi(item) {
      this.$emit("click_nascondi", item);
    },
    clickRegistraTempi(item) {
      this.$emit("click_start_stop", item);
    },
    clickPrelievi(item) {
      this.$emit("click_prelievi", item);
    },
    clickRegistraPezzi(item) {
      if (item.view_disegno != null) {
        this.$emit("click_registra_pezzi", item);
      } else {
        // Se non ho visto il disegno, stampo una snackbar di errore.
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Visionare nuova modifica di disegno prima di dichiarare.";
        this.snackbar_text_color = "white";
      }
    },
    clickVisualizzaAllegatoControllo(item) {
      this.$emit("click_visualizza_allegto_controllo", item);
    },
    clickVisualizzaDisegno(item) {
      /* Controllo che ci siano disegni da
       * visualizzare (me ne accorgo perché se
       * non ci sono id_disegno == null). */
      if (item.id_disegno == null) {
        // Se non ci sono disegni lancio una snackber di errore
        this.snackbar = true;
        this.snackbar_background = "grey lighten-5";
        this.snackbar_icon = "mdi-information";
        this.snackbar_icon_color = "indigo";
        this.snackbar_text = "Non ci sono disegni da visualizzare.";
        this.snackbar_text_color = "black";
      } else {
        /* Se ci sono disegni emetto normalmente l'evento,
         * così il componente padre apre il dialog dei disegni. */
        this.$emit("click_visualizza_disegno", item);
      }
    },
    evidenziaRitardo(item) {
      return item.pzhreale < item.pzh ? "evidenziato" : "";
    },
    formattaCifra(amount) {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        // Sostituisco la virgola al punto eventualmente presente.
        amount = amount.toString().replace(".", ",");
        // Se non c'è una virgola la aggiungo e le metto in coda due 0.
        if (amount.indexOf(",") === -1) {
          amount = amount + ",00";
        }

        if (amount.indexOf(",") < amount.length - 3) {
          /* Se c'è una virgola prima della terzultima posizione accorcio la
           * stringa in modo da renderla il terzultimo carattere della stringa. */
          let i = amount.indexOf(",");
          amount = amount.slice(0, i + 4);
        } else {
          if (amount.split(",")[1].length == 1) {
            amount += "0";
          }
        }
        // Aggiungo un punto ogni 3 posti.
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return amount;
      }
    },
    /*primaCommessa() {
      this.$emit("prima_commessa");
    },*/
    setCommessaTempi(commessa, start_stop) {
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessatempi",
          token: localStorage.getItem("user_token"),
          id_commessa: commessa.id_commessa,
          id_commessa_dettaglio: commessa.id_commessa_dettaglio,
          id_macchina: commessa.id_macchina,
          start: start_stop,
          id_causale: 55,
        },
      };

      this.$store.dispatch("api", request).then(() => {
        //console.log("setcommessatempi response: ", JSON.stringify(res));
        if (start_stop == 0) {
          this.$emit("click_stop", commessa);
        } else {
          this.$emit("click_start");
        }
      });
    },
    switchStartStop(elem) {
      if (
        elem.ora_start == null ||
        (elem.ora_start != null && elem.ora_stop != null)
      ) {
        // Se ho iniziato a lavorare, controllo di avere visto il disegno
        if (elem.view_disegno == 1 || elem.id_disegno == null) {
          // Se l'ho visto, stabilisco che ora è
          let adesso = moment();
          let orario_attivazione =
            adesso.date() +
            "/" +
            ("0" + (adesso.month() + 1)).substr(-2) +
            "/" +
            adesso.year() +
            " " +
            ("0" + adesso.hour()).substr(-2) +
            ":" +
            ("0" + adesso.minutes()).substr(-2);

          // Aggiorno il work order
          elem.ora_start = orario_attivazione;
          elem.ora_stop = null;

          // Mando al backend l'aggiornamento
          this.setCommessaTempi(elem, "1");
        } else {
          // Se non ho visto il disegno, stampo una snackbar di errore.
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Visionare nuova modifica di disegno prima di avviare il work order.";
          this.snackbar_text_color = "white";
        }
      } else {
        // Se ho finito di lavorare, stabilisco che ora è
        let adesso = moment();
        let orario_attivazione =
          adesso.date() +
          "/" +
          ("0" + (adesso.month() + 1)).substr(-2) +
          "/" +
          adesso.year() +
          " " +
          ("0" + adesso.hour()).substr(-2) +
          ":" +
          ("0" + adesso.minutes()).substr(-2);

        // Aggiorno l'oggetto
        elem.ora_stop = orario_attivazione;

        // Mando al backend l'aggiornamento
        this.setCommessaTempi(elem, "0");

        // Riporto i campi di start e stop a null
        elem.ora_start = null;
        elem.ora_stop = null;
      }
    },
  },
  mounted() {
    // Preparo i listener.
    document
      .getElementById("listener_carrello2")
      .addEventListener("cambiata_macchina", this.cambiaMacchina);

    document
      .getElementById("listener_carrello2")
      .addEventListener("prelievo_registrato", this.aggiornaPrelievi);
  },
  beforeDestroy() {
    if (document.getElementById("listener_carrello2")) {
      document
        .getElementById("listener_carrello2")
        .removeEventListener("clicked_row", this.aggiungiWorkOrder);

      document
        .getElementById("listener_carrello2")
        .removeEventListener("prelievo_registrato", this.aggiornaPrelievi);
    }
  },
};
</script>
<style>
.evidenziato {
  background-color: pink;
}
</style>