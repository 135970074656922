class SLIDES{
    DATA = [];

    RATIOS = [{name:'2:1',perc:'50%'},{name:'16:9',perc:'56.25%'},{name:'3:4',perc:'75%'},{name:'1:1',perc:'100%'}];
    selected_ratio = '2:1';    
    set_timer = 0;
    footer_show = null;
    slideshow = false;
    update_interval = null;
    cached_nodes = [];
    slideshow_interval = null;

    grabbing = null;
    grabbing_margin = 0;
    grabbing_ghost = null;

    setBackground(background){
        let body = document.getElementsByClassName('graph_slides')[0];
        let figure = body.getElementsByTagName('figure')[0].children[0];

        if(background.charAt(0)=='#'||background.indexOf('rgb')==0){
            body.style.backgroundColor = background;
            figure.style.backgroundColor = background;
        }else if(background.indexOf('url')>=0){
            body.style.backgroundImage = background;
            figure.style.backgroundImage = background;            
        }
    }
    chartUpdate(options,target){
        let update_event = new CustomEvent('graph_update',{detail:options});
        document.getElementById('chart-'+target).dispatchEvent(update_event);
    }
    chartResize(target){
        let resize_event = new CustomEvent('graph_resize',{detail:null});
        document.getElementById('chart-'+target).dispatchEvent(resize_event);
    }
    chartResizeAll(){for(let i=0;i<this.data.length;i++){this.chartResize(i);}}
    setRatio(ratio){
        document.getElementsByClassName('graph_slides')[0].style.setProperty('--graph_slide_aspect_ratio',ratio.perc);
        this.chartResizeAll();
        this.selected_ratio = ratio.name;
    }    
    setSlideshow(){
        this.slideshow=true;
        this.footer_show=null;
        let slideshow = document.getElementsByClassName('graph_slideshow')[0];
        let main = slideshow.getElementsByTagName('main')[0];
        let data = slideshow.getElementsByTagName('data')[0];
        let slides = document.getElementById('slides_main').children;
        let slidenum = slides.length;
        let that = this;

        requestAnimationFrame(function(){
            for(let i=0;i<slidenum;i++){
                let slide = slides[0];
                slide.parentElement.removeChild(slide);
                that.cached_nodes.push(slide.id);
                if(i==0){main.appendChild(slide);}else{data.appendChild(slide);}
            }
        });
        requestAnimationFrame(function(){that.chartResizeAll()});
        slideshow.requestFullscreen();

        // Attesa fullscreen
        function waitForFullscreen(){
            if(document.fullscreenElement){
                document.body.addEventListener('fullscreenchange',that.unsetSlideshowOnEvent);
            }else{
                requestAnimationFrame(waitForFullscreen);
            }  
        }
        requestAnimationFrame(waitForFullscreen);       
    }
    slideForward(){        
        let slideshow = document.getElementsByClassName('graph_slideshow')[0];
        let main = slideshow.getElementsByTagName('main')[0];
        let data = slideshow.getElementsByTagName('data')[0];
        if(data.length<=0){return;}
        let o_slide = main.children[0];
        let n_slide = data.children[0];
        requestAnimationFrame(function(){
            main.removeChild(o_slide);
            data.removeChild(n_slide);
            data.appendChild(o_slide);
            main.appendChild(n_slide);
        });
        
    }
    slideBackwards(){
        let slideshow = document.getElementsByClassName('graph_slideshow')[0];
        let main = slideshow.getElementsByTagName('main')[0];
        let data = slideshow.getElementsByTagName('data')[0];
        if(data.length<=0){return;}
        let o_slide = main.children[0];
        let n_slide = data.lastElementChild;
        requestAnimationFrame(function(){
            main.removeChild(o_slide);
            data.removeChild(n_slide);
            data.insertBefore(o_slide,data.firstElementChild);
            main.appendChild(n_slide);
        });
    }
    playSlideshow(){
        if(Number(this.set_timer)<=0){return;}
        this.slideshow_interval = setInterval(this.slideForward,this.set_timer*1000);
    }
    pauseSlideshow(){
        clearInterval(this.slideshow_interval);
        this.slideshow_interval = null;
    }
    // Metodo di appoggio per uscire dallo schermo intero quando l'utente preme esc (o simili)
    unsetSlideshowOnEvent(){
        let closeButton = document.getElementById("close_slideshow");
        if(closeButton !=undefined){
            closeButton.dispatchEvent(new Event('click'));
        }
    }
    unsetSlideshow(){    
        document.body.removeEventListener('fullscreenchange',this.unsetSlideshowOnEvent);     
        this.pauseSlideshow();   
        let slides_main = document.getElementById('slides_main');
        let slideshow = document.getElementsByClassName('graph_slideshow')[0];
        let that = this;
        requestAnimationFrame(function(){
            for(let i=0;i<that.cached_nodes.length;i++){
                let slide = document.getElementById(that.cached_nodes[i]);
                slide.parentElement.removeChild(slide);
                slides_main.appendChild(slide);
            }
            slideshow.getElementsByTagName('main')[0].innerHTML = '';
            slideshow.getElementsByTagName('data')[0].innerHTML = ''; 
            that.cached_nodes = [];           
        });   
        requestAnimationFrame(function(){that.chartResizeAll()});
        this.slideshow=false;
        if(document.fullscreenElement){document.exitFullscreen();}        
    }
    grabStart(event){
        this.grabbing = event.target;
        let comp = window.getComputedStyle(this.grabbing);
        let margin = comp.getPropertyValue('margin-top');
        if(margin==''||margin==null||margin==undefined){margin=comp.getPropertyValue('margin');}

        this.grabbing_margin = Number(margin.split(' ')[0].replace('px',''));
        this.grabbing.style.marginLeft = '5%';
        this.grabbing.style.top = this.grabbing.offsetTop+'px';
        this.grabbing.style.position='absolute';
        this.grabbing.style.zIndex = '2';

        this.grabbing_ghost = document.createElement('div');
        this.grabbing_ghost.setAttribute('data-ghost','true');
        this.grabbing_ghost.setAttribute('data-target',this.grabbing.id.replace('nav','main'));
        this.grabbing.parentElement.insertBefore(this.grabbing_ghost,this.grabbing);

        this.move_offset_Y = event.clientY - this.grabbing.getBoundingClientRect().y;
    }
    grabMove(event){
        if(this.grabbing==null||this.grabbing==undefined){return;}
        let parent = this.grabbing.parentElement;
        let delta = event.clientY - parent.getBoundingClientRect().y + parent.scrollTop - this.move_offset_Y;

        if(delta > 0){
            this.grabbing.style.top = delta+'px';
        }
         
        
        
        if(this.grabbing.offsetTop>(this.grabbing_ghost.offsetTop+(this.grabbing_ghost.offsetHeight))){
            let next= this.grabbing_ghost.nextElementSibling;
            if(next==this.grabbing){next=next.nextElementSibling;}            
            if(next!=null&&next!=undefined){
                next=next.nextElementSibling;
                let target = document.getElementById(this.grabbing_ghost.getAttribute('data-target'));
                let t_next = target.nextElementSibling.nextElementSibling;
                let t_parent = target.parentElement;
                if(next!=null&&next!=undefined){
                    parent.removeChild(this.grabbing_ghost);
                    parent.insertBefore(this.grabbing_ghost,next);
                    t_parent.removeChild(target);
                    t_parent.insertBefore(target,t_next);
                }else{
                    parent.removeChild(this.grabbing_ghost);
                    parent.appendChild(this.grabbing_ghost);
                    t_parent.removeChild(target);
                    t_parent.appendChild(target);
                }
            }
        }else if((this.grabbing.offsetTop+this.grabbing.offsetHeight)<this.grabbing_ghost.offsetTop){
            let prev = this.grabbing_ghost.previousElementSibling;
            if(prev==this.grabbing){prev=prev.previousElementSibling;}
            if(prev!=null&&prev!=undefined){                
                parent.removeChild(this.grabbing_ghost);
                parent.insertBefore(this.grabbing_ghost,prev);
                let target = document.getElementById(this.grabbing_ghost.getAttribute('data-target'));
                let t_prev = target.previousElementSibling;
                let t_parent = target.parentElement;
                t_parent.removeChild(target);
                t_parent.insertBefore(target,t_prev);
            }
        }
    }
    grabEnd(){
        let parent = this.grabbing.parentElement;
        this.grabbing.style.position='relative';
        this.grabbing.style.top='0';
        this.grabbing.style.margin=this.grabbing_margin+'px auto';
        parent.removeChild(this.grabbing);
        parent.insertBefore(this.grabbing,this.grabbing_ghost);
        parent.removeChild(this.grabbing_ghost);
        this.grabbing.style.zIndex = '';
        this.grabbing=null;      
    }

}
export default SLIDES;