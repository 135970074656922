<template>
  <v-app id="geo_listener">
    <v-card class="pa-2">
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text">Geolocalizzazione</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="goToTabellaMezzi" small v-bind="attrs" v-on="on"
              ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
            >
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>

      <GmapMap
        ref="mapRef"
        name="d"
        :center="center"
        :zoom="13"
        style="width: 100%; height: 70vh"
      >
        <GmapMarker
          :key="index"
          :label="{
            text: m.data.label,
            color: 'black',
            fontSize: '10px',
            fontWeight: 'bold',
            background: 'black',
          }"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :bounds="true"
          :draggable="true"
          @click="openInfoWindow(m)"
          :icon="
            setMarkerIcon(
              (m.data.des_tipo == 'AUTOCISTERNA'
                ? m.data.des_tipo
                : 'mezzo' + m.id_stato
              ).toString()
            )
          "
        />

        <GmapInfoWindow
          v-if="selectedMarker"
          :position="{
            lat: selectedMarker.position.lat,
            lng: selectedMarker.position.lng,
          }"
          @closeclick="closeInfoWindow()"
        >
          <v-card class="mt-2">
            <v-badge
              bordered
              color="error"
              :content="selectedMarker.data.badge"
              overlap
            >
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Targa: {{ selectedMarker.data.targa }}
                </v-toolbar-title>
              </v-toolbar>
            </v-badge>
            <div class="container">
              <div class="infoWindow">
                <h3 class="infoWindowData">
                  Tipologia: {{ selectedMarker.data.des_tipo }}
                </h3>
                <h3 class="infoWindowData">
                  Modello: {{ selectedMarker.data.des_mezzo }}
                </h3>
                <h3 class="infoWindowData">
                  Coordinate:
                  <h4>{{ selectedMarker.position.lat }}</h4>
                  <h4>{{ selectedMarker.position.lng }}</h4>
                </h3>
              </div>
              <div>
                <v-icon color="primary">mdi-car-cruise-control</v-icon>
                <div class="bar">
                  <div
                    :style="{
                      position: 'absolute',
                      bottom: 0,
                      borderRadius: 12 + 'px',
                      border: 1 + 'px solid black',
                      height:
                        (selectedMarker.data.carburante_attuale * 120) /
                          selectedMarker.data.serbatoio +
                        'px',
                      width: 100 + '%',
                      backgroundImage:
                        selectedMarker.data.stato === 0
                          ? `linear-gradient(
                              90deg,
                               hsl(20, 100%, 50%),
                               hsl(${
                                 (selectedMarker.data.carburante_attuale *
                                   120) /
                                 selectedMarker.data.serbatoio
                               }, 100%, 50%),
                               hsl(20, 100%, 50%)
                              )`
                          : selectedMarker.data.stato === 1
                          ? `linear-gradient(
                              90deg,
                              hsl(${
                                (selectedMarker.data.carburante_attuale * 120) /
                                  selectedMarker.data.serbatoio -
                                20
                              }, 100%, 50%),
                              hsl(${
                                (selectedMarker.data.carburante_attuale * 120) /
                                selectedMarker.data.serbatoio
                              }, 100%, 50%),
                              hsl(${
                                (selectedMarker.data.carburante_attuale * 120) /
                                  selectedMarker.data.serbatoio -
                                20
                              }, 100%, 50%)
                              )`
                          : `linear-gradient(
                              90deg,
                              hsl(${
                                (selectedMarker.data.carburante_attuale * 120) /
                                  selectedMarker.data.serbatoio -
                                20
                              }, 100%, 50%),
                              hsl(${
                                (selectedMarker.data.carburante_attuale * 120) /
                                selectedMarker.data.serbatoio
                              }, 100%, 40%),
                              hsl(${
                                (selectedMarker.data.carburante_attuale * 120) /
                                  selectedMarker.data.serbatoio -
                                20
                              }, 100%, 50%)
                              )`,
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </v-card>
        </GmapInfoWindow>

        <DirectionsRenderer
          travelMode="DRIVING"
          :origin="origin"
          :destination="destionation"
          :waypoints="waypts"
          @onDirection="getPath"
        />
      </GmapMap>
      <!-- <v-btn @click="moveStart" class="ma-2" color="primary">Move start</v-btn>
      <v-btn @click="myStopFunction" class="ma-2" color="primary"
        >Stop start</v-btn
      > -->
    </v-card>
  </v-app>
</template>

<script>
import GmapMap from "vue2-google-maps/dist/components/map.vue";
import DirectionsRenderer from "@/components/LOGISTICA/DirectionsRenderer";

const scaledSize = { width: 50, height: 50, f: "px", b: "px" };

export default {
  name: "Geolocalizzazione",
  components: {
    GmapMap,
    DirectionsRenderer,
  },
  data() {
    return {
      interval: null,
      start: "",
      end: "",
      pure_markers: [],
      only_waypoints: [],
      waypts: [],
      path: [],
      carburante: 0,
      center: {
        lat: 0,
        lng: 0,
      },

      markers: [],
      // Singolo oggetto dell'array markers
      selectedMarker: null,
    };
  },
  computed: {
    origin() {
      if (!this.start) return null;
      return { query: this.start };
    },
    destionation() {
      if (!this.end) return null;
      return { query: this.end };
    },
  },
  created() {},
  mounted() {
    /* Ho passato i markers dalla TabellaMezzi
     * con una prop nella route. Faccio un array
     * temporaneo per modificarlo. */
    let markers_tmp = this.$route.params.markers;
    markers_tmp.forEach((elem) => {
      let marker = { position: { lat: 0, lng: 0 } };
      // marker.position.lat = parseFloat(elem.latitudine);
      marker.position.lat = elem.latitudine;
      marker.position.lng = elem.longitudine;
      // passo tutti i dati da tabellaMezzi
      marker.data = elem;

      if (marker.data.carburante_attuale < marker.data.serbatoio / 3) {
        marker.id_stato = 0;
      } else if (
        marker.data.carburante_attuale <
        (marker.data.serbatoio / 3) * 2
      ) {
        marker.id_stato = 1;
      } else {
        marker.id_stato = 2;
      }

      this.markers.push(marker);
      console.log("markers", this.markers);
    });

    this.center.lat = this.markers[0].position.lat;
    this.center.lng = this.markers[0].position.lng;

    this.partenza();

    setTimeout(() => {
      this.arrivoPriorita();
      this.calcWaypts();
    }, 1000);
  },
  methods: {
    partenza() {
      this.markers.forEach((elem) => {
        if (elem.data.partenza) {
          elem.data.badge = "P";
          elem.data.label = "P";
          console.log(elem);

          this.start = (
            elem.position.lat +
            ", " +
            elem.position.lng
          ).toString();
          console.log(this.start, "start");
        }
      });
    },
    arrivoPriorita() {
      // devo ordinare qui
      this.markers.sort(function (a, b) {
        return (
          (a.data.carburante_residuo * 100) / a.data.capienza -
          (b.data.carburante_residuo * 100) / b.data.capienza
        );
      });
      console.log("ordinati");
      console.log(this.markers);

      this.markers.forEach((elem, index) => {
        if (!elem.data.partenza) {
          elem.data.badge = index + 1;
          elem.data.label = (index + 1).toString();
          this.pure_markers.push(elem);
        }
      });

      console.log("senza autocisterna");
      console.log(this.pure_markers);

      this.end =
        this.pure_markers[this.pure_markers.length - 1].position.lat +
        ", " +
        this.pure_markers[this.pure_markers.length - 1].position.lng;

      console.log(this.end, "end");

      this.pure_markers.pop();
      console.log("ultimo elemento eliminato");
      console.log(this.pure_markers);
    },
    calcWaypts() {
      this.pure_markers.forEach((elem) => {
        const stop = new window.google.maps.LatLng(
          elem.position.lat,
          elem.position.lng
        );

        this.waypts.push({
          location: stop,
          stopover: true,
        });
      });

      console.log("waypts");
      console.log(this.waypts);
    },
    goToTabellaMezzi() {
      // Svuoto l'array "markers"
      this.markers = [];

      // Eseguo il redirect
      this.$router.push("/home/logistica2");
    },
    moveStart() {
      let count = 0;

      this.interval = setInterval(() => {
        if (count < this.path.length) {
          this.markers = this.markers.map((m) => {
            if (m.data.partenza) {
              m.position.lat = this.path[count].lat();
              m.position.lng = this.path[count].lng();
              return m;
            }
            return m;
          });
          count++;
        } else {
          this.myStopFunction();
        }
      }, 100);

      // function myStopFunction() {
      //   clearInterval(interval);
      // }
    },
    myStopFunction() {
      clearInterval(this.interval);
    },
    getPath(data) {
      console.log({ data });
      this.path = data;
    },
    // // Creo l'oggetto che mi permette di renderizzare l'infoWindow
    // //  e di avere i dati di ogni singolo marker
    openInfoWindow(m) {
      this.selectedMarker = m;
    },
    // // Chiudo l'infoWindow
    closeInfoWindow() {
      this.selectedMarker = null;
    },
    // Metodo che mi permette la selezione dell'icona colorata
    // in base al des_mezzo e id stato
    setMarkerIcon(nome) {
      return {
        url: require(`../../assets/iconsMap/${nome.toLowerCase()}.png`),
        scaledSize,
        labelOrigin: new window.google.maps.Point(40.8, 8.5),
      };
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}
.infoWindow {
  margin-right: 20px;
}
.infoWindowData {
  padding-bottom: 20px;
}
.bar {
  background-image: linear-gradient(to right, gray, black, gray);
  border: 1px solid black;
  position: relative;
  width: 10px;
  height: 120px;
  border-radius: 12px;
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 3px 3px 6px 3px #ccc;
}
.labelPoints {
  background: green !important;
}
</style>

