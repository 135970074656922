<template>
  <div>
    <v-card class="pa-2">
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text"
          >Heavy Dueties Monitor</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <!-- Bottone di mappa -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="goToGeolocalizzazione" small v-bind="attrs" v-on="on"
              ><v-icon color="primary">mdi-google-maps</v-icon></v-btn
            >
          </template>
          <span>Vai alla Mappa</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <!-- Bottone di indietro -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$router.push('/home')" small v-bind="attrs" v-on="on"
              ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
            >
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <!-- AREA FILTRI -->

        <v-row align="center" justify="center">
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_mezzi_visualizzati"
              item-text="targa"
              item-value="targa"
              label="Targa Mezzo"
              style="display: inline-block; max-width: 350px"
              v-model="targa_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="targa_search = ''"
                  color="primary"
                  >{{
                    targa_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_mezzi_visualizzati"
              item-text="des_mezzo"
              item-value="des_mezzo"
              label="Descrizione Mezzo"
              style="display: inline-block; max-width: 350px"
              v-model="des_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="des_search = ''" color="primary">{{
                  des_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_mezzi_visualizzati"
              item-text="tipo_mezzo"
              item-value="tipo_mezzo"
              label="Modello"
              style="display: inline-block; max-width: 350px"
              v-model="tipo_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="tipo_search = ''"
                  color="primary"
                  >{{
                    tipo_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_mezzi_visualizzati"
              item-text="des_tipo"
              item-value="id_tipo"
              label="Tipologia"
              style="display: inline-block; max-width: 350px"
              v-model="tipologia_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="tipologia_search = ''"
                  color="primary"
                  >{{
                    tipologia_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
                <v-icon
                  color="primary"
                  @click="tabella_tipo_visibile = !tabella_tipo_visibile"
                  >mdi-eye-plus</v-icon
                >
              </template>
            </v-autocomplete>

            <!-- TIPO FILTRO COMP. ALICE -->
            <!-- <v-autocomplete
              v-model="this.items_tipo.cod"
              :items="items_mezzi_visualizzati"
              label="Tipo Mezzo"
              placeholder="Tipo Mezzo"
              item-text="des"
              item-value="cod"
              required
            >
              <template #label>
                <span class="">Tipo<strong>* </strong></span>
                <span @click.stop style="min-width: 500px; pointer-events: all">
                  <Modules
                    v-on:dialogModules="getTabelle"
                    tipo="MEZZI"
                    nome="Tipo Mezzo"
                    setaction="settabella"
                    getaction="gettabella"
                    controller="Global"
                    filter="validita = 1"
                    :dati="[{ des: '' }]"
                  />
                </span>
              </template>
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="tipo_search = ''"
                  color="primary"
                  >{{
                    tipo_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete> -->
          </v-col>

          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_mezzi_visualizzati"
              item-text="stato"
              item-value="stato"
              label="Stato Carburante"
              style="display: inline-block; max-width: 350px"
              v-model="stato_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="stato_search = ''"
                  color="primary"
                  >{{
                    stato_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="ripristina" v-bind="attrs" v-on="on"
                  ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
                >
              </template>
              <span>Svuota Filtri</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <!-- CARD PARTENZE -->
          <v-col cols="4" class="cardPartenze">
            <v-card>
              <v-toolbar color="indigo" dense>
                <!-- <v-toolbar-title class="white--text">Partenze</v-toolbar-title> -->
                <!-- <v-spacer></v-spacer> -->
                <v-select
                  class="mr-3"
                  hide-details
                  dense
                  :items="mezzi_partenze"
                  item-text="targa"
                  label="Seleziona Partenza"
                  solo
                  style="display: inline-block; max-width: 200px"
                  v-model="targa_sel"
                >
                  <!-- <template v-slot:append>
                    <v-icon @click="clickAggiungiPartenze" color="primary"
                      >mdi-plus
                    </v-icon>
                  </template> -->
                </v-select>

                <v-spacer></v-spacer>

                <!-- Bottone Modifica Mezzo Partenze -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!targa_sel"
                      @click="clickModificaMezzoPartenze(partenza_sel)"
                      class="ml-2 mr-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Mezzo</span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <!-- Bottone di indietro -->

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!targa_sel"
                      @click="clickEliminaPartenze(partenza_sel)"
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="error">mdi-close</v-icon></v-btn
                    >
                  </template>
                  <span>Elimina Mezzo</span>
                </v-tooltip> -->
              </v-toolbar>

              <!-- Sez. Partenze -->

              <v-card-text v-if="!targa_sel">
                <v-row>
                  <v-col>Nessun mezzo selezionato</v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else>
                <v-row>
                  <v-col cols="4">
                    <div
                      class="mt-2"
                      v-if="partenza_sel.img"
                      :id="partenza_sel.id_mezzo"
                      style="
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        border: 1px solid gray;
                        border-radius: 3px;
                        height: 100px;
                        width: 100px;
                      "
                    ></div>
                    <div
                      class="mt-2"
                      v-else
                      style="
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        border: 1px solid gray;
                        border-radius: 3px;
                        height: 100px;
                        width: 100px;
                      "
                    >
                      Nussuna immagine selezionata
                    </div>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        Targa:
                        <h4>{{ partenza_sel.targa }}</h4>
                      </v-col>
                      <v-col
                        >Tipo Mezzo:
                        <h4>{{ partenza_sel.des_tipo }}</h4></v-col
                      >
                    </v-row>
                    <v-row justify="center">
                      <v-col
                        >Capienza:
                        <h4>{{ partenza_sel.serbatoio }}</h4></v-col
                      >
                      <v-col
                        >Carb. Residuo:
                        <h4>{{ partenza_sel.carburante_attuale }}</h4></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    >Latitudine:
                    <h4>{{ partenza_sel.latitudine }}</h4></v-col
                  >
                  <v-col
                    >Longitudine:
                    <h4>{{ partenza_sel.longitudine }}</h4></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- TABELLA MEZZI -->
          <v-col>
            <v-card>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text"
                  >Tabella Mezzi</v-toolbar-title
                >
                <v-spacer></v-spacer>

                <!-- Bottone Aggiungi Mezzo -->
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="clickAggiungiMezzo"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Mezzo</span>
                </v-tooltip> -->

                <v-spacer></v-spacer>
              </v-toolbar>

              <v-data-table
                :headers="prefisso == 'cemental' ? headersCemental : headers"
                :items="items_mezzi_visualizzati"
              >
                <template v-slot:[`item.edita`]="{ item }">
                  <v-btn small @click="clickModificaMezzo(item)">
                    <v-icon color="primary"> mdi-pencil </v-icon>
                  </v-btn>
                </template>

                <template v-slot:[`item.img`]="{ item }">
                  <!-- <v-icon>{{ item.icon }}</v-icon> -->
                  <div
                    :id="item.id_mezzo"
                    style="
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      border: 1px solid gray;
                      border-radius: 3px;
                      height: 100px;
                      margin-top: 2px;
                      margin-bottom: 2px;
                      width: 100px;
                    "
                  ></div>
                </template>

                <template v-slot:[`item.stato`]="{ item }">
                  <div class="bar">
                    <div
                      v-if="prefisso == 'euroscavi'"
                      :style="{
                        borderRadius: 12 + 'px',
                        border: 1 + 'px solid black',
                        height: 100 + '%',
                        width:
                          (item.carburante_attuale * 120) / item.serbatoio +
                          'px',
                        backgroundImage:
                          item.stato === 0
                            ? `linear-gradient(
                               hsl(20, 100%, 50%),
                               hsl(${
                                 (item.carburante_attuale * 100) /
                                 item.serbatoio /
                                 10
                               }, 100%, 50%),
                               hsl(20, 100%, 50%)
                              )`
                            : item.stato === 1
                            ? `linear-gradient(
                              hsl(${
                                (item.carburante_attuale * 100) /
                                  item.serbatoio -
                                20
                              }, 100%, 50%),
                              hsl(${
                                (item.carburante_attuale * 100) / item.serbatoio
                              }, 100%, 50%),
                              hsl(${
                                (item.carburante_attuale * 100) /
                                  item.serbatoio -
                                20
                              }, 100%, 50%)
                              )`
                            : `linear-gradient(
                              hsl(${
                                (item.carburante_attuale * 100) /
                                  item.serbatoio -
                                20
                              }, 100%, 50%),
                              hsl(${
                                (item.carburante_attuale * 100) / item.serbatoio
                              }, 100%, 40%),
                              hsl(${
                                (item.carburante_attuale * 100) /
                                  item.serbatoio -
                                20
                              }, 100%, 50%)
                              )`,
                      }"
                    ></div>
                    <div
                      v-if="prefisso == 'cemental'"
                      :style="{
                        borderRadius: 12 + 'px',
                        border: 1 + 'px solid black',
                        height: 100 + '%',
                        width: item.carburante_attuale + 'px',
                        backgroundImage:
                          item.stato === 0
                            ? `linear-gradient(
                               hsl(20, 100%, 50%),
                               hsl(${item.carburante_attuale / 10}, 100%, 50%),
                               hsl(20, 100%, 50%)
                              )`
                            : item.stato === 1
                            ? `linear-gradient(
                              hsl(${item.carburante_attuale - 20}, 100%, 50%),
                              hsl(${item.carburante_attuale}, 100%, 50%),
                              hsl(${item.carburante_attuale - 20}, 100%, 50%)
                              )`
                            : `linear-gradient(
                              hsl(${item.carburante_attuale - 20}, 100%, 50%),
                              hsl(${item.carburante_attuale}, 100%, 40%),
                              hsl(${item.carburante_attuale - 20}, 100%, 50%)
                              )`,
                      }"
                    ></div>
                  </div>
                </template>

                <template v-slot:[`item.carburante_attuale`]="{ item }">
                  {{
                    prefisso == "cemental"
                      ? item.carburante_attuale + "%"
                      : item.carburante_attuale
                  }}
                </template>
                <template v-slot:[`item.coordinate`]="{ item }">
                  <v-row>{{ item.latitudine }}</v-row>
                  <v-row>{{ item.longitudine }}</v-row>
                </template>

                <!-- <template v-slot:[`item.elimina`]="{ item }">
                  <v-btn small @click="clickElimina(item)">
                    <v-icon color="error"> mdi-close </v-icon>
                  </v-btn>
                </template> -->
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- DIALOG AGGIUNGI/MODIFICA MEZZO -->
    <v-dialog v-model="modifica_mezzo_visible" width="450">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title
            >\
            <span class="white--text">{{
              mezzo_sel.id_mezzo === -1 ? "Aggiungi Mezzo" : "Modifica Mezzo"
            }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small @click="clickSalva">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn small @click="chiudiModificaDialog">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row justify="center" align="center">
            <!-- <v-col class="py-0 my-0">
              <v-text-field
                v-model="mezzo_sel.targa"
                label="Targa"
              ></v-text-field>
            </v-col> -->
            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-4"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog_aggiungi_image = true"
                  >
                    <v-icon color="#1a80b6">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    mezzo_sel.img ? "Modifica Immagine" : "Aggiungi Immagine"
                  }}</span
                >
              </v-tooltip>
            </v-col>
            <v-col v-show="immagine_preview">
              <div
                v-if="!isPdf(immagine_preview)"
                v-viewer
                class="images clearfix"
              >
                <img
                  id="immagine"
                  style="
                    height: 100% !important;
                    width: 100%;
                    display: block !important;
                  "
                  :src="immagine_preview"
                  :lazy-src="immagine_preview"
                  aspect-ratio="1"
                  class="grey lighten-2"
                />
              </div>
              <v-card
                @click="openPdfAppenaCaricato(immagine_preview)"
                style="height: 40px !important; width: 80px !important"
                v-if="isPdf(immagine_preview)"
              >
                <div style="text-align: center">
                  <v-spacer></v-spacer>
                  <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                background-color="#ffffff"
                hide-details
                :items="items_tipo"
                item-text="des_tipo"
                item-value="cod"
                :label="mezzo_sel.des_tipo"
                style="display: inline-block; max-width: 350px"
                v-model="mezzo_sel.id_tipo"
              />
              <!-- <v-text-field
                v-model="mezzo_sel.tipo_mezzo"
                label="Tipo Mezzo"
              ></v-text-field> -->
            </v-col>
            <!-- <v-col class="py-0 my-0">
              <v-autocomplete
                background-color="#ffffff"
                hide-details
                :items="cantieri"
                item-text="des_cantiere"
                item-value="id_cantiere"
                label="Nome Cantiere"
                style="display: inline-block; max-width: 350px"
                v-model="mezzo_sel.id_cantiere"
              />
              <v-text-field
                v-model="mezzo_sel.des_mezzo"
                label="Descrizione"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="mezzo_sel.serbatoio"
                label="Serbatoio (litri)"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="mezzo_sel.carburante_attuale"
                label="Carburante Att. (litri)"
                @change="associaStato"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="mezzo_sel.latitudine"
                label="Latitudine"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="mezzo_sel.longitudine"
                label="Longitudine"
              ></v-text-field>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOOG CANCELLA MEZZO -->
    <!-- <v-dialog v-model="dialog_cancella_visible" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          Sei sicuro di voler eliminare questo elemento?
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="chiudiConfermaCancellazione"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancellaMezzo(mezzo_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- DIALOG AGGIUNGI PARTENZE -->
    <!-- <v-dialog v-model="aggiungi_mezzo_partenza_visible" width="500">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text">Aggiungi Mezzo Partenza</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small @click="clickSalvaPartenza">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn small @click="clickAnnullaCancellazionePartenze">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row justify="center">
            <v-col class="py-0 my-0" cols="5">
              <v-text-field
                v-model="partenza_sel.targa"
                label="Targa"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog_aggiungi_image = true"
                  >
                    <v-icon color="#1a80b6">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Nuova Immagine</span>
              </v-tooltip>
            </v-col>
            <v-col v-show="immagine_preview">
              <div
                v-if="!isPdf(immagine_preview)"
                v-viewer
                class="images clearfix"
              >
                <img
                  id="immagine"
                  style="
                    height: 100% !important;
                    width: 100%;
                    display: block !important;
                  "
                  :src="immagine_preview"
                  :lazy-src="immagine_preview"
                  aspect-ratio="1"
                  class="grey lighten-2"
                />
              </div>
              <v-card
                @click="openPdfAppenaCaricato(immagine_preview)"
                style="height: 40px !important; width: 80px !important"
                v-if="isPdf(immagine_preview)"
              >
                <div style="text-align: center">
                  <v-spacer></v-spacer>
                  <v-icon color="error" x-large> mdi-pdf-box </v-icon>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="py-0 my-0">
              <v-autocomplete
                background-color="#ffffff"
                hide-details
                :items="items_tipo"
                item-text="des_tipo"
                item-value="cod"
                label="Tipologia"
                v-model="partenza_sel.id_tipo"
              />
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.capienza"
                label="Serbatoio (L)"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.carburante_residuo"
                label="Carburante  Att.(L)"
                @change="associaStatoPartenze"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.longitudine"
                label="Longitudine"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.latitudine"
                label="Latitudine"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog> -->

    <!-- DIALOG MODIFICA PARTENZE -->
    <v-dialog v-model="modifica_mezzo_partenza_visible" width="450">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text"> Modifica Mezzo Partenza</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small @click="clickSalvaEditPartenze">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn small @click="clickChiudiModificaPartenza">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row>
            <!-- <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.targa"
                label="Targa"
              ></v-text-field>
            </v-col> -->
            <v-col cols="3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog_aggiungi_image = true"
                  >
                    <v-icon color="#1a80b6">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Nuova Immagine</span>
              </v-tooltip>
            </v-col>
            <v-col v-show="immagine_preview">
              <div
                v-if="!isPdf(immagine_preview)"
                v-viewer
                class="images clearfix"
              >
                <img
                  id="immagine"
                  style="
                    height: 100% !important;
                    width: 100%;
                    display: block !important;
                  "
                  :src="immagine_preview"
                  :lazy-src="immagine_preview"
                  aspect-ratio="1"
                  class="grey lighten-2"
                />
              </div>
              <v-card
                @click="openPdfAppenaCaricato(immagine_preview)"
                style="height: 40px !important; width: 80px !important"
                v-if="isPdf(immagine_preview)"
              >
                <div style="text-align: center">
                  <v-spacer></v-spacer>
                  <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5" class="py-0 my-0">
              <v-autocomplete
                background-color="#ffffff"
                hide-details
                :items="items_tipo"
                item-text="des_tipo"
                item-value="cod"
                :label="partenza_sel.des_tipo"
                style="display: inline-block; max-width: 350px"
                v-model="partenza_sel.id_tipo"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.capienza"
                label="Serbatoio (litri)"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.carburante_residuo"
                label="Carburante Att. (litri)"
                @change="associaStatoPartenze"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.latitudine"
                label="Latitudine"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                v-model="partenza_sel.longitudine"
                label="Longitudine"
              ></v-text-field>
            </v-col>
          </v-row> -->
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOG CANCELLA PARTENZE  -->
    <v-dialog v-model="dialog_cancella_visible_par" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          Sei sicuro di voler eliminare questo elemento?
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    dialog_cancella_visible_par = !dialog_cancella_visible_par
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancellaMezzoPartenze(partenza_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG TABELLA CANTIERE -->
    <!-- <v-dialog width="50%" v-model="tabella_cantiere_visibile">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text">Tabella Cantieri</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickAggiungiCantiere"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Cantiere</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="tabella_cantiere_visibile = !tabella_cantiere_visibile"
          >
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-data-table :headers="headersCantiere" :items="cantieri">
            <template v-slot:[`item.coordinate`]="{ item }">
              {{ item.latitudine }} - {{ item.longitudine }}
            </template>

            <template v-slot:[`item.elimina`]="{ item }">
              <v-btn small @click="clickEliminaCantiere(item)">
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog> -->

    <!-- DIALOG AGGIUNGI CANTIERE -->
    <!-- <v-dialog width="50%" v-model="modifica_cantiere">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text"> Aggiungi Cantiere </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="clickSalvaCantiere" small>
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn small @click="chiudiDialogCantiere">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                hide-details
                dense
                v-model="cantiere_sel.des_cantiere"
                label="Nome Cantiere"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                hide-details
                dense
                v-model="cantiere_sel.latitudine"
                label="Latitudine"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                hide-details
                dense
                v-model="cantiere_sel.longitudine"
                label="Longitudine"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog> -->

    <!-- DIALOG CANCELLA CANTIERI  -->
    <!-- <v-dialog v-model="dialog_cancella_cantiere_visible" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          Sei sicuro di voler eliminare questo elemento?
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    dialog_cancella_cantiere_visible = !dialog_cancella_cantiere_visible
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancellaCantiere(cantiere_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- DIALOG TABELLA TIPOLOGIA -->
    <v-dialog width="350" v-model="tabella_tipo_visibile">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text">Tabella Tipologia</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickAggiungiTipologia"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Tipologia</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn small @click="tabella_tipo_visibile = !tabella_tipo_visibile">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-data-table :headers="headersTipo" :items="items_tipo">
            <template v-slot:[`item.elimina`]="{ item }">
              <v-btn small @click="clickEliminaTipo(item)">
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOG AGGIUNGI TIPOLOGIA -->
    <v-dialog width="25%" v-model="modifica_tipo">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text">Aggiungi Tipologia</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="clickSalvaTipo" small>
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn small @click="modifica_tipo = false">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                hide-details
                dense
                v-model="tipo_sel.des_tipo"
                label="Nome Tipologia Mezzo"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!--DIALOG CANCELLA TIPOLOGIA  -->
    <v-dialog v-model="dialog_cancella_tipo_visible" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          Sei sicuro di voler eliminare questo elemento?
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    dialog_cancella_tipo_visible = !dialog_cancella_tipo_visible
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancellaTipo(tipo_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG ADD NEW IMAGE TO ITEM -->
    <v-dialog persistent v-model="dialog_aggiungi_image" width="500">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Immagine</v-toolbar-title
          >
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setImmagine" small>
                <v-icon v-on="on" color="success" v-bind="attrs"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip> -->
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-n4" @click="chiudiDialogImage" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image,image/PNG"
            label="File"
            prepend-icon="mdi-paperclip"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Modules from "@/components/TOOLS/Modules.vue";

export default {
  name: "TabellaMezzi",
  components: {
    // Modules,
  },
  data() {
    return {
      // Variabili d'appoggio
      prefisso: null,
      immagine_preview: "",
      // NETWORK: null,
      targa_sel: null,
      mezzi_search: "",
      targa_search: "",
      des_search: "",
      stato_search: "",
      tipo_search: "",
      tipologia_search: "",
      cantiere_search: "",

      mezzo_sel: {
        id_mezzo: -1,
        id_stato: null,
        id_tipo: null,
        targa: "",
        img: null,
        serbatoio: null,
        carburante_attuale: null,
        id_cantiere: null,
        latitudine: null,
        longitudine: null,
        validita: 1,
      },
      partenza_sel: {
        id_mezzo: -1,
        des_mezzo: "default",
        id_stato: null,
        id_tipo: null,
        img: null,
        targa: "",
        serbatoio: null,
        carburante_attuale: null,
        latitudine: null,
        longitudine: null,
        validita: 1,
      },
      cantiere_sel: {
        id_cantiere: -1,
        des_cantiere: "",
        latitudine: null,
        longitudine: null,
        validita: 1,
      },
      tipo_sel: {
        id_tabella: -1,
        cod: null, // vecchio id tipo
        des_tipo: "",
        validita: 1,
      },

      // Modal
      allegato: [],
      dialog_aggiungi_image: false,
      modifica_mezzo_visible: false,
      modifica_mezzo_partenza_visible: false,
      aggiungi_mezzo_partenza_visible: false,
      dialog_cancella_visible: false,
      dialog_cancella_visible_par: false,
      dialog_cancella_cantiere_visible: false,
      dialog_cancella_tipo_visible: false,
      tabella_cantiere_visibile: false,
      tabella_tipo_visibile: false,
      modifica_cantiere: false,
      modifica_tipo: false,

      // Array
      headers: [
        {
          text: "Modifica",
          align: "start",
          sortable: false,
          value: "edita",
        },

        { text: "Targa", value: "targa" },
        { text: "Descrizione", value: "des_mezzo" },
        { text: "Tipologia", value: "des_tipo" },
        { text: "Modello", value: "tipo_mezzo" },
        // { text: "Icona", value: "icon" },
        { text: "Immagine", value: "img" },
        { text: "Barra Stato", value: "stato" },
        { text: "Capienza Massima", value: "serbatoio" },
        { text: "Carburante Attuale", value: "carburante_attuale" },
        // { text: "Cantiere", value: "des_cantiere" },
        { text: "Coordinate", value: "coordinate" },
        // { text: "Elimina", value: "elimina", sortable: false },
      ],
      headersCemental: [
        {
          text: "Modifica",
          align: "start",
          sortable: false,
          value: "edita",
        },

        { text: "Targa", value: "targa" },
        { text: "Descrizione", value: "des_mezzo" },
        { text: "Tipologia", value: "des_tipo" },
        { text: "Modello", value: "tipo_mezzo" },
        // { text: "Icona", value: "icon" },
        { text: "Immagine", value: "img" },
        { text: "Barra Stato", value: "stato" },
        { text: "Carburante Attuale", value: "carburante_attuale" },
        // { text: "Cantiere", value: "des_cantiere" },
        { text: "Coordinate", value: "coordinate" },
        // { text: "Elimina", value: "elimina", sortable: false },
      ],
      // headersCantiere: [
      //   {
      //     text: "Cantiere",
      //     align: "start",
      //     sortable: false,
      //     value: "des_cantiere",
      //   },
      //   { text: "Coordinate", value: "coordinate" },
      //   { text: "Elimina", value: "elimina", sortable: false },
      // ],
      headersTipo: [
        {
          text: "Tipologia",
          align: "start",
          sortable: false,
          value: "des_tipo",
        },
        { text: "Elimina", value: "elimina", sortable: false },
      ],
      items_mezzi_visualizzati: [],
      mezzi: [
        // {
        //   carburante_attuale: 0,
        //   des_mezzo: "GPRS - *000853 ",
        //   icon: "CAT_SD_M",
        //   id_mezzo: 1,
        //   id_mezzo_peref: 215498,
        //   img: null,
        //   latitudine: "44,548333",
        //   longitudine: "7,9252",
        //   serbatoio: 100,
        //   stato: 1,
        //   targa: "K0000853 ",
        //   tipo_mezzo: "Ditch Witch JT25",
        //   validita: 1,
        // },
      ],
      items_stato: [],
      cantieri: [],
      items_tipo: [],
      mezzi_partenze: [],
    };
  },
  computed: {},
  watch: {
    mezzi: {
      immediate: true,
      handler() {
        this.items_mezzi_visualizzati = this.mezzi;
      },
    },
    targa_sel: {
      immediate: true,
      handler() {
        this.mezzi_partenze.forEach((partenza) => {
          if (partenza.targa === this.targa_sel) {
            this.partenza_sel = Object.assign({}, partenza);
            if (this.partenza_sel.img) {
              this.aggiungiImg(this.partenza_sel);
            }
          }
        });
      },
    },
  },
  mounted() {
    // this.NETWORK = this.$store.state.Network;

    this.prefisso = window.localStorage.getItem("prefisso_utente");
    console.log(this.prefisso);

    this.items_mezzi_visualizzati = this.mezzi;
    3;
    this.getDettagli();
    // this.getTabelle();
    this.getMezzi();
    // this.getPartenze();
  },
  methods: {
    aggiungiImg(messaggio) {
      console.log("messaggio ricevuto" + messaggio);
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.img;

      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width >= img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.id_mezzo).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.id_mezzo).style.height =
            height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.id_mezzo).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.id_mezzo).style.height =
            height_nuova + "px";
        } else {
          document.getElementById(messaggio.id_mezzo).style.width =
            img.width + "px";
          document.getElementById(messaggio.id_mezzo).style.height =
            img.height + "px";
        }
      };

      img.src = photo;

      this.$nextTick(() => {
        document.getElementById(messaggio.id_mezzo).style.backgroundImage =
          "url('" + img.src + "')";
      });
    },
    associaId(tipo) {
      if (tipo === "tipo") {
        this.items_tipo.forEach((elem) => {
          if (elem.des_tipo == this.mezzo_sel.des_tipo) {
            this.mezzo_sel.id_tipo = elem.id_tipo;
          }
        });
      }

      // if (tipo === "cantiere") {
      //   this.cantieri.forEach((elem) => {
      //     if (elem.des_cantiere == this.mezzo_sel.des_cantire) {
      //       this.mezzo_sel.id_cantiere = elem.id_cantiere;
      //     }
      //   });
      // }
    },
    // associaStato() {
    //   if (this.mezzo_sel.carburante_residuo < this.mezzo_sel.capienza / 3) {
    //     this.mezzo_sel.id_stato = 0;
    //   } else if (
    //     this.mezzo_sel.carburante_residuo <
    //     (this.mezzo_sel.capienza / 3) * 2
    //   ) {
    //     this.mezzo_sel.id_stato = 1;
    //   } else {
    //     this.mezzo_sel.id_stato = 2;
    //   }
    // },
    // associaStatoPartenze() {
    //   if (
    //     this.partenza_sel.carburante_residuo <
    //     this.partenza_sel.capienza / 3
    //   ) {
    //     this.partenza_sel.id_stato = 0;
    //   } else if (
    //     this.partenza_sel.carburante_residuo <
    //     (this.partenza_sel.capienza / 3) * 2
    //   ) {
    //     this.partenza_sel.id_stato = 1;
    //   } else {
    //     this.partenza_sel.id_stato = 2;
    //   }
    // },
    // cancellaCantiere(item) {
    //   item.validita = 0;
    //   this.setCantieri(item);

    //   let cantiere_tmp = {
    //     id_cantiere: -1,
    //     des_cantiere: "",
    //     latitudine: null,
    //     longitudine: null,
    //     validita: 1,
    //   };

    //   this.cantiere_sel = Object.assign({}, cantiere_tmp);

    //   this.dialog_cancella_cantiere_visible = false;
    // },
    cancellaTipo(item) {
      item.validita = 0;

      this.tipo_sel.des = this.tipo_sel.des_tipo;
      delete this.tipo_sel.des_tipo;

      item.id_tabella = item.id_tipo;
      delete item.id_tipo;

      this.setTipo(item);

      let tipo_tmp = {
        id_tabella: -1,
        cod: null,
        des_tipo: "",
        validita: 1,
      };
      this.tipo_sel = Object.assign({}, tipo_tmp);

      this.dialog_cancella_tipo_visible = false;
    },
    cancellaMezzo(item) {
      item.validita = 0;
      this.setMezzi(item);

      this.dialog_cancella_visible = false;
    },
    cancellaMezzoPartenze(item) {
      item.validita = 0;
      this.setPartenze(item);

      this.dialog_cancella_visible_par = false;
      this.targa_sel = null;
    },
    chiudiConfermaCancellazione() {
      this.dialog_cancella_visible = false;

      let mezzo_tmp = {
        id_mezzo: -1,
        id_stato: null,
        id_tipo: null,
        targa: "",
        img: null,
        capienza: null,
        carburante_residuo: null,
        id_cantiere: null,
        latitudine: null,
        longitudine: null,
        validita: 1,
      };

      this.mezzo_sel = Object.assign({}, mezzo_tmp);
    },
    // chiudiDialogCantiere() {
    //   this.modifica_cantiere = false;

    //   let cantiere_tmp = {
    //     id_cantiere: -1,
    //     des_cantiere: "",
    //     latitudine: null,
    //     longitudine: null,
    //     validita: 1,
    //   };
    //   this.cantiere_sel = Object.assign({}, cantiere_tmp);
    // },
    chiudiDialogImage() {
      this.dialog_aggiungi_image = false;
      this.allegato = null;
    },
    chiudiModificaDialog() {
      this.modifica_mezzo_visible = false;

      let mezzo_tmp = {
        id_mezzo: -1,
        id_stato: null,
        id_tipo: null,
        targa: "",
        img: null,
        capienza: null,
        carburante_residuo: null,
        id_cantiere: null,
        validita: 1,
      };

      this.mezzo_sel = Object.assign({}, mezzo_tmp);
      this.immagine_preview = null;
    },
    clickChiudiModificaPartenza() {
      this.modifica_mezzo_partenza_visible = false;
      this.immagine_preview = null;
    },
    clickAnnullaCancellazionePartenze() {
      this.immagine_preview = null;
      this.allegato = null;
      this.targa_sel = null;
      this.aggiungi_mezzo_partenza_visible = false;
    },
    clickAggiungiPartenze() {
      let mezzo_partenza_tmp = {
        id_mezzo: -1,
        des_mezzo: "default",
        id_stato: null,
        id_tipo: null,
        targa: "",
        img: null,
        capienza: null,
        carburante_residuo: null,
        latitudine: null,
        longitudine: null,
        validita: 1,
      };

      this.partenza_sel = Object.assign({}, mezzo_partenza_tmp);

      this.aggiungi_mezzo_partenza_visible = true;
    },
    clickAggiungiCantiere() {
      let cantiere_tmp = {
        id_cantiere: -1,
        des_cantiere: "",
        latitudine: null,
        longitudine: null,
        validita: 1,
      };

      this.cantiere_sel = Object.assign({}, cantiere_tmp);

      this.modifica_cantiere = true;
    },
    clickAggiungiTipologia() {
      let tipo_tmp = {
        id_tabella: -1,
        cod: null, // vecchio id tipo
        tipo: "mezzi",
        des_tipo: "",
        validita: 1,
      };

      this.tipo_sel = Object.assign({}, tipo_tmp);

      this.modifica_tipo = true;
    },
    clickAggiungiMezzo() {
      let mezzo_tmp = {
        id_mezzo: -1,
        id_stato: null,
        id_tipo: null,
        targa: "",
        img: null,
        capienza: null,
        carburante_residuo: null,
        id_cantiere: null,
        validita: 1,
      };

      this.mezzo_sel = Object.assign({}, mezzo_tmp);

      this.modifica_mezzo_visible = true;
    },
    clickElimina(arg) {
      this.mezzo_sel = Object.assign({}, arg);
      this.dialog_cancella_visible = true;
    },
    clickEliminaCantiere(arg) {
      this.cantiere_sel = Object.assign({}, arg);
      this.dialog_cancella_cantiere_visible = true;
    },
    clickEliminaPartenze(arg) {
      this.partenza_sel = Object.assign({}, arg);
      this.dialog_cancella_visible_par = true;
    },
    clickEliminaTipo(arg) {
      this.tipo_sel = Object.assign({}, arg);
      this.dialog_cancella_tipo_visible = true;
    },

    clickModificaMezzo(item) {
      this.mezzo_sel = Object.assign({}, item);
      this.modifica_mezzo_visible = true;
    },
    clickModificaMezzoPartenze(item) {
      this.partenza_sel = Object.assign({}, item);
      this.modifica_mezzo_partenza_visible = true;
    },
    clickSalva() {
      // let tipo = null;

      // this.items_tipo.forEach((elem) => {
      //   if (elem.cod == this.mezzo_sel.id_tipo) {
      //     tipo = elem.des_tipo;
      //   }
      // });

      // this.mezzo_sel.des_tipo = tipo;

      this.setMezzi(this.mezzo_sel);

      let mezzo_tmp = {
        id_mezzo: -1,
        id_stato: null,
        id_tipo: null,
        targa: "",
        img: null,
        capienza: null,
        carburante_residuo: null,
        id_cantiere: null,
        latitudine: null,
        longitudine: null,
        validita: 1,
      };

      this.mezzo_sel = Object.assign({}, mezzo_tmp);

      this.modifica_mezzo_visible = false;

      // if (this.mezzo_sel.id_mezzo === -1) {
      //   this.setMezzi(this.mezzo_sel);
      // } else {
      //   this.mezzi.forEach((elem) => {
      //     if (elem.id_mezzo === this.mezzo_sel.id_mezzo) {
      //       elem.id_stato = this.mezzo_sel.id_stato;
      //       elem.id_tipo = this.mezzo_sel.id_tipo;
      //       elem.targa = this.mezzo_sel.targa;
      //       elem.img = this.mezzo_sel.img;
      //       elem.capienza = this.mezzo_sel.capienza;
      //       elem.carburante_residuo = this.mezzo_sel.carburante_residuo;
      //       elem.latitudine = this.mezzo_sel.latitudine;
      //       elem.longitudine = this.mezzo_sel.longitudine;
      //     }
      //   });

      //   this.setMezzi(this.mezzo_sel);
      // }

      // let mezzo_tmp = {
      //   id_mezzo: -1,
      //   id_stato: null,
      //   id_tipo: null,
      //   targa: "",
      //   img: null,
      //   capienza: null,
      //   carburante_residuo: null,
      //   id_cantiere: null,
      //   latitudine: null,
      //   longitudine: null,
      //   validita: 1,
      // };

      // this.mezzo_sel = Object.assign({}, mezzo_tmp);

      // this.modifica_mezzo_visible = false;
    },
    // clickSalvaPartenza() {
    //   this.setPartenze(this.partenza_sel);
    //   this.aggiungi_mezzo_partenza_visible = false;
    // },
    clickSalvaEditPartenze() {
      // this.mezzi_partenze.forEach((elem) => {
      //   if (elem.id_mezzo === this.partenza_sel.id_mezzo) {
      //     elem.id_stato = this.partenza_sel.id_stato;
      //     elem.id_tipo = this.partenza_sel.id_tipo;
      //     elem.targa = this.partenza_sel.targa;
      //     elem.img = this.partenza_sel.img;
      //     elem.capienza = this.partenza_sel.capienza;
      //     elem.carburante_residuo = this.partenza_sel.carburante_residuo;
      //     elem.latitudine = this.partenza_sel.latitudine;
      //     elem.longitudine = this.partenza_sel.longitudine;
      //   }
      // });
      delete this.partenza_sel.des_tipo;
      this.setMezzi(this.partenza_sel);
      this.modifica_mezzo_partenza_visible = false;
      this.partenza_sel = {};
      this.targa_sel = null;
    },
    clickSalvaCantiere() {
      this.setCantieri(this.cantiere_sel);

      let cantiere_tmp = {
        id_cantiere: -1,
        des_cantiere: "",
      };
      this.cantiere_sel = Object.assign({}, cantiere_tmp);

      this.modifica_cantiere = false;
    },
    clickSalvaTipo() {
      let cod = Math.max(...this.items_tipo.map((o) => o.cod), 0) + 1;

      this.tipo_sel.cod = cod;
      this.tipo_sel.des = this.tipo_sel.des_tipo;
      delete this.tipo_sel.des_tipo;

      this.setTipo(this.tipo_sel);

      let tipo_tmp = {
        id_tabella: -1,
        cod: null, // vecchio id tipo
        des_tipo: "",
        validita: 1,
      };
      this.tipo_sel = Object.assign({}, tipo_tmp);

      this.modifica_tipo = false;
    },
    filtra() {
      let items_mezzi_tmp = [];
      this.items_mezzi_visualizzati.forEach((elem) => {
        items_mezzi_tmp.push(elem);
      });

      // // Filtro il mezzo
      // if (this.mezzi_search != "") {
      //   // Faccio un array temporaneo
      //   let res = [];

      //   /* Filtro gli elementi visualizzati dopo i passaggi precedenti
      //    * e li inserisco in "res". */
      //   items_mezzi_tmp.forEach((elem) => {
      //     if (elem.des_mezzo == this.mezzi_search) {
      //       res.push(elem);
      //     }
      //   });

      //   // Modello la risposta provvisoria su "res".
      //   items_mezzi_tmp = res;
      // }

      if (this.stato_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_mezzi_tmp.forEach((elem) => {
          if (elem.stato == this.stato_search) {
            res.push(elem);
          }
        });

        items_mezzi_tmp = res;
      }

      if (this.des_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_mezzi_tmp.forEach((elem) => {
          if (elem.des_mezzo == this.des_search) {
            res.push(elem);
          }
        });

        items_mezzi_tmp = res;
      }

      if (this.tipo_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_mezzi_tmp.forEach((elem) => {
          if (elem.tipo_mezzo == this.tipo_search) {
            res.push(elem);
          }
        });

        items_mezzi_tmp = res;
      }

      if (this.tipologia_search != "") {
        console.log(this.tipologia_search);
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_mezzi_tmp.forEach((elem) => {
          if (elem.id_tipo == this.tipologia_search) {
            res.push(elem);
          }
        });

        items_mezzi_tmp = res;
        console.log(res);
      }
      if (this.targa_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_mezzi_tmp.forEach((elem) => {
          if (elem.targa == this.targa_search) {
            res.push(elem);
          }
        });

        items_mezzi_tmp = res;
      }

      this.items_mezzi_visualizzati = [];
      this.items_mezzi_visualizzati = items_mezzi_tmp;
    },

    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name
      );
    },
    onPaste(e) {
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;
        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.immagine_preview = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    // Function to select an image and change it to base64
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        this.hasExtension(files[0], [
          ".jpeg",
          ".gif",
          ".png",
          ".jpg",
          ".pdf",
          ".PNG",
        ])
      ) {
        this.allegato = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;
        reader.onload = function () {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          that.immagine_preview = reader.result;
          localStorage.setItem("ArticoloImage", that.immagine_preview);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        this.dialogAggiungiAllegato = false;
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Formato non valido";
        this.snackbar_text_color = "white";
      }
      this.dialog_aggiungi_image = false;
      console.log(this.immagine_preview);
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");
        // Create an image
        var img = new Image();
        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;
          // Draw the image
          ctx.drawImage(img, 0, 0);
          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };
        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;
        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
      this.dialogAggiungiAllegato = false;
    },
    // To Resize Image
    resizeImage(img, id) {
      if (img.width >= img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;

        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    getDettagli() {
      let request = {
        controller: "hdm",
        method: "POST",
        richiesta: {
          action: "getdettagli",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.cantieri = res.data.cantieri;
        this.items_stato = res.data.stato;
        this.items_tipo = res.data.tipo;
        console.log(this.items_tipo);
      });
    },
    getMezzi() {
      let request = {
        controller: "hdm",
        method: "POST",
        richiesta: {
          action: "hdmapimezzi",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        // this.partenza_sel = null;
        this.mezzi = [];
        this.mezzi_partenze = [];
        let array = res.data.result.mezzi;

        // assegno una des tipo in base a l'id che mi passa il be
        array.forEach((elem) => {
          this.items_tipo.forEach((tipo) => {
            if (elem.id_tipo == tipo.cod) {
              elem.des_tipo = tipo.des_tipo.toUpperCase();
            }
          });
        });

        // se la des è autocisterna inserisco il mezzo nelle partenze
        array.forEach((mezzo) => {
          if (mezzo.des_tipo == "AUTOCISTERNA") {
            this.mezzi_partenze.push(mezzo);
          }
        });

        // creo l'array di mezzi senza autocisterna
        array.forEach((e) => {
          if (e.des_tipo != "AUTOCISTERNA") {
            this.mezzi.push(e);
          }
        });

        // se il mezzo ha un immagine la inserisco
        this.mezzi.forEach((item) => {
          if (item.img) {
            this.aggiungiImg(item);
          }
        });
      });
    },
    // getPartenze() {
    //   let request = {
    //     controller: "hdm",
    //     method: "POST",
    //     richiesta: {
    //       action: "getpartenze",
    //       token: localStorage.getItem("user_token"),
    //     },
    //   };
    //   this.$store.dispatch("api", request).then((res) => {
    //     this.mezzi_partenze = res.data.partenze;
    //   });
    // },
    // getTabelle() {
    //   let request = {
    //     controller: "Global",
    //     body: {
    //       action: "gettabella",
    //       token: localStorage.user_token,
    //       tipo: "MEZZI",
    //       filter: "validita = 1",
    //     },
    //     method: "POST",
    //     on: {
    //       load: this.resultTabella,
    //     },
    //   };
    //   this.NETWORK.AjaxRequest(request);
    // },
    // resultTabella(response) {
    //   if (response.currentTarget.status == 204) {
    //     this.items_tipo = [];
    //     return;
    //   } else {
    //     response = JSON.parse(response.currentTarget.responseText);
    //     if (!response.response) {
    //       return;
    //     }
    //     let objServer = response.data;
    //     console.log(objServer);
    //     this.items_tipo = objServer;
    //   }
    // },
    goToGeolocalizzazione() {
      if (this.partenza_sel.id_mezzo != -1) {
        this.partenza_sel.partenza = 1;
        this.items_mezzi_visualizzati.push(this.partenza_sel);
        this.items_mezzi_visualizzati.map((elem) => {
          elem.latitudine = parseFloat(elem.latitudine);
          elem.longitudine = parseFloat(elem.longitudine);
        });
        this.$router.push({
          name: "Geolocalizzazione",
          params: { markers: this.items_mezzi_visualizzati },
        });
        console.log("qui");
        console.log(this.$route.params);
      } else {
        this.items_mezzi_visualizzati.map((elem) => {
          elem.latitudine = parseFloat(elem.latitudine);
          elem.longitudine = parseFloat(elem.longitudine);
        });
        this.$router.push({
          name: "Geolocalizzazione",
          params: { markers: this.items_mezzi_visualizzati },
        });
      }
    },
    ripristina() {
      this.items_mezzi_visualizzati = this.mezzi;
      this.targa_search = "";
      this.des_search = "";
      this.stato_search = "";
      this.tipo_search = "";
      this.tipologia_search = "";

      this.mezzi.forEach((item) => {
        if (item.img) {
          this.aggiungiImg(item);
        }
      });
    },
    // setCantieri(arg) {
    //   let arr = [];
    //   arr.push(arg);

    //   let richiesta = {
    //     controller: "hdm",
    //     method: "PUT",
    //     richiesta: {
    //       action: "setcantieri",
    //       token: localStorage.user_token,
    //       cantieri: arr,
    //     },
    //   };
    //   this.$store.dispatch("api", richiesta).then((res) => {
    //     console.log(res);
    //     this.getDettagli();
    //   });
    // },
    setMezzi(arg) {
      if (this.immagine_preview) {
        arg.img = this.immagine_preview;
      }
      let arr = [];
      arr.push(arg);

      let richiesta = {
        controller: "hdm",
        method: "PUT",
        richiesta: {
          action: "setmezzihdm",
          token: localStorage.user_token,
          mezzi: arr,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        this.immagine_preview = null;
        this.allegato = null;
        this.getMezzi();
      });
    },
    // setPartenze(arg) {
    //   if (this.immagine_preview) {
    //     arg.img = this.immagine_preview;
    //   }
    //   let arr = [];
    //   arr.push(arg);

    //   let richiesta = {
    //     controller: "hdm",
    //     method: "PUT",
    //     richiesta: {
    //       action: "setpartenze",
    //       token: localStorage.user_token,
    //       partenze: arr,
    //     },
    //   };
    //   this.$store.dispatch("api", richiesta).then((res) => {
    //     console.log(res);
    //     this.immagine_preview = null;
    //     this.allegato = null;
    //     this.getPartenze();
    //   });
    // },
    // setTabelle(arg) {
    //   let arr = [];

    //   arr.push(arg);
    //   console.log("set tipo arg");
    //   console.log(arr);

    //   let request = {
    //     controller: "hdm",
    //     body: {
    //       action: "settabella",
    //       token: localStorage.user_token,
    //       tabella: arr,
    //     },
    //     method: "PUT",
    //     on: {
    //       load: this.resultSetTipo,
    //     },
    //   };
    //   this.NETWORK.AjaxRequest(request);
    // },
    // resultSetTipo(response) {
    //   console.log(response);
    //   this.getDettagli();
    // },
    setTipo(arg) {
      let arr = [];
      arr.push(arg);

      let richiesta = {
        controller: "hdm",
        method: "PUT",
        richiesta: {
          action: "settabelle",
          token: localStorage.user_token,
          tabella: arr,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        this.getDettagli();
      });
    },
  },
};
</script>

<style scoped>
.bar {
  border: 1px solid black;
  position: relative;
  width: 120px;
  height: 10px;
  border-radius: 12px;
  box-shadow: 1px 2px 4px 2px #ccc;
}
.cardPartenze {
  max-width: min(90vw, 400px);
  max-height: min(90vh, 400px);
}
</style>