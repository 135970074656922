<template>
  <div>
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Fornitura Modal in order to add/update new Fornitura -->
    <v-dialog persistent :hide-overlay="true" v-model="dialogFornitura" width="1200">
      <!--Modal to Search Ragione sociale inside a Modal Fornitura-->
      <v-dialog
        persistent
        :hide-overlay="true"
        v-model="dialogSearchAnagrafica"
        width="700"
      >
        <v-card>
          <v-toolbar dense class="indigo darken-1 headline" primary-title>
            <v-toolbar-title class="white--text">Risultato della ricerca</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="dialogSearchAnagrafica = false" small>
                  <v-icon v-on="on" v-bind="attrs" depressed class="" color="error"
                    >mdi-window-close</v-icon
                  >
                </v-btn>
              </template>
              <span>Cancellare</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="max-height: 600px; overflow-y: auto">
            <v-data-table
              :loading="loadingragionesocialesearch"
              :headers="RagioneSociale"
              :items="ragioneSocialeLista"
            >
              <v-progress-linear
                v-show="progressBarragionesocialesearch"
                slot="progress"
                color="blue"
                indeterminate
              ></v-progress-linear>
              <template v-slot:[`item.actions`]="props">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-2" small @click="onSetItem(props.item)">
                      <v-icon v-bind="attrs" v-on="on" dark small color="#009900">
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> imposta Fornitura </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card>
        <v-overlay :value="overlay" style="z-index: 10">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text">Anagrafica</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            :idForm="SOTTO_FORM"
            :idOggetto="FORM_BUTTON_ARRAY[3]"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="salvaAnagrafica"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  closeModal();
                  dialogFornitura = false;
                "
                small
              >
                <v-icon v-on="on" v-bind="attrs" color="error" depressed class=""
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-container>
          <v-form @keyup.enter.native="searchAnagrafica" class="">
            <v-container>
              <v-row class="">
                <v-col class="py-0" cols="2" sm="2" md="1">
                  <div class="">
                    <span
                      style="
                        font-size: 11px;
                        color: rgba(0, 0, 0, 0.6);
                        padding-left: -0.5rem;
                      "
                      >Predefinito</span
                    >
                    <v-switch
                      v-model="predefinito"
                      style="margin-left: 1rem !important; margin-top: -5px"
                      class=""
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" md="2" cols="2" sm="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_COD_ANAGRAFICA"
                    label_input="Codice"
                    palceholder_input="Codice"
                    :vmodel="cod_anagrafica"
                    v-on:changeText="(val) => (this.cod_anagrafica = val)"
                  />
                </v-col>
                <v-col md="6" class="py-0" sm="6" cols="6">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_RAGIONE_SOCIALE"
                    label_input="Ragione Sociale"
                    palceholder_input="Ragione Sociale"
                    :vmodel="ragione_sociale"
                    v-on:changeText="(val) => (this.ragione_sociale = val)"
                  />
                </v-col>
                <v-col md="1" class="py-0" sm="1" cols="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        color="#1a80b6"
                        class="mt-5"
                        @click="searchAnagrafica"
                        @keyup.enter.native="searchAnagrafica"
                        >mdi-magnify</v-icon
                      >
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
                <v-col class="py-0" cols="1" sm="1" md="1">
                  <div class="ml-2 mt-n2">
                    <span
                      style="
                        font-size: 11px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -0.2rem;
                        padding-left: -0.2rem;
                      "
                      >Trasf. Diretto</span
                    >
                    <v-switch
                      v-model="trasferimento_diretto"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" cols="6" sm="6" md="6">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_INDIRIZZO"
                    label_input=" indirizzo"
                    palceholder_input="indirizzo"
                    :vmodel="indirizzo"
                    v-on:changeText="(val) => (this.indirizzo = val)"
                  />
                </v-col>
                <v-col class="py-0" cols="6" sm="6" md="6">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_LOCALITA"
                    label_input=" Località"
                    palceholder_input="Località"
                    :vmodel="localita"
                    v-on:changeText="(val) => (this.localita = val)"
                  />
                </v-col>
                <v-col class="py-0" md="1" cols="1" sm="1">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_QTA_MINIMA_ORDINE"
                    label_input=" MOQ"
                    palceholder_input="MOQ"
                    :vmodel="qta_minima_ordine"
                    v-on:changeText="(val) => (this.qta_minima_ordine = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" sm="1" cols="1" md="1">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_QTA_LOTTO_MINIMO"
                    label_input=" Q.ta Lot"
                    palceholder_input="Q.ta Lot"
                    :vmodel="qta_lotto_minimo"
                    v-on:changeText="(val) => (this.qta_lotto_minimo = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" sm="3" cols="3" md="3">
                  <v-autocomplete
                    class="ml-4"
                    v-model="id_um_lotto"
                    :items="mizura_list"
                    item-text="des"
                    placeholder="UM Q.ta Lot"
                    required
                    item-value="id_tabella"
                    label="UM Q.ta Lot"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" sm="3" cols="3" md="3">
                  <v-autocomplete
                    class=""
                    v-model="id_tipo_fornitura"
                    :items="modalita_list"
                    item-text="des"
                    placeholder="Modalità"
                    required
                    item-value="id_tabella"
                    label="Modalità"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" sm="4" cols="4" md="4">
                  <v-autocomplete
                    v-model="id_tipo_trasporto"
                    :items="incoterms_list"
                    placeholder="Incoterms"
                    item-text="des"
                    required
                    item-value="id_tabella"
                    label="Incoterms"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="1" class="py-0" sm="2" cols="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_TEMPO_PRODUZIONE"
                    label_input="Production Time"
                    palceholder_input="Production Time"
                    :vmodel="tempo_produzione"
                    v-on:changeText="(val) => (this.tempo_produzione = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col md="1" class="py-0" sm="2" cols="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_TEMPO_TRANSITO"
                    label_input="Transit Time"
                    palceholder_input="Transit Time"
                    :vmodel="tempo_transito"
                    v-on:changeText="(val) => (this.tempo_transito = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col md="1" class="py-0" cols="2" sm="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_TEMPO_SPECIFICO"
                    label_input="Custom Time"
                    palceholder_input="Custom Time"
                    :vmodel="tempo_specifico"
                    v-on:changeText="(val) => (this.tempo_specifico = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col md="1" class="py-0" sm="2" cols="2">
                  <TextField
                    :readonly="true"
                    idForm="CICLOFASI"
                    idOggetto="CF_LEAD_TIME"
                    label_input="Lead Time"
                    palceholder_input="Lead Time"
                    :vmodel="lead_time"
                    v-on:changeText="(val) => (this.lead_time = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col md="2" class="py-0" sm="3" cols="3">
                  <v-autocomplete
                    class="ml-1"
                    v-model="id_um_lead_time"
                    :items="unita_misura_lead_time"
                    item-text="des"
                    placeholder="UM Lead Time"
                    required
                    item-value="id_tabella"
                    label="Unità misura Lead Time"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-container>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Main Table of Fase Standard -->
    <v-toolbar dense class="indigo darken-1 headline" primary-title uppercase>
      <v-toolbar-title class="white--text">Fornitura</v-toolbar-title>
      <v-spacer></v-spacer>
      <Button
        colore_icona="primary"
        :idForm="SOTTO_FORM"
        :idOggetto="FORM_BUTTON_ARRAY[0]"
        mdi_icona="mdi-plus"
        testo_tooltip="Aggiungi Nuova Fornitura"
        v-on:buttonClick="
          openModal();
          dialogFornitura = true;
        "
      />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :loading="loadingFornitura"
      :headers="FornituraHeaders"
      class="elevation-1 tablestyle"
      :items="fornitoriLista"
    >
      <v-progress-linear
        v-show="progressBarFornitura"
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template v-slot:[`item.edit`]="{ item }">
        <Button
          style="float: left"
          colore_icona="primary"
          :idForm="SOTTO_FORM"
          :idOggetto="FORM_BUTTON_ARRAY[1]"
          mdi_icona="mdi-pencil"
          testo_tooltip="Aggiornare"
          v-on:buttonClick="handleclick(item)"
        />
      </template>
      <template v-slot:[`item.predefinito`]="{ item }">
        <v-checkbox
          v-model="item.predefinito"
          :value="item.predefinito"
          @click="changePredefinito(item)"
        >
        </v-checkbox>
      </template>
      <template v-slot:[`item.trasferimento_diretto`]="{ item }">
        <v-icon v-if="item.trasferimento_diretto === 1">mdi-check-underline</v-icon>
      </template>
      <template v-slot:[`item.lead_time`]="{ item }">
        <v-chip style="font-style: bold; font-weight: 700" color="green">{{
          item.lead_time
        }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <Button
          colore_icona="error"
          :idForm="SOTTO_FORM"
          :idOggetto="FORM_BUTTON_ARRAY[2]"
          mdi_icona="mdi-window-close"
          testo_tooltip="Cancellare"
          v-on:buttonClick="deleteFornitura(item)"
        />
      </template>
    </v-data-table>
    <!-- In order to show All the Alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
        color="white"
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
import moment from "moment";
export default {
  props: [
    "data",
    "ID_ARTICOLO",
    "ID_CICLO_FASI",
    "id_ciclostd_dettaglio",
    "id_commessa_dettaglio",
    "Tipo_component",
    "FORM_BUTTON_ARRAY",
    "FORM_ID",
    "SOTTO_FORM",
  ],
  components: {
    Button,
    TextField,
  },
  data() {
    return {
      rules: {
        min: (value) => {
          if (this.pezzi_min != null) {
            if (this.pezzi_min != null && value != null) {
              return parseInt(value) > parseInt(this.pezzi_min);
            } else return false;
          } else return true;
        },
      },
      //<======== Fornitura Variables =====>//
      //  // FK for Fornitura Object(Fase Std)
      id_ciclostd_fasi_fornitori: -1,
      // FK for Fornitura Object(Commessa)
      id_temp: -1,
      ragioneSocialeLista: [],
      fornitoriLista: [],
      deletedFornitura: [],
      dataToUpdate: {},
      progressBarragionesocialesearch: false,
      loadingragionesocialesearch: false,
      idciclo_fasi_fornitori: -1,
      codice: "",
      id_anagrafica: "",
      cod_anagrafica: "",
      ragione_sociale: "",
      indirizzo: "",
      localita: "",
      selectedItem: {},
      incoterms: "",
      qta_minima_ordine: 0,
      qta_lotto_minimo: 0,
      tempo_produzione: 0,
      tempo_transito: 0,
      tempo_specifico: 0,
      lead_time: 0,
      predefinito: 0,
      id_um_lead_time: "",
      trasferimento_diretto: 0,
      id_tipo_trasporto: null,
      id_tipo_fornitura: null,
      mizura_list: [],
      id_um_lotto: null,
      des_um_lotto: "",
      dialogFornitura: false,
      dialogSearchAnagrafica: false,
      modalita_list: [],
      incoterms_list: [],
      unita_misura_lead_time: [],
      progressBarFornitura: false,
      loadingFornitura: false,
      client: false,
      umfornitura: "",
      umtrasporto: "",
      //  <=======================Generic Variables =====================>
      // Dailog Fase Standard
      singleSelect: false,
      selected: [],
      item: "",
      overlay: false,
      id_articolo: this.ID_ARTICOLO ? this.ID_ARTICOLO : -1,
      validita: 1,
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_icon: "",
      snackbar_text: "",
      // Fasi Standard List and Fasi standard Headers
      last_item: null,
      //Header of search ragione sociale in ciclo
      RagioneSociale: [
        {
          align: "start",
          text: "Actions",
          value: "actions",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "_tipo",
          sortable: false,
        },
        {
          text: "Codice",
          value: "codice",
          sortable: false,
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Indrizzo",
          value: "indirizzo",
          sortable: false,
        },
      ],
      // Header of fornitura table
      FornituraHeaders: [
        {
          value: "edit",
        },
        {
          text: "Predefinito",
          align: "start",
          value: "predefinito",
          sortable: false,
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Indirizzo",
          value: "indirizzo",
          sortable: false,
        },
        {
          text: "Località",
          value: "localita",
          sortable: false,
        },
        {
          text: "MOQ",
          value: "qta_minima_ordine",
          sortable: false,
        },
        {
          text: "Q.ta Lotto",
          value: "qta_lotto_minimo",
          sortable: false,
        },
        {
          text: "UM Lotto",
          value: "_umlotto",
          sortable: false,
        },
        {
          text: "Transit Time",
          value: "tempo_transito",
          sortable: false,
        },
        {
          text: "Production Time",
          value: "tempo_produzione",
          sortable: false,
        },
        {
          text: "Custom Time",
          value: "tempo_specifico",
          sortable: false,
        },
        {
          text: "Lead Time",
          value: "lead_time",
          sortable: false,
        },
        {
          text: "Trasferimento Diretto",
          value: "trasferimento_diretto",
          sortable: false,
        },
        {
          text: "UM Lead Time",
          value: "_umleadtime",
          sortable: false,
        },
        {
          text: "Modalità",
          value: "_umfornitura",
          sortable: false,
        },
        {
          text: "Incoterms",
          value: "_umtrasporto",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    // In order to change predefinito in Fornitura
    changePredefinito(item) {
		if(this.Tipo_component=="Cara"){
			this.doalert(item)
		}

      if (item.predefinito) {
        item.predefinito = 1;
      }
      let dataArray = this.fornitoriLista.map((it) => {
        it.validita = 1;
        if (it === item) {
          if (item.predefinito === 1) {
            it.predefinito = 1;
          } else {
            it.predefinito = 0;
          }
        } else {
          it.predefinito = 0;
        }
        return it;
      });
      this.fornitoriLista = dataArray;
    },
    // In order to delete single Fornitura
    deleteFornitura(item) {
      //Delete logic for caratteristiche where it is making direct call to backend
      if (this.Tipo_component == "Cara") {
        let data = {};
        data.id_articolo_fornitura = item.id_articolo_fornitura;
        data.validita = 0;
        let dataArray = [data];
        let DeleteFornitura = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolofornitura",
            token: localStorage.getItem("user_token"),
            id_articolo: this.id_articolo,
            articolo_fornitura: dataArray,
          },
        };
        this.$store.dispatch("api", DeleteFornitura).then((res) => {
          this.overlay = false;
          if (res.data) {
            this.getFornitura();
            this.snackbar = true;
            this.snackbar_text = "Modifica effettuata con successo!";
            this.color = "green darken-3";
            this.mainIcon = "mdi-check-circle";
          }
        });
      } else {
        //Delete logic for other component and modules
        if (item.idciclo_fasi_fornitori > -1 || item.id_ciclostd_fasi_fornitori > -1) {
          item.validita = 0;
          this.deletedFornitura.push(item);
          if (item.idciclo_fasi_fornitori) {
            let index = this.fornitoriLista.findIndex(
              (it) => it.idciclo_fasi_fornitori == item.idciclo_fasi_fornitori
            );
            this.fornitoriLista.splice(index, 1);
            this.$emit("delFornitura", this.deletedFornitura);
            this.$emit("updateFornitura", this.fornitoriLista);
          }
          if (item.id_ciclostd_fasi_fornitori) {
            let index = this.fornitoriLista.findIndex(
              (it) => it.id_ciclostd_fasi_fornitori == item.id_ciclostd_fasi_fornitori
            );
            this.fornitoriLista.splice(index, 1);
            console.log('update phase :>> ');
            this.$emit("delFornitura", this.deletedFornitura);
            this.$emit("updateFornitura", this.fornitoriLista);
          }
          item = {};
        } else {
          let i = this.fornitoriLista.findIndex((it) => it === item);
          this.fornitoriLista.splice(i, 1);
          item = {};
        }
      }
    },
    // In order to set single fornitura values
    handleclick(val) {
      this.id_temp = val.id_temp ? val.id_temp : moment().unix();
      this.dataToUpdate = val;

      this.predefinito = val.predefinito;
      this.umfornitura = this.dataToUpdate._umfornitura;
      this.umtrasporto = this.dataToUpdate._umtrasporto;
      this.idciclo_fasi_fornitori = val.idciclo_fasi_fornitori;
      this.dialogFornitura = true;
      this.ragione_sociale = val.ragione_sociale;
      this.indirizzo = val.indirizzo;
      this.localita = val.localita;
      this.qta_minima_ordine = val.qta_minima_ordine;
      this.qta_lotto_minimo = val.qta_lotto_minimo;
      this.unita_misura = val.unita_misura;
      this.tempo_produzione = val.tempo_produzione;
      this.tempo_transito = val.tempo_transito;
      this.tempo_specifico = val.tempo_specifico;
      this.lead_time = val.lead_time;
      this.trasferimento_diretto = val.trasferimento_diretto;
      this.lead_misura = val.lead_misura;
      this.tempo_transit = val.tempo_transit;
      this.id_um_lead_time = val.id_um_lead_time;
      this.id_um_lotto = val.id_um_lotto;
      this.id_tipo_fornitura = val.id_tipo_fornitura;
      this.id_tipo_trasporto = val.id_tipo_trasporto;
      this.cod_anagrafica = val.codice;
      this.id_anagrafica = val.id_anagrafica;
      (this.id_ciclostd_dettaglio = val.id_commessa_dettaglio),
        (this.id_ciclostd_fasi_fornitori = val.id_ciclostd_fasi_fornitori);
    },
    // in order to get detail of anagrafica
    onSetItem(val) {
      this.anagraficaItem = val;
      this.cod_anagrafica = val.codice;
      this.indirizzo = val.indirizzo;
      this.localita = val.localita;
      this.ragione_sociale = val.ragione_sociale;
      this.dialogSearchAnagrafica = false;
    },
    closeModal() {
      this.dialogFornitura = false;
    },
    // in order to clear some fields
    openModal() {
      this.umfornitura = "";
      this.predefinito = 0;
      this.umfornitura = "";
      this.umtrasporto = "";
      this.ragione_sociale = "";
      this.localita = "";
      this.indirizzo = "";
      this.qta_minima_ordine = null;
      this.qta_lotto_minimo = null;
      this.unita_misura = "";
      this.tempo_produzione = null;
      this.tempo_transito = null;
      this.tempo_specifico = null;
      this.lead_time = null;
      this.trasferimento_diretto = 0;
      this.idciclo_fasi_fornitori = -1;
      this.id_ciclostd_fasi_fornitori = -1;
      this.lead_misura = null;
      this.id_um_lead_time = null;
      this.id_um_lotto = null;
      this.id_tipo_trasporto = null;
      this.id_tipo_fornitura = "";
      this.cod_anagrafica = "";
      this.dataToUpdate = {};
    },
    // IN order to search Ragione Sociale
    searchAnagrafica() {
      this.loading = true;
      this.progressBar = true;
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          token: localStorage.getItem("user_token"),
          cod_anagrafica: this.cod_anagrafica,
          des_anagrafica: this.ragione_sociale,
          tipo_anagrafica: "F",
        },
      };
      this.overlay = true;
      this.$store.dispatch("api", searchReq).then((res) => {
        this.dialogSearchAnagrafica = true;
        this.overlay = false;
        this.ragioneSocialeLista = res.data;
        if (this.ragioneSocialeLista.length > 0) {
          this.progressBar = false;
          this.loading = false;
        }
        if (this.ragioneSocialeLista.length === 0) {
          this.progressBar = false;
          this.loading = false;
          this.snackbar = true;
          this.snackbar_text = "Ragione Sociale non è stato trovato. Riprova !";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
          this.dialogSearchAnagrafica = false;
        }
      });
      this.client = false;
    },
    // In order to set Predefinito to only one item of the table
    doalert(item) {
      if (this.Tipo_component == "Cara") {
        if (item.id_articolo_fornitura > 0) {
          this.fornitoriLista.map((it) => {
            if (item.id_articolo_fornitura == it.id_articolo_fornitura) {
              it.predefinito = 1;
            } else {
              it.predefinito = 0;
            }
          });
        }else{
			this.fornitoriLista.map(it=>{
				if (it == this.fornitoriLista[this.fornitoriLista.length-1]) {
					it.predefinito = 1
				}else{
					it.predefinito = 0
				}
			})
		}

        let setCheckBoxes = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolofornitura",
            token: localStorage.getItem("user_token"),
            id_articolo: this.id_articolo,
            articolo_fornitura: this.fornitoriLista,
          },
        };
        this.$store.dispatch("api", setCheckBoxes).then((res) => {
          if (res.status == 200) {
            this.getFornitura;
          }
        });
      } else {
        this.fornitoriLista.filter((it) => {
          if (it !== item) {
            it.predefinito = 0;
            return this.fornitoriLista;
          }
          if (it === item) {
            it.predefinito = 1;
            return this.fornitoriLista;
          }
        });
      }
    },
    // In order to save a new fornitura or update an existing one
    salvaAnagrafica() {

      if (this.cod_anagrafica === "") {
        this.snackbar = true;
        this.mainIcon = "mdi-alert-outline";
        this.color = "yellow accent-4";
        this.snackbar_text = "Inserire il Codice e la Ragione Sociale prima di salvare.";
      }
      if ((this.idciclo_fasi_fornitori == -1 || this.id_ciclostd_fasi_fornitori == -1) && this.id_temp == -1) {
        let data = {
          codice: this.anagraficaItem.codice,
          id_anagrafica: this.anagraficaItem.id_anagrafica,
          id_articolo: this.id_articolo ? this.id_articolo : -1,
          idciclo_fasi_fornitori: -1,
          id_ciclostd_fasi_fornitori: this.id_ciclostd_fasi_fornitori
            ? this.id_ciclostd_fasi_fornitori
            : -1,
          id_ciclostd_dettaglio:
            this.id_ciclostd_dettaglio == -1 ? -1 : this.id_ciclostd_dettaglio,
          id_commessa_dettaglio:
            this.id_commessa_dettaglio == -1 ? -1 : this.id_commessa_dettaglio,
          id_ciclo_fasi: this.ID_CICLO_FASI ? this.ID_CICLO_FASI : -1,
          id_articolo_fornitura: -1,
          ragione_sociale: this.anagraficaItem.ragione_sociale,
          indirizzo: this.anagraficaItem.indirizzo,
          localita: this.anagraficaItem.localita,
          qta_minima_ordine: this.qta_minima_ordine ? this.qta_minima_ordine : null,
          qta_lotto_minimo: this.qta_lotto_minimo ? this.qta_lotto_minimo : null,
          id_um_lotto: this.id_um_lotto ? this.id_um_lotto : null,
          tempo_produzione: this.tempo_produzione ? parseFloat(this.tempo_produzione) : 0,
          tempo_transito: this.tempo_transito ? parseFloat(this.tempo_transito) : 0,
          tempo_specifico: this.tempo_specifico ? parseFloat(this.tempo_specifico) : 0,
          lead_time:
            parseFloat(this.tempo_produzione) +
            parseFloat(this.tempo_transito) +
            parseFloat(this.tempo_specifico),
          predefinito: this.predefinito ? this.predefinito : 0,
          id_um_lead_time: this.id_um_lead_time ? this.id_um_lead_time : null,
          trasferimento_diretto: this.trasferimento_diretto
            ? this.trasferimento_diretto
            : null,
          id_tipo_trasporto: this.id_tipo_trasporto ? this.id_tipo_trasporto : null,
          id_tipo_fornitura: this.id_tipo_fornitura,
          validita: 1,
          id_temp: moment().unix(),
        };
        let dataToadd = data;
        this.mizura_list.map((mo) => {
          if (this.id_um_lotto === mo.id_tabella) {
            dataToadd._umlotto = mo.des;
            data.des_um_lotto = mo.des;
          }
        });
        if (!data.id_um_lotto) data._umlotto = null;
        this.mizura_list.map((mi) => {
          if (this.id_um_lead_time === mi.id_tabella) {
            dataToadd.id_um_lead_time = mi.id_tabella;
            dataToadd._umleadtime = mi.des;
            data._umleadtime = mi.des;
          }
        });
        if (!data.id_um_lead_time) data._umleadtime = null;
        this.modalita_list.map((mo) => {
          if (this.id_tipo_fornitura === mo.id_tabella) {
            dataToadd.id_tipo_fornitura = mo.id_tabella;
            dataToadd._umfornitura = mo.des;
          }
        });
        if (!data.id_tipo_fornitura) data._umfornitura = null;

        this.incoterms_list.map((mo) => {
          if (this.id_tipo_trasporto === mo.id_tabella) {
            dataToadd.id_tipo_trasporto = mo.id_tabella;
            dataToadd._umtrasporto = mo.des;
          }
        });
        if (!data.id_tipo_trasporto) data._umtrasporto = null;
        let dataArray = [];
        //Creating Fornitura logic for caratteristiche where it is making direct call to backend
        if (this.Tipo_component == "Cara" && this.id_temp == -1) {
          let setFornitura = {
            controller: "Magazzino",
            method: "PUT",
            richiesta: {
              action: "setarticolofornitura",
              token: localStorage.getItem("user_token"),
              id_articolo: this.id_articolo,
              articolo_fornitura: [dataToadd],
            },
          };
          this.$store.dispatch("api", setFornitura).then(async (res) => {
            if (res.status === 200) {
              this.fornitoriLista = res.data;
              if (data.predefinito == 1) {
                await this.doalert(dataToadd);
              }
              this.snackbar = true;
              this.snackbar_text = "Modifica effettuata con successo!";
              this.color = "green darken-3";
              this.mainIcon = "mdi-check-circle";
            }
          });
        } else {
          dataArray.push(data);
          this.fornitoriLista.push(data);
          if (dataToadd.predefinito) {
            this.changePredefinito(dataToadd);
          }
          this.predefinito = false;
          this.dialogFornitura = false;
          this.id_ciclostd_fasi_fornitori = -1;
          this.idciclo_fasi_fornitori = -1;

          this.$emit("updateFornitura", this.fornitoriLista);
        }
      }
      if (
        this.dataToUpdate.idciclo_fasi_fornitori > -1 ||
        this.id_temp > -1 ||
        this.dataToUpdate.id_articolo_fornitura > -1
      ) {
        let data = {
          codice: this.cod_anagrafica,
          id_articolo: this.dataToUpdate.id_articolo ? this.dataToUpdate.id_articolo : -1,
          id_ciclo_fasi: this.ID_CICLO_FASI,
          id_ciclostd_dettaglio: this.dataToUpdate.id_ciclostd_dettaglio,
          id_anagrafica: this.id_anagrafica,

          id_articolo_fornitura: this.dataToUpdate.id_articolo_fornitura,
          id_commessa_dettaglio: this.dataToUpdate.id_commessa_dettaglio,
          id_ciclostd_fasi_fornitori: this.id_ciclostd_fasi_fornitori,
          ragione_sociale: this.ragione_sociale,
          indirizzo: this.indirizzo,
          localita: this.localita,
          predefinito: this.predefinito,
          idciclo_fasi_fornitori: this.idciclo_fasi_fornitori,
          qta_minima_ordine: this.qta_minima_ordine,
          qta_lotto_minimo: this.qta_lotto_minimo,
          trasferimento_diretto: this.trasferimento_diretto,
          id_um_lotto: this.id_um_lotto,
          lead_time:
            parseFloat(this.tempo_produzione) +
            parseFloat(this.tempo_transito) +
            parseFloat(this.tempo_specifico),
          tempo_produzione: parseFloat(this.tempo_produzione),
          tempo_transito: parseFloat(this.tempo_transito),
          tempo_specifico: parseFloat(this.tempo_specifico),
          id_um_lead_time: this.id_um_lead_time,
          id_tipo_trasporto: this.id_tipo_trasporto,
          validita: 1,
          id_tipo_fornitura: this.id_tipo_fornitura,
          date_ins: this.dataToUpdate.data_ins ? this.dataToUpdate.data_ins : "",
          date_mod: this.dataToUpdate.date_mod,
          id_utente: localStorage.id,
          id_temp: moment().unix(),
          id_temp_dett: this.dataToUpdate.id_temp_dett,
          note: this.dataToUpdate.note ? this.dataToUpdate.note : "",
        };
        this.mizura_list.map((mo) => {
          if (this.id_um_lotto === mo.id_tabella) {
            data.id_um_lotto = mo.id_tabella;
            data._umlotto = mo.des;
            return data;
          }
        });
        if (!data.id_um_lotto) data._umlotto = null;

        this.mizura_list.map((mo) => {
          if (this.id_um_lead_time === mo.id_tabella) {
            data.id_um_lead_time = mo.id_tabella;
            data._umleadtime = mo.des;
            return data;
          }
        });
        if (!data.id_um_lead_time) data._umleadtime = null;

        this.modalita_list.map((mo) => {
          if (data.id_tipo_fornitura === mo.id_tabella) {
            data._umfornitura = mo.des;
          }
        });
        if (!data.id_tipo_fornitura) data._umfornitura = null;

        this.incoterms_list.map((mo) => {
          if (data.id_tipo_trasporto === mo.id_tabella) {
            data.id_tipo_trasporto = mo.id_tabella;
            data._umtrasporto = mo.des;
          }
        });
        if (!data.id_tipo_trasporto) data._umtrasporto = null;

        let dataArray = [];
        dataArray.push(data);
        //Update Fornitura logic for caratteristiche where it is making direct call to backend
        if (this.Tipo_component == "Cara" && this.id_temp > -1) {
          let setFornitura = {
            controller: "Magazzino",
            method: "PUT",
            richiesta: {
              action: "setarticolofornitura",
              token: localStorage.getItem("user_token"),
              id_articolo: this.id_articolo,
              articolo_fornitura: dataArray,
            },
          };
          this.$store.dispatch("api", setFornitura).then(async (res) => {
            if (res.status == 200) {
              this.fornitoriLista = await res.data;
              if (data.predefinito == 1) {
                await this.doalert(data);
              }

              this.snackbar = true;
              this.snackbar_text = "Modifica effettuata con successo!";
              this.color = "green darken-3";
              this.mainIcon = "mdi-check-circle";
              return;
            }
          });
        } else {
          if (this.dataToUpdate.id_temp) {
            let i = this.fornitoriLista.findIndex(
              (it) => it.id_temp === this.dataToUpdate.id_temp
            );
            this.fornitoriLista.splice(i, 1, data);
            this.$emit("updateFornitura", this.fornitoriLista);
            if (data.predefinito) {
              this.changePredefinito(data);
            }
          } else {
            let i = this.fornitoriLista.findIndex(
              (it) =>
                it.idciclo_fasi_fornitori == this.dataToUpdate.idciclo_fasi_fornitori
            );
            this.fornitoriLista.splice(i, 1, data);
            if (data.predefinito) {
              this.changePredefinito(data);
            }
            this.$emit("updateFornitura", this.fornitoriLista);
          }

          this.id_ciclostd_fasi_fornitori = -1;
          this.idciclo_fasi_fornitori = -1;
          this.dialogFornitura = false;
        }
      }
      this.predefinito = false;
      this.cliente = "";
      this.indirizzo = "";
      this.localita = "";
      this.qta_minima_ordine = "";
      this.id_articolo_fornitura = null;
      this.ragione_sociale = "";
      this.qta_lotto_minimo = 0;
      this.lead_misura = "";
      this.unita_misura = "";
      this.tempo_produzione = 0;
      this.tempo_transito = 0;
      this.id_um_lotto = "";
      this.id_tipo_fornitura = "";
      this.id_tipo_trasporto = "";
      this.id_um_lead_time = "";
      this.tempo_specifico = 0;
      this.lead_time = 0;
      this.predefinito = 0;
      this.id_tipo_fornitura = "";
      this.dialog = false;
      this.dialogFornitura = false;
      this.cod_anagrafica = "";
      this.modalita = "";
      this.id_temp = -1;
      this.idciclo_fasi_fornitori = -1;
    },
    // Getting list of fornitura to populate data table in caratteristiche
    getFornitura() {
      let getFornitura = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolofornitura",
          token: localStorage.getItem("user_token"),
          id_articolo: this.ID_ARTICOLO,
        },
      };
      this.$store.dispatch("api", getFornitura).then((res) => {
        this.fornitoriLista = res.data;
      });
    },
    // Getting the single ciclo Fornitoni lista
    getarticoliciclofornitori() {
      this.loadingFornitura = true;
      this.progressBarFornitura = true;
      // IN order to get Ciclo fornitori list List to p
      let getarticoliciclofornitori = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliciclofornitori",
          token: localStorage.getItem("user_token"),
          id_ciclo_fasi: this.ID_CICLO_FASI,
          id_articolo: this.id_articolo,
        },
      };
      this.$store.dispatch("api", getarticoliciclofornitori).then((res) => {
        this.loadingFornitura = false;
        this.progressBarFornitura = false;
        this.fornitoriLista = res.data;
        this.$emit("updateFornitura", this.fornitoriLista);
      });
    },
  },
  watch: {
    tempo_produzione: {
      immediate: true,
      deep: true,
      handler() {
        const I = this.tempo_produzione;
        this.lead_time =
          parseFloat(this.tempo_produzione) +
          parseFloat(this.tempo_transito) +
          parseFloat(this.tempo_specifico);
        this[I] = !this[I];
      },
    },
    tempo_transito: {
      immediate: true,
      deep: true,
      handler() {
        const T = this.tempo_transito;
        this.lead_time =
          parseFloat(this.tempo_produzione) +
          parseFloat(this.tempo_transito) +
          parseFloat(this.tempo_specifico);
        this[T] = !this[T];
      },
    },
    tempo_specifico: {
      immediate: true,
      deep: true,
      handler() {
        const S = this.tempo_specifico;
        this.lead_time =
          parseFloat(this.tempo_produzione) +
          parseFloat(this.tempo_transito) +
          parseFloat(this.tempo_specifico);
        this[S] = !this[S];
      },
    },
  },
  destroyed() {
    this.fornitoriLista = [];
  },
  mounted() {
    if (this.Tipo_component == "Cara") {
      this.getFornitura();
    }
    //   Getting list of Macchina
    if (this.ID_ARTICOLO && this.Tipo_component !== "Cara") {
      this.getarticoliciclofornitori();
    } else {
      this.fornitoriLista = this.data;
    }
    // Getting all the lista value for select fields
    this.progressBarCiclo = true;
    this.loadingCiclo = true;
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'PORTO','UNITA_MISURA','CAUSALE_TRASPORTO'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array PORTO che mi arriva dal BE
      this.modalita_list = res.data.PORTO;
      this.incoterms_list = res.data.CAUSALE_TRASPORTO;
      // Gestisco l'array UNITA_MISURA che mi arriva dal BE
      this.mizura_list = res.data.UNITA_MISURA;
      res.data.UNITA_MISURA.map((el) => {
        if (el.val_txt === "H") {
          this.unita_misura_lead_time.push(el);
        }
      });
      this.unita_misura_mod_list = res.data.UNITA_MISURA;
    });
  },
};
</script>
<style css>
.dialogshadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  overflow-y: hidden !important;
}
</style>
