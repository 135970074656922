<template>
  <div class="graph_slides">
    <figure><div></div></figure>
    <aside id="slides_nav" class="graph_scrollbar">
        <div @mousedown="grab_start" v-for="(item, i) in SLIDES.data" :key="i" :id="'nav_slide_'+i"><img /></div>
    </aside>
    <main id="slides_main" class="graph_scrollbar">
      <div v-for="(item, i) in SLIDES.data" :key="i" :id="'main_slide_'+i" >
        <header class="graph_scrollbar">
          <span v-for="(param,j) in item.overall" :key="j">
            {{param.descrizione}} : {{param.layout}}
          </span>
        </header>
        <div>
          <Grafico :index="Number(i)" />
        </div>
      </div>
    </main>
    <footer>
      <div @click="(SLIDES.footer_show==0)?SLIDES.footer_show=null:SLIDES.footer_show=0">
        <ul :data-show="(SLIDES.footer_show==0)?true:false">
          <li v-for="(item,i) in SLIDES.RATIOS" :key="i" @click="SLIDES.setRatio(item)">{{item.name}}</li>
        </ul>
        <GraphSVG name="aspect_ratio" /><span>{{SLIDES.selected_ratio}}</span>
      </div>
      <div>
        <div :data-show="(SLIDES.footer_show==1)?true:false">
          <input @keydown="guard_number" type='number' min='0' max='600' placeholder='Timer' v-model="SLIDES.set_timer">
          <span @click="SLIDES.footer_show=null">OK</span>
        </div>
        <GraphSVG name="timer" @click="(SLIDES.footer_show==1)?SLIDES.footer_show=null:SLIDES.footer_show=1" />
        <span @click="(SLIDES.footer_show==1)?SLIDES.footer_show=null:SLIDES.footer_show=1">
          {{ (SLIDES.set_timer==0||SLIDES.set_timer==null||SLIDES.set_timer==undefined)?'IMPOSTA TIMER':SLIDES.set_timer+'s'}}
        </span>
      </div>
      <div @click="set_slideshow">
        <GraphSVG name="presentation" /><span>AVVIA PRESENTAZIONE</span>
      </div>
    </footer>
    <div class='graph_slideshow' :data-show="(SLIDES.slideshow==true)?true:false">
      <span></span>
      <main></main>
      <data></data>
      <nav>
        <div><span></span><GraphSVG @click="slide_backwards" name='arrow_left' /></div>
        <div><span></span><GraphSVG @click="slide_forward" name='arrow_right' /></div>
        <GraphSVG @click="unset_slideshow" name='close' id="close_slideshow"/>
        <div>
          <GraphSVG 
            @click="(SLIDES.slideshow_interval==null)?play_slideshow():pause_slideshow()" 
            :name="(SLIDES.slideshow_interval==null)?'play':'pause'"
            v-if="Number(SLIDES.set_timer)>0" />
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

import Grafico from "@/components/GRAPH/Grafici/Grafici.vue";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import SLIDES from "./Slides.js"

export default {
  name: "ChartSlides",
  components: {
    Grafico,
    GraphSVG,
  },
  data() {
      return {
        id : 0,
        navbarpath : [],
        NETWORK: null,
        SLIDES : new SLIDES(),
        showloading : true,
      };
    },
    mounted() {      
      this.NETWORK = this.$store.state.Network;
    },
    beforeDestroy(){
      window.removeEventListener('resize',this.resize);
      clearInterval(this.SLIDES.update_interval);
    },
    destroyed(){ window.freeComponent(this); },
    methods: {      

      runMounted(id){

        this.id = id;
        window.addEventListener('resize',this.resize);              
        this.get_dashboard();    
        //this.SLIDES.update_interval = setInterval(this.get_dashboard,60000); 

        document.body.dispatchEvent(new CustomEvent('graph_toolbar_items',{detail:[]}));
      },

      get_dashboard(){
        let request = {
          controller : 'Graph',
          body : {
            action: "getDashboardDettaglio",
            prefisso_utente: localStorage.prefisso_utente,
            id_ds: this.id,
            token: localStorage.user_token
          },
          method: 'POST',
          on : {
            load : this.set_dashboard
          },
          showloading : this.showloading
        }
        this.NETWORK.AjaxRequest(request);
        this.showloading = false
      },
      set_dashboard(response){
        this.dashboard = response.dashboard[0];
        
        this.SLIDES.data = ((Array.isArray(this.dashboard.grafici)?this.dashboard.grafici:[]));
        for(let i=0;i<this.SLIDES.data.length;i++){
          this.SLIDES.data[i].index = i;
        }
        let that = this;

        this.$nextTick(function(){
          for(let i=0;i<that.SLIDES.data.length;i++){

            
            that.SLIDES.chartResize(i);
            that.SLIDES.chartUpdate(that.SLIDES.data[i],i); 
          }        
        });
      },

      resize(){this.SLIDES.chartResizeAll();},

      guard_number(event){
        let key = event.key.charCodeAt(0);
        let code = event.key.toLowerCase();
        let allowed = ['backspace','arrowleft','arrowright','delete'];
        if((key<48||key>57)&&allowed.indexOf(code)<0){event.preventDefault();}
        if(code=='enter'){this.SLIDES.footer_show=null;}
      },

      set_slideshow(){if(!this.SLIDES.slideshow){this.SLIDES.setSlideshow();}},
      slide_forward(){if(this.SLIDES.slideshow){this.SLIDES.slideForward();}},
      slide_backwards(){if(this.SLIDES.slideshow){this.SLIDES.slideBackwards();}},
      play_slideshow(){if(this.SLIDES.slideshow){this.SLIDES.playSlideshow();}},
      pause_slideshow(){if(this.SLIDES.slideshow){this.SLIDES.pauseSlideshow();}},
      unset_slideshow(){if(this.SLIDES.slideshow){this.SLIDES.unsetSlideshow();}},

      grab_start(event){  
        if(event.button!=0){return;}     
        document.body.addEventListener('mousemove',this.grab_move);
        document.body.addEventListener('mouseleave',this.grab_end);
        document.body.addEventListener('mouseup',this.grab_end);  
        this.SLIDES.grabStart(event);      
      },
      grab_move(event){this.SLIDES.grabMove(event);},
      grab_end(){
        document.body.removeEventListener('mousemove',this.grab_move);
        document.body.removeEventListener('mouseleave',this.grab_end);
        document.body.removeEventListener('mouseup',this.grab_end);
        this.SLIDES.grabEnd();
      }

    }


};
</script>

<style src="./Slides.css"> </style>
