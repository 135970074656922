<template>
  <div class="ml-5 mr-5 pb-0 mb-0 mt-0" align="center">
    <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
    <!-- <v-dialog v-model="modalAddAvviso" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Aggiungi avviso:</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="gruppi"
                  label="Gruppi"
                  v-model="gruppi_sel"
                  item-text="des_gruppo"
                  item-value="id_gruppo"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  v-model="des_avviso"
                  label="Testo Avviso"
                  placeholder="...."
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="modalAddAvviso = false">Annulla</v-btn>
          <v-btn color="success" @click="inviaAvviso()">Invia</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <!-- <v-navigation-drawer
      v-model="drawer"
      class="mr-n5"
      width="500px"
      right
      absolute
      temporary
      :stateless="modalAddAvviso"
    >
      <v-toolbar color="primary" dense>
        <v-toolbar-title>AVVISI</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              fab
              small
              @click="modalAddAvviso = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi avviso</span>
        </v-tooltip>
      </v-toolbar>
      <v-autocomplete
        style="width:60%"
        v-model="tipo_avviso_selezionato"
        filled
        class="mt-2"
        rounded
        :items="gruppi"
        item-text="des_gruppo"
        hide-details
        label="Filtra"
      ></v-autocomplete>
      <div style="max-height:70vh;overflow-y:auto" class="mt-4">
        <div v-for="(avviso,index) in avvisi" :key="index">
          <v-alert
            dark
            v-if="avviso.gruppi.includes('-1')"
            icon="mdi-alert"
            class="mr-4 ml-4"
            prominent
            text
            dense
            color="blue"
          >{{avviso.testo}}</v-alert>
          <v-alert
            dark
            v-else
            class="mr-4 ml-4"
            color="blue-grey"
            text
            dense
            icon="mdi-school"
            prominent
          >{{avviso.testo}}</v-alert>
        </div>
      </div>
    </v-navigation-drawer>-->
    <v-row style="overflow-y:auto">
      <v-navigation-drawer
        v-model="drawer_utenti"
        style="background-color:#37474F "
        absolute
        width="400"
        class="ma-2 mb-10"
        dark
        temporary
      >
        <v-list-item>
          <v-list-item-title>Seleziona:</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        <v-text-field
          dense
          class="rounded-xl mt-2 mb-2"
          flat
          hide-details
          label=" Cerca"
          v-model="testo_cerca_utente"
          prepend-inner-icon="search"
          solo-inverted
          style="max-width:350px"
        ></v-text-field>
        <v-virtual-scroll :items="utenti" :key="drawer_utenti" :item-height="50" style="height:85%">
          <template v-slot="{ item }">
            <v-list-item :key="item.id" @click="creaChatUtente(item)">
              <v-list-item-avatar class>
                <v-img :src="item.photoUrl"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.nickname }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-navigation-drawer>
      <v-col cols="12">
        <div v-if="this.avvisi.length != 0">
          <v-alert
            v-if="this.avvisi[0].gruppi.includes('-1')"
            dark
            icon="mdi-alert"
            class="mr-4 ml-4 text-truncate"
            prominent
            dense
            color="blue"
          >{{this.avvisi.length > 0? this.avvisi[0].testo: "" }}</v-alert>
          <v-alert
            v-else
            dark
            class="mr-4 ml-4 text-truncate"
            color="blue-grey"
            dense
            icon="mdi-school"
            prominent
          >{{this.avvisi.length > 0? this.avvisi[0].testo: "" }}</v-alert>
        </div>
        <v-tabs v-model="tab" class="pt-0 mt-0" fixed-tabs background-color="#E8EAF6
">
          <v-tab @click="cambioTab('utenti')">UTENTI</v-tab>
          <v-tab @click="cambioTab('gruppi')">GRUPPI</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item style="background-color:#E8EAF6
">
            <v-col class="mb-0 pb-0">
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    dense
                    class="rounded-xl mt-0"
                    flat
                    hide-details
                    label=" Cerca"
                    v-model="testo_cerca"
                    prepend-inner-icon="search"
                    solo-inverted
                    style="max-width:350px"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    dark
                    class="mt-n1"
                    fab
                    color="indigo"
                    @click="modalNuovaChat"
                    medium
                    style="width:45px;height:45px"
                  >
                    <v-icon class="mb-0 pb-0">mdi-message-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-progress-circular
              v-if="caricamento_utenti"
              class="mt-10"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <v-list
              v-else
              rounded
              style="background-color: #E8EAF6;"
              dense
              class="text-left mt-0 pt-0"
              ref="lista_utenti"
              :key="cont"
            >
              <v-list-item-group three-line style="overflow-y:auto;" v-model="utente_sel">
                <v-list-item
                  @click="selezionaUtente(item)"
                  rounded
                  v-for="(item,index) in items"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-img
                      @click.stop="dialog_img_profile = true;profileSelected=item.photoUrl"
                      :src="item.photoUrl"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="pt-0 ma-0 pb-0">
                    <v-list-item-title v-html="item.nickname"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.mess[item.mess.length-1].idFrom != user.data.id "
                    >
                      <h3
                        v-if="item.mess[item.mess.length-1].stato==1"
                      >{{item.mess[item.mess.length-1].content}}</h3>
                      <h4 v-else color="teal">{{item.mess[item.mess.length-1].content}}</h4>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <v-icon
                        small
                        v-if="item.mess[item.mess.length-1].stato==0"
                        color="primary"
                      >mdi-check-all</v-icon>
                      <v-icon small v-else>mdi-check</v-icon>
                      {{item.mess[item.mess.length-1].content}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-dialog v-model="dialog_img_profile" persistent max-width="290">
              <v-card>
                <v-img :src="profileSelected" height="200px">
                  <v-row class="mb-0 pb-0 mt-0 pt-0">
                    <v-col class="align-right mr-2 mb-0 pb-0 mt-0 pt-0">
                      <v-btn fab class @click="dialog_img_profile = false" text color="error" small>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-img>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <v-tab-item style="background-color:#E8EAF6">
            <v-col class="mb-0 pb-0">
              <v-row>
                <v-col cols="12" class="align-left">
                  <v-text-field
                    dense
                    class="rounded-xl mt-0"
                    flat
                    hide-details
                    label=" Cerca"
                    v-model="testo_gruppo"
                    prepend-inner-icon="search"
                    solo-inverted
                    style="max-width:350px"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-list
              rounded
              style="background-color: #E8EAF6;"
              dense
              class="text-left mt-0 pt-0"
              ref="lista_gruppi"
              :key="cont"
            >
              <v-list-item-group
                three-line
                style="overflow-y:auto;max-height:350px"
                v-model="gruppo_sel"
                :key="cont"
              >
                <v-list-item
                  rounded
                  v-for="(item,index) in gruppi"
                  :key="index"
                  @click="selezionaGruppo(item)"
                >
                  <v-list-item-avatar class>
                    <v-icon>mdi-account-group-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="pt-0 ma-0 pb-0">
                    <v-list-item-title v-html="item.des_gruppo"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.content!= null">
                      <span class="mr-2 blue-grey--text">{{item.nickname}}:</span>
                      <b>{{item.content}}</b>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as fb from "../../plugins/firebase";

export default {
  data: () => ({
    tab: null,
    dialog_img_profile: false,
    profileSelected: null,
    drawer_utenti: false,
    drawer_loading: false,
    utente_sel_chat: null,
    testo_cerca: "",
    testo_gruppo: "",
    testo_cerca_utente: "",
    drawer: false,
    modalAddAvviso: false,
    items: [],
    items_support: [],

    utenti_support: [],
    user: {},
    utenti: [],
    tipo_avvisi: ["foo", "bar", "fizz", "buzz"],
    tipo_avviso_selezionato: null,
    utente_sel: {},
    utente_sel_obj: {},
    gruppo_sel: {},
    gruppo_sel_obj: {},
    cont: 0,
    avvisi: [],
    des_avviso: "",
    tabella_utenti: [],
    gruppi: [],
    gruppi_sel: [],
    gruppi_support: [],
    snackbar: false,
    snackbar_text: "",
    cont_aggiornamento: 0,
    caricamento_utenti: true,
    id_azienda: -1,
    snackbar_color: "error",
    contatore_avvisi: 0,
    ultima_lettura: 0,
    valore_modifica_utenti: 0,
  }),
  mounted() {
    this.caricamento_utenti = true;
    this.user = this.$store.state.user;
    try {
      fb.db
        .collection("utenti")
        .where("id_azienda", "==", parseInt(localStorage.id_azienda))
        .onSnapshot((querySnapshot) => {
          this.utenti = querySnapshot.docs.map((doc) => doc.data());
          this.utenti = this.utenti.filter((e) => e.id != this.user.data.id);
          this.utenti_support = querySnapshot.docs
            .map((doc) => doc.data())
            .filter((e) => e.id != this.user.data.id);
          this.drawer_loading = false;
        });
    } catch (error) {
      console.log(error);
    }
    this.getUsers();
    this.getGruppi();
  },
  watch: {
    valore_modifica_utenti() {
      this.streamChat();
    },
    testo_cerca(val) {
      this.filtraUtenti(val);
    },
    testo_cerca_utente(val) {
      this.filtraCercaUtenti(val);
    },
    testo_gruppo(val) {
      this.filtraGruppi(val);
    },
  },
  methods: {
    modalNuovaChat() {
      this.drawer_utenti = true;
    },
    cambioTab(arrivo) {
      this.cont++;
      if (arrivo == "utenti") {
        this.gruppo_sel = null;
      } else {
        this.utente_sel = null;
      }
    },
    getAvvisi(id_azienda) {
      try {
        fb.db
          .collection("avvisi")
          .where("id_azienda", "==", parseInt(id_azienda))
          .orderBy("timestamp", "desc")
          .onSnapshot((querySnapshot) => {
            this.avvisi = querySnapshot.docs.map((doc) => doc.data());
            this.avvisi.forEach((element) => {
              if (parseInt(element.timestamp) > parseInt(this.ultima_lettura)) {
                this.contatore_avvisi++;
              }
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getGruppi() {
      var id_azienda = localStorage.id_azienda;
      fb.db
        .collection("t_gruppo")
        .where("id_azienda", "==", id_azienda)
        .onSnapshot((querySnapshot) => {
          var gruppi = querySnapshot.docs.map((doc) => doc.data());
          if (gruppi != undefined) {
            this.gruppi = gruppi;
            this.gruppi_support = gruppi;
          }
          // this.gruppi.unshift({
          //   des_gruppo: "Tutti gli utenti",
          //   cod_gruppo: "Tutti gli utenti",
          //   id_gruppo: -1,
          // });
        });
    },
    getUsers() {
      let that = this;
      fb.db
        .collection("utenti")
        .where("id", "==", this.user.data.id)
        .onSnapshot((querySnapshot) => {
          var utente = querySnapshot.docs.map((doc) => doc.data());
          this.ultima_lettura = utente[0].ultima_lettura;
          if (this.ultima_lettura == undefined) this.ultima_lettura = 0;
          this.id_azienda = utente[0].id_azienda;
          this.getAvvisi(this.id_azienda);

          fb.db
            .collection("ultimi_messaggi")
            .where("idUtenti", "array-contains", that.user.data.id)
            .onSnapshot((querySnapshot) => {
              var temp = querySnapshot.docs.map((doc) => doc.data());
              temp.forEach((f) => {
                var id_controllare = "-1";
                if (f.idTo == that.user.data.id) {
                  id_controllare = f.idFrom;
                } else {
                  id_controllare = f.idTo;
                }
                fb.db
                  .collection("utenti")
                  .where("id", "==", id_controllare)
                  .get()
                  .then((querySnapshot) => {
                    var sup = querySnapshot.docs.map((doc) => doc.data());
                    sup.forEach((elem) => {
                      var a = this.items.filter((e) =>
                        e.id.toLowerCase().includes(elem.id.toLocaleLowerCase())
                      );

                      if (a.length > 0) {
                        var index = this.items.indexOf(a[0]);
                        if (
                          parseInt(this.$store.state.user.data.creato) <=
                          parseInt(elem.createAt)
                        ) {
                          this.items[index]["chatId"] =
                            this.$store.state.user.data.id + "-" + elem.id;
                        } else {
                          this.items[index]["chatId"] =
                            elem.id + "-" + this.$store.state.user.data.id;
                        }
                        this.items[index]["mess"] = [
                          {
                            content: f.content,
                            idFrom: f.idFrom,
                            idTo: f.idTo,
                            stato: f.stato,
                          },
                        ];
                        this.items[index]["timestamp"] = f.timestamp;
                        this.items[index]["ultimo_mex"] = 0;
                      } else {
                        if (
                          parseInt(this.$store.state.user.data.creato) <=
                          parseInt(elem.createAt)
                        ) {
                          elem["chatId"] =
                            this.$store.state.user.data.id + "-" + elem.id;
                        } else {
                          elem["chatId"] =
                            elem.id + "-" + this.$store.state.user.data.id;
                        }

                        elem["mess"] = [
                          {
                            content: f.content,
                            idFrom: f.idFrom,
                            idTo: f.idTo,
                            stato: f.stato,
                          },
                        ];
                        elem["ultimo_mex"] = 0;
                        elem["timestamp"] = f.timestamp;
                        this.items_support.unshift(elem);
                        this.items.unshift(elem);
                      }
                    });
                  });
              });
            });

          // fb.db
          //   .collection("ultimi_messaggi")
          //   .where("idTo", "==", that.user.data.id)
          //   .onSnapshot((querySnapshot) => {
          //     var temp = querySnapshot.docs.map((doc) => doc.data());
          //     temp.forEach((f) => {
          //       fb.db
          //         .collection("utenti")
          //         .where("id", "==", f.idFrom)
          //         .get()
          //         .then((querySnapshot) => {
          //           var sup = querySnapshot.docs.map((doc) => doc.data());
          //           sup.forEach((elem) => {
          //             var a = this.items.filter((e) =>
          //               e.id.toLowerCase().includes(elem.id.toLocaleLowerCase())
          //             );
          //             if (a.length > 0) {
          //               var index = this.items.indexOf(a[0]);
          //               if (
          //                 parseInt(this.$store.state.user.data.creato) <=
          //                 parseInt(elem.createAt)
          //               ) {
          //                 this.items[index]["chatId"] =
          //                   this.$store.state.user.data.id + "-" + elem.id;
          //               } else {
          //                 this.items[index]["chatId"] =
          //                   elem.id + "-" + this.$store.state.user.data.id;
          //               }
          //               this.items[index]["mess"] = [{ content: f.content }];
          //               this.items[index]["ultimo_mex"] = 0;
          //               this.items[index]["timestamp"] = f.timestamp;
          //             } else {
          //               if (
          //                 parseInt(this.$store.state.user.data.creato) <=
          //                 parseInt(elem.createAt)
          //               ) {
          //                 elem["chatId"] =
          //                   this.$store.state.user.data.id + "-" + elem.id;
          //               } else {
          //                 elem["chatId"] =
          //                   elem.id + "-" + this.$store.state.user.data.id;
          //               }
          //               elem["mess"] = [{ content: f.content }];
          //               elem["ultimo_mex"] = 0;
          //               elem["timestamp"] = f.timestamp;
          //               this.items_support.unshift(elem);
          //               this.items.unshift(elem);
          //             }
          //           });
          //         });
          //     });
          //   });
        });
      console.log("this.items", this.items);
      this.caricamento_utenti = false;
    },
    openConnection() {
      // fb.db.collection("messages").onSnapshot(() => {
      //   this.streamChat().then(() => {
      //     this.cont_aggiornamento++;
      //   });
      // });
    },
    sortJSON(data, key) {
      return data.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    },
    async streamChat() {
      fb.db
        .collection("ultimi_messaggi")
        .orderBy("timestamp", "asc")
        .onSnapshot((querySnapshot) => {
          var mess = querySnapshot.docs.map((doc) => doc.data());
          mess.forEach((element) => {
            this.items.forEach((el) => {
              if (el.chatId == element.chatId) {
                el.mess.push(element);
                el.ultimo_mex = element.timestamp;
              }
            });
            //this.items = this.alasql("SELECT * FROM ? ORDER BY ", [this.items]);
          });
          var a = [];
          this.items.forEach((element) => {
            a.push(element);
          });
          var b = this.sortJSON(a, "ultimo_mex");
          this.cont++;
          this.items = b;
          this.items_support = b;
          var index = this.items.indexOf(this.utente_sel_obj);
          if (this.utente_sel != index) {
            this.utente_sel = 0;
          }
        });

      this.caricamento_utenti = false;
    },
    inviaAvviso() {
      if (
        this.des_avviso == "" ||
        this.des_avviso == " " ||
        this.des_avviso == undefined
      ) {
        this.snackbar_text = "INSERIRE UN MESSAGGIO VALIDO";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }
      var test = this.des_avviso.replace(/(\r\n|\n|\r)/gm, "");
      if (test == "" || test == " ") {
        this.snackbar_text = "INSERIRE UN MESSAGGIO VALIDO";
        this.snackbar_color = "error";
        this.snackbar = true;
        this.des_avviso = "";

        return;
      }
      // se ad un gruppo metto un altro
      const now = new Date();
      //const secondsSinceEpoch = now.getTime();
      fb.db
        .collection("avvisi")
        .doc(now.getTime().toString())
        .set({
          id_azienda: this.id_azienda,
          testo: this.des_avviso,
          timestamp: now.getTime().toString(),
          gruppi: this.gruppi_sel.toString(),
          type: 0,
          stato: 1,
        })
        .then(() => {
          this.des_avviso = "";
          this.modalAddAvviso = false;
          this.snackbar_text = "Avviso inviato";
          this.snackbar = true;
          this.snackbar_color = "success";
          this.ultima_lettura = now.getTime().toString();
          this.contatore_avvisi = 0;
          this.gruppi_sel = [];
          this.drawer = false;
        })
        .catch(function (error) {
          this.des_avviso = "";
          this.snackbar_text = "Errore";
          this.snackbar = true;
          this.snackbar_color = "error";
          this.ultima_lettura = now.getTime().toString();
          this.contatore_avvisi = 0;
          this.gruppi_sel = [];
          console.error("Error writing document: ", error);
        });
      this.messaggio = "";
    },
    apriModalAvvisi() {
      this.drawer = true;
      const now = new Date();
      //setto ultima vista messaggi ad adesso

      fb.db
        .collection("utenti")
        .doc(this.user.data.id)
        .update({ ultima_lettura: now.getTime().toString() })
        .then(() => {
          this.contatore_avvisi = 0;
          this.ultima_lettura = now.getTime().toString();
        });
      // If you need the doc id, use `doc.id`
    },
    filtraGruppi(val) {
      this.gruppi = this.gruppi_support;
      console.log("filtra filtraGruppi", this.gruppi_support, val);
      this.gruppi = this.gruppi.filter((e) =>
        e.des_gruppo.toLowerCase().includes(val.toLowerCase())
      );
    },
    filtraUtenti(val) {
      this.items = this.items_support;

      this.items = this.items.filter((e) =>
        e.nickname.toLowerCase().includes(val.toLowerCase())
      );
    },
    filtraCercaUtenti(val) {
      this.utenti = this.utenti_support;
      this.utenti = this.utenti.filter((e) =>
        e.nickname.toLowerCase().includes(val.toLowerCase())
      );
    },
    creaChatUtente(item) {
      this.drawer_utenti = false;
      this.utente_sel_obj = item;
      this.$emit("aggiornaUtente", item);
    },
    selezionaUtente(val) {
      this.utente_sel_obj = val;
      this.$emit("aggiornaUtente", val);
    },
    selezionaGruppo(val) {
      this.gruppo_sel_obj = val;
      this.$emit("aggiornaGruppo", val);
    },
    aggiornaModelItems(item) {
      if (item.arrivo == "utente") {
        var sup = this.items.filter((f) => f.id == item.id);

        if (sup.length > 0) {
          var index = this.items.indexOf(sup[0]);
          this.items.splice(index, 1);
          this.items.unshift(sup[0]);
        }
        this.utente_sel = 0;
      }
    },
    scrollToTop() {
      let that = this;
      var checkExist = setInterval(function () {
        if (that.$refs.lista_gruppi) {
          clearInterval(checkExist);
          var content = that.$refs.lista_gruppi;
          content.scrollTop = 0;
        }
      }, 100);
    },
  },
};
</script>