<template>
  <div id="listener_tabella_regole">
    <v-data-table
      class="elevation-1"
      :headers="headers_regole"
      :items="items_regole"
    >
      <!-- Titolo tabella -->
      <template v-slot:top>
        <v-toolbar class="sfondoStandard" dense>
          <v-toolbar-title class="white--text">Regole</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="aggiungiRegola" small v-bind="attrs" v-on="on">
                <v-icon color="primary"> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Nuova Regola</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="goBack" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <!-- Bottone dialog degli step -->
      <template v-slot:[`item.dettaglio_step`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickRegolaDialog(item)"
              small
              v-bind="attrs"
              v-on="on"
              ><v-icon color="primary">mdi-pencil</v-icon></v-btn
            >
          </template>
          <span>Mostra Dettagli</span>
        </v-tooltip>
      </template>

      <!-- Colonna degli ambiti manutentivi -->
      <template v-slot:[`item.regole_dettaglio`]="{ item }">
        <span v-for="(ele, i) in item.regole_dettaglio" :key="i">
          <span v-if="i > 0 && ele.des != null">{{ ", " }}</span>
          <span class="text-nowrap">{{ ele.des }}</span>
        </span>
      </template>

      <!-- Colonna dell'importo della regola -->
      <template v-slot:[`item.importo`]="{ item }">
        {{ item.importo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
      </template>
    </v-data-table>

    <!-- Dialog per il dettaglio delle regole -->
    <v-dialog persistent v-model="dialog_modifica_regola">
      <v-card>
        <v-toolbar class="sfondoStandard" dense
          ><v-toolbar-title class="white--text">{{
            regola_sel.id_regola === -1
              ? `Dettagli Nuova Regola`
              : `Dettagli della Regola ${regola_sel.des_regola}`
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaModificheRegola"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="success">mdi-floppy</v-icon></v-btn
              >
            </template>
            <span>Salva Regola</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDettaglioRegola"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip></v-toolbar
        >

        <div>
          <v-row justify="center">
            <v-col cols="2">
              <v-text-field
                disabled
                placeholder="(Automatico)"
                label="Codice"
                v-model="regola_sel.cod_regola"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Descrizione" v-model="regola_sel.des_regola">
              </v-text-field
            ></v-col>
            <v-col cols="5">
              <v-select
                @change="associaIdTipoIntervento"
                v-model="regola_sel.tipi_regole_dettaglio"
                :items="tipo_intervento_lista"
                item-text="des"
                item-value="des"
                :menu-props="{ maxHeight: '400' }"
                label="Ambiti Manutentivi"
                placeholder="Associa"
                multiple
              >
                <template #label>
                  <span class="">Ambiti Manutentivi</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_AMBITO_MANUTENTIVO"
                      nome="Ambiti Manutentivi"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      filter="validita = 1"
                      :dati="[{ des: '' }]"
                    />
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" id="listener_soglia_regola">
              <!--<v-text-field
                label="Importo (€)"
                type="number"
                v-model="regola_sel.importo"
                min="0"
              ></v-text-field>-->
              <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_soglia_regola'"
                :label="'Importo (€)'"
                :id="'inputRegola'"
              />
            </v-col>
          </v-row>
        </div>
        <v-data-table
          :headers="headers_step"
          :items="regola_sel_steps.filter((f) => f.validita == 1)"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id_step"
          show-expand
          class="elevation-1"
          :key="contatore"
        >
          <template v-slot:top>
            <v-toolbar class="sfondoStandard" dense>
              <v-toolbar-title>Step della Regola</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="aggiungiStep" small v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Step</span>
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <!-- Bottone di modifica dello step -->
          <template v-slot:[`item.modifica_step`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaStep(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Modifica Step</span>
            </v-tooltip>
          </template>

          <!-- Colonna dell'importo dello step -->
          <template v-slot:[`item.importo`]="{ item }">
            {{ item.importo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          </template>
          <template v-slot:[`item.elimina_step`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="eliminaStep(item)" small v-bind="attrs" v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Elimina Step</span>
            </v-tooltip>
          </template>
          <!-- Drill down dei dettagli dello step-->
          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="pa-0"
              style="background-color: white"
              :colspan="headers.length"
            >
              <v-data-table
                class="elevation-3"
                :headers="
                  headers_approvatori.filter((elem) => elem.text != 'Modifica')
                "
                hide-default-footer
                :items="item.approvatori"
              >
                <!-- Titolo tabella -->
                <template v-slot:top>
                  <v-toolbar class="sfondoStandard" dense>
                    <v-toolbar-title class="white--text"
                      >Dettagli dello Step {{ item.codice }}</v-toolbar-title
                    >
                  </v-toolbar>
                </template>

                <!-- Colonna dell'importo dell'approvatore -->
                <template v-slot:[`item.importo`]="{ item }">
                  {{
                    item.importo
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }}
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog_modifica_step">
      <v-card>
        <v-toolbar class="sfondoStandard" dense>
          <v-toolbar-title>{{
            step_sel.id_step === -1 ? "Creazione Nuovo Step" : `Modifica Step`
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }"
              ><v-btn @click="salvaModificheStep" small v-bind="attrs" v-on="on"
                ><v-icon color="success">mdi-floppy</v-icon></v-btn
              ></template
            ><span>Salva Step</span></v-tooltip
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom
            ><template v-slot:activator="{ on, attrs }"
              ><v-btn @click="chiudiModificaStep" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              ></template
            ><span>Annulla</span></v-tooltip
          >
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row justify="center">
            <v-col cols="2">
              <v-text-field
                placeholder="(Automatico)"
                disabled
                label="Codice"
                v-model="step_sel.cod_step"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Descrizione"
                v-model="step_sel.des_step"
              ></v-text-field>
            </v-col>
            <v-col cols="2" id="listener_soglia_step">
              <!--<v-text-field
                label="Importo (€)"
                v-model="step_sel.importo"
                min="0"
                type="number"
              ></v-text-field>-->
              <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_soglia_step'"
                :label="'Importo (€)'"
                :id="'inputStep'"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                disabled
                label="Num. Approvazioni"
                v-model="step_sel.num_approvazioni"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                label="Tipo Approvazione"
                placeholder="Seleziona"
                :items="tipi_approvazione_lista"
                required
                item-text="des"
                item-value="id_tabella"
                v-model="step_sel.id_tipo_approvazione"
                @change="cambioTipoApprovazione"
              >
                <template #label>
                  <span class="">Tipo Approvazione</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_TIPO_APPROVAZIONE"
                      nome="Tipi Approvazione"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      filter="validita = 1"
                      :dati="[{ des: '' }]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="elevation-1"
                :headers="headers_approvatori"
                :items="
                  step_sel.approvatori.filter((elem) => elem.validita == 1)
                "
              >
                <!-- Titolo tabella -->
                <template v-slot:top>
                  <v-toolbar class="sfondoStandard" dense>
                    <v-toolbar-title class="white--text"
                      >Dettagli</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-btn
                          @click="aggiungiApprovatore"
                          small
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon color="primary">mdi-plus</v-icon></v-btn
                        ></template
                      ><span>Aggiungi Dettaglio</span></v-tooltip
                    >
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <!-- Colonna della modifica -->
                <template v-slot:[`item.modifica`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="clickModificaApprovatore(item)"
                        small
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon
                          color="
                        primary
                      "
                          >mdi-pencil</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Modifica Dettaglio</span>
                  </v-tooltip>
                </template>

                <!-- Colonna dell'eliminazione -->
                <template v-slot:[`item.elimina`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="item.validita = 0"
                        small
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon
                          color="
                        error
                      "
                          >mdi-close</v-icon
                        ></v-btn
                      >
                    </template>
                    <span>Elimina Approvatore</span>
                  </v-tooltip>
                </template>

                <!-- Colonna dell'importo dell'Approvatore -->
                <template v-slot:[`item.importo`]="{ item }">
                  {{
                    item.importo
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog_modifica_approvatore">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            {{
              approvatore_sel.id_approvatore === -1
                ? "Nuovo Approvatore"
                : "Modifica Approvatore"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaDettaglio" small v-bind="attrs" v-on="on"
                ><v-icon color="success">mdi-floppy</v-icon></v-btn
              >
            </template>
            <span>Salva Dettaglio</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiModificaApprovatore"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 500px; overflow-y: auto">
          <v-row align="center" justify="center">
            <v-col cols="3"
              ><v-text-field
                label="Descrizione"
                v-model="approvatore_sel.des_approvatore"
              ></v-text-field
            ></v-col>
            <v-col cols="3">
              <v-autocomplete
                :items="ruoli_workflow_lista"
                item-text="des"
                item-value="id_tabella"
                label="Ruolo Workflow"
                placeholder="Seleziona"
                required
                v-model="approvatore_sel.id_ruolo_workflow"
                @change="
                  ruoli_workflow_lista.filter((e) =>
                    e.id_tabella == approvatore_sel.id_ruolo_workflow
                      ? (approvatore_sel.des_ruolo_workflow = e.des)
                      : null
                  )
                "
              >
                <template #label>
                  <span class="">Ruolo Workflow</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_RUOLO_WORKFLOW"
                      nome="Ruoli Workflow"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      filter="validita = 1"
                      :dati="[{ des: '' }]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" id="listener_soglia_approvatore">
              <!--<v-text-field
                label="Soglia Importo (€)"
                v-model="approvatore_sel.importo"
                min="0"
                type="number"
              ></v-text-field>
              -->
              <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_soglia_approvatore'"
                :label="'Importo (€)'"
                :id="'inputApprovatore'"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment/moment";
import InputSeparatore from "@/components/TOOLS/InputSeparatore.vue";

export default {
  name: "TabellaRegole",
  components: {
    InputSeparatore,
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    rotta_back: {
      type: String,
      default: "/home/manutenzione/main",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      approvatore_sel: {
        id_approvatore: -1,
        id_regola: null,
        des_approvatore: null,
        importo: 0,
        id_ruolo_workflow: null,
        des_ruolo_workflow: null,
        stato: 1,
        validita: 1,
      },
      contatore: 0,
      expanded: [],
      singleExpand: true,
      regola_sel_steps: [],
      regola_sel: {
        id_regola: -1,
        id_intervento: null,
        cod_regola: null,
        importo: 0,
        des_regola: null,
        steps: [],
        regole_dettaglio: [],
        tipi_regole_dettaglio: [],
      },
      step_sel: {
        id_step: -1,
        id_regola: null,
        cod_step: null,
        des_step: null,
        importo: 0,
        num_approvazioni: 0,
        id_tipo_approvazione: null,
        des_tipo_approvazione: "",
        tipo_approvazione: null,
        approvatori: [],
        validita: 1,
      },

      // Modal
      dialog_modifica_approvatore: false,
      dialog_modifica_regola: false,
      dialog_modifica_step: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      step_eliminato: [],

      // Array
      headers_approvatori: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_approvatore",
          sortable: false,
        },
        {
          text: "Soglia Importo (€)",
          value: "importo",
          sortable: false,
        },
        {
          text: "Ruolo Workflow",
          value: "des_ruolo_workflow",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      headers_regole: [
        {
          text: "Dettagli",
          value: "dettaglio_step",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_regola",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_regola",
          sortable: false,
        },
        {
          text: "Ambiti Manutentivi",
          value: "regole_dettaglio",
          sortable: false,
        },
        {
          text: "Importo (€)",
          value: "importo",
          sortable: false,
        },
      ],
      headers_step: [
        {
          text: "Modifica",
          value: "modifica_step",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_step",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_step",
          sortable: false,
        },
        {
          text: "Importo (€)",
          value: "importo",
          sortable: false,
        },
        {
          text: "Numero Approvazioni",
          value: "num_approvazioni",
          sortable: false,
        },
        {
          text: "Tipo Approvazione",
          value: "des_tipo_approvazione",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina_step",
          sortable: false,
        },
      ],
      items_regole: [],
      ruoli_workflow_lista: [],
      tipi_approvazione_lista: [],
      tipo_intervento_lista: [],
    };
  },
  computed: {},
  watch: {
    dialog_modifica_approvatore: {
      immediate: true,
      handler() {
        if (this.dialog_modifica_approvatore === true) {
          let that = this;
          setTimeout(() => {
            // Compongo un messaggio.
            let messaggio = {
              detail: {
                numero: this.approvatore_sel.importo,
              },
              src: null,
            };

            // Inserisco il messaggio in un evento custom.
            let evento = new CustomEvent("numero_iniziale", messaggio);

            // Consegno alla mia destinazione l'evento
            document
              .getElementById("listener_InputSeparatoreinputApprovatore")
              .dispatchEvent(evento);

            document
              .getElementById("listener_soglia_approvatore")
              .addEventListener("nuovo_numero", function (elem) {
                console.log("Entrato mounted listener", elem.detail.numero);
                that.approvatore_sel.importo = elem.detail.numero;
              });
          }, 10);
        }
      },
    },
    dialog_modifica_step: {
      immediate: true,
      handler() {
        if (this.dialog_modifica_step === true) {
          let that = this;
          setTimeout(() => {
            // Compongo un messaggio.
            let messaggio = {
              detail: {
                numero: this.step_sel.importo,
              },
              src: null,
            };

            // Inserisco il messaggio in un evento custom.
            let evento = new CustomEvent("numero_iniziale", messaggio);

            // Consegno alla mia destinazione l'evento
            document
              .getElementById("listener_InputSeparatoreinputStep")
              .dispatchEvent(evento);

            document
              .getElementById("listener_soglia_step")
              .addEventListener("nuovo_numero", function (elem) {
                console.log("Entrato mounted listener", elem.detail.numero);
                that.step_sel.importo = elem.detail.numero;
              });
          }, 10);
        }
      },
    },
    dialog_modifica_regola: {
      immediate: true,
      handler() {
        if (this.dialog_modifica_regola === true) {
          let that = this;
          setTimeout(() => {
            // Compongo un messaggio.
            let messaggio = {
              detail: {
                numero: this.regola_sel.importo,
              },
              src: null,
            };

            // Inserisco il messaggio in un evento custom.
            let evento = new CustomEvent("numero_iniziale", messaggio);

            // Consegno alla mia destinazione l'evento
            document
              .getElementById("listener_InputSeparatoreinputRegola")
              .dispatchEvent(evento);

            document
              .getElementById("listener_soglia_regola")
              .addEventListener("nuovo_numero", function (elem) {
                console.log("Entrato mounted listener", elem.detail.numero);
                that.regola_sel.importo = elem.detail.numero;
              });
          }, 10);
        }
      },
    },
    "step_sel.approvatori": {
      immediate: true,
      handler() {
        this.step_sel.num_approvazioni = this.step_sel.approvatori.length;
      },
    },
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.getRegoleList();
          this.gettabelle();
        }
      },
    },
  },
  methods: {
    eliminaStep(item) {
      let index = this.regola_sel_steps.indexOf(item);
      this.regola_sel_steps.splice(index, 1);
      item.validita = 0;
      item.approvatori.forEach((e) => {
        e.validita = 0;
      });
      this.regola_sel.steps.push(item);
      console.log("REGOLA SEL:", this.regola_sel);
      console.log("REGOLA SEL STEP:", this.regola_sel_steps);
    },
    aggiungiApprovatore() {
      // Genero un oggetto con i campi resettati
      let nuovo_approvatore = {
        id_approvatore: -1,
        id_step: this.step_sel.id_step >= 1 ? this.step_sel.id_step : -1,
        des_approvatore: null,
        importo: 0,
        id_ruolo_workflow: null,
        des_ruolo_workflow: null,
        stato: 1,
        validita: 1,
      };
      // L'oggetto resettato diventa quello selezionato
      this.approvatore_sel = Object.assign({}, nuovo_approvatore);
      // Apro il dialog per aggiungere l'approvatore
      this.dialog_modifica_approvatore = true;
    },
    aggiungiStep() {
      // Preparo uno step vuoto
      let nuovo_step = {
        id_regola: this.regola_sel.id_regola,
        id_step: -1,
        cod_step: null,
        des_step: null,
        importo: 0,
        num_approvazioni: 0,
        tipo_approvazione: null,
        id_tipo_approvazione: null,
        des_tipo_approvazione: "",
        approvatori: [],
        validita: 1,
      };

      // Uso lo step vuoto come step selezionato.
      this.step_sel = Object.assign({}, nuovo_step);
      this.dialog_modifica_step = true;
    },
    aggiungiRegola() {
      // Definisco un oggetto con i campi resettati
      let nuova_regola = {
        id_regola: -1,
        cod_regola: null,
        des_regola: null,
        importo: 0,
        steps: [],
        regole_dettaglio: [],
        tipi_regole_dettaglio: [],
      };

      // Passo l'oggetto resettato alla regola selezionata
      this.regola_sel = Object.assign({}, nuova_regola);
      // Apro il dialog
      this.dialog_modifica_regola = true;
    },
    associaIdTipoIntervento() {
      /* Svuoto l'array degli identificativi dei
       * tipi di intervento associati alla regola. */
      var old_regole_dettaglio = this.regola_sel.regole_dettaglio;
      this.regola_sel.regole_dettaglio = [];

      /* Per ogni descrizione presente nei tipi
       * intervento della regola selezionata... */
      this.regola_sel.tipi_regole_dettaglio.forEach((descrizione) => {
        // Controllo la lista dei tipi di intervento disponibili
        this.tipo_intervento_lista.forEach((tipo) => {
          /* E quando trovo la descrizione, ne copio l'identificativo
           * nell'array degli identificativi dei tipi di intervento
           * associati alla regola selezionata. */

          if (tipo.des === descrizione) {
            var filter = old_regole_dettaglio.filter(
              (f) => f.des == descrizione
            );
            if (filter.length != 0) {
              this.regola_sel.regole_dettaglio.push({
                des: tipo.des,
                id_regola: this.regola_sel.id_regola,
                id_regola_dettaglio: filter[0].id_regola_dettaglio,
                id_tipo_intervento: tipo.id_tabella,
                validita: 1,
              });
            } else {
              this.regola_sel.regole_dettaglio.push({
                des: tipo.des,
                id_regola: this.regola_sel.id_regola,
                id_regola_dettaglio: -1,
                id_tipo_intervento: tipo.id_tabella,
                validita: 1,
              });
            }
          }
        });
      });
      //metto la validita a 0 per i rimanenti item di old_regole_dettaglio
      old_regole_dettaglio.forEach((regole) => {
        var presente = false;
        this.regola_sel.regole_dettaglio.forEach((dettaglio) => {
          if (regole.id_tipo_intervento == dettaglio.id_tipo_intervento) {
            presente = true;
          }
        });
        if (presente == false) {
          this.regola_sel.regole_dettaglio.push({
            des: regole.des,
            id_regola: regole.id_regola,
            id_regola_dettaglio: regole.id_regola_dettaglio,
            id_tipo_intervento: regole.id_tipo_intervento,
            validita: 0,
          });
        }
      });
    },
    cambioTipoApprovazione() {
      this.tipi_approvazione_lista.forEach((elem) => {
        if (elem.id_tabella == this.step_sel.id_tipo_approvazione) {
          this.step_sel.des_tipo_approvazione = elem.des;
        }
      });
    },
    chiudiDettaglioRegola() {
      // Definisco un oggetto con i campi resettati.
      let nuova_regola = {
        id_regola: -1,
        cod_regole: null,
        des_regola: null,
        importo: 0,
        steps: [],
        regole_dettaglio: [],
      };

      // Passo l'oggetto resettato alla regola selezionata.
      this.regola_sel = Object.assign({}, nuova_regola);
      this.regola_sel_steps = [];

      // Chiudo il dialog.
      this.dialog_modifica_regola = false;
    },
    chiudiModificaApprovatore() {
      // Genero un oggetto con i campi resettati
      let nuovo_approvatore = {
        id_approvatore: -1,
        id_step: this.step_sel.id_step >= 1 ? this.step_sel.id_step : -1,
        descrizione: null,
        importo: 0,
        id_ruolo_workflow: null,
        des_ruolo_workflow: null,
        stato: 1,
        validita: 1,
      };

      // L'oggetto resettato diventa quello selezionato
      this.approvatore_sel = Object.assign({}, nuovo_approvatore);

      // Chiudo il dialog dell'approvatore
      this.dialog_modifica_approvatore = false;
    },
    chiudiModificaStep() {
      // Faccio un nuovo oggetto con i valori resettati
      let nuovo_step = {
        id_regola: null,
        id_step: -1,
        cod_step: null,
        des_step: null,
        importo: 0,
        num_approvazioni: 0,
        tipo_approvazione: null,
        id_tipo_approvazione: null,
        des_tipo_approvazione: "",
        approvatori: [],
        validita: 1,
      };

      // Associo l'oggetto allo step_sel, per resettarne i valori
      this.step_sel = Object.assign({}, nuovo_step);

      // Chiudo il dialog
      this.dialog_modifica_step = false;
    },
    clickModificaApprovatore(item) {
      this.approvatore_sel = Object.assign({}, item);
      this.dialog_modifica_approvatore = true;
    },
    clickModificaStep(item) {
      console.log("STEP SELEZIONATO:", item);
      this.step_sel = Object.assign({}, item);
      console.log("STEP SELEZIONATO:", this.step_sel);
      this.dialog_modifica_step = true;
    },
    async clickRegolaDialog(item) {
      this.dialog_modifica_regola = true;
      this.regola_sel = Object.assign({}, item);
      this.regola_sel.steps = [];
      this.regola_sel.tipi_regole_dettaglio = this.regola_sel.regole_dettaglio;

      await this.getRegolaDettaglio();
    },
    async getRegolaDettaglio() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getregoladettaglio",
          id_regola: this.regola_sel.id_regola,
          token: localStorage.user_token,
        },
      };

      await this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.regola_sel_steps = res.data.result.steps;
          this.regola_sel.steps = this.regola_sel_steps;
        }
      });
    },
    getRegoleList() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getregolelist",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.items_regole = res.data.result.regole;
        }
      });
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_AMBITO_MANUTENTIVO','MAN_TIPO_APPROVAZIONE','MAN_RUOLO_WORKFLOW'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.tipo_intervento_lista =
            res.data.MAN_AMBITO_MANUTENTIVO != undefined
              ? res.data.MAN_AMBITO_MANUTENTIVO
              : [];
          this.tipi_approvazione_lista =
            res.data.MAN_TIPO_APPROVAZIONE != undefined
              ? res.data.MAN_TIPO_APPROVAZIONE
              : [];
          this.ruoli_workflow_lista =
            res.data.MAN_RUOLO_WORKFLOW != undefined
              ? res.data.MAN_RUOLO_WORKFLOW
              : [];
        }
      });
    },
    goBack() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        this.$emit("click_indietro");
      }
    },
    salvaDettaglio() {
      /* Controllo che la soglia dell'approvatore
       * non sia inferiore a quella dello step. */
      if (this.approvatore_sel.importo < this.step_sel.importo) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "L'approvatore non può avere una soglia d'importo inferiore a quella dello step in cui è inserito.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una descrizione dell'approvatore
      if (
        this.approvatore_sel.des_approvatore == null ||
        this.approvatore_sel.des_approvatore == ""
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Inserisci la descrizione dell'Approvatore prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che sia indicato il ruolo workflow dell'approvatore
      if (this.approvatore_sel.id_ruolo_workflow == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Inserisci Ruolo Workflow dell'Approvatore prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che la soglia di importo non sia minore di 0
      if (this.approvatore_sel.importo < 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Inserisci un Importo maggiore o uguale a 0 per l'Approvatore prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      /* Faccio un check per capire se l'id
       * è di un oggetto nuovo o modificato */
      if (this.approvatore_sel.id_approvatore === -1) {
        /* Se l'approvatore_sel è nuovo lo aggiungo alla lista,
         * cambiandogli l'id con un valore ufficiale. */
        this.approvatore_sel.id_approvatore = -moment().valueOf();
        this.step_sel.approvatori.push(this.approvatore_sel);
      } else {
        // Se è una modifica, modifico nella lista l'elemento corrispondente
        this.step_sel.approvatori.forEach((approvatore) => {
          if (
            approvatore.id_approvatore === this.approvatore_sel.id_approvatore
          ) {
            // var index = this.step_sel.approvatori.indexOf(approvatore);
            // this.step_sel.approvatori[index] = this.approvatore_sel;
            approvatore.des_approvatore = this.approvatore_sel.des_approvatore;
            approvatore.des_ruolo_workflow = this.approvatore_sel.des_ruolo_workflow;
            approvatore.des_stato = this.approvatore_sel.des_stato;
            approvatore.id_approvatore = this.approvatore_sel.id_approvatore;
            approvatore.id_approvatore_dettaglio = this.approvatore_sel.id_approvatore_dettaglio;
            approvatore.id_ruolo_workflow = this.approvatore_sel.id_ruolo_workflow;
            approvatore.id_stato = this.approvatore_sel.id_stato;
            approvatore.id_step = this.approvatore_sel.id_step;
            approvatore.id_step_dettaglio = this.approvatore_sel.id_step_dettaglio;
            approvatore.importo = this.approvatore_sel.importo;
          }
        });
      }

      // Creo un Approvatore temporaneo con i campi resettati
      let nuovo_approvatore = {
        id_approvatore: -1,
        id_step: this.step_sel.id_step >= 1 ? this.step_sel.id_step : -1,
        des_approvatore: null,
        importo: 0,
        id_ruolo_workflow: null,
        stato: 1,
        validita: 1,
      };

      // Uso l'approvatore temporaneo per resettare i campi dell'approvatore_sel
      this.approvatore_sel = Object.assign({}, nuovo_approvatore);

      // Chiudo il dialog
      this.dialog_modifica_approvatore = false;
    },
    salvaModificheRegola() {
      /* Controllo che non ci siano step con importi
       * inferiori all'importo della regola */
      let check_importo = true;

      this.regola_sel.steps.forEach((step) => {
        if (step.importo < this.regola_sel.importo) {
          check_importo = false;
          return;
        }
      });

      if (check_importo === false) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "L'importo degli step non può mai essere inferiore all'importo della regola.";
        this.snackbar_text_color = "white";
        return;
      }

      /* Controllo che ci sia uno step con
       * importo pari all'importo della regola. */
      let check_importo_regola_step = false;

      this.regola_sel.steps.forEach((step) => {
        if (step.importo === this.regola_sel.importo && step.validita == 1) {
          check_importo_regola_step = true;
          return;
        }
      });

      if (check_importo_regola_step === false) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Deve esserci uno step con importo pari all'importo della regola.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che non ci siano più step con uguale importo.
      let check_doppio_importo = false;

      this.regola_sel.steps.forEach((step1) => {
        let occorrenze = 0;
        this.regola_sel.steps.forEach((step2) => {
          if (step1.importo === step2.importo) {
            occorrenze = occorrenze + 1;
          }
        });

        if (occorrenze > 1) {
          check_doppio_importo = true;
          return;
        }
      });

      if (check_doppio_importo === true) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Nella stessa regola non ci possono essere più step con uguale importo.";
        this.snackbar_text_color = "white";
        return;
      }

      /* Controllo che non ci siano già altre regole
       * per lo stesso ambito manutentivo. */
      let check_ambito = true;
      this.regola_sel.regole_dettaglio.forEach((dettaglio) => {
        this.items_regole.forEach((regola) => {
          regola.regole_dettaglio.forEach((rd) => {
            if (
              parseInt(dettaglio.id_tipo_intervento) ===
                parseInt(rd.id_tipo_intervento) &&
              regola.id_regola != this.regola_sel.id_regola
            ) {
              check_ambito = false;
              return;
            }
          });
        });
      });

      if (check_ambito === false) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "È già presente una regola per gli ambiti manutentivi specificati.";
        this.snackbar_text_color = "white";
        return;
      }

      var approvatori_esistenti = true;
      this.regola_sel.steps.forEach((element) => {
        if (element.approvatori.length > 0) {
          approvatori_esistenti = approvatori_esistenti && true;
        } else {
          approvatori_esistenti = approvatori_esistenti && false;
        }
      });

      if (
        this.regola_sel.des_regola == "" ||
        this.regola_sel.des_regola == null
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare la Descrizione della regola.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.regola_sel.importo < 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Indicare una soglia d'importo per la regola maggiore o uguale a 0.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.regola_sel.regole_dettaglio.length == 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Specificare gli Ambiti Manutentivi della regola.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.regola_sel.steps.length == 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Aggiungere almeno uno step alla regola.";
        this.snackbar_text_color = "white";
        return;
      }
      if (!approvatori_esistenti) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Aggiungere almeno un Approvatore per ogni step della regola.";
        this.snackbar_text_color = "white";
        return;
      }
      this.regola_sel.steps.forEach((step) => {
        if (step.id_step < 1) {
          step.id_step = -1;
        }
        step.approvatori.forEach((approvatore) => {
          if (approvatore.id_approvatore < 1) {
            approvatore.id_approvatore = -1;
          }
        });
      });
      this.setRegola(this.regola_sel);

      // Genero una regola resettata
      let nuova_regola = {
        id_intervento: null,
        id_regola: -1,
        codice: null,
        descrizione: null,
        importo: 0,
        steps: [],
        des_tipo_intervento: [],
        tipi_intervento: [],
      };

      // Uso la regola resettata per valorizzare la regola_sel
      this.regola_sel = Object.assign({}, nuova_regola);
      this.regola_sel_steps = [];

      // Chiudo il dialog di modifica della regola
      this.dialog_modifica_regola = false;
    },
    salvaModificheStep() {
      /* Controllo che lo step abbia un approvatore
       * con importo pari all'importo dello step. */
      let check_importo_step_approvatore = false;

      this.step_sel.approvatori.forEach((approvatore) => {
        if (this.step_sel.importo === approvatore.importo) {
          check_importo_step_approvatore = true;
          return;
        }
      });

      if (check_importo_step_approvatore === false) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Deve esserci almeno un approvatore con importo pari all'importo dello step.";
        this.snackbar_text_color = "white";
        return;
      }

      /* Controllo che la soglia dello step corrente non sia compresa
       * fra la soglia di un altro step e la soglia dell'approvatore
       * massimo di quello stesso step. */
      this.regola_sel.steps.forEach((step) => {
        step.riga = this.regola_sel.steps.indexOf(step);
        /* Se lo step ha un id diverso da quello selezionato
         * (perché magari sono in edit...) definisco il suo
         * importo minimo e massimo.*/
        if (step.id_step != this.step_sel.id_step) {
          let valore_min = this.regola_sel.importo;
          let valore_max = 0;
          step.approvatori.forEach((approvatore) => {
            if (approvatore.importo > valore_max) {
              valore_max = approvatore.importo;
            }
          });

          // Ora ho l'importo minimo e massimo: procedo al confronto
          /* Verifico che la soglia step non sia compresa fra i valori
           * dello step in esame. */
          if (
            valore_min < this.step_sel.importo &&
            this.step_sel.importo < valore_max
          ) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "La soglia d'importo dello step corrente è compresa fra i valori di un altro step.";
            this.snackbar_text_color = "white";
            return;
          }

          /* Verifico che la soglia approvatore massimo dello step selezionato
           * non sia compresa fra i valori dello step in esame. */
          let step_sel_approvatore_max = 0;
          this.step_sel.approvatori.forEach((approvatore) => {
            approvatore.riga = this.step_sel.riga;
            if (approvatore.importo > step_sel_approvatore_max) {
              step_sel_approvatore_max = approvatore.importo;
            }
          });

          if (
            valore_min < this.step_sel.importo &&
            this.step_sel.importo < valore_max
          ) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "La soglia d'importo degli approvatori dello step corrente è compresa fra i valori di un altro step.";
            this.snackbar_text_color = "white";
            return;
          }
        }
      });

      /* Controllo che l'importo dello step non sia
       * inferiore all'importo della regola. */
      if (this.step_sel.importo < this.regola_sel.importo) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "L'importo dello step non può essere inferiore all'importo della regola in cui è inserito.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che sia presente una descrizione per lo step.
      if (this.step_sel.des_step == null || this.step_sel.des_step == "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire una Descrizione per lo step.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che l'importo dello step sia maggiore o uguale a 0.
      if (this.step_sel.importo < 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Inserire un Importo almeno maggiore o uguale a 0 per lo step.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che sia stato indicato il tipo di approvazione.
      if (this.step_sel.id_tipo_approvazione == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire un Tipo Approvazione per lo step";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che lo step abbia almeno un approvatore.
      if (this.step_sel.approvatori.length == 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Aggiungere almeno un Approvatore per lo step";
        this.snackbar_text_color = "white";
        return;
      }

      /* Se lo step è nuovo gli assegno un id semi-ufficiale e lo
       * aggiungo all'array degli step, altrimenti lo aggiorno. */
      if (this.step_sel.id_step === -1) {
        this.step_sel.id_step = -moment().valueOf();
        this.regola_sel.steps.push(this.step_sel);
      } else {
        this.regola_sel.steps.forEach((step) => {
          if (step.id_step === this.step_sel.id_step) {
            // var index = this.regola_sel.steps.indexOf(step);
            // this.regola_sel.steps[index] = Object.assign({}, this.step_sel);
            step.id_intervento = this.step_sel.id_intervento;
            step.id_step_dettaglio = this.step_sel.id_step_dettaglio;
            step.id_regola = this.step_sel.id_regola;
            step.cod_step = this.step_sel.cod_step;
            step.des_step = this.step_sel.des_step;
            step.id_stato = this.step_sel.id_stato;
            step.des_stato = this.step_sel.des_stato;
            step.id_step = this.step_sel.id_step;
            step.des_step = this.step_sel.des_step;
            step.importo = this.step_sel.importo;
            step.num_approvazioni = this.step_sel.num_approvazioni;
            step.id_tipo_approvazione = this.step_sel.id_tipo_approvazione;
            step.des_tipo_approvazione = this.step_sel.des_tipo_approvazione;
            step.approvatori = this.step_sel.approvatori;
          }
        });
      }
      this.regola_sel.steps.forEach((e) => {
        e.riga = this.regola_sel.steps.indexOf(e);
        e.approvatori.forEach((f) => {
          f.riga = e.riga;
        });
      });
      this.regola_sel_steps = this.regola_sel.steps;
      // Faccio un nuovo oggetto con i valori resettati
      let nuovo_step = {
        id_regola: null,
        id_step: -1,
        cod_step: null,
        des_step: null,
        riga: null,
        importo: 0,
        num_approvazioni: 0,
        tipo_approvazione: null,
        approvatori: [],
        validita: 1,
      };

      // Associo l'oggetto allo step_sel, per resettarne i valori
      this.step_sel = Object.assign({}, nuovo_step);

      // Chiudo il dialog
      this.dialog_modifica_step = false;
    },
    setRegola(arg) {
      delete arg["tipi_regole_dettaglio"];
      arg.steps.forEach((e) => {
        e.approvatori.forEach((f) => {
          f.riga = e.riga;
        });
      });
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setregola",
          token: localStorage.user_token,
          regola: [arg],
        },
      };
      console.log("regola:", request.richiesta.regola);
      console.log(JSON.stringify(request.richiesta));
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Ricarico le regole
          this.getRegoleList();
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.bg-red {
  background: linear-gradient(
    90deg,
    rgba(97, 1, 1, 1) 0%,
    rgba(254, 0, 0, 1) 100%
  );
  color: white !important;
}
.sfondoStandard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 0%,
    rgba(63, 81, 181, 1) 100%
  );
  color: white !important;
}
</style>