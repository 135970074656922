<template>
  <v-card>
    <v-card-title>
      
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="date"
            label="Data inizio"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
            clearable
          ></v-text-field>
          </template>
          <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
        </v-menu>
         <v-spacer></v-spacer>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="date2"
            label="Data fine"
            prepend-icon="mdi-calendar"
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
            clearable
          ></v-text-field>
          </template>
          <v-date-picker
          v-model="date2"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(date2)"
          >
            OK
          </v-btn>
        </v-date-picker>
        </v-menu>
         
        <v-btn class="mb-7 ml-5"
          elevation="5"
          tile
          rounded
          color='primary'
          @click="getSpecificMovimenti"
          ><v-icon left>
            mdi-magnify
          </v-icon>
          Cerca per Data
        </v-btn>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cerca ..."
          single-line
          hide-details
        ></v-text-field>
        
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="movimenti"
      :search="search"      
      :items-per-page="50"
      :footer-props="footerProps"
      :sort-desc="data"
      class="elevation-1"
      >
      <template  v-slot:[`item.data`]="{ item }" >
        <span>{{ scrivi_data(item.data) }}</span>
      </template>   

        
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-card>

  

</template>
  


<script>
  export default {
    data: () => ({
        
      dialog: false,
      dialogDelete: false,
      search: '',
      //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      //date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date:'',
      date2:'',
      menu: false,
      menu2: false,
      overlay: false,     
      footerProps: { "items-per-page-options": [50, 100, 500] }, 

      headers: [
           { text: 'Data e Ora', value: 'data', sort: 'desc'  },
           { text: 'Codice Fiscale', value: 'codice' },
           { text: 'Nominativo', value: 'nominativo' },
           { text: 'Tipo Rifiuto', value: 'des' },           
      ],

      movimenti : [],
      editedIndex: -1,
      movimenti_tot:[],
      arrayTemp: [],

    }),


    mounted () {
      this.getMovimenti()
    },

    methods: {

      scrivi_data(value){
        let stringa_data = new Date(value).toLocaleString()
        //let pos = stringa_data.indexOf(',')
        //console.log(a)
        //return new Date(value).toLocaleString().substr(0, 10)
        return stringa_data.split(",")[0] + " -" + stringa_data.split(",")[1]; 
      },

        getMovimenti(){
            this.overlay = true
            let movimenti_ori=[]
            let request = {
                controller: "Buttigliera",
                method: "POST",
                richiesta: {
                    action: "getmovimento",
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((res) => {   
              this.movimenti_tot = res.data;
              movimenti_ori = this.alasql(
                  "SELECT * FROM ?", 
                    [this.movimenti_tot],
                    );
                    this.movimenti = movimenti_ori;              
              this.overlay = false   
            });
        },



        getSpecificMovimenti(){
            debugger
            this.overlay = true
            this.movimenti = []
            if( this.date == null) this.date = ""
            if( this.date2 == null) this.date2 = ""
            if( this.date.length == 0  && this.date2.length == 0 ){
            this.movimenti = this.alasql(
                "SELECT * FROM ?  order by data DESC ", 
                  [this.movimenti_tot],
                  );
            }
            if(this.date.length > 0  && this.date2.length == 0){
              this.movimenti = this.alasql(
                 "SELECT * FROM ? WHERE data > '"+ this.date +"' order by data DESC", 
                    [this.movimenti_tot],
                    );
                    this.overlay = false  
            }
            if( this.date.length == 0  && this.date2.length > 0 ){
              this.movimenti = this.alasql(
                  "SELECT * FROM ? WHERE data <= '"+ this.date2 +"' order by data DESC", 
                    [this.movimenti_tot],
                    );
                     this.overlay = false  
            }            
            if(this.date.length > 0 && this.date2.length > 0){
              this.movimenti = this.alasql(
                "SELECT * FROM ? WHERE data > '"+ this.date +"' and data <= '"+ this.date2 +"' order by data DESC", 
                  [this.movimenti_tot],
                  );
                  this.overlay = false  
            }   
            this.overlay = false      
        },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          console.log("close");
        })
      },



    },
  }
</script>