import moment from 'moment/moment';
class GanttTask {
    data = { // DATI PER IL SERVER
        id_task: -1,
        id_progetto: null,
        id_task_padre: null,
        nesting: null,
        tipo: null,
        descrizione_task: null,
        inizio_task: new Date(),
        inizio_task_reale: new Date(),
        fine_task: new Date(),
        fine_task_reale: new Date(),
        titolo_task: null,
        validita: 1,
        note: null,
        completamento: 0,
        risorse: [],
        allegati: [],
        durata: 0,
        durata_reale: 0,
        id_temp: moment().valueOf(),
        id_temp_padre:null,
    };
    treeView = {
        open: true,
        task_row: null,
        diagram_row: null,
    }

    _priv = { // OGGETTO PER GESTIONE PUNTATORI
        children: [],
        connections: [],
        inbound_connections: [],
        parent: null,
    }

    constructor(data = null, parent = null) {
        this._priv.parent = parent;
        if (parent != null) {
            parent.addChild(this);
            this.data.nesting = this.getNesting();
        } else if (data.id_task_padre == null) {
            this.data.nesting = 0;
        }
        if (data != null) {
            this.importData(data);
        }
    }

    importData(data) {
        const fields = Object.keys(this.data);
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (data[field] == undefined) {
                continue;
            }
            switch (field) {
                case 'inizio_task':
                    this.data[field].setTime(Date.parse(data[field]))
                    break;
                case 'inizio_task_reale':
                    this.data[field].setTime(Date.parse(data[field]))
                    break;
                case 'fine_task':
                    this.data[field].setTime(Date.parse(data[field]))
                    break;
                case 'fine_task_reale':
                    this.data[field].setTime(Date.parse(data[field]));
                    break;
                case 'risorse':
                    try {
                        if (data[field] != null) {
                            this.data.risorse = [];
                            data[field].forEach(e => {
                                this.data.risorse.push(e);
                            })
                        }
                    } catch {
                        this.data[field] = [];
                    }
                    break;
                case 'allegati':
                    try {
                        if (data[field] != null) {
                            this.data.allegati = [];
                            data[field].forEach(e => {
                                this.data.allegati.push(e);
                            })
                        }
                    } catch {
                        this.data[field] = [];
                    }
                    break;
                default:
                    this.data[field] = data[field];
                    break;
            }
        }
    }
    checkBoundaries(src_index, delta) {
        let parent_delta = null;
        let sibling_index = Infinity;
        let siblings = [];
        if (this._priv.parent != null)
            siblings = this._priv.parent._priv.children
        siblings.forEach(e => {
            if (e == this) return;
            const task_cells = Array.from(e.nodereference.parentElement.children);
            sibling_index = Math.min(sibling_index, task_cells.indexOf(e.nodereference));
        });
        if (sibling_index > src_index) {
            if (this._priv.parent != null)
                parent_delta = this._priv.parent.checkBoundaries(src_index, delta);
        }
        if (delta > 0) {
            let delta_tmp = Infinity;
            this._priv.connections.forEach(e => {
                if (e.tipo_partenza == 1 && e.tipo_arrivo == 0) {
                    const task_end = e.ref_task_arrivo;
                    const task_cells = Array.from(task_end.nodereference.parentElement.children);
                    delta_tmp = Math.min(task_cells.indexOf(task_end.nodereference) - src_index, delta_tmp);
                }
            });
            if (parent_delta !== null)
                delta = Math.min(delta, delta_tmp, parent_delta);
            else
                delta = Math.min(delta, delta_tmp);
        } else if (delta < 0) {
            let delta_tmp = -Infinity;
            this._priv.inbound_connections.forEach(e => {
                if (e.tipo_partenza == 1 && e.tipo_arrivo == 0) {
                    const task_start = e.ref_task_partenza;
                    const task_cells = Array.from(task_start.nodereference.parentElement.children);
                    delta_tmp = Math.max(task_cells.indexOf(task_start.nodereference) - src_index, delta_tmp);
                }
            });
            if (parent_delta !== null)
                delta = Math.max(delta, delta_tmp, parent_delta);
            else
                delta = Math.max(delta, delta_tmp);
        }
        return delta;
    }
    offSetFather(delta) {

        this.data.inizio_task.step(delta);
        this.data.fine_task.step(delta);
        this.data.inizio_task_reale.step(delta);
        this.data.fine_task_reale.step(delta);
        console.log("priv:", this._priv);

        this._priv.children.forEach(e => {
            e.offSetFather(delta);
        });
    }

    checkPosition(cells, src_cell, target) {
        // if(this._priv.parent == null)
        // {
        //     return
        // }
        console.log("src_cell", src_cell);
        const src_index = cells.indexOf(this.nodereference);
        let delta = cells.indexOf(target) - cells.indexOf(src_cell);
        delta = this.checkBoundaries(src_index, delta);

        console.log("delta before", delta);
        this.offSetFather(delta);
        // let siblings = this._priv.parent._priv.children;
        // let previuos = null;
        // let offset = false;
        // let delta = 0;
        // for (let i =0; i < siblings.length; i++) {
        //     if(siblings[i] == this)
        //     {
        //         offset=true;
        //         const src_index = cells.indexOf(src_cell);
        //         let target_index = cells.indexOf(target);
        //         if(previuos != null)
        //         {
        //             target_index = Math.max(target_index,Array.from(previuos.nodereference.parentElement.children).indexOf(previuos.nodereference));
        //         }else{
        //             console.log("entrato")
        //             target_index = Math.max(target_index,siblings.indexOf(siblings[i]));
        //         }
        //         delta = target_index-src_index;
        //     }
        //     if(offset){
        //         siblings[i].offSetFather(delta);
        //     }else{
        //         previuos = siblings[i];
        //     }
        // }
    }

    rearrangeTask() {
        if (!this.hasChildren()) {
            return {
                start: this.data.inizio_task.getTime(),
                end: this.data.fine_task.getTime()
            };
        } else {
            let obj = {
                start: Infinity,
                end: -Infinity
            };
            this._priv.children.forEach(child => {
                const childDates = child.rearrangeTask();
                obj.start = Math.min(childDates.start, obj.start);
                obj.end = Math.max(childDates.end, obj.end);
            });
            this.data.inizio_task.setTime(obj.start);
            this.data.fine_task.setTime(obj.end);
            return obj;
        }
    }

    hasChildren() {
        return this._priv.children.length > 0;
    }
    addChild(data) {
        this._priv.children.push(data);
    }

    toggleTreeView(state, retain = true) {
        let open = this.treeView.open;
        if (retain) {
            this.treeView.open = state;
            open = state;
        } else {
            open &= state;
        }
        this._priv.children.forEach(e => {
            e.treeView.task_row.style.display = open ? 'table-row' : 'none';
            e.treeView.diagram_row.style.display = open ? 'table-row' : 'none';
            e.toggleTreeView(state, false);
        });
    }

    addParent(parent) {
        this._priv.parent = parent;
        if (parent != null) {
            parent.addChild(this);
        }
        this.data.nesting = this.getNesting();
    }

    getNesting(nesting = 0) {
        const parent = this._priv.parent;
        if (parent != null) {
            nesting = parent.getNesting(nesting + 1);
        }
        return nesting;
    }

    dumpToArray(tree, validita = 1) {
        tree.push(this);
        const children = this._priv.children;
        const length = children.length;
        validita = validita == 1 && this.data.validita == 1 ? 1 : 0;
        this.data.validita = validita;
        for (let i = 0; i < length; i++) {
            children[i].dumpToArray(tree, validita);
        }
    }


}
export default GanttTask;