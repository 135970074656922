<template>
  <div style="width:100%">
    <v-snackbar v-model="snackbar" dark>
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="info" text v-bind="attrs" @click="snackbar = false">Chiudi</v-btn>
      </template>
    </v-snackbar>
    <v-app-bar color="blue-grey darken-3" class="ma-3" dense dark>
      <v-toolbar-title>I miei questionari:</v-toolbar-title>
      <v-text-field
        class="mx-4"
        flat
        dense
        rounded
        v-model="filtro"
        hide-details
        label="Cerca"
        prepend-inner-icon="search"
        solo-inverted
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-btn-toggle dark v-model="toggle_one" dense shaped mandatory>
      <v-btn color="primary">Tutti</v-btn>

      <v-btn color="success">Pronti</v-btn>

      <v-btn color="orange">In esecuzione</v-btn>

      <v-btn color="red">Conclusi</v-btn>
    </v-btn-toggle>
    <v-row class="ml-3 mr-3">
      <v-col cols="12" sm="12" md="6" lg="4" v-for="(item, i) in quiz" :key="i">
        <v-card color="gray" dark>
          <v-list>
            <v-list-item>
              <v-list-item-avatar tile size="80" color="grey">
                <v-img :src="getImg(item)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline" v-text="item.titolo_questionario"></v-list-item-title>
                <v-list-item-subtitle class="ml-4" v-text="item.des_questionario"></v-list-item-subtitle>
                <v-divider></v-divider>
                <v-list-item-subtitle
                  class="ml-4"
                  v-if="item.inizio != null"
                >Inizio: {{item.inizio | formatDateTime}}</v-list-item-subtitle>
                <v-list-item-subtitle
                  class="ml-4"
                  v-if="item.fine != null"
                >Fine: {{item.fine | formatDateTime}}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-chip v-if="item.stato_questionario == 0" class color="green" text-color="white">
                <v-avatar left>
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-avatar>Pronto
              </v-chip>
              <v-chip
                v-else-if="item.stato_questionario == 1"
                class
                color="orange"
                text-color="white"
              >
                <v-avatar left>
                  <v-icon>mdi-alert-circle</v-icon>
                </v-avatar>In esecuzione
              </v-chip>
              <v-chip v-else-if="item.stato_questionario == 2" class color="red" text-color="white">
                <v-avatar left>
                  <v-icon>mdi-alert</v-icon>
                </v-avatar>Concluso
              </v-chip>
            </v-col>
            <v-col>
              <v-btn @click="apriDettaglio(item)">
                Avvia
                <v-icon x-large color="success">mdi-play</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Base64 from "b64-to-blob";
export default {
  data() {
    return {
      caricamento_dati: true,
      quiz: [],
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      quiz_backup: [],
      filtro: "",
      toggle_one: true,
    };
  },
  watch: {
    filtro(val) {
      this.filtra(val);
    },
    toggle_one(val) {
      this.filtra(this.filtro, val);
    },
  },
  mounted() {
    localStorage.removeItem("quiz_detail");
    this.getQuestionari();
  },
  methods: {
    apriDettaglio(item) {
      var dettaglio = {
        immagine: item.img_logo,
        titolo: item.titolo_questionario,
        abilita_lista_domande: item.abilita_lista_domande
      };
      localStorage.setItem("quiz_detail", JSON.stringify(dettaglio));
      this.$router.replace("/home/check/quiz_detail/" + item.id_questionario);
    },
    getImg(item) {
      if (item.img_logo == null) {
        return "";
      }
      var base = item.img_logo.split(",");
      var blob = Base64(base[1], "image/png");
      return URL.createObjectURL(blob);
    },
    getQuestionari() {
      this.caricamento_dati = true;
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getplayquestionario",
          id_utente: localStorage.id,
          id_profilo_utente: localStorage.id_profilo,
          token: localStorage.user_token,
          db_name: "quickcheck",
        },
      };
      console.log("nominativo: " + localStorage.id_profilo);
      //console.log(JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        //console.log(JSON.stringify(res));
        this.quiz = res.data;
        this.quiz_backup = res.data;
        this.caricamento_dati = false;
      });
    },
    filtra(filtro, categoria) {
      this.quiz = this.quiz_backup;
      if (categoria == undefined) {
        categoria = this.toggle_one;
      }
      if (categoria != 0 && categoria != undefined) {
        this.quiz = this.quiz.filter(
          (f) => f.stato_questionario == categoria - 1
        );
      }
      this.quiz = this.quiz.filter((f) =>
        f.titolo_questionario.toLowerCase().includes(filtro.toLowerCase())
      );
    },
  },
};
</script>
