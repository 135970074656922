<template>
    <v-dialog v-model="ui.show" persistent>
        <v-card>
            <!-- HEADER -->
            <v-toolbar dark dense>
                <v-toolbar-title>Modifica Formato</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="ui.show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <!-- BODY -->
            <v-container >
                <v-row align="center">
                    <v-col cols="3">
                        <!-- TABS DI SINISTRA -->
                        <v-tabs vertical v-model="ui.tabs" >
                            <v-tab v-for="(item,i) in store.formato" :key="i">
                                <v-row align="center">
                                    <v-col cols="4" justify="start">
                                        <v-btn icon small @click.stop="remove_rule(i)">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="4" justify="center">Regola {{i+1}}</v-col>
                                </v-row>                                
                            </v-tab>
                            <v-btn text color="success" @click="add_rule">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </v-tabs>
                    </v-col>
                    <v-col cols="9">
                        <!-- CONTENUTO -->
                        <v-tabs-items v-model="ui.tabs" >
                            <v-tab-item v-for="(item,i) in store.formato" :key="i">
                                <v-container>
                                    <v-card-title class="px-0">Condizioni</v-card-title>
                                    <!-- SWITCH -->
                                    <v-row >
                                        <v-col cols="4" justify="center" v-show="value.famiglia_grafici!=3">
                                            <v-switch :label="(item.static)?'Statica':'Condizionale'" v-model="item.static"/>
                                        </v-col>
                                        <v-col cols="4" justify="center" v-if="item.static!=true&&value.serie[index].tipo_dati!=0&&value.famiglia_grafici!=3">
                                            <v-switch :label="(item.range)?'Range':'Confronto'" v-model="item.range"/>
                                        </v-col>
                                        <v-col cols="4" justify="center" v-if="item.static!=true">
                                            <v-switch :label="(item.query)?'Usa Query':'Usa valore fisso'" v-model="item.query"/>
                                        </v-col>
                                    </v-row>
                                    <!-- INPUT RANGE -->
                                    <v-row align="center" justify="start" v-if="item.range==true&&item.static!=true&&get_range(value)">
                                        <v-col cols="3" >
                                            <p style="text-align:left;font-size:14px">Compreso tra</p>
                                        </v-col>
                                         <v-col cols="3">
                                            <v-text-field v-model="item.start_ref" v-if="item.query!=true" :type="get_type(value)"/>
                                            <v-select v-model="item.start_ref" v-if="item.query==true" :items="value.condizioni" item-value="cod" item-text="cod"/>
                                        </v-col>
                                         <v-col cols="3">
                                            <p style="font-size:14px">e</p>
                                        </v-col>
                                         <v-col cols="3">
                                            <v-text-field v-model="item.end_ref" v-if="item.query!=true" :type="get_type(value)"/>
                                            <v-select v-model="item.end_ref" v-if="item.query==true" :items="value.condizioni" item-value="cod" item-text="cod"/>
                                        </v-col>
                                    </v-row>
                                    <!-- INPUT VALORE -->
                                    <v-row align="center" justify="start" v-if="(item.range!=true||!get_range(value))&&item.static!=true">
                                        <v-col cols="6" >
                                            <v-select v-model="item.operation" :items="get_compare(value)" item-text="text" item-value="value"/>
                                        </v-col>
                                         <v-col cols="4">
                                            <v-text-field v-model="item.start_ref" v-if="item.query!=true" :type="get_type(value)"/>
                                            <v-select v-model="item.start_ref" v-if="item.query==true" :items="value.condizioni" item-value="cod" item-text="cod"/>
                                        </v-col>
                                    </v-row>
                                    <!-- IMPOSTAZIONI STRINGA -->
                                    <div v-if="value.serie[index].tipo_dati==0">
                                        <v-divider />
                                        <v-card-title class="px-0">Opzioni Stringa</v-card-title>
                                        <v-row justify="center">
                                            <v-checkbox v-model="item.string.case_sensitive" label="Case sensitive" />
                                        </v-row>
                                    </div>
                                    <!-- COLORE -->
                                    <div v-if="!is_tabella(value)&&!is_card(value)">
                                        <v-divider />
                                        <v-card-title class="px-0">Colore</v-card-title>
                                        <v-row align="center" justify="center" class="mt-8">
                                            <v-col cols="4" align="center">
                                                <span style="font-size:16px;margin-right:20px;display:block;margin:5px auto;">Colore</span>
                                                <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="item.color" width="200" canvas-height="100"/>
                                            </v-col>                                             
                                        </v-row>  
                                    </div>
                                    <!-- FORMATO CELLA (SOLO TABELLE) -->
                                    <div v-if="is_tabella(value)||is_card(value)">
                                        <v-divider />
                                        <v-card-title class="px-0">Formato Cella</v-card-title>
                                        <v-row align="center">
                                            <v-col cols="6" align="left">
                                                <span :style="get_style(item)">Abc</span>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn-toggle v-model="item.align">
                                                    <v-btn>
                                                        <v-icon>mdi-format-align-left</v-icon>
                                                    </v-btn>
                                                    <v-btn>
                                                        <v-icon>mdi-format-align-center</v-icon>
                                                    </v-btn>
                                                    <v-btn>
                                                        <v-icon>mdi-format-align-right</v-icon>
                                                    </v-btn>
                                                </v-btn-toggle>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center" justify="center" >                                             
                                            <v-col cols="3">
                                                <v-text-field v-model="item.font_size" type="number" min="1" label="Dimensioni Font" />
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox v-model="item.bold" label="Grassetto" />
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox v-model="item.italic" label="Corsivo" />
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox v-model="item.underlined" label="Sottolineato" />
                                            </v-col>
                                        </v-row>
                                        <!-- COLORI CELLA -->
                                        <v-row  justify="center" align="center">
                                            <v-col cols="6" justify="center" align="center">
                                                <span style="display:block;font-size:16px">Sfondo Cella</span>
                                                <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="item.color" canvas-height="100"/>
                                            </v-col>
                                            <v-col cols="6" justify="center" align="center">
                                                <span style="display:block;font-size:16px">Colore Testo</span>
                                                <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="item.font_color" canvas-height="100"/>
                                            </v-col>
                                        </v-row> 
                                        <!-- FORMATTAZIONE VALORE -->
                                        <v-divider />
                                        <v-card-title class="px-0">Formato Valore</v-card-title>
                                        <p class="helper"><strong>{valore}</strong> per il valore della cella</p>
                                        <v-text-field label="Formula" v-model="item.formatter"/>                                         
                                    </div>                                   
                                </v-container>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-container>
            <!-- TASTO SALVA -->
            <v-card-actions>
                <v-spacer />
                <v-btn text color="success" @click="save">
                    <v-icon class="mr-2">mdi-floppy</v-icon>Salva modifiche</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name : 'Formato',
    props : {
        value : Object
    },
    data(){
        return{
            index : -1,
            compare_num : [
                {text:'Minore di',value:0},
                {text:'Minore o uguale a',value:1},
                {text:'Uguale a',value:2},
                {text:'Maggiore o uguale a',value:3},
                {text:'Maggiore di',value:4},
                {text:'Diverso da',value:5},
            ],
            compare_str : [
                {text:'Uguale a',value:0},
                {text:'Diverso da',value:1},
            ],
            store : {},
            template : {
                color : '#ffffff',
                operation : 0,
                query: false,
                range : true,
                compiled_style : '',
                align : 0,
                bold : false,
                font_color : '#000000',
                font_size : 13,
                italic : false,
                underlined : false,
                start_ref : null,
                string : {
                    case_sensitive : false,
                },
                end_ref: null,                   
                static : false,
                formatter : '{valore}'
            },
            ui : {
                show : false,
                tabs : 0
            }
        }
    },
    mounted(){
        document.body.addEventListener('graph_editor_formato',this.show_editor);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_editor_formato',this.show_editor);
    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        add_rule(){
            let new_item = Object.assign({},this.template);
            this.store.formato.push(new_item);
        },
        remove_rule(index){
            if(this.store.formato[index]==undefined){return;}
            this.store.formato.splice(index,1);
        },
        get_compare(dettaglio){
            switch(dettaglio.serie[this.index].tipo_dati){
                case 1:
                case 2:
                    return this.compare_num;
                case 0:
                default:
                    return this.compare_str;               
            }
        },
        get_type(dettaglio){
            switch(dettaglio.serie[this.index].tipo_dati){
                case 1:
                    return 'number';
                case 2:
                    return 'date';
                case 0:
                default:
                    return 'text';               
            }
        },
        get_range(dettaglio){
            if(dettaglio.serie[this.index].tipo_dati == 0){
                return false;
            }else{
                return true;
            }
        },
        get_style(item, compile = false){
            if(item.font_size > 60){item.font_size = 60;}
            let align = ['left','center','right'];
            align = align[item.align];
            let style = `background-color:${item.color};
            text-align:${align};
            font-weight:`+((item.bold)?'bold':'normal')+`;
            color:${item.font_color};
            font-size:${item.font_size}px;
            font-style:`+((item.italic)?'italic':'normal')+`;
            text-decoration:`+((item.underlined)?'underline':'none')+';';
            if(compile == false){
                style += 'display:block;border:thin solid rgba(0,0,0,.12);max-width:200px';
            }            
            return style.replaceAll('\n','');
        },
        is_tabella(dettaglio){
            return dettaglio.famiglia_grafici == 4;
        },
        is_card(dettaglio){
            return dettaglio.famiglia_grafici == 5;
        },
        save(){
            let value = Object.assign({},this.value);
            for(let i=0; i < this.store.formato.length; i++){
                this.store.formato[i].compiled_style = this.get_style(this.store.formato[i],true);
            }
            let encoded = JSON.stringify(this.store);
            // codifica del campo EXTRA come json codificato in base64 (con supporto per UTF-8)
            value.serie[this.index].extra = btoa(unescape(encodeURIComponent(encoded)));
            this.$emit('input',value);
            this.ui.show = false;
        },
        show_editor(data){
            data = data.detail;
            this.index = this.value.serie.indexOf(data);
            if(this.index < 0){return}
            try{
                // il campo EXTRA è un json codificato in base64 (con supporto per UTF-8)
                this.store = JSON.parse(decodeURIComponent(escape(atob(this.value.serie[this.index].extra))));               
                if(this.store.formato==undefined){
                    this.store = Object.assign({ formato : []}, this.store); 
                }
            }catch(error){
                this.store = {formato : []};              
            } 
            this.ui.show = true;        
        }
    }
}
</script>