<template>
  <div class="dettaglio_bolla">
    <header>
      <v-toolbar dense dark color="indigo" primary-title>
        <v-toolbar-title class="white--text">
          {{
            dettaglio.num_documento != null
              ? "Documento N. " + dettaglio.num_documento
              : "Nuovo Documento"
          }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small light v-bind="attrs" v-on="on" @click="salvaDati()">
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>Salva Documento</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-on="on"
              v-bind="attrs"
              light
              @click="$emit('goBack')"
            >
              <v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
    </header>
    <v-container>
      <v-form ref="bolla_form">
        <v-row justify="center" align="center">
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              @keyup.enter.native="goToAnagrafica"
              class="mr-2"
              label="Fornitore"
              prepend-icon="mdi-truck"
              v-model="dettaglio.ragione_sociale"
              required
              :rules="regole.no_null_str"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  small
                  v-on="on"
                  v-bind="attrs"
                  @click="goToAnagrafica"
                  ><v-icon color="success">mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Vai al fornitore</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-on="on"
                  v-bind="attrs"
                  @click="getAnagrafica"
                  @keyup.enter.native="getAnagrafica"
                  ><v-icon color="primary">mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Cerca fornitore</span>
            </v-tooltip>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              label="Numero Documento"
              prepend-icon="mdi-file-document-multiple"
              v-model="dettaglio.num_documento"
              required
              :rules="regole.no_null_str"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <InputCalendario
              label="Data"
              v-model="dettaglio.data_documento"
              required
              :rules="regole.no_null_str"
            />
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12">
          <v-data-table :headers="tabella.testata" :items="tabella.dati">
            <template v-slot:top>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Articoli in Accettazione
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <DataMatrixReader
                  :disabled="
                    dettaglio.ragione_sociale === null ||
                    dettaglio.ragione_sociale === '' ||
                    dettaglio.ragione_sociale === undefined
                  "
                  ref="qr"
                  @clickChiudi="qr_attivo = false"
                  v-on:detectQrCode="detectQrCode"
                />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-5"
                      @click="clickStampaAccettazioni"
                      :disabled="
                        tabella.dati.filter((e) => e.stampa === true).length ===
                        0
                      "
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-printer </v-icon>
                    </v-btn>
                  </template>
                  <span>Stampa</span>
                </v-tooltip>

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="!dettaglio.id_anagrafica"
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="mostraDialog(null)"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Articolo</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item="{ item }">
              <tr
                class="record_tabella"
                :data-deleted="item.validita == 0 ? true : false"
              >
                <td style="text-align: left">
                  <v-btn
                    class="mx-1 mb-1 mt-1"
                    small
                    @click="mostraDialog(item)"
                    :disabled="item.validita == 0 ? true : false"
                    ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                  >
                </td>
                <td>
                  <v-btn
                    class="mx-1 mb-1"
                    small
                    @click="mostraDialog(item, true)"
                    :disabled="item.validita == 0 ? true : false"
                    ><v-icon color="primary">mdi-content-copy</v-icon></v-btn
                  >
                </td>
                <td
                  v-for="(colonna, i) in tabella.testata"
                  :key="i"
                  v-show="colonna.value != null"
                >
                  {{ item[colonna.value] }}
                </td>
                <td>
                  <v-simple-checkbox color="primary" v-model="item.stampa">
                  </v-simple-checkbox>
                </td>
                <td style="text-align: right">
                  <v-btn class="ml-1" small @click="eliminaRiga(item)"
                    ><v-icon
                      :color="item.validita == 0 ? 'primary' : 'error'"
                      >{{
                        item.validita == 1 ? "mdi-close" : "mdi-restore"
                      }}</v-icon
                    ></v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- DIALOG AGG ART -->
    <v-dialog persistent v-model="dialog_inserimento.mostra" width="700">
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>{{
            dialog_buffer.cod_articolo === null
              ? "Aggiungi Articolo"
              : "Modifica Articolo"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 ml-2"
                v-on="on"
                v-bind="attrs"
                @click="clickDialogOrdini"
                light
                small
              >
                <v-icon color="primary">mdi-file-document-multiple</v-icon>
              </v-btn>
            </template>
            <span>Ordini</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="salvaModifiche"
                light
                small
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva Articolo</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="dialog_inserimento.mostra = false; qr_attivo = false"
                light
                small
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>

        <v-container>
          <v-form ref="form">
            <v-row align="center" justify="center">
              <v-col class="py-0">
                <v-row align="center" justify="center">
                  <v-col class="py-0">
                    <v-row align="center" justify="center">
                      <v-text-field
                        class="ml-3 mt-5"
                        label="Cod. Articolo"
                        required
                        :rules="regole.no_null_str"
                        v-model="dialog_buffer.cod_articolo"
                      ></v-text-field>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mr-2 ml-3"
                            v-on="on"
                            v-bind="attrs"
                            @click="goToArticolo"
                            small
                          >
                            <v-icon color="success">mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>Vai all'Articolo</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mr-2"
                            v-on="on"
                            v-bind="attrs"
                            @click="getArticoli"
                            small
                          >
                            <v-icon color="primary">mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                        <span>Cerca</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  label="Descrizione"
                  readonly
                  v-model="dialog_buffer.descrizione"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="py-0">
                <v-text-field
                  label="Numero Colli"
                  type="number"
                  v-model.number="dialog_buffer.num_colli"
                  required
                  :rules="regole.no_null_num"
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  type="number"
                  label="Quantità"
                  min="0"
                  v-model.number="dialog_buffer.quantita"
                  required
                  :rules="regole.no_null_num"
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  label="Unità di Misura"
                  readonly
                  v-model="dialog_buffer.um"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="py-0">
                <v-text-field
                  label="Lotto Assegnato"
                  readonly
                  v-model="dialog_buffer.cod_lotto"
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  label="Lotto Fornitore"
                  v-model="dialog_buffer.lotto_fornitore"
                  required
                  :rules="regole.no_null_str"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="py-0">
                <v-text-field
                  label="Num. Seriale"
                  v-model="dialog_buffer.num_seriale"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="py-0">
                <v-text-field
                  label="Rif. Ordine"
                  readonly
                  v-model="dialog_buffer.num_ordine"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="py-0">
                <v-text-field
                  label="Riga Ordine"
                  readonly
                  v-model="dialog_buffer.riga_ordine"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-overlay :value="caricamento">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>

    <!-- DIALOG ricerca cod articolo -->
    <v-dialog v-model="dialog_lista.mostra" width="700" persistent>
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>Seleziona Articolo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="chiudiDialogRicercaArt" light small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <!-- <v-row align="center">
            <v-col cols="1"></v-col>
            <v-col cols="8">
              <v-text-field
                v-model="dialog_lista.ricerca"
                label="Cerca"
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="right">
              <v-badge color="transparent">
                <template v-slot:badge>
                  <v-progress-circular
                    v-if="dialog_lista.caricamento"
                    indeterminate
                    size="10"
                    width="2"
                    color="indigo"
                  ></v-progress-circular>
                </template>
                <v-btn @click="getArticoli" small>
                  <v-icon color="primary">mdi-magnify</v-icon>
                </v-btn>
              </v-badge>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="dialog_lista.tabella.testata"
                :items="dialog_lista.tabella.dati"
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="record_tabella"
                    :data-deleted="item.validita == 0 ? true : false"
                  >
                    <td>
                      <v-btn small @click="selezionaArticolo(item)">
                        <v-icon color="primary">mdi-check</v-icon>
                      </v-btn>
                    </td>
                    <td
                      v-for="(colonna, i) in dialog_lista.tabella.testata"
                      :key="i"
                      v-show="colonna.value != null"
                    >
                      {{ item[colonna.value] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Dialog lista Ordini -->
    <v-dialog v-model="dialog_lista_ordini" width="1000" persistent>
      <v-card>
        <v-data-table :headers="tabellaOrdini.testata" :items="items_articoli">
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text">
                Lista Ordini
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-col>
                <v-autocomplete
                  solo
                  light
                  dense
                  hide-details
                  background-color="#ffffff"
                  @change="filtra"
                  :items="items_articoli"
                  item-text="COD_ARTICOLO"
                  item-value="COD_ARTICOLO"
                  label="Cerca Articolo"
                  v-model="articoli_search"
                />
              </v-col>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="articoli_search"
                    class="mr-5"
                    v-on="on"
                    v-bind="attrs"
                    small
                    @click="eliminaFiltro"
                  >
                    <v-icon color="primary">mdi-filter-off</v-icon>
                  </v-btn>
                </template>
                <span>Chiudi Ricerca</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    v-on="on"
                    v-bind="attrs"
                    light
                    @click="getOrdini"
                  >
                    <v-icon color="success">mdi-sync</v-icon>
                  </v-btn>
                </template>
                <span>Aggiorna ordini</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    v-on="on"
                    v-bind="attrs"
                    light
                    @click="backFromListaOrdini"
                  >
                    <v-icon color="error">mdi-keyboard-backspace</v-icon>
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr
              class="record_tabella"
              :data-deleted="item.validita == 0 ? true : false"
              :style="
                item.QUANTITA < 0 && item.QUANTITA >= -500
                  ? 'background:#fbd287;'
                  : item.QUANTITA < -500
                  ? 'background:#fda1a1;'
                  : ''
              "
            >
              <!-- <td style="text-align: left">
                <v-btn
                  class="mx-1"
                  small
                  @click="mostraDialog(item)"
                  :disabled="item.validita == 0 ? true : false"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  class="mx-1"
                  small
                  @click="mostraDialog(item, true)"
                  :disabled="item.validita == 0 ? true : false"
                  ><v-icon color="primary">mdi-content-copy</v-icon>
                </v-btn>
              </td> -->
              <td>
                <v-btn small @click="clickItemListaOrdini(item)">
                  <v-icon color="primary">mdi-check</v-icon>
                </v-btn>
              </td>

              <td
                v-for="(colonna, i) in tabellaOrdini.testata"
                :key="i"
                v-show="colonna.value != null"
              >
                {{ item[colonna.value] }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-overlay :value="caricamento">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>

    <!-- Dialog Conferma -->
    <v-dialog v-model="dialogConfReg" width="700" persistent>
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>Conferma Registrazione</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title
            >Quantità:
            {{ dialog_buffer.quantita ? dialog_buffer.quantita : "" }}/{{
              qtaDisp ? qtaDisp : ""
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn @click="chiudiDialogConfermaReg" light small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                v-if="array_tmp_ord_qnt.length"
                :headers="headersConf"
                :items="array_tmp_ord_qnt"
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="record_tabella"
                    :data-deleted="item.validita == 0 ? true : false"
                  >
                    <td
                      v-for="(colonna, i) in headersConf"
                      :key="i"
                      v-show="colonna.value != null"
                    >
                      {{ item[colonna.value] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="6">
              <v-btn color="error" @click="chiudiDialogConfermaReg"
                >Annulla</v-btn
              >
            </v-col>
            <v-col cols="6" v-if="dialog_buffer.quantita <= (qtaDisp + 500)">
              <v-btn @click="confermaRegMultiRiga" color="success">
                Conferma
                </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOG RICHERCA FORNITORE -->
    <v-dialog v-model="dialogSearchAnagrafica" width="700" persistent>
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>Seleziona Fornitore</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="chiudiDialogRicercaFornitore" light small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <!-- <v-row align="center">
            <v-col cols="1"></v-col>
            <v-col cols="8">
              <v-text-field
                v-model="ricerca_anagrafica"
                label="Cerca Fornitore"
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="right">
              <v-badge color="transparent">
                <template v-slot:badge>
                  <v-progress-circular
                    v-if="dialog_lista.caricamento"
                    indeterminate
                    size="10"
                    width="2"
                    color="indigo"
                  ></v-progress-circular>
                </template>
                <v-btn @click="getAnagrafica" small>
                  <v-icon color="primary">mdi-magnify</v-icon>
                </v-btn>
              </v-badge>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="ragioneSociale"
                :items="ragioneSocialeLista"
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="record_tabella"
                    :data-deleted="item.validita == 0 ? true : false"
                  >
                    <td>
                      <v-btn small @click="selezionaAnagrafica(item)"
                        ><v-icon color="primary">mdi-check</v-icon></v-btn
                      >
                    </td>
                    <td
                      v-for="(colonna, i) in ragioneSociale"
                      :key="i"
                      v-show="colonna.value != null"
                    >
                      {{ item[colonna.value] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Dialog conferma ordini  -->
    <v-dialog v-model="dialog_conferma_ordine" width="unset" persistent>
      <v-card>
        <v-toolbar class="white--text" color="indigo" flat>
          <v-toolbar-title>Conferma Selezione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          Vuoi registrare l'articolo:
          <b>{{ ordine_tmp == null ? "" : ordine_tmp.COD_ARTICOLO }}</b>
          dell'ordine:
          <b>{{ ordine_tmp == null ? "" : ordine_tmp.NUM_ORDINE }}</b>
          ?
        </v-card-text>
        <v-divider></v-divider>
        <v-btn class="mx-2 my-2" @click="annullaSelezioneOrdine" small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="selezionaOrdine(ordine_tmp)" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog Registrazione Superiore -->

    <!-- <v-dialog v-model="dialog_cnf_reg_qnt_sup" width="500" persistent>
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>Conferma la Registrazione</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn light small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-card-text class="mt-5">
            Stai registrando una quantità superiore alla quantità disponibile
            nell'ordine.
          </v-card-text>
          <v-row align="center">
            <v-col cols="6">
              <v-btn color="error">Annulla</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="confermaRegSuper" color="success">Conferma</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog> -->

    <!-- Dialog di conferma della stampa -->
    <v-dialog v-model="conferma_stampa_visible" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Conferma Stampa</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi la stampa dei dati di n.
            {{ tabella.dati.filter((e) => e.stampa === true).length }} articoli
            in accettazione?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_stampa_visible = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="stampaAccettazioni"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di riepilogo pre-salvataggio -->
    <v-dialog v-model="dialog_riepilogo" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Riepilogo Accettazioni</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <v-data-table
            :headers="tabella.testata_riepilogo"
            :items="items_riepilogo"
          >
          </v-data-table>

          <!-- Riga di conferma / rifiuto -->
          <v-container class="mt-5 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="dialog_riepilogo = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="setDocumento"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>
<script>
import DataMatrixReader from "../../TOOLS/DataMatrixReader.vue";
import InputCalendario from "./InputCalendario.vue";
import moment from "moment/moment";
import _ from "lodash";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "DettaglioBolla",
  components: {
    DataMatrixReader,
    InputCalendario,
    SnackbarQV,
  },
  props: {
    bolla_dettaglio: { type: Number, default: null },
    value: Array,
    admin_power: Boolean,
  },
  data() {
    return {
      qr_attivo: false,
      articoli_search: "",
      dialog_riepilogo: false,
      conferma_stampa_visible: false,
      qtaDisp: null,
      itemTmp: null,
      lista_um: [],
      items_riepilogo: [],
      array_ordini_quantita: [],
      array_tmp_ord_qnt: [],
      ordine_tmp: null,
      codice_fornitore: null,
      caricamento: false,
      qr_code_sel: "",
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      ricerca_anagrafica: null,
      ragioneSocialeLista: [],
      ragioneSociale: [
        { text: null, value: null, align: "start" },
        { text: "Ragione Sociale", value: "ragione_sociale" },
        { text: "Indrizzo", value: "indirizzo" },
      ],
      dialogConfReg: false,
      dialogSearchAnagrafica: false,
      dialog_lista_ordini: false,
      dialog_conferma_ordine: false,
      riga_count: 1,
      dettaglio: {
        id_documento: -1,
        cod_documento: "DDTR",
        num_documento: null,
        data_documento: moment().format("YYYY-MM-DD"),
        id_anagrafica: null,
        tipo_soggetto: null,
        validita: 1,
        ragione_sociale: null,
        dettaglio: [],
      },
      dialog_buffer: {
        id_documento_dettaglio: -1,
        id_documento: -1,
        id_articolo: null,
        descrizione: null,
        cod_articolo: null,
        peso_unitario: null,
        id_um: null,
        um: null,
        quantita: null,
        num_colli: 1,
        num_seriale: null,
        lotto_fornitore: null,
        cod_lotto: null,
        num_riga: null,
        stato_riga: null,
        num_ordine: null,
        riga_ordine: null,
        validita: 1,
      },
      dialog_inserimento: {
        indice: -1,
        mostra: false,
      },
      dialog_lista: {
        caricamento: false,
        mostra: false,
        ricerca: null,
        tabella: {
          testata: [
            { text: null, value: null, align: "start" },
            { text: "Codice Articolo", value: "cod_articolo" },
            { text: "Descrizione Articolo", value: "des_articolo" },
          ],
          dati: [],
        },
      },
      regole: {
        no_null_str: [
          (valore) =>
            (valore != null && valore != undefined
              ? valore.toString().length > 0
              : false) || "Campo obbligatorio",
        ],
        no_null_num: [
          (valore) =>
            (valore != null &&
              valore != undefined &&
              !isNaN(valore) &&
              valore > 0) ||
            "Campo obbligatorio",
        ],
      },
      tabella: {
        testata: [
          { text: null, value: null, sortable: false },
          { text: null, value: null, sortable: false },
          { text: "Codice Articolo", value: "cod_articolo", align: "start" },
          { text: "Numero seriale", value: "num_seriale", align: "start" },
          { text: "Descrizione", value: "descrizione" },
          { text: "Numero Colli", value: "num_colli" },
          { text: "Quantità", value: "quantita" },
          { text: "Residuo Ordine", value: "rimanenza" },
          { text: "Unità di Misura", value: "um" },
          { text: "Lotto Fornitore", value: "lotto_fornitore" },
          { text: "Lotto Assegnato", value: "cod_lotto" },
          { text: "Rif. Ordine", value: "num_ordine" },
          { text: "Riga Ordine", value: "num_riga" },
          { text: null, value: null, sortable: false },
          { text: null, value: null, sortable: false },
        ],
        testata_riepilogo: [
          { text: "Codice Articolo", value: "cod_articolo", align: "start" },
          { text: "Numero Colli", value: "colli" },
          { text: "Quantità", value: "quantita" },
        ],
        dati: [],
      },
      tabellaOrdini: {
        testata: [
          { text: null, value: null, sortable: false },
          { text: "Numero Ordine", value: "NUM_ORDINE", align: "start" },
          { text: "Cod. Articolo", value: "COD_ARTICOLO" },
          { text: "Des. Articolo", value: "DESCRIZIO" },
          { text: "Num. Seriale", value: "NUM_SERIALE" },
          { text: "Data Consegna", value: "DATA_CONSEGNA" },
          { text: "Quantità", value: "QUANTITA" },
        ],
        dati: [],
      },
      headersConf: [
        { text: "Numero Ordine", value: "NUM_ORDINE" },
        { text: "Cod. Articolo", value: "COD_ARTICOLO" },
        { text: "Data Consegna", value: "DATA_CONSEGNA" },
        { text: "Quantità", value: "QUANTITA" },
      ],
      items_articoli: [],
    };
  },
  watch: {
    bolla_dettaglio: {
      immediate: true,
      handler() {
        if (this.bolla_dettaglio != null) {
          this.getDocumento();
        }
      },
    },
    // dettaglio: {
    //   immediate: true,
    //   handler() {
    //     this.goToAnagrafica();
    //   },
    // },
  },
  mounted() {},
  methods: {
    eliminaFiltro() {
      this.articoli_search = "";
      this.items_articoli = Array.from(this.tabellaOrdini.dati);
    },
    filtra() {
      let items_articoli_tmp = [];
      this.items_articoli.forEach((elem) => {
        items_articoli_tmp.push(elem);
      });

      if (this.articoli_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_articoli_tmp.forEach((elem) => {
          if (elem.COD_ARTICOLO == this.articoli_search) {
            res.push(elem);
          }
        });

        items_articoli_tmp = res;
      }

      this.items_articoli = [];
      this.items_articoli = items_articoli_tmp;
    },
    popolaRiepilogo() {
      const array_tmp = new Array();

      this.tabella.dati.forEach((e) => {
        let ricorre = false;

        array_tmp.forEach((f) => {
          if (e.cod_articolo === f.cod_articolo) {
            ricorre = true;
          }
        });

        if (ricorre === false) {
          let colli = 0;
          let quantita = 0;

          this.tabella.dati.forEach((f) => {
            if (e.cod_articolo === f.cod_articolo) {
              colli += f.num_colli;
              quantita += f.quantita;
            }
          });

          array_tmp.push({
            cod_articolo: e.cod_articolo,
            colli: colli,
            quantita: quantita,
          });
        }
      });

      return array_tmp;
    },
    // confermaRegSuper() {
    //   this.tabella.dati.push(this.itemTmp);
    //   this.dialog_cnf_reg_qnt_sup = false;
    //   this.itemTmp = {};
    // },
    confermaRegMultiRiga() {
      this.array_tmp_ord_qnt.forEach((elem) => {
        let clone = Object.assign({}, this.dialog_buffer);
        clone.validita = 1;
        clone.id_documento_dettaglio = -1;
        clone.id_documento = -1;
        clone.num_riga = elem.RIGA_ORDINE;
        // this.riga_count++;
        // clone.riga_ordine = elem.RIGA_ORDINE;
        clone.num_ordine = elem.NUM_ORDINE;
        clone.quantita = elem.QUANTITA;
        this.tabella.dati.push(clone);
      });

      for (let i = 0; i < this.tabellaOrdini.dati.length; i++) {
        for (let j = 0; j < this.array_tmp_ord_qnt.length; j++) {
          if (
            this.tabellaOrdini.dati[i].DATA_CONSEGNA ===
              this.array_tmp_ord_qnt[j].DATA_CONSEGNA &&
            this.tabellaOrdini.dati[i].COD_ARTICOLO ===
              this.array_tmp_ord_qnt[j].COD_ARTICOLO
          ) {
            this.tabellaOrdini.dati[i].QUANTITA =
              this.tabellaOrdini.dati[i].QUANTITA -
              this.array_tmp_ord_qnt[j].QUANTITA;
            console.log(this.tabellaOrdini.dati[i]);
            console.log(this.array_tmp_ord_qnt[j]);
          }
        }
      }

      this.array_tmp_ord_qnt = [];
      this.array_ordini_quantita = [];
      // this.tabellaOrdini.dati = [];
      this.qtaDisp = null;
      this.dialogConfReg = false;
    },
    chiudiDialogConfermaReg() {
      this.array_tmp_ord_qnt = [];
      this.array_ordini_quantita = [];
      // this.tabellaOrdini.dati = [];
      this.qtaDisp = null;
      this.dialogConfReg = false;
    },
    annullaSelezioneOrdine() {
      this.ordine_tmp = null;
      this.dialog_conferma_ordine = false;
    },
    backFromListaOrdini() {
      // this.tabellaOrdini.dati = [];
      this.dialog_lista_ordini = false;
    },
    selezionaOrdine(ordine_tmp, num_seriale, qta, lotto_fornitore) {
      this.qtaDisp = null;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: ordine_tmp.COD_ARTICOLO,
          id_famiglia:
            this.id_famiglia_sel == null ? "" : this.id_famiglia.id_tabella,
          id_tipo: this.id_tipo == null ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.overlay = false;

        // if (res.data.lista.length > 1) {
        //   this.snackbar = true;
        //   this.snackbar_background = "orange";
        //   this.snackbar_icon = "mdi-alert";
        //   this.snackbar_icon_color = "white";
        //   this.snackbar_text = "Sono presenti più articoli con questo codice.";
        //   this.snackbar_text_color = "white";
        //   this.dialog_lista.tabella.dati = res.data.lista;
        //   this.dialog_lista.caricamento = false;
        //   this.dialog_lista.mostra = true;
        // }

        // if (res.data.lista.length === 0) {
        //   this.snackbar = true;
        //   this.snackbar_background = "error";
        //   this.snackbar_icon = "mdi-close";
        //   this.snackbar_icon_color = "white";
        //   this.snackbar_text =
        //     "Non sono stati trovati articoli con questo codice.";
        //   this.snackbar_text_color = "white";
        // }

        if (res.data.lista.length === 1) {
          // this.selezionaArticolo(res.data.lista[0]);
          this.dialog_buffer.descrizione = res.data.lista[0].des_articolo;
          this.dialog_buffer.um = res.data.dettaglio[0].um_articolo;
          this.dialog_buffer.num_ordine = ordine_tmp.NUM_ORDINE;
          this.dialog_buffer.riga_ordine = ordine_tmp.RIGA_ORDINE;
          this.dialog_buffer.cod_articolo = ordine_tmp.COD_ARTICOLO;
          this.dialog_buffer.quantita = qta ? qta : ordine_tmp.QUANTITA;
          this.dialog_buffer.num_seriale = num_seriale;
          this.dialog_buffer.lotto_fornitore = lotto_fornitore;
        }
        this.dialog_inserimento.mostra = true;
      });

      // this.getUm(ordine_tmp, num_seriale);
      // passo le informazioni dell ordine(item) nell'articolo

      this.tabellaOrdini.dati.forEach((elem) => {
        if (elem.COD_ARTICOLO === ordine_tmp.COD_ARTICOLO) {
          this.array_ordini_quantita.push(elem);
        }
      });

      // this.array_ordini_quantita.sort((a, b) => {
      //   return new Date(b.DATA_CONSEGNA) - new Date(a.DATA_CONSEGNA);
      // });

      this.dialog_conferma_ordine = false;
      this.dialog_lista_ordini = false;

      // alla fine svuoto ordine_tmp
      this.ordine_tmp = null;
      // this.tabellaOrdini.dati = [];
    },
    clickDialogOrdini() {
      // chiamo la get ordini e popolo l'array che mi servira per
      // visualizzare la lista degli ordini

      this.getOrdini();
      this.caricamento = true;

      this.dialog_lista_ordini = true;
    },
    clickItemListaOrdini(item) {
      if (item.QUANTITA < 0) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_text =
          "Quantità ordine negativa, Impossibile aggiungere l'articolo!";
        this.snackbar_icon = "mdi-close-outline";
        this.snackbar_text_color = "white";
        this.snackbar_icon_color = "white";
        return;
      }
      this.ordine_tmp = Object.assign({}, item);
      // QUI
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.ordine_tmp.COD_ARTICOLO,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.dialog_buffer.id_articolo = res.data.lista[0].id_articolo;
      });
      this.dialog_conferma_ordine = true;
    },
    chiudiDialogRicercaArt() {
      this.dialog_lista.mostra = false;
      this.dialog_lista.ricerca = null;
    },
    chiudiDialogRicercaFornitore() {
      this.dialogSearchAnagrafica = false;
      this.ricerca_anagrafica = null;
    },
    detectQrCode(arg) {
      this.qr_code_sel = arg;
      this.qr_attivo = true;

      if (this.qr_code_sel) {
        this.getQr(this.qr_code_sel);
      }
    },
    eliminaRiga(riga) {
      riga.validita = riga.validita == 0 ? 1 : 0;
    },

    /* DIALOG */
    mostraDialog(riga, clona = false) {
      let indice = this.tabella.dati.indexOf(riga);
      this.dialog_inserimento.indice = indice;
      let campi = Object.keys(this.dialog_buffer);
      for (let i = 0; i < campi.length; i++) {
        if (campi[i] === "num_colli") {
          this.dialog_buffer["num_colli"] = 1;
        } else {
          this.dialog_buffer[campi[i]] = indice >= 0 ? riga[campi[i]] : null;
        }
      }

      this.dialog_inserimento.mostra = true;
      if (clona) {
        this.dialog_inserimento.indice = -1;
      }
      let that = this;
      this.$nextTick(function () {
        that.$refs.form.resetValidation();
      });
    },
    selezionaArticolo(articolo) {
      this.dialog_buffer.cod_articolo = articolo.cod_articolo;
      this.dialog_buffer.id_articolo = articolo.id_articolo;
      this.dialog_buffer.um = articolo.des_um;
      this.dialog_buffer.id_um = articolo.id_um;
      this.dialog_buffer.descrizione = articolo.des_articolo;
      this.dialog_lista.mostra = false;
    },
    selezionaAnagrafica(fornitore) {
      this.dettaglio.tipo_soggetto = fornitore.tipo;
      this.dettaglio.id_anagrafica = fornitore.id_anagrafica;
      this.dettaglio.ragione_sociale = fornitore.ragione_sociale;
      this.codice_fornitore = fornitore.codice;
      this.dialogSearchAnagrafica = false;
    },
    salvaModifiche() {
      if (!this.$refs.form.validate()) {
        return;
      }

      /* Controllo che la coppia codice lotto fornitore - numero seriale
       * non ricorra per nessuna delle righe già registrate.*/
      let ricorre = false;
      this.tabella.dati.forEach((e) => {
        if (
          e.num_seriale === this.dialog_buffer.num_seriale &&
          e.lotto_fornitore === this.dialog_buffer.lotto_fornitore
        ) {
          ricorre = true;
        }
      });

      if (ricorre === true) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Questo numero seriale è già stato inserito nel documento con associazione a questo lotto fornitore."
        );
        return;
      }

      // Se sono state passate tutte le modifiche, compongo la request.

      let indice = this.dialog_inserimento.indice;
      if (indice < 0) {
        let clone = Object.assign({}, this.dialog_buffer);
        clone.validita = 1;
        clone.id_documento_dettaglio = -1;
        clone.id_documento = -1;
        console.log(clone);

        if (this.array_ordini_quantita.length) {
          if (
            clone.quantita - this.array_ordini_quantita[0].QUANTITA <=
            500
            // this.lista_um.tolleranza
          ) {
            console.log("entro nell if tolleranza");
            // Metto da parte la quantità dell'ordine
            let qta_ordine = clone.quantita;

            // Assegno la quantità e il num_riga per ogni riga splittata
            clone.num_riga = this.array_ordini_quantita[0].RIGA_ORDINE;
            clone.quantita = clone.quantita / clone.num_colli;

            // A ogni riga splittata assegno le quantità dei colli e dei componenti
            for (let i = 0; i < clone.num_colli; i++) {
              let obj_tmp = Object.assign({}, clone);
              obj_tmp.num_colli = 1;

              qta_ordine -= obj_tmp.quantita;
              obj_tmp.rimanenza = qta_ordine;

              this.tabella.dati.push(Object.assign({}, obj_tmp));
            }

            // ... E la quantità dei componenti, con la rimanenza rispetto alla qta dell'ordine

            this.array_ordini_quantita.forEach((elem) => {
              if (elem.COD_ARTICOLO == clone.cod_articolo) {
                elem.QUANTITA = elem.QUANTITA - clone.quantita;
              }
            });
          } else if (clone.quantita <= this.array_ordini_quantita[0].QUANTITA) {
            console.log("entro nell if quantità <= ord_quantità");
            let qta_ordine = clone.quantita;

            clone.num_riga = this.array_ordini_quantita[0].RIGA_ORDINE;
            clone.quantita = clone.quantita / clone.num_colli;

            let obj_tmp = Object.assign({}, clone);
            obj_tmp.num_colli = 1;

            for (let i = 0; i < clone.num_colli; i++) {
              qta_ordine -= obj_tmp.quantita;
              obj_tmp.rimanenza = qta_ordine;
              this.tabella.dati.push(Object.assign({}, obj_tmp));
            }
            this.array_ordini_quantita.forEach((elem) => {
              if (elem.COD_ARTICOLO == clone.cod_articolo) {
                elem.QUANTITA = elem.QUANTITA - clone.quantita;
              }
            });
          } else {
            if (this.array_ordini_quantita.length <= 1) {
              this.$refs.SnackbarQV.print(
                "error",
                "Quantità superiore alla quantità dell'ordine. Contattare il responsabile"
              );
              this.qr_attivo = false;
              clone.num_riga = this.array_ordini_quantita[0].RIGA_ORDINE;
              this.itemTmp = Object.assign({}, clone);
              this.array_ordini_quantita.forEach((elem) => {
                if (elem.COD_ARTICOLO == clone.cod_articolo) {
                  elem.QUANTITA = elem.QUANTITA - clone.quantita;
                }
              });
            } else {
              let quantitaTmp = clone.quantita;
              // Azzero la quantità disponibile
              this.qtaDisp = 0;

              this.array_ordini_quantita.forEach((elem) => {
                if (elem.QUANTITA > 0) {
                  this.qtaDisp = this.qtaDisp + elem.QUANTITA;
                }
                // Resetto l'array di supporto
                this.array_tmp_ord_qnt = new Array();

                let elemTmp = Object.assign({}, elem);
                elemTmp.num_riga = elem.RIGA_ORDINE;
                elemTmp.QUANTITA =
                  quantitaTmp >= elem.QUANTITA ? elem.QUANTITA : quantitaTmp;
                quantitaTmp =
                  quantitaTmp - elemTmp.QUANTITA >= 0
                    ? quantitaTmp - elemTmp.QUANTITA
                    : 0;
                if (elemTmp.QUANTITA > 0) {
                  this.array_tmp_ord_qnt.push(elemTmp);
                }
              });

              if(this.dialog_buffer.quantita > (this.qtaDisp + 500)){
                this.$refs.SnackbarQV.print("error","Quantità superiore a ordinato, contattare il responsabile.");
                this.qr_attivo = false;
                return;
              }

              this.dialogConfReg = true;

              
            }
          }
        } else {
          // Mi metto da parte la quantità dell'ordine
          let qta_ordine = clone.quantita;

          // Assegno al clone alcune proprietà
          clone.quantita = clone.quantita / clone.num_colli;

          let obj_tmp = Object.assign({}, clone);
          obj_tmp.num_colli = 1;

          for (let i = 0; i < clone.num_colli; i++) {
            qta_ordine -= obj_tmp.quantita;
            obj_tmp.rimanenza = qta_ordine;
            this.tabella.dati.push(Object.assign({}, obj_tmp));
          }
          this.array_ordini_quantita.forEach((elem) => {
            console.log("entro quii");
            if (elem.COD_ARTICOLO == clone.cod_articolo) {
              elem.QUANTITA = elem.QUANTITA - clone.quantita;
            }
          });
        }
      } else {
        let campi = Object.keys(this.dialog_buffer);
        let qta_backup = this.tabella.dati[indice].quantita;
        let riga_tmp = this.tabella.dati[indice].riga_ordine;
        for (let i = 0; i < campi.length; i++) {
          this.tabella.dati[indice][campi[i]] = this.dialog_buffer[campi[i]];
        }
        let qta_after = this.tabella.dati[indice].quantita;
        this.tabellaOrdini.dati.forEach((ordine) => {
          if (ordine.RIGA_ORDINE == riga_tmp) {
            ordine.QUANTITA =
              parseInt(ordine.QUANTITA) + (qta_backup - qta_after);
          }
        });
      }
      this.dialog_inserimento.mostra = false;
      if (this.qr_attivo) {
        this.$refs.qr.attiva_telecamera();
      }
    },
    salvaDati() {
      if (
        !(
          this.dettaglio.ragione_sociale &&
          this.dettaglio.num_documento &&
          this.dettaglio.data_documento
        )
      ) {
        this.$refs.SnackbarQV.print(
          "error",
          "Tutti i campi del form devono essere valorizzati."
        );
        return;
      } else if (!this.tabella.dati.length) {
        this.$refs.SnackbarQV.print(
          "error",
          "Il documento deve contenere almeno un articolo"
        );
        return;
      }
      //  else {
      //   if (this.dettaglio.id_documento === -1) {
      //     let clone = Object.assign({}, this.dettaglio);
      //     clone.dettaglio = this.tabella.dati;
      //     this.value.unshift(clone);
      //   } else {
      //     this.value.forEach((elem) => {
      //       if (elem.id_documento === this.dettaglio.id_documento) {
      //         elem.ragione_sociale = this.dettaglio.ragione_sociale;
      //         elem.num_documento = this.dettaglio.num_documento;
      //         elem.data_documento = this.dettaglio.data_documento;
      //         elem.dettaglio = this.tabella.dati;
      //       }
      //     });
      //   }
      // }

      // Popolo l'array del riepilogo
      this.items_riepilogo = Array.from(this.popolaRiepilogo());
      this.dialog_riepilogo = true;
    },
    clickStampaAccettazioni() {
      this.conferma_stampa_visible = true;
    },
    stampaAccettazioni() {
      console.log(
        `Stampa ${
          this.tabella.dati.filter((e) => e.stampa === true).length
        } articoli.`
      );
      this.conferma_stampa_visible = false;
    },

    getArticoli() {
      this.dialog_lista.caricamento = true;

      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getArticoli",
          token: localStorage.user_token,
          cod_articolo: this.dialog_buffer.cod_articolo,
        },
      };
      let that = this;
      this.$store.dispatch("api", richiesta).then((risposta) => {
        if (risposta.data.lista.length > 1) {
          that.dialog_lista.tabella.dati = risposta.data.lista;
          // that.dialog_lista.caricamento = false;
          this.dialog_lista.mostra = true;
        }

        if (risposta.data.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non sono stati trovati articoli con questo nome.";
          this.snackbar_text_color = "white";
        }

        if (risposta.data.lista.length === 1) {
          that.dialog_lista.tabella.dati = risposta.data.lista;
          that.dialog_lista.caricamento = false;
          this.dialog_lista.mostra = true;
        }
      });
    },

    getAnagrafica() {
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          token: localStorage.getItem("user_token"),
          // cod_anagrafica: this.cod_anagrafica,
          des_anagrafica: this.dettaglio.ragione_sociale,
          tipo_anagrafica: "F",
        },
      };
      this.$store.dispatch("api", searchReq).then((res) => {
        if (res.data.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Il Fornitore non è stato trovato. Riprova!";
          this.snackbar_text_color = "white";
          this.loadingVal = false;
          this.progressBar = false;
          this.savebutton = false;
          this.topDiv = true;
        }
        if (res.data.length > 1) {
          this.ragioneSocialeLista = res.data;
          this.dialogSearchAnagrafica = true;
        }
        if (res.data.length == 1) {
          this.dialogSearchAnagrafica = true;
          this.overlay = false;
          this.ragioneSocialeLista = res.data;
          this.dialogSearchAnagrafica = true;
        }
      });
    },

    getDocumento() {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getdocumento",
          token: localStorage.user_token,
          id_documento: this.bolla_dettaglio,
        },
      };
      this.$store.dispatch("api", richiesta).then((risposta) => {
        this.dettaglio = Object.assign({}, risposta.data.documento[0]);

        if (!this.admin_power) {
          let res = risposta.data.documento[0].dettaglio;
          const arr = res.filter((elem) => elem.validita);
          this.tabella.dati = arr;
        } else {
          this.tabella.dati = risposta.data.documento[0].dettaglio;
        }
        this.codice_fornitore = this.dettaglio.cod_anagrafica;
      });
    },

    getOrdini(codice_articolo, num_seriale = "", qta, lotto_fornitore) {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getordini",
          token: localStorage.user_token,
          cod_fornitore: this.codice_fornitore,
        },
      };

      // Faccio scattare l'overlay di caricamento
      this.caricamento = true;

      this.$store
        .dispatch("api", richiesta)
        .then((risposta) => {
          // Fermo l'overlay di caricamento
          this.caricamento = false;

          // Gestisco la response
          let result = risposta.data.result;
          this.tabellaOrdini.dati = [];
          this.items_articoli = [];
          if (result.riga1) {
            let count = 0;
            for (let campo in result) {
              this.tabellaOrdini.dati.push(Object.assign({}, result[campo]));
              this.items_articoli.push(Object.assign({}, result[campo]));
              if (result[campo].COD_ARTICOLO === codice_articolo) {
                let ordine_tmp;
                ordine_tmp = result[campo];

                this.selezionaOrdine(
                  ordine_tmp,
                  num_seriale,
                  qta,
                  lotto_fornitore
                );
                count++;
              }
              if (count === 0 && this.qr_attivo) {
                this.$refs.SnackbarQV.print(
                  "error",
                  "Articolo non presente negli ordini. Contattare il responsabile."
                );
              }
            }
          } else {
            this.tabellaOrdini.dati = [result];
            this.items_articoli = [result];
          }

          this.tabella.dati.forEach((ordine_tbl) => {
            let qta_ordine_utile = ordine_tbl.quantita;
            this.tabellaOrdini.dati.forEach((ordine) => {
              if (
                ordine_tbl.riga_ordine == ordine.RIGA_ORDINE &&
                ordine_tbl.validita != 0 &&
                qta_ordine_utile > 0 &&
                ordine.QUANTITA + 500 > ordine_tbl.quantita
              ) {
                ordine.QUANTITA = ordine.QUANTITA - ordine_tbl.quantita;
                qta_ordine_utile = qta_ordine_utile - qta_ordine_utile;
                console.log("è questo");
              }
            });
          });

          this.items_articoli = this.tabellaOrdini.dati;

          if (this.dettaglio.synch === null) {
            for (let i = 0; i < this.tabellaOrdini.dati.length; i++) {
              for (let j = 0; j < this.dettaglio.dettaglio.length; j++) {
                if (
                  this.tabellaOrdini.dati[i].COD_ARTICOLO ==
                    this.dettaglio.dettaglio[j].cod_articolo &&
                  this.tabellaOrdini.dati[i].RIGA_ORDINE ==
                    this.dettaglio.dettaglio[j].num_riga &&
                  this.dettaglio.dettaglio[j].validita != 0
                ) {
                  this.tabellaOrdini.dati[i].QUANTITA =
                    this.tabellaOrdini.dati[i].QUANTITA -
                    this.dettaglio.dettaglio[j].quantita;
                }
              }
            }
          } else if (risposta.status === 204) {
            this.caricamento = false;
            this.snackbar = true;
            this.snackbar_text =
              "Nessun ordine disponibile per questo fornitore";
            this.snackbar_background = "error";
          }
        })
        .catch((error) => {
          this.caricamento = false;
          this.snackbar = true;
          this.snackbar_text = error;
          this.snackbar_background = "error";
        });
    },
    getUm(ordine_tmp, num_seriale) {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoloum",
          token: localStorage.user_token,
          cod_articolo: ordine_tmp
            ? ordine_tmp.COD_ARTICOLO
            : this.ordine_tmp.COD_ARTICOLO,
        },
      };
      this.$store
        .dispatch("api", richiesta)
        .then((risposta) => {
          this.lista_um = risposta.data;

          this.dialog_buffer.descrizione = this.lista_um.des_articolo;
          this.dialog_buffer.um = this.lista_um.cod_um;
          this.dialog_buffer.num_ordine = ordine_tmp.NUM_ORDINE;
          this.dialog_buffer.riga_ordine = ordine_tmp.RIGA_ORDINE;
          this.dialog_buffer.cod_articolo = ordine_tmp.COD_ARTICOLO;
          this.dialog_buffer.quantita = ordine_tmp.QUANTITA;
          this.dialog_buffer.num_seriale = num_seriale;

          if (ordine_tmp) {
            this.dialog_inserimento.mostra = true;
          }
        })
        .catch(() => {
          this.caricamento = false;
          this.snackbar = true;
          this.snackbar_text =
            "Non puoi selezionare questo articolo, non è presente nel nostro database";
          this.snackbar_background = "error";
        });
    },

    getQr(arg) {
      if (arg.includes("GETQRCPS;") && arg.includes("(P)")) {
        // Pesco il codice articolo
        let codice_articolo;
        codice_articolo = arg.split("(P)")[1].split(";")[0];

        // Pesco il numero seriale
        let num_seriale;
        if (arg.includes("(B)"))
          num_seriale = arg.split("(B)")[1].split(";")[0];

        // Pesco la quantità ordine
        let qta;
        if (arg.includes("(Q)")) qta = arg.split("(Q)")[1].split(";")[0];

        // Pesco il lotto fornitore
        let lotto_fornitore;
        if (arg.includes("(H)"))
          lotto_fornitore = arg.split("(H)")[1].split(";")[0];

        /* Controllo che la coppia codice lotto fornitore - numero seriale
         * non ricorra per nessuna delle righe già registrate.*/
        let ricorre = false;
        this.tabella.dati.forEach((e) => {
          if (
            e.num_seriale === num_seriale &&
            e.lotto_fornitore === lotto_fornitore
          ) {
            ricorre = true;
          }
        });

        if (ricorre === true) {
          this.$refs.SnackbarQV.print(
            "orange",
            "Questo numero seriale è già stato inserito nel documento con associazione a questo lotto fornitore."
          );
          this.qr_attivo = false;
          // this.$refs.qr.attiva_telecamera();
          return;
        }

        // Lancio la chiamata getOrdini
        this.getOrdini(codice_articolo, num_seriale, qta, lotto_fornitore);
      } else {
        this.$refs.SnackbarQV.print("error", "QR code non valido.");
        this.qr_attivo = false;
      }
    },

    goToAnagrafica() {
      if (
        this.dettaglio.ragione_sociale === "" ||
        this.dettaglio.ragione_sociale === null
      ) {
        return;
      }

      // this.overlay = true;
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",

          token: localStorage.getItem("user_token"),
          des_anagrafica: this.dettaglio.ragione_sociale,
          id_famiglia:
            this.id_famiglia_sel == null ? "" : this.id_famiglia.id_tabella,
          id_tipo: this.id_tipo == null ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.overlay = false;

        if (res.data.length > 1) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Sono presenti più fornitori con questo nome.";
          this.snackbar_text_color = "white";
          this.ragioneSocialeLista = res.data;
          this.dialogSearchAnagrafica = true;
        }

        if (res.data.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non sono stati trovati fornitori con questo nome.";
          this.snackbar_text_color = "white";
        }

        if (res.data.length === 1) {
          this.selezionaAnagrafica(res.data[0]);
        }
      });

      // this.articolo_search_results = false;
    },

    goToArticolo() {
      if (this.dialog_buffer.cod_articolo === "") {
        return;
      }

      // this.overlay = true;
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.dialog_buffer.cod_articolo,
          id_famiglia:
            this.id_famiglia_sel == null ? "" : this.id_famiglia.id_tabella,
          id_tipo: this.id_tipo == null ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.overlay = false;

        if (res.data.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non sono stati trovati articoli con questo codice.";
          this.snackbar_text_color = "white";
        }

        if (res.data.lista.length === 1) {
          this.selezionaArticolo(res.data.lista[0]);
        }
      });

      // this.articolo_search_results = false;
    },

    setDocumento() {
      let arr = [];
      let documento_tmp = new Object();

      const tabella_tmp = _.cloneDeep(this.tabella.dati);

      for (let i = 0; i < tabella_tmp.length; i++) {
        tabella_tmp[i].num_riga = i;
      }

      documento_tmp = _.cloneDeep(this.dettaglio);
      documento_tmp.dettaglio = Array.from(tabella_tmp);

      arr.push(documento_tmp);

      let richiesta = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setdocumento",
          token: localStorage.user_token,
          documento: arr,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        if (res.status === 200) {
          this.$emit("goBack");
        } else {
          this.$refs.SnackbarQV.print(
            "orange",
            "Errore nello cabio di dati col server."
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.dettaglio_bolla {
  position: relative;
}
.dettaglio_bolla > header {
  border-radius: inherit;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.record_tabella {
  text-align: left;
}
.record_tabella > td {
  text-align: left;
}
.record_tabella[data-deleted] {
  background-color: #ffcccc !important;
}
.record_tabella[data-deleted]:hover {
  background-color: #ffb3b3 !important;
}
</style>