<template>
    <AnagraficaBarche class="pa-5" style="border-radius:0px;" />
</template>

<script>
import AnagraficaBarche from "@/components/ELECTRICALMARINE/AnagraficaBarche.vue";
export default {
  name: "EMAnagraficaBarche",
  components: {
      AnagraficaBarche,
  },
  props: {},
  data() {
    return {
        // Variabili d'appoggio
        // Modal
        // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {

  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
</style>