<template>
  <v-card>
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="3" id="listener_consuntivi_massimaleExtra">
          <!-- <v-text-field
            label="Massimale"
            v-model="massimale_tmp"
          ></v-text-field> -->
          <InputSeparatore
            :decimali_fissi="true"
            :destinazione="'listener_consuntivi_massimaleExtra'"
            :label="'Massimale'"
            :id="'5'"
          />
        </v-col>
        <v-col cols="3" id="listener_consuntivoExtra">
          <!-- <v-text-field
            disabled
            label="Consuntivo"
            v-model="consuntivo"
          ></v-text-field> -->
          <InputSeparatore
            :decimali_fissi="true"
            :disabled="true"
            :destinazione="'listener_consuntivoExtra'"
            :label="'Consuntivo'"
            :id="'6'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers_extra"
            :items="items_extra.filter((elem) => elem.validita === 1)"
            class="elevation-1 mx-5"
          >
            <!-- Titolo tabella -->
            <template v-slot:top>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Spese Extra
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="aggiungiExtra"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Spesa</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <!-- Bottone di aggiunta extra -->
            <template v-slot:[`item.modifica`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickModificaExtra(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Modifica</span>
              </v-tooltip>
            </template>

            <!-- Bottone di eliminazione extra -->
            <template v-slot:[`item.elimina`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickElimina(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-close </v-icon>
                  </v-btn>
                </template>
                <span>Elimina</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="modifica_extra_dialog" width="1000%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Modifica Spesa Extra</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaModificaExtra" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiModificaExtra" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-row align="center" justify="center">
            <v-col cols="3">
              <v-text-field
                label="Descrizione"
                v-model="extra_sel.des_spesa_extra"
                max-width="500px"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="extra_sel.id_tipologia"
                :items="tipologia_extra_lista"
                label="Sede"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">Tipologia</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_TIPOLOGIA_CONSUNTIVO_EXTRA"
                      nome="Tipologia extra"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :controllaCodice="true"
                      :filter="'validita = 1'"
                      :all_label="['des', 'codice', 'val_int']"
                      :dati="[
                        { des: '' },
                        { des_lingua3: '' },
                        { val_int: '' },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" id="listener_importoExtra">
              <!-- <v-text-field
                label="Importo (€)"
                type="number"
                v-model="extra_sel.importo_spesa_extra"
                max-width="150px"
              >
              </v-text-field> -->
              <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_importoExtra'"
                :label="'Importo (€)'"
                :id="'7'"
              />
            </v-col>
            <v-col cols="2" id="listener_costoDirittoChiamata">
              <!-- <v-text-field
                label="Importo (€)"
                type="number"
                v-model="extra_sel.importo_spesa_extra"
                max-width="150px"
              >
              </v-text-field> -->
              <!-- <InputSeparatore
                :decimali_fissi="true"
                :destinazione="'listener_costoDirittoChiamata'"
                :label="'Costo diritto di chiamata (€)'"
                :id="'8'"
              /> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import moment from "moment/moment";
import InputSeparatore from "@/components/TOOLS/InputSeparatore.vue";

export default {
  name: "ConsuntiviExtra",
  components: {
    InputSeparatore,
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {
    consuntivo_extra: { type: Array, default: () => {} },
    id_consuntivo: { type: Number, default: 0 },
    massimale: { type: Number, default: 0 },
  },
  data() {
    return {
      // Variabili d'appoggio
      consuntivo: 0,
      massimale_tmp: 0,
      extra_sel: {},
      tipologia_extra_lista: [],

      // Modal
      modifica_extra_dialog: false,
      aggiungi: false,

      // Array
      headers_extra: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Descrizione", sortable: false, value: "des_spesa_extra" },
        { text: "Tipologia", sortable: false, value: "des_tipologia" },
        { text: "Importo (€)", sortable: false, value: "importo_spesa_extra" },
        // {
        //   text: "Costo Diritto di chiamata (€)",
        //   sortable: false,
        //   value: "costo_diritto_chiamata",
        // },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      items_extra: [],
    };
  },
  computed: {},
  watch: {
    consuntivo_extra: {
      immediate: true,
      handler() {
        this.items_extra = [];
        this.consuntivo_extra.forEach((elem) => {
          this.items_extra.push(elem);
        });

        this.calcolaConsuntivo();
      },
    },
    consuntivo: {
      immediate: true,
      handler() {
        if (this.consuntivo != 0) {
          let messaggio = {
            detail: {
              numero: this.consuntivo,
            },
            src: null,
          };

          setTimeout(() => {
            // Inserisco il messaggio in un evento custom.
            let evento = new CustomEvent("numero_iniziale", messaggio);

            // Consegno alla mia destinazione l'evento
            document
              .getElementById("listener_InputSeparatore6")
              .dispatchEvent(evento);
          }, 10);
        }
      },
    },
    massimale: {
      immediate: true,
      handler() {
        this.massimale_tmp = this.massimale;
        if (this.massimale_tmp != 0) {
          let messaggio = {
            detail: {
              numero: this.massimale_tmp,
            },
            src: null,
          };

          if (document.getElementById("listener_InputSeparatore5") == null) {
            setTimeout(() => {
              // Inserisco il messaggio in un evento custom.
              let evento = new CustomEvent("numero_iniziale", messaggio);

              // Consegno alla mia destinazione l'evento
              document
                .getElementById("listener_InputSeparatore5")
                .dispatchEvent(evento);
            }, 10);
          }
        }
      },
    },
    "extra_sel.importo_spesa_extra": {
      immediate: true,
      handler() {
        if (this.extra_sel.importo_spesa_extra != undefined) {
          let messaggio = {
            detail: {
              numero: this.extra_sel.importo_spesa_extra,
            },
            src: null,
          };
          if (document.getElementById("listener_InputSeparatore7") == null) {
            setTimeout(() => {
              // Inserisco il messaggio in un evento custom.
              let evento = new CustomEvent("numero_iniziale", messaggio);

              // Consegno alla mia destinazione l'evento
              document
                .getElementById("listener_InputSeparatore7")
                .dispatchEvent(evento);
            }, 10);
          }
        }
      },
    },
    // "extra_sel.costo_diritto_chiamata": {
    //   immediate: true,
    //   handler() {
    //     if (this.extra_sel.costo_diritto_chiamata != undefined) {
    //       let messaggio = {
    //         detail: {
    //           numero: this.extra_sel.costo_diritto_chiamata,
    //         },
    //         src: null,
    //       };
    //       if (document.getElementById("listener_InputSeparatore8") == null) {
    //         setTimeout(() => {
    //           // Inserisco il messaggio in un evento custom.
    //           let evento = new CustomEvent("numero_iniziale", messaggio);

    //           // Consegno alla mia destinazione l'evento
    //           document
    //             .getElementById("listener_InputSeparatore8")
    //             .dispatchEvent(evento);
    //         }, 10);
    //       }
    //     }
    //   },
    // },
    modifica_extra_dialog: {
      immediate: true,
      handler() {
        if (this.modifica_extra_dialog == true) {
          let that = this;
          setTimeout(() => {
            document
              .getElementById("listener_importoExtra")
              .addEventListener("nuovo_numero", function (elem) {
                that.extra_sel.importo_spesa_extra = elem.detail.numero;
              });
          }, 10);

          setTimeout(() => {
            document
              .getElementById("listener_costoDirittoChiamata")
              .addEventListener("nuovo_numero", function (elem) {
                that.extra_sel.costo_diritto_chiamata = elem.detail.numero;
              });
          }, 10);
        }
        if (this.modifica_extra_dialog) {
          this.gettabelle();
        }
      },
    },
  },
  methods: {
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_TIPOLOGIA_CONSUNTIVO_EXTRA",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        console.log("RES GET TABELLE:", res.data);
        this.tipologia_extra_lista = res.data;
        if (this.tipologia_extra_lista == "") {
          this.tipologia_extra_lista = [];
        }
      });
    },
    aggiungiExtra() {
      let messaggio = {
        detail: {
          numero: 0,
        },
        src: null,
      };

      // setTimeout(() => {
      //   // Inserisco il messaggio in un evento custom.
      //   let evento = new CustomEvent("numero_iniziale", messaggio);

      //   // Consegno alla mia destinazione l'evento
      //   document
      //     .getElementById("listener_InputSeparatore8")
      //     .dispatchEvent(evento);
      // }, 10);
      setTimeout(() => {
        // Inserisco il messaggio in un evento custom.
        let evento = new CustomEvent("numero_iniziale", messaggio);

        // Consegno alla mia destinazione l'evento
        document
          .getElementById("listener_InputSeparatore7")
          .dispatchEvent(evento);
      }, 10);

      // Resetto l'ectra selezionato
      this.aggiungi = true;
      let nuovo_extra = {
        importo_spesa_extra: 0,
        des_spesa_extra: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_extra: -1,
        id_tipologia: null,
        des_tipologia: "",
        costo_diritto_chiamata: 0,
        validita: 1,
      };

      this.extra_sel = Object.assign({}, nuovo_extra);

      // Apro il dialog di modifica deli extra
      this.modifica_extra_dialog = true;
    },
    calcolaConsuntivo() {
      this.consuntivo = 0;
      this.items_extra
        .filter((elem) => elem.validita === 1)
        .forEach((elem) => {
          this.consuntivo +=
            parseFloat(elem.importo_spesa_extra) +
            parseFloat(elem.costo_diritto_chiamata);
        });

      let messaggio = {
        detail: {
          numero: this.consuntivo,
        },
        src: null,
      };
      if (document.getElementById("listener_InputSeparatore6") == null) {
        setTimeout(() => {
          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("numero_iniziale", messaggio);

          // Consegno alla mia destinazione l'evento
          document
            .getElementById("listener_InputSeparatore6")
            .dispatchEvent(evento);
        }, 10);
      }
    },
    chiudiModificaExtra() {
      this.aggiungi = false;
      // Resetto l'extra selezionato
      let nuovo_extra = {
        importo_spesa_extra: 0,
        des_spesa_extra: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_extra: -1,
        id_tipologia: null,
        des_tipologia: "",
        costo_diritto_chiamata: 0,
        validita: 1,
      };

      this.extra_sel = Object.assign({}, nuovo_extra);

      // Chiudo il dialog di modifica deli extra
      this.modifica_extra_dialog = false;
    },
    clickElimina(arg) {
      this.items_extra.forEach((ex) => {
        if (ex.id_consuntivo_extra === arg.id_consuntivo_extra) {
          ex.validita = 0;
        }
      });

      this.calcolaConsuntivo();
    },
    clickModificaExtra(arg) {
      let messaggio_importo_extra = {
        detail: {
          numero: arg.importo_spesa_extra,
        },
        src: null,
      };
      // let messaggio_costo_diritto_chiamata = {
      //   detail: {
      //     numero: arg.costo_diritto_chiamata,
      //   },
      //   src: null,
      // };

      //setTimeout(() => {
        // Inserisco il messaggio in un evento custom.
      //   let evento = new CustomEvent(
      //     "numero_iniziale",
      //     messaggio_costo_diritto_chiamata
      //   );

      //   // Consegno alla mia destinazione l'evento
      //   document
      //     .getElementById("listener_InputSeparatore8")
      //     .dispatchEvent(evento);
      // }, 10);
      setTimeout(() => {
        // Inserisco il messaggio in un evento custom.
        let evento = new CustomEvent(
          "numero_iniziale",
          messaggio_importo_extra
        );

        // Consegno alla mia destinazione l'evento
        document
          .getElementById("listener_InputSeparatore7")
          .dispatchEvent(evento);
      }, 10);

      this.aggiungi = false;
      // Assegno l'extra selezionato
      this.extra_sel = Object.assign({}, arg);

      // Apro il dialog di modifica degli extra.
      this.modifica_extra_dialog = true;
    },
    salvaModificaExtra() {
      if (this.extra_sel.id_consuntivo_extra === -1) {
        // ... Gli assegno un id semi-ufficiale...
        this.extra_sel.id_consuntivo_extra = -moment().valueOf();
        this.tipologia_extra_lista.forEach((e) => {
          if (this.extra_sel.id_tipologia == e.id_tabella) {
            this.extra_sel.des_tipologia = e.des;
          }
        });
        // ... E lo inserisco nell'array degli extra
        this.items_extra.push(this.extra_sel);
      } else {
        // Altrimenti apporto delle modifiche all'oggetto con id corrispondente
        this.tipologia_extra_lista.forEach((e) => {
          if (this.extra_sel.id_tabella == e.id_tabella) {
            e.id_tipologia = e.id_tabella;
            e.des_tipologia = e.des;
          }
        });
        this.items_extra.forEach((ex) => {
          if (ex.id_consuntivo_extra === this.extra_sel.id_consuntivo_extra) {
            ex.importo_spesa_extra = this.extra_sel.importo_spesa_extra;
            ex.des_spesa_extra = this.extra_sel.des_spesa_extra;
          }
        });
      }
      console.log("ITEMS CONSUNTIVI EXTRA:", this.items_extra);
      // Resetto l'extra selezionato
      let nuovo_extra = {
        importo_spesa_extra: 0,
        des_spesa_extra: "",
        id_consuntivo: this.id_consuntivo,
        id_consuntivo_extra: -1,
        id_tipologia: null,
        des_tipologia: "",
        costo_diritto_chiamata: 0,
        validita: 1,
      };

      this.extra_sel = Object.assign({}, nuovo_extra);

      // Chiudo il dialog di modifica deli extra
      this.modifica_extra_dialog = false;

      this.calcolaConsuntivo();
    },
  },
  created() {},
  mounted() {
    let that = this;
    document
      .getElementById("listener_consuntivi_massimaleExtra")
      .addEventListener("nuovo_numero", function (elem) {
        that.massimale_tmp = elem.detail.numero;
      });
    document
      .getElementById("listener_consuntivoExtra")
      .addEventListener("nuovo_numero", function (elem) {
        that.consuntivo = elem.detail.numero;
      });
  },
};
</script>
<style scoped>
</style>