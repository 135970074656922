<template>
  <div>
    <!-- Delete Confirmation Of Piano Controllo-->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="pianocontrollodeletedialog"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Questo elemento verrà eliminato definitivamente</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="pianocontrollodeletedialog = false"
            >ANNULLA</v-btn
          >
          <v-btn color="green" dark @click="deletePianoControllo">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog In case of Many Articolo -->
    <v-dialog v-model="dialog_gestione_articoli" width="800">
      <v-card>
        <v-toolbar dark color="indigo">
          <v-toolbar-title>Articoli</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn small light @click="dialog_gestione_articoli = false">
            <v-icon color="red"> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 500px; overflow-y: auto">
          <v-data-table :headers="ArticoliHeaders" :items="ArticoliLista">
            <template v-slot:[`item.details`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="selectArticolo(item)"
                      small
                    >
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-hover>
      <template v-slot="{ hover }">
        <v-card witdh="unset" :elevation="hover ? 24 : 2">
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">Piano Controllo</v-toolbar-title>
            <v-spacer></v-spacer>
            <Button
              colore_icona="primary"
              idForm="QUALITA"
              idOggetto="CREA_PIANOCONTROLLO"
              mdi_icona="mdi-plus"
              testo_tooltip="Aggiungi Piano Controllo"
              v-on:buttonClick="gotonewpiano"
            />
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="clickChiudi" small>
                  <v-icon color="error">mdi-keyboard-backspace</v-icon>
                </v-btn>
              </template>
              <span>Torna al menu</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center">
              <v-col class="py-0" cols="2" md="1"></v-col>
              <v-col class="py-0" cols="4" md="6">
                <v-text-field
                  v-model="cod_articolo"
                  label="Cod. Articolo"
                  placeholder="Cod. Articolo"
                  v-on:keydown.enter.prevent="searchPianoControllo"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="2" md="2">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on:keydown.enter.prevent="searchPianoControllo"
                      @click="searchPianoControllo"
                      type="submit"
                      v-bind="attrs"
                      v-on="on"
                      small
                      style="top: 20px; right: 50px"
                      ><v-icon color="primary">mdi-magnify</v-icon></v-btn
                    >
                  </template>
                  <span>Cerca Paino Controllo</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :headers="headers_piano_controllo"
                  :items="item_piano_controllo"
                >
                  <template v-slot:[`item.detail`]="{ item }">
                    <Button
                      colore_icona="primary"
                      idForm="QUALITA"
                      idOggetto="MODIFICA_PIANOCONTROLLO"
                      mdi_icona="mdi-pencil"
                      testo_tooltip="Dettaglio Piano Controllo"
                      v-on:buttonClick="dettaglio_piano_conrtrollo(item)"
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <Button
                      colore_icona="error"
                      idForm="QUALITA"
                      idOggetto="ELIMINA_PIANOCONTROLLO"
                      mdi_icona="mdi-window-close"
                      testo_tooltip="Cancellare"
                      v-on:buttonClick="
                        pianocontrollodeletedialog = true;
                        selectedPiano = item;
                      "
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
  </div>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
export default {
  components: {
    Button,
  },
  data() {
    return {
      // Caracheristics Array
      cod_articolo: null,
      selectedPiano: {},
      dialog_gestione_articoli: false,
      headers_piano_controllo: [],
      pianocontrollodeletedialog: false,
      item_piano_controllo: [],
      ArticoliHeaders: [
        {
          text: "",
          value: "details",
          sortable: false,
        },
        {
          text: "Cod Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des Articolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Famiglia",
          value: "id_famiglia",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "id_tipo",
          sortable: false,
        },
        {
          text: "Tipo Fornitura",
          value: "id_tipo_fornitura",
          sortable: false,
        },
      ],
      // DATA LISTA
      ArticoliLista: [],
    };
  },
  methods: {
    // IN order to get piano controllo of single articolo
    // In order to Get the Detail of Articolo from dialog of Articoli
    selectArticolo(item) {
      this.cod_articolo = item.cod_articolo;
      this.id_articolo = item.id_articolo;
      this.getPianoControllo();
      this.dialog_gestione_articoli = false;
    },
    deletePianoControllo() {
      this.overlay = true;
      this.selectedPiano.validita = 0;
      let delPiano = {
        controller: "qualita",
        method: "PUT",
        richiesta: {
          action: "setpianocontrollo",
          token: localStorage.user_token,
          qm_piano_controllo: [this.selectedPiano],
          id_piano_controllo: this.selectedPiano.id_piano_controllo,
        },
      };
      this.$store.dispatch("api", delPiano).then(() => {
        this.overlay = false;
        let i = this.item_piano_controllo.findIndex((it) => it == this.selectedPiano);
        this.item_piano_controllo.splice(i, 1);
        this.snackbar = true;
        this.snackbar_text = "Modifica effettuata con successo!";
        this.color = "green darken-3";
        this.mainIcon = "mdi-check-circle";
        this.selectedPiano = {};
        this.pianocontrollodeletedialog = false;
      });
    },
    gotonewpiano() {
      this.$router.push({
        name: "PianoControlloDetail",
        params: { id: -1 },
      });
    },
    searchPianoControllo() {
      this.overlay = true;
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
        },
      };
      // Eseguo la chiamata al BE
      this.$store.dispatch("api", search_Articolo).then((res) => {
        if (res.data.lista.length == 0) {
          this.snackbar = true;
          this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
          this.loadingVal = false;
          this.progressBar = false;
          this.overlay = false;
          this.savebutton = false;
          this.topDiv = true;
        }
        if (res.data.lista.length > 1) {
          this.overlay = false;
          this.dialog_gestione_articoli = true;
          this.ArticoliLista = res.data.lista;
        }
        if (res.data.lista.length == 1) {
          this.overlay = false;
          this.id_articolo = res.data.lista[0].id_articolo;
          this.cod_articolo = res.data.lista[0].cod_articolo;
          this.getPianoControllo();
          // Getting Ciclo lista to populate the table
        }
      });
    },
    getPianoControllo() {
      this.item_piano_controllo = [];
      this.headers_piano_controllo = [];
      let getPianoControllo = {
        controller: "qualita",
        method: "POST",
        richiesta: {
          action: "getpianocontrollo",
          token: localStorage.user_token,
          id_piano_controllo: "",
          id_articolo: this.id_articolo,
        },
      };
      this.$store.dispatch("api", getPianoControllo).then((res) => {
        if (res.data.lista.length == 0) {
          this.snackbar = true;
          this.snackbar_text = "Non esiste un ciclo autocontrollo per questo articolo.";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
        }
        if (res.data.lista.length > 1) {
          this.headers_piano_controllo = res.data.header;
          /* Aggiungo l'header per la modifica. */
          let edit = {
            value: "detail",
            sortable: false,
          };
          this.headers_piano_controllo.unshift(edit);
          /* Aggiungo l'header per la cancellazione. */
          let del = {
            value: "delete",
            sortable: false,
          };
          this.headers_piano_controllo.push(del);
          this.item_piano_controllo = res.data.lista;
        }
        if (res.data.lista.length == 1) {
          this.dettaglio_piano_conrtrollo(res.data.lista[0]);
        }
      });
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    dettaglio_piano_conrtrollo(item) {
      this.$router.push({
        name: "PianoControlloDetail",
        params: { id: item.id_piano_controllo },
      });
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.overlay = true;
      let getPianoControllo = {
        controller: "qualita",
        method: "POST",
        richiesta: {
          action: "getpianocontrollo",
          token: localStorage.user_token,
          id_piano_controllo: "",
          id_articolo: this.$route.params.id,
        },
      };
      this.$store.dispatch("api", getPianoControllo).then((res) => {
        this.overlay = false;
        if (res.data.lista.length == 0) {
          this.snackbar = true;
          this.snackbar_text = "Non esiste un ciclo autocontrollo per questo articolo.";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
        }
        this.headers_piano_controllo = res.data.header;
        /* Aggiungo l'header per la modifica. */
        let edit = {
          value: "detail",
          sortable: false,
        };
        this.headers_piano_controllo.unshift(edit);
        /* Aggiungo l'header per la cancellazione. */
        let del = {
          value: "delete",
          sortable: false,
        };
        this.headers_piano_controllo.push(del);
        this.item_piano_controllo = res.data.lista;
        this.dialog_gestione_articoli = false;
      });
    }
  },
};
</script>
