export default class Warehouse {
    constructor(
        id_articolo_magazzino,
        id_articolo,
        id_magazzino,
        note_magazzino,
        magazzino_predefinito,
        lotto_economico,
        qta_scorta,
        qta_sottoscorta,
        date_ins,
        date_mod,
        id_utente,
        cod_magazzino,
        des_magazzino,
        tipo_magazzino
    ) {
        this.id_articolo_magazzino = id_articolo_magazzino;
        this.id_articolo = id_articolo;
        this.id_magazzino = id_magazzino;
        this.note_magazzino = note_magazzino;
        this.magazzino_predefinito = magazzino_predefinito;
        this.lotto_economico = lotto_economico;
        this.qta_scorta = qta_scorta;
        this.qta_sottoscorta = qta_sottoscorta;
        this.date_ins = date_ins;
        this.date_mod = date_mod;
        this.id_utente = id_utente;
        this.cod_magazzino = cod_magazzino;
        this.des_magazzino = des_magazzino;
        this.tipo_magazzino = tipo_magazzino;
    }
}