<template>
  <div id="listener_carrello" style="text-align: center">
    <!-- Card per le commesse vuote -->
    <v-card v-if="items_carrello.length == 0" width="unset">
      <v-toolbar class="white--text rounded-t" color="indigo" dense>
        <v-toolbar-title>Commesse in Gestione</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Filtro del cod. macchina -->
        <v-autocomplete
          background-color="#ffffff"
          class="custompadding mr-5"
          color="white"
          dense
          hide-details
          :items="macchine_lista"
          item-text="cod_macchina"
          item-value="cod_macchina"
          label="Cod. Macchina"
          style="
            color: white;
            text-align: center;
            display: inline-block;
            max-width: 350px;
          "
          v-model="cod_macchina_sel"
        >
          <template #label><span>Cod. Macchina</span></template>
          <template v-slot:append>
            <v-icon class="ml-2" @click="cod_macchina_sel = ''" color="primary"
              >mdi-filter-off</v-icon
            >
          </template>
        </v-autocomplete>

        <!-- Filtro del reparto -->
        <v-autocomplete
          background-color="#ffffff"
          class="custompadding"
          color="white"
          dense
          hide-details
          :items="reparti_lista.filter((elem) => elem != null)"
          label="Reparto"
          style="display: inline-block; max-width: 350px"
          v-model="reparto_sel"
        >
          <template #label>
            <span class="white--text">Reparto</span>
          </template>
          <template v-slot:append>
            <v-icon class="ml-2" @click="reparto_sel = ''" color="primary"
              >mdi-filter-off</v-icon
            >
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class=""
              @click="gotoDashboard"
              small
            >
              <v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Vai alla Dashboard</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="mt-5"
        >Ciao {{ nome_utente }}, la lista commesse è vuota: vuoi aggiungere una
        commessa?</v-card-text
      >
      <v-divider></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="my-2"
            @click="primaCommessa"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Commessa</span>
      </v-tooltip>
    </v-card>

    <!-- Tabella delle commesse in gestion (quando ce ne sono) -->
    <v-data-table
      class="elevation-1"
      @click:row="clickCarrelloRow"
      :headers="headers_carrello"
      :items="items_carrello"
      v-if="items_carrello.length > 0"
      style="display: inline-block; width: 100%"
    >
      <!-- Titolo della tabella -->
      <template v-slot:top>
        <v-toolbar class="white--text rounded-t" color="indigo" dense>
          <v-toolbar-title>Commesse in Gestione</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- Filtro del cod. macchina -->
          <v-autocomplete
            background-color="#ffffff"
            class="custompadding mr-5"
            color="white"
            dense
            hide-details
            :items="macchine_lista"
            item-text="cod_macchina"
            item-value="cod_macchina"
            label="Cod. Macchina"
            style="
              color: white;
              text-align: center;
              display: inline-block;
              max-width: 350px;
            "
            v-model="cod_macchina_sel"
          >
            <template #label><span>Cod. Macchina</span></template>
            <template v-slot:append>
              <v-icon
                class="ml-2"
                @click="cod_macchina_sel = ''"
                color="primary"
                >mdi-filter-off</v-icon
              >
            </template>
          </v-autocomplete>

          <!-- Filtro del reparto -->
          <v-autocomplete
            background-color="#ffffff"
            class="custompadding"
            color="white"
            dense
            hide-details
            :items="reparti_lista.filter((elem) => elem != null)"
            label="Reparto"
            style="display: inline-block; max-width: 350px"
            v-model="reparto_sel"
          >
            <template #label>
              <span class="white--text">Reparto</span>
            </template>
            <template v-slot:append>
              <v-icon class="ml-2" @click="reparto_sel = ''" color="primary"
                >mdi-filter-off</v-icon
              >
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="aggiorna"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span
              >Aggiorna, ultimo aggiornamento: {{ ultimo_aggiornamento }}</span
            >
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="clickAggiungi" small v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Commessa</span>
          </v-tooltip>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class=""
                @click="gotoDashboard"
                small
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Vai alla Dashboard</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <!-- Bottone dei Prelievi -->
      <template v-slot:[`item.prelievi`]="{ item }">
        <v-btn
          v-if="item.controllo != 1 && item.materia_prima == 1"
          @click="clickPrelievi(item)"
          color="primary"
          dark
          fab
          small
        >
          P
        </v-btn>
        <!--<v-btn color="blue-grey lighten-5" dark fab small v-else
          ><span class="grey--text">P</span></v-btn
        >-->
      </template>

      <!-- Bottone di Gestione Lavorazione -->
      <template v-slot:[`item.gestione_lavorazione`]="{ item }">
        <v-btn
          @click="clickGestioneLavorazione(item)"
          color="primary"
          dark
          fab
          small
          v-if="item.controllo != 1"
          >G</v-btn
        >
      </template>

      <!-- Bottone di Registrazione -->
      <template v-slot:[`item.registra`]="{ item }">
        <v-btn
          @click="clickRegistra(item)"
          color="primary"
          dark
          fab
          small
          v-if="
            item.controllo != 1 &&
            ((item.materia_prima == 1 && item.qta_realizzabile > 0) ||
              item.materia_prima == 0)
          "
          >R</v-btn
        >
      </template>

      <!-- Bottone del Controllo Qualità -->
      <template v-slot:[`item.controllo`]="{ item }">
        <v-btn
          @click="clickControllo(item)"
          color="error"
          dark
          fab
          small
          v-if="item.controllo === 1"
        >
          C
        </v-btn>
      </template>

      <!-- Pulsante per la visualizzazione dei dettagli -->
      <template v-slot:[`item.visualizza_dettagli`]="{ item }">
        <v-btn
          class="my-3"
          @click="clickVisualizzaDisegno(item)"
          :color="
            item.id_disegno == null
              ? 'grey lighten-5'
              : item.view_disegno == true
              ? 'primary'
              : 'error'
          "
          fab
          small
        >
          <v-icon :color="item.id_disegno == null ? 'black' : 'white'">
            mdi-eye
          </v-icon>
        </v-btn>
      </template>

      <!-- Bottone del Controllo Qualità -->
      <template v-slot:[`item.qta_realizzabile`]="{ item }">
        <v-chip
          :color="
            item.materia_prima == 1 && item.qta_realizzabile == 0
              ? 'red'
              : 'green'
          "
          v-show="item.materia_prima == 1"
        >
          <span style="color: white">
            <b> {{ item.materia_prima == 0 ? "" : item.qta_realizzabile }} </b>
          </span>
        </v-chip>
      </template>
    </v-data-table>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Carrello",
  props: {
    cod_macchina_default: { type: String, default: "" },
    items_carrello: { type: Array, default: () => [] },
    macchine_lista: { type: Array, default: () => [] },
    reparti_lista: { type: Array, default: () => [] },
    reparto_default: { type: String, default: "" },
    ultimo_aggiornamento: { type: String, default: "" },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_macchina_sel: "",
      nome_utente: localStorage.getItem("nominativo"),
      reparto_sel: "",

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      headers_carrello: [
        {
          text: "Prelievi",
          sortable: false,
          value: "prelievi",
          class: "primary--text white",
        },
        {
          text: "Gestione",
          sortable: false,
          value: "gestione_lavorazione",
          class: "primary--text white",
        },
        {
          text: "Registra",
          sortable: false,
          value: "registra",
          class: "primary--text white",
        },
        {
          text: "Controllo",
          sortable: false,
          value: "controllo",
          class: "primary--text white",
        },
        {
          text: "Disegno",
          sortable: false,
          value: "visualizza_dettagli",
          class: "primary--text white",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
          class: "primary--text white",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
          class: "primary--text white",
        },
        {
          text: "Cod. Fase",
          sortable: false,
          value: "cod_fase",
          class: "primary--text white",
        },
        {
          text: "Cod. Macchina",
          sortable: false,
          value: "cod_macchina",
          class: "primary--text white",
        },
        {
          text: "Des. Fase",
          sortable: false,
          value: "des",
          class: "primary--text white",
        },
        {
          text: "Ora Start",
          sortable: false,
          value: "ora_start",
          class: "primary--text white",
        },
        {
          text: "Ora Stop",
          sortable: false,
          value: "ora_stop",
          class: "primary--text white",
        },
        {
          text: "Q.tà Commessa",
          sortable: false,
          value: "quantita",
          class: "primary--text white",
        },
        {
          text: "Q.tà Prodotta",
          sortable: false,
          value: "pezzi_registrati",
          class: "primary--text white",
        },
        {
          text: "Q.tà Realizzabile",
          sortable: false,
          value: "qta_realizzabile",
          class: "primary--text white",
        },
      ],
    };
  },
  methods: {
    // redirected to production dashboard
    gotoDashboard() {
      this.$router.push("/home/production");
    },
    aggiorna() {
      this.$emit("aggiornamento_richiesto");
    },
    aggiungiCommessa(messaggio) {
      // Controllo che la commessa non sia già nel carrello.
      let ricorre = false;
      this.items_carrello.forEach((elem) => {
        if (
          elem.numero_commessa == messaggio.detail.commessa_sel.numero_commessa
        ) {
          ricorre = true;
        }
      });

      // Stampo errore se la commessa è già nel carrello.
      if (ricorre == true) {
        this.snackbar = true;
        this.snackbar_color = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_text = "Commessa già presente nel carrello.";
      } else {
        /* Se è tutto ok aggiungo la commessa al carrello e apro
         * la finestra di evasione della commessa.*/
        let commessa_da_aggiungere = Object.assign(
          {},
          messaggio.detail.commessa_sel
        );
        commessa_da_aggiungere.fase = 1;
        this.items_carrello.push(commessa_da_aggiungere);
      }
    },
    cambiaMacchina(messaggio) {
      this.items_carrello.forEach((elem) => {
        if (elem.num_commessa == messaggio.detail.num_commessa) {
          elem.cod_macchina = messaggio.detail.cod_macchina;
        }
      });
    },
    clickAggiungi() {
      this.$emit("click_aggiungi");
    },
    clickCarrelloRow(item) {
      this.$emit("click_row", item);
    },
    clickControllo(item) {
      console.log("entrato clickControllo del Carrello");
      this.$emit("click_controllo", item);
    },
    clickGestioneLavorazione(item) {
      this.$emit("click_gestione_lavorazione", item);
    },
    clickPrelievi(item) {
      this.$emit("click_prelievi", item);
    },
    clickRegistra(item) {
      this.$emit("click_registra", item);
    },
    clickVisualizzaDisegno(item) {
      /* Controllo che ci siano disegni da
       * visualizzare (me ne accorgo perché se
       * non ci sono id_disegno == null). */
      if (item.id_disegno == null) {
        // Se non ci sono disegni lancio una snackber di errore
        this.snackbar = true;
        this.snackbar_background = "grey lighten-5";
        this.snackbar_icon = "mdi-information";
        this.snackbar_icon_color = "indigo";
        this.snackbar_text = "Non ci sono disegni da visualizzare.";
        this.snackbar_text_color = "black";
      } else {
        /* Se ci sono disegni emetto normalmente l'evento,
         * così il componente padre apre il dialog dei disegni. */
        this.$emit("click_visualizza_disegno", item);
      }
    },
    primaCommessa() {
      this.$emit("prima_commessa");
    },
  },
  mounted() {
    document
      .getElementById("listener_carrello")
      .addEventListener("cambiata_macchina", this.cambiaMacchina);
  },
  beforeDestroy() {
    // Rimuovo il listener per l'evento clicked_row
    if (document.getElementById("listener_carrello")) {
      document
        .getElementById("listener_carrello")
        .removeEventListener("clicked_row", this.aggiungiCommessa);
    }
  },
  watch: {
    cod_macchina_default: {
      immediate: true,
      handler() {
        if (
          this.cod_macchina_default != null &&
          this.cod_macchina_default != ""
        ) {
          this.cod_macchina_sel = this.cod_macchina_default;
        }
      },
    },
    reparto_default: {
      immediate: true,
      handler() {
        if (this.reparto_default != null && this.reparto_default != "") {
          this.reparto_sel = this.reparto_default;
        }
      },
    },
  },
};
</script>
<style>
.custompadding .v-label > span {
  color: grey !important;
  padding: 0 5px !important;
  transition: color 0.3s ease;
}
.custompadding .v-label.v-label--active > span {
  color: white !important;
}
.custompadding input {
  padding: 0 5px !important;
}
</style>