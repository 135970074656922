var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3","id":"listener_consuntivi_massimaleExtra"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"destinazione":'listener_consuntivi_massimaleExtra',"label":'Massimale',"id":'5'}})],1),_c('v-col',{attrs:{"cols":"3","id":"listener_consuntivoExtra"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"disabled":true,"destinazione":'listener_consuntivoExtra',"label":'Consuntivo',"id":'6'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 mx-5",attrs:{"headers":_vm.headers_extra,"items":_vm.items_extra.filter(function (elem) { return elem.validita === 1; })},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Spese Extra ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.aggiungiExtra}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Spesa")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaExtra(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])])]}},{key:"item.elimina",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickElimina(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"1000%"},model:{value:(_vm.modifica_extra_dialog),callback:function ($$v) {_vm.modifica_extra_dialog=$$v},expression:"modifica_extra_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Modifica Spesa Extra")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaModificaExtra}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiModificaExtra}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Descrizione","max-width":"500px"},model:{value:(_vm.extra_sel.des_spesa_extra),callback:function ($$v) {_vm.$set(_vm.extra_sel, "des_spesa_extra", $$v)},expression:"extra_sel.des_spesa_extra"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.tipologia_extra_lista,"label":"Sede","placeholder":"Seleziona","item-text":"des","item-value":"id_tabella","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-2"},[_vm._v("Tipologia")]),_c('span',{staticStyle:{"min-width":"500px","pointer-events":"all"},on:{"click":function($event){$event.stopPropagation();}}},[_c('Modules',{attrs:{"tipo":"MAN_TIPOLOGIA_CONSUNTIVO_EXTRA","nome":"Tipologia extra","setaction":"settabella","getaction":"gettabella","controller":"Global","controllaCodice":true,"filter":'validita = 1',"all_label":['des', 'codice', 'val_int'],"dati":[
                      { des: '' },
                      { des_lingua3: '' },
                      { val_int: '' } ]},on:{"dialogModules":_vm.gettabelle}})],1)]},proxy:true}]),model:{value:(_vm.extra_sel.id_tipologia),callback:function ($$v) {_vm.$set(_vm.extra_sel, "id_tipologia", $$v)},expression:"extra_sel.id_tipologia"}})],1),_c('v-col',{attrs:{"cols":"2","id":"listener_importoExtra"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"destinazione":'listener_importoExtra',"label":'Importo (€)',"id":'7'}})],1),_c('v-col',{attrs:{"cols":"2","id":"listener_costoDirittoChiamata"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }