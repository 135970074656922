var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ width: _vm.width, height: _vm.height })},[_c('div',{style:({
      backgroundColor: 'red',
      display: 'inline-block',
      height: _vm.height,
      magrin: '0px',
      padding: '0px',
      position: 'relative',
      width: _vm.width,
      zIndex: 2,
    }),attrs:{"id":"wrapper_svg"}},[_vm._l((_vm.nodi_interni.filter(
        function (n) { return n.categoria === 'rettangolo_smussato'; }
      )),function(nodo){return _c('div',{key:nodo.id_nodo,style:({
        borderRadius: '5px 5px 5px 5px',
        boxShadow: '2px 2px 4px #000000',
        display: 'inline-block',
        height: nodo.height + 'px',
        left: nodo.x + 'px',
        margin: 0 + 'px',
        maxHeight: nodo.height + 'px',
        maxWidth: nodo.width + 'px',
        padding: 0 + 'px',
        position: 'absolute',
        top: nodo.y + 'px',
        width: nodo.width + 'px',
        zIndex: 3,
      }),attrs:{"id":'div_nodo_' + nodo.id_nodo},on:{"mousedown":function($event){return _vm.startDragging($event, nodo)},"mouseup":function($event){return _vm.endDragging($event)},"mousemove":function($event){return _vm.dragNode($event)},"contextmenu":function($event){return _vm.clickDestroNodo($event, nodo)}}},[_c('div',{style:({
          backgroundColor: '#3f51b5',
          border: '1px solid black',
          borderRadius: '5px 5px 0px 0px',
          color: 'white',
          height: nodo.height * 0.3 + 'px',
          lineHeight: nodo.height * 0.3 + 'px',
          padding: '0px 0px 10px 0px',
          textAlign: 'left',
          textShadow: '2px 2px 4px #000000',
          userSelect: 'none',
        })},[_c('span',{staticStyle:{"bottom":"2px","font-size":"13px","left":"2px","position":"relative"}},[_vm._v(_vm._s(nodo.titolo_nodo_flowchart))])]),_c('div',{style:({
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '0px 0px 5px 5px',
          height: nodo.height * 0.7 + 'px',
          margin: '0px',
          maxHeight: nodo.height * 0.7 + 'px',
          maxWidth: nodo.width + 'px',
          padding: '0px',
          overflow: 'hidden',
          textAlign: 'center',
          userSelect: 'none',
          verticalAlign: 'middle',
          width: nodo.width + 'px',
        })},[_c('div',{style:({
            backgroundColor: 'white',
            border: '0px',
            borderRadius: '0px 0px 5px 5px',
            display: 'table-cell',
            height: nodo.height * 0.7 + 'px',
            maxHeight: nodo.height * 0.7 + 'px',
            maxWidth: nodo.width + 'px',
            overflow: 'hidden',
            textAlign: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            width: nodo.width + 'px',
          })},[_c('span',{staticStyle:{"font-size":"13px","position":"relative","top":"-5px"}},[_vm._v(_vm._s(nodo.testo_nodo_flowchart))])])]),(_vm.modalita_editing === 'aggiungi_freccia')?_c('div',{style:({
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: 10 + 'px',
          left: nodo.width / 2 - 5 + 'px',
          margin: 0 + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: -5 + 'px',
          userSelect: 'none',
          width: 10 + 'px',
        }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDrawing($event, nodo, 'top')},"mouseup":function($event){$event.stopPropagation();return _vm.stopDrawing($event, nodo, 'top')}}}):_vm._e(),(_vm.modalita_editing === 'aggiungi_freccia')?_c('div',{style:({
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: 10 + 'px',
          left: nodo.width / 2 - 5 + 'px',
          margin: 0 + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.height - 5 + 'px',
          userSelect: 'none',
          width: 10 + 'px',
        }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDrawing($event, nodo, 'bottom')},"mouseup":function($event){$event.stopPropagation();return _vm.stopDrawing($event, nodo, 'bottom')}}}):_vm._e(),(_vm.modalita_editing === 'aggiungi_freccia')?_c('div',{style:({
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: 10 + 'px',
          left: -5 + 'px',
          margin: 0 + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.height / 2 - 5 + 'px',
          userSelect: 'none',
          width: 10 + 'px',
        }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDrawing($event, nodo, 'left')},"mouseup":function($event){$event.stopPropagation();return _vm.stopDrawing($event, nodo, 'left')}}}):_vm._e(),(_vm.modalita_editing === 'aggiungi_freccia')?_c('div',{style:({
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: 10 + 'px',
          left: nodo.width - 5 + 'px',
          margin: 0 + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.height / 2 - 5 + 'px',
          userSelect: 'none',
          width: 10 + 'px',
        }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDrawing($event, nodo, 'right')},"mouseup":function($event){$event.stopPropagation();return _vm.stopDrawing($event, nodo, 'right')}}}):_vm._e()])}),_vm._l((_vm.nodi_interni.filter(
        function (n) { return n.categoria === 'nodo_immagine'; }
      )),function(nodo){return _c('div',{key:nodo.id_nodo,style:({
        backgroundImage: 'url(' + nodo.background_src.src + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        borderRadius: '5px 5px 5px 5px',
        boxShadow: '2px 2px 4px #000000',
        display: 'inline-block',
        height: nodo.height + 'px',
        left: nodo.x + 'px',
        margin: 0 + 'px',
        maxHeight: nodo.height + 'px',
        maxWidth: nodo.width + 'px',
        padding: 0 + 'px',
        position: 'absolute',
        top: nodo.y + 'px',
        width: nodo.width + 'px',
        zIndex: 3,
      }),attrs:{"id":'div_nodo_' + nodo.id_nodo},on:{"mousedown":function($event){return _vm.startDragging($event, nodo)},"mouseup":function($event){return _vm.endDragging($event)},"mousemove":function($event){return _vm.dragNode($event)},"contextmenu":function($event){return _vm.clickDestroNodo($event, nodo)}}})}),_c('svg',{staticClass:"ma-0 pa-0",style:({
        backgroundColor: 'white',
        border: '1px solid black',
        display: 'inline-block',
        height: _vm.height,
        position: 'relative',
        width: _vm.width,
      }),attrs:{"id":"svg","xmlns":"http://www.w3.org/2000/svg","xmlns:svg":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"},on:{"mousedown":function($event){return _vm.startDrawing($event, undefined, '')},"mouseup":function($event){return _vm.stopDrawing($event, undefined, undefined)},"contextmenu":function($event){$event.stopPropagation();return _vm.clickDestroSVG.apply(null, arguments)}}},[_c('defs',[_c('marker',{attrs:{"id":"blackarrowend","viewBox":"0 0 24 24","refX":"12","refY":"12","markerWidth":"6","markerHeight":"6","orient":"auto-start-reverse"}},[_c('path',{attrs:{"stroke":"#000000","fill":"#000000","d":"M2,21L23,12L2,3V10L17,12L2,14V21Z"}})]),_c('marker',{attrs:{"id":"greenarrowend","viewBox":"0 0 24 24","refX":"12","refY":"12","markerWidth":"6","markerHeight":"6","orient":"auto-start-reverse"}},[_c('path',{attrs:{"stroke":"#00ff00","fill":"#00ff00","d":"M2,21L23,12L2,3V10L17,12L2,14V21Z"}})]),_c('marker',{attrs:{"id":"bluearrowend","viewBox":"0 0 24 24","refX":"12","refY":"12","markerWidth":"6","markerHeight":"6","orient":"auto-start-reverse"}},[_c('path',{attrs:{"stroke":"#0000ff","fill":"#0000ff","d":"M2,21L23,12L2,3V10L17,12L2,14V21Z"}})]),_c('marker',{attrs:{"id":"redarrowend","viewBox":"0 0 24 24","refX":"12","refY":"12","markerWidth":"6","markerHeight":"6","orient":"auto-start-reverse"}},[_c('path',{attrs:{"stroke":"#ff0000","fill":"#ff0000","d":"M2,21L23,12L2,3V10L17,12L2,14V21Z"}})])])])],2),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.menu_contestuale_visible),expression:"menu_contestuale_visible"}],staticStyle:{"z-index":"99"},attrs:{"id":"menu_contestuale","width":"250px"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Menu Contestuale ")])],1),_c('v-list',[(
          _vm.bvc.bottone_aggiungi_nodo_visible === true &&
          _vm.allow_aggiungi_nodo === true
        )?_c('v-list-item',{on:{"click":function($event){return _vm.enterModEditAggiungiNodo('aggiungi_nodo')}}},[_c('v-list-item-title',[_vm._v(" Aggiungi Nodo ")])],1):_vm._e(),(
          _vm.bvc.bottone_aggiungi_immagine_visible === true &&
          _vm.allow_aggiungi_immagine === true
        )?_c('v-list-item',{on:{"click":function($event){return _vm.enterModEditAggiungiNodo('aggiungi_immagine')}}},[_c('v-list-item-title',[_vm._v(" Aggiungi Immagine ")])],1):_vm._e(),(
          _vm.bvc.bottone_aggiungi_linea_visible === true &&
          _vm.allow_aggiungi_linea === true
        )?_c('v-list-item',{on:{"click":function($event){return _vm.enterModEditAggiungiLinea('aggiungi_linea')}}},[_c('v-list-item-title',[_vm._v(" Aggiungi Linea ")])],1):_vm._e(),(
          _vm.bvc.bottone_aggiungi_freccia_visible === true &&
          _vm.allow_aggiungi_freccia === true
        )?_c('v-list-item',{on:{"click":_vm.enterModEditAggiungiFreccia}},[_c('v-list-item-title',[_vm._v(" Aggiungi Freccia ")])],1):_vm._e(),(
          _vm.bvc.bottone_copia_nodo_visible === true && _vm.allow_copia_nodo === true
        )?_c('v-list-item',{on:{"click":_vm.enterModEditCopiaNodo}},[_c('v-list-item-title',[_vm._v(" Copia Nodo ")])],1):_vm._e(),(
          _vm.bvc.bottone_modifica_nodo_visible === true &&
          _vm.allow_modifica_nodo === true
        )?_c('v-list-item',{on:{"click":_vm.enterModEditModificaNodo}},[_c('v-list-item-title',[_vm._v(" Modifica Nodo ")])],1):_vm._e(),(
          _vm.bvc.bottone_modifica_freccia_visible === true &&
          _vm.allow_modifica_freccia === true
        )?_c('v-list-item',{on:{"click":_vm.enterModEditModificaFreccia}},[_c('v-list-item-title',[_vm._v(" Modifica Freccia ")])],1):_vm._e(),(
          _vm.bvc.bottone_elimina_freccia_visible === true &&
          _vm.allow_elimina_freccia === true
        )?_c('v-list-item',{on:{"click":_vm.clickEliminaFreccia}},[_c('v-list-item-title',[_vm._v(" Elimina Freccia ")])],1):_vm._e(),(
          _vm.bvc.bottone_elimina_nodo_visible === true &&
          _vm.allow_elimina_nodo === true
        )?_c('v-list-item',{on:{"click":_vm.clickEliminaNodo}},[_c('v-list-item-title',[_vm._v(" Elimina Nodo ")])],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_freccia),callback:function ($$v) {_vm.conferma_elimina_freccia=$$v},expression:"conferma_elimina_freccia"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Eliminazione Connessione")])],1),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"overflow-y":"auto"}},[_vm._v(" Confermi di voler cancellare la connessione selezionata? "),_c('v-divider',{staticClass:"my-5"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.annullaEliminaFreccia}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Annulla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.confermaEliminaFreccia}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])])],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_nodo),callback:function ($$v) {_vm.conferma_elimina_nodo=$$v},expression:"conferma_elimina_nodo"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Eliminazione Nodo")])],1),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"overflow-y":"auto"}},[_vm._v(" Confermi di voler cancellare l'elemento selezionato? "),_c('v-divider',{staticClass:"my-5"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.annullaEliminaNodo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Annulla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.confermaEliminaNodo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])])],1)],1)],1),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }