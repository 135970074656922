<template>
  <v-container fluid class="py-0">
    <!-- Snackbar to show alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon color="error">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- dialog for delete confirmation -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogElimina"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Questo elemento verrà eliminato definitivamente</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="green" dark @click="delasso">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog to see the result of ragione sociale or fornitura -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogRagioneSocialeFornitura"
      width="700"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Ragione sociale</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogRagioneSocialeFornitura = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed class="" color="red"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table :loading="true" :headers="headers1" :items="searchRes">
            <v-progress-linear
              v-show="progressBar"
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template v-slot:[`item.actions`]="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" @click="onSetItem(props.item)" small>
                    <v-icon dark v-bind="attrs" v-on="on" small color="#009900">
                      mdi-check
                    </v-icon>
                  </v-btn>
                </template>
                <span> imposta Fornitura </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog to add or update Association-->
    <v-dialog content-class="artDialog" persistent v-model="dialog" width="900">
      <v-overlay :value="overlay" style="z-index: 10">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">
            Aggiungi Associazioni
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="ARTICOLO_ASSOCIAZIONI"
            idOggetto="SALVA_ARTICOLO_ASSOCIAZIONI"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="salvaAssociation"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  clearDetail();
                  dialog = false;
                "
                small
              >
                <v-icon v-on="on" v-bind="attrs" depressed class="" color="red"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              @keyup.enter.native="searchAssociazioni"
              class=""
              ref="form"
            >
              <v-container fluid>
                <v-row>
                  <v-col class="py-0" sm="3" cols="3" md="3">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_NUMERO_DISEGNO"
                      label_input="Numero Disegno"
                      palceholder_input="Numero Disegno"
                      :vmodel="numero_disegno"
                      v-on:changeText="(val) => (this.numero_disegno = val)"
                    />
                  </v-col>
                  <v-col class="py-0" cols="3" sm="4" md="3">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="dum_format"
                          label="DUM"
                          prepend-icon="event"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dum"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="py-0" cols="3" sm="3" md="2">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_COD_ALTERNATIVO"
                      label_input="Codice Alternativo"
                      palceholder_input="Codice Alt."
                      :vmodel="cod_alternativo"
                      v-on:changeText="(val) => (this.cod_alternativo = val)"
                    />
                  </v-col>
                  <v-col class="py-0" cols="3" sm="3" md="4">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_DES_ALTERNATIVO"
                      label_input="Des Codice"
                      palceholder_input="Des Codice"
                      :vmodel="des_alternativo"
                      v-on:changeText="(val) => (this.des_alternativo = val)"
                    />
                  </v-col>
                  <v-col sm="3" class="py-0" cols="4" md="4">
                    <v-autocomplete
                      class="ml-1"
                      v-model="id_gestione_magazzino"
                      :items="getione_magazzino_list"
                      item-text="des"
                      placeholder="Gestione Magazzino"
                      required
                      item-value="id_tabella"
                      label="Gestione Magazzino"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" cols="4" sm="2" md="2">
                    <TextField
                      classe_input="input-class centered-input"
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_QUANTITA_MINIMO"
                      label_input="Quantita Minimo"
                      palceholder_input="Quantita Minimo"
                      :vmodel="quantita_stock_minimo"
                      v-on:changeText="
                        (val) => (this.quantita_stock_minimo = val)
                      "
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" cols="4" sm="2" md="2">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_QUANTITA_MASSIMO"
                      :rules_input="[rules.min]"
                      ref="quantita_stock_massimo"
                      label_input="Quantita Massimo"
                      palceholder_input="Quantita Massimo"
                      :vmodel="quantita_stock_massimo"
                      v-on:changeText="
                        (val) => (this.quantita_stock_massimo = val)
                      "
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" cols="4" sm="2" md="2">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_REVISIONE_ARTICOLO"
                      label_input="Revisione"
                      palceholder_input="Revisione"
                      :vmodel="revisione_articolo"
                      v-on:changeText="(val) => (this.revisione_articolo = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" cols="4" sm="2" md="2">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_INDICE_MODIFICA"
                      label_input="Indice"
                      palceholder_input="Indice"
                      :vmodel="indice_modifica"
                      v-on:changeText="(val) => (this.indice_modifica = val)"
                    />
                  </v-col>
                  <v-col class="py-0" cols="4" sm="3" md="4">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_DES_ANAGRAFICA"
                      label_input="Codice"
                      palceholder_input="Codice"
                      :vmodel="des_anagrafica"
                      v-on:changeText="(val) => (this.des_anagrafica = val)"
                    />
                  </v-col>
                  <v-col class="py-0" cols="4" sm="3" md="7">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_RAGIONE_SOCIALE"
                      label_input="Ragione Sociale"
                      palceholder_input="Ragione Sociale"
                      :vmodel="ragione_sociale"
                      v-on:changeText="(val) => (this.ragione_sociale = val)"
                    />
                  </v-col>
                  <v-col class="mt-4" cols="1" sm="1" md="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          color="#1a80b6"
                          @click="searchAssociazioni"
                          @keyup.enter.native="searchAssociazioni"
                          >mdi-magnify</v-icon
                        >
                      </template>
                      <span>Cerca </span>
                    </v-tooltip>
                  </v-col>
                  <v-col sm="2" class="py-0" cols="4" md="8">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_INDIRIZZO"
                      label_input="Indirizzo"
                      palceholder_input="Indirizzo"
                      :vmodel="indirizzo"
                      v-on:changeText="(val) => (this.indirizzo = val)"
                    />
                  </v-col>
                  <v-col class="py-0" cols="4" sm="2" md="4">
                    <TextField
                      idForm="ASSOCIAZIONI"
                      idOggetto="AS_LOCALITA"
                      label_input="Località"
                      palceholder_input="Località"
                      :vmodel="localita"
                      v-on:changeText="(val) => (this.localita = val)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n4">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Main -->
    <v-row>
      <v-col md="12" sm="11">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-toolbar
                dense
                class="indigo darken-1 headline"
                primary-title
                uppercase
              >
                <v-toolbar-title class="white--text"
                  >Associazioni</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <Button
                  colore_icona="primary"
                  idForm="ARTICOLO_ASSOCIAZIONI"
                  idOggetto="CREA_ARTICOLO_ASSOCIAZIONI"
                  mdi_icona="mdi-plus"
                  testo_tooltip="Aggiungi Nuova Associazioni"
                  v-on:buttonClick="openModal"
                />
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text style="max-height: 600px; overflow-y: auto">
                <v-row>
                  <v-col md="12">
                    <v-data-table
                      :loading="loading"
                      :items="items"
                      :headers="headers"
                      style=""
                    >
                      <v-progress-linear
                        v-show="progressBar1"
                        slot="progress"
                        color="blue"
                        indeterminate
                      ></v-progress-linear>
                      <template v-slot:[`item.edit`]="{ item }">
                        <Button
                          colore_icona="primary"
                          idForm="ARTICOLO_ASSOCIAZIONI"
                          idOggetto="MODIFICA_ARTICOLO_ASSOCIAZIONI"
                          mdi_icona="mdi-pencil"
                          testo_tooltip="Aggiornare"
                          style="float: left"
                          v-on:buttonClick="handleClick(item)"
                        />
                      </template>
                      <template v-slot:[`item.dum`]="{ item }">{{
                        item.dum | formatDate
                      }}</template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <Button
                          colore_icona="error"
                          idForm="ARTICOLO_ASSOCIAZIONI"
                          idOggetto="ELIMINA_ARTICOLO_ASSOCIAZIONI"
                          mdi_icona="mdi-window-close"
                          testo_tooltip="Cancellare"
                          v-on:buttonClick="SelectAssociation(item)"
                        />
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />

    <!-- Loader -->
    <v-overlay :value="overlay" style="">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import moment from "moment";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";

export default {
  components: {
    Button,
    SnackbarQV,
    TextField,
  },
  props: ["data"],
  data() {
    return {
      // Deletion Pop Up
      selectedAsso: {},
      dialogElimina: false,
      // Min and max Validation rule
      rules: {
        min: (value) => {
          if (this.quantita_stock_minimo != null) {
            if (this.quantita_stock_minimo != null && value != null) {
              return parseInt(value) > parseInt(this.quantita_stock_minimo);
            } else return false;
          } else return true;
        },
      },
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_text: "",
      progressBar: true,
      progressBar1: true,
      indirizzo: "",
      localita: "",
      des_anagrafica: "",
      loading: false,
      id_disegno: -1,
      des_alternativo: "",
      numero_disegno: "",
      dialogRagioneSocialeFornitura: false,
      menu: false,
      menu2: false,
      getione_magazzino_list: [],
      dialog: false,
      cod_alternativo: "",
      cod_disegno: "",
      codice: "",
      overlay: false,
      des_tipo_magazzino: "",
      dum: "",
      id_anagrafica: 0,
      id_articolo: this.data.id_articolo,
      searchRes: [],
      id_gestione_magazzino: 0,
      indice_modifica: "/",
      singleItem: {},
      quantita_stock_massimo: 0,
      quantita_stock_minimo: 0,
      ragione_sociale: "",
      revisione_articolo: 0,
      tipo_disegno: 0,
      validita: 1,
      // Search Ragione sociale table header
      headers1: [
        {
          align: "start",
          text: "Actions",
          value: "actions",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "_tipo",
          sortable: false,
        },
        {
          text: "Codice",
          value: "codice",
          sortable: false,
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Indrizzo",
          value: "indirizzo",
          sortable: false,
        },
      ],
      // Table header for assocaitions
      headers: [
        {
          value: "edit",
          sortable: false,
          align: "start",
        },
        {
          text: "Numero Disegno",
          value: "cod_disegno",
          sortable: false,
        },
        {
          text: "Gestione Magazzino",
          value: "des_tipo_magazzino",
          sortable: false,
        },
        {
          text: "Stock Min",
          value: "quantita_stock_minimo",
          sortable: false,
        },
        {
          text: "Stock Max",
          value: "quantita_stock_massimo",
          sortable: false,
        },
        {
          text: "Codice Alternativo",
          value: "cod_alternativo",
          sortable: false,
        },
        {
          text: "Desc Codice",
          value: "des_alternativo",
          sortable: false,
        },
        {
          text: "Revisione",
          value: "revisione_articolo",
          sortable: false,
        },
        {
          text: "Indice",
          value: "indice_modifica",
          sortable: false,
        },
        {
          text: "D.U.M",
          value: "_dum",
          sortable: false,
        },
        {
          text: "Ragione Sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  methods: {
    // Function trigger when Annulla button is clicked
    delSelectedItem() {
      this.selectedAsso = {};
      this.dialogElimina = false;
    },
    // In order to open confirmation pop up for delete
    SelectAssociation(item) {
      this.dialogElimina = true;
      this.selectedAsso = item;
    },
    // Click X button to close modal and clear all the variable values
    clearDetail() {
      this.id_disegno = "";
      this.codice = "";
      this.id_gestione_magazzino = "";
      this.id_anagrafica = "";
      this.ragione_sociale = "";
      this.quantita_stock_massimo = null;
      this.quantita_stock_minimo = null;
      this.des_alternativo = "";
      this.dum = "";
      this.cod_disegno = "";
      this.indice_modifica = "";
      this.revisione_articolo = "";
      this.numero_disegno = "";
      this.cod_alternativo = "";
      this.des_anagrafica = "";
    },
    // In order to get single association value
    handleClick(item) {
      this.dialog = true;
      this.singleItem = item;
      this.id_disegno = this.singleItem.id_disegno;
      this.codice = this.singleItem.codice;
      this.id_gestione_magazzino = this.singleItem.id_gestione_magazzino;
      this.id_anagrafica = this.singleItem.id_anagrafica;
      this.ragione_sociale = this.singleItem.ragione_sociale;
      this.quantita_stock_massimo = this.singleItem.quantita_stock_massimo;
      this.quantita_stock_minimo = this.singleItem.quantita_stock_minimo;
      this.des_alternativo = this.singleItem.des_alternativo;
      this.indirizzo = this.singleItem.indirizzo;
      this.localita = this.singleItem.localita;
      this.dum = this.singleItem._dum
        ? moment(this.singleItem._dum, ["DD/MM/YYYY"]).format("YYYY-MM-DD")
        : "";
      this.cod_disegno = this.singleItem.cod_disegno;
      this.indice_modifica = this.singleItem.indice_modifica;
      this.revisione_articolo = this.singleItem.revisione_articolo;
      this.numero_disegno = this.singleItem.cod_disegno;
      this.cod_alternativo = this.singleItem.cod_alternativo;
      this.des_anagrafica = this.singleItem.codice;
    },
    // set Anagrafica detail
    onSetItem(val) {
      this.codice = val.codice;
      this.id_anagrafica = val.id_anagrafica;
      this.des_anagrafica = val.codice;
      this.indirizzo = val.indirizzo;
      this.localita = val.localita;
      this.ragione_sociale = val.ragione_sociale;
      this.dialogRagioneSocialeFornitura = false;
      this.id_disegno = this.singleItem.id_disegno;
    },
    //    In order to del single association
    delasso() {
      this.overlay = true;
      let data = {};
      data.id_disegno = this.selectedAsso.id_disegno;
      data.validita = 0;
      let setAsso = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticoliassociazione",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo,
          articolo_disegni: [data],
        },
      };
      this.$store.dispatch("api", setAsso).then(() => {
        this.overlay = false;
        this.snackbar = true;
        this.snackbar_text = "Modifica effettuata con successo!";
        this.color = "green darken-3";
        this.mainIcon = "mdi-check-circle";
      });
      let i = this.items.findIndex((it) => it.id_disegno === data.id_disegno);
      this.items.splice(i, 1);
      this.dialogElimina = false;
    },
    // In order to save a new or update an existing Association
    salvaAssociation() {
      this.overlay = true;
      if (
        parseFloat(this.quantita_stock_minimo) !== 0 &&
        parseFloat(this.quantita_stock_minimo) > parseFloat(this.quantita_stock_massimo)
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          "La quantità massima dovrebbe essere maggiore della quantità minima."
        );
        this.overlay = false;
        console.log(this.quantita_stock_minimo, this.quantita_stock_massimo);
        return;
      }
      if (!this.singleItem.id_disegno) {
        if (this.numero_disegno === "") {
          this.$refs.SnackbarQV.print(
            "orange",
            "Inserire il Numero Disegno prima di salvare."
          );
          this.overlay = false;
          return;
        }
        if (this.ragione_sociale === "") {
          this.$refs.SnackbarQV.print(
            "orange",
            "Inserire il Ragione Sociale prima di salvare."
          );
          this.overlay = false;
          return;
        }

        let data = {};
        data.id_disegno = -1;
        data.id_articolo = this.id_articolo;
        data.tipo_disegno = 0;
        data.localita = this.localita;
        data.indirizzo = this.indirizzo;
        data.codice = this.des_anagrafica;
        data.des_alternativo = this.des_alternativo;
        data.id_gestione_magazzino =
          this.id_gestione_magazzino == "" ? null : this.id_gestione_magazzino;
        data.id_anagrafica = this.id_anagrafica;
        data.ragione_sociale = this.ragione_sociale;
        data.quantita_stock_massimo = this.quantita_stock_massimo;
        data.quantita_stock_minimo = this.quantita_stock_minimo;
        data.cod_alternativo = this.cod_alternativo;
        data.cod_disegno = this.numero_disegno;
        data.revisione_articolo = this.revisione_articolo;
        data.indice_modifica = this.indice_modifica;
        data.dum = this.dum;
        data._dun = this.dum;
        data.validita = 1;
        if (data.id_gestione_magazzino !== null) {
          this.getione_magazzino_list.map((it) => {
            if (it.id_tabella === data.id_gestione_magazzino) {
              data.des_tipo_magazzino = it.des;
              return it;
            }
          });
        }
        let setAsso = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticoliassociazione",
            token: localStorage.getItem("user_token"),
            id_articolo: this.id_articolo,
            articolo_disegni: [data],
          },
        };
        this.$store.dispatch("api", setAsso).then((res) => {
          if (res.status == 200) {
            this.fetchAssociationz();
            this.overlay = false;
            this.dialog = false;
            this.snackbar = true;
            this.snackbar_text = "Modifica effettuata con successo!";
            this.color = "green darken-3";
            this.mainIcon = "mdi-check-circle";
          }
        });
        this.getione_magazzino_list.map((it) => {
          if (it.id_tabella === data.id_gestione_magazzino) {
            data.des_tipo_magazzino = it.des;
            return it;
          }
        });
        this.id_disegno = "";
        this.des_anagrafica = "";
        this.codice = "";
        this.id_gestione_magazzino = "";
        this.id_anagrafica = "";
        this.ragione_sociale = "";
        this.quantita_stock_massimo = null;
        this.quantita_stock_minimo = null;
        this.cod_alternativo = "";
        this.revisione_articolo = 0;
        this.indice_modifica = "";
        this.des_alternativo = "";
        this.dum = "";
        this.localita = "";
        this.dialog = false;
      }
      if (this.singleItem.id_disegno) {
        let data = {};
        data.id_disegno = this.id_disegno;
        data.id_articolo = this.id_articolo;
        data.tipo_disegno = 0;
        data.localita = this.localita;
        data.indirizzo = this.indirizzo;
        data.codice = this.des_anagrafica;
        data.id_gestione_magazzino = this.id_gestione_magazzino;
        data.id_anagrafica = this.id_anagrafica;
        data.ragione_sociale = this.ragione_sociale;
        data.quantita_stock_massimo = this.quantita_stock_massimo;
        data.quantita_stock_minimo = this.quantita_stock_minimo;
        data.cod_alternativo = this.cod_alternativo;
        data.des_alternativo = this.des_alternativo;
        data.dum = this.dum;
        data._dun = this.dum;
        data.validita = 1;
        (data.cod_disegno = this.numero_disegno),
          (data.indice_modifica = this.indice_modifica);
        data.revisione_articolo = this.revisione_articolo;
        this.getione_magazzino_list.map((it) => {
          if (it.id_tabella === data.id_gestione_magazzino) {
            data.des_tipo_magazzino = it.des;
            return it;
          }
        });
        let setAsso = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticoliassociazione",
            token: localStorage.getItem("user_token"),
            id_articolo: this.id_articolo,
            tipo: 0,
            articolo_disegni: [data],
          },
        };
        this.$store.dispatch("api", setAsso).then((res) => {
          if (res.status == 200) {
            this.fetchAssociationz();
            this.snackbar = true;
            this.overlay = false;
            this.snackbar_text = "Modifica effettuata con successo!";
            this.color = "green darken-3";
            this.mainIcon = "mdi-check-circle";
          }
        });
        this.getione_magazzino_list.map((it) => {
          if (it.id_tabella === data.id_gestione_magazzino) {
            data.des_tipo_magazzino = it.des;
            return it;
          }
        });
        this.id_disegno = "";
        this.des_anagrafica = "";
        this.codice = "";
        this.localita = "";
        this.ragione_sociale = "";
        this.id_gestione_magazzino = "";
        this.id_anagrafica = "";
        this.ragione_sociale = "";
        this.quantita_stock_massimo = null;
        this.quantita_stock_minimo = null;
        this.cod_alternativo = "";
        this.revisione_articolo = 0;
        this.indice_modifica = "";
        this.des_alternativo = "";
        this.dum = "";
        this.dialog = false;
      }
    },
    // In order to open Association form modal
    openModal() {
      this.numero_disegno = "";
      this.dialog = true;
      this.id_disegno = "";
      this.des_anagrafica = "";
      this.codice = "";
      this.id_gestione_magazzino = "";
      this.id_anagrafica = "";
      this.ragione_sociale = "";
      this.indirizzo = "";
      this.localita = "";
      this.quantita_stock_massimo = null;
      this.quantita_stock_minimo = null;
      this.cod_alternativo = "";
      this.revisione_articolo = null;
      this.indice_modifica = "";
      this.des_alternativo = "";
      this.dum = "";
    },
    // In order to search ragione sociale
    searchAssociazioni() {
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          token: localStorage.getItem("user_token"),
          des_anagrafica: this.des_anagrafica,
        },
      };
      this.overlay = true;
      this.$store.dispatch("api", searchReq).then((res) => {
        this.dialogRagioneSocialeFornitura = true;
        this.overlay = false;
        this.searchRes = res.data;
        if (this.searchRes.length === 0) {
          this.snackbar = true;
          this.snackbar_text = "Ragione Sociale non è stato trovato. Riprova !";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
          this.progressBar = false;
          this.dialogRagioneSocialeFornitura = false;
        }
        if (this.searchRes.length > 0) {
          this.progressBar = false;
        }
      });
    },
    // IN order to get list of association to populate the table
    fetchAssociationz() {
      this.loading = true;
      this.progressBar = true;
      let AssociazioniReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliassociazione",
          token: localStorage.getItem("user_token"),
          id_articolo: this.data.id_articolo,
        },
      };
      this.$store.dispatch("api", AssociazioniReq).then((res) => {
        this.items = res.data;
        if (this.items.length > 0) {
          this.progressBar1 = false;
          this.loading = false;
        }
        if (this.items.length === 0) {
          this.progressBar1 = false;
          this.loading = false;
        }
        return this.items;
      });
    },
  },
  watch: {
    quantita_stock_minimo: {
      immediate: true,
      async handler() {
        if (this.$refs.form != undefined) {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      },
    },
  },
  computed: {
    dum_format() {
      return this.dum ? moment(this.dum).format("DD/MM/YYYY") : "";
    },
  },
  mounted() {
    this.fetchAssociationz();
    let gestione_magazzino = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "ARTICOLO_GESTIONE_MAGAZZINO",
      },
    };
    this.$store.dispatch("api", gestione_magazzino).then((res) => {
      this.getione_magazzino_list = res.data;
    });
  },
};
</script>
<style lang="scss" scoped>
.artDialog {
  overflow-x: hidden;
}
</style>
