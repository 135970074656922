var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 ml-2"},[_c('v-card',[_c('v-toolbar',{staticClass:"primary white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"ml-2"},[_vm._v("Permission Forms Fields Production")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dense":""},on:{"click":_vm.salvaPermission}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Crea Permissions")])]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","light":""},on:{"click":_vm.clickChiudi}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)],1),_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"3","cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.user_list,"clearable":"","label":"Utente","placeholder":"Utente","item-text":function (item) { return item.nome + ' ' + item.cognome; },"required":"","item-value":"id_personale"},model:{value:(_vm.id_personale),callback:function ($$v) {_vm.id_personale=$$v},expression:"id_personale"}})],1),_c('v-col',{attrs:{"md":"3","sm":"3","cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.profilo_list,"clearable":"","label":"Profilo","placeholder":"Profilo","item-text":"des_profilo_utente","required":"","item-value":"id_profilo_utente"},model:{value:(_vm.id_profilo),callback:function ($$v) {_vm.id_profilo=$$v},expression:"id_profilo"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.ModulesHeaders,"expanded":_vm.expanded,"single-expand":true,"item-key":"id","show-expand":"","items":_vm.ModulesItems},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-file-tree")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":item.children,"expanded":_vm.expanded1,"item-key":_vm.id,"headers":_vm.ModulesSubHeaders,"single-expand":true,"items-per-page":50,"show-expand":"","hide-default-header":true,"hide-default-footer":true},on:{"update:expanded":function($event){_vm.expanded1=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersSingleForm,"items":item.forms,"item-key":item.key,"items-per-page":50,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.flag_readonly",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"false-value":0,"true-value":1},model:{value:(item.flag_readonly),callback:function ($$v) {_vm.$set(item, "flag_readonly", $$v)},expression:"item.flag_readonly"}})]}},{key:"item.flag_hide",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"false-value":0,"true-value":1},model:{value:(item.flag_hide),callback:function ($$v) {_vm.$set(item, "flag_hide", $$v)},expression:"item.flag_hide"}})]}},{key:"item.flag_obbligatorio",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"false-value":0,"true-value":1},model:{value:(item.flag_obbligatorio),callback:function ($$v) {_vm.$set(item, "flag_obbligatorio", $$v)},expression:"item.flag_obbligatorio"}})]}}],null,true)})],1)]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }