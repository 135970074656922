var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"min-height":"500px"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Comparazione Versioni di Configurazione "+_vm._s(_vm.multiple ? _vm.codice_search : ""))]),(_vm.diff_ordini.length > 1)?_c('v-spacer'):_vm._e(),(_vm.diff_ordini.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.changeAllExpanded(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-double-down")])],1)]}}],null,false,2727937320)},[_c('span',[_vm._v("Espandi Tutto")])]):_vm._e(),(_vm.diff_ordini.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.changeAllExpanded(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-double-up")])],1)]}}],null,false,3100026794)},[_c('span',[_vm._v("Comprimi Tutto")])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiCompareDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"overflow-y":"auto"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center"},[(_vm.multiple == false)?_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"8"}},[_c('v-text-field',{staticStyle:{"min-width":"400px","display":"inline-block"},attrs:{"autocomplete":"off","label":"Codice Configurazione"},model:{value:(_vm.codice_search),callback:function ($$v) {_vm.codice_search=$$v},expression:"codice_search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",staticStyle:{"display":"inline-block"},attrs:{"disabled":_vm.codice_search == '',"small":""},on:{"click":function($event){return _vm.compareVersion()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}],null,false,3851261914)},[_c('span',[_vm._v("Cerca")])])],1):_vm._e()],1),(_vm.diff_ordini.length > 1)?_c('v-row',_vm._l((_vm.categorie_distinct),function(categoria,i){return _c('v-col',{key:categoria,attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.changeExpanded(i)}}},[_vm._v(" "+_vm._s(categoria.replaceAll("_", " "))+" "),_c('v-icon',[_vm._v(_vm._s(_vm.categorie_expanded[i] ? "mdi-arrow-down" : "mdi-arrow-up"))])],1),(_vm.categorie_expanded[i])?_c('v-container',{attrs:{"fluid":""}},_vm._l((_vm.ordini_categorie_totali.filter(
                function (el) { return el.nome_categoria == categoria; }
              )),function(ordine_categoria,i2){return _c('v-row',{key:i2,staticClass:"py-2"},[(
                  ordine_categoria.gemini != undefined &&
                  ordine_categoria.gemini.length > 0
                )?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-icon',{style:('color:' + ordine_categoria.color)},[_vm._v("mdi-pencil")]),(ordine_categoria.codice1 != null)?_c('span',[_c('strong',[_vm._v("codice:")]),_vm._v(" "+_vm._s(ordine_categoria.codice1)+" "),_c('br')]):_vm._e(),(ordine_categoria.elemento != null)?_c('span',[_c('strong',[_vm._v("elemento:")]),_vm._v(" "+_vm._s(ordine_categoria.elemento)+" "),_c('br')]):_vm._e(),(ordine_categoria.info != null)?_c('span',[_c('strong',[_vm._v("info:")]),_vm._v(" "+_vm._s(ordine_categoria.info)+" "),_c('br')]):_vm._e(),(ordine_categoria.quantita != null)?_c('span',[_c('strong',[_vm._v("quantita:")]),_vm._v(" "+_vm._s(ordine_categoria.quantita)+" "),_c('br')]):_vm._e(),(ordine_categoria.des != null)?_c('span',[_c('strong',[_vm._v("des:")]),_vm._v(" "+_vm._s(ordine_categoria.des)+" "),_c('br')]):_vm._e(),(ordine_categoria.data != null)?_c('span',[_c('strong',[_vm._v("data:")]),_vm._v(" "+_vm._s(ordine_categoria.data)+" "),_c('br')]):_vm._e(),(ordine_categoria.firma != null)?_c('span',[_c('strong',[_vm._v("firma:")]),_vm._v(" "+_vm._s(ordine_categoria.firma)+" "),_c('br')]):_vm._e(),(ordine_categoria.elemento_padre != null)?_c('span',[_c('strong',[_vm._v("elemento padre:")]),_vm._v(" "+_vm._s(ordine_categoria.elemento_padre)+" "),_c('br')]):_vm._e()],1):_vm._e(),(
                  ordine_categoria.gemini != undefined &&
                  ordine_categoria.gemini.length > 0
                )?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-icon',{style:('color:' + ordine_categoria.color)},[_vm._v("mdi-pencil")]),(ordine_categoria.gemini[0].codice1 != null)?_c('span',[_c('strong',[_vm._v("codice:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].codice1)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].elemento != null)?_c('span',[_c('strong',[_vm._v("elemento:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].elemento)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].info != null)?_c('span',[_c('strong',[_vm._v("info:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].info)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].quantita != null)?_c('span',[_c('strong',[_vm._v("quantita:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].quantita)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].des != null)?_c('span',[_c('strong',[_vm._v("des:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].des)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].data != null)?_c('span',[_c('strong',[_vm._v("data:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].data)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].firma != null)?_c('span',[_c('strong',[_vm._v("firma:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].firma)+" "),_c('br')]):_vm._e(),(ordine_categoria.gemini[0].elemento_padre != null)?_c('span',[_c('strong',[_vm._v("elemento padre:")]),_vm._v(" "+_vm._s(ordine_categoria.gemini[0].elemento_padre)+" "),_c('br')]):_vm._e()],1):_vm._e(),(
                  ordine_categoria.gemini == undefined ||
                  ordine_categoria.gemini.length == 0
                )?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-icon',{style:('color:' + ordine_categoria.color)},[_vm._v(_vm._s(ordine_categoria.color == "red" ? "mdi-minus" : "mdi-plus"))]),(ordine_categoria.codice1 != null)?_c('span',[_c('strong',[_vm._v("codice:")]),_vm._v(" "+_vm._s(ordine_categoria.codice1)+" "),_c('br')]):_vm._e(),(ordine_categoria.elemento != null)?_c('span',[_c('strong',[_vm._v("elemento:")]),_vm._v(" "+_vm._s(ordine_categoria.elemento)+" "),_c('br')]):_vm._e(),(ordine_categoria.info != null)?_c('span',[_c('strong',[_vm._v("info:")]),_vm._v(" "+_vm._s(ordine_categoria.info)+" "),_c('br')]):_vm._e(),(ordine_categoria.quantita != null)?_c('span',[_c('strong',[_vm._v("quantita:")]),_vm._v(" "+_vm._s(ordine_categoria.quantita)+" "),_c('br')]):_vm._e(),(ordine_categoria.des != null)?_c('span',[_c('strong',[_vm._v("des:")]),_vm._v(" "+_vm._s(ordine_categoria.des)+" "),_c('br')]):_vm._e(),(ordine_categoria.data != null)?_c('span',[_c('strong',[_vm._v("data:")]),_vm._v(" "+_vm._s(ordine_categoria.data)+" "),_c('br')]):_vm._e(),(ordine_categoria.firma != null)?_c('span',[_c('strong',[_vm._v("firma:")]),_vm._v(" "+_vm._s(ordine_categoria.firma)+" "),_c('br')]):_vm._e(),(ordine_categoria.elemento_padre != null)?_c('span',[_c('strong',[_vm._v("elemento padre:")]),_vm._v(" "+_vm._s(ordine_categoria.elemento_padre)+" "),_c('br')]):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)}),1):_vm._e(),(_vm.revisioni.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.head,"items":_vm.revisioni},scopedSlots:_vm._u([{key:"item.search",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openComparisonByVersions(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#1a80b6"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Compara versioni")])])]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.removeItemDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}},{key:"item.pdf",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.openSingleFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#1a80b6"}},[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizza Dettaglio")])])]}}],null,true)})],1)],1):_vm._e()],1),_c('SnackbarQV',{ref:"snackbarqv"}),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.compare_files),callback:function ($$v) {_vm.compare_files=$$v},expression:"compare_files"}},[_c('ComparazioneVersioni',{ref:"comparazioneVersioni",on:{"chiudi_dialog":function($event){_vm.compare_files = false}}})],1),_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.singleFile),callback:function ($$v) {_vm.singleFile=$$v},expression:"singleFile"}},[_c('VisualizzaFilePdf',{ref:"visualizzaFilePdf",on:{"chiudi_dialog":_vm.chiudiVisualizzaPdf}})],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog_elimina_record),callback:function ($$v) {_vm.dialog_elimina_record=$$v},expression:"dialog_elimina_record"}},[_c('v-toolbar',{attrs:{"dark":"","color":"error"}},[_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Attenzione!")]),_c('v-spacer')],1),_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Sei sicuro di voler eliminare l'elemento selezionato?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"error"},on:{"click":function($event){_vm.dialog_elimina_record = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.removeItem()}}},[_vm._v("Conferma")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }