<template>
  <div :id="'listener_InputSeparatore' + id">
    <v-text-field
      :disabled="disabled"
      @blur="onBlurNumber"
      @input="dispatch"
      :label="label"
      type="number"
      v-if="visible === true"
      v-model="amount"
    >
    </v-text-field>

    <v-text-field
      :disabled="disabled"
      @focus="onFocusText"
      :label="label"
      type="text"
      v-if="visible === false"
      v-model="amount"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "InputSeparatore",
  components: {},
  props: {
    decimali_fissi: { type: Boolean, default: false },
    destinazione: { type: String, default: "" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    id: { type: String, default: "a" },
  },
  data() {
    return { amount: 0, temp: null, visible: false };
  },
  methods: {
    dispatch() {
      // Compongo un messaggio.
      let messaggio = {
        detail: {
          numero: parseFloat(this.amount.toString().replace(",", ".")),
        },
        src: null,
      };
      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("nuovo_numero", messaggio);
      console.log("messaggio",messaggio);
      // Consegno alla mia destinazione l'evento
      document.getElementById(this.destinazione).dispatchEvent(evento);
    },
    onBlurNumber() {
      if (this.decimali_fissi === false) {
        this.visible = false;
        /* temp is used to holding the original
         * value before separator is applied. */
        this.temp = this.amount;
        /* this.thousandSeprator is a function used to apply
         * separator into user input */
        this.amount = this.thousandSeprator(this.amount);
      } else {
        // Chiudo il dialog
        this.visible = false;

        // Tronco al secondo decimale.
        this.amount = Math.trunc(this.amount * 100) / 100;
        /* temp is used to holding the original
         * value before separator is applied. */
        this.temp = this.amount;
        /* this.thousandSeprator is a function used to apply
         * separator into user input */
        this.amount = this.thousandSeprator(this.amount);
      }
    },
    onFocusText() {
      this.visible = true;
      /**
       * temp is used to holding the original
       * value before separator is applied
       */
      this.amount = this.temp;
    },

    /*Replace numbers with comma separated value*/
    thousandSeprator(amount) {
      if (this.decimali_fissi === false) {
        if (
          amount !== "" ||
          amount !== undefined ||
          amount !== 0 ||
          amount !== "0" ||
          amount !== null
        ) {
          amount = amount.toString().replace(".", ",");
          return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return amount;
        }
      } else {
        if (
          amount !== "" ||
          amount !== undefined ||
          amount !== 0 ||
          amount !== "0" ||
          amount !== null
        ) {
          // Sostituisco la virgola al punto eventualmente presente.
          amount = amount.toString().replace(".", ",");
          // Se non c'è una virgola la aggiungo e le metto in coda due 0.
          if (amount.indexOf(",") === -1) {
            amount = amount + ",00";
          }

          if (amount.indexOf(",") < amount.length - 3) {
            /* Se c'è una virgola prima della terzultima posizione accorcio la
             * stringa in modo da renderla il terzultimo carattere della stringa. */
            let i = amount.indexOf(",");
            amount = amount.slice(0, i + 4);
          } else {
            if(amount.split(",")[1].length==1)
            {
              amount += "0";
            }
            console.log(amount.indexOf(","));
          }
          // Aggiungo un punto ogni 3 posti.
          return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return amount;
        }
      }
    },
  },
  mounted() {
    let that = this;
    console.log("listener_InputSeparatore" + this.id);
    document
      .getElementById("listener_InputSeparatore" + this.id)
      .addEventListener("numero_iniziale", function (elem) {
        that.amount = that.thousandSeprator(elem.detail.numero);
        that.temp = parseInt(elem.detail.numero);
        console.log("entrato listener iniziale " + that.amount);
      });
  },
};
</script>
<style scoped>
</style>