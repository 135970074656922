<template>
  <div style="width: 100% !important">
    <v-tabs>
      <v-tab key="1">
        PACKAGING
        <v-icon class="outlined" fab> mdi-exclamation </v-icon>
      </v-tab>
      <v-tab key="2"> PRE-SHIPPING </v-tab>
      <v-tab key="3"> SHIPPING </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <PackagingTab />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <PreShippingTab />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Shipping />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>


<script>
import PackagingTab from "./PackgingTab";
import PreShippingTab from "./PreShippingTab";
import Shipping from "./ShippingTab";
export default {
  components: {
    PackagingTab,
    PreShippingTab,
    Shipping,
  },
};
</script>