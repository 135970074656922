import { render, staticRenderFns } from "./RegistraPezziMulti.vue?vue&type=template&id=6d8e25d7&scoped=true&"
import script from "./RegistraPezziMulti.vue?vue&type=script&lang=js&"
export * from "./RegistraPezziMulti.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8e25d7",
  null
  
)

export default component.exports