<template>
  <v-container id="listener_main" class="mx-0 px-0" fluid>
    <v-row v-if="tabella_richieste_visible === true">
      <v-col cols="12">
        <v-data-table
          :headers="headers_richieste"
          :items="items_richieste_visualizzate"
          class="elevation-1 mx-5"
        >
          <!-- Titolo tabella -->
          <template v-slot:top>
            <v-toolbar class="sfondoStandard" dense>
              <v-toolbar-title class="white--text">
                Richieste di Intervento
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickNuovaRichiesta"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuova Richiesta</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-5"
                    @click="filtro_visible = true"
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="primary">mdi-filter</v-icon></v-btn
                  >
                </template>
                <span>Visualizza Filtri</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                    ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
                  >
                </template>
                <span>Resetta Filtro</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToManutenzione"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-keyboard-backspace </v-icon>
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row align="center" justify="center" v-if="filtro_visible">
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_richieste_visualizzate"
                    item-text="cod_richiesta"
                    item-value="cod_richiesta"
                    label="Cod. Richiesta"
                    style="display: inline-block; max-width: 350px"
                    v-model="cod_richiesta_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          cod_richiesta_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          cod_richiesta_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_richieste_visualizzate"
                    item-text="des_richiedente"
                    item-value="des_richiedente"
                    label="Richiedente"
                    style="display: inline-block; max-width: 350px"
                    v-model="des_richiedente_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          des_richiedente_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          des_richiedente_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_richieste_visualizzate"
                    item-text="des_urgenza"
                    item-value="des_urgenza"
                    label="Urgenza"
                    style="display: inline-block; max-width: 350px"
                    v-model="des_urgenza_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          des_urgenza_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          des_urgenza_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="2">
                  <v-menu
                    v-model="calendario_data_richiesta"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="289"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="#ffffff"
                        @change="filtra"
                        dense
                        hide-details
                        label="Data Richiesta"
                        style="display: inline-block; max-width: 350px"
                        v-model="data_richiesta_search"
                        readonly
                        v-on="on"
                      >
                        <template v-slot:append>
                          <v-icon
                            class="ml-2"
                            @click="data_richiesta_search = ''"
                            color="primary"
                            >{{
                              data_richiesta_search == ""
                                ? "mdi-filter"
                                : "mdi-filter-off"
                            }}</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      @change="filtra"
                      v-model="data_richiesta_search"
                      @input="calendario_data_richiesta = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_richieste_visualizzate"
                    item-text="des_sede"
                    item-value="des_sede"
                    label="Sede"
                    style="display: inline-block; max-width: 350px"
                    v-model="des_sede_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          des_sede_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          des_sede_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_richieste_visualizzate"
                    item-text="des_struttura"
                    item-value="des_struttura"
                    label="Struttura"
                    style="display: inline-block; max-width: 350px"
                    v-model="des_struttura_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          des_struttura_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          des_struttura_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!-- Bottone dialog della Richiesta -->
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaDialog(item,false)"
                  small
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.cod_stato!='FINISH'"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Modifica Richiesta</span>
            </v-tooltip>
             <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaDialog(item,true)"
                  small
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.cod_stato=='FINISH'"
                  ><v-icon color="primary">mdi-magnify</v-icon></v-btn
                >
              </template>
              <span>Dettaglio Richiesta</span>
            </v-tooltip>
          </template>
          <!-- Iconda del campo "email" -->
          <template v-slot:[`item.email`]="{ item }">
            <v-icon :color="item.email === 1 ? 'success' : 'error'">{{
              item.email === 1 ? "mdi-check" : "mdi-close"
            }}</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="nuova_richiesta_visible === true">
      <v-col class="px-10" cols="12">
        <Richieste
          @click_indietro="chiudiNuovaRichiesta"
          :richiesta_sel="richiesta_sel"
          :visible="nuova_richiesta_visible"
          ref="ComponenteRichieste"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_modifica_visible" width="70%">
      <Richieste
        @click_indietro="chiudiModificaRichiesta"
        :richiesta_sel="richiesta_sel"
        :visible="dialog_modifica_visible"
        :readOnly="richiesta_readOnly"
        ref="ComponenteRichieste"
      />
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import moment from "moment/moment";
import Request from "@/classes/Request.js";
import Richieste from "@/components/MANUTENZIONE/Richieste.vue";

export default {
  name: "ManutenzioneRichieste",
  components: { Richieste },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      // Variabili di filtro
      cod_richiesta_search: "",
      data_richiesta_search: "",
      des_richiedente_search: "",
      des_urgenza_search: "",
      des_sede_search: "",
      des_struttura_search: "",
      richiesta_readOnly:false,

      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Altre varabili d'appoggio
      classe: "",
      richiesta_sel: new Request(),

      // Modal
      calendario_data_richiesta: false,
      dialog_modifica_visible: false,
      filtro_visible: false,
      nuova_richiesta_visible: false,
      tabella_richieste_visible: true,

      // Array
      headers_richieste: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Cod. Richiesta",
          sortable: true,
          value: "cod_richiesta",
        },
        {
          text: "Richiedente",
          sortable: true,
          value: "des_richiedente",
        },
        {
          text: "Data Richiesta (Da)",
          sortable: true,
          value: "data_richiesta",
        },
        {
          text: "Urgenza",
          sortable: false,
          value: "des_urgenza",
        },
        {
          text: "Sede",
          sortable: true,
          value: "des_sede",
        },
        {
          text: "Struttura",
          sortable: true,
          value: "des_struttura",
        },
        {
          text: "Area",
          sortable: false,
          value: "des_area",
        },
        {
          text: "Zona",
          sortable: false,
          value: "des_zona",
        },
        {
          text: "Stanza",
          sortable: false,
          value: "des_stanza",
        },
        {
          text: "Macchinario",
          sortable: false,
          value: "des_macchinario",
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        { text: "Stato", sortable: false, value: "des_stato" },
      ],
      items_richieste: [],
      items_richieste_visualizzate: [],
    };
  },
  computed: {},
  watch: {
    dialog_modifica_visible: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudiModificaRichiesta(check) {
      // Ricarico la tabella delle richieste per aggiornarla
      this.getListaRichieste();

      // Genero un oggetto richiesta con i campi resettati
      let nuova_richiesta = new Request();

      // Uso l'oggetto resettato per resettare la richiesta_sel.
      this.richiesta_sel = Object.assign({}, nuova_richiesta);

      // Chiudo il dialog di modifica della richiesta.
      this.dialog_modifica_visible = false;
      console.log(check);
      if (check == false) {
        this.snackbarOpen();
      }
      this.$refs.ComponenteRichieste.check = true;
    },
    chiudiNuovaRichiesta() {
      // Aggiusto quello che è visibile
      this.nuova_richiesta_visible = false;
      this.tabella_richieste_visible = true;

      // Resetto la richiesta_sel
      let nuova_richiesta = new Request();
      this.richiesta_sel = Object.assign({}, nuova_richiesta);

      // Ricarico la tabella delle richieste per aggiornarla

      this.getListaRichieste();
    },
    snackbarOpen() {
      console.log("entrato");
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    clickModificaDialog(item,readOnly) {
      this.richiesta_readOnly = readOnly;
      console.log("ITEM:", item);
      this.richiesta_sel = Object.assign({}, item);
      this.dialog_modifica_visible = true;
    },
    clickNuovaRichiesta() {
      this.tabella_richieste_visible = false;
      this.nuova_richiesta_visible = true;
    },
    filtra() {
      console.log("entrato");
      let items_richieste_tmp = [];
      this.items_richieste.forEach((elem) => {
        items_richieste_tmp.push(elem);
      });

      // Filtro il cod_richiesta
      if (this.cod_richiesta_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.cod_richiesta == this.cod_richiesta_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro il richiedente
      if (this.des_richiedente_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_richiedente == this.des_richiedente_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }
      if (this.des_urgenza_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_urgenza == this.des_urgenza_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro la data richiesta
      if (this.data_richiesta_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Mi segno la data ricercata formattata in moment
        let data_ricercata = new moment(
          this.data_richiesta_search.substring(0, 4) +
            "-" +
            this.data_richiesta_search.substring(5, 7) +
            "-" +
            this.data_richiesta_search.substring(8, 10)
        );

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          /* Per ogni elemento, mi faccio una data temporanea che mi serve
           * per confrontare la data ricercata e la data della richiesta.*/
          elem.data_js = new moment(
            elem.data_richiesta.substring(6, 10) +
              "-" +
              elem.data_richiesta.substring(3, 5) +
              "-" +
              elem.data_richiesta.substring(0, 2)
          );

          // Cerco le richieste effettuate a partire dalla data del filtro.
          if (elem.data_js.isSameOrAfter(data_ricercata)) {
            /* Dopo il confronto se c'è match elimino la data moment
             * per convenienza di BE e aggiungo l'oggetto all'array */
            delete elem.data_js;
            res.push(elem);
          } else {
            // Altrimenti non faccio niente, ma elimino comunque la data moment.
            delete elem.data_js;
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro la sede della richiesta
      if (this.des_sede_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_sede == this.des_sede_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro la struttura della richiesta
      if (this.des_struttura_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_struttura == this.des_struttura_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      this.items_richieste_tmp = [];
      this.items_richieste_visualizzate = items_richieste_tmp;
      console.log(JSON.stringify(this.items_richieste_visualizzate));
    },
    getListaRichieste() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistarichieste",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          id_profilo_utente: localStorage.id_profilo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.items_richieste = res.data.result;
          this.items_richieste.forEach((elem) => {
            elem.data_richiesta = elem.data_richiesta.substring(0, 10);
          });
          this.items_richieste_visualizzate = res.data.result;
        }
      });
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
    resetFiltro() {
      // Resetto i filtri
      this.cod_richiesta_search = "";
      this.des_richiedente_search = "";
      this.data_richiesta_search = "";
      this.des_sede_search = "";
      this.des_struttura_search = "";

      // Nascondo i filtri
      this.filtro_visible = false;

      // Ricarico la tabella
      this.items_richieste_visualizzate = Array.from(this.items_richieste);
    },
  },
  created() {},
  mounted() {
    this.getListaRichieste();
  },
};
</script>
<style scoped>
.bg-red {
  background: linear-gradient(
    90deg,
    rgba(97, 1, 1, 1) 0%,
    rgba(254, 0, 0, 1) 100%
  );
  color: white !important;
}
.sfondoStandard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 0%,
    rgba(63, 81, 181, 1) 100%
  );
  color: white !important;
}
</style>