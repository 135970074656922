var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 24 : 2}},[_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Prelievo")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.gotoDashboard}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,true)},[_c('span',[_vm._v("Vai alla Dashboard")])])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-1"},[_c('v-col',{attrs:{"md":"1"}},[_c('v-text-field',{staticClass:"input-class",attrs:{"type":"text","label":"Codice","placeholder":"Codice","required":""},model:{value:(_vm.codice),callback:function ($$v) {_vm.codice=$$v},expression:"codice"}})],1),_c('v-col',{staticClass:"ml-4",attrs:{"md":"2"}},[_c('p',{staticClass:"ml=n12"},[_vm._v(" K49.3199.2[PRODUZIONE GERNERICA] LOTTO[20/F565] 8000 PEZZI TOTALI ")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{attrs:{"hide-default-footer":true,"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.edit",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({},'v-col',attrs,false),on),[_c('PrelievoModal')],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio")])])]},proxy:true},{key:"item.dettagli",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"md":"4"}},'v-col',attrs,false),on),[_c('v-icon',{staticStyle:{"float":"left"},attrs:{"md":"4","color":"#c00000"},on:{"click":function($event){return _vm.handleClick(_vm.item)}}},[_vm._v("mdi-window-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Rimuovere Macchina ")])])]}}],null,true)})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Componente Extra"},model:{value:(_vm.componente_extra),callback:function ($$v) {_vm.componente_extra=$$v},expression:"componente_extra"}})],1)],1),_c('v-row',{staticClass:"mt-n8 ml-1"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","md":"1"}},[_c('v-text-field',{staticClass:"input-class",attrs:{"clearable":"","type":"text","placeholder":"Cod. Lotto","required":""},model:{value:(_vm.cod_lotto),callback:function ($$v) {_vm.cod_lotto=$$v},expression:"cod_lotto"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","md":"1"}},[_c('v-text-field',{staticClass:"input-class",attrs:{"clearable":"","type":"text","placeholder":"Nº Pezzi","required":""},model:{value:(_vm.numero_pezzi),callback:function ($$v) {_vm.numero_pezzi=$$v},expression:"numero_pezzi"}})],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"#1a80b6","dark":""},on:{"click":function($event){return _vm.onButtonClick(_vm.props.item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-plus")])]}}],null,true)},[_c('span',[_vm._v("Aggungi Componente")])]),_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{attrs:{"hide-default-footer":true,"items":_vm.items1,"headers":_vm.headers1},scopedSlots:_vm._u([{key:"item.reso",fn:function(props){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"tile":"","color":"primary"},on:{"click":function($event){return _vm.onButtonClick(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-restore")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettagli")])])]}}],null,true)})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }