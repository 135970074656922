<template>
  <v-card>
    <v-card-title>
      
    <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="date"
            label="Data inizio"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
          ></v-text-field>
          </template>
          <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
        </v-menu>



        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="date2"
            label="Data fine"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
          ></v-text-field>
          </template>
          <v-date-picker
          v-model="date2"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(date2)"
          >
            OK
          </v-btn>
        </v-date-picker>
        </v-menu>

<v-btn
  elevation="2"
  icon
  @click="getDocumenti"
><v-icon>
        mdi-magnify
      </v-icon></v-btn>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      


      
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="documenti"
    :search="search"
    :items-per-page="10"
    sort-by="id_movimento"
    class="elevation-1"
  >
<template v-slot:item.data="{ item }">
    <span>{{ new Date(item.data).toLocaleString() }}</span>
  </template>

  
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{VOCE_MENU}}</v-toolbar-title>
        
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        
        <v-dialog v-model= "dialog"
        >
            <DocumentoDettaglio
                          :ID_DOCUMENTO="id_doc"
                          :ID_MODULO="ID_MODULO"
                      /> 
        </v-dialog>

       

      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
                      
<v-icon 
        small
      >
        mdi-delete
      </v-icon>

    </template>
    
  </v-data-table>
  </v-card>

</template>
  


<script>
import axios from "axios";
import DocumentoDettaglio from "./DocumentoDettaglio.vue";
  export default {
    props: {
            VOCE_MENU:String,
            ID_MODULO:Number,
        },
      components: {
            DocumentoDettaglio,
        },  
    data: () => ({
        
      dialog: false,
      search: '',
      
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      id_doc : '',



      headers: [
        {
          text: 'id_documento',
            align: 'start',
            sortable: false,
            value: 'id_documento',
        },
           { text: 'data_documento', value: 'data_documento' },
           { text: 'des_pagamento', value: 'des_pagamento' },
           { text: 'totale_imponibile', value: 'totale_imponibile' },
           { text: 'totale_iva', value: 'totale_iva' },
           { text: 'totale_importo', value: 'totale_importo' },
           { text: 'des_stato_doc', value: 'des_stato_doc' },
           { text: 'Actions', value: 'actions', sortable: false },
      ],
      documenti : [],
      editedIndex: -1,
    }),
      

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      
    },

    

    mounted () {
    var initDate = new Date();
    initDate.setDate(initDate.getDate() - 30);
    this.date = (new Date(initDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    this.getDocumenti();
    },

    methods: {

      editItem (item) {
        this.id_doc = item.id_documento;
        this.dialog = true
   
      },

    getDocumenti: function() {

      console.log(this.date);
      console.log(this.date2);
      const b = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var data = JSON.stringify(
      (data = {
          action: "getlistadocumenti",
          token: localStorage.user_token,
          id_modulo_menu: this.ID_MODULO,
          data1:this.date,
          data2:this.date2,
        })
      );
      axios
         .post("https://localhost:5001/api/Produzione", data, b)
        .then(response => {
          this.documenti = response.data.ListaDocumenti;
          console.log(this.documenti);
          this.firstCall = response.data;
        })
        .catch(e => {
          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }
         
        });
    },

 

    

        
    },
  }
</script>