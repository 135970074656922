<template>
  <div class="ma-3">
    <v-data-table class="elevation-3" :items="flowchart_caricabili" :headers="headers_flowchart">
      <template v-slot:top>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Progettazione Questionari
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Bottone per aggiungere un nuovo questionario -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="switchNuovoFlowchartDialogVisible"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuovo questionario</span>
          </v-tooltip>
          <!-- Bottone di filtro -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="filtraDialogVisible = !filtraDialogVisible"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-filter-menu</v-icon>
              </v-btn>
            </template>
            <span>Apri Menu Filtri</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div v-if="filtraDialogVisible">
          <v-row align="center" justify="center">
            <v-col cols="2">
              <v-text-field
                id="inputFiltraDa"
                label="Creati dalla Data"
                type="date"
                v-model="inputFiltraDa"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                id="inputFiltraA"
                label="Creati Entro la Data"
                type="date"
                v-model="inputFiltraA"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                id="inputFiltraTitolo"
                label="Titolo Questionario"
                type="text"
                v-model="inputFiltraTitolo"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                :items="[
                  { name: 'Creazione', id: -1 },
                  { name: 'OK', id: 0 },
                  { name: 'Non editabile', id: 1 },
                  { name: 'Nascosto', id: 2 },
                  { name: 'Annullato', id: 4 },
                ]"
                item-text="name"
                item-value="id"
                id="inputFiltraStato"
                label="Filtra per Stato"
                style="display: inline-block; width: 200px"
                v-model="inputFiltraStato"
              ></v-select>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="filtra()" small v-bind="attrs" v-on="on">
                    <v-icon color="primary"> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <span>Filtra</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="ripristina()" small v-bind="attrs" v-on="on">
                    <v-icon color="primary"> mdi-filter-off </v-icon>
                  </v-btn>
                </template>
                <span>Ripristina</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </template>

      <!-- Colonna del bottone di pubblicazione-->
      <template v-slot:[`item.pubblica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="
                checkDomandeQuestionario(item);
                titoloQuestionarioSelezionato = item.titolo_questionario;
                idQuestionarioSelezionato = item.id_questionario;
              "
              color="light-blue"
              dark
              fab
              outlined
              small
              style="background-color: #ffffff"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-double-up</v-icon>
            </v-btn>
          </template>
          <span>Pubblica</span>
        </v-tooltip>
      </template>

      <!-- Colonna del bottone di modifica -->
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="gestisciClickModificaButton(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifica struttura</span>
        </v-tooltip>
      </template>

      <!-- Colonna del bottone di cancellazione -->
      <template v-slot:[`item.cancella`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="avviaCancellazioneFlowchart(item)"
              small
              style="background-color: #ffffff"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error">mdi-file-remove-outline</v-icon>
            </v-btn>
          </template>
          <span>Cancella questionario</span>
        </v-tooltip>
      </template>

      <!-- Colonna della descrizione stato -->
      <template v-slot:[`item.stato`]="{ item }">
        {{
          item.id_stato_questionario == -1
            ? "Creato"
            : item.id_stato_questionario == 0
            ? "OK"
            : item.id_stato_questionario == 1
            ? "Non editabile"
            : item.id_stato_questionario == 2
            ? "Nascosto"
            : item.id_stato_questionario == 1
            ? "Annullato"
            : item.id_stato_questionario
        }}
      </template>
    </v-data-table>

    <!-- Dialog di conferma cancellazione -->
    <v-dialog v-model="confermaCancellaDialogVisible" width="500">
      <v-card class="text-center">
        <v-card-title style="background-color: #546e7a; color: #ffffff"
          >Conferma la cancellazione del questionario</v-card-title
        >
        <v-card-text class="pt-5"
          >Vuoi veramente cancellare questo questionario?</v-card-text
        >
        <v-divider></v-divider>
        <div style="padding-right: 10px; text-align: right">
          <v-btn
            class="mr-2 my-2"
            color="error"
            @click="confermaCancellaAnnulla"
            fab
            small
            outlined
          >
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
          <v-btn
            class="my-2"
            color="success"
            @click="confermaCancellaOk"
            fab
            small
            outlined
          >
            <v-icon>mdi-check-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog di pubblicazione del Questionario -->
    <v-dialog v-model="pubblicaDialogVisible" width="75%">
      <v-card class="text-center">
        <v-card-title
          style="
            background-color: #546e7a;
            color: #ffffff;
            position: sticky;
            top: 0px;
            z-index: 1;
          "
          >Pubblica il questionario</v-card-title
        >
        <v-card-text class="pt-5">
          <h1>{{ titoloQuestionarioSelezionato }}</h1>
        </v-card-text>
        <v-card-text
          >Aggiungi qui i dati di un nuovo utente che potrà accedere al
          questionario</v-card-text
        >
        <div style="text-align: right">
          <vue-xlsx-table class="mr-10" @on-select-file="handleSelectedFile"
            >Carica da file xls</vue-xlsx-table
          >
        </div>
        <v-form
          class="px-10"
          lazy-validation
          ref="pubblicaForm"
          style="margin: 10px"
        >
          <v-row class="ma-0">
            <v-col cols="4">
              <v-text-field
                :counter="30"
                id="inputNomeCandidato"
                label="Nome"
                required
                :rules="validaNomeCandidato"
                v-model="nomeCandidatoDaAggiungere"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :counter="30"
                label="Cognome"
                id="inputCognomeCandidato"
                required
                :rules="validaNomeCandidato"
                v-model="cognomeCandidatoDaAggiungere"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :counter="30"
                id="inputEmailCandidato"
                label="E-mail"
                required
                :rules="validaEmail"
                v-model="emailCandidatoDaAggiungere"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 my-2"
            color="light-blue"
            @click="aggiungiCandidato()"
            fab
            small
            outlined
          >
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-form>
        <div v-if="listaCandidati.length == 0">
          <v-card-text
            >Questo test non è ancora stato pubblicato per nessun
            utente.</v-card-text
          >
        </div>
        <div v-else>
          <v-card-text
            >Ecco di seguito l'elenco degli utenti che possono accedere al
            test:</v-card-text
          >
          <table
            style="margin-left: auto; margin-right: auto; text-align: left"
          >
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Nome</th>
                <th style="padding-left: 10px">Cognome</th>
                <th style="padding-left: 10px">E-mail</th>
                <th style="padding-left: 10px">Stato</th>
                <th style="padding-left: 10px">Iniziato</th>
                <th style="padding-left: 10px">Terminato</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="item.num_utente" v-for="item in listaCandidatiValidi">
                <td>
                  <v-btn
                    @click="delCandidato(item)"
                    color="error"
                    dark
                    fab
                    outlined
                    x-small
                  >
                    <v-icon>mdi-eraser</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-btn
                    @click="modificaCandidato(item)"
                    color="light-blue"
                    dark
                    fab
                    outlined
                    x-small
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </td>
                <td style="padding-left: 10px">{{ item.nome }}</td>
                <td style="padding-left: 10px">{{ item.cognome }}</td>
                <td style="padding-left: 10px">{{ item.email }}</td>
                <td style="padding-left: 10px">
                  {{
                    item.id_stato == null
                      ? "Contattabile"
                      : item.id_stato == 1
                      ? "E-mail inviata"
                      : item.id_stato
                  }}
                </td>
                <td style="padding-left: 10px">
                  {{ item.dataInizio == null ? "" : item.dataInizio }}
                </td>
                <td style="padding-left: 10px">
                  {{ item.dataFine == null ? "" : item.dataFine }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <v-divider></v-divider>
        <div style="padding-right: 10px; text-align: right">
          <v-btn
            class="mr-2 my-2"
            color="error"
            @click="switchPubblicaDialogVisible()"
            fab
            small
            outlined
          >
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
          <v-btn
            class="my-2"
            color="success"
            @click="pubblica()"
            fab
            small
            outlined
          >
            <v-icon>mdi-check-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog di creazione del nuovo questionario -->
    <v-dialog persistent v-model="nuovoFlowchartDialogVisibile" width="75%">
      <v-card class="text-center">
        <v-toolbar dense color="indigo">
          <v-card-title class="white--text"> Nuovo Questionario </v-card-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="my-2"
                @click="salvaNuovoFlowchartDialog"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva Questionario</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 my-2"
                @click="chiudiNuovoFlowchartDialog"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <form class="px-10">
          <v-row class="ma-0">
            <v-col cols="4">
              <v-text-field
                :counter="50"
                label="Titolo del questionario"
                required
                v-model="titolo_questionarioNuovo"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Data di Scadenza"
                required
                type="date"
                v-model="data_questionarioNuovo"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :items="tipi_questionario"
                v-model="id_tipo_questionario_sel"
                item-text="des"
                item-value="id_tabella"
                label="Tipo di Questionario"
                placeholder="Seleziona"
                required
              >
                <template #label>
                  <span class="">Tipo di Questionario</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="QC_TIPO_QUESTIONARIO"
                      nome="Tipo di Questionario"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="'validita = 1'"
                      :dati="[{ des: '' }]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="6" style="padding-left: 30px; padding-right: 30px">
              <v-text-field
                :counter="50"
                label="Testo finale"
                required
                v-model="testo_finaleNuovo"
              ></v-text-field>
            </v-col>
            <v-col cols="6" style="padding-left: 30px; padding-right: 30px">
              <v-text-field
                :counter="30"
                id="inputLinkFinale"
                label="Link finale"
                required
                v-model="link_finaleNuovo"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="6">
              <v-textarea
                class="mt-10 mx-auto"
                id="testoDescrizioneQuestionario"
                label="Descrizione questionario"
                outlined
                placeholder="Descrizione del questionario"
                v-model="des_questionarioNuovo"
                style="display: inline-block; width: 500px"
              ></v-textarea>
            </v-col>
            <v-col>
              <!-- Qui sotto c'è il form per caricare le
                   immagini che l'utente vuole usare come logo.
              -->
              <div style="max-width: 510px; min-width: 235px">
                <v-file-input
                  accept="image/*"
                  @change="renderizzaLogo()"
                  class="mb-n3 pb-0 pr-2"
                  label="Carica un'immagine (max 500 × 500 px)"
                  style="max-width: 500px; min-width: 325px"
                  v-model="img_logoNuovo"
                ></v-file-input>
              </div>
              <div
                display="inline-block"
                height="150"
                id="chartLogoNuovo"
                style="
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: 5px;
                  max-height: 150px;
                  max-width: 150px;
                  min-height: 150px;
                  min-width: 150px;
                "
                width="150"
              >
                <svg
                  id="svgImmagine"
                  height="150"
                  style="border: 1px solid black"
                  width="150"
                >
                  <!--<rect class="selection" height="0" width="0" /> --->
                </svg>
              </div>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="6">
              <div>
                <v-checkbox
                  class="ml-auto mr-auto"
                  label="Rendi visibili le domande del questionario"
                  v-model="abilita_lista_domandeNuovo"
                ></v-checkbox>
              </div>
              <!--<div>
                <v-checkbox
                  class="ml-auto mr-auto"
                  label="Abilita l'utente a revisionare il questionario"
                  v-model="abilita_indietroNuovo"
                ></v-checkbox>
              </div>-->
            </v-col>
            <!--<v-col cols="6">
              <div style="text-align:center;">
                <div style="text-align:center;">
                  <label
                    for="tempoMin"
                    style="display:inline-block;margin-right:5px;"
                  >Tempo minimo (min):</label>
                  <v-text-field
                    id="tempoMin"
                    single-line
                    style="display:inline-block;margin-left:5px;width:50px;"
                    type="number"
                    v-model="tempo_minNuovo"
                  />
                </div>
                <div style="text-align:center;">
                  <label
                    for="tempoMax"
                    style="display:inline-block;margin-right:5px;"
                  >Tempo massimo (min):</label>
                  <v-text-field
                    id="tempoMax"
                    single-line
                    style="display:inline-block;margin-left:5px;width:50px;"
                    type="number"
                    v-model="tempo_maxNuovo"
                  />
                </div>
              </div>
            </v-col>-->
          </v-row>
        </form>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
//import Base64 from "b64-to-blob";
import moment from "moment/moment";

export default {
  components: {
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  data: function () {
    return {
      abilita_indietroNuovo: false,
      abilita_lista_domandeNuovo: false,
      cognomeCandidatoDaAggiungere: "",
      data_questionarioNuovo: null,
      des_questionarioNuovo: "",
      emailCandidatoDaAggiungere: "",
      fileCaricato: null,
      flowchartInCancellazione: null,
      id_questionarioNuovo: moment().valueOf(),
      idQuestionarioSelezionato: null,
      id_stato_questionarioNuovo: "-1",
      id_tipo_questionario_sel: null,
      img_logoNuovo: null,
      inputFiltraA: "",
      inputFiltraDa: "",
      inputFiltraStato: "",
      inputFiltraTitolo: "",
      link_finaleNuovo: "",
      nuovo_cod: "",
      nuova_des: "",
      nomeCandidatoDaAggiungere: "",
      num_utenteDaAggiungere: moment().valueOf(),

      tempo_maxNuovo: 0,
      tempo_minNuovo: 0,
      testo_finaleNuovo: "",
      titolo_questionarioNuovo: null,
      titoloQuestionarioSelezionato: "",
      validaEmail: [
        (v) => !!v || "Indirizzo richiesto",
        (v) => /.+@.+/.test(v) || "Indirizzo non valido",
      ],
      validaNomeCandidato: [(v) => !!v || "Nome e cognome richiesti"],

      // Modal
      caricamento_questionari: true,
      confermaCancellaDialogVisible: false,
      filtraDialogVisible: false,
      nuovoFlowchartDialogVisibile: false,
      pubblicaDialogVisible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      flowchart_caricabili: [],
      headers_flowchart: [
        { text: "Pubblica", sortable: false, value: "pubblica" },
        { text: "Modicia", sortable: false, value: "modifica" },
        { text: "Titolo", sortable: false, value: "titolo_questionario" },
        {
          text: "Descrizione",
          sortable: false,
          value: "des_tipo_questionario",
        },
        { text: "Stato", sortable: false, value: "id_stato_questionario" },
        { text: "Cancella", sortable: false, value: "cancella" },
      ],
      listaCandidati: [],
      listaCandidatiValidi: [],
      nodesNuovo: [],
      tipi_questionario: [],
    };
  },
  mounted() {
    this.getQuestionari();
    this.gettabelle();
  },
  methods: {
    aggiungiCandidato() {
      if (
        this.nomeCandidatoDaAggiungere == "" ||
        this.cognomeCandidatoDaAggiungere == "" ||
        this.emailCandidatoDaAggiungere == ""
      ) {
        // Non succede niente, in seguito si potrebbe implementare un pop-up
      } else {
        // Se si tratta di un identificativo già presente, modifico il candidato
        let occorrenzeDelCandidato = 0;
        this.listaCandidati.forEach((elem) => {
          if (
            this.num_utenteDaAggiungere == elem.num_utente &&
            elem.validita == 1
          ) {
            occorrenzeDelCandidato = occorrenzeDelCandidato + 1;
            elem.nome = this.nomeCandidatoDaAggiungere;
            elem.cognome = this.cognomeCandidatoDaAggiungere;
            elem.email = this.emailCandidatoDaAggiungere;
          }
        });

        // Se l'identificativo non era già presente, aggiungo il candidato
        if (occorrenzeDelCandidato == 0) {
          this.listaCandidati.push({
            cognome: this.cognomeCandidatoDaAggiungere,
            dataFineTest: null,
            dataInizioTest: null,
            id_questionario: this.idQuestionarioSelezionato,
            id_questionario_utenti: -1,
            id_utente: localStorage.getItem("id"),
            email: this.emailCandidatoDaAggiungere,
            nome: this.nomeCandidatoDaAggiungere,
            num_utente: this.num_utenteDaAggiungere,
            des_stato: null,
            validita: 1,
          });
        }

        // Sia che abia aggiunto o modificato, resetto le liste di candidati
        occorrenzeDelCandidato = 0;
        this.listaCandidatiValidi = [];
        this.listaCandidati.forEach((elem) => {
          if (elem.validita == 1) {
            this.listaCandidatiValidi.push(elem);
          }
        });

        this.nomeCandidatoDaAggiungere = "";
        this.cognomeCandidatoDaAggiungere = "";
        this.emailCandidatoDaAggiungere = "";
        this.num_utenteDaAggiungere =
          +new Date() + this.listaCandidatiValidi.length;
        this.$refs.pubblicaForm.resetValidation();
        document.getElementById("inputNomeCandidato").focus();
      }
    },
    avviaCancellazioneFlowchart(flowchart) {
      this.flowchartInCancellazione = flowchart;
      this.switchConfermaCancellaDialogVisible();
    },
    checkDomandeQuestionario(elem) {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getdiagramma",
          token: localStorage.user_token,
          db_name: "quickcheck",
          id_questionario: elem.id_questionario,
          id_utente: localStorage.getItem("id"),
        },
      };
      //console.log("getdiagramma request", JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        if (res.data[0] == null) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Nessuna domanda presente, impossibile pubblicare!";
          this.snackbar_text_color = "white";
        } else if (res.data[0].nodes.length == 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Nessuna domanda presente, impossibile pubblicare!";
          this.snackbar_text_color = "white";
        } else {
          this.switchPubblicaDialogVisible();
        }
      });
    },
    chiudiNuovoFlowchartDialog() {
      this.abilita_indietroNuovo = false;
      this.abilita_lista_domandeNuovo = false;
      this.data_questionarioNuovo = "";
      this.des_questionarioNuovo = "";
      this.fileCaricato = null;
      this.link_finaleNuovo = "";
      this.tempo_maxNuovo = 0;
      this.tempo_minNuovo = 0;
      this.id_tipo_questionario_sel = "";
      this.testo_finaleNuovo = "";
      this.titolo_questionarioNuovo = "";
      this.img_logoNuovo = null;
      document.getElementById("chartLogoNuovo").style.backgroundImage =
        "url('')";
      this.id_questionarioNuovo = +new Date();
      if (this.$refs.nuovoFlowchartForm != null) {
        this.$refs.nuovoFlowchartForm.resetValidation();
      }
      this.switchNuovoFlowchartDialogVisible();
    },
    confermaCancellaAnnulla() {
      this.flowchartInCancellazione = null;
      this.switchConfermaCancellaDialogVisible();
    },
    confermaCancellaOk() {
      this.deleteFlowchart(this.flowchartInCancellazione);
      this.switchConfermaCancellaDialogVisible();
      this.flowchartInCancellazione = null;
    },
    filtra() {
      if (this.inputFiltraDa != "") {
        let flowchart_caricabili_aggiornati = this.flowchart_caricabili.filter(
          (elem) => elem.data_questionario >= this.inputFiltraDa
        );
        this.flowchart_caricabili = flowchart_caricabili_aggiornati;
      }
      if (this.inputFiltraA != "") {
        let flowchart_caricabili_aggiornati = this.flowchart_caricabili.filter(
          (elem) => elem.data_questionario <= this.inputFiltraA
        );
        this.flowchart_caricabili = flowchart_caricabili_aggiornati;
      }
      if (this.inputFiltraTitolo != "") {
        let flowchart_caricabili_aggiornati = this.flowchart_caricabili.filter(
          (elem) => elem.titolo_questionario.includes(this.inputFiltraTitolo)
        );
        this.flowchart_caricabili = flowchart_caricabili_aggiornati;
      }
      if (this.inputFiltraStato != "") {
        let flowchart_caricabili_aggiornati = this.flowchart_caricabili.filter(
          (elem) => elem.id_stato_questionario == this.inputFiltraStato
        );
        this.flowchart_caricabili = flowchart_caricabili_aggiornati;
      }
    },
    getQuestionari() {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getquestionario",
          id_utente: localStorage.getItem("id"),
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          //console.log("getquestionario request", JSON.stringify(request));
          //console.log("getquestionario response", JSON.stringify(res.data));
          res.data.forEach((elem) => {
            this.flowchart_caricabili.push(elem);
          });
          this.caricamento_questionari = false;
        })
        .catch(() => {
          this.caricamento_questionari = false;
        });
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "QC_TIPO_QUESTIONARIO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.tipi_questionario = res.data != undefined ? res.data : [];
      });
    },
    getUtenti() {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getutentitest",
          db_name: "quickcheck",
          token: localStorage.getItem("user_token"),
          id_questionario: this.idQuestionarioSelezionato,
          id_utente: localStorage.getItem("id"),
        },
      };
      //console.log("getutenti request", JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        //console.log("getutenti response", JSON.stringify(res));
        res.data.forEach((elem) => {
          if (elem.validita == 1) {
            this.listaCandidati.push(elem);
          }
        });
      });

      this.listaCandidatiValidi = this.listaCandidati;

      /*this.listaCandidati.forEach(elem => {
        if (elem.validita == 1) {
          this.listaCandidatiValidi.push(elem);
        }
      });*/
    },
    deleteFlowchart(flowchart) {
      let flowchartAttuale = [
        {
          data_questionario: flowchart.data_questionario,
          des_questionario: flowchart.des_questionario,
          id_questionario: flowchart.id_questionario,
          id_stato_questionario: 0,
          id_tipo_questionario: flowchart.id_tipo_questionario,
          validita: 0,
        },
      ];
      var request = {
        controller: "Questionario",
        method: "PUT",
        richiesta: {
          action: "setquestionario",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          db_name: "quickcheck",
          tabella: flowchartAttuale,
        },
      };
      this.$store.dispatch("api", request).then(() => {
        location.reload();
      });
    },
    delCandidato(candidato) {
      this.listaCandidati.forEach((elem) => {
        if (elem.id_questionario_utenti == candidato.id_questionario_utenti) {
          elem.validita = 0;
        }
      });

      this.listaCandidatiValidi = [];
      this.listaCandidati.forEach((elem) => {
        if (elem.validita == 1) {
          this.listaCandidatiValidi.push(elem);
        }
      });
    },
    gestisciClickModificaButton(flowchart) {
      /*console.log(
        "questionario in localstorage: ",
        JSON.parse(localStorage.questionarioDaCaricare)
      );*/
      //   if (flowchart.json_text == null) {
      //     console.log("nodi e / o connessioni non valorizzzati");
      //     console.log(flowchart);
      //     flowchart.json_text = {
      //       connections: [],
      //       data_questionario: flowchart.data_questionario,
      //       des_questionario: flowchart.des_questionario,
      //       id_questionario: flowchart.id_questionario,
      //       id_stato_questionario: flowchart.id_stato_questionario,
      //       id_tipo_questionario: flowchart.id_tipo_questionario,
      //       id_utente: flowchart.id_utente,
      //       nodes: [],
      //       num_questionario: flowchart.id_questionario,
      //     };
      //     flowchart.json_text = JSON.stringify(flowchart.json_text);
      //     localStorage.setItem(
      //       "flowchartInCaricamento",
      //       JSON.stringify(JSON.stringify(flowchart))
      //     );
      //     this.$router.replace("/home/dashboard");
      //   } else {
      //     localStorage.setItem(
      //       "flowchartInCaricamento",
      //       JSON.stringify(JSON.stringify(flowchart))
      //     );
      //     this.$router.replace("/home/dashboard");
      //   }
      this.$router.push("/home/check/flowdetail/" + flowchart.id_questionario);
      localStorage.removeItem("questionario");
      localStorage.removeItem("questionario_back");
    },
    handleSelectedFile(convertedData) {
      for (let i = 0; i < convertedData.body.length; i++) {
        this.nomeCandidatoDaAggiungere = convertedData.body[i].nome;
        this.cognomeCandidatoDaAggiungere = convertedData.body[i].cognome;
        this.emailCandidatoDaAggiungere = convertedData.body[i].email;
        this.aggiungiCandidato();
      }
    },
    modificaCandidato(candidato) {
      this.nomeCandidatoDaAggiungere = candidato.nome;
      this.cognomeCandidatoDaAggiungere = candidato.cognome;
      this.emailCandidatoDaAggiungere = candidato.email;
      this.num_utenteDaAggiungere = candidato.num_utente;
    },
    pubblica() {
      var request = {
        controller: "Questionario",
        method: "PUT",
        richiesta: {
          action: "setutentitest",
          token: localStorage.getItem("user_token"),
          id_questionario: this.idQuestionarioSelezionato,
          id_utente: localStorage.getItem("id"),
          db_name: "quickcheck",
          tabella: this.listaCandidati,
        },
      };

      this.$store.dispatch("api", request).then((response) => {
        //console.log("request", JSON.stringify(request));
        //console.log("response", JSON.stringify(response));
        if (response.status == 200) {
          //console.log(response.status);
          this.snackbarPubblicaTxt = "Messaggi inviati con successo!";
          this.snackbarPubblica = true;
        } else {
          this.snackbarPubblicaTxt =
            "Criticità rilevata, risposta del server in stato " +
            response.status;
          this.snackbarPubblica = true;
        }
      });
      this.switchPubblicaDialogVisible();
    },
    renderizzaLogo() {
      // Procedo a caricare l'immagine
      /*var dataBase64;
      var reader = new FileReader();
      reader.readAsDataURL(this.fileCaricato);
      reader.onload = function() {
        dataBase64 = reader.result;
        this.fileCaricato = dataBase64;
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };*/

      // Uso la variabile "fileCaricato" per definire lo sfondo del contenitore
      //console.log(this.img_logoNuovo);
      document.getElementById("chartLogoNuovo").style.backgroundImage =
        "url(" + URL.createObjectURL(this.img_logoNuovo) + ")";

      var dataBase64;
      var reader = new FileReader();
      let that = this;
      reader.onload = function () {
        dataBase64 = reader.result;
        that.fileCaricato = dataBase64;
      };
      reader.readAsDataURL(this.img_logoNuovo);
      reader.onerror = function () {};
    },
    ripristina() {
      this.flowchart_caricabili = [];
      this.getQuestionari();
      this.switchFiltraDialogVisible();
    },
    salvaNuovoFlowchartDialog() {
      if (
        this.des_questionarioNuovo == "" ||
        this.data_questionarioNuovo == null ||
        this.id_tipo_questionario_sel == null
      ) {
        /* Se ci sono dei campi non compilati esce la snackbar */
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "My text";
        this.snackbar_text_color =
          "Sono presenti campi non compilati, impossibile generare il questionario.";
      } else {
        /* Se invece i campi principali sono compilati,
         * salvo dando valori di default a eventuali campi secondari
         * non ancora compilati */
        let flowchartAttuale = [
          {
            abilita_indietro: this.abilita_indietroNuovo == true ? 1 : 0,
            abilita_lista_domande:
              this.abilita_lista_domandeNuovo == true ? 1 : 0,
            data_questionario:
              this.data_questionarioNuovo == ""
                ? new Date()
                : this.data_questionarioNuovo,
            des_questionario: this.des_questionarioNuovo,
            id_questionario: -1,
            id_stato_questionario: "-1",
            id_tipo_questionario: this.id_tipo_questionario_sel,
            id_utente: localStorage.getItem("id"),
            img_logo: this.fileCaricato,
            link_finale: this.link_finaleNuovo,
            tempo_max: this.tempo_maxNuovo,
            tempo_min: this.tempo_minNuovo,
            testo_finale: this.testo_finaleNuovo,
            titolo_questionario: this.titolo_questionarioNuovo,
            validita: 1,
          },
        ];

        var request = {
          controller: "Questionario",
          method: "PUT",
          richiesta: {
            action: "setquestionario",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.getItem("id"),
            db_name: "quickcheck",
            tabella: flowchartAttuale,
          },
        };
        //console.log("setquestionario request", JSON.stringify(request));
        this.$store.dispatch("api", request).then(() => {
          //console.log("setquestionario response", JSON.stringify(res));
          location.reload();
        });
        // Serve un'attesa di mezzo secondo altrimenti Firefox blocca la request (Error: request aborted)
        let that = this;
        setTimeout(function () {
          that.$emit("update:visible", false);
        }, 500);

        // Cambio la visibilità del dialog
        this.switchNuovoFlowchartDialogVisible();
      }
    },
    switchConfermaCancellaDialogVisible() {
      this.confermaCancellaDialogVisible =
        this.confermaCancellaDialogVisible == true ? false : true;
    },
    switchFiltraDialogVisible() {
      this.filtraDialogVisible =
        this.filtraDialogVisible == true ? false : true;
    },
    switchNuovoFlowchartDialogVisible() {
      this.nuovoFlowchartDialogVisibile =
        this.nuovoFlowchartDialogVisibile == true ? false : true;
      //document.getElementById("chartLogoNuovo").style.backgroundImage = "url('')";
    },
    switchPubblicaDialogVisible() {
      this.listaCandidati = [];
      this.pubblicaDialogVisible =
        this.pubblicaDialogVisible == true ? false : true;
    },
  },
  watch: {
    pubblicaDialogVisible: function () {
      if (this.pubblicaDialogVisible) {
        this.nomeCandidatoDaAggiungere = "";
        this.cognomeCandidatoDaAggiungere = "";
        this.emailCandidatoDaAggiungere = "";
        if (this.$refs.pubblicaForm != null) {
          this.$refs.pubblicaForm.resetValidation();
        }
        this.getUtenti();
      }
    },
    filtraDialogVisible: function () {
      this.inputFiltraA = "";
      this.inputFiltraDa = "";
      this.inputFiltraTitolo = "";
      this.inputFiltraStato = "";
    },
  },
};
</script>