<template>
  <CarrelloCristal />
</template>
<script>
import CarrelloCristal from "@/components/PRODUCTION/Produzione/CarrelloCristal.vue";

export default {
  name: "CRISTALCarrelloCristal",
  components: {CarrelloCristal},
  props: {},
  data() {
    return {
        // Variabili d'appoggio
        // Modal
        // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {

  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>