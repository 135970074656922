import CHART_COMMON from "./_common.js";
class CHART_GAUGE{
    extractedColor = '#000000';   
    COMMON = new CHART_COMMON(); 
    options = {
        tooltip: {
            formatter : function (data){
                let tooltip = '<div class="graph_tooltip"><h6>'+data.name+'</h6>';
                tooltip+=   '<div>'+data.data.formatted+'</div></div>';
                return tooltip;
            },
            backgroundColor : 'transparent',
        },
        grid: {
            show:false
        },
        series: []
    };
    setOptions(data){
        try{
            this.extractedColor = getComputedStyle(document.documentElement).getPropertyValue('--widget_title_background');  
            this.setSerie(data.serie);
            return this.options;
        }catch(error){
            return {};
        }        
    }

    setSerie(serie){
        if(serie.length <= 0){return;}
        serie = this.COMMON.filterSerie(serie)[0];        
        if(serie.length <= 0){return;}

        serie.extra = this.COMMON.getExtra(serie);
        // Formattazione del dato
        let processed = this.COMMON.processSerie(serie)[0];
        delete processed.itemStyle;

        function sort_ranges(a,b){
            if ( a.start_ref < b.start_ref ){
                return -1;
            }
            if ( a.start_ref > b.start_ref ){
                return 1;
            }
            return 0;
        }

        let ranges = serie.extra.formato;
        for(let i = 0; i < ranges.length; i++){
            if(ranges[i].query == true){
                ranges[i].start_ref = Number(this.COMMON.format_serie[ranges[i].start_ref][0]);
                ranges[i].end_ref = Number(this.COMMON.format_serie[ranges[i].end_ref][0]);
            }else{
                ranges[i].start_ref = Number(ranges[i].start_ref);
                ranges[i].end_ref = Number(ranges[i].end_ref);
            }
            
        }

        if(ranges.length <= 0){return;}
        ranges.sort(sort_ranges);
        
        let min = ranges[0].start_ref;
        let max = ranges[ranges.length-1].end_ref;
        let delta = Math.abs(max-min);
        let color = [];
        for(let i=0;i<ranges.length;i++){
            color.push([(Math.abs(Number(ranges[i].end_ref)-min)/delta),ranges[i].color]);
        }
        this.options.series.push({
            type : 'gauge',
            radius: '95%',
            startAngle : 225,
            endAngle : -45,
            min : min,
            max : max,
            splitNumber : 5,
            data : [processed],
            axisLine : {
                lineStyle : {
                    color : color,
                }
            },
            splitLine : {
                show: false,
            },
            axisTick : {
                show : false,
            },
            detail : {
                offsetCenter : [0, '80%'],
                fontSize: 18,
                fontWeight:'bold',
                formatter : function(){return '{title|'+ serie.nome +':} '+processed.formatted;},
                rich : {
                    title : {
                        color : this.extractedColor,
                        fontSize: 18,
                    }
                }
            },
            itemStyle : {
                color : this.extractedColor,
            },
            title : {
                show:false,
            },
            animation : false
            
        });
    }    
}
export default CHART_GAUGE;