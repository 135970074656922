<template>
    <v-dialog v-model="show" persistent>
        <div class="graph_render">
            <header>
                <h1>Esporta Grafico</h1>
                <GraphSVG name="close" @click="show=false" />
            </header>
            <main>
                <section>
                    <section :data-disabled="!is_table||options.type=='svg'" >
                        <figure title="Mantieni proporzioni" ><GraphSVG :name="(respect_ratio==true)?'link':'link_off'" @click="respect_ratio=!respect_ratio;widthCheckRatio(null)" /></figure>
                        <div>
                            <div>
                                <span>Larghezza</span><input v-bind:value="options.width" min="1" type="number" @input="widthCheckRatio">
                            </div>
                            <div>
                                <span>Altezza</span><input v-bind:value="options.height" min="1" type="number" @input="heightCheckRatio">
                            </div>
                        </div>                        
                    </section>
                     <section>
                        <div>
                            <div>
                                <span>Qualità</span><input :min="min_quality" :max="max_quality" v-model="options.quality" type="range" :disabled="(options.type!='jpeg'||!is_table)?true:false"><span>{{options.quality}}%</span>
                            </div>
                            <div :data-disabled="is_table">
                                <span>Sfondo</span>
                                <div>
                                    <input type="color" v-model="options.background" id="graph_render_color">
                                    <label :style="'background-color:'+ ((no_background==true)?'transparent':options.background)" for="graph_render_color" ></label>
                                    <input :disabled="no_background" type="text" v-model="options.background">                                    
                                </div>
                                <GraphSVG v-if="options.type!='jpeg'" :name="(no_background==true)?'eye_closed':'eye_open'" title="Visibilità sfondo" @click="no_background=!no_background" />
                            </div>
                        </div>                        
                    </section>
                    <section>
                        <div>
                            <div>
                                <span style="margin:0">Formato</span>
                                <select v-model="options.type">
                                    <option v-for="(item,i) in types" :key="i" :value="item" :disabled="item=='svg'&&!is_table">{{item}}</option>
                                </select>
                            </div>
                        </div>                        
                    </section>
                </section>
                <aside>
                    <div class="graph_scrollbar_input">
                        <img @load="preview_loaded" :src="preview_src" id="graph_render_preview" :style="'background-color:'+options.background">
                    </div>
                </aside> 
            </main>
            <footer>
                <span @click="show=false">ANNULLA</span>
                <span @click="graph_export">ESPORTA</span> 
            </footer>
        </div>
    </v-dialog>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name : 'GraphRender',
    components:{
        GraphSVG,
    },
    data(){
        return{
            show : false,
            options : {
                quality : 90,                
                type : 'png',
                width : 300,
                height : 300,
                background : '#ffffff',
            }, 
            types : ['png','jpeg','svg','pdf'],
            min_quality : 10,
            max_quality : 100,
            preview_ratio : 1,  
            preview_src : null,
            respect_ratio : true, 
            no_background : false,  
            ref_index : null,  
            ref_title : '',    
            is_table : false
        }
    },
    mounted(){
        document.body.addEventListener('graph_render',this.show_render);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_render',this.show_render);
    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        show_render(evt){
            this.show=true;
            let index = evt.detail.id;
            this.ref_index = index;
            this.ref_title = evt.detail.data.nome;
            this.options.type = 'jpeg';

            let data = evt.detail.data;


            let baseWidth = undefined;

            if(baseWidth!=null&&baseWidth!=undefined){
                baseWidth = baseWidth.toString().replaceAll('px','');
                if(!isNaN(baseWidth)){this.options.width=Number(baseWidth);}
            }
            this.is_table = data.famiglia_grafici == 4;

            let that = this;
            let render_event = new CustomEvent('graph_raster',{detail:{
                options : {
                    quality : null,
                    type : 'svg',
                    width : 400,
                    height : 'auto',
                    background : 'transparent',
                },
                download : false,
                callback : function(data){
                    that.preview_src = data;
                },
            }});
            document.getElementById('chart-'+index).dispatchEvent(render_event);            
        },
        preview_loaded(){
            let img = document.getElementById('graph_render_preview');
            this.preview_ratio = img.offsetWidth/img.offsetHeight;
            this.options.height = Math.round(this.options.width/this.preview_ratio);
        },
        heightCheckRatio(event){
            let value = (event==null)?this.options.height:event.target.value;
            if(isNaN(value)||value<=0){
                this.options.height=1;
            }else{
                this.options.height=value;
                if(this.respect_ratio){this.options.width=Math.round(value*this.preview_ratio);}
            }
        },
        widthCheckRatio(event){
            let value = (event==null)?this.options.width:event.target.value;
            if(isNaN(value)||value<=0){
                this.options.width=1;
            }else{
                this.options.width=value;
                if(this.respect_ratio){this.options.height=Math.round(value/this.preview_ratio);}
            }
        },
        graph_export(){
            let options = Object.assign({},this.options);
            if(this.no_background){
                if(options.type=='jpeg'){
                    options.background='#ffffff';
                }else{
                    options.background='transparent';
                }
            }
            let render_event;
            if(options.type == 'pdf'){
                render_event = new CustomEvent('graph_raster',{detail:{
                    options : {
                        quality : 100,
                        type : 'svg',
                        width : 'auto',
                        height : 'auto',
                        background : 'transparent',
                    },
                    download : false,
                    callback : this.printExec,
                }});                
            }else{
                render_event = new CustomEvent('graph_raster',{detail:{
                    options : options,
                    download : true,
                    name : this.ref_title,
                }});                
            }
            document.getElementById('chart-'+this.ref_index).dispatchEvent(render_event);
             
        },
        printExec(data){  
            this.printCleanup();
            let figure = document.createElement('figure');            
            figure.setAttribute('class','graph_print'); 

            let title = document.createElement('h2');
            title.innerText = this.ref_title;
            figure.appendChild(title);

            window.addEventListener("afterprint", this.printCleanup);

            let img = new Image();
            img.onload = function(){window.print();};
            img.onerror = function(){window.print();};
            img.src = data;  
            figure.appendChild(img);
            document.body.appendChild(figure);         
        },
        printCleanup(){
            window.removeEventListener("afterprint", this.printCleanup);
            let imgs = document.body.getElementsByClassName('graph_print');
            let length = imgs.length;
            for(let i=0; i < length; i++){
                document.body.removeChild(imgs[0]);
            }
        }
    }
}
</script>
<style scoped src="./Render.css" />
<style src="../CSS/Print.css" />