<template>
    <v-container>        
        <v-row justify="end">
            <v-btn text @click="parse">
                <v-icon class="mr-2">mdi-check</v-icon>
                Analizza e salva
            </v-btn>
        </v-row>
        <v-row>
            <p contenteditable="true" spellcheck="false" id="graph_text_editor_content_overall">{{value.query}}</p>
        </v-row>        
    </v-container>
</template>
<script>
export default {
    name : "TextEditorOverall",
    props : {
        value : Object,
        template : Array,
    },
    data(){
        return {
            PARSED : {},
            DATA : [],
            SQL : [
                {name:'SELECT',type:0},
                {name:'FROM',type:0},
                {name:'WHERE',type:0},
                {name:'UNION',type:0},
                {name:'GROUP BY',type:1},
                {name:' AND',type:1},
                {name:' OR',type:1},
                {name:'INNER JOIN',type:1},
                {name:'OUTER JOIN',type:1},
                {name:'LEFT JOIN',type:1},
                {name:'RIGHT JOIN',type:1},
                {name:'JOIN',type:2},
                {name:'BETWEEN',type:2},
                {name:' AS',type:2},
                {name:' ON',type:2},
            ]
        }
    },
    mounted(){
        document.getElementById('graph_text_editor_content_overall').addEventListener('paste',this.parse_paste);
        this.parse();
    },
    beforeDestroy(){
        let editor = document.getElementById('graph_text_editor_content_overall');
        if(editor!=null&&editor!=undefined){editor.removeEventListener('paste',this.parse_paste);}
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        parse_paste(event){
            let text = event.clipboardData.getData('text/plain');
            event.preventDefault();
            document.getElementById('graph_text_editor_content_overall').innerHTML = text;
        },

        parse(){            
            let textarea = document.getElementById('graph_text_editor_content_overall');
            let indents = textarea.getElementsByClassName('space');
            for(let i = 0; i < indents.length; i++){
                textarea.removeChild(indents[i]);
            }

            let text = textarea.innerText.toString().replaceAll('\n',' ')+ ' ';
            let parsedText = '';
            let buffer = null;
            let nameBuffer = null;
            let skip = false;
            this.PARSED = {};

            for(let i=0;i<this.template.length;i++){this.PARSED[this.template[i].keyword] = [];}
            for(let i=0;i<text.length;i++){                
                let char = text.charCodeAt(i);
                skip = false;
                for(let j=0;(j<this.template.length)&&buffer==null;j++){
                    if(this.template[j].keyword.charCodeAt(0) == char){
                        buffer = String.fromCharCode(char);
                        let color = this.template[j].color
                        parsedText += '<mark style="color:'+color+'" >'+String.fromCharCode(char);
                        nameBuffer = this.template[j].keyword;
                        skip = true;
                        break;
                    }                                        
                }     
                if(skip){continue;}           
                if(buffer!=null){                                       
                    if((char>=48&&char<=57)||(char>=97&&char<=122)||(char>=65&&char<=90)||(char==95)){
                        buffer+=String.fromCharCode(char);
                        parsedText+=String.fromCharCode(char);
                    }else{
                        if(this.PARSED[nameBuffer].indexOf(buffer)<0){this.PARSED[nameBuffer].push(buffer);}
                        parsedText+='</mark>'+String.fromCharCode(char);
                        buffer = null;
                        nameBuffer = null;
                        for(let j=0;j<this.template.length;j++){
                            if(this.template[j].keyword.charCodeAt(0) == char){i--;break;}                                        
                        }
                    }
                }else{
                    parsedText+=String.fromCharCode(char);
                }
            }
            // Formattazione SQL
            let node = document.createElement('p');
            node.innerHTML = parsedText;
            parsedText = '';
            
            for(let i = 0; i < node.childNodes.length; i++){
                let curr_node = node.childNodes[i];
                if(curr_node.nodeName.toLowerCase() != '#text'){
                    parsedText += curr_node.outerHTML;
                    continue;
                }
                let text = curr_node.textContent;
                let upper_text = text.toUpperCase();
                for(let j=0; j < this.SQL.length;j++){
                    let name = this.SQL[j].name;
                    if(upper_text.indexOf(name)<0){
                        continue;
                    }
                    name = new RegExp(name + ' ', 'ig');
                    let mark = '';
                    switch(this.SQL[j].type){
                        case 0:
                            mark = '<div class="indent"><mark class="keyword">' + this.SQL[j].name + ' </mark></div>';                            
                            break;
                        case 1:
                            mark = '<div></div><mark class="keyword">' + this.SQL[j].name + ' </mark>';                                                      
                            break;
                        case 2:
                            mark = '<mark class="keyword">' + this.SQL[j].name + ' </mark>';
                            break;
                        default:
                            break;
                    }
                    text = text.replaceAll(name,mark);   
                 
                }
                parsedText += text.replaceAll(',',',<div></div>');    
            }
            
            node.innerHTML = parsedText;
            parsedText = '';
            let indent = false;
            let prev_node = null;
            for(let i = 0; i <node.childNodes.length; i++){
                let curr_node = node.childNodes[i];
                if(curr_node.nodeName.toLowerCase()=='div'){
                    if(curr_node.getAttribute('class')=='indent'){
                        indent = true;
                        parsedText += curr_node.outerHTML;
                        prev_node = curr_node;
                        continue;
                    }
                }
                    
                if(indent){
                    if(prev_node!=null){
                        if(prev_node.nodeName.toLowerCase()!='#text'&&prev_node.nodeName.toLowerCase()!='mark'){
                            parsedText += '<div class="space"></div>';
                        }
                    }                    
                }
                if(curr_node.nodeName.toLowerCase()=='#text'){
                    parsedText += curr_node.textContent;
                }else{
                    parsedText += curr_node.outerHTML;
                }
                prev_node = curr_node;
                
            }

            let newdata = Object.assign({},this.value);
            for(let i=0;i<this.template.length;i++){                
                let newtable = [];
                let target = this.value[this.template[i].targetField];
                if(target == null){continue;}
                let oldtable = (target==undefined)?[]:target.map((x => x));
                let targetProperty = this.template[i].targetProperty;
                let parsed = this.PARSED[this.template[i].keyword];
                for(let j=0; j<parsed.length;j++){
                    let found = false;
                    for(let k=0; k<oldtable.length;k++){                        
                        let olditem = oldtable[k];
                        if(olditem == null){continue;}
                        if(olditem[targetProperty] == parsed[j]){
                            found = true;
                            newtable.push(olditem);
                            oldtable[k] = null;                            
                            break;
                        }
                    }
                    if(!found){
                        let newitem = Object.assign({},this.template[i].template);
                        newitem[targetProperty] = parsed[j];
                        newtable.push(newitem);
                    }
                }
                newdata[this.template[i].targetField] = newtable;
            }

            
            newdata.query = text;
            this.$emit('input',newdata);

            document.getElementById('graph_text_editor_content_overall').innerHTML = parsedText.replaceAll('\t','');
        }
    }
}
</script>
<style scoped>

#graph_text_editor_content_overall{
    outline: none!important;
    border-radius: 0;
    border:thin solid rgba(0,0,0,.12);
    border-radius : 5px;
    resize:none;
    padding:15px;
    width:100%;
    font-size:15px;
    margin:10px 0 15px;
    text-align: left;
    max-height:300px;
    overflow-y: auto;
}
</style>
<style>
#graph_text_editor_content_overall  mark{
    background-color: transparent;
    font-weight: 600;
}
#graph_text_editor_content_overall  mark.keyword{
    text-transform: uppercase;
    color :  #006666;
    font-weight: bold;
}
#graph_text_editor_content_overall .space{
    display: inline-block;
    width:30px;
    vertical-align: middle;
    height:20px;
}
</style>