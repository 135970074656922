var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5 mx-5"},[_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Contenitori Registrati ")]),_c('v-spacer'),(_vm.filtri_visible === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.filtri_visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter")])],1)]}}],null,false,3068551005)},[_c('span',[_vm._v("Filtri")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.ripristina}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter-off")])],1)]}}])},[_c('span',[_vm._v("Ripristina")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-keyboard-backspace ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers_registrazioni,"items":_vm.items_registrazioni,"height":"70vh"},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.filtri_visible)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni,"item-text":function (e) { return e.cod_articolo; },"item-value":"cod_articolo","label":"Cod. Articolo"},on:{"change":_vm.filtra},model:{value:(_vm.cod_articolo_search),callback:function ($$v) {_vm.cod_articolo_search=$$v},expression:"cod_articolo_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_articolo_search = '';
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni,"item-text":function (e) { return e.nominativo; },"item-value":"nominativo","label":"Operatore"},on:{"change":_vm.filtra},model:{value:(_vm.nominativo_search),callback:function ($$v) {_vm.nominativo_search=$$v},expression:"nominativo_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.nominativo_search = '';
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data Da","readonly":""},model:{value:(_vm.data_da_search_formattata),callback:function ($$v) {_vm.data_da_search_formattata=$$v},expression:"data_da_search_formattata"}},on))]}}],null,false,541284678),model:{value:(_vm.calendario_data_da),callback:function ($$v) {_vm.calendario_data_da=$$v},expression:"calendario_data_da"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario_data_da = false}},model:{value:(_vm.data_da_search),callback:function ($$v) {_vm.data_da_search=$$v},expression:"data_da_search"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data A","readonly":""},model:{value:(_vm.data_a_search_formattata),callback:function ($$v) {_vm.data_a_search_formattata=$$v},expression:"data_a_search_formattata"}},on))]}}],null,false,3102972966),model:{value:(_vm.calendario_data_a),callback:function ($$v) {_vm.calendario_data_a=$$v},expression:"calendario_data_a"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario_data_a = false}},model:{value:(_vm.data_a_search),callback:function ($$v) {_vm.data_a_search=$$v},expression:"data_a_search"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni,"item-text":function (e) { return e.conformita === null
                    ? 'indefinito'
                    : e.conformita === 1
                    ? 'approvato'
                    : 'respinto'; },"item-value":"conformita","label":"Conformità"},on:{"change":_vm.filtra},model:{value:(_vm.conformita_search),callback:function ($$v) {_vm.conformita_search=$$v},expression:"conformita_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.conformita_search = 2;
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1)],1):_vm._e()]},proxy:true},{key:"item.qta_box",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.qta_box != null ? item.qta_box : "N/D")+" ")]}},{key:"item.qta_pz",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.qta_box != null ? item.qta_pz : "N/D")+" ")]}},{key:"item.quantita",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.qta_box != null ? item.quantita : "N/D")+" ")]}},{key:"item.data",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(((item.data.substring(8, 10)) + "/" + (item.data.substring( 5, 7 )) + "/" + (item.data.substring(0, 4)) + " - " + (item.data.substring( 11, 13 )) + ":" + (item.data.substring(14, 16))))+" ")]}},{key:"item.conformita",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.conformita === 1 ? 'success' : 'grey'},on:{"click":function($event){_vm.$set(item, 'conformita', 1);
            _vm.setContenitori(item);}}},[_vm._v(" "+_vm._s(item.conformita === 1 ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('v-icon',{attrs:{"color":item.conformita === 0 ? 'error' : 'grey'},on:{"click":function($event){_vm.$set(item, 'conformita', 0);
            _vm.setContenitori(item);}}},[_vm._v(" "+_vm._s(item.conformita === 0 ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")]),_c('v-icon',{attrs:{"color":item.conformita === null ? 'primary' : 'grey'},on:{"click":function($event){_vm.$set(item, 'conformita', null);
            _vm.setContenitori(item);}}},[_vm._v(" "+_vm._s(item.conformita === null ? "mdi-checkbox-intermediate" : "mdi-checkbox-blank-outline")+" ")])]}}],null,true)})],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }