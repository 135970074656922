<template>
  <div class="ml-auto mr-auto mt-4">
    <v-container fluid>
      <v-row class="px-3" justify="end">
        <v-col class="py-0 d-flex justify-end align-center" cols="12" sm="4" md="6">
          FEDERAL MOGUL GLOBAL AFTERMARKET EMEA - BVBA
          <v-btn
            @click="goBack()"
            tile
            dark
            class="py-0"
            style="margin-left: 1.6rem"
            color="red lighten-2"
          >
            <v-icon dark>mdi-chevron-left</v-icon>INDIETRO
          </v-btn>
        </v-col>
      </v-row>
      <v-container fluid>
        <v-divider></v-divider>
        <Drawer />
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Drawer from "./PackagingDrawer";

export default {
  components: {
    Drawer,
  },
  methods: {
    goBack() {
      this.$router.push("/home/production/packaging")
    },
  },
};
</script>

<style css scoped>
</style>
