 <template>
  <v-card style="padding: 20px; width: 99%" class="ml-2">
    <v-snackbar :color="colore_snack" v-model="snackbar">
      {{ this.messaggio_snackbar }}
      <v-btn color="white" text @click="snackbar = false">Chiudi</v-btn>
    </v-snackbar>
    <v-toolbar dark color="secondary" dense>
      <v-toolbar-title>Parametri Azienda</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="$router.replace('/home')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col md="3">
          <v-card class="mx-auto" color>
            <v-toolbar color="success" dark>
              <v-toolbar-title>Email</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="white" icon dark v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="aggiungiUtentiEmail()">
                    <v-list-item-title>Aggiungi Utenti</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-list style="max-height: 300px; overflow-y: auto">
              <v-list-item v-for="item in utenti_email" :key="item.id_personale">
                <v-list-item-icon
                  @click="deleteEmail(item)"
                  style="cursor: pointer"
                >
                  <v-icon color="red">mdi-delete</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.email"></v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.nome }} {{ item.cognome }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col md="9" sm="12">
          <v-card class="mx-auto">
            <v-toolbar color="blue-grey darken-3" dark>
              <v-toolbar-title>Impostazioni Generali</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-btn
                color="green "
                class="ma-2 white--text"
                @click="salvaImpo()"
              >
                Salva
                <v-icon right dark>mdi-content-save</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="2" sm="12">
                  <v-chip class="ma-5" color="blue" label text-color="white">
                    <v-icon left>mdi-thermometer-chevron-down</v-icon>Valore
                    Soglia Min
                  </v-chip>
                </v-col>
                <v-col cols="6" md="1" sm="12">
                  <v-text-field
                    v-model="azienda.valore_min"
                    type="number"
                    label="Valore"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="1" sm="12">
                  <v-chip class="ma-5" color="error" label text-color="white">
                    <v-icon left>mdi-youtube</v-icon>Link
                  </v-chip>
                </v-col>
                <v-col cols="6" md="3" sm="12">
                  <v-text-field
                    v-model="azienda.link"
                    label="Link"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" sm="12">
                  <v-chip class="ma-5" color="green" label text-color="white">
                    <v-icon left>mdi-value</v-icon>Rileva valore
                  </v-chip>
                </v-col>
                <v-col cols="6" md="2" sm="12" class="ml-0">
                  <v-switch v-model="azienda.rileva_valore" class></v-switch>
                </v-col>

                <v-col cols="6" md="2" sm="12">
                  <v-chip class="ma-5" color="error" label text-color="white">
                    <v-icon left>mdi-thermometer-chevron-up</v-icon>Valore
                    Soglia Max
                  </v-chip>
                </v-col>
                <v-col cols="6" md="1" sm="12">
                  <v-text-field
                    v-model="azienda.valore_max"
                    type="number"
                    label="Valore"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" sm="12">
                  <v-chip class="ma-5" color="purple" label text-color="white">
                    <v-icon left>mdi-value</v-icon>Salva Anonimo
                  </v-chip>
                </v-col>
                <v-col cols="6" md="2" sm="12" class="ml-0" text-align="right">
                  <v-switch v-model="azienda.check_anonimo" class></v-switch>
                </v-col>
                <v-col cols="6" md="2" sm="12">
                  <v-chip class="ma-5" color="green" label text-color="white">
                    <v-icon left>mdi-value</v-icon>Salva valore
                  </v-chip>
                </v-col>
                <v-col cols="6" md="2" sm="12" class="ml-0" text-align="right">
                  <v-switch v-model="azienda.salva_valore" class></v-switch>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip class="ma-5" color="error" label text-color="white">
                    <v-icon left>mdi-time</v-icon>Orario Ritardo
                  </v-chip>
                </v-col>
                <v-col cols="12" md="5" sm="12"></v-col>
                <v-col cols="12" md="3" sm="12" text-align="right">
                  <v-menu
                    v-model="menuDataOra"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="azienda.orario"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="azienda.orario"
                      type="month"
                      format="24hr"
                      @input="menu2 = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="yellow darken-3"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-email</v-icon>Testo Email Allerta
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.email_txt"
                    name="input-7-4"
                    label="Testo Email Allerta"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Pre Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.pre_disclaimer"
                    name="input-7-4"
                    label="Pre Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Post Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    outlined
                    v-model="azienda.post_disclaimer"
                    name="input-7-4"
                    label="Post Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8">
                  <v-textarea
                    outlined
                    v-model="azienda.disclaimer1"
                    name="input-7-4"
                    label="Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.disclaimer2"
                    name="input-7-4"
                    label="Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.disclaimer3"
                    name="input-7-4"
                    label="Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.disclaimer4"
                    name="input-7-4"
                    label="Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Disclaimer
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.disclaimer5"
                    name="input-7-4"
                    label="Disclaimer"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="orange darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Privacy
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.privacy"
                    name="input-7-4"
                    label="Privacy"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-chip
                    class="ma-5"
                    color="blue darken-4"
                    label
                    text-color="white"
                  >
                    <v-icon left>mdi-text</v-icon>Termini d'uso
                  </v-chip>
                </v-col>
                <v-col cols="12" md="8" sm="12">
                  <v-textarea
                    outlined
                    v-model="azienda.termini_uso"
                    name="input-7-4"
                    label="Termini d'uso"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col md="2">
          <v-card class="mx-auto" color>
            <v-toolbar color="success" dark>
              <v-toolbar-title>Gruppi</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="white" icon dark v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="aggiungiGruppo()">
                    <v-list-item-title>Aggiungi Gruppo</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-list style="max-height:300px;overflow-y:auto">
              <v-list-item v-for="item in gruppo_famiglia" :key="item.nome">
                <v-list-item-icon @click="deleteGruppo(item)" style="cursor:pointer">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-list-item-icon>

                <v-list-item-content style="cursor:pointer" @click="detailGruppo(item)">
                  <v-list-item-subtitle>{{item.cod_personale}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>-->
        <v-dialog v-model="dialog_set_utente_email" max-width="40%" persistent>
          <v-card>
            <v-card-title>Aggiungi Utente</v-card-title>
            <v-card-text>
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-autocomplete
                      v-model="dipendente_sel"
                      :items="dipendenti"
                      chips
                      color="blue-grey lighten-2"
                      label="Seleziona utenti"
                      item-text="cognome"
                      item-value="id_tabella"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                          >{{ data.item.nome }}</v-chip
                        >
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.nome"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.cognome"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog_set_utente_email = false"
                >Annulla</v-btn
              >
              <v-btn color="blue darken-1" text @click="SetUtenti()"
                >Conferma</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
//import axios from "axios";
import config from "../../../public/config";
export default {
  props: ["azienda_sel"],
  data: () => ({
    config: config,
    dialog_users: false,
    azienda_sel_local: {},
    utenti_email: [],
    utenti_gruppo: [],
    azienda: [],
    messaggio_snackbar: "",
    colore_snack: "green",
    snackbar: false,
    gruppo_famiglia: [],
    menuDataOra: false,
    //GESTIONE GRUPPO EMAIL
    dialog_set_utente_email: false,
    dipendente_sel: [],
    dipendenti: [],
  }),
  mounted() {
    this.getDipendenti();
    this.getGruppi();
    this.getAzienda();
  },
  methods: {
    aggiungiUtentiEmail() {
      this.dialog_set_utente_email = true;
    },
    getDipendenti() {
      this.values = [];
      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getlistadipendenti",
          tipo: "a",
          token: localStorage.user_token,
          id_utente: localStorage.id,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.dipendenti = res.data.dipendenti;
      });
    },
    getAzienda() {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "getazienda",
      //   token: localStorage.user_token,
      //   idUtente: parseInt(localStorage.id),
      //   idAzienda: localStorage.id_azienda,
      //   dbName: "medical",
      // };
      // console.log(finale);
      // var data = JSON.stringify(finale);
      var request = {
        controller: "User",
        method: "POST",
        richiesta: {
          action: "getazienda",
          token: localStorage.user_token,
          idUtente: parseInt(localStorage.id),
          idAzienda: localStorage.id_azienda,
          dbName: "medical",
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        this.azienda = response.data[0];
      });

      // axios
      //   .post(this.$store.state.config.ip + "api/User", data, config)
      //   .then((response) => {
      //     // JSON responses are automatically parsed.
      //     this.azienda = response.data[0];
      //     console.log(this.azienda);
      //   })
      //   .catch((e) => {
      //     if (e.response.status == 401) {
      //       localStorage.clear();
      //       this.$router.replace("login");
      //     }
      //     console.log(e);
      //   });
    },
    getGruppi() {
      this.utenti_email = [];
      var request = {
        controller: "User",
        method: "POST",
        richiesta: {
          action: "getgruppi",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.data.result != undefined) {
          this.utenti_email = res.data.result.email;
          if (res.data.result.gruppo) {
            this.utenti_gruppo = res.data.result.gruppo;
            this.gruppo_famiglia = this.alasql(
              "SELECT DISTINCT(cod_personale) as cod_personale FROM ? ",
              [this.utenti_gruppo]
            );
          }
        }
      });
    },
    SetUtenti() {
      var item = this.dipendente_sel;
      var tab = this.utenti_email;
      item.forEach((element) => {
        var a = {
          cognome: element.cognome,
          nome: element.nome,
          id_personale: element.id_personale,
          id_ruolo: 1,
        };
        tab.push(a);
      });

      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "setGruppi",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
          tabella: tab,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status != 204) {
          this.colore_snack = "green";
          this.messaggio_snackbar = "Email aggiunte con successo !";
          this.snackbar = true;
          this.dialog_set_utente_email = false;
        } else {
          this.colore_snack = "error";
          this.messaggio_snackbar = "Errore nel processare la richiesta!";
          this.snackbar = true;
          this.dialog_set_utente_email = false;
        }
      });
    },
    deleteEmail(item) {
      var index = this.utenti_email.indexOf(item);
      this.utenti_email[index].id_ruolo = 0;

      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "setGruppi",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
          tabella: this.utenti_email,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status != 204) {
          this.colore_snack = "green";
          this.messaggio_snackbar = "Email rimossa con successo !";
          this.snackbar = true;
          var index = this.utenti_email.indexOf(item);
          this.utenti_email.splice(index, 1);
        } else {
          this.colore_snack = "error";
          this.messaggio_snackbar = "Errore nel processare la richiesta!";
          this.snackbar = true;
        }
      });
    },
    salvaImpo() {
      var tab = [];
      this.azienda.check_anonimo =
        this.azienda.check_anonimo == true ? "1" : "0";
      this.azienda.rileva_valore =
        this.azienda.rileva_valore == true ? "1" : "0";
      this.azienda.salva_valore = this.azienda.salva_valore == true ? "1" : "0";

      tab.push(this.azienda);

      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "setazienda",
          token: localStorage.user_token,
          idUtente: parseInt(localStorage.id),
          tabella: tab,
          dbName: "medical",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status != 204) {
          this.colore_snack = "green";
          this.messaggio_snackbar = "Impostazioni salvate correttamente";
          this.snackbar = true;
          this.azienda.check_anonimo =
            this.azienda.check_anonimo == 1 ? true : false;
          this.azienda.rileva_valore =
            this.azienda.rileva_valore == 1 ? true : false;
          this.azienda.salva_valore =
            this.azienda.salva_valore == 1 ? true : false;
        } else {
          this.colore_snack = "error";
          this.messaggio_snackbar = "Errore nel processare la richiesta!";
          this.snackbar = true;
        }
      });
    },
  },
};
</script>