<template>
  <div style="padding: 20px">
    <v-card>
      <v-toolbar primary-title dense class="white--text" color="indigo" flat>
        <v-toolbar-title>Scarti</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="red--text"
          @click="clickChiudi"
          color="blue-grey lighten-5"
          small
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="header_scarti"
        :items="items_scarti"
        class="elevation-1"
      >
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "TabellaScarti",
  components: {},
  props: {
    header_scarti: {
      type: Array,
      default: () => [],
    },
    items_scarti: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
    };
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
  },
  mounted() {},
};
</script>
<style scoped>
</style>