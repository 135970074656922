<template>
  <v-app id="inspire_main" style="background-color: #f5f5f5">
    <v-snackbar v-model="snackbar" :color="snackbar_color">{{
      snackbar_text
    }}</v-snackbar>

    <v-app-bar
      id="general_app_bar"
      style="position: fixed; width: 100%; left: 0"
      app
      dense
      clipped
      color="white"
      
    >
      <v-app-bar-nav-icon 
        @click.stop="drawer = !drawer"
        class="hidden-lg-and-up "
      />

          <GlobalToolBar 
              :NOME_AZIENDA = this.nome_azienda
              :NOME_UTENTE = this.nome_utente  
              :DES_PROFILO = this.des_profilo
              :ELEVATION = 0 
          /> 

   <!--   
      <v-row align="center">
        
        <v-col cols="5" align="left">
          <v-toolbar-title style="color: Black"
            >{{ nome_azienda }} — {{ nome_utente }}</v-toolbar-title
          >
        </v-col>

       
        <v-col cols="2">
          <v-btn @click="apriDashboard" icon align="center">
            <v-icon >mdi-home</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" />

       
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-help</v-icon>
              </v-btn>
            </template>
            <span>FAQ</span>
          </v-tooltip>
        </v-col>

       
        <v-col cols="1">
          <v-menu
            v-model="menu"
            :close-on-content-click="true"
            :nudge-width="200"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                light
                v-bind="attrs"
                v-on="on"
                class="ml-4 mr-1 pr-1 pl-1"
              >
                <v-avatar size="30">
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

          
            <v-card>
              <v-card-title>{{ nome_utente }}</v-card-title>
              <v-card-subtitle class="text-left">{{
                des_profilo
              }}</v-card-subtitle>
              <v-divider />
              <v-card-actions>
              
                <v-btn
                  icon
                  x-small
                  title="Impostazioni Account"
                  @click="$router.push('/home/account')"
                >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>

               
                <v-btn
                  icon
                  x-small
                  title="Amministrazione"
                  v-if="show_admin_tools == true"
                  @click="$router.push('/home/amministrazione')"
                >
                  <v-icon>mdi-shield-account</v-icon>
                </v-btn>

                <v-btn
                  icon
                  x-small
                  title="Amministrazione"
                  v-if="show_admin_tools == true"
                  @click="dialog_sync_db = true"
                >
                  <v-icon>mdi-database-sync</v-icon>
                </v-btn>
                <v-spacer />
                <v-btn color="error" text @click="logout">LOGOUT</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="1">
          <v-app-bar-nav-icon
            @click.stop="mini2 = !mini2"
            class="hidden-lg-and-up"
          />
        </v-col>
      </v-row>

      -->
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      v-if="voci_menu.length > 0"
      :key="voci_menu.length"
      app
      :mini-variant="mini"
      
      style="
        height: -webkit-fill-available;
        position: fixed;
        top: 48px;
        margin-bottom: 48px;
      "
    >
      <v-list-item class="px-2" rounded>
        <v-list-item-avatar @click="mini = !mini">
          <v-icon v-if="mini">mdi-menu</v-icon>
          <v-icon v-else>mdi-laravel</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Menu</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-menu-open</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        v-for="item in voci_menu"
        v-model="menu_selected"
        :key="item.id_modulo_menu"
        rounded
        class="pb-0 pt-0"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-on="on" v-bind="attrs" link exact :to="item.path">
              <v-list-item-icon class="ml-n2">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  class=""
                  style="text-align: left !important"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="mini2"
      right
      app
      :mini-variant="mini2"
      class=" visible-lg-and-down mt-1"
      style="
        height: -webkit-fill-available;
        position: fixed;
        top: 48px;
        margin-bottom: 48px;
        height: 93vh !important;
      "
    >
      <v-list dense class rounded :key="1">
        <v-menu
          v-model="menu_messaggi"
          :close-on-content-click="false"
          offset-y
          width="50%"
          min-width="550px"
          :close-on-click="!modalAddAvviso"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" @click="apriModalAvvisi">
              <v-list-item-icon class="ml-n2">
                <v-badge
                  v-show="contatore_avvisi > 0"
                  color="primary"
                  :content="contatore_avvisi"
                  overlap
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-badge>
                <v-icon v-show="contatore_avvisi == 0"
                  >mdi-alert-circle-outline</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content></v-list-item-content>
            </v-list-item>
          </template>

          <v-card class="text-center" align="center">
            <v-toolbar dark color="blue-grey darken-2" dense>
              <v-btn
                color="error"
                text
                dark
                fab
                @click="menu_messaggi = false"
                small
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>AVVISI</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    rounded
                    absolute
                    top
                    right
                    class="mt-3"
                    @click="modalAddAvviso = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi avviso</span>
              </v-tooltip>
            </v-toolbar>

            <v-autocomplete
              style="margin-left: 20%; width: 60%"
              v-model="tipo_avviso_selezionato"
              filled
              dense
              class="mt-2"
              rounded
              :items="gruppi"
              item-text="des_gruppo"
              hide-details
              label="Filtra"
            ></v-autocomplete>
            <div style="max-height: 65vh; overflow-y: auto" class="mt-4">
              <div v-for="(avviso, index) in avvisi" :key="index">
                <v-alert
                  v-if="avviso.gruppi.includes('-1')"
                  icon="mdi-alert"
                  class="mr-4 ml-4"
                  prominent
                  text
                  dense
                  color="blue"
                  style="white-space: pre-line"
                  >{{ avviso.testo }}</v-alert
                >
                <v-alert
                  v-else
                  class="mr-4 ml-4"
                  color="blue-grey"
                  dark
                  text
                  dense
                  icon="mdi-school"
                  prominent
                  style="white-space: pre-line"
                  >{{ avviso.testo }}</v-alert
                >
              </div>
            </div>
          </v-card>
        </v-menu>

        <v-list-item link @click="apriUtenti" v-if="menu_dx.UTENTE == '1'">
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item link @click="apriAzienda" v-if="menu_dx.AZIENDA == '1'">
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-city-variant-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-menu
          v-model="menu_gruppi"
          :close-on-content-click="false"
          offset-y
          width="50%"
          min-width="550px"
          v-if="menu_dx.GRUPPI == '1'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon class="ml-n2">
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>

              <v-list-item-content></v-list-item-content>
            </v-list-item>
          </template>

          <GestioneGruppi v-on:chiudi="chiudiMenu"></GestioneGruppi>
        </v-menu>
        <v-list-item
          link
          @click="apriPdfConvert"
          v-if="menu_dx.PDFCONVERT == '1'"
        >
          <v-list-item-icon class="ml-n2">
            <v-icon size="x-large">mdi-file-pdf-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item link @click="apriTicket(0)" v-if="menu_dx.TICKET == '1'">
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-ticket-confirmation</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item link @click="apriTicket(1)" v-if="menu_dx.TICKETA == '1'">
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-ticket-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/home/competenze/"
          v-if="menu_dx.COMPETENZE == '1'"
        >
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-account-check</v-icon>
            <!-- <v-icon>mdi-shield-account</v-icon> -->
            <!-- <v-icon>mdi-card-account-details</v-icon> -->
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/home/cristal-avanzamento"
          v-if="menu_dx.CRISTALPRODUCTION == '1'"
        >
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="apriGantTaskRisorse()"
          v-if="menu_dx.GANTT_TASK_RISORSE == '1'"
        >
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-chart-timeline</v-icon>
            <v-badge
              color="green"
              v-show="$store.state.task_validi > 0"
              top
              :content="$store.state.task_validi"
            ></v-badge>
            <v-badge
              color="green"
              v-show="
                $store.state.task_validi == 0 && $store.state.task_scaduti == 0
              "
              top
              icon="mdi-check"
            ></v-badge>
            <v-badge
              color="red"
              v-show="$store.state.task_scaduti > 0"
              bottom
              :content="$store.state.task_scaduti"
            ></v-badge>
            <!-- <v-icon>mdi-shield-account</v-icon> -->
            <!-- <v-icon>mdi-card-account-details</v-icon> -->
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>

        <v-list-item link to="/home/tools/" v-if="menu_dx.TOOLS == '1'">
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>
        <!--<v-list-item link to="/home/import">
          <v-list-item-icon class="ml-n2">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-file</v-icon>
              </template>
              <span>Import</span>
            </v-tooltip>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>-->
      </v-list>

      <v-list dense class rounded style="position: absolute; bottom: 0">
        <v-spacer></v-spacer>
        <v-list-item
          link
          @click="takeScreenshot()"
          v-if="moduli_sx.filter((el) => el.cod_modulo == 'TICKETA').length > 0"
        >
          <v-list-item-icon class="ml-n2">
            <v-icon>mdi-tag-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>
        <v-list-item
          class="mb-2 ml-n1"
          link
          @click="apriChat"
          v-if="menu_dx.CHAT == '1' && abilita_chat == '1'"
        >
          <v-list-item-icon class="ml-n2">
            <v-badge
              v-show="contatore_messaggi > 0"
              color="primary"
              :content="contatore_messaggi"
              overlap
            >
              <v-avatar size="36">
                <v-img :src="logo_chat" aspect-ratio="1.9"></v-img>
              </v-avatar>
            </v-badge>
            <v-avatar size="36">
              <v-img
                v-show="contatore_messaggi == 0"
                :src="logo_chat"
                aspect-ratio="1.9"
              ></v-img>
            </v-avatar>
          </v-list-item-icon>

          <v-list-item-content></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      
        <router-view></router-view>
     
    </v-main>

    <v-dialog v-model="modalAddAvviso" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Aggiungi avviso:</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="gruppi"
                  label="Gruppi"
                  v-model="gruppi_sel"
                  item-text="des_gruppo"
                  item-value="id_gruppo"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  v-model="des_avviso"
                  label="Testo Avviso"
                  placeholder="...."
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="modalAddAvviso = false">Annulla</v-btn>
          <v-btn color="success" @click="inviaAvviso()">Invia</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="controllo_cambio_rotta" width="500">
      <v-card>
        <v-card-title style="background-color: #546e7a; color: #ffffff"
          >Cambio pagina</v-card-title
        >
        <v-card-text class="pt-5"
          >Sei sicuro di voler cambiare pagina?</v-card-text
        >
        <v-divider></v-divider>
        <div style="padding-right: 10px; text-align: right">
          <v-btn
            class="mr-2 my-2"
            color="error"
            @click="annullaCambioRotta"
            fab
            small
            outlined
          >
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
          <v-btn
            class="my-2"
            color="success"
            @click="confermaCambioRotta"
            fab
            outlined
            small
          >
            <v-icon>mdi-check-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="open_change_screenshot" width="1000">
      <Controllo
        ref="controlloScreen"
        @click_indietro="clearArrrayScreen"
        :destinazione="'inspire_main'"
        :disegni_iniziali="disegni_iniziali"
        :return_array="false"
        :riferimento="0"
        tabella_supporto="disegno"
      />
    </v-dialog>
    <v-dialog v-model="dialog_sync_db" width="500">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Sincronizza database</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="dialog_sync_db = false"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <span style="font-size: 18px"
                  >Vuoi effettuare la sincronizzazione del database? </span
                ><br />
                <span>(la procedura potrebbe richiedere del tempo)</span>
              </v-col>
              <v-col cols="12">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="SyncDB()" small v-on="on" v-bind="attrs">
                      <v-icon color="success">mdi-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>Sincronizza Database</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay absolute :value="caricamento_dati">
      <div class="my-2">
        <v-progress-circular indeterminate> </v-progress-circular>
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
import GlobalToolBar from "@/components/GlobalToolBar.vue";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import GestioneGruppi from "../views/IMPOSTAZIONI/GestioneGruppi";
import logo_chat from "../assets/logo_chat.png";
import * as fb from "../plugins/firebase";
import moment from "moment/moment";
import { saveAs } from "file-saver";
export default {
  components: {
    Controllo,
    GestioneGruppi,
    GlobalToolBar,
  },
  props: {
    source: String,
  },
  data: () => ({
    // SET UP GENERALI
    show_admin_tools: localStorage.show_admin_tools == "true",
    logo_chat: logo_chat,
    snackbar: false,
    snackbar_color: "",
    snackbar_text: "",
    nome_azienda: localStorage.titolo_azienda,
    abilita_chat: localStorage.chat,
    //SET UP MODAL E VARIABILI MODAL
    messages: 2,
    menu_messaggi: false,
    show: true,
    caricamento_dati: false,
    menu: false,
    menu_gruppi: false,
    drawer: null,
    drawerRight: null,
    right: false,
    left: false,
    mini: true,
    menu_selected: null,
    mini2: true,
    modal_invio_messaggi: false,
    open_change_screenshot: false,
    dialog_sync_db: false,

    //SET UP DATI UTILI
    imgTicket: null,
    des_profilo: localStorage.des_profilo,
    nome_utente: localStorage.nominativo,
    abbr_nome: "",
    //LISTA MENU
    voci_menu: [],
    menu_dx: [],
    moduli_sx: [],
    task_validi: 0,
    task_scaduti: 0,

    disegni_iniziali: [],
    //FIREBASE
    contatore_avvisi: 0,
    contatore_messaggi: 0,
    modalAddAvviso: false,
    ultima_lettura: 0,
    id_azienda: -1,
    drawerAvvisi: false,
    gruppi: [],
    gruppi_sel: [],
    avvisi: [],
    des_avviso: "",
    tipo_avviso_selezionato: null,
  }),
  watch: {
    "$store.state.user": function () {
      this.getUsers();
      this.getGruppi();
    },
    "$store.state.menu_sx": function () {
      this.voci_menu = this.$store.state.menu_sx;
      // console.log('Voci Menu',this.voci_menu)
      if (this.voci_menu.length > 0) {
        this.$router.push(this.voci_menu[0].path).catch((e) => {
          console.log(e);
        });
      }
    },
  },
  mounted() {
    this.getCheckTask();
    document
      .getElementById("inspire_main")
      .addEventListener("nuova_img", this.aggiungiImg);
    //SET ABBR NOME
    try {
      this.$store.state.menu_sx = JSON.parse(localStorage.menu);
    } catch (error) {
      // console.log(error);
    }
    this.getInfoAzienda();
    if (localStorage.moduli_dx !== "undefined") {
      this.menu_dx = JSON.parse(localStorage.moduli_dx);
    }
    if (localStorage.moduli_sx !== "undefined") {
      this.moduli_sx = JSON.parse(localStorage.moduli_sx);
    }
    var sup = this.nome_utente.split(" ");
    this.abbr_nome = "";
    sup.forEach((e) => {
      this.abbr_nome += e.toUpperCase().substring(0, 1);
    });
    this.getUsersFb();
    this.getGruppiFb();

    document
      .getElementsByTagName("html")[0]
      .setAttribute("style", "overflow:auto");

    this.task_validi = localStorage.task_validi;
    this.task_scaduti = localStorage.task_scaduti;
  },
  computed: {
    controllo_cambio_rotta() {
      return this.$store.state.conferma_cambio_rotta;
    },
  },
  methods: {
    getCheckTask() {
      let request = {
        controller: "global",
        method: "POST",
        richiesta: {
          action: "getchecktask",
          token: localStorage.user_token,
          id_utente: localStorage.id,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$store.state.task_validi = res.data.result.task_validi;
            this.$store.state.task_scaduti = res.data.result.task_scaduti;
          }
        })
        .catch((e) => {
          if (e.response.status == 400) {
            //this.snackbar = true;
            //this.snackbar_text = "Errore : " + e.response.status;
            //this.caricamento_dati = false;
          }
          console.log(e);
        });
    },
    SyncDB() {
      this.caricamento_dati = true;
      this.dialog_sync_db = false;
      let request = {
        controller: "token",
        method: "POST",
        richiesta: {
          action: "clonadb",
          token: localStorage.user_token,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.caricamento_dati = false;
            const blob = new Blob([res.data.file], { type: "octet/stream" });
            saveAs(blob, res.data.fileName);
            console.log(res.data);
          } else {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = `Criticità rilevata rilevata: codice risposta ${res.status}.`;
            this.snackbar_text_color = "white";
          }
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.snackbar = true;
            this.snackbar_text = "Errore : " + e.response.status;
            this.caricamento_dati = false;
          }
          console.log(e);
        });
    },
    aggiungiImg(messaggio) {
      console.log("mesasggio detail img ", messaggio.detail.img);
      this.$router
        .replace({
          name: "SingleTicketAssistenza",
          params: {
            id: -1,
            tipo_permesso: "0",
            assistenza: 1,
            allegatoEsterno: messaggio.detail.img,
          },
        })
        .catch(() => {});
    },
    clearArrrayScreen() {
      this.open_change_screenshot = false;
      this.disegni_iniziali = [];
    },
    async takeScreenshot() {
      let captureStream = null;
      let video = document.createElement("video");

      let displayMediaOptions = {
        video: {
          cursor: "always",
        },
        audio: false,
      };

      try {
        captureStream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
      } catch (err) {
        console.error("Error: " + err);
      }
      let that = this;
      let callback = function () {
        let track = captureStream.getVideoTracks()[0];
        let settings = track.getSettings();
        let canvas = document.createElement("canvas");
        canvas.width = settings.width;
        canvas.height = settings.height;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, settings.width, settings.height);
        that.imgTicket = canvas.toDataURL();
        video.removeEventListener("canplay", callback);
        video = null;
        track.stop();
        that.open_change_screenshot = true;
        that.disegni_iniziali.push({
          disegno: that.imgTicket,
          id_img: moment().valueOf(),
        });
      };
      video.addEventListener("canplay", callback);
      setTimeout(function () {
        video.srcObject = captureStream;
      }, 500);
      video.play();
    },
    getInfoAzienda() {
      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {
          action: "getinfoazienda",
          token: localStorage.user_token,
          nome_azienda: localStorage.cod_azienda,
        },
      };
      //console.log("getinfoazienda request: ",JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        //console.log("getinfoazienda response: ",JSON.stringify(res));
        if (res.data.length > 0) {
          if (res.data[0].img_azienda != null && res.data[0].img_azienda != "")
            this.img_azienda = res.data[0].img_azienda;
          localStorage.setItem("img_azienda", this.img_azienda);
          if (
            res.data[0].ragione_sociale != null &&
            res.data[0].ragione_sociale != ""
          ) {
            this.nome_azienda = res.data[0].ragione_sociale;
            localStorage.setItem("titolo_azienda", this.titolo_azienda);
          }
          if (
            res.data[0].testo_azienda != null &&
            res.data[0].testo_azienda != ""
          ) {
            this.$store.state.testo_azienda = res.data[0].testo_azienda;
            localStorage.setItem("testo_azienda", res.data[0].testo_azienda);
          }
          if (
            res.data[0].prefisso_utente != null &&
            res.data[0].prefisso_utente != ""
          ) {
            this.$store.state.prefisso_utente = res.data[0].prefisso_utente;
            localStorage.setItem(
              "prefisso_utente",
              res.data[0].prefisso_utente
            );
            console.log(res.data[0].prefisso_utente);
          }
        }
      });
    },
    annullaCambioRotta() {
      history.pushState({}, null, this.$store.state.rotta_from.path);
      this.$store.state.conferma_cambio_rotta = false;
    },
    confermaCambioRotta() {
      this.$store.state.conferma_cambio_rotta = false;
      this.$store.state.check_paginaAttuale = 0;
      this.$router.push(this.$store.state.rotta_to.path);
    },
    apriDashboard() {
      this.$router.push("/home/dashboard").catch(() => {});
      localStorage.setItem("menu", []);
      this.$store.state.menu_sx = [];
    },
    chiudiMenu() {
      this.menu_gruppi = false;
    },
    apriUtenti() {
      this.$router.replace("/home/gestioneutenti").catch(() => {});
    },
    apriPdfConvert() {
      this.$router.replace("/home/pdfconvert").catch(() => {});
    },
    apriGantTaskRisorse() {
      this.$router.replace("/home/consuntivo_risorse").catch(() => {});
    },
    apriTicket(assistenza) {
      if (assistenza == 1) {
        this.$router.replace("/home/ticketassistenza").catch(() => {});
      } else {
        this.$router.replace("/home/ticket").catch(() => {});
        this.$store.state.menu_sx = [];
      }
    },
    apriAzienda() {
      this.$router.replace("/home/gestioneazienda").catch(() => {});
    },
    apriChat() {
      this.$router.replace("/home/chat").catch(() => {});
    },
    async getGruppiFb() {
      if (this.$store.state.user.data != null) {
        fb.db.collection("t_gruppo").onSnapshot((querySnapshot) => {
          var gruppi = querySnapshot.docs.map((doc) => doc.data());
          if (gruppi != undefined) {
            this.gruppi = gruppi;
          }
          this.gruppi.unshift({
            des_gruppo: "Tutti gli utenti",
            cod_gruppo: "Tutti gli utenti",
            id_gruppo: -1,
          });
        });
      }
    },
    getUsersFb() {
      if (this.$store.state.user.data != null) {
        fb.db
          .collection("utenti")
          .where("id", "==", this.$store.state.user.data.id)
          .get()
          .then((querySnapshot) => {
            var utente = querySnapshot.docs.map((doc) => doc.data());
            if (utente.length > 0) {
              if (utente[0].ultima_lettura == undefined) {
                this.ultima_lettura = 0;
              } else {
                this.ultima_lettura = utente[0].ultima_lettura;
              }
              this.id_azienda = utente[0].id_azienda;
            }
            this.getAvvisi(parseInt(this.id_azienda));
            this.getUltimiMex();
          });
      }
    },
    getUltimiMex() {
      fb.db
        .collection("ultimi_messaggi")
        .where("idTo", "==", this.$store.state.user.data.id)
        .onSnapshot((querySnapshot) => {
          var mess = querySnapshot.docs.map((doc) => doc.data());
          mess.forEach((element) => {
            this.contatore_messaggi = 0;
            if (element.stato == 1) this.contatore_messaggi++;
          });
        });
    },
    getAvvisi(id_azienda) {
      try {
        fb.db
          .collection("avvisi")
          .where("id_azienda", "==", id_azienda)
          .orderBy("timestamp", "desc")
          .onSnapshot((querySnapshot) => {
            this.avvisi = querySnapshot.docs.map((doc) => doc.data());
            this.avvisi.forEach((element) => {
              if (parseInt(element.timestamp) > parseInt(this.ultima_lettura)) {
                this.contatore_avvisi++;
              }
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    apriModalAvvisi() {
      const now = new Date();
      //setto ultima vista messaggi ad adesso
      fb.db
        .collection("utenti")
        .doc(this.$store.state.id_utente)
        .update({ ultima_lettura: now.getTime().toString() })
        .then(() => {
          this.contatore_avvisi = 0;
          this.ultima_lettura = now.getTime().toString();
        });
      // If you need the doc id, use `doc.id`
    },
    inviaAvviso() {
      if (
        this.des_avviso == "" ||
        this.des_avviso == " " ||
        this.des_avviso == undefined
      ) {
        this.snackbar_text = "INSERIRE UN MESSAGGIO VALIDO";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }
      var test = this.des_avviso.replace(/(\r\n|\n|\r)/gm, "");
      if (test == "" || test == " ") {
        this.snackbar_text = "INSERIRE UN MESSAGGIO VALIDO";
        this.snackbar_color = "error";
        this.snackbar = true;
        this.des_avviso = "";

        return;
      }
      // se ad un gruppo metto un altro
      const now = new Date();
      //const secondsSinceEpoch = now.getTime();
      fb.db
        .collection("avvisi")
        .doc(now.getTime().toString())

        .set({
          id_azienda: this.id_azienda,
          testo: this.des_avviso,
          timestamp: now.getTime().toString(),
          gruppi: this.gruppi_sel.toString(),
          type: 0,
          stato: 1,
        })
        .then(() => {
          this.des_avviso = "";
          this.modalAddAvviso = false;
          this.snackbar_text = "Avviso inviato";
          this.snackbar = true;
          this.snackbar_color = "success";
          this.ultima_lettura = now.getTime().toString();
          this.contatore_avvisi = 0;
          this.gruppi_sel = [];
        })
        .catch(function (error) {
          this.des_avviso = "";
          this.snackbar_text = "Errore";
          this.snackbar = true;
          this.snackbar_color = "error";
          this.ultima_lettura = now.getTime().toString();
          this.contatore_avvisi = 0;
          this.gruppi_sel = [];
          console.error("Error writing document: ", error);
        });
      this.messaggio = "";
    },
    logout() {
      localStorage.removeItem("user_token");
      localStorage.removeItem("id");
      localStorage.removeItem("nominativo");
      localStorage.removeItem("des_profilo");
      localStorage.removeItem("id_profilo");
      localStorage.removeItem("des_azienda");
      localStorage.removeItem("id_azienda");
      fb.firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/login");
        })
        .catch(function () {});
    },
    invia_messaggi() {
      this.menu_messaggi = false;
      this.modal_invio_messaggi = true;
    },
  },
};
</script>