<template >
  <v-container>
    <v-row class>
      <v-snackbar :color="colore_snack" v-model="snackbar">
        {{ this.messaggio_snackbar }}
        <v-btn color="white" text @click="snackbar = false">Chiudi</v-btn>
      </v-snackbar>
      <v-col cols="12" md="4" lg="2"></v-col>
      <v-col cols="12" md="8" lg="8">
        <v-card class="mx-auto" color>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Tabella Aziende</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark color="green" @click="nuovaAzienda"
                >Nuova Azienda</v-btn
              >
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="aziende"
            class="elevation-1"
            style="overflow-y: auto; max-height: 700px; cursor: pointer"
            @click:row="selezionaAzienda"
            :footer-props="{
              disablePagination: true,
              disableItemsPerPage: true,
            }"
            :hide-default-footer="true"
            disable-pagination
          >
            <template v-slot:item.action="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="blue"
                    @click="setAzienda(item)"
                    v-on="on"
                  >
                    <v-icon medium>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifica</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="modal_azienda" max-width="40%" persistent>
        <v-card>
          <v-card-title>{{ titolo_modal_aziende }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-card class="mx-auto" outlined min-height="350px">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4">DATI</div>
                        <v-row>
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="azienda_sel.ragione_sociale"
                              label="Ragione Sociale"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="azienda_sel.indirizzo"
                              :counter="20"
                              label="Indirizzo"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="7">
                            <v-text-field
                              :readonly="arrivo_azienda == 1"
                              v-model="azienda_sel.codice"
                              :counter="20"
                              label="Cod. Azienda"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="azienda_sel.localita"
                              :counter="20"
                              label="Localita"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="azienda_sel.provincia"
                              :counter="2"
                              label="Provincia"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="7">
                            <v-text-field
                              v-model="azienda_sel.email"
                              :counter="20"
                              label="Email"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>

              <v-row></v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="modal_azienda = false"
              >Annulla</v-btn
            >
            <v-btn color="blue darken-1" text @click="setAziendaConferma()"
              >Conferma</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<style scoped>
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 8px;
}
.colore_card_utente {
  background: linear-gradient(
    118deg,
    rgba(92, 107, 192, 1),
    rgba(123, 136, 212, 0.7)
  ) !important;
}
</style>
<script>
import axios from "axios";
import config from "../../../public/config";
export default {
  components: {},
  data: () => ({
    config: config,
    headers: [],
    aziende: [],
    azienda_sel: {},
    modal_azienda: false,
    titolo_modal_aziende: "Aggiungi Azienda",
    arrivo_azienda: 0,
    modal_sel_azienda: false,
    messaggio_snackbar: "",
    colore_snack: "green",
    snackbar: false,
  }),
  watch: {},
  mounted() {
    this.getAziende();
  },
  methods: {
    getAziende() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "getaziende",
        token: localStorage.user_token,
        idUtente: parseInt(localStorage.id),
        dbName: "medical",
      };
      console.log(finale);
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/User", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response);
          this.headers = response.data.header;
          this.aziende = response.data.aziende;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          console.log(e);
        });
    },
    setAzienda(item) {
      console.log(item);
      this.titolo_modal_aziende = "Modifica Azienda";
      this.azienda_sel = item;
      this.arrivo_azienda = 1;
      this.modal_azienda = true;
    },
    setAziendaConferma() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var tabella_send = [];
      if (this.arrivo_azienda == 0) {
        this.azienda_sel.id_anagrafica = -1;
      }
      tabella_send.push(this.azienda_sel);
      var finale = {
        action: "putsetazienda",
        token: localStorage.user_token,
        idUtente: parseInt(localStorage.id),
        tabella: tabella_send,
        dbName: "medical",
      };
      console.log("set");
      console.log(finale);
      var data = JSON.stringify(finale);
      axios
        .put(this.$store.state.config.ip + "api/User", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response);
          if (this.arrivo_azienda == 0) {
            this.azienda_sel.id_anagrafica = response.data;
            this.aziende.push(this.azienda_sel);
            this.messaggio_snackbar = "Azienda aggiunta con successo";
          } else {
            this.messaggio_snackbar = "Azienda modificata con successo";
          }
          this.modal_azienda = false;
          this.colore_snack = "green";
          this.snackbar = true;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          this.messaggio_snackbar = "Errore";
          this.colore_snack = "red";
          this.snackbar = true;
          console.log(e);
        });
    },
    selezionaAzienda(item) {
      this.azienda_sel = item;
    },
    change_dialog_azienda() {
      this.modal_sel_azienda = false;
    },
    nuovaAzienda() {
      this.titolo_modal_aziende = "Aggiungi Nuova Azienda";
      this.arrivo_azienda = 0;
      this.azienda_sel = {};
      this.modal_azienda = true;
    },
  },
};
</script>