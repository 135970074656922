<template>
  <div style="padding: 20px">
    <v-card>
      <v-toolbar
        class="indigo white--text"
        dense
        primary-title
      >
        <v-toolbar-title>Movimenti Magazzino</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="toggleFilters"
            >
              <v-icon color="purple">
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
          <span>Filtri</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="$router.push({ name: 'giacenze-magazzino' })"
            >
              <v-icon class="error--text">
                mdi-keyboard-backspace
              </v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
        id="progress"
      >
      </v-progress-linear>

      <v-card-text>
        <v-row v-if="show_filters">
          <v-col>
            <v-row>
              <v-col>
                <v-menu
                  ref="menu-da"
                  v-model="menu_da"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="data_start_formatted"
                      label="Da"
                      autocomplete="off"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data_start"
                    no-title
                    locale="it"
                    @input="menu_da = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  ref="menu-a"
                  v-model="menu_a"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="data_end_formatted"
                      label="A"
                      autocomplete="off"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data_end"
                    no-title
                    locale="it"
                    @input="menu_a = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-select
              label="User"
              dense
              outlined
              v-model="user"
              :items="users"
              item-text="des_utente"
              item-value="des_utente"
            >
            </v-select>
          </v-col>
          <v-col cols="auto">
            <v-btn
              outlined
              color="#2196f3"
              @click="callList">
              Search
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="lista_tabella">
              <template v-slot:item.posizione="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      elevation="1"
                      @click="openPosizioneMagazzino(item)"
                    >
                      <v-icon color="#1a80b6">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Dettaglio posizione</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-if="currentItem" v-model="posizione_magazzino_visible" width="800">
      <v-card>
        <v-toolbar class="indigo white--text" dense>
          <v-toolbar-title class="white--text">
            Posizione
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="posizione_magazzino_visible = false"
                small
              >
                <v-icon class="error--text">
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pa-0 pt-3">
          <v-container>
            <v-row>
              <v-col cols="6">
                <div class="text-h5">Magazzino partenza</div>
                <v-subheader class="px-0">{{ currentItem.posizionepartenza }}</v-subheader>
              </v-col>
              <v-col cols="6">
                <div class="text-h5">Magazzino arrivo</div>
                <v-subheader class="px-0">{{ currentItem.posizionearrivo }}</v-subheader>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'MovimentiMagazzino',
  props: ['id_magazzino', 'id_articolo'],
  data() {
    return {
      currentItem: null,
      loading: false,
      show_filters: true,
      posizione_magazzino_visible: false,
      menu_a: false,
      menu_da: false,
      codice_articolo: null,
      descrizione_articolo: null,
      data_start: null,
      data_end: null,
      data_start_formatted: null,
      data_end_formatted: null,
      user: null,
      headers: [
        {
          text: 'Posizione',
          value: 'posizione',
          sortable: false,
        },
        {
          text: 'Data',
          value: 'data',
        },
        {
          text: 'Cod. articolo',
          value: 'cod_articolo',
        },
        {
          text: 'Des. articolo',
          value: 'des_articolo',
        },
        {
          text: 'Num. documento',
          value: 'num_documento',
        },
        {
          text: 'Des. documento',
          value: 'des_documento',
        },
        {
          text: 'Cod. lotto',
          value: 'cod_lotto',
        },
        {
          text: 'Qnt',
          value: 'quantita',
        },
        {
          text: 'User',
          value: 'des_utente',
        },
      ],
      lista_tabella: [],
      movimentiMagazzino: [],
    }
  },
  watch: {
    data_start(val) {
      this.data_start_formatted = this.formatDate(val);
    },
    data_end(val) {
      this.data_end_formatted = this.formatDate(val);
    },
  },
  computed: {
    users() {
      let users = [];
      users.push({ des_utente: "Tutti" });
      this.movimentiMagazzino.map((item) => users.push(item));

      return users;
    },
  },
  mounted() {
    this.data_start = moment().subtract(1, 'months').format('YYYY-MM-DD');
    this.data_end = moment().format('YYYY-MM-DD');

    this.callList();
  },
  methods: {
    async callList() {
      this.loading = true;
      this.movimentiMagazzino = [];

      await this.getArticolo();

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolomovimentimagazzino",
          token: localStorage.user_token,
          id_articolo: this.id_articolo,
          id_magazzino: this.id_magazzino,
          data_da: this.data_start,
          data_a: this.data_end,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.movimentiMagazzino = res.data;

        this.movimentiMagazzino.forEach((item) => {
          item.cod_articolo = this.codice_articolo;
          item.des_articolo = this.descrizione_articolo;
        });

        this.lista_tabella = this.movimentiMagazzino;

        this.loading = false;
      });
    },
    getArticolo() {
      return new Promise((resolve) => {
        let request = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticoli",
            token: localStorage.user_token,
            id_articolo: this.id_articolo,
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.codice_articolo = res.data.articolo[0].cod_articolo;
          this.descrizione_articolo = res.data.articolo[0].des_articolo;
          resolve();
        });
      });
    },
    formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    openPosizioneMagazzino(item) {
      this.currentItem = item;
      this.posizione_magazzino_visible = true;
    },
    toggleFilters() {
      this.show_filters = !this.show_filters;
    },
  },
};
</script>
