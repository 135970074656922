<template>
  <div style="padding: 20px" id="listener_produzione">
    <v-row justify="center">
      <Carrello
        @aggiornamento_richiesto="getWorkOrdersUtente(null)"
        @click_aggiungi="getWorkOrders()"
        @click_controllo="clickControllo"
        @click_gestione_lavorazione="clickGestioneLavorazione"
        @click_prelievi="clickPrelievi"
        @click_registra="clickRegistra"
        @click_visualizza_disegno="clickVisualizzaDisegno"
        :cod_macchina_default="cod_macchina_default"
        :items_carrello="commesse_in_corso"
        :macchine_lista="macchine_lista"
        @prima_commessa="getWorkOrders()"
        ref="carrello"
        :reparti_lista="reparti_lista"
        :reparto_default="reparto_default"
        style="width: 98.5%"
        :ultimo_aggiornamento="ultimo_aggiornamento"
      />
    </v-row>
    <v-row class="mt-5" justify="center">
      <v-col cols="6">
        <TempiDiLavorazione
          @click_gestione_lavorazione="clickGestioneLavorazione"
          class="pl-2"
          :contatore="contatore_tempi_di_lavorazione"
        />
      </v-col>
      <v-col cols="6">
        <PezziRegistrati :visibile="true" />
      </v-col>
    </v-row>
    <v-dialog v-model="consuntivo_commesse_visible">
      <ConsuntivoCommesse
        @click_chiudi="consuntivo_commesse_visible = false"
        :cod_macchina_sel="cod_macchina_sel"
        @inviato_elemento="consuntivo_commesse_visible = false"
        :items_totali="commesse_totali"
        :macchine_lista="macchine_lista"
        :reparti_lista="reparti_lista"
        :reparto_sel="reparto_sel"
        v-if="consuntivo_commesse_visible == true"
        :visibile="consuntivo_commesse_visible"
      />
    </v-dialog>
    <v-dialog persistent v-model="prelievi_visible">
      <Prelievi
        @click_chiudi="prelievi_visible = !prelievi_visible"
        @click_prosegui="
          (prelievi_visible = !prelievi_visible),
            (gestione_lavorazione_visible = !gestione_lavorazione_visible)
        "
        :fase_sel="fase_sel"
        :visibile="prelievi_visible"
      />
    </v-dialog>
    <v-dialog persistent width="700px" v-model="gestione_lavorazione_visible">
      <GestioneLavorazione
        @click_chiudi="
          gestione_lavorazione_visible = !gestione_lavorazione_visible
        "
        @click_start="clickStart"
        @click_stop="clickStop"
        :fase_sel="fase_sel"
        :visibile="gestione_lavorazione_visible"
      />
    </v-dialog>
    <v-dialog persistent v-model="registra_visible">
      <Registra
        @click_chiudi="registra_visible = !registra_visible"
        @eseguita_registrazione="eseguitaRegistrazione"
        :fase_sel="fase_sel"
        :visibile="registra_visible"
      />
    </v-dialog>
    <v-dialog persistent v-model="visualizza_disegno_visible">
      <VisualizzaDisegno
        @click_chiudi="validaDisegno"
        :visible="visualizza_disegno_visible"
        :work_order_sel="fase_sel"
      />
    </v-dialog>
    <!-- Dialog del controllo dei parametri dell'articolo -->
    <div
      style="
        background-color: rgba(33, 33, 33, 0.46);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        width: 100%;
      "
      v-if="controllo_qualita_visible"
    >
      <div
        style="
          height: 90%;
          left: 5%;
          position: absolute;
          top: 5%;
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          width: 90%;
        "
      >
        <ControlloQualita
          @click_chiudi="controllo_qualita_visible = !controllo_qualita_visible"
          @eseguito_controllo="eseguitoControllo"
          :visible="controllo_qualita_visible"
          :work_order_sel="fase_sel"
        />
      </div>
    </div>

    <v-dialog v-model="avviso_controllo_visible" width="unset" persistent>
      <v-card>
        <v-toolbar color="error" dense>
          <v-row align="center">
            <v-col cols="5" align="left">
              <v-toolbar-title class="white--text"> Avviso </v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      (avviso_controllo_visible = false), (stop_timer = false)
                    "
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="success"> mdi-check </v-icon>
                  </v-btn>
                </template>
                <span>Conferma</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text style="padding-bottom: 80px; padding-top: 80px">
          <span style="font-size: 40px"
            ><b> È necessario eseguire i controlli di produzione </b>
          </span>
          <v-divider class="my-5"></v-divider>
          <span style="font-size: 40px; margin-top: 20px"
            ><b>
              Cliccare sul pulsante
              <v-icon color="error" style="font-size: 40px"
                >mdi-alpha-c-circle</v-icon
              >
            </b>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import moment from "moment/moment";
import Carrello from "@/components/PRODUCTION/Produzione/Carrello.vue";
import ConsuntivoCommesse from "@/components/PRODUCTION/Produzione/ConsuntivoCommesse.vue";
import ControlloQualita from "@/components/PRODUCTION/Produzione/ControlloQualita.vue";
import TempiDiLavorazione from "@/components/PRODUCTION/Produzione/TempiDiLavorazione.vue";
import PezziRegistrati from "@/components/PRODUCTION/Produzione/PezziRegistrati.vue";
import Prelievi from "@/components/PRODUCTION/Produzione/Prelievi.vue";
import GestioneLavorazione from "@/components/PRODUCTION/Produzione/GestioneLavorazione.vue";
import Registra from "@/components/PRODUCTION/Produzione/Registra.vue";
import VisualizzaDisegno from "@/components/PRODUCTION/ProduzioneOnline/VisualizzaDisegno.vue";

export default {
  components: {
    Carrello,
    ConsuntivoCommesse,
    ControlloQualita,
    PezziRegistrati,
    TempiDiLavorazione,
    Prelievi,
    GestioneLavorazione,
    Registra,
    VisualizzaDisegno,
  },
  data() {
    return {
      // Variabili di appoggio
      cod_macchina_default: "",
      contatore_tempi_di_lavorazione: 0,
      fase_sel: null,
      caricamento_dati: false,
      id_ciclo_dettaglio: 0,
      reparto_default: "",
      stop_timer: false,
      ultimo_aggiornamento: "",

      // Modal
      avviso_controllo_visible: false,
      consuntivo_commesse_visible: false,
      controllo_qualita_visible: false,
      filtri_visibili: true,
      gestione_lavorazione_visible: false,
      prelievi_visible: false,
      registra_visible: false,
      visualizza_disegno_visible: false,

      // Array
      commesse_in_corso: [],
      commesse_totali: [],
      macchine_lista: [],
      reparti_lista: [],
    };
  },
  computed: {
    cod_macchina_sel() {
      return this.$refs.carrello.cod_macchina_sel;
    },
    reparto_sel() {
      return this.$refs.carrello.reparto_sel;
    },
  },
  methods: {
    aggiungiCommessa(messaggio) {
      // Controllo che la commessa non sia già nel carrello.
      let ricorre = false;

      this.commesse_in_corso.forEach((elem) => {
        if (
          elem.id_commessa_dettaglio ==
          messaggio.detail.commessa_sel.id_commessa_dettaglio
        ) {
          ricorre = true;
        }
      });

      // Stampo errore se la commessa è già nel carrello.
      if (ricorre == true) {
        this.snackbar = true;
        this.snackbar_background = "grey lighten-3";
        this.snackbar_icon = "mdi-information-outline";
        this.snackbar_icon_color = "indigo";
        this.snackbar_text = "Work Order già presente nel carrello.";
        this.snackbar_text_color = "#3F51B5";
      } else {
        /* Se è tutto ok aggiungo la commessa al carrello e apro
         * la finestra di evasione della commessa.*/
        let WO_da_aggiungere = Object.assign({}, messaggio.detail.commessa_sel);

        this.commesse_in_corso.push(WO_da_aggiungere);

        /* Se il work order ha un disegno e non l'ho ancora visto,
         * faccio partire automaticamente la visualizzazione del sisegno.*/
        /*if (
          WO_da_aggiungere.id_disegno != null &&
          WO_da_aggiungere.view_disegno == 0
        ) {
          this.clickVisualizzaDisegno(WO_da_aggiungere);
        }*/
      }
    },
    clickGestioneLavorazione(item) {
      this.fase_sel = item;
      this.gestione_lavorazione_visible = true;
    },
    clickControllo(item) {
      this.fase_sel = item;
      this.controllo_qualita_visible = true;
    },
    clickPrelievi(item) {
      this.fase_sel = item;
      this.prelievi_visible = true;
    },
    clickRegistra(item) {
      this.fase_sel = item;
      this.registra_visible = true;
    },
    clickStart() {
      // Chiudo il dialog di gestione della lavorazione
      this.gestione_lavorazione_visible = false;

      // Aggiorno i dati
      this.getWorkOrdersUtente();
    },
    clickStop(commessa) {
      // Chiudo il dialog di gestione della lavorazione
      this.gestione_lavorazione_visible = false;

      // Ricarico i dati dal BE
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersutente",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Chiudo l'overlay di caricamento
          this.caricamento_dati = false;

          // Svuoto l'array che contiene le commesse in gestione
          this.commesse_in_corso = [];

          // Popolo l'array che contiene le commesse in gestione
          res.data.workorders.forEach((elem) => {
            this.commesse_in_corso.push(elem);
          });

          this.filtri_visibili = res.data.filtro_visibile == 0 ? false : true;
          this.cod_macchina_default =
            res.data.cod_macchina == null ? "" : '';
          this.reparto_default =
            res.data.des_reparto == null ? "" : res.data.des_reparto;

          this.commesse_in_corso.forEach((elem) => {
            if (elem.cod_fase == commessa.cod_fase) {
              this.clickRegistra(elem);
            }
          });
        } else {
          this.snackbar = true;
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile aggiornare i dati: codice errore ${res.status}.`;
          this.snackbar_text_color = "white";
        }
      });

      /* Modifico il valore di un contatore in modo
       * da determinare un aggiornamento dei dati della
       * tabella Tempi di Lavorazione. */
      this.contatore_tempi_di_lavorazione =
        this.contatore_tempi_di_lavorazione === 0 ? 1 : 0;
    },
    clickVisualizzaDisegno(item) {
      if (item.view_disegno == 0) {
        /* Se il disegno non è ancora stato visualizzato mando
         * una chiamata al BE e apro il dialog. */
        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setviewdisegno",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.getItem("id"),
            id_disegno: item.id_disegno,
            db_name: "quickproduction",
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          if (res.status === 200) {
            this.visualizza_disegno_visible = true;
            this.fase_sel = Object.assign({}, item);
          } else {
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_icon = "mdi-close";
            this.snackbar_icon_color = "white";
            this.snackbar_text = `Non è stato possibile caricare i dati: codice errore ${res.status}.`;
            this.snackbar_text_color = "white";
          }
        });
      } else {
        /* Se il disegno è già stato visualizzato apro
         * il dialog semplicementa, senza chiamare il BE. */
        this.visualizza_disegno_visible = true;
        this.fase_sel = Object.assign({}, item);
      }
    },
    eseguitaRegistrazione() {
      this.getWorkOrdersUtente(null);
      this.registra_visible = false;
    },
    eseguitoControllo() {
      this.getWorkOrdersUtente(null);
      this.controllo_qualita_visible = false;
    },
    getWorkOrders() {
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkorders",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Svuoto l'array che contiene le commesse in gestione
          this.commesse_totali = [];

          // Popolo l'array che contiene i dati totali
          res.data.forEach((elem) => {
            this.commesse_totali.push(elem);
          });

          // Apro il dialog delle fasi avviabili.
          this.consuntivo_commesse_visible = true;
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile caricare i dati: codice errore ${res.status}.`;
          this.snackbar_text_color = "white";
        }

        // Fermo l'overlay di caricamento.
        this.caricamento_dati = false;
      });
    },
    getWorkOrdersUtente(arg) {
      if (this.stop_timer) {
        return;
      }

      this.caricamento_dati = true;
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersutente",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: arg,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Mi segno la data dell'ultimo aggiornamento
          this.ultimo_aggiornamento = moment().format("DD/MM/YYYY HH:mm");

          // Svuoto l'array che contiene le fasi in gestione
          this.commesse_in_corso = [];

          // Popolo l'array che contiene i dati delle fasi in lavorazione
          res.data.workorders.forEach((elem) => {
            this.commesse_in_corso.push(elem);
          });

          this.filtri_visibili = res.data.filtro_visibile == 0 ? false : true;
          this.cod_macchina_default =
            res.data.cod_macchina == null ? "" : '';
          this.reparto_default =
            res.data.des_reparto == null ? "" : res.data.des_reparto;

          this.caricamento_dati = false;

          this.commesse_in_corso.forEach((elem) => {
            if (elem.controllo === 1) {
              this.stop_timer = true;
              this.avviso_controllo_visible = true;
            }
          });
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile caricare i dati: codice errore ${res.status}.`;
          this.snackbar_text_color = "white";
        }
      });

      this.contatore_tempi_di_lavorazione =
        this.contatore_tempi_di_lavorazione === 0 ? 1 : 0;
    },
    getMacchineReparti() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistamacchine",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          let reparti_lista_doppia_tmp = Array();

          res.data.forEach((elem) => {
            // Inserisco la macchina nella lista delle macchine.
            this.macchine_lista.push(elem);

            // Inserisco il reparto nel collettore dei reparti.
            reparti_lista_doppia_tmp.push(elem.des_reparto);
          });

          // Elimino i reparti doppi e valorizzo l'array ufficiale dei reparti.
          this.reparti_lista = [...new Set(reparti_lista_doppia_tmp)];
        }
      });
    },
    validaDisegno(arg) {
      // Chiudo il dialog di visualizzazione dei disegni.
      this.visualizza_disegno_visible = false;

      /* Metto a 1 la visione del disegno per tutti
       * gli oggetti che hanno questo id_ disegno. */
      this.commesse_in_corso.forEach((elem) => {
        if (elem.id_disegno == arg.id_disegno) {
          elem.view_disegno = 1;
        }
      });
    },
  },
  mounted() {
    //let that = this;

    /*setInterval(function () {
      if (that.stop_timer === false) {
        that.getWorkOrdersUtente(null);
      }
    }, 30000);*/

    this.getWorkOrdersUtente(null);
    this.getMacchineReparti();

    document
      .getElementById("listener_produzione")
      .addEventListener("clicked_row", this.aggiungiCommessa);
  },
};
</script>