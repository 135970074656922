import { render, staticRenderFns } from "./Bacheca.vue?vue&type=template&id=96293d66&scoped=true&"
import script from "./Bacheca.vue?vue&type=script&lang=js&"
export * from "./Bacheca.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96293d66",
  null
  
)

export default component.exports