<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Anagrafica Barche</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickNuovaBarca" small v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Barca</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Anagrafica Barche -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goBack" small v-bind="attrs" v-on="on">
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <template>
      <div>
        <v-data-table
          :headers="headers_barche"
          :items="items_barche_visualizzate"
          item-key="nome"
          class="elevation-1"
          height="75vh"
        >
          <!-- Bottone di modifica della barca -->
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaBarca(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary"> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Modifica Richiesta</span>
            </v-tooltip>
          </template>

          <!-- Colonna della data consegna -->
          <template v-slot:[`item.data_acquisto`]="{ item }">
            {{item.data_acquisto === '' ? '' :
              `${item.data_acquisto.substring(
                8,
                10
              )}/${item.data_acquisto.substring(
                5,
                7
              )}/${item.data_acquisto.substring(0, 4)}`
            }}
          </template>

          <!-- Colonna della dell'inizio garanzia -->
          <template v-slot:[`item.inizio_garanzia`]="{ item }">
            {{item.inizio_garanzia === '' ? '' :
              `${item.inizio_garanzia.substring(
                8,
                10
              )}/${item.inizio_garanzia.substring(
                5,
                7
              )}/${item.inizio_garanzia.substring(0, 4)}`
            }}
          </template>

          <!-- Colonna della fine garanzia -->
          <template v-slot:[`item.fine_garanzia`]="{ item }">
            {{item.fine_garanzia === '' ? '' : 
              `${item.fine_garanzia.substring(
                8,
                10
              )}/${item.fine_garanzia.substring(
                5,
                7
              )}/${item.fine_garanzia.substring(0, 4)}`
            }}
          </template>

          <!-- Bottone di eliminazione della barca -->
          <template v-slot:[`item.elimina`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickElimina(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Elimina</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </template>
    <v-dialog v-model="modifica_barche">
      <ModificaBarca
        :barca_sel="barca_sel"
        @chiudi_dialog="chiudiDialog"
        :id_filtro_nave="id_filtro_nave"
        @salvata_macchina="handleSalvataMacchina"
        :visible="modifica_barche"
      />
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>

<script>
import ModificaBarca from "@/components/ELECTRICALMARINE/ModificaBarca.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

class Barca {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "autonoma", default_val: 0 },
      { nome: "cod_macchina", default_val: "" },
      { nome: "costo_orario", default_val: 0 },
      { nome: "data_acquisto", default_val: "" },
      { nome: "data_consegna", default_val: "" },
      { nome: "des_connessione", default_val: "" },
      { nome: "des_macchina", default_val: "" },
      { nome: "des_stato", default_val: "" },
      { nome: "modello", default_val: "" },
      { nome: "matricola", default_val: "" },
      { nome: "id_centrocosto", default_val: null },
      { nome: "id_funzione", default_val: null },
      { nome: "id_macchina", default_val: -1 },
      { nome: "id_reparto", default_val: null },
      { nome: "id_stato", default_val: null },
      { nome: "id_tipo_get_macchina", default_val: null },
      { nome: "id_utente", default_val: localStorage.id },
      { nome: "inizio_garanzia", default_val: "" },
      { nome: "fine_garanzia", default_val: "" },
      { nome: "macchina_img", default_val: "" },
      { nome: "marca", default_val: "" },
      { nome: "matricola", default_val: "" },
      { nome: "modello", default_val: "" },
      { nome: "multi_commessa", default_val: 0 },
      { nome: "ore_disponibilita", default_val: 0 },
      { nome: "schedula", default_val: 0 },
      { nome: "specifiche", default_val: "" },
      { nome: "tipo_connessione", default_val: 0 },
      { nome: "validita", default_val: 1 },
    ];

    return campi;
  }
}

export default {
  name: "AnagraficaBarche",
  components: {
    ModificaBarca,
    SnackbarQV,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      id_filtro_nave: null,
      modifica_barche: false,
      barca_sel: new Barca(),

      // Modal

      // Array
      items_barche_totali: [],
      items_barche_visualizzate: [],
      headers_barche: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "Matricola",
          value: "matricola",
          sortable: false,
        },
        {
          text: "Nome Barca",
          value: "des_macchina",
          sortable: false,
        },
        {
          text: "Modello",
          value: "modello",
          sortable: false,
        },
        {
          text: "Inizio Garanzia",
          value: "inizio_garanzia",
          sortable: false,
        },
        {
          text: "Fine Garanzia",
          value: "fine_garanzia",
          sortable: false,
        },
        {
          text: "Data Consegna",
          value: "data_acquisto",
          sortable: false,
        },
        {
          text: "Eliminazione",
          value: "elimina",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    chiudiDialog() {
      this.modifica_barche = false;
      this.barca_sel = new Barca();
    },
    clickModificaBarca(item) {
      this.barca_sel = new Barca(item);
      this.modifica_barche = true;
    },
    clickElimina(item) {
      this.barca_sel = new Barca(item);
      this.barca_sel.validita = 0;

      // Faccio la query di salvataggio
      const dataarray = [];
      dataarray.push(Object.assign({}, this.barca_sel));
      const request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatemacchina",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.getMacchine();
        } else {
          this.$refs.SnackbarQV.print(
            "orange",
            `Criticità tecnica con codice ${res.status}, non è stato possibile salvare i dati.`
          );
        }
      });
    },
    clickNuovaBarca() {
      this.barca_sel = new Barca();
      this.modifica_barche = true;
    },
    goBack() {
      this.$router.push("/home/naval");
    },
    getMacchine() {
      /* Faccio una gettabelle per ricevere l'id dell'etichetta
       * che mi serve per identificare le macchine che sono navi. */
      let gettabelle = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "TIPO_GET_MACCHINA",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", gettabelle).then((res) => {
        if (res.status === 200) {
          // Valorizzo l'id_filtro nave per riconoscere le macchine che sono navi
          res.data.forEach((e) => {
            if (e.cod === "NAVE") {
              this.id_filtro_nave = e.id_tabella;
            }
          });

          // Faccio una chiamata per avere tutte le macchine
          const getlistamacchine = {
            controller: "Manutenzione",
            method: "POST",
            richiesta: {
              action: "getlistamacchine",
              token: localStorage.getItem("user_token"),
            },
          };

          this.$store.dispatch("api", getlistamacchine).then((res) => {
            if (res.status === 200) {
              // Resetto gli array di supporto.
              this.items_barche_totali = new Array();
              this.items_barche_visualizzate = new Array();

              // Per ogni macchina, se la riconosco come barca la aggiungo al mio array.
              res.data.forEach((e) => {
                if (
                  this.id_filtro_nave != null &&
                  e.id_tipo_get_macchina === this.id_filtro_nave
                ) {
                  const macchina = new Barca(e);
                  this.items_barche_totali.push(Object.assign({}, macchina));
                }
              });

              // Pareggio le barche visualizzate e le barche totali.
              this.items_barche_visualizzate = Array.from(
                this.items_barche_totali
              );
            } else {
              this.$refs.SnackbarQV.print(
                "orange",
                `Criticità tecnica con codice ${res.status}, non è stato possibile caricare i dati.`
              );
            }
          });
        } else {
          this.$refs.SnackbarQV.print(
            "orange",
            `Criticità tecnica con codice ${res.status}, non è stato possibile caricare i dati.`
          );
        }
      });
    },
    handleSalvataMacchina() {
      this.modifica_barche = false;
      this.barca_sel = new Barca();
      this.getMacchine();
    },
  },
  created() {},
  mounted() {
    this.getMacchine();
  },
};
</script>
<style scoped>
</style>