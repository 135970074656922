<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details
            label="Codice"
            :readonly="this.abilita_codice_fornitore===false"
            style="display: inline-block; width: 100%"
            v-model="codice_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details
            label="Ragione Sociale"
            style="display: inline-block; width: 100%"
            v-model="ragione_sociale_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details
            label="Indirizzo"
            style="display: inline-block; width: 100%"
            v-model="indirizzo_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details
            label="Sede Operativa"
            style="display: inline-block; width: 100%"
            v-model="sede_operativa_tmp"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-autocomplete
            @change="
              cap_tmp = '';
              id_localita_tmp = null;
              id_provincia_tmp = null;
              id_regione_tmp = null;
              des_localita_tmp = '';
              des_provincia_tmp = '';
              des_regione_tmp = '';
            "
            v-model="id_nazione_tmp"
            :items="nazione_lista"
            label="Nazione"
            placeholder="Seleziona"
            item-text="des"
            item-value="id_tabella"
            required
          >
            <template #label>
              <span class="mr-2">Nazione</span>
              <span @click.stop style="min-width: 500px; pointer-events: all">
                <Modules
                  v-on:dialogModules="gettabelle"
                  tipo="MAN_NAZIONE"
                  nome="Nazione"
                  setaction="settabella"
                  getaction="gettabella"
                  controller="Global"
                  :filter="'(val_int = ' + id_azienda + ') AND validita = 1'"
                  :dati="[{ des: '' }, { val_int: id_azienda }]"
                />
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            @change="
              cap_tmp = '';
              id_localita_tmp = null;
              id_provincia_tmp = null;
              des_localita_tmp = '';
              des_provincia_tmp = '';
            "
            v-model="id_regione_tmp"
            :items="regione_lista.filter((f) => f.val_int == id_nazione_tmp)"
            label="Regione"
            placeholder="Seleziona"
            item-text="des"
            item-value="id_tabella"
            required
          >
            <template #label>
              <span class="mr-2">Regione</span>
              <span @click.stop style="min-width: 500px; pointer-events: all">
                <Modules
                  v-on:dialogModules="gettabelle"
                  tipo="MAN_REGIONE"
                  nome="Regione"
                  setaction="settabella"
                  getaction="gettabella"
                  controller="Global"
                  :filter="
                    '(val_int = ' + id_nazione_tmp + ') AND validita = 1'
                  "
                  :dati="[{ des: '' }, { val_int: id_nazione_tmp }]"
                />
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            @change="
              cap_tmp = '';
              id_localita_tmp = null;
              des_localita_tmp = '';
            "
            v-model="id_provincia_tmp"
            :disabled="
              provincia_lista.filter((elem) => elem.val_int === id_regione_tmp)
                .length === 0
            "
            :items="
              provincia_lista.filter((elem) => elem.val_int === id_regione_tmp)
            "
            label="Provincia"
            placeholder="Seleziona"
            item-text="des"
            item-value="id_tabella"
            required
          >
            <template #label>
              <span class="mr-2">Provincia</span>
              <span @click.stop style="min-width: 500px; pointer-events: all">
                <Modules
                  v-on:dialogModules="gettabelle"
                  tipo="MAN_PROVINCIA"
                  nome="Provincia"
                  setaction="settabella"
                  getaction="gettabella"
                  controller="Global"
                  :filter="
                    '(val_int = ' + id_regione_tmp + ') AND validita = 1'
                  "
                  :dati="[{ des: '' }, { val_int: id_regione_tmp }]"
                />
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            @change="cap_tmp = ''"
            v-model="id_localita_tmp"
            :disabled="
              localita_lista.filter((elem) => elem.val_int === id_provincia_tmp)
                .length === 0
            "
            :items="
              localita_lista.filter((elem) => elem.val_int === id_provincia_tmp)
            "
            label="Località"
            placeholder="Seleziona"
            item-text="des"
            item-value="id_tabella"
            required
          >
            <template #label>
              <span class="mr-2">Località</span>
              <span @click.stop style="min-width: 500px; pointer-events: all">
                <Modules
                  v-on:dialogModules="gettabelle"
                  tipo="MAN_LOCALITA"
                  nome="Località"
                  setaction="settabella"
                  getaction="gettabella"
                  controller="Global"
                  :filter="
                    '(val_int = ' + id_provincia_tmp + ') AND validita = 1'
                  "
                  :dati="[{ des: '' }, { val_int: id_provincia_tmp }]"
                />
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            autocomplete="off"
            :disabled="
              cap_lista.filter((elem) => elem.val_int === id_localita_tmp)
                .length === 0
            "
            :items="
              cap_lista.filter((elem) => elem.val_int === id_localita_tmp)
            "
            item-text="des"
            item-value="des"
            label="CAP"
            placeholder="Seleziona"
            required
            v-model="cap_tmp"
          >
            <template #label>
              <span class="mr-2">CAP</span>
              <span @click.stop style="min-width: 500px; pointer-events: all">
                <Modules
                  autocomplete="off"
                  v-on:dialogModules="gettabelle"
                  tipo="MAN_CAP"
                  nome="CAP"
                  setaction="settabella"
                  getaction="gettabella"
                  controller="Global"
                  :filter="
                    '(val_int = ' + id_localita_tmp + ') AND validita = 1'
                  "
                  :dati="[{ des: '' }, { val_int: id_localita_tmp }]"
                />
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            counter="2"
            dense
            label="COD. P.IVA"
            style="display: inline-block; width: 100%"
            :rules="rule_partita_iva_mini"
            v-model="p_iva_mini_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            counter="11"
            dense
            label="Partita IVA"
            style="display: inline-block; width: 100%"
            :rules="rule_partita_iva"
            v-model="p_iva_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            :counter="codice_fiscale_tmp.length <= 11 ? 11 : 16"
            dense
            label="Codice Fiscale"
            :rules="rule_codice_fiscale"
            style="display: inline-block; width: 100%"
            v-model="codice_fiscale_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details
            label="Telefono"
            style="display: inline-block; width: 100%"
            v-model="telefono_tmp"
            autofocus
            :key="keyRender_telefono_tmp"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details
            label="E-mail"
            style="display: inline-block; width: 100%"
            v-model="email_tmp"
            autofocus
          >
          </v-text-field>
        </v-col>
        <v-col md="1" cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 py-0"
            counter="2"
            denses
            label="Sigla REA"
            style="display: inline-block; width: 100%"
            :rules="rule_sigla_rea"
            v-model="sigla_rea_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            type="number"
            counter="6"
            :rules="rule_codice_rea"
            label="REA"
            style="display: inline-block; width: 100%"
            v-model="codice_rea_tmp"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            min="0"
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            counter="7"
            :rules="rule_sdi"
            label="SDI"
            style="display: inline-block; width: 100%"
            v-model="SDI_tmp"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  name: "AnagraficheDettaglioGenerale",
  components: {
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {
    anagrafica_sel: { type: Object, default: null },
    abilita_codice_fornitore:{type: Boolean, default: true},
  },
  data() {
    return {
      // Variabili d'appoggio
      cap_tmp: null,
      codice_fiscale_tmp: "",
      codice_tmp: "",
      des_localita_tmp: "",
      des_nazione_tmp: "",
      des_provincia_tmp: "",
      id_localita_tmp: null,
      id_nazione_tmp: null,
      id_provincia_tmp: null,
      id_regione_tmp: null,
      indirizzo_tmp: "",
      localita_tmp: "",
      sigla_rea_tmp: "",
      codice_rea_tmp: "",
      p_iva_tmp: "",
      p_iva_mini_tmp: "",
      ragione_sociale_tmp: "",
      regione_tmp: "",
      SDI_tmp: "",
      sede_operativa_tmp: "",
      email_tmp: "",
      rule_codice_fiscale: [
        (v) =>
          v != null && v.length === 16 ||
          v != null && v.length === 11 ||
          v === "" ||
          "Richiesti 11 o 16 caratteri",
      ],
      rule_sigla_rea: [
        (v) => v != null && v.length === 2 || v === "" || "Richiesti 2 caratteri",
      ],
      rule_codice_rea: [
        (v) => v != null && v.length === 6 || v === "" || "Richiesti 6 caratteri",
      ],
      rule_partita_iva: [
        (v) => v != null && v.length === 11 || v === "" || "Richiesti 11 caratteri",
      ],
      rule_partita_iva_mini: [
        (v) => v != null && v.length === 2 || v === "" || "Richiesti 2 caratteri",
      ],
      rule_sdi: [
        (v) => v != null && v.length === 7 || v === "" || "Richiesti 7 caratteri",
      ],
      reg: new RegExp("(^$)|(^[0-9]+$)"), //REGEX CHE ACCETTA SOLAMENTE NUMERI O STRINGA VUOTA
      telefono_tmp: "",
      telefono_tmp_check: "",
      tipo_tmp: "",
      keyRender_telefono_tmp: 0,
      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      cap_lista: [],
      localita_lista: [],
      nazione_lista: [],
      regione_lista: [],
      provincia_lista: [],
    };
  },
  computed: {
    id_azienda() {
      return localStorage.id_azienda;
    },
  },
  watch: {
    telefono_tmp(NewValue) {
      if (this.reg.test(NewValue) == false) {
        this.keyRender_telefono_tmp++;
        NewValue = "";
        this.telefono_tmp = this.telefono_tmp_check;
      } else {
        this.telefono_tmp_check = NewValue;
      }
    },
    anagrafica_sel: {
      immediate: true,
      handler() {
        this.cap_tmp = this.anagrafica_sel.cap;
        this.codice_fiscale_tmp = this.anagrafica_sel.codice_fiscale;
        this.codice_rea_tmp = this.anagrafica_sel.codice_rea;
        this.codice_tmp = this.anagrafica_sel.codice;
        this.des_localita_tmp = this.anagrafica_sel.des_localita;
        this.des_nazione_tmp = this.anagrafica_sel.des_nazione;
        this.des_regione_tmp = this.anagrafica_sel.des_regione;
        this.des_provincia_tmp = this.anagrafica_sel.des_provincia;
        this.email_tmp = this.anagrafica_sel.email;
        this.id_localita_tmp = this.anagrafica_sel.id_localita;
        this.id_nazione_tmp = this.anagrafica_sel.id_nazione;
        this.id_regione_tmp = this.anagrafica_sel.id_regione;
        this.id_provincia_tmp = this.anagrafica_sel.id_provincia;
        this.indirizzo_tmp = this.anagrafica_sel.indirizzo;
        this.localita_tmp = this.anagrafica_sel.localita;
        this.p_iva_mini_tmp = this.anagrafica_sel.p_iva_mini;
        this.p_iva_tmp = this.anagrafica_sel.p_iva;
        this.ragione_sociale_tmp = this.anagrafica_sel.ragione_sociale;
        this.sede_operativa_tmp = this.anagrafica_sel.sede_operativa;
        this.SDI_tmp = this.anagrafica_sel.SDI;
        this.sigla_rea_tmp = this.anagrafica_sel.sigla_rea;
        this.telefono_tmp = this.anagrafica_sel.telefono;
        this.tipo_tmp = this.anagrafica_sel.tipo;
      },
    },
    id_nazione_tmp: {
      immediate: true,
      handler() {
        // Quando cambia l'id_sede, gli associo una descrizione
        this.nazione_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_nazione_tmp) {
            this.des_nazione_tmp = elem.des;
          }
        });
      },
    },
    id_regione_tmp: {
      immediate: true,
      handler() {
        // Quando cambia l'id_sede, gli associo una descrizione
        this.regione_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_regione_tmp) {
            this.des_regione_tmp = elem.des;
          }
        });
      },
    },
    id_provincia_tmp: {
      immediate: true,
      handler() {
        // Quando cambia l'id_sede, gli associo una descrizione
        this.provincia_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_provincia_tmp) {
            this.des_provincia_tmp = elem.des;
          }
        });
      },
    },
    id_localita_tmp: {
      immediate: true,
      handler() {
        // Quando cambia l'id_sede, gli associo una descrizione
        this.localita_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_localita_tmp) {
            this.des_localita_tmp = elem.des;
          }
        });
      },
    },
    /*cap_tmp: {
      immediate: true,
      handler() {
        // Quando cambia l'id_sede, gli associo una descrizione
        this.cap_lista.forEach((elem) => {
          if (elem.val_int === this.id_localita_tmp) {
            this.cap_tmp = elem.des;
          } else {
            console.log("nisba ");
          }
        });
      },
    },*/
  },
  methods: {
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_NAZIONE','MAN_REGIONE','MAN_PROVINCIA','MAN_LOCALITA','MAN_CAP'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.nazione_lista =
          res.data.MAN_NAZIONE != undefined ? res.data.MAN_NAZIONE : [];

        this.regione_lista =
          res.data.MAN_REGIONE != undefined ? res.data.MAN_REGIONE : [];

        this.provincia_lista =
          res.data.MAN_PROVINCIA != undefined ? res.data.MAN_PROVINCIA : [];

        this.localita_lista =
          res.data.MAN_LOCALITA != undefined ? res.data.MAN_LOCALITA : [];

        this.cap_lista = res.data.MAN_CAP != undefined ? res.data.MAN_CAP : [];

        this.cap_lista.forEach(elem => {elem.des = parseInt(elem.des)});
      });
    },
  },
  created() {},
  mounted() {
    this.gettabelle();
  },
};
</script>
<style scoped>
#a12::-webkit-outer-spin-button,
#a12::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>