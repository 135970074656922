<template>
    <v-card>
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="filteredPermessiValidita"
                        sort-by="data"
                        class="elevation-1"
                        hide-default-footer
                        :items-per-page="12000"                        
                    >
                        <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Permessi</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                            
                            <v-dialog
                                v-model="dialog_permesso"
                                max-width="50%"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                dark
                                outlined
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                >
                                Nuovo Permesso
                                </v-btn>
                            </template>
                            <v-card>
                            <v-toolbar
                                    class="white--text"
                                    color="blue"
                                    dense
                                    style="position: sticky; top: 0px; z-index: 1"
                                    >
                                    <v-toolbar-title> {{ formTitle }} </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="red--text"
                                        @click="close"
                                        color="blue-grey lighten-5"
                                        small
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn> 
                                </v-toolbar>

                                <v-card-text>
                                    <v-container v-if="editedIndex == -1">
                                        <v-row > 
                                            <v-col cols="12" sm="6" md="6" align="center">
                                                <v-menu
                                                    ref="menu_da"
                                                    v-model="menu_da"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="editedItem.data_da"
                                                   transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="editedItem.data_da"
                                                        label="Data Dal"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="editedItem.data_da"
                                                    no-title
                                                    scrollable
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="menu_da = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.menu_da.save(editedItem.data_da)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="12" sm="6" md="6" align="center">
                                            <v-menu
                                                ref="menu_a"
                                                v-model="menu_a"
                                                :close-on-content-click="false"
                                                :return-value.sync="editedItem.data_a"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="editedItem.data_a"
                                                    label="Data Al"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="editedItem.data_a"
                                                no-title
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu_a = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.menu_a.save(editedItem.data_a)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row >
                                            <v-col cols="12" sm="6" md="6" align="left">
                                                <v-container
                                                    class="px-0"
                                                    fluid
                                                >
                                                <v-checkbox
                                                    v-model="editedItem.esteso_azienda"
                                                    :label="`Estendi Azienda.`"
                                                ></v-checkbox>
                                                </v-container>
                                            </v-col>       
                                            <v-col cols="12" sm="6" md="6" align="left">
                                                <v-container
                                                    class="px-0"
                                                    fluid
                                                >
                                                <v-checkbox
                                                    v-model="editedItem.esteso_sede"
                                                    :label="`Estendi Sede.`"
                                                ></v-checkbox>
                                                </v-container>
                                            </v-col>   
                                        </v-row>  
                                    </v-container>
                                        <v-divider/>
                                        <br>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <v-select
                                                    :items="tabella_permessi"
                                                    item-text='des_permesso'
                                                    item-value='id_t_permesso'
                                                    label="Tipo Permesso"
                                                    dense
                                                    outlined
                                                    v-model="editedItem.id_t_permesso"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field
                                                v-model="editedItem.ore_permesso"
                                                label="Ore Permesso"
                                                type="number"
                                                v-on:keydown.enter.prevent="conferma"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    outlined
                                    @click="conferma"
                                >
                                    Conferma
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Confemi la cancellazione del permesso?</v-card-title>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>


                        </v-toolbar>
                        </template>
                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="editItem(item)">
                                            <v-icon color="green"
                                            >mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Modifica Permesso</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                            <v-icon color="red"
                                            >mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cancella Permesso</span>
                                </v-tooltip>

                            </template>
                    </v-data-table>
 
                </v-col>
                <v-col cols="12" sm="6" md="12" align="center">
                        <v-btn
                            color="primary"
                            @click="save_back"
                        >
                            SALVA
                        </v-btn>
                </v-col>
                    
            </v-row>


        </v-card-text>
 

    </v-card>
</template>

<script>
   // import axios from "axios";
    export default {
        data() {
            return{
                modalTab: "cod_lotto",
                dialog_permesso: false,
                dialogDelete: false,
                tabella_permessi:[],
                permessi_utente:[],

                data_dal: this.DATA.substr(0, 10),
                menu_da: false,
                data_al: this.DATA.substr(0, 10),
                menu_a: false,

                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",

                editedIndex: -1,
                editedItem: {id_permesso: '', id_personale: '', des_permesso: '', data: null, ore_presenza: 0, esteso_azienda: 0, esteso_sede: 0, data_da: null, data_a: null, ore_permesso: 0, id_t_permesso: '', date_ins: null, date_mod: null, id_utente: 0, id_permesso_old: null, des_permesso1: '', ore_gg: '', validita: 1},
                defaultItem: {id_permesso: '', id_personale: '', des_permesso: '', data: null, ore_presenza: 0, esteso_azienda: 0, esteso_sede: 0, data_da: null, data_a: null, ore_permesso: 0, id_t_permesso: '', date_ins: null, date_mod: null, id_utente: 0, id_permesso_old: null, des_permesso1: '', ore_gg: '', validita: 1},

                headers: [
                    { text: 'Data', value: 'data', sortable: false, },
                    { text: 'Tipo Permesso', value: 'des_permesso' , sortable: false,},
                    { text: 'Ore', value: 'ore_permesso', width:'10%', sortable: false, },
                    { text: 'Esteso. Azienda', value: 'esteso_azienda', width:'10%', sortable: false, },
                    { text: 'Esteso Sede', value: 'esteso_sede', width:'10%', sortable: false, },
                    { text: 'Da', value: 'data_da', width:'10%', sortable: false, },
                    { text: 'A', value: 'data_a', width:'10%', sortable: false, },
                    { text: 'Azione', value: 'actions', sortable: false,  width:'10%' },
                ],

            }

        
        },
        props: {
            ID_UTENTE: Number,
            DATA: String,
            TRIGGER: Number,
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuovo Permesso' : 'Modifica Permesso'
            },
            // Questa funzione è applicata al v-data-table con :headers="headers" per filtrare gli :items  di "permessi_utente" con validità diversa 0
           
           filteredPermessiValidita() {
               //debugger
                return this.permessi_utente.filter((i) => {
                    return (i.validita === null) || (i.validita === 1) || (i.validita === "");
                })
            },
        },        
        mounted() {
            this.gettabellapermessi()
            this.getpermessipersona() 
        },
        watch: {
            DATA: {
            immediate: true,
            handler() {
                    this.getpermessipersona() 
                }
            },
            ID_UTENTE: {
            immediate: true,
            handler() {
                    this.getpermessipersona() 
                }
            },
            TRIGGER: {
            immediate: true,
            handler() {
                   // debugger
                    this.getpermessipersona() 
                }
            },
        },
        methods: { 
            gettabellapermessi(){
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "gettabellapermessi",
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {    
                    this.tabella_permessi = res.data;
                })
                .catch(e => {
                    console.log(e);
                });

            },
            getpermessipersona(){
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "getpermessipersonale",
                        data: this.DATA,
                        id_personale: this.ID_UTENTE, 
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {             
                    //this.permessi_utente = res.data;
                    this.permessi_utente = []
                    this.load_permessi_utente(res.data)
                })
                .catch(e => {
                    console.log(e);
                });                

            },
            load_permessi_utente(array_permessi){
            
            var lung = array_permessi.length
            if (lung > 0 ){
                for (var i = 0; i <=lung - 1; i++){
                this.permessi_utente.push({  
                        id_permesso: array_permessi[i].id_permesso,  //Da Valorizzare = -1 se nuovo
                        id_personale: array_permessi[i].id_personale,  //Da Valorizzare
                        des_permesso: array_permessi[i].des_permesso,  //Da Valorizzare
                        data: array_permessi[i].data.substr(0,10),  //Da Valorizzare
                        ore_presenza: array_permessi[i].ore_presenza,
                        data_da: array_permessi[i].data_da,  //Da Valorizzare -opzionale
                        data_a: array_permessi[i].data_a,  //Da Valorizzare -opzionale
                        ore_permesso: array_permessi[i].ore_permesso,  //Da Valorizzare
                        id_t_permesso: array_permessi[i].id_t_permesso,  //Da Valorizzare
                        esteso_azienda: array_permessi[i].esteso_azienda,  //Da Valorizzare
                        esteso_sede: array_permessi[i].esteso_sede,  //Da Valorizzare
                        date_ins: array_permessi[i].date_ins,
                        date_mod: array_permessi[i].date_mod,
                        id_utente: array_permessi[i].id_utente,
                        id_permesso_old: array_permessi[i].id_permesso_old,
                        des_permesso1: array_permessi[i].des_permesso1,
                        ore_gg: array_permessi[i].ore_gg,
                        validita: array_permessi[i].validita,
                     });  
                    }
                }
            },
   
            editItem (item) {

                this.editedIndex = this.permessi_utente.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog_permesso = true
            },
            deleteItem (item) {
                this.editedIndex = this.permessi_utente.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },
            deleteItemConfirm () {
                 //Se il record non è nuovo (-1) mettendo validità a 0 il record sparisce perchè la tabella permessi_utente  è filtrata su validita != 0
                if (this.editedItem.id_permesso != -1){
                        this.permessi_utente[this.editedIndex].validita = 0
                }else{
                this.permessi_utente.splice(this.editedIndex, 1)
                }
                this.closeDelete()
            },
            close () {
                this.dialog_permesso = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },
            conferma () {
                if (this.editedItem.esteso_azienda && this.editedItem.esteso_sede ){
                    
                    this.snackbar_color =  "red"
                    this.snackbar_text =  "Non è possibile estendere il permesso all'azienda e alla sede contemporanemante";
                    this.snackbar = true

                }else if ((this.editedItem.esteso_azienda || this.editedItem.esteso_sede) &&  (this.editedItem.data_da == null || this.editedItem.data_a == null) ){
                    this.snackbar_color =  "red"
                    this.snackbar_text =  "Con l'Estesione entrambe le date devono essere valorizzate";
                    this.snackbar = true

                }else if (this.editedItem.id_t_permesso < 1  ){
                    this.snackbar_color =  "red"
                    this.snackbar_text =  "Il numero di ore permesso deve essere maggiore di zero";
                    this.snackbar = true

                }else if(this.editedItem.ore_permesso == 0){
                    this.snackbar_color =  "red"
                    this.snackbar_text =  "Il numero di ore permesso deve essere maggiore di zero";
                    this.snackbar = true

                }else{
                    var lung = this.tabella_permessi.length
                    for (var i = 0; i <=lung - 1; i++){
                        //popolo le decrizioni dei permessi in funzione del codice 
                        if (this.tabella_permessi[i].id_t_permesso ==  this.editedItem.id_t_permesso)
                            this.editedItem.des_permesso = this.tabella_permessi[i].des_permesso
                    }
                    if (this.editedIndex > -1) {
                        Object.assign(this.permessi_utente[this.editedIndex], this.editedItem)
                    } else {
                        this.editedItem.data = this.DATA.substr(0,10)
                        this.editedItem.id_personale = this.ID_UTENTE
                        this.editedItem.id_permesso = -1
                        this.editedItem.validita = 1
                        this.permessi_utente.push(this.editedItem)

                    }
                    debugger
                    this.close()
                    //console.log (this.permessi_utente)
                }
            },
            save_back(){
                let request = {
                    controller: "Presenze",
                    method: "PUT",
                    richiesta: {
                        action: "setpermessi",
                        permessi: this.permessi_utente,
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => { 
                            if (res.statusText == "OK"){
                                this.$emit("permessi", this.permessi_utente);  
                            }      

                    });
                /*               
                const config = {
                        headers: { "Content-Type": "application/json"},
                    };
                    var data = JSON.stringify(
                        (data = {
                        action: "setpermessi",
                        data_dal: this.data_dal,
                        data_al: this.data_al,
                        estendi: this.checkbox,
                        permessi: this.permessi_utente,
                        token: localStorage.getItem("user_token"),
                        })
                    );
                    
                    axios
                    .put(store.state.config.ip + "api/Presenze" , data, config)
                        .then(response => {
                            debugger
                            if (response.statusText == "OK"){
                                this.$emit("permessi", this.permessi_utente);  
                            }      

                    });
                   */ 


            },             
        }

    }
</script>
