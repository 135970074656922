<template>
  <v-container fluid>
    <v-dialog
      content-class="artDialog"
      :scrollable="false"
      v-model="dialoginfo"
      width="700"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Dettaglio delle azioni per questa pagina</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                @click="
                  dialoginfo = false;
                  closeModalInfo();
                "
                depressed
                class="mt-n5 mr-n6"
                color="error"
                >mdi-window-close</v-icon
              >
            </template>
            <span>Cancellare </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-list disabled>
            <v-subheader>Azioni</v-subheader>
            <v-list-item-group v-model="item" color="primary">
              <v-list-item v-for="(item, i) in actionItems" :key="i">
                <v-list-item-icon>
                  <v-icon :color="item.color" v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    style="text-align: left !important"
                    v-text="item.text"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogArticoloDettaglio"
      width="1500"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Articolo Dettaglio</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  closedetailModal();
                  dialogArticoloDettaglio = false;
                "
                small
              >
                <v-icon v-on="on" v-bind="attrs" color="red" depressed class=""
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text
          style="max-height: 600px; overflow-y: auto; overflow-x: hidden"
          class="mt-6"
        >
          <Articolo
            :articoloId="this.recursiveId"
            :key="this.recursiveId"
            :name="this.recursiveId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="artDialog" persistent v-model="dialog" width="700">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="mt-n4 white--text">Distinta Base</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-row class="mt-n4 mb-3">
            <v-col class="text-left" md="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="saveIcon" @click="saveDistinta" small>
                    <v-icon v-bind="attrs" v-on="on" color="success" size="32"
                      >mdi-floppy</v-icon
                    >
                  </v-btn>
                </template>
                <span> salva distinta </span>
              </v-tooltip>
            </v-col>
            <v-col md="4"></v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="hideModal" small>
                    <v-icon v-on="on" v-bind="attrs" color="#1a80b6" size="32"
                      >mdi-replay</v-icon
                    >
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row></v-row>
          <v-form v-on:submit.prevent="searchDistintaBase" class="mt-n9 ml-n1">
            <v-container>
              <v-row>
                <v-col class="d-flex" cols="12" md="3">
                  <v-text-field
                    :disabled="this.disablesearch === true"
                    type="text"
                    v-model="cod_articolo"
                    class="shrink"
                    label="Ricerca Componente"
                    placeholder="Ricerca Componente"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-5" @click="searchDistintaBase" small>
                        <v-icon v-on="on" v-bind="attrs" color="#1a80b6" size="32"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-divider></v-divider>
          <v-row>
            <v-col md="12">
              <v-data-table
                :loading="LoadingVal"
                :headers="headers"
                :items="items"
                class="elevation-1"
                :dense="true"
              >
                <v-progress-linear
                  v-show="progressBar"
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:[`item.quantita`]="props">
                  <v-text-field
                    solo
                    flat
                    min="0"
                    dense
                    class="shrink shrinkinput"
                    v-model="props.item.quantita"
                    type="number"
                    name="quantita"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogDistintaBase"
      width="700"
    >
      <v-card>
        <v-toolbar class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Distinta Base</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-row class="mt-n4 mb-3">
            <v-col class="text-left" md="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="saveIcon" @click="updateDistinta" small>
                    <v-icon v-bind="attrs" v-on="on" color="success" size="32"
                      >mdi-floppy</v-icon
                    >
                  </v-btn>
                </template>
                <span> salva distinta </span>
              </v-tooltip>
            </v-col>
            <v-col md="4"></v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="closedialogDistintaBase" small>
                    <v-icon v-on="on" v-bind="attrs" color="#1a80b6" size="32"
                      >mdi-replay</v-icon
                    >
                  </v-btn>
                </template>
                <span>Undo</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row></v-row>
          <v-form v-on:submit.prevent="searchDistintaBase" class="mt-n9 ml-n1">
            <v-container>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    :disabled="this.disablesearch === true"
                    type="text"
                    v-model="cod_articolo"
                    label="Ricerca Componente"
                    placeholder="Ricerca Componente"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-5" @click="searchDistintaBase" small>
                        <v-icon v-on="on" v-bind="attrs" color="#1a80b6" size="32"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-divider></v-divider>
          <v-row>
            <v-col md="12">
              <v-data-table
                :loading="LoadingVal"
                :headers="headers"
                :items="items"
                class="elevation-1"
                :dense="true"
              >
                <v-progress-linear
                  v-show="progressBar"
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:[`item.quantita`]="props">
                  <v-text-field
                    solo
                    flat
                    min="0"
                    dense
                    class="shrink shrinkinput"
                    v-model="props.item.quantita"
                    type="number"
                    name="quantita"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogEliminaDistintaBase"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="headline indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">elimina la distinta base</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col md="1">
              <v-icon color="red">mdi-exclamation-thick</v-icon>
            </v-col>
            <v-col>
              <p>
                Attenzione. Confermi la rimozione del componenti: N':
                {{ this.deletingQuantity }}-{{ this.deletingItemDetail }}
              </p>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col md="5"></v-col>
            <v-col md="3">
              <v-btn
                dark
                tile
                class="mx-auto"
                @click="
                  deleteItem();
                  dialogEliminaDistintaBase = false;
                "
                color="green"
                >Invia</v-btn
              >
            </v-col>
            <v-col md="2">
              <v-btn
                dark
                tile
                class="mr-5"
                color="red"
                @click="dialogEliminaDistintaBase = false"
                >Anulla</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-snackbar v-model="snackbar" :vertical="true">
                {{ message_snackbar }}
                <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
              </v-snackbar>
              <v-toolbar primary-title dense class="indigo darken-1 rounded-t">
                <v-toolbar-title class="white--text"
                  >Articolo presente in distinte base</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="dialoginfo = true"
                      color="white"
                      v-on="on"
                      v-bind="attrs"
                      class=""
                      small
                    >
                      <v-icon color="primary"> mdi-information-outline </v-icon>
                    </v-btn>
                  </template>
                  <span>Dettaglio delle azioni</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <!--<v-btn v-if="!on" @click="showIcons" class="mt-n1 ml-16" color="primary" dark fab top tile small>S</v-btn>
            <v-btn v-if="on" dark fab top tile @click="hideIcons" class="mt-n1 ml-16" color="primary" small>D</v-btn>-->
              </v-toolbar>
              <v-card-text class="text-left">
                <v-progress-circular
                  class=""
                  v-show="progressBar1"
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-circular>
                <v-treeview
                  activatable
                  shaped
                  hoverable
                  :open.sync="open"
                  :items="distintaBaseList"
                  item-key="id"
                  content="6"
                  open-on-click
                >
                  <template v-slot:prepend="{ item, open }">
                    <v-icon v-if="item.id == 0">{{
                      open ? "mdi-buffer" : "mdi-buffer"
                    }}</v-icon>
                    <v-icon v-if="item.id > 0">{{
                      open ? "mdi-text-short" : "mdi-text-short"
                    }}</v-icon>
                    <template v-if="item.id >= -1"
                      >N° {{ item.quantita }} {{ item.cod_articolo }}
                    </template>
                  </template>
                  <template v-if="hideicons === true" slot="append" slot-scope="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="showModal"
                          v-on="on"
                          v-if="item.id_rif == -1"
                          small
                        >
                          <v-icon color="#1a80b6">mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Inserisci Componente</span>
                    </v-tooltip>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="getDetail(item)" v-on="on" small>
                          <v-icon color="blue">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Dettaglio Articolo</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="ml-1"
                          v-on="on"
                          v-if="item.id_rif == 0"
                          small
                          @click="updateItem(item)"
                        >
                          <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Modifica Componente</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="ml-1"
                          v-on="on"
                          v-show="item.id_rif == 0"
                          small
                          @click="deleteFolder(item)"
                        >
                          <v-icon color="error">mdi-window-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Cancella Componente</span>
                    </v-tooltip>
                  </template>
                </v-treeview>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import config from '../../../../../public/config'
export default {
  name: "distintaBase",
  props: ["pdata"],
  components: {
    Articolo: () => import("../Articolo"),
  },
  data() {
    return {
      headers: [
        {
          text: "Q.ta Distinta",
          value: "quantita",
        },
        {
          text: "Codice Articolo",
          value: "cod_articolo",
        },
        {
          text: "Descrizione",
          value: "des_articolo",
        },
      ],
      item: {},
      ids: [],
      dialoginfo: false,
      dialogDistintaBase: false,
      LoadingVal: false,
      progressBar: true,
      progressBar1: true,
      recursiveId: null,
      updatedDistinta: [],
      dialogArticoloDettaglio: false,
      openAll: false,
      on: true,
      findalData: [],
      deletingQuantity: 0,
      deletingItemDetail: "",
      dialogEliminaDistintaBase: false,
      disablesearch: false,
      open: [0],
      hideicons: true,
      quantita: 0,
      items: [],
      cod_articolo: "",
      abilita_distinta_base: 0,
      distintaBaseList: [], //array cartelle
      arrayPadre: [], //array con la lista dei nodi tra la cartella selezionata e la root
      dialog: false,
      strPath: "", //string path della cartella
      strNameFolder: "", //string nuovo nome cartella
      editIdCartella: -1, //int id cartella selezionata
      files: [],
      editedIndexAllegato: -1,
      message_new_allegato_waiting: "",
      date: new Date().toISOString().substr(0, 10),
      snackbar: false,
      message_snackbar: "",
      id_articolo: this.pdata.id_articolo,
      actionItems: [
        {
          id: 1,
          icon: "mdi-eye",
          text: "Per vedere il dettaglio del singolo componente",
          color: "black",
        },
        // {
        //     id:3,
        //     icon:"mdi-window-close",
        //     text:"Per eliminare una ",
        //     color:"error"
        // }
      ],
      data: (vm) => ({
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        menu2: false,
      }),
    };
  },
  mounted() {
    //  In order to get Utilizzo list
    let getdistintabase = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "getarticoloutilizzo",
        id_articolo: this.id_articolo,
        token: localStorage.user_token,
      },
    };
    this.$store.dispatch("api", getdistintabase).then((res) => {
      this.distintaBaseList = res.data;
      if (this.distintaBaseList.length > 0) {
        this.progressBar1 = false;
      }
      if (this.distintaBaseList.length === 0) {
        this.distintaBaseList = [];
        this.progressBar1 = false;
      }
      this.distintaBaseList.map((el) => {
        this.ids.push(el.id);
      });
    });
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    closedialogDistintaBase() {
      this.dialogDistintaBase = false;
    },
    updateDistinta() {
      this.distintaBaseList[0].children.map((it) => (it.validita = 1));
      this.dialogDistintaBase = false;
      let setdistintabase1 = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.id_articolo,
          token: localStorage.getItem("user_token"),
          distinta_base: this.distintaBaseList,
        },
      };
      this.$store.dispatch("api", setdistintabase1);
      this.dialogDistintaBase = false;
    },
    getDetail(item) {
      this.dialogArticoloDettaglio = true;
      this.recursiveId = item.id_articolo;
    },
    closedetailModal() {
      this.dialogArticoloDettaglio = false;
    },
    deleteFolder(item) {
      let data = {};
      data.id_distinta_base = item.id_distinta_base;
      data.validita = 0;
      let deldistintabase = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.id_articolo,
          token: localStorage.getItem("user_token"),
          distinta_base: [data],
        },
      };
      this.$store.dispatch("api", deldistintabase);
      //   console.log(this.distintaBaseList[0].children)
      let i = this.distintaBaseList[0].children.findIndex(
        (it) => it.id_distinta_base === item.id_distinta_base
      );
      this.distintaBaseList[0].children.splice(i, 1);
    },
    hideIcons() {
      this.hideicons = false;
      this.on = false;
      // this.openAll = true;
      this.open = this.ids;
    },
    showIcons() {
      this.hideicons = true;
      this.openAll = false;
      this.open = [];
      this.on = true;
    },
    updateItem(val) {
      this.dialogDistintaBase = true;
      this.disablesearch = true;
      this.item = val;
      val.cod_articolo = val.id;
      val.des_articolo = val.name;
      this.items.push(val);
    },
    searchDistintaBase(evt) {
      this.LoadingVal = true;
      this.progressBar = true;
      evt.preventDefault();
      let getdistintabase = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          cod_articolo: this.cod_articolo,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", getdistintabase).then((res) => {
        this.items = res.data.lista;
        if (this.items.length > 0) {
          this.progressBar = false;
          this.LoadingVal = false;
        }
        let index = this.items.findIndex((it) => it.id_articolo === this.id_articolo);
        this.items.splice(index, 1);
        this.items.filter((el) => el.id_articolo === this.id_articolo);
        if (this.items[0].id_articolo === this.id_articolo) {
          this.items = [];
          return this.items;
        }
        this.items.map((el) => (el.quantita = 0));
      });
    },
    showModal() {
      this.dialog = true;
    },
    saveDistinta() {
      let filteredObject = [];
      this.items.map((el) => {
        if (el.quantita > 0) {
          filteredObject.push(el);
        }
      });
      filteredObject.map((el, index, array) => {
        let getdistintabase = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticolidistintabase",
            id_articolo: el.id_articolo,
            token: localStorage.getItem("user_token"),
          },
        };
        let setdistintabase = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolidistintabase",
            id_articolo: this.id_articolo,
            token: localStorage.getItem("user_token"),
            distinta_base: this.distintaBaseList,
          },
        };
        this.$store.dispatch("api", getdistintabase).then((res) => {
          let getresult = res.data;
          getresult[0].id = parseInt(getresult[0].id);
          getresult[0].id_componente = el.id_articolo;
          (getresult[0].id_articolo = this.id_articolo),
            (getresult[0].id_distinta_base = -1);
          getresult[0].quantita = el.quantita;
          getresult[0].id_rif = 0;
          getresult[0].children = [];
          getresult[0].validita = 1;
          // if (getresult[0].children[0]) {
          //     getresult[0].children[0].id_rif = 1
          // }
          el = getresult;
          this.distintaBaseList[0].children.push(el[0]);
          this.distintaBaseList[0].validita = 1;
          if (index === array.length - 1) {
            this.$store.dispatch("api", setdistintabase);
          }
        });
      });
      this.distintaBaseList.map((el) => {
        if (el.children.length > 0) {
          el.children.map((el) => {
            this.ids.push(el.id);
            // if(el.children.length > 0){
            //   el.children.map(el=>{
            //     this.ids.push(el.id)
            //   })
            // }
          });
        }
        // this.ids.push(el.id)
      });
      this.dialog = false;
      this.items = [];
      this.cod_articolo = "";
    },
    hideModal() {
      this.dialog = false;
      this.disablesearch = false;
      this.cod_articolo = "";
      this.items = [];
    },
    //METODO GET ARRAY DEI FIGLI
    getChildren(item) {
      if (item.children == null) {
        if (this.selectListChildrenFolder.length == 0)
          this.selectListChildrenFolder.push(item);
        return item;
      } else {
        var i;
        if (item != undefined) this.selectListChildrenFolder.push(item);
        for (i = 0; i < item.children.length; i++) {
          this.selectListChildrenFolder.push(this.getChildren(item.children[i]));
        }
      }
    },
    //METODO GET ARRAY PARENT
    getFather(item) {
      if (item.id_rif == -1) {
        this.strPath += item.name;
        return item;
      } else {
        this.getFather(this.findItem(item.id_rif, this.folder));
        this.strPath += "/" + item.name;
      }
    },
    //METODO GET NODO SPECIFICO
    findItem(id, items = null) {
      if (!items) {
        items = this.items;
      }
      return items.reduce((acc, item) => {
        if (acc) {
          return acc;
        }
        if (item.id === id) {
          return item;
        }
        if (item.children) {
          return this.findItem(id, item.children);
        }
        return acc;
      }, null);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    /*
            Handles a change on the file upload
          */
  },
};
</script>
<style lang="scss" scoped>
.artDialog {
  overflow-x: hidden;
}
</style>
