const HOUR = 0, DAY = 1, WEEK = 2, MONTH = 3, YEAR = 4;
const week_names = ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'];
const month_names = ['Gen','Feb','Mar','Apr','Mag','Giu','Lug','Ago','Set','Ott','Nov','Dic'];

const normalize = [
    null,
    function(date){date.setHours(0);},
    function(date){date.setHours(0);},
    function(date){date.setDate(1);},
    function(date){date.setMonth(0);},
]

const reference = new Date();

class GanttCalendar{
    zoom_levels = ['Ore','Giorni','Settimane','Mesi','Anni'];
    zoom = DAY;
    blocks = new Array();

    constructor(){ this.setStep(); }

    // Azzeramento minuti, secondi ecc
    normalizeCalendar(date){

        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        
        try{
            for(let j=this.zoom; j >= 0; j--){
                const norm_func = normalize[j];
                if(norm_func == null){continue;}
                norm_func(date);
            }
        }catch(error){
            window.error = error;
        }           
    }

    // Costruzione del calendario
    build(start_date, end_date){    
        
        reference.setTime(start_date.getTime());
        this.normalizeCalendar(reference);

        switch(this.zoom){
            case HOUR:
                reference.setHours(reference.getHours()-1);
                break;
            case DAY:                   
                reference.setDate(reference.getDate()-1);
                break;    
            case WEEK:
                    reference.setDate(reference.getDate()-7);
                    while(reference.getDay() != 1){reference.setDate(reference.getDate() - 1);} 
                break;
            case MONTH:
                    reference.setMonth(reference.getMonth()-1);
                break;
            case YEAR:
                    reference.setFullYear(reference.getFullYear()-1);                    
                break;
        }

        const stepDate = this.getFormatter(reference);
        const fragments = { upper:new DocumentFragment(), lower:new DocumentFragment() };
        const last_upperblock = { reference:null, colspan:1, value:null };
        this.blocks = new Array();
        const target = new Date(end_date.getTime());
        target.step(2);

        do{
            this.blocks.push(reference.getTime());
            const values = stepDate();
            const block = document.createElement('th');             
            block.innerText = values.lower;
            fragments.lower.appendChild(block);

            if(values.upper != last_upperblock.value){
                last_upperblock.value = values.upper;
                last_upperblock.colspan = 1;
                last_upperblock.reference = document.createElement('th');            
                last_upperblock.reference.innerText = values.upper;
                fragments.upper.appendChild(last_upperblock.reference);
            }else{
                last_upperblock.colspan++;
                last_upperblock.reference.setAttribute('colspan',last_upperblock.colspan);
            }
        }while(reference <= target);

        requestAnimationFrame(function(){
            const header = document.getElementById('gantt_diagram_table').getElementsByTagName('thead')[0];    
            const headers = {
                upper: header.children[0],
                lower: header.children[1]
            };
            headers.upper.innerHTML = '';
            headers.lower.innerHTML = '';
            headers.upper.appendChild(fragments.upper);
            headers.lower.appendChild(fragments.lower);
        });        
    }

    getFormatter(date){
        switch(this.zoom){
            case HOUR:{
                return function(){                                          
                    const value = {
                        upper: `${week_names[date.getDay()]} ${date.getDate()} ${month_names[date.getMonth()]} ${date.getFullYear()}`,
                        lower: `${date.getHours()}:00`
                    };
                    date.setHours(date.getHours()+1);
                    return value;
                }
            }
            case DAY:{
                return function(){                                          
                    const value = {
                        upper: `${month_names[date.getMonth()]} ${date.getFullYear()}`,
                        lower: `${week_names[date.getDay()]} ${date.getDate()}`
                    };
                    date.setDate(date.getDate()+1);
                    return value;
                }
            }
            case WEEK:{
                return function(){                      
                    const value = {
                        upper: `${month_names[date.getMonth()]} ${date.getFullYear()}`,
                        lower: `${date.getDate()} - ${date.getDate()+6}`
                    };
                    date.setDate(date.getDate()+7);
                    return value;
                }
            }
            case MONTH:{
                return function(){ 
                    const value = {
                        upper: date.getFullYear(),
                        lower: `${month_names[date.getMonth()]}`
                    };
                    date.setMonth(date.getMonth()+1);
                    return value;
                }
            }
            case YEAR: {
                return function(){ 
                    const value = {
                        upper: Math.floor(date.getFullYear()/10)*10,
                        lower: date.getFullYear()
                    };
                    date.setFullYear(date.getFullYear()+1);
                    return value;
                }
            }
                
        }
    }

    setStep(){
        switch(this.zoom){
            case HOUR:
                Date.prototype.step = function(diff){this.setHours(this.getHours()+diff);}
                break;
            case DAY:
                Date.prototype.step = function(diff){this.setDate(this.getDate()+diff);}
                break;
            case WEEK:
                Date.prototype.step = function(diff){this.setDate(this.getDate()+(diff*7));}
                break;
            case MONTH:
                Date.prototype.step = function(diff){this.setMonth(this.getMonth()+diff);}
                break;
            case YEAR:
                Date.prototype.step = function(diff){this.setFullYear(this.getFullYear()+diff);}
                break;
        }
    }

    unsetStep(){ delete Date.prototype.step; }

    setZoom(zoomlevel){
        this.zoom = zoomlevel;
        this.setStep();
    }


}
export default GanttCalendar;