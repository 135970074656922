//import moment from "moment/moment";
export default class Report {
    constructor(arg = undefined) {
      if (arg != undefined) {
        const campi = this.setCampi();
        campi.forEach((c) => {
          this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
        });
      } else {
        const campi = this.setCampi();
        campi.forEach((c) => {
          this[c.nome] = c.default_val;
        });
      }
    }
  
    // Metodo per resettare tutte i campi
    reset() {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  
    setCampi() {
      const campi = [
        { nome: "cod_articolo", default_val: "" },
        { nome: "cod_lotto", default_val: "" },
        { nome: "data_documento", default_val: "" },
        { nome: "data_ins", default_val: "" },
        { nome: "datamatrix", default_val: "" },
        { nome: "data_mod", default_val: "" },
        { nome: "des_articolo", default_val: "" },
        { nome: "dimenisone", default_val: "" },
        { nome: "id_label", default_val: null },
        { nome: "id_utente", default_val: null },
        { nome: "lotto_fornitore", default_val: "" },
        { nome: "materiale", default_val: "" },
        { nome: "name", default_val: "" },
        { nome: "num_documento", default_val: "" },
        { nome: "quantita", default_val: "" },
        { nome: "ragione_sociale", default_val: "" },
        { nome: "report", default_val: [] },
        { nome: "validita", default_val: "" },
      ];
      return campi;
    }
  }