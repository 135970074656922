<template>
    <div>
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <v-row>
            <v-col cols="6" align="left">
 
            </v-col>
            <v-col cols="6" align="left">
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="12">
                <v-text-field v-model="articolo.des_articolo" label="Descrizione Articolo" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.l" label="Lunghezza" type="number"  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.p" label="Profondità" type="number"  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.h" label="Altezza" type="number"  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.quantita" label="Quantità" :rules="[rules.required,rules.numberRule]" ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6"  md="4">
                <v-select
                :items="ubicazioni"
                item-text='des'
                item-value='id_tabella'
                label="Ubicazione"
                autocomplete
                v-model="articolo.id_ubicazione"
                ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6"  md="4">
                <v-select
                :items="materiali"
                item-text='descrizione'
                item-value='id_materiale'
                label="Materiale"
                autocomplete
                v-model="articolo.id_materiale"
                ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="12" align="center">
                <v-btn outlined color="blue darken-1" text @click="save">Salva</v-btn>
            </v-col>
        </v-row>


        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>    
</template>
<script>
    export default {
        data() {
            return{
                overlay: false,
                codice_lotto: '', 
                modalTab: "cod_lotto",

                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",


                timestamp: '',
                id_ubicazione: '', 
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 50 || 'Max 50 Caratteri',
                    numberRule: value => (!isNaN(parseFloat(value)) && value >= 0 && value <= 1000000) || 'Numero intero maggione di zero',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                articoli:[],
                articolo: { id_magazzino_inventario: '', id_articolo: '', codice_articolo: '', des_articolo: '', id_lotto: '', cod_lotto: '', ubicazione: '', quantita: '', l: '',  p: '', h: '', id_materiale: '',validita: '', data:''},
                ubicazioni: [],
                materiali: [],
            }
        },

        mounted() { 
           this.getUbicazioni()
           this.getMateriali()
        },
        methods: {  
            getUbicazioni(){
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getUbicazioni",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    this.ubicazioni = response.data.result.tblubicazioni
                });
            },
            getMateriali(){
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getMateriali",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    this.materiali = response.data.result.tblmateriali
                });
            },

            save(){
                this.overlay = true
                this.getNow()
                this.articolo.data = this.timestamp
                var request = {
                    controller: "Logistica",
                    method: "PUT",
                    richiesta: {
                    action: "insertInventario",
                    token: localStorage.getItem("user_token"),
                    id_utente: localStorage.getItem("id"),
                    id_magazzino_inventario: "-1",
                    id_magazzino_lotto: "-1",
                    id_magazzino_articolo: "-1",
                    db_name: "geclink_durbiano",
                    tabella: [this.articolo]
                    }
                };
                this.$store.dispatch("api", request).then(() => {
                        window.location.href = '/home/Inventario'
                        this.overlay = false
                });
            },
            getNow() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            },

         }

    }
</script>
