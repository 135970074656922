<template>
   <v-dialog v-model="GE.ui.show" persistent>
       <v-card >
           <v-toolbar dense dark>
               <v-toolbar-title>Modifica Grafico</v-toolbar-title>
               <v-spacer />
                <v-btn icon @click="GE.ui.exit_prompt=true">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
           </v-toolbar>
            <v-form ref="form">
                <v-tabs v-model="GE.ui.tab" centered fixed-tabs>
                    <v-tabs-slider />
                    <v-tab>Generali</v-tab>
                    <v-tab>Layout</v-tab>
                    <v-tab>Grafico</v-tab>
                    <v-tab>Parametri</v-tab>
                    <v-tab>Database</v-tab>
                </v-tabs>
                <v-tabs-items v-model="GE.ui.tab">
                    <!-- GENERALI -->
                    <v-tab-item>
                        <v-container>
                            <v-row>
                                <v-col cols="2" />
                                <v-col cols="8">
                                    <v-text-field prepend-icon="mdi-form-textbox" label="Nome Grafico" v-model="GE.dettaglio.nome"/>
                                </v-col>                                
                            </v-row>
                            <v-row>
                                <v-col cols="2" />
                                <v-col cols="8">
                                    <v-text-field prepend-icon="mdi-text" label="Descrizione Grafico" v-model="GE.dettaglio.descrizione"/>
                                </v-col>                                
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!-- LAYOUT -->
                    <v-tab-item>
                        <v-container v-if="GE.dettaglio.layout!=undefined">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field type="number" min="0" label="Layout X" prepend-icon="mdi-arrow-expand-right" v-model="GE.dettaglio.layout.x"/>
                                </v-col>   
                                <v-col cols="6">
                                    <v-text-field type="number" min="250" label="Larghezza" prepend-icon="mdi-arrow-expand-horizontal" v-model="GE.dettaglio.layout.w"/>
                                </v-col>                             
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field type="number" min="0" label="Layout Y" prepend-icon="mdi-arrow-expand-down" v-model="GE.dettaglio.layout.y"/>
                                </v-col>   
                                <v-col cols="6">
                                    <v-text-field type="number" min="250" label="Altezza" prepend-icon="mdi-arrow-expand-vertical" v-model="GE.dettaglio.layout.h"/>
                                </v-col>                             
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!-- GRAFICO -->
                    <v-tab-item>
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="4" align="center">
                                    <v-select :items="GE.grafici" item-text="nome" item-value="valore" label="Tipo di Grafico" v-model="GE.dettaglio.famiglia_grafici"/>
                                </v-col>
                                <v-col cols="4" align="center" class="pt-7">
                                    <v-btn text @click="showClick">
                                        <v-icon>mdi-cursor-default-click-outline</v-icon>
                                        Configura click
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <div v-if="GE.mostraAssi(GE.dettaglio)">
                                <v-row align="center">
                                    <v-col cols="3" align="right">
                                        <v-checkbox disabled label="Asse X" v-model="GE.dettaglio.assi[0].e"/>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-text-field label="Nome Asse X" v-model="GE.dettaglio.assi[0].n"/>
                                    </v-col> 
                                </v-row>
                                <v-row align="center">
                                    <v-col cols="3" align="right">
                                        <v-checkbox disabled label="Asse Y" v-model="GE.dettaglio.assi[1].e"/>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-text-field label="Nome Asse Y" v-model="GE.dettaglio.assi[1].n"/>
                                    </v-col> 
                                </v-row>
                                <v-row align="center">
                                    <v-col cols="3" align="right">
                                        <v-checkbox label="Asse X2" v-model="GE.dettaglio.assi[2].e"/>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-text-field :disabled="GE.dettaglio.assi[2].e==false" label="Nome Asse X2" v-model="GE.dettaglio.assi[2].n"/>
                                    </v-col> 
                                </v-row>
                                <v-row align="center">
                                    <v-col cols="3" align="right">
                                        <v-checkbox label="Asse Y2" v-model="GE.dettaglio.assi[3].e"/>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-text-field :disabled="GE.dettaglio.assi[3].e==false" label="Nome Asse Y2" v-model="GE.dettaglio.assi[3].n"/>
                                    </v-col> 
                                </v-row>
                            </div>
                        </v-container>                        
                    </v-tab-item>
                    <!-- PARAMETRI -->
                    <v-tab-item>
                        <v-container>
                            <TextEditor v-model="GE.dettaglio" :template="GE.editor" :key="key"/>
                            <v-row>
                                <v-col cols="12">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>Parametri</v-expansion-panel-header>                                    
                                            <v-expansion-panel-content>
                                                <v-data-table dense hide-default-footer disable-pagination :headers="GE.testata_parametri" :items="GE.dettaglio.parametri">
                                                    
                                                    <!-- COLONNA NOME -->
                                                    <template v-slot:[`item.nome`]="{ item }">
                                                        <v-text-field v-model="item.nome" />
                                                    </template>

                                                    <!-- COLONNA TIPO DATI -->
                                                    <template v-slot:[`item.tipo`]="{ item }">
                                                        <v-select v-model="item.tipo" :items="GE.tipi_dati" item-text="nome" item-value="valore" />
                                                    </template>

                                                    <!-- COLONNA VALORE -->
                                                    <template v-slot:[`item.valore`]="{ item }">
                                                         <v-text-field v-model="item.valore" :type="GE.getTipoInput(item.tipo)" />
                                                    </template>

                                                </v-data-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>Serie</v-expansion-panel-header>                                    
                                            <v-expansion-panel-content>
                                                <v-data-table dense hide-default-footer disable-pagination :headers="GE.getTestata(GE.dettaglio)" :items="GE.dettaglio.serie">
                                                    
                                                    <!-- COLONNA NOME -->
                                                    <template v-slot:[`item.nome`]="{ item }">
                                                        <v-text-field v-model="item.nome" title="{$nome_parametro} per parametro overall"/>
                                                    </template>
                                                    
                                                    <!-- COLONNA ASSE -->
                                                    <template v-slot:[`item.valore`]="{ item }">
                                                        <v-select v-model="item.valore" :items="GE.getAssi(item)" item-text="nome" item-value="valore" />
                                                    </template>

                                                    <!-- COLONNA TIPO DATI -->
                                                    <template v-slot:[`item.tipo_dati`]="{ item }">
                                                        <v-select v-model="item.tipo_dati" :items="GE.tipi_dati" item-text="nome" item-value="valore" />
                                                    </template>

                                                    <!-- COLONNA TIPO SERIE -->
                                                    <template v-slot:[`item.tipo_grafico`]="{ item }">
                                                        <v-select v-model="item.tipo_grafico" :items="GE.grafici[GE.dettaglio.famiglia_grafici].tipi" item-text="nome" item-value="valore" :disabled="GE.getValiditaOpzioni(item)"/>
                                                    </template>

                                                    <!-- COLONNA FORMATO -->
                                                    <template v-slot:[`item.formato`]="{ item }">
                                                        <v-btn icon :disabled="GE.getValiditaOpzioni(item)" @click="showFormato(item)">
                                                            <v-icon>mdi-palette</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <!-- COLONNA NUMERO -->
                                                    <template v-slot:[`item.numero`]="{ item }">
                                                        <v-btn icon v-if="item.tipo_dati==1" @click="showNumero(item)">
                                                            <v-icon>mdi-pound</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <!-- COLONNA OPZIONI GRAFICO CARTESIANO -->
                                                    <template v-slot:[`item.cartesiano`]="{ item }">
                                                        <v-btn icon v-if="GE.is_bar(GE.dettaglio,item)" @click="showBar(item)">
                                                            <v-icon>mdi-finance</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <!-- COLONNA MACRO -->
                                                    <template v-slot:[`item.macro`]="{ item }">
                                                        <v-btn icon v-if="GE.is_macroable(GE.dettaglio,item)" @click="showMacro(item)">
                                                            <v-icon>mdi-table-cog</v-icon>
                                                        </v-btn>
                                                    </template>

                                                </v-data-table>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!-- DATABASE -->
                    <v-tab-item>
                        <v-container>
                            <v-row justify="center">
                                <v-radio-group v-model="GE.dettaglio.tipo_query">
                                    <v-radio v-for="(item,i) in GE.tabelle" :key="i" :label="item.cod" :value="item.id_tabella"></v-radio>
                                </v-radio-group>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-form>
            <v-card-actions>
                <v-btn text color="error" @click="save(false)"><v-icon class="mr-2">mdi-delete</v-icon>Elimina Grafico</v-btn>
                <v-spacer />
                <v-btn text color="primary" @click="save"><v-icon class="mr-2">mdi-content-save</v-icon>Salva Grafico</v-btn>
            </v-card-actions>
       </v-card>
       <v-dialog v-model="GE.ui.exit_prompt" width="300">
           <v-card>
               <v-card-title>Abbandonare le modifiche?</v-card-title>
               <v-card-actions>
                   <v-btn text color="error" @click="GE.ui.exit_prompt=false">Annulla</v-btn>
                   <v-spacer />
                   <v-btn text color="success" @click="GE.ui.exit_prompt=false;GE.ui.show=false">Conferma</v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
       <Formato v-model="GE.dettaglio"/>
       <GraphEditorNumero v-model="GE.dettaglio" />
       <GraphEditorBar v-model="GE.dettaglio" />
       <GraphEditorMacro v-model="GE.dettaglio" />
       <GraphEditorClick v-model="GE.dettaglio" />
   </v-dialog>
</template>
<script>
import GRAPH_EDITOR from "./GraphEditor.js";
import TextEditor from "./TextEditor.vue";
import Formato from "./Dialogs/Formato.vue";
import GraphEditorNumero from "./Dialogs/Numero.vue";
import GraphEditorBar from "./Dialogs/Cartesian.vue";
import GraphEditorMacro from "./Dialogs/Macro.vue";
import GraphEditorClick from "./Dialogs/Click.vue";
export default {
    name : "GraphEditor",
    components : {
        TextEditor,
        Formato,
        GraphEditorNumero,
        GraphEditorBar,
        GraphEditorMacro,
        GraphEditorClick
    },
    data(){
        return{ 
            GE : new GRAPH_EDITOR(),  
            id: null,       
            key : 0,             
            NETWORK : null,
            old : {
                parametri : [],
                serie : [],
            },
            template_dettaglio : {
                id_ds_dettaglio : -1,
                id_ds_rif : null,
                nome : 'Nuovo Grafico',
                descrizione : null,
                query : null,
                tipo_query : null,
                famiglia_grafici : 0,
                layout : {x:0,y:0,w:300,h:300},
                assi : [
                    {'n':'Asse X','o':0,'e':true},
                    {'n':'Asse Y','o':1,'e':true},
                    {'n':'Asse X2','o':2,'e':false},
                    {'n':'Asse Y2','o':3,'e':false}
                ],
                validita : 1,
                serie : [],
                parametri : [],
            }
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        document.body.addEventListener('graph_editor',this.show_editor);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_editor',this.show_editor);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        show_editor(data){
            let index = data.detail.index;
            this.id = data.detail.ds;
            let that = this;           

            let database_list_request =  {
                controller : 'Global',
                body : { 
                    action: "gettabella",
                    tipo: "TIPI_QUERY_GRAFICI",
                    token: localStorage.user_token
                },
                method: 'POST',
                response : { parse : false },
                on : {
                    load : function(response){                
                        try{
                            response = JSON.parse(response);
                            that.GE.tabelle = response;
                        }catch(error){
                            return;
                        }
                        if(index == -1){
                            that.GE.dettaglio = Object.assign({},that.template_dettaglio);
                            that.GE.dettaglio.layout = Object.assign({},that.template_dettaglio.layout);
                            that.GE.dettaglio.assi = that.template_dettaglio.assi.map((x)=>x);
                            
                            if(data.detail.derived == true){
                                that.GE.dettaglio.id_ds_rif = -1;
                                that.GE.dettaglio.id_padre = that.id;
                            }else{
                                that.GE.dettaglio.id_ds_rif = that.id;
                            }  

                            that.old.parametri = [];
                            that.old.serie = [];
                            that.GE.ui.show = true;
                            that.GE.ui.tab = 0;
                            that.key++;
                            return;
                        }
                        let graph_detail_request = {
                            controller : 'Graph',
                            body : {
                                action: "getDettaglioGrafico",
                                id_ds_dettaglio: index,
                                token: localStorage.user_token,
                            },
                            method: 'POST',
                            on : {
                                load : function(response){                                    
                                    try{
                                        response = response.grafici[0];
                                        try{
                                            response.layout = JSON.parse(decodeURIComponent(escape(atob(response.layout))));
                                            response.assi = JSON.parse(decodeURIComponent(escape(atob(response.assi))));
                                        }catch(error){
                                            response.layout = {x:0,y:0,w:300,h:300};
                                            response.assi = [{"n":"Asse X","o":0,"e":true},{"n":"Asse Y","o":1,"e":true},{"n":"Asse X2","o":2,"e":false},{"n":"Asse Y2","o":3,"e":false}];
                                        }                                        
                                    }catch(error){
                                        return;
                                    }
                                    for(let i =0; i < response.parametri.length; i++){
                                        response.parametri[i].tipo = Number(response.parametri[i].tipo);
                                    }
                                    that.GE.dettaglio = response;
                                    that.old.parametri = response.parametri.map((x)=>x);
                                    that.old.serie = response.serie.map((x)=>x);
                                    that.GE.ui.show = true;
                                    that.GE.ui.tab = 0;
                                    that.key++;       
                                }
                            }
                        }
                        that.NETWORK.AjaxRequest(graph_detail_request);
                    }
                }
            }
            this.NETWORK.AjaxRequest(database_list_request);            
        },
        save(update=true){
            let dettaglio = Object.assign({},this.GE.dettaglio);
            let parametri = this.GE.dettaglio.parametri.map((x)=>x);
            let serie = this.GE.dettaglio.serie.map((x)=>x);

            // Eliminazione
            if(!update){
                dettaglio.validita = 0;
                serie = [];
                parametri = [];
            }

            for(let i = 0; i < parametri.length; i++){ parametri[i].ordine = i; }
            for(let i = 0; i < serie.length; i++){ serie[i].ordine = i; }

            if(Array.isArray(dettaglio.condizioni)){
                serie = serie.concat(dettaglio.condizioni);
            }            

            // Campi ridondanti
            delete dettaglio.serie;
            delete dettaglio.parametri;

            // codifica come base64 con supporto UTF-8
            dettaglio.assi = btoa(unescape(encodeURIComponent(JSON.stringify(dettaglio.assi))));
            dettaglio.layout = btoa(unescape(encodeURIComponent(JSON.stringify(dettaglio.layout))));

            // Eventuale azzeramento validità di parametri/serie eliminati

            // PARAMERI
            for(let i=0;i<this.old.parametri.length;i++){
                let found = false;
                for(let j=0;j<parametri.length;j++){
                    if(parametri[j].id_ds_dettaglio_param == this.old.parametri[i].id_ds_dettaglio_param){
                        found=true;
                        break;
                    }
                }
                if(!found){
                    this.old.parametri[i].validita = 0;
                    parametri.push(this.old.parametri[i]);
                }
            }
            // SERIE
            for(let i=0;i<this.old.serie.length;i++){
                let found = false;
                for(let j=0;j<serie.length;j++){
                    if(serie[j].id_ds_dettaglio_serie == this.old.serie[i].id_ds_dettaglio_serie){
                        found=true;
                        break;
                    }
                }
                if(!found){
                    this.old.serie[i].validita = 0;
                    serie.push(this.old.serie[i]);
                }
            }

            let that = this;
            let request =  {
                controller : 'Graph',
                body : {
                    action: "setDashboardDettaglio",
                    token: localStorage.user_token,
                    jsonDettaglio: [dettaglio],
                    jsonParam: parametri,
                    jsonSerie: serie,
                },
                method: 'PUT',
                on : {
                    load : function(){                    
                        let msg = new CustomEvent('dashboardrefresh',{detail:null});
                        document.getElementById('quickchart_dashboard_main').dispatchEvent(msg);
                        that.GE.ui.show = false;                   
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
            that.GE.ui.show = false;
        },
        showFormato(item){
            let event = new CustomEvent('graph_editor_formato',{detail:item});
            document.body.dispatchEvent(event);
        },
        showNumero(item){
            let event = new CustomEvent('graph_editor_numero',{detail:item});
            document.body.dispatchEvent(event);
        },
        showBar(item){
            let event = new CustomEvent('graph_editor_bar',{detail:item});
            document.body.dispatchEvent(event);
        },
        showMacro(item){
            let event = new CustomEvent('graph_editor_macro',{detail:item});
            document.body.dispatchEvent(event);
        },
        showClick(){
            let event = new CustomEvent('graph_editor_click',{detail:null});
            document.body.dispatchEvent(event);
        }
    }
}
</script>
<style src="./GraphEditor.css" scoped></style>
