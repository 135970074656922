<template>
  <v-card>
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text">Modifica Barche</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaModificaBarca" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Modifica</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on">
            <v-icon color="error"> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="4" dense>
            <v-text-field
              v-model="barca_sel_tmp.des_macchina"
              label="Nome Barca"
            ></v-text-field>
          </v-col>
          <v-col cols="4" dense>
            <v-text-field
              v-model="barca_sel_tmp.matricola"
              label="Matricola"
            ></v-text-field>
          </v-col>
          <v-col cols="4" dense>
            <v-text-field
              v-model="barca_sel_tmp.modello"
              label="Modello"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" dense>
            <v-menu
              v-model="calendario_inizio_garanzia"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  hide-details
                  label="Inizio Garanzia"
                  v-model="inizio_garanzia_formattata"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="barca_sel_tmp.inizio_garanzia"
                @input="calendario_inizio_garanzia = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" dense>
            <v-menu
              v-model="calendario_fine_garanzia"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  hide-details
                  label="Fine Garanzia"
                  v-model="fine_garanzia_formattata"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                @input="calendario_fine_garanzia = false"
                :min="barca_sel_tmp.inizio_garanzia"
                v-model="barca_sel_tmp.fine_garanzia"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" dense>
            <v-menu
              v-model="calendario_data_consegna"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  hide-details
                  label="Data Consegna"
                  v-model="data_acquisto_formattata"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="barca_sel_tmp.data_acquisto"
                @input="calendario_data_consegna = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>

<script>
import moment from "moment/moment";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ModificaBarca",
  components: {
    SnackbarQV,
  },
  props: {
    barca_sel: {
      type: Object,
      default: () => {},
    },
    id_filtro_nave: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      barca_sel_tmp: {},

      // Modal
      calendario_data_consegna: false,
      calendario_fine_garanzia: false,
      calendario_inizio_garanzia: false,

      // Array
    };
  },
  computed: {
    data_acquisto_formattata() {
      return this.barca_sel_tmp.data_acquisto != ""
        ? moment(this.barca_sel_tmp.data_acquisto).format("DD/MM/YYYY")
        : "";
    },
    fine_garanzia_formattata() {
      return this.barca_sel_tmp.fine_garanzia != ""
        ? moment(this.barca_sel_tmp.fine_garanzia).format("DD/MM/YYYY")
        : "";
    },
    inizio_garanzia_formattata() {
      return this.barca_sel_tmp.inizio_garanzia != ""
        ? moment(this.barca_sel_tmp.inizio_garanzia).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.barca_sel_tmp = Object.assign({}, this.barca_sel);
        }
      },
    },
  },
  methods: {
    chiudiDialog() {
      this.$emit("chiudi_dialog");
    },
    salvaModificaBarca() {
      // Faccio la query di salvataggio

      // Faccio in modo che la macchina sia segnata come nave.
      this.barca_sel_tmp.id_tipo_get_macchina = this.id_filtro_nave;

      const dataarray = [];
      dataarray.push(Object.assign({}, this.barca_sel_tmp));
      const request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updatemacchina",
          token: localStorage.getItem("user_token"),
          tabella: dataarray,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.$emit("salvata_macchina");
        } else {
          this.$refs.SnackbarQV.print(
            "orange",
            `Criticità tecnica con codice ${res.status}, non è stato possibile salvare i dati.`
          );
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
</style>