<template>
  <v-card class="ma-3">
    <v-snackbar v-model="snackbar" :vertical="true">
      {{ snackbar_messaggio }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-toolbar color="primary" dark flat>
      <v-btn class="px-0" color="error" @click="indietro">
        <v-icon>mdi-undo</v-icon>
      </v-btn>

      <v-toolbar-title class="ml-2">Richieste</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-toolbar flat color="white">
        <v-menu
          ref="modalDataDa"
          v-model="modalDataDa"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dataDa"
              label="Data da"
              prepend-icon="event"
              @blur="date = parseDate(dataDa)"
              single-line
              hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataDa"
            no-title
            @input="modalDataDa = false"
          ></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-menu
          ref="modalDataA"
          v-model="modalDataA"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dataA"
              label="Data A"
              prepend-icon="event"
              @blur="date = parseDate(dataA)"
              single-line
              hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataA"
            no-title
            @input="modalDataA = false"
          ></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-select
          v-model="statoSearch"
          :items="statoLista"
          label="Stato"
          item-text="des"
          item-value="id_tabella"
          single-line
          hide-details
        ></v-select>
        <v-spacer></v-spacer>
        <v-select
          v-model="resposanbileSearch"
          :items="responsabileLista"
          label="Responsabile"
          item-text="cognome"
          item-value="id_personale"
          single-line
          hide-details
        ></v-select>

        <v-spacer></v-spacer>
        <v-select
          v-model="categoriaSearch"
          :items="categoriaLista"
          label="Categoria"
          item-text="des"
          item-value="id_tabella"
          single-line
          hide-details
          menu-props="auto"
          prepend-icon="map"
        ></v-select>
        <v-spacer></v-spacer>
        <v-icon large color @click="loadAttivitaLista">mdi-magnify</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" fab small dark @click="openModalRichieste">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="richiesteLista"
        class="elevation-1"
        hide-default-footer
        disable-sort
      >
        <template v-slot:item.des_stato="{ item }">
          <v-icon
            v-if="item.des_stato == 'IN LAVORAZIONE'"
            class="ma-2"
            text
            icon
            color="orange lighten-2"
            >mdi-checkbox-blank-circle</v-icon
          >
          <v-icon
            v-if="item.des_stato == 'ANNULLATA'"
            class="ma-2"
            text
            icon
            color="red lighten-2"
            >mdi-checkbox-blank-circle</v-icon
          >
          <v-icon
            v-if="item.des_stato == 'CONCLUSA'"
            class="ma-2"
            text
            icon
            color="green lighten-2"
            >mdi-checkbox-blank-circle</v-icon
          >
          <v-icon
            v-if="item.des_stato == 'ATTIVA'"
            class="ma-2"
            text
            icon
            color="blue lighten-2"
            >mdi-checkbox-blank-circle</v-icon
          >
        </template>
        <template v-slot:item.data_richiesta="{ item }">{{
          item.data_richiesta | formatDate
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editAttivita(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="modalInsertRichiesta" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Inserisci una richiesta</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-card class="mx-auto" outlined min-height="350px">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">DATI</div>
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model="editedRichiesta.cod_richiesta"
                            label="Codice Richiesta"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="7">
                          <v-text-field
                            v-model="editedRichiesta.titolo_richiesta"
                            :counter="20"
                            label="Titolo Richiesta"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-menu
                            ref="modalDataScadenza"
                            v-model="modalDataScadenza"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedRichiesta.data_richiesta"
                                label="Data"
                                prepend-icon="event"
                                @blur="
                                  editedRichiesta.data_richiesta = parseDate(
                                    editedRichiesta.data_richiesta
                                  )
                                "
                                v-on="on"
                                :disabled="isRicorsiva"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedRichiesta.data_richiesta"
                              no-title
                              @input="modalDataScadenza = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col md="2"></v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="editedRichiesta.id_responsabile"
                            :items="responsabileLista"
                            label="Responsabile"
                            required
                            item-text="cognome"
                            item-value="id_personale"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="editedRichiesta.id_categoria"
                            :items="categoriaLista"
                            label="Categoria"
                            required
                            item-text="des"
                            item-value="id_tabella"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" outlined min-height="350px">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">SPECIFICHE</div>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="editedRichiesta.id_sede"
                            :items="sedeLista"
                            label="Sede"
                            item-text="des"
                            item-value="id_tabella"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="editedRichiesta.id_struttura"
                            :items="strutturaLista"
                            label="Struttura"
                            item-text="des"
                            item-value="id_tabella"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="editedRichiesta.id_area"
                            :items="areaLista"
                            label="Area"
                            item-text="des"
                            item-value="id_tabella"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="editedRichiesta.id_zona"
                            :items="zonaLista"
                            label="Zona"
                            item-text="des"
                            item-value="id_tabella"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col>
                <v-card class="mx-auto" outlined min-height="350px">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Generiche</div>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="editedRichiesta.email_richiesta"
                            label="Email Avviso"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-textarea
                            v-model="editedRichiesta.note"
                            outlined
                            name="input-7-2"
                            label="Note"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row></v-row>
          </v-container>
          <small></small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="modalInsertRichiesta = false"
            >Chiudi</v-btn
          >
          <v-btn color="blue darken-1" text @click="insertRichiesta()"
            >Salva</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>
import config from "../../../public/config";
import axios from "axios";

export default {
  name: "Richieste",
  data: () => ({
    config: config,
    isRicorsiva: false,
    isManutenzione: false,
    loading: true,
    tipo: "",
    modulo: "",
    tipoLogin: "",
    modalTab: "dettaglio",
    dataDa: new Date().toISOString().substr(0, 10),
    dataA: new Date().toISOString().substr(0, 10),
    dataInizio: new Date().toISOString().substr(0, 10),
    dataScadenza: new Date().toISOString().substr(0, 10),
    modalDataDa: false,
    modalDataA: false,
    modalDataScadenza: false,
    modalInsertRichiesta: false,
    modalDataInizio: false,

    dialog: false,
    attivita_sel: {},
    headers: [
      {
        text: "Codice",
        align: "start",
        sortable: false,
        value: "cod_richiesta",
      },
      {
        text: "Data",
        align: "start",
        sortable: false,
        value: "data_richiesta",
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "email_richiesta",
      },
      {
        text: "Responsabile",
        align: "start",
        sortable: false,
        value: "nominativo_responsabile",
      },
      {
        text: "Categoria",
        align: "start",
        sortable: false,
        value: "des_categoria",
      },
      {
        text: "Stato",
        align: "start",
        sortable: false,
        value: "des_stato",
      },
      {
        text: "Modulo",
        align: "start",
        sortable: false,
        value: "des_modulo",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
    snackbar: false,
    snackbar_messaggio: "",

    dialogAddTeam: false,
    dialogAddEvent: false,
    dialogFirmaAttivita: false,
    dialogAnnullaAttivita: false,
    dialogNoteInterne: false,
    dialogViewAttivitaDettaglio: false,
    attivitaLista: [],
    richiesteLista: [],
    attivitaSelect: [],
    attivita: [],
    team: [],
    teamSelect: [],
    eventi: [],
    eventiSelect: [],
    responsabileLista: [],
    statoLista: [],
    categoriaLista: [],
    richiedenteLista: [],
    sedeLista: [],
    zonaLista: [],
    strutturaLista: [],
    areaLista: [],
    probabilitaLista: [],
    dannoLista: [],
    newTeamList: [],
    categoriaSearch: "",
    resposanbileSearch: "",
    statoSearch: "",
    macchinaLista: [],
    stato_pagina: "",
    attivitaIndex: -1,
    editedIndex: -1,
    isSigned: false,
    statoColor: "green",
    newEvento: "",
    noteInterne: "",
    editedAttivita: {},
    editedRichiesta: {},
    dates: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDateFormattedDa() {
      return this.formatDate(this.dataDa);
    },
    computedDateFormattedA() {
      return this.formatDate(this.dataA);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.modulo = this.$route.params.modulo;
    console.log(this.$route.params.modulo);
    this.categoriaSelectSearch = [];
    this.resposanbileSelectSearch = [];
    var tomorrow = new Date();
    var tomorrow2 = new Date();
    tomorrow2.setDate(new Date().getDate() - 5);
    tomorrow.setDate(new Date().getDate() + 30);
    this.dataDa = tomorrow2.toISOString().substr(0, 10);
    this.dataA = tomorrow.toISOString().substr(0, 10);

    //this.initialize();
  },
  mounted() {
    this.getInfoModulo();
    this.loadResponsabile();
    this.getRichiesta();
    this.loadCombo();
  },
  methods: {
    getInfoModulo() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "gettabella",
        token: localStorage.user_token,
        id_utente: localStorage.id,
        tipo: "STATO_ATTIVITA",
        cod: "A",
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Global", data, config)
        .then((response) => {
          console.log(response);
          if (response.data != "") {
            this.stato_pagina = response.data[0].id_tabella;
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE I RESPONSABILI !";
          this.snackbar = true;
          console.log(e);
        });
      console.log("a", this.$store.state.ip);
      var finale2 = {
        action: "gettabella",
        token: localStorage.user_token,
        id_utente: localStorage.id,
        tipo: "ATTIVITA_MODULO",
        cod: "M",
      };
      var data2 = JSON.stringify(finale2);
      axios
        .post(this.$store.state.config.ip + "api/Global", data2, config)
        .then((response) => {
          console.log(response);
          if (response.data != "") {
            this.modulo = response.data[0].id_tabella;
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE I RESPONSABILI !";
          this.snackbar = true;
          console.log(e);
        });
    },
    editAttivita(item) {
      // this.modalTab = "dettaglio";
      // this.newEvento = "";
      // this.dialogViewAttivitaDettaglio = true;
      // this.editedAttivita = Object.assign({}, item);
      // this.attivitaIndex = this.attivitaLista.indexOf(item);
      // this.attivitaSelect.push(item);
      // this.statoColor = "orange";
      // //this.loadAttivita();
      // this.editedAttivita.desStato = "NUOVA";
      // if (item.stato == 1) {
      //   this.statoColor = "blue";
      //   this.editedAttivita.desStato = "IN LAVORAZIONE";
      // } else if (item.stato == 2) {
      //   this.statoColor = "green";
      //   this.editedAttivita.desStato = "CONCLUSA";
      // } else if (item.stato == 3) {
      //   this.statoColor = "red";
      //   this.editedAttivita.desStato = "ANUNLLATA";
      // }
      // this.noteInterne = item.noteInterne;

      this.$router
        .push("/home/attivita/" + item.id_richiesta)
        .catch((ex) => console.log(ex));
    },

    newAttivita() {
      this.modalTab = "dettaglio";
      this.noteInterne = "";
      this.newEvento = "";
      this.dialogViewAttivitaDettaglio = true;
      this.editedAttivita = {};
      this.statoColor = "orange";
      this.editedAttivita.desStato = "NUOVA";
    },

    salvaAttivita() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "putAttivita",
        token: localStorage.user_token,
        id_utente: localStorage.id,
        tabella: this.editedAttivita,
      };
      var data = JSON.stringify(finale);
      axios
        .put(this.$store.state.config.ip + "api/Attivita", data, config)
        .then((response) => {
          if (response.data == "errore") {
            return;
          }
          this.dialogViewAttivitaDettaglio = false;
          this.editedAttivita = {};
          this.alert = true;
          this.alert_text = "Attività salvata con successo !";
          console.log(this.attivita);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }
          //controllare password ed utente
          this.snackbar_testo = "ERRORE! IMPOSSIBILE TARARE  !";
          this.snackbar = true;
          console.log(e);
        });
    },

    firmaAttivita() {},

    closeAttivita() {
      this.dialogViewAttivitaDettaglio = false;
      this.editedAttivita = {};
      this.eventiSelect = {};
      this.teamSelect = {};
    },

    concludiAttivita() {},

    addTeam() {
      if (this.attivita_sel["qm_progetti_attivita_team"] == undefined) {
        this.attivita_sel["qm_progetti_attivita_team"] = [];
        this.attivita_sel["qm_progetti_attivita_team"].push(this.newTeamList);
      } else {
        this.attivita_sel["qm_progetti_attivita_team"].push(this.newTeamList);
      }
      this.dialogAddTeam = false;
    },

    addEvento() {
      console.log(this.newEvento);

      this.newEvento = "";
    },

    loadResponsabile() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "getutenti",
        token: localStorage.user_token,
        idUtente: localStorage.id,
        modulo: 3251,
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/User", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          if (response.data == "") {
            this.responsabileLista = [];
            this.richiedenteLista = [];
          } else {
            this.responsabileLista = response.data.result.utenti;
            this.richiedenteLista = response.data.result.utenti;
          }
        })
        .catch((e) => {
          this.responsabileLista = [];
          this.richiedenteLista = [];

          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE I RESPONSABILI !";
          this.snackbar = true;
          console.log(e);
        });
    },

    loadCombo() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "gettabella",
        token: localStorage.user_token,

        id_utente: localStorage.id,
        tipo:
          "'CATEGORIA_ATTIVITA_MEDICAL','AREA','STRUTTURA','STATO_ATTIVITA','SEDE','ZONA'",
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Global", data, config)
        .then((response) => {
          console.log(response);
          this.statoLista = response.data.STATO_ATTIVITA;
          this.categoriaLista = response.data.CATEGORIA_ATTIVITA_MEDICAL;
          this.sedeLista = response.data.SEDE;
          this.strutturaLista = response.data.STRUTTURA;
          this.areaLista = response.data.AREA;
          this.zonaLista = response.data.ZONA;
        })
        .catch((e) => {
          this.responsabileLista = [];
          this.richiedenteLista = [];

          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE I RESPONSABILI !";
          this.snackbar = true;
          console.log(e);
        });
    },

    openNoteInterne() {
      this.noteInterne = this.editedAttivita.note_interne;
      this.dialogNoteInterne = true;
    },

    salvaNoteInterneAttivita() {
      this.editedAttivita.note_interne = this.noteInterne;
      this.dialogNoteInterne = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      if (date.indexOf("/") !== -1) {
        const [month, day, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
      return date;
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    indietro() {
      this.$router.replace("dashboard");
      // location.realod();
    },
    insertRichiesta() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var tabel = [];
      this.editedRichiesta.id_richiesta = -1;
      this.editedRichiesta.id_modulo = this.modulo;
      this.editedRichiesta.id_richiedente = localStorage.id;
      this.editedRichiesta.id_stato = this.stato_pagina;

      tabel.push(this.editedRichiesta);
      var finale = {
        action: "setrichiesta",
        token: localStorage.user_token,
        modulo: this.modulo,
        tabella: tabel,
        id_utente: localStorage.id,
        db_name: "medical",
      };
      var data = JSON.stringify(finale);
      axios
        .put(this.$store.state.config.ip + "api/Attivita", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response);
          this.editedRichiesta = {};
          this.modalInsertRichiesta = false;
          this.richiesteLista.push(tabel);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar = true;
          console.log(e);
        });
    },
    getRichiesta() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "getrichiesta",
        token: localStorage.user_token,
        id_modulo: this.modulo,
        id_utente: localStorage.id,
        data: [this.dataDa, this.dataA],
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Attivita", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.ù
          console.log(response);
          if (response.status == "204") {
            this.richiesteLista = [];
          } else {
            this.richiesteLista = response.data;
          }
          console.log(this.richiesteLista);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("user_token");
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar = true;
          console.log(e);
        });
    },
    openModalRichieste() {
      this.modalInsertRichiesta = true;
    },
    loadAttivitaLista() {
      this.getRichiesta();
    },
  },
};
</script>