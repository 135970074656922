<template>
  <div class="gestione_moduli">
    <v-card>
      <!-- TOOLBAR sia per radice che moduli-->
      <v-toolbar dense color="primary" dark align="center">
        <v-row>
          <v-col cols="4" align="left">
           
          </v-col>
          <v-col cols="1">
            <!--
            <v-btn small light @click.stop="showTree">
              <v-icon color="primary">mdi-file-tree</v-icon>
            </v-btn>
            -->
          </v-col>
          <v-col cols="1">
            <v-btn small light @click="clickAggiungiItem">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" align="right">
            <v-btn small light @click="setModuli">
              <v-icon color="success">mdi-content-save</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" align="right">

          </v-col>
          <v-col cols="4" align="right">
            <v-btn small light @click="getModuli">
              <v-icon color="primary">mdi-autorenew</v-icon>
            </v-btn>            
          </v-col>
        </v-row>
      </v-toolbar>

      <!--  TABLE Gestione Modulo RADICE con Medaglione-->
      <v-container v-if="secondaryTable == false">
        <v-data-table :headers="head" :items="moduli">
          <template v-slot:[`item.id_modulo`]="{ item }">
            <v-btn small @click="tableToggle(item.index)">
              <v-icon color="primary">mdi-file-tree</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.edita`]="{ item }">
            <v-btn small @click="clickModificaModulo(item)">
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.img_modulo`]="{ item }">
            <div style="text-align: center">
              <v-icon v-if="item.img_modulo == null" style="margin: 0 auto"
                >mdi-image-off</v-icon
              >
              <v-img
                style="margin: 0 auto; display: block"
                max-width="24"
                v-if="item.img_modulo != null"
                :src="item.img_modulo"
              />
            </div>
          </template>

          <template v-slot:[`item.tipo_modulo`]="{ item }">
            {{ item.tipo_modulo == 0 ? "Medaglione" : "Menu Dx" }}
          </template>

          <template v-slot:[`item.delete_toggle`]="{ item }">
            <v-btn small @click="deleteToggle(item)">
              <v-icon color="error">{{
                item.validita == 0 ? "mdi-delete-restore" : "mdi-delete"
              }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>

      <!--  SECOND TAB per gestione e modifica moduli sotto il Modulo Radice -->
      <v-container v-if="secondaryTable == true">
        <v-data-table 
          :headers="head_menu" 
          :items="moduli[selected.index].moduli_menu"
          >
          <template v-slot:[`item.edita`]="{ item }">
            <v-btn small @click="clickModificaModulo(item)">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.icon`]="{ item }">
            <v-icon v-if="item.icon == null">mdi-image-off</v-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </template>

          <template v-slot:[`item.delete_toggle`]="{ item }">
            <v-btn small @click="deleteToggle(item)">
              <v-icon color="error">{{
                item.validita == 0 ? "mdi-delete-restore" : "mdi-delete"
              }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <!-- DIALOG CREA/MODIFICA Modulo Radice Campi e Moduli -->
    <v-dialog v-model="dialogEditItem" persistent>
      
      <!-- DIALOG modifica  Gestione Root -->
      <v-card v-if="!secondaryTable">
        <v-toolbar color="primary" dense dark>
          <v-row align="center">
            <v-col cols="5" align="left"> 
              <v-toolbar-title>{{
                template.id_modulo === -1 ? "Aggiungi Modulo" : "Modifica Modulo"
              }}</v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-btn small @click="clickSalva" light>
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="5" align="right">
              <v-btn small @click="chiudiModificaDialog" light>
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-container>
          <v-row  align="center">
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="template.cod_modulo"
                label="Cod Modulo"
              />
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="template.des_modulo"
                label="Descrizione Modulo"
              />
            </v-col>
            <v-col>
              <v-row>
                <v-img
                  class="ml-1"
                  max-width="40"
                  max-height="40"
                  v-if="template.img_modulo != null"
                  :src="template.img_modulo"
                />
                <template>
                  <v-btn class="ma-1 ml-3" small @click="uploadAndCompress">{{
                    template.img_modulo == null ? "Carica Immagine" : "Modifica Immagine"
                  }}</v-btn>
                </template>
                <template v-if="template.img_modulo != null">
                  <v-btn class="ma-1" small @click="template.img_modulo = null"
                    >Rimuovi Immagine</v-btn
                  >
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col>
              <v-text-field hide-details dense v-model="template.link" label="Link" />
            </v-col>
            <v-col>
              <v-select
                :items="tipi_moduli"
                v-model="template.tipo_modulo"
                dense
                label="Tipo Modulo"
              />
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="template.tipo_chiamata"
                label="Tipo Chiamata"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="template.json_parametri"
                label="JSON Parametri"
              />
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="template.link_username"
                label="Link Username"
              />
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                dense
                v-model="template.link_password"
                label="Link Password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!-- DIALOG modifica  Gestione modulo-->
      <v-card v-if="secondaryTable">
        <v-toolbar color="primary" dense dark>
          <v-row align="center">
            <v-col cols="5" align="left">
              <v-toolbar-title>{{
                template_menu.id_modulo_menu === -1
                  ? "Aggiungi Modulo"
                  : "Modifica Modulo"
              }}</v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-btn small @click="clickSalva" light>
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="5" align="right">
              <v-btn small @click="chiudiModificaDialog" light>
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="3">
              <v-text-field
                hide-details
                dense
                v-model="template_menu.des"
                label="Descrizione"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                hide-details
                dense
                v-model="template_menu.icon"
                label="Icona"
                :prepend-icon="
                  template_menu.icon == null ? 'mdi-image-off' : template_menu.icon
                "
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                hide-details
                dense
                v-model="template_menu.text"
                label="Nome"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                hide-details
                dense
                v-model="template_menu.path"
                label="Percorso"
              />
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="3">
              <v-text-field
                hide-details
                dense
                v-model="template_menu.link_menu"
                label="Link Menu"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                :false-value="0"
                :true-value="1"
                v-model="template_menu.form_startup"
                label="Form Startup"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                hide-details
                dense
                v-model="template_menu.pagina"
                label="Ordine"
              ></v-text-field>
            </v-col>

            <!-- Datatable Parametri Modulo -->
            <v-col cols="12" sm="12" md="12">
                <template>
                    <v-data-table
                        :headers="headers_param_menu"
                        :items="filteredParametriValidita"
                        hide-default-footer
                        :items-per-page="12000"
                        class="elevation-1"
                                            >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                                <v-toolbar-title>Parametri Modulo</v-toolbar-title>
                                <v-divider
                                class="mx-4"
                                inset
                                vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined small v-bind="attrs" v-on="on" @click="nuovo_parametro">
                                                <v-icon color="blue"
                                                >mdi mdi-file-plus-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Nuovo Parametro</span>
                                    </v-tooltip>
                            </v-toolbar>
                        </template>     
                        <template  v-slot:[`item.cod_parametro`]="{ item }" >                                           
                            <v-text-field
                                v-model="item.cod_parametro"
                            ></v-text-field>        
                        </template>
                        <template  v-slot:[`item.val_parametro`]="{ item }" > 
                            <v-text-field
                                v-model="item.val_parametro"
                            ></v-text-field>        
                        </template> 
                        <template  v-slot:[`item.actions`]="{ item }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                        <v-icon color="purple"
                                        >mdi-delete-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Elimina Parametro </span>
                            </v-tooltip>
                        </template>      

                        <template v-slot:no-data>
                            <v-btn
                                color="primary"
                                @click="nuovo_parametro"
                            >
                                Nuovo
                            </v-btn>
                        </template>                                                                                                  

                    </v-data-table>
                </template>       
            </v-col>

          </v-row>
        </v-container>
      </v-card>

    </v-dialog>

    <aside id="tree_view" :data-hidden="aside.show == true ? false : true">
      <section v-for="(modulo, i) in moduli" :key="i" class="tree_section">
        <div>
          <img :src="modulo.img_modulo" v-if="modulo.img_modulo != null" />
          <v-icon data-lazy v-if="modulo.img_modulo == null">mdi-image-off</v-icon>
          <h3>{{ modulo.des_modulo }}</h3>
        </div>
        <section class="tree_section">
          <div style="height: 3px" :id="'tree_section_' + i + '_-1'"></div>
          <div
            v-for="(modulo_menu, j) in modulo.moduli_menu"
            :key="j"
            @mousedown="dragStart(i, j)"
            :id="'tree_section_' + i + '_' + j"
          >
            <v-icon data-lazy>{{ modulo_menu.icon }}</v-icon>
            <h4>{{ modulo_menu.text }}</h4>
          </div>
        </section>
      </section>
    </aside>

    <!-- DIALOG ERRORI -->
    <v-dialog v-model="alert.show_errors" width="300">
      <v-card>
        <v-container>
          <v-list dense>
            <v-list-item v-for="(item, i) in alert.db_errors" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR STATO OPERAZIONE -->
    <v-snackbar
      style="position: fixed; bottom: 10px"
      dark
      :color="alert.error == true ? 'error' : 'light-green darken-2'"
      v-model="alert.show"
    >
      {{ alert.message }}
      <template v-slot:action="{ attrs }">
        <span
          @click="
            alert.show_errors = true;
            alert.show = false;
          "
          style="cursor: pointer; text-decoration: underline"
          v-if="alert.db_errors.length > 0"
          >{{ alert.db_errors.length }} DB saltati</span
        >
        <v-btn class="ml-2" v-bind="attrs" text @click="alert.show = false">Chiudi</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
import moment from "moment/moment";
export default {
  name: "GestioneModuli",
  data() {
    return {
      alert: {
        show: false,
        message: null,
        error: false,
        db_errors: [],
        show_errors: false,
      },
      aside: { show: false },
      dialog: { show: false, type: null },
      dialogEditItem: false,
      head: [
        { text: null, value: "id_modulo", sortable: false, align: "left" },
        { text: null, value: "edita", sortable: false, align: "left" },
        { value: "cod_modulo", text: "COD Modulo" },
        { value: "des_modulo", text: "Descrizione" },
        { value: "img_modulo", text: "Immagine" },
        { value: "link", text: "Link" },
        { value: "tipo_modulo", text: "Tipo Modulo" },
        { value: "tipo_chiamata", text: "Tipo Chiamata" },
        { value: "json_parametri", text: "JSON Parametri" },
        { value: "link_username", text: "Link Username" },
        { value: "link_password", text: "Link Password" },
        { value: "ordine", text: "Ordine", input: "number" },
        {
          text: null,
          value: "delete_toggle",
          sortable: false,
          align: "right",
        },
      ],
      head_menu: [
        { text: null, value: "edita", sortable: false, align: "left" },
        { value: "des", text: "Descrizione" },
        { value: "icon", text: "Icona" },
        { value: "text", text: "Nome" },
        { value: "path", text: "Percorso" },
        { value: "link_menu", text: "Link Menu" },
        { value: "form_startup", text: "Form Startup" },
        { value: "pagina", text: "Ordine" },
        {
          text: null,
          value: "delete_toggle",
          sortable: false,
          align: "right",
        },
      ],
      moduli: [],
      NETWORK: null,
      selected: { index: null, field: null, type: null },
      secondaryTable: false,

      headers_param_menu: [
          { text: 'Parametro', value: 'cod_parametro', sortable: false },
          { text: 'Valore', value: 'val_parametro', sortable: false },
          { text: '', value: 'actions',  width: "8%", sortable: false },
        ],

      template: {
        id_modulo: -1,
        id_temp:0,
        id_azienda: -1,
        cod_modulo: "",
        des_modulo: "",
        img_modulo: null,
        link: null,
        tipo_modulo: null,
        tipo_chiamata: null,
        json_parametri: null,
        link_username: null,
        link_password: null,
        ordine: null,
        validita: 1,
        moduli_menu: [],
      },
      template_menu: {
        id_modulo_menu: -1,
        id_temp:0,
        id_temp_padre:0, //coincide con id_modulo_menu
        id_modulo: null,
        id_modulo_menu_rif: null,
        des: null,
        icon: null,
        text: null,
        path: null,
        link_menu: null,
        moduli_menu_parametri:[],
        form_startup: null,
        pagina: null,
        id_utente: null,
        validita: 1,
      },
      tipi_moduli: [
        { text: "Medaglione", value: 0 },
        { text: "Modulo Menu Dx", value: 1 },
      ],
    };
  },
  computed: {
      filteredParametriValidita() {
          return this.template_menu.moduli_menu_parametri.filter((i) => {
              return (i.validita === null) || (i.validita === 1) || (i.validita === "");
          })
      },
  },   
  mounted() {
    this.NETWORK = this.$store.state.Network;
    this.getModuli();
  },
  methods: {
    getModuli() {
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "getModuli",
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (response) {
            that.moduli = [];
            that.secondaryTable = false;
            let moduli_tmp = response.moduli;
            for(let i=0; i < moduli_tmp.length; i++){
              let modulo = moduli_tmp[i];
              modulo.index = i;
              for(let j=0; j < modulo.moduli_menu.length; j++){
                modulo.moduli_menu[j].index = j;
              }
              that.moduli.push(modulo);
            }
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    setModuli() {
      let moduli = [],
        moduli_menu = [];
      for (let i = 0; i < this.moduli.length; i++) {
        let modulo = Object.assign({}, this.moduli[i]);
        for (let j = 0; j < this.moduli[i].moduli_menu.length; j++) {
          let modulo_menu = Object.assign({}, this.moduli[i].moduli_menu[j]);
          modulo_menu["index_reference"] = i;
          if (Number(modulo.validita) == 0) {
            modulo_menu.validita = 0;
          }
          moduli_menu.push(modulo_menu);
        }
        modulo["index_reference"] = i;
        delete modulo.moduli_menu;
        moduli.push(modulo);
      }
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "setModuli",
          token: localStorage.user_token,
          moduli: moduli,
          moduli_menu: moduli_menu,
        },
        method: "PUT",
        on: {
          load: function (response) {
            that.getModuli();
            that.alert = {
              message : 'Dati salvati con successo',
              show : true,
              error : false,
              db_errors : response,
            }
          },
          error: function(){
            that.alert = {
              message : 'Errore durante il salvataggio',
              show : true,
              error : true,
              db_errors : [],
            }
          }
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    clickAggiungiItem() {
      this.dialogEditItem = true;
    },
    chiudiModificaDialog() {
      if (this.secondaryTable) {
        this.dialogEditItem = false;

        //clear delm'oggetto menu template in uscita dal salvataggio
        let item_menu_tmp = {
            id_modulo_menu: -1,
            id_temp:0,
            id_temp_padre:0, 
            id_modulo: null,
            id_modulo_menu_rif: null,
            des: null,
            icon: null,
            text: null,
            path: null,
            link_menu: null,
            moduli_menu_parametri:[],
            form_startup: null,
            pagina: null,
            id_utente: null,
            validita: 1,
        };

        this.template_menu = Object.assign({}, item_menu_tmp);
      } else {
        this.dialogEditItem = false;

        let item_tmp = {
          id_modulo: -1,
          id_azienda: -1,
          cod_modulo: "",
          des_modulo: "",
          img_modulo: null,
          link: null,
          tipo_modulo: null,
          tipo_chiamata: null,
          json_parametri: null,
          link_username: null,
          link_password: null,
          ordine: null,
          validita: 1,
          moduli_menu: [],
        };

        this.template = Object.assign({}, item_tmp);
      }
    },
    clickModificaModulo(item) {

      if (this.secondaryTable) {
        this.template_menu = Object.assign({}, item);
        this.dialogEditItem = true;
      } else {
        this.template = Object.assign({}, item);
        this.dialogEditItem = true;
      }
    },

    nuovo_parametro(){
      //il figlio se il padre è nuovo avrà id_padre = id_temp del padre
      this.template_menu.id_temp = moment().valueOf()  //id_temp nodo padre nuovo anche lui nuovo
      this.template_menu.moduli_menu_parametri.push ({  
            id_menu_parametro:-1,
            id_modulo_menu: this.template.id_modulo ,
            cod_parametro: "",
            val_parametro: "",
            id_temp_padre:  this.template_menu.id_temp,
            id_temp: 0,
            validita: 1,
          });
    },  
    deleteItem(associazione) {
        let index = this.template_menu.moduli_menu_parametri.findIndex(item => item.id_menu_parametro === associazione.id_menu_parametro)
        if (this.template_menu.moduli_menu_parametri[index].id_menu_parametro != -1){
            this.template_menu.moduli_menu_parametri[index].validita = 0 
        }else{
            this.template_menu.moduli_menu_parametri.splice(index, 1)
        }
    }, 
    //edit non serve perchè modifico in linea e mantengo i valori caricati


    clickSalva() {
      if (this.secondaryTable) {
        if (this.template_menu.index == undefined) {
          this.template_menu.index = this.moduli[this.selected.index].moduli_menu.length;
          this.moduli[this.selected.index].moduli_menu.push(this.template_menu);
        } else {
          this.moduli[this.selected.index].moduli_menu.forEach((elem) => {
            if (elem.index === this.template_menu.index) {
              elem.des = this.template_menu.des;
              elem.icon = this.template_menu.icon;
              elem.text = this.template_menu.text;
              elem.path = this.template_menu.path;
              elem.id_temp = this.template_menu.id_temp;
              elem.id_temp_padre = this.template_menu.id_temp_padre;              
              elem.moduli_menu_parametri = this.template_menu.moduli_menu_parametri;
              elem.link_menu = this.template_menu.link_menu;
              elem.form_startup = this.template_menu.form_startup;
              elem.pagina = this.template_menu.pagina;
            }
          });
        }

        //clear delm'oggetto menu template in uscita dal salvataggio
        let item_menu_tmp = {
            id_modulo_menu: -1,
            id_temp:0,
            id_temp_padre:0, 
            id_modulo: null,
            id_modulo_menu_rif: null,
            des: null,
            icon: null,
            text: null,
            path: null,
            link_menu: null,
            moduli_menu_parametri:[],
            form_startup: null,
            pagina: null,
            id_utente: null,
            validita: 1,
        };
        this.template_menu = Object.assign({}, item_menu_tmp);
        
      } else {
        if (this.template.index == undefined) {
          this.template.index = this.moduli.length;
          this.moduli.push(this.template);
        } else {
          this.moduli.forEach((elem) => {
            if (elem.index === this.template.index) {
              elem.cod_modulo = this.template.cod_modulo;
              elem.des_modulo = this.template.des_modulo;
              elem.img_modulo = this.template.img_modulo;
              elem.link = this.template.link;
              elem.tipo_modulo = this.template.tipo_modulo;
              elem.tipo_chiamata = this.template.tipo_chiamata;
              elem.json_parametri = this.template.json_parametri;
              elem.link_username = this.template.link_username;
              elem.link_password = this.template.link_password;
              elem.ordine = this.template.ordine;
            }
          });
        }

        let item_tmp = {
          id_modulo: -1,
          id_azienda: -1,
          cod_modulo: "",
          des_modulo: "",
          img_modulo: null,
          link: null,
          tipo_modulo: null,
          tipo_chiamata: null,
          json_parametri: null,
          link_username: null,
          link_password: null,
          ordine: null,
          validita: 1,
          moduli_menu: [],
        };
        this.template = Object.assign({}, item_tmp);
      }
      this.dialogEditItem = false;
    },
    deleteToggle(item) {
      item.validita = (item.validita + 1) % 2;
    },
    tableToggle(index) {
      this.selected.index = index;
      this.secondaryTable = !this.secondaryTable;
    },
    uploadAndCompress() {
      let that = this;
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/png, image/jpeg");
      input.onchange = function () {
        let reader = new FileReader();
        reader.onload = function () {
          let img = new Image();
          img.onload = function () {
            let width, height;
            let ratio = img.width / img.height;
            if (img.width > img.height) {
              width = Math.min(500, img.width);
              height = width / ratio;
            } else {
              height = Math.min(500, img.height);
              width = height * ratio;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            that.template.img_modulo = ctx.canvas.toDataURL();

            canvas = null;
            img = null;
            reader = null;
            input = null;
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(input.files[0]);
      };
      input.click();
    },
    showTree() {
      if (this.aside.show) {
        return;
      }
      this.aside.show = true;
      document.body.addEventListener("click", this.hideTree);
    },
    hideTree(event) {
      let tree_container = document.getElementById("tree_view");
      let path = event.composedPath();
      let close = true;
      for (let i = 0; i < path.length; i++) {
        if (path[i] == tree_container) {
          close = false;
          break;
        }
      }
      if (close) {
        document.body.removeEventListener("click", this.hideTree);
        this.aside.show = false;
      }
    },
    dragStart(i, j) {
      let tree_container = document.getElementById("tree_view");
      document.body.addEventListener("mousemove", this.dragMove);
      document.body.addEventListener("mouseup", this.dragEnd);
      document.body.addEventListener("mouseleave", this.dragEnd);

      let original = document.getElementById("tree_section_" + i + "_" + j);
      let element = original.cloneNode(true);
      element.removeAttribute("id");
      let container = document.createElement("div");
      container.setAttribute("class", "tree_section");

      container.style.position = "absolute";
      container.appendChild(element);
      tree_container.appendChild(container);

      container.style.top =
        original.getBoundingClientRect().y -
        tree_container.getBoundingClientRect().y +
        tree_container.scrollTop -
        5 +
        "px";
      container.style.left = "12px";
      container.style.backgroundColor = "white";
      container.style.padding = "5px 12px";
      container.style.boxShadow = "0 2px 4px -1px rgb(0 0 0 / 20%)";

      this.tree_data = {
        element: container,
        container: tree_container,
        active_element: null,
        cache: Object.assign({}, this.moduli[i].moduli_menu[j]),
        position: { i: i, j: j },
      };
      this.moduli[i].moduli_menu.splice(j, 1);
    },
    dragMove(event) {
      let tree_container = this.tree_data.container;
      this.tree_data.element.style.top =
        event.clientY -
        tree_container.getBoundingClientRect().y +
        tree_container.scrollTop -
        5 +
        "px";

      let path = document.elementsFromPoint(event.clientX, event.clientY);
      for (let i = 0; i < path.length; i++) {
        let element = path[i];
        if (element.tagName.toLowerCase() == "body") {
          return;
        }
        let id = element.getAttribute("id");
        if (id == undefined || id == null) {
          continue;
        }
        if (id.indexOf("tree_section_") < 0) {
          continue;
        }
        if (this.tree_data.active_element != null) {
          this.tree_data.active_element.style.borderBottom = "none";
        }
        element.style.borderBottom = "1px solid black";
        this.tree_data.active_element = element;
      }
    },
    dragEnd() {
      let element = this.tree_data.element;
      document.body.removeEventListener("mousemove", this.dragMove);
      document.body.removeEventListener("mouseup", this.dragEnd);
      document.body.removeEventListener("mouseleave", this.dragEnd);

      this.tree_data.container.removeChild(element);
      let i, j;

      if (this.tree_data.active_element != null) {
        this.tree_data.active_element.style.borderBottom = "none";
        let id = this.tree_data.active_element
          .getAttribute("id")
          .substring(13)
          .split("_");
        i = Number(id[0]);
        j = Number(id[1]);
      } else {
        i = this.tree_data.position.i;
        j = this.tree_data.position.j;
      }
      this.moduli[i].moduli_menu.splice(j + 1, 0, this.tree_data.cache);
      delete this.tree_data;
    },
  },
};
</script>
<style scoped>
.gestione_moduli {
  padding: 20px;
}
.gestione_moduli > aside {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  text-align: left;
  padding: 15px;
  padding-left: 0;
  min-width: 300px;
  margin-left: 0;
  transition: margin-left 0.5s linear;
}
.gestione_moduli > aside[data-hidden] {
  margin-left: -100%;
}
.gestione_moduli > aside .tree_section {
  display: block;
  margin-left: 12px;
  margin-bottom: 10px;
}

.gestione_moduli > aside .tree_section > div {
  height: 30px;
  display: block;
}
.gestione_moduli > aside .tree_section > div > * {
  display: inline-block;
  vertical-align: middle;
}
.gestione_moduli > aside .tree_section > div > h3 {
  font-weight: bold;
}
.gestione_moduli > aside .tree_section > div > h4 {
  font-weight: normal;
}

.gestione_moduli > aside .tree_section > div > *[data-lazy],
.gestione_moduli > aside .tree_section > div > img {
  width: 24px;
  margin: 0 12px 0 0;
}
</style>
