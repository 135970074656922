<template>
    <v-card>
        <v-card-text>
            <v-row>
              <div class="col-md-12" >
                  <v-card>
                      <v-card-title>
                      <v-col cols="12" sm="6" md="6" align="left">

                      </v-col>
                          <v-spacer></v-spacer>
                          <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Cerca"
                              single-line
                              hide-details
                          ></v-text-field>
                      </v-card-title>
                      <v-data-table
                          :headers="headers"
                          :items="inventari"  
                          :search="search"
                          item-key="id_magazzino_inventario"
                          class="elevation-1"
                          
                          :footer-props="{
                          itemsPerPageOptions: [50, 1000, 10000], 
                          showFirstLastPage: true,
                          }"
                      >
                          <template v-slot:top>
        
                                  <v-dialog v-model="dialog_edit" max-width="900px" persistent>
                                      <v-card>
                                          <v-card-title>
                                          <span class="headline">Modifica Articolo Inventariato</span>
                                          
                                          </v-card-title>
                                              
                                          <v-card-text>
                                              <v-container>
                                                  <v-row>
                                                      <v-col cols="8" align="left">
                                                      </v-col>
                                                  </v-row>
                                                  <br>
                                                  <v-progress-linear
                                                  color="indigo"
                                                  rounded
                                                  value="100"
                                                  ></v-progress-linear>
                                                  <br>
                                                  <v-row>
                                                      <v-col cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.codice_articolo" label="Codice Articolo" readonly></v-text-field>
                                                      </v-col>
                                                      <v-col cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.des_articolo" label="Descrizione Articolo" readonly></v-text-field>
                                                      </v-col>
                                                      <v-col cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.cod_lotto" label="Cod. Lotto" readonly></v-text-field>
                                                      </v-col>
                                                      <v-col cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.quantita" label="Quantità" type="number" :rules="[rules.required]" ></v-text-field>
                                                      </v-col>
                                                      <v-col class="d-flex" cols="12" sm="6"  md="4">
                                                          <v-select
                                                          :items="ubicazioni"
                                                          item-text='des'
                                                          item-value='id_tabella'
                                                          label="Ubicazione"
                                                          autocomplete
                                                          v-model="articolo.id_ubicazione"
                                                          ></v-select>
                                                      </v-col>
                                                      <v-col cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.note" label="Note" ></v-text-field>
                                                      </v-col>
                                                      <v-col v-if="articolo.id_materiale != null" class="d-flex" cols="12" sm="6"  md="4">
                                                          <v-select
                                                              :items="materiali"
                                                              item-text='descrizione'
                                                              item-value='id_materiale'
                                                              label="Materiale"
                                                              autocomplete
                                                              v-model="articolo.id_materiale"
                                                          ></v-select>
                                                      </v-col>
                                                      <v-col v-if="articolo.l != null" cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.l" label="Lunghezza" ></v-text-field>
                                                      </v-col>
                                                      <v-col v-if="articolo.p != null" cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.p" label="Profondità" ></v-text-field>
                                                      </v-col>
                                                      <v-col v-if="articolo.h != null" cols="12" sm="6" md="4">
                                                          <v-text-field v-model="articolo.h" label="Altezza" ></v-text-field>
                                                      </v-col>                                                                                                


                                                  </v-row>
                                                  
                                              </v-container>
                                              <v-card-actions>
                                              <v-spacer></v-spacer>
                                                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                                  <v-btn color="blue darken-1" text @click="save('edit')">Salva</v-btn>
                                              </v-card-actions>
                                          </v-card-text>
                                      </v-card>
                                  </v-dialog>

                                  <v-dialog
                                  v-model="dialog_del_conf"
                                  persistent
                                  max-width="500"
                                  >
                                      <v-card>
                                          <v-card-title class="headline">
                                          Conferma Cancellazione.
                                          </v-card-title >
                                          <v-card-text align="left">Confermi la cancellazione dell'articolo inventariato?</v-card-text>
                                          <v-card-actions>
                                          <v-spacer></v-spacer>
                                              <v-btn color="blue darken-1" outlined text @click="close">No</v-btn>
                                              <v-btn color="blue darken-1" outlined text @click="delete_articolo">Si</v-btn>
                                          </v-card-actions>
                                      </v-card>
                                  </v-dialog>

                                  <v-overlay :value="overlay">
                                      <v-progress-circular indeterminate size="64"></v-progress-circular>
                                  </v-overlay>

                          </template>

                          <template  v-slot:[`item.actions`]="{ item }" >
                              <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn outlined small v-bind="attrs" v-on="on" @click="editItem(item)">
                                          <v-icon color="green"
                                          >mdi-pencil
                                          </v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Modifica Articolo Invantariato </span>
                              </v-tooltip>    

                              <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn outlined small v-bind="attrs" v-on="on" @click="confirmDeleteItem(item)">
                                          <v-icon color="red"
                                          >mdi-delete
                                          </v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Cancella Articolo Invantariato </span>
                              </v-tooltip>  
                          </template>
          
                      </v-data-table>
          
                  </v-card>
              </div>

            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
    data: () => ({
        
        dialog_edit: false,
        dialog_del_conf: false,
        table_cod_lotto: false,
        overlay: false,
        search: '',
        codice_lotto: '',  
        id_ubicazione: '',   
        editedIndex: -1, 
        timestamp: '',
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 50 || 'Max 50 Caratteri',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        /*  
        headers: [
              { text: 'Cod Articolo', value: 'codice_articolo' },
              { text: 'Desc', value: 'des_articolo' },
              { text: 'Q.ta', value: 'quantita' },
              { text: 'ID Ubicazione', value: 'id_ubicazione' },
              { text: 'Cod Lotto', value: 'cod_lotto' },
              { text: 'Data', value: 'data_mod' },
              { text: 'Id Utente', value: 'id_utente' },
              { text: 'Actions', value: 'actions', sortable: false },
          ],

          ubicazioni: [ { id_tabella: "1", des: "Ubicazione 1", }, 
                        { id_tabella: "2", des: "Ubicazione 2", }, 
                        { id_tabella: "3", des: "Ubicazione 3", }, 
                    ],
        */
        headers: [],  
        inventari: [],
        ubicazioni: [],
        materiali: [],
        inventario: { id_magazzino_inventario: '', id_articolo: '', codice_articolo: '', des_articolo: '', quantita: '', id_ubicazione: '', ubicazione: '',  id_lotto: '', cod_lotto: '', data_ins: '', data_mod: '', id_utente: '', nome_utente: '', validita: '', l:'',p:'',h:'', id_materiale: ''},
        articolo: { id_magazzino_inventario: '', id_articolo: '', codice_articolo: '', des_articolo: '', id_lotto: '', cod_lotto: '', ubicazione: '', quantita: '', validita: '', data:'', note: '', l:'',p:'',h:'', id_materiale: '' },
        headers_cod_lotto: [
              { text: 'Cod Articolo', value: 'codice_articolo' },
              { text: 'Descrizione', value: 'des_articolo' },
              { text: 'Q.ta', value: 'quantita' },
              { text: 'ID Ubicazione', value: 'id_ubicazione' },
          ],
    }),
    mounted() {
      this.getUbicazioni()
      this.getMateriali()
      this.getHeadersInventario();
    },
    methods: {
        getUbicazioni(){
            let request = {
                controller: "Logistica",
                method: "POST",
                richiesta: {
                    action: "getUbicazioni",
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((response) => {
                console.log(response.data.result.tblubicazioni);
                this.ubicazioni = response.data.result.tblubicazioni
            });
        },
        getMateriali(){
            let request = {
                controller: "Logistica",
                method: "POST",
                richiesta: {
                    action: "getMateriali",
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((response) => {
                this.materiali = response.data.result.tblmateriali
            });
        },
        getHeadersInventario(){
            this.overlay = true
            let request = {
                controller: "Logistica",
                method: "POST",
                richiesta: {
                    action: "getHeadersInventario",
                    token: localStorage.getItem("user_token"),
                },
            }
            this.$store.dispatch("api", request).then((response) => {
                console.log(response.data.result.headers);
                this.headers = response.data.result.headers
                this.inventari = response.data.result.lista
                if (localStorage.id_profilo == 3){
                        this.headers.push({ text: 'Actions', value: 'actions', sortable: false })
                }
                this.overlay = false
            });
        },
        gestioneInventario(){
            this.gestione_inventario= true
            this.main = false
        },

        getartilololotto(){
            if (this.codice_lotto != null &&  this.codice_lotto != ''  ){
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getartilololotto",
                        token: localStorage.getItem("user_token"),
                        cod_lotto: this.codice_lotto.trim(),
                    },
                }

                this.$store.dispatch("api", request).then((res) => {
                    console.log(res.data.result.response)
                    if (res.data.result.response != 'ko'){
                        this.articolo = res.data.result.articoloLotto[0]
                        this.getMagazzinoInventario(this.codice_lotto)
                        this.table_cod_lotto = true
                    }else{
                        this.snackbar_text = "Codice lotto non presente.";
                        this.snackbar_color = "error";
                        this.snackbar = true;
                    }
                });
            }
        },
        close(){
                this.dialog_edit = false
                this.dialog_del_conf = false
                this.articolo = []
                this.gestione_inventario= false
                this.main = true
        },
        save(item){
            if (this.articolo.codice_articolo != null && this.articolo.quantita != null && this.articolo.quantita > 0 ){
                var id_pk
                this.getNow()
                this.articolo.data = this.timestamp
                //console.log(this.articolo.data)
                if (item == 'new')  id_pk = "-1"; else  id_pk = this.articolo.id_magazzino_inventario;
                var request = {
                    controller: "Logistica",
                    method: "PUT",
                    richiesta: {
                    action: "insertInventario",
                    token: localStorage.getItem("user_token"),
                    id_magazzino_inventario: id_pk,
                    tabella: [this.articolo]
                    }
                };
                this.$store.dispatch("api", request).then(() => {
                        this.getHeadersInventario();
                        this.dialog_ins_lotto = false
                        this.dialog_edit = false
                        this.dialog_del_conf = false
                        this.table_cod_lotto = false
                        this.articolo = []
                });
            }
         else{
                    this.snackbar_text = "Non è possibile salvare: dati incompleti.";
                    this.snackbar_color = "error";
                    this.snackbar = true;
            }

        },
        editItem(item){
            this.articolo = Object.assign({}, item)
            this.dialog_edit = true
        },
        confirmDeleteItem(item){
            this.articolo = Object.assign({}, item)
            this.articolo.validita = 0
            console.log(this.articolo)
            this.dialog_del_conf = true
        },
        delete_articolo(){
                var request = {
                    controller: "Logistica",
                    method: "PUT",
                    richiesta: {
                    action: "insertInventario",
                    token: localStorage.getItem("user_token"),
                    id_magazzino_inventario: this.articolo.id_magazzino_inventario,
                    tabella: [this.articolo]
                    }
                };
                this.$store.dispatch("api", request).then(() => {
                        this.getHeadersInventario();
                        this.dialog_ins_lotto = false
                        this.dialog_edit = false
                        this.dialog_del_conf = false
                        this.table_cod_lotto = false
                        this.articolo = []
                });
        },
        getNow() {
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            this.timestamp = dateTime;
        }

    },
};
</script>