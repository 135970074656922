import CHART_COMMON from "./_common.js";
class CHART_2D_H_BAR {
    COMMON = new CHART_COMMON();
    
    options = {
        animation: false,
        color : [],
        legend: {
            show: true,
            top: 10,
            right : 5,
            data : [],
        },
        grid: {
            containLabel: true,
            left:30,
            bottom:30,
            right:30,
            //top:35
        },
        tooltip: {
            trigger: 'axis',
            formatter : function(data){
                if(data.length<=0){return;}
                let tooltip = '<div class="graph_tooltip"><h6>'+data[0].axisValue+'</h6>';
                for(let i=0;i<data.length;i++){
                    tooltip+=   '<div><span style="background-color:'+data[i].color+'"></span>'+
                                '<span>'+data[i].seriesName+':</span>'+
                                '<span>'+data[i].data.formatted+'</span></div>';
                }
                tooltip+='</div>';
                return tooltip;
            },
            backgroundColor : 'transparent',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: [],
        yAxis: [],
        series: [],
    }
    setOptions(data){
        try{
            this.setAssi(data.assi);
            this.setSerie(data.serie);
            return this.options;
        }catch(error){
            return {};
        }
    }

    setAssi(data){
        let assi = [{n:'X',o:0},{n:'Y',o:1}];
        try{
            assi = JSON.parse(decodeURIComponent(escape(atob(data))));
        }catch(error){
            console.warn(`Non è stato possibile leggere gli assi per il grafico`);
        }
        for(let i=0; i < assi.length; i++){
            if(assi[i].e!=true){continue;}
            switch(assi[i].o){
                case 0:
                case 2:
                    this.options.xAxis.push({                    
                        axisTick: {
                            alignWithLabel: true
                        },                       
                        name : assi[i].n,
                        nameGap : 30,
                        nameLocation : 'center',
                        nameTextStyle : {
                            fontWeight : 'bold'
                        },
                        position : (assi[i].o == 0)?'bottom':'top',
                        reference : assi[i].o,
                        splitLine: {
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        type: 'value'
                    });
                    break;
                case 1:
                case 3:
                    this.options.yAxis.push({                    
                        axisTick: {
                            alignWithLabel: true
                        },
                        data : [],
                        name : assi[i].n,
                        nameLocation : 'center',
                        nameRotate : 90,
                        nameGap : 5,
                        nameTextStyle : {
                            fontWeight : 'bold'
                        },
                        position : (assi[i].o == 1)?'left':'right',
                        reference : assi[i].o,
                        type: 'category',
                        axisLabel : {
                            show : true,
                            overflow : 'truncate',
                            lineHeight: 16,
                            formatter : function(value){
                                if(value.length > 15){
                                    let counter = 0;
                                    let newVal = '';
                                    for(let i = 0; i < value.length; i++){
                                        counter++;
                                        if(counter >= 15 & value[i] == ' '){                                            
                                            newVal += '\n';
                                            counter = 0;
                                        }else{
                                            newVal += value[i];
                                        }
                                    }
                                    return newVal;
                                }else{
                                    return value;
                                }
                            }
                        }                        
                    });
                    break;
            }
        }
    }

    setSerie(serie){
        serie = this.COMMON.filterSerie(serie);

        for(let i=0; i < serie.length; i++){
            // Decodifica dei parametri extra
            serie[i].extra = this.COMMON.getExtra(serie[i]);

            let done = false;
            for(let j=0; j < this.options.yAxis.length; j ++){
                if(this.options.yAxis[j].reference == serie[i].valore){
                    this.COMMON.normalizeData(serie[i].serie,serie[i].tipo_dati);
                    this.options.yAxis[j].nameGap = this.COMMON.getAxisNameGap(serie[i].serie);                    
                    this.options.yAxis[j].data = serie[i].serie;
                    done = true;
                    break;
                }
            }
            if(done){continue;}
            for(let j=0; j < this.options.xAxis.length; j ++){
                if(this.options.xAxis[j].reference == serie[i].valore){
                    // Mostra label
                    let show_label = false;
                    let background = this.extractedColor;
                    let color = '#ffffff';
                    let distance = 5;
                    try{
                        if(serie[i].extra.cartesian.show_labels==true){show_label = true;}
                        if(serie[i].extra.cartesian.background!=undefined){background = serie[i].extra.cartesian.background;}
                        if(serie[i].extra.cartesian.color!=undefined){color = serie[i].extra.cartesian.color;}
                        if(serie[i].extra.cartesian.distance!=undefined){distance = serie[i].extra.cartesian.distance;}
                    }catch(error){
                        show_label = false;
                        background = this.extractedColor;
                        color = '#ffffff';
                        distance = 5;
                    }
                    
                    // Serie
                    this.options.series.push({
                        data : this.COMMON.processSerie(serie[i]),
                        name : serie[i].nome,
                        type: 'bar',
                        xAxisIndex : (serie[i].valore == 0)?undefined:1,
                        label : {
                            show : show_label,
                            position : 'right',
                            backgroundColor : background,
                            color : color,
                            distance : Number(distance),
                            padding : 5,
                            borderRadius : 3,
                            formatter : function(data){return data.data.formatted;}
                        }
                    });
                    // Legenda
                    if(serie[i].is_static==true){
                        this.options.color.push(serie[i].linecolor);
                    }else{
                        this.options.color.push('#b3b3b3');
                    }
                   
                    this.options.legend.data.push(serie[i].nome);
                    break;
                }
            }
        }

    }
















    getColor(color,labels,dataY,value,index){
        let temp = color.substring(5).split(';');
        for(let i=0;i<temp.length;i++){
            if(temp[i]==''){continue;}
            let rule = temp[i].split(':');
            let param = -1;
            for(let j=0;j<labels.length;j++){if(labels[j]==rule[1]){param=j;break;}}
            if(param==-1){continue;}
            param = dataY[param][index];

            if(isNaN(param)||isNaN(value)){return null;}
            value=Number(value);
            param=Number(param);

            switch(rule[0]){
                case '<':
                    if(value<param){color=rule[2];}
                    break;
                case '<=':
                    if(value<=param){color=rule[2];} 
                    break;
                case '=':
                    if(value==param){color=rule[2];}
                    break;
                case '>=':
                    if(value>=param){color=rule[2];}
                    break;
                case '>':
                    if(value>param){color=rule[2];}
                    break;
                case '<>':
                    if(value!=param){color=rule[2];}
                    break;
                default:
                    color = 'transparent';
                    break;
            }                                    
        }
        return color;
    }
}
export default CHART_2D_H_BAR;