import moment from "moment/moment";

/* Questa classe fornisce l'oggetto di riferimento
 * per le Richieste di Manutenzione all'interno del modulo
 * Manutenzione per EduCatt e altri. */
export default class Activity {
    constructor(arg) {
        if (arg != undefined) {
            this.allegati = arg.allegati != undefined? arg.allegati : [];
            this.cod_attivita = arg.cod_attivita != undefined? arg.cod_attivita : "";
            this.completata = arg.completata != undefined? arg.completata : 0;
            this.data_fine = arg.data_fine != undefined? arg.data_fine : moment().add(1, "d").format("YYYY-MM-DD HH:mm");
            this.data_inizio = arg.data_inizio != undefined? arg.data_inizio : moment().format("YYYY-MM-DD HH:mm");
            this.des_area = arg.des_area != undefined? arg.des_area : "";
            this.des_attivita = arg.des_attivita != undefined? arg.des_attivita : "";
            this.des_categoria = arg.des_categoria != undefined? arg.des_categoria : "";
            this.des_responsabile = arg.des_responsabile != undefined? arg.des_responsabile : "";
            this.des_sede = arg.des_sede != undefined? arg.des_sede : "";
            this.des_struttura = arg.des_struttura != undefined? arg.des_struttura : "";
            this.des_stanza = arg.des_stanza != undefined? arg.des_stanza : "";
            this.des_macchinario = arg.des_macchinario != undefined? arg.des_macchinario : "";
            this.des_tipo_attivita = arg.des_tipo_attivita != undefined? arg.des_tipo_attivita : "";
            this.des_zona = arg.des_zona != undefined? arg.des_zona : "";
            this.id_area = arg.id_area != undefined? arg.id_area : null;
            this.id_attivita = arg.id_attivita != undefined? arg.id_attivita : null;
            this.id_categoria = arg.id_categoria != undefined? arg.id_categoria : null;
            this.id_consuntivo = arg.id_consuntivo != undefined? arg.id_consuntivo : null;
            this.id_intervento = arg.id_intervento != undefined? arg.id_intervento : null;
            this.id_responsabile = arg.id_responsabile != undefined? arg.id_responsabile : null;
            this.id_sede = arg.id_sede != undefined? arg.id_sede : null;
            this.id_struttura = arg.id_struttura != undefined? arg.id_struttura : null;
            this.id_tipo_attivita = arg.id_tipo_attivita != undefined? arg.id_tipo_attivita : null;
            this.id_zona = arg.id_zona != undefined? arg.id_zona : null;
            this.id_stanza = arg.id_stanza != undefined? arg.id_stanza : null;
            this.id_macchinario = arg.id_macchinario != undefined? arg.id_macchinario : null;
            this.note = arg.note != undefined? arg.note : "";
            this.team = arg.team != undefined? arg.team : [];
            this.titolo = arg.titolo != undefined? arg.titolo : "";
        }else{
            this.allegati = [];
            this.cod_attivita = "";
            this.completata = 0;
            this.data_fine = moment().add(1, "d").format("YYYY-MM-DD HH:mm");
            this.data_inizio = moment().format("YYYY-MM-DD HH:mm");
            this.des_area = "";
            this.des_attivita = "Nuova Attività";
            this.des_categoria = "";
            this.des_responsabile = localStorage.nominativo;
            this.des_sede = "";
            this.des_struttura = "";
            this.des_tipo_attivita = "";
            this.des_zona = "";
            this.des_stanza="";
            this.des_macchinario ="";
            this.id_area = null;
            this.id_attivita = null;
            this.id_categoria = null;
            this.id_consuntivo = null;
            this.id_intervento = null;
            this.id_responsabile = localStorage.id;
            this.id_sede = null;
            this.id_struttura = null;
            this.id_stanza = null;
            this.id_macchinario = null;
            this.id_tipo_attivita = null;
            this.id_zona = null;
            this.note = "";
            this.team = [];
            this.titolo = "Nuova Attività";
        }
    }
}