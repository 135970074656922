<template>
    <div class="card" >
        <div class="card-text">
          <br>

          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="modalTab"
                grow
                style="color: black"
                slider-size="5"
                slider-color="primary accent-3 "
                color="black"
                @change="changeTab"
              >
                <v-tab :href="`#conto_lavoro_ingresso`" style="max-width: 350px"
                  >CONTROLLO DDT</v-tab
                >
                <v-tab :href="`#conto_lavoro_uscita`" style="max-width: 350px"
                  >DDT CONTROLLATE</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="modalTab" class="no-transition" touchless>
                <v-tab-item
                  :value="`conto_lavoro_ingresso`"
                  transition="false"
                  class="no-transition"
                >
                  <ControlloDDT 
                    :TRIGGER = this.ricarica
                    @changeTab="changeTab" />
                </v-tab-item>

                <v-tab-item
                  :value="`conto_lavoro_uscita`"
                  transition="false"
                  class="no-transition"
                >
                  <DDTControllate 
                    :TRIGGER = this.ricarica_uscita
                    @changeTab="changeTab"                    
                  />
                </v-tab-item>

              </v-tabs-items>
            </v-col>
          </v-row>
        </div> 
    </div>


  
</template>
<script>
import ControlloDDT from "../../../components/PRODUCTION/Magazzino/controlloDDT.vue";
import DDTControllate from "../../../components/PRODUCTION/Magazzino/DDTControllate.vue";
export default {
  components: {
    ControlloDDT,
    DDTControllate,
  },
  data() {
    return {
      modalTab: "conto_lavoro_ingresso",
      ricarica: null,  
      ricarica_uscita: null,

    };
  },
    methods: {

    changeTab(tab) {
      this.modalTab = tab;
      this.ricarica = Math.round(Math.random()*100)
      this.ricarica_uscita = Math.round(Math.random()*100)
    },

  },
};
</script>