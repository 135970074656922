<template>
  <div style="width: 100%">
    <v-row>
      <v-col cols="3">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">Packing List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              color="success"
              link
              to="/home/logistica/packinglist"
              style="width: 100%"
            >Vai a</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">Packaging</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              color="success"
              link
              to="/home/tools/logistica/packaging"
              style="width: 100%"
            >Vai a</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>