<template>
  <v-card style="min-height: 500px">
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"
        >Comparazione Versioni di Configurazione
        {{ multiple ? codice_search : "" }}</v-toolbar-title
      >
      <v-spacer v-if="diff_ordini.length > 1"></v-spacer>
      <v-tooltip bottom v-if="diff_ordini.length > 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="changeAllExpanded(true)" small v-bind="attrs" v-on="on"
            ><v-icon>mdi-chevron-double-down</v-icon></v-btn
          >
        </template>
        <span>Espandi Tutto</span>
      </v-tooltip>
      <v-tooltip bottom v-if="diff_ordini.length > 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="changeAllExpanded(false)"
            small
            v-bind="attrs"
            v-on="on"
            class="ml-2"
            ><v-icon>mdi-chevron-double-up</v-icon></v-btn
          >
        </template>
        <span>Comprimi Tutto</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiCompareDialog" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="pa-0" style="overflow-y: auto">
      <v-container fluid>
        <v-row class="justify-center">
          <v-col class="ma-0 pa-0" cols="8" v-if="multiple == false">
            <v-text-field
              autocomplete="off"
              label="Codice Configurazione"
              style="min-width: 400px; display: inline-block"
              v-model="codice_search"
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-5"
                  @click="compareVersion()"
                  :disabled="codice_search == ''"
                  small
                  style="display: inline-block"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Cerca</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="diff_ordini.length > 1">
          <v-col
            cols="12"
            v-for="(categoria, i) in categorie_distinct"
            :key="categoria"
          >
            <v-btn @click="changeExpanded(i)">
              {{ categoria.replaceAll("_", " ") }}
              <v-icon>{{
                categorie_expanded[i] ? "mdi-arrow-down" : "mdi-arrow-up"
              }}</v-icon>
            </v-btn>
            <v-container fluid v-if="categorie_expanded[i]">
              <v-row
                v-for="(ordine_categoria, i2) in ordini_categorie_totali.filter(
                  (el) => el.nome_categoria == categoria
                )"
                :key="i2"
                class="py-2"
              >
                <v-col
                  v-if="
                    ordine_categoria.gemini != undefined &&
                    ordine_categoria.gemini.length > 0
                  "
                  cols="6"
                  class="py-0"
                >
                  <v-icon :style="'color:' + ordine_categoria.color"
                    >mdi-pencil</v-icon
                  >
                  <span v-if="ordine_categoria.codice1 != null"
                    ><strong>codice:</strong> {{ ordine_categoria.codice1 }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.elemento != null"
                    ><strong>elemento:</strong> {{ ordine_categoria.elemento }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.info != null"
                    ><strong>info:</strong> {{ ordine_categoria.info }} <br
                  /></span>
                  <span v-if="ordine_categoria.quantita != null"
                    ><strong>quantita:</strong> {{ ordine_categoria.quantita }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.des != null"
                    ><strong>des:</strong> {{ ordine_categoria.des }} <br
                  /></span>
                  <span v-if="ordine_categoria.data != null"
                    ><strong>data:</strong> {{ ordine_categoria.data }} <br
                  /></span>
                  <span v-if="ordine_categoria.firma != null"
                    ><strong>firma:</strong> {{ ordine_categoria.firma }} <br
                  /></span>
                  <span v-if="ordine_categoria.elemento_padre != null"
                    ><strong>elemento padre:</strong>
                    {{ ordine_categoria.elemento_padre }} <br
                  /></span>
                </v-col>
                <v-col
                  v-if="
                    ordine_categoria.gemini != undefined &&
                    ordine_categoria.gemini.length > 0
                  "
                  cols="6"
                  class="py-0"
                >
                  <v-icon :style="'color:' + ordine_categoria.color"
                    >mdi-pencil</v-icon
                  >
                  <span v-if="ordine_categoria.gemini[0].codice1 != null"
                    ><strong>codice:</strong>
                    {{ ordine_categoria.gemini[0].codice1 }} <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].elemento != null"
                    ><strong>elemento:</strong>
                    {{ ordine_categoria.gemini[0].elemento }} <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].info != null"
                    ><strong>info:</strong>
                    {{ ordine_categoria.gemini[0].info }} <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].quantita != null"
                    ><strong>quantita:</strong>
                    {{ ordine_categoria.gemini[0].quantita }} <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].des != null"
                    ><strong>des:</strong> {{ ordine_categoria.gemini[0].des }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].data != null"
                    ><strong>data:</strong>
                    {{ ordine_categoria.gemini[0].data }} <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].firma != null"
                    ><strong>firma:</strong>
                    {{ ordine_categoria.gemini[0].firma }} <br
                  /></span>
                  <span v-if="ordine_categoria.gemini[0].elemento_padre != null"
                    ><strong>elemento padre:</strong>
                    {{ ordine_categoria.gemini[0].elemento_padre }} <br
                  /></span>
                </v-col>
                <v-col
                  v-if="
                    ordine_categoria.gemini == undefined ||
                    ordine_categoria.gemini.length == 0
                  "
                  cols="12"
                  class="py-0"
                >
                  <v-icon :style="'color:' + ordine_categoria.color">{{
                    ordine_categoria.color == "red" ? "mdi-minus" : "mdi-plus"
                  }}</v-icon>
                  <span v-if="ordine_categoria.codice1 != null"
                    ><strong>codice:</strong> {{ ordine_categoria.codice1 }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.elemento != null"
                    ><strong>elemento:</strong> {{ ordine_categoria.elemento }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.info != null"
                    ><strong>info:</strong> {{ ordine_categoria.info }} <br
                  /></span>
                  <span v-if="ordine_categoria.quantita != null"
                    ><strong>quantita:</strong> {{ ordine_categoria.quantita }}
                    <br
                  /></span>
                  <span v-if="ordine_categoria.des != null"
                    ><strong>des:</strong> {{ ordine_categoria.des }} <br
                  /></span>
                  <span v-if="ordine_categoria.data != null"
                    ><strong>data:</strong> {{ ordine_categoria.data }} <br
                  /></span>
                  <span v-if="ordine_categoria.firma != null"
                    ><strong>firma:</strong> {{ ordine_categoria.firma }} <br
                  /></span>
                  <span v-if="ordine_categoria.elemento_padre != null"
                    ><strong>elemento padre:</strong>
                    {{ ordine_categoria.elemento_padre }} <br
                  /></span>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- <v-col
            cols="12"
            v-for="(ordine, i) in ordini_categorie_totali"
            :key="ordine.id_ordine"
          > -->
          <!-- {{ i }}
            {{ ordine }}
            {{ ordini_categorie_totali.map((el) => el.nome_categoria) }} -->
          <!-- </v-col> -->
          <!-- <v-col
            cols="6"
            v-for="(ordine, i) in diff_ordini"
            :key="ordine.id_ordine"
          > -->

          <!-- <i
              >Versione {{ i == 0 ? "Corrente " : "Precedente" }}
              {{ "n° " + ordine.n_corrente }}</i
            >
            <v-container fluid>
              <v-row
                v-for="categoria in ordine.ordini_categoria"
                :key="categoria.id_ordine_categoria"
                class="justify-center"
              >
                <v-col cols="12" class="pb-0">
                  <h3
                    :style="
                      'color:' +
                      getColor(i, categoria.codice1, categoria.nome_categoria)
                    "
                  >
                    {{ i == 0 ? "+" : "-" }} {{ categoria.nome_categoria }}
                  </h3>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span v-if="categoria.codice != null"
                    ><strong>codice:</strong> {{ categoria.codice }} <br
                  /></span>
                  <span v-if="categoria.elemento != null"
                    ><strong>elemento:</strong> {{ categoria.elemento }} <br
                  /></span>
                  <span v-if="categoria.info != null"
                    ><strong>info:</strong> {{ categoria.info }} <br
                  /></span>
                  <span v-if="categoria.quantita != null"
                    ><strong>quantita:</strong> {{ categoria.quantita }} <br
                  /></span>
                  <span v-if="categoria.des != null"
                    ><strong>des:</strong> {{ categoria.des }} <br
                  /></span>
                  <span v-if="categoria.data != null"
                    ><strong>data:</strong> {{ categoria.data }} <br
                  /></span>
                  <span v-if="categoria.firma != null"
                    ><strong>firma:</strong> {{ categoria.firma }} <br
                  /></span>
                  <span v-if="categoria.elemento_padre != null"
                    ><strong>elemento padre:</strong>
                    {{ categoria.elemento_padre }} <br
                  /></span>
                </v-col>
              </v-row>
            </v-container> -->
          <!-- </v-col> -->
        </v-row>
        <v-row v-if="revisioni.length > 0">
          <v-col cols="12">
            <v-data-table :headers="head" :items="revisioni">
              <template v-slot:[`item.search`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="openComparisonByVersions(item)"
                    >
                      <v-icon style="color: #1a80b6">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Compara versioni</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      @click="removeItemDialog(item)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.pdf`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      class="ml-2"
                      @click="openSingleFile(item)"
                    >
                      <v-icon style="color: #1a80b6">mdi-file-pdf-box</v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizza Dettaglio</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>

      <SnackbarQV ref="snackbarqv" />
      <v-dialog persistent v-model="compare_files">
        <ComparazioneVersioni
          ref="comparazioneVersioni"
          @chiudi_dialog="compare_files = false"
        />
      </v-dialog>
      <v-dialog v-model="singleFile" width="1000">
        <VisualizzaFilePdf
          ref="visualizzaFilePdf"
          @chiudi_dialog="chiudiVisualizzaPdf"
        />
      </v-dialog>
      <v-dialog v-model="dialog_elimina_record" width="600">
        <v-toolbar dark color="error">
          <v-spacer></v-spacer>
          <v-toolbar-title>Attenzione!</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <h2>Sei sicuro di voler eliminare l'elemento selezionato?</h2>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="error" @click="dialog_elimina_record = false"
              >Annulla</v-btn
            >
            <v-btn dark color="success" @click="removeItem()">Conferma</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import VisualizzaFilePdf from "@/views/TOOLS/VisualizzaFilePdf.vue";

export default {
  name: "ComparazioneVersioni",
  components: {
    ComparazioneVersioni: () =>
      import("@/views/TOOLS/ComparazioneVersioni.vue"),
    SnackbarQV,
    VisualizzaFilePdf,
  },
  props: {
    id_commessa: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      // Variabili di supporto
      codice_search: "",
      itemToRemove: {},
      multiple: false,
      n_versione: "",

      // Array
      categorie_distinct: [],
      categorie_expanded: [],
      diff_ordini: [],
      files: [],
      head: [
        { value: "search", text: "Compara Versioni" },
        { value: "pdf", text: "Visualizza Dettaglio" },
        {
          text: "Nome File",
          value: "file_path",
          sortable: false,
          align: "left",
        },
        {
          text: "N° versione",
          value: "n_corrente",
          sortable: false,
          align: "left",
        },
        {
          text: null,
          value: "delete_toggle",
          sortable: false,
          align: "right",
        },
        { value: "delete", text: "Elimina" },
      ],
      ordini_categorie_totali: [],
      pdfFiles: [],
      revisioni: [],
      showContent: [],

      // Modal
      compare_files: false,
      dialog_elimina_record: false,
      singleFile: false,
    };
  },
  mounted() {},
  methods: {
    changeAllExpanded(boolStatment) {
      this.categorie_expanded.forEach((el) => {
        this.$set(
          this.categorie_expanded,
          this.categorie_expanded.indexOf(el),
          boolStatment
        );
      });
    },
    changeExpanded(i) {
      this.$set(this.categorie_expanded, i, !this.categorie_expanded[i]);
    },
    changeShowContent(i) {
      this.$set(this.showContent, i, !this.showContent[i]);
    },
    chiudiCompareDialog() {
      /* Questo metodo è usato per chiudere il dialog di comparazione
       * fra i documenti che deviniscono due diverse versioni di configurazione.
       * Quando si chiude il dialog diventa invisibile e le variabili di supporto
       * interessate devono essere resettate. */
      this.compare_files = false;
      this.codice_search = "";
      this.diff_ordini = [];
      this.ordini_categorie_totali = [];
      this.categorie_expanded = [];
      this.$emit("chiudi_dialog");
    },
    chiudiVisualizzaPdf() {
      this.singleFile = false;
    },
    compareVersion() {
      this.revisioni = [];
      this.diff_ordini = [];
      // Verifico che il codice_search sia valorizzato;
      if (this.codice_search === "") {
        this.$refs.snackbarqv.print(
          "orange",
          "Il campo del codice da ricercare non può essere vuoto."
        );
        return;
      }

      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "compare_config",
          token: localStorage.user_token,
          codice: this.codice_search.trim(),
          multiple: this.multiple,
          n_versione: this.n_versione,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status == 200) {
            if (this.multiple == false) {
              if (res.data.result.ordini.length > 0) {
                this.setDiffOrdini(res.data.result.ordini);
              } else {
                this.$refs.snackbarqv.print(
                  "orange",
                  "Codice configurazione inesistente oppure solo una versione presente."
                );
              }
            } else {
              if (
                res.data.result.lista_codici != undefined &&
                res.data.result.lista_codici.length > 0
              ) {
                this.revisioni = res.data.result.lista_codici;
              } else if (
                res.data.result.ordini != undefined &&
                res.data.result.ordini.length > 0
              ) {
                this.setDiffOrdini(res.data.result.ordini);
              } else {
                this.$refs.snackbarqv.print(
                  "orange",
                  "Codice configurazione inesistente oppure solo una versione presente."
                );
              }
            }
          }
        })
        .catch((err) => {
          this.$refs.snackbarqv.print(
            "orange",
            `${
              err.response != undefined
                ? JSON.parse(err.response.data.message).result
                : err
            }.`
          );
        });
    },
    getColor(idOrdine, codice, nome_categoria) {
      var indiceControllo = idOrdine == 0 ? 1 : 0;
      var colore = idOrdine == 0 ? "green" : "red";
      this.diff_ordini[indiceControllo]["ordini_categoria"].forEach(
        (element) => {
          if (
            element.codice1 == codice &&
            element.nome_categoria == nome_categoria
          ) {
            colore = "darkorange";
          }
        }
      );

      return colore;
    },
    openComparisonByVersions(item) {
      this.compare_files = true;
      this.$nextTick(function () {
        this.$refs.comparazioneVersioni.codice_search = item.codice;
        this.$refs.comparazioneVersioni.multiple = true;
        this.$refs.comparazioneVersioni.n_versione = item.n_corrente;
        setTimeout(() => {
          this.$refs.comparazioneVersioni.compareVersion();
        }, 10);
      });
    },
    openSingleFile(file) {
      /* Con questo metodo apro una revisione
       * della configurazione di una barca. */

      this.singleFile = true;

      this.$nextTick(function () {
        this.$refs.visualizzaFilePdf.infoFile = file;
      });

      // Controllo di avere un identificativo commessa valido.
      if (
        this.id_commessa === null ||
        this.id_commessa === undefined ||
        this.id_commessa === ""
      ) {
        return;
      }

      /* Eseguo una chiamata per dire al BE
       * che ho visualizzato questo pdf. */
      const request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "vedirevisione",
          token: localStorage.user_token,
          id_commessa: this.id_commessa,
          id_ordine: file.id_ordine,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status != 200) {
            this.$refs.snackbarqv.print(
              "orange",
              `Criticità tecnica con codice ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.snackbarqv.print(
            "orange",
            `${
              err.response != undefined
                ? JSON.parse(err.response.data.message).result
                : err
            }.`
          );
        });
    },
    removeItemDialog(item) {
      this.itemToRemove = item;
      this.dialog_elimina_record = true;
    },
    removeItem() {
      let tmp = Object.assign({}, this.itemToRemove);
      delete tmp["ordini_categoria"];
      tmp.validita = 0;
      let request = {
        controller: "Global",
        method: "PUT",
        richiesta: {
          action: "setrevisione",
          token: localStorage.user_token,
          tabella: [tmp],
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status == 200) {
            this.compareVersion();
            this.$refs.snackbarqv.print(
              "success",
              "Eliminazione ordine avvenuta con successo"
            );
          }
          this.dialog_elimina_record = false;
        })
        .catch((err) => {
          this.$refs.snackbarqv.print(
            "orange",
            `${
              err.response != undefined
                ? JSON.parse(err.response.data.message).result
                : err
            }.`
          );

          this.dialog_elimina_record = false;
        });
    },
    setDiffOrdini(res) {
      this.diff_ordini = res;
      this.diff_ordini.forEach((el) => {
        el.ordini_categoria.forEach((el2) => {
          if (
            this.diff_ordini[0]["ordini_categoria"].filter(
              (el) =>
                el.nome_categoria == el2.nome_categoria &&
                el.codice1 == el2.codice1 &&
                el.elemento == el2.elemento &&
                el.elemento_padre == el2.elemento_padre &&
                el.firma == el2.firma &&
                el.id_ordine_categoria != el2.id_ordine_categoria
            ).length > 0 ||
            this.diff_ordini[1]["ordini_categoria"].filter(
              (el) =>
                el.nome_categoria == el2.nome_categoria &&
                el.codice1 == el2.codice1 &&
                el.elemento == el2.elemento &&
                el.elemento_padre == el2.elemento_padre &&
                el.firma == el2.firma &&
                el.id_ordine_categoria != el2.id_ordine_categoria
            ).length > 0
          ) {
            el2["color"] = "yellow";
            let elem;
            if (
              this.diff_ordini[1]["ordini_categoria"].filter(
                (el) =>
                  el.nome_categoria == el2.nome_categoria &&
                  el.codice1 == el2.codice1 &&
                  el.elemento == el2.elemento &&
                  el.elemento_padre == el2.elemento_padre &&
                  el.firma == el2.firma &&
                  el.id_ordine_categoria != el2.id_ordine_categoria
              ).length > 0
            ) {
              elem = this.diff_ordini[1]["ordini_categoria"].filter(
                (el) =>
                  el.nome_categoria == el2.nome_categoria &&
                  el.codice1 == el2.codice1 &&
                  el.elemento == el2.elemento &&
                  el.elemento_padre == el2.elemento_padre &&
                  el.firma == el2.firma &&
                  el.id_ordine_categoria != el2.id_ordine_categoria
              )[0];
              this.diff_ordini[1]["ordini_categoria"].splice(elem, 1);
            }
            if (
              this.diff_ordini[0]["ordini_categoria"].filter(
                (el) =>
                  el.nome_categoria == el2.nome_categoria &&
                  el.codice1 == el2.codice1 &&
                  el.elemento == el2.elemento &&
                  el.elemento_padre == el2.elemento_padre &&
                  el.firma == el2.firma &&
                  el.id_ordine_categoria != el2.id_ordine_categoria
              ).length > 0
            ) {
              elem = this.diff_ordini[0]["ordini_categoria"].filter(
                (el) =>
                  el.nome_categoria == el2.nome_categoria &&
                  el.codice1 == el2.codice1 &&
                  el.elemento == el2.elemento &&
                  el.elemento_padre == el2.elemento_padre &&
                  el.firma == el2.firma &&
                  el.id_ordine_categoria != el2.id_ordine_categoria
              )[0];
              this.diff_ordini[0]["ordini_categoria"].splice(elem, 1);
            }
            el2["gemini"] = [];
            el2["gemini"].push(elem);
          } else if (
            this.diff_ordini[1]["ordini_categoria"].filter(
              (el) =>
                el.nome_categoria == el2.nome_categoria &&
                el.codice1 == el2.codice1 &&
                el.elemento == el2.elemento &&
                el.elemento_padre == el2.elemento_padre &&
                el.firma == el2.firma
            ).length > 0
          ) {
            el2["color"] = "red";
          } else if (
            this.diff_ordini[0]["ordini_categoria"].filter(
              (el) =>
                el.nome_categoria == el2.nome_categoria &&
                el.codice1 == el2.codice1 &&
                el.elemento == el2.elemento &&
                el.elemento_padre == el2.elemento_padre &&
                el.firma == el2.firma
            ).length > 0
          ) {
            el2["color"] = "green";
          } else {
            el2["color"] = "grey";
          }
          // console.log(el2);
          this.ordini_categorie_totali.push(el2);
          if (
            this.categorie_distinct.filter((el) => el == el2.nome_categoria)
              .length == 0
          ) {
            this.categorie_distinct.push(el2.nome_categoria);
            this.categorie_expanded.push(false);
          }
        });
      });
    },
  },
};
</script>