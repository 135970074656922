<template>
  <v-card>
    <v-card-text class="ma-0 pa-0">
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text">
          Ambiti Disponibili
        </v-toolbar-title>
      </v-toolbar>
      <v-row class="px-5">
        <v-col cols="4" v-for="(item, i) in items_tipologia" :key="i">
          <v-row class="align-end">
            <v-col cols="8" class="justify-end d-flex">
              {{ item.des_ambito_manutenzione }}
            </v-col>
            <v-col cols="4">
              <v-switch
                @change="aggiornaTipologie"
                hide-details
                style="display: inline-block"
                v-model="item.validita"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "AnagraficheDettaglioTipologia",
  components: {},
  props: {
    anagrafica_sel: { type: Object, default: null },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
      items_tipologia: [],
    };
  },
  computed: {},
  watch: {
    anagrafica_sel: {
      immediate: true,
      handler() {
        this.gettabelle();
      },
    },
    items_tipologia: {
      immediate: true,
      handler() {
        /* Emetto un evento quando vengono caricate le tipologie;
         * in un altro metodo c'è la stessa emissione quando viene
         * modificata una tipologia (metodo aggiornaTipologie). */
        this.$emit("tipologie_aggiornate", this.items_tipologia);
      },
    },
  },
  methods: {
    aggiornaTipologie() {
      /* Emetto un evento quando viene modificata una tipologia;
       * nel watch c'è la stessa emissione quando vengono
       * caricate le tipolgie (watch puntato su items_tipologia).*/
      this.$emit("tipologie_aggiornate", this.items_tipologia);
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_AMBITO_MANUTENTIVO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        let risposta_tmp = [];
        res.data.forEach((elem) => {
          /* Costruisco un oggetto come mi serve per gestire
           * le tipologie di intervento da FE. */
          let obj = {
            id_anagrafica_dettaglio: -1,
            id_anagrafica: this.anagrafica_sel.id_anagrafica,
            des_ambito_manutenzione: elem.des,
            id_ambito_manutenzione: elem.id_tabella,
            validita: false,
          };

          /* Controllo se devo assegnare un id_anagrafica_dettaglio
           * diverso da -1 perché magari l'utente aveva già selezionato
           * quell'ambito manutentivo. */
          if (this.anagrafica_sel.ambiti_manutenzione) {
            this.anagrafica_sel.ambiti_manutenzione.forEach((ambito) => {
              if (
                ambito.id_ambito_manutenzione === obj.id_ambito_manutenzione
              ) {
                obj.id_anagrafica_dettaglio = ambito.id_anagrafica_dettaglio;
                obj.validita = true;
              }
            });
          }

          risposta_tmp.push(obj);
        });
        this.items_tipologia = risposta_tmp;
      });
    },
  },
  created() {},
  mounted() {},
};
/*
{
  id_anagrafica_dettaglio: -1,
  id_anagrafica: 1,
  id_ambito_manutenzione: 123,
  validita: 1
}
*/
</script>
<style scoped>
</style>