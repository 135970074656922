var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"geo_listener"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Geolocalizzazione")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToTabellaMezzi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"70vh"},attrs:{"name":"d","center":_vm.center,"zoom":13}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"label":{
          text: m.data.label,
          color: 'black',
          fontSize: '10px',
          fontWeight: 'bold',
          background: 'black',
        },"position":m.position,"clickable":true,"bounds":true,"draggable":true,"icon":_vm.setMarkerIcon(
            (m.data.des_tipo == 'AUTOCISTERNA'
              ? m.data.des_tipo
              : 'mezzo' + m.id_stato
            ).toString()
          )},on:{"click":function($event){return _vm.openInfoWindow(m)}}})}),(_vm.selectedMarker)?_c('GmapInfoWindow',{attrs:{"position":{
          lat: _vm.selectedMarker.position.lat,
          lng: _vm.selectedMarker.position.lng,
        }},on:{"closeclick":function($event){return _vm.closeInfoWindow()}}},[_c('v-card',{staticClass:"mt-2"},[_c('v-badge',{attrs:{"bordered":"","color":"error","content":_vm.selectedMarker.data.badge,"overlap":""}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Targa: "+_vm._s(_vm.selectedMarker.data.targa)+" ")])],1)],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"infoWindow"},[_c('h3',{staticClass:"infoWindowData"},[_vm._v(" Tipologia: "+_vm._s(_vm.selectedMarker.data.des_tipo)+" ")]),_c('h3',{staticClass:"infoWindowData"},[_vm._v(" Modello: "+_vm._s(_vm.selectedMarker.data.des_mezzo)+" ")]),_c('h3',{staticClass:"infoWindowData"},[_vm._v(" Coordinate: "),_c('h4',[_vm._v(_vm._s(_vm.selectedMarker.position.lat))]),_c('h4',[_vm._v(_vm._s(_vm.selectedMarker.position.lng))])])]),_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-car-cruise-control")]),_c('div',{staticClass:"bar"},[_c('div',{style:({
                    position: 'absolute',
                    bottom: 0,
                    borderRadius: 12 + 'px',
                    border: 1 + 'px solid black',
                    height:
                      (_vm.selectedMarker.data.carburante_attuale * 120) /
                        _vm.selectedMarker.data.serbatoio +
                      'px',
                    width: 100 + '%',
                    backgroundImage:
                      _vm.selectedMarker.data.stato === 0
                        ? ("linear-gradient(\n                            90deg,\n                             hsl(20, 100%, 50%),\n                             hsl(" + ((_vm.selectedMarker.data.carburante_attuale *
                                 120) /
                               _vm.selectedMarker.data.serbatoio) + ", 100%, 50%),\n                             hsl(20, 100%, 50%)\n                            )")
                        : _vm.selectedMarker.data.stato === 1
                        ? ("linear-gradient(\n                            90deg,\n                            hsl(" + ((_vm.selectedMarker.data.carburante_attuale * 120) /
                                _vm.selectedMarker.data.serbatoio -
                              20) + ", 100%, 50%),\n                            hsl(" + ((_vm.selectedMarker.data.carburante_attuale * 120) /
                              _vm.selectedMarker.data.serbatoio) + ", 100%, 50%),\n                            hsl(" + ((_vm.selectedMarker.data.carburante_attuale * 120) /
                                _vm.selectedMarker.data.serbatoio -
                              20) + ", 100%, 50%)\n                            )")
                        : ("linear-gradient(\n                            90deg,\n                            hsl(" + ((_vm.selectedMarker.data.carburante_attuale * 120) /
                                _vm.selectedMarker.data.serbatoio -
                              20) + ", 100%, 50%),\n                            hsl(" + ((_vm.selectedMarker.data.carburante_attuale * 120) /
                              _vm.selectedMarker.data.serbatoio) + ", 100%, 40%),\n                            hsl(" + ((_vm.selectedMarker.data.carburante_attuale * 120) /
                                _vm.selectedMarker.data.serbatoio -
                              20) + ", 100%, 50%)\n                            )"),
                  })})])],1)])],1)],1):_vm._e(),_c('DirectionsRenderer',{attrs:{"travelMode":"DRIVING","origin":_vm.origin,"destination":_vm.destionation,"waypoints":_vm.waypts},on:{"onDirection":_vm.getPath}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }