var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Work Orders per Multicommessa ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.confermaSelezione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-all ")])],1)]}}])},[_c('span',[_vm._v(" Conferma Selezioni Correnti ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('v-data-table',{attrs:{"items":_vm.items_wo_multi_v,"headers":_vm.headers_wo_multi},scopedSlots:_vm._u([{key:"item.seleziona",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(item.sel),callback:function ($$v) {_vm.$set(item, "sel", $$v)},expression:"item.sel"}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }