<template>
  <div style="padding: 20px">
    <v-card>
      <v-toolbar rounede dense class="white--text" color="indigo" flat>
        <v-toolbar-title>Causali di fermo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="red--text"
          @click="clickChiudi"
          color="blue-grey lighten-5"
          small
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="header_causali_fermo"
        :items="items_causali_fermo"
        class="elevation-1"
      >
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "TabellaCausaliFermo",
  components: {},
  props: {
    header_fermi: {
      type: Array,
      default: () => [],
    },
    items_fermi: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
      header_causali_fermo:[],
      items_causali_fermo:[]
    };
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
  },
  mounted() {},
};
</script>
<style scoped>
</style>