<template>
  <div class="pt-5 px-5">
    <TabellaAnagrafiche
      :abilita_codice_fornitore="false"
      :back_as_landing_page="true"
      :check_codice_fiscale="false"
      :check_p_iva="false"
      :check_p_iva_mini="false"
      :concessionari_visible="concessionari_visible"
      :headers_scelti_anagrafiche="headers_scelti_anagrafiche"
      :rotta_back="'/home/naval'"
    />
  </div>
</template>
<script>
import TabellaAnagrafiche from "@/components/MANUTENZIONE/TabellaAnagrafiche.vue";

export default {
  name: "DGAnagrafiche",
  components: { TabellaAnagrafiche },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      concessionari_visible: true,

      // Modal
      // Array
      headers_scelti_anagrafiche: [
        { text: "Dettagli", sortable: false, value: "dettagli" },
        { text: "Ragione Sociale", sortable: false, value: "ragione_sociale" },
        { text: "Sede Operativa", sortable: false, value: "sede_operativa" },
        { text: "Marchi", sortable: false, value: "concessionari" },
        {
          text: "Ambiti Manutentivi",
          sortable: false,
          value: "ambiti_manutentivi",
        },
        { text: "Telefono", sortable: false, value: "telefono" },
        { text: "Mail", sortable: false, value: "email" },
      ],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>