/* Questa classe fornisce l'oggetto di riferimento
 * per le Sedi di Manutenzione all'interno del modulo
 * Manutenzione per EduCatt e altri. */
export default class Location {
    constructor(arg) {
        if (arg != undefined) {
            this.id_sede = arg.id_sede === undefined ? "" : arg.id_sede;
            this.id_struttura = arg.id_struttura === undefined ? "" : arg.id_struttura;
            this.id_area = arg.id_area === undefined ? "" : arg.id_area;
            this.id_stanza = arg.id_stanza === undefined ? "" : arg.id_stanza;
            this.id_zona = arg.id_zona === undefined ? "" : arg.id_zona;
            this.id_macchinario = arg.id_macchinario === undefined ? "" : arg.id_macchinario;
            this.id_centro_costo = arg.id_centro_costo === undefined ? "" : arg.id_centro_costo;
            this.des_sede = arg.des_sede === undefined ? "" : arg.des_sede;
            this.des_struttura = arg.des_struttura === undefined ? "" : arg.des_struttura;
            this.des_indirizzo = arg.des_indirizzo === undefined ? "" : arg.des_indirizzo;
            this.des_area = arg.des_area === undefined ? "" : arg.des_area;
            this.des_zona = arg.des_zona === undefined ? "" : arg.des_zona;
            this.des_stanza = arg.des_stanza === undefined ? "" : arg.des_stanza;
            this.des_macchinario = arg.des_macchinario === undefined ? "" : arg.des_macchinario;
            this.modello = arg.modello === undefined ? "" : arg.modello;
            this.telefono = arg.telefono === undefined ? "" : arg.telefono;
            this.referente = arg.referente === undefined ? "" : arg.referente;
        } else {
            this.id_sede = -1;
            this.id_struttura = null;
            this.id_area = null;
            this.id_stanza = null;
            this.id_zona = null;
            this.id_macchinario = null;
            this.id_centro_costo = null;
            this.des_sede = "";
            this.des_stanza = "";
            this.des_struttura = "";
            this.des_indirizzo = "";
            this.des_area = "";
            this.des_zona = "";
            this.des_macchinario = "";
            this.modello = "";
            this.telefono = null;
            this.referente = "";
        }
    }
}