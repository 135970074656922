<template>
    <v-dialog v-model="SHOW" persistent>
        <div class="graph_fullscreen">
            <header>
                <h1>{{DATA.nome}}</h1>
                <GraphSVG name="close" @click="close_fullscreen"  />
            </header>
            <main>
                <div :data-hide="LOADED">
                    <svg style="shape-rendering: auto;" width="120px" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <rect x="17.5" y="30" width="15" height="40">
                            <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                            <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate>
                        </rect>
                        <rect x="42.5" y="30" width="15" height="40">
                            <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                            <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate>
                        </rect>
                        <rect x="67.5" y="30" width="15" height="40">
                            <animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                            <animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                        </rect>
                    </svg>
                </div>
                 <Grafico index="fullscreen"/>
            </main>           
        </div>
    </v-dialog>
</template>
<script>
import Grafico from "@/components/GRAPH/Grafici/Grafici.vue";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name : "GraphFullscreen",
    components:{
        Grafico,
        GraphSVG
    },
    data(){
        return{
            SHOW : false,
            DATA : {},
            TIME_HANDLER : null,
            LOADED : false,
        }
    },
    mounted(){
        document.body.addEventListener('graph_fullscreen',this.show_fullscreen);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_fullscreen',this.show_fullscreen);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        show_fullscreen(data){
            data = data.detail;
            this.DATA = data;
            this.SHOW = true;
            
            let that = this;
            this.TIME_HANDLER = setInterval(function(){
                let target =  document.getElementById('chart-fullscreen');
                if(target==null||target==undefined){return;}
                let update_event = new CustomEvent('graph_update',{detail:that.DATA});
                let resize_event = new CustomEvent('graph_resize',{detail:that.DATA});
                target.dispatchEvent(update_event);                
                target.dispatchEvent(resize_event);
                clearInterval(that.TIME_HANDLER);
                that.LOADED = true;               
            },100);             
        },
        close_fullscreen(){            
            this.SHOW = false;
            this.LOADED = false;
        }
    }
}
</script>
<style src="./Fullscreen.css" scoped></style>