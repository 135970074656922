<template>
  <v-container>
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col md="12" class="py-0">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <!-- Bottone di creazione commessa singola -->
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Ciclo Standard
                  <v-icon color="white">mdi-file-cog-outline</v-icon>
                </v-toolbar-title>
                <v-text-field
                  style="background-color: white !important"
                  class="ml-2"
                  v-model="search"
                  label="Cerca"
                  single-line
                  hide-details
                >
                  <v-icon slot="append" color="primary"> mdi-magnify </v-icon>
                </v-text-field>
                <v-spacer></v-spacer>
                <Button
                  colore_icona="primary"
                  idForm="IMPOSTAZIONI"
                  idOggetto="CREA_CICLO_STANDARD"
                  mdi_icona="mdi-plus"
                  testo_tooltip="Nuova Ciclo Standard"
                  v-on:buttonClick="newCicloStd"
                />
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="clickChiudi" small>
                      <v-icon color="error">mdi-keyboard-backspace</v-icon>
                    </v-btn>
                  </template>
                  <span>Vai alla Dashboard</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  fixed-header
                  height="55vh"
                  :search="search"
                >
                  <!-- Colonna per la selezione di stato -->
                  <template
                    v-slot:[`item.edit_stato`]="{ item }"
                    v-if="cambia_stato_sel === true"
                  >
                    <v-checkbox v-model="item.edit_stato"></v-checkbox>
                  </template>
                  <!-- Colonna per l'azione di modifica -->
                  <template v-slot:[`item.edit`]="{ item }">
                    <Button
                      classe_bottone="ml-2"
                      colore_icona="primary"
                      idForm="IMPOSTAZIONI"
                      idOggetto="MODIFICA_CICLO_STANDARD"
                      mdi_icona="mdi-pencil"
                      testo_tooltip="Dettaglio CicloStd"
                      v-on:buttonClick="goToCicloStd(item)"
                    />
                  </template>
                  <!-- Colonna per l'azione di cancellazione -->
                  <template v-slot:[`item.delete`]="{ item }">
                    <Button
                      classe_bottone="ml-2"
                      colore_icona="error"
                      idForm="IMPOSTAZIONI"
                      idOggetto="ELIMINA_CICLO_STANDARD"
                      mdi_icona="mdi-window-close"
                      testo_tooltip="Dettaglio CicloStd"
                      v-on:buttonClick="deleteCiclo(item)"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackbar_background" :timeout="3000">
      <span
        :style="{
          fontSize: snackbar_font_size,
          lineHeight: snackbar_font_size,
        }"
      >
        {{ snackbar_text }}
      </span>
    </v-snackbar>
  </v-container>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
export default {
  components: {
    Button,
  },
  data() {
    return {
      cod_ciclo: null,
      cambia_stato_sel: false,
      stato_sel: null,
      // Overlay Variable
      overlay: false,
      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_color: "",
      snackbar_font_size: "",
      snackbar_text: "",
      snackbar_sfondo: "",
      show: true,
      search: "",
      des_ciclo: "",
      headers: [],
      items: [],
    };
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    deleteCiclo(item) {
      let ciclostd = [];
      let delobj = {};
      let data = {};
      delobj.id_ciclostd = item.id_ciclostd;
      delobj.validita = 0;
      // let ciclostd = []
      ciclostd.push(delobj);
      data.ciclostd = ciclostd;
      let detCicolo = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setciclostandard",
          token: localStorage.getItem("user_token"),
          tabella: [data],
        },
      };
      this.$store.dispatch("api", detCicolo).then(() => {
        let i = this.items.findIndex((it) => it == data);
        this.items.splice(i, 1);
      });
    },
    newCicloStd() {
      this.$router.push({
        name: "cicloStd",
        params: {
          id: -1,
        },
      });
    },
    goToCicloStd(item) {
      this.$router.push({
        name: "cicloStd",
        params: {
          id: item.id_ciclostd,
        },
      });
    },
    getAllCiloStd() {
      this.overlay = true;
      // Getting Ciclo lista to populate the table
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: "",
          id_articolo: "",
          tipo_import: "",
        },
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        this.overlay = false;
        const data = res.data.lista;
        this.headers = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false,
        };
        this.headers.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false,
        };
        this.headers.push(data1);
        this.items = data;
        this.overlay = false;
      });
    },
  },
  mounted() {
    this.getAllCiloStd();
  },
};
</script>
<style lang="scss" scoped></style>
