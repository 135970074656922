var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":false,"persistent":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(
        Object.keys(_vm.item).length != 0 &&
        _vm.item.id_ticket_attivita != undefined &&
        _vm.item.id_ticket_attivita != -1
      ),expression:"\n        Object.keys(item).length != 0 &&\n        item.id_ticket_attivita != undefined &&\n        item.id_ticket_attivita != -1\n      "}],attrs:{"color":"primary","medium":""}},on),[_vm._v(" mdi-pencil ")]),_c('div',_vm._g({},on),[(
          Object.keys(_vm.item).length == 0 ||
          _vm.item.id_ticket_attivita == undefined ||
          _vm.item.id_ticket_attivita == -1
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-note-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(" Aggiungi Attività "+_vm._s(_vm.assistenza == 1 ? "Assistenza" : ""))])]):_vm._e()],1)]}}]),model:{value:(_vm.dialogSingolaAttivita),callback:function ($$v) {_vm.dialogSingolaAttivita=$$v},expression:"dialogSingolaAttivita"}},[_c('div',{staticClass:"main-div ml-auto mr-auto py-0"},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.single_item.id_ticket_attivita != -1 ? " Modifica Attività ticket" : "Crea Attività")+" "+_vm._s(_vm.assistenza == 1 ? "Assistenza " : " ")+" "+_vm._s(_vm.single_item.id_ticket_attivita != -1 ? this.item.id_ticket : "")+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.valid || _vm.saving,"small":""},on:{"click":function($event){return _vm.validate()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Invia Attività")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.dialogSingolaAttivita = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-start"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","type":"text","label":"Descrizione attività","required":""},model:{value:(_vm.single_item.des_attivita),callback:function ($$v) {_vm.$set(_vm.single_item, "des_attivita", $$v)},expression:"single_item.des_attivita"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.utenti,"label":"Risorsa","get-label":_vm.getLabel,"item-value":"id_ticket_risorsa","item-text":"nominativo","placeholder":"Digita per cercare"},model:{value:(_vm.single_item.id_ticket_risorsa),callback:function ($$v) {_vm.$set(_vm.single_item, "id_ticket_risorsa", $$v)},expression:"single_item.id_ticket_risorsa"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","autocomplete":"off","label":"Data Inizio","persistent-hint":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Data Fine","autocomplete":"off","persistent-hint":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":3}},[_c('v-text-field',{attrs:{"dense":"","label":"Quantita","type":"number","min":"0","max":"1000","step":"1","onkeydown":"javascript: return event.keyCode == 69 ? false : true"},model:{value:(_vm.single_item.ore),callback:function ($$v) {_vm.$set(_vm.single_item, "ore", $$v)},expression:"single_item.ore"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":3}},[_c('v-select',{attrs:{"dense":"","items":_vm.misure,"label":"Unità","item-text":"des","item-value":"id_tabella"},model:{value:(_vm.int_id_um_ore),callback:function ($$v) {_vm.int_id_um_ore=$$v},expression:"int_id_um_ore"}})],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }