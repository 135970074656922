<template>
  <div class="mainDash">
    <v-container id="app">
      <div style="position: absolute; top: 10px; right: 20px">
        v.{{ this.$store.state.versione }}
      </div>
      <svg id="icons">
        <symbol id="pdfconvert" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,10.5H13V13.5H12V10.5M7,11.5H8V10.5H7V11.5M20,6V18A2,2 0 0,1 18,20H6A2,2 0 0,1 4,18V6A2,2 0 0,1 6,4H18A2,2 0 0,1 20,6M9.5,10.5A1.5,1.5 0 0,0 8,9H5.5V15H7V13H8A1.5,1.5 0 0,0 9.5,11.5V10.5M14.5,10.5A1.5,1.5 0 0,0 13,9H10.5V15H13A1.5,1.5 0 0,0 14.5,13.5V10.5M18.5,9H15.5V15H17V13H18.5V11.5H17V10.5H18.5V9Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="listadipicking" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M16,4L9,8.04V15.96L16,20L23,15.96V8.04M16,6.31L19.8,8.5L16,10.69L12.21,8.5M0,7V9H7V7M11,10.11L15,12.42V17.11L11,14.81M21,10.11V14.81L17,17.11V12.42M2,11V13H7V11M4,15V17H7V15"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="distintabase" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13,15L15.5,17.5L16.92,16.08L12,11.16L7.08,16.08L8.5,17.5L11,15V21H13V15M3,3H21V5H3V3M3,7H13V9H3V7Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="articolo" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M11.97,19.88C10.93,20.26 9.78,19.72 9.4,18.69C9,17.65 9.55,16.5 10.59,16.13C11.63,15.75 12.78,16.28 13.16,17.31C13.54,18.35 13,19.5 11.97,19.88M9.9,14.25C7.83,15 6.76,17.3 7.5,19.38C8.28,21.45 10.58,22.5 12.66,21.75C14.73,21 15.79,18.7 15.04,16.63C14.28,14.55 11.97,13.5 9.9,14.25M15.94,4.58L9.37,7L10.75,10.74L17.32,8.33M20.32,13.62L15.54,15.37C15.71,15.66 15.85,15.96 15.97,16.28C16.09,16.6 16.17,16.93 16.22,17.25L21,15.5M19.54,8.58L11.09,11.68L11.58,13C12.83,13.09 14,13.64 14.89,14.55L20.92,12.34M2,2V4H5.09L8.66,13.75C8.94,13.57 9.24,13.43 9.56,13.31C9.88,13.19 10.21,13.11 10.53,13.06L6.5,2"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="diario" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M4 7H2V21C2 22.1 2.9 23 4 23H18V21H4M20 3H16.8C16.4 1.8 15.3 1 14 1C12.7 1 11.6 1.8 11.2 3H8C6.9 3 6 3.9 6 5V17C6 18.1 6.9 19 8 19H20C21.1 19 22 18.1 22 17V5C22 3.9 21.1 3 20 3M14 3C14.6 3 15 3.5 15 4C15 4.5 14.5 5 14 5C13.5 5 13 4.5 13 4C13 3.5 13.4 3 14 3M12.3 15.1L9 11.8L10.4 10.4L12.3 12.3L17.6 7L19 8.4"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="checklist" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M4 8H2V2H4V8M2 22H4V16H2V22M3 10C1.9 10 1 10.9 1 12C1 13.11 1.9 14 3 14C4.11 14 5 13.11 5 12C5 10.9 4.11 10 3 10M24 6V18C24 19.11 23.11 20 22 20H10C8.9 20 8 19.11 8 18V14L6 12L8 10V6C8 4.89 8.9 4 10 4H22C23.11 4 24 4.89 24 6M19.75 10.33L18.59 8.92L15 12.5L13.41 10.92L12.25 12.08L15 15.08L19.75 10.33Z" />
            </svg>
          </g>
        </symbol>
      </svg>
      <Radial_Menu
        ref="radialMenu"
        @clicked="menuClicked"
        @touchend="menuClicked"
        :menu-items="menuItems"
        :size="altezza - 55"
      ></Radial_Menu>
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        v-model="snackbar"
        :timeout="3000"
      >
        <v-icon :size="64">{{ snackbar_icon }}</v-icon>
        <h2 class="ml-16">{{ snackbar_text }}</h2>

        <v-btn
          @click="snackbar = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import RadialMenu from "./ElectricalDashComp/ElectricalRadialMenu.vue";

export default {
  name: "app",
  data: function () {
    return {
      // Variabili d'appoggio
      altezza: 0,

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_text: "",

      // Array
      menuItems: [],
    };
  },
  computed: {
    id_profilo() {
      return localStorage.id_profilo;
    },
  },
  methods: {
    isModuloAutorizzato(id_modulo_menu) {
      let menu_presente = false;
      this.$store.state.menu_sx.forEach((elem) => {
        if (elem.id_modulo_menu === id_modulo_menu) {
          menu_presente = true;
        }
      });

      if (menu_presente === true) {
        return true;
      } else {
        return false;
      }
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
    menuClicked: function (menuItem) {
      this.$router.push(menuItem.path);
    },
    openMenu: function () {
      this.$refs.radialMenu.open();
    },
    closeMenu: function () {
      this.$refs.radialMenu.close();
    },
  },
  created() {
    // Popolo il radial menu.
    let menuArr = JSON.parse(localStorage.menu);

    for (let index = 0; index < menuArr.length; index++) {
      if (menuArr[index].text != "Manutenzione") {
        const element = menuArr[index];
        let strval = "";

        const newEl = {};

        newEl.id = element.des;
        strval = element.text.toLowerCase();

        newEl.title = strval.charAt(0).toUpperCase() + strval.slice(1);

        newEl.title.charAt(0).toUpperCase();

        newEl.icon = `#${element.des}`.toLowerCase().replaceAll(" ", "");

        newEl.path = element.path;
        if (newEl.id != "dashboard electrical") {
          this.menuItems.push(newEl);
        }
      }
    }

    // if (localStorage.id_profilo != 3 && localStorage.id_profilo != 4) {
    //   this.menuItems = [
    //     {
    //       id: "Richieste",
    //       title: "Richieste",
    //       icon: "#richieste",
    //     },
    //     {
    //       id: "Interventi",
    //       title: "Gestione Intervento",
    //       icon: "#interventi",
    //     },
    //     {
    //       id: "Regole",
    //       title: "Regole",
    //       icon: "#regole",
    //     },
    //     {
    //       id: "Attivita",
    //       title: "Attività",
    //       icon: "#attivita",
    //     },
    //     // {
    //     //   id: "ManutenzioneMacchinari",
    //     //   title: "Gestione Macchinari",
    //     //   icon: "#manutenzionemacchinari",
    //     // },
    //     {
    //       id: "Anagrafiche",
    //       title: "Anagrafiche",
    //       icon: "#anagrafiche",
    //     },
    //     {
    //       id: "GestioneSediFornitori",
    //       title: "Gestione Luoghi d'Intervento/ Fornitori",
    //       icon: "#gestionesedifornitori",
    //     },
    //     {
    //       id: "ManutenzioneRecursiva",
    //       title: "Manutenzione Recursiva",
    //       icon: "#manutenzionerecursiva",
    //     },
    //   ];
    //   // } else {
    //   //   this.menuItems = [
    //   //     {
    //   //       id: "Interventi",
    //   //       title: "Gestione Intervento",
    //   //       icon: "#interventi",
    //   //     },
    //   //     {
    //   //       id: "Attivita",
    //   //       title: "Attività",
    //   //       icon: "#attivita",
    //   //     },
    //   //     {
    //   //       id: "Anagrafiche",
    //   //       title: "Anagrafiche",
    //   //       icon: "#anagrafiche",
    //   //     },
    //   //     {
    //   //       id: "GestioneSediFornitori",
    //   //       title: "Gestione Luoghi d'Intervento/ Fornitori",
    //   //       icon: "#gestionesedifornitori",
    //   //     },
    //   //   ];
    // }
  },
  mounted() {
    let that = this;

    this.altezza = window.innerHeight;

    window.addEventListener("resize", function () {
      that.altezza = window.innerHeight;
    });
  },
  components: {
    Radial_Menu: RadialMenu,
  },
  watch: {},
};
</script>

<style scoped>
.allegati {
  width: 1rem !important;
  height: 1rem !important;
}

.icons {
  width: 15rem !important;
  height: 5rem !important;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

#icons {
  display: none;
  size: 16px;
}

#app {
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: white;
}

div.info {
  margin: 15px 0;
}

div.info * {
  color: white;
  text-shadow: 1px 1px 0 #000000d0;
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.mainDash {
  width: 100% !important;
  background-image: url("../../assets/Ind.jpg");

  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100% !important; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden !important; /* Resize the background image to cover the entire container */
}
svg #shadow {
  transition: all 0.65s;
  opacity: 1;
}
svg:hover #shadow {
  opacity: 0;
}
symbol:hover {
  color: aliceblue;
  box-shadow: 1000px;
}
</style>
