<template>
  <v-row align="center" justify="center" id="listener_pezzi_registrati">
    <v-col class="ma-0 py-0" cols="12" align="center" justify="center">
      <v-data-table
        :headers="headers_pezzi_registrati"
        :items="items_pezzi_registrati"
        :items-per-page="5"
        class="elevation-1"
        style="width: 100%"
      >
        <!-- Titolo della tabella -->
        <template v-slot:top>
          <v-toolbar color="indigo" class="white--text rounded-t" dense>
            <v-toolbar-title>Pezzi Registrati</v-toolbar-title>
          </v-toolbar>
        </template>
        <!-- Bottone Stampa -->
        <template v-slot:[`item.stampa`]="{ item }">
          <v-btn @click="clickStampa(item)" small>
            <v-icon color="primary">mdi-printer</v-icon>
          </v-btn>
        </template>
        <!-- Bottone Elimina -->
        <template v-slot:[`item.elimina`]="{ item }">
          <v-btn @click="clickElimina(item)" small>
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  <!-- Gestione dialog Stampa Cartellino -->
    <v-dialog v-model="pdfdialog" width="unset">
      <v-card>
          <v-toolbar class="white--text" color="blue" dense style="position: sticky; top: 0px; z-index: 1">
              <v-toolbar-title> Stampa Pdf </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="red--text" @click="pdfdialog = false" color="blue-grey lighten-5" small>
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </v-toolbar>
          <v-row>
              <v-col cols="12" sm="6" md="12">
                  <iframe
                      :src="selected_pdf"
                      frameborder="0"
                      style="height: 90vh !important; width: 90vw !important"
                  >
                  </iframe>
              </v-col>
          </v-row>
      </v-card>
    </v-dialog>
  </v-row>
  <!--</div>-->

</template>

<script>
import moment from "moment/moment";
export default {
  name: "PezziRegistrati",
  props: {
    visibile: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      saldo_fase_commessa: false,
      pdfdialog: null,
      selected_pdf: null,
      // Array
      headers_pezzi_registrati: [
        { text: "Stampa", sortable: false, value: "stampa" },
        {
          text: "Num. Commessa",
          sortable: false,
          value: "numero_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Data",
          sortable: false,
          value: "_data",
        },
        {
          text: "Nome",
          sortable: false,
          value: "nominativo",
        },
        {
          text: "Des. Macchina",
          sortable: false,
          value: "des_macchina",
        },
        {
          text: "Quantità",
          sortable: false,
          value: "quantita",
        },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      items_pezzi_registrati: [],
    };
  },
  methods: {
    // clickElimina(item) {
    //   console.log(JSON.stringify(item));
    // },
    clickStampa(item) {
      console.log(JSON.stringify(item));
      let request = {
                controller: 'Report',
                method: 'POST',
                richiesta: {
                    action: 'getreportpdf',
                    token: localStorage.getItem('user_token'),
                    cod_report: 'Cartellino',
                    id: item.id_movimento,
                },
            };
            this.$store.dispatch('api', request).then((res) => {
                const byteCharacters = atob(res.data.result);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);
                this.selected_pdf = blobUrl;
                this.pdfdialog = true;
            });
    },
    getAvanzamentoPezzi() {
      // Stabilisco che data è, per inserire i filtri data del BE
      let oggi = moment();
      let ieri = moment().subtract(1, "days");

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessaavanzamento",
          token: localStorage.getItem("user_token"),
          tipo: "p",
          analisi: "d",
          data_da: ieri.year() + "-" + (ieri.month() + 1) + "-" + ieri.date(),
          data_a: oggi.year() + "-" + (oggi.month() + 1) + "-" + oggi.date(),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Svuoto l'array che contiene i dati dell'avanzamento
        this.items_pezzi_registrati = [];

        // Popolo l'array che contiene i dati totali
        res.data.forEach((elem) => {
          this.items_pezzi_registrati.push(elem);
        });
      });
    },
  },
  mounted() {
    let that = this;

    document
      .getElementById("listener_pezzi_registrati")
      .addEventListener("effettuata_registrazione", function () {
        that.getAvanzamentoPezzi();
      });
  },
  watch: {
    visibile: {
      immediate: true,
      handler() {
        if (this.visibile == true) {
          this.getAvanzamentoPezzi();
        }
      },
    },
  },
};
</script>
<style>
</style>