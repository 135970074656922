import { render, staticRenderFns } from "./TabellaCausaliFermo.vue?vue&type=template&id=6e9e42f0&scoped=true&"
import script from "./TabellaCausaliFermo.vue?vue&type=script&lang=js&"
export * from "./TabellaCausaliFermo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e9e42f0",
  null
  
)

export default component.exports