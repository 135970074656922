<template>
    <v-dialog persistent v-model="ui.show">
        <div class="graph_overall">
            <header>
                <h1>Impostazione Parametri Generali</h1>
                <GraphSVG @click="ui.show=false" name="close"/>
            </header>
            <v-container>                
                
                <v-row align="center">
                    <v-col cols="6" >
                        <template v-for="(item,i) in data" >
                            <div :key="i">
                                <TextEditorOverall v-show="item.assi!='1'" v-model="data[i]" :template="[]" v-if="is_selected(item)" :key="ui.key" />
                            </div>                            
                        </template>
                    </v-col>
                    <v-col cols="5">
                        <div style="max-height:300px;overflow-y:auto;overflow-x:hidden" class="graph_scrollbar_input">
                            <template  v-for="(item,i,j) in data" >
                                <v-row align="center" class="ma-0 pa-0" :key="i" v-if="is_valid(item)">
                                    <v-col cols="11">
                                        <v-row align="center">
                                            <v-col cols="6">
                                                <v-text-field prepend-inner-icon="mdi-currency-usd" :label="'Codice parametro '+(i+1)" v-model="item.nome" @focus="ui.selected=i"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field label="Descrizione" v-model="item.descrizione" @focus="ui.selected=i"/>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center">
                                            <v-col cols="6">
                                                <v-select label="Tipo Dati" v-model="item.assi" :items="types" @focus="ui.selected=i"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select label="Database" :disabled="item.assi=='1'" v-model="item.tipo_query" :items="db_list" item-text="cod" item-value="id_tabella" @focus="ui.selected=i"/>
                                            </v-col>
                                        </v-row>
                                    </v-col>                                    
                                    <v-col cols="1">
                                        <v-btn icon @click="del(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>                                
                                </v-row>
                                <v-divider :key="j"/>
                            </template>                                                        
                        </div>
                    </v-col>
                    <v-col cols="1">
                        <v-btn icon large @click="add">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="3">
                        <v-btn text :color="ui.color" @click="save">
                            <v-icon class="mr-2">mdi-floppy</v-icon>Salva modifiche
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>           
        </div>
    </v-dialog>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import TextEditorOverall from "@/components/GRAPH/Dashboard/Editor/TextEditor_overall.vue";
export default {
    name : 'GraphOverall',
    components : {
        GraphSVG,
        TextEditorOverall
    },
    data(){
        return {
            data : [],
            db_list : [],
            id : null,
            NETWORK : null, 
            template : {
                nome : null,
                descrizione : null,
                id_ds_dettaglio : -1,
                famiglia_grafici : -1,
                query : null,
                validita : 1
            },
            types: [
                {text:'Valore Singolo',value:'0'},
                {text:'Data',value:'1'},
                {text:'Multiselect',value:'2'},
            ],
            ui : {
                show : false,
                color : null,
                selected : null,
                key : 0
            }
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        document.body.addEventListener('graph_overall',this.show);
        this.ui.color = getComputedStyle(document.documentElement).getPropertyValue('--widget_title_background');
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_overall',this.show);
    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        add(){
            this.data.push(Object.assign({},this.template));
        },
        del(item){
            item.validita = 0;
            this.ui.selected = null;
        },
        is_selected(item){
            return (this.data[this.ui.selected] == item );
        },
        is_valid(item){
            return item.validita == 1;
        },
        save(){
            let data = [];
            for(let i=0;i<this.data.length;i++){
                let datum = Object.assign({},this.data[i]);
                if(datum.nome == null||datum.nome==undefined){datum.nome='';}
                datum.nome = '$' + datum.nome.replaceAll('$','');
                datum.id_ds_rif = this.id;
                data.push(datum);
            }
            
            let that = this;
            let request =  {
                controller : 'Graph',
                body : {
                    action: "setOverallDettaglio",
                    token: localStorage.user_token,
                    overall : data,
                },
                method: 'PUT',
                on : {
                    load : function(){                 
                        let msg = new CustomEvent('dashboardrefresh',{detail:null});
                        document.getElementById('quickchart_dashboard_main').dispatchEvent(msg);
                        that.ui.show = false;
                        that.ui.selected=null;
                    },
                    error : function(){alert('Si è verificato un errore durante il salvataggio dei dati');}
                }
            }
            this.NETWORK.AjaxRequest(request);
        },
        show(data){
            this.id = data.detail;   
            let that = this;

            let database_list_request =  {
                controller : 'Global',
                body : { 
                    action: "gettabella",
                    tipo: "TIPI_QUERY_GRAFICI",
                    token: localStorage.user_token
                },
                method: 'POST',
                response : { parse : false },
                on :{
                    load : function(response){
                        try{
                            response = JSON.parse(response);
                            that.db_list = response;
                        }catch(error){
                            return;
                        }
                        let request =  {
                            controller : 'Graph',
                            body : {
                                action: "getOverallDettaglio",
                                token: localStorage.user_token,
                                id_ds : data.detail,
                            },
                            method: 'POST',
                            on : {
                                load : function(response){                    
                                    that.data = response.overall;
                                    for(let i=0;i<that.data.length;i++){
                                        let param = that.data[i].nome;
                                        if(param==null||param==undefined){param='';}
                                        that.data[i].nome = param.replaceAll('$','');
                                    }
                                    that.ui.show=true;                  
                                }
                            }
                        }
                        that.NETWORK.AjaxRequest(request);
                    }
                }
            }
            this.NETWORK.AjaxRequest(database_list_request);
        }
    }
}
</script>
<style src="./Overall.css" scoped />