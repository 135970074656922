var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Termina Produzione ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.terminaProduzione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success","large":""}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva Interruzione")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","large":""}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-5"},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Indicare l'avanzo di bobina per terminare la produzione ")]),_c('v-container',{staticClass:"mt-3"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v("Nessun avanzo")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_c('b',[_vm._v("Avanzo presente")])])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-icon',{attrs:{"color":_vm.avanzo_presente === 0 ? "success" : "","large":""},on:{"click":function($event){_vm.avanzo_presente = 0;
              _vm.percentuale_avanzo = 0;}}},[_vm._v(" "+_vm._s(_vm.avanzo_presente === 0 ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-icon',{attrs:{"color":_vm.avanzo_presente === 1 ? "success" : "","large":""},on:{"click":function($event){_vm.avanzo_presente = 1;
              _vm.percentuale_avanzo = _vm.items_percentuali[0].value;}}},[_vm._v(" "+_vm._s(_vm.avanzo_presente === 1 ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])],1)],1)],1),(_vm.avanzo_presente === 1)?_c('v-card',{staticClass:"elevation-0 mt-3",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.percentuale_avanzo),callback:function ($$v) {_vm.percentuale_avanzo=$$v},expression:"percentuale_avanzo"}},[[_c('span',{staticClass:"mb-5",staticStyle:{"font-size":"20px"}},[_vm._v(" Indicare la percentuale di avanzo bobina ")])],_vm._l((this.items_percentuali),function(item,i){return _c('v-radio',{key:i,attrs:{"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(((item.value) + "%")))])]},proxy:true}],null,true)})})],2)],1)],1):_vm._e()],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }