<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-hover>
        <template v-slot="{ hover }">
          <v-col cols="12">
            <v-card :elevation="hover ? 24 : 2">
              <v-toolbar
                dense
                class="indigo darken-1 headline"
                primary-title
                uppercase
                ><v-toolbar-title class="white--text"
                  >Categoria</v-toolbar-title
                ></v-toolbar
              >
              <v-data-table :headers="headers"></v-data-table>
            </v-card>
          </v-col>
        </template>
      </v-hover>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "",
          value: "",
          align: "start",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "descrizione",
          sortable: false,
        },
        {
          text: "Categoria",
          value: "categoria",
          sortable: false,
        },
        {
          text: "Annotazioni",
          value: "annotazioni",
          sortable: false,
        },
      ],
    };
  },
};
</script>
