<template>
  <v-card
    id="listener_Produzione2"
    style="text-align: center; padding: 20px; height: 100%"
  >
    <Carrello2
      :back_as_landing_page="back_as_landing_page"
      @click_aggiungi="getWorkOrders()"
      @click_aggiungi_multiregistrazione="handleAddMultiRegistrazione"
      @click_indietro="handleClickIndietro"
      @click_nascondi="nascondiWorkOrder"
      @click_registra_pezzi="clickRegistraPezzi"
      @click_visualizza_allegto_controllo="clickVisualizzaAllegatoControllo"
      @click_visualizza_disegno="clickVisualizzaDisegno"
      @click_start="clickStart"
      @click_stop="clickStop"
      :headers_carrello="headers_carrello"
      :items_carrello="work_orders_in_corso"
      :multicommessa_visible="multicommessa_visible"
      ref="Carrello2"
      style="width: 100%"
    />

    <!-- Dialog del consuntivo dei work order -->
    <v-dialog v-model="consuntivo_commesse_visible" width="unset">
      <ConsuntivoWorkOrder
        :items_consuntivo="work_orders_totali"
        :visible="consuntivo_commesse_visible"
        @click_chiudi="consuntivo_commesse_visible = false"
        @inviato_elemento="consuntivo_commesse_visible = false"
        v-if="consuntivo_commesse_visible == true"
      />
    </v-dialog>

    <!-- Dialog del consuntivo delle macchine multicommessa -->
    <v-dialog v-model="macchine_multi_visible">
      <ConsuntivoMacchineMulti
        :items_macchine_multi="items_macchine_multi"
        :visible="macchine_multi_visible"
        @click_chiudi="macchine_multi_visible = false"
        @selezionata_macchina="handleSelezionataMacchina"
        v-if="macchine_multi_visible === true"
      />
    </v-dialog>

    <!-- Dialog del consuntivo dei work order per la multicommessa -->
    <v-dialog v-model="consuntivo_wo_multi_visible">
      <ConsuntivoWorkOrderMulti
        :items_wo_multi="items_wo_multi"
        :visible="consuntivo_wo_multi_visible"
        @click_chiudi="consuntivo_wo_multi_visible = false"
        @selezionati_wo="handleSelezionatiWO"
        v-if="consuntivo_wo_multi_visible === true"
      />
    </v-dialog>

    <!-- Dialog di registrazione pezzi -->
    <!--<div
      style="
        background-color: rgba(33, 33, 33, 0.46);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      "
      v-if="registra_pezzi_visible"
    >
      <div
        style="height: 90%; left: 1.5%; position: absolute; top: 5%; width: 97%"
      >-->
    <v-dialog v-model="registra_pezzi_visible">
      <RegistraPezzi
        @click_controllo="clickControllo"
        @click_avanti="
          (registra_pezzi_visible = !registra_pezzi_visible),
            (registra_tempi_visible = true),
            getWorkOrdersUtente()
        "
        @click_indietro="registra_pezzi_visible = !registra_pezzi_visible"
        @click_salva_esci="
          (registra_pezzi_visible = !registra_pezzi_visible),
            getWorkOrdersUtente()
        "
        :flag_em_registrazione="filtroEM === '1' ? filtroEM : ''"
        :visible="registra_pezzi_visible"
        @work_order_completato="handleWorkOrderCompletato"
        :work_order_sel="work_order_sel"
      />
    </v-dialog>
    <!--</div>
    </div>-->

    <v-dialog v-model="registra_pezzi_multi_visible">
      <RegistraPezziMulti
        @click_indietro="
          registra_pezzi_multi_visible = !registra_pezzi_multi_visible
        "
        :items_wo_multi_reg_pezzi="items_wo_multi_reg_pezzi"
        :key="key_registra_pezzi_multi"
        @registrati_pezzi="
          getWorkOrdersUtente();
          registra_pezzi_multi_visible = false;
        "
        :visible="registra_pezzi_multi_visible"
      />
    </v-dialog>

    <v-dialog persistent v-model="registra_tempi_visible">
      <RegistraTempi
        @click_avanti="
          (registra_tempi_visible = !registra_tempi_visible),
            getWorkOrdersUtente()
        "
        @click_indietro="
          (registra_tempi_visible = !registra_tempi_visible),
            (registra_pezzi_visible = !registra_pezzi_visible)
        "
        :work_order_sel="work_order_sel"
      />
    </v-dialog>
    <v-dialog persistent v-model="visualizza_disegno_visible">
      <VisualizzaDisegno
        @click_chiudi="validaDisegno"
        :visible="visualizza_disegno_visible"
        :work_order_sel="work_order_sel"
      />
    </v-dialog>
    <v-dialog persistent v-model="allegato_controllo_visible" width="unset">
      <GestioneAllegato
        @click_chiudi="allegato_controllo_visible = false"
        :consenti_upload="false"
        controller_allegati="Global"
        controller_caratteristiche_tabella="Manutenzione"
        :id_riferimento="
          work_order_sel != null ? work_order_sel.id_ciclo_fasi : null
        "
        ref="componenteAllegati"
        @snackbarOpen="snackbarOpen"
        tipo_allegato="CONTROLLO"
        :tipo_headers="'minimi'"
      />
    </v-dialog>
    <div
      style="
        background-color: rgba(33, 33, 33, 0.46);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
      "
      v-if="controllo_dialog_visible"
    >
      <div
        style="height: 90%; left: 5%; position: absolute; top: 5%; width: 90%"
      >
        <Controllo
          @click_indietro="controllo_dialog_visible = false"
          :id_causale_sel="id_causale_sel"
        />
      </div>
    </div>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      style="z-index: 200001 !important"
      :timeout="3000"
      v-model="snackbar"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import Carrello2 from "@/components/PRODUCTION/ProduzioneOnline/Carrello2.vue";
import ConsuntivoWorkOrder from "@/components/PRODUCTION/ProduzioneOnline/ConsuntivoWorkOrder.vue";
import ConsuntivoWorkOrderMulti from "@/components/PRODUCTION/ProduzioneOnline/ConsuntivoWorkOrderMulti.vue";
import ConsuntivoMacchineMulti from "@/components/PRODUCTION/ProduzioneOnline/ConsuntivoMacchineMulti.vue";
import RegistraPezzi from "@/components/PRODUCTION/ProduzioneOnline/RegistraPezzi.vue";
import RegistraPezziMulti from "@/components/PRODUCTION/ProduzioneOnline/RegistraPezziMulti.vue";
import RegistraTempi from "@/components/PRODUCTION/ProduzioneOnline/RegistraTempi.vue";
import VisualizzaDisegno from "@/components/PRODUCTION/ProduzioneOnline/VisualizzaDisegno.vue";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
export default {
  components: {
    Carrello2,
    ConsuntivoMacchineMulti,
    ConsuntivoWorkOrder,
    ConsuntivoWorkOrderMulti,
    Controllo,
    GestioneAllegato,
    RegistraPezzi,
    RegistraPezziMulti,
    RegistraTempi,
    VisualizzaDisegno,
  },
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    cod_commessa: {
      type: String,
      default: "",
    },
    commessa: {
      type: Object,
      default: () => {},
    },
    filtroEM: {
      type: String,
      default: "",
    },
    headers_carrello: {
      type: Array,
      default: () => [
        {
          text: "Start/Stop",
          sortable: false,
          value: "start_stop",
          class: "primary--text white",
        },
        {
          text: "Registra",
          sortable: false,
          value: "registra",
          class: "primary--text white",
        },
        {
          text: "Dettagli",
          sortable: false,
          value: "visualizza_dettagli",
          class: "primary--text white",
        },
        {
          text: "Allegato Controllo",
          sortable: false,
          value: "visualizza_allegato_controllo",
          class: "primary--text white",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
          class: "primary--text white",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
          class: "primary--text white",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
          class: "primary--text white",
        },
        {
          text: "Cod. Macchina",
          sortable: false,
          value: "cod_macchina",
          class: "primary--text white",
        },

        {
          text: "Lavorazione",
          sortable: false,
          value: "des_fase",
          class: "primary--text white",
        },
        {
          text: "Q.tà Totale",
          sortable: false,
          value: "quantita",
          class: "primary--text white",
        },
        {
          text: "Q.tà Prodotta",
          sortable: false,
          value: "pezzi_registrati",
          class: "primary--text white",
        },
        {
          text: "Q.tà Fase Precedente",
          sortable: false,
          value: "qta_precedente",
          class: "primary--text white",
        },
        {
          text: "Ora Start",
          sortable: false,
          value: "ora_start",
          class: "primary--text white",
        },
        {
          text: "Ora Stop",
          sortable: false,
          value: "ora_stop",
          class: "primary--text white",
        },

        {
          text: "Ore Registrate",
          sortable: false,
          value: "ore_produzione",
          class: "primary--text white",
        },
        {
          text: "Q.tà Registrata",
          sortable: false,
          value: "pezzi_produzione",
          class: "primary--text white",
        },
        {
          text: "Produzione Reale",
          sortable: false,
          value: "pzhreale",
          class: "primary--text white",
        },
        {
          text: "Tempo Ciclo",
          sortable: false,
          value: "tempo_ciclo",
          class: "primary--text white",
        },
        {
          text: "Nascondi",
          sortable: false,
          value: "nascondi",
          class: "primary--text white",
          align: "center",
        },
      ],
    },
    multicommessa_visible: {
      type: Boolean,
      default: true,
    },
    open_consuntivi_if_empty: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      caricamento_dati: false,
      id_causale_sel: null,
      key_registra_pezzi_multi: 0,
      work_order_sel: null,

      // Modal
      allegato_controllo_visible: false,
      consuntivo_commesse_visible: false,
      consuntivo_wo_multi_visible: false,
      controllo_dialog_visible: false,
      macchine_multi_visible: false,
      registra_pezzi_visible: false,
      registra_pezzi_multi_visible: false,
      registra_tempi_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      visualizza_disegno_visible: false,

      // Array
      items_wo_multi: [],
      items_wo_multi_reg_pezzi: [],
      items_macchine_multi: [],
      work_orders_in_corso: [],
      work_orders_totali: [],
    };
  },
  watch: {
    visible: {
      immediate: true,
      async handler() {
        if (this.visible === true) {
          // Popolo la lista delle macchine
          this.getListaMacchine();

          // Popolo l'array dei work order già presi in carico.
          await this.getWorkOrdersUtente();

          /* Se sono non ci sono WO in corso e la prop me
           * lo consente, apro il dialog dei consuntivi per
           * scegliere dei WO da aggiungere al carrello. */
          if (
            this.work_orders_in_corso.length === 0 &&
            this.open_consuntivi_if_empty === true
          ) {
            this.$refs.Carrello2.clickAggiungi();
          }
        }
      },
    },
  },
  methods: {
    aggiungiWorkOrder(messaggio) {
      // Controllo che la commessa non sia già nel carrello.
      let ricorre = false;

      this.work_orders_in_corso.forEach((elem) => {
        if (
          elem.id_commessa_dettaglio ==
          messaggio.detail.work_order_sel.id_commessa_dettaglio
        ) {
          ricorre = true;
        }
      });

      // Stampo errore se la commessa è già nel carrello.
      if (ricorre == true) {
        this.snackbar = true;
        this.snackbar_background = "grey lighten-3";
        this.snackbar_icon = "mdi-information-outline";
        this.snackbar_icon_color = "indigo";
        this.snackbar_text = "Work Order già presente nel carrello.";
        this.snackbar_text_color = "#3F51B5";
      } else {
        /* Se è tutto ok aggiungo la commessa al carrello e apro
         * la finestra di evasione della commessa.*/
        let WO_da_aggiungere = Object.assign(
          {},
          messaggio.detail.work_order_sel
        );

        this.work_orders_in_corso.push(WO_da_aggiungere);

        /* Se il work order ha un disegno e non l'ho ancora visto,
         * faccio partire automaticamente la visualizzazione del sisegno.*/
        /*if (
          WO_da_aggiungere.id_disegno != null &&
          WO_da_aggiungere.view_disegno == 0
        ) {
          this.clickVisualizzaDisegno(WO_da_aggiungere);
        }*/
      }
    },
    chiudi_allegato_controllo() {
      this.allegato_controllo_visible = false;
    },
    clickConsuntivoCommesse() {
      this.consuntivo_commesse_visible = true;
    },
    clickControllo(id) {
      this.controllo_dialog_visible = true;
      // Compongo un messaggio.
      let messaggio = {
        detail: {
          id: id,
        },
        src: null,
      };

      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("nuovo_id", messaggio);

      // Recapito l'evento alla mia destinazione.
      setTimeout(function () {
        document
          .getElementById("listener_visualizzatore")
          .dispatchEvent(evento);
        /*console.log(
          "Mandato evento al Visualizzatore con id " + messaggio.detail.id
        );*/
      }, 1000);
    },
    clickRegistraPezzi(item) {
      this.registra_pezzi_visible = true;
      this.work_order_sel = Object.assign({}, item);
    },
    clickStart() {
      this.getWorkOrdersUtente();
    },
    clickStop(commessa) {
      // Ricarico i dati dal BE
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersutente",
          token: localStorage.getItem("user_token"),
          cod_commessa: this.filtroEM === "1" ? this.commessa.cod_commessa : "",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Svuoto l'array che contiene le commesse in gestione
          this.work_orders_in_corso = [];

          // Popolo l'array che contiene i dati totali
          res.data.workorders.forEach((elem) => {
            /* Se ce l'ha, formatto la Produzione reale della fase
             * per convenienza di FE. */
            if (elem.pzhreale != null) {
              elem.pzhreale = elem.pzhreale.toFixed(2);
            }

            /* Se ce l'ha, formatto il tempo ciclo della fase
             * per convenienza di FE. */
            if (elem.tempo_ciclo != null) {
              elem.tempo_ciclo = elem.tempo_ciclo.toFixed(2);
            }

            this.work_orders_in_corso.push(elem);
          });

          this.caricamento_dati = false;

          // Cerco il Work Order che ho selezionato e ne apro la Dichiarazione
          this.work_orders_in_corso.forEach((elem) => {
            if (elem.id_commessa_dettaglio == commessa.id_commessa_dettaglio) {
              this.clickRegistraPezzi(elem);
            }
          });
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile caricare i dati: codice errore ${res.status}.`;
          this.snackbar_text_color = "white";
        }
      });
    },
    clickVisualizzaAllegatoControllo(item) {
      // Se non ci sono allegati stampo una snackbar di errore.
      if (item.check_allegato == 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Non ci sono allegati per questo Work Order";
        this.snackbar_text_color = "white";
      } else {
        // Se invece gli allegati ci sono, faccio la chiamata per averli.
        this.allegato_controllo_visible = true;
        this.work_order_sel = Object.assign({}, item);
      }
    },
    clickVisualizzaDisegno(item) {
      if (item.view_disegno == 0) {
        /* Se il disegno non è ancora stato visualizzato mando
         * una chiamata al BE e apro il dialog. */
        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setviewdisegno",
            token: localStorage.getItem("user_token"),
            id_disegno: item.id_disegno,
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          if (res.status === 200) {
            this.visualizza_disegno_visible = true;
            this.work_order_sel = Object.assign({}, item);
          } else {
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_icon = "mdi-close";
            this.snackbar_icon_color = "white";
            this.snackbar_text = `Non è stato possibile caricare i dati: codice errore ${res.status}.`;
            this.snackbar_text_color = "white";
          }
        });
      } else {
        /* Se il disegno è già stato visualizzato apro
         * il dialog semplicementa, senza chiamare il BE. */
        this.visualizza_disegno_visible = true;
        this.work_order_sel = Object.assign({}, item);
      }
    },
    getWorkOrders() {
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkorders",
          token: localStorage.getItem("user_token"),
          cod_commessa: this.cod_commessa,
          filtroEM: this.filtroEM,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Svuoto l'array che contiene le commesse in gestione
          this.work_orders_totali = [];

          // Popolo l'array che contiene i dati totali
          res.data.forEach((elem) => {
            this.work_orders_totali.push(elem);
          });

          // Apro il dialog delle fasi avviabili.
          this.consuntivo_commesse_visible = true;
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile caricare i dati, codice errore: ${res.status}.`;
          this.snackbar_text_color = "white";
        }

        // Fermo l'overlay di caricamento.
        this.caricamento_dati = false;
      });
    },
    getListaMacchine() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistamacchine",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          res.data.forEach((m) => {
            if (m.multi_commessa == 1) {
              delete m.macchina_img;
              this.items_macchine_multi.push(m);
            }
          });
        }
      });
    },
    getWorkOrdersUtente() {
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersutente",
          token: localStorage.getItem("user_token"),
          cod_commessa: this.filtroEM === "1" ? this.commessa.cod_commessa : "",
        },
      };

      return this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Svuoto l'array che contiene le commesse in gestione
          this.work_orders_in_corso = [];

          // Popolo l'array che contiene le fasi in gestione
          res.data.workorders.forEach((elem) => {
            /* Se ce l'ha, formatto la Produzione reale della fase
             * per convenienza di FE. */
            if (elem.pzhreale != null) {
              elem.pzhreale = elem.pzhreale.toFixed(2);
            }

            /* Se ce l'ha, formatto il tempo ciclo della fase
             * per convenienza di FE. */
            if (elem.tempo_ciclo != null) {
              elem.tempo_ciclo = elem.tempo_ciclo.toFixed(2);
            }

            this.work_orders_in_corso.push(elem);
          });
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile caricare i dati, codice errore: ${res.status}.`;
          this.snackbar_text_color = "white";
        }

        this.caricamento_dati = false;
      });
    },
    handleAddMultiRegistrazione() {
      this.macchine_multi_visible = true;
    },
    handleClickIndietro() {
      this.$emit("click_indietro");
    },
    handleSelezionataMacchina(arg_id) {
      /* Filtro fra i work order in corso solo quelli associati alla macchina selezionata
       * e gli aggiungo un campo sel per lavorare con la checkbox nel dialog che aprirò */
      this.items_wo_multi = this.work_orders_in_corso.filter(
        (w) => w.id_macchina == arg_id
      );

      this.items_wo_multi.forEach((w) => (w.sel = false));

      // Chiudo il dialog di selezione della macchina per la multicommessa
      this.macchine_multi_visible = false;

      // Rendo visibile il dialog di selezione dei workorder per la multiregistrazione
      this.consuntivo_wo_multi_visible = true;
    },
    handleSelezionatiWO(arg_arr) {
      // Chiudo il dialog di selezione.
      this.consuntivo_wo_multi_visible = false;

      // Registro i WO che mi sono stati inviati
      this.items_wo_multi_reg_pezzi = new Array();
      arg_arr.forEach((w) => this.items_wo_multi_reg_pezzi.push(w));

      // Apro il dialog di registrazione multipla.
      this.key_registra_pezzi_multi =
        this.key_registra_pezzi_multi === 0 ? 1 : 0;
      this.registra_pezzi_multi_visible = true;
    },
    nascondiWorkOrder(item) {
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "hideworkorder",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: item.id_commessa_dettaglio,
        },
      };

      //console.log(JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.getWorkOrdersUtente();
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non è stato possibile caricare i dati, codice errore: ${res.status}.`;
          this.snackbar_text_color = "white";
        }
        this.caricamento_dati = false;
      });
    },
    redirect() {
      this.$router.push("/home/production/controllo");
    },
    snackbarOpen() {
      // Funzione lanciata per segnalare allegati che non hanno la descrizione.
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    validaDisegno(arg) {
      // Chiudo il dialog di visualizzazione dei disegni.
      this.visualizza_disegno_visible = false;

      /* Metto a 1 la visione del disegno per tutti
       * gli oggetti che hanno questo id_ disegno. */
      this.work_orders_in_corso.forEach((elem) => {
        if (elem.id_disegno == arg.id_disegno) {
          elem.view_disegno = 1;
        }
      });
    },
    handleWorkOrderCompletato() {
      // Funzione lanciata per segnalare allegati che non hanno la descrizione.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Work Order Completato!";
      this.snackbar_text_color = "white";
    },
  },
  mounted() {
    document
      .getElementById("listener_Produzione2")
      .addEventListener("clicked_row", this.aggiungiWorkOrder);
  },
};
</script>
<style scoped>
</style>