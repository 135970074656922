<template>
  <div>
    <v-card>
      <v-card-text style="overflow-y: scroll" id="listener_Produzione2">
        <!-- Tabella della fase in lavorazione -->
        <v-data-table
          class="elevation-2"
          :headers="headers_avanzamento"
          hide-default-footer
          :items="items_avanzamento"
          v-if="items_avanzamento.length > 0"
        >
          <!-- Barra del titolo -->
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text">
                Avanzamento Work Order
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <!-- Bottone per tornare indietro -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="clickIndietro" v-bind="attrs" v-on="on">
                    <v-icon color="error" large>
                      mdi-keyboard-backspace
                    </v-icon>
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <!-- Header cod. commessa -->
          <template v-slot:[`header.cod_commessa`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header cod. articolo -->
          <template v-slot:[`header.cod_articolo`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header operatore -->
          <template v-slot:[`header.nominativo`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header des. fase -->
          <template v-slot:[`header.des_fase`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header q.tà commessa -->
          <template v-slot:[`header.quantita`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header q.tà prodotta -->
          <template v-slot:[`header.pezzi_registrati`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header ora start -->
          <template v-slot:[`header.ora_start`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Header ora stop -->
          <template v-slot:[`header.ora_stop`]="{ header }">
            <span style="font-size: 18px; font-weight: normal">{{
              header.text
            }}</span>
          </template>

          <!-- Valore cod. commessa -->
          <template v-slot:[`item.cod_commessa`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.cod_commessa
            }}</span>
          </template>

          <!-- Valore cod. articolo -->
          <template v-slot:[`item.cod_articolo`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.cod_articolo
            }}</span>
          </template>

          <!-- Valore des. fase -->
          <template v-slot:[`item.des_fase`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.des_fase
            }}</span>
          </template>

          <!-- Valore des. fase -->
          <template v-slot:[`item.nominativo`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.nominativo
            }}</span>
          </template>

          <!-- Valore q.tà commessa -->
          <template v-slot:[`item.quantita`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.quantita
            }}</span>
          </template>

          <!-- Valore q.tà prodotta -->
          <template v-slot:[`item.pezzi_registrati`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.pezzi_registrati
            }}</span>
          </template>

          <!-- Valore ora start -->
          <template v-slot:[`item.ora_start`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.ora_start != null ? item.ora_start.substring(0, 16) : ""
            }}</span>
          </template>

          <!-- Valore ora stop -->
          <template v-slot:[`item.ora_stop`]="{ item }">
            <span style="font-size: 18px; font-weight: normal">{{
              item.ora_stop != null ? item.ora_stop.substring(0, 16) : ""
            }}</span>
          </template>
        </v-data-table>

        <!-- Card per avvisare che il carrello è vuoto -->
        <v-card
          v-if="items_avanzamento.length === 0"
          style="border-radius: 0px"
        >
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              Avanzamento Work Order
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- Bottone per tornare indietro -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clickIndietro" v-bind="attrs" v-on="on">
                  <v-icon color="error" large> mdi-keyboard-backspace </v-icon>
                </v-btn>
              </template>
              <span>Indietro</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-5">
            <span style="font-size: 20px"
              >Non ci sono Work Order in avanzamento: cliccare sul pulsante di
              aggiunta per iniziare la procedura</span
            >
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <!-- Bottone di aggiunta Work Order -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="clickAggiungiFase"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary" large> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Aggiungi Fase di Lavorazione</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <v-divider class="my-5"></v-divider>

        <!-- Spazio dei bottoni -->
        <v-card class="elevation-2">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="3">
                <!-- Bottone di montaggio bobina -->
                <div class="mb-3">
                  <v-chip outlined> Montaggio Bobina </v-chip>
                </div>
                <div>
                  <v-btn
                    @click="clickMontaggioBobina"
                    :disabled="items_avanzamento.length < 1 || controllo_needed === 1"
                    height="90px"
                    width="90px"
                    tile
                  >
                    <v-icon color="primary" x-large> mdi-cog </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="3">
                <!-- Bottone di cambio bobina -->
                <div class="mb-3">
                  <v-chip outlined> Cambio Bobina </v-chip>
                </div>
                <v-btn
                  @click="clickCambioBobina"
                  :disabled="items_avanzamento.length < 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-cog-clockwise </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <!-- Bottone di stop -->
                <div class="mb-3">
                  <v-chip outlined>Start / Stop Avanzamento </v-chip>
                </div>
                <v-btn
                  @click="clickStartStop"
                  :disabled="items_avanzamento.length < 1 || controllo_needed === 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large>
                    {{
                      ora_start === null ||
                      (ora_start != null && ora_stop != null)
                        ? "mdi-play"
                        : "mdi-stop"
                    }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <!-- Bottone di subentro -->
                <div class="mb-3">
                  <v-chip outlined> Esegui Subentro </v-chip>
                </div>
                <v-btn
                  @click="clickSubentro"
                  :disabled="items_avanzamento.length < 1 || controllo_needed === 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-account-convert </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="3">
                <!-- Bottone di termina produzione -->
                <div class="mb-3">
                  <v-chip outlined> Termina Produzione </v-chip>
                </div>
                <v-btn
                  @click="clickTerminaProduzione"
                  :disabled="items_avanzamento.length < 1 || controllo_needed === 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-power </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <!-- Bottone della registrazione avanzamento -->
                <div class="mb-3">
                  <v-chip outlined> Registrazione Manuale </v-chip>
                </div>
                <v-btn
                  @click="clickRegistraAvanzamento"
                  :disabled="items_avanzamento.length < 1 || controllo_needed === 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-package-variant </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <!-- Bottone del controllo di qualità -->
                <div class="mb-3">
                  <v-chip outlined> Controllo Qualità </v-chip>
                </div>
                <v-btn
                :color="controllo_needed != 1 ? '' : 'error'"
                  :disabled="controllo_needed != 1"
                  @click="clickControlloQualita"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon :color="controllo_needed != 1 ? 'primary' : 'white'" x-large>
                    mdi-file-certificate
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="3">
                <!-- Bottone della ristampa delle etichette -->
                <div class="mb-3">
                  <v-chip outlined> Ristampa Etichette </v-chip>
                </div>
                <v-btn
                  @click="clickRistampaEtichette"
                  :disabled="items_avanzamento.length < 1 || controllo_needed === 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-tag-multiple </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="3">
                <!-- Bottone di esame allegati -->
                <div class="mb-3">
                  <v-chip outlined> Esamina Allegati </v-chip>
                </div>
                <v-btn
                  @click="clickAllegati"
                  :disabled="items_avanzamento.length < 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <!-- Bottone di esame specifiche -->
                <div class="mb-3">
                  <v-chip outlined> Esamina Specifiche </v-chip>
                </div>
                <v-btn
                  @click="clickSpecifiche"
                  :disabled="items_avanzamento.length < 1"
                  height="90px"
                  width="90px"
                  tile
                >
                  <v-icon color="primary" x-large> mdi-script-text </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- Dialog del montaggio bobina -->
    <v-dialog persistent v-model="montaggio_bobina_visible" width="unset">
      <MontaggioBobina
        @click_chiudi="handleCloseMontBob"
        @montaggio_avvenuto="handleMontAvv"
        :work_order_sel="
          items_avanzamento.length > 0 ? items_avanzamento[0] : {}
        "
      />
    </v-dialog>

    <!-- Dialog del cambio bobina -->
    <v-dialog persistent v-model="cambio_bobina_visible" width="unset">
      <CambioBobina
        @click_chiudi="handleCloseCamBob"
        @cambio_avvenuto="handleCamBob"
        :work_order_sel="
          items_avanzamento.length > 0 ? items_avanzamento[0] : {}
        "
      />
    </v-dialog>

    <!-- Dialog del subentro -->
    <v-dialog persistent v-model="subentro_visible" width="unset">
      <Subentro
        @click_chiudi="handleCloseSubentro"
        @credenziali_inviate="handleCredInv"
        :work_order_sel="
          items_avanzamento.length > 0 ? items_avanzamento[0] : {}
        "
      />
    </v-dialog>

    <!-- Dialog di ristampa delle etichette -->
    <v-dialog persistent v-model="ristampa_etichette_visible" width="unset">
      <RistampaEtichette @click_chiudi="handleCloseRistEti" />
    </v-dialog>

    <!-- Dialog di registro avanzamento -->
    <v-dialog persistent v-model="registra_avanzamento_visible">
      <RegistraAvanzamento
        @click_chiudi="handleCloseRegAvanzamento"
        @registra_avanzamento="handleRegAv"
        :visible="registra_avanzamento_visible"
        :work_order_sel="
          this.items_avanzamento.length > 0 ? items_avanzamento[0] : {}
        "
      />
    </v-dialog>

    <!-- Dialog di termina produzione -->
    <v-dialog persistent v-model="termina_produzione_visible" width="unset">
      <TerminaProduzione
        @click_chiudi="handleCloseTermProd"
        @terminata_produzione="handleTermProd"
        :work_order_sel="
          this.items_avanzamento.length > 0 ? items_avanzamento[0] : {}
        "
      />
    </v-dialog>

    <!-- Dialog di controllo qualità -->
    <v-dialog persistent v-model="controllo_qualita_visible">
      <ControlloQualita
        @click_chiudi="handleCloseContQual"
        @eseguito_controllo="handleRegInv"
        :work_order_sel="
          items_avanzamento.length > 0 ? items_avanzamento[0] : {}
        "
        :visible="controllo_qualita_visible"
        :key="controllo_qualita_visible === true ? 1 : 0"
      />
    </v-dialog>

    <!-- Dialog del consuntivo dei work order -->
    <v-dialog persistent v-model="consuntivo_fasi_visible" width="unset">
      <ConsuntivoWorkOrder
        :filtro_cod_macchina_visible="false"
        :items_consuntivo="fasi_caricabili"
        :visible="consuntivo_fasi_visible"
        @click_chiudi="consuntivo_fasi_visible = false"
        @inviato_elemento="consuntivo_fasi_visible = false"
      />
    </v-dialog>

    <!-- Dialog per la visualizzazione delle specifiche tecniche -->
    <v-dialog persistent v-model="visualizza_disegno_visible">
      <VisualizzaDisegno
        @click_chiudi="handleCloseVisDis"
        :visible="visualizza_disegno_visible"
        :work_order_sel="
          items_avanzamento.length > 0
            ? items_avanzamento[0]
            : { id_articolo: null }
        "
      />
    </v-dialog>

    <!-- Dialog degli allegati -->
    <v-dialog v-model="gestione_allegati_visible">
      <GestioneAllegato
        ref="componenteAllegati"
        @click_chiudi="handleCloseGestAll"
        controller_allegati="Global"
        controller_caratteristiche_tabella="Manutenzione"
        :id_riferimento="
          items_avanzamento.length > 0 ? items_avanzamento[0].id_articolo : null
        "
        tipo_allegato="ARTICOLO"
        @snackbarOpen="snackbarOpen"
        :read_only="true"
      />
    </v-dialog>

    <!-- Dialog di scelta del work order in caso ci siano più avanzamenti in carrello -->
    <v-dialog persistent v-model="scegli_work_order_visible">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Selezione Work Order</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-data-table
            :items="items_avanzamento_totali"
            :headers="headers_avanzamento_totali"
          >
            <template v-slot:[`item.selezione`]="{ item }">
              <v-btn @click="scegliWorkOrder(item)">
                <v-icon color="success" large> mdi-check </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Overlay di caricamento -->
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>
<script>
import CambioBobina from "@/components/PRODUCTION/Produzione/CambioBobina.vue";
import ConsuntivoWorkOrder from "@/components/PRODUCTION/ProduzioneOnline/ConsuntivoWorkOrder.vue";
import ControlloQualita from "@/components/PRODUCTION/Produzione/ControlloQualita.vue";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import MontaggioBobina from "@/components/PRODUCTION/Produzione/MontaggioBobina.vue";
import RistampaEtichette from "@/components/PRODUCTION/Produzione/RistampaEtichette.vue";
import RegistraAvanzamento from "@/components/PRODUCTION/Produzione/RegistraAvanzamento.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import Subentro from "@/components/PRODUCTION/Produzione/Subentro.vue";
import TerminaProduzione from "@/components/PRODUCTION/Produzione/TerminaProduzione.vue";
import VisualizzaDisegno from "@/components/PRODUCTION/ProduzioneOnline/VisualizzaDisegno.vue";

export default {
  name: "CarrelloCristal",
  components: {
    CambioBobina,
    ConsuntivoWorkOrder,
    ControlloQualita,
    GestioneAllegato,
    MontaggioBobina,
    RegistraAvanzamento,
    RistampaEtichette,
    SnackbarQV,
    Subentro,
    TerminaProduzione,
    VisualizzaDisegno,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio

      // Modal
      caricamento_dati: false,
      cambio_bobina_visible: false,
      consuntivo_fasi_visible: false,
      controllo_qualita_visible: false,
      gestione_allegati_visible: false,
      montaggio_bobina_visible: false,
      registra_avanzamento_visible: false,
      ristampa_etichette_visible: false,
      scegli_work_order_visible: false,
      subentro_visible: false,
      termina_produzione_visible: false,
      visualizza_disegno_visible: false,

      // Array
      expanded_fasi: new Array(),
      fasi_caricabili: new Array(),
      headers_avanzamento: [
        { text: "Cod. Commessa", value: "cod_commessa", sortable: false },
        { text: "Cod. Articolo", value: "cod_articolo", sortable: false },
        { text: "Des. Fase", value: "des_fase", sortable: false },
        { text: "Operatore", value: "nominativo", sortable: false },
        { text: "Q.tà Commessa", value: "quantita", sortable: false },
        { text: "Q.tà Prodotta", value: "pezzi_registrati", sortable: false },
        { text: "Ora Start", value: "ora_start", sortable: false },
        { text: "Ora Stop", value: "ora_stop", sortable: false },
      ],
      headers_avanzamento_totali: [
        { text: "", value: "selezione", sortable: false },
        { text: "Cod. Commessa", value: "cod_commessa", sortable: false },
        { text: "Cod. Articolo", value: "cod_articolo", sortable: false },
        { text: "Operatore", value: "nominativo", sortable: false },
        { text: "Q.tà Commessa", value: "quantita", sortable: false },
        { text: "Q.tà Prodotta", value: "pezzi_registrati", sortable: false },
        { text: "Ora Start", value: "ora_start", sortable: false },
        { text: "Ora Stop", value: "ora_stop", sortable: false },
      ],
      items_avanzamento: new Array(),
      items_avanzamento_totali: new Array(),
    };
  },
  computed: {
    controllo_needed() {
      if (this.items_avanzamento.length > 0) {
        return this.items_avanzamento[0].controllo;
      } else {
        return 0;
      }
    },
    ora_start() {
      if (this.items_avanzamento.length > 0) {
        return this.items_avanzamento[0].ora_start;
      } else {
        return null;
      }
    },
    ora_stop() {
      if (this.items_avanzamento.length > 0) {
        return this.items_avanzamento[0].ora_stop;
      } else {
        return null;
      }
    },
  },
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    aggiungiFase(messaggio) {
      // Controllo che la commessa non sia già nel carrello.
      let ricorre = false;

      this.items_avanzamento.forEach((elem) => {
        if (
          elem.id_commessa_dettaglio ==
          messaggio.detail.work_order_sel.id_commessa_dettaglio
        ) {
          ricorre = true;
        }
      });

      // Stampo errore se la fase è già nel carrello.
      if (ricorre == true) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Fase di lavorazione già presente nel carrello."
        );
      } else {
        /* Se è tutto ok aggiungo la commessa al carrello e apro
         * la finestra di evasione della commessa.*/
        let WO_da_aggiungere = Object.assign(
          {},
          messaggio.detail.work_order_sel
        );

        this.items_avanzamento.push(WO_da_aggiungere);
      }
    },
    checkExpandFasi() {
      if (this.items_avanzamento.length > 0) {
        this.items_avanzamento.forEach((e) => {
          this.expanded_fasi.push(Object.assign({}, e));
        });
      }
    },
    async clickAggiungiFase() {
      await this.getWorkOrders();
      this.consuntivo_fasi_visible = true;
    },
    clickAllegati() {
      this.gestione_allegati_visible = true;
    },
    clickCambioBobina() {
      this.cambio_bobina_visible = true;
    },
    clickControlloQualita() {
      this.controllo_qualita_visible = true;
    },
    clickIndietro() {
      this.$router.push("/home");
    },
    clickStartStop() {
      // if (
      //   this.items_avanzamento[0].ora_start == null ||
      //   (this.items_avanzamento[0].ora_start != null &&
      //     this.items_avanzamento[0]._stop != null)
      // ) {
      //   // Se ho iniziato a lavorare, stabilisco che ora è
      //   let adesso = moment();
      //   let orario_attivazione =
      //     adesso.date() +
      //     "/" +
      //     adesso.month() +
      //     "/" +
      //     adesso.year() +
      //     " " +
      //     ("0" + adesso.hour()).substr(-2) +
      //     ":" +
      //     ("0" + adesso.minutes()).substr(-2);

      //   // Mando al backend l'aggiornamento
      //   this.setCommessaTempi(this.items_avanzamento[0], "1");
      // } else {
      // Se ho finito di lavorare, stabilisco che ora è

      // Mando al backend l'aggiornamento
      this.setCommessaTempi(
        this.items_avanzamento[0],
        this.ora_start === null ||
          (this.ora_start != null && this.ora_stop != null)
          ? "1"
          : "0"
      );
      // }
    },
    clickMontaggioBobina() {
      this.montaggio_bobina_visible = true;
    },
    clickRegistraAvanzamento() {
      this.registra_avanzamento_visible = true;
    },
    handleRegInv() {
      this.controllo_qualita_visible = false;
      this.getworkordersmacchina();
      this.$refs.SnackbarQV.print("success", "Controllo eseguito!");
    },
    clickRistampaEtichette() {
      this.ristampa_etichette_visible = true;
    },
    clickSpecifiche() {
      this.visualizza_disegno_visible = true;
    },
    clickStop() {},
    clickSubentro() {
      this.subentro_visible = true;
    },
    clickTerminaProduzione() {
      this.termina_produzione_visible = true;
    },
    // getControlli() {
    //   // Se ho un work order nel carrello..
    //   if (this.items_avanzamento.length > 0) {
    //     /* ... E se questo work order è iniziato, chiedo al BE
    //      * se ci sono dei controlli a cui devo sottoporlo. */
    //     if (
    //       this.items_avanzamento[0].ora_start != null &&
    //       this.items_avanzamento[0].ora_start != undefined &&
    //       this.items_avanzamento[0].ora_start != ""
    //     ) {
    //       let request = {
    //         controller: "Magazzino",
    //         method: "POST",
    //         richiesta: {
    //           action: "getcontrollineeded",
    //           token: localStorage.getItem("user_token"),
    //           id_commessa_dettaglio: this.items_avanzamento[0]
    //             .id_commessa_dettaglio,
    //         },
    //       };

    //       return this.$store
    //         .dispatch("api", request)
    //         .then((res) => {
    //           if (res.status === 200) {
    //             // Registro se è necessario o meno un controllo qualità
    //             this.controllo_needed = res.data;
    //           } else {
    //             this.$refs.SnackbarQV.print(
    //               "error",
    //               `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
    //             );
    //           }

    //           /* Disabilito il pulsante dei controlli
    //            * perché non so più se ci sono da fare
    //            * o meno dei controlli. */
    //           this.controllo_needed = 0;

    //           // Fermo l'overlay di caricamento.
    //           this.caricamento_dati = false;
    //         })
    //         .catch((err) => {
    //           /* Disabilito il pulsante dei controlli
    //            * perché non so più se ci sono da fare
    //            * o meno dei controlli. */
    //           this.controllo_needed = 0;

    //           // Stampo una snackbar di errore
    //           this.$refs.SnackbarQV.print(
    //             "orange",
    //             `${JSON.parse(err.response.data.message).result}.`
    //           );

    //           // Fermo l'overlay di caricamento.
    //           this.caricamento_dati = false;
    //         });
    //     }
    //   }
    // },
    getWorkOrders() {
      // Attivo l'overlay di caricamento
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkorders",
          token: localStorage.getItem("user_token"),
          id_macchina: 65,
          cod_commessa: null,
          filtroEM: "",
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            // Svuoto l'array che contiene le fasi caricabili
            this.fasi_caricabili = [];

            // Popolo l'array che contiene le fasi caricabili
            this.fasi_caricabili = Array.from(res.data);

            // Fermo l'overlay di caricamento.
            this.caricamento_dati = false;
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
            );
          }

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        });
    },
    getworkordersmacchina() {
      // Ricarico i dati dal BE
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersmacchina",
          token: localStorage.getItem("user_token"),
          id_macchina: 65,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            // Chiudo l'overlay di caricamento
            this.caricamento_dati = false;

            // Svuoto l'array che contiene le commesse avanzate
            this.items_avanzamento_totali = [];

            // Se il BE mi restituisce più di un WO in avanzamento...
            if (res.data.workorders.length > 1) {
              // ... Ma ho già caricato qualcosa in carrello...
              if (this.items_avanzamento.length === 1) {
                // Aggiorno il WO che è già in tabella.
                res.data.workorders.forEach((elem) => {
                  if (
                    elem.id_commessa_dettaglio ===
                    this.items_avanzamento[0].id_commessa_dettaglio
                  ) {
                    this.items_avanzamento = [];
                    this.items_avanzamento.push(Object.assign({}, elem));
                  }
                });
              } else {
                /* Se ci sono più WO avanzati ma non avevo ancora nulla
                 * nel carrello, apro il dialog di scelta e faccio
                 * scegliere all'utente cosa caricare dentro al carrello. */
                this.items_avanzamento_totali = Array.from(res.data.workorders);
                this.scegli_work_order_visible = true;
              }
            } else if (res.data.workorders.length === 1) {
              /* Se invece c'è solo un WO avanzato, lo uso per
               * popolare l'array che contiene il WO in gestione */
              this.items_avanzamento = Array.from(res.data.workorders);
              this.items_avanzamento_totali = Array.from(res.data.workorders);
            } else {
              // Se invece non ci sono WO, lo dico.
              this.$refs.SnackbarQV.print(
                "orange",
                `Non ci sono work order in avanzamento.`
              );
            }
          } else {
            // Stampo una snackbar di errore
            this.$refs.SnackbarQV.print(
              "orange",
              `Errore nella comunicazione col server, codice ${res.status}.`
            );

            // Chiudo l'overlay di caricamento
            this.caricamento_dati = false;
          }
        })
        .catch(() => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "error",
            `Errore critico nello scambio di informazioni col server.`
          );

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        });

      /* Modifico il valore di un contatore in modo
       * da determinare un aggiornamento dei dati della
       * tabella Tempi di Lavorazione. */
      this.contatore_tempi_di_lavorazione =
        this.contatore_tempi_di_lavorazione === 0 ? 1 : 0;
    },
    handleCamBob(arg) {
      this.cambio_bobina_visible = false;
      this.getworkordersmacchina();
      this.$refs.SnackbarQV.print("success",`Registrato cambio bobina: montata bobina ${arg}.`);
    },
    handleCloseCamBob() {
      this.cambio_bobina_visible = false;
    },
    handleCloseGestAll() {
      this.gestione_allegati_visible = false;
    },
    handleCloseMontBob() {
      this.montaggio_bobina_visible = false;
    },
    handleCloseTermProd() {
      this.termina_produzione_visible = false;
    },
    handleCloseRegAvanzamento() {
      this.registra_avanzamento_visible = false;
    },
    handleCloseRistEti() {
      this.ristampa_etichette_visible = false;
    },
    handleCloseSubentro() {
      this.subentro_visible = false;
    },
    handleCloseVisDis() {
      this.visualizza_disegno_visible = false;
    },
    handleCloseContQual() {
      this.controllo_qualita_visible = false;
    },
    handleCredInv() {
      this.subentro_visible = false;
      this.getworkordersmacchina();
    },
    handleMontAvv(arg) {
      this.montaggio_bobina_visible = false;
      this.getworkordersmacchina();
      this.$refs.SnackbarQV.print("success",`Montaggio bobina ${arg} registrato con successo.`);
    },
    handleRegAv() {
      this.registra_avanzamento_visible = false;
      this.$refs.SnackbarQV.print(
        "success",
        `Avanzamento del Work Order registrato con successo!`
      );
      this.getworkordersmacchina();
    },
    handleTermProd() {
      this.termina_produzione_visible = false;
      this.getworkordersmacchina();
    },
    scegliWorkOrder(arg) {
      // Metto l'oggetto selezionato dentro gli items_avanzamento
      this.items_avanzamento = [];
      this.items_avanzamento.push(Object.assign({}, arg));

      // Chiudo il dialog di selezione
      this.scegli_work_order_visible = false;
    },
    setCommessaTempi(commessa, start_stop) {
      // Compongo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessatempi",
          token: localStorage.getItem("user_token"),
          id_commessa: commessa.id_commessa,
          id_commessa_dettaglio: commessa.id_commessa_dettaglio,
          id_macchina: commessa.id_macchina,
          id_movimento: commessa.id_commessa_tempi,
          start: start_stop,
        },
      };
      // Consegno la request e gestisco la response
      this.$store.dispatch("api", request).then(() => {
        this.getworkordersmacchina();
      });
    },
    snackbarOpen() {
      this.$refs.SnackbarQV.print(
        "orange",
        `Aggiungere ad ogni Allegato dell'attività una descrizione.`
      );
    },
  },
  created() {},
  mounted() {
    document
      .getElementById("listener_Produzione2")
      .addEventListener("clicked_row", this.aggiungiFase);

    this.getworkordersmacchina();

    this.intervallo = setInterval(() => {
      this.getworkordersmacchina();
    }, 120000);
  },
  beforeDestroy() {
    clearTimeout(this.intervallo);
    console.log("entrato beforedestroy");
  },
};
</script>
<style scoped>
</style>