<template>
  <div id="graph_contextmenu" class="graph_card">
    <div v-for="(item,i) in items" :key="i" @click="runMethod(item.ref);menu.style.display='none'"> 
      <GraphSVG :name="item.icon" />
      <span>{{item.text}}</span>
    </div>   
  </div>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue"
export default {
  name: "CONTEXTMENU",
  components : {GraphSVG},
  data() {
    return {
      menu: null,
      items: [],
    };
  },
  mounted() {
    this.menu = document.getElementById("graph_contextmenu");
    document.body.addEventListener("contextmenu", this.context_menu_event);
    document.body.addEventListener("mousedown", this.mouse_down_event);
  },
  beforeDestroy(){
    document.body.removeEventListener("contextmenu", this.context_menu_event);
    document.body.removeEventListener("mousedown", this.mouse_down_event);
  },
  destroyed(){ window.freeComponent(this); },
  methods: {
    context_menu_event(event){
      event.preventDefault();
      this.menu.style.display = 'block';

      let top = event.clientY ;
      if (top + this.menu.offsetHeight >= window.innerHeight && top > this.menu.offsetHeight){top -= this.menu.offsetHeight;}
      this.menu.style.top = top + "px";

      let left = event.clientX;
      if (left + this.menu.offsetWidth >= window.innerWidth &&left > this.menu.offsetWidth) {left -= this.menu.offsetWidth;}
      this.menu.style.left = left + "px";

      let path = event.composedPath();

      for (let i = 0; i < path.length; i++) {
        let element = path[i];
        if(element.tagName.toLowerCase() == 'body'){break;}
        let menudata = element.getElementsByClassName('graph_contextmenu');
        if(menudata!=null&&menudata!=undefined){menudata=menudata[0];}
        if(menudata==null||menudata==undefined){continue;}
        if(menudata.children.length<=0){continue;}else{menudata=menudata.children;}
        this.items = [];
        let pushed = 0;
        for(let i=0;i<menudata.length;i++){
          if(menudata[i].getAttribute('disabled')!=null){continue;}
          pushed++;
          this.items.push({
              text: menudata[i].value,
              icon: menudata[i].innerText,
              ref : menudata[i]
          });
        }              
        if(pushed>0){return;}
      }
      this.menu.style.display = 'none';
    
    },
    runMethod(element){element.dispatchEvent(new Event('contextmenu'));},
    mouse_down_event(event){
      let path = event.composedPath();
      let close = true;
      for(let i=0;i<path.length;i++){
        if(path[i].id == "graph_contextmenu"){close = false;break;}
      }
      if(close){this.menu.style.display = "none";}
    },
  },
};
</script>
<style scoped src="./ContextMenu.css"></style>