<template>
  <div class="gestione_profili" style="margin: 20px">
    <v-card>
      <v-toolbar dense color="primary" dark align="center">
        <v-row>
          <v-col cols="5" align="left">
          </v-col>
          <v-col cols="1">
            <v-btn small light @click="setProfili">
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn small light @click="mostraDialog(null)">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" align="right" @click="getProfili">

          </v-col>
          <v-col cols="1" align="right">
            <v-btn small light>
              <v-icon color="primary">mdi-autorenew</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container>
        <v-data-table :headers="tabella.testata" :items="tabella.dati">
          <!-- BOTTONE MODIFICA -->
          <template v-slot:[`item.modifica_btn`]="{ item }">
            <v-btn
              small
              :disabled="item.validita != 1"
              @click="mostraDialog(item)"
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- BOTTONE VALIDITA -->
          <template v-slot:[`item.validita_btn`]="{ item }">
            <v-btn small @click="toggleValidita(item)">
              <v-icon :color="item.validita == 1 ? 'error' : 'primary'">{{
                item.validita == 1 ? "mdi-trash-can-outline" : "mdi-restore"
              }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <v-dialog v-model="dialog.mostra" persistent width="900">
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-row align="center" dark>
            <v-col cols="5" align="left">
              <v-toolbar-title>Modifica Profilo</v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-btn small light @click="salvaDialog">
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="5" align="right">
              <v-btn small light @click="dialog.mostra = false">
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-container>
          <v-form ref="form">
            <v-row align="center">
              <v-col cols="4">
                <v-text-field
                  required
                  :rules="regole.no_null_str"
                  v-model="dialog.buffer.des_profilo_utente"
                  label="Nome"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  required
                  :rules="regole.no_null_str"
                  v-model="dialog.buffer.cod_profilo_utente"
                  label="Codice Profilo"
                />
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  required
                  v-model="dialog.buffer.approva_ticket"
                  :false-value="null"
                  :true-value="1"
                  label="Approvatore Ticket"
                />
              </v-col>
            </v-row>
            <v-divider />
            <div>
              <v-card-subtitle class="text-left px-0"
                >Autorizzazioni Amministrative</v-card-subtitle
              >
              <v-row v-if="$store.state.auth_level != 0">
                <v-col cols="2" v-if="$store.state.auth_level == -1">
                  <label>Aziende</label>
                  <v-simple-checkbox
                    v-bind:value="getAuth(dialog.buffer.auth_level, 0)"
                    @input="setAuth($event, 0)"
                  />
                </v-col>
                <v-col cols="2" v-if="$store.state.auth_level == -1">
                  <label>Moduli</label>
                  <v-simple-checkbox
                    v-bind:value="getAuth(dialog.buffer.auth_level, 1)"
                    @input="setAuth($event, 1)"
                  />
                </v-col>
                <v-col cols="2" v-if="$store.state.auth_level == -1">
                  <label>Moduli Aziende</label>
                  <v-simple-checkbox
                    v-bind:value="getAuth(dialog.buffer.auth_level, 2)"
                    @input="setAuth($event, 2)"
                  />
                </v-col>
                <v-col cols="2">
                  <label>Profili</label>
                  <v-simple-checkbox
                    v-bind:value="getAuth(dialog.buffer.auth_level, 3)"
                    @input="setAuth($event, 3)"
                  />
                </v-col>
                <v-col cols="2">
                  <label>Utenti</label>
                  <v-simple-checkbox
                    v-bind:value="getAuth(dialog.buffer.auth_level, 4)"
                    @input="setAuth($event, 4)"
                  />
                </v-col>
                <v-col cols="2">
                  <label>Permessi</label>
                  <v-simple-checkbox
                    v-bind:value="getAuth(dialog.buffer.auth_level, 5)"
                    @input="setAuth($event, 5)"
                  />
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :disabled="Number(dialog.buffer.id_profilo_utente) <= 0"
            @click="mostraModuli"
          >
            <v-icon class="mr-5">mdi-file-tree</v-icon>
            <span>Gestione Moduli</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.mostra_moduli" width="900" persistent>
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-row align="center" dark>
            <v-col cols="5" align="left">
              <v-toolbar-title>Gestione Accesso Moduli</v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-btn small light @click="setModuliProfilo">
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="5" align="right">
              <v-btn small light @click="dialog.mostra_moduli = false">
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-container>
          <main id="tree_view">
            <section
              v-for="(modulo, i) in moduli"
              :key="modulo.cod_modulo"
              class="tree_section"
            >
              <div>
                <img
                  :src="modulo.img_modulo"
                  v-if="modulo.img_modulo != null"
                />
                <v-icon data-lazy v-if="modulo.img_modulo == null"
                  >mdi-image-off</v-icon
                >
                <v-checkbox
                  class="tree_checkbox"
                  v-model="modulo.checked"
                ></v-checkbox>
                <h3>{{ modulo.des_modulo }}</h3>
                <v-btn
                  icon
                  data-move
                  v-if="i > 0"
                  @click="move(true, i)"
                  max-height="30"
                  max-width="30"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  icon
                  data-move
                  v-if="i < moduli.length - 1"
                  @click="move(false, i)"
                  max-height="30"
                  max-width="30"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </div>
              <section class="tree_section">
                <div
                  style="height: 3px"
                  :id="'tree_section_' + i + '_-1'"
                ></div>
                <div
                  v-for="(modulo_menu, j) in modulo.moduli_menu"
                  :key="modulo_menu.path"
                  :id="'tree_section_' + i + '_' + j"
                >
                  <v-icon data-lazy>{{ modulo_menu.icon }}</v-icon>
                  <v-checkbox
                    class="tree_checkbox"
                    v-model="modulo_menu.checked"
                  ></v-checkbox>
                  <h4 @mousedown="dragStart(i, j)">{{ modulo_menu.text }}</h4>
                </div>
              </section>
            </section>
          </main>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      style="position: fixed; bottom: 10px"
      dark
      :color="alert.error == true ? 'error' : 'light-green darken-2'"
      v-model="alert.show"
    >
      {{ alert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="alert.show = false">Chiudi</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "GestioneProfili",
  data() {
    return {
      alert: {
        show: false,
        message: null,
        error: false,
      },
      moduli: [],
      NETWORK: null,
      profili: [],

      dialog: {
        buffer: {
          id_profilo_utente: null,
          cod_profilo_utente: null,
          des_profilo_utente: null,
          id_azienda: null,
          validita: 1,
          auth_level: 0,
          approva_ticket: 0,
        },
        indice: -1,
        key: 0,
        mostra: false,
        mostra_moduli: false,
      },
      regole: {
        no_null_str: [
          (valore) =>
            (valore != null && valore != undefined
              ? valore.toString().length > 0
              : false) || "Campo obbligatorio",
        ],
        no_null_num: [
          (valore) =>
            (valore != null &&
              valore != undefined &&
              !isNaN(valore) &&
              valore > 0) ||
            "Campo obbligatorio",
        ],
      },
      tabella: {
        testata: [
          { text: null, value: "modifica_btn", sortable: false, align: "left" },
          { text: "Nome", value: "des_profilo_utente" },
          { text: "Codice Profilo", value: "cod_profilo_utente" },
          {
            text: null,
            value: "validita_btn",
            sortable: false,
            align: "right",
          },
        ],
        dati: [],
      },
    };
  },
  mounted() {
    this.NETWORK = this.$store.state.Network;
    this.getProfili();
  },
  methods: {
    setAuth(state, bit) {
      this.dialog.buffer.auth_level = Number(this.dialog.buffer.auth_level);
      if (state) {
        this.dialog.buffer.auth_level |= 1 << bit;
      } else {
        this.dialog.buffer.auth_level &= ~(1 << bit);
      }
    },
    getAuth(auth, bit) {
      return auth == null ? 0 : (auth & (1 << bit)) > 0;
    },
    getProfili() {
      this.loading = true;
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "getProfili",
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (response) {
            that.tabella.dati = response.profili;
            for (let i = 0; i < that.tabella.dati.length; i++) {
              const item = that.tabella.dati[i];
              item.key = i;
              item.auth_level = new Uint32Array([item.auth_level])[0];
            }
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    mostraDialog(riga) {
      let indice = this.tabella.dati.indexOf(riga);
      this.dialog.indice = indice;
      let campi = Object.keys(this.dialog.buffer);
      for (let i = 0; i < campi.length; i++) {
        this.dialog.buffer[campi[i]] = indice < 0 ? null : riga[campi[i]];
      }
      this.dialog.mostra = true;
      let that = this;
      this.$nextTick(function () {
        that.$refs.form.resetValidation();
        that.dialog.key++;
      });
    },
    salvaDialog() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.dialog.indice < 0) {
        let clone = Object.assign({}, this.dialog.buffer);
        clone.validita = 1;
        clone.id_profilo_utente = -1;
        this.tabella.dati.push(clone);
      } else {
        let campi = Object.keys(this.dialog.buffer);
        for (let i = 0; i < campi.length; i++) {
          this.tabella.dati[this.dialog.indice][campi[i]] =
            this.dialog.buffer[campi[i]];
        }
      }
      this.dialog.mostra = false;
    },
    setProfili() {
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "setProfili",
          token: localStorage.user_token,
          profili: this.tabella.dati,
        },
        method: "PUT",
        on: {
          load: function () {
            that.getProfili();
            that.alert = {
              message: "Dati salvati con successo",
              show: true,
              error: false,
            };
          },
          error: function () {
            that.alert = {
              message: "Errore durante il salvataggio",
              show: true,
              error: true,
            };
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },

    mostraModuli() {
      this.dialog.mostra_moduli = true;
      let that = this;
      this.$nextTick(function () {
        that.getModuli();
      });
    },

    getModuli() {
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "getModuliAzienda_cliente",
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (response) {
            that.moduli = [];
            try {
              that.moduli = response.moduli;
              for (let i = 0; i < that.moduli.length; i++) {
                for (let j = 0; j < that.moduli[i].moduli_menu.length; j++) {
                  that.moduli[i].moduli_menu[j].checked = false;
                  that.moduli[i].moduli_menu[j].id_profilo_dettaglio = -1;
                  that.moduli[i].moduli_menu[j].pagina = j;
                }
                that.moduli[i].checked = false;
                that.moduli[i].id_profilo_dettaglio = -1;
                that.moduli[i].ordine = i;
              }
              that.getModuliProfilo();
            } catch (error) {
              console.log(error);
            }
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    getModuliProfilo() {
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "getModuliProfilo",
          id_profilo: this.dialog.buffer.id_profilo_utente,
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (response) {
            try {
              let moduli_profilo = response.moduli;
              for (let i = 0; i < that.moduli.length; i++) {
                for (let j = 0; j < that.moduli[i].moduli_menu.length; j++) {
                  for (let j_s = 0; j_s < moduli_profilo.length; j_s++) {
                    if (
                      moduli_profilo[j_s].id_modulo_menu ==
                      that.moduli[i].moduli_menu[j].id_modulo_menu
                    ) {
                      that.moduli[i].moduli_menu[j].checked =
                        moduli_profilo[j_s].validita == 1 ? true : false;
                      that.moduli[i].moduli_menu[j].validita =
                        moduli_profilo[j_s].validita;
                      that.moduli[i].moduli_menu[j].pagina =
                        moduli_profilo[j_s].ordine;
                      that.moduli[i].moduli_menu[j].id_profilo_dettaglio =
                        moduli_profilo[j_s].id_profilo_dettaglio;
                      that.moduli[i].checked = true;
                    }
                  }
                }
                that.moduli[i].moduli_menu.sort((a, b) => a.pagina - b.pagina);
                for (let i_s = 0; i_s < moduli_profilo.length; i_s++) {
                  if (
                    moduli_profilo[i_s].id_modulo == that.moduli[i].id_modulo &&
                    moduli_profilo[i_s].id_modulo_menu == null
                  ) {
                    that.moduli[i].checked =
                      moduli_profilo[i_s].validita == 1 ? true : false;
                    that.moduli[i].validita = moduli_profilo[i_s].validita;
                    that.moduli[i].ordine = moduli_profilo[i_s].ordine;
                    that.moduli[i].id_profilo_dettaglio =
                      moduli_profilo[i_s].id_profilo_dettaglio;
                  }
                }
              }
              that.moduli.sort((a, b) => a.ordine - b.ordine);
              that.loading = false;
            } catch (error) {
              console.log(error);
            }
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    setModuliProfilo() {
      let result = [];
      for (let i = 0; i < this.moduli.length; i++) {
        let modulo = this.moduli[i];
        let clone_modulo = {
          id_profilo_dettaglio: modulo.id_profilo_dettaglio,
          id_profilo: this.dialog.buffer.id_profilo_utente,
          id_modulo: modulo.id_modulo,
          id_modulo_menu: null,
          id_azienda: 1,
          ordine: i,
          validita: modulo.checked == true ? 1 : 0,
        };
        result.push(clone_modulo);
        for (let j = 0; j < modulo.moduli_menu.length; j++) {
          let modulo_menu = modulo.moduli_menu[j];
          let clone_modulo_menu = {
            id_profilo_dettaglio: modulo_menu.id_profilo_dettaglio,
            id_profilo: this.dialog.buffer.id_profilo_utente,
            id_modulo: modulo.id_modulo,
            id_modulo_menu: modulo_menu.id_modulo_menu,
            id_azienda: 1,
            ordine: j,
            validita: modulo_menu.checked == true ? 1 : 0,
          };
          if (modulo_menu.checked == true) {
            modulo.validita = 1;
            clone_modulo.validita = 1;
          }
          result.push(clone_modulo_menu);
        }
      }
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "setModuliProfilo",
          token: localStorage.user_token,
          moduli: result,
        },
        method: "PUT",
        on: {
          load: function () {
            that.getModuli();
            that.alert = {
              message: "Dati salvati con successo",
              show: true,
              error: false,
            };
          },
          error: function () {
            that.alert = {
              message: "Errore durante il salvataggio",
              show: true,
              error: true,
            };
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    toggleValidita(riga) {
      riga.validita = riga.validita == 0 ? 1 : 0;
    },

    dragStart(i, j) {
      let tree_container = document.getElementById("tree_view");
      document.body.addEventListener("mousemove", this.dragMove);
      document.body.addEventListener("mouseup", this.dragEnd);
      document.body.addEventListener("mouseleave", this.dragEnd);

      let original = document.getElementById("tree_section_" + i + "_" + j);
      let element = original.cloneNode(true);
      element.removeAttribute("id");
      let container = document.createElement("div");
      container.setAttribute("class", "tree_section");

      container.style.position = "absolute";
      container.appendChild(element);
      tree_container.appendChild(container);

      container.style.top =
        original.getBoundingClientRect().y -
        tree_container.getBoundingClientRect().y +
        tree_container.scrollTop -
        5 +
        "px";
      container.style.left = "27px";
      container.style.backgroundColor = "white";
      container.style.padding = "5px 12px";
      container.style.boxShadow = "0 2px 4px -1px rgb(0 0 0 / 20%)";
      container.style.opacity = "0.8";
      container.childNodes[0].style.pointerEvents = "none";

      document.body.style.cursor = "grabbing";

      this.tree_data = {
        element: container,
        container: tree_container,
        active_element: null,
        cache: Object.assign({}, this.moduli[i].moduli_menu[j]),
        position: { i: i, j: j },
      };
      this.moduli[i].moduli_menu.splice(j, 1);
    },
    dragMove(event) {
      let tree_container = this.tree_data.container;
      this.tree_data.element.style.top =
        event.clientY -
        tree_container.getBoundingClientRect().y +
        tree_container.scrollTop -
        5 +
        "px";

      let path = document.elementsFromPoint(event.clientX, event.clientY);
      for (let i = 0; i < path.length; i++) {
        let element = path[i];
        if (element.tagName.toLowerCase() == "body") {
          return;
        }
        let id = element.getAttribute("id");
        if (id == undefined || id == null) {
          continue;
        }
        if (id.indexOf("tree_section_") < 0) {
          continue;
        }
        let range = Number(id.substring(13).split("_")[0]);
        if (range != this.tree_data.position.i) {
          return;
        }
        if (this.tree_data.active_element != null) {
          this.tree_data.active_element.style.borderBottom = "none";
        }
        element.style.borderBottom = "1px solid black";
        this.tree_data.active_element = element;
      }
    },
    dragEnd() {
      let element = this.tree_data.element;
      document.body.removeEventListener("mousemove", this.dragMove);
      document.body.removeEventListener("mouseup", this.dragEnd);
      document.body.removeEventListener("mouseleave", this.dragEnd);

      document.body.style.cursor = "auto";

      this.tree_data.container.removeChild(element);
      let i, j;

      if (this.tree_data.active_element != null) {
        this.tree_data.active_element.style.borderBottom = "none";
        let id = this.tree_data.active_element
          .getAttribute("id")
          .substring(13)
          .split("_");
        i = Number(id[0]);
        j = Number(id[1]);
      } else {
        i = this.tree_data.position.i;
        j = this.tree_data.position.j;
      }
      this.moduli[i].moduli_menu.splice(j + 1, 0, this.tree_data.cache);
      delete this.tree_data;
    },

    move(up, index) {
      let clone = Object.assign({}, this.moduli[index]);
      if (up) {
        if (index == 0) {
          return;
        }
        this.moduli.splice(index, 1);
        this.moduli.splice(index - 1, 0, clone);
      } else {
        if (index == this.moduli.length - 1) {
          return;
        }
        this.moduli.splice(index, 1);
        this.moduli.splice(index + 1, 0, clone);
      }
    },
  },
};
</script>
<style scoped>
#tree_view {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 400px;
  text-align: left;
  padding: 15px;
  margin: 0 auto;
  transition: margin-left 0.5s linear;
  user-select: none;
}

#tree_view .tree_section {
  display: block;
  margin-left: 12px;
  margin-bottom: 10px;
}

#tree_view .tree_section > div {
  height: 30px;
  display: block;
  border-radius: 3px;
  padding: 0 5px;
}
#tree_view .tree_section > div:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
#tree_view .tree_section > div > * {
  display: inline-block;
  vertical-align: middle;
}
#tree_view .tree_section > div > .tree_checkbox {
  height: 30px;
  width: 30px;
  margin: 0;
}
#tree_view .tree_section > div > h3 {
  font-weight: bold;
}
#tree_view .tree_section > div > h4 {
  font-weight: normal;
  cursor: grab;
  width: 190px;
}

#tree_view .tree_section > div > *[data-lazy],
#tree_view .tree_section > div > img {
  width: 24px;
  margin: 0 12px 0 0;
}
#tree_view .tree_section > div > *[data-move] {
  float: right;
}
#tree_view .tree_section > div > input {
  outline: none !important;
  border-bottom: 1px solid grey;
  appearance: none;
  border-radius: 0;
  width: 120px;
  margin-left: 10px;
}
</style>