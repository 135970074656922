<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col md="6">
          <v-card>
            <v-card-title
              style="height: 1rem !important"
              class="blue-grey darken-3"
              primary-title
            >
              <h6 class="mt-n4 white--text">Upload Csv/Excel</h6>
            </v-card-title>

            <v-card-text class="mt-10 ml-n2">
              <v-container class="mt-n13">
                <v-layout row wrap class="mb-2">
                  <v-flex xs12 sm6 class="text-xs-center text-sm-right"> </v-flex>
                </v-layout>
                <v-layout v-if="loading">
                  <v-flex xs12 class="text-xs-center">
                    <v-progress-circular
                      indeterminate
                      class="primary--text"
                      :width="7"
                      :size="70"
                    ></v-progress-circular>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="mt-2" v-else>
                  <v-flex xs12 sm6 offset-sm3>
                    <div class="form-group">
                      <div style="float: center" class="col-sm-9" md="12">
                        <v-btn
                          raised
                          style="margin-left: 5rem"
                          class="primary"
                          @click="onPickFile"
                          >Upload CSV/Excel</v-btn
                        >
                        <input
                          type="file"
                          style="display: none"
                          ref="fileInput"
                          class="form-control"
                          accept="csvFile/*"
                          @change="loadCSV"
                        />
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout row wrap class="mt-2">
                  <v-flex xs12 md12>
                    <v-data-table
                      :headers="headers"
                      :items="contacts"
                      :items-per-page="5"
                      class="table table-striped table-hover"
                    >
                      <template slot="header">
                        <tr>
                          <th class="" v-for="header in headers" :key="header.text">
                            <input type="checkbox" @click="select(header.value)" />
                          </th>
                        </tr>
                      </template>
                      <!-- <template slot="body">
                                     <tr v-for="(item) in contacts" :key="item.city">

                                                <td v-for="header in headers" :key="header.value">{{ item[header.value] }}</td>
                                            </tr>


                                    </template>-->

                      <!--      <tr class="pa-4" v-for="i in contacts" :key="i.first_name">

                                                <td class="pa-5">{{i.first_name}}</td>
                                                <td>
                                                    {{i.last_name}}

                                                </td>
                                                <td>{{i.company_name}}</td>
                                                <td>{{i.address}}</td>
                                                <td>{{i.city}}</td>
                                            </tr>-->
                    </v-data-table>

                    <!--    <v-data-table :headers="headers" :pagination.sync="pagination" :items="contacts" hide-actions class="elevation-1">
                                        <template slot="headers" slot-scope="">
                                            <tr>

                                                <th v-for="header in headers" :key="header.text">

                                                    <v-checkbox :value="header.text"></v-checkbox>
                                                    {{header.text}}
                                                </th>
                                            </tr>
                                        </template>
                                    </v-data-table>-->

                    <!-- <table class="table table-striped">
                                        <thead class="thead-dark">
                                            <draggable v-model="headers" group="tasks" multi-drag tag="tr" :move="checkMove" :clone="cloneDog" @change="log">
                                                <th v-for="(header) in headers" :key="header.value" scope="col">
                                                    <v-checkbox :value="header.text"></v-checkbox>
                                                    {{ header.text }}

                                                </th>
                                            </draggable>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in contacts" :key="item.city">

                                                <td v-for="header in headers" :key="header.value">{{ item[header.value] }}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <rawDisplayer class="col-2" :value="contacts" title="List" />

                                    <rawDisplayer class="col-2" :value="headers" title="Headers" />-->
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card>
            <v-card-title
              style="height: 1rem !important"
              class="blue-grey darken-3"
              primary-title
            >
              <h6 class="mt-n4 white--text">Seleziona una Tabella</h6>
            </v-card-title>
            <v-card-text>
              <!--  <v-layout>
                            <v-flex>

                              <table class="table table-striped">
                                    <thead class="thead-dark">
                                        <th v-for="(header) in headers1" :key="header" scope="col">
                                            {{ header.text }}
                                        </th>

                                    </thead>
                                    <tbody>
                                        <draggable v-model="list" group="tasks" tag="tbody">
                                            <tr v-for="item in list" :key="item">
                                                <td v-for="header in headers1" :key="header">{{ item[header.value] }}</td>
                                            </tr>
                                        </draggable>
                                    </tbody>

                                </table>

                                <rawDisplayer class="col-2" :value="list" title="List" />

                                <rawDisplayer class="col-2" :value="headers1" title="Headers" />

                            </v-flex>
                        </v-layout>-->

              <v-data-table
                :items-per-page="5"
                :headers="headers1"
                :items="list[0].tabella"
                v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }"
                :key="anIncreasingNumber"
              >
                <template v-slot:[`item.selector`]="{ item }">
                  <v-checkbox
                    v-model="item.isSelected"
                    :value="item.isSelected"
                    @change="rowClick(item)"
                  >
                  </v-checkbox>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col md="11">
          <v-data-table :headers="headers2" :items="items2"> </v-data-table>
        </v-col>

        <v-spacer></v-spacer>
        <v-icon @click="SalvaTabela">mdi-floppy</v-icon>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// Add back the sortHandle class if it gets stripped away by external code
let watchClass = (targetNode, classToWatch) => {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];

      if (mutation.type === "attributes" && mutation.attributeName === "class") {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add("sortHandle");
          }
        }
      }
    }
  });
  observer.observe(targetNode, {
    attributes: true,
  });
};

// import draggable from 'vuedraggable';
// import axios from 'axios'
import Sortable from "sortablejs";
import { excel2json } from "js2excel";

export default {
  components: {
    // draggable,
  },

  data() {
    return {
      id_import_dettaglio: -1,
      id_questionario_test: this.$route.params.id,
      id_import: 55,
      campo_tipo: "",
      nr_colonna: 1,
      campo_db: "",
      anIncreasingNumber: 1,
      AnIncreasingNumber: 1,
      dragging: false,
      singleSelect: false,
      selectedRow: false,
      selectAll: false,
      selected: [],
      contacts: [],
      items2: [],
      headers2: [],
      headers: [],
      headers1: [
        {
          text: "",
          align: "start",
          value: "selector",
          sortable: false,
        },
        {
          text: "Column Name",
          align: "start",
          value: "column_name",
          sortable: false,
        },
        {
          text: "DataType",
          align: "left",
          sortable: false,
          value: "data_type",
        },
        {
          text: "PK",
          align: "left",
          sortable: false,
          value: "pk",
        },
        {
          text: "NN",
          align: "left",
          sortable: false,
          value: "nn",
        },
        {
          text: "UQ",
          align: "left",
          sortable: false,
          value: "uq",
        },
        {
          text: "B",
          align: "left",
          sortable: false,
          value: "b",
        },
        {
          text: "UN",
          align: "left",
          sortable: false,
          value: "un",
        },
        {
          text: "ZF",
          align: "left",
          sortable: false,
          value: "zf",
        },
        {
          text: "AI",
          align: "left",
          sortable: false,
          value: "ai",
        },
        {
          text: "G",
          align: "left",
          sortable: false,
          value: "g",
        },
        {
          text: "Default/Expression",
          align: "left",
          sortable: false,
          value: "default",
        },
      ],
      list: [
        {
          id: 34,
          tabella: [
            {
              column_name: "attivita_scaduta",
              isSelected: false,
              data_type: "INT(11)",
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: "null",
            },
            {
              column_name: "email_richiesta",
              isSelected: false,
              data_type: "VARCHAR(255)",
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: "null",
            },
            {
              column_name: "email_stato",
              data_type: "INT(11)",
              isSelected: false,
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: "null",
            },
            {
              column_name: "email_avviso",
              data_type: "VARCHAR(255)",
              isSelected: false,
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: "null",
            },
            {
              column_name: "email_attiva",
              data_type: "INT(11)",
              isSelected: false,
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: 0,
            },
            {
              column_name: "email_conferma",
              data_type: "INT(11)",
              isSelected: false,
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: 0,
            },
            {
              column_name: "ricorsione",
              data_type: "INT(11)",
              isSelected: false,
              pk: false,
              nn: false,
              uq: false,
              b: false,
              un: false,
              zf: false,
              ai: false,
              g: false,
              default: 0,
            },
          ],
        },
      ],

      loading: false,
      pagination: {},
      id: 1,
      newArray: [],
      objId: 1,
    };
  },
  filters: {
    capitalize(str) {
      return str != undefined ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    },
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null)
        return 0;
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
  },
  methods: {
    rowClick: function (item) {
      const newObj = {
        sortable: false,
        text: item.column_name,
        value: item.column_name,
        type: item.data_type,
      };
      this.headers2.push(newObj);

      if (this.headers2.length === 1) {
        this.selected.map((sel) => {
          for (const [value] of Object.entries(sel)) {
            const newObj = {};
            let val = item.column_name;

            newObj[val] = value;
            newObj.id = this.id;
            this.id++;

            this.items2.push(newObj);
          }
          this.selected = [];
        });
      } else if (this.headers2.length > 1) {
        if (this.headers2.length > 2) {
          if (this.headers2[0].value === "email_conferma") {
            this.array_move(this.headers2, 0, 3);
          }
          if (this.headers2[1].value === "email_conferma") {
            this.array_move(this.headers2, 1, 3);
          }
        }
        this.selected.map((sel) => {
          for (const [key, value] of Object.entries(sel)) {
            let val = item.column_name;
            const obj = {};
            obj.id = this.objId;
            obj[val] = value;

            this.newArray.push(obj);

            this.objId++;

            delete sel[key];
          }
        });
        this.objId = 1;
        this.items2 = this.ObjectsMerger(this.items2, this.newArray);
        this.newArray = [];
      }

      this.selected = [];
    },
    SalvaTabela() {
      let headerData = [];

      this.headers2.map((it) => {
        this.nr_colonna;
        var dataobj = {};
        dataobj.id_import_dettaglio = -1;
        dataobj.id_import = this.list[0].id;
        dataobj.nr_colonna = this.nr_colonna;
        dataobj.campo_db = it.text;
        dataobj.campo_tipo = it.type;
        dataobj.validita = 1;
        headerData.push(dataobj);
        this.nr_colonna++;
      });

      let setimportdettaglioReq = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setimportdettaglio",
          token: localStorage.user_token,
          id_questionario_test: this.id_questionario_test,
          tabella: headerData,
        },
      };

      this.$store.dispatch("api", setimportdettaglioReq);
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 2)[0]);
      return arr; // for testing
    },
    ObjectsMerger(ar1, ar2) {
      return ar1.map((item, i) => {
        if (item.id === ar2[i].id) {
          //merging two objects
          return Object.assign({}, item, ar2[i]);
        }
      });
    },
    select(val) {
      if (!this.selectAll) {
        this.contacts.map((it) => {
          for (const [key, value] of Object.entries(it)) {
            if (key === val) {
              let newObj = {};
              newObj[val] = value;
              this.selected.push(newObj);
            }
          }
        });
        return this.selected;
      }
    },
    // log: function (evt) {
    //     window.console.log(evt);
    // },
    cloneDog() {
      let idGlobal = 1;
      let id;
      return {
        id: idGlobal++,
        name: `cat ${id}`,
      };
    },
    checkMove: function (evt) {
      return evt.draggedContext.element;
    },
    onDrop(event) {
      this.lists.push(event.data);
    },

    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers1;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },
    SortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.AnIncreasingNumber += 1;
    },
    csvJSON(csv) {
      this.contacts = [];
      this.headers = [];
      var lines = csv.split("\n");

      var result = [];
      if (lines[0].split(",")) {
        var headers = lines[0].split(",");
      }

      headers.map((head) => {
        var newObj = {};
        newObj.text = head;
        newObj.value = head;
        this.headers.push(newObj);
      });

      lines.map((line, indexLine) => {
        if (indexLine < 1) return; // Jump header line

        const obj = {};
        var currentline = line.split(",");

        headers.map((header, indexHeader) => {
          obj[header.trim()] = currentline[indexHeader];
        });

        result.push(obj);
      });

      result.pop();

      return result; // JavaScript object
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    loadCSV(e) {
      var vm = this;
      let files = e.target.files;

      let file = files[0];

      if (window.FileReader) {
        let reader = new FileReader();

        let filevalue = file.name;

        let file_name = filevalue.split(".").pop();

        if (file_name === "xlsx" || file_name === "xls") {
          this.headers = [];

          excel2json(e.target.files, (data) => {
            this.contacts = data.Sheet1;
            let co = this.contacts[0];

            for (const [key] of Object.entries(co)) {
              let dataObj = {};
              if (co.key !== "id") {
                dataObj.text = key;
                dataObj.value = key;

                this.headers.push(dataObj);
              }
            }

            return this.contacts;
          });
        }

        // when the file is read it triggers the onload event above.
        if (file_name === "csv") {
          reader.readAsText(file, "UTF-8");
          // Handle errors load
          reader.onload = function () {
            let csv = reader.result;

            vm.contacts = vm.csvJSON(csv);
          };

          reader.onerror = function (evt) {
            if (evt.target.error.name == "NotReadableError") {
              alert("Canno't read file !");
            }
          };
        }
      } else {
        alert("FileReader are not supported in this browser.");
      }
    },
  },
  directives: {
    "sortable-table": {
      inserted: (el, binding) => {
        el.querySelectorAll("th").forEach((draggableEl) => {
          // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
          watchClass(draggableEl, "sortHandle");
          draggableEl.classList.add("sortHandle");
        });
        Sortable.create(
          el.querySelector("tr"),
          binding.value
            ? {
                ...binding.value,
                handle: ".sortHandle",
              }
            : {}
        );
      },
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
