<template>
  <div>
    <v-row class="mt-3">
      <v-spacer></v-spacer>
      <v-btn style="margin-right: 5px" dark color="orange" @click="spedisciPalletsSelezionati()"
        >spedisci pedane</v-btn
      >
      <v-btn style="margin-right: 5px" dark color="primary"
        >estrai packing list</v-btn
      >
      <v-btn dark color="primary"
        ><v-icon>mdi-format-list-bulleted-square</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-5">
         <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            ><v-toolbar-title>Pedane</v-toolbar-title></v-toolbar
          >
        <v-data-table
          :headers="headers_pedane"
          :items="lista_pedane"
          :items-per-page="5"
          v-model="pallets_selezionati"
          class="elevation-3"
          show-select
          item-key="id_lotto_pedana"
        >
          <template v-slot:item.del="{ item }">
            <v-btn  @click="eliminaPedana(item)">
              <v-icon color="red">mdi-delete &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.dettagli="{ item }">
            <v-btn @click="dettaglioPedana(item)">
              <v-icon color="primary"
                >mdi-eye
                &nbsp;&nbsp;&nbsp;&nbsp;</v-icon
              >
            </v-btn>
          </template>
          <template v-slot:item.spedisci="{ item }">
            <v-btn  @click="spedisciPedana(item)">
              <v-icon color="success">mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.etichetta="{ item }">
            <v-btn  @click="stampaPallet(item)">
              <v-icon color="primary">mdi-printer &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>



    <v-dialog v-model="dialog_dettaglio_pedana" width="900" persistent>
      <v-toolbar
        dark
        color="#3F51B5"
        style="border-radius: 5px 5px 0px 0px"
        class="elevation-0"
        
      >
        <v-spacer></v-spacer>
        <v-toolbar-title>Dettaglio Pedana</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn light @click="dialog_dettaglio_pedana = false"
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card>
        <v-data-table
          :headers="headers_dettaglio_pedane"
          :items="dettaglio_pedane"
          :items-per-page="5"
          class="elevation-3"
        >
        </v-data-table>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialog_elimina_pedana" width="600">
      <v-toolbar
        dark
        color="#3F51B5"
        style="border-radius: 5px 5px 0px 0px"
        class="elevation-0"
        
      >
        <v-spacer></v-spacer>
        <v-toolbar-title>Conferma eliminazione pedana</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn light @click="dialog_elimina_pedana = false"
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>

      <v-card>
        <v-card-text style="font-size: 18px"
          ><br>Sei sicuro di voler eliminare la pedana selezioanta?</v-card-text
        >
        <v-card-actions>
          <v-container>
            <v-row>
              <v-spacer> </v-spacer>
              <v-btn dark color="success" @click="dialog_elimina_pedana = false"
                >indietro</v-btn
              >
              <v-btn
                dark
                color="red"
                style="margin-left: 5px"
                @click="confermaEliminaPedana()"
                >conferma</v-btn
              >
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>
<script>
export default {
  data() {
    return {
      indice_pedana: null,
      dialog_elimina_pedana: false,
      id_lotto_pedana: null,
      use_imballo: null,
      pallets_selezionati: [],

      dialog_dettaglio_pedana: false,

      headers_pedane: [
        {text: "Dettagli", value: "dettagli", width: "50",},
        {text: "Spedisci",value: "spedisci",width: "50",},
        {text: "Stampa",value: "etichetta",width: "50",},
        {text: "Lotto pedana",value: "id_lotto_pedana",},
        {text: "Cod. Articolo",value: "cod_articolo",},
        {text: "Larghezza",value: "l",},
        {text: "Lunghezza",value: "p",},
        {text: "Altezza",value: "h",},
        {text: "Peso",value: "peso",},
        {text: "Qta pezzi",value: "pezzi",},
        {text: "Rimuovi",value: "del",},
      ],
  
      lista_pedane:[],

      headers_dettaglio_pedane: [
        {text: "Lotto pedana",value: "id_lotto_pedana",},
        {text: "Lotto",value: "cod_lotto",},
        {text: "Cod. Articolo",value: "cod_articolo",},
        {text: "Cod. Articolo",value: "cod_articolo2",},
        {text: "Qta Contenitore",value: "qta_contenitore",},
        {text: "Quantità",value: "quantita",},
      ],      
      dettaglio_pedane:[],

    };
  },
  props: {
    id_anagrafica: Number,
    TRIGGER: Number,
  },
  watch: {
      id_anagrafica: {
        immediate: true,
        handler() {
          if (this.id_anagrafica != null) {
            this.getPreShipping();
          }
        }
      },
    
      TRIGGER: {
        immediate: true,
        handler() {
          if (this.id_anagrafica != null) {
            this.getPreShipping();
          }
        }
      },

    },

  methods: {

        getPreShipping() {
          let request = {
            controller: "Logistica",
            method: "POST",
            richiesta: {
              action: "getpreshipping",
              token: localStorage.getItem("user_token"),
              id_utente: localStorage.getItem("id"),
              id_anagrafica: this.id_anagrafica,
              stato: 1,
              db_name: "geclink_cavallo",
            },
          };

          this.lista_pedane = []
          this.dettaglio_pedane = []
          this.$store.dispatch("api", request).then((res) => {
            this.lista_pedane = res.data.listapedane
            this.dettaglio_pedane = res.data.dettagliopedane
          });
        },
        dettaglioPedana(item){
          let array_pedane = this.alasql(
            "SELECT cod_articolo, cod_articolo1, cod_articolo2,cod_lotto,id_lotto_pedana,qta_contenitore, quantita FROM ? where id_lotto_pedana = " + item.id_lotto_pedana ,
            [this.dettaglio_pedane]
          );
          this.dettaglio_pedane = array_pedane
          this.dialog_dettaglio_pedana = true
        },
        eliminaPedana(item) {
          this.id_lotto_pedana =  item.id_lotto_pedana 
          this.use_imballo =  item.use_imballo 
          this.dialog_elimina_pedana = true;
        },
        confermaEliminaPedana() {
          let request = {
          controller: "Logistica",
          method: "PUT",
            richiesta: {
              action: "delshipping",
              token: localStorage.getItem("user_token"),
              id_utente: localStorage.getItem("id"),
              id_lotto_pedana : this.id_lotto_pedana,
              use_imballo : this.use_imballo,
              stato: 0,
              db_name: "geclink_cavallo",
            },
          };
       
          this.$store.dispatch("api", request).then((res) => {
            if (res.status == 200){
                this.getPreShipping() 
                this.dialog_elimina_pedana = false;
                this.$emit("changeTab", "packaging");
            }
          })
        },


        spedisciPedana(item){
          
          let request = {
          controller: "Logistica",
          method: "PUT",
            richiesta: {
              action: "setshipping",
              token: localStorage.getItem("user_token"),
              id_utente: localStorage.getItem("id"),
              id_lotto_pedana : item.id_lotto_pedana,
              stato: 2,
              db_name: "geclink_cavallo",
            },
          };
       
          this.$store.dispatch("api", request).then((res) => {
            if (res.status == 200){
                this.getPreShipping() 
                this.$emit("changeTab", "shipping");
            }
          })

        },


        spedisciPalletsSelezionati(){
            if(this.pallets_selezionati.length!=0)
            {
            this.$emit("changeTab", "shipping");
            } else {
            this.$store.state.snackbar.stato = true;
            this.$store.state.snackbar.messaggio =
              "Selezionare dalla tabella pallets almeno un elemento da spedire!";
            this.$store.state.snackbar.colore = "#E53935";
            this.$store.commit("close_snackbar");
          }
        },
      },
  };
</script>