<template>
  <div class="gantt" ref="gantt">
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      style="z-index: 99"
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar_step = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <header>
      <GraphSVG
        name="arrow_left"
        @click="$emit('project')"
        style="float: left"
      />
      <GraphSVG name="home" @click="$router.push('/home/')" />
    </header>
    <main>
      <section>
        <section>
          <figure @click="newtask">
            <GraphSVG name="plus" />
            <span>Aggiungi task</span>
          </figure>
          <figure @click="setProjectDetail">
            <GraphSVG name="save" />
            <span>Salva progetto</span>
          </figure>
        </section>
        <section>
          <!--figure>
                        <GraphSVG name="calendar_backwards" />
                        <span>15 giorni</span>
                    </figure>
                    <figure>
                        <GraphSVG name="rewind" />
                        <span>1 giorno</span>
                    </figure>
                    <figure>
                        <GraphSVG name="fast_forward" />
                        <span>1 giorno</span>
                    </figure>                    
                    <figure>
                        <GraphSVG name="calendar_forward" />
                        <span>15 giorni</span>
                    </figure-->
          <v-btn
            :color="selected == 0 ? 'var(--accent)' : 'lightgrey'"
            class="mr-2"
            @click="switchView(0), (selected = 0)"
            ><span
              :style="selected == 0 ? 'color:white' : 'color:var(--accent)'"
              >Ore</span
            ></v-btn
          >
          <v-btn
            :color="selected == 1 ? 'var(--accent)' : 'lightgrey'"
            class="mr-2"
            @click="switchView(1), (selected = 1)"
            ><span
              :style="selected == 1 ? 'color:white' : 'color:var(--accent)'"
              >Giorni</span
            ></v-btn
          >
          <v-btn
            :color="selected == 2 ? 'var(--accent)' : 'lightgrey'"
            class="mr-2"
            @click="switchView(2), (selected = 2)"
            ><span
              :style="selected == 2 ? 'color:white' : 'color:var(--accent)'"
              >Settimane</span
            ></v-btn
          >
          <v-btn
            :color="selected == 3 ? 'var(--accent)' : 'lightgrey'"
            class="mr-2"
            @click="switchView(3), (selected = 3)"
            ><span
              :style="selected == 3 ? 'color:white' : 'color:var(--accent)'"
              >Mesi</span
            ></v-btn
          >
          <v-btn
            :color="selected == 4 ? 'var(--accent)' : 'lightgrey'"
            class="mr-2"
            @click="switchView(4), (selected = 4)"
            ><span
              :style="selected == 4 ? 'color:white' : 'color:var(--accent)'"
              >Anni</span
            ></v-btn
          >

          <!-- <figure>
            <GraphSVG name="calendar_multiple" tabindex="1" />
            <span>Vista</span>
            <ul>
              <li @click="switchView(0)">Ore</li>
              <li @click="switchView(1)">Giorni</li>
              <li @click="switchView(2)">Settimane</li>
              <li @click="switchView(3)">Mesi</li>
              <li @click="switchView(4)">Anni</li>
            </ul>
          </figure> -->
        </section>
      </section>
      <GanttGridView>
        <!-- TASK LIST -->
        <template v-slot:tasks>
          <table id="gantt_task_table_host">
            <thead style="padding-left: 5px">
              <tr>
                <th colspan="4" style="padding-left: 15px">Task</th>
              </tr>
              <tr>
                <th style="padding-left: 15px">Attività</th>
                <th style="padding-left: 15px">Inizio</th>
                <th style="padding-left: 15px">Fine</th>
              </tr>
              <tr />
            </thead>
            <tbody
              @click="TABLE.ClickedItem($event, TABLE)"
              id="gantt_task_table"
            />
          </table>
        </template>
        <!-- DIAGRAM -->
        <template v-slot:diagram>
          <table id="gantt_diagram_table">
            <GanttConnections ref="connections" v-model="PROJECT" />
            <thead>
              <tr />
              <tr />
            </thead>
            <tbody ref="diagram" @mousedown="handleMouseDown($event)" />
          </table>
        </template>
        <!-- RESOURCE LIST -->
        <template v-slot:resources> 3 </template>
        <!-- RESOURCE USAGE -->
        <template v-slot:usage> 4 </template>
      </GanttGridView>
    </main>
    <GanttEditor
      ref="editor"
      @update="rebuild"
      @aggiornaProgetto="aggiornaProgetto"
      @insert="insert"
      @snackbarOpen="snackbarOpen"
    />
    <GanttContextMenu @contextmenu="handleContextMenu" />
    <!-- <GanttTooltip ref="tooltip" @contextmenu="handleContextMenu" /> -->
  </div>
</template>
<script>
import GanttCalendar from "@/components/GANTT/Classes/Calendar/Calendar.js";
import GanttTable from "@/components/GANTT/Classes/Table/Table.js";
import GanttDrag from "@/components/GANTT/Classes/Graphics/Drag.js";
import GanttTask from "@/components/GANTT/Classes/Task/Task.js";
import moment from "moment/moment";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import GanttGridView from "./GridView.vue";
import GanttEditor from "./Editor.vue";
import GanttConnections from "../../Components/Connections/Connections.vue";
import GanttContextMenu from "../../Components/ContextMenu/ContextMenu.vue";
//import GanttTooltip from "../../Components/Tooltip/Tooltip.vue";

export default {
  name: "GanttDiagram",
  props: {
    project: [Number, String],
  },
  components: {
    GraphSVG,
    GanttGridView,
    GanttEditor,
    GanttConnections,
    GanttContextMenu,
    // GanttTooltip,
  },
  data() {
    return {
      CALENDAR: new GanttCalendar(),
      CONNECTIONS: null,
      DRAG: new GanttDrag(),
      NETWORK: null,
      PROJECT: null,
      TABLE: new GanttTable(),
      selected: 1,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
    };
  },
  mounted() {
    this.CONNECTIONS = this.$refs.connections;
    this.NETWORK = this.$store.state.Network;
    this.getProjectDetail();

    this.TABLE.setReferences(this.CALENDAR, this.CONNECTIONS);

    this.DRAG.setReferences(this.TABLE, this.CALENDAR, this.CONNECTIONS);

    this.zoomwatcher = function () {
      document.body.style.setProperty("--zoomlevel", window.devicePixelRatio);
    };
    this.zoomwatcher();
    window.addEventListener("resize", this.zoomwatcher);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.zoomwatcher);
  },
  methods: {
    snackbarOpen(resSalvataggio) {
      console.log("ENTRATO", resSalvataggio);
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      if (resSalvataggio) {
        this.snackbar = true;
        this.snackbar_background = "success";
        this.snackbar_icon = "mdi-check";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Salvataggio avvenuto con successo";
        this.snackbar_text_color = "white";
      } else {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Aggiungere una descrizione per ogni Allegato.";
        this.snackbar_text_color = "white";
      }
    },
    aggiornaProgetto(id_progetto) {
      this.project = id_progetto;
      this.getProjectDetail();
    },
    getProjectDetail() {
      const that = this;
      const request = {
        controller: "Gantt",
        body: {
          action: "getProjectDetail",
          token: localStorage.user_token,
          id_progetto: this.project,
        },
        method: "POST",
        on: {
          load: function (response) {
            that.PROJECT = response;
            that.TABLE.build(response.task);
            that.CONNECTIONS.setReferences(that.TABLE);
            that.CONNECTIONS.build(response.connessioni, that.TABLE.items);
          },
          error: function () {},
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    setProjectDetail() {
      // let request = {
      //   task: this.TABLE.getTasks(),
      //   connections: this.CONNECTIONS.getConnections(),
      // };
      // this.TABLE.getTasks();
      // console.log("TASKS PRE SAVE", JSON.stringify(request));
      const that = this;
      const request = {
        controller: "Gantt",
        body: {
          action: "setProjectDetail",
          token: localStorage.user_token,
          connections: this.CONNECTIONS.getConnections(),
          task: this.TABLE.getTasks(),
        },
        method: "PUT",
        on: {
          load: function () {
            alert("Progetto salvato con successo");
            that.getProjectDetail();
          },
          error: function () {
            alert("Errore durante il salvataggio");
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    newtask() {
      const task = new GanttTask({
        id_task: null,
        id_progetto: this.project,
        id_task_padre: null,
        id_temp: moment().valueOf(),
        risorse: [],
        nesting: null,
        tipo: null,
        inizio_task: new Date(),
        inizio_task_reale: new Date(),
        fine_task: new Date(),
        fine_task_reale: new Date(),
        titolo_task: null,
        validita: 1,
        note: null,
        completamento: 0,
      });
      this.$refs.editor.show(task, true);
    },
    insert(task) {
      console.log("enntrato", task);
      console.log(task);
      this.TABLE.data.push(task);
      this.rebuild();
    },
    handleContextMenu(event) {
      switch (event.action) {
        case "add":
          this.$refs.editor.show(event.reference, true);
          break;
        case "edit":
          this.$refs.editor.show(event.reference);
          break;
        case "delete":
          {
            const task = event.reference;
            task.data.validita = 0;
            this.rebuild();
          }
          break;
        case "unlink":
          this.CONNECTIONS.unlink(event.reference);
          break;
      }
    },
    handleMouseDown(event) {
      let target = document.elementFromPoint(event.clientX, event.clientY);
      let tag;
      while (target != null) {
        tag = target.tagName.toLowerCase();
        if (tag == "body") {
          return;
        }
        if (
          target.getAttribute("data-realtask") != null ||
          tag == "figure" ||
          tag == "aside"
        ) {
          break;
        }
        target = target.parentElement;
      }
      if (tag == "aside") {
        this.CONNECTIONS.startDrawing(target, event);
      } else {
        this.DRAG.start(event, this.$refs.diagram, target);
      }
    },
    // handleMouseOver(event){
    //     let target = document.elementFromPoint(event.clientX, event.clientY);
    //      let tag;
    //   while (target != null) {
    //     tag = target.tagName.toLowerCase();
    //     if (tag == "body") {
    //       return;
    //     }
    //     if (
    //       target.getAttribute("data-realtask") != null ||
    //       tag == "figure" ||
    //       tag == "aside"
    //     ) {
    //       break;
    //     }
    //     target = target.parentElement;
    //   }
    //   this.$refs.tooltip.show(event);
    // },
    rebuild() {
      const TABLE = this.TABLE;
      const dates = TABLE.updateBoundaries();
      this.CALENDAR.build(dates.min, dates.max);
      TABLE.update();
      this.CONNECTIONS.build(null, TABLE.items);
      console.log("TARGET", this.target);
    },
    switchView(zoom) {
      this.CALENDAR.setZoom(zoom);
      this.rebuild();
    },
  },
};
</script>
<style src="./GanttDiagram.css" />