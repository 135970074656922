<template>
  <v-app id="inspire" class="fill-height">
    <v-dialog v-model="dialog" width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ titolo_modal }}</span>
        </v-card-title>
        <v-card-text>{{ testo_modal }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="rifiutoDialog()"
            >Rifiuto</v-btn
          >
          <v-btn color="green darken-1" text @click="accettoDialog()"
            >Accetto</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :color="colore_snackbar" v-model="snackbar">
      {{ this.messaggio_errore }}
      <v-btn color="white" text @click="snackbar = false">Chiudi</v-btn>
    </v-snackbar>
    <v-main class="bkground">


      <div class="centerDiv" style="heigth: 100%; width: 100%; vertical-align: middle; padding-top: 60px">      
        <v-container >
           

          <v-row>
              <v-col xs="12" sm="12" md="3" lg="3"/>

              <v-col xs="12" sm="12" md="6" lg="6" >
                <v-card elevation="10" rounded class="mt-20">
                  <br>
                  <v-card-subtitle >
                    <img
                      :src="img_azienda"
                      style="max-height: 125px; max-width: 125px"
                      alt="Q-ERP"
                    />
                  <br> <br>
                  <v-divider/>
                  </v-card-subtitle>
                  <v-card-subtitle
                    style="
                      font-size: 45px;
                      line-height: 1;
                      text-align: center;
                      font-weight: bold;
                      border-radius: 0px 5px 0px 10px;
                    "
                    class="text"
                    >{{ des_titolo_azienda }}</v-card-subtitle
                  >
                  <v-card-subtitle
                    style="
                      font-size: 25px;
                      line-height: 1;
                      text-align: center;
                      font-weight: bold;
                      border-radius: 0px 5px 0px 10px;
                    "
                    class="text"
                    >{{ testo_azienda }}</v-card-subtitle
                  >

                  <v-card-subtitle
                    class="mb-5"
                    style="font-size: 20px; text-align: center"
                    >Accedi con le tue credenziali</v-card-subtitle
                  >
                  <!-- flat -->
                  <v-btn
                    class="text-right"
                    text
                    color="indigo"
                    v-on:click="openDialogRecuperoPassword"
                    >Recupero Password</v-btn
                  >
                  <v-text-field
                    class="mb-5"
                    style="max-width: 75%; margin: 0 auto"
                    v-model="email"
                    filled
                    prepend-inner-icon="mdi-account"
                    label="Username"
                    :rules="[rules.required]"
                    v-on:keyup.enter="login()"
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="password"
                    style="max-width: 75%; margin: 0 auto"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    prepend-inner-icon="mdi-lock"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    class="input-group--focused"
                    :rules="[rules.required]"
                    @click:append="show3 = !show3"
                    v-on:keyup.enter="login()"
                  ></v-text-field>

                  <v-container class="ma-0 pa-0 ">
                    <v-row>
                      <v-col class="ma-0 pa-0" cols="4"></v-col>
                      <v-col class="ma-0 pa-0" cols="4">
                        <v-btn
                          :loading="loading3"
                          :disabled="loading3"
                          style="
                            width: 100%;
                            background-image: linear-gradient(
                              270deg,
                              #103b82,
                              #03b0ec
                            );
                          "
                          class="mt-5"
                          elevation="2"
                          x-large
                          rounded
                          dark
                          @click="login()"
                          >Login</v-btn
                        >
                      </v-col>
                      <v-col class="ma-0 pa-0" cols="4"></v-col>
                    </v-row>

                    <!-- Bottone per attivare il lettore di QR code -->
                    <v-row align="center" justify="center">
                      <v-col cols="4"></v-col>
                      <v-col
                        align="center"
                        cols="4"
                        justify="center"
                      >
                        <v-btn @click="clickQRCode" icon>
                          <v-icon color="primary" x-large> mdi-qrcode-scan </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="4"></v-col>
                    
                    </v-row>
                  
                  </v-container>
                  
              </v-card>             
            </v-col>
          </v-row>

          <div style="position: absolute; bottom: 0px; right: 0px">
            v.{{ this.$store.state.versione }}
          </div>
        </v-container>
      </div>
      <!-- <v-main :style="{ 'background-image': `url(${backgroundUrl})`, 'background-position': 'center' }"> -->

      <v-dialog v-model="dialog_cambia_password" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ titolo_cambio_psw }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="data_psw == ''"
                    name="passwordOld"
                    label="Password Precedente"
                    id="passwordOld"
                    v-model="passwordOld"
                    :rules="[rules.required]"
                    :append-icon="value_log ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value_log = !value_log)"
                    :type="value_log ? 'password' : 'text'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="password"
                    label="Nuova Password"
                    id="password"
                    :append-icon="value_log ? 'visibility' : 'visibility_off'"
                    @click:append="() => (value_log = !value_log)"
                    :type="value_log ? 'password' : 'text'"
                    v-model="passwordNew"
                    :rules="[rules.required, rules.min, rules.password]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    name="confirmPassword"
                    label="Conferma Password"
                    id="confirmPassword"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="passwordConfirm"
                    :rules="[comparePasswords, rules.required]"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog_cambia_password = false"
              >Chiudi</v-btn
            >
            <v-btn color="blue darken-1" text @click="salvaNuovaPassword"
              >Salva</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_recupero_password" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Recupero Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="emailRecupero"
                    label="Email"
                    id="emailRecupero"
                    type="email"
                    v-model="emailRecupero"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog_recupero_password = false"
              >Chiudi</v-btn
            >
            <v-btn color="blue darken-1" text @click="recuperoPassword"
              >Invia</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog del lettore QR code -->
      <v-dialog v-model="qr_reader_visible" persistent>
        <v-toolbar>
          <v-toolbar-title> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="handleCloseQRDialog" v-bind="attrs" v-on="on">
                <v-icon color="error" large> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <qrcode-stream
          :camera="camera"
          @decode="handleOnDecode"
        ></qrcode-stream>
      </v-dialog>
    </v-main>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-app>
</template>
<style scoped>
.bkground {
  background: url('../assets/bg.jpeg');
  background-size: cover;
  height: 100vh;
}
</style>

<script>
import backgroundUrl from "../assets/logo.png";
//import axios from "axios";
import config from "../../public/config";
import { firebase } from "../plugins/firebase";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  components: {
    SnackbarQV,
  },
  data: () => ({
    backgroundUrl,
    config: config,
    snackbar: false,
    value: String,
    value_rip: String,
    value_log: String,
    loading3: false,
    loader: null,
    show3: false,
    img_azienda: "../assets/logo2.png",
    email: "",
    messaggio_errore: "Email o Password errati!",
    password: "",
    ripeti_password_reg: "",
    password_red: "",
    n_tessera_reg: "",
    email_reg: "",
    titolo_azienda: "",
    nome_reg: "",
    cognome_reg: "",
    data_psw: "",
    titolo_cambio_psw: "Cambio Password Obbligatorio",
    dialog_cambia_password: false,
    idUtentePasswordNew: -1,
    passwordNew: "",
    passwordConfirm: "",
    passwordOld: "",
    showPassword: false,
    colore_snackbar: "error",
    dialog: false,
    tokenJwt: "",
    testo_modal: "",
    arrayAvvisi: [],
    testo_azienda: "",
    check_privacy: 0,
    check_termini: 0,
    check_avvisi: 0,
    des_titolo_azienda: "Benvenuto in QuickView",
    tipo: "",
    titolo: "",
    testo: "",
    titolo_modal: "",
    avvisoAttuale: 0,
    dialog_recupero_password: false,
    emailRecupero: "",
    rules: {
      required: (value) => !!value || "Richiesta.",
      min: (v) => v.length >= 8 || "Min 8 caratteri",
      password: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 caratteri con almeno una lettera maiuscola, un numero, e un carattere speciale."
        );
      },
    },
    camera: "off",
    qr_reader_visible: false,
  }),
  name: "Login",
  props: {
    source: String,
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      this.loader = null;
    },
  },
  computed: {
    comparePasswords() {
      return this.passwordNew === this.passwordConfirm
        ? true
        : "La password non coincide";
    },
  },
  mounted() {
    localStorage.clear();
    var name_azienda = window.location.href
      .replace("https://", "")
      .replace("http://", "")
      .replace("www", "");
    var sup = name_azienda.split(".");
    //console.log(sup[0]);
    if (sup[0].includes("localhost")) {
      sup[0] = "test";
    }
    this.getInfoAzienda(sup[0]);

    var url = window.location.href.split("?");
    if (url.length > 1) {
      this.data_psw = url[1].replace("k=", "");
      this.dialog_cambia_password = true;
      this.titolo_cambio_psw = "Reimposta la tua password";
    } else {
      this.titolo_cambio_psw = "Cambio Password Obbligatorio";
    }
    console.log(this.data_psw);
  },
  methods: {
    getInfoAzienda(azienda) {
      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {
          action: "getinfoazienda",
          nome_azienda: azienda,
        },
      };
      console.log("getinfoazienda request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        if (res.data.length > 0) {
          if (res.data[0].img_azienda != null && res.data[0].img_azienda != "")
            this.img_azienda = res.data[0].img_azienda;
          localStorage.setItem("img_azienda", this.img_azienda);
          if (
            res.data[0].ragione_sociale != null &&
            res.data[0].ragione_sociale != ""
          ) {
            this.titolo_azienda = res.data[0].ragione_sociale;
            localStorage.setItem("titolo_azienda", this.titolo_azienda);
          }
          if (
            res.data[0].testo_azienda != null &&
            res.data[0].testo_azienda != ""
          ) {
            this.testo_azienda = res.data[0].testo_azienda;
            localStorage.setItem("testo_azienda", res.data[0].testo_azienda);
          }
          if (res.data[0].titolo != null && res.data[0].titolo != "") {
            this.des_titolo_azienda = res.data[0].titolo;
            localStorage.setItem("des_titolo_azienda", res.data[0].titolo);
          }
          if (
            res.data[0].prefisso_utente != null &&
            res.data[0].prefisso_utente != ""
          ) {
            this.$store.state.prefisso_utente = res.data[0].prefisso_utente;
            // localStorage.setItem(
            //   "prefisso_utente",
            //   res.data[0].prefisso_utente
            // );
            console.log(res.data[0].prefisso_utente);
          }
        }
      });
    },
    accettoDialog() {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "setcheckutente",
      //   id: localStorage.id,
      //   dbname: "medical",
      //   tipo: this.arrayAvvisi[this.avvisoAttuale].tipo,
      //   token: this.tokenJwt,
      // };

      var request = {
        controller: "Token",
        method: "PUT",
        richiesta: {
          action: "setcheckutente",
          id: localStorage.id,
          dbname: "medical",
          tipo: this.arrayAvvisi[this.avvisoAttuale].tipo,
          token: this.tokenJwt,
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        this.azienda = response.data[0];
      });
      // var data = JSON.stringify(finale);
      // axios.put(this.$store.state.config.ip + "api/Token", data, config).catch((e) => {
      //   console.log(e);
      // });

      if (this.avvisoAttuale === this.arrayAvvisi.length - 1) {
        localStorage.setItem("user_token", this.tokenJwt);
        this.$router.replace("home/");
      } else {
        this.avvisoAttuale++;
        this.titolo_modal = this.arrayAvvisi[this.avvisoAttuale].titolo;
        this.testo_modal = this.arrayAvvisi[this.avvisoAttuale].testo;
        this.dialog = true;
      }
    },
    rifiutoDialog() {
      this.avvisoAttuale = 0;
      this.dialog = false;
      localStorage.clear();
      this.loading3 = false;
    },
    openDialogNuovaPassword: function () {
      this.passwordOld = "";
      this.passwordNew = "";
      this.passwordConfirm = "";
      this.idUtentePasswordNew = -1;
      this.loading3 = false;
      this.dialog_cambia_password = true;
    },
    salvaNuovaPassword: function () {
      this.idUtentePasswordNew = localStorage.id;

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      //var finale;
      if (this.data_psw != "" && this.data_psw != null) {
        if (
          this.idUtentePasswordNew == -1 ||
          this.passwordNew == "" ||
          this.passwordConfirm == "" ||
          this.passwordNew != this.passwordConfirm
        ) {
          this.colore_snackbar = "error";
          this.messaggio_errore =
            "Errore: Compilare correttamente tutti i campi";
          this.snackbar = true;
          return;
        }
        // finale = {
        //   action: "setrecuperopsw",
        //   dbname: "medical",
        //   password: this.passwordNew,
        //   data: this.data_psw,
        // };
        // var data = JSON.stringify(finale);

        var request = {
          controller: "Token",
          method: "POST",
          richiesta: {
            action: "setrecuperopsw",
            dbname: "medical",
            password: this.passwordNew,
            data: this.data_psw,
          },
        };
        this.$store.dispatch("api", request).then(() => {
          this.passwordOld = "";
          this.passwordNew = "";
          this.passwordConfirm = "";
          this.idUtentePasswordNew = -1;
          this.dialog_cambia_password = false;
          this.colore_snackbar = "success";
          this.messaggio_errore = "Password Modificata";
          this.snackbar = true;
          this.password = "";
          this.loading3 = false;
          localStorage.clear();
        });

        // axios
        //   .post(this.$store.state.config.ip + "api/Token", data, config)
        //   .then((response) => {
        //     console.log(response);
        //     // JSON responses are automatically parsed.
        //     this.passwordOld = "";
        //     this.passwordNew = "";
        //     this.passwordConfirm = "";
        //     this.idUtentePasswordNew = -1;
        //     this.dialog_cambia_password = false;
        //     this.colore_snackbar = "success";
        //     this.messaggio_errore = "Password Modificata";
        //     this.snackbar = true;
        //     this.password = "";
        //     this.loading3 = false;
        //     localStorage.clear();
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      } else {
        if (
          this.idUtentePasswordNew == -1 ||
          this.passwordNew == "" ||
          this.passwordOld == "" ||
          this.passwordConfirm == "" ||
          this.passwordNew != this.passwordConfirm
        ) {
          this.colore_snackbar = "error";
          this.messaggio_errore =
            "Errore: Compilare correttamente tutti i campi";
          this.snackbar = true;
          return;
        }
        // finale = {
        //   action: "putcambio",
        //   id_utente: this.idUtentePasswordNew,
        //   dbname: "medical",
        //   old: this.passwordOld,
        //   new: this.passwordNew,
        //   token: this.tokenJwt,
        // };
        // data = JSON.stringify(finale);
        request = {
          controller: "Token",
          method: "PUT",
          richiesta: {
            action: "putcambio",
            id_utente: this.idUtentePasswordNew,
            dbname: "medical",
            old: this.passwordOld,
            new: this.passwordNew,
            token: this.tokenJwt,
          },
        };
        this.$store.dispatch("api", request).then((response) => {
          if (response.status == 200) {
            if (response.data.attivo == 0) {
              this.loading3 = false;
              this.colore_snackbar = "error";
              this.messaggio_errore =
                "Errore:  L'utente non è attivo, contattare un responsabile";
              this.snackbar = true;
              return;
            }
            this.passwordOld = "";
            this.passwordNew = "";
            this.passwordConfirm = "";
            this.idUtentePasswordNew = -1;
            this.dialog_cambia_password = false;
            this.colore_snackbar = "success";
            this.messaggio_errore = "Password Modificata";
            this.snackbar = true;
            this.password = "";
            this.loading3 = false;
            localStorage.clear();
          } else {
            this.colore_snackbar = "orange";
            this.messaggio_errore = "Errore di modifica password";
            this.snackbar = true;
          }
        });

        // axios
        //   .put(this.$store.state.config.ip + "api/Token", data, config)
        //   .then((response) => {
        //     console.log(response);
        //     if (response.data.attivo == 0) {
        //       this.loading3 = false;
        //       this.colore_snackbar = "error";
        //       this.messaggio_errore =
        //         "Errore:  L'utente non è attivo, contattare un responsabile";
        //       this.snackbar = true;
        //       return;
        //     }
        //     console.log(response);
        //     // JSON responses are automatically parsed.
        //     this.passwordOld = "";
        //     this.passwordNew = "";
        //     this.passwordConfirm = "";
        //     this.idUtentePasswordNew = -1;
        //     this.dialog_cambia_password = false;
        //     this.colore_snackbar = "success";
        //     this.messaggio_errore = "Password Modificata";
        //     this.snackbar = true;
        //     this.password = "";
        //     this.loading3 = false;
        //     localStorage.clear();
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //     this.passwordOld = "";
        //     this.passwordNew = "";
        //     this.passwordConfirm = "";
        //     this.idUtentePasswordNew = -1;
        //     this.dialog_cambia_password = false;
        //     this.colore_snackbar = "error";
        //     this.messaggio_errore = "Errore nel cambio Password";
        //     this.snackbar = true;
        //     this.password = "";
        //     this.loading3 = false;
        //     localStorage.clear();
        //     console.log(e);
        //   });
      }
    },

    openDialogRecuperoPassword: function () {
      this.emailRecupero = "";
      this.dialog_recupero_password = true;
    },

    recuperoPassword: function () {
      if (this.emailRecupero == "") {
        //compilare tutto
        this.colore_snackbar = "error";
        this.messaggio_errore = "Errore: Compilare correttamente tutti i campi";
        this.snackbar = true;
        return;
      }

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "getrecuperopsw",
      //   db_name: "medical",
      //   email: this.emailRecupero,
      // };
      // var data = JSON.stringify(finale);

      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {
          action: "getrecuperopsw",
          db_name: "medical",
          email: this.emailRecupero,
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        this.emailRecupero = "";
        this.dialog_recupero_password = false;

        if (response.status == "204") {
          this.colore_snackbar = "error";
          this.messaggio_errore = "Errore:  Email non trovata";
        } else {
          this.colore_snackbar = "success";
          this.messaggio_errore = "Email Inviata.\nControlla la tua email";
        }

        this.snackbar = true;
      });

      // axios
      //   .post(this.$store.state.config.ip + "api/Token", data, config)
      //   .then((response) => {
      //     console.log(response);
      //     // JSON responses are automatically parsed.
      //     this.emailRecupero = "";
      //     this.dialog_recupero_password = false;

      //     if (response.status == "204") {
      //       this.colore_snackbar = "error";
      //       this.messaggio_errore = "Errore:  Email non trovata";
      //     } else {
      //       this.colore_snackbar = "success";
      //       this.messaggio_errore = "Email Inviata.\nControlla la tua email";
      //     }

      //     this.snackbar = true;
      //   })
      //   .catch((e) => {
      //     this.emailRecupero = "";
      //     this.dialog_recupero_password = false;
      //     this.colore_snackbar = "error";
      //     this.messaggio_errore = "Errore nel recupero della Password";
      //     this.snackbar = true;

      //     if (e.response.status == 401) {
      //       this.$router.replace("login");
      //     }
      //     //controllare password ed utente
      //     console.log(e);
      //   });
    },

    invioLogin: function (e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },

    login: function () {
      this.loader = "loading3";
      if (this.email == "" || this.password == "") {
        //compilare tutto
        this.colore_snackbar = "error";
        this.messaggio_errore = "Compilare correttamente username e password";
        this.snackbar = true;
        this.loading3 = false;
        return;
      }

      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      var request = {
        controller: "Token",
        method: "POST",
        richiesta: {},
      };

      /*if (
        localStorage.prefisso_utente != null &&
        localStorage.prefisso_utente != undefined &&
        localStorage.prefisso_utente != "" &&
        !this.email.includes("/")
      ) {
        request.richiesta = {
          username: this.email,
          password: this.password,
          prefisso_utente: localStorage.prefisso_utente,
        };
      } else */
      if (this.email.includes("/")) {
        var username = this.email.split("/");
        request.richiesta = {
          username: username[1],
          password: this.password,
          prefisso_utente: username[0],
        };
      } else {
        request.richiesta = {
          username: this.email,
          password: this.password,
          db_name: "medical",
        };
      }

      this.$store
        .dispatch("api", request)
        .then((response) => {
          if (response.data.jwt != "") {
            if (response.data.attivo == 0) {
              this.loading3 = false;
              //controllare password ed utente

              this.colore_snackbar = "error";
              this.messaggio_errore =
                "L'utente non è attivo, contattare il personale!";
              this.snackbar = true;
              return;
            }

            /*if(response.data.versione != "" && response.data.versione != this.$store.state.versione){
            this.loading3 = false;
            //controllare password ed utente
            this.colore_snackbar = "error";
            this.messaggio_errore =
              "Azzerare i dati di navigazione del browser.";
            this.snackbar = true;
            return;
          }*/

            //console.log(response.data);
            localStorage.setItem("user_token", response.data.jwt);
            localStorage.setItem(
              "moduli_dx",
              JSON.stringify(response.data.moduloDx)
            );
            localStorage.setItem(
              "moduli_sx",
              JSON.stringify(response.data.moduloSx)
            );
            console.log("response login:", response.data);
            localStorage.setItem("id", response.data.id);
            localStorage.setItem("nominativo", response.data.nominativo);
            localStorage.setItem("des_profilo", response.data.des_profilo);
            localStorage.setItem("id_profilo", response.data.id_profilo);
            localStorage.setItem("des_azienda", response.data.des_azienda);
            localStorage.setItem("cod_azienda", response.data.cod_azienda);
            localStorage.setItem("id_azienda", response.data.id_azienda);
            localStorage.setItem("cod_profilo", response.data.cod_profilo);
            localStorage.setItem("chat", response.data.firebase_ok);
            localStorage.setItem("db_name", response.data.db_name);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem(
              "prefisso_user",
              response.data.prefisso_utente
            );
            localStorage.setItem("task_validi", response.data.task_validi);
            localStorage.setItem("task_scaduti", response.data.task_scaduti);

            //  Info accesso ai moduli amministrativi
            let auth_level = Number(response.data.auth_level);
            if (auth_level >= 0) {
              auth_level = new Uint32Array([auth_level])[0];
            }
            localStorage.auth_level = auth_level;
            // this.$store.state.auth_level = auth_level;
            // this.$store.state.show_admin_tools =
            localStorage.show_admin_tools = auth_level == -1 || auth_level > 0;

            if (response.data.check_privacy === "1") {
              this.check_privacy = 1;
            }
            if (response.data.check_termini === "1") {
              this.check_termini = 1;
            }
            if (response.data.check_avvisi === "1") {
              this.check_avvisi = 1;
            }

            if (response.data.check_privacy === "1") {
              this.check_privacy = 1;
            }
            if (response.data.check_termini === "1") {
              this.check_termini = 1;
            }
            if (response.data.check_avvisi === "1") {
              this.check_avvisi = 1;
            }

            this.arrayAvvisi = [];
            if (response.data.cambio_password == 1) {
              this.tokenJwt = response.data.jwt;
              localStorage.removeItem("user_token");
              this.loading3 = false;
              this.dialog_cambia_password = true;
            } else {
              if (
                this.check_privacy != 1 &&
                response.data.privacy != null &&
                response.data.privacy != "" &&
                response.data.privacy != undefined
              ) {
                this.tokenJwt = response.data.jwt;
                localStorage.removeItem("user_token");
                this.titolo_modal = "PRIVACY";
                this.testo_modal = response.data.privacy;
                var obj = {
                  testo: response.data.privacy,
                  titolo: "PRIVACY",
                  tipo: "privacy",
                };
                this.arrayAvvisi.push(obj);
              }
              if (
                this.check_termini != 1 &&
                response.data.termini_uso != null &&
                response.data.termini_uso != "" &&
                response.data.termini_uso != undefined
              ) {
                this.tokenJwt = response.data.jwt;
                localStorage.removeItem("user_token");
                this.titolo_modal = "TERMINI D' USO";
                this.testo_modal = response.data.termini_uso;
                var objx = {
                  testo: response.data.termini_uso,
                  titolo: "TERMINI D' USO",
                  tipo: "termini_uso",
                };
                this.arrayAvvisi.push(objx);
              }
              if (this.arrayAvvisi.length > 0) {
                this.titolo_modal = this.arrayAvvisi[this.avvisoAttuale].titolo;
                this.testo_modal = this.arrayAvvisi[this.avvisoAttuale].testo;
                this.dialog = true;
                return;
              }
              //CONTROLLO SE HA LOGINCON FIREBASE PRIMA
              try {
                if (
                  response.data.email != "" &&
                  response.data.firebase_ok == "1"
                ) {
                  firebase
                    .auth()
                    //.signInWithEmailAndPassword("l.squillaci@getsoft.it", "123456")
                    .signInWithEmailAndPassword(response.data.email, "123456")
                    .then(
                      () => {
                        this.$router.replace("home/");
                        this.loading3 = false;
                      },
                      (err) => {
                        this.loading3 = false;
                        console.log("err firebase", err);
                      }
                    );
                } else {
                  this.$router.replace("home/");
                }
              } catch (error) {
                console.log(error);
                this.$router.replace("home/");
              }
            }
          } else {
            this.loading3 = false;
            //controllare password ed utente
            this.colore_snackbar = "error";
            if (response.data.status == 403) {
              this.messaggio_errore = "Username o password errati!";
            } else {
              this.messaggio_errore = response.data.result;
            }
            this.snackbar = true;
          }
        })
        .catch((e) => {
          console.log("catch Login auth", e);
          this.loading3 = false;
          if (e.toString().includes("403") || e.toString().includes("401")) {
            this.messaggio_errore = "Username o password errati!";
          } else {
            this.messaggio_errore = e;
          }
          this.snackbar = true;
        });

      // axios
      //   .post(this.$store.state.config.ip + "api/Token", data, config)
      //   .then((response) => {
      //     if (response.data.jwt != "") {
      //       if (response.data.attivo == 0) {
      //         this.loading3 = false;
      //         //controllare password ed utente
      //         this.colore_snackbar = "error";
      //         this.messaggio_errore =
      //           "L'utente non è attivo, contattare il personale!";
      //         this.snackbar = true;
      //         return;
      //       }

      //       //console.log(response.data);
      //       localStorage.setItem("user_token", response.data.jwt);
      //       localStorage.setItem(
      //         "moduli_dx",
      //         JSON.stringify(response.data.moduloDx)
      //       );
      //       localStorage.setItem(
      //         "moduli_sx",
      //         JSON.stringify(response.data.moduloSx)
      //       );

      //       localStorage.setItem("id", response.data.id);
      //       localStorage.setItem("nominativo", response.data.nominativo);
      //       localStorage.setItem("des_profilo", response.data.des_profilo);
      //       localStorage.setItem("id_profilo", response.data.id_profilo);
      //       localStorage.setItem("des_azienda", response.data.des_azienda);
      //       localStorage.setItem("cod_azienda", response.data.cod_azienda);
      //       localStorage.setItem("id_azienda", response.data.id_azienda);
      //       localStorage.setItem("chat", response.data.firebase_ok);
      //       localStorage.setItem("db_name", response.data.db_name);
      //       localStorage.setItem("email", response.data.email);

      //       if (response.data.check_privacy === "1") {
      //         this.check_privacy = 1;
      //       }
      //       if (response.data.check_termini === "1") {
      //         this.check_termini = 1;
      //       }
      //       if (response.data.check_avvisi === "1") {
      //         this.check_avvisi = 1;
      //       }

      //       this.arrayAvvisi = [];
      //       if (response.data.cambio_password == 1) {
      //         this.tokenJwt = response.data.jwt;
      //         localStorage.removeItem("user_token");
      //         this.loading3 = false;
      //         this.dialog_cambia_password = true;
      //       } else {
      //         if (
      //           this.check_privacy != 1 &&
      //           response.data.privacy != null &&
      //           response.data.privacy != "" &&
      //           response.data.privacy != undefined
      //         ) {
      //           this.tokenJwt = response.data.jwt;
      //           localStorage.removeItem("user_token");
      //           this.titolo_modal = "PRIVACY";
      //           this.testo_modal = response.data.privacy;
      //           var obj = {
      //             testo: response.data.privacy,
      //             titolo: "PRIVACY",
      //             tipo: "privacy",
      //           };
      //           this.arrayAvvisi.push(obj);
      //         }
      //         if (
      //           this.check_termini != 1 &&
      //           response.data.termini_uso != null &&
      //           response.data.termini_uso != "" &&
      //           response.data.termini_uso != undefined
      //         ) {
      //           this.tokenJwt = response.data.jwt;
      //           localStorage.removeItem("user_token");
      //           this.titolo_modal = "TERMINI D' USO";
      //           this.testo_modal = response.data.termini_uso;
      //           var objx = {
      //             testo: response.data.termini_uso,
      //             titolo: "TERMINI D' USO",
      //             tipo: "termini_uso",
      //           };
      //           this.arrayAvvisi.push(objx);
      //         }
      //         if (this.arrayAvvisi.length > 0) {
      //           this.titolo_modal = this.arrayAvvisi[this.avvisoAttuale].titolo;
      //           this.testo_modal = this.arrayAvvisi[this.avvisoAttuale].testo;
      //           this.dialog = true;
      //           return;
      //         }
      //         //CONTROLLO SE HA LOGINCON FIREBASE PRIMA
      //         try {
      //           if (
      //             response.data.email != "" &&
      //             response.data.firebase_ok == "1"
      //           ) {
      //             firebase
      //               .auth()
      //               //.signInWithEmailAndPassword("l.squillaci@getsoft.it", "123456")
      //               .signInWithEmailAndPassword(response.data.email, "123456")
      //               .then(
      //                 () => {
      //                   this.$router.replace("home/");
      //                   this.loading3 = false;
      //                 },
      //                 (err) => {
      //                   this.loading3 = false;
      //                   console.log("err firebase", err);
      //                 }
      //               );
      //           } else {
      //             this.$router.replace("home/");
      //           }
      //         } catch (error) {
      //           console.log(error);
      //           this.$router.replace("home/");
      //         }
      //       }
      //     } else {
      //       this.loading3 = false;
      //       //controllare password ed utente
      //       this.colore_snackbar = "error";
      //       if (response.data.status == 403) {
      //         this.messaggio_errore = "Username o password errati!";
      //       } else {
      //         this.messaggio_errore = response.data.result;
      //       }
      //       this.snackbar = true;
      //     }
      //     // JSON responses are automatically parsed.
      //   })
      //   .catch((e) => {
      //     //console.log(JSON.stringify(data));
      //     this.loading3 = false;
      //     if (e.toString().includes("403")) {
      //       this.messaggio_errore = "Username o password errati!";
      //     } else {
      //       this.messaggio_errore = e;
      //     }
      //     this.snackbar = true;

      //     console.log(e);
      //   });
    },
    handleOnDecode(arg) {
      this.email = arg.split("(Q)")[1].split(";")[0];
      this.password = arg.split("(H)")[1].split(";")[0];
      this.camera = "off";
      this.qr_reader_visible = false;

      this.login();
    },
    clickQRCode() {
      this.qr_reader_visible = true;
      this.camera = "auto";
    },
    handleCloseQRDialog() {
      this.qr_reader_visible = false;
      this.camera = "off";
    },
    noop() {
      // do nothing ?
    },
  },
};
</script>

<style lang="scss">
.centered-container {
  display: flex;
  position: relative;
  height: 100vh;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 80px;
    }
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }

  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
