<template>
    <div style="display:none" />
</template>
<script>
export default {
    name : 'GraphPrintMode',
    data(){
        return{
            show : false,
            last_click : null,
            rendering:{queue:0,data:[],length:0,titles:[]},
        }
    },
    mounted(){

    },
    beforeDestroy(){

    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        printSetup(){
            // Se non esiste l'evento afterprint (vecchio safari), pulisco gli elementi di stampa già esistenti
            this.printCleanup();            
            this.show = false;
            let widgets = document.getElementsByClassName('graph_widget');
            this.rendering.queue = 0;
            this.rendering.length = widgets.length;
            this.rendering.data = [];
            this.rendering.titles = [];
            for(let i=0;i<widgets.length;i++){
                let id = widgets[i].getAttribute('id').replace('widget-','chart-');
                this.rendering.data.push(null);
                this.rendering.titles.push(widgets[i].getElementsByTagName('h1')[0].innerText);
                let that = this;
                let render_event = new CustomEvent('graph_raster',{detail:{
                    options : {
                        quality : 100,
                        type : 'svg',
                        width : 'auto',
                        height : 'auto',
                        background : 'transparent',
                    },
                    download : false,
                    callback : function(data){
                        for(let j=0;j<that.rendering.data.length;j++){
                            if(j==i){
                                that.rendering.queue++;
                                that.rendering.data[j] = data
                            }
                        }
                        if(that.rendering.queue==that.rendering.length){
                            that.printExec();
                        }
                    },
                }});                
                document.getElementById(id).dispatchEvent(render_event); 
            }
        },
        printExec(){  
            let load_count = this.rendering.data.length;
            function process(){
                load_count--;
                if(load_count >0){return;}
                window.print();
            }     
            for(let i = 0; i < this.rendering.data.length; i++){
                let data = this.rendering.data[i];
                let figure = document.createElement('figure');
                let title = document.createElement('h2');
                let img = new Image();                                  

                figure.setAttribute('class','graph_print');                
                title.innerText = this.rendering.titles[i];
                window.addEventListener("afterprint", this.printCleanup);
                img.onload = process;
                img.onerror = process;                
                figure.appendChild(title);
                figure.appendChild(img);
                document.body.appendChild(figure);
                img.src = data;
            }           
        },
        printCleanup(){
            window.removeEventListener("afterprint", this.printCleanup);
            let imgs = document.body.getElementsByClassName('graph_print');
            let length = imgs.length;
            for(let i=0; i < length; i++){
                document.body.removeChild(imgs[0]);
            }
        }
    }
}
</script>
<style src="../CSS/Print.css" />