<template>
  <div :data-nonadmin="(isAdmin()==true)?false:true" class="quickchart_dashboard_main" id="quickchart_dashboard_main" :style="'background:' + ui.background" >
    <GraphHeader :path="navbarpath"/>
    <nav>
      <GraphPrintMode />
      <GraphSVG @click="show_layout_dialog" name='save' />
      <GraphSVG @click="$router.push('/home/graph/presentation/'+id)" name='presentation'/>
      <GraphSVG @click="show_chartedit_create" name='plus'/>      
    </nav>
    <GraphOverallUser :id="id" />
    <main class="graph_scrollbar">
      <datalist class="graph_contextmenu">
        <option value="Crea nuovo" @contextmenu="show_chartedit_create" :disabled="(isAdmin()==true)?false:true">plus</option>
        <option value="Incolla grafico" @contextmenu="pasteChart" :disabled="(isAdmin()==true)?false:true">paste</option>
        <option value="Impostazione Parametri" @contextmenu="show_overall" :disabled="(isAdmin()==true)?false:true">tools</option>
      </datalist>
      <div id="dashboard" :style="'transform:scale(' + ui.zoom / 100 + ')'" >
        <Widget v-for="(data, i) in WIDGET_DATA" :key="i" :index="i" :title="data.nome" />
      </div>
    </main> 

    <footer context_menu_eligible="disabled">
      <GraphSwitch :id="id" />
      <span>{{ ui.zoom }}%</span>
      <GraphSVG @click="ui.zoom=(ui.zoom>=20)?ui.zoom-10:10" name='zoom_out'/>
      <input type="range" min="10" max="160" v-model="ui.zoom" id='graph_dashboard_zoom'>
      <GraphSVG @click="ui.zoom=(ui.zoom<=150)?Number(ui.zoom)+10:160" name='zoom_in'/>
    </footer>
    
    <GraphEditor />
    <GraphTabella />
    <GraphFullscreen />
    <GraphParametri />
    <GraphLayout />
    <GraphRender />
    <GraphOverall />
    <GraphCompiledQuery />    

    <CONTEXTMENU />
  </div>
</template>
<script>

import CONTEXTMENU from "@/components/GRAPH/Generale/Componenti/ContextMenu/ContextMenu.vue";

import Widget from "@/components/GRAPH/Dashboard/Widget/Widget.vue";
import GraphFullscreen from "@/components/GRAPH/Dashboard/Fullscreen/Fullscreen.vue";
import GraphEditor from "@/components/GRAPH/Dashboard/Editor/GraphEditor.vue";
import GraphTabella from "@/components/GRAPH/Dashboard/Tabella/Tabella.vue";
import GraphParametri from "@/components/GRAPH/Dashboard/Parametri/Parametri.vue";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import GraphLayout from "@/components/GRAPH/Dashboard/Layout/Layout.vue";
import GraphHeader from "@/components/GRAPH/Generale/Componenti/Header/Header.vue";
import GraphRender from "@/components/GRAPH/Dashboard/Render/Render.vue";
import GraphPrintMode from "@/components/GRAPH/Dashboard/Stampa/PrintMode.vue";
import GraphOverall from "@/components/GRAPH/Dashboard/Overall/Overall.vue";
import GraphOverallUser from "@/components/GRAPH/Dashboard/Overall/Overall_user.vue";
import GraphSwitch from "@/components/GRAPH/Dashboard/Switch/Switch.vue";
import GraphCompiledQuery from "@/components/GRAPH/Dashboard/Query/Query.vue";

export default {
  name: "ChartDashboard",
  components: {
    CONTEXTMENU,

    Widget,
    GraphFullscreen,
    GraphEditor,
    GraphTabella,
    GraphParametri,
    GraphSVG,
    GraphLayout,
    GraphHeader,
    GraphRender,
    GraphPrintMode,
    GraphOverall,
    GraphOverallUser,
    GraphSwitch,
    GraphCompiledQuery
  },
  props: {
    id: String,
  },
  data() {
    return {
      dashboard: {},
      ui : {
        background:null,
        zoom:100,
        first_time:true,
        refresh_interval : null,
      },
      WIDGET_DATA: [],
      navbarpath: null,
    };
  },
  mounted() {
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    document.getElementById('quickchart_dashboard_main').addEventListener('dashboardrefresh',this.get_dashboard);
    this.get_dashboard(true);   
    document.body.addEventListener("widget_event",this.widget_event_handler);
    //this.ui.refresh_interval = setInterval(this.get_dashboard,60000); 
    
  },
  beforeDestroy(){ 
    document.body.removeEventListener("widget_event",this.widget_event_handler);  
    //clearInterval(this.ui.refresh_interval);  
  },
  destroyed(){ window.freeComponent(this); },
  methods: {
    isAdmin(){return Number(localStorage.id_profilo)==1;},

    update_chart(options,target){
      let update_event = new CustomEvent('graph_update',{detail:options});
      document.getElementById('chart-'+target).dispatchEvent(update_event);
    },
    resize_chart(target){
      let resize_event = new CustomEvent('graph_resize',{detail:null});
      document.getElementById('chart-'+target).dispatchEvent(resize_event);
    },
    
    //  Metodo per la gestione degli eventi provenienti dal componente Widget.vue
    widget_event_handler(data){
      let code = data.detail.code;
      let widget = data.detail.widget;
      //let dashboard = document.getElementById("dashboard");

      switch(code){
        //  Ridimensionamento widget concluso (esecuzione ridimensionamento grafico) (Obsoleto, rimuovere)
        case 0: {
          this.resize_chart(widget);
        }
        break;
        //  Menu widget : modifica grafico
        case 1: {
            let edit_event = new CustomEvent('graph_editor',{detail:{ds:this.id,index:this.WIDGET_DATA[widget].id_ds_dettaglio}});
            document.body.dispatchEvent(edit_event);
          }
          break;
        //  Menu widget : visualizzazione tabella
        case 2:{            
            let table_event = new CustomEvent('graph_table',{detail:this.WIDGET_DATA[widget]});
            document.body.dispatchEvent(table_event);
          }
          break;
        //  Menu widget : schermo intero
        case 3: {
            let fullscreen_event = new CustomEvent('graph_fullscreen',{detail:this.WIDGET_DATA[widget]});
            document.body.dispatchEvent(fullscreen_event);
          }
          break;
        //  Menu widget : visualizza/modifica parametri
        case 4:{
            let params_event = new CustomEvent('graph_params',{detail:{id:this.WIDGET_DATA[widget].id_ds_dettaglio}});
            document.body.dispatchEvent(params_event);
          }
          break;
        // Menu contestuale : copia grafico
        case 5 : {
            let request = {
              controller : 'Graph',
              body : {
                action: "getDettaglioGrafico",
                id_ds_dettaglio: this.WIDGET_DATA[widget].id_ds_dettaglio,
                token: localStorage.user_token,
              },
              method: 'POST',
              on : {
                load : function(response){                 
                    sessionStorage.setItem('graphCopy', btoa(unescape(encodeURIComponent(JSON.stringify(response)))));                   
                }
              }              
            }
            window.NETWORK.AjaxRequest(request);
          }
          break;
        case 6:{
            let render_event = new CustomEvent('graph_render',{detail:{id:widget,data:this.WIDGET_DATA[widget]}});
            document.body.dispatchEvent(render_event);
        }
        break;
        case 7:{
            let query_event = new CustomEvent('graph_compiled_query',{detail:this.WIDGET_DATA[widget]});
            document.body.dispatchEvent(query_event);
        }
        break;
        case 8:{
          const chart_id = this.WIDGET_DATA[widget].id_ds_dettaglio;
          const child_id = this.WIDGET_DATA[widget].derivato;
          const edit_event = new CustomEvent('graph_editor',{detail:{ds:chart_id,index: child_id == null ? -1 : child_id, derived:true}});
          document.body.dispatchEvent(edit_event);
        }
        break;
      }
    },
    pasteChart(){
      // Recupero e controllo della copia
      let copy = sessionStorage.graphCopy;
      if(copy==null||copy==undefined){return;}      
      try{
        copy=JSON.parse(decodeURIComponent(escape(atob(copy))));
        copy = copy.grafici[0];
        if(copy==undefined){return;}
      }catch(error){
        console.log(error);
        return;
      }
      // Impostazione dettaglio
      copy.id_ds_dettaglio=-1;
      copy.id_ds_rif=this.id;
      // Impostazione parametri
      for(let i=0;i<copy.parametri.length;i++){
        copy.parametri[i].id_ds_dettaglio_param=-1;
        copy.parametri[i].id_ds_dettaglio_rif=-1;
      }
      let parametri = copy.parametri.map((x)=>x);
      delete copy.parametri;
      // Impostazione serie
      for(let i=0;i<copy.serie.length;i++){
        copy.serie[i].id_ds_dettaglio_serie=-1;
        copy.serie[i].id_ds_dettaglio_rif=-1;
      }
      let serie = copy.serie.map((x)=>x);
      delete copy.serie;

      let request = {
        controller : 'Graph',
        body : {
          action: "setDashboardDettaglio",
          prefisso_utente: localStorage.prefisso_utente,
          token: localStorage.user_token,
          id_utente: localStorage.id,
          jsonDettaglio: [copy],
          jsonParam: parametri,
          jsonSerie: serie,
        },
        method: 'PUT',
        on : {
          load : this.get_dashboard,
          error : function(){ alert("Si è verificato un errore durante il salvataggio dei dati"); }                                     
          }
        }
      window.NETWORK.AjaxRequest(request);

    },
    show_chartedit_create(){
      let edit_event = new CustomEvent('graph_editor',{detail:{ds:this.id,index:-1}});
      document.body.dispatchEvent(edit_event);
    },

    show_layout_dialog(){
      let layout_event = new CustomEvent('graph_layout',{detail:this.WIDGET_DATA});
      document.body.dispatchEvent(layout_event);
    },

    show_overall(admin=true){
      let overall_event = new CustomEvent('graph_overall'+((admin)?'':'_user'),{detail:this.id});
      document.body.dispatchEvent(overall_event);
    },

    get_dashboard(event = null) {
      this.ui.first_time = event != null;
      let request = {
        controller : 'Graph',
        body : {
          action: "getDashboardDettaglio",
          id_ds: this.id,
          token: localStorage.user_token
        },
        method: 'POST',
        on : {
          load : this.set_dashboard
        },
        showloading : this.ui.first_time
      }
      if(this.ui.first_time){ this.show_overall(false); }      
      window.NETWORK.AjaxRequest(request);
    },
    set_dashboard(response) {
      const that = this;
      this.dashboard = response.dashboard[0];
      this.navbarpath = [{name:this.dashboard.nome,path:null}];
      
      // sfondo
      let dashboard = document.getElementById('quickchart_dashboard_main');
      dashboard.style.background = this.dashboard.sfondo;
      dashboard.style.backgroundSize = 'cover';
      dashboard.style.backgroundPosition = 'center center';

      const old_data = this.WIDGET_DATA;
      this.WIDGET_DATA = ((Array.isArray(this.dashboard.grafici)?this.dashboard.grafici:[]));     

      this.$nextTick(function(){
        for(let i=0;i<that.WIDGET_DATA.length;i++){
          let widget = document.getElementById('widget-'+i); 
          if(widget==null||widget==undefined){continue;}

          that.WIDGET_DATA[i].index = i;

          if(that.ui.first_time == true || old_data[i] == undefined ){
            let layout = { x:0, y:0, w:300, h:300 };
            try{
              layout = JSON.parse(decodeURIComponent(escape(atob(that.WIDGET_DATA[i].layout))));            
            }catch(error){
              console.warn(`Non è stato possibile leggere il layout per il grafico ${i}, applicazione del layout di default`);
            } 

            widget.style.left=layout.x+'px';
            widget.style.top=layout.y+'px';
            widget.style.width=layout.w+'px';
            widget.style.height=layout.h+'px';
            that.WIDGET_DATA[i].layout = layout;
          }else{
            that.WIDGET_DATA[i].layout = old_data[i].layout
          }        

          that.resize_chart(i);
          that.update_chart(that.WIDGET_DATA[i],i); 
        }    
        that.ui.first_time = false;    
      });      

    },
  },
};
</script>
<style src="@/components/GRAPH/Dashboard/CSS/Dashboard.css" ></style>