var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"font-size":"20px","text-align":"center"},attrs:{"id":"listener_carrello2"}},[(_vm.items_carrello.length == 0)?_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v("Work Orders in Gestione")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.gotoDashboard}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,false,3233997669)},[_c('span',[_vm._v("Vai alla Dashboard")])])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"font-size":"20px","line-height":"20px"}},[_vm._v("Ciao "+_vm._s(_vm.nome_utente)+", la lista dei Work Order è vuota: ne vuoi aggiungere uno?")]),_c('v-divider'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5 my-2",on:{"click":_vm.clickAggiungi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}],null,false,1504739001)},[_c('span',[_vm._v("Aggiungi Work Order")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.multicommessa_visible === true)?_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",on:{"click":_vm.clickAggiungiMultiregistrazione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-inbox-multiple")])],1):_vm._e()]}}],null,false,1157557468)},[_c('span',[_vm._v("Aggiungi Multiregistrazione")])])],1):_vm._e(),(_vm.items_carrello.length > 0)?_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-size":"20px !important"},attrs:{"headers":_vm.headers_carrello,"items":_vm.items_carrello,"item-class":_vm.evidenziaRitardo},on:{"click:row":_vm.clickCarrelloRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"white--text",attrs:{"rounded":"","color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v("Work Orders in Gestione")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.clickAggiungi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}],null,false,2280285031)},[_c('span',[_vm._v("Aggiungi Work Order")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.multicommessa_visible === true)?_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"small":""},on:{"click":_vm.clickAggiungiMultiregistrazione}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-inbox-multiple")])],1):_vm._e()]}}],null,false,4017717774)},[_c('span',[_vm._v("Aggiungi Multiregistrazione")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.gotoDashboard}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,false,3233997669)},[_c('span',[_vm._v("Vai alla Dashboard")])])],1)]},proxy:true},{key:"header.start_stop",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.registra",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.visualizza_dettagli",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.visualizza_allegato_controllo",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.cod_articolo",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.cod_commessa",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.cod_fase",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.cod_macchina",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.des_fase",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.quantita",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.pezzi_registrati",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.qta_precedente",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.ora_start",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.ora_stop",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.ore_produzione",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.pezzi_produzione",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.pzhreale",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.tempo_ciclo",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"header.nascondi",fn:function(ref){
var header = ref.header;
return [_c('span',{staticStyle:{"font-size":"20px","font-weight":"normal"}},[_vm._v(_vm._s(header.text))])]}},{key:"item.start_stop",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-3",staticStyle:{"height":"80px"},attrs:{"color":item.ora_start == null ||
          (item.ora_start != null && item.ora_stop != null)
            ? 'success'
            : 'error',"dark":"","tile":"","x-large":""},on:{"click":function($event){return _vm.switchStartStop(item)}}},[_c('v-icon',{staticStyle:{"font-size":"40px"}},[_vm._v(" "+_vm._s(item.ora_start == null || (item.ora_start != null && item.ora_stop != null) ? "mdi-play" : "mdi-stop")+" ")])],1)]}},{key:"item.registra",fn:function(ref){
            var item = ref.item;
return [(item.ora_start != null)?_c('v-btn',{staticClass:"my-3",staticStyle:{"height":"80px"},attrs:{"color":"primary","dark":"","x-large":""},on:{"click":function($event){return _vm.clickRegistraPezzi(item)}}},[_vm._v("DICHIARA")]):_vm._e()]}},{key:"item.visualizza_dettagli",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"my-3",staticStyle:{"height":"80px"},attrs:{"color":item.id_disegno == null
            ? 'grey lighten-5'
            : item.view_disegno == true
            ? 'primary'
            : 'error',"dark":"","x-large":""},on:{"click":function($event){return _vm.clickVisualizzaDisegno(item)}}},[_c('v-icon',{attrs:{"color":item.id_disegno == null ? 'black' : 'white'}},[_vm._v(" mdi-eye ")])],1)]}},{key:"item.visualizza_allegato_controllo",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"my-3",staticStyle:{"height":"80px"},attrs:{"color":item.check_allegato == 0 ? 'grey lighten-5' : 'primary',"dark":"","x-large":""},on:{"click":function($event){return _vm.clickVisualizzaAllegatoControllo(item)}}},[_c('v-icon',{attrs:{"color":item.check_allegato == 0 ? 'black' : 'white'}},[_vm._v(" mdi-check-decagram ")])],1)]}},{key:"item.cod_articolo",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.cod_articolo))])]}},{key:"item.cod_commessa",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.cod_commessa))])]}},{key:"item.cod_fase",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.cod_fase))])]}},{key:"item.cod_macchina",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.cod_macchina))])]}},{key:"item.quantita",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.quantita))])]}},{key:"item.pezzi_registrati",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.pezzi_registrati))])]}},{key:"item.qta_precedente",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.qta_precedente))])]}},{key:"item.des_fase",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.des_fase))])]}},{key:"item.ora_start",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.ora_start))])]}},{key:"item.ora_stop",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.ora_stop))])]}},{key:"item.ore_produzione",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.ore_produzione))])]}},{key:"item.pezzi_produzione",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.pezzi_produzione))])]}},{key:"item.pzhreale",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(item.pzhreale))])]}},{key:"item.tempo_ciclo",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.formattaCifra(item.tempo_ciclo)))])]}},{key:"item.nascondi",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickNascondi(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-eye-off ")])],1)]}}],null,true)},[_c('span',[_vm._v("Nascondi")])])]}}],null,true)}):_vm._e(),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }