<template>
  <div style="padding: 20px">
    <v-row justify="center">
      <v-col class="py-0" cols="12">
        <v-card>
          <v-data-table
            :headers="headers_anagrafiche"
            :items="items_anagrafiche"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar primary-title class="white--text rounded-t" color="indigo" dense>
                <v-toolbar-title
                  >Anagrafiche
                  {{
                    tipo_anagrafiche_visualizzate == "C"
                      ? "Clienti"
                      : "Fornitori"
                  }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mr-5"
                      @click="clicAkdd"
                      small
                    >
                      <v-icon color="primary"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Nuova Anagrafica</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mr-5"
                      @click="getAnagrafiche('C')"
                      small
                    >
                      <v-icon color="primary"> mdi-account </v-icon>
                    </v-btn>
                  </template>
                  <span>Anagrafica Clienti</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="getAnagrafiche('F')"
                      small
                    >
                      <v-icon color="primary"> mdi-truck </v-icon>
                    </v-btn>
                  </template>
                  <span>Anagrafica Fornitori</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="clickChiudi" small v-on="on" v-bind="attrs">
                      <v-icon color="error">mdi-keyboard-backspace</v-icon>
                    </v-btn>
                  </template>
                  <span>Indietro</span>
                </v-tooltip>
              </v-toolbar>
            </template>
            <!-- Bottone Stampa -->
            <template v-slot:[`item.dettagli`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="clickDettagli(item)"
                    small
                  >
                    <v-icon color="primary">mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Dettaglio Anagrafica</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dettagli_anagrafica_visible">
      <DettagliAnagrafica
        @aggiorna_anagrafica="aggiornaAnagrafica"
        @aggiungi_anagrafica="aggiungiAnagrafica"
        :anagrafica_sel="anagrafica_sel"
        @click_chiudi="dettagli_anagrafica_visible = false"
      />
    </v-dialog>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import DettagliAnagrafica from "@/components/PRODUCTION/Anagrafiche/DettagliAnagrafica.vue";

export default {
  name: "Anagrafiche",
  components: {
    DettagliAnagrafica,
  },
  data() {
    return {
      // Variabili d'appoggio
      anagrafica_sel: null,
      tipo_anagrafiche_visualizzate: "",

      // Modal
      caricamento_dati: false,
      dettagli_anagrafica_visible: false,
      // Array
      headers_anagrafiche: [
        { text: "Codice", sortable: false, value: "codice" },
        { text: "Ragione Sociale", sortable: false, value: "ragione_sociale" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "CAP", sortable: false, value: "cap" },
        { text: "Località", sortable: false, value: "localita" },
        { text: "Provincia", sortable: false, value: "provincia" },
        { text: "Regione", sortable: false, value: "regione" },
        { text: "P. IVA", sortable: false, value: "p_iva" },
        { text: "Codice Fiscale", sortable: false, value: "codice_fiscale" },
        { text: "Telefono", sortable: false, value: "telefono" },
        { text: "Dettagli", sortable: false, value: "dettagli" },
      ],
      items_anagrafiche: [
        {
          codice: 2023948,
          ragione_sociale: "Trattamenti termici srl",
          indirizzo: "Via Palestro 3",
          cap: 13039,
          localita: "Torino",
          provincia: "Torino",
          regione: "Piemonte",
          p_iva: "001212232",
          codice_fiscale: "00032929288",
          telefono: "011.373733",
          tipo: "C",
          dettagli: "asas",
        },
        {
          codice: 45435678,
          ragione_sociale: "Forniture meccaniche srl",
          indirizzo: "Via Minniti 7",
          cap: 46445,
          localita: "Novara",
          provincia: "Novara",
          regione: "Piemonte",
          p_iva: "0038476",
          codice_fiscale: "00032929288",
          telefono: "011.445468",
          tipo: "F",
          dettagli: "",
        },
      ],
      items_anagrafiche_clienti: [],
      items_anagrafiche_fornitori: [],
    };
  },
  methods: {
    aggiornaAnagrafica() {
      console.log("entrata risposta a evento aggiorna_anagrafica");
      this.dettagli_anagrafica_visible = false;
      this.getAnagrafiche(this.tipo_anagrafiche_visualizzate);
    },
    aggiungiAnagrafica(tipo_anagrafica_aggiunta) {
      // Nascondo il dialog dei dettagli anagrafica.
      this.dettagli_anagrafica_visible = false;

      /* Se ho aggiunto un'anagrafica dello stesso tipo di quelle
       * attualmente visualizzate, rifaccio la getAnagrafiche per
       * aggiornare i dati. Altrimenti tutto resta com'è. */
      if (this.tipo_anagrafiche_visualizzate == tipo_anagrafica_aggiunta) {
        this.getAnagrafiche(this.tipo_anagrafiche_visualizzate);
      }
    },
    clicAkdd() {
      this.dettagli_anagrafica_visible = true;
      this.anagrafica_sel = Object.assign(
        {},
        {
          id_anagrafica: -1,
          codice: null,
          ragione_sociale: "",
          indirizzo: "",
          cap: null,
          localita: "",
          provincia: "",
          regione: "",
          p_iva: "",
          codice_fiscale: "",
          telefono: "",
          tipo: "N",
          dettagli: "",
        }
      );
    },
    clickChiudi() {
      this.$router.push("/home/production");
    },
    clickDettagli(item) {
      this.anagrafica_sel = Object.assign({}, item);
      this.dettagli_anagrafica_visible = true;
    },
    getAnagrafiche(parametro) {
      this.tipo_anagrafiche_visualizzate = parametro;

      /* Parametro "C" -> anagrafiche clienti,
       * parametro "F" -> anagrafiche fornitori. */
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getanagrafica",
          token: localStorage.getItem("user_token"),
          tipo: parametro,
          db_name: "quickproduction",
          id_utente: localStorage.getItem("id"),
        },
      };

      this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Azzero le anagrafiche
        this.items_anagrafiche = [];

        // Popolo le anagrafiche in base alla response
        this.items_anagrafiche = res.data;
        this.caricamento_dati = false;
      });
    },
  },
  mounted() {
    this.getAnagrafiche("C");
  },
};
</script>
<style scoped>
</style>