<template>
  <div style="width:100%" class="ma-3">
    <v-toolbar class="elevation-0">
      <download-excel
        class="btn btn-default"
        :data="valoriSintetici"
        :fields="json_fields_sintetici"
        worksheet="sintesi"
        name="sintesi_questionari.xls"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="indigo"
              dark
              fab
              outlined
              small
              style="background-color:#ffffff;"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-btn>
          </template>
          <span>Scarica in formato xls</span>
        </v-tooltip>
      </download-excel>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-5"
            @click="filtraDialogVisible=!filtraDialogVisible"
            color="#546E7A"
            dark
            fab
            outlined
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filtra</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        label="Cerca nella tabella"
        single-line
        hide-details
        style="margin-bottom:25px;margin-top:10px;max-width:500px;min-width:500px;"
        v-model="search"
      ></v-text-field>
    </v-toolbar>
    <v-data-table class="elevation-1" :headers="headers" :items="valoriSintetici" :search="search">
      <template v-slot:item="row">
        <tr>
          <td style="text-align:left;">{{row.item.nome}}</td>
          <td style="text-align:left;">{{row.item.cognome}}</td>
          <td style="text-align:left;">{{row.item.titolo_questionario}}</td>
          <td style="text-align:left;">{{row.item.tipo}}</td>
          <td style="text-align:left;">
            <!--{{('0' + row.item.inizio_test.getDate()).slice(-2)}}/
            {{('0' + (row.item.inizio_test.getMonth()+1)).slice(-2)}}/
            {{row.item.inizio_test.getFullYear()}}-->
            {{row.item.inizio_test_str}}
          </td>
          <td style="text-align:left;">
            <!--{{row.item.fine_test==""?"Test in corso":('0' + row.item.inizio_test.getDate()).slice(-2)}}/
            {{('0' + (row.item.inizio_test.getMonth()+1)).slice(-2)}}/
            {{row.item.inizio_test.getFullYear()}}-->
            {{row.item.fine_test_str}}
          </td>
          <td style="text-align:left;">{{row.item.punteggioTotaleConseguito}}</td>
          <td style="text-align:left;">{{row.item.percentuale_test}}</td>
          <td>
            <v-btn
              class="mx-2"
              dark
              fab
              x-small
              color="light-blue"
              @click="mostraDettagli(row.item)"
            >
              <v-icon>mdi-file-eye-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dettagliDialogVisible" width="75%">
      <v-card style="text-align:left">
        <v-card-title style="background-color:#546E7A;color:#ffffff;">
          Dettagli
          <v-spacer></v-spacer>
          <download-excel
            class="btn btn-default mr-5"
            :data="dettagli"
            :fields="json_fields_dettagliati"
            worksheet="dettaglio"
            :name="'test_' + dettagliNome + '_' + dettagliCognome + '.xls'"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="indigo"
                  dark
                  fab
                  outlined
                  small
                  style="background-color:#ffffff;"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-excel-outline</v-icon>
                </v-btn>
              </template>
              <span>Scarica in formato xls</span>
            </v-tooltip>
          </download-excel>
          <v-btn
            class="mr-2"
            color="error"
            @click="switchDettagliDialogVisible()"
            fab
            outlined
            small
            style="background-color:#ffffff;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="margin-top:15px;">
          <table style="display:inline-block;margin-bottom:10px;">
            <thead>
              <tr>
                <th class="dettaglio">Nome:</th>
                <th class="dettaglio">Cognome:</th>
                <th class="dettaglio">Ruolo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="dettaglio">{{dettagliNome}}</td>
                <td class="dettaglio">{{dettagliCognome}}</td>
                <td class="dettaglio">{{dettagliTipo}}</td>
              </tr>
            </tbody>
          </table>
          <table style="display:inline-block;margin-bottom:10px;">
            <thead>
              <tr>
                <th class="dettaglio">Test:</th>
                <th class="dettaglio">Data, ora inizio:</th>
                <th class="dettaglio">Data, ora conclusione:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="dettaglio">{{dettagliTitolo_questionario}}</td>
                <td class="dettaglio">{{dettagliInizio_test}}</td>
                <td class="dettaglio">{{dettagliFine_test==""?"Test in corso":dettagliFine_test}}</td>
              </tr>
            </tbody>
          </table>
          <table style="display:inline-block;margin-bottom:10px;">
            <thead>
              <tr>
                <th class="dettaglio">Punteggio:</th>
                <th class="dettaglio">Punteggio (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="dettaglio">{{dettagliPunteggio}}</td>
                <td class="dettaglio">{{dettagliPercentuale_test}}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <table style="display:inline-block;margin-bottom:10px;">
              <thead>
                <tr>
                  <th class="dettaglio">Domanda:</th>
                  <th class="dettaglio">Risposta:</th>
                  <th class="dettaglio">Punteggio:</th>
                  <th class="dettaglio">Allegato:</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="(elem.id_questionario_domanda,elem.id_questionario_test_risposte)"
                  v-for="elem in dettagli"
                >
                  <td class="dettaglio">{{elem.des_questionario_domanda}}</td>
                  <td class="dettaglio">{{elem.testo_risposta}}</td>
                  <td class="dettaglio">{{elem.punteggio_risposta}}</td>
                  <td class="dettaglio">
                    <v-btn
                      class="mr-2"
                      color="light-blue"
                      @click="switchAllegatiDialogVisible(elem)"
                      fab
                      x-small
                      outlined
                      v-if="elem.nr_allegati != 0"
                    >
                      <v-icon>mdi-attachment</v-icon>
                    </v-btn>
                    <span v-else>nessun allegato</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
      <v-overlay :value="caricamento_dati2">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="allegatiDialogVisible">
      <AllegatoList
        :tipo="'QUESTIONARIO'"
        :id_rif="this.id_questionario"
        :id_rif_dettaglio="this.id_domanda"
        :famiglia="-1"
        :blnViewButtonUpload="false"
        :blnViewButtonDelete="false"
        :strPath="'root'"
      ></AllegatoList>
    </v-dialog>
    <v-dialog persistent v-model="filtraDialogVisible" width="75%">
      <v-card class="text-center">
        <v-card-title style="background-color:#546E7A;color:#ffffff;">
          <div>Filtra i questionari</div>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 my-2"
            color="error"
            @click="filtraDialogVisible=!filtraDialogVisible"
            fab
            outlined
            small
            style="background-color:#ffffff;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <form class="px-10">
          <v-row>
            <v-col cols="4">
              <v-text-field
                id="inputFiltraDa"
                label="Iniziati dalla data"
                type="date"
                v-model="inputFiltraDa"
              ></v-text-field>
              {{inputFiltraDa}}
            </v-col>
            <v-col cols="4">
              <v-text-field
                id="inputFiltraA"
                label="Iniziati entro la data"
                type="date"
                v-model="inputFiltraA"
              ></v-text-field>
              {{inputFiltraA}}
            </v-col>
            <v-col cols="4">
              <v-text-field
                id="inputFiltraTitolo"
                label="Titolo questionario"
                type="text"
                v-model="inputFiltraTitolo"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn class="mr-2 my-2" color="#546E7A" @click="filtra()" dark small>Filtra</v-btn>
          <v-btn class="mr-2 my-2" color="#546E7A" @click="ripristina()" dark small>Ripristina</v-btn>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AllegatoList from "../../components/CHECK/Allegato/AllegatoList.vue";

export default {
  components: {
    AllegatoList,
  },
  data() {
    return {
      // Filtro
      filtraDialogVisible: false,
      inputFiltraA: "",
      inputFiltraDa: "",
      inputFiltraTitolo: "",
      // Dati
      allegatiDialogVisible: false,
      caricamento_dati: true,
      caricamento_dati2: false,
      dateStringFine_test: null,
      dateStringInizio_test: null,
      dettagliFineStampa: null,
      dettagliInizioStampa: null,
      dettagliDialogVisible: false,
      dettagliNome: null,
      dettagliCognome: null,
      dettagliTitolo_questionario: null,
      dettagliTipo: null,
      dettagliInizio_test: null,
      dettagliFine_test: null,
      dettagliPunteggio: null,
      dettagliPercentuale_test: null,
      id_domanda: null,
      id_questionario: null,
      json_fields_dettagliati: {
        Domanda: "des_questionario_domanda",
        Risposta: "testo_risposta",
        Punteggio: "punteggio_risposta",
      },
      json_fields_sintetici: {
        "Id test": "id_test",
        Tipo: "tipo",
        Nome: "nome",
        Cognome: "cognome",
        "Data questionario": "data_questionario",
        Titolo: "titolo_questionario",
        "Inizio test": "inizio_test",
        "Fine test": "fine_test",
        Punteggio: "punteggioTotaleConseguito",
        "Punteggio (%)": "percentuale_test",
      },
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "nome",
        },
        { text: "Cognome", value: "cognome" },
        { text: "Test", value: "titolo_questionario" },
        { text: "Ruolo", value: "tipo" },
        { text: "Iniziato", value: "inizio_test" },
        { text: "Terminato", value: "fine_test" },
        { text: "Punteggio", value: "punteggio" },
        { text: "Punteggio (%)", value: "percentuale_test" },
        { text: "Dettagli", value: "" },
      ],
      search: "",
      valoriDettagliati: [],
      valoriSintetici: [],
      dettagli: [],
    };
  },
  watch: {
    filtraDialogVisible: function () {
      this.inputFiltraA = "";
      this.inputFiltraDa = "";
      this.inputFiltraTitolo = "";
    },
  },
  methods: {
    // Filtro
    filtra() {
      if (this.inputFiltraDa != "") {
        let data_tmp = new Date(this.inputFiltraDa);
        let valoriSinteticiAggiornati = this.valoriSintetici.filter(
          (elem) => elem.inizio_test_js >= data_tmp
        );
        this.valoriSintetici = valoriSinteticiAggiornati;
      }
      if (this.inputFiltraA != "") {
        let data_tmp = new Date(this.inputFiltraA);
        let valoriSinteticiAggiornati = this.valoriSintetici.filter(
          (elem) => elem.inizio_test <= data_tmp
        );
        this.valoriSintetici = valoriSinteticiAggiornati;
      }
      if (this.inputFiltraTitolo != "") {
        let valoriSinteticiAggiornati = this.valoriSintetici.filter((elem) =>
          elem.titolo_questionario.includes(this.inputFiltraTitolo)
        );
        this.valoriSintetici = valoriSinteticiAggiornati;
      }
    },
    ripristina() {
      this.valoriSintetici = [];
      this.getRiepilogoSintetico();
      this.filtraDialogVisible == true ? false : true;
    },
    // Visualizzazione dati
    chiudiDettagli() {
      this.switchDettagliDialogVisible();
      this.dettagliNome = null;
      this.dettagliCognome = null;
      this.dettagliTitolo_questionario = null;
      this.dettagliTipo = null;
      this.dettagliInizio_test = null;
      this.dettagliInizioStampa = null;
      this.dettagliFine_test = null;
      this.dettagliFineStampa = null;
      this.dettagliPunteggio = null;
      this.dettagliPercentuale_test = null;
      this.dettagli = [];
    },
    getRiepilogoDettagliato() {
      this.caricamento_dati2 = true;
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getriepilogo",
          db_name: "medical",
          id_utente: localStorage.getItem("id"),
          tipo: "D",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.dettagli = [];
        //console.log("getRiepilogoDettagliato request", JSON.stringify(request));
        //console.log("getRiepilogoDettagliato response:", JSON.stringify(res));
        res.data.forEach((elem) => {
          if (
            elem.nome == this.dettagliNome &&
            elem.cognome == this.dettagliCognome &&
            elem.titolo_questionario == this.dettagliTitolo_questionario &&
            elem.inizio_test == this.dettagliInizio_test &&
            elem.fine_test == this.dettagliFine_test
          ) {
            this.dettagli.push(elem);
          }
        });
        //console.log(this.valori);
        //console.log(JSON.stringify(this.dettagli));
        this.caricamento_dati2 = false;
      });
    },
    getRiepilogoSintetico() {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getriepilogo",
          db_name: "medical",
          id_utente: localStorage.getItem("id"),
          tipo: "S",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        //console.log("getriepilogo s request",JSON.stringify(request));
        //console.log("getriepilogo s response", JSON.stringify(res));
        this.valoriDettagliati = [];
        this.valoriSintetici = [];

        if (res.data.length > 0) {
          this.valoriDettagliati = this.alasql(
            "SELECT *, SUM(maxPunteggio) as punteggioTotaleTest, SUM(punteggio_risposta) as punteggioTotaleConseguito, CONCAT(((SUM(punteggio_risposta) * 100) / SUM(maxPunteggio)),'%') AS percentuale_test FROM ? GROUP BY id_test",
            [res.data]
          );

          /*res.data.forEach(elem => {
            this.valoriDettagliati.push(elem);
          });*/

          /* Controllo che l'elemento non sia un doppione
           se non è un doppione lo aggiungo all'elenco sintetico. */
          for (let i = 0; i < this.valoriDettagliati.length; i++) {
            let ricorre = false;

            // Qui controllo se ricorre
            for (let j = 0; j < this.valoriSintetici.length; j++) {
              if (
                this.valoriDettagliati[i].nome ==
                  this.valoriSintetici[j].nome &&
                this.valoriDettagliati[i].cognome ==
                  this.valoriSintetici[j].cognome &&
                this.valoriDettagliati[i].titolo_questionario ==
                  this.valoriSintetici[j].titolo_questionario &&
                this.valoriDettagliati[i].inizio_test ==
                  this.valoriSintetici[j].inizio_test &&
                this.valoriDettagliati[i].fine_test ==
                  this.valoriSintetici[j].fine_test
              ) {
                ricorre = true;
              }
            }
            if (ricorre == false) {
              this.valoriSintetici.push(this.valoriDettagliati[i]);
            }
          }

          if (this.valoriSintetici.length > 0) {
            //console.log(this.valoriSintetici.length);
            /* Le date che mi vengono restituite dal backend devono essere ricostruite per essere
             * stampate o filtrate. Qui prendo il tempo-Luciano e costruisco un tempo-JS */
            for (let i = 0; i < this.valoriSintetici.length; i++) {
              this.valoriSintetici[i].inizio_test_js = new Date(
                this.valoriSintetici[i].inizio_test.substring(6, 10) +
                  "-" +
                  this.valoriSintetici[i].inizio_test.substring(3, 5) +
                  "-" +
                  this.valoriSintetici[i].inizio_test.substring(0, 2) +
                  "T" +
                  this.valoriSintetici[i].inizio_test.substring(11, 19)
              );
              this.valoriSintetici[i].fine_test_js = new Date(
                this.valoriSintetici[i].fine_test.substring(6, 10) +
                  "-" +
                  this.valoriSintetici[i].fine_test.substring(3, 5) +
                  "-" +
                  this.valoriSintetici[i].fine_test.substring(0, 2) +
                  "T" +
                  this.valoriSintetici[i].fine_test.substring(11, 19)
              );

              // Costruisco un tempo-stringa da stampare
              this.valoriSintetici[i].inizio_test_str = this.valoriSintetici[
                i
              ].inizio_test.substring(0, 10);
              this.valoriSintetici[i].fine_test_str = this.valoriSintetici[
                i
              ].fine_test.substring(0, 10);
            }
          }
        }
        // Faccio sparire il simbolo di caricamento
        this.caricamento_dati = false;
      });
    },
    mostraDettagli(elem) {
      this.dettagliNome = elem.nome;
      this.dettagliCognome = elem.cognome;
      this.dettagliTitolo_questionario = elem.titolo_questionario;
      this.dettagliTipo = elem.tipo;
      this.dettagliInizio_test = elem.inizio_test;
      this.dettagliFine_test = elem.fine_test;
      this.dettagliPunteggio = elem.punteggioTotaleConseguito;
      this.dettagliPercentuale_test = elem.percentuale_test;
      this.getRiepilogoDettagliato();
      this.switchDettagliDialogVisible();
    },
    switchAllegatiDialogVisible(elem) {
      this.id_domanda = elem.id_questionario_domanda;
      this.id_questionario = elem.id_questionario_test;

      this.allegatiDialogVisible =
        this.allegatiDialogVisible == true ? false : true;
    },
    switchDettagliDialogVisible() {
      this.dettagliDialogVisible =
        this.dettagliDialogVisible == true ? false : true;
    },
  },
  mounted() {
    this.getRiepilogoSintetico();
  },
};
</script>
<style>
.dettaglio {
  padding-left: 20px;
  text-align: left;
}
</style>