<template>
  <v-card v-if="infoFile != null">
    <v-toolbar class="indigo white--text" dense primary-title>
      <v-toolbar-title>File: {{ infoFile.nome_file }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="chiudiVisualizzaPdf()"
            small
          >
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex justify-start">
            <v-tabs vertical v-if="infoFile.ordini_categoria != undefined">
              <v-tab
                v-for="(oggetto, i) in infoFile.ordini_categoria
                  .map((item) => item.nome_categoria)
                  .filter(
                    (value, index, categoryArray) =>
                      categoryArray.indexOf(value) === index
                  )"
                :key="i"
                class="d-flex justify-start"
              >
                {{ oggetto.replaceAll("_", " ") }}
              </v-tab>

              <v-tab-item
                v-for="(oggetto, i) in infoFile.ordini_categoria
                  .map((item) => item.nome_categoria)
                  .filter(
                    (value, index, categoryArray) =>
                      categoryArray.indexOf(value) === index
                  )"
                :key="i"
              >
                <v-card flat>
                  <v-card-text v-if="oggetto == 'DECORI'">
                    <!-- {{
                          infoFile.ordini_categoria.filter(
                            (el) => el.nome_categoria == oggetto
                          )
                        }} -->
                    <v-data-table
                      :headers="[
                        {
                          text: 'Elemento',
                          value: 'elemento',
                        },
                      ]"
                      :items="
                        infoFile.ordini_categoria.filter(
                          (el) =>
                            el.nome_categoria == oggetto &&
                            el.id_categoria_padre == null
                        )
                      "
                      hide-default-footer
                      hide-default-header
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      item-key="elemento"
                      show-expand
                      class="elevation-1"
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td
                          class="pa-0"
                          style="background-color: white"
                          :colspan="headers.length"
                        >
                          <v-data-table
                            class="elevation-3"
                            :headers="[
                              { text: 'Codice', value: 'codice' },
                              {
                                text: 'Elemento',
                                value: 'elemento',
                              },
                              { text: 'Descrizione', value: 'des' },
                              { text: 'Informazioni', value: 'info' },
                              { text: 'Quantita', value: 'quantita' },
                            ]"
                            :items="
                              infoFile.ordini_categoria.filter(
                                (el) =>
                                  el.nome_categoria == oggetto &&
                                  el.id_categoria_padre ==
                                    item.id_ordine_categoria
                              )
                            "
                          >
                          </v-data-table>
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-text v-else-if="oggetto == 'APPROVAZIONI'">
                    <v-data-table
                      :headers="[
                        { text: 'Elemento', value: 'elemento' },
                        {
                          text: 'Data',
                          value: 'data',
                        },
                        { text: 'Firma', value: 'firma' },
                      ]"
                      :items="
                        infoFile.ordini_categoria.filter(
                          (el) => el.nome_categoria == oggetto
                        )
                      "
                      class="elevation-1"
                    ></v-data-table>
                  </v-card-text>
                  <v-card-text
                    v-else-if="oggetto == 'ACCESSORI_NON_A_LISTINO_E_MODIFICHE'"
                  >
                    <v-data-table
                      :headers="[
                        { text: 'Codice', value: 'codice' },
                        {
                          text: 'Elemento',
                          value: 'elemento',
                        },
                        { text: 'Informazioni', value: 'info' },
                      ]"
                      :items="
                        infoFile.ordini_categoria.filter(
                          (el) => el.nome_categoria == oggetto
                        )
                      "
                      class="elevation-1"
                    ></v-data-table>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-data-table
                      :headers="[
                        { text: 'Codice', value: 'codice' },
                        {
                          text: 'Elemento',
                          value: 'elemento',
                        },
                        { text: 'Informazioni', value: 'info' },
                        { text: 'Quantita', value: 'quantita' },
                      ]"
                      :items="
                        infoFile.ordini_categoria.filter(
                          (el) => el.nome_categoria == oggetto
                        )
                      "
                      class="elevation-1"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "VisualizzaFilePdf",
  components: {
  },
  props: {},
  data() {
    return {
      // Variabili di supporto

      // Array
      expanded: [],
      infoFile: [],

      // Modal
      singleExpand: true,
    };
  },
  mounted() {},
  methods: {
    chiudiVisualizzaPdf() {
      /* Questo metodo è usato per chiudere il dialog di comparazione
       * fra i documenti che deviniscono due diverse versioni di configurazione.
       * Quando si chiude il dialog diventa invisibile e le variabili di supporto
       * interessate devono essere resettate. */
      this.$emit("chiudi_dialog");
    },
  },
};
</script>