var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3","id":"listener_consuntivi_massimaleMateriale"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"destinazione":'listener_consuntivi_massimaleMateriale',"label":'Massimale',"id":'3'}})],1),_c('v-col',{attrs:{"cols":"3","id":"listener_consuntivoMateriale"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"disabled":true,"destinazione":'listener_consuntivoMateriale',"label":'Consuntivo',"id":'22'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 mx-5",attrs:{"headers":_vm.headers_materiale,"items":_vm.items_materiale.filter(function (elem) { return elem.validita === 1; })},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Spese per Materiali ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.aggiungiMateriale}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Materiale")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaMateriale(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])])]}},{key:"item.elimina",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickElimina(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.modifica_materiale_dialog),callback:function ($$v) {_vm.modifica_materiale_dialog=$$v},expression:"modifica_materiale_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Modifica Materiale")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaModificaMateriale}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiModificaMateriale}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Descrizione","max-width":"500px"},model:{value:(_vm.materiale_sel.des_materiale),callback:function ($$v) {_vm.$set(_vm.materiale_sel, "des_materiale", $$v)},expression:"materiale_sel.des_materiale"}})],1),_c('v-col',{attrs:{"cols":"3","id":"listener_importoMateriale"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"destinazione":'listener_importoMateriale',"label":'Importo (€)',"id":'9'}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }