<template>
    <GraphDialog ref="dialog" :title="selected.name" >
        <div class="graph_folder_viewer">
            <GraphAppItem v-for="(item,i) in selected.data" :key="i" :data="item" :in_folder="true"
                @remove="remove($event)"
                @delete_ds="$emit('delete_ds',$event)"
                @edit_ds="$emit('edit_ds',$event)"
                @click_ds="$emit('click_ds',$event)"
            />
        </div>
    </GraphDialog>
</template>
<script>
import GraphDialog from "@/components/GRAPH/.common/Dialog/GraphDialog.vue";
import GraphAppItem from "../AppItem/GraphAppItem.vue";
export default {
    name : 'GraphFolderViewer',
    components : {
        GraphDialog,
        GraphAppItem
    },
    props : {
        items : Array()
    },
    data(){
        return{
            selected : { }
        }
    },
    methods : {
        open(item){
            this.selected = item;
            this.$refs.dialog.display();
        },
        remove(item){
            this.selected.data.splice(this.selected.data.indexOf(item),1);
            this.items.push(item);
        }
    }

}
</script>
<style scoped>
    .graph_folder_viewer{
        position: relative;
        width:600px;
        text-align: center;        
    }
</style>