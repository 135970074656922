<template>
  <div class="gestione_aziende" style="margin: 20px">
    <v-card elevation="0">
      <v-toolbar dense color="primary" dark align="center">
        <v-row>
          <v-col cols="5" align="left">
            <v-toolbar-title></v-toolbar-title>
          </v-col>
          <v-col cols="1">
            <v-btn small light @click="setAziende">
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn small light @click="mostraDialog(null)">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4" align="right">

          </v-col>
          <v-col cols="1" align="right">
            <v-btn small light @click="getAziende">
              <v-icon color="primary">mdi-autorenew</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container>
        <v-data-table
          :headers="tabella.testata"
          :items="tabella.dati"
          class="gestione_aziende"
          
        >
          <!-- BOTTONE MODIFICA -->
          <template v-slot:[`item.modifica_btn`]="{ item }">
            <v-btn
              small
              :disabled="item.validita != 1"
              @click="mostraDialog(item)"
            >
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- BOTTONE VALIDITA -->
          <template v-slot:[`item.rimuovi_btn`]="{ item }">
            <v-btn small @click="toggleValidita(item)">
              <v-icon :color="item.validita == 1 ? 'error' : 'primary'">{{
                item.validita == 1 ? "mdi-trash-can-outline" : "mdi-restore"
              }}</v-icon>
            </v-btn>
          </template>
          <!-- IMMAGINE -->
          <template v-slot:[`item.img_azienda`]="{ item }">
            <v-icon v-if="item.img_azienda == null">mdi-image-off</v-icon>
            <v-img
              style="margin: 0 auto; display: block"
              max-width="24"
              v-if="item.img_azienda != null"
              :src="item.img_azienda"
            />
          </template>
          <!-- COLONNA ATTIVO -->
          <template v-slot:[`item.attivo`]="{ item }">
            <v-icon :color="item.attivo == 1 ? 'success' : 'error'">{{
              item.attivo == 1 ? "mdi-check" : "mdi-close"
            }}</v-icon>
          </template>
        </v-data-table>
      </v-container>
    </v-card>


    <v-dialog persistent v-model="dialog.mostra">
      <v-card>
        <v-toolbar dense color="primary" dark>
          <v-row align="center" dark>
            <v-col cols="5" align="left">
              <v-toolbar-title>Modifica Azienda</v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-btn small light @click="salvaDialog">
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="5" align="right">
              <v-btn small light @click="dialog.mostra = false">
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-container>
          <v-form ref="form">
            <v-row align="center">
              <v-col cols="3">
                <v-text-field
                  label="Ragione Sociale"
                  v-model="dialog.buffer.ragione_sociale"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Partita IVA"
                  v-model="dialog.buffer.partita_iva"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Nome Database"
                  v-model="dialog.buffer.nome_database"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Sottodominio"
                  v-model="dialog.buffer.sottodominio"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-text-field
                  label="Prefisso Utente"
                  v-model="dialog.buffer.prefisso_utente"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Codice Azienda"
                  v-model="dialog.buffer.cod_azienda"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  type="number"
                  min="0"
                  label="ID Azienda Rif."
                  v-model="dialog.buffer.id_azienda_rif"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  type="number"
                  min="0"
                  label="ID Agente"
                  v-model="dialog.buffer.id_agente"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Codice ISO"
                  v-model="dialog.buffer.cod_iso"
                />
              </v-col>
              <v-col cols="2">
                <v-checkbox label="Attivo" v-model="dialog.buffer.attivo" />
              </v-col>
            </v-row>
            <v-divider />
            <v-row align="center">
              <v-col cols="4">
                <v-text-field label="Titolo" v-model="dialog.buffer.titolo" />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Versione Software"
                  v-model="dialog.buffer.sw_ver"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field label="Licenza" v-model="dialog.buffer.license" />
              </v-col>
            </v-row>
            <v-divider />
            <v-row align="center">
              <v-col cols="4">
                <v-textarea
                  no-resize
                  rows="3"
                  label="Testo Azienda"
                  v-model="dialog.buffer.testo_azienda"
                />
              </v-col>
              <v-col cols="4">
                <v-textarea
                  no-resize
                  rows="3"
                  label="Testo Privacy"
                  v-model="dialog.buffer.testo_privacy"
                />
              </v-col>
              <v-col cols="4">
                <v-textarea
                  no-resize
                  rows="3"
                  label="Testo Termini Uso"
                  v-model="dialog.buffer.testo_termini_uso"
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row v-if="dialog.buffer.img_azienda != null" align="center">
              <v-col cols="1" />
              <v-col cols="5" align="right">
                <v-img :src="dialog.buffer.img_azienda" max-width="250" />
              </v-col>
              <v-col cols="5" align="left">
                <v-row>
                  <v-btn
                    width="200"
                    class="mb-10"
                    @click="dialog.buffer.img_azienda = null"
                    >Rimuovi Immagine</v-btn
                  >
                </v-row>
                <v-row>
                  <v-btn width="200" color="primary" @click="uploadAndCompress"
                    >Cambia Immagine</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" v-if="dialog.buffer.img_azienda == null">
              <v-col cols="12">
                <v-btn
                  class="mt-2"
                  width="200"
                  text
                  color="primary"
                  @click="uploadAndCompress"
                  >Carica Immagine Azienda</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    
    <v-snackbar dark :color="(alert.error==true)?'error':'light-green darken-2'" v-model="alert.show">
      {{alert.message}}
      <template v-slot:action="{attrs}">
        <v-btn v-bind="attrs" text @click="alert.show=false">Chiudi</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "GestioneAziende",
  data() {
    return {
      alert : {
        show : false,
        message : null,
        error : false
      },
      dialog: {
        buffer: {
          id_azienda: null,
          id_azienda_rif: null, //
          id_agente: null, //
          attivo: null, //
          ragione_sociale: null, //
          prefisso_utente: null, //
          nome_database: null, //
          cod_azienda: null, //
          cod_iso: null, //
          partita_iva: null, //
          license: null, //
          sw_ver: null, //
          testo_azienda: null,
          titolo: null, //
          testo_privacy: null,
          testo_termini_uso: null,
          img_azienda: null,
          validita: 1,
          sottodominio : null,
        },
        indice: -1,
        mostra: false,
      },
      NETWORK: null,
      tabella: {
        testata: [
          { text: null, value: "modifica_btn", sortable: false, align: "left" },
          { value: "ragione_sociale", text: "Ragione Sociale" },
          { value: "prefisso_utente", text: "Prefisso Utente" },
          { value: "nome_database", text: "Nome DB" },
          { value: "cod_azienda", text: "Codice Azienda" },
          { value: "testo_azienda", text: "Testo Azienda" },
          { value: "titolo", text: "Titolo" },
          { text: null, value: "rimuovi_btn", sortable: false, align: "right" },
        ],
        dati: [],
      },
    };
  },
  mounted() {
    this.NETWORK = this.$store.state.Network;
    this.getAziende();
  },
  methods: {
    toggleValidita(riga) {
      riga.validita = riga.validita == 0 ? 1 : 0;
    },
    getAziende() {
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "getAzienda",
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (response) {that.tabella.dati = response.azienda;},
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    mostraDialog(riga) {
      this.dialog.mostra = true;
      let indice = this.tabella.dati.indexOf(riga);
      this.dialog.indice = indice;
      let campi = Object.keys(this.dialog.buffer);
      for (let i = 0; i < campi.length; i++) {
        this.dialog.buffer[campi[i]] = indice < 0 ? null : riga[campi[i]];
        if (campi[i] == "attivo" && indice >= 0) {
          this.dialog.buffer[campi[i]] = riga[campi[i]] == 1;
        }
      }
      let that = this;
      this.$nextTick(function () {
        that.$refs.form.resetValidation();
      });
    },
    salvaDialog() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.dialog.indice < 0) {
        let clone = Object.assign({}, this.dialog.buffer);
        clone.validita = 1;
        clone.id_azienda = -1;
        clone.attivo = clone.attivo == 1 ? 1 : 0;
        clone.id_personale = -1;
        this.tabella.dati.push(clone);
      } else {
        let campi = Object.keys(this.dialog.buffer);
        for (let i = 0; i < campi.length; i++) {
          this.tabella.dati[this.dialog.indice][campi[i]] = this.dialog.buffer[
            campi[i]
          ];
          if (campi[i] == "attivo") {
            this.tabella.dati[this.dialog.indice][campi[i]] =
              this.dialog.buffer[campi[i]] == true ? 1 : 0;
          }
        }
      }
      this.dialog.mostra = false;
    },

    setAziende() {
      let that = this;
      let request = {
        controller: "Modulo",
        body: {
          action: "setAzienda",
          token: localStorage.user_token,
          azienda: this.tabella.dati,
        },
        method: "PUT",
        on: {
          load: function () {
            that.getAziende();
            that.alert = {
              message : 'Dati salvati con successo',
              show : true,
              error : false
            }
          },
          error: function(){
            that.alert = {
              message : 'Errore durante il salvataggio',
              show : true,
              error : true
            }
          }
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
    deleteToggle(index) {
      if (this.aziende[index].validita == 0) {
        this.aziende[index].validita = 1;
      } else {
        if (this.aziende[index].id_azienda == -1) {
          this.aziende.splice(index, 1);
          return;
        }
        this.aziende[index].validita = 0;
      }
    },
    showDialog(index_sel, field_sel, field_type) {
      this.selected = { index: index_sel, field: field_sel, type: field_type };
      this.dialog.show = true;
    },
    uploadAndCompress() {
      let that = this;
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/png, image/jpeg");
      input.onchange = function () {
        let reader = new FileReader();
        reader.onload = function () {
          let img = new Image();
          img.onload = function () {
            let width, height;
            let ratio = img.width / img.height;
            if (img.width > img.height) {
              width = Math.min(500, img.width);
              height = width / ratio;
            } else {
              height = Math.min(500, img.height);
              width = height * ratio;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            that.dialog.buffer.img_azienda = ctx.canvas.toDataURL();

            canvas = null;
            img = null;
            reader = null;
            input = null;
          };
          img.src = reader.result;
        };
        reader.readAsDataURL(input.files[0]);
      };
      input.click();
    },
  },
};
</script>
<style >
.gestione_aziende th,
.gestione_aziende td {
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>