<template>
    <ListaPicking class="pa-5" style="border-radius:0px;" />
</template>
<script>
import ListaPicking from "@/components/ELECTRICALMARINE/ListaPicking.vue";
export default {
  name: "EMListaPicking",
  components: {
      ListaPicking,
  },
  props: {},
  data() {
    return {
        // Variabili d'appoggio
        // Modal
        // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {

  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>