/* Questa classe fornisce l'oggetto di riferimento
 * per le Anagrafiche all'interno del modulo
 * Manutenzione per EduCatt e altri. */
export default class Request {
    constructor(arg) {
        if (arg != undefined) {
            this.ambiti_manutentivi = arg.ambiti_manutentivi === undefined ? [] : arg.ambiti_manutentivi;
            this.anagrafica_contatti = arg.anagrafica_contatti === undefined ? [] : arg.anagrafica_contatti;
            this.tipologie = arg.tipologie === undefined ? [] : arg.tipologie;
            this.cap = arg.cap === undefined ? null : arg.cap;
            this.codice = arg.codice === undefined ? "" : arg.codice;
            this.codice_fiscale = arg.codice_fiscale === undefined ? "" : arg.codice_fiscale;
            this.dettagli = arg.dettagli === undefined ? "" : arg.dettagli;
            this.id_anagrafica = arg.id_anagrafica === undefined ? -1 : arg.id_anagrafica;
            this.id_localita = arg.id_localita === undefined ? null : arg.id_localita;
            this.id_nazione = arg.id_nazione === undefined ? null : arg.id_nazione;
            this.id_regione = arg.id_regione === undefined ? -1 : arg.id_regione;
            this.id_provincia = arg.id_provincia === undefined ? null : arg.id_provincia;
            this.indirizzo = arg.indirizzo === undefined ? "" : arg.indirizzo;
            this.des_localita = arg.des_localita === undefined ? "" : arg.des_localita;
            this.des_nazione = arg.des_nazione === undefined ? "" : arg.des_nazione;
            this.des_regione = arg.des_regione === undefined ? "" : arg.des_regione;
            this.des_provincia = arg.des_provincia === undefined ? "" : arg.des_provincia;
            this.p_iva = arg.p_iva === undefined ? "" : arg.p_iva;
            this.p_iva = arg.p_iva_mini === undefined ? "" : arg.p_iva_mini;
            this.ragione_sociale = arg.ragione_sociale === undefined ? "" : arg.ragione_sociale;
            this.sede_operativa = arg.sede_operativa === undefined ? "" : arg.sede_operativa;
            this.SDI = arg.SDI === undefined ? "" : arg.SDI;
            this.telefono = arg.telefono === undefined ? "" : arg.telefono;
            this.tipo = arg.tipo === undefined ? "FORNITORE" : arg.tipo;
            this.sigla_rea = arg.sigla_rea === undefined ? "" : arg.sigla_rea;
            this.codice_rea = arg.codice_rea === undefined ? "" : arg.codice_rea;
            this.email = arg.email === undefined ? "" : arg.email;
        } else {
            this.ambiti_manutentivi = [];
            this.anagrafica_contatti = [];
            this.tipologie = [];
            this.cap = null;
            this.codice = "";
            this.codice_fiscale = "";
            this.dettagli = "";
            this.id_anagrafica = -1;
            this.id_localita = null;
            this.id_nazione = null;
            this.id_regione = null;
            this.id_provincia = null ;
            this.indirizzo = "";
            this.des_localita = "";
            this.des_nazione = "";
            this.des_regione = "";
            this.des_provincia = "";
            this.p_iva = "";
            this.p_iva_mini = "";
            this.ragione_sociale = "";
            this.sede_operativa = "";
            this.SDI = "";
            this.telefono = "";
            this.tipo = "FORNITORE";
            this.codice_rea = "";
            this.email = "";
            this.sigla_rea = "";
        }
    }
}