<template>
  <v-card max-height="87vh" min-height="87vh" style="overflow: auto">
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text">Distinta Base</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Bottone di generazione commessa multipla -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="conferma_commessa_multipla = true"
            :disabled="
              items_distinta_base[0]
                ? items_distinta_base[0].id_commessa != undefined
                : true
            "
            small
            v-bind="attrs"
            v-if="
              cod_profilo === 'capofficina' ||
                      cod_profilo === 'manager' ||
              cod_profilo === 'CNXBOX' ||
              cod_profilo === 'Admin' ||
              cod_profilo === 'SVIL'
            "
            v-on="on"
          >
            <v-icon color="primary"> mdi-expand-all </v-icon>
          </v-btn>
        </template>
        <span>Genera Commessa Multipla</span>
      </v-tooltip>

      <!-- Bottone di copia dell'articolo -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="clickDuplicaArticolo"
            :disabled="items_distinta_base.length === 0"
            small
            v-bind="attrs"
            v-on="on"
            v-if="
              cod_profilo === 'capofficina' ||
                      cod_profilo === 'manager' ||
              cod_profilo === 'CNXBOX' ||
              cod_profilo === 'Admin' ||
              cod_profilo === 'SVIL'
            "
          >
            <v-icon color="primary">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Duplica Articolo</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goBack" small v-bind="attrs" v-on="on">
            <v-icon color="error"> mdi-keyboard-backspace </v-icon>
          </v-btn>
        </template>
        <span>Indietro</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-2 pa-0">
      <div style="d-flex" v-show="search_bar_visible">
        <!-- Riga dei filtri -->
        <v-row align="center" class="ma-0 px-5 py-0" justify="center">
          <!-- Filtro del cod_articolo-->
          <v-col align="center" cols="2" justify="center">
            <v-text-field
              @keydown="handleKeydown($event, 'getArticoli')"
              label="Cod. Articolo"
              v-model="cod_articolo_sel"
            >
              <template v-slot:append>
                <v-btn
                  @click="cod_articolo_sel = ''"
                  icon
                  v-show="cod_articolo_sel != ''"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>

          <v-col align="center" cols="1" justify="center">
            <!-- Bottone di ricerca -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="getArticoli" small v-bind="attrs" v-on="on">
                  <v-icon color="primary"> mdi-magnify </v-icon>
                </v-btn>
              </template>
              <span>Cerca Articolo</span>
            </v-tooltip>
          </v-col>

          <!-- Filtro del tipo famiglia -->
          <v-col align="center" cols="3" justify="center">
            <v-autocomplete
              :items="items_tipo_famiglia"
              label="Tipo Famiglia"
              item-text="des"
              item-value="id_tabella"
              v-model="tipo_famiglia_sel"
            >
              <template v-slot:append>
                <v-btn
                  @click="tipo_famiglia_sel = null"
                  icon
                  v-show="tipo_famiglia_sel != null"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>

          <!-- Filtro Numero Ordine (des_aggiuntiva_articolo) -->
          <v-col align="center" cols="3" justify="center">
            <v-text-field
              label="Numero d'Ordine"
              v-model="des_aggiuntiva_articolo_sel"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-divider
        style="mt-4 mb-3 mx-10"
        v-show="search_bar_visible"
      ></v-divider>

      <!-- Inizio della treeview -->
      <v-treeview
        activatable
        shaped
        hoverable
        :open.sync="open"
        :items="items_distinta_base"
        item-key="id_tmp"
        content="6"
      >
        <template v-slot:prepend="{ item }">
          <b class="mr-2">{{ item.indice }}:</b>

          <v-btn
            :color="
              item.id_stato === id_stato_commessa_chiusa
                ? 'success'
                : item.id_stato === id_stato_commessa_aperta
                ? 'orange'
                : 'error'
            "
            class="mr-5"
            fab
            small
            v-if="item.id_commessa != undefined"
          >
            <v-icon color="white">
              {{
                item.id_stato === id_stato_commessa_chiusa
                  ? "check"
                  : item.id_stato === id_stato_commessa_aperta
                  ? "mdi-play-speed"
                  : "mdi-close"
              }}
            </v-icon>
          </v-btn>

          <template v-if="item.id_articolo >= -1">
            {{
              item.id_commessa
                ? `Q.tà: ${item.quantita}`
                : `n° ${item.quantita}`
            }}
            {{ item.cod_articolo ? `${item.cod_articolo} ― ` : "" }}
            {{
              item.cod_commessa
                ? `${item.cod_commessa} ― ${
                    item.des_commessa ? item.des_commessa : ""
                  }`
                : ""
            }}
            {{ item.des_articolo ? item.des_articolo : "" }}
            {{ item.note ? item.note : "" }}
          </template>

          <!-- Pulsante dell'aggiunta di componenti alla distinta base -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5"
                @click="inserisciComponente(item)"
                small
                v-bind="attrs"
                v-on="on"
                v-if="
                  (cod_profilo === 'capofficina' ||
                      cod_profilo === 'manager' ||
                    cod_profilo === 'CNXBOX' ||
                    cod_profilo === 'Admin' ||
                    cod_profilo === 'SVIL') &&
                  ((item.children && item.children.length > 0) ||
                    !item.cod_commessa)
                "
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Inserisci Componente</span>
          </v-tooltip>
        </template>

        <template
          style="background-color: red"
          slot="append"
          slot-scope="{ item }"
        >
          <div
            class="mr-5"
            style="display: inline-block; height: 28px; width: 50px"
          >
            <!-- Pulsante degli allegati -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickGestioneAllegati(item)"
                  small
                  v-if="
                    (cod_profilo === 'capofficina' ||
                      cod_profilo === 'addetto_produzione' ||
                      cod_profilo === 'manager' ||
                      cod_profilo === 'CNXBOX' ||
                      cod_profilo === 'Admin' ||
                      cod_profilo === 'SVIL') &&
                    item.allegato != 0
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary"> mdi-paperclip </v-icon>
                </v-btn>
              </template>
              <span>Visiona Allegati</span>
            </v-tooltip>
          </div>

          <!-- Pulsante delle specifiche -->
          <div
            class="mr-5"
            style="display: inline-block; height: 28px; width: 50px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickSpecifiche(item)"
                  :color="
                    item.view_disegno == 1 || item.id_disegno == null
                      ? ''
                      : 'error'
                  "
                  small
                  v-bind="attrs"
                  v-if="
                    (cod_profilo === 'capofficina' ||
                      cod_profilo === 'addetto_produzione' ||
                      cod_profilo === 'manager' ||
                      cod_profilo === 'CNXBOX' ||
                      cod_profilo === 'Admin' ||
                      cod_profilo === 'SVIL') &&
                    item.id_disegno != 0 &&
                    item.id_disegno != null
                  "
                  v-on="on"
                >
                  <v-icon
                    :color="
                      item.view_disegno == 1 || item.id_disegno == null
                        ? 'primary'
                        : 'white'
                    "
                  >
                    mdi-script
                  </v-icon>
                </v-btn>
              </template>
              <span>Specifiche Tecniche</span>
            </v-tooltip>
          </div>

          <!-- Pulsante di play dell'avanzamento produzione -->
          <v-btn
            class="mr-5"
            @click="clickPlay(item)"
            :color="item.stato_avanzamento === 0 ? 'success' : 'success'"
            :disabled="item.id_stato != id_stato_commessa_aperta"
            small
            tile
            v-if="
              (cod_profilo === 'capofficina' ||
                cod_profilo === 'addetto_produzione' ||
                      cod_profilo === 'manager' ||
                cod_profilo === 'CNXBOX' ||
                cod_profilo === 'Admin' ||
                cod_profilo === 'SVIL') &&
              item.id_commessa != undefined &&
              (!item.children || item.children.length === 0) &&
              item.id_stato === id_stato_commessa_aperta &&
              (item.id_disegno === 0 ||
                (item.id_disegno != 0 && item.view_disegno === 1)) &&
              items_distinta_base[0].revisione_visualizzata != '0'
            "
          >
            <v-icon color="white">
              {{
                item.numero_registrazioni === 0 ? "mdi-play" : "mdi-play-pause"
              }}
            </v-icon>
          </v-btn>

          <!-- Pulsante delle versioni -->
          <v-btn
            class="mr-5"
            @click="openComparisonByVersions(item)"
            :color="item.revisione_visualizzata == 0 ? 'error' : ''"
            small
            v-if="
              (cod_profilo === 'capofficina' ||
                cod_profilo === 'addetto_produzione' ||
                      cod_profilo === 'manager' ||
                cod_profilo === 'CNXBOX' ||
                cod_profilo === 'Admin' ||
                cod_profilo === 'SVIL') &&
              item.id_commessa != undefined &&
              item.id_commessa_padre === null
            "
          >
            <v-icon
              :color="item.revisione_visualizzata == 0 ? 'white' : 'primary'"
            >
              mdi-eye
            </v-icon>
          </v-btn>

          <!-- Pulsante di eliminazione componente -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickEliminaComponente(item)"
                small
                v-bind="attrs"
                v-on="on"
                v-if="
                  (cod_profilo === 'capofficina' ||
                      cod_profilo === 'manager' ||
                    cod_profilo === 'CNXBOX' ||
                    cod_profilo === 'Admin' ||
                    cod_profilo === 'SVIL') &&
                  item.id_commessa === undefined &&
                  item.id_componente != 0
                "
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Elimina Componente</span>
          </v-tooltip>
        </template>
      </v-treeview>

      <!-- Dialog di scelta dell'articolo -->
      <v-dialog persistent v-model="dialog_multiscelta">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">Articoli</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="chiudiMultiscelta"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-2" style="overflow-y: auto">
            <v-data-table
              fixed-header
              :headers="headers_articoli"
              max-height="70vh"
              :items="
                items_articoli.filter(
                  (e) => e.id_tipo_fornitura === des_fornitura_navi
                )
              "
            >
              <template v-slot:[`item.modifica`]="{ item }">
                <v-btn @click="clickScegliArticolo(item)" small>
                  <v-icon color="success">mdi-check</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog dell'aggiunta multilevel -->
      <v-dialog persistent v-model="dialog_aggiungi_multilevel">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Aggiungi Componente in
              {{
                articolo_sel.des_articolo
                  ? `${articolo_sel.cod_articolo} - ${articolo_sel.des_articolo}`
                  : articolo_sel.des_commessa
              }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="salvaAggiuntaDistinta"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span>Salva Modifiche</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="chiudiDialogCommessa"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-2" style="overflow-y: auto">
            <div style="d-flex" v-show="search_bar_visible">
              <!-- Riga dei filtri -->
              <v-row align="center" class="ma-0 px-5 py-0" justify="center">
                <!-- Filtro del cod_articolo-->
                <v-col align="center" cols="2" justify="center">
                  <v-text-field
                    @keydown="handleKeydown($event, 'getArticoliAggiunta')"
                    label="Cod. Articolo"
                    v-model="cod_articolo_sel_aggiunta"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="cod_articolo_sel_aggiunta = ''"
                        icon
                        v-show="cod_articolo_sel_aggiunta != ''"
                      >
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col align="center" cols="1" justify="center">
                  <!-- Bottone di ricerca -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="getArticoliAggiunta"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary"> mdi-magnify </v-icon>
                      </v-btn>
                    </template>
                    <span>Cerca Articolo</span>
                  </v-tooltip>
                </v-col>

                <!-- Filtro del tipo articolo -->
                <v-col align="center" cols="3" justify="center">
                  <v-autocomplete
                    :items="items_tipo_articolo"
                    label="Tipo Articolo"
                    item-text="des"
                    item-value="id_tabella"
                    v-model="tipo_articolo_sel_aggiunta"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="tipo_articolo_sel_aggiunta = null"
                        icon
                        v-show="tipo_articolo_sel_aggiunta != null"
                      >
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete></v-col
                >

                <!-- Filtro del tipo famiglia -->
                <v-col align="center" cols="3" justify="center">
                  <v-autocomplete
                    :items="items_tipo_famiglia"
                    label="Tipo Famiglia"
                    item-text="des"
                    item-value="id_tabella"
                    v-model="tipo_famiglia_sel_aggiunta"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="tipo_famiglia_sel_aggiunta = null"
                        icon
                        v-show="tipo_famiglia_sel_aggiunta != null"
                      >
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete></v-col
                >

                <!-- Filtro del tipo fornitura -->
                <v-col align="center" cols="3" justify="center">
                  <v-autocomplete
                    :items="items_tipo_fornitura"
                    label="Tipo Fornitura"
                    item-text="des"
                    item-value="id_tabella"
                    v-model="tipo_fornitura_sel_aggiunta"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="tipo_fornitura_sel_aggiunta = null"
                        icon
                        v-show="tipo_fornitura_sel_aggiunta != null"
                      >
                        <v-icon color="error"> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete></v-col
                >
              </v-row>
            </div>
            <!-- Tabella degli articoli passibili di essere aggiunti -->
            <v-data-table
              fixed-header
              :headers="headers_articoli_aggiunta"
              max-height="70vh"
              :items="items_articoli_aggiunta"
            >
              <template v-slot:[`item.quantita`]="{ item }">
                <v-text-field
                  dense
                  hide-details
                  type="number"
                  style="max-width: 75px"
                  v-model="item.quantita"
                >
                </v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog di conferma generazione commessa multipla -->
      <v-dialog v-model="conferma_commessa_multipla" persistent>
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Conferma Generazione Commessa Multipla</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="chiudiConfermaCommessaMultipla"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-2" style="overflow-y: auto">
            <span style="font-size: 18px">
              Inserire la Data di Commessa e la Data di Consegna per confermare
              la generazione della Commessa.
            </span>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="6">
                  <v-menu
                    v-model="calendario_data_commessa"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="289"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="#ffffff"
                        hide-details
                        label="Data Commessa"
                        v-model="data_commessa_formattata"
                        readonly
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data_commessa"
                      @input="calendario_data_commessa = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="calendario_data_consegna"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="289"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="#ffffff"
                        hide-details
                        label="Data Consegna Prevista"
                        v-model="data_consegna_formattata"
                        readonly
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data_consegna"
                      @input="calendario_data_consegna = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <v-divider class="mt-4 mb-3"></v-divider>
            <div>
              <v-btn @click="setCommessaMultipla" small>
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog di conferma eliminazione articolo dalla distinta base -->
      <v-dialog v-model="conferma_elimina_dialog" persistent>
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Conferma Eliminazione</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="chiudiConfermaEliminazione"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-2" style="overflow-y: auto">
            <span style="font-size: 18px">
              Confermi l'eliminazione del componente
              {{ articolo_sel.cod_articolo }} dalla distinta base?
            </span>

            <v-divider class="mt-4 mb-3"></v-divider>

            <div>
              <v-btn @click="eliminaComponente" small>
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog del carrello WO -->
      <v-dialog v-model="dialog_avanzamento">
        <Produzione2
          :back_as_landing_page="false"
          class="ma-0 pa-0"
          @click_indietro="handleClickIndietro"
          :cod_commessa="articolo_sel.cod_commessa"
          :commessa="articolo_sel"
          :filtroEM="'1'"
          :headers_carrello="headers_carrello"
          :multicommessa_visible="false"
          :open_consuntivi_if_empty="true"
          :visible="dialog_avanzamento"
        />
      </v-dialog>

      <!-- Dialog di comparazione versioni documentazione -->
      <v-dialog v-model="dialog_comparazione_versioni">
        <ComparazioneVersioni
          @chiudi_dialog="chiudiComparazioneVersioni"
          class="ma-0 pa-0"
          :id_commessa="articolo_sel.id_commessa"
          ref="ComparazioneVersioni"
        />
      </v-dialog>

      <!-- Dialog di conferma duplicazione articolo -->
      <v-dialog v-model="conferma_duplica_dialog" width="unset">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              Conferma Duplicazione
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    duplicaArticolo();
                    conferma_duplica_dialog = false;
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success">mdi-floppy</v-icon>
                </v-btn>
              </template>
              <span>Duplica</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <!-- Bottone di annullamento -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    conferma_duplica_dialog = false;
                    cod_articolo_duplicazione = '';
                    des_aggiuntiva_duplicazione = '';
                    des_articolo_duplicazione = '';
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error">mdi-keyboard-backspace</v-icon>
                </v-btn>
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-5" style="overflow-y: auto">
            <span style="font-size: 18px"
              >Per confermare la duplicazione, inserire i campi di testata per
              il nuovo articolo da creare.</span
            >
            <v-container>
              <v-row align="center" justify="center">
                <v-col class="align-center d-flex justify-end" cols="4">
                  <v-text-field
                    class="mr-5"
                    hide-details
                    label="Cod. Articolo"
                    style="max-width: 200px"
                    v-model="cod_articolo_duplicazione"
                  ></v-text-field>
                </v-col>
                <v-col class="align-center d-flex justify-start" cols="4">
                  <v-text-field
                    class="ml-5"
                    hide-details
                    label="Des. Articolo"
                    style="max-width: 200px"
                    v-model="des_articolo_duplicazione"
                  ></v-text-field>
                </v-col>
                <v-col class="align-center d-flex justify-start" cols="4">
                  <v-text-field
                    class="ml-5"
                    hide-details
                    label="Numero d'Ordine"
                    style="max-width: 200px"
                    v-model="des_aggiuntiva_duplicazione"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog per la visualizzazione delle specifiche tecniche -->
      <v-dialog persistent v-model="visualizza_disegno_visible">
        <VisualizzaDisegno
          @click_chiudi="chiudiDisegno"
          :visible="visualizza_disegno_visible"
          :work_order_sel="articolo_sel"
        />
      </v-dialog>

      <!-- Dialog degli allegati -->
      <v-dialog v-model="gestione_allegati_visible">
        <GestioneAllegato
          ref="componenteAllegati"
          @click_chiudi="gestione_allegati_visible = false"
          controller_allegati="Global"
          controller_caratteristiche_tabella="Manutenzione"
          :id_riferimento="articolo_sel.id_articolo"
          tipo_allegato="ARTICOLO"
          @snackbarOpen="snackbarOpen"
          :read_only="true"
        />
      </v-dialog>

      <!-- Snackbar -->
      <SnackbarQV ref="SnackbarQV" />

      <!-- Overlay -->
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import moment from "moment/moment";
import ComparazioneVersioni from "@/views/TOOLS/ComparazioneVersioni.vue";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import Produzione2 from "@/views/PRODUCTION/ProduzioneOnline/Produzione2.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import VisualizzaDisegno from "@/components/PRODUCTION/ProduzioneOnline/VisualizzaDisegno.vue";

class Articolo {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  reset() {
    const campi = this.setCampi();
    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "barcode", default_val: "" },
      { nome: "chkGenera", default_val: null },
      { nome: "cod_articolo", default_val: "" },
      { nome: "cod_commessa", default_val: "" },
      { nome: "data_commessa", default_val: "" },
      { nome: "data_consegna", default_val: "" },
      { nome: "data_spedizione", default_val: "" },
      { nome: "des_articolo", default_val: "" },
      { nome: "des_commessa", default_val: "" },
      { nome: "id_articolo", default_val: null },
      { nome: "id_articolo_disegno", default_val: null },
      { nome: "id_ciclo_testa", default_val: null },
      { nome: "id_commessa", default_val: null },
      { nome: "id_commessa_padre", default_val: null },
      { nome: "id_commessa_avo", default_val: null },
      { nome: "id_componente", default_val: null },
      { nome: "id_disegno", default_val: null },
      { nome: "id_distinta_base", default_val: null },
      { nome: "id_famiglia", default_val: null },
      { nome: "id_lotto", default_val: null },
      { nome: "id_magazzino", default_val: null },
      { nome: "id_stato", default_val: null },
      { nome: "id_tipo", default_val: null },
      { nome: "id_tipo_fornitura", default_val: null },
      { nome: "id_tmp", default_val: null },
      { nome: "livello", default_val: null },
      { nome: "note", default_val: "" },
      { nome: "nr_fasi", default_val: 0 },
      { nome: "numero_commessa", default_val: null },
      { nome: "qtaTotale", default_val: 1 },
      { nome: "quantita", default_val: 1 },
      { nome: "riferimento", default_val: null },
      { nome: "validita", default_val: 1 },
    ];

    return campi;
  }
}

export default {
  name: "DistintaMultilevel",
  components: {
    ComparazioneVersioni,
    GestioneAllegato,
    Produzione2,
    SnackbarQV,
    VisualizzaDisegno,
  },
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    id_articolo: {
      type: Number,
      default: null,
    },
    search_bar_visible: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      articolo_sel: new Articolo(),
      cod_articolo_sel: "",
      cod_articolo_sel_aggiunta: "",
      cod_articolo_duplicazione: "",
      cod_profilo: null,
      conferma_duplica_dialog: false,
      data_commessa: "",
      data_consegna: "",
      des_aggiuntiva_articolo_sel: "",
      des_articolo_duplicazione: "",
      des_aggiuntiva_duplicazione: "",
      des_fornitura_navi: "",
      id_articolo_sel: null,
      id_fornitura_navi: null,
      id_stato_commessa_aperta: null,
      id_stato_commessa_chiusa: null,
      tipo_articolo_sel: null,
      tipo_articolo_sel_aggiunta: null,
      tipo_famiglia_sel: null,
      tipo_famiglia_sel_aggiunta: null,
      tipo_fornitura_sel: null,
      tipo_fornitura_sel_aggiunta: null,

      // Modal
      calendario_data_commessa: false,
      calendario_data_consegna: false,
      conferma_commessa_multipla: false,
      conferma_elimina_dialog: false,
      dialog_aggiungi_multilevel: false,
      dialog_comparazione_versioni: false,
      dialog_avanzamento: false,
      dialog_multiscelta: false,
      gestione_allegati_visible: false,
      overlay: false,
      visualizza_disegno_visible: false,

      // Array
      headers_articoli: [
        {
          value: "modifica",
          text: "Scelta",
          sortable: false,
        },
        {
          text: "Cod. Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. articolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Famiglia",
          value: "id_famiglia",
          sortable: false,
        },
        {
          text: "Numero d'Ordine",
          value: "des_aggiuntiva",
          sortable: false,
        },
      ],
      headers_articoli_aggiunta: [
        {
          value: "quantita",
          text: "Q.tà",
          sortable: false,
        },
        {
          text: "Cod. Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. articolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "id_tipo",
          sortable: false,
        },
        {
          text: "Famiglia",
          value: "id_famiglia",
          sortable: false,
        },
        {
          text: "Fornitura",
          value: "id_tipo_fornitura",
          sortable: false,
        },
      ],
      headers_carrello: [
        {
          text: "Start/Stop",
          sortable: false,
          value: "start_stop",
          class: "primary--text white",
        },
        {
          text: "Registra",
          sortable: false,
          value: "registra",
          class: "primary--text white",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
          class: "primary--text white",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
          class: "primary--text white",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
          class: "primary--text white",
        },
        {
          text: "Cod. Macchina",
          sortable: false,
          value: "cod_macchina",
          class: "primary--text white",
        },

        {
          text: "Lavorazione",
          sortable: false,
          value: "des_fase",
          class: "primary--text white",
        },
        {
          text: "Q.tà Totale",
          sortable: false,
          value: "quantita",
          class: "primary--text white",
        },
        {
          text: "Q.tà Prodotta",
          sortable: false,
          value: "pezzi_registrati",
          class: "primary--text white",
        },
        {
          text: "Q.tà Fase Precedente",
          sortable: false,
          value: "qta_precedente",
          class: "primary--text white",
        },
        {
          text: "Ora Start",
          sortable: false,
          value: "ora_start",
          class: "primary--text white",
        },
        {
          text: "Ora Stop",
          sortable: false,
          value: "ora_stop",
          class: "primary--text white",
        },

        {
          text: "Ore Registrate",
          sortable: false,
          value: "ore_produzione",
          class: "primary--text white",
        },
        {
          text: "Q.tà Registrata",
          sortable: false,
          value: "pezzi_produzione",
          class: "primary--text white",
        },
        {
          text: "Produzione Reale",
          sortable: false,
          value: "pzhreale",
          class: "primary--text white",
        },
        {
          text: "Tempo Ciclo",
          sortable: false,
          value: "tempo_ciclo",
          class: "primary--text white",
        },
        {
          text: "Nascondi",
          sortable: false,
          value: "nascondi",
          class: "primary--text white",
          align: "center",
        },
      ],
      items_articoli: new Array(),
      items_articoli_aggiunta: new Array(),
      items_distinta_base: new Array(),
      items_distinta_base_aggiunta: new Array(),
      open: new Array(),
      items_tipo_articolo: new Array(),
      items_tipo_famiglia: new Array(),
      items_tipo_fornitura: new Array(),
      tabella_distinta_bk: new Array(),
    };
  },
  computed: {
    data_commessa_formattata() {
      return this.data_commessa != ""
        ? moment(this.data_commessa).format("DD/MM/YYYY")
        : "";
    },
    data_consegna_formattata() {
      return this.data_consegna != ""
        ? moment(this.data_consegna).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudiConfermaCommessaMultipla() {
      this.conferma_commessa_multipla = false;
      this.data_commessa = "";
      this.data_consegna = "";
    },
    chiudiComparazioneVersioni() {
      this.dialog_comparazione_versioni = false;
      this.getCommessaMultipla();
    },
    chiudiConfermaEliminazione() {
      this.conferma_elimina_dialog = false;
      this.articolo_sel = new Articolo();
    },
    chiudiDialogCommessa() {
      // Chiudo il dialog e resetto le variabili di supporto interessate.
      this.dialog_aggiungi_multilevel = false;
      this.articolo_sel = new Articolo();
      this.items_articoli_aggiunta = new Array();
    },
    chiudiDisegno() {
      // Chiudo il dialog di visualizzazione dei disegni.
      this.visualizza_disegno_visible = false;

      this.getCommessaMultipla();
    },
    chiudiMultiscelta() {
      /* Devo chiudere il dialog di selezione
       * dell'articolo da una lista di possibilità. */
      this.dialog_multiscelta = false;

      // Resetto l'array degli articoli per non pesare sulla RAM del browser senza motivo.
      this.items_articoli = new Array();
    },
    clickAllegati(arg) {
      console.log(arg);
    },
    clickDuplicaArticolo() {
      this.conferma_duplica_dialog = true;
    },
    clickEliminaComponente(arg) {
      this.articolo_sel = new Articolo(arg);
      this.conferma_elimina_dialog = true;
    },
    clickGestioneAllegati(item) {
      this.articolo_sel = new Articolo(item);
      this.gestione_allegati_visible = true;
    },
    async clickScegliArticolo(arg) {
      /* In questo momento l'utente ha selezionato un articolo
       *  da una lista e ne vuole vedere la distinta base. */

      // Ricerco l'articolo selezionato.
      this.id_articolo_sel = arg.id_articolo;
      await this.getCommessaMultipla();

      // Chiudo il dialog di scelta.
      this.dialog_multiscelta = false;
    },
    clickPlay(arg) {
      this.articolo_sel = new Articolo(arg);
      this.dialog_avanzamento = true;
    },
    clickSpecifiche(item) {
      /* Controllo che ci siano disegni da
       * visualizzare (me ne accorgo perché se
       * non ci sono id_disegno == null). */
      if (item.id_disegno === null || item.id_disegno === undefined) {
        // Se non ci sono disegni lancio una snackber di errore
        this.$refs.SnackbarQV.print(
          "grey",
          `Non ci sono disegni da visualizzare.`
        );
      } else {
        // Se ci sono disegni apro il dialog dei disegni.
        if (item.view_disegno == 0) {
          /* Se il disegno non è ancora stato visualizzato mando
           * una chiamata al BE e apro il dialog. */
          let request = {
            controller: "Magazzino",
            method: "PUT",
            richiesta: {
              action: "setviewdisegno",
              token: localStorage.getItem("user_token"),
              id_commessa: item.id_commessa,
              id_disegno: item.id_disegno,
              flag_em: "1",
            },
          };

          this.$store
            .dispatch("api", request)
            .then((res) => {
              if (res.status === 200) {
                this.visualizza_disegno_visible = true;
                this.articolo_sel = Object.assign({}, item);
              } else {
                this.$refs.SnackbarQV.print(
                  "error",
                  `Non è stato possibile caricare i dati: codice errore ${res.status}.`
                );
              }
            })
            .catch((err) => {
              this.$refs.SnackbarQV.print(
                "orange",
                `${JSON.parse(err.response.data.message).result}.`
              );
            });
        } else {
          /* Se il disegno è già stato visualizzato apro
           * il dialog semplicementa, senza chiamare il BE. */
          this.visualizza_disegno_visible = true;
          this.articolo_sel = Object.assign({}, item);
        }
      }
    },
    duplicaArticolo() {
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "duplicatearticle",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo_sel,
          cod_articolo: this.cod_articolo_duplicazione,
          des_aggiuntiva: this.des_aggiuntiva_duplicazione,
          des_articolo: this.des_articolo_duplicazione,
        },
      };
      // Attivo l'overlay di caricamento.
      this.overlay = true;
      // Eseguo la chiamata al BE
      this.$store
        .dispatch("api", search_Articolo)
        .then((res) => {
          this.cod_articolo_duplicazione = "";
          this.des_aggiuntiva_duplicazione = "";
          this.des_articolo_duplicazione = "";

          // Appena ricevo una risposta, blocco l'overlay di caricamento
          this.overlay = false;

          // Se la risposta è in stato 200...
          if (res.status === 200) {
            // ... Carico l'articolo che ho appena generato
            this.id_articolo_sel = res.data[0].id_articolo;
            this.des_aggiuntiva_articolo_sel = res.data[0].des_aggiuntiva;
            this.cod_articolo_sel = res.data[0].cod_articolo;
            this.getCommessaMultipla();
          } else {
            // Se invece non è in stato 200 stampo una snackbar di errore.
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.cod_articolo_duplicazione = "";
          this.des_aggiuntiva_duplicazione = "";
          this.des_articolo_duplicazione = "";
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    eliminaComponente() {
      this.overlay = true;
      this.articolo_sel.children = [];
      this.articolo_sel.validita = 0;

      // Ora ho l'array di distinta base da salvare, e compongo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.articolo_sel.id_componente,
          token: localStorage.getItem("user_token"),
          distinta_base: [{ children: [this.articolo_sel] }],
        },
      };

      // Eseguo la chiamata al BE.
      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              `Aggiunta effettuata con successo!`
            );
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }

          // Chiudo il dialog e resetto le variabili di supporto interessate.
          this.conferma_elimina_dialog = false;
          this.articolo_sel = new Articolo();

          // Ricarico la distinta base aggiornata.
          this.getCommessaMultipla();
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getArticoli() {
      this.overlay = true;

      // Controllo che qualcuno dei campi sia valorizzato
      if (
        this.cod_articolo_sel === "" &&
        this.tipo_famiglia_sel === null &&
        this.des_aggiuntiva_articolo_sel === ""
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Specificare dei parametri di ricerca."
        );
      }

      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel || "",
          des_aggiuntiva: this.des_aggiuntiva_articolo_sel || "",
          id_tipo: this.tipo_articolo_sel || "",
          id_famiglia: this.tipo_famiglia_sel || "",
          id_tipo_fornitura: this.id_fornitura_navi || "",
        },
      };

      // Eseguo la chiamata al BE
      this.$store
        .dispatch("api", search_Articolo)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            // Se non ci sono risultati stampo una snackbar di avviso.
            if (res.data.lista.length === 0) {
              this.$refs.SnackbarQV.print(
                "orange",
                `Non sono stati trovati articoli corrispondenti ai criteri di ricerca.`
              );
            } else if (res.data.lista.length === 1) {
              // Se c'è un risultato, ne carico la distinta base.
              this.id_articolo_sel = res.data.lista[0].id_articolo;
              this.getCommessaMultipla();
            } else {
              /* Se ci sono più risultati li carico in una tabella e
               * apro il dialog di scelta dell'articolo. */
              this.items_articoli = Array.from(res.data.lista);
              this.dialog_multiscelta = true;
            }
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getArticoliAggiunta() {
      this.overlay = true;

      // Controllo che qualcuno dei campi sia valorizzato
      if (
        this.cod_articolo_sel === "" &&
        this.tipo_articolo_sel === null &&
        this.tipo_famiglia_sel === null &&
        this.tipo_fornitura_sel === null
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Specificare dei parametri di ricerca."
        );
      }

      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel_aggiunta || "",
          id_tipo: this.tipo_articolo_sel_aggiunta || "",
          id_famiglia: this.tipo_famiglia_sel_aggiunta || "",
          id_tipo_fornitura: this.tipo_fornitura_sel_aggiunta || "",
        },
      };

      // Eseguo la chiamata al BE
      this.$store
        .dispatch("api", search_Articolo)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            // Se non ci sono risultati stampo una snackbar di avviso.
            if (res.data.lista.length === 0) {
              this.$refs.SnackbarQV.print(
                "orange",
                `Non sono stati trovati articoli corrispondenti ai criteri di ricerca.`
              );
            } else {
              // Se ci sono dei risultati li carico nella tabella debita
              this.items_articoli_aggiunta = Array.from(
                res.data.lista.filter(
                  /* Escludo dagli oggetti da caricare quello che ha
                   * lo stesso id_articolo della tabella selezionata. */
                  (e) => e.id_articolo != this.articolo_sel.id_articolo
                )
              );

              this.items_articoli_aggiunta.forEach((e) => {
                e.quantita = 0;
              });
            }
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    async getCommessaMultipla() {
      this.overlay = true;

      const getcommessamultipla = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessamultipla",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo_sel,
          flag_produzione: 1,
        },
      };

      return this.$store
        .dispatch("api", getcommessamultipla)
        .then(async (res) => {
          this.overlay = false;

          if (res.status === 200) {
            // Resetto l'array di backup della distinta
            this.tabella_distinta_bk = [];

            /* Valorizzo le variabili di supporto come
             * mi serve per l'operatività del sistema. */
            const my_array = [];
            for (let i = 0; i < res.data.length; i++) {
              /* Creo dei campi qtaTotale_originale e qtaTotale_somma che mi
               * torneranno utili in seguito per calcolare la quantità di
               * oggetti da produrre. */
              this.tabella_distinta_bk.push(_.cloneDeep(res.data[i]));
              let o = _.cloneDeep(res.data[i]);
              o.qtaTotale_originale = o.qtaTotale;
              o.qtaTotale_somma = 0;
              my_array.push(_.cloneDeep(o));
            }

            // Verifico il flag revisionato dell'elemento capostipite
            const rv = await this.getRevisioneVisualizzata(my_array[0]);

            let arr_tmp = new Array();
            this.open = new Array();
            this.setAnnidamentoRecursivo(my_array, arr_tmp, 0, 0);

            /* Assegno un indice a tutti gli elementi di "arr_tmp"
             * e ai loro figli per stampare il numero di riga nella treeview. */
            this.setIndiceRicorsivo(arr_tmp);

            /* Assegno il flag revisione_visualizzata che ho valorizzato
             * poche righe sopra; valorizzare prima, e poi assegnare il flag in
             * due battute è necessario per una questione di millisecondi e puntatori,
             * altrimenti gli identificativi dei nodi non vengono inseriti nell'array
             * open e la treeview non viene espansa al primo colpo. */
            arr_tmp[0].revisione_visualizzata = rv;

            this.items_distinta_base = Array.from(arr_tmp);
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getDistintaBaseAggiunta() {
      /* Questa chiamata serve per mettermi da parte la distinta
       * base dell'articolo a cui voglio aggiungere dei componenti. */

      this.overlay = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolidistintabase",
          token: localStorage.getItem("user_token"),
          id_articolo: this.articolo_sel.id_articolo,
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          this.overlay = false;

          /* Resetto l'articolo_sel in caso questa chiamata sia stata fatta
           * per eseguire un refresh deidati dopo selezione (per esempio è
           * quello che succede nella procedura di eliminazione da distinta base). */
          // this.articolo_sel = new Articolo();

          if (res.status === 200) {
            this.items_distinta_base_aggiunta = Array.from(res.data);
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }

          /* Assegno un indice a tutti gli elementi di "items_distinta_base"
           * e ai loro figli per stampare il numero di riga nella treeview. */
          this.setIndiceRicorsivo(this.items_distinta_base_aggiunta);
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getRevisioneVisualizzata(arg) {
      this.overlay = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getrevisionevisualizzata",
          token: localStorage.getItem("user_token"),
          des_aggiuntiva: arg.des_aggiuntiva,
          id_commessa: arg.id_commessa,
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            return res.data.result;
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getTabelle() {
      this.overlay = true;

      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'Famiglia','FORNITURA_STANDARD','TIPOARTICOLO', 'COMMESSA_STATO'",
          filter: "validita = 1",
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.overlay = false;

          this.items_tipo_articolo =
            res.data.TIPOARTICOLO != undefined ? res.data.TIPOARTICOLO : [];
          this.items_tipo_famiglia =
            res.data.Famiglia != undefined ? res.data.Famiglia : [];
          this.items_tipo_fornitura =
            res.data.FORNITURA_STANDARD != undefined
              ? res.data.FORNITURA_STANDARD
              : [];

          /* Registro l'id_tabella dell'etichetta di forniture con codice NAV
           * che mi servirà in seguito per le chiamate in get dal BE. */
          if (res.data.FORNITURA_STANDARD != undefined) {
            res.data.FORNITURA_STANDARD.forEach((e) => {
              if (e.cod === "NAV") {
                this.des_fornitura_navi = e.des;
                this.id_fornitura_navi = e.id_tabella;
              }
            });
          }

          /* Registro gli id_tabella di COMMESSA_STATO
           * che mi servono per le icone delle commesse. */
          if (res.data.COMMESSA_STATO != undefined) {
            res.data.COMMESSA_STATO.forEach((e) => {
              if (e.val_txt == 2) {
                this.id_stato_commessa_aperta = e.id_tabella;
              } else if (e.val_txt == 3) {
                this.id_stato_commessa_chiusa = e.id_tabella;
              }
            });
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    goBack() {
      if (this.back_as_landing_page === false) {
        this.$emit("chiudi_dialog");
      } else if (this.back_as_landing_page === true) {
        this.$emit("go_back");
      }
    },
    handleClickIndietro() {
      this.dialog_avanzamento = false;
      this.getCommessaMultipla();
    },
    handleKeydown(e, tipo) {
      if (
        e.code === "Enter" &&
        this.cod_articolo_sel != "" &&
        tipo === "getArticoli"
      ) {
        this.getArticoli();
      } else if (
        e.code === "Enter" &&
        this.cod_articolo_sel != "" &&
        tipo === "getArticoliAggiunta"
      ) {
        this.getArticoliAggiunta();
      }
    },
    inserisciComponente(arg) {
      // Registro l'articolo selezionato.
      this.articolo_sel = new Articolo(arg);

      // Registro la distinta base dell'articolo che ho selezionato.
      this.getDistintaBaseAggiunta();

      // Apro il dialog.
      this.dialog_aggiungi_multilevel = true;
    },
    openComparisonByVersions(arg) {
      this.articolo_sel = new Articolo(arg);
      this.dialog_comparazione_versioni = true;
      this.$nextTick(function () {
        this.$refs.ComparazioneVersioni.codice_search = arg.des_aggiuntiva;
        this.$refs.ComparazioneVersioni.multiple = true;
        setTimeout(() => {
          this.$refs.ComparazioneVersioni.compareVersion();
        }, 10);
      });
    },
    salvaAggiuntaDistinta() {
      this.overlay = true;

      /* Costruisco un oggetto che userò per valorizzare
       * eventualmente l'argomento della chiamata per creare
       * una commessa dopo aver modificato la distinta base. */
      let item_for_commessa = {};

      /* Faccio una chiamata  */
      let nuova_distinta_base = Array.from(this.items_distinta_base_aggiunta);
      this.items_articoli_aggiunta.forEach((e) => {
        // Trasformo la quanitità da stringa (eventuale) in numero.
        e.quantita = parseInt(e.quantita);

        /* Se la quantità del componente è maggiore
         * di 0 lo gestisco, altrimenti lo ignoro. */
        if (e.quantita > 0) {
          // Verifico che il componente non ricorra già nella distinta base
          nuova_distinta_base.forEach((c) => {
            if (c.id_componente === e.id_articolo) {
              // Se il componente ricorre, gli cambio semplicemente la quantità
              c.quantita = parseInt(e.quantita);
            } else {
              // Se invece l'articolo non ricorre già come componente...

              // Costruisco un nuovo componente
              const nuovo_componente = {};
              nuovo_componente.children = [];
              nuovo_componente.cod_articolo = e.cod_articolo;
              nuovo_componente.des_articolo = e.des_articolo;
              nuovo_componente.id = e.id_articolo;
              nuovo_componente.id_articolo = this.articolo_sel.id_articolo;
              nuovo_componente.id_componente = e.id_articolo;
              nuovo_componente.id_distinta_base = -1;
              nuovo_componente.id_rif = 0;
              nuovo_componente.name = e.cod_articolo;
              nuovo_componente.quantita = e.quantita;
              nuovo_componente.validita = 1;

              // Aggiungo il nuovo componente alla distinta base.
              nuova_distinta_base[0].children.push(
                Object.assign({}, nuovo_componente)
              );

              /* Registro questo componente che mi può servire in
               * seguito per creare una commessa dopo il cambiamento
               * della distinta base. */
              item_for_commessa = Object.assign({}, nuovo_componente);
            }
          });
        }
      });

      /* Setto la validità del primo livello di
       * distinta base a 1 per convenienza di BE. */
      nuova_distinta_base[0].validita = 1;

      /* Azzero l'array dei children di tutti i figli di
       * primo livello del primo oggetto della distinta base.*/
      nuova_distinta_base[0].children.forEach((c) => {
        c.children = [];
      });

      // Ora ho l'array di distinta base da salvare, e compongo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.articolo_sel.id_articolo,
          token: localStorage.getItem("user_token"),
          distinta_base: nuova_distinta_base,
        },
      };

      // Eseguo la chiamata al BE.
      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              `Aggiunta effettuata con successo!`
            );

            /* Se le seguenti condizioni sono verificate significa che
             * sto avanzando una commessa; in questo caso oltre ad aggiungere
             * un elemento alla distinta base devo fare una chiamata in coda
             * per generare la commessa dell'articolo che ho aggiunto. */
            if (
              this.items_distinta_base[0].id_commessa != undefined &&
              this.items_distinta_base[0].id_commessa != null
            ) {
              this.setCommessaInCorsa(item_for_commessa);
            }
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }

          // Chiudo il dialog e resetto le variabili di supporto interessate.
          this.dialog_aggiungi_multilevel = false;
          this.articolo_sel = new Articolo();
          this.items_articoli_aggiunta = new Array();

          // Ricarico la distinta base aggiornata.
          this.getCommessaMultipla();
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    setAnnidamentoRecursivo(a1, a2, val_livello, val_ricercato) {
      a1.forEach((i) => {
        if (i.id_commessa == undefined) {
          if (i.livello === val_livello && i.id_componente === val_ricercato) {
            const obj_tmp = _.cloneDeep(i);
            obj_tmp.id_tmp = "" + obj_tmp.id_articolo + obj_tmp.id_componente;
            this.open.push(obj_tmp.id_tmp);
            obj_tmp.children = [];
            this.setAnnidamentoRecursivo(
              a1,
              obj_tmp.children,
              val_livello + 1,
              i.id_articolo
            );
            a2.push(_.cloneDeep(obj_tmp));
          }
        } else {
          if (
            i.livello === val_livello &&
            (i.id_commessa_padre === val_ricercato ||
              (i.id_commessa_padre === null && val_ricercato === 0))
          ) {
            const obj_tmp = _.cloneDeep(i);
            obj_tmp.id_tmp =
              "" + obj_tmp.id_commessa + obj_tmp.id_commessa_padre;
            this.open.push(obj_tmp.id_tmp);
            obj_tmp.children = [];
            this.setAnnidamentoRecursivo(
              a1,
              obj_tmp.children,
              val_livello + 1,
              i.id_commessa
            );
            a2.push(_.cloneDeep(obj_tmp));
          }
        }
      });
    },
    setCommessaInCorsa(arg) {
      this.overlay = true;

      // Aggiungo dei campi per convenienza di BE
      arg.qtaTotale_originale = arg.quantita;
      arg.qtaTotale = arg.quantita;
      arg.chkgenera = 1;

      const setcommessamultipla = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessamultipla",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel,
          id_commessa_avo: this.items_distinta_base[0].id_commessa,
          id_commessa_padre: this.articolo_sel.id_commessa,
          data_commessa:
            this.items_distinta_base[0].data_commessa != null &&
            this.items_distinta_base[0].data_commessa != ""
              ? this.items_distinta_base[0].data_commessa.substring(0, 10)
              : "",
          data_consegna:
            this.items_distinta_base[0].data_consegna != null &&
            this.items_distinta_base[0].data_consegna != ""
              ? this.items_distinta_base[0].data_consegna.substring(0, 10)
              : "",
          tabella: [arg],
        },
      };

      this.$store
        .dispatch("api", setcommessamultipla)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            this.getCommessaMultipla();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.conferma_commessa_multipla = false;
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    setCommessaMultipla() {
      this.overlay = true;

      const a_tmp = Array.from(this.tabella_distinta_bk);

      a_tmp.forEach((elem) => {
        /* Creo dei campi qtaTotale_originale e qtaTotale_somma che mi
         * torneranno utili in seguito per calcolare la quantità di
         * oggetti da produrre. */
        elem.qtaTotale_originale = elem.qtaTotale;
        elem.qtaTotale_somma = 0;
      });

      /* Eseguo la chiamata per avere le fasi di lavorazione
       * necessarie per tutti i componenti di questo articolo. */
      const setcommessamultipla = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessamultipla",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel,
          data_commessa: this.data_commessa,
          data_consegna: this.data_consegna,
          tabella: Array.from(a_tmp),
          flag_produzione: 1,
        },
      };

      this.data_commessa = "";
      this.data_consegna = "";

      this.$store
        .dispatch("api", setcommessamultipla)
        .then((res) => {
          this.overlay = false;

          if (res.status === 200) {
            this.conferma_commessa_multipla = false;
            this.getCommessaMultipla();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.conferma_commessa_multipla = false;
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    setIndiceRicorsivo(array, val = 0) {
      for (let i = val; i < array.length; i++) {
        array[i].indice = i + 1;
        if (array[i].children && array[i].children.length > 0) {
          this.setIndiceRicorsivo(array[i].children, 0);
        }
      }
    },
    snackbarOpen() {
      this.$refs.SnackbarQV.print(
        "orange",
        `Aggiungere ad ogni Allegato dell'attività una descrizione.`
      );
    },
  },
  created() {},
  mounted() {
    /* Questo mounted serve per il componente di primo livello.
     * Le istruzioni per i componenti di livello successivo sono
     * specificate nella gestione del watch sulla prop "visible". */
    this.getTabelle();

    // Assegno il cod_profilo per identificare il ruolo
    this.cod_profilo = localStorage.cod_profilo;
  },
};
</script>
<style scoped>
</style>