<template>
        <v-card>
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        
          <v-container>

            <v-row>
              <v-col cols="12" class="pa-5">
                <v-toolbar
                  dark
                  color="primary"
                  style="border-radius: 5px 5px 0px 0px"
                  class="elevation-0"
                  >
                      <v-col cols="12" sm="6" md="4" align="left">
                        <h2>Totale articoli da prelevare: {{this.NUM_TOT_ARTICOLI}}. </h2>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" align="center">
                        <v-text-field
                            style="width:180px"
                            type="number" 
                            class="my-0 py-0"
                            hide-details
                            label="Impostare nuova quantità"
                            min="0"
                            outlined
                            :max="this.NUM_TOT_ARTICOLI"
                            v-model="quantita_da_prelevare" 
                            @input="gestisci_quantità_prelevate()"
                            @focus="$event.target.select()"
                        /> 
                      </v-col>
                      <v-col cols="12" sm="6" md="4" align="right" >
                        <h2>Quantità prelevata {{this.quantita_selezionata}}</h2> 
                      </v-col>    
                </v-toolbar>
                <v-data-table
                  :headers="headers_articolo_lotti"
                  :items="items_articolo_lotti"
                  class="elevation-3"
                >
                  <template v-slot:item.selezione="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="num_articoli_da_lotto(item)"
                        >
                          <v-icon color="success"
                            >mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Selezione numero pezzi articolo da Lotto</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.check="{ item }">
                    <v-checkbox
                      style="margin-left: 50px"
                      v-model="item.check"
                      @change=" _check()"
                    ></v-checkbox>
                  </template> 
                </v-data-table>
              </v-col>
            </v-row>

            <v-btn color="primary" @click="save_back">Salva</v-btn>

          </v-container>

        <!-- Gestione dialog picking 
        <v-dialog v-model="dialog_picking" max-width="30%" persistent >
            <v-card>
                <v-toolbar
                        class="white--text"
                        color="blue"
                        dense
                        style="position: sticky; top: 0px; z-index: 1"
                        >
                        <v-toolbar-title> Selezione quantità articoli da lotto </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="red--text"
                            @click="dialog_picking = false"
                            color="blue-grey lighten-5"
                            small
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" />
                        <v-col cols="12" sm="6" md="4" class="d-flex justify-center">
                            <v-text-field
                                v-model="num_articoli_selezionati"
                                label="Numero Articoli"
                                hint="Numero di articoli del lotto da prelevare"
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4"/> 
                        <v-col cols="12" sm="6" md="12">
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save_picking"
                            >
                            Save
                            </v-btn>
                        </v-col>                            
                    </v-row>

            </v-card>
        </v-dialog>
        --> 

        </v-card>
        
</template>

<script>
   // import axios from "axios";
    export default {
        data() {
            return{

                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",
                dialog_picking: false,
                num_articoli_selezionati: 0,
                quantita_selezionata: 0 ,
                quantita_da_prelevare:0,
                TRIGGER: 0,
                checked: true,

                items_articolo_lotti: [], //Array (Matrice contenente  Lotto, cod_Articolo, giacenza e quantità prelevate in funzione della selezione dei check)
                array_picking_lotti_articolo: [],
                headers_articolo_lotti: [
                    {text: "Lotto",value: "cod_lotto",},
                    {text: "Cod. Articolo",value: "cod_articolo",},
                    {text: "Giacenza",value: "giacenza",},
                    {text: "Qta Prelevata",value: "qta_prelevata",},
                    {text: "Prelievo",value: "check",},
                ],

                item_articolo_lotto: {cod_lotto: '', cod_articolo: '', pmu: '', num_pezzi: ''},
                item_articolo_selezionato: {lotto: '', cod_articolo: '', quantita: ''},
            }
        },
        props: {
            DA: String,
            COD_ARTICOLO: String,
            CAMBIO_STATO: Number,
            NUM_TOT_ARTICOLI: Number,
            ID_ARTICOLO: Number,
            ARRAY_ID_DOC_DETTAGLIO: Array,
            QTA_CONTENITORE: Number,
            ID_CONTENITORE: Number,
            ID_COMMESSA: Number,
        },
        computed: {
        },        
        mounted() {
            this.quantita_da_prelevare = this.NUM_TOT_ARTICOLI
            this.getarticolilottopicking()
        },
        watch: {
            COD_ARTICOLO: {
            immediate: true,
            handler() {
                    this.quantita_da_prelevare = this.NUM_TOT_ARTICOLI
                    this.getarticolilottopicking() 
                }
            },
            
            CAMBIO_STATO: {
            immediate: true,
            handler() {
                    this.quantita_da_prelevare = this.NUM_TOT_ARTICOLI
                    this.getarticolilottopicking() 
                }
            },
            TRIGGER: {
            immediate: true,
            handler() {
                    this.gestisci_quantità_prelevate() 
                }
            },

        },
        methods: { 
            getarticolilottopicking(){

                //debugger
                let request = {
                    controller: "Magazzino",
                    method: "POST",
                    richiesta: {
                        action: "getpickingarticolo",
                        id_articolo: this.ID_ARTICOLO,
                        qta_prelievo: this.quantita_da_prelevare,
                        id_contenitore: this.ID_CONTENITORE,
                        qta_contenitore: this.QTA_CONTENITORE,
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {    
                    this.items_articolo_lotti= []
                    res.data.forEach((elem) => {
                        this.items_articolo_lotti.push(elem);
                    });
                    this.quantita_selezionata = this.quantita_da_prelevare
                })
                .catch(e => {
                    console.log(e);
                });

            },
            gestisci_quantità_prelevate(){
                
                let qta_selezionata = 0
                let quantita_rimanente = this.quantita_da_prelevare
                this.items_articolo_lotti.forEach((elem) => {
                        if (elem.check == true && quantita_rimanente > 0){
                           // debugger
                            if (quantita_rimanente >= elem.giacenza){
                                
                                quantita_rimanente = quantita_rimanente - elem.giacenza
                                elem.qta_prelevata = elem.giacenza
                                
                                qta_selezionata = qta_selezionata + elem.qta_prelevata
                               
                            }else{ //rimanente < giacenza
                                
                                elem.qta_prelevata = quantita_rimanente
                                qta_selezionata = qta_selezionata + elem.qta_prelevata
                                quantita_rimanente = 0
                            }
                        }else{
                            //debugger
                            elem.qta_prelevata = 0
                        }
                    });
                    this.quantita_selezionata = qta_selezionata
                },

            _check(){
                
                this.TRIGGER = Math.round(Math.random()*100);   
            },

            setpicking(){
                return new Promise((resolve, reject) => {
                    let request = {
                        controller: "Magazzino",
                        method: "PUT",
                        richiesta: {
                        action: "setpicking",
                        lista_id_documento_dettaglio: this.ARRAY_ID_DOC_DETTAGLIO,
                        array_picking: this.array_picking_lotti_articolo,
                        id_contenitore: this.ID_CONTENITORE,
                        qta_contenitore: this.QTA_CONTENITORE,                        
                        token: localStorage.getItem("user_token"),
                        },
                    };
                    this.$store.dispatch("api", request).then((res) => {
                        resolve(res);
                        return;
                    })
                    .catch((e) => {
                        reject(e);
                        console.log(e);
                    });
                });
            },     
            
 
           async save_back(){

                this.array_picking_lotti_articolo = []
                this.items_articolo_lotti.forEach((elem) => {
                    if (elem.qta_prelevata > 0){
                        this.array_picking_lotti_articolo.push(elem);
                    }
                })
                if (this.DA == "Packaging"){
                    await this.setpicking()
                    this.$emit("imballo");  
                }
                if (this.DA == "DistintaBase"){
                    this.$emit("prelievo", this.array_picking_lotti_articolo );  
                }
                /*
                if (await this.setpicking() == 'OK'){
                    this.snackbar_text = "Articoli prelevati correttamente.";
                    this.snackbar_color = "Success";
                    this.snackbar = true;  

                    this.$emit("imballo");  
                }else{
                    this.snackbar_text = "Errore nel prelievo degli articoli.";
                    this.snackbar_color = "error";
                    this.snackbar = true;  
                }
                */

            },


 
            
        }

    }
</script>
