<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Work Orders per Multicommessa
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="confermaSelezione" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-check-all </v-icon>
          </v-btn>
        </template>
        <span> Conferma Selezioni Correnti </span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text style="overflow-y: auto">
      <v-data-table :items="items_wo_multi_v" :headers="headers_wo_multi">
        <!-- Checkbox di selezione del Work Order -->
        <template v-slot:[`item.seleziona`]="{ item }">
          <!--<v-btn @click="item.sel = item.sel === false? true : false" large>
            <v-icon :color="item.sel === false ? '' : 'success'" large>
              {{ item.sel === false ? "" : "mdi-check" }}
            </v-icon>
          </v-btn>-->

          <v-checkbox color="primary" v-model="item.sel"></v-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "ConsuntivoWorkOrderMulti",
  components: {},
  props: {
    items_wo_multi: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
      items_wo_multi_v: [],
      headers_wo_multi: [
        { text: "Seleziona", value: "seleziona", sortable: false },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Q.tà Totale",
          sortable: false,
          value: "quantita",
        },
        {
          text: "Q.tà Prodotta",
          sortable: false,
          value: "pezzi_registrati",
        },
        {
          text: "Q.tà Fase Precedente",
          sortable: false,
          value: "qta_precedente",
        },
        {
          text: "Ore Registrate",
          sortable: false,
          value: "ore_produzione",
        },
        {
          text: "Q.tà Registrata",
          sortable: false,
          value: "pezzi_produzione",
        },
      ],
    };
  },
  computed: {},
  watch: {
    items_wo_multi: {
      immediate: true,
      handler() {
        /* Copio l'array che mi arriva come prop e renderizzo
         * questi dati nella tabella, in modo da poterli modificare
         * senza interferire con la morfologia di una prop. */
        this.items_wo_multi_v = Array.from(this.items_wo_multi);
      },
    },
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    clickSelezionaMacchina(arg) {
      this.$emit("selezionata_macchina", arg.id_macchina);
    },
    confermaSelezione() {
      /* Cerco i Work Order selezionati e li metto  in
       * un array per spedirli al componente padre */
      const wo_sel = [];

      this.items_wo_multi_v.forEach((w) => {
        if (w.sel === true) {
          delete w.sel;
          wo_sel.push(w);
        }
      });

      this.$emit("selezionati_wo", wo_sel);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>