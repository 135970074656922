var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"listener_tabella_disegni"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Immagini ")]),_c('v-spacer'),(_vm.return_array === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaArrayImmagini}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}],null,false,4171552619)},[_c('span',[_vm._v("Salva")])]):_vm._e()],1),_c('v-card-text',{staticStyle:{"max-height":"60vh","overflow-y":"scroll"}},[_c('v-container',_vm._l((_vm.items_disegno),function(item,i){return _c('v-row',{key:i,attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticStyle:{"overflow":"hidden","margin":"5px"},attrs:{"width":"100","height":"70","elevation":"5"},on:{"click":function($event){return _vm.reloadImage(item)}}},[_c('img',{staticStyle:{"object-fit":"contain","object-position":"center"},attrs:{"width":"100","height":"70","src":item.disegno}})]),(_vm.return_array === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"small":""},on:{"click":function($event){return _vm.inviaImmagine(item.disegno)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Scegli Immagine")])]):_vm._e(),(_vm.return_array === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"small":""},on:{"click":function($event){return _vm.clickEliminaImmagine(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina Immagine")])]):_vm._e()],1)}),1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_dialog),callback:function ($$v) {_vm.conferma_elimina_dialog=$$v},expression:"conferma_elimina_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Elimina Immagine")])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"overflow-y":"auto"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Sei sicuro di voler eliminare l'immagine? ")]),_c('v-divider',{staticClass:"my-5"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.chiudiConfermaDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Annulla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.eliminaImmagine}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }