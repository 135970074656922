<template>
  <div style="width: 100%">
     

    
    <IngressoMateriale /></div>
</template>
<script>
import IngressoMateriale from "../../../components/PRODUCTION/LOGISTICA/Ingresso_materiale.vue";
export default {
  components: {
    IngressoMateriale,
  },
  data() {
    return {
      
    };
  },
};
</script>