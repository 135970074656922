<template>
  <v-card>
    <v-data-table
      :headers="headers_responsabili"
      :items="items_responsabili"
      :single-expand="true"
      :expanded.sync="expanded_responsabili"
      fixed-header
      height="70vh"
      item-key="id_utente"
      show-expand
      class="elevation-1 pt-0"
    >
      <template v-slot:top>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Responsabili di Reparto
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="aggiungiResponsabile()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary"> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Responsabile</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on">
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <!-- Bottone di eliminazione del responsabile -->
      <template v-slot:[`item.elimina`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickEliminaResponsabile(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Elimina Responsabile</span>
        </v-tooltip>
      </template>

      <!-- Drill down dei membri del team -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="pa-0"
          style="background-color: white"
          :colspan="headers.length"
        >
          <v-data-table
            class="elevation-3"
            :headers="headers_team"
            hide-default-footer
            :items="item.team"
          >
            <!-- Titolo tabella -->
            <template v-slot:top>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text"> Team </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="aggiungiMembro(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Membro</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <!-- Bottone di eliminazione del membro -->
            <template v-slot:[`item.elimina`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickEliminaResponsabile(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Elimina Responsabile</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  name: "GestioneReparto",
  components: {},
  props: {},
  data() {
    return {
      // Variabili d'appoggio

      // Modal

      // Array
      expanded_responsabili: [],
      headers_responsabili: [
        {
          text: "Nominativo",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      headers_team: [
        {
          text: "Nominativo",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      items_responsabili: [
        {
          id_utente: 1,
          nominativo: "Gianni Bianchi",
          team: [
            { id_utente: 2, nominativo: "Mario Longhi" },
            { id_utente: 3, nominativo: "Franco Brevi" },
          ],
        },
        {
          id_utente: 4,
          nominativo: "Daniele Neri",
          team: [
            { id_utente: 5, nominativo: "Mario Longhi" },
            { id_utente: 6, nominativo: "Franco Brevi" },
          ],
        },
      ],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    aggiungiMembro(arg) {
        console.log(JSON.stringify(arg));
    },
    aggiungiResponsabile() {},
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    clickEliminaMembro() {},
    clickEliminaResponsabile() {},
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>