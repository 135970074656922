let TABLE = null;
let CALENDAR = null;
let REF = null;
let CONNECTIONS = null
class Drag {
    constructor() {
        REF = this;
    }

    setReferences(table, calendar, connections) {
        TABLE = table;
        CALENDAR = calendar;
        CONNECTIONS = connections;
    }

    start(event, container, target) {
        if (event.button != 0) {
            return;
        }
        REF.isreal = target.getAttribute('data-realtask') != null;
        REF.container = container;
        REF.target = target;
        REF.ghost = document.createElement('div');
        REF.taskreference = target.parentElement.taskreference;

        const items = document.elementsFromPoint(event.clientX, event.clientY);
        console.log("items:",items);
        items.forEach(item=>{
            const tag = item.tagName.toLowerCase();
            if (tag == 'body') {
                return;
            }
            if (tag == 'td') {
                REF.src_cell = item;
                return;
            }
        });
        REF.ghost.setAttribute('data-ghost', true);
        REF.to_hide = target;
        REF.realoffset = 0;

        const rect = target.getBoundingClientRect();
        REF.ghost.style.left = rect.x + 'px';
        REF.ghost.style.top = rect.y + 'px';
        REF.ghost.style.width = rect.width + 'px';
        REF.ghost.style.height = rect.height + 'px';
        REF.offset = event.clientX - rect.x;
        REF.y = rect.y;

        container.appendChild(REF.ghost);
        container.addEventListener('mouseleave', REF.stop);
        container.addEventListener('mouseup', REF.stop);
        container.addEventListener('mousemove', REF.move);
        REF.to_hide.style.display = 'none';
    }
    move(event) {
        const x = event.clientX - REF.offset;
        REF.ghost.style.left = x + 'px';
    }

    stop(event) {
        REF.container.removeChild(REF.ghost);
        REF.container.removeEventListener('mouseleave', REF.stop);
        REF.container.removeEventListener('mouseup', REF.stop);
        REF.container.removeEventListener('mousemove', REF.move);

        let target = document.elementFromPoint(event.clientX, REF.y);
        while (target != null) {
            const tag = target.tagName.toLowerCase();
            if (tag == 'body') {
                target = null;
                break;
            }
            if (tag == 'td') {
                break;
            }
            target = target.parentElement;
        }
        if (target != null) {
            const row = REF.src_cell.parentElement;
            const row_test = target.parentElement;
            if (row == row_test) {
                const cells = Array.from(row.children);

                //const delta = (cells.indexOf(target) - cells.indexOf(REF.src_cell)) - REF.realoffset;
                REF.taskreference.checkPosition(cells,REF.src_cell,target);
                
                //REF.taskreference.offSetFather(delta);

                const res = TABLE.updateBoundaries();
                CALENDAR.build(res.min, res.max);
                TABLE.update();
                CONNECTIONS.build(null, TABLE.items);
            }
        }

        REF.to_hide.style.display = 'block';
        delete REF.ghost;
        delete REF.container;
        delete REF.target;
        delete REF.offset;
        delete REF.y;
        delete REF.src_cell;
        delete REF.to_hide;
        delete REF.isreal;
        delete REF.realoffset;
        delete REF.taskreference;
    }
}

export default Drag;