<template>
  <div style="padding: 20px">
    <v-hover>
      <template v-slot="{ hover }">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-card :elevation="hover ? 24 : 2">
              <v-toolbar dense class="indigo darken-1 headline" primary-title>
                <v-toolbar-title class="white--text">Packaging</v-toolbar-title>
                 <v-spacer></v-spacer>
       <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
                  <v-icon color="error">mdi-keyboard-backspace</v-icon>
                </v-btn>
              </template>
              <span>Vai alla Dashboard</span>
            </v-tooltip>
              </v-toolbar>

              <v-container fluid>
                <v-row>
                  <v-col md="2">
                    <v-autocomplete
                      v-model="operatore_selezionato"
                      :items="operatore_selezionato_list"
                      label="Operatore Selezionato"
                      placeholder="Operatore Selezionato"
                      item-text="text"
                      required
                      return-object
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          class="mb-0 mt-0"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          color="light-blue lighten-4"
                          @click="data.select"
                          @click:close="tiporemove(data.item)"
                          >{{ data.item }}</v-chip
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col md="12">
                    <v-data-table
                      :headers="headers"
                      :items="items"
                      :hide-default-footer="true"
                    >
                      <template v-slot:item.dettagli="">
                        <v-btn
                          class="mx-2"
                          tile
                          color="primary"
                          @click="onButtonClick()"
                        >
                          <v-icon dark>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-hover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      operatore_selezionato: "",
      operatore_selezionato_list: ["1", "2", "3"],
      headers: [
        {
          text: "Codice Cliente",
          value: "codice_cliente",
          sortable: false,
        },
        {
          text: "Ragione Sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Data Consegna",
          value: "data_consegna",
          sortable: false,
        },
        {
          text: "Peso Totale",
          value: "peso_totale",
          sortable: false,
        },
        {
          text: "Destinazione",
          value: "destinazione",
          sortable: false,
        },
        {
          text: "Num. Ordine",
          value: "num_ordine",
          sortable: false,
        },
        {
          text: "Dettagli",
          value: "dettagli",
        },
      ],
      items: [
        {
          id: 1,
          codice_cliente: "0106032126",
          ragione_sociale: "SIGMA BRAKES S.A.",
          data_consegna: "16/04/2020",
          peso_totale: "868,874",
          destinazione: "SIGMA BRAKES",
          num_ordine: "B1234BGH987798HJGYNNVT8963KIL",
        },
        {
          id: 2,
          codice_cliente: "0106032126",
          ragione_sociale: "SIGMA BRAKES S.A.",
          data_consegna: "16/04/2020",
          peso_totale: "868,874",
          destinazione: "SIGMA BRAKES",
          num_ordine: "B1234BGH987798HJGYNNVT8963KIL",
        },
        {
          id: 3,
          codice_cliente: "0106032126",
          ragione_sociale: "SIGMA BRAKES S.A.",
          data_consegna: "16/04/2020",
          peso_totale: "868,874",
          destinazione: "SIGMA BRAKES",
          num_ordine: "B1234BGH987798HJGYNNVT8963KIL",
        },
        {
          id: 4,
          codice_cliente: "0106032126",
          ragione_sociale: "SIGMA BRAKES S.A.",
          data_consegna: "16/04/2020",
          peso_totale: "868,874",
          destinazione: "SIGMA BRAKES",
          num_ordine: "B1234BGH987798HJGYNNVT8963KIL",
        },
        {
          id: 5,
          codice_cliente: "0106032126",
          ragione_sociale: "SIGMA BRAKES S.A.",
          data_consegna: "16/04/2020",
          peso_totale: "868,874",
          destinazione: "SIGMA BRAKES",
          num_ordine: "B1234BGH987798HJGYNNVT8963KIL",
        },
      ],
    };
  },
  methods: {
       gotoDashboard() {
      this.$router.push("/home/production");
    },
    onButtonClick() {
      this.$router.push("packaging/1/");
    },
  },
};
</script>

<style css scoped>
</style>
