var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"consuntivo_work_order"}},[_c('v-toolbar',{staticClass:"white--text",staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v(" Seleziona il Work Order ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"overflow-y":"auto"}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Codice Articolo"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_articolo_sel = '';
                  _vm.filtra();}}},[_vm._v(_vm._s(_vm.cod_articolo_sel == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}]),model:{value:(_vm.cod_articolo_sel),callback:function ($$v) {_vm.cod_articolo_sel=$$v},expression:"cod_articolo_sel"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Codice Commessa"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_commessa_sel = '';
                  _vm.filtra();}}},[_vm._v(_vm._s(_vm.cod_commessa_sel == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}]),model:{value:(_vm.cod_commessa_sel),callback:function ($$v) {_vm.cod_commessa_sel=$$v},expression:"cod_commessa_sel"}})],1),(_vm.filtro_cod_macchina_visible === true)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_consuntivo,"item-text":function (e) { return e.cod_macchina; },"item-value":"cod_macchina","label":"Cod. Macchina"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_macchina_sel = null;
                  _vm.filtra();}}},[_vm._v(_vm._s(_vm.cod_macchina_sel == null ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,3841890247),model:{value:(_vm.cod_macchina_sel),callback:function ($$v) {_vm.cod_macchina_sel=$$v},expression:"cod_macchina_sel"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_consuntivo,"item-text":"des","item-value":"des","label":"Lavorazione"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.lavorazione_sel = null;
                  _vm.filtra();}}},[_vm._v(_vm._s(_vm.lavorazione_sel == null ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}]),model:{value:(_vm.lavorazione_sel),callback:function ($$v) {_vm.lavorazione_sel=$$v},expression:"lavorazione_sel"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"fixed-header":"","headers":_vm.headers_consuntivo,"height":"65vh","items":_vm.items_wo_visualizzati,"item-class":_vm.evidenziaRitardo},scopedSlots:_vm._u([{key:"item.avvia_work_order",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('v-btn',{staticClass:"ma-5",attrs:{"color":"success","dark":"","large":""},on:{"click":function($event){return _vm.clickAvviaWO(item)}}},[_vm._v(" AVVIA WORK ORDER ")])],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"unset"},model:{value:(_vm.conferma_dialog),callback:function ($$v) {_vm.conferma_dialog=$$v},expression:"conferma_dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"indigo","flat":""}},[_c('v-toolbar-title',[_vm._v("Conferma attivazione")])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"font-size":"20px"}},[_vm._v(" Vuoi avviare il work order n. "),_c('b',[_vm._v(_vm._s(_vm.work_order_sel == null ? "" : _vm.work_order_sel.cod_commessa + "." + _vm.work_order_sel.cod_fase))]),_vm._v("? ")]),_c('v-divider'),_c('v-btn',{staticClass:"mx-2 my-2",staticStyle:{"font-size":"20px"},attrs:{"color":"error","dark":""},on:{"click":function($event){(_vm.conferma_dialog = !_vm.conferma_dialog),
            (_vm.work_order_sel = Object.assign({}))}}},[_vm._v(" ANNULLA ")]),_c('v-btn',{staticClass:"mx-2 my-2",staticStyle:{"font-size":"20px"},attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.addToCarrello(_vm.work_order_sel)}}},[_vm._v(" CONFERMA ")])],1),_c('v-overlay',{staticStyle:{"position":"absolute"},attrs:{"color":"rgba(0, 0, 0, 1)","value":_vm.caricamento_dati}},[_c('v-icon',{staticClass:"ruota",attrs:{"size":"100"}},[_vm._v("mdi-cog")]),_c('v-icon',{staticClass:"controruota",attrs:{"size":"100"}},[_vm._v("mdi-cogs")])],1)],1),_c('v-snackbar',{attrs:{"timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" "),_c('v-btn',{staticClass:"ml-5",staticStyle:{"background-color":"#ffffff"},attrs:{"color":_vm.snackbar_color,"fab":"","outlined":"","x-small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.snackbar_icon))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }