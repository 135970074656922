var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"unset"},model:{value:(_vm.cicloautodeletedialog),callback:function ($$v) {_vm.cicloautodeletedialog=$$v},expression:"cicloautodeletedialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Questo elemento verrà eliminato definitivamente")])],1),_c('v-card-text',{staticClass:"mt-6"},[_c('h3',{staticStyle:{"text-align":"left !important"}},[_vm._v(" Sei sicuro? vuoi eliminare questa elemento? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.cicloautodeletedialog = false}}},[_vm._v("ANNULLA")]),_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":_vm.deletecicloAutoControllo}},[_vm._v("CONFERMA")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.dialog_gestione_articoli),callback:function ($$v) {_vm.dialog_gestione_articoli=$$v},expression:"dialog_gestione_articoli"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v("Articoli")]),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","light":""},on:{"click":function($event){_vm.dialog_gestione_articoli = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticStyle:{"max-height":"500px","overflow-y":"auto"}},[_c('v-data-table',{attrs:{"headers":_vm.ArticoliHeaders,"items":_vm.ArticoliLista},scopedSlots:_vm._u([{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-col',{attrs:{"md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"left"},attrs:{"small":""},on:{"click":function($event){return _vm.selectArticolo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi")])])],1)]}}],null,true)})],1)],1)],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"witdh":"1200","elevation":hover ? 24 : 2}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Ciclo Autocontrollo")]),_c('v-spacer'),_c('Button',{attrs:{"colore_icona":"primary","idForm":"QUALITA","idOggetto":"CREA_CICLOAUTOCONTROLLO","mdi_icona":"mdi-plus","testo_tooltip":"Aggiungi Ciclo Autocontrollo"},on:{"buttonClick":_vm.nuovi_ciclo_auto_controllo}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,true)},[_c('span',[_vm._v("Torna al menu")])])],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"1"}}),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"6"}},[_c('v-text-field',{attrs:{"label":"Cod. Articolo","placeholder":"Cod. Articolo"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.searchArticolo.apply(null, arguments)}},model:{value:(_vm.cod_articolo),callback:function ($$v) {_vm.cod_articolo=$$v},expression:"cod_articolo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"top":"20px","right":"50px"},attrs:{"small":""},on:{"click":_vm.searchArticolo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Cerca")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers_ciclo_auto_contorollo,"items":_vm.items_ciclo_auto_contorollo},scopedSlots:_vm._u([{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"colore_icona":"primary","idForm":"QUALITA","idOggetto":"MODIFICA_CICLOAUTOCONTROLLO","mdi_icona":"mdi-pencil","testo_tooltip":"Dettaglio Ciclo Autocontrollo"},on:{"buttonClick":function($event){return _vm.dettaglio_ciclo_auto_conrtrollo(item)}}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"colore_icona":"error","idForm":"QUALITA","idOggetto":"ELIMINA_CICLOAUTOCONTROLLO","mdi_icona":"mdi-window-close","testo_tooltip":"Cancellare"},on:{"buttonClick":function($event){_vm.confermaDelCicloAutoConytollo = true;
                      _vm.setitem(item);}}})]}}],null,true)})],1)],1)],1)],1)]}}])}),_c('v-snackbar',{attrs:{"color":_vm.color,"content-class":"snackbar","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"margin-top":"-6rem"},attrs:{"fab":"","dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-window-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"size":64}},[_vm._v(_vm._s(_vm.mainIcon))]),_c('h2',{staticClass:"mt-n8 ml-16"},[_vm._v(_vm._s(_vm.snackbar_text))])],1),_c('v-overlay',{staticStyle:{"z-index":"10"},attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }