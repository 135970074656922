<template>
  <div>
    <ul v-for="(message) in messages" v-bind:key="message.id">
      <li class="clearfix" v-if="message.idFrom == utente_log">
        <div class="message-data align-right">
          <v-icon v-if="message.stato==0" color="primary">mdi-check-all</v-icon>
          <v-icon v-else>mdi-check</v-icon>
          <span class="message-data-time">{{message.timestamp | timestampToHour}}</span> &nbsp; &nbsp;
          <span class="message-data-name">| Tu</span>
          <i class="fa fa-circle me"></i>
        </div>
        <div
          v-if="message.type==0"
          class="message other-message float-right"
          style="white-space: pre-line;"
          v-html="message.content"
        ></div>
        <v-hover v-if="message.type==1">
          <template v-slot:default="{ hover }">
            <div
              class="message other-message float-right"
              @click="openImg(message)"
              style="cursor:pointer"
            >
              <img
                aspect-ratio="1"
                style=" max-height:300px;max-width:250px"
                cover
                :src="message.content"
              />
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <v-btn @click="dialogImmagini == true">Visualizza</v-btn>
                </v-overlay>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
        <v-hover v-if="message.type==2">
          <template v-slot:default="{ hover }">
            <div
              class="message other-message float-right"
              @click="downloadFile(message)"
              style="cursor:pointer"
            >
              <div>{{message.filename}}</div>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <v-btn @click="dialogImmagini == true">Scarica</v-btn>
                </v-overlay>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
      </li>

      <li v-else>
        <div class="message-data">
          <span class="message-data-name">
            <i class="fa fa-circle online"></i>
            <span v-if="gruppo_chat== null">{{utente_chat.nickname}}</span>
            <span v-else>{{message.nickname}}</span> |
          </span>
          <span class="message-data-time">{{message.timestamp | timestampToHour}}</span>
        </div>
        <div
          v-if="message.type==0"
          style="white-space: pre-line;"
          class="message my-message"
          v-html="message.content"
        ></div>
        <v-hover v-if="message.type==1">
          <template v-slot:default="{ hover }">
            <div class="message my-message" style="cursor:pointer" @click="openImg(message)">
              <img
                aspect-ratio="1"
                style=" max-height:300px; max-width:250px"
                cover
                :src="message.content"
              />
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <v-btn @click="dialogImmagini == true">Visualizza</v-btn>
                </v-overlay>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
        <v-hover v-if="message.type==2">
          <template v-slot:default="{ hover }">
            <div class="message my-message" style="cursor:pointer" @click="downloadFile(message)">
              <div>{{message.filename}}</div>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <v-btn @click="dialogImmagini == true">Scarica</v-btn>
                </v-overlay>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
      </li>
    </ul>
    <!-- <div class="message" v-for="(message,index) in messages" v-bind:key="index">
      <v-card
        color="#385F73"
        class="mb-2"
        style="max-width:300px"
        dark
        v-if="message.idFrom != utente_log"
      >
        <v-card-title>{{message.idFrom}}</v-card-title>
        <v-img :src="message.content" v-if="message.type==1" aspect-ratio="1.7"></v-img>
        <v-card-subtitle v-html="message.content" v-else></v-card-subtitle>
      </v-card>
      <v-card color="info" class="mb-2" style="max-width:300px;position:relative;right:0" dark v-else>
        <v-card-title>{{message.idFrom}}</v-card-title>
        <v-img :src="message.content" v-if="message.type==1" aspect-ratio="1.7"></v-img>
        <v-card-subtitle v-html="message.content" v-else></v-card-subtitle>
      </v-card>
    </div>-->
    <v-dialog
      v-model="dialogImmagini"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialogImmagini = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Galleria</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-zoomer-gallery
          style="width: 100vw; height: 93vh;"
          :list="immagini_chat"
          v-model="selIndex"
        ></v-zoomer-gallery>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["messages", "utente_chat", "gruppo_chat"],

  data() {
    return {
      selIndex: 0,
      overlay: true,
      utente_log: null,
      dialogImmagini: false,
      caricamento_messaggi: true,
      immagini_chat: [],
    };
  },
  components: {},
  watch: {
    messages() {
      this.immagini_chat = this.messages
        .filter((w) => w.type == 1)
        .map((w) => w.content);
    },
  },
  mounted() {
    console.log("messaggi", this.messages);
    this.utente_log = this.$store.state.user.data.id;
    console.log("utenteLog", this.utente_log);
    console.log("gruppo chat", this.gruppo_chat);
  },

  methods: {
    downloadFile(item) {
      window.open(item.content, "_blank");
    },
    openImg(item) {
      var index = this.immagini_chat.indexOf(item.content);
      this.selIndex = index;
      this.dialogImmagini = true;
    },
    imageLoad() {
      // this.$emit('imageLoad')
    },
  },
};
</script>


<style lang="scss">
  $green: #e8eaf6;
  $blue: #c5cae9;
  $orange: #e38968;
  $gray: #92959e;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  ul {
    list-style-type: none;
  }
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .chat {
    width: 100%;
    float: left;
    background: #f2f5f8;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    color: #434651;

    .chat-header {
      padding: 20px;
      border-bottom: 2px solid white;

      img {
        float: left;
      }

      .chat-about {
        float: left;
        padding-left: 10px;
        margin-top: 6px;
      }

      .chat-with {
        font-weight: bold;
        font-size: 16px;
      }

      .chat-num-messages {
        color: $gray;
      }

      .fa-star {
        float: right;
        color: #d8dadf;
        font-size: 20px;
        margin-top: 12px;
      }
    }

    .chat-history {
      padding: 30px 30px 20px;
      border-bottom: 2px solid white;
      overflow-y: scroll;
      height: 57vh;

      .message-data {
        margin-bottom: 15px;
      }

      .message-data-time {
        color: lighten($gray, 8%);
        padding-left: 6px;
      }

      .message {
        color: black;
        padding: 18px 20px;
        line-height: 26px;
        font-size: 16px;
        border-radius: 7px;
        margin-bottom: 30px;
        min-width: 350px;
        max-width: 50%;
        position: relative;

        &:after {
          bottom: 100%;
          left: 7%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: $green;
          border-width: 10px;
          margin-left: -10px;
        }
      }

      .my-message {
        background: $green;
      }

      .other-message {
        background: $blue;

        &:after {
          border-bottom-color: $blue;
          left: 93%;
        }
      }
    }
  }

  .online,
  .offline,
  .me {
    margin-right: 3px;
    font-size: 10px;
  }

  .online {
    color: $green;
  }

  .offline {
    color: $orange;
  }

  .me {
    color: $blue;
  }

  .align-left {
    text-align: left;
  }

  .align-right {
    text-align: right;
  }

  .float-right {
    float: right;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
</style>
