<template>
<v-container v-if="vedi_menu" height="1000">
  <div class="centerDiv" style="heigth: 100%; width: 100%; padding-top: 10px">

    <v-container class="pl-4" > 
      <v-parallax
          src = ../Top.jpg
          height="400"
      >

        <v-row>  
            <v-col cols="3" class="pa-2">
             <!--  LOGO Nel Top Banner:
              <v-card class="pa-4 mt-5"> 
                    <img
                      style="height: auto; width: 200px"
                      v-if="logo_azienda != 'undefined'"
                      :src="logo_azienda"
                      alt="Q-ERP"
                    />
                    <img
                      style="height: auto; width: 200px"
                      v-else
                      src="../assets/logo2.png"
                      alt="Q-ERP"
                    />          
                  <h1 style="color: #03a9f4; font-weight: normal" :key="testo_azienda">
                    {{ testo_azienda }}
                  </h1>
              </v-card>
              -->
            </v-col>     
        </v-row>
      </v-parallax>
    </v-container>

    <!--Gestione Tessere Menu Principale -->
    <v-row>
        <v-col cols="12" align="center">
            <h1 style="color: black; font-weight: bold" > Moduli Abilitati </h1>
        </v-col>      
    </v-row>
    <br>
    <v-divider inset> </v-divider>
    <br>

    <v-container class="pa-4 text-center ">
      <v-row
        class="fill-height"
        align="center"
      >

        <template v-for="(item, id_modulo) in moduli_sx">
          <v-col
            :key="id_modulo"
            cols="12"
            md="4"
          >
            <v-hover v-slot="{ hover }"
              open-delay="50"
            >
              
              <v-card 
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                height="225"
                rounded="3"
                outlined
              >
                <v-row>
                  <v-col cols="8">
                    <v-card-title>
                          {{ item.des_modulo }}
                    </v-card-title>
                        <v-divider ></v-divider>
                    <v-card-title>
                        <div>
                          <p class="ma-0 text-body-1 font-weight-bold  text-left">
                            Quality - ERP
                          </p>
                          <p class="text-caption font-weight-medium  text-left">
                            Modulo
                          </p>
                        </div>
                      
                    </v-card-title> 
                    <v-card-title>
                        <div>
                      <v-btn
                        outlined
                        color="teal"
                        @click="apriModulo(item)"
                      >
                        <v-icon>mdi-format-list-bulleted-square</v-icon>
                        Procedi
                      </v-btn> 

                        </div>
                      
                    </v-card-title>                   
    

                  </v-col>                
                  <v-col cols="4"  class="pr-3" align="center">
                    <v-img class="mt-1"
                      height="225px"
                      :src="item.img_modulo"
                      style="
                        border-radius: 0%;
                        height: 120px;
                        width: 120px;
                        line-height: 120px;
                        vertical-align: middle;
                      "
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="1" />
  
                </v-row>  


              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <!--
      <v-container style="margin-top: 50px">
        <v-row justify="space-around">
          <v-hover
            v-for="item in moduli_sx"
            :key="item.id_modulo"
            v-slot:default="{ hover }"
            style="border-radius: 50%; transition-duration: 0.5s"
          >
            <v-card
              :elevation="hover ? 12 : 2"
              style="
                border: 1px solid #03a9f4 !important;
                cursor: pointer;
                text-align: center;
              "
              @click="apriModulo(item)"
              color="white"
              height="150"
              width="150"
            >
              <p style="margin-top: 43%" v-if="item.img_modulo == null">
                {{ item.des_modulo }}
              </p>
              <v-img
                :src="item.img_modulo == null ? '' : item.img_modulo"
                style="
                  display: inline-block;
                  border-radius: 0%;
                  height: 100px;
                  line-height: 100px;
                  vertical-align: middle;
                  width: 100px;
                  margin-top: 25px;
                "
              ></v-img>
            </v-card>
          </v-hover>
        </v-row>
        <v-divider vertical> </v-divider>
      </v-container>
     --> 
    <v-dialog
      v-model="dialog_iframe"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="dialog_iframe = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe
          :src="link"
          style="display: block; height: 100vh"
          width="100%"
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</v-container>


</template>

<style scoped>
.bkground {
  background: url('../assets/Top.jpg');
  background-size: cover;
  height: 100vh;
}
</style>
<script>
import { encrypt } from "@/components/TOOLS/FormComponentPermissions/PermissionCrypto.js";
export default {
  props: {
    source: String,
  },
  data: () => ({
    link: "",
    moduli_dx: [],
    moduli_sx: [],
    nome_utente: localStorage.nominativo,
    id_azienda: localStorage.id_azienda,
    dialog_iframe: false,
    logo_azienda: localStorage.img_azienda,
    testo_azienda: "",
    vedi_menu: false,
    imageSrc: 'Top_.jpg',

      icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      items: [
        {
          title: 'New Releases',
          text: `It's New Release Friday`,
          subtext: 'Newly released songs. Updated daily.',
          img: '',
        },
        {
          title: 'Rock',
          text: 'Greatest Rock Hits',
          subtext: 'Lose yourself in rock tunes.',
          img: 'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
        },
        {
          title: 'Mellow Moods',
          text: 'Ambient Bass',
          subtext: 'Chill beats to mellow you out.',
          img: 'https://images.unsplash.com/photo-1542320868-f4d80389e1c4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3750&q=80',
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',

  }),
  created() { 
    
    if (localStorage.moduli_dx !== "undefined") {
      // console.log("qui");
      this.moduli_dx = JSON.parse(localStorage.moduli_dx);
    }
    if (localStorage.moduli_sx !== "undefined")
      this.moduli_sx = JSON.parse(localStorage.moduli_sx);
      
 
      
  },
  mounted() {

    if (localStorage.moduli_sx !== "undefined")
      this.moduli_sx = JSON.parse(localStorage.moduli_sx);

    //Istruzione che controlla se ho una sola voce di menu ed il profilo è maggiore di 1 e 2 ovvero super user e eamministratore quindi accedo direttamante alla pagina.
 //   if( this.moduli_sx.length == 1 && localStorage.id_profilo > 2) {
 //       this.vedi_menu = false
 //       this.apriModulo(this.moduli_sx[0])
 //   }   
 //   else this.vedi_menu = true
    this.vedi_menu = true

   // console.log( "AAAAAAA", this.moduli_sx);
    this.testo_azienda = this.$store.state.testo_azienda;
    this.getFormAutorizzazioni();
  },
  
  watch: {
    "$store.state.testo_azienda": function () {
      this.testo_azienda = this.$store.state.testo_azienda;
    },
  },
  methods: {

    apriModulo(item) {

      if (item.link != null) {
        // this.dialog_iframe = true;
        this.link = item.link;
        window.open(this.link, "_blank");
      } else {
        var request = {
          controller: "User",
          method: "POST",
          richiesta: {
            action: "getmenu",
            token: localStorage.user_token,
            id_utente: localStorage.id,
            db_name: "medical",
            id_modulo: item.id_modulo,
            id_profilo_utente: localStorage.id_profilo,
            id_azienda: localStorage.id_azienda,
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          localStorage.setItem("menu", JSON.stringify(res.data.menu));
          if (item.json_parametri != null) {
            let params = item.json_parametri.split(",");
            let parametroChiamate = params[0] == undefined ? null : params[0];
            let parametroComponente = params[1] == undefined ? null : params[1];
            localStorage.setItem("parametro_chiamata", parametroChiamate);
            localStorage.setItem("parametro_componente", parametroComponente);
          }
          this.$store.state.menu_sx = res.data.menu;
        });

          var request_tree = {
          controller: "User",
          method: "POST",
          richiesta: {
            action: "getmenutree",
            token: localStorage.user_token,
            id_utente: localStorage.id,
            id_modulo: item.id_modulo,
            id_azienda: localStorage.id_azienda,
          },
        };
        this.$store.dispatch("api", request_tree).then((res) => {
          localStorage.setItem("menutree", JSON.stringify(res.data.menutree));
        });

      }
    },
    getFormAutorizzazioni() {
      var request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getformautorizzazioni",
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", request).then(async (res) => {
        if (res.status == 200 && res.data.length > 0) {
          await encrypt(JSON.stringify(res.data));
        } else {
          await encrypt(JSON.stringify([]));
        }
      });
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
  },
};
</script>
<style scoped></style>
