import { render, staticRenderFns } from "./Tabella.vue?vue&type=template&id=91818bee&scoped=true&"
import script from "./Tabella.vue?vue&type=script&lang=js&"
export * from "./Tabella.vue?vue&type=script&lang=js&"
import style0 from "./Tabella.css?vue&type=style&index=0&id=91818bee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91818bee",
  null
  
)

export default component.exports