var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"},attrs:{"id":"listener_articolo_search"}},[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers,"height":"65vh","items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":"","rounded":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Articoli")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":"blue-grey lighten-5","small":""},on:{"click":_vm.nuovoArticolo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuovo Articolo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey lighten-5","small":""},on:{"click":_vm.cercaArticolo,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.cercaArticolo.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey lighten-5","small":""},on:{"click":_vm.clickIndietro}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"dense":"","hide-details":"","label":"Cod. Articolo"},model:{value:(_vm.cod_articolo_sel),callback:function ($$v) {_vm.cod_articolo_sel=$$v},expression:"cod_articolo_sel"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"items":_vm.tipo_list,"label":"Tipo Articolo","placeholder":"Articolo","item-text":"des","required":"","item-value":"id_tabella"},model:{value:(_vm.id_tipo_sel),callback:function ($$v) {_vm.id_tipo_sel=$$v},expression:"id_tipo_sel"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"items":_vm.famiglia_list,"item-text":"des","required":"","item-value":"id_tabella","placeholder":"Famiglia","label":"Tipo Famiglia"},model:{value:(_vm.id_famiglia_sel),callback:function ($$v) {_vm.id_famiglia_sel=$$v},expression:"id_famiglia_sel"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"items":_vm.fornitura_list,"item-text":"des","required":"","item-value":"id_tabella","placeholder":"Fornitura","label":"Tipo Fornitura"},model:{value:(_vm.id_tipo_fornitura_sel),callback:function ($$v) {_vm.id_tipo_fornitura_sel=$$v},expression:"id_tipo_fornitura_sel"}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickDettaglio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"float":"left"},attrs:{"color":"#009999"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio Articolo")])])]}}],null,true)}),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }