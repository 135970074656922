import { render, staticRenderFns } from "./ListaOrdini.vue?vue&type=template&id=68726bc7&scoped=true&"
import script from "./ListaOrdini.vue?vue&type=script&lang=js&"
export * from "./ListaOrdini.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68726bc7",
  null
  
)

export default component.exports