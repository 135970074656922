<template>
  <v-card>
    <v-snackbar v-model="snackbar" :vertical="true">
      {{ message_snackbar }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <div v-if="blnViewAllegato" id="pnlAllegati">
      <v-toolbar dense color="primary" dark>
        <v-toolbar-title>Allegati</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          v-if="blnViewButtonUpload"
          color="green"
          dark
          v-on:click="dialog_new_allegato=true"
        >
          Allega File
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-toolbar>
      <v-dialog v-model="dialog_new_allegato" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Caricamento nuovo allegato</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="files"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Seleziona File"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >{{ text }}</v-chip>

                      <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                      >+{{ files.length - 2 }} File(s)</span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_new_allegato = false">Esci</v-btn>
            <v-btn color="blue darken-1" text @click="salvaNuovoAllegato()">Upload</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog_new_allegato_waiting"
        persistent
        content
        content-class="centered-dialog"
        max-width="500px"
      >
        <v-card color="primary" dark>
          <v-container>
            <v-card-text>
              {{this.message_new_allegato_waiting}}
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-data-table :headers="headers" :items="allegato" class="elevation-1" disable-sort>
        <!-- <template v-slot:item.date_ins="{ item }">{{item.date_ins | formatDate}}</template>-->
        <!-- <template v-slot:item.data_validita="{ item }">{{item.data_validita | formatDate}}</template> -->
        <template v-slot:item.estensione="{ item }">
          <v-icon v-if="item.estensione == '.pdf' " class="mr-2" color="red darken-2">mdi-file-pdf</v-icon>
          <v-icon
            v-if="item.estensione == '.jpg'   || item.estensione == '.jpeg'|| item.estensione == '.png'"
            class="mr-2"
            color="yellow darken-2"
          >mdi-file-image</v-icon>
          <v-icon
            v-if="item.estensione == '.xlsx'  || item.estensione == '.xls' || item.estensione == '.csv'"
            class="mr-2"
            color="green darken-2"
          >mdi-microsoft-excel</v-icon>
          <v-icon
            v-if="item.estensione == '.doc'   || item.estensione == '.docx'|| item.estensione == '.dot'"
            class="mr-2"
            color="blue darken-2"
          >mdi-microsoft-word</v-icon>
          <v-icon
            v-if="item.estensione != '.doc'  && item.estensione != '.docx' && item.estensione != '.dot'   && 
                           item.estensione != '.jpg'  && item.estensione != '.jpeg' && item.estensione != '.png'   &&
                           item.estensione != '.xlsx' && item.estensione != '.xls'  && item.estensione != '.csv'   &&
                           item.estensione != '.doc'  && item.estensione != '.docx' && item.estensione != '.dot'   &&
                           item.estensione != '.pdf'"
            class="mr-2"
          >mdi-file</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                @click="downloadAllegato(item)"
                color="blue darken-2"
              >mdi-download</v-icon>
            </template>
            <span>Download</span>
          </v-tooltip>

          <v-tooltip left v-if="blnViewButtonDelete">
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="blnViewButtonDelete"
                v-on="on"
                class="mr-2"
                @click="deleteAllegato(item)"
                color="gray darken-2"
              >mdi-delete</v-icon>
            </template>
            <span>Cancella</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog_delete_file" max-width="290">
        <v-card>
          <v-card-title class="headline">Attenzione</v-card-title>
          <v-card-text>Cancella il file '{{editedAllegato.des_allegato}}' ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog_delete_file = false">Annulla</v-btn>
            <v-btn color="green darken-1" text @click="deleteAllegatoItem(editedAllegato)">Conferma</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import config from "../../../../public/config";
import axios from "axios";

export default {
  name: "AllegatoList",
  props: {
    id_rif: {
      type: Number,
      required: true
    },
    id_rif_dettaglio: {
      type: Number,
      required: true
    },
    famiglia: {
      type: Number,
      required: true
    },
    tipo: {
      type: String,
      required: true
    },
    blnViewButtonDelete: {
      type: Boolean,
      required: true
    },
    blnViewButtonUpload: {
      type: Boolean,
      required: true
    },
    strPath: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      config: config,
      dialog_edit_allegato: false, //bool per visualizzare dialog edit allegato
      blnViewAllegato: true, //bool per visualizzare panel allegati
      dialog_new_allegato: false,
      dialog_delete_file: false,
      date: new Date().toISOString().substr(0, 10),
      dialog_new_allegato_waiting: false,

      allegato: [], //array tutti gli allegati per id_rif e tipo

      files: [],
      editedIndexAllegato: -1,
      message_new_allegato_waiting: "",
      select: [],
      snackbar: false,
      message_snackbar: "",
      modal: false,
      headers: [
        { text: "", value: "estensione", type: "string" },
        { text: "Allegato", value: "des_allegato" },
        { text: "Rev", value: "num_revisione" },
        // { text: "Data Ins.", value: "date_ins", type: "date" },
        // { text: "Categoria", value: "des_categoria" },
        //{ text: "Validità", value: "data_validita", type: "date" },
        // { text: "", value: "des_azione" },
        { text: "Actions", value: "actions" }
      ],
      data: vm => ({
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        menu2: false
      }),
      cmbCategoria: [],
      editedAllegato: {
        id_padre: 0,
        des_allegato: "",
        num_revisione: 0,
        estensione: "",
        //data_validita: new Date().toISOString().substr(0, 10),
        famiglia: 0,
        des_azione: "",
        des_categoria: ""
      },
      editedFolder: {}
    };
  },

  created() {
    /*console.log(
      "enter allegato list" + this.id_rif + " " + this.id_rif_dettaglio
    );*/
    this.files = [];
    this.loadAllegati();
    //this.loadCategoria();
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },

  watch: {},

  methods: {
    //METODO LOAD ALLEGATI DELLA CARTELLA

    // gati() {
    //   const config = {
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   };
    //   var object = {
    //     action: "getallegatofilehistory",
    //     tipo: "UTENTE",
    //     token: localStorage.jwt,
    //     id_rif: this.id_rif,
    //     tipo_riga: "2",
    //     famiglia: this.famiglia
    //   };

    //   var data = JSON.stringify(object);
    //   axios
    //     .post(this.$store.state.config.ip + "api/Allegato", data, config)
    //     .then(response => {
    //       // JSON responses are automatically parsed.
    //       if (response.statusText != "No Content")
    //         this.allegato = response.data;
    //       else this.allegato = [];
    //     })
    //     .catch(e => {
    //       if (e.response.status == 401) {
    //         alert("Error" + e.response.status);
    //       }
    //       console.log(e);
    //     });
    // },
    loadAllegati() {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var object = {
        action: "getallegato",
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        id_rif_dettaglio: this.id_rif_dettaglio,
        tipo_riga: "2",
        famiglia: this.famiglia,
        db_name: "quickcheck"
      };

      var data = JSON.stringify(object);
      //console.log(JSON.stringify(object));
      axios
        .post(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          // JSON responses are automatically parsed.
          if (response.statusText != "No Content")
            this.allegato = response.data;
          else this.allegato = [];
        })
        .catch(e => {
          if (e.response.status == 401) {
            //alert("Error" + e.response.status);
            this.snackbar = true;
            this.message_snackbar =
              "Sessione scaduta: è necessario effettuare un nuovo login.";
          }
          console.log(e);
        });
    },
    salvaNuovoAllegato() {
      //console.log("carica nuovo");
      if (this.files.length == 0) {
        this.snackbar = true;
        this.message_snackbar = "Compila tutti i dati.";
        return;
      }
      this.message_new_allegato_waiting = "Caricamento in corso";
      this.dialog_new_allegato_waiting = true;
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("file" + i, file);
      }
      var finale = {
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        id_rif_dettaglio: this.id_rif_dettaglio,
        tipo_riga: "file",
        id_padre: this.editIdCartella,
        id_categoria: this.select["id_tabella"],
        data_validita: this.date,
        db_name: "quickcheck",
        id_utente: localStorage.id
      };
      formData.append("json", JSON.stringify(finale));
      //console.log(JSON.stringify(finale));

      this.select == [];
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(this.$store.state.config.ip + "api/Allegato/putAllegato", formData, config)
        .then(response => {
          //console.log("save");
          if (response.status == 200) {
            this.data = response.data;
            this.data.forEach(item => {
              this.allegato.push({
                id_allegato: item.id_allegato,
                des_allegato: item.des_allegato,
                id_padre: item.id_padre,
                num_revisione: item.num_revisione,
                // data_validita: new Date(item.data_validita).toISOString().substr(0, 10),
                estensione: item.estensione
              });
              //console.log("primo push", this.allegato);
              /*this.selectListAllegato.push({
                id_allegato: item.id_allegato,
                des_allegato: item.des_allegato,
                id_padre: item.id_padre,
                num_revisione: item.num_revisione,
                data_validita: item.data_validita,
                estensione: item.estensione
              });
              console.log("secondo push",this.selectListAllegato);
              console.log("found: ", item.id_allegato);*/
            });
            this.snackbar = true;
            this.message_snackbar = "File caricato";
          } else {
            this.snackbar = true;
            this.message_snackbar =
              "File non caricato.\nProblemi di connessione.";
          }
          this.dialog_new_allegato_waiting = false;
          this.message_new_allegato_waiting = "";
          this.files = [];
          this.dialog_new_allegato = false;
          this.blnViewAllegato = true;
          this.dialog_new_allegato_waiting = false;
          this.dialog_new_allegato = false;
        })
        .catch(e => {
          this.dialog_new_allegato_waiting = false;
          this.dialog_new_allegato = false;
          if (e.response.status == 401) {
            console.log(e);
            this.snackbar = true;
            this.message_snackbar = "Errore: " + e.response.status;
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
            this.files = [];
          }
        });
    },
    //METODO PER SCARICARE IL FILE
    downloadAllegato(item) {
      this.message_new_allegato_waiting = item.des_allegato + " in download";
      this.dialog_new_allegato_waiting = true;
      // axios({
      //   url:
      //     this.$store.state.config.ip +
      //     "api/Allegato/DownloadDocument?idAllegato=" +
      //     item.id_allegato, //your url
      //   method: "GET",
      //   responseType: "blob" // important
      // }).then(response => {
      //   let blob = new Blob([response.data]),
      //     downloadUrl = window.URL.createObjectURL(blob);

      //   let a = document.createElement("a");

      //   a.href = downloadUrl;
      //   a.download = item.des_allegato.replace(" ", "_") + item.estensione;
      //   document.body.appendChild(a);
      //   a.click();
      //   this.dialog_new_allegato_waiting = false;
      // });

      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var finale = {
        action: "downloadfile",
        token: localStorage.user_token,
        idAllegato: item.id_allegato,
        db_name: "quickcheck"
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          //console.log(response);
          const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          // const contentType = "application/pdf";
          const b64Data = response.data.file;

          const blob = b64toBlob(b64Data);
          const blobUrl = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = blobUrl;
          a.download = item.des_allegato.replace(" ", "_") + item.estensione;
          document.body.appendChild(a);
          a.click();
          //window.open(blobUrl);
          this.dialog_new_allegato_waiting = false;
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            localStorage.clear();
            this.dialog_new_allegato_waiting = false;
          }
          this.dialog_new_allegato_waiting = false;
        });
    },

    //METODO PER CANCELLARE IL FILE
    deleteAllegato(item) {
      this.dialog_delete_file = true;
      this.editedAllegato = item;
      this.editedIndexAllegato = -1;
    },

    deleteAllegatoItem(item) {
      //console.log(this.allegato[this.allegato.indexOf(item)]);
      this.dialog_edit_allegato = false;
      this.dialog_new_allegato_waiting = true;
      this.editedIndexAllegato = this.allegato.indexOf(item);
      //.log(this.editedIndexAllegato);
      this.message_new_allegato_waiting =
        "Cancellazione in corso del file " + item.des_allegato;
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var finale = {
        action: "deleteallegato",
        id_allegato: item.id_allegato,
        token: localStorage.user_token,
        id_utente: -13,
        db_name: "quickcheck"
      };
      var data = JSON.stringify(finale);
      axios
        .put(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(() => {
          this.allegato.splice(this.editedIndexAllegato, 1);
          this.dialog_new_allegato_waiting = false;
          this.message_new_allegato_waiting = "";
          this.dialog_delete_file = false;
          this.snackbar = true;
          this.message_snackbar = "File cancellato correttamente";
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            this.snackbar = true;
            this.message_snackbar = "Errore: " + e.response.status;
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
          }
        });
    }
  }
};
</script>