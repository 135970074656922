<template>
  <div id="gantt_gridview">
    <aside ref="tasks"><slot name="tasks" /></aside>
    <span @mousedown="dragStart($event, 0)" />
    <main ref="diagram"><slot name="diagram" /></main>
    <div @mousedown="dragStart($event, 1)" />
    <aside ref="resources"><slot name="resources" /></aside>
    <span @mousedown="dragStart($event, 0)" />
    <main ref="usage"><slot name="usage" /></main>
  </div>
</template>
<script>
export default {
  name: "GanttGridView",
  data() {
    return {
      grid: null,
      mutex: 0,
    };
  },
  mounted() {
    const that = this;
    //  TASKS
    this.tasks_listener = function () {
      if (that.mutex == 0) {
        that.$refs.diagram.scrollTop = that.$refs.tasks.scrollTop;
      }
    };
    this.tasks_hover = function () {
      that.mutex = 0;
    };
    // DIAGRAM
    this.diagram_listener = function () {
      if (that.mutex == 1) {
        that.$refs.tasks.scrollTop = that.$refs.diagram.scrollTop;
        that.$refs.usage.scrollLeft = that.$refs.diagram.scrollLeft;
      }
    };
    this.diagram_hover = function () {
      that.mutex = 1;
    };
    //  RESOURCES
    this.resources_listener = function () {
      if (that.mutex == 2) {
        that.$refs.usage.scrollTop = that.$refs.resources.scrollTop;
      }
    };
    this.resources_hover = function () {
      that.mutex = 2;
    };
    //  USAGE
    this.usage_listener = function () {
      if (that.mutex == 3) {
        that.$refs.resources.scrollTop = that.$refs.usage.scrollTop;
        that.$refs.diagram.scrollLeft = that.$refs.usage.scrollLeft;
      }
    };
    this.usage_hover = function () {
      that.mutex = 3;
    };

    this.$refs.tasks.addEventListener("scroll", this.tasks_listener);
    this.$refs.diagram.addEventListener("scroll", this.diagram_listener);
    this.$refs.resources.addEventListener("scroll", this.resources_listener);
    this.$refs.usage.addEventListener("scroll", this.usage_listener);

    this.$refs.tasks.addEventListener("mouseover", this.tasks_hover);
    this.$refs.diagram.addEventListener("mouseover", this.diagram_hover);
    this.$refs.resources.addEventListener("mouseover", this.resources_hover);
    this.$refs.usage.addEventListener("mouseover", this.usage_hover);
  },
  beforeDestroy() {
    this.$refs.tasks.removeEventListener("scroll", this.tasks_listener);
    this.$refs.diagram.removeEventListener("scroll", this.diagram_listener);
    this.$refs.resources.removeEventListener("scroll", this.resources_listener);
    this.$refs.usage.removeEventListener("scroll", this.usage_listener);

    this.$refs.tasks.removeEventListener("mouseover", this.tasks_hover);
    this.$refs.diagram.removeEventListener("mouseover", this.diagram_hover);
    this.$refs.resources.removeEventListener("mouseover", this.resources_hover);
    this.$refs.usage.removeEventListener("mouseover", this.usage_hover);
  },
  methods: {
    dragStart(event, direction = 0) {
      const target = event.target;
      const grid =
        this.grid == null
          ? document.getElementById("gantt_gridview")
          : this.grid;
      if (this.grid == null) {
        this.grid = grid;
      }

      const g_rect = grid.getBoundingClientRect();
      const t_rect = target.getBoundingClientRect();
      const that = this;

      this.drag = {
        offset: direction == 0 ? t_rect.width - g_rect.x : g_rect.y,
        grid: grid,
        move: function (event) {
          that.dragMove(event, direction);
        },
        stop: function () {
          that.dragStop();
        },
      };

      grid.addEventListener("mousemove", this.drag.move);
      grid.addEventListener("mouseup", this.drag.stop);
      grid.addEventListener("mouseleave", this.drag.stop);
    },
    dragMove(event, direction) {
      const offset = this.drag.offset;
      const grid = this.drag.grid;
      switch (direction) {
        case 0:
          grid.style.setProperty("--grid_width", event.clientX - offset + "px");
          break;
        case 1:
          grid.style.setProperty(
            "--grid_height",
            event.clientY - offset + "px"
          );
          break;
      }
    },
    dragStop() {
      this.drag.grid.removeEventListener("mousemove", this.drag.move);
      this.drag.grid.removeEventListener("mouseup", this.drag.stop);
      this.drag.grid.removeEventListener("mouseleave", this.drag.stop);
      delete this.drag;
    },
  },
};
</script>
<style scoped>
#gantt_gridview {
  display: block;
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  --grid_spacing: calc(4px / var(--zoomlevel));
  --grid_width: 400px;
  --grid_height: calc(100% - 40px);
  background-color: white;
}

#gantt_gridview > aside {
  height: calc(var(--grid_height) - calc(var(--grid_spacing) / 2));
  height: 100%;
  width: var(--grid_width);
  display: inline-block;
  vertical-align: top;
  overflow: scroll;
  position: relative;
}
#gantt_gridview > span {
  height: calc(var(--grid_height) - calc(var(--grid_spacing) / 2));
  height: 100%;
  width: var(--grid_spacing);
  display: inline-block;
  vertical-align: top;
  background-color: lightgrey;
  cursor: e-resize;
}
#gantt_gridview > main {
  height: calc(var(--grid_height) - calc(var(--grid_spacing) / 2));
  height: 100%;
  width: calc(100% - var(--grid_width) - var(--grid_spacing));
  display: inline-block;
  vertical-align: top;
  overflow: scroll;
  position: relative;
}
#gantt_gridview > aside:nth-of-type(2),
#gantt_gridview > span:nth-of-type(2),
#gantt_gridview > main:nth-of-type(2) {
  height: calc(calc(100% - var(--grid_height)) - calc(var(--grid_spacing) / 2));
  height: 0;
}
#gantt_gridview > div {
  width: 100%;
  height: var(--grid_spacing);
  display: block;
  background-color: lightgrey;
  cursor: n-resize;
}
</style>