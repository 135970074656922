<template>
  <div class="mt-3 ml-2">
    <v-card>
      <v-toolbar dense class="primary white--text" primary-title>
        <v-toolbar-title class="ml-2">Permission Forms Fields Production</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="salvaPermission" v-on="on" v-bind="attrs" small dense>
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>Crea Permissions</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn small light @click="clickChiudi">
          <v-icon color="error">mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-subtitle>
        <v-row>
          <v-col md="3" sm="3" cols="6">
            <v-autocomplete
              v-model="id_personale"
              :items="user_list"
              clearable
              label="Utente"
              placeholder="Utente"
              :item-text="(item) => item.nome + ' ' + item.cognome"
              required
              item-value="id_personale"
            >
            </v-autocomplete
          ></v-col>
          <v-col md="3" sm="3" cols="6">
            <v-autocomplete
              v-model="id_profilo"
              :items="profilo_list"
              clearable
              label="Profilo"
              placeholder="Profilo"
              item-text="des_profilo_utente"
              required
              item-value="id_profilo_utente"
            >
            </v-autocomplete
          ></v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="ModulesHeaders"
          :expanded.sync="expanded"
          :single-expand="true"
          item-key="id"
          show-expand
          :items="ModulesItems"
        >
          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <v-icon @click="expand(!isExpanded)" color="primary">mdi-file-tree</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="pa-0" style="background-color: white" :colspan="headers.length">
              <v-data-table
                :items="item.children"
                :expanded.sync="expanded1"
                :item-key="id"
                :headers="ModulesSubHeaders"
                :single-expand="true"
                :items-per-page="50"
                show-expand
                :hide-default-header="true"
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    class="pa-0"
                    style="background-color: white"
                    :colspan="headers.length"
                  >
                    <v-data-table
                      :headers="headersSingleForm"
                      :items="item.forms"
                      :item-key="item.key"
                      :items-per-page="50"
                      :hide-default-footer="true"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.flag_readonly`]="{ item }">
                        <v-checkbox
                          :false-value="0"
                          :true-value="1"
                          v-model="item.flag_readonly"
                        ></v-checkbox>
                      </template>
                      <template v-slot:[`item.flag_hide`]="{ item }">
                        <v-checkbox
                          :false-value="0"
                          :true-value="1"
                          v-model="item.flag_hide"
                        ></v-checkbox>
                      </template>
                      <template v-slot:[`item.flag_obbligatorio`]="{ item }">
                        <v-checkbox
                          :false-value="0"
                          :true-value="1"
                          v-model="item.flag_obbligatorio"
                        ></v-checkbox>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: [],
      expanded1: [],
      id_profilo: localStorage.id_profilo,
      id_personale: localStorage.id,
      user_list: [],
      profilo_list: [],
      overlay: false,
      ModulesItems: [
        {
          id: 1,
          FORM_ID: "MACCHINA",
          SOTTO_FORM: "MACCHINA",
          children: [
            {
              id: 1,
              FORM_ID: "MACCHINA",
              SOTTO_FORM: "MACCHINA",

              forms: [
                {
                  id: 1,
                  fieldname: "CREA_CODICE_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 2,
                  fieldname: "CREA_DES_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 3,
                  fieldname: "CREA_ID_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 4,
                  fieldname: "CREA_MATRICOLA_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 5,
                  fieldname: "CREA_MARCA_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CREA_DES_CONNESSIONE_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "CREA_ORA_DISPONIBILITA_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "CREA_COSTO_ORARIO_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "CREA_DATA_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "CREA_SPECIFICHE_MACCHINA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 2,
              FORM_ID: "MACCHINA",
              SOTTO_FORM: "MACCHINA_SCHEDA",
              forms: [
                {
                  id: 1,
                  fieldname: "CREA_SCHEDA_NUM_OPERAZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 2,
                  fieldname: "CREA_SCHEDA_ORE_GIORNI",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 3,
                  fieldname: "CREA_SCHEDA_DES",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 4,
                  fieldname: "MODIFICA_SCHEDA_NUM_OPERAZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 5,
                  fieldname: "MODIFICA_SCHEDA_ORE_GIORNI",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "MODIFICA_SCHEDA_DES",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "SCHEDA_DETT_NUM_OPERAZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "SCHEDA_DETT_TIPO_OPERAZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "SCHEDA_DETT_DUM",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "SCHEDA_DETT_METOCO_CACLCOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "SCHEDA_DETT_DES_CACLCOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "SCHEDA_DETT_ORE_GIORNI",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "SCHEDA_DETT_DES",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "SCHEDA_DETT_ORE_INTERVENTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "SCHEDA_DETT_ESITO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          FORM_ID: "ARTICOLO",
          SOTTO_FORM: "ARTICOLO",
          children: [
            {
              id: 1,
              FORM_ID: "ARTICOLO",
              SOTTO_FORM: "ARTICOLO",
              forms: [
                {
                  id: 1,
                  fieldname: "CODICE_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 2,
                  fieldname: "DES_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 3,
                  fieldname: "DES_AGGIUNTIVA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 4,
              FORM_ID: "ARTICOLO",
              SOTTO_FORM: "CARATTERISTICHE",
              forms: [
                {
                  id: 1,
                  fieldname: "CE_UNITA_MISURA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 2,
                  fieldname: "CE_FATTORE_CONVERSIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 3,
                  fieldname: "CE_LEAD_TIME",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 4,
                  fieldname: "CE_LUNGHEZZA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 5,
                  fieldname: "CE_LARGHEZZA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CE_ALTEZZA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "CE_COD_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "CE_RAGIONE_SOCIALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "CE_INDIRIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "CE_LOCALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "CE_QTA_MINIMA_ORDINE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "CE_QTA_LOTTO_MINIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "CE_TEMPO_PRODUZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "CE_TEMPO_TRANSITO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "CE_TEMPO_SPECIFICO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 5,
              FORM_ID: "ARTICOLO",
              SOTTO_FORM: "DISEGNO",
              forms: [
                {
                  id: 1,
                  fieldname: "DISEGNO_COD_ALTERNATIVO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "DISEGNO_REVISIONE_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "DISEGNO_INDICE_MODIFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "SPECIFICHE_TIMBRO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "SPECIFICHE_PREFISSO_LOTTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },

            {
              id: 6,
              FORM_ID: "ARTICOLO",
              SOTTO_FORM: "PACKAGING",
              forms: [
                {
                  id: 1,
                  fieldname: "PK_COD_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "PK_QTA_CONTENITORE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "PK_QTA_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "PK_QTA_IMBALLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "PK_ISTRUZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "PK_QTA_CONTENITORE_STD",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "PK_QTA_IMBALLO_STD",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 7,
              FORM_ID: "ARTICOLO",
              SOTTO_FORM: "ASSOCIAZIONI",
              forms: [
                {
                  id: 1,
                  fieldname: "AS_NUMERO_DISEGNO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "AS_COD_ALTERNATIVO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "AS_DES_ALTERNATIVO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "AS_QUANTITA_MINIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "AS_QUANTITA_MASSIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "AS_REVISIONE_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "AS_INDICE_MODIFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "AS_DES_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "AS_RAGIONE_SOCIALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "AS_INDIRIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "AS_LOCALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },

            {
              id: 8,
              FORM_ID: "ARTICOLO",
              SOTTO_FORM: "CICLOFASI",
              forms: [
                {
                  id: 1,
                  fieldname: "CF_COD_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "CF_RAGIONE_SOCIALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "CF_INDIRIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "CF_LOCALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "CF_QTA_MINIMA_ORDINE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CF_QTA_LOTTO_MINIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "CF_TEMPO_PRODUZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "CF_TEMPO_TRANSITO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "CF_TEMPO_SPECIFICO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "CF_LEAD_TIME",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "CF_TEMPO_SETUP",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "CF_PEZZI_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "CF_PEZZI_MAX",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "CF_DES_PROGRAMMA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "CF_DES_UTENSILE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 16,
                  fieldname: "CF_FATTORE_UTILIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 17,
                  fieldname: "CF_COD_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 18,
                  fieldname: "CF_TEMPO_STANDARD",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 19,
                  fieldname: "CF_PESO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
          ],
        },
        {
          id: 3,
          FORM_ID: "COMMESSA",
          SOTTO_FORM: "COMMESSA",
          children: [
            {
              id: 1,
              FORM_ID: "COMMESSA",
              SOTTO_FORM: "COMMESSA",
              forms: [
                {
                  id: 1,
                  fieldname: "CM_COD_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 2,
                  fieldname: "CM_DES_COMMESSA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 3,
                  fieldname: "CM_DES_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 4,
                  fieldname: "CM_COD_LOTTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 5,
                  fieldname: "CM_INDICE_DISEGNO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CM_RIFERIMENTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "MODIFICA_DES_COMMESSA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "MODIFICA_COD_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "MODIFICA_DES_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "MODIFICA_TIPO_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 11,
                  fieldname: "MODIFICA_FAMIGLIA_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "MODIFICA_FORNITURA_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "MODIFICA_DUM_COMMESSA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "MODIFICA_INDICE_DISEGNO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "MODIFICA_COD_LOTTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 16,
                  fieldname: "MODIFICA_RIFERIMENTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 8,
              FORM_ID: "COMMESSA",
              SOTTO_FORM: "COMMESSA_CICLO",
              forms: [
                {
                  id: 1,
                  fieldname: "CC_COD_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "CC_RAGIONE_SOCIALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "CC_INDIRIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "CC_LOCALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "CC_QTA_MINIMA_ORDINE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CC_QTA_LOTTO_MINIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "CC_TEMPO_PRODUZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "CC_TEMPO_TRANSITO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "CC_TEMPO_SPECIFICO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "CC_LEAD_TIME",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "CC_TEMPO_SETUP",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "CC_PEZZI_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "CC_PEZZI_MAX",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "CC_DES_PROGRAMMA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "CC_DES_UTENSILE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 16,
                  fieldname: "CC_FATTORE_UTILIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 17,
                  fieldname: "CC_COD_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 18,
                  fieldname: "CC_TEMPO_STANDARD",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 19,
                  fieldname: "CC_PESO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
          ],
        },
        {
          id: 4,
          FORM_ID: "IMPOSTAZIONI",
          SOTTO_FORM: "IMPOSTAZIONI",
          children: [
            {
              id: 1,
              FORM_ID: "IMPOSTAZIONI",
              SOTTO_FORM: "FASE_STANDARD",
              forms: [
                {
                  id: 1,
                  fieldname: "FS_COD_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "FS_RAGIONE_SOCIALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "FS_INDIRIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "FS_LOCALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "FS_QTA_MINIMA_ORDINE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "FS_QTA_LOTTO_MINIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "FS_TEMPO_PRODUZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "FS_TEMPO_TRANSITO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "FS_TEMPO_SPECIFICO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "FS_LEAD_TIME",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "FS_TEMPO_SETUP",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "FS_PEZZI_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "FS_PEZZI_MAX",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "FS_DES_PROGRAMMA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "FS_DES_UTENSILE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 16,
                  fieldname: "FS_FATTORE_UTILIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 17,
                  fieldname: "FS_COD_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 18,
                  fieldname: "FS_TEMPO_STANDARD",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 19,
                  fieldname: "FS_PESO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 2,
              FORM_ID: "IMPOSTAZIONI",
              SOTTO_FORM: "CICLO_STANDARD",
              forms: [
                {
                  id: 1,
                  fieldname: "CS_COD_ANAGRAFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "CS_RAGIONE_SOCIALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "CS_INDIRIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "CS_LOCALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "CS_QTA_MINIMA_ORDINE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CS_QTA_LOTTO_MINIMO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "CS_TEMPO_PRODUZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "CS_TEMPO_TRANSITO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "CS_TEMPO_SPECIFICO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "CS_LEAD_TIME",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "CS_TEMPO_SETUP",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "CS_PEZZI_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "CS_PEZZI_MAX",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 14,
                  fieldname: "CS_DES_PROGRAMMA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 15,
                  fieldname: "CS_DES_UTENSILE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 16,
                  fieldname: "CS_FATTORE_UTILIZZO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 17,
                  fieldname: "CS_COD_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 18,
                  fieldname: "CS_TEMPO_STANDARD",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 19,
                  fieldname: "CS_PESO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
          ],
        },
        {
          id: 5,
          FORM_ID: "QUALITA",
          SOTTO_FORM: "QUALITA",
          children: [
            {
              id: 1,
              FORM_ID: "QUALITA",
              SOTTO_FORM: "QUALITA_COMMON_HEADER",
              forms: [
                {
                  id: 1,
                  fieldname: "QH_FORNITORE_SEARCH",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "QH_COD_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "QH_DESCRIZIONE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "QH_ARTICOLO_ESTERNA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "QH_INDICE_MODIFICA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "QH_FASE_ATTUALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "QH_FASE_SUCCESSIVA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 2,
              FORM_ID: "QUALITA",
              SOTTO_FORM: "BENESTARE",
              forms: [
                {
                  id: 1,
                  fieldname: "BENESTARE_RIGA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "BENESTARE_TOLLERANZA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "BENESTARE_TOLLERANZA_DA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "BENESTARE_TOLLERANZA_A",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 3,
              FORM_ID: "QUALITA",
              SOTTO_FORM: "PIANOCONTROLLO",
              forms: [
                {
                  id: 1,
                  fieldname: "PIANOCONTROLLO_VAL_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "PIANOCONTROLLO_VAL_MAX",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "PIANOCONTROLLO_VAL_NOMINALE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 4,
              FORM_ID: "QUALITA",
              SOTTO_FORM: "CICLOAUTOCONTROLLO",
              forms: [
                {
                  id: 1,
                  fieldname: "CA_RIGA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "CA_TOLLERANZA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "CA_VAL_MIN",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "CA_VAL_MAX",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "CA_VALORE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "CA_DES_CAMPIONE_MASTER",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 7,
                  fieldname: "CA_AUTO_CONTROLLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 8,
                  fieldname: "CA_CONTROLLO_SUPPLEMENTARE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 9,
                  fieldname: "CA_CONTROLLO_QUALITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 10,
                  fieldname: "CA_AUTO_CONTROLLO_REG",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 11,
                  fieldname: "CA_CONTROLLO_SUPPLEMENTARE_REG",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 12,
                  fieldname: "CA_CONTROLLO_QUALITA_REG",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 13,
                  fieldname: "CA_FREQUENZA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
          ],
        },
        {
          id: 6,
          FORM_ID: "CONSUNTIVI",
          SOTTO_FORM: "CONSUNTIVI",
          children: [
            {
              id: 1,
              FORM_ID: "CONSUNTIVI",
              SOTTO_FORM: "C_PEZZI",
              forms: [
                {
                  id: 1,
                  fieldname: "PEZZI_RIFERIMENTO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "PEZZI_COD_COMMESSA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "PEZZI_COD_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "PEZZI_COD_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 5,
                  fieldname: "PEZZI_DES_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
                {
                  id: 6,
                  fieldname: "PEZZI_QUANTITA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
            {
              id: 2,
              FORM_ID: "CONSUNTIVI",
              SOTTO_FORM: "C_TEMPI",
              forms: [
                {
                  id: 1,
                  fieldname: "TEMPI_NUMERO_COMMESSA",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 2,
                  fieldname: "TEMPI_COD_ARTICOLO",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 3,
                  fieldname: "TEMPI_COD_FASE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },

                {
                  id: 4,
                  fieldname: "TEMPI_ORE",
                  flag_readonly: 0,
                  flag_hide: 0,
                  flag_obbligatorio: 0,
                },
              ],
            },
          ],
        },
      ],
      headersSingleForm: [
        {
          text: "Field-Name",
          value: "fieldname",
        },
        {
          text: "Read-only",
          value: "flag_readonly",
        },
        {
          text: "Flag-hide",
          value: "flag_hide",
        },
        {
          text: "Flag_obbligatorio",
          value: "flag_obbligatorio",
        },
      ],
      ModulesHeaders: [
        {
          value: "edit",
        },
        {
          text: "nome_module",
          value: "SOTTO_FORM",
        },
      ],
      ModulesSubHeaders: [
        {
          value: "edit",
        },
        {
          text: "",
          value: "SOTTO_FORM",
        },
      ],
    };
  },

  methods: {
    // get Permission
    getPermission() {
      this.overlay = true;
      let getButtonPermission = {
        controller: "Auth",
        method: "POST",
        richiesta: {
          action: "gettextfieldauth",
          id_utente: localStorage.id,
          id_profilo_utente: localStorage.id_profilo,
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", getButtonPermission).then(async (res) => {
        if (res.status == 200) {
          this.overlay = false;
          this.PermissionButton = await res.data;
        }
      });
    },

    // UserList Getter
    getUtente() {
      let getUser = {
        controller: "User",
        method: "POST",
        richiesta: {
          action: "getUtenti",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", getUser).then((res) => {
        this.user_list = res.data.result.utenti;
      });
    },
    // Profile List Getter
    getProfili() {
      let getProfili = {
        controller: "Modulo",
        method: "POST",
        richiesta: {
          action: "getProfili",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", getProfili).then((res) => {
        this.profilo_list = res.data.result.profili;
      });
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    salvaPermission() {
      console.log("this.ModulesItem :>> ", this.ModulesItems);
    },
  },
  mounted() {
    this.getPermission();
    this.getUtente();
    this.getProfili();
  },
};
</script>
