<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"> Fotocamera </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        @click="
          clickChiudi();
          stopCameraStream();
        "
        small
      >
        <v-icon color="error"> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <div id="app" class="web-camera-container">
        <!-- <div class="camera-button">
          <v-btn
            type="button"
            class="button is-rounded"
            :class="{
              'is-primary': !isCameraOpen,
              'is-danger': isCameraOpen,
            }"
            @click="toggleCamera"
          >
            <span class="primary--text" v-if="!isCameraOpen"
              >Attiva Fotocamera</span
            >
            <span class="primary--text" v-else>Chiudi Fotocamera</span>
          </v-btn>
        </div> -->

        <div
          v-if="isCameraOpen"
          v-show="!isLoading"
          class="camera-box"
          :class="{ flash: isShotPhoto }"
        >
          <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

          <video
            v-show="!isPhotoTaken"
            ref="camera"
            :width="450"
            :height="337.5"
            autoplay
          ></video>

          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            ref="canvas"
            :width="450"
            :height="337.5"
          ></canvas>
        </div>

        <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
          <v-btn @click="takePhoto">
            <v-icon color="primary"> mdi-radiobox-marked </v-icon>
          </v-btn>
        </div>

        <!-- <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
    <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">
      Download
    </a>
  </div> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Camera",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
    };
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        // console.log('this.visible', this.visible)
        if (this.visible === true) {
          this.toggleCamera("on");
        }
      },
    },
  },

  methods: {
    clickChiudi() {
      this.toggleCamera("off");
      this.$emit("click_chiudi");
    },
    toggleCamera(arg) {
      if (arg === "off") {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.$nextTick(() => this.createCameraElement());
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;

          alert(
            "May the browser didn't support or there is some errors.",
            error
          );
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");

      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      const img = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");

      this.$emit("getimage", img);
      //   console.log(img)
      this.$emit("scattata_foto");
      this.stopCameraStream();
      this.$emit("click_chiudi");
      this.isCameraOpen = false;
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  display: flex;
  justify-content: center;
}

.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 450;

  // .camera-button {
  //   margin-bottom: 2rem;
  // }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450;
      height: 337.5;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
