<template>
  <v-container fluid class="ml-5 mr-5" style="height:100vh">
    <v-card class dense flat>
      <v-card-actions>
        <v-list-item-avatar class="mt-0 mb-0 pt-0 pb-0">
          <img src="../..//assets/logo_chat.png" style="border-radius:unset!important" />
        </v-list-item-avatar>QUICK Chat
        <v-spacer></v-spacer>
        <v-row align="center" justify="end">
          <b class="mr-4">Accesso effettuato con : {{utente}}</b>
        </v-row>
        <v-btn color="error" text dark fab @click="$router.replace('/home')" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <Dashboard />
  </v-container>
</template>
<script>
import Dashboard from "../../components/CHAT/Dashboard";
export default {
  components: {
    Dashboard,
  },
  data: () => ({}),

  created() {
    this.utente = this.$store.state.user.data.email;
    // fb.message
    //   .requestPermission()
    //   .then(function() {
    //     console.log("Notification permission granted.");
    //     console.log( fb.message.getToken())
    //     return fb.message.getToken();
    //   })
    //   .then(function(token) {
    //     console.log("token",token); // Display user token
    //   })
    //   .catch(function(err) {
    //     // Happen if user deney permission
    //     console.log("Unable to get permission to notify.", err);
    //   });

    // fb.message.onMessage(function(payload) {
    //   console.log("onMessage", payload);
    // });
  },
  methods: {},
};
</script>