<template>
  <div>
    <div class="chat text-left mt-2 ml-2 mb-2" style="margin-rigth:20px!important">
      <v-snackbar v-model="snackbar">{{ snackbar_text }}</v-snackbar>
      <v-dialog v-model="dialog_img_profile" persistent max-width="290">
        <v-card>
          <v-img :src="img_profilo_sel" height="200px">
            <v-row class="mb-0 pb-0 mt-0 pt-0">
              <v-col class="align-right mr-2 mb-0 pb-0 mt-0 pt-0">
                <v-btn fab class @click="dialog_img_profile = false" text color="error" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-img>
        </v-card>
      </v-dialog>
      <div class="chat-header clearfix">
        <img
          @click="dialog_img_profile= true;img_profilo_sel = utente_chat.photoUrl"
          style="cursor:pointer"
          v-if="utente_chat != null"
          v-show="utente_chat.photoUrl"
          :src="utente_chat.photoUrl"
          alt="avatar"
          class="avatar"
        />

        <div class="chat-about" v-if="utente_chat != null">
          <div class="chat-with">{{utente_chat.nickname}}</div>
          <div class="chat-num-messages">{{utente_chat.aboutMe}}</div>
        </div>
        <div class="chat-about" v-else>
          <div class="chat-with">{{gruppo_chat.des_gruppo}}</div>
        </div>
      </div>
      <!-- end chat-header -->

      <div class="chat-history" ref="messagesContainer">
        <div class="text-center" align="center">
          <p
            v-show="caricamento_messaggi"
          >Selezionare una utente o gruppo prima di iniziare a chattare</p>
        </div>
        <Messaggio
          v-show="!caricamento_messaggi"
          :messages="chatMessages"
          :utente_chat="utente_chat"
          :gruppo_chat="gruppo_chat"
        ></Messaggio>
      </div>
      <!-- end chat-history -->

      <!-- end chat-message -->
      <v-flex class="d-flex align-start" v-if="caricamento_messaggi == false">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small class="ml-5 mt-1" color="#FAFAFA" v-on="on" v-bind="attrs">
              <v-icon>mdi-emoticon-happy-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <VEmojiPicker @select="selectEmoji" />
          </v-card>
        </v-menu>
        <div class="chat-message clearfix">
          <div
            class="div_contenitore_anteprima"
            :key="contatore_foto"
            style="float: left!important;"
          >
            <v-row>
              <v-col v-for="(img,index) in fileMessaggio" :key="index" cols="2">
                <v-card outlined height="130" max-height="130">
                  <v-btn
                    fab
                    absolute
                    class="mr-n5"
                    @click="rimuoviImgUpload(img)"
                    text
                    color="error"
                    small
                    top
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <img
                    height="125"
                    max-height="125"
                    style="max-width:175px"
                    contain
                    aspect-ratio="1"
                    :id="index"
                    src="prova1"
                    class="Preview_image"
                  />
                </v-card>
              </v-col>
            </v-row>
          </div>

          <!-- <div class="float-left">
          <v-file-input
            v-model="fileMessaggio"
            v-on:change="Preview_image($event)"
            small
            hide-input
            multiple
            label="File input"
          ></v-file-input>
          <v-btn v-show="fileMessaggio == null" icon @click="inviaMessaggio">
            <v-icon>mdi-send</v-icon>
          </v-btn>
          <v-btn v-show="fileMessaggio != null" icon @click="inviaMessaggioAllegati">
            <v-icon>mdi-send</v-icon>
          </v-btn>
          </div>-->
        </div>
        <v-textarea
          v-show="fileMessaggio == null"
          class="rounded-xs ml-5 mr-5"
          name="message-to-send"
          v-on:keyup.enter="inviaMessaggio"
          v-model="messaggio"
          placeholder="Scrivi il tuo messaggio"
          rows="1"
          background-color="#FAFAFA"
          auto-grow
          solo
          ref="textareaMessaggio"
          style=" font-size: 18px; font-weight: bold;max-height:150px;overflow-y:auto"
        ></v-textarea>
        <v-btn
          v-show="fileMessaggio == null && messaggio != ''"
          fab
          small
          class="ml-5 mt-1 mr-5"
          color="#FAFAFA"
          @click="inviaMessaggio"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
        <v-btn
          v-show="fileMessaggio != null"
          fab
          small
          class="ml-5 mt-1 mr-5"
          color="#FAFAFA"
          @click="inviaMessaggioAllegati"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
        <v-btn fab small class="ml-5 mt-1 mr-5" color="#FAFAFA" v-show="messaggio== ''">
          <v-file-input
            class="pa-0 ma-0 ml-2"
            v-model="fileMessaggio"
            v-on:change="Preview_image($event)"
            medium
            hide-input
            multiple
          ></v-file-input>
        </v-btn>
      </v-flex>
    </div>
  </div>
  <!-- end chat -->

  <!-- <div >
   
    <v-toolbar color="blue" dark>
      <v-toolbar-title>Messaggia con: {{utente_chat.nickname}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text class="chat-container" ref="chatContainer">
      <Messaggio :messages="chatMessages" :key="chatMessages"></Messaggio>
    </v-card-text>
    <div class="typer">
      <v-text-field
      small 
        class="mt-7"
        v-on:keyup.enter="inviaMessaggio"
        v-model="messaggio"
        :append-outer-icon="messaggio ? 'mdi-send' : 'mdi-microphone'"
        filled
        clear-icon="mdi-close-circle"
        clearable
        label="Messaggio"
        type="text"
        @click:append-outer="inviaMessaggio"
        @click:clear="clearMessage"
      ></v-text-field>
    </div>
  </div>-->
</template>
<script>
import VEmojiPicker from "v-emoji-picker";
import * as fb from "../../plugins/firebase";
import Messaggio from "./Messaggio.vue";
export default {
  components: { Messaggio, VEmojiPicker },
  props: ["utente_chat", "gruppo_chat"],
  data() {
    return {
      dialog_img_profile: false,
      img_profilo_sel: null,
      menu: false,
      contatore_foto: 0,
      snackbar: false,
      snackbar_text: "",
      messaggio: "",
      chatMessages: [],
      currentRef: {},
      loading: false,
      totalChatHeight: 0,
      messages: [],
      overlay: true,
      utente_selezionato: {},
      gruppo_selezionato: {},
      groupChatId: "",
      caricamento_messaggi: true,
      fileMessaggio: null,
    };
  },

  watch: {
    utente_selezionato(val) {
      if (val.id != undefined) {
        this.overlay = false;
      }
    },
    gruppo_selezionato(val) {
      if (val.id_gruppo != undefined) {
        this.overlay = false;
      }
    },
  },
  mounted() {
    if (this.gruppo_chat == null) {
      this.caricamento_messaggi = true;
      this.utente_selezionato = this.utente_chat;
      if (
        parseInt(this.$store.state.user.data.creato) <=
        parseInt(this.utente_chat.createAt)
      ) {
        this.groupChatId =
          this.$store.state.user.data.id + "-" + this.utente_chat.id;
      } else {
        this.groupChatId =
          this.utente_chat.id + "-" + this.$store.state.user.data.id;
      }
      this.loadChat("utenti");
    } else {
      this.caricamento_messaggi = true;
      this.gruppo_selezionato = this.gruppo_chat;
      this.loadChat("gruppi");
    }
  },
  methods: {
    selectEmoji(emoji) {
      console.log(emoji);
      this.messaggio += emoji.data;
      this.menu = false;
      this.$refs.textareaMessaggio.focus();
    },
    // CHAT PERSONE
    inviaMessaggioAllegati() {
      this.fileMessaggio.forEach((img) => {
        this.picture = null;
        const storageRef = fb.storage.ref(`${img.name}`).put(img);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            console.log(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              const now = new Date();
              var filetype = img.name.split(".").pop();
              var tipo = 1;
              if (
                !filetype.includes("jpg") &&
                !filetype.includes("png") &&
                !filetype.includes("jpeg") &&
                !filetype.includes("SVG") &&
                !filetype.includes("TIFF")
              ) {
                tipo = 2;
              }

              var messaggio = url;
              fb.db
                .collection("messages")
                .doc(this.groupChatId)
                .collection(this.groupChatId)
                .doc(now.getTime().toString())
                .set({
                  idFrom: this.$store.state.user.data.id,
                  idTo: this.utente_chat.id,
                  timestamp: now.getTime().toString(),
                  content: messaggio,
                  filename: img.name,
                  type: tipo,
                  stato: 1,
                })
                .then(() => {
                  //this.loadChat();
                  //salvo ultimo mex
                  fb.db
                    .collection("utenti")
                    .doc(this.$store.state.user.data.id)
                    .update({
                      chattingWith: this.utente_chat.id,
                      ultimoMex: now.getTime().toString(),
                    });
                  fb.db
                    .collection("ultimi_messaggi")
                    .where("chatId", "==", this.groupChatId)
                    .get()
                    .then((querySnapshot) => {
                      if (querySnapshot.docs.length == 0) {
                        fb.db
                          .collection("ultimi_messaggi")
                          .doc(this.groupChatId)
                          .set(
                            {
                              chatId: this.groupChatId,
                              idFrom: this.$store.state.user.data.id,
                              idTo: this.utente_chat.id,
                              timestamp: now.getTime().toString(),
                              content: messaggio,
                              type: 0,
                              stato: 1,
                            },
                            { merge: true }
                          );
                      } else {
                        try {
                          querySnapshot.forEach(function (doc) {
                            doc.ref.update({
                              content: messaggio,
                              timestamp: now.getTime().toString(),
                              idFrom: this.$store.state.user.data.id,
                              idTo: this.utente_chat.id,
                              type: 0,
                              stato: 1,
                            });
                            // If you need the doc id, use `doc.id`
                          });
                        } catch (error) {
                          fb.db
                            .collection("ultimi_messaggi")
                            .doc(this.groupChatId)
                            .set(
                              {
                                chatId: this.groupChatId,
                                idFrom: this.$store.state.user.data.id,
                                idTo: this.utente_chat.id,
                                timestamp: now.getTime().toString(),
                                content: messaggio,
                                type: 0,
                                stato: 1,
                              },
                              { merge: true }
                            );
                        }
                      }
                    });
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            });
          }
        );
      });
      this.fileMessaggio = null;
    },
    rimuoviImgUpload(img) {
      var index = this.fileMessaggio.indexOf(img);
      this.fileMessaggio.splice(index, 1);
      if (this.fileMessaggio.length == 0) {
        this.fileMessaggio = null;
      }
      this.Preview_image(this.fileMessaggio);
    },
    Preview_image(e) {
      this.contatore_foto++;
      console.log("IMG", this.fileMessaggio);
      if (e) {
        var cont = 0;

        e.forEach((el) => {
          var checkExist = setInterval(function () {
            if (document.getElementById(cont)) {
              clearInterval(checkExist);
              console.log("elimg", document.getElementById(cont));
              console.log("el", URL.createObjectURL(el));
              document
                .getElementById(cont)
                .setAttribute("src", URL.createObjectURL(el));
              cont++;
            }
          }, 100);
        });
      }
    },
    clearMessage() {
      this.messaggio = "";
    },
    getImg() {},
    inviaMessaggio(event) {
      if (event.shiftKey != true) {
        if (
          this.messaggio == "" ||
          this.messaggio == " " ||
          this.messaggio == undefined
        ) {
          this.snackbar_text = "INSERIRE UN MESSAGGIO VALIDO";
          this.snackbar = true;
          return;
        }
        var test = this.messaggio.replace(/(\r\n|\n|\r)/gm, "");
        if (test == "" || test == " ") {
          this.snackbar_text = "INSERIRE UN MESSAGGIO VALIDO";
          this.snackbar = true;
          this.messaggio = "";
          return;
        }
        const now = new Date();
        //const secondsSinceEpoch = now.getTime();
        if (this.utente_chat != null) {
          var messaggio = this.messaggio;
          this.messaggio = "";
          fb.db
            .collection("messages")
            .doc(this.groupChatId)
            .collection(this.groupChatId)
            .doc(now.getTime().toString())
            .set({
              idFrom: this.$store.state.user.data.id,
              idTo: this.utente_chat.id,
              idUtenti: [this.$store.state.user.data.id, this.utente_chat.id],
              timestamp: now.getTime().toString(),
              content: messaggio,
              type: 0,
              stato: 1,
            })
            .then(() => {
              //this.loadChat();
              //salvo ultimo mex
              fb.db
                .collection("utenti")
                .doc(this.$store.state.user.data.id)
                .update({
                  chattingWith: this.utente_chat.id,
                  ultimoMex: now.getTime().toString(),
                });
              fb.db
                .collection("ultimi_messaggi")
                .where("chatId", "==", this.groupChatId)
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.docs.length == 0) {
                    fb.db
                      .collection("ultimi_messaggi")
                      .doc(this.groupChatId)
                      .set(
                        {
                          chatId: this.groupChatId,
                          idFrom: this.$store.state.user.data.id,
                          idTo: this.utente_chat.id,
                          idUtenti: [
                            this.$store.state.user.data.id,
                            this.utente_chat.id,
                          ],
                          timestamp: now.getTime().toString(),
                          content: messaggio,
                          type: 0,
                          stato: 1,
                        },
                        { merge: true }
                      );
                  } else {
                    try {
                      querySnapshot.forEach(function (doc) {
                        doc.ref.update({
                          content: messaggio,
                          timestamp: now.getTime().toString(),
                          idFrom: this.$store.state.user.data.id,
                          idTo: this.utente_chat.id,
                          type: 0,
                          stato: 1,
                        });
                        // If you need the doc id, use `doc.id`
                      });
                    } catch (error) {
                      fb.db
                        .collection("ultimi_messaggi")
                        .doc(this.groupChatId)
                        .set(
                          {
                            chatId: this.groupChatId,
                            idFrom: this.$store.state.user.data.id,
                            idTo: this.utente_chat.id,
                            timestamp: now.getTime().toString(),
                            content: messaggio,
                            type: 0,
                            stato: 1,
                          },
                          { merge: true }
                        );
                    }
                  }
                });
              var aggiorna = {
                arrivo: "utente",
                id: this.utente_chat.id,
              };
              this.$emit("aggiornaModelItems", aggiorna);
            })

            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        } else {
          messaggio = this.messaggio;
          this.messaggio = "";
          fb.db
            .collection("group_messages")
            .doc(this.gruppo_chat.id_gruppo)
            .collection(this.gruppo_chat.id_gruppo)
            .doc(now.getTime().toString())
            .set({
              idFrom: this.$store.state.user.data.id,
              timestamp: now.getTime().toString(),
              content: messaggio,
              nickname: this.$store.state.user.data.displayName,
              type: 0,
              stato: 1,
            })
            .then(() => {
              //this.loadChat();
              //salvo ultimo mex
              fb.db
                .collection("t_gruppo")
                .doc(this.gruppo_chat.id_gruppo)
                .update({
                  content: messaggio,
                  nickname: this.$store.state.user.data.displayName,
                });
              var aggiorna = {
                arrivo: "gruppo",
                id: this.gruppo_chat.id_gruppo,
              };
              this.$emit("aggiornaModelItems", aggiorna);
            })

            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        }
      }
    },
    loadChat(tipo) {
      const now = new Date();
      if (tipo == "utenti") {
        fb.db
          .collection("messages")
          .doc(this.groupChatId)
          .collection(this.groupChatId)
          .onSnapshot((querySnapshot) => {
            this.chatMessages = querySnapshot.docs.map((doc) => doc.data());
            this.scrollToEnd();
            //setta come letti
          });

        if (this.utente_chat.id != undefined) {
          fb.db
            .collection("utenti")
            .doc(this.$store.state.user.data.id)
            .update({
              chattingWith: this.utente_chat.id,
              ultimoMex: now.getTime().toString(),
            });
        }
      } else {
        fb.db
          .collection("group_messages")
          .doc(this.gruppo_chat.id_gruppo.toString())
          .collection(this.gruppo_chat.id_gruppo.toString())
          .onSnapshot((querySnapshot) => {
            this.chatMessages = querySnapshot.docs.map((doc) => doc.data());
            //get utenti

            console.log(this.chatMessages);
            this.scrollToEnd();
            this.caricamento_messaggi = false;
            //setta come letti
          });
      }
      this.settaStatoMessaggi();
    },
    settaStatoMessaggi() {
      if (this.utente_chat.id != undefined && this.utente_chat.id != null)
        fb.db
          .collection("messages")
          .doc(this.groupChatId)
          .collection(this.groupChatId)
          .where("stato", "==", 1)
          .where("idFrom", "==", this.utente_chat.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(function (doc) {
              doc.ref.update({ stato: 0 });
              // If you need the doc id, use `doc.id`
            });
            fb.db
              .collection("ultimi_messaggi")
              .doc(this.utente_chat.chatId)
              .get()
              .then((querySnapshot2) => {
                var prova = querySnapshot2.data();
                if (prova != undefined) {
                  if (prova.idFrom == this.utente_chat.id) {
                    querySnapshot2.ref.update({ stato: 0 });
                  }
                }
              });
          });
    },
    //FUNZIONI CHAT GRUPPI

    //FUNZIONI GENERICHE
    scrollToEnd() {
      let that = this;
      var checkExist = setInterval(function () {
        if (that.$refs.messagesContainer) {
          clearInterval(checkExist);
          var content = that.$refs.messagesContainer;
          content.scrollTop = content.scrollHeight;
        }
      }, 100);
      this.caricamento_messaggi = false;
    },
    hashCode2(s) {
      for (var i = 0, h = 0; i < s.length; i++)
        h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
      return h;
    },
  },
};
</script>
<style scoped>
  .typer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    bottom: 0;
    height: 3.9rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
  }

  .typer_icon {
    position: absolute;
    right: 2.5rem;
    padding: 1rem;
    width: 80%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.25rem;
  }

  .chat-container {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
  }
</style>
<style lang="scss">
  $green: #86bb71;
  $blue: #94c2ed;
  $orange: #e38968;
  $gray: #92959e;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .chat {
    width: 98%;
    float: left;
    background: #ffffff;

    color: #434651;

    .chat-header {
      padding: 20px;
      border-bottom: 2px solid white;

      img {
        float: left;
      }

      .chat-about {
        float: left;
        padding-left: 10px;
        margin-top: 6px;
      }

      .chat-with {
        font-weight: bold;
        font-size: 16px;
      }

      .chat-num-messages {
        color: $gray;
      }

      .fa-star {
        float: right;
        color: #d8dadf;
        font-size: 20px;
        margin-top: 12px;
      }
    }
    .div_contenitore_anteprima {
      width: 95%;
      height: 10%;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      resize: none;
    }
    .chat-message {
      padding: 10px;

      .fa-file-o,
      .fa-file-image-o {
        font-size: 16px;
        color: gray;
        cursor: pointer;
      }
    }
  }

  .online,
  .offline,
  .me {
    margin-right: 3px;
    font-size: 10px;
  }

  .online {
    color: $green;
  }

  .offline {
    color: $orange;
  }

  .me {
    color: $blue;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
</style>
