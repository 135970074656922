<template>
    <v-card>
        <v-card-text>
            <v-row>

                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="filteredTable"
                        sort-by="ora"
                        class="elevation-1"
                        hide-default-footer
                        :items-per-page="12000"                        
                    >
                        <template v-slot:top>

                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Timbrature</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer/>
                            <v-text-field
                                class="mt-2"
                                label="Totale Accantonamento"
                                readonly
                                outlined
                                dense
                                v-model="bonus_totale"
                            ></v-text-field>
                            <v-spacer/>
                            <!--Dialog Nuova e modifica timbratura-->
                            <v-dialog 
                                v-model="dialog_timbratura"
                                max-width="600px"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                dark
                                outlined
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                >
                                Nuova Timbratura
                                </v-btn>
                            </template>
                            <v-card> 
                                <v-col cols="12" sm="6" md="12" align="right">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn  small v-bind="attrs" v-on="on" @click="close">
                                                <v-icon color="red"
                                                >mdi-close-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Chiudi.</span>
                                    </v-tooltip>
                                </v-col>
                                <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <v-select
                                                    :items="tipo_timbratura"
                                                    item-text='descrizione'
                                                    item-value='id'
                                                    label="Tipo Timbratura"
                                                    dense
                                                    outlined
                                                    v-model="editedItem.tipo"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">                                             
                                                <v-text-field
                                                    label="Ora Timbratura"
                                                    value="12:30:00"
                                                    type="time"
                                                    suffix="ORA"                                                
                                                    v-model="editedItem.ora"
                                                    v-on:keydown.enter.prevent="conferma"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    outlined
                                    @click="conferma"
                                >
                                    Conferma
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogDelete" max-width="800px">
                            <v-card>
                                <v-card-title class="headline">Conferma cancellazione timbratura?</v-card-title>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>


                        </v-toolbar>

                        </template>
                            <template  v-slot:[`item.auth_ingresso`]="{ item }" >
                                <v-checkbox
                                    v-model="item.auth_ingresso"
                                ></v-checkbox>
                            </template> 
                            <template  v-slot:[`item.actions`]="{ item }" >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="editItem(item)">
                                            <v-icon color="green"
                                            >mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Modifica Timbratura</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                            <v-icon color="red"
                                            >mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cancella Timbratura</span>
                                </v-tooltip>
                            </template>
                            <template  v-slot:[`item.piu_meno`]="{ item }" >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="piumeno(item, 'piu')">
                                            <v-icon color="green"
                                            >mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Accantona 15'</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="piumeno(item, 'meno')">
                                            <v-icon color="red"
                                            >mdi-minus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cancella 15' accantonati</span>
                                </v-tooltip>
                            </template>                            
                    </v-data-table>
 
                </v-col>
                <v-col cols="12" sm="6" md="12" align="center">
                        <v-btn
                            color="primary"
                            @click="save_back"
                        >
                            SALVA
                        </v-btn>
                </v-col>
                    
            </v-row>


        </v-card-text>
 

    </v-card>
</template>

<script>
    import moment from "moment/moment";
    export default {
        data() {
            return{
                modalTab: "cod_lotto",
                dialog_timbratura: false,
                dialogDelete: false,
                timbrature_utente:[],

                id_profilo: localStorage.id_profilo,
                bonus_totale:'5',

                editedIndex: -1,
                editedItem:  { id_timbratura: 0, id_timbratura_dispositivo: 0, id_personale: 0 , tipo: 1, des_tipo: '', data : null, ora: null, tot_bonus:0, bonus: null, matricola:null, cod_dispositivo:"", exp: null,  filename: null, stato :0, elaborato:0, bollato:null, validita: null, id_personale_old: null, date_ins: null, date_mod :null, id_utente: null, auth_ingresso:null},
                defaultItem: { id_timbratura: 0, id_timbratura_dispositivo: 0, id_personale: 0 , tipo: 1, des_tipo: '', data : null, ora: null, tot_bonus:0, bonus: null, matricola:null, cod_dispositivo:"", exp: null,  filename: null, stato :0, elaborato:0, bollato:null, validita: null, id_personale_old: null, date_ins: null, date_mod :null, id_utente: null, auth_ingresso:null},
               
                headers: [
                    { text: 'Data', value: 'data', sortable: false, width:'25%'},
                    { text: 'Tipo Timbratura', value: 'des_tipo' , sortable: false,},
                    { text: 'Autorizz. Ingresso', value: 'auth_ingresso' , sortable: false,},
                    { text: 'Orario', value: 'ora', width:'10%', sortable: false, },
                    { text: '', value: 'actions', sortable: false,  width:'20%' },
                    { text: 'Accantonamento', value: 'bonus', width:'10%', sortable: false },
                    { text: '', value: 'piu_meno', sortable: false,  width:'20%' },
                ],

                tipo_timbratura: [  { id: 1, descrizione: "Ingresso", }, 
                                    { id: 2, descrizione: "Uscita", },  
                ],

            }

        
        },
        props: {
            ID_UTENTE: Number,
            DATA: String,
            TRIGGER: Number,
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Nuova Timbratura' : 'Modifica Timbratura'
            },
            // Questa funzione è applicata al v-data-table con :headers="headers" per filtrare gli :items  di "timbrature_utente" con validità diversa 0
           filteredTable() {
                return this.timbrature_utente.filter((i) => {
                    return (i.validita === null) || (i.validita === 1) || (i.validita === "");
                })
            },
        },        
        mounted() {
            this.gettimbrature() 
        },
        watch: {
            DATA: {
            immediate: true,
            handler() {
                    this.gettimbrature() 
                }
            },
            ID_UTENTE: {
            immediate: true,
            handler() {
                    this.gettimbrature() 
                }
            },
            TRIGGER: {
            immediate: true,
            handler() {
                    this.gettimbrature() 
                }
            },
 
        },
        methods: { 
            gettimbrature(){
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "gettimbrature",
                        data: this.DATA,
                        id_personale: this.ID_UTENTE, 
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {           
                    //this.timbrature_utente = res.data;
                    this.timbrature_utente = []
                    this.load_timbrature_utente(res.data)
                })
                .catch(e => {
                    console.log(e);
                });                

            },
            load_timbrature_utente(array_t){
            var lung = array_t.length
            if (lung > 0 ){
                for (var i = 0; i <=lung - 1; i++){
                this.timbrature_utente.push({  
                        id_timbratura: array_t[i].id_timbratura,  //Da Valorizzare = -1 se nuovo
                        id_personale: array_t[i].id_personale,  //Da Valorizzare
                        tipo: array_t[i].tipo,  //Da Valorizzare
                        data: array_t[i].data.substr(0,10),  //Da Valorizzare
                        ora: array_t[i].ora,  //Da Valorizzare
                        stato: array_t[i].stato,  //Da Valorizzare
                        des_tipo: array_t[i].des_tipo,
                        auth_ingresso: array_t[i].auth_ingresso,
                        bonus: array_t[i].bonus,
                        tot_bonus: array_t[i].tot_bonus, // DA MODIFICARE array_t[i].bonus
                        id_timbratura_dispositivo: array_t[i].id_timbratura_dispositivo,  
                        matricola: array_t[i].matricola,  
                        cod_dispositivo: array_t[i].cod_dispositivo,  
                        exp: array_t[i].exp,
                        filename: array_t[i].filename,
                        elaborato: array_t[i].elaborato,
                        bollato: array_t[i].bollato,
                        id_personale_old: array_t[i].id_personale_old,

                        id_utente: array_t[i].id_utente,
                        date_ins: array_t[i].date_ins,
                        date_mod: array_t[i].date_mod,
                        validita: array_t[i].validita,
                     });  
                     this.bonus_totale = array_t[i].tot_bonus
                    }
                }
                console.log(this.timbrature_utente)
            },
            piumeno(item, tipo){
                var orario_con_aggiunta;
                this.editedIndex = this.timbrature_utente.indexOf(item)
                this.editedItem = Object.assign({}, item)
                Object.assign(this.timbrature_utente[this.editedIndex], this.editedItem)

                var orario_indicato = moment(item.ora, 'HH:mm:ss');
                if(item.des_tipo == 'Uscita'){
                    if (tipo == 'piu'){
                        this.editedItem.bonus = item.bonus + 1
                        orario_con_aggiunta = moment(orario_indicato).subtract(15, 'm').toDate();
                        this.bonus_totale = this.bonus_totale + 1
                    }else{
                        this.editedItem.bonus = item.bonus - 1
                        orario_con_aggiunta = moment(orario_indicato).add(15, 'm').toDate();
                        this.bonus_totale = this.bonus_totale - 1
                    }
                }else{//ingresso
                    if (tipo == 'piu'){
                        this.editedItem.bonus = item.bonus + 1
                        orario_con_aggiunta = moment(orario_indicato).add(15, 'm').toDate();
                        this.bonus_totale = this.bonus_totale + 1
                    }else{
                        this.editedItem.bonus = item.bonus - 1
                        orario_con_aggiunta = moment(orario_indicato).subtract(15, 'm').toDate();
                        this.bonus_totale = this.bonus_totale - 1
                    }                    
                }
                var nuovo_orario = moment(orario_con_aggiunta).format('HH:mm:ss');
                this.editedItem.ora = nuovo_orario

                Object.assign(this.timbrature_utente[this.editedIndex], this.editedItem)
            },
            editItem (item) {
                this.editedIndex = this.timbrature_utente.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog_timbratura = true
            },
            deleteItem (item) {
                this.editedIndex = this.timbrature_utente.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },
            deleteItemConfirm () {
                //Se il record non è nuovo (-1) mettendo validità a 0 il record sparisce perchè la tabella timbrature_utente  è filtrata su validita != 0
                if (this.editedItem.id_timbratura != -1){
                        this.timbrature_utente[this.editedIndex].validita = 0
                }else{
                this.timbrature_utente.splice(this.editedIndex, 1)
                }
                this.closeDelete()
            },
            close () {
                this.dialog_timbratura = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },
            conferma () { 
                if(this.editedItem.tipo == 1) this.editedItem.des_tipo = 'Ingresso'; else this.editedItem.des_tipo = 'Uscita'
                if (this.editedIndex > -1) {
                    Object.assign(this.timbrature_utente[this.editedIndex], this.editedItem)
                } else {
                    this.editedItem.data = this.DATA.substr(0,10)
                    this.editedItem.id_personale = this.ID_UTENTE
                    this.editedItem.id_timbratura = -1
                    this.editedItem.validita = 1
                    this.timbrature_utente.push(this.editedItem)

                }
                this.close()
            },
            save_back(){
                debugger
                let request = {
                    controller: "Presenze",
                    method: "PUT",
                    richiesta: {
                        action: "settimbratura",
                        timbratura: this.timbrature_utente,
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {
                            if (res.data == "OK"){
                                this.$emit("timbrature", this.timbrature_utente);  
                            }      

                    });
            },             
        }

    }
</script>
