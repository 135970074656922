<template>
    <div>
        <v-container fluid>
         <v-row>
         <v-col md="12">
         <v-data-table :items="items" :hide-default-footer="true"  :headers="headers">
          <template v-slot:item.seleziona="">
            <v-btn class="mx-2" tile color="primary" @click="onButtonClick(props.item)">
                    <v-icon dark>mdi-check</v-icon>
            </v-btn>    
        </template>
         </v-data-table>
         </v-col>
         </v-row>

         <v-divider>o</v-divider>

         <v-row class="mt-2 ml-n12">
         <v-col md="4">
         <v-btn  tile color="primary"> 
          <v-icon>mdi-chevron-up</v-icon>
         </v-btn>
         <v-btn color="primary" tile class="ml-1">COPIA RIGHE TABELLA IMBALLI</v-btn>
        
         
         </v-col>
         <v-col class="ml-auto mr-n16 text--center" md="4">IMBALLO <b>(Peso Selezionati:0)</b> </v-col>
         <v-col class="" md="3">
         <v-btn tile class="ml-n3" color="yellow">
         STAMPA RIGHE TABELLA IMBALLI <v-icon>mdi-printer</v-icon>
         </v-btn>
         
         </v-col>
         </v-row>
         <v-divider></v-divider>


      <v-row>
      
      <v-col md="12">
      
      <v-data-table :hide-default-footer="true"   
      v-model="selected"
    :single-select="singleSelect"
    item-key="id"
    show-select
    class="elevation-1" :headers="headers1">
      
      
      </v-data-table>
      </v-col>
      </v-row>
        
        </v-container>
    </div>
</template>

<script>
export default {
 data() {
     return {
           singleSelect: false,
    selected:[],
          headers:[
              {
          text: "Cod. Articolo",
          value: "cod_articolo",
          sortable: false
        },
        {
          text: "PMU",
          value: "pmu",
          sortable: false
        },
        {
          text: "Articolo",
          value: "articolo",
          sortable: false
        },
        {
          text: "Q.ta da Preparare",
          value: "qty_preparare",
          sortable: false
        },
        {
          text: "Q.ta Magazzino",
          value: "qty_magazzino",
          sortable: false
        },
        {
          text: "Cod. Cliente",
          value: "cod_cliente",
          sortable: false
        },
        { 
          text:"Cod. Contenitore",
          value: "cod_contenitore",
          sortable:false
        },
        { 
          text:"Q.ta x Contenitore",
          value: "qty_x_contenitore",
          sortable:false
        },
            { 
          text:"Num. Contenitori",
          value: "num_contenitori",
          sortable:false
        }, 
            { 
          text:"Seleziona",
          value: "seleziona",
          sortable:false
        }, 
         ],
           headers1:[
              {
          text: "Articolo",
          value: "articolo",
          sortable: false
        },
        {
          text: "Des. Articolo",
          value: "des_articolo",
          sortable: false
        },
        {
          text: "Cod. Lotto",
          value: "cod_lotto",
          sortable: false
        },
        {
          text: "Quantitá",
          value: "Quantita",
          sortable: false
        },
        {
          text: "Confezioni",
          value: "confezioni",
          sortable: false
        },
        {
          text: "Peso x Contenitore",
          value: "peso_contenitore",
          sortable: false
        },
        { 
          text:"Riferimento",
          value: "riferimento",
          sortable:false
        },
        { 
          text:"Etichetta",
          value: "etichetta",
          sortable:false
        },
            { 
          text:"Elimina",
          value: "elimina",
          sortable:false
        }, 
         ],
         items:[
             {
                 cod_articolo:'ZM089',
                 pmu:"UI<JYGBGYHJIJ((",
                 articolo:"Engine",
                 qty_preparare:"12",
                 qty_magazzino:"100",
                 cod_cliente:"10PKHMB089",
                 cod_contenitore:"KMLO080MUK",
                 qty_x_contenitore:"50",
                 num_contenitori:"XMYOI089"

             },
              {
                 cod_articolo:'ZM089',
                 pmu:"UI<JYGBGYHJIJ((",
                 articolo:"Engine",
                 qty_preparare:"12",
                 qty_magazzino:"100",
                 cod_cliente:"10PKHMB089",
                 cod_contenitore:"KMLO080MUK",
                 qty_x_contenitore:"50",
                 num_contenitori:"XMYOI089"

             },
              {
                 cod_articolo:'ZM089',
                 pmu:"UI<JYGBGYHJIJ((",
                 articolo:"Engine",
                 qty_preparare:"12",
                 qty_magazzino:"100",
                 cod_cliente:"10PKHMB089",
                 cod_contenitore:"KMLO080MUK",
                 qty_x_contenitore:"50",
                 num_contenitori:"XMYOI089"

             },
              {
                 cod_articolo:'ZM089',
                 pmu:"UI<JYGBGYHJIJ((",
                 articolo:"Engine",
                 qty_preparare:"12",
                 qty_magazzino:"100",
                 cod_cliente:"10PKHMB089",
                 cod_contenitore:"KMLO080MUK",
                 qty_x_contenitore:"50",
                 num_contenitori:"XMYOI089"

             },
              {
                 cod_articolo:'ZM089',
                 pmu:"UI<JYGBGYHJIJ((",
                 articolo:"Engine",
                 qty_preparare:"12",
                 qty_magazzino:"100",
                 cod_cliente:"10PKHMB089",
                 cod_contenitore:"KMLO080MUK",
                 qty_x_contenitore:"50",
                 num_contenitori:"XMYOI089"

             },
              {
                 cod_articolo:'ZM089',
                 pmu:"UI<JYGBGYHJIJ((",
                 articolo:"Engine",
                 qty_preparare:"12",
                 qty_magazzino:"100",
                 cod_cliente:"10PKHMB089",
                 cod_contenitore:"KMLO080MUK",
                 qty_x_contenitore:"50",
                 num_contenitori:"XMYOI089"

             }
         ]
     }
 },



}
</script>


<style css scoped>

</style>