<template>
    <div class="card" >
      
        <div class="card-text">

          <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
          <br>

          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="modalTab"
                grow
                style="color: black"
                slider-size="5"
                slider-color="primary accent-3 "
                color="black"
              > 
                <v-tab :href="`#accettazione_materiale`" style="max-width: 350px"
                  >ACCETTAZIONE MATERIALE</v-tab
                >
                <v-tab :href="`#uscita_materiale`" style="max-width: 350px"
                  >USCITA MATERIALE</v-tab
                >

              </v-tabs>
              <v-tabs-items v-model="modalTab" class="no-transition" touchless>
                <v-tab-item
                  :value="`accettazione_materiale`"
                  transition="false"
                  class="no-transition"
                >
                  <div class="production_magazzino">
                    <v-card>
                      <AccettazioneMateriale
                        v-if="sezione_attiva == 1"
                        @goToDettaglioEmpty="sezione_attiva = 2"
                        @goToDettaglio="getDettaglio"
                        :lista_bolle="lista_bolle"
                        @data_cambiata="newDate"
                        :data_inizio="ricerca.data_inizio"
                        :data_fine="ricerca.data_fine"
                        :fornitore="ricerca.fornitore"
                        :id_anagrafica="ricerca.id_anagrafica"
                        @resetFiltro="resetFiltro"
                        @checkbox="admin_power = !admin_power"
                        :admin_power="admin_power"
                        @get_documento="getDocumento"
                      />
                      <DettaglioBolla
                        v-if="sezione_attiva == 2"
                        @goBack="goBack"
                        :bolla_dettaglio="bolla_dettaglio"
                        v-model="lista_bolle"
                        :admin_power="admin_power"
                        @getDocumento="getDocumento"
                      />
                    
                    </v-card>
                  </div>
                </v-tab-item>

                <v-tab-item
                  :value="`uscita_materiale`"
                  transition="false"
                  class="no-transition"
                >
                  <div class="production_magazzino">
                    <v-card>
                      <header>
                        <v-toolbar dense color="indigo" dark>
                          <v-toolbar-title>Uscita Materiale</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                light
                                v-on="on"
                                v-bind="attrs"
                                @click="$router.push('/home/production')"
                                ><v-icon color="error">mdi-keyboard-backspace</v-icon>
                              </v-btn>
                            </template>
                            <span>Indietro</span>
                          </v-tooltip>
                        </v-toolbar>
                      </header>     

                        <v-row >
                          <v-col cols="4">
                            <v-row align="center">
                              <v-col>
                                <v-text-field
                                  class="ml-2"
                                  label="Fornitore"
                                  prepend-icon="mdi-truck"
                                  v-model="ricerca_uscita.fornitore"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small v-on="on" v-bind="attrs" @click="getAnagraficaUscita"
                                    ><v-icon color="primary">mdi-magnify</v-icon>
                                  </v-btn>

                                    <!-- DIALOG RICHERCA FORNITORE -->
                                    <v-dialog v-model="dialogSearchAnagraficaUscita" width="700" >
                                      <v-card>
                                        <v-toolbar color="indigo" dense dark>
                                          <v-toolbar-title>Seleziona Fornitore</v-toolbar-title>
                                          <v-spacer></v-spacer>
                                          <v-btn @click="chiudiDialogRicercaFornitore" light small>
                                            <v-icon color="error">mdi-keyboard-backspace</v-icon>
                                          </v-btn>
                                        </v-toolbar>
                                        <v-container>
                                          <v-row align="center">
                                            <v-col cols="1"></v-col>
                                            <v-col cols="8">
                                              <v-text-field
                                                v-model="ricerca_anagrafica"
                                                label="Cerca Fornitore"
                                              ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" align="right">
                                              <v-btn @click="getAnagraficaUscita" small>
                                                <v-icon color="primary">mdi-magnify</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col cols="12">
                                              <v-data-table
                                                :headers="ragioneSociale"
                                                :items="ragioneSocialeLista"
                                              >
                                                <template v-slot:item="{ item }">
                                                  <tr
                                                    class="record_tabella"
                                                    :data-deleted="item.validita == 0 ? true : false"
                                                  >
                                                    <td>
                                                      <v-btn small @click="selezionaAnagrafica(item)"
                                                        ><v-icon color="primary">mdi-check</v-icon></v-btn
                                                      >
                                                    </td>
                                                    <td
                                                      v-for="(colonna, i) in ragioneSociale"
                                                      :key="i"
                                                      v-show="colonna.value != null"
                                                    >
                                                      {{ item[colonna.value] }}
                                                    </td>
                                                  </tr>
                                                </template>
                                              </v-data-table>
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card>
                                    </v-dialog>   
                                                                  
                                </template>
                                <span>Cerca </span>
                              </v-tooltip>

                            </v-row>
                          </v-col>

                                   
                          <v-col cols="3" class= "pl-5 pt-6">
                            <v-text-field
                              outlined
                              dense
                              v-model="cod_lotto_selected"
                              label="Codice Lotto"
                            ></v-text-field>
                            </v-col>
                            <!--
                            <v-col cols="1" class= "pt-8">
                              <v-tooltip bottom >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small v-on="on" v-bind="attrs" @click="getArticoloLotto"
                                    ><v-icon color="primary">mdi-check-box-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Controlla Lotto </span>
                              </v-tooltip>
                          </v-col>                             
                          -->
                          <v-col cols="4" class= "pt-6">
                            <v-text-field
                              type="number"
                              outlined
                              dense
                              v-model="quantita_pezzi_selected"
                              min="0"
                              label="Numero Pezzi"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="7"  class= "pl-13">
                            <v-autocomplete
                              outlined
                              v-model="id_contenitore_selected"
                              :items="contenitori"
                              dense
                                  item-text="des_imballo"
                                  item-value="id_articolo"
                              label="Contenitori"
                              @change="cambiaSelezioneContenitore"
                            ></v-autocomplete>
                          </v-col>  
                          <v-col cols="3">
                            <v-text-field
                              type="number"
                              outlined
                              dense
                              v-model="quantita_colli_selected"
                              min="0"
                              label="Numero Colii"
                            ></v-text-field>
                          </v-col>      
                          <v-col cols= "1" class= "pt-4">
                              <v-tooltip bottom >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small v-on="on" v-bind="attrs" @click="AddArticoloBolla"
                                    ><v-icon color="primary">mdi-plus</v-icon> Aggiungi
                                  </v-btn>
                                </template>
                                <span>Aggiungi Articolo a Bolla </span>
                              </v-tooltip> 
                          </v-col>                                             

                        </v-row>
                        <v-row>
                          <v-col cols="12" >
                            <v-toolbar
                              dark
                              color="#3F51B5"
                              style="border-radius: 5px 5px 0px 0px"
                              class="elevation-0"
                              ><v-toolbar-title>Bolla in preparazione  </v-toolbar-title>
                              <v-spacer></v-spacer> 
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn light v-bind="attrs" v-on="on" class="" @click="salva_bolla" >
                                    <v-icon color="blue" >mdi-content-save-edit-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Salva Bolla</span>
                              </v-tooltip>

                            </v-toolbar>
                            <v-data-table
                              :headers="headers_bolla"
                              :items="bolla"
                              :items-per-page="10"
                              class="elevation-3"
                            >
                            <template v-slot:item.scarto="{ item }">
                              <v-checkbox
                                v-model="item.scarto"
                                readonly
                              ></v-checkbox>
                            </template> 

                              <template v-slot:item.seleziona="{ item }">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn outlined small v-bind="attrs" v-on="on" @click="modifica_bolla(item)">
                                          <v-icon color="success"
                                          >mdi-check-outline
                                          </v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Modifica Bolla</span>
                              </v-tooltip>            
                              <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItemBolla(item)">
                                          <v-icon color="red"
                                          >mdi-delete
                                          </v-icon>
                                      </v-btn>
                                  </template>
                                  <span>Cancella articolo da bolla</span>
                              </v-tooltip>
                              </template>
                            </v-data-table>
                          </v-col>      
                        </v-row>                  
                        
                    </v-card>

                   </div>



                </v-tab-item>
               


              </v-tabs-items>

            </v-col>
          </v-row>
        </div> 
    </div>
</template>

<script>
import AccettazioneMateriale from "@/components/PRODUCTION/Magazzino/AccettazioneMateriale.vue";
import DettaglioBolla from "@/components/PRODUCTION/Magazzino/DettaglioBolla.vue";
export default {
  name: "Magazzino",
  components: {
    AccettazioneMateriale,
    DettaglioBolla,
  },
  data() {
    return {
      sezione_attiva: 1,
      modalTab: "accettazione-materiale",
      bolla_dettaglio: null,
      lista_bolle: [],
      admin_power: false,
      ricerca: {
        fornitore: null,
        id_anagrafica: null,
        data_inizio: null,
        data_fine: null,
      },






      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
    

      contenitori:[],

      cod_lotto_selected: '',
      quantita_pezzi_selected: 0,
      quantita_colli_selected: 0,
      id_contenitore_selected: null,
      des_contenitore_selected: '',
      articoloLotto: [],

      ricerca_uscita: {
        fornitore: null,
        id_anagrafica: null,
        data_inizio: null,
        data_fine: null,
      },
      ragioneSociale: [
        { text: null, value: null, align: "start" },
        { text: "Ragione Sociale", value: "ragione_sociale" },
        { text: "Indrizzo", value: "indirizzo" },
      ],      
      
      ricerca_anagrafica: null,
      ragioneSocialeLista: [],
      dialogSearchAnagraficaUscita: false,

      headers_bolla: [
        { text: "Cod articolo", value: "cod_articolo" },
        { text: "Lotto", value: "cod_lotto" },
        { text: "Fornitore", value: "fornitore" },
        { text: "Quantità", value: "num_pezzi", width: "5%" },
        { text: "N.colli", value: "qta_colli" , width: "5%"},
        { text: "Contenitore", value: "contenitore" },
        { text: "Azione", value: "seleziona",width: "10%" },
      ],
      bolla:[],
      record_bolla: {cod_articolo: '', cod_lotto: '', id_articolo: null, id_lotto: null, id_fornitore: null, fornitore: '', num_pezzi: 0, qta_colli: 0, id_contenitore: null, contenitore: '' },

     // cod_articolo(this.articoloLotto.cod_articolo) cod_lotto  id_fornitore (this.ricerca_uscita.id_anagrafica), fornitore (this.ricerca_uscita.fornitore ), num_pezzi (quantita_pezzi_selected) qta_colli(quantita_colli_selected),  id_contenitore (id_contenitore_selected)  contenitore (des_contenitore_selected)


      
    };
  },
  mounted() {
      this.getContenitori()
  },   
  methods: {
    getDettaglio(data) {
      this.bolla_dettaglio = data;
      this.sezione_attiva = 2;
    },
    goBack() {
      this.bolla_dettaglio = null;
      this.sezione_attiva = 1;
      setTimeout(() => {
        this.getDocumento();
      }, 500);
    },
    newDate(data_inizio, data_fine, fornitore, id_anagrafica) {
      this.ricerca.data_inizio = data_inizio;
      this.ricerca.data_fine = data_fine;
      this.ricerca.fornitore = fornitore;
      this.ricerca.id_anagrafica = id_anagrafica;
    },
    resetFiltro() {
      this.ricerca.data_inizio = null;
      this.ricerca.data_fine = null;
      this.ricerca.fornitore = null;
      this.ricerca.id_anagrafica = null;

      // Remove my-component from the DOM
      this.sezione_attiva = null;

      this.$nextTick(() => {
        // Add the component back in
        this.sezione_attiva = 1;
      });
    },
    getDocumento() {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getdocumento",
          token: localStorage.user_token,
          cod_documento: "DDTR",
          id_anagrafica: this.ricerca.id_anagrafica,
          data_da: this.ricerca.data_inizio,
          data_a: this.ricerca.data_fine,
        },
      };
      this.$store.dispatch("api", richiesta).then((risposta) => {
        if (!this.admin_power) {
          let res = risposta.data.documento;
          const arr = res.filter((elem) => elem.validita);

          this.lista_bolle = arr;
        } else {
          this.lista_bolle = risposta.data.documento;
        }
      });
    },

    // USCITA Materiale
    getAnagraficaUscita() {
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          token: localStorage.getItem("user_token"),
          cod_anagrafica: this.cod_anagrafica,
          des_anagrafica: this.ricerca_anagrafica,
          tipo_anagrafica: "F",
        },
      };
      this.$store.dispatch("api", searchReq)
      .then((res) => {
        this.overlay = false;
        this.ragioneSocialeLista = res.data;
        this.dialogSearchAnagraficaUscita = true;
      });
    },
    selezionaAnagrafica(fornitore) {
      this.ricerca_uscita.fornitore = fornitore.ragione_sociale;
      this.ricerca_uscita.id_anagrafica = fornitore.id_anagrafica;
      this.dialogSearchAnagraficaUscita = false;
    },    
    chiudiDialogRicercaFornitore() {
      debugger
      this.dialogSearchAnagraficaUscita = false;
      this.ricerca_anagrafica = null;
    },    
    getContenitori(){
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcontenitori",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          
          this.contenitori = [];
          res.data.forEach((e) => {
            this.contenitori.push(e);
          });
        
        }
      });      
    },
    cambiaSelezioneContenitore(){
      let Cont_selezionato = this.alasql(
          "SELECT * FROM ? WHERE id_articolo = " + this.id_contenitore_selected ,
          [this.contenitori]
      );
      this.des_contenitore_selected = Cont_selezionato[0].des_articolo
    },    
    getArticoloLotto(){
      this.articoloLotto = [];
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticololotto",
          token: localStorage.getItem("user_token"),
          cod_lotto: this.cod_lotto_selected,
        },
      };
      this.$store.dispatch("api", request).then((res) => {

        if (res.status == 200){
          res.data.forEach((e) => {
            this.articoloLotto.push(e);
          });

        
        }
      });      
    },    

    controllaArticoloLotto() {
        return new Promise((resolve, reject) => {
            this.articoloLotto = [];
            let request = {
              controller: "Magazzino",
              method: "POST",
              richiesta: {
                action: "getarticololotto",
                token: localStorage.getItem("user_token"),
                cod_lotto: this.cod_lotto_selected,
              },
            };
            this.$store.dispatch("api", request)
              .then((res) => {
                if (res.status == 200){
                  res.data.forEach((e) => {
                    this.articoloLotto.push(e);
                
                  })
                }else this.articoloLotto = []
                resolve(res);
                return;    

              })
            .catch((e) => {
                reject(e);
                console.log(e);
            })
        })
      },      


    async AddArticoloBolla(){
      //console.log("articoloLotto", this.articoloLotto )
      await this.controllaArticoloLotto();
      if (this.articoloLotto.length == 0 ){
          this.snackbar_color =  "error"
          this.snackbar_text =  "Attenzione, codice lotto inserito non valido: non è stato trovato un articolo associato.";
          this.snackbar = true   
          return     
      }
      if (this.quantita_pezzi_selected == 0 || this.quantita_colli_selected == 0   ){
          this.snackbar_color =  "error"
          this.snackbar_text =  "Attenzione, il numero di colli ed il numnro di pezzi deve essere maggiore di zero.";
          this.snackbar = true   
          return     
      }
      if (this.id_contenitore_selected  == '' || this.id_contenitore_selected  == null   ){
          this.snackbar_color =  "error"
          this.snackbar_text =  "Attenzione, è necessario selezionare un contenitore.";
          this.snackbar = true   
          return     
      } 
      if (this.ricerca_uscita.id_anagrafica == '' || this.ricerca_uscita.id_anagrafica == null ){
          this.snackbar_color =  "error"
          this.snackbar_text =  "Attenzione, è necessario selezionare un fornitore. " ;
          this.snackbar = true   
          return     
      } 

      if (this.quantita_pezzi_selected  > this.articoloLotto[0].giacenza  ){
          this.snackbar_color =  "error"
          this.snackbar_text =  "Attenzione, il numero dei pezzi deve essere minore della giacenza  di " + this.articoloLotto[0].giacenza + " pezzi. " ;
          this.snackbar = true   
          return     
      }    

      var Lotto_selezionato = [] 
      Lotto_selezionato = this.alasql(
          "SELECT * FROM ? WHERE cod_lotto = '" + this.cod_lotto_selected + "' and  id_fornitore = " +  this.ricerca_uscita.id_anagrafica ,
          [this.bolla]
      );
      if (Lotto_selezionato["length"] > 0) {
          this.snackbar_color =  "error"
          this.snackbar_text =  "Attenzione, Codice Lotto " + this.cod_lotto_selected + " per fornitore " + this.ricerca_uscita.fornitore +  " già inserito nella bolla in  preparazione. " ;
          this.snackbar = true    
          return          
      }
      this.ricerca_uscita.fornitore
       
        this.record_bolla.cod_articolo = this.articoloLotto[0].cod_articolo
        this.record_bolla.cod_lotto = this.cod_lotto_selected
        this.record_bolla.id_articolo = this.articoloLotto[0].id_articolo
        this.record_bolla.id_lotto = this.articoloLotto[0].id_lotto
        this.record_bolla.id_fornitore = this.ricerca_uscita.id_anagrafica
        this.record_bolla.fornitore = this.ricerca_uscita.fornitore
        this.record_bolla.num_pezzi  = this.quantita_pezzi_selected
        this.record_bolla.qta_colli = this.quantita_colli_selected  
        this.record_bolla.id_contenitore = this.id_contenitore_selected  
        this.record_bolla.contenitore = this.des_contenitore_selected

        this.bolla.push(this.record_bolla)

        

    },
    deleteItemBolla(item){
        let editedIndex = this.bolla.indexOf(item)
        //editedItem = Object.assign({}, item)
        this.bolla.splice(editedIndex, 1)
    },
    salva_bolla(){
        
       // let selected = this.alasql(
       //   "SELECT * FROM tbl_bolle Where id_anagrafica = " + this.id_anagrafica_selected
       // );
       // console.log("selezionati X bolla: ", selected)
        
            let request = {
              controller: "Magazzino",
              method: "PUT",
              richiesta: {
                action: "setddtclI",
                ddt: this.bolla,
                token: localStorage.getItem("user_token"),
                tipo: "U"
              },
            };

            this.$store.dispatch("api", request).then((res) => {
              console.log(res)
            })
            .catch(e => {
                console.log(e);
            });
         
    },

  },
};
</script>

<style scoped>
.production_magazzino {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 20px;
  user-select: none;
}

.record_tabella {
  text-align: left;
}
.record_tabella > td {
  text-align: left;
}
.record_tabella[data-deleted] {
  background-color: #ffcccc !important;
}
.record_tabella[data-deleted]:hover {
  background-color: #ffb3b3 !important;
}

.button_progress {
  position: relative;
}
.button_progress figure {
  position: absolute;
  top: 0;
  right: -10px;
}
</style>