<template>
  <div style="width: 100%; height: 900px">
    <v-app-bar dark clipped-left color="primary" app dense rounded>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <h3>NOME AZIENDA</h3>
      <v-spacer></v-spacer>
      <v-icon @click="logout()">mdi-exit-to-app</v-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list rounded dense>
        <v-subheader>Menu</v-subheader>
        <v-list-item-group v-model="menu_sel" color="#3F51B5">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
            <v-list-item-icon>
              <v-icon v-text="item.icon[0]"></v-icon>
              <v-icon small v-text="item.icon[1]"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view />
    <v-footer>
      <v-footer app dark class="font-weight-medium mt-10">
        <v-col class="text-center" cols="3">
          {{ new Date().getFullYear() }} — <strong>[OPERATORE LOGGATO]</strong>
        </v-col>
        <v-col cols="6">
          <v-spacer></v-spacer>
          <div>[LOGO]</div>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="3">
          <div>
            [CONTATTI]
          </div>
        </v-col>
      </v-footer>
    </v-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modalTab: "packaging",
      drawer: false,
      group: null,
      menu_sel: null,
      items: [
        {
          id: 1,
          text: "Ciclo AutoControllo",
          icon: ["mdi-package-variant"],
          path: "/home/tools/modulo_nuovo/ciclo_autocontrollo",
        },
        {
          id: 2,
          text: "Benestare",
          icon: ["mdi-dolly", "mdi-arrow-right"],
          path: "/home/tools/modulo_nuovo/benestare",
        },
        {
          id: 3,
          text: "Piano controllo",
          icon: ["mdi-dolly", "mdi-arrow-right"],
          path: "/home/tools/modulo_nuovo/piano_controllo",
        },
      ],
    };
  },
  components: {
    // Packaging,
    // IngressoMateriale,
    // UscitaMateriale,
    // ContoLavoroIngresso,
    // ContoLavoroUscita,
  },
};
</script>
