var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{attrs:{"dense":""}},[_vm._v("Gestione Lavorazione")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.clickChiudi}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Cod. Articolo: "),_c('b',[_vm._v(_vm._s(_vm.fase_sel_tmp.cod_articolo))])]),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Work Order: "),_c('b',[_vm._v(_vm._s(_vm.fase_sel_tmp.cod_fase))])])],1)],1),_c('v-divider'),_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"pa-1 mr-5",staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.macchine,"item-text":"cod_macchina","item-value":"cod_macchina","label":"Cod. Macchina","outlined":""},model:{value:(_vm.cod_macchina_sel),callback:function ($$v) {_vm.cod_macchina_sel=$$v},expression:"cod_macchina_sel"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.fase_sel_tmp.des_macchina))]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{style:({
              color:
                _vm.fase_sel_tmp._start == null ||
                (_vm.fase_sel_tmp._start != null && _vm.fase_sel_tmp._stop != null)
                  ? 'red'
                  : 'green',
            })},[_vm._v("mdi-circle")])],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"placeholder":"Note Tempi","label":"Note Tempi","outlined":"","rows":4},model:{value:(_vm.fase_sel.note_tempi),callback:function ($$v) {_vm.$set(_vm.fase_sel, "note_tempi", $$v)},expression:"fase_sel.note_tempi"}})],1),_c('v-col',[_c('v-textarea',{attrs:{"placeholder":"Note Tempi","label":"Note Work Order","outlined":"","rows":4,"readonly":""},model:{value:(_vm.fase_sel.note_workorder),callback:function ($$v) {_vm.$set(_vm.fase_sel, "note_workorder", $$v)},expression:"fase_sel.note_workorder"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"my-1",attrs:{"background-color":"#ffffff","dense":"","hide-details":"auto","item-text":"des","item-value":"id_tabella","items":_vm.causali,"label":"Attività di Produzione","outlined":""},model:{value:(_vm.causale_sel),callback:function ($$v) {_vm.causale_sel=$$v},expression:"causale_sel"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticStyle:{"width":"200px","height":"60px"},attrs:{"color":_vm.fase_sel_tmp._start == null ||
              (_vm.fase_sel_tmp._start != null && _vm.fase_sel_tmp._stop != null)
                ? 'success'
                : 'error',"dark":""},on:{"click":function($event){return _vm.clickStartStop(_vm.fase_sel_tmp)}}},[_c('h1',{staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.fase_sel_tmp._start == null || (_vm.fase_sel_tmp._start != null && _vm.fase_sel_tmp._stop != null) ? "START" : "STOP")+" ")])])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }