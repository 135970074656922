class CHART_COMMON{
    format_serie = []

    // Separatori delle migliaia
    dotNumber(num,limit=false,amount=0){
        if(isNaN(num)){return '';}
        let result;
        if(limit){
            amount = Number(amount);
            let str = num.toFixed(amount);
            result = Number(str).toLocaleString('de-DE');
            let split = result.split(',');
            if(amount > 0){
                if(split[1]==undefined){split[1]='';}
                let spl_len = split[1].length;
                if(spl_len<amount){
                    for(let i=spl_len; i < amount; i++){
                        split[1] += '0';
                    }
                }
                result = split[0] + ',' + split[1];
            } 
        }else{
            result = num.toLocaleString('de-DE');
        }
        return result;
    }
    
    // Divisione tra serie dati e serie di confronto per formattazione
    filterSerie(serie){
        let result = [];
        for(let i=0;i<serie.length;i++){
            if(serie[i].tipo_grafico == -1){
                this.format_serie[serie[i].cod] = serie[i].serie;
            }else{
                result.push(serie[i]);
            }
        }
        return result;
    }

    // Decodifica della stringa parametri extra
    getExtra(serie){
        // Se la decodifica è già avvenuta in un'altra iterazione dei dati, non è necessaria nessuna azione        
        if(serie.extra!=undefined && serie.extra!= null && typeof serie.extra ==='object'){
            return serie.extra
        }
        let extra;
        try{
            extra = JSON.parse(decodeURIComponent(escape(atob(serie.extra))));
        }catch(error){
            extra = {};            
        }
        return extra;
    }

    // Calcolo distanza etichette da asse
    getAxisNameGap(serie,rif = {max:0}){
        let max = rif.max;
        for(let i=0; i < serie.length;i++){
            try{
                let value = serie[i].toString();
                if(value.length>max){
                    max = Math.min(value.length,15);
                }
            }catch(error){
                continue;
            }            
        }
        rif.max = max;
        return max*6.3 + 17;
    }

    // Normalizzazione e cast dei dati
    normalizeData(serie,type){
        switch(type){
            case 1:
                for(let i=0;i<serie.length;i++){
                    serie[i] = Number(serie[i].toString().replaceAll(',','.')); 
                }                                             
            break;
            case 2:
                for(let i=0;i<serie.length;i++){
                    serie[i] = serie[i].toString().split(' ')[0];
                } 
                break;
            case 0:
            default:
                for(let i=0;i<serie.length;i++){
                    serie[i] = serie[i].toString(); 
                } 
                break;
        }
    }

    // Elaborazione dati di una signola serie
    processSerie(serie){
        let result = [];

        let style = []; 
        try{
            style = serie.extra.formato;
        }catch(error){
            style= [];
        }
        if(style == undefined){style = [];}

        let macro = [];
        try{
            macro = serie.extra.macro;
        }catch(error){
            macro= [];
        }
        if(macro == undefined){macro = [];}
        for(let i=0;i<serie.serie.length;i++){
            let datum = {
                name : serie.nome,
                coords : {cod: serie.cod, index: i,value : serie.serie[i]},
                value : serie.serie[i],
                formatted : null,
                macro : macro
            }            
    
            try{
                switch(serie.tipo_dati){
                    case 1:{
                        datum.value = Number(datum.value.toString().replaceAll(',','.'));
                        try{
                            let number = serie.extra.number;
                            if(serie.extra.number.divider==true){
                                datum.formatted = this.dotNumber(datum.value,number.limit,number.amount);
                            }else if(number.limit == true){
                                datum.formatted = datum.value.toFixed(number.amount).replaceAll('.',',');
                            }else{
                                datum.formatted = datum.value.toString().replaceAll('.',',');
                            }
                        }catch(error){
                            datum.formatted = datum.value.toString().replaceAll('.',',');
                        }                        
                    }                                                                     
                    break;
                    case 2:
                        datum.value = datum.value.toString().split(' ')[0];
                        datum.formatted = datum.value;
                        break;
                    case 0:
                    default:
                        datum.value = datum.value.toString();
                        datum.formatted = datum.value;
                        break;
                }
                let apply_style = this.getStyle(datum,style,serie.tipo_dati,i);
                let formatter = '{valore}';
                if(apply_style!=undefined){
                    if(apply_style.static==true){
                        serie.is_static = true;
                        serie.linecolor = apply_style.color;
                    }
                    datum.itemStyle = {
                        color : apply_style.color,
                        table : apply_style.rich
                    }
                    formatter = apply_style.formatter;                   
                }else{
                    datum.itemStyle = {
                        color : null,
                        table : ''
                    }
                }
                datum.formatted = formatter.replaceAll('{valore}',datum.formatted); 
                datum.coords.value = datum.value;               
            }finally{
                result.push(datum);
            }            
        }
        return result;
    }

    getStyle(datum,styles,type,datum_index){
        // Funzioni di comparazione
        let class_ref = this;
        let style;
        let ops = {
            number : [
                function(item,test){return (item < test);},
                function(item,test){return (item <= test);},
                function(item,test){return (item == test);},
                function(item,test){return (item >= test);},
                function(item,test){return (item > test);},
                function(item,test){return (item != test);}
            ],
            string : [
                function(item,test){
                if(style.string.case_sensitive==true){
                    return (item == test);
                }else{                    
                    return (item.toLowerCase() == test.toLowerCase() );
                }},
                function(item,test){
                    if(style.string.case_sensitive==true){
                        return (item != test);
                    }else{
                        return (item.toLowerCase() != test.toLowerCase() );
                    }                    
                }
            ]
        }

        // Casting dei dati
        function castValue(value){
            if(value == null || value == undefined){value = '';}
            switch(type){
                case 1:
                    return Number(value.toString().replaceAll(',','.'));
                case 2:{
                    let datestr = value.toString().split(' ')[0].split('/');
                    return new Date(datestr[2] + '-' + datestr[1] + '-' + datestr[0]);
                }                    
                case 0:
                default:
                    return value.toString();
            }
        }
        
        
        // Recupero valore in serie da query
        function getQueryValue(query_ref){
            try{
                return castValue(class_ref.format_serie[query_ref][datum_index]);
            }catch(error){
                return undefined;
            }
        }

        let apply_rule = false;
        let apply_style = {};
       
        for(let i=0; i<styles.length;i++){
            style = styles[i];
            // Regola statica, non è necessario eseguire ulteriori confronti
            if(style.static==true){
                apply_style = {
                    color : style.color,
                    rich : style.compiled_style,
                    number : style.number,
                    static : true,
                    formatter : (style.formatter==undefined)?'{valore}':style.formatter
                }
                apply_rule = true;
                break;
            }
            
            // Confronto valori            
            let value = castValue(datum.value);
            
            if(style.range==true&&type!=0){               
                if(style.query==true){
                    apply_rule = (value >= getQueryValue(style.start_ref) && value <= getQueryValue(style.end_ref));
                }else{ 
                    let test_1 = castValue(style.start_ref);
                    let test_2 = castValue(style.end_ref);                   
                    apply_rule = (value >= test_1 && value <= test_2);
                }                
            }else{
                if(style.query==true){
                    if(type==0){                        
                        apply_rule = ops.string[style.operation](value,getQueryValue(style.start_ref));
                    }else{
                        apply_rule = ops.number[style.operation](value,getQueryValue(style.start_ref));
                    }                    
                }else{
                    let test = castValue(style.start_ref);
                    if(type==0){
                        apply_rule = ops.string[style.operation](value,test);
                    }else{
                        apply_rule = ops.number[style.operation](value,test);
                    }
                }                
            }
            
            if(apply_rule==true){
                apply_style = {
                    color : style.color,
                    rich : style.compiled_style,
                    number : style.number,
                    formatter : (style.formatter==undefined)?'{valore}':style.formatter
                }
                break;
            }
        }
        return (apply_rule == true)?apply_style:undefined; 
               
    }

    getVisualMap(styles){
        if(styles == undefined || styles == null){return undefined;}

        let visualMap = {
            pieces:[],
            left : '-100%',
            seriesIndex : [],
        };

        let less_index = -1;
        let more_index = -1;

        for(let i=0; i <styles.length; i++){
            let style = styles[i];
            if(style.range==true){
                visualMap.pieces.push({
                    gte : Number(style.start_ref),
                    lte : Number(style.end_ref),
                    color : style.color
                });
            }else{
                switch(style.operation){
                    case 0:
                    case 1:
                        less_index = i;
                        break;
                    case 3:
                    case 4:
                        more_index = i;
                        break;
                }
            }
        }
        if(less_index >=0){
            visualMap.pieces.push({
                lt : Number(styles[less_index].start_ref),
                color : styles[less_index].color
            });
        }
        if(more_index >=0){
            visualMap.pieces.push({
                gt : Number(styles[more_index].start_ref),
                color : styles[more_index].color
            });
        }
        return visualMap;
    }
}
export default CHART_COMMON;