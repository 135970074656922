<template>
    <main class="graph_home">
        <datalist>
            <option v-if="isAdmin()" data-name="Nuova Dashboard" data-icon="plus" @contextmenu="$refs.dashboard_editor.open(null)" />
            <option data-name="Nuova Cartella" data-icon="folder" @contextmenu="$refs.folder_editor.open(null)" />
        </datalist>
        
        <section class="graph_home_loader" v-if="loading==true">
            <span />
            <GraphIcon icon="apps" />
            <h4>Sto caricando le dashboard</h4>
        </section>

        <GraphAppItem v-for="(item,i) in items.parsed" :key="i" :data="item" :images="items.images"
            @edit_ds="$refs.dashboard_editor.open($event)"
            @edit_folder="$refs.folder_editor.open($event)"
            @delete_ds="$refs.dashboard_editor.remove($event)" 
            @delete_folder="$refs.folder_editor.remove($event)"
            @click_ds="$emit('gotonext', $event.data.id_ds)"
            @click_folder="$refs.folder_viewer.open($event)"
            @moveitem="$refs.folder_picker.open($event)"
        />
        
        <GraphFolderEditor ref="folder_editor" :items="items.parsed" :images="items.images" />
        <GraphFolderPicker ref="folder_picker" :items="items.parsed" /> 
        <GraphFolderViewer ref="folder_viewer" :items="items.parsed" 
            @edit_ds="$refs.dashboard_editor.open($event)"
            @delete_ds="$refs.dashboard_editor.remove($event)"
            @click_ds="$emit('gotonext', $event.data.id_ds)"
        />

        <GraphHomeSettings ref="home_settings" @image_event="handleImageEvent($event)" />
        <GraphDashboardEditor ref="dashboard_editor" @refresh="refresh"/>         
    </main> 
</template>
<script>
import GraphDashboardEditor from './Components/DashboardEditorDialog/GraphDashboardEditor.vue';
import GraphFolderEditor from './Components/FolderEditorDialog/GraphFolderEditor.vue';
import GraphFolderPicker from './Components/FolderPickerDialog/GraphFolderPicker.vue';
import GraphFolderViewer from './Components/FolderViewerDialog/GraphFolderViewer.vue';
import GraphHomeSettings from './Components/SettingsDialog/GraphHomeSettings.vue';
import GraphAppItem from "./Components/AppItem/GraphAppItem.vue";
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";

export default {
    name : 'GraphMain',
    components : {
        GraphAppItem,
        GraphDashboardEditor,
        GraphFolderEditor,
        GraphFolderPicker,
        GraphFolderViewer,
        GraphHomeSettings,
        GraphIcon
    },
    data(){
        return{
            NETWORK : null,
            loading : false,
            startup : true,
            items : {
                dashboards : null,
                folders : null,
                images : null,
                parsed : null
            },
            user_settings : {},
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.refresh();
        this.setToolbar();
    },
    methods : {
        isAdmin(){return Number(localStorage.id_profilo)==1;},

        buildView(){            
            const parsed = new Array();
            const folders = this.items.folders;
            const dashboards = this.items.dashboards;
            for(let i = 0; i < folders.length; i++){
                const item = { 
                    type : 0, 
                    data : new Array(), 
                    name : folders[i].name, 
                    image: folders[i].image 
                }
                for(let j = 0; j < folders[i].dashboards.length; j++){
                    for(let k = 0; k < dashboards.length; k++){
                        if(folders[i].dashboards[j] == dashboards[k].id_ds){
                            item.data.push({
                                type : 1,
                                data : dashboards[k],
                                name : dashboards[k].nome,
                                image : dashboards[k].thumb
                            });
                            dashboards.splice(k,1);
                            break;
                        }                   
                    }
                }                
                parsed.push(item);
            }
            for(let i = 0; i < dashboards.length; i++){
                parsed.push({
                    type : 1,
                    data : dashboards[i],
                    name : dashboards[i].nome,
                    image : dashboards[i].thumb
                });
            }
            this.loading = false;
            this.items.parsed = parsed;
        },      
        getDashboards(){
            const that = this;
            const request = {
                controller : 'Graph',
                showloading : false,
                body : {
                    action: 'GetDashboard',
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : function(response) {
                        that.items.dashboards = response.dashboards;
                        if(that.items.folders != null && that.items.images != null){ that.buildView(); }
                    },
                    error : function(response) {
                        console.error(response);
                    }
                },
            }
            this.NETWORK.AjaxRequest(request);
        },
        getUserSettings(){
            const that = this;
            const request = {
                controller : 'Graph',
                showloading : false,
                body : {
                    action: 'getimpostazioniutente',
                    token: localStorage.user_token,
                    id_utente : localStorage.id
                },
                method: 'POST',
                on : {
                    load : function(response) {
                        try{
                            that.user_settings = response.impostazioni[0];
                            const parsed = JSON.parse(decodeURIComponent(escape(atob(that.user_settings.impostazioni))));
                            that.items.folders = parsed.directories;
                        }catch{
                            that.items.folders = new Array();
                            that.user_settings = { id_impostazione: -1 };
                        }
                        that.user_settings.utente = localStorage.id;
                        if(that.items.dashboards != null && that.items.images != null){ that.buildView(); }
                    },
                    error : function(response) {
                        console.error(response);
                    }
                },
            }
            this.NETWORK.AjaxRequest(request);
        },    
        handleImageEvent(images){
            this.items.images = images;
            if(this.startup){
                this.startup = false;
                if(this.items.dashboards != null && this.items.folders != null){ this.buildView(); }
            }            
        }, 
        refresh(){
            if(this.loading){ return; }
            this.loading = true;
            this.items.dashboards = null;
            this.items.folders = null;
            this.items.parsed = null;
            this.getDashboards();
            this.getUserSettings();
        },
        runMounted(){
            this.setToolbar();
        },
        setToolbar(){
            const that = this;
            const buttons = [ { icon:'refresh', callback: this.sync } ];
            if(this.isAdmin()){ buttons.push({ icon:'gear', callback: function(){
                that.$refs.home_settings.open();
            }});}
            document.body.dispatchEvent(new CustomEvent('graph_toolbar_items',{detail:buttons}));
        },
        sync(){
            const folders = new Array();
            for(let i=0; i < this.items.parsed.length; i++){
                const item = this.items.parsed[i];
                if(item.type == 0){
                    const dashboards = new Array();
                    for(let j = 0; j < item.data.length; j++){
                        dashboards.push(item.data[j].data.id_ds);
                    }
                    folders.push({
                        name : item.name,
                        dashboards : dashboards,
                        image : item.image
                    });
                }
            }
            const data = btoa(unescape(encodeURIComponent(JSON.stringify({ directories: folders}))));
            this.user_settings.impostazioni = data;

            const that = this;
            const request = {
                controller : 'graph',
                body : {
                    action : 'setimpostazioniutente',
                    token : localStorage.user_token,
                    impostazioni_utente : [this.user_settings],
                },
                showloading : false,
                method: 'PUT',
                on : {
                    load : function() {
                        that.refresh();
                    },
                    error : function(response) {
                        console.error(response);
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        }
    }
}
</script>
<style scoped src="./GraphHome.css" />
