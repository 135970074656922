<template>
    <div class="graph_dialog" ref="overlay">
        <datalist />
        <span />
        <div class="graph_card">
            <header>
                <h4>{{ title }}</h4>
                <GraphButton icon="close" @click="hide()" :disabled="locked"/>
            </header>
            <main><slot /></main>
        </div>
    </div>
</template>
<script>
import GraphButton from "@/components/GRAPH/.common/Buttons/GraphButton.vue";
export default {
  components: { GraphButton },
    name : 'GraphDialog',
    props : { title : String },
    data(){
        return{
            locked : false,
        }
    },
    methods : {
        display(){
            const overlay = this.$refs.overlay;
            overlay.style.display = 'block';
            setTimeout(function(){
                overlay.style.opacity = 1;
            }, 10);
        },
        hide(){
            const overlay = this.$refs.overlay;
            overlay.style.opacity = 0;
            setTimeout(function(){
                overlay.style.display = 'none';
            }, 300);
        },
        lock(){ 
            this.locked = true;
        },
        unlock(){
            this.locked = false;
        }
    }
}
</script>
<style scoped>
    .graph_dialog{
        width:100vw;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        padding: 0;
        margin: 0;
        text-align: center;
        background-color: rgba(0,0,0,0.4);
        backdrop-filter: blur(10px);
        opacity : 0;
        transition: opacity 0.3s ease;
        display: none;
    }
    .graph_dialog > span{
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width:0;
    }
    .graph_dialog > div{
        max-width: calc(100% - 60px);
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        position: relative;
    }
    .graph_dialog > div > header{
        width: 100%;
        height: 48px;
        line-height: 30px;
        display: block;
        padding: 0 10px;
        background-color: var(--accent);        
        position: relative;
    }
    .graph_dialog > div > header > h4{
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 34px);
        height: 100%;
        line-height: 48px;
        padding:0;
        margin:0;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: white;
        font-size: 16px;
    }
    .graph_dialog > div > header > h4+*{
        display: inline-block;
        vertical-align: middle;
    }
    .graph_dialog > div > main {
        max-height: calc(80vh - 48px);
        margin: 0;
        padding: 15px;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }
</style>