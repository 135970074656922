<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 3"
    >
      <v-toolbar-title class="white--text">
        Checklist di Collaudo {{ checklist.cod_checklist }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Ordine -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="salvaModifica"
            small
            v-bind="attrs"
            v-show="!readonly"
            v-on="on"
          >
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Modifica</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>

    <!-- Corpo del dialog -->
    <v-card-text class="mt-2 pa-0">
      <v-container>
        <v-row>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Codice Articolo"
              v-model="checklist.cod_articolo"
              readonly
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Mercato"
              v-model="checklist.des_mercato"
              readonly
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Impianto elettrico"
              v-model="checklist.des_impianto"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="my-0 py-0" cols="3">
            <v-text-field
              label="Motori"
              v-model="checklist.des_motori"
              readonly
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="3">
            <v-text-field
              label="Operatore"
              v-model="checklist.nominativo"
              readonly
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="3">
            <v-text-field
              label="Data Inizio Collaudo"
              v-model="date_ins_formattata"
              readonly
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="3">
            <v-menu
              v-model="calendario_fine_collaudo"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Fine Collaudo"
                  v-model="fine_collaudo_formattata"
                  readonly
                  v-on="on"
                >
                  <template slot="append">
                    <v-icon
                      @click="checklist.fine_collaudo = null"
                      color="primary"
                      v-show="
                        checklist.fine_collaudo != null &&
                        checklist.fine_collaudo != undefined &&
                        checklist.fine_collaudo != '' &&
                        !readonly
                      "
                    >
                      mdi-eraser
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                :disabled="readonly === true"
                v-model="checklist.fine_collaudo"
                @input="calendario_fine_collaudo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="my-0 py-0" cols="12">
            <!-- Toolbar della tabella delle aree -->
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text" style="width: 100%">
                <div style="width: 100%">
                  <div
                    style="display: inline-block; text-align: left; width: 50%"
                  >
                    Aree
                  </div>
                  <div
                    style="display: inline-block; text-align: right; width: 50%"
                  >
                    <!-- Chip che contiene l'icona dello stato consuntivo delle aree -->
                    <v-chip
                      class="ma-0"
                      color="white"
                      style="display: inline-block"
                    >
                      <v-icon
                        :color="
                          checklist.aree
                            ? checklist.aree.filter(
                                (a) =>
                                  a.zone &&
                                  a.zone.filter(
                                    (z) =>
                                      z.quesiti &&
                                      z.quesiti.filter((q) => q.esito === '0')
                                        .length > 0
                                  ).length > 0
                              ).length > 0
                              ? 'error'
                              : checklist.aree.filter(
                                  (a) =>
                                    a.zone &&
                                    a.zone.filter(
                                      (z) =>
                                        z.quesiti &&
                                        z.quesiti.filter(
                                          (q) => q.esito === null
                                        ).length > 0
                                    ).length > 0
                                ).length > 0
                              ? 'orange'
                              : 'green darken-3'
                            : 'green darken-3'
                        "
                        large
                      >
                        {{
                          checklist.aree
                            ? checklist.aree.filter(
                                (a) =>
                                  a.zone &&
                                  a.zone.filter(
                                    (z) =>
                                      z.quesiti &&
                                      z.quesiti.filter((q) => q.esito === "0")
                                        .length > 0
                                  ).length > 0
                              ).length > 0
                              ? "mdi-close"
                              : checklist.aree.filter(
                                  (a) =>
                                    a.zone &&
                                    a.zone.filter(
                                      (z) =>
                                        z.quesiti &&
                                        z.quesiti.filter(
                                          (q) => q.esito === null
                                        ).length > 0
                                    ).length > 0
                                ).length > 0
                              ? "mdi-play-speed"
                              : "mdi-check"
                            : "mdi-check"
                        }}
                      </v-icon>
                    </v-chip>
                  </div>
                </div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <!-- Tabella delle aree -->
            <v-data-table
              :headers="headers_aree"
              :items="checklist.aree"
              item-key="id_temp"
              class="elevation-0"
              :single-expand="true"
              :expanded.sync="expanded_aree"
              show-expand
            >
              <!-- Bottone per aggiungere le note -->
              <template v-slot:[`item.note`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="clickAggiungiNota(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Nota</span>
                </v-tooltip>
              </template>

              <!-- Colonna dello stato -->
              <template v-slot:[`item.stato`]="{ item }">
                <v-icon
                  :color="
                    item.zone
                      ? item.zone.filter(
                          (z) =>
                            z.quesiti &&
                            z.quesiti.filter((q) => q.esito === '0').length > 0
                        ).length > 0
                        ? 'error'
                        : item.zone.filter(
                            (z) =>
                              z.quesiti &&
                              z.quesiti.filter((q) => q.esito === null).length >
                                0
                          ).length > 0
                        ? 'orange'
                        : 'green darken-3'
                      : 'green darken-3'
                  "
                >
                  {{
                    item.zone
                      ? item.zone.filter(
                          (z) =>
                            z.quesiti &&
                            z.quesiti.filter((q) => q.esito === "0").length > 0
                        ).length > 0
                        ? "mdi-close"
                        : item.zone.filter(
                            (z) =>
                              z.quesiti &&
                              z.quesiti.filter((q) => q.esito === null).length >
                                0
                          ).length > 0
                        ? "mdi-play-speed"
                        : "mdi-check"
                      : "mdi-check"
                  }}
                </v-icon>
              </template>

              <!-- Drill down delle zone -->
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  class="pa-0"
                  style="background-color: white"
                  :colspan="headers.length"
                >
                  <v-toolbar color="blue darken-3" dense fixed>
                    <v-toolbar-title class="white--text">
                      Zone
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-data-table
                    class="elevation-3"
                    :headers="headers_zone"
                    hide-default-footer
                    :items="item.zone"
                    item-key="id_temp"
                    :single-expand="true"
                    :expanded.sync="expanded_zone"
                    show-expand
                  >
                    <!-- Icone di stato -->
                    <template v-slot:[`item.stato`]="{ item }">
                      <v-icon
                        :color="
                          item.quesiti.length > 0 &&
                          item.quesiti.filter((q) => q.esito === '0').length > 0
                            ? 'error'
                            : item.quesiti.length > 0 &&
                              item.quesiti.filter((q) => q.esito === null)
                                .length > 0
                            ? 'orange'
                            : 'green darken-3'
                        "
                      >
                        {{
                          item.quesiti.length > 0 &&
                          item.quesiti.filter((q) => q.esito === "0").length > 0
                            ? "mdi-close"
                            : item.quesiti.length > 0 &&
                              item.quesiti.filter((q) => q.esito === null)
                                .length > 0
                            ? "mdi-play-speed"
                            : "mdi-check"
                        }}
                      </v-icon>
                    </template>
                    <!-- Drill down dei quesiti -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td
                        class="pa-0"
                        style="background-color: white"
                        :colspan="headers.length"
                      >
                        <v-toolbar color="blue darken-1" dense fixed>
                          <v-toolbar-title class="white--text">
                            Domande
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-data-table
                          class="elevation-3"
                          :headers="headers_domande"
                          hide-default-footer
                          :items="item.quesiti"
                          :single-expand="true"
                        >
                          <!-- Colonna della descrizione tipo domanda  -->
                          <template v-slot:[`item.des_tipo`]="{ item }">
                            {{
                              item.tipo_quesito === id_domanda_polare
                                ? des_domanda_polare
                                : item.tipo_quesito === id_domanda_descrittiva
                                ? des_domanda_descrittiva
                                : item.tipo_quesito === id_domanda_immagine
                                ? des_domanda_immagine
                                : ""
                            }}
                          </template>

                          <!-- checkbox selezione degli esiti -->
                          <template v-slot:[`item.esito`]="{ item }">
                            <div
                              v-show="item.tipo_quesito === id_domanda_polare"
                            >
                              <v-icon
                                :color="item.esito == '1' ? 'success' : ''"
                                v-model="item.esito"
                                @click="
                                  readonly === false
                                    ? $set(item, 'esito', '1')
                                    : $refs.SnackbarQV.print(
                                        'success',
                                        `Collaudo terminato: la checklist è in modalità di sola lettura.`
                                      )
                                "
                              >
                                {{
                                  item.esito === "1"
                                    ? "mdi-checkbox-marked"
                                    : "mdi-checkbox-blank-outline"
                                }}
                              </v-icon>
                              <v-icon
                                :color="item.esito == '0' ? 'error' : ''"
                                v-model="item.esito"
                                @click="
                                  readonly === false
                                    ? $set(item, 'esito', '0')
                                    : $refs.SnackbarQV.print(
                                        'success',
                                        `Collaudo terminato: la checklist è in modalità di sola lettura.`
                                      )
                                "
                              >
                                {{
                                  item.esito === "0"
                                    ? "mdi-close-box"
                                    : "mdi-checkbox-blank-outline"
                                }}
                              </v-icon>
                              <v-icon
                                :color="item.esito != null ? '' : 'primary'"
                                v-model="item.esito"
                                @click="
                                  readonly === false
                                    ? $set(item, 'esito', null)
                                    : $refs.SnackbarQV.print(
                                        'success',
                                        `Collaudo terminato: la checklist è in modalità di sola lettura.`
                                      )
                                "
                              >
                                {{
                                  item.esito != null
                                    ? "mdi-checkbox-blank-outline"
                                    : "mdi-help-box"
                                }}
                              </v-icon>
                            </div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  @click="clickRispondiDomanda(item)"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="
                                    item.tipo_quesito != id_domanda_polare
                                  "
                                  ><v-icon color="primary"
                                    >mdi-pencil</v-icon
                                  ></v-btn
                                >
                              </template>
                              <span>Rispondi</span>
                            </v-tooltip>
                            <v-icon
                              class="ml-2"
                              :color="
                                item.esito === '0'
                                  ? 'error'
                                  : item.esito === null
                                  ? 'orange'
                                  : 'green darken-3'
                              "
                              v-show="item.tipo_quesito != id_domanda_polare"
                            >
                              {{
                                item.esito === "0"
                                  ? "mdi-close"
                                  : item.esito === null
                                  ? "mdi-play-speed"
                                  : "mdi-check"
                              }}
                            </v-icon>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Dialog di risposta alle domande descrittive -->
    <v-dialog v-model="inserimento_risposte_dialog">
      <RDescrizioneDialog
        @click_chiudi="chiudiDialogRisposta"
        :domanda_sel="domanda_sel"
        :id_domanda_descrittiva="id_domanda_descrittiva"
        :id_domanda_immagine="id_domanda_immagine"
        :readonly="readonly"
        @salva_risposta_descrittiva="handleSalvataRispostaDescrittiva"
        @salva_risposta_immagine="handleSalvataRispostaImmagine"
        :visible="inserimento_risposte_dialog"
      />
    </v-dialog>

    <!-- Dialog note area  -->
    <v-dialog v-model="dialog_note">
      <DialogNote
        @click_chiudi="chiudiDialogNote"
        :area_sel="area_sel"
        @salva_nota="salvaNota"
        :visible="dialog_note"
      />
    </v-dialog>

    <!-- Dialog di selezione della checklist  -->
    <v-dialog v-model="dialog_selezione_checklist">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Documenti di Collaudo Selezionabili</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="dialog_selezione_checklist = false"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-data-table
            class="mt-3"
            fixed-header
            :headers="headers_lista_checklist"
            height="50vh"
            :items="
              lista_checklist.filter(
                (e) => e.validita === 1 && e.id_articolo === null
              )
            "
          >
            <!-- Bottone di modifica -->
            <template v-slot:[`item.scegli`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickScegliChecklist(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="success"> mdi-check </v-icon>
                  </v-btn>
                </template>
                <span>Modifica</span>
              </v-tooltip>
            </template>

            <!-- Colonna della data -->
            <template v-slot:[`item.date_ins`]="{ item }">
              {{
                item.date_ins && item.date_ins.length > 0
                  ? `${item.date_ins.substring(
                      8,
                      10
                    )}/${item.date_ins.substring(
                      5,
                      7
                    )}/${item.date_ins.substring(0, 4)}`
                  : "N/A"
              }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di conferma scelta checklist -->
    <v-dialog v-model="conferma_scegli_checklist_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Associazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Vuoi associare alla barca <b>{{ riga_sel.matricola }}</b> il
            questionario di collaudo <b>{{ checklist_sel.cod_checklist }}</b
            >?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_scegli_checklist_dialog = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="scegliChecklist(checklist_sel)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- Snackbar -->
        <SnackbarQV ref="SnackbarQVConfermaScelta" />
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import Area from "@/classes/EMArea.js";
import DialogNote from "@/components/ELECTRICALMARINE/DialogNote.vue";
import Domanda from "@/classes/EMDomanda.js";
import moment from "moment/moment";
import Questionario from "@/classes/EMQuestionario.js";
import RDescrizioneDialog from "@/components/ELECTRICALMARINE/RDescrizioneDialog.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "RisposteDialog",
  components: {
    RDescrizioneDialog,
    SnackbarQV,
    DialogNote,
  },
  props: {
    readonly: { type: Boolean, default: false },
    riga_sel: { type: Object, default: () => {} },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      des_domanda_polare: null,
      des_domanda_descrittiva: null,
      des_domanda_immagine: null,
      id_domanda_polare: null,
      id_domanda_descrittiva: null,
      id_domanda_immagine: null,
      area_sel: new Area(),
      domanda_sel: new Domanda(),
      checklist: new Questionario(),
      checklist_sel: new Questionario(),

      // Modal bool
      calendario_fine_collaudo: false,
      conferma_scegli_checklist_dialog: false,
      dialog_note: false,
      dialog_selezione_checklist: false,
      inserimento_risposte_dialog: false,

      // Array
      expanded_aree: [],
      expanded_zone: [],
      headers_aree: [
        {
          text: "Numero",
          value: "num_quesito",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_quesito",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_quesito",
          sortable: false,
        },
        {
          text: "Stato",
          value: "stato",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
          sortable: false,
        },
      ],
      headers_domande: [
        {
          text: "Numero",
          value: "num_quesito",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_quesito",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_quesito",
          sortable: false,
        },
        {
          text: "Descrizione Tipo",
          value: "des_tipo",
          sortable: false,
        },
        {
          text: "Esito",
          value: "esito",
          sortable: false,
        },
      ],
      headers_zone: [
        {
          text: "Numero",
          value: "num_quesito",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_quesito",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_quesito",
          sortable: false,
        },
        {
          text: "Stato",
          value: "stato",
          sortable: false,
        },
      ],
      headers_lista_checklist: [
        {
          text: "",
          value: "scegli",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_checklist",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_checklist",
          sortable: false,
        },
        {
          text: "Mercato",
          value: "des_mercato",
          sortable: false,
        },
        {
          text: "Motori",
          value: "des_motori",
          sortable: false,
        },
        {
          text: "Data Creazione",
          value: "date_ins",
          sortable: false,
        },
      ],
      lista_checklist: new Array(),
    };
  },
  computed: {
    date_ins_formattata() {
      if (this.checklist.date_ins != null && this.checklist.date_ins != "") {
        return moment(this.checklist.date_ins).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    date_mod_formattata() {
      if (this.checklist.date_mod != null && this.checklist.date_mod != "") {
        return moment(this.checklist.date_mod).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    fine_collaudo_formattata() {
      if (
        this.checklist.fine_collaudo != null &&
        this.checklist.fine_collaudo != ""
      ) {
        return moment(this.checklist.fine_collaudo).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.getChecklist(this.riga_sel);
          if (this.readonly === true) {
            setTimeout(() => {
              this.$refs.SnackbarQV.print(
                "success",
                `Collaudo terminato: la checklist è in modalità di sola lettura.`
              );
            }, 500);
          }
        }
      },
    },
  },
  methods: {
    chiudiDialogSelezioneChecklist() {
      this.dialog_selezione_checklist = false;
    },
    clickAggiungiNota(item) {
      this.area_sel = new Area(item);
      this.dialog_note = true;
    },
    chiudiDialog() {
      // Resetto i registri di aree e di zone aperte
      this.expanded_aree = new Array();
      this.expanded_zone = new Array();

      /* Resetto la snackbar (se aprissi due finestre di risposta
       * molto velocemente la snackbar della finestra precedente
       * potrebbe ricomparire in quella successiva perché non ha
       * ancora terminato il suo ciclo di show). */
      this.$refs.SnackbarQV.chiudi();

      // Emetto l'evento per lanciare la procedura di chiusura nel padre.
      this.$emit("click_chiudi");
    },
    chiudiDialogNote() {
      this.dialog_note = false;
    },
    chiudiDialogRisposta() {
      this.inserimento_risposte_dialog = false;
    },
    clickRispondiDomanda(item) {
      this.domanda_sel = new Domanda(item);
      this.inserimento_risposte_dialog = true;
    },
    clickScegliChecklist(arg) {
      this.checklist_sel = Object.assign(arg);
      this.conferma_scegli_checklist_dialog = true;
    },
    getChecklist(arg) {
      /* Faccio la chiamata per collezionare i dati del
       * questionario associato all'articolo selezionato. */
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getchecklist",
          token: localStorage.user_token,
          id_articolo: arg.id_articolo_avo,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            /* Se nella response esiste un campo checklist (quindi se
             * esiste una checklist associata a questo articolo),
             * carico la checklist e posso iniziare a compilarla.*/
            if (res.data.checklist) {
              this.checklist = Object.assign({}, res.data.checklist[0]);
              this.checklist.aree = Array.from(res.data.aree);
            } else {
              /* Se invece non esiste un campo checklist vuol dire che non ho ancora
               * associato una checklist all'articolo, e apro il dialog per sceglierla. */
              this.dialog_selezione_checklist = true;
              this.getListaChecklist();
            }
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Errore tecnico con codice ${res.status}`
            );
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getListaChecklist() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getchecklist",
          token: localStorage.user_token,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.lista_checklist = Array.from(res.data.checklist);
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Errore tecnico con codice ${res.status}`
            );
          }
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getTabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "EM_TIPO_DOMANDA",
          filter: "validita = 1",
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            res.data.forEach((e) => {
              if (e.cod === "POLARE") {
                this.des_domanda_polare = e.des;
                this.id_domanda_polare = e.id_tabella;
              }
              if (e.cod === "K") {
                this.des_domanda_descrittiva = e.des;
                this.id_domanda_descrittiva = e.id_tabella;
              }
              if (e.cod === "IMG") {
                this.des_domanda_immagine = e.des;
                this.id_domanda_immagine = e.id_tabella;
              }
            });
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata. Stato: ${res.status}`
            );
          }
        })
        .catch((e) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(e.response.data.message).result}.`
          );
        });
    },
    handleSalvataRispostaDescrittiva(arg) {
      // Aggiorno la risposta.
      this.checklist.aree.forEach((a) => {
        if (a.id_temp === this.expanded_aree[0].id_temp) {
          a.zone.forEach((z) => {
            if (z.id_temp === this.expanded_zone[0].id_temp) {
              z.quesiti.forEach((q) => {
                if (q.id_temp === this.domanda_sel.id_temp) {
                  q.esito = arg.esito;
                  q.note = arg.note;
                }
              });
            }
          });
        }
      });

      // Chiudo il dialog di modifica della risposta.
      this.inserimento_risposte_dialog = false;
    },
    handleSalvataRispostaImmagine(arg) {
      // Aggiorno la risposta.
      this.checklist.aree.forEach((a) => {
        if (a.id_temp === this.expanded_aree[0].id_temp) {
          a.zone.forEach((z) => {
            if (z.id_temp === this.expanded_zone[0].id_temp) {
              z.quesiti.forEach((q) => {
                if (q.id_temp === this.domanda_sel.id_temp) {
                  q.esito = arg.esito;
                  q.img = arg.img;
                }
              });
            }
          });
        }
      });

      // Chiudo il dialog di modifica della risposta.
      this.inserimento_risposte_dialog = false;
    },
    salvaModifica() {
      /* Se la data di fine collaudo è valorizzata,
       * controllo che non ci siano domande in pending. */

      // Se la data di fine collaudo è valorizzata...
      if (
        this.checklist.fine_collaudo != "" &&
        this.checklist.fine_collaudo != undefined &&
        this.checklist.fine_collaudo != null
      ) {
        // ... E se esistono delle aree nella checklist..
        if (this.checklist.aree) {
          // E se dentro queste aree ci sono zone con quesiti irrisolti...
          if (
            this.checklist.aree.filter(
              (a) =>
                a.zone &&
                a.zone.filter(
                  (z) =>
                    z.quesiti &&
                    z.quesiti.filter((q) => q.esito != "0" && q.esito != "1")
                      .length > 0
                ).length > 0
            ).length > 0
          ) {
            // Allora stampo una snackbar di errore e blocco il salvataggio.
            this.$refs.SnackbarQV.print(
              "orange",
              `Rispondere a tutti i quesiti prima di concludere la compilazione del documento di collaudo.`
            );
            return;
          }
        }
      }

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setchecklist",
          token: localStorage.getItem("user_token"),
          checklist: [this.checklist],
          aree: this.checklist.aree,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            console.log("emesso salvato questionario");
            this.$emit("salvato_questionario");
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice stato: ${res.status}.`
            );
          }
        })
        .catch((e) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(e.response.data.message).result}.`
          );
        });
    },
    salvaNota(arg) {
      this.checklist.aree.forEach((a) => {
        if (a.id_temp === this.area_sel.id_temp) {
          a.note = arg;
        }
      });

      this.dialog_note = false;
    },
    scegliChecklist() {
      // Comincio a preparare la checklist da salvare
      let checklist_save = {};

      // Faccio una chiamata per avere il dettaglio del questionario da salvare
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getchecklist",
          token: localStorage.user_token,
          id_articolo: null,
          id_checklist: this.checklist_sel.id_checklist,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            /* Ora ho la testata e il dettaglio dell'oggetto da salvare,
             * quindi procedo a mettere -1 tutti a gli identificativi debiti
             * per generare un nuovo questionario. */
            checklist_save = Object.assign({}, res.data.checklist[0]);
            checklist_save.aree = Array.from(
              res.data.aree ? res.data.aree : []
            );
            checklist_save.id_articolo = this.riga_sel.id_articolo_avo;
            checklist_save.id_checklist = -1;

            checklist_save.aree.forEach((a) => {
              a.id_checklist = -1;
              a.id_checklist_dettaglio = -1;
              a.id_padre = null;
              a.id_temp = moment().valueOf();
              a.id_temp_padre = null;

              if (a.zone) {
                a.zone.forEach((z) => {
                  z.id_checklist = -1;
                  z.id_checklist_dettaglio = -1;
                  z.id_padre = -1;
                  z.id_temp = moment().valueOf();
                  z.id_temp_padre = a.id_temp;

                  if (z.quesiti) {
                    z.quesiti.forEach((q) => {
                      q.id_checklist = -1;
                      q.id_checklist_dettaglio = -1;
                      q.id_padre = -1;
                      q.id_temp = moment().valueOf();
                      q.id_temp_padre = z.id_temp;
                    });
                  }
                });
              }
            });

            let request = {
              controller: "Magazzino",
              method: "PUT",
              richiesta: {
                action: "setchecklist",
                token: localStorage.getItem("user_token"),
                checklist: [checklist_save],
              },
            };
            this.$store
              .dispatch("api", request)
              .then((res) => {
                if (res.status === 200) {
                  this.getChecklist(this.riga_sel);
                  this.conferma_scegli_checklist_dialog = false;
                  this.dialog_selezione_checklist = false;
                } else {
                  this.$refs.SnackbarQVConfermaScelta.print(
                    "orange",
                    `Criticità tecnica rilevata, codice stato: ${res.status}.`
                  );
                }
              })
              .catch((e) => {
                this.$refs.SnackbarQVConfermaScelta.print(
                  "orange",
                  `${JSON.parse(e.response.data.message).result}.`
                );
              });
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
  },
  created() {},
  mounted() {
    this.getTabelle();
  },
};
</script>
<style scoped>
</style> 
