<template>
  <div style="padding: 20px">
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-data-table
          :headers="headers_macchinari"
          :items="items_macchinari"
          class="elevation-1"
          fixed-header
          max-height="70vh"
        >
          <!-- Titolo tabella -->
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text"
                >Gestione Macchinari/Attrezzature</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-5"
                    @click="clickAggiungiMacchinario()"
                    color="blue-grey lighten-5"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Macchinario</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-5"
                    @click="filtro_visible = true"
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="primary">mdi-filter</v-icon></v-btn
                  >
                </template>
                <span>Visualizza Filtri</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                    ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
                  >
                </template>
                <span>Resetta Filtro</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToManutenzione"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-keyboard-backspace </v-icon>
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text class="pt-5" v-if="filtro_visible">
              <v-row>
                <v-col cols="4" md="1"> </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_macchinari"
                    item-text="des_famiglia"
                    item-value="id_famiglia"
                    label="Famiglia Macchinario"
                    style="display: inline-block; max-width: 350px"
                    v-model="cod_macchinario_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          famiglia_macchinario_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          famiglia_macchinario_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="1"> </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_macchinari"
                    item-text="cod_macchinario"
                    item-value="cod_macchinario"
                    label="Cod. Macchinario"
                    style="display: inline-block; max-width: 350px"
                    v-model="cod_macchinario_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          cod_macchinario_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          cod_macchinario_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="1"> </v-col>
                <v-col cols="4" md="2">
                  <v-autocomplete
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    :items="items_macchinari"
                    item-text="des_macchinario"
                    item-value="des_macchinario"
                    label="Des. Macchinario"
                    style="display: inline-block; max-width: 350px"
                    v-model="des_macchinario_search"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="
                          des_macchinario_search = '';
                          filtra();
                        "
                        color="primary"
                        >{{
                          des_macchinario_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
          <!-- Bottone dialog di modifica intervento -->
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="modificaMacchinario(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Mostra Dettagli</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.elimina`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="EliminaMacchinario(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Elimina macchinario</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog_gestione_macchinario">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title v-if="!modifica_macchinario" class="white--text"
              >Aggiungi Macchinario</v-toolbar-title
            >
            <v-toolbar-title v-else class="white--text"
              >Modifica Macchinario</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="salvaModificheMacchinario()"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="success"
                    >mdi-content-save-outline</v-icon
                  ></v-btn
                >
              </template>
              <span>Salva Modifiche</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudiDialog()" small v-bind="attrs" v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="max-height: 500px; overflow-y: auto">
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Cod. Macchinario"
                    v-model="macchinario_sel.cod_macchinario"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Des. Macchinario"
                    v-model="macchinario_sel.des_macchinario"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="macchinario_sel.id_famiglia"
                    :items="famiglia_lista"
                    label="Famiglia"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                    <template #label>
                      <span class="mr-2">Famiglia</span>
                      <span
                        @click.stop
                        style="min-width: 500px; pointer-events: all"
                      >
                        <Modules
                          v-on:dialogModules="gettabelle"
                          tipo="MAN_FAMIGLIA"
                          nome="Famiglia"
                          setaction="settabella"
                          getaction="gettabella"
                          controller="Global"
                          :filter="'validita = 1'"
                          :all_label="['des']"
                          :dati="[{ des: '' }]"
                        />
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    class="elevation-3"
                    fixed-header
                    :headers="headers_frequenza"
                    :items="
                      macchinario_sel.frequenze.filter((f) => f.validita == 1)
                    "
                  >
                    <template v-slot:top>
                      <v-toolbar color="indigo" dense>
                        <v-toolbar-title class="white--text"
                          >Frequenze Macchinario</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="aggiungiFrequenza()"
                              small
                              v-bind="attrs"
                              v-on="on"
                              ><v-icon color="primary">mdi-plus</v-icon></v-btn
                            >
                          </template>
                          <span>Aggiungi frequenza</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.modifica`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="modificaFrequenza(item)"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary"> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Modifica Intervento</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.elimina`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="EliminaFrequenza(item)"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="error"> mdi-close </v-icon>
                          </v-btn>
                        </template>
                        <span>Elimina frequenza</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-container> </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_gestione_frequenze">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title v-if="!modifica_frequenze" class="white--text"
              >Aggiungi Frequenza</v-toolbar-title
            >
            <v-toolbar-title v-else class="white--text"
              >Modifica Frequenze</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!modifica_frequenze"
                  class="mr-5"
                  @click="clickAggiungiFrequenza()"
                  color="blue-grey lighten-5"
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="success">mdi-content-save-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  class="mr-5"
                  @click="clickModificaFrequenza()"
                  color="blue-grey lighten-5"
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="success">mdi-content-save-outline</v-icon>
                </v-btn>
              </template>
              <span v-if="!modifica_frequenze">Aggiungi frequenza</span>
              <span v-else>Modifica frequenza</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    (dialog_gestione_frequenze = false),
                      (um_frequenza = null),
                      (frequenza = null)
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="frequenza"
                    type="number"
                    :min="1"
                    label="Frequenza"
                    @input="checkValidaFrequenza(frequenza)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-autocomplete
                    v-model="um_frequenza"
                    :items="um_frequenza_lista"
                    label="UM Frequenza"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_conferma_eliminazione" width="500">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title
              v-if="eliminazione_macchinario && !eliminazione_frequenza"
              class="white--text"
              >Eliminazione Macchinario</v-toolbar-title
            >
            <v-toolbar-title v-else class="white--text"
              >Eliminazione Frequenza</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-container class="pa-5">
              <v-row>
                <span
                  style="font-size: 20px"
                  v-if="eliminazione_macchinario && !eliminazione_frequenza"
                >
                  Sei sicuro di voler eliminare il macchinario selezionato?
                </span>
                <span style="font-size: 18px" v-else>
                  Sei sicuro di voler eliminare la frequenza selezionata?
                </span>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row v-if="eliminazione_macchinario && !eliminazione_frequenza">
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="confermaEliminazioneMacchinario()"
                      style="margin-right: 5px"
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="success">mdi-check</v-icon></v-btn
                    >
                  </template>
                  <span>Elimina macchinario</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="
                        (dialog_conferma_eliminazione = false),
                          (eliminazione_macchinario = false),
                          (eliminazione_frequenza = false)
                      "
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="error">mdi-close</v-icon></v-btn
                    >
                  </template>
                  <span>Chiudi</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import Machine from "@/classes/Machine.js";

export default {
  name: "Attivita",
  components: {
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      dialog_gestione_macchinario: false,
      dialog_gestione_frequenze: false,
      dialog_conferma_eliminazione: false,
      eliminazione_macchinario: false,
      eliminazione_frequenza: false,
      filtro_visible: false,
      modifica_macchinario: false,
      macchinario_sel: [],
      cod_macchinario_search: null,
      famiglia_macchinario_search: null,
      des_macchinario_search: null,
      frequenza: null,
      um_frequenza: null,
      macchinari_backup: [],
      famiglia_lista: [],
      um_frequenza_lista: [],
      modifica_frequenze: false,
      frequenza_sel: null,
      indice_frequenza_sel: null,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      // Array
      headers_macchinari: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Famiglia Macchinario",
          sortable: false,
          value: "des_famiglia",
        },
        {
          text: "Cod. Macchinario",
          sortable: false,
          value: "cod_macchinario",
        },
        {
          text: "Des. Macchinario",
          sortable: false,
          value: "des_macchinario",
        },
        {
          text: "Elimina",
          sortable: false,
          value: "elimina",
        },
      ],
      items_macchinari: [],
      headers_frequenza: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Frequenza",
          sortable: false,
          value: "frequenza",
        },
        {
          text: "UM Frequenza",
          sortable: false,
          value: "des_frequenza",
        },
        {
          text: "Elimina",
          sortable: false,
          value: "elimina",
        },
      ],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    modificaMacchinario(item) {
      this.macchinario_sel = new Machine(item);
      console.log("MACCHINARIO SELEZIONATO:",this.macchinario_sel);
      this.modifica_macchinario = true;
      this.dialog_gestione_macchinario = true;
    },
    confermaEliminazioneMacchinario() {
      this.setmacchinario(this.macchinario_sel);
      this.dialog_conferma_eliminazione = false;
      this.eliminazione_macchinario = false;
      this.eliminazione_frequenza = false;
      console.log("MACCHINARIO ELIMINATO:", this.macchinario_sel);
    },
    EliminaMacchinario(item) {
      item.validita = 0;
      item.frequenze.forEach((e) => {
        e.validita = 0;
      });
      this.macchinario_sel = item;
      this.eliminazione_macchinario = true;
      this.dialog_conferma_eliminazione = true;
    },
    EliminaFrequenza(item) {
      if (item.id_frequenza == -1) {
        let indice = this.macchinario_sel.frequenze.indexOf(item);
        this.macchinario_sel.frequenze.splice(indice, 1);
      } else {
        item.validita = 0;
      }
      console.log("MACCHINARI:", this.macchinario_sel);
    },
    clickModificaFrequenza() {
      console.log("FREQUENZA SEL:", this.frequenza_sel);
      this.frequenza_sel.frequenza = this.frequenza;
      this.frequenza_sel.um_frequenza = this.um_frequenza;
      this.um_frequenza_lista.forEach((e) => {
        if (e.id_tabella == this.frequenza_sel.um_frequenza) {
          this.frequenza_sel.des_frequenza = e.des;
        }
      });
      this.macchinario_sel.frequenze[
        this.indice_frequenza_sel
      ] = this.frequenze_sel;
      this.dialog_gestione_frequenze = false;
    },
    clickAggiungiFrequenza() {
      if (
        this.frequenza == 0 ||
        this.um_frequenza == null ||
        this.um_frequenza == ""
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Compilari tutti i campi presenti!";
        this.snackbar_text_color = "white";
        return;
      }
      let obj = {
        id_frequenza: -1,
        frequenza: this.frequenza,
        um_frequenza: this.um_frequenza,
        des_frequenza: null,
        validita: 1,
      };
      this.um_frequenza_lista.forEach((e) => {
        if (e.id_tabella == obj.um_frequenza) {
          obj.des_frequenza = e.des;
        }
      });
      this.macchinario_sel.frequenze.push(obj);
      this.frequenza = null;
      this.um_frequenza = null;
      this.dialog_gestione_frequenze = false;
    },
    modificaFrequenza(item) {
      this.frequenza_sel = item;
      console.log("FREQUENZA SELEZIONATA:",this.frequenza_sel);
      this.indice_frequenza_sel = this.macchinario_sel.frequenze.indexOf(
        this.frequenze_sel
      );
      this.modifica_frequenze = true;
      this.dialog_gestione_frequenze = true;
      this.frequenza = item.frequenza;
      this.um_frequenza = item.um_frequenza;
    },
    checkValidaFrequenza(frequenza) {
      if (parseInt(frequenza) < 1) {
        this.frequenza = 1;
      }
    },
    aggiungiFrequenza() {
      this.modifica_frequenze = false;
      this.dialog_gestione_frequenze = true;
    },
    salvaModificheMacchinario() {
      console.log("MACCHINARIO SEL PER SAVE:", this.macchinario_sel);
      if (
        this.macchinario_sel.cod_macchinario == null ||
        this.macchinario_sel.cod_macchinario == "" ||
        this.macchinario_sel.des_macchinario == null ||
        this.macchinario_sel.des_macchinario == "" ||
        this.macchinario_sel.id_famiglia == null
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Compilari tutti i campi presenti!";
        this.snackbar_text_color = "white";
        return;
      } else {
        if (this.macchinario_sel.frequenze.length == 0) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Inserire almeno una frequenza!";
          this.snackbar_text_color = "white";
          return;
        }
      }
     this.setmacchinario(this.macchinario_sel);
    },
    clickAggiungiMacchinario() {
      this.gettabelle();
      this.modifica_macchinario = false;
      this.dialog_gestione_macchinario = true;
    },
    chiudiDialog() {
      this.dialog_gestione_macchinario = false;
      this.macchinario_sel = new Machine();
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
    filtra() {
      let items_macchinari_tmp = [];
      this.items_macchinari.forEach((elem) => {
        items_macchinari_tmp.push(elem);
      });

      // Filtro il cod_richiesta
      if (this.cod_macchinario_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_macchinari_tmp.forEach((elem) => {
          if (elem.cod_macchinario == this.cod_macchinario_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_macchinari_tmp = res;
      }

      // Filtro il richiedente
      if (this.famiglia_macchinario_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_macchinari_tmp.forEach((elem) => {
          if (
            elem.des_famiglia_macchinario == this.famiglia_macchinario_search
          ) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_macchinari_tmp = res;
      }

      if (this.des_macchinario_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_macchinari_tmp.forEach((elem) => {
          if (elem.des_macchinario == this.des_macchinario_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_macchinari_tmp = res;
      }

      if (items_macchinari_tmp.length != 0)
        this.items_macchinari = items_macchinari_tmp;
      else this.items_macchinari = this.macchinari_backup;
    },
    resetFiltro() {
      // Resetto i filtri
      this.cod_macchinario_search = "";
      this.famiglia_macchinario_search = "";
      this.des_macchinario_search = "";

      // Nascondo i filtri
      this.filtro_visible = false;

      // Ricarico la tabella
      this.items_macchinari = Array.from(this.macchinari_backup);
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "'MAN_FAMIGLIA','MAN_UM_FREQUENZA'",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.famiglia_lista =
          res.data.MAN_FAMIGLIA != undefined ? res.data.MAN_FAMIGLIA : [];
        this.um_frequenza_lista =
          res.data.MAN_UM_FREQUENZA != undefined
            ? res.data.MAN_UM_FREQUENZA
            : [];
      });
    },
    getmacchinarilist() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getmacchinarilist",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          if (res.data.result.macchinari.length != 0) {
            this.items_macchinari = res.data.result.macchinari;
          }
        }
      });
    },
    setmacchinario(macchinario) {
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setmacchinario",
          token: localStorage.getItem("user_token"),
          macchinario: [macchinario],
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.dialog_gestione_macchinario = false;
          this.getmacchinarilist();
        }else
        {
          this.snackbar = true;
          this.snackbar_background ="orange";
          this.snackbar_icon ="mdi-alert";
          this.snackbar_icon_color ="white";
          this.snackbar_text ="Errore tecnico non è stato possibile salvare i dati";
          this.snackbar_text_color ="white";
        }
      });
    },
  },
  beforeCreate(){
     this.$router.push("/home/manutenzione/main");
  },

  created() {
    this.macchinario_sel = new Machine();
  
  },
  mounted() {
    this.gettabelle();
    this.getmacchinarilist();
  },
};
</script>
<style scoped>
</style>