<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-list rounded class="ma-0 pa-0 elevation-2">
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">Attività</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="
                    intervento.id_stato != stato_approvato_id || readOnly
                  "
                  @click="aggiungiAttivita"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi Attività</span>
            </v-tooltip>
          </v-toolbar>

          <!-- Testo di avviso nessuna attività. -->
          <v-card-text v-if="items_attivita.length === 0">
            Non ci sono ancora attività per questo intervento.
          </v-card-text>
          <v-list-item-group
            v-else
            no-action
            style="max-height: 450px; overflow-y: auto"
          >
            <v-list-item
              v-for="(item, i) in items_attivita"
              :key="i"
              class="mt-4"
              @click="selezionaAttivita(item)"
              style="text-align: center"
            >
              <div style="text-align: center !important; width: 100%">
                <span style="display: inline-block">
                  {{ item.titolo }}
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      @click="setStatoAttivita(item)"
                      icon
                      style="display: inline-block"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :color="item.completata === 0 ? 'orange' : 'success'"
                        v-show="item.id_attivita > 0"
                        >{{
                          item.completata === 0
                            ? "mdi-rotate-right"
                            : "mdi-check"
                        }}</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{
                    item.completata === 0 ? "Da Eseguire" : "Eseguita"
                  }}</span>
                </v-tooltip>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="9">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              Dettagli Attività
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="attivita_sel.id_attivita === null || readOnly"
                  @click="salvaAttivita(attivita_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span> Salva Attività </span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudiDettaglio" small v-bind="attrs" v-on="on">
                  <v-icon color="red"> mdi-keyboard-backspace </v-icon>
                </v-btn>
              </template>
              <span>Chiudi Dettaglio</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="mt-5" v-if="!dettaglio_visible">
            Nessuna Attività è attualmente selezionata.
          </v-card-text>
          <v-tabs
            v-model="tab"
            slider-color="transparent"
            v-if="dettaglio_visible"
          >
            <v-tab
              active-class="py-0 my-0"
              v-for="item in ['Dati', 'Allegati', 'Team', 'Note']"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" v-if="dettaglio_visible">
            <v-tab-item>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Caratteristiche Generali Attività
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="3">
                    <v-text-field
                      label="Titolo"
                      v-model="attivita_sel.titolo"
                      :disabled="readOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      disabled
                      label="Responsabile"
                      v-model="attivita_sel.des_responsabile"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="menu_data_inizio"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      :disabled="readOnly"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="data_inizio"
                          label="Data Inizio Attività"
                          :disabled="readOnly"
                          readonly
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon color="primary">mdi-calendar-today</v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_inizio"
                        @input="menu_data_inizio = false"
                        :disabled="readOnly"
                        :min="
                          ricorsivo == true
                            ? data_contratto_inizio
                            : intervento.data_prevista
                        "
                        :max="ricorsivo == true ? data_contratto_fine : null"
                        @change="
                          calcolaDeltaOre(
                            data_inizio,
                            data_fine,
                            ora_inizio,
                            ora_fine
                          )
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="menu_data_fine"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      :disabled="readOnly"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="data_fine"
                          label="Data Fine Attività"
                          :disabled="readOnly"
                          readonly
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon color="primary">mdi-calendar-today</v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_fine"
                        @input="menu_data_fine = false"
                        :disabled="readOnly"
                        :min="
                          ricorsivo == true
                            ? data_contratto_inizio
                            : data_inizio
                        "
                        :max="ricorsivo == true ? data_contratto_fine : null"
                        @change="
                          calcolaDeltaOre(
                            data_inizio,
                            data_fine,
                            ora_inizio,
                            ora_fine
                          )
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="attivita_sel.id_tipo_attivita"
                      :items="tipo_attivita_lista"
                      :disabled="readOnly"
                      label="Tipo Attività"
                      item-text="des"
                      item-value="id_tabella"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="attivita_sel.id_categoria"
                      :items="categoria_lista"
                      :disabled="readOnly"
                      label="Categoria"
                      item-text="des"
                      item-value="id_tabella"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="menu_ora_inizio"
                      v-model="menu_ora_inizio"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="attivita_sel.ora_inizio"
                      :disabled="readOnly"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ora_inizio"
                          label="Ora Inizio Attività"
                          prepend-inner-icon="access_time"
                          :disabled="readOnly"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="ampm"
                        v-if="menu_ora_inizio"
                        v-model="ora_inizio"
                        :disabled="readOnly"
                        full-width
                        @click:minute="$refs.menu_ora_inizio.save(ora_inizio)"
                        @change="
                          calcolaDeltaOre(
                            data_inizio,
                            data_fine,
                            ora_inizio,
                            ora_fine
                          )
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      ref="menu"
                      v-model="menu_ora_fine"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="ora_fine"
                      :disabled="readOnly"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ora_fine"
                          label="Ora Fine Attività"
                          prepend-inner-icon="access_time"
                          readonly
                          v-bind="attrs"
                          :disabled="readOnly"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        @click:minute="$refs.menu.save(ora_fine)"
                        @change="
                          calcolaDeltaOre(
                            data_inizio,
                            data_fine,
                            ora_inizio,
                            ora_fine
                          )
                        "
                        v-if="menu_ora_fine"
                        :disabled="readOnly"
                        v-model="ora_fine"
                        full-width
                        format="ampm"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="2">
                    <v-text-field
                      v-model="attivita_sel.des_sede"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="attivita_sel.des_struttura"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="attivita_sel.des_area"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="attivita_sel.des_zona"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="attivita_sel.des_stanza"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="attivita_sel.des_macchinario"
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item :eager="true">
              <GestioneAllegato
                ref="componenteAllegati"
                controller_allegati="Global"
                controller_caratteristiche_tabella="Manutenzione"
                :id_riferimento="attivita_sel.id_attivita"
                tipo_allegato="ATTIVITA"
                @snackbarOpen="snackbarOpen"
                :read_only="readOnly"
              />
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headers_team"
                :items="items_team.filter((el) => el.validita != 0)"
                item-key="id_team"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar color="indigo" dense>
                    <v-toolbar-title class="white--text"
                      >Dettagli Membri Gruppo</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="clickAggiungiMembro"
                          small
                          v-bind="attrs"
                          v-on="on"
                          :disabled="readOnly"
                        >
                          <v-icon color="primary">mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Aggiungi Membro</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.specializzazioni`]="{ item }">
                  <span v-for="(ele, i) in item.specializzazioni" :key="i">
                    <span
                      v-if="
                        i > 0 &&
                        (ele.des != null || ele.des_specializzazione != null)
                      "
                      >{{ ", " }}</span
                    >
                    <span class="text-nowrap">{{
                      ele.des == undefined ? ele.des_specializzazione : ele.des
                    }}</span>
                  </span>
                </template>
                <!-- Colonna del importo orario -->
                <!-- <template v-slot:[`item.importo_orario`]="{ item }">
                  {{ item.costo_orario }}
                </template> -->

                <!-- <template
                  v-slot:[`item.ore_lavorate_feriali_mattino`]="{ item }"
                >
                 
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_feriali_mattino"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_feriali_mattino"
                  >
                  </v-text-field>
                </template>
                <template
                  v-slot:[`item.ore_lavorate_prefestive_mattino`]="{ item }"
                >
              
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_prefestive_mattino"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_prefestive_mattino"
                  >
                  </v-text-field>
                </template>
                <template
                  v-slot:[`item.ore_lavorate_festive_mattino`]="{ item }"
                >
                 
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_festive_mattino"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_festive_mattino"
                  >
                  </v-text-field>
                </template>
                <template
                  v-slot:[`item.ore_lavorate_feriali_pomeriggio`]="{ item }"
                >
                
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_feriali_pomeriggio"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_feriali_pomeriggio"
                  >
                  </v-text-field>
                </template>
                <template
                  v-slot:[`item.ore_lavorate_prefestive_pomeriggio`]="{ item }"
                >
                 
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_prefestive_pomeriggio"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_prefestive_pomeriggio"
                  >
                  </v-text-field>
                </template>
                <template
                  v-slot:[`item.ore_lavorate_festive_pomeriggio`]="{ item }"
                >
                 
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_festive_pomeriggio"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_festive_pomeriggio"
                  >
                  </v-text-field>
                </template>
                <template v-slot:[`item.ore_lavorate_feriali_notte`]="{ item }">
               
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_feriali_notte"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_feriali_notte"
                  >
                  </v-text-field>
                </template>
                <template
                  v-slot:[`item.ore_lavorate_prefestive_notte`]="{ item }"
                >
                 
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_prefestive_notte"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_prefestive_notte"
                  >
                  </v-text-field>
                </template>
                <template v-slot:[`item.ore_lavorate_festive_notte`]="{ item }">
                 
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    outlined
                    min="0"
                    :max="max_ore_lavorate_festive_notte"
                    style="
                      border-radius: 0px;
                      display: inline-block;
                      max-width: 100px;
                    "
                    type="number"
                    @change="riadattaOreLavorate(item)"
                    @input="ricalcolaDeltaOreLavorate(item)"
                    v-if="ricorsivo === false"
                    v-model="item.ore_lavorate_festive_notte"
                  >
                  </v-text-field>
                </template> -->
                <!-- Colonna del Totale importo orario -->
                <template v-slot:[`item.importo_totale`]="{ item }">
                  {{
                    item.costo_orario *
                    (item.ore_lavorate != null ? item.ore_lavorate : 1)
                  }}
                </template>

                <!-- Bottone di eliminazione -->
                <template v-slot:[`item.elimina`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="removeComponenteTeam(items_team.indexOf(item))"
                        small
                        v-bind="attrs"
                        v-on="on"
                        :disabled="readOnly"
                        ><v-icon color="error">mdi-close</v-icon></v-btn
                      >
                    </template>
                    <span>Elimina Operatore</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-textarea
                class="ma-5"
                outlined
                v-model="attivita_sel.des_attivita"
                :disabled="readOnly"
              >
              </v-textarea>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dettaglio_team_visible" width="800">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Dettagli Team</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaTeam" small v-bind="attrs" v-on="on"
                ><v-icon color="success">mdi-floppy</v-icon></v-btn
              >
            </template>
            <span>Salva Team</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiDialogTeam" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 500px; overflow-y: auto">
          <v-row>
            <v-col cols="12">
              <v-data-table
                style="width: 100%"
                :headers="headers_operatori"
                :items="operatori_fornitori"
                fixed-header
                height="55vh"
              >
                <!-- Colonna per la selezione di stato -->
                <template v-slot:[`item.selected`]="{ item }">
                  <v-checkbox v-model="item.selected"></v-checkbox>
                </template>
                <template v-slot:[`item.specializzazioni`]="{ item }">
                  <span v-for="(ele, i) in item.specializzazioni" :key="i">
                    <span v-if="i > 0 && ele.des_specializzazione != null">{{
                      ", "
                    }}</span>
                    <span class="text-nowrap">{{
                      ele.des_specializzazione
                    }}</span>
                  </span>
                </template>
                <template v-slot:[`item.livello`]="{ item }">
                  <span v-for="(ele, i) in item.specializzazioni" :key="i">
                    <span v-if="i == 0" class="text-nowrap">{{
                      ele.livello
                    }}</span>
                  </span>
                </template>
                <template v-slot:[`item.nome_tipologia`]="{ item }">
                  <span v-for="(ele, i) in item.specializzazioni" :key="i">
                    <span v-if="i == 0" class="text-nowrap">{{
                      ele.nome_tipologia
                    }}</span>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment/moment";
import Activity from "@/classes/Activity.js";
// import Intervention from "@/classes/Intervention.js";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";

export default {
  name: "DettaglioAttivita",
  components: { GestioneAllegato },
  props: {
    attivita: {
      type: Array,
      default: () => [],
    },
    intervento: {
      type: Object,
      default: () => {},
    },
    fornitori: {
      type: Array,
      default: () => [],
    },
    ricorsivo: { type: Boolean, default: false },
    stato_approvato_id: { type: Number, default: null },
    stato_rifiutato_id: { type: Number, default: null },
    stato_sospeso_id: { type: Number, default: null },
    data_contratto_inizio: { type: String, default: null },
    data_contratto_fine: { type: String, default: null },
  },
  data() {
    return {
      // Variabili d'appoggio
      attivita_sel: new Activity(),
      delta_ore: null,
      tab: null,
      readOnly: false,
      // Modal
      dettaglio_team_visible: false,
      dettaglio_visible: false,
      menu_data_fine: false,
      menu_data_inizio: false,
      menu_data_validita: false,
      menu_ora_inizio: false,
      menu_ora_fine: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      area_lista: [],
      categoria_lista: [],
      fornitori_lista: [],

      headers_operatori: [
        {
          text: "Seleziona",
          value: "selected",
          sortable: false,
        },
        {
          text: "Fornitore",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Nominativo",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Tipologia",
          value: "nome_tipologia",
          sortable: false,
        },
        {
          text: "Livello",
          value: "livello",
          sortable: false,
        },
        {
          text: "Specializzazioni",
          sortable: false,
          value: "specializzazioni",
        },
      ],
      headers_team: [
        {
          text: "Nominativo",
          value: "nominativo",
          sortable: false,
        },

        {
          text: "Tipologia",
          sortable: false,
          value: "nome_tipologia",
        },
        // {
        //   text: "Importo Orario",
        //   sortable: false,
        //   value: "importo_orario",
        // },
        // {
        //   text: "Ore Feriali Mattino",
        //   sortable: false,
        //   value: "ore_lavorate_feriali_mattino",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Feriali Pomeriggio",
        //   sortable: false,
        //   value: "ore_lavorate_feriali_pomeriggio",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Feriali Notte",
        //   sortable: false,
        //   value: "ore_lavorate_feriali_notte",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Prefestive Mattino",
        //   sortable: false,
        //   value: "ore_lavorate_prefestive_mattino",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Prefestive Pomeriggio",
        //   sortable: false,
        //   value: "ore_lavorate_prefestive_pomeriggio",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Prefestive Notte",
        //   sortable: false,
        //   value: "ore_lavorate_prefestive_notte",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Festive Mattino",
        //   sortable: false,
        //   value: "ore_lavorate_festive_mattino",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Festive Pomeriggio",
        //   sortable: false,
        //   value: "ore_lavorate_festive_pomeriggio",
        //   width: "125px",
        // },
        // {
        //   text: "Ore Festive Notte",
        //   sortable: false,
        //   value: "ore_lavorate_festive_notte",
        //   width: "125px",
        // },

        // {
        //   text: "Costo Totale",
        //   sortable: false,
        //   value: "importo_totale",
        // },
        {
          text: "Elimina",
          sortable: false,
          value: "elimina",
        },
      ],
      items_attivita: [],
      items_team: [],
      lista_completa_zone: [],
      operatori_fornitori: [],
      tipo_attivita_lista: [],
      zona_lista: [],
      max_ore_lavorate_feriali_mattino: 999,
      max_ore_lavorate_festive_mattino: 999,
      max_ore_lavorate_prefestive_mattino: 999,

      max_ore_lavorate_feriali_pomeriggio: 999,
      max_ore_lavorate_festive_pomeriggio: 999,
      max_ore_lavorate_prefestive_pomeriggio: 999,

      max_ore_lavorate_feriali_notte: 999,
      max_ore_lavorate_festive_notte: 999,
      max_ore_lavorate_prefestive_notte: 999,
    };
  },
  computed: {
    ora_fine: {
      get: function () {
        if (this.attivita_sel != null) {
          return moment(this.attivita_sel.data_fine).format("HH:mm");
        } else {
          return moment().format("HH:mm");
        }
      },
      set: function (newValue) {
        this.attivita_sel.data_fine = this.data_fine + " " + newValue;
        return this.attivita_sel.data_fine;
      },
    },
    data_fine: {
      get: function () {
        if (this.attivita_sel != null) {
          return moment(this.attivita_sel.data_fine).format("YYYY-MM-DD");
        } else {
          return moment().format("YYYY-MM-DD");
        }
      },
      set: function (newValue) {
        this.attivita_sel.data_fine = newValue + " " + this.ora_fine;
        return this.attivita_sel.data_fine;
      },
    },
    ora_inizio: {
      get: function () {
        if (this.attivita_sel != null) {
          return moment(this.attivita_sel.data_inizio).format("HH:mm");
        } else {
          return moment().format("HH:mm");
        }
      },
      set: function (newValue) {
        this.attivita_sel.data_inizio = this.data_inizio + " " + newValue;
        return this.attivita_sel.data_inizio;
      },
    },
    data_inizio: {
      get: function () {
        if (this.attivita_sel != null) {
          return moment(this.attivita_sel.data_inizio).format("YYYY-MM-DD");
        } else {
          return moment().format("YYYY-MM-DD");
        }
      },
      set: function (newValue) {
        this.attivita_sel.data_inizio = newValue + " " + this.ora_inizio;
        return this.attivita_sel.data_inizio;
      },
    },
    nominativo() {
      return localStorage.nominativo;
    },
  },
  watch: {
    attivita: {
      immediate: true,
      async handler() {
        /* Questo è praticamente un metodo di inizializzazione: ogni
         * volta che l'array delle attività cambia, io ripopolo
         * la sua copia temporanea "items_attivita" e resetto l'attivita_sel. */

        this.items_attivita = this.attivita;

        // Uso l'attività resettata per resettare l'attivita_sel
        this.attivita_sel = Object.assign({}, new Activity());

        // Chiudo il dialog dei dettagli dell'attività
        this.dettaglio_visible = false;
        //await this.getIntervento(this.id_manutenzione);
        this.getTabelle(
          "'MAN_TIPO_ATTIVITA','MAN_CATEGORIA_ATTIVITA','MAN_ZONA'",
          "validita = 1"
        );
        this.getTabelleArea(
          "MAN_AREA",
          "(val_int = " + this.intervento.id_struttura + ") AND validita = 1"
        );
      },
    },
    intervento: {
      immediate: true,
      handler() {
        this.intervento.cod_stato_richiesta == "FINISH"
          ? (this.readOnly = true)
          : (this.readOnly = false);
      },
    },
    fornitori() {
      // console.log("FORNITORI:", this.fornitori);
      if (this.fornitori.length > 0) {
        let id_fornitori = [];
        if (this.fornitori[0].id_fornitore != undefined) {
          this.fornitori.forEach((el) => {
            if (el.id_anagrafica != undefined) {
              id_fornitori.push(el.id_anagrafica);
            } else {
              id_fornitori.push(el.id_fornitore);
            }
          });
        }
        let request = {
          controller: "Manutenzione",
          method: "POST",
          richiesta: {
            action: "getoperatoribyfornitore",
            token: localStorage.user_token,
            id_ambito_manutentivo: this.intervento.id_tipo_intervento,
            fornitori: id_fornitori.length == 0 ? this.fornitori : id_fornitori,
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          if (res.status === 200) {
            this.operatori_fornitori = res.data.result.operatori;
            this.changeSelezioneOperatori();
          }
        });
      }
    },
  },
  methods: {
    ricalcolaDeltaOreLavorate(item) {
      // console.log("item:", item);

      let ore_feriali_mattino = parseFloat(item.ore_lavorate_feriali_mattino);
      let ore_prefestive_mattino = parseFloat(
        item.ore_lavorate_prefestive_mattino
      );
      let ore_festive_mattino = parseFloat(item.ore_lavorate_festive_mattino);

      let ore_feriali_pomeriggio = parseFloat(
        item.ore_lavorate_feriali_pomeriggio
      );
      let ore_prefestive_pomeriggio = parseFloat(
        item.ore_lavorate_prefestive_pomeriggio
      );
      let ore_festive_pomeriggio = parseFloat(
        item.ore_lavorate_festive_pomeriggio
      );

      let ore_feriali_notte = parseFloat(item.ore_lavorate_feriali_notte);
      let ore_prefestive_notte = parseFloat(item.ore_lavorate_prefestive_notte);
      let ore_festive_notte = parseFloat(item.ore_lavorate_festive_notte);

      if (
        ore_feriali_mattino +
          ore_prefestive_mattino +
          ore_festive_mattino +
          ore_feriali_pomeriggio +
          ore_prefestive_pomeriggio +
          ore_festive_pomeriggio +
          ore_feriali_notte +
          ore_prefestive_notte +
          ore_festive_notte >=
        this.delta_ore
      ) {
        this.max_ore_lavorate_feriali_mattino = ore_feriali_mattino;
        this.max_ore_lavorate_prefestive_mattino = ore_prefestive_mattino;
        this.max_ore_lavorate_festive_mattino = ore_festive_mattino;

        this.max_ore_lavorate_feriali_pomeriggio = ore_feriali_pomeriggio;
        this.max_ore_lavorate_prefestive_pomeriggio = ore_prefestive_pomeriggio;
        this.max_ore_lavorate_festive_pomeriggio = ore_festive_pomeriggio;

        this.max_ore_lavorate_feriali_notte = ore_feriali_notte;
        this.max_ore_lavorate_prefestive_notte = ore_prefestive_notte;
        this.max_ore_lavorate_festive_notte = ore_festive_notte;

        return;
      } else {
        this.max_ore_lavorate_feriali_mattino = 999;
        this.max_ore_lavorate_prefestive_mattino = 999;
        this.max_ore_lavorate_festive_mattino = 999;

        this.max_ore_lavorate_feriali_pomeriggio = 999;
        this.max_ore_lavorate_prefestive_pomeriggio = 999;
        this.max_ore_lavorate_festive_pomeriggio = 999;

        this.max_ore_lavorate_feriali_notte = 999;
        this.max_ore_lavorate_prefestive_notte = 999;
        this.max_ore_lavorate_festive_notte = 999;
      }
    },
    riadattaOreLavorate(item) {
      // console.log("item:", item);

      // console.log("ORE LAVORATE:", item.ore_lavorate);
      // console.log("DELTA", this.delta_ore);
      if (
        parseFloat(item.ore_lavorate) +
          parseFloat(item.ore_lavorate_feriali_mattino) +
          parseFloat(item.ore_lavorate_feriali_pomeriggio) +
          parseFloat(item.ore_lavorate_feriali_notte) +
          parseFloat(item.ore_lavorate_festive_mattino) +
          parseFloat(item.ore_lavorate_festive_pomeriggio) +
          parseFloat(item.ore_lavorate_festive_notte) +
          parseFloat(item.ore_lavorate_prefestive_mattino) +
          parseFloat(item.ore_lavorate_prefestive_pomeriggio) +
          parseFloat(item.ore_lavorate_prefestive_notte) >
        this.delta_ore
      ) {
        item.ore_lavorate_feriali_mattino != 0
          ? parseFloat(item.ore_lavorate_feriali_mattino) - 1
          : item.ore_lavorate_feriali_mattino;

        item.ore_lavorate_feriali_pomeriggio != 0
          ? parseFloat(item.ore_lavorate_feriali_pomeriggio) - 1
          : item.ore_lavorate_feriali_pomeriggio;

        item.ore_lavorate_feriali_notte != 0
          ? parseFloat(item.ore_lavorate_feriali_mattino_notte) - 1
          : item.ore_lavorate_feriali_mattino_notte;

        item.ore_lavorate_festive_mattino != 0
          ? parseFloat(item.ore_lavorate_festive_mattino) - 1
          : item.ore_lavorate_festive_mattino;

        item.ore_lavorate_festive_pomeriggio != 0
          ? parseFloat(item.ore_lavorate_festive_pomeriggio) - 1
          : item.ore_lavorate_festive_pomeriggio;

        item.ore_lavorate_festive_notte != 0
          ? parseFloat(item.ore_lavorate_festive_notte) - 1
          : item.ore_lavorate_festive_notte;

        item.ore_lavorate_prefestive_mattino != 0
          ? parseFloat(item.ore_lavorate_prefestive_mattino) - 1
          : item.ore_lavorate_prefestive_mattino;

        item.ore_lavorate_prefestive_pomeriggio != 0
          ? parseFloat(item.ore_lavorate_prefestive_pomeriggio) - 1
          : item.ore_lavorate_prefestive_pomeriggio;

        item.ore_lavorate_prefestive_notte != 0
          ? parseFloat(item.ore_lavorate_prefestive_notte) - 1
          : item.ore_lavorate_prefestive_notte;
      }
      if (parseFloat(item.ore_lavorate_feriali_mattino) > this.delta_ore) {
        item.ore_lavorate_feriali_mattino = this.delta_ore.toString();
      }
      if (parseFloat(item.ore_lavorate_festive_mattino) > this.delta_ore) {
        item.ore_lavorate_festive_mattino = this.delta_ore.toString();
      }
      if (parseFloat(item.ore_lavorate_prefestive_mattino) > this.delta_ore) {
        item.ore_lavorate_prefestive_mattino = this.delta_ore.toString();
      }

      if (parseFloat(item.ore_lavorate_feriali_pomeriggio) > this.delta_ore) {
        item.ore_lavorate_feriali_pomeriggio = this.delta_ore.toString();
      }
      if (parseFloat(item.ore_lavorate_festive_pomeriggio) > this.delta_ore) {
        item.ore_lavorate_festive_pomeriggio = this.delta_ore.toString();
      }
      if (
        parseFloat(item.ore_lavorate_prefestive_pomeriggio) > this.delta_ore
      ) {
        item.ore_lavorate_pomeriggio = this.delta_ore.toString();
      }

      if (parseFloat(item.ore_lavorate_feriali_notte) > this.delta_ore) {
        item.ore_lavorate_feriali_notte = this.delta_ore.toString();
      }
      if (parseFloat(item.ore_lavorate_festive_notte) > this.delta_ore) {
        item.ore_lavorate_notte = this.delta_ore.toString();
      }
      if (parseFloat(item.ore_lavorate_prefestive_notte) > this.delta_ore) {
        item.ore_lavorate_notte = this.delta_ore.toString();
      }
      this.items_attivita.forEach((e) => {
        if (e.id_attivita == this.attivita_sel.id_attivita) {
          e.team = this.attivita_sel.team;
        }
      });
    },

    aggiungiAttivita() {
      // Dichiaro un oggetto con valori resettati
      let nuova_attivita = new Activity();
      // console.log("INTERVENTO:", this.intervento);
      /* Setto una serie di parametri dell'attività in base
       * ai valori di supporto che trovo nel componente. */
      nuova_attivita.des_responsabile = localStorage.nominativo;
      nuova_attivita.des_sede = this.intervento.des_sede;
      nuova_attivita.des_struttura = this.intervento.des_struttura;
      nuova_attivita.id_attivita = -moment().valueOf();
      nuova_attivita.id_consuntivo = this.intervento.id_consuntivo;
      nuova_attivita.id_intervento = this.intervento.id_intervento;
      nuova_attivita.id_responsabile = localStorage.id;
      nuova_attivita.id_sede = this.intervento.id_sede;
      nuova_attivita.id_struttura = this.intervento.id_struttura;
      nuova_attivita.id_area = this.intervento.id_area;
      nuova_attivita.id_zona = this.intervento.id_zona;
      nuova_attivita.id_stanza = this.intervento.id_stanza;
      nuova_attivita.id_macchinario = this.intervento.id_macchinario;
      nuova_attivita.des_area = this.intervento.des_area;
      nuova_attivita.des_zona = this.intervento.des_zona;
      nuova_attivita.des_stanza = this.intervento.des_stanza;
      nuova_attivita.des_macchinario = this.intervento.des_macchinario;

      // Aggiungo all'array delle attività l'oggetto resettato
      this.items_attivita.push(nuova_attivita);

      // Assegno all'attivita_sel i valori resettati
      this.attivita_sel = Object.assign({}, nuova_attivita);
      // console.log("ATTIVITA NUOVA:", this.attivita_sel);

      // Apro il dialog di modifica dell'attività
      this.dettaglio_visible = true;
    },
    calcolaDeltaOre(data_inizio, data_fine, ora_inizio, ora_fine) {
      /* Controllo che gli argomenti non siano null; se
       * non lo sono, proseguo, altrimenti mi fermo. */
      if (
        data_inizio == null ||
        data_fine == null ||
        ora_inizio == null ||
        ora_fine == null
      ) {
        return;
      }

      let dayStart = data_inizio.split("-")[2];
      let dayEnd = data_fine.split("-")[2];
      let minuteStart = parseFloat(ora_inizio.split(":")[1] / 60);
      let minuteEnd = parseFloat(ora_fine.split(":")[1] / 60);
      let hourStart =
        parseFloat(ora_inizio.split(":")[0]) +
        parseFloat(minuteStart.toFixed(1));

      let hourEnd =
        parseFloat(ora_fine.split(":")[0]) + parseFloat(minuteEnd.toFixed(1));
      hourEnd = 24 - hourEnd;
      let daysActivity = dayEnd - dayStart + 1;
      let hoursActiviy = daysActivity * 24;
      this.delta_ore = parseFloat(
        (hoursActiviy - hourStart - hourEnd).toFixed(1)
      );
      // console.log("DELTA ORE:", this.delta_ore);
    },
    changeSelezioneOperatori() {
      this.operatori_fornitori.forEach((el) => {
        let esiste = false;
        this.attivita_sel.team.forEach((el2) => {
          if (
            (el.id_anagrafica_contatto == el2.id_operatore ||
              el.id_anagrafica_contatto == el2.id_anagrafica_contatto) &&
            el2.validita != 0
          ) {
            esiste = true;
          }
        });
        el.selected = esiste;
      });
    },
    changeZone() {
      this.zona_lista = this.lista_completa_zone.filter(
        (el) => el.val_int == this.attivita_sel.id_area
      );
    },
    chiudiDettaglio() {
      // Chiudo il dialog di modifica dell'attività
      this.dettaglio_visible = false;

      // Uso l'oggetto resettato per resettare l'attivita_sel
      this.attivita_sel = Object.assign({}, new Activity());
    },
    chiudiDialogTeam() {
      this.dettaglio_team_visible = false;
    },
    async clickAggiungiMembro() {
      this.dettaglio_team_visible = true;
    },
    async getAttivitaIntervento(id_attivita) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getattivitadettaglio",
          id_attivita: id_attivita,
          token: localStorage.user_token,
        },
      };

      await this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.attivita_sel = res.data.result.attivita[0];
          let data_inizio = this.attivita_sel.data_inizio.split(" ")[0];
          let data_fine = this.attivita_sel.data_fine.split(" ")[0];
          let ora_inizio = this.attivita_sel.data_inizio.split(" ")[1];
          let ora_fine = this.attivita_sel.data_fine.split(" ")[1];
          this.calcolaDeltaOre(data_inizio, data_fine, ora_inizio, ora_fine);
          if (res.data.result.attivita[0].team != undefined) {
            this.items_team = res.data.result.attivita[0].team;
          } else {
            this.items_team = [];
            this.attivita_sel.team = [];
          }
          //riporto informazioni pulite di lista_src_tmp in this.lista_src, così da avere sempre le src corrette
          this.changeSelezioneOperatori();
          this.changeZone();
        }
      });
    },
    async getTabelle(tipi, filter) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "gettabella",
          tipo: tipi,
          filter: filter,
          token: localStorage.user_token,
        },
      };

      await this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.tipo_attivita_lista = res.data.MAN_TIPO_ATTIVITA;
          this.categoria_lista = res.data.MAN_CATEGORIA_ATTIVITA;
          this.lista_completa_zone = res.data.MAN_ZONA;
        }
      });
    },
    async getTabelleArea(tipi, filter) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "gettabella",
          tipo: tipi,
          filter: filter,
          token: localStorage.user_token,
        },
      };

      await this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200 && res.data != "") {
          this.area_lista = res.data;
        }
      });
    },
    removeComponenteTeam(item) {
      this.attivita_sel.team.forEach((e) => {
        e.validita = 1;
      });
      this.attivita_sel.team[item].validita = 0;
      this.items_attivita.forEach((e) => {
        if (e.id_attivita == this.attivita_sel.id_attivita) {
          e.team = this.attivita_sel.team;
        }
      });
      // console.log("ITEMS ATTIVITA:", this.items_attivita);
      this.items_team = [];
      this.attivita_sel.team.forEach((el) => {
        this.items_team.push(Object.assign({}, el));
      });

      this.changeSelezioneOperatori();
    },
    salvaAllegato(arg) {
      this.$refs.componenteAllegati.id_riferimento_creazione_genitore = arg;
      this.$refs.componenteAllegati.salvaTuttiAllegati();
    },
    saveOggettoAttivitaSel(arg) {
      if (arg.des_attivita == "" || arg.des_attivita == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire una descrizione dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una data inizio.
      if (arg.data_inizio == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire la data d'inizio dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una data fine.
      if (arg.data_fine == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire la data di fine dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che la data inizio non sia successiva alla data fine.
      if (!moment(arg.data_inizio).isBefore(arg.data_fine)) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "La data d'inizio dell'attività deve essere precedere la data di conclusione.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia il tipo attività.
      if (arg.id_tipo_attivita == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Specificare il tipo di attività da svolgere.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una categoria.
      if (arg.id_categoria == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Specificare la categoria dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }
      // if (arg.id_area == null) {
      //   this.snackbar = true;
      //   this.snackbar_background = "orange";
      //   this.snackbar_icon = "mdi-alert";
      //   this.snackbar_icon_color = "white";
      //   this.snackbar_text = "Inserire l' Area dell' attività";
      //   this.snackbar_text_color = "white";
      //   return;
      // }
      // if (arg.id_zona == null) {
      //   this.snackbar = true;
      //   this.snackbar_background = "orange";
      //   this.snackbar_icon = "mdi-alert";
      //   this.snackbar_icon_color = "white";
      //   this.snackbar_text = "Inserire la Zona dell' attività";
      //   this.snackbar_text_color = "white";
      //   return;
      // }

      /* Se le attività non sono recursive e il team è vuoto, stampo un errore
       * (le attività recursive invece "possono" essere salvate senza operatori). */
      if (
        arg.team.filter((el) => el.validita != 0).length == 0 &&
        this.recursione == false
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Aggiungere almeno un Operatore al team dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      this.attivita_sel.team.forEach((el) => {
        delete el.specializzazioni;
        el.id_operatore_dettaglio = el.id_operatore_dettaglio
          ? el.id_operatore_dettaglio
          : -1;
        el.id_operatore =
          el.id_anagrafica_contatto != undefined
            ? el.id_anagrafica_contatto
            : el.id_operatore;
        el.id_fornitore =
          el.id_anagrafica != undefined ? el.id_anagrafica : el.id_fornitore;
        el.id_attivita =
          this.attivita_sel.id_attivita > 0
            ? this.attivita_sel.id_attivita
            : -1;
        el.id_intervento = this.intervento.id_intervento;
        el.validita = el.validita != undefined ? el.validita : 1;
        delete el.id_anagrafica_contatto;
        delete el.id_anagrafica;
        delete el.selected;
      });
      if (this.attivita_sel.id_intervento == null) {
        this.attivita_sel.id_intervento = this.intervento.id_intervento;
      }
      if (this.intervento.id_intervento == -1 && this.ricorsivo === false) {
        this.dettaglio_visible = false;
      } else if (this.ricorsivo === false) {
        if (this.attivita_sel.id_attivita < 0) {
          this.attivita_sel.id_attivita = -1;
        }
      }
      // console.log("ATTIVITAAA:", this.items_attivita);
    },
    async salvaAttivita(arg) {
      // Controllo che ci sia una descrizione dell'attività.
      if (arg.des_attivita == "" || arg.des_attivita == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire una descrizione dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una data inizio.
      if (arg.data_inizio == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire la data d'inizio dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una data fine.
      if (arg.data_fine == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire la data di fine dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che la data inizio non sia successiva alla data fine.
      if (!moment(arg.data_inizio).isBefore(arg.data_fine)) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "La data d'inizio dell'attività deve essere precedere la data di conclusione.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia il tipo attività.
      if (arg.id_tipo_attivita == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Specificare il tipo di attività da svolgere.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia una categoria.
      if (arg.id_categoria == null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Specificare la categoria dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }
      // if (arg.id_area == null) {
      //   this.snackbar = true;
      //   this.snackbar_background = "orange";
      //   this.snackbar_icon = "mdi-alert";
      //   this.snackbar_icon_color = "white";
      //   this.snackbar_text = "Inserire l' Area dell' attività";
      //   this.snackbar_text_color = "white";
      //   return;
      // }
      // if (arg.id_zona == null) {
      //   this.snackbar = true;
      //   this.snackbar_background = "orange";
      //   this.snackbar_icon = "mdi-alert";
      //   this.snackbar_icon_color = "white";
      //   this.snackbar_text = "Inserire la Zona dell' attività";
      //   this.snackbar_text_color = "white";
      //   return;
      // }

      /* Se le attività non sono recursive e il team è vuoto, stampo un errore
       * (le attività recursive invece "possono" essere salvate senza operatori). */
      if (
        arg.team.filter((el) => el.validita != 0).length == 0 &&
        this.recursione == false
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Aggiungere almeno un Operatore al team dell'attività.";
        this.snackbar_text_color = "white";
        return;
      }

      this.attivita_sel.team.forEach((el) => {
        delete el.specializzazioni;
        el.id_operatore_dettaglio = el.id_operatore_dettaglio
          ? el.id_operatore_dettaglio
          : -1;
        el.id_operatore =
          el.id_anagrafica_contatto != undefined
            ? el.id_anagrafica_contatto
            : el.id_operatore;
        el.id_fornitore =
          el.id_anagrafica != undefined ? el.id_anagrafica : el.id_fornitore;
        el.id_attivita =
          this.attivita_sel.id_attivita > 0
            ? this.attivita_sel.id_attivita
            : -1;
        el.id_intervento = this.intervento.id_intervento;
        el.validita = el.validita != undefined ? el.validita : 1;
        delete el.id_anagrafica_contatto;
        delete el.id_anagrafica;
        delete el.selected;
      });
      if (this.attivita_sel.id_intervento == null) {
        this.attivita_sel.id_intervento = this.intervento.id_intervento;
      }
      if (this.intervento.id_intervento == -1 && this.ricorsivo === false) {
        this.dettaglio_visible = false;
      } else if (this.ricorsivo === false) {
        if (this.attivita_sel.id_attivita < 0) {
          this.attivita_sel.id_attivita = -1;
        }
        // console.log("ATTIVITA SEL:", this.attivita_sel);
        let request = {
          controller: "Manutenzione",
          method: "PUT",
          richiesta: {
            action: "setattivita",
            attivita: [this.attivita_sel],
            token: localStorage.user_token,
          },
        };
        /* Questa è una funzione asincrona perchè prima di terminare lo store dispatch
         * veniva chiuso il dialog_visible perciò non era più renderizzato il componente
         * della gestione degli allegati che perciò non trovava la funzione salvaTuttiAllegati. */
        await this.$store.dispatch("api", request).then(async (res) => {
          if (res.status === 200) {
            //chiamo funzione tramite di salvaTuttiAllegati del compoenente GestioneAllegato tramite il watch sulla variabile saveAllFiles
            this.$refs.componenteAllegati.id_riferimento_creazione_genitore =
              res.data[0].id_attivita;
            this.$refs.componenteAllegati.salvaTuttiAllegati();
            this.$emit("aggiorna_attivita");
          }
        });
        // Uso l'oggetto resettato per resettare l'attivita_sel
        this.attivita_sel = Object.assign({}, new Activity());

        // // Chiudo il dialog di modifica dell'attività
        // this.dettaglio_visible = false;
      } else if (this.ricorsivo === true) {
        // Inserisco nell'attività i miei files nuovi.
        this.attivita_sel.lista_files = this.$refs.componenteAllegati.lista_files;
        this.attivita_sel.allegati = this.$refs.componenteAllegati.lista_allegati;
        this.attivita_sel.lista_src = this.$refs.componenteAllegati.lista_src;

        /* Se sto salvando un'attività in un intervento recursivo,
         * la aggiungo all'array delle attività e faccio aggiornare il mio componente. */
        this.items_attivita.forEach((attivita) => {
          if (attivita.id_attivita === this.attivita_sel.id_attivita) {
            // Fra gle attività cerco l'indice di quella che sto modificando.
            let i = this.items_attivita.findIndex(
              (elem) => elem.id_attivita === this.attivita_sel.id_attivita
            );

            // Modifico l'intervento che devo modificare usando l'indice come chiave di accesso.
            this.items_attivita.splice(i, 1, this.attivita_sel);
          }
        });

        // Resetto l'attivita_sel.
        this.attivita_sel = Object.assign({}, new Activity());

        // Chiudo il dialog di modifica dell'attività.
        this.dettaglio_visible = false;

        this.$emit("aggiorna_attivita");
      }
    },
    salvaTeam() {
      this.operatori_fornitori.forEach((el2) => {
        // console.log("EL2", el2);
        this.attivita_sel.team.forEach((el) => {
          if (el2.selected == 1) {
            if (el2.id_anagrafica_contatto == el.id_operatore) {
              el.validita = 1;
            }
          }
        });
        if (el2.selected == 1) {
          var res = this.attivita_sel.team.filter(
            (el) => el.id_operatore == el2.id_anagrafica_contatto
          );
          if (res.length == 0) {
            this.attivita_sel.team.push({
              // costo_orario_festivo_mattino: el2.costo_orario_festivo_mattino,
              // costo_orario_festivo_pomeriggio:
              //   el2.costo_orario_festivo_pomeriggio,
              // costo_orario_festivo_notte: el2.costo_orario_festivo_notte,
              // costo_orario_feriale_mattino: el2.costo_orario_feriale_mattino,
              // costo_orario_feriale_pomeriggio:
              //   el2.costo_orario_feriale_pomeriggio,
              // costo_orario_feriale_notte: el2.costo_orario_feriale_notte,
              // costo_orario_prefestivo_mattino:
              //   el2.costo_orario_prefestivo_mattino,
              // costo_orario_prefestivo_pomeriggio:
              //   el2.costo_orario_prefestivo_pomeriggio,
              // costo_orario_prefestivo_notte: el2.costo_orario_prefestivo_notte,
              // costo_orario_festivo: el2.costo_orario_festivo,
              // costo_orario_feriale: el2.costo_orario_feriale,
              // costo_orario_prefestivo: el2.costo_orario_prefestivo,
              // nome_tipologia: el2.nome_tipologia,
              livello: el2.livello,
              nominativo: el2.nominativo,
              specializzazioni: el2.specializzazioni,
              id_operatore_dettaglio: -1,
              id_operatore: el2.id_anagrafica_contatto,
              id_fornitore: el2.id_anagrafica,
              nome_tipologia: el2.specializzazioni[0].nome_tipologia,
              id_attivita:
                this.attivita_sel.id_attivita > 0
                  ? this.attivita_sel.id_attivita
                  : -1,
              id_intervento: this.intervento.id_intervento,
              validita: 1,
            });
          }
        }
      });

      this.items_team = [];

      this.attivita_sel.team.forEach((e) => {
        e.specializzazioni.forEach((f) => {
          e.nome_tipologia = f.nome_tipologia;
        });

        this.items_team.push(e);
      });
      // console.log("attivita team", this.attivita_sel.team);

      // console.log("items team", this.items_team);
      this.items_attivita.forEach((e) => {
        if (e.id_attivita == this.attivita_sel.id_attivita) {
          e.team = this.attivita_sel.team;
        }
      });
      this.dettaglio_team_visible = false;
    },
    async selezionaAttivita(item) {
      // console.log("attivita selezionata:", item);
      this.items_team = [];
      // Vuoto l'oggetto per evitare problemi con i puntatori
      // this.attivita_sel = null;
      if (item.id_attivita > 0) {
        // Modello attivita_sel sull'oggetto selezionato
        await this.getAttivitaIntervento(item.id_attivita);
      } else {
        this.attivita_sel = new Activity(item);
        let data_inizio = this.attivita_sel.data_inizio.split(" ")[0];
        let data_fine = this.attivita_sel.data_fine.split(" ")[0];
        let ora_inizio = this.attivita_sel.data_inizio.split(" ")[1];
        let ora_fine = this.attivita_sel.data_fine.split(" ")[1];
        this.calcolaDeltaOre(data_inizio, data_fine, ora_inizio, ora_fine);
        this.items_team = this.attivita_sel.team;
        this.changeSelezioneOperatori();
      }
      // await this.getFornitori();
      // Apro il dialog di modifica dell'attività
      this.dettaglio_visible = true;

      // Preparo il that perché sia richiamato nel setTimeout.
      let that = this;

      setTimeout(function () {
        /* Faccio in modo che la GestioneAllegato non perda memoria degli allegati che ho
         * provvisoriamente caricato senza ancora salvarli. */
        if (
          that.items_attivita[that.items_attivita.indexOf(item)].lista_files !=
          undefined
        ) {
          that.$refs.componenteAllegati.lista_files =
            that.items_attivita[that.items_attivita.indexOf(item)].lista_files;
        }
        if (
          that.items_attivita[that.items_attivita.indexOf(item)].allegati !=
          undefined
        ) {
          that.$refs.componenteAllegati.lista_allegati =
            that.items_attivita[that.items_attivita.indexOf(item)].allegati;
        }
        if (
          that.items_attivita[that.items_attivita.indexOf(item)].lista_src !=
          undefined
        ) {
          that.$refs.componenteAllegati.lista_src =
            that.items_attivita[that.items_attivita.indexOf(item)].lista_src;
        }
      }, 10);
    },
    setStatoAttivita(arg) {
      arg.completata = arg.completata === 1 ? 0 : 1;
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setstatoattivita",
          token: localStorage.user_token,
          attivita: [
            {
              id_attivita: arg.id_attivita,
              completata: arg.completata,
            },
          ],
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Resetto l'attivita_sel.
          this.attivita_sel = Object.assign({}, new Activity());

          // Chiudo il dialog di modifica dell'attività.
          this.dettaglio_visible = false;

          this.$emit("aggiorna_attivita");
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Si è verificata una criticità tecnica con codice ${res.status}, non è stato possibile modificare lo stato dell'attività.`;
          this.snackbar_text_color = "white";
        }
      });
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
  },
  created() {},
  mounted() {
    if (this.fornitori.length > 0) {
      let id_fornitori = [];
      if (this.fornitori[0].id_fornitore != undefined) {
        this.fornitori.forEach((el) => {
          if (el.id_anagrafica != undefined) {
            id_fornitori.push(el.id_anagrafica);
          } else {
            id_fornitori.push(el.id_fornitore);
          }
        });
      }
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getoperatoribyfornitore",
          token: localStorage.user_token,
          id_ambito_manutentivo: this.intervento.id_tipo_intervento,
          fornitori: id_fornitori.length == 0 ? this.fornitori : id_fornitori,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.operatori_fornitori = res.data.result.operatori;
          this.operatori_fornitori.forEach((e) => {
            e.specializzazioni.forEach((f) => {
              e.costo_orario_festivo_mattino = f.costo_orario_festivo_mattino;
              e.costo_orario_feriale_mattino = f.costo_orario_feriale_mattino;
              e.costo_orario_prefestivo_mattino =
                f.costo_orario_prefestivo_mattino;

              e.costo_orario_festivo_pomeriggio =
                f.costo_orario_festivo_pomeriggio;
              e.costo_orario_feriale_pomeriggio =
                f.costo_orario_feriale_pomeriggio;
              e.costo_orario_prefestivo_pomeriggio =
                f.costo_orario_prefestivo_pomeriggio;

              e.costo_orario_festivo_notte = f.costo_orario_festivo_notte;
              e.costo_orario_feriale_notte = f.costo_orario_feriale_notte;
              e.costo_orario_prefestivo_notte = f.costo_orario_prefestivo_notte;
              e.nome_tipologia = f.nome_tipologia;
            });
            // console.log("OPERATORI FORNITORI:", this.operatori_fornitori);
          });
          this.changeSelezioneOperatori();
        }
      });
    }
  },
};
</script>
<style scoped>
</style>