<template>
  <v-card class="mt-5 mx-5">
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text">
        Esecuzioni Autocontrolli
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Filtri (mostra campi) -->
      <v-tooltip bottom v-if="filtri_visible === false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="filtri_visible = true"
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter</v-icon></v-btn
          >
        </template>
        <span>Filtri</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" @click="ripristina" small v-bind="attrs" v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter-off</v-icon></v-btn
          >
        </template>
        <span>Ripristina</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Bottone per tornare indietro -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on">
            <v-icon color="error"> mdi-keyboard-backspace </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers_registrazioni"
        :items="items_registrazioni"
        height="70vh"
      >
        <template v-slot:top>
          <v-row align="center" justify="center" v-if="filtri_visible">
            <!-- Filtro del codice commessa -->
            <v-col cols="2">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni"
                :item-text="(e) => e.cod_commessa"
                item-value="cod_commessa"
                label="Cod. Commessa"
                v-model="cod_commessa_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      cod_commessa_search = '';
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Filtro del codice articolo -->
            <v-col cols="2">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni.filter(e => e.cod_articolo != null)"
                :item-text="(e) => e.cod_articolo"
                item-value="cod_articolo"
                label="Cod. Articolo"
                v-model="cod_articolo_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      cod_articolo_search = '';
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Filtro della descrizione fase -->
            <v-col cols="2">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni.filter(e => e.des != null)"
                :item-text="(e) => e.des"
                item-value="des_fase"
                label="Des. Fase"
                v-model="des_fase_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      des_fase_search = '';
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Filtro della data da -->
            <v-col cols="2">
              <v-menu
                v-model="calendario_data_da"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    label="Data Da"
                    v-model="data_da_search_formattata"
                    readonly
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="data_da_search"
                  @input="calendario_data_da = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Filtro della data a -->
            <v-col cols="2">
              <v-menu
                v-model="calendario_data_a"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    label="Data A"
                    v-model="data_a_search_formattata"
                    readonly
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="data_a_search"
                  @input="calendario_data_a = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Filtro della validazione -->
            <v-col cols="2">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni"
                :item-text="
                  (e) =>
                    e.conforme === null
                      ? 'indefinito'
                      : e.conforme === 1
                      ? 'approvato'
                      : 'respinto'
                "
                item-value="conforme"
                label="Conformità"
                v-model="conforme_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      conforme_search = 2;
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </template>

        <!-- Colonna della data -->
        <template v-slot:[`item.data_controllo`]="{ item }">
          {{
            item.data_controllo != null
              ? `${item.data_controllo.substring(
                  8,
                  10
                )}/${item.data_controllo.substring(
                  5,
                  7
                )}/${item.data_controllo.substring(
                  0,
                  4
                )} - ${item.data_controllo.substring(
                  11,
                  13
                )}:${item.data_controllo.substring(14, 16)}`
              : "N/D"
          }}
        </template>

        <!-- Colonna della data -->
        <template v-slot:[`item.esito`]="{ item }">
          <v-icon
            :color="
              (item.valore === null && item.misura === 1) ||
              (item.valore != null &&
                item.valore_max >= item.misura &&
                item.misura >= item.valore_min)
                ? 'success'
                : 'error'
            "
            large
          >
            {{
              (item.valore === null && item.misura === 1) ||
              (item.valore != null &&
                item.valore_max >= item.misura &&
                item.misura >= item.valore_min)
                ? "mdi-check"
                : "mdi-close"
            }}
          </v-icon>
        </template>

        <!-- Colonna della conformità -->
        <template v-slot:[`item.conforme`]="{ item }">
          <v-icon
            :color="item.conforme === 1 ? 'success' : 'grey'"
            @click="
              $set(item, 'conforme', 1);
              setAutocontrolli(item);
            "
          >
            {{
              item.conforme === 1
                ? "mdi-checkbox-marked"
                : "mdi-checkbox-blank-outline"
            }}
          </v-icon>
          <v-icon
            :color="item.conforme === 0 ? 'primary' : 'grey'"
            @click="
              $set(item, 'conforme', 0);
              setAutocontrolli(item);
            "
          >
            {{
              item.conforme === 0
                ? "mdi-checkbox-intermediate"
                : "mdi-checkbox-blank-outline"
            }}
          </v-icon>
        </template>
        <!-- :style="{
              backgroundImage: `url('${item.img}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: contain,
              border: '1px solid black',
              height: '100px',
              marginTop: 5px,
              position: 'relative',
              width: '100px',
            }"-->
        <!-- Spazio per la miniatura della foto da webcam -->
        <template v-slot:[`item.img`]="{ item }">
          <v-tooltip bottom v-if="item.image == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickDettaglioImmagine(item)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary"> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>Dettaglio immagine</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Dialog di visualizzazione dell'immagine -->
    <v-dialog v-model="dettaglio_immagine_visible">
      <Controllo
        :clear_on_save="false"
        :immagine_standard_editor="img_sel != null ? img_sel : ''"
        :show_close_button="false"
        :show_editor_tool_columns="false"
        :show_editor_save_button="false"
        :tabella_supporto="''"
        :titolo_editor="`Immagine Associata`"
        v-if="dettaglio_immagine_visible"
        :visible="true"
      />
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import moment from "moment/moment";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ControlloQualita",
  components: { Controllo, SnackbarQV },
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_commessa_search: "",
      cod_articolo_search: "",
      des_fase_search: "",
      conforme_search: 2,
      data_da_search: moment().subtract(3, "days").format("YYYY-MM-DD"),
      data_a_search: moment().format("YYYY-MM-DD"),
      img_sel: null,

      // Modal
      calendario_data_da: false,
      calendario_data_a: false,
      dettaglio_immagine_visible: false,
      filtri_visible: false,

      // Array
      headers_registrazioni: [
        { text: "Cod. Commessa", value: "cod_commessa", sortable: false },
        { text: "Cod. Articolo", value: "cod_articolo", sortable: false },
        { text: "Des. Fase", value: "des", sortable: false },
        { text: "Caratteristica", value: "caratteristica", sortable: false },
        { text: "Esito", value: "esito", sortable: false },
        {
          text: "Operatore",
          value: "nominativo",
          sortable: false,
        },
        { text: "Data", value: "data_controllo", sortable: false },
        { text: "Presa Visione", value: "conforme", sortable: false },
        { text: "Immagine", value: "img", sortable: false },
      ],
      items_registrazioni: [],
      items_registrazioni_totali: [],
    };
  },
  computed: {
    data_a_search_formattata() {
      return this.data_a_search != ""
        ? moment(this.data_a_search).format("DD/MM/YYYY")
        : "";
    },
    data_da_search_formattata() {
      return this.data_da_search != ""
        ? moment(this.data_da_search).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    clickDettaglioImmagine(arg) {
      const request = {
        controller: "Qualita",
        method: "POST",
        richiesta: {
          action: "getautocontrolli",
          token: localStorage.user_token,
          id_controllo: arg.id_ciclo_dettaglio_misure,
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.img_sel = res.data.Table1[0].img;
            this.dettaglio_immagine_visible = true;
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
            );
          }
        })
        .catch(() => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `Errore critico nello scambio di dati col server.`
          );
        });
    },
    filtra() {
      /* Preparo un array temporaneo in cui
       * collezionare gli elementi filtrati */
      let elementi_tmp = [];
      this.items_registrazioni_totali.forEach((e) => {
        elementi_tmp.push(Object.assign({}, e));
      });

      // Filtro per codice commessa
      if (this.cod_commessa_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.cod_commessa.includes(this.cod_commessa_search)) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per codice articolo
      if (this.cod_articolo_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.cod_articolo.includes(this.cod_articolo_search)) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per descrizione fase
      if (this.des_fase_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.des_fase.includes(this.des_fase_search)) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      /* Filtro per conformità. Il suo valore neutro è 2, perché
       * il BE potrebbe già valorizzare la proprietà con null. */
      if (this.conforme_search != 2) {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.conforme === this.conforme_search) {
            res.push(Object.assign({}, elem));
          } else {
            console.log(`no match: ${elem.conforme} ${this.conforme_search}`);
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Ricostruisco gli elementi visualizzati in base agli elementi filtrati.
      this.items_registrazioni = Array.from(elementi_tmp);
    },
    getAutocontrolli() {
      const request = {
        controller: "Qualita",
        method: "POST",
        richiesta: {
          action: "getautocontrolli",
          token: localStorage.user_token,
          data_da: this.data_da_search,
          data_a: this.data_a_search,
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.items_registrazioni = [];
            this.items_registrazioni_totali = [];
            console.log(res.data.Table1);
            this.items_registrazioni = Array.from(res.data.Table1);
            this.items_registrazioni_totali = Array.from(res.data.Table1);
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
            );
          }
        })
        .catch(() => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `Errore critico nello scambio di dati col server.`
          );
        });
    },
    inviaRegistrazione() {
      this.resetProperties();
      this.$emit("registrazione_inviata");
    },
    resetProperties() {},
    ripristina() {
      // Rendo invisibili i filtri e resetto le chiavi di filtro
      this.cod_commessa = "";
      this.cod_articolo = "";
      this.des_fase = "";
      this.data_da_search = moment().subtract(3, "days").format("YYYY-MM-DD");
      this.data_a_search = moment().format("YYYY-MM-DD");
      this.filtri_visible = false;

      // Resetto gli elementi visualizzati in tabella
      this.items_registrazioni = [];
      this.items_registrazioni_totali.forEach((e) => {
        this.items_registrazioni.push(Object.assign({}, e));
      });

      this.filtra();
    },
    // scaleDimensions(returnValue,width,height){
    //   if (width >= height && width > 100) {
    //       let width_nuova = 100;
    //       // Proporzione -> width_nuova : width = height_nuova : height
    //       let height_nuova = (width_nuova * height) / width;
    //       return returnValue === 'width'? width_nuova : height_nuova;
    //     } else if (height > width && height > 100) {
    //       let height_nuova = 100;
    //       // Proporzione -> width_nuova : width = height_nuova : height
    //       let width_nuova = (height_nuova * width) / height;
    //       return returnValue === 'width'? width_nuova : height_nuova;
    //     } else {
    //       return returnValue === 'width'? width_nuova : height_nuova;
    //     }
    //   }
    // },
    scaleDimensions(returnValue, width, height) {
      if (width >= height && width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * height) / width;
        return returnValue === "width" ? width_nuova : height_nuova;
      } else if (height > width && height > 100) {
        let height_nuova = 100;
        let width_nuova = (height_nuova * width) / height;
        return returnValue === "width" ? width_nuova : height_nuova;
      } else {
        return returnValue === "width" ? width : height;
      }
    },
    setAutocontrolli(arg) {
      const request = {
        controller: "Qualita",
        method: "PUT",
        richiesta: {
          action: "setautocontrollocristal",
          token: localStorage.user_token,
          contenitore: [arg],
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.getAutocontrolli();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità di dialogo col server, codice errore: ${res.status}.`
            );
          }
        })
        .catch(() => {
          this.$refs.SnackbarQV.print(
            "error",
            "Errore critico nello scambio di dati col server."
          );
        });
    },
  },
  created() {},
  mounted() {
    this.getAutocontrolli();
  },
};
</script>
<style scoped>
</style>