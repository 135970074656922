<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar
      color="indigo"
      dense
      fixed
      style="position: sticky; top: 0px; z-index: 3"
    >
      <v-toolbar-title class="white--text">
        Questionario di Collaudo
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Ordine -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaModifica" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Modifica</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>

    <!-- Corpo del dialog -->
    <v-card-text class="mt-2 pa-0">
      <v-container>
        <v-toolbar class="mb-5" color="indigo" dense>
          <v-toolbar-title class="white--text">Generale</v-toolbar-title>
        </v-toolbar>
        <v-row>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Codice"
              v-model="questionario_sel_tmp.cod_checklist"
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Descrizione"
              v-model="questionario_sel_tmp.des_checklist"
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Mercato"
              v-model="questionario_sel_tmp.des_mercato"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Impianto Elettrico"
              v-model="questionario_sel_tmp.des_impianto"
            ></v-text-field>
          </v-col>
          <v-col class="my-0 py-0" cols="4">
            <v-text-field
              label="Motori"
              v-model="questionario_sel_tmp.des_motori"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <!-- Tabella delle aree -->
            <v-toolbar color="indigo" class="mt-2" dense>
              <v-toolbar-title class="white--text"> Aree </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickAggiungiArea"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary"> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Area</span>
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-data-table
              :headers="headers_aree"
              :items="questionario_sel_tmp.aree.filter((e) => e.validita === 1)"
              item-key="id_temp"
              class="elevation-0"
              :single-expand="true"
              :expanded.sync="expanded_aree"
              show-expand
            >
              <!-- Bottone di modifica dell'area -->
              <template v-slot:[`item.modifica`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="clickModificaArea(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Area</span>
                </v-tooltip>
              </template>

              <!-- Bottone per nascondere la checklist -->
              <template v-slot:[`item.elimina`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="clickEliminaArea(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
              </template>

              <!--Drill down delle zone -->
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  class="pa-0"
                  style="background-color: white"
                  :colspan="headers.length"
                >
                  <v-toolbar color="blue darken-3" dense fixed>
                    <v-toolbar-title class="white--text">
                      Zone
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- Aggiungi -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="clickAggiungiZona"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary"> mdi-plus </v-icon>
                        </v-btn>
                      </template>
                      <span>Aggiungi Zona</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-data-table
                    class="elevation-3"
                    :headers="headers_zone"
                    hide-default-footer
                    :items="
                      item.zone ? item.zone.filter((e) => e.validita === 1) : []
                    "
                    item-key="id_temp"
                    :single-expand="true"
                    :expanded.sync="expanded_zone"
                    show-expand
                  >
                    <!-- Bottone di modifica della zona -->
                    <template v-slot:[`item.modifica`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="clickModificaZona(item)"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary"> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Modifica Zona</span>
                      </v-tooltip>
                    </template>

                    <!-- Bottone per nascondere la checklist -->
                    <template v-slot:[`item.elimina`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="clickEliminaZona(item)"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="error"> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Elimina</span>
                      </v-tooltip>
                    </template>

                    <!-- Drill down delle domande -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td
                        class="pa-0"
                        style="background-color: white"
                        :colspan="headers.length"
                      >
                        <v-toolbar color="blue darken-1" dense fixed>
                          <v-toolbar-title class="white--text">
                            Domande
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- Aggiungi -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="clickAggiungiDomanda"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="primary"> mdi-plus </v-icon>
                              </v-btn>
                            </template>
                            <span>Aggiungi Domanda</span>
                          </v-tooltip>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-data-table
                          class="elevation-3"
                          :headers="headers_domande"
                          hide-default-footer
                          :items="
                            item.quesiti
                              ? item.quesiti.filter((e) => e.validita === 1)
                              : []
                          "
                        >
                          <!-- Bottone di modifica della domanda -->
                          <template v-slot:[`item.modifica`]="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  @click="clickModificaDomanda(item)"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="primary"> mdi-pencil </v-icon>
                                </v-btn>
                              </template>
                              <span>Modifica Domanda</span>
                            </v-tooltip>
                          </template>

                          <!-- Colonna della descrizione tipo domanda  -->
                          <template v-slot:[`item.des_tipo`]="{ item }">
                            {{
                              item.tipo_quesito === id_domanda_polare
                                ? des_domanda_polare
                                : item.tipo_quesito === id_domanda_descrittiva
                                ? des_domanda_descrittiva
                                : item.tipo_quesito === id_domanda_immagine
                                ? des_domanda_immagine
                                : ""
                            }}
                          </template>

                          <!-- Bottone per nascondere la domanda -->
                          <template v-slot:[`item.elimina`]="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  @click="clickEliminaDomanda(item)"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="error"> mdi-delete </v-icon>
                                </v-btn>
                              </template>
                              <span>Elimina</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Dialog di creazione / modifica domande -->
    <v-dialog v-model="modifica_area">
      <ModificaArea
        :area_sel="area_sel"
        @click_chiudi="chiudiDialogArea"
        @salva_area="salvaArea"
        :visible="modifica_area"
      />
    </v-dialog>

    <!-- Dialog di creazione / modifica domande -->
    <v-dialog v-model="modifica_domanda">
      <ModificaDomanda
        @click_chiudi="chiudiDialogDomanda"
        :domanda_sel="domanda_sel"
        :key="key_modifica_domanda"
        @salva_domanda="salvaDomanda"
        :visible="modifica_domanda"
      />
    </v-dialog>

    <!-- Dialog di creazione / modifica zone -->
    <v-dialog v-model="modifica_zona">
      <ModificaZona
        @click_chiudi="chiudiDialogZona"
        @salva_zona="salvaZona"
        :visible="modifica_zona"
        :zona_sel="zona_sel"
      />
    </v-dialog>

    <!-- Dialog di conferma eliminazione area -->
    <v-dialog v-model="conferma_eliminazione_area" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi l'eliminazione dell'area selezionata?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_eliminazione_area = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="eliminaArea"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di conferma eliminazione zona -->
    <v-dialog v-model="conferma_eliminazione_zona" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi l'eliminazione della zona selezionata?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_eliminazione_zona = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="eliminaZona"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di conferma eliminazione domanda -->
    <v-dialog v-model="conferma_eliminazione_domanda" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi l'eliminazione del quesito selezionato?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_eliminazione_domanda = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="eliminaDomanda"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import _ from "lodash";
import Area from "@/classes/EMArea.js";
import Domanda from "@/classes/EMDomanda.js";
import ModificaArea from "@/components/ELECTRICALMARINE/ModificaArea.vue";
import ModificaDomanda from "@/components/ELECTRICALMARINE/ModificaDomanda.vue";
import ModificaZona from "@/components/ELECTRICALMARINE/ModificaZona.vue";
import moment from "moment/moment";
import Questionario from "@/classes/EMQuestionario.js";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import Zona from "@/classes/EMZona.js";

export default {
  name: "ListaSpecifiche",
  components: {
    ModificaArea,
    ModificaDomanda,
    ModificaZona,
    SnackbarQV,
  },
  props: {
    questionario_sel: {
      type: Object,
      default: () => new Questionario(),
    },
    des_domanda_descrittiva: { type: String, default: "" },
    des_domanda_immagine: { type: String, default: "" },
    des_domanda_polare: { type: String, default: "" },
    id_domanda_descrittiva: { type: Number, default: null },
    id_domanda_immagine: { type: Number, default: null },
    id_domanda_polare: { type: Number, default: null },
  },
  data() {
    return {
      // Variabili d'appoggio
      area_sel: new Area(),
      domanda_sel: new Domanda(),
      questionario_sel_tmp: new Questionario(),
      zona_sel: new Zona(),

      // Modal bool
      conferma_eliminazione_area: false,
      conferma_eliminazione_domanda: false,
      conferma_eliminazione_zona: false,
      key_modifica_domanda: 0,
      modifica_area: false,
      modifica_domanda: false,
      modifica_zona: false,

      // Array
      expanded_aree: [],
      expanded_zone: [],
      headers_aree: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "#",
          value: "num_quesito",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_quesito",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_quesito",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      headers_domande: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "#",
          value: "num_quesito",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_quesito",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_quesito",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "des_tipo",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      headers_zone: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "#",
          value: "num_quesito",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_quesito",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_quesito",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
  watch: {
    questionario_sel: {
      immediate: true,
      handler() {
        this.questionario_sel_tmp = new Questionario(this.questionario_sel);
      },
    },
    opz_controllo: {
      immediate: true,
      handler() {
        if (this.opz_controllo === 1);
      },
    },
  },
  methods: {
    chiudiDialog() {
      this.expanded_aree = new Array();
      this.expanded_zone = new Array();
      this.$emit("click_chiudi");
    },
    chiudiDialogArea() {
      this.modifica_area = false;
    },
    chiudiDialogDomanda() {
      this.modifica_domanda = false;
    },
    chiudiDialogZona() {
      this.modifica_zona = false;
    },
    clickAggiungiArea() {
      this.area_sel = new Area();
      this.modifica_area = true;
    },
    clickAggiungiDomanda() {
      this.domanda_sel = new Domanda();
      this.domanda_sel.id_temp_padre = this.expanded_zone[0].id_temp;
      this.modifica_domanda = true;
    },
    clickAggiungiZona() {
      this.zona_sel = new Zona();
      this.zona_sel.id_temp_padre = this.expanded_aree[0].id_temp;
      this.modifica_zona = true;
    },
    clickEliminaArea(arg) {
      // Registro l'area che ho cliccato
      this.area_sel = new Area(arg);

      // Faccio uscire un dialog di conferma
      this.conferma_eliminazione_area = true;
    },
    clickEliminaDomanda(arg) {
      // Registro la domanda che ho cliccato
      this.domanda_sel = new Domanda(arg);

      // Faccio uscire un dialog di conferma
      this.conferma_eliminazione_domanda = true;
    },
    clickEliminaZona(arg) {
      // Registro l'area che ho cliccato
      this.zona_sel = new Zona(arg);

      // Faccio uscire un dialog di conferma
      this.conferma_eliminazione_zona = true;
    },
    clickModificaArea(arg) {
      this.area_sel = new Area(arg);
      this.modifica_area = true;
    },
    clickModificaDomanda(arg) {
      this.domanda_sel = new Domanda(arg);
      this.key_modifica_domanda = this.key_modifica_domanda === 0 ? 1 : 0;
      this.modifica_domanda = true;
    },
    clickModificaZona(arg) {
      this.zona_sel = new Zona(arg);
      this.modifica_zona = true;
    },
    eliminaArea() {
      /* Cerco l'area e le metto la validita a 0, poi
       * invalido tuee le sue zone e le loro domande. */
      this.questionario_sel_tmp.aree.forEach((a) => {
        if (a.id_temp === this.area_sel.id_temp) {
          a.validita = 0;
          if (a.zone) {
            a.zone.forEach((z) => {
              z.validita = 0;
              if (z.quesiti) {
                z.quesiti.forEach((q) => {
                  q.validita = 0;
                });
              }
            });
          }
        }
      });

      // Ho aggiornato il mio questionario e procedo al salvataggio
      const checklist_save = _.cloneDeep(this.questionario_sel_tmp);
      const aree_save = Array.from(this.questionario_sel_tmp.aree);
      delete checklist_save.aree;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setchecklist",
          token: localStorage.user_token,
          checklist: [checklist_save],
          aree: aree_save,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("eliminazione_effettuata");
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    eliminaDomanda() {
      // Cerco la domanda e le metto la validita a 0
      this.questionario_sel_tmp.aree.forEach((a) => {
        if (a.id_temp === this.expanded_aree[0].id_temp) {
          a.zone.forEach((z) => {
            if (z.id_temp === this.expanded_zone[0].id_temp) {
              z.quesiti.forEach((q) => {
                if (q.id_temp === this.domanda_sel.id_temp) {
                  q.validita = 0;
                }
              });
            }
          });
        }
      });

      // Ho aggiornato il mio questionario e procedo al salvataggio
      const checklist_save = _.cloneDeep(this.questionario_sel_tmp);
      const aree_save = Array.from(this.questionario_sel_tmp.aree);
      delete checklist_save.aree;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setchecklist",
          token: localStorage.user_token,
          checklist: [checklist_save],
          aree: aree_save,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("eliminazione_effettuata");
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    eliminaZona() {
      /* Cerco la zona e le metto la validita a 0, poi
       * invalido anche tutti i suoi figli */
      this.questionario_sel_tmp.aree.forEach((a) => {
        if (a.id_temp === this.expanded_aree[0].id_temp) {
          a.zone.forEach((z) => {
            if (z.id_temp === this.zona_sel.id_temp) {
              z.validita = 0;
              if (z.quesiti) {
                z.quesiti.forEach((q) => {
                  q.validita = 0;
                });
              }
            }
          });
        }
      });

      // Ho aggiornato il mio questionario e procedo al salvataggio
      const checklist_save = _.cloneDeep(this.questionario_sel_tmp);
      const aree_save = Array.from(this.questionario_sel_tmp.aree);
      delete checklist_save.aree;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setchecklist",
          token: localStorage.user_token,
          checklist: [checklist_save],
          aree: aree_save,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("eliminazione_effettuata");
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    handleSceltoElemento(arg) {
      const obj_tmp = {};
      obj_tmp.id_articolo = arg.id_articolo;
      obj_tmp.cod_articolo = arg.cod_articolo;
      this.questionario_sel_tmp.articoli_associati.push(
        Object.assign({}, obj_tmp)
      );
    },
    salvaArea(arg) {
      // Se l'area non esisteva, la aggiungo
      if (arg.id_temp === -1) {
        arg.id_temp = moment().valueOf();
        arg.num_quesito = this.questionario_sel_tmp.aree.length + 1;
        this.questionario_sel_tmp.aree.push(_.cloneDeep(arg));
      } else {
        // Se esisteva, la aggiorno
        for (let a = 0; a < this.questionario_sel_tmp.aree.length; a++) {
          if (this.questionario_sel_tmp.aree[a].id_temp === arg.id_temp) {
            this.$set(this.questionario_sel_tmp.aree, a, _.cloneDeep(arg));
          }
        }
      }

      // Chiudo il dialog di modifica
      this.modifica_area = false;
    },
    salvaDomanda(arg) {
      // Controllo se la domanda eisste già
      if (arg.id_temp === -1) {
        // Se non esiste la aggiungo
        this.questionario_sel_tmp.aree.forEach((a) => {
          if (a.id_temp === this.expanded_aree[0].id_temp) {
            a.zone.forEach((z) => {
              if (z.id_temp === this.expanded_zone[0].id_temp) {
                arg.id_temp = moment().valueOf();
                /* Mi assicuro che esista un array di domande
                 * (a volte il BE non me li da se sono vuoti). */
                if (!z.quesiti) {
                  z.quesiti = [];
                }

                arg.num_quesito = z.quesiti.length + 1;

                // Agggiungo la domanda all'array
                z.quesiti.push(_.cloneDeep(arg));
              }
            });
          }
        });
      } else {
        // Se invece esisteva la aggiorno
        this.questionario_sel_tmp.aree.forEach((a) => {
          if (a.id_temp === this.expanded_aree[0].id_temp) {
            a.zone.forEach((z) => {
              if (z.id_temp === this.expanded_zone[0].id_temp) {
                for (let q = 0; q < z.quesiti.length; q++) {
                  if (z.quesiti[q].id_temp === arg.id_temp) {
                    this.$set(z.quesiti, q, _.cloneDeep(arg));
                  }
                }
              }
            });
          }
        });
      }

      // Chiudo il dialog di modifica
      this.modifica_domanda = false;
    },
    salvaModifica() {
      const checklist_save = _.cloneDeep(this.questionario_sel_tmp);
      const aree_save = Array.from(this.questionario_sel_tmp.aree);
      delete checklist_save.aree;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setchecklist",
          token: localStorage.user_token,
          checklist: [checklist_save],
          aree: aree_save,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.$emit("salvato_questionario");
        } else {
          this.$refs.SnackbarQV.print(
            "orange",
            `Criticità tecnica rilevata, codice: ${res.status}.`
          );
        }
      });
    },
    salvaZona(arg) {
      // Controllo se la zona eisste già
      if (arg.id_temp === -1) {
        // Se non esiste la aggiungo
        this.questionario_sel_tmp.aree.forEach((a) => {
          if (a.id_temp === this.expanded_aree[0].id_temp) {
            arg.id_temp = moment().valueOf();

            /* Mi assicuro che esista un array di zone
             * (se fosse vuoto il BE non me lo restituisce). */
            if (!a.zone) {
              a.zone = [];
            }

            arg.num_quesito = a.zone.length + 1;
            a.zone.push(_.cloneDeep(arg));
          }
        });
      } else {
        // Se invece esisteva la aggiorno
        this.questionario_sel_tmp.aree.forEach((a) => {
          if (a.id_temp === this.expanded_aree[0].id_temp) {
            for (let z = 0; z < a.zone.length; z++) {
              if (a.zone[z].id_temp === arg.id_temp) {
                this.$set(a.zone, z, _.cloneDeep(arg));
              }
            }
          }
        });
      }

      // Chiudo il dialog di modifica
      this.modifica_zona = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style> 
