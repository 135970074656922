<template>
  <div style="padding: 20px">
    <v-card>
      <v-toolbar
        class="indigo white--text"
        dense
        primary-title
      >
        <v-toolbar-title>Giacenze Magazzino</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              class="mr-5"
              @click="vistaSintetica"
            >
              <v-icon color="#2196f3">
                mdi-table
              </v-icon>
            </v-btn>
          </template>
          <span>Analisi sintetica</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="vistaDettagliata"
            >
              <v-icon color="#2196f3">
                mdi-table-search
              </v-icon>
            </v-btn>
          </template>
          <span>Analisi dettagliata</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="toggleFilters"
            >
              <v-icon color="purple">
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
          <span>Filtri</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="$router.push({ name: 'home-magazzino' })"
            >
              <v-icon class="error--text">
                mdi-keyboard-backspace
              </v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
        id="progress"
      >
      </v-progress-linear>

      <v-card-text>
        <v-row v-if="show_filters">
          <v-col>
            <v-row>
              <v-col class="flex-grow-1 flex-shrink-0">
                <v-text-field
                  label="Cod. Articolo"
                  dense
                  outlined
                  clearable
                  v-model="codice_articolo"
                >
                </v-text-field>
              </v-col>
              <v-col class="flex-grow-0 flex-shrink-1">
                <v-btn
                  class="mr-2"
                  elevation="1"
                  @click="searchByCodiceArticolo"
                >
                  <v-icon color="#1a80b6">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col class="flex-grow-1 flex-shrink-0">
                <v-text-field
                  label="Cod. Lotto"
                  dense
                  outlined
                  clearable
                  v-model="codice_lotto"
                >
                </v-text-field>
              </v-col>
              <v-col class="flex-grow-0 flex-shrink-1">
                <v-btn
                  class="mr-2"
                  elevation="1"
                  @click="searchByCodiceLotto"
                >
                  <v-icon color="#1a80b6">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-select
              label="Sede"
              dense
              outlined
              hide-details
              v-model="sede"
              :items="sedi"
              item-text="sedemagazzino"
              item-value="sedemagazzino"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              label="Codice Magazzino"
              dense
              outlined
              hide-details
              v-model="codice_magazzino"
              :items="codiciMagazzino"
              item-text="codicemagazzino"
              item-value="codicemagazzino"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="lista_tabella"
              item-key="id_articolo_magazzino"
              show-expand
              :expanded.sync="expandedIds"
            >
              <template v-slot:item.movimenti="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      elevation="1"
                      @click="$router.push({ name: 'movimenti-magazzino', params: { id_magazzino: item.id_magazzino, id_articolo: item.id_articolo } })"
                    >
                      <v-icon color="#1a80b6">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Lista Movimenti</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0"
                  style="background-color: white;"
                >
                  <v-data-table
                    :headers="subHeaders"
                    :items="item.lotti"
                    hide-default-footer
                    class="elevation-1"
                  >
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="dialog_multiscelta">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Articoli</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiMultiscelta"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-2" style="overflow-y: auto">
          <v-data-table
            fixed-header
            :headers="headers_articoli"
            max-height="70vh"
            :items="items_articoli"
          >
            <template v-slot:[`item.modifica`]="{ item }">
              <v-btn @click="clickScegliArticolo(item)" small>
                <v-icon color="success">mdi-check</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>

<script>
import SnackbarQV from '@/components/TOOLS/SnackbarQV.vue';

export default {
  name: 'GiacenzeMagazzino',
  components: {
    SnackbarQV,
  },
  data() {
    return {
      loading: false,
      show_filters: true,
      dialog_multiscelta: false,
      sede: null,
      codice_magazzino: null,
      codice_articolo: null,
      codice_lotto: null,
      expandedIds: [],
      items_articoli: [],
      headers: [
        {
          text: 'Codice magazzino',
          value: 'cod_magazzino',
        },
        {
          text: 'Des. magazzino',
          value: 'des_magazzino',
        },
        {
          text: 'Cod. articolo',
          value: 'cod_articolo',
        },
        {
          text: 'Des. articolo',
          value: 'des_articolo',
        },
        {
          text: 'Qnt',
          value: 'giacenza',
        },
        {
          text: 'Movimenti',
          value: 'movimenti',
          sortable: false,
        }
      ],
      subHeaders: [
        {
          text: 'Cod. lotto',
          value: 'cod_lotto',
        },
        {
          text: 'Qta',
          value: 'giacenza',
        },
        {
          text: 'Posizione',
          value: 'posizione',
        },
      ],
      headers_articoli: [
        {
          value: "modifica",
          text: "Scelta",
          sortable: false,
        },
        {
          text: "Cod. Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. articolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Famiglia",
          value: "id_famiglia",
          sortable: false,
        },
        {
          text: "Numero d'Ordine",
          value: "des_aggiuntiva",
          sortable: false,
        },
      ],
      lista_tabella: [],
      giacenzeMagazzino: [],
    };
  },
  watch: {
    sede: {
      immediate: true,
      handler() {
        this.filtro();
      },
    },
    codice_magazzino: {
      immediate: true,
      handler() {
        this.filtro();
      },
    },
  },
  computed: {
    codiciMagazzino() {
      let cod = [];
      cod.push({ codicemagazzino: "Tutti" });
      this.giacenzeMagazzino.map((item) => cod.push(item));

      return cod;
    },
    sedi() {
      let sed = [];
      sed.push({ sedemagazzino: "Tutti" });
      this.giacenzeMagazzino.map((item) => sed.push(item));

      return sed;
    },
  },
  methods: {
    filtro() {
      this.lista_tabella = this.giacenzeMagazzino;

      if (this.sede != null && this.sede != "Tutti") {
        this.lista_tabella = this.lista_tabella.filter((el) => {
          return el.sedemagazzino == this.sede;
        });
      }

      if (this.codice_magazzino != null && this.codice_magazzino != "Tutti") {
        this.lista_tabella = this.lista_tabella.filter((el) => {
          return el.codicemagazzino == this.codice_magazzino;
        });
      }
    },
    toggleFilters() {
      this.show_filters = !this.show_filters;
    },
    vistaSintetica() {
      this.expandedIds = [];
    },
    vistaDettagliata() {
      this.expandedIds = this.giacenzeMagazzino;
    },
    chiudiMultiscelta() {
      this.dialog_multiscelta = false;
    },
    clickScegliArticolo(arg) {
      this.codice_articolo = arg.cod_articolo;
      this.id_articolo_sel = arg.id_articolo;

      // Chiudo il dialog di scelta.
      this.dialog_multiscelta = false;

      this.loading = true;
      this.getArticoloMagazzini();
    },
    searchByCodiceArticolo() {
      if (this.codice_articolo === '' || this.codice_articolo === null) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Specificare un codice articolo."
        );
      } else {
        this.loading = true;

        let search_Articolo = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticoli",
            token: localStorage.getItem("user_token"),
            cod_articolo: this.codice_articolo,
          },
        };

        // Eseguo la chiamata al BE
        this.$store
          .dispatch("api", search_Articolo)
          .then((res) => {
            this.loading = false;

            if (res.status === 200) {
              // Se non ci sono risultati stampo una snackbar di avviso.
              if (res.data.lista.length === 0) {
                this.$refs.SnackbarQV.print(
                  "orange",
                  `Non sono stati trovati articoli corrispondenti ai criteri di ricerca.`
                );
              } else if (res.data.lista.length === 1) {
                this.id_articolo_sel = res.data.lista[0].id_articolo;
                this.getArticoloMagazzini();
              } else {
                /* Se ci sono più risultati li carico in una tabella e
                 * apro il dialog di scelta dell'articolo. */
                this.items_articoli = Array.from(res.data.lista);
                this.dialog_multiscelta = true;
              }
            } else {
              this.$refs.SnackbarQV.print(
                "error",
                `Criticità rilevata, non è stato possibile caricare i dati; codice errore: ${res.status}.`
              );
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$refs.SnackbarQV.print(
              "orange",
              `${JSON.parse(err.response.data.message).result}.`
            );
          });
      }
    },
    searchByCodiceLotto() {
      if (this.codice_lotto === '' || this.codice_lotto === null) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Specificare un codice lotto."
        );
      } else {
        this.loading = true;

        this.getArticoloMagazziniByLotto();
      }
    },
    getArticoloMagazzini() {
      this.codice_lotto = null;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getlottimagazzini",
          token: localStorage.user_token,
          id_articolo: this.id_articolo_sel,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.giacenzeMagazzino = [];

        res.data.articolo_magazzino.forEach((elem) => {
          elem.lotti = res.data.lotti.filter((el) => el.id_magazzino == elem.id_magazzino);
          this.giacenzeMagazzino.push(elem);
        });

        this.lista_tabella = this.giacenzeMagazzino;

        this.loading = false;
      });
    },
    getArticoloMagazziniByLotto() {
      this.codice_articolo = null;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getlottimagazzini",
          token: localStorage.user_token,
          cod_lotto: this.codice_lotto,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.giacenzeMagazzino = [];

        res.data.articolo_magazzino.forEach((elem) => {
          elem.lotti = res.data.lotti.filter((el) => el.id_magazzino == elem.id_magazzino);
          this.giacenzeMagazzino.push(elem);

          this.codice_articolo = elem.cod_articolo;
        });

        this.lista_tabella = this.giacenzeMagazzino;

        this.loading = false;
      });
    },
  },
};
</script>
