<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"
        >Macchine Multicommessa</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text style="overflow-y: auto">
      <v-data-table
        :items="items_macchine_multi"
        :headers="headers_macchine_multi"
      >
        <!-- Bottone di selezione della macchina -->
        <template v-slot:[`item.seleziona`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="my-2"
                @click="clickSelezionaMacchina(item)"
                small
                v-bind="attrs"
                v-on="on"
                x-large
              >
                <v-icon color="primary" large> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Seleziona Macchina</span>
          </v-tooltip>
        </template>

        <!-- Colonna dell'autonomia -->
        <template v-slot:[`item.autonoma`]="{ item }">
          <v-icon :color="`${item.autonoma == 1? 'success' : 'error'}`">
              {{item.autonoma == 1? "mdi-check" : "mdi-close"}}
          </v-icon>
        </template>

        <!-- Colonna della connessione online -->
        <template v-slot:[`item.on_line`]="{ item }">
          <v-icon :color="`${item.on_line == 1? 'success' : 'error'}`">
              {{item.on_line == 1? "mdi-check" : "mdi-close"}}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "ConsuntivoMacchineMulti",
  components: {},
  props: {
    items_macchine_multi: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
      headers_macchine_multi: [
        { text: "Seleziona", value: "seleziona", sortable: false },
        { text: "Cod. Macchina", value: "cod_macchina", sortable: false },
        { text: "Des. Macchina", value: "des_macchina", sortable: false },
        { text: "Matricola", value: "matricola", sortable: false },
        { text: "Marca", value: "marca", sortable: false },
        { text: "Autonoma", value: "autonoma", sortable: false },
        { text: "Online", value: "on_line", sortable: false },
      ],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    clickSelezionaMacchina(arg) {
      this.$emit("selezionata_macchina", arg.id_macchina);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>