var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dettaglio_ordine == false)?_c('div',[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"ml-4",attrs:{"label":"Operatore selezionato","outlined":"","items":_vm.operatori,"dense":""}})],1)],1),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{staticClass:"elevation-0",staticStyle:{"border-radius":"5px 5px 0px 0px"},attrs:{"dark":"","color":"blue"}},[_c('v-toolbar-title',[_vm._v("Lista Spedizioni")])],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.ordini,"items-per-page":5},scopedSlots:_vm._u([{key:"item.dettagli",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.dettaglio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye     ")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio Spedizione")])])]}}],null,false,3395624174)})],1)],1)],1)])])]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-text"},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"2","align":"left"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){_vm.dettaglio_ordine = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-format-indent-increase")]),_vm._v(" Lista Completa")],1)]}}])},[_c('span',[_vm._v("Lista Spedizioni Completa")])])],1),_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"4","align":"center"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticStyle:{"color":"black"},attrs:{"grow":"","slider-size":"5","slider-color":"primary accent-3 ","color":"black"},on:{"change":_vm.changeTab},model:{value:(_vm.modalTab),callback:function ($$v) {_vm.modalTab=$$v},expression:"modalTab"}},[_c('v-tab',{staticStyle:{"max-width":"150px"},attrs:{"href":"#packaging"}},[_vm._v("Packaging")]),_c('v-tab',{staticStyle:{"max-width":"200px"},attrs:{"href":"#pre_shipping"}},[_vm._v("PRE-SHIPPING")]),_c('v-tab',{staticStyle:{"max-width":"200px"},attrs:{"href":"#shipping"}},[_vm._v("SHIPPING")])],1),_c('v-tabs-items',{staticClass:"no-transition",attrs:{"touchless":""},model:{value:(_vm.modalTab),callback:function ($$v) {_vm.modalTab=$$v},expression:"modalTab"}},[_c('v-tab-item',{staticClass:"no-transition",attrs:{"value":"packaging","transition":"false"}},[_c('PackagingDettaglio',{attrs:{"id_anagrafica":this.id_anagrafica,"DESTINAZIONE":this.destinazione,"TRIGGER":this.ricarica},on:{"changeTab":_vm.changeTab}})],1),_c('v-tab-item',{staticClass:"no-transition",attrs:{"value":"pre_shipping","transition":"false"}},[_c('PreShipping',{attrs:{"id_anagrafica":this.id_anagrafica,"TRIGGER":this.ricarica},on:{"changeTab":_vm.changeTab}})],1),_c('v-tab-item',{staticClass:"no-transition",attrs:{"value":"shipping","transition":"false"}},[_c('Shipping',{attrs:{"id_anagrafica":this.id_anagrafica,"TRIGGER":this.ricarica},on:{"changeTab":_vm.changeTab}})],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }