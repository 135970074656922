<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar color="indigo" dense fixed>
      <v-toolbar-title class="white--text"> Lista di Picking </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Bottone per confermare l'ordine-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="clickSpecificheOrdine"
            small
            v-bind="attrs"
            v-if="
              cod_profilo === 'capobarca' ||
              cod_profilo === 'capofficina' ||
              cod_profilo === 'Admin' ||
              cod_profilo === 'manager' ||
              cod_profilo === 'CNXBOX' ||
              cod_profilo === 'SVIL'
            "
            v-on="on"
          >
            <v-icon color="primary">mdi-clipboard-list</v-icon>
          </v-btn>
        </template>
        <span>Specifiche Ordine</span>
      </v-tooltip>

      <!-- Bottone per confermare la spedizione -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="clickSpecificheSpedizione"
            small
            v-bind="attrs"
            v-if="
              cod_profilo === 'magazziniere' ||
              cod_profilo === 'capofficina' ||
              cod_profilo === 'manager' ||
              cod_profilo === 'Admin' ||
              cod_profilo === 'CNXBOX' ||
              cod_profilo === 'SVIL'
            "
            v-on="on"
          >
            <v-icon color="primary">mdi-cube-send</v-icon>
          </v-btn>
        </template>
        <span>Specifiche Spedizione</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Filtri (mostra campi) -->
      <v-tooltip bottom v-if="filtri_visible === false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="filtri_visible = true"
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter</v-icon></v-btn
          >
        </template>
        <span>Filtri</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" @click="ripristina" small v-bind="attrs" v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter-off</v-icon></v-btn
          >
        </template>
        <span>Ripristina</span>
      </v-tooltip>

      <!-- Filtro pezzi prodotti -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="filtraProdotti"
            :color="filtra_prenotazione === true ? 'purple lighten-2' : ''"
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon
              :color="
                filtra_prenotazione === true ? 'white' : 'purple lighten-2'
              "
              >mdi-package-up</v-icon
            ></v-btn
          >
        </template>
        <span>Filtra Pezzi Prodotti</span>
      </v-tooltip>

      <!-- Filtro delle commesse in stato ordinato -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="filtraOrdinati"
            :color="filtra_ordinati === true ? 'purple lighten-2' : ''"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              :color="filtra_ordinati === true ? 'white' : 'purple lighten-2'"
            >
              mdi-cart
            </v-icon>
          </v-btn>
        </template>
        <span>Filtra Pezzi Ordinati</span>
      </v-tooltip>

      <!-- Filtro delle commesse spedite -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            :color="filtra_spedizione === true ? 'purple lighten-2' : ''"
            @click="filtraSpedizione"
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon
              :color="filtra_spedizione === true ? 'white' : 'purple lighten-2'"
              >mdi-truck-delivery</v-icon
            ></v-btn
          >
        </template>
        <span>Filtra Pezzi Spediti</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goBack" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-2 pa-0"></v-card-text>
    <template>
      <div>
        <v-data-table
          :headers="headers"
          :items="elementi"
          item-key="nome"
          class="elevation-0"
          height="75vh"
          fixed-header
        >
          <template v-slot:top>
            <v-row align="center" justify="center" v-if="filtri_visible">
              <v-col cols="3">
                <v-text-field
                  @input="filtra"
                  v-model="matricola_search"
                  label="Barca"
                >
                  <template slot="append">
                    <v-icon
                      @click="
                        matricola_search = '';
                        filtra();
                      "
                      color="primary"
                    >
                      mdi-filter-off
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  @change="filtra"
                  :items="elementi_barca"
                  :item-text="(e) => e.cod_articolo1"
                  item-value="cod_articolo1"
                  label="Gruppo"
                  v-model="gruppo_search"
                >
                  <template slot="append">
                    <v-icon
                      @click="
                        gruppo_search = '';
                        filtra();
                      "
                      color="primary"
                    >
                      mdi-filter-off
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  @change="filtra"
                  :items="elementi_barca"
                  :item-text="(e) => e.cod_articolo2"
                  item-value="cod_articolo2"
                  label="Cod. Articolo"
                  v-model="cod_articolo_search"
                >
                  <template slot="append">
                    <v-icon
                      @click="
                        cod_articolo_search = '';
                        filtra();
                      "
                      color="primary"
                    >
                      mdi-filter-off
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>

          <!-- Colonna dell'icona dello stato del work order -->
          <template v-slot:[`item.des`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="
                    item.des == 'Aperta' && item.data_produzione === null
                      ? 'error'
                      : item.des == 'Aperta' && item.data_produzione != null
                      ? 'cyan darken-3'
                      : item.des == 'Chiusa' && item.data_preparazione === null
                      ? 'primary'
                      : item.des == 'Chiusa' &&
                        item.data_preparazione != null &&
                        item.data_spedizione === null
                      ? 'indigo'
                      : 'teal darken-4'
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    item.des == "Aperta" && item.data_produzione === null
                      ? "mdi-calendar-clock"
                      : item.des == "Aperta" && item.data_produzione != null
                      ? "mdi-factory"
                      : item.des == "Chiusa" && item.data_preparazione === null
                      ? "mdi-package-up"
                      : item.des == "Chiusa" &&
                        item.data_preparazione != null &&
                        item.data_spedizione === null
                      ? "mdi-cart"
                      : "mdi-truck-delivery"
                  }}
                </v-icon>
              </template>
              <span>
                {{
                  item.des == "Aperta" && item.data_produzione === null
                    ? "Programmato"
                    : item.des == "Aperta" && item.data_produzione != null
                    ? "In Produzione"
                    : item.des == "Chiusa" && item.data_preparazione === null
                    ? "Completato"
                    : item.des == "Chiusa" &&
                      item.data_preparazione != null &&
                      item.data_spedizione === null
                    ? "Ordinato"
                    : "Spedito"
                }}
              </span>
            </v-tooltip>
          </template>

          <!-- checkbox selezione multipla di elementi -->
          <template v-slot:[`item.selezionato`]="{ item }">
            <!-- checkbox di selezione per ordinare qualcosa -->
            <span>
              <v-simple-checkbox
                color="success"
                v-if="item.des === 'Chiusa' && item.data_preparazione === null"
                v-model="item.selezionato"
              ></v-simple-checkbox>
            </span>

            <!-- Checkbox selezione multipla per spedire elementi -->
            <span>
              <v-simple-checkbox
                color="success"
                v-if="item.des === 'Chiusa' && item.data_preparazione != null"
                v-model="item.selezionato"
              ></v-simple-checkbox>
            </span>
          </template>

          <!-- Colonna del gruppo -->
          <template v-slot:[`item.des_articolo1`]="{ item }">
            {{ `${item.cod_articolo1} - ${item.des_articolo1}` }}
          </template>

          <!-- Colonna della des. componente -->
          <template v-slot:[`item.des_articolo2`]="{ item }">
            {{ `${item.cod_articolo2} - ${item.des_articolo2}` }}
          </template>

          <!-- Colonna della data produzione -->
          <template v-slot:[`item.data_produzione`]="{ item }">
            {{
              item.data_produzione && item.data_produzione.length > 0
                ? `${item.data_produzione.substring(
                    8,
                    10
                  )}/${item.data_produzione.substring(
                    5,
                    7
                  )}/${item.data_produzione.substring(0, 4)}`
                : "N/A"
            }}
          </template>

          <!-- Colonna della data ordine -->
          <template v-slot:[`item.data_preparazione`]="{ item }">
            {{
              item.data_preparazione && item.data_preparazione.length > 0
                ? `${item.data_preparazione.substring(
                    8,
                    10
                  )}/${item.data_preparazione.substring(
                    5,
                    7
                  )}/${item.data_preparazione.substring(0, 4)}`
                : "N/A"
            }}
          </template>

          <!-- Colonna della data spedizione -->
          <template v-slot:[`item.data_spedizione`]="{ item }">
            {{
              item.data_spedizione && item.data_spedizione.length > 0
                ? `${item.data_spedizione.substring(
                    8,
                    10
                  )}/${item.data_spedizione.substring(
                    5,
                    7
                  )}/${item.data_spedizione.substring(0, 4)}`
                : "N/A"
            }}
          </template>
        </v-data-table>
      </div>
    </template>

    <!-- Dialog per definire gli ordini -->
    <v-dialog v-model="specifica_ordine">
      <ListaOrdini
        @click_chiudi="chiudiDialogOrdini"
        :item_selezionati="
          elementi.filter(
            (e) => e.selezionato === true && e.data_preparazione === null
          )
        "
        @salva_ordine="salvaOrdine"
        :tipo="'ordine'"
        :titolo="'Riepilogo Ordine'"
      />
    </v-dialog>

    <!-- Dialog per definire i pezzi che sono spediti -->
    <v-dialog v-model="specifica_spedizione">
      <ListaOrdini
        @click_chiudi="chiudiDialogSpedizione"
        :item_selezionati="
          elementi.filter(
            (e) => e.selezionato === true && e.data_preparazione != null
          )
        "
        @salva_spedizione="salvaSpedizione"
        :tipo="'spedizione'"
        :titolo="'Riepilogo Spedizione'"
      />
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>

<script>
import ListaOrdini from "@/components/ELECTRICALMARINE/ListaOrdini.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ListaPicking",
  components: { ListaOrdini, SnackbarQV },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo_search: "",
      cod_profilo: null,
      filtri_visible: false,
      gruppo_search: "",
      item_sel: null,
      matricola_search: "",

      // Modal bool
      filtra_ordinati: false,
      filtra_prenotazione: false,
      filtra_spedizione: false,
      specifica_ordine: false,
      specifica_spedizione: false,

      // Array
      item_selezionati: [],
      elementi: [],
      elementi_barca: [],
      headers: [],
      headers_estesi_tmp: [
        {
          text: "Stato",
          value: "des",
          sortable: false,
        },
        {
          text: "Seleziona",
          value: "selezionato",
          sortable: false,
        },
        {
          text: "Matricola",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. Barca",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Gruppo",
          value: "des_articolo1",
          sortable: false,
        },
        {
          text: "Des. Componente",
          value: "des_articolo2",
          sortable: false,
        },
        {
          text: "Data Produzione",
          value: "data_produzione",
          sortable: false,
        },
        {
          text: "Data Prenotazione",
          value: "data_preparazione",
          sortable: false,
        },
      ],
      headers_ristretti_tmp: [
        {
          text: "Stato",
          value: "des",
          sortable: false,
        },
        {
          text: "Matricola",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. Barca",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Gruppo",
          value: "des_articolo1",
          sortable: false,
        },
        {
          text: "Des. Componente",
          value: "des_articolo2",
          sortable: false,
        },
        {
          text: "Data Produzione",
          value: "data_produzione",
          sortable: false,
        },
        {
          text: "Data Prenotazione",
          value: "data_preparazione",
          sortable: false,
        },
        {
          text: "Data Spedizione",
          value: "data_spedizione",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudiDialogOrdini() {
      this.specifica_ordine = false;
    },
    chiudiDialogSpedizione() {
      this.specifica_spedizione = false;
    },
    goBack() {
      this.$router.push("/home/electrical");
    },
    clickSpecificheOrdine() {
      if (
        this.elementi.filter(
          (e) => e.selezionato === true && e.data_preparazione === null
        ).length === 0
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          `Selezionare almeno una commessa da ordinare.`
        );
        return;
      }

      this.specifica_ordine = true;
    },
    clickSpecificheSpedizione() {
      if (
        this.elementi.filter(
          (e) => e.selezionato === true && e.data_preparazione != null
        ).length === 0
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          `Selezionare almeno una commessa da spedire.`
        );
        return;
      }

      this.specifica_spedizione = true;
    },
    filtra() {
      /* Preparo un array temporaneo in cui
       * collezionare gli elementi filtrati */
      let elementi_tmp = [];
      this.elementi_barca.forEach((e) => {
        elementi_tmp.push(Object.assign({}, e));
      });

      // Filtro per matricola della barca
      if (this.matricola_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.cod_articolo.includes(this.matricola_search)) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per gruppo
      if (this.gruppo_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.cod_articolo2 === this.gruppo_search) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per cod_articolo del componente
      if (this.cod_articolo_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.cod_articolo2 == this.cod_articolo_search) {
            res.push(Object.assign({}, elem));
          }
        });

        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per data prenotazione
      if (this.filtra_prenotazione === true) {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.des == "Chiusa" && elem.data_preparazione === null) {
            res.push(Object.assign({}, elem));
          }
        });

        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per includere solo elementi ordinati
      if (this.filtra_ordinati === true) {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (
            elem.des == "Chiusa" &&
            elem.data_preparazione != null &&
            elem.data_spedizione === null
          ) {
            res.push(Object.assign({}, elem));
          }
        });

        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per includere solo i row in stato spedito (che è il 4).
      if (this.filtra_spedizione === true) {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.des === "Chiusa" && elem.data_spedizione != null) {
            res.push(Object.assign({}, elem));
          }
        });

        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Ricostruisco gli elementi visualizzati in base agli elementi filtrati.
      this.elementi = Array.from(elementi_tmp);
    },
    filtraOrdinati() {
      this.filtra_ordinati = !this.filtra_ordinati;
      this.filtra_prenotazione = false;
      this.filtra_spedizione = false;
      this.filtra();

      /* Aggiorno gli headers, che potrebbero essere
       * estesi o ristretti a seconda del caso. */
      if (this.filtra_ordinati === true) {
        this.headers = Array.from(this.headers_estesi_tmp);
      } else {
        this.headers = Array.from(this.headers_ristretti_tmp);
      }
    },
    filtraProdotti() {
      this.filtra_ordinati = false;
      this.filtra_prenotazione = !this.filtra_prenotazione;
      this.filtra_spedizione = false;
      this.filtra();

      /* Aggiorno gli headers, che potrebbero essere
       * estesi o ristretti a seconda del caso. */
      if (this.filtra_prenotazione === true) {
        this.headers = Array.from(this.headers_estesi_tmp);
      } else {
        this.headers = Array.from(this.headers_ristretti_tmp);
      }
    },
    filtraSpedizione() {
      this.filtra_ordinati = false;
      this.filtra_prenotazione = false;
      this.filtra_spedizione = !this.filtra_spedizione;
      this.filtra();

      /* Aggiorno gli headers, che ora devono essere
       * per forza ristretti. */
      this.headers = Array.from(this.headers_ristretti_tmp);
    },
    getPickingList() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getpickinglist",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.elementi_barca = [];
          this.elementi = [];
          res.data.result.PickingList.forEach((e) => {
            this.elementi_barca.push(Object.assign({}, e));
            this.elementi.push(Object.assign({}, e));
          });
        })
        .catch((err) => {
          this.overlay = false;
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    ripristina() {
      // Rendo invisibili i filtri e resetto le chiavi di filtro
      this.cod_articolo_search = "";
      this.gruppo_search = "";
      this.matricola_search = "";
      this.filtri_visible = false;

      // Resetto gli elementi visualizzati in tabella
      this.elementi = [];
      this.elementi_barca.forEach((e) => {
        this.elementi.push(Object.assign({}, e));
      });

      this.filtra();
    },
    salvaOrdine() {
      // Chiudo il dialog
      this.specifica_ordine = false;

      // Aggiorno i dati della tabella
      this.getPickingList();

      // Stampo un messaggio di successo.
      this.$refs.SnackbarQV.print("success", `Ordine inviato con successo!`);
    },
    salvaSpedizione() {
      // Chiudo il dialog
      this.specifica_spedizione = false;

      // Aggiorno i dati della tabella
      this.getPickingList();

      // Stampo un messaggio di successo.
      this.$refs.SnackbarQV.print(
        "success",
        `Spedizione registrata con successo!`
      );
    },
  },
  created() {},
  mounted() {
    this.headers = Array.from(this.headers_ristretti_tmp);
    this.getPickingList();

    this.cod_profilo = localStorage.cod_profilo;
    // this.elementi = [];
    // this.elementi_barca.forEach((e) => {
    //   this.elementi.push(Object.assign({}, e));
    // });
  },
};
</script>
<style scoped>
</style> 