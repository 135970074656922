<template>
    <div style="margin:20px">
        <v-card>
            <v-toolbar dense color="primary" dark align="center">
                <v-row>
                    <v-col cols="5" align="left">
                    </v-col>
                    <v-col cols="1">
                        <v-btn small light @click="setUtenti">
                            <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>                        
                    </v-col>
                    <v-col cols="1">
                        <v-btn small light @click="mostraDialog(null)">
                            <v-icon color="primary">mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" align="right">

                    </v-col>
                    <v-col cols="1" align="right">
                        <v-btn small light @click="aggiorna">
                            <v-icon color="primary">mdi-autorenew</v-icon>
                        </v-btn>
                    </v-col>                    
                </v-row>
            </v-toolbar>
            <v-container>
                <v-data-table :headers="tabella.testata" :items="tabella.dati">
                    <!-- BOTTONE MODIFICA -->
                    <template v-slot:[`item.modifica_btn`]="{ item }">
                        <v-btn small @click="mostraDialog(item)" :disabled="item.validita!=1">
                            <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <!-- COLONNA ATTIVO -->
                    <template v-slot:[`item.attivo`]="{ item }">
                        <v-icon :color="(item.attivo==1)?'success':'error'">{{(item.attivo==1)?'mdi-check':'mdi-close'}}</v-icon>
                    </template>
                    <!-- COLONNA PROFILO UTENTE -->
                    <template v-slot:[`item.id_profilo_utente`]="{ item }">
                        <span>{{getCodiceProfilo(item.id_profilo_utente)}}</span>
                    </template>
                    <!-- COLONNA NOTE -->
                    <template v-slot:[`item.note`]="{ item }">
                        <span :title="item.note" style="display:block;max-width:150px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden">{{item.note}}</span>
                    </template>
                    <!-- BOTTONE VALIDITA -->
                    <template v-slot:[`item.rimuovi_btn`]="{ item }">
                        <v-btn small @click="toggleValidita(item)">
                            <v-icon :color="(item.validita==1)?'error':'primary'">{{(item.validita==1)?'mdi-close':'mdi-restore'}}</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
        <v-dialog v-model="dialog.mostra" persistent width="900">
            <v-card>
                <v-toolbar dense color="primary" dark>
                    <v-row align="center">
                        <v-col cols="5" align="left">
                            <v-toolbar-title>Modifica Utente</v-toolbar-title>
                        </v-col>
                        <v-col cols="2">
                            <v-btn small light @click="salvaDialog">
                                <v-icon color="success">mdi-floppy</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="5" align="right">
                            <v-btn small light @click="dialog.mostra=false">
                                <v-icon color="error">mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-container>
                    <v-form ref="form">
                        <v-row align="center"> 
                            <v-col cols="1">  
                                <v-icon>mdi-account</v-icon>
                            </v-col>                         
                            <v-col cols="3">
                                <v-text-field label="Nome" required :rules="regole.no_null_str" v-model="dialog.buffer.nome"/>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Cognome" required :rules="regole.no_null_str" v-model="dialog.buffer.cognome"/>
                            </v-col>
                            <v-col cols="2">
                                <v-checkbox label="Attivo" v-model="dialog.buffer.attivo"/>
                            </v-col>
                            <v-col cols="3">
                                <v-select label="Profilo" required :rules="regole.no_null_str" v-model="dialog.buffer.id_profilo_utente" :items="profili" item-text="des_profilo_utente" item-value="id_profilo_utente"/>
                            </v-col>                            
                        </v-row>
                        <v-divider />
                        <v-row align="center"> 
                            <v-col cols="1">  
                                <v-icon>mdi-text-account</v-icon>
                            </v-col>                         
                            <v-col cols="3">
                                <v-text-field required :rules="regole.no_null_str" label="Username" v-model="dialog.buffer.username"/>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-if="dialog.buffer.new_pass==true" required :rules="regole.no_null_str" label="Password" v-model="dialog.buffer.password"/>
                                <v-btn v-if="dialog.buffer.new_pass==false" @click="cambiaPassword">
                                    <v-icon color="amber darken-3">mdi-lock</v-icon>
                                    <span>Cambia Password</span>                                    
                                </v-btn>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Note" v-model="dialog.buffer.note"/>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row align="center">
                            <v-col cols="1">  
                                <v-icon>mdi-email</v-icon>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Email" v-model="dialog.buffer.email"/>
                            </v-col>
                        </v-row>
                    </v-form>                    
                </v-container>
            </v-card>
        </v-dialog>
        <v-snackbar style="position:fixed;bottom:10px;" dark :color="(alert.error==true)?'error':'light-green darken-2'" v-model="alert.show">
            {{alert.message}}
            <template v-slot:action="{attrs}">
                <v-btn v-bind="attrs" text @click="alert.show=false">Chiudi</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    name : 'GestioneUtenti',
    data(){
        return {
            alert : {
                show : false,
                message : null,
                error : false
            },
            dialog : {                
                buffer : {
                    nome : null,
                    cognome : null,
                    attivo : null,
                    id_profilo_utente : null,
                    id_azienda : null,
                    username : null,
                    password : null,
                    email : null,
                    note : null,
                    validita : 1,
                    new_pass : true,
                },
                indice : -1,
                mostra : false,
            },
            NETWORK : null,
            profili : [],
            regole : {
                no_null_str : [valore => ((valore!=null&&valore!=undefined)?(valore.toString().length>0):false)||'Campo obbligatorio'],
                no_null_num : [valore => ((valore!=null&&valore!=undefined&&!isNaN(valore))&&valore>0)||'Campo obbligatorio'],
            },
            tabella : {
                testata : [
                    {text:null,value:'modifica_btn',sortable:false,align:'left'},
                    {text:'Nome',value:'nome'},
                    {text:'Cognome',value:'cognome'},
                    {text:'Attivo',value:'attivo'},
                    {text:'Profilo Utente',value:'id_profilo_utente'},
                    {text:'Username',value:'username'},
                    {text:'Email',value:'email',sortable:false},
                    {text:'Note',value:'note',sortable:false},
                    {text:null,value:'rimuovi_btn',sortable:false,align:'right'},
                ],
                dati : []
            }
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.aggiorna();
    },
    methods : {
        aggiorna(){
            this.getUtenti();
            this.getProfili();
        },
        cambiaPassword(){
            this.dialog.buffer.new_pass = true;
            this.dialog.buffer.password = null;
        },
        getCodiceProfilo(id_profilo){
            for(let i=0;i<this.profili.length;i++){
                if(this.profili[i].id_profilo_utente==id_profilo){
                    return this.profili[i].cod_profilo_utente;
                }
            }
            return null;
        },
        getProfili(){
            let that = this;
            let richiesta = {
                controller : 'Modulo',
                method : 'POST',
                body : {
                    action : 'getProfili',
                    token : localStorage.user_token
                },
                on : {
                    load : function(response){
                        that.profili = [];
                        try{
                            that.profili = response.profili;                            
                        }catch(error){
                            console.log(error);
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(richiesta);
        },
        getUtenti(){
            let that = this;
            let richiesta = {
                controller : 'Modulo',
                method : 'POST',
                body : {
                    action : 'getUtenti',
                    token : localStorage.user_token
                },
                on : {
                    load : function(response){
                        that.tabella.dati = [];
                        try{
                            that.tabella.dati = response.utenti;
                            for(let i=0;i<that.tabella.dati.length;i++){
                                that.tabella.dati[i].new_pass = false;
                            }
                        }catch(error){
                            console.log(error);
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(richiesta);
        },
        mostraDialog(riga){
            this.dialog.mostra=true;
            let indice = this.tabella.dati.indexOf(riga);
            this.dialog.indice = indice;
            let campi = Object.keys(this.dialog.buffer);
            for(let i=0; i<campi.length;i++){
                this.dialog.buffer[campi[i]] = (indice<0)?null:riga[campi[i]];
                if(campi[i] =='attivo'&&indice>=0){
                    this.dialog.buffer[campi[i]] = (riga[campi[i]]==1);
                }else if(campi[i] =='new_pass'&&indice<0){
                    this.dialog.buffer[campi[i]] = true;
                }
            }
            let that = this;
            this.$nextTick(function(){
                that.$refs.form.resetValidation();
            });
        },
        salvaDialog(){
            if(!this.$refs.form.validate()){return;}
            if(this.dialog.indice<0){
                let clone = Object.assign({},this.dialog.buffer);
                clone.validita = 1;
                clone.attivo = (clone.attivo==1)?1:0;
                clone.id_personale = -1;
                clone.new_pass = true;
                this.tabella.dati.push(clone);
            }else{
                let campi = Object.keys(this.dialog.buffer);
                for(let i=0; i<campi.length;i++){
                    this.tabella.dati[this.dialog.indice][campi[i]] = this.dialog.buffer[campi[i]];
                    if(campi[i] == 'attivo'){
                        this.tabella.dati[this.dialog.indice][campi[i]] = (this.dialog.buffer[campi[i]]==true)?1:0;
                    }
                }
            }
            this.dialog.mostra=false;
        },
        setUtenti(){
            let that = this;
            let richiesta = {
                controller : 'Modulo',
                method : 'PUT',
                body : {
                    action : 'setUtenti',
                    token : localStorage.user_token,
                    utenti : this.tabella.dati
                },
                on : {
                    load : function(){
                        that.alert = {
                            message : 'Dati salvati con successo',
                            show : true,
                            error : false
                        }
                    },
                    error : function(){
                        that.alert = {
                            message : 'Errore durante il salvataggio',
                            show : true,
                            error : true
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(richiesta);
        },
        toggleValidita(riga){riga.validita = (riga.validita==0)?1:0;}
    }
}
</script>
