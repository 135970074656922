<template>
  <div style="padding: 20px">
    <v-card>
      <v-toolbar
        class="indigo white--text"
        dense
        primary-title
      >
        <v-toolbar-title>Magazzino</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="$router.push({ name: 'edit-magazzino', params: { id: -1 } })"
            >
              <v-icon color="#1a80b6">
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Crea Magazzino</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              class="ml-10"
              @click="toggleFilters"
            >
              <v-icon color="purple">
                mdi-filter
              </v-icon>
            </v-btn>
          </template>
          <span>Filtri</span>
        </v-tooltip>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
        id="progress"
      >
      </v-progress-linear>

      <v-card-text>
        <v-row v-if="show_filters">
          <v-col>
            <v-select
              label="Tipologia"
              dense
              outlined
              hide-details
              v-model="tipologia"
              :items="tipologieFiltri"
              item-text="des"
              item-value="id_tabella"
            >
            </v-select>
          </v-col>

          <v-col>
            <v-select
              label="Cod. Magazzino"
              dense
              outlined
              hide-details
              v-model="codice_magazzino"
              :items="codiciMagazzino"
              item-text="cod_magazzino"
              item-value="cod_magazzino"
            >
            </v-select>
          </v-col>

          <v-col>
            <v-select
              label="Descrizione"
              dense
              outlined
              hide-details
              v-model="descrizione"
              :items="descrizioni"
              item-text="des_magazzino"
              item-value="des_magazzino"
            >
            </v-select>
          </v-col>

          <v-col>
            <v-select
              label="Sede"
              dense
              outlined
              hide-details
              v-model="sede"
              :items="sedi"
              item-text="sede_magazzino"
              item-value="sede_magazzino"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="lista_tabella.filter(e => e.validita === 1)"
            >
              <template v-slot:item.buttons="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      elevation="1"
                      class="mr-3"
                      @click="$router.push({ name: 'edit-magazzino', params: { id: item.id_magazzino } })"
                    >
                      <v-icon color="#1a80b6">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      elevation="1"
                      @click="$router.push({ name: 'edit-magazzino', params: { id: '-1', duplicateId: item.id_magazzino } })"
                    >
                      <v-icon color="#2196f3">
                        mdi-plus-box-multiple-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Duplica</span>
                </v-tooltip>
              </template>

              <template v-slot:item.tipo_magazzino="{ item }">
                {{ getTipologia(item.tipo_magazzino) }}
              </template>

              <template v-slot:item.delete="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      elevation="1"
                      @click="showDeleteMagazzino(item)"
                    >
                      <v-icon class="error--text">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogEliminaMagazzino"
      width="unset"
    >
      <v-card>
        <v-toolbar
          dense
          class="indigo darken-1 headline"
        >
          <v-toolbar-title class="white--text">
            Eliminazione
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di voler eliminare questo magazzino?
          </h3>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            @click="dialogEliminaMagazzino = false"
          >
            ANNULLA
          </v-btn>
          <v-btn
            color="#1a80b6"
            dark
            @click="deleteMagazzino"
          >
            CONFERMA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>

<script>
import SnackbarQV from '@/components/TOOLS/SnackbarQV.vue';

export default {
  name: 'ListMagazzino',
  components: {
    SnackbarQV,
  },
  data() {
    return {
      show_filters: false,
      loading: false,
      tipologia: null,
      codice_magazzino: null,
      descrizione: null,
      sede: null,
      dialogEliminaMagazzino: false,
      currentMagazzino: false,
      headers: [
        {
          text: '',
          value: 'buttons',
          sortable: false,
        },
        {
          text: 'Tipologia',
          value: 'tipo_magazzino',
        },
        {
          text: 'Cod. Magazzino',
          value: 'cod_magazzino',
        },
        {
          text: 'Descrizione',
          value: 'des_magazzino',
        },
        {
          text: 'Sede',
          value: 'sede_magazzino',
        },
        {
          text: '',
          value: 'delete',
          sortable: false,
        },
      ],
      lista_tabella: [],
      magazzini: [],
    }
  },
  watch: {
    tipologia: {
      immediate: true,
      handler() {
        this.filtro();
      },
    },
    codice_magazzino: {
      immediate: true,
      handler() {
        this.filtro();
      },
    },
    descrizione: {
      immediate: true,
      handler() {
        this.filtro();
      },
    },
    sede: {
      immediate: true,
      handler() {
        this.filtro();
      },
    },
  },
  computed: {
    tipologieFiltri() {
      let tip = [];
      tip.push({ id_tabella: -1, des: "Tutti" });
      this.tipologie.filter(e => e.validita === 1).map((item) => tip.push(item));

      return tip;
    },
    codiciMagazzino() {
      let cod = [];
      cod.push({ cod_magazzino: "Tutti" });
      this.magazzini.filter(e => e.validita === 1).map((item) => cod.push(item));

      return cod;
    },
    descrizioni() {
      let des = [];
      des.push({ des_magazzino: "Tutti" });
      this.magazzini.filter(e => e.validita === 1).map((item) => des.push(item));

      return des;
    },
    sedi() {
      let sed = [];
      sed.push({ sede_magazzino: "Tutti" });
      this.magazzini.filter(e => e.validita === 1).map((item) => sed.push(item));

      return sed;
    },
  },
  mounted() {
    this.getTabella();
    this.callList();
  },
  methods: {
    callList() {
      this.loading = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getmagazzini",
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.magazzini = res.data;
        this.lista_tabella = this.magazzini;

        this.loading = false;
      });
    },
    getTabella() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "TIPO_MAGAZZINO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.tipologie = res.data != undefined
                    ? res.data
                    : [];
      });
    },
    getTipologia(id_tabella) {
      if (!this.tipologie.length) {
        return '';
      }

      const tipo = this.tipologie.find((el) => el.id_tabella == id_tabella);
      return (tipo) ? tipo.des : '';
    },
    showDeleteMagazzino(item) {
      this.currentMagazzino = item;
      this.dialogEliminaMagazzino = true;
    },
    deleteMagazzino() {
      this.dialogEliminaMagazzino = false;

      this.currentMagazzino.validita = 0;

      var itemRequest = {
        magazzino: [
          this.currentMagazzino,
        ],
      };

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setmagazzinoubicazione",
          token: localStorage.user_token,
          ...itemRequest,
        },
      };

      this.$store.dispatch("api", request).then(async (res) => {
        if (res.status != 200) {
          this.currentMagazzino.validita = 1;

          this.$refs.SnackbarQV.print(
            "orange",
            'Si è verificato un errore e non è stato possibile eliminare questo magazzino. Riprova più tardi.'
          );
        }
      });
    },
    filtro() {
      this.lista_tabella = this.magazzini;

      if (this.tipologia != null && this.tipologia != -1) {
        this.lista_tabella = this.lista_tabella.filter((el) => {
          return el.tipo_magazzino == this.tipologia;
        });
      }

      if (this.codice_magazzino != null && this.codice_magazzino != "Tutti") {
        this.lista_tabella = this.lista_tabella.filter((el) => {
          return el.cod_magazzino == this.codice_magazzino;
        });
      }

      if (this.descrizione != null && this.descrizione != "Tutti") {
        this.lista_tabella = this.lista_tabella.filter((el) => {
          return el.des_magazzino == this.descrizione;
        });
      }

      if (this.sede != null && this.sede != "Tutti") {
        this.lista_tabella = this.lista_tabella.filter((el) => {
          return el.sede_magazzino == this.sede;
        });
      }
    },
    toggleFilters() {
      this.show_filters = !this.show_filters;
    },
  },
};
</script>