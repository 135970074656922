 <template>
  <div class="ma-2">
    <v-snackbar v-model="snackbar" :vertical="true">
      {{ snackbar_messaggio }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-card>
      <v-row>
        <v-col cols="12" md="12" lg="12" sm="12" class="mt-0 pt-0">
          <v-card>
            <v-toolbar color="blue-grey darken-3 mt-0 pt-0" dark>
              <v-btn class="px-0" color="error" @click="indietro">
                <v-icon>mdi-undo</v-icon>
              </v-btn>
              <v-toolbar-title class="ml-2">Richiesta</v-toolbar-title>
            </v-toolbar>

            <v-card-title>
              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    class="pt-0"
                    v-model="richiesteLista[0].cod_richiesta"
                    label="Codice:"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    class="pa-0"
                    v-model="richiesteLista[0].titolo_richiesta"
                    :counter="20"
                    label="Titolo Richiesta"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="1">
                  <v-select
                    class="pt-0"
                    v-model="richiesteLista[0].id_responsabile"
                    :items="responsabileLista"
                    label="Responsabile"
                    required
                    item-text="cognome"
                    item-value="id_personale"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  <v-select
                    class="pa-0"
                    v-model="richiesteLista[0].id_categoria"
                    :items="categoriaLista"
                    label="Categoria"
                    required
                    item-text="des"
                    item-value="id_tabella"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                    class="pa-0"
                    ref="modalDataA"
                    v-model="modalDataA"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="pa-0"
                        v-model="richiesteLista[0].data_richiesta"
                        label="Data Richiesta"
                        prepend-icon="event"
                        @blur="
                          richiesteLista[0].data = parseDate(
                            richiesteLista[0].data_richiesta
                          )
                        "
                        v-on="on"
                        :disabled="isRicorsiva"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="richiesteLista[0].data_richiesta"
                      no-title
                      @input="modalDataScadenza = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-spacer></v-spacer>

                <v-btn class="ma-2 px-0" @click="show = !show" color="primary">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-card-text>
                  <v-container class="pa-0 ma-0">
                    <v-row>
                      <v-col cols="4" class="mt-0 pt-0">
                        <v-row>
                          <v-col cols="12" md="5">
                            <v-menu
                              ref="modalDataScadenza"
                              v-model="modalDataScadenza"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="richiesteLista[0].data"
                                  label="Data Scadenza"
                                  prepend-icon="event"
                                  @blur="
                                    richiesteLista[0].data = parseDate(
                                      richiesteLista[0].data
                                    )
                                  "
                                  v-on="on"
                                  :disabled="isRicorsiva"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="richiesteLista[0].data"
                                no-title
                                @input="modalDataScadenza = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="2"></v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="richiesteLista[0].id_sede"
                              :items="sedeLista"
                              label="Sede"
                              item-text="des"
                              item-value="id_tabella"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="richiesteLista[0].id_struttura"
                              :items="strutturaLista"
                              label="Struttura"
                              item-text="des"
                              item-value="id_tabella"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="richiesteLista[0].id_area"
                              :items="areaLista"
                              label="Area"
                              item-text="des"
                              item-value="id_tabella"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="richiesteLista[0].id_zona"
                              :items="zonaLista"
                              label="Zona"
                              item-text="des"
                              item-value="id_tabella"
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="9" md="8">
                        <v-textarea
                          v-model="note"
                          name="input-7-4"
                          label="Note"
                          value
                          shaped
                          filled
                          rows="6"
                          class="mb-0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-btn
                      absolute
                      dark
                      bottom
                      right
                      color="success"
                      v-show="show"
                      @click="insertRichiesta"
                    >
                      <!-- @click="salvaProgetto" -->
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-container>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-list rounded class="ma-0 pa-0">
              <v-toolbar color="blue-grey darken-3" dark>
                <v-toolbar-title>Elenco Attività</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn icon @click="add_attivita">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>

              <v-list-item-group
                color="primary"
                no-action
                style="max-height: 450px; overflow-y: auto"
              >
                <v-list-item
                  v-for="(item, i) in attivita"
                  :key="i"
                  class="text-center mt-4"
                >
                  <v-list-item-content @click="selezionaAttivita(item)">
                    <v-list-item-title
                      v-text="item.attivita_svolgere"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="9">
            <v-skeleton-loader
              v-if="loader_attivo"
              class="mx-auto"
              type="card"
            ></v-skeleton-loader>
            <div v-else>
              <v-toolbar icon flat dark color="blue-grey darken-4">
                <v-toolbar-title>
                  {{ titolo_attivita }}
                  <v-chip class="ma-2" color="teal" text-color="white">
                    <v-avatar left>
                      <v-icon>mdi-checkbox-marked-circle</v-icon> </v-avatar
                    >Confermato
                  </v-chip>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="salvaAttivita">Salva</v-btn>
                </v-toolbar-items>

                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn dark icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group>
                      <v-list-item @click="dialogFirmaAttivita = true">
                        <v-list-item-icon>
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Firma</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="dialog_users = true">
                        <v-list-item-icon>
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Allega</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="openNoteInterne">
                        <v-list-item-icon>
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Note Interne</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="dialogAllegati = true">
                        <v-list-item-icon>
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Annulla Attività</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-toolbar flat dark color="blue-grey darken-3">
                <template>
                  <v-tabs
                    v-model="modalTab"
                    center
                    slider-color="light-green accent-3 "
                  >
                    <v-tab :href="`#dettaglio`">Dati</v-tab>
                    <v-tab :href="`#allegati`">Allegati</v-tab>
                    <v-tab :href="`#team`">TEAM</v-tab>
                    <v-tab :href="`#eventi`">EVENTI</v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>

              <v-dialog v-model="dialogFirmaAttivita" max-width="290">
                <v-card>
                  <v-card-title class="headline">Attenzione</v-card-title>
                  <v-card-text>Firmare l'attività?</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogFirmaAttivita = false"
                      >Cancella</v-btn
                    >
                    <v-btn
                      color="green darken-1"
                      text
                      @click="firmaAttivita(editAttivita)"
                      >Ok</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogAnnullaAttivita" max-width="290">
                <v-card>
                  <v-card-title class="headline">Attenzione</v-card-title>
                  <v-card-text>Annullare l'attività?</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogAnnullaAttivita = false"
                      >Cancella</v-btn
                    >
                    <v-btn
                      color="green darken-1"
                      text
                      @click="annullaAttivita(editAttivita)"
                      >Ok</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogNoteInterne" max-width="600">
                <v-card>
                  <v-card-title class="headline"></v-card-title>
                  <v-card-text>
                    <v-textarea
                      v-model="noteInterne"
                      outlined
                      name="input-7-2"
                      label="Note Interne"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialogNoteInterne = false"
                      >Cancella</v-btn
                    >
                    <v-btn
                      color="green darken-1"
                      text
                      @click="salvaNoteInterneAttivita()"
                      >Ok</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-tabs-items v-model="modalTab" class="no-transition">
                <v-tab-item
                  :value="`dettaglio`"
                  transition="false"
                  class="no-transition"
                >
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="attivita_selezionata.attivita_svolgere"
                        :rules="nameRules"
                        :counter="10"
                        label="Attività"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_responsabile"
                        :items="responsabileLista"
                        label="Responsabile"
                        required
                        item-text="cognome"
                        item-value="id_personale"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-switch
                        v-model="isRicorsiva"
                        class="ma-2"
                        label="Ricorsiva"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-menu
                        ref="modalDataScadenza"
                        v-model="modalDataScadenza"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="attivita_selezionata.data_scadenza"
                            label="Data Scadenza"
                            prepend-icon="event"
                            @blur="
                              attivita_selezionata.data_scadenza = parseDate(
                                attivita_selezionata.data_scadenza
                              )
                            "
                            v-on="on"
                            :disabled="isRicorsiva"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="attivita_selezionata.data_scadenza"
                          no-title
                          @input="modalDataScadenza = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" md="1">
                      <v-menu
                        ref="modalDataInizio"
                        v-model="modalDataInizio"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="attivita_selezionata.dataInizio"
                            label="Data Inizio"
                            prepend-icon="event"
                            @blur="date = parseDate(dateFormatted)"
                            v-on="on"
                            :disabled="!isRicorsiva"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="attivita_selezionata.dataInizio"
                          no-title
                          @input="modalDataInizio = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" md="1">
                      <v-text-field
                        v-model="attivita_selezionata.giorni"
                        :rules="nameRules"
                        label="Ogni"
                        :disabled="!isRicorsiva"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_categoria"
                        :items="categoriaLista"
                        label="Categoria"
                        required
                        item-text="des"
                        item-value="id_tabella"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_richiedente"
                        :items="richiedenteLista"
                        label="Richiesta da"
                        required
                        item-text="cognome"
                        item-value="id_personale"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_sede"
                        :items="sedeLista"
                        label="Sede"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_struttura"
                        :items="strutturaLista"
                        label="Struttura"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_area"
                        :items="areaLista"
                        label="Area"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="attivita_selezionata.id_zona"
                        :items="zonaLista"
                        label="Zona"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_probabilita"
                        :items="probabilitaLista"
                        label="Probabilità"
                        item-text="des"
                        item-value="id_tabella"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-select
                        v-model="attivita_selezionata.id_danno"
                        :items="dannoLista"
                        label="Danno"
                        item-text="des"
                        item-value="id_tabella"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="attivita_selezionata.Rischio"
                        label="Rischio"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1"></v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="attivita_selezionata.email"
                        label="Email Avviso"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-switch
                        v-model="isManutenzione"
                        class="ma-2"
                        label="Manutenzione"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        v-model="attivita_selezionata.id_oggetto_manutenzione"
                        :items="macchinaLista"
                        label="Danno"
                        item-text="des"
                        item-value="id_tabella"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="attivita_selezionata.descrizione"
                        outlined
                        name="input-7-2"
                        label="Descrizione"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items
                v-model="modalTab"
                transition="false"
                class="no-transition"
              >
                <v-tab-item :value="`allegati`" class="no-transition">
                  <v-card>
                    <Allegato
                      :tipo="tipo_allegato"
                      :id_rif="id_rif"
                      :key="id_rif"
                    ></Allegato>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items
                v-model="modalTab"
                transition="false"
                class="no-transition"
              >
                <v-tab-item :value="`attivita`" class="no-transition">
                  <v-card>
                    <v-list three-line>
                      <template v-for="(item, index) in eventi">
                        <v-subheader
                          v-if="item.header"
                          :key="item.header"
                          v-text="item.header"
                        ></v-subheader>

                        <v-divider
                          v-else-if="item.divider"
                          :key="index"
                          :inset="item.inset"
                        ></v-divider>

                        <v-list-item v-else :key="item.title">
                          <v-list-item-avatar>
                            <v-img :src="item.avatar"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              v-html="item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="item.subtitle"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <v-tabs-items v-model="modalTab" class="no-transition">
                <v-tab-item :value="`team`" class="no-transition">
                  <v-card class="mx-auto">
                    <v-btn
                      small
                      absolute
                      dark
                      fab
                      top
                      right
                      color="success"
                      @click="dialogAddTeam = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-card-title>Team:</v-card-title>
                    <v-row>
                      <v-col md="3"></v-col>
                      <v-col md="6">
                        <v-list nav dense>
                          <v-list-item-group v-model="item1" color="primary">
                            <v-list-item
                              v-for="(
                                item, i
                              ) in attivita_selezionata.calendario_attivita_team"
                              :key="i"
                              @click="getDettagli(item)"
                            >
                              <v-list-item-icon></v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item.nome"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-text="item.cognome"
                                ></v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-action>{{
                                item.des_attivita
                              }}</v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-dialog v-model="dialogAddTeam" width="50%">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Aggiungi membri al team</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="6">
                              <v-autocomplete
                                style=""
                                v-model="newTeamList"
                                :items="teamSelect"
                                item-text="nome"
                                class="mt-5"
                                return-object
                                dense
                                label="Membro del team"
                              ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-menu
                                ref="modalDataScadenzaTeam"
                                v-model="modalDataScadenzaTeam"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="newTeamList.data_scadenza_firma"
                                    label="Data Scadenza Firma"
                                    prepend-icon="event"
                                    @blur="date = parseDate(dateFormatted)"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="newTeamList.data_scadenza_firma"
                                  no-title
                                  @input="modalDataScadenzaTeam = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-textarea
                                v-model="newTeamList.descrizione"
                                outlined
                                name="input-7-2"
                                label="Descrizione attività utente"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialogAddTeam = false"
                          >Annulla</v-btn
                        >
                        <v-btn color="blue darken-1" text @click="addTeam"
                          >Salva</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-tab-item>
              </v-tabs-items>

              <v-tabs-items v-model="modalTab" class="no-transition">
                <v-tab-item :value="`eventi`" class="no-transition">
                  <v-card>
                    <v-list three-line>
                      <template v-for="(item, index) in eventi">
                        <v-subheader
                          v-if="item.header"
                          :key="item.header"
                          v-text="item.header"
                        ></v-subheader>

                        <v-divider
                          v-else-if="item.divider"
                          :key="index"
                          :inset="item.inset"
                        ></v-divider>

                        <v-list-item v-else :key="item.title">
                          <v-list-item-avatar>
                            <v-img :src="item.avatar"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              v-html="item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="item.subtitle"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.no-transition {
  transition: none;
}
</style>
<script>
import config from "../../../public/config";
import Allegato from "./Allegato";
import moment from "moment";
export default {
  name: "Attivita",
  data: () => ({
    config: config,
    show: false,
    isRicorsiva: false,
    isManutenzione: false,
    loading: true,
    tipo: "",
    modulo: "",
    tipoLogin: "",
    modalTab: "dettaglio",
    dataDa: new Date().toISOString().substr(0, 10),
    dataA: new Date().toISOString().substr(0, 10),
    dataInizio: new Date().toISOString().substr(0, 10),
    dataScadenza: new Date().toISOString().substr(0, 10),
    modalDataDa: false,
    modalDataA: false,
    modalDataScadenza: false,
    modalInsertRichiesta: false,
    modalDataInizio: false,
    modalDataScadenzaTeam: false,
    id_rif: 2,
    tipo_allegato: "ATTIVITA",
    dialog: false,
    attivita_sel: {},
    id_attivita: 0,
    attivita_selezionata: {},
    loader_attivo: true,
    headers: [
      { text: "", value: "num_richiesta", type: "number" },
      { text: "", value: "data_richiesta", type: "number" },
      {
        text: "Numero",
        align: "start",
        sortable: false,
        value: "des_categoria",
      },
      {
        text: "Attività",
        align: "start",
        sortable: false,
        value: "des_stato",
      },
      { text: "Categoria", value: "des_modulo" },
      { text: "Responsabile", value: "nominativo_responsabile" },
      { text: "Scadenza", value: "data_scadenza" },
      { text: "Ricorsiva", value: "ricorsiva" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    snackbar: false,
    snackbar_messaggio: "",

    dialogAddTeam: false,
    dialogAddEvent: false,
    dialogFirmaAttivita: false,
    dialogAnnullaAttivita: false,
    dialogNoteInterne: false,
    dialogViewAttivitaDettaglio: false,
    attivitaLista: [],
    richiesteLista: [],
    attivitaSelect: [],
    team: [
      {
        nominativo_partecipante: "Luca Squillaci",
        ruolo: "Software developer",
        des_attivita: "Sviluppo piattaforma social",
      },
      {
        nominativo_partecipante: "Luciano Calviello",
        ruolo: "Elettricista",
        des_attivita: "Ricablare server n° 2",
      },
      {
        nominativo_partecipante: "Matteo Mileva",
        ruolo: "Meccanico",
        des_attivita: "Aggiustare la fiat 500",
      },
    ],
    teamSelect: [],
    eventi: [],
    eventiSelect: [],
    responsabileLista: [],
    statoLista: [],
    categoriaLista: [],
    richiedenteLista: [],
    sedeLista: [],
    zonaLista: [],
    strutturaLista: [],
    areaLista: [],
    probabilitaLista: [],
    dannoLista: [],
    newTeamList: [],
    categoriaSearch: "",
    resposanbileSearch: "",
    statoSearch: "",
    macchinaLista: [],
    attivitaIndex: -1,
    editedIndex: -1,
    isSigned: false,
    statoColor: "green",
    newEvento: "",
    noteInterne: "",
    editedAttivita: {},
    attivita: [],
    editedRichiesta: {},
    stato_pagina: "",
    dates: null,
    note: "Note della richiesta",
    titolo_attivita: "Nuova Attivita",
  }),
  components: {
    Allegato,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDateFormattedDa() {
      return this.formatDate(this.dataDa);
    },
    computedDateFormattedA() {
      return this.formatDate(this.dataA);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.modulo = this.$route.params.id;
    this.categoriaSelectSearch = [];
    this.resposanbileSelectSearch = [];
    var tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 30);
    this.dataA = tomorrow.toISOString().substr(0, 10);

    //this.initialize();
  },
  mounted() {
    this.getInfoModulo();
    this.getAttivita();
    this.loadResponsabile();
    this.getRichiesta();
    this.loadCombo();
    this.eventi = [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Luca",
        subtitle:
          "<span class='text--primary'>CONCLUSO</span> &mdash; 17/05/2020 14:15 - Ho finito!!!",
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Luciano",
        subtitle:
          "<span class='text--primary'>IN LAVORAZIONE</span> &mdash; 16/05/2020 14:15 - Sto aspettando Luca per concludere l'attività",
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Luca",
        subtitle:
          "<span class='text--primary'>IN LAVORAZIONE</span> &mdash; 15/05/2020 14:15 - In attesa dei documenti",
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Luciano",
        subtitle:
          "<span class='text--primary'>IN LAVORAZIONE</span> &mdash; 15/05/2020 14:15 - Domani ti chiamo",
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Carla",
        subtitle:
          "<span class='text--primary'>IN LAVORAZIONE</span> &mdash; 14/05/2020 14:15 - Domani ti chiamo",
      },
    ];
  },
  methods: {
    getInfoModulo() {
      let requestStato = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tipo: "STATO_ATTIVITA",
          filter: "cod = 'A' AND validita = 1",
        },
      };

      this.$store
        .dispatch("api", requestStato)
        .then((res) => {
          if (res.data[0] != undefined) {
            console.log(res);
            this.stato_pagina = res.data[0].id_tabella;
          }
        })
        .catch((e) => {
          if (e.response != undefined && e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          //controllare password ed utente
          this.snackbar_messaggio = e;
          this.snackbar = true;
        });

      let requestModulo = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tipo: "ATTIVITA_MODULO",
          filter: "cod = 'M' AND validita = 1",
        },
      };

      this.$store
        .dispatch("api", requestModulo)
        .then((res) => {
          if (res.data[0] != undefined) {
            this.modulo = res.data[0].id_tabella;
          }
        })
        .catch((e) => {
          if (e.response != undefined && e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          //controllare password ed utente
          this.snackbar_messaggio = e;
          this.snackbar = true;
          console.log("aa", e);
        });
    },
    editAttivita(item) {
      this.modalTab = "dettaglio";
      this.newEvento = "";
      this.dialogViewAttivitaDettaglio = true;
      this.editedAttivita = Object.assign({}, item);
      this.attivitaIndex = this.attivitaLista.indexOf(item);
      this.attivitaSelect.push(item);
      this.statoColor = "orange";
      //this.loadAttivita();
      this.editedAttivita.desStato = "NUOVA";
      if (item.stato == 1) {
        this.statoColor = "blue";
        this.editedAttivita.desStato = "IN LAVORAZIONE";
      } else if (item.stato == 2) {
        this.statoColor = "green";
        this.editedAttivita.desStato = "CONCLUSA";
      } else if (item.stato == 3) {
        this.statoColor = "red";
        this.editedAttivita.desStato = "ANUNLLATA";
      }
      this.noteInterne = item.noteInterne;
    },

    newAttivita() {
      this.modalTab = "dettaglio";
      this.noteInterne = "";
      this.newEvento = "";
      this.dialogViewAttivitaDettaglio = true;
      this.editedAttivita = {};
      this.statoColor = "orange";
      this.editedAttivita.desStato = "NUOVA";
    },

    salvaAttivita() {
      if (
        this.attivita_selezionata.id_attivita == undefined ||
        this.attivita_selezionata.id_attivita == null
      )
        this.attivita_selezionata.id_attivita = -1;

      if (this.attivita_selezionata.calendario_attivita_team == undefined) {
        this.attivita_selezionata.calendario_attivita_team = [{}];
      }
      this.attivita_selezionata.calendario_attivita_log = [{}];
      this.attivita_selezionata.id_richiesta = this.$route.params.id;
      this.attivita_selezionata.id_modulo = this.modulo;
      this.attivita_selezionata.id_stato = this.stato_pagina;

      let request = {
        controller: "Attivita",
        method: "PUT",
        richiesta: {
          action: "setattivita",
          id_utente: localStorage.id,
          token: localStorage.user_token,
          tabella: this.attivita_selezionata,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.data == "errore") {
            return;
          }
          this.dialogViewAttivitaDettaglio = false;
          this.editedAttivita = {};
          this.alert = true;
          this.alert_text = "Attività salvata con successo !";
          console.log(this.attivita);
          this.attivita.push(this.attivita_selezionata);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          //controllare password ed utente
          this.snackbar_testo = "ERRORE! IMPOSSIBILE TARARE  !";
          this.snackbar = true;
          console.log(e);
        });
    },

    firmaAttivita() {},

    closeAttivita() {
      this.dialogViewAttivitaDettaglio = false;
      this.editedAttivita = {};
      this.eventiSelect = {};
      this.teamSelect = {};
    },

    concludiAttivita() {},

    addTeam() {
      var id_atti = -1;
      if (
        this.attivita_selezionata.id_attivita != null &&
        this.attivita_selezionata.id_attivita != undefined
      ) {
        id_atti = this.attivita_selezionata.id_attivita;
      }
      if (
        this.newTeamList.id_personale == null ||
        this.newTeamList.id_personale == undefined ||
        this.newTeamList.data_scadenza_firma == null ||
        this.newTeamList.data_scadenza_firma == undefined
      ) {
        this.snackbar_messaggio =
          "ERRORE! Compilare tutti i campi per aggiungere un membro al team !";
        this.snackbar = true;
        return;
      }
      var obj = {
        id_attivita: id_atti,
        id_richiesta: this.$route.params.id,
        id_partecipante: this.newTeamList.id_personale,
        data_scadenza_firma: this.newTeamList.data_scadenza_firma,
        nome: this.newTeamList.nome,
        descrizione: this.newTeamList.descrizione,
        cognome: this.newTeamList.cognome,
        id_team_calendario_attivita: -1,
      };
      if (this.attivita_selezionata["calendario_attivita_team"] == undefined) {
        this.attivita_selezionata["calendario_attivita_team"] = [];
        this.attivita_selezionata["calendario_attivita_team"].push(obj);
      } else {
        this.attivita_selezionata["calendario_attivita_team"].push(obj);
      }
      this.dialogAddTeam = false;
      console.log(this.attivita_selezionata);
    },

    addEvento() {
      console.log(this.newEvento);

      this.newEvento = "";
    },

    loadResponsabile() {
      let request = {
        controller: "Attivita",
        method: "POST",
        richiesta: {
          action: "getUtenti",
          token: localStorage.user_token,
          idUtente: localStorage.id,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          // JSON responses are automatically parsed.
          if (res.data == "") {
            this.responsabileLista = [];
            this.richiedenteLista = [];
          } else {
            this.teamSelect = res.data;
            this.responsabileLista = res.data;
            this.richiedenteLista = res.data;
          }
        })
        .catch((e) => {
          this.responsabileLista = [];
          this.richiedenteLista = [];

          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE I RESPONSABILI !";
          this.snackbar = true;
          console.log(e);
        });
    },

    loadCombo() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tipo:
            "'CATEGORIA_ATTIVITA_MEDICAL','AREA','STRUTTURA','STATO_ATTIVITA','SEDE','DANNO','PROBABILITA'",
          filter: "validita = 1",
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          
          if (res.data != "") {
            console.log("gettabelle lista ", res);

            this.statoLista = res.data.STATO_ATTIVITA;
            this.categoriaLista = res.data.CATEGORIA_ATTIVITA_MEDICAL;
            this.sedeLista = res.data.SEDE;
            this.strutturaLista = res.data.STRUTTURA;
            this.areaLista = res.data.AREA;
            this.probabilitaLista = res.data.PROBABILITA;
            this.dannoLista = res.data.DANNO;
          }
        })
        .catch((e) => {
          this.responsabileLista = [];
          this.richiedenteLista = [];

          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE I RESPONSABILI !";
          this.snackbar = true;
          console.log(e);
        });
    },

    openNoteInterne() {
      this.noteInterne = this.editedAttivita.note_interne;
      this.dialogNoteInterne = true;
    },

    salvaNoteInterneAttivita() {
      this.editedAttivita.note_interne = this.noteInterne;
      this.dialogNoteInterne = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },

    insertRichiesta() {
      console.log(this.editedRichiesta);
      var a = 1;
      if (a == 1) {
        return;
      }

      let request = {
        controller: "Attivita",
        method: "PUT",
        richiesta: {
          action: "setrichiesta",
          token: localStorage.user_token,
          modulo: this.modulo,
          tabella: this.editedRichiesta,
          idUtente: localStorage.id,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          console.log(res);
          this.editedRichiesta = {};
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar = true;
          console.log(e);
        });
    },
    getRichiesta() {
      let request = {
        controller: "Attivita",
        method: "POST",
        richiesta: {
          action: "getrichiesta",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          id_richiesta: this.$route.params.id,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.richiesteLista = res.data;
          if(res.data[0] != undefined){
            this.richiesteLista[0].data_richiesta = moment(
              this.richiesteLista[0].data_richiesta
            ).format("YYYY-MM-GG");
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar = true;
          console.log(e);
        });
    },
    indietro() {
      this.$router.replace("../richieste");
    },
    getAttivita() {
      let request = {
        controller: "Attivita",
        method: "POST",
        richiesta: {
          action: "getattivita",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          id_richiesta: this.$route.params.id,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          // JSON responses are automatically parsed.
          if(res.data != ''){
            this.attivita = res.data.calendario_attivita;
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }

          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar = true;
          console.log(e);
        });
    },
    selezionaAttivita(item) {
      this.titolo_attivita = "Modifica Attività";
      this.attivita_selezionata = item;
      this.id_rif = this.attivita_selezionata.id_attivita;
      this.modalTab = "dettaglio";
      this.loader_attivo = false;
    },
    add_attivita() {
      this.titolo_attivita = "Nuova Attività";
      this.attivita_selezionata = {};
      this.loader_attivo = false;
      this.modalTab = "dettaglio";
    },
  },
};
</script>

