<template>
  <v-container fluid style="padding: 20px">
    <v-row>
      <v-col md="12" class="py-0">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="lista"
                :search="search"
                fixed-header
                height="70vh"
              >
                <template v-slot:top>
                  <v-toolbar
                    class="mb-2 white--text rounded-t"
                    color="indigo"
                    dense
                  >
                    <v-toolbar-title class="white--text"
                      >Lista Competenze</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="setCompetenze"
                          small
                        >
                          <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>
                      </template>
                      <span>Salva</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="gotoDashboard"
                          small
                        >
                          <v-icon color="error">mdi-window-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Vai alla Dashboard</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>

                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      v-for="(col, columnIndex) in headers"
                      :key="columnIndex"
                    >
                      <div v-if="columnIndex > 1">
                        <v-icon
                          v-if="item[col.value] == 1"
                          @click="item[col.value] = 0"
                          >mdi-check-underline</v-icon
                        >
                        <v-icon
                          v-else
                          @click="item[col.value] = 1"
                          color="white"
                          >mdi-close</v-icon
                        >
                      </div>
                      <div
                        :style="{
                          textAlign: columnIndex === 0 ? 'center' : 'left',
                        }"
                        v-else
                      >
                        {{ item[col.value] }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
      <v-btn class="ml-5" fab outlined x-small>
        <v-icon>{{
          snackbarColor == "green" ? "mdi-check" : "mdi-alert"
        }}</v-icon>
      </v-btn>
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "CompetenzeList",
  data() {
    return {
      search: "",
      headers: [],
      lista: [],
      snackbar: false,
      snackbarText: "Competenze salvate correttamente",
      snackbarColor: "green",
    };
  },
  mounted() {
    this.getCompetenze();
  },
  computed: {
    widthMin() {
      return window.innerWidth < 960 ? true : false;
    },
  },
  methods: {
    gotoDashboard() {
      this.$router.push("/home");
    },
    setCompetenze() {
      var setcompetenze = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcompetenze",
          id_utente: localStorage.getItem("id"),
          db_name: "quickproduction",
          token: localStorage.user_token,
          tabella: this.lista,
        },
      };
      console.log(JSON.stringify(setcompetenze.richiesta));
      this.$store
        .dispatch("api", setcompetenze)
        .then((res) => {
          if (res.status == 200) {
            this.snackbar = true;
            this.getCompetenze();
          } else {
            this.snackbar = true;
            this.snackbarText = res.status + " " + res.statusText;
            this.snackbarColor = "red";
            this.getCompetenze();
          }
        })
        .catch((ex) => {
          this.snackbar = true;
          this.snackbarText = ex;
          this.snackbarColor = "red";
          this.getCompetenze();
        });
    },
    getCompetenze() {
      this.lista = this.$store.state.competenze;
      var getcompetenze = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcompetenze",
          token: localStorage.user_token,
          id_utente: localStorage.getItem("id"),
          db_name: "quickproduction",
        },
      };
      this.$store.dispatch("api", getcompetenze).then((res) => {
        this.lista = res.data.tabella_competenze;
        this.headers = res.data.header;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.v-data-table > .v-data-table__wrapper > table {
  min-height: 100vh;
  // max-height: 100vh;
}
.v-data-table__wrapper {
  max-height: 70vh !important;
  overflow-y: visible;
}
</style>