<template>
  <v-row justify="center">
    <v-col cols="12" class="py-0">
      <GestioneMacchinari />
    </v-col>
  </v-row>
</template>
<script>
import GestioneMacchinari from "@/components/MANUTENZIONE/GestioneMacchinari.vue";
export default {
  name: "ManutenzioneGestioneMacchinari",
  components: { GestioneMacchinari },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>