<template>
  <div>
    <v-data-table
      :headers="
        allow_cod_richiesta_custom === true
          ? headers_richieste
          : headers_richieste.filter((h) => h.value != `cod_richiesta_custom`)
      "
      :items="
        filtraRecursive == true
          ? items_richieste_visualizzate.filter(
              (f) => f.cod_richiesta != null && f.cod_richiesta.includes('RR')
            )
          : items_richieste_visualizzate.filter(
              (f) => f.cod_richiesta != null && !f.cod_richiesta.includes('RR')
            )
      "
      :single-expand="singleExpand"
      :expanded.sync="expanded_richieste"
      fixed-header
      height="70vh"
      item-key="id_richiesta"
      show-expand
      class="elevation-1 pt-0"
    >
      <!-- Titolo tabella -->
      <template v-slot:top>
        <!-- Toolbar di Myrepair -->
        <v-toolbar
          class="sfondoStandard"
          dense
          v-show="toolbar_style === 'educatt'"
        >
          <v-toolbar-title class="white--text">
            Gestione degli Interventi
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-switch
            dark
            label="Filtro Recursive"
            class="mt-5"
            v-model="filtraRecursive"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="filtro_visible = true"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-filter</v-icon></v-btn
              >
            </template>
            <span>Visualizza Filtri</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
              >
            </template>
            <span>Resetta Filtro</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="goBack" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>

        <!-- Toolbar sintetica (tipicamente per Macchine / manutenzione) -->
        <v-toolbar
          dense
          class="indigo"
          v-show="toolbar_style === 'quickproduction'"
        >
          <v-toolbar-title class="white--text">
            Scheda Manutenzione Straordinaria
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            classe_bottone="mr-5"
            colore_icona="primary"
            idForm="MACCHINA_SCHEDA"
            idOggetto="CREA_RICHIESTA_MACCHINA_SCHEDA"
            mdi_icona="mdi-plus"
            testo_tooltip="Nuova Richiesta"
            v-on:buttonClick="dialog_modifica_visible = true"
          >
          </Button>

          <Button
            classe_bottone="mr-5"
            colore_icona="primary"
            idForm="MACCHINA_SCHEDA"
            idOggetto="APRI_REGOLE_IN_MACCHINA_SCHEDA"
            mdi_icona="mdi-file-certificate"
            testo_tooltip="Regole"
            v-on:buttonClick="dialog_regole_visible = true"
          >
          </Button>

          <Button
            classe_bottone="mr-5"
            colore_icona="primary"
            idForm="MACCHINA_SCHEDA"
            idOggetto="APRI_ANAGRAFICHE_IN_MACCHINA_SCHEDA"
            mdi_icona="mdi-account-multiple"
            testo_tooltip="Anagrafiche"
            v-on:buttonClick="dialog_anagrafiche_visible = true"
          >
          </Button>

          <Button
            colore_icona="primary"
            idForm="MACCHINA_SCHEDA"
            idOggetto="APRI_GESTIONE_LUOGHI_IN_MACCHINA_SCHEDA"
            mdi_icona="mdi-home-edit"
            testo_tooltip="Gestione Luoghi d'Intervento"
            v-on:buttonClick="dialog_gestione_luoghi_visible = true"
          >
          </Button>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="filtro_visible = true"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-filter</v-icon></v-btn
              >
            </template>
            <span>Visualizza Filtri</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
              >
            </template>
            <span>Resetta Filtro</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="goBack"
                small
                v-bind="attrs"
                v-if="go_back_visible"
                v-on="on"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>

        <!-- Toolbar sintetica di Digital Marine -->
        <v-toolbar
          dense
          class="indigo"
          v-show="toolbar_style === 'digitalmarine'"
        >
          <v-toolbar-title class="white--text">
            Richieste e Interventi di Manutenzione
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- Bottone per aggiungere una richiesta -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="dialog_modifica_visible = true"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-plus</v-icon></v-btn
              >
            </template>
            <span>Nuova Richiesta</span>
          </v-tooltip>

          <!-- Bottone per filtrare per data schedulata di interventi -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="filtraDataSchedulata"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary"
                  >mdi-sort-calendar-ascending</v-icon
                ></v-btn
              >
            </template>
            <span>Filtra per Data Schedulata Interventi</span>
          </v-tooltip>

          <!-- Bottone per filtrare per attività non completate -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="filtraAttivitaInCorso"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-rotate-right</v-icon></v-btn
              >
            </template>
            <span>Filtra per Attività non Completate</span>
          </v-tooltip>

          <!-- Bottone per visualizzare tutte le richieste disponibili -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="getValidazioneRichiesteList"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-ballot</v-icon></v-btn
              >
            </template>
            <span>Carica Tutte le Richieste Disponibili</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="filtro_visible = true"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="primary">mdi-filter</v-icon></v-btn
              >
            </template>
            <span>Visualizza Filtri</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
              >
            </template>
            <span>Resetta Filtro</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="goBack"
                small
                v-bind="attrs"
                v-if="go_back_visible"
                v-on="on"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>

        <!-- corpo della card contenente la tabella-->
        <v-card-text class="pt-5">
          <v-row align="center" justify="center" v-if="filtro_visible">
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="cod_richiesta"
                item-value="cod_richiesta"
                :label="IsDgMarine ? 'N° Claim' : 'Cod. Richiesta'"
                style="display: inline-block; max-width: 350px"
                v-model="cod_richiesta_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      cod_richiesta_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      cod_richiesta_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="des_sede"
                item-value="des_sede"
                :label="config_sede.label.singolare"
                style="display: inline-block; max-width: 350px"
                v-model="des_sede_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      des_sede_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      des_sede_search == "" ? "mdi-filter" : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="des_stato"
                item-value="des_stato"
                :label="config_stato.label.singolare"
                style="display: inline-block; max-width: 350px"
                v-model="des_stato_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      des_stato_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      des_stato_search == "" ? "mdi-filter" : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="des_stato_2"
                item-value="des_stato_2"
                :label="config_stato_dettaglio.label.singolare"
                style="display: inline-block; max-width: 350px"
                v-model="des_stato_dettaglio_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      des_stato_dettaglio_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      des_stato_dettaglio_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="4" v-if="!IsDgMarine" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="des_richiedente"
                item-value="des_richiedente"
                label="Richiedente"
                style="display: inline-block; max-width: 350px"
                v-model="des_richiedente_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      des_richiedente_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      des_richiedente_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="des_urgenza"
                item-value="des_urgenza"
                label="Urgenza"
                style="display: inline-block; max-width: 350px"
                v-model="des_urgenza_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      des_urgenza_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      des_urgenza_search == "" ? "mdi-filter" : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="2">
              <v-menu
                v-model="calendario_data_richiesta"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    @change="filtra"
                    dense
                    hide-details
                    label="Data Richiesta"
                    style="display: inline-block; max-width: 350px"
                    v-model="data_richiesta_search"
                    readonly
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="data_richiesta_search = ''"
                        color="primary"
                        >{{
                          data_richiesta_search == ""
                            ? "mdi-filter"
                            : "mdi-filter-off"
                        }}</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  @change="filtra"
                  v-model="data_richiesta_search"
                  @input="calendario_data_richiesta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4" v-if="!IsDgMarine" md="2">
              <v-autocomplete
                background-color="#ffffff"
                @change="filtra"
                dense
                hide-details
                :items="items_richieste_visualizzate"
                item-text="des_struttura"
                item-value="des_struttura"
                :label="config_struttura.label.singolare"
                style="display: inline-block; max-width: 350px"
                v-model="des_struttura_search"
              >
                <template v-slot:append>
                  <v-icon
                    class="ml-2"
                    @click="
                      des_struttura_search = '';
                      filtra();
                    "
                    color="primary"
                    >{{
                      des_struttura_search == ""
                        ? "mdi-filter"
                        : "mdi-filter-off"
                    }}</v-icon
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </template>

      <!-- Bottone di modifica della richiesta -->
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickModificaRichiesta(item, false)"
              small
              v-bind="attrs"
              v-on="on"
              v-if="item.cod_stato != 'FINISH'"
            >
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              @click="clickModificaRichiesta(item, true)"
              small
              v-bind="attrs"
              v-on="on"
              v-else
            >
              <v-icon color="primary"> mdi-magnify </v-icon>
            </v-btn>
          </template>
          <span>Modifica Richiesta</span>
        </v-tooltip>
      </template>

      <!-- Colonna dello stato della richiesta -->
      <template v-slot:[`item.des_stato`]="{ item }">
        <v-row class="ma-0 pa-0">
          <v-col cols="1">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  outlined
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  v-show="
                    cod_profilo === 'SVIL' ||
                    cod_profilo === 'AMM' ||
                    cod_profilo === 'GOD'
                  "
                >
                  <v-icon color="primary">mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  v-for="(stato, i) in stati_richiesta_lista.filter(
                    (e) =>
                      e.cod == 'PENDING' ||
                      e.cod == 'FINISH' ||
                      e.cod == 'REJECT' ||
                      e.cod == 'ON' ||
                      e.cod == 'ACCEPT'
                  )"
                  :key="i"
                >
                  <v-list-item-title
                    @click="
                      setStatoRichiesta(
                        item.id_richiesta,
                        stato.id_tabella,
                        item.id_stato_2
                      )
                    "
                    >{{ stato.des }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="10">
            {{ item.des_stato }}
          </v-col>
        </v-row>
      </template>

      <!-- Colonna dello stato della richiesta -->
      <template v-slot:[`item.des_stato_2`]="{ item }">
        <v-row class="ma-0 pa-0">
          <v-col cols="2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  outlined
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  v-show="
                    cod_profilo === 'SVIL' ||
                    cod_profilo === 'AMM' ||
                    cod_profilo === 'GOD'
                  "
                >
                  <v-icon color="primary">mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  v-for="(stato, i) in stati_richiesta_2_lista.filter(
                    (e) =>
                      e.cod == 'APP_CANT' ||
                      e.cod == 'PZ' ||
                      e.cod == 'H_INTERN' ||
                      e.cod == 'EXTERN_QUOT' ||
                      e.cod == 'NO_INFO' ||
                      e.cod == 'RIC_SPEDITI'
                  )"
                  :key="i"
                >
                  <v-list-item-title
                    @click="
                      setStatoRichiesta(
                        item.id_richiesta,
                        item.id_stato,
                        stato.id_tabella
                      )
                    "
                    >{{ stato.des }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="10">
            {{ item.des_stato_2 }}
          </v-col>
        </v-row>
      </template>

      <!-- Icona della email -->
      <template v-slot:[`item.email`]="{ item }">
        <v-icon :color="item.email === 1 ? 'success' : 'error'">{{
          item.email === 1 ? "mdi-check" : "mdi-close"
        }}</v-icon>
      </template>

      <!-- Icona dello stato -->
      <!--<template v-slot:[`item.stato`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="
                item.stato === stato_rifiutato_id
                  ? 'error'
                  : item.stato === stato_sospeso_id
                  ? 'orange'
                  : 'success'
              "
              v-bind="attrs"
              v-on="on"
              >{{
                item.stato === stato_rifiutato_id
                  ? "mdi-thumb-down"
                  : item.stato === stato_sospeso_id
                  ? "mdi-thumbs-up-down"
                  : "mdi-thumb-up"
              }}</v-icon
            >
          </template>
          <span>{{
            item.stato === stato_rifiutato_id
              ? "Respinto"
              : item.stato === stato_sospeso_id
              ? "Spspeso"
              : "Approvato"
          }}</span>
        </v-tooltip>
      </template>-->

      <!-- Drill down degli interventi della richiesta -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="pa-0"
          style="background-color: white"
          :colspan="headers.length"
        >
          <v-data-table
            class="elevation-3"
            :headers="headers_interventi"
            hide-default-footer
            :items="item.interventi"
          >
            <!-- Titolo tabella -->
            <template v-slot:top>
              <v-toolbar class="sfondoStandard" dense>
                <v-toolbar-title class="white--text">
                  Interventi di Manutenzione
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom
                  ><template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      @click="clickAggiungiIntervento(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                      :disabled="
                        item.cod_stato == 'FINISH' ||
                        item.cod_stato == 'PENDING' ||
                        item.cod_stato == 'REJECT'
                          ? true
                          : false
                      "
                      ><v-icon color="primary">mdi-plus</v-icon></v-btn
                    ></template
                  ><span>Aggiungi Intervento</span></v-tooltip
                >
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <!-- Bottone dialog di modifica intervento -->
            <template v-slot:[`item.modifica`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickModificaIntervento(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                  >
                </template>
                <span>Mostra Dettagli</span>
              </v-tooltip>
            </template>

            <!-- Bottone dialog degli step -->
            <template v-slot:[`item.dettaglio_step`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickStepDialog(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary"> mdi-certificate </v-icon>
                  </v-btn>
                </template>
                <span>Mostra Dettagli</span>
              </v-tooltip>
            </template>

            <!-- Bottone del dialog dei consuntivi -->
            <template v-slot:[`item.consuntivi`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.preventivo == false && item.interno == false"
                    @click="clickConsuntiviDialog(item)"
                    small
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="primary">mdi-cash-multiple</v-icon></v-btn
                  >
                </template>
                <span>Mostra Dettagli</span>
              </v-tooltip>
            </template>

            <!-- Colonna delle attvità -->
            <template v-slot:[`item.attivita`]="{ item }">
              {{ item.attivita != undefined ? item.attivita.length : 0 }}
            </template>

            <!-- Testo del codice regola -->
            <template v-slot:[`item.regola`]="{ item }">
              {{ item.cod_regola }}
            </template>

            <!-- Colonna del costo stimato -->
            <template v-slot:[`item.costo_stimato`]="{ item }">
              {{
                item.costo_stimato
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }}
            </template>

            <!-- Colonna della data prevista -->
            <template v-slot:[`item.data_prevista`]="{ item }">
              {{
                item.data_prevista === null
                  ? ""
                  : `${item.data_schedulata.substring(
                      8,
                      10
                    )}/${item.data_schedulata.substring(
                      5,
                      7
                    )}/${item.data_schedulata.substring(0, 4)}`
              }}
            </template>

            <!-- Colonna della data schedulata -->
            <template v-slot:[`item.data_schedulata`]="{ item }">
              {{
                item.data_schedulata === null
                  ? ""
                  : `${item.data_schedulata.substring(
                      8,
                      10
                    )}/${item.data_schedulata.substring(
                      5,
                      7
                    )}/${item.data_schedulata.substring(0, 4)}`
              }}
            </template>
            <!-- Bottone dinamico dello stato -->
            <template v-slot:[`item.id_stato`]="{ item }">
              <div style="text-align: center">
                <v-btn color="primary" icon>
                  <v-icon
                    :color="
                      item.id_stato === stato_rifiutato_id
                        ? 'error'
                        : item.id_stato === stato_sospeso_id
                        ? 'orange'
                        : 'success'
                    "
                    >{{
                      item.id_stato === stato_rifiutato_id
                        ? "mdi-thumb-down"
                        : item.id_stato === stato_sospeso_id
                        ? "mdi-thumbs-up-down"
                        : "mdi-thumb-up"
                    }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <!-- Dialog degli step -->
    <v-dialog v-model="dialog_step_visible" width="70%">
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        style="z-index: 99"
        v-model="snackbar_step"
        :timeout="3000"
      >
        <v-icon :color="snackbar_icon_color" :size="64">{{
          snackbar_icon
        }}</v-icon>
        <h2
          :style="{ color: snackbar_text_color + '!important' }"
          class="ml-16"
        >
          {{ snackbar_text }}
        </h2>

        <v-btn
          @click="snackbar_step = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-card-text class="px-0 py-0">
          <v-data-table
            :headers="headers_steps"
            :items="steps_sel"
            item-key="id_step"
            :single-expand="singleExpand"
            :expanded.sync="expanded_steps"
            show-expand
            class="elevation-1"
          >
            <!-- Titolo tabella -->
            <template v-slot:top>
              <v-toolbar class="sfondoStandard" dense>
                <v-toolbar-title class="white--text"
                  >Step della Regola
                  {{ regola_sel.cod_regola }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="setStepIntervento"
                      small
                      v-bind="attrs"
                      v-if="
                        cod_profilo == 'GOD' ||
                        cod_profilo == 'BOSS' ||
                        cod_profilo == 'AMM'
                      "
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-floppy </v-icon>
                    </v-btn>
                  </template>
                  <span>Salva Step</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="click_chiudi_step"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Chiudi</span>
                </v-tooltip>
              </v-toolbar>
            </template>
            <!-- Colonna dello stato dello step -->
            <template v-slot:[`item.id_stato`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="
                      item.id_stato === stato_rifiutato_id
                        ? 'error'
                        : item.id_stato === stato_sospeso_id
                        ? 'orange'
                        : 'success'
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      item.id_stato === stato_rifiutato_id
                        ? "mdi-thumb-down"
                        : item.id_stato === stato_sospeso_id
                        ? "mdi-thumbs-up-down"
                        : "mdi-thumb-up"
                    }}
                  </v-icon>
                </template>
                <span>{{
                  item.id_stato === stato_rifiutato_id
                    ? "Respinto"
                    : item.id_stato === stato_sospeso_id
                    ? "Sospeso"
                    : "Approvato"
                }}</span>
              </v-tooltip>
            </template>

            <!-- Drill down dei dettagli dello step -->
            <template v-slot:expanded-item="{ headers, item }">
              <td
                class="pa-0"
                style="background-color: white"
                :colspan="headers.length"
              >
                <v-data-table
                  class="elevation-3"
                  :headers="headers_approvatori"
                  hide-default-footer
                  :items="item.approvatori"
                >
                  <!-- Titolo tabella -->
                  <template v-slot:top>
                    <v-toolbar class="sfondoStandard" dense>
                      <v-toolbar-title class="white--text">
                        Dettagli dello Step {{ item.cod_step }}
                      </v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <!-- Bottone dinamico dello stato dell'approvatore -->
                  <template v-slot:[`item.id_stato`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="cambiaStatoApprovatore(item)"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            :color="
                              item.id_stato === stato_rifiutato_id
                                ? 'error'
                                : item.id_stato === stato_sospeso_id
                                ? 'orange'
                                : 'success'
                            "
                          >
                            {{
                              item.id_stato === stato_rifiutato_id
                                ? "mdi-thumb-down"
                                : item.id_stato === stato_sospeso_id
                                ? "mdi-thumbs-up-down"
                                : "mdi-thumb-up"
                            }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        item.id_stato === stato_rifiutato_id
                          ? "Respinto"
                          : item.id_stato === stato_sospeso_id
                          ? "Sospeso"
                          : "Approvato"
                      }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modifica_intervento_visible">
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        style="z-index: 99"
        v-model="snackbar_richiesta"
        :timeout="3000"
      >
        <v-icon :color="snackbar_icon_color" :size="64">{{
          snackbar_icon
        }}</v-icon>
        <h2
          :style="{ color: snackbar_text_color + '!important' }"
          class="ml-16"
        >
          {{ snackbar_text }}
        </h2>

        <v-btn
          @click="snackbar_richiesta = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-toolbar
          color="indigo"
          dense
          style="position: sticky; top: 0px; z-index: 1"
        >
          <v-toolbar-title class="white--text">
            {{
              intervento_sel.id_intervento === -1
                ? "Nuovo Intervento"
                : "Modifica Intervento"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaModificaIntervento"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva Intervento</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickChiudiModificaIntervento"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-keyboard-backspace </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pb-5 px-5 py-0">
          <v-tabs class="mt-5" v-model="tab_intervento" align-with-title>
            <v-tabs-slider color="transparent"></v-tabs-slider>
            <v-tab
              active-class="py-0 my-0 indigo white--text"
              class="py-0 my-0"
              v-for="item in tabs_intervento"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab_intervento">
            <v-tab-item>
              <v-card elevation="2" outlined class="mx-0 py-4">
                <v-row class="mx-5" v-if="titolo_intervento_visible">
                  <v-col cols="6">
                    <v-text-field
                      label="Titolo Intervento"
                      v-model="intervento_sel.des_intervento"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      @change="getFornitoreIntervento(intervento_sel)"
                      :items="concessionari_lista"
                      item-text="des"
                      item-value="id_tabella"
                      label="Concessionari"
                      v-model="intervento_sel.id_concessionario"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      @change="associaIdTipoIntervento"
                      v-model="intervento_sel.id_tipo_intervento"
                      :items="ambito_manutentivo_lista"
                      label="Ambito Manutentivo"
                      :disabled="
                        intervento_sel.attivita.length > 0
                          ? true
                          : false ||
                            intervento_sel.cod_stato_richiesta == 'FINISH'
                      "
                      placeholder="Seleziona"
                      item-text="des"
                      item-value="id_tabella"
                      required
                    >
                      <template #label>
                        <span class="">Ambito Manutentivo</span>
                        <span
                          @click.stop
                          style="min-width: 500px; pointer-events: all"
                        >
                          <Modules
                            v-on:dialogModules="gettabelle"
                            tipo="MAN_AMBITO_MANUTENTIVO"
                            nome="Ambito Manutentivo"
                            setaction="settabella"
                            getaction="gettabella"
                            controller="Global"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mx-5">
                  <v-col cols="2">
                    <v-text-field
                      disabled
                      label="Cod. Intervento"
                      v-model="intervento_sel.cod_intervento"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      :disabled="intervento_sel.id_stato_operativo == null"
                      :items="stato_intervento_lista_disponibile"
                      label="Stato Intervento"
                      placeholder="Seleziona"
                      item-text="des"
                      item-value="id_tabella"
                      required
                      v-model="intervento_sel.id_stato_operativo"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      label="Referente"
                      v-model="intervento_sel.des_referente"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" id="listener_costo_stimato">
                    <InputSeparatore
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      :decimali_fissi="true"
                      :destinazione="'listener_costo_stimato'"
                      :label="'Costo Stimato (€)'"
                      id="input_costo_stimato"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      v-model="intervento_sel.id_tipo_manutenzione"
                      :items="tipo_manutenzione_lista"
                      label="Tipo Manutenzione"
                      placeholder="Seleziona"
                      item-text="des"
                      item-value="id_tabella"
                      required
                    >
                      <template #label>
                        <span class="">Tipo Manutenzione</span>
                        <span
                          @click.stop
                          style="min-width: 500px; pointer-events: all"
                        >
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mx-5">
                  <v-col cols="2">
                    <v-autocomplete
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      @change="associaIdUrgenza"
                      v-model="intervento_sel.id_urgenza"
                      :items="urgenza_lista"
                      label="Urgenza"
                      placeholder="Seleziona"
                      item-text="des"
                      item-value="id_tabella"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      @change="associaFornitore(fornitore_sel)"
                      class="mt-n1"
                      :items="fornitore_lista"
                      item-text="ragione_sociale"
                      item-value="id_fornitore"
                      label="Fornitori"
                      :menu-props="{ maxHeight: '400' }"
                      :readonly="
                        intervento_sel.attivita.length > 0
                          ? true
                          : false ||
                            intervento_sel.cod_stato_richiesta == 'FINISH'
                      "
                      v-model="fornitore_sel"
                    >
                      <template v-slot:append>
                        <v-btn
                          class="ma-0 pa-0"
                          @click="clickDettaglioFornitore"
                          icon
                        >
                          <v-icon color="primary"> mdi-account </v-icon>
                        </v-btn>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1" v-if="preventivo_visible === true">
                    <v-checkbox
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      v-model="intervento_sel[checkbox]"
                      :label="`${checkbox
                        .charAt(0)
                        .toUpperCase()}${checkbox.slice(1)}`"
                      color="primary"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      v-model="calendario_data_prevista_intervento"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="289"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="
                            intervento_sel.cod_stato_richiesta == 'FINISH'
                              ? true
                              : false
                          "
                          background-color="#ffffff"
                          hide-details
                          label="Data Prevista"
                          v-model="intervento_sel.data_prevista"
                          readonly
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        :disabled="
                          intervento_sel.cod_stato_richiesta == 'FINISH'
                            ? true
                            : false
                        "
                        v-model="intervento_sel.data_prevista"
                        :min="intervento_sel.data_richiesta"
                        @input="
                          (calendario_data_prevista_intervento = false),
                            checkData(intervento_sel)
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      v-model="calendario_data_schedulata_intervento"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="289"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="
                            intervento_sel.cod_stato_richiesta == 'FINISH'
                              ? true
                              : false
                          "
                          background-color="#ffffff"
                          hide-details
                          label="Data Schedulata"
                          v-model="intervento_sel.data_schedulata"
                          readonly
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        :disabled="
                          intervento_sel.cod_stato_richiesta == 'FINISH'
                            ? true
                            : false
                        "
                        v-model="intervento_sel.data_schedulata"
                        :min="intervento_sel.data_prevista"
                        @input="calendario_data_schedulata_intervento = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      ref="menu_ora_schedulata"
                      v-model="menu_ora_schedulata"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="intervento_sel.ora_schedulata"
                      :disabled="
                        intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="intervento_sel.ora_schedulata"
                          label="Ora Schedulata"
                          prepend-inner-icon="access_time"
                          :disabled="
                            intervento_sel.cod_stato_richiesta == 'FINISH'
                              ? true
                              : false
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="ampm"
                        v-if="menu_ora_schedulata"
                        v-model="intervento_sel.ora_schedulata"
                        full-width
                        @click:minute="
                          $refs.menu_ora_schedulata.save(
                            intervento_sel.ora_schedulata
                          )
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="mx-5">
                  <v-col class="pb-0" cols="12">
                    <v-text-field
                      :disabled="
                        this.intervento_sel.cod_stato_richiesta == 'FINISH'
                          ? true
                          : false
                      "
                      label="Annotazioni"
                      outlined
                      v-model="intervento_sel.note"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-5">
                  <v-col cols="6" md="2">
                    <v-text-field
                      disabled
                      v-model="des_sede_intervento_sel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      disabled
                      v-model="des_struttura_intervento_sel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      disabled
                      v-model="des_area_intervento_sel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      disabled
                      v-model="des_zona_intervento_sel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      disabled
                      v-model="des_stanza_intervento_sel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      disabled
                      v-model="des_macchina_interento_sel"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  class="mx-5"
                  v-if="intervento_sel.id_stato_operativo == id_stato_ordinato"
                >
                  <v-col cols="3">
                    <v-text-field
                      label="Numero d'Ordine"
                      v-model="intervento_sel.numero_ordine"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-5">
                  <v-col cols="12">
                    <DettaglioAttivita
                      @aggiorna_attivita="aggiornaAttivita"
                      :intervento="intervento_sel"
                      :fornitori="intervento_sel.fornitori"
                      ref="dettaglio_attivita"
                      :attivita="attivita_intervento"
                      :stato_approvato_id="stato_approvato_id"
                      :stato_rifiutato_id="stato_rifiutato_id"
                      :stato_sospeso_id="stato_sospeso_id"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <GestioneAllegato
                controller_allegati="Global"
                controller_caratteristiche_tabella="Manutenzione"
                :id_riferimento="intervento_sel.id_intervento"
                ref="componenteAllegati"
                @snackbarOpen="snackbarOpen"
                tipo_allegato="INTERVENTO"
                :read_only="
                  intervento_sel.cod_stato_richiesta == 'FINISH' ? true : false
                "
              />
            </v-tab-item>
            <v-tab-item>
              <GestioneAllegato
                controller_allegati="Global"
                controller_caratteristiche_tabella="Manutenzione"
                :id_riferimento="intervento_sel.id_richiesta"
                :read_only="true"
                @snackbarOpen="snackbarOpen"
                tipo_allegato="RICHIESTA"
              />
            </v-tab-item>

            <!-- Tab della Descrizione Richiesta -->
            <v-tab-item>
              <v-card elevation="3" outlined class="mx-4 mb-3 py-4">
                <v-container>
                  <v-row>
                    <v-textarea
                      class="mx-5"
                      :label="`${
                        this.allow_doppia_descrizione === true ? 'Problema' : ''
                      }`"
                      outlined
                      readonly
                      v-model="intervento_sel.descrizione"
                    ></v-textarea>
                  </v-row>
                  <!-- Textarea della Soluzione -->
                  <v-row>
                    <v-textarea
                      class="mx-5"
                      label="Soluzione"
                      outlined
                      readonly
                      v-model="intervento_sel.soluzione"
                    ></v-textarea>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>

            <!-- Tab della bacheca -->
            <v-tab-item v-if="bacheca_intervento_visible === true">
              <Bacheca
                :id="intervento_sel.id_intervento"
                :tipo="'intervento'"
                :visible="modifica_intervento_visible"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-snackbar
          absolute
          content-class="snackbar"
          :color="snackbar_background"
          right
          style="z-index: 200"
          v-model="snackbar_intervento"
          :timeout="3000"
        >
          <v-icon :color="snackbar_icon_color" :size="64">{{
            snackbar_icon
          }}</v-icon>
          <h2
            :style="{ color: snackbar_text_color + '!important' }"
            class="ml-16"
          >
            {{ snackbar_text }}
          </h2>

          <v-btn
            @click="snackbar_intervento = false"
            fab
            dark
            text
            small
            style="
              margin-top: -6rem;
              float: right !important;
              margin-right: -1rem !important;
            "
          >
            <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
          </v-btn>
        </v-snackbar>
      </v-card>
    </v-dialog>
    <v-dialog v-model="consuntivi_dialog_visible" persistent>
      <v-snackbar
        absolute
        content-class="snackbar"
        :color="snackbar_background"
        right
        style="z-index: 200"
        v-model="snackbar_consuntivo"
        :timeout="3000"
      >
        <v-icon :color="snackbar_icon_color" :size="64">{{
          snackbar_icon
        }}</v-icon>
        <h2
          :style="{ color: snackbar_text_color + '!important' }"
          class="ml-16"
        >
          {{ snackbar_text }}
        </h2>

        <v-btn
          @click="snackbar_intervento = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"> Consuntivi </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="salvaModificaConsuntivi"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva Consuntivi</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="
                  (id_profilo != 'OPJ' || id_profilo != 'OPS') &&
                  intervento_sel.des_stato_operativo != 'COMPLETATO' &&
                  intervento_sel.des_stato_operativo != 'REVISIONE'
                "
                @click="validaConsuntivo"
                small
                v-bind="attrs"
                v-if="
                  cod_profilo == 'OPJ' ||
                  cod_profilo == 'OPS' ||
                  cod_profilo == 'SVIL' ||
                  cod_profilo == 'GOD'
                "
                v-on="on"
              >
                <v-icon color="success"> mdi-file-certificate </v-icon>
              </v-btn>
            </template>
            <span>Valida</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickChiudiModificaConsuntivi"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-keyboard-backspace </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="px-5 py-5">
          <v-row class="mb-5">
            <v-col cols="3">
              <span style="font-size: 18px"
                >Massimale Totale (€):
                <i
                  ><b>{{ this.costo_massimale_totale }}</b></i
                >
              </span>
            </v-col>
            <v-col cols="4">
              <span style="font-size: 18px"
                >Consuntivo Totale (€):
                <i
                  ><b>{{ this.costo_consuntivo_totale }}</b></i
                >
              </span>
            </v-col>
            <v-col cols="4">
              <span v-if="delta_totale <= 0" style="color: red; font-size: 18px"
                >Delta (€):
                <i
                  ><b>{{ this.delta_totale }}</b></i
                >
              </span>
              <span v-else style="color: green; font-size: 18px"
                >Delta (€)
                <i
                  ><b>{{ this.delta_totale }}</b></i
                >
              </span>
            </v-col>
          </v-row>
          <v-tabs
            v-model="tab_consuntivi"
            align-with-title
            slider-color="transparent"
          >
            <v-tab
              active-class="py-0 my-0 indigo white--text"
              class="py-0 my-0"
              v-for="item in ['Personale', 'Materiale', 'Extra']"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab_consuntivi">
            <v-tab-item>
              <ConsuntiviPersonale
                :consuntivo_personale="consuntivo_sel_personale"
                :id_consuntivo="intervento_sel.consuntivo[0].id_consuntivo"
                :massimale="consuntivo_max_personale"
                :attivita="attivita"
                @salvaConsuntivo="salvaModificaConsuntivi"
                ref="ConsuntiviPersonale"
              />
            </v-tab-item>
            <v-tab-item>
              <ConsuntiviMateriale
                :consuntivo_materiale="consuntivo_sel_materiale"
                :id_consuntivo="intervento_sel.consuntivo[0].id_consuntivo"
                :massimale="consuntivo_max_materiale"
                ref="ConsuntiviMateriale"
              />
            </v-tab-item>
            <v-tab-item>
              <ConsuntiviExtra
                :consuntivo_extra="consuntivo_sel_extra"
                :id_consuntivo="intervento_sel.consuntivo[0].id_consuntivo"
                :massimale="consuntivo_max_extra"
                ref="ConsuntiviExtra"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_modifica_visible" width="70%">
      <Richieste
        :allow_cod_richiesta_custom="allow_cod_richiesta_custom"
        :allow_doppia_descrizione="allow_doppia_descrizione"
        :bacheca_visible="bacheca_richiesta_visible"
        @click_indietro="chiudiModificaRichiesta"
        :cod_filtro_sede="cod_filtro_sede"
        :config_sede="config_sede"
        :config_struttura="config_struttura"
        :config_area="config_area"
        :config_zona="config_zona"
        :config_stanza="config_stanza"
        :config_macchinario="config_macchinario"
        :config_modello="config_modello"
        :get_sedi_from_macchine="get_sedi_from_macchine"
        :richiesta_sel="richiesta_sel"
        :show_invio_email="show_invio_email"
        :stato_richiesta_visible="stato_richiesta_visible"
        :stato_richiesta_2_visible="stato_richiesta_2_visible"
        :tempo_stimato_visible="tempo_stimato_visible"
        :urgenza_richiesta_default_val="urgenza_richiesta_default_val"
        :visible="dialog_modifica_visible"
        :readOnly="richiesta_readOnly"
      />
    </v-dialog>

    <TabellaAnagrafiche
      :concessionari_visible="true"
      ref="TabellaAnagrafiche"
      v-show="false"
    >
    </TabellaAnagrafiche>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      style="z-index: 99"
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay absolute :value="caricamento_dati">
      <div class="my-2">
        <v-progress-circular
          color="indigo"
          :rotate="-90"
          :size="120"
          :value="avanzamento"
          :width="20"
        >
          <div
            style="
              background-color: #3f51b5;
              border-radius: 250px;
              width: 50px;
              height: 50px;
              line-height: 50px;
              text-align: center;
            "
          >
            <span style="color: white; display: inline-block; height: 50px"
              >{{ avanzamento }}%</span
            >
          </div>
        </v-progress-circular>
      </div>
      <div style="background-color: #3f51b5; border-radius: 5px">
        <span class="pa-2" style="color: white"
          >{{ caricati }} MB / {{ caricabili }} MB</span
        >
      </div>
    </v-overlay>
  </div>
</template>
<script>
import Bacheca from "@/components/MANUTENZIONE/Bacheca.vue";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import ConsuntiviExtra from "@/components/MANUTENZIONE/ConsuntiviExtra.vue";
import ConsuntiviMateriale from "@/components/MANUTENZIONE/ConsuntiviMateriale.vue";
import ConsuntiviPersonale from "@/components/MANUTENZIONE/ConsuntiviPersonale.vue";
import DettaglioAttivita from "@/components/MANUTENZIONE/DettaglioAttivita.vue";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import InputSeparatore from "@/components/TOOLS/InputSeparatore.vue";
import Intervention from "@/classes/Intervention.js";
import moment from "moment/moment";
import Registry from "@/classes/Registry.js";
import Request from "@/classes/Request.js";
import Richieste from "@/components/MANUTENZIONE/Richieste.vue";
import TabellaAnagrafiche from "@/components/MANUTENZIONE/TabellaAnagrafiche.vue";

export default {
  name: "TabellaRichieste",
  components: {
    Bacheca,
    Button,
    ConsuntiviPersonale,
    ConsuntiviMateriale,
    ConsuntiviExtra,
    DettaglioAttivita,
    GestioneAllegato,
    InputSeparatore,
    Modules: () => import("@/components/TOOLS/Modules"),
    Richieste,
    TabellaAnagrafiche,
  },
  props: {
    allow_cod_richiesta_custom: {
      type: Boolean,
      default: false,
    },
    allow_doppia_descrizione: {
      type: Boolean,
      default: false,
    },
    allow_update_ambiti_manutentivi_custom_dg_marine: {
      type: Boolean,
      default: false,
    },
    bacheca_intervento_visible: {
      type: Boolean,
      default: false,
    },
    bacheca_richiesta_visible: {
      type: Boolean,
      default: false,
    },
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    checkbox: {
      type: String,
      default: "preventivo",
    },
    checkbox_default_val: {
      type: Boolean,
      default: false,
    },
    concessionari_visible: {
      type: Boolean,
      default: false,
    },
    cod_filtro_sede: {
      type: String,
      default: "",
    },
    config_area: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Area", plurale: "Aree" },
          visibility: true,
        };
      },
    },
    config_macchinario: {
      type: Object,
      default: function () {
        return {
          label: {
            singolare: "Macchinario/Attrezzatura",
            plurale: "Marrchinari/Attrezzature",
          },
          visibility: true,
        };
      },
    },
    config_modello: {
      type: Object,
      default: function () {
        return {
          label: {
            singolare: "Modello",
            plurale: "Modelli",
          },
          visibility: false,
        };
      },
    },
    config_sede: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Sede", plurale: "Sedi" },
          visibility: true,
        };
      },
    },
    config_stanza: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Stanza", plurale: "Stanze" },
          visibility: true,
        };
      },
    },
    config_stato: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Stato", plurale: "Stati" },
          visibility: true,
        };
      },
    },
    config_stato_dettaglio: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Stato dettaglio", plurale: "Stati dettaglio" },
          visibility: true,
        };
      },
    },

    config_struttura: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Struttura", plurale: "Strutture" },
          visibility: true,
        };
      },
    },
    config_zona: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Zona", plurale: "Zone" },
          visibility: true,
        };
      },
    },
    dettaglio_step_visible: {
      type: Boolean,
      default: true,
    },
    get_sedi_from_macchine: {
      type: Boolean,
      default: false,
    },
    go_back_visible: {
      type: Boolean,
      default: false,
    },
    headers_interventi_scelti: {
      type: Array,
      default: () => [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Dettaglio Step",
          sortable: false,
          value: "dettaglio_step",
        },
        {
          text: "Consuntivi",
          sortable: false,
          value: "consuntivi",
        },
        {
          text: "N° Attività",
          sortable: false,
          value: "attivita",
        },
        {
          text: "Cod. Intervento",
          sortable: false,
          value: "cod_intervento",
        },
        {
          text: "Ambito Manutentivo",
          sortable: false,
          value: "des_tipo_intervento",
        },
        {
          text: "Referente",
          sortable: false,
          value: "des_referente",
        },
        {
          text: "Costo Stimato (€)",
          sortable: false,
          value: "costo_stimato",
        },
        {
          text: "Regola",
          sortable: false,
          value: "regola",
        },
        {
          text: "Stato Approvazione",
          sortable: false,
          value: "id_stato",
        },
        {
          text: "Tipo Manutenzione",
          value: "des_tipo_manutenzione",
          sortable: false,
        },
        {
          text: "Stato Operazioni",
          value: "des_stato_operativo",
          sortable: false,
        },
      ],
    },
    headers_richieste_scelti: {
      type: Array,
      default: () => [
        {
          text: "Modifica",
          value: "modifica",
          sortable: true,
        },
        {
          text: "Cod. Richiesta",
          value: "cod_richiesta",
          sortable: true,
        },
        {
          text: "Cod. Richiesta Privato",
          value: "cod_richiesta_custom",
          sortable: true,
        },
        {
          text: "Richiedente",
          value: "des_richiedente",
          sortable: true,
        },
        {
          text: "Data Richiesta",
          value: "data_richiesta",
          sortable: true,
        },
        {
          text: "Urgenza",
          value: "des_urgenza",
          sortable: false,
        },
        {
          text: "Sede",
          value: "des_sede",
          sortable: true,
        },
        {
          text: "Struttura",
          value: "des_struttura",
          sortable: true,
        },
        {
          text: "Area",
          value: "des_area",
          sortable: false,
        },
        {
          text: "Zona",
          value: "des_zona",
          sortable: false,
        },
        {
          text: "Stanza",
          value: "des_stanza",
          sortable: false,
        },
        {
          text: "Macchinario",
          value: "des_macchinario",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Stato",
          value: "des_stato",
          sortable: false,
          width: "150px",
        },
      ],
    },
    icona_gestione_sedi_fornitori: {
      type: String,
      default: "mdi-home-edit",
    },
    IsDgMarine: {
      type: Boolean,
      default: false,
    },
    preventivo_visible: {
      type: Boolean,
      default: true,
    },
    rotta_back: {
      type: String,
      default: "/home/manutenzione/main",
    },
    show_invio_email: { type: Boolean, default: true },
    toolbar_style: {
      type: String,
      default: "educatt",
    },
    stato_richiesta_visible: { type: Boolean, default: false },
    stato_richiesta_2_visible: { type: Boolean, default: false },
    tempo_stimato_visible: { type: Boolean, default: false },
    titolo_intervento_visible: {
      type: Boolean,
      default: false,
    },
    tipo_getfornitori: {
      type: String,
      default: "standard",
    },
    urgenza_richiesta_default_val: { type: String, default: "" },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Variabili di filtro
      avanzamento: 0,
      caricati: 0,
      caricabili: 0,
      cod_richiesta_search: "",
      data_richiesta_search: "",
      des_richiedente_search: "",
      des_urgenza_search: "",
      des_sede_search: "",
      des_stato_search: "",
      des_stato_dettaglio_search: "",
      des_struttura_search: "",
      filtraRecursive: false,
      richiesta_readOnly: false,
      // Altre variabili d'appoggio
      cod_profilo: null,
      consuntivo_max_extra: 0,
      consuntivo_max_extra_confronto: 0,
      consuntivo_max_materiale: 0,
      consuntivo_max_materiale_confronto: 0,
      consuntivo_max_personale: 0,
      consuntivo_max_personale_confronto: 0,
      costo_massimale_totale: 0,
      costo_consuntivo_totale: 0,
      delta_totale: 0,
      costo_extra_confronto: 0,
      costo_materiale_confronto: 0,
      costo_personale_confronto: 0,
      des_stanza_intervento_sel: "",
      des_macchina_interento_sel: "",
      des_area_intervento_sel: "",
      des_sede_intervento_sel: "",

      des_struttura_intervento_sel: "",
      des_zona_intervento_sel: "",
      attivita: [],
      id_profilo: null,
      id_sede_intervento_sel: null,
      id_zona_intervento_sel: null,

      id_stato_in_lavorazione: null,
      id_stato_ordinato: null,
      id_stato_richiesta_aperta: null,
      id_stato_richiesta_confermata: null,
      id_stato_richiesta_rigettata: null,
      id_stato_richiesto: null,
      id_struttura_intervento_sel: null,
      intervento_sel: new Intervention(),
      regola_sel: {
        id_regola: null,
        cod_regola: "",
        descrizione: "",
        tipo_intervento: [],
        steps: [],
      },
      richiesta_sel: new Request(),
      singleExpand: true,
      stato_approvato_id: null,
      stato_sospeso_id: null,
      stato_rifiutato_id: null,

      // Modal
      calendario_data_richiesta: false,
      caricamento_dati: false,
      conferma_scarica_tutti_allegati_visible: false,
      consuntivi_dialog_visible: false,
      dialog_modifica_visible: false,
      dialog_step_visible: false,
      filtro_visible: false,
      menu_ora_schedulata: false,
      modifica_intervento_visible: false,
      snackbar: false,
      snackbar_intervento: false,
      snackbar_consuntivo: false,
      snackbar_step: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      snackbar_richiesta: false,

      // Array
      ambito_manutentivo_lista: [],
      area_lista: [],
      attivita_intervento: [],
      concessionari_lista: [],
      consuntivo_sel_extra: [],
      consuntivo_sel_materiale: [],
      consuntivo_sel_personale: [],
      expanded_richieste: [],
      expanded_steps: [],
      fornitore_lista: [],
      fornitori_confronto: [],
      headers_approvatori: [
        {
          text: "Descrizione",
          value: "des_approvatore",
          sortable: false,
        },
        {
          text: "Soglia Importo (€)",
          value: "importo",
          sortable: false,
        },
        {
          text: "Ruolo Workflow",
          value: "des_ruolo_workflow",
          sortable: false,
        },
        {
          text: "Stato",
          value: "id_stato",
          sortable: false,
        },
      ],
      headers_interventi: [],
      headers_regole: [
        {
          text: "Codice",
          value: "cod_regola",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "descrizione",
          sortable: false,
        },
        {
          text: "Associa",
          value: "associa",
          sortable: false,
        },
      ],
      headers_richieste: [],
      headers_steps: [
        {
          text: "Step",
          sortable: false,
          value: "cod_step",
        },
        {
          text: "Descrizione",
          sortable: false,
          value: "des_step",
        },
        {
          text: "Stato",
          sortable: false,
          value: "id_stato",
        },
      ],
      calendario_data_prevista_intervento: false,
      calendario_data_schedulata_intervento: false,
      items_allegati: [],
      items_regole: [],
      items_richieste: [],
      items_richieste_visualizzate: [],
      sede_lista: [],
      stati_richiesta_lista: [],
      stati_richiesta_2_lista: [],
      stato_intervento_lista: [],
      stato_intervento_lista_disponibile: [],
      steps_sel: [],
      struttura_lista: [],
      tab_consuntivi: [],
      tab_intervento: [],
      tabs_intervento: [],
      tipo_manutenzione_lista: [],
      urgenza_lista: [],
      zona_lista: [],
      fornitore_sel: null,
    };
  },
  computed: {},
  watch: {
    modifica_intervento_visible: {
      immediate: true,
      handler() {
        if (this.modifica_intervento_visible == true) {
          let that = this;
          setTimeout(() => {
            // Do il valore iniziale all'input del costo stimato
            let messaggio = {
              detail: {
                numero: this.intervento_sel.costo_stimato,
              },
              src: null,
            };

            // Inserisco il messaggio in un evento custom.
            let evento = new CustomEvent("numero_iniziale", messaggio);

            // Consegno alla mia destinazione l'evento
            document
              .getElementById("listener_InputSeparatoreinput_costo_stimato")
              .dispatchEvent(evento);

            // Associo un listener per l'aggiornamento all'InputSeparatore del costo stimato
            document
              .getElementById("listener_costo_stimato")
              .addEventListener("nuovo_numero", function (elem) {
                that.intervento_sel.costo_stimato = elem.detail.numero;
              });
          }, 10);
        }
      },
    },
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          // Decido quali devono essere le tabs disponibili in base alle props
          this.tabs_intervento = [
            "Generale",
            "Allegati",
            "Allegati Richiesta",
            "Des. Richiesta",
          ];

          if (this.bacheca_intervento_visible === true) {
            this.tabs_intervento.push("Bacheca");
          }

          // Mi segno l'id_profilo per sapere cosa autorizzare
          this.id_profilo = localStorage.id_profilo;
          this.cod_profilo = localStorage.cod_profilo;

          // Popolo la tabella delle richieste
          this.getValidazioneRichiesteList();

          /* Popolo le select all'interno del
           * dialog di modifica dell'intervento. */
          this.gettabelle();
        }
      },
    },
  },
  methods: {
    checkData(intervento_sel) {
      let date_preview = new Date(intervento_sel.data_prevista);
      let date_final = new Date(intervento_sel.data_schedulata);
      if (date_final < date_preview) {
        this.intervento_sel.data_schedulata = this.intervento_sel.data_prevista;
      }
    },
    checkInterventosel() {
      this.intervento_sel.preventivo =
        this.intervento_sel.preventivo === true ? 1 : 0;
      /*this.intervento_sel.preventivo == true
        ? (this.intervento_sel.preventivo = 1)
        : (this.intervento_sel.preventivo = 0);*/

      this.intervento_sel.interno =
        this.intervento_sel.interno === true ? 1 : 0;
      /*this.intervento_sel.interno == true
        ? (this.intervento_sel.interno = 1)
        : (this.intervento_sel.interno = 0);*/
      // console.log("PREVENTIVO INTERVENTO SEL:", this.intervento_sel.interno);
    },
    associaFornitore(fornitore) {
      //console.log("FORNITORI LISTA:", this.fornitore_lista);
      this.intervento_sel.fornitori = [];
      this.fornitore_lista.forEach((e) => {
        if (e.id_fornitore == fornitore) {
          this.intervento_sel.fornitori.push(e);
        }
      });
    },
    aggiornaAttivita() {
      /* Il DettaglioAttivita ha eseguito un aggiornamento dei propri elementi
       * e l'Intervento fa una chiamata per allineare i propri dati. */
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getinterventodettaglio",
          token: localStorage.user_token,
          id_regola: this.intervento_sel.id_regola,
          id_intervento: this.intervento_sel.id_intervento,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          if (res.data.result.attivita) {
            this.attivita_intervento = res.data.result.attivita;
          }
        }
      });
    },
    associaIdManutentore() {
      /* Svuoto l'array degli identificativi dei
       * manutentori associati all'intervento. */
      this.intervento_sel.id_manutentori = [];

      /* Per ogni descrizione presente nella lista
       * dei manutentori dell'intervento selezionato... */
      this.intervento_sel.des_fornitori.forEach((descrizione) => {
        // Controllo la lista dei manutentori disponibili
        this.fornitore_lista.forEach((fornitore) => {
          /* E quando trovo la descrizione, ne copio l'identificativo
           * nell'array degli identificativi dei manutentori
           * associati all'intervento selezionato. */
          if (fornitore.ragione_sociale === descrizione) {
            this.intervento_sel.id_fornitori.push(fornitore.id_fornitore);
          }
        });
      });
    },
    associaIdTipoIntervento() {
      this.ambito_manutentivo_lista.forEach((elem) => {
        if (elem.id_tabella === this.intervento_sel.id_tipo_intervento) {
          this.intervento_sel.des_tipo_intervento = elem.des;
        }
      });

      this.getFornitoreIntervento(this.intervento_sel);
    },
    associaIdUrgenza() {
      // Ciclo sullalista delle urgenze...
      this.urgenza_lista.forEach((urgenza) => {
        // E abbino la descrizione selezionata al suo identificativo.
        if (this.intervento_sel.des_urgenza === urgenza.des) {
          this.intervento_sel.id_urgenza = urgenza.id_tabella;
        }
      });
    },
    cambiaStatoApprovatore(item) {
      /* Controllo che l'utente sia abilitato a cambiare
       * lo stato dello step, altrimenti stampo una snackbar. */
      if (
        this.cod_profilo != "GOD" &&
        (this.cod_profilo != "AMM" ||
          // &&
          //   item.des_ruolo_workflow == "RESPONSABILE AMMINISTRATIVO"
          this.cod_profilo != "BOSS")
        // &&
        //   item.des_ruolo_workflow == "RESPONSABILE TECNICO"
      ) {
        this.snackbar_step = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "L'utente non è autorizzato a modificare questa approvazione.";
        this.snackbar_text_color = "white";
        return;
      }

      // Cambio lo stato dell'item
      item.id_stato === this.stato_rifiutato_id
        ? (item.id_stato = this.stato_sospeso_id)
        : item.id_stato === this.stato_sospeso_id
        ? (item.id_stato = this.stato_approvato_id)
        : (item.id_stato = this.stato_rifiutato_id);

      this.steps_sel.forEach((step) => {
        if (step.id_step === item.id_step) {
          // Conto le occorrenze dei vari stati approvatori
          let contatore_approvati = 0;
          let contatore_sospesi = 0;
          let contatore_respinti = 0;

          step.approvatori.forEach((approvatore) => {
            if (approvatore.id_stato === this.stato_rifiutato_id) {
              contatore_respinti = contatore_respinti + 1;
            } else if (approvatore.id_stato === this.stato_sospeso_id) {
              contatore_sospesi = contatore_sospesi + 1;
            } else {
              contatore_approvati = contatore_approvati + 1;
            }
          });

          if (
            contatore_approvati > 0 &&
            contatore_sospesi === 0 &&
            contatore_respinti === 0
          ) {
            // Se ci sono solo approvati, approvo lo step
            step.id_stato = this.stato_approvato_id;
          } else if (contatore_respinti > 0) {
            // Se ci sono dei respinti (anche solo 1), respingo lo step
            step.id_stato = this.stato_rifiutato_id;
          } else {
            // Altrimenti sospendo lo step
            step.id_stato = this.stato_sospeso_id;
          }
        }
      });
    },
    chiudiModificaRichiesta() {
      // Ricarico la tabella delle richieste per aggiornarla
      this.getValidazioneRichiesteList();

      // Genero un oggetto richiesta con i campi resettati
      let nuova_richiesta = new Request();

      // Uso l'oggetto resettato per resettare la richiesta_sel.
      this.richiesta_sel = Object.assign({}, nuova_richiesta);

      // Chiudo il dialog di modifica della richiesta.
      this.dialog_modifica_visible = false;
    },
    clickAggiungiIntervento(item) {
      // Registro la richiesta interessata
      this.richiesta_sel = Object.assign({}, item);

      /* Valorizzo le variabili di supporto che descrivono
       * i dati geografici dell'intervento. */
      this.des_sede_intervento_sel = item.des_sede;
      this.des_struttura_intervento_sel = item.des_struttura;
      this.des_area_intervento_sel = item.des_area;
      this.des_zona_intervento_sel = item.des_zona;
      this.des_stanza_intervento_sel = item.des_stanza;
      this.des_macchina_interento_sel = item.des_macchinario;
      this.id_sede_intervento_sel = item.id_sede;
      this.id_struttura_intervento_sel = item.id_struttura;
      this.id_area_intervento_sel = item.id_area;
      this.id_zona_intervento_sel = item.id_zona;
      this.id_stanza_intervento_sel = item.id_stanza;
      this.id_macchinario_intervento_sel = item.id_macchinario;

      // Genero un intervento resettato
      let nuovo_intervento = new Intervention();

      /* Definisco l'urgenza del nuovo intervento
       * in base all'urgenza della richiesta padre. */
      nuovo_intervento.id_urgenza = item.id_urgenza;
      nuovo_intervento.des_urgenza = item.des_urgenza;

      /* Definisco la descrizione e la soluzione dell'intervento
       * in base alla descrizione / soluzione della richiesta,
       * che resteranno campi in readonly. */
      nuovo_intervento.descrizione = item.descrizione;
      nuovo_intervento.soluzione = item.soluzione;

      // Assegno all'intervento resettato l'id_richiesta
      nuovo_intervento.id_richiesta = item.id_richiesta;
      // Uso l'oggetto appena creato per valorizzare intervento_sel
      this.intervento_sel = Object.assign({}, nuovo_intervento);
      // console.log("NUOVO INTERVENTO:", this.intervento_sel);
      this.intervento_sel.id_sede = this.id_sede_intervento_sel;
      this.intervento_sel.id_struttura = this.id_struttura_intervento_sel;
      this.intervento_sel.id_area = this.id_area_intervento_sel;
      this.intervento_sel.id_zona = this.id_zona_intervento_sel;
      this.intervento_sel.id_stanza = this.id_stanza_intervento_sel;
      this.intervento_sel.id_macchinario = this.id_macchinario_intervento_sel;
      this.intervento_sel.des_sede = this.des_sede_intervento_sel;
      this.intervento_sel.des_struttura = this.des_struttura_intervento_sel;
      this.intervento_sel.des_area = this.des_area_intervento_sel;
      this.intervento_sel.des_zona = this.des_zona_intervento_sel;
      this.intervento_sel.des_stanza = this.des_stanza_intervento_sel;
      this.intervento_sel.des_macchinario = this.des_macchina_interento_sel;

      /* Valorizzo il valore di default per la checkbox di interno /
       * preventivo all'interno dell'intervento selezionato. */
      this.intervento_sel[this.checkbox] = this.checkbox_default_val;

      // Resetto il fornitore_sel
      this.fornitore_sel = null;

      // Apro il dialog di modifica / creazione intervento
      this.modifica_intervento_visible = true;
    },
    clickChiudiModificaConsuntivi() {
      this.consuntivi_dialog_visible = false;
    },
    clickChiudiModificaIntervento() {
      // Genero un oggetto resettato
      let nuovo_intervento = new Intervention();

      // Uso l'oggetto resettato per resettare intervento_sel
      this.intervento_sel = Object.assign({}, nuovo_intervento);

      // Chiudo il dialog di modifica / creazione intervento
      this.modifica_intervento_visible = false;

      // Resetto gli array di supporto che servono per gli allegati
      this.lista_src = [];
      this.lista_files = [];

      /* Resetto le variabili di supporto che rappresentano
       * i campi geografici dell'intervento selezionato. */
      this.des_sede_intervento_sel = "";
      this.des_struttura_intervento_sel = "";
      this.des_area_intervento_sel = "";
      this.des_zona_intervento_sel = "";
      this.des_stanza_intervento_sel = "";
      this.des_macchina_interento_sel = "";
      this.id_sede_intervento_sel = null;
      this.id_struttura_intervento_sel = null;
      this.id_area_intervento_sel = null;
      this.id_zona_intervento_sel = null;
      this.id_stanza_intervento_sel = null;
      this.id_macchinario_intervento_sel = null;

      // Resetto l'array dei fornitori passibili di incarico
      this.fornitore_lista = [];

      // Resetto le attività dell'intervento selezionato
      this.attivita_intervento = [];
    },
    clickDettaglioFornitore() {
      let fornitore_sel_tmp = Object.assign({}, this.fornitore_sel);
      fornitore_sel_tmp.id_anagrafica = fornitore_sel_tmp.id_fornitore;
      fornitore_sel_tmp = new Registry(fornitore_sel_tmp);

      this.$refs.TabellaAnagrafiche.anagrafica_sel = new Registry(
        fornitore_sel_tmp
      );
      this.$refs.TabellaAnagrafiche.clickDettagli(fornitore_sel_tmp);
    },
    getoperatoribyattivitaintervento(id_intervento) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getoperatorybyattivitaintervento",
          token: localStorage.user_token,
          id_intervento: id_intervento,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.operatori_by_attivita = [];
          // Assegno i massimali
          // console.log("RES.DATA:", res.data);
          this.attivita = res.data.result.attivita;
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non è stato possibile caricare i consuntivi di questo intervento.";
          this.snackbar_text_color = "white";
        }
      });
    },

    clickConsuntiviDialog(arg) {
      // Prendo arg come intervento_sel
      this.intervento_sel = new Intervention(arg);
      this.intervento_sel.consuntivo[0].id_consuntivo = arg.id_consuntivo;
      this.getoperatoribyattivitaintervento(arg.id_intervento);

      /* Faccio una getInterventoDettaglio per prendere il fornitore
       * dell'intervento altrimenti la chiamata setStatoIntervento
       * non trova i fornitori e stampa errore in console. */
      this.getInterventoDettaglio(this.intervento_sel, "empty");

      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getconsuntivodettaglio",
          token: localStorage.user_token,
          id_consuntivo: arg.id_consuntivo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Assegno i massimali
          this.consuntivo_max_personale =
            res.data.result.consuntivo[0].massimale_personale != null
              ? res.data.result.consuntivo[0].massimale_personale
              : 0;

          this.consuntivo_max_materiale =
            res.data.result.consuntivo[0].massimale_materiale != null
              ? res.data.result.consuntivo[0].massimale_materiale
              : 0;

          this.consuntivo_max_extra =
            res.data.result.consuntivo[0].massimale_extra != null
              ? res.data.result.consuntivo[0].massimale_extra
              : 0;
          this.$refs.ConsuntiviPersonale.items_personale =
            res.data.result.consuntivo[0].consuntivo_personale;

          /* Registro una serie di valori di confronto che
           * mi serviranno al momento del salvataggio. */

          // Massimale del personale
          this.consuntivo_max_personale_confronto = this.consuntivo_max_personale;

          // Massimale del materiale
          this.consuntivo_max_materiale_confronto = this.consuntivo_max_materiale;

          // Massimale degli extra
          this.consuntivo_max_extra_confronto = this.consuntivo_max_extra;

          // Consuntivo del personale
          this.costo_personale_confronto =
            res.data.result.consuntivo[0].costo_personale != null
              ? res.data.result.consuntivo[0].costo_personale
              : 0;

          // Consuntivo del materiale
          this.costo_materiale_confronto =
            res.data.result.consuntivo[0].costo_materiale != null
              ? res.data.result.consuntivo[0].costo_materiale
              : 0;

          // Consuntivo degli extra
          this.costo_extra_confronto =
            res.data.result.consuntivo[0].costo_extra != null
              ? res.data.result.consuntivo[0].costo_extra
              : 0;

          // Popolo le tabelle interne dei consuntivi
          this.consuntivo_sel_personale =
            res.data.result.consuntivo[0].consuntivo_personale != undefined
              ? res.data.result.consuntivo[0].consuntivo_personale
              : [];

          this.consuntivo_sel_materiale =
            res.data.result.consuntivo[0].consuntivo_materiale != undefined
              ? res.data.result.consuntivo[0].consuntivo_materiale
              : [];

          this.consuntivo_sel_extra =
            res.data.result.consuntivo[0].consuntivo_extra != undefined
              ? res.data.result.consuntivo[0].consuntivo_extra
              : [];

          this.costo_massimale_totale =
            res.data.result.consuntivo[0].massimale_personale +
            res.data.result.consuntivo[0].massimale_materiale +
            res.data.result.consuntivo[0].massimale_extra;

          this.costo_consuntivo_totale =
            res.data.result.consuntivo[0].costo_personale +
            res.data.result.consuntivo[0].costo_materiale +
            res.data.result.consuntivo[0].costo_extra;
          this.delta_totale =
            this.costo_massimale_totale - this.costo_consuntivo_totale;
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non è stato possibile caricare i consuntivi di questo intervento.";
          this.snackbar_text_color = "white";
        }
      });

      this.consuntivi_dialog_visible = true;
    },
    clickModificaIntervento(item) {
      // Popolo la select per gli stati dell'intervento
      /*if (
        item.des_stato_operativo === "APERTO" &&
        localStorage.id_profilo == 7
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.des === "RICHIESTO" || elem.des === "APERTO"
        );
      } else if (
        item.des_stato_operativo === "RICHIESTO" &&
        localStorage.id_profilo == 7
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.des === "IN LAVORAZIONE" || elem.des === "RICHIESTO"
        );
      } else*/ if (
        item.cod_stato_operativo === "WIP" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "SVIL" ||
          localStorage.cod_profilo == "OPJ" ||
          localStorage.cod_profilo == "OPS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.cod === "UNDEFINED" ||
            elem.cod === "WAIT_MAT" ||
            elem.cod === "REQ" ||
            elem.cod === "CANT_DO" ||
            elem.cod === "WIP" ||
            elem.cod === "COMPL"
        );
      } else if (
        (item.cod_stato_operativo === "UNDEFINED" ||
          item.cod_stato_operativo === "WAIT_MAT" ||
          item.cod_stato_operativo === "REQ" ||
          item.cod_stato_operativo === "CANT_DO") &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "SVIL" ||
          localStorage.cod_profilo == "OPJ" ||
          localStorage.cod_profilo == "OPS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.cod === "UNDEFINED" ||
            elem.cod === "WAIT_MAT" ||
            elem.cod === "REQ" ||
            elem.cod === "CANT_DO" ||
            elem.cod === "COMPL"
        );
      } else if (
        item.cod_stato_operativo === "COMPL" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "SVIL") &&
        item.preventivo == 0 &&
        item.interno == 0
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.cod === "CONS" || elem.des === "COMPL"
        );
      } else if (
        item.cod_stato_operativo === "CONS" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "SVIL" ||
          localStorage.cod_profilo == "AMM" ||
          localStorage.cod_profilo == "BOSS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.cod === "CONS" || elem.cod === "VALID" || elem.cod === "REV"
        );
      } else if (
        item.cod_stato_operativo === "VALID" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "SVIL" ||
          localStorage.cod_profilo == "AMM" ||
          localStorage.cod_profilo == "BOSS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.cod === "ORDER" || elem.cod === "VALID"
        );
      } else {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.cod === item.cod_stato_operativo
        );
        // console.log(item.des_stato_operativo + " " + localStorage.id_profilo);
      }

      // Registro l'intervento selezionato
      this.intervento_sel = new Intervention(item);

      // Resetto il fornitore_sel
      this.fornitore_sel = null;

      /* Faccio una chiamata per avere i fornitori,
       * le attività, gli allegati e definire altri
       * dettagli di supporto dell'intervento. */
      this.getInterventoDettaglio(this.intervento_sel);

      this.items_richieste.forEach((elem) => {
        if (elem.id_richiesta === item.id_richiesta) {
          this.des_sede_intervento_sel = elem.des_sede;
          this.des_struttura_intervento_sel = elem.des_struttura;
          this.des_area_intervento_sel = elem.des_area;
          this.des_zona_intervento_sel = elem.des_zona;
          this.des_stanza_intervento_sel = elem.des_stanza;
          this.des_macchina_interento_sel = elem.des_macchinario;
          this.id_sede_intervento_sel = elem.id_sede;
          this.id_struttura_intervento_sel = elem.id_struttura;
          this.id_area_intervento_sel = elem.id_area;
          this.id_zona_intervento_sel = elem.id_zona;
          this.id_stanza_intervento_sel = elem.id_stanza;
          this.id_macchinario_intervento_sel = elem.id_macchinario;

          /* Setto la descrizione e la soluzione dell'intervento (che
           * saranno in readonly) come la descrizione e la soluzione
           * della richiesta a cui l'intervento si riferisce. */
          this.intervento_sel.descrizione = elem.descrizione;
          this.intervento_sel.soluzione = elem.soluzione;
        }
      });

      this.intervento_sel.id_sede = this.id_sede_intervento_sel;
      this.intervento_sel.id_struttura = this.id_struttura_intervento_sel;
      this.intervento_sel.id_area = this.id_area_intervento_sel;
      this.intervento_sel.id_zona = this.id_zona_intervento_sel;
      this.intervento_sel.id_stanza = this.id_stanza_intervento_sel;
      this.intervento_sel.id_macchinario = this.id_macchinario_intervento_sel;
      this.intervento_sel.des_sede = this.des_sede_intervento_sel;
      this.intervento_sel.des_struttura = this.des_struttura_intervento_sel;
      this.intervento_sel.des_area = this.des_area_intervento_sel;
      this.intervento_sel.des_zona = this.des_zona_intervento_sel;
      this.intervento_sel.des_stanza = this.des_stanza_intervento_sel;
      this.intervento_sel.des_macchinario = this.des_macchina_interento_sel;

      // Apro il dialog di modifica
      this.modifica_intervento_visible = true;
    },
    clickModificaRichiesta(item, readOnly) {
      console.log(item);
      this.richiesta_readOnly = readOnly;
      this.richiesta_sel = Object.assign({}, item);
      this.dialog_modifica_visible = true;
    },
    clickStepDialog(item) {
      if (item.attivita) {
        if (item.attivita.length > 0) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non è possibile modificare le approvazioni di un Intervento a cui sono già state assegnate delle attività.";
          this.snackbar_text_color = "white";
          return;
        }
      }

      this.intervento_sel = new Intervention(item);
      this.getInterventoListaRegolaDettaglio(item);
      this.dialog_step_visible = true;
    },
    click_chiudi_step() {
      // Genero una regola resettata
      let nuova_regola = {
        id_regola: -1,
        id_intervento: null,
        cod_regola: "",
        descrizione: "",
        tipo_intervento: [],
        steps: [],
      };

      // Uso la regola resettata pe resettare la regola_sel
      this.regola_sel = Object.assign({}, nuova_regola);

      // Chiudo il dialog
      this.dialog_step_visible = false;

      /* Chiudo tutti i drill down di questo dialog, nel caso
       * qualcuno fosse stato aperto e non chiuso. */
      this.expanded_steps = [];
    },
    filtra() {
      let items_richieste_tmp = [];
      this.items_richieste.forEach((elem) => {
        items_richieste_tmp.push(elem);
      });

      // Filtro il cod_richiesta
      if (this.cod_richiesta_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.cod_richiesta == this.cod_richiesta_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      if (this.des_stato_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_stato == this.des_stato_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      if (this.des_stato_dettaglio_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_stato_2 == this.des_stato_dettaglio_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro il richiedente
      if (this.des_richiedente_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_richiedente == this.des_richiedente_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      if (this.des_urgenza_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_urgenza == this.des_urgenza_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro la data richiesta
      if (this.data_richiesta_search != "") {
        // Faccio un array temporaneo
        let res = [];

        // Mi segno la data ricercata formattata in moment
        let data_ricercata = new moment(
          this.data_richiesta_search.substring(0, 4) +
            "-" +
            this.data_richiesta_search.substring(5, 7) +
            "-" +
            this.data_richiesta_search.substring(8, 10)
        );

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          elem.data_js = new moment(elem.data_richiesta);
          if (elem.data_js.isSameOrAfter(data_ricercata)) {
            /* Dopo il confronto se c'è match elimino la data moment
             * per convenienza di BE e aggiungo l'oggetto all'array */
            delete elem.data_js;
            res.push(elem);
          } else {
            // Altrimenti non faccio niente, ma elimino comunque la data moment.
            delete elem.data_js;
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro la sede della richiesta
      if (this.des_sede_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_sede == this.des_sede_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      // Filtro la struttura della richiesta
      if (this.des_struttura_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_richieste_tmp.forEach((elem) => {
          if (elem.des_struttura == this.des_struttura_search) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_richieste_tmp = res;
      }

      this.items_richieste_tmp = [];
      this.items_richieste_visualizzate = items_richieste_tmp;
    },
    filtraAttivitaInCorso() {
      // Attivo l'overlay di caricamento.
      this.caricamento_dati = true;

      // Compongo la request per il BE.
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getvalidazionerichiestelistfiltered",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Disattivo l'overlay di caricamento.
        this.caricamento_dati = false;

        if (res.status === 200) {
          res.data.result.richieste.forEach((richiesta) => {
            if (richiesta.interventi) {
              richiesta.interventi.forEach((intervento) => {
                intervento.interno = intervento.interno == 0 ? false : true;
                intervento.preventivo =
                  intervento.preventivo == 0 ? false : true;
              });
            }
          });
          this.items_richieste = res.data.result.richieste;
          this.items_richieste_visualizzate = res.data.result.richieste;
          this.filtra();
        }
      });
    },
    filtraDataSchedulata() {
      // Attivo l'overlay di caricamento.
      this.caricamento_dati = true;

      // Compongo la request per il BE.
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getvalidazionerichiestelistordered",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Disattivo l'overlay di caricamento.
        this.caricamento_dati = false;

        if (res.status === 200) {
          res.data.result.richieste.forEach((richiesta) => {
            if (richiesta.interventi) {
              richiesta.interventi.forEach((intervento) => {
                intervento.interno = intervento.interno == 0 ? false : true;
                intervento.preventivo =
                  intervento.preventivo == 0 ? false : true;
              });
            }
          });
          this.items_richieste = res.data.result.richieste;
          this.items_richieste_visualizzate = res.data.result.richieste;
          this.filtra();
          this.singleExpand = false;
          this.expanded_richieste = Array.from(
            this.items_richieste_visualizzate
          );
        }
      });
    },
    getFornitoreIntervento(arg) {
      console.log("entrago getfornitoreintervento generico");
      if (this.tipo_getfornitori === "standard") {
        console.log("entrato getfornitore intervento", this.tipo_getfornitori);
        let request = {
          controller: "Manutenzione",
          method: "POST",
          richiesta: {
            action: "getfornitoreintervento",
            token: localStorage.user_token,
            id_sede: this.id_sede_intervento_sel,
            id_struttura: this.id_struttura_intervento_sel,
            id_ambito_manutenzione: arg.id_tipo_intervento,
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          if (res.status === 200) {
            this.fornitore_lista = res.data.result;
            // Rinomino il campo id_anagrafica per convenienza di BE sul salvataggio
            this.fornitore_lista.forEach((fornitore) => {
              fornitore.id_fornitore = fornitore.id_anagrafica;
              delete fornitore.id_anagrafica;
            });
            //this.intervento_sel.fornitori = this.fornitore_lista;
          } else {
            this.fornitore_lista = [];
            this.snackbar_richiesta = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Non è stato trovato nessun fornitore autorizzato a operare in questa sede / struttura specializzato per questo ambito manutentivo.";
            this.snackbar_text_color = "white";
          }
        });
      } else if (
        this.tipo_getfornitori === "digitalmarine" &&
        this.intervento_sel.id_concessionario != null &&
        arg.id_tipo_intervento != null
      ) {
        let request = {
          controller: "Manutenzione",
          method: "POST",
          richiesta: {
            action: "getfornitoridigitalmarine",
            token: localStorage.user_token,
            id_concessionario: this.intervento_sel.id_concessionario,
            id_ambito_manutenzione: arg.id_tipo_intervento,
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          if (res.status === 200) {
            this.fornitore_lista = res.data.result;
            // Rinomino il campo id_anagrafica per convenienza di BE sul salvataggio
            this.fornitore_lista.forEach((fornitore) => {
              fornitore.id_fornitore = fornitore.id_anagrafica;
              delete fornitore.id_anagrafica;
            });
            //this.intervento_sel.fornitori = this.fornitore_lista;
          } else {
            this.fornitore_lista = [];
            this.snackbar_richiesta = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Non è stato trovato nessun fornitore autorizzato a operare in questa sede / struttura specializzato per questo ambito manutentivo.";
            this.snackbar_text_color = "white";
          }
        });
      }
    },
    getInterventoDettaglio(arg, arg_tipo = "intdettaglio") {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getinterventodettaglio",
          token: localStorage.user_token,
          id_regola: arg.id_regola,
          id_intervento: arg.id_intervento,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          if (res.data.result.fornitori) {
            res.data.result.fornitori.forEach((fornitore) => {
              arg.fornitori.push(fornitore.id_fornitore);
            });
            arg.consuntivo = res.data.result.consuntivo;
            this.attivita_intervento = res.data.result.attivita;

            /* Inserisco nelle attività un campo che le collega al
             * consuntivo dell'intervento; questa informazione risulta
             * utile al BE in fase di salvataggio (chiamata setattivita).*/
            this.attivita_intervento.forEach((attivita) => {
              attivita.id_consuntivo = arg.id_consuntivo;
            });

            this.fornitori_confronto = res.data.result.fornitori;
            this.fornitore_sel = res.data.result.fornitori[0];
            this.intervento_sel.fornitori = res.data.result.fornitori;

            this.items_allegati = res.data.result.allegati_intervento;
            this.intervento_sel.allegati = res.data.result.allegati_intervento;

            //creazione lista delle src usando il $set perchè altrimenti si perdono informazioni e risulta array vuoto su this.lista_src
            //   this.items_allegati.forEach(async (el, i) => {
            //     this.lista_files.push(el);
            //     if (el.validita == 0) {
            //       this.$set(this.lista_src, i, null);
            //     } else {
            //       let blob;
            //       blob = await this.getImagePreviewDb(el.id_allegato + el.estensione.toString());
            //       this.$set(this.lista_src, i, URL.createObjectURL(blob));
            //     }
            //   });
          }

          /* Faccio una chiamata per valorizzare la select
           * dei fornitori passibili di chiamata per le attività. */
          if (arg_tipo === "intdettaglio") {
            this.getFornitoreIntervento(arg);
          }
        }
      });
    },
    getInterventoListaRegolaDettaglio(arg) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getinterventolistaregoladettaglio",
          token: localStorage.user_token,
          id_regola: arg.id_regola,
          id_intervento: arg.id_intervento,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.steps_sel = res.data.result.steps;
        }
      });
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_TIPO_MANUTENZIONE','MAN_URGENZA_INTERVENTO','MAN_AMBITO_MANUTENTIVO','MAN_STATO_INTERVENTO_APPROVATIVO','MAN_STATO_INTERVENTO_OPERATIVO','MAN_STATO_RICHIESTA','MAN_CONCESSIONARIO','MAN_STATO_RICHIESTA_2'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.concessionari_lista =
          res.data.MAN_CONCESSIONARIO != undefined
            ? res.data.MAN_CONCESSIONARIO
            : [];

        this.tipo_manutenzione_lista =
          res.data.MAN_TIPO_MANUTENZIONE != undefined
            ? res.data.MAN_TIPO_MANUTENZIONE
            : [];
        this.urgenza_lista =
          res.data.MAN_URGENZA_INTERVENTO != undefined
            ? res.data.MAN_URGENZA_INTERVENTO
            : [];
        this.ambito_manutentivo_lista =
          res.data.MAN_AMBITO_MANUTENTIVO != undefined
            ? res.data.MAN_AMBITO_MANUTENTIVO
            : [];
        this.stato_intervento_lista =
          res.data.MAN_STATO_INTERVENTO_OPERATIVO != undefined
            ? res.data.MAN_STATO_INTERVENTO_OPERATIVO
            : [];

        // Registro l'id dello stato intervento ordinato
        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "ORDINATO") {
            this.id_stato_ordinato = elem.id_tabella;
          }
        });

        // Registro l'id dello stato intervento richiesto
        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "RICHIESTO") {
            this.id_stato_richiesto = elem.id_tabella;
          }
        });

        // Registro l'id dello stato intervento in lavorazione
        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "IN LAVORAZIONE") {
            this.id_stato_in_lavorazione = elem.id_tabella;
          }
        });

        // Recupero dinamicamente gli id degli stati approvativi degli interventi
        if (res.data.MAN_STATO_INTERVENTO_APPROVATIVO != undefined) {
          res.data.MAN_STATO_INTERVENTO_APPROVATIVO.forEach((ambito) => {
            if (ambito.des === "RIFIUTATO") {
              this.stato_rifiutato_id = ambito.id_tabella;
            } else if (ambito.des === "DA APPROVARE") {
              this.stato_sospeso_id = ambito.id_tabella;
            } else if (ambito.des === "APPROVATO") {
              this.stato_approvato_id = ambito.id_tabella;
            }
          });
        }

        // Recupero dinamicamente gli id degli stati della richiesta
        if (res.data.MAN_STATO_RICHIESTA != undefined) {
          res.data.MAN_STATO_RICHIESTA.forEach((stato) => {
            if (stato.cod === "PENDING") {
              this.id_stato_richiesta_aperta = stato.id_tabella;
            } else if (stato.cod === "ACCEPT") {
              this.id_stato_richiesta_confermata = stato.id_tabella;
            } else if (stato.cod === "REJECT") {
              this.id_stato_richiesta_rigettata = stato.id_tabella;
            }
          });
        }

        // Definisco la lista degli stati richiesta
        this.stati_richiesta_lista =
          res.data.MAN_STATO_RICHIESTA != undefined
            ? res.data.MAN_STATO_RICHIESTA
            : [];

        // Definisco la lista degli stati richiesta dettaglio
        this.stati_richiesta_2_lista =
          res.data.MAN_STATO_RICHIESTA_2 != undefined
            ? res.data.MAN_STATO_RICHIESTA_2
            : [];
      });
    },
    getValidazioneRichiesteList() {
      /* Faccio in modo che si possa espandere un solo row
       * per volta e svuoto l'aray delle richieste espanse.*/
      this.singleExpand = true;
      this.expanded_richieste = [];

      // Attivo l'overlay di caricamento.
      this.caricamento_dati = true;

      // Compongo la request per il BE.
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getvalidazionerichiestelist",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Disattivo l'overlay di caricamento.
        this.caricamento_dati = false;

        if (res.status === 200) {
          res.data.result.richieste.forEach((richiesta) => {
            if (richiesta.interventi) {
              richiesta.interventi.forEach((intervento) => {
                intervento.interno = intervento.interno == 0 ? false : true;
                intervento.preventivo =
                  intervento.preventivo == 0 ? false : true;
              });
            }
          });
          this.items_richieste = Array.from(res.data.result.richieste);
          this.items_richieste_visualizzate = Array.from(
            res.data.result.richieste
          );
          this.filtra();
        }
      });
    },
    goBack() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        // Resetto la tab corrente
        this.tab_intervento = [];

        /* Emetto un evento per scatenare nel padre
         * la gestione dell'evento click indietro. */
        this.$emit("click_indietro");
      }
    },
    resetFiltro() {
      // Resetto i filtri
      this.cod_richiesta_search = "";
      this.des_richiedente_search = "";
      this.data_richiesta_search = "";
      this.des_sede_search = "";
      this.des_struttura_search = "";

      // Nascondo i filtri
      this.filtro_visible = false;

      // Ricarico la tabella
      this.items_richieste_visualizzate = Array.from(this.items_richieste);
    },
    async salvaModificaConsuntivi(arg) {
      // Preparo un consuntivo da salvare
      let consuntivo_personale_tmp =
        this.$refs.ConsuntiviPersonale.items_personale != undefined
          ? this.$refs.ConsuntiviPersonale.items_personale
          : [];
      consuntivo_personale_tmp.forEach((element) => {
        element.id_consuntivo = this.intervento_sel.consuntivo[0].id_consuntivo;
      });
      let consuntivo_tmp = {
        id_consuntivo: this.intervento_sel.consuntivo[0].id_consuntivo,

        massimale_personale:
          this.$refs.ConsuntiviPersonale != undefined
            ? parseFloat(this.$refs.ConsuntiviPersonale.massimale_tmp)
            : this.consuntivo_max_personale_confronto,

        massimale_materiale:
          this.$refs.ConsuntiviMateriale != undefined
            ? this.$refs.ConsuntiviMateriale.massimale_tmp
            : this.consuntivo_max_materiale_confronto,
        massimale_extra:
          this.$refs.ConsuntiviExtra != undefined
            ? this.$refs.ConsuntiviExtra.massimale_tmp
            : this.consuntivo_max_extra_confronto,
        consuntivo_materiale:
          this.$refs.ConsuntiviMateriale != undefined
            ? this.$refs.ConsuntiviMateriale.items_materiale.filter(
                (elem) =>
                  elem.validita === 1 || elem.id_consuntivo_materiale > 0
              )
            : null,
        consuntivo_extra:
          this.$refs.ConsuntiviExtra != undefined
            ? this.$refs.ConsuntiviExtra.items_extra.filter(
                (elem) => elem.validita === 1 || elem.id_consuntivo_extra > 0
              )
            : null,
        costo_personale:
          this.$refs.ConsuntiviPersonale != undefined
            ? this.$refs.ConsuntiviPersonale.consuntivo
            : this.costo_personale_confronto,
        costo_materiale:
          this.$refs.ConsuntiviMateriale != undefined
            ? this.$refs.ConsuntiviMateriale.consuntivo
            : this.costo_materiale_confronto,
        costo_extra:
          this.$refs.ConsuntiviExtra != undefined
            ? this.$refs.ConsuntiviExtra.consuntivo
            : this.costo_extra_confronto,
      };

      // Riassegno gli id dei materiali nuovi
      if (consuntivo_tmp.consuntivo_materiale != null) {
        consuntivo_tmp.consuntivo_materiale.forEach((elem) => {
          if (elem.id_consuntivo_materiale < 0) {
            elem.id_consuntivo_materiale = -1;
          }
        });
      }

      // Riassegno gli id degli extra nuovi
      if (consuntivo_tmp.consuntivo_extra != null) {
        consuntivo_tmp.consuntivo_extra.forEach((elem) => {
          if (elem.id_consuntivo_extra < 0) {
            elem.id_consuntivo_extra = -1;
          }
        });
      }

      // Preparo la request di salvataggio
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setconsuntivo",
          token: localStorage.user_token,
          consuntivo: [consuntivo_tmp],
          consuntivo_personale: consuntivo_personale_tmp,
        },
      };
      //Gestisco la response
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Eseguo il metodo concatenato (se presente).
          if (arg != undefined && arg != "donotclose") {
            arg;
          }

          // Chiudo il dialog dei consuntivi.
          if (arg == undefined || arg != "donotclose") {
            this.consuntivi_dialog_visible = false;
            this.intervento_sel = new Intervention();
          }
          if (arg == "donotclose") {
            let request = {
              controller: "Manutenzione",
              method: "POST",
              richiesta: {
                action: "getconsuntivodettaglio",
                token: localStorage.user_token,
                id_consuntivo: this.intervento_sel.consuntivo[0].id_consuntivo,
              },
            };

            this.$store.dispatch("api", request).then((res) => {
              if (res.status === 200) {
                // Assegno i massimali
                this.consuntivo_max_personale =
                  res.data.result.consuntivo[0].massimale_personale != null
                    ? res.data.result.consuntivo[0].massimale_personale
                    : 0;

                this.consuntivo_max_materiale =
                  res.data.result.consuntivo[0].massimale_materiale != null
                    ? res.data.result.consuntivo[0].massimale_materiale
                    : 0;

                this.consuntivo_max_extra =
                  res.data.result.consuntivo[0].massimale_extra != null
                    ? res.data.result.consuntivo[0].massimale_extra
                    : 0;
                this.$refs.ConsuntiviPersonale.items_personale =
                  res.data.result.consuntivo[0].consuntivo_personale;
                /* Registro una serie di valori di confronto che
                 * mi serviranno al momento del salvataggio. */

                // Massimale del personale
                this.consuntivo_max_personale_confronto = this.consuntivo_max_personale;

                // Massimale del materiale
                this.consuntivo_max_materiale_confronto = this.consuntivo_max_materiale;

                // Massimale degli extra
                this.consuntivo_max_extra_confronto = this.consuntivo_max_extra;

                // Consuntivo del personale
                this.costo_personale_confronto =
                  res.data.result.consuntivo[0].costo_personale != null
                    ? res.data.result.consuntivo[0].costo_personale
                    : 0;

                // Consuntivo del materiale
                this.costo_materiale_confronto =
                  res.data.result.consuntivo[0].costo_materiale != null
                    ? res.data.result.consuntivo[0].costo_materiale
                    : 0;

                // Consuntivo degli extra
                this.costo_extra_confronto =
                  res.data.result.consuntivo[0].costo_extra != null
                    ? res.data.result.consuntivo[0].costo_extra
                    : 0;

                // Popolo le tabelle interne dei consuntivi
                this.consuntivo_sel_personale =
                  res.data.result.consuntivo[0].consuntivo_personale !=
                  undefined
                    ? res.data.result.consuntivo[0].consuntivo_personale
                    : [];

                this.consuntivo_sel_materiale =
                  res.data.result.consuntivo[0].consuntivo_materiale !=
                  undefined
                    ? res.data.result.consuntivo[0].consuntivo_materiale
                    : [];

                this.consuntivo_sel_extra =
                  res.data.result.consuntivo[0].consuntivo_extra != undefined
                    ? res.data.result.consuntivo[0].consuntivo_extra
                    : [];

                this.costo_massimale_totale =
                  res.data.result.consuntivo[0].massimale_personale +
                  res.data.result.consuntivo[0].massimale_materiale +
                  res.data.result.consuntivo[0].massimale_extra;

                this.costo_consuntivo_totale =
                  res.data.result.consuntivo[0].costo_personale +
                  res.data.result.consuntivo[0].costo_materiale +
                  res.data.result.consuntivo[0].costo_extra;
                this.delta_totale =
                  this.costo_massimale_totale - this.costo_consuntivo_totale;
                this.snackbar_consuntivo = true;
                this.snackbar_background = "success";
                this.snackbar_icon = "mdi-check-outline";
                this.snackbar_icon_color = "white";
                this.snackbar_text = "Consuntivo salvato con successo!";
                this.snackbar_text_color = "white";
              } else {
                this.snackbar = true;
                this.snackbar_background = "orange";
                this.snackbar_icon = "mdi-alert";
                this.snackbar_icon_color = "white";
                this.snackbar_text =
                  "Non è stato possibile caricare i consuntivi di questo intervento.";
                this.snackbar_text_color = "white";
              }
            });
          }

          // Resetto l'intervento_sel
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Errore tecnico, non è stato possibile salvare i dati del consuntivo.";
          this.snackbar_text_color = "white";
        }
      });
    },
    async salvaModificaIntervento() {
      // Traduco intervento e preventivo da booleano a numerico.
      // this.checkInterventosel();

      // Eseguo una serie di controlli prima di salvare.

      // Controllo che ci sia un titolo intervento (se necessario)
      if (
        this.intervento_sel.des_intervento === "" &&
        this.titolo_intervento_visible === true
      ) {
        this.snackbar_intervento = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire il Titolo Intervento prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo la presenza di un referente
      if (this.intervento_sel.des_referente === "") {
        this.snackbar_intervento = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire il Referente prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo la presenza di un costo stimato
      /*if (this.intervento_sel.costo_stimato === "") {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire il Costo Stimato prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }*/

      // Controllo che il costo stimato non sia negativo
      if (this.intervento_sel.costo_stimato < 0) {
        this.snackbar_intervento = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il Costo Stimato deve essere pari o superiore a 0.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo la presenza di un Ambito Manutentivo
      /* if (this.intervento_sel.id_tipo_intervento === null) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Definire l'Ambito Manutentivo prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      } */

      // Se l'id_tipo_intervento non è assegnato, lo assegno con valore di default
      if (this.intervento_sel.id_tipo_intervento === null) {
        this.ambito_manutentivo_lista.forEach((elem) => {
          if (elem.cod === "STANDARD") {
            this.intervento_sel.id_tipo_intervento = elem.id_tabella;
          }
        });
      }

      // Se lo Stato è RICHIESTO e c'è una attività, lo stato operativo passa in IN LAVORAZIONE
      if (
        this.intervento_sel.id_stato_operativo == this.id_stato_richiesto &&
        this.$refs.dettaglio_attivita.items_attivita.length > 0
      ) {
        this.intervento_sel.id_stato_operativo = this.id_stato_in_lavorazione;
        this.intervento_sel.des_stato_operativo = "IN LAVORAZIONE";
      }

      // Controllo la presenza dell'Urgenza
      if (this.intervento_sel.id_urgenza === null) {
        this.snackbar_intervento = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Definire l'Urgenza prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che gli interventi in stato ordinato abbiano il numero d'ordine
      if (
        this.intervento_sel.id_stato_operativo === this.id_stato_ordinato &&
        (this.intervento_sel.numero_ordine === null ||
          this.intervento_sel.numero_ordine === "")
      ) {
        this.snackbar_intervento = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare un Numero d'Ordine prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Ricavo la lista dei fornitori da salvare
      let fornitori_tmp = [];
      this.intervento_sel.fornitori.forEach((id) => {
        this.fornitore_lista.forEach((fornitore) => {
          if (fornitore.id_fornitore === id.id_fornitore) {
            fornitori_tmp.push(fornitore);
          }
        });
      });

      // Associo la des_urgenza
      this.urgenza_lista.forEach((urgenza) => {
        if (urgenza.id_tabella === this.intervento_sel.id_urgenza) {
          this.intervento_sel.des_urgenza = urgenza.des;
        }
      });

      // Genero un oggetto intervento che andrò a salvare
      let intervento_tmp = new Intervention(this.intervento_sel);

      intervento_tmp.interno = intervento_tmp.interno === false ? 0 : 1;
      intervento_tmp.preventivo = intervento_tmp.preventivo === false ? 0 : 1;

      // Se l'intervento è nuovo ne setto lo stato a "sospeso".
      if (intervento_tmp.id_intervento === -1) {
        intervento_tmp.id_stato = this.stato_sospeso_id;
      }
      //console.log("FORNITORI TMP:", fornitori_tmp);
      // Resetto i fornitori dell'intervento temporaneo di salvataggio
      intervento_tmp.fornitori = [];

      /* Valorizzo i fornitori dell'intervento temporaneo
       * con la lista dei fornitori da salvare. */
      intervento_tmp.fornitori = fornitori_tmp;

      // Assegno un id_fornitore_dettaglio a ogni fornitore
      intervento_tmp.fornitori.forEach((fornitore) => {
        // Se non c'è un id_anagrafica lo assegno in base all'id_fornitore
        if (fornitore.id_anagrafica === undefined) {
          fornitore.id_anagrafica = fornitore.id_fornitore;
        }

        // Se non c'è un id_fornitore_dettaglio lo inserisco
        if (fornitore.id_fornitore_dettaglio === undefined) {
          // Assegno un id_fornitore_dettaglio a -1
          fornitore.id_fornitore_dettaglio = -1;
          this.fornitori_confronto.forEach((confronto) => {
            /* Se il fornitore era già presente sostituisco il -1 con
             * l'id_fornitore_dettaglio debito. */
            if (confronto.id_fornitore === fornitore.id_fornitore) {
              fornitore.id_fornitore_dettaglio =
                confronto.id_fornitore_dettaglio;
            }
          });
        }

        // Assegno un id_intervento a ogni fornitore
        fornitore.id_intervento = intervento_tmp.id_intervento;
      });

      /* Di seguito faccio in modo che se un fornitore era presente
       * ed è stato deflaggato, gli metto la validità a 0. */
      // Per ogni fornitore originario nell'array di confronto...
      this.fornitori_confronto.forEach((confronto) => {
        // ... Faccio un semaforo per sapere se il fornitore ricorre...
        let ricorre = false;

        // ... E ciclo sull'array dei fornitori attuali...
        intervento_tmp.fornitori.forEach((fornitore) => {
          // Se il fornitore originario è ancora selezionato, accendo il semaforo
          if (confronto.id_fornitore == fornitore.id_fornitore) {
            ricorre = true;
          }
        });

        /* Se il fornitore originario non è più presente lo metto nell'array
         * dei fornitori da salvare, modificandogli la validità a 0. */
        if (ricorre === false) {
          let nuovo_fornitore = new Object();
          nuovo_fornitore = Object.assign({}, confronto);
          nuovo_fornitore.validita = 0;
          intervento_tmp.fornitori.push(nuovo_fornitore);
        }
      });
      //intervento_tmp.allegati = this.$refs.componenteAllegati.lista_allegati.filter(f=>f.tipo=="INTERVENTO");
      //Faccio un check sugli identificativi degli allegati
      intervento_tmp.allegati.forEach((allegato) => {
        if (allegato.id_allegato < 0) {
          allegato.id_allegato = -1;
        }
      });
      //console.log("intervento tmp allegati:", intervento_tmp.allegati);
      // Innesto le attività nell'intervento per il salvataggio
      intervento_tmp.attivita = this.$refs.dettaglio_attivita.items_attivita;
      //console.log("items attivita:", intervento_tmp.attivita);

      // Faccio un check sugli identificativi delle attività
      intervento_tmp.attivita.forEach((attivita) => {
        if (attivita.id_attivita < 0) {
          attivita.id_attivita = -1;
        }

        /* Per ogni attività, faccio un check sugli
         * identificativi dei suoi allegati. */
        if (attivita.allegati != undefined) {
          attivita.allegati.forEach((allegato) => {
            if (allegato.id_allegato < 0) {
              allegato.id_allegato = -1;
              allegato.id_riferimento = -1;
            }
          });
        }
        // delete attivita.lista_files;
      });
      /* Se l'intervento è nuovo e non ha fornitori,
       * gli assegno lo stato APERTO... */
      if (
        intervento_tmp.fornitori.length == 0 &&
        intervento_tmp.id_intervento === -1
      ) {
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.cod === "OPEN") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      } else if (
        /* Se invece è nuovo, ha già un fornitore ma non
         * ha una data schedulata, lo segno come Contattare Fornitore. */
        intervento_tmp.fornitori.length > 0 &&
        intervento_tmp.id_intervento === -1 &&
        intervento_tmp.data_schedulata === ""
      ) {
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.cod === "CONT_FOR") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      } else if (
        /* Se invece è nuovo, ha già un fornitore e una
         * data schedulata, lo segno come richiesto. */
        intervento_tmp.fornitori.length > 0 &&
        intervento_tmp.id_intervento === -1 &&
        intervento_tmp.data_schedulata != ""
      ) {
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.cod === "REQ") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      } else if (
        intervento_tmp.fornitori.length > 0 &&
        intervento_tmp.attivita.length === 0 &&
        intervento_tmp.cod_stato_operativo === "OPEN"
      ) {
        // ... Altrimenti se non è nuovo e ha lo stato OPEN gli assegno lo stato RICHIESTO
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.cod === "REQ") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      } else if (
        intervento_tmp.fornitori.length > 0 &&
        intervento_tmp.attivita.length > 0 &&
        intervento_tmp.cod_stato_operativo === "REQ"
      ) {
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.cod === "WIP") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      }

      /* Prima di mandare la request, se sono in Digital Marine,
       * aspetto che venga computata dal server una request preliminare
       * in cui si controlla che tutti gli ambiti manutentivi siano
       * associati a una regola. In caso non sia così, il server li associa
       * automaticamente a una regola catch-all, e questo permette che
       * qualsiasi ambito manutentivo l'utente generi in Digital Marine
       * la chiamata vada sempre a buon fine anche se l'utente non ha collegato
       * l'ambito manutentivo a una regola. */
      if (this.allow_update_ambiti_manutentivi_custom_dg_marine === true) {
        const checkCall = await this.updateRichiestaDgMarine();

        // Controllo che la chiamata sia andata a buon fine prima di continuare il salvataggio.
        if (checkCall === 0) {
          this.snackbar_intervento = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Si è verificata una criticità tecnica nella registrazione dell'ambito manutentivo e non è stato possibile salvare i dati.";
          this.snackbar_text_color = "white";
          return;
        }
      }

      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setintervento",
          token: localStorage.user_token,
          id_intervento: intervento_tmp.id_intervento,
          intervento: [intervento_tmp],
          id_fornitore:
            intervento_tmp.fornitori[0] != undefined
              ? intervento_tmp.fornitori[0].id_anagrafica
              : null,
          id_richiesta: intervento_tmp.id_richiesta,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            if (this.$refs.componenteAllegati) {
              // Salvo gli allegati dell'intervento.
              this.$refs.componenteAllegati.id_riferimento_creazione_genitore =
                res.data[0].id_intervento;
              this.$refs.componenteAllegati.salvaTuttiAllegati();
            }
            // console.log("INTERVENTO TMP:", intervento_tmp);
            // Eseguo una setstatointervento.
            // let setstatointervento = {
            //   controller: "Manutenzione",
            //   method: "PUT",
            //   richiesta: {
            //     action: "setstatointervento",
            //     token: localStorage.user_token,
            //     id_intervento: intervento_tmp.id_intervento,
            //     id_stato_operativo: intervento_tmp.id_stato_operativo,
            //     cod_intervento: intervento_tmp.cod_intervento,
            //     id_fornitore:
            //       intervento_tmp.fornitori[0] != undefined
            //         ? intervento_tmp.fornitori[0].id_anagrafica
            //         : null,
            //     id_richiesta: intervento_tmp.id_richiesta,
            //   },
            // };

            // this.$store
            //   .dispatch("api", setstatointervento)
            //   .then((res) => {
            //     if (res.status == 200) {
            //       let nuovo_intervento = new Intervention();

            //       // Uso l'oggetto resettato per resettare intervento_sel
            //       this.intervento_sel = Object.assign({}, nuovo_intervento);

            //       /* Resetto le variabili di supporto che rappresentano
            //        * i campi geografici dell'intervento selezionato. */
            //       this.des_sede_intervento_sel = "";
            //       this.des_struttura_intervento_sel = "";
            //       this.des_area_intervento_sel = "";
            //       this.des_zona_intervento_sel = "";
            //       this.des_stanza_intervento_sel = "";
            //       this.des_macchina_interento_sel = "";
            //       this.id_sede_intervento_sel = null;
            //       this.id_struttura_intervento_sel = null;
            //       this.id_area_intervento_sel = null;
            //       this.id_zona_intervento_sel = null;
            //       this.id_stanza_intervento_sel = null;
            //       this.id_macchinario_intervento_sel = null;
            //       this.attivita_intervento = [];

            //       // Resetto la lista dei fornitori passibili di incarico
            //       this.fornitore_lista = [];

            //       // Resetto il fornitore selezionato
            //       this.fornitore_sel = null;

            //       // Chiudo il dialog di modifica dell'intervento
            this.modifica_intervento_visible = false;

            //       // Ricarico la tabella generale delle richieste di intervento
            this.getValidazioneRichiesteList();
            //     }
            //   })
            //   .catch((ex) => {
            //     if (
            //       ex.response.status == 404 ||
            //       ex.response.status == undefined
            //     ) {
            //       // console.log("EX:", ex.response);
            //       this.snackbar_intervento = true;
            //       this.snackbar_background = "error";
            //       this.snackbar_text_color = "white";
            //       this.snackbar_icon = "mdi-close-outline";
            //       this.snackbar_text = ex.response.data.result;
            //       this.snackbar_icon_color = "white";
            //     }
            //   });
          }
        })
        .catch((ex) => {
          this.snackbar_intervento = true;
          this.snackbar_background = "error";
          this.snackbar_text_color = "white";
          this.snackbar_icon = "mdi-close-outline";
          this.snackbar_text = ex;
          this.snackbar_icon_color = "white";
        });
    },
    setStatoIntervento() {
      console.log(JSON.stringify(this.intervento_sel));
      // Recupero l'id_stato del Consuntivo Fornitore
      let id_tmp = null;
      this.stato_intervento_lista.forEach((elem) => {
        if (elem.des === "CONSUNTIVO FORNITORE") {
          id_tmp = elem.id_tabella;
        }
      });

      console.log(JSON.stringify(this.intervento_sel));
      let setstatointervento = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setstatointervento",
          token: localStorage.user_token,
          id_intervento: this.intervento_sel.id_intervento,
          id_stato_operativo: id_tmp,
          cod_intervento: this.intervento_sel.cod_intervento,
          id_fornitore: this.intervento_sel.fornitori[0].id_anagrafica
            ? this.intervento_sel.fornitori[0].id_anagrafica
            : this.intervento_sel.fornitori[0].id_fornitore,
          id_richiesta: this.intervento_sel.id_richiesta,
        },
      };

      this.$store.dispatch("api", setstatointervento).then((res) => {
        if (res.status === 200) {
          this.getValidazioneRichiesteList();
        } else {
          // Se il salvataggio non ha avuto successo, stampo una snackbar di errore.
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Errore tecnico, non è stato possibile validare il consuntivo.";
          this.snackbar_text_color = "white";
        }
      });
    },
    setStatoRichiesta(
      arg_id_richiesta,
      arg_id_stato_richiesta,
      arg_id_stato_richiesta_2
    ) {
      this.caricamento_dati = true;

      // Preparo la chiamata
      let setstatorichiesta = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setstatorichiesta",
          token: localStorage.user_token,
          richiesta: [
            {
              id_richiesta: arg_id_richiesta,
              id_stato: arg_id_stato_richiesta,
              id_stato_2: arg_id_stato_richiesta_2,
            },
          ],
        },
      };

      this.$store.dispatch("api", setstatorichiesta).then((res) => {
        if (res.status === 200) {
          this.getValidazioneRichiesteList();
        } else {
          // Fermo l'overlay di caricamento
          this.caricamento_dati = false;

          // Se il salvataggio non ha avuto successo, stampo una snackbar di errore.
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Errore tecnico, non è stato possibile effettuare la modifica.";
          this.snackbar_text_color = "white";
        }
      });
    },
    setStepIntervento() {
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setstepintervento",
          token: localStorage.user_token,
          id_intervento: this.intervento_sel.id_intervento,
          steps: this.steps_sel,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Chiudo il dialog degli Step
          this.dialog_step_visible = false;

          // Chiudo il drill down
          this.expanded_steps = [];

          // Resetto gli step selezionati
          this.steps_sel = [];

          // Aggiorno i dati della tabella generale
          this.getValidazioneRichiesteList();
        }
      });
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    updateRichiestaDgMarine() {
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "updaterichiestadgmarine",
          token: localStorage.user_token,
          ambiti_manutentivi: this.ambito_manutentivo_lista,
        },
      };

      return this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Ritorno 1 se la chiamata è andata a buon fine.
          return 1;
        } else {
          // Ritorno 0 se la chiamata non è andata a buon fine.
          return 0;
        }
      });
    },
    validaConsuntivo() {
      this.salvaModificaConsuntivi(this.setStatoIntervento());
    },
  },
  created() {},
  mounted() {
    // Resetto gli headers delle richieste
    this.headers_richieste = [];

    // Definisco i titoli delle colonne in base alle props.
    this.headers_richieste_scelti.forEach((hrs) => {
      this.headers_richieste.push(Object.assign({}, hrs));
    });

    /* Dopo averli resettati, definisco in base alle props
     * gli headers della tabella degli interventi. */
    this.headers_interventi = [];
    this.headers_interventi_scelti.forEach((his) => {
      this.headers_interventi.push(Object.assign({}, his));
    });
  },
};
</script>
<style scoped>
.bg-red {
  background: linear-gradient(
    90deg,
    rgba(97, 1, 1, 1) 0%,
    rgba(254, 0, 0, 1) 100%
  );
  color: white !important;
}
.sfondoStandard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 0%,
    rgba(63, 81, 181, 1) 100%
  );
}
</style>