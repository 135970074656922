import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";
import { store } from "../store";
// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyBtD-zJHfuHVVnfVhPwnTDOdsp16x8yNEw",
  authDomain: "quickviewsafety.firebaseapp.com",
  databaseURL: "https://quickviewsafety.firebaseio.com",
  projectId: "quickviewsafety",
  storageBucket: "quickviewsafety.appspot.com",
  messagingSenderId: "177818110027",
  appId: "1:177818110027:web:963dcc516541084cb425ce",
  measurementId: "G-7QQVZ15M85",
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
// const message = firebase.messaging();

// if (firebase.messaging.isSupported()) {
//   const messaging = firebase.messaging();
//   const key = ""
//   messaging.usePublicVapidKey(key);

//   messaging.requestPermission().then(() => {
//       messaging.getToken().then((token) => {
//           localStorage.setItem('notificationToken', token);
//           if (localStorage.getItem('notificationToken')) {
//               // console.log('');
//           } else {
//               let notification = localStorage.getItem('notificationToken');
//               const userData = JSON.stringify({
//                   deviceId: uuid,
//                   googleAdvertisingId: '',
//                   pushId: notification,
//                   platform: 3
//               });
//           }
//       })
//   }).catch();
//   } else {
//   // console.log('no supported');
//   }

// messaging.onMessage(response => {
// console.log('response: ', response);
// });
// utils

firebase.auth().onAuthStateChanged((user) => {
  // console.log("firebase us", user);
  store.dispatch("fetchUser", user);
});
// collection references

// export utils/refs
export { firebase, db, auth, storage };
