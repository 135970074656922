<template>
  <v-container fluid style="padding: 20px">
    <v-row>
      <v-col md="12" class="py-0">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-data-table
                class="elevation-1"
                :headers="headers.filter((el) => el.visible == 1)"
                :items="lista"
                :search="search"
                fixed-header
                height="70vh"
              >
                <template v-slot:top>
                  <v-toolbar
                    class="mb-2 white--text rounded-t"
                    color="indigo"
                    dense
                  >
                    <v-toolbar-title class="white--text"
                      >Lista Ambiti Aziende</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="createAmbito"
                          small
                        >
                          <v-icon color="success">mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Crea Ambito</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="setCompetenze"
                          small
                          class="ml-2"
                        >
                          <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>
                      </template>
                      <span>Salva</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="gotoDashboard"
                          small
                        >
                          <v-icon color="error">mdi-window-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Vai alla Dashboard</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>

                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      v-for="(col, columnIndex) in headers.filter(
                        (el) => el.visible == 1
                      )"
                      :key="columnIndex"
                    >
                      <div
                        v-if="columnIndex > 0"
                        :style="{
                          textAlign: 'left',
                        }"
                      >
                        <v-icon
                          v-if="item[col.value] == 1"
                          @click="item[col.value] = 0"
                          >mdi-check-underline</v-icon
                        >
                        <v-icon
                          v-else
                          @click="item[col.value] = 1"
                          color="white"
                          >mdi-close</v-icon
                        >
                      </div>

                      <div
                        :style="{
                          textAlign: columnIndex === 1 ? 'center' : 'left',
                        }"
                        v-else
                      >
                        {{ item[col.value] }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_ambiti">
      <v-card>
        <v-toolbar dense color="indigo" dark>
          <v-row align="end" dark>
            <v-col cols="12" align="right">
              <v-btn small light @click="getCompetenze();dialog_ambiti = false">
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <v-container class="mt-4">
            <v-row>
              <v-col sm="3" cols="12" class="py-0">
                <v-autocomplete
                  dense
                  v-model="id_azienda"
                  :items="lista"
                  item-value="id_azienda"
                  item-text="ragione_sociale"
                  placeholder="Digita per cercare"
                  @input="id_ambito = null"
                  label="Azienda"
                >
                </v-autocomplete>
              </v-col>
              <v-col sm="3" cols="12" class="py-0">
                <v-autocomplete
                  dense
                  v-model="id_ambito"
                  :items="ambiti.filter((el) => el.id_azienda == id_azienda)"
                  item-value="id_tabella"
                  item-text="des"
                  placeholder="Digita per cercare"
                  :disabled="id_azienda == null || id_azienda == -1"
                  @input="id_progetto = null"
                  label="Ambito"
                >
                  <template #label>
                    <span class="">Ambito</span>
                    <a @click.stop style="pointer-events: all">
                      <Modules
                        v-on:dialogModules="emitModules"
                        :assistenza="1"
                        tipo="AMBITO"
                        nome="Ambito"
                        setaction="setambitobyazienda"
                        getaction="getambitibyazienda"
                        :id_azienda="id_azienda.toString()"
                        controller="Global"
                        filter="validita = 1"
                        :dati="[{ des: '' }, { id_azienda: id_azienda }]"
                      />
                    </a>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="3" cols="12" class="py-0">
                <v-autocomplete
                  dense
                  v-model="id_progetto"
                  :items="progetti.filter((el) => el.val_int == id_ambito)"
                  item-value="id_tabella"
                  item-text="des"
                  placeholder="Digita per cercare"
                  :disabled="id_ambito == null"
                  @input="id_modulo = null"
                >
                  <template #label>
                    <span class="">Progetto</span>
                    <a @click.stop style="pointer-events: all">
                      <Modules
                        v-on:dialogModules="emitModules"
                        :assistenza="1"
                        tipo="PROGETTO"
                        nome="Progetto"
                        setaction="settabella"
                        getaction="gettabella"
                        controller="Global"
                        :filter="
                          '(val_int = ' + id_ambito + ') AND validita = 1'
                        "
                        :dati="[{ des: '' }, { val_int: id_ambito }]"
                      />
                    </a>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="3" cols="12" class="py-0">
                <v-autocomplete
                  dense
                  v-model="id_modulo"
                  :items="moduli.filter((el) => el.val_int == id_progetto)"
                  item-value="id_tabella"
                  item-text="des"
                  placeholder="Digita per cercare"
                  :disabled="id_progetto == null"
                >
                  <template #label>
                    <span class="">Modulo</span>
                    <a @click.stop style="pointer-events: all">
                      <Modules
                        v-on:dialogModules="emitModules"
                        :assistenza="1"
                        tipo="PROGETTO_MODULO"
                        nome="Modulo"
                        setaction="settabella"
                        getaction="gettabella"
                        controller="Global"
                        :filter="
                          '(val_int = ' + id_progetto + ') AND validita = 1'
                        "
                        :dati="[{ des: '' }, { val_int: id_progetto }]"
                      />
                    </a>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<style scoped>
label {
  color: white !important;
}
.v-label {
  color: white !important;
}
.v-input .v-label {
  color: white !important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: white !important;
}
</style>
<script>
import Modules from "@/components/TOOLS/Modules";
export default {
  name: "AmbitiTicket",
  data() {
    return {
      headers: [],
      lista: [],
      search: "",
      snackbar: false,
      snackbar_background: "success",
      snackbar_icon: "mdi-check",
      snackbar_icon_color: "white",
      snackbar_text: "Ambiti salvati correttamente",
      snackbar_text_color: "white",

      dialog_ambiti: false,
      id_ambito: null,
      id_progetto: null,
      id_modulo: null,
      id_azienda: -1,

      ambiti: [],
      progetti: [],
      moduli: [],
    };
  },
  components: {
    Modules,
  },
  mounted() {
    this.getCompetenze();
  },
  computed: {
    widthMin() {
      return window.innerWidth < 960 ? true : false;
    },
  },
  methods: {
    createAmbito() {
      this.id_ambito= null;
      this.id_progetto=  null;
      this.id_modulo=  null;
      this.id_azienda=  -1;
      this.emitModules();
      this.dialog_ambiti = true;
    },
    emitModules() {
      var requestAmbitiAssistenza = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getambitibyazienda",
          token: localStorage.user_token,
          filter: "validita = 1",
          chiedi_azienda: "0",
        },
      };
      this.$store.dispatch("api", requestAmbitiAssistenza).then((res) => {
        if (res.data != "") {
          if (res.data != undefined) {
            this.ambiti = res.data;
          }
        } else {
          this.ambiti = [];
        }
      });
      var requestProgetto = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          filter: "validita = 1",
          tipo: "PROGETTO",
          assistenza: 1,
        },
      };
      this.$store.dispatch("api", requestProgetto).then((res) => {
        if (res.data != "") {
          if (res.data != undefined) {
            this.progetti = res.data;
          }
        } else {
          this.progetti = [];
        }
      });

      var requestModuli = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          filter: "validita = 1",
          tipo: "PROGETTO_MODULO",
          assistenza: 1,
        },
      };
      this.$store.dispatch("api", requestModuli).then((res) => {
        if (res.data != "") {
          if (res.data != undefined) {
            this.moduli = res.data;
          }
        } else {
          this.moduli = [];
        }
      });
    },
    gotoDashboard() {
      this.$router.push("/home");
    },
    setCompetenze() {
      var setcompetenze = {
        controller: "Global",
        method: "PUT",
        richiesta: {
          action: "setambitiazienda",
          token: localStorage.user_token,
          tabella: this.lista,
        },
      };
      this.$store
        .dispatch("api", setcompetenze)
        .then((res) => {
          if (res.status == 200) {
            this.snackbar = true;
            this.getCompetenze();
          } else {
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_icon = "mdi-close";
            this.snackbar_icon_color = "white";
            this.snackbar_text = res.status + " " + res.statusText;
            this.snackbar_text_color = "white";
            this.getCompetenze();
          }
        })
        .catch((ex) => {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = ex;
          this.snackbar_text_color = "white";
          this.getCompetenze();
        });
    },
    getCompetenze() {
      var getprogetti = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getambitiazienda",
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", getprogetti).then((res) => {
        this.lista = res.data.tabella_aziende;
        this.headers = res.data.header;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.v-data-table > .v-data-table__wrapper > table {
  min-height: 100vh;
  // max-height: 100vh;
}
.v-data-table__wrapper {
  max-height: 70vh !important;
  overflow-y: visible;
}
</style>