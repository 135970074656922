var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.questionari.filter(function (e) { return e.validita === 1 && e.id_articolo === null; }),"item-key":"nome","height":"74vh","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"z-index":"3"},attrs:{"color":"indigo","dense":"","fixed":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Lista Controlli")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.aggiungiNuovoControllo()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Elemento")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaControllo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])])]}},{key:"item.elimina",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickEliminaQuestionario(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}},{key:"item.date_ins",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_ins && item.date_ins.length > 0 ? ((item.date_ins.substring(8, 10)) + "/" + (item.date_ins.substring( 5, 7 )) + "/" + (item.date_ins.substring(0, 4))) : "N/A")+" ")]}}],null,true)}),_c('v-dialog',{model:{value:(_vm.specifiche_controllo),callback:function ($$v) {_vm.specifiche_controllo=$$v},expression:"specifiche_controllo"}},[_c('ListaSpecifiche',{key:_vm.key_lista_specifiche,attrs:{"des_domanda_descrittiva":_vm.des_domanda_descrittiva,"des_domanda_immagine":_vm.des_domanda_immagine,"des_domanda_polare":_vm.des_domanda_polare,"id_domanda_descrittiva":_vm.id_domanda_descrittiva,"id_domanda_immagine":_vm.id_domanda_immagine,"id_domanda_polare":_vm.id_domanda_polare,"questionario_sel":_vm.questionario_sel},on:{"click_chiudi":_vm.chiudiModificaControllo,"eliminazione_effettuata":_vm.handleEliminazioneEffetuata,"salvato_questionario":_vm.handleSalvatoQuestionario}})],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_eliminazione_dialog),callback:function ($$v) {_vm.conferma_eliminazione_dialog=$$v},expression:"conferma_eliminazione_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Eliminazione")])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"overflow-y":"auto"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" Confermi l'eliminazione del Questionario di Collaudo selezionato? ")]),_c('v-divider',{staticClass:"my-5"}),_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.conferma_eliminazione_dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Annulla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.eliminaQuestionario}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])])],1)],1)],1)],1)],1)],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }