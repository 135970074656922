<template>
    <v-dialog v-model="show" width="600px" persistent>
        <div class="graph_select" v-if="show==true" :data-multiselect="multiselect">
            <header>
                <h1>{{value[index].descrizione}}</h1>
                <GraphSVG name="close" @click="hide"  />
            </header>
            <main>
                <div>
                    <input v-model="filter" placeholder="Filtra risultati" /> 
                    <div>
                        <span @click="setAll(true)">
                            <GraphSVG name="check" />
                            <span>Seleziona tutti</span>
                        </span>  
                        <span @click="setAll(false)">
                            <GraphSVG name="close" />
                            <span>Deseleziona tutti</span>
                        </span>                       
                    </div>                
                </div>
                <article class="graph_scrollbar_input">
                    <template v-for="(item,i) in filterData(filter, key)" >
                        <div :key="i" v-if="multiselect">
                            <input type="checkbox" :id="'graph_overall_select_'+i" v-model="item.checked"/>
                            <label :for="'graph_overall_select_'+i">
                                <GraphSVG name="check" />
                            </label>
                            <span>{{getText(item)}}</span>
                        </div>
                        <div :key="i" v-else class="graph_overall_select_single" @click="select(item)">
                            <span >{{getText(item)}}</span>
                        </div>
                    </template>                    
                </article>                
            </main>
            <footer>
                <span @click="save">APPLICA</span>
            </footer>           
        </div>
    </v-dialog>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name : 'GraphSelect',
    props : { value : Array },
    components : {
        GraphSVG
    },
    data(){
        return{
            backup : null,
            show : false,
            index : 0,
            filter : '',
            key : 0,
            multiselect : false,
        }        
    },
    mounted(){
        document.body.addEventListener('graph_select',this.showDialog);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_select',this.showDialog);
    },
    methods : {
        showDialog(event){    
            this.index = event.detail.index;  
            this.multiselect = this.value[this.index].assi == '2';
            const list = this.value[this.index];
            const src = list.dati;
            this.backup = (list.layout == null) ? '' : list.layout;
            const selected = this.backup.split(',');

            let is_string = false;
            for(let i = 0; i < src.length; i++){ is_string |= isNaN(src[i].col_0); }
            for(let i = 0; i < src.length; i++){
                const datum = is_string ? `'${src[i].col_0.replaceAll("'",'’')}'` : src[i].col_0;
                src[i].checked = selected.indexOf(datum) >= 0;
            }          
            this.show = true;
            this.filter = null;
        },
        hide(){
            this.value[this.index].layout = this.backup;
            this.show = false;
        },
        save(){
            const list = this.value[this.index];
            const src = list.dati;
            const selected = new Array();
            const selected_template = new Array();
            let is_string = false;
            for(let i = 0; i < src.length; i++){ is_string |= isNaN(src[i].col_0); }
            for(let i = 0; i < src.length; i++){
                if(src[i].checked == true){
                    const datum = is_string ? `'${src[i].col_0.replaceAll("'",'’')}'` : src[i].col_0;
                    selected.push(datum);
                    selected_template.push(src[i].col_1);
                }
            }
            this.value[this.index].layout = selected.join(',');
            this.value[this.index].template = selected_template.join(',');
            this.show = false;
        },
        select(item){
            const is_string = isNaN(item.col_0);
            this.value[this.index].layout = is_string ? `'${item.col_0.replaceAll("'",'’')}'` : item.col_0;
            this.value[this.index].template = item.col_1;
            this.show = false;
        },
        filterData(filter){            
            const src = this.value[this.index].dati;
            if(filter==''||filter==null){ return src; }
            const result = new Array();
            const n_filter = filter.toLowerCase();
            let i;

            for(i=0;i<src.length;i++){
                let field = this.getText(src[i]);
                if(field == null || field == undefined){continue;}
                if(field.toLowerCase().indexOf(n_filter)>=0){
                    result.push(src[i]);
                    continue; 
                }
            }
            return result;
        },
        getText(item){
            let text = '';
            let col = item.col_1;
            if(col!=null&&col!=undefined){text = col;}
            col = item.col_2;
            if(col!=null&&col!=undefined){
                if(text==''){
                    text = col;
                }else{
                    text += ' - ' + col;
                }
            }
            return text;
        },
        setAll(status){
            const src = this.value[this.index].dati;
            for(let i = 0; i < src.length; i++){           
                src[i].checked = status;
            }
            this.key++;
        }
    }
}
</script>
<style scoped>
.graph_select{
    background-color: var(--widget_body_background);
    position: relative;
    width:100%;
    height:90vh;
    overflow: hidden;
    user-select: none;
}
.graph_select > header{
    width:100%;
    background-color: var(--widget_title_background);
    color : var(--widget_title_text_color); 
    position: relative; 
}
.graph_select > header > h1{
    line-height: 40px;
    height:40px;
    font-size: 18px;
    font-weight : normal;
    text-align: left;
    width:calc(100% - 10px);
    margin : 0 10px;
}
.graph_select > header > svg{
    position: absolute;
    height:40px;
    right:10px;
    top:0px;
    cursor:pointer;
}
.graph_select > main{
    width:100%;
    position: relative;
    height:calc(100% - 40px);
}
.graph_select[data-multiselect] > main { height:calc(100% - 80px); }
.graph_select > main > div{
    position: relative;
    width:100%;
    padding:10px 0 5px;
    text-align:center;
    border-bottom:1px solid var(--widget_title_background);
    margin-bottom:2px;
}
.graph_select > main > div > input{
    outline:none!important;
    width: calc(100% - 30px);
    margin:0 auto;
    border-radius: 0;
    padding: 5px;
}

.graph_select > main > div > div{
    width:100%;
    height:20px;
    line-height: 20px;
    display: none;
}
.graph_select[data-multiselect] > main > div > div {display: block;}
.graph_select > main > div > div > span{
    display: inline-block;
    vertical-align: middle;
    line-height:15px;
    margin: 0 10px;
    cursor: pointer;
}
.graph_select > main > div > div > span:hover{
    color : var(--widget_title_background)
}
.graph_select > main > div > div > span > *{
    display: inline-block;
    vertical-align: middle;
    height: 15px;
    font-size: 15px;
    padding:0;
    margin: 0 2px;
}
.graph_select > main > article{
    position: relative;
    max-height: calc(100% - 55px);
    overflow: auto;
}
.graph_select[data-multiselect] > main > article{ max-height: calc(100% - 75px); }
.graph_select > main > article > div{
    text-align:left;
    padding: 5px 20px;
}
.graph_select > main > article > div{
    text-align:left;
    padding: 5px 20px;
}

.graph_select > main > article > div.graph_overall_select_single:hover{
    cursor: pointer;
    background-color: var(--widget_settings_hover);
}

.graph_select > main > article > div > input{ display:none; }

.graph_select > main > article > div > label{
    display: inline-block;
    vertical-align: middle;
    height:15px;
    width:15px;
    border: 1px solid grey;
    border-radius: 2px;
    margin-right:10px;
    padding:0;
    cursor: pointer;
}

.graph_select > main > article > div > label > svg{
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    top:0;
    position: relative;
    color:white;
    display: none;
}

.graph_select > main > article > div > input:checked + label{
    background-color: var(--widget_title_background);
}

.graph_select > main > article > div > input:checked + label > svg{
    display: block;
}
.graph_select > main > article > div > span{
    display: inline-block;
    vertical-align: middle;
    margin:0;
    padding:0;
    line-height: 15px;
    height:15px;
}

.graph_select > footer{
    position: relative;
    height:40px;
    width:100%;
    padding:0 20px;
    border-top: 1px solid var(--widget_title_background);
    background-color: white;
    text-align: right;
    display:none;
}

.graph_select[data-multiselect] > footer{ display: block; }

.graph_select > footer > span{
    display: inline-block;
    height:100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    color: var(--widget_title_background);
    cursor:pointer;
}


</style>