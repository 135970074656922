<template >
  <div class="ml-3 mr-2">
    <Dash v-if="utente==1 || utente===3" :nome_utente_sel="nome_utente_sel" />
    <MainDash
      v-on:selezionaUtente="selezionaUtente"
      v-if="(utente==1 || utente===4 || utente ===7 || utente ===8) && utente_sel ==null"
    />
    <Dash
      v-on:indietro="indietro"
      :nome_utente_sel="nome_utente_sel"
      :utente_sel="utente_sel"
      v-if="utente_sel != null && utente != 7   && utente != 9"
    />
    <Azienda v-if="utente==1 || utente == 9 " />
  </div>
</template>
<style scoped>
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 8px;
}
.colore_card_utente {
  background: linear-gradient(
    118deg,
    rgba(92, 107, 192, 1),
    rgba(123, 136, 212, 0.7)
  ) !important;
}
</style>
<script>
import Dash from "../../components/MEDICAL/Dash";
import MainDash from "../../components/MEDICAL/MainDash";
import Azienda from "../../views/MEDICAL/Aziende";
export default {
  components: {
    Dash,
    MainDash,
    Azienda,
  },
  mounted() {
    this.getDatiUtente();
  },
  data: () => ({
    utente: null,
    utente_sel: null,
    nome_utente_sel: localStorage.nominativo,
  }),
  methods: {
    indietro() {
      console.log();
      this.utente_sel = null;
      localStorage.removeItem("id_user_sel");
      localStorage.removeItem("nominativo_sel");
      localStorage.removeItem("arrivo");
    },
    selezionaUtente(item) {
      if (this.utente != 7 && this.utente != 8) {
        console.log(item);
        this.utente_sel = item.id_utente;
        this.nome_utente_sel = item.nominativo;
        localStorage.setItem("id_user_sel", item.id_utente);
        localStorage.setItem("nominativo_sel", item.nominativo);
        localStorage.setItem("arrivo", "dash");
      }
    },
    getDatiUtente() {
      if (localStorage.id_user_sel != undefined) {
        this.utente_sel = localStorage.id_user_sel;
        this.nome_utente_sel = localStorage.nominativo_sel;
      }
      this.utente = parseInt(localStorage.id_profilo);
      console.log(this.utente);
    },
  },
};
</script>