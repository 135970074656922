var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.messages),function(message){return _c('ul',{key:message.id},[(message.idFrom == _vm.utente_log)?_c('li',{staticClass:"clearfix"},[_c('div',{staticClass:"message-data align-right"},[(message.stato==0)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-all")]):_c('v-icon',[_vm._v("mdi-check")]),_c('span',{staticClass:"message-data-time"},[_vm._v(_vm._s(_vm._f("timestampToHour")(message.timestamp)))]),_vm._v("     "),_c('span',{staticClass:"message-data-name"},[_vm._v("| Tu")]),_c('i',{staticClass:"fa fa-circle me"})],1),(message.type==0)?_c('div',{staticClass:"message other-message float-right",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(message.content)}}):_vm._e(),(message.type==1)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"message other-message float-right",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openImg(message)}}},[_c('img',{staticStyle:{"max-height":"300px","max-width":"250px"},attrs:{"aspect-ratio":"1","cover":"","src":message.content}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":function($event){_vm.dialogImmagini == true}}},[_vm._v("Visualizza")])],1):_vm._e()],1)],1)]}}],null,true)}):_vm._e(),(message.type==2)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"message other-message float-right",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadFile(message)}}},[_c('div',[_vm._v(_vm._s(message.filename))]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":function($event){_vm.dialogImmagini == true}}},[_vm._v("Scarica")])],1):_vm._e()],1)],1)]}}],null,true)}):_vm._e()],1):_c('li',[_c('div',{staticClass:"message-data"},[_c('span',{staticClass:"message-data-name"},[_c('i',{staticClass:"fa fa-circle online"}),(_vm.gruppo_chat== null)?_c('span',[_vm._v(_vm._s(_vm.utente_chat.nickname))]):_c('span',[_vm._v(_vm._s(message.nickname))]),_vm._v(" | ")]),_c('span',{staticClass:"message-data-time"},[_vm._v(_vm._s(_vm._f("timestampToHour")(message.timestamp)))])]),(message.type==0)?_c('div',{staticClass:"message my-message",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(message.content)}}):_vm._e(),(message.type==1)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"message my-message",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openImg(message)}}},[_c('img',{staticStyle:{"max-height":"300px","max-width":"250px"},attrs:{"aspect-ratio":"1","cover":"","src":message.content}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":function($event){_vm.dialogImmagini == true}}},[_vm._v("Visualizza")])],1):_vm._e()],1)],1)]}}],null,true)}):_vm._e(),(message.type==2)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"message my-message",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadFile(message)}}},[_c('div',[_vm._v(_vm._s(message.filename))]),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',{on:{"click":function($event){_vm.dialogImmagini == true}}},[_vm._v("Scarica")])],1):_vm._e()],1)],1)]}}],null,true)}):_vm._e()],1)])}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogImmagini),callback:function ($$v) {_vm.dialogImmagini=$$v},expression:"dialogImmagini"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogImmagini = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Galleria")]),_c('v-spacer')],1),_c('v-zoomer-gallery',{staticStyle:{"width":"100vw","height":"93vh"},attrs:{"list":_vm.immagini_chat},model:{value:(_vm.selIndex),callback:function ($$v) {_vm.selIndex=$$v},expression:"selIndex"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }