<template>
    <v-dialog v-model="SHOW">
        <div class="graph_table">
            <header>
                <h1>{{tabella.titolo}} - Dati</h1>
                <GraphSVG name="close" @click="SHOW=false"  />
                <GraphSVG name="csv" @click="getCSV"  />                
            </header>
            <main class="graph_scrollbar_input">
                <table>
                    <thead>
                        <tr>
                            <th v-for="(name,i) in tabella.testata" :key="i">{{name}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row,i) in tabella.dati" :key="i">
                            <td v-for="(cell,j) in row" :key="j">{{cell}}</td>
                        </tr>
                    </tbody>
                </table> 
            </main>
        </div>
    </v-dialog>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name: "GraphTabella",
    components : {
        GraphSVG
    },
    data(){
        return{
            SHOW : false,
            tabella : {
                testata : [],
                titolo : null,
                dati : [],
            }
        }
    },
    mounted(){
        document.body.addEventListener('graph_table',this.show_table);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_table',this.show_table);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        getCSV(){
            let CSV = '';
            for(let i=0;i<this.tabella.testata.length;i++){
                let datum = String(this.tabella.testata[i]);
                CSV+='"'+ datum.replaceAll('"',"''")+'";';
            }
            CSV+='\n';
            for(let i=0;i<this.tabella.dati.length;i++){
                for(let j=0;j<this.tabella.dati[i].length;j++){
                    let datum = String(this.tabella.dati[i][j]);
                    CSV+='"'+ datum.replaceAll('"',"''")+'";';
                }
                CSV+='\n';
            }
            let _blob = new Blob([CSV], { type: "text/plain" });
            let url = window.URL.createObjectURL(_blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = this.tabella.titolo + " - Dati.csv";
            a.click();
        },
        show_table(data){
            this.tabella.dati = [];
            this.tabella.testata = [];
            data = data.detail;
            this.tabella.titolo = data.nome;
            for(let i = 0; i < data.serie.length; i++){
                this.tabella.testata.push(data.serie[i].nome);
                this.tabella.dati.push(data.serie[i].serie);
            }
            this.tabella.dati = this.transpose(this.tabella.dati);
            this.SHOW = true;
        },
        // Inversione righe / colonne
        transpose(data){
            if(data.length==0){return [[]];}
            if(data[0].length==0){return [[]];}
            let sorted = [];
            for(let j=0;j<data[0].length;j++){
                let item = [];
                for(let i=0;i<data.length;i++){
                    item.push(data[i][j]);
                }
                sorted.push(item);
            }
            return sorted;
        }        
    }
}
</script>
<style src="./Tabella.css" scoped></style>

