<template>
  <v-card>
     <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
      <v-row class="pa-3">
        <v-col cols="3">
            <v-text-field
            outlined
            label="Filtra tabella"
            v-model="search"
            append-icon="mdi-magnify"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">

          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            ><v-toolbar-title>Lavori in uscita</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="conto_lavoro_uscita"
            :search="search"
            :items-per-page="10"
            class="elevation-3"
          >
            <template v-slot:item.seleziona="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined small v-bind="attrs" v-on="on" @click="dettaglio(item)">
                        <v-icon color="success"
                        >mdi-check-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Seleziona articolo per Bolla</span>
            </v-tooltip>            
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" v-if="bolla_in_preparazione">
          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            ><v-toolbar-title>Bolla in preparazione per {{ this.ragione_sociale_selected}} </v-toolbar-title>
            <v-spacer></v-spacer> 
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn light v-bind="attrs" v-on="on" class="" @click="salvaBolla" >
                  <v-icon color="blue" >mdi-content-save-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Salva Bolla</span>
            </v-tooltip>

          </v-toolbar>
          <v-data-table
            :headers="headers_bolla"
            :items="bolla"
            :items-per-page="10"
            class="elevation-3"
          >
          <template v-slot:item.scarto="{ item }">
            <v-checkbox
              v-model="item.scarto"
              readonly
            ></v-checkbox>
          </template> 

            <template v-slot:item.seleziona="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined small v-bind="attrs" v-on="on" @click="modifica_bolla(item)">
                        <v-icon color="success"
                        >mdi-check-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Modifica Bolla</span>
            </v-tooltip>            
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItemBolla(item)">
                        <v-icon color="red"
                        >mdi-delete
                        </v-icon>
                    </v-btn>
                </template>
                <span>Cancella articolo da bolla</span>
            </v-tooltip>
            </template>
          </v-data-table>
        </v-col>


      </v-row>

      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-dialog v-model="dialog_dettaglio_conto_lavoro_uscita" width="1000" persistent>
        <v-toolbar
          dark
          color="#3F51B5"
          style="border-radius: 5px 5px 0px 0px"
          class="elevation-0"
          ><v-toolbar-title>Dettaglio conto lavoro uscita per conto di {{ this.ragione_sociale_selected}}  </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
          light
            @click="dialog_dettaglio_conto_lavoro_uscita = false"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="cod_lotto_selected"
                  label="Lotto"
                ></v-text-field>
              </v-col>  
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="codMP_selected"
                  label="Codice M.P."
                ></v-text-field>
              </v-col>  
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="lottoMP_selected"
                  label="Lotto M.P."
                ></v-text-field>
              </v-col>  


              <v-col cols="6">
                <v-text-field dense label="Fase di lavorazione" v-model="fase_lavorazione_selected" outlined readonly>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  v-model="id_contenitore_selected"
                  :items="contenitori"
                  dense
                      item-text="des_imballo"
                      item-value="id_articolo"
                  label="Contenitori"
                  @change="cambiaSelezioneContenitore"
                ></v-autocomplete>
              </v-col>    

              <v-col cols="3">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  v-model="peso_unitario_selected"
                  min="0"
                  label="Peso unitario"
                  readonly
                ></v-text-field>
              </v-col>   
              <v-col cols="3">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  v-model="quantita_colli_selected"
                  min="0"
                  label="Quantita colli"
                ></v-text-field>
              </v-col>  
              <v-col cols="1">
                <v-btn
                  class="mr-2"
                  @click="calcolaPeso()"
                  ><v-icon color="#3F51B5">mdi-weight</v-icon></v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  v-model="quantita_pezzi_selected"
                  min="0"
                  label="Quantità pezzi"
                ></v-text-field>
              </v-col>   
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-container>
              <v-btn
                color="success"
                dark
                @click="predisponi_bolla"
                >conferma</v-btn
              >
            </v-container>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_modifica_bolla_conto_lavoro_uscita" width="1000" persistent>
        <v-toolbar
          dark
          color="#3F51B5"
          style="border-radius: 5px 5px 0px 0px"
          class="elevation-0"
          ><v-toolbar-title>Dettaglio conto lavoro uscita per conto di {{ this.ragione_sociale_selected}}  </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
          light
            @click="dialog_modifica_bolla_conto_lavoro_uscita = false"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row>

             <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="cod_lotto_selected"
                  label="Lotto"
                ></v-text-field>
              </v-col>  
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="codMP_selected"
                  label="Codice M.P."
                ></v-text-field>
              </v-col>  
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="lottoMP_selected"
                  label="Lotto M.P."
                ></v-text-field>
              </v-col> 



              <v-col cols="6">
                <v-text-field dense label="Fase di lavorazione" v-model="fase_lavorazione_selected" outlined readonly>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  v-model="id_contenitore_selected"
                  :items="contenitori"
                  dense
                      item-text="des_imballo"
                      item-value="id_articolo"
                  label="Contenitori"
                  @change="cambiaSelezioneContenitore"
                ></v-autocomplete>
              </v-col>    

              <v-col cols="3">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  v-model="peso_unitario_selected"
                  min="0"
                  label="Peso unitario"
                  readonly
                ></v-text-field>
              </v-col>   
              <v-col cols="3">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  v-model="quantita_colli_selected"
                  min="0"
                  label="Quantita colli"
                ></v-text-field>
              </v-col>   
              <v-col cols="1">
                <v-btn
                  class="mr-2"
                  @click="calcolaPeso()"
                  ><v-icon color="#3F51B5">mdi-weight</v-icon></v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  v-model="quantita_pezzi_selected"
                  min="0"
                  label="Quantità pezzi"
                ></v-text-field>
              </v-col> 
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-container>
              <v-btn>
                color="success"
                dark
                @click="salva_modifica_bolla()"
                >conferma</v-btn>
            </v-container>

          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-card>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",
      
      search: "",
      overlay: false,

      dialog_dettaglio_conto_lavoro_uscita:false,
      dialog_modifica_bolla_conto_lavoro_uscita: false,
      bolla_in_preparazione: false,

      contenitori:[],

      headers: [
        { text: "Cod articolo", value: "cod_articolo" },
        { text: "Lotto", value: "cod_lotto" },
        { text: "Codice MP", value: "cod_materiaprima" },
        { text: "Lotto MP", value: "lotto_materiaprima" },
        { text: "Commessa", value: "cod_commessa" },
        { text: "Ragione sociale", value: "ragione_sociale" },
        { text: "Articolo", value: "des_articolo" },
        { text: "Fase lavorazione", value: "des_fase" },
        { text: "Quantità", value: "quantita" },
        { text: "Qta residua", value: "quantita_residua" },
        { text: "Qta pronta", value: "quantita_pronta" },
        { text: "Qta inviata", value: "quantita_invio" },
        { text: "Qta da Fornitore", value: "quantita_saldo" },
        { text: "Qta rientrata", value: "quantita_rientro" },
        { text: "Qta scarto", value: "quantita_scarto" },
        { text: "Seleziona", value: "seleziona" },
      ],
      conto_lavoro_uscita: [],

     headers_bolla: [
        { text: "Cod articolo", value: "cod_articolo" },
        { text: "Lotto", value: "cod_lotto" },
        { text: "Codice MP", value: "cod_materiaprima" },
        { text: "Lotto MP", value: "lotto_materiaprima" },        
        { text: "Commessa", value: "cod_commessa" },
        { text: "Qta selez", value: "num_pezzi", width: "5%" },
        { text: "Peso unitario", value: "peso_unitario" , width: "5%" },
        { text: "N.colli", value: "qta_colli" , width: "5%"},
        { text: "Fase lavorazione", value: "fase" },
        { text: "Contenitore", value: "contenitore" },
        { text: "Azione", value: "seleziona",width: "10%" },
      ],
      bolla:[],      
 
      id_commessa_dettaglio_selected:0,
      fase_lavorazione_selected: null,
      quantita_colli_selected: 0,
      quantita_pezzi_selected: 0,

      codMP_selected: null,
      lottoMP_selected: null ,
      id_commessa_associalotti_selected :0,


      peso_unitario_selected: 0,
      ragione_sociale_selected: null,
      cod_lotto_selected: null,
      cod_commessa_selected: null,
      id_articolo_selected: null,
      cod_articolo_selected: null,
      id_anagrafica_selected: null,
      scarto_selected: 0,

      id_contenitore_selected: 0,
      des_contenitore_selected: "",
      cod_contenitore_selected: "",
      
      id_commessa_selected: null,
      id_um_selected: null,
      id_lotto_selected: null,
      des_articolo_selected: null,      

      articolo_in_modifica_in_bolla: 0,
      check_qta_fornitore:0,

    };
  },
  props: {
    TRIGGER: Number,
  },
  watch: {
   TRIGGER: {
      immediate: true,
      handler() {
          this.getData();
      }
    },
    search: {
      immediate: true,
      handler() {
          if (this.search == "" ) this.bolla_in_preparazione = false
      }
    },

  },  
  mounted() {
      console.log ("componente conto lavoro uscita montato")
      this.create_db()
  },
  methods: {
    create_db(){
        //Autoincrement realizzato manualmente
        this.alasql(' CREATE TABLE IF NOT EXISTS tbl_bolle_uscita (id INT, id_articolo INT, id_commessa_associalotti INT,  cod_materiaprima STRING, lotto_materiaprima STRING, id_commessa_dettaglio INT, id_anagrafica INT, cod_articolo STRING, des_articolo STRING, id_lotto INT, cod_lotto STRING, id_commessa INT, cod_commessa STRING, num_pezzi INT, peso_unitario INT, qta_colli INT, id_um INT, fase STRING, contenitore STRING, cod_contenitore STRING, id_contenitore INT, scarto INT);');
    },

      getData() {
        this.overlay = true
        let request = {
          controller: "Logistica",
          method: "POST",
          richiesta: {
            action: "getcontolavoro",
            token: localStorage.getItem("user_token"),
            tipo: "U"
          },
        };

        this.conto_lavoro_uscita = []
        this.$store.dispatch("api", request).then((res) => {
          this.conto_lavoro_uscita = res.data.result.contolavoro
          this.search = ""
          this.bolla_in_preparazione = false   
          console.log ("conto_lavoro_uscita:", this.conto_lavoro_uscita )       
          this.overlay = false
        })
        .catch(e => {
            this.overlay = false
            console.log(e);
        });
    },

    dettaglio(item) {
      //Serve solo per visualizzare la tabella tbl_bolle_uscita
      let tabella_bolle = this.alasql("SELECT * FROM tbl_bolle_uscita;");
      console.log ("Tbl_bolle_uscita : ", tabella_bolle)

      this.ragione_sociale_selected = item.ragione_sociale;
      this.fase_lavorazione_selected = item.des_fase
      this.cod_lotto_selected = item.cod_lotto
      this.cod_commessa_selected = item.cod_commessa
      this.id_articolo_selected = item.id_articolo
      this.cod_articolo_selected = item.cod_articolo
      this.id_anagrafica_selected = item.id_anagrafica
      this.id_commessa_dettaglio_selected = item.id_commessa_dettaglio
      this.peso_unitario_selected = item.peso_unitario
      this.codMP_selected = item.cod_materiaprima
      this.lottoMP_selected = item.lotto_materiaprima
      this.id_commessa_associalotti_selected = item.id_commessa_associalotti

      this.id_commessa_selected = item.id_commessa
      this.id_um_selected = item.id_um
      this.id_lotto_selected = item.id_lotto
      this.des_articolo_selected = item.des_articolo
    
      this.check_qta_fornitore = item.quantita_saldo

      //verifico se ci sono già articoli selezionti per la bolla se si faccio vedere le bolle in preparazione
      let count_prod = this.alasql(
          "SELECT COUNT(*) as contatore FROM tbl_bolle_uscita Where cod_articolo = '" + this.cod_articolo_selected + "' and  cod_lotto = '" + this.cod_lotto_selected + "' and cod_commessa = '" + this.cod_commessa_selected + "' and  id_anagrafica = " + this.id_anagrafica_selected
      );
      if (count_prod[0].contatore > 0){
  
        this.bolla = this.alasql(
          "SELECT * FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
        );        
        
        this.search = this.ragione_sociale_selected
        this.bolla_in_preparazione = true
        this.dialog_dettaglio_conto_lavoro_uscita = true;
      }

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcontenitori",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_anagrafica: item.id_anagrafica,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          
          this.contenitori = [];
          res.data.forEach((e) => {
            this.contenitori.push(e);
          });
        
        }
      });

      this.quantita_colli_selected =  ""
      this.quantita_pezzi_selected = ""
      this.id_contenitore_selected =  ""
      this.scarto_selected = 0 
      this.dialog_dettaglio_conto_lavoro_uscita = true;
    },

    predisponi_bolla(){

      if (this.id_contenitore_selected == "") this.id_contenitore_selected = 0;
      if (this.quantita_pezzi_selected == "") this.quantita_pezzi_selected = 0;
      if (this.peso_unitario_selected == "") this.peso_unitario_selected = 0;
      if (this.quantita_colli_selected == "") this.quantita_colli_selected = 0;
      
      //this.check_qta_fornitore è la quantità massima di un articolo di un lotto presso il fornitore selezionato.
      /*
      let totale_selezionati = this.alasql(
          "SELECT SUM(num_pezzi) as Somma FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
      );
      
      if ( totale_selezionati[0].Somma + Number(this.quantita_pezzi_selected) > this.check_qta_fornitore){
          this.snackbar_color =  "red"
          this.snackbar_text =  "Attenzione quantità inserita maggiore di quantità presso fornitore.";
          this.snackbar = true
      }
      */
      // gestione auto increment id tbl_bolle_uscita--> aumento sempre di 1 il max id in tabella.
      let last_id = this.alasql(
          "SELECT max(id) as Max FROM tbl_bolle_uscita"
      );  

      let id_tbl_bolle = last_id[0].Max == null ? 1 : last_id[0].Max + 1

      this.alasql(
          "Insert INTO tbl_bolle_uscita  VALUES (" + id_tbl_bolle + ", " + this.id_articolo_selected + ", " + this.id_commessa_associalotti_selected + ", '" + this.codMP_selected + "', '" + this.lottoMP_selected + "', " + this.id_commessa_dettaglio_selected + ", " + this.id_anagrafica_selected + " , '" + this.cod_articolo_selected + "', '" + this.des_articolo_selected + "','" + this.id_lotto_selected + "','" + this.cod_lotto_selected + "','" + this.id_commessa_selected + "','" + this.cod_commessa_selected + "', " + this.quantita_pezzi_selected + " , " + this.peso_unitario_selected + " , " + this.quantita_colli_selected + ",'" + this.id_um_selected + "','" + this.fase_lavorazione_selected + "', '" + this.des_contenitore_selected + "','" + this.cod_contenitore_selected + "'," + this.id_contenitore_selected + ", " + this.scarto_selected + ")"
      );
      
      //filtro l'array di base della tabella
      this.bolla = this.alasql(
          "SELECT * FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
      );        
      this.search = this.ragione_sociale_selected
      this.bolla_in_preparazione = true
      this.dialog_dettaglio_conto_lavoro_uscita = false
    },

    modifica_bolla(item) {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcontenitori",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_anagrafica: item.id_anagrafica,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          
          this.contenitori = [];
          res.data.forEach((e) => {
            this.contenitori.push(e);
          });
        
        }
      });

      //recond in tabella già presente --> carico la relativa predisposizione bolla 
      let selected = this.alasql(
        "SELECT * FROM tbl_bolle_uscita Where id = " + item.id
      );
      this.quantita_colli_selected =  selected[0].qta_colli
      this.quantita_pezzi_selected = selected[0].num_pezzi
      this.peso_unitario_selected = selected[0].peso_unitario
      this.id_contenitore_selected =  selected[0].id_contenitore
      this.cod_contenitore_selected =  selected[0].cod_contenitore
      this.des_contenitore_selected = selected[0].contenitore
      this.scarto_selected = selected[0].scarto



      this.bolla = this.alasql(
        "SELECT * FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
      );        
      
      this.articolo_in_modifica_in_bolla = item.id
      this.dialog_modifica_bolla_conto_lavoro_uscita = true;
    },

    salva_modifica_bolla(){
      
      let totale_selezionati = this.alasql(
          "SELECT SUM(num_pezzi) as Somma FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected + " and id <> " + this.articolo_in_modifica_in_bolla
      );

      if ( totale_selezionati[0].Somma + Number(this.quantita_pezzi_selected) > this.check_qta_fornitore){
          this.snackbar_color =  "red"
          this.snackbar_text =  "Attenzione quantità inserita maggiore di quantità presso fornitore.";
          this.snackbar = true
      }

      this.alasql("UPDATE tbl_bolle_uscita SET qta_colli = " + this.quantita_colli_selected + ",  num_pezzi = " + this.quantita_pezzi_selected + ",  peso_unitario = " + this.peso_unitario_selected + ", contenitore = '" + this.des_contenitore_selected + "', id_contenitore = " + this.id_contenitore_selected + ", scarto = " + this.scarto_selected + "  Where id = " + this.articolo_in_modifica_in_bolla );  
     
      this.bolla = this.alasql(
          "SELECT * FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
      );    

      this.dialog_modifica_bolla_conto_lavoro_uscita = false

    },

    deleteItemBolla(item){
      this.alasql(
        //"Delete FROM tbl_bolle_uscita Where cod_articolo = '" + item.cod_articolo + "' and  cod_lotto = '" + item.cod_lotto + "' and cod_commessa = '" + item.cod_commessa + "' and  id_anagrafica = " + this.id_anagrafica_selected
        "Delete FROM tbl_bolle_uscita Where id = " + item.id 
      );        

      let num_record_bolla = this.alasql(
          "SELECT COUNT(*) as contatore FROM tbl_bolle_uscita Where cod_articolo = '" + this.cod_articolo_selected + "' and  cod_lotto = '" + this.cod_lotto_selected + "' and cod_commessa = '" + this.cod_commessa_selected + "' and  id_anagrafica = " + this.id_anagrafica_selected
      );
      if (num_record_bolla[0].contatore == 0){
        this.search = "" //azzero search --> watch e quindi sparice la Bolla in preparazione 
      }else{
        this.bolla = this.alasql(
          "SELECT * FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
        );        
      }
    },

    cambiaSelezioneContenitore(){
      let Cont_selezionato = this.alasql(
          "SELECT * FROM ? WHERE id_articolo = " + this.id_contenitore_selected ,
          [this.contenitori]
      );
      this.des_contenitore_selected = Cont_selezionato[0].des_articolo
      this.cod_contenitore_selected = Cont_selezionato[0].cod_articolo
    },

    salvaBolla(){

        let selected = this.alasql(
          "SELECT * FROM tbl_bolle_uscita Where id_anagrafica = " + this.id_anagrafica_selected
        );
        console.log("selezionati X bolla: ", selected)
        
            this.overlay = true
            let request = {
              controller: "Logistica",
              method: "PUT",
              richiesta: {
                action: "setcontolavoro",
                token: localStorage.getItem("user_token"),
                bolla: selected,
                tipo: "U"
              },
            };

            this.$store.dispatch("api", request).then((res) => {
              //richiamo la getdata
               if (res.status == 200){
                this.bolla_in_preparazione = false
               }
              this.overlay = false
            })
            .catch(e => {
                this.overlay = false
                console.log(e);
            });
            

    },



  },
};
</script>