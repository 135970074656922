<template>
  <header class="graph_header">
    <svg width="40" height="40" viewBox="0 300 1587.4015 1587.4016">
      <rect width="118.83575" height="197.19821" x="1201.4883" y="669.59119" />
      <rect width="118.40529" height="433.57825" x="964.67786" y="669.59094" />
      <rect width="117.97462" height="315.17307" x="728.72864" y="669.59119" />
      <rect width="117.97462" height="630.34613" x="491.91833" y="669.59119" />
      <rect width="117.97462" height="867.15649" x="255.10791" y="669.59119" />
    </svg>
    <span @click="homeCheck">QuickGraphs</span>
    <GraphSVG name="home" @click="$store.state.menu_sx=[];$router.push('/home')" />
    <template v-for="(item,i,j) in path" >
      <GraphSVG name="chevron_right" v-bind:key="j" />
      <span v-bind:key="i" @click="(item.path!=null)?$router.push(item.path):{}">{{item.name}}</span>
    </template>
    <div>
      <GraphSVG name="info" @click="openInfo"/>
      <div class="graph_card" v-if="clicked_info==true">
        <h5>QuickGraphs</h5>
        <h6>Versione {{versione.versione}}</h6>
      </div>
    </div>
    <div>
      <GraphSVG name="user" @click="openLogout"/>
      <div class="graph_card" v-if="clicked==true">
        <h5>{{username}}</h5>
        <h6>{{usertype}}</h6>
        <span @click="logout">LOGOUT</span>
      </div>
    </div>
  </header>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import versione from "@/components/GRAPH/Generale/Versione/Versione.js";
export default {
  name: "GraphHeader",
  components : {
    GraphSVG,
  },
  props: {
    path:Array,    
  },
  data(){
    return {
      username : null,
      usertype : null,
      clicked : false,
      last_click : null,
      versione : versione,
      clicked_info : false,
    }   
  },
  created(){
    this.username = localStorage.nominativo;
    this.usertype = localStorage.des_profilo;
    document.body.addEventListener('click',this.close);
  }, 
  beforeDestroy (){    
    document.body.removeEventListener('click',this.close);
  },
  destroyed(){ window.freeComponent(this); },
  methods : {
    homeCheck(){
      if(this.path.length > 0){
        this.$emit('gohome');
      }
    },
    openLogout(){
      this.clicked = true;
      this.last_click = Date.now();
      this.clicked_info = false; 
    },
    close(){
        if(Date.now()-this.last_click<300){return;}
        this.clicked = false;
        this.clicked_info = false;        
    },
    logout(){
        sessionStorage.clear();
        localStorage.clear();
        this.$router.push('/login');
    },
    openInfo(){
      this.clicked_info = true;
      this.last_click = Date.now();
      this.clicked = false; 
    },
  }
};
</script>
<style scoped src="./Header.css"></style>