<template>
  <MovimentiMagazzino
    :id_magazzino="id_magazzino"
    :id_articolo="id_articolo"
  />
</template>

<script>
import MovimentiMagazzino from '@/components/MAGAZZINO/MovimentiMagazzino.vue';

export default {
  name: 'MovimentiMagazzinoView',
  props: ['id_magazzino', 'id_articolo'],
  components: {
    MovimentiMagazzino,
  },
}
</script>
