<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col class="px-0 py-0" cols="12">
          <v-card>
            <!-- Tabella delle anagrafiche -->
            <v-data-table
              :headers="headers_anagrafiche"
              :items="items_anagrafiche"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  primary-title
                  class="white--text rounded-t"
                  color="indigo"
                  dense
                >
                  <v-toolbar-title>Anagrafiche</v-toolbar-title>
                  <v-spacer></v-spacer>

                  <!-- Bottone per aggiungere le anagrafiche -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mr-5"
                        @click="clickAdd"
                        small
                      >
                        <v-icon color="primary"> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Nuova Anagrafica</span>
                  </v-tooltip>

                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-5"
                        @click="filtro_visible = true"
                        small
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-filter</v-icon></v-btn
                      >
                    </template>
                    <span>Visualizza Filtri</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="resetFiltro" small v-bind="attrs" v-on="on"
                        ><v-icon color="primary">mdi-filter-off</v-icon></v-btn
                      >
                    </template>
                    <span>Resetta Filtro</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>

                  <!-- Bottone per tornare indietro -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="goBack" small v-on="on" v-bind="attrs">
                        <v-icon color="error">mdi-keyboard-backspace</v-icon>
                      </v-btn>
                    </template>
                    <span>Indietro</span>
                  </v-tooltip>
                </v-toolbar>
                 <v-card-text>
                <v-row align="start" justify="start" v-if="filtro_visible">
                  <v-col cols="1"></v-col>
                  <v-col cols="4" md="2">
                    <v-autocomplete
                      background-color="#ffffff"
                      @change="filtra"
                      dense
                      hide-details
                      :items="items_anagrafiche"
                      item-text="ragione_sociale"
                      item-value="ragione_sociale"
                      label="Ragione sociale"
                      style="display: inline-block; max-width: 350px"
                      v-model="ragione_sociale_search"
                    >
                      <template v-slot:append>
                        <v-icon
                          class="ml-2"
                          @click="
                            ragione_sociale_search = '';
                            filtra();
                          "
                          color="primary"
                          >{{
                            ragione_sociale_search == ""
                              ? "mdi-filter"
                              : "mdi-filter-off"
                          }}</v-icon
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              </template>
             
              <!-- Bottone Dettaglio dell'Anagrafica -->
              <template v-slot:[`item.dettagli`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="clickDettagli(item)"
                      small
                    >
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Dettaglio Anagrafica</span>
                </v-tooltip>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog dei dettagli dell'anagrafica -->
    <v-dialog persistent v-model="dettagli_anagrafica_visible">
      <DettagliAnagrafica
        :abilita_codice_fornitore="abilita_codice_fornitore"
        @aggiorna_anagrafica="aggiornaAnagrafica"
        @AggiornaAnagraficaFinal="getAnagraficaDettaglio"
        :anagrafica_sel="anagrafica_sel"
        :check_codice_fiscale="check_codice_fiscale"
        :check_p_iva="check_p_iva"
        :check_p_iva_mini="check_p_iva_mini"
        :concessionari_visible="concessionari_visible"
        @chiudiTabAnagrafica="chiudiAnagrafica"
        :visible="dettagli_anagrafica_visible"
      />
    </v-dialog>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import DettagliAnagrafica from "@/components/MANUTENZIONE/DettagliAnagrafica.vue";
import Registry from "@/classes/Registry.js";

export default {
  name: "Anagrafiche",
  components: {
    DettagliAnagrafica,
  },
  props: {
    abilita_codice_fornitore: {
      type: Boolean,
      default: true,
    },
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    check_codice_fiscale: {
      type: Boolean,
      default: true,
    },
    check_p_iva: {
      type: Boolean,
      default: true,
    },
    check_p_iva_mini: {
      type: Boolean,
      default: true,
    },
    concessionari_visible: {
      type: Boolean,
      default: false,
    },
    headers_scelti_anagrafiche: {
      type: Array,
      default: () => [
        { text: "Dettagli", sortable: false, value: "dettagli" },
        { text: "Codice", sortable: false, value: "codice" },
        { text: "Ragione Sociale", sortable: false, value: "ragione_sociale" },
        { text: "Codice Fiscale", sortable: false, value: "codice_fiscale" },
        { text: "Nazione", sortable: false, value: "des_nazione" },
        { text: "Regione", sortable: false, value: "des_regione" },
        { text: "Provincia", sortable: false, value: "des_provincia" },
        { text: "Località", sortable: false, value: "des_localita" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "P. IVA", sortable: false, value: "p_iva" },
        { text: "CAP", sortable: false, value: "cap" },
        { text: "Telefono", sortable: false, value: "telefono" },
        {
          text: "sigla REA",
          sortable: false,
          value: "sigla_rea",
          align: "center",
        },
        { text: "Codice REA", sortable: false, value: "codice_rea" },
      ],
    },
    rotta_back: {
      type: String,
      default: "/home/manutenzione/main",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      anagrafica_sel: new Registry(),
      // Modal
      caricamento_dati: false,
      dettagli_anagrafica_visible: false,
      // Array
      filtro_visible: false,
      headers_anagrafiche: [],
      items_anagrafiche: [],
      items_anagrafiche_generale: [],
      ragione_sociale_search: "",
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          // Popolo la tabella delle anagrafiche
          this.getAnagraficaList();

          // Resetto le attuali intestazioni di colonna
          this.headers_anagrafiche = [];

          // Decido quali intestazioni delle colonne devono essere mostrate in base alle props.
          this.headers_scelti_anagrafiche.forEach((hsa) => {
            this.headers_anagrafiche.push(Object.assign({}, hsa));
          });
        }
      },
    },
  },
  methods: {
    chiudiAnagrafica() {
      this.dettagli_anagrafica_visible = false;
      this.anagrafica_sel = new Registry();
    },
    aggiornaAnagrafica() {
      // Nascondo il dialog dei dettagli anagrafica.
      this.dettagli_anagrafica_visible = false;

      // Resetto l'anagrafica_sel
      this.anagrafica_sel = new Registry();

      // Rifaccio la getAnagrafiche per agiornare i dati
      this.getAnagraficaList();
    },
    clickAdd() {
      this.dettagli_anagrafica_visible = true;
      this.anagrafica_sel = new Registry();
    },
    async clickDettagli(item) {
      await this.getAnagraficaDettaglio(item);
      this.dettagli_anagrafica_visible = true;
    },
    filtra() {
      let items_fornitori_tmp = [];
      this.items_anagrafiche_generale.forEach((elem) => {
        items_fornitori_tmp.push(elem);
      });
      // Filtro il cod_richiesta
      if (this.ragione_sociale_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_fornitori_tmp.forEach((elem) => {
          if (elem.ragione_sociale == this.ragione_sociale_search) {
            res.push(elem);
          }
        });
        // Modello la risposta provvisoria su "res".
        items_fornitori_tmp = res;
      }
      this.items_fornitori_tmp = [];
      this.items_anagrafiche = items_fornitori_tmp;
    },
    async getAnagraficaDettaglio(arg) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getanagraficadettaglio",
          token: localStorage.user_token,
          id_anagrafica: arg.id_anagrafica,
          tipo: "FORNITORE",
        },
      };

      return this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Controllo che il fornitore abbia gli operatori
          if (res.data.result.fornitori[0].operatori) {
            // Controllo che gli operatori abbiano le specializzazioni
            res.data.result.fornitori[0].operatori.forEach((operatore) => {
              if (!operatore.specializzazioni) {
                operatore.specializzazioni = [];
              }
            });
          }

          // Controllo che il fornitore abbia le autorizzazioni
          if (!res.data.result.fornitori[0].autorizzazioni) {
            res.data.result.fornitori[0].autorizzazioni = [];
          }
          let res_tmp = res.data.result.fornitori[0];

          /* Mi assicuro che la res_tmp contenga un array tipologie;
           * se non lo contiene glie lo aggiungo (vuoto). */
          res_tmp.tipologie = res_tmp.tipologie ? res_tmp.tipologie : [];

          // Gestisco l'array tipologie come conviene al FE
          res_tmp.tipologie.forEach((tipologia) => {
            if (tipologia.fascie_orarie) {
              tipologia.fascie_orarie.forEach((fascia) => {
                fascia.des_giorni = [];
                fascia.giorni.forEach((giorno) => {
                  giorno.id_tabella = giorno.id_giorno;
                  fascia.des_giorni.push(giorno.des);
                });
              });
            }
          });

          this.anagrafica_sel = Object.assign({}, res_tmp);
        }
      });
    },
    getAnagraficaList() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getanagraficalist",
          token: localStorage.getItem("user_token"),
          tipo: "FORNITORE",
        },
      };

      //this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Azzero le anagrafiche
        this.items_anagrafiche = [];

        // Popolo le anagrafiche in base alla response
        this.items_anagrafiche = res.data.result;
        this.items_anagrafiche_generale = res.data.result;
        this.caricamento_dati = false;
      });
    },
    goBack() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        this.$emit("click_indietro");
      }
    },
    resetFiltro() {
      // Resetto i filtri
      this.ragione_sociale_search = "";

      // Nascondo i filtri
      this.filtro_visible = false;

      // Ricarico la tabella
      this.items_anagrafiche = Array.from(this.items_anagrafiche_generale);
    },
  },
  mounted() {},
};
</script>
<style scoped>
</style>