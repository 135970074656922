<template>
    <select v-model="id_ds" @change="changeDashboard" class="graph_switch">
        <option v-for="(item,i) in dashboards" :key="i" :value="item.id_ds" >{{item.nome}}</option>
    </select>
</template>
<script>
export default {
    name : 'GraphSwitch',
    props : {
        id : [String,Number]
    },
    data(){
        return{
            dashboards : [],
            id_ds : null,
            NETWORK : null,
        }
    },
    mounted(){
        document.body.addEventListener('graph_dashboard_list_request',this.dumpList);
        document.body.addEventListener('graph_dashboard_id_update',this.setId);
        this.id_ds = Number(this.id);
        this.NETWORK = this.$store.state.Network;
        this.getDashboard();
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_dashboard_list_request',this.dumpList);
        document.body.removeEventListener('graph_dashboard_id_update',this.setId);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        setId(event){
            this.id_ds = Number(event.detail);
        },
        dumpList(event){
            event.detail.dashboards = this.dashboards;
        },
        getDashboard(){
            let that = this;
            let request = {            
                controller : 'Graph',
                method : 'POST',
                body:{
                    action : 'getDashboard',
                    token : localStorage.user_token
                },
                on : {
                    load : function(response){ that.dashboards = response.dashboards; }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },
        changeDashboard(){
            this.$router.push('/home/graph/dashboard/' + this.id_ds);
            this.$nextTick(function(){
                let msg = new CustomEvent('dashboardrefresh',{detail:null});
                document.getElementById('quickchart_dashboard_main').dispatchEvent(msg);
            })
            
        }
    }
}
</script>
<style scoped>
.graph_switch{
    float:left;
    padding:0 5px;
    outline:none!important;
    appearance: none;
    height:100%;
    line-height:40px;
    font-size:15px;
    color: white;
    cursor:pointer;
}
.graph_switch option{
    color:black;
}
</style>