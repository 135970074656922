<template>
  <div style="width: 100%" id="listener_articolo_search">
    <v-data-table fixed-header :headers="headers" height="65vh" :items="items">
      <template v-slot:top>
        <v-toolbar color="indigo" dense rounded="">
          <v-toolbar-title class="white--text">Articoli</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="nuovoArticolo"
                color="blue-grey lighten-5"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Nuovo Articolo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="cercaArticolo"
                color="blue-grey lighten-5"
                small
                v-on:keydown.enter.prevent="cercaArticolo"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Cerca</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickIndietro"
                color="blue-grey lighten-5"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-row align="center" justify="start">
          <v-col cols="3">
            <v-text-field
              dense
              hide-details
              label="Cod. Articolo"
              style="display: inline-block; max-width: 350px"
              v-model="cod_articolo_sel"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="id_tipo_sel"
              :items="tipo_list"
              label="Tipo Articolo"
              placeholder="Articolo"
              item-text="des"
              required
              style="display: inline-block; max-width: 350px"
              item-value="id_tabella"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="id_famiglia_sel"
              :items="famiglia_list"
              item-text="des"
              required
              style="display: inline-block; max-width: 350px"
              item-value="id_tabella"
              placeholder="Famiglia"
              label="Tipo Famiglia"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="id_tipo_fornitura_sel"
              :items="fornitura_list"
              item-text="des"
              required
              style="display: inline-block; max-width: 350px"
              item-value="id_tabella"
              placeholder="Fornitura"
              label="Tipo Fornitura"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" small @click="clickDettaglio(item)">
              <v-icon style="float: left" color="#009999">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Dettaglio Articolo</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "ArticoloSearch",
  components: {},
  props: {
    cod_articolo: {
      type: String,
      default: "",
    },
    gestione_click_indietro: {
      type: String,
      default: "radial",
    },
    id_famiglia: {
      type: Number,
      default: null,
    },
    id_tipo: {
      type: Number,
      default: null,
    },
    id_tipo_fornitura: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo_sel: "",
      id_famiglia_sel: null,
      id_tipo_fornitura_sel: null,
      id_tipo_sel: null,

      // Modal
      filtra_menu_visible: false,
      nuovo_articolo_dialog: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      famiglia_list: [],
      fornitura_list: [],
      headers: [],
      items: [],
      items_nuovi: [],
      tipo_list: [],
    };
  },
  computed: {},
  watch: {
    cod_articolo: {
      immediate: true,
      handler() {
        /* Non posso usare semplicemente cod_articolo perché le
         * prop non possono essere mutate direttamente. */
        this.cod_articolo_sel = this.cod_articolo;
      },
    },
    id_tipo: {
      immediate: true,
      handler() {
        /* Non posso usare semplicemente id_tipo perché le
         * prop non possono essere mutate direttamente. */
        this.id_tipo_sel = this.id_tipo;
      },
    },
    id_famiglia: {
      immediate: true,
      handler() {
        /* Non posso usare semplicemente id_famiglia perché le
         * prop non possono essere mutate direttamente. */
        this.id_famiglia_sel = this.id_famiglia;
      },
    },
    id_tipo_fornitura: {
      immediate: true,
      handler() {
        /* Non posso usare semplicemente id_tipo_fornitura perché le
         * prop non possono essere mutate direttamente. */
        this.id_tipo_fornitura_sel = this.id_tipo_fornitura;
      },
    },
    items_nuovi: {
      immediate: true,
      handler() {
        /* Se sono statoi creati degli articoli in questa sessione,
         * me ne accorgo perché sono dentro items_nuovi e li
         * visualizzo all'interno della tabella iniziale. */
        if (this.items_nuovi.length > 0) {
          this.items_nuovi.forEach((elem) => {
            this.items.push(elem);
          });
        }
      },
    },
    visible: {
      immediate: true,
      handler() {
        /* Tutte le volte che il componente diventa
         * visibile, aggiorno i dati che scarico da DB. */
        if (this.visible === true) {
          this.inizializza();
        }
      },
    },
  },
  methods: {
    aggiornaItemsNuovi(messaggio) {
      this.items_nuovi = messaggio.detail.items_nuovi;
    },
    cercaArticolo() {
      //this.cod_articolo_alteration = false;
      // this.loadingVal = true
      //this.articoloDetail = {};
      //this.completeData = [];
      //this.des_articolo = "";
      //this.des_aggiuntiva = "";
      //this.id_tipo = "";
      //this.id_famiglia = "";
      //this.id_tipo_fornitura = "";

      if (this.cod_articolo_sel === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire un Codice Articolo";
        this.snackbar_text_color = "white";
        return;
      }

      /*      if (this.showicon === false) {
        return;
      }*/

      //this.codArticolo = this.cod_articolo;

      /*this.tipo_list.map((ti) => {
        if (ti.des === this.id_tipo) {
          this.id_tipo = ti.id_tabella;
        }
      });*/
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel,
          id_famiglia: this.id_famiglia_sel == null ? "" : this.id_famiglia_sel,
          id_tipo: this.id_tipo_sel == null ? "" : this.id_tipo_sel,
          id_tipo_fornitura:
            this.id_tipo_fornitura_sel == null
              ? ""
              : this.id_tipo_fornitura_sel,
          id_utente: localStorage.getItem("id"),
        },
      };

      // Eseguo la chiamata al BE
      this.$store.dispatch("api", request).then((res) => {
        if (res.data.lista.length > 1) {
          this.items = res.data.lista;
        }

        if (res.data.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "yellow";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Nessun articolo corrisponde a questo codice.";
          this.snackbar_text_color = "white";
        }

        if (res.data.lista.length === 1) {
          this.$emit("click_indietro");
          this.$router
            .push(
              `/home/production/articolodetails/${res.data.lista[0].id_articolo}`
            )
            .catch(() => {});

          // Compongo un messaggio con il contenuto dell'array items_nuovi.
          let messaggio = {
            detail: {
              items_nuovi: this.items_nuovi,
            },
            src: null,
          };

          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("items_nuovi", messaggio);

          /* Consegno alla mia destinazione l'evento con il
           * contenuto dell'array. */
          var searchInterval = setInterval(function() {
            if (document.getElementById("listener_articolo_details")) {
              clearInterval(searchInterval);
              document
                .getElementById("listener_articolo_details")
                .dispatchEvent(evento);
            }
          }, 500);
        }
      });
    },
    chiudiDialog() {
      this.nuovo_articolo_dialog = false;
    },
    clickDettaglio(elem) {
      this.$emit("click_indietro");
      this.$router
        .push(`/home/production/articolodetails/${elem.id_articolo}`)
        .catch(() => {});
    },
    clickIndietro() {
      if (this.gestione_click_indietro === "radial") {
        // Se il componente è una landing page, torna al menu radiale
        this.$router.push("/home/production");
      } else {
        /* Se il componente è in un dialog, emette un evento e
         * suo padre decide come gestirlo. */
        this.$emit("click_indietro");
      }
    },
    inizializza() {
      // Chiedo al BE l'elenco delle testate
      let request_headers = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getheader",
          id_utente: localStorage.id,
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          nome_modulo: "lista_articoli",
        },
      };

      this.$store.dispatch("api", request_headers).then((res) => {
        // Pulisco le testate prima di ripopolarle.
        this.headers = [];
        // Registro gli headers del BE
        res.data.forEach((elem) => {
          this.headers.push(elem);
        });

        // Aggiungo una colonna per il tasto Dettagli
        this.headers.unshift({
          text: "Dettaglio",
          value: "actions",
        });
      });

      // Chiedo al BE i valori per le select / autocomplete
      let request_select = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: "'FAMIGLIA','TIPOARTICOLO','FORNITURA_STANDARD'",
          filter: "validita = 1",
        },
      };

      // Registro i valori per le select / autocomplete
      this.$store.dispatch("api", request_select).then((res) => {
        this.famiglia_list = res.data.FAMIGLIA;
        this.fornitura_list = res.data.FORNITURA_STANDARD;
        this.tipo_list = res.data.TIPOARTICOLO;
      });

      if (this.cod_articolo_sel != "") {
        this.cercaArticolo();
      }
    },
    nuovoArticolo() {
      // Vado alla pagina di creazione dell'articolo
      this.$router.push({ name: "ArticoloDetails", params: { id: -1 } });
    },
  },
  created() {},
  mounted() {
    /* Preparo un listener per collezionare gli articoli
     * creati nella sessione presente. */
    document
      .getElementById("listener_articolo_search")
      .addEventListener("items_aggiornati", this.aggiornaItemsNuovi);
  },
};
</script>
<style scoped></style>
