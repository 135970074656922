var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"20px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 24 : 2}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lista,"search":_vm.search,"fixed-header":"","height":"70vh"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-2 white--text rounded-t",attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Lista Competenze")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.setCompetenze}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}],null,true)},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.gotoDashboard}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-window-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Vai alla Dashboard")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.headers),function(col,columnIndex){return _c('td',{key:columnIndex},[(columnIndex > 1)?_c('div',[(item[col.value] == 1)?_c('v-icon',{on:{"click":function($event){item[col.value] = 0}}},[_vm._v("mdi-check-underline")]):_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){item[col.value] = 1}}},[_vm._v("mdi-close")])],1):_c('div',{style:({
                        textAlign: columnIndex === 0 ? 'center' : 'left',
                      })},[_vm._v(" "+_vm._s(item[col.value])+" ")])])}),0)]}}],null,true)})],1)]}}])})],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-btn',{staticClass:"ml-5",attrs:{"fab":"","outlined":"","x-small":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.snackbarColor == "green" ? "mdi-check" : "mdi-alert"))])],1),_vm._v(" "+_vm._s(_vm.snackbarText)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }