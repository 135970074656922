<template>
    <v-dialog persistent v-model="ui.show" width="800">
        <v-card>
            <!-- HEADER -->
            <v-toolbar dark dense>
                <v-toolbar-title>Opzioni Etichetta</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="ui.show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container v-if="ui.show==true">
                <v-card-title class="px-0">Layout</v-card-title>
                <v-row justify="center" align="center" v-if="store.cartesian!=undefined">
                    <v-col cols="4" justify="start">
                        <v-checkbox v-model="store.cartesian.show_labels" label="Mostra etichette" />
                    </v-col>
                    <v-col cols="4" justify="start">
                        <v-btn @click="store.cartesian.on_top=(store.cartesian.on_top!=true)?true:false" :disabled="value.famiglia_grafici!=0">
                            {{(store.cartesian.on_top==true)?'posiziona in alto':'posiziona in basso'}}
                            <v-icon :style="'transition:transform .3s ease;'+((store.cartesian.on_top!=true)?'':'transform:rotate(180deg)')">mdi-arrow-down-thick</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" justify="start">
                        <v-text-field type="number" v-model="store.cartesian.distance" label="Distanza" />
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-title class="px-0">Colori</v-card-title>
                <v-row  justify="center" align="center">
                    <v-col cols="6" justify="center" align="center">
                        <span style="display:block;font-size:16px">Colore Sfondo</span>
                        <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="store.cartesian.background" canvas-height="100"/>
                    </v-col>
                    <v-col cols="6" justify="center" align="center">
                        <span style="display:block;font-size:16px">Colore Testo</span>
                        <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="store.cartesian.color" canvas-height="100"/>
                    </v-col>
                </v-row>
            </v-container>
            <!-- TASTO SALVA -->
            <v-card-actions>
                <v-spacer />
                <v-btn text color="success" @click="save">
                    <v-icon class="mr-2">mdi-floppy</v-icon>Salva modifiche</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
<script>
export default {
    name : 'GraphEditorBar',
    props : {
        value: Object
    },
    data(){
        return{
            index : -1,
            store : {},
            template : {
                show_labels : false,
                on_top : true,
                background : '#065c6d',
                color : '#ffffff',
                distance : 5,
            },
            ui : {
                show : false
            }
        }
    },
    mounted(){
        document.body.addEventListener('graph_editor_bar',this.show_editor);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_editor_bar',this.show_editor);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        save(){
            let value = Object.assign({},this.value);
            let encoded = JSON.stringify(this.store);
            // codifica del campo EXTRA come json codificato in base64 (con supporto per UTF-8)
            value.serie[this.index].extra = btoa(unescape(encodeURIComponent(encoded)));
            this.$emit('input',value);
            this.ui.show = false;
        },
        show_editor(data){
            data = data.detail;
            this.index = this.value.serie.indexOf(data);
            if(this.index < 0){return}
            try{
                // il campo EXTRA è un json codificato in base64 (con supporto per UTF-8)
                this.store = JSON.parse(decodeURIComponent(escape(atob(this.value.serie[this.index].extra))));                
                if(this.store.cartesian==undefined){
                    this.store.cartesian = Object.assign({},this.template);
                }
                let keys = Object.keys(this.template);
                for(let i=0; i < keys.length; i++){
                    let field = keys[i];
                    if(this.store.cartesian[field] == undefined){
                        this.store.cartesian[field] = this.template[field];
                    }
                }
            }catch(error){
                this.store = {
                    cartesian : Object.assign({},this.template)
                }         
            }           
            this.ui.show = true;
        }
    }
}
</script>