<template>
  <v-data-table
    class="elevation-1"
    :headers="headers_componenti_scartati"
    :items="items_componenti_scartati"
    style="width: 100%"
  >
    <!-- Titolo tabella -->
    <template v-slot:top>
      <v-toolbar class="white--text" color="indigo" dense>
        <v-toolbar-title>Componenti Scartati</v-toolbar-title>
      </v-toolbar>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "PezziRegistrati",
  data() {
    return {
      headers_componenti_scartati: [
        { text: "Lotto", sortable: false, value: "lotto" },
        { text: "Componente", sortable: false, value: "componente" },
        { text: "Q.tà Scartata", sortable: false, value: "qta_scartata" },
      ],
      items_componenti_scartati: [],
    };
  },
};
</script>
<style scoped>
</style>