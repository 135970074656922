<template>
    <v-card>
    <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                <v-tabs
                    v-model="modalTab"
                    grow
                    style="color: black"
                    slider-size="5"
                    slider-color="primary accent-3 "
                    color="black"
                >
                    <v-tab :href="`#generalita`" style="max-width: 150px"
                    >Generalita</v-tab >
                    <v-tab :href="`#configurazione`" style="max-width: 200px"
                    >Configurazione</v-tab>
                    <v-tab :href="`#orario_settimanale`" style="max-width: 230px"
                    >Orario Settimanale</v-tab>
                </v-tabs>
                <v-tabs-items v-model="modalTab" class="no-transition" touchless>

                    <v-tab-item
                    :value="`generalita`"
                    transition="false"
                    class="no-transition"
                    >
                        <v-spacer></v-spacer>
                        <v-card-text>
                            <v-container>
                                <v-divider></v-divider>
                                <v-row>
                                    <v-col cols="12" sm="6" md="2">
                                        <v-text-field v-model="scheda_utente.matricola"  label="Matricola" ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="scheda_utente.nome"  label="Nome" ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="scheda_utente.cognome"  label="Cognome" ></v-text-field>
                                    </v-col> 
                                    <v-col class="d-flex" cols="12" sm="6"  md="2">
                                        <v-select
                                        :items="aziende"
                                        item-text='ragione_sociale'
                                        item-value='id_azienda'
                                        label="Azienda"
                                        v-model="scheda_utente.id_azienda"
                                        @change="onChangeAzienda" 
                                        ></v-select>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="6"  md="2">
                                        <v-select
                                        :items="sedi"
                                        item-text='des_sede'
                                        item-value='id_sede'
                                        label="Sede"
                                        v-model="scheda_utente.id_sede"
                                        ></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="6"  md="3">
                                        <v-select
                                        :items="presenze_qualifica"
                                        item-text='des'
                                        item-value='id_tabella'
                                        label="Qualifica"
                                        v-model="scheda_utente.id_qualifica"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="6"  md="3">
                                        <v-select
                                        :items="presenze_inquadramento"
                                        item-text='des'
                                        item-value='id_tabella'
                                        label="Inquadramento"
                                        v-model="scheda_utente.id_inquadramento"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="scheda_utente.cod_fiscale"  label="Codice Fiscale" ></v-text-field>
                                    </v-col>   
                                    <v-col cols="12" sm="6" md="3"> 
                                        <!-- Gestione campo testo e finestra modale per selezione data --> 
                                            <v-dialog
                                                ref="dialog_nascita"
                                                v-model="modal_data_nascita"
                                                :return-value.sync="scheda_utente.data_nascita"
                                                persistent
                                                width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="scheda_utente.data_nascita"
                                                    label="Data Nascita"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @click:clear="scheda_utente.data_nascita = null"                                                    
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="scheda_utente.data_nascita"
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_data_nascita = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog_nascita.save(scheda_utente.data_nascita)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-dialog>                                        
                                    </v-col>    


                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="scheda_utente.indirizzo"  label="Indirizzo" ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="scheda_utente.localita"  label="Località" ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2">
                                        <v-text-field v-model="scheda_utente.cap"  label="Cap" ></v-text-field>
                                    </v-col>    
                                    <v-col cols="12" sm="6" md="2">
                                        <v-text-field v-model="scheda_utente.provoncia" label="Prov." ></v-text-field>
                                    </v-col>       

                                    <v-col cols="12" sm="6" md="2">
                                        <v-text-field v-model="scheda_utente.telefono" label="Telefono" ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="2">
                                        <v-text-field v-model="scheda_utente.cellulare"  label="Cellulare" ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="scheda_utente.email"  label="Email" :rules="[rules.required, rules.email]" ></v-text-field>
                                    </v-col>                                    
                                    <v-col cols="12" sm="6" md="2">
                                        <!-- Gestione campo testo e finestra modale per selezione data --> 
                                            <v-dialog
                                                ref="dialog_assunzione"
                                                v-model="modal_data_assunzione"
                                                :return-value.sync="scheda_utente.data_assunzione"
                                                persistent
                                                width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="scheda_utente.data_assunzione"
                                                    label="Data Assunzione"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @click:clear="scheda_utente.data_assunzione = null"                                                    
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="scheda_utente.data_assunzione"
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_data_assunzione = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog_assunzione.save(scheda_utente.data_assunzione)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-dialog>
                                    </v-col>    
                                    <v-col cols="12" sm="6" md="2">
                                        <!-- Gestione campo testo e finestra modale per selezione data --> 
                                            <v-dialog
                                                ref="dialog"
                                                v-model="modal_data_licenziamento"
                                                :return-value.sync="scheda_utente.data_licenziamento"
                                                persistent
                                                width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="scheda_utente.data_licenziamento"
                                                    label="Data Licenziamento"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    clearable
                                                    @click:clear="scheda_utente.data_licenziamento = null"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="scheda_utente.data_licenziamento"
                                                scrollable
                                                >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal_data_licenziamento = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog.save(scheda_utente.data_licenziamento)"
                                                >
                                                    OK
                                                </v-btn>
                                                </v-date-picker>
                                            </v-dialog>

                                    </v-col> 
                                    <v-col cols="12" sm="6" md="12">
                                              <v-textarea
                                                v-model="scheda_utente.note"
                                                auto-grow
                                                filled
                                                label="Note"
                                                rows="1"
                                            ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                    </v-tab-item>

                    <v-tab-item
                    :value="`configurazione`"
                    transition="false"
                    class="no-transition"
                    >
                        <v-spacer></v-spacer>
                        <v-card-text>
                            
                                <v-divider></v-divider>
                                <v-row>
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="scheda_utente.badge_codificato" label="Nr Badge" ></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="6"  md="3">
                                        <v-select
                                        :items="tipo_cartellino"
                                        item-text='des'
                                        item-value='id_tabella'
                                        label="Tipo Cartellino"
                                        v-model="scheda_utente.tipo_cartellino"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="6"  md="3">
                                        <v-select
                                        :items="tolleranza_ingresso"
                                        item-text='des'
                                        item-value='id_tabella'
                                        label="Tolleranza"
                                        v-model="scheda_utente.tolleranza"
                                        ></v-select>
                                    </v-col>                                  
                                    <v-col cols="12" sm="6" md="3">
                                        <v-text-field v-model="scheda_utente.tempo_pausa" label="Tempo Pausa (ore)" ></v-text-field>
                                    </v-col>
                                    <!-- Colonna campi SX -->
                                    <v-col cols="12" sm="6" md="6">
                                        <v-row>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-checkbox
                                                    v-model="scheda_utente.auth_ingresso"
                                                    color="blue"
                                                    label="Autoriz. Ingresso"
                                                    >
                                                </v-checkbox>
                                            </v-col>                                            
                                            <v-col cols="12" sm="4" md="4">
                                                <v-checkbox
                                                    v-model="scheda_utente.straordinario"
                                                    color="blue"
                                                    label="Straordinario"
                                                    >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-checkbox
                                                    v-model="scheda_utente.notturno"
                                                    color="blue"
                                                    label="Notturno"
                                                    >
                                                </v-checkbox>
                                            </v-col>

       
                                            <v-col cols="12" sm="4" md="4">
                                                <v-checkbox
                                                    v-model="scheda_utente.bonus_produzione"
                                                    color="blue"
                                                    label="Bonus Produzione"
                                                    >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="scheda_utente.bonus_produzione_ore" label="Bonus Produzione (ore)" ></v-text-field>
                                            </v-col>    
                                            <v-col cols="12" sm="4" md="4">
                                            </v-col>                                            
                                            <v-col cols="12" sm="4" md="4">
                                                <v-checkbox
                                                    v-model="scheda_utente.bonus_mensa"
                                                    color="blue"
                                                    label="Bonus Mensa"
                                                    >
                                                </v-checkbox>
                                            </v-col>

                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="scheda_utente.bonus_mensa_ore" label="Bonus Mensa (ore)" ></v-text-field>
                                            </v-col>    
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="scheda_utente.bonus_mensa_euro" label="Bonus Mensa (euro)" ></v-text-field>
                                            </v-col>                                            

                                        </v-row>
                                    </v-col>
                                    <!-- Fine Colonna campi SX -->

                                    <!-- Colonna campi DX Tabella Dispositivi -->
                                    <v-col cols="12" sm="6" md="6">
                                        <v-row>
                                            <v-col cols="12" sm="2" md="2">
                                            </v-col>
                                            <v-data-table
                                                id="table_dispositivi"
                                                :height="larghezza"
                                                fixed-header
                                                :headers="headers_dispositivi"
                                                :items="dispositivi"  
                                                item-key="id"
                                                class="elevation-1"
                                                hide-default-footer
                                                :items-per-page="2000"
                                            >
                                                <template  v-slot:[`item.validita`]="{ item }" >
                                                    <v-checkbox
                                                    style="margin-left: 10px"
                                                    v-model="item.validita"
                                                    @change=" _check()"
                                                    ></v-checkbox>
                                                </template> 

                                            </v-data-table>                                            
                                        </v-row>
                                    </v-col>    

                                    <!-- Fine Colonna campi DX -->                                    

                                </v-row>
                            
                        </v-card-text>
                    </v-tab-item>

                    <v-tab-item
                    :value="`orario_settimanale`"
                    transition="false"
                    class="no-transition"
                    >
                        <template>
                            <v-data-table
                                :headers="headers"
                                :items="orari"
                                hide-default-footer
                                :items-per-page="12000"
                                class="elevation-1"
                            >
                                <template  v-slot:[`item.giorno_lettere`]="{ item }" >
                                <v-chip
                                    :color="getColor(item.giorno_lettere)"
                                    dark
                                >
                                    {{ item.giorno_lettere }}
                                </v-chip>
                                </template>

                                <template  v-slot:[`item.ore`]="{ item }" >
                                    <v-text-field
                                        v-model="item.ore"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.auth_ingresso1`]="{ item }" >
                                    <v-checkbox
                                        style="margin-left: 10px"
                                        v-model="item.auth_ingresso1"
                                    ></v-checkbox>
                                </template> 

                                <template  v-slot:[`item.ingresso_standard1`]="{ item }" >
                                    <v-text-field
                                        v-model="item.ingresso_standard1"
                                        type="time"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.uscita_standard1`]="{ item }" >
                                    <v-text-field
                                        v-model="item.uscita_standard1"
                                        type="time"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.auth_ingresso2`]="{ item }" >
                                    <v-checkbox
                                        v-model="item.auth_ingresso2"
                                    ></v-checkbox>
                                </template> 
                                <template  v-slot:[`item.ingresso_standard2`]="{ item }" >
                                    <v-text-field
                                        v-model="item.ingresso_standard2"
                                        type="time"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.uscita_standard2`]="{ item }" >
                                    <v-text-field
                                        v-model="item.uscita_standard2"
                                        type="time"
                                    ></v-text-field>        
                                </template>
                               <template  v-slot:[`item.auth_ingresso3`]="{ item }" >
                                    <v-checkbox
                                        v-model="item.auth_ingresso3"
                                    ></v-checkbox>
                                </template> 
                                <template  v-slot:[`item.ingresso_standard3`]="{ item }" >
                                    <v-text-field
                                        v-model="item.ingresso_standard3"
                                        type="time"
                                    ></v-text-field>        
                                </template>
                                <template  v-slot:[`item.uscita_standard3`]="{ item }" >
                                    <v-text-field
                                        v-model="item.uscita_standard3"
                                        type="time"
                                    ></v-text-field>        
                                </template>


                            </v-data-table>
                        </template>
                    </v-tab-item>
                    
                </v-tabs-items>
                </v-col>
            </v-row>
            <v-col cols="12" sm="6" md="12" align="center">
                    <v-btn class="ma-2" outlined color="blue darken-1" text @click="save_back">Salva</v-btn>
            </v-col>


        </v-card-text>
 

    </v-card>
</template>

<style scoped>

    th {
    font-size: 1.2rem;
    }

  

</style>

<script>
    export default {
        data() {
            return{
                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",
                modal_data_assunzione: false,
                modal_data_licenziamento: false,
                modal_data_nascita: false,
                modalTab: "generalita",
                scheda_utente:[],
                scheda: { id_personale: -1, id_azienda: 1, id_sede: 1, id_nazione: null, id_qualifica: null, id_inquadramento: null, id_contratto: null, id_nazione_nascita: null, id_t_utente: null, attivo: 0, update_trm: null, nome: '', cognome: '', matricola: null, cod_personale: null, id_ruolo: null, badge: '', badge_codificato: '', costo: null, indirizzo: '', cap: '', localita: '', provincia: '', data_nascita: '', cod_fiscale: '', data_assunzione: '', data_licenziamento: null, email: '', telefono: '', cellulare: '', localita_nascita: null, provincia_nascita: null, cap_nascita: null, password: '', tipo_cartellino: 1, notturno: 0, tolleranza: 2, auth_ingresso: null, tempo_pausa: null, straordinario: 0, bonus_produzione: 0, bonus_produzione_ore: null, bonus_mensa: 0, bonus_mensa_ore: null, bonus_mensa_euro: null, tipo_riconoscimento: null, fp_acquisizione: null, accesso_check: null, note: '', validita: 1, date_ins: '', date_mod: '', id_utente: null, id_personale_old: null, id_sede_old: null, des_inquadramento: '', des_qualifica: '', des_tolleranza: '', des_tipo_cartellino: ''},
                scheda_new: { id_personale: -1, id_azienda: 1, id_sede: 1, id_nazione: null, id_qualifica: null, id_inquadramento: null, id_contratto: null, id_nazione_nascita: null, id_t_utente: null, attivo: 0, update_trm: null, nome: '', cognome: '', matricola: null, cod_personale: null, id_ruolo: null, badge: '', badge_codificato: '', costo: null, indirizzo: '', cap: '', localita: '', provincia: '', data_nascita: '', cod_fiscale: '', data_assunzione: '', data_licenziamento: null, email: '', telefono: '', cellulare: '', localita_nascita: null, provincia_nascita: null, cap_nascita: null, password: '', tipo_cartellino: 1, notturno: 0, tolleranza: 2, auth_ingresso: null, tempo_pausa: null, straordinario: 0, bonus_produzione: 0, bonus_produzione_ore: null, bonus_mensa: 0, bonus_mensa_ore: null, bonus_mensa_euro: null, tipo_riconoscimento: null, fp_acquisizione: null, accesso_check: null, note: '', validita: 1, date_ins: '', date_mod: '', id_utente: null, id_personale_old: null, id_sede_old: null, des_inquadramento: '', des_qualifica: '', des_tolleranza: '', des_tipo_cartellino: ''},
                orari: [],
                orario: [
                        {id_personale_orario: -1, giorno: 1, giorno_lettere: 'DOM', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        {id_personale_orario: -1, giorno: 2, giorno_lettere: 'LUN', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        {id_personale_orario: -1, giorno: 3, giorno_lettere: 'MAR', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        {id_personale_orario: -1, giorno: 4, giorno_lettere: 'MER', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        {id_personale_orario: -1, giorno: 5, giorno_lettere: 'GIO', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        {id_personale_orario: -1, giorno: 6, giorno_lettere: 'VEN', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        {id_personale_orario: -1, giorno: 7, giorno_lettere: 'SAB', ore : 0, id_personale: -1, auth_ingresso1: 0, ingresso_standard1: null, uscita_standard1: null, auth_ingresso2: 0, ingresso_standard2: null, uscita_standard2: null, auth_ingresso3: 0, ingresso_standard3: null, uscita_standard3: null},
                        ],
                headers: [
                    { text: 'Giorno', value: 'giorno_lettere', sortable: false },
                    { text: 'Ore Presenza', value: 'ore', width: '12%', sortable: false },
                    { text: 'Auth.1', value: 'auth_ingresso1', sortable: false },
                    { text: 'Ingr.1', value: 'ingresso_standard1', sortable: false },
                    { text: 'Usc.1', value: 'uscita_standard1', sortable: false },
                    { text: 'Auth.2', value: 'auth_ingresso2', sortable: false },
                    { text: 'Ingr.2', value: 'ingresso_standard2', sortable: false },
                    { text: 'Usc.2', value: 'uscita_standard2', sortable: false },
                    { text: 'Auth.3', value: 'auth_ingresso3', sortable: false },
                    { text: 'Ingr.3', value: 'ingresso_standard3', sortable: false },
                    { text: 'Usc.3', value: 'uscita_standard3', sortable: false },
                ],

                dispositivi: [],
                larghezza : 250,
                headers_dispositivi: [
                    { text: "Terminale", value: 'des_dispositivo', sortable: false, width: 200 },
                    { text: 'Abilitato', value: 'validita', sortable: false , width: 200},
                ],                
                tolleranza_ingresso: [],
                tipo_cartellino: [],
                presenze_inquadramento: [],
                presenze_qualifica: [],
                aziende:[],
                sedi: [],
                sedi_ini: [],
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 20 || 'Max 20 characters',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },                
            }
        },
        props: {
            ID_UTENTE: Number,
        },
        mounted() { 
            this.getazienda()
            this.getsedi();
            this.gettabelle();  
            this.getpersonale();
        },
        watch: {
            ID_UTENTE: {
                immediate: true,
                handler() {
                    this.getpersonale() 
                }
            },

        },
        methods: {  
            gettabelle(){
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "gettabelle",
                        tipo: "'PRESENZE_QUALIFICA','PRESENZE_INQUADRAMENTO','TIPO_CARTELLINO','TOLLERANZA_INGRESSO'",
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => { 
                    this.tolleranza_ingresso = []
                    this.load_tolleranza_ingresso(res.data.result.TOLLERANZA_INGRESSO)
                    this.tipo_cartellino = []
                    this.load_tipo_cartellino(res.data.result.TIPO_CARTELLINO)
                    this.presenze_inquadramento = []
                    this.load_presenze_inquadramento(res.data.result.PRESENZE_INQUADRAMENTO)
                    this.presenze_qualifica = []
                    this.load_presenze_qualifica(res.data.result.PRESENZE_QUALIFICA)

                })
                .catch(e => {
                    console.log(e);
                });                

            },
            getazienda(){
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "getazienda",
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {  
                    this.aziende = []
                    res.data.forEach((elem) => {
                        this.aziende.push(elem)
                    })

                })
                .catch(e => {
                    console.log(e);
                });                

            },            
            getsedi(){
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "getsedi",
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {  
                    this.sedi = []
                    this.load_sedi(res.data)

                })
                .catch(e => {
                    console.log(e);
                });                

            },
            onChangeAzienda(){
                let tab =  this.sedi_ini 
                let id_azienda = this.scheda_utente['id_azienda']
                this.sedi = this.alasql(
                    "SELECT * FROM ? WHERE id_azienda = " + id_azienda ,
                    [tab]
                );
            },
            getpersonale(){
                if(this.ID_UTENTE > 99999){
                        this.scheda_utente = []
                        this.orari = this.orario
                        this.dispositivi = []
                        let request = {
                            controller: "Presenze",
                            method: "POST",
                            richiesta: {
                                action: "getpersonale",
                                id_personale: -1, 
                                token: localStorage.getItem("user_token"),
                            },
                        }
                        this.$store.dispatch("api", request).then((res) => {   
                        this.scheda_utente = this.scheda
                        this.orari = this.orario                            
                        res.data.dispositivi.forEach((elem) => {
                            this.dispositivi.push ( Object.assign({}, elem))
                        });

                    })
                    .catch(e => {
                        console.log(e);
                    });                
                }else{
                        let request = {
                            controller: "Presenze",
                            method: "POST",
                            richiesta: {
                                action: "getpersonale",
                                id_personale: this.ID_UTENTE, 
                                token: localStorage.getItem("user_token"),
                            },
                        }
                        this.$store.dispatch("api", request).then((res) => {   
                        this.scheda_utente = res.data.scheda[0];
                        if (this.scheda_utente['data_nascita'] != null)  this.scheda_utente['data_nascita'] = this.scheda_utente['data_nascita'].substr(0, 10)
                        if (this.scheda_utente['data_assunzione'] != null)  this.scheda_utente['data_assunzione'] = this.scheda_utente['data_assunzione'].substr(0, 10)
                        if (this.scheda_utente['data_licenziamento'] != null)  this.scheda_utente['data_licenziamento'] = this.scheda_utente['data_licenziamento'].substr(0, 10)

                        this.orari = []
                        this.load_orari_utente(res.data.orario)

                        this.dispositivi = []
                        res.data.dispositivi.forEach((elem) => {
                            this.dispositivi.push ( Object.assign({}, elem))
                        });

                    })
                    .catch(e => {
                        console.log(e);
                    });                
                }
            },

            load_tolleranza_ingresso(array){
                var lung = array.length
                if (lung > 0 ){
                    for (var i = 0; i <=lung - 1; i++){
                    this.tolleranza_ingresso.push({  
                            id_tabella: array[i].val_int, 
                            des: array[i].des, 
                        });  
                    }
                }    
            },
            load_tipo_cartellino(array){
                var lung = array.length
                if (lung > 0 ){
                    for (var i = 0; i <=lung - 1; i++){
                    this.tipo_cartellino.push({  
                            id_tabella: array[i].val_int, 
                            des: array[i].des, 
                        });  
                    }
                }    
            },
            load_presenze_inquadramento(array){
                var lung = array.length
                if (lung > 0 ){
                    for (var i = 0; i <=lung - 1; i++){
                    this.presenze_inquadramento.push({  
                            id_tabella: array[i].id_tabella, 
                            des: array[i].des, 
                        });  
                    }
                }    
            },   
            load_presenze_qualifica(array){
                var lung = array.length
                if (lung > 0 ){
                    for (var i = 0; i <=lung - 1; i++){
                    this.presenze_qualifica.push({  
                            id_tabella: array[i].id_tabella, 
                            des: array[i].des, 
                        });  
                    }
                }    
            },    
            load_sedi(array){
                var lung = array.length
                if (lung > 0 ){
                    for (var i = 0; i <=lung - 1; i++){
                    this.sedi.push({  
                            id_sede: array[i].id_sede, 
                            id_azienda:  array[i].id_azienda,
                            des_sede: array[i].des_sede, 
                        });  
                    this.sedi_ini.push({  
                            id_sede: array[i].id_sede, 
                            id_azienda:  array[i].id_azienda,
                            des_sede: array[i].des_sede, 
                        }); 
                    }
                }    
            },                  
            load_orari_utente(array){
            var lung = array.length
            if (lung > 0 ){
                var day = ''
                for (var i = 0; i <=lung - 1; i++){

                switch (array[i].giorno) {
                case 1:
                    day = "DOM";
                    break;
                case 2:
                    day = "LUN";
                    break;
                case 3:
                    day = "MAR";
                    break;
                case 4:
                    day = "MER";
                    break;
                case 5:
                    day = "GIO";
                    break;
                case 6:
                    day = "VEN";
                    break;
                case 7:
                    day = "SAB";
                }
                
                this.orari.push({  
                        id_personale_orario: array[i].id_personale_orario, 
                        id_personale: array[i].id_personale, 
                        giorno: array[i].giorno, 
                        giorno_lettere: day,
                        ore: array[i].ore,  
                        auth_ingresso1: array[i].auth_ingresso1,
                        ingresso_standard1: array[i].ingresso_standard1,
                        uscita_standard1: array[i].uscita_standard1,
                        auth_ingresso2: array[i].auth_ingresso2,  
                        ingresso_standard2: array[i].ingresso_standard2,
                        uscita_standard2: array[i].uscita_standard2, 
                        auth_ingresso3: array[i].auth_ingresso3,
                        ingresso_standard3: array[i].ingresso_standard3,
                        uscita_standard3: array[i].uscita_standard3,  
                        date_ins: array[i].date_ins,
                        date_mod: array[i].date_mod,
                        id_utente: array[i].id_utente,
                        id_personale_old: array[i].id_personale_old,
                     });  
                    }
                }
            },
            _check(){
                this.dispositivi.forEach((elem) => {
                    //il campo check_box è mappato (model) sull'array.validità
                    if (elem.validita == true ){
                        elem.id_personale = this.ID_UTENTE
                        elem.validita = 1
                    }else{ //rimanente < giacenza
                        elem.id_personale = this.ID_UTENTE
                        elem.validita = 0                        
                    }
                });
            },

            getColor (giorno) {
                if (giorno == 'DOM') return 'orange'
                else if (giorno == 'SAB') return 'orange'
                else return 'green'
            },
            save_back(){
                    
                    if (this.scheda_utente.data_assunzione == "" || this.scheda_utente.data_assunzione == null ||
                        this.scheda_utente.matricola == "" || this.scheda_utente.matricola == null  ||
                        this.scheda_utente.cognome == "" || this.scheda_utente.cognome == null  ) {   
                        this.snackbar_text = "Matricola, Cognome e data assunzione devono essere valorizzati";
                        this.snackbar_color = "error";
                        this.snackbar = true;  
                    }else{
                        let Array_Scheda = []
                        Array_Scheda.push(this.scheda_utente)
                        let request = {
                            controller: "Presenze",
                            method: "PUT",
                            richiesta: {
                                action: "setschedapersonale",
                                scheda: Array_Scheda,
                                orario: this.orari,
                                dispositivi: this.dispositivi,
                                token: localStorage.getItem("user_token"),
                            },
                        }
                        this.$store.dispatch("api", request).then((res) => {  
                            if (res.data == "OK"){
                                this.scheda = this.scheda_new
                                this.$emit("gestioneutente");  
                            }else{
                                console.log("Utente non salvato!")
                            }  
                            this.$emit("gestioneutente");   
                        });
                    }
                
            },          
           
        }

    }
</script>
