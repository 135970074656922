import CHART_COMMON from "./_common.js";
class CHART_2D_GENERAL {
    COMMON = new CHART_COMMON();

    extractedColor = '#000000';
    options = {
        animation : false,
        legend : {
            show : true,
            data : [],
            top :5,
            right: 5,
        },
        grid: {
            containLabel: true,
            left:25,
            bottom:30,
            right:25,
            //top:35
        },
        tooltip : {
            show : true,
            formatter : function(data){
                if(data.length<=0){return;}
                let tooltip = '<div class="graph_tooltip"><h6>'+data.name+'</h6>';
                tooltip+=   '<div><span style="background-color:'+data.color+'"></span>'+
                            '<span>'+data.seriesName+':</span>'+
                            '<span>'+data.data.formatted+'</span></div>';
                tooltip+='</div>';
                return tooltip;
            },
            backgroundColor : 'transparent',
        },
        xAxis: [],
        yAxis: [],
        series: [],
        visualMap : [],
        color : [],
    }

    setOptions(data){
        try{
            this.extractedColor = getComputedStyle(document.documentElement).getPropertyValue('--widget_title_background');  
            this.setAssi(data.assi);
            this.setSerie(data.serie);
            return this.options;
        }catch(error){
            return {};
        }        
    }

    setAssi(data){
        let class_ref = this;
        let assi = [{n:'X',o:0},{n:'Y',o:1}];
        try{
            assi = JSON.parse(decodeURIComponent(escape(atob(data))));
        }catch(error){
            console.warn(`Non è stato possibile leggere gli assi per il grafico`);
        }
        for(let i=0; i < assi.length; i++){
            if(assi[i].e!=true){continue;}
            switch(assi[i].o){
                case 0:
                case 2:
                    this.options.xAxis.push({                    
                        axisTick: {
                            alignWithLabel: true
                        },
                        data : [],
                        name : assi[i].n,
                        nameGap : 30,
                        nameLocation : 'center',
                        nameTextStyle : {
                            fontWeight : 'bold'
                        },
                        position : (assi[i].o == 0)?'bottom':'top',
                        reference : assi[i].o,
                        type: 'category'                        
                    });
                    break;
                case 1:
                case 3:
                    this.options.yAxis.push({
                        axisLabel : {
                            formatter : function(data){
                                return class_ref.COMMON.dotNumber(data);
                            }
                        },                    
                        axisTick: {
                            alignWithLabel: true
                        },
                        name : assi[i].n,
                        nameLocation : 'center',
                        nameRotate : 90,
                        nameGap : 5,
                        nameTextStyle : {
                            fontWeight : 'bold'
                        },
                        position : (assi[i].o == 1)?'left':'right',
                        reference : assi[i].o,
                        type: 'value',
                    });
                    break;
            }
        }
    }    

    setSerie(serie){
        serie = this.COMMON.filterSerie(serie);
        let nameGapRef = {max:0};
        
        for(let i=0; i < serie.length; i++){
            
            // Decodifica dei parametri extra
            serie[i].extra = this.COMMON.getExtra(serie[i]);

            // Definizione del tipo di grafico per la serie
            let type = 'line';
            let area = false;
            let smooth = false;
            let setVisualMap = false;

            let show_label = false;
            let on_top = true;
            let background = this.extractedColor;
            let color = '#ffffff';
            let distance = 5;
            try{
                if(serie[i].extra.cartesian.show_labels==true){show_label = true;}
                if(serie[i].extra.cartesian.on_top!=true){on_top = false;}
                if(serie[i].extra.cartesian.background!=undefined){background = serie[i].extra.cartesian.background;}
                if(serie[i].extra.cartesian.color!=undefined){color = serie[i].extra.cartesian.color;}
                if(serie[i].extra.cartesian.distance!=undefined){distance = serie[i].extra.cartesian.distance;}
            }catch(error){
                show_label = false;
                on_top = true;
                background = this.extractedColor;
                color = '#ffffff';
                distance = 5;
            } 

            switch(serie[i].tipo_grafico){
                case 1:
                    area = true;   
                    setVisualMap = true;                
                    break;
                case 2:
                    smooth = true;
                    setVisualMap = true;  
                    break;
                case 3:
                    area = true;
                    smooth = true;
                    setVisualMap = true;  
                    break;
                case 4:
                    type = 'bar';                                       
                    break;
                case 0:
                default:
                    setVisualMap = true;                     
                    break;
            }

            let done = false;
            for(let j=0; j < this.options.xAxis.length; j ++){
                if(this.options.xAxis[j].reference == serie[i].valore){
                    this.COMMON.normalizeData(serie[i].serie,serie[i].tipo_dati);
                    this.options.xAxis[j].data = serie[i].serie;
                    done = true;
                    break;
                }
            }
            if(done){continue;}
            for(let j=0; j < this.options.yAxis.length; j ++){
                if(this.options.yAxis[j].reference == serie[i].valore){                  
                    // Serie
                    this.options.series.push({                        
                        data : this.COMMON.processSerie(serie[i]),
                        name : serie[i].nome,
                        smooth : smooth,
                        smoothMonotone : (smooth==true)?'x':undefined,
                        type: type,
                        yAxisIndex : (serie[i].valore == 1)?undefined:1,
                        areaStyle : (area==true)?{}:undefined,
                        avoidLabelOverlap : true,
                        label : {
                            distance : Number(distance),
                            padding : 5,
                            borderRadius : 3,
                            show:show_label,
                            position:(on_top)?'top':'bottom',
                            backgroundColor : background,
                            color : color,
                            formatter : function(data){return data.data.formatted;}
                        }
                    });
                    // Visual Map
                    if(setVisualMap&&!serie[i].is_static){
                        let visualMap = this.COMMON.getVisualMap(serie[i].extra.formato);
                        if(visualMap != undefined){
                            visualMap.seriesIndex = this.options.series.length -1;
                            this.options.visualMap.push(visualMap);
                            this.options.color.push('#b3b3b3');
                        }
                    }else{
                        if(serie[i].is_static){
                            this.options.color.push(serie[i].linecolor);
                        }else{
                            this.options.color.push('#b3b3b3');
                        }
                        
                    }
                    // Legenda
                    this.options.legend.data.push(serie[i].nome);
                    // Distanziamento asse
                    this.options.yAxis[j].nameGap = this.COMMON.getAxisNameGap(serie[i].serie,nameGapRef);
                    break;
                }
            }
        }

    }


    clickHandler(params) {
        return {
            seriesIndex : params.seriesIndex,
            dataIndex : params.dataIndex,
        }
    }

}

export default CHART_2D_GENERAL;