import GanttTask from "@/components/GANTT/Classes/Task/Task.js";
let CALENDAR = null;
let CONNECTIONS = null;
//M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z
//M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z
const freccia_svg = `<svg
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path d="@" />
  </svg>`;
const freccia = `M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z`;
const pallino = `M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z`;

class GanttTable {
    data = new Array();
    items = new Array();
    dates = {
        min: Infinity,
        max: -Infinity
    };

    setReferences(calendar, connections) {
        CALENDAR = calendar;
        CONNECTIONS = connections;
    }

    build(tasks) {
        this.data.length = 0;
        const temp = new Array();
        let i, j, length = tasks.length;

        for (i = 0; i < length; i++) {
            temp.push(new GanttTask(tasks[i]));
            if (temp[i].data.nesting == 0) {
                this.data.push(temp[i]);
            }
        }

        let done = false;
        while (!done) {
            done = true;
            for (i = 0; i < length; i++) {
                let task_i = temp[i];
                if (task_i.data.nesting == null && task_i.data.id_task_padre != null) {
                    for (j = 0; j < length; j++) {
                        let task_j = temp[j];
                        if (task_j.data.id_task == task_i.data.id_task_padre) {
                            if (task_j.data.nesting != null) {
                                task_i.addParent(task_j);
                            }
                        }
                    }
                }
                if (task_i.data.nesting == null) {
                    done = false;
                }
            }
        }
        this.updateBoundaries();
        CALENDAR.build(this.dates.min, this.dates.max);
        this.update();
    }

    updateBoundaries() {
        const data = this.data;
        let length = data.length;
        let min = Infinity;
        let max = -Infinity;
        for (let i = 0; i < length; i++) {
            data[i].dumpToArray(this.items);
            const date = data[i].rearrangeTask();
            min = Math.min(min, date.start);
            max = Math.max(max, date.end);
        }
        this.dates = {
            min: new Date(min),
            max: new Date(max)
        }
        return this.dates;
    }

    ClickedItem(event, that) {

        const path = event.composedPath();
        path.forEach(obj => {
            if (obj.tagName == undefined) {
                return;
            }
            if (obj.taskReference != null) {
                const task = obj.taskReference;
                console.log("task", task);
                task.toggleTreeView(!task.treeView.open);
            }
        });
        path.forEach(obj => {
            if (obj.tagName == undefined) {
                return;
            }
            if (obj.tagName.toLowerCase() == 'tbody') {
                CONNECTIONS.build(null, that.items);
            }
        });
    }

    update() {
        this.items.length = 0;
        const data = this.data;
        let length = this.data.length;
        for (let i = 0; i < length; i++) {
            data[i].dumpToArray(this.items);
        }
        const items = this.items;
        length = items.length;

        const fragment = new DocumentFragment();
        const table = document.getElementById('gantt_task_table');
        table.innerHTML = '';

        const blocknum = CALENDAR.blocks.length;
        const diagram_fragment = new DocumentFragment();
        const diagram = document.getElementById('gantt_diagram_table').getElementsByTagName('tbody')[0]
        diagram.innerHTML = '';

        const total_row_task = document.createElement('tr');
        const total_cell_task_des = document.createElement('td');
        total_row_task.appendChild(total_cell_task_des);
        total_cell_task_des.innerText = 'Durata progetto';

        const total_cell_task_data_inizio = document.createElement('td');
        total_row_task.appendChild(total_cell_task_data_inizio);
        total_cell_task_data_inizio.innerText = this.dates.min.toLocaleDateString('it-IT', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        const total_cell_task_data_fine = document.createElement('td');
        total_row_task.appendChild(total_cell_task_data_fine);
        total_cell_task_data_fine.innerText = this.dates.max.toLocaleDateString('it-IT', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        fragment.appendChild(total_row_task);

        const total_row_diagram = document.createElement('tr');
        for (let i = 0; i < blocknum; i++) {
            const total_cell_diagram = document.createElement('td');
            if (i == 1) {
                total_cell_diagram.innerHTML = `
                <figure>
                    <div>
                        <p></p>
                        <span >Durata Progetto</span>
                        <aside data-type="0" ></aside>
                        <aside data-type="1" ></aside>
                    </div>
                </figure>`;
                total_cell_diagram.style.setProperty('--duration', blocknum - 3);
                total_cell_diagram.style.setProperty('--completion', 100);
                total_cell_diagram.style.pointerEvents = 'none';
                total_cell_diagram.setAttribute('data-itemtype', 0);
            }
            total_row_diagram.appendChild(total_cell_diagram);

        }
        diagram_fragment.appendChild(total_row_diagram);

        for (let i = 0; i < length; i++) {
            const item = items[i];
            item.index = i;
            if (item.data.validita != 1) {
                continue;
            }

            const row = document.createElement('tr');
            const labelcell = document.createElement('td');

            labelcell.taskreference = item;
            labelcell.style.paddingLeft = item.data.nesting * 15 + 'px';
            if (item.hasChildren()) {
                labelcell.innerHTML = freccia_svg.replace("@", freccia);
                const svg = labelcell.children[0];
                svg.taskReference = item;
            } else {
                labelcell.innerHTML = freccia_svg.replace("@", pallino);
                const svg = labelcell.children[0];
                svg.style.pointerEvents = 'none';
            }

            const labelSpan = document.createElement('span');
            labelSpan.innerText = item.data.titolo_task;
            labelcell.appendChild(labelSpan);
            row.appendChild(labelcell);

            const start_date = item.data.inizio_task;
            const end_date = item.data.fine_task;

            const startcell = document.createElement('td');
            startcell.innerText = start_date.toLocaleDateString('it-IT', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            row.appendChild(startcell);

            const endcell = document.createElement('td');
            endcell.innerText = end_date.toLocaleDateString('it-IT', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            row.appendChild(endcell);

            const diagram_row = document.createElement('tr');
            for (let j = 0; j < blocknum; j++) {
                const item = document.createElement('td');
                item.innerText = 'spacer';
                diagram_row.appendChild(item);
            }

            let t = 0,
                r = 0;
            for (let j = 0; j < blocknum - 1; j++) {
                const block1 = CALENDAR.blocks[j];
                const block2 = CALENDAR.blocks[j + 1];
                if (item.data.inizio_task >= block1 && item.data.inizio_task < block2) {
                    t = j;
                }
                if (item.data.inizio_task_reale >= block1 && item.data.inizio_task_reale < block2) {
                    r = j;
                }
            }


            const target = diagram_row.children[t];

            target.taskreference = item;
            item.nodereference = target;

            target.setAttribute('data-itemtype', item.data.tipo);
            const svg = item.data.allegati.length > 0 ? `<div style="background-color:transparent; z-index:1;" ><svg xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="#FFFFFF" d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" />
                        </svg></div>` : ``;
            const span = document.createElement('span');
            span.innerText = item.data.descrizione_task != undefined ? item.data.descrizione_task : '';
            target.innerHTML = `
                <figure>
                ${svg}
                <span class="label">${item.data.descrizione_task != undefined? item.data.descrizione_task : ''}
                </span>
                <div>
                <p></p>
                <span>${item.data.completamento}%</span>
                <aside data-type="0" ></aside>
                <aside data-type="1" ></aside>
                </div>
                <span data-realtask="true" ></span> 
                </figure>
                `;

            target.style.setProperty('--realoffset', r - t);
            let tf = blocknum,
                rf = blocknum;
            if (t != blocknum) {
                for (let j = 0; j < blocknum - 1; j++) {
                    const block1 = CALENDAR.blocks[j];
                    const block2 = CALENDAR.blocks[j + 1];
                    if (item.data.fine_task >= block1 && item.data.fine_task < block2) {
                        tf = j;
                    }
                    if (item.data.fine_task_reale >= block1 && item.data.fine_task_reale < block2) {
                        rf = j;
                    }
                }
            }
            target.style.setProperty('--duration', 1 + tf - t);
            target.style.setProperty('--realduration', 1 + rf - r);
            target.style.setProperty('--completion', item.data.completamento);
            diagram_fragment.appendChild(diagram_row);
            fragment.appendChild(row);
            item.treeView.task_row = row;
            item.treeView.diagram_row = diagram_row;
        }
        this.data.forEach(e => {
            e.toggleTreeView(e.treeView.open);
        });
        table.appendChild(fragment);
        diagram.appendChild(diagram_fragment);
    }

    formatDate(date) {
        const year = String(date.getFullYear()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    getTasks() {
        const items = this.items;
        const length = items.length;
        const result = new Array();
        for (let i = 0; i < length; i++) {
            const item = items[i];
            result.push({
                id_task: item.data.id_task,
                id_progetto: item.data.id_progetto,
                id_task_padre: item.data.id_task_padre,
                id_temp:item.data.id_task == -1 ? item.data.id_temp : item.data.id_task,
                id_temp_padre:item.data.id_temp_padre,
                risorse:item.data.risorse,
                tipo: item.data.tipo,
                descrizione_task: item.data.descrizione_task,
                inizio_task: this.formatDate(item.data.inizio_task),
                inizio_task_reale: this.formatDate(item.data.inizio_task_reale),
                fine_task: this.formatDate(item.data.fine_task),
                fine_task_reale: this.formatDate(item.data.fine_task_reale),
                titolo_task: item.data.titolo_task,
                validita: item.data.validita,
            })
        }
        return result;
    }
}
export default GanttTable;