<template>
    <v-dialog persistent v-model="ui.show">
        <v-card>
            <!-- HEADER -->
            <v-toolbar dark dense>
                <v-toolbar-title>Modifica Macro</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="ui.show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-row align="center">
                <v-col cols="2">
                    <!-- TABS DI SINISTRA -->
                    <v-tabs vertical v-model="ui.tabs" >
                        <v-tab v-for="(item,i) in store.macro" :key="i">
                            <v-row align="center">
                                <v-col cols="4" justify="start">
                                    <v-btn icon small @click.stop="remove_macro(i)">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="4" justify="center">Macro {{i+1}}</v-col>
                            </v-row>                                
                        </v-tab>
                        <v-btn text color="success" @click="add_macro">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-tabs>
                </v-col>
                <v-col cols="10">
                    <!-- CONTENUTO -->
                    <v-tabs-items v-model="ui.tabs" >
                        <v-tab-item v-for="(item,i) in store.macro" :key="i">
                            <v-expansion-panels>
                                <!-- ETICHETTA -->
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Etichetta</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row align="center" justify="start">
                                            <v-col cols="6">
                                                <v-text-field v-model="item.text" label="Etichetta" :disabled="item.use_parent"/>
                                            </v-col>
                                            <v-col cols="3" justify="start">
                                                <v-checkbox v-model="item.use_parent" label="Usa genitore" />
                                            </v-col>
                                            <v-col cols="3" justify="start">
                                                <v-checkbox v-model="item.up" label="In cima" />
                                            </v-col>
                                        </v-row>                                        
                                        <v-card-title class="px-0">Formato</v-card-title>
                                        <v-row align="center">
                                            <v-col cols="6">
                                                <span :style="get_style(item)">Abc</span>
                                            </v-col>
                                            <v-col cols="6" align="left">
                                                <v-btn-toggle v-model="item.align">
                                                    <v-btn>
                                                        <v-icon>mdi-format-align-left</v-icon>
                                                    </v-btn>
                                                    <v-btn>
                                                        <v-icon>mdi-format-align-center</v-icon>
                                                    </v-btn>
                                                    <v-btn>
                                                        <v-icon>mdi-format-align-right</v-icon>
                                                    </v-btn>
                                                </v-btn-toggle>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center" justify="center" >                                            
                                            <v-col cols="3">
                                                <v-text-field type="number" min="1" label="Dimensioni Font" v-model="item.font_size"/>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox label="Grassetto" v-model="item.bold"/>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox label="Corsivo" v-model="item.italic"/>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-checkbox label="Sottolineato" v-model="item.underlined"/>
                                            </v-col>                                          
                                        </v-row>
                                        <v-row  justify="center" align="center">
                                            <v-col cols="6" justify="center" align="center">
                                                <span style="display:block;font-size:16px">Sfondo Cella</span>
                                                <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="item.color" canvas-height="100"/>
                                            </v-col>
                                            <v-col cols="6" justify="center" align="center">
                                                <span style="display:block;font-size:16px">Colore Testo</span>
                                                <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="item.font_color" canvas-height="100"/>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <!-- CELLE -->
                                    <v-expansion-panel>
                                    <v-expansion-panel-header>Target e formattazione</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-tabs v-model="ui.target_tabs">
                                            <v-tab v-for="(target,target_name,j) in item.targets" :key="j">{{target_name}}</v-tab>
                                        </v-tabs>
                                        <v-tabs-items v-model="ui.target_tabs">
                                            <v-tab-item v-for="(target,target_name,j) in item.targets" :key="j">
                                                <v-checkbox v-model="target.enabled" label="Abilita"/>
                                                <div v-if="target.enabled==true">
                                                    <v-card-title class="px-0">Formula</v-card-title>
                                                    <p class="helper"><strong>C{#nome_serie}</strong> per il numero di celle, <strong>S{#nome_serie}</strong> per la somma dei valori delle celle
                                                        <br><strong>Esempio (calcolo della media):</strong> S{#nome_serie}/C{#nome_serie}
                                                    </p>
                                                    <v-text-field label="Formula" v-model="target.formula"/>
                                                    <!-- FORMATO NUMERO -->
                                                    <v-card-title class="px-0">Formato Numero</v-card-title>
                                                    <v-row justify="center" >
                                                        <v-col cols="4">
                                                            <v-checkbox label="Separa le migliaia" v-model="target.number.divider"/>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-checkbox label="Limita cifre decimali" v-model="target.number.limit"/>
                                                        </v-col>
                                                        <v-col cols="4">
                                                            <v-text-field type="number" min="0" v-model="target.number.amount"/>
                                                        </v-col>
                                                    </v-row> 
                                                    <v-divider />
                                                    <!-- TABS REGOLE -->
                                                    <v-row align="center">                                                        
                                                        <v-col cols="2">
                                                            <v-tabs v-model="target.tabs" vertical>
                                                                <v-tab v-for="(rule,k) in target.rules" :key="k">
                                                                    <v-row align="center">
                                                                        <v-col cols="4" justify="start">
                                                                            <v-btn icon small @click.stop="remove_rule(target,k)">
                                                                                <v-icon>mdi-close</v-icon>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col cols="4" justify="center">Regola {{k+1}}</v-col>
                                                                    </v-row>
                                                                </v-tab>
                                                                <v-btn text color="success" @click="add_rule(target)">
                                                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                                                </v-btn>
                                                            </v-tabs>
                                                        </v-col>
                                                        <!-- FORMATTAZIONE CELLA MACRO -->
                                                        <v-col cols="10">     
                                                            <v-card-title class="px-0">Formattazione</v-card-title>                                                      
                                                            <v-tabs-items v-model="target.tabs" >
                                                                <v-tab-item v-for="(rule,k) in target.rules" :key="k">                                                                    <!-- CHECKBOX -->
                                                                    
                                                                    <v-row>
                                                                        <v-col cols="4" justify="center">
                                                                            <v-checkbox label="Statica" v-model="rule.static"/>
                                                                        </v-col>
                                                                        <v-col cols="4" justify="center" v-if="rule.static!=true">
                                                                            <v-checkbox label="Range" v-model="rule.range"/>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <!-- INPUT RANGE -->
                                                                    <v-row align="center" justify="start" v-if="rule.range==true&&rule.static!=true">
                                                                        <v-col cols="3" >
                                                                            <p style="text-align:left;font-size:14px">Compreso tra</p>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-text-field v-model="rule.start_ref" type="number"/>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <p style="font-size:14px">e</p>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-text-field v-model="rule.end_ref" type="number"/>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <!-- INPUT VALORE -->
                                                                    <v-row align="center" justify="start" v-if="rule.range!=true&&rule.static!=true">
                                                                        <v-col cols="6" >
                                                                            <v-select v-model="rule.operation" :items="ops" />
                                                                        </v-col>
                                                                        <v-col cols="4">
                                                                            <v-text-field v-model="rule.start_ref" type="number"/>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider />
                                                                    <!-- FORMATO CELLA -->
                                                                    <v-card-title class="px-0">Formato Cella</v-card-title>
                                                                    <v-row align="center">
                                                                        <v-col cols="6">
                                                                            <span :style="get_style(rule)">Abc</span>
                                                                        </v-col>
                                                                        <v-col cols="6" align="left">
                                                                            <v-btn-toggle v-model="rule.align">
                                                                                <v-btn>
                                                                                    <v-icon>mdi-format-align-left</v-icon>
                                                                                </v-btn>
                                                                                <v-btn>
                                                                                    <v-icon>mdi-format-align-center</v-icon>
                                                                                </v-btn>
                                                                                <v-btn>
                                                                                    <v-icon>mdi-format-align-right</v-icon>
                                                                                </v-btn>
                                                                            </v-btn-toggle>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row align="center" justify="center" >
                                                                        <v-col cols="3">
                                                                            <v-text-field type="number" min="1" label="Dimensioni Font" v-model="rule.font_size"/>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-checkbox label="Grassetto" v-model="rule.bold"/>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-checkbox label="Corsivo" v-model="rule.italic"/>
                                                                        </v-col>
                                                                        <v-col cols="3">
                                                                            <v-checkbox label="Sottolineato" v-model="rule.underlined"/>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row  justify="center" align="center">
                                                                        <v-col cols="6" justify="center" align="center">
                                                                            <span style="display:block;font-size:16px">Sfondo Cella</span>
                                                                            <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="rule.color" canvas-height="100"/>
                                                                        </v-col>
                                                                        <v-col cols="6" justify="center" align="center">
                                                                            <span style="display:block;font-size:16px">Colore Testo</span>
                                                                            <v-color-picker mode="hexa" hide-mode-switch hide-s v-model="rule.font_color" canvas-height="100"/>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-divider />
                                                                    <v-card-title class="px-0">Formato Valore</v-card-title>
                                                                    <p class="helper"><strong>{valore}</strong> per il valore della cella</p>
                                                                    <v-text-field label="Formula" v-model="rule.formatter"/>
                                                                </v-tab-item>
                                                            </v-tabs-items>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-tab-item>
                                        </v-tabs-items> 
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
            <!-- TASTO SALVA -->
            <v-card-actions>
                <v-spacer />
                <v-btn text color="success" @click="save">
                    <v-icon class="mr-2">mdi-floppy</v-icon>Salva modifiche</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
<script>
export default {
    name : 'GraphEditorMacro',
    props : {
        value: Object
    },
    data(){
        return{
            index : -1,
            ops : [
                {text:'Minore di',value:0},
                {text:'Minore o uguale a',value:1},
                {text:'Uguale a',value:2},
                {text:'Maggiore o uguale a',value:3},
                {text:'Maggiore di',value:4},
                {text:'Diverso da',value:5},
            ],
            store : {},
            number_template : {                
                limit : false,
                amount : 0,
                divider : false,
            },
            target_template : {
                enabled : false,
                formula : '',
                rules : null,
                tabs : 0,
                number : null,
            },
            target_template_rules : {                
                operation : 0,
                compiled_style : '',
                range : true,
                align : 'center',
                bold : false,
                color : '#ffffff',
                font_color : '#000000',
                font_size : 13,
                italic : false,
                underlined : false,
                start_ref : null,
                end_ref: null,                   
                static : false,   
                formatter : '{valore}'             
            },
            template : {            
                text : null,
                compiled_style : '',
                align : 0,
                bold : false,
                color : '#FFFFFF',
                font_color : '#000000',
                font_size : 13,
                italic : false,
                underlined : false,
                targets : null,
                up : false,
                use_parent : false,
            },
            ui : {
                show : false,
                tabs : 0,
                target_tabs : 0,
            }
        }
    },
    mounted(){
        document.body.addEventListener('graph_editor_macro',this.show_editor);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_editor_macro',this.show_editor);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        add_macro(){
            let new_item = Object.assign({},this.template);
            new_item.targets = {};            
            for(let i=0; i < this.value.serie.length; i++){
                let serie = this.value.serie[i];
                if(serie.tipo_grafico == 3){
                    let target_template = Object.assign({},this.target_template);
                    target_template.rules = [];
                    target_template.number = Object.assign({},this.number_template);
                    new_item.targets[serie.cod] = target_template;
                }
            }
            this.store.macro.push(new_item);
        },
        add_rule(target){
            target.rules.push(Object.assign({},this.target_template_rules));
        },
        remove_macro(index){
            if(this.store.macro[index]==undefined){return;}
            this.store.macro.splice(index,1);
        },
        remove_rule(target,index){
            if(target.rules[index]==undefined){return;}
            target.rules.splice(index,1);
        },
        // Compilazione dello stile inline
        get_style(item,compiled=false){
            if(item.font_size > 60){item.font_size = 60;}
            let align = ['left','center','right'];
            align = align[item.align];
            let style = `background-color:${item.color};            
            text-align:${align};
            font-weight:`+((item.bold)?'bold':'normal')+`;
            color:${item.font_color};
            font-size:${item.font_size}px;
            font-style:`+((item.italic)?'italic':'normal')+`;
            text-decoration:`+((item.underlined)?'underline':'none')+`;`
            if(!compiled){
                style += 'display:block;border:thin solid rgba(0,0,0,.12);max-width:200px';
            }
            return style.replaceAll('\n','');
        },  
        save(){
            // Compilazione macro
            let macros = this.store.macro;
            for(let i=0; i < macros.length; i++){
                macros[i].compiled_style = this.get_style(macros[i],true);
                let keys = Object.keys(macros[i].targets);
                for(let j=0; j < keys.length; j++){
                    let target = macros[i].targets[keys[j]];
                    if(target.enabled != true){continue;}
                    for(let k=0; k < target.rules.length; k++){
                        target.rules[k].compiled_style = this.get_style(target.rules[k],true);
                    }
                }
            }
            let value = Object.assign({},this.value);
            let encoded = JSON.stringify(this.store);
            // codifica del campo EXTRA come json codificato in base64 (con supporto per UTF-8)
            value.serie[this.index].extra = btoa(unescape(encodeURIComponent(encoded)));
            this.$emit('input',value);
            this.ui.show = false;
        },
        show_editor(data){
            
            data = data.detail;
            this.index = this.value.serie.indexOf(data);
            if(this.index < 0){return}
            try{
                // il campo EXTRA è un json codificato in base64 (con supporto per UTF-8)
                this.store = JSON.parse(decodeURIComponent(escape(atob(this.value.serie[this.index].extra))));                
                if(this.store.macro==undefined){
                    this.store = Object.assign({ macro : []}, this.store);
                }
            }catch(error){
                this.store = {macro : []};             
            }

            // Pulizia per transizione versione, rimuovere quando tutte le macro saranno salvate con il nuovo formato
            for(let i=0; i < this.store.macro.length;i++){
                if(this.store.macro[i].targets == undefined){ this.store.macro[i].targets = new Object(); }
                let keys = Object.keys(this.store.macro[i].targets);
                for(let j = 0; j < keys.length; j++){
                    if(this.store.macro[i].targets[keys[j]].number == undefined){                        
                        this.store.macro[i].targets[keys[j]].number = Object.assign({},this.number_template);
                    }
                }                                
            }         
            this.ui.show = true;
        }
    }
}
</script>
<style>
.helper{
    text-align:left;
    color : darkslategray
}
</style>