var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v(" Dettagli Anagrafica "+_vm._s(_vm.anagrafica_sel.tipo == "C" ? "Cliente" : _vm.anagrafica_sel.tipo == "F" ? "Fornitore" : "Nuovo Inserimento")+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaAnagrafica}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"pa-1 mr-5",staticStyle:{"display":"inline-block","width":"100%"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Codice"},model:{value:(_vm.codice_tmp),callback:function ($$v) {_vm.codice_tmp=$$v},expression:"codice_tmp"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pa-1 mr-5",staticStyle:{"display":"inline-block","width":"100%"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Ragione Sociale"},model:{value:(_vm.ragione_sociale_tmp),callback:function ($$v) {_vm.ragione_sociale_tmp=$$v},expression:"ragione_sociale_tmp"}})],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"3"}},[(_vm.anagrafica_sel.tipo == 'N')?_c('v-select',{staticClass:"pa-1 mr-5",staticStyle:{"border-radius":"0px","display":"inline-block","width":"100%"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"auto","item-text":"text","item-value":"id","items":[
              { id: 'C', text: 'Cliente' },
              { id: 'F', text: 'Fornitore' } ],"label":"Tipo","outlined":""},model:{value:(_vm.tipo_tmp),callback:function ($$v) {_vm.tipo_tmp=$$v},expression:"tipo_tmp"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"pa-1 mr-5",staticStyle:{"display":"inline-block","width":"100%"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Indirizzo"},model:{value:(_vm.indirizzo_tmp),callback:function ($$v) {_vm.indirizzo_tmp=$$v},expression:"indirizzo_tmp"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pa-1 mr-5",staticStyle:{"display":"inline-block","width":"100%"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Località"},model:{value:(_vm.localita_tmp),callback:function ($$v) {_vm.localita_tmp=$$v},expression:"localita_tmp"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"pa-1 mr-5",staticStyle:{"display":"inline-block","width":"100%"},attrs:{"min":"0","background-color":"#ffffff","dense":"","hide-details":"","label":"CAP"},model:{value:(_vm.cap_tmp),callback:function ($$v) {_vm.cap_tmp=$$v},expression:"cap_tmp"}})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" "),_c('v-btn',{staticClass:"ml-5",staticStyle:{"background-color":"#ffffff"},attrs:{"color":_vm.snackbar_color,"fab":"","outlined":"","x-small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.snackbar_icon))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }