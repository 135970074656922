<template>
  <v-card style="border-radius: 0px">
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Configurazione Etichetta
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Bottone di salvataggio -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small @click="salvaLabel" v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Bottone di aumento ingrandimento -->
      <v-chip class="mr-2 py-0">
        <v-icon class="mr-2" color="primary">mdi-magnify</v-icon>
        {{ fattore_ingrandimento }}
      </v-chip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="ingrandisci"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary"> mdi-magnify-plus </v-icon>
          </v-btn>
        </template>
        <span>Ingrandisci</span>
      </v-tooltip>

      <!-- Bottone di riduzione ingrandimento -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small @click="rimpicciolisci" v-bind="attrs" v-on="on">
            <v-icon color="primary"> mdi-magnify-minus </v-icon>
          </v-btn>
        </template>
        <span>Rimpicciolisci</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small @click="goBack" v-bind="attrs" v-on="on">
            <v-icon color="error"> mdi-keyboard-backspace </v-icon>
          </v-btn>
        </template>
        <span>Indietro</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text id="mainCardText" class="mt-3">
      <!-- Spazio del grafico -->
      <!-- Renderizzo gli elementi ReportPage -->
      <div
        id="reportPage"
        :key="elem0.id_dettaglio"
        :name="elem0.name"
        :style="{
          border:
            elem0.paperHeight > 0 && elem0.paperWidth > 0
              ? `${1 * fattore_ingrandimento}px solid black`
              : '',
          display: 'inline-block',
          height: elem0.paperHeight * fattore_ingrandimento + 'mm',
          left: '0mm',
          marginBottom:
            elem0.bottom_margin === null
              ? '0px'
              : elem0.bottom_margin * fattore_ingrandimento + 'px',
          marginLeft:
            elem0.left_margin === null
              ? '0px'
              : elem0.left_margin * fattore_ingrandimento + 'px',
          marginRight:
            elem0.right_margin === null
              ? '0px'
              : elem0.right_margin * fattore_ingrandimento + 'px',
          marginTop:
            elem0.top_margin === null
              ? '0px'
              : elem0.top_margin * fattore_ingrandimento + 'px',
          overflow: 'hidden',
          position: 'relative',
          top: '0mm',
          width: elem0.paperWidth * fattore_ingrandimento + 'mm',
        }"
        v-for="elem0 in report.report.filter((e) => e.tipo === 'ReportPage')"
      >
        <!-- Renderizzo gli elementi Report Title Band
               che stanno dentro alla Report Page -->
        <div
          :key="elem1.id_dettaglio"
          :name="elem1.name"
          :style="{
            border:
              elem1.width > 0 && elem1.height > 0
                ? `${1 * fattore_ingrandimento}px solid black`
                : '',
            display: 'inline-block',
            height:
              elem1.height === null
                ? '0px'
                : elem1.height * fattore_ingrandimento + 'px',
            left:
              elem1.left === null
                ? '0px'
                : elem1.left * fattore_ingrandimento + 'px',
            overflow: 'hidden',
            position: 'relative',
            top:
              elem1.top === null
                ? '0px'
                : elem1.top * fattore_ingrandimento + 'px',
            width:
              elem1.width === null
                ? '0px'
                : elem1.width * fattore_ingrandimento + 'px',
          }"
          v-for="elem1 in elem0.sezione.filter(
            (e) => e.tipo === 'ReportTitleBand'
          )"
        ></div>

        <!-- Renderizzo gli elementi Page Header Band
               che stanno dentro alla Report Page -->
        <div
          :key="elem1.id_dettaglio"
          :name="elem1.name"
          :style="{
            border:
              elem1.height > 0 && elem1.width > 0
                ? `${1 * fattore_ingrandimento}px solid black`
                : '',
            display: 'inline-block',
            height:
              elem1.height === null
                ? '0px'
                : elem1.height * fattore_ingrandimento + 'px',
            left:
              elem1.left === null ? '0px' : elem1.left * fattore_ingrandimento,
            marginBottom:
              elem1.bottom_margin === null
                ? '0px'
                : elem1.bottom_margin * fattore_ingrandimento + 'px',
            marginLeft:
              elem1.left_margin === null
                ? '0px'
                : elem1.left_margin * fattore_ingrandimento + 'px',
            marginRight:
              elem1.right_margin === null
                ? '0px'
                : elem1.right_margin * fattore_ingrandimento + 'px',
            marginTop:
              elem1.top_margin === null
                ? '0px'
                : elem1.top_margin * fattore_ingrandimento + 'px',
            overflow: 'hidden',
            position: 'relative',
            top: elem1.top === null ? '0px' : elem1.top * fattore_ingrandimento,
            width:
              elem1.width === null
                ? '0px'
                : elem1.width * fattore_ingrandimento + 'px',
          }"
          v-for="elem1 in elem0.sezione.filter(
            (e) => e.tipo === 'PageHeaderBand'
          )"
        ></div>

        <!-- Renderizzo gli elementi Data Band
               che stanno dentro alla Report Page -->
        <div
          :key="elem1.id_dettaglio"
          :name="elem1.name"
          :style="{
            border:
              elem1.height > 0 && elem1.width > 0
                ? `${1 * fattore_ingrandimento}px solid black`
                : '',
            display: 'inline-block',
            height:
              elem1.height === null
                ? '0px'
                : elem1.height * fattore_ingrandimento + 'px',
            left:
              elem1.left === null
                ? '0px'
                : elem1.left * fattore_ingrandimento + 'px',
            marginBottom:
              elem1.bottom_margin === null
                ? '0px'
                : elem1.bottom_margin * fattore_ingrandimento + 'px',
            marginLeft:
              elem1.left_margin === null
                ? '0px'
                : elem1.left_margin * fattore_ingrandimento + 'px',
            marginRight:
              elem1.right_margin === null
                ? '0px'
                : elem1.right_margin * fattore_ingrandimento + 'px',
            marginTop:
              elem1.top_margin === null
                ? '0px'
                : elem1.top_margin * fattore_ingrandimento + 'px',
            overflow: 'hidden',
            position: 'absolute',
            top:
              elem1.top === null
                ? '0px'
                : elem1.top * fattore_ingrandimento + 'px',
            width:
              elem1.width === null
                ? '0px'
                : elem1.width * fattore_ingrandimento + 'px',
          }"
          v-for="elem1 in elem0.sezione.filter((e) => e.tipo === 'DataBand')"
        >
          <!-- Renderizzo gli elementi Barcode Object
               che stanno dentro alla Data Band -->
          <div
            :key="elem2.id_dettaglio"
            :name="elem2.name"
            :style="{
              border:
                elem2.height > 0 && elem2.width > 0
                  ? `${1 * fattore_ingrandimento}px solid black`
                  : '',
              display: 'inline-block',
              height:
                elem2.height === null
                  ? '0px'
                  : elem2.height * fattore_ingrandimento + 'px',
              left:
                elem2.left === null
                  ? '0px'
                  : elem2.left * fattore_ingrandimento + 'px',
              overflow: 'hidden',
              position: 'absolute',
              top:
                elem2.top === null
                  ? '0px'
                  : elem2.top * fattore_ingrandimento + 'px',
              width:
                elem2.width === null
                  ? '0px'
                  : elem2.width * fattore_ingrandimento + 'px',
            }"
            v-for="elem2 in elem1.oggetto.filter(
              (e) => e.tipo === 'BarcodeObject'
            )"
          >
            <span v-show="elem2.showText != 0">
              {{ elem2.text }}
            </span>
          </div>

          <!-- Renderizzo gli elementi Text Object
               che stanno dentro alla Data Band -->
          <div
            @contextmenu="handleClickDestroTextObject($event, elem2)"
            :key="elem2.id_dettaglio"
            :name="elem2.name"
            :style="{
              backgroundImage: `url('data:image/jpeg;base64,${
                elem2.fillImageData && elem2.fillImageData != null
                  ? elem2.fillImageData
                  : ''
              }')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              border:
                elem2.height > 0 && elem2.width > 0
                  ? `${1 * fattore_ingrandimento}px dashed grey`
                  : '',
              display: 'inline-block',
              fontFamily:
                elem2.font && elem2.font != null
                  ? elem2.font.split(', ')[0]
                  : 'Arial',
              fontSize:
                elem2.font && elem2.font != null
                  ? elem2.font.split(', ').length >= 2
                    ? parseInt(elem2.font.split(', ')[1]) *
                        fattore_ingrandimento +
                      'pt'
                    : '10pt'
                  : '10pt',
              fontWeight: elem2.font
                ? elem2.font.split('style=').length >= 2
                  ? elem2.font.split('style=')[1]
                  : 'normal'
                : 'normal',
              height:
                elem2.height === null
                  ? '0px'
                  : elem2.height * fattore_ingrandimento + 'px',
              left:
                elem2.left === null
                  ? '0px'
                  : elem2.left * fattore_ingrandimento + 'px',
              lineHeight: elem2.font.split(', ')[1],
              marginBottom:
                elem2.bottom_margin === null
                  ? '0px'
                  : elem2.bottom_margin + 'px',
              marginLeft:
                elem2.left_margin === null ? '0px' : elem2.left_margin + 'px',
              marginRight:
                elem2.right_margin === null ? '0px' : elem2.right_margin + 'px',
              marginTop:
                elem2.top_margin === null ? '0px' : elem2.top_margin + 'px',
              overflow: 'hidden',
              paddingLeft: '2px',
              paddingright: '2px',
              position: 'absolute',
              textAlign: 'left',
              top:
                elem2.top === null
                  ? '0px'
                  : elem2.top * fattore_ingrandimento + 'px',
              width:
                elem2.width === null
                  ? '0px'
                  : elem2.width * fattore_ingrandimento + 'px',
            }"
            v-for="elem2 in elem1.oggetto.filter(
              (e) => e.tipo === 'TextObject'
            )"
          >
            {{
              elem2.fillImageData && elem2.fillImageData != null
                ? ""
                : elem2.text
            }}
            <!-- Renderizzo gli elementi Highlight
               che stanno dentro al Text Object -->
            <div
              :key="elem3.id_dettaglio"
              v-for="elem3 in elem2.formato.filter(
                (e) => e.tipo === 'Highlight'
              )"
            >
              <!-- Renderizzo gli elementi Condition
               che stanno dentro a Highlight -->
              <div
                :key="elem4.id_dettaglio"
                v-for="elem4 in elem3.condizione.filter(
                  (e) => e.tipo === 'Condition'
                )"
              ></div>
            </div>
          </div>

          <!-- Renderizzo gli elementi Picture Object
               che stanno dentro al Data Band -->
          <div
            :key="elem2.id_dettaglio"
            :name="elem2.name"
            :style="{
              backgroundImage: `url('data:image/${elem2.imageFormat};base64,${elem2.image}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              border:
                elem2.height > 0 && elem2.width > 0
                  ? `${1 * fattore_ingrandimento}px dashed grey`
                  : '',
              display: 'inline-block',
              height:
                elem2.height === null
                  ? '0px'
                  : elem2.height * fattore_ingrandimento + 'px',
              left:
                elem2.left === null
                  ? '0px'
                  : elem2.left * fattore_ingrandimento + 'px',
              overflow: 'hidden',
              position: 'absolute',
              top:
                elem2.top === null
                  ? '0px'
                  : elem2.top * fattore_ingrandimento + 'px',
              width:
                elem2.width === null
                  ? '0px'
                  : elem2.width * fattore_ingrandimento + 'px',
            }"
            v-for="elem2 in elem1.oggetto.filter(
              (e) => e.tipo === 'PictureObject'
            )"
          ></div>

          <!-- Renderizzo gli elementi Line Object
               che stanno dentro al Data Band -->
          <hr
            :key="elem2.id_dettaglio"
            :name="elem2.name"
            :style="{
              backgroundColor: 'black',
              border: `${
                elem2.borderWidth === null
                  ? 0
                  : (elem2.borderWidth / 2) * fattore_ingrandimento
              }px solid black`,
              display: 'inline-block',
              height:
                elem2.height === null
                  ? '0px'
                  : elem2.height * fattore_ingrandimento + 'px',
              left:
                elem2.left === null
                  ? '0px'
                  : elem2.left * fattore_ingrandimento + 'px',
              position: 'absolute',
              top:
                elem2.top === null
                  ? '0px'
                  : elem2.top * fattore_ingrandimento + 'px',
              width:
                elem2.width === null
                  ? '0px'
                  : elem2.width * fattore_ingrandimento + 'px',
              zIndex: 9,
            }"
            v-for="elem2 in elem1.oggetto.filter(
              (e) => e.tipo === 'LineObject'
            )"
          />
        </div>

        <!-- Renderizzo gli elementi Page Footer Band
               che stanno dentro alla Report Page -->
        <div
          :key="elem1.id_dettaglio"
          :name="elem1.name"
          :style="{
            border:
              elem1.width > 0 && elem1.height > 0
                ? `${1 * fattore_ingrandimento}px solid black`
                : '',
            display: 'inline-block',
            height:
              elem1.height === null
                ? '0px'
                : elem1.height * fattore_ingrandimento + 'px',
            left:
              elem1.left === null
                ? '0px'
                : elem1.left * fattore_ingrandimento + 'px',
            overflow: 'hidden',
            position: 'relative',
            top:
              elem1.top === null
                ? '0px'
                : elem1.top * fattore_ingrandimento + 'px',
            width:
              elem1.width === null
                ? '0px'
                : elem1.width * fattore_ingrandimento + 'px',
          }"
          v-for="elem1 in elem0.sezione.filter(
            (e) => e.tipo === 'PageFooterBand'
          )"
        ></div>

        <!-- Menu contestuale -->
        <v-card
          id="menu_contestuale"
          style="position: absolute; z-index: 10"
          v-show="menu_contestuale_visible"
          width="250px"
        >
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              Menu Contestuale
            </v-toolbar-title>
          </v-toolbar>
          <v-list>
            <v-list-item @click="enterModEditObj">
              <v-list-item-title> Modifica Oggetto </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-card-text>

    <!-- Dialog di modifica degli oggetti -->
    <v-dialog v-model="modifica_dialog">
      <ModificaOggetto
        @chiudi_dialog="handleChiudiDialogModifica"
        :elemento_sel="elemento_sel"
        @salvata_modifica="handleSalvataModifica"
        :testata="report"
        :visible="modifica_dialog"
      >
      </ModificaOggetto>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import _ from "lodash";
import Report from "@/classes/QLReport.js";
import ModificaOggetto from "@/components/QUICKLABEL/ModificaOggetto.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "",
  components: {
    ModificaOggetto,
    SnackbarQV,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      click_down_x: null,
      click_down_y: null,
      click_up_x: null,
      click_up_y: null,
      elemento_sel: new Object(),
      fattore_ingrandimento: 1,
      report: new Report(),

      // Modal
      menu_contestuale_visible: false,
      modifica_dialog: false,

      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudiDialogModifica() {
      this.modifica_dialog = false;
    },
    enterModEditObj() {
      // Rendo invisibile il menu contestuale
      this.menu_contestuale_visible = false;

      // Rendo visibile il dialog di modifica
      this.modifica_dialog = true;
    },
    getLabel() {
      const request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlabel",
          token: localStorage.user_token,
          id_label: 1,
          // pruneNull: true,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            const obj_tmp = Object.assign(res.data.label[0]);
            obj_tmp.report = _.cloneDeep(res.data.report);
            this.report = new Report(obj_tmp);
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata, codice stato: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "error",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    goBack() {
      this.$router.push("/home");
    },
    handleChiudiDialogModifica() {
      this.modifica_dialog = false;
    },
    handleClickDestroTextObject(e, arg_obj) {
      e.preventDefault();

      // Se il menu era visibile lo nascondo
      if (this.menu_contestuale_visible === true) {
        this.menu_contestuale_visible = false;
      } else {
        // Se non era ancora visibile...
        console.log(
          e.clientX -
            document.getElementById("reportPage").getBoundingClientRect().x
        );
        console.log(
          e.clientY -
            document.getElementById("reportPage").getBoundingClientRect().y
        );
        // Registro le coordiante del punto in cui ho clickato.
        this.click_down_x = e.clientX;
        this.click_down_y = e.clientY;
        this.click_up_x = e.clientX;
        this.click_up_y = e.clientY;

        const rect = document
          .getElementById("reportPage")
          .getBoundingClientRect();

        let computedX = Math.round(e.clientX - rect.x);
        let computedY = Math.round(e.clientY - rect.y);

        document.getElementById("menu_contestuale").style.left =
          computedX + "px";
        document.getElementById("menu_contestuale").style.top =
          computedY + "px";

        // Copio il nodo che ho selezionato per un'eventuale modifica.
        this.elemento_sel = Object.assign(arg_obj);

        // Rendo visibile il menun contestuale
        this.menu_contestuale_visible = true;
      }
    },
    handleSalvataModifica(arg) {
      console.log("lunghezza report:" + this.report.report.length);
      this.modifica_dialog = false;
      for (let s = 0; s < this.report.report[0].sezione.length; s++) {
        if (this.report.report[0].sezione[s].oggetto.length > 0) {
          for (
            let o = 0;
            o < this.report.report[0].sezione[s].oggetto.length;
            o++
          ) {
            if (
              this.report.report[0].sezione[s].oggetto[o].id_label_dettaglio ===
              arg.id_label_dettaglio
            ) {
              console.log("entrato 2");
              this.$set(this.report.report[0].sezione[s].oggetto, o, _.cloneDeep(arg));
            } else {
              console.log(
                `no buono: ${this.report.report[0].sezione[s].oggetto[o].id_label_dettaglio} ${arg.id_label_dettaglio}.`
              );
            }
          }
        }
      }
    },
    ingrandisci() {
      let fattore_tmp = this.fattore_ingrandimento + 0.5;

      if (fattore_tmp <= 2) {
        this.fattore_ingrandimento = fattore_tmp;
      }
    },
    rimpicciolisci() {
      let fattore_tmp = this.fattore_ingrandimento - 0.5;

      if (fattore_tmp >= 0.5) {
        this.fattore_ingrandimento = fattore_tmp;
      }
    },
    salvaLabel() {
      // Definisco la testata per il salvataggio;
      const testata_tmp = new Report(this.report);
      delete testata_tmp.report;

      /* Emetto un evento per lasciare che il componente
       * padre si aggiorni e lanci la gestione della
       * chiusura del dialog del figlio.*/
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setlabel",
          token: localStorage.user_token,
          label: [testata_tmp],
          report: [this.report.report],
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.SnackbarQV.print(
              "success",
              "Etichetta salvata con successo!"
            );
            this.getLabel();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Si è verificata una criticità tecnica con codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    uploadImage(val, dim_x, dim_y) {
      /* Se l'argomento è nullo non faccio niente
       * (magari ho appena pulito il campo del dialog). */
      if (val === null) return;

      /* Se invece l'argomento è un'immagine, comincio a eseguire
       * la logica di salvataggio per importarla nel canvas SVG
       * e chiudo il dialog di upload dell'immagine. */

      let that = this;

      let reader = new FileReader();

      reader.onload = function () {
        // Registro l'immagine come Base64.

        let img_tmp = new Image();

        img_tmp.width = dim_x;
        img_tmp.height = dim_y;

        that.$nextTick(() => (img_tmp.src = reader.result));

        return 'url("' + img_tmp.src + '")';
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

      reader.readAsDataURL(val);
    },
  },
  created() {},
  mounted() {
    this.getLabel();
  },
};
</script>
<style scoped>
</style>