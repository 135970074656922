<template>
  <v-dialog
    :scrollable="false"
    persistent
    v-model="dialogSingolaAttivita"
    width="800"
  >
    <template v-slot:activator="{ on }">
      <v-icon
        v-show="
          Object.keys(item).length != 0 &&
          item.id_ticket_attivita != undefined &&
          item.id_ticket_attivita != -1
        "
        color="primary"
        medium
        v-on="on"
      >
        mdi-pencil
      </v-icon>
      <div v-on="on">
        <v-tooltip
          v-if="
            Object.keys(item).length == 0 ||
            item.id_ticket_attivita == undefined ||
            item.id_ticket_attivita == -1
          "
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-on="on" v-bind="attrs"
              ><v-icon color="primary">mdi-note-plus</v-icon>
            </v-btn>
          </template>
          <span>
            Aggiungi Attività
            {{ assistenza == 1 ? "Assistenza" : "" }}</span
          >
        </v-tooltip>
      </div>
    </template>
    <div class="main-div ml-auto mr-auto py-0">
      <v-card>
        <v-toolbar class="indigo white--text" dense primary-title>
          <v-toolbar-title>
            {{
              single_item.id_ticket_attivita != -1
                ? " Modifica Attività ticket"
                : "Crea Attività"
            }}
            {{ assistenza == 1 ? "Assistenza " : " " }}
            {{
              single_item.id_ticket_attivita != -1 ? this.item.id_ticket : ""
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!valid || saving"
                @click="validate()"
                small
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Invia Attività</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="dialogSingolaAttivita = false"
                small
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container fluid>
              <v-row class="text-start">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    dense
                    v-model="single_item.des_attivita"
                    type="text"
                    label="Descrizione attività"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    dense
                    v-model="single_item.id_ticket_risorsa"
                    :items="utenti"
                    label="Risorsa"
                    :get-label="getLabel"
                    item-value="id_ticket_risorsa"
                    item-text="nominativo"
                    placeholder="Digita per cercare"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="start_date"
                        autocomplete="off"
                        label="Data Inizio"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_date"
                      no-title
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="end_date"
                        label="Data Fine"
                        autocomplete="off"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_date"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" :md="3" class="py-0">
                  <v-text-field
                    dense
                    v-model="single_item.ore"
                    label="Quantita"
                    type="number"
                    min="0"
                    max="1000"
                    step="1"
                    onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :md="3" class="py-0">
                  <v-select
                    dense
                    v-model="int_id_um_ore"
                    :items="misure"
                    label="Unità"
                    item-text="des"
                    item-value="id_tabella"
                  >
                  </v-select>
                </v-col>
                <!-- <v-col cols="12" :md="1" class="py-0">
                  <v-text-field outlined dense
                    v-model="single_item.progressione"
                    label="Progressione"
                    type="number"
                    min="0"
                    max="1"
                    step='0.01'
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";

function open(that) {
  let listaMisura = {
    controller: "Global",
    method: "POST",
    richiesta: {
      action: "gettabella",
      token: localStorage.user_token,
      filter: "validita = 1",
      tipo: "UNITA_MISURA",
      assistenza: that.assistenza,
    },
  };

  that.$store.dispatch("api", listaMisura).then((res) => {
    res.data.map((el) => {
      if (el.val_txt === "H") {
        that.misure.push(el);
      }
    });
  });

  if (that.item.id_ticket_attivita) {
    that.single_item = that.item;
    that.start_date = that.single_item.data_inizio
      ? moment(that.single_item.data_inizio).format("YYYY-MM-DD")
      : null;
    that.end_date = that.single_item.data_fine
      ? moment(that.single_item.data_fine).format("YYYY-MM-DD")
      : null;
  }
  var reqUsers = {
    controller: "Ticket",
    method: "POST",
    richiesta: {
      action: "getticket",
      token: localStorage.user_token,
      id_ticket:
        that.ticket != undefined ? that.ticket.id_ticket : that.item.id_ticket,
      id_stato: "",
      assistenza: that.assistenza,
    },
  };

  that.$store.dispatch("api", reqUsers).then((res) => {
    that.utenti = res.data.result.tickets_risorsa.filter(
      (e) =>
        e.id_ticket ==
        (that.ticket != undefined ? that.ticket.id_ticket : that.item.id_ticket)
    );
  });
}

export default {
  name: "AddActivity",

  data: () => ({
    dialogSingolaAttivita: false,
    id: -1,
    menu: false,
    menu2: false,
    single_item: {
      id_ticket_attivita: -1,
      id_responsabile: "",
      id_ticket_risorsa: "",
      id_risorsa: "",
      data_inizio: null,
      data_fine: null,
      ore: "",
      id_unita_misura: "",
      des_attivita: "",
      validita: 1,
      id_utente: null,
    },
    end_date: null,
    misure: [],
    saving: false,
    start_date: null,
    utenti: [],
  }),
  props: {
    item: Object,
    ticket: Object,
    tipo_ticket: Number,
    assistenza: Number,
  },
  mounted() {},
  watch: {
    dialogSingolaAttivita: function (newM, oldM) {
      if (newM == true) {
        if (this.ticket != undefined && Object.keys(this.ticket).length !== 0) {
          this.single_item.id_ticket = this.ticket.id_ticket;
        } else {
          this.single_item.id_ticket = this.item.id_ticket;
        }
      }
      if (newM != oldM && newM != false) {
        open(this);
      } else {
        this.start_date = null;
        this.end_date = null;
        this.single_item = {
          id_ticket_attivita: -1,
          id_responsabile: "",
          id_ticket_risorsa: "",
          id_risorsa: "",
          data_inizio: null,
          data_fine: null,
          ore: "",
          id_unita_misura: "",
          des_attivita: "",
          validita: 1,
          id_utente: null,
        };
      }
    },
  },
  computed: {
    int_id_um_ore: {
      get: function () {
        return parseInt(this.single_item.id_unita_misura);
      },
      set: function (newValue) {
        this.single_item.id_unita_misura = newValue;
        return parseInt(this.single_item.id_unita_misura);
      },
    },
    valid() {
      return this.single_item.des_attivita != "" &&
        this.single_item.id_ticket_risorsa != "" &&
        this.single_item.id_unita_misura != "" &&
        this.single_item.ore != ""
        ? true
        : false;
    },
  },
  methods: {
    getLabel() {
      console.log("aa");
      console.log("www.google.com");
    },
    validate() {
      this.single_item.id_utente = localStorage.id_utente;
      this.single_item.data_inizio = this.start_date;
      this.single_item.data_fine = this.end_date;

      var user = this.utenti.filter((u) => {
        if (u.id_ticket_risorsa == this.single_item.id_ticket_risorsa) {
          return u;
        }
      });
      this.single_item.id_risorsa = user[0].id_risorsa;
      // this.single_item.id_ticket = ;
      console.log(this.single_item);
      this.saving = true;
      var requestAddActivity = {
        controller: "Ticket",
        method: "PUT",
        richiesta: {
          action: "setticketattivita",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          id_azienda: localStorage.id_azienda,
          tabella: [this.single_item],
          assistenza: this.assistenza,
        },
      };
      // console.log(JSON.stringify(requestAddActivity.richiesta))
      this.$store.dispatch("api", requestAddActivity).then((res) => {
        this.dialogSingolaAttivita = false;
        this.$emit("dialogActivity", true);
        console.log("res dialog activity", res);
        this.saving = false;
      });
    },
  },
};
</script>