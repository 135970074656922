<template>
  <v-card id="listener_attivita">
    <v-toolbar dense class="indigo white--text" primary-title>
      <v-toolbar-title class="ml-2"
        >Risorse {{ assistenza == 1 ? "Assistenza" : "" }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <AddResource
            v-on="on"
            v-bind="attrs"
            :ticket="ticket"
            v-on:dialogModule="emitActivities"
            :assistenza="assistenza"
          ></AddResource>
        </template>
        <span>Crea Risorse</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <AddActivity
            v-on="on"
            v-bind="attrs"
            v-if="id_ticket != -1"
            :item="{}"
            :ticket="ticket"
            v-on:dialogActivity="emitActivities"
            :assistenza="assistenza"
          ></AddActivity>
        </template>
        <span>Crea Attività</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="showDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="closeActivities()" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Indietro</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear indeterminate color="cyan" v-if="loading" id="progress">
    </v-progress-linear>
    <v-card-text>
      <v-data-table
        :headers="headersRisorse"
        :items="items"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id_risorsa"
        show-expand
        :items-per-page="15"
        class="elevation-1 mt-4"
      >
        <template v-slot:[`item.ore_assegnate`]="{ item }">
          {{ item.ore_assegnate }}
        </template>
        <template v-slot:[`item.numero_attivita`]="{ item }">
          {{ (item.tickets_attivita != null) ? item.tickets_attivita.length : 0 }}
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon medium @click="deleteItemRisorsa(items.indexOf(item))">
            mdi-close
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            class="pa-0"
            style="background-color: white"
            :colspan="headers.length"
          >
            <v-data-table
              :headers="headersAttivita"
              :items="item.tickets_attivita"
              item-key="id_ticket_attivita"
              :items-per-page="15"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item.edit`]="{ item }">
                <AddActivity
                  :assistenza="assistenza"
                  :item="item"
                  v-on:dialogActivity="emitActivities"
                />
              </template>
              <template v-slot:[`item.time`]="{ item }">
                {{ item.ore }} {{ item.unita_misura }}
              </template>
              <template v-slot:[`item.data_inizio`]="{ item }">
                {{ convertDate(item.data_inizio) }}
              </template>
              <template v-slot:[`item.data_fine`]="{ item }">
                {{ convertDate(item.data_fine) }}
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <v-icon
                  medium
                  @click="deleteItemAttivita(attivita.indexOf(item))"
                >
                  mdi-close
                </v-icon>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="modalDelete" max-width="290">
      <v-card>
        <v-card-title class="headline">Spostamento</v-card-title>
        <v-card-text>Sicuro di voler eliminare?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="modalDelete = false"
            >Non eliminare</v-btn
          >

          <v-btn
            color="primary darken-1"
            text
            @click="deleteItem(attivita.indexOf(item))"
            >Elimina</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import AddActivity from "./AddActivity";
import AddResource from "./AddResource";

function updateList(that) {
  that.attivita = [];
  that.items = [];
  var requestActivities = {
    controller: "Ticket",
    method: "POST",
    richiesta: {
      action: "getticket",
      token: localStorage.user_token,
      id_ticket: that.ticket.id_ticket,
      id_stato: "",
      tipo_ticket: that.ticket.id_tipo_ticket,
      assistenza: that.assistenza,
    },
  };

  that.$store.dispatch("api", requestActivities).then((res) => {
    if (res.data.result.tickets_risorsa != undefined) {
      that.items = res.data.result.tickets_risorsa.filter(
        (e) => e.id_ticket == that.id_ticket
      );
      that.items.forEach((element) => {
        if (
          element.tickets_attivita != null &&
          element.id_ticket == that.id_ticket
        ) {
          that.attivita = that.attivita.concat(element.tickets_attivita);
        }
      });
    }
    that.loading = false;
  });
}

export default {
  name: "Activities",

  data: () => ({
    attivita: [],
    des: "",
    expanded: [],
    headersAttivita: [
      {
        text: "Modifica",
        value: "edit",
      },
      {
        text: "Descrizione",
        value: "des_attivita",
      },
      {
        text: "Risorsa",
        value: "nominativo",
      },
      {
        text: "Data Inizio",
        value: "data_inizio",
      },
      {
        text: "Data Fine",
        value: "data_fine",
      },
      {
        text: "Tempo",
        align: "center",
        value: "ore",
      },
      {
        text: "Elimina",
        align: "center",
        value: "delete",
      },
    ],
    headersRisorse: [
      {
        text: "Nominativo",
        value: "nominativo",
      },
      {
        text: "Ore Assegnate",
        align: "center",
        value: "ore_assegnate",
      },
      {
        text: "Numero Attività",
        align: "center",
        value: "numero_attivita",
      },
      {
        text: "Elimina",
        align: "center",
        value: "delete",
      },
    ],
    id_ticket: null,
    items: [],
    itemsPerPage: 2,
    list_view: true,
    loading: false,
    modalDelete: false,
    page: 1,
    search: "",
    singleExpand: true,
  }),
  props: {
    assistenza: Number,
    dialogActivities: Boolean,
    showDialog: Boolean,
    ticket: Object,
  },
  components: {
    AddActivity,
    AddResource,
  },
  created() {
    this.id_ticket = this.ticket.id_ticket;
    updateList(this);
  },
  watch: {
    dialogActivities: function (newA, oldA) {
      if (oldA != newA && newA != false) {
        this.loading = true;
        updateList(this);
      }
    },
    // ticket: function () {
    //   if (Object.keys(this.local_ticket).length != 0) {
    //     console.log(this.local_ticket);
    //     updateList(this);
    //   }
    // },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  methods: {
    convertDate(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    changeView() {
      this.list_view = !this.list_view;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    emitActivities() {
      updateList(this);
    },
    closeActivities() {
      this.$emit("dialogActivities", true);
    },
    deleteItemAttivita(id) {
      if (confirm("Sicuro di voler eliminare?")) {
        this.attivita[id].validita = 0;
        var removeItem = {
          controller: "Ticket",
          method: "PUT",
          richiesta: {
            action: "setticketattivita",
            token: localStorage.user_token,
            id_utente: localStorage.id,
            id_azienda: localStorage.id_azienda,
            tabella: [this.attivita[id]],
            assistenza: this.assistenza,
          },
        };
        this.$store.dispatch("api", removeItem).then((res) => {
          if (res.status == 200) {
            this.attivita.splice(id, 1);
            this.modalDelete = false;
            updateList(this);
          }
        });
      }
    },
    deleteItemRisorsa(id) {
      if (confirm("Sicuro di voler eliminare?")) {
        this.items[id].validita = 0;
        var request = {
          controller: "Ticket",
          method: "PUT",
          richiesta: {
            action: "setticketrisorsa",
            token: localStorage.user_token,
            id_utente: localStorage.id,
            tabella: [this.items[id]],
            assistenza: this.assistenza,
          },
        };
        // console.log(JSON.stringify(request.richiesta))
        this.$store.dispatch("api", request).then((res) => {
          if (res.status == 200) {
            this.items.splice(id, 1);
            this.modalDelete = false;
            updateList(this);
          }
        });
      }
    },
  },
};
</script>