<template>
	<div>
		<v-snackbar content-class="snackbar" :color="snackbar_color" right v-model="snackbar" :timeout="3000">
			<v-icon :size="64">{{ mainIcon }}</v-icon>
			<h2 class="ml-16">{{ snackbar_text }}</h2>
			<v-btn
				@click="snackbar = false"
				fab
				dark
				text
				small
				style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
			>
				<v-icon>mdi-window-close</v-icon>
			</v-btn>
		</v-snackbar>

		<v-card>
			<v-toolbar dense class="indigo darken-1 headline" primary-title>
				<v-toolbar-title class="white--text">Anagrafica QuickConnext Box </v-toolbar-title>
				<v-spacer></v-spacer>
				<Button
					stile_bottone="float:center"
					colore_icona="primary"
					mdi_icona="mdi-plus"
					testo_tooltip="Aggiornare"
					v-on:buttonClick="openModal"
				/>
				<v-spacer></v-spacer>
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn @click="salvaMTNode" v-on="on" v-bind="attrs" small>
							<v-icon color="success"> mdi-floppy </v-icon>
						</v-btn>
					</template>
					<span>Salva</span>
				</v-tooltip>
				<v-spacer></v-spacer> -->
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-on="on" v-bind="attrs" @click="clickChiudi" small>
							<v-icon depressed class="" color="error">mdi-keyboard-backspace</v-icon>
						</v-btn>
					</template>
					<span>Cancellare</span>
				</v-tooltip>
			</v-toolbar>

			<v-dialog hide-overflow v-model="pidetail" width="600">
				<v-card>
					<v-toolbar dense class="indigo darken-1 headline" primary-title>
						<v-toolbar-title class="white--text">Box Dettaglio: </v-toolbar-title>

						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-on="on" v-bind="attrs" @click="pidetail = false" small>
									<v-icon depressed class="" color="error">mdi-window-close</v-icon>
								</v-btn>
							</template>
							<span>Cancellare</span>
						</v-tooltip>
					</v-toolbar>

					<v-card-text>
						<v-row>
							<v-col class="mt-4" md="4">
								<v-header style="width:100%">Quickbox Nome</v-header>
								<v-subheader>

									<v-icon class="mr-2">mdi-raspberry-pi</v-icon>
									{{ nome }}
										<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
									<v-icon v-on="on" v-bind="attrs" class="mt-1" x-large :color="box_status=='running' ? 'green' : 'red'">mdi-circle-small</v-icon>
						</template>
						<span>{{box_status}}</span>
										</v-tooltip>
								</v-subheader>
							</v-col>
							<v-col class="mt-2" md="8">
								<v-card class="mt-2" max-width="400" tile>
									<v-list dense>
										<v-header>Macchines Connected</v-header>
										<v-list-item-group v-model="selectedItem" color="primary">
											<v-list-item v-for="(item, i) in items" :key="i">
												<v-list-item-icon>
													<v-icon v-text="item.icon"></v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title v-text="item.text"></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-dialog hide-overflow v-model="anagraficadialog" width="800">
				<v-card>
					<v-toolbar dense class="indigo darken-1 headline" primary-title>
						<v-toolbar-title class="white--text">Anagrafica QC Box</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn  v-on="on" v-bind="attrs" small>
									<v-icon color="success"> mdi-floppy </v-icon>
								</v-btn>
							</template>
							<span>Salva</span>
						</v-tooltip>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-on="on" v-bind="attrs" @click="anagraficadialog = false" small>
									<v-icon depressed class="" color="error">mdi-window-close</v-icon>
								</v-btn>
							</template>
							<span>Cancellare</span>
						</v-tooltip>
					</v-toolbar>

					<v-card-text>
						<v-row>
							<v-col md="6">
								<TextField
									idForm=""
									idOggetto=""
									label_input="Nome"
									palceholder_input="Nome"
									:vmodel="nome"
									v-on:changeText="(val) => (this.nome = val)"
								/>
							</v-col>
							<v-col md="6">
								<TextField
									idForm=""
									idOggetto=""
									label_input="Descrizione"
									palceholder_input="Descrizione"
									:vmodel="des"
									v-on:changeText="(val) => (this.des = val)"
								/>
							</v-col>
							<v-col md="6">
								<TextField
									idForm=""
									idOggetto=""
									label_input="Username"
									palceholder_input="Username"
									:vmodel="username"
									v-on:changeText="(val) => (this.username = val)"
								/>
							</v-col>
							<v-col md="6">
								<TextField
									v-if="new_pass"
									idForm=""
									idOggetto=""
									label_input="Password"
									palceholder_input="Password"
									:vmodel="password"
									v-on:changeText="(val) => (this.password = val)"
								/>
								<v-btn v-if="new_pass == false" @click="cambiaPassword">
									<v-icon color="amber darken-3">mdi-lock</v-icon>
									<span>Cambia Password</span>
								</v-btn>
							</v-col>
							<v-col md="6">
								<TextField
									idForm=""
									idOggetto=""
									label_input="Note"
									palceholder_input="Note"
									:vmodel="note"
									v-on:changeText="(val) => (this.note = val)"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>

			<!-- Terminal dialog -->
			<v-dialog hide-overflow v-model="terminalDialog" width="800">
				<v-card>
					<v-toolbar dense class="indigo darken-1 headline" primary-title>
						<v-toolbar-title class="white--text">Anagrafica QC Box Terminal</v-toolbar-title>

						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-on="on" v-bind="attrs" @click="terminalDialog = false" small>
									<v-icon depressed class="" color="error">mdi-window-close</v-icon>
								</v-btn>
							</template>
							<span>Cancellare</span>
						</v-tooltip>
					</v-toolbar>

					<v-card-text>
						<v-row>
							<v-col md="5">
								<v-text-field
									idForm=""
									idOggetto=""
									label="Commands"
									palceholder="Commands"
									@keydown.enter="sendRequest"
									v-model="command"

								></v-text-field>



							</v-col>
							<v-col md="1" class="mt-6 pl-0">
									<Button
							colore_icona="primary"
							mdi_icona="mdi-console-line"
							testo_tooltip="Invio"
							v-on:buttonClick="sendRequest"
						/>
							</v-col>
							<v-col md="6" class="mt-5">
								<h1>Details</h1>
							{{this.commandResponse}}
							</v-col>

						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
			<!-- </v-toolbar> -->
			<v-card-text>
				<v-data-table :items="BoxItem" :headers="BoxHeader" class="elevation-1">
					<template v-slot:[`item.edit`]="{ item }">
						<Button
							colore_icona="primary"
							mdi_icona="mdi-pencil"
							testo_tooltip="Aggiornare"
							v-on:buttonClick="handleclick(item)"
						/>

						<Button
							colore_icona="success"
							classe_bottone="ml-3"
							mdi_icona="mdi-raspberry-pi"
							testo_tooltip="Aggiornare"
							v-on:buttonClick="openPiInfo(item)"
						/>
						<Button
							colore_icona="black"
							classe_bottone="ml-3"
							mdi_icona="mdi-console"
							testo_tooltip="Invio Commands"
							v-on:buttonClick="terminalDialog = true"
						/>
					</template>

					<template v-slot:[`item.delete`]="{ item }">
						<Button
							colore_icona="error"
							mdi_icona="mdi-window-close"
							testo_tooltip="Cancellare"
							v-on:buttonClick="deleteNode(item)"
						/>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import Button from '@/components/TOOLS/FormComponentPermissions/Button';
import TextField from '@/components/TOOLS/FormComponentPermissions/TextField';
export default {
	components: {
		Button,
		TextField,
	},
	props: ['id_macchina'],
	data() {
		return {
			selectedItem:"",
			snackbar: false,
			snackbar_color: '',
			mainIcon: '',
			snackbar_text: '',
			box_status: '',
			commandResponse:null,
			// Dailog Fase Standard
			command:"",
			anagraficadialog: false,
			terminalDialog:false,
			pidetail: false,
			id: null,
			nome: '',
			username: '',
			des: '',
			note: '',
			password: '',
			new_pass: false,
			selected_box: null,
			BoxHeader: [
				{
					value: 'edit',
					sortable: false,
				},
				{
					value: 'info',
					sortable: false,
				},
				{
					text: 'Nome',
					value: 'device_name',
					sortable: false,
				},




				{
					value: 'delete',
					sortable: false,
				},
			],

			BoxItem: [

			],
			items: [
				{ text: 'VM_thermostate', icon: 'mdi-robot-industrial' },
				{ text: 'Measuring Macchine', icon: 'mdi-robot-industrial' },
				{ text: 'Oven TH', icon: 'mdi-robot-industrial' },
			],
		};
	},

	mounted() {
		this.getPiList()
	},
	methods: {
		sendRequest(){
			this.commandResponse = null;
						const request = {
				controller : 'Connext',
				method : 'PUT',
				richiesta : {
					action : 'sendcommand',
					token : localStorage.user_token,
					cmd:this.command,
					device_id:this.BoxItem[0].device_id
				}

			};

			this.$store.dispatch("api", request).then((response) => {
				this.commandResponse = response.data;
				console.log(`this.BoxItem`, response)
			});
		},
		// to open the detail of each pi
		openPiInfo(item) {
			this.pidetail = true;
			this.selected_box = item;
			this.nome = item.nome;
			this.box_status = item.status

		},
		// to clear the form at new creation
		openModal() {
			this.anagraficadialog = true;
			this.new_pass = true;
			this.id = -1;
			this.nome = '';
			this.note = '';
			this.password = '';
			this.des = '';
			this.username = '';
		},
		cambiaPassword() {
			this.new_pass = true;
			this.password = '';
		},
		// To delete single node
		deleteNode(item) {
			let i = this.BoxItem.indexOf((it) => it == item);
			this.BoxItem.splice(i, 1);
		},
		// TO Toggle the Table
		getPiList(){
			const request = {
				controller : 'Connext',
				method : 'POST',
				richiesta : {
					action : 'getdevicelist',
					token : localStorage.user_token
				}
			};
			this.$store.dispatch("api", request).then((response) => {
				this.BoxItem = response.data;
				console.log(`this.BoxItem`, response)
			});
		},
		// setPiList() {
		// 	if (this.nome == null || this.nome == '' || this.nome == undefined) {
		// 		return;
		// 	}

		// 	const request = {
		// 		controller : 'Connext',
		// 		method : 'PUT',
		// 		richiesta : {
		// 			action : 'SetListaConnext',
		// 			token : localStorage.user_token,
		// 			data : {
		// 				nome : this.nome,
		// 				note : this.note,
		// 				password : this.password,
		// 				username : this.username,
		// 				descrizione : this.descrizione,
		// 				id : this.id,
		// 				new_pass : this.new_pass
		// 			}
		// 		}
		// 	};
		// 	this.$store.dispatch("api", request).then((response) => {
		// 		console.log(response);
		// 	});

		// },
		clickChiudi() {
			this.$emit('click_chiudi');
		},

		handleclick(it) {
			this.anagraficadialog = true;
			this.id = it.id;
			this.nome = it.nome;
			this.des = it.descrizione;
			this.username = it.username;
			this.note = it.note;
			this.password = it.password;
			this.new_pass = false;
		},

		closeModal() {
			console.log('this.origionalList xlose; :>> ', this.origionalList);
			this.origionalList.map((it) => (it.isSelected = false));
			this.programModal = false;
			this.programlista = this.origionalList;
			this.id_programma = -1;
			this.getmacchinaProgramma();
		},
	},
};
</script>
