<template>
  <div>
    <v-card>
      <v-toolbar primary-title dense color="indigo">
        <v-toolbar-title class="white--text">Ricerca Magazzino</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="float: left !important" @click="clickChiudi" small>
              <v-icon v-on="on" v-bind="attrs" color="red">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-subtitle>
        <v-row>
          <v-col cols="2" md="2" justify="center" class="py-0">
            <v-text-field
              label="Cod. Articolo"
              placeholder="Cod. Articolo"
              v-model="cod_articolo"
              @blur="getArticolo()"
              clearable
            ></v-text-field>
          </v-col>
          <v-col md="2" justify="center" class="py-0">
            <TextField
              clearable_input
              idForm="ARTICOLO_MAGAZZINO"
              idOggetto="CODICE_LOTTO"
              label_input="Cod. Lotto"
              palceholder_input="Cod. Lotto"
              :vmodel="cod_lotto"
              v-on:changeText="(val) => (this.cod_lotto = val)"
            />
          </v-col>
          <v-col md="3" justify="center" class="py-0">
            <v-autocomplete
              clearable
              :disabled="this.magazzino_list.length == 0"
              v-model="id_magazzino"
              :items="magazzino_list"
              item-text="des_magazzino"
              label="Magazzino"
              placeholder="Magazzino"
              required
              item-value="id_magazzino"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="2" justify="center" class="py-0">
            <v-menu
              v-model="menuDataDa"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="data_da"
                  label="Data Da"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                style="width: 100% !important"
                v-model="data_da"
                @input="menuDataDa = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2" justify="center" class="py-0">
            <v-menu
              v-model="menuDataAa"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="data_aa"
                  label="Data A"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                style="width: 100% !important"
                v-model="data_aa"
                @input="menuDataAa = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1" md="1">
            <Button
              classe_bottone="mt-2"
              colore_icona="primary"
              idForm="ARTICOLO_MAGAZZINO"
              idOggetto="CERCA_MAGAZZINO"
              mdi_icona="mdi-magnify"
              testo_tooltip="Cerca Magazzino"
              v-on:buttonClick="cercaMagazzino"
            />
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table></v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import TextField from "../../TOOLS/FormComponentPermissions/TextField";
import Button from "../../TOOLS/FormComponentPermissions/Button";
export default {
  components: {
    TextField,
    Button,
  },
  props: {
    codArticolo: {
      type: String,
    },
    codLotto: {
      type: String,
    },
    idMagazzino: {
      type: Number,
    },
    dataDA: {
      type: String,
    },
    dataA: {
      type: String,
    },
  },
  data() {
    return {
      cod_lotto: "",
      cod_articolo: "",
      id_articolo: null,
      id_magazzino: null,
      magazzino_list: [],
      menuDataDa: false,
      menuDataAa: false,
      data_da: "",
      data_aa: "",
      headerMagazzino: [],
      dataMagazzino: [],
    };
  },
  //   mounted() {
  //     this.getMagazzini();
  //   },
  methods: {
    // TO Toggle the Table
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    getArticolo() {
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
        },
      };
      this.$store.dispatch("api", search_Articolo).then((res) => {
        this.id_articolo = res.data.dettaglio[0].id_articolo;
        this.getMagazzini();
      });
    },
    getMagazzini() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getmagazzini",
          id_articolo: this.id_articolo,
          token: localStorage.getItem("user_token"),
        },
      };
      console.log(`request`, request);
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          // Registro i magazzini della response
          this.magazzino_list = res.data;
        }
      });
    },
    // To search the magazzines
    cercaMagazzino() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getmovimentimagazzino",
          cod_articolo: this.cod_articolo ? this.cod_articolo : this.codArticolo,
          cod_lotto: this.cod_lotto ? this.cod_lotto : this.codLotto,
          id_magazzino: this.id_magazzino ? this.id_magazzino : this.idMagazzino,
          data_da: this.data_da ? this.data_da : this.dataDA,
          data_a: this.data_aa ? this.data_aa : this.dataA,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        console.log(`res.data`, res.data);
      });
    },
  },
};
</script>
