class DASHBOARD_OVERVIEW {
    dialog = false;
    dialogElement = null;
    cached = null;

    loading = false;

    error = {
        occurred : false,
        status_code : null,
        status_text : null,
    };

    model_utenti = [];
    model_profili = [];
    utenti = [];
    profili = [];

    isAdmin(){return Number(localStorage.id_profilo)==1;}

    process_image(data, that) {
        if (data.target.files.length < 1) { return }
        let pr = function () {
            return new Promise(function (resolve) {
                let FR = new FileReader();
                let file = data.target.files[0];
                FR.onload = async function (ev) {
                    let compressed = await that.image_compress(ev.target.result, [[400, 0.7], [1920, 0.7]]);
                    let brightness = await that.getImageLightness(compressed[0]);
                    requestAnimationFrame(function () {
                        let target_image =  data.target.parentElement.nextElementSibling;
                        target_image.src = compressed[0];
                        target_image.setAttribute('data-variant',(brightness > 127) ? 's' : 'c');
                        target_image.setAttribute('data-fullres',compressed[1]);
                    });
                    resolve();
                }
                FR.readAsDataURL(file);
            });
        }
        pr();

    }

    image_compress(dataURL, options = []) {
        return new Promise(function (resolve) {
            let img = new Image();
            img.src = dataURL;
            let data = [];
            img.onload = function () {
                let canvas = document.createElement('canvas');
                for (let i = 0, len = options.length; i < len; i++) {
                    let width = options[i][0];
                    let height;
                    if (img.width < width) {
                        height = img.height;
                        width = img.width;
                    } else {
                        height = Math.floor(width * img.height / img.width);
                    }
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    data.push(ctx.canvas.toDataURL('image/jpeg', options[i][1]));
                }
                img = null;
                canvas = null;
                resolve(data);
            }
        });

    }



    getImageLightness(imageSrc) {
        return new Promise(function (resolve) {
            if (imageSrc.indexOf('#') == 0) {
                let r = parseInt(imageSrc.substring(1, 3), 16);
                let g = parseInt(imageSrc.substring(3, 5), 16);
                let b = parseInt(imageSrc.substring(5, 7), 16);
                let brightness = Math.floor((r + g + b) / 3);
                resolve(brightness);
            } else if (imageSrc.indexOf('rgb') == 0) {
                imageSrc = imageSrc.replace('rgb(', '');
                imageSrc = imageSrc.replace(')', '');
                imageSrc = imageSrc.split(',')
                let r = parseInt(imageSrc[0]);
                let g = parseInt(imageSrc[1]);
                let b = parseInt(imageSrc[2]);
                let brightness = Math.floor((r + g + b) / 3);
                resolve(brightness);
            } else {
                imageSrc = imageSrc.replace('url(', '');
                imageSrc = imageSrc.replace(')', '');
                var img = new Image();
                img.setAttribute('src', imageSrc);

                var colorSum = 0;

                img.onload = async function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;

                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    let data = imageData.data;
                    let r, g, b, avg;

                    for (let x = 0, len = data.length; x < len; x += 4) {
                        r = data[x];
                        g = data[x + 1];
                        b = data[x + 2];

                        avg = Math.floor((r + g + b) / 3);
                        colorSum += avg;
                    }

                    let brightness = Math.floor(colorSum / (this.width * this.height));
                    resolve(brightness);

                }

            }
        });
    }

    showDialog(num,that,item){ 
        let bg = (item!=null&&item!=undefined)?item.bg:null;
        //this.id_profilo = item.id_profilo;
        this.model_utenti = (item!=null)?item.id_utente:[];
        this.model_profili = (item!=null)?item.id_profilo:[];
        
        //console.log(this.model_utenti,item.id_utente,this.utenti);      
        that.dialogElement = num;
        that.dialog = true;
        let bgRadios = null;        
        let handler = setInterval(function(){
            bgRadios = document.getElementsByName('bckg_sel');
            if(bgRadios[0] === undefined){return;}
            clearInterval(handler);

            let srcImage = document.getElementById('dsovw_img_input');
            srcImage.src = '';
            srcImage.setAttribute('data-fullres','');
            srcImage.setAttribute('data-variant','s');
            let srcColor = document.getElementById('dsovw_color_input');
            srcColor.value = '#ffffff';
            
            if(num == -1){
                bgRadios[0].checked = true;           
            }else if(bg == 'rgb(255,255,255)' || bg == '#ffffff'){
                bgRadios[0].checked = true;
            }else if(bg[0] == '#'){
                bgRadios[1].checked = true;
                srcColor.value = bg;
            }else{
                bgRadios[2].checked = true;
                srcImage.src = bg.substring(4,bg.length-1);
            }
        },50);       
    }

}

export default DASHBOARD_OVERVIEW;