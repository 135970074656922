<template>
  <div style="padding: 20px">
    <v-card>
      <v-toolbar class="indigo white--text" dense primary-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              :color="getColor(single_item.id_stato)"
              >{{ getIcon(single_item.id_stato) }}</v-icon
            >
          </template>
          <span>
            {{ getStatus(single_item.id_stato) }}
          </span>
        </v-tooltip>
        <v-toolbar-title v-if="single_item.id_ticket != -1 && tipo_ticket > 0">
          Modifica
          {{ single_item.id_tipo_ticket == 0 ? "Ticket" : "Task" }}
          {{ assistenza == 1 ? "Assistenza" : "" }}
          {{
            assistenza == 1 && single_item.id_ticket_padre
              ? "TSA"
              : assistenza == 1
              ? "TCA"
              : single_item.id_ticket_padre
              ? "TSK"
              : "TCK"
          }}
          -
          {{ single_item.num_ticket }}
        </v-toolbar-title>
        <v-toolbar-title v-else>
          Crea {{ single_item.id_tipo_ticket == 0 ? "Ticket" : "Task" }}
          {{ assistenza == 1 ? "Assistenza" : "" }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="!valid"
              @click="validate(0)"
              small
            >
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>{{
            single_item.id_ticket == -1
              ? single_item.id_tipo_ticket == 0
                ? "Invia Ticket" + (assistenza == 1 ? "Assistenza" : "")
                : "Invia Task" + (assistenza == 1 ? "Assistenza" : "")
              : "Salva Modifiche"
          }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="tipo_permesso == 1"
              v-bind="attrs"
              v-on="on"
              :disabled="!valid"
              @click="validate(1)"
              class="ml-3"
              small
            >
              <v-icon color="success">mdi-content-save-move</v-icon>
            </v-btn>
          </template>
          <span> Salva e Continua </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="tipo_permesso == 1"
              v-bind="attrs"
              v-on="on"
              :disabled="!valid"
              @click="validate(2)"
              class="ml-3"
              small
            >
              <v-icon color="success">mdi-content-save-edit</v-icon>
            </v-btn>
          </template>
          <span> Salva e Nuovo </span>
        </v-tooltip>
        <v-spacer v-if="single_item.id_ticket != -1"></v-spacer>
        <span
          v-if="
            single_item.id_ticket != -1 && tipo_ticket > 0 && assistenza == 0
          "
          class="mx-2"
        >
          Ultimo Modificatore: {{ this.user_last_mod }}
        </span>
        <v-spacer v-if="single_item.id_ticket != -1"></v-spacer>
        <span
          v-if="single_item.id_ticket != -1 && tipo_ticket > 0"
          class="mx-2"
        >
          Data Modifica: {{ this.modify_date }}
        </span>
        <v-spacer></v-spacer>
        <v-tooltip v-if="!hide_back_arrow" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="goBack" small>
              <v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>

      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
        id="progress"
      >
      </v-progress-linear>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row class="text-start" v-if="errors.length">
            <v-col cols="12">
              <p v-if="errors.length">
                <v-alert type="error">
                  <ul>
                    <li v-for="(error, index) in errors" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </v-alert>
              </p>
            </v-col>
          </v-row>
          <v-row class="align-start">
            <v-col
              cols="12"
              class="py-1"
              v-if="
                single_item.nominativo_utente != null ||
                single_item.azienda_utente != null
              "
            >
              <v-chip> Nominativo: {{ single_item.nominativo_utente }}</v-chip>
              <v-chip class="ml-2">
                Azienda: {{ single_item.azienda_utente }}</v-chip
              >
            </v-col>
            <v-col
              cols="12"
              :md="single_item.id_ticket != -1 && assistenza != 1 ? 11 : 12"
            >
              <v-container fluid class="px-2">
                <v-row class="align-end">
                  <!-- v-if="
                      tipo_ticket > 0 &&
                      user_last_mod != 'Utente Sconosciuto' &&
                      !drawer
                    " -->
                  <v-col v-if="!drawer" cols="12" sm="6" md="3">
                    <v-text-field
                      dense
                      :value="
                        single_item.id_responsabile != null
                          ? utenti.filter(
                              (el) =>
                                single_item.id_responsabile == el.id_personale
                            )[0] != undefined
                            ? utenti.filter(
                                (el) =>
                                  single_item.id_responsabile == el.id_personale
                              )[0].cognome
                            : ''
                          : null
                      "
                      label="Responsabile"
                      class="appen_text_none"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!drawer" cols="12" sm="6" md="3">
                    <v-text-field
                      dense
                      v-model="single_item.des_progetto"
                      label="Progetto"
                      class="appen_text_none"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!drawer" cols="12" sm="6" md="3">
                    <v-text-field
                      dense
                      v-model="single_item.des_modulo"
                      label="Modulo"
                      class="appen_text_none"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!drawer" cols="12" sm="6" md="3">
                    <v-textarea
                      dense
                      rows="4"
                      label="Descrizione"
                      class="appen_text_none"
                      v-model="single_item.des_ticket"
                      :readonly="true"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="drawer" class="text-start align-start">
                  <v-col class="py-0" :md="assistenza == 0 ? 10 : 12" cols="12">
                    <v-container fluid>
                      <v-row class="text-start align-end">
                        <v-col md="4" cols="12" class="text-start py-0">
                          <v-textarea
                            dense
                            :disabled="
                              assistenza == 1 &&
                              tipo_permesso == '0' &&
                              single_item.id_stato > 1
                            "
                            rows="1"
                            v-model="single_item.titolo_task"
                            :label="
                              (single_item.id_tipo_ticket == 0
                                ? 'Titolo Ticket'
                                : 'Titolo Task') +
                              (assistenza == 1 ? ' Assistenza' : '')
                            "
                          ></v-textarea>
                        </v-col>
                        <v-col
                          v-if="
                            assistenza == 0
                              ? tipo_ticket > 0
                              : single_item.id_ticket != -1
                          "
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            dense
                            :disabled="
                              assistenza == 1 && !(tipo_permesso == '1')
                            "
                            v-model="int_id_responsabile"
                            :items="utenti"
                            label="Utente responsabile"
                            item-value="id_personale"
                            :item-text="
                              (item) => `${item.nome} ${item.cognome}`
                            "
                            placeholder="Digita per cercare"
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          :md="tipo_ticket > 0 ? 2 : 3"
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            dense
                            :disabled="
                              assistenza == 1 &&
                              tipo_permesso == '0' &&
                              single_item.id_stato > 1
                            "
                            v-model="single_item.id_ambito"
                            :items="ambiti"
                            item-value="id_tabella"
                            item-text="des"
                            placeholder="Digita per cercare"
                            @input="int_id_progetto = null"
                            label="Ambito"
                          >
                            <!-- :readonly="
                              single_item.id_ticket_padre != null ? true : false
                            " -->
                            <template #label>
                              <span class="">Ambito</span>
                              <a
                                @click.stop
                                style="pointer-events: all"
                                v-if="assistenza == 0"
                              >
                                <Modules
                                  v-on:dialogModules="emitModules"
                                  :assistenza="assistenza"
                                  tipo="AMBITO"
                                  nome="Ambito"
                                  setaction="settabella"
                                  getaction="gettabella"
                                  controller="Global"
                                  filter="validita = 1"
                                  :dati="[{ des: '' }]"
                                />
                              </a>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          :md="tipo_ticket > 0 ? 2 : 3"
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            dense
                            v-model="int_id_progetto"
                            :items="
                              progetti.filter(
                                (el) => el.val_int == single_item.id_ambito
                              )
                            "
                            item-value="id_tabella"
                            item-text="des"
                            placeholder="Digita per cercare"
                            :disabled="
                              single_item.id_ambito == null ||
                              (assistenza == 1 &&
                                tipo_permesso == '0' &&
                                single_item.id_stato > 1)
                            "
                            @input="int_id_modulo = null"
                          >
                            <!-- :readonly="
                              single_item.id_ticket_padre != null ? true : false
                            " -->
                            <template #label>
                              <span class="">Progetto</span>
                              <a
                                @click.stop
                                style="pointer-events: all"
                                v-if="assistenza == 0 || tipo_permesso == '1'"
                              >
                                <Modules
                                  v-on:dialogModules="emitModules"
                                  :assistenza="assistenza"
                                  tipo="PROGETTO"
                                  nome="Progetto"
                                  setaction="settabella"
                                  getaction="gettabella"
                                  controller="Global"
                                  :risorsa_preferenziale="true"
                                  :filter="
                                    '(val_int = ' +
                                    single_item.id_ambito +
                                    ') AND validita = 1'
                                  "
                                  :dati="[
                                    { des: '' },
                                    { val_int: single_item.id_ambito },
                                    {
                                      categoria: [
                                        {
                                          vmodel: 'val_dbl',
                                          nome: 'Risorsa preferenziale',
                                          getaction:
                                            'getutentelistresponsabili',
                                          controller: 'utente',
                                          dati: [{ nome: '' }],
                                          filter: 'validita = 1',
                                        },
                                      ],
                                    },
                                  ]"
                                />
                              </a>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          :md="tipo_ticket > 0 ? 4 : 5"
                          cols="12"
                          class="py-0"
                        >
                          <v-autocomplete
                            dense
                            v-model="int_id_modulo"
                            :items="
                              moduli.filter(
                                (el) => el.val_int == single_item.id_progetto
                              )
                            "
                            item-value="id_tabella"
                            item-text="des"
                            placeholder="Digita per cercare"
                            :disabled="
                              single_item.id_progetto == null ||
                              (assistenza == 1 &&
                                tipo_permesso == '0' &&
                                single_item.id_stato > 1)
                            "
                          >
                            <template #label>
                              <span class="">Modulo</span>
                              <a
                                @click.stop
                                style="pointer-events: all"
                                v-if="assistenza == 0 || tipo_permesso == '1'"
                              >
                                <Modules
                                  v-on:dialogModules="emitModules"
                                  :assistenza="assistenza"
                                  tipo="PROGETTO_MODULO"
                                  nome="Modulo"
                                  setaction="settabella"
                                  getaction="gettabella"
                                  controller="Global"
                                  :filter="
                                    '(val_int = ' +
                                    single_item.id_progetto +
                                    ') AND validita = 1'
                                  "
                                  :dati="[
                                    { des: '' },
                                    { val_int: single_item.id_progetto },
                                  ]"
                                />
                              </a>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="align-end">
                        <v-col
                          v-if="
                            assistenza == 0
                              ? single_item.id_ticket_padre
                              : tipo_permesso == '1' &&
                                single_item.id_ticket_padre
                          "
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <label>Milestone</label>
                          <v-switch
                            class="my-0 py-0"
                            v-model="single_item.milestone"
                          ></v-switch>
                        </v-col>
                        <v-col
                          v-if="assistenza == 0 || tipo_permesso == '1'"
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                v-model="start_date"
                                label="Data inizio"
                                autocomplete="off"
                                persistent-hint
                                prepend-icon="event"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="start_date"
                              no-title
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col
                          md="2"
                          cols="12"
                          class="py-0"
                          v-if="
                            assistenza == 0
                              ? !single_item.milestone
                              : tipo_permesso == '1' && !single_item.milestone
                          "
                        >
                          <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                v-model="end_date"
                                label="Data fine"
                                autocomplete="off"
                                persistent-hint
                                prepend-icon="event"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="end_date"
                              no-title
                              @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col
                          md="2"
                          cols="12"
                          class="py-0"
                          v-if="
                            assistenza == 0 ||
                            (assistenza == 1 && tipo_permesso == 1) ||
                            (assistenza == 1 &&
                              tipo_permesso == 0 &&
                              single_item.id_ticket != -1)
                          "
                        >
                          <v-menu
                            ref="menu3"
                            v-model="menu3"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                :disabled="
                                  assistenza == 1 && tipo_permesso == 0
                                "
                                v-model="end_prefix_date"
                                label="Data Fine Prevista"
                                autocomplete="off"
                                persistent-hint
                                prepend-icon="event"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="end_prefix_date"
                              no-title
                              @input="menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" :md="2" class="py-0">
                          <v-select
                            dense
                            v-model="single_item.id_um_ore"
                            :disabled="assistenza == 1 && tipo_permesso == 0"
                            :items="misure"
                            label="Unità"
                            item-text="des"
                            item-value="id_tabella"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" :md="2" class="py-0">
                          <v-text-field
                            dense
                            v-model="single_item.ore_previste"
                            :disabled="assistenza == 1 && tipo_permesso == 0"
                            label="Quantità"
                            type="number"
                            min="0"
                            max="1000"
                            step="1"
                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="single_item.id_ticket != -1 && assistenza == 1"
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <v-text-field
                            dense
                            v-model="single_item.durata_attesa"
                            :disabled="assistenza == 1 && tipo_permesso == 0"
                            label="Durata Attesa (Ore)"
                            type="number"
                            min="0"
                            step="1"
                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                          ></v-text-field>
                        </v-col>
                        <v-col md="2" cols="12" class="py-0">
                          <v-text-field
                            dense
                            v-model="single_item.email"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <v-col md="2" cols="12" class="py-0">
                          <v-text-field
                            dense
                            v-model="single_item.telefono"
                            label="Telefono"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="single_item.id_ticket != -1"
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <v-select
                            dense
                            v-model="single_item.id_stato"
                            :disabled="
                              assistenza == 1 && !(tipo_permesso == '1')
                            "
                            :items="
                              int_id_responsabile == null
                                ? status
                                : assistenza == 1 &&
                                  tipo_permesso == '1' &&
                                  single_item.avvenuta_risoluzione != 1
                                ? status.filter(
                                    (f) => f.val_int != 0 && f.val_int != 5
                                  )
                                : status.filter((f) => f.val_int != 0)
                            "
                            @input="cambioStato"
                            label="Selezione Stato"
                            item-text="des"
                            item-value="val_int"
                          >
                            <template #label>
                              <span class="">Stato<strong>* </strong></span>
                              <a
                                @click.stop
                                style="pointer-events: all"
                                v-if="assistenza == 0 || tipo_permesso == '1'"
                              >
                                <Modules
                                  v-on:dialogModules="emitModules"
                                  :assistenza="assistenza"
                                  tipo="PROGETTO_STATO"
                                  nome="Stato"
                                  setaction="settabella"
                                  getaction="gettabella"
                                  controller="Global"
                                  filter="validita = 1"
                                  :dati="[
                                    { des: '' },
                                    { val_txt: '' },
                                    { nota_interna: '' },
                                  ]"
                                />
                              </a>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          v-if="single_item.id_ticket != -1 && assistenza == 1"
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <v-select
                            dense
                            v-model="single_item.id_tipo_assistenza"
                            :disabled="
                              assistenza == 1 && !(tipo_permesso == '1')
                            "
                            :items="tipi_assistenza"
                            label="Tipo Assistenza"
                            item-text="des"
                            item-value="id_tabella"
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          v-if="single_item.id_ticket != -1 && assistenza == 1"
                          md="2"
                          cols="12"
                          class="py-0"
                        >
                          <v-select
                            dense
                            v-model="single_item.urgenza"
                            :disabled="
                              assistenza == 1 && !(tipo_permesso == '1')
                            "
                            :items="[1, 2, 3, 4, 5]"
                            label="Urgenza"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-textarea
                            dense
                            rows="4"
                            v-model="single_item.des_ticket"
                            label="Descrizione"
                            :disabled="
                              assistenza == 1 &&
                              tipo_permesso == '0' &&
                              single_item.id_stato > 1
                            "
                          ></v-textarea>
                        </v-col>
                        <v-col
                          v-if="single_item.id_ticket != -1"
                          cols="12"
                          class="text-start py-0"
                        >
                          <v-textarea
                            dense
                            rows="4"
                            v-model="single_item.esito_ticket"
                            :disabled="
                              assistenza == 1 && !(tipo_permesso == '1')
                            "
                            :label="
                              (single_item.id_tipo_ticket == 0
                                ? 'Risposta Ticket'
                                : 'Risposta Task') +
                              (assistenza == 1 ? ' Assistenza' : '')
                            "
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row
                        class="align-center"
                        v-if="single_item.id_ticket > 0 && assistenza == 1"
                      >
                        <v-col cols="8">
                          <v-checkbox
                            v-model="single_item.avvenuta_risoluzione"
                            :disabled="
                              (tipo_permesso == 1 &&
                                single_item.id_utente != id_utente &&
                                tipo_permesso == 1 &&
                                single_item.id_utente != id_utente &&
                                approva_ticket != 1) ||
                              (tipo_permesso == 1 && approva_ticket != 1) ||
                              single_item.avvenuta_risoluzione == 1 ||
                              single_item.id_stato != 3
                            "
                            :label="`Conferma risoluzione ticket: ${(single_item.avvenuta_risoluzione ==
                            1
                              ? 'Risolto'
                              : 'Non risolto'
                            ).toString()}`"
                            @change="confermaRisoluzione"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="4" class="py-0 d-flex justify-end">
                          <v-chip class="py-0"
                            >{{
                              single_item.ore_previste != 0 &&
                              misure.filter(
                                (el) => el.id_tabella == single_item.id_um_ore
                              )[0] != undefined
                                ? (single_item.ore_previste *
                                    misure.filter(
                                      (el) =>
                                        el.id_tabella == single_item.id_um_ore
                                    )[0].val_int) /
                                  60
                                : 0
                            }}
                            h</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col v-if="assistenza == 0" class="py-0" md="2" cols="12">
                    <v-container fluid>
                      <v-row class="d-flex text-start align-end justify-center">
                        <v-col
                          v-if="!single_item.immagine"
                          cols="12"
                          class="align-self-end py-1"
                        >
                          <!-- <v-icon @click="pasteIcon">mdi-clipboard</v-icon> -->
                          <v-text-field
                            dense
                            v-model="allegato"
                            @paste="onPaste"
                            accept="image/*"
                            label="Immagine"
                            prepend-icon="mdi-camera"
                            @click:prepend="openImagePicker"
                          ></v-text-field>
                          <input
                            type="file"
                            v-on:change="onFileChange"
                            accept="image/*"
                            ref="allegato"
                            name="allegato"
                            class="d-none"
                          />
                        </v-col>
                        <v-col
                          v-if="single_item.immagine"
                          cols="12"
                          class="py-0 text-center align-self-end"
                        >
                          <v-icon small @click="deleteAttach()">
                            mdi-close
                          </v-icon>

                          <div
                            v-viewer="{ navbar: false, toolbar: false }"
                            class="images clearfix d-flex justify-center"
                          >
                            <img
                              id="image_prev"
                              ref="image_prev"
                              :src="single_item.immagine"
                              :lazy-src="single_item.immagine"
                              aspect-ratio="1"
                              class="grey lighten-2"
                              style="width: 150px"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <!-- v-if="single_item.id_stato > 0 && tipo_ticket > 0 && edit" -->
            <v-col
              v-if="single_item.id_ticket != -1 && assistenza != 1"
              cols="12"
              md="1"
              class="py-0"
            >
              <v-btn v-on:click="drawer = !drawer" class="my-4">
                <v-icon v-if="drawer" color="primary"
                  >mdi-arrow-up-bold-circle-outline</v-icon
                >
                <v-icon v-else color="primary"
                  >mdi-arrow-down-bold-circle-outline</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!-- <hr v-if="drawer && edit" /> -->

        <!-- <v-container class="px-2 py-0" v-if="edit" fluid> -->
        <v-row
          justify="space-around"
          class="px-2 py-0"
          v-if="
            single_item.id_ticket != -1 &&
            (assistenza == 0 || (assistenza == 1 && tipo_permesso == '1'))
          "
        >
          <v-col cols="12" md="4" class="text-center">
            <v-card>
              <v-toolbar dense class="blue" primary-title>
                <v-spacer></v-spacer>
                <v-toolbar-title class="white--text">Nuovo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <AddTask
                      v-on="on"
                      v-bind="attrs"
                      :id_parent="parseInt(single_item.id_ticket)"
                      :id_task="-1"
                      :assistenza="assistenza"
                      v-on:dialogTask="emitDialog"
                    />
                  </template>
                  <span
                    >Aggiungi Task
                    {{ assistenza == 1 ? "Assistenza" : "" }}</span
                  >
                </v-tooltip>
              </v-toolbar>
              <v-container>
                <draggable
                  style="min-height: 100px"
                  id="task_created"
                  @add="addTask($event)"
                  v-model="task_created"
                  group="people"
                >
                  <v-row
                    v-for="task_cr in task_created"
                    :key="task_cr.id_ticket"
                    class="d-flex justify-center"
                  >
                    <v-col cols="11">
                      <v-card class="py-2" style="cursor: grabbing !important">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="warning(task_cr.data_fine)"
                              style="width: 10%; display: block"
                              color="yellow"
                            >
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  background-color: transparent;
                                  box-shadow: none;
                                  min-width: 15px;
                                  height: 15px;
                                "
                                class="px-0 yellow"
                                slot="badge"
                              >
                                <v-icon medium class="white--text"
                                  >mdi-alert</v-icon
                                >
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>In scadenza</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="alert(task_cr.data_fine)"
                              style="width: 10%; display: block"
                              color="red"
                            >
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  background-color: transparent;
                                  box-shadow: none;
                                  min-width: 15px;
                                  height: 15px;
                                "
                                class="px-0 red"
                                slot="badge"
                              >
                                <v-icon medium class="white--text"
                                  >mdi-alert</v-icon
                                >
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>Scaduto</span>
                        </v-tooltip>

                        <v-badge style="width: 80%">
                          <v-btn
                            style="
                              background-color: transparent;
                              box-shadow: none;
                              min-width: 15px;
                              height: 15px;
                            "
                            class="px-0 primary"
                            slot="badge"
                          >
                            <AddTask
                              :id_parent="parseInt(task_cr.id_ticket_padre)"
                              :id_task="parseInt(task_cr.id_ticket)"
                              :assistenza="assistenza"
                              v-on:dialogTask="emitDialog"
                            />
                          </v-btn>
                          <v-btn
                            style="
                              background-color: transparent;
                              box-shadow: none;
                              min-width: 15px;
                              height: 15px;
                            "
                            class="px-0 primary"
                            slot="badge"
                            @click="deleteTask(task_cr)"
                          >
                            <v-icon medium class="white--text"
                              >mdi-close</v-icon
                            >
                          </v-btn>

                          <span>
                            (
                            {{
                              task_cr.id_responsabile != null
                                ? utenti.filter(
                                    (e) =>
                                      e.id_personale == task_cr.id_responsabile
                                  )[0] != undefined
                                  ? utenti.filter(
                                      (e) =>
                                        e.id_personale ==
                                        task_cr.id_responsabile
                                    )[0].cognome +
                                    " " +
                                    utenti.filter(
                                      (e) =>
                                        e.id_personale ==
                                        task_cr.id_responsabile
                                    )[0].nome
                                  : "utente sconosciuto"
                                : "nessun responsabile"
                            }}
                            )
                            {{
                              task_cr.titolo_task
                                ? task_cr.titolo_task
                                : task_cr.des_ticket
                            }}</span
                          >
                        </v-badge>
                      </v-card>
                    </v-col>
                  </v-row>
                </draggable>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card>
              <v-toolbar dense class="orange" primary-title>
                <v-spacer></v-spacer>
                <v-toolbar-title class="white--text"> In Corso</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-container>
                <draggable
                  id="task_executed"
                  style="min-height: 100px"
                  @add="addTask($event)"
                  v-model="task_executed"
                  group="people"
                >
                  <v-row
                    v-for="task_ex in task_executed"
                    :key="task_ex.id_ticket"
                    class="d-flex justify-center"
                  >
                    <v-col cols="11">
                      <v-card class="py-2" style="cursor: grabbing !important">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="warning(task_ex.data_fine)"
                              style="width: 10%; display: block"
                              color="yellow"
                            >
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  background-color: transparent;
                                  box-shadow: none;
                                  min-width: 15px;
                                  height: 15px;
                                "
                                class="px-0 yellow"
                                slot="badge"
                              >
                                <v-icon medium class="white--text"
                                  >mdi-alert</v-icon
                                >
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>In scadenza</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="alert(task_ex.data_fine)"
                              style="width: 10%; display: block"
                              color="red"
                            >
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  background-color: transparent;
                                  box-shadow: none;
                                  min-width: 15px;
                                  height: 15px;
                                "
                                class="px-0 red"
                                slot="badge"
                              >
                                <v-icon medium class="white--text"
                                  >mdi-alert</v-icon
                                >
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>Scaduto</span>
                        </v-tooltip>

                        <v-badge style="width: 80%">
                          <v-btn
                            style="
                              background-color: transparent;
                              box-shadow: none;
                              min-width: 15px;
                              height: 15px;
                            "
                            class="px-0 primary"
                            slot="badge"
                          >
                            <AddTask
                              :id_parent="parseInt(task_ex.id_ticket_padre)"
                              :id_task="parseInt(task_ex.id_ticket)"
                              :assistenza="assistenza"
                              v-on:dialogTask="emitDialog"
                            />
                          </v-btn>
                          <v-btn
                            style="
                              background-color: transparent;
                              box-shadow: none;
                              min-width: 15px;
                              height: 15px;
                            "
                            class="px-0 primary"
                            slot="badge"
                            @click="deleteTask(task_ex)"
                          >
                            <v-icon medium class="white--text"
                              >mdi-close</v-icon
                            >
                          </v-btn>
                          <span
                            >(
                            {{
                              task_ex.id_responsabile != null
                                ? utenti.filter(
                                    (e) =>
                                      e.id_personale == task_ex.id_responsabile
                                  )[0] != undefined
                                  ? utenti.filter(
                                      (e) =>
                                        e.id_personale ==
                                        task_ex.id_responsabile
                                    )[0].cognome +
                                    " " +
                                    utenti.filter(
                                      (e) =>
                                        e.id_personale ==
                                        task_ex.id_responsabile
                                    )[0].nome
                                  : "utente sconosciuto"
                                : "nessun responsabile"
                            }}
                            )
                            {{
                              task_ex.titolo_task
                                ? task_ex.titolo_task
                                : task_ex.des_ticket
                            }}</span
                          >
                        </v-badge>
                      </v-card>
                    </v-col>
                  </v-row>
                </draggable>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card>
              <v-toolbar dense class="green" primary-title>
                <v-spacer></v-spacer>
                <v-toolbar-title class="white--text">
                  Completato</v-toolbar-title
                >
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-container>
                <draggable
                  id="task_completed"
                  style="min-height: 100px"
                  @add="addTask($event)"
                  v-model="task_completed"
                  group="people"
                >
                  <v-row
                    v-for="task_co in task_completed"
                    :key="task_co.id_ticket"
                    class="d-flex justify-center"
                  >
                    <v-col cols="11">
                      <v-card class="py-2" style="cursor: grabbing !important">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="warning(task_co.data_fine)"
                              style="width: 10%; display: block"
                              color="yellow"
                            >
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  background-color: transparent;
                                  box-shadow: none;
                                  min-width: 15px;
                                  height: 15px;
                                "
                                class="px-0 yellow"
                                slot="badge"
                              >
                                <v-icon medium class="white--text"
                                  >mdi-alert</v-icon
                                >
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>In scadenza</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              v-if="alert(task_co.data_fine)"
                              style="width: 10%; display: block"
                              color="red"
                            >
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  background-color: transparent;
                                  box-shadow: none;
                                  min-width: 15px;
                                  height: 15px;
                                "
                                class="px-0 red"
                                slot="badge"
                              >
                                <v-icon medium class="white--text"
                                  >mdi-alert</v-icon
                                >
                              </v-btn>
                            </v-badge>
                          </template>
                          <span>Scaduto</span>
                        </v-tooltip>

                        <v-badge style="width: 80%">
                          <v-btn
                            style="
                              background-color: transparent;
                              box-shadow: none;
                              min-width: 15px;
                              height: 15px;
                            "
                            class="px-0 primary"
                            slot="badge"
                          >
                            <AddTask
                              :id_parent="parseInt(task_co.id_ticket_padre)"
                              :id_task="parseInt(task_co.id_ticket)"
                              :assistenza="assistenza"
                              v-on:dialogTask="emitDialog"
                            />
                          </v-btn>
                          <v-btn
                            style="
                              background-color: transparent;
                              box-shadow: none;
                              min-width: 15px;
                              height: 15px;
                            "
                            class="px-0 primary"
                            slot="badge"
                            @click="deleteTask(task_co)"
                          >
                            <v-icon medium class="white--text"
                              >mdi-close</v-icon
                            >
                          </v-btn>

                          <span
                            >(
                            {{
                              task_co.id_responsabile != null
                                ? utenti.filter(
                                    (e) =>
                                      e.id_personale == task_co.id_responsabile
                                  )[0]
                                  ? utenti.filter(
                                      (e) =>
                                        e.id_personale ==
                                        task_co.id_responsabile
                                    )[0].cognome +
                                    " " +
                                    utenti.filter(
                                      (e) =>
                                        e.id_personale ==
                                        task_co.id_responsabile
                                    )[0].nome
                                  : "utente sconosciuto"
                                : "nessun responsabile"
                            }}
                            )
                            {{
                              task_co.titolo_task
                                ? task_co.titolo_task
                                : task_co.des_ticket
                            }}</span
                          >
                        </v-badge>
                      </v-card>
                    </v-col>
                  </v-row>
                </draggable>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <!-- </v-container> -->
        <v-row>
          <v-col cols="12">
            <GestioneAllegato
              ref="componenteAllegati"
              :assistenza="assistenza.toString()"
              controller_allegati="Global"
              controller_caratteristiche_tabella="Manutenzione"
              :id_riferimento="parseInt(single_item.id_ticket)"
              :tipo_allegato="assistenza == 0 ? 'TICKET' : 'TICKETA'"
              @snackbarOpen="snackbarOpen"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-dialog v-model="modalAgree" max-width="290">
        <v-card>
          <v-card-title class="headline">Spostamento</v-card-title>
          <v-card-text>Vuoi fare lo spostamento?</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="agree(false)"
              >Non accetto</v-btn
            >

            <v-btn color="primary darken-1" text @click="agree(true)"
              >Accetto</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<style>
.appen_text_none .v-input__control .v-text-field__details {
  display: none;
}
</style>

<script>
// import Base64 from "b64-to-blob";
import Modules from "@/components/TOOLS/Modules";
import AddTask from "./AddTask";
import draggable from "vuedraggable";
import moment from "moment";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";

function save(that, returnView) {
  if (that.tipo_permesso == "0" && that.assistenza == 1) {
    that.single_item.id_utente = localStorage.id;
  }
  const today = new Date();

  if (
    that.single_item.id_ticket == -1 ||
    that.single_item.data_ticket == null ||
    that.single_item.data_ticket == ""
  ) {
    that.single_item.data_ticket =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      (today.getDate() < 10 ? "0" : "") +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();
  }
  if (that.single_item.id_ticket == -1 && that.assistenza == 1) {
    that.single_item.id_tipo_assistenza = that.tipi_assistenza.filter(
      (el) => el.des.toUpperCase() == "ASSISTENZA"
    )[0].id_tabella;
  }
  that.single_item.data_inizio = that.start_date;
  that.single_item.data_fine = that.end_date;
  that.single_item.data_fine_prevista = that.end_prefix_date;

  var itemRequest = that.single_item;
  delete itemRequest.task;

  that.progetti.forEach((e) => {
    if (
      e.id_tabella == itemRequest.id_progetto &&
      that.single_item.id_ticket == -1
    ) {
      itemRequest.id_responsabile = e.val_dbl;
    }
  });
  console.log("TICKET!!!!", itemRequest, returnView);
  if (itemRequest.des_ticket != null) {
    itemRequest.des_ticket = itemRequest.des_ticket.replaceAll(
      /[\n\r]/g,
      "|newLine"
    );
  }
  var request3 = {
    controller: "Ticket",
    method: "PUT",
    richiesta: {
      action: "setticket",
      token: localStorage.user_token,
      id_utente: localStorage.id,
      id_azienda: localStorage.id_azienda,
      tipologia: "",
      tipo_ticket: that.single_item.id_tipo_ticket,
      tabella: [itemRequest],
      assistenza: that.assistenza,
    },
  };
  if (that.$refs.componenteAllegati.checkDescrizioniAllegati() == true) {
    that.$store.dispatch("api", request3).then(async (res) => {
      if (res.status == 200) {
        if (that.$refs.componenteAllegati) {
          that.$refs.componenteAllegati.id_riferimento_creazione_genitore =
            res.data.result.id_ticket;
          if (that.$refs.componenteAllegati.lista_allegati.length > 0) {
            var resSalvataggio =
              await that.$refs.componenteAllegati.salvaTuttiAllegati();
            if (resSalvataggio) {
              if (that.single_item.id_ticket == -1) {
                that.snackbar = true;
                that.snackbar_background = "success";
                that.snackbar_icon = "mdi-check";
                that.snackbar_icon_color = "white";
                that.snackbar_text =
                  "Creazione ticket N. " +
                  res.data.result.cod_ticket +
                  " avvenuta con successo";
                that.snackbar_text_color = "white";
              } else if (that.single_item.id_ticket != -1) {
                that.snackbar = true;
                that.snackbar_background = "success";
                that.snackbar_icon = "mdi-check";
                that.snackbar_icon_color = "white";
                that.snackbar_text =
                  "Salvataggio ticket N. " +
                  res.data.result.cod_ticket +
                  " avvenuto con successo";
                that.snackbar_text_color = "white";
              }
              let timer = setTimeout(function () {
                returnView == 0
                  ? that.goBack()
                  : returnView == 1
                  ? that.stayHere(res.data.result.id_ticket)
                  : that.clearTicket();
                clearTimeout(timer);
              }, 800);
            } else {
              this.snackbarOpen();
            }
          } else {
            if (that.single_item.id_ticket == -1) {
              that.snackbar = true;
              that.snackbar_background = "success";
              that.snackbar_icon = "mdi-check";
              that.snackbar_icon_color = "white";
              that.snackbar_text =
                "Creazione ticket N. " +
                res.data.result.cod_ticket +
                " avvenuta con successo";
              that.snackbar_text_color = "white";
            } else if (that.single_item.id_ticket != -1) {
              that.snackbar = true;
              that.snackbar_background = "success";
              that.snackbar_icon = "mdi-check";
              that.snackbar_icon_color = "white";
              that.snackbar_text =
                "Salvataggio ticket N. " +
                res.data.result.cod_ticket +
                " avvenuto con successo";
              that.snackbar_text_color = "white";
            }
            let timer = setTimeout(function () {
              returnView == 0
                ? that.goBack()
                : returnView == 1
                ? that.stayHere(res.data.result.id_ticket)
                : that.clearTicket();
              clearTimeout(timer);
            }, 800);
          }
        }
      }
    });
  } else {
    that.snackbarOpen();
  }
}

function saveTask(that, item) {
  item.id_utente = localStorage.id;

  var saveTask = {
    controller: "Ticket",
    method: "PUT",
    richiesta: {
      action: "setticket",
      token: localStorage.user_token,
      id_utente: localStorage.id,
      id_azienda: localStorage.id_azienda,
      tipologia: "",
      tipo_ticket: item.tipo_ticket,
      tabella: [item],
      assistenza: that.assistenza,
    },
  };

  that.$store.dispatch("api", saveTask).then((res) => {
    console.log("res update task", res);
    // that.$route.params.num_ticket = res.data[0].num_ticket
    that.move = false;
    updateTask(that);
  });
}

function updateTask(that) {
  that.loading = true;
  var request2 = {
    controller: "Ticket",
    method: "POST",
    richiesta: {
      action: "getticket",
      token: localStorage.user_token,
      id_ticket: that.single_item.id_ticket,
      id_stato: "",
      assistenza: that.assistenza,
    },
  };
  that.$store.dispatch("api", request2).then((res) => {
    if (res.data.result.tickets != undefined) {
      that.single_item = res.data.result.tickets[0];
      that.approva_ticket = parseInt(res.data.result.approva_ticket);
      if (that.single_item.id_tipo_assistenza == null) {
        that.single_item.id_tipo_assistenza = that.tipi_assistenza.filter(
          (el) => el.des.toUpperCase() == "ASSISTENZA"
        )[0].id_tabella;
      }
      if (that.single_item.data_inizio) {
        that.start_date = moment(that.single_item.data_inizio).format(
          "YYYY-MM-DD"
        );
      }
      if (that.single_item.data_fine) {
        that.end_date = moment(that.single_item.data_fine).format("YYYY-MM-DD");
      }
      if (that.single_item.data_fine_prevista) {
        that.end_prefix_date = moment(
          that.single_item.data_fine_prevista
        ).format("YYYY-MM-DD");
      }
      if (that.single_item.des_ticket.includes("|newLine")) {
        console.log(typeof that.single_item.des_ticket);

        that.single_item.des_ticket = that.single_item.des_ticket.replaceAll(
          "|newLine",
          "\n"
        );
      }

      // if (that.tipo_ticket > 0) {
      let tasks_received = res.data.result.tickets[0].task;
      if (tasks_received != undefined) {
        if (tasks_received.length == undefined) {
          that.task = [tasks_received];
        } else {
          that.task = tasks_received;
        }
        that.task_created = that.task.filter((item) => {
          return item.id_stato == 1 || item.id_stato == 0;
        });
        that.task_executed = that.task.filter((item) => {
          return item.id_stato == 2;
        });
        that.task_completed = that.task.filter((item) => {
          return item.id_stato == 3 || item.id_stato == 4;
        });
        that.old_completed = that.task_completed;
        that.old_created = that.task_created;
        that.old_executed = that.task_executed;
      } else {
        that.task = [];
        that.task_completed = [];
        that.task_created = [];
        that.task_executed = [];
        that.old_completed = [];
        that.old_created = [];
        that.old_executed = [];
      }
      // }
      that.loading = false;

      that.tipo_ticket > 0 &&
      that.single_item.id_stato > 0 &&
      that.assistenza != 1
        ? (that.drawer = false)
        : (that.drawer = true);
    }
  });
}

export default {
  name: "SingleTicket",

  data: () => ({
    // Variabili d'appoggio
    approva_ticket: 0,
    color: "blue",
    end_date: null,
    end_prefix_date: null,
    drawer: true,
    id_from: null,
    id_new: null,
    id_old: null,
    id_to: null,
    id_utente: localStorage.id,
    loading: true,
    menu: false,
    menu2: false,
    menu3: false,
    move: false,
    start_date: null,
    tipo_ticket: 1,

    // Modal
    dialog_task: false,
    modalAgree: false,
    snackbar: false,
    snackbar_background: "",
    snackbar_icon: "",
    snackbar_icon_color: "",
    snackbar_text: "",
    snackbar_text_color: "",

    // Array
    utenti: [],
    utenti_responsabili: [],
    status: [],
    task: [],
    errors: [],
    allegato: [],
    moduli: [],
    ambiti: [],
    misure: [],
    progetti: [],
    task_created: [],
    task_executed: [],
    task_completed: [],
    tipi_assistenza: [],
    single_item: {
      avvenuta_risoluzione: null,
      id_utente: localStorage.id,
      id_azienda: null,
      id_ticket: -1,
      num_ticket: "",
      data_ins: "",
      id_ambito: null,
      id_progetto: null,
      id_modulo: null,
      id_tipo_ticket: 0,
      id_ticket_padre: null,
      des_ticket: "",
      esito_ticket: "",
      data_inizio: null,
      data_fine: null,
      data_fine_prevista: null,
      data_inizio_reale: null,
      data_fine_reale: null,
      durata_attesa: null,
      urgenza: 3,
      id_tipo_assistenza: null,
      email: "",
      telefono: "",
      milestone: false,
      ore_previste: 0.5,
      unita_misura: "ore",
      progressione: 0,
      immagine: null,
      id_stato: null,
      des_stato: "Nuovo",
      data_mod: "",
      data_scadenza: "",
      id_responsabile: null,
      titolo_task: "",
      validita: 1,
    },
  }),
  props: {
    assistenza: { type: Number, default: 0 },
    tipo_permesso: { type: String, default: "0" },
    allegatoEsterno: { type: String, default: null },
    hide_back_arrow: { type: Boolean, default: false },
  },
  components: {
    Modules,
    AddTask,
    draggable,
    GestioneAllegato,
  },
  mounted() {
    this.inizializza();
    console.log("TIPI LISTA:", this.tipi_assistenza);
    if (this.allegatoEsterno != null) {
      this.$refs.componenteAllegati.getImgFromScreen(this.allegatoEsterno);
    }
  },
  watch: {
    dialog_task: function (newT, oldT) {
      if (newT != oldT) {
        updateTask(this);
        this.dialog_task = false;
      }
    },
    "$route.params.id": function (newT, oldT) {
      if (newT != oldT) {
        this.inizializza();
      }
    },
  },
  computed: {
    valid() {
      if (
        this.single_item.id_ambito != null &&
        this.single_item.id_progetto != null &&
        // this.single_item.id_modulo != null &&
        this.single_item.titolo_task != null &&
        this.single_item.titolo_task != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    // int_id_um_ore: {
    //   get: function () {
    //     return parseInt(this.single_item.id_um_ore);
    //   },
    //   set: function (newValue) {
    //     this.single_item.id_um_ore = newValue;
    //     return parseInt(this.single_item.id_um_ore);
    //   },
    // },
    int_id_ambito: {
      get: function () {
        console.log("get", this.single_item.id_ambito);
        return parseInt(this.single_item.id_ambito);
      },
      set: function (newValue) {
        this.single_item.id_ambito = newValue;
        console.log("set", this.single_item.id_ambito);
        return parseInt(this.single_item.id_ambito);
      },
    },
    int_id_progetto: {
      get: function () {
        return parseInt(this.single_item.id_progetto);
      },
      set: function (newValue) {
        this.single_item.id_progetto = newValue;
        return parseInt(this.single_item.id_progetto);
      },
    },
    int_id_stato: {
      get: function () {
        return parseInt(this.single_item.id_stato);
      },
      set: function (newValue) {
        this.single_item.id_stato = newValue;
        return parseInt(this.single_item.id_stato);
      },
    },
    int_id_modulo: {
      get: function () {
        return parseInt(this.single_item.id_modulo);
      },
      set: function (newValue) {
        this.single_item.id_modulo = newValue;
        return parseInt(this.single_item.id_modulo);
      },
    },
    int_id_responsabile: {
      get: function () {
        return parseInt(this.single_item.id_responsabile);
      },
      set: function (newValue) {
        this.single_item.id_responsabile = newValue;
        return parseInt(this.single_item.id_responsabile);
      },
    },
    create_date() {
      return moment(this.single_item.data_ins).format("MM/DD H:mm");
    },
    modify_date() {
      if (this.single_item.data_mod != undefined) {
        return moment(this.single_item.data_mod).format("MM/DD H:mm");
      } else {
        return "Non presente";
      }
    },
    user_last_mod() {
      var user;
      if (this.single_item.id_utente) {
        user = this.utenti.filter((res) => {
          if (
            typeof res != "undefined" &&
            res.id_personale == this.single_item.id_utente
          ) {
            return res;
          }
        });
      }
      return user != undefined && user.length > 0
        ? user[0].cognome
        : "Utente Sconosciuto";
    },
  },
  methods: {
    addTask(event) {
      this.id_from = event.from.getAttribute("id");
      this.id_to = event.to.getAttribute("id");
      this.id_old = event.oldDraggableIndex;
      this.id_new = event.newDraggableIndex;

      this.modalAgree = true;
    },
    agree(val) {
      if (val) {
        var item = null;
        if (this.id_to == "task_executed") {
          item = this.task_executed[this.id_new];

          item.id_stato = 2;
          item.des_stato = "In progress";
        } else if (this.id_to == "task_created") {
          item = this.task_created[this.id_new];

          item.id_stato = 1;
          item.des_stato = "Gestito";
        } else {
          item = this.task_completed[this.id_new];

          item.id_stato = 3;
          item.des_stato = "Concluso";
        }
        saveTask(this, item);
      } else {
        updateTask(this);
      }
      this.modalAgree = false;
    },
    alert(data_fine) {
      if (data_fine != null) {
        const today = new Date();
        var date1 = moment(data_fine).format("L");
        var comparing_date = moment(today).format("L");
        if (comparing_date >= date1) {
          return true;
        }
      }
      return false;
    },
    cambioStato() {
      if (this.single_item.id_stato == 3) {
        this.end_date = moment().format("YYYY-MM-DD");
      }
    },
    clearTicket() {
      this.$refs.componenteAllegati.lista_files = [];
      this.$refs.componenteAllegati.lista_src = [];
      this.$refs.componenteAllegati.lista_allegati = [];
      this.$refs.componenteAllegati.date_validita_tabella = [];
      this.single_item.des_stato = "Nuovo";
      this.single_item.avvenuta_risoluzione = null;
      this.single_item.id_ticket = -1;
      this.single_item.num_ticket = "";
      this.single_item.des_ticket = "";
      this.single_item.esito_ticket = "";
      this.single_item.titolo_task = "";

      var stat = this.status.filter((res) => {
        if (res.val_int == 0) {
          return res;
        }
      });
      if (this.single_item.id_stato == null) {
        this.single_item.id_stato = stat[0].val_int;
      }
    },
    confermaRisoluzione() {
      if (confirm("Sicuro di voler confermare che il ticket è stato risolto")) {
        this.single_item.avvenuta_risoluzione = 1;
        this.single_item.id_stato = 5;
      } else {
        this.single_item.avvenuta_risoluzione = 0;
      }
    },
    dataURItoBlob(dataURI) {
      const bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    },
    deleteAttach() {
      this.allegato = [];
      this.single_item.immagine = null;
    },
    deleteTask(task) {
      if (confirm("Sicuro di voler eliminare?")) {
        task.validita = 0;
        var removeTask = {
          controller: "Ticket",
          method: "PUT",
          richiesta: {
            action: "setticket",
            token: localStorage.user_token,
            id_utente: localStorage.id,
            id_ticket: task.id_ticket,
            tipo_ticket: task.id_tipo_ticket,
            tipologia: "",
            tabella: [task],
            assistenza: this.assistenza,
          },
        };
        // console.log(JSON.stringify(removeTask))

        this.$store.dispatch("api", removeTask).then((res) => {
          console.log(res);
          updateTask(this);
        });
      }
    },
    emitDialog(value) {
      this.dialog_task = value;
    },
    emitModules() {
      this.getTabelle();
    },
    getColor(id_stato) {
      var color = this.status.filter(function (st) {
        if (st.val_int == id_stato) {
          return st;
        }
      });

      return color.length != 0 && color[0].val_txt ? color[0].val_txt : "grey";
    },
    getIcon(id_stato) {
      var icon = this.status.filter(function (st) {
        if (st.val_int == id_stato) {
          return st;
        }
      });
      return icon.length != 0 && icon[0].nota_interna
        ? "mdi-" + icon[0].nota_interna
        : "mdi-help-circle";
    },
    getTabelle() {
      //da lasciare divisit perchè a seconda se sono assistenza o cliente non è detto che debbano cambiare db
      var requestProgetto = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          filter: "validita = 1",
          tipo: "PROGETTO",
          progettiticket: 1,
          assistenza: this.assistenza,
        },
      };
      this.$store.dispatch("api", requestProgetto).then((res) => {
        if (res.data != "") {
          if (res.data != undefined) {
            this.progetti = res.data;
          }
        } else {
          this.progetti = [];
        }
      });
      if (this.assistenza == 0) {
        var requestAmbitiNonAssistenza = {
          controller: "Global",
          method: "POST",
          richiesta: {
            action: "gettabella",
            token: localStorage.user_token,
            filter: "validita = 1",
            tipo: "AMBITO",
            assistenza: this.assistenza,
          },
        };
        this.$store.dispatch("api", requestAmbitiNonAssistenza).then((res) => {
          if (res.data != "") {
            if (res.data != undefined) {
              this.ambiti = res.data;
            }
          } else {
            this.ambiti = [];
          }
        });
      } else {
        console.log("getambiti");
        var requestAmbitiAssistenza = {
          controller: "Global",
          method: "POST",
          richiesta: {
            action: "getambitibyazienda",
            token: localStorage.user_token,
            filter: "validita = 1",
          },
        };
        this.$store.dispatch("api", requestAmbitiAssistenza).then((res) => {
          if (res.data != "") {
            if (res.data != undefined) {
              this.ambiti = res.data;
            }
          } else {
            this.ambiti = [];
          }
        });
      }

      var request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          filter: "validita = 1",
          tipo: "'PROGETTO_MODULO','PROGETTO_STATO', 'TIPO_ASSISTENZA'",
          assistenza: this.assistenza,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.data != "") {
          if (res.data.PROGETTO_MODULO != undefined) {
            this.moduli = res.data.PROGETTO_MODULO;
          }
          if (res.data.TIPO_ASSISTENZA != undefined) {
            this.tipi_assistenza = res.data.TIPO_ASSISTENZA;
            console.log("TIPI ASSISTENZA:", this.tipi_assitenza);
          }
          if (res.data.PROGETTO_STATO != undefined) {
            this.status = res.data.PROGETTO_STATO;
            var stat = this.status.filter((res) => {
              if (res.val_int == 0) {
                return res;
              }
            });
            if (this.single_item.id_stato == null) {
              this.single_item.id_stato = stat[0].val_int;
            }
          }
        } else {
          this.moduli = [];
          this.status = [];
          this.tipi_assistenza = [];
        }
      });

      var requestMisure = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          filter: "validita = 1",
          tipo: "UNITA_MISURA",
          assistenza: this.assistenza,
        },
      };
      this.$store.dispatch("api", requestMisure).then((res) => {
        if (res.data != "") {
          if (res.data != undefined) {
            res.data.map((el) => {
              if (el.val_txt === "H") {
                this.misure.push(el);
              }
            });
            if (this.single_item.id_um_ore == null) {
              this.single_item.id_um_ore = this.misure.filter(
                (el) => el.des.toLowerCase() == "ore"
              )[0].id_tabella;
            }
          }
        } else {
          this.misure = [];
        }
      });
    },
    getStatus(id_stato) {
      var status = this.status.filter(function (st) {
        if (st.val_int == id_stato) {
          return st;
        }
      });
      return status.length != 0 ? status[0].des : this.single_item.des_ticket;
    },
    goBack() {
      if (this.assistenza == "1") {
        this.$router
          .replace({
            name: "TicketListAssistenza",
          })
          .catch(() => {});
      } else {
        this.$router
          .replace({
            name: "TicketList",
          })
          .catch(() => {});
      }
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name.toLowerCase()
      );
    },
    inizializza() {
      var getAzienda = {
        controller: "Modulo",
        method: "POST",
        richiesta: {
          action: "getaziendabyid",
          token: localStorage.user_token,
        },
      };
      console.log("bb");
      this.$store.dispatch("api", getAzienda).then((res) => {
        if (res.status == 200) {
          this.single_item.id_tipo_assistenza =
            res.data[0].valore_tipo_assistenza;
        }
      });
      if (this.assistenza != null) {
        if (this.assistenza == 1) {
          this.single_item.id_azienda = localStorage.id_azienda;
        }
        var getUtenti = {
          controller: "Utente",
          method: "POST",
          richiesta: {
            action: "getutentelist",
            token: localStorage.user_token,
          },
        };

        this.$store.dispatch("api", getUtenti).then((res) => {
          console.log("aa", res.data.result.gp_personale_utenti);
          this.utenti = res.data.result.gp_personale_utenti;
        });
        if (this.assistenza == 0) {
          this.utenti_responsabili = this.utenti;
        } else {
          var getUtentiResp = {
            controller: "Utente",
            method: "POST",
            richiesta: {
              action: "getutentelistresponsabili",
              token: localStorage.user_token,
            },
          };

          this.$store.dispatch("api", getUtentiResp).then((res) => {
            this.utenti_responsabili = res.data.result.gp_personale_utenti;
          });
        }

        this.getTabelle();

        if (this.$route.params.id != -1) {
          this.single_item.id_ticket = this.$route.params.id;
          updateTask(this);
        } else {
          this.loading = false;
        }
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (this.hasExtension(files[0], [".jpeg", ".gif", ".png", ".jpg"])) {
        this.allegato = files[0];

        let that = this;
        this.resizeImageBackEnd(this.allegato, 600)
          .then((resizedImage) => {
            var reader = new FileReader();
            reader.readAsDataURL(resizedImage);

            let thet = that;
            reader.onload = function () {
              thet.single_item.immagine = reader.result;
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        console.log("tipo non accettato");
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Formato non supportato";
        this.snackbar_text_color = "white";
      }
    },
    onPaste(e) {
      // console.log("event t:", e)
      // if (e.clipboardData != false) {
      //   var items = e.clipboardData.items;

      //   if (items != undefined) {
      //     for (var i = 0; i < items.length; i++) {
      //       // Skip content if not image
      //       if (items[i].type.indexOf("image") == -1) continue;

      //       // Retrieve image on clipboard as blob
      //       var blob = items[i].getAsFile();
      //       console.log(blob);
      //     }
      //   }
      // }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.single_item.immagine = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    pasteIcon(e) {
      const event = new Event("paste");
      console.log("event f:", event);
      console.log("e f:", e);
      // console.log("ex :", navigator.clipboard.read())
      // console.log("w :",window.clipboardData.getData('Text'))

      // var items = e.clipboardData.items;

      // if(items != undefined){
      //   for (var i = 0; i < items.length; i++) {
      //     // Skip content if not image
      //     if (items[i].type.indexOf("image") == -1) continue;

      //     var blob = items[i].getAsFile();
      //     console.log(blob)
      //   }
      // }
      //   let that = this
      // retrieveImageFromClipboardAsBase64(e, function(imageDataBase64){
      //   that.single_item.immagine = imageDataBase64
      // });
    },
    resizeImageBackEnd(file, maxSize) {
      const reader = new FileReader();
      const image = new Image();
      const canvas = document.createElement("canvas");

      const resize = () => {
        let { width, height } = image;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);

        const dataUrl = canvas.toDataURL("image/jpeg");

        return this.dataURItoBlob(dataUrl);
      };

      return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = (readerEvent) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };

        reader.readAsDataURL(file);
      });
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        this.resizeImageBackEnd(blob, 600)
          .then((resizedImage) => {
            // img.src = URL.createObjectURL(resizedImage);
            // // Create an abstract canvas and get context
            var mycanvas = document.createElement("canvas");
            var ctx = mycanvas.getContext("2d");

            // // Create an image
            var img = new Image();

            // // Once the image loads, render the img on the canvas
            img.onload = function () {
              // Update dimensions of the canvas with the dimensions of the image
              mycanvas.width = this.width;
              mycanvas.height = this.height;

              // Draw the image
              ctx.drawImage(img, 0, 0);

              // Execute callback with the base64 URI of the image
              if (typeof callback == "function") {
                callback(mycanvas.toDataURL(imageFormat || "image/png"));
              }
            };

            var reader = new FileReader();
            reader.readAsDataURL(resizedImage);

            reader.onload = function () {
              img.src = reader.result;
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    stayHere(idTicket) {
      if (this.assistenza == "1") {
        this.$router
          .replace({
            name: "SingleTicketAssistenza",
            params: {
              id: idTicket,
              tipo_permesso: this.tipo_permesso.toString(),
              assistenza: this.assistenza,
            },
          })
          .catch(() => {});
      } else {
        this.$router.push("/home/ticket/" + idTicket);
      }
    },
    validate(returnView) {
      if (this.single_item.email && !this.validEmail(this.single_item.email)) {
        this.errors.push("Email non valida.");
        return true;
      }
      if (
        this.single_item.id_responsabile != null &&
        this.single_item.id_stato == 0
      ) {
        this.single_item.id_stato = 1;
      }

      save(this, returnView);
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    warning(data_fine) {
      if (data_fine != null) {
        const today = new Date();
        var min_date = moment(today).format("L");
        today.setDate(today.getDate() + 3);
        var date1 = moment(data_fine).format("L");
        var max_date = moment(today).format("L");
        if (min_date <= date1 && date1 < max_date) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>