<template>
  <div>
    <!-- Alert Dialog -->
    <v-dialog persistent width="400" v-model="alert.mostra">
      <v-card>
        <v-container>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-show="this.alert.loader"
          ></v-progress-circular>
          <v-row v-if="!alert.loader" align="center" justify="center">
            <v-col cols="1" align="center">
              <v-icon large>{{ alert.icona }}</v-icon>
            </v-col>
            <v-col cols="11">
              <h3 style="word-break: keep-all; text-align: left; padding: 0 20px">
                {{ alert.messaggio }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-show="!this.alert.loader">
          <v-spacer />
          <v-btn text color="primary" @click="alert.mostra = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <!-- MAIN TOOLBAR -->
      <v-toolbar dense class="indigo darken-1 headline" primary-title>
        <v-row justify="start">
          <v-col cols="5" align="left">
            <v-toolbar-title class="white--text"
              >Configurazione Part Program</v-toolbar-title
            >
          </v-col>
          <v-col cols="1">
            <Button
              colore_icona="primary"
              mdi_icona="mdi-plus"
              testo_tooltip="Aggiungi programma"
              v-on:buttonClick="addPartProgram"
            />
          </v-col>
          <v-col cols="1">
            <Button
              colore_icona="success"
              mdi_icona="mdi-floppy"
              testo_tooltip="Salva programmi"
              v-on:buttonClick="savePartPrograms"
            />
          </v-col>
        </v-row>
      </v-toolbar>

      <!-- PART PROGRAM LIST -->
      <v-container v-if="interfaces != null && configuration != null && programs != null">
        <v-expansion-panels>
          <v-expansion-panel v-for="(program, i) in programs" :key="i">
            <v-expansion-panel-header>
              <v-row no-gutters align="center">
                <v-col cols="11">
                  {{ program.name }}
                </v-col>
                <v-col cols="1">
                  <Button
                    colore_icona="error"
                    mdi_icona="mdi-delete"
                    testo_tooltip="Elimina programma"
                    v-on:buttonClick="deletePartProgram(program)"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col cols="6" align="center">
                  <v-text-field v-model="program.name" label="Nome programma" />
                </v-col>
              </v-row>
              <v-divider />
              <!-- STEPS -->
              <v-row align="center" class="my-3">
                <v-col cols="11" align="left">
                  <h2>Passaggi</h2>
                </v-col>
                <v-col cols="1" align="center">
                  <Button
                    colore_icona="primary"
                    mdi_icona="mdi-plus"
                    testo_tooltip="Nuovo passaggio"
                    v-on:buttonClick="addStep(program)"
                  />
                </v-col>
              </v-row>
              <v-expansion-panels>
                <v-expansion-panel v-for="(step, j) in program.steps" :key="j">
                  <v-expansion-panel-header>
                    <v-row no-gutters>
                      <v-col cols="11">
                        {{ step.name }}
                      </v-col>
                      <v-col cols="1">
                        <Button
                          colore_icona="error"
                          mdi_icona="mdi-delete"
                          testo_tooltip="Elimina passaggio"
                          v-on:buttonClick="deleteStep(step, program)"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- STEP HEADING -->
                    <v-row align="center">
                      <v-col cols="3" align="center">
                        <v-text-field v-model="step.name" label="Nome passaggio" />
                      </v-col>
                      <v-col cols="3" align="center">
                        <v-select
                          v-model="step.interface_id"
                          label="Interfaccia"
                          :items="interfaces"
                          item-text="interface_name"
                          item-value="interface_id"
                          @change="resetRegisterFields($event, step)"
                        />
                      </v-col>
                    </v-row>
                    <!-- STEP DETAIL -->
                    <v-row align="center">
                      <v-col cols="3">
                        <v-select
                          v-model="step.access_type"
                          label="Modalità accesso dato"
                          :items="access_types"
                          @change="step.default_value = null"
                        />
                      </v-col>
                      <v-col cols="3" align="center">
                        <v-select
                          v-model="step.type"
                          label="Tipo dato"
                          :items="configuration.data_types"
                          @change="step.default_value = null"
                        />
                      </v-col>
                      <v-col
                        cols="3"
                        align="center"
                        v-if="step.type != null && step.access_type < 2"
                      >
                        <DynamicInputVue
                          v-model="step.default_value"
                          :items="configuration.data_types[step.type].values"
                          :type="configuration.data_types[step.type].type"
                          label="Valore Default"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="isNumber(step) && step.access_type > 0">
                      <v-col cols="3">
                        <v-text-field
                          type="number"
                          v-model="step.boundaries.hard_min_value"
                          label="Limite minimo valore"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          type="number"
                          v-model="step.boundaries.hard_max_value"
                          label="Limite massimo valore"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          type="number"
                          v-model="step.boundaries.min_value"
                          label="Valore minimo default"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          type="number"
                          v-model="step.boundaries.max_value"
                          label="Valore massimo default"
                        />
                      </v-col>
                    </v-row>
                    <v-divider />
                    <!-- REGISTER DETAIL -->
                    <v-row class="my-3">
                      <v-col cols="12" align="left">
                        <h2>Impostazioni registro</h2>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                      <template v-for="(register, k, k1, k2) in getRegister(step)">
                        <v-col cols="3" :key="k" align="center">
                          <DynamicInputVue
                            v-model="step.register[register.field].value"
                            :items="register.items"
                            :type="register.type"
                            :label="register.name"
                          />
                        </v-col>
                        <v-col
                          cols="1"
                          :key="k1"
                          align="center"
                          v-if="register.type == 'string' || register.type == 'textarea'"
                        >
                          <Button
                            colore_icona="primary"
                            mdi_icona="mdi-format-list-text"
                            testo_tooltip="Parametri aggiuntivi"
                            v-on:buttonClick="
                              showParameters(step.register[register.field].parameters)
                            "
                          />
                        </v-col>
                        <v-col cols="1" v-else :key="k2">
                          <v-icon disabled>mdi-format-list-text</v-icon>
                        </v-col>
                      </template>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>
    <v-dialog v-model="ui.dialog.display" persistent>
      <v-toolbar dense color="indigo darken-1" dark>
        <v-row align="center" dark>
          <v-col cols="5" align="left">
            <v-toolbar-title>Parametri aggiuntivi</v-toolbar-title>
          </v-col>
          <v-col cols="2" align="center">
            <v-btn small light @click="addParameter()">
              <v-icon color="success">mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="5" align="right">
            <v-btn small light @click="ui.dialog.display = false">
              <v-icon color="primary">mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card
        v-if="ui.dialog.reference != null"
        style="max-height: 80vh; overflow-y: auto"
      >
        <v-container>
          <v-expansion-panels>
            <v-expansion-panel v-for="(parameter, i) in ui.dialog.reference" :key="i">
              <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="start">
                  <v-col align="left" cols="11">
                    {{ parameter.name }}
                  </v-col>
                  <v-col cols="1">
                    <Button
                      colore_icona="error"
                      mdi_icona="mdi-delete"
                      testo_tooltip="Elimina parametro"
                      v-on:buttonClick="deleteParameter(parameter)"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row align="center">
                  <v-col cols="3">
                    <v-text-field label="Nome" v-model="parameter.name" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="Simbolo" v-model="parameter.symbol" />
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="3">
                    <v-select
                      v-model="parameter.access_type"
                      label="Modalità accesso parametro"
                      :items="access_types"
                      @change="parameter.default_value = null"
                    />
                  </v-col>
                  <v-col cols="3" align="center">
                    <v-select
                      v-model="parameter.type"
                      label="Tipo parametro"
                      :items="configuration.data_types"
                      @change="parameter.default_value = null"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    align="center"
                    v-if="parameter.type != null && parameter.access_type < 2"
                  >
                    <DynamicInputVue
                      v-model="parameter.default_value"
                      :items="configuration.data_types[parameter.type].values"
                      :type="configuration.data_types[parameter.type].type"
                      label="Valore Default"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="isNumber(parameter) && parameter.access_type > 0">
                  <v-col cols="3">
                    <v-text-field
                      type="number"
                      v-model="parameter.boundaries.hard_min_value"
                      label="Limite minimo valore"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="number"
                      v-model="parameter.boundaries.hard_max_value"
                      label="Limite massimo valore"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="number"
                      v-model="parameter.boundaries.min_value"
                      label="Valore minimo default"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      type="number"
                      v-model="parameter.boundaries.max_value"
                      label="Valore massimo default"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button";
import DynamicInputVue from "./DynamicInput.vue";

export default {
  components: {
    Button,
    DynamicInputVue,
  },
  props: ["id_macchina"],
  data() {
    return {
      access_types: [
        { text: "Costante", value: 0 },
        { text: "Opzionale", value: 1 },
        { text: "Obbligatorio", value: 2 },
      ],
      configuration: null,
      interfaces: null,
      programs: null,
      NETWORK: null,
      // Alert Dialog Variable
      alert: {
        icona: null,
        messaggio: null,
        mostra: false,
        loader: false,
      },
      ui: {
        dialog: {
          reference: null,
          display: false,
        },
        param_list_key: 0,
      },
    };
  },

  mounted() {
    this.getinterfacesList();
    this.getMacchineProgram();
    this.getSchema();
    this.NETWORK = this.$store.state.Network;
  },
  methods: {
    addPartProgram() {
      let id = 1;
      for (let i = 0; i < this.programs.length; i++) {
        id = Math.max(Number(this.programs[i].id), id) + 1;
      }
      this.programs.push({ name: "Part program #" + id, id: id, steps: [] });
    },
    deletePartProgram(program) {
      this.programs.splice(this.programs.indexOf(program), 1);
    },

    addStep(program) {
      let id = 1;
      for (let i = 0; i < program.steps.length; i++) {
        id = Math.max(Number(program.steps[i].id), id) + 1;
      }

      program.steps.push({
        name: "Passaggio #" + id,
        register: new Object(),
        access_type: 1,
        type: null,
        interface_id: null,
        id: id,
        default_value: null,
        boundaries: {
          min_value: null,
          max_value: null,
          hard_min_value: null,
          hard_max_value: null,
        },
      });
    },

    deleteStep(step, program) {
      program.steps.splice(program.steps.indexOf(step), 1);
    },

    deleteParameter(parameter) {
      const parameters = this.ui.dialog.reference;
      parameters.splice(parameters.indexOf(parameter), 1);
    },

    addParameter() {
      let id = 1;
      const parameters = this.ui.dialog.reference;
      for (let i = 0; i < parameters.length; i++) {
        id = Math.max(Number(parameters[i].id), id) + 1;
      }

      parameters.push({
        symbol: null,
        name: "Parametro #" + id,
        default_value: null,
        type: null,
        id: id,
        access_type: 1,
        boundaries: {
          min_value: null,
          max_value: null,
          hard_min_value: null,
          hard_max_value: null,
        },
      });
    },

    showParameters(params) {
      Object.assign(this.ui.dialog, { display: true, reference: params });
    },

    isNumber(item) {
      try {
        return this.configuration.data_types[item.type].type == "number";
      } catch {
        return false;
      }
    },
    getRegister(step) {
      try {
        for (let i = 0; i < this.interfaces.length; i++) {
          const interf = this.interfaces[i];
          if (interf.interface_id == step.interface_id) {
            return this.configuration.transport_layers[interf.transport_layer]
              .register_fields;
          }
        }
        return [];
      } catch {
        return [];
      }
    },
    resetRegisterFields(id, step) {
      const defaults = {
        boolean: false,
        number: 0,
        string: null,
        textarea: null,
        keyvalue: new Array(),
      };
      for (let i = 0; i < this.interfaces.length; i++) {
        const interf = this.interfaces[i];
        if (interf.interface_id == id) {
          step.register = new Object();
          const register_fields = this.configuration.transport_layers[
            interf.transport_layer
          ].register_fields;
          for (let j = 0; j < register_fields.length; j++) {
            const field = register_fields[j];
            step.register[field.field] = {
              value: defaults[field.type],
              parameters: new Array(),
            };
          }
        }
      }
    },

    // TO get the Json schema for part program intializer
    getSchema() {
      let finale = {
        controller: "quickconnext",
        method: "POST",
        richiesta: {
          action: "getpartprogramschema",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", finale).then((res) => {
        this.configuration = res.data;
      });
    },
    getMacchineProgram() {
      let setpp_detail = {
        controller: "quickconnext",
        method: "POST",
        richiesta: {
          action: "getmachineprograms",
          machine_id: this.id_macchina,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", setpp_detail).then((res) => {
        console.log(res.data);
        if (res.data == null || res.data == "") {
          this.programs = [];
        } else {
          this.programs = res.data;
        }
      });
    },
    getinterfacesList() {
      let setpp_detail = {
        controller: "quickconnext",
        method: "POST",
        richiesta: {
          action: "getmachineoutputinterfaces",
          machine_id: this.id_macchina,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", setpp_detail).then((res) => {
        this.interfaces = res.data;
      });
    },
    // savePartPrograms() {
    //   const encoded = btoa(unescape(encodeURIComponent(JSON.stringify(this.programs))));
    //   let setpp_detail = {
    //     controller: "quickconnext",
    //     method: "PUT",
    //     richiesta: {
    //       action: "setmachineprograms",
    //       machine_id: this.id_macchina,
    //       token: localStorage.getItem("user_token"),
    //       part_prog_4_0: encoded,
    //     },
    //   };

    //   this.$store.dispatch("api", setpp_detail);
    // },
    savePartPrograms() {
      this.alert.loader = true;
      // this.alert.mostra = true;
      const encoded = btoa(unescape(encodeURIComponent(JSON.stringify(this.programs))));

      const that = this;
      const request = {
        controller: "quickconnext",
        response: { parse: false },
        showdialog: false,
        body: {
          action: "setmachineprograms",
          token: localStorage.getItem("user_token"),
          machine_id: this.id_macchina,
          part_prog_4_0: encoded,
        },
        method: "PUT",
        on: {
          load: function () {
            Object.assign(that.alert, {
              icona: "mdi-check",
              messaggio: "Salvato con successo!",
              mostra: true,
              loader: false,
            });
          },
          error: function (response) {
            response = response.target;
            Object.assign(that.alert, {
              icona: "mdi-alert-box",
              messaggio: response.responseText,
              mostra: true,
              loader: false,
            });
          },
        },
      };
      this.NETWORK.AjaxRequest(request);
    },
  },
};
</script>
