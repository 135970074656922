import moment from "moment/moment";

export default class QCConnection {
  codice = "";
  id_questionario = null;
  id_questionario_connessione = null;
  id_questionario_domanda = null;
  id_questionario_domanda_successiva = null;
  id_questionario_freccia = -1;
  id_utente = null;
  link = "";
  num_questionario_domanda = null;
  num_questionario_domanda_successiva = null;
  parametri = [];
  position_destination = "";
  position_source = "";
  punteggio = null;
  tipo = null;
  tipologia = "connessione";
  type = null;
  validita = 1;

  constructor(arg) {
    if (arg != undefined) {
      this.id_questionario = arg.id_questionario != undefined ? arg.id_questionario : this.id_questionario;
      this.id_questionario_connessione = arg.id_questionario_connessione != undefined ? arg.id_questionario_connessione : moment().valueOf();
      this.id_questionario_domanda = arg.id_questionario_domanda != undefined ? arg.id_questionario_domanda : this.id_questionario_domanda;
      this.num_questionario_domanda = arg.num_questionario_domanda != undefined ? arg.num_questionario_domanda : this.num_questionario_domanda;
      this.id_questionario_domanda_successiva = arg.id_questionario_domanda_successiva != undefined ? arg.id_questionario_domanda_successiva : this.id_questionario_domanda_successiva;
      this.num_questionario_domanda_successiva = arg.num_questionario_domanda_successiva != undefined ? arg.num_questionario_domanda_successiva : this.num_questionario_domanda_successiva;
      this.id_questionario_freccia = arg.id_questionario_freccia != undefined ? arg.id_questionario_freccia : -1;
      this.id_utente = arg.id_utente != undefined ? arg.id_utente : this.id_utente;
      this.tipologia = arg.tipologia != undefined ? arg.tipologia : this.tipologia;
      this.tipo = arg.tipo != undefined ? arg.tipo : this.tipo;
      this.link = arg.link != undefined ? arg.link : this.link;
      this.codice = arg.codice != undefined ? arg.codice : this.codice;
      this.parametri = arg.parametri != undefined ? arg.parametri : this.parametri;
      this.position_destination = arg.position_destination != undefined ? arg.position_destination : this.position_destination;
      this.position_source = arg.position_source != undefined ? arg.position_source : this.position_source;
      this.type = arg.type != undefined ? arg.type : this.type;
      this.validita = arg.validita != undefined ? arg.validita : this.validita;
      this.punteggio = arg.punteggio != undefined ? arg.punteggio : this.punteggio;
    } else {
      this.id_questionario = null;
      this.id_questionario_connessione = moment().valueOf();
      this.id_questionario_domanda = null;
      this.num_questionario_domanda = null;
      this.id_questionario_domanda_successiva = null;
      this.num_questionario_domanda_successiva = null;
      this.id_questionario_freccia = -1;
      this.id_utente = null;
      this.tipologia = "connessione";
      this.tipo = null;
      this.link = "";
      this.codice = "";
      this.parametri = [];
      this.position_destination = "";
      this.position_source = "";
      this.type = null;
      this.validita = 1;
      this.punteggio = null;
    }
  }
}