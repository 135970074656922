<template>
  <v-card class="pb-3">
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Ristampa Etichette
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Bottone di stampaggio -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="confermaStampa"
            v-show="etichetta_partenza > 0 && numero_etichette > 0"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="success" large> mdi-printer </v-icon>
          </v-btn>
        </template>
        <span>Stampa</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Bottone per tornare indietro -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" v-bind="attrs" v-on="on">
            <v-icon color="error" large> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-3">
      <span style="font-size: 20px"> Stampa </span>
      <div class="mx-2" style="display: inline-block">
        <v-text-field
          background-color="#ffffff"
          class="input-class centered-input"
          dense
          height="50px"
          hide-details
          label=""
          style="
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            line-height: 25px;
            max-width: 100px;
            padding: 0px;
          "
          type="number"
          v-model="etichetta_partenza"
        >
        </v-text-field>
      </div>
      <span style="font-size: 20px">
        etichette a partire dall'etichetta numero
      </span>
      <div class="mx-2" style="display: inline-block">
        <v-text-field
          background-color="#ffffff"
          class="input-class centered-input"
          dense
          height="50px"
          hide-details
          label=""
          style="
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            line-height: 25px;
            max-width: 100px;
            padding: 0px;
          "
          type="number"
          v-model="numero_etichette"
        >
        </v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "RistampaEtichette",
  components: {},
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      etichetta_partenza: null,
      numero_etichette: null,
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.resetProperties();
      this.$emit("click_chiudi");
    },
    confermaStampa() {
      // Fingo che l'utente abbia cliccato chiudi per chiudere il dialog
      // Preparo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "stampaetichette",
          token: localStorage.user_token,
          etichetta_partenza: this.etichetta_partenza,
          numero_etichette: this.numero_etichette,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.resetProperties();
            this.$emit("click_chiudi");
          } else {
            // Stampo una snackbar di errore
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    resetProperties() {
      this.etichetta_partenza = null;
      this.numero_etichette = null;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>