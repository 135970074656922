<template>
    <div>
        <DASHBOARDOVERVIEW :MODE="1"/>  
    </div>
</template>
<script>
import DASHBOARDOVERVIEW from "@/components/TOOLS/DashboardOverview/DashboardOverview.vue";
export default {
    name: "DashboardOverviewImport",
    components: {
        DASHBOARDOVERVIEW
    },
    mounted(){
        //this.$router.push('/home/import/1');
    },
    
}
</script>