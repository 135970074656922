 <template>
  <div
    :class="richiesta_sel.id_richiesta == -1 ? 'ma-0 mx-0' : ''"
    id="listener_richiesta"
  >
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-card elevation="0">
      <v-toolbar class="sfondoStandard" dense>
        <v-toolbar-title class="white--text">
          Richiesta di Intervento
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- Bottone di salvataggio -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="creaRichiesta"
              :disabled="readOnly"
              small
              v-bind="attrs"
              v-on="on"
              ><v-icon color="success">mdi-floppy</v-icon></v-btn
            >
          </template>
          <span>Salva Richiesta</span>
        </v-tooltip>

        <!-- Bottone di scaricamento multiplo -->
        <v-tooltip bottom v-if="richiesta_sel.id_richiesta != -1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-5"
              @click="
                clickScaricaAllegatiMultipli(
                  `Allegati_Digital_Marine_Richiesta_${
                    richiesta_sel.cod_richiesta_custom != ''
                      ? richiesta_sel.cod_richiesta_custom
                      : richiesta_sel.cod_richiesta
                  }.zip`
                )
              "
              small
              v-bind="attrs"
              v-on="on"
              ><v-icon color="primary">mdi-download-multiple</v-icon></v-btn
            >
          </template>
          <span
            >Scarica Allegati per Questa Richiesta e Interventi Relativi</span
          >
        </v-tooltip>

        <!-- Bottone di invio automatico mail -->
        <v-tooltip bottom v-if="dialog_email_custom === true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickDialogEmail"
              :disabled="readOnly"
              small
              v-bind="attrs"
              v-on="on"
              ><v-icon color="primary">mdi-email-send</v-icon></v-btn
            >
          </template>
          <span>Invio Automatico Email</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="clickIndietro" small v-bind="attrs" v-on="on"
              ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
            >
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
      <v-container>
        <div class="mx-13 mb-2">
          <v-row align="center" justify="center">
            <v-col :cols="allow_cod_richiesta_custom === true ? 4 : 3">
              <v-text-field
                disabled
                label="Cod. Richiesta"
                :placeholder="
                  richiesta_sel.id_richiesta === -1 ? '(Automatico)' : ''
                "
                v-model="cod_richiesta"
              ></v-text-field>
            </v-col>
            <v-col cols="4" v-if="allow_cod_richiesta_custom === true">
              <v-text-field
                label="Cod. Richiesta Custom"
                v-model="cod_richiesta_custom"
              ></v-text-field>
            </v-col>
            <v-col :cols="allow_cod_richiesta_custom === true ? 4 : 3">
              <v-text-field
                label="Richiedente"
                v-model="des_richiedente"
                :disabled="readOnly"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" md="2">
              <v-text-field
                disabled
                label="Data Richiesta"
                v-model="data_richiesta"
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-autocomplete
                v-model="id_urgenza"
                label="Urgenza"
                :items="urgenza_lista"
                placeholder="Seleziona"
                required
                item-text="des"
                item-value="id_tabella"
                :disabled="readOnly"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                label="Tipo Richiesta"
                :items="tipo_richiesta_lista"
                item-text="des"
                item-value="id_tabella"
                placeholder="Seleziona"
                required
                v-model="tipo_richiesta"
                :disabled="readOnly"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" v-if="stato_richiesta_visible === true">
              <v-autocomplete
                label="Stato Richiesta"
                :items="
                  stato_lista.filter(
                    (e) =>
                      e.cod == 'PENDING' ||
                      e.cod == 'FINISH' ||
                      e.cod == 'REJECT' ||
                      e.cod == 'ON' ||
                      e.cod == 'ACCEPT'
                  )
                "
                item-text="des"
                item-value="id_tabella"
                placeholder="Seleziona"
                :readonly="id_stato != id_stato_richiesta_aperta"
                required
                v-model="id_stato"
                :disabled="
                  readOnly ||
                  richiesta_sel.id_richiesta == -1 ||
                  (cod_profilo != 'SVIL' &&
                    cod_profilo != 'AMM' &&
                    cod_profilo != 'GOD')
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2" v-if="stato_richiesta_2_visible === true">
              <v-autocomplete
                label="Dettaglio Stato"
                :items="stato_2_lista"
                item-text="des"
                item-value="id_tabella"
                placeholder="Seleziona"
                required
                v-model="id_stato_2"
                :disabled="
                  readOnly ||
                  richiesta_sel.id_richiesta == -1 ||
                  (cod_profilo != 'SVIL' &&
                    cod_profilo != 'AMM' &&
                    cod_profilo != 'GOD')
                "
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="tempo_stimato_visible === true">
            <v-col cols="3" md="2">
              <v-text-field
                label="Q.tà Tempo Stimato"
                type="number"
                v-model="qta_tempo_stimato"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" md="2">
              <v-autocomplete
                label="U.M. Tempo Stimato"
                :items="tempo_lista"
                item-text="des"
                item-value="id_tabella"
                placeholder="Seleziona"
                readonly
                v-model="um_tempo_stimato"
                :disabled="readOnly"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" md="2">
              <v-text-field
                label="Q.tà Tempo Effettivo"
                type="number"
                v-model="qta_tempo_effettivo"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" md="2">
              <v-autocomplete
                label="U.M. Tempo Effettivo"
                :items="tempo_lista"
                item-text="des"
                item-value="id_tabella"
                placeholder="Seleziona"
                readonly
                v-model="um_tempo_effettivo"
                :disabled="readOnly"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>

        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="transparent"></v-tabs-slider>
          <v-tab
            active-class="sfondoStandard"
            class="py-0 my-0"
            v-for="item in tabs_richiesta"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Tab generale -->
          <v-tab-item>
            <v-card elevation="3" outlined class="mx-4 mb-3 py-4">
              <v-row class="mx-7">
                <v-col cols="6" md="2" v-if="config_sede.visibility">
                  <v-autocomplete
                    :disabled="readOnly"
                    @change="
                      id_struttura = null;
                      id_area = null;
                      id_stanza = null;
                      id_zona = null;
                      id_macchinario = null;
                      des_struttura = null;
                      des_area = null;
                      des_zona = null;
                      des_macchinario = null;
                      des_stanza = null;
                    "
                    v-model="id_sede"
                    :items="sede_lista"
                    :label="config_sede.label.singolare"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" v-if="config_modello.visibility">
                  <v-text-field
                    :placeholder="config_modello.label.singolare"
                    readonly
                    v-model="modello"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" v-if="config_struttura.visibility">
                  <v-autocomplete
                    @change="
                      id_area = null;
                      id_stanza = null;
                      id_zona = null;
                      id_macchinario = null;
                      des_area = null;
                      des_zona = null;
                      des_macchinario = null;
                      des_stanza = null;
                    "
                    v-model="id_struttura"
                    :disabled="
                      struttura_lista.filter((elem) => elem.val_int === id_sede)
                        .length === 0 || readOnly
                    "
                    :items="
                      struttura_lista.filter((elem) => elem.val_int === id_sede)
                    "
                    :label="config_struttura.label.singolare"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" v-if="config_area.visibility">
                  <v-autocomplete
                    @change="
                      id_zona = null;
                      des_zona = null;
                      id_stanza = null;
                      des_stanza = null;
                      id_macchinario = null;
                      des_macchinario = null;
                    "
                    v-model="id_area"
                    :disabled="
                      area_lista.filter((elem) => elem.val_int === id_struttura)
                        .length === 0 || readOnly
                    "
                    :items="
                      area_lista.filter((elem) => elem.val_int === id_struttura)
                    "
                    :label="config_area.label.singolare"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="6" md="3" v-if="config_zona.visibility">
                  <v-autocomplete
                    @change="
                      id_stanza = null;
                      des_stanza = null;
                      id_macchinario = null;
                      des_macchinario = null;
                    "
                    v-model="id_zona"
                    :disabled="
                      zona_lista.filter((elem) => elem.val_int === id_area)
                        .length === 0 || readOnly
                    "
                    :items="
                      zona_lista.filter((elem) => elem.val_int === id_area)
                    "
                    :label="config_zona.label.singolare"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" v-if="config_stanza.visibility">
                  <v-autocomplete
                    @change="
                      id_macchinario = null;
                      des_macchinario = null;
                    "
                    v-model="id_stanza"
                    :disabled="
                      stanza_lista.filter((elem) => elem.val_int === id_zona)
                        .length === 0 || readOnly
                    "
                    :items="
                      stanza_lista.filter((elem) => elem.val_int === id_zona)
                    "
                    :label="config_stanza.label.singolare"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" v-if="config_macchinario.visibility">
                  <v-autocomplete
                    v-model="id_macchinario"
                    :disabled="
                      macchinario_lista.filter(
                        (elem) => elem.val_int === id_stanza
                      ).length === 0 || readOnly
                    "
                    :items="
                      macchinario_lista.filter(
                        (elem) => elem.val_int === id_stanza
                      )
                    "
                    :label="config_macchinario.label.singolare"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                    <template #label>
                      <span class="">Macchinario/Attrezzatura</span>
                      <span
                        @click.stop
                        style="min-width: 500px; pointer-events: all"
                      >
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" md="3">
                  <v-checkbox
                    v-model="email"
                    :label="`Invio Email`"
                    :disabled="readOnly"
                    v-if="show_invio_email"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>

          <!-- Tab della descrizione / dettagli-->
          <v-tab-item>
            <v-card elevation="3" outlined class="mx-4 mb-3 py-4">
              <v-container>
                <v-row>
                  <v-textarea
                    :disabled="readOnly"
                    class="mx-5"
                    :label="`${
                      this.allow_doppia_descrizione === true ? 'Problema' : ''
                    }`"
                    outlined
                    v-model="descrizione"
                  ></v-textarea>
                </v-row>
                <!-- Textarea della Soluzione -->
                <v-row>
                  <v-textarea
                    class="mx-5"
                    :disabled="readOnly"
                    label="Soluzione"
                    outlined
                    v-model="soluzione"
                  ></v-textarea>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>

          <!-- Tab degli allegati -->
          <v-tab-item>
            <GestioneAllegato
              ref="componenteAllegati"
              controller_allegati="Global"
              controller_caratteristiche_tabella="Manutenzione"
              :id_riferimento="richiesta_sel.id_richiesta"
              tipo_allegato="RICHIESTA"
              @snackbarOpen="snackbarOpen"
              :read_only="readOnly"
            />
          </v-tab-item>

          <!-- Tab della bacheca -->
          <v-tab-item v-if="bacheca_visible === true">
            <Bacheca
              :id="richiesta_sel.id_richiesta"
              :tipo="'richiesta'"
              :visible="visible"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-container>

      <!-- Dialog di selezione degli indirizzi email. -->
      <v-dialog v-model="dialog_email_visible">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Seleziona Destinatari</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudiDialogEmail" small v-bind="attrs" v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="overflow-y: auto">
            <v-container>
              <v-row align="center" class="ma-0 pa-0" justify="center">
                <v-col align="center" cols="10" justify="center">
                  <v-autocomplete
                    v-model="destinatari"
                    :items="destinatari_lista"
                    label="Destinatari"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="des"
                    required
                    chips
                    deletable-chips
                    multiple
                  >
                    <template #label>
                      <span class="mr-2">Destinatari</span>
                      <span
                        @click.stop
                        style="min-width: 500px; pointer-events: all"
                      >
                        <Modules
                          v-on:dialogModules="
                            get_sedi_from_macchine === false
                              ? gettabelle
                              : updateNavi
                          "
                          tipo="MAN_DESTINATARIO_EMAIL"
                          nome="E-mail"
                          setaction="settabella"
                          getaction="gettabella"
                          controller="Global"
                          :controllaCodice="true"
                          :filter="'validita = 1'"
                          :all_label="['Indirizzo e-mail']"
                          :dati="[{ des: '' }]"
                        />
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
    <v-overlay absolute :value="caricamento_dati">
      <div class="my-2">
        <v-progress-circular
          color="indigo"
          :rotate="-90"
          :size="120"
          :value="avanzamento"
          :width="20"
        >
          <div
            style="
              background-color: #3f51b5;
              border-radius: 250px;
              width: 50px;
              height: 50px;
              line-height: 50px;
              text-align: center;
            "
          >
            <span style="color: white; display: inline-block; height: 50px"
              >{{ avanzamento }}%</span
            >
          </div>
        </v-progress-circular>
      </div>
      <div style="background-color: #3f51b5; border-radius: 5px">
        <span class="pa-2" style="color: white"
          >{{ caricati }} MB / {{ caricabili }} MB</span
        >
      </div>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import Bacheca from "@/components/MANUTENZIONE/Bacheca.vue";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import moment from "moment/moment";
import Request from "@/classes/Request.js";
import { saveAs } from "file-saver";
// import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "Richiesta",
  components: {
    Bacheca,
    GestioneAllegato,
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {
    allow_cod_richiesta_custom: {
      type: Boolean,
      default: false,
    },
    allow_doppia_descrizione: {
      type: Boolean,
      default: false,
    },
    bacheca_visible: {
      type: Boolean,
      default: false,
    },
    cod_filtro_sede: {
      type: String,
      default: "",
    },
    config_area: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Area", plurale: "Aree" },
          visibility: true,
        };
      },
    },
    config_macchinario: {
      type: Object,
      default: function () {
        return {
          label: {
            singolare: "Macchinario/Attrezzatura",
            plurale: "Marrchinari/Attrezzature",
          },
          visibility: true,
        };
      },
    },
    config_modello: {
      type: Object,
      default: function () {
        return {
          label: {
            singolare: "Modello",
            plurale: "Modelli",
          },
          visibility: false,
        };
      },
    },
    config_sede: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Sede", plurale: "Sedi" },
          visibility: true,
        };
      },
    },
    config_stanza: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Stanza", plurale: "Stanze" },
          visibility: true,
        };
      },
    },
    config_struttura: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Struttura", plurale: "Strutture" },
          visibility: true,
        };
      },
    },
    config_zona: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Zona", plurale: "Zone" },
          visibility: true,
        };
      },
    },
    controller: { type: String, default: "Manutenzione" },
    dialog_email_custom: { type: Boolean, default: false },
    get_sedi_from_macchine: {
      type: Boolean,
      default: false,
    },
    richiesta_sel: { type: Object, default: null },
    show_invio_email: { type: Boolean, default: true },
    stato_richiesta_visible: { type: Boolean, default: false },
    stato_richiesta_2_visible: { type: Boolean, default: false },
    tempo_stimato_visible: { type: Boolean, default: false },
    urgenza_richiesta_default_val: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      allegato_sel: {},
      avanzamento: 0,
      caricabili: 0,
      caricati: 0,
      email: false,
      cod_profilo: "",
      cod_richiesta: "",
      cod_richiesta_custom: "",
      data_richiesta: null,
      descrizione: "",
      des_richiedente: "",
      des_area: "",
      id_area: null,
      des_macchinario: "",
      id_macchinario: null,
      des_sede: "",
      id_sede: null,
      des_struttura: "",
      id_struttura: null,
      des_tipo_manutenzione: "",
      id_tipo_manutenzione: null,
      des_urgenza: "",
      id_urgenza: null,
      des_zona: "",
      des_stanza: "",
      id_zona: null,
      id_stanza: null,
      id_stato: null,
      id_stato_2: null,
      modello: "",
      nuovo_messaggio: "",
      id_stato_richiesta_aperta: null,
      id_stato_richiesta_confermata: null,
      id_stato_richiesta_rigettata: null,
      qta_tempo_effettivo: 0,
      qta_tempo_stimato: 0,
      soluzione: "",
      tab: 0,
      tipo_richiesta: null,
      um_tempo_effettivo: null,
      um_tempo_stimato: null,

      // Modal
      caricamento_dati: false,
      check: null,
      controllo_snackbar_effettuabile: false,
      dialog_email_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      area_lista: [],
      date_validita_tabella: [],
      destinatari: [],
      destinatari_lista: [],
      headers_allegati: [
        {
          text: "Scarica",
          value: "scarica",
          sortable: false,
        },
        {
          text: "Tipologia",
          value: "famiglia",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_allegato",
          sortable: false,
        },
        {
          text: "Data Validità",
          value: "data_validita",
          sortable: false,
        },
        {
          text: "Caricato da",
          value: "utente",
          sortable: false,
        },
        {
          text: "Immagine",
          value: "preview",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      macchinario_lista: [],
      sede_lista: [],
      stato_lista: [],
      stato_2_lista: [],
      struttura_lista: [],
      stanza_lista: [],
      tabs_richiesta: [],
      tempo_lista: [],
      tipo_manutenzione_lista: [],
      tipo_richiesta_lista: [],
      urgenza_lista: [],
      zona_lista: [],
    };
  },
  computed: {
    nominativo() {
      return localStorage.nominativo;
    },
  },
  watch: {
    id_urgenza: {
      immediate: true,
      handler() {
        this.urgenza_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_urgenza) {
            this.des_urgenza = elem.des;
          }
        });
      },
    },
    id_sede: {
      immediate: true,
      handler() {
        // Quando cambia l'id_sede, gli associo una descrizione
        this.sede_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_sede) {
            this.des_sede = elem.des;
            this.modello = elem.val_txt;
          }
        });

        if (
          this.id_sede != null &&
          this.controllo_snackbar_effettuabile === true &&
          this.struttura_lista.filter((elem) => elem.val_int === this.id_sede)
            .length === 0 &&
          this.config_struttura.visibility === true
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non sono state trovate ${this.config_struttura.label.plurale} collegate a questa ${this.config_sede.label.singolare}.`;
          this.snackbar_text_color = "white";
        }
      },
    },
    id_struttura: {
      immediate: true,
      handler() {
        this.struttura_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_struttura) {
            this.des_struttura = elem.des;
          }
        });

        if (
          this.id_struttura != null &&
          this.controllo_snackbar_effettuabile === true &&
          this.area_lista.filter((elem) => elem.val_int === this.id_struttura)
            .length === 0 &&
          this.config_area.visibility === true
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non sono state trovate ${this.config_aree.label.plurale} collegate a questa ${this.config_struttura.label.singolare}.`;
          this.snackbar_text_color = "white";
        }
      },
    },
    id_area: {
      immediate: true,
      handler() {
        this.area_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_area) {
            this.des_area = elem.des;
          }
        });

        if (
          this.id_area != null &&
          this.controllo_snackbar_effettuabile === true &&
          this.zona_lista.filter((elem) => elem.val_int === this.id_area)
            .length === 0 &&
          this.config_zona.visibility === true
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non sono state trovate ${this.config_zona.label.plurale} collegate a questa ${this.config_area.label.singolare}.`;
          this.snackbar_text_color = "white";
        }
      },
    },

    id_zona: {
      immediate: true,
      handler() {
        this.zona_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_zona) {
            this.des_zona = elem.des;
          }
        });
        if (
          this.id_zona != null &&
          this.controllo_snackbar_effettuabile === true &&
          this.stanza_lista.filter((elem) => elem.val_int === this.id_zona)
            .length === 0 &&
          this.config_stanza.visibility === true
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `Non sono state trovate ${this.config_stanza.label.plurale} collegate a questa ${this.config_zona.label.singolare}.`;
          this.snackbar_text_color = "white";
        }
      },
    },
    id_stanza: {
      immediate: true,
      handler() {
        this.stanza_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_stanza) {
            this.des_stanza = elem.des;
          }
        });

        if (
          this.id_stanza != null &&
          this.controllo_snackbar_effettuabile === true &&
          this.macchinario_lista.filter(
            (elem) => elem.val_int === this.id_stanza
          ).length === 0
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non sono state trovati macchinari o attrezzature collegate a questa stanza.";
          this.snackbar_text_color = "white";
        }
      },
    },
    id_macchinario: {
      immediate: true,
      handler() {
        this.macchinario_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_macchinario) {
            this.des_macchinario = elem.des;
          }
        });
      },
    },
    id_tipo_manutenzione: {
      immediate: true,
      handler() {
        this.tipo_manutenzione_lista.forEach((elem) => {
          if (elem.id_tabella === this.id_tipo_manutenzione) {
            this.des_tipo_manutenzione = elem.des;
          }
        });
      },
    },
    visible: {
      immediate: true,
      async handler() {
        // Quando il componente diventa visibile eseguo le inizializzazioni

        // Prendo dal BE i dati delle select
        if (this.get_sedi_from_macchine === false) {
          this.gettabelle();
        } else {
          this.updateNavi();
        }

        if (this.visible === true) {
          // Decido quali devono essere le tabs disponibili in base alle props
          this.tabs_richiesta = [
            "Generale",
            `${
              this.allow_doppia_descrizione === true
                ? "Dettagli"
                : "Descrizione"
            }`,
            "Allegati",
          ];

          if (this.bacheca_visible === true) {
            this.tabs_richiesta.push("Bacheca");
          }

          // Ho deciso quali tab sono visibili, ora resetto la posizione sulla prima
          this.tab = 0;

          // Resetto gli array di supporto degli allegati
          this.lista_src = [];
          this.lista_files = [];

          /* Resetto l'array dei destinatari selezionati in modo
           * da dimenticarmi di quello che l'utente ha scelto prima
           * (che potrebbe non essere più valido). */
          this.destinatari.length = 0;

          // Valorizzo la data della richiesta
          this.data_richiesta = moment().format("DD/MM/YYYY");

          /* Registro il ruolo dell'utente, che mi serve per capire
           * se può o meno vedere certe cose / compiere certe azioni. */
          this.cod_profilo = localStorage.cod_profilo;

          // Se è un nuovo inserimento, setto i valori di default
          // Genero una richiesta resettata
          this.cod_richiesta = this.richiesta_sel.cod_richiesta;
          this.cod_richiesta_custom = this.richiesta_sel.cod_richiesta_custom;
          this.data_richiesta = this.richiesta_sel.data_richiesta;
          this.descrizione = this.richiesta_sel.descrizione;
          this.des_richiedente = this.richiesta_sel.des_richiedente;
          this.des_area = this.richiesta_sel.des_area;
          this.des_macchinario = this.richiesta_sel.des_macchinario;
          this.des_sede = this.richiesta_sel.des_sede;
          this.des_struttura = this.richiesta_sel.des_struttura;
          this.des_tipo_manutenzione = this.richiesta_sel.des_tipo_manutenzione;
          this.des_urgenza = this.richiesta_sel.des_urgenza;
          this.des_zona = this.richiesta_sel.des_zona;
          this.email = this.richiesta_sel.email;
          this.id_area = this.richiesta_sel.id_area;
          this.id_macchinario = this.richiesta_sel.id_macchinario;
          this.id_sede = this.richiesta_sel.id_sede;
          this.id_stato = this.richiesta_sel.id_stato;
          this.id_struttura = this.richiesta_sel.id_struttura;
          this.id_stanza = this.richiesta_sel.id_stanza;
          this.tipo_richiesta = parseInt(this.richiesta_sel.tipo_richiesta);
          this.id_tipo_manutenzione = this.richiesta_sel.id_tipo_manutenzione;
          this.id_urgenza = this.richiesta_sel.id_urgenza;
          this.id_zona = this.richiesta_sel.id_zona;
          this.modello = this.richiesta_sel.modello;
          this.qta_tempo_effettivo = this.richiesta_sel.qta_tempo_effettivo;
          this.qta_tempo_stimato = this.richiesta_sel.qta_tempo_stimato;
          this.soluzione = this.richiesta_sel.soluzione;
          this.um_tempo_effettivo = this.richiesta_sel.um_tempo_effettivo;
          this.um_tempo_stimato = this.richiesta_sel.um_tempo_stimato;
        }
      },
    },
  },
  methods: {
    aggiornaFiles(files) {
      this.files = files;
    },
    annulla() {
      // Resetto i campi
      this.des_richiedente = "";
      this.des_urgenza = "";
      this.id_urgenza = null;
      this.des_sede = "";
      this.id_sede = null;
      this.des_struttura = "";
      this.id_struttura = null;
      this.des_area = "";
      this.id_area = null;
      this.des_zona = "";
      this.id_zona = null;
      this.des_macchinario = "";
      this.id_macchinario = null;
      this.des_tipo_manutenzione = "";
      this.id_tipo_manutenzione = null;
      this.tipo_richiesta = null;
      this.email = false;
      this.descrizione = "";
      this.modello = "";
      this.soluzione = "";
    },
    chiudiDialogEmail() {
      this.dialog_email_visible = false;
    },
    clickDialogEmail() {
      this.dialog_email_visible = true;
    },
    clickIndietro() {
      // Resetto la tab attualmente aperta.
      this.tab = 0;

      // Emetto un evento per scatenare la gestione del click nel padre.
      this.$emit("click_indietro");
    },
    clickScaricaAllegatiMultipli(arg_str) {
      this.caricamento_dati = true;

      let request = {
        controller: "Allegato",
        method: "POST",
        richiesta: {
          action: "download_zip_richieste_interventi",
          token: localStorage.user_token,
          id_richiesta: this.richiesta_sel.id_richiesta,
        },
      };
      try {
        axios({
          url: this.$store.state.config.ip + "api/" + "Allegato",
          data: request.richiesta,
          method: "post",
          responseType: "arraybuffer",
          onDownloadProgress: (progressEvent) => {
            this.avanzamento = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            this.caricati = parseFloat(progressEvent.loaded / 1000000).toFixed(
              2
            );
            this.caricabili = parseFloat(progressEvent.total / 1000000).toFixed(
              2
            );
          },
        }).then((res) => {
          if (res.status === 200) {
            const blob = new Blob([res.data], { type: "octet/stream" });
            saveAs(blob, arg_str);

            // Resetto le variabili dell'verlay di caricamento
            this.caricamento_dati = false;
            this.avanzamento = 0;
            this.caricati = 0;
            this.caricabili = 0;
          } else if (res.status === 204) {
            // Resetto le variabili dell'verlay di caricamento
            this.caricamento_dati = false;
            this.avanzamento = 0;
            this.caricati = 0;
            this.caricabili = 0;

            // Stampo una snackbar di avvetimento.
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = `Non sono stati trovati allegati da scaricare.`;
            this.snackbar_text_color = "white";
          } else {
            // Resetto le variabili dell'verlay di caricamento
            this.caricamento_dati = false;
            this.avanzamento = 0;
            this.caricati = 0;
            this.caricabili = 0;

            // Stampo una snackbar di errore.
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = `Criticità rilevata, codice risosta del server: ${res.status}. Non è stato possibile scaricare gli allegati.`;
            this.snackbar_text_color = "white";
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    creaRichiesta() {
      // Eseguo una serie di controlli per validare i campi
      if (this.des_richiedente === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare un Richiedente.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.id_urgenza === null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare il livello di urgenza.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.id_sede === null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare la sede.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.id_struttura === null && this.struttura_lista.length > 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare la struttura.";
        this.snackbar_text_color = "white";
        return;
      }
      /*if (this.id_area === null && this.area_lista.length > 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare l'area.";
        this.snackbar_text_color = "white";
        return;
      }*/
      /*if (this.id_zona === null && this.zona_lista.length > 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare la zona.";
        this.snackbar_text_color = "white";
        return;
      }*/
      /*if (this.id_macchinario === null && this.macchinario_lista.length > 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare il macchinario.";
        this.snackbar_text_color = "white";
        return;
      }*/
      if (this.id_tipo_manutenzione === null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare il tipo di manutenzione.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.descrizione === "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Inserire una descrizione minima dell'intervento richiesto.";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.tipo_richiesta == null || this.tipo_richiesta == "") {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Specificare il tipo della richiesta";
        this.snackbar_text_color = "white";
        return;
      }

      /* Se i controlli hanno dato esito positivo,
       * preparo la richiesta di intervento */
      let richiesta = {
        allegati: [],
        cod_richiesta: this.cod_richiesta,
        cod_richiesta_custom: this.cod_richiesta_custom,
        data_richiesta: this.data_richiesta,
        des_area: this.des_area,
        descrizione: this.descrizione,
        des_macchinario: this.des_macchinario,
        des_richiedente: this.des_richiedente,
        des_sede: this.des_sede,
        des_struttura: this.des_struttura,
        des_tipo_manutenzione: this.des_tipo_manutenzione,
        destinatari: this.destinatari,
        des_urgenza: this.des_urgenza,
        des_zona: this.des_zona,
        email: this.email === true ? 1 : 0,
        id_area: this.id_area,
        id_macchinario: this.id_macchinario,
        id_richiesta: this.richiesta_sel.id_richiesta,
        id_sede: this.id_sede,
        tipo_richiesta: this.tipo_richiesta,
        id_stanza: this.id_stanza,
        id_stato:
          this.richiesta_sel.id_richiesta === -1
            ? this.id_stato_richiesta_aperta
            : this.id_stato,
        id_stato_2: this.id_stato_2,
        id_struttura: this.id_struttura,
        id_tipo_manutenzione: this.id_tipo_manutenzione,
        des_tipo_richiesta: this.tipo_richiesta,
        id_urgenza: this.id_urgenza,
        id_zona: this.id_zona,
        modello: this.modello,
        qta_tempo_effettivo: this.qta_tempo_effettivo,
        qta_tempo_stimato: this.qta_tempo_stimato,
        soluzione: this.soluzione,
        um_tempo_effettivo: this.um_tempo_effettivo,
        um_tempo_stimato: this.um_tempo_stimato,
      };

      let r = new Request(richiesta);

      // Formatto gli indirizzi email come piace al BE.
      let destinatari_tmp = this.destinatari.join();

      let check = false;

      if (r.destinatari.length > 1) {
        check = true;
      }

      if (check) {
        destinatari_tmp = destinatari_tmp.replaceAll(",", ";");
      }

      r.destinatari = destinatari_tmp;

      /* Se la richiesta è nuova, formatto la sua data
       * come richiesto dal BE per riuscire a salvarla. */
      if (r.id_richiesta == -1) {
        const data_tmp = `${r.data_richiesta
          .toString()
          .substring(6, 10)}-${r.data_richiesta
          .toString()
          .substring(3, 5)}-${r.data_richiesta.toString().substring(0, 2)}`;
        r.data_richiesta = moment(data_tmp).format("YYYY-MM-DD");
      }

      let request = {
        controller: this.controller,
        method: "PUT",
        richiesta: {
          action: "setrichiesta",
          token: localStorage.user_token,
          richiesta: [r],
        },
      };

      this.$store.dispatch("api", request).then(async (res) => {
        if (res.status === 200) {
          if (this.$refs.componenteAllegati) {
            this.$refs.componenteAllegati.id_riferimento_creazione_genitore =
              res.data;
            let resSalvataggio = await this.$refs.componenteAllegati.salvaTuttiAllegati();

            if (resSalvataggio) {
              this.snackbar = true;
              this.snackbar_background = "success";
              this.snackbar_icon = "mdi-check";
              this.snackbar_icon_color = "white";
              this.snackbar_text = "Salvataggio avvenuto con successo";
              this.snackbar_text_color = "white";

              let timer = setTimeout(() => {
                // Emetto un evento click_indietro per aggiornare la pagina
                this.$emit("click_indietro");
                clearTimeout(timer);
              }, 800);
            } else {
              this.snackbar = true;
              this.snackbar_background = "orange";
              this.snackbar_icon = "mdi-alert";
              this.snackbar_icon_color = "white";
              this.snackbar_text =
                "Aggiungere una descrizione per ogni Allegato.";
              this.snackbar_text_color = "white";
            }
          } else {
            /* Se invece non avevo allegati, emetto comunque
             * il click_indietro per gestire il fatto che
             * il dialog deve essere chiuso dal padre. */
            this.$emit("click_indietro");
          }
        }
      });
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_URGENZA_RICHIESTA','MAN_TIPO_RICHIESTA','MAN_ATTREZZATURA','MAN_ZONA','MAN_AREA','MAN_STRUTTURA','MAN_SEDE','MAN_STATO_RICHIESTA','MAN_STATO_RICHIESTA_2','MAN_STANZA','MAN_DESTINATARIO_EMAIL','MAN_TEMPO_STIMATO'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Se lo stato della risposta è diverso da 200 non faccio niente;
        if (res.status != 200 || res.data.response == false) {
          /* Abilito il semaforo che mi dice che posso effettuare
           * i controlli sulle snackbar. */
          this.controllo_snackbar_effettuabile = true;
          return;
        }

        // Valorizzo la lista delle urgenze
        this.urgenza_lista =
          res.data.MAN_URGENZA_RICHIESTA != undefined
            ? res.data.MAN_URGENZA_RICHIESTA
            : [];

        // Se è specificato un valore urgenza di default, lo registro
        this.urgenza_lista.forEach((elem) => {
          if (elem.cod === this.urgenza_richiesta_default_val) {
            this.id_urgenza = elem.id_tabella;
          }
        });

        // Valorizzo la lista dei tipi richiesta
        this.tipo_richiesta_lista =
          res.data.MAN_TIPO_RICHIESTA != undefined
            ? res.data.MAN_TIPO_RICHIESTA
            : [];
        this.macchinario_lista =
          res.data.MAN_ATTREZZATURA != undefined
            ? res.data.MAN_ATTREZZATURA
            : [];
        this.zona_lista =
          res.data.MAN_ZONA != undefined ? res.data.MAN_ZONA : [];
        this.area_lista =
          res.data.MAN_AREA != undefined ? res.data.MAN_AREA : [];
        this.struttura_lista =
          res.data.MAN_STRUTTURA != undefined ? res.data.MAN_STRUTTURA : [];

        /* Se devo filtrare le sedi per cod, le filtro, altrimenti
         * popolo l'array delle sedi con tutto quello che mi manda il BE. */
        if (this.cod_filtro_sede != "") {
          this.sede_lista =
            res.data.MAN_SEDE != undefined
              ? res.data.MAN_SEDE.filter(
                  (elem) => elem.cod === this.cod_filtro_sede
                )
              : [];
        } else {
          this.sede_lista =
            res.data.MAN_SEDE != undefined ? res.data.MAN_SEDE : [];
        }

        this.stanza_lista =
          res.data.MAN_STANZA != undefined ? res.data.MAN_STANZA : [];
        this.destinatari_lista =
          res.data.MAN_DESTINATARIO_EMAIL != undefined
            ? res.data.MAN_DESTINATARIO_EMAIL
            : [];

        // Cerco l'urgenza low e la uso come valore di default.
        /* Definisco la variabile di supporto che mi indica l'id
         * da BE per lo stato 'APERTA' delle richieste. */
        res.data.MAN_URGENZA_RICHIESTA.forEach((u) => {
          if (u.cod === "LOW") {
            this.id_urgenza = u.id_tabella;
          }
        });

        /* Definisco la variabile di supporto che mi indica l'id
         * da BE per lo stato 'APERTA' delle richieste. */
        res.data.MAN_STATO_RICHIESTA.forEach((stato) => {
          if (stato.cod === "PENDING") {
            this.id_stato_richiesta_aperta = stato.id_tabella;
          } else if (stato.cod === "ACCEPT") {
            this.id_stato_richiesta_confermata = stato.id_tabella;
          } else if (stato.cod === "REJECT") {
            this.id_stato_richiesta_rigettata = stato.id_tabella;
          }
        });

        // Valorizzo l'array degli stati della richiesta
        this.tempo_lista =
          res.data.MAN_TEMPO_STIMATO != undefined
            ? res.data.MAN_TEMPO_STIMATO
            : [];

        // Assegno un valore di default alla UM del tempo stimato.
        this.tempo_lista.forEach((elem) => {
          if (elem.cod === "HOUR") {
            this.um_tempo_stimato = elem.id_tabella;
            this.um_tempo_effettivo = elem.id_tabella;
          }
        });

        // Valorizzo l'array degli stati della richiesta
        this.stato_lista =
          res.data.MAN_STATO_RICHIESTA != undefined
            ? res.data.MAN_STATO_RICHIESTA
            : [];

        // Valorizzo l'array degli stati della richiesta 2
        this.stato_2_lista =
          res.data.MAN_STATO_RICHIESTA_2 != undefined
            ? res.data.MAN_STATO_RICHIESTA_2
            : [];

        /* Abilito il semaforo che mi dice che posso effettuare
         * i controlli sulle snackbar. */
        this.controllo_snackbar_effettuabile = true;
      });
    },
    snackbarOpen() {
      // console.log("entrato");
      // //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      // this.snackbar = true;
      // this.snackbar_background = "orange";
      // this.snackbar_icon = "mdi-alert";
      // this.snackbar_icon_color = "white";
      // this.snackbar_text =
      //   "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      // this.snackbar_text_color = "white";
      this.check = false;
    },
    updateNavi() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "updatenavi",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          /* Qui il backend ha parificato le macchine
           * che sono navie le etichette delle sedi, e
           * posso passare a valorizzare le select. */
          this.gettabelle();
        } else {
          // Se ricevo un errore avviso l'Utente con una snackbar.
          this.$refs.snackbarqv.print(
            "error",
            `Si è verificata una criticità tecnica con codice ${res.status}.`
          );
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.noBottomRule >>> .v-input__slot::before {
  border-style: none !important;
}
.v-text-field fieldset {
  color: red !important;
}
.bg-red {
  background: linear-gradient(
    90deg,
    rgba(97, 1, 1, 1) 0%,
    rgba(254, 0, 0, 1) 100%
  );
  color: white !important;
}
.sfondoStandard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 0%,
    rgba(63, 81, 181, 1) 100%
  );
  color: white !important;
}
</style>
