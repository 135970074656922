<template>
  <div>
    <v-text-field
      @click="dialogDateTime = true"
      v-model="dataVal"
      :label="label"
      :placeholder="placeholder"
    ></v-text-field>

    <v-dialog v-model="dialogDateTime" width="unset">
      <v-card>
        <v-toolbar class="indigo" dark dense>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small class="white" @click="setVal">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small @click="dialogDateTime = false" class="white">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-tabs
            class="tabs"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab :key="1" class="tabs">
              Date
            </v-tab>
            <v-tab :key="2" class="tabs">
              Time
            </v-tab>

            <v-tab-item :key="1" class="tabs">
              <v-card flat>
                <v-card-text>
                  <v-date-picker class="mt-4" v-model="date"></v-date-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="2" class="tabs">
              <v-card flat>
                <v-card-text>
                  <v-time-picker
                    v-model="time"
                    use-seconds
                    class="mt-4"
                    format="24hr"
                  ></v-time-picker>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DateTimePicker",
  props: ["dataVal", "label", "placeholder", "title"],
  data: () => ({
    tab: null,
    date: "",
    time: "",
    items: ["date", "time"],

    dialogDateTime: false,
  }),
  methods: {
    allowedStep: (m) => m % 10 === 0,
    setVal() {

      let val = moment(`${this.date} ${this.time}`);
    
      this.$emit("returnVal", { val: val._i, title: this.title });
      this.dialogDateTime = false;
    },
  },
  mounted() {
    // console.log(`this.dataVal`, this.dataVal)
    if (this.dataVal == null) {
      this.date = "";
      this.time = "";
    }
    if (this.dataVal) {
      let val = moment(this.dataVal,[
        'DD/MM/YYYY HH:mm:ss'
      ])
        .format("YYYY-MM-DD HH:mm:ss")
        .split(" ");
      this.date = val[0];
      // console.log(`this.date`, this.date)
      this.time = val[1];
    }
  },
};
</script>
