<template>
  <v-card witdh="unset">
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Gestisci Causali di Fermo</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="clickChiudi" small>
        <v-icon color="error">mdi-keyboard-backspace</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="3"
          ><v-select
            background-color="#ffffff"
            class="pa-1 mr-5"
            dense
            hide-details="auto"
            item-text="des"
            item-value="id_tabella"
            :items="fasi"
            label="Fase Lavorazione"
            outlined
            style="border-radius: 0px; display: inline-block; max-width: 350px"
            v-model="fase_sel"
          ></v-select
        ></v-col>
        <v-col cols="4">
          <span class="mr-3"><b>Causali generiche:</b></span>
          <span v-if="causali_generali.length == 0">
            (non ci sono causali generiche)
          </span>
          <span
            class="mr-5"
            v-for="causale in causali_generali"
            :key="causale.id_tabella"
            >{{ causale.des }}</span
          >
        </v-col>
        <v-col cols="4">
          <span class="mr-3"><b>Causali specifiche:</b></span>
          <span v-if="causali_specifiche.length == 0">
            (non ci sono causali specifiche per questa fase)
          </span>
          <span
            class="mr-5"
            v-for="causale in causali_specifiche"
            :key="causale.id_tabella"
            >{{ causale.des }}</span
          >
        </v-col>
        <v-col cols="1">
          <Modules
            v-on:dialogModules="getCausaliFermo"
            tipo="FASE_causali"
            nome="Causali Specifiche"
            setaction="settabelle"
            getaction="gettabelle"
            controller="Magazzino"
            db_name="quickproduction"
            addfields=",(select max(t.des) from tabella t where t.id_tabella=tabella.val_dbl) as dd"
            :filter="'(val_int = ' + this.fase_sel + ') AND validita = 1 '"
            :dati="[
              { des: '' },
              { val_int: fase_sel },
              {
                categoria: [
                  {
                    vmodel: 'val_dbl',
                    tipo: 'CATEGORIA_CAUSALI_FERMI',
                    nome: 'Categoria Causali Specifiche Fermi',
                    setaction: 'settabelle',
                    getaction: 'gettabelle',
                    controller: 'Magazzino',
                    filter: 'validita = 1',
                    dati: [{ des: '' }, { cod: '' }],
                  },
                ],
              },
            ]"
            :background_icon="true"
          />
          <Modules
            v-on:dialogModules="getCausaliFermo"
            tipo="FASE_causali"
            nome="Causali Generiche"
            setaction="settabelle"
            getaction="gettabelle"
            controller="Magazzino"
            db_name="quickproduction"
            addfields=",(select max(t.des) from tabella t where t.id_tabella=tabella.val_dbl) as dd"
            :filter="'(val_int IS null) AND validita = 1'"
            color="red--text"
            :background_icon="true"
            :dati="[
              { des: '' },
              {
                categoria: [
                  {
                    vmodel: 'val_dbl',
                    tipo: 'CATEGORIA_CAUSALI_FERMI',
                    nome: 'Categoria Causali Generiche Fermi',
                    setaction: 'settabelle',
                    getaction: 'gettabelle',
                    controller: 'Magazzino',
                    filter: 'validita = 1',
                    dati: [{ des: '' }, { cod: '' }],
                  },
                ],
              },
            ]"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Modules from "@/components/TOOLS/Modules.vue";
export default {
  name: "TabellaCausaliFermo",
  components: {
    Modules,
  },
  data() {
    return {
      // Variabili d'appoggio
      fase_sel: null,
      // Modal
      // Array
      causali_generali: [],
      causali_specifiche: [],
      fasi: [],
    };
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    getCausaliFermo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: "FASE_causali",
          filter:
            "(val_int IS NULL OR val_int = " + this.fase_sel + ")  AND validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        // Svuoto gli array delle causali
        this.causali_specifiche = [];
        this.causali_generali = [];
        /* Ripopolo l'array delle causali: se non hanno val_int sono
         * causali generali, altrimenti sono speficiche per una fase.*/
        res.data.forEach((elem) => {
          if (elem.val_int != null) {
            this.causali_specifiche.push(elem);
          } else {
            this.causali_generali.push(elem);
          }
        });
      });
    },
    getFasi() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: "FASE",
          id_utente: localStorage.getItem("id"),
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        // Popolo l'array "fasi"
        res.data.forEach((elem) => {
          this.fasi.push(elem);
        });
      });
    },
  },
  mounted() {
    this.getFasi();
  },
  watch: {
    fase_sel: {
      immediate: true,
      handler() {
        if (this.fase_sel != null) {
          this.getCausaliFermo();
        }
      },
    },
  },
};
</script>
<style scoped></style>
