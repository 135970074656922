<template>
  <v-card id="consuntivo_work_order">
    <v-toolbar
      class="white--text"
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title> Seleziona il Work Order </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="pa-0" style="overflow-y: auto">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="3">
            <v-text-field
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              label="Codice Articolo"
              style="display: inline-block; max-width: 350px"
              v-model="cod_articolo_sel"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="
                    cod_articolo_sel = '';
                    filtra();
                  "
                  color="primary"
                  >{{
                    cod_articolo_sel == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template></v-text-field
            >
          </v-col>
          <v-col cols="3">
            <v-text-field
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              label="Codice Commessa"
              style="display: inline-block; max-width: 350px"
              v-model="cod_commessa_sel"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="
                    cod_commessa_sel = '';
                    filtra();
                  "
                  color="primary"
                  >{{
                    cod_commessa_sel == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template></v-text-field
            >
          </v-col>
          <v-col cols="3" v-if="filtro_cod_macchina_visible === true">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_consuntivo"
              :item-text="(e) => e.cod_macchina"
              item-value="cod_macchina"
              label="Cod. Macchina"
              style="display: inline-block; max-width: 350px"
              v-model="cod_macchina_sel"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="
                    cod_macchina_sel = null;
                    filtra();
                  "
                  color="primary"
                  >{{
                    cod_macchina_sel == null ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_consuntivo"
              item-text="des"
              item-value="des"
              label="Lavorazione"
              style="display: inline-block; max-width: 350px"
              v-model="lavorazione_sel"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="
                    lavorazione_sel = null;
                    filtra();
                  "
                  color="primary"
                  >{{
                    lavorazione_sel == null ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!--<p
          style="
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 30px;
            margin-top: 30px;
          "
        >
          Sono elencati di seguito i Work Orders disponibili:
        </p>-->
        <v-data-table
          fixed-header
          :headers="headers_consuntivo"
          height="65vh"
          :items="items_wo_visualizzati"
          :item-class="evidenziaRitardo"
          class="elevation-1 mt-5"
        >
          <!-- Pulsante per la registrazione dei pezzi -->
          <template v-slot:[`item.avvia_work_order`]="{ item }">
            <div style="text-align: center">
              <v-btn
                class="ma-5"
                @click="clickAvviaWO(item)"
                color="success"
                dark
                large
              >
                AVVIA WORK ORDER
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>

    <!-- Dialog di conferma attivazione -->
    <v-dialog persistent v-model="conferma_dialog" width="unset">
      <v-card>
        <v-toolbar class="white--text" color="indigo" flat>
          <v-toolbar-title>Conferma attivazione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5" style="font-size: 20px">
          Vuoi avviare il work order n.
          <b>{{
            work_order_sel == null
              ? ""
              : work_order_sel.cod_commessa + "." + work_order_sel.cod_fase
          }}</b
          >?
        </v-card-text>
        <v-divider></v-divider>
        <v-btn
          class="mx-2 my-2"
          @click="
            (conferma_dialog = !conferma_dialog),
              (work_order_sel = Object.assign({}))
          "
          color="error"
          dark
          style="font-size: 20px"
        >
          ANNULLA
        </v-btn>
        <v-btn
          class="mx-2 my-2"
          @click="addToCarrello(work_order_sel)"
          color="success"
          dark
          style="font-size: 20px"
        >
          CONFERMA
        </v-btn>
      </v-card>
      <v-overlay
        color="rgba(0, 0, 0, 1)"
        style="position: absolute"
        :value="caricamento_dati"
      >
        <v-icon class="ruota" size="100">mdi-cog</v-icon>
        <v-icon class="controruota" size="100">mdi-cogs</v-icon>
      </v-overlay>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbar_text }}
      <v-btn
        @click="snackbar = false"
        class="ml-5"
        :color="snackbar_color"
        fab
        outlined
        x-small
        style="background-color: #ffffff"
      >
        <v-icon>{{ snackbar_icon }}</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  name: "ConsuntivoWorkOrder",
  props: {
    filtro_cod_macchina_visible: {
      type: Boolean,
      default: true,
    },
    items_consuntivo: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo_sel: "",
      cod_commessa_sel: "",
      cod_macchina_sel: null,
      lavorazione_sel: null,
      work_order_sel: null,

      // Modal
      caricamento_dati: false,
      conferma_dialog: false,
      snackbar: false,
      snackbar_color: "",
      snackbar_icon: "",
      snackbar_text: "",

      // Array
      headers_consuntivo: [
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
          class: "white--text indigo",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
          class: "white--text indigo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
          class: "white--text indigo",
        },
        {
          text: "Cod. Macchina",
          sortable: false,
          value: "cod_macchina",
          class: "white--text indigo",
        },
        {
          text: "Lavorazione",
          sortable: false,
          value: "des",
          class: "white--text indigo",
        },
        {
          text: "Qta Totale",
          sortable: false,
          value: "quantita",
          class: "white--text indigo",
        },
        {
          text: "Qta Prodotta",
          sortable: false,
          value: "pezzi_registrati",
          class: "white--text indigo",
        },
        {
          text: "",
          sortable: false,
          value: "avvia_work_order",
          class: "white--text indigo",
        },
      ],
      items_wo_visualizzati: [],
    };
  },
  methods: {
    addToCarrello(arg) {
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersutente",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: arg.id_commessa_dettaglio,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Compongo un messaggio con il contenuto del row cliccato.
          let messaggio = {
            detail: {
              work_order_sel: res.data.workorders[0],
            },
            src: null,
          };

          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("clicked_row", messaggio);

          /* Consegno alla mia destinazione l'evento con il
           * contenuto del row che ho cliccato. */
          document.getElementById("listener_Produzione2").dispatchEvent(evento);
          
          /* Segnalo al padre che è stato scelto un work order,
           * così lui può chiudermi se vuole. */
          this.$emit("inviato_elemento");
        }

        // Chiudo il dialog di conferma
        this.conferma_dialog = !this.conferma_dialog;

        // Chiudo l'overlay di caricamento
        this.caricamento_dati = false;
      });
    },
    clickAvviaWO(item) {
      this.work_order_sel = Object.assign({}, item);
      this.conferma_dialog = true;
    },
    clickChiudi() {
      this.$emit("click_chiudi");

      // Resetto i filtri
      this.cod_articolo_sel = "";
      this.cod_commessa_sel = "";
      this.cod_macchina_sel = null;
      this.lavorazione_sel = null;
    },
    evidenziaRitardo(item) {
      return item.cancellato == 1 ? "evidenziato" : "";
    },
    filtra() {
      let items_wo_tmp = [];
      this.items_consuntivo.forEach((elem) => {
        items_wo_tmp.push(elem);
      });

      // Filtro il codice articolo
      if (this.cod_articolo_sel != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_wo_tmp.forEach((elem) => {
          if (
            elem.cod_articolo
              .toUpperCase()
              .includes(this.cod_articolo_sel.toUpperCase())
          ) {
            res.push(elem);
          }
        });

        // Modello la risposta provvisoria su "res".
        items_wo_tmp = res;
      }

      // Filtro il codice commessa
      if (this.cod_commessa_sel != "") {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_wo_tmp.forEach((elem) => {
          if (elem.cod_commessa.includes(this.cod_commessa_sel)) {
            res.push(elem);
          }
        });

        items_wo_tmp = res;
      }

      // Filtro il codice macchina
      if (this.cod_macchina_sel != null) {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_wo_tmp.forEach((elem) => {
          if (elem.cod_macchina == this.cod_macchina_sel) {
            res.push(elem);
          }
        });

        items_wo_tmp = res;
      }

      // Filtro la lavorazione
      if (this.lavorazione_sel != null) {
        // Faccio un array temporaneo
        let res = [];

        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_wo_tmp.forEach((elem) => {
          if (elem.des == this.lavorazione_sel) {
            res.push(elem);
          }
        });

        items_wo_tmp = res;
      }

      this.items_wo_visualizzati = [];
      this.items_wo_visualizzati = items_wo_tmp;
      this.items_wo_visualizzati.sort(
        (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
      );
    },
  },
  watch: {
    items_consuntivo: {
      immediate: true,
      handler() {
        /* Se la lista dei WO dovesse cambiare per qualche motivo,
         * facci in modo di avere gli aggiornamenti. */
        this.items_wo_visualizzati = this.items_consuntivo;
        //this.items_wo_visualizzati.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        // Filtro la lista aggiornata, nel caso l'utente avesse disposto dei filtri
        this.filtra();
      },
    },
  },
  mounted() {},
};
</script>
<style scoped>
@keyframes controtazione {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotazione {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.controruota {
  animation: controtazione 1s infinite;
  animation-timing-function: linear;
}
.ruota {
  animation: rotazione 1s infinite;
  animation-timing-function: linear;
}
th,
td {
  border-right: 1px solid grey;
}
.evidenziato {
  background-color: pink;
}
</style>