var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"content-class":"artDialog","scrollable":false,"width":"700"},model:{value:(_vm.dialoginfo),callback:function ($$v) {_vm.dialoginfo=$$v},expression:"dialoginfo"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Dettaglio delle azioni per questa pagina")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n5 mr-n6",attrs:{"depressed":"","color":"error"},on:{"click":function($event){_vm.dialoginfo = false;
                _vm.closeModalInfo();}}},'v-icon',attrs,false),on),[_vm._v("mdi-window-close")])]}}])},[_c('span',[_vm._v("Cancellare ")])])],1),_c('v-card-text',[_c('v-list',{attrs:{"disabled":""}},[_c('v-subheader',[_vm._v("Azioni")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},_vm._l((_vm.actionItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-align":"left !important"},domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"1500"},model:{value:(_vm.dialogArticoloDettaglio),callback:function ($$v) {_vm.dialogArticoloDettaglio=$$v},expression:"dialogArticoloDettaglio"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Articolo Dettaglio")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.closedetailModal();
                _vm.dialogArticoloDettaglio = false;}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","depressed":""}},'v-icon',attrs,false),on),[_vm._v("mdi-window-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-6",staticStyle:{"max-height":"600px","overflow-y":"auto","overflow-x":"hidden"}},[_c('Articolo',{key:this.recursiveId,attrs:{"articoloId":this.recursiveId,"name":this.recursiveId}})],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"mt-n4 white--text"},[_vm._v("Distinta Base")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-n4 mb-3"},[_c('v-col',{staticClass:"text-left",attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"saveIcon",attrs:{"small":""},on:{"click":_vm.saveDistinta}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v(" salva distinta ")])])],1),_c('v-col',{attrs:{"md":"4"}}),_c('v-spacer'),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":""},on:{"click":_vm.hideModal}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#1a80b6","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-replay")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1)],1),_c('v-row'),_c('v-form',{staticClass:"mt-n9 ml-n1",on:{"submit":function($event){$event.preventDefault();return _vm.searchDistintaBase.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"disabled":this.disablesearch === true,"type":"text","label":"Ricerca Componente","placeholder":"Ricerca Componente","required":""},model:{value:(_vm.cod_articolo),callback:function ($$v) {_vm.cod_articolo=$$v},expression:"cod_articolo"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-5",attrs:{"small":""},on:{"click":_vm.searchDistintaBase}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#1a80b6","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca ")])])],1)],1)],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.LoadingVal,"headers":_vm.headers,"items":_vm.items,"dense":true},scopedSlots:_vm._u([{key:"item.quantita",fn:function(props){return [_c('v-text-field',{staticClass:"shrink shrinkinput",attrs:{"solo":"","flat":"","min":"0","dense":"","type":"number","name":"quantita"},model:{value:(props.item.quantita),callback:function ($$v) {_vm.$set(props.item, "quantita", $$v)},expression:"props.item.quantita"}})]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar),expression:"progressBar"}],attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"700"},model:{value:(_vm.dialogDistintaBase),callback:function ($$v) {_vm.dialogDistintaBase=$$v},expression:"dialogDistintaBase"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1",attrs:{"primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Distinta Base")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-n4 mb-3"},[_c('v-col',{staticClass:"text-left",attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"saveIcon",attrs:{"small":""},on:{"click":_vm.updateDistinta}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v(" salva distinta ")])])],1),_c('v-col',{attrs:{"md":"4"}}),_c('v-spacer'),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":""},on:{"click":_vm.closedialogDistintaBase}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#1a80b6","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-replay")])],1)]}}])},[_c('span',[_vm._v("Undo")])])],1)],1),_c('v-row'),_c('v-form',{staticClass:"mt-n9 ml-n1",on:{"submit":function($event){$event.preventDefault();return _vm.searchDistintaBase.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"disabled":this.disablesearch === true,"type":"text","label":"Ricerca Componente","placeholder":"Ricerca Componente","required":""},model:{value:(_vm.cod_articolo),callback:function ($$v) {_vm.cod_articolo=$$v},expression:"cod_articolo"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-5",attrs:{"small":""},on:{"click":_vm.searchDistintaBase}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#1a80b6","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca ")])])],1)],1)],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.LoadingVal,"headers":_vm.headers,"items":_vm.items,"dense":true},scopedSlots:_vm._u([{key:"item.quantita",fn:function(props){return [_c('v-text-field',{staticClass:"shrink shrinkinput",attrs:{"solo":"","flat":"","min":"0","dense":"","type":"number","name":"quantita"},model:{value:(props.item.quantita),callback:function ($$v) {_vm.$set(props.item, "quantita", $$v)},expression:"props.item.quantita"}})]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar),expression:"progressBar"}],attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"400"},model:{value:(_vm.dialogEliminaDistintaBase),callback:function ($$v) {_vm.dialogEliminaDistintaBase=$$v},expression:"dialogEliminaDistintaBase"}},[_c('v-card',[_c('v-toolbar',{staticClass:"headline indigo darken-1",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("elimina la distinta base")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"1"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-exclamation-thick")])],1),_c('v-col',[_c('p',[_vm._v(" Attenzione. Confermi la rimozione del componenti: N': "+_vm._s(this.deletingQuantity)+"-"+_vm._s(this.deletingItemDetail)+" ")])]),_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"md":"5"}}),_c('v-col',{attrs:{"md":"3"}},[_c('v-btn',{staticClass:"mx-auto",attrs:{"dark":"","tile":"","color":"green"},on:{"click":function($event){_vm.deleteItem();
                _vm.dialogEliminaDistintaBase = false;}}},[_vm._v("Invia")])],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"dark":"","tile":"","color":"red"},on:{"click":function($event){_vm.dialogEliminaDistintaBase = false}}},[_vm._v("Anulla")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 24 : 2}},[_c('v-snackbar',{attrs:{"vertical":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message_snackbar)+" "),_c('v-btn',{attrs:{"color":"indigo","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('v-toolbar',{staticClass:"indigo darken-1 rounded-t",attrs:{"primary-title":"","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Articolo presente in distinte base")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","small":""},on:{"click":function($event){_vm.dialoginfo = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio delle azioni")])]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"text-left"},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar1),expression:"progressBar1"}],attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('v-treeview',{attrs:{"activatable":"","shaped":"","hoverable":"","open":_vm.open,"items":_vm.distintaBaseList,"item-key":"id","content":"6","open-on-click":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
                var item = ref.item;
                var open = ref.open;
return [(item.id == 0)?_c('v-icon',[_vm._v(_vm._s(open ? "mdi-buffer" : "mdi-buffer"))]):_vm._e(),(item.id > 0)?_c('v-icon',[_vm._v(_vm._s(open ? "mdi-text-short" : "mdi-text-short"))]):_vm._e(),(item.id >= -1)?[_vm._v("N° "+_vm._s(item.quantita)+" "+_vm._s(item.cod_articolo)+" ")]:_vm._e()]}},{key:"append",fn:function(ref){
                var item = ref.item;
return (_vm.hideicons === true)?[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(item.id_rif == -1)?_c('v-btn',_vm._g({attrs:{"small":""},on:{"click":_vm.showModal}},on),[_c('v-icon',{attrs:{"color":"#1a80b6"}},[_vm._v("mdi-plus")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Inserisci Componente")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.getDetail(item)}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio Articolo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(item.id_rif == 0)?_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){return _vm.updateItem(item)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica Componente")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.id_rif == 0),expression:"item.id_rif == 0"}],staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){return _vm.deleteFolder(item)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-window-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancella Componente")])])]:undefined}}],null,true)})],1),_c('v-divider')],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }