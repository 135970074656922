<template>
  <div class="input_calendario">
    <v-text-field
      :label="label"
      v-bind:value="parse(value, 'it')"
      prepend-icon="mdi-calendar-blank"
      readonly
      @click="mostraCalendario"
      :required="required != undefined ? true : false"
      :rules="rules.length > 0 ? rules : []"
    ></v-text-field>
    <div class="contenitore_calendario" v-show="espandi">
      <v-card>
        <v-date-picker
          v-bind:value="parse(value, 'en')"
          @input="emit"
        ></v-date-picker>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputCalendario",
  props: {
    value: String,
    label: String,
    required: [String, Number, Boolean],
    rules: [Array, Boolean],
  },
  data() {
    return {
      espandi: false,
    };
  },
  methods: {
    // Normalizzazione data in input
    parse(value, format = "en") {
      if (value == null || value == undefined) {
        return null;
      }
      if (value.indexOf("/") > 0) {
        let split = value.split("/");
        if (
          split[0].length == 2 &&
          split[1].length == 2 &&
          split[2].length == 4
        ) {
          if (format == "it") {
            return value;
          } else {
            return split[2] + "-" + split[1] + "-" + split[0];
          }
        } else {
          return null;
        }
      } else if (value.indexOf("-") > 0) {
        let split = value.split("-");
        if (
          split[0].length == 4 &&
          split[1].length == 2 &&
          split[2].length == 2
        ) {
          if (format == "it") {
            return split[2] + "/" + split[1] + "/" + split[0];
          } else {
            return value;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    emit(data) {
      this.$emit("input", data);
      this.espandi = false;
    },
    mostraCalendario() {
      this.espandi = true;
      document.body.addEventListener("mousedown", this.nascondiCalendario);
    },
    nascondiCalendario(event) {
      let path = event.composedPath();
      for (let i = 0; i < path.length; i++) {
        if (path[i].getAttribute("class") == "contenitore_calendario") {
          return;
        }
        if (path[i].tagName.toLowerCase() == "body") {
          break;
        }
      }
      this.espandi = false;
      document.body.removeEventListener("mousedown", this.nascondiCalendario);
    },
  },
};
</script>
<style scoped>
.input_calendario {
  position: relative;
}
.input_calendario > .contenitore_calendario {
  position: absolute;
  top: calc(100% - 12px);
  left: 0;
  z-index: 2;
}
</style>