<template>
  <v-card>
    <v-toolbar color="indigo" dense fixed>
      <v-toolbar-title class="white--text">Modifica Domanda</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Ordine -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaModifica" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Domanda</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialogDomanda" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-2 pa-0">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="4">
            <v-text-field
              label="Codice"
              v-model="domanda_sel_tmp.cod_quesito"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Descrizione"
              v-model="domanda_sel_tmp.des_quesito"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              background-color="#ffffff"
              @change="
                items_tipi.forEach((e) => {
                  if (e.id_tabella === domanda_sel_tmp.tipo_quesito) {
                    domanda_sel_tmp.des_tipo = e.des;
                  }
                })
              "
              dense
              hide-details
              :items="items_tipi"
              item-text="des"
              item-value="id_tabella"
              label="Tipo Domanda"
              style="display: inline-block; max-width: 350px"
              v-model="domanda_sel_tmp.tipo_quesito"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>

      <Controllo
        :clear_on_save="false"
        :immagine_standard_editor="
          domanda_sel_tmp.img != null ? domanda_sel_tmp.img : ''
        "
        :show_close_button="false"
        :show_editor_save_button="false"
        :tabella_supporto="''"
        :titolo_editor="`Editor d'Immagine`"
        v-if="
          domanda_sel_tmp.tipo_quesito === id_tipo_domanda_immagine &&
          id_tipo_domanda_immagine != null
        "
        :visible="true"
      />
    </v-card-text>
    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import _ from "lodash";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import Domanda from "@/classes/EMDomanda.js";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ModificaDomanda",
  components: { Controllo, SnackbarQV },
  props: {
    domanda_sel: { type: Object, default: () => new Domanda() },
    visible: { type: Boolean, default: true },
  },
  data() {
    return {
      // Variabili d'appoggio
      domanda_sel_tmp: new Domanda(),
      id_tipo_domanda_argomentativa: null,
      id_tipo_domanda_si_no: null,
      id_tipo_domanda_immagine: null,

      // Modal bool

      // Array
      items_tipi: new Array(),
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.domanda_sel_tmp = new Domanda(this.domanda_sel);
        }
      },
    },
  },
  methods: {
    chiudiDialogDomanda() {
      // Se avevo aperto l'editor di immagini, lo pulisco
      if (this.$refs.Visualizzatore) {
        this.$refs.Visualizzatore.clickPulisciEditor();
      }

      /* Emetto l'evento per lanciare nel componente padre
       * la procedura di gestione della chiusura del dialog. */
      this.$emit("click_chiudi");
    },
    gettabelle() {
      /* Questa gettabelle mi serve sostanzialmente per
       * essere in grado di riconoscere il tipo di domanda
       * con cui sto lavorando, in modo da configurare debitamente
       * i contenuti dei dialog. */
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "EM_TIPO_DOMANDA",
          filter: "validita = 1",
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            // Popolo l'array dei tipi di domanda
            this.items_tipi = Array.from(res.data);

            // Registro gli identificativi dei tipi di domande
            res.data.forEach((e) => {
              if (e.cod === "POLARE") {
                this.id_tipo_domanda_si_no = e.id_tabella;
              } else if (e.cod === "K") {
                this.id_tipo_domanda_argomentativa = e.id_tabella;
              } else if (e.cod === "IMG") {
                this.id_tipo_domanda_immagine = e.id_tabella;
              }
            });
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    handleNuovaImg(arg) {
      this.domanda_sel_tmp.img = _.cloneDeep(arg.detail.img);
      this.$refs.SnackbarQV.print(
        "success",
        `Immagine associata con successo!`
      );
    },
    salvaModifica() {
      // Se la domanda è di tipo immagine...
      if (
        this.domanda_sel_tmp.tipo_quesito === this.id_tipo_domanda_immagine &&
        this.id_tipo_domanda_immagine != null
      ) {
        // Pesco l'immagine dal componente figlio
        this.domanda_sel_tmp.img =
          "data:image/svg+xml; charset=utf8, " +
          encodeURIComponent(document.getElementById("svg").outerHTML) +
          ` #svgView(viewBox(0, 0, ${
            document.getElementById("svg").style.width
          }, ${document.getElementById("svg").style.height}))`;
      }

      // Emetto la domanda modificata nel padre, che la salverà.
      const domanda_save = _.cloneDeep(this.domanda_sel_tmp);
      this.$emit("salva_domanda", domanda_save);
    },
  },
  created() {},
  mounted() {
    // Eseguo la gettabelle per riconoscere il tipo di domanda
    this.gettabelle();
  },
};
</script>
<style scoped>
</style> 
