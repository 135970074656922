<template>
   <div class="fix_container" style="padding:10px">      
    <v-card class="pa-3">
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>

        <v-toolbar 
            class="white--text"
            color="indigo darken-1"
            dense
            >
            <v-toolbar-title> {{VOCE_MENU}} </v-toolbar-title>
            
            <v-dialog
            v-model="dialog">
            <template>
            <v-card>
            <v-card-title>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            
                <v-data-table
                    :headers="headers_articoli"
                    :items="itemsArticoli"
                    :search="search"
                    :items-per-page="10"
                    sort-by="id_articolo"
                    class="elevation-1"
                >
            
                <template v-slot:top>
                    <v-toolbar
                        flat
                    >
                        <v-toolbar-title>Articoli</v-toolbar-title>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>          
                    <v-icon 
                        small
                    >
                        mdi-delete
                    </v-icon>
                </template>

                <template  v-slot:[`item.confirm`]="{ item }" >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined small v-bind="attrs" v-on="on" @click="aggiungiDettaglio(item)">
                                <v-icon color="purple"
                                >mdi-check-decagram-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Conferma Articolo </span>
                    </v-tooltip>
                </template>

                </v-data-table>
            </v-card>
            </template>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-btn
                class="red--text"
                @click="dialog_timbrature = false"
                color="blue-grey lighten-5"
                small
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="4" sm="4" md="4">

                </v-col>

                <v-col cols="12" sm="12" md="12">
                    <template>
                        <v-data-table
                            :headers="headers_documento"
                            :items="documento"
                            hide-default-footer
                            :items-per-page="12000"
                            class="elevation-1"
                                                >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-app-bar-nav-icon></v-app-bar-nav-icon>
                                    <v-toolbar-title>Documento</v-toolbar-title>
                                    <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                    ></v-divider>
                                    <v-spacer></v-spacer>
                                      
                                    <v-spacer></v-spacer>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn outlined small v-bind="attrs" v-on="on" @click="nuova_riga_documento">
                                                    <v-icon color="blue"
                                                    >mdi mdi-file-plus-outline
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Nuova Istruzione di Lavoro</span>
                                        </v-tooltip>
                                </v-toolbar>
                            </template>


                            <template  v-slot:[`item.cod_articolo`]="{ item }" >                    
                                <v-select
                                :items="articoli_documento"
                                item-text='cod_articolo'
                                item-value='id_articolo'
                                label="Codice Articolo"
                                v-model="item.id_articolo"
                                @change="cambiaCodArticolo(item)"
                                ></v-select>      
                            </template>                            
                            <template  v-slot:[`item.cod_articolo_cli`]="{ item }" > 
                                <v-text-field
                                    v-model="item.cod_articolo_cli"
                                ></v-text-field>        
                            </template>

                            <template  v-slot:[`item.des`]="{ item }" > 
                                <v-text-field
                                    v-model="item.des"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.um`]="{ item }" > 
                                <v-text-field
                                    v-model="item.um"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.qta`]="{ item }" > 
                                <v-text-field
                                    v-model="item.qta"
                                    @change="calcola(item)"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.qta_res`]="{ item }" > 
                                <v-text-field
                                    v-model="item.qta_res"
                                ></v-text-field>        
                            </template> 
                            <template  v-slot:[`item.prezzo_netto`]="{ item }" > 
                                <v-text-field
                                    v-model="item.prezzo_netto"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.sconto`]="{ item }" > 
                                <v-text-field
                                    v-model="item.sconto"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.imp_tot`]="{ item }" > 
                                <v-text-field
                                    v-model="item.imp_tot"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.iva`]="{ item }" > 
                                <v-text-field
                                    v-model="item.iva"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.iva_tot`]="{ item }" > 
                                <v-text-field
                                    v-model="item.iva_tot"
                                ></v-text-field>        
                            </template>   
                            <template  v-slot:[`item.data_cons`]="{ item }" > 
                                <v-text-field
                                    v-model="item.data_cons"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.data_conf`]="{ item }" > 
                                <v-text-field
                                    v-model="item.data_conf"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.stato`]="{ item }" > 
                                <v-text-field
                                    v-model="item.stato"
                                ></v-text-field>        
                            </template>
                            <template  v-slot:[`item.prezzo_netto_listino`]="{ item }" > 
                                <v-text-field
                                    v-model="item.prezzo_netto_listino"
                                ></v-text-field>        
                            </template>                                                                                    




                            <template  v-slot:[`item.articolo`]="{ item }" >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="openDialog(item)">
                                            <v-icon color="purple"
                                            >mdi-folder-search-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Apri articoli </span>
                                </v-tooltip>
                            </template>
 
                            <template  v-slot:[`item.delete`]="{ item }" >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                            <v-icon color="purple"
                                            >mdi-delete-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Elimina Associzione </span>
                                </v-tooltip>
                            </template>      

                            <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                    @click="nuova_riga_documento"
                                >
                                    Nuovo
                                </v-btn>
                            </template>                                                                                                  

                        </v-data-table>
                    </template>       
                </v-col>
                    
            </v-row>


        </v-card-text>
 

    </v-card>        
        
    </div>

</template>

<style>
    .fix_container{ --documento : #2b3131; }

    .fix_container *{
        scrollbar-color: var(--documento) transparent!important;
        scrollbar-width: thin!important;
    }

    .fix_container *::-webkit-scrollbar{
        width:6px!important;
        height:6px!important;
        overflow:overlay!important;
        position: relative!important;          
    }

    .fix_container *::-webkit-scrollbar-track{ background: transparent!important ; }
    .fix_container *::-webkit-scrollbar-corner{ background: transparent!important ; }
    .fix_container *::-webkit-scrollbar-thumb{ background: var(--documento)!important; }
</style>

<script>
import axios from "axios";
    export default {
        props: {
            ID_DOCUMENTO:Number,
            VOCE_MENU:String,
        },
      
        data() {
            return {
                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",
                dialog:false,
                overlay: false,

                articoli:[],
                des_articolo_selected : "",
                id_articolo_selected: null,

                headers_documento: [
                    { text: 'Cod Articolo', value: 'cod_articolo', sortable: false },
                    { text: '', value: 'articolo',  sortable: false },
                    { text: 'Cod Articolo Cliente', value: 'cod_articolo_cli', sortable: false },
                    { text: 'Descrizione', value: 'descrizione' , sortable: false, width: "8%" },
                    { text: 'U.M.', value: 'um' , sortable: false },
                    { text: 'Q.ta', value: 'quantita' , sortable: false },
                    { text: 'Q.ta Res.', value: 'qta_res' , sortable: false },
                    { text: 'Prezzo Netto', value: 'prezzo_netto' , sortable: false },
                    { text: 'Sconto', value: 'sconto' , sortable: false },
                    { text: 'Prezzo Totale', value: 'prezzo_totale' , sortable: false },
                    { text: 'Iva', value: 'iva' , sortable: false },
                    { text: 'Tot. Iva', value: 'iva_tot' , sortable: false },
                    { text: 'Data Consegna', value: 'data_cons' , sortable: false },
                    { text: 'Data Conferma', value: 'data_conf' , sortable: false },
                    { text: 'Stato Riga', value: 'stato' , sortable: false },
                    { text: 'Prezzo Netto Listino', value: 'prezzo_netto_listino' , sortable: false },
                    { text: '', value: 'delete',  sortable: false },
               
                ],
                headers_articoli: [
                    { text: '', value: 'confirm',  sortable: false },
                    { text: 'Id Articolo', value: 'id_articolo', sortable: false },
                    { text: 'Codice Articolo', value: 'cod_articolo',  sortable: false },
                    { text: 'Descrizione', value: 'des_articolo', sortable: false },
                    
               
                ],

                documento:[],                
                articoli_documento:[],
                itemsArticoli:[],
                search: '',
                totali:0,


            };
        },  
        watch: {
           
           ID_DOCUMENTO:{
               handler(){
               this.getDettagliDocumento();
               console.log(this.ID_DOCUMENTO);
               }
           },

            
        },              
        mounted() {
            
            console.log(this.ID_DOCUMENTO);
            this.getDettagliDocumento();
            
        },

        methods: {

            openDialog (item) {
                this.id_articolo = item.id_documento;
                this.dialog = true;
                this.getArticoliDocumento();
            },

            getDettagliDocumento: function() {
        const b = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var data = JSON.stringify(
          (data = {
            action: "getdocumentodettaglio",
            token: localStorage.user_token,
            id_documento: this.ID_DOCUMENTO,

          })
        );
      axios
         .post("https://localhost:5001/api/Produzione", data, b)
        .then(response => {
          this.documento = response.data.documento_dettaglio;
          console.log(this.documento);
         
        })
        .catch(e => {
          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }
         
        });
    },

     getArticoliDocumento: function() {
         debugger
        const b = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var data = JSON.stringify(
          (data = {
            action: "getarticolidocumento",
            token: localStorage.user_token,

          })
        );
      axios
         .post("https://localhost:5001/api/Produzione", data, b)
        .then(response => {
          this.itemsArticoli = response.data.articoli;
          console.log(this.itemsArticoli);
         
        })
        .catch(e => {
          if (e.response.status == 401) {
            localStorage.removeItem("jwt");
            this.$router.replace("login");
          }
         
        });
    },

            

            nuova_riga_documento(){
                this.documento.push ({  
                    cod_articolo: '',
                    cod_articolo_cli: '',
                    des: '',
                    um: '',          
                    qta: '',         
                    qta_res: '',     
                    prezzo_netto: '',
                    sconto: '',
                    imp_tot: '',
                    iva: '',
                    iva_tot: '',
                    data_cons: '',
                    data_conf: '',
                    stato: '',
                    prezzo_netto_listino: '',
                });
                console.log (this.documento)
            },  
            deleteItem(riga) {
                console.log(riga)
                let index = this.documento.findIndex(item => item.id === riga.id)
                this.documento.splice(index, 1)

                this.aggiornaTotStorage()
            },             

            cambiaCodArticolo(riga)  {

                let riga_articoli_documento = this.alasql(
                    "SELECT * FROM ? WHERE id_articolo = " + riga.id_articolo ,
                    [this.articoli_documento]
                ); 
                    
                let index = this.documento.findIndex(item => item.id === riga.id)
                this.documento.splice(index, 1) 
                
                riga_articoli_documento.forEach((elem) => {
                this.documento.push ( Object.assign({}, elem))
                });
                

            },   
            
            calcola(riga){

                let _imp_tot = riga.prezzo_netto * riga.qta
                let _iva_tot = _imp_tot * riga.iva / 100
                let index = this.documento.findIndex(item => item.id === riga.id)
                //console.log(_imp_tot)
                //console.log("index", index)
                this.documento[index].imp_tot= _imp_tot
                this.documento[index].iva_tot= _iva_tot
                //console.log(_imp_tot)
                this.aggiornaTotStorage()

            },



        }

    }
</script>