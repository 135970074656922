

export default class RisorsaTask {
    constructor(arg, id_task) {
        if (arg != undefined) {
            this.id_task = arg.id_task != undefined ? arg.id_task : -1;
            this.id_risorsa = arg.id_risorsa != undefined ? arg.id_risorsa : -1;
            this.id_personale = arg.id_personale != undefined ? arg.id_personale : -1;
            this.nome = arg.nome != undefined ? arg.nome : "";
            this.id_temp = (arg.id_task != undefined ? arg.id_task : (id_task != undefined ? id_task : -1));
            this.id_temp_padre = arg.id_temp != undefined ? arg.id_temp_padre :"";
            this.cognome = arg.cognome != undefined ? arg.cognome : "";
            this.nominativo = arg.nominativo != undefined ? arg.nominativo : "";
            this.data_inizio_lavoro = arg.data_inizio_lavoro != undefined ? arg.data_inizio_lavoro : "";
            this.data_fine_lavoro = arg.data_fine_lavoro != undefined ? arg.data_fine_lavoro : "";
            this.ore_lavoro = arg.ore_lavoro != undefined ? arg.ore_lavoro : "";
            this.percentuale_unita = arg.percentuale_unita != undefined ? arg.percentuale_unita : 0;
            this.validita = arg.validita != undefined ? arg.validita : 1;
        } else {
            this.id_task = -1;
            this.id_risorsa = -1;
            this.id_personale = -1;
            this.nome = "";
            this.cognome = "";
            this.nominativo = "";
            this.id_temp =-1;
            this.id_temp = -1;
            this.data_inizio_lavoro = "";
            this.data_fine_lavoro = "";
            this.ore_lavoro = "";
            this.percentuale_unita = 0;
            this.validita = 1;
        }
    }
}