var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Anagrafica Barche")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickNuovaBarca}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Barca")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_barche,"items":_vm.items_barche_visualizzate,"item-key":"nome","height":"75vh"},scopedSlots:_vm._u([{key:"item.modifica",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaBarca(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica Richiesta")])])]}},{key:"item.data_acquisto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_acquisto === '' ? '' : ((item.data_acquisto.substring( 8, 10 )) + "/" + (item.data_acquisto.substring( 5, 7 )) + "/" + (item.data_acquisto.substring(0, 4))))+" ")]}},{key:"item.inizio_garanzia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inizio_garanzia === '' ? '' : ((item.inizio_garanzia.substring( 8, 10 )) + "/" + (item.inizio_garanzia.substring( 5, 7 )) + "/" + (item.inizio_garanzia.substring(0, 4))))+" ")]}},{key:"item.fine_garanzia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fine_garanzia === '' ? '' : ((item.fine_garanzia.substring( 8, 10 )) + "/" + (item.fine_garanzia.substring( 5, 7 )) + "/" + (item.fine_garanzia.substring(0, 4))))+" ")]}},{key:"item.elimina",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickElimina(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1)],_c('v-dialog',{model:{value:(_vm.modifica_barche),callback:function ($$v) {_vm.modifica_barche=$$v},expression:"modifica_barche"}},[_c('ModificaBarca',{attrs:{"barca_sel":_vm.barca_sel,"id_filtro_nave":_vm.id_filtro_nave,"visible":_vm.modifica_barche},on:{"chiudi_dialog":_vm.chiudiDialog,"salvata_macchina":_vm.handleSalvataMacchina}})],1),_c('SnackbarQV',{ref:"SnackbarQV"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }