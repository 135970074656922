<template>
  <v-container fluid class="py-0">
    <form>
      <v-row>
        <v-col>
          <v-hover>
            <template v-slot="{ hover }">
              <v-card :elevation="hover ? 24 : 2">
                <v-toolbar
                  dense
                  class="indigo darken-1 headline"
                  primary-title
                  uppercase
                  ><v-toolbar-title class="white--text">Costo</v-toolbar-title>
                </v-toolbar>
                <v-row>
                  <v-col md="12" class="ml-2">
                    <v-icon class="ml-5">mdi-file-plus-outline</v-icon>
                    <v-icon class="ml-5">mdi-file-document-edit-outline</v-icon>
                    <v-icon class="ml-5">mdi-file-remove</v-icon>
                  </v-col>
                  <v-col class="ml-5" md="4">
                    <v-text-field
                      type="text"
                      class="input-class"
                      v-model="prefisso_lotto"
                      label="Soggetto"
                      placeholder="Soggetto"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col md="5">
                    <v-text-field
                      type="text"
                      class="input-class"
                      v-model="prefisso_lotto"
                      label="Ricerca"
                      placeholder="Ricerca"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-icon style="margin-top: -1.5rem">mdi-magnify</v-icon>
                </v-row>

                <v-row>
                  <v-col md="12">
                    <v-data-table :headers="headers"></v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col>
          <v-hover>
            <template v-slot="{ hover }">
              <v-card :elevation="hover ? 24 : 2">
                <v-toolbar dense class="indigo darken-1 headline" primary-title>
                  <v-toolbar-title class="white--text"
                    >Prezzo/Costo</v-toolbar-title
                  >
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        type="number"
                        class="input-class"
                        v-model="costo_industriale"
                        label="Costo Industriale"
                        placeholder="Costo Industriale"
                        append-outer-icon="mdi-currency-eur"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <v-text-field
                        type="number"
                        class="input-class"
                        v-model="prezzo_base"
                        label="Prezzo Base"
                        placeholder="Prezzo Base"
                        append-outer-icon="mdi-currency-eur"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col md="4" class="py-0">
                      <v-text-field
                        type="number"
                        class="input-class"
                        v-model="costo_imballo"
                        label="Costo Imballo"
                        placeholder="Costo Imballo"
                        append-outer-icon="mdi-currency-eur"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4" class="py-0">
                      <v-text-field
                        type="number"
                        class="input-class"
                        v-model="costo_trasporto"
                        label="Costo Trasporto"
                        placeholder="Costo Trasporto"
                        append-outer-icon="mdi-currency-eur"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      prefisso_lotto: "",
      costo_industriale: null,
      prezzo_base: null,
      costo_imballo: null,
      costo_trasporto: null,
      headers: [
        {
          text: "",
          value: "",
          align: "start",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des",
          sortable: false,
        },
        {
          text: "Codice",
          value: "codice",
          sortable: false,
        },
        {
          text: "Ragione Sociale",
          value: "Ragione Sociale",
          sortable: false,
        },
        {
          text: "Destinazione",
          value: "destinazione",
          sortable: false,
        },
        {
          text: "Fase",
          value: "fase",
          sortable: false,
        },
        {
          text: "Q.ta DA",
          value: "da_qty",
          sortable: false,
        },
        {
          text: "Q.ta A",
          value: "a_qty",
          sortable: false,
        },
        {
          text: "Prez. Unitario",
          value: "prez_uni",
          sortable: false,
        },
        {
          text: "Tipologia",
          value: "tipo",
          sortable: false,
        },
        {
          text: "Validata Da",
          value: "validata_da",
          sortable: false,
        },
        {
          text: "Validata A",
          value: "validata_a",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style css scoped></style>
