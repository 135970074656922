//import moment from "moment/moment";
export default class Zona {
    constructor(arg = undefined) {
      if (arg != undefined) {
        const campi = this.setControlli();
        campi.forEach((c) => {
          this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
        });
      } else {
        const campi = this.setControlli();
        campi.forEach((c) => {
          this[c.nome] = c.default_val;
        });
      }
    }
  
    // Metodo per resettare tutte i campi
    reset() {
      const campi = this.setControlli();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  
    setControlli() {
      const campi = [
        { nome: "cod_quesito", default_val: "" },
        { nome: "des_quesito", default_val: "" },
        { nome: "esito", default_val: "" },
        { nome: "id_checklist", default_val: -1 },
        { nome: "id_checklist_dettaglio", default_val: -1 },
        { nome: "id_padre", default_val: -1 },
        { nome: "id_temp", default_val: -1 },
        { nome: "id_temp_padre", default_val: -1 },
        { nome: "img", default_val: "" },
        { nome: "note", default_val: "" },
        { nome: "num_quesito", default_val: null },
        { nome: "tipo_quesito", default_val: null },
        { nome: "validita", default_val: 1 },
        { nome: "quesiti", default_val: [] },
      ];
      return campi;
    }
  }