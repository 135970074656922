<template>
  <v-card class="mt-5">
    <v-toolbar class="white--text" color="primary" dense flat>
      <v-toolbar-title>Impostazione Permessi</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goBack" v-bind="attrs" v-on="on" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Vai alla Dashboard</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="" cols="12">
            <v-btn class="elevation-5" @click="clickFormAuth" medium>
              <span class="primary--text" style="font-size: 35px"
                >TextField Permessi</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12" class="my-1">
            <v-btn class="elevation-5" @click="clickActionAuth" medium>
              <span class="primary--text" style="font-size: 35px">Button Permessi</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.push("/home/amministrazione/");
    },
    clickFormAuth() {
      this.$emit("click_form_auth");
    },
    clickActionAuth() {
      this.$emit("click_action_auth");
    },
  },
};
</script>
