<template>
  <v-card style="border-radius: 0px; overflow-y: hidden">
    <v-toolbar dense color="indigo">
      <v-toolbar-title class="white--text">{{ titolo_editor }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="clickIndietro" small v-if="show_close_button === true">
        <v-icon color="error">mdi-keyboard-backspace</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-0 mt-5">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="2" v-if="tabella_supporto === 'controllo'">
            <TabellaControllo :id_articolo="id_articolo" />
          </v-col>
          <v-col cols="3" v-if="tabella_supporto === 'disegno'">
            <TabellaDisegni
              :destinazione="destinazione"
              :disegni_iniziali="disegni_iniziali"
              :return_array="return_array"
              :riferimento="riferimento.toString()"
              @scelta_immagine="clickIndietro"
            />
          </v-col>
          <v-col :cols="tabella_supporto === 'disegno' ? 9 : 10">
            <Visualizzatore
              ref="Visualizzatore"
              :destinazione="destinazione"
              :immagine_standard_editor="immagine_standard_editor"
              :riferimento="riferimento.toString()"
              @salvata_immagine="handleSalvataImmagine"
              :show_editor_arrow_button="show_editor_arrow_button"
              :show_editor_camera_button="show_editor_camera_button"
              :show_editor_circle_button="show_editor_circle_button"
              :show_editor_color_picker_button="show_editor_color_picker_button"
              :show_editor_crop_button="show_editor_crop_button"
              :show_editor_edit_back_button="show_editor_edit_back_button"
              :show_editor_edit_forward_button="show_editor_edit_forward_button"
              :show_editor_eraser_button="show_editor_eraser_button"
              :show_editor_free_draw_button="show_editor_free_draw_button"
              :show_editor_large_size_button="show_editor_large_size_button"
              :show_editor_letter_case_button="show_editor_letter_case_button"
              :show_editor_medium_size_button="show_editor_medium_size_button"
              :show_editor_save_button="show_editor_save_button"
              :show_editor_small_size_button="show_editor_small_size_button"
              :show_editor_square_button="show_editor_square_button"
              :show_editor_tool_columns="show_editor_tool_columns"
              :show_editor_upload_button="show_editor_upload_button"
              :visible="visible"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import TabellaControllo from "@/components/PRODUCTION/Controllo/TabellaControllo.vue";
import TabellaDisegni from "@/components/PRODUCTION/Controllo/TabellaDisegni.vue";
import Visualizzatore from "@/components/PRODUCTION/Controllo/Visualizzatore.vue";
export default {
  name: "Controllo",
  components: {
    TabellaControllo,
    TabellaDisegni,
    Visualizzatore,
  },
  props: {
    clear_on_save: { type: Boolean, default: true },
    destinazione: { type: String, default: "" },
    disegni_iniziali: { type: Array, default: () => [] },
    id_articolo: { type: Number, default: 0 },
    immagine_standard_editor: { type: String, default: "" },
    riferimento: { type: Number, default: 0 },
    return_array: { type: Boolean, default: false },
    show_close_button: { type: Boolean, default: true },
    show_editor_arrow_button: { type: Boolean, default: true },
    show_editor_camera_button: { type: Boolean, default: true },
    show_editor_circle_button: { type: Boolean, default: true },
    show_editor_color_picker_button: { type: Boolean, default: true },
    show_editor_crop_button: { type: Boolean, default: true },
    show_editor_edit_back_button: { type: Boolean, default: true },
    show_editor_edit_forward_button: { type: Boolean, default: true },
    show_editor_eraser_button: { type: Boolean, default: true },
    show_editor_free_draw_button: { type: Boolean, default: true },
    show_editor_large_size_button: { type: Boolean, default: true },
    show_editor_letter_case_button: { type: Boolean, default: true },
    show_editor_medium_size_button: { type: Boolean, default: true },
    show_editor_save_button: { type: Boolean, default: true },
    show_editor_small_size_button: { type: Boolean, default: true },
    show_editor_square_button: { type: Boolean, default: true },
    show_editor_tool_columns: { type: Boolean, default: true },
    show_editor_upload_button: { type: Boolean, default: true },
    tabella_supporto: { type: String, default: "controllo" },
    titolo_editor: { type: String, default: "Controllo" },
    visible: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  methods: {
    clickIndietro() {
      this.$emit("click_indietro");
      this.$refs.Visualizzatore.clickPulisciEditor();
    },
    handleSalvataImmagine() {
      if (this.clear_on_save === true) {
        this.clickIndietro();
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>
<style scoped>
</style>