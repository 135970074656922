<template>
  <div :style="{ width: width, height: height }">
    <!-- SVG che contiene il grafico -->
    <div
      id="wrapper_svg"
      :style="{
        backgroundColor: 'red',
        display: 'inline-block',
        height: height,
        magrin: '0px',
        padding: '0px',
        position: 'relative',
        width: width,
        zIndex: 2,
      }"
    >
      <!-- Template del nodo rettangolo con angoli smussati -->
      <div
        @mousedown="startDragging($event, nodo)"
        @mouseup="endDragging($event)"
        @mousemove="dragNode($event)"
        @contextmenu="clickDestroNodo($event, nodo)"
        :id="'div_nodo_' + nodo.id_nodo"
        :key="nodo.id_nodo"
        :style="{
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: nodo.height + 'px',
          left: nodo.x + 'px',
          margin: 0 + 'px',
          maxHeight: nodo.height + 'px',
          maxWidth: nodo.width + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.y + 'px',
          width: nodo.width + 'px',
          zIndex: 3,
        }"
        v-for="nodo in nodi_interni.filter(
          (n) => n.categoria === 'rettangolo_smussato'
        )"
      >
        <!-- Barra del titolo -->
        <div
          :style="{
            backgroundColor: '#3f51b5',
            border: '1px solid black',
            borderRadius: '5px 5px 0px 0px',
            color: 'white',
            height: nodo.height * 0.3 + 'px',
            lineHeight: nodo.height * 0.3 + 'px',
            padding: '0px 0px 10px 0px',
            textAlign: 'left',
            textShadow: '2px 2px 4px #000000',
            userSelect: 'none',
          }"
        >
          <span
            style="bottom: 2px; font-size: 13px; left: 2px; position: relative"
            >{{ nodo.titolo_nodo_flowchart }}</span
          >
        </div>
        <!-- Corpo del nodo -->
        <div
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '0px 0px 5px 5px',
            height: nodo.height * 0.7 + 'px',
            margin: '0px',
            maxHeight: nodo.height * 0.7 + 'px',
            maxWidth: nodo.width + 'px',
            padding: '0px',
            overflow: 'hidden',
            textAlign: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            width: nodo.width + 'px',
          }"
        >
          <div
            :style="{
              backgroundColor: 'white',
              border: '0px',
              borderRadius: '0px 0px 5px 5px',
              display: 'table-cell',
              height: nodo.height * 0.7 + 'px',
              maxHeight: nodo.height * 0.7 + 'px',
              maxWidth: nodo.width + 'px',
              overflow: 'hidden',
              textAlign: 'center',
              userSelect: 'none',
              verticalAlign: 'middle',
              width: nodo.width + 'px',
            }"
          >
            <span style="font-size: 13px; position: relative; top: -5px">{{
              nodo.testo_nodo_flowchart
            }}</span>
          </div>
        </div>
        <!-- Maniglia nord -->
        <div
          @mousedown.stop="startDrawing($event, nodo, 'top')"
          @mouseup.stop="stopDrawing($event, nodo, 'top')"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: nodo.width / 2 - 5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: -5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_freccia'"
        ></div>
        <!-- Maniglia sud -->
        <div
          @mousedown.stop="startDrawing($event, nodo, 'bottom')"
          @mouseup.stop="stopDrawing($event, nodo, 'bottom')"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: nodo.width / 2 - 5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height - 5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_freccia'"
        ></div>
        <!-- Maniglia ovest -->
        <div
          @mousedown.stop="startDrawing($event, nodo, 'left')"
          @mouseup.stop="stopDrawing($event, nodo, 'left')"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: -5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height / 2 - 5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_freccia'"
        ></div>
        <!-- Maniglia est -->
        <div
          @mousedown.stop="startDrawing($event, nodo, 'right')"
          @mouseup.stop="stopDrawing($event, nodo, 'right')"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: nodo.width - 5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height / 2 - 5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_freccia'"
        ></div>
      </div>

      <!-- Template del nodo immagine -->
      <div
        @mousedown="startDragging($event, nodo)"
        @mouseup="endDragging($event)"
        @mousemove="dragNode($event)"
        @contextmenu="clickDestroNodo($event, nodo)"
        :id="'div_nodo_' + nodo.id_nodo"
        :key="nodo.id_nodo"
        :style="{
          backgroundImage: 'url(' + nodo.background_src.src + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: nodo.height + 'px',
          left: nodo.x + 'px',
          margin: 0 + 'px',
          maxHeight: nodo.height + 'px',
          maxWidth: nodo.width + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.y + 'px',
          width: nodo.width + 'px',
          zIndex: 3,
        }"
        v-for="nodo in nodi_interni.filter(
          (n) => n.categoria === 'nodo_immagine'
        )"
      ></div>

      <svg
        class="ma-0 pa-0"
        @mousedown="startDrawing($event, undefined, '')"
        @mouseup="stopDrawing($event, undefined, undefined)"
        @contextmenu.stop="clickDestroSVG"
        :style="{
          backgroundColor: 'white',
          border: '1px solid black',
          display: 'inline-block',
          height: height,
          position: 'relative',
          width: width,
        }"
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <!-- Defs che disegnano le punte della freccia -->
        <defs>
          <marker
            id="blackarrowend"
            viewBox="0 0 24 24"
            refX="12"
            refY="12"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              stroke="#000000"
              fill="#000000"
              d="M2,21L23,12L2,3V10L17,12L2,14V21Z"
            />
          </marker>
          <marker
            id="greenarrowend"
            viewBox="0 0 24 24"
            refX="12"
            refY="12"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              stroke="#00ff00"
              fill="#00ff00"
              d="M2,21L23,12L2,3V10L17,12L2,14V21Z"
            />
          </marker>
          <marker
            id="bluearrowend"
            viewBox="0 0 24 24"
            refX="12"
            refY="12"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              stroke="#0000ff"
              fill="#0000ff"
              d="M2,21L23,12L2,3V10L17,12L2,14V21Z"
            />
          </marker>
          <marker
            id="redarrowend"
            viewBox="0 0 24 24"
            refX="12"
            refY="12"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
          >
            <path
              stroke="#ff0000"
              fill="#ff0000"
              d="M2,21L23,12L2,3V10L17,12L2,14V21Z"
            />
          </marker>
        </defs>
      </svg>
    </div>
    <!-- Menu contestuale -->
    <v-card
      id="menu_contestuale"
      style="z-index: 99"
      v-show="menu_contestuale_visible"
      width="250px"
    >
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text">
          Menu Contestuale
        </v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-item
          @click="enterModEditAggiungiNodo('aggiungi_nodo')"
          v-if="
            bvc.bottone_aggiungi_nodo_visible === true &&
            allow_aggiungi_nodo === true
          "
        >
          <v-list-item-title> Aggiungi Nodo </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditAggiungiNodo('aggiungi_immagine')"
          v-if="
            bvc.bottone_aggiungi_immagine_visible === true &&
            allow_aggiungi_immagine === true
          "
        >
          <v-list-item-title> Aggiungi Immagine </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditAggiungiLinea('aggiungi_linea')"
          v-if="
            bvc.bottone_aggiungi_linea_visible === true &&
            allow_aggiungi_linea === true
          "
        >
          <v-list-item-title> Aggiungi Linea </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditAggiungiFreccia"
          v-if="
            bvc.bottone_aggiungi_freccia_visible === true &&
            allow_aggiungi_freccia === true
          "
        >
          <v-list-item-title> Aggiungi Freccia </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditCopiaNodo"
          v-if="
            bvc.bottone_copia_nodo_visible === true && allow_copia_nodo === true
          "
        >
          <v-list-item-title> Copia Nodo </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditModificaNodo"
          v-if="
            bvc.bottone_modifica_nodo_visible === true &&
            allow_modifica_nodo === true
          "
        >
          <v-list-item-title> Modifica Nodo </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditModificaFreccia"
          v-if="
            bvc.bottone_modifica_freccia_visible === true &&
            allow_modifica_freccia === true
          "
        >
          <v-list-item-title> Modifica Freccia </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="clickEliminaFreccia"
          v-if="
            bvc.bottone_elimina_freccia_visible === true &&
            allow_elimina_freccia === true
          "
        >
          <v-list-item-title> Elimina Freccia </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="clickEliminaNodo"
          v-if="
            bvc.bottone_elimina_nodo_visible === true &&
            allow_elimina_nodo === true
          "
        >
          <v-list-item-title> Elimina Nodo </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
    <v-dialog v-model="conferma_elimina_freccia" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Eliminazione Connessione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="pt-5" style="overflow-y: auto">
          Confermi di voler cancellare la connessione selezionata?
          <v-divider class="my-5"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="annullaEliminaFreccia"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Annulla</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="confermaEliminaFreccia"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="conferma_elimina_nodo" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Eliminazione Nodo</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="pt-5" style="overflow-y: auto">
          Confermi di voler cancellare l'elemento selezionato?
          <v-divider class="my-5"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="annullaEliminaNodo"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Annulla</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="confermaEliminaNodo"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment/moment";
class ButtonVisibilityController {
  bottone_aggiungi_freccia_visible;
  bottone_aggiungi_immagine_visible;
  bottone_aggiungi_linea_visible;
  bottone_aggiungi_nodo_visible;
  bottone_copia_nodo_visible;
  bottone_elimina_freccia_visible;
  bottone_elimina_nodo_visible;
  bottone_modifica_freccia_visible;
  bottone_modifica_nodo_visible;

  constructor() {
    this.bottone_aggiungi_freccia_visible = false;
    this.bottone_aggiungi_immagine_visible = false;
    this.bottone_aggiungi_linea_visible = false;
    this.bottone_aggiungi_nodo_visible = false;
    this.bottone_copia_nodo_visible = false;
    this.bottone_elimina_freccia_visible = false;
    this.bottone_elimina_nodo_visible = false;
    this.bottone_modifica_freccia_visible = false;
    this.bottone_modifica_nodo_visible = false;
  }

  reset() {
    this.bottone_aggiungi_freccia_visible = false;
    this.bottone_aggiungi_immagine_visible = false;
    this.bottone_aggiungi_linea_visible = false;
    this.bottone_aggiungi_nodo_visible = false;
    this.bottone_copia_nodo_visible = false;
    this.bottone_elimina_freccia_visible = false;
    this.bottone_elimina_nodo_visible = false;
    this.bottone_modifica_freccia_visible = false;
    this.bottone_modifica_nodo_visible = false;
  }

  show(arg_string) {
    this[arg_string] = true;
  }
}

class FlowConn {
  cdx = null;
  cdy = null;
  cux = null;
  cuy = null;
  id_connessione = null;
  id_nodo_end = null;
  id_nodo_start = null;
  maniglia_end = "";
  maniglia_start = "";

  constructor(arg) {
    if (arg != undefined) {
      this.cdx = arg.cdx != undefined ? arg.cdx : null;
      this.cdy = arg.cdy != undefined ? arg.cdy : null;
      this.cux = arg.cux != undefined ? arg.cux : null;
      this.cuy = arg.cuy != undefined ? arg.cuy : null;
      this.id_connessione =
        arg.id_connessione != undefined
          ? arg.id_connessione
          : moment().valueOf();
      this.id_nodo_end = arg.id_nodo_end != undefined ? arg.id_nodo_end : null;
      this.id_nodo_start =
        arg.id_nodo_start != undefined ? arg.id_nodo_start : null;
      this.maniglia_end = arg.maniglia_end != undefined ? arg.maniglia_end : "";
      this.maniglia_start =
        arg.maniglia_start != undefined ? arg.maniglia_start : "";
    } else {
      this.cdx = null;
      this.cdy = null;
      this.cux = null;
      this.cuy = null;
      this.id_connessione = null;
      this.id_nodo_end = null;
      this.id_nodo_start = null;
      this.maniglia_end = "";
      this.maniglia_start = "";
    }
  }
}

class FlowNode {
  background_src = "";
  bottom = { x: null, y: null };
  categoria = "";
  height = null;
  id_nodo = null;
  left = { x: null, y: null };
  right = { x: null, y: null };
  testo_nodo_flowchart = "";
  titolo_nodo_flowchart = "";
  top = { x: null, y: null };
  width = null;
  x = null;
  y = null;

  constructor(arg) {
    if (arg != undefined) {
      this.id_nodo = arg.id_nodo != undefined ? arg.id_nodo : null;
      this.bottom.x = arg.x + arg.width / 2;
      this.bottom.y = arg.y + arg.height;
      this.height = arg.height != undefined ? arg.height : null;
      this.left.x = arg.x;
      this.left.y = arg.y + arg.height / 2;
      this.right.x = arg.x + arg.width;
      this.right.y = arg.y + arg.height / 2;
      this.testo_nodo_flowchart =
        arg.testo_nodo_flowchart != undefined ? arg.testo_nodo_flowchart : "";
      this.titolo_nodo_flowchart =
        arg.titolo_nodo_flowchart != undefined ? arg.titolo_nodo_flowchart : "";
      this.top.x = arg.x + arg.width / 2;
      this.top.y = arg.y;
      this.width = arg.width != undefined ? arg.width : null;
      this.x = arg.x != undefined ? arg.x : null;
      this.y = arg.y != undefined ? arg.y : null;
    } else {
      this.id_nodo = null;
      this.bottom.x = null;
      this.bottom.y = null;
      this.height = null;
      this.left.x = null;
      this.left.y = null;
      this.right.x = null;
      this.right.y = null;
      this.testo_nodo_flowchart = "";
      this.titolo_nodo_flowchart = "";
      this.top.x = null;
      this.top.y = null;
      this.width = null;
      this.x = null;
      this.y = null;
    }
  }
}

class FunctionArray {
  items = [
    [
      [
        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -1);
        },
        //{f:1,c:-1}

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -1);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, -1);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, -1);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, -1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -1);
        },
      ],
    ],
    [
      [
        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, 2);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 2);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 2);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, 2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, 2);
        },
      ],
    ],
    [
      [
        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, 1);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, 1);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, 1);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, 1);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, 1);
        },
      ],
    ],
    [
      [
        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, -2);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSII(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionS(a, b, c, d, -2);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionL(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIup(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionSIdown(a, b, c, d, -2);
        },
      ],
      [
        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -2);
        },

        (a, b, c, d) => {
          return this.renderConnectionU(a, b, c, d, -2);
        },
      ],
    ],
  ];

  renderConnectionI(cdx, cdy, cux, cuy) {
    let path_str = "";

    path_str += `M ${cdx},${cdy} L ${cux},${cuy}`;

    return path_str;
  }

  renderConnectionL(cdx, cdy, cux, cuy, cvf) {
    // + se la destinazione è a destra, - se la destinazione è a sinistra.
    const fX = Math.sign(cux - cdx);

    // + se la destinazione è in basso, - se la destinazione è in alto.
    const fY = Math.sign(cuy - cdy);

    const vertical = cvf % 2 != 0;

    const dX = Math.abs(cux - cdx);
    const dY = Math.abs(cuy - cdy);

    let path_str = "";

    if (vertical) {
      path_str += `M ${cdx},${cdy} `;
      path_str += `v ${cvf * (dY - 10)} `;
      path_str += `q 0,${cvf * 10} ${fX * 10},${cvf * 10} `;
      path_str += `H ${cux}`;
    } else {
      path_str += `M ${cdx},${cdy} `;
      path_str += `h ${(cvf / 2) * (dX - 10)} `;
      path_str += `q ${(cvf / 2) * 10},0 ${(cvf / 2) * 10},${fY * 10} `;
      path_str += `V ${cuy}`;
    }

    return path_str;
  }

  renderConnectionS(cdx, cdy, cux, cuy, cvf) {
    // + se la destinazione è a destra, - se la destinazione è a sinistra.
    const fX = Math.sign(cux - cdx);

    // + se la destinazione è in basso, - se la destinazione è in alto.
    const fY = Math.sign(cuy - cdy);

    const dX = Math.abs(cux - cdx);
    const dY = Math.abs(cuy - cdy);

    const linear = dX < 10 || dY < 10 ? true : false;
    const vertical = cvf % 2 != 0;

    let path_str = "";

    if (linear) {
      path_str += `M ${cdx},${cdy} L ${cux},${cuy}`;
    } else {
      if (vertical) {
        path_str += `M ${cdx} ${cdy} `;
        path_str += `v ${fY * 10} `;
        path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
        path_str += `h ${fX * (dX - 10 * 2)} `;
        path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
        path_str += `V ${cuy}`;
      } else {
        path_str += `M ${cdx} ${cdy} `;
        path_str += `h ${fX * 10} `;
        path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
        path_str += `v ${fY * (dY - 10 * 2)} `;
        path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
        path_str += `H ${cux}`;
      }
    }

    return path_str;
  }

  renderConnectionSIdown(cdx, cdy, cux, cuy, cvf) {
    // + se la destinazione è a destra, - se la destinazione è a sinistra.
    const fX = Math.sign(cux - cdx);

    // + se la destinazione è in basso, - se la destinazione è in alto.
    const fY = Math.sign(cuy - cdy);

    const dX = Math.abs(cux - cdx);
    const dY = Math.abs(cuy - cdy);

    const vertical = cvf % 2 != 0;

    let path_str = "";

    if (vertical) {
      path_str += `M ${cdx} ${cdy} `;
      path_str += `v ${cvf * 10} `;
      path_str += `q 0,${cvf * 10} ${fX * 10},${cvf * 10} `;
      path_str += `h ${fX * dX} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
      path_str += `V ${cuy + -fY * 10} `;
      path_str += `q 0,${fY * 10} ${-fX * 10},${fY * 10} `;
      path_str += `H ${cux}`;
    } else {
      path_str += `M ${cdx} ${cdy} `;
      path_str += `h ${(cvf / 2) * 10} `;
      path_str += `q ${(cvf / 2) * 10},0 ${(cvf / 2) * 10},${fY * 10} `;
      path_str += `v ${fY * dY} `;
      path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
      path_str += `H ${cux + -fX * 10} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${-fY * 10} `;
      path_str += `V ${cuy}`;
    }

    return path_str;
  }

  renderConnectionSIup(cdx, cdy, cux, cuy, cvf) {
    // + se la destinazione è a destra, - se la destinazione è a sinistra.
    const fX = Math.sign(cux - cdx);

    // + se la destinazione è in basso, - se la destinazione è in alto.
    const fY = Math.sign(cuy - cdy);

    const dX = Math.abs(cux - cdx);
    const dY = Math.abs(cuy - cdy);

    const vertical = cvf % 2 != 0;

    let path_str = "";

    if (vertical) {
      path_str += `M ${cdx} ${cdy} `;
      path_str += `v ${cvf * 10} `;
      path_str += `q 0,${cvf * 10} ${fX * 10},${cvf * 10} `;
      path_str += `h ${fX * (dX - 40)} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
      path_str += `V ${cuy + -fY * 10} `;
      path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
      path_str += `H ${cux}`;
    } else {
      path_str += `M ${cdx} ${cdy} `;
      path_str += `h ${(cvf / 2) * 10} `;
      path_str += `q ${(cvf / 2) * 10},0 ${(cvf / 2) * 10},${fY * 10} `;
      path_str += `v ${fY * (dY - 40)} `;
      path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
      path_str += `H ${cux + -fX * 10} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
      path_str += `V ${cuy}`;
    }

    return path_str;
  }

  renderConnectionSII(cdx, cdy, cux, cuy, cvf) {
    // + se la destinazione è a destra, - se la destinazione è a sinistra.
    const fX = Math.sign(cux - cdx);

    // + se la destinazione è in basso, - se la destinazione è in alto.
    const fY = Math.sign(cuy - cdy);

    const dX = Math.abs(cux - cdx);
    const dY = Math.abs(cuy - cdy);

    const vertical = cvf % 2 != 0;

    let path_str = "";

    if (vertical) {
      path_str += `M ${cdx} ${cdy} `;
      path_str += `v ${cvf * 10}`;
      path_str += `q 0,${cvf * 10} ${fX * 10},${cvf * 10} `;
      path_str += `h ${fX * (dX / 2 - 10)} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
      path_str += `V ${cuy + fY * 10} `;
      path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
      path_str += `H ${cux + -fX * 10} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${-fY * 10} `;
      path_str += `V ${cuy}`;
    } else {
      path_str += `M ${cdx} ${cdy} `;
      path_str += `h ${(cvf / 2) * 10}`;
      path_str += `q ${(cvf / 2) * 10},0 ${(cvf / 2) * 10},${fY * 10} `;
      path_str += `v ${fY * (dY / 2 - 10)} `;
      path_str += `q 0,${fY * 10} ${fX * 10},${fY * 10} `;
      path_str += `H ${cux + fX * 10} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${fY * 10} `;
      path_str += `V ${cuy + -fY * 10} `;
      path_str += `q 0,${fY * 10} ${-fX * 10},${fY * 10} `;
      path_str += `H ${cux}`;
    }

    return path_str;
  }

  renderConnectionU(cdx, cdy, cux, cuy, cvf) {
    // + se la destinazione è a destra, - se la destinazione è a sinistra.
    const fX = Math.sign(cux - cdx);

    // + se la destinazione è in basso, - se la destinazione è in alto.
    const fY = Math.sign(cuy - cdy);

    const vertical = cvf % 2 != 0;

    const dX = Math.abs(cux - cdx);
    const dY = Math.abs(cuy - cdy);

    let path_str = "";

    if (vertical) {
      path_str += `M ${cdx},${cdy} `;
      path_str += `v ${
        (cdy > cuy && cvf > 0) || (cdy < cuy && cvf < 0)
          ? cvf * 10
          : cvf * dY + fY * 10
      } `;
      path_str += `q 0,${cvf * 10} ${fX * 10},${cvf * 10} `;
      path_str += `h ${fX * Math.max(dX - 20, 50)} `;
      path_str += `q ${fX * 10},0 ${fX * 10},${cvf * -10} `;
      path_str +=
        dX - 20 < 50
          ? `v ${fY * (dY - 40)} q 0,${fY * 10} ${-fX * 10},${fY * 10} h ${
              -fX * (50 - dX)
            } q ${-fX * 10},0 ${-fX * 10},${fY * 10} `
          : "";
      path_str += `V ${cuy}`;
    } else {
      path_str += `M ${cdx},${cdy} `;
      path_str += `h ${
        (cdx > cux && cvf > 0) || (cdx < cux && cvf < 0)
          ? (cvf / 2) * 10
          : (cvf / 2) * dX + fX * 10
      } `;
      path_str += `q ${(cvf / 2) * 10},0 ${(cvf / 2) * 10},${fY * 10} `;
      path_str += `v ${fY * Math.max(dY - 20, 50)} `;
      path_str += `q 0,${fY * 10} ${(cvf / 2) * -10},${fY * 10} `;
      path_str +=
        dY - 20 < 50
          ? `h ${fX * (dX - 40)} q ${fX * 10},0 ${fX * 10},${-fY * 10} v ${
              -fY * (50 - dY)
            } q 0,${-fY * 10} ${fX * 10},${-fY * 10} `
          : "";
      path_str += `H ${cux}`;
    }
    return path_str;
  }
}

export default {
  name: "flowchart",
  components: {},
  props: {
    allow_aggiungi_freccia: {
      type: Boolean,
      default: true,
    },
    allow_aggiungi_immagine: {
      type: Boolean,
      default: true,
    },
    allow_aggiungi_linea: {
      type: Boolean,
      default: true,
    },
    allow_aggiungi_nodo: {
      type: Boolean,
      default: true,
    },
    allow_copia_nodo: {
      type: Boolean,
      default: true,
    },
    allow_elimina_freccia: {
      type: Boolean,
      default: true,
    },
    allow_elimina_nodo: {
      type: Boolean,
      default: true,
    },
    allow_modifica_freccia: {
      type: Boolean,
      default: true,
    },
    allow_modifica_nodo: {
      type: Boolean,
      default: true,
    },
    connessioni: {
      type: Array,
      return: () => [],
    },
    height: {
      type: String,
      default: "500px",
    },
    metodista: {
      type: Object,
      default: function () {
        return {
          setBackgorundImg: function (n_target, n_origin) {
            if (n_origin.immagine) {
              n_target.background_src = n_origin.immagine;
            }
          },
          setTestoNodo: function (n_target, n_origin) {
            n_target.testo_nodo_flowchart = n_origin.des_questionario_domanda;
          },
          setTipoConnessione: function (c_target, c_origin) {
            c_target.type =
              c_origin.punteggio == 100
                ? "enter"
                : c_origin.punteggio == 0
                ? "reject"
                : "annulla";
          },
          setTitoloNodo: function (n_target, n_origin) {
            n_target.titolo_nodo_flowchart =
              n_origin.id_tipo_domanda == 0
                ? "[T] "
                : n_origin.id_tipo_domanda == 1
                ? "[S] "
                : n_origin.id_tipo_domanda == 2
                ? "[M] "
                : n_origin.id_tipo_domanda == 3
                ? "[I] "
                : "[A] ";

            n_target.titolo_nodo_flowchart +=
              "Risposte: " + n_origin.risposte.length;
          },
        };
      },
    },
    nodes_object: {
      type: Object,
      default: function () {
        return {
          linee: [],
          nodi_immagine: [],
          rettangoli_smussati: [],
        };
      },
    },
    var_id_nodo: {
      type: String,
      default: "",
    },
    var_id_nodo_end: {
      type: String,
      default: "",
    },
    var_id_nodo_start: {
      type: String,
      default: "",
    },
    var_id_connessione: {
      type: String,
      default: "",
    },
    var_maniglia_end: {
      type: String,
      default: "",
    },
    var_maniglia_start: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      altezza_freccia: 10,
      click_down_x: null,
      click_down_y: null,
      click_up_x: null,
      click_up_y: null,
      direzione: "",
      id_nodo_end: null,
      id_nodo_start: null,
      id_freccia_sel: null,
      id_nodo_sel: null,
      larghezza_freccia: 6,
      maniglia_start: "",
      modalita_editing: "click_atteso",

      // Modal
      bvc: new ButtonVisibilityController(),
      conferma_elimina_freccia: false,
      conferma_elimina_nodo: false,
      menu_contestuale_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      connessioni_interne: new Array(),
      f: new FunctionArray(),
      nodi_interni: new Array(),
    };
  },
  computed: {},
  watch: {
    nodes_object: {
      immediate: true,
      handler() {
        this.nodi_interni = new Array();

        // Ricevo i rettangoli smussati
        this.nodes_object.rettangoli_smussati.forEach((nodo) => {
          let fn = new FlowNode(nodo);

          // Assegno titolo e testo al nodo.
          fn.categoria = "rettangolo_smussato";
          this.metodista.setTestoNodo(fn, nodo);
          this.metodista.setTitoloNodo(fn, nodo);
          this.metodista.setBackgorundImg(fn, nodo);

          /* Cerco l'id del nodo dove il padre mi dice
           * di guardare usando una prop. */
          fn.id_nodo = nodo[this.var_id_nodo];

          // Aggiungo il nodo.
          this.nodi_interni.push(fn);
        });

        // Ricevo i nodi immagine
        this.nodes_object.nodi_immagine.forEach((nodo) => {
          let fn = new FlowNode(nodo);

          // Assegno titolo e testo al nodo.
          fn.categoria = "nodo_immagine";
          this.metodista.setBackgorundImg(fn, nodo);

          /* Cerco l'id del nodo dove il padre mi dice
           * di guardare usando una prop. */
          fn.id_nodo = nodo[this.var_id_nodo];

          // Aggiungo il nodo.
          this.nodi_interni.push(fn);
        });

        // Ricevo le linee.
        this.nodes_object.linee.forEach((l) => {
          // Creo una linea.
          let linea = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "line"
          );

          // Setto i dati della linea.
          linea.setAttribute("id", l.id);
          linea.setAttribute("x1", l.x1);
          linea.setAttribute("y1", l.y1);
          linea.setAttribute("x2", l.x2);
          linea.setAttribute("y2", l.y2);
          linea.style.stroke = l.stroke;
          linea.style.strokeWidth = l.strokeWidth;

          // Aggiungo la linea al documento.
          this.$nextTick(() => {
            document.getElementById("svg").appendChild(linea);
          });
          console.log(JSON.stringify(l));
          console.log(linea);
        });
      },
    },
    connessioni: {
      immediate: true,
      handler() {
        // Svuoto le connessioni interne
        this.connessioni_interne = new Array();

        /* Se è renderizzato, vuoto il canvas; se
         * non è renderizzato vuol dire che sono appena
         * entrato, e quindi è vuoto di suo.*/
        if (document.getElementById("svg")) {
          let svg = document.getElementById("svg");
          while (svg.childNodes.firstChild) {
            svg.childNodes[0].remove();
          }
        }

        // (Ri)popolo le connessioni interne e il canvas.
        this.connessioni.forEach((conn) => {
          let fc = new FlowConn();

          /* Valorizzo i campi della connessione guardando
           * dove il padre mi dice di guardare usando le prop. */
          fc.id_connessione =
            conn[this.var_id_connessione] != undefined
              ? conn[this.var_id_connessione]
              : fc.id_connessione;
          fc.id_nodo_end =
            conn[this.var_id_nodo_end] != undefined
              ? conn[this.var_id_nodo_end]
              : fc.id_nodo_end;
          fc.id_nodo_start =
            conn[this.var_id_nodo_start] != undefined
              ? conn[this.var_id_nodo_start]
              : fc.id_nodo_start;
          fc.maniglia_end =
            conn[this.var_maniglia_end] != undefined
              ? conn[this.var_maniglia_end]
              : fc.maniglia_end;
          fc.maniglia_start =
            conn[this.var_maniglia_start] != undefined
              ? conn[this.var_maniglia_start]
              : fc.maniglia_start;
          this.metodista.setTipoConnessione(fc, conn);

          /* Sempre per valorizzare i campi della connessione, cerco il
           * nodo di start per prendere le coordinate di inizio del path. */
          this.nodi_interni.forEach((n) => {
            if (fc.id_nodo_start === n.id_nodo) {
              fc.cdx = n[fc.maniglia_start].x;
              fc.cdy = n[fc.maniglia_start].y;
            }
          });

          /* Sempre per valorizzare i campi della connessione, cerco il
           * nodo di start per prendere le coordinate di fine del path. */
          this.nodi_interni.forEach((n) => {
            if (fc.id_nodo_end === n.id_nodo) {
              fc.cux = n[fc.maniglia_end].x;
              fc.cuy = n[fc.maniglia_end].y;
            }
          });

          // Aggiungo la connessione.
          this.connessioni_interne.push(fc);

          this.$nextTick(() => {
            this.renderConnection(
              fc.cdx,
              fc.cdy,
              fc.cux,
              fc.cuy,
              fc.maniglia_start,
              fc.maniglia_end,
              fc.id_connessione,
              fc.type
            );
          });
        });
      },
    },
  },
  methods: {
    annullaEliminaFreccia() {
      this.id_freccia_sel = null;
      this.conferma_elimina_freccia = false;
    },
    annullaEliminaNodo() {
      this.id_nodo_sel = null;
      this.conferma_elimina_nodo = false;
    },
    clickDestroNodo(e, nodo) {
      e.preventDefault();

      /* Se il menu è visibile e faccio un nuovo click
       * destro vuol dire che lo sto chiudendo. */
      if (this.menu_contestuale_visible === true) {
        // Nascondo il menu contestuale
        this.menu_contestuale_visible = false;

        // Rendo invisibili tutti i bottoni
        this.bvc.reset();
      } else {
        // Registro le coordiante del punto in cui ho clickato.
        this.click_down_x = e.clientX;
        this.click_down_y = e.clientY;
        this.click_up_x = e.clientX;
        this.click_up_y = e.clientY;

        // Valorizzo i modal dei bottoni che voglio / non voglio mostrare.
        this.bvc.reset();
        this.bvc.show("bottone_copia_nodo_visible");
        this.bvc.show("bottone_elimina_nodo_visible");
        this.bvc.show("bottone_modifica_nodo_visible");

        // Rendo visibile il menu contestuale e lo posiziono.
        this.menu_contestuale_visible = true;
        document.getElementById("menu_contestuale").style.position = "absolute";
        document.getElementById("menu_contestuale").style.left =
          Math.round(e.clientX) + "px";
        document.getElementById("menu_contestuale").style.top =
          Math.round(e.clientY - 50) + "px";

        // Registro l'id del nodo che ho selezionato.
        this.id_nodo_sel = nodo.id_nodo;
      }
    },
    clickDestroSVG(e) {
      e.preventDefault();

      /* Se la modalità non è click_atteso, nascondo i
       * bottoni del menu contestuale e non faccio nulla. */
      if (this.modalita_editing != "click_atteso") {
        this.bvc.reset();
        return;
      } else {
        // Se ho cliccato su una freccia...
        if (e.composedPath()[0].tagName === "path") {
          // Registro l'id della freccia selezionata.
          this.id_freccia_sel = e.composedPath()[0].getAttribute("id");

          // Abilito i bottoni del menu contestuale della freccia.
          this.bvc.reset();
          this.bvc.show("bottone_elimina_freccia_visible");
          this.bvc.show("bottone_modifica_freccia_visible");
        } else {
          // Abilito i bottoni del menu contestuale generico.
          this.bvc.reset();
          this.bvc.show("bottone_aggiungi_freccia_visible");
          this.bvc.show("bottone_aggiungi_immagine_visible");
          this.bvc.show("bottone_aggiungi_linea_visible");
          this.bvc.show("bottone_aggiungi_nodo_visible");
        }
        // Registro le coordiante del punto in cui ho clickato.
        this.click_down_x = Math.round(e.clientX);
        this.click_down_y = Math.round(e.clientY);
        this.click_up_x = Math.round(e.clientX);
        this.click_up_y = Math.round(e.clientY);

        // Rendo visibile il menu contestuale e lo posiziono.
        this.menu_contestuale_visible = true;
        document.getElementById("menu_contestuale").style.position = "absolute";
        document.getElementById("menu_contestuale").style.left =
          Math.round(e.clientX) + "px";
        document.getElementById("menu_contestuale").style.top =
          Math.round(e.clientY) - 50 + "px";
      }
    },
    clickEliminaFreccia() {
      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Apro il dialog di conferma.
      this.conferma_elimina_freccia = true;
    },
    clickEliminaNodo() {
      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Apro il dialog di conferma.
      this.conferma_elimina_nodo = true;
    },
    confermaEliminaFreccia() {
      this.$emit("eliminaFreccia", "connessioni", this.id_freccia_sel);

      // Chiudo il dialog di conferma.
      this.conferma_elimina_freccia = false;

      // Resetto l'id della freccia selezionata.
      this.id_freccia_sel = null;
    },
    confermaEliminaNodo() {
      this.$emit("eliminaNodo", "nodi", this.id_nodo_sel);

      // Resetto l'id del nodo selezionato.
      this.id_nodo_sel = null;

      // Chiudo il dialog di conferma.
      this.conferma_elimina_nodo = false;
    },
    dragNode(e) {
      // Eseguo le istruzioni solo se sono in drag;
      if (!this.stato_drag_nodo) {
        return;
      }

      /* Se la mia posizione attuale è la stessa dell'inizio
       * del drag non faccio nulla perché evidentemente al momento
       * non ho ancora fatto altro che cliccare.*/
      if (
        e.clientX -
          document.getElementById("svg").getBoundingClientRect().x -
          40 ==
          this.click_down_x &&
        e.clientY -
          document.getElementById("svg").getBoundingClientRect().y -
          40 ==
          this.click_down_x
      ) {
        return;
      }

      // Se effettivamente sto draggando, riassegno le coordinate al nodo.
      this.nodo_sel.x =
        e.clientX -
        document.getElementById("svg").getBoundingClientRect().x -
        40;
      this.nodo_sel.y =
        e.clientY -
        document.getElementById("svg").getBoundingClientRect().y -
        40;

      for (let j = 0; j < this.connessioni_interne.length; j++) {
        if (
          this.connessioni_interne[j].id_nodo_start == this.nodo_sel.id_nodo ||
          this.connessioni_interne[j].id_nodo_end == this.nodo_sel.id_nodo
        ) {
          let conn;
          for (
            let i = 0;
            i < document.getElementById("svg").childNodes.length;
            i++
          ) {
            if (
              document.getElementById("svg").childNodes[i].id ==
              this.connessioni_interne[j].id_connessione
            ) {
              conn = i;
            }
          }
          let element_to_remove = document.getElementById("svg").childNodes[
            conn
          ];
          element_to_remove.parentElement.removeChild(element_to_remove);
          this.connessioni_interne.splice(j, 1);
        }
      }
    },
    endDragging(e) {
      // Eseguo le istruzioni solo se sono in drag.
      if (!this.stato_drag_nodo) {
        return;
      }

      /* Se non ho mai spostato il mouse ho solo cliccato
       * per selezionar il nodo e non faccio nulla. */
      if (
        e.clientX -
          document.getElementById("svg").getBoundingClientRect().x -
          40 ==
          this.click_down_x &&
        e.clientY -
          document.getElementById("svg").getBoundingClientRect().y -
          40 ==
          this.click_down_y
      ) {
        /* Elimino la variabile per lo stato del drag, che
         * tanto poi verrà ripristinata al prossimo drag. */
        delete this.stato_drag_nodo;

        // Elimino il puntatore al nodo selezionato.
        delete this.nodo_sel;

        // Resetto la x e la y iniziali.
        this.click_down_x = null;
        this.click_down_y = null;

        return;
      }

      // Resetto la x e la y iniziali.
      this.click_down_x = null;
      this.click_down_y = null;

      // Riassegno un'ultima volta le coordinate al nodo
      this.nodo_sel.x =
        e.clientX -
        document.getElementById("svg").getBoundingClientRect().x -
        40;
      this.nodo_sel.y =
        e.clientY -
        document.getElementById("svg").getBoundingClientRect().y -
        40;

      // Registro id, x e y del nodo per emetterle.
      let id = this.nodo_sel.id_nodo;
      let x = this.nodo_sel.x;
      let y = this.nodo_sel.y;

      this.$nextTick(() => {
        /* Elimino la variabile per lo stato del nodo, che
         * tanto poi verrà ripristinata al prossimo drag. */
        delete this.stato_drag_nodo;

        // Elimino il puntatore al nodo selezionato.
        delete this.nodo_sel;

        /* Segnalo al componente padre che sono
         * cambiate le coordinate di uno dei suoi nodi. */
        this.$emit("movimentoNodo", "spostamento", id, x, y);
      });
    },
    enterModEditAggiungiFreccia() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_freccia.
      this.modalita_editing = "aggiungi_freccia";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();
    },
    enterModEditAggiungiLinea() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_freccia.
      this.modalita_editing = "aggiungi_linea";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();
    },
    enterModEditAggiungiNodo(text) {
      /* Questo metodo serve per entrare in modalità
       * di aggiunta nodi di vari tipi: rettangolari,
       * immagini, o altro. La tipologia specifica
       * è identificata dall'argomento del metodo. */

      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_nodo.
      this.modalita_editing = text;

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Stampo una snackbar di prompt.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere l'elemento.";
      this.snackbar_text_color = "white";
    },
    enterModEditCopiaNodo() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità
      this.modalita_editing = "copia_nodo";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Clicca nel punto in cui vuoi aggiungere il nodo.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere il nodo.";
      this.snackbar_text_color = "white";
    },
    enterModEditModificaNodo() {
      this.$emit("editnode", "modificaDati", this.id_nodo_sel);

      // Chiudo il menu contestuale e tutti i suoi pulsanti.
      this.menu_contestuale_visible = false;

      this.bvc.reset();

      // Resetto il nodo_sel.
      this.id_nodo_sel = null;
    },
    enterModEditModificaFreccia() {
      /* Cerco la connessione nella prop delle
       * connessioni che mi arriva dal componente padre,
       * per mandargli dati più precisi da modificare.*/
      this.connessioni.forEach((c) => {
        if (c.id_questionario_connessione == this.id_freccia_sel) {
          this.$emit("editconnection", c);
        }
      });

      // Chiudo il menu contestuale e tutti i suoi pulsanti.
      this.menu_contestuale_visible = false;

      this.bvc.reset();

      // Resetto l'id_freccia_sel.
      this.id_freccia_sel = null;
      return;
    },
    moveDrawing(e) {
      switch (this.modalita_editing) {
        case "aggiungi_freccia":
          // Registro le coordinate del mouse che viene spostato.
          this.click_up_x = Math.round(
            e.clientX - document.getElementById("svg").getBoundingClientRect().x
          );
          this.click_up_y = Math.round(
            e.clientY - document.getElementById("svg").getBoundingClientRect().y
          );

          this.drawingLine.setAttribute("x1", Math.round(this.click_down_x));
          this.drawingLine.setAttribute("y1", Math.round(this.click_down_y));
          this.drawingLine.setAttribute("x2", this.click_up_x);
          this.drawingLine.setAttribute("y2", this.click_up_y);
          this.drawingLine.setAttribute("marker-end", "url(#blackarrowend)");
          this.drawingLine.style.stroke = "#000000";
          this.drawingLine.style.strokeWidth = "2";
          break;
        case "aggiungi_linea":
          // Registro le coordinate del mouse che viene spostato.
          this.click_up_x = Math.round(
            e.clientX - document.getElementById("svg").getBoundingClientRect().x
          );
          this.click_up_y = Math.round(
            e.clientY - document.getElementById("svg").getBoundingClientRect().y
          );

          this.drawingLine.setAttribute("x1", Math.round(this.click_down_x));
          this.drawingLine.setAttribute("y1", Math.round(this.click_down_y));
          this.drawingLine.setAttribute("x2", this.click_up_x);
          this.drawingLine.setAttribute("y2", this.click_up_y);
          this.drawingLine.style.stroke = "#000000";
          this.drawingLine.style.strokeWidth = "2";
          break;
      }
    },
    renderConnection(
      cdx,
      cdy,
      cux,
      cuy,
      maniglia_start,
      maniglia_end,
      id_connessione,
      type
    ) {
      // Prendo il riferimento del mio svg
      let svg = document.getElementById("svg");

      // Disegno la freccia definitiva sulla base dei dati di posizione fin qui raccolti.
      let path = document.createElementNS("http://www.w3.org/2000/svg", "path");

      let maniglia = { top: 0, right: 1, bottom: 2, left: 3 };

      // Definisco il quadrante di destinazione rispetto all'origine.
      let quad;

      if (cdx > cux && cdy > cuy) {
        quad = 0;
      } else if (cdx < cux && cdy > cuy) {
        quad = 1;
      } else if (cdx < cux && cdy < cuy) {
        quad = 2;
      } else {
        quad = 3;
      }

      const a = maniglia[maniglia_start];
      const b = maniglia[maniglia_end];
      const c = quad;

      path.setAttribute("d", this.f.items[a][b][c](cdx, cdy, cux, cuy));

      // Setto i parametri grafici della linea e aggiungo la punta di freccia.
      path.style.fill = "none";
      path.style.stroke =
        type == "enter" ? "#00ff00" : type == "reject" ? "#ff0000" : "#0000ff";
      path.style.strokeWidth = "2";
      path.setAttribute(
        "marker-end",
        `url(#${
          type == "enter"
            ? "greenarrowend"
            : type == "reject"
            ? "redarrowend"
            : "bluearrowend"
        })`
      );
      path.setAttribute("id", id_connessione);
      svg.appendChild(path);
      console.log(type);
    },
    startDragging(e, nodo) {
      /* Se il click viene dal bottone destro
       * non sto draggando e non faccio nulla. */
      if (e.which === 3) {
        return;
      }

      this.click_down_x =
        e.clientX -
        document.getElementById("svg").getBoundingClientRect().x -
        40;
      this.click_down_y =
        e.clientY -
        document.getElementById("svg").getBoundingClientRect().y -
        40;

      this.stato_drag_nodo = true;
      this.nodo_sel = nodo;
    },
    startDrawing(e, arg, maniglia_start) {
      // Registro la maniglia di start.
      this.maniglia_start = maniglia_start;

      /* Se non sto aggiungendo né un nodo né una freccia, non faccio nulla ma
       * rimetto invisibile il menu contestuale nel caso fosse stato aperto. */
      if (
        this.modalita_editing != "aggiungi_freccia" &&
        this.modalita_editing != "aggiungi_immagine" &&
        this.modalita_editing != "aggiungi_linea" &&
        this.modalita_editing != "aggiungi_nodo" &&
        this.modalita_editing != "copia_nodo"
      ) {
        this.menu_contestuale_visible = false;

        // Rendo invisibili tutti i bottoni del menu
        this.bvc.reset();
        return;
      }

      if (arg != undefined) {
        this.id_nodo_start = arg.id_questionario_domanda;
      }

      switch (this.modalita_editing) {
        // Se sto aggiungendo una freccia...
        case "aggiungi_freccia":
          {
            // Se la modalità è quella di disegno della freccia, registro la posizione del click.
            this.click_down_x =
              e.clientX -
              document.getElementById("svg").getBoundingClientRect().x;
            this.click_down_y =
              e.clientY -
              document.getElementById("svg").getBoundingClientRect().y;

            // Registro id del nodo di partenza e la maniglia.
            this.id_nodo_start = arg.id_nodo;
            this.maniglia_start = maniglia_start;

            // Aggiungo una linea all'SVG.
            let svg = document.getElementById("svg");

            let line = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line"
            );

            svg.appendChild(line);

            this.drawingLine = line;

            // Aggiungo un event listener per il movimento del mouse sulla superficie.
            document
              .getElementById("svg")
              .addEventListener("mousemove", this.moveDrawing);
          }
          break;
        // Se sto aggiungendo un'immagine...
        case "aggiungi_immagine":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            /* Emetto un evento per segnalare che c'è un nuovo nodo. Non importa
             * che campi ha, al padre interessa solo che abbia una x e una y. */
            let nuovo_nodo_img = new FlowNode();
            nuovo_nodo_img.x = this.click_down_x;
            nuovo_nodo_img.y = this.click_down_y;

            this.$emit("aggiungi_immagine", nuovo_nodo_img);

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        // Se sto aggiungendo una linea...
        case "aggiungi_linea":
          {
            // Registro la posizione del click.
            this.click_down_x =
              e.clientX -
              document.getElementById("svg").getBoundingClientRect().x;
            this.click_down_y =
              e.clientY -
              document.getElementById("svg").getBoundingClientRect().y;

            // Aggiungo una linea all'SVG.
            let svg = document.getElementById("svg");

            let line = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line"
            );

            svg.appendChild(line);

            this.drawingLine = line;

            // Aggiungo un event listener per il movimento del mouse sulla superficie.
            document
              .getElementById("svg")
              .addEventListener("mousemove", this.moveDrawing);
          }
          break;
        // Se sto aggiungendo un nodo...
        case "aggiungi_nodo":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            /* Emetto un evento per segnalare che c'è un nuovo nodo. Non importa
             * che campi ha, al padre interessa solo che abbia una x e una y. */
            let nuovo_nodo = new FlowNode();
            nuovo_nodo.x = this.click_down_x;
            nuovo_nodo.y = this.click_down_y;

            this.$emit("aggiungi_nodo", nuovo_nodo);

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        // Se invece sto copiando un nodo...
        case "copia_nodo":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            /* Emetto un evento per segnalare che c'è un nuovo nodo.
             * Al padre interessa id del nodo originale, nuava x e nuova y. */
            this.$emit(
              "copiaNodo",
              this.id_nodo_sel,
              this.click_down_x,
              this.click_down_y
            );

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        default:
          break;
      }
    },
    stopDrawing(e, arg, maniglia_end) {
      switch (this.modalita_editing) {
        case "aggiungi_freccia":
          {
            let svg = document.getElementById("svg");
            /* Se arg e maniglia_end sono undefined vuol dire che ho
             * rilasciato su un punto che non ha senso, quindi resetto
             * la mia configurazione e non faccio più nulla. */
            if (arg === undefined && maniglia_end === undefined) {
              // Torno in modalità di attesa del click.
              this.modalita_editing = "click_atteso";

              // Rimuovo l'event listener del movimento del mouse.
              svg.removeEventListener("mousemove", this.moveDrawing);

              // Resetto i riferimenti della posizione.
              this.click_down_x = null;
              this.click_down_y = null;
              this.click_up_x = null;
              this.click_up_y = null;

              if (this.drawingLine) {
                // Cancello la linea provvisoria
                svg.removeChild(this.drawingLine);

                // Mi libero di una variabile che non è più necessaria.
                delete this.drawingLine;
              }

              // Resetto la maniglia di start.
              this.maniglia_start = "";

              // Resetto il nodo start.
              this.id_nodo_start = null;

              return;
            }

            // Se ho rilasciato su una maniglia e quindi sto tracciando una nuova freccia...
            if (arg != undefined) {
              // ... Dichiaro una nuova connessione
              let fc = new FlowConn();

              // Assegno le proprietà della connessione
              fc.id_connessione = moment().valueOf();
              fc.id_nodo_start = this.id_nodo_start;
              fc.id_nodo_end = arg.id_nodo;
              fc.maniglia_start = this.maniglia_start;
              fc.maniglia_end = maniglia_end;

              // Aggiungo la connessione agli array.
              this.$emit("save", "nuovaConnessione", fc);
            }

            // Resetto i riferimenti della posizione.
            this.click_down_x = null;
            this.click_down_y = null;
            this.click_up_x = null;
            this.click_up_y = null;
            this.controllo_x = null;
            this.controllo_y = null;

            // Cancello la linea provvisoria
            svg.removeChild(this.drawingLine);

            // Mi libero di una variabile che non è più necessaria.
            delete this.drawingLine;

            // Resetto la maniglia di start.
            this.maniglia_start = "";

            // Resetto il nodo start.
            this.id_nodo_start = null;

            // Torno in modalità di attesa del click.
            this.modalita_editing = "click_atteso";

            // Rimuovo l'event listener del movimento del mouse.
            document
              .getElementById("svg")
              .removeEventListener("mousemove", this.moveDrawing);
          }
          break;
        case "aggiungi_linea":
          {
            let svg = document.getElementById("svg");

            // Registro le coordinate del mouse che viene spostato.
            this.click_up_x = Math.round(
              e.clientX -
                document.getElementById("svg").getBoundingClientRect().x
            );
            this.click_up_y = Math.round(
              e.clientY -
                document.getElementById("svg").getBoundingClientRect().y
            );

            // Assegno i parametri alla linea.
            let id = moment().valueOf();
            this.drawingLine.setAttribute("id", id);
            this.drawingLine.setAttribute("x1", this.click_down_x);
            this.drawingLine.setAttribute("y1", this.click_down_y);
            this.drawingLine.setAttribute("x2", this.click_up_x);
            this.drawingLine.setAttribute("y2", this.click_up_y);
            this.drawingLine.style.stroke = "#000000";
            this.drawingLine.style.strokeWidth = "2";

            // Mi libero di una variabile che non è più necessaria.
            delete this.drawingLine;

            // Torno in modalità di attesa del click.
            this.modalita_editing = "click_atteso";

            // Rimuovo l'event listener del movimento del mouse.
            svg.removeEventListener("mousemove", this.moveDrawing);

            // Emetto l'oggetto linea.
            let nuova_linea = {
              id: id,
              stroke: "#000000",
              strokeWidth: 2,
              x1: this.click_down_x,
              x2: this.click_up_x,
              y1: this.click_down_y,
              y2: this.click_up_y,
            };

            this.$emit("aggiungi_linea", nuova_linea);
          }
          break;
      }
    },
  },
  created() {},
  mounted() {
    //top right bottom left
    //funzioni[select.f](cdx,cdy,cux,cuy,select.c);
  },
};
</script>
<style scoped>
</style>