<template>
  <div id="listener_tempi_di_lavorazione">
    <v-row align="center" justify="center">
      <v-data-table
        class="elevation-1"
        :headers="headers_tempi"
        :items="items_tempi"
        :items-per-page="5"
        style="width: 100%"
      >
        <!-- Titolo della tabella -->
        <template v-slot:top>
          <v-toolbar color="indigo" class="white--text rounded-t" dense>
            <v-toolbar-title>Tempi di Lavorazione</v-toolbar-title>
          </v-toolbar>
        </template>
        <!-- Bottoni della tabella -->
        <template v-slot:[`item.gestione_lavorazione`]="{ item }">
          <v-btn
            @click="clickGestioneLavorazione(item)"
            :color="item._start == null || (item._start != null && item._stop != null)?'success':'error'"
            dark
            fab
            small
            v-if="item.id_stato_fase != 1 && item.stato_commessa == 1"
          >
            <v-icon>
              {{item._start == null || (item._start != null && item._stop != null)?'mdi-play':'mdi-stop'}}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <v-btn @click="clickNote(item)" color="primary" dark fab small>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.dettagli`]="{ item }">
          <v-btn @click="clickDettagli(item)" color="primary" dark fab small>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
    <!-- Dialog delle note -->
    <v-dialog v-model="note_dialog_visible">
      <v-card>
        <v-toolbar
          class="white--text"
          color="indigo"
          dense
          style="position: sticky; top: 0px; z-index: 1"
        >
          <v-toolbar-title dense>Note</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="note_dialog_visible = !note_dialog_visible"
            color="#ffffff"
            fab
            outlined
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row></v-row>
      </v-card>
    </v-dialog>
    <!-- Dialog dei dettagli -->
    <v-dialog v-model="dettagli_dialog_visible">
      <v-card>
        <v-toolbar
          class="white--text"
          color="indigo"
          dense
          style="position: sticky; top: 0px; z-index: 1"
        >
          <v-toolbar-title dense>Dettagli</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="dettagli_dialog_visible = !dettagli_dialog_visible"
            color="#ffffff"
            fab
            outlined
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row></v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  name: "TempiDiLavorazione",
  props: {
        contatore: {Type: Number, defult: 0}
        },
  data() {
    return {
      // Variabili d'appoggio
      dettagli_dialog_visible: false,
      note_dialog_visible: false,
      // Array
      headers_tempi: [
        {
          text: "Gestione Lavorazione",
          sortable: false,
          value: "gestione_lavorazione",
        },
        {
          text: "Num. Commessa",
          sortable: false,
          value: "numero_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Cod. Fase",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Data",
          sortable: false,
          value: "_data",
        },
        {
          text: "Nome",
          sortable: false,
          value: "nominativo",
        },
        {
          text: "Id Macchina",
          sortable: false,
          value: "id_macchina",
        },
        {
          text: "Des. Macchina",
          sortable: false,
          value: "des_macchina",
        },
        {
          text: "Start",
          sortable: false,
          value: "_start",
        },
        {
          text: "Stop",
          sortable: false,
          value: "_stop",
        },
        { text: "Note", sortable: false, value: "note" },
        { text: "Dettagli", sortable: false, value: "dettagli" },
      ],
      items_tempi: [
      ],
    };
  },
  methods: {
    azionataMacchina(){
      console.log("entrato azionataMacchina");
      this.getAvanzamentoTempi();
    },
    clickGestioneLavorazione(item) {
      this.$emit("click_gestione_lavorazione", item);
    },
    clickDettagli(item) {
      console.log(JSON.stringify(item));
      this.dettagli_dialog_visible = true;
    },
    clickNote(item) {
      console.log(JSON.stringify(item));
      this.note_dialog_visible = true;
    },
    fermataMacchina(){
      console.log("entrato fermataMacchina");
      this.getAvanzamentoTempi();
    },
    getAvanzamentoTempi() {
      // Stabilisco che data è, per inserire i filtri data del BE
      let oggi = moment();
      let ieri = moment().subtract(1, "days");

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessaavanzamento",
          token: localStorage.getItem("user_token"),
          tipo: "t",
          analisi: "prod",
          data_da: ieri.year() +"-"+ ("0" + (ieri.month() + 1)).toString().slice(-2) + "-" + ieri.date(),
          data_a: oggi.year() +"-"+ ("0" + (oggi.month() + 1)).toString().slice(-2) + "-" + oggi.date(),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Svuoto l'array che contiene i dati dell'avanzamento
        this.items_tempi = [];

        // Popolo l'array che contiene i dati totali
        res.data.forEach((elem) => {
          this.items_tempi.push(elem);
        });
      });
    },
  },
  mounted() {
    this.getAvanzamentoTempi();
    document
      .getElementById("listener_tempi_di_lavorazione")
      .addEventListener("click_start", this.azionataMacchina);
    
    document
      .getElementById("listener_tempi_di_lavorazione")
      .addEventListener("click_stop", this.fermataMacchina);
  },

  watch: {
    contatore: {
      immediate: true,
      handler() {
        this.getAvanzamentoTempi();
      },
    },
  },
};
</script>
<style>
</style>