<template>
  <div style="padding: 20px">
    <!-- Dialog to confirm deletion of single pezzi -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogdeletepezziconfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"> Cancellazione </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di di voler eliminare questo elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="dialogdeletepezziconfirmation = false"
            >ANNULLA</v-btn
          >
          <v-btn color="success" dark @click="delPezzi">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to update Pezzi Object -->
    <v-dialog v-model="update_pezzi_dialog" width="800">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Modifica Pezzi</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="AVANZAMENTO"
            idOggetto="SALVA_PEZZI"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="salvaPezzi"
          />
          <v-spacer></v-spacer>
          <v-btn light small @click="update_pezzi_dialog = false">
            <v-icon color="error"> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="2">
                  <TextField
                    :readonly="true"
                    idForm="C_PEZZI"
                    idOggetto="PEZZI_RIFERIMENTO"
                    label_input="Riferimento"
                    palceholder_input="Riferimento"
                    :vmodel="riferimento"
                    v-on:changeText="(val) => (this.riferimento = val)"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :readonly="true"
                    idForm="C_PEZZI"
                    idOggetto="PEZZI_COD_COMMESSA"
                    label_input="Codice Commessa"
                    palceholder_input="Codice Commessa"
                    :vmodel="cod_commessa"
                    v-on:changeText="(val) => (this.cod_commessa = val)"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :readonly="true"
                    idForm="C_PEZZI"
                    idOggetto="PEZZI_COD_ARTICOLO"
                    label_input="Codice Articolo"
                    palceholder_input="Codice Articolo"
                    :vmodel="cod_articolo"
                    v-on:changeText="(val) => (this.cod_articolo = val)"
                  />
                </v-col>
                <v-col cols="2">
                  <TextField
                    :readonly="true"
                    idForm="C_PEZZI"
                    idOggetto="PEZZI_COD_FASE"
                    label_input="Codice Fase"
                    palceholder_input="Codice Fase"
                    :vmodel="cod_fase"
                    v-on:changeText="(val) => (this.cod_fase = val)"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :readonly="true"
                    idForm="C_PEZZI"
                    idOggetto="PEZZI_DES_FASE"
                    label_input="Des. Fase"
                    palceholder_input="Des. Fase"
                    :vmodel="des_fase"
                    v-on:changeText="(val) => (this.des_fase = val)"
                  />
                </v-col>
                <v-col cols="3">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate"
                        label="Data"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        class="mt-6"
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data"
                      @input="menu3 = false"
                      locale="it"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <TextField
                    :readonly="true"
                    idForm="C_PEZZI"
                    idOggetto="PEZZI_QUANTITA"
                    label_input="Quantità"
                    palceholder_input="Quantità"
                    :vmodel="quantita"
                    v-on:changeText="(val) => (this.quantita = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="note" placeholder="Note" label="Note" outlined>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar rounede dense class="white--text" color="indigo" flat>
        <v-toolbar-title>Pezzi</v-toolbar-title>
        <v-spacer></v-spacer>
        <h3>Analisi Attuale: {{ analisi }}</h3>
        <Button
          classe_bottone="ml-5 mr-5"
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="PEZZI_ANALISI_SINTETICA"
          mdi_icona="mdi-table"
          testo_tooltip="Analisi Sintetica"
          v-on:buttonClick="getAvanzamentoPezzi('s')"
        />
        <Button
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="PEZZI_ANALISI_DETTAGLIAATA"
          mdi_icona="mdi-table-search"
          testo_tooltip="Analisi Dettagliata"
          v-on:buttonClick="getAvanzamentoPezzi('d')"
        />
        <v-spacer></v-spacer>
        <Button
          classe_bottone="mr-5"
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="PEZZI_FILTRA"
          mdi_icona="mdi-filter"
          testo_tooltip="FILTRA"
          v-on:buttonClick="filtra_menu_visible = !filtra_menu_visible"
        />
        <Button
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="PEZZI_RIPRISTINA"
          mdi_icona="mdi-filter-off"
          testo_tooltip="Ripristina"
          v-on:buttonClick="ripristina"
        />
        <v-spacer></v-spacer>
        <v-btn @click="clickChiudi" color="blue-grey lighten-5" small>
          <v-icon color="error">mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5" v-if="filtra_menu_visible">
        <v-row align="center" justify="center">
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="riferimento"
              item-value="riferimento"
              label="Rif. Ordine"
              style="display: inline-block; max-width: 350px"
              v-model="riferimento_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="riferimento_search = ''" color="primary">{{
                  riferimento_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="cod_articolo"
              item-value="cod_articolo"
              label="Cod. Articolo"
              style="display: inline-block; max-width: 350px"
              v-model="cod_articolo_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="cod_articolo_search = ''" color="primary">{{
                  cod_articolo_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="cod_commessa"
              item-value="cod_commessa"
              label="Cod Commessa"
              style="display: inline-block; max-width: 350px"
              v-model="cod_commessa_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="cod_commessa_search = ''" color="primary">{{
                  cod_commessa_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="des_macchina"
              item-value="des_macchina"
              label="Cod. Macchina"
              style="display: inline-block; max-width: 350px"
              v-model="cod_macchina_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="cod_macchina_search = ''" color="primary">{{
                  cod_macchina_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="nominativo"
              item-value="nominativo"
              label="Nome Operatore"
              style="display: inline-block; max-width: 350px"
              v-model="nome_operatore_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="nome_operatore_search = ''"
                  color="primary"
                  >{{
                    nome_operatore_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="_data"
              item-value="_data"
              label="Data"
              style="display: inline-block; max-width: 350px"
              v-model="dalla_data_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="dalla_data_search = ''" color="primary">{{
                  dalla_data_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="quantita"
              item-value="quantita"
              label="Quantita"
              style="display: inline-block; max-width: 350px"
              v-model="qualita_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="qualita_search = ''" color="primary">{{
                  qualita_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="items_pezzi[0].precent_residua" cols="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_pezzi_visualizzati"
              item-text="precent_residua"
              item-value="precent_residua"
              label="Avanzament %"
              style="display: inline-block; max-width: 350px"
              v-model="perc_avanzamento_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="perc_avanzamento_search = ''"
                  color="primary"
                  >{{
                    perc_avanzamento_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="header_pezzi"
        :items="items_pezzi_visualizzati"
        class="elevation-1"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <Button
            colore_icona="primary"
            idForm="AVANZAMENTO"
            idOggetto="MODIFICA_PEZZI"
            mdi_icona="mdi-pencil"
            testo_tooltip="Aggiornare"
            v-on:buttonClick="handleClick(item)"
          />
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="float: left"
                @click="clickNote(item)"
                small
                v-if="item.note != ''"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-clipboard-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>Visualizza Note</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <Button
            colore_icona="error"
            idForm="AVANZAMENTO"
            idOggetto="ELIMINA_PEZZI"
            mdi_icona="mdi-window-close"
            testo_tooltip="Cancellare"
            v-on:buttonClick="seletedPezzi(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <!-- Dialog delle note -->
    <v-dialog v-model="note_dialog_visible" width="75%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Annotazioni</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="note_dialog_visible = false" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-5" style="text-align: left; overflow-y: auto">
          {{ note_tmp }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loader -->
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import moment from "moment/moment";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField";
export default {
  name: "TabellaPezzi",
  components: { Button, TextField },
  props: {},
  data() {
    return {
      //  Pezzi object Variable
      riferimento: "",
      cod_commessa: "",
      cod_articolo: "",
      cod_fase: "",
      des_fase: "",
      data: "",
      quantita: null,
      qta_commessa: null,
      qta_residua: null,
      precent_residua: "",
      selectedItem: {},
      // Update Dialog Variable
      update_pezzi_dialog: false,
      dialogdeletepezziconfirmation: false,
      menu3: false,
      // Variabili d'appoggio
      analisi: "",
      cod_articolo_search: "",
      cod_commessa_search: "",
      cod_macchina_search: "",
      dalla_data_search: "",
      // dalla_data_search:"",
      qualita_search: "",
      data_da: "",
      note: "",
      data_a: "",
      filtra_menu_visible: false,
      nome_operatore_search: "",
      note_tmp: "",
      riferimento_search: "",
      perc_avanzamento_search: null,
      // Modal
      caricamento_dati: false,
      menu1: null,
      menu2: null,
      note_dialog_visible: false,
      // Array
      header_pezzi: [],
      header_pezzi_sintetico: [
        {
          text: "Rif. Ordine",
          sortable: false,
          value: "riferimento",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Des. Fase",
          sortable: false,
          value: "des_fase",
        },
        {
          text: "Data",
          sortable: false,
          value: "_data",
        },
        {
          text: "Qtà Prodotta",
          sortable: false,
          value: "quantita",
        },
        {
          text: "Qtà Da Produrre",
          sortable: false,
          value: "qta_commessa",
        },
        {
          text: "Qtà Rimanente",
          sortable: false,
          value: "qta_residua",
        },
        {
          text: "Avanzamento %",
          sortable: false,
          value: "precent_residua",
        },
      ],
      header_pezzi_dettagliato: [
        { text: "Note", sortable: false, value: "note" },
        {
          text: "Rif. Ordine",
          sortable: false,
          value: "riferimento",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Des. Fase",
          sortable: false,
          value: "des_fase",
        },
        {
          text: "Data",
          sortable: false,
          value: "_data",
        },
        {
          text: "Data Dichiarazione",
          sortable: false,
          value: "_ora",
        },
        {
          text: "Nome",
          sortable: false,
          value: "nominativo",
        },
        {
          text: "Des. Macchina",
          sortable: false,
          value: "des_macchina",
        },
        {
          text: "Quantità",
          sortable: false,
          value: "quantita",
        },
      ],
      items_pezzi: [],
      items_pezzi_visualizzati: [],
    };
  },
  methods: {
    clickNote(item) {
      this.note_dialog_visible = true;
      this.note_tmp = item.note;
    },
    delPezzi() {
      let i = this.items_pezzi.findIndex((it) => it == this.selectedItem);
      this.items_pezzi.splice(i, 1);
      this.dialogdeletepezziconfirmation = false;
    },
    // function to select the pezzo for deletion
    seletedPezzi(item) {
      this.selectedItem = item;
      this.dialogdeletepezziconfirmation = true;
    },
    // Function in order to Populate Modal form with selected pezzi item detail
    handleClick(item) {
      this.selectedItem = item;
      // console.log(`item._data`, item._data)
      this.riferimento = item.riferimento;
      this.cod_commessa = item.cod_commessa;
      this.cod_articolo = item.cod_articolo;
      this.cod_fase = item.cod_fase;
      this.des_fase = item.des_fase;
      this.data = moment(item._data, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
      // console.log(`this.data`, this.data)
      this.quantita = item.quantita;
      this.qta_commessa = item.qta_commessa;
      this.qta_residua = item.qta_residua;
      this.precent_residua = item.precent_residua;
      this.note = item.note;
      this.update_pezzi_dialog = true;
    },
    // Function to Update Pezzi
    salvaPezzi() {
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "updatecommessaquantita",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          db_name: "quickproduction",
          id_movimento: this.selectedItem.id_movimento,
          data: this.data,
          id_macchina: this.selectedItem.id_macchina,
          quantita: this.quantita,
          saldo_fase:
            this.selectedItem.saldo_fase == undefined ? 0 : this.selectedItem.saldo_fase,
          validita: 1,
          id_causale: null,
          note: this.note,
        },
      };
      this.$store.dispatch("api", request).then(() => {
        this.update_pezzi_dialog = false;
        this.selectedItem = null;
        this.getAvanzamentoPezzi("d");
      });
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    filtra() {
      let items_pezzi_tmp = [];
      this.items_pezzi.forEach((elem) => {
        items_pezzi_tmp.push(elem);
      });
      // Filtro il riferimento ordine
      if (this.riferimento_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_pezzi_tmp.forEach((elem) => {
          if (elem.riferimento == this.riferimento_search) {
            res.push(elem);
          }
        });
        // Modello la risposta provvisoria su "res".
        items_pezzi_tmp = res;
      }
      // Filtro il cod_articolo
      if (this.cod_articolo_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_articolo == this.cod_articolo_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      //Filtro il codice Commessa
      if (this.cod_commessa_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_commessa == this.cod_commessa_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro il codice macchina
      if (this.cod_macchina_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.des_macchina == this.cod_macchina_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro il nome dell'operatore
      if (this.nome_operatore_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.nominativo == this.nome_operatore_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la percentuale di avanzamento
      if (this.perc_avanzamento_search != null) {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.precent_residua === this.perc_avanzamento_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la data_da
      if (this.dalla_data_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem._data == this.dalla_data_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la data_a
      if (this.data_a != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.data_js.isSameOrBefore(this.data_a)) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la qualita
      if (this.qualita_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.quantita == this.qualita_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      this.items_pezzi_visualizzati = [];
      this.items_pezzi_visualizzati = items_pezzi_tmp;
    },
    getAvanzamentoPezzi(param) {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessaavanzamento",
          token: localStorage.getItem("user_token"),
          tipo: "p",
          analisi: param,
        },
      };
      this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Definisco il tipo di analisi corrente in base alla chiamata
        this.analisi = param == "s" ? "sintetica" : "dettagliata";
        // Svuoto l'array che contiene i dati dell'avanzamento
        this.items_pezzi = [];
        this.items_pezzi = res.data;

        // Svuoto lo header dei e lo ripopolo a seconda dell'analisi
        if (param == "s") {
          this.header_pezzi = [];
          this.header_pezzi_sintetico.forEach((elem) => {
            this.header_pezzi.push(elem);
          });
        } else if (param == "d") {
          this.header_pezzi = [];
          this.header_pezzi_dettagliato.forEach((elem) => {
            this.header_pezzi.push(elem);
          });
          let data2 = {
            value: "edit",
            sortable: false,
          };
          this.header_pezzi.unshift(data2);
          let data1 = {
            value: "delete",
            sortable: false,
          };
          this.header_pezzi.push(data1);
        }
        this.caricamento_dati = false;
      });
    },
    ripristina() {
      // Resetto i valori dei filtri
      this.riferimento_search = "";
      this.cod_articolo_search = "";
      this.cod_macchina_search = "";
      this.nome_operatore_search = "";
      this.data_da = "";
      this.data_a = "";
      this.qualita_search = "";
      // Resetto i valori della tabella
      this.items_pezzi_visualizzati = [];
      this.items_pezzi.forEach((elem) => {
        this.items_pezzi_visualizzati.push(elem);
      });
    },
  },
  mounted() {
    this.getAvanzamentoPezzi("s");
  },
  computed: {
    formatDate() {
      return this.data ? moment(this.data).format("L") : "";
    },
  },
  watch: {
    items_pezzi: {
      immediate: true,
      deep: true,
      handler() {
        this.items_pezzi_visualizzati = [];
        this.items_pezzi.forEach((elem) => {
          elem.data_js = moment(
            elem._data.substring(6, 10) +
              "-" +
              elem._data.substring(3, 5) +
              "-" +
              elem._data.substring(0, 2)
          );
          // Aggiungo gli items ai pezzi visualizzati
          this.items_pezzi_visualizzati.push(elem);
        });
        // Eseguo un filtro, caso mai l'utente ne abbia messo uno.
        this.filtra();
      },
    },
  },
};
</script>
<style scoped></style>
