<template>
  <v-card class="ma-0 pa-0">
    <v-data-table
      :headers="headers"
      :items="
        questionari.filter((e) => e.validita === 1 && e.id_articolo === null)
      "
      item-key="nome"
      class="elevation-2"
      height="74vh"
      fixed-header
    >
      <template v-slot:top>
        <v-toolbar color="indigo" dense fixed style="z-index: 3">
          <v-toolbar-title class="white--text">Lista Controlli</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Aggiungi commessa -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="aggiungiNuovoControllo()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary"> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Elemento</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <!-- Chiudi Diario -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="goBack" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <!-- Bottone di modifica -->
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickModificaControllo(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Modifica</span>
        </v-tooltip>
      </template>

      <!-- Bottone per nascondere la checklist -->
      <template v-slot:[`item.elimina`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickEliminaQuestionario(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Elimina</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.date_ins`]="{ item }">
        {{
          item.date_ins && item.date_ins.length > 0
            ? `${item.date_ins.substring(8, 10)}/${item.date_ins.substring(
                5,
                7
              )}/${item.date_ins.substring(0, 4)}`
            : "N/A"
        }}
      </template>
    </v-data-table>

    <!-- Dialog di configurazione del questionario -->
    <v-dialog v-model="specifiche_controllo">
      <ListaSpecifiche
        @click_chiudi="chiudiModificaControllo"
        :des_domanda_descrittiva="des_domanda_descrittiva"
        :des_domanda_immagine="des_domanda_immagine"
        :des_domanda_polare="des_domanda_polare"
        @eliminazione_effettuata="handleEliminazioneEffetuata"
        :id_domanda_descrittiva="id_domanda_descrittiva"
        :id_domanda_immagine="id_domanda_immagine"
        :id_domanda_polare="id_domanda_polare"
        :key="key_lista_specifiche"
        :questionario_sel="questionario_sel"
        @salvato_questionario="handleSalvatoQuestionario"
      />
    </v-dialog>

    <v-dialog v-model="conferma_eliminazione_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi l'eliminazione del Questionario di Collaudo selezionato?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_eliminazione_dialog = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="eliminaQuestionario"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>

<script>
import ListaSpecifiche from "@/components/ELECTRICALMARINE/ListaSpecifiche.vue";
import Questionario from "@/classes/EMQuestionario.js";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "Checklist",
  components: {
    ListaSpecifiche,
    SnackbarQV,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio

      // Modal bool
      conferma_eliminazione_dialog: false,
      des_domanda_descrittiva: "",
      des_domanda_immagine: "",
      des_domanda_polare: "",
      id_domanda_descrittiva: null,
      id_domanda_immagine: null,
      id_domanda_polare: null,
      key_lista_specifiche: 0,
      specifiche_controllo: false,
      questionario_sel: new Questionario(),

      // Array
      questionari: [],
      headers: [
        {
          text: "",
          value: "modifica",
          sortable: false,
        },
        {
          text: "Codice",
          value: "cod_checklist",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_checklist",
          sortable: false,
        },
        {
          text: "Mercato",
          value: "des_mercato",
          sortable: false,
        },
        {
          text: "Motori",
          value: "des_motori",
          sortable: false,
        },
        {
          text: "Data Creazione",
          value: "date_ins",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudiModificaControllo() {
      this.specifiche_controllo = false;
    },
    chiudiDialog() {},
    clickEliminaQuestionario(arg) {
      // Registro il questionario che ho cliccato
      this.questionario_sel = new Questionario(arg);

      // Faccio uscire un dialog di conferma
      this.conferma_eliminazione_dialog = true;
    },
    aggiungiNuovoControllo() {
      this.questionario_sel = new Questionario();
      this.questionario_sel.id_utente = localStorage.id;
      this.specifiche_controllo = true;
    },
    clickModificaControllo(item) {
      this.questionario_sel = new Questionario(item);
      this.specifiche_controllo = true;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getchecklist",
          token: localStorage.user_token,
          id_articolo: null,
          id_checklist: item.id_checklist ? item.id_checklist : 2,
        },
      };
      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.questionario_sel = Object.assign({}, res.data.checklist[0]);
            this.questionario_sel.aree = Array.from(res.data.aree);
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    eliminaQuestionario() {
      // Faccio un oggetto per il salvataggio con validità a 0
      const questionario_save = new Questionario(this.questionario_sel);
      questionario_save.validita = 0;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setchecklist",
          token: localStorage.user_token,
          checklist: [questionario_save],
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            /* Se la chiamata di eliminazione è ok,
             * ricarico i questionari aggiornati.*/
            this.conferma_eliminazione_dialog = false;
            this.getChecklist();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getChecklist() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getchecklist",
          token: localStorage.user_token,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.questionari = Array.from(res.data.checklist);
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Errore tecnico con codice ${res.status}`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    getTabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "EM_TIPO_DOMANDA",
          filter: "validita = 1",
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            res.data.forEach((e) => {
              if (e.cod === "POLARE") {
                this.des_domanda_polare = e.des;
                this.id_domanda_polare = e.id_tabella;
              }
              if (e.cod === "K") {
                this.des_domanda_descrittiva = e.des;
                this.id_domanda_descrittiva = e.id_tabella;
              }
              if (e.cod === "IMG") {
                this.des_domanda_immagine = e.des;
                this.id_domanda_immagine = e.id_tabella;
              }
            });
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità rilevata. Stato: ${res.status}`
            );
          }
        })
        .catch((e) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(e.response.data.message).result}.`
          );
        });
    },
    goBack() {
      this.$router.push("/home/electrical");
    },
    async handleEliminazioneEffetuata() {
      /* A seguito di un'eliminazione, ricarico i dati
       * del questionario selezionato per aggiornare il
       * dialog di configurazione. */
      await this.clickModificaControllo(this.questionario_sel);

      /* Una volta che ho aggiornato i dati, forzo la
       * renderizzazione del componente per mostrare il cambiamento. */
      this.key_lista_specifiche = this.key_lista_specifiche === 1 ? 0 : 1;
    },
    handleSalvatoQuestionario() {
      this.specifiche_controllo = false;
      this.$refs.SnackbarQV.print(
        "success",
        `Documento di collaudo aggiornato con successo.`
      );
      this.getChecklist();
    },
  },
  created() {},
  mounted() {
    /* Faccio una gettabelle per
     * riconoscere i tipi di domande. */
    this.getTabelle();
    
    // Prendo i dati della checklist
    this.getChecklist();
  },
};
</script>
<style scoped>
</style> 
