import moment from "moment/moment";

/* Questa classe fornisce l'oggetto di riferimento
 * per le Richieste di Manutenzione all'interno del modulo
 * Manutenzione per EduCatt e altri. */
export default class Request {
    constructor(arg) {
        if (arg != undefined) {
            this.allegati = arg.allegati != undefined ? arg.allegati : [];
            this.cod_richiesta = arg.cod_richiesta != undefined ? arg.cod_richiesta : "";
            this.cod_richiesta_custom = arg.cod_richiesta_custom != undefined ? arg.cod_richiesta_custom : "";
            this.data_richiesta = arg.data_richiesta != undefined ? arg.data_richiesta : moment().format("DD/MM/YYYY");
            this.des_area = arg.des_area != undefined ? arg.des_area : "";
            this.descrizione = arg.descrizione != undefined ? arg.descrizione : "";
            this.des_macchinario = arg.des_macchinario != undefined ? arg.des_macchinario : "";
            this.des_richiedente = arg.des_richiedente != undefined ? arg.des_richiedente : "";
            this.des_sede = arg.des_sede != undefined ? arg.des_sede : "";
            this.des_stato = arg.des_stato != undefined ? arg.des_stato : "";
            this.des_struttura = arg.des_struttura != undefined ? arg.des_struttura : "";
            this.destinatari = arg.destinatari != undefined ? arg.destinatari : [];
            this.des_urgenza = arg.des_urgenza != undefined ? arg.des_urgenza : "";
            this.des_zona = arg.des_zona != undefined ? arg.des_zona : "";
            this.email = arg.email != undefined ? arg.email : "";
            this.id_area = arg.id_area != undefined ? arg.id_area : null;
            this.id_macchinario = arg.id_macchinario != undefined ? arg.id_macchinario : null;
            this.id_richiesta = arg.id_richiesta != undefined ? arg.id_richiesta : -1;
            this.id_sede = arg.id_sede != undefined ? arg.id_sede : null;
            this.id_stato = arg.id_stato != undefined ? arg.id_stato : null;
            this.id_stato_2 = arg.id_stato_2 != undefined ? arg.id_stato_2 : null;
            this.id_stanza = arg.id_stanza != undefined ? arg.id_stanza : null;
            this.id_struttura = arg.id_struttura != undefined ? arg.id_struttura : null;
            this.id_urgenza = arg.id_urgenza != undefined ? arg.id_urgenza : null;
            this.id_zona = arg.id_zona != undefined ? arg.id_zona : null;
            this.interventi = arg.interventi != undefined ? arg.interventi : [];
            this.qta_tempo_effettivo = arg.qta_tempo_effettivo != undefined ? arg.qta_tempo_effettivo : 0;
            this.qta_tempo_stimato = arg.qta_tempo_stimato != undefined ? arg.qta_tempo_stimato : 0;
            this.soluzione = arg.soluzione != undefined ? arg.soluzione : "";
            this.tipo_richiesta = arg.tipo_richiesta != undefined ? arg.tipo_richiesta : null;
            this.um_tempo_effettivo = arg.um_tempo_effettivo != undefined ? arg.um_tempo_effettivo : null;
            this.um_tempo_stimato = arg.um_tempo_stimato != undefined ? arg.um_tempo_stimato : null;
            this.modello = arg.modello != undefined ? arg.modello : "";
            this.validita = arg.validita != undefined ? arg.validita : 1;
        } else {
            this.allegati = [];
            this.cod_richiesta = "";
            this.data_richiesta = moment().format("DD/MM/YYYY");
            this.des_area = "";
            this.descrizione = "";
            this.des_macchinario = "";
            this.des_richiedente = "";
            this.des_sede = "";
            this.des_stato = "";
            this.des_struttura = "";
            this.des_urgenza = "";
            this.destinatari = [];
            this.des_zona = "";
            this.email = false;
            this.id_area = null;
            this.id_macchinario = null;
            this.id_richiesta = -1;
            this.id_sede = null;
            this.id_stanza = null,
            this.id_stato = null;
            this.id_stato_2 = null;
            this.id_struttura = null;
            this.id_urgenza = null;
            this.id_zona = null;
            this.interventi = [];
            this.modello = "";
            this.qta_tempo_effettivo = 0;
            this.qta_tempo_stimato = 0;
            this.soluzione = "";
            this.tipo_richiesta = null;
            this.um_tempo_effettivo = null;
            this.um_tempo_stimato = null;
            this.validita = 1;
        }
    }
}