<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Registrazione Pezzi Multicommessa
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="registraMulticommessa" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-check-all </v-icon>
          </v-btn>
        </template>
        <span>Salva Registrazione Multicommessa</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickIndietro" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text style="overflow-y: scroll">
      <v-card
        class="mt-5"
        v-for="(e, i) in items_wo_multi_reg_pezzi_v"
        :key="i"
      >
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            <span class="mr-2">Articolo {{ e.cod_articolo }},</span>
            <span class="mr-2">Commessa {{ e.cod_commessa }},</span>
            <span class="mr-2">Fase {{ e.des_fase }}</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="end" justify="center">
              <v-col cols="3">
                <v-text-field
                  background-color="#ffffff"
                  dense
                  label="Quantità"
                  type="number"
                  v-model="e.qta_reg_multi"
                >
                </v-text-field>
              </v-col>
              <v-col cols="9">
                <v-textarea
                  background-color="#ffffff"
                  dense
                  hide-details
                  label="Note Registrazione"
                  outlined
                  style="border-radius: 0px"
                  v-model="e.note_reg_multi"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "RegistraPezziMulti",
  components: {},
  props: {
    items_wo_multi_reg_pezzi: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
      items_wo_multi_reg_pezzi_v: [],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.items_wo_multi_reg_pezzi.forEach((w) => {
            w.note_reg_multi = "";
            w.qta_reg_multi = w.quantita;
            this.items_wo_multi_reg_pezzi_v.push(w);
          });

          this.items_wo_multi_reg_pezzi_v.forEach((w) => {
            this.getCommessaRegistrazione(w);
          });
        }
      },
    },
  },
  methods: {
    clickIndietro() {
      this.$emit("click_indietro");
    },
    getCommessaRegistrazione(arg) {
      let getcommessaregistrazione = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessaregistrazione",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_commessa_dettaglio: arg.id_commessa_dettaglio,
          db_name: "quickproduction",
        },
      };

      this.$store.dispatch("api", getcommessaregistrazione).then((res) => {
        // Azzero l'array delle causali
        arg.causali_reg_multi = [];

        // Inserisco le causali che mi dice il backend
        res.data.forEach((elem) => {
          arg.causali_reg_multi.push(elem);
        });
      });
    },
    async registraMulticommessa() {
      let c_res = 0;

      for (let i of this.items_wo_multi_reg_pezzi_v) {
        c_res += await this.setCommessaRegistrazione(i);

        if (c_res === this.items_wo_multi_reg_pezzi_v) {
          this.$emit("registrati_pezzi");
        }
      }
    },
    setCommessaRegistrazione(arg) {
      // Modifico le causali per comodità di BE
      arg.causali_reg_multi.forEach((elem) => {
        elem.id_causali_produzione = -1;
        elem.id_causali_movimenti = -1;
        elem.id_commessa_dettaglio = arg.id_commessa_dettaglio;
        elem.pezzi = elem.pezzi === null ? 0 : elem.pezzi;
      });

      // Preparo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessaregistrazione",
          token: localStorage.getItem("user_token"),
          id_commessa: arg.id_commessa,
          id_commessa_dettaglio: arg.id_commessa_dettaglio,
          id_macchina: arg.id_macchina,
          flag_fase_ultima: arg.flag_fase_ultima,
          id_articolo: arg.id_articolo,
          causali: arg.causali_reg_multi,
          note: arg.note_reg_multi,
          quantita: arg.qta_reg_multi,
          chiudi_workorder:
            parseInt(arg.qta_reg_multi) + parseInt(arg.pezzi_registrati) <
            parseInt(arg.quantita)
              ? 0
              : 1,
        },
      };

      this.$store.dispatch("api", request).then(() => {
        return 1;
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>