 <template>
  <div>
    <v-card class="text-center" align="center">
      <v-toolbar dark color="blue-grey darken-2" dense>
        <v-btn color="error" text dark fab @click="chiudiDialog" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>GESTIONE GRUPPI</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items>
        <v-btn dark text @click="updateValue">Salva</v-btn>
        </v-toolbar-items>-->
      </v-toolbar>
      <v-card-text>
        <v-row>
          <!-----------------------------------------------------------------WIDGET---LATERALE----------------------------------------------------------------------->
          <v-col md="12" sm="12" style="min-height: 300px">
            <v-card class="mx-auto" color>
              <v-toolbar color="success" dark dense>
                <v-autocomplete
                  v-model="gruppo_sel"
                  filled
                  dense
                  rounded
                  chips
                  small-chips
                  :items="gruppi_esistenti.t_gruppo"
                  item-text="des_gruppo"
                  hide-details
                  label="Filtra"
                ></v-autocomplete>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn color="white" icon dark v-on="on">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="aggiungiGruppo()">
                      <v-list-item-title>Aggiungi Gruppo</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>

              <v-dialog v-model="popup" persistent width="500">
                <v-card>
                  <v-card-title
                    class="headline red mb-3"
                    style="color: white"
                    primary-title
                    >Attenzione !</v-card-title
                  >

                  <v-card-text
                    >Sei sicuro di voler eliminare il gruppo
                    selezionato?</v-card-text
                  >

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      dark
                      @click="(popup = false), resetValidita()"
                      >Indietro</v-btn
                    >
                    <v-btn
                      color="error"
                      dark
                      @click="(popup = false), (check = true), deleteGruppo()"
                      >Cancella</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-progress-circular
                v-if="loader_gruppi == true"
                indeterminate
                color="primary"
                style="min-height: 300px"
              ></v-progress-circular>
              <v-virtual-scroll
                v-else
                :items="gruppi_visualizzati"
                :item-height="50"
                style="max-height: 300px; overflow-y: auto"
                height="300"
              >
                <template v-slot="{ item }">
                  <v-list-item>
                    <v-list-item-icon class="mr-3" style="cursor: pointer">
                      <!--@click="deleteGruppo(item)"-->
                      <v-icon
                        color="red"
                        @click="(check = false), detailDeletegruppo(item)"
                        >mdi-delete</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-icon class="mr-3" style="cursor: pointer">
                      <!--@click="deleteGruppo(item)"-->

                      <v-icon color="grey" @click="dialogModgruppo(item)"
                        >mdi-cog</v-icon
                      >
                    </v-list-item-icon>

                    <v-list-item-content
                      @click="dialog_visualizza_gruppo = true"
                      style="cursor: pointer"
                    >
                      <v-list-item-subtitle @click="detailGruppo(item)">{{
                        item.des_gruppo
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>

              <!-- <v-list style="max-height:300px;overflow-y:auto">
                <v-list-item v-for="(item,index) in gruppi_esistenti.t_gruppo" :key="'b'+index">
                  <v-list-item-icon class="mr-3" style="cursor:pointer">
                    <v-icon color="red" @click="check=false ,detailDeletegruppo(item) ">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon class="mr-3" style="cursor:pointer">

                    <v-icon color="grey" @click="dialogModgruppo(item)">mdi-cog</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    @click="dialog_visualizza_gruppo=true"
                    style="cursor:pointer"
                  >
                    <v-list-item-subtitle @click="detailGruppo(item)">{{item.des_gruppo}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>-->
            </v-card>
          </v-col>
          <v-dialog
            v-model="dialog_mod_gruppo"
            max-width="60%"
            scrollable
            persistent
          >
            <v-card>
              <v-card-title>Modifica gruppo</v-card-title>
              <v-card-text>
                <v-card flat>
                  <v-card-text style="height: 60%">
                    <v-row>
                      <v-col md="3"></v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="nome_gruppo"
                          label="Nome Gruppo"
                          autofocus
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col md="3"></v-col>
                    </v-row>
                    <!-- <v-row>
                      <div
                        style="min-height:100px; border-top:1px solid grey; border-left:1px solid grey; border-bottom:1px solid grey; border-radius:5px 0px 0px 5px;"
                        class="col-6"
                      >
                        <h3 class="text-center" style="margin-bottom:50px;">Utenti:</h3>
                        <div class="d-flex flex-row">
                          <v-text-field
                            dense
                            class="rounded-xl mt-2 mb-2"
                            flat
                            hide-details
                            label=" Cerca"
                            v-model="cerca_utenti"
                            prepend-inner-icon="search"
                            v-on:keyup="filtraUtenti"
                            solo-inverted
                            style="max-width:350px"
                          ></v-text-field>
                          <v-btn small fab text icon class="mt-2" @click="filtraUtentiCerca()">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </div>

                        <draggable
                          style="min-height:700px; cursor:grabbing!important;"
                          class="list-group"
                          v-model="utenti_iniziali"
                          group="people"
                        >
                          <transition-group>
                            <v-card
                              v-for="(item,index)  in utenti_iniziali"
                              class="mt-1 mb-1 pl-5 pt-2 pb-1 d-flex flex-row"
                              :key="'c'+index"
                              elevation="2"
                            >
                              <span class>{{ item.nominativo }}</span>
                            </v-card>
                          </transition-group>
                        </draggable>
                      </div>

                      <div
                        style="min-height:100px; border-top:1px solid grey; border-right:1px solid grey; border-bottom:1px solid grey; border-radius:0px 5px 5px 0px;"
                        class="col-6"
                      >
                        <h3 class="text-center" style="margin-bottom:50px;">Utenti gruppo:</h3>
                        <draggable
                          style="min-height:700px; margin-top:95px;  cursor:grabbing!important;"
                          class="list-group"
                          v-model="utenti_partecipanti"
                          group="people"
                          @change="rimuoviUtenteGruppo"
                        >
                          <v-card
                            id="utenti"
                            class="mt-1 mb-1 pl-5 pt-0 pb-0 d-flex flex-row"
                            v-for="(item,index)  in utenti_partecipanti"
                            :key="'d'+index"
                            elevation="2"
                          >
                            <v-checkbox
                              :messages="item.preposto==true?'preposto':''"
                              :key="item.preposto"
                              small
                              class="pa-0 ma-0 mt-2 mb-1"
                              v-model="item.preposto"
                              :label="item.nominativo"
                              :value="1"
                              :hide-details="true"
                            >
                              <template v-slot:item.messages="{  }">
                                <span>asd</span>
                              </template>
                            </v-checkbox>
                          </v-card>
                        </draggable>
                      </div>
                    </v-row>-->
                    <v-row>
                      <div
                        style="
                          min-height: 100px;
                          border-top: 1px solid grey;
                          border-left: 1px solid grey;
                          border-bottom: 1px solid grey;
                          border-radius: 5px 0px 0px 5px;
                        "
                        class="col-6"
                      >
                        <h3 class="text-center" style="margin-bottom: 5px">
                          Utenti:
                        </h3>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Cerca utente"
                              single-line
                              hide-details
                              filled
                              rounded
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <v-btn color="primary" @click="spostaUtenti">
                              Sposta selezionati
                              <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-data-table
                          v-model="utenti_iniziali_sel"
                          :headers="headers_utenti"
                          :items="utenti_iniziali"
                          :single-select="false"
                          :search="search"
                          item-key="id_personale"
                          show-select
                          class="elevation-1"
                          dense
                          height="400"
                        >
                          <template v-slot:top></template>
                        </v-data-table>
                        <!-- 
                      <div class="d-flex flex-row">
                        <v-text-field
                          dense
                          class="rounded-xl mt-2 mb-2"
                          flat
                          hide-details
                          label=" Cerca"
                          v-model="cerca_utenti"
                          prepend-inner-icon="search"
                          v-on:keyup="filtraUtenti"
                          solo-inverted
                          style="max-width:350px"
                        ></v-text-field>
                        <v-btn small fab text icon class="mt-2" @click="filtraUtentiCerca()">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </div>

                      <draggable
                        style="min-height:700px; cursor:grabbing!important;"
                        class="list-group"
                        v-model="utenti_iniziali"
                        group="people"
                      >
                        <transition-group>
                          <v-card
                            v-for="(item,index)  in utenti_iniziali"
                            class="mt-1 mb-1 pl-5 pt-2 pb-1 d-flex flex-row"
                            :key="'c'+index"
                            elevation="2"
                          >
                            <span class>{{ item.nominativo }}</span>
                          </v-card>
                        </transition-group>
                        </draggable>-->
                      </div>

                      <div
                        style="
                          min-height: 100px;
                          border-top: 1px solid grey;
                          border-right: 1px solid grey;
                          border-bottom: 1px solid grey;
                          border-radius: 0px 5px 5px 0px;
                        "
                        class="col-6"
                      >
                        <h3 class="text-center" style="margin-bottom: 5px">
                          Utenti gruppo:
                        </h3>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="search2"
                              append-icon="mdi-magnify"
                              label="Cerca utente"
                              single-line
                              hide-details
                              filled
                              rounded
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <!-- :key="utenti_partecipanti" -->
                        <v-data-table
                          :headers="headers_utenti_sel"
                          :items="utenti_partecipanti"
                          :search="search2"
                          item-key="id_personale"
                          class="elevation-1"
                          dense
                          height="400"
                        >
                          <template v-slot:top>
                            <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
                          </template>
                          <template v-slot:[`item.preposto`]="{ item }">
                            <v-checkbox
                              :messages="
                                item.preposto == true ? 'preposto' : ''
                              "
                              :key="item.preposto"
                              small
                              class="pa-0 ma-0 mt-2 mb-1"
                              v-model="item.preposto"
                              :true-value="1"
                              :false-value="0"
                              :hide-details="true"
                            ></v-checkbox>
                          </template>
                          <template v-slot:[`item.action`]="{ item }">
                            <v-btn
                              text
                              icon
                              color="error"
                              @click="rimuoviUtenteSel(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                        <!-- <draggable
                        style="min-height:700px; margin-top:95px;  cursor:grabbing!important;"
                        class="list-group"
                        v-model="utenti_partecipanti"
                        group="people"
                      >
                        <v-card
                          id="utenti"
                          class="mt-1 mb-1 pl-5 pt-0 pb-0 d-flex flex-row"
                          v-for="(item,index)  in utenti_partecipanti"
                          :key="'d'+index"
                          elevation="2"
                        >
                          <v-checkbox
                            :messages="item.preposto==true?'preposto':''"
                            :key="item.preposto"
                            small
                            class="pa-0 ma-0 mt-2 mb-1"
                            v-model="item.preposto"
                            :label="item.nominativo"
                            :value="1"
                            :hide-details="true"
                          >
                            <template v-slot:item.messages="{  }">
                              <span>asd</span>
                            </template>
                          </v-checkbox>
                        </v-card>
                        </draggable>-->
                      </div>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" dark @click="annulladialogModGruppo()"
                  >Annulla</v-btn
                >
                <v-btn color="success" dark @click="modGruppo(item)"
                  >Conferma</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog_visualizza_gruppo"
            scrollable
            width="500px"
            persistent
          >
            <!-- <template v-slot:activator="{on}">
                    
            </template>-->
            <v-card>
              <v-card-title
                class="headline green"
                style="color: white"
                primary-title
                >Informazioni gruppo</v-card-title
              >

              <v-card-text style="height: 60%">
                <v-row>
                  <v-col md="12">
                    <div>
                      <h4 class="text-center mb-3">Utenti partecipanti:</h4>
                      <v-text-field
                        v-model="search_partecipanti"
                        append-icon="mdi-magnify"
                        label="Cerca utente"
                        single-line
                        hide-details
                        filled
                        rounded
                        dense
                        class="mb-2"
                      ></v-text-field>
                      <!-- :key="utenti_partecipanti" -->
                      <v-data-table
                        :headers="headers_utenti_info"
                        :items="utenti_partecipanti"
                        item-key="id_personale"
                        :search="search_partecipanti"
                        class="elevation-1"
                        dense
                        height="400"
                      >
                        <template v-slot:top>
                          <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
                        </template>
                        <template v-slot:[`item.preposto`]="{ item }">
                          <v-chip
                            class
                            v-if="item.preposto == 1"
                            color="primary"
                            small
                            >Preposto</v-chip
                          >
                        </template>
                      </v-data-table>
                      <!-- <div
                        id="utentigruppo"
                        style="margin: 0 auto; max-width: 250px; border-radius: 5px; max-height: 40px; box-shadow: 0px 0px 3px; line-height: 3; user-select: none; cursor: grab;"
                        class="mb-2 text-center list-group-item"
                        v-for="(item,index)  in utenti_partecipanti"
                        :key="'e'+index"
                      >
                        {{ item.nominativo }} -
                        <span></span>
                      </div>-->
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="svuotaDialogVisualizzaGruppo()"
                  >Indietro</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-dialog
          v-model="dialog_set_gruppo"
          scrollable
          max-width="60%"
          persistent
        >
          <!--v-model="dialog_set_gruppo"-->
          <v-card>
            <v-card-title>Aggiungi Gruppo Utenti</v-card-title>
            <v-card-text>
              <v-card flat>
                <v-card-text style="height: 60%">
                  <v-row>
                    <v-col md="3"></v-col>
                    <v-col md="6">
                      <v-text-field
                        v-model="nome_gruppo"
                        label="Nome Gruppo"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col md="3"></v-col>
                  </v-row>
                  <v-row>
                    <div
                      style="
                        min-height: 100px;
                        border-top: 1px solid grey;
                        border-left: 1px solid grey;
                        border-bottom: 1px solid grey;
                        border-radius: 5px 0px 0px 5px;
                      "
                      class="col-6"
                    >
                      <h3 class="text-center" style="margin-bottom: 5px">
                        Utenti:
                      </h3>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Cerca utente"
                            single-line
                            hide-details
                            filled
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <v-btn color="primary" @click="spostaUtenti">
                            Sposta selezionati
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-data-table
                        v-model="utenti_iniziali_sel"
                        :headers="headers_utenti"
                        :items="utenti_iniziali"
                        :single-select="false"
                        :search="search"
                        item-key="id_personale"
                        show-select
                        class="elevation-1"
                        dense
                        height="400"
                      >
                        <template v-slot:top></template>
                      </v-data-table>
                      <!-- 
                      <div class="d-flex flex-row">
                        <v-text-field
                          dense
                          class="rounded-xl mt-2 mb-2"
                          flat
                          hide-details
                          label=" Cerca"
                          v-model="cerca_utenti"
                          prepend-inner-icon="search"
                          v-on:keyup="filtraUtenti"
                          solo-inverted
                          style="max-width:350px"
                        ></v-text-field>
                        <v-btn small fab text icon class="mt-2" @click="filtraUtentiCerca()">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </div>

                      <draggable
                        style="min-height:700px; cursor:grabbing!important;"
                        class="list-group"
                        v-model="utenti_iniziali"
                        group="people"
                      >
                        <transition-group>
                          <v-card
                            v-for="(item,index)  in utenti_iniziali"
                            class="mt-1 mb-1 pl-5 pt-2 pb-1 d-flex flex-row"
                            :key="'c'+index"
                            elevation="2"
                          >
                            <span class>{{ item.nominativo }}</span>
                          </v-card>
                        </transition-group>
                      </draggable>-->
                    </div>

                    <div
                      style="
                        min-height: 100px;
                        border-top: 1px solid grey;
                        border-right: 1px solid grey;
                        border-bottom: 1px solid grey;
                        border-radius: 0px 5px 5px 0px;
                      "
                      class="col-6"
                    >
                      <h3 class="text-center" style="margin-bottom: 5px">
                        Utenti gruppo:
                      </h3>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="search2"
                            append-icon="mdi-magnify"
                            label="Cerca utente"
                            single-line
                            hide-details
                            filled
                            rounded
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- :key="utenti_partecipanti" -->
                      <v-data-table
                        :headers="headers_utenti_sel"
                        :items="utenti_partecipanti"
                        :search="search2"
                        item-key="id_personale"
                        class="elevation-1"
                        dense
                        height="400"
                      >
                        <template v-slot:top>
                          <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
                        </template>
                        <template v-slot:[`item.preposto`]="{ item }">
                          <v-checkbox
                            :messages="item.preposto == true ? 'preposto' : ''"
                            :key="item.preposto"
                            small
                            class="pa-0 ma-0 mt-2 mb-1"
                            v-model="item.preposto"
                            :value="1"
                            :hide-details="true"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                          <v-btn
                            text
                            icon
                            color="error"
                            @click="rimuoviUtenteSel(item)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                      <!-- <draggable
                        style="min-height:700px; margin-top:95px;  cursor:grabbing!important;"
                        class="list-group"
                        v-model="utenti_partecipanti"
                        group="people"
                      >
                        <v-card
                          id="utenti"
                          class="mt-1 mb-1 pl-5 pt-0 pb-0 d-flex flex-row"
                          v-for="(item,index)  in utenti_partecipanti"
                          :key="'d'+index"
                          elevation="2"
                        >
                          <v-checkbox
                            :messages="item.preposto==true?'preposto':''"
                            :key="item.preposto"
                            small
                            class="pa-0 ma-0 mt-2 mb-1"
                            v-model="item.preposto"
                            :label="item.nominativo"
                            :value="1"
                            :hide-details="true"
                          >
                            <template v-slot:item.messages="{  }">
                              <span>asd</span>
                            </template>
                          </v-checkbox>
                        </v-card>
                      </draggable>-->
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" dark @click="chiudiAggiungigruppo()"
                >Annulla</v-btn
              >
              <v-btn color="success" dark @click="setgruppo(item)"
                >Conferma</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_conferma" max-width="40%" persistent>
          <v-card>
            <v-card-title>{{ titolo_conferma }}</v-card-title>
            <v-card-text>
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-textarea
                      v-show="arrivo == 'sblocca' || arrivo == 'blocca'"
                      outlined
                      model="note_blocco"
                      name="input-7-4"
                      label="Note"
                      value
                    ></v-textarea>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog_conferma = false"
                >Annulla</v-btn
              >
              <v-btn color="blue darken-1" text @click="setUtenteConferma()"
                >Conferma</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
//var prova;
//import axios from "axios";
import config from "../../../public/config";
//import draggable from "vuedraggable";

export default {
  data: () => ({
    id_gruppo_dettaglio_original: [],
    des_gruppo_backup: "",
    struttura_gruppi: {
      gruppi: [],
    },
    struttura_gruppi_backup: {
      gruppi: [],
    },
    cerca_utenti: "",
    id_gruppo_save: 0,
    nuovo_gruppo: {},
    edited_struttura_gruppi: null,
    id_utente: [],
    nominativo_utente: [],
    id_gruppo_dettaglio: [],
    validita: [],
    gruppo_sel: null,
    gruppi_visualizzati: [],
    gruppi_esistenti: "",
    config: config,
    titolo_dialog: "",
    dialog_conferma: false,
    dialog_users: false,
    dialog_set_gruppo: false,
    dialog_users_edit: false,
    dialog_visualizza_gruppo: false,
    dialog_mod_gruppo: false,
    arrivo_utente: "",
    nome_gruppo: "",
    id_gruppo: -1,
    update_fb: 1,
    gruppo_damod: null,
    tab: null,
    utenti_email: [],
    utenti_gruppo: [],
    gruppo_famiglia: [],
    utenti_famiglia: [],
    utenti_partecipanti: "",
    support_array: [],
    utenti_iniziali: [],
    utenti_iniziali_backup: [],
    utenti_iniziali_sel: [],
    headers_utenti: [
      {
        text: "Nominativo",
        value: "nominativo",
      },
    ],
    headers_utenti_sel: [
      { text: "Preposto", value: "preposto" },
      {
        text: "Nominativo",
        value: "nominativo",
      },
      { text: "Azioni", value: "action" },
    ],
    headers_utenti_info: [
      { text: "Preposto", value: "preposto" },
      {
        text: "Nominativo",
        value: "nominativo",
      },
    ],
    search: "",
    search2: "",
    popup: false,
    popup1: false,
    snackcheck: false,
    colore_snackcheck: "",
    msg_snackcheck: "",
    array_nome_gruppo: [],
    check: false,
    search_partecipanti: "",
    stati: [
      {
        id_stato: 1,
        testo: "BLOCCATO",
      },
      {
        id_stato: 3,
        testo: "ATTIVO",
      },
      {
        id_stato: 2,
        testo: "SBLOCCATO",
      },
      {
        id_stato: 4,
        testo: "DISATTIVO",
      },
    ],
    stato_sel: null,
    messaggio_snackbar: "",
    colore_snack: "green",
    snackbar: false,
    item_sel: {},
    backup_item: [],
    arrivo: "",
    dipendenti: [],
    titolo_conferma: "",
    gruppo_nome: "",
    loader_gruppi: false,
    item: 0,
    items: [],
    persone_rimosse: [],
    headers: [
      {
        text: "Nominativo",
        align: "start",
        sortable: true,
        value: "nominativo",
      },
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Gruppo", value: "cod_personale" },
      { text: "Profilo", value: "des_profilo_utente" },
      { text: "Badge", value: "badge" },

      { text: "cod. Fiscale", value: "cod_fiscale" },
      { text: "Stato", value: "stato" },
      { text: "Azioni", value: "action" },
      { text: "Gestione", value: "gestione" },
    ],
  }),
  // components: { draggable },
  watch: {
    stato_sel(val) {
      //filtro la tabella
      if (this.backup_item.length > 0) this.dipendenti = this.backup_item;
      if (val != null) {
        this.backup_item = this.dipendenti;
        if (val.id_stato == 1) {
          this.dipendenti = this.dipendenti.filter((i) => i.blocco_utente == 1);
        } else if (val.id_stato == 2) {
          this.dipendenti = this.dipendenti.filter((i) => i.blocco_utente == 0);
        } else if (val.id_stato == 3) {
          this.dipendenti = this.dipendenti.filter((i) => i.attivo == 1);
        } else if (val.id_stato == 4) {
          this.dipendenti = this.dipendenti.filter((i) => i.attivo == 0);
        }
      }
    },
    gruppo_sel(val) {
      console.log("valore", val);

      this.gruppi_visualizzati = this.gruppi_esistenti.t_gruppo;
      if (val != undefined) {
        this.gruppi_visualizzati = this.gruppi_visualizzati.filter(
          (f) => f.des_gruppo == val
        );
      }
    },
  },
  created() {},
  mounted() {
    if (!localStorage.struttura_gruppi)
      localStorage.setItem(
        "struttura_gruppi",
        JSON.stringify(this.struttura_gruppi)
      );
    this.edited_struttura_gruppi = JSON.parse(
      localStorage.getItem("struttura_gruppi")
    );

    this.getInfo();
    this.getGruppi();
    console.log("richiesta gruppi");

    this.dipendenti.id_gruppo_dettaglio = -1;
  },
  methods: {
    cancella_autocomplete() {
      this.stato_sel = null;
    },
    handleSelectedFile(convertedData) {
      console.log(convertedData);
    },
    updateValue: function () {
      this.$emit("dialogUtenti", false);
    },
    getInfo() {
      this.values = [];
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "getlistadipendenti",
      //   token: localStorage.user_token,
      //   tipo: "a",
      //   id_utente: localStorage.id,
      //   db_name: "medical",
      // };
      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getlistadipendenti",
          token: localStorage.user_token,
          tipo: "a",
          id_utente: localStorage.id,
          db_name: "medical",
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((response) => {
        //console.log("settabelle response: ", JSON.stringify(res));
        this.dipendenti = response.data.dipendenti;
        this.dipendenti.id_gruppo_dettaglio = -1;
      });
      // var data = JSON.stringify(finale);
      // axios
      //   .post(this.$store.state.config.ip + "api/Medical", data, config)
      //   .then((response) => {
      //     // JSON responses are automatically parsed.
      //     this.dipendenti = response.data.dipendenti;
      //     this.dipendenti.id_gruppo_dettaglio = -1;
      //     //this.dipendenti.forEach(e => this.utenti_iniziali.push(e));
      //   })
      //   .catch((e) => {
      //     if (e.response.status == 401) {
      //       localStorage.clear();
      //       this.$router.replace("login");
      //     }
      //     console.log(e);
      //   });
    },
    setColoreStatoDipendenti(item) {
      if (item.attivo == 0) {
        return "red";
      } else if (item.blocco_utente == 1) {
        return "orange";
      } else {
        return "green";
      }
    },
    setStatoDipendenti(item) {
      if (item.attivo == 0) {
        return "Disattivo";
      } else if (item.blocco_utente == 1) {
        return "Bloccato";
      } else {
        return "Attivo";
      }
    },
    setUtente(item, arrivo) {
      this.item_sel = item;
      this.arrivo = arrivo;
      var data = {
        arrivo: arrivo,
        item: item,
      };
      this.$emit("confermaUsers", data);
    },
    conferma_azioni(item, arrivo) {
      if (arrivo == "sblocca") {
        this.dipendenti[this.dipendenti.indexOf(item)].blocco_utente = 0;
      } else if (arrivo == "blocca") {
        this.dipendenti[this.dipendenti.indexOf(item)].blocco_utente = 1;
      } else if (arrivo == "disattiva") {
        this.dipendenti[this.dipendenti.indexOf(item)].attivo = 0;
      } else if (arrivo == "attiva") {
        this.dipendenti[this.dipendenti.indexOf(item)].attivo = 1;
      }
      this.colore_snack = "green";
      this.messaggio_snackbar = "Azione effettuata con successo!";
      this.snackbar = true;
    },
    errore_azioni() {
      this.colore_snack = "red";
      this.messaggio_snackbar =
        "Errore, non è stato possibile effettuare l'azione!";
      this.snackbar = true;
    },
    insertUtenti(item, arrivo) {
      if (arrivo == "modifica") {
        this.arrivo_utente = "modifica";
      } else {
        this.tab = "tab-1";
        this.arrivo_utente = "inserisci";
      }
    },

    aggiungiGruppo() {
      this.utenti_iniziali = [];
      this.utenti_partecipanti = [];
      this.dialog_set_gruppo = true;
      this.dipendenti.forEach((e) => {
        this.utenti_iniziali.push(e);
        this.utenti_iniziali_backup.push(e);
      });
    },
    chiudiAggiungigruppo() {
      this.nome_gruppo = "";
      (this.dialog_set_gruppo = false), (this.utenti_iniziali = []);
    },
    dialogModgruppo(item) {
      this.gruppo_damod = item;
      this.nome_gruppo = item.des_gruppo;
      this.des_gruppo_backup = item.des_gruppo;

      this.utenti_partecipanti = [];
      this.utenti_iniziali = [];
      this.id_utente = [];
      this.id_gruppo_dettaglio = [];
      this.validita = [];
      this.nominativo_utente = [];

      console.log(item);
      for (let i = 0; i < item.t_gruppo_dettaglio.length; i++) {
        this.utenti_partecipanti.push(item.t_gruppo_dettaglio[i]);
        this.id_gruppo = item.t_gruppo_dettaglio[i].id_gruppo;
      }
      this.id_gruppo_dettaglio_original = this.utenti_partecipanti;

      for (let i = 0; i < this.utenti_partecipanti.length; i++) {
        this.id_utente.push(this.utenti_partecipanti[i].id_personale);
        this.id_gruppo_dettaglio.push(
          this.utenti_partecipanti[i].id_gruppo_dettaglio
        );
        this.validita.push(this.utenti_partecipanti[i].validita);
      }
      // for (let i = 0; i < this.utenti_iniziali.length; i++) {
      //   for (let j = 0; j < this.utenti_partecipanti.length; j++) {
      //     if (
      //       this.utenti_iniziali[i].id_personale ==
      //       this.utenti_partecipanti[j].id_personale
      //     )
      //       this.utenti_partecipanti[j].nome = this.utenti_iniziali[
      //         i
      //       ].nominativo;
      //   }
      // }

      this.dipendenti.forEach((e) => {
        var esiste = 0;
        for (let i = 0; i < this.utenti_partecipanti.length; i++) {
          if (e.id_personale == this.utenti_partecipanti[i].id_personale) {
            esiste = 1;
          }
        }
        if (esiste == 0) {
          this.utenti_iniziali.push(e);
          this.utenti_iniziali_backup.push(e);
        }
      });
      this.persone_rimosse = [];
      this.dialog_mod_gruppo = true;
    },
    annulladialogModGruppo() {
      this.dialog_mod_gruppo = false;
      this.nome_gruppo = "";
      this.id_gruppo = -1;
      this.utenti_partecipanti = [];
      this.utenti_iniziali = [];
    },
    svuotaDialogVisualizzaGruppo() {
      this.dialog_visualizza_gruppo = false;
    },
    checknomegruppo() {
      if (this.nome_gruppo == "" && this.utenti_partecipanti.length == 0) {
        this.snackbar = true;
        this.colore_snack = "red";
        this.messaggio_snackbar =
          "Il nome del gruppo e la lista dei partecipanti non possono essere vuoti!";
        return false;
      } else if (
        this.utenti_partecipanti.length == 0 &&
        this.nome_gruppo != ""
      ) {
        this.snackbar = true;
        this.colore_snack = "red";
        this.messaggio_snackbar =
          "La lista dei partecipanti non può essere vuota!";
        return false;
      } else if (
        this.utenti_partecipanti.length != 0 &&
        this.nome_gruppo == ""
      ) {
        this.snackbar = true;
        this.colore_snack = "red";
        this.messaggio_snackbar = "Il nome del gruppo non può essere vuoto!";
        return false;
      } else {
        return true;
      }
    },
    spostaUtenti() {
      console.log("utenti_sekl", this.utenti_iniziali_sel);
      this.utenti_iniziali_sel.forEach((elem) => {
        elem["nuovo"] = 1;
        var newElem = {
          id_gruppo_dettaglio: -1,
          id_gruppo: elem.id_gruppo,
          cod_gruppo: elem.cod_gruppo == undefined ? null : elem.cod_gruppo,
          id_personale: elem.id_personale,
          validita: 1,
          id_utente: localStorage.id,
          nominativo: elem.nominativo,
          update_fb: null,
          preposto: "0",
          date_ins: null,
          date_mod: null,
        };
        this.utenti_partecipanti.push(newElem);
        var index = this.utenti_iniziali.indexOf(elem);
        this.utenti_iniziali.splice(index, 1);
      });
      this.utenti_iniziali_sel = [];
      this.utenti_partecipanti = this.utenti_partecipanti.sort(function (x, y) {
        let fa = x.nominativo.toLowerCase(),
          fb = y.nominativo.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
    rimuoviUtenteSel(item) {
      var index = this.utenti_partecipanti.indexOf(item);
      this.utenti_iniziali.push(item);
      this.utenti_partecipanti.splice(index, 1);
      this.persone_rimosse.push(item.id_gruppo_dettaglio);
    },
    // setGruppo() {
    //   var item = {
    //     nome: this.nome_gruppo,
    //     utenti: this.utenti_gruppo
    //   };
    //   this.$refs.GestioneUtenti.setgruppo(item);
    //   this.dialog_set_gruppo = false;
    // },
    // setGruppoMod() {
    //   var item = {
    //     nome: this.gruppo_nome,
    //     utenti: this.utenti_famiglia
    //   };
    //   this.$refs.GestioneUtenti.modGruppo(item);
    //   this.dialog_visualizza_gruppo = false;
    // },
    rimuoviUtenteGruppo(val) {
      console.log("VALORE DRAG AND DROP", val);
      if (val.removed != undefined) {
        this.persone_rimosse.push(val.removed.element.id_gruppo_dettaglio);
      }
    },
    setgruppo() {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };

      var today = new Date();

      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        " " +
        today.getHours() +
        ":" +
        today.getMinutes() +
        ":" +
        today.getSeconds();
      if (this.nome_gruppo == "" || this.utenti_partecipanti.length == 0) {
        this.snackbar = true;
        this.colore_snack = "red";
        this.messaggio_snackbar =
          "Il nome del gruppo non può essere vuoto o il gruppo deve contenere almeno un utente!";
        return;
      }
      var item = {
        nome: this.nome_gruppo,
        utenti: this.utenti_partecipanti,
      };

      var tab = this.gruppi_esistenti.t_gruppo;

      for (let i = 0; i < this.utenti_partecipanti.length; i++) {
        if (this.utenti_partecipanti[i].id_gruppo_dettaglio == null) {
          this.utenti_partecipanti[i].id_gruppo_dettaglio = -1;
          this.utenti_partecipanti[i].id_gruppo = -1;
          this.utenti_partecipanti[i].validita = 1;
        }
      }

      var a = {
        cod_gruppo: "",
        date_ins: date,
        date_mod: "",
        id_azienda: localStorage.id_azienda,
        id_gruppo: -1,
        update_fb: 1,
        id_utente: -99,
        des_gruppo: item.nome,
        t_gruppo_dettaglio: item.utenti,
        validita: 1,
      };
      tab.push(a);

      // var finale = {
      //   action: "setgruppo",
      //   token: localStorage.user_token,
      //   idUtente: localStorage.id,
      //   dbName: "medical",
      //   t_gruppo: tab,
      //   utentiCancellati: this.persone_rimosse,
      // };
      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "setgruppo",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
          t_gruppo: tab,
          utentiCancellati: this.persone_rimosse,
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request.richiesta));
      this.$store.dispatch("api", request).then(() => {
        //console.log("settabelle response: ", JSON.stringify(res));
        this.colore_snack = "green";
        this.messaggio_snackbar = "Gruppo aggiunto con successo !";
        this.snackbar = true;
        this.nome_gruppo = "";

        this.utenti_partecipanti = [];
        this.getGruppi();
      });

      this.dialog_set_gruppo = false;
    },
    detailDeletegruppo(item) {
      this.des_gruppo_backup = item.des_gruppo;
      this.popup = true;
    },
    resetValidita() {
      for (let i = 0; i < this.gruppi_esistenti.gruppi.length; i++) {
        if (this.gruppi_esistenti.gruppi[i].validita == 0) {
          this.gruppi_esistenti.gruppi[i].validita = 1;
        }
      }
    },

    deleteGruppo() {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };

      this.gruppi_esistenti.t_gruppo.forEach((element) => {
        if (element.des_gruppo == this.des_gruppo_backup) {
          element.validita = 0;
          element.update_fb = 1;
        }
      });
      var tab = this.gruppi_esistenti.t_gruppo;
      // var finale = {
      //   action: "setgruppo",
      //   token: localStorage.user_token,
      //   idUtente: localStorage.id,
      //   dbName: "medical",
      //   t_gruppo: tab,
      //   utentiCancellati: this.persone_rimosse,
      // };
      // var data = JSON.stringify(finale);
      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "setgruppo",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
          t_gruppo: tab,
          utentiCancellati: this.persone_rimosse,
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        if (response.status != 204) {
          this.colore_snack = "green";
          this.messaggio_snackbar = "Gruppo eliminato con successo !";
          this.snackbar = true;
          this.gruppi_esistenti.t_gruppo.forEach((element) => {
            if (element.des_gruppo == this.des_gruppo_backup) {
              element.validita = 0;
            }
          });
          this.getGruppi();
          console.log("richiesta gruppi");
        }
        console.log(response);
      });

      // axios
      //   .put(this.$store.state.config.ip + "api/User", data, config)
      //   .then((response) => {
      //     // JSON responses are automatically parsed.
      //     if (response.status != 204) {
      //       this.colore_snack = "green";
      //       this.messaggio_snackbar = "Gruppo eliminato con successo !";
      //       this.snackbar = true;
      //       this.gruppi_esistenti.t_gruppo.forEach((element) => {
      //         if (element.des_gruppo == this.des_gruppo_backup) {
      //           element.validita = 0;
      //         }
      //       });
      //       this.getGruppi();
      //       console.log("richiesta gruppi");
      //     }
      //     console.log(response);
      //   })
      //   .catch((e) => {
      //     if (e.response.status == 401) {
      //       localStorage.clear();
      //       this.$router.replace("login");
      //     }
      //     console.log(e);
      //   });
    },

    modGruppo(item) {
      var check = this.checknomegruppo();
      if (!check) {
        return;
      }

      var today = new Date();

      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        " " +
        today.getHours() +
        ":" +
        today.getMinutes() +
        ":" +
        today.getSeconds();

      for (let i = 0; i < this.gruppi_esistenti.t_gruppo.length; i++) {
        if (
          this.gruppi_esistenti.t_gruppo[i].des_gruppo == this.des_gruppo_backup
        ) {
          var data_inserimento = this.gruppi_esistenti.t_gruppo[i].data_ins;
        }
      }
      for (let i = 0; i < this.utenti_partecipanti.length; i++) {
        if (this.utenti_partecipanti[i].id_gruppo_dettaglio == null)
          this.utenti_partecipanti[i].id_gruppo_dettaglio = -1;
        this.utenti_partecipanti[i].id_gruppo = this.id_gruppo;
        this.utenti_partecipanti[i].validita = 1;
      }

      var mod_gruppo = {
        id_gruppo: this.id_gruppo,
        date_ins: data_inserimento,
        date_mod: date,
        update_fb: 1,
        id_azienda: localStorage.id_azienda,
        id_utente: localStorage.id,
        des_gruppo: this.nome_gruppo,
        t_gruppo_dettaglio: this.utenti_partecipanti,
        validita: 1,
      };

      for (let i = 0; i < this.gruppi_esistenti.t_gruppo.length; i++) {
        if (
          this.gruppi_esistenti.t_gruppo[i].des_gruppo == this.des_gruppo_backup
        ) {
          this.gruppi_esistenti.t_gruppo[i] = mod_gruppo;
        }
      }

      //var tab = this.gruppi_esistenti.t_gruppo;
      var tab = [];
      tab.push(mod_gruppo);

      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "setgruppo",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
          t_gruppo: tab,
          utentiCancellati: this.persone_rimosse,
        },
      };
      console.log(JSON.stringify(request.richiesta));
      this.$store.dispatch("api", request).then(() => {
        this.colore_snack = "green";
        this.messaggio_snackbar = "Gruppo modificato con successo !";
        this.snackbar = true;
        this.utenti_partecipanti.forEach((element) => {
          if (element.cod_personale == item.nome) {
            element.cod_personale = "";
          }
          this.persone_rimosse = [];

          console.log("richiesta gruppi");
        });
        this.getGruppi();
      });

      this.dialog_mod_gruppo = false;
    },
    detailGruppo(item) {
      this.utenti_partecipanti = [];
      this.utenti_iniziali = [];
      this.id_utente = [];
      this.id_gruppo_dettaglio = [];
      this.validita = [];
      this.nominativo_utente = [];
      this.dipendenti.forEach((e) => this.utenti_iniziali.push(e));

      for (let i = 0; i < item.t_gruppo_dettaglio.length; i++) {
        this.utenti_partecipanti.push(item.t_gruppo_dettaglio[i]);
      }

      for (let i = 0; i < this.utenti_partecipanti.length; i++) {
        this.id_utente.push(this.utenti_partecipanti[i].id_personale);
        this.id_gruppo_dettaglio.push(
          this.utenti_partecipanti[i].id_gruppo_dettaglio
        );
        this.validita.push(this.utenti_partecipanti[i].validita);
      }

      for (let i = 0; i < this.utenti_iniziali.length; i++) {
        for (let j = 0; j < this.utenti_partecipanti.length; j++) {
          if (
            this.utenti_iniziali[i].id_personale ==
            this.utenti_partecipanti[j].id_personale
          )
            this.utenti_partecipanti[j].nome =
              this.utenti_iniziali[i].nominativo;
        }
      }
      // prova = this.utenti_gruppo.filter(
      //   f => f.cod_personale == item.cod_personale
      // );

      // this.utenti_partecipanti = [];
      // this.utenti_iniziali = [];

      // this.dipendenti.forEach(e => {
      //   prova.forEach(x => {
      //     if (e.id_personale === x.id_personale) {
      //       this.utenti_partecipanti.push(e);
      //       this.utenti_partecipanti = Array.from(
      //         new Set(this.utenti_partecipanti)
      //       );
      //     } else {
      //       this.utenti_iniziali.push(e);
      //       this.utenti_iniziali = Array.from(new Set(this.utenti_iniziali));
      //     }
      //   });
      // });
      // this.utenti_iniziali.forEach(e => {
      //   this.utenti_partecipanti.forEach(x => {
      //     if (e === x) this.utenti_iniziali.splice(e, 1);
      //   });
      // });
      // console.log("%cUTENTI INIZIALI", "color:blue", this.utenti_iniziali);

      // console.log("%cLISTA DIPENDENTI", "color:blue", this.dipendenti);
      // console.log("%cLISTA PROVA", "color:blue", prova);
    },

    getGruppi() {
      this.utenti_email = [];
      // this.gruppi_esistenti = this.edited_struttura_gruppi;
      this.loader_gruppi = true;
      this.values = [];

      var request = {
        controller: "User",
        method: "POST",
        richiesta: {
          action: "getgruppo",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          db_name: "medical",
        },
      };
      this.$store.dispatch("api", request).then((response) => {
        this.gruppi_esistenti = response.data;
        if (
          Object.keys(this.gruppi_esistenti).length !== 0 &&
          typeof this.gruppi_esistenti.t_gruppo[0] != "object"
        ) {
          this.gruppi_esistenti.t_gruppo = [this.gruppi_esistenti.t_gruppo];
          this.gruppi_visualizzati = this.gruppi_esistenti.t_gruppo;
          for (let i = 0; i < this.gruppi_esistenti.t_gruppo.length; i++) {
            console.log("a", this.gruppi_esistenti.t_gruppo[i].id_gruppo);
            this.id_gruppo_save = this.gruppi_esistenti.t_gruppo[i].id_gruppo;
          }
        } else if (
          Object.keys(this.gruppi_esistenti).length !== 0 &&
          typeof this.gruppi_esistenti.t_gruppo[0] == "object"
        ) {
          this.gruppi_visualizzati = this.gruppi_esistenti.t_gruppo;
          for (let i = 0; i < this.gruppi_esistenti.t_gruppo.length; i++) {
            this.id_gruppo_save = this.gruppi_esistenti.t_gruppo[i].id_gruppo;
          }
        } else {
          this.gruppi_esistenti.t_gruppo = [];
        }
        this.loader_gruppi = false;
      });
    },
    filtraUtenti(event) {
      if (event.key == "Enter") {
        this.utenti_iniziali = this.utenti_iniziali_backup;
        this.utenti_iniziali = this.utenti_iniziali.filter((e) =>
          e.nominativo.toLowerCase().includes(this.cerca_utenti.toLowerCase())
        );
      }
    },
    filtraUtentiCerca() {
      this.utenti_iniziali = this.utenti_iniziali_backup;
      this.utenti_iniziali = this.utenti_iniziali.filter((e) =>
        e.nominativo.toLowerCase().includes(this.cerca_utenti.toLowerCase())
      );
    },
    chiudiDialog() {
      this.$emit("chiudi");
    },
  },
};
</script>
<style  scoped>
.nowrap {
  white-space: nowrap !important;
}

.liststyler:hover {
  box-shadow: 0px 0px 5px rgb(47, 177, 252) !important;
}
.liststyler:active {
  cursor: grabbing !important;
}
</style>
