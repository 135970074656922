var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Macchine Multicommessa")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('v-data-table',{attrs:{"items":_vm.items_macchine_multi,"headers":_vm.headers_macchine_multi},scopedSlots:_vm._u([{key:"item.seleziona",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"small":"","x-large":""},on:{"click":function($event){return _vm.clickSelezionaMacchina(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Seleziona Macchina")])])]}},{key:"item.autonoma",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":("" + (item.autonoma == 1? 'success' : 'error'))}},[_vm._v(" "+_vm._s(item.autonoma == 1? "mdi-check" : "mdi-close")+" ")])]}},{key:"item.on_line",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":("" + (item.on_line == 1? 'success' : 'error'))}},[_vm._v(" "+_vm._s(item.on_line == 1? "mdi-check" : "mdi-close")+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }