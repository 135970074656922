<template>
  <ListTicket :assistenza="assistenza"/>
</template>

<script>
import ListTicket from '@/components/TICKET/ListTicket.vue'

export default {
  name: 'HomeTicket',
  components: {
    ListTicket
  },
  props: {
    assistenza:{ type: Number, default: 0 },
  }
}
</script>