<template>
    <div class="graph_button graph_card" @click="$emit('click', $event)" :data-disabled="disabled">
        <GraphIcon :icon="icon" v-if="text==null" />        
        <span v-else>{{ text }}</span>
    </div>
</template>
<script>
import GraphIcon from "../Icons/GraphIcon.vue";
export default {
    name : 'GraphButton',
    props : { 
        icon : String,
        text : String,
        disabled : Boolean
    },
    components : { GraphIcon },
}
</script>
<style scoped>
    .graph_button{
        position: relative;
        min-width:30px;
        height: 30px;
        margin: 0 auto;
        display: inline-block;
        vertical-align: middle;
        padding: 3px;
        border-radius: 10px;
        text-align: center;
        cursor:pointer;
        transition: background-color 0.3s ease, color 0.1s ease;
        background-color: rgba(0,0,0,0);
    }
    .graph_button[data-disabled]{
        pointer-events: none;
        background-color: #cccccc!important;
    }
    .graph_button > svg{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }
    .graph_button:hover{
        color: white;
        background-color: var(--accent)!important;
    }
    .graph_button:active{
        color:white;
        transition: background-color 0s ease;
        background-color: var(--darker_accent)!important;
    }
    .graph_button > span{
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;
    }
</style>