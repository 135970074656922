export default class FasciaOraria {
    constructor(arg) {
        if (arg != undefined) {
            this.nominativo = arg.nominativo != undefined ? arg.nominativo : "";
            this.cod_attivita = arg.cod_attivita != undefined ? arg.cod_attivita : "";
            this.des_attivita = arg.des_attivita != undefined ? arg.des_attivita : "";
            this.id_attivita = arg.id_attivita != undefined ? arg.id_attivita : null;
            this.riga = arg.riga != undefined ? arg.riga : null;
            this.fasce_orarie_lavorate = arg.fasce_orarie_lavorate != undefined ? arg.fasce_orarie_lavorate : [];
            this.fasce_orarie_disponibili = arg.fasce_orarie_disponibili != undefined ? arg.fasce_orarie_disponibili : [];
            this.nome_tipologia = arg.nome_tipologia != undefined ? arg.nome_tipologia : null;
            this.id_anagrafica_tipologia = arg.id_anagrafica_tipologia != undefined ? arg.id_anagrafica_tipologia : null;
            this.id_operatore = arg.id_operatore != undefined ? arg.id_operatore : null;
            this.id_operatore_dettaglio = arg.id_operatore_dettaglio != undefined ? arg.id_operatore_dettaglio : null;
            this.id_anagrafica_tipologia = arg.id_anagrafica_tipologia != undefined ? arg.id_anagrafica_tipologia : null;
            this.id_consuntivo_personale = arg.id_consuntivo_personale != undefined ? arg.id_consuntivo_personale : -1;
            this.importo_personale = arg.importo_personale != undefined ? arg.importo_personale : 0;
            this.validita = arg.validita != undefined ? arg.validita : 1;
        } else {
            this.cod_attivita = "";
            this.des_attivita = "";
            this.id_anagrafica_tipologia = null;
            this.nome_tipologia = "";
            this.id_operatore = null;
            this.id_operatore_dettaglio = null;
            this.id_anagrafica_tipologia = null;
            this.id_consuntivo_personale = null;
            this.id_attivita = null;
            this.validita = 1;
            this.riga = null;
            this.fasce_orarie_lavorate = [];
            this.fasce_orarie_disponibili = [];
            this.importo_personale = 0;
            this.nominativo = "";
        }
    }
}