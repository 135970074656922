var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"dense":"","color":"white","elevation":"0","dark":"","align":"center"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5","align":"left"}},[_c('v-toolbar-title',[_vm._v("Cambio Password")])],1),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"5","align":"right"}},[_c('v-btn',{attrs:{"small":"","light":"","disabled":!(_vm.checkLength(_vm.pw.new) && _vm.checkEqual(_vm.pw.new,_vm.pw.check))},on:{"click":_vm.update_pw}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)],1)],1)],1),_c('v-container',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":_vm.rules.pw,"type":"password","label":"Vecchia password"},model:{value:(_vm.pw.old),callback:function ($$v) {_vm.$set(_vm.pw, "old", $$v)},expression:"pw.old"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":_vm.rules.pw,"type":"password","label":"Nuova password"},model:{value:(_vm.pw.new),callback:function ($$v) {_vm.$set(_vm.pw, "new", $$v)},expression:"pw.new"}})],1),(!_vm.checkLength(_vm.pw.new))?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,2726130036)},[_c('span',[_vm._v("Lunghezza minima: 8 caratteri")])])],1):_vm._e()],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":_vm.rules.pw,"type":"password","label":"Ripeti nuova password"},model:{value:(_vm.pw.check),callback:function ($$v) {_vm.$set(_vm.pw, "check", $$v)},expression:"pw.check"}})],1),(!_vm.checkEqual(_vm.pw.new,_vm.pw.check))?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,2726130036)},[_c('span',[_vm._v("Password non coincidenti")])])],1):_vm._e()],1)],1)],1)],1),_c('v-snackbar',{attrs:{"dark":"","color":(_vm.alert.error==true)?'error':'light-green darken-2'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.alert.show=false}}},'v-btn',attrs,false),[_vm._v("Chiudi")])]}}]),model:{value:(_vm.alert.show),callback:function ($$v) {_vm.$set(_vm.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }