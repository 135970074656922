import CHART_COMMON from "./_common.js";
class CHART_CARD{  
    COMMON = new CHART_COMMON(); 
    TARGET = null;
    SETLISTENER = null;
    constructor(target, clicklistener){
        this.TARGET = target;
        this.SETLISTENER = clicklistener;
    }
    
    setOptions(data){
        try{
            data.extra = this.COMMON.getExtra(data);
            const serie = this.COMMON.filterSerie(data.serie)[0];
            serie.extra = this.COMMON.getExtra(serie);
            const processed = this.COMMON.processSerie(serie)[0];
            const that = this;

            requestAnimationFrame(function(){
                const div = document.createElement('div');
                div.setAttribute('class','graph_carditem graph_render_target');
                div.setAttribute('style',processed.itemStyle.table);

                const helper = document.createElement('span');
                div.appendChild(helper);

                const text = document.createElement('p');
                text.innerText = processed.formatted;
                div.appendChild(text);

                that.TARGET.appendChild(div);
            });
            
            try{
                if(data.extra.click.type == 0){
                    this.SETLISTENER(function(event, router){
                        window.stdout = event;
                        router.push('/home/graph/dashboard/' + data.extra.click.value);
                        const msg = new CustomEvent('graph_dashboard_id_update',{detail:data.extra.click.value});
                        document.body.dispatchEvent(msg);
                        setTimeout(function(){
                            const msg = new CustomEvent('dashboardrefresh',{detail:null});
                            document.getElementById('quickchart_dashboard_main').dispatchEvent(msg);
                        });
                    });
                }
            }catch{ 
                this.SETLISTENER = null;
            }

            return null;
        }catch(error){
            console.error(error);
            return null;
        }        
    }    
}
export default CHART_CARD;