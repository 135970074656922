var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5 mx-5"},[_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Esecuzioni Autocontrolli ")]),_c('v-spacer'),(_vm.filtri_visible === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.filtri_visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter")])],1)]}}],null,false,3068551005)},[_c('span',[_vm._v("Filtri")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.ripristina}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter-off")])],1)]}}])},[_c('span',[_vm._v("Ripristina")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-keyboard-backspace ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers_registrazioni,"items":_vm.items_registrazioni,"height":"70vh"},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.filtri_visible)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni,"item-text":function (e) { return e.cod_commessa; },"item-value":"cod_commessa","label":"Cod. Commessa"},on:{"change":_vm.filtra},model:{value:(_vm.cod_commessa_search),callback:function ($$v) {_vm.cod_commessa_search=$$v},expression:"cod_commessa_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_commessa_search = '';
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni.filter(function (e) { return e.cod_articolo != null; }),"item-text":function (e) { return e.cod_articolo; },"item-value":"cod_articolo","label":"Cod. Articolo"},on:{"change":_vm.filtra},model:{value:(_vm.cod_articolo_search),callback:function ($$v) {_vm.cod_articolo_search=$$v},expression:"cod_articolo_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_articolo_search = '';
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni.filter(function (e) { return e.des != null; }),"item-text":function (e) { return e.des; },"item-value":"des_fase","label":"Des. Fase"},on:{"change":_vm.filtra},model:{value:(_vm.des_fase_search),callback:function ($$v) {_vm.des_fase_search=$$v},expression:"des_fase_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.des_fase_search = '';
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data Da","readonly":""},model:{value:(_vm.data_da_search_formattata),callback:function ($$v) {_vm.data_da_search_formattata=$$v},expression:"data_da_search_formattata"}},on))]}}],null,false,541284678),model:{value:(_vm.calendario_data_da),callback:function ($$v) {_vm.calendario_data_da=$$v},expression:"calendario_data_da"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario_data_da = false}},model:{value:(_vm.data_da_search),callback:function ($$v) {_vm.data_da_search=$$v},expression:"data_da_search"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data A","readonly":""},model:{value:(_vm.data_a_search_formattata),callback:function ($$v) {_vm.data_a_search_formattata=$$v},expression:"data_a_search_formattata"}},on))]}}],null,false,3102972966),model:{value:(_vm.calendario_data_a),callback:function ($$v) {_vm.calendario_data_a=$$v},expression:"calendario_data_a"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario_data_a = false}},model:{value:(_vm.data_a_search),callback:function ($$v) {_vm.data_a_search=$$v},expression:"data_a_search"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.items_registrazioni,"item-text":function (e) { return e.conforme === null
                    ? 'indefinito'
                    : e.conforme === 1
                    ? 'approvato'
                    : 'respinto'; },"item-value":"conforme","label":"Conformità"},on:{"change":_vm.filtra},model:{value:(_vm.conforme_search),callback:function ($$v) {_vm.conforme_search=$$v},expression:"conforme_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.conforme_search = 2;
                    _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1)],1):_vm._e()]},proxy:true},{key:"item.data_controllo",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_controllo != null ? ((item.data_controllo.substring( 8, 10 )) + "/" + (item.data_controllo.substring( 5, 7 )) + "/" + (item.data_controllo.substring( 0, 4 )) + " - " + (item.data_controllo.substring( 11, 13 )) + ":" + (item.data_controllo.substring(14, 16))) : "N/D")+" ")]}},{key:"item.esito",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"color":(item.valore === null && item.misura === 1) ||
            (item.valore != null &&
              item.valore_max >= item.misura &&
              item.misura >= item.valore_min)
              ? 'success'
              : 'error',"large":""}},[_vm._v(" "+_vm._s((item.valore === null && item.misura === 1) || (item.valore != null && item.valore_max >= item.misura && item.misura >= item.valore_min) ? "mdi-check" : "mdi-close")+" ")])]}},{key:"item.conforme",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.conforme === 1 ? 'success' : 'grey'},on:{"click":function($event){_vm.$set(item, 'conforme', 1);
            _vm.setAutocontrolli(item);}}},[_vm._v(" "+_vm._s(item.conforme === 1 ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('v-icon',{attrs:{"color":item.conforme === 0 ? 'primary' : 'grey'},on:{"click":function($event){_vm.$set(item, 'conforme', 0);
            _vm.setAutocontrolli(item);}}},[_vm._v(" "+_vm._s(item.conforme === 0 ? "mdi-checkbox-intermediate" : "mdi-checkbox-blank-outline")+" ")])]}},{key:"item.img",fn:function(ref){
            var item = ref.item;
return [(item.image == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickDettaglioImmagine(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio immagine")])]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{model:{value:(_vm.dettaglio_immagine_visible),callback:function ($$v) {_vm.dettaglio_immagine_visible=$$v},expression:"dettaglio_immagine_visible"}},[(_vm.dettaglio_immagine_visible)?_c('Controllo',{attrs:{"clear_on_save":false,"immagine_standard_editor":_vm.img_sel != null ? _vm.img_sel : '',"show_close_button":false,"show_editor_tool_columns":false,"show_editor_save_button":false,"tabella_supporto":'',"titolo_editor":"Immagine Associata","visible":true}}):_vm._e()],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }