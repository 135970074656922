var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredTable,"sort-by":"ora","hide-default-footer":"","items-per-page":12000},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Timbrature")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Totale Accantonamento","readonly":"","outlined":"","dense":""},model:{value:(_vm.bonus_totale),callback:function ($$v) {_vm.bonus_totale=$$v},expression:"bonus_totale"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Nuova Timbratura ")])]}}]),model:{value:(_vm.dialog_timbratura),callback:function ($$v) {_vm.dialog_timbratura=$$v},expression:"dialog_timbratura"}},[_c('v-card',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","align":"right"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.close}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle-outline ")])],1)]}}])},[_c('span',[_vm._v("Chiudi.")])])],1),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-select',{attrs:{"items":_vm.tipo_timbratura,"item-text":"descrizione","item-value":"id","label":"Tipo Timbratura","dense":"","outlined":""},model:{value:(_vm.editedItem.tipo),callback:function ($$v) {_vm.$set(_vm.editedItem, "tipo", $$v)},expression:"editedItem.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Ora Timbratura","value":"12:30:00","type":"time","suffix":"ORA"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.conferma.apply(null, arguments)}},model:{value:(_vm.editedItem.ora),callback:function ($$v) {_vm.$set(_vm.editedItem, "ora", $$v)},expression:"editedItem.ora"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","outlined":""},on:{"click":_vm.conferma}},[_vm._v(" Conferma ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Conferma cancellazione timbratura?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.auth_ingresso",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.auth_ingresso),callback:function ($$v) {_vm.$set(item, "auth_ingresso", $$v)},expression:"item.auth_ingresso"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica Timbratura")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancella Timbratura")])])]}},{key:"item.piu_meno",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.piumeno(item, 'piu')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Accantona 15'")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.piumeno(item, 'meno')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancella 15' accantonati")])])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","align":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save_back}},[_vm._v(" SALVA ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }