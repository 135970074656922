<template>
    <GraphDialog ref="dialog" title="Seleziona Cartella">
        <div v-for="(folder,i) in folders" :key="i" class="graph_folder_picker clickable" @click="select(folder)">
            <GraphIcon icon="folder" />
            <span>{{folder.name}}</span>
        </div>
    </GraphDialog>
</template>
<script>
import GraphDialog from "@/components/GRAPH/.common/Dialog/GraphDialog.vue";
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";
export default {
    name : 'GraphFolderPicker',
    props : {
        items : Array
    },
    components : { 
        GraphDialog,
        GraphIcon
    },
    data(){
        return{
            folders : [],
            reference : null
        }
    },
    methods : {
        open(item){
            this.reference = item;
            while(this.folders.length>0){this.folders.splice(0,1);}
            for(let i=0; i < this.items.length; i++){
                const item = this.items[i];
                if(item.type==0){ this.folders.push(item); }
            }
            if(this.folders.length <= 0){ return; }
            this.$refs.dialog.display();
        },
        select(folder){
            folder.data.push(this.reference);
            this.items.splice(this.items.indexOf(this.reference), 1);
            this.$refs.dialog.hide();
        }
    }
}
</script>
<style scoped>
    .graph_folder_picker{
        position: relative;
        display: block;
        width:350px;
        margin: 5px 20px;
        border-radius: 8px;
        text-align: left;
        cursor: pointer;
        padding: 10px 0;
    }
    .graph_folder_picker:hover{
        background-color: rgba(0,0,0,0.1);
    }
    .graph_folder_picker:active{
        background-color: rgba(0,0,0,0.15);
    }
    .graph_folder_picker > svg{
        display: inline-block;
        vertical-align: middle;
        width:24px;
        margin:0 10px;
    }
    .graph_folder_picker > span{
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }
</style>