<template>
    <header class="graph_toolbar">
        <img src="@/components/GRAPH/.common/.media/logo.png" />
        <GraphButton icon="home" @click="$store.state.menu_sx=[];$router.push('/home')" />
        <nav>
            <GraphButton v-for="(item,i) in items" :key="i" :icon="item.icon" @click="item.callback" />
            <GraphButton icon="info" @click="showPopup('toolbar_info')" />            
        </nav>
        <div class="graph_toolbar_info graph_card" ref="toolbar_info">
            <h2>QuickGraphs</h2>
            <p>Versione 1.9.0</p>
            <div />
            <h3>{{ user }}</h3>
            <p>{{ profile }}</p>
        </div>
        <div class="graph_toolbar_search graph_card" ref="toolbar_search">
            <input type="text" placeholder="Ricerca"/>
        </div>
    </header>
</template>
<script>
import GraphButton from "@/components/GRAPH/.common/Buttons/GraphButton.vue";
export default {
    name : 'GraphToolbar',
    components : {
        GraphButton
    },
    data(){
        return{
            event_handler : null,
            items : null,
            profile : localStorage.des_profilo,
            timer : null,
            user : localStorage.nominativo,
        }
    },
    mounted(){
        document.body.addEventListener('graph_toolbar_items', this.updateToolbarItems);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_toolbar_items', this.updateToolbarItems);
    },
    methods : {
        showPopup(item){
            if(this.timer != null){ clearTimeout(this.timer); }
            this.$refs[item].style.display = 'block';              
            const that = this;
            setTimeout(function(){
                that.$refs[item].style.opacity = 1;
                if(item == 'toolbar_search'){
                    that.$refs[item].getElementsByTagName('input')[0].focus();
                }
                that.event_handler = function(){ that.hidePopup(item); }
                document.body.addEventListener('click', that.event_handler); 
            }, 50);                               
        },
        hidePopup(item){             
            this.$refs[item].style.opacity = 0; 
            const that = this;
            this.timer = setTimeout(function(){
                that.$refs[item].style.display = 'none';                
            }, 300);
            document.body.removeEventListener('click', that.event_handler);
            that.event_handler = null;
        },
        updateToolbarItems(event){
            this.items = event.detail;
        }
    }
}
</script>
<style scoped>
    .graph_toolbar{
        height: 48px;
        line-height: 48px;
        width:100%;
        position: relative;
        margin:0 auto;
        z-index: 2;
    }
    .graph_toolbar > img{
        position:absolute;
        height: calc(100% - 20px);
        top:10px;
        left:5px;
    }
    .graph_toolbar > nav{
        position:absolute;
        height: 48px;
        line-height: 48px;
        top:0;    
        right:0;  
    }
    .graph_toolbar > nav > div{
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
        text-align: right;
    }

    .graph_toolbar_info{
        position: absolute;
        min-width:250px;
        top: 100%;
        right:5px;
        text-align: left;
        padding: 8px;
        display: none;
        transition: opacity 0.3s ease;
        opacity: 0;
    }
    .graph_toolbar_info > h2{
        font-size: 18px;
        padding:0;
        margin: 0;
        height: 35px;
        line-height: 35px;
    }
    .graph_toolbar_info > h3{
        font-size: 15px;
        padding:0;
        margin: 0;
        height: 35px;
        line-height: 35px;
    }
    .graph_toolbar_info > p{
        margin:0;
        padding: 0;
        font-size: 13px;
        height: 25px;
        line-height: 25px;
    }
    .graph_toolbar_info > div{
        display: block;
        height: 1px;
        background-color: #595959;
        margin: 5px 0;
    }

    .graph_toolbar_search{
        position: absolute;
        width:350px;
        top: 100%;
        right: 44px;
        text-align: left;
        padding: 8px;
        display: none;
        transition: opacity 0.3s ease;
        opacity: 0;
    }
    .graph_toolbar_search > input{
        outline: none!important;
        position: relative;
        width:100%;
        border-bottom: 1px solid grey;
        border-radius: 0px;
        height:30px;
        line-height: 30px;
        font-size: 13px;
        padding:0;
        margin: 0;
    }
</style>