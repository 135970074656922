var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"primary-title":"","dense":"","color":"indigo"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Ricerca Magazzino")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"float":"left !important"},attrs:{"small":""},on:{"click":_vm.clickChiudi}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),_c('v-card-subtitle',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","md":"2","justify":"center"}},[_c('v-text-field',{attrs:{"label":"Cod. Articolo","placeholder":"Cod. Articolo","clearable":""},on:{"blur":function($event){return _vm.getArticolo()}},model:{value:(_vm.cod_articolo),callback:function ($$v) {_vm.cod_articolo=$$v},expression:"cod_articolo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2","justify":"center"}},[_c('TextField',{attrs:{"clearable_input":"","idForm":"ARTICOLO_MAGAZZINO","idOggetto":"CODICE_LOTTO","label_input":"Cod. Lotto","palceholder_input":"Cod. Lotto","vmodel":_vm.cod_lotto},on:{"changeText":function (val) { return (this$1.cod_lotto = val); }}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"3","justify":"center"}},[_c('v-autocomplete',{attrs:{"clearable":"","disabled":this.magazzino_list.length == 0,"items":_vm.magazzino_list,"item-text":"des_magazzino","label":"Magazzino","placeholder":"Magazzino","required":"","item-value":"id_magazzino"},model:{value:(_vm.id_magazzino),callback:function ($$v) {_vm.id_magazzino=$$v},expression:"id_magazzino"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2","justify":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data Da","prepend-icon":"event","readonly":""},model:{value:(_vm.data_da),callback:function ($$v) {_vm.data_da=$$v},expression:"data_da"}},on))]}}]),model:{value:(_vm.menuDataDa),callback:function ($$v) {_vm.menuDataDa=$$v},expression:"menuDataDa"}},[_c('v-date-picker',{staticStyle:{"width":"100% !important"},on:{"input":function($event){_vm.menuDataDa = false}},model:{value:(_vm.data_da),callback:function ($$v) {_vm.data_da=$$v},expression:"data_da"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"2","justify":"center"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data A","prepend-icon":"event","readonly":""},model:{value:(_vm.data_aa),callback:function ($$v) {_vm.data_aa=$$v},expression:"data_aa"}},on))]}}]),model:{value:(_vm.menuDataAa),callback:function ($$v) {_vm.menuDataAa=$$v},expression:"menuDataAa"}},[_c('v-date-picker',{staticStyle:{"width":"100% !important"},on:{"input":function($event){_vm.menuDataAa = false}},model:{value:(_vm.data_aa),callback:function ($$v) {_vm.data_aa=$$v},expression:"data_aa"}})],1)],1),_c('v-col',{attrs:{"cols":"1","md":"1"}},[_c('Button',{attrs:{"classe_bottone":"mt-2","colore_icona":"primary","idForm":"ARTICOLO_MAGAZZINO","idOggetto":"CERCA_MAGAZZINO","mdi_icona":"mdi-magnify","testo_tooltip":"Cerca Magazzino"},on:{"buttonClick":_vm.cercaMagazzino}})],1)],1)],1),_c('v-card-text',[_c('v-data-table')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }