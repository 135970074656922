<template>
    <div ref="menu" id="graph_context_menu" class="graph_card">
        <div v-for="(option, i) in menu_options" :key="i" @click="execute(option.target)">
            <GraphIcon :icon="option.icon" />
            <span>{{option.name}}</span>
        </div>
    </div>
</template>
<script>
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";
export default {
    name : 'GraphContextMenu',
    components : {
        GraphIcon
    },
    data(){
        return {
            menu_options : new Array(),
            SVG : window.SVG,
        }
    },
    mounted(){
        document.body.addEventListener('contextmenu', this.open);
    },
    beforeDestroy(){
        document.body.removeEventListener('contextmenu', this.open);
    },
    methods : {
        open(event){
            while(this.menu_options.length > 0){ this.menu_options.splice(0,1); }
            event.preventDefault();
            const path = event.composedPath();
            const length = path.length;
            for(let i = 0; i < length; i++){
                const item = path[i];                
                if(item.tagName.toLowerCase() == 'body'){ return; }
                let datalist = null;
                for(let j = 0; j < item.children.length; j++){
                    const child = item.children[j];
                    if(child.tagName.toLowerCase() == 'datalist'){
                        datalist = child;
                        break;
                    }
                }
                if(datalist != null){
                    const options = datalist.getElementsByTagName('option');
                    for(let j = 0; j < options.length; j++){
                        const option = options[j];
                        this.menu_options.push({
                            name : option.getAttribute('data-name'),
                            icon : option.getAttribute('data-icon'),
                            target : option
                        })
                    }
                    break;
                }
            }
            const menu = this.$refs.menu;
            Object.assign(menu.style, {
                top: Math.min(event.clientY, window.innerHeight - menu.offsetHeight) + 'px',
                left : Math.min(event.clientX, window.innerWidth - menu.offsetWidth) + 'px',
                opacity : 1,
                pointerEvents : 'all'
            });            
            document.body.addEventListener('click', this.close);
        },
        close(event){
            const path = event.composedPath();
            const length = path.length;
            const menu = this.$refs.menu;
            for(let i = 0; i < length; i++){
                const item = path[i];
                if(item == menu){ return; }
            }
            this.hide();
        },
        execute(target){
            target.dispatchEvent(new CustomEvent('contextmenu'));
            this.hide();
        },
        hide(){
            const menu = this.$refs.menu;
            Object.assign(menu.style, {
                opacity : 0,
                pointerEvents : 'none'
            });
            document.body.removeEventListener('click', this.close);
        }
    }
}
</script>
<style scoped>
#graph_context_menu{
    position: fixed;
    opacity: 0;
    pointer-events: none;
    min-width: 200px;
    transition: opacity 0.2s ease;
    color: black;
    overflow: hidden;
}

#graph_context_menu > div{
    display: block;
    padding:5px 10px;
    text-align: left;
    transition: background-color 0.2s ease;
}

#graph_context_menu > div:hover{
    background-color: #f2f2f2;
}
#graph_context_menu > div:active{
    background-color: #e6e6e6;
}

#graph_context_menu > div > svg{
    display: inline-block;
    vertical-align: middle;
    width:24px;
    height: 24px;
}

#graph_context_menu > div > span{
    display: inline-block;
    vertical-align: middle;
    line-height: 24px;
    margin-left: 15px;
    white-space: nowrap;
    font-size:13px;
}
</style>