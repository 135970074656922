var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":"","fixed":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Modifica Domanda")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaModifica}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva Domanda")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiDialogDomanda}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-2 pa-0"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Codice"},model:{value:(_vm.domanda_sel_tmp.cod_quesito),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "cod_quesito", $$v)},expression:"domanda_sel_tmp.cod_quesito"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Descrizione"},model:{value:(_vm.domanda_sel_tmp.des_quesito),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "des_quesito", $$v)},expression:"domanda_sel_tmp.des_quesito"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_tipi,"item-text":"des","item-value":"id_tabella","label":"Tipo Domanda"},on:{"change":function($event){_vm.items_tipi.forEach(function (e) {
                if (e.id_tabella === _vm.domanda_sel_tmp.tipo_quesito) {
                  _vm.domanda_sel_tmp.des_tipo = e.des;
                }
              })}},model:{value:(_vm.domanda_sel_tmp.tipo_quesito),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "tipo_quesito", $$v)},expression:"domanda_sel_tmp.tipo_quesito"}})],1)],1)],1),(
        _vm.domanda_sel_tmp.tipo_quesito === _vm.id_tipo_domanda_immagine &&
        _vm.id_tipo_domanda_immagine != null
      )?_c('Controllo',{attrs:{"clear_on_save":false,"immagine_standard_editor":_vm.domanda_sel_tmp.img != null ? _vm.domanda_sel_tmp.img : '',"show_close_button":false,"show_editor_save_button":false,"tabella_supporto":'',"titolo_editor":"Editor d'Immagine","visible":true}}):_vm._e()],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }