import { render, staticRenderFns } from "./Produzione2.vue?vue&type=template&id=1c13d022&scoped=true&"
import script from "./Produzione2.vue?vue&type=script&lang=js&"
export * from "./Produzione2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c13d022",
  null
  
)

export default component.exports