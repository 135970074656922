<template>
  <div class="accettazione_materiale">
    <v-overlay :value="caricamento">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <header>
      <v-toolbar dense color="indigo" dark>
        <v-toolbar-title>Accettazione Materiale</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              light
              v-on="on"
              v-bind="attrs"
              @click="$router.push('/home/production')"
              ><v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
    </header>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="3">
          <v-row align="center">
            <v-col>
              <v-text-field
                class="ml-2"
                label="Fornitore"
                prepend-icon="mdi-truck"
                v-model="ricerca.fornitore"
                readonly
              ></v-text-field>
            </v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-on="on" v-bind="attrs" @click="getAnagrafica"
                  ><v-icon color="primary">mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Cerca </span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="2" class="ml-3">
          <InputCalendario label="Data Inizio" v-model="ricerca.data_inizio" />
        </v-col>
        <v-col cols="2">
          <InputCalendario label="Data Fine" v-model="ricerca.data_fine" />
        </v-col>
        <v-spacer></v-spacer>
        <v-checkbox
          v-if="power"
          @change="$emit('checkbox')"
          label="Lista Completa"
          color="success"
          v-model="checkbox"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                @click="$emit('resetFiltro')"
              >
                <v-icon color="primary">mdi-filter-off</v-icon>
              </v-btn>
            </template>
            <span>Rimuovi filtri</span>
          </v-tooltip>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                @click="$emit('get_documento')"
              >
                <v-icon color="primary">mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Avvia la ricerca</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- TAB  -->
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="tabella.testata" :items="lista_bolle">
            <template v-slot:top>
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text"
                  >Lista Documenti</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="$emit('goToDettaglioEmpty')"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Documenti</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <!-- Tab -->
            <template v-slot:item="{ item }">
              <tr
                class="record_tabella"
                :data-deleted="item.validita == 0 ? true : false"
              >
                <td style="text-align: left">
                  <v-btn
                    @click="$emit('goToDettaglio', item.id_documento)"
                    class="mx-1"
                    small
                    :disabled="item.validita == 0 ? true : false"
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td
                  v-for="(colonna, i) in tabella.testata"
                  :key="i"
                  v-show="colonna.value != null"
                >
                  {{ item[colonna.value] }}
                </td>
                <td style="text-align: left">
                  <v-btn
                    v-show="item.synch == 1 ? false : true"
                    @click="clickExport(item)"
                    small
                  >
                    <v-icon color="primary">mdi-export</v-icon>
                  </v-btn>
                </td>
                <td style="text-align: left">
                  <v-btn
                    small
                    @click="clickElimina(item)"
                    v-show="item.synch == 1 ? false : true"
                    ><v-icon
                      :color="item.validita == 0 ? 'primary' : 'error'"
                      >{{
                        item.validita == 1 ? "mdi-close" : "mdi-restore"
                      }}</v-icon
                    ></v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- DIALOG CANCELLA BOLLA  -->
    <v-dialog v-model="dialog_cancellazione" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          Sei sicuro di voler eliminare questo elemento?
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAnnullaCancellazione()"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancellaBolla(bolla_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG RICHERCA FORNITORE -->
    <v-dialog v-model="dialogSearchAnagrafica" width="700" persistent>
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>Seleziona Fornitore</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="chiudiDialogRicercaFornitore" light small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row align="center">
            <v-col cols="1"></v-col>
            <v-col cols="8">
              <v-text-field
                v-model="ricerca_anagrafica"
                label="Cerca Fornitore"
              ></v-text-field>
            </v-col>
            <v-col cols="2" align="right">
              <v-btn @click="getAnagrafica" small>
                <v-icon color="primary">mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="ragioneSociale"
                :items="ragioneSocialeLista"
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="record_tabella"
                    :data-deleted="item.validita == 0 ? true : false"
                  >
                    <td>
                      <v-btn small @click="selezionaAnagrafica(item)"
                        ><v-icon color="primary">mdi-check</v-icon></v-btn
                      >
                    </td>
                    <td
                      v-for="(colonna, i) in ragioneSociale"
                      :key="i"
                      v-show="colonna.value != null"
                    >
                      {{ item[colonna.value] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- SNACKBAR -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import InputCalendario from "./InputCalendario.vue";
export default {
  name: "AccettazioneMateriale",
  components: {
    InputCalendario,
  },
  props: {
    lista_bolle: Array,
    fornitore: String,
    id_anagrafica: Number,
    data_inizio: String,
    data_fine: String,
    admin_power: Boolean,
  },
  data() {
    return {
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      caricamento: false,
      full_bolla: null,
      power: localStorage.id_profilo,
      checkbox: this.admin_power,
      ragioneSocialeLista: [],
      ricerca_anagrafica: null,
      ragioneSociale: [
        { text: null, value: null, align: "start" },
        { text: "Ragione Sociale", value: "ragione_sociale" },
        { text: "Indrizzo", value: "indirizzo" },
      ],
      dialogSearchAnagrafica: null,
      dialog_cancellazione: false,
      bolla_sel: {
        id_documento: -1,
        cod_documento: "DDTR",
        num_documento: null,
        data_documento: null,
        id_anagrafica: null,
        tipo_soggetto: null,
        validita: 1,
        ragione_sociale: null,
        dettaglio: [],
      },
      ricerca: {
        fornitore: this.fornitore,
        id_anagrafica: this.id_anagrafica,
        data_inizio: this.data_inizio,
        data_fine: this.data_fine,
      },
      tabella: {
        testata: [
          { text: "Modifica", value: null, sortable: false },
          { text: "Numero Documento", value: "num_documento" },
          { text: "Fornitore", value: "ragione_sociale" },
          { text: "Data", value: "data_documento" },
          { text: "Esporta", value: null, sortable: false },
          { text: "Elimina", value: null, sortable: false },
        ],
      },
    };
  },
  mounted() {
    if (!(this.ricerca.data_fine && this.ricerca.data_inizio)) {
      this.getDate();
      this.$emit("get_documento");
    }
  },
  methods: {
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
    getDate() {
      let data = new Date();

      // data fine (oggi)
      let aaaa, mm, gg;
      aaaa = data.getFullYear() + "-0";
      mm = data.getMonth() + 1 + "-";
      gg = data.getDate();

      if (gg < 10) {
        gg = "0" + data.getDate();
      }

      this.ricerca.data_fine = aaaa + mm + gg;

      // data inizio (sett prima)
      let gg1, mm1, aaaa1, month, year, daysMonth;

      aaaa1 = data.getFullYear() + "-0";
      gg1 = data.getDate() - 7;
      month = data.getMonth();
      year = data.getFullYear();

      daysMonth = parseFloat(this.daysInMonth(month, year));

      // anno di inizio e fine diverso
      if (gg1 < 0 && month == 1) {
        aaaa1 = data.getFullYear() - 1 + "-0";
      }

      // mese di inizio e fine diverso
      if (gg1 > 0) {
        if (gg1 < 10) {
          mm1 = data.getMonth() + 1 + "-0";
        } else {
          mm1 = data.getMonth() + 1 + "-";
        }
      } else {
        mm1 = data.getMonth() + "-";
        gg1 = data.getDate() + daysMonth - 7;
      }

      this.ricerca.data_inizio = aaaa1 + mm1 + gg1;

      // console.log(this.ricerca.data_inizio);
      // console.log(this.ricerca.data_fine);

      this.$emit(
        "data_cambiata",
        this.ricerca.data_inizio,
        this.ricerca.data_fine,
        this.ricerca.fornitore
      );
    },
    chiudiDialogRicercaFornitore() {
      this.dialogSearchAnagrafica = false;
      this.ricerca_anagrafica = null;
    },
    cancellaBolla(item) {
      this.getFullItem(item);

      this.dialog_cancellazione = false;
    },
    getFullItem(item) {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getdocumento",
          token: localStorage.user_token,
          id_documento: item.id_documento,
        },
      };
      this.$store.dispatch("api", richiesta).then((risposta) => {
        this.full_bolla = Object.assign({}, risposta.data.documento[0]);
        console.log(this.full_bolla);
        this.full_bolla.dettaglio.forEach((elem) => {
          elem.validita = 0;
        });

        this.full_bolla.validita = 0;

        this.setDocumento(this.full_bolla);
      });
      // this.full_bolla = null;
    },
    clickAnnullaCancellazione() {
      let bolla_tmp = {
        id_documento: -1,
        cod_documento: "DDTR",
        num_documento: null,
        data_documento: null,
        id_anagrafica: null,
        tipo_soggetto: null,
        validita: 1,
        ragione_sociale: null,
        dettaglio: [],
      };

      this.bolla_sel = Object.assign({}, bolla_tmp);

      this.dialog_cancellazione = false;
    },
    clickElimina(item) {
      if (item.validita == 0) {
        item.validita = 1;
        this.setDocumento(item);
      } else {
        this.bolla_sel = Object.assign({}, item);
        this.dialog_cancellazione = true;
      }
    },
    clickExport(item) {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getdocumento",
          token: localStorage.user_token,
          id_documento: item.id_documento,
        },
      };
      this.$store.dispatch("api", richiesta).then((risposta) => {
        this.full_bolla = Object.assign({}, risposta.data.documento[0]);
        console.log(this.full_bolla);

        this.full_bolla.dettaglio.forEach((elem) => {
          if (elem.lotto_qp) {
            elem.lotto_qp = elem.cod_lotto.replace("00", "");
          }
          elem.cod_um = elem.um;
        });
        console.log("entrato click exp");
        this.setExport(this.full_bolla);
      });

      this.full_bolla = null;
    },
    getAnagrafica() {
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          token: localStorage.getItem("user_token"),
          cod_anagrafica: this.cod_anagrafica,
          des_anagrafica: this.ricerca_anagrafica,
          tipo_anagrafica: "F",
        },
      };
      this.$store.dispatch("api", searchReq).then((res) => {
        this.dialogSearchAnagrafica = true;
        this.overlay = false;
        this.ragioneSocialeLista = res.data;
        this.dialogSearchAnagrafica = true;
      });
    },

    setDocumento(item) {
      // let split = item.data_documento.split("/");
      // item.data_documento = split[2] + "-" + split[1] + "-" + split[0];
      // console.log(item.data_documento);

      let arr = [];
      arr.push(item);

      let richiesta = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setdocumento",
          token: localStorage.user_token,
          documento: arr,
        },
      };
      console.log(JSON.stringify(richiesta.richiesta));
      this.$store.dispatch("api", richiesta).then((risposta) => {
        console.log(risposta);
        this.$emit("get_documento");
      });
    },
    setExport(item) {
      item.causale = "003";
      item.synch = 1;
      item.magazzino = "MP";
      item.dep_pr_de = "";
      item.id_anagrafica = item.cod_anagrafica;

      let split = item.data_documento.split("/");
      item.data_documento = split[0] + "-" + split[1] + "-" + split[2];
      item.data_documento;

      let splitDue = item.date_ins.split(" ");
      item.date_ins = splitDue[0];

      delete item.cod_anagrafica;

      let arrChildren = [];
      item.dettaglio.forEach((elem) => {
        if (elem.validita != 0) {
          arrChildren.push(elem);
        }
      });
      item.dettaglio = arrChildren;

      console.log(item);

      let arr = [];
      arr.push(item);

      this.caricamento = true;

      let richiesta = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "exportddt",
          token: localStorage.user_token,
          documento: arr,
        },
      };
      console.log(JSON.stringify(richiesta.richiesta));
      this.$store
        .dispatch("api", richiesta)
        .then((risposta) => {
          this.caricamento = false;
          if (risposta.status === 200) {
            console.log("export ok 200");
            this.snackbar = true;
            this.snackbar_text = "Esportazione avvenuta con successo";
            this.snackbar_background = "success";
            this.snackbar_icon_color = "white";
            this.snackbar_text_color = "white";
            this.snackbar_icon = "mdi-close";
          }

          this.$emit("get_documento");
        })
        .catch((risposta) => {
          console.log(risposta.response.status);
          this.caricamento = false;
          this.snackbar = true;
          this.snackbar_text = "Esportazione fallita";
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
        });
    },
    selezionaAnagrafica(fornitore) {
      this.ricerca.fornitore = fornitore.ragione_sociale;
      this.ricerca.id_anagrafica = fornitore.id_anagrafica;
      this.dialogSearchAnagrafica = false;
    },
  },
  watch: {
    ricerca: {
      deep: true,
      handler() {
        if (
          this.ricerca.data_inizio != this.data_inizio ||
          this.ricerca.data_fine != this.data_fine ||
          this.ricerca.fornitore != this.fornitore
        ) {
          this.$emit(
            "data_cambiata",
            this.ricerca.data_inizio,
            this.ricerca.data_fine,
            this.ricerca.fornitore,
            this.ricerca.id_anagrafica
          );
        }
      },
    },
  },
};
</script>
<style>
.accettazione_materiale {
  position: relative;
}
.accettazione_materiale > header {
  border-radius: inherit;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.record_tabella {
  text-align: left;
}
.record_tabella > td {
  text-align: left;
}
.record_tabella[data-deleted] {
  background-color: #ffcccc !important;
}
.record_tabella[data-deleted]:hover {
  background-color: #ffb3b3 !important;
}
</style>