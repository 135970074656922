<template>
    <div class="graph_widget graph_card" :id="'widget-'+index">
        <input type="checkbox" :id="'graph_widget_options_'+index" />
        <header :title="title" >
            <datalist class="graph_contextmenu">
                <option value="Copia grafico" @contextmenu="emit(5)" :disabled="(isAdmin()==true)?false:true">copy</option>
                <option value="Modifica grafico" @contextmenu="emit(1)" :disabled="(isAdmin()==true)?false:true">pencil</option>
                <option value="Visualizza a schermo intero" @contextmenu="emit(3)" >fullscreen</option>
                <option value="Esporta grafico" @contextmenu="emit(6)" >download</option>
                <option value="Query compilata" @contextmenu="emit(7)" :disabled="(isAdmin()==true)?false:true">database_cog</option>
                <option value="Grafico derivato" @contextmenu="emit(8)" :disabled="(isAdmin()==true)?false:true">math_function</option>
            </datalist>
            <h1 @mousedown="moveStart" >{{title}}</h1>
            <label title="Parametri" @click="emit(4)">
                <GraphSVG name='tune'/>
            </label>             
            <label title="Visualizza Tabella" @click="emit(2)">
                <GraphSVG name='table'/>
            </label>   
            <label title="Schermo Intero" @click="emit(3)">
                <GraphSVG name='fullscreen'/>
            </label> 
            <label title="Modifica" @click="emit(1)">
                <GraphSVG name='pencil'/>
            </label>
            <label title="Visualizza query compilata" @click="emit(7)">
                <GraphSVG name='database_cog'/>
            </label>                      
        </header>
        <div>                        
            <main>
                <Grafico :index="Number(index)" />
            </main>
            <GraphSVG @mousedown="resizeStart" name='resize_angle'/>
        </div>        
    </div>    
</template>
<script>
import Grafico from "@/components/GRAPH/Grafici/Grafici.vue";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import WIDGET from "./Widget.js";
export default {
    name : "Widget",
    props : {
        index : Number,
        title : String,        
    },
    components : {
        Grafico,
        GraphSVG,
    },
    data(){
        return{
            WIDGET : new WIDGET(),
        }
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        isAdmin(){return Number(localStorage.id_profilo)==1;},
        //  Metodo per inviare informazioni alla dashboard
        emit(event_code){
            let event = new CustomEvent('widget_event',{detail:{
                widget : this.index,
                code : event_code
            }});
            document.body.dispatchEvent(event);
        },
        //  Metodo per la preparazione al trascinamento del widget
        moveStart(event){            
            this.WIDGET.start_moving(this.index,event);
            let dashboard = document.getElementById("dashboard");
            if(dashboard != null && dashboard != undefined){dashboard.style.pointerEvents = "none";}           
            document.body.addEventListener("mousemove",this.moveHandler);
            document.body.addEventListener("mouseup", this.mouseUpLeaveHandler);
        },
        //  Metodo per la preparazione al ridimensionamento del widget
        resizeStart(){            
            this.WIDGET.resize_active = this.index;
            this.WIDGET.setZ();
            let dashboard = document.getElementById("dashboard");
            if(dashboard != null && dashboard != undefined){dashboard.style.pointerEvents = "none";}
            document.body.addEventListener("mousemove",this.resizeHandler);
            document.body.addEventListener("mouseup", this.mouseUpLeaveHandler);
        },
        //  Incapsulamento dei metodi per il movimento/ridimensionamento del widget 
        moveHandler(event){this.WIDGET.move(event);},
        resizeHandler(event){this.WIDGET.resize(event)},
        //  Metodo per gestire la fine delle operazioni di movimento/ridimensionamento del widget
        mouseUpLeaveHandler(){
            let widget = this.WIDGET.stop();
            if(widget>-1){
                let resize_event = new CustomEvent('graph_resize',{detail:null});
                document.getElementById('chart-'+this.index).dispatchEvent(resize_event);
                document.body.removeEventListener("mousemove",this.resizeHandler);
            }else{
                document.body.removeEventListener("mousemove",this.moveHandler);
            }
            document.body.removeEventListener("mouseup", this.mouseUpLeaveHandler);
        },
    }
    
}
</script>
<style src="./Widget.css" scoped></style>