<template>
    <div class="report_manager">
        <v-card>
            <!-- TOOLBAR -->
            <v-toolbar dense color="primary" dark align="center">
                <v-row>
                    <v-col cols="5" align="left">
                        <v-toolbar-title>Gestione Report Aziende</v-toolbar-title>
                    </v-col>
                    <v-col cols="2" align="center">
                        <v-menu v-model="menu_test.show" bottom :nudge-width="250" offset-y :nudge-left="125" :nudge-bottom="5" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small light v-bind="attrs" v-on="on" >
                                    <v-icon color="indigo">mdi-test-tube</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-container>
                                    <v-row justify="center">
                                        <v-col cols="12" align="center">
                                            <v-select v-model="menu_test.customer" class="mt-5 pa-0" hide-details style="width:230px" :items="filterCustomers(filter)" item-text="ragione_sociale" label="Cliente" return-object />
                                        </v-col>                                        
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" align="center">
                                            <v-select v-model="menu_test.report" class="pa-0" style="width:230px" hide-details :items="menu_test.customer.report" item-text="cod_report" return-object label="Report" />
                                        </v-col>                                        
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="12" align="center">
                                            <v-text-field v-model="menu_test.doc_id" class="pa-0" type="number" label="ID Documento" style="width:230px" />
                                        </v-col>                                        
                                    </v-row>
                                </v-container>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn text color="primary" @click="testReport(menu_test.report,menu_test.doc_id)">Esegui Test</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-col>
                    <v-col cols="4" align="right">
                        <v-btn small light @click="getReportAziende">
                            <v-icon color="primary">mdi-autorenew</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="1" align="right">
                        <v-btn small light @click="$router.push('/home')">
                            <v-icon color="error">mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </v-col>                    
                </v-row>
            </v-toolbar>
            <!-- BODY -->
            <v-container>
                <div class="tree_container">
                    <v-text-field v-model="filter" label="Filtra Clienti" prepend-icon="mdi-account-search"/>
                    <v-treeview :items="filterCustomers(filter)" item-key="id" open-on-click item-children="report" >
                        <template v-slot:prepend="{item}">
                            <v-icon>
                                {{ item.ragione_sociale == undefined ? 'mdi-file' : 'mdi-account-tie' }}
                            </v-icon>
                        </template>
                        <template v-slot:label="{item}">
                            <span class="tree_label" :data-customer="item.ragione_sociale != undefined">
                                {{ item.ragione_sociale == undefined ? item.cod_report : item.ragione_sociale }}
                            </span>                         
                        </template>
                        <template v-slot:append="{item}">
                            <div v-if="item.ragione_sociale == undefined">
                                <!-- MODIFICA REPORT -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click="showEditDialog(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Modifica Report</span>
                                </v-tooltip>
                                <!-- SCARICA REPORT -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click.stop="downloadReportModel(item)">
                                            <v-icon>mdi-download</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Scarica Report</span>
                                </v-tooltip>
                                <!-- ELIMINA REPORT -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click.stop="showDeleteDialog(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Elimina Report</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.ragione_sociale != undefined">
                                <!-- AGGIUNGI REPORT -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" @click.stop="showEditDialog(null,item)">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Aggiungi Report</span>
                                </v-tooltip>
                            </div> 
                        </template>
                    </v-treeview>
                </div>
            </v-container>
        </v-card>
        <!-- DIALOG CONFERMA ELIMINAZIONE -->
        <v-dialog v-model="alert.delete.show" width="500" persistent>
            <v-card>
                <v-container>
                    <v-card-title>Eliminare {{alert.delete.report_name}}" per {{alert.delete.customer_name}}?</v-card-title> 
                    <v-card-text class="text-left">Le funzioni di stampa ancora collegate a questo documento genereranno un errore.</v-card-text>
                </v-container>
                <v-card-actions>
                    <v-btn text @click="alert.delete.show = false">Annulla</v-btn>
                    <v-spacer />
                    <v-btn text color="error" @click="deleteReport">Elimina</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- DIALOG MODIFICA / CREA -->
        <v-dialog v-model="alert.edit.show" persistent width="900">
            <v-card v-if="alert.edit.show == true">
                <v-toolbar dense dark color="primary">
                    <v-row>
                        <v-col cols="5" align="left">
                            <v-toolbar-title>{{alert.edit.text}}</v-toolbar-title>
                        </v-col>
                        <v-col cols="2">
                            <v-btn small light @click="setReportAzienda(alert.edit.report)">
                                <v-icon color="success">mdi-floppy</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" align="right">
                            <v-menu v-model="alert.edit.menu_test.show" bottom :nudge-width="250" offset-y :nudge-left="125" :nudge-bottom="5" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small light v-bind="attrs" v-on="on" >
                                        <v-icon color="indigo">mdi-test-tube</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="12" align="center">
                                                <v-text-field v-model="alert.edit.menu_test.doc_id" class="pa-0" type="number" label="ID Documento" style="width:230px" />
                                            </v-col>                                        
                                        </v-row>
                                    </v-container>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn text color="primary" @click="testReport(alert.edit.report,alert.edit.menu_test.doc_id)">Esegui Test</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-col>
                        <v-col cols="1" align="right">
                            <v-btn small light @click="alert.edit.show = false">
                                <v-icon color="error">mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </v-col> 
                    </v-row>
                </v-toolbar>
                <v-container>
                    <v-row justify="center" >
                        <v-col cols="6" >
                            <v-text-field v-model="alert.edit.report.cod_report" label="Nome report" prepend-icon="mdi-file"  />
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-row align="center" justify="center" v-for="(query,i) in alert.edit.report.query" :key="i">
                        <v-col cols="6">
                            <v-text-field hide-details prepend-icon="mdi-table-cog" :label="`Query per ${query.nome_tabella}`" readonly @click="showQueryDialog(query,i)" v-bind:value="query.query" />
                        </v-col>
                    </v-row>                    
                </v-container>
                <v-card-actions>
                    <v-btn text :disabled="alert.edit.report.modello == null" @click="downloadReportModel()">
                        <v-icon class="mr-5" v-if="alert.edit.report.modello != null">mdi-download</v-icon>
                        {{ alert.edit.report.modello == null ? 'Nessun modello allegato' : 'Scarica modello offline' }}
                    </v-btn>
                    <v-spacer />
                    <v-btn text color="primary" @click="uploadReportModel">
                        <v-icon class="mr-5" >mdi-upload</v-icon>
                        Carica modello
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- DIALOG EDITOR QUERY -->
        <v-dialog v-model="alert.query.show" width="900" persistent>
            <v-card v-if="alert.query.show == true">
                <v-toolbar dense dark color="primary">
                    <v-row>
                        <v-col cols="5" />
                        <v-col cols="2">
                            <v-btn small light @click="saveQuery">
                                <v-icon color="success">mdi-floppy</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="5" align="right">
                            <v-btn small light @click="alert.query.show = false">
                                <v-icon color="error">mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </v-col> 
                    </v-row>
                </v-toolbar>
                <v-container>
                    <TextEditor v-model="alert.query.query" :template="editor" />
                </v-container>
            </v-card>
        </v-dialog>
    </div>    
</template>
<script>
import TextEditor from "@/components/GRAPH/Dashboard/Editor/TextEditor.vue";
export default {
    name : 'ReportManager',
    components : { TextEditor },
    data(){
        return{ 
            alert : {
                delete : new Object(),
                edit : new Object(),
                query : new Object(),
            }, 
            customers: [],
            editor : [
                {
                    targetProperty : '',
                    color : 'red',
                    keyword : '@',
                    targetField : null,
                    template : {},
                }
            ],
            filter : null,
            menu_test : {                
                customer : new Object(),
                doc_id : null,
                report : null,
                show : false
            },
            NETWORK : null,             
        }        
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.getReportAziende();
    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        filterCustomers(filter){
            if(filter == null || filter == ''){ return this.customers; }
            const result = new Array();
            filter = filter.toLowerCase();
            for(let i = 0; i < this.customers.length; i++){
                const customer = this.customers[i];
                if(customer.ragione_sociale.toLowerCase().indexOf(filter) >= 0 ){
                    result.push(customer);
                }                
            }
            return result;
        },
        deleteReport(){
            const report = window.cloneDeep(this.alert.delete.report);
            report.validita = 0;
            this.setReportAzienda(report);
        },
        downloadReportModel(src_report = null){
            const report = src_report == null ? this.alert.edit.report : src_report;
            const model = decodeURIComponent(atob(report.modello));            
            const _blob = new Blob([model], { type: 'text/plain' });
            const url = window.URL.createObjectURL(_blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${report.customer_name} - ${report.cod_report}.frx`;
            a.click();
        },
        uploadReportModel(){
            const that = this;
            const input = document.createElement('input');
            input.setAttribute('type','file');
            input.setAttribute('accept','.frx');
            input.onchange = function(){
                const reader = new FileReader();
                reader.onload = function () {
                    const parser = new DOMParser();
                    const project = parser.parseFromString(reader.result,'text/xml');
                    const tables = project.getElementsByTagName('TableDataSource');
                    const report = that.alert.edit.report;
                    const tables_result = new Array();
                    for(let i=0; i < tables.length; i++){
                        const table = tables[i].getAttribute('Name');
                        let found = false;
                        for(let j = 0; j < report.query.length; j++){
                            if(report.query[j].nome_tabella == table){
                                found = true;
                                tables_result.push(report.query[j]);
                                break;
                            }
                        }
                        if(!found){
                            tables_result.push({nome_tabella:table,query:null});
                        }
                    }
                    report.query = tables_result;
                    report.modello = btoa(encodeURIComponent(reader.result));
                }
                reader.readAsText(input.files[0]);
            }
            input.click();
        },
        getReportAziende(){
            const that = this;
            const request = {
                controller: 'Report',
                body: {
                    action: 'getreportazienda',
                    token: localStorage.user_token,
                },
                method: 'POST',
                on: {
                    load: function (response) {
                        response = response.azienda;
                        for(let i=0; i < response.length; i++){
                            const customer = response[i];                            
                            customer.id = customer.id_azienda.toString();
                            for(let j=0; j < customer.report.length; j++){
                                const report = customer.report[j];
                                report.id = `${report.id_azienda}.${report.id_report}`;
                                report.customer_name = customer.ragione_sociale;
                                try{
                                    report.query = JSON.parse(decodeURIComponent(escape(atob(report.query))));
                                    for(let z = 0; z < report.query.length; z++){
                                        try{
                                            report.query[z].query = decodeURIComponent(atob(report.query[z].query));
                                        }catch(error){
                                            report.query[z].query = null;
                                        }                                        
                                    }
                                }catch(error){
                                    report.query = new Array();
                                }
                            }
                        }
                        that.customers = response;
                    },
                },
            };
            this.NETWORK.AjaxRequest(request);
        },
        setReportAzienda(report){
            report = window.cloneDeep(report);
            this.alert.edit.show = false;
            try{
                const query = report.query;
                for(let i = 0; i < query.length; i++){
                    query[i].query = btoa(encodeURIComponent(query[i].query));
                }
                report.query = btoa(unescape(encodeURIComponent(JSON.stringify(report.query))));
            }catch(error){
                report.query = btoa(unescape(encodeURIComponent(JSON.stringify([]))));
            }
            
            const that = this;
            const request = {
                controller: 'Report',
                body: {
                    action: 'setreportazienda',
                    token: localStorage.user_token,
                    id_azienda : report.id_azienda,
                    report : [report]
                },
                method: 'PUT',
                on: {
                    load: function (){
                        that.getReportAziende();
                        that.alert.delete.show = false;                        
                    },
                },
            };
            this.NETWORK.AjaxRequest(request);
        },
        showDeleteDialog(report){
            this.alert.delete = {
                customer_name : report.customer_name,
                report_name : report.cod_report,
                report : report,
                show : true
            }
        },
        showEditDialog(report, customer = null){
            let text = null;
            if(customer != null){
                report = {
                    id_report : -1,
                    id_azienda : customer.id_azienda,
                    modello : null,
                    query : new Array(),
                    validita : 1,
                    cod_report : null,
                }
                text = `Crea nuovo report per ${customer.ragione_sociale}`;
            }else{
                text = `Modifica "${report.cod_report}" per ${report.customer_name}`;
            }
            this.alert.edit = {
                menu_test : {
                    doc_id : null,
                    show : false
                },
                show : true,
                report : window.cloneDeep(report),
                text : text
            }
        },
        saveQuery(){
            const query_alert = this.alert.query;
            const target = this.alert.edit.report.query[query_alert.index];
            delete query_alert.query.null;
            target.query = query_alert.query.query;
            this.alert.query = new Object();
        },
        showQueryDialog(query, index){
            this.alert.query = { 
                show : true, 
                query : query, 
                index : index 
            }
        },
        testReport(report,doc_id){            
            if(report == null || doc_id == null){ return; }
            report = window.cloneDeep(report);
            try{
                const query = report.query;
                for(let i = 0; i < query.length; i++){
                    query[i].query = btoa(encodeURIComponent(query[i].query));
                }
                report.query = btoa(unescape(encodeURIComponent(JSON.stringify(report.query))));
            }catch(error){
                report.query = btoa(unescape(encodeURIComponent(JSON.stringify([]))));
            }

            const request = {
                controller: 'Report',
                body: {
                    action: 'testreportpdf',
                    token: localStorage.user_token,
                    report : [report],
                    id : doc_id,
                },
                method: 'POST',
                on: {
                    load: function (response){
                        const byteCharacters = atob(response);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                        const blobUrl = URL.createObjectURL(blob);
                        
                        const a = document.createElement('a');
                        a.href = blobUrl;
                        a.download = `test.pdf`;
                        a.click();                        
                    },
                },
            };
            this.NETWORK.AjaxRequest(request);
        }
    }
}
</script>
<style scoped>
    .report_manager{ padding : 20px; }
    .report_manager .tree_container{
        display: block;
        margin: 0 auto;
        max-width:600px;
        width:100%;
    }
    .report_manager .tree_label{
        font-size: 14px;
        width:100%;
        overflow-x:hidden;
        text-overflow: ellipsis;
        text-align : left;
        display: block;
    }
    .report_manager .tree_label[data-customer]{
        font-weight: bold;
        overflow: visible;
    }
</style>