var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-overlay',{staticStyle:{"z-index":"10"},attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"unset"},model:{value:(_vm.dialogElimina),callback:function ($$v) {_vm.dialogElimina=$$v},expression:"dialogElimina"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Questo elemento verrà eliminato definitivamente")])],1),_c('v-card-text',{staticClass:"mt-6"},[_c('h3',{staticStyle:{"text-align":"left !important"}},[_vm._v(" Sei sicuro? vuoi eliminare questa elemento? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":_vm.delSelectedItem}},[_vm._v("ANNULLA")]),_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":_vm.deleteFolder}},[_vm._v("CONFERMA")])],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"unset"},model:{value:(_vm.dialogArticolo),callback:function ($$v) {_vm.dialogArticolo=$$v},expression:"dialogArticolo"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Articolo Dettaglio")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.closedetailModal();
                _vm.dialogArticolo = false;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","depressed":""}},[_vm._v("mdi-window-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-6",staticStyle:{"max-height":"600px","overflow-y":"auto","overflow-x":"hidden"}},[_c('Articolo',{key:this.recursiveId,attrs:{"articoloId":this.recursiveId,"name":this.recursiveId}})],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Distinta Base")]),_c('v-spacer'),_c('Button',{attrs:{"bottone_class":"saveIcon","colore_icona":"success","idForm":"ARTICOLO_DISTINTA_BASE","idOggetto":"SALVA_ARTICOLO_DISTINTABASE","mdi_icona":"mdi-floppy","testo_tooltip":"Salva distinta"},on:{"buttonClick":_vm.saveDistinta}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.hideModal()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-window-close")])],1)]}}])},[_c('span',[_vm._v("Cancallare")])])],1),_c('v-card-subtitle',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchDistintaBase.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"disabled":this.disablesearch === true,"type":"text","label":"Ricerca Componente","placeholder":"Ricerca Componente","required":""},model:{value:(_vm.cod_articolo),callback:function ($$v) {_vm.cod_articolo=$$v},expression:"cod_articolo"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-5",attrs:{"small":""},on:{"click":_vm.searchDistintaBase}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#1a80b6","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca ")])])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-n16",staticStyle:{"max-height":"450px","overflow-y":"auto"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.LoadingVal,"headers":_vm.headers,"items":_vm.items,"dense":true},scopedSlots:_vm._u([{key:"item.quantita",fn:function(props){return [_c('v-text-field',{attrs:{"solo":"","flat":"","min":"0","dense":"","type":"number","name":"quantita"},model:{value:(props.item.quantita),callback:function ($$v) {_vm.$set(props.item, "quantita", $$v)},expression:"props.item.quantita"}})]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar),expression:"progressBar"}],attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"700"},model:{value:(_vm.dialogDistintaBase),callback:function ($$v) {_vm.dialogDistintaBase=$$v},expression:"dialogDistintaBase"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Distinta Base")]),_c('v-spacer'),_c('Button',{attrs:{"bottone_class":"saveIcon","colore_icona":"success","idForm":"ARTICOLO_DISTINTA_BASE","idOggetto":"SALVA_ARTICOLO_DISTINTABASE","mdi_icona":"mdi-floppy","testo_tooltip":"Salva distinta"},on:{"buttonClick":_vm.updateDistinta}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":""},on:{"click":_vm.closedialogDistintaBase}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-window-close")])],1)]}}])},[_c('span',[_vm._v("Cancallare")])])],1),_c('v-card-text',{staticStyle:{"max-height":"600px","overflow-y":"auto"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-form',{staticClass:"mt-n9 ml-n1",on:{"submit":function($event){$event.preventDefault();return _vm.searchDistintaBase.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"disabled":this.disablesearch === true,"type":"text","label":"Ricerca Componente","placeholder":"Ricerca Componente","required":""},model:{value:(_vm.cod_articolo),callback:function ($$v) {_vm.cod_articolo=$$v},expression:"cod_articolo"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mt-5",attrs:{"small":""},on:{"click":_vm.searchDistintaBase}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#1a80b6","size":"32"}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca ")])])],1)],1)],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.LoadingVal,"headers":_vm.headers,"items":_vm.items,"dense":true},scopedSlots:_vm._u([{key:"item.quantita",fn:function(props){return [_c('v-text-field',{attrs:{"solo":"","flat":"","min":"0","dense":"","type":"number","name":"quantita"},model:{value:(props.item.quantita),callback:function ($$v) {_vm.$set(props.item, "quantita", $$v)},expression:"props.item.quantita"}})]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar),expression:"progressBar"}],attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"400"},model:{value:(_vm.dialogEliminaDistintaBase),callback:function ($$v) {_vm.dialogEliminaDistintaBase=$$v},expression:"dialogEliminaDistintaBase"}},[_c('v-card',[_c('v-toolbar',{staticClass:"headline red lighten-2 ml-n2",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("elimina la distinta base")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"1"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-exclamation-thick")])],1),_c('v-col',[_c('p',[_vm._v(" Attenzione. Confermi la rimozione del componenti: N': "+_vm._s(this.deletingQuantity)+"-"+_vm._s(this.deletingItemDetail)+" ")])]),_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"md":"5"}}),_c('v-col',{attrs:{"md":"3"}},[_c('v-btn',{staticClass:"mx-auto",attrs:{"dark":"","tile":"","color":"green"},on:{"click":function($event){_vm.deleteItem();
                _vm.dialogEliminaDistintaBase = false;}}},[_vm._v("Invia")])],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"dark":"","tile":"","color":"red"},on:{"click":function($event){_vm.dialogEliminaDistintaBase = false}}},[_vm._v("Anulla")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 24 : 2}},[_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.color,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"size":64}},[_vm._v(_vm._s(_vm.mainIcon))]),_c('h2',{staticClass:"ml-16"},[_vm._v(_vm._s(_vm.snackbar_text))]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-toolbar',{staticClass:"indigo darken-1 rounded-t",attrs:{"primary-title":"","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Distinta Base")]),_c('v-spacer'),(!_vm.on)?_c('v-btn',{staticClass:"ml-16",attrs:{"color":"white","dark":"","fab":"","top":"","tile":"","small":""},on:{"click":_vm.showIcons}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-collapse")])],1):_vm._e(),(_vm.on)?_c('v-btn',{staticClass:"ml-16",attrs:{"dark":"","fab":"","top":"","tile":"","color":"white","small":""},on:{"click":_vm.hideIcons}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-expand")])],1):_vm._e()],1),_c('v-card-text',{staticClass:"text-left",staticStyle:{"overflow":"auto","max-height":"600px"}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressBar1),expression:"progressBar1"}],attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('v-treeview',{attrs:{"activatable":"","shaped":"","hoverable":"","open":_vm.open,"items":_vm.distintaBaseList,"item-key":"id","content":"6","open-on-click":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
                var item = ref.item;
                var open = ref.open;
return [_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(item.indice)+":")]),(item.id == 0)?_c('v-icon',[_vm._v(_vm._s(open ? "mdi-buffer" : "mdi-buffer"))]):_vm._e(),(item.id > 0)?_c('v-icon',[_vm._v(_vm._s(open ? "mdi-text-short" : "mdi-text-short"))]):_vm._e(),(item.id >= -1)?[_vm._v("N° "+_vm._s(item.quantita)+" "+_vm._s(item.cod_articolo)+" ")]:_vm._e()]}},{key:"append",fn:function(ref){
                var item = ref.item;
return (_vm.hideicons === true)?[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.id_rif == -1),expression:"item.id_rif == -1"}]},[_c('Button',{attrs:{"colore_icona":"primary","idForm":"ARTICOLO_DISTINTA_BASE","idOggetto":"CREA_ARTICOLO_DISTINTABASE","mdi_icona":"mdi-plus","testo_tooltip":"Inserisci Componente"},on:{"buttonClick":_vm.showModal}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.id_rif == 0),expression:"item.id_rif == 0"}]},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.getDetail(item)}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio Componente")])])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.id_rif == 0),expression:"item.id_rif == 0"}]},[_c('Button',{attrs:{"classe_bottone":"ml-1","colore_icona":"primary","idForm":"ARTICOLO_DISTINTA_BASE","idOggetto":"MODIFICA_ARTICOLO_DISTINTABASE","mdi_icona":"mdi-pencil","testo_tooltip":"Modifica Componente"},on:{"buttonClick":function($event){return _vm.updateItem(item)}}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.id_rif == 0),expression:"item.id_rif == 0"}]},[_c('Button',{attrs:{"classe_bottone":"ml-1","colore_icona":"error","idForm":"ARTICOLO_DISTINTA_BASE","idOggetto":"ELIMINA_ARTICOLO_DISTINTABASE","mdi_icona":"mdi-window-close","testo_tooltip":"Cancella Componente"},on:{"buttonClick":function($event){return _vm.selectDistinta(item)}}})],1)]:undefined}}],null,true)})],1),_c('v-divider')],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }