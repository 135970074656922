<template>
  <v-card id="listener_registra_pezzi" style="height: 90%; overflow-y: auto">
    <v-toolbar
      class="white--text"
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title dense> Dichiarazione </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="8" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Utente:</span
              >
              <span style="font-size: 20px">{{ nome_utente }}</span>
            </v-col>
            <v-col cols="4" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Data:</span
              >
              <span style="font-size: 20px">{{
                oggi.year() + "/" + (oggi.month() + 1) + "/" + oggi.date()
              }}</span>
              </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Commessa:</span
              >
              <span style="font-size: 20px">{{
                work_order_sel.cod_commessa
              }}</span></v-col
            >
            <v-col cols="4" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Work Order:</span
              >
              <span style="font-size: 20px">{{
                work_order_sel.cod_fase
              }}</span></v-col
            >
            <v-col cols="4" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Macchina:</span
              >
              <span style="font-size: 20px">{{
                work_order_sel.cod_macchina
              }}</span></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="4" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Pezzi Registrati:</span
              >
              <span style="font-size: 20px">{{
                work_order_sel.pezzi_produzione
              }}</span></v-col
            >
            <v-col cols="4" class="justify-start d-flex"
              ><span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Progressivo Fase:</span
              >
              <span style="font-size: 20px">{{
                work_order_sel.pezzi_registrati
              }}</span></v-col
            >
            <v-col cols="4" class="justify-start d-flex">
              <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
                >Pezzi Rilevati:</span
              >
              <span style="font-size: 20px"
                ><b style="font-size: 22px">{{ pezzi_rilevati }}</b></span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12" class="justify-center d-flex"
              ><span
                style="font-size: 25px; line-height: 30px; margin-right: 10px"
              >
                Pezzi Conformi:
              </span></v-col
            >
            <v-col cols="12" class="justify-center d-flex"
              ><v-text-field
                background-color="#ffffff"
                dense
                height="50px"
                hide-details
                label=""
                style="
                  display: inline-block;
                  font-size: 30px;
                  font-weight: bold;
                  line-height: 35px;
                  max-width: 150px;
                  padding: 0px;
                "
                type="number"
                v-model="pezzi_prodotti"
              >
              </v-text-field
            ></v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="12" class="justify-end d-flex"
              ><v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="salvaEdEsci"
                    style="height: 80px; width: 180px"
                  >
                    <v-icon color="success" style="font-size: 40px">
                      mdi-floppy
                    </v-icon>
                  </v-btn>
                </template>
                <span>Salva ed Esci</span>
              </v-tooltip></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12" class="justify-end d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="error--text mr-5"
                    @click="clickIndietro"
                    style="height: 80px; width: 80px"
                  >
                    <v-icon style="font-size: 40px"> mdi-undo </v-icon>
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="success--text"
                    @click="clickAvanti"
                    style="height: 80px; width: 80px"
                  >
                    <v-icon style="font-size: 40px"> mdi-redo </v-icon>
                  </v-btn>
                </template>
                <span>Avanti</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="justify-start d-flex"
          style="
            font-size: 20px;
            line-height: 25px;
            padding: 10px;
            text-align: left;
          "
        >
          <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
            >Fase Successiva:</span
          >
          <span style="font-size: 20px">{{
            work_order_sel.fase_successiva
          }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="6">
          <v-textarea
            background-color="#ffffff"
            dense
            height="50px"
            hide-details
            label="Note Work Order"
            readonly
            style="
              display: inline-block;
              font-size: 15px;
              font-weight: bold;
              line-height: 35px;
              padding: 0px;
              width: 100%;
            "
            v-model="note_workorder"
          ></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-textarea
            background-color="#ffffff"
            dense
            height="50px"
            hide-details
            label="Note Registrazione"
            style="
              display: inline-block;
              font-size: 15px;
              font-weight: bold;
              line-height: 35px;
              padding: 0px;
              width: 100%;
            "
            v-model="note"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <v-row>
        <v-col cols="8" style="text-align: left">
          <v-row>
            <span class="ml-3 mb-5" style="color: #3399ff; font-size: 20px">
              Log Macchina:
            </span>
          </v-row>
          <v-row>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(header, i) in headers_log_macchina"
                      :key="i"
                      class="text-left"
                    >
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items_log_macchina"
                    :key="item.id_dato_macchina"
                  >
                    <td v-for="(header, i) in headers_log_macchina" :key="i">
                      {{ item[header.value] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="6"
              ><span style="font-size: 20px"
                ><b>Causale di Qualità</b></span
              ></v-col
            >
            <v-col cols="6"
              ><span style="font-size: 20px; margin-left: -3rem"
                ><b>Pezzi</b></span
              ></v-col
            >
          </v-row>
          <v-row
            align="center"
            :key="causale.id_causale"
            v-for="causale in causali"
            style="margin: 0px; padding: 0px"
          >
            <v-col cols="6">{{ causale.des }}</v-col>
            <v-col cols="5"
              ><v-text-field
                background-color="#ffffff"
                dense
                hide-details
                label=""
                outlined
                style="
                  border-radius: 0px;
                  display: inline-block;
                  font-size: 20px;
                  max-width: 100px;
                "
                type="number"
                v-model="causale.pezzi"
              >
              </v-text-field
            ></v-col>
            <!--<v-col cols="1">
              <v-btn class="ml-n4" @click="clickControllo(causale.id_causali_produzione)" small color="primary"
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            </v-col>-->
          </v-row>
        </v-col>
      </v-row>
      <!--<v-row v-for="causale in causali" :key="causale.id_causali_produzione">
        <div
        :id="causale.id_causali_produzione"
          style="
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border: 1px solid black;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5px;
            width: 100px;
          "
        ></div>
      </v-row>-->
    </v-card-text>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ snackbar_icon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="controllo_dialog_visible">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Controllo Qualità</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn @click="controllo_dialog_visible = false" small
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text style="max-height: 500px; overflow-y: auto">
          <Controllo />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import moment from "moment/moment";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
export default {
  name: "RegistraPezzi",
  components: { Controllo },
  props: {
    flag_em_registrazione: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    work_order_sel: { type: Object, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      nome_utente: localStorage.getItem("nominativo"),
      note: "",
      note_workorder: "",
      oggi: moment(),
      pezzi_prodotti: null,
      pezzi_rilevati: null,

      // Modal
      controllo_dialog_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_text: "",

      // Array
      causali: [],
      headers_log_macchina: [],
      items_log_macchina: [],
    };
  },
  watch: {
    pezzi_prodotti: {
      immediate: true,
      handler() {
        if (
          parseInt(this.pezzi_prodotti) +
            parseInt(this.work_order_sel.pezzi_registrati) >
          parseInt(this.work_order_sel.quantita)
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_text =
            "Quantità prodotta maggiore della quantità pianificata.";
        }

        if (
          parseInt(this.pezzi_prodotti) +
            parseInt(this.work_order_sel.pezzi_registrati) ==
          parseInt(this.work_order_sel.quantita)
        ) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check-circle";
          this.snackbar_text = "Quantità raggiunta: work order completato!";
        }
      },
    },
    visible: {
      immediate: true,
      handler() {
        if (this.visible == true) {
          let getcommessaregistrazione = {
            controller: "Magazzino",
            method: "POST",
            richiesta: {
              action: "getcommessaregistrazione",
              token: localStorage.getItem("user_token"),
              id_utente: localStorage.getItem("id"),
              id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
              db_name: "quickproduction",
            },
          };

          this.$store.dispatch("api", getcommessaregistrazione).then((res) => {
            // Azzero l'array delle causali
            this.causali = [];

            // Inserisco le causali che mi dice il backend
            res.data.forEach((elem) => {
              this.causali.push(elem);
            });
          });

          /* Devo ricevere dal BE i dati dell'avanzamento delle macchine.
           * Per riceverli devo inviare le date di start e stop dell'attività.
           * Preparo la data_da e la data_a per la request. */
          let req_data_da = null;
          if (this.work_order_sel.ora_start != null) {
            req_data_da = `${this.work_order_sel.ora_start.substring(
              6,
              10
            )}-${this.work_order_sel.ora_start.substring(
              3,
              5
            )}-${this.work_order_sel.ora_start.substring(
              0,
              2
            )} ${this.work_order_sel.ora_start.substring(11, 19)}`;
          }

          let req_data_a = null;
          if (this.work_order_sel.ora_stop != null) {
            req_data_a = `${this.work_order_sel.ora_stop.substring(
              6,
              10
            )}-${this.work_order_sel.ora_stop.substring(
              3,
              5
            )}-${this.work_order_sel.ora_stop.substring(
              0,
              2
            )} ${this.work_order_sel.ora_stop.substring(11, 19)}`;
          }

          /*let req_data_da = "2020-12-19 01:09:42";
          let req_data_a = "2020-12-19 23:09:42";*/

          // Preparo la request per l'avanzamento delle macchine.
          let getmacchinaavanzamento = {
            controller: "Magazzino",
            method: "POST",
            richiesta: {
              action: "getmacchinaavanzamento",
              token: localStorage.getItem("user_token"),
              id_utente: localStorage.getItem("id"),
              id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
              id_macchina: this.work_order_sel.id_macchina,
              data_da: req_data_da,
              data_a: req_data_a,
              db_name: "quickproduction",
            },
          };

          // Consegno la request e gestisco la response.
          this.$store.dispatch("api", getmacchinaavanzamento).then((res) => {
            this.items_log_macchina = [];

            // Gestione vecchia
            /*if (res.data.length === 2) {
              res.data.forEach((elem) => {
                this.items_log_macchina.push(elem);
              });
              this.pezzi_rilevati =
                res.data[1].pezzi_prodotti - res.data[0].pezzi_prodotti;

              // Impedisco che i pezzi_rilevati abbiano valore negativo.
              if (this.pezzi_rilevati < 0) {
                this.pezzi_rilevati = 0;
              }
            }*/

            // Gestione nuova
            if (res.data.logs.length === 2) {
              res.data.logs.forEach((elem) => {
                this.items_log_macchina.push(elem);
              });
              this.pezzi_rilevati = res.data.quantita;

              // Impedisco che i pezzi_rilevati abbiano valore negativo.
              if (this.pezzi_rilevati < 0) {
                this.pezzi_rilevati = 0;
              }
            }
          });

          /* Faccio una chiamata per ricevere gli header della tabella di log. */
          let headers_log = {
            controller: "Magazzino",
            method: "POST",
            richiesta: {
              action: "getheader",
              token: localStorage.getItem("user_token"),
              nome_modulo: "TABELLA_CONNEXT",
            },
          };
          this.$store.dispatch("api", headers_log).then((res) => {
            this.headers_log_macchina = Array.from(res.data);
          });

          // Valorizzo le note e le note_workorder con i dati del work_order_sel
          this.note_workorder =
            this.work_order_sel.note_workorder != undefined
              ? this.work_order_sel.note_workorder
              : "";

          /* Valorizzo di default i pezz_prodotti (i pezzi
           * conformi che voglio registrare) con la quantità
           * di pezzi previsti per la commessa. */
          this.pezzi_prodotti = 0;
        }
      },
    },
  },
  methods: {
    aggiungiImg(messaggio) {
      console.log("messaggio ricevuto" + messaggio);
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.detail.img;

      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width =
            img.width + "px";
          document.getElementById(messaggio.detail.id).style.height =
            img.height + "px";
        }
      };

      img.src = photo;

      document.getElementById(messaggio.detail.id).style.backgroundImage =
        "url(" + img.src + ")";
    },
    clickAvanti() {
      this.$emit("click_avanti");
      this.salvaRegistrazione();
      this.pezzi_prodotti = null;
    },
    clickControllo(id) {
      this.$emit("click_controllo", id);
    },
    clickIndietro() {
      this.$emit("click_indietro");
      this.pezzi_prodotti = null;
    },
    salvaRegistrazione() {
      // Controllo che si stiano registrando dei pezzi
      if (this.pezzi_prodotti === null) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire dei pezzi da registrare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che i pezzi che si stanno registrando non siano meno di 0.
      if (this.pezzi_prodotti < 0) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "I pezzi registrati non possono essere meno di 0.";
        this.snackbar_text_color = "white";
        return;
      }

      // Modifico le causali per comodità di BE
      this.causali.forEach((elem) => {
        elem.id_causali_produzione = -1;
        elem.id_commessa_movimenti = -1;
        elem.id_commessa_dettaglio = this.work_order_sel.id_commessa_dettaglio;
        elem.pezzi = elem.pezzi === null ? 0 : elem.pezzi;
      });

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessaregistrazione",
          token: localStorage.getItem("user_token"),
          id_commessa: this.work_order_sel.id_commessa,
          id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
          id_macchina: this.work_order_sel.id_macchina,
          flag_fase_ultima: this.work_order_sel.flag_fase_ultima,
          flag_em: this.flag_em_registrazione,
          id_articolo: this.work_order_sel.id_articolo,
          causali: this.causali,
          note: this.note,
          quantita: this.pezzi_prodotti,
          chiudi_workorder:
            parseInt(this.pezzi_prodotti) +
              parseInt(this.work_order_sel.pezzi_registrati) <
            parseInt(this.work_order_sel.quantita)
              ? 0
              : 1,
        },
      };
      this.$store.dispatch("api", request) /*.then((res) => {})*/;

      /* Se ho finito di lavorare, emetto un evento che
       * stampa nel componente padre una snackbar di avviso. */
      if (
        parseInt(this.pezzi_prodotti) +
          parseInt(this.work_order_sel.pezzi_registrati) >=
        parseInt(this.work_order_sel.quantita)
      ) {
        this.$emit("work_order_completato");
      }
    },
    salvaEdEsci() {
      // Controllo che si stiano registrando dei pezzi
      if (this.pezzi_prodotti === null) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire dei pezzi da registrare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che i pezzi che si stanno registrando non siano meno di 0.
      if (this.pezzi_prodotti < 0) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "I pezzi registrati non possono essere meno di 0.";
        this.snackbar_text_color = "white";
        return;
      }

      // Modifico le causali per comodità di BE
      this.causali.forEach((elem) => {
        elem.id_causali_produzione = -1;
        elem.id_causali_movimenti = -1;
        elem.id_commessa_dettaglio = this.work_order_sel.id_commessa_dettaglio;
        elem.pezzi = elem.pezzi === null ? 0 : elem.pezzi;
      });

      // Preparo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessaregistrazione",
          token: localStorage.getItem("user_token"),
          id_commessa: this.work_order_sel.id_commessa,
          id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
          id_macchina: this.work_order_sel.id_macchina,
          flag_fase_ultima: this.work_order_sel.flag_fase_ultima,
          flag_em: this.flag_em_registrazione,
          id_articolo: this.work_order_sel.id_articolo,
          causali: this.causali,
          note: this.note,
          quantita: this.pezzi_prodotti,
          chiudi_workorder:
            parseInt(this.pezzi_prodotti) +
              parseInt(this.work_order_sel.pezzi_registrati) <
            parseInt(this.work_order_sel.quantita)
              ? 0
              : 1,
        },
      };

      /* Mando la request al BE e dopo che mi torna la
       * response indietro, emetto un evento perché il
       * componente padre si aggiorni. */
      this.$store.dispatch("api", request).then(() => {
        this.pezzi_prodotti = null;
        this.$emit("click_salva_esci");
      });

      /* Se ho finito di lavorare, emetto un evento che
       * stampa nel componente padre una snackbar di avviso. */
      if (
        parseInt(this.pezzi_prodotti) +
          parseInt(this.work_order_sel.pezzi_registrati) >=
        parseInt(this.work_order_sel.quantita)
      ) {
        this.$emit("work_order_completato");
      }
    },
  },
  mounted() {
    document
      .getElementById("listener_registra_pezzi")
      .addEventListener("nuova_img", this.aggiungiImg);
  },
};
</script>
<style scoped>
</style>