var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":true,"persistent":"","width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0",style:(_vm.background_icon == undefined || _vm.background_icon == false
          ? 'background-color:transparent; box-shadow:none; min-width:15px; height:15px'
          : ''),attrs:{"slot":"badge"},slot:"badge"},on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.bg_color=='indigo')?_c('v-icon',_vm._g(_vm._b({class:_vm.color == undefined ? 'blue--text' : _vm.color},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icon == undefined ? "mdi-eye" : _vm.icon))]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.bg_color}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icon == undefined ? "mdi-eye" : _vm.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.nome))])])],1)]}}]),model:{value:(_vm.dialogModuli),callback:function ($$v) {_vm.dialogModuli=$$v},expression:"dialogModuli"}},[[_c('div',{staticClass:"main-div ml-auto mr-auto",staticStyle:{"background-color":"white"}},[_c('v-toolbar',{attrs:{"color":_vm.bg_color,"dense":""}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"py-1 d-flex justify-start",attrs:{"cols":"4"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.nome))])],1),_c('v-col',{staticClass:"py-1 d-flex justify-center",attrs:{"cols":"4"}},[_c('AddModule',{attrs:{"assistenza":_vm.assistenza,"item":{},"tipo":_vm.tipo,"nome":_vm.nome,"getaction":_vm.getaction,"setaction":_vm.setaction,"controller":_vm.controller,"filter":_vm.filter,"dati":_vm.dati,"all_label":_vm.all_label,"id_azienda":_vm.id_azienda,"controllaCodice":_vm.controllaCodice,"bg_color":_vm.bg_color,"icon_color":_vm.icon_color},on:{"dialogModule":_vm.emitDialog}})],1),_c('v-col',{staticClass:"py-1 d-flex justify-end",attrs:{"cols":"4"}},[_c('v-text-field',{staticStyle:{"background-color":"white !important"},attrs:{"append-icon":"mdi-magnify","label":_vm.label,"placeholder":_vm.placeholder,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",staticStyle:{"height":"auto"},attrs:{"small":"","icon":_vm.icon_color == 'primary' ? false:true},on:{"click":function($event){return _vm.closeModules()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#c00000"},attrs:{"color":_vm.icon_color == 'primary' ? '#c00000' : _vm.icon_color}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi ")])])],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}}),_c('v-container',{staticClass:"py-0 mt-n6",staticStyle:{"max-height":"600px","overflow-y":"auto"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"white"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lista,"items-per-page":15,"search":_vm.search},scopedSlots:_vm._u([{key:"item.modifica",fn:function(ref){
          var item = ref.item;
return [_c('AddModule',{attrs:{"assistenza":_vm.assistenza,"item":item,"tipo":_vm.tipo,"nome":_vm.nome,"getaction":_vm.getaction,"setaction":_vm.setaction,"controller":_vm.controller,"filter":_vm.filter,"dati":_vm.dati,"risorsa_preferenziale":_vm.risorsa_preferenziale,"all_label":_vm.all_label,"id_azienda":_vm.id_azienda,"controllaCodice":_vm.controllaCodice,"bg_color":_vm.bg_color,"icon_color":_vm.icon_color},on:{"dialogModule":_vm.emitDialog}})]}},{key:"item.categoria",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dd)+" ")]}},{key:"item.elimina",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.modalDelete = true;
                        _vm.id = _vm.lista.indexOf(item);}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#c00000","medium":""}},[_vm._v(" mdi-window-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancellare")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Spostamento")]),_c('v-card-text',[_vm._v("Sicuro di voler eliminare?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.modalDelete = false}}},[_vm._v("Non eliminare")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Elimina")])],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }