import axios from "axios";

export default{
    name: 'Form',
  components: {
    
  },
  props: {
    titolo: String,
    file: Array,
    index: String,
    admin: Boolean,
  },
  data: () => {
    return {
      baseURL: "",//this.$store.state.config.ip,
      btn_enabled: false,
      caricato: false,
      color: '',
      is_admin: localStorage.getItem("id_profilo") == 1 ? true : false,
      dialog: false,
      time_stamp:'',
      element: 0,
      filepath: [],
      info: '',
      loading: false,
      secondi: 0,
      //NETWORK: null,
      max: 100,
      selected_file: null,
      selected_file_extension: null,
      selected_file_name: null,
      separatore_selezionato:";",
      risultato: '',
      value: 0,
      timeout: '',

    }
  },
  watch: {
    selected_file(val) {
      if (val == null) {
        this.caricato = false
        this.btn_enabled = false
      } else {
        this.btn_enabled = true
      }
    }
  },
  created() {
    this.element = this.$props.index;
    let refIP = this.$store.state.config.ip;
    this.BASE_URL = refIP + ((refIP[refIP.length-1] == '/')?'':'/') + 'api/';
  },
  methods: {

    downloadFile(){
      var finale = {
        id_utente: localStorage.id,
        token: localStorage.user_token,
        prefisso_utente: localStorage.prefisso_utente,
        id_widget: this.$props.index,
        action: "download"
      };
      axios.post(this.BASE_URL+"import" , finale)
      .then(response => {
        if(response.data.type){
          
          let type = response.data.type;
          let ext;

         

         
        if (type == "text/csv") {  ext = "csv"; }
        else if (type =="text/xml") { ext = "xml"; }
        else if (type =="application/vnd.ms-excel") { ext = "xls"; }
        else if (type =="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") { ext = "xlsx"; }
        
        console.log(response.data);

        const b64toBlob = (b64Data, sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];
          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: type });
          return blob;
        };
        
        const b64Data = response.data.file;



        const blob = b64toBlob(b64Data);
        const blobUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = blobUrl;
        a.target = '_blank';
        a.download = 'file.' + ext;
        document.body.appendChild(a);
        a.click();

         /* const anchor = document.createElement('a');
          anchor.href = `data:${type};charset=utf-8,` + encodeURIComponent(response.data.file);
         
          anchor.target = '_blank';
          anchor.download = 'file.' + ext;
          anchor.click();*/


        } else {
          this.info = "nessun file trovato"
          this.color = "alert alert-danger"
          
         }
        //console.log(response)
      })
        

        .catch((error) => {
            console.error('Error:', error);
        });
    },
      // al cambiamento del file  cambio i campi
    onFileChanged(event) {
        console.log(event[0]);
        this.selected_file = event[0];
        if(this.selected_file){
          let dotIndex = this.selected_file.name.lastIndexOf('.') + 1;
          this.selected_file_name = this.selected_file.name.substring(0, dotIndex - 1);
          this.selected_file_extension = this.selected_file.name.substring(dotIndex);
          (this.selected_file_extension == "csv" && this.selected_file) ? this.caricato = true: this.caricato = false;
        } 
      },

      uploadFile() {
        if (!this.file.includes(this.selected_file_extension)) {
          alert(`File non valido`); // se non ce nessun file restituisci un errore
          return;
        }
        if (this.selected_file == null) {
          alert(`Nessun file del tipo ${this.file}`);
          return;
        }
  
  
        if(this.selected_file){
          const form_data = new FormData();
          this.loading = true;
          this.time_stamp = Date.now();
  
          form_data.append('myFile', this.selected_file, this.selected_file.name);
          var finale = {
            estensione: this.selected_file_extension,
            nome_file: this.selected_file_name,
            separatore: this.separatore_selezionato,
            time_stamp: this.time_stamp.toString(),
            id_utente: localStorage.id,
            token: localStorage.user_token,
            prefisso_utente: localStorage.prefisso_utente,
            id_widget: this.$props.index,
          };
          form_data.append("json", JSON.stringify(finale));
  
  
          this.sendPostRequest(form_data).then(result => {
            if(typeof result.data === 'string') { 
              if(result.data.includes('errore')) {
                this.info = result.data;
                this.color = "alert alert-danger";
              } else if(result.data.includes('success')) {
                this.info = 'l\'inserimento è andato a buon fine';
                this.color = "alert alert-success";
                setTimeout( () => {
                  this.info = ''
                }, 15000);
              }
            } else {
              this.info = '';
            }
            this.loading = false
            this.dialog= false;
            this.selected_file = null;
           
          }).catch((err) => {
              this.loading = false;
              this.dialog= false;
  
              console.log(err);
          });
        }
        this.caricato = false;
  
       
      },
  
      startTimeout(){
        this.secondi = 0;
        this.timeout = setInterval(() => {this.secondi++;}, 1000);
      },

      inviamessaggio(){
        
        var finale = {
          id_utente: localStorage.id,
          token: localStorage.user_token,
          prefisso_utente: localStorage.prefisso_utente,
          id_widget: this.$props.index,
          action: "messaggioletto",
          testo: this.info,
        };

        axios.post(this.BASE_URL+"import" , finale)
            .then(result => {
              console.log(result);
            })
            .catch(err => {
                console.log(err);
            });
        
        this.info = '';
      },

      sendPostRequest(form_data) {
        let promise = new Promise((resolve, reject) => {
  
            //apitest.quickview.biz:91
            axios.post(this.BASE_URL+"import/inserisci" , form_data,
            {
              onUploadProgress: progressEvent => {
                
                  let progress = Math.round((progressEvent.loaded  / progressEvent.total)*100)
                  this.value = progress;
                  if(this.value == 100) { 
                    this.dialog= true;
                    
                    //this.startTimeout();
                  }
              }
            })
            .then(result => {

               clearInterval(this.timeout);
                this.value = 0;
                resolve(result);
            })
            .catch(err => {
                clearInterval(this.timeout);
                reject(err);
            });
  
        })
        
  
        return promise;
    },
  }
}
