import Import from "@/components/IMPORT/Import.vue";
import TextEditor from "@/components/GRAPH/Dashboard/Editor/TextEditor.vue";
import axios from "axios";

//import NETWORK from "@/components/CHART/Common/Network/Network.vue"
//import {getDashboardDett } from "./backend.js"


//import axios from "axios";
export default {
    name: "TabellaImport",
    data: () => {
        return{
            dialog: false,
            widget_selezionato: 0,
            upload: false,
            aspetta:true,
            attiva_visualizzatore: false,
            attiva_visual_1: true,
            attiva_visual_2: false,  
            current_index_visualizzato: 0,
            is_admin: localStorage.getItem("id_profilo") == 1 ? true : false,
            edit_mode: 0,
            json_query_arr: null,
            json_database_item: [],
            json_DB_query: [], 
            NETWORK: null,
            widget: [],
            dettaglio: {},
            //query:'',      
            TABLE_DATA: [{
                targetProperty : 'cod',
                color : 'red',
                keyword : '@',
                targetField: 'parametri',
                template : {
                                id_ds_dettaglio_param: -1,
                                id_ds_dettaglio_rif: -1,
                                nome: null,
                                descrizione: null,
                                valore: null,
                                tipo: null,
                                cod: null,
                                validita: 1,
                            },
            }],
            TABLE_DATA2: [{
                targetProperty : 'cod',
                color : 'blue',
                keyword : '@',
                targetField: 'params',
                template : {
                                id_ds_dettaglio_param: -1,
                                id_ds_dettaglio_rif: -1,
                                nome: null,
                                descrizione: null,
                                valore: null,
                                tipo: null,
                                cod: null,
                                validita: 1,
                            },
            }],
            testata : [{text:'Nome',value:'nome'},{text:'Valore',value:'valore'}],
            tipofile: [],
            visualizza: false,
            visualizza_scheda: 0,
            visualizza_oggetto: {index: "", titolo : "", file:""},
            refresh_editor: 0,
            refresh_editor2: 0,
            nota: false,
            info:'',
            caricato:'',
            colore:'',
            log: false,
            tabella_logs: [],
            selected_file_name: "",
            selected_file_type: "",
            BASE_URL: "",
            riepologo_items: [],
            riepologo_headers: [],
            riepologo_data: null,

        }

    },
    created(){
        let refIP = this.$store.state.config.ip;
        this.BASE_URL = refIP + ((refIP[refIP.length-1] == '/')?'':'/') + 'api/';
    },

    mounted() {
        
        // creato gli item facendo chiamata al server
        //this.NETWORK = this.$refs.network;
        this.NETWORK = this.$store.state.Network;  


        if(this.NETWORK==undefined||this.NETWORK == null){this.NETWORK = this.$refs.network;}
        this.aspetta = true;

    
        //this.getDashboardDett();
       // console.log("this.$route.params.id");
        //console.log(this.$route.params.id);
        //console.log(this.$route.params.id);

        let request = {
            controller : 'Import',
            body : {
                action:"getDashboardDettaglio",
                prefisso_utente: localStorage.prefisso_utente,
                id_ds:this.$route.params.id,
                token:localStorage.user_token
            },
            method: 'POST',
            on : {
                load : this.creaItems
            },
        }

        console.log(request);

       
        this.NETWORK.AjaxRequest(request); 
    },
    components: {
        Import,
        TextEditor,
    }, 

    methods: {
        visualizzaInfo(i){
            this.nota = true;
            console.log(i);
            this.info = this.widget[i].info != "" ? this.widget[i].info : "nessuna descrizione";
        },

        tornaIndietro(){
            this.$router.push('/home/import')
        },
        // creazione degli item
        creaItems(response){

            let that = this;
            setTimeout( () => {
                
                that.aspetta = false;
            }, 1000)
            this. widget = [];


            if(typeof response == "string"){response = JSON.parse(response).result; }

            this.tipofile = response.dashboard.formati_file;
            let creazioneWidget = response.dashboard.grafici;

           
            for(let i = 0; i < creazioneWidget.length; i++){
                let obj = {titolo: '', file: '', index: '', colonna_intestazioni: 0, colonna_dati: 0, colonna_fine: 0, colonna_skip: 0};
                console.log( creazioneWidget[i].metadati);
                obj.titolo = creazioneWidget[i].metadati.titolo;
                obj.file = creazioneWidget[i].metadati.formati_supportati.split(',');
                obj.index = creazioneWidget[i].metadati.id_ds_dettaglio;
                obj.colonna_intestazioni = creazioneWidget[i].metadati.colonna_intestazioni;
                obj.colonna_dati = creazioneWidget[i].metadati.colonna_dati;
                obj.colonna_fine = creazioneWidget[i].metadati.righe_fine;
                obj.colonna_skip = creazioneWidget[i].metadati.righe_skip;
                obj.info = creazioneWidget[i].metadati.info;
                obj.tipo_query = creazioneWidget[i].metadati.tipo_query;
                this. widget.push(obj);
            }
        },

        // serve per visualizzare una delle due tabelle presenti nel visualizzatore
        visualizzaBody(n){
            console.log(this.visualizza_scheda)
            console.log(n)
            this. visualizza_scheda = n
            switch(n){
                case 0:
                    this.attiva_visual_1 = true
                    this.attiva_visual_2 = false
                    break
                case 1:
                    this.attiva_visual_1 = false
                    this.visualizzaTabelle(1);
                    this.attiva_visual_2 = true
                    break
            }
        },
        
        chiudiVisualizzatore() {
            this.visualizza = false;
            this.attiva_visualizzatore = false
            this.visualizza_oggetto = {index: "", titolo : "", file:"", colonna_intestazioni: 0, colonna_dati: 0,  colonna_fine: 0, colonna_skip: 0, info: ""}
        },

        visualizzaDialog (index, id_widget) {
            this.widget_selezionato = id_widget
            console.log(this.visualizza_scheda);
            this.visualizza_scheda = 0;
            let database_list_request = {
                controller : 'Global',
                body : {
                    action: "gettabella",
                    db_name: localStorage.db_name,
                    tipo: "TIPI_QUERY_GRAFICI",
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : this.popolaSelect
                },
                response : { parse : false },
            }
            this.NETWORK.AjaxRequest(database_list_request);  
            this.current_index_visualizzato = index
            this.visualizza = true;
            this.attiva_visualizzatore = true;
            
            if(index >= 0){this.modificaOggetto(index);
            } else {this.nuovoOggetto();}
        },

        modificaOggetto(index){
            let request = {
                controller : 'Import',
                body : {
                    action: "getDettaglioGrafico",
                    prefisso_utente:localStorage.prefisso_utente,
                    id_ds_dettaglio: this. widget[index].index,
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : this.getDettaglio
                },
            }
            
            this.NETWORK.AjaxRequest(request);  
        },

        getDettaglio(response){
 
            this.refresh_editor++;
            this.refresh_editor2++;
            let objServer = response;
            
            this.dettaglio = objServer.jsonDettaglio[0];

            this.json_database_item.push(objServer);
            
            this.dettaglio.parametri = objServer.jsonParam;
            let index = this.current_index_visualizzato;
            this.visualizza_oggetto.index = this. widget[index].index
            this.visualizza_oggetto.titolo = this. widget[index].titolo
            this.visualizza_oggetto.file = this. widget[index].file
            this.visualizza_oggetto.colonna_intestazioni = this. widget[index].colonna_intestazioni
            this.visualizza_oggetto.colonna_dati = this. widget[index].colonna_dati
            this.visualizza_oggetto.colonna_fine = this. widget[index].colonna_fine
            this.visualizza_oggetto.colonna_skip = this. widget[index].colonna_skip
            this.visualizza_oggetto.info = this. widget[index].info

            
            
            this.visualizzaBody(0);
            this.edit_mode = 1;
            
        },

        nuovoOggetto(){
            console.log("passa qui");
            this.visualizza_oggetto = {index: "", titolo : "", file:"", colonna_intestazioni: 0, colonna_dati: 0 , colonna_fine: 0, colonna_skip: 0, info: ""}
            this.visualizzaBody(0);
            this.edit_mode = 0;
            this.TABLE_DATA[0].data = [];
            this.TABLE_DATA2[0].data = [];
            this.json_database_item
            this.dettaglio.query = '';
            
            this.dettaglio.query_riepilogo = '';
            this.refresh_editor =this.refresh_editor == -1 ? -2 : -1;
            this.refresh_editor2 = this.refresh_editor2 == -1 ? -2 : -1;

            this.json_query_arr = '';
            
            let request = {
                controller : 'Import',
                body : {
                    action:"getformatifile",
                    token:localStorage.user_token
                },
                method: 'POST',
                on : {
                    load : this.getFileSupportati
                },
            }
            this.NETWORK.AjaxRequest(request); 
            


        },

        getFileSupportati(response){

            
            this.tipofile = response.dashboard.formati_file;
        },

        


        /**********************************
            medoti del  visualizza_scheda 1
        **********************************/ 
        salva(){
            // serve per aggiornare i dialog generati o crearne di nuovi
            let indice= this.current_index_visualizzato;
            let titolo = this.visualizza_oggetto.titolo;
            let file = this.visualizza_oggetto.file;
            let colonna_dati = this.visualizza_oggetto.colonna_dati;
            let colonna_intestazioni = this.visualizza_oggetto.colonna_intestazioni;
            let colonna_fine = this.visualizza_oggetto.colonna_fine;
            let colonna_skip = this.visualizza_oggetto.colonna_skip;
            let info = this.visualizza_oggetto.info;
            let tipo_query = this.visualizza_oggetto.tipo_query;


            console.log(titolo);
            console.log(this.edit_mode)

            this.dettaglio.parametri.map(e => e.id_ds_dettaglio_rif == this.widget_selezionato)

            
            

            if(this.edit_mode == 1 && titolo != '' && file != '' && this.json_query_arr && this.dettaglio.query.trim() != '' && this.dettaglio.query_riepilogo.trim() != ''){
                this. widget[indice].titolo = titolo;
                this. widget[indice].file = file;
                this.confronta();
            } else if (this.edit_mode == 0 && titolo != '' && file != '' && this.dettaglio.query.trim() != '' && this.dettaglio.query_riepilogo.trim() != ''){
                this.dettaglio.query = this.dettaglio.query.trim();
                this.dettaglio.query_riepilogo = this.dettaglio.query_riepilogo.trim();
                indice = this. widget.length;

                //creo l'ggetto e faccio la push
                let obj = { titolo: titolo, file: file, colonna_dati: colonna_dati, colonna_intestazioni: colonna_intestazioni, 
                    colonna_fine:colonna_fine, colonna_skip:colonna_skip, info: info, tipo_query: tipo_query, validita: 1  }
                this. widget.push(obj);
                this.chiudiVisualizzatore();
                


                // fare chiamata al backend passandogli i nuovi file
                let creajsonDettaglio = [{ 
                    query: this.dettaglio.query.replaceAll('\n', ' ').replaceAll('\t', ' '),
                    query_riepilogo: this.dettaglio.query_riepilogo.replaceAll('\n', ' ').replaceAll('\t', ' '),
                    id_ds_dettaglio : -1,
                    id_ds_rif : this.$route.params.id,
                    tipo_query: this.json_query_arr.id_tabella,
                    layout: file.toString(),
                    nome: titolo,           
                    validita : 1,
                    id_utente: localStorage.id,
                    righe_dati: colonna_dati,
                    righe_fine: colonna_fine,
                    righe_skip: colonna_skip,
                    righe_intestazione: colonna_intestazioni,

                }]

                
                this.setDashboardDett(creajsonDettaglio, this.dettaglio.parametri);

                                
            } else {
                let arr = [];
                console.log(this.dettaglio);

                titolo != '' ? "" : arr.push("titolo"); 
                file != '' ? "" : arr.push("selezione file"); 
                this.dettaglio.query.trim() != '' ? "" : arr.push("query"); 
                this.dettaglio.query_riepilogo.trim() != '' ? "" : arr.push("query_riepilogo"); 
                alert(`I campi: \n${arr.toString()} \nnon possono essere vuoti`);
            }
        },

        elimina(index) {
            // elimina eventuali dialog
            let conferma
            (typeof index == 'number') ? conferma = confirm('sei sicuro di voler eliminare il cliente?') : alert('nessun campo selezionato');
            console.log(conferma);
            if(conferma) {
                this. widget.splice(index, 1)
                this.visualizza_oggetto
                this.json_database_item[0].jsonParam.map(obj => obj.validita = 0);

                console.log("************")
                console.log(this.json_database_item[0].jsonDettaglio[0].validita);
                console.log("************")
                this.json_database_item[0].jsonDettaglio[0].validita = 0;
                this.json_database_item[0].jsonDettaglio[0].id_ds_dettaglio = this.visualizza_oggetto.index;
    
                //aggiornare il backend
                this.setDashboardDett(this.json_database_item[0].jsonDettaglio, this.json_database_item[0].jsonParam);
                
                
                this. widget = [];
            }
            this.chiudiVisualizzatore();

        },

        editcss(){
            // serve per editare il css del componente vuetify per selezionare i file in modo da averlo centrato
            setTimeout( () => {
                document.getElementsByClassName('col-sm-12')[0].childNodes[0].childNodes[1].style.top = "unset";
                document.getElementsByClassName('col-sm-12')[0].childNodes[0].childNodes[1].style.position = "unset";
                },10)
        },

        


        /***********************************
            medoti del  visualizza_scheda 2
        ***********************************/ 
       visualizzaTabelle(index) {
           console.log(index);
            if(index == 1){this.set_text_editor_data(); }
            if(index == 2){this.set_text_editor_data2(); }
            /*setTimeout( () => {
                document.getElementById('graph_text_editor_content').classList.add('setheight');
                },10)*/
        },

       // comfronto i dati inseriti con i dati iniziali
        confronta(){
            console.log('this.json_database_item[0].jsonParam');
            console.log(this.json_database_item[0].jsonParam);
            let originalArr = this.json_database_item[0].jsonParam;
            let data = this.dettaglio.parametri;
            let isValid = true; 

            for(let i = 0; i < originalArr.length; i++){
                isValid = true;
                for(let j = 0; j < data.length; j++){
                    if(originalArr[i].id_ds_dettaglio_param == data[j].id_ds_dettaglio_param){
                        isValid = false;
                    }
                }
                if(isValid){
                    console.log('oggetto non presente');
                    originalArr[i].validita = 0;
                    this.dettaglio.parametri.push(originalArr[i])
                }
            }
            


            // modifico il json dettagli
            this.json_database_item[0].jsonParam = data;
            this.json_database_item[0].jsonDettaglio[0].query = this.dettaglio.query.replaceAll('\n', ' ').replaceAll('\t', ' ');
            this.json_database_item[0].jsonDettaglio[0].query_riepilogo = this.dettaglio.query_riepilogo.replaceAll('\n', ' ').replaceAll('\t', ' ');
            this.json_database_item[0].jsonDettaglio[0].layout = this.visualizza_oggetto.file.toString();
            this.json_database_item[0].jsonDettaglio[0].id_ds_rif = this.$route.params.id;
            this.json_database_item[0].jsonDettaglio[0].id_ds_dettaglio = this.visualizza_oggetto.index;
            this.json_database_item[0].jsonDettaglio[0].righe_intestazione = this.visualizza_oggetto.colonna_intestazioni;
            this.json_database_item[0].jsonDettaglio[0].righe_dati = this.visualizza_oggetto.colonna_dati;
            this.json_database_item[0].jsonDettaglio[0].righe_fine = this.visualizza_oggetto.colonna_fine;
            this.json_database_item[0].jsonDettaglio[0].righe_skip = this.visualizza_oggetto.colonna_skip;
            this.json_database_item[0].jsonDettaglio[0].info = this.visualizza_oggetto.info;
            this.json_database_item[0].jsonDettaglio[0].tipo_query = this.json_query_arr.id_tabella;
            this.json_database_item[0].jsonDettaglio[0].validita = 1;

            this.json_database_item[0].jsonDettaglio[0].nome = this.visualizza_oggetto.titolo;


            // chiamo i backend per aggiornare il tutto
            this.setDashboardDett(this.json_database_item[0].jsonDettaglio, this.json_database_item[0].jsonParam);
            this.chiudiVisualizzatore();
        },

        /***********************************
            gestione dati da e verso il backend
        ***********************************/ 
        popolaSelect(response) {
            console.log('response');
            console.log(response);

            this.json_DB_query = JSON.parse(response);

            if(this.current_index_visualizzato){
                this.json_DB_query.map(e => {
                    if(e.id_tabella == this.widget[this.current_index_visualizzato].tipo_query){this.json_query_arr = e.id_tabella}
                    console.log(this.widget[this.current_index_visualizzato].tipo_query);
                    console.log(e.id_tabella +  " - " + e.des);               
                })
            }

        },

        getlogs(){             
            let request = {
                controller : 'Import',                 
                body : {                     
                    action: "getlogs",                     
                    prefisso_utente:localStorage.prefisso_utente,                     
                    token: localStorage.user_token,                     
                },                 
                method: 'POST',                 
                on : {                     
                    load : this.logs                 
                }             
            }                          
            console.log(request);             
            this.NETWORK.AjaxRequest(request);           
        },
        
        logs(response){
            
            this.tabella_logs = response;
            this.log=true;
        },
        
        visualizzaUpload(i){             
            this.upload = true;   
            this.widget_selezionato = i;     
        },
    
    dettagliquery(i){             
        this.riepologo_data = true;   
        this.widget_selezionato = i; 

        let request = {
            controller : 'Import',
            body : {
                action:"getdettaglioquery",
                prefisso_utente: localStorage.prefisso_utente,
                token: localStorage.user_token,
                id_utente: localStorage.id,
                id_widget: i,

            },
            method: 'POST',
            on : {
                load : this.dettaglioqueryres
            },
        }

        this.NETWORK.AjaxRequest(request);  

    },

    dettaglioqueryres(response){
        this.riepologo_headers = [];
        this.riepologo_items = [];
        console.log('response.dettagli');
        if(response.dettaglio){
            console.log(response.dettaglio);
            this.riepologo_items = response.dettaglio;
            this.riepologo_headers = Object.keys(response.dettaglio[0]);
        }
        
    },

            // al cambiamento del file  cambio i campi
    onFileChanged(event) {
        console.log(event[0]);
        this.selected_file = event[0];
        if(this.selected_file){
            let dotIndex = this.selected_file.name.lastIndexOf('.') + 1;
            this.selected_file_type =  this.selected_file.name.substring(dotIndex);
            this.selected_file_name =  Date.now() + "." + this.selected_file_type;
        } 
      },

      uploadFile() {

 
  
  
        if(this.selected_file){
          const form_data = new FormData();
          this.time_stamp = Date.now();
  
          form_data.append('myFile', this.selected_file, this.selected_file.name);
          var finale = {
            nome_file: this.selected_file_name,
            estensione : this.selected_file.name,
            id_utente: localStorage.id,
            token: localStorage.user_token,
            prefisso_utente: localStorage.prefisso_utente,
            id_widget: this.widget_selezionato,
          };
          form_data.append("json", JSON.stringify(finale));
  
          console.log(JSON.stringify(finale));
          this.sendPostRequest(form_data).then(result => {
            this.dialog = false
            this.upload = false
            if(typeof result.data === 'string') { 
             if(result.data.includes('success')) {

                console.log('caricato!!!!!');
                this.caricato = 'file caricato';
                this.colore = 'background: #5cb85c; padding: 10px 20px; color: #fff; border-radius: 5px';
                setTimeout(()=> {this.caricato = '';},3000)
            }
            } else {
                this.caricato = 'non caricato';
                this.colore = 'background: #d9534f; padding: 10px 20px; color: #fff;  border-radius: 5px';
                setTimeout(()=> {this.caricato = '';},3000)
            }

            this.selected_file = null;
           
          }).catch((err) => {
            this.dialog = false
            this.upload = false
            this.caricato = 'non caricato: ' + err;
            this.colore = 'background: #d9534f; padding: 10px 20px; color: #fff;  border-radius: 5px';
            setTimeout(()=> {this.caricato = '';},3000)
  
              console.log(err);
          });
        }
  
       
      },

      sendPostRequest(form_data) {
        let promise = new Promise((resolve, reject) => {
  
            //apitest.quickview.biz:91
            axios.post(this.BASE_URL+"import/settemplate" , form_data,
            {
              onUploadProgress: progressEvent => {
                
                  let progress = Math.round((progressEvent.loaded  / progressEvent.total)*100)
                  this.value = progress;
                  if(this.value == 100) { 
                    this.dialog= true;
                    
                    //this.startTimeout();
                  }
              }
            })
            .then(result => {

               
                this.value = 0;
                resolve(result);
            })
            .catch(err => {
                
                reject(err);
            });
  
        })
        
  
        return promise;
    },
        // setta la textbox con i dati presi dal backend
        set_text_editor_data(){

            let that = this;
            let event = new CustomEvent('graph_editor_set_values',{detail:{
                    get : function(){return {table:that.TABLE_DATA ,query: that.query};},
                    set : function(table,query){
                        that.TABLE_DATA[0].template = table[0];
                        that.query = query;
                    }
            }});
            document.body.dispatchEvent(event);
        },
        set_text_editor_data2(){

            let that = this;
            let event = new CustomEvent('graph_editor_set_values',{detail:{
                    get : function(){return {table:that.TABLE_DATA2 ,query: that.query_riepilogo};},
                    set : function(table,query){
                        that.TABLE_DATA2[0].template = table[0];
                        that.query = query;
                    }
            }});
            document.body.dispatchEvent(event);
        },

        // serve a salvare i dati aggiornati nel backend
        setDashboardDett(jsondett, jsonParam){
            let request = {
                controller : 'Import',
                body : {
                    action:"setDashboardDettaglio",
                    prefisso_utente: localStorage.prefisso_utente,
                    token: localStorage.user_token,
                    id_utente: localStorage.id,
                    jsonDettaglio: jsondett,
                    jsonParam: jsonParam
                },
                method: 'PUT',
                on : {
                    load : this.salvataggio
                },
            }

            this.NETWORK.AjaxRequest(request);  
        },

        salvataggio(response){
            console.log(response);
            console.log('HO FINITO')
        
            let request = {
                controller : 'Import',
                body : {
                    action:"getDashboardDettaglio",
                    prefisso_utente: localStorage.prefisso_utente,
                    id_ds:this.$route.params.id,
                    token:localStorage.user_token
                },
                method: 'POST',
                on : {
                    load : this.creaItems
                },
                response : { parse : false }
            }
    
            if(this.NETWORK==undefined||this.NETWORK == null){this.NETWORK = this.$refs.network;}
            this.aspetta = true;

            this.NETWORK.AjaxRequest(request); 
            
        }

    }
    
}