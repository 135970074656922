<template>
    <main class="graph_main" :data-position="scroll_index">
        <section>
            <div ref="home">
                <GraphHome @gotonext="goToNext($event)" ref="home_ref" />
            </div>
        </section>
        <section >
            <div ref="dashboard">
                <GraphDashboard ref="dashboard_ref" @gotoprevious="goBack" @gotonext="goToNext($event)" />
            </div>
        </section>
        <section >
            <div ref="slides">
                <GraphSlides ref="slides_ref" @gotoprevious="goBack" />
            </div>
        </section>
        <!-- Back arrow -->
        <div>
            <span />
            <div class="clickable graph_card" @click="goBack()" ref="back_button">
                <GraphIcon icon="back" />
            </div>            
        </div>
    </main> 
</template>
<script>
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";
import GraphHome from "@/components/GRAPH/Home/GraphHome.vue";
import GraphDashboard from "@/components/GRAPH/Dashboard/GraphDashboard.vue";
import GraphSlides from "@/components/GRAPH/Slides/Slides.vue";
export default {
    name : 'GraphMain',
    components : {
        GraphIcon,
        GraphHome,
        GraphDashboard,
        GraphSlides
    },
    data(){
        return{
            navigation_id : 0,
            scroll_index : 0,
            positions : null,
            references : null,
        }
    },
    mounted(){
        this.positions = [ this.$refs.home, this.$refs.dashboard, this.$refs.slides ];
        this.positions[this.scroll_index].style.display = 'block';
        this.references = [ this.$refs.home_ref, this.$refs.dashboard_ref, this.$refs.slides_ref ];
    },
    methods : {
        disableBackButton(){
            this.$refs.back_button.style.pointerEvents = 'none';
        },
        enableBackButton(){
            this.$refs.back_button.style.pointerEvents = 'all';
        }, 
        go(direction){
            const current = this.positions[this.scroll_index];
            const that = this;
            this.scroll_index += direction;
            this.positions[this.scroll_index].style.display = 'block';
            const reference = this.references[this.scroll_index];
            if(reference.runMounted != null){ reference.runMounted(this.navigation_id); }
            this.disableBackButton();
            setTimeout(function(){
                that.enableBackButton();
                current.style.display = 'none';
            }, 600);
        },    
        goBack(){
            if(this.scroll_index <= 0){ return; }
            this.go(-1);
            if(this.scroll_index == 0){ this.hideBackButton(); }
        },
        goToNext(id){            
            if(this.scroll_index == 2){ return; }
            this.navigation_id = Number(id);
            this.go(1);
            this.showBackButton();
        },
        hideBackButton(){
            this.$refs.back_button.style.display = 'none';
        },
        showBackButton(){
            this.$refs.back_button.style.display = 'inline-block';
        }
    }
}
</script>
<style scoped src="./GraphMain.css" />