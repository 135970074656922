import { render, staticRenderFns } from "./SnackbarQV.vue?vue&type=template&id=0ae1dcfa&scoped=true&"
import script from "./SnackbarQV.vue?vue&type=script&lang=js&"
export * from "./SnackbarQV.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae1dcfa",
  null
  
)

export default component.exports