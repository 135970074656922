<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                <v-tabs
                    v-model="modalTab"
                    grow
                    style="color: black"
                    slider-size="5"
                    slider-color="primary accent-3 "
                    color="black"
                >
                    <v-tab :href="`#cod_lotto`" style="max-width: 150px"
                    >Codice Lotto</v-tab >
                    <v-tab :href="`#cod_fao`" style="max-width: 200px"
                    >Codice FAO</v-tab>
                    <v-tab :href="`#codice_articolo`" style="max-width: 200px"
                    >Codice Articolo</v-tab>
                    <v-tab :href="`#articolo_generico`" style="max-width: 200px"
                    >Articolo Generico</v-tab>
                </v-tabs>
                <v-tabs-items v-model="modalTab" class="no-transition" touchless>
                    <v-tab-item
                    :value="`cod_lotto`"
                    transition="false"
                    class="no-transition"
                    ><br>
                        <ricercaPerCodLotto/>
                    </v-tab-item>
                    <v-tab-item
                    :value="`cod_fao`"
                    transition="false"
                    class="no-transition"
                    ><br>
                        <ricercaPerCodFAO/>
                    </v-tab-item>

                    <v-tab-item
                    :value="`codice_articolo`"
                    transition="false"
                    class="no-transition"
                    ><br>
                        <ricercaPerCodArticolo/>
                    </v-tab-item>
                    
                    <v-tab-item
                    :value="`articolo_generico`"
                    transition="false"
                    class="no-transition"
                    ><br>
                        <articoloGenerico/>
                    </v-tab-item>

                </v-tabs-items>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import ricercaPerCodLotto from "./ricercaPerCodLotto.vue";
    import ricercaPerCodFAO from "./ricercaPerCodFAO.vue";
    import ricercaPerCodArticolo from "./ricercaPerCodArticolo.vue";
    import articoloGenerico from "./articoloGenerico.vue";
    export default {
        components: {
            ricercaPerCodLotto,
            ricercaPerCodFAO,
            ricercaPerCodArticolo,
            articoloGenerico,
        },
        data() {
            return{
                modalTab: "cod_lotto",
            }
        },
        mounted() { 
        },
        methods: {  
        }

    }
</script>
