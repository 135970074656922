import { render, staticRenderFns } from "./CRISTALCarrelloCristal.vue?vue&type=template&id=2e4c5074&scoped=true&"
import script from "./CRISTALCarrelloCristal.vue?vue&type=script&lang=js&"
export * from "./CRISTALCarrelloCristal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e4c5074",
  null
  
)

export default component.exports