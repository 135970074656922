<template>
  <GiacenzeMagazzino />
</template>

<script>
import GiacenzeMagazzino from '@/components/MAGAZZINO/GiacenzeMagazzino.vue';

export default {
  name: 'GiacenzeMagazzinoView',
  components: {
    GiacenzeMagazzino,
  },
}
</script>
