var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 px-0",attrs:{"id":"listener_main","fluid":""}},[(_vm.tabella_richieste_visible === true)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 mx-5",attrs:{"headers":_vm.headers_richieste,"items":_vm.items_richieste_visualizzate},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"sfondoStandard",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Richieste di Intervento ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickNuovaRichiesta}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}],null,false,4063115076)},[_c('span',[_vm._v("Nuova Richiesta")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.filtro_visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-filter")])],1)]}}],null,false,723244371)},[_c('span',[_vm._v("Visualizza Filtri")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.resetFiltro}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-filter-off")])],1)]}}],null,false,1486766132)},[_c('span',[_vm._v("Resetta Filtro")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToManutenzione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-keyboard-backspace ")])],1)]}}],null,false,2017073310)},[_c('span',[_vm._v("Indietro")])])],1),_c('v-container',[(_vm.filtro_visible)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_richieste_visualizzate,"item-text":"cod_richiesta","item-value":"cod_richiesta","label":"Cod. Richiesta"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_richiesta_search = '';
                        _vm.filtra();}}},[_vm._v(_vm._s(_vm.cod_richiesta_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,2535410279),model:{value:(_vm.cod_richiesta_search),callback:function ($$v) {_vm.cod_richiesta_search=$$v},expression:"cod_richiesta_search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_richieste_visualizzate,"item-text":"des_richiedente","item-value":"des_richiedente","label":"Richiedente"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.des_richiedente_search = '';
                        _vm.filtra();}}},[_vm._v(_vm._s(_vm.des_richiedente_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,2768158055),model:{value:(_vm.des_richiedente_search),callback:function ($$v) {_vm.des_richiedente_search=$$v},expression:"des_richiedente_search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_richieste_visualizzate,"item-text":"des_urgenza","item-value":"des_urgenza","label":"Urgenza"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.des_urgenza_search = '';
                        _vm.filtra();}}},[_vm._v(_vm._s(_vm.des_urgenza_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,1142629159),model:{value:(_vm.des_urgenza_search),callback:function ($$v) {_vm.des_urgenza_search=$$v},expression:"des_urgenza_search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data Richiesta","readonly":""},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.data_richiesta_search = ''}}},[_vm._v(_vm._s(_vm.data_richiesta_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,true),model:{value:(_vm.data_richiesta_search),callback:function ($$v) {_vm.data_richiesta_search=$$v},expression:"data_richiesta_search"}},on))]}}],null,false,3788866740),model:{value:(_vm.calendario_data_richiesta),callback:function ($$v) {_vm.calendario_data_richiesta=$$v},expression:"calendario_data_richiesta"}},[_c('v-date-picker',{on:{"change":_vm.filtra,"input":function($event){_vm.calendario_data_richiesta = false}},model:{value:(_vm.data_richiesta_search),callback:function ($$v) {_vm.data_richiesta_search=$$v},expression:"data_richiesta_search"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_richieste_visualizzate,"item-text":"des_sede","item-value":"des_sede","label":"Sede"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.des_sede_search = '';
                        _vm.filtra();}}},[_vm._v(_vm._s(_vm.des_sede_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,2304884551),model:{value:(_vm.des_sede_search),callback:function ($$v) {_vm.des_sede_search=$$v},expression:"des_sede_search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_richieste_visualizzate,"item-text":"des_struttura","item-value":"des_struttura","label":"Struttura"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.des_struttura_search = '';
                        _vm.filtra();}}},[_vm._v(_vm._s(_vm.des_struttura_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,2526475495),model:{value:(_vm.des_struttura_search),callback:function ($$v) {_vm.des_struttura_search=$$v},expression:"des_struttura_search"}})],1)],1):_vm._e()],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [(item.cod_stato!='FINISH')?_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaDialog(item,false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica Richiesta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [(item.cod_stato=='FINISH')?_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaDialog(item,true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Dettaglio Richiesta")])])]}},{key:"item.email",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.email === 1 ? 'success' : 'error'}},[_vm._v(_vm._s(item.email === 1 ? "mdi-check" : "mdi-close"))])]}}],null,true)})],1)],1):_vm._e(),(_vm.nuova_richiesta_visible === true)?_c('v-row',[_c('v-col',{staticClass:"px-10",attrs:{"cols":"12"}},[_c('Richieste',{ref:"ComponenteRichieste",attrs:{"richiesta_sel":_vm.richiesta_sel,"visible":_vm.nuova_richiesta_visible},on:{"click_indietro":_vm.chiudiNuovaRichiesta}})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"70%"},model:{value:(_vm.dialog_modifica_visible),callback:function ($$v) {_vm.dialog_modifica_visible=$$v},expression:"dialog_modifica_visible"}},[_c('Richieste',{ref:"ComponenteRichieste",attrs:{"richiesta_sel":_vm.richiesta_sel,"visible":_vm.dialog_modifica_visible,"readOnly":_vm.richiesta_readOnly},on:{"click_indietro":_vm.chiudiModificaRichiesta}})],1),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }