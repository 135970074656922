var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-4 mb-3 pt-5",attrs:{"elevation":"3","outlined":""}},[_c('v-container',{staticClass:"my-0 pa-0"},[_c('v-row',{staticClass:"mb-5 mx-2 px-2",staticStyle:{"min-height":"200px","max-height":"500px","overflow-y":"scroll"},attrs:{"id":"chatHistory"}},_vm._l((_vm.bacheca),function(messaggio,i){return _c('div',{key:i,style:({
          display: 'block',
          textAlign: ("" + (messaggio.id_utente == _vm.id_utente ? 'right' : 'left')),
          width: '100%',
        })},[_c('v-card',{staticClass:"elevation-2 my-1 pa-0",style:({
            backgroundColor: ("" + (messaggio.id_utente == _vm.id_utente ? '#ECEFF1' : '#3f51b5')),
            borderRadius: '15px',
            display: 'inline-block',
            width: 'fit-content',
            maxWidth: '75%',
          })},[_c('v-card-text',{staticClass:"py-0"},[_c('span',{style:({
                color: ("" + (messaggio.id_utente == _vm.id_utente ? 'black' : 'white')),
              })},[_vm._v(_vm._s(messaggio.messaggio))]),_c('div',{staticStyle:{"text-align":"right"}},[_c('span',{style:({
                  color: ("" + (messaggio.id_utente == _vm.id_utente ? '#ECEFF' : '#FFFFFF')),
                  fontSize: '10px',
                })},[_c('i',[_vm._v(" "+_vm._s(messaggio.nominativo)+", "+_vm._s(messaggio.data_ins)+" ")])])])])],1)],1)}),0),_c('v-toolbar',{staticClass:"elevation-0",attrs:{"color":"rgba(255,255,255,0)","dense":""}},[_c('v-spacer'),_c('div',{staticClass:"mr-5",staticStyle:{"display":"inline-block","width":"200px"}},[(_vm.salva_messaggio_loader === true)?_c('v-progress-linear',{attrs:{"value":_vm.avanzamento_barra,"width":"200"}}):_vm._e()],1),(_vm.annulla_salva_messaggio_visible === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"inline-block"},attrs:{"small":""},on:{"click":_vm.clickSalvaMessaggio}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}],null,false,1349980960)},[_c('span',[_vm._v("Salva Messaggio")])]):_vm._e(),(_vm.annulla_salva_messaggio_visible === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"display":"inline-block"},attrs:{"small":""},on:{"click":_vm.clickAnnullaSalvaMessaggio}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}],null,false,2643794980)},[_c('span',[_vm._v("Annulla Salvataggio")])]):_vm._e()],1),_c('v-row',{staticClass:"my-0 pa-0"},[_c('v-textarea',{staticClass:"mx-5",attrs:{"label":"Nuovo Messaggio","outlined":""},model:{value:(_vm.nuovo_messaggio),callback:function ($$v) {_vm.nuovo_messaggio=$$v},expression:"nuovo_messaggio"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }