<template>
<div style="width:100% !important;" class="main">
    <v-row>
        <v-col md="12">
            <v-hover>
                <template v-slot="{ hover }">
                    <v-card :elevation="hover ? 24 : 2">
                        <v-toolbar dense class="indigo darken-1 headline mb-6" primary-title>
                            <v-toolbar-title class="white--text">Allegati Lista</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-text-field style="width:.3rem !important; background-color:white;" v-model="search" append-icon="mdi-magnify" label="Cerca" single-line hide-details>
                            </v-text-field>
                        </v-toolbar>
                        <v-card-text>
                            <v-data-table :headers="headers" :items="items">

                                <template v-slot:item.edit="{item}">
                                    <v-row>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-col class="ml-2" v-on="on" v-bind="attrs" md="4">
                                                    <v-btn @click="handleClick(item)" small style="float:left">
                                                        <v-icon md="4" color="#1a80b6">mdi-magnify</v-icon>
                                                    </v-btn>
                                                </v-col>

                                            </template>
                                            <span>Dettaglio</span>
                                        </v-tooltip>
                                    </v-row>

                                </template>

                                <template v-slot:item.delete="{}">

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-col class="ml-2" v-on="on" v-bind="attrs" md="4">
                                                <v-btn style="float:left" small>
                                                    <v-icon md="4" color="#c00000">mdi-window-close</v-icon>
                                                </v-btn>
                                            </v-col>

                                        </template>
                                        <span>Per Rimuovere
                                        </span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>

                        </v-card-text>
                    </v-card>
                </template>
            </v-hover>

        </v-col>
    </v-row>

</div>
</template>

<script>
export default {

    data() {
        return {
            search: "",
            headers: [{
                    align: "start",
                    value: "edit",
                    sortable: false,
                    width: "10px"
                },
                {
                    text: "Commessa",
                    value: "commessa",
                    sortable: false
                },
                {
                    text: "Cod. Commessa",
                    value: "cod_commessa",
                    sortable: false

                }, {
                    text: "Des. Commessa",
                    value: "des_commessa",
                    sortable: false,
                },
                {
                    text: "Cod. Articolo",
                    value: "cod_articolo",
                    sortable: false
                },
                {
                    text: "Des. Articolo",
                    value: "des_articolo",
                    sortable: false
                },
                {
                    text: "Barcode",
                    value: "barcode",
                    sortable: false
                },
                {
                    value: "delete",
                    sortable: false
                }

            ],
            items: [{
                    id: 1,
                    commessa: "Allegati1",
                    cod_commessa: "1453httrt4fd32",
                    des_commessa: "This is Des",
                    cod_articolo: "YT198894UN",
                    des_articolo: "this is Articolo",
                    barcode: "00024210042MGMFGD"
                },
                {
                    id: 2,
                    commessa: "Allegati2",
                    cod_commessa: "1453httrt4fd32",
                    des_commessa: "This is Des",
                    cod_articolo: "YT198894UN",
                    des_articolo: "this is Articolo",
                    barcode: "00024210042MGMFGD"
                },
                {
                    id: 3,
                    commessa: "Allegati3",
                    cod_commessa: "1453httrt4fd32",
                    des_commessa: "This is Des",
                    cod_articolo: "YT198894UN",
                    des_articolo: "this is Articolo",
                    barcode: "00024210042MGMFGD"
                },
                {
                    id: 4,
                    commessa: "Allegati4",
                    cod_commessa: "1453httrt4fd32",
                    des_commessa: "This is Des",
                    cod_articolo: "YT198894UN",
                    des_articolo: "this is Articolo",
                    barcode: "00024210042MGMFGD"
                },
                {
                    id: 5,
                    commessa: "Allegati5",
                    cod_commessa: "1453httrt4fd32",
                    des_commessa: "This is Des",
                    cod_articolo: "YT198894UN",
                    des_articolo: "this is Articolo",
                    barcode: "00024210042MGMFGD"
                },
                {
                    id: 6,
                    commessa: "Allegati6",
                    cod_commessa: "1453httrt4fd32",
                    des_commessa: "This is Des",
                    cod_articolo: "YT198894UN",
                    des_articolo: "this is Articolo",
                    barcode: "00024210042MGMFGD"
                }

            ]
        }
    },
    methods: {
        handleClick(val) {
            this.$router.push(`/home/production/binding/${val.id}`)
        }
    }

}
</script>

<style lang="scss" scoped>

</style>
