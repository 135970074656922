<template>
  <v-snackbar
    content-class="snackbar"
    :color="snackbar_background"
    right
    v-model="snackbar"
    :timeout="3000"
  >
    <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
    <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
      {{ snackbar_text }}
    </h2>

    <v-btn
      @click="snackbar = false"
      fab
      dark
      text
      small
      style="
        margin-top: -6rem;
        float: right !important;
        margin-right: -1rem !important;
      "
    >
      <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  name: "SnackbarQV",
  components: {},
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudi() {
      this.snackbar = false;
      this.snackbar_background = "";
      this.snackbar_icon = "";
      this.snackbar_icon_color = "";
      this.snackbar_text = "";
      this.snackbar_text_color = "";
    },
    print(c, te) {
      this.snackbar = true;
      this.snackbar_background =
        c === "success"
          ? "success"
          : c === "orange"
          ? "orange"
          : c === "error"
          ? "error"
          : "grey lighten-5";
      this.snackbar_icon =
        c === "success"
          ? "mdi-check"
          : c === "orange"
          ? "mdi-alert"
          : c === "error"
          ? "mdi-close"
          : "mdi-information";
      this.snackbar_icon_color =
        c === "success"
          ? "mdi-check"
          : c === "orange"
          ? "mdi-alert"
          : c === "error"
          ? "mdi-close"
          : "mdi-information";
      this.snackbar_text = te;
      this.snackbar_text_color =
        c === "success"
          ? "white"
          : c === "orange"
          ? "white"
          : c === "error"
          ? "white"
          : "black";
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>