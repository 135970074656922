<template>
  <div class="">
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Confirmation Dialog Before saving the Fase Standard -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogSavingConfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense color="indigo">
          <v-toolbar-title class="white--text">Salvataggio</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di voler salvare la fase standard?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="dialogSavingConfirmation = false"
            >ANNULLA</v-btn
          >
          <v-btn color="success" dark @click="UpdateCicloFasi">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirmation Dialog before Deletion of Fase Standard -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogdeletecicloconfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"> Cancellazione </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di di voler eliminare questo elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="success" dark @click="delCiclo">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Ciclo Dialog In order to Add/Remove Ciclo -->
    <v-dialog
      v-model="dialogCiclo"
      content-class="dialogshadow"
      persistent
      :scrollable="false"
      width="1350"
    >
      <v-container fluid>
        <form>
          <v-row>
            <v-card>
              <v-toolbar class="indigo darken-1" dense>
                <v-toolbar-title class="white--text">
                  Fase Standard :
                  <v-text-field
                    style="background-color: white !important; display: inline-block"
                    class="ml-3"
                    v-model="fase_description"
                    label="Descrizione Fase"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <Button
                  colore_icona="success"
                  idForm="IMPOSTAZIONI"
                  idOggetto="SALVA_FASE_STANDARD"
                  mdi_icona="mdi-floppy"
                  testo_tooltip="Salva Fase"
                  v-on:buttonClick="checkcodfase()"
                />
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="closeDialog" v-on="on" v-bind="attrs" small>
                      <v-icon color="error">mdi-window-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancellare</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text style="max-height: 600px; overflow-y: auto">
                <v-row>
                  <v-col md="1" class="py-0" cols="2" sm="2">
                    <TextField
                      idForm="FASE_STANDARD"
                      idOggetto="FS_COD_FASE"
                      label_input="Cod. Fase"
                      palceholder_input="Cod. Fase"
                      :vmodel="cod_fase"
                      v-on:changeText="(val) => (this.cod_fase = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" cols="3" sm="3" md="2">
                    <v-autocomplete
                      v-model="des1"
                      :items="tipo_produzione_list"
                      label="Tipo Produzione"
                      placeholder="Tipo Produzione"
                      item-text="text"
                      required
                    >
                      <template #label>
                        <span class="">Tipo Produzione</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" sm="1" cols="1" md="1">
                    <div class="ml-n4">
                      <span style="font-size: 12px; color: rgba(0, 0, 0, 0.6)"
                        >Prelievo Comp.
                      </span>
                      <v-switch
                        v-model="materia_prima"
                        style="margin-top: -0.3rem !important"
                        class="mt-n1 ml-5"
                        :false-value="0"
                        :true-value="1"
                      ></v-switch>
                    </div>
                  </v-col>
                  <v-col class="py-0" sm="2" cols="2" md="1">
                    <TextField
                      idForm="FASE_STANDARD"
                      idOggetto="FS_TEMPO_STANDARD"
                      label_input="Tempo Standard"
                      palceholder_input="Tempo"
                      :vmodel="tempo_standard"
                      v-on:changeText="(val) => (this.tempo_standard = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" sm="2" cols="2" md="1">
                    <v-autocomplete
                      style="width: 190px !important"
                      v-model="um_tempo"
                      :items="unita_misura_lead_time"
                      label="UM Tempo"
                      :placeholder="this.um_tempo ? this.um_tempo : 'UM Tempo'"
                      item-text="des"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" sm="2" cols="2" md="1">
                    <TextField
                      idForm="FASE_STANDARD"
                      idOggetto="FS_PESO"
                      label_input="Peso"
                      palceholder_input="Peso"
                      :vmodel="peso"
                      v-on:changeText="(val) => (this.peso = val)"
                      :min_input="0"
                      type_input="number"
                    />
                  </v-col>
                  <v-col class="py-0" sm="2" cols="2" md="1">
                    <v-autocomplete
                      style="width: 150px !important"
                      v-model="um_pezzo"
                      :items="unita_misura_peso"
                      label="UM Peso"
                      :placeholder="this.um_pezzo ? this.um_pezzo : 'UM Peso'"
                      item-text="des"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" sm="3" cols="3" md="3">
                    <v-autocomplete
                      v-model="des"
                      :items="fase_lavorazione_list"
                      label="Fase. Lavo"
                      placeholder="Fase. Lavo"
                      item-text="des"
                      required
                    >
                      <!-- :rules="[(value) => value !==  '']"-->
                      <template #label>
                        <span>Fase. Lavo</span>
                        <a @click.stop style="pointer-events: all">
                          <Modules
                            v-on:dialogModules="aggiornaFase"
                            tipo="FASE"
                            nome="Fase"
                            setaction="settabelle"
                            getaction="gettabelle"
                            controller="Magazzino"
                            filter="validita = 1"
                            :dati="[{ des: '' }]"
                          />
                        </a>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0" sm="2" cols="2" md="1">
                    <div class="">
                      <span
                        style="
                          font-size: 12px;
                          color: rgba(0, 0, 0, 0.6);
                          margin-left: -2.2rem;
                          padding-left: -0.2rem;
                        "
                        >Fase Esterna
                      </span>
                      <v-switch
                        v-model="fase_esterna"
                        style="margin-top: -0.3rem !important"
                        class="mt-n1"
                        :false-value="0"
                        :true-value="1"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="mt-n7">
                    <Fornitura
                      FORM_ID="IMPOSTAZIONI"
                      SOTTO_FORM="FASE_STANDARD"
                      :FORM_BUTTON_ARRAY="FORM_BUTTON_ARRAY"
                      v-on:updateFornitura="updateFornitura($event)"
                      :key="id_ciclostd_dettaglio"
                      :id_ciclostd_dettaglio="id_ciclostd_dettaglio"
                      :data="fornitoriLista"
                      v-on:delFornitura="deleteFornituraLista($event)"
                    />
                  </v-col>
                  <v-col class="mt-n5" md="6">
                    <Macchina
                      FORM_ID="IMPOSTAZIONI"
                      SOTTO_FORM="FASE_STANDARD"
                      :MACCHINA_BUTTON_ARRAY="MACCHINA_BUTTON_ARRAY"
                      v-on:updateMacchina="updateMacchina($event)"
                      :key="id_ciclostd_dettaglio"
                      :id_ciclostd_dettaglio="id_ciclostd_dettaglio"
                      :data="articolo_ciclo_fasi_macchina"
                      v-on:delMacchina="delMacchina($event)"
                    />
                  </v-col>
                  <v-col class="mt-n5" md="6">
                    <Personale
                      FORM_ID="IMPOSTAZIONI"
                      SOTTO_FORM="FASE_STANDARD"
                      :PERSONALE_BUTTON_ARRAY="PERSONALE_BUTTON_ARRAY"
                      v-on:updatePersonale="updatePersonale($event)"
                      :key="id_ciclostd_dettaglio"
                      :id_ciclostd_dettaglio="id_ciclostd_dettaglio"
                      :data="articolociclipersonale"
                      v-on:delPersonale="delPersonale($event)"
                    />
                  </v-col>
                  <v-col md="12" class="ml-1">
                    <v-textarea
                      v-model="note"
                      flat
                      item-text="text"
                      label="Annotazione"
                      placeholder="Annotazione"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </form>
      </v-container>
    </v-dialog>
    <!-- Main Table of Fase Standard -->
    <v-row>
      <v-col md="12">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2" class="">
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text">Fasi Standard</v-toolbar-title>
                <v-text-field
                  style="background-color: white !important"
                  class="ml-2"
                  v-model="search"
                  label="Cerca"
                  single-line
                  hide-details
                >
                  <v-icon slot="append" color="primary"> mdi-magnify </v-icon>
                </v-text-field>
                <v-spacer></v-spacer>
                <Button
                  colore_icona="primary"
                  idForm="IMPOSTAZIONI"
                  idOggetto="CREA_FASE_STANDARD"
                  mdi_icona="mdi-plus"
                  testo_tooltip="Nuova Fase Std."
                  v-on:buttonClick="opendialogciclo"
                />
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on"
                      ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
                    >
                  </template>
                  <span>Chiudi</span>
                </v-tooltip>
              </v-toolbar>
              <v-data-table
                :loading="loadingCiclo"
                :headers="CicloHeaders"
                :items="cicloLista"
                :search="search"
              >
                <v-progress-linear
                  v-show="progressBarCiclo"
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-linear>
                <template v-slot:[`item.edit`]="{ item }">
                  <Button
                    colore_icona="primary"
                    idForm="IMPOSTAZIONI"
                    idOggetto="MODIFICA_FASE_STANDARD"
                    mdi_icona="mdi-pencil"
                    testo_tooltip="Aggiornare"
                    v-on:buttonClick="handleClick(item)"
                  />
                </template>
                <template v-slot:[`item.materia_prima`]="{ item }">
                  <v-icon v-if="item.materia_prima === 1">mdi-check-underline</v-icon>
                </template>
                <template v-slot:[`item.fase_esterna`]="{ item }">
                  <v-icon v-if="item.fase_esterna === 1">mdi-check-underline</v-icon>
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <Button
                    colore_icona="error"
                    idForm="IMPOSTAZIONI"
                    idOggetto="ELIMINA_FASE_STANDARD"
                    mdi_icona="mdi-window-close"
                    testo_tooltip="Cancellare"
                    v-on:buttonClick="seletedCiclo(item)"
                  />
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <!-- In order to show All the Alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import moment from "moment";
import Fornitura from "../../../views/PRODUCTION/Common/Fornitura.vue";
import Macchina from "../../../views/PRODUCTION/Common/Macchina.vue";
import Personale from "../../../views/PRODUCTION/Common/Personale.vue";
export default {
  props: ["data"],
  components: {
    Modules: () => import("../../TOOLS/Modules"),
    Button,
    TextField,
    Fornitura,
    Macchina,
    Personale,
  },
  data() {
    return {
      FORM_BUTTON_ARRAY: [
        "CREA_FASE_STANDARD_FORNITURA",
        "MODIFICA_FASE_STANDARD_FORNITURA",
        "ELIMINA_FASE_STANDARD_FORNITURA",
        "SALVA_FASE_STANDARD_FORNITURA",
      ],
      MACCHINA_BUTTON_ARRAY: [
        "CREA_FASE_STANDARD_MACCHINA",
        "MODIFICA_FASE_STANDARD_MACCHINA",
        "ELIMINA_FASE_STANDARD_MACCHINA",
        "SALVA_FASE_STANDARD_MACCHINA",
      ],
      PERSONALE_BUTTON_ARRAY: [
        "CREA_FASE_STANDARD_PERSONALE",
        "MODIFICA_FASE_STANDARD_PERSONALE",
        "ELIMINA_FASE_STANDARD_PERSONALE",
        "SALVA_FASE_STANDARD_PERSONALE",
      ],
      //<==============================Fase Standard Variables=====================>//
      ciclostd_dettaglio: null,
      fase_description: "",
      cicloLista: [],
      tipo_produzione_list: [],
      fase_lavorazione_list: [],
      progressBarCiclo: false,
      loadingCiclo: false,
      dialogCiclo: false,
      id_ciclostd_dettaglio: "",
      cod_fase: "",
      produzione_list: [],
      id_tipo_fase: "",
      id_fase: "",
      fase_list: [],
      search: "",
      peso: "",
      unita_misura_peso: [],
      des: "",
      des1: "",
      cod_macchina: null,
      costo_lavorazione: null,
      fase_esterna: 0,
      materia_prima: 0,
      tempo_standard: 0,
      um_tempo: -1,
      id_um_tempo: "",
      des_um_tempo: "",
      id_um_pezzo: "",
      um_pezzo: "",
      singleitem: {},
      note: "",
      // Variable for pop modal for deletion
      dialogdeletecicloconfirmation: false,
      selectedCiclo: {},
      //<======== Fornitura Variables =====>//
      fornitoriLista: [],
      deletedFornitura: [],
      dataToUpdate: {},
      id_ciclostd_fasi_fornitori: "",
      // Macchina Variables
      articolo_ciclo_fasi_macchina: [],
      singleMacchina: {},
      deletedMacchina: [],
      macchinaList: [],
      unita_misura_lead_time: [],
      // Personale Variables
      articolociclipersonale: [],
      id_ciclostd_fasi_personale: "",
      deletedPersonale: [],
      // Variabili d'appoggio
      index_sel: null,
      //  Generic Variables
      item: "",
      overlay: false,
      validita: 1,
      // Confirmation Dialog
      dialogSavingConfirmation: false,
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_text: "",
      deletedFase: null,
      // Header of ciclo table
      CicloHeaders: [],
    };
  },
  methods: {
    //  updating macchina Lista
    updatePersonale(val) {
      this.articolociclipersonale = val;
    },
    // Delete Macchina
    delPersonale(val) {
      this.deletedPersonale = val;
    },
    //  updating macchina Lista
    updateMacchina(val) {
      this.articolo_ciclo_fasi_macchina = val;
    },
    // Delete Macchina
    delMacchina(val) {
      this.deletedMacchina = val;
    },
    // Updating the Fornitura Lista
    deleteFornituraLista(val) {
      this.deletedFornitura = val;
    },
    // Delete Fornitura
    updateFornitura(val) {
      val.map((it) => {
        delete it.id_articolo;
        delete it.id_articolo_fornitura;
        delete it.id_ciclo_fasi;
        delete it.id_commessa_dettaglio;
        delete it.idciclo_fasi_fornitori;
      });
      this.fornitoriLista = val;
    },
    // TO Toggle the Table
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    // To Get all the list of Fasi Standard
    getAllFaseStd() {
      this.overlay = true;
      // Getting Ciclo lista to populate the table
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getfasestd",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: "",
        },
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        this.overlay = false;
        const data = res.data.lista;
        this.CicloHeaders = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false,
        };
        this.CicloHeaders.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false,
        };
        this.CicloHeaders.push(data1);
        data.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        this.cicloLista = data;
        if (this.cicloLista.length > 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
        if (this.cicloLista.length === 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
      });
    },
    // In order to check two Cod_fase with same value\
    checkcodfase() {
      if (this.cicloLista.length === 0) {
        this.dialogSavingConfirmation = true;
      }
      this.cicloLista.map((it) => {
        if (it.cod_fase == this.cod_fase && this.id_ciclostd_dettaglio === "") {
          this.snackbar = true;
          // this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          // this.snackbar_text = "la quantità massima do    vrebbe essere maggiore della quantità minima";
          this.snackbar_text =
            "Non è possibile salvare due cicli con lo stesso codice fase";
          this.dialogSavingConfirmation = false;
          return;
        }
        if (
          it.cod_fase == this.cod_fase &&
          this.id_ciclostd_dettaglio != it.id_ciclostd_dettaglio
        ) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text =
            "Non è possibile salvare due cicli con lo stesso codice fase";
          this.dialogSavingConfirmation = false;
          return;
        } else {
          if (!this.snackbar) {
            this.dialogSavingConfirmation = true;
          }
        }
      });
    },
    // In order to Open Deletion Pop up
    seletedCiclo(item) {
      this.deletedFase = item;
      this.deletedFase.validita = 0;
      this.dialogdeletecicloconfirmation = true;
    },
    // TO Deselect the item when hit Annulla of deletion
    delSelectedItem() {
      this.dialogdeletecicloconfirmation = false;
      this.selectedCiclo = {};
    },
    // In order to update Fase Lista
    aggiornaFase() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "FASE",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.fase_list = res.data;
        this.fase_list.map((el) => {
          this.fase_lavorazione_list.push(el.des);
        });
      });
    },
    // In order to open Ciclo form dialog
    opendialogciclo() {
      this.des1 = null;
      this.materia_prima = 0;
      this.tempo_standard = null;
      this.fase_esterna = 0;
      this.des = null;
      this.cod_fase = null;
      this.fase_description = "";
      this.dialogCiclo = true;
      this.id_ciclostd_dettaglio = "";
      this.um_tempo = "";
      this.um_pezzo = "";
      this.peso = "";
      this.note = "";
      this.fornitoriLista = [];
      this.articolo_ciclo_fasi_macchina = [];
      this.articolociclipersonale = [];
      this.dataToUpdate = {};
      this.progressBarMacchina = false;
    },
    // In order to clear the personale form when simply closing it
    closeDialog() {
      this.dialogCiclo = false;
      this.articolociclipersonale = [];
      this.fornitoriLista = [];
      this.articolo_ciclo_fasi_macchina = [];
      this.codice = "";
      this.des1 = "";
      this.materia_prima = "";
      this.tempo_standard = "";
      this.des = "";
      this.fase_esterna = 0;
    },
    // Getting the single ciclo value
    handleClick(value) {
      this.overlay = true;
      // Getting the Value of single Fase Standard From backend
      let getFaseStandardDettaglio = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getfasestd",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: value.id_ciclostd_dettaglio,
        },
      };
      this.$store.dispatch("api", getFaseStandardDettaglio).then((res) => {
        this.overlay = false;
        this.fornitoriLista =
          res.data.ciclostd_dettaglio[0].ciclostd_dettaglio_fasi_fornitori;
        this.ciclostd_dettaglio = res.data.ciclostd_dettaglio[0];
        this.fase_description = this.ciclostd_dettaglio.des_fase_standard;
        this.cod_fase = this.ciclostd_dettaglio.cod_fase;
        this.des1 = this.ciclostd_dettaglio.des1;
        this.des = this.ciclostd_dettaglio.des;
        this.materia_prima = this.ciclostd_dettaglio.materia_prima;
        this.tempo_standard = this.ciclostd_dettaglio.tempo_standard;
        this.peso = this.ciclostd_dettaglio.peso;
        this.fase_esterna = this.ciclostd_dettaglio.fase_esterna;
        this.id_ciclostd_dettaglio = this.ciclostd_dettaglio.id_ciclostd_dettaglio;
        this.note = this.ciclostd_dettaglio.note;
        this.id_um_pezzo = this.ciclostd_dettaglio.id_um_pezzo;
        this.id_um_tempo = this.ciclostd_dettaglio.id_um_tempo;
        this.um_tempo = this.ciclostd_dettaglio.um_tempo;
        this.um_pezzo = this.ciclostd_dettaglio.um_pezzo;
        this.progressBarMacchina = true;
        this.loadingMacchina = true;
        this.articolo_ciclo_fasi_macchina = this.ciclostd_dettaglio.ciclostd_dettaglio_fasi_macchina;
        this.progressBarMacchina = false;
        this.articolo_ciclo_fasi_macchina.sort((a, b) => {
          if (a.des_macchina < b.des_macchina) {
            return -1;
          }
          if (a.des_macchina > b.des_macchina) {
            return 1;
          }
          return 0;
        });
        if (this.articolo_ciclo_fasi_macchina.length > 0) {
          this.progressBarMacchina = false;
          this.loadingMacchina = false;
        }
        if (this.articolo_ciclo_fasi_macchina.length === 0) {
          this.progressBarMacchina = false;
          this.loadingMacchina = false;
        }
        this.loadingFornitura = true;
        this.progressBarFornitura = true;
        this.loadingFornitura = false;
        this.progressBarFornitura = false;
        this.articolociclipersonale = this.ciclostd_dettaglio.ciclostd_dettaglio_fasi_personale;
        this.articolociclipersonale.sort((a, b) => {
          if (a.competenza < b.competenza) {
            return -1;
          }
          if (a.competenza > b.competenza) {
            return 1;
          }
          return 0;
        });
        if (this.articolociclipersonale.length > 0) {
          this.progressBarPersonale = false;
          this.loadingPersonale = false;
        }
        if (this.articolociclipersonale.length === 0) {
          this.progressBarPersonale = false;
          this.loadingPersonale = false;
        }
      });
      this.dialogCiclo = true;
      // this.ciclostd_dettaglio = value;
    },
    // In order to remove ciclo
    delCiclo() {
      this.overlay = true;
      let updateCiclo = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setfasestandard",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: this.deletedFase.id_ciclostd_dettaglio,
          ciclostd_dettaglio: [this.deletedFase],
        },
      };
      this.$store.dispatch("api", updateCiclo).then(() => {
        this.dialogdeletecicloconfirmation = false;
        this.overlay = false;
        this.getAllFaseStd();
      });
    },
    // In order to save a new ciclo or update an existing one
    UpdateCicloFasi() {
      this.overlay = true;
      if (this.id_ciclostd_dettaglio === "") {
        let i = this.cicloLista.findIndex(
          (it) => it.des_fase_standard == this.fase_description
        );
        i;
        if (i != -1) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text =
            "Non è possibile salvare due fasi con la stessa descrizione";
          return;
        }
        if (this.des1 == null || this.des == null) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text =
            "Definire almeno il Tipo Produzione e la Fase Lavorazione per salvare.";
          return;
        }
        if (!this.fase_description) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire la Descrizione Fase prima di salvare.";
          return;
        }
        if (this.cod_fase === "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire il Cod Fase prima di salvare.";
          return;
        }
        let data = {};
        data.id_ciclostd_dettaglio = -1;
        data.id_ciclostd = null;
        data.des_fase_standard = this.fase_description;
        data.cod_fase = this.cod_fase != "" ? this.cod_fase : null;
        data.riga_fase = null;
        if (this.des1 == "") {
          data.id_tipo_fase = null;
        } else {
          this.produzione_list.map((el) => {
            if (el.des === this.des1) {
              data.id_tipo_fase = el.id_tabella;
              return data;
            }
          });
        }
        if (this.um_pezzo == "") {
          data.id_um_pezzo = null;
        } else {
          this.unita_misura_peso.map((ui) => {
            if (ui.des === this.um_pezzo) {
              data.id_um_pezzo = ui.id_tabella;
            }
          });
        }
        data.id_um_tempo = this.um_tempo;
        if (this.um_tempo == "") {
          data.id_um_tempo = null;
        } else {
          this.unita_misura_lead_time.map((ui) => {
            if (ui.des === data.id_um_tempo) {
              data.id_um_tempo = ui.id_tabella;
              // data.des_um_tempo = ui.des;
            }
          });
        }
        data.materia_prima = this.materia_prima;
        data.fase_esterna = this.fase_esterna;
        if (this.des == "") {
          data.id_fase = null;
        } else {
          this.fase_list.map((el) => {
            if (el.des === this.des) {
              data.id_fase = el.id_tabella;
              return data;
            }
          });
        }
        data.lotto_produzione = null;
        data.tempo_standard = this.tempo_standard == "" ? null : this.tempo_standard;
        data.lead_time = null;
        data.lotto_lavorazione = null;
        data.costo_lavorazione =
          this.costo_lavorazione == "" ? null : this.costo_lavorazione;
        data.id_macchina = null;
        data.pezzi_ora_min = null;
        data.pezzi_ora_max = null;
        data.tempo_setup = null;
        data.id_attrezzatura = null;
        data.id_anagrafica = null;
        data.note = this.note == "" ? null : this.note;
        data.riga = null;
        data.validita = 1;
        data.date_ins = null;
        data.date_mod = null;
        data.id_utente = localStorage.id;
        data.id_temp = moment().unix();
        data.des = this.des == "" ? null : this.des;
        data.des1 = this.des1 == "" ? null : this.des1;
        data.um_pezzo = this.um_pezzo == "" ? null : this.um_pezzo;
        data.um_tempo = this.um_tempo == "" ? null : this.um_tempo;
        data.ciclostd_dettaglio_fasi_personale = this.articolociclipersonale;
        data.ciclostd_dettaglio_fasi_macchina = this.articolo_ciclo_fasi_macchina;
        data.ciclostd_dettaglio_fasi_fornitori = this.fornitoriLista;
        this.fase_list.map((fa) => {
          if (fa.des === data.des) {
            data.id_fase = fa.id_tabella;
          }
          this.produzione_list.map((prod) => {
            if (prod.des === data.des1) {
              data.id_tipo_fase = prod.id_tabella;
            }
          });
        });
        if (this.fase_esterna) {
          var fornitoriwithpref = [];
          data.ciclostd_dettaglio_fasi_fornitori.map((it) => {
            if (it.predefinito !== 0) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text = "È necessario selezionare una fornitura predefinita.";
            return;
          }
          data.ciclostd_dettaglio_fasi_macchina.map((it) => (it.predefinito = 0));
        }
        if (!this.fase_esterna) {
          var macchinawithpref = [];
          data.ciclostd_dettaglio_fasi_macchina.map((it) => {
            if (it.predefinito == 1) {
              macchinawithpref.push(it);
            }
          });
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text = "È necessario selezionare una macchina predefinita.";
            return;
          }
          data.ciclostd_dettaglio_fasi_fornitori.map((it) => (it.predefinito = 0));
        }
        let dataArray = [];
        dataArray.push(data);
        this.dialog = false;
        let updateCiclo = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setfasestandard",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: -1,
            ciclostd_dettaglio: dataArray,
          },
        };
        this.$store.dispatch("api", updateCiclo).then((res) => {
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          data.id_ciclostd_dettaglio = res.data;
          this.getAllFaseStd();
          this.cicloLista.push(data);
          this.cicloLista.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        });
        this.dialogCiclo = false;
        this.codice = "";
        this.peso = "";
        this.des = "";
        this.des1 = "";
        this.materia_prima = "";
        this.tempo_standard = "";
        this.peso = "";
        this.fase_esterna = "";
        this.note = "";
        this.id_ciclostd_dettaglio = "";
        this.cod_fase = "";
        this.dialogSavingConfirmation = false;
      }
      if (this.id_ciclostd_dettaglio) {
        "this.id_ciclostd_dettaglio :>> ", this.id_ciclostd_dettaglio;
        if (this.des1 == null || this.des == null) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text =
            "Definire almeno il Tipo Produzione e la Fase Lavorazione per salvare.";
          return;
        }
        if (this.fase_description == null) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire la Descrizione Fase prima di salvare.";
          return;
        }
        if (this.cod_fase === "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire il Cod Fase prima di salvare.";
          return;
        }
        let data = this.ciclostd_dettaglio;
        // ("data", data);
        this.produzione_list.map((el) => {
          if (el.des === this.des1) {
            data.id_tipo_fase = el.id_tabella;
            return data;
          }
        });
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
            return data;
          }
        });
        data.cod_macchina = this.ciclostd_dettaglio.cod_macchina;
        data.cod_fase = this.cod_fase;
        data.des_fase_standard = this.fase_description;
        data.costo_lavorazione = this.ciclostd_dettaglio.costo_lavorazione;
        data.date_ins = this.ciclostd_dettaglio.date_ins;
        data.date_mod = this.ciclostd_dettaglio.date_mod;
        data.des = this.des;
        data.des1 = this.des1;
        data.fase_esterna = this.fase_esterna;
        data.materia_prima = this.materia_prima;
        data.id_anagrafica = this.ciclostd_dettaglio.id_anagrafica;
        data.id_attrezzatura = this.ciclostd_dettaglio.id_attrezzatura;
        data.id_ciclostd_dettaglio = this.ciclostd_dettaglio.id_ciclostd_dettaglio;
        data.id_fase = this.id_fase;
        data.id_macchina = this.ciclostd_dettaglio.id_macchina;
        data.id_tipo_fase = this.id_tipo_fase;
        data.id_utente = localStorage.id;
        data.lead_time = this.ciclostd_dettaglio.lead_time;
        data.lotto_lavorazione = this.ciclostd_dettaglio.lotto_lavorazione;
        data.lotto_produzione = this.ciclostd_dettaglio.lotto_produzione;
        data.materia_prima = this.materia_prima;
        data.note = this.note;
        data.peso = this.peso;
        data.pezzi_ora_max = this.ciclostd_dettaglio.pezzi_ora_max;
        data.pezzi_ora_min = this.ciclostd_dettaglio.pezzi_ora_min;
        data.riga = this.ciclostd_dettaglio.riga;
        data.riga_fase = this.ciclostd_dettaglio.riga_fase;
        data.tempo_setup = this.ciclostd_dettaglio.tempo_setup;
        data.tempo_standard = this.tempo_standard;
        data.validita = this.ciclostd_dettaglio.validita;
        data.id_um_tempo = this.um_tempo;
        data.id_um_pezzo = this.um_pezzo;
        this.fase_list.map((fa) => {
          if (fa.des === data.des) {
            data.id_fase = fa.id_tabella;
          }
          this.produzione_list.map((prod) => {
            if (prod.des === data.des1) {
              data.id_tipo_fase = prod.id_tabella;
            }
          });
        });
        this.unita_misura_lead_time.map((ui) => {
          if (ui.des === data.id_um_tempo) {
            data.id_um_tempo = ui.id_tabella;
            data.um_tempo = ui.des;
          }
        });
        this.unita_misura_peso.map((ui) => {
          if (ui.des === data.id_um_pezzo) {
            data.id_um_pezzo = ui.id_tabella;
            data.um_pezzo = ui.des;
          }
        });
        if (this.deletedMacchina.length > 0) {
          this.deletedMacchina.map((el) => {
            this.articolo_ciclo_fasi_macchina.push(el);
          });
        }
        if (this.deletedPersonale.length > 0) {
          this.deletedPersonale.map((el) => {
            this.articolociclipersonale.push(el);
          });
        }
        if (this.deletedFornitura.length > 0) {
          this.deletedFornitura.map((el) => {
            this.fornitoriLista.push(el);
          });
        }
        data.ciclostd_dettaglio_fasi_personale = this.articolociclipersonale;
        data.ciclostd_dettaglio_fasi_macchina = this.articolo_ciclo_fasi_macchina;
        data.ciclostd_dettaglio_fasi_fornitori = this.fornitoriLista;
        if (this.fase_esterna) {
          let fornitoriwithpref = [];
          data.ciclostd_dettaglio_fasi_fornitori.map((it) => {
            if (it.predefinito !== 0) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text = "È necessario selezionare una fornitura predefinita.";
            return;
          }
          data.ciclostd_dettaglio_fasi_macchina.map((it) => (it.predefinito = 0));
        }
        if (!this.fase_esterna) {
          let macchinawithpref = [];
          data.ciclostd_dettaglio_fasi_macchina.map((it) => {
            if (it.predefinito == 1) {
              macchinawithpref.push(it);
            }
          });
          macchinawithpref;
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text = "È necessario selezionare una macchina predefinita.";
            return;
          }
          data.ciclostd_dettaglio_fasi_fornitori.map((it) => (it.predefinito = 0));
        }
        let dataArray = [];
        dataArray.push(data);
        // ("data", data);
        this.dialog = false;
        // ("dataArray :>> ", dataArray);
        let updateCiclo = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setfasestandard",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: data.id_ciclostd_dettaglio,
            ciclostd_dettaglio: dataArray,
          },
        };
        this.$store.dispatch("api", updateCiclo).then(() => {
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          let i = this.cicloLista.findIndex(
            (it) => it.id_ciclostd_dettaglio == data.id_ciclostd_dettaglio
          );
          this.getAllFaseStd();
          this.cicloLista.splice(i, 1, data);
          this.cicloLista.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
          data = {};
        });
        this.dialog = false;
        this.dialogCiclo = false;
        this.codice = "";
        this.peso = "";
        this.des = "";
        this.des1 = "";
        this.materia_prima = "";
        this.tempo_standard = "";
        this.peso = "";
        this.fase_esterna = "";
        this.note = "";
        this.id_ciclostd_dettaglio = "";
        this.id_ciclo_test = "";
        this.singleitem = {};
        this.cod_fase = "";
        this.id_ciclostd_dettaglio = "";
        this.dialogSavingConfirmation = false;
      }
    },
  },
  mounted() {
    this.getAllFaseStd();
    // Getting all the lista value for select fields
    this.progressBarCiclo = true;
    this.loadingCiclo = true;
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'UNITA_MISURA','TIPO_PRODUZIONE','FASE'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array UNITA_MISURA che mi arriva dal BE
      res.data.UNITA_MISURA.map((el) => {
        if (el.val_txt === "P") {
          this.unita_misura_peso.push(el);
        }
        if (el.val_txt === "H") {
          this.unita_misura_lead_time.push(el);
        }
      });
      this.unita_misura_mod_list = res.data.UNITA_MISURA;
      // Gestisco l'array TIPO_PRODUZIONE che mi arriva dal BE
      this.produzione_list = res.data.TIPO_PRODUZIONE;
      this.produzione_list.map((el) => {
        this.tipo_produzione_list.push(el.des);
      });
      // Gestisco l'array FASE che mi arriva dal BE
      this.fase_list = res.data.FASE;
      this.fase_lavorazione_list = res.data.FASE;
      // Gestisco l'array COMPETENZA che mi arriva dal BE
    });
  },
};
</script>
<style css>
.dialogshadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
