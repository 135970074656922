<template>
<div class="main mt-1 ml-auto mr-auto">
    <v-hover>
        <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2" style="" class="mt-2">
                <v-toolbar dense class="indigo darken-1 headline" primary-title>
                    <v-toolbar-title class="white--text">Registrazione</v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                    <v-row>
                        <v-col class="mt-n5" md="4">
                            <v-row>
                                <v-col class="ml-2" md="5">
                                    <v-text-field type="text" class="input-class" v-model="codice" placeholder="Codice" required></v-text-field>
                                    <p class="mt-n5">Coneftenztrice Verticale CORR[72343234]</p>
                                </v-col>
                                <v-col class="ml-2" md="4">
                                    <v-btn tile small class="mt-5 mr-2" color="primary">
                                        <v-icon size="24">mdi-magnify</v-icon>
                                    </v-btn>
                                    <v-btn tile small class="mt-5" color="primary">
                                        <v-icon size="24">mdi-menu</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col class="mt-n10 ml-2" md="5">
                                    <v-text-field type="text" class="input-class" v-model="codice1" placeholder="Codice1" required></v-text-field>
                                    <p class="mt-n5">Coneftenztrice Verticale CORR[24]</p>
                                </v-col>
                                <v-col class="mt-n6" md="1">
                                    <v-btn tile small class="mt-5 mr-2" color="primary">
                                        <v-icon size="24">mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="mt-n8" cols="12" md="4" sm="6">
                                    <v-text-field class="mx-2" label="0" prepend-icon="mdi-magnify"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mt-n10">
                                <v-col class="ml-2 text-center" md="4">
                                    <v-autocomplete v-model="id_tipo" :items="tipoList" :disabled="disabletipo" label="CONFORME" :placeholder="id_tipo" item-text="des" required return-object>
                                        <template v-slot:selection="data">
                                            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="tiporemove(data.item)">{{ data.item.des }}</v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row class="mt-n12">
                                <v-col md="6">
                                    <v-switch v-model="componente_extra" label="Saldo Fase Commessa" class="pa-3"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n10">
                                <v-col class="ml-1" md="12">
                                    <v-btn tile color="primary">REGISTRA</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col class="ml-n16" md="8">
                            <v-data-table :hide-default-footer="true" :headers="headers"></v-data-table>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <v-row>

                        <v-col md="4">
                            <v-row class="mt-n4">
                                <v-col>
                                    Quantità totale fase:9000
                                </v-col>
                            </v-row>
                            <v-row class="mt-n6">
                                <v-col>
                                    Quantità Prodotta:null
                                </v-col>
                            </v-row>
                            <v-row class="mt-n6">
                                <v-col>
                                    Cod. Articolo: K49,3199,26
                                </v-col>
                            </v-row>
                            <v-row class="mt-n6">
                                <v-col>
                                    Cod. Lotto: 20/F566
                                </v-col>
                            </v-row>
                            <v-row class="mt-n6">
                                <v-col>
                                    Componenti prelevati:
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col class="ml-n16" md="8">

                            <v-data-table :hide-default-footer="true" :headers="headers1"></v-data-table>

                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col md="12">

                            <v-data-table :hide-default-footer="true" :items="items" :headers="headers2"></v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </template>
    </v-hover>
</div>
</template>

<script>
export default {
    data() {
        return {
            codice: "",
            headers: [{
                    text: "Stampi",
                    value: "stampi",
                    sortable: false
                },
                {
                    text: "Commessa",
                    value: "commessa",
                    sortable: false
                },
                {
                    text: "Fase",
                    value: "fase",
                    sortable: false
                },
                {
                    text: "Macchina",
                    value: "macchina",
                    sortable: false
                },
                {
                    text: "Cod. Articolo",
                    value: "cod_articolo",
                    sortable: false
                },
                {
                    text: "Q.ta Prodotta",
                    value: "qty_prodotta",
                    sortable: false
                },
                {
                    text: "Q.ta Commessa",
                    value: "qty_commessa"
                },
                {
                    text: "Casuale",
                    value: "casuale"
                },
                {
                    text: "Data",
                    value: "data"
                },
                {
                    text: "Eliminazione",
                    value: "elimin"
                }
            ],
            headers1: [{
                    text: "Lotto",
                    value: "lotto",
                    sortable: false
                },
                {
                    text: "Componente",
                    value: "componente",
                    sortable: false
                },
                {
                    text: "Q.ta Scartata",
                    value: "qty_scartata",
                    sortable: false
                },

            ],
            headers2: [{
                    text: "Lotto",
                    value: "lotto",
                    sortable: false
                },
                {
                    text: "Componente",
                    value: "componente",
                    sortable: false
                },
                {
                    text: "Q.ta Impegnata",
                    value: "qty_impegnata",
                    sortable: false
                },
                {
                    text: "Q.ta Prelevata",
                    value: "qty_prelevata",
                    sortable: false
                },
                {
                    text: "Magazzino",
                    value: "magazzino",
                    sortable: false
                },
                {
                    text: "Reso",
                    value: "reso",
                    sortable: false
                },

            ]
        };
    }
};
</script>

<style css scoped>

</style>
