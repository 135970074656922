<template>
  <div>
    <v-card>
      <v-card-text class="ma-0 pa-0">
        <v-data-table
          fixed-header
          :headers="headers_tipologie"
          :items="items_tipologie.filter((elem) => elem.validita === 1)"
          :single-expand="true"
          :expanded.sync="expanded_tipologie"
          height="70vh"
          item-key="id_anagrafica_tipologia"
          show-expand
          :key="contatore_tipologie"
          class="elevation-1 mx-5 mt-5 pt-0"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="pa-0"
              style="background-color: white"
              :colspan="headers.length"
            >
              <v-data-table
                class="elevation-3"
                :headers="
                  headers_fascie_orarie.filter(
                    (elem) => elem.text != 'Modifica' && elem.text != 'Elimina'
                  )
                "
                hide-default-footer
                :items="item.fascie_orarie"
              >
                <!-- Titolo tabella -->
                <template v-slot:top>
                  <v-toolbar color="indigo" dense>
                    <v-toolbar-title class="white--text"
                      >Fasce Orarie
                    </v-toolbar-title>
                  </v-toolbar>
                </template>

                <!-- Colonna dell'importo dell'approvatore -->
              </v-data-table>
            </td>
          </template>
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text">
                Dettagli Tipologie
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="clickAggiungiTipologia"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Tipologia</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-5"
                    :disabled="
                      anagrafica_sel.id_anagrafica == -1 ||
                      items_tipologie.length == 0
                        ? true
                        : false
                    "
                    @click="salvaTipologie()"
                    small
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon color="success">mdi-floppy</v-icon>
                  </v-btn>
                </template>
                <span>Salva tipologie</span>
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaTipologia(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary"> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Modifica</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.specializzazioni`]="{ item }">
            <span
              class="mr-2"
              v-for="(elem, index) in item.specializzazioni"
              :key="index"
              >{{ elem.des_ambito_manutenzione }};</span
            >
          </template>
          <template v-slot:[`item.elimina`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="item.disabilita_eliminazione"
                  @click="clickEliminaOperatore(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Elimina</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog v-model="aggiungi_tipologia_dialog" persistent>
        <v-snackbar
          content-class="snackbar"
          :color="snackbar_background"
          right
          v-model="snackbar_dialog"
          :timeout="3000"
          :style="{}"
        >
          <v-icon :color="snackbar_icon_color" :size="64">{{
            snackbar_icon
          }}</v-icon>
          <h2
            :style="{ color: snackbar_text_color + '!important' }"
            class="ml-16"
          >
            {{ snackbar_text }}
          </h2>

          <v-btn
            @click="snackbar_dialog = false"
            fab
            dark
            text
            small
            style="
              margin-top: -6rem;
              float: right !important;
              margin-right: -1rem !important;
            "
          >
            <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Dettagli Tipologia</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="salvaModificheTipologia"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span>Salva Tipologia</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="overflow-y: auto">
            <v-row class="ma-0 pa-0" align="center" justify="center">
              <v-col cols="2">
                <v-text-field
                  label="Descrizione"
                  v-model="tipologia_sel.nome_tipologia"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="Livello"
                  v-model="tipologia_sel.livello"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="tipologia_sel.specializzazioni"
                  :items="tipo_intervento_lista"
                  item-text="des_ambito_manutenzione"
                  return-object
                  :menu-props="{ maxHeight: '400' }"
                  label="Specializzazioni"
                  placeholder="Associa"
                  :key="contatore_render_tipologia_lista"
                  multiple
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-container>
                <v-data-table
                  fixed-header
                  :headers="headers_fascie_orarie"
                  height="40vh"
                  :items="
                    tipologia_sel.fascie_orarie.filter(
                      (elem) => elem.validita != 0
                    )
                  "
                  :key="contatore_fascie"
                >
                  <template v-slot:top>
                    <v-toolbar color="indigo" dense>
                      <v-toolbar-title class="white--text">
                        Fasce Orarie
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="clickAggiungiFasciaOraria()"
                            small
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon color="primary">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Aggiungi Fascia Oraria</span>
                      </v-tooltip>

                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.modifica`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="clickModificaFasciaOraria(item)"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary"> mdi-pencil </v-icon>
                        </v-btn>
                      </template>
                      <span>Modifica</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.specializzazioni`]="{ item }">
                    <span
                      class="mr-2"
                      v-for="(elem, index) in item.specializzazioni"
                      :key="index"
                      >{{ elem.des_ambito_manutenzione }};</span
                    >
                  </template>
                  <template v-slot:[`item.elimina`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="item.disabilita_eliminazione"
                          @click="clickEliminaFasciaOraria(item)"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="error"> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Elimina</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-container>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="conferma_elimina_tipologia_dialog" width="unset">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Conferma Eliminazione</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="margin-top: 20px; overflow-y: auto">
            Sei sicuro di voler eliminare questa tipologia?
            <v-divider class="my-5"></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-5"
                  @click="clickConfermaEliminaOperatore"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAnnullaEliminaOperatore"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="conferma_chiudi_tipologia_dettaglio_dialog"
        width="unset"
      >
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Conferma Chiusura</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="margin-top: 20px; overflow-y: auto">
            Sei sicuro di voler chiudere questa finestra senza salvare i dati?
            <v-divider class="my-5"></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-5"
                  @click="clickConfermaChiudiDettaglioOperatore"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAnnullaChiudiDettaglioOperatore"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_aggiungi_fascia_oraria" persistent>
        <v-snackbar
          content-class="snackbar"
          :color="snackbar_background"
          right
          v-model="snackbar_dialog_fasce"
          :timeout="3000"
          :style="{}"
        >
          <v-icon :color="snackbar_icon_color" :size="64">{{
            snackbar_icon
          }}</v-icon>
          <h2
            :style="{ color: snackbar_text_color + '!important' }"
            class="ml-16"
          >
            {{ snackbar_text }}
          </h2>

          <v-btn
            @click="snackbar_dialog_fascie = false"
            fab
            dark
            text
            small
            style="
              margin-top: -6rem;
              float: right !important;
              margin-right: -1rem !important;
            "
          >
            <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
          </v-btn>
        </v-snackbar>
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title v-if="!modifica_fascia" class="white--text"
              >Aggiungi Fascia oraria</v-toolbar-title
            >
            <v-toolbar-title v-else class="white--text"
              >Modifica Fascia oraria</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="salvaModificheFasciaOraria()"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span>Salva</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialog_aggiungi_fascia_oraria = false"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="margin-top: 20px; overflow-y: auto">
            <v-container fluid>
              <v-row>
                <v-col cols="2">
                  <v-autocomplete
                    v-model="fascia_oraria_sel.id_tipo_giorno"
                    :items="tipo_giorno_lista"
                    label="Tipo giorno"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                    <template #label>
                      <span class="mr-2">Tipo giorno</span>
                      <span
                        @click.stop
                        style="min-width: 500px; pointer-events: all"
                      >
                        <Modules
                          v-on:dialogModules="gettabelleFasce"
                          tipo="MAN_TIPO_GIORNO"
                          nome="Tipo giorno"
                          setaction="settabella"
                          getaction="gettabella"
                          controller="Global"
                          :controllaCodice="true"
                          :filter="'validita = 1'"
                          :all_label="['des']"
                          :dati="[
                            { des: '' },
                          ]"
                        />
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- <v-col cols="2">
                  <v-autocomplete
                    v-model="fascia_oraria_sel.id_tipo_giorno"
                    :items="tipo_giorno_lista"
                    label="Tipo Giorno"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col> -->
                <v-col cols="2">
                  <v-menu
                    ref="menu_ora_inizio"
                    v-model="menu_ora_inizio"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="fascia_oraria_sel.fascia_oraria_inizio"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fascia_oraria_sel.fascia_oraria_inizio"
                        label="Fascia oraria inizio"
                        prepend-inner-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      format="24hr"
                      v-if="menu_ora_inizio"
                      v-model="fascia_oraria_sel.fascia_oraria_inizio"
                      full-width
                      @click:minute="
                        $refs.menu_ora_inizio.save(
                          fascia_oraria_sel.fascia_oraria_inizio
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-menu
                    ref="menu_ora_fine"
                    v-model="menu_ora_fine"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="fascia_oraria_sel.fascia_oraria_fine"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fascia_oraria_sel.fascia_oraria_fine"
                        label="Fascia oraria fine"
                        prepend-inner-icon="access_time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      format="24hr"
                      v-if="menu_ora_fine"
                      v-model="fascia_oraria_sel.fascia_oraria_fine"
                      full-width
                      @click:minute="
                        $refs.menu_ora_fine.save(
                          fascia_oraria_sel.fascia_oraria_fine
                        )
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                 <v-col cols="2">
                  <v-autocomplete
                    v-model="fascia_oraria_sel.id_tipo_maggiorazione"
                    :items="maggiorazione_lista"
                    label="Tipo maggiorazione"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                    <template #label>
                      <span class="mr-2">Tipo maggiorazione</span>
                      <span
                        @click.stop
                        style="min-width: 500px; pointer-events: all"
                      >
                        <Modules
                          v-on:dialogModules="gettabelleFasce"
                          tipo="MAN_MAGGIORAZIONE"
                          nome="Tipo maggiorazione"
                          setaction="settabella"
                          getaction="gettabella"
                          controller="Global"
                          :controllaCodice="true"
                          :filter="'validita = 1'"
                          :all_label="['des']"
                          :dati="[
                            { des: '' },
                          ]"
                        />
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- <v-col cols="2">
                  <v-autocomplete
                    v-model="fascia_oraria_sel.id_tipo_maggiorazione"
                    :items="maggiorazione_lista"
                    label="Maggiorazioni"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                  >
                  </v-autocomplete>
                </v-col> -->
                <v-col cols="2">
                  <v-autocomplete
                    v-model="fascia_oraria_sel.giorni"
                    :items="giorno_lista"
                    label="Giorno"
                    placeholder="Seleziona"
                    item-text="des"
                    item-value="id_tabella"
                    required
                    multiple
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="fascia_oraria_sel.costo"
                    label="Costo (€)"
                    type="number"
                    :min="0"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="conferma_elimina_fascia_dialog" width="unset">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Conferma Eliminazione</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="margin-top: 20px; overflow-y: auto">
            Sei sicuro di voler eliminare questa fascia oraria?
            <v-divider class="my-5"></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-5"
                  @click="clickConfermaEliminaFascia()"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="conferma_elimina_fascia_dialog = false"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        v-model="snackbar"
        :timeout="3000"
        :style="{}"
      >
        <v-icon :color="snackbar_icon_color" :size="64">{{
          snackbar_icon
        }}</v-icon>
        <h2
          :style="{ color: snackbar_text_color + '!important' }"
          class="ml-16"
        >
          {{ snackbar_text }}
        </h2>

        <v-btn
          @click="snackbar = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
import moment from "moment/moment";
export default {
  name: "AnagraficheDettaglioOperatori",
  components: { Modules: () => import("@/components/TOOLS/Modules") },
  props: {
    anagrafica_sel: { type: Object, default: null },
    tipologie: { type: Array, default: () => [] },
  },
  data() {
    return {
      // Variabili d'appoggio
      tipologia_sel: {
        id_anagrafica_tipologia: -1,
        id_anagrafica: this.anagrafica_sel.id_anagrafica,
        nome_tipologia: null,
        livello: null,
        specializzazioni: [],
        fascie_orarie: [],
        validita: 1,
      },
      fascia_oraria_sel: {
        id_anagrafica_tipologia_fascia_oraria: -1,
        id_anagrafica_tipologia: this.tipologie.id_anagrafica_tipologia,
        id_tipo_giorno: null,
        des_tipo_giorno: null,
        id_tipo_maggiorazione: null,
        des_tipo_maggiorazione: null,
        fascia_oraria_inizio: null,
        fascia_oraria_fine: null,
        giorni: [],
        des_giorni: [],
        validita: 1,
        riga: null,
        riga_fascia_oraria: null,
        costo: null,
      },
      modifica_fascia: false,
      indice_fascia: null,
      tipologia_operatore_lista: [],
      tipo_giorno_lista: [],
      giorno_lista: [],
      maggiorazione_lista: [],
      disabilita_eliminazione: false,
      contatore_render_tipologia_lista: 0,
      snackbar_dialog: false,
      snackbar_dialog_fasce: false,
      // Modal
      aggiungi_tipologia_dialog: false,
      conferma_elimina_tipologia_dialog: false,
      conferma_chiudi_tipologia_dettaglio_dialog: false,
      conferma_elimina_fascia_dialog: false,
      contatore_fascie: 0,
      giorni_backup: [],

      dialog_aggiungi_fascia_oraria: false,
      menu_ora_inizio: false,
      menu_ora_fine: false,
      expanded_tipologie: [],

      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      contatore_tipologie: 0,

      // Array
      headers_tipologie: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Tipologia", sortable: false, value: "nome_tipologia" },
        {
          text: "Livello",
          sortable: false,
          value: "livello",
        },
        {
          text: "Specializzazione",
          sortable: false,
          value: "specializzazioni",
        },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      headers_fascie_orarie: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Tipo giorno", sortable: false, value: "des_tipo_giorno" },
        {
          text: "Fascia Oraria Inizio",
          sortable: false,
          value: "fascia_oraria_inizio",
        },
        {
          text: "Fascia Oraria Fine",
          sortable: false,
          value: "fascia_oraria_fine",
        },
        {
          text: "Tipo Maggiorazione",
          sortable: false,
          value: "des_tipo_maggiorazione",
        },
        { text: "Giorno/i", sortable: false, value: "des_giorni" },
        { text: "Costo", sortable: false, value: "costo" },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      items_tipologie: [],
      tipo_intervento_lista: [],
    };
  },
  computed: {},
  watch: {
    anagrafica_sel: {
      immediate: true,
      handler() {
        if (this.anagrafica_sel.tipologie) {
          this.items_tipologie = this.anagrafica_sel.tipologie;
          this.items_tipologie.forEach((tipologia) => {
            if (tipologia.specializzazioni) {
              tipologia.specializzazioni.forEach((s) => {
                s.des_ambito_manutenzione = s.des_specializzazione;
                s.id_ambito_manutenzione = s.id_specializzazione;
              });
              /* Creo un array di confronto che mi serve
               * per il salvataggio, in modo da sapere quali
               * specializzazioni eventualmente sono state tolte. */
              tipologia.specializzazioni_confronto = tipologia.specializzazioni;
            }
          });
          // this.items_tipologie.forEach((tipologia) => {
          //   if (
          //     this.anagrafica_sel.operatori.filter(
          //       (operatore) =>
          //         tipologia.id_anagrafica_tipologia ==
          //         operatore.id_anagrafica_tipologia && operatore.validita==1
          //     ).length != 0
          //   ) {
          //     console.log("ENTRATO:");
          //     tipologia.disabilita_eliminazione = true;
          //   }
          // });
        }
      },
    },
    tipologie: {
      immediate: true,
      handler() {
        console.log("entrato handler delle tipologie");

        this.tipo_intervento_lista = [];

        // Se la prop "tipologie" ha già degli elementi, li uso
        if (this.tipologie.length > 0) {
          this.tipologie.forEach((elem) => {
            if (elem.validita == true) {
              this.tipo_intervento_lista.push(elem);
            }
          });
          console.log("tipo_intervento_lista:", this.tipo_intervento_lista);
          this.contatore_render_tipologia_lista++;
        } else {
          /* Se non ho ancora elementi all'interno della lista dei tipi intervento per
           * la select, faccio una chiamata gettabelle molto simile a quella di
           * AnagraficheDettaglioTipologia.vue per popolarmi i dati per conto mio,
           * perché vuol dire che il componente fratello non è ancora stato richiamato
           * e non gli ha ancora passato i dati. */
          if (this.tipo_intervento_lista.length === 0) {
            this.gettabelleTipoIntervento();
          }
        }
      },
    },
  },
  methods: {
    // getAnagraficaDettaglio(arg) {
    //   let request = {
    //     controller: "Manutenzione",
    //     method: "POST",
    //     richiesta: {
    //       action: "getanagraficadettaglio",
    //       token: localStorage.user_token,
    //       id_anagrafica: arg,
    //       tipo: "FORNITORE",
    //     },
    //   };

    //   this.$store.dispatch("api", request).then((res) => {
    //     if (res.status === 200) {
    //       // Controllo che il fornitore abbia gli operatori
    //       if (res.data.result.fornitori[0].operatori) {
    //         // Controllo che gli operatori abbiano le specializzazioni
    //         res.data.result.fornitori[0].operatori.forEach((operatore) => {
    //           if (!operatore.specializzazioni) {
    //             operatore.specializzazioni = [];
    //           }
    //         });
    //       }

    //       // Controllo che il fornitore abbia le autorizzazioni
    //       if (!res.data.result.fornitori[0].autorizzazioni) {
    //         res.data.result.fornitori[0].autorizzazioni = [];
    //       }
    //       console.log("RES:", res.data.result);
    //       if(res.data.result.fornitori[0].tipologie)
    //       {
    //         this.items_tipologie = res.data.result.fornitori[0].tipologie;
    //         this.items_tipologie.forEach((tipologia) => {
    //           tipologia.specializzazioni.forEach((s) => {
    //             s.des_ambito_manutenzione = s.des_specializzazione;
    //             s.id_ambito_manutenzione = s.id_specializzazione;
    //           });

    //           /* Creo un array di confronto che mi serve
    //            * per il salvataggio, in modo da sapere quali
    //            * specializzazioni eventualmente sono state tolte. */
    //           tipologia.specializzazioni_confronto = tipologia.specializzazioni;
    //         });
    //         this.anagrafica_sel = Object.assign({}, res.data.result.fornitori[0]);
    //         console.log("ITEMS TIPOLOGIE:", this.items_tipologie);
    //       }
    //     }
    //   });
    // },
    salvaModificheFasciaOraria() {
      if (
        this.fascia_oraria_sel.id_tipo_giorno == null ||
        this.fascia_oraria_sel.id_tipo_giorno == "" ||
        this.fascia_oraria_sel.fascia_oraria_inizio == null ||
        this.fascia_oraria_sel.fascia_oraria_inizio == "" ||
        this.fascia_oraria_sel.fascia_oraria_fine == "" ||
        this.fascia_oraria_sel.fascia_oraria_fine == null ||
        this.fascia_oraria_sel.id_tipo_maggiorazione == null ||
        this.fascia_oraria_sel.id_tipo_maggiorazione == "" ||
        this.fascia_oraria_sel.giorni.length == 0 ||
        this.fascia_oraria_sel.costo == "" ||
        this.fascia_oraria_sel.costo == null
      ) {
        this.snackbar_dialog_fasce = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Compila tutti i campi prima di salvare!";
        this.snackbar_text_color = "white";
        return;
      }
      if (!this.modifica_fascia) {
        this.fascia_oraria_sel.giorni.forEach((giorno) => {
          this.giorno_lista.forEach((giornoTabella) => {
            if (giorno.id_tabella == giornoTabella.id_tabella) {
              giorno.id_giorno = giornoTabella.id_tabella;
              giorno.id_anagrafica_tipologia_fascia_oraria_giorno = -1;
              this.fascia_oraria_sel.des_giorni.push(giorno.des);
            }
          });
          this.tipo_giorno_lista.forEach((tipo_giorno) => {
            if (
              this.fascia_oraria_sel.id_tipo_giorno == tipo_giorno.id_tabella
            ) {
              this.fascia_oraria_sel.des_tipo_giorno = tipo_giorno.des;
            }
          });
          this.maggiorazione_lista.forEach((maggiorazione) => {
            if (
              this.fascia_oraria_sel.id_tipo_maggiorazione ==
              maggiorazione.id_tabella
            ) {
              this.fascia_oraria_sel.des_tipo_maggiorazione = maggiorazione.des;
            }
          });
        });
        console.log("TIPOLOGIA SEL PRE PUSH:", this.tipologia_sel);
        this.fascia_oraria_sel.id_anagrafica_tipologia =
          this.tipologia_sel.id_anagrafica_tipologia < -1
            ? -1
            : this.tipologia_sel.id_anagrafica_tipologia;

        this.tipologia_sel.fascie_orarie.push(this.fascia_oraria_sel);
      } else {
        console.log("entrato nel modifica:");
        console.log("INDICE FASCIA:", this.indice_fascia);

        this.fascia_oraria_sel.des_giorni = [];

        this.fascia_oraria_sel.giorni.forEach((giorno) => {
          this.giorno_lista.forEach((giornoTabella) => {
            if (giorno.id_tabella == giornoTabella.id_tabella) {
              giorno.id_giorno = giornoTabella.id_tabella;
              if (
                this.giorni_backup.filter(
                  (f) => f.id_giorno == giorno.id_giorno
                ).length == 0
              ) {
                giorno.id_anagrafica_tipologia_fascia_oraria_giorno == undefined
                  ? (giorno.id_anagrafica_tipologia_fascia_oraria_giorno = -1)
                  : giorno.id_anagrafica_tipologia_fascia_oraria_giorno;
              }
              this.fascia_oraria_sel.des_giorni.push(giorno.des);
            }
            this.tipo_giorno_lista.forEach((tipo_giorno) => {
              if (
                this.fascia_oraria_sel.id_tipo_giorno == tipo_giorno.id_tabella
              ) {
                this.fascia_oraria_sel.des_tipo_giorno = tipo_giorno.des;
              }
            });
            this.maggiorazione_lista.forEach((maggiorazione) => {
              if (
                this.fascia_oraria_sel.id_tipo_maggiorazione ==
                maggiorazione.id_tabella
              ) {
                this.fascia_oraria_sel.des_tipo_maggiorazione =
                  maggiorazione.des;
              }
            });
          });
        });
        this.giorni_backup.forEach((giorno) => {
          if (
            this.fascia_oraria_sel.giorni.filter(
              (f) => f.id_giorno == giorno.id_giorno
            ).length == 0
          ) {
            let obj_tmp = Object.assign({}, giorno);
            obj_tmp.validita = 0;
            this.fascia_oraria_sel.giorni.push(obj_tmp);
          }
          this.fascia_oraria_sel.giorni.forEach((e) => {
            if (giorno.id_giorno == e.id_giorno) {
              e.id_anagrafica_tipologia_fascia_oraria_giorno =
                giorno.id_anagrafica_tipologia_fascia_oraria_giorno;
            }
          });
        });
        console.log("FASCIA ORARIA SEL:", this.fascia_oraria_sel);
        this.tipologia_sel.fascie_orarie[this.indice_fascia] = Object.assign(
          {},
          this.fascia_oraria_sel
        );
      }
      this.contatore_fascie++;
      this.dialog_aggiungi_fascia_oraria = false;
    },
    clickAggiungiFasciaOraria() {
      this.modifica_fascia = false;
      let nuova_fascia_oraria = {
        id_anagrafica_tipologia_fascia_oraria: -1,
        id_anagrafica_tipologia: this.tipologie.id_anagrafica_tipologia,
        id_tipo_giorno: null,
        des_tipo_giorno: null,
        id_tipo_maggiorazione: null,
        des_tipo_maggiorazione: null,
        fascia_oraria_inizio: null,
        fascia_oraria_fine: null,
        giorni: [],
        des_giorni: [],
        validita: 1,
        riga: null,
        riga_fascia_oraria: null,
        costo: null,
      };
      this.fascia_oraria_sel = Object.assign({}, nuova_fascia_oraria);
      this.dialog_aggiungi_fascia_oraria = true;
    },
    clickModificaFasciaOraria(item) {
      this.modifica_fascia = true;
      this.indice_fascia = this.tipologia_sel.fascie_orarie.indexOf(item);
      this.fascia_oraria_sel = Object.assign({}, item);
      this.fascia_oraria_sel.giorni.forEach((giorno) => {
        let obj_tmp = Object.assign({}, giorno);
        this.giorni_backup.push(obj_tmp);
      });

      this.dialog_aggiungi_fascia_oraria = true;
    },
    clickEliminaFasciaOraria(item) {
      this.indice_fascia = this.tipologia_sel.fascie_orarie.indexOf(item);
      this.conferma_elimina_fascia_dialog = true;
    },
    clickConfermaEliminaFascia() {
      this.tipologia_sel.fascie_orarie[this.indice_fascia].validita = 0;
      this.tipologia_sel.fascie_orarie[this.indice_fascia].giorni.forEach(
        (e) => {
          e.validita = 0;
        }
      );
      this.conferma_elimina_fascia_dialog = false;
    },
    salvaTipologie() {
      console.log("TIPOLGIE:", this.items_tipologie);
      this.items_tipologie.forEach((e) => {
        if (e.id_anagrafica_tipologia < -1) {
          e.id_anagrafica_tipologia = -1;
        }
        e.specializzazioni.forEach((f) => {
          f.id_specializzazione = f.id_ambito_manutenzione;
        });
      });
      this.items_tipologie.forEach((tipologia) => {
        if (tipologia.specializzazioni_confronto != undefined) {
          tipologia.specializzazioni_confronto.forEach((e) => {
            if (
              tipologia.specializzazioni.filter(
                (f) => f.id_specializzazione == e.id_specializzazione
              ).length == 0
            ) {
              e.validita = 0;
              tipologia.specializzazioni.push(e);
            }
          });
        }

        tipologia.specializzazioni.forEach((specializzazione_da_salvare) => {
          if (tipologia.specializzazioni_confronto != undefined) {
            if (
              tipologia.specializzazioni_confronto.filter(
                (specializzazione_confronto) =>
                  specializzazione_confronto.id_specializzazione ==
                  specializzazione_da_salvare.id_specializzazione
              ).length == 0
            ) {
              specializzazione_da_salvare.id_anagrafica_tipologia_dettaglio = -1;
              specializzazione_da_salvare.validita = 1;
            }
          }
        });
      });

      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "settipologieanagrafica",
          token: localStorage.getItem("user_token"),
          tipologie: Array.from(new Set(this.items_tipologie)),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check-outline";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Tipologie salvate con successo!";
          this.snackbar_text_color = "white";
          this.$emit("AggiornaAnagrafica", this.anagrafica_sel);
          //this.getAnagraficaDettaglio(this.anagrafica_sel.id_anagrafica);
        }
      });
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_TIPOLOGIA_OPERATORE",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        console.log("RES.DATA:", res.data);
        this.tipologia_operatore_lista = res.data != undefined ? res.data : [];
      });
    },
    gettabelleFasce() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "'MAN_TIPO_GIORNO','MAN_GIORNO','MAN_MAGGIORAZIONE'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        console.log("RES.DATA:",res.data);
        this.tipo_giorno_lista =
          res.data.MAN_TIPO_GIORNO != undefined ? res.data.MAN_TIPO_GIORNO : [];
        this.maggiorazione_lista =
          res.data.MAN_MAGGIORAZIONE != undefined
            ? res.data.MAN_MAGGIORAZIONE
            : [];
        this.giorno_lista =
          res.data.MAN_GIORNO != undefined ? res.data.MAN_GIORNO : [];
      });
    },
    clickAggiungiTipologia() {
      this.gettabelleTipoIntervento();
      this.gettabelleFasce();
      this.aggiungi_tipologia_dialog = true;

      // Faccio un operatore resettato
      let nuova_tipologia = {
        id_anagrafica_tipologia: -1,
        id_anagrafica: this.anagrafica_sel.id_anagrafica,
        nome_tipologia: null,
        livello: null,
        fascie_orarie: [],
        specializzazioni: [],
        validita: 1,
      };

      // Uso l'operatore resettato per modificare l'tipologia_sel
      this.tipologia_sel = Object.assign({}, nuova_tipologia);
    },
    chiudiDialog() {
      this.conferma_chiudi_tipologia_dettaglio_dialog = true;
    },
    clickAnnullaEliminaOperatore() {
      this.conferma_elimina_tipologia_dialog = false;

      // Faccio un operatore resettato
      let nuova_tipologia = {
        id_anagrafica_tipologia: -1,
        id_anagrafica: this.anagrafica_sel.id_anagrafica,
        nome_tipologia: null,
        livello: null,
        fascie_orarie: [],
        specializzazioni: [],
        validita: 1,
      };
      // Uso l'operatore resettato per modificare l'tipologia_sel
      this.tipologia_sel = Object.assign({}, nuova_tipologia);
    },
    clickAnnullaChiudiDettaglioOperatore() {
      this.conferma_chiudi_tipologia_dettaglio_dialog = false;
    },
    clickConfermaChiudiDettaglioOperatore() {
      // Chiudo il dialog d'avvertimento.
      this.conferma_chiudi_tipologia_dettaglio_dialog = false;

      // Chiudo il dialog del dettaglio operatore.
      this.aggiungi_tipologia_dialog = false;

      // Faccio un operatore resettato
      let nuova_tipologia = {
        id_anagrafica_tipologia: -1,
        id_anagrafica: this.anagrafica_sel.id_anagrafica,
        nome_tipologia: null,
        livello: null,
        fascie_orarie: [],
        specializzazioni: [],
        validita: 1,
      };

      // Uso l'operatore resettato per modificare l'tipologia_sel
      this.tipologia_sel = Object.assign({}, nuova_tipologia);
    },
    clickConfermaEliminaOperatore() {
      this.items_tipologie.forEach((o) => {
        if (
          o.id_anagrafica_tipologia ===
          this.tipologia_sel.id_anagrafica_tipologia
        ) {
          o.validita = 0;
          o.specializzazioni.forEach((e) => {
            e.validita = 0;
          });
        }
      });

      this.conferma_elimina_tipologia_dialog = false;

      // Faccio un operatore resettato
      let nuova_tipologia = {
        id_anagrafica_tipologia: -1,
        id_anagrafica: this.anagrafica_sel.id_anagrafica,
        nome_tipologia: null,
        livello: null,
        fascie_orarie: [],
        specializzazioni: [],
        validita: 1,
      };

      // Uso l'operatore resettato per modificare l'tipologia_sel
      this.tipologia_sel = Object.assign({}, nuova_tipologia);
    },
    clickEliminaOperatore(arg) {
      this.tipologia_sel = Object.assign({}, arg);
      this.conferma_elimina_tipologia_dialog = true;
    },
    clickModificaTipologia(arg) {
      this.aggiungi_tipologia_dialog = true;
      this.gettabelle();
      this.gettabelleFasce();

      this.tipologia_sel = Object.assign({}, arg);
    },
    gettabelleTipoIntervento() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_AMBITO_MANUTENTIVO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        let risposta_tmp = [];
        res.data.forEach((elem) => {
          /* Costruisco un oggetto come mi serve per gestire
           * le tipologie di intervento da FE. */
          let obj = {
            id_anagrafica_tipologia_dettaglio: -1,
            id_anagrafica_tipologia: this.anagrafica_sel.id_anagrafica,
            des_ambito_manutenzione: elem.des,
            id_ambito_manutenzione: elem.id_tabella,
            validita: false,
          };

          /* Controllo se devo assegnare un id_anagrafica_dettaglio
           * diverso da -1 perché magari l'utente aveva già selezionato
           * quell'ambito manutentivo. */
          if (this.anagrafica_sel.ambiti_manutenzione) {
            this.anagrafica_sel.ambiti_manutenzione.forEach((ambito) => {
              if (
                ambito.id_ambito_manutenzione === obj.id_ambito_manutenzione
              ) {
                obj.id_anagrafica_dettaglio = ambito.id_anagrafica_dettaglio;
                obj.validita = true;
              }
            });
          }
          risposta_tmp.push(obj);
        });
        if (this.anagrafica_sel.id_anagrafica == -1) {
          this.tipo_intervento_lista = [];
          this.tipologie.forEach((e) => {
            risposta_tmp.forEach((f) => {
              if (
                e.id_ambito_manutenzione == f.id_ambito_manutenzione &&
                e.validita == true
              ) {
                this.tipo_intervento_lista.push(e);
              }
            });
          });
        } else {
          this.tipo_intervento_lista = [];

          risposta_tmp.forEach((elem) => {
            if (elem.validita === true) {
              this.tipo_intervento_lista.push(elem);
            }
          });
        }
      });
    },
    salvaModificheTipologia() {
      if (
        this.tipologia_sel.nome_tipologia == null ||
        this.tipologia_sel.nome_tipologia == "" ||
        this.tipologia_sel.livello == null ||
        this.tipologia_sel.livello == "0" ||
        this.tipologia_sel.specializzazioni.length == 0
      ) {
        this.snackbar_dialog = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Compilare tutti i campi prima di salvare la tipologia!";
        this.snackbar_text_color = "white";
        return;
      }
      if (this.tipologia_sel.id_anagrafica_tipologia === -1) {
        /* Assegno un id semi-provvisorio in caso di
         * ulteriori modifiche dell'utente prima del salvataggio. */
        this.tipologia_sel.id_anagrafica_tipologia = -moment().valueOf();
        this.items_tipologie.push(this.tipologia_sel);
        let riga_tipologia = this.items_tipologie.indexOf(this.tipologia_sel);
        this.items_tipologie[riga_tipologia].riga = riga_tipologia;
        this.items_tipologie[riga_tipologia].fascie_orarie.forEach(
          (fascia_oraria) => {
            fascia_oraria.riga = riga_tipologia;
            fascia_oraria.riga_fascia_oraria = this.items_tipologie[
              riga_tipologia
            ].fascie_orarie.indexOf(fascia_oraria);
            fascia_oraria.giorni.forEach((e) => {
              e.riga_fascia_oraria = fascia_oraria.riga_fascia_oraria;
            });
          }
        );
        this.items_tipologie[riga_tipologia].specializzazioni.forEach(
          (specializzazione) => {
            specializzazione.riga = riga_tipologia;
            specializzazione.id_specializzazione =
              specializzazione.id_ambito_manutenzione;
            specializzazione.id_anagrafica_tipologia_dettaglio = -1;
          }
        );
      } else {
        this.items_tipologie.forEach((elem) => {
          if (
            elem.id_anagrafica_tipologia ===
            this.tipologia_sel.id_anagrafica_tipologia
          ) {
            this.tipologia_sel.specializzazioni.forEach((specializzazione) => {
              specializzazione.riga = this.items_tipologie.indexOf(elem);
              specializzazione.id_specializzazione =
                specializzazione.id_ambito_manutenzione;
            });
            this.tipologia_sel.fascie_orarie.forEach((fascia) => {
              fascia.riga = this.tipologia_sel.riga;
              fascia.riga_fascia_oraria = this.tipologia_sel.fascie_orarie.indexOf(
                fascia
              );
              fascia.giorni.forEach((giorno) => {
                giorno.riga_fascia_oraria = fascia.riga_fascia_oraria;
              });
            });
            elem.livello = this.tipologia_sel.livello;
            elem.specializzazioni = this.tipologia_sel.specializzazioni;
            elem.fascie_orarie = this.tipologia_sel.fascie_orarie;
            elem.validita = this.tipologia_sel.validita;
            elem.id_tipologia = this.tipologia_sel.id_tipologia;
            elem.nome_tipologia = this.tipologia_sel.nome_tipologia;
          }
        });
      }
      this.contatore_tipologie++;
      this.aggiungi_tipologia_dialog = false;
      console.log("tipologie:", this.items_tipologie);
      // Faccio un operatore resettato
      let nuova_tipologia = {
        id_anagrafica_tipologia: -1,
        id_anagrafica: this.anagrafica_sel.id_anagrafica,
        nome_tipologia: null,
        livello: null,
        fascie_orarie: [],
        specializzazioni: [],
        validita: 1,
      };

      // Uso l'operatore resettato per modificare l'tipologia_sel
      this.tipologia_sel = Object.assign({}, nuova_tipologia);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>