<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"> Montaggio Bobina </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Bottone di chiusura -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" v-bind="attrs" v-on="on">
            <v-icon color="error" large> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-5">
      <span style="font-size: 20px"
        >Inserire manualmente il codice della bobina da montare, oppure usare il
        lettore di QR Code</span
      >
      <v-container>
        <v-row align="center" justify="center">
          <v-col align="center" cols="12" justify="center">
            <div class="mr-5" style="display: inline-block">
              <v-btn @click="clickQRCode" large>
                <v-icon color="indigo" large> mdi-qrcode-scan </v-icon>
              </v-btn>
            </div>
            <div style="display: inline-block">
              <v-text-field
                background-color="#ffffff"
                class="input-class centered-input"
                dense
                height="50px"
                hide-details
                label=""
                style="
                  display: inline-block;
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 25px;
                  padding: 0px;
                "
                v-model="cod_bobina"
              >
              </v-text-field>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-10"
                  @click="confermaMontaggio('manuale')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success" large> mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Dialog di controllo qualità -->
    <v-dialog v-model="qr_reader_visible" persistent>
      <v-toolbar>
        <v-toolbar-title> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="handleCloseQRDialog" v-bind="attrs" v-on="on">
              <v-icon color="error" large> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Chiudi</span>
        </v-tooltip>
      </v-toolbar>
      <qrcode-stream
        class="qr"
        :camera="camera"
        @decode="handleOnDecode"
      ></qrcode-stream>
    </v-dialog>

    <!-- Overlay di caricamento -->
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "MontaggioBobina",
  components: { SnackbarQV },
  props: {
    work_order_sel: { type: Object, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_bobina: "",

      // Modal
      camera: "off",
      caricamento_dati: false,
      qr_reader_visible: false,

      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.resetProperties();
      this.$emit("click_chiudi");
    },
    clickQRCode() {
      this.qr_reader_visible = true;
      this.camera = "auto";
    },
    confermaMontaggio(modalita) {
      console.log(this.cod_bobina);
      // Attivo l'overlay di caricamento
      this.caricamento_dati = true;

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "gestiscibobina",
          token: localStorage.getItem("user_token"),
          id_commessa: this.work_order_sel.id_commessa,
          id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
          id_articolo_wo: this.work_order_sel.id_articolo,
          tipo_operazione: "m",
          id_macchina: this.work_order_sel.id_macchina,
          QRcode:
            modalita === "qr" ? this.cod_bobina : `${this.cod_bobina}`,
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {            
            this.caricamento_dati = false;

            const cod_tmp = this.cod_bobina;
            this.resetProperties();
            this.$emit("montaggio_avvenuto", cod_tmp.split('(P)')[1].split(";")[0]);
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
            );
          }

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}`
          );

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        });
    },
    handleCloseQRDialog() {
      this.qr_reader_visible = false;
      this.camera = "off";
    },
    handleOnDecode(arg) {
      console.log(arg);
      this.cod_bobina = arg;
      this.camera = "off";
      this.qr_reader_visible = false;
      this.confermaMontaggio("qr");
    },
    resetProperties() {
      this.cod_bobina = "";
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>