<template>
  <div style="padding: 20px">
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- Dialog to confirm deletion of single Tempo -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogdeletetempiconfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"> Cancellazione </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di di voler eliminare questo elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="dialogdeletetempiconfirmation = false"
            >ANNULLA</v-btn
          >
          <v-btn color="success" dark @click="delTempi">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to Update Tempi -->
    <v-dialog v-model="update_tempi_dialog" width="800" content-class="hideOverlay">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Modifica Tempi</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="AVANZAMENTO"
            idOggetto="SALVA_TEMPI"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="salvaTempi"
          />
          <v-spacer></v-spacer>
          <v-btn light small @click="closeDialog">
            <v-icon color="error"> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="2">
                  <TextField
                    :readonly="true"
                    idForm="C_TEMPI"
                    idOggetto="TEMPI_NUMERO_COMMESSA"
                    label_input="Numero Commessa"
                    palceholder_input="Numero Commessa"
                    :vmodel="numero_commessa"
                    v-on:changeText="(val) => (this.numero_commessa = val)"
                  />
                </v-col>
                <v-col cols="4">
                  <TextField
                    :readonly="true"
                    idForm="C_TEMPI"
                    idOggetto="TEMPI_COD_ARTICOLO"
                    label_input="Codice Articolo"
                    palceholder_input="Codice Articolo"
                    :vmodel="cod_articolo"
                    v-on:changeText="(val) => (this.cod_articolo = val)"
                  />
                </v-col>
                <v-col cols="3">
                  <TextField
                    :readonly="true"
                    idForm="C_TEMPI"
                    idOggetto="TEMPI_COD_FASE"
                    label_input="Codice Fase"
                    palceholder_input="Codice Fase"
                    :vmodel="cod_fase"
                    v-on:changeText="(val) => (this.cod_fase = val)"
                  />
                </v-col>
                <v-col cols="3" class="mt-4">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate"
                        label="Data"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data"
                      @input="menu3 = false"
                      locale="it"
                      first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3" class="py-1 mt-n1">
                  <DateTimePicker
                    @returnVal="returnVal($event)"
                    title="Start"
                    placeholder="Start Date"
                    label="Start Date"
                    v-model="start"
                    :key="start"
                    :dataVal="start"
                  />
                </v-col>
                <v-col cols="3" class="py-1 mt-n1">
                  <DateTimePicker
                    @returnVal="returnVal($event)"
                    v-model="stop"
                    placeholder="Stop Date"
                    label="Stop Date"
                    title="Stop"
                    :key="stop"
                    :dataVal="stop"
                  />
                </v-col>
                <v-col cols="3" class="py-0">
                  <TextField
                    :readonly="true"
                    idForm="C_TEMPI"
                    idOggetto="TEMPI_ORE"
                    label_input="Ore"
                    palceholder_input="Ore"
                    :vmodel="ore"
                    v-on:changeText="(val) => (this.ore = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    v-model="note"
                    placeholder="Note"
                    outlined
                    label="Note"
                    style="height: 150px"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Main Card to show Tempi Table -->
    <v-card>
      <v-toolbar primary-title class="white--text" color="indigo" dense>
        <v-toolbar-title>Tempi</v-toolbar-title>
        <v-spacer></v-spacer>
        <h3>Analisi Attuale: {{ analisi }}</h3>
        <Button
          classe_bottone="ml-5 mr-5"
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="TEMPI_ANALISI_SINTETICA"
          mdi_icona="mdi-table"
          testo_tooltip="Analisi Sintetica"
          v-on:buttonClick="getAvanzamentoTempi('s')"
        />
        <Button
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="TEMPI_ANALISI_DETTAGLIAATA"
          mdi_icona="mdi-table-search"
          testo_tooltip="Analisi Dettagliata"
          v-on:buttonClick="getAvanzamentoTempi('d')"
        />
        <v-spacer></v-spacer>
        <Button
          classe_bottone="mr-5"
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="TEMPI_FILTRA"
          mdi_icona="mdi-filter"
          testo_tooltip="FILTRA"
          v-on:buttonClick="filtra_menu_visible = !filtra_menu_visible"
        />
        <Button
          colore_icona="primary"
          idForm="AVANZAMENTO"
          idOggetto="TEMPI_RIPRISTINA"
          mdi_icona="mdi-filter-off"
          testo_tooltip="Ripristina"
          v-on:buttonClick="ripristina"
        />
        <v-spacer></v-spacer>
        <v-btn class="red--text" @click="clickChiudi" color="blue-grey lighten-5" small>
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5" v-if="filtra_menu_visible">
        <v-row align="center" justify="center">
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="cod_commessa"
              item-value="cod_commessa"
              label="Cod. Commessa"
              style="display: inline-block; max-width: 350px"
              v-model="numero_commessa_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="numero_commessa_search = ''"
                  color="primary"
                  >{{
                    numero_commessa_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="cod_articolo"
              item-value="cod_articolo"
              label="Cod. Articolo"
              style="display: inline-block; max-width: 350px"
              v-model="cod_articolo_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="cod_articolo_search = ''" color="primary">{{
                  cod_articolo_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="cod_fase"
              item-value="cod_fase"
              label="Cod. Fase"
              style="display: inline-block; max-width: 350px"
              v-model="cod_fase_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="cod_fase_search = ''" color="primary">{{
                  cod_fase_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="nominativo"
              item-value="nominativo"
              label="Operatore"
              style="display: inline-block; max-width: 350px"
              v-model="nome_operatore_search"
            >
              <template v-slot:append>
                <v-icon
                  class="ml-2"
                  @click="nome_operatore_search = ''"
                  color="primary"
                  >{{
                    nome_operatore_search == "" ? "mdi-filter" : "mdi-filter-off"
                  }}</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="_data"
              item-value="_data"
              label="Data"
              style="display: inline-block; max-width: 350px"
              v-model="data_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="data_search = ''" color="primary">{{
                  data_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="_start"
              item-value="_start"
              label="Start"
              style="display: inline-block; max-width: 350px"
              v-model="start_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="start_search = ''" color="primary">{{
                  start_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="_stop"
              item-value="_stop"
              label="Stop"
              style="display: inline-block; max-width: 350px"
              v-model="stop_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="stop_search = ''" color="primary">{{
                  stop_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_tempi_visualizzati"
              item-text="ore"
              item-value="ore"
              label="Ore"
              style="display: inline-block; max-width: 350px"
              v-model="ore_search"
            >
              <template v-slot:append>
                <v-icon class="ml-2" @click="ore_search = ''" color="primary">{{
                  ore_search == "" ? "mdi-filter" : "mdi-filter-off"
                }}</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="header_tempi"
        :items="items_tempi_visualizzati"
        class="elevation-1"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <Button
            colore_icona="primary"
            idForm="AVANZAMENTO"
            idOggetto="MODIFICA_TEMPI"
            mdi_icona="mdi-pencil"
            testo_tooltip="Aggiornare"
            v-on:buttonClick="handleClick(item)"
          />
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="float: left"
                @click="clickNote(item)"
                small
                v-if="item.note != ''"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-clipboard-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>Visualizza Note</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <Button
            colore_icona="error"
            idForm="AVANZAMENTO"
            idOggetto="ELIMINA_TEMPI"
            mdi_icona="mdi-window-close"
            testo_tooltip="Cancellare"
            v-on:buttonClick="selectTempi(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <!-- Dialog delle note -->
    <v-dialog v-model="note_dialog_visible" width="75%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Annotazioni</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="note_dialog_visible = false" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-5" style="text-align: left; overflow-y: auto">
          {{ note_tmp }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loader -->
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import moment from "moment";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField";
import DateTimePicker from "../../TOOLS/DateTimePicker.vue";
export default {
  name: "TabellaTempi",
  components: { DateTimePicker, Button, TextField },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      analisi: "",
      isReset: false,
      sel_tempi: {},
      cod_articolo_search: "",
      cod_commessa_search: "",
      cod_macchina_search: "",
      nome_operatore_search: "",
      numero_commessa: null,
      numero_commessa_search: "",
      cod_articolo: null,
      cod_fase: null,
      cod_fase_search: "",
      data: "",
      data_search: "",
      menu3: false,
      menu4: false,
      menu5: false,
      start: null,
      start_search: "",
      stop_search: "",
      ore_search: "",
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      stop: "",
      ore: null,
      note: "",
      note_tmp: "",
      items_tempi_visualizzati: [],
      // Modal
      dialogdeletetempiconfirmation: false,
      caricamento_dati: false,
      filtra_menu_visible: false,
      note_dialog_visible: false,
      update_tempi_dialog: false,
      // Array
      header_tempi: [],
      header_tempi_sintetico: [
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Data",
          sortable: false,
          value: "_data",
        },
        {
          text: "Start",
          sortable: false,
          value: "_start",
        },
        {
          text: "Stop",
          sortable: false,
          value: "_stop",
        },
        {
          text: "Ore",
          sortable: false,
          value: "ore",
        },
      ],
      header_tempi_dettagliato: [
        { text: "Note", sortable: false, value: "note" },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Data",
          sortable: false,
          value: "_data",
        },
        {
          text: "Nome",
          sortable: false,
          value: "nominativo",
        },
        {
          text: "Des. Macchina",
          sortable: false,
          value: "des_macchina",
        },
        {
          text: "Start",
          sortable: false,
          value: "_start",
        },
        {
          text: "Stop",
          sortable: false,
          value: "_stop",
        },
        {
          text: "Ore",
          sortable: false,
          value: "ore",
        },
      ],
      items_tempi: [],
    };
  },
  methods: {
    clickNote(item) {
      this.note_dialog_visible = true;
      this.note_tmp = item.note;
    },
    returnVal(obj) {
      switch (obj.title) {
        case "Start":
          this.start = moment(obj.val).format("DD/MM/YYYY HH:mm:ss");
          break;
        case "Stop":
          this.stop = moment(obj.val).format("DD/MM/YYYY HH:mm:ss");
          var ms = moment(this.stop, "DD/MM/YYYY HH:mm:ss").diff(
            moment(this.start, "DD/MM/YYYY HH:mm:ss")
          );
          var d = moment.duration(ms, "milliseconds");
          var hours = Math.floor(d.asHours());
          var mins = Math.floor(d.asMinutes()) - hours * 60;
          this.ore = `${hours}.${mins}`;
          break;
        default:
          break;
      }
    },
    // CLose Update Dialog
    closeDialog() {
      this.update_tempi_dialog = false;
    },
    salvaTempi() {
      if (this.stop < this.start) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "La data d'inizio dell'attività deve precedere la data di conclusione.";
        this.snackbar_text_color = "white";
        return;
      }
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "updatecommessatempi",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          db_name: "quickproduction",
          id_movimento: this.sel_tempi.id_movimento,
          id_macchina: this.sel_tempi.id_macchina,
          ora_inizio: moment(this.start, ["DD/MM/YYYY HH:mm:ss"]).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          ora_fine: moment(this.stop, ["DD/MM/YYYY HH:mm:ss"]).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          validita: 1,
          note: this.note,
          id_causale:
            this.sel_tempi.id_causale == undefined ? null : this.sel_tempi.id_causale,
        },
      };
      this.$store.dispatch("api", request).then(() => {
        this.update_tempi_dialog = false;
        this.sel_tempi = null;
        this.getAvanzamentoTempi("d");
      });
    },
    delTempi() {
      let i = this.items_tempi.findIndex((it) => it == this.sel_tempi);
      this.items_tempi.splice(i, 1);
      this.dialogdeletetempiconfirmation = false;
    },
    // In order to select tempo for delete
    selectTempi(item) {
      this.sel_tempi = item;
      this.dialogdeletetempiconfirmation = true;
    },
    // IN order to make Filters work
    filtra() {
      let items_pezzi_tmp = [];
      this.items_tempi.forEach((elem) => {
        items_pezzi_tmp.push(elem);
      });
      // Filtro il riferimento ordine
      if (this.numero_commessa_search != "") {
        // Faccio un array temporaneo
        let res = [];
        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_commessa == this.numero_commessa_search) {
            res.push(elem);
          }
        });
        // Modello la risposta provvisoria su "res".
        items_pezzi_tmp = res;
      }
      // Filtro il cod_articolo
      if (this.cod_articolo_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_articolo == this.cod_articolo_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      //Filtro il codice Commessa
      if (this.cod_fase_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.cod_fase == this.cod_fase_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      //   // Filtro il nome dell'operatore
      if (this.nome_operatore_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.nominativo == this.nome_operatore_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      if (this.start_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem._start == this.start_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la data
      if (this.data_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem._data == this.data_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la Stop
      if (this.stop_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem._stop == this.stop_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      // Filtro la Stop
      if (this.ore_search != "") {
        // Faccio un array temporaneo
        let res = [];
        // Filtro gli elementi visualizzati dopo i passaggi precedenti
        items_pezzi_tmp.forEach((elem) => {
          if (elem.ore == this.ore_search) {
            res.push(elem);
          }
        });
        items_pezzi_tmp = res;
      }
      this.items_tempi_visualizzati = [];
      this.items_tempi_visualizzati = items_pezzi_tmp;
    },
    // Function to Open Edit Modal for tempi
    handleClick(item) {
      this.sel_tempi = item;
      this.numero_commessa = item.numero_commessa;
      this.cod_articolo = item.cod_articolo;
      this.cod_fase = item.cod_fase;
      this.data = moment(item._data, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
      this.note = item.note;
      this.start = moment(item._start, ["DD/MM/YYYY HH:mm:ss"]).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      this.stop = item._stop;
      this.ore = item.ore;
      this.update_tempi_dialog = true;
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    getAvanzamentoTempi(param) {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessaavanzamento",
          token: localStorage.getItem("user_token"),
          tipo: "t",
          analisi: param,
          check: 0,
        },
      };
      this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Definisco il tipo di analisi corrente in base alla chiamata
        this.analisi = param == "s" ? "sintetica" : "dettagliata";
        // Svuoto l'array che contiene i dati dell'avanzamento
        this.items_tempi = [];
        // Popolo l'array che contiene i dati totali
        res.data.forEach((elem) => {
          this.items_tempi.push(elem);
        });
        // Svuoto lo header dei e lo ripopolo a seconda dell'analisi
        if (param == "s") {
          this.header_tempi = [];
          this.header_tempi_sintetico.forEach((elem) => {
            this.header_tempi.push(elem);
          });
        } else if (param == "d") {
          this.header_tempi = [];
          this.header_tempi_dettagliato.forEach((elem) => {
            this.header_tempi.push(elem);
          });
          let data2 = {
            value: "edit",
            sortable: false,
          };
          this.header_tempi.unshift(data2);
          let data1 = {
            value: "delete",
            sortable: false,
          };
          this.header_tempi.push(data1);
        }
        this.caricamento_dati = false;
      });
    },
    ripristina() {
      this.numero_commessa_search = "";
      this.cod_articolo_search = "";
      this.cod_fase_search = "";
      this.data_search = "";
      this.start_search = "";
      this.stop_search = "";
      this.ore_search = "";
      this.items_tempi_visualizzati = [];
      this.items_tempi.forEach((el) => {
        this.items_tempi_visualizzati.push(el);
      });
    },
  },
  computed: {
    formatDate() {
      return this.data ? moment(this.data).format("DD/MM/YYYY") : "";
    },
  },
  watch: {
    stop: {
      immediate: true,
      deep: true,
      handler() {
        if (this.stop == undefined) {
          this.stop = "";
        }
      },
    },
    items_tempi: {
      immediate: true,
      deep: true,
      handler() {
        this.items_tempi_visualizzati = [];
        this.items_tempi.forEach((elem) => {
          // Aggiungo gli items ai pezzi visualizzati
          this.items_tempi_visualizzati.push(elem);
        });
        // Eseguo un filtro, caso mai l'utente ne abbia messo uno.
        this.filtra();
      },
    },
  },
  mounted() {
    this.getAvanzamentoTempi("s");
  },
};
</script>
<style lang="css" >
.hideOverlay{
  overflow-y:hidden !important;
}
</style>
