<template>
  <div class="dashboardtools">
    <v-container>
      <v-row>
        <v-col cols="3" v-for="(item, i) in routes" :key="i">
          <v-card class="mx-auto" width="100%" outlined>
            <v-card-text>
              <p :title="item.text">{{ item.text }}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                outlined
                rounded
                text
                color="success"
                link
                @click.stop="$router.push(item.path)"
                style="width: 100%"
                >Vai a</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "DashboardTools",
  data() {
    return {
      NETWORK: null,
      routes: [],
    };
  },
  mounted() {
    this.NETWORK = this.$store.state.Network;
    const that = this;
    const request = {
      controller: "Modulo",
      body: {
        action: "getTools",
        token: localStorage.user_token,
      },
        method: "POST",
        on: {
          load: function (response) {
            that.routes = response.tools;
          },
        },
      };
      this.NETWORK.AjaxRequest(request);    
  },
};
</script>
<style scoped>
.dashboardtools {
  width: 100%;
  position: relative;
  user-select: none;
}
.dashboardtools p {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  color: black;
}
</style>
