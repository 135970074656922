var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-snackbar',{attrs:{"color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbar_text))]),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12"}},[_c('v-toolbar',{staticClass:"elevation-0",staticStyle:{"border-radius":"5px 5px 0px 0px"},attrs:{"dark":"","color":"primary"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","align":"left"}},[_c('h2',[_vm._v("Totale articoli da prelevare: "+_vm._s(this.NUM_TOT_ARTICOLI)+". ")])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","align":"center"}},[_c('v-text-field',{staticClass:"my-0 py-0",staticStyle:{"width":"180px"},attrs:{"type":"number","hide-details":"","label":"Impostare nuova quantità","min":"0","outlined":"","max":this.NUM_TOT_ARTICOLI},on:{"input":function($event){_vm.gestisci_quantità_prelevate()},"focus":function($event){return $event.target.select()}},model:{value:(_vm.quantita_da_prelevare),callback:function ($$v) {_vm.quantita_da_prelevare=$$v},expression:"quantita_da_prelevare"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","align":"right"}},[_c('h2',[_vm._v("Quantità prelevata "+_vm._s(this.quantita_selezionata))])])],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers_articolo_lotti,"items":_vm.items_articolo_lotti},scopedSlots:_vm._u([{key:"item.selezione",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.num_articoli_da_lotto(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-outline     ")])],1)]}}],null,true)},[_c('span',[_vm._v("Selezione numero pezzi articolo da Lotto")])])]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticStyle:{"margin-left":"50px"},on:{"change":function($event){return _vm._check()}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save_back}},[_vm._v("Salva")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }