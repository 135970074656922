<template>
    <header class="overall" >
        <div class="graph_scrollbar">
            <GraphSVG @click="save" name="refresh" />
            <template v-for="(param,i) in parametri" >
                <!-- SELECT -->
                <span :key="i" @click="viewSelect(i)" :title="getText(param)" v-if="param.assi=='0'||param.assi=='2'">
                    <strong>{{param.descrizione}}:</strong> 
                    <span :title="getText(param)">{{getText(param)}}</span>
                </span>
                <!-- INPUT DATA -->
                <span :key="i" v-if="param.assi=='1'">
                    <strong>{{param.descrizione}}:</strong> 
                    <input type="date" v-model="param.layout" v-if="param.assi=='1'" />
                </span>                
            </template>
        </div>
        <v-dialog>
        </v-dialog>
        <GraphSelect v-model="parametri" />        
    </header>
</template>
<script>
import GraphSelect from "./Inputs/Select.vue";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name : 'GraphOverallUser',
    props : {
        id : [String,Array, Number]
    },
    components : {
        GraphSVG,
        GraphSelect
    },
    data(){
        return {
            headers : [
                {text:null,value:'descrizione'},
                {text:null,value:'select'}
            ],
            parametri : [],
            NETWORK : null, 
            ui : {
                show : false,
            }
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        document.body.addEventListener('graph_overall_user',this.getOverall);
        document.body.addEventListener('graph_overall_export',this.export);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_overall_user',this.getOverall);
        document.body.removeEventListener('graph_overall_export',this.export);
    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        export(event){
            event.detail.overall = this.parametri;
        },
        save(){
           let copy = [];
           let len = this.parametri.length;
           if(len <= 0){return;}
           for(let i=0;i<len;i++){
                const layout = this.parametri[i].layout == null ? '' : this.parametri[i].layout;
                let template;
                if(Number(this.parametri[i].assi) == 1){
                    template = this.parametri[i].layout;
                }else{
                    template = this.parametri[i].template == null ? '' : this.parametri[i].template;
                }
               copy.push({
                   id_ds_dettaglio : this.parametri[i].id_ds_dettaglio,
                   layout : layout,
                   template : template
               });
           }

           let that = this;
            let request =  {
                controller : 'Graph',
                body : {
                    action: "setOverall",
                    token: localStorage.user_token,
                    overall : copy,
                },
                method: 'PUT',
                on : {
                    load : function(){                    
                        let msg = new CustomEvent('dashboardrefresh',{detail:null});
                        document.getElementById('quickchart_dashboard_main').dispatchEvent(msg);
                        that.ui.show = false;                   
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },
        getText(item){
            const data = item.dati;
            const list = item.layout == null ? [] : item.layout.split(',');
            let is_string = false;
            let result = '';
            for(let i = 0; i < data.length; i++){ is_string |= isNaN(data[i].col_0); }
            for(let i=0; i < data.length; i++){
                const val = is_string ? `'${data[i].col_0.replaceAll("'",'’')}'` : data[i].col_0;
                if(list.indexOf(val) >= 0){
                    const datum = data[i];
                    let text = '';
                    let col = datum.col_1;
                    if(col!=null&&col!=undefined){text = col;}
                    col = datum.col_2;
                    if(col!=null&&col!=undefined){
                        if(text==''){
                            text = col;
                        }else{
                            text += ' - ' + col;
                        }
                    }
                    if(result == ''){
                        result += text
                    }else{
                        result += ', ' + text;
                    }
                    
                }
            }  
            return result;          
        },
        getOverall(event = undefined){
            let that = this;

            let request =  {
                controller : 'Graph',
                body : {
                    action: "getOverall",
                    token: localStorage.user_token,
                    id_ds : event == undefined ? this.id : event.detail,
                },
                method: 'POST',
                on : {
                    load : function(response){
                        that.parametri = response.parametri;
                        if(that.parametri.length > 0){
                            document.getElementById('quickchart_dashboard_main').setAttribute('data-overall','');
                        }else{
                            document.getElementById('quickchart_dashboard_main').removeAttribute('data-overall');
                        }                    
                    }
                }
            }
            that.NETWORK.AjaxRequest(request);
        },
        viewSelect(index){
            let event = new CustomEvent('graph_select',{detail:{index:index}});
            document.body.dispatchEvent(event);
        },

    }
}
</script>
<style src="./Overall.css" scoped />