var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',[_vm._v("Movimenti Magazzino")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","elevation":"1"},on:{"click":_vm.toggleFilters}},on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v(" mdi-filter ")])],1)]}}])},[_c('span',[_vm._v("Filtri")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","elevation":"1"},on:{"click":function($event){return _vm.$router.push({ name: 'giacenze-magazzino' })}}},on),[_c('v-icon',{staticClass:"error--text"},[_vm._v(" mdi-keyboard-backspace ")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan","id":"progress"}}):_vm._e(),_c('v-card-text',[(_vm.show_filters)?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu-da",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Da","autocomplete":"off","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.data_start_formatted),callback:function ($$v) {_vm.data_start_formatted=$$v},expression:"data_start_formatted"}},'v-text-field',attrs,false),on))]}}],null,false,3507996208),model:{value:(_vm.menu_da),callback:function ($$v) {_vm.menu_da=$$v},expression:"menu_da"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"it"},on:{"input":function($event){_vm.menu_da = false}},model:{value:(_vm.data_start),callback:function ($$v) {_vm.data_start=$$v},expression:"data_start"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"menu-a",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"A","autocomplete":"off","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.data_end_formatted),callback:function ($$v) {_vm.data_end_formatted=$$v},expression:"data_end_formatted"}},'v-text-field',attrs,false),on))]}}],null,false,3715156603),model:{value:(_vm.menu_a),callback:function ($$v) {_vm.menu_a=$$v},expression:"menu_a"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"it"},on:{"input":function($event){_vm.menu_a = false}},model:{value:(_vm.data_end),callback:function ($$v) {_vm.data_end=$$v},expression:"data_end"}})],1)],1)],1)],1),_c('v-col',[_c('v-select',{attrs:{"label":"User","dense":"","outlined":"","items":_vm.users,"item-text":"des_utente","item-value":"des_utente"},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"#2196f3"},on:{"click":_vm.callList}},[_vm._v(" Search ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lista_tabella},scopedSlots:_vm._u([{key:"item.posizione",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","elevation":"1"},on:{"click":function($event){return _vm.openPosizioneMagazzino(item)}}},on),[_c('v-icon',{attrs:{"color":"#1a80b6"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio posizione")])])]}}])})],1)],1)],1)],1),(_vm.currentItem)?_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.posizione_magazzino_visible),callback:function ($$v) {_vm.posizione_magazzino_visible=$$v},expression:"posizione_magazzino_visible"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Posizione ")]),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""},on:{"click":function($event){_vm.posizione_magazzino_visible = false}}},on),[_c('v-icon',{staticClass:"error--text"},[_vm._v(" mdi-close ")])],1)]}}],null,false,169818608)},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"pa-0 pt-3"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Magazzino partenza")]),_c('v-subheader',{staticClass:"px-0"},[_vm._v(_vm._s(_vm.currentItem.posizionepartenza))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Magazzino arrivo")]),_c('v-subheader',{staticClass:"px-0"},[_vm._v(_vm._s(_vm.currentItem.posizionearrivo))])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }