<template>
  <v-row class="ml-n3 mt-n4">
    <v-hover>
      <template v-slot="{ hover }">
        <v-card style="width: 88.5vw !important" :elevation="hover ? 24 : 2">
          <v-toolbar
            dense
            class="indigo darken-1 headline"
            primary-title
            uppercase
            ><v-toolbar-title class="white--text"
              >Categoria</v-toolbar-title
            ></v-toolbar
          >
          <v-data-table :headers="headers"></v-data-table>
        </v-card>
      </template>
    </v-hover>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "",
          value: "",
          align: "start",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "descrizione",
          sortable: false,
        },
        {
          text: "Categoria",
          value: "categoria",
          sortable: false,
        },
        {
          text: "Annotazioni",
          value: "annotazioni",
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style css scoped></style>
