export default class Article {
    constructor(
        id_articolo, 
        cod_articolo, 
        des_articolo, 
        famiglia_articolo,
        fornitura_articolo,
        tipo_articolo,
        des_aggiuntiva,
        vendita_kit,
        materiale_articolo,
        note,
        um_articolo,
        umfisica_articolo,
        umpeso_articolo,
        l,
        p,
        h,
        peso_netto,
        peso_lordo,
        peso_specifico,
        volume,
        id_tipo, 
        id_famiglia, 
        id_tipo_fornitura,
        id_materiale,
        id_um,
        id_um_fisica,
        id_um_peso,
        id_um_volume,
        id_articolo_contenitore_standard,
        id_articolo_imballo_standard,
        immagine_preview,
        barcode,
        check_inventario,
        costo_imballo,
        costo_standard,
        costo_trasporto,
        genera_num_lotto,
        gestione_lotto,
        id_certificato,
        id_conto_acquisto,
        id_conto_vendita,
        id_fornitore_abituale,
        id_iva,
        id_magazzino_principale,
        id_ubicazione_standard,
        lotto_standard,
        magazzino_articolo,
        particolare_sicurezza,
        prefisso_lotto,
        prelievo_lotti_successivi,
        prezzo_standard,
        qta_contenitore_standard,
        qta_imballo_standard,
        specifiche,
        standard,
        timbro,
        ubicazione_articolo,
        versione,
        validita
    ){
        this.id_articolo = id_articolo;
        this.cod_articolo = cod_articolo;
        this.des_articolo = des_articolo;
        this.famiglia_articolo = famiglia_articolo;
        this.fornitura_articolo = fornitura_articolo;
        this.tipo_articolo = tipo_articolo;
        this.des_aggiuntiva = des_aggiuntiva;
        this.vendita_kit = vendita_kit;

        this.materiale_articolo = materiale_articolo;
        this.note = note;
        this.um_articolo = um_articolo;
        this.umfisica_articolo = umfisica_articolo;
        this.umpeso_articolo = umpeso_articolo;

        this.l = l;
        this.p = p;
        this.h = h;
        this.peso_netto = peso_netto;
        this.peso_lordo = peso_lordo;
        this.peso_specifico = peso_specifico;
        this.volume = volume;
        this.id_tipo = id_tipo;
        this.id_famiglia = id_famiglia;
        this.id_tipo_fornitura = id_tipo_fornitura;
        this.id_materiale = id_materiale;
        this.id_um = id_um;
        this.id_um_fisica = id_um_fisica;
        this.id_um_peso = id_um_peso;
        this.id_um_volume = id_um_volume;
        this.id_articolo_contenitore_standard = id_articolo_contenitore_standard; 
        this.id_articolo_imballo_standard = id_articolo_imballo_standard; 
        this.immagine_preview = immagine_preview;
        this.barcode = barcode;
        
        this.check_inventario = check_inventario;
        this.costo_imballo = costo_imballo;
        this.costo_standard = costo_standard;
        this.costo_trasporto = costo_trasporto;
        this.genera_num_lotto = genera_num_lotto;
        this.gestione_lotto = gestione_lotto;
        this.id_certificato = id_certificato;
        this.id_conto_acquisto = id_conto_acquisto;
        this.id_conto_vendita = id_conto_vendita;
        this.id_fornitore_abituale = id_fornitore_abituale;
        this.id_iva = id_iva;
        this.id_magazzino_principale = id_magazzino_principale;
        this.id_ubicazione_standard = id_ubicazione_standard;
        this.lotto_standard = lotto_standard;
        this.magazzino_articolo = magazzino_articolo;
        this.particolare_sicurezza = particolare_sicurezza;
        this.prefisso_lotto = prefisso_lotto;
        this.prelievo_lotti_successivi = prelievo_lotti_successivi;
        this.prezzo_standard = prezzo_standard;
        this.qta_contenitore_standard = qta_contenitore_standard;
        this.qta_imballo_standard = qta_imballo_standard;
        this.specifiche = specifiche;
        this.standard = standard;
        this.timbro = timbro;
        this.ubicazione_articolo = ubicazione_articolo;
        this.versione = versione; 

        
        this.validita = validita; 
    }
}