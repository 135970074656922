<template>
    <Checklist class="pa-5" style="border-radius:0px;" />
</template>
<script>
import Checklist from "@/components/ELECTRICALMARINE/Checklist.vue";
export default {
  name: "EMChecklist",
  components: {
      Checklist,
  },
  props: {},
  data() {
    return {
        // Variabili d'appoggio
        // Modal
        // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {

  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>