<template>
  <div>
    <v-data-table
      class="elevation-3"
      fixed-header
      :headers="headers_tabella"
      max-height="40vh"
      :items="items_luoghi_visualizzati"
    >
      <template v-slot:top>
        <!-- Toolbar di intestazione della tabella principale -->
        <v-toolbar class="mb-2" color="indigo" dense>
          <!-- Titolo della tabella -->
          <v-toolbar-title class="white--text mr-5">
            {{titolo_tabella_principale}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="modalita_visualizzazione === false ? 'primary' : ''"
                class="mr-5"
                @click="
                  modalita_visualizzazione = false;
                  associazione = false;
                "
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon
                  :color="
                    modalita_visualizzazione === false ? 'white' : 'primary'
                  "
                  >mdi-home-group</v-icon
                >
              </v-btn>
            </template>
            <span>Modalità Sintetica</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="modalita_visualizzazione === true ? 'primary' : ''"
                @click="
                  modalita_visualizzazione = true;
                  associazione = false;
                "
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon
                  :color="
                    modalita_visualizzazione === true ? 'white' : 'primary'
                  "
                  >mdi-home-map-marker</v-icon
                >
              </v-btn>
            </template>
            <span>Modalità Dettagliata</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickAggiungiSede"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi {{ config_sede.label.singolare }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="associazione = !associazione"
                :disabled="modalita_visualizzazione"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="primary">mdi-map-marker-multiple</v-icon>
              </v-btn>
            </template>
            <span>Associa Sedi</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="confermaAssociazione"
                :disabled="modalita_visualizzazione"
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="success">mdi-map-marker-check</v-icon>
              </v-btn>
            </template>
            <span>Conferma Associazione</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <!-- Bottone per tornare indietro -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="goBack" small v-on="on" v-bind="attrs">
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-row align="center" justify="center" v-if="associazione === true">
          <v-col cols="4">
            <v-autocomplete
              @change="getAnagraficaDettaglio('FORNITORE', fornitore_sel)"
              hide-details
              :items="fornitore_lista"
              item-text="ragione_sociale"
              item-value="id_anagrafica"
              placeholder="Fornitore"
              required
              v-model="fornitore_sel"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickModificaSede(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Dettagli</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.seleziona`]="{ item }">
        <v-checkbox
          color="primary"
          v-model="item.selezionato"
          @change="AbbinaStrutture(item)"
        >
        </v-checkbox>
      </template>

      <!-- Colonna della multiselect delle strutture -->
      <template v-slot:[`item.strutture`]="{ item }">
        <v-autocomplete
          hide-details
          :items="
            struttura_lista.filter((elem) => elem.val_int === item.id_sede)
          "
          item-text="des"
          item-value="id_tabella"
          multiple
          placeholder="Strutture"
          required
          style="max-width: 300px"
          v-model="item.strutture"
        >
        </v-autocomplete>
      </template>
      <!-- Colonna della multiselect degli ambiti manutentivi -->
      <template v-slot:[`item.ambiti_manutentivi`]="{ item }">
        <v-autocomplete
          hide-details
          :items="ambiti_manutentivi_fornitore_sel"
          item-text="des_ambito_manutenzione"
          item-value="id_ambito_manutenzione"
          multiple
          placeholder="Ambiti Manutentivi"
          required
          style="max-width: 300px"
          v-model="item.ambiti_manutentivi"
        >
        </v-autocomplete>
      </template>
    </v-data-table>

    <!-- Dialog di modifica della sede -->
    <v-dialog v-model="modifica_sede_visible">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Modifica {{ config_sede.label.singolare }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaModificaSede" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiModificaSede" small v-bind="attrs" v-on="on">
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="4" md="2">
                <v-text-field
                  min="0"
                  background-color="#ffffff"
                  class="pa-1 mr-5"
                  dense
                  hide-details
                  :label="config_sede.label.singolare"
                  style="display: inline-block; width: 100%"
                  v-model="sede_sel.sede"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="2" v-if="config_modello.visibility === true">
                <v-text-field
                  min="0"
                  background-color="#ffffff"
                  class="pa-1 mr-5"
                  dense
                  hide-details
                  :label="config_modello.label.singolare"
                  readonly
                  style="display: inline-block; width: 100%"
                  v-model="sede_sel.modello"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field
                  min="0"
                  background-color="#ffffff"
                  class="pa-1 mr-5"
                  dense
                  hide-details
                  :label="this.config_struttura.label.singolare"
                  style="display: inline-block; width: 100%"
                  v-model="sede_sel.struttura"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="3">
                <v-text-field
                  min="0"
                  background-color="#ffffff"
                  class="pa-1 mr-5"
                  dense
                  hide-details
                  label="Indirizzo"
                  style="display: inline-block; width: 100%"
                  v-model="sede_sel.indirizzo"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="3">
                <v-text-field
                  min="0"
                  background-color="#ffffff"
                  class="pa-1 mr-5"
                  dense
                  hide-details
                  label="Referente"
                  style="display: inline-block; width: 100%"
                  v-model="sede_sel.referente"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-text-field
                  min="0"
                  background-color="#ffffff"
                  class="pa-1 mr-5"
                  dense
                  hide-details
                  label="Numero di Telefono"
                  style="display: inline-block; width: 100%"
                  v-model="sede_sel.telefono"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  class="elevation-3"
                  :expanded.sync="expanded_fornitori"
                  fixed-header
                  :headers="headers_fornitori"
                  height="50vh"
                  item-key="id_anagrafica"
                  :items="
                    items_fornitori.filter(
                      (fornitore) => fornitore.validita == 1
                    )
                  "
                  show-expand
                  :single-expand="true"
                >
                  <!-- Titolo della tabella -->
                  <template v-slot:top>
                    <v-toolbar class="mb-2" color="indigo" dense>
                      <v-toolbar-title class="white--text">
                        Fornitori Autorizzati per questa
                        {{ config_struttura.label.singolare }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="clickAggiungiFornitore"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary"> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <span>Aggiungi Fornitore</span>
                      </v-tooltip>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.elimina_fornitore`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="eliminaFornitore(item)"
                          small
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon color="error">mdi-close</v-icon></v-btn
                        >
                      </template>
                      <span>Elimina fornitore</span>
                    </v-tooltip>
                  </template>

                  <!-- Drill down delle competenze -->
                  <template v-slot:expanded-item="{ headers, item }">
                    <td
                      class="pa-0"
                      style="background-color: white"
                      :colspan="headers.length"
                    >
                      <v-data-table
                        class="elevation-3"
                        :headers="item.items[0].headers"
                        hide-default-footer
                        :items="item.items"
                      >
                        <!-- Titolo tabella -->
                        <template v-slot:top>
                          <v-toolbar class="indigo" dense>
                            <v-toolbar-title class="white--text">
                              Ambiti Manutentivi del Fornitore
                            </v-toolbar-title>
                          </v-toolbar>
                        </template>

                        <!-- Riga delle competenze del fornitore -->
                        <template v-slot:item="{ item }">
                          <tr>
                            <td
                              v-for="(col, columnIndex) in item.headers"
                              :key="columnIndex"
                            >
                              <v-simple-checkbox
                                :color="
                                  item[col.value] === true ? 'success' : ''
                                "
                                v-model="item[col.value]"
                              ></v-simple-checkbox>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di aggiunta di una nuova sede -->
    <v-dialog v-model="aggiungi_sede_dialog" width="100%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Nuovo Punto di Manutenzione
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <!--<v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="dettaglioSedi()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary"> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>Dettaglio Sedi</span>
          </v-tooltip>-->

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaNuovaSede" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiNuovaSede" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-row align="center" class="mx-7" justify="center">
            <v-col
              align="center"
              cols="6"
              md="2"
              justify="center"
              v-if="checkbox_strutture_standard === true"
            >
              <v-checkbox
                color="primary"
                label="Zone di Default"
                v-model="flag_zone_default"
              >
              </v-checkbox>
            </v-col>
            <v-col
              align="center"
              cols="6"
              md="2"
              justify="center"
              v-if="config_sede.visibility === true"
            >
              <v-autocomplete
                @change="
                  sede_lista.forEach((elem) => {
                    if (elem.id_tabella === sede_sel.id_sede) {
                      sede_sel.des_sede = elem.des;
                      sede_sel.modello = elem.val_txt;
                    }
                  });
                  sede_sel.id_struttura = null;
                  sede_sel.id_area = null;
                  sede_sel.id_zona = null;
                  sede_sel.id_macchinario = null;
                  sede_sel.des_struttura = null;
                  sede_sel.des_area = null;
                  sede_sel.des_zona = null;
                  sede_sel.des_macchinario = null;
                  sede_sel.val_dbl = null;
                "
                v-model="sede_sel.id_sede"
                :items="sede_lista.filter((elem) => elem.val_int == id_azienda)"
                :label="config_sede.label.singolare"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">{{ config_sede.label.singolare }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_SEDE"
                      :nome="config_sede.label.singolare"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :controllaCodice="true"
                      :visualizzaCodice="false"
                      :filter="`(val_int =  ${id_azienda}) ${
                        cod_filtro_sede === ''
                          ? ''
                          : 'AND cod = \'' + cod_filtro_sede + '\''
                      } AND validita = 1`"
                      :all_label="['Matricola', 'val_int']"
                      :dati="[
                        { des: '' },
                        { cod: cod_filtro_sede },
                        { val_int: id_azienda },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              align="center"
              cols="6"
              justify="center"
              md="2"
              v-if="config_modello.visibility === true"
            >
              <v-text-field
                autocomplete="off"
                label="Modello"
                readonly
                v-model="sede_sel.modello"
              >
              </v-text-field>
            </v-col>
            <v-col
              align="center"
              cols="6"
              justify="center"
              md="2"
              v-if="
                config_struttura.visibility === true &&
                flag_zone_default === false
              "
            >
              <v-autocomplete
                @change="
                  struttura_lista.forEach((elem) => {
                    if (elem.id_tabella === sede_sel.id_struttura) {
                      sede_sel.des_struttura = elem.des;
                    }
                  });
                  sede_sel.id_area = null;
                  sede_sel.id_zona = null;
                  sede_sel.id_macchinario = null;
                  sede_sel.des_area = null;
                  sede_sel.des_zona = null;
                  sede_sel.des_macchinario = null;
                  sede_sel.val_dbl = null;
                "
                v-model="sede_sel.id_struttura"
                :disabled="
                  struttura_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_sede
                  ).length === 0
                "
                :items="
                  struttura_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_sede
                  )
                "
                :label="config_struttura.label.singolare"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">{{
                    config_struttura.label.singolare
                  }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_STRUTTURA"
                      :nome="config_struttura.label.singolare"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="
                        '(val_int = ' + sede_sel.id_sede + ') AND validita = 1'
                      "
                      :all_label="['des', 'codice', 'val_int']"
                      :dati="[
                        { des: '' },
                        { des_lingua3: '' },
                        { val_int: sede_sel.id_sede },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Autocomplete alternativa, per mostrare le zone barca default -->
            <v-col
              align="center"
              cols="6"
              justify="center"
              md="2"
              v-if="
                config_struttura.visibility === true &&
                flag_zone_default === true
              "
            >
              <v-autocomplete
                chips
                :items="items_zone_default_lista"
                :label="config_struttura.label.plurale + ' default'"
                multiple
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
                return-object
                v-model="items_zone_default_attuali"
              >
                <template #label>
                  <span class="mr-2">{{
                    config_struttura.label.plurale + " Default"
                  }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="updateNavi"
                      tipo="MAN_STRUTTURA_DEFAULT"
                      :nome="config_struttura.label.singolare + ' Default'"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="'validita = 1'"
                      :all_label="['des']"
                      :dati="[{ des: '' }]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              align="center"
              cols="6"
              justify="center"
              md="2"
              v-if="config_area.visibility === true"
            >
              <v-autocomplete
                @change="
                  area_lista.forEach((elem) => {
                    if (elem.id_tabella === sede_sel.id_area) {
                      sede_sel.des_area = elem.des;
                    }
                  });
                  sede_sel.id_macchinario = null;
                  sede_sel.id_zona = null;
                  sede_sel.des_macchinario = null;
                  sede_sel.des_zona = null;
                  sede_sel.des_stanza = null;
                  sede_sel.id_stanza = null;
                  sede_sel.val_dbl = null;
                  associaValoriArea();
                "
                v-model="sede_sel.id_area"
                :disabled="
                  area_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_struttura
                  ).length === 0
                "
                :items="
                  area_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_struttura
                  )
                "
                :label="config_area.label.singolare"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">{{ config_area.label.singolare }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_AREA"
                      :nome="config_area.label.singolare"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="
                        '(val_int = ' +
                        sede_sel.id_struttura +
                        ') AND validita = 1'
                      "
                      :all_label="[
                        'des',
                        'codice',
                        'val_int',
                        'centro di costo',
                      ]"
                      :dati="[
                        { des: '' },
                        { des_lingua3: '' },
                        { val_int: sede_sel.id_struttura },
                        {
                          categoria: [
                            {
                              vmodel: 'val_dbl',
                              tipo: 'MAN_CENTRI_DI_COSTO',
                              nome: 'Centri di costo',
                              setaction: 'settabelle',
                              getaction: 'gettabelle',
                              controller: 'Magazzino',
                              dati: [{ des: '' }, { cod: '' }],
                              filter: 'validita = 1',
                            },
                          ],
                        },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              align="center"
              cols="6"
              justify="center"
              md="2"
              v-if="config_sede.zona === true"
            >
              <v-autocomplete
                @change="
                  zona_lista.forEach((elem) => {
                    if (elem.id_tabella === sede_sel.id_zona) {
                      sede_sel.des_zona = elem.des;
                    }
                  });
                  sede_sel.id_macchinario = null;
                  sede_sel.des_macchinario = null;
                  sede_sel.id_stanza = null;
                  sede_sel.des_stanza = null;
                "
                v-model="sede_sel.id_zona"
                :disabled="
                  zona_lista.filter((elem) => elem.val_int === sede_sel.id_area)
                    .length === 0
                "
                :items="
                  zona_lista.filter((elem) => elem.val_int === sede_sel.id_area)
                "
                :label="config_zona.label.singolare"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">{{ config_zona.label.singolare }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_ZONA"
                      :nome="config_zona.label.singolare"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="
                        '(val_int = ' + sede_sel.id_area + ') AND validita = 1'
                      "
                      :all_label="['des', 'codice', 'val_int']"
                      :dati="[
                        { des: '' },
                        { des_lingua3: '' },
                        { val_int: sede_sel.id_area },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              align="center"
              cols="6"
              justify="center"
              md="2"
              v-if="config_stanza.visibility === true"
            >
              <v-autocomplete
                @change="
                  stanza_lista.forEach((elem) => {
                    if (elem.id_tabella === sede_sel.id_stanza) {
                      sede_sel.des_stanza = elem.des;
                    }
                  });
                  sede_sel.id_macchinario = null;
                  sede_sel.des_macchinario = null;
                "
                v-model="sede_sel.id_stanza"
                :disabled="
                  stanza_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_zona
                  ).length === 0
                "
                :items="
                  stanza_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_zona
                  )
                "
                :label="config_stanza.label.singolare"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">{{ config_stanza.label.singolare }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_STANZA"
                      :nome="config_stanza.label.singolare"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="
                        '(val_int = ' + sede_sel.id_zona + ') AND validita = 1'
                      "
                      :all_label="['des', 'codice', 'val_int']"
                      :dati="[
                        { des: '' },
                        { des_lingua3: '' },
                        { val_int: sede_sel.id_zona },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col
              align="center"
              cols="3"
              justify="center"
              v-if="config_macchinario.visibility === true"
            >
              <v-autocomplete
                @change="
                  macchinario_lista.forEach((elem) => {
                    if (elem.id_tabella === sede_sel.id_macchinario) {
                      sede_sel.des_macchinario = elem.des;
                    }
                  })
                "
                v-model="sede_sel.id_macchinario"
                :disabled="
                  macchinario_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_stanza
                  ).length === 0
                "
                :items="
                  macchinario_lista.filter(
                    (elem) => elem.val_int === sede_sel.id_stanza
                  )
                "
                :label="config_macchinario.label.singolare"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">{{
                    config_macchinario.label.singolare
                  }}</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_ATTREZZATURA"
                      :nome="config_macchinario.label.singolare"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :filter="
                        '(val_int = ' +
                        sede_sel.id_stanza +
                        ') AND validita = 1'
                      "
                      :all_label="['des', 'codice', 'val_int']"
                      :dati="[
                        { des: '' },
                        { des_lingua3: '' },
                        { val_int: sede_sel.id_stanza },
                      ]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col align="center" cols="3" justify="center">
              <v-text-field
                min="0"
                background-color="#ffffff"
                class="pa-1 mr-5"
                dense
                hide-details
                label="Indirizzo"
                style="display: inline-block; width: 100%"
                v-model="sede_sel.des_indirizzo"
              >
              </v-text-field>
            </v-col>
            <v-col align="center" cols="3" justify="center">
              <v-text-field
                min="0"
                background-color="#ffffff"
                class="pa-1 mr-5"
                dense
                hide-details
                label="Referente"
                style="display: inline-block; width: 100%"
                v-model="sede_sel.referente"
              >
              </v-text-field>
            </v-col>
            <v-col align="center" cols="2" justify="center">
              <v-text-field
                min="0"
                background-color="#ffffff"
                class="pa-1 mr-5"
                dense
                hide-details
                label="Numero di Telefono"
                style="display: inline-block; width: 100%"
                v-model="sede_sel.telefono"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di aggiunta nuovo fornitore -->
    <v-dialog v-model="aggiungi_fornitore_dialog">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Aggiungi Fornitore</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogAggiungiFornitore"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <!-- Tabella dei fornitori disponibili -->
          <v-data-table
            class="elevation-3"
            fixed-header
            :headers="headers_fornitori_disponibili"
            height="50vh"
            :items="items_fornitori_disponibili"
          >
            <!-- Titolo della tabella -->

            <template v-slot:top>
              <v-toolbar class="mb-2" color="indigo" dense>
                <v-toolbar-title class="white--text">
                  Fornitori Disponibili
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="AggiungiFornitoriMultipli()"
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon color="success"
                        >mdi-content-save-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Aggiungi Fornitori</span>
                </v-tooltip>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <!-- Bottone di modifica (aggiunta) del fornitore -->
            <template v-slot:[`item.modifica`]="{ item }">
              <v-checkbox v-model="item.selected"></v-checkbox>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_dettaglio_sedi">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Dettaglio Sedi</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="dialog_dettaglio_sedi = false"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="1"> <b>Sedi</b> </v-col>
            <v-col cols="2"> <b>Strutture</b> </v-col>
            <v-col cols="2"> <b>Aree</b> </v-col>
            <v-col cols="2"> <b>Zone</b> </v-col>
            <v-col cols="2"> <b>Stanze</b> </v-col>
            <v-col cols="2"> <b>Macchinari</b> </v-col>
          </v-row>
          <v-row class="mb-5" v-for="(item, index) in sedi_nested" :key="index">
            <v-col cols="1"
              ><v-chip class="mt-2">{{ item.des }}</v-chip></v-col
            >
            <v-col cols="2">
              <v-chip
                class="mt-2"
                v-for="(strutture, indexStrutture) in item.strutture"
                :key="indexStrutture"
                >{{ strutture.des }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                class="mt-2"
                v-for="(aree, indexAree) in item.aree"
                :key="indexAree"
                >{{ aree.des }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                class="mt-2"
                v-for="(zone, indexZone) in item.zone"
                :key="indexZone"
                >{{ zone.des }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                class="mt-2"
                v-for="(stanze, indexStanze) in item.stanze"
                :key="indexStanze"
                >{{ stanze.des }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                class="mt-2"
                v-for="(macchine, indexMacchine) in item.macchinari"
                :key="indexMacchine"
                >{{ macchine.des }}</v-chip
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <SnackbarQV ref="snackbarqv" />
  </div>
</template>
<script>
import Location from "@/classes/Location.js";
import SnackbarQV from "../TOOLS/SnackbarQV.vue";

export default {
  name: "ManutenzioneGestioneSediFornitori",
  components: {
    Modules: () => import("@/components/TOOLS/Modules"),
    SnackbarQV,
  },
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    checkbox_strutture_standard: {
      type: Boolean,
      default: false,
    },
    cod_filtro_sede: {
      type: String,
      defaault: "",
    },
    config_area: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Area", plurale: "Aree" },
          visibility: true,
        };
      },
    },
    config_macchinario: {
      type: Object,
      default: function () {
        return {
          label: {
            singolare: "Macchinario/Attrezzatura",
            plurale: "Marrchinari/Attrezzature",
          },
          visibility: true,
        };
      },
    },
    config_modello: {
      type: Object,
      default: function () {
        return {
          label: {
            singolare: "Modello",
            plurale: "Modelli",
          },
          visibility: false,
        };
      },
    },
    config_sede: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Sede", plurale: "Sedi" },
          visibility: true,
        };
      },
    },
    config_stanza: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Stanza", plurale: "Stanze" },
          visibility: true,
        };
      },
    },
    config_struttura: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Struttura", plurale: "Strutture" },
          visibility: true,
        };
      },
    },
    config_zona: {
      type: Object,
      default: function () {
        return {
          label: { singolare: "Zona", plurale: "Zone" },
          visibility: true,
        };
      },
    },
    get_sedi_from_macchine: {
      type: Boolean,
      default: false,
    },
    rotta_back: {
      type: String,
      default: "/home/manutenzione/main",
    },
    titolo_tabella_principale:{
      type: String,
      default: "Luoghi d'Intervento",
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      flag_zone_default: false,
      fornitore_sel: null,
      id_azienda: localStorage.id_azienda,
      sede_sel: new Location(),

      // Modal
      aggiungi_fornitore_dialog: false,
      aggiungi_sede_dialog: false,
      ambiti_manutentivi_fornitore_sel: [],
      autorizzazioni_fornitore_sel: [],
      associazione: false,
      modalita_visualizzazione: false,
      modifica_sede_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      dialog_dettaglio_sedi: false,

      // Array
      area_lista: [],
      stanza_lista: [],
      expanded_fornitori: [],
      fornitore_lista: [],
      headers_fornitori_disponibili: [
        { text: "Aggiungi", sortable: false, value: "modifica" },
        { text: "Dettagli", sortable: false, value: "dettagli" },
        { text: "Codice", sortable: false, value: "codice" },
        { text: "Ragione Sociale", sortable: false, value: "ragione_sociale" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "CAP", sortable: false, value: "cap" },
        { text: "Località", sortable: false, value: "localita" },
        { text: "Provincia", sortable: false, value: "provincia" },
        { text: "Regione", sortable: false, value: "regione" },
        { text: "P. IVA", sortable: false, value: "p_iva" },
        { text: "Codice Fiscale", sortable: false, value: "codice_fiscale" },
        { text: "Telefono", sortable: false, value: "telefono" },
      ],
      headers_fornitori: [
        { text: "Dettagli", sortable: false, value: "dettagli" },
        { text: "Codice", sortable: false, value: "codice" },
        { text: "Ragione Sociale", sortable: false, value: "ragione_sociale" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "CAP", sortable: false, value: "cap" },
        { text: "Località", sortable: false, value: "localita" },
        { text: "Provincia", sortable: false, value: "provincia" },
        { text: "Regione", sortable: false, value: "regione" },
        { text: "P. IVA", sortable: false, value: "p_iva" },
        { text: "Codice Fiscale", sortable: false, value: "codice_fiscale" },
        { text: "Telefono", sortable: false, value: "telefono" },
        { text: "Elimina", sortable: false, value: "elimina_fornitore" },
      ],
      sedi_nested: [],
      headers_tabella: [],
      items_fornitori: [],
      items_fornitori_disponibili: [],
      items_sedi: [],
      items_sedi_strutture: [],
      items_luoghi_visualizzati: [],
      items_zone_default_attuali: [],
      items_zone_default_lista: [],
      macchinario_lista: [],
      sede_lista: [],
      struttura_lista: [],
      zona_lista: [],
    };
  },
  computed: {
    tipo_headers() {
      /* A seconda dei semafori per la modalità di
       * visualizzazione e per l'associazione sedi,
       * popolo gli headers. */
      if (
        this.modalita_visualizzazione === false &&
        this.associazione === false
      ) {
        // Se sono in modalità sintetica senza associazioni...
        return 0;
      } else if (
        this.modalita_visualizzazione === false &&
        this.associazione === true
      ) {
        // Se sono in modalità sintetica con le associazioni...
        return 1;
      } else {
        // Se sono in modalità dettagliata...
        return 2;
      }
    },
  },
  watch: {
    aggiungi_sede_dialog: {
      immediate: true,
      async handler() {
        if (this.aggiungi_sede_dialog) {
          if (this.get_sedi_from_macchine === true) {
            this.updateNavi();
            this.sede_sel = new Location();
          } else {
            this.gettabelle();
            this.sede_sel = new Location();
          }
        }
      },
    },
    tipo_headers: {
      immediate: true,
      handler() {
        /* 0 = modalità sintetica senza associazioni
         * 1 = modalità sintetica con associazioni
         * 2 = modalità dettagliata */
        if (this.tipo_headers === 0) {
          this.headers_tabella = [
            {
              text: this.config_sede.label.singolare,
              sortable: false,
              value: "sede",
              align: "start",
            },
            {
              text: `N. ${this.config_struttura.label.plurale}`,
              sortable: false,
              value: "numero_strutture",
              align: "start",
            },
          ];

          // Se devo avere la colonna del modello, la inserisco fra le altre.
          if (this.config_modello.visibility === true) {
            this.headers_tabella.splice(1, 0, {
              text: "Modello",
              sortable: false,
              value: "modello",
            });
          }

          // Visualizzo solo le sedi
          this.items_luoghi_visualizzati = this.items_sedi.filter(
            (elem) => elem.numero_strutture > 0
          );
        } else if (this.tipo_headers === 1) {
          this.headers_tabella = [
            {
              text: "Abilita",
              sortable: false,
              value: "seleziona",
              align: "center",
            },
            {
              text: this.config_sede.label.singolare,
              sortable: false,
              value: "sede",
            },
            {
              text: this.config_struttura.label.singolare,
              sortable: false,
              value: "strutture",
              align: "left",
            },
            {
              text: "Ambiti Manutentivi",
              sortable: false,
              value: "ambiti_manutentivi",
              align: "left",
            },
            {
              text: `N. ${this.config_struttura.label.plurale}`,
              sortable: false,
              value: "numero_strutture",
              align: "center",
            },
          ];

          // Se devo avere la colonna del modello, la inserisco fra le altre.
          if (this.config_modello.visibility === true) {
            this.headers_tabella.splice(2, 0, {
              text: "Modello",
              sortable: false,
              value: "modello",
            });
          }

          // Visualizzo solo le sedi
          this.items_luoghi_visualizzati = this.items_sedi.filter(
            (elem) => elem.numero_strutture > 0
          );

          this.gettabelle();
        } else {
          this.headers_tabella = [
            {
              text: "Modifica",
              sortable: false,
              value: "modifica",
              align: "center",
            },
            {
              text: this.config_sede.label.singolare,
              sortable: false,
              value: "sede",
            },
            {
              text: this.config_struttura.label.singolare,
              sortable: false,
              value: "struttura",
            },
            { text: "Indirizzo", sortable: false, value: "indirizzo" },
            { text: "Referente", sortable: false, value: "referente" },
            { text: "Numero di Telefono", sortable: false, value: "telefono" },
          ];

          // Se devo avere la colonna del modello, la inserisco fra le altre.
          if (this.config_modello.visibility === true) {
            this.headers_tabella.splice(2, 0, {
              text: "Modello",
              sortable: false,
              value: "modello",
            });
          }

          /* Resetto le variabili di supporto legate al
           * fornitore e ai suoi ambiti manutentivi. */
          this.fornitore_sel = null;
          this.ambiti_manutentivi_fornitore_sel = [];
          this.autorizzazioni_fornitore_sel = [];

          // Visualizzo le sedi e le strutture
          this.items_luoghi_visualizzati = this.items_sedi_strutture;
        }
      },
    },
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.id_azienda = localStorage.id_azienda;
          this.getAnagraficaDettaglio("CLIENTE", localStorage.id_azienda);
          this.getAnagraficaList();
        }
      },
    },
  },
  methods: {
    associaValoriArea() {
      this.area_lista.forEach((e) => {
        if (this.sede_sel.id_area == e.id_tabella) {
          this.sede_sel.id_centro_costo = e.val_dbl;
        }
      });
    },
    dettaglioSedi() {
      this.dialog_dettaglio_sedi = true;
    },
    AggiungiFornitoriMultipli() {
      this.items_fornitori_disponibili.forEach((fornitore) => {
        if (fornitore.selected == true) {
          this.aggiungiFornitore(fornitore);
        }
      });
    },
    AbbinaStrutture(item) {
      if (item.selezionato == true) {
        this.struttura_lista
          .filter((e) => e.val_int == item.id_sede)
          .forEach((k) => {
            item.strutture.push(k.id_tabella);
          });
      } else {
        item.strutture = [];
      }
    },
    aggiungiFornitore(arg) {
      /* Qui aggiungo un fornitore disponibile
       * alla lista dei fornitori della sede. */

      /* Per comodità di FE genero un array custom che
       * dovrà contenere un oggetto con cui valorizzare
       * la riga di competenze del drill down degli ambiti
       * manutentivi del fornitore. */
      arg.items = [];

      // Preparo l'oggetto che conterrà le competenze
      let oggetto_competenze = {};

      /* Inserisco nell'oggetto i campi corrispondenti
       * alle competenze che mi sono state restituite dal BE. */
      arg.ambiti_manutentivi.forEach((elem) => {
        elem.id_sede = this.sede_sel.id_sede;
        elem.id_struttura = this.sede_sel.id_struttura;
        elem.id_anagrafica_autorizzazione = -1;

        oggetto_competenze[elem.des_ambito_manutentivo] = true;
      });

      /* Per necessità di FE dentro l'oggetto_competenze genero un array
       * custom che definisce le testate all'interno della tabella*/
      oggetto_competenze.headers = [];

      arg.ambiti_manutentivi.forEach((elem) => {
        oggetto_competenze.headers.push({
          text: elem.des_ambito_manutentivo,
          sortable: false,
          value: elem.des_ambito_manutentivo,
          align: "center",
        });
      });

      // Aggiungo l'oggetto delle competenze ad arg.items.
      arg.items.push(oggetto_competenze);

      // Aggiungo il fornitore all'array dei fornitori.
      this.items_fornitori.push(arg);

      // Chiudo il dialog.
      this.aggiungi_fornitore_dialog = false;
    },
    chiudiDialogAggiungiFornitore() {
      this.aggiungi_fornitore_dialog = false;
    },
    chiudiModificaSede() {
      // Chiudo il dialog
      this.modifica_sede_visible = false;

      // Chiudo tutti i dill down
      this.expanded_fornitori = [];

      // Resetto la sede_sel
      this.sede_sel = new Location();
    },
    chiudiNuovaSede() {
      this.getAnagraficaDettaglio("CLIENTE", localStorage.id_azienda);
      
      // Chiudo il dialog e resetto le variabili di supporto che mi interessano
      this.aggiungi_sede_dialog = false;
      this.flag_zone_default = false;
    },
    clickAggiungiFornitore() {
      /* Faccio una chiamata a BE per popolare
       * il dialog di selezione dei fornitori. */
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getfornitoriambitimanutentivi",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Resetto items_fornitori_disponibili
        this.items_fornitori_disponibili = [];

        // Valotizzo items_fornitori_disponibili
        this.items_fornitori_disponibili = res.data.result.fornitori;
        this.items_fornitori_disponibili.forEach((fornitore) => {
          fornitore.selected = false;
        });

        if (this.items_fornitori != undefined) {
          this.items_fornitori.forEach((e) => {
            if (e.validita == 1) {
              this.items_fornitori_disponibili = this.items_fornitori_disponibili.filter(
                (f) => f.id_anagrafica != e.id_anagrafica
              );
            }
          });
        }

        // Apro il dialog
        this.aggiungi_fornitore_dialog = true;
      });
    },
    clickAggiungiSede() {
      this.aggiungi_sede_dialog = true;
    },

    async clickModificaSede(arg) {
      this.sede_sel = Object.assign({}, arg);

      /* Faccio una chiamata al BE per avere
       * fornitori e autorizzazioni di questa sede. */
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getfornitoriautorizzati",
          token: localStorage.user_token,
          id_sede: arg.id_sede,
          id_struttura: arg.id_struttura,
          tipo: "CLIENTE",
        },
      };

      await this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Resetto l'array dei fornitori
          this.items_fornitori = [];
          res.data.result.fornitori.forEach((fornitore) => {
            /* Per comodità di FE genero un array custom che
             * dovrà contenere un oggetto con cui valorizzare
             * la riga di competenze del drill down degli ambiti
             * manutentivi del fornitore. */
            fornitore.items = [];

            // Preparo l'oggetto che conterrà le competenze
            let oggetto_competenze = {};

            /* Inserisco nell'oggetto i campi corrispondenti
             * alle competenze che mi sono state restituite dal BE
             * e assegno agli ambiti manutentivi lo stesso campo
             * id_anagrafica_autorizzazione che trovo nelle autorizzazioni. */
            fornitore.ambiti_manutentivi.forEach((ambito) => {
              ambito.id_sede = this.sede_sel.id_sede;
              ambito.id_struttura = this.sede_sel.id_struttura;

              // Faccio un semaforo per sapere se l'ambito è autorizzato
              let autorizzato = false;

              if (fornitore.autorizzazioni) {
                fornitore.autorizzazioni.forEach((autorizzazione) => {
                  if (
                    ambito.id_ambito_manutenzione ===
                    autorizzazione.id_ambito_manutentivo
                  ) {
                    /* Se trovo corrispondenza fra ambito & autorizzazione
                     * assegno all'ambito lo stesso id_anagrafica_autorizzazione
                     * dell'autorizzazione in esame e accendo il semaforo. */
                    ambito.id_anagrafica_autorizzazione =
                      autorizzazione.id_anagrafica_autorizzazione;

                    autorizzato = true;
                  }
                });
              }

              // Se il semaforo è verse, flaggo il campo per il drill down
              if (autorizzato === true) {
                oggetto_competenze[ambito.des_ambito_manutentivo] = true;
              } else {
                /* Se il semaforo dell'autorizzazione è rosso, deflaggo il campo
                 * del drill down e dichiaro id_anagrafica_autorizzazione a -1.*/
                oggetto_competenze[ambito.des_ambito_manutentivo] = false;
                ambito.id_anagrafica_autorizzazione = -1;
              }
            });

            /* Per necessità di FE dentro l'oggetto_competenze genero un array
             * custom che definisce le testate all'interno della tabella*/
            oggetto_competenze.headers = [];

            fornitore.ambiti_manutentivi.forEach((ambito) => {
              oggetto_competenze.headers.push({
                text: ambito.des_ambito_manutentivo,
                sortable: false,
                value: ambito.des_ambito_manutentivo,
                align: "center",
              });
            });

            // Aggiungo l'oggetto delle competenze ad arg.items.
            fornitore.items.push(oggetto_competenze);

            // Aggiungo il fornitore all'array dei fornitori.
            this.items_fornitori.push(fornitore);
          });
        }
      });

      this.modifica_sede_visible = true;
    },
    confermaAssociazione() {
      // Controllo che ci sia un fornitore selezionato
      if (this.fornitore_sel === null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Selezionare un fornitore per eseguire l'associazione.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che almeno una sede sia stata flaggata.
      if (
        this.items_sedi.filter((elem) => elem.selezionato === true).length === 0
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Selezionare almeno una Sede per eseguire l'associazione.";
        this.snackbar_text_color = "white";
        return;
      }

      /* Controllo che nelle sedi selezionate siano indicati degli ambiti:
       * se ci sono una o più sedi selezionate ma senza ambiti manutentivi... */
      if (
        this.items_sedi.filter(
          (elem) =>
            elem.selezionato === true && elem.ambiti_manutentivi.length === 0
        ).length > 0
      ) {
        // ... Stampo un messaggio di errore e blocco il salvataggio.
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "È necessario associare a ogni sede selezionata almeno un ambito manutentivo.";
        this.snackbar_text_color = "white";
        return;
      }

      // Preparo un array temporaneo da inserire nella request
      let dettagli_tmp = [];

      // Per tutte le sedi contenenti strutture, e che siano flaggate...
      this.items_sedi
        .filter((elem) => elem.numero_strutture > 0 && elem.selezionato)
        .forEach((sede) => {
          this.autorizzazioni_fornitore_sel.forEach((autorizzazione) => {
            sede.ambiti_manutentivi.forEach((ambito_flaggato) => {
              /* Cerco in autorizzazioni_fornitore_sel le autorizzazioni
               * che hanno stessa sede della mia sede in esame e le copio
               * nel mio array dei dettagli per il BE con validita a 0. */
              if (
                autorizzazione.id_sede === sede.id_sede &&
                autorizzazione.id_ambito_manutentivo === ambito_flaggato
              ) {
                let nuova_autorizzazione = new Object();
                nuova_autorizzazione = Object.assign({}, autorizzazione);
                nuova_autorizzazione.validita = 0;
                dettagli_tmp.push(nuova_autorizzazione);
              }
            });
          });

          /* Dentro l'array ambiti_manutentivi_fornitore_sel, per ogni struttura
           * dell'array items_sedi_strutture che abbia la stessa sede, e la cui struttura
           * ricorra fra le strutture selezionate nella sede selezionata dall'utente, la
           * aggungo ai dettagli_tmp apportando le modifiche necessarie.*/
          this.ambiti_manutentivi_fornitore_sel.forEach((ambito) => {
            sede.ambiti_manutentivi.forEach((ambito_flaggato) => {
              if (ambito.id_ambito_manutenzione === ambito_flaggato) {
                this.items_sedi_strutture.forEach((sedestruttura) => {
                  if (
                    sedestruttura.id_sede === sede.id_sede &&
                    sede.strutture.indexOf(sedestruttura.id_struttura) != -1
                  ) {
                    let nuova_autorizzazione = new Object();
                    nuova_autorizzazione = Object.assign({}, ambito);
                    nuova_autorizzazione.id_anagrafica_autorizzazione = -1;
                    delete nuova_autorizzazione.id_anagrafica_dettaglio;
                    nuova_autorizzazione.id_sede = sede.id_sede;
                    nuova_autorizzazione.id_struttura =
                      sedestruttura.id_struttura;
                    nuova_autorizzazione.id_ambito_manutentivo =
                      ambito.id_ambito_manutenzione;
                    delete nuova_autorizzazione.id_ambito_manutenzione;
                    dettagli_tmp.push(nuova_autorizzazione);
                  }
                });
              }
            });
          });
        });

      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setanagraficaautorizzazionimultiple",
          token: localStorage.user_token,
          dettagli: dettagli_tmp,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Associazione sedi-fornitore eseguita con successo.";
          this.snackbar_text_color = "white";
          this.getAnagraficaDettaglio("CLIENTE", localStorage.id_azienda);
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Criticità tecnica: non è stato possibile salvare l'associazione sede-fornitore.";
          this.snackbar_text_color = "white";
        }
      });
    },
    eliminaFornitore(item) {
      item.validita = 0;
      for (const property in item.items[0]) {
        if (property != "headers") {
          item.items[0][property] = false;
        }
      }
    },
    getAnagraficaDettaglio(tipo, id) {
      /* Chiamata per ottenere l'array di sedi
       * del cliente con i relativi fornitori,
       * oppure il dettaglio di un fornitore in
       * termini di autorizzazioni e ambiti di
       * manutenzione disponibili. */
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getanagraficadettaglio",
          token: localStorage.user_token,
          id_anagrafica: id,
          tipo: tipo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          if (tipo === "CLIENTE") {
            /* Questo è il metodo che uso per caricare i dati
             * all'inizio di una sessione e per ricaricare i dati
             * dopo un salvataggio o comunque dopo un'azione che
             * richiede un aggiornamento della tabella. */

            /* Resetto la modalità di visualizzazione (nel caso
             * l'utente l'avesse modificata nel corso della sessione). */
            this.modalita_visualizzazione = true;
            this.associazione = false;

            // Resetto i valori della tabella e gli array di supporto.
            this.ambiti_manutentivi_fornitore_sel = [];
            this.autorizzazioni_fornitore_sel = [];
            this.items_sedi = [];
            this.items_sedi_strutture = [];
            this.items_luoghi_visualizzati = [];

            // Per ogni sede...
            res.data.result.sedi.forEach((sede) => {
              // Creo una nuova sede...
              let nuova_sede = {
                id_sede: sede.id_sede,
                sede: sede.des_sede,
                numero_strutture: 0,
                ambiti_manutentivi: [],
                selezionato: false,
                strutture: [],
              };

              // ... Ciclo sulle strutture...
              res.data.result.strutture.forEach((struttura) => {
                /* ... E se trovo una struttura di quella
                 * sede, uso sede e struttura per preparare
                 * un oggetto e lo aggiungo in tabella. */
                if (
                  sede.id_sede === struttura.id_sede &&
                  sede.validita === 1 &&
                  struttura.validita === 1
                ) {
                  let nuova_sede_struttura = {
                    id_sede: sede.id_sede,
                    id_struttura: struttura.id_struttura,
                    indirizzo: struttura.indirizzo,
                    sede: sede.des_sede,
                    struttura: struttura.des_struttura,
                    referente: struttura.referente,
                    telefono: struttura.telefono,
                    validita: struttura.validita,
                  };

                  /* Aggiungo la sede/struttura all'elenco delle sedi/strutture
                   * e aumento di uno il numero delle strutture dell'oggetto sede. */
                  if (nuova_sede_struttura.validita == 1) {
                    this.items_sedi_strutture.push(nuova_sede_struttura);
                    nuova_sede.numero_strutture += 1;
                  }
                }
              });
              // Aggiungo la sede alla lista delle sedi.
              this.items_sedi.push(nuova_sede);
            });

            // Visualizzo di default le strutture
            this.items_luoghi_visualizzati = this.items_sedi_strutture;
          } else {
            /* In caso stia chiedendo i dettagli degli ambiti
             * manutentivi e autorizzazioni di un fornitore,
             * valorizzo gli array debiti. */
            this.ambiti_manutentivi_fornitore_sel =
              res.data.result.fornitori[0].ambiti_manutenzione;
            this.autorizzazioni_fornitore_sel = res.data.result.autorizzazioni;
          }
        }
      });
    },
    getAnagraficaList() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getanagraficalist",
          token: localStorage.getItem("user_token"),
          tipo: "FORNITORE",
        },
      };

      //this.caricamento_dati = true;
      this.$store.dispatch("api", request).then((res) => {
        // Azzero le anagrafiche
        this.fornitore_lista = [];

        // Popolo le anagrafiche in base alla response
        this.fornitore_lista = res.data.result;
      });
    },
    async updateNavi() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "updatenavi",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          /* Se la chiamata BE è andata a buon fine
           * posso eseguire la chiamata gettabelle. */
          this.gettabelleParziale();
        } else {
          // Se ricevo un errore avviso l'Utente con una snackbar.
          this.$refs.snackbarqv.print(
            "error",
            `Si è verificata una criticità tecnica con codice ${res.status}.`
          );
        }
      });
    },
    async gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_ATTREZZATURA','MAN_ZONA','MAN_AREA','MAN_STRUTTURA','MAN_SEDE','MAN_STANZA'",
          filter: "validita = 1",
        },
      };

      await this.$store.dispatch("api", request).then((res) => {
        this.macchinario_lista =
          res.data.MAN_ATTREZZATURA != undefined
            ? res.data.MAN_ATTREZZATURA
            : [];
        this.zona_lista =
          res.data.MAN_ZONA != undefined ? res.data.MAN_ZONA : [];
        this.area_lista =
          res.data.MAN_AREA != undefined ? res.data.MAN_AREA : [];
        this.struttura_lista =
          res.data.MAN_STRUTTURA != undefined ? res.data.MAN_STRUTTURA : [];

        /* Se devo filtrare le sedi per cod, le filtro, altrimenti
         * popolo l'array delle sedi con tutto quello che mi manda il BE. */
        if (this.cod_filtro_sede != "") {
          this.sede_lista =
            res.data.MAN_SEDE != undefined
              ? res.data.MAN_SEDE.filter(
                  (elem) => elem.cod === this.cod_filtro_sede
                )
              : [];
          console.log("entrato cod_filtro_sede_1");
        } else {
          this.sede_lista =
            res.data.MAN_SEDE != undefined ? res.data.MAN_SEDE : [];
          console.log("entrato cod_filtro_sede_2");
        }

        this.stanza_lista =
          res.data.MAN_STANZA != undefined ? res.data.MAN_STANZA : [];

        this.sedi_nested = [];
        this.sede_lista.forEach((e) => {
          let obj = Object.assign({}, e);
          this.sedi_nested.push(obj);
        });
        this.sedi_nested.forEach((e) => {
          e.strutture = [];
          e.aree = [];
          e.zone = [];
          e.stanze = [];
          e.macchinari = [];
        });
        this.sedi_nested.forEach((sede) => {
          this.struttura_lista.forEach((struttura) => {
            let struttura_tmp = Object.assign({}, struttura);
            if (sede.id_tabella == struttura.val_int) {
              sede.strutture.push(struttura_tmp);
            }
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.strutture.forEach((struttura) => {
            this.area_lista.forEach((area) => {
              let area_tmp = Object.assign({}, area);
              if (area.val_int == struttura.id_tabella) {
                sede.aree.push(area_tmp);
              }
            });
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.aree.forEach((area) => {
            this.zona_lista.forEach((zona) => {
              let zona_tmp = Object.assign({}, zona);
              if (zona.val_int == area.id_tabella && sede.zone != undefined) {
                sede.zone.push(zona_tmp);
              }
            });
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.zone.forEach((zona) => {
            this.stanza_lista.forEach((stanza) => {
              let stanza_tmp = Object.assign({}, stanza);
              if (stanza.val_int == zona.id_tabella) {
                sede.stanze.push(stanza_tmp);
              }
            });
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.stanze.forEach((stanza) => {
            this.macchinario_lista.forEach((macchinario) => {
              let macchina_tmp = Object.assign({}, macchinario);
              if (macchinario.val_int == stanza.id_tabella) {
                sede.macchinari.push(macchina_tmp);
              }
            });
          });
        });
      });
    },
    async gettabelleParziale() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_ATTREZZATURA','MAN_ZONA','MAN_AREA','MAN_STRUTTURA','MAN_STANZA','MAN_STRUTTURA_DEFAULT'",
          filter: "validita = 1",
        },
      };

      return this.$store.dispatch("api", request).then((res) => {
        this.macchinario_lista =
          res.data.MAN_ATTREZZATURA != undefined
            ? res.data.MAN_ATTREZZATURA
            : [];
        this.zona_lista =
          res.data.MAN_ZONA != undefined ? res.data.MAN_ZONA : [];
        this.area_lista =
          res.data.MAN_AREA != undefined ? res.data.MAN_AREA : [];
        this.struttura_lista =
          res.data.MAN_STRUTTURA != undefined ? res.data.MAN_STRUTTURA : [];
        this.stanza_lista =
          res.data.MAN_STANZA != undefined ? res.data.MAN_STANZA : [];
        
        this.items_zone_default_lista =
          res.data.MAN_STRUTTURA_DEFAULT != undefined ? res.data.MAN_STRUTTURA_DEFAULT : [];
        this.items_zone_default_attuali = Array.from(this.items_zone_default_lista);

        this.gettabelleSingola();
      });
    },
    async gettabelleSingola() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_SEDE",
          filter: " cod = 'NAVE' AND validita = 1 ",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.sede_lista = res.data != undefined ? res.data : [];

        this.sedi_nested = [];
        this.sede_lista.forEach((e) => {
          let obj = Object.assign({}, e);
          this.sedi_nested.push(obj);
        });
        this.sedi_nested.forEach((e) => {
          e.strutture = [];
          e.aree = [];
          e.zone = [];
          e.stanze = [];
          e.macchinari = [];
        });
        this.sedi_nested.forEach((sede) => {
          this.struttura_lista.forEach((struttura) => {
            let struttura_tmp = Object.assign({}, struttura);
            if (sede.id_tabella == struttura.val_int) {
              sede.strutture.push(struttura_tmp);
            }
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.strutture.forEach((struttura) => {
            this.area_lista.forEach((area) => {
              let area_tmp = Object.assign({}, area);
              if (area.val_int == struttura.id_tabella) {
                sede.aree.push(area_tmp);
              }
            });
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.aree.forEach((area) => {
            this.zona_lista.forEach((zona) => {
              let zona_tmp = Object.assign({}, zona);
              if (zona.val_int == area.id_tabella && sede.zone != undefined) {
                sede.zone.push(zona_tmp);
              }
            });
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.zone.forEach((zona) => {
            this.stanza_lista.forEach((stanza) => {
              let stanza_tmp = Object.assign({}, stanza);
              if (stanza.val_int == zona.id_tabella) {
                sede.stanze.push(stanza_tmp);
              }
            });
          });
        });
        this.sedi_nested.forEach((sede) => {
          sede.stanze.forEach((stanza) => {
            this.macchinario_lista.forEach((macchinario) => {
              let macchina_tmp = Object.assign({}, macchinario);
              if (macchinario.val_int == stanza.id_tabella) {
                sede.macchinari.push(macchina_tmp);
              }
            });
          });
        });
      });
    },
    goBack() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        this.$emit("click_indietro");
      }
    },
    salvaModificaSede() {
      // Salvo le eventuali modifiche alla sede
      let request_settabella_sede = {
        controller: "Global",
        method: "PUT",
        richiesta: {
          action: "settabella",
          token: localStorage.user_token,
          tabella: [
            {
              tipo: "MAN_SEDE",
              id_tabella: this.sede_sel.id_sede,
              des: this.sede_sel.sede,
            },
          ],
        },
      };

      this.$store.dispatch("api", request_settabella_sede).then((res) => {
        if (res.status != 200) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Criticità tecnica, non è stato possibile salvare i dati.";
          this.snackbar_text_color = "white";
          return;
        }
      });

      // Salvo le eventuali modifiche alla struttura
      let request_settabella_struttura = {
        controller: "Global",
        method: "PUT",
        richiesta: {
          action: "settabella",
          token: localStorage.user_token,
          tabella: [
            {
              tipo: "MAN_STRUTTURA",
              des: this.sede_sel.struttura,
              id_tabella: this.sede_sel.id_struttura,
              val_txt: this.sede_sel.indirizzo,
              des_lingua1: this.sede_sel.referente,
              des_lingua2: this.sede_sel.telefono,
            },
          ],
        },
      };

      this.$store.dispatch("api", request_settabella_struttura).then((res) => {
        if (res.status != 200) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Criticità tecnica, non è stato possibile salvare i dati.";
          this.snackbar_text_color = "white";
          return;
        }
      });

      // Preparo un array temporaneo che andrò a salvare
      let array_per_salvataggio = [];

      // Per ogni fornitore...
      this.items_fornitori.forEach((fornitore) => {
        /* Valorizzo il campo validita di ciascuno dei suoi ambiti
         * manutentivi in base al valore assegnato dall'utente alla
         * relativa checkbox. Questi valori sono contenuti nei
         * campi dell'unico oggetto contenuto di volta in volta
         * nell'array "items" del fornitore in esame. */
        fornitore.ambiti_manutentivi.forEach((ambito) => {
          ambito.validita =
            fornitore.items[0][ambito.des_ambito_manutentivo] === true ? 1 : 0;

          /* Per comodità di BE cambio il nome del campo id_ambito_manutenzione
           * in id_ambito_manutentivo. */
          ambito.id_ambito_manutentivo = ambito.id_ambito_manutenzione;
          delete ambito.id_ambito_manutenzione;

          /* Passo all'array gli ambiti che non sono
           * contemporaneamente nuovi e flaggati a 0. */
          if (
            ambito.id_anagrafica_autorizzazione != -1 ||
            ambito.validita != 0
          ) {
            array_per_salvataggio.push(ambito);
          }
        });
      });

      // Ora ho i dati che mi servono ed eseguo la chiamata al BE.
      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setanagraficaautorizzazioni",
          token: localStorage.user_token,
          autorizzazioni: array_per_salvataggio,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Chiudo il dialog
          this.modifica_sede_visible = false;

          // Aggiorno la tabella generale
          this.getAnagraficaDettaglio("CLIENTE", localStorage.id_azienda);
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Si è verificato un problema tecnico, non è stato possibile salvare.";
          this.snackbar_text_color = "white";
        }
      });
    },
    salvaNuovaSede() {
      // Preparo un array per il salvataggio
      let array_salvataggio = [];

      if (this.flag_zone_default === false) {
        if (
          this.sede_sel.telefono == null ||
          this.sede_sel.telefono == "" ||
          this.sede_sel.referente == null ||
          this.sede_sel.referente == "" ||
          this.sede_sel.des_indirizzo == null ||
          this.sede_sel.des_indirizzo == ""
        ) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Compilare tutti i campi prima di salvare!";
          this.snackbar_text_color = "white";
          return;
        }

        if (this.sede_sel.id_sede != null) {
          // Preparo degli oggetti da salvare
          let sede_salvataggio = {
            des: this.sede_sel.des_sede,
            id_tabella: this.sede_sel.id_sede,
            val_int: this.id_azienda,
            val_txt: this.sede_sel.modello,
          };
          array_salvataggio.push(sede_salvataggio);
        }

        if (this.sede_sel.id_struttura != null) {
          let struttura_salvataggio = {
            des: this.sede_sel.des_struttura,
            id_tabella: this.sede_sel.id_struttura,
            des_lingua1: this.sede_sel.referente,
            des_lingua2: this.sede_sel.telefono,
            val_int: this.sede_sel.id_sede,
            val_txt: this.sede_sel.des_indirizzo,
          };
          array_salvataggio.push(struttura_salvataggio);
        }

        if (this.sede_sel.id_area != null) {
          let area_salvataggio = {
            des: this.sede_sel.des_area,
            id_tabella: this.sede_sel.id_area,
            val_int: this.sede_sel.id_struttura,
            val_dbl: this.sede_sel.id_centro_costo,
          };
          array_salvataggio.push(area_salvataggio);
        }
        if (this.sede_sel.id_zona != null) {
          let zona_salvataggio = {
            des: this.sede_sel.des_zona,
            id_tabella: this.sede_sel.id_zona,
            val_int: this.sede_sel.id_area,
          };
          array_salvataggio.push(zona_salvataggio);
        }
        if (this.sede_sel.id_stanza != null) {
          let stanza_salvataggio = {
            des: this.sede_sel.des_stanza,
            id_tabella: this.sede_sel.id_stanza,
            val_int: this.sede_sel.id_zona,
          };
          array_salvataggio.push(stanza_salvataggio);
        }

        if (this.sede_sel.id_macchinario != null) {
          let macchinario_salvataggio = {
            des: this.sede_sel.des_macchinario,
            id_tabella: this.sede_sel.id_macchinario,
            val_int: this.sede_sel.id_stanza,
          };
          array_salvataggio.push(macchinario_salvataggio);
        }
      } else {
        if (this.sede_sel.id_sede != null) {
          // Preparo degli oggetti da salvare
          let sede_salvataggio = {
            des: this.sede_sel.des_sede,
            id_tabella: this.sede_sel.id_sede,
            val_int: this.id_azienda,
            val_txt: this.sede_sel.modello,
          };
          array_salvataggio.push(sede_salvataggio);

          this.items_zone_default_attuali.forEach((z) => {
            let struttura_salvataggio = {
              des: z.des,
              id_tabella: -1,
              tipo: "MAN_STRUTTURA",
              val_int: this.sede_sel.id_sede,
              val_txt: this.sede_sel.des_indirizzo,
            };
            array_salvataggio.push(struttura_salvataggio);
          });
        }
      }

      let request = {
        controller: "Global",
        method: "PUT",
        richiesta: {
          action: "settabella",
          token: localStorage.user_token,
          tabella: array_salvataggio,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.aggiungi_sede_dialog = false;
          this.getAnagraficaDettaglio("CLIENTE", localStorage.id_azienda);
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Criticità tecnica: non è stato possibile registrare i dati.";
          this.snackbar_text_color = "white";
        }
      });
    },
  },
  created() {},
  mounted() {
    //this.getAnagraficaDettaglio("CLIENTE", localStorage.id_azienda);
  },
};
</script>
<style scoped>
</style>