
export const jWorkers = {
    'new' : function(method){
        const blobURL = URL.createObjectURL( new Blob(
            [ `(${method.toString()})()` ], { type: 'application/javascript' } ) 
        );
        const result = new Worker( blobURL );
        URL.revokeObjectURL( blobURL );
        return result;
    },

    'stop' : function(worker){ worker.terminate(); }
}