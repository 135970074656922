import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";

import GestioneProfilo from "../views/IMPOSTAZIONI/GestioneProfilo.vue";
import GestioneAzienda from "../views/IMPOSTAZIONI/GestioneAzienda.vue";
import GestioneUtentiSettings from "../views/IMPOSTAZIONI/GestioneUtenti.vue";

import HomeChat from "../views/CHAT/Home.vue";
import ArticoloDetails from "../views/PRODUCTION/Articolo/ArticoloDetails.vue";
import Prelievo from "../views/PRODUCTION/Prelievo/Prelievo";
import Registrazione from "../views/PRODUCTION/Registrazione/Registrazione";
import Magazzino from "../views/PRODUCTION/Magazzino/Magazzino";
import ControlloDDT from "../views/PRODUCTION/Magazzino/ControlloDDT.vue";
import PackageList from "../views/PRODUCTION/Packaging/PackagingList";
import PackageDetail from "../views/PRODUCTION/Packaging/PackageItemDetail";
import AllegatiLista from "../views/PRODUCTION/AllegatiCsvorxslx/AllegatiLista.vue";
import Produzione2 from "../views/PRODUCTION/ProduzioneOnline/Produzione2.vue";
import Produzione from "../views/PRODUCTION/Produzione/Produzione.vue";
import Controllo from "../views/PRODUCTION/Controllo/Controllo.vue";
import Impostazioni from "../views/PRODUCTION/Impostazioni/Impostazioni.vue";
import Avanzamento from "../views/PRODUCTION/Avanzamento/Avanzamento.vue";
import Anagrafiche from "../views/PRODUCTION/Anagrafiche/Anagrafiche.vue";
import Binding from "../views/PRODUCTION/AllegatiCsvorxslx/ImportCsvToDb.vue";
import Manutenzione from "../views/MANUTENZIONE/Manutenzione.vue";
import ManutenzioneRichieste from "../views/MANUTENZIONE/ManutenzioneRichieste.vue";
import ManutenzioneInterventi from "../views/MANUTENZIONE/ManutenzioneInterventi.vue";
import ManutenzioneRegole from "../views/MANUTENZIONE/ManutenzioneRegole.vue";
import ManutenzioneAttivita from "../views/MANUTENZIONE/ManutenzioneAttivita.vue";
import ManutenzioneAnagrafiche from "../views/MANUTENZIONE/ManutenzioneAnagrafiche.vue";
import ManutenzioneGestioneLuoghi from "../views/MANUTENZIONE/ManutenzioneGestioneLuoghi.vue";
import ManutenzioneRecursiva from "../views/MANUTENZIONE/ManutenzioneRecursiva.vue";
import GestioneMacchinari from "../views/MANUTENZIONE/GestioneMacchinari.vue";
import MedicalDashboard from "../views/MEDICAL/Dashboard";
import MedicalHome from "../views/MEDICAL/Home";
import MedicalDettaglio from "../views/MEDICAL/Dettaglio";
import Richieste from "../views/MEDICAL/Richieste.vue";
import Attivita from "../views/MEDICAL/Attivita.vue";
import CheckHome from "../views/CHECK2/Dashboard";
import Quiz from "../views/CHECK2/Quiz.vue";
import QuizDetail from "../components/CHECK2/Quickquiz/QuizDetail.vue";
import FlowDetail from "../views/CHECK2/FlowDetail.vue";
import TabellaTest from "../views/CHECK2/TabellaTest.vue";
import Plant from "../views/PLANT/Plant.vue";
import Ganttview from "../views/GANTT/Gantt.vue";
import ConsuntivoRisorse from "../views/GANTT/ConsuntivoRisorse.vue";
// import EditTicket from "../views/TICKET/EditTicket.vue";
import SingleTicket from "../components/TICKET/SingleTicket.vue";
import HomeTicket from "../views/TICKET/HomeTicket.vue";
import DashboardTools from "../views/TOOLS/Dashboardtools.vue";
import GestionePriorita from "../views/TOOLS/Gestionepriorita.vue";
import Plan from "../views/TOOLS/Plan.vue";
import Logistica from "../views/TOOLS/Produzione_logistica.vue";
import ContoLavoro from "../views/TOOLS/LOGISTICA/Conto_lavoro.vue";
import IngressoMateriale from "../views/TOOLS/LOGISTICA/Ingresso_materiale.vue";
import UscitaMateriale from "../views/TOOLS/LOGISTICA/Uscita_materiale.vue";
import ProductionAuth from "../views/TOOLS/ProductionAuth/ProductionAuth.vue";
import ProgettiTicket from "../views/TOOLS/ProgettiTicket.vue";
import AmbitiTicket from "../views/TOOLS/AmbitiTicket.vue";
import Packaging from "../views/TOOLS/LOGISTICA/Packaging.vue";
import ModuloNuovo from "../views/TOOLS/ModuloNuovo.vue";
import Benestare from "../views/TOOLS/BENESTARE/Benestare.vue";
import CicloAutoControllo from "../views/TOOLS/BENESTARE/CicloAutocontrollo.vue";
import PianoControlloModuloNuovo from "../views/TOOLS/BENESTARE/PianoControllo.vue";
import DashboardLogistica from "../views/LOGISTICA/Dashboard";
import PackingList from "../views/LOGISTICA/PackingList";
import PackingListDettaglio from "../views/LOGISTICA/PackingListDettaglio";
import QuickGraphs from "@/views/GRAPH/QuickGraphs.vue";
import GraphDashboard from "@/views/GRAPH/Old/Dashboard.vue";
import TabellaImport from "@/views/IMPORT/TabellaImport.vue";
import DashboardImport from "@/views/IMPORT/Dashboard.vue";
import CompetenzeList from "../views/COMPETENZE/CompetenzeList.vue";

import TabellaMezzi from "../components/LOGISTICA/TabellaMezzi.vue";
import Geolocalizzazione from "../components/LOGISTICA/Geolocalizzazione.vue";
import CicloStandard from "../components/PRODUCTION/Impostazioni/CicloStandardDettaglioNew.vue";
// import VideoChat from "../views/VIDEOCHAT/Videochat.vue";
import Chat from "../views/VIDEOCHAT/Chat.vue";
import ReportManager from "../components/REPORT/ReportManager.vue";
import PartProgramWS from "../components/TOOLS/PartProgramWS.vue";
import NavalRepair from "@/views/NAVALREPAIR/NavalRepair.vue";
import GestioneSintetica from "@/views/NAVALREPAIR/GestioneSintetica.vue";
import DGAnagrafiche from "@/views/NAVALREPAIR/DGAnagrafiche.vue";
import DGGestioneLF from "@/views/NAVALREPAIR/DGGestioneLF.vue";
import ElectricalMarine from "@/views/ELECTRICALMARINE/ElectricalMarine.vue";
import EMDistinta from "@/views/ELECTRICALMARINE/EMDistinta.vue";
import EMListaPicking from "@/views/ELECTRICALMARINE/EMListaPicking.vue";
import EMChecklist from "@/views/ELECTRICALMARINE/EMChecklist.vue";
import EMAnagraficaBarche from "@/views/ELECTRICALMARINE/EMAnagraficaBarche.vue";
import EMPdfConvert from "../views/ELECTRICALMARINE/EMPdfConvert.vue";
import EMArticolo from "../views/ELECTRICALMARINE/EMArticolo.vue";
import EMDiario from "../views/ELECTRICALMARINE/EMDiario.vue";
import Geolocation from "../components/GEOLOCATION/Geolocation.vue";
import Ocr from "../components/OCR/Ocr.vue";
import AnagraficaQuickConnext from '../components/INDUSTRIA_4.0/AnagraficaQuickConnext.vue';
import QuickLabel from "@/views/LABEL/QuickLabel.vue";
import EditMagazzinoView from '../views/MAGAZZINO/EditMagazzinoView.vue';
import GiacenzeMagazzinoView from '../views/MAGAZZINO/GiacenzeMagazzinoView.vue';
import MovimentiMagazzinoView from '../views/MAGAZZINO/MovimentiMagazzinoView.vue';
import HomeMagazzino from '../views/MAGAZZINO/HomeMagazzino.vue';

import CRISTALCarrelloCristal from '@/views/PRODUCTION/Produzione/ProduzioneCristal/CRISTALCarrelloCristal.vue';

import MovimentiStrumento from '@/views/PRODUCTION/Strumenti/Movimenti_Strumento.vue';
import IstruzioniDiLavoro from '@/views/PRODUCTION/IstruzioniLavoro/Istruzioni.vue';
import Presenze from "../views/_MODULI/PRESENZE/presenze.vue";

import Amministrazione from "@/views/_MODULI/AMMINISTRAZIONE/Amministrazione.vue";
import Inventario from "@/views/_MODULI/INVENTARIO/Inventario.vue";

import Contabilita from '@/views/_MODULI/CONTABILITA/Documenti.vue';
import Buttigliera from '@/views/_CLIENTI/Buttigliera/Home.vue';

Vue.use(VueRouter);
// Controlli permessi per QuickProduction
const checkGenerale = (id_modulo_menu) => {
  let menu_presente = false;
  store.state.menu_sx.forEach((elem) => {
    if (elem.id_modulo_menu === id_modulo_menu) {
      menu_presente = true;
    }
  });
  if (menu_presente === true) {
    redirectToDestination;
  } else {
    redirectToSource;
  }
};
const checkGeneraleDx = (titleModulo) => {
  let menu_presente = false;
  if (
    localStorage.moduli_dx !== undefined &&
    localStorage.moduli_dx !== "undefined" &&
    JSON.parse(localStorage.moduli_dx)[titleModulo] != "undefined"
  ) {
    menu_presente = true;
  }
  if (menu_presente === true) {
    redirectToDestination;
  } else {
    redirectToSource;
  }
};

const redirectToDestination = (to, from, next) => {
  next();
};
const redirectToSource = (to, from, next) => {
  next(from.path);
};
const routes = [
  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  //#region Gestione Comune Buttigliera
  {
    path: "/buttigliera/dashboard",
    name: "Buttigliera",
    component: Buttigliera,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  //#region Amministrazione
  {
    path: "/amministrazione/dashboard",
    name: "Amministrazione",
    component: Amministrazione,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
 
  //#endregion
  //#region Gestione Presenze
  {
    path: "/presenze/dashboard",
    name: "Presenze",
    component: Presenze,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion  
  //#region Contabilità
  {
    path: "/contabilita/dashboard",
    name: "Contabilita",
    component: Contabilita,
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion   

  //#region Inventario
  {
    path: "/inventario/dashboard",
    name: "Inventario",
    component: Inventario,
    meta: {
      requiresAuth: true,
    },
  },
  //#endregion  

  
  {
    path: "/home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        redirect: "/home/dashboard",
      },
      {
        path: "/home/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
     
      //#region Chat
      {
        path: "/home/chat",
        name: "Chat",
        component: HomeChat,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion     
      
      //#region Profili-Azienda-Utenti
      {
        path: "/home/gestioneprofilo",
        name: "GestioneProfilo",
        component: GestioneProfilo,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/gestioneazienda",
        name: "GestioneAzienda",
        component: GestioneAzienda,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/gestioneutenti",
        name: "GestioneUtenti",
        component: GestioneUtentiSettings,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/home/pdfconvert",
        name: "EMPdfConvert",
        component: EMPdfConvert,
        meta: {
          requiresAuth: true,
        },
      },

      //#endregion
      {
        path: "/home/competenze",
        name: "Competenze",
        component: CompetenzeList,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      //#region Ticket
      {
        path: "/home/ticket",
        name: "TicketList",
        component: HomeTicket,
        beforeEnter: checkGeneraleDx("TICKET"),
        props: {
          assistenza: 0,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/ticketassistenza",
        name: "TicketListAssistenza",
        component: HomeTicket,
        beforeEnter: checkGeneraleDx("TICKETA"),
        props: {
          assistenza: 1,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/ticket/:id",
        name: "SingleTicket",
        component: SingleTicket,
        beforeEnter: checkGeneraleDx("TICKETA"),
        props: {
          assistenza: 0,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/ticketassistenza/:id",
        name: "SingleTicketAssistenza",
        component: SingleTicket,
        beforeEnter: checkGeneraleDx("TICKETA"),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },

      //---------------- Da Eliminare
      /*
      {
        path: "/home/amministrazione",
        name: "Panoramica",
        component: Panoramica,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/gestioneprofili",
        name: "GestioneProfili",
        component: GestioneProfili,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/gestioneutenti",
        name: "GestioneUtenti",
        component: GestioneUtenti,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/gestionemoduli",
        name: "GestioneModuli",
        component: GestioneModuli,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/gestionemoduliaziende",
        name: "GestioneModuliAziende",
        component: GestioneModuliAziende,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/gestioneaziende",
        name: "GestioneAziende",
        component: GestioneAziende,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },

      */
      //-------------------------------

      {
        path: "/home/amministrazione/authmenu",
        name: "ProductionAuth",
        component: ProductionAuth,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/progettiticket",
        name: "ProgettiTicket",
        component: ProgettiTicket,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/amministrazione/ambititicket",
        name: "AmbitiTicket",
        component: AmbitiTicket,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/dashboardtools",
        name: "Dashboardtools",
        component: DashboardTools,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      /*
      {
        path: "/home/tools/gestioneprofili",
        name: "GestioneProfili",
        component: GestioneProfili,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/gestioneutenti",
        name: "GestioneUtenti",
        component: GestioneUtenti,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/gestionemoduli",
        name: "GestioneModuli",
        component: GestioneModuli,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/gestionemoduliaziende",
        name: "GestioneModuliAziende",
        component: GestioneModuliAziende,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/gestioneaziende",
        name: "GestioneAziende",
        component: GestioneAziende,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      */
      {
        path: "/home/tools/gestionepriorita",
        name: "GestionePriorita",
        component: GestionePriorita,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/plan",
        name: "Plan",
        component: Plan,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/tools/modulo_nuovo",
        name: "ModuloNuovo",
        component: ModuloNuovo,
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "/home/tools/modulo_nuovo/benestare",
            name: "Benestare",
            component: Benestare,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/home/tools/modulo_nuovo/ciclo_autocontrollo",
            name: "CicloAutoControllo",
            component: CicloAutoControllo,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/home/tools/modulo_nuovo/piano_controllo",
            name: "PianoControlloModuloNuovo",
            component: PianoControlloModuloNuovo,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      //#region Logistica
      {
        path: "/home/tools/logistica",
        name: "Logistica",
        component: Logistica,
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "/home/tools/logistica/packaging",
            name: "Packaging",
            component: Packaging,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/home/tools/logistica/ingresso_materiale",
            name: "IngressoMateriale",
            component: IngressoMateriale,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/home/tools/logistica/uscita_materiale",
            name: "UscitaMateriale",
            component: UscitaMateriale,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/home/logistica/contolavoro",
            name: "ContoLavoro",
            component: ContoLavoro,
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      //#endregion
      //#region Manutenzione
      {
        path: "/home/manutenzione/main",
        name: "ManutenzioneMain",
        component: Manutenzione,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/richieste",
        name: "ManutenzioneRichieste",
        component: ManutenzioneRichieste,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/interventi",
        name: "ManutenzioneInterventi",
        component: ManutenzioneInterventi,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/regole",
        name: "ManutenzioneRegole",
        component: ManutenzioneRegole,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/attivita",
        name: "ManutenzioneAttivita",
        component: ManutenzioneAttivita,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/anagrafiche",
        name: "ManutenzioneAnagrafiche",
        component: ManutenzioneAnagrafiche,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/gestionesedifornitori",
        name: "ManutenzioneGestioneLuoghi",
        component: ManutenzioneGestioneLuoghi,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/manutenzionerecursiva",
        name: "ManutenzioneRecursiva",
        component: ManutenzioneRecursiva,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/manutenzione/gestionemacchinari",
        name: "GestioneMacchinari",
        component: GestioneMacchinari,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region Production
      {
        path: "/home/production/articolo",
        name: "Articolo",
        component: () => import("../views/PRODUCTION/Articolo/Articolo.vue"),
        beforeEnter: checkGenerale(2),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/articolodetails/:id",
        name: "ArticoloDetails",
        component: ArticoloDetails,
        beforeEnter: checkGenerale(2),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production",
        name: "Production Dashboard",
        component: () =>
          import("../views/PRODUCTION/ProductionDash/ProductionDash.vue"),
        // component: ProductionDashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/macchina",
        name: "VehicleDetail",
        component: () =>
          import("../views/PRODUCTION/Macchine/VehicleDetail.vue"),
        beforeEnter: checkGenerale(3),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/prelievo",
        name: "Prelievo",
        component: Prelievo,
        beforeEnter: checkGenerale(11),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/magazzino",
        name: "Magazzino",
        component: Magazzino,
        beforeEnter: checkGenerale(11),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/magazzino/controlloddt",
        name: "ControlloDDT",
        component: ControlloDDT,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/registerazione",
        name: "Registrazione",
        component: Registrazione,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/pianocontrollo",
        name: "Qualita",
        // component: Qualita,
        component: () => import("../views/PRODUCTION/Qualita/Qualita.vue"),
        beforeEnter: checkGenerale(10),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/packaging",
        name: "PackageList",
        component: PackageList,
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/commesa",
        name: "commessa",
        // component: CommessaLista,
        component: () => import("../views/PRODUCTION/Commesa/CommessaList.vue"),
        beforeEnter: checkGenerale(8),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/qualita",
        name: "Qualita",
        component: () => import("../views/PRODUCTION/Qualita/Qualita.vue"),
        // component: Qualita,
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/cicloautocontrollodetail",
        name: "CicloAutoControlloDetail",
        // component: CicloAutoControlloDetail,
        component: () =>
          import(
            "../components/Qualita/CicloAutoControllo/CicloAutocontrolloDetail.vue"
          ),
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/benestaredetail",
        name: "BenestareDetail",
        // component: BenestareDetail,
        component: () =>
          import("../components/Qualita/Benestare/BenestareDetail.vue"),
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/pianocontrollodetail",
        name: "PianoControlloDetail",
        // component: PianoControlloDetail,
        component: () =>
          import(
            "../components/Qualita/PianoControlo/PianoControlloDetail.vue"
          ),
        props: true,
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/ciclostd",
        name: "cicloStd",
        component: CicloStandard,
        props: true,
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/pickinglist",
        name: "pickingList",
        // component: TabellaAvanzzamentoConfirmation,
        component: () =>
          import(
            "../components/PRODUCTION/Impostazioni/TabellaAvanzamentoConfirmation.vue"
          ),
        props: true,
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/commesa/:id",
        name: "commessaDetail",
        // component: commessaDetail,
        component: () =>
          import("../views/PRODUCTION/Commesa/CommesaDashBoard.vue"),
        beforeEnter: checkGenerale(8),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/commessamultipla/:id",
        name: "NuovaCommessaMultipla",
        // component: NuovaCommessaMultipla,
        component: () =>
          import("../views/PRODUCTION/Commesa/NuovaCommessaMultipla.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/binding/:id",
        name: "Binding",
        component: Binding,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/binding",
        name: "AllegatiLista",
        component: AllegatiLista,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/controllo",
        name: "ControlloProd",
        component: Controllo,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/produzioneonline",
        name: "Produzione2",
        component: Produzione2,
        beforeEnter: checkGenerale(6),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/produzione",
        name: "Produzione",
        component: Produzione,
        beforeEnter: checkGenerale(6),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/impostazioni",
        name: "Impostazioni",
        component: Impostazioni,
        beforeEnter: checkGenerale(4),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/avanzamento",
        name: "Avanzamento",
        component: Avanzamento,
        beforeEnter: checkGenerale(7),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/anagrafiche",
        name: "Anagrafiche",
        component: Anagrafiche,
        beforeEnter: checkGenerale(5),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/:orderId",
        name: "UpdateVehicle",
        // component: UpdateVehicleDetail,
        component: () =>
          import("../views/PRODUCTION/Macchine/UpdateVehicleDetail.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/production/packaging/:id",
        name: "PackageDetail",
        component: PackageDetail,
        beforeEnter: checkGenerale(9),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/istruzioni",
        name: "IstruzioniDiLavoro",
        component: IstruzioniDiLavoro,
        meta: {
          requiresAuth: true,
        },
      }, 
      {
        path: "/home/movimentistrumento",
        name: "MovimentiStrumento",
        component: MovimentiStrumento,
        meta: {
          requiresAuth: true,
        },
      },
      
      //#endregion
      //#region Medical
      {
        path: "/home/medical/home",
        name: "Home",
        component: MedicalHome,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/medical/dashboard",
        name: "Medical Dashboard",
        component: MedicalDashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/medical/dettaglio",
        name: "Dettaglio",
        component: MedicalDettaglio,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      {
        path: "/home/richieste",
        name: "Richieste",
        component: Richieste,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/home/attivita/:id",
        name: "Attivita",
        component: Attivita,
        meta: {
          requiresAuth: false,
        },
      },
      //#region QuickCheck
      {
        path: "/home/check/dashboard",
        name: "Check Dashboard",
        component: CheckHome,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/check/flowdetail/",
        name: "FlowDetail",
        component: FlowDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/check/consuntivotest",
        name: "TabellaTest",
        component: TabellaTest,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/check/quiz",
        name: "Quiz",
        component: Quiz,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/check/quiz_detail/:id",
        name: "Quiz Detail",
        component: QuizDetail,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region QuickPlant
      {
        path: "/home/plant/main",
        name: "Plant",
        component: Plant,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region Gantt
      {
        path: "/home/gantt",
        name: "Gantt",
        component: Ganttview,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/consuntivo_risorse",
        name: "ConsuntivoRisorse",
        component: ConsuntivoRisorse,
        meta: {
          requiresAuth: true,
        }
      },
      //#endregion
      //#region Logistica
      {
        path: "/home/logistica/",
        redirect: "/home/logistica/dashboard",
      },
      {
        path: "/home/logistica/dashboard",
        name: "Logistica Dashboard",
        component: DashboardLogistica,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/logistica/packinglist",
        name: "PackingList",
        component: PackingList,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/logistica/packinglistdettaglio/:id",
        name: "PackingListDettaglio",
        component: PackingListDettaglio,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      {
        path: "/home/graph",
        name: "QuickGraphs",
        component: QuickGraphs,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/graph/dashboard/:id",
        component: GraphDashboard,
        props: (route) => ({
          id: route.params.id,
        }),
        name : 'QuickGraphsDashboardTemp',
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region QuickData
      {
        path: "/home/import",
        name: "DashboardOverviewImport",
        component: DashboardImport,
      },
      {
        path: "/home/import/:id",
        name: "TabellaImport",
        component: TabellaImport,
      },
      //#endregion
      {
        path: "/home/logistica2/",
        name: "TabellaMezzi",
        component: TabellaMezzi,
      },
      {
        path: "/home/logistica2/Geolocalizzazione/:markers",
        name: "Geolocalizzazione",
        component: Geolocalizzazione,
      },

      //#region VideoChat
      // {
      //   path: "/home/videochat",
      //   name: "VideoChat",
      //   component: VideoChat,
      //   props: true,
      //   meta: {
      //     requiresAuth: true,
      //   }
      // },
      {
        path: "/home/videochat",
        name: "VideoChat",
        component: Chat,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/reportmanager",
        name: "ReportManager",
        component: ReportManager,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/partprogramws",
        name: "PartProgramWS",
        component: PartProgramWS,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region NavalRepair
      {
        path: "/home/naval",
        name: "NavalRepair",
        component: NavalRepair,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/naval/gestionesintetica",
        name: "GestioneSintetica",
        component: GestioneSintetica,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/naval/DGAnagrafiche",
        name: "DGAnagrafiche",
        component: DGAnagrafiche,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/naval/DGGestioneLF",
        name: "DGGestioneLF",
        component: DGGestioneLF,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/electrical/EMAnagraficaBarche",
        name: "EMAnagraficaBarche",
        component: EMAnagraficaBarche,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region ElectricalMarine
      {
        path: "/home/electrical",
        name: "ElectricalMarine",
        component: ElectricalMarine,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/electrical/EMDistinta",
        name: "EMDistinta",
        component: EMDistinta,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/electrical/EMListaPicking",
        name: "EMListaPicking",
        component: EMListaPicking,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/electrical/EMArticolo",
        name: "EMArticolo",
        component: EMArticolo,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/electrical/EMChecklist",
        name: "EMChecklist",
        component: EMChecklist,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/home/electrical/EMDichiarazioni",
        name: "EMDiario",
        component: EMDiario,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home/quickconnext/anagrafica',
        name: 'AnagraficaQuickConnext',
        component: AnagraficaQuickConnext,
        meta: {
          requiresAuth: true,
        },

      },
      {
        path: '/home/geolocation',
        name: 'Geolocation',
        component: Geolocation,
        meta: {
          requiresAuth: true,
        },

      },
      {
        path: '/home/ocr',
        name: 'Ocr',
        component: Ocr,
        meta: {
          requiresAuth: true,
        },

      },
      //#endregion
      //#region QuickLabel
      {
        path: '/home/label',
        name: 'QuickLabel',
        component: QuickLabel,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region Magazzino
      {
        path: '/home/magazzino',
        name: 'home-magazzino',
        component: HomeMagazzino,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home/giacenze-magazzino',
        name: 'giacenze-magazzino',
        component: GiacenzeMagazzinoView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home/edit-magazzino/:id/:duplicateId?',
        name: 'edit-magazzino',
        component: EditMagazzinoView,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/home/movimenti-magazzino/:id_magazzino/:id_articolo',
        name: 'movimenti-magazzino',
        component: MovimentiMagazzinoView,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      //#endregion
      //#region AvanzamentoCristal
      {
        path: '/home/cristal-avanzamento',
        name: 'CRISTALCarrelloCristal',
        component: CRISTALCarrelloCristal,
        props: true,
        meta: {
          requiresAuth: true,
        },
      }
      //#endregion
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  //controllo che l'utente abbia l'auth per entrare
  let jwt_get = localStorage.user_token;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !jwt_get && to.path !== "/login") next("login");
  else if (!requiresAuth && jwt_get) next("home");
  else {
    if (
      store.state.pagina_attuale == "controllo" &&
      store.state.check_paginaAttuale == 1
    ) {
      store.state.conferma_cambio_rotta = true;
      store.state.rotta_to = to;
      store.state.rotta_from = from;
    } else {
      next();
    }
  }
});
export default router;
