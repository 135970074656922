<template>
  <v-app style>
    <div class="d-flex" style="height:100%">
      <div style="width:100%">
        <v-content class="d-flex" style="height:100%">
          <v-container
            fluid
            style="height:100%;background-color:#f8f8f8;  background: url('../../assets/logo.png') no-repeat right 50%;"
          >
            <v-card class="mb-4 ml-3 mr-3 mt-2" color="white">
              <v-card-actions>
                <v-list-item class="grow mt-0 mb-0 pt-0 pb-0">
                  <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
                    <v-list-item-title class="title mt-0 mb-0 pt-0 pb-0">
                      <v-list-item-avatar class="mt-0 mb-0 pt-0 pb-0">
                        <img src="../../assets/logo.png" style="border-radius:unset!important" />
                      </v-list-item-avatar>QUICK VIEW Safety
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-row align="center" justify="end">
                    <div>
                      <v-card-title class="headline mt-0 pt-0 pb-2">{{this.nome_utente}}</v-card-title>
                      <v-card-subtitle class="pb-0">{{this.des_profilo}}</v-card-subtitle>
                    </div>
                    <b></b>
                    <v-menu
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :nudge-width="350"
                      bottom
                      :offset-y="offset"
                      right
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" small fab dark v-on="on">
                          <v-icon dark>mdi-account-circle</v-icon>
                        </v-btn>
                      </template>
                      <v-card light color style="z-index:9999">
                        <v-list rounded>
                          <v-subheader>IMPOSTAZIONI</v-subheader>
                          <v-list-item-group>
                            <v-list-item @click="dialog_users = true">
                              <v-list-item-icon>
                                <v-icon>mdi-account-edit-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Impostazioni</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-list rounded v-show="id_profilo==7">
                          <v-subheader>GESTIONE AZIENDA</v-subheader>
                          <v-list-item-group>
                            <v-list-item @click="dialog_users_edit=true">
                              <v-list-item-icon>
                                <v-icon>mdi-account-details</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Utenti</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="dialog_edit_azienda=true">
                              <v-list-item-icon>
                                <v-icon>mdi-office-building</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Azienda</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-list rounded>
                          <v-list-item-group>
                            <v-list-item to="/home/Privacy">
                              <v-list-item-icon>
                                <v-icon>mdi-privacy</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Privacy</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-dialog
                          v-model="dialog_users"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                        >
                          <GestioneProfilo
                            v-on:dialogUtenti="dialog_users=false"
                            v-on:confermaUsers="conferma_users_edit"
                            v-on:insertUtenti="insertUtenti"
                          />
                        </v-dialog>
                        <v-dialog
                          v-model="dialog_edit_azienda"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                        >
                          <GestioneAzienda
                            :key="contatore"
                            v-on:dialogUtenti="change_dialog_azienda"
                            v-on:aggiungiUtente="aggiungiUtente"
                            v-on:aggiungiGruppo="aggiungiGruppo"
                            v-on:visualizzaGruppo="visualizzaGruppo"
                            ref="GestioneAzienda"
                          />
                        </v-dialog>
                        <v-dialog
                          v-model="dialog_users_edit"
                          fullscreen
                          hide-overlay
                          transition="dialog-bottom-transition"
                        >
                          <GestioneUtenti
                            :key="contatore"
                            ref="dialog_mod_user"
                            v-on:dialogUtenti="change_dialog_users_edit"
                            v-on:confermaUsers="conferma_users_edit"
                            v-on:insertUtenti="insertUtenti"
                          />
                        </v-dialog>
                        <v-dialog v-model="dialog_set_utente_email" max-width="40%" persistent>
                          <v-card>
                            <v-card-title>Aggiungi Utente</v-card-title>
                            <v-card-text>
                              <v-card flat>
                                <v-card-text>
                                  <v-container>
                                    <v-select
                                      v-model="dipendente_sel"
                                      :items="dipendenti"
                                      label="Seleziona Utenti"
                                      item-text="nome"
                                      item-value="id_tabella"
                                      single-line
                                      multiple
                                      return-object
                                      hide-details
                                    ></v-select>
                                  </v-container>
                                </v-card-text>
                              </v-card>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog_set_utente_email = false"
                              >Annulla</v-btn>
                              <v-btn color="blue darken-1" text @click="setUtenteEmail()">Conferma</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialog_set_gruppo" max-width="40%" persistent>
                          <v-card>
                            <v-card-title>Aggiungi Gruppo Utenti</v-card-title>
                            <v-card-text>
                              <v-card flat>
                                <v-card-text>
                                  <v-row>
                                    <v-col md="4">
                                      <v-text-field
                                        v-model="nome_gruppo"
                                        label="Nome Gruppo"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col md="8">
                                      <v-select
                                        v-model="utenti_gruppo"
                                        :items="dipendenti.filter(f => f.cod_personale == '' || f.cod_personale == null)"
                                        label="Seleziona Utenti"
                                        item-text="cognome"
                                        item-value="id_tabella"
                                        single-line
                                        multiple
                                        return-object
                                        hide-details
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog_set_gruppo = false"
                              >Annulla</v-btn>
                              <v-btn color="blue darken-1" text @click="setGruppo()">Conferma</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialog_visualizza_gruppo" max-width="40%" persistent>
                          <v-card>
                            <v-card-title>Aggiungi Gruppo Utenti</v-card-title>
                            <v-card-text>
                              <v-card flat>
                                <v-card-text>
                                  <v-row>
                                    <v-col md="4">
                                      <v-text-field
                                        v-model="gruppo_nome"
                                        label="Nome Gruppo"
                                        required
                                        readonly="true"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col md="8">
                                      <v-select
                                        v-model="utenti_famiglia"
                                        :items="dipendenti.filter(f => f.cod_personale == '' || f.cod_personale == null || f.cod_personale == gruppo_nome)"
                                        label="Seleziona Utenti"
                                        item-text="cognome"
                                        item-value="id_tabella"
                                        single-line
                                        multiple
                                        return-object
                                        hide-details
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog_visualizza_gruppo = false"
                              >Annulla</v-btn>
                              <v-btn color="blue darken-1" text @click="setGruppoMod()">Conferma</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialog_conferma" max-width="40%" persistent>
                          <v-card>
                            <v-card-title v-html="titolo_conferma"></v-card-title>
                            <v-card-text>
                              <v-card flat>
                                <v-card-text>
                                  <v-container>
                                    <v-textarea
                                      v-show="arrivo=='sblocca' || arrivo=='blocca'"
                                      outlined
                                      model="note_blocco"
                                      name="input-7-4"
                                      label="Note"
                                      value
                                    ></v-textarea>
                                  </v-container>
                                </v-card-text>
                              </v-card>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog_conferma = false"
                              >Annulla</v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="setUtenteConferma()"
                              >Conferma</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog
                          v-model="dialog_inserUtenti"
                          max-width="80%"
                          min-height="500px"
                          persistent
                        >
                          <v-card>
                            <v-card-title v-html="titolo_conferma"></v-card-title>
                            <v-card-text>
                              <v-card>
                                <v-tabs
                                  v-model="tab"
                                  background-color="primary accent-4"
                                  centered
                                  dark
                                  icons-and-text
                                >
                                  <v-tabs-slider></v-tabs-slider>

                                  <v-tab href="#tab-1" v-if="arrivo_utente == 'inserisci'">
                                    Aggiungi Utente
                                    <v-icon>mdi-account</v-icon>
                                  </v-tab>

                                  <v-tab href="#tab-2" v-if="arrivo_utente == 'inserisci'">
                                    Carica Utenti
                                    <v-icon>mdi-account-group</v-icon>
                                  </v-tab>

                                  <v-tab href="#tab-3" v-if="arrivo_utente == 'modifica'">
                                    Modifica Utente
                                    <v-icon>mdi-account</v-icon>
                                  </v-tab>
                                </v-tabs>

                                <v-tabs-items v-model="tab">
                                  <v-tab-item :value="'tab-' + 1" style="min-height: 500px;">
                                    <v-card flat>
                                      <v-card-text>
                                        <v-container>
                                          <v-row>
                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.nome"
                                                :counter="10"
                                                label="Nome"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.cognome"
                                                :counter="10"
                                                label="Cognome"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                            <v-col md="4"></v-col>

                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.email"
                                                label="Email"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.cod_fiscale"
                                                label="Codice Fiscale"
                                                required
                                              ></v-text-field>
                                            </v-col>

                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.badge"
                                                label="Badge"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card-text>
                                    </v-card>
                                  </v-tab-item>
                                  <v-tab-item :value="'tab-' +2" style="min-height: 500px;">
                                    <v-card flat>
                                      <v-card-text style>
                                        <v-row>
                                          <v-col md="10">
                                            <vue-xlsx-table
                                              @on-select-file="handleSelectedFile"
                                            >CARICA FILE</vue-xlsx-table>
                                          </v-col>
                                          <v-col md="2">
                                            <a href="https://www.quickviewsafety.com/esempio.xls">
                                              <v-btn color="green darken-2" dark flat>
                                                <v-icon>mdi-file-excel</v-icon>Scarica Esempio
                                              </v-btn>
                                            </a>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-data-table
                                              :headers="headers_utenti"
                                              :items="items_utenti"
                                              class="elevation-1"
                                              :footer-props="{ disablePagination : true, disableItemsPerPage : true }"
                                              :hide-default-footer="true "
                                              disable-pagination
                                            >
                                              <template v-slot:item.des_esito="{ item }">
                                                <v-chip
                                                  :color="getAllerta(item.esito_temperatura)"
                                                  dark
                                                >{{ item.des_esito }}</v-chip>
                                              </template>
                                              <template
                                                v-slot:item.data_rilevazione="{ item }"
                                              >{{item.data_rilevazione | formatDateTime}}</template>
                                            </v-data-table>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </v-tab-item>
                                  <v-tab-item :value="'tab-' + 3" style="min-height: 500px;">
                                    <v-card flat>
                                      <v-card-text>
                                        <v-container>
                                          <v-row>
                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.nome"
                                                :counter="10"
                                                label="Nome"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.cognome"
                                                :counter="10"
                                                label="Cognome"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                            <v-col md="4">
                                              <v-autocomplete
                                                v-if="id_profilo == 7"
                                                v-model="nuovo_utente.id_profilo_utente"
                                                :items="tipi_profili"
                                                item-text="des_profilo_utente"
                                                item-value="id_profilo_utente"
                                                label="Tipo Profilo"
                                              ></v-autocomplete>
                                            </v-col>

                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.email"
                                                label="Email"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.cod_fiscale"
                                                label="Codice Fiscale"
                                                required
                                              ></v-text-field>
                                            </v-col>

                                            <v-col md="4">
                                              <v-text-field
                                                v-model="nuovo_utente.badge"
                                                label="Badge"
                                                required
                                              ></v-text-field>
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card-text>
                                    </v-card>
                                  </v-tab-item>
                                </v-tabs-items>
                              </v-card>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red darken-1"
                                dark
                                @click="chiudiDialogInserUtenti()"
                              >Annulla</v-btn>
                              <v-btn color="green darken-1" dark @click="uploadUtente()">Conferma</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text v-on:click="logout">Logout</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
            <router-view />
          </v-container>
        </v-content>
      </div>
    </div>
  </v-app>
</template>
<style scoped>
  .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px;
  }
</style>
<script>
import GestioneProfilo from "../IMPOSTAZIONI/GestioneProfilo";
import GestioneUtenti from "../IMPOSTAZIONI/GestioneUtenti";
import GestioneAzienda from "../IMPOSTAZIONI/GestioneAzienda";
import axios from "axios";
import config from "../../../public/config";
export default {
  data: () => ({
    config: config,
    nome_utente: localStorage.nominativo,
    collapseOnScroll: true,
    offset: true,
    name: "",
    titolo_conferma: "",
    drawer: false,
    note_blocco: "",
    item: 0,
    items: [],
    arrivo_utente: "",
    dipendente_sel: [],
    nuovo_utente: {
      nome: "",
      cognome: "",
      id_profilo_utente: "",
      badge: "",
      email: "",
      cod_fiscale: "",
      id_personale: -1,
      id_azienda: localStorage.id_azienda,
      attivo: 1,
    },
    tab: null,
    text: "",
    mini: true,
    item_sel: {},
    utenti_sel: [],

    arrivo: "",
    contatore: 0,
    dialog_users: false,
    dialog_users_edit: false,
    dialog_conferma: false,
    dialog_inserUtenti: false,
    dialog_edit_azienda: false,
    dialog_set_utente_email: false,
    dialog_visualizza_gruppo: false,
    dialog_set_gruppo: false,
    nome_gruppo: "",
    utenti_gruppo: [],
    dipendenti: [],
    tipi_profili: [],
    gruppo_nome: "",
    utenti_famiglia: [],
    headers_utenti: [
      {
        text: "Nome",
        value: "Nome",
      },
      { text: "Cognome", value: "Cognome" },
      { text: "Cod. Fiscale", value: "Cod_Fiscale" },
      { text: "Badge", value: "Badge" },
      { text: "Email", value: "email" },
    ],
    items_utenti: [],
    des_profilo: localStorage.des_profilo,
    id_profilo: localStorage.id_profilo,
  }),
  components: {
    GestioneProfilo,
    GestioneUtenti,
    GestioneAzienda,
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.getDipendenti();
  },
  methods: {
    handleSelectedFile(convertedData) {
      this.items_utenti = convertedData.body;
      console.log(convertedData);
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    logout() {
      localStorage.removeItem("id_user_sel");
      localStorage.removeItem("nominativo_sel");
      localStorage.removeItem("user_token");
      localStorage.removeItem("arrivo");
      this.$router.replace("login");
      location.reload();
    },
    change_dialog_users() {
      this.dialog_users = false;
    },
    change_dialog_users_edit() {
      this.contatore++;
      this.dialog_users_edit = false;
    },
    change_dialog_azienda() {
      this.dialog_edit_azienda = false;
    },
    conferma_users_edit(data) {
      this.dialog_conferma = true;
      if (data.item == -1) {
        var nominativi =
          "<div style='margin-left:10px;margin-right:10px;max-height:100px;overflow-y:auto'>";
        data.utenti.forEach((el) => {
          nominativi += el.nominativo + "<br>";
        });
        nominativi += "</div>";
        if (data.arrivo == "sblocca") {
          this.titolo_conferma =
            "Gli utenti:<br> " + nominativi + " verranno sbloccati.";
        } else if (data.arrivo == "blocca") {
          this.titolo_conferma =
            "Gli utenti:<br> " + nominativi + " verranno bloccati.";
        } else if (data.arrivo == "disattiva") {
          this.titolo_conferma =
            "Disattivazione degli utenti:<br> " + nominativi;
        } else if (data.arrivo == "attiva") {
          this.titolo_conferma = "Attivazione degli utenti:<br> " + nominativi;
        } else if (data.arrivo == "elimina") {
          this.titolo_conferma =
            "Gli utenti:<br> " + nominativi + " verranno eliminati";
        } else {
          this.titolo_conferma =
            "Reset password per gli utenti:<br> " + nominativi;
        }
      } else {
        if (data.arrivo == "sblocca") {
          this.titolo_conferma =
            "L' utente " + data.item.nominativo + " verrà sbloccato.";
        } else if (data.arrivo == "blocca") {
          this.titolo_conferma =
            "L 'utente " + data.item.nominativo + " verrà bloccato.";
        } else if (data.arrivo == "disattiva") {
          this.titolo_conferma =
            "Disattivazione dell'utente: " + data.item.nominativo;
        } else if (data.arrivo == "attiva") {
          this.titolo_conferma =
            "Attivazione dell'utente: " + data.item.nominativo;
        } else if (data.arrivo == "elimina") {
          this.titolo_conferma =
            "L'utente: " + data.item.nominativo + " verrà eliminato";
        } else {
          this.titolo_conferma =
            "Reset password per l'utente: " + data.item.nominativo;
        }
      }

      this.item_sel = data.item;
      this.utenti_sel = data.utenti;
      this.arrivo = data.arrivo;
    },
    setUtenteConferma() {
      var item = this.item_sel;
      var id = "-1";

      if (item != -1) {
        id = item.id_personale;
      } else {
        id = "";
        this.utenti_sel.forEach((el) => {
          id += el.id_personale + ",";
        });
      }
      id = id.substring(0, id.length - 1);
      var arrivo = this.arrivo;
      this.values = [];
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {};
      if (arrivo == "sblocca") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: item.attivo,
          sblocco: 1,
          note_sblocco: this.note_blocco,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "blocca") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: item.attivo,
          cambio_password: item.cambio_password,
          sblocco: 0,
          note_sblocco: this.note_blocco,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "disattiva") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: 0,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "attiva") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: 1,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "elimina") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: 1,
          validita: 0,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: item.attivo,
          cambio_password: 1,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      }

      var data = JSON.stringify(finale);
      axios
        .put(this.$store.state.config.ip + "api/Medical", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response);
          this.dialog_conferma = false;
          this.$refs.dialog_mod_user.conferma_azioni(
            item,
            arrivo,
            this.utenti_sel
          );
          this.note_blocco = "";
        })
        .catch((e) => {
          console.log("errore", e);

          this.$refs.dialog_mod_user.errore_azioni();
          if (e.response.status == 401) {
            localStorage.removeItem("id_user_sel");
            localStorage.removeItem("nominativo_sel");
            localStorage.removeItem("user_token");
            localStorage.removeItem("arrivo");
            this.$router.replace("login");
          }
        });
    },
    insertUtenti(arrivo) {
      //chiamata per i profili utente
      if (arrivo.arrivo == "modifica") {
        this.arrivo_utente = "modifica";
      } else {
        this.tab = "tab-1";
        this.arrivo_utente = "inserisci";
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var finale = {
        action: "getprofili",
        token: localStorage.user_token,
        idUtente: localStorage.id,
        dbName: "medical",
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/User", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log(response);
          this.tipi_profili = response.data;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("id_user_sel");
            localStorage.removeItem("nominativo_sel");
            localStorage.removeItem("user_token");
            localStorage.removeItem("arrivo");
            this.$router.replace("login");
          }
          console.log(e);
        });
      console.log(arrivo);
      if (arrivo.arrivo == "modifica") {
        this.nuovo_utente.nome = arrivo.item.nome;
        this.nuovo_utente.cognome = arrivo.item.cognome;
        this.nuovo_utente.id_profilo_utente = arrivo.item.id_profilo_utente;
        this.nuovo_utente.email = arrivo.item.email;
        this.nuovo_utente.id_personale = arrivo.item.id_personale;
        this.nuovo_utente.cod_fiscale = arrivo.item.cod_fiscale;
        this.nuovo_utente.badge = arrivo.item.badge;
      }
      this.titolo_conferma = "";
      this.dialog_inserUtenti = true;
    },
    getDipendenti() {
      this.values = [];
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var tipo = "";
      if (localStorage.id_profilo != "8") {
        tipo = "a";
      }
      console.log("tipooo", tipo)
      var finale = {
        action: "getlistadipendenti",
        // tipo: tipo,
        tipo: "tipo",
        token: localStorage.user_token,
        id_utente: localStorage.id,
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Medical", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.dipendenti = response.data.dipendenti;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          console.log(e);
        });
    },
    uploadUtente() {
      var tabella = [];
      console.log(this.tab);
      if (this.tab == "tab-2") {
        tabella = this.items_utenti;
      } else {
        if (
          this.nuovo_utente.nome != "" &&
          this.nuovo_utente.cognome != "" &&
          this.nuovo_utente.id_profilo != ""
        ) {
          tabella = [this.nuovo_utente];
        } else {
          return;
        }
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log(tabella);
      var finale = {
        action: "putsetutenti",
        token: localStorage.user_token,
        id_utente: localStorage.id,
        dbName: "medical",
        tabella: tabella,
      };
      var data = JSON.stringify(finale);
      console.log(finale);
      axios
        .put(this.$store.state.config.ip + "api/User", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.items_utenti = [];
          this.$refs.dialog_mod_user.conferma_azioni(response.data, "utenti");
          this.dialog_inserUtenti = false;
          console.log(response);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            this.$router.replace("login");
          }
          console.log(e);
        });
    },
    chiudiDialogInserUtenti() {
      this.items_utenti = [];
      this.dialog_inserUtenti = false;
    },
    aggiungiUtente() {
      this.dialog_set_utente_email = true;
    },
    aggiungiGruppo() {
      this.dialog_set_gruppo = true;
    },
    setUtenteEmail() {
      this.$refs.GestioneAzienda.SetUtenti(this.dipendente_sel);
      this.dialog_set_utente_email = false;
    },
    setGruppo() {
      var item = {
        nome: this.nome_gruppo,
        utenti: this.utenti_gruppo,
      };
      this.$refs.GestioneAzienda.setgruppo(item);
      this.dialog_set_gruppo = false;
    },
    setGruppoMod() {
      var item = {
        nome: this.gruppo_nome,
        utenti: this.utenti_famiglia,
      };
      this.$refs.GestioneAzienda.modGruppo(item);
      this.dialog_visualizza_gruppo = false;
    },
    downloadEsempio() {
      axios
        .get("/https://www.quickviewsafety.com/esempio.xls", {
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xls" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    visualizzaGruppo(item) {
      console.log(item);
      this.dialog_visualizza_gruppo = true;
      this.gruppo_nome = item[0].cod_personale;
      this.utenti_famiglia = item;
    },
  },
};
</script>
