<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar color="indigo" dense fixed>
      <v-toolbar-title class="white--text">Descrizione</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Risposta -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaRisposta" small v-bind="attrs" v-if="readonly===false" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Risposta</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="10" class="justify-center">
            <span style="font-size: 18px">{{
              domanda_sel_tmp.des_quesito
            }}</span>
            <v-divider class="mt-4 mb-5"></v-divider>
            <v-container class="ma-0">
              <v-row align="center" justify="center">
                <v-col align="center" justify="center">
                  <span class="mx-3" style="font-size: 18px">Esito:</span>
                  <v-icon
                    :color="domanda_sel_tmp.esito == '1' ? 'success' : ''"
                    v-model="domanda_sel_tmp.esito"
                    @click="
                      readonly === false
                        ? (domanda_sel_tmp.esito = '1')
                        : $refs.SnackbarQV.print(
                            'success',
                            `Collaudo terminato: la checklist è in modalità di sola lettura.`
                          )
                    "
                  >
                    {{
                      domanda_sel_tmp.esito === "1"
                        ? "mdi-checkbox-marked"
                        : "mdi-checkbox-blank-outline"
                    }}
                  </v-icon>
                  <v-icon
                    :color="domanda_sel_tmp.esito == '0' ? 'error' : ''"
                    v-model="domanda_sel_tmp.esito"
                    @click="
                      readonly === false
                        ? (domanda_sel_tmp.esito = '0')
                        : $refs.SnackbarQV.print(
                            'success',
                            `Collaudo terminato: la checklist è in modalità di sola lettura.`
                          )
                    "
                  >
                    {{
                      domanda_sel_tmp.esito === "0"
                        ? "mdi-close-box"
                        : "mdi-checkbox-blank-outline"
                    }}
                  </v-icon>
                  <v-icon
                    :color="
                      domanda_sel_tmp.esito != null &&
                      domanda_sel_tmp.esito != undefined &&
                      domanda_sel_tmp.esito != ''
                        ? ''
                        : 'primary'
                    "
                    v-model="domanda_sel_tmp.esito"
                    @click="
                      readonly === false
                        ? (domanda_sel_tmp.esito = null)
                        : $refs.SnackbarQV.print(
                            'success',
                            `Collaudo terminato: la checklist è in modalità di sola lettura.`
                          )
                    "
                  >
                    {{
                      domanda_sel_tmp.esito != null &&
                      domanda_sel_tmp.esito != undefined &&
                      domanda_sel_tmp.esito != ""
                        ? "mdi-checkbox-blank-outline"
                        : "mdi-help-box"
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
            <v-textarea
              v-model="domanda_sel_tmp.note"
              outlined
              label="Risposta"
              v-if="domanda_sel_tmp.tipo_quesito === id_domanda_descrittiva"
              :disabled="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      
      <Controllo
        :clear_on_save="false"
        :immagine_standard_editor="
          domanda_sel_tmp.img != null ? domanda_sel_tmp.img : ''
        "
        :show_close_button="false"
        :show_editor_arrow_button="!readonly"
        :show_editor_camera_button="!readonly"
        :show_editor_circle_button="!readonly"
        :show_editor_color_picker_button="!readonly"
        :show_editor_crop_button="!readonly"
        :show_editor_edit_back_button="!readonly"
        :show_editor_edit_forward_button ="!readonly"
        :show_editor_eraser_button="!readonly"
        :show_editor_free_draw_button="!readonly"
        :show_editor_large_size_button="!readonly"
        :show_editor_letter_case_button="!readonly"
        :show_editor_medium_size_button="!readonly"
        :show_editor_save_button="false"
        :show_editor_small_size_button="!readonly"
        :show_editor_square_button="!readonly"
        :show_editor_tool_columns="!readonly"
        :show_editor_upload_button="!readonly"
        :tabella_supporto="''"
        :titolo_editor="`Editor d'Immagine`"
        v-if="
          domanda_sel_tmp.tipo_quesito === id_domanda_immagine &&
          id_domanda_immagine != null
        "
        :visible="true"
      />
    </v-card-text>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import Domanda from "@/classes/EMDomanda.js";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "RDescrizioneDialog",
  components: {
    Controllo,
    SnackbarQV,
  },
  props: {
    domanda_sel: {
      type: Object,
      default: () => {},
    },
    id_domanda_descrittiva: {
      type: Number,
      default: null,
    },
    id_domanda_immagine: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      domanda_sel_tmp: new Domanda(),
      // Modal bool
      // Array
    };
  },
  computed: {},
  watch: {
    domanda_sel: {
      immediate: true,
      handler() {
        this.domanda_sel_tmp = new Domanda(this.domanda_sel);
      },
    },
  },
  methods: {
    chiudiDialog() {
      this.$emit("click_chiudi");
    },
    salvaRisposta() {
      // Se la domanda è descrittiva...
      if (this.domanda_sel_tmp.tipo_quesito === this.id_domanda_descrittiva) {
        // Controllo che siano stato indicati l'esito e la descrizione
        if (this.domanda_sel_tmp.esito === null) {
          this.$refs.SnackbarQV.print(
            "orange",
            `Associare un esito alla descrizione.`
          );
          return;
        } else if (this.domanda_sel_tmp.note === "") {
          this.$refs.SnackbarQV.print("orange", `Inserire una descrizione.`);
          return;
        }

        // Se è tutto a posto, emetto l'oggetto da registrare
        this.$emit("salva_risposta_descrittiva", {
          note: this.domanda_sel_tmp.note,
          esito: this.domanda_sel_tmp.esito,
        });
      } else {
        // Se invece la domanda è di tipo immagine...

        // Controllo che siano stato indicati l'esito e la descrizione
        if (this.domanda_sel_tmp.esito === null) {
          this.$refs.SnackbarQV.print(
            "orange",
            `Associare un esito alla descrizione.`
          );
          return;
        }

        // Pesco l'immagine dal componente figlio
        this.domanda_sel_tmp.img =
          "data:image/svg+xml; charset=utf8, " +
          encodeURIComponent(document.getElementById("svg").outerHTML) +
          ` #svgView(viewBox(0, 0, ${
            document.getElementById("svg").style.width
          }, ${document.getElementById("svg").style.height}))`;

        // Se è tutto a posto, emetto l'oggetto da registrare
        this.$emit("salva_risposta_immagine", {
          img: this.domanda_sel_tmp.img,
          esito: this.domanda_sel_tmp.esito,
        });
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style> 
