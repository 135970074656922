<template>
  <v-card>
    <v-toolbar
      class="white--text"
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title dense> Registrazione Tempi di Fermo </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-row align="center" style="text-align: left">
        <v-col cols="3">
          <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
            >Utente:</span
          >
          <span style="font-size: 20px">{{ nome_utente }}</span>
        </v-col>
        <v-col cols="3">
          <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
            >Commessa:</span
          >
          <span style="font-size: 20px">{{ work_order_sel.cod_commessa }}</span>
        </v-col>
        <v-col cols="3"> </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row align="center" style="text-align: left">
        <v-col cols="3">
          <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
            >Work Order:</span
          >
          <span style="font-size: 20px">{{ work_order_sel.cod_fase }}</span>
        </v-col>
        <v-col cols="3">
          <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
            >Macchina:</span
          >
          <span style="font-size: 20px">{{ work_order_sel.cod_macchina }}</span>
        </v-col>
        <v-col cols="3">
          <span style="color: #3399ff; font-size: 20px; margin-right: 10px"
            >Data:</span
          >
          <span style="font-size: 20px">{{ work_order_sel.ora_stop }}</span>
        </v-col>
        <v-col cols="3" align="right">
          <v-btn
            class="error--text mr-5"
            @click="clickIndietro"
            style="height: 80px; width: 80px"
          >
            <v-icon style="font-size: 40px"> mdi-undo </v-icon>
          </v-btn>
          <v-btn
            class="success--text"
            @click="clickAvanti"
            style="height: 80px; width: 80px"
          >
            <v-icon style="font-size: 40px"> mdi-redo </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <v-row>
        <v-col cols="6">
          <v-text-field
            background-color="#ffffff"
            dense
            height="50px"
            hide-details
            label="Note Registrazione"
            style="
              display: inline-block;
              font-size: 15px;
              font-weight: bold;
              line-height: 35px;
              padding: 0px;
              width: 100%;
            "
            v-model="note_tempi"
          ></v-text-field>
        </v-col>
        <v-col cols="3" style="text-align: left"
          ><span style="font-size: 20px"><b>Causale di fermo</b></span></v-col
        >
        <v-col cols="3" style="text-align: left"
          ><span style="font-size: 20px"><b>Tempo (minuti)</b></span></v-col
        >
      </v-row>
      <v-row align="center" :key="causale.id" v-for="causale in causali">
        <v-col cols="6"> </v-col>
        <v-col cols="3" style="text-align: left">
          <span style="font-size: 20px; line-height: 25px">{{
            causale.text
          }}</span>
        </v-col>
        <v-col cols="3" style="text-align: left; line-height: 25px">
          <v-text-field
            background-color="#ffffff"
            @change="calcolaTotaleTempi()"
            class="mr-5"
            dense
            hide-details
            label=""
            outlined
            style="
              border-radius: 0px;
              display: inline-block;
              font-size: 20px;
              line-height: 25px;
              max-width: 100px;
            "
            type="number"
            v-model="causale.numero"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"> </v-col>
        <v-col cols="3" style="text-align: right"
          ><span style="color: #3399ff; font-size: 20px">Totale</span>
        </v-col>
        <v-col cols="3" style="text-align: left">
          <span style="color: #3399ff; font-size: 20px">{{
            totale_tempi
          }}</span></v-col
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "RegistraTempi",
  components: {},
  props: {
    work_order_sel: { type: Object, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo: 21212,
      cod_lotto: "ab123",
      conformita: null,
      causale_non_conformita: null,
      nome_utente: localStorage.getItem("nominativo"),
      note_tempi: "",
      pezzi_prodotti: null,
      totale_tempi: 0,

      // Array
      causali: [
        { id: 0, text: "Mancanza Materiale", numero: 0 },
        { id: 1, text: "Mancanza Consumabili", numero: 0 },
        { id: 2, text: "Attrezzature Indisponibili", numero: 0 },
        { id: 3, text: "Macchinari Indisponibili", numero: 0 },
        { id: 4, text: "Operation Sconosciuta", numero: 0 },
      ],
    };
  },
  watch: {
    work_order_sel: {
      immediate: true,
      handler() {
        // Se ci sono le note_tempi, le visualizzo
        this.note_tempi =
          this.work_order_sel.note_tempi != undefined ? this.work_order_sel.note_tempi : "";
      },
    },
  },
  methods: {
    calcolaTotaleTempi() {
      this.totale_tempi = 0;
      this.causali.forEach((elem) => {
        this.totale_tempi = this.totale_tempi + parseInt(elem.numero);
      });
    },
    clickAvanti() {
      this.$emit("click_avanti");
    },
    clickIndietro() {
      this.$emit("click_indietro");
    },
  },
};
</script>
<style>
</style>