<template>
  <div
    class="mt-0 mb-0 mx-5 pt-3"
    style="background-color: rgba(0, 0, 0, 0); height: 85vh; max-height: 85vh"
  >
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"> QuickPlant</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="esci" small v-bind="attrs" v-on="on">
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Torna alla Home</span>
      </v-tooltip>
    </v-toolbar>

    <v-data-table
      fixed-header
      :headers="headers_disegni"
      height="65vh"
      :items="items_disegni"
      v-if="tabella_disegni_visible === true"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 pt-2 mb-5" justify="center">
          <v-col cols="4" md="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-5"
                  @click="aggiungiDisegno"
                  color="blue-grey lighten-5"
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi Disegno</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" md="2">
            <v-autocomplete
              background-color="#ffffff"
              @change="filtra"
              dense
              hide-details
              :items="items_disegni"
              item-text="des_disegno"
              item-value="des_disegno"
              label="Titolo"
              style="display: inline-block; max-width: 350px"
              v-model="des_disegno_search"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="2">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="0"
              min-width="289"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="#ffffff"
                  @change="filtra"
                  dense
                  hide-details
                  label="Dalla Data (Creazione)"
                  style="display: inline-block; max-width: 350px"
                  v-bind:value="formatDataDa(data_da_search)"
                  readonly
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="data_da_search"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" md="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="ripristina" small v-bind="attrs" v-on="on">
                  <v-icon color="primary"> mdi-filter-off </v-icon>
                </v-btn>
              </template>
              <span>Annulla Filtro</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>

      <!-- Bottone di modifica del disegno -->
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="clickModifica(item)" small v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Modifica Disegno</span>
        </v-tooltip>
      </template>

      <!-- Bottone di ridefinizione della testata del disegno -->
      <template v-slot:[`item.configurazione`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickModificaConfigurazione(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-axis-arrow-info </v-icon>
            </v-btn>
          </template>
          <span>Aggiorna Configurazione</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <Plantchart
      @chiudi_disegno="handleChiudiDisegno"
      :id_disegno="disegno_sel.id_disegno"
      :key="key_flowchart"
      v-if="plantchart_visible"
      :visible="plantchart_visible"
    >
    </Plantchart>

    <v-dialog v-model="modifica_disegno_dialog" width="75%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Caratteristiche Disegno</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaDisegno" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiModificaDisegno"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="3">
                <v-text-field autocomplete="off" label="Titolo" v-model="disegno_sel.des_disegno">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field autocomplete="off" label="Autore" v-model="disegno_sel.des_utente">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field autocomplete="off" label="Data Creazione" readonly v-model="disegno_sel.data_ins">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field autocomplete="off" label="Data Ultima Modifica" readonly v-model="disegno_sel.data_mod">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SnackbarQV ref="snackbarqv" />
  </div>
</template>
<script>
import moment from "moment/moment";
import Plantchart from "../../components/PLANT/Plantchart.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

class Disegno {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "data_ins", default_val: "" },
      { nome: "data_mod", default_val: "" },
      { nome: "des_disegno", default_val: "" },
      { nome: "des_utente", default_val: localStorage.nominativo },
      { nome: "id_disegno", default_val: -1 },
      { nome: "id_utente", default_val: localStorage.id_profilo },
      { nome: "nodi", default_val: [] },
      { nome: "validita", default_val: 1 },
    ];

    return campi;
  }
}

export default {
  name: "Plant",
  components: {
    Plantchart,
    SnackbarQV
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      data_da_search: "",
      des_disegno_search: "",
      disegno_sel: new Disegno(),
      key_flowchart: 0,

      // Modal
      menu1: false,
      modifica_disegno_dialog: false,
      plantchart_visible: false,
      tabella_disegni_visible: true,

      // Array
      headers_disegni: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Configurazione",
          sortable: false,
          value: "configurazione",
        },
        {
          text: "Titolo",
          sortable: false,
          value: "des_disegno",
        },
        {
          text: "Utente",
          sortable: false,
          value: "des_utente",
        },
        {
          text: "Creazione",
          sortable: false,
          value: "data_ins",
        },
        {
          text: "Ultima Modifica",
          sortable: false,
          value: "data_mod",
        },
      ],
      items_disegni: [],
      items_disegni_totali: [],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    aggiungiDisegno() {
      // Apro il dialog della modifica del disegno.
      this.modifica_disegno_dialog = true;
    },
    chiudiModificaDisegno() {
      // Chiudo il dialog
      this.modifica_disegno_dialog = false;

      // Resetto le variabili di supporto del dialog
      this.disegno_sel.reset();
    },
    clickModifica(arg) {
      // Aumento la key per resettare il grafico, nel caso ce ne fosse bisogno.
      this.key_flowchart++;
      this.disegno_sel = new Disegno(arg);
      this.tabella_disegni_visible = false;
      this.plantchart_visible = true;
    },
    clickModificaConfigurazione(arg) {
      this.disegno_sel = new Disegno(arg);
      this.modifica_disegno_dialog = true;
    },
    esci() {
      this.$router.push("/home");
    },
    filtra() {
      let items_disegni_tmp = new Array();
      this.items_disegni_totali.forEach((elem) => {
        items_disegni_tmp.push(Object.assign({}, elem));
      });

      // Filtro la des_disegno
      if (this.des_disegno_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_disegni_tmp.forEach((elem) => {
          if (elem.des_disegno == this.des_disegno_search) {
            res.push(Object.assign({}, elem));
          }
        });

        // Modello la risposta provvisoria su "res".
        items_disegni_tmp = res;
      }

      if (this.data_da_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_disegni_tmp.forEach((elem) => {
          if (elem.des_disegno == this.des_disegno_search) {
            res.push(Object.assign({}, elem));
          }
        });

        // Modello la risposta provvisoria su "res".
        items_disegni_tmp = res;
      }

      this.items_disegni = Array.from(items_disegni_tmp);
    },
    formatDataDa() {
      return this.data_da_search
        ? moment(this.data_da_search).format("DD/MM/YYYY")
        : "";
    },
    getListaDisegni() {
      let request = {
        controller: "Plant",
        method: "POST",
        richiesta: {
          action: "getlistadisegni",
          token: localStorage.user_token,
          id_utente: localStorage.id,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Modifico le date.
          res.data.result.disegni.forEach((d) => {
            d.data_ins = d.data_ins
              ? moment(d.data_ins).format("DD/MM/YYYY")
              : "";
            d.data_mod = d.data_mod
              ? moment(d.data_mod).format("DD/MM/YYYY")
              : "";
          });

          // Popolo gli array dei disegni totali e dei disegni visualizzati.
          this.items_disegni_totali = Array.from(res.data.result.disegni);
          this.items_disegni = Array.from(res.data.result.disegni);

          // Resetto i filtri nel caso fossero stati precedentemente attivati.
          this.des_disegno_search = "";
        } else {
          this.$refs.snackbarqv.print(
            "error",
            `Criticità tecnica, non è stato possibile salvare i dati. Codice errore: ${res.status}.`
          );
        }
      });
    },
    handleChiudiDisegno() {
      /* Nascondo il chart, visualizzo la tabella e resetto
       * il disegno_sel per prepararmi a una nuova selezione.*/
      this.plantchart_visible = false;
      this.disegno_sel.reset();
      this.tabella_disegni_visible = true;

      // Aumento la key per resettare il componente.
      this.key_flowchart++;
    },
    ripristina() {
      // Resetto i valori dei filtri
      this.des_disegno_search = "";

      // Resetto i valori della tabella
      this.items_disegni = new Array();
      this.items_disegni_totali.forEach((elem) => {
        this.items_disegni.push(Object.assign({}, elem));
      });
    },
    salvaDisegno() {
      let request = {
        controller: "Plant",
        method: "PUT",
        richiesta: {
          action: "setdisegno",
          token: localStorage.user_token,
          disegno: [this.disegno_sel],
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          /* Se il salvataggio è andato a buon fine il BE mi sta
           * rispondendo con un oggetto e lo uso per entrare in modifica. */
          this.key_flowchart++;
          this.disegno_sel.id_disegno = res.data.id_disegno;
          this.tabella_disegni_visible = false;
          this.plantchart_visible = true;

          // Chiudo il dialog di creazione del nuovo nodo.
          this.modifica_disegno_dialog = false;
          this.disegno_sel.reset();
        } else {
          this.$refs.snackbarqv.print("error", `Criticità tecnica, non è stato possibile salvare i dati. Codice errore: ${res.status}.`);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getListaDisegni();
  },
};
</script>
<style scoped>
</style>