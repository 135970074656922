import SVG from './SVG.js';

const StandardClasses = {
    setup : function(){
    
        // 1) definizione del metodo String.replaceAll se non esistente 
        if(String.prototype.replaceAll == undefined){
            console.warn('Ridefinizione di String.prototype.replaceAll()');
            String.prototype.replaceAll = function(vecchio, nuovo){
                try{
                    return this.split(vecchio).join(nuovo);
                }catch(error){
                    console.error(error);
                }
            }
        }

        // 2) definizione del metodo window.freeComponent (da chiamare nel destroyed() )
        window.freeComponent = function(component){
            let keys;
            try{
                keys = Object.keys(component);                
            }catch(error){
                console.warn(error);
                return;
            }
            const keynum = keys.length;
            let counter = 0;
            for(;counter < keynum; counter++){
                try{
                    delete component[keys[counter]];
                }catch(error){
                    console.warn(error);
                    continue;
                }
            }
        }

        // 3) window.cloneDeep -> copia ricorsiva di array e oggetti
        window.cloneDeep = function(item){
            if(item === null || item === undefined){ return item; }
            try{
                switch(item.constructor.name.toLowerCase()){
                    case 'object': {
                            const new_item = new Object();
                            const fields = Object.keys(item);
                            const length = fields.length;
                            for(let i = 0; i < length; i++){
                                const field = fields[i];
                                new_item[field] = window.cloneDeep(item[field]);
                            }
                            return new_item;
                        }
                    case 'array': {
                            const new_item = new Array();
                            const length = item.length;
                            for(let i = 0; i < length; i++){
                                new_item[i] = window.cloneDeep(item[i]);
                            }
                            return new_item;
                        }
                    default:
                        return item;
                }
            }catch(error){
                console.warn(error);
                return item;
            }
        }

        // 4) window.SVG -> restituisce path svg (path = nome di material design icons)
        const svgclass = new SVG();
        window.SVG = svgclass.getPath;
    }
}
export default StandardClasses