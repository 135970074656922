<template>
  <v-card>
    <!-- Titolo della card -->
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Dettagli Fornitore di Manutenzione
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaAnagrafica" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on">
            <v-icon color="error"> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text class="mt-5">
      <!-- Tab di dettaglio -->
      <v-tabs v-model="tab" slider-color="transparent">
        <v-tab
          active-class="py-0 my-0 indigo white--text"
          v-for="item in tabs_anagrafica"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <AnagraficheDettaglioGenerale
            :abilita_codice_fornitore="abilita_codice_fornitore"
            :anagrafica_sel="anagrafica_sel"
            ref="DettaglioGenerale"
          />
        </v-tab-item>
        <v-tab-item>
          <AnagraficheDettaglioTipologia
            :anagrafica_sel="anagrafica_sel"
            @tipologie_aggiornate="aggiornaTipologie"
            ref="DettaglioTipologia"
          />
        </v-tab-item>
        <v-tab-item>
          <AnagraficheDettaglioTipologie
            @AggiornaAnagrafica="AggiornaAnagrafica"
            @tipologie_aggiornate="aggiornaTipologie"
            :anagrafica_sel="anagrafica_sel"
            ref="DettaglioTipologie"
            :tipologie="tipologie"
            :key="chiave_dettaglio_tipologie"
          />
        </v-tab-item>
        <v-tab-item v-if="concessionari_visible">
          <AnagraficheDettaglioConcessionari
            :anagrafica_sel="anagrafica_sel"
            @concessionari_aggiornati="aggiornaTipologie2"
            :key="chiave_dettaglio_concessionari"
            :visible="visible"
            ref="DettaglioConcessionari"
          />
        </v-tab-item>
        <v-tab-item v-if="anagrafica_sel.id_anagrafica != -1">
          <AnagraficheDettaglioOperatori
            @tipologie_aggiornate="aggiornaTipologie2"
            :anagrafica_sel="anagrafica_sel"
            ref="DettaglioOperatori"
            :key="chiave_dettaglio_operatori"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <!-- Dialog di conferma di chiusura -->
    <v-dialog v-model="conferma_chiudi_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Chiusura Modifica Anagrafica</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text style="margin-top: 20px; overflow-y: auto">
          Sei sicuro di voler chidere la finestra? Le eventuali modifiche
          saranno perse.
          <v-divider class="my-5"></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickConfermaChiudi"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="clickAnnullaChiudi" small v-bind="attrs" v-on="on">
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Annulla</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Snackbar -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import AnagraficheDettaglioConcessionari from "@/components/MANUTENZIONE/AnagraficheDettaglioConcessionari.vue";
import AnagraficheDettaglioGenerale from "@/components/MANUTENZIONE/AnagraficheDettaglioGenerale.vue";
import AnagraficheDettaglioTipologia from "@/components/MANUTENZIONE/AnagraficheDettaglioTipologia.vue";
import AnagraficheDettaglioOperatori from "@/components/MANUTENZIONE/AnagraficheDettaglioOperatori.vue";
import AnagraficheDettaglioTipologie from "@/components/MANUTENZIONE/AnagraficheDettaglioTipologie.vue";
import Registry from "@/classes/Registry.js";

export default {
  name: "DettagliAnagrafica",
  components: {
    AnagraficheDettaglioConcessionari,
    AnagraficheDettaglioGenerale,
    AnagraficheDettaglioTipologia,
    AnagraficheDettaglioOperatori,
    AnagraficheDettaglioTipologie,
  },
  props: {
    abilita_codice_fornitore: {
      type: Boolean,
      default: true,
    },
    anagrafica_sel: { type: Object, default: null },
    concessionari_visible: {
      type: Boolean,
      default: false,
    },
    check_codice_fiscale: {
      type: Boolean,
      default: true,
    },
    check_p_iva: {
      type: Boolean,
      default: true,
    },
    check_p_iva_mini: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      chiave_dettaglio_concessionari: 0,
      chiave_dettaglio_operatori: 0,
      chiave_dettaglio_tipologie: 0,

      // Modal
      conferma_chiudi_dialog: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      tab: [],
      tabs_anagrafica: [],
      tipologie: [],
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          // Resetto la tab attualmente selezionata
          this.tab = [];

          /* Resetto le tab e decido quali mostrare.
           * Comincio a inserire quelle di default. */
          this.tabs_anagrafica = [];
          this.tabs_anagrafica = [
            "Generale",
            "Ambito Manutentivo",
            "Tipologie",
          ];

          // Se è necessario inserisco la tab dei concessionari.
          if (this.concessionari_visible === true) {
            this.tabs_anagrafica.push("Concessionari");
          }

          /* Se l'anagrafica è già stata salvata
           * aggiungo la tab del personale.  */
          if (this.anagrafica_sel.id_anagrafica != -1) {
            this.tabs_anagrafica.push("Personale");
          }
        }
      },
    },
  },
  methods: {
    AggiornaAnagrafica(anagrafica) {
      this.$emit("AggiornaAnagraficaFinal", anagrafica);
    },
    aggiornaTipologie(arg) {
      this.tipologie = arg;

      // Muto una chiave di prop per forzare il refresh del componente figlio.
      this.chiave_dettaglio_concessionari =
        this.chiave_dettaglio_concessionari === 0 ? 1 : 0;

      this.chiave_dettaglio_operatori =
        this.chiave_dettaglio_operatori === 0 ? 1 : 0;

      this.chiave_dettaglio_tipologie =
        this.chiave_dettaglio_tipologie === 0 ? 1 : 0;
    },
    aggiornaTipologie2() {
      this.chiave_dettaglio_concessionari =
        this.chiave_dettaglio_concessionari === 0 ? 1 : 0;

      this.chiave_dettaglio_operatori =
        this.chiave_dettaglio_operatori === 0 ? 1 : 0;

      this.chiave_dettaglio_tipologie =
        this.chiave_dettaglio_tipologie === 0 ? 1 : 0;
    },
    clickAnnullaChiudi() {
      this.conferma_chiudi_dialog = false;
    },
    clickChiudi() {
      this.conferma_chiudi_dialog = true;
    },
    clickConfermaChiudi() {
      this.conferma_chiudi_dialog = false;
      this.$emit("chiudiTabAnagrafica");
    },
    controllaCF() {
      // Definisco un pattern per il confronto
      var pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;

      // utilizzo il metodo search per verificare che il valore inserito nel campo
      // di input rispetti la stringa di verifica (pattern)
      if (
        this.$refs.DettaglioGenerale.codice_fiscale_tmp.search(pattern) == -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    controllaPartitaIVA(val) {
      if (!/^[0-9]{11}$/.test(val)) return false;
      else return true;
    },
    salvaAnagrafica() {
      // Verifico la lunghezza della partita IVA
      if (this.$refs.DettaglioGenerale.p_iva_tmp.length > 11 && this.check_p_iva === true) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Codice di P. IVA troppo lungo: richiesti 11 caratteri.";
        this.snackbar_text_color = "white";
        return;
      } else if (this.$refs.DettaglioGenerale.p_iva_tmp.length < 11 && this.check_p_iva === true) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Codice di P. IVA troppo breve: richiesti 11 caratteri.";
        this.snackbar_text_color = "white";
        return;
      } else if (
        !this.controllaPartitaIVA(this.$refs.DettaglioGenerale.p_iva_tmp) && this.check_p_iva === true
      ) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Codice di P. IVA non valido.";
        this.snackbar_text_color = "white";
        return;
      }

      // Verifico la validità della partita IVA minima.
      if (this.$refs.DettaglioGenerale.p_iva_mini_tmp.length > 2 && this.check_p_iva_mini === true) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Codice di P. IVA troppo lungo: richiesti 2 caratteri.";
        this.snackbar_text_color = "white";
        return;
      } else if (this.$refs.DettaglioGenerale.p_iva_mini_tmp.length < 2 && this.check_p_iva_mini === true) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Codice di P. IVA troppo breve: richiesti 2 caratteri.";
        this.snackbar_text_color = "white";
        return;
      }

      // Verifico la validità del codice fiscale.
      if (
        !(
          this.$refs.DettaglioGenerale.codice_fiscale_tmp.length == 16 ||
          this.$refs.DettaglioGenerale.codice_fiscale_tmp.length == 11
        ) && this.check_codice_fiscale === true
      ) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Codice Fiscale troppo lungo: richiesti 11 o 16 caratteri.";
        this.snackbar_text_color = "white";
        return;
      }

      if (
        !(
          this.controllaCF() == true ||
          this.controllaPartitaIVA(
            this.$refs.DettaglioGenerale.codice_fiscale_tmp
          ) == true
        ) && this.check_codice_fiscale === true
      ) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Codice Fiscale non valido.";
        this.snackbar_text_color = "white";
        return;
      }

      let anagrafica_tmp = new Registry();

      // Setto i dati generali dell'anagrafica
      anagrafica_tmp.cap = this.$refs.DettaglioGenerale.cap_tmp;
      anagrafica_tmp.codice = this.$refs.DettaglioGenerale.codice_tmp;
      anagrafica_tmp.codice_fiscale = this.$refs.DettaglioGenerale.codice_fiscale_tmp;
      anagrafica_tmp.des_localita = this.$refs.DettaglioGenerale.des_localita_tmp;
      anagrafica_tmp.des_nazione = this.$refs.DettaglioGenerale.des_nazione_tmp;
      anagrafica_tmp.des_regione = this.$refs.DettaglioGenerale.des_ragione_tmp;
      anagrafica_tmp.des_provincia = this.$refs.DettaglioGenerale.des_provincia_tmp;
      anagrafica_tmp.id_anagrafica = this.anagrafica_sel.id_anagrafica;
      anagrafica_tmp.id_localita = this.$refs.DettaglioGenerale.id_localita_tmp;
      anagrafica_tmp.id_nazione = this.$refs.DettaglioGenerale.id_nazione_tmp;
      anagrafica_tmp.id_regione = this.$refs.DettaglioGenerale.id_regione_tmp;
      anagrafica_tmp.id_provincia = this.$refs.DettaglioGenerale.id_provincia_tmp;
      anagrafica_tmp.indirizzo = this.$refs.DettaglioGenerale.indirizzo_tmp;
      anagrafica_tmp.p_iva = this.$refs.DettaglioGenerale.p_iva_tmp;
      anagrafica_tmp.p_iva_mini = this.$refs.DettaglioGenerale.p_iva_mini_tmp;
      anagrafica_tmp.ragione_sociale = this.$refs.DettaglioGenerale.ragione_sociale_tmp;
      anagrafica_tmp.sede_operativa = this.$refs.DettaglioGenerale.sede_operativa_tmp;
      anagrafica_tmp.SDI = this.$refs.DettaglioGenerale.SDI_tmp;
      anagrafica_tmp.telefono = this.$refs.DettaglioGenerale.telefono_tmp;
      anagrafica_tmp.tipo = "FORNITORE";
      anagrafica_tmp.codice_rea = this.$refs.DettaglioGenerale.codice_rea_tmp;
      anagrafica_tmp.sigla_rea = this.$refs.DettaglioGenerale.sigla_rea_tmp;
      anagrafica_tmp.email = this.$refs.DettaglioGenerale.email_tmp;

      // Genero il campo concessionari come serve al BE.
      if (this.$refs.DettaglioConcessionari) {
        let concessionari_tmp = [];
        this.$refs.DettaglioConcessionari.concessionari.forEach((c) => {
          /* I concessionari senza id_anagrafica sono oggetti provenienti da
           * tabella di DB e devono essere ricostruiti per essere salvati. */
          if (c.id_anagrafica === undefined) {
            let c_tmp = {};
            c_tmp.id_anagrafica = this.anagrafica_sel.id_anagrafica;
            c_tmp.id_anagrafica_concessionario = -1;
            c_tmp.id_concessionario = c.id_tabella;
            c_tmp.validita = 1;
            concessionari_tmp.push(c_tmp);
          } else {
            /* Se invece è un concessionario che già esisteva
             * lo risalvo tranqullamente così com'è. */
            concessionari_tmp.push(c);
          }
        });

        /* Elimino i concessionari che ci sono in
         * anagrafica_sel ma non nell'array da salvare,
         * perché vuol dire che l'utente li ha cancellati. */
        if (this.anagrafica_sel.concessionari) {
          this.anagrafica_sel.concessionari.forEach((ca) => {
            let ricorre = false;
            this.$refs.DettaglioConcessionari.concessionari.forEach((cc) => {
              if (
                ca.id_anagrafica_concessionario ===
                cc.id_anagrafica_concessionario
              ) {
                ricorre = true;
              }
            });

            if (ricorre === false) {
              let c_tmp = {};
              c_tmp = Object.assign({}, ca);
              c_tmp.validita = 0;
              concessionari_tmp.push(c_tmp);
            }
          });
        }

        anagrafica_tmp.concessionari = concessionari_tmp;
      }

      // Setto gli ambiti manutentivi dell'anagrafica
      /* Costruisco un array di oggetti conformi alle necessità del BE,
       * che mi serve per salvare gli ambiti manutentivi. */
      let ambiti_tmp = [];

      // Se ho aperto la tab delle tipologie, ne aggiungo i dati all'anagrafica
      if (this.$refs.DettaglioTipologia) {
        this.$refs.DettaglioTipologia.items_tipologia.forEach((ambito) => {
          if (
            ambito.validita === true ||
            ambito.id_anagrafica_dettaglio != -1
          ) {
            let nuovo_ambito = {
              id_anagrafica_dettaglio: ambito.id_anagrafica_dettaglio,
              id_anagrafica: ambito.id_anagrafica,
              id_ambito_manutenzione: ambito.id_ambito_manutenzione,
              validita: ambito.validita === false ? 0 : 1,
            };

            ambiti_tmp.push(nuovo_ambito);
          }
        });
      }

      // Assegno l'array modificato all'anagrafica da salvare nella request per il BE
      anagrafica_tmp.ambiti_manutentivi = ambiti_tmp;

      // Assegno i valori relativi al personale
      let tipologie_tmp = [];

      // Se ho aperto la tab degli operatori, ne aggiungo i dati all'anagrafica
      if (this.$refs.DettaglioOperatori) {
        this.$refs.DettaglioOperatori.items_operatori.forEach((operatore) => {
          anagrafica_tmp.anagrafica_contatti.push(operatore);
        });
      }
      if (this.$refs.DettaglioTipologie) {
        this.$refs.DettaglioTipologie.items_tipologie.forEach((tipologia) => {
          // Creo un array di specializzazioni per l'tipologia.
          let anagrafica_tipologia_specializzazioni = [];

          /* Aggiungo all'array delle specializzazioni degli oggetti
           * costruiti come serve al BE. */
          if (tipologia.specializzazioni) {
            tipologia.specializzazioni.forEach((specializzazione) => {
              let obj = {
                id_anagrafica_tipologia_dettaglio: specializzazione.id_anagrafica_tipologia_dettaglio
                  ? specializzazione.id_anagrafica_tipologia_dettaglio
                  : -1,
                id_anagrafica_tipologia:
                  tipologia.id_anagrafica_tipologia < 0
                    ? -1
                    : tipologia.id_anagrafica_tipologia,
                id_specializzazione: specializzazione.id_ambito_manutenzione,
                validita: tipologia.validita === 1 ? 1 : 0,
              };

              // Immetto l'oggetto generato nell'array temporaneo
              anagrafica_tipologia_specializzazioni.push(obj);
            });
          }

          // Se l'tipologia ha l'array specializzazioni_confronto perché non è nuovo
          if (tipologia.specializzazioni_confronto) {
            /* ... Guardo nelle specializzazioni_confronto dell'tipologia
             * le specializzazioni che non ricorrono nell'array specializzazioni
             * dell'tipologia, e se ce ne sono le aggiungo con validita = 0. */
            tipologia.specializzazioni_confronto.forEach(
              (specializzazione_c) => {
                let ricorre = false;
                tipologia.specializzazioni.forEach((specializzazione) => {
                  if (
                    specializzazione.id_specializzazione ===
                    specializzazione_c.id_specializzazione
                  ) {
                    ricorre = true;
                  }
                });

                if (ricorre === false) {
                  let obj = {
                    id_anagrafica_tipologia_dettaglio: specializzazione_c.id_anagrafica_tipologia_dettaglio
                      ? specializzazione_c.id_anagrafica_tipologia_dettaglio
                      : -1,
                    id_anagrafica_tipologia:
                      tipologia.id_anagrafica_tipologia < 0
                        ? -1
                        : tipologia.id_anagrafica_tipologia,
                    id_specializzazione:
                      specializzazione_c.id_ambito_manutenzione,
                    validita: 0,
                  };

                  // Immetto l'oggetto generato nell'array temporaneo
                  anagrafica_tipologia_specializzazioni.push(obj);
                }
              }
            );
          }

          // Assegno l'array delle specializzazioni all'tipologia
          tipologia.anagrafica_contatto_specializzazioni = anagrafica_tipologia_specializzazioni;

          // Valorizzo gli id degli eventuali operatori nuovi come piace al BE.
          tipologia.id_anagrafica_tipologia =
            tipologia.id_anagrafica_tipologia < 0
              ? -1
              : tipologia.id_anagrafica_tipologia;

          // Cancello eventuali array vuoti per conenienza di BE
          if (tipologia.anagrafica_contatto_specializzazioni) {
            if (tipologia.anagrafica_contatto_specializzazioni.length === 0) {
              delete tipologia.anagrafica_contatto_specializzazioni;
            }
          }

          if (tipologia.specializzazioni) {
            if (tipologia.specializzazioni.length === 0) {
              delete tipologia.specializzazioni;
            }
          }

          /* Se l'tipologia è nuovo non ha le
           * specializzazioni_confronto, ma se le ha... */
          if (tipologia.specializzazioni_confronto) {
            /* Contollo che siano piene, altrimenti le cancello
             * per convenienza di BE. */
            if (tipologia.specializzazioni_confronto.length === 0) {
              delete tipologia.specializzazioni_confronto;
            }
          }

          // Immetto l'tipologia nell'array temporaneo degli operatori.
          tipologie_tmp.push(tipologia);
        });
      }

      // Assegno all'anagrafica l'array temporaneo degli operatori
      anagrafica_tmp.tipologie = tipologie_tmp;
      anagrafica_tmp.tipologie.forEach((tipologia) => {
        let indice_tipologia = anagrafica_tmp.tipologie.indexOf(tipologia);
        tipologia.specializzazioni.forEach((specializzazione) => {
          specializzazione.riga = indice_tipologia;
        });
      });

      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setanagrafica",
          token: localStorage.user_token,
          anagrafica: [anagrafica_tmp],
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.$emit("aggiorna_anagrafica");
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Errore tecnico, non è stato possibile salvare i dati.";
          this.snackbar_text_color = "white";
        }
      });
    },
  },
};
</script>
<style>
</style>