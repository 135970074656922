<template>
  <v-dialog :scrollable="false" persistent v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-on="on" v-bind="attrs">
              <v-icon color="primary">mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi Risorse</span>
        </v-tooltip>
      </div>
    </template>
    <v-card>
      <v-toolbar class="indigo white--text" dense primary-title>
        <v-toolbar-title>Crea Risorsa</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              small
              :disabled="!valid || saving"
              @click="validate()"
            >
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>Salva Risorsa</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="dialog = false" small>
              <v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Chiudi</span>
        </v-tooltip>
      </v-toolbar>
      <v-container class="px-5" fluid>
        <v-form ref="form" lazy-validation>
          <v-row class="text-start">
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="single_item.id_risorsa"
                :items="utenti"
                label="Nome Risorsa"
                item-value="id_personale"
                :item-text="(item) => `${item.nome} ${item.cognome}`"
                placeholder="Digita per cercare"
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="6" class="py-0">
                  <v-text-field outlined dense
                    v-model="single_item.percentuale"
                    type="number"
                    label="Percentuale Risorsa"
                    step="0.01"
                    min="0"
                    max="1"
                    required
                  ></v-text-field>
                </v-col> -->
            <v-col cols="6">
              <v-text-field
                dense
                v-model="single_item.ore_assegnate"
                label="Ore assegnate"
                type="number"
                min="0"
                max="1000"
                step="1"
                required
                onkeydown="javascript: return event.keyCode == 69 ? false : true"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
function open(that) {
  var getUtenti = {
    controller: "Utente",
    method: "POST",
    richiesta: {
      action: "getutentelist",
      token: localStorage.user_token,
      id_utente: localStorage.id,
    },
  };

  that.$store.dispatch("api", getUtenti).then((res) => {
    that.utenti = res.data.result.gp_personale_utenti;
    var reqUsers = {
      controller: "Ticket",
      method: "POST",
      richiesta: {
        action: "getticket",
        token: localStorage.user_token,
        id_ticket: that.ticket.id_ticket,
        id_stato: "",
        assistenza: that.assistenza,
      },
    };
    that.$store.dispatch("api", reqUsers).then((res) => {
      var utenti_ticket = res.data.result.tickets_risorsa;
      if (utenti_ticket != undefined) {
        utenti_ticket.forEach((element2) => {
          that.utenti.forEach((element) => {
            if (element.id_personale == element2.id_risorsa) {
              that.utenti.splice(that.utenti.indexOf(element), 1);
            }
          });
        });
      }
    });
  });
}
export default {
  name: "AddResource",

  data: () => ({
    single_item: {
      // id_tabella: -1,
      // id_utente: "",
      // percentuale: "",
      // validita: 1,
      id_ticket_risorsa: -1,
      id_ticket: -1,
      id_risorsa: -1,
      ore_assegnate: 0,
      validita: 1,
    },
    dialog: false,
    id: -1,
    saving: false,
    utenti: [],
  }),
  props: {
    item: Object,
    ticket: Object,
    tipo: Number,
    assistenza: Number,
  },
  created() {},
  watch: {
    dialog: function (newI, oldI) {
      if (oldI != newI && newI != false) {
        this.single_item = {
          // id_tabella: -1,
          // id_utente: "",
          // percentuale: "",
          // validita: 1,
          id_ticket_risorsa: -1,
          id_ticket: -1,
          id_risorsa: -1,
          ore_assegnate: 0,
          validita: 1,
        };
        open(this);
      }
    },
  },
  computed: {
    valid() {
      return this.single_item.ore_assegnate != 0 &&
        this.single_item.id_risorsa != -1
        ? true
        : false;
    },
  },
  methods: {
    validate() {
      this.single_item.id_ticket = this.ticket.id_ticket;
      this.saving = true;
      var request = {
        controller: "Ticket",
        method: "PUT",
        richiesta: {
          action: "setticketrisorsa",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tabella: [this.single_item],
          assistenza: this.assistenza,
        },
      };
      // console.log(JSON.stringify(request.richiesta))
      this.$store.dispatch("api", request).then((res) => {
        this.dialog = false;
        this.$emit("dialogModule", true);
        console.log("res dialog module", res);
        this.saving= false;
      });
    },
  },
};
</script>