var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-snackbar',{attrs:{"color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbar_text))]),_c('v-navigation-drawer',{staticClass:" pt-1 ",attrs:{"app":"","mini-variant":_vm.mini,"mini-variant-width":"85","width":"370","permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{staticClass:"mx-2 mt-2"},[_c('v-list-item',{staticClass:"px-2"},[(_vm.mini)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),(!_vm.mini)?_c('v-list-item-title',[_vm._v("Contabilità")]):_vm._e(),(!_vm.mini)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v("mdi mdi-menu-open")])],1):_vm._e()],1)],1),_c('v-divider'),(!_vm.mini)?_c('v-container',[_c('v-text-field',{attrs:{"label":"Cerca ...","outlined":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{attrs:{"vertical":""}}),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"height":"size_height","max-height":"1010"}},[_c('v-treeview',{attrs:{"active":_vm.active,"open":_vm.open,"items":_vm.items,"activatable":"","transition":"","dense":"","item-key":"name","search":_vm.search,"filter":_vm.filter},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.file)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(item.file)+" ")])]}},{key:"append",fn:function(ref){
var leaf = ref.leaf;
var active = ref.active;
return [(leaf)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","plain":"","depressed":"","elevation":"0"},on:{"click":function($event){return _vm.aggiungi_preferiti()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" "+_vm._s(leaf ? active ? 'mdi-star-outline' : '' : '')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi a preferiti")])]):_vm._e()]}}],null,false,2516762979),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-card',{staticClass:"overflow-y-auto"},[_c('h4',{staticStyle:{"text-align":"left"}},[_vm._v(" Preferiti")]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItemPreferiti),callback:function ($$v) {_vm.selectedItemPreferiti=$$v},expression:"selectedItemPreferiti"}},_vm._l((_vm.preferiti),function(item,i){return _c('v-list-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-flag")])],1),_c('v-list-item-content',{on:{"click":_vm.apri_preferito}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-action',[(active)?_c('v-icon',{attrs:{"color":"red"},on:{"click":_vm.cancella_da_preferiti}},[_vm._v(" mdi-trash-can-outline ")]):_vm._e()],1)]}}],null,true)})}),1)],1)],1)],1):_vm._e()],1),_c('v-main',[_c('v-container',{staticClass:" px-2 ",attrs:{"fluid":""}},[(!_vm.selected)?[_c('v-toolbar',{attrs:{"dense":"","color":"white","elevation":"1"}},[_c('GlobalToolBar',{attrs:{"NOME_AZIENDA":this.nome_azienda,"NOME_UTENTE":this.nome_utente,"DES_PROFILO":this.des_profilo}})],1)]:[(!_vm.parentNode)?[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title'),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.chiudi_finestra()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi mdi-close ")])],1)]}}],null,false,3663616659)},[_c('span',[_vm._v("Chiudi Finestra")])])],1),(this.menu_name == 'Fattura Clienti')?_c('div',[_c('Documento',{attrs:{"VOCE_MENU":this.menu_name,"ID_MODULO":this.id_leaf}})],1):_vm._e(),(this.menu_name == 'DDT Clienti')?_c('div',[_c('Documento',{attrs:{"VOCE_MENU":this.menu_name,"ID_MODULO":this.id_leaf}})],1):_vm._e()]:[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("Nodo Padre")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.chiudi_finestra()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi Finestra")])])],1)]]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }