<template>
  <v-card>
    <v-toolbar
      class="white--text"
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title> Specifiche Tecniche </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="red--text"
        @click="clickChiudi"
        color="blue-grey lighten-5"
        small
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-2">
        <v-data-table
          :loading="loading"
          :items="items_disegno"
          :headers="headers_disegno"
        >
          <v-progress-linear
            v-show="progressBar"
            slot="progress"
            color="blue"
            indeterminate
          ></v-progress-linear>

          <!--<template v-slot:[`item.revisione_articolo`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  v-if="item.revisione_articolo >= 1"
                  >mdi-check-box-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
            </v-tooltip>
          </template>-->
          <template v-slot:[`item.dum`]="{ item }">
            {{ item.dum | formatDate }}
          </template>

          <template v-slot:[`item.disegno`]="{ item }">
            <!-- Card per le immagini. -->
            <v-card
              style="
                height: 40px !important;

                width: 60px !important;
                margin-right: -10rem !important;
              "
              v-if="!isPdf(item.disegno)"
            >
              <div v-viewer class="images clearfix">
                <img
                  style="
                    height: 40px !important;
                    display: block !important;

                    width: 60px !important;
                    margin-right: -10rem !important;
                  "
                  :src="item.disegno"
                  :lazy-src="item.disegno"
                  aspect-ratio="1"
                  class="grey lighten-2"
                />
              </div>
            </v-card>

            <!-- Card per i PDF -->
            <v-card
              @click="openPdf(item)"
              style="
                    height: 40px !important;
                    display: block !important;

                    width: 60px !important;
                    margin-right: -10rem !important;
                  "
              v-else
            >
              <div style="text-align: center">
                <v-spacer></v-spacer>
                <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
              </div>
            </v-card>
          </template>
        </v-data-table>

    <!-- Dialog di visualizzazione delle immagini raster -->
    <!--<v-dialog
      content-class="artDialog"
      persistent
      v-model="dialog1"
      width="1100"
    >
      <v-card>
        <v-card-title
          style="height: 1rem !important"
          class="headline blue-grey darken-3 ml-n2"
          primary-title
        >
          <h6 class="mt-n5 white--text">Allegati</h6>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="#c00000"
                @click="dialog1 = false"
                depressed
                class="mt-n4 mr-n6"
                >mdi-window-close</v-icon
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-card-title>
        <div v-viewer class="images clearfix">
          <img
            aspect-ratio="1"
            :src="ImageToShow"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          />
        </div>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>-->

    <!-- Dialog di visualizzazione delle immagini PDF -->
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <!-- <div ref="pdf">
        <pdf
          @loaded="onLoad"
          style="border: 1px solid black"
          :src="this.selectedPdf"
        ></pdf>
      </div> -->
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="height: 90vh !important; width: 90vw !important"
      >
      </iframe>
    </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
// import pdf from 'vue-pdf'
import panzoom from "panzoom";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);

export default {
  name: "VisualizzaDisegno",
  components: {
    // pdf
  },
  props: {
    visible: { type: Boolean, default: false },
    work_order_sel: { type: Object, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      ImageToShow: "",
      loading: false,
      progressBar: false,
      selectedPdf: "",

      // Modal
      dialog1: false,
      pdfdialog: false,

      // Array
      headers_disegno: [
        {
          value: "edit",
          sortable: false,
        },
        {
          text: "Numero Disegno",
          value: "cod_alternativo",
          sortable: false,
        },
        {
          text: "Revisione",
          value: "revisione_articolo",
          sortable: false,
        },
        {
          text: "Indice",
          value: "indice_modifica",
          sortable: false,
        },
        {
          text: "D.U.M",
          value: "dum",
          sortable: false,
        },
        {
          text: "Annotazioni",
          value: "annotazioni",
          sortable: false,
        },
        {
          text: "Allegati",
          value: "disegno",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      items_disegno: [],
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible == true) {
          this.progressBar = true;
          this.loading = true;
          let disegnoReq = {
            controller: "Magazzino",
            method: "POST",
            richiesta: {
              action: "getarticolidisegno",
              token: localStorage.getItem("user_token"),
              db_name: "quickproduction",
              id_utente: localStorage.getItem("id"),
              id_articolo: this.work_order_sel.id_articolo,
            },
          };

          this.items_disegno = [];
          this.$store.dispatch("api", disegnoReq).then((res) => {
            res.data.forEach((elem) => {
              this.items_disegno.push(elem);
            });
            this.progressBar = false;
            this.loading = false;
          });
        }
      },
    },
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi", this.work_order_sel);
    },
    getImage(item) {
      this.ImageToShow = item.disegno;
    },
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    onLoad() {
      panzoom(this.$refs.pdf);
    },
    openPdf(item) {
      console.log(item.disegno);
      if (item.disegno.startsWith("data:application/pdf")) {
        const byteCharacters = atob(item.disegno.substring(28));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        console.log(blobUrl);
        this.pdfdialog = true;
      }
    },
  },
};
</script>
<style scoped>
</style>