<template>
  <div
    id="container_generale"
    :style="{
      height: '85vh',
      marginTop: '5px',
      maxHeight: '100%',
      maxWidth: '100%',
      width: width,
      overflow: 'scroll',
    }"
  >
    <!-- Bottone per aprire il dialog delle impostazioni -->
    <v-toolbar color="indigo" dense>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="clickImpostazioni"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary"> mdi-cogs </v-icon>
          </v-btn>
        </template>
        <span>Impostazioni (CTRL+I)</span>
      </v-tooltip>

      <!-- Bottone di lock movimento verticale -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="lockHorizontalMovement"
            :color="lockedAscissa === true ? 'primary' : ''"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="lockedAscissa === true ? 'white' : 'primary'">
              mdi-arrow-vertical-lock
            </v-icon>
          </v-btn>
        </template>
        <span>Lock Ascissa (CTRL+A)</span>
      </v-tooltip>

      <!-- Bottone di lock movimento orizzontale -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="lockVerticalMovement"
            :color="lockedOrdinata === true ? 'primary' : ''"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon :color="lockedOrdinata === true ? 'white' : 'primary'">
              mdi-arrow-horizontal-lock
            </v-icon>
          </v-btn>
        </template>
        <span>Lock Ordinata (CTRL+O)</span>
      </v-tooltip>

      <!-- Bottone della griglia -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="switchGriglia"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary"> mdi-grid </v-icon>
          </v-btn>
        </template>
        <span>Visualizza Griglia (CTRL+G)</span>
      </v-tooltip>

      <!-- Bottone per aggiungere la porta -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            class="mr-5"
            @click="clickAggiungiPorta"
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary"> mdi-door-open </v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Porta</span>
      </v-tooltip>

      <!-- Bottone per aggiungere la finestra -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="clickAggiungiFinestra"
            class="mr-5"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary"> mdi-window-closed-variant </v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Finestra</span>
      </v-tooltip>

      <!-- Bottone per aggiungere le card informative -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="clickAggiungiInfocard"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary"> mdi-card-text </v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Infocard</span>
      </v-tooltip>

      <!-- Bottone di download -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="scarica"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="success"> mdi-download </v-icon>
          </v-btn>
        </template>
        <span>Scarica Disegno</span>
      </v-tooltip>

      <!-- Bottone di salvataggio -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="salva"
            small
            tile
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Disegno (CTRL+S)</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-btn @click="clickChiudiDisegno" small tile>
        <v-icon color="error"> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Canvas HTML sovrapposto all'SVG -->
    <div
      id="wrapper_svg"
      :style="{
        backgroundColor: 'red',
        display: 'inline-block',
        height: height,
        magrin: '0px',
        padding: '0px',
        position: 'relative',
        width: width,
        zIndex: 2,
      }"
    >
      <!-- Template del nodo rettangolo con angoli smussati -->
      <div
        @mousedown="startDragging($event, nodo)"
        @mouseup="endDragging($event)"
        @mousemove="dragNode($event)"
        @contextmenu="clickDestroNodo($event, nodo)"
        :id="'div_nodo_' + nodo.id_nodo"
        :key="nodo.id_nodo"
        :style="{
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: nodo.height + 'px',
          left: nodo.x1 + 'px',
          margin: 0 + 'px',
          maxHeight: nodo.height + 'px',
          maxWidth: nodo.width + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.y1 + 'px',
          width: nodo.width + 'px',
          zIndex: 3,
        }"
        v-for="nodo in nodi.filter((n) => n.categoria === 'ingombro')"
      >
        <!-- Corpo del nodo -->
        <div
          :style="{
            backgroundColor: 'white',
            borderRadius: '5px 5px 5px 5px',
            height: nodo.height + 'px',
            margin: '0px',
            maxHeight: nodo.height + 'px',
            maxWidth: nodo.width + 'px',
            padding: '0px',
            overflow: 'hidden',
            textAlign: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            width: nodo.width + 'px',
          }"
        >
          <div
            :style="{
              backgroundColor: 'white',
              border: nodo.stroke_width + 'px solid ' + nodo.stroke,
              borderRadius: '5px 5px 5px 5px',
              display: 'table-cell',
              height: nodo.height + 'px',
              maxHeight: nodo.height + 'px',
              maxWidth: nodo.width + 'px',
              overflow: 'hidden',
              textAlign: 'center',
              userSelect: 'none',
              verticalAlign: 'middle',
              width: nodo.width + 'px',
            }"
          >
            <span style="font-size: 13px; position: relative; top: -5px">
              {{ nodo.testo_nodo }}
            </span>
          </div>
        </div>
        <!-- Maniglia nord -->
        <div
          @mousedown.stop="startDrawing($event)"
          @mouseup.stop="stopDrawing($event)"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: nodo.width / 2 - 5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: -5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_linea'"
        ></div>
        <!-- Maniglia sud -->
        <div
          @mousedown.stop="startDrawing($event)"
          @mouseup.stop="stopDrawing($event)"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: nodo.width / 2 - 5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height - 5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_linea'"
        ></div>
        <!-- Maniglia ovest -->
        <div
          @mousedown.stop="startDrawing($event)"
          @mouseup.stop="stopDrawing($event)"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: -5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height / 2 - 5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_linea'"
        ></div>
        <!-- Maniglia est -->
        <div
          @mousedown.stop="startDrawing($event)"
          @mouseup.stop="stopDrawing($event)"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '5px 5px 5px 5px',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            height: 10 + 'px',
            left: nodo.width - 5 + 'px',
            margin: 0 + 'px',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height / 2 - 5 + 'px',
            userSelect: 'none',
            width: 10 + 'px',
          }"
          v-if="modalita_editing === 'aggiungi_linea'"
        ></div>
      </div>

      <!-- Template del nodo immagine -->
      <div
        @mousedown="startDragging($event, nodo)"
        @mouseup="endDragging($event)"
        @mousemove="dragNode($event)"
        @contextmenu="clickDestroNodo($event, nodo)"
        :id="'div_nodo_' + nodo.id_nodo"
        :key="nodo.id_nodo"
        :style="{
          backgroundImage: 'url(' + nodo.background_src + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: nodo.height + 'px',
          left: nodo.x1 + 'px',
          margin: 0 + 'px',
          maxHeight: nodo.height + 'px',
          maxWidth: nodo.width + 'px',
          padding: 0 + 'px',
          position: 'absolute',
          top: nodo.y1 + 'px',
          width: nodo.width + 'px',
          zIndex: 3,
        }"
        v-for="nodo in nodi.filter((n) => n.categoria === 'nodo_immagine')"
      >
        <!-- Titolo del nodo immagine -->
        <div
          @mousedown.stop="startDrawing($event)"
          @mouseup.stop="stopDrawing($event)"
          :style="{
            backgroundColor: 'white',
            border: '1px solid black',
            boxShadow: '2px 2px 4px #000000',
            display: 'inline-block',
            minHeight: 18 + 'px',
            left: 0 + 'px',
            lineHeight: 18 + 'px',
            margin: 0 + 'px',
            overflow: 'hidden',
            padding: 0 + 'px',
            position: 'absolute',
            top: nodo.height + 'px',
            userSelect: 'none',
            width: nodo.width + 'px',
          }"
        >
          <span
            style="font-size: 13px; height: 18px; position: relative; top: 0px"
          >
            {{ nodo.testo_nodo }}
          </span>
        </div>
      </div>

      <!-- Template del nodo infocard -->
      <div
        @dblclick="apriInfocardDialog(nodo)"
        @mousedown="startDragging($event, nodo)"
        @mouseup="endDragging($event)"
        @mousemove="dragNode($event)"
        @contextmenu="clickDestroNodo($event, nodo)"
        :id="'div_nodo_' + nodo.id_nodo"
        :key="nodo.id_nodo"
        :style="{
          backgroundColor: 'white',
          border: nodo.stroke_width + 'px solid ' + nodo.stroke,
          borderRadius: '5px 5px 5px 5px',
          boxShadow: '2px 2px 4px #000000',
          display: 'inline-block',
          height: nodo.height + 'px',
          left: nodo.x1 + 'px',
          margin: 0 + 'px',
          maxHeight: nodo.height + 'px',
          maxWidth: nodo.width + 'px',
          overflow: 'hidden',
          padding: 0 + 'px',
          position: 'absolute',
          textAlign: 'center',
          top: nodo.y1 + 'px',
          userSelect: 'none',
          width: nodo.width + 'px',
          verticalAlign: 'middle',
          zIndex: 3,
        }"
        v-for="nodo in nodi.filter(
          (n) => n.categoria.substring(0, 8) === 'infocard'
        )"
      >
        <GraphSVG
          name="sticker-check"
          :height="nodo.height - 5"
          style="color: #8bc34a"
          v-if="nodo.categoria === 'infocard_verde'"
          :width="nodo.width - 5"
        />
        <GraphSVG
          name="alert-octagon"
          :height="nodo.height - 5"
          style="color: #ff9800"
          v-if="nodo.categoria === 'infocard_gialla'"
          :width="nodo.width - 5"
        />
        <GraphSVG
          name="close-octagon"
          :height="nodo.height - 5"
          style="color: #ff5722"
          v-if="nodo.categoria === 'infocard_rossa'"
          :width="nodo.width - 5"
        />
        <GraphSVG
          name="info"
          :height="nodo.height - 5"
          style="color: #2196f3"
          v-if="nodo.categoria === 'infocard_info'"
          :width="nodo.width - 5"
        />
      </div>

      <!-- Canvas SVG che contiene gli oggetti vettoriali -->
      <svg
        class="ma-0 pa-0"
        @mousedown="startDrawing($event)"
        @mouseup="stopDrawing($event)"
        @contextmenu.stop="clickDestroSVG"
        :style="{
          backgroundColor: isc.dark_theme === true ? 'grey' : 'white',
          border: '1px solid black',
          display: 'inline-block',
          height: height,
          position: 'relative',
          width: width,
        }"
        id="main_canvas_svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <!-- Def che mi mettono a disposizione la griglia -->
        <defs>
          <!-- Inizio del pattern della griglia -->
          <pattern
            id="smallGrid"
            :width="isc.passo_griglia"
            :height="isc.passo_griglia"
            patternUnits="userSpaceOnUse"
          >
            <path
              :d="'M ' + isc.passo_griglia + ' 0 L 0 0 0 ' + isc.passo_griglia"
              fill="none"
              :stroke="isc.dark_theme === false ? 'gray' : 'white'"
              stroke-width="0.5"
            />
          </pattern>
          <pattern
            id="grid"
            :width="isc.passo_griglia * 10"
            :height="isc.passo_griglia * 10"
            patternUnits="userSpaceOnUse"
          >
            <rect
              :width="isc.passo_griglia * 10"
              :height="isc.passo_griglia * 10"
              fill="url(#smallGrid)"
            />
            <path
              :d="
                'M ' +
                isc.passo_griglia * 10 +
                ' 0 L 0 0 0 ' +
                isc.passo_griglia * 10
              "
              fill="none"
              :stroke="isc.dark_theme === false ? 'gray' : 'white'"
              stroke-width="1"
            />
          </pattern>
          <!-- Qui finise il pattern della griglia!! -->

          <!-- Qui inizia il pattern della porta sinistra -->
          <!-- Qui finisce il pattern della porta sinistra -->

          <!-- Qui inizia il pattern della porta destra -->
          <!-- Qui finisce il pattern della porta destra!! -->

          <!-- Qui inizia il pattern della porta doppia -->
          <!-- Qui finisce il pattern della porta doppia!! -->
        </defs>

        <!-- Richiamo il pattern della griglia -->
        <rect id="grid_rect" width="100%" height="100%" fill="url(#grid)" />
      </svg>
      <SvgStore />
    </div>

    <!-- Menu contestuale -->
    <v-card
      id="menu_contestuale"
      style="z-index: 99"
      v-show="menu_contestuale_visible"
      width="250px"
    >
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text">
          Menu Contestuale
        </v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-item
          @click="enterModEditAggiungiNodo('aggiungi_nodo')"
          v-if="bvc.bottone_aggiungi_nodo_visible === true"
        >
          <v-list-item-title> Aggiungi Ingombro </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditAggiungiNodo('aggiungi_immagine')"
          v-if="bvc.bottone_aggiungi_immagine_visible === true"
        >
          <v-list-item-title> Aggiungi Ingombro Custom </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditAggiungiLinea('aggiungi_linea')"
          v-if="bvc.bottone_aggiungi_linea_visible === true"
        >
          <v-list-item-title> Aggiungi Muro </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditTrascinaManiglia('trascina_maniglia')"
          v-if="bvc.bottone_trascina_maniglia_visible === true"
        >
          <v-list-item-title> Trascina Maniglia </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditTrascinaMuro('trascina_muro')"
          v-if="bvc.bottone_trascina_muro_visible === true"
        >
          <v-list-item-title> Trascina Muro </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditModificaNodo"
          v-if="bvc.bottone_modifica_nodo_visible === true"
        >
          <v-list-item-title> Modifica Oggetto </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="chiamaModuloEsterno"
          v-if="bvc.bottone_modulo_esterno_visible === true && nodo_sel.modulo != ''"
        >
          <v-list-item-title> Modulo Esterno </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="enterModEditRuotaPorta"
          v-if="bvc.bottone_ruota_porta_visible === true"
        >
          <v-list-item-title> Ruota Porta </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="clickEliminaNodo"
          v-if="bvc.bottone_elimina_nodo_visible === true"
        >
          <v-list-item-title> Elimina Oggetto </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- Dialog di modifica dei nodi -->
    <v-dialog v-model="dialog_modifica_nodo">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Configurazione Oggetto
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaModificheNodo" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr"
                @click="chiudiConfigurazioneIngombro"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pt-5" style="overflow-y: auto">
          <v-form>
            <v-container>
              <v-row
                align="center"
                justify="center"
                v-if="nodo_sel.categoria != 'nodo_immagine'"
              >
                <!-- Colonne per la x e la y -->
                <v-col cols="3">
                  <v-text-field
                    label="Ascissa 1"
                    type="number"
                    v-model="nodo_sel.x1"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Ordinata 1"
                    type="number"
                    v-model="nodo_sel.y1"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" v-if="nodo_sel.categoria == 'linea'">
                  <v-text-field
                    label="Ascissa 2"
                    type="number"
                    v-model="nodo_sel.x2"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" v-if="nodo_sel.categoria == 'linea'">
                  <v-text-field
                    label="Ordinata 2"
                    type="number"
                    v-model="nodo_sel.y2"
                  ></v-text-field>
                </v-col>

                <!-- Colonna per l'etichetta -->
                <v-col
                  cols="6"
                  v-if="
                    nodo_sel.categoria === 'ingombro' ||
                    nodo_sel.categoria === 'nodo_immagine' ||
                    nodo_sel.categoria.substring(0, 8) === 'infocard'
                  "
                >
                  <v-text-field label="Etichetta" v-model="nodo_sel.testo_nodo">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <!-- Colonna per lo spessore -->
                <v-col cols="3">
                  <v-text-field
                    label="Spessore"
                    type="number"
                    v-model="nodo_sel.stroke_width"
                  >
                  </v-text-field>
                </v-col>

                <!-- Colonna per il colore -->
                <v-col cols="3" v-if="nodo_sel.categoria">
                  Colore:
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-2" small v-bind="attrs" v-on="on">
                        <v-icon :color="nodo_sel.stroke"> mdi-palette </v-icon>
                      </v-btn>
                    </template>
                    <v-color-picker v-model="nodo_sel.stroke" hide-inputs>
                    </v-color-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Larghezza"
                    v-if="
                      nodo_sel.categoria == 'ingombro' ||
                      nodo_sel.categoria == 'nodo_immagine'
                    "
                    v-model="nodo_sel.width"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Altezza"
                    v-if="
                      nodo_sel.categoria == 'ingombro' ||
                      nodo_sel.categoria == 'nodo_immagine'
                    "
                    v-model="nodo_sel.height"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-autocomplete
                    @change="associaModulo"
                    :items="items_moduli"
                    item-text="des"
                    item-value="des"
                    label="Modulo Dinamico"
                    v-model="nodo_sel.modulo"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    @change="associaEtichetta"
                    :items="items_etichette"
                    :item-text="campo_select_etichette"
                    :item-value="campo_select_etichette"
                    label="Etichetta"
                    v-model="nodo_sel.testo_nodo"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di conferma dell'eliminazione del nodo -->
    <v-dialog v-model="conferma_elimina_nodo" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Eliminazione Nodo
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-5" style="overflow-y: auto">
          Confermi di voler cancellare l'elemento selezionato?
          <v-divider class="my-5"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="annullaEliminaNodo"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Annulla</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="confermaEliminaNodo"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di caricamento dei file immagine -->
    <v-dialog v-model="upload_file_visible" width="50%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Carica Immagine</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiUploadFile" small v-bind="attrs" v-on="on">
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="text-align: center" class="mt-5">
          <v-file-input
            accept="image/*"
            @change="
              uploadImage(img_upload_editor);
              chiudiUploadFile();
            "
            style="display: inline-block; width: 400px"
            type="file"
            v-model="img_upload_editor"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog delle impostazioni -->
    <v-dialog v-model="impostazioni_dialog_visible" width="75%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Impostazioni Strumenti
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="applicaModificheImpostazioniStrumenti"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Applica</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogImpostazioni"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <!-- Titolo della sezione Dati Generali -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_generale_visible')"
                fab
                x-small
              >
                <v-icon color="primary">
                  {{
                    menu_generale_visible === true
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </v-btn>
              Generale
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_generale_visible"
            >
              <!-- Colonna del titolo disegno -->
              <v-col cols="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Titolo Disegno"
                    v-model="isc.des_disegno_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <!-- Colonna della larghezza -->
              <v-col cols="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Larghezza (px)"
                    v-model="isc.larghezza_disegno_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <!-- colonna dell'altezza -->
              <v-col cols="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Altezza (px)"
                    v-model="isc.altezza_disegno_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <!-- Titolo della sezione "Griglia" -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_griglia_visible')"
                fab
                x-small
              >
                <v-icon color="primary">
                  {{
                    menu_griglia_visible === true
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </v-btn>
              Griglia
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_griglia_visible"
            >
              <!-- Colonna del tema -->
              <v-col cols="4" md="3">
                <div class="divAzzurrino">
                  <span class="mr-2">Tema scuro</span>
                  <v-simple-checkbox
                    color="primary"
                    style="display: inline-block"
                    v-model="isc.dark_theme_tmp"
                  ></v-simple-checkbox>
                </div>
              </v-col>

              <!-- Colonna del passo -->
              <v-col cols="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Passo (px)"
                    type="number"
                    v-model="isc.passo_griglia_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <!-- Colonna dello snap -->
              <v-col cols="4" md="3">
                <div class="divAzzurrino">
                  <span class="mr-2">Snap</span>
                  <v-simple-checkbox
                    color="primary"
                    style="display: inline-block"
                    v-model="isc.snap_allowed_tmp"
                  ></v-simple-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <!-- Titolo della sezione "Ingombri" -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_ingombri_visible')"
                fab
                x-small
              >
                <v-icon color="primary">{{
                  menu_ingombri_visible === true
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
              Ingombri
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_ingombri_visible === true"
            >
              <!-- Colonna per la larghezza -->
              <v-col cols="4" md="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Larghezza"
                    type="number"
                    v-model="isc.larghezza_nodo_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <!-- Colonna per l'altezza -->
              <v-col cols="4" md="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Altezza"
                    type="number"
                    v-model="isc.altezza_nodo_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <!-- Colonna per lo spessore -->
              <v-col cols="4" md="2">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Spessore"
                    type="number"
                    v-model="isc.spessore_nodo_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <!-- Colonna per l'etichetta-->
              <v-col cols="4" md="3">
                <div class="divAzzurrino">
                  <v-text-field label="Etichetta" v-model="isc.testo_nodo_tmp">
                  </v-text-field>
                </div>
              </v-col>

              <v-col cols="3">
                <div class="divAzzurrino">
                  Colore:
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-2" small v-bind="attrs" v-on="on">
                        <v-icon :color="isc.colore_nodo_tmp">
                          mdi-palette
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-color-picker v-model="isc.colore_nodo_tmp" hide-inputs>
                    </v-color-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <!-- Titolo della sezione "Muri" -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_linea_visible')"
                fab
                x-small
              >
                <v-icon color="primary">{{
                  menu_linea_visible === true
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
              Muri
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_linea_visible === true"
            >
              <!-- Colonna per lo spessore della linea -->
              <v-col cols="4" md="3">
                <div class="divAzzurrino">
                  <v-text-field
                    label="Spessore (px)"
                    type="number"
                    v-model="isc.spessore_linea_tmp"
                  >
                  </v-text-field>
                </div>
              </v-col>

              <v-col cols="3">
                <div class="divAzzurrino">
                  Colore:
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-2" small v-bind="attrs" v-on="on">
                        <v-icon :color="isc.colore_linea_tmp">
                          mdi-palette
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-color-picker v-model="isc.colore_linea_tmp" hide-inputs>
                    </v-color-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <!-- Titolo della sezione "Porte" -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_porte_visible')"
                fab
                x-small
              >
                <v-icon color="primary">
                  {{
                    menu_porte_visible === true
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </v-btn>
              Porte
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_porte_visible"
            >
              <!-- Colonna del tema -->
              <v-col cols="4" md="3"> </v-col></v-row
          ></v-container>

          <!-- Titolo della sezione "Finestre" -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_finestre_visible')"
                fab
                x-small
              >
                <v-icon color="primary">
                  {{
                    menu_finestre_visible === true
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </v-btn>
              Finestre
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_finestre_visible"
            >
              <!-- Colonna del tema -->
              <v-col cols="4" md="3"> </v-col></v-row
          ></v-container>

          <!-- Titolo della sezione "Infocard" -->
          <div
            style="
              border-bottom: 2px solid #2196f3;
              padding-bottom: 5px;
              padding-top: 5px;
              text-align: left;
            "
          >
            <h2>
              <v-btn
                class="mr-5"
                @click="switchMenuSection('menu_infocard_visible')"
                fab
                x-small
              >
                <v-icon color="primary">
                  {{
                    menu_infocard_visible === true
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}
                </v-icon>
              </v-btn>
              Infocard
            </h2>
          </div>
          <v-container>
            <v-row
              align="center"
              class="elevation-2 rowAzzurrino"
              justify="center"
              v-if="menu_infocard_visible"
            >
              <!-- Colonna del tema -->
              <v-col cols="4" md="3"> </v-col></v-row
          ></v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Mini dialog per aggiungere oggetti -->
    <v-dialog v-model="aggiungi_obj_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <!-- Bottone di aggiunta della porta sinistra -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="
                  enterModEditAggiungiPortaSinistra();
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_porta_sinistra_visible == true"
                v-on="on"
              >
                <v-icon color="primary">mdi-door</v-icon></v-btn
              >
            </template>
            <span>Porta Battente a Sinistra</span>
          </v-tooltip>

          <!-- Bottone di aggiunta della porta destra -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="
                  enterModEditAggiungiPortaDestra();
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_porta_destra_visible == true"
                v-on="on"
              >
                <v-icon color="primary"> mdi-door-closed </v-icon>
              </v-btn>
            </template>
            <span>Porta Battente a Destra</span>
          </v-tooltip>

          <!-- Bottone di aggiunta della porta doppia -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-10"
                @click="
                  enterModEditAggiungiPortaDoppia();
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_porta_doppia_visible == true"
                v-on="on"
                ><v-icon color="primary">mdi-window-open-variant</v-icon></v-btn
              >
            </template>
            <span>Porta a Doppio Battente</span>
          </v-tooltip>

          <!-- Bottone di aggiunta della card verde -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="
                  enterModEditAggiungiNodo('aggiungi_infocard_verde');
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_card_verde_visible == true"
                v-on="on"
                ><v-icon color="success">mdi-sticker-check</v-icon></v-btn
              >
            </template>
            <span>Infocard di Conferma</span>
          </v-tooltip>

          <!-- Bottone di aggiunta della card gialla -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="
                  enterModEditAggiungiNodo('aggiungi_infocard_gialla');
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_card_gialla_visible == true"
                v-on="on"
                ><v-icon color="orange">mdi-alert-octagon</v-icon></v-btn
              >
            </template>
            <span>Infocard di Avviso</span>
          </v-tooltip>

          <!-- Bottone di aggiunta della card rossa -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="
                  enterModEditAggiungiNodo('aggiungi_infocard_rossa');
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_card_rossa_visible == true"
                v-on="on"
                ><v-icon color="error">mdi-close-octagon</v-icon></v-btn
              >
            </template>
            <span>Infocard di Pericolo</span>
          </v-tooltip>

          <!-- Bottone di aggiunta della card info -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-10"
                @click="
                  enterModEditAggiungiNodo('aggiungi_infocard_info');
                  chiudiAggiungiOggettiDialog();
                "
                small
                v-bind="attrs"
                v-show="bvc.bottone_aggiungi_card_info_visible == true"
                v-on="on"
                ><v-icon color="primary">mdi-information-outline</v-icon></v-btn
              >
            </template>
            <span>Infocard Informativa</span>
          </v-tooltip>

          <!-- Bottone di chiusura del dialog -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiAggiungiOggettiDialog"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <!-- Dialog di messaggistica delle infocard -->
    <v-dialog v-model="infocard_dialog_visible" width="unset">
      <v-card>
        <v-card-text
          class="pt-5 px-5"
          :style="{
            backgroundColor:
              tipo_infocard_sel === 'infocard_verde'
                ? '#ccffcc'
                : tipo_infocard_sel === 'infocard_gialla'
                ? '#ffffcc'
                : tipo_infocard_sel === 'infocard_rossa'
                ? '#ffe6e6'
                : '#cce5ff',
            color: 'black',
            fontSize: '25px',
            overflowY: 'auto',
          }"
        >
          <div>{{ testo_infocard_dialog }}</div>
          <div class="mt-5" style="text-align: center">
            <v-btn @click="chiudiInfocardDialog">
              <v-icon color="success"> mdi-check </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="componente_dinamico_visible">
      <component
        class="ma-0 pa-0"
        :is="dynamic_component"
        :key="key_componente_dinamico"
        :props="nodo_sel.props"
        ref="componente_dinamico"
        @whoIsMyFather="IAmYourFather"
      />
    </v-dialog>

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <SnackbarQV ref="SnackbarQV" />
    <!--<div class="my-12">
      {{ nodo_sel }}
    </div>-->
  </div>
</template>
<script>
class ImpostazioniStrumentiController {
  campi = [
    { nome: "altezza_disegno", default_val: 500 },
    { nome: "altezza_infocard", default_val: 80 },
    { nome: "altezza_nodo", default_val: 100 },
    { nome: "colore_infocard", default_val: "#000000" },
    { nome: "colore_linea", default_val: "#000000" },
    { nome: "colore_nodo", default_val: "#000000" },
    { nome: "dark_theme", default_val: false },
    { nome: "des_disegno", default_val: "" },
    { nome: "larghezza_disegno", default_val: 500 },
    { nome: "larghezza_infocard", default_val: 80 },
    { nome: "larghezza_nodo", default_val: 100 },
    { nome: "passo_griglia", default_val: 8 },
    { nome: "snap_allowed", default_val: false },
    { nome: "spessore_linea", default_val: 2 },
    { nome: "spessore_infocard", default_val: 2 },
    { nome: "spessore_nodo", default_val: 2 },
    { nome: "testo_infocard", default_val: "" },
    { nome: "testo_nodo", default_val: "" },
  ];

  constructor(arg) {
    if (arg != undefined) {
      this.campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
        this[c.nome + "_tmp"] =
          arg[c.nome + "_tmp"] != undefined
            ? arg[c.nome + "_tmp"]
            : c.default_val;
      });
    } else {
      this.campi.forEach((c) => {
        this[c.nome] = c.default_val;
        this[c.nome + "_tmp"] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i valori
  reset() {
    this.campi.forEach((c) => {
      this[c.nome] = c.default_val;
      this[c.nome + "_tmp"] = c.default_val;
    });
  }

  /* Metodo per aggiornare i valori temporanei in base ai
   * valori stabili (serve in caso di modifica annullata). */
  updateStableToTmp() {
    this.campi.forEach((c) => {
      this[c.nome + "_tmp"] = this[c.nome];
    });
  }

  /* Metodo per aggiornare i valori stabili in base ai
   * valori temporanei (serve in caso di modifica confermata). */
  updateTmpToStable() {
    this.campi.forEach((c) => {
      this[c.nome] = this[c.nome + "_tmp"];
    });
  }
}

class ButtonVisibilityController {
  campi = [
    "bottone_aggiungi_card_gialla_visible",
    "bottone_aggiungi_card_info_visible",
    "bottone_aggiungi_card_rossa_visible",
    "bottone_aggiungi_card_verde_visible",
    "bottone_aggiungi_immagine_visible",
    "bottone_aggiungi_linea_visible",
    "bottone_aggiungi_nodo_visible",
    "bottone_aggiungi_porta_destra_visible",
    "bottone_aggiungi_porta_doppia_visible",
    "bottone_aggiungi_porta_sinistra_visible",
    "bottone_copia_nodo_visible",
    "bottone_elimina_nodo_visible",
    "bottone_modifica_nodo_visible",
    "bottone_modulo_esterno_visible",
    "bottone_ruota_porta_visible",
    "bottone_trascina_maniglia_visible",
    "bottone_trascina_muro_visible",
  ];

  constructor() {
    this.campi.forEach((c) => {
      this[c] = false;
    });
  }

  reset() {
    this.campi.forEach((c) => {
      this[c] = false;
    });
  }

  show(arg_string) {
    this[arg_string] = true;
  }
}

class FlowNode {
  background_src = "";
  bottom = { x: null, y: null };
  categoria = "no_cat";
  height = 100;
  id_nodo = -moment().valueOf();
  modulo = "";
  left = { x: null, y: null };
  path_componente = "";
  props = null;
  props_inizializzazione = [];
  props_url = null;
  right = { x: null, y: null };
  rotate = 0;
  stroke = "#000000";
  stroke_width = 0;
  testo_nodo = "";
  titolo_nodo = "";
  top = { x: null, y: null };
  url = "";
  width = 100;
  x1 = null;
  x2 = null;
  y1 = null;
  y2 = null;

  constructor(arg) {
    if (arg != undefined) {
      this.background_src =
        arg.background_src != undefined ? arg.background_src : "";
      this.bottom.x = arg.x1 + arg.width / 2;
      this.bottom.y = arg.y1 + arg.height;
      this.categoria = arg.categoria != undefined ? arg.categoria : "no_cat";
      this.height = arg.height != undefined ? arg.height : 100;
      this.id_nodo =
        arg.id_nodo != undefined ? arg.id_nodo : -moment().valueOf();
      this.modulo = arg.modulo != undefined ? arg.modulo : "";
      this.left.x = arg.x1 ? arg.x1 : null;
      this.left.y = arg.y1 && arg.height ? arg.y1 + arg.height / 2 : null;
      this.path_componente =
        arg.path_componente != undefined ? arg.path_componente : "";
      this.props = arg.props != undefined ? arg.props : null;
      this.props_inizializzazione =
        arg.props_inizializzazione != undefined
          ? arg.props_inizializzazione
          : [];
      this.props_url = arg.props_url != undefined ? arg.props_url : null;
      this.right.x = arg.x1 && arg.width ? arg.x1 + arg.width : null;
      this.right.y = arg.y1 && arg.height ? arg.y1 + arg.height / 2 : null;
      this.rotate = arg.rotate ? arg.rotate : 0;
      this.stroke = arg.stroke != undefined ? arg.stroke : "#000000";
      this.stroke_width = arg.stroke_width != undefined ? arg.stroke_width : 2;
      this.testo_nodo = arg.testo_nodo != undefined ? arg.testo_nodo : "";
      this.titolo_nodo = arg.titolo_nodo != undefined ? arg.titolo_nodo : "";
      this.top.x = arg.x1 && arg.width ? arg.x1 + arg.width / 2 : null;
      this.top.y = arg.y1 ? arg.y1 : null;
      this.url = arg.url != undefined ? arg.url : "";
      this.width = arg.width != undefined ? arg.width : 100;
      this.x1 = arg.x1 != undefined ? arg.x1 : null;
      this.x2 = arg.x2 != undefined ? arg.x2 : null;
      this.y1 = arg.y1 != undefined ? arg.y1 : null;
      this.y2 = arg.y2 != undefined ? arg.y2 : null;
    } else {
      this.background_src = "";
      this.bottom.x = null;
      this.bottom.y = null;
      this.categoria = "no_cat";
      this.height = 100;
      this.id_nodo = -moment().valueOf();
      this.modulo = "";
      this.left.x = null;
      this.left.y = null;
      this.path_componente = "";
      this.props = null;
      this.props_inizializzazione = [];
      this.props_url = null;
      this.right.x = null;
      this.right.y = null;
      this.rotate = 0;
      this.stroke = "#000000";
      this.stroke_width = 2;
      this.testo_nodo = "";
      this.titolo_nodo_flowchart = "";
      this.top.x = null;
      this.top.y = null;
      this.url = "";
      this.width = 100;
      this.x1 = null;
      this.x2 = null;
      this.y1 = null;
      this.y2 = null;
    }
  }
}

import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
import _ from "lodash";
import moment from "moment/moment";
import RASTER from "@/components/GRAPH/Generale/Componenti/Rasterize/Rasterize.js";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import SvgStore from "@/components/PLANT/SvgStore.vue";

export default {
  name: "Planchart",
  components: {
    Articolo: () => import("@/views/PRODUCTION/Articolo/Articolo.vue"),
    Macchina: () =>
      import("@/views/PRODUCTION/Macchine/UpdateVehicleDetail.vue"),
    GraphSVG,
    SnackbarQV,
    SvgStore,
  },
  props: {
    id_disegno: { type: Number, default: -1 },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      altezza_freccia: 10,
      campo_select_etichette: "",
      variabile_campo_prop_url: null,
      click_down_x: null,
      click_down_y: null,
      click_up_x: null,
      click_up_y: null,
      dynamic_component: "",
      height: "74vh",
      id_nodo_end: null,
      id_nodo_start: null,
      id_freccia_sel: null,
      img_upload_editor: null,
      isc: new ImpostazioniStrumentiController(),
      key_comp: "",
      key_componente_dinamico: 0,
      lockedAscissa: false,
      lockedOrdinata: false,
      modalita_editing: "click_atteso",
      nodo_sel: new FlowNode(),
      R2D: 180 / Math.PI,
      r: new RASTER(),
      testo_infocard_dialog: "",
      tipo_infocard_sel: "",
      width: "100%",

      // Modal
      aggiungi_obj_dialog: false,
      bvc: new ButtonVisibilityController(),
      componente_dinamico_visible: false,
      conferma_elimina_nodo: false,
      dialog_modifica_nodo: false,
      impostazioni_dialog_visible: false,
      infocard_dialog_visible: false,
      menu_contestuale_visible: false,
      menu_finestre_visible: false,
      menu_generale_visible: false,
      menu_griglia_visible: false,
      menu_infocard_visible: false,
      menu_ingombri_visible: false,
      menu_linea_visible: false,
      menu_porte_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      upload_file_visible: false,

      // Array
      nodi: new Array(),
      items_etichette: [
        /*{
          des: "LAV MECC",
          props: {},
          props_inizializzazione: [],
          props_url: 1,
          modulo: "Macchina",
        },
        {
          des: `musone ancora 40kg`,
          modulo: "Articolo",
          props: {},
          props_inizializzazione: [{ cod_articolo: "A00001090" }],
          props_url: undefined,
        },*/
      ],
      items_moduli: [
        /*{
          des: "Macchina",
          path: "@/views/PRODUCTION/Macchine/UpdateVehicleDetail.vue",
          url: "/home/production/:orderId",
        },
        {
          des: "Articolo",
          path: "@/views/PRODUCTION/Articolo/Articolo.vue",
          url: "/home/production/articolo",
        },*/
      ],
    };
  },
  computed: {
    // comp() {
    //   // return () => import(`@/views/NAVALREPAIR/GestioneSintetica.vue`);
    //   if (this.nodo_sel.path_componente === "") {
    //     return () => import(`@/views/NAVALREPAIR/GestioneSintetica.vue`);
    //   } else {
    //     return () => import(`${this.nodo_sel.path_componente.toString()}`);
    //   }
    // },
  },
  watch: {
    impostazioni_dialog_visible: {
      immediate: true,
      handler() {
        this.menu_finestre_visible = false;
        this.menu_generale_visible = false;
        this.menu_griglia_visible = false;
        this.menu_infocard_visible = false;
        this.menu_ingombri_visible = false;
        this.menu_linea_visible = false;
        this.menu_porte_visible = false;
      },
    },
    key_comp: {
      immediate: true,
      handler() {
        switch (this.key_comp) {
          case "ControlLeftKeyA":
            this.lockHorizontalMovement();
            break;
          case "ControlLeftKeyG":
            this.switchGriglia();
            break;
          case "ControlLeftKeyI":
            this.clickImpostazioni();
            break;
          case "ControlLeftKeyO":
            this.lockVerticalMovement();
            break;
          case "ControlLeftKeyS":
            this.salva();
            break;
          default:
            break;
        }
      },
    },
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.getDisegno();
        }
      },
    },
  },
  methods: {
    annullaEliminaNodo() {
      this.nodo_sel = new FlowNode();
      this.conferma_elimina_nodo = false;
    },
    applicaModificheImpostazioniStrumenti() {
      // Aggiorno i valori delle impostazioni strumenti in base ai valori temporanei.
      this.isc.updateTmpToStable();

      // Chiudo il dialog
      this.impostazioni_dialog_visible = false;
    },
    apriInfocardDialog(arg_n) {
      this.tipo_infocard_sel = arg_n.categoria;
      this.testo_infocard_dialog = arg_n.testo_nodo;
      this.infocard_dialog_visible = true;
    },
    associaEtichetta() {
      this.items_etichette.forEach((elem) => {
        if (elem[this.campo_select_etichette] === this.nodo_sel.testo_nodo) {
          // Valorizzo le prop di inizializzazione
          this.nodo_sel.props_inizializzazione.forEach((p) => {
            for (let campo in p) {
              p[campo] = elem[campo];
            }
          });

          // Valorizzo la prop da passare nella url
          if (this.variabile_campo_prop_url != null) {
            this.nodo_sel.props_url = elem[this.variabile_campo_prop_url];
          }
        }
      });
    },
    associaModulo() {
      this.items_moduli.forEach((elem) => {
        if (elem.des === this.nodo_sel.modulo) {
          console.log(elem);
          this.nodo_sel.url = elem.url;
          this.nodo_sel.path_componente = elem.path_componente;
          this.nodo_sel.props = elem.props != undefined ? elem.props : {};
          elem.props_inizializzate.forEach((p) => {
            let myprop = Object.assign({}, JSON.parse(p.obj));
            console.log(myprop);
            this.nodo_sel.props_inizializzazione.push(
              Object.assign({}, myprop)
            );
          });

          /* Registro il campo da ricercare nella response della
           * chiamata BE per ottneere i singoli elementi della select. */
          this.campo_select_etichette = elem.text;
          this.variabile_campo_prop_url = elem.props_url;

          let request = {
            controller: elem.controller,
            method: "POST",
            richiesta: {
              action: elem.action,
              token: localStorage.user_token,
            },
          };

          this.$store.dispatch("api", request).then((res) => {
            if (res.status === 200) {
              if (elem.response_detail == null) {
                this.items_etichette = res.data;
              } else {
                /* Il campo response_detail contiene una stringa che mi descrive il
                 * percorso dove devo andare a prendere i dati; se è un percorso
                 * complesso, lo computo e lo uso.*/
                let response_detail = new Array();
                let myres = res.data;

                if (
                  elem.response_detail != null &&
                  elem.response_detail.includes("|")
                ) {
                  response_detail = elem.response_detail.split("|");

                  for (let i in response_detail) {
                    myres = myres[i];
                  }
                } else {
                  // ... Altrimenti contiene un solo campo, e uso quello
                  myres = res.data[elem.response_detail];
                }
                this.items_etichette = myres;
              }
            } else {
              this.snackbar = true;
              this.snackbar_background = "orange";
              this.snackbar_icon = "mdi-alert";
              this.snackbar_icon_color = "white";
              this.snackbar_text = `Criticità rilevata rilevata: codice risposta ${res.status}.`;
              this.snackbar_text_color = "white";
            }
          });
        }
      });
    },
    cancellaElementoHTML() {
      this.nodi.forEach((n) => {
        if (n.id_nodo == this.nodo_sel.id_nodo) {
          n.validita = 0;
        }
      });
    },
    cancellaElementoSVG() {
      this.nodi.forEach((n) => {
        if (n.id_nodo == this.nodo_sel.id_nodo) {
          n.validita = 0;
        }
      });

      const el = document
        .getElementById("main_canvas_svg")
        .getElementById(this.nodo_sel.id_nodo);
      el.parentNode.removeChild(el);
    },
    checkDimensioniCanvas(arg_nodo) {
      let wrapper_svg = document.getElementById("wrapper_svg");

      /* Controllo se devo ridimensionare i canvas, prendendo come riferimento le dimensioni
       * dell'SVG. Nel caso debba ridimensionare, ridimensiono sulle variabili width e height
       * in modo da ridimensionare in un colpo solo il canvas SVG, HTML e il div contenitore generale.*/
      if (wrapper_svg.offsetWidth < arg_nodo.x1 + arg_nodo.width) {
        this.width = arg_nodo.x1 + arg_nodo.width + 50 + "px";
      }

      if (wrapper_svg.offsetHeight < arg_nodo.y1 + arg_nodo.height) {
        this.height = arg_nodo.y1 + arg_nodo.height + 50 + "px";
      }
    },
    chiamaModuloEsterno() {
      console.log(this.nodo_sel.path_componente);
      // Punto il componente dinamico sul modulo esterno del nodo selezionato.
      this.dynamic_component = this.nodo_sel.modulo;

      // Rendo visibile il dialog che contiene il componente esterno.
      this.componente_dinamico_visible = true;

      // Forzo la riesecuzione del mounted
      this.key_componente_dinamico = this.key_componente_dinamico === 0 ? 1 : 0;

      // Nascondo il menu contestuale e i suoi bottoni
      this.menu_contestuale_visible = false;
      this.bvc.reset;
    },
    chiudiAggiungiOggettiDialog() {
      // Chiudo il dialog.
      this.aggiungi_obj_dialog = false;

      // Resetto i bottoni dei menu contestuali.
      this.bvc.reset();
    },
    chiudiConfigurazioneIngombro() {
      // Chiudo il dialog di modifica del nodo
      this.dialog_modifica_nodo = false;

      // Resetto il nodo_sel
      this.nodo_sel = new FlowNode();

      // Torno in modalità click_atteso
      this.modalita_editing = "click_atteso";
    },
    chiudiDialogImpostazioni() {
      // Resetto i valori temporanei in base a quelli stabili.
      this.isc.updateStableToTmp();

      // Chiudo il dialog delle impostazioni.
      this.impostazioni_dialog_visible = false;
    },
    chiudiInfocardDialog() {
      this.infocard_dialog_visible = false;
    },
    chiudiUploadFile() {
      this.upload_file_visible = false;
    },
    clickAggiungiFinestra() {},
    clickAggiungiInfocard() {
      this.aggiungi_obj_dialog = true;
      this.bvc.reset();
      this.bvc.show("bottone_aggiungi_card_info_visible");
      this.bvc.show("bottone_aggiungi_card_gialla_visible");
      this.bvc.show("bottone_aggiungi_card_rossa_visible");
      this.bvc.show("bottone_aggiungi_card_verde_visible");
    },
    clickAggiungiPorta() {
      this.aggiungi_obj_dialog = true;

      this.bvc.show("bottone_aggiungi_porta_destra_visible");
      this.bvc.show("bottone_aggiungi_porta_doppia_visible");
      this.bvc.show("bottone_aggiungi_porta_sinistra_visible");
    },
    clickChiudiDisegno() {
      /* Emetto l'evento di chiusura del nodo, il componente
       * padre mi farà un aggiornamento della key e ricomputerà
       * il componente resettando i campi. */
      this.$emit("chiudi_disegno");
    },
    clickDestroNodo(e, nodo) {
      e.preventDefault();

      /* Se il menu è visibile e faccio un nuovo click
       * destro vuol dire che lo sto chiudendo. */
      if (this.menu_contestuale_visible === true) {
        // Nascondo il menu contestuale
        this.menu_contestuale_visible = false;

        // Rendo invisibili tutti i bottoni
        this.bvc.reset();
      } else {
        // Registro le coordiante del punto in cui ho clickato.
        this.click_down_x = e.clientX;
        this.click_down_y = e.clientY;
        this.click_up_x = e.clientX;
        this.click_up_y = e.clientY;

        // Valorizzo i modal dei bottoni che voglio / non voglio mostrare.
        this.bvc.reset();
        this.bvc.show("bottone_copia_nodo_visible");
        this.bvc.show("bottone_modulo_esterno_visible");
        this.bvc.show("bottone_elimina_nodo_visible");
        this.bvc.show("bottone_modifica_nodo_visible");

        // Rendo visibile il menu contestuale e lo posiziono.
        this.menu_contestuale_visible = true;
        document.getElementById("menu_contestuale").style.position = "absolute";
        document.getElementById("menu_contestuale").style.left =
          Math.round(e.clientX) -
          document.getElementById("main_canvas_svg").getBoundingClientRect().x +
          "px";
        document.getElementById("menu_contestuale").style.top =
          Math.round(e.clientY) -
          (document.getElementById("main_canvas_svg").getBoundingClientRect()
            .y -
            document.getElementById("general_app_bar").getBoundingClientRect()
              .height) +
          "px";

        // Copio il nodo che ho selezionato per un'eventuale modifica.
        this.nodo_sel = new FlowNode(nodo);
      }
    },
    clickDestroSVG(e) {
      e.preventDefault();

      /* Se la modalità non è click_atteso, nascondo i
       * bottoni del menu contestuale e non faccio nulla. */
      if (this.modalita_editing != "click_atteso") {
        this.bvc.reset();
        return;
      } else {
        // Se ho cliccato su una linea...
        if (e.composedPath()[0].tagName === "line") {
          // Seleziono l'oggetto corrispondente alla linea selezionata.
          this.nodi.forEach((n) => {
            if (n.id_nodo == e.composedPath()[0].getAttribute("id")) {
              this.nodo_sel = new FlowNode(n);
              return;
            }
          });

          // Abilito i bottoni del menu contestuale della linea.
          this.bvc.reset();
          this.bvc.show("bottone_modifica_nodo_visible");
          this.bvc.show("bottone_elimina_nodo_visible");
        }
        // Se ho cliccato su una qualsiasi porta...
        else if (
          e.path[10].className.baseVal === "porta_destra" ||
          e.path[11].className.baseVal === "porta_doppia" ||
          e.path[9].className.baseVal === "porta_doppia" ||
          e.path[9].className.baseVal === "porta_sinistra"
        ) {
          const id_sel =
            e.path[10].className.baseVal === "porta_destra"
              ? e.path[10].id
              : e.path[11].className.baseVal === "porta_doppia"
              ? e.path[11].id
              : e.path[9].className.baseVal === "porta_doppia"
              ? e.path[9].id
              : e.path[9].className.baseVal === "porta_sinistra"
              ? e.path[9].id
              : null;
          // Seleziono l'oggetto corrispondente alla porta selezionata.
          this.nodi.forEach((n) => {
            if (n.id_nodo == id_sel) {
              this.nodo_sel = n;
              return;
            }
          });
          // Imposto i bottoni del menu contestuale.
          this.bvc.reset();
          this.bvc.show("bottone_elimina_nodo_visible");
          this.bvc.show("bottone_modifica_nodo_visible");
          this.bvc.show("bottone_ruota_porta_visible");
        } else {
          /* Altrimenti ho cliccato su uno spazio libero, quindi
           * abilito i bottoni del menu contestuale generico. */
          this.bvc.reset();
          this.bvc.show("bottone_aggiungi_immagine_visible");
          this.bvc.show("bottone_aggiungi_linea_visible");
          this.bvc.show("bottone_aggiungi_nodo_visible");
          this.bvc.show("bottone_trascina_maniglia_visible");
          this.bvc.show("bottone_trascina_muro_visible");
        }

        /* In qualsiasi caso devo far vedere un menu, quindi
         * registro le coordiante del punto in cui ho cliccato.*/
        this.click_down_x = Math.round(e.clientX);
        this.click_down_y = Math.round(e.clientY);
        this.click_up_x = Math.round(e.clientX);
        this.click_up_y = Math.round(e.clientY);

        // Rendo visibile il menu contestuale e lo posiziono.
        this.menu_contestuale_visible = true;
        document.getElementById("menu_contestuale").style.position = "absolute";
        document.getElementById("menu_contestuale").style.left =
          Math.round(e.clientX) -
          document.getElementById("main_canvas_svg").getBoundingClientRect().x +
          "px";
        document.getElementById("menu_contestuale").style.top =
          Math.round(e.clientY) -
          (document.getElementById("main_canvas_svg").getBoundingClientRect()
            .y -
            document.getElementById("general_app_bar").getBoundingClientRect()
              .height) +
          "px";
      }
    },
    clickEliminaNodo() {
      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Apro il dialog di conferma.
      this.conferma_elimina_nodo = true;
    },
    clickImpostazioni() {
      this.impostazioni_dialog_visible = true;
    },
    confermaEliminaNodo() {
      // Riconosco la categoria dell'ingombro che devo eliminare.
      const cat = this.nodo_sel.categoria;
      console.log(cat);

      switch (cat) {
        case "infocard_gialla":
          {
            this.cancellaElementoHTML();
          }
          break;
        case "infocard_info":
          {
            this.cancellaElementoHTML();
          }
          break;
        case "infocard_rossa":
          {
            this.cancellaElementoHTML();
          }
          break;
        case "infocard_verde":
          {
            this.cancellaElementoHTML();
          }
          break;
        case "ingombro":
          {
            this.cancellaElementoHTML();
          }
          break;
        case "linea":
          {
            this.cancellaElementoSVG();
          }
          break;
        case "nodo_immagine":
          {
            this.cancellaElementoHTML();
          }
          break;
        case "porta_destra":
          {
            this.cancellaElementoSVG();
          }
          break;
        case "porta_doppia":
          {
            this.cancellaElementoSVG();
          }
          break;
        case "porta_sinisrta":
          {
            this.cancellaElementoSVG();
          }
          break;
        default:
          break;
      }

      // Resetto l'id del nodo selezionato.
      this.id_nodo_sel = null;

      // Chiudo il dialog di conferma.
      this.conferma_elimina_nodo = false;
    },
    dragNode(e) {
      // Eseguo le istruzioni solo se sono in drag.
      if (!this.stato_drag_nodo) {
        return;
      }

      /* Se effettivamente sto draggando, riassegno le
       * coordinate al nodo controllando se lo snap è attivo. */
      const dX =
        this.isc.snap_allowed === true
          ? Math.round(
              (e.clientX - this.wrapper_svg.x - this.offsetX) /
                this.isc.passo_griglia
            ) * this.isc.passo_griglia
          : e.clientX - this.wrapper_svg.x - this.offsetX;
      const dY =
        this.isc.snap_allowed === true
          ? Math.round(
              (e.clientY - this.wrapper_svg.y - this.offsetY) /
                this.isc.passo_griglia
            ) * this.isc.passo_griglia
          : e.clientY - this.wrapper_svg.y - this.offsetY;

      if (!this.lockedAscissa) this.nodo_sel.x1 = dX;

      if (!this.lockedOrdinata) this.nodo_sel.y1 = dY;
    },
    endDragging(e) {
      // Eseguo le istruzioni solo se sono in drag.
      if (!this.stato_drag_nodo) {
        return;
      }

      // Resetto la x e la y iniziali.
      this.click_down_x = null;
      this.click_down_y = null;

      // Riassegno un'ultima volta le coordinate al nodo
      const dX =
        this.isc.snap_allowed === true
          ? Math.round(
              (e.clientX - this.wrapper_svg.x - this.offsetX) /
                this.isc.passo_griglia
            ) * this.isc.passo_griglia
          : e.clientX - this.wrapper_svg.x - this.offsetX;
      const dY =
        this.isc.snap_allowed === true
          ? Math.round(
              (e.clientY - this.wrapper_svg.y - this.offsetY) /
                this.isc.passo_griglia
            ) * this.isc.passo_griglia
          : e.clientY - this.wrapper_svg.y - this.offsetY;

      if (!this.lockedAscissa) this.nodo_sel.x1 = dX;

      if (!this.lockedOrdinata) this.nodo_sel.y1 = dY;

      // Controllo se devo ridimensionare i canvas
      this.checkDimensioniCanvas(this.nodo_sel);

      /* Elimino la variabile per lo stato del nodo, che
       * tanto poi verrà ripristinata al prossimo drag. */
      delete this.stato_drag_nodo;

      // Resetto il nodo selezionato.
      this.nodo_sel = new FlowNode();

      /* Cancello l'offset e la variabile di supporto provvisoria
       * per il wrapper svg, tanto le ricreo quando ricomincio a draggare. */
      delete this.offsetX;
      delete this.offsetY;
      delete this.wrapper_svg;
    },
    enterModEditAggiungiLinea() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_freccia.
      this.modalita_editing = "aggiungi_linea";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();
    },
    enterModEditAggiungiNodo(text) {
      /* Questo metodo serve per entrare in modalità
       * di aggiunta nodi di vari tipi: rettangolari,
       * immagini, o altro. La tipologia specifica
       * è identificata dall'argomento del metodo. */

      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_nodo.
      this.modalita_editing = text;

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Stampo una snackbar di prompt.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere l'elemento.";
      this.snackbar_text_color = "white";
    },
    enterModEditAggiungiPortaDestra() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_porta_destra.
      this.modalita_editing = "aggiungi_porta_destra";

      // Stampo una snackbar di prompt.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere l'elemento.";
      this.snackbar_text_color = "white";
    },
    enterModEditAggiungiPortaDoppia() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_porta_doppia.
      this.modalita_editing = "aggiungi_porta_doppia";

      // Stampo una snackbar di prompt.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere l'elemento.";
      this.snackbar_text_color = "white";
    },
    enterModEditAggiungiPortaSinistra() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_porta_sinistra.
      this.modalita_editing = "aggiungi_porta_sinistra";

      // Stampo una snackbar di prompt.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere l'elemento.";
      this.snackbar_text_color = "white";
    },
    enterModEditCopiaNodo() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità
      this.modalita_editing = "copia_nodo";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Clicca nel punto in cui vuoi aggiungere il nodo.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Clicca nel punto in cui aggiungere il nodo.";
      this.snackbar_text_color = "white";
    },
    enterModEditModificaNodo() {
      // Setto la modalità a "modifica_nodo"
      this.modalita_editing = "modifica_nodo";

      // Apro il dialog per la modifica.
      this.dialog_modifica_nodo = true;

      // Chiudo il menu contestuale e tutti i suoi pulsanti.
      this.menu_contestuale_visible = false;
      this.bvc.reset();
    },
    enterModEditRuotaPorta() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità aggiungi_nodo.
      this.modalita_editing = "ruota_porta";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();

      // Stampo una snackbar di prompt.
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Dragga per ruotare l'elemento.";
      this.snackbar_text_color = "white";
    },
    enterModEditTrascinaMuro() {
      // Resetto le coordinate che mi ero segnato.
      this.click_down_x = null;
      this.click_down_y = null;
      this.click_up_x = null;
      this.click_up_y = null;

      // Entro in modalità trascina muro.
      this.modalita_editing = "trascina_muro";

      // Nascondo il menu contestuale.
      this.menu_contestuale_visible = false;

      // Rendo invisibili i suoi bottoni
      this.bvc.reset();
    },
    fileDownload(downloadUrl, downloadName) {
      let aLink = document.createElement("a");
      aLink.href = downloadUrl;
      aLink.download = `${downloadName}.JPEG`;
      // Trigger click-then remove
      aLink.click();
    },
    getDisegno() {
      /* Se l'id_disegno è -1 (perché sto aprendo per la prima volta
       * un nuovo disegno), non faccio la chiamata perché sarebbe inutile. */
      if (this.id_disegno == -1) return;

      let request = {
        controller: "Plant",
        method: "POST",
        richiesta: {
          action: "getdisegno",
          token: localStorage.user_token,
          id_disegno: this.id_disegno,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Aggiorno il titolo del disegno
          this.isc.des_disegno = res.data.result.disegno[0].des_disegno;
          this.isc.des_disegno_tmp = res.data.result.disegno[0].des_disegno;

          // Resetto l'array dei nodi.
          this.nodi = new Array();

          res.data.result.disegno[0].nodi.forEach((n) => {
            /* Mutuo un nodo FE dal nodo del BE, che
             * gli somiglia ma non è proprio uguale. */
            let n_tmp = new FlowNode(n);

            // Aggiusto i campi che mi servono
            n_tmp.bottom.x = n.bottom_x;
            n_tmp.bottom.y = n.bottom_y;
            n_tmp.left.x = n.left_x;
            n_tmp.left.y = n.left_y;
            n_tmp.right.x = n.right_x;
            n_tmp.right.y = n.right_y;
            n_tmp.top.x = n.top_x;
            n_tmp.top.y = n.top_y;

            // Aggiungo il nodo aggiustato ai nodi.
            this.nodi.push(n_tmp);

            // Se è una linea la renderizzo sull'svg.
            if (n_tmp.categoria === "linea") {
              let svg = document.getElementById("main_canvas_svg");
              let line = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "line"
              );

              line.setAttribute("id", n_tmp.id_nodo);
              line.setAttribute("x1", n_tmp.x1);
              line.setAttribute("y1", n_tmp.y1);
              line.setAttribute("x2", n_tmp.x2);
              line.setAttribute("y2", n_tmp.y2);
              line.style.stroke = n_tmp.stroke;
              line.style.strokeWidth = n_tmp.stroke_width;

              svg.appendChild(line);
            }
          });
        }
      });
    },
    getModuliDinamici() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getcomponenti",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.items_moduli = Array.from(res.data.result.componenti);
        }
      });
    },
    handleAggiungiImmagine(arg_obj) {
      // Creo una variabile provvisoria per tenermi i dati del nodo provvisorio.
      this.nodo_sel = arg_obj;

      // Apro il dialog di upload
      this.upload_file_visible = true;
    },
    lockHorizontalMovement() {
      this.lockedAscissa = !this.lockedAscissa;
      if (this.lockedOrdinata === true) this.lockedOrdinata = false;
    },
    lockVerticalMovement() {
      this.lockedOrdinata = !this.lockedOrdinata;
      if (this.lockedAscissa === true) this.lockedAscissa = false;
    },
    IAmYourFather(arg = undefined) {
      // Passo le eventuali props da URL
      console.log(this.nodo_sel.props_url);
      this.$refs.componente_dinamico.$route.params.orderId = this.nodo_sel.props_url;

      // Passo le eventuali props standard
      for (let prop in this.nodo_sel.props) {
        this.$refs.componente_dinamico.$props[prop] = _.cloneDeep(
          this.nodo_sel.props[prop]
        );
      }

      // Eseguo l'inizializzazione forzata come specificato dal figlio, se è necessario.
      arg != undefined
        ? arg.customHandler(this.nodo_sel.props_inizializzazione)
        : false;
    },
    moveDrawing(e) {
      switch (this.modalita_editing) {
        case "aggiungi_linea":
          {
            // Registro le coordinate del mouse che viene spostato.
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX -
                      document
                        .getElementById("main_canvas_svg")
                        .getBoundingClientRect().x) /
                      this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX -
                  document
                    .getElementById("main_canvas_svg")
                    .getBoundingClientRect().x;

            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY -
                      document
                        .getElementById("main_canvas_svg")
                        .getBoundingClientRect().y) /
                      this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY -
                  document
                    .getElementById("main_canvas_svg")
                    .getBoundingClientRect().y;

            if (!this.lockedAscissa) this.click_up_x = dX;
            if (!this.lockedOrdinata) this.click_up_y = dY;

            this.drawingLine.setAttribute("x1", Math.round(this.click_down_x));
            this.drawingLine.setAttribute("y1", Math.round(this.click_down_y));
            this.drawingLine.setAttribute("x2", this.click_up_x);
            this.drawingLine.setAttribute("y2", this.click_up_y);
            this.drawingLine.style.stroke = this.isc.colore_linea;
            this.drawingLine.style.strokeWidth = this.isc.spessore_linea;
          }
          break;
        case "drag_vettoriale":
          {
            /* Se sto draggando un oggetto vettoriale, riassegno le coordinate
             * al nodo e all'oggetto controllando se lo snap è attivo. */
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX - this.svg.x) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX - this.svg.x;
            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY - this.svg.y) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY - this.svg.y;

            // Aggiorno le coordinate del nodo
            if (!this.lockedAscissa) this.nodo_sel.x1 = dX;

            if (!this.lockedOrdinata) this.nodo_sel.y1 = dY;

            /* Aggiorno le coordinate dell'oggetto vettoriale facendo
             * riferimento alle coordinate dell'oggetto appena aggiornato. */
            this.vett_sel.setAttribute(
              "transform",
              `translate(${this.nodo_sel.x1}, ${this.nodo_sel.y1}) rotate(${this.nodo_sel.rotate})`
            );
          }
          break;
        case "ruota_porta":
          {
            // Trasformo i radianti in gradi per ruotare la porta
            this.x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x -
              this.center.x;
            this.y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y -
              this.center.y;
            const d = this.R2D * Math.atan2(this.y, this.x);

            // Ruoto la porta della debita quantità di gradi.
            this.porta_rotante.setAttribute(
              "transform",
              `translate(${this.nodo_sel.x1},${this.nodo_sel.y1}) rotate(${d})`
            );

            // Segno i gradi di rotazione dentro l'oggetto logico.
            this.nodo_sel.rotate = d;
          }
          break;
        case "trascina_muro":
          {
            /* Se sto draggando un muro, riassegno le coordinate del nodo (logico)
             *  e all'oggetto di template controllando se lo snap è attivo. */
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX - this.svg.x) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX - this.svg.x;
            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY - this.svg.y) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY - this.svg.y;

            // Aggiorno le coordinate del nodo
            if (!this.lockedAscissa) this.nodo_sel.x1 = dX;
            if (!this.lockedOrdinata) this.nodo_sel.y1 = dY;
            if (!this.lockedOrdinata) this.nodo_sel.x2 = dX + this.diff_x;
            if (!this.lockedOrdinata) this.nodo_sel.y2 = dY + this.diff_y;

            /* Aggiorno le coordinate dell'oggetto vettoriale facendo
             * riferimento alle coordinate dell'oggetto appena aggiornato. */
            this.vett_sel.setAttribute("x1", this.nodo_sel.x1);
            this.vett_sel.setAttribute("x2", this.nodo_sel.x2);
            this.vett_sel.setAttribute("y1", this.nodo_sel.y1);
            this.vett_sel.setAttribute("y2", this.nodo_sel.y2);
          }
          break;
        default:
          break;
      }
    },
    resetStringBuild() {
      this.key_comp = "";
      delete this.last_key;
    },
    salva() {
      let nodi_tmp = Array.from(this.nodi);

      nodi_tmp.forEach((e) => {
        e.id_nodo = e.id_nodo < 0 ? -1 : e.id_nodo;
        e.id_disegno = this.id_disegno;
        e.bottom_x = e.bottom.x;
        e.bottom_y = e.bottom.y;
        e.left_x = e.left.x;
        e.left_y = e.left.y;
        e.right_x = e.right.x;
        e.right_y = e.right.y;
        e.top_x = e.top.x;
        e.top_y = e.top.y;

        delete e.bottom;
        delete e.left;
        delete e.right;
        delete e.top;
      });

      let request = {
        controller: "Plant",
        method: "PUT",
        richiesta: {
          action: "setdisegno",
          token: localStorage.user_token,
          disegno: [
            {
              des_disegno: this.isc.des_disegno,
              id_disegno: this.id_disegno,
              nodi: nodi_tmp,
            },
          ],
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          console.log("La chiamata è andata a buon fine!!");
        } else {
          console.log("La chiamata non è andata a buon fine...");
        }
      });
    },
    salvaModificheNodo() {
      if (this.nodi.length === 0) {
        // Se non ho ancora aggiunto un nodo, lo aggiungo ora
        this.nodi.push(this.nodo_sel);
        this.dialog_modifica_nodo = false;

        // Controllo se devo ridimensionare i canvas
        this.checkDimensioniCanvas(this.nodo_sel);

        // Resetto il nodo_sel
        this.nodo_sel = new FlowNode();

        // Torno in modalità click_atteso
        this.modalita_editing = "click_atteso";
      } else {
        let index = null;
        // Cerco un match di id.
        for (let i = 0; i < this.nodi.length; i++) {
          if (this.nodi[i].id_nodo == this.nodo_sel.id_nodo) {
            index = i;
          }
        }

        // Se ho trovato una corrispondenza vedo il tipo del nodo.
        if (index != null) {
          // Prendo il riferimento della categoria.
          const cat = this.nodo_sel.categoria;
          switch (cat) {
            case "ingombro":
              // ... Modifico il nodo se è un ingombro.
              this.nodi.splice(index, 1, this.nodo_sel);
              break;
            case "linea":
              {
                // Prendo il riferimento dell'svg
                let svg = document.getElementById("main_canvas_svg");

                // Elimino il vecchio elemento svg
                const el = svg.getElementById(this.nodo_sel.id_nodo);
                el.parentNode.removeChild(el);

                // Renderizzo il nuovo elemento svg.
                let line = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "line"
                );

                line.setAttribute("id", this.nodo_sel.id_nodo);
                line.setAttribute("x1", this.nodo_sel.x1);
                line.setAttribute("y1", this.nodo_sel.y1);
                line.setAttribute("x2", this.nodo_sel.x2);
                line.setAttribute("y2", this.nodo_sel.y2);
                line.style.stroke = this.nodo_sel.stroke;
                line.style.strokeWidth = this.nodo_sel.stroke_width;

                svg.appendChild(line);

                // Aggiorno i nodi.
                this.nodi.splice(index, 1, this.nodo_sel);
              }
              break;
            case "nodo_immagine":
              // ... Modifico il nodo se è un nodo immagine.
              this.nodi.splice(index, 1, this.nodo_sel);
              break;
            default:
              break;
          }

          // Chiudo il dialog e interrompo l'esecuzione del metodo.
          this.dialog_modifica_nodo = false;

          // Resetto il nodo_sel
          this.nodo_sel = new FlowNode();

          // Torno in modalità click_atteso
          this.modalita_editing = "click_atteso";
          return;
        } else {
          /* Altrimenti ci sono dei nodi ma questo è nuovo,
           * quindi aggiungo il nodo poi chiudo il dialog. */
          this.nodi.push(this.nodo_sel);
          this.dialog_modifica_nodo = false;

          // Controllo se devo ridimensionare i canvas
          this.checkDimensioniCanvas(this.nodo_sel);

          // Resetto il nodo_sel
          this.nodo_sel = new FlowNode();

          // Torno in modalità click_atteso
          this.modalita_editing = "click_atteso";
        }
      }
    },
    scarica() {
      // Prendo il wrapper HTML
      const wrapper = document.getElementById("wrapper_svg");

      // Definisco il titolo
      const titolo = "img_" + moment().valueOf();
      // Chiamo il rasterize
      this.r.toDataURL(
        wrapper,
        (a) => {
          this.fileDownload(a, titolo);
        },
        "auto",
        "auto",
        1,
        "JPEG"
      );
    },
    startDragging(e, nodo) {
      /* Se il click viene dal bottone destro
       * non sto draggando e non faccio nulla. */
      if (e.which === 3) {
        return;
      }

      // Salvo l'offset del mouse rispetto al blocchetto;
      const rect_nodo = document
        .getElementById("div_nodo_" + nodo.id_nodo)
        .getBoundingClientRect();

      this.offsetX = e.clientX - rect_nodo.x;
      this.offsetY = e.clientY - rect_nodo.y;

      this.stato_drag_nodo = true;
      this.nodo_sel = nodo;

      /* Mi segno i dati del wrapper e li terrò in memoria
       * finché non finisco di draggare, poi cancello la
       * variabile di supporto. */
      this.wrapper_svg = document
        .getElementById("wrapper_svg")
        .getBoundingClientRect();
    },
    startDrawing(e) {
      /* Se ho cliccato col tasto destro non faccio niente
       * perché questa logica serve solo per il sinistro. */
      if (e.which === 3) {
        return;
      }

      // Se ho cliccato su qualsiasi porta, comincio a draggarla...
      if (
        (e.path[10].className.baseVal === "porta_destra" ||
          e.path[9].className.baseVal === "porta_doppia" ||
          e.path[9].className.baseVal === "porta_sinistra" ||
          e.path[11].className.baseVal === "porta_doppia") &&
        this.modalita_editing === "click_atteso"
      ) {
        // Seleziono l'oggetto corrispondente alla porta selezionata.
        const id_sel =
          e.path[10].className.baseVal === "porta_destra"
            ? e.path[10].id
            : e.path[11].className.baseVal === "porta_doppia"
            ? e.path[11].id
            : e.path[9].className.baseVal === "porta_doppia"
            ? e.path[9].id
            : e.path[9].className.baseVal === "porta_sinistra"
            ? e.path[9].id
            : null;
        this.nodi.forEach((n) => {
          if (n.id_nodo == id_sel) {
            // Mi faccio un puntatore all'oggetto nei nodi.
            this.nodo_sel = n;

            // Mi faccio un puntatore all'oggetto vettoriale
            this.vett_sel = document.getElementById(n.id_nodo);

            this.offsetX = e.clientX - this.vett_sel.getBoundingClientRect().x;
            this.offsetY = e.clientY - this.vett_sel.getBoundingClientRect().y;

            this.modalita_editing = "drag_vettoriale";

            /* Mi segno i dati dell'svg e li terrò in memoria
             * finché non finisco di draggare, poi cancello la
             * variabile di supporto. */
            this.svg = document
              .getElementById("main_canvas_svg")
              .getBoundingClientRect();

            // Attivo il listener del drag vettoriale sull'svg
            document
              .getElementById("main_canvas_svg")
              .addEventListener("mousemove", this.moveDrawing);
            return;
          }
        });
      }

      /* Se non sto aggiungendo nulla e non sto draggando, non faccio nulla ma
       * rimetto invisibile il menu contestuale nel caso fosse stato aperto. */
      if (
        this.modalita_editing != "aggiungi_immagine" &&
        this.modalita_editing != "aggiungi_infocard_gialla" &&
        this.modalita_editing != "aggiungi_infocard_info" &&
        this.modalita_editing != "aggiungi_infocard_rossa" &&
        this.modalita_editing != "aggiungi_infocard_verde" &&
        this.modalita_editing != "aggiungi_linea" &&
        this.modalita_editing != "aggiungi_nodo" &&
        this.modalita_editing != "aggiungi_porta_destra" &&
        this.modalita_editing != "aggiungi_porta_doppia" &&
        this.modalita_editing != "aggiungi_porta_sinistra" &&
        this.modalita_editing != "copia_nodo" &&
        this.modalita_editing != "ruota_porta" &&
        this.modalita_editing != "trascina_muro"
      ) {
        this.menu_contestuale_visible = false;

        // Rendo invisibili tutti i bottoni del menu
        this.bvc.reset();
        return;
      }

      switch (this.modalita_editing) {
        // Se sto aggiungendo un'immagine...
        case "aggiungi_immagine":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            // Genero la rappresentazione logica del nuovo nodo.
            let nuovo_nodo_img = new FlowNode();
            nuovo_nodo_img.x1 = this.click_down_x;
            nuovo_nodo_img.y1 = this.click_down_y;

            this.handleAggiungiImmagine(nuovo_nodo_img);

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        // Se sto aggiungendo una linea...
        case "aggiungi_infocard_gialla":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            // Genero la rappresentazione logica del nuovo nodo.
            let n = new FlowNode();
            n.categoria = "infocard_gialla";
            n.height = parseInt(this.isc.altezza_infocard);
            n.stroke = this.isc.colore_infocard;
            n.stroke_width = this.isc.spessore_infocard;
            n.testo_nodo = this.isc.testo_infocard;
            n.width = parseInt(this.isc.larghezza_infocard);
            n.x1 = this.click_down_x;
            n.y1 = this.click_down_y;

            /* Seleziono il nodo appena creato
             * e apro il dialog di modifica. */
            this.nodo_sel = new FlowNode(n);
            this.enterModEditModificaNodo();

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        case "aggiungi_infocard_info":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            // Genero la rappresentazione logica del nuovo nodo.
            let n = new FlowNode();
            n.categoria = "infocard_info";
            n.height = parseInt(this.isc.altezza_infocard);
            n.stroke = this.isc.colore_infocard;
            n.stroke_width = this.isc.spessore_infocard;
            n.testo_nodo = this.isc.testo_infocard;
            n.width = parseInt(this.isc.larghezza_infocard);
            n.x1 = this.click_down_x;
            n.y1 = this.click_down_y;

            /* Seleziono il nodo appena creato
             * e apro il dialog di modifica. */
            this.nodo_sel = new FlowNode(n);
            this.enterModEditModificaNodo();

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        case "aggiungi_infocard_rossa":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            // Genero la rappresentazione logica del nuovo nodo.
            let n = new FlowNode();
            n.categoria = "infocard_rossa";
            n.height = parseInt(this.isc.altezza_infocard);
            n.stroke = this.isc.colore_infocard;
            n.stroke_width = this.isc.spessore_infocard;
            n.testo_nodo = this.isc.testo_infocard;
            n.width = parseInt(this.isc.larghezza_infocard);
            n.x1 = this.click_down_x;
            n.y1 = this.click_down_y;

            /* Seleziono il nodo appena creato
             * e apro il dialog di modifica. */
            this.nodo_sel = new FlowNode(n);
            this.enterModEditModificaNodo();

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        case "aggiungi_infocard_verde":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            // Genero la rappresentazione logica del nuovo nodo.
            let n = new FlowNode();
            n.categoria = "infocard_verde";
            n.height = parseInt(this.isc.altezza_infocard);
            n.stroke = this.isc.colore_infocard;
            n.stroke_width = this.isc.spessore_infocard;
            n.testo_nodo = this.isc.testo_infocard;
            n.width = parseInt(this.isc.larghezza_infocard);
            n.x1 = this.click_down_x;
            n.y1 = this.click_down_y;

            /* Seleziono il nodo appena creato
             * e apro il dialog di modifica. */
            this.nodo_sel = new FlowNode(n);
            this.enterModEditModificaNodo();

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        case "aggiungi_linea":
          {
            /* Registro la posizione del click e inizio a valorizzare
             * le variabili di appoggio che mi serviranno per i
             * parametri della linea, tenendo conto dell'eventuale snap. */
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX -
                      document
                        .getElementById("main_canvas_svg")
                        .getBoundingClientRect().x) /
                      this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX -
                  document
                    .getElementById("main_canvas_svg")
                    .getBoundingClientRect().x;

            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY -
                      document
                        .getElementById("main_canvas_svg")
                        .getBoundingClientRect().y) /
                      this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY -
                  document
                    .getElementById("main_canvas_svg")
                    .getBoundingClientRect().y;

            if (!this.lockedAscissa) this.click_down_x = dX;
            if (!this.lockedOrdinata) this.click_down_y = dY;
            if (!this.lockedAscissa) this.click_up_x = dX;
            if (!this.lockedOrdinata) this.click_up_y = dY;

            // Aggiungo una linea all'SVG.
            let svg = document.getElementById("main_canvas_svg");

            let line = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line"
            );

            svg.appendChild(line);

            this.drawingLine = line;

            // Aggiungo un event listener per il movimento del mouse sulla superficie.
            document
              .getElementById("main_canvas_svg")
              .addEventListener("mousemove", this.moveDrawing);
          }
          break;
        // Se sto aggiungendo una porta...
        case "aggiungi_porta_destra":
          {
            /* Registro la posizione del click e inizio
             * a valorizzare le variabili di appoggio che
             * mi serviranno per i parametri della linea. */

            this.click_down_x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x;
            this.click_down_y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y;
            this.click_up_x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x;
            this.click_up_y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y;

            // Innesto la porta nell'SVG
            let svg = document.getElementById("main_canvas_svg");
            let my_id = moment().valueOf();
            let porta_template = document.getElementsByClassName(
              "porta_destra_template"
            )[0];
            let porta = porta_template.cloneNode(true);

            porta.setAttribute(
              "transform",
              `translate(${this.click_down_x}, ${this.click_down_y}) rotate(0)`
            );
            porta.setAttribute("transform-origin", `50 50`);
            porta.setAttribute("id", my_id);
            porta.setAttribute("class", "porta_destra");
            porta.style.display = "block";
            svg.appendChild(porta);

            // Mi genero un oggetto nei nodi che mi rappresenta la porta.
            let fn = new FlowNode();
            fn.categoria = "porta_destra";
            fn.id_nodo = my_id;
            fn.x1 = this.click_down_x;
            fn.y1 = this.click_down_y;
            fn.width = 100;
            fn.height = 100;
            this.nodi.push(fn);

            this.modalita_editing = "click_atteso";
          }
          break;
        case "aggiungi_porta_doppia":
          {
            /* Registro la posizione del click e inizio
             * a valorizzare le variabili di appoggio che
             * mi serviranno per i parametri della linea. */

            this.click_down_x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x;
            this.click_down_y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y;
            this.click_up_x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x;
            this.click_up_y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y;

            // Innesto la porta nell'SVG
            let svg = document.getElementById("main_canvas_svg");
            let my_id = moment().valueOf();
            let porta_template = document.getElementsByClassName(
              "porta_doppia_template"
            )[0];
            let porta = porta_template.cloneNode(true);

            porta.setAttribute(
              "transform",
              `translate(${this.click_down_x}, ${this.click_down_y}) rotate(0)`
            );
            porta.setAttribute("transform-origin", `100 50`);
            porta.setAttribute("id", my_id);
            porta.setAttribute("class", "porta_doppia");
            porta.style.display = "block";
            svg.appendChild(porta);

            // Mi genero un oggetto nei nodi che mi rappresenta la porta.
            let fn = new FlowNode();
            fn.categoria = "porta_doppia";
            fn.id_nodo = my_id;
            fn.x1 = this.click_down_x;
            fn.y1 = this.click_down_y;
            fn.width = 200;
            fn.height = 100;
            this.nodi.push(fn);

            this.modalita_editing = "click_atteso";
          }
          break;
        case "aggiungi_porta_sinistra":
          {
            /* Registro la posizione del click e inizio
             * a valorizzare le variabili di appoggio che
             * mi serviranno per i parametri della linea. */

            this.click_down_x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x;
            this.click_down_y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y;
            this.click_up_x =
              e.clientX -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .x;
            this.click_up_y =
              e.clientY -
              document.getElementById("main_canvas_svg").getBoundingClientRect()
                .y;

            // Innesto la porta nell'SVG
            let svg = document.getElementById("main_canvas_svg");
            let my_id = moment().valueOf();
            let porta_template = document.getElementsByClassName(
              "porta_sinistra_template"
            )[0];
            let porta = porta_template.cloneNode(true);

            porta.setAttribute(
              "transform",
              `translate(${this.click_down_x}, ${this.click_down_y}) rotate(0)`
            );
            porta.setAttribute("transform-origin", `50 50`);
            porta.setAttribute("id", my_id);
            porta.setAttribute("class", "porta_sinistra");
            porta.style.display = "block";
            svg.appendChild(porta);

            // Mi genero un oggetto nei nodi che mi rappresenta la porta.
            let fn = new FlowNode();
            fn.categoria = "porta_sinisrta";
            fn.id_nodo = my_id;
            fn.x1 = this.click_down_x;
            fn.y1 = this.click_down_y;
            fn.width = 100;
            fn.height = 100;
            this.nodi.push(fn);

            this.modalita_editing = "click_atteso";
          }
          break;
        // Se sto aggiungendo un nodo...
        case "aggiungi_nodo":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            // Definisco i parametri del nuovo nodo.
            let nuovo_nodo = new FlowNode();
            nuovo_nodo.categoria = "ingombro";
            nuovo_nodo.height = parseInt(this.isc.altezza_nodo);
            nuovo_nodo.stroke = this.isc.colore_nodo;
            nuovo_nodo.stroke_width = this.isc.spessore_nodo;
            nuovo_nodo.testo_nodo = this.isc.testo_nodo;
            nuovo_nodo.width = parseInt(this.isc.larghezza_nodo);
            nuovo_nodo.x1 = this.click_down_x;
            nuovo_nodo.y1 = this.click_down_y;

            /* Aggiungo il nodo appena creato ai nodi, lo
             * seleziono e apro il dialog di modifica. */
            this.nodo_sel = new FlowNode(nuovo_nodo);
            this.enterModEditModificaNodo();
          }
          break;
        // Se invece sto copiando un nodo...
        case "copia_nodo":
          {
            // ... Registro le coordinate del mio click.
            this.click_down_x = Math.round(
              e.clientX -
                document.getElementById("wrapper_svg").getBoundingClientRect().x
            );
            this.click_down_y = Math.round(
              e.clientY -
                document.getElementById("wrapper_svg").getBoundingClientRect().y
            );

            /* Emetto un evento per segnalare che c'è un nuovo nodo.
             * Al padre interessa id del nodo originale, nuava x e nuova y. */
            this.$emit(
              "copiaNodo",
              this.id_nodo_sel,
              this.click_down_x,
              this.click_down_y
            );

            // Torno in modalità click_atteso.
            this.modalita_editing = "click_atteso";
          }
          break;
        case "ruota_porta":
          {
            this.porta_rotante = document.getElementById(this.nodo_sel.id_nodo);
            const t = this.nodo_sel.y1;
            const l = this.nodo_sel.x1;
            const h = this.nodo_sel.height;
            const w = this.nodo_sel.width;
            this.center = {
              x: l + w / 2,
              y: t + h / 2,
            };

            // Aggiungo un event listener per il movimento del mouse sulla superficie.
            document
              .getElementById("main_canvas_svg")
              .addEventListener("mousemove", this.moveDrawing);
          }
          break;
        case "trascina_muro":
          {
            const id_sel = e.path[0].id;
            this.nodi.forEach((n) => {
              if (n.id_nodo == id_sel) {
                // Mi faccio un puntatore all'oggetto nei nodi.
                this.nodo_sel = n;

                // Mi faccio un puntatore all'oggetto vettoriale
                this.vett_sel = document.getElementById(n.id_nodo);

                this.offsetX =
                  e.clientX - this.vett_sel.getBoundingClientRect().x;
                this.offsetY =
                  e.clientY - this.vett_sel.getBoundingClientRect().y;

                /* Mi segno i dati dell'svg e li terrò in memoria
                 * finché non finisco di draggare, poi cancello la
                 * variabile di supporto. */
                this.svg = document
                  .getElementById("main_canvas_svg")
                  .getBoundingClientRect();

                // Attivo il listener del drag vettoriale sull'svg
                document
                  .getElementById("main_canvas_svg")
                  .addEventListener("mousemove", this.moveDrawing);

                /* I muri hanno una x1 y1 e una x2 y2, per cui comincio a segnarmi
                 * la differenza per conservarla in drag. */
                this.diff_x = n.x1 - n.x2;
                this.diff_y = n.y1 - n.y2;
              }
            });
          }
          break;
        default:
          break;
      }
    },
    stopDrawing(e) {
      /* Se ho cliccato col destro non faccio niente perché
       * questo metodo col tasto destro non c'entra nulla. */
      if (e.which === 3) {
        return;
      }

      switch (this.modalita_editing) {
        case "aggiungi_linea":
          {
            let svg = document.getElementById("main_canvas_svg");

            // Registro le coordinate del mouse che viene spostato.
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX -
                      document
                        .getElementById("main_canvas_svg")
                        .getBoundingClientRect().x) /
                      this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX -
                  document
                    .getElementById("main_canvas_svg")
                    .getBoundingClientRect().x;

            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY -
                      document
                        .getElementById("main_canvas_svg")
                        .getBoundingClientRect().y) /
                      this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY -
                  document
                    .getElementById("main_canvas_svg")
                    .getBoundingClientRect().y;

            if (!this.lockedAscissa) this.click_up_x = dX;
            if (!this.lockedOrdinata) this.click_up_y = dY;

            // Assegno i parametri alla linea.
            const id = -moment().valueOf();
            this.drawingLine.setAttribute("id", id);
            this.drawingLine.setAttribute("x1", this.click_down_x);
            this.drawingLine.setAttribute("y1", this.click_down_y);
            this.drawingLine.setAttribute("x2", this.click_up_x);
            this.drawingLine.setAttribute("y2", this.click_up_y);
            this.drawingLine.style.stroke = this.isc.colore_linea;
            this.drawingLine.style.strokeWidth = this.isc.spessore_linea;

            // Aggiungo ai nodi l'oggetto linea.
            let fc = new FlowNode();

            fc.categoria = "linea";
            fc.height = null;
            fc.id_nodo = id;
            fc.width = null;
            fc.x1 = this.click_down_x;
            fc.y1 = this.click_down_y;
            fc.x2 = this.click_up_x;
            fc.y2 = this.click_up_y;
            fc.stroke = this.isc.colore_linea;
            fc.stroke_width = this.isc.spessore_linea;

            this.nodi.push(fc);

            // Mi libero di una variabile che non è più necessaria.
            delete this.drawingLine;

            // Torno in modalità di attesa del click.
            this.modalita_editing = "click_atteso";

            // Rimuovo l'event listener del movimento del mouse.
            svg.removeEventListener("mousemove", this.moveDrawing);
          }
          break;
        case "drag_vettoriale":
          {
            // Riassegno un'ultima volta le coordinate al nodo
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX - this.svg.x) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX - this.svg.x;
            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY - this.svg.y) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY - this.svg.y;

            // Aggiorno le coordinate del nodo
            if (!this.lockedAscissa) this.nodo_sel.x1 = dX;

            if (!this.lockedOrdinata) this.nodo_sel.y1 = dY;

            /* Aggiorno le coordinate dell'oggetto vettoriale
             * basandomi sulle coordinate del nodo. */
            this.vett_sel.setAttribute(
              "transform",
              `translate(${this.nodo_sel.x1}, ${this.nodo_sel.y1}) rotate(${this.nodo_sel.rotate})`
            );

            // Controllo se devo ridimensionare i canvas
            this.checkDimensioniCanvas(this.nodo_sel);

            // Torno in modalità click_atteso
            this.modalita_editing = "click_atteso";

            // Resetto il nodo selezionato.
            this.nodo_sel = new FlowNode();

            // Rimuovo l'event listener del movimento del mouse.
            document
              .getElementById("main_canvas_svg")
              .removeEventListener("mousemove", this.moveDrawing);

            /* Cancello il puntatore all'oggetto vettoriale
             * selezionato e il riferimento all'svg. */
            delete this.vett_sel;
            delete this.svg;
          }
          break;
        case "ruota_porta":
          delete this.porta_rotante;
          this.modalita_editing = "click_atteso";

          // Rimuovo l'event listener del movimento del mouse.
          document
            .getElementById("main_canvas_svg")
            .removeEventListener("mousemove", this.moveDrawing);
          break;
        case "trascina_muro":
          {
            // Riassegno un'ultima volta le coordinate al nodo
            const dX =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientX - this.svg.x) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientX - this.svg.x;
            const dY =
              this.isc.snap_allowed === true
                ? Math.round(
                    (e.clientY - this.svg.y) / this.isc.passo_griglia
                  ) * this.isc.passo_griglia
                : e.clientY - this.svg.y;

            // Aggiorno le coordinate del nodo
            if (!this.lockedAscissa) this.nodo_sel.x1 = dX;
            if (!this.lockedOrdinata) this.nodo_sel.y1 = dY;
            if (!this.lockedOrdinata) this.nodo_sel.x2 = dX + this.diff_x;
            if (!this.lockedOrdinata) this.nodo_sel.y2 = dY + this.diff_y;

            /* Aggiorno le coordinate dell'oggetto vettoriale
             * basandomi sulle coordinate del nodo. */
            this.vett_sel.setAttribute("x1", this.nodo_sel.x1);
            this.vett_sel.setAttribute("x2", this.nodo_sel.x2);
            this.vett_sel.setAttribute("y1", this.nodo_sel.y1);
            this.vett_sel.setAttribute("y2", this.nodo_sel.y2);

            // Controllo se devo ridimensionare i canvas
            this.checkDimensioniCanvas(this.nodo_sel);

            // Torno in modalità click_atteso
            this.modalita_editing = "click_atteso";

            // Resetto il nodo selezionato.
            this.nodo_sel = new FlowNode();

            // Rimuovo l'event listener del movimento del mouse.
            document
              .getElementById("main_canvas_svg")
              .removeEventListener("mousemove", this.moveDrawing);

            /* Cancello il puntatore all'oggetto vettoriale
             * selezionato e il riferimento all'svg. */
            delete this.diff_x;
            delete this.diff_y;
            delete this.vett_sel;
            delete this.svg;
          }
          break;
        default:
          break;
      }
    },
    stringBuild(e) {
      // Prevengo il comportamento di default per le chiavi che mi sono riservato.
      if (e)
        switch (this.key_comp + e.code) {
          case "ControlLeftKeyA":
            e.preventDefault();
            break;
          case "ControlLeftKeyG":
            e.preventDefault();
            break;
          case "ControlLeftKeyI":
            e.preventDefault();
            break;
          case "ControlLeftKeyO":
            e.preventDefault();
            break;
          case "ControlLeftKeyS":
            e.preventDefault();
            break;
          default:
            break;
        }

      /* Se ho un evento di pressione da tastiera ne memorizzo il codice,
       * altrimenti resetto il contatore che mi tiene in memoria i codici. */
      if (e != undefined && this.last_key != e.code) {
        this.key_comp += e.code;
        this.last_key = e.code;
      }
    },
    switchGriglia() {
      let svg = document.getElementById("main_canvas_svg");

      if (document.getElementById("grid_rect")) {
        let gr = document.getElementById("grid_rect");
        svg.removeChild(gr);
      } else {
        let gr = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        gr.setAttribute("id", "grid_rect");
        gr.setAttribute("width", "100%");
        gr.setAttribute("height", "100%");
        gr.setAttribute("fill", "url(#grid)");
        svg.appendChild(gr);
      }
    },
    switchMenuSection(arg) {
      this[arg] = !this[arg];
    },
    uploadImage(val) {
      let that = this;

      let reader = new FileReader();

      reader.onload = function () {
        // Registro l'immagine come Base64.

        let img = new Image();

        img.onload = function () {
          /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
           * e dimensiono il div in maniera da accoglierla senza stretcharla.
           * Voglio che le dimensioni dell'immagine non siano mai
           * superiori a 100 px; se lo sono scalo le dimesioni.*/
          let w;
          let h;

          if (img.width >= img.height && img.width > 100) {
            w = 100;
            // Proporzione -> width_nuova : img.width = height_nuova : img.height
            h = (w * img.height) / img.width;
          } else if (img.height > img.width && img.height > 100) {
            h = 100;
            // Proporzione -> width_nuova : img.width = height_nuova : img.height
            w = (h * img.width) / img.height;
          } else {
            w = img.width;
            h = img.height;
          }

          let obj = new FlowNode();
          obj.background_src = img.src;
          obj.categoria = "nodo_immagine";
          obj.height = h;
          obj.id_nodo = -moment().valueOf();
          obj.width = w;
          obj.x1 = that.nodo_sel ? that.nodo_sel.x1 : 0;
          obj.y1 = that.nodo_sel ? that.nodo_sel.y1 : 0;
          that.nodi.push(obj);

          /* Seleziono il nodo appena creato
           * e apro il dialog di modifica. */
          that.nodo_sel = new FlowNode(obj);
          that.enterModEditModificaNodo();
        };
        img.src = reader.result;
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

      reader.readAsDataURL(val);

      this.key_flowchart++;
    },
  },
  created() {},
  mounted() {
    /* Vincolo le chiavi da tastiera: se sto premendo un tasto
     * lo uso per aggiornarmi il contatore dei tasti premuti,
     * altrimenti se ho rilasciato resetto il contatore. */
    document.addEventListener("keydown", this.stringBuild);
    document.addEventListener("keyup", this.resetStringBuild);

    // Popolo la lista dei moduli richiamabili dinamicamente
    this.getModuliDinamici();
  },
};
</script>
<style>
.vdialognew {
  background-color: rgba(63, 81, 181,0);
  border: none !important;
  box-shadow: none !important;
  margin: -20px !important;
}
.rowAzzurrino {
  background-color: #e3f2fd;
}
.divAzzurrino {
  background-color: white;
  border: 1px solid;
  border-radius: 5px 5px 5px 5px;
  border-style: inset;
  padding: 5px;
  transition: 1s;
}
</style>