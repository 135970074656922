<template>
  <v-container fluid class="py-0">
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!--Dialog In order to Replace existing fasi with new ciclostd fasi or add as child -->
    <!-- Dialog to show list of fasi standard -->
    <v-dialog v-model="dailogciclostandardconfirm" width="50%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Aggiunta / Sostituzione Cicli Standard</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dailogciclostandardconfirm = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="setAsChild" small>Aggiungi</v-btn>
          <v-btn color="warning" @click="setAsCicloNew" small>sostituire</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to show list of fasi standard -->
    <v-dialog
      v-model="dialogciclistandard"
      width="75%"
      content-class="dialogshadow"
    >
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Cicli Standard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dialogciclistandard = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table :headers="cicloheaders" :items="cicloitems">
            <template v-slot:[`item.edit`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="openconfimationCicloStd(item)"
                      small
                    >
                      <v-icon color="success">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog to show list of fasi standard Table in Ciclo Commessa Produzione -->
    <v-dialog
      v-model="dialogfasestandard"
      width="75%"
      content-class="dialogshadow"
    >
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Fasi Standard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="ARTICOLO_CICLO_PRODUZIONE"
            idOggetto="IMPORT_FASE_STANDARD"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva Fasi Std."
            v-on:buttonClick="showFasi"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dialogfasestandard = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table
            v-model="selected"
            item-key="des_fase_standard"
            :headers="fasiHeaders"
            :items="fasiStdLista"
            :single-select="singleSelect"
            show-select
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog to show list of fasi standard -->
    <v-dialog
      v-model="dialogfasestandard1"
      width="75%"
      content-class="dialogshadow"
    >
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Fasi Standard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                @click="dialogfasestandard1 = false"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-data-table :headers="fasiHeaders" :items="fasiStdLista">
            <template v-slot:[`item.edit`]="{ item }">
              <v-col md="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      style="float: left"
                      @click="addToCicli(item)"
                      small
                    >
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Confirmation Dialog Before saving the Articolo ciclo -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogSavingConfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense color="indigo">
          <v-toolbar-title class="white--text">Salvataggio</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di voler salvare il ciclo?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="dialogSavingConfirmation = false"
            >ANNULLA</v-btn
          >
          <v-btn color="success" dark @click="UpdateCicloFasi">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Confirmation Dialog before Deletion of Articolo ciclo -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogdeletecicloconfirmation"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"> Cancellazione </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro di di voler eliminare questo elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="success" dark @click="delCiclo">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Ciclo Dialog In order to Add/Remove Ciclo -->
    <v-dialog
      v-model="dialogCiclo"
      persistent
      :scrollable="false"
      content-class="dialogshadow"
    >
      <!-- width="1450" -->
      <form>
        <v-card>
          <v-toolbar class="indigo darken-1" dense>
            <v-toolbar-title class="white--text">
              Ciclo Produzione
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    getFaseStd();
                    dialogfasestandard1 = true;
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="mr-5"
                  small
                >
                  <v-icon color="primary"> mdi-shape-circle-plus </v-icon>
                </v-btn>
              </template>
              <span> Aggiungi fase standard</span>
            </v-tooltip>
            <Button
              bottone_class="ml-auto mr-3"
              colore_icona="success"
              idForm="ARTICOLO_CICLO_PRODUZIONE"
              idOggetto="SALVA_ARTICOLO_FASE"
              mdi_icona="mdi-floppy"
              testo_tooltip="Salva Fase"
              v-on:buttonClick="checkcodfase"
            />
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="closeDialog" v-on="on" v-bind="attrs" small>
                  <v-icon color="error">mdi-window-close</v-icon>
                </v-btn>
              </template>
              <span>Cancellare</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="max-height: 600px; overflow-y: auto">
            <v-container fluid>
              <v-row class="">
                <v-col md="1" sm="2" cols="3" class="py-0">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_COD_FASE"
                    label_input="Cod. Fase"
                    palceholder_input="Cod. Fase"
                    :vmodel="cod_fase"
                    v-on:changeText="(val) => (this.cod_fase = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" md="2" sm="3" cols="3">
                  <v-autocomplete
                    v-model="des1"
                    :items="tipo_produzione_list"
                    label="Tipo Produzione"
                    placeholder="Tipo Produzione"
                    item-text="text"
                    required
                  >
                    <template #label>
                      <span class="">Tipo Produzione<strong>* </strong></span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="1" sm="1" cols="2">
                  <div class="ml-n9">
                    <span style="font-size: 12px; color: rgba(0, 0, 0, 0.6)"
                      >Prelievo Comp.
                    </span>
                    <v-switch
                      v-model="materia_prima"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1 ml-10"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" md="1" sm="2" cols="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_TEMPO_STANDARD"
                    label_input="Tempo Standard"
                    palceholder_input="Tempo"
                    :vmodel="tempo_standard"
                    v-on:changeText="(val) => (this.tempo_standard = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" md="1" sm="3" cols="2">
                  <v-autocomplete
                    style="width: 190px !important"
                    v-model="um_tempo"
                    :items="unita_misura_lead_time"
                    label="UM Tempo"
                    :placeholder="this.um_tempo ? this.um_tempo : 'UM Tempo'"
                    item-text="des"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="1">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_PESO"
                    label_input="Peso"
                    palceholder_input="Peso"
                    :vmodel="peso"
                    v-on:changeText="(val) => (this.peso = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" md="1">
                  <v-autocomplete
                    style="width: 150px !important"
                    v-model="um_pezzo"
                    :items="unita_misura_peso"
                    label="UM Peso"
                    :placeholder="this.um_pezzo ? this.um_pezzo : 'UM Peso'"
                    item-text="des"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="3">
                  <v-autocomplete
                    v-model="des"
                    :items="fase_lavorazione_list"
                    label="Fase. Lavo"
                    placeholder="Fase. Lavo"
                    item-text="des"
                    required
                  >
                    <!-- :rules="[(value) => value !==  '']"-->
                    <template #label>
                      <span>Fase. Lavo<strong>* </strong></span>
                      <a @click.stop style="pointer-events: all">
                        <Modules
                          v-on:dialogModules="aggiornaFase"
                          tipo="FASE"
                          nome="Fase"
                          setaction="settabelle"
                          getaction="gettabelle"
                          controller="Magazzino"
                          filter="validita = 1"
                          :dati="[{ des: '' }]"
                        />
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0 ml-n2" md="1">
                  <div class="">
                    <span
                      style="
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -2.2rem;
                        padding-left: -0.2rem;
                      "
                      >Fase Esterna
                    </span>
                    <v-switch
                      v-model="fase_esterna"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="mt-n7">
                  <Fornitura
                    FORM_ID="ARTICOLO"
                    ref="Fornitoridetails"
                    SOTTO_FORM="ARTICOLO_CICLO_PRODUZIONE"
                    :FORM_BUTTON_ARRAY="FORM_BUTTON_ARRAY"
                    v-on:updateFornitura="updateFornitura($event)"
                    :ID_ARTICOLO="ID_ARTICOLO"
                    :ID_CICLO_FASI="ID_CICLO_FASI"
                    :key="ID_CICLO_FASI"
                    :data="this.fornitoriLista"
                    v-on:delFornitura="delFornitura($event)"
                  />
                </v-col>
                <v-col class="" md="6">
                  <Macchina
                    FORM_ID="ARTICOLO"
                    SOTTO_FORM="ARTICOLO_CICLO_PRODUZIONE"
                    ref="Macchinedetails"
                    :MACCHINA_BUTTON_ARRAY="MACCHINA_BUTTON_ARRAY"
                    :ID_ARTICOLO="ID_ARTICOLO"
                    :ID_CICLO_FASI="ID_CICLO_FASI"
                    :key="ID_CICLO_FASI"
                    v-on:delMacchina="delMacchina($event)"
                    :data="this.articolo_ciclo_fasi_macchina"
                    v-on:updateMacchina="updateMacchina($event)"
                  />
                </v-col>
                <v-col class="" md="6">
                  <Personale
                    FORM_ID="ARTICOLO"
                    SOTTO_FORM="ARTICOLO_CICLO_PRODUZIONE"
                    ref="Personaledetails"
                    :PERSONALE_BUTTON_ARRAY="PERSONALE_BUTTON_ARRAY"
                    :ID_ARTICOLO="ID_ARTICOLO"
                    :ID_CICLO_FASI="ID_CICLO_FASI"
                    :key="ID_CICLO_FASI"
                    v-on:delPersonale="delPersonale($event)"
                    :data="this.articolo_ciclo_fasi_personale"
                    v-on:updatePersonale="updatePersonale($event)"
                  />
                </v-col>
                <v-col md="12" class="ml-1">
                  <v-textarea
                    v-model="note"
                    flat
                    item-text="text"
                    label="Annotazione"
                    placeholder="Annotazione"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </form>
    </v-dialog>
    <!-- Main Table of Fase Standard -->
    <v-row>
      <v-col md="12">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-toolbar color="indigo" dense>
                <v-toolbar-title class="white--text"
                  >Cicli Produzione</v-toolbar-title
                >
                <v-text-field
                  style="background-color: white !important"
                  class="ml-2"
                  v-model="search"
                  label="Cerca"
                  single-line
                  hide-details
                >
                  <v-icon slot="append" color="primary"> mdi-magnify </v-icon>
                </v-text-field>
                <v-spacer></v-spacer>
                <Button
                  classe_bottone="mr-5"
                  colore_icona="primary"
                  idForm="ARTICOLO_CICLO_PRODUZIONE"
                  idOggetto="CREA_ARTICOLO_FASE"
                  mdi_icona="mdi-plus"
                  testo_tooltip="Nuova Fase"
                  v-on:buttonClick="opendialogciclo"
                />
                <Button
                  classe_bottone="mr-5"
                  colore_icona="primary"
                  idForm="ARTICOLO_CICLO_PRODUZIONE"
                  idOggetto="AGGIUNGI_CICLO_STANDARD"
                  mdi_icona="  mdi-vector-circle-variant"
                  testo_tooltip="Aggiungi ciclo standard"
                  v-on:buttonClick="
                    getCicloStd();
                    dialogciclistandard = true;
                  "
                />
                <Button
                  classe_bottone="mr-5"
                  colore_icona="primary"
                  idForm="ARTICOLO_CICLO_PRODUZIONE"
                  idOggetto="AGGIUNGI_FASE_STANDARD"
                  mdi_icona="mdi-shape-circle-plus"
                  testo_tooltip="Aggiungi fase standard"
                  v-on:buttonClick="
                    dialogfasestandard = true;
                    getFaseStd();
                  "
                />
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-data-table
                  :loading="loadingCiclo"
                  :headers="CicloHeaders"
                  :items="cicloLista"
                  :search="search"
                >
                  <v-progress-linear
                    v-show="progressBarCiclo"
                    slot="progress"
                    color="blue"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:[`item.edit`]="{ item }">
                    <Button
                      style="float: left"
                      colore_icona="primary"
                      idForm="ARTICOLO_CICLO_PRODUZIONE"
                      idOggetto="MODIFICA_ARTICOLO_FASE"
                      mdi_icona="mdi-pencil"
                      testo_tooltip="Modifica"
                      v-on:buttonClick="
                        handleClick(item);
                        ID_ARTICOLO = item.id_articolo;
                        ID_CICLO_FASI = item.id_ciclo_fasi;
                      "
                    />
                  </template>
                  <template v-slot:[`item.allegati`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="float: left"
                          @click="clickAllegati(item)"
                          small
                          v-on="on"
                          v-bind="attrs"
                        >
                          <v-icon color="primary">mdi-paperclip</v-icon>
                        </v-btn>
                      </template>
                      <span>Allegati</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.materia_prima`]="{ item }">
                    <v-icon v-if="item.materia_prima === 1"
                      >mdi-check-underline</v-icon
                    >
                  </template>
                  <template v-slot:[`item.fase_esterna`]="{ item }">
                    <v-icon v-if="item.fase_esterna === 1"
                      >mdi-check-underline</v-icon
                    >
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <Button
                      colore_icona="error"
                      idForm="ARTICOLO_CICLO_PRODUZIONE"
                      idOggetto="ELIMINA_ARTICOLO_FASE"
                      mdi_icona="mdi-window-close"
                      testo_tooltip="Cancellare"
                      v-on:buttonClick="seletedCiclo(item)"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <!-- Allegati Attachments -->
    <v-dialog id="dialog_allegati" persistent v-model="allegati_dialog_visible">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Documenti di Controllo</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="ARTICOLO_CICLO_PRODUZIONE"
            idOggetto="SALVA_FASE_ALLEGATI"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva Allegati"
            v-on:buttonClick="salvaAllegati"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogAllegati"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-5">
          <GestioneAllegato
            controller_allegati="Global"
            controller_caratteristiche_tabella="Manutenzione"
            :id_riferimento="id_fase_sel"
            ref="componenteAllegati"
            @snackbarOpen="snackbarOpen"
            tipo_allegato="CONTROLLO"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- In order to show All the Alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
        color="white"
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
import Fornitura from "../../Common/Fornitura.vue";
import Macchina from "../../Common/Macchina.vue";
import Personale from "../../Common/Personale.vue";
export default {
  props: ["data"],
  components: {
    Button,
    TextField,
    Modules: () => import("../../../../components/TOOLS/Modules"),
    GestioneAllegato: () => import("@/components/TOOLS/GestioneAllegato.vue"),
    Fornitura,
    Macchina,
    Personale
  },
  data() {
    return {
      // Part Program
      associatePartPrgoram:null,
      rules: {},
      FORM_BUTTON_ARRAY: [
        "CREA_ARTICOLO_FASE_FORNITURA",
        "MODIFICA_ARTICOLO_FASE_FORNITURA",
        "ELIMINA_ARTICOLO_FASE_FORNITURA",
        "SALVA_ARTICOLO_FASE_FORNITURA"
      ],
      MACCHINA_BUTTON_ARRAY: [
        "CREA_ARTICOLO_FASE_MACCHINA",
        "MODIFICA_ARTICOLO_FASE_MACCHINA",
        "ELIMINA_ARTICOLO_FASE_MACCHINA",
        "SALVA_ARTICOLO_FASE_MACCHINA"
      ],
      PERSONALE_BUTTON_ARRAY: [
        "CREA_ARTICOLO_FASE_PERSONALE",
        "MODIFICA_ARTICOLO_FASE_PERSONALE",
        "ELIMINA_ARTICOLO_FASE_PERSONALE",
        "SALVA_ARTICOLO_FASE_PERSONALE"
      ],
      // Variables for Allegati
      allegati_dialog_visible: false,
      //<==============================Ciclo Variables=====================>//
      id_fase_sel: null,
      fase_description: "",
      cicloLista: [],
      tipo_produzione_list: [],
      fase_lavorazione_list: [],
      dialogCiclo: false,
      id_ciclo_fasi: "",
      cod_fase: "",
      produzione_list: [],
      id_tipo_fase: "",
      id_fase: "",
      fase_list: [],
      search: "",
      peso: "",
      unita_misura_peso: [],
      des: "",
      des1: "",
      cod_macchina: null,
      costo_lavorazione: null,
      fase_esterna: 0,
      materia_prima: 0,
      tempo_standard: 0,
      id_um_tempo: "",
      des_um_tempo: "",
      id_um_pezzo: "",
      um_pezzo: "",
      singleitem: {},
      note: "",
      loadingCiclo: false,
      progressBarCiclo: false,
      // Variable for pop modal for deletion
      dialogdeletecicloconfirmation: false,
      selectedCiclo: {},
      //<======== Fornitura Variables =====>//
      ID_ARTICOLO: -1,
      ID_CICLO_FASI: -1,
      fornitoriLista: [],
      deletedFornitura: [],
      // Macchina Variables
      um_tempo: [],
      unita_misura_lead_time: [],
      articolo_ciclo_fasi_macchina: [],
      singleMacchina: {},
      deletedMacchina: [],
      macchinaList: [],
      idciclo_fasi_macchina: -1,
      // Personale Variables
      articolociclipersonale: [],
      articolo_ciclo_fasi_personale: [],
      idciclo_fasi_personale: "",
      deletedPersonale: [],
      // Variabili d'appoggio
      index_sel: null,
      //  <=======================Generic Variables =====================>
      // cicloheaders
      cicloheaders: [],
      cicloitems: [],
      // Dialog Ciclo Standard
      dialogciclistandard: false,
      // Dailog Ciclo Standard Confirmation
      dailogciclostandardconfirm: false,
      selectedCicloStd: {},
      // Dailog Fase Standard
      singleSelect: false,
      selected: [],
      dialogfasestandard1: false,
      dialogfasestandard: false,
      item: "",
      overlay: false,
      id_articolo: this.data.id_articolo,
      validita: 1,
      // Confirmation Dialog
      dialogSavingConfirmation: false,
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_icon: "",
      snackbar_text: "",
      // Fasi Standard List and Fasi standard Headers
      fasiStdLista: [],
      fasiHeaders: [],
      last_item: null,
      // Header of ciclo table
      CicloHeaders: [
        {
          value: "edit"
        },
        {
          value: "allegati",
          sortable: false
        },
        {
          text: "Cod. Fase",
          value: "cod_fase",
          sortable: false
        },
        {
          text: "Tipo Fase",
          value: "des1",
          sortable: false
        },
        {
          text: "Prelievo Comp",
          value: "materia_prima",
          sortable: false
        },
        {
          text: "Lavoro Esterna",
          value: "fase_esterna",
          sortable: false
        },
        {
          text: "Fase Lavoro",
          value: "des",
          sortable: false
        },
        {
          text: "Peso",
          value: "peso",
          sortable: false
        },
        {
          text: "Lotto Prod",
          value: "lotto_produzione",
          sortable: false
        },
        {
          text: "Ragione Sociale",
          value: "ragione_sociale",
          sortable: false
        },
        {
          text: "Trasf. Diretto",
          value: "trasf_diretto",
          sortable: false
        },
        {
          text: "Lead Time",
          value: "lead_time",
          sortable: false
        },
        {
          text: "Lotto Lavoro",
          value: "lotto_lavorazione",
          sortable: false
        },
        {
          text: "Costo Lavoro",
          value: "costo_lavorazione",
          sortable: false
        },
        {
          text: "Tempo Standard",
          value: "tempo_standard",
          sortable: false
        },
        {
          text: "Pezzi/h Min",
          value: "pezzi_ora_min",
          sortable: false
        },
        {
          text: "Pezzi/h Max",
          value: "pezzi_ora_max",
          sortable: false
        },
        {
          text: "Macchina",
          value: "codice",
          sortable: false
        },
        {
          text: "Tempo Setup",
          value: "tempo_setup",
          sortable: false
        },
        {
          text: "Attrezzatura",
          value: "id_attrezzatura",
          sortable: false
        },
        {
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  methods: {

    delPersonale(ev) {
      this.deletedPersonale = ev;
    },
    updatePersonale(ev) {
      this.articolociclipersonale = ev;
    },
    delFornitura(ev) {
      this.deletedFornitura = ev;
    },
    delMacchina(ev) {
      console.log(ev);
      this.deletedMacchina = ev;
    },
    updateMacchina(ev) {
      this.articolo_ciclo_fasi_macchina = ev;
    },
    updateFornitura(ev) {
      ev.map((e) => {
        if (e.id_ciclostd_fasi_fornitori) {
          delete e.id_ciclostd_fasi_fornitori;
        }
      });
      this.fornitoriLista = ev;
    },
    // In order to open allegati dialog
    clickAllegati(item) {
      this.id_fase_sel = item.id_ciclo_fasi;
      this.allegati_dialog_visible = true;
    },
    // Close Allegatio dialog
    chiudiDialogAllegati() {
      this.allegati_dialog_visible = false;
    },
    // Open Ciclo Std Confirmation Dialog
    openconfimationCicloStd(item) {
      this.dailogciclostandardconfirm = true;
      this.selectedCicloStd = item;
    },
    // Add Ciclo Std to existing Ciclo
    setAsChild() {
      let getCicloStd = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: this.selectedCicloStd.id_ciclostd,
          id_articolo: "",
          tipo_import: ""
        }
      };
      this.$store.dispatch("api", getCicloStd).then((res) => {
        let data = res.data.ciclostd[0].ciclostd_dettaglio;
        this.selected = data;
        this.showFasi();
        this.dailogciclostandardconfirm = false;
        this.dialogciclistandard = false;
      });
    },
    // To set Ciclo Std as New ciclo or replace with existing ciclo
    setAsCicloNew() {
      for (let index = 0; index < this.cicloLista.length; index++) {
        const element = this.cicloLista[index];
        element.validita = 0;
        let delCiclo = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setciclodettaglio",
            token: localStorage.getItem("user_token"),
            articolo_ciclo_dettaglio: [element]
          }
        };
        this.$store.dispatch("api", delCiclo).then(() => {
          let i = this.cicloLista.findIndex(
            (it) => it.id_ciclo_fasi === element.id_ciclo_fasi
          );
          this.cicloLista.splice(i, 1);
        });
      }
      let getCicloStd = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: this.selectedCicloStd.id_ciclostd,
          id_articolo: "",
          tipo_import: ""
        }
      };
      this.$store.dispatch("api", getCicloStd).then((res) => {
        this.selected = res.data.ciclostd[0].ciclostd_dettaglio;
        this.showFasi();
        this.dailogciclostandardconfirm = false;
        this.dialogciclistandard = false;
      });
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    salvaAllegati() {
      if (this.$refs.componenteAllegati != undefined) {
        if (!this.$refs.componenteAllegati.allegato_sel.des_allegato) {
          this.snackbar = true;
          this.color = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "È necessario des di allegato.";
          this.snackbar_text_color = "white";
          return;
        }
      }
      this.$refs.componenteAllegati.id_riferimento_creazione_genitore = this.id_fase_sel;
      this.$refs.componenteAllegati.salvaTuttiAllegati();
      this.allegati_dialog_visible = false;
    },
    // Show All Ciclo Standard
    getCicloStd() {
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclostd",
          token: localStorage.getItem("user_token"),
          id_ciclostd: "",
          id_articolo: "",
          tipo_import: ""
        }
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        const data = res.data.lista;
        this.cicloheaders = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false
        };
        this.cicloheaders.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false
        };
        this.cicloheaders.push(data1);
        this.cicloitems = data;
        this.overlay = false;
        this.dialogciclistandard = true;
      });
    },
    //Show Fasi is to add fasi standard to Table directly from the page
    showFasi() {
      this.overlay = true;
      for (let index = 0; index < this.selected.length; index++) {
        let it = this.selected[index];
        let getArticoloStandardDettaglioFromFaseStandard = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getfasestd",
            token: localStorage.getItem("user_token"),
            id_ciclostd_dettaglio: it.id_ciclostd_dettaglio,
            id_articolo: this.id_articolo,
            tipo_import: "A"
          }
        };
        this.$store
          .dispatch("api", getArticoloStandardDettaglioFromFaseStandard)
          .then((res) => {
            this.overlay = false;
            this.FaseStandDetail = res.data.articolo_ciclo_dettaglio[0];
            const i = this.cicloLista.find(
              (it) => it.cod_fase == this.FaseStandDetail.cod_fase
            );
            if (i) {
              this.last_item = this.cicloLista[this.cicloLista.length - 1];
              this.last_item.cod_fase += 10;
              this.FaseStandDetail.cod_fase = this.last_item.cod_fase;
              let updateCiclo = {
                controller: "Magazzino",
                method: "PUT",
                richiesta: {
                  action: "setciclodettaglio",
                  token: localStorage.getItem("user_token"),
                  id_ciclo_fasi: -1,
                  articolo_ciclo_dettaglio: [this.FaseStandDetail]
                }
              };
              this.$store.dispatch("api", updateCiclo).then((res) => {
                if (res.status == 200) {
                  this.overlay = false;
                  this.getCicli();
                  this.cicloLista.sort(
                    (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
                  );
                  this.last_item = null;
                }
              });
            } else {
              let updateCiclo = {
                controller: "Magazzino",
                method: "PUT",
                richiesta: {
                  action: "setciclodettaglio",
                  token: localStorage.getItem("user_token"),
                  id_ciclo_fasi: -1,
                  articolo_ciclo_dettaglio: [this.FaseStandDetail]
                }
              };
              this.$store.dispatch("api", updateCiclo).then((res) => {
                this.overlay = false;
                this.FaseStandDetail.id_ciclo_fasi = res.data[0].id_ciclo_fasi;
                this.getCicli();
                this.cicloLista.sort(
                  (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
                );
                //
              });
            }
          });
      }

      this.dialogfasestandard = false;
      this.selected = [];
    },
    // To get the list of Fase Standard
    getFaseStd() {
      this.overlay = true;
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getfasestd",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: ""
        }
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        this.overlay = false;
        const data = res.data.lista;
        this.fasiHeaders = res.data.header;
        let data2 = {
          value: "edit",
          sortable: false
        };
        this.fasiHeaders.unshift(data2);
        let data1 = {
          value: "delete",
          sortable: false
        };
        this.fasiHeaders.push(data1);
        data.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        this.fasiStdLista = data;
        if (this.cicloLista.length > 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
        if (this.cicloLista.length === 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
      });
    },
    // In order to Add Fase Standard into Ciclo table
    addToCicli(item) {
      this.overlay = true;
      let getFaseStandardDettaglio = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getfasestd",
          token: localStorage.getItem("user_token"),
          id_ciclostd_dettaglio: item.id_ciclostd_dettaglio,
          id_articolo: this.id_articolo,
          tipo_import: "A"
        }
      };
      this.$store.dispatch("api", getFaseStandardDettaglio).then((res) => {
        this.overlay = false;
        this.singleitem = res.data.articolo_ciclo_dettaglio[0];
        this.fornitoriLista = this.singleitem.articolo_ciclo_fasi_fornitori;
        this.articolo_ciclo_fasi_macchina = this.singleitem.articolo_ciclo_fasi_macchina;
        this.ID_CICLO_FASI = this.singleitem.ID_CICLO_FASI;
        this.ID_ARTICOLO = this.singleitem.ID_ARTICOLO;
        this.cod_fase = this.singleitem.cod_fase;
        this.des1 = this.singleitem.des1;
        this.des = this.singleitem.des;
        this.materia_prima = this.singleitem.materia_prima;
        this.tempo_standard = this.singleitem.tempo_standard;
        this.peso = this.singleitem.peso;
        this.fase_esterna = this.singleitem.fase_esterna;
        this.id_ciclo_fasi = this.singleitem.id_ciclo_fasi;
        this.note = this.singleitem.note;
        this.id_um_pezzo = this.singleitem.id_um_pezzo;
        this.id_um_tempo = this.singleitem.id_um_tempo;
        this.um_tempo = this.singleitem.um_tempo;
        this.um_pezzo = this.singleitem.um_pezzo;
        this.articolo_ciclo_fasi_macchina = this.singleitem.articolo_ciclo_fasi_macchina;
        this.articolo_ciclo_fasi_macchina.sort((a, b) => {
          if (a.des_macchina < b.des_macchina) {
            return -1;
          }
          if (a.des_macchina > b.des_macchina) {
            return 1;
          }
          return 0;
        });
        this.articolociclipersonale = this.singleitem.articolo_ciclo_fasi_personale;
        this.articolociclipersonale.sort((a, b) => {
          if (a.competenza < b.competenza) {
            return -1;
          }
          if (a.competenza > b.competenza) {
            return 1;
          }
          return 0;
        });
        if (this.articolociclipersonale.length > 0) {
          this.progressBarPersonale = false;
          this.loadingPersonale = false;
        }
        if (this.articolociclipersonale.length === 0) {
          this.progressBarPersonale = false;
          this.loadingPersonale = false;
        }
      });
      this.dialogfasestandard1 = false;
    },
    // In order to check two Cod_fase with same value\
    checkcodfase() {
      if (this.cicloLista.length === 0) {
        this.dialogSavingConfirmation = true;
      }
      this.cicloLista.map((it) => {
        if (it.cod_fase == this.cod_fase && this.id_ciclo_fasi === "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text =
            "Non è possibile salvare due cicli con lo stesso codice fase";
          this.dialogSavingConfirmation = false;
          return;
        }
        if (
          it.cod_fase == this.cod_fase &&
          this.id_ciclo_fasi != it.id_ciclo_fasi
        ) {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text =
            "Non è possibile salvare due cicli con lo stesso codice fase";
          this.dialogSavingConfirmation = false;
          return;
        } else {
          if (!this.snackbar) {
            this.dialogSavingConfirmation = true;
          }
        }
      });
    },
    // In order to Open Deletion Pop up
    seletedCiclo(item) {
      this.selectedCiclo = item;
      this.dialogdeletecicloconfirmation = true;
    },
    // TO Deselect the item when hit Annulla of deletion
    delSelectedItem() {
      this.dialogdeletecicloconfirmation = false;
      this.selectedCiclo = {};
    },
    // In order to update Fase Lista
    aggiornaFase() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "FASE",
          filter: "validita = 1"
        }
      };
      this.$store.dispatch("api", request).then((res) => {
        this.fase_list = res.data;
        this.fase_list.map((el) => {
          this.fase_lavorazione_list.push(el.des);
        });
      });
    },
    // In order to open Ciclo form dialog
    opendialogciclo() {
      this.des1 = "";
      this.materia_prima = 0;
      this.tempo_standard = "";
      this.fase_esterna = 0;
      this.singleitem = {};
      this.ID_ARTICOLO = -1;
      this.ID_CICLO_FASI = -1;
      this.des = "";
      this.cod_fase = "";
      this.dialogCiclo = true;
      this.id_ciclo_fasi = "";
      this.um_tempo = "";
      this.um_pezzo = "";
      this.peso = "";
      this.note = "";
      this.$refs.Macchinedetails.articolo_ciclo_fasi_macchina = [];
      this.$refs.Personaledetails.articolociclipersonale = [];
      this.dataToUpdate = {};
      this.$refs.Fornitoridetails.fornitoriLista = [];
    },
    // In order to clear the personale form when simply closing it
    closeDialog() {
      this.dialogCiclo = false;
      this.articolociclipersonale = [];
      this.fornitoriLista = [];
      this.articolo_ciclo_fasi_macchina = [];
      this.codice = "";
      this.des1 = "";
      this.materia_prima = "";
      this.tempo_standard = "";
      this.des = "";
      this.fase_esterna = 0;
    },
    // Getting the single ciclo value
    handleClick(value) {
      this.dialogCiclo = true;
      this.singleitem = value;
      this.cod_fase = this.singleitem.cod_fase;
      this.des1 = this.singleitem.des1;
      this.des = this.singleitem.des;
      this.materia_prima = this.singleitem.materia_prima;
      this.tempo_standard = this.singleitem.tempo_standard;
      this.peso = this.singleitem.peso;
      this.fase_esterna = this.singleitem.fase_esterna;
      this.id_ciclo_fasi = this.singleitem.id_ciclo_fasi;
      this.note = this.singleitem.note;
      this.id_um_pezzo = this.singleitem.id_um_pezzo;
      this.id_um_tempo = this.singleitem.id_um_tempo;
      this.um_tempo = this.singleitem.um_tempo;
      this.um_pezzo = this.singleitem.um_pezzo;
      this.progressBarMacchina = true;
      this.loadingMacchina = true;
      // Getting the single ciclo value
      this.loadingFornitura = true;
      this.progressBarFornitura = true;
      // IN order to get Ciclo fornitori list List to p
      let getarticoliciclofornitori = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliciclofornitori",
          token: localStorage.getItem("user_token"),
          id_ciclo_fasi: this.singleitem.id_ciclo_fasi,
          id_articolo: this.singleitem.id_articolo
        }
      };
      this.$store.dispatch("api", getarticoliciclofornitori).then((res) => {
        this.loadingFornitura = false;
        this.progressBarFornitura = false;
        this.fornitoriLista = res.data;
      });
    },
    // In order to remove ciclo
    delCiclo() {
      this.overlay = true;
      let data = this.selectedCiclo;
      data.validita = 0;
      let delCiclo = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setciclodettaglio",
          token: localStorage.getItem("user_token"),
          articolo_ciclo_dettaglio: [data]
        }
      };
      this.$store.dispatch("api", delCiclo).then(() => {
        this.snackbar = true;
        this.overlay = false;
        this.snackbar_text = "Modifica effettuata con successo!";
        this.color = "green darken-3";
        this.mainIcon = "mdi-check-circle";
      });
      let i = this.cicloLista.findIndex(
        (it) => it.id_ciclo_fasi === data.id_ciclo_fasi
      );
      this.cicloLista.splice(i, 1);
      this.dialogdeletecicloconfirmation = false;
    },
    // In order to save a new ciclo or update an existing one
    UpdateCicloFasi() {
      this.overlay = true;
      if (this.des1 == "" || this.des == "") {
        this.snackbar = true;
        this.mainIcon = "mdi-alert";
        this.color = "yellow accent-4";
        this.snackbar_text =
          "Definire almeno il Tipo Produzione e la Fase Lavorazione per salvare.";
        return;
      } else {
        console.log(this.des1 + " e " + this.des);
      }
      if (this.id_ciclo_fasi === "" || this.id_ciclo_fasi == -1) {
        if (this.cod_fase === "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire il Cod Fase prima di salvare.";
          return;
        }
        let data = {};
        this.produzione_list.map((el) => {
          if (el.des === this.des1) {
            data.id_tipo_fase = el.id_tabella;
            return data;
          }
        });
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
            return data;
          }
        });
        data.peso = this.peso;
        data.id_ciclo_fasi = -1;
        data.cod_fase = this.cod_fase;
        data.note = this.note;
        data.des = this.des;
        data.des1 = this.des1;
        data.cod_macchina = this.cod_macchina;
        data.costo_lavorazione = this.costo_lavorazione;
        data.id_articolo = this.id_articolo;
        data.fase_esterna = this.fase_esterna;
        data.materia_prima = this.materia_prima;
        data.tempo_standard = this.tempo_standard;
        data.id_um_tempo = this.um_tempo;
        this.unita_misura_lead_time.map((ui) => {
          if (ui.des === data.id_um_tempo) {
            data.id_um_tempo = ui.id_tabella;
            data.um_tempo = ui.des;
          }
        });
        data.id_um_pezzo = this.um_pezzo;
        this.unita_misura_peso.map((ui) => {
          if (ui.des === data.id_um_pezzo) {
            data.id_um_pezzo = ui.id_tabella;
            data.um_pezzo = ui.des;
          }
        });
        data.validita = 1;
        data.articolo_ciclo_fasi_personale = this.articolociclipersonale;
        data.articolo_ciclo_fasi_macchina = this.articolo_ciclo_fasi_macchina;
        data.articolo_ciclo_fasi_fornitori = this.fornitoriLista;
        this.fase_list.map((fa) => {
          if (fa.des === data.des) {
            data.id_fase = fa.id_tabella;
          }
          this.produzione_list.map((prod) => {
            if (prod.des === data.des1) {
              data.id_tipo_fase = prod.id_tabella;
            }
          });
        });
        if (this.fase_esterna) {
          var fornitoriwithpref = [];
          data.articolo_ciclo_fasi_fornitori.map((it) => {
            if (it.predefinito !== 0) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text =
              "È necessario selezionare una fornitura predefinita.";
            return;
          }
          data.articolo_ciclo_fasi_macchina.map((it) => (it.predefinito = 0));
        }
        if (!this.fase_esterna) {
          var macchinawithpref = [];
          data.articolo_ciclo_fasi_macchina.map((it) => {
            if (it.predefinito == 1) {
              macchinawithpref.push(it);
            }
          });
          console.log(macchinawithpref);
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text =
              "È necessario selezionare una macchina predefinita.";
            return;
          }
          data.articolo_ciclo_fasi_fornitori.map((it) => (it.predefinito = 0));
        }
        let dataArray = [];
        dataArray.push(data);
        let updateCiclo = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setciclodettaglio",
            token: localStorage.getItem("user_token"),
            id_ciclo_fasi: -1,
            articolo_ciclo_dettaglio: dataArray
          }
        };
        this.$store.dispatch("api", updateCiclo).then((res) => {
          if (res.status === 200) {
            this.getCicli();
          }
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          data.id_ciclo_fasi = res.data[0].id_ciclo_fasi;
          this.cicloLista.push(data);
          this.cicloLista.sort(
            (a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase)
          );
        });
        this.dialogCiclo = false;
        this.codice = "";
        this.peso = "";
        this.des = "";
        this.des1 = "";
        this.materia_prima = "";
        this.tempo_standard = "";
        this.peso = "";
        this.fase_esterna = "";
        this.note = "";
        this.id_ciclo_fasi = "";
        this.cod_fase = "";
        this.dialogSavingConfirmation = false;
      }
      if (this.id_ciclo_fasi > -1) {
        if (this.cod_fase === "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire il Cod Fase prima di salvare.";
          return;
        }
        let data = this.singleitem;
        console.log(this.singleitem);
        this.produzione_list.map((el) => {
          if (el.des === this.des1) {
            data.id_tipo_fase = el.id_tabella;
            return data;
          }
        });
        this.fase_list.map((el) => {
          if (el.des === this.des) {
            data.id_fase = el.id_tabella;
            return data;
          }
        });
        data.cod_macchina = this.singleitem.cod_macchina;
        data.cod_fase = this.cod_fase;
        data.costo_lavorazione = this.singleitem.costo_lavorazione;
        data.date_ins = this.singleitem.date_ins;
        data.date_mod = this.singleitem.date_mod;
        data.des = this.des;
        data.des1 = this.des1;
        data.fase_esterna = this.fase_esterna;
        data.materia_prima = this.materia_prima;
        data.id_anagrafica = this.singleitem.id_anagrafica;
        data.id_articolo = this.singleitem.id_articolo;
        data.id_attrezzatura = this.singleitem.id_attrezzatura;
        data.id_ciclo_fasi = this.singleitem.id_ciclo_fasi;
        data.id_ciclo_testa = this.singleitem.id_ciclo_testa;
        data.id_fase = this.id_fase;
        data.id_macchina = this.singleitem.id_macchina;
        data.id_tipo_fase = this.id_tipo_fase;
        data.id_utente = localStorage.id;
        data.lead_time = this.singleitem.lead_time;
        data.lotto_lavorazione = this.singleitem.lotto_lavorazione;
        data.lotto_produzione = this.singleitem.lotto_produzione;
        data.materia_prima = this.singleitem.materia_prima;
        data.note = this.note;
        data.peso = this.peso;
        data.pezzi_ora_max = this.singleitem.pezzi_ora_max;
        data.pezzi_ora_min = this.singleitem.pezzi_ora_min;
        data.ragione_sociale = this.singleitem.ragione_sociale;
        data.riga = this.singleitem.riga;
        data.riga_fase = this.singleitem.riga_fase;
        data.tempo_setup = this.singleitem.tempo_setup;
        data.tempo_standard = this.tempo_standard;
        data.validita = this.singleitem.validita;
        data.id_um_tempo = this.um_tempo;
        data.id_um_pezzo = this.um_pezzo;
        this.fase_list.map((fa) => {
          if (fa.des === data.des) {
            data.id_fase = fa.id_tabella;
          }
          this.produzione_list.map((prod) => {
            if (prod.des === data.des1) {
              data.id_tipo_fase = prod.id_tabella;
            }
          });
        });
        this.unita_misura_lead_time.map((ui) => {
          if (ui.des === data.id_um_tempo) {
            data.id_um_tempo = ui.id_tabella;
            data.um_tempo = ui.des;
          }
        });
        this.unita_misura_peso.map((ui) => {
          if (ui.des === data.id_um_pezzo) {
            data.id_um_pezzo = ui.id_tabella;
            data.um_pezzo = ui.des;
          }
        });
        console.log(this.deletedMacchina)
        if (this.deletedMacchina.length > 0) {
          this.deletedMacchina.map((el) => {
            this.articolo_ciclo_fasi_macchina.push(el);
          });
        }
        if (this.deletedPersonale.length > 0) {
          console.log("true :>> ", true);
          this.deletedPersonale.map((el) => {
            this.articolociclipersonale.push(el);
          });
        }
        if (this.deletedFornitura.length > 0) {
          this.deletedFornitura.map((el) => {
            this.fornitoriLista.push(el);
          });
        }
        data.articolo_ciclo_fasi_personale = this.articolociclipersonale;
        data.articolo_ciclo_fasi_fornitori = [];
        data.articolo_ciclo_fasi_macchina = this.articolo_ciclo_fasi_macchina;
        data.articolo_ciclo_fasi_fornitori = this.fornitoriLista;
        if (this.fase_esterna) {
          let fornitoriwithpref = [];
          data.articolo_ciclo_fasi_fornitori.map((it) => {
            if (it.predefinito !== 0) {
              fornitoriwithpref.push(it);
            }
          });
          if (fornitoriwithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text =
              "È necessario selezionare una fornitura predefinita.";
            return;
          }
          data.articolo_ciclo_fasi_macchina.map((it) => (it.predefinito = 0));
        }
        if (!this.fase_esterna) {
          let macchinawithpref = [];
          data.articolo_ciclo_fasi_macchina.map((it) => {
            if (it.predefinito == 1) {
              macchinawithpref.push(it);
            }
          });
          if (macchinawithpref.length < 1) {
            this.snackbar = true;
            this.mainIcon = "mdi-alert";
            this.color = "yellow accent-4";
            this.snackbar_text =
              "È necessario selezionare una macchina predefinita.";
            return;
          }
          data.articolo_ciclo_fasi_fornitori.map((it) => (it.predefinito = 0));
        }
        let dataArray = [];
        dataArray.push(data);
        let updateCiclo = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setciclodettaglio",
            token: localStorage.getItem("user_token"),
            id_ciclo_fasi: this.singleitem.id_ciclo_fasi,
            articolo_ciclo_dettaglio: dataArray
          }
        };
        this.$store.dispatch("api", updateCiclo).then((res) => {
          if (res.status === 200) {
            this.getCicli();
          }
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
        });
        this.dialogCiclo = false;
        data = {};
        this.codice = "";
        this.peso = "";
        this.des = "";
        this.des1 = "";
        this.materia_prima = "";
        this.tempo_standard = "";
        this.peso = "";
        this.fase_esterna = "";
        this.note = "";
        this.id_ciclo_fasi = "";
        this.id_ciclo_test = "";
        this.singleitem = {};
        this.cod_fase = "";
        this.id_ciclo_fasi = "";
        this.dialogSavingConfirmation = false;
      }
    },
    getCicli() {
      this.cicloLista = [];
      // Getting Ciclo lista to populate the table
      let getCicolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliciclo",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo
        }
      };
      this.$store.dispatch("api", getCicolo).then((res) => {
        const data = res.data;
        this.ID_ARTICOLO = -1;
        this.ID_CICLO_FASI = -1;
        data.sort((a, b) => parseFloat(a.cod_fase) - parseFloat(b.cod_fase));
        this.cicloLista = data;
        if (this.cicloLista.length > 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
        if (this.cicloLista.length === 0) {
          this.progressBarCiclo = false;
          this.loadingCiclo = false;
        }
      });
    }
  },
  watch: {
    pezzi_min: {
      immediate: true,
      async handler() {
        if (this.$refs.form != undefined) {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        if (this.allegati_dialog_visible === true) {
          this.allegati_dialog_visible = false;
        }
      }
    });
    this.getCicli();
    // Getting all the lista value for select fields
    this.progressBarCiclo = true;
    this.loadingCiclo = true;
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'UNITA_MISURA','TIPO_PRODUZIONE','FASE'",
        filter: "validita = 1"
      }
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array PORTO che mi arriva dal BE
      this.modalita_list = res.data.PORTO;
      // Gestisco l'array UNITA_MISURA che mi arriva dal BE
      res.data.UNITA_MISURA.map((el) => {
        if (el.val_txt === "P") {
          this.unita_misura_peso.push(el);
        }
        if (el.val_txt === "H") {
          this.unita_misura_lead_time.push(el);
        }
      });
      this.unita_misura_mod_list = res.data.UNITA_MISURA;
      // Gestisco l'array TIPO_PRODUZIONE che mi arriva dal BE
      this.produzione_list = res.data.TIPO_PRODUZIONE;
      this.produzione_list.map((el) => {
        this.tipo_produzione_list.push(el.des);
      });
      // Gestisco l'array FASE che mi arriva dal BE
      this.fase_list = res.data.FASE;
      this.fase_lavorazione_list = res.data.FASE;
    });
  }
};
</script>
<style css>
.dialogshadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  overflow-y: hidden !important;
}
</style>
