<template>
  <div style="width: 100%"><UscitaMateriale /></div>
</template>
<script>
import UscitaMateriale from "../../../components/PRODUCTION/LOGISTICA/Uscita_materiale.vue";
export default {
  components: {
    UscitaMateriale,
  },
  data() {
    return {};
  },
};
</script>