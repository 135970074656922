<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Registra Avanzamento
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="registraAvanzamento" v-bind="attrs" v-on="on">
            <v-icon color="success" large> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Invia Registrazione</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Bottone per tornare indietro -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" v-bind="attrs" v-on="on">
            <v-icon color="error" large> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-container>
        <v-row align="center" justify="center">
          <!-- Colonna della card della causale -->
          <v-col cols="4">
            <!-- Card della quantità -->
            <v-card
              class="elevation-0"
              outlined
              style="border: 1px solid black"
            >
              <v-card-text>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="6">
                      <span style="font-size: 20px">
                        Scatole da registrare
                        </span>
                    </v-col>
                    <v-col cols="6">
                      <span style="font-size: 20px">
                        Pz. per scatola
                        </span>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="6">
                      <v-text-field
                        background-color="#ffffff"
                        class="input-class centered-input"
                        dense
                        height="50px"
                        hide-details
                        label=""
                        style="
                          display: inline-block;
                          font-size: 20px;
                          font-weight: bold;
                          line-height: 25px;
                          max-width: 150px;
                          padding: 0px;
                        "
                        type="number"
                        v-model="qta_box"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        background-color="#ffffff"
                        class="input-class centered-input"
                        dense
                        height="50px"
                        hide-details
                        label=""
                        style="
                          display: inline-block;
                          font-size: 20px;
                          font-weight: bold;
                          line-height: 25px;
                          max-width: 150px;
                          padding: 0px;
                        "
                        type="number"
                        v-model="qta_pz"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Colonna della card della causale -->
          <v-col cols="4">
            <!-- Card della bobina -->
            <v-card
              class="elevation-0"
              outlined
              style="border: 1px solid black"
            >
              <v-card-text>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <span style="font-size: 20px"
                        >Inserire il codice della bobina</span
                      >
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <div class="mr-5" style="display: inline-block">
                        <v-btn @click="clickQRCode" icon>
                          <v-icon color="primary" x-large>
                            mdi-qrcode-scan
                          </v-icon>
                        </v-btn>
                      </div>
                      <div style="display: inline-block">
                        <v-text-field
                          background-color="#ffffff"
                          class="input-class centered-input"
                          dense
                          height="50px"
                          hide-details
                          label=""
                          style="
                            display: inline-block;
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 25px;
                            max-width: 150px;
                            padding: 0px;
                          "
                          v-model="cod_bobina"
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Colonna della card della causale -->
          <v-col cols="4">
            <!-- Card della causale -->
            <v-card
              class="elevation-0"
              outlined
              style="border: 1px solid black"
            >
              <v-card-text>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <span style="font-size: 20px"
                        >Indicare la causale della registrazione manuale</span
                      >
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="causale_sel"
                        :items="items_causali"
                        label="Causale"
                        item-text="des"
                        item-value="id_tabella"
                      >
                        <template #label>
                          <span class="">Causale</span>
                          <span
                            @click.stop
                            style="min-width: 500px; pointer-events: all"
                          >
                            <Modules
                              v-on:dialogModules="gettabelle"
                              tipo="CRISTAL_CAUSALE_REGISTRAZIONE"
                              nome="Causali di Registrazione"
                              setaction="settabella"
                              getaction="gettabella"
                              controller="Global"
                              filter="validita = 1"
                              :dati="[{ des: '' }]"
                            />
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Dialog di avviso all'apertura del form -->
    <v-dialog v-model="conferma_dialog" persistent width="500px">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Registrazione Manuale</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          <span style="color: black; font-size: 20px"
            >La registrazione manuale non è la procedura standard: confermi di
            voler procedere alla compilazione di questo form?</span
          >
          <v-divider class="my-5"></v-divider>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-btn class="mr-5" @click="clickConfermaAnnulla">
                  <v-icon color="error" large>mdi-close</v-icon>
                </v-btn>
                <v-btn class="mr-5" @click="clickConfermaOk">
                  <v-icon color="success" large> mdi-check </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog di controllo qualità -->
    <v-dialog v-model="qr_reader_visible" persistent>
      <v-toolbar>
        <v-toolbar-title> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="handleCloseQRDialog" v-bind="attrs" v-on="on">
              <v-icon color="error" large> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Chiudi</span>
        </v-tooltip>
      </v-toolbar>
      <qrcode-stream
        class="qr"
        :camera="camera"
        @decode="handleOnDecode"
      ></qrcode-stream>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "RegistraAvanzamento",
  components: {
    Modules: () => import("@/components/TOOLS/Modules"),
    SnackbarQV,
  },
  props: {
    work_order_sel:{type: Object, default: ()=>{}},
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      causale_sel: null,
      cod_bobina: "",
      qta_box: 0,
      qta_pz: 0,

      // Modal
      conferma_dialog: false,
      qr_reader_visible: false,
      camera: "off",

      // Array
      items_causali: [],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.gettabelle();

          /* Apro il dialog di conferma per chiedere
           * all'utente se vuole davvero usare questa
           * finestra di dialogo, che non è standard.*/
          this.$nextTick(() => {
            this.conferma_dialog = true;
          });
        }
      },
    },
  },
  methods: {
    clickChiudi() {
      this.resetProperties();
      this.$emit("click_chiudi");
    },
    clickConfermaAnnulla() {
      this.conferma_dialog = false;
      this.clickChiudi();
    },
    clickConfermaOk() {
      this.conferma_dialog = false;
    },
    clickQRCode() {
      this.qr_reader_visible = true;
      this.camera = "auto";
    },
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "CRISTAL_CAUSALE_REGISTRAZIONE",
          filter: "validita = 1",
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.items_causali = res.data;
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice ${res.status}.`
            );
          }
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    handleCloseQRDialog() {
      this.qr_reader_visible = false;
      this.camera = "off";
    },
    handleOnDecode(arg) {
      this.cod_bobina = arg;
      this.camera = "off";
      this.qr_reader_visible = false;
    },
    registraAvanzamento() {
      // Controllo che i valori del form siano accettabili

      // Controllo il codice della bobina
      if (
        this.cod_bobina === null ||
        this.cod_bobina === undefined ||
        this.cod_bobina === ""
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Sono presenti campi non compilati: indicare il codice della bobina."
        );
        return;
      }

      // Controllo la quantità da registrare
      if (
        this.qta_box <= 0 ||
        this.qta_box === null ||
        this.qta_box === undefined ||
        this.qta_pz <= 0 ||
        this.qta_pz === null ||
        this.qta_pz === undefined
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Sono presenti campi non compilati: definire quantità da registrare maggiori di 0."
        );
        return;
      }

      // Controllo la causale di registrazione
      if (this.causale_sel === null) {
        this.$refs.SnackbarQV.print(
          "orange",
          "Sono presenti campi non compilati: dichiarare la causale della registrazione manuale."
        );
        return;
      }

      // Preparo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setavanzamentomanuale",
          token: localStorage.getItem("user_token"),
          id_commessa: this.work_order_sel.id_commessa,
          id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
          id_macchina: this.work_order_sel.id_macchina,
          flag_fase_ultima: this.work_order_sel.flag_fase_ultima,
          flag_em: this.flag_em_registrazione,
          id_articolo: this.work_order_sel.id_articolo,
          causali: this.causali,
          note: this.note,
          qta_box: this.qta_box,
          qta_pz: this.qta_pz,
          quantita: this.qta_pz * this.qta_box,
          chiudi_workorder:
            parseInt(this.qta_pz * this.qta_box) +
              parseInt(this.work_order_sel.pezzi_registrati) <
            parseInt(this.work_order_sel.quantita)
              ? 0
              : 1,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            // Resetto le variabili di supporto
            this.resetProperties();
            this.$emit("registra_avanzamento");
            /* Emetto un evento per segnalare
             * al componente padre di aggiornarsi */
            this.$emit("terminata_produzione");
          } else {
            // Stampo una snackbar di errore
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        });
    },
    resetProperties() {
      this.causale_sel = null;
      this.cod_bobina = "";
      this.qta_box = 0;
      this.qta_pz = 0;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>