var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fix_container",staticStyle:{"padding":"10px"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-snackbar',{attrs:{"color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbar_text))]),_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"indigo darken-1","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.VOCE_MENU)+" ")]),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_articoli,"items":_vm.itemsArticoli,"search":_vm.search,"items-per-page":10,"sort-by":"id_articolo"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Articoli")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])]}},{key:"item.confirm",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.aggiungiDettaglio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-decagram-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Conferma Articolo ")])])]}}],null,true)})],1)]],2),_c('v-spacer'),_c('v-btn',{staticClass:"red--text",attrs:{"color":"blue-grey lighten-5","small":""},on:{"click":function($event){_vm.dialog_timbrature = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_documento,"items":_vm.documento,"hide-default-footer":"","items-per-page":12000},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon'),_c('v-toolbar-title',[_vm._v("Documento")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":_vm.nuova_riga_documento}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi mdi-file-plus-outline ")])],1)]}}])},[_c('span',[_vm._v("Nuova Istruzione di Lavoro")])])],1)]},proxy:true},{key:"item.cod_articolo",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.articoli_documento,"item-text":"cod_articolo","item-value":"id_articolo","label":"Codice Articolo"},on:{"change":function($event){return _vm.cambiaCodArticolo(item)}},model:{value:(item.id_articolo),callback:function ($$v) {_vm.$set(item, "id_articolo", $$v)},expression:"item.id_articolo"}})]}},{key:"item.cod_articolo_cli",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.cod_articolo_cli),callback:function ($$v) {_vm.$set(item, "cod_articolo_cli", $$v)},expression:"item.cod_articolo_cli"}})]}},{key:"item.des",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.des),callback:function ($$v) {_vm.$set(item, "des", $$v)},expression:"item.des"}})]}},{key:"item.um",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.um),callback:function ($$v) {_vm.$set(item, "um", $$v)},expression:"item.um"}})]}},{key:"item.qta",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"change":function($event){return _vm.calcola(item)}},model:{value:(item.qta),callback:function ($$v) {_vm.$set(item, "qta", $$v)},expression:"item.qta"}})]}},{key:"item.qta_res",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.qta_res),callback:function ($$v) {_vm.$set(item, "qta_res", $$v)},expression:"item.qta_res"}})]}},{key:"item.prezzo_netto",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.prezzo_netto),callback:function ($$v) {_vm.$set(item, "prezzo_netto", $$v)},expression:"item.prezzo_netto"}})]}},{key:"item.sconto",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.sconto),callback:function ($$v) {_vm.$set(item, "sconto", $$v)},expression:"item.sconto"}})]}},{key:"item.imp_tot",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.imp_tot),callback:function ($$v) {_vm.$set(item, "imp_tot", $$v)},expression:"item.imp_tot"}})]}},{key:"item.iva",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.iva),callback:function ($$v) {_vm.$set(item, "iva", $$v)},expression:"item.iva"}})]}},{key:"item.iva_tot",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.iva_tot),callback:function ($$v) {_vm.$set(item, "iva_tot", $$v)},expression:"item.iva_tot"}})]}},{key:"item.data_cons",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.data_cons),callback:function ($$v) {_vm.$set(item, "data_cons", $$v)},expression:"item.data_cons"}})]}},{key:"item.data_conf",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.data_conf),callback:function ($$v) {_vm.$set(item, "data_conf", $$v)},expression:"item.data_conf"}})]}},{key:"item.stato",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.stato),callback:function ($$v) {_vm.$set(item, "stato", $$v)},expression:"item.stato"}})]}},{key:"item.prezzo_netto_listino",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{model:{value:(item.prezzo_netto_listino),callback:function ($$v) {_vm.$set(item, "prezzo_netto_listino", $$v)},expression:"item.prezzo_netto_listino"}})]}},{key:"item.articolo",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.openDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-folder-search-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Apri articoli ")])])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina Associzione ")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.nuova_riga_documento}},[_vm._v(" Nuovo ")])]},proxy:true}],null,true)})]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }