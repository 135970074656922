<template>
  <div>
    <v-dialog v-model="dialogArticolo" persistent max-width="1100">
      <v-card>
        <v-toolbar dense dark class="indigo darken-1 headline" primary-title>
          <h6 class="mt-n4 white--text">Articolo</h6>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white" @click="closeModal" small>
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  style="float: right"
                  color="#c00000"
                >
                  mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-form @keyup.enter.native="searchResult1">
          <v-row class="mx-auto ml-auto" align="center">
            <v-col class="ml-auto" sm="8" md="3">
              <v-text-field
                class="shrink"
                v-model="cod_articolo"
                label="Cod Articolo"
                required
              ></v-text-field>
            </v-col>
            <v-col class="mr-auto" sm="3" md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="searchResult1" small>
                    <v-icon
                      v-on="on"
                      class
                      v-bind="attrs"
                      size="32"
                      color="#1a80b6"
                      >mdi-magnify</v-icon
                    >
                  </v-btn>
                </template>
                <span>Cerca Articolo</span>
              </v-tooltip>
            </v-col>
            <v-col sm="2" xs="12" md="2">
              <v-autocomplete
                v-model="id_tipo"
                :items="this.tipoList"
                label="Tipo Articolo"
                item-text="des"
                required
                return-object
              >
                <template v-slot:selection="data">
                  <v-chip
                    color="light-blue lighten-4"
                    small
                    class="mb-0 mt-1"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="tiporemove(data.item)"
                    >{{ data.item.des }}</v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols sm="4" xs="12" md="3">
              <v-autocomplete
                v-model="id_famiglia"
                :items="famigliaList"
                item-text="des"
                required
                return-object
                label="Tipo Famiglia"
              >
                <template v-slot:selection="data">
                  <v-chip
                    color="light-blue lighten-4"
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    class="mb-0 mt-1"
                    @click:close="remove(data.item)"
                    >{{ data.item.des }}</v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="mr-auto" cols sm="4" xs="12" md="3"> </v-col>
          </v-row>
        </v-form>

        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-row>
            <v-col md="12">
              <v-data-table
                :footer-props="{ 'items-per-page-options': [100, -1] }"
                :search="search"
                :headers="this.headers"
                :items="tableData"
              >
                <template v-slot:[`item.actions`]="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        @click="onDetail(props.item)"
                      >
                        <v-icon style="float: left" color="#009999"
                          >mdi-pencil</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Dettaglio Articolo</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import config from '../../../../public/config'
export default {
  props: [
    "dialog",
    "compdata",
    "headers",
    "tipoList",
    "famigliaList",
    "codArticolo",
  ],
  data() {
    return {
      // Variables needed in Search Modal
      search: "",
      cod_articolo: this.codArticolo,
      id_tipo: "",
      id_famiglia: "",
      dialogArticolo: this.dialog,
      tableData: this.compdata,
    };
  },

  methods: {
    // When try get detail of single item
    onDetail(value) {
      this.$emit("onDetail", value);
    },

    modalSearch() {
      let value = {
        cod_articolo: this.cod_articolo,
        id_tipo: this.id_tipo,
        id_famiglia: this.id_famiglia,
      };

      this.$emit("modalSearch", value);
    },
    // function triggered inside the searchModal
    searchResult1() {
      let search_Arti_req = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          db_name: "quickproduction",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
          id_famiglia:
            this.id_famiglia.id_tabella == undefined
              ? ""
              : this.id_famiglia.id_tabella,
          id_tipo:
            this.id_tipo.id_tabella == undefined ? "" : this.id_tipo.id_tabella,
          id_utente: localStorage.id,
        },
      };
      this.$store.dispatch("api", search_Arti_req).then((res) => {
        this.completeData = res.data.lista;

        if (this.completeData.length > 1) {
          this.overlay = false;
          this.tableData = this.completeData.lista;

          this.dialog = true;
        }

        // if (this.completeData.lista.length === 0) {
        //   this.tableData = []
        //   this.overlay = true;

        //   this.snackbar = true
        //      this.closeModal()

        // }

        this.tableData = this.completeData;
        this.sendVal(this.tableData);
      });
    },
    // In order to close the modal
    closeModal() {
      this.dialogArticolo = false;
      this.$emit("closeModal");
    },

    // Sending the values from modal to detail component

    sendVal() {
      this.$emit("sendVal", this.tableData);
    },
  },
  mounted() {
    // console.log("this.dialog", this.dialog);
  },
};
</script>

<style css scoped></style>
