<template>
  <div style="width: 100%;">
    <!--
     <v-app-bar dark  clipped-left color="primary" dense rounded>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>

        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app clipped>
          <v-list rounded dense>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group v-model="menu_sel" color="#3F51B5">
              <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
                <v-list-item-icon>
                  <v-icon v-text="item.icon[0]"></v-icon>
                  <v-icon small v-text="item.icon[1]"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        -->
        <router-view />

  
  </div>
</template>
<script>
// import Packaging from "../LOGISTICA/Packaging.vue";
// import IngressoMateriale from "../LOGISTICA/Ingresso_materiale.vue";
// import UscitaMateriale from "../LOGISTICA/Uscita_materiale.vue";
// import ContoLavoroIngresso from "../LOGISTICA/Conto_lavoro_ingresso.vue";
// import ContoLavoroUscita from "../LOGISTICA/Conto_lavoro_uscita.vue";
export default {
  data() {
    return {
      modalTab: "packaging",
      drawer: false,
      group: null,
      menu_sel: null,
      items: [
        {
          id: 1,
          text: "Packaging",
          icon: ["mdi-package-variant"],
          path: "/home/tools/logistica/packaging",
        },
        {
          id: 2,
          text: "Ingresso materiale",
          icon: ["mdi-dolly","mdi-arrow-right"],
          path: "/home/tools/logistica/ingresso_materiale",
        },
        {
          id: 3,
          text: "Uscita materiale",
          icon: ["mdi-dolly","mdi-arrow-left"],
          path: "/home/tools/logistica/uscita_materiale",
        },
        {
          id: 4,
          text: "Conto lavoro ingresso",
          icon: ["mdi-cog","mdi-arrow-right"],
          path: "/home/tools/logistica/conto_lavoro_ingresso",
        },
        {
          id: 5,
          text: "Conto lavoro uscita",
          icon:  ["mdi-cog","mdi-arrow-left"],
          path: "/home/tools/logistica/conto_lavoro_uscita",
        },
      ],
    };
  },
  components: {
    // Packaging,
    // IngressoMateriale,
    // UscitaMateriale,
    // ContoLavoroIngresso,
    // ContoLavoroUscita,
  },
};
</script>