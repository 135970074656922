var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_anagrafiche,"items":_vm.items_anagrafiche},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"white--text rounded-t",attrs:{"primary-title":"","color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v("Anagrafiche "+_vm._s(_vm.tipo_anagrafiche_visualizzate == "C" ? "Clienti" : "Fornitori"))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.clicAkdd}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Nuova Anagrafica")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.getAnagrafiche('C')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-account ")])],1)]}}])},[_c('span',[_vm._v("Anagrafica Clienti")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.getAnagrafiche('F')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-truck ")])],1)]}}])},[_c('span',[_vm._v("Anagrafica Fornitori")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1)]},proxy:true},{key:"item.dettagli",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickDettagli(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio Anagrafica")])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dettagli_anagrafica_visible),callback:function ($$v) {_vm.dettagli_anagrafica_visible=$$v},expression:"dettagli_anagrafica_visible"}},[_c('DettagliAnagrafica',{attrs:{"anagrafica_sel":_vm.anagrafica_sel},on:{"aggiorna_anagrafica":_vm.aggiornaAnagrafica,"aggiungi_anagrafica":_vm.aggiungiAnagrafica,"click_chiudi":function($event){_vm.dettagli_anagrafica_visible = false}}})],1),_c('v-overlay',{attrs:{"value":_vm.caricamento_dati}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }