<template>
  <div style="width: 100%">
    <v-row> <PianoControllo /></v-row>
  </div>
</template>
<script>
import PianoControllo from "../../../components/Qualita/PianoControlo/PianoControllo";
export default {
  components: {
    PianoControllo,
  },
  data() {
    return {};
  },
};
</script>
