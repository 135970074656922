<template>
  <div class="pt-5 px-5">
    <TabellaAnagrafiche
    :concessionari_visible="true"
    />
  </div>
</template>
<script>
import TabellaAnagrafiche from "@/components/MANUTENZIONE/TabellaAnagrafiche.vue";

export default {
  name: "ManutenzioneAnagrafiche",
  components: { TabellaAnagrafiche },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      headers_scelti_anagrafiche: [
        { text: "Dettagli", sortable: false, value: "dettagli" },
        { text: "Codice", sortable: false, value: "codice" },
        { text: "Ragione Sociale", sortable: false, value: "ragione_sociale" },
        { text: "Codice Fiscale", sortable: false, value: "codice_fiscale" },
        { text: "Nazione", sortable: false, value: "des_nazione" },
        { text: "Regione", sortable: false, value: "des_regione" },
        { text: "Provincia", sortable: false, value: "des_provincia" },
        { text: "Località", sortable: false, value: "des_localita" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "P. IVA", sortable: false, value: "p_iva" },
        { text: "CAP", sortable: false, value: "cap" },
        { text: "Telefono", sortable: false, value: "telefono" },
        {
          text: "sigla REA",
          sortable: false,
          value: "sigla_rea",
          align: "center",
        },
        { text: "Codice REA", sortable: false, value: "codice_rea" },
      ],

      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>