<template>
  <PdfConvert :back_as_landing_page="true" :rotta_back="'/home/electrical'" />
</template>
<script>
import PdfConvert from "@/views/TOOLS/PdfConvert.vue";

export default {
  name: "EMPdfConvert",
  components: { PdfConvert },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>