var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":false,"persistent":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi Risorse")])])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',[_vm._v("Crea Risorsa")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","disabled":!_vm.valid || _vm.saving},on:{"click":function($event){return _vm.validate()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Salva Risorsa")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-container',{staticClass:"px-5",attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"text-start"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.utenti,"label":"Nome Risorsa","item-value":"id_personale","item-text":function (item) { return ((item.nome) + " " + (item.cognome)); },"placeholder":"Digita per cercare"},model:{value:(_vm.single_item.id_risorsa),callback:function ($$v) {_vm.$set(_vm.single_item, "id_risorsa", $$v)},expression:"single_item.id_risorsa"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Ore assegnate","type":"number","min":"0","max":"1000","step":"1","required":"","onkeydown":"javascript: return event.keyCode == 69 ? false : true"},model:{value:(_vm.single_item.ore_assegnate),callback:function ($$v) {_vm.$set(_vm.single_item, "ore_assegnate", $$v)},expression:"single_item.ore_assegnate"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }