<template>
  <EditMagazzino
    :id="id"
    :duplicateId="duplicateId"
  />
</template>

<script>
import EditMagazzino from '@/components/MAGAZZINO/EditMagazzino.vue';

export default {
  name: 'EditMagazzinoView',
  props: ['id', 'duplicateId'],
  components: {
    EditMagazzino,
  },
}
</script>
