<template>
  <v-card elevation="3" outlined class="mx-4 mb-3 pt-5">
    <v-container class="my-0 pa-0">
      <v-row
        id="chatHistory"
        class="mb-5 mx-2 px-2"
        style="min-height: 200px; max-height: 500px; overflow-y: scroll"
      >
        <div
          v-for="(messaggio, i) in bacheca"
          :key="i"
          :style="{
            display: 'block',
            textAlign: `${messaggio.id_utente == id_utente ? 'right' : 'left'}`,
            width: '100%',
          }"
        >
          <v-card
            class="elevation-2 my-1 pa-0"
            :style="{
              backgroundColor: `${
                messaggio.id_utente == id_utente ? '#ECEFF1' : '#3f51b5'
              }`,
              borderRadius: '15px',
              display: 'inline-block',
              width: 'fit-content',
              maxWidth: '75%',
            }"
          >
            <v-card-text class="py-0">
              <span
                :style="{
                  color: `${
                    messaggio.id_utente == id_utente ? 'black' : 'white'
                  }`,
                }"
                >{{ messaggio.messaggio }}</span
              >
              <div style="text-align: right">
                <span
                  :style="{
                    color: `${
                      messaggio.id_utente == id_utente ? '#ECEFF' : '#FFFFFF'
                    }`,
                    fontSize: '10px',
                  }"
                >
                  <i>
                    {{ messaggio.nominativo }}, {{ messaggio.data_ins }}
                  </i>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-row>

      <v-toolbar class="elevation-0" color="rgba(255,255,255,0)" dense>
        <v-spacer></v-spacer>
        <div class="mr-5" style="display: inline-block; width: 200px">
          <v-progress-linear
            :value="avanzamento_barra"
            v-if="salva_messaggio_loader === true"
            width="200"
          >
          </v-progress-linear>
        </div>
        <v-tooltip bottom v-if="annulla_salva_messaggio_visible === false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickSalvaMessaggio"
              small
              style="display: inline-block"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>Salva Messaggio</span>
        </v-tooltip>

        <v-tooltip bottom v-if="annulla_salva_messaggio_visible === true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickAnnullaSalvaMessaggio"
              small
              style="display: inline-block"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Annulla Salvataggio</span>
        </v-tooltip>
      </v-toolbar>

      <!-- Textarea del nuovo messaggio -->
      <v-row class="my-0 pa-0">
        <v-textarea
          class="mx-5"
          label="Nuovo Messaggio"
          outlined
          v-model="nuovo_messaggio"
        ></v-textarea>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    id: { type: Number, default: -1 },
    tipo: { type: String, default: "" },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      avanzamento_barra: 0,
      id_utente: null,
      nuovo_messaggio: "",

      // Modal
      annulla_salva_messaggio_visible: false,
      salva_messaggio_loader: false,
      stopSetBacheca: false,

      // Array
      bacheca: [],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      async handler() {
        console.log("entrato handler");
        // Quando il componente diventa visibile eseguo le inizializzazioni

        if (this.visible === true) {
          // Mi segno l'id dell'utente
          this.id_utente = localStorage.id;

          // Resetto le variabili di supporto
          this.nuovo_messaggio = "";

          /* Popolo la bacheca (se la richiesta non è nuova);
           * il metodo getBacheca azzera automaticamente l'array
           * di supporto prima di riempirlo con i dati del BE.*/
          if (this.id != -1) {
            this.getBacheca();
          }
        }
      },
    },
  },
  methods: {
    clickAnnullaSalvaMessaggio() {
      this.stopSetBacheca = true;
      this.annulla_salva_messaggio_visible = false;
      this.salva_messaggio_loader = false;
      // this.avanzamento_barra = 0;
    },
    clickSalvaMessaggio() {
      this.annulla_salva_messaggio_visible = true;
      this.salva_messaggio_loader = true;
      this.stopSetBacheca = false;

      let interval = setInterval(() => {
        if (this.avanzamento_barra === 100) {
          clearInterval(interval);
          this.setBacheca();
          this.avanzamento_barra = 0;
          this.nuovo_messaggio = "";
          this.annulla_salva_messaggio_visible = false;
          this.salva_messaggio_loader = false;
          this.stopSetBacheca = false;
        } else {
          if (this.stopSetBacheca === false) {
            this.avanzamento_barra += 1;
            console.log("int");
          } else {
            this.avanzamento_barra = 0;
            this.nuovo_messaggio = "";
            this.annulla_salva_messaggio_visible = false;
            this.salva_messaggio_loader = false;
            this.stopSetBacheca = false;
            clearInterval(interval);
          }
        }
      }, 50);
    },
    getBacheca() {
      // Svuoto l'array bacheca prima di prendere i dati del BE.
      this.bacheca.length = 0;

      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getbacheca",
          token: localStorage.user_token,
          tipo: this.tipo,
          id: this.id,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          res.data.result.messaggi.forEach((elem) => {
            this.bacheca.push(Object.assign({}, elem));

            this.$nextTick(() => {
              /* Scrollo in fondo alla finestra della history
               * (altrimenti faccio cmq la stessa cosa nel mounted). */
              if (document.getElementById("chatHistory")) {
                const element = document.getElementById("chatHistory");
                element.scrollTop = element.scrollHeight - element.clientHeight;
              }
            });
          });
        }
      });
    },
    setBacheca() {
      // Preparo il messaggio da salvare.
      const mex = {
        messaggio: this.nuovo_messaggio,
        tipo: this.tipo,
      };

      /* A seconda che la del tipo prop sia richiesta
       * o intervento, metto un campo nominato come conviene. */
      if (this.tipo === "richiesta") {
        mex.id_richiesta_messaggio = -1;
        mex.id_richiesta = this.id;
      } else if (this.tipo === "intervento") {
        mex.id_intervento_messaggio = -1;
        mex.id_intervento = this.id;
      }

      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setbacheca",
          token: localStorage.user_token,
          messaggio: [mex],
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.getBacheca();
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>