<template>
  <div style="padding: 20px">
    <v-row v-if="assistenza">
      <v-col cols="6" md="1" sm="2" class="py-0">
        <v-chip
          @click="clickLink(link)"
          class="ma-5"
          color="error"
          label
          text-color="white"
        >
          <v-icon left>mdi-youtube</v-icon>Link
        </v-chip>
      </v-col>
      <v-col cols="6" md="3" sm="10" class="py-0">
        <v-text-field
          v-model="link"
          readonly
          @click="clickLink(link)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2" style="min-height: 800px">
              <!-- Bottone di creazione commessa singola -->
              <v-toolbar class="indigo white--text" dense primary-title>
                <v-toolbar-title>
                  Lista Ticket
                  {{ assistenza == 1 ? "Assistenza" : "" }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      class="mr-2"
                      @click="openSettings()"
                      v-show="
                        assistenza == 1 &&
                        tipo_permesso == 1 &&
                        (auth_level & (1 << 0)) > 0
                      "
                    >
                      <v-icon style="color: #1a80b6">mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>Impostazioni</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" small @click="newItem()">
                      <v-icon style="color: #1a80b6">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Crea Ticket {{ assistenza == 1 ? "Assistenza" : "" }}</span
                  >
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="gotoDashboard"
                      small
                    >
                      <v-icon color="error">mdi-keyboard-backspace</v-icon>
                    </v-btn>
                  </template>
                  <span>Vai alla Dashboard</span>
                </v-tooltip>
              </v-toolbar>
              <v-progress-linear
                indeterminate
                color="cyan"
                v-if="loading"
                id="progress"
              >
              </v-progress-linear>
              <v-card-text>
                <v-alert v-if="delete_item" type="success">
                  Ticket {{ assistenza == 1 ? "Assistenza" : "" }} eliminato con
                  successo
                </v-alert>

                <v-alert v-if="create_item" type="success">
                  Ticket n° {{ this.create_item }} salvato con successo
                </v-alert>
                <v-container fluid>
                  <v-row class="align-center">
                    <v-col
                      class="py-0 pb-2"
                      cols="7"
                      :md="assistenza == 0 ? 3 : tipo_permesso == 0 ? 4 : 5"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Cerca"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="py-0 pb-2"
                      cols="5"
                      md="3"
                      v-if="assistenza == 0 || tipo_permesso == 1"
                    >
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="ris_ticket"
                        :items="risorse"
                        label="Risorsa"
                        item-text="nominativo"
                        item-value="nominativo"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      class="py-0 pb-2"
                      cols="5"
                      md="3"
                      v-if="assistenza == 1 && tipo_permesso == '1'"
                    >
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="utente_ticket"
                        :items="utentiRichieste"
                        label="Utente"
                        item-text="utente"
                        item-value="utente"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      class="py-0 pb-2"
                      cols="5"
                      :md="assistenza == 0 ? 4 : 3"
                    >
                      <v-select
                        outlined
                        dense
                        multiple
                        hide-details
                        v-model="stato"
                        :items="stati"
                        label="Stato"
                        item-text="des_stato"
                        item-value="des_stato"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      v-if="
                        assistenza == 0 ||
                        (assistenza == 1 && tipo_permesso == 1)
                      "
                      class="py-0 pb-2"
                      cols="5"
                      md="2"
                    >
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="tipologia"
                        :items="[
                          { tipo: -1, tipologia: 'Tutto' },
                          { tipo: 0, tipologia: 'Ticket' },
                          { tipo: 1, tipologia: 'Task' },
                        ]"
                        label="Tipologia"
                        item-text="tipologia"
                        item-value="tipo"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      v-if="assistenza == 1"
                      class="py-0 pb-2"
                      cols="5"
                      md="2"
                    >
                      <v-select
                        outlined
                        dense
                        hide-details
                        v-model="tipo_assistenza"
                        :items="
                          tipiAssistenza.filter(
                            (tipo) => tipo.des_tipo_assistenza != null
                          )
                        "
                        label="Tipo Assistenza"
                        item-text="des_tipo_assistenza"
                        item-value="des_tipo_assistenza"
                      >
                      </v-select>
                    </v-col>
                    <v-col class="py-0 pb-2" cols="5" md="2">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="start_date"
                            outlined
                            label="Da data"
                            autocomplete="off"
                            persistent-hint
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          no-title
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="py-0 pb-2" cols="5" md="2">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="end_date"
                            outlined
                            label="A data"
                            autocomplete="off"
                            persistent-hint
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="end_date"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="py-0 pb-2" cols="1" md="1">
                      <v-btn @click="resetFiltri" outlined color="error"
                        >RESET</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="
                          headers.filter(
                            (e) =>
                              e.visible != false &&
                              (assistenza == '1' && tipo_permesso == '0'
                                ? e.value != 'utente' && e.value != 'nominativo'
                                : true)
                          )
                        "
                        :search="search"
                        :items="
                          assistenza == 1 && tipologia != 1
                            ? lista_tabella.filter(
                                (el) => el.id_tipo_ticket == 0
                              )
                            : tipologia != 1
                            ? lista_tabella.filter(
                                (el) => el.id_tipo_ticket == 0
                              )
                            : lista_tabella
                        "
                        :items-per-page="10"
                        class="elevation-1"
                        :single-expand="
                          (assistenza == 1 &&
                            tipo_permesso == '1' &&
                            tipologia != 1) ||
                          (assistenza == 0 && tipologia != 1)
                            ? singleExpand
                            : false
                        "
                        :expanded.sync="expanded"
                        item-key="id_ticket"
                        :show-expand="
                          (assistenza == 1 &&
                            tipo_permesso == '1' &&
                            tipologia != 1) ||
                          (assistenza == 0 && tipologia != 1)
                            ? singleExpand
                            : false
                        "
                        :page="current_page"
                        :options.sync="pagination"
                      >
                        <template
                          v-if="
                            (assistenza == 1 &&
                              tipo_permesso == '1' &&
                              tipologia != 1) ||
                            (assistenza == 0 && tipologia != 1)
                          "
                          v-slot:expanded-item="{ headers, item }"
                        >
                          <td
                            class="pa-0"
                            style="background-color: white"
                            :colspan="headers.length"
                          >
                            <v-data-table
                              :headers="
                                headers.filter((e) => e.visible != false)
                              "
                              :items="
                                lista_tabella.filter(
                                  (el) =>
                                    el.id_tipo_ticket == 1 &&
                                    el.id_ticket_padre == item.id_ticket
                                )
                              "
                              item-key="id_ticket_attivita"
                              :items-per-page="15"
                              class="elevation-1"
                              hide-default-footer
                            >
                              <template v-slot:[`item.modifica`]="{ item }">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="editItem(lista.indexOf(item))"
                                    >
                                      <v-icon medium class="primary--text">
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    >Modifica
                                    {{
                                      item.id_tipo_ticket == 1
                                        ? "Task"
                                        : "Ticket"
                                    }}
                                    {{
                                      assistenza == 1 ? "Assistenza" : ""
                                    }}</span
                                  >
                                </v-tooltip>
                              </template>
                              <template v-slot:[`item.add`]="{ item }">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <ActivitiesInterface
                                      v-on:emitClose="callList()"
                                      v-on="on"
                                      v-bind="attrs"
                                      :ticket="item"
                                      :showDialog="true"
                                      :assistenza="assistenza"
                                    ></ActivitiesInterface>
                                  </template>
                                  <span>Aggiungi Attività</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-slot:[`item.avanzamento_lavori`]="{ item }"
                              >
                                <!-- v-model="skill" -->
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-progress-linear
                                      color="light-green"
                                      height="25"
                                      striped
                                      v-bind="attrs"
                                      v-on="on"
                                      :value="
                                        item.min_previsti == 0
                                          ? item.min_lavorati == 0
                                            ? 0
                                            : 100
                                          : (item.min_lavorati * 100) /
                                            item.min_previsti
                                      "
                                    >
                                      <template>
                                        <v-row class="d-flex align-center px-2">
                                          <v-col cols="12" class="py-0">
                                            <strong
                                              >{{
                                                Math.ceil(
                                                  item.min_previsti == 0
                                                    ? item.min_lavorati == 0
                                                      ? 0
                                                      : 100
                                                    : (item.min_lavorati *
                                                        100) /
                                                        item.min_previsti
                                                )
                                              }}%</strong
                                            >
                                          </v-col>
                                        </v-row>
                                      </template>
                                    </v-progress-linear>
                                  </template>
                                  <p class="py-0 my-0">
                                    Min lavorati: {{ item.min_lavorati }}
                                  </p>
                                  <p class="py-0 my-0">
                                    Min Previsti: {{ item.min_previsti }}
                                  </p>
                                </v-tooltip>
                              </template>
                              <template v-slot:[`item.des_stato`]="{ item }">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      style="cursor: default"
                                      :color="
                                        item.des_stato != null
                                          ? getColor(item.des_stato)
                                          : 'grey'
                                      "
                                      >{{ getIcon(item.des_stato) }}</v-icon
                                    >
                                  </template>
                                  <span>{{
                                    item.des_stato
                                      ? item.des_stato
                                      : "Nessuno stato"
                                  }}</span>
                                </v-tooltip>
                              </template>
                              <template v-slot:[`item.edit`]="{ item }">
                                <AddActivity
                                  :assistenza="assistenza"
                                  :item="item"
                                  v-on:dialogActivity="emitActivities"
                                />
                              </template>
                              <template v-slot:[`item.time`]="{ item }">
                                {{ item.ore }} {{ item.unita_misura }}
                              </template>
                              <template v-slot:[`item.data_inizio`]="{ item }">
                                {{ convertDate(item.data_inizio) }}
                              </template>
                              <template v-slot:[`item.data_fine`]="{ item }">
                                {{ convertDate(item.data_fine) }}
                              </template>
                              <template v-slot:[`item.delete`]="{ item }">
                                <v-icon
                                  medium
                                  @click="
                                    deleteItemAttivita(attivita.indexOf(item))
                                  "
                                >
                                  mdi-close
                                </v-icon>
                              </template>
                            </v-data-table>
                          </td>
                        </template>
                        <template v-slot:[`item.data_ticket`]="{ item }">
                          {{ getFormattedData(item.data_ticket) }}
                        </template>
                        <template v-slot:[`item.des_stato`]="{ item }">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                style="cursor: default"
                                :color="
                                  item.des_stato != null
                                    ? getColor(item.des_stato)
                                    : 'grey'
                                "
                                >{{ getIcon(item.des_stato) }}</v-icon
                              >
                            </template>
                            <span>{{
                              item.des_stato ? item.des_stato : "Nessuno stato"
                            }}</span>
                          </v-tooltip>
                        </template>

                        <template v-slot:[`item.numero`]="{ item }">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                style="cursor: default"
                                >{{
                                  item.numero
                                    ? item.numero
                                    : "Numero non inserito"
                                }}</span
                              >
                            </template>
                            <span
                              >{{
                                item.id_tipo_ticket == 1 ? "Task" : "Ticket "
                              }}
                              {{ assistenza == 1 ? "Assistenza" : "" }}</span
                            >
                          </v-tooltip>
                        </template>

                        <template v-slot:[`item.avanzamento_lavori`]="{ item }">
                          <!-- v-model="skill" -->
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-progress-linear
                                color="light-green"
                                height="25"
                                striped
                                v-bind="attrs"
                                v-on="on"
                                :value="
                                  item.min_previsti == 0
                                    ? item.min_lavorati == 0
                                      ? 0
                                      : 100
                                    : (item.min_lavorati * 100) /
                                      item.min_previsti
                                "
                              >
                                <template>
                                  <v-row class="d-flex align-center px-2">
                                    <v-col cols="12" class="py-0">
                                      <strong
                                        >{{
                                          Math.ceil(
                                            item.min_previsti == 0
                                              ? item.min_lavorati == 0
                                                ? 0
                                                : 100
                                              : (item.min_lavorati * 100) /
                                                  item.min_previsti
                                          )
                                        }}%</strong
                                      >
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-progress-linear>
                            </template>
                            <p class="py-0 my-0">
                              Min lavorati: {{ item.min_lavorati }}
                            </p>
                            <p class="py-0 my-0">
                              Min Previsti: {{ item.min_previsti }}
                            </p>
                          </v-tooltip>
                        </template>

                        <template v-slot:[`item.progetto`]="{ item }">
                          {{ getProgetto(item.elem.id_progetto) }}
                        </template>

                        <template v-slot:[`item.modulo`]="{ item }">
                          {{ getModulo(item.elem.id_modulo) }}
                        </template>

                        <template v-slot:[`item.modifica`]="{ item }">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="editItem(lista.indexOf(item))"
                              >
                                <v-icon medium class="primary--text">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span
                              >Modifica
                              {{ item.id_tipo_ticket == 1 ? "Task" : "Ticket" }}
                              {{ assistenza == 1 ? "Assistenza" : "" }}</span
                            >
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.add`]="{ item }">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <ActivitiesInterface
                                v-on:emitClose="callList()"
                                v-on="on"
                                v-bind="attrs"
                                :ticket="item"
                                :showDialog="true"
                                :assistenza="assistenza"
                              ></ActivitiesInterface>
                            </template>
                            <span>Aggiungi Attività</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog_setting" width="800">
      <v-card>
        <v-toolbar dense color="indigo" dark>
          <v-row align="center" dark>
            <v-col cols="5" align="left">
              <v-toolbar-title
                >Configura Azienda > {{ nome_azienda }}</v-toolbar-title
              >
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    small
                    light
                    @click="salvaSettings"
                  >
                    <v-icon color="success">mdi-floppy</v-icon>
                  </v-btn>
                </template>
                <span>Salva Impostazioni Assistenza</span>
              </v-tooltip>
            </v-col>
            <v-col cols="5" align="right">
              <v-btn small light @click="dialog_setting = false">
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-container>
          <v-row class="my-1">
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                dense
                label="E-mail"
                v-model="emailSettings"
                :rules="emailRules"
                autofocus
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                min="0"
                dense
                label="Ore disponibili"
                v-model="oreSettings"
                type="number"
              >
              </v-text-field
            ></v-col>
            <v-col cols="12" md="6">
              <!-- <v-text-field
                dense
                label="Valore generico tipo assistenza"
                v-model="valoreAssistenzaSettings"
              >
              </v-text-field
            > -->
              <v-select
                dense
                hide-details
                v-model="valoreAssistenzaSettings"
                :items="tipi_assistenza"
                label="Valore generico tipo assistenza"
                item-text="des"
                item-value="id_tabella"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import ActivitiesInterface from "./ActivitiesInterface";

export default {
  name: "ListTicket",
  data() {
    return {
      auth_level: localStorage.auth_level,
      link: "https://www.youtube.com/watch?v=raXlb2eX3s4&feature=youtu.be",
      codProfilo: "",
      current_page: null,
      end_date: null,
      emailSettings: "",
      nome_azienda: localStorage.prefisso_utente,
      oreSettings: 0,
      pagination: null,
      prec_pagina: null,
      ris_ticket: null,
      search: "",
      start_date: null,
      tipo_assistenza: null,
      ticket_info: null,
      tipo_ticket: null,
      tipologia: null,
      tipi_assistenza: [],
      tipo_permesso: 0,
      utente_ticket: null,
      valoreAssistenzaSettings: 0,

      all_status: [],
      expanded: [],
      headers: [],
      lista: [],
      lista_tabella: [],
      emailRules: [
        (v) => !!v || "Email necessaria",
        (v) =>
          /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(
            v
          ) ||
          "Formato email non corretto (separazione email multiple con virgola)",
      ],
      single_item: [],
      stato: [],

      create_item: false,
      delete_item: false,
      dialog_setting: false,
      loading: false,
      menu: false,
      menu2: false,
      singleExpand: true,

      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
    };
  },
  props: ["assistenza"],
  components: {
    ActivitiesInterface,
  },
  watch: {
    // tipo_ticket: function (newT, oldT) {
    //   if (oldT != newT) {
    //     this.tipo_ticket = newT;
    //     callList(this, this.tipo_ticket);
    //   }
    // },
    assistenza: {
      immediate: true,
      handler() {
        this.headers = [];
        this.lista = [];
        this.lista_tabella = [];
        this.created();
      },
    },
    current_page: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    end_date: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    pagination: {
      immediate: true,
      handler() {
        if (
          this.pagination != null &&
          (window.FILTRI_TICKET["current_page"] == null ||
            window.FILTRI_TICKET["current_page"] == undefined ||
            this.current_page == this.pagination.page - 1 ||
            this.current_page == this.pagination.page + 1)
        ) {
          this.current_page = this.pagination.page;
        }
      },
    },
    ris_ticket: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    search: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    start_date: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    stato: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    tipo_assistenza: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    tipologia: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
    utente_ticket: {
      immediate: true,
      handler() {
        this.setFiltriTicket();
        this.filtro();
      },
    },
  },
  created() {
  },
  computed: {
    risorse() {
      let ris = [];
      ris.push({ nominativo: "Tutti" });
      this.lista.map((item) => ris.push(item));

      return ris;
    },
    stati() {
      let states = [];
      states.push({ des_stato: "Tutti" });
      this.lista.map((item) => states.push(item));
      console.log(this.lista);

      return states;
    },
    tipiAssistenza() {
      let types = [];
      types.push({ des_tipo_assistenza: "Tutti" });
      this.lista.map((item) => types.push(item));

      return types;
    },
    utentiRichieste() {
      let ris = [];
      ris.push({ utente: "Tutti" });
      this.lista.map((item) => ris.push(item));

      return ris;
    },
  },
  methods: {
    callList() {
      this.loading = true;
      if (this.codProfilo != "") {
        var request = {
          controller: "Ticket",
          method: "POST",
          richiesta: {
            action: "getticket",
            token: localStorage.user_token,
            id_stato: "",
            tipo_ticket:
              this.assistenza == 0 ? null : this.tipo_permesso == 1 ? null : 0,
            id_ticket: null,
            assistenza: this.assistenza,
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.headers = [];
          if (res.data.result.tipo_permesso != undefined) {
            this.tipo_permesso = res.data.result.tipo_permesso;
            this.singleExpand = this.tipo_permesso == 1;
          }
          if (
            this.assistenza == 0 ||
            (this.assistenza == 1 && this.tipo_permesso == 1)
          ) {
            this.headers.push({
              text: "Aggiungi",
              value: "add",
              align: "center",
              valide: true,
            });
          }
          this.headers.push({
            text: "Modifica",
            value: "modifica",
            align: "center",
            valide: true,
          });

          if (res.data.result != undefined) {
            if (res.data.result.header != undefined) {
              res.data.result.header.forEach((element) => {
                this.headers.push(element);
              });
            }
            if (res.data.result.lista != undefined) {
              this.lista = res.data.result.lista;
              this.lista_tabella = res.data.result.lista;
            }
          }

          if (
            this.assistenza == 0 ||
            (this.assistenza == 1 && this.tipo_permesso == "1")
          ) {
            this.headers.push({
              text: "Avanzamento lavori",
              value: "avanzamento_lavori",
              width: "15%",
            });
          }
          // this.headers.push({
          //   text: 'Elimina',
          //   value: 'elimina',
          // })
          if (window.FILTRI_TICKET != undefined) {
            this.tipologia = window.FILTRI_TICKET["tipologia"];
            this.stato = window.FILTRI_TICKET["stato"];
            this.ris_ticket = window.FILTRI_TICKET["ris_ticket"];
            this.current_page = window.FILTRI_TICKET["current_page"];
            this.utente_ticket = window.FILTRI_TICKET["utente_ticket"];
            this.search = window.FILTRI_TICKET["search"];
            this.start_date = window.FILTRI_TICKET["start_date"];
            this.end_date = window.FILTRI_TICKET["end_date"];
            this.filtro();
          }

          this.loading = false;
        });
      }
    },
    clickLink(link) {
      window.open(link, "_blank");
    },
    created() {
      this.codProfilo = localStorage.cod_profilo;
      this.callList();

      var getStatus = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          filter: "validita = 1",
          tipo: "PROGETTO_STATO",
          assistenza: this.assistenza,
        },
      };

      this.$store.dispatch("api", getStatus).then((res) => {
        if (res.data != "") {
          this.all_status = res.data;
          console.log("all status:",this.all_status);
        }
      });
    },
    deleteItem(id) {
      if (confirm("Sicuro di voler eliminare?")) {
        var getSingleItem = {
          controller: "Ticket",
          method: "POST",
          richiesta: {
            action: "getticket",
            token: localStorage.user_token,
            id_ticket: this.lista[id].id_ticket,
            id_stato: "",
            assistenza: this.assistenza,
          },
        };

        this.$store.dispatch("api", getSingleItem).then((res) => {
          this.single_item = res.data.result.ticket;
          this.single_item.validita = 0;

          var removeItem = {
            controller: "Ticket",
            method: "PUT",
            richiesta: {
              action: "setticket",
              token: localStorage.user_token,
              id_utente: localStorage.id,
              id_azienda: localStorage.id_azienda,
              tipologia: "",
              tipo_ticket: this.single_item.tipo_ticket,
              tabella: [this.single_item],
              assistenza: this.assistenza,
            },
          };

          this.$store.dispatch("api", removeItem).then();
          this.lista.splice(id, 1);

          this.delete_item = true;
          setTimeout(() => {
            this.delete_item = false;
          }, 2000);
        });
      }
    },
    editItem(id) {
      if (this.assistenza == 1) {
        this.$router.push({
          name: "SingleTicketAssistenza",
          params: {
            id: this.lista[id].id_ticket,
            tipo_permesso: this.tipo_permesso.toString(),
            assistenza: this.assistenza,
          },
        });
      } else {
        this.$router.push("/home/ticket/" + this.lista[id].id_ticket);
      }
    },
    filtro() {
      this.lista_tabella = this.lista;
      if (
        (this.ris_ticket != null && this.ris_ticket != "Tutti") ||
        (this.utente_ticket != null && this.utente_ticket != "Tutti") ||
        (this.tipo_assistenza != null && this.tipo_assistenza != "Tutti") ||
        (this.tipologia != null && this.tipologia != -1) ||
        this.start_date != null ||
        this.end_date != null ||
        this.stato.length > 0
      ) {
        if (this.tipo_assistenza != null && this.tipo_assistenza != "Tutti") {
          this.lista_tabella = this.lista_tabella.filter((el) => {
            return el.des_tipo_assistenza == this.tipo_assistenza;
          });
        }
        if (this.ris_ticket != null && this.ris_ticket != "Tutti") {
          this.lista_tabella = this.lista_tabella.filter((el) => {
            return el.nominativo == this.ris_ticket;
          });
        }
        if (this.utente_ticket != null && this.utente_ticket != "Tutti") {
          this.lista_tabella = this.lista_tabella.filter((el) => {
            return el.utente == this.utente_ticket;
          });
        }
        if (this.tipologia != null && this.tipologia != -1) {
          this.lista_tabella = this.lista_tabella.filter((el) => {
            return el.id_tipo_ticket == this.tipologia;
          });
        }
        if (this.start_date != null) {
          this.lista_tabella = this.lista_tabella.filter((el) => {
            return (
              Math.round(
                moment
                  .duration(
                    moment(
                      moment(String(el.data), "DD/MM/YY").format("YYYY-MM-DD")
                    ).diff(moment(this.start_date))
                  )
                  .asDays()
              ) >= 0
            );
          });
        }
        if (this.end_date != null) {
          this.lista_tabella = this.lista_tabella.filter((el) => {
            //DD-MM perchè partendo da 03/05/21 lo legge come MM-DD invece per noi sono DD-MM
            return (
              Math.round(
                moment.duration(
                  moment(this.end_date).diff(
                    moment(
                      moment(String(el.data), "DD/MM/YY").format("YYYY-MM-DD")
                    )
                  )
                )
              ) >= 0
            );
          });
        }
        if (this.stato.length > 0) {
          let presente = this.stato.find((el) => el == "Tutti");
          if (presente == undefined) {
            this.lista_tabella = this.lista_tabella.filter((el) => {
              let presente2 = this.stato.find((el2) => el2 == el.des_stato);
              if (presente2 != undefined) return el;
            });
          }
        }
      }
    },
    async getAmbiti() {
      var getAziende = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          tipo: "TIPO_ASSISTENZA",
          filter: "validita = 1",
          assistenza: "1",
        },
      };

      await this.$store.dispatch("api", getAziende).then((res) => {
        if (res.status == 200) {
          this.tipi_assistenza = res.data;
        }
      });
    },
    async getAziende() {
      var getAziende = {
        controller: "Modulo",
        method: "POST",
        richiesta: {
          action: "getAzienda",
          token: localStorage.user_token,
        },
      };

      await this.$store.dispatch("api", getAziende).then((res) => {
        if (res.status == 200) {
          var aziendaLocal = res.data.result.azienda.filter(
            (el) => el.id_azienda == localStorage.id_azienda
          );
          this.emailSettings = aziendaLocal[0].email_assistenza;
          this.oreSettings = aziendaLocal[0].ore_assistenza;
          this.valoreAssistenzaSettings =
            aziendaLocal[0].valore_tipo_assistenza;
        }
      });
    },
    getColor(des_stato) {
      var color = this.all_status.filter(function (st) {
        if (st.des == des_stato) {
          return st;
        }
      });
      return color.length != 0 && color[0].val_txt ? color[0].val_txt : "grey";
    },
    getFormattedData(data) {
      return moment(data).format("MM/DD hh:mm");
    },
    getIcon(des_stato) {
      
      var icon = this.all_status.filter(function (st) {
        if (st.des == des_stato) {
          return st;
        }
      });
      return icon.length != 0 && icon[0].nota_interna
        ? "mdi-" + icon[0].nota_interna
        : "mdi-help-circle";
    },
    getModulo(id) {
      var mod = this.moduli.filter(function (modulo) {
        if (modulo.id_tabella == id) {
          return modulo;
        }
      });
      return mod[0].des;
    },
    getProgetto(id) {
      var prog = this.progetti.filter(function (progetto) {
        if (progetto.id_tabella == id) {
          return progetto;
        }
      });
      return prog[0].des;
    },
    gotoDashboard() {
      this.$router.push("/home");
    },
    newItem() {
      if (this.assistenza == 1) {
        this.$router.push({
          name: "SingleTicketAssistenza",
          params: {
            id: "-1",
            tipo_permesso: this.tipo_permesso.toString(),
            assistenza: this.assistenza,
          },
        });
      } else {
        this.$router.push({
          path: "/home/ticket/-1",
          params: { assistenza: this.assistenza },
        });
      }
    },
    async openSettings() {
      await this.getAziende();
      this.getAmbiti();
      this.dialog_setting = true;
    },
    resetFiltri() {
      this.stato = [];
      this.start_date = null;
      this.end_date = null;
      this.ris_ticket = null;
      this.current_page = null;
      this.utente_ticket = null;
      this.tipologia = null;
      this.tipo_assistenza = null;
      this.search = "";
      window.FILTRI_TICKET["ris_ticket"] = this.ris_ticket;
      window.FILTRI_TICKET["current_page"] = this.current_page;
      window.FILTRI_TICKET["utente_ticket"] = this.utente_ticket;
      window.FILTRI_TICKET["stato"] = this.stato;
      window.FILTRI_TICKET["search"] = this.search;
      window.FILTRI_TICKET["tipo_assistenza"] = this.tipo_assistenza;
      window.FILTRI_TICKET["tipologia"] = this.tipologia;
      window.FILTRI_TICKET["start_date"] = this.start_date;
      window.FILTRI_TICKET["end_date"] = this.end_date;
    },
    salvaSettings() {
      this.dialog_setting = false;
      var setAzienda = {
        controller: "Modulo",
        method: "PUT",
        richiesta: {
          action: "setAzienda",
          token: localStorage.user_token,
          azienda: [
            {
              id_azienda: localStorage.id_azienda,
              email_assistenza: this.emailSettings,
              ore_assistenza: this.oreSettings,
              valore_tipo_assistenza: this.valoreAssistenzaSettings,
            },
          ],
        },
      };

      this.$store.dispatch("api", setAzienda).then((res) => {
        if (res.status == 200) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Impostazioni per l' azienda " +
            this.nome_azienda +
            " salvate correttamente";
          this.snackbar_text_color = "white";
        }
      });
    },
    setFiltriTicket() {
      if (
        window.FILTRI_TICKET != undefined &&
        (this.ris_ticket != null ||
          this.current_page != null ||
          this.utente_ticket != null ||
          this.tipologia != null ||
          this.start_date != null ||
          this.end_date != null ||
          this.tipo_assistenza != null ||
          this.search != "" ||
          this.stato.length > 0)
      ) {
        window.FILTRI_TICKET["current_page"] = this.current_page;
        window.FILTRI_TICKET["ris_ticket"] = this.ris_ticket;
        window.FILTRI_TICKET["utente_ticket"] = this.utente_ticket;
        window.FILTRI_TICKET["stato"] = this.stato;
        window.FILTRI_TICKET["search"] = this.search;
        window.FILTRI_TICKET["tipo_assistenza"] = this.tipo_assistenza;
        window.FILTRI_TICKET["tipologia"] = this.tipologia;
        window.FILTRI_TICKET["end_date"] = this.end_date;
        window.FILTRI_TICKET["start_date"] = this.start_date;
      } else if (window.FILTRI_TICKET == undefined) {
        window.FILTRI_TICKET = [];
        window.FILTRI_TICKET["current_page"] = this.current_page;
        window.FILTRI_TICKET["ris_ticket"] = this.ris_ticket;
        window.FILTRI_TICKET["utente_ticket"] = this.utente_ticket;
        window.FILTRI_TICKET["stato"] = this.stato;
        window.FILTRI_TICKET["search"] = this.search;
        window.FILTRI_TICKET["tipo_assistenza"] = this.tipo_assistenza;
        window.FILTRI_TICKET["tipologia"] = this.tipologia;
        window.FILTRI_TICKET["end_date"] = this.end_date;
        window.FILTRI_TICKET["start_date"] = this.start_date;
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // apriAttivita() {
    //   let messaggio = {
    //     detail: {
    //       apriAttivita: true,
    //     },
    //     src: null,
    //   };

    //   // Inserisco il messaggio in un evento custom.
    //   let evento = new CustomEvent("apriAttivita", messaggio);

    //   // Recapito l'evento alla mia destinazione.
    //   document.getElementById("listener_attivita").dispatchEvent(evento);
    //   console.log(
    //     "Messaggio inviato a Registra Pezzi: " + JSON.stringify(messaggio)
    //   );
    // },
  },
};
</script>