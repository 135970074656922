<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Disegni</v-toolbar-title>
    </v-toolbar>
    <!--v-row>
      <v-col md="12">
        <v-data-table :loading="loading" :items="items_disegno" :headers="headers_disegno" hide-default-footer>
          <v-progress-linear v-show="progressBar" slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.revisione_articolo`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  v-if="item.revisione_articolo >= 1"
                  >mdi-check-box-outline</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:[`item.dum`]="{ item }">
            {{ item.dum | formatDate }}
          </template>

          <template v-slot:[`item.disegno`]="{ item }">

            <v-card
              style="
                height: 40px !important;

                width: 60px !important;
                margin-right: -10rem !important;
              "
              v-if="!isPdf(item.disegno)"
            >
              <div v-viewer class="images clearfix">
                <img
                  style="
                    height: 40px !important;
                    display: block !important;

                    width: 60px !important;
                    margin-right: -10rem !important;
                  "
                  :src="item.disegno"
                  :lazy-src="item.disegno"
                  aspect-ratio="1"
                  class="grey lighten-2"
                />
              </div>
            </v-card>


            <v-card
              @click="openPdf(item)"
              style="
                height: 40px !important;

                width: 80px !important;
                margin-right: -10rem !important;
              "
              v-if="isPdf(item.disegno)"
            >
              <div style="text-align: center">
                <v-spacer></v-spacer>
                <v-icon color="error" x-large> mdi-pdf-box </v-icon>
              </div>
            </v-card>
          </template>
        </v-data-table>
      </v-col>
    </v-row-->
    <v-container>
        <v-row justify="center" v-for="(item,i) in items_disegno" :key="i" >
          <v-btn style="overflow:hidden;margin:5px" width="90" height="70" elevation="5" @click="(isPdf(item.disegno)==true)?openPdf(item):getImage(item)" >
            <!-- se PDF -->
            <v-icon v-if="isPdf(item.disegno)" color="error" x-large> mdi-file-pdf-box </v-icon>
            <!-- se immagine -->
            <div v-if="!isPdf(item.disegno)" v-viewer
              :style="'width:90px;height:70px;background:url('+ item.disegno +');background-size:cover;background-position:center'">
              <img :src="item.disegno" style="opacity:0">
            </div>
          </v-btn>
        </v-row>
    </v-container>

    <!--v-dialog
      content-class="artDialog"
      persistent
      v-model="dialog1"
      width="1100"
    >
      <v-card>
        <v-card-title
          style="height: 1rem !important"
          class="headline blue-grey darken-3 ml-n2"
          primary-title
        >
          <h6 class="mt-n5 white--text">Allegati</h6>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="#c00000"
                @click="dialog1 = false"
                depressed
                class="mt-n4 mr-n6"
                >mdi-window-close</v-icon
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-card-title>
        <div v-viewer class="images clearfix">
          <img
            aspect-ratio="1"
            :src="ImageToShow"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          />
        </div>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog-->

    <!-- Dialog di visualizzazione delle immagini PDF -->
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <!-- <div ref="pdf">
        <pdf
          @loaded="onLoad"
          style="border: 1px solid black"
          :src="this.selectedPdf"
        ></pdf>
      </div> -->
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="height: 90vh !important; width: 90vw !important"
      >
      </iframe>
    </v-dialog>
  </v-card>
</template>
<script>
import panzoom from "panzoom";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);

export default {
  name: "TabellaControllo",
  props: {
    id_articolo: {type: Number, default: 0},
  },
  data() {
    return {
      // Variabili d'appoggio
      ImageToShow: "",
      loading: false,
      progressBar: false,
      selectedPdf: "",

      // Modal
      dialog1: false,
      pdfdialog: false,

      // Array
      headers_disegno: [
        {
          text: "Allegati",
          value: "disegno",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      items_disegno: [],
    };
  },
  methods: {
    getImage(item) {
      this.ImageToShow = item.disegno;
    },
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    onLoad() {
      panzoom(this.$refs.pdf);
    },
    openPdf(item) {
      console.log(item.disegno);
      if (item.disegno.startsWith("data:application/pdf")) {
        const byteCharacters = atob(item.disegno.substring(28));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        console.log(blobUrl);
        this.pdfdialog = true;
      }
    },
  },
  mounted() {
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "getarticolidisegno",
        token: localStorage.getItem("user_token"),
        db_name: "quickproduction",
        id_articolo: this.id_articolo,
      },
    };

    this.items_disegno = [];
    this.$store.dispatch("api", request).then((res) => {
      res.data.forEach((elem) => {
        this.items_disegno.push(elem);
      });
      this.progressBar = false;
      this.loading = false;
    });
  },
  watch: {

  },
};
</script>
<style scoped>
</style>