<template>
<div class="pt-8 px-5">
  <ManutenzioneGestioneSediFornitori />
</div>
</template>
<script>
import ManutenzioneGestioneSediFornitori from "@/components/MANUTENZIONE/ManutenzioneGestioneSediFornitori.vue";
export default {
  name: "ManutenzioneInterventi",
  components: {ManutenzioneGestioneSediFornitori},
  props: {},
  data() {
    return {
        // Variabili d'appoggio
        // Modal
        // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {

  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>