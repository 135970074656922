<template>
  <svg id="gantt_connections_host" ref="overlay">
    <defs>
      <marker
        v-for="(item, index) in colors"
        :key="index"
        :stroke="item"
        :fill="item"
        :id="'gantt_arrowend_' + index"
        viewBox="0 0 24 24"
        refX="22"
        refY="12"
        markerWidth="5"
        markerHeight="5"
        orient="auto-start-reverse"
      >
        <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
      </marker>
    </defs>
  </svg>
</template>
<script>
let REF = null;
let OVERLAY = null;
let TABLE = null;
export default {
  name: "GanttConnections",
  data() {
    return {
      deleted_connections: new Array(),
      colors: [
        "#008000",
        "#6495ed",
        "#9400d3",
        "#808000",
        "#8b4513",
        "#000080",
      ],
    };
  },
  mounted() {
    REF = this;
    OVERLAY = this.$refs.overlay;
  },
  methods: {
    setReferences(table) {
      TABLE = table;
    },
    getConnections() {
      const result = [];
      TABLE.items.forEach((item) => {
        item._priv.connections.forEach((connection) => {
          let obj = {
            id_connessione: connection.id_connessione,
            id_task_arrivo: connection.id_task_arrivo,
            id_task_partenza: connection.id_task_partenza,
            tipo_arrivo: connection.tipo_arrivo,
            tipo_connessione: connection.tipo_connessione,
            tipo_partenza: connection.tipo_partenza,
            validita: connection.validita,
          };
          result.push(obj);
        });
      });
      this.deleted_connections.forEach((connection) => {
        let obj = {
          id_connessione: connection.id_connessione,
          id_task_arrivo: connection.id_task_arrivo,
          id_task_partenza: connection.id_task_partenza,
          tipo_arrivo: connection.tipo_arrivo,
          tipo_connessione: connection.tipo_connessione,
          tipo_partenza: connection.tipo_partenza,
          validita: 0,
        };
        result.push(obj);
      });
      return result;
    },
    build(connections, tasks) {
      if (connections != null) {
        this.deleted_connections = [];
        tasks.forEach((task) => {
          task._priv.connections = [];
          task._priv.inbound_connections = [];
        });
        connections.forEach((connessione) => {
          if (connessione.validita != 1) {
            this.deleted_connections.push(connessione);
            return;
          }
          let flag = false;
          tasks.forEach((partenza) => {
            if (connessione.id_task_partenza == partenza.data.id_task) {
              tasks.forEach((arrivo) => {
                if (
                  connessione.id_task_arrivo == arrivo.data.id_task &&
                  partenza != arrivo
                ) {
                  flag = true;
                  partenza._priv.connections.push(connessione);
                  arrivo._priv.inbound_connections.push(connessione);
                  connessione.ref_task_partenza = partenza;
                  connessione.ref_task_arrivo = arrivo;
                }
              });
            }
          });
          if (!flag) {
            console.log("entrato");
            this.deleted_connections.push(connessione);
          }
        });
      }
      requestAnimationFrame(function () {
        REF._build(tasks);
      });
    },
    _build(tasks) {
      const paths = OVERLAY.getElementsByClassName("connection");
      while (paths.length > 0) {
        OVERLAY.removeChild(paths[0]);
      }
      const t_length = tasks.length;
      const rect = OVERLAY.getBoundingClientRect();
      for (let i = 0; i < t_length; i++) {
        for (let j = 0; j < tasks[i]._priv.connections.length; j++) {
          const connection = tasks[i]._priv.connections[j];
          const start_task = connection.ref_task_partenza.nodereference;
          const end_task = connection.ref_task_arrivo.nodereference;

          try {
            const start_type = connection.tipo_partenza;
            const start_node =
              start_task.getElementsByTagName("aside")[start_type];
            start_node.style.display = "block";
            const start_rect = start_node.getBoundingClientRect();
            start_node.removeAttribute("style");
            const start_point = {
              x: Math.round(start_rect.x + start_rect.width / 2 - rect.x),
              y: Math.round(start_rect.y + start_rect.height / 2 - rect.y),
            };

            const end_type = connection.tipo_arrivo;
            const end_node = end_task.getElementsByTagName("aside")[end_type];
            end_node.style.display = "block";
            const end_rect = end_node.getBoundingClientRect();
            end_node.removeAttribute("style");
            const end_point = {
              x: Math.round(end_rect.x + end_rect.width / 2 - rect.x),
              y: Math.round(end_rect.y + end_rect.height / 2 - rect.y),
            };

            const line = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            console.log(start_task.taskreference.data.nesting);
            const color_index =
              start_task.taskreference.data.nesting % this.colors.length;
            line.setAttribute("class", "connection");
            line.style.stroke = this.colors[color_index];
            line.style.markerEnd = "url(#gantt_arrowend_" + color_index + ")";
            line.setAttribute(
              "d",
              this.getPath(start_point, end_point, end_type, start_type != 1)
            );
            line.connectionreference = connection;
            line.taskreference = start_task;
            OVERLAY.appendChild(line);
          } catch (error) {
            console.log("errore", error);
            connection.validita = 0;
            window.stderr = error;
            continue;
          }
        }
      }

      // for (let i = 0; i < c_length; i++) {
      //   const connection = connections[i];
      //   if (connection.validita == 0) {
      //     continue;
      //   }
      //   let start_task = null,
      //     end_task = null;
      //   for (let j = 0; j < t_length; j++) {
      //     const task = tasks[j];
      //     if (task.data.id_task == connection.id_task_partenza) {
      //       if (task.data.validita == 1) {
      //         start_task = task.nodereference;
      //       }
      //     }
      //     if (task.data.id_task == connection.id_task_arrivo) {
      //       if (task.data.validita == 1) {
      //         end_task = task.nodereference;
      //       }
      //     }
      //     if (start_task != null && end_task != null) {
      //       break;
      //     }
      //   }

      //   try {
      //     const start_type = connection.tipo_partenza;
      //     const start_node =
      //       start_task.getElementsByTagName("aside")[start_type];
      //     start_node.style.display = "block";
      //     const start_rect = start_node.getBoundingClientRect();
      //     start_node.removeAttribute("style");
      //     const start_point = {
      //       x: Math.round(start_rect.x + start_rect.width / 2 - rect.x),
      //       y: Math.round(start_rect.y + start_rect.height / 2 - rect.y),
      //     };

      //     const end_type = connection.tipo_arrivo;
      //     const end_node = end_task.getElementsByTagName("aside")[end_type];
      //     end_node.style.display = "block";
      //     const end_rect = end_node.getBoundingClientRect();
      //     end_node.removeAttribute("style");
      //     const end_point = {
      //       x: Math.round(end_rect.x + end_rect.width / 2 - rect.x),
      //       y: Math.round(end_rect.y + end_rect.height / 2 - rect.y),
      //     };

      //     const line = document.createElementNS(
      //       "http://www.w3.org/2000/svg",
      //       "path"
      //     );
      //     console.log(start_task.taskreference.data.nesting);
      //     const color_index =
      //       start_task.taskreference.data.nesting % this.colors.length;
      //     line.setAttribute("class", "connection");
      //     line.style.stroke = this.colors[color_index];
      //     line.style.markerEnd = "url(#gantt_arrowend_" + color_index + ")";
      //     line.setAttribute(
      //       "d",
      //       this.getPath(start_point, end_point, end_type, start_type != 1)
      //     );
      //     line.connectionreference = connection;
      //     line.taskreference = start_task;
      //     OVERLAY.appendChild(line);
      //   } catch (error) {
      //     console.log("errore", error);
      //     connection.validita = 0;
      //     window.stderr = error;
      //     continue;
      //   }
      // }
    },
    startDrawing(target) {
      const src = target.getBoundingClientRect();
      const rect = OVERLAY.getBoundingClientRect();

      const connection = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      connection.setAttribute("d", "");
      const color_index =
        target.parentElement.parentElement.parentElement.taskreference.data
          .nesting % REF.colors.length;
      connection.setAttribute("class", "connection");
      connection.style.stroke = this.colors[color_index];
      connection.style.markerEnd = "url(#gantt_arrowend_" + color_index + ")";
      OVERLAY.appendChild(connection);

      REF.target = target;
      REF.reverse = Number(target.getAttribute("data-type")) != 1;
      REF.connection = connection;
      REF.rect = rect;
      REF.start = {
        x: Math.round(src.x + src.width / 2 - rect.x),
        y: Math.round(src.y + src.height / 2 - rect.y),
      };

      document.body.addEventListener("mousemove", REF.moveDrawing);
      document.body.addEventListener("mouseup", REF.stopDrawing);
      document.body.addEventListener("mouseleave", REF.stopDrawing);
    },

    getPath(start_point, end_point, type, reverse) {
      const factor = reverse ? -1 : 1;
      const breakpoint = (75 / 3) * factor;
      const deltaX = end_point.x - (start_point.x + breakpoint);
      const deltaY = end_point.y - start_point.y;
      const v_factor = Math.sign(deltaY);
      const radius = Math.min(Math.abs(deltaX), Math.abs(deltaY), 5);
      const variant = (type != 1 && !reverse) || (type == 1 && reverse);

      let points = `M ${start_point.x} ${start_point.y}`;
      let offset = 0 * factor;

      if ((deltaX >= 0 && !reverse) || (deltaX <= 0 && reverse)) {
        const coeff = variant ? -1 : 1;
        let point = end_point.x + breakpoint * coeff;
        points += " H " + (point - radius * factor);
        points += ` Q ${point} ${start_point.y} ${point} ${
          start_point.y + radius * v_factor
        }`;
        points += " V " + (end_point.y - radius * v_factor);
        points += ` Q ${point} ${end_point.y} ${
          point - radius * coeff * factor
        } ${end_point.y}`;
        points += " H " + (end_point.x + offset);
      } else {
        let point = Math.round(start_point.x + breakpoint);
        points += " H " + (point - radius * factor);
        points += ` Q ${point} ${start_point.y} ${point} ${
          start_point.y + radius * v_factor
        }`;
        if (variant) {
          const v_spacing = 30;
          points += " V " + (end_point.y - (v_spacing + radius) * v_factor);
          points += ` q 0 ${radius * v_factor} ${-radius * factor} ${
            radius * v_factor
          }`;
          points += " H " + (end_point.x - breakpoint + radius * factor);
          points += ` q ${-radius * factor} 0 ${-radius * factor} ${
            radius * v_factor
          }`;
          points += " V " + (end_point.y - radius * v_factor);
          points += ` q 0 ${radius * v_factor} ${radius * factor} ${
            radius * v_factor
          }`;
          points += " H " + (end_point.x + offset);
        } else {
          points += " V " + (end_point.y - radius * v_factor);
          points += ` q 0 ${radius * v_factor} ${-radius * factor} ${
            radius * v_factor
          }`;
          points += " H " + (end_point.x + offset);
        }
      }
      return points;
    },

    moveDrawing(event) {
      let type = -1;
      const target = event.composedPath()[0];
      const is_handle = target.tagName.toLowerCase() == "aside";
      const point = {
        x: Math.round(event.clientX - REF.rect.x),
        y: Math.round(event.clientY - REF.rect.y),
        type: null,
      };

      if (is_handle) {
        type = Number(target.getAttribute("data-type"));
        const rect = target.getBoundingClientRect();
        point.x = Math.round(rect.x + rect.width / 2 - REF.rect.x);
        point.y = Math.round(rect.y + rect.height / 2 - REF.rect.y);
        point.type = target.parentElement.getAttribute("class");
      }
      REF.connection.setAttribute(
        "d",
        this.getPath(REF.start, point, type, REF.reverse)
      );
    },
    // evitaLoop(task, ref_task) {
    //   task._priv.connections.forEach((e) => {

    //   });
    // },
    // mouseup disegno frecce
    stopDrawing(event) {
      const target = event.composedPath()[0];

      if (target.tagName.toLowerCase() == "aside") {
        try {
          const task_start =
            REF.target.parentElement.parentElement.parentElement.taskreference;
          const task_end =
            target.parentElement.parentElement.parentElement.taskreference;
            console.log(task_start.data.id_task_padre,task_end.data.id_task_padre)
          if (
            task_start.data.id_task_padre == task_end.data.id_task_padre &&
            task_start != task_end
          ) {
            console.log(
              task_start._priv.connections,
              task_end._priv.connections
            );
            let flag = false;
            task_start._priv.connections.forEach((e) => {
              flag |= e.ref_task_arrivo == task_end;
            });
            task_end._priv.connections.forEach((e) => {
              flag |= e.ref_task_partenza == task_start;
            });
            console.log("FLAG:", flag);

            if (!flag) {
              if (task_end._priv.inbound_connections.length == 0) {
                if (
                  TABLE.items.indexOf(task_start) <
                  TABLE.items.indexOf(task_end)
                ) {
                  const connection = document.createElementNS(
                    "http://www.w3.org/2000/svg",
                    "path"
                  );
                  connection.setAttribute(
                    "d",
                    REF.connection.getAttribute("d")
                  );
                  const color_index =
                    task_start.data.nesting % REF.colors.length;
                  connection.style.stroke = this.colors[color_index];
                  connection.style.markerEnd =
                    "url(#gantt_arrowend_" + color_index + ")";
                  connection.setAttribute("class", "connection");
                  OVERLAY.appendChild(connection);
                  let connection_obj = {
                    id_connessione: -1,
                    id_task_partenza: task_start.data.id_temp,
                    id_task_arrivo: task_end.data.id_temp,
                    tipo_partenza: REF.reverse == false ? 1 : 0,
                    tipo_arrivo: Number(target.getAttribute("data-type")),
                    ref_task_partenza: task_start,
                    ref_task_arrivo: task_end,
                    validita: 1,
                  };
                  task_start._priv.connections.push(connection_obj);
                  task_end._priv.inbound_connections.push(connection_obj);
                  connection.connectionreference = connection_obj;
                  console.log(
                    task_start.data,
                    task_end.data,
                    connection.connectionreference
                  );
                }
              }
            }
          }
        } catch (error) {
          window.stderr = error;
        }
      }

      document.body.removeEventListener("mousemove", this.moveDrawing);
      document.body.removeEventListener("mouseup", this.stopDrawing);
      document.body.removeEventListener("mouseleave", this.stopDrawing);
      OVERLAY.removeChild(REF.connection);

      delete REF.reverse;
      delete REF.connection;
      delete REF.rect;
      delete REF.start;
      delete REF.target;
    },
    unlink(element) {
      OVERLAY.removeChild(element);
      const connection = element.connectionreference;
      const connection_array = connection.ref_task_partenza._priv.connections;
      const inbound_connection_array =
        connection.ref_task_arrivo._priv.inbound_connections;
      connection.validita = 0;
      this.deleted_connections.push(connection);
      connection_array.splice(connection_array.indexOf(connection), 1);
      inbound_connection_array.splice(
        inbound_connection_array.indexOf(connection),
        1
      );
    },
  },
};
</script>
<style >
#gantt_connections_host {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  height: 100%;
  width: 100%;
}
#gantt_connections_host > .connection {
  fill: none;
  stroke-width: 4;
  stroke: #f7cc55;
  marker-end: url(#gantt_arrowend);
}
</style>