<template >
  <v-container fluid>
    <v-row class>
      <v-dialog v-model="dialog_eventi" max-width="40%" persistent>
        <v-card>
          <v-card-title>{{ titolo_conferma }}</v-card-title>
          <v-card-text>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-textarea
                      outlined
                      v-model="descrizione_evento"
                      name="input-7-4"
                      label="Descrizione Attivita"
                      value
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-card class="mt-5" color>
                      <v-toolbar color="amber darken-3" dark>
                        <v-toolbar-title>Eventi Attivita</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-list style="max-height: 300px; overflow-y: auto">
                        <v-list-item
                          v-for="item in evento_sel.calendario_attivita_log"
                          :key="item.id_log_calendario_attivita"
                        >
                          <v-list-item-icon>
                            <v-icon color="red">mdi-alert</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.descrizione_log"
                            ></v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.nome }}{{ item.cognome }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ item.date_ins }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_eventi = false"
              >Annulla</v-btn
            >
            <v-btn color="blue darken-1" text @click="setEventoUtente()"
              >Conferma</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="12" md="4" lg="2">
        <v-card class="mx-auto" color>
          <v-toolbar color="success" dark>
            <v-toolbar-title>Seleziona Date</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-date-picker v-model="dates" no-title range width="100%">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    class="align-self-start mt-0"
                    size="28"
                    v-on="on"
                    @click="reimpostaDate"
                  >
                    <v-icon>mdi-undo</v-icon>
                  </v-btn>
                </template>
                <span>Reimposta date</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    class="align-self-start mt-0"
                    size="28"
                    v-on="on"
                    @click="cercaDate"
                  >
                    <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </template>
                <span>Cerca con queste date</span>
              </v-tooltip>
            </v-date-picker>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto mt-3" color>
          <v-toolbar color="deep-purple darken-1" dark>
            <v-toolbar-title>Utenti in evidenza (2 gg)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <span v-if="utenti_allerta.length == 0" class="mt-2">Nessun utente in evidenza</span>
          <v-virtual-scroll
            :items="utenti_allerta"
            :item-height="50"
            style="max-height: 450px; overflow-y: auto"
            height="200"
            :key="item.nominativo"
          >
            <template v-slot="{ item }">
              <v-list-item @click="selezionaUtente(item)">
                <v-list-item-icon>
                  <v-icon color="red">mdi-alert</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.nominativo"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <!-- <v-list>
          <v-list-item
            v-for="item in utenti_allerta"
            :key="item.nominativo"
            @click="selezionaUtente(item)"
          >
            <v-list-item-icon>
              <v-icon color="red">mdi-alert</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.nominativo"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>-->
        </v-card>
        <!-- PER IL MOMENTO -->
        <!-- <v-card class="mt-5" color>
          <v-toolbar color="amber darken-3" dark>
            <v-toolbar-title>Richieste</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="white" icon dark v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="goToRichieste()">
                  <v-list-item-title>Gestisci richieste</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-list style="max-height: 300px; overflow-y: auto">
            <v-list-item
              v-for="item in richieste.calendario_attivita"
              :key="item.nominativo"
            >
              <v-list-item-icon>
                <v-icon color="red">mdi-alert</v-icon>
              </v-list-item-icon>

              <v-list-item-content
                @click="setEvento(item)"
                style="cursor: pointer"
              >
                <v-list-item-title
                  v-text="item.attivita_svolgere"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.nome_partecipante }}{{ item.cognome_partecipante }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.data_richiesta | formatDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card> -->
      </v-col>
      <v-col cols="12" md="7" lg="7">
        <v-card class="mx-auto" color>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Tabella Utenti</v-toolbar-title>
            <v-autocomplete
              v-model="values"
              :items="nominativi_item"
              item-text="nominativo"
              flat
              hide-no-data
              hide-details
              chips
              multiple
              label="Filtra Utenti"
              solo-inverted
              class="ml-5"
              :menu-props="{ closeOnContentClick: true }"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="cancella_autocomplete(data.item)"
                  >{{ data.item.nominativo }}</v-chip
                >
              </template>
            </v-autocomplete>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="item"
            class="elevation-1"
            style="overflow-y: auto; max-height: 700px; cursor: pointer"
            @click:row="selezionaUtente"
            :footer-props="footerProps"
          >
            <template v-slot:[`item.nominativo`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item.nominativo }}</span>
                </template>
                <span>{{ item.nominativo }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.des_esito`]="{ item }">
              <v-chip :color="getAllerta(item.esito_temperatura)" dark>
                {{ item.des_esito }}
              </v-chip>
            </template>
            <template v-slot:[`item.data_rilevazione`]="{ item }">
              {{ item.data_rilevazione | formatDateTime }}
            </template>
            <template v-slot:[`item.disclaimer1`]="{ item }">
              <v-icon v-if="item.disclaimer1 == 1" color="green"
                >mdi-check-underline</v-icon
              >
              <v-icon v-if="item.disclaimer1 == 0" color="red"
                >mdi-close-circle</v-icon
              >
            </template>
            <template v-slot:[`item.disclaimer2`]="{ item }">
              <v-icon v-if="item.disclaimer2 == 1" color="green"
                >mdi-check-underline</v-icon
              >
              <v-icon v-if="item.disclaimer2 == 0" color="red"
                >mdi-close-circle</v-icon
              >
            </template>
            <template v-slot:[`item.disclaimer3`]="{ item }">
              <v-icon v-if="item.disclaimer3 == 1" color="green"
                >mdi-check-underline</v-icon
              >
              <v-icon v-if="item.disclaimer3 == 0" color="red"
                >mdi-close-circle</v-icon
              >
            </template>
            <template v-slot:[`item.disclaimer4`]="{ item }">
              <v-icon v-if="item.disclaimer4 == 1" color="green"
                >mdi-check-underline</v-icon
              >
              <v-icon v-if="item.disclaimer4 == 0" color="red"
                >mdi-close-circle</v-icon
              >
            </template>
            <template v-slot:[`item.disclaimer5`]="{ item }">
              <v-icon v-if="item.disclaimer5 == 1" color="green"
                >mdi-check-underline</v-icon
              >
              <v-icon v-if="item.disclaimer5 == 0" color="red"
                >mdi-close-circle</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-card color>
          <v-toolbar color="accent" dark>
            <v-toolbar-title>Stato Esito Utenti</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <download-excel :fetch="downloadExcel" :name="nome_excel">
                  <v-btn fab text small v-on="{ ...tooltip }">
                    <v-icon color="teal">mdi-file-excel</v-icon>
                  </v-btn>
                </download-excel>
              </template>
              <span>Scarica Excel</span>
            </v-tooltip>

            <v-menu :close-on-content-click="false" v-model="menu_data_range">
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-on="{ ...tooltip, ...menu }" small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleziona Data</span>
                </v-tooltip>
              </template>
              <v-date-picker v-model="data_sel_Stato" dark no-title>
                <v-tooltip bottom>
                  <span>Reimposta date</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="align-self-start mt-0"
                      size="28"
                      v-on="on"
                      @click="cercaDateStato"
                    >
                      <v-icon>mdi-arrow-right-thick</v-icon>
                    </v-btn>
                  </template>
                  <span>Cerca con queste date</span>
                </v-tooltip>
              </v-date-picker>
            </v-menu>
          </v-toolbar>
          <v-autocomplete
            v-model="value_stato"
            :items="utenti_stato_backup"
            item-text="des_stato"
            flat
            hide-no-data
            hide-details
            chips
            label="Filtra Stati"
            solo-inverted
            class
            :menu-props="{ closeOnContentClick: true }"
          ></v-autocomplete>
          <v-autocomplete
            v-model="value_qualifica"
            :items="utenti_stato_backup"
            item-text="des_qualifica"
            item-value="des_qualifica"
            flat
            hide-no-data
            hide-details
            chips
            label="Filtra Qualifica"
            solo-inverted
            class
            :menu-props="{ closeOnContentClick: true }"
          ></v-autocomplete>
          <v-autocomplete
            v-model="value_gruppo"
            :items="utenti_gruppo"
            item-text="des_gruppo"
            item-value="id_gruppo"
            flat
            hide-no-data
            hide-details
            chips
            label="Filtra Gruppi"
            solo-inverted
            class
            :menu-props="{ closeOnContentClick: true }"
          ></v-autocomplete>
          <v-virtual-scroll
            :items="utenti_stato"
            :item-height="50"
            style="max-height: 450px; overflow-y: auto"
            height="450"
          >
            <template v-slot="{ item }">
              <v-list-item>
                <v-list-item-content>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-list-item-title
                        v-on="on"
                        v-text="item.nominativo"
                      ></v-list-item-title>
                    </template>
                    <span>{{ item.nominativo }}</span>
                  </v-tooltip>
                </v-list-item-content>
                <v-list-item-icon class="mt-2">
                  <v-chip
                    :color="
                      item.blocco_utente == 1
                        ? 'red'
                        : item.stato == 2
                        ? 'gray'
                        : item.stato == 1
                        ? 'green'
                        : 'orange'
                    "
                    outlined
                  >
                    {{ item.blocco_utente == 1 ? "BLOCCATO" : item.des_stato }}
                  </v-chip>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <!-- <v-list style="height:450px;overflow-y:auto">
          <v-list-item v-for="item in utenti_stato" :key="item.nominativo">
            <v-list-item-content>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-list-item-title v-on="on" v-text="item.nominativo"></v-list-item-title>
                </template>
                <span>{{ item.nominativo }}</span>
              </v-tooltip>
            </v-list-item-content>
            <v-list-item-icon class="mt-2">
              <v-chip
                :color="item.blocco_utente==1?'red':item.stato == 2?'gray':item.stato==1?'green':'orange'"
                outlined
              >{{ item.blocco_utente == 1 ? "BLOCCATO" :item.des_stato}}</v-chip>
            </v-list-item-icon>
          </v-list-item>
        </v-list>-->
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<style scoped>
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 8px;
}
.colore_card_utente {
  background: linear-gradient(
    118deg,
    rgba(92, 107, 192, 1),
    rgba(123, 136, 212, 0.7)
  ) !important;
}
</style>
<script>
export default {
  components: {},
  data: () => ({
    titolo_conferma: "",
    value_qualifica: null,
    value_stato: null,
    value_gruppo: null,
    dates: [],
    values: [],
    value: null,
    utenti_allerta: [],
    utenti_stato: [],
    utenti_gruppo: [],
    gruppi_utenti: [],
    headers: [],
    item: [],
    richieste: [],
    backup_item: [],
    stato_item: [],
    utenti_stato_backup: [],
    gruppo_item: [],
    nominativi_item: [],
    menu_data_range: false,
    data_sel_Stato: null,
    dialog_eventi: false,
    descrizione_evento: "",
    evento_sel: {},
    nome_excel: "Estrazione stati",
    footerProps: { "items-per-page-options": [15, 30, 50, 100] },
    pagination: {
      itemsPerPage: 50,
    },
    //Modal variables
    snackbar: false,
    snackbar_background: "",
    snackbar_icon: "",
    snackbar_icon_color: "",
    snackbar_text: "",
    snackbar_text_color: "",
  }),
  watch: {
    values(val) {
      //filtro la tabella
      if (this.backup_item.length > 0) this.item = this.backup_item;
      if (val.length > 0) {
        this.backup_item = this.item;
        this.item = this.item.filter(function (el) {
          console.log("val", val);
          return val.indexOf(el.nominativo) >= 0;
        });
      }
    },
    value_stato() {
      this.filtraStatiUtenti("stato");
      // if (val == undefined && this.utenti_stato_backup.length > 0) {
      //   this.value_qualifica = null;
      //   this.value_gruppo = null;
      //   this.utenti_stato = this.utenti_stato_backup;
      //   console.log("utebtu", this.utenti_stato);
      //   this.filtraStatiUtenti("stato");
      //   return;
      // }
      // if (this.stato_item.length > 0) {
      //   this.utenti_stato = this.stato_item;
      // }
      // this.stato_item = this.utenti_stato;
      // this.utenti_stato = this.utenti_stato.filter(function (el) {
      //   return val.indexOf(el.des_stato) >= 0;
      // });
    },
    value_qualifica() {
      this.filtraStatiUtenti("qualifica");
      // if (val == undefined && this.utenti_stato_backup.length > 0) {
      //   this.value_gruppo = null;

      //   this.utenti_stato = this.utenti_stato_backup;
      //   this.filtraStatiUtenti("qualifica");

      //   return;
      // }
      // if (this.stato_item.length > 0) {
      //   this.utenti_stato = this.stato_item;
      // }
      // this.stato_item = this.utenti_stato;
      // this.utenti_stato = this.utenti_stato.filter(function (el) {
      //   return val.indexOf(el.des_qualifica) >= 0;
      // });
    },
    value_gruppo() {
      this.filtraStatiUtenti("gruppo");
      // if (val == undefined && this.utenti_stato_backup.length > 0) {
      //   this.utenti_stato = this.utenti_stato_backup;
      //   this.filtraStatiUtenti("gruppo");
      //   return;
      // }

      // if (this.stato_item.length > 0) {
      //   this.utenti_stato = this.stato_item;
      // }
      // this.stato_item = this.utenti_stato;
      // this.utenti_stato = this.utenti_stato.filter((f) =>
      //   f.gruppi.includes(val)
      // );
    },
  },
  mounted() {
    this.getAttivita();
    this.getDataOggi();
    this.getInfo();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    this.data_sel_Stato = yyyy + "-" + mm + "-" + dd;
    this.getStatoUtenti(yyyy + "-" + mm + "-" + dd);
  },
  methods: {
    downloadExcel() {
      return this.utenti_stato;
    },
    getStatoUtenti(dataOggi) {
      var tipo = "";
      if (localStorage.id_profilo != "8") {
        tipo = "a";
      }
      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getstatoutenti",
          token: localStorage.user_token,
          tipo: tipo,
          id_utente: localStorage.id,
          data: dataOggi,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.utenti_stato = res.data;
        this.utenti_stato_backup = res.data;
        this.getGruppoUtenti(dataOggi);
      });
    },
    async getGruppoUtenti(dataOggi) {
      var tipo = "";
      if (localStorage.id_profilo != "8") {
        tipo = "a";
      }
      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getgruppidipendenti",
          token: localStorage.user_token,
          tipo: tipo,
          id_utente: localStorage.id,
          data: dataOggi,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.gruppi_utenti = res.data;
        this.utenti_gruppo = this.alasql(
          "SELECT des_gruppo , id_gruppo FROM ? item GROUP BY id_gruppo,des_gruppo",
          [res.data]
        );
        this.utenti_stato.forEach((elem) => {
          var filtr = this.gruppi_utenti
            .filter((f) => f.id_personale == elem.id_personale)
            .map((m) => {
              var obj = {};
              obj["id_gruppo"] = m.id_gruppo;
              obj["des_gruppo"] = "" + m.des_gruppo + "";
              return obj;
            });
          var filtrDes = filtr.map((m) => m.des_gruppo);
          var filtrId = filtr.map((m) => m.id_gruppo);
          elem["gruppi"] = filtrId;
          elem["des_gruppi"] = filtrDes.join();
        });
      });
    },
    getEvidenza() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var ddweek = String(today.getDate() - 1).padStart(2, "0");
      var date = [];
      date.push(
        yyyy.toString() + "-" + mm.toString() + "-" + ddweek.toString()
      );
      date.push(yyyy.toString() + "-" + mm.toString() + "-" + dd.toString());

      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getriepilogoutenti",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tipo: "E",
          data: date,
        },
      };
      console.log("dAttere", request.richiesta);
      this.$store.dispatch("api", request).then((res) => {
        try {
          // let utenti_allert = this.alasql(
          //   "SELECT id_utente,nominativo,des_esito FROM ? item WHERE des_esito='NON IDONEO' OR des_esito = 'CONTROLLO' GROUP BY id_utente, nominativo ",
          //   [res.data.items]
          // );
          console.log("all", res.data.items)
          this.utenti_allerta = this.alasql(
            "SELECT id_utente,nominativo,des_esito FROM ? item WHERE des_esito='NON IDONEO' OR des_esito = 'CONTROLLO' GROUP BY id_utente, nominativo ",
            [res.data.items]
          );
        } catch (error) {
          console.log(error);
        }
      });
    },
    getInfo() {
      this.values = [];
      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getriepilogoutenti",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          data: JSON.parse(localStorage.date_sel),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.headers = res.data.header;
        if (res.data.items != undefined) {
          this.item = res.data.items;
          this.nominativi_item = res.data.items;
        }
        this.getEvidenza();
      });
    },
    cancella_autocomplete(item) {
      const index = this.values.indexOf(item.nominativo);
      if (index >= 0) this.values.splice(index, 1);
    },
    cercaDate() {
      localStorage.setItem("date_sel", JSON.stringify(this.dates));
      this.getInfo();
    },
    reimpostaDate() {
      localStorage.removeItem("date_sel");
      this.getDataOggi();
      this.getInfo();
    },
    getAllerta(esito) {
      if (esito == 0) return "red";
      else if (esito == 2) return "orange";
      else return "green";
    },
    getDataOggi() {
      if (localStorage.date_sel == undefined) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var ddweek = String(today.getDate() - 7).padStart(2, "0");
        this.dates = [];
        this.dates.push(yyyy + "-" + mm + "-" + ddweek);
        this.dates.push(yyyy + "-" + mm + "-" + dd);
        localStorage.setItem("date_sel", JSON.stringify(this.dates));
      } else {
        this.dates = JSON.parse(localStorage.date_sel);
      }
    },
    selezionaUtente(elem) {
      this.$emit("selezionaUtente", elem);
    },
    cercaDateStato() {
      console.log(this.data_sel_Stato);
      this.menu_data_range = false;
      this.getStatoUtenti(this.data_sel_Stato);
    },
    goToRichieste() {
      this.$router.replace("/home/richieste");
    },
    getAttivita() {
      var request = {
        controller: "Attivita",
        method: "POST",
        richiesta: {
          action: "getattivita",
          token: localStorage.user_token,
          id_utente: localStorage.id,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          this.richieste = res.data;
        })
        .catch((e) => {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar_text_color = "white";
          //controllare password ed utente
          console.log(e);
        });
    },
    setEvento(item) {
      this.dialog_eventi = true;
      this.evento_sel = item;
      console.log(item);
    },
    setEventoUtente() {
      var tab = [];
      var a = {
        id_richiesta: this.evento_sel.id_richiesta,
        id_attivita: this.evento_sel.id_attivita,
        id_partecipante: localStorage.id,
        id_log_calendario_attivita: "-1",
        descrizione: this.descrizione_evento,
      };
      tab.push(a);
      var request = {
        controller: "Attivita",
        method: "POST",
        richiesta: {
          action: "setevento",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tabella: tab,
        },
      };
      this.$store
        .dispatch("api", request)
        .then(() => {})
        .catch((e) => {
          //controllare password ed utente
          this.snackbar_messaggio = "ERRORE! IMPOSSIBILE CARICATE LE ATTIVITA!";
          this.snackbar = true;
          console.log(e);
        });
    },
    filtraStatiUtenti(arrivo) {
      this.utenti_stato = this.utenti_stato_backup;
      if (arrivo == "stato") {
        if (this.value_stato != undefined && this.value_stato != null)
          this.utenti_stato = this.utenti_stato.filter((el) => {
            return this.value_stato.indexOf(el.des_stato) >= 0;
          });
        if (this.value_qualifica != undefined && this.value_qualifica != null)
          this.utenti_stato = this.utenti_stato.filter((el) => {
            return this.value_qualifica.indexOf(el.des_qualifica) >= 0;
          });
        if (this.value_gruppo != undefined && this.value_gruppo != null)
          this.utenti_stato = this.utenti_stato.filter((f) =>
            f.gruppi.includes(this.value_gruppo)
          );
      }
      if (arrivo == "qualifica") {
        if (this.value_stato != undefined && this.value_stato != null)
          this.utenti_stato = this.utenti_stato.filter((el) => {
            return this.value_stato.indexOf(el.des_stato) >= 0;
          });
        if (this.value_qualifica != undefined && this.value_qualifica != null)
          this.utenti_stato = this.utenti_stato.filter((el) => {
            return this.value_qualifica.indexOf(el.des_qualifica) >= 0;
          });
        if (this.value_gruppo != undefined && this.value_gruppo != null)
          this.utenti_stato = this.utenti_stato.filter((f) =>
            f.gruppi.includes(this.value_gruppo)
          );
      }
      if (arrivo == "gruppo") {
        if (this.value_stato != undefined && this.value_stato != null)
          this.utenti_stato = this.utenti_stato.filter((el) => {
            return this.value_stato.indexOf(el.des_stato) >= 0;
          });
        if (this.value_qualifica != undefined && this.value_qualifica != null)
          this.utenti_stato = this.utenti_stato.filter((el) => {
            return this.value_qualifica.indexOf(el.des_qualifica) >= 0;
          });
        if (this.value_gruppo != undefined && this.value_gruppo != null)
          this.utenti_stato = this.utenti_stato.filter((f) =>
            f.gruppi.includes(this.value_gruppo)
          );
      }
      //   if (this.stato_item.length > 0) this.utenti_stato = this.stato_item;
      //   this.stato_item = this.utenti_stato;
      //   let filtro = "";
      //   if (this.value_stato != undefined) {
      //     filtro += "des_stato =" + this.value_stato;
      //   }
      //   if (this.value_qualifica != undefined) {
      //     if (filtro == "") {
      //       filtro += "des_qualifica =" + this.value_qualifica;
      //     } else {
      //       filtro += " AND des_qualifica =" + this.value_qualifica;
      //     }
      //   }
      //   if (this.value_gruppo != undefined) {
      //     if (filtro == "") {
      //       filtro += "des_gruppi LIKE'%§" + this.value_gruppo + "§%'";
      //     } else {
      //       filtro += " AND des_gruppi LIKE'%§" + this.value_gruppo + "§%'";
      //     }
      //   }
      //   this.utenti_stato = this.alasql("SELECT * FROM ? item WHERE " + filtro, [
      //     this.utenti_stato,
      //   ]);
    },
  },
};
</script>