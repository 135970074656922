var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-snackbar',{attrs:{"vertical":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message_snackbar)+" "),_c('v-btn',{attrs:{"color":"indigo","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),(_vm.blnViewAllegato)?_c('div',{attrs:{"id":"pnlAllegati"}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Allegati")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.blnViewButtonUpload)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green","dark":""},on:{"click":function($event){_vm.dialog_new_allegato=true}}},[_vm._v(" Allega File "),_c('v-icon',[_vm._v("mdi-upload")])],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_new_allegato),callback:function ($$v) {_vm.dialog_new_allegato=$$v},expression:"dialog_new_allegato"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Caricamento nuovo allegato")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"File input","multiple":"","placeholder":"Seleziona File","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(_vm._s(text))]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.files.length - 2)+" File(s)")]):_vm._e()]}}],null,false,1193222699),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog_new_allegato = false}}},[_vm._v("Esci")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.salvaNuovoAllegato()}}},[_vm._v("Upload")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","content":"","content-class":"centered-dialog","max-width":"500px"},model:{value:(_vm.dialog_new_allegato_waiting),callback:function ($$v) {_vm.dialog_new_allegato_waiting=$$v},expression:"dialog_new_allegato_waiting"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-container',[_c('v-card-text',[_vm._v(" "+_vm._s(this.message_new_allegato_waiting)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_vm._v(" "+_vm._s(_vm.allegato)+" "),_c('v-data-table',{key:_vm.allegato.id_allegato,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allegato,"disable-sort":""},scopedSlots:_vm._u([{key:"item.date_ins",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.date_ins)))]}},{key:"item.data_validita",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.data_validita)))]}},{key:"item.estensione",fn:function(ref){
var item = ref.item;
return [(item.estensione == '.pdf' )?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-2"}},[_vm._v("mdi-file-pdf")]):_vm._e(),(item.estensione == '.jpg'   || item.estensione == '.jpeg'|| item.estensione == '.png')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-2"}},[_vm._v("mdi-file-image")]):_vm._e(),(item.estensione == '.xlsx'  || item.estensione == '.xls' || item.estensione == '.csv')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")]):_vm._e(),(item.estensione == '.doc'   || item.estensione == '.docx'|| item.estensione == '.dot')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue darken-2"}},[_vm._v("mdi-microsoft-word")]):_vm._e(),(item.estensione != '.doc'  && item.estensione != '.docx' && item.estensione != '.dot'   && 
                         item.estensione != '.jpg'  && item.estensione != '.jpeg' && item.estensione != '.png'   &&
                         item.estensione != '.xlsx' && item.estensione != '.xls'  && item.estensione != '.csv'   &&
                         item.estensione != '.doc'  && item.estensione != '.docx' && item.estensione != '.dot'   &&
                         item.estensione != '.pdf')?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                         var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                         var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"blue darken-2"},on:{"click":function($event){return _vm.downloadAllegato(item)}}},on),[_vm._v("mdi-download")])]}}],null,true)},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                         var on = ref.on;
return [(_vm.blnViewButtonUpload)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"gray darken-2"},on:{"click":function($event){return _vm.deleteAllegato(item)}}},on),[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Cancella")])])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog_delete_file),callback:function ($$v) {_vm.dialog_delete_file=$$v},expression:"dialog_delete_file"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Attenzione")]),_c('v-card-text',[_vm._v("Cancella il file '"+_vm._s(_vm.editedAllegato.des_allegato)+"' ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialog_delete_file = false}}},[_vm._v("Annulla")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteAllegatoItem(_vm.editedAllegato)}}},[_vm._v("Conferma")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }