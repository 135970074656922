<template>
  <v-card>
    <v-toolbar
      style="position: sticky; top: 0px; z-index: 1"
      color="indigo"
      dark
    >
      <v-toolbar-title>Modifica Connessione</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        @click="configurazione_quiz = true"
        color="light-blue"
        fab
        outlined
        small
        style="background-color: #ffffff"
      >
        <v-icon>mdi-file-cog-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="px-10 pt-10">
      <label for="punteggio">
        Punteggio attuale:
        {{
          connessione_sel.punteggio == null
            ? "non ancora assegnato"
            : connessione_sel.punteggio
        }}, punteggi a disposizione: {{ listaPunteggi.length }}
      </label>
      <v-select
        @change="assegnaDati"
        class="my-5"
        id="punteggio"
        :items="listaPunteggi"
        item-text="testo"
        item-value="punteggio"
        label="Il punteggio associato a questa freccia"
        outlined
        v-model="connessione_sel_backup.punteggio"
      ></v-select>
    </div>
    <v-divider></v-divider>
    <div
      style="background-color: #d3d3d3; padding-right: 10px; text-align: right"
    >
      <v-btn
        @click="annulla"
        class="mr-2 my-2"
        color="error"
        fab
        outlined
        small
        style="background-color: #ffffff"
      >
        <v-icon>mdi-close-outline</v-icon>
      </v-btn>
      <v-btn
        @click="salva"
        class="my-2"
        color="success"
        fab
        outlined
        small
        style="background-color: #ffffff"
      >
        <v-icon>mdi-check-outline</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="configurazione_quiz" width="60%" persistent>
      <ConfigurazioneQuiz
        @annulla="configurazione_quiz = false"
        :oggetto="connessione_sel_backup"
        @salva="salvaConf"
      ></ConfigurazioneQuiz>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbar_text }}
      <v-btn
        @click="snackbar = false"
        :color="snackbar_color"
        fab
        outlined
        small
        style="background-color: #ffffff"
      >
        <v-icon>mdi-close-outline</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import ConfigurazioneQuiz from "./ConfigurazioneQuiz.vue";
import QCConnection from "@/classes/QCConnection.js";

export default {
  components: {
    ConfigurazioneQuiz,
  },
  props: {
    connessione_sel: {
      type: Object,
      default: null,
    },
    nodes: {
      type: Array,
      default: () => [],
    },
    arrivo: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    configurazione_quiz: false,
    connessione_sel_backup: {},
    listaPunteggi: [],
    snackbar: false,
    snackbar_color: "",
    snackbar_text: "",
  }),
  watch: {
    connessione_sel: {
      immediate: true,
      deep: true,
      handler() {
        // Clono la connessione per fare un oggetto provvisorio
        if (this.connessione_sel != null) {
          this.connessione_sel_backup = new QCConnection(this.connessione_sel);
        }

        // Popolo la lista dei punteggi disponibili
        this.listaPunteggi = [];
        this.nodes.forEach((nodo) => {
          if (
            nodo.id_questionario_domanda ==
            this.connessione_sel_backup.id_questionario_domanda
          ) {
            nodo.frecce.forEach((freccia) => {
              if (freccia.assegnata == 0) {
                this.listaPunteggi.push({
                  id_questionario_freccia: freccia.id_questionario_freccia,
                  punteggio: freccia.punteggio,
                  testo: "Punteggio fino a " + freccia.punteggio.toString(),
                });
              }
            });
          }
        });
      },
    },
  },
  methods: {
    // MODIFICHE A ELEMENTI INTERNI
    assegnaDati() {
      // Assegno il tipo in base al punteggio
      this.connessione_sel_backup.type =
        this.connessione_sel_backup.punteggio == 100
          ? "pass"
          : this.connessione_sel_backup.punteggio == 0
          ? "reject"
          : "annulla";
      // Assegno id_questionario_freccia in base al punteggio
      this.listaPunteggi.forEach((elem) => {
        if (elem.punteggio == this.connessione_sel_backup.punteggio) {
          this.connessione_sel_backup.id_questionario_freccia =
            elem.id_questionario_freccia;
        }
      });
    },
    salvaConf(confDaSalvare) {
      this.connessione_sel_backup.conf = confDaSalvare;
      this.configurazione_quiz = false;
    },
    // Salva e annulla
    salva() {
      console.log("entrato salva 1");
      if (this.connessione_sel_backup.punteggio != null) {
        console.log("entrato salva 2");
        let risultato = {
          connessione_sostituita: new QCConnection(this.connessione_sel),
          connessione: this.connessione_sel_backup,
          arrivo: this.arrivo,
        };
        console.log(risultato);
        this.$emit("salva", risultato);
        // Ripulisco i campi
        this.connessione_sel_backup = [];
        //this.nodes = [];
      } else {
        this.snackbar = true;
        this.snackbar_text =
          "Per confermare le modifiche è necessario selezionare un punteggio.";
        this.snackbar_color = "error";
      }
    },
    annulla() {
      this.$emit("annulla");
    },
  },
};
</script>
