<template>
  <v-card>
    <v-tabs vertical>
      <v-navigation-drawer permanent expand-on-hover>
        <v-tab class="tabs">
          <v-icon class="pr-4">mdi-checkbox-multiple-outline</v-icon>
          CARATTERISTICHE
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-menu</v-icon> CATEGORIA
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-script</v-icon>
          SPECIFICHE
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-package-variant-closed</v-icon> PACKAGING
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-assistant</v-icon> ASSOCIAZIONI
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-text</v-icon> DISTINTA BASE
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-circle-slice-5</v-icon> CICLO PRODUZIONE
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-store</v-icon> MAGAZZINO
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-view-carousel</v-icon> UTILIZZO
        </v-tab>
        <v-tab v-show="data.id_articolo" class="tabs">
          <v-icon class="pr-4">mdi-cash</v-icon> COSTO
        </v-tab>
      </v-navigation-drawer>
      <v-tab-item
        style="height: 100px !important"
        :transition="false"
        :reverse-transition="false"
      >
        <v-card flat>
          <v-card-text class="px-0 py-0" style="overflow-y: auto; height: 100vh">
            <Caratteristiche
              v-on:ValChanged="valChanged($event)"
              v-on:update="updateDrawer($event)"
              :CodEnable="this.CodEnable"
              :dataChar="this.data"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Categoria />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Specifiche :data="this.data" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Packaging
              v-on:update="updatePackging($event)"
              :data="this.data"
              :conteCod="this.contentConte"
              :imballoCod="this.imballoCod"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Associations :data="this.data" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <BasicDistinct :pdata="this.data" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <CicoloProdList :data="this.data" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!--<v-tab-item  :transition="false" :reverse-transition="false">
        <v-card  flat>
            <v-card-text>
                <Commesse />
            </v-card-text>
        </v-card>
    </v-tab-item>-->
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Magazzino :articolo_sel="data" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Utilizzo :pdata="this.data" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <Costo />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false">
        <v-card flat>
          <v-card-text class="px-0 py-0">
            <DatiExtra />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import Caratteristiche from "./CaratteristicheNew";
import Categoria from "./Categoria";
import Specifiche from "./Specifiche";
import Packaging from "./Packaging";
import Associations from "./Associazioni";
import BasicDistinct from "./DistintaBase";
import Magazzino from "./Magazzino";
import Utilizzo from "./Utlizzo";
import Costo from "./Costo";
import DatiExtra from "./DatiExtra";
import CicoloProdList from "../CicoloFasi/ArticoloCicloFasiNew.vue";
export default {
  components: {
    Caratteristiche,
    Categoria,
    Specifiche,
    Packaging,
    Associations,
    BasicDistinct,
    Magazzino,
    Utilizzo,
    Costo,
    DatiExtra,
    CicoloProdList,
  },
  props: ["data", "contentConte", "imballoCod", "CodEnable"],
  data() {
    return {
      overlay1: false,
      items: [],
    };
  },
  methods: {
    valChanged(val) {
      this.$emit("valChanged", val);
    },
    updateDrawer(val) {
      this.$emit("updateDrawer", val);
    },
    updatePackging(val) {
      this.$emit("updatePackging", val);
    },
  },
};
</script>
<style scoped>
.tabs {
  justify-content: start !important;
}
</style>
