<template>
  <v-card>
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text">Modifica Domanda</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            @click="confermaModifiche"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
        </template>
        <span>Salva Domanda</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="
              proposizione = nodo_sel_backup;
              configurazione_quiz = true;
            "
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary">mdi-file-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>Modifica Configurazione</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2 my-2"
            @click="annulla"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <!-- Qui sotto c'è il menu a tendina
         per selezionare il tipo di domanda / nodo
    -->
    <v-row class="ma-0 px-5" align="center" justify="center">
      <v-col lg="4" cols="4">
        <v-select
          @change="editTipo()"
          dense
          :items="[
            { name: 'Risposta testuale', id: 0 },
            { name: 'Risposta multipla (1 sola corretta)', id: 1 },
            { name: 'Risposta multipla (più di una corretta)', id: 2 },
            { name: 'Selezione immagine', id: 3 },
            { name: 'Acquisizione e modifica di immagine', id: 4 },
          ]"
          item-text="name"
          item-value="id"
          id="tipoDiDomanda"
          label="Tipo di domanda"
          style="display: inline-block"
          v-model="nodo_sel_backup.id_tipo_domanda"
        ></v-select>
      </v-col>
      <v-col lg="4" cols="4">
        <v-select
          dense
          :items="[
            { name: 'Non esportare', id: 0 },
            { name: 'Esporta in formato compresso .zip', id: 1 },
            { name: 'Esporta come documento PDF', id: 2 },
          ]"
          item-text="name"
          item-value="id"
          id="tipoDiEsportazione"
          label="Esportazione"
          v-model="nodo_sel_backup.id_tipo_esportazione"
          style="display: inline-block"
        ></v-select>
      </v-col>
      <v-col lg="4" cols="4">
        <v-checkbox
          class="ml-auto mr-auto"
          label="Accetta allegati"
          v-model="nodo_sel_backup.accetta_allegati"
          style="display: inline-block"
        ></v-checkbox>
      </v-col>
    </v-row>

    <!--Qui si inserisce il testo della domanda-->

    <div align="center">
      <div
        style="
          display: inline-block;
          margin-left: auto;
          margin-right: auto;
          padding-right: 20px;
          vertical-align: middle;
        "
      >
        <v-textarea
          auto-grow
          class="mx-auto"
          id="testoDomanda"
          label="Testo domanda"
          outlined
          placeholder="Inserisci qui il testo della domanda"
          single-line
          style="display: inline-block; width: 500px"
          v-model="nodo_sel_backup.des_questionario_domanda"
        ></v-textarea>
      </div>
      <!--Qui sotto c'è il form per caricare gli allegati-->
      <div
        v-if="nodo_sel_backup.accetta_allegati"
        style="
          display: inline-block;
          margin-bottom: 10px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          width: 500px;
          vertical-align: middle;
        "
      >
        <AllegatoList
          :tipo="'QUESTIONARIO'"
          :id_rif="nodo_sel.id_questionario"
          :id_rif_dettaglio="nodo_sel.num_questionario_domanda"
          :famiglia="-1"
          :blnViewButtonUpload="true"
          :blnViewButtonDelete="true"
          :strPath="'root'"
        ></AllegatoList>
      </div>
      <v-row
        class="ma-0"
        v-if="
          nodo_sel_backup.id_tipo_domanda == 0 ||
          nodo_sel_backup.id_tipo_domanda == 4
        "
      >
        <v-col cols="6">
          <div
            style="
              display: inline-block;
              text-align: center;
              margin-right: 10px;
            "
          >
            <label for="caratteriMin" style="display: inline-block"
              >Lunghezza minima risposta:</label
            >
            <v-text-field
              id="caratteriMin"
              single-line
              style="display: inline-block; margin-left: 5px; width: 50px"
              type="number"
              v-model="nodo_sel_backup.caratteri_min"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div style="display: inline-block; text-align: center">
            <label for="caratteriMax" style="display: inline-block"
              >Lunghezza massima risposta:</label
            >
            <v-text-field
              id="caratteriMax"
              single-line
              style="display: inline-block; margin-left: 5px; width: 50px"
              type="number"
              v-model="nodo_sel_backup.caratteri_max"
            />
          </div>
        </v-col>
      </v-row>
      <div
        style="
          display: inline-block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          padding-left: 15px;
          text-align: left;
          vertical-align: middle;
        "
        v-if="
          nodo_sel_backup.id_tipo_domanda == 3 ||
          nodo_sel_backup.id_tipo_domanda == 4
        "
      >
        <!-- Qui sotto viene visualizzato il form
          per caricare le immagini che l'utente può
          selezionare come risposta.
        -->
        <div style="max-width: 510px; min-width: 235px">
          <v-file-input
            accept="image/*"
            @change="renderizzaImmagineDomanda()"
            class="mb-n3 pb-0 pr-2"
            label="Carica un'immagine (max 500 × 500 px)"
            outlined
            style="max-width: 500px; min-width: 325px"
            v-model="img_caricata"
          ></v-file-input>
        </div>
        <v-checkbox
          class="mb-5"
          label="Mostra all'utente i contorni delle aree cliccabili"
          v-if="nodo_sel_backup.id_tipo_domanda == 3"
          v-model="nodo_sel_backup.mostra_contorno_aree"
        ></v-checkbox>
        <div style="text-align: left">
          <v-checkbox
            class="ml-auto mr-auto"
            label="Consenti il caricamento di immagini"
            style="display: inline-block"
            v-if="nodo_sel_backup.id_tipo_domanda == 4"
            v-model="nodo_sel_backup.abilita_caricamento_immagini"
          ></v-checkbox>
        </div>
        <div
          style="text-align: left"
          v-if="nodo_sel_backup.id_tipo_domanda == 4"
        >
          <label
            class="mt-5 mr-2"
            for="idTipoModificheImmagine"
            style="display: inline-block"
            >Modifiche sull'immagine:</label
          >
          <v-select
            :items="[
              { name: 'Non effettuabili', id: 0 },
              { name: 'Opzionali', id: 1 },
              { name: 'Da effettuare', id: 2 },
            ]"
            item-text="name"
            item-value="id"
            id="idTipoModificheImmagine"
            label="Opzioni"
            solo
            v-model="nodo_sel_backup.id_tipo_modifica_immagine"
            style="display: inline-block; width: 200px"
          ></v-select>
        </div>
      </div>
    </div>
    <div
      v-if="
        nodo_sel_backup.id_tipo_domanda == 3 ||
        nodo_sel_backup.id_tipo_domanda == 4
      "
      style="text-align: center"
    >
      <!--Qui c'è il riquadro che mostra l'immagine della domanda-->
      <div class="text-center pa-2">
        <v-chip>{{
          cursor_to_chart_offset.x + ", " + cursor_to_chart_offset.y
        }}</v-chip>
        <v-row class="ma-0">
          <v-col cols="7">
            <div
              display="inline-block"
              height="500"
              id="chartImmagine"
              @mousedown="clickGiu($event)"
              @mousemove="movimentoMouse"
              @mouseup="clickSu"
              style="
                background-repeat: no-repeat;
                background-size: 100% 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 5px;
                max-height: 500px;
                max-width: 500px;
                min-height: 500px;
                min-width: 500px;
              "
              width="500"
            >
              <svg
                id="svgImmagine"
                height="500"
                style="border: 1px solid black"
                width="500"
              />
            </div>
          </v-col>
          <v-col
            cols="5"
            v-if="nodo_sel_backup.id_tipo_domanda == 3"
            style="text-align: left"
          >
            <!-- Qui sotto c'è lo spazio per le risposte inserite nelle immagini-->
            <div
              style="
                display: inline-block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
              "
            >
              <label style="text-align: center">
                <b>RISPOSTE</b>
              </label>
              <div
                v-if="nodo_sel_backup.risposte.length == 0"
                style="margin-bottom: 25px; margin-top: 25px"
              >
                <i>Non ci sono ancora risposte per questa domanda</i>
              </div>
              <table
                style="
                  margin-bottom: 15px;
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: 15px;
                "
                v-else
              >
                <draggable>
                  <tr>
                    <td>
                      <!-- Spazio per la matita -->
                    </td>
                    <td>
                      <!-- Spazio per la gomma -->
                    </td>
                    <td style="padding-left: 15px; text-align: left">
                      <b>Punti</b>
                    </td>
                    <td style="padding-left: 15px; text-align: left">
                      <b>N. area</b>
                    </td>
                  </tr>
                  <tr
                    :key="risposta.id_questionario_risposta"
                    v-for="risposta in nodo_sel_backup.risposte"
                  >
                    <td>
                      <v-btn
                        @click="modificaRisposta(risposta)"
                        color="light-blue"
                        dark
                        fab
                        outlined
                        x-small
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                        @click="delRisposta(risposta)"
                        color="error"
                        dark
                        fab
                        outlined
                        x-small
                      >
                        <v-icon>mdi-eraser</v-icon>
                      </v-btn>
                    </td>
                    <td style="padding-left: 15px; text-align: left">
                      {{ risposta.punteggio_risposta }}
                    </td>
                    <td style="padding-left: 15px; text-align: left">
                      {{ nodo_sel_backup.risposte.indexOf(risposta) + 1 }}
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <!--Qui sotto c'è la sezione
          per preparare nuove risposte
          nelle domande con una o più
          risposte corrette
    -->
    <div
      v-if="
        nodo_sel_backup.id_tipo_domanda == 1 ||
        nodo_sel_backup.id_tipo_domanda == 2
      "
      align="center"
    >
      <v-divider></v-divider>
      <v-container class="ma-0 pa-0" fluid>
        <v-row align="center" class="text-center ma-0">
          <v-col class="ma-0 pb-0 px-0">
            <b>RISPOSTE</b>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pa-0" fluid>
        <v-row class="ma-0">
          <v-col cols="12" md="6" align="center" justify="center">
            <!-- Qui sotto c'è il form
                 per preparare nuove risposte
            -->
            <v-textarea
              class="mx-3"
              id="nuovaRisposta"
              label="Nuova risposta"
              outlined
              single-line
              style="width: 400px"
              v-model="testo_risp_mod"
            ></v-textarea>
            <v-btn
              class="text-center"
              @click="aggiungiRisposta"
              color="primary"
              dark
              id="aggiungiRisposta"
              small
            >
              Aggiungi risposta
            </v-btn>
          </v-col>
          <!-- Qui sotto vengono visualizzate le risposte
               aggiunte dal docente, con i dati relativi.
        -->
          <v-col cols="12" md="6" style="text-align: left">
            <v-container fluid v-if="nodo_sel_backup.risposte.length == 0">
              <v-row style="margin-bottom: 25px; margin-top: 25px">
                <i>Non ci sono ancora risposte per questa domanda</i>
              </v-row>
            </v-container>
            <v-container fluid v-else>
              <draggable>
                <v-row>
                  <v-col cols="2">
                    <!--Colonna della matita-->
                  </v-col>
                  <v-col cols="2">
                    <!--Colonna della gomma da cancellare-->
                  </v-col>
                  <v-col cols="2">
                    <b>Punti</b>
                  </v-col>
                  <v-col cols="4">
                    <b>Testo risposte</b>
                  </v-col>
                  <v-col cols="2">
                    <!--Colonna per il bottone di configurazione-->
                  </v-col>
                </v-row>
                <v-row
                  :key="risposta.id_questionario_risposta"
                  v-for="risposta in nodo_sel_backup.risposte"
                >
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="modificaRisposta(risposta)"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Modifica Risposta</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="delRisposta(risposta)"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="error">mdi-eraser</v-icon>
                        </v-btn>
                      </template>
                      <span>Cancella Risposta</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="2">
                    {{ risposta.punteggio_risposta }}
                  </v-col>
                  <v-col cols="4">
                    {{ risposta.des_risposta }}
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="
                            proposizione = risposta;
                            configurazione_quiz = true;
                          "
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">mdi-file-cog-outline</v-icon>
                        </v-btn>
                      </template>
                      <span> Apri Configurazione </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </draggable>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      class="mt-5"
      v-if="
        nodo_sel_backup.id_tipo_domanda == 2 ||
        nodo_sel_backup.id_tipo_domanda == 3
      "
    >
      <v-divider class="my-5"></v-divider>
      <div style="text-align: center">
        <span style="display: inline-block; margin-right: 5px">
          Numero frecce in uscita:
        </span>
        <span class="mx-5">
          {{ nodo_sel_backup.frecce.length }}
        </span>
        <v-btn class="mx-5" @click="frecceUp" small>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
        <v-btn @click="frecceDown(nodo_sel_backup.frecce)" small>
          <v-icon color="error">mdi-minus</v-icon>
        </v-btn>
      </div>
      <v-container fluid v-if="nodo_sel_backup.frecce.length > 0">
        <v-row align="center" class="ma-0 pa-0" justify="center">
          <v-col class="ma-0 pa-0" cols="3" md="2">
            <i>Freccia</i>
          </v-col>
          <v-col class="ma-0 pa-0" cols="3" md="2">
            <i>Punteggio</i>
          </v-col>
          <v-col class="ma-0 pa-0" cols="3" md="2">
            <!--Colonna per la gomma da cancellare-->
          </v-col>
        </v-row>
        <v-row
          align="center"
          class="ma-0 pa-0"
          justify="center"
          v-for="elem in nodo_sel_backup.frecce"
          :key="elem.indexOf"
        >
          <v-col class="ma-0 pa-0" cols="3" md="2">{{
            nodo_sel_backup.frecce.indexOf(elem) + 1
          }}</v-col>
          <v-col class="ma-0 pa-0" cols="3" md="2">
            <v-text-field
              @change="elem.assegnata = 0"
              class="ma-0 pa-0"
              id="punteggio"
              single-line
              style="display: inline-block; width: 50px"
              type="number"
              v-model="elem.punteggio"
            />
          </v-col>
          <v-col class="ma-0 pa-0" cols="3" md="2">
            <v-btn
              @click="delFreccia(elem)"
              small
            >
              <v-icon color="error">mdi-eraser</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog persistent v-model="dialog_modifica_risposta" width="50%">
      <v-card style="text-align: center">
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Modifica la Risposta
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="confermaModificaRisposta()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="annullaModificaRisposta()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Annulla</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            auto-grow
            class="mt-10 mx-auto"
            id="testoRispostaDaModificare"
            label="Testo risposta"
            outlined
            placeholder="Inserisci qui il nuovo testo della risposta"
            v-if="
              nodo_sel_backup.id_tipo_domanda == 1 ||
              nodo_sel_backup.id_tipo_domanda == 2
            "
            v-model="des_risposta_mod"
            style="display: inline-block; width: 500px"
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <p>
            Il nuovo punteggio che vuoi associare alla risposta:
            <v-text-field
              single-line
              style="display: inline-block; margin-left: 5px; width: 50px"
              type="number"
              v-model="punteggio_risp_mod"
            />
          </p>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar2" :timeout="3000">
        {{ snackbar_text2 }}
        <v-btn
          @click="snackbar2 = false"
          :color="snackbar_color2"
          fab
          outlined
          small
          style="background-color: #ffffff"
        >
          <v-icon>mdi-close-outline</v-icon>
        </v-btn>
      </v-snackbar>
    </v-dialog>
    <v-dialog
      v-model="configurazione_quiz"
      width="60%"
      persistent
      style="background-color: blue"
    >
      <ConfigurazioneQuiz
        @annulla="configurazione_quiz = false"
        :oggetto="proposizione"
        @salva="salvaConf"
        style="width: 100%"
      ></ConfigurazioneQuiz>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbar_text }}
      <v-btn
        @click="snackbar = false"
        :color="snackbar_color"
        fab
        outlined
        small
        style="background-color: #ffffff"
      >
        <v-icon>mdi-close-outline</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import _ from "lodash";
import * as d3 from "d3";
import Base64 from "b64-to-blob";
import ConfigurazioneQuiz from "./ConfigurazioneQuiz.vue";
import draggable from "vuedraggable";
import AllegatoList from "./Allegato/AllegatoList.vue";

export default {
  components: {
    AllegatoList,
    ConfigurazioneQuiz,
    draggable,
  },
  props: {
    prop_id_questionario: { type: Number, default: null },
    nodo_sel: {
      type: Object,
      default: null,
    },
    arrivo: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      aree: [],
      aree_delete: [],
      connections_delete: [],
      frecce_delete: [],
      nodes_delete: [],
      risposte_delete: [],
      cursor_to_chart_offset: { x: 0, y: 0 },
      cliccato: false,
      des_risposta_mod: null,
      img_caricata: {},
      id_questionario_risposta_mod: +new Date(),
      id_risp_mod: null,
      id_risp_img_mod: null,

      configurazione_quiz: false,
      dialog_modifica_risposta: false,

      nodo_sel_backup: {},
      proposizione: {},
      num_risposta: 0,
      num_risposta_mod: null,
      cord_risp_1: { x: 0, y: 0 },
      cord_risp_2: { x: 0, y: 0 },

      punteggio_risp_mod: null,
      punteggio_risp_img_mod: null,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      snackbar2: false,
      snackbar_text2: "",
      snackbar_color2: "error",
      testo_risp_mod: "",
    };
  },
  watch: {
    "nodo_sel_backup.aree": function () {
      if (this.nodo_sel_backup.aree) {
        if (this.nodo_sel_backup.aree.length > 0) {
          this.renderizzaAree();
        }
      }
    },

    "nodo_sel_backup.img_caricata": function () {
      if (
        this.nodo_sel_backup.img_caricata == this.nodo_sel.img_caricata &&
        this.nodo_sel_backup.img_caricata != ""
      ) {
        let that = this;
        let checkExist = setInterval(function () {
          if (document.getElementById("chartImmagine")) {
            clearInterval(checkExist);
            that.renderizzaAree();
            that.renderizzaImmagineDomandaSalvata();
          }
        }, 100);
      }
    },
    nodo_sel: {
      immediate: true,
      deep: true,
      handler() {
        if (this.nodo_sel != null) {
          this.nodo_sel_backup = _.cloneDeep(this.nodo_sel);
        }
      },
    },
  },
  methods: {
    //GESTIONE DISEGNA AREE
    renderizzaAree() {
      if (this.nodo_sel_backup.aree.length != 0) {
        d3.selectAll("#svgImmagine > rect").remove();
        d3.selectAll("#svgImmagine > text").remove();
        this.nodo_sel_backup.aree.forEach((elem) => {
          if (elem.x) {
            d3.select("#svgImmagine")
              .append("rect")
              .attr("x", elem.x)
              .attr("y", elem.y)
              .attr("width", elem.width)
              .attr("height", elem.height)
              .attr(
                "style",
                "stroke: #000066; fill: #3333ff;fill-opacity: 0.2;"
              );

            d3.select("#svgImmagine")
              .append("rect")
              .attr("x", elem.x)
              .attr("y", elem.y)
              .attr("width", 20)
              .attr("height", 20)
              .attr("style", "fill: #000000;fill-opacity: 0.5;");

            d3.select("#svgImmagine")
              .append("text")
              .attr("x", elem.x + 5)
              .attr("y", elem.y + 15)
              .attr("class", "unselectable")
              .text(() => this.nodo_sel_backup.aree.indexOf(elem) + 1)
              .style("fill", "white");
          }
        });
      }
    },
    clickGiu() {
      this.cord_risp_1.x = this.cursor_to_chart_offset.x;
      this.cord_risp_1.y = this.cursor_to_chart_offset.y;
      this.cliccato = true;
    },
    movimentoMouse() {
      let boundingClientRect = event.currentTarget.getBoundingClientRect();
      let actualX = event.pageX - boundingClientRect.left - window.scrollX;
      this.cursor_to_chart_offset.x = Math.trunc(actualX);
      let actualY = event.pageY - boundingClientRect.top - window.scrollY;
      this.cursor_to_chart_offset.y = Math.trunc(actualY);

      if (this.cliccato == true) {
        d3.selectAll("#svgImmagine > rect").remove();
        d3.selectAll("#svgImmagine > text").remove();

        // Definisco il primo e il secondo punto
        let cord_risp_1_tmp = {};
        cord_risp_1_tmp.x = this.cord_risp_1.x;
        cord_risp_1_tmp.y = this.cord_risp_1.y;

        let cord_risp_2_tmp = {};
        cord_risp_2_tmp.x = this.cursor_to_chart_offset.x;
        cord_risp_2_tmp.y = this.cursor_to_chart_offset.y;

        /**Le coordinate del primo punto
         * devono sempre essere inferiori rispetto
         * alle coordinate del secondo punto.
         * Se non è così, le scambio in modo
         * che non ci siano valori negativi nelle dimensioni
         * quando do le istruzioni per disegnare il rettangolo.
         * (Praticamente faccio in modo che i punti corrispondano sempre all'angolo
         * in basso a sinistra e a quello in alto a destra)
         */
        if (cord_risp_1_tmp.x > cord_risp_2_tmp.x) {
          let tmpX = cord_risp_2_tmp.x;
          cord_risp_2_tmp.x = cord_risp_1_tmp.x;
          cord_risp_1_tmp.x = tmpX;
        }
        if (cord_risp_1_tmp.y > cord_risp_2_tmp.y) {
          let tmpY = cord_risp_2_tmp.y;
          cord_risp_2_tmp.y = cord_risp_1_tmp.y;
          cord_risp_1_tmp.y = tmpY;
        }

        let area_tmp = {
          x: cord_risp_1_tmp.x,
          y: cord_risp_1_tmp.y,
          width: cord_risp_2_tmp.x - cord_risp_1_tmp.x,
          height: cord_risp_2_tmp.y - cord_risp_1_tmp.y,
        };

        d3.select("#svgImmagine")
          .append("rect")
          .attr("x", area_tmp.x)
          .attr("y", area_tmp.y)
          .attr("width", area_tmp.width)
          .attr("height", area_tmp.height)
          .attr("style", "stroke: #000066; fill: #ffffff;fill-opacity: 0.2;");
      }
    },
    clickSu() {
      this.cliccato = false;
      this.cord_risp_2.x = this.cursor_to_chart_offset.x;
      this.cord_risp_2.y = this.cursor_to_chart_offset.y;
      /**Le coordinate del primo punto
       * devono sempre essere inferiori rispetto
       * alle coordinate del secondo punto.
       * Se non è così, le scambio in modo
       * che non ci siano valori negativi nelle dimensioni
       * quando do le istruzioni per disegnare il rettangolo.
       * (Praticamente faccio in modo che i punti corrispondano sempre all'angolo
       * in basso a sinistra e a quello in alto a destra)
       */
      if (this.cord_risp_1.x > this.cord_risp_2.x) {
        let tmpX = this.cord_risp_2.x;
        this.cord_risp_2.x = this.cord_risp_1.x;
        this.cord_risp_1.x = tmpX;
      }
      if (this.cord_risp_1.y > this.cord_risp_2.y) {
        let tmpY = this.cord_risp_2.y;
        this.cord_risp_2.y = this.cord_risp_1.y;
        this.cord_risp_1.y = tmpY;
      }
      // Compongo il testo della risposta
      this.testo_risp_mod =
        "(" +
        this.cord_risp_1.x +
        "," +
        this.cord_risp_1.y +
        ")" +
        " " +
        "(" +
        this.cord_risp_2.x +
        "," +
        this.cord_risp_2.y +
        ")";
      //console.log(this.testo_risp_mod);
      let area = {
        id_questionario: this.nodo_sel_backup.id_questionario,
        id_questionario_domanda: this.nodo_sel_backup.id_questionario_domanda,
        id_questionario_area: +new Date(),
        x: this.cord_risp_1.x,
        y: this.cord_risp_1.y,
        width: this.cord_risp_2.x - this.cord_risp_1.x,
        height: this.cord_risp_2.y - this.cord_risp_1.y,
      };
      // Controllo che l'area non sia sovrapposta
      let controllo = false;
      this.nodo_sel_backup.aree.forEach((areaDisegnata) => {
        // Angoli dell'area già presente
        let angolo_NO_AD = { x: areaDisegnata.x, y: areaDisegnata.y };
        let angolo_NE_AD = {
          x: areaDisegnata.x + areaDisegnata.width,
          y: areaDisegnata.y,
        };
        let angolo_SO_AD = {
          x: areaDisegnata.x,
          y: areaDisegnata.y + areaDisegnata.height,
        };

        // Angoli dell'area nuova
        let angolo_NO_AN = { x: area.x, y: area.y };
        let angolo_NE_AN = {
          x: area.x + area.width,
          y: area.y,
        };
        let angolo_SO_AN = {
          x: area.x,
          y: area.y + area.height,
        };
        let angolo_SE_AN = {
          x: area.x + area.width,
          y: area.y + area.height,
        };

        // Controllo che l'angolo NO nuovo non sia compreso fra quelli già presenti
        if (
          angolo_NO_AN.x > angolo_NO_AD.x &&
          angolo_NO_AN.x < angolo_NE_AD.x &&
          angolo_NO_AN.y < angolo_SO_AD.y &&
          angolo_NO_AN.y > angolo_NO_AD.y
        ) {
          controllo = true;
        }

        // Controllo che l'angolo NE nuovo non sia compreso fra quelli già presenti
        if (
          angolo_NE_AN.x > angolo_NO_AD.x &&
          angolo_NE_AN.x < angolo_NE_AD.x &&
          angolo_NE_AN.y < angolo_SO_AD.y &&
          angolo_NE_AN.y > angolo_NO_AD.y
        ) {
          controllo = true;
        }

        // Controllo che l'angolo SO nuovo non sia compreso fra quelli già presenti
        if (
          angolo_SO_AN.x > angolo_NO_AD.x &&
          angolo_SO_AN.x < angolo_NE_AD.x &&
          angolo_SO_AN.y < angolo_SO_AD.y &&
          angolo_SO_AN.y > angolo_NO_AD.y
        ) {
          controllo = true;
        }

        // Controllo che l'angolo SE nuovo non sia compreso fra quelli già presenti
        if (
          angolo_SE_AN.x > angolo_NO_AD.x &&
          angolo_SE_AN.x < angolo_NE_AD.x &&
          angolo_SE_AN.y < angolo_SO_AD.y &&
          angolo_SE_AN.y > angolo_NO_AD.y
        ) {
          controllo = true;
        }
      });

      /* Se le dimensioni non sono troppo piccole e l'area non è sovrapposta,
       *  le salvo come risposta */
      if (controllo == false) {
        if (area.height > 10 && area.width > 10) {
          this.aggiungiRisposta();
          // Assegno all'area lo stesso id della risposta e la renderizzo
          area.id_questionario_risposta = this.nodo_sel_backup.risposte[
            this.nodo_sel_backup.risposte.length - 1
          ].id_questionario_risposta;
          area.num_questionario_risposta = this.nodo_sel_backup.risposte[
            this.nodo_sel_backup.risposte.length - 1
          ].num_questionario_risposta;
          this.nodo_sel_backup.aree.push(area);
        }
      } else {
        // Stampo un avviso e ri-renderizzo le aree
        this.snackbar = true;
        this.snackbar_color = "error";
        this.snackbar_text = "Le aree non si possono sovrapporre.";
        this.renderizzaAree();
      }
      // Ripulisco il "testo_risp_mod" e le coordinate della risposta
      this.testo_risp_mod = "";
      this.cord_risp_1.x = null;
      this.cord_risp_1.y = null;
      this.cord_risp_2.x = null;
      this.cord_risp_2.y = null;
    },
    //FINE GESTIONE DISEGNA AREE
    // AGGIUNGI RISPOSTA
    aggiungiRisposta() {
      /* A seconda del tipo di domanda e del numero
       * di risposte presenti, voglio che le risposte inserite abbiano
       * un diverso punteggio di default */
      let punteggio = 0;
      if (
        this.nodo_sel_backup.id_tipo_domanda == 1 &&
        this.nodo_sel_backup.risposte.length > 0
      ) {
        punteggio = 0;
      } else {
        punteggio = 100;
      }

      // Genero i campi principali della risposta
      if (this.testo_risp_mod !== "") {
        var rispostaDaAggiungere = {
          des_risposta: this.testo_risp_mod,
          punteggio_risposta: punteggio,
          id_questionario_risposta: this.id_questionario_risposta_mod,
          num_questionario_risposta: this.id_questionario_risposta_mod,
          id_questionario_domanda: this.nodo_sel_backup.id_questionario_domanda,
          id_questionario: this.nodo_sel_backup.id_questionario,
          tipologia: "risposta",
          tipo: null,
          link: "",
          codice: "",
          parametri: [],
          num_questionario_domanda: this.nodo_sel_backup
            .num_questionario_domanda,
          num_risposta: null,
        };
        /* Valorizzo rispostaDaAggiungere.num_risposta qui perché non posso
         * usare un postulato condizionale direttamente nell'oggetto
         */
        if (this.num_rispostaInModifica == null) {
          rispostaDaAggiungere.num_risposta = this.assegnaNumRisposta();
        } else {
          rispostaDaAggiungere.num_risposta = this.num_rispostaInModifica;
        }

        this.nodo_sel_backup.risposte.push(rispostaDaAggiungere);

        this.id_questionario_risposta_mod = +new Date();
        this.num_risposta_mod = null;
        this.testo_risp_mod = "";
        this.punteggio_risp_mod = 100;

        /** Se il tipo domanda è a singola o più
         *  risposte corrette, sposto il focus sul
         *  campo per inserire una nuova risposta.
         *  Invece non faccio niente se il tipo di
         *  domanda è 3 o 4 (quindi con un'immagine). */
        if (
          this.nodo_sel_backup.id_tipo_domanda == 1 ||
          this.nodo_sel_backup.id_tipo_domanda == 2
        ) {
          document.getElementById("nuovaRisposta").focus();
        }
      } else {
        this.snackbar = true;
        this.snackbar_text = "Il testo della risposta non può essere vuoto.";
      }
    },
    assegnaNumRisposta() {
      this.numRisposta = this.numRisposta + 1;
      return this.numRisposta;
    },
    //END AGGIUNGI RISPOSTA

    //MODIFICA RISPOSTE
    confermaModificaRisposta() {
      // Controllo che alla risposta non siano associati più di 100 punti.
      let risposta_oltre_100 = false;

      if (this.punteggio_risp_mod > 100) {
        risposta_oltre_100 = true;
      }

      if (risposta_oltre_100 == true) {
        this.snackbar_text2 =
          "Punteggio massimo assegnabile a una risposta: 100.";
        this.snackbar_color2 = "error";
        this.snackbar2 = true;
        return;
      } else {
        // Se il punteggio della risposta è meno di 100, registro la modifica.
        this.nodo_sel_backup.risposte.forEach((elem) => {
          if (elem.id_questionario_risposta == this.id_risp_mod) {
            elem.des_risposta = this.des_risposta_mod;
            elem.punteggio_risposta = this.punteggio_risp_mod;
          }
        });
        this.des_risposta_mod = null;
        this.id_risp_mod = null;
        this.punteggio_risp_mod = null;
        this.dialog_modifica_risposta = false;
      }
    },
    annullaModificaRisposta() {
      this.dialog_modifica_risposta = false;
    },
    modificaRisposta(risposta) {
      this.id_risp_mod = risposta.id_questionario_risposta;
      this.des_risposta_mod = risposta.des_risposta;
      this.punteggio_risp_mod = risposta.punteggio_risposta;
      this.dialog_modifica_risposta = true;
    },
    delRisposta(risposta) {
      let risposte_tmp = this.nodo_sel_backup.risposte.filter(
        (elem) =>
          elem.id_questionario_risposta !== risposta.id_questionario_risposta
      );
      this.nodo_sel_backup.risposte = risposte_tmp;
      this.risposte_delete.push({ id: risposta.id_questionario_risposta });

      // Se la risposta era un'area allora l'area viene cancellata
      this.nodo_sel_backup.aree.forEach((elem) => {
        if (
          elem.id_questionario_risposta == risposta.id_questionario_risposta
        ) {
          this.delArea(elem);
        }
      });
    },
    delArea(area) {
      this.aree_delete.push({ id: area.id_questionario_area });

      let areeTMP = this.nodo_sel_backup.aree.filter(
        (elem) =>
          elem.id_questionario_risposta !== area.id_questionario_risposta
      );
      this.nodo_sel_backup.aree = areeTMP;
      this.renderizzaAree();
      if (this.nodo_sel_backup.aree.length == 0) {
        d3.selectAll("#svgImmagine > rect").remove();
        d3.selectAll("#svgImmagine > text").remove();
      }
    },
    //FINE MODIFICA RISPOSTE
    //MODIFICA FRECCE
    frecceUp() {
      let id_per_freccia = +new Date();
      this.nodo_sel_backup.frecce.push({
        id_questionario: this.nodo_sel_backup.id_questionario,
        id_questionario_freccia: id_per_freccia,
        num_questionario_freccia: id_per_freccia,
        id_questionario_domanda: this.nodo_sel_backup.id_questionario_domanda,
        num_questionario_domanda: this.nodo_sel_backup.id_questionario_domanda,
        assegnata: 0,
        punteggio: 0,
      });
    },
    frecceDown(strutturaDiDati) {
      let freccia_eliminata = strutturaDiDati.pop();
      this.frecce_delete.push({
        id: freccia_eliminata.id_questionario_freccia,
      });
    },
    delFreccia(freccia) {
      this.frecce_delete.push({ id: freccia.id_questionario_freccia });
      let frecceTMP = this.nodo_sel_backup.frecce.filter(
        (elem) =>
          elem.id_questionario_freccia != freccia.id_questionario_freccia
      );
      this.nodo_sel_backup.frecce = frecceTMP;
    },
    //FINE MODIFICA FRECCE
    renderizzaImmagineDomanda() {
      // Pulisco via tutte le aree che eventualmente esistevano
      this.nodo_sel_backup.risposte.forEach((elem) => this.delRisposta(elem));
      d3.selectAll("#svgImmagine > rect").remove();
      d3.selectAll("#svgImmagine > text").remove();

      // Procedo a caricare l'immagine
      var dataBase64;
      var reader = new FileReader();
      reader.readAsDataURL(this.img_caricata);
      let that = this;
      reader.onload = function () {
        dataBase64 = reader.result;
        that.nodo_sel_backup["img_caricata"] = dataBase64;
        document.getElementById("chartImmagine").style.backgroundImage =
          "url(" + URL.createObjectURL(that.img_caricata) + ")";
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

      // Uso la variabile "fileCaricato" per definire lo sfondo del contenitore
    },
    renderizzaImmagineDomandaSalvata() {
      var base = this.nodo_sel_backup.img_caricata.split(",");
      var blob = Base64(base[1], "image/png");
      document.getElementById("chartImmagine").style.backgroundImage =
        "url(" + URL.createObjectURL(blob) + ")";
    },
    editTipo() {
      document.getElementById("testoDomanda").focus();
      /* Dopo avere spostato il focus svuoto anche le risposte,
       * per non tenere quelle eventualmente già inserite e che
       * non riguardano più il tipo di nodo attuale.*/
      this.nodo_sel_backup.risposte.forEach((elem) => this.delRisposta(elem));
      if (document.getElementById("chartImmagine")) {
        document.getElementById("chartImmagine").style.backgroundImage =
          "url('')";
      }

      /* Modifico "frecce" in base al
       * tipo di nodo selezionato*/
      let id_per_freccia = +new Date();
      this.nodo_sel_backup.frecce =
        this.nodo_sel_backup.id_tipo_domanda == 0
          ? [
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia,
                num_questionario_freccia: id_per_freccia,
                punteggio: 100,
                assegnata: 0,
              },
            ]
          : this.nodo_sel_backup.id_tipo_domanda == 1
          ? [
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia,
                num_questionario_freccia: id_per_freccia,
                punteggio: 100,
                assegnata: 0,
              },
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia + 5,
                num_questionario_freccia: id_per_freccia + 5,
                punteggio: 0,
                assegnata: 0,
              },
            ]
          : this.nodo_sel_backup.id_tipo_domanda == 2
          ? [
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia,
                num_questionario_freccia: id_per_freccia,
                punteggio: 100,
                assegnata: 0,
              },
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia + 5,
                num_questionario_freccia: id_per_freccia + 5,
                punteggio: 0,
                assegnata: 0,
              },
            ]
          : this.nodo_sel_backup.id_tipo_domanda == 3
          ? [
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia,
                num_questionario_freccia: id_per_freccia,
                punteggio: 100,
                assegnata: 0,
              },
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia + 5,
                num_questionario_freccia: id_per_freccia + 5,
                punteggio: 0,
                assegnata: 0,
              },
            ]
          : this.nodo_sel_backup.id_tipo_domanda == 4
          ? [
              {
                id_questionario: this.nodo_sel_backup.id_questionario,
                id_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                num_questionario_domanda: this.nodo_sel_backup
                  .id_questionario_domanda,
                id_questionario_freccia: id_per_freccia,
                num_questionario_freccia: id_per_freccia,
                punteggio: 100,
                assegnata: 0,
              },
            ]
          : [];
    },
    salvaConf(confDaSalvare) {
      if (confDaSalvare.tipologia == "domanda") {
        this.nodo_sel_backup.tipo = confDaSalvare.tipo;
        this.nodo_sel_backup.link = confDaSalvare.link;
        this.nodo_sel_backup.codice = confDaSalvare.codice;
        this.nodo_sel_backup.parametri = confDaSalvare.parametri;
        this.configurazione_quiz = false;
      }
      if (confDaSalvare.tipologia == "risposta") {
        this.nodo_sel_backup.risposte.forEach((risposta) => {
          if (
            risposta.id_questionario_risposta ==
            confDaSalvare.id_questionario_risposta
          ) {
            risposta.tipo = confDaSalvare.tipo;
            risposta.link = confDaSalvare.link;
            risposta.codice = confDaSalvare.codice;
            risposta.parametri = confDaSalvare.parametri;
            this.configurazione_quiz = false;
          }
        });
      }
    },
    async confermaModifiche() {
      // Controllo che non ci siano frecce con punteggi superiori a 100
      let freccia_oltre_100 = false;
      this.nodo_sel_backup.frecce.forEach((freccia) => {
        if (freccia.punteggio > 100) {
          freccia_oltre_100 = true;
        }
      });

      if (freccia_oltre_100 == true) {
        this.snackbar_text =
          "Punteggio massimo assegnabile a una freccia: 100.";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }

      // Se l'utente non ha scelto un tipo di domanda blocco tutto e lo avviso
      if (this.nodo_sel_backup.id_tipo_domanda == null) {
        this.snackbar_text = "È necessario scegliere un tipo di domanda.";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }

      // Se l'utente non ha scritto il testo di domanda blocco tutto e lo avviso
      if (
        this.nodo_sel_backup.des_questionario_domanda == null ||
        this.nodo_sel_backup.des_questionario_domanda == ""
      ) {
        this.snackbar_text = "È necessario formulare una domanda.";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }

      // Se l'utente non inserito risposte blocco tutto e lo avviso.
      if (
        this.nodo_sel_backup.id_tipo_domanda != 0 &&
        this.nodo_sel_backup.id_tipo_domanda != 4 &&
        this.nodo_sel_backup.risposte.length == 0
      ) {
        this.snackbar_text = "È necessario inserire delle risposte.";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      } else {
        /* Se la domanda deve avere e ha delle risposte, controllo
         * che nessuna di esse abbia un punteggio oltre 100. */
        let punteggio_max = 0;

        this.nodo_sel_backup.risposte.forEach((risposta) => {
          if (risposta.punteggio_risposta > 0) {
            punteggio_max =
              parseInt(punteggio_max) + parseInt(risposta.punteggio_risposta);
          }
        });

        if (punteggio_max > 100) {
          this.snackbar_text =
            "Il punteggio massimo conseguibile non deve superare i 100 punti.";
          this.snackbar_color = "error";
          this.snackbar = true;
          return;
        }
      }

      /* Se la domanda è testuale e non ha una (solo una) risposta testuale
       * conveniente per il backend in termini di testo e punteggio,
       * svuoto l'array delle domande (nel caso fosse già pieno)
       * e ne aggiungo una di comodo per convenienza di backend */
      if (this.nodo_sel_backup.id_tipo_domanda == 0) {
        this.nodo_sel_backup.risposte = [];
        this.nodo_sel_backup.aree = [];
        this.img_caricata = {};
        let id = +new Date();
        let rispostaDaAggiungere = {
          des_risposta: "Risposta testuale",
          punteggio_risposta: 100,
          id_questionario_risposta: id,
          num_questionario_risposta: id,
          id_questionario_domanda: this.nodo_sel_backup.id_questionario_domanda,
          id_questionario: this.nodo_sel_backup.id_questionario,
          num_questionario_domanda: this.nodo_sel_backup
            .num_questionario_domanda,
          num_risposta: this.assegnaNumRisposta(),
          tipologia: "risposta",
          tipo: null,
          link: "",
          codice: "",
          parametri: [],
        };

        this.nodo_sel_backup.risposte.push(rispostaDaAggiungere);
      } else if (this.nodo_sel_backup.id_tipo_domanda == 4) {
        this.nodo_sel_backup.risposte = [];
        this.nodo_sel_backup.aree = [];
        this.img_caricata = {};
        let id = +new Date();
        let rispostaDaAggiungere = {
          des_risposta: "Risposta grafica",
          punteggio_risposta: 100,
          id_questionario_risposta: id,
          num_questionario_risposta: id,
          id_questionario_domanda: this.nodo_sel_backup.id_questionario_domanda,
          id_questionario: this.nodo_sel_backup.id_questionario,
          num_questionario_domanda: this.nodo_sel_backup
            .num_questionario_domanda,
          num_risposta: this.assegnaNumRisposta(),
          tipologia: "risposta",
          tipo: null,
          link: "",
          codice: "",
          parametri: [],
        };

        this.nodo_sel_backup.risposte.push(rispostaDaAggiungere);
      }

      /* Se ho lo stesso numero di frecce ma ho modificato il punteggio di una freccia,
       * metto la freccia assegnabile*/
      /*if(this.nodo_sel_backup.frecce.length == this.nodo_sel.length){
        for(let i=0; i<this.nodo_sel_backup.frecce.length; i++){
          if(this.nodo_sel_backup.frecce[i].punteggio != this.nodo_sel.frecce[i].punteggio){
            this.nodo_sel_backup.frecce[i].assegnata = 0;
          }
        }
      }*/

      // Emetto l'evento per l'aggiornamento dei dati
      var risultato = {
        nodo: this.nodo_sel_backup,
        arrivo: this.arrivo,
        aree_delete: this.aree_delete,
        connections_delete: this.connections_delete,
        frecce_delete: this.frecce_delete,
        nodes_delete: this.nodes_delete,
        risposte_delete: this.risposte_delete,
      };
      this.$emit("salva", risultato);
      // Rendo il componente invisibile e ripulisco i campi
      this.aree = [];
      this.img_caricata = {};
      this.nodo_sel_backup = [];
      this.aree_delete = [];
      this.connections_delete = [];
      this.frecce_delete = [];
      this.nodes_delete = [];
      this.risposte_delete = [];
    },

    annulla() {
      this.$emit("annulla");
    },
  },
};
</script>
<style scoped>
</style>