<template >
  <v-container fluid>
    <v-row class>
      <v-col cols="12" md="4" lg="4">
        <v-card class="text-center colore_card_utente" dark>
          <v-btn
            class="mt-10"
            absolute
            dark
            fab
            top
            left
            color="red"
            v-show="utente_sel != null && arrivo == 'dash'"
            @click="indietro"
          >
            <v-icon>mdi-undo</v-icon>
          </v-btn>
          <v-avatar color class="elevation-4 mt-5">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-avatar>

          <v-card-text
            style="color: white !important"
            class="headline font-weight-bold mt-3"
          >
            {{ this.messaggio_ingresso }}
            <br />
            {{ this.nome_utente_sel }}
          </v-card-text>
          <div class="overline mt-5">
            Dati ultima rilevazione:({{ data_ultima_rilevazione }})
          </div>
          <v-card-title class="mt-0 pt-0">
            <v-icon class="mr-1" size="64" :color="colore_last_temperatura"
              >mdi-thermometer</v-icon
            >
            <v-row align="start">
              <div class="ml-2">
                <span class="display-2" v-text="last_temperatura || '—'"></span>
                <strong v-if="last_temperatura">°</strong>
              </div>
            </v-row>
            <v-icon class="mr-1" size="64" color="red">mdi-heart-pulse</v-icon>
            <v-row align="start">
              <div class="ml-2">
                <span class="display-2" v-text="last_bpm || '—'"></span>
                <var v-if="last_bpm">BPM</var>
              </div>
            </v-row>
            <!-- <v-icon class="mr-1" size="64">mdi-thermometer</v-icon>
          <v-row align="start">
            <div class="ml-2">
              <span class="display-2" v-text="avg || '—'"></span>
              <strong v-if="avg">°</strong>
            </div>
          </v-row>-->
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" lg="8">
        <v-card class="mx-auto" color>
          <v-card-title>
            <v-icon class="mr-1" size="64">mdi-thermometer</v-icon>
            <v-row align="start">
              <div class="caption grey--text text-uppercase">Temperatura</div>
              <div class="ml-2">
                <span
                  class="display-2 font-weight-black"
                  v-text="avg || '—'"
                ></span>
                <strong v-if="avg">°</strong>
              </div>
            </v-row>

            <v-spacer></v-spacer>
            <v-menu>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      class="align-self-start"
                      size="28"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleiona periodo</span>
                </v-tooltip>
              </template>
              <v-list shaped>
                <v-list-item-group v-model="periodo_sel" color="primary">
                  <v-list-item
                    v-for="(item, index) in periodo"
                    :key="index"
                    @click="console.log(item)"
                  >
                    <v-list-item-title active-class="highlighted">{{
                      item.periodo
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="align-self-start" size="28" v-on="on">
                <v-icon>mdi-arrow-right-thick</v-icon>
              </v-btn>
            </template>
            <span>Visualizza dati</span>
          </v-tooltip>-->
          </v-card-title>

          <v-sheet color="transparent">
            <apexchart
              height="300"
              type="line"
              :options="options_temperatura"
              :series="series_temperatura"
              ref="grafico_temperatura"
            ></apexchart>
          </v-sheet>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="4" lg="4">
      <v-card class="mx-auto" color>
        <v-card-title>
          <v-icon class="mr-6" size="64">mdi-heart-pulse</v-icon>
          <v-row align="start">
            <div class="caption grey--text text-uppercase">Battiti</div>
            <div class="ml-2">
              <span class="display-2 font-weight-black" v-text="avgbpm || '—'"></span>
              <strong v-if="avgbpm">BPM</strong>
            </div>
          </v-row>

          <v-spacer></v-spacer>

          <v-btn icon class="align-self-start" size="28">
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-sheet color="transparent">
          <apexchart height="300" type="line" :options="options_battiti" :series="series_battiti"></apexchart>
        </v-sheet>
      </v-card>
    </v-col>-->
      <v-col cols="12" md="4" lg="4">
        <!-- <AllegatoList
          :tipo="editedAllegato.tipo"
          :id_rif="utente_sel == null ? parseInt(id_utente) : parseInt(utente_sel)"
          :blnViewButtonUpload="id_profilo == 4 ? false : true"
          :famiglia="editedAllegato.famiglia"
          :strPath="this.strPath"
        ></AllegatoList> -->
      </v-col>
      <v-col cols="12" md="8" lg="8">
        <v-card class="mx-auto" color>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Rilevazioni</v-toolbar-title>

            <v-autocomplete
              v-model="values"
              :items="esiti"
              item-text="des_esito"
              flat
              hide-no-data
              hide-details
              chips
              multiple
              label="Filtra Esito"
              solo-inverted
              class="ml-5"
              :menu-props="{ closeOnContentClick: true }"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="cancella_autocomplete(data.item)"
                  >{{ data.item.des_esito }}</v-chip
                >
              </template>
            </v-autocomplete>

            <v-menu :close-on-content-click="false" v-model="menu_data_range">
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleiona periodo</span>
                </v-tooltip>
              </template>
              <v-date-picker v-model="dates" dark no-title range>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="align-self-start mt-0"
                      size="28"
                      v-on="on"
                      @click="reimpostaDate"
                    >
                      <v-icon>mdi-undo</v-icon>
                    </v-btn>
                  </template>
                  <span>Reimposta date</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="align-self-start mt-0"
                      size="28"
                      v-on="on"
                      @click="cercaDate"
                    >
                      <v-icon>mdi-arrow-right-thick</v-icon>
                    </v-btn>
                  </template>
                  <span>Cerca con queste date</span>
                </v-tooltip>
              </v-date-picker>
            </v-menu>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :footer-props="{
              disablePagination: true,
              disableItemsPerPage: true,
            }"
            :hide-default-footer="true"
            disable-pagination
          >
            <template v-slot:item.des_esito="{ item }">
              <v-chip :color="getAllerta(item.esito_temperatura)" dark>{{
                item.des_esito
              }}</v-chip>
            </template>
            <template v-slot:item.data_rilevazione="{ item }">{{
              item.data_rilevazione | formatDateTime
            }}</template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 8px;
}
.colore_card_utente {
  background: linear-gradient(
    118deg,
    rgba(92, 107, 192, 1),
    rgba(123, 136, 212, 0.7)
  ) !important;
}
</style>
<script>
// import AllegatoList from "../../views/MEDICAL/AllegatoList";
import apexchart from "vue-apexcharts";
import axios from "axios";
import config from "../../../public/config";
import moment from "moment";
export default {
  components: {
    apexchart,
    // AllegatoList,
  },
  props: ["utente_sel", "nome_utente_sel"],
  data: () => ({
    strPath: "root/cartella",
    editedAllegato: {
      tipo: "utente",
      id_rif: localStorage.id,
      famiglia: 2,
    },
    config: config,
    messaggio_ingresso: "Benvenuto",
    id_profilo: localStorage.id_profilo,
    id_utente: localStorage.id,
    dates: [],
    esiti: [],
    values: [],
    menu_data_range: false,
    periodo_sel: 0,
    periodo: [
      {
        giorni: 7,
        periodo: "7 Giorni",
      },
      {
        giorni: 14,
        periodo: "14 Giorni",
      },
      { giorni: 28, periodo: "28 Giorni" },
    ],
    arrivo: localStorage.arrivo,
    backup_item: [],
    options_battiti: {
      autoSelected: "zoom",
      chart: {
        id: "battiti",
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: false,
            zoomin: false,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      xaxis: {
        categories: [
          "20/04",
          "21/04",
          "22/04",
          "23/04",
          "24/0",
          "25/04",
          "26/04",
          "27/04",
          "28/04",
          "29/04",
        ],
      },
      stroke: {
        curve: "smooth",
      },
    },
    series_battiti: [
      {
        name: "Valori",
        data: [76, 75, 80, 73, 66, 110, 130, 70, 86, 90],
      },
    ],
    options_temperatura: {
      chart: {
        id: "battiti",
        toolbar: {
          show: false,
          position: "left",
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: true,
            zoom: false,
            zoomin: false,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      colors: ["#64B5F6", "#E53935"],
      xaxis: {
        categories: [],
      },
      stroke: {
        curve: "smooth",
      },
    },
    series_temperatura: [],
    avg: null,
    avgbpm: 71,
    last_bpm: null,
    data_ultima_rilevazione: null,
    last_temperatura: null,
    colore_last_temperatura: "success",
    headers: [],
    items: [],
    utente_sel_local: null,
  }),
  watch: {
    values(val) {
      //filtro la tabella
      if (this.backup_item.length > 0) this.items = this.backup_item;
      if (val.length > 0) {
        this.backup_item = this.items;
        this.items = this.items.filter(function (el) {
          return val.indexOf(el.des_esito) >= 0;
        });
      }
    },
    periodo_sel() {
      this.generaGrafici();
    },
  },
  mounted() {
    this.getDataOggi();
    this.getInfo();
    this.generaGrafici();
  },
  methods: {
    getAllerta(esito) {
      if (esito == 0) return "red";
      else if (esito == 2) return "orange";
      else return "green";
    },
    indietro() {
      this.$emit("indietro", "ok");
    },
    getDataOggi() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var ddweek = String(today.getDate() - 7).padStart(2, "0");
      this.dates = [];
      this.dates.push(yyyy + "-" + mm + "-" + ddweek);
      this.dates.push(yyyy + "-" + mm + "-" + dd);
    },
    generaGrafici() {
      // SET UP CATEGORIE
      var periodo_giorni = this.periodo[this.periodo_sel].giorni;
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var ddweek = String(
        new Date(
          today.getTime() - periodo_giorni * 24 * 60 * 60 * 1000
        ).getDate()
      ).padStart(2, "0");
      var mmweek = String(
        new Date(
          today.getTime() - periodo_giorni * 24 * 60 * 60 * 1000
        ).getMonth() + 1
      ).padStart(2, "0");
      var data_min = new Date(yyyy + "-" + mmweek + "-" + ddweek + " 00:00:00");
      var data_max = new Date(yyyy + "-" + mm + "-" + dd + " 24:00:00");
      if (localStorage.arrivo == "dash") {
        this.messaggio_ingresso = "Profilo di ";
      } else {
        this.utente_sel_local = localStorage.id;
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var finale = {
        action: "getriepilogoutenti",
        token: localStorage.user_token,
        id_utente: localStorage.id,
        id_personale: this.utente_sel,
        data: [data_min, data_max],
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Medical", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          var range = response.data.items.map((a) => a.data_rilevazione);
          var b = response.data.items.map((a) => a.valore_temperatura);
          var esiti = response.data.items.map((a) => a.esito_temperatura);
          var a = [];
          var temp_allerta = [];
          var temp_rilevata = [];
          var contatore = 0;
          var storage = 0;
          if (esiti[0] == 1) {
            this.colore_last_temperatura = "success";
          } else if (esiti[0] == 0) {
            this.colore_last_temperatura = "red";
          } else {
            this.colore_last_temperatura = "warning";
          }
          this.last_temperatura = b[0];
          this.data_ultima_rilevazione = moment(range[0]).format("DD/MM HH:mm");
          for (let i = range.length - 1; i >= 0; i--) {
            // pass the date at index i into moment
            var data_watch = new Date(range[i]);
            if (data_watch >= data_min && data_watch <= data_max) {
              let date = moment(range[i]).format("DD/MM HH:mm");
              temp_rilevata.push(b[i]);
              temp_allerta.push("37");
              contatore++;
              storage += parseFloat(b[i]);
              a.push(date);
            } else {
              let date = moment(range[i]).format("DD/MM HH:mm");
              temp_rilevata.push(0);
              temp_allerta.push("37");
              a.push(date);
            }
            this.avg = (storage / contatore).toFixed(1);
            // add this new date to the newDateArray
          }
          this.$refs.grafico_temperatura.updateOptions({
            xaxis: {
              categories: a,
            },
          });
          // SET UP SERIE
          try {
            this.series_temperatura = [
              {
                name: "Valori",
                data: temp_rilevata,
              },
              {
                name: "TEMPERATURE ALLERTA",
                color: "red",
                data: temp_allerta,
              },
            ];
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("id_user_sel");
            localStorage.removeItem("nominativo_sel");
            localStorage.removeItem("user_token");
            localStorage.removeItem("arrivo");
            this.$router.replace("login");
          }
          //controllare password ed utente
          console.log(e);
        });
    },
    getInfo() {
      this.values = [];
      if (localStorage.arrivo == "dash") {
        this.messaggio_ingresso = "Profilo di ";
      } else {
        this.utente_sel_local = localStorage.id;
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var finale = {
        action: "getriepilogoutenti",
        token: localStorage.user_token,
        id_utente: localStorage.id,
        id_personale: this.utente_sel,
        data: this.dates,
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Medical", data, config)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.headers = response.data.header;
          this.items = response.data.items;
          this.esiti = response.data.items;
          // genero grafici
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("id_user_sel");
            localStorage.removeItem("nominativo_sel");
            localStorage.removeItem("user_token");
            localStorage.removeItem("arrivo");
            this.$router.replace("login");
          }
          //controllare password ed utente
          console.log(e);
        });
    },
    cancella_autocomplete(item) {
      const index = this.values.indexOf(item.des_esito);
      if (index >= 0) this.values.splice(index, 1);
    },
    cercaDate() {
      this.menu_data_range = false;
      this.getInfo();
    },
    reimpostaDate() {
      this.getDataOggi();
      this.getInfo();
      this.menu_data_range = false;
    },
  },
};
</script>