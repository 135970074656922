<template>
  <v-dialog
    :scrollable="false"
    persistent
    v-model="dialogAggiungiModulo"
    width="800"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-tooltip v-if="Object.keys(item).length !== 0" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-on="on" v-bind="attrs">
              <v-icon color="#009999" class="ml0"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Aggiungere</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small :icon="icon_color == 'primary' ? false:true" slot="badge" v-on="on">
              <v-icon :color="icon_color == 'primary' ? '#1a80b6' : icon_color" style="color: #1a80b6" v-on="on" v-bind="attrs"
                >mdi-plus</v-icon
              >
            </v-btn>
          </template>
          <span>Aggiungi {{ nome }}</span>
        </v-tooltip>
      </div>
    </template>
    <div class="main-div ml-auto mr-auto">
      <v-card style="width: 89vw !important">
        <v-toolbar :color="bg_color" dense>
          <v-container class="py-0">
            <v-row>
              <v-col cols="4" class="py-1 d-flex justify-start align-center">
                <v-toolbar-title class="white--text" style="font-size: unset">
                  {{
                    single_item[0].id_tabella == -1
                      ? "Crea " + nome
                      : "Modifica " + nome
                  }}
                </v-toolbar-title>
              </v-col>
              <v-col cols="4" class="py-1 d-flex justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      :disabled="!valid"
                      @click="validate()"
                      :icon="icon_color=='primary' ? false:true"
                      small
                    >
                      <v-icon :color="icon_color=='primary' ? 'success' : icon_color" >mdi-floppy</v-icon>
                    </v-btn></template
                  >
                  <span>Salva</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" class="py-1 d-flex justify-end px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="px-0"
                      v-on="on"
                      v-bind="attrs"
                      :icon="icon_color=='primary' ? false:true"
                      @click="dialogAggiungiModulo = false"
                    >
                      <v-icon depressed :color="icon_color=='primary' ? '#c00000' : icon_color" 
                        >mdi-keyboard-backspace</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Chiudi</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
        <v-card-title>
          <v-container class="py-0 pt-2" fluid>
            <v-form ref="form" lazy-validation>
              <v-container class="py-0" fluid>
                <v-row class="text-start">
                  <v-col
                    v-for="(dato, index) in dati"
                    :key="index"
                    cols="12"
                    class="py-0"
                  >
                    <div v-for="(value, name) in dato" :key="name">
                      <v-text-field
                        v-if="!Array.isArray(value) && dato[name] == ''"
                        outlined
                        dense
                        :value="single_item[0][name]"
                        v-model="single_item[0][name]"
                        :rules="
                          name == 'des'
                            ? [
                                exist
                                  ? 'Descrizione già presente in tabella'
                                  : true,
                              ]
                            : name == 'des_lingua3'
                            ? [
                                exist2
                                  ? 'Codice già presente in tabella'
                                  : single_item[0][name] != null &&
                                    single_item[0][name].length != 8 &&
                                    single_item[0][name].length != 0
                                  ? 'Il codice deve avere 8 caratteri'
                                  : true,
                              ]
                            : []
                        "
                        type="text"
                        :label="
                          all_label.length > index
                            ? all_label[index]
                            : `${nome} ${name}`
                        "
                        required
                      ></v-text-field>
                      <v-autocomplete
                        v-else-if="Array.isArray(value)"
                        outlined
                        dense
                        v-model="single_item[0][value[0].vmodel]"
                        :items="itemsSelected"
                        :item-value="
                          risorsa_preferenziale ? 'id_personale' : 'id_tabella'
                        "
                        :item-text="
                          risorsa_preferenziale ? (item) => `${item.nome}   ${item.cognome}` : 'des'
                        "
                        placeholder="Digita per cercare"
                      >
                        <template #label>
                          <span class=""
                            >{{ value[0].nome }}<strong>* </strong></span
                          >
                          <a @click.stop style="pointer-events: all">
                            <Modules
                              v-if="!risorsa_preferenziale"
                              v-on:dialogModules="emitDialog"
                              :assistenza="assistenza"
                              :tipo="value[0].tipo"
                              :nome="value[0].nome"
                              :setaction="value[0].setaction"
                              :getaction="value[0].getaction"
                              :controller="value[0].controller"
                              :dati="value[0].dati"
                              :filter="value[0].filter"
                            />
                          </a>
                        </template>
                      </v-autocomplete>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
        </v-card-title>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
function updateList(that) {
  for (var dato in that.dati) {
    for (var datoVal in that.dati[dato]) {
      if (Array.isArray(that.dati[dato][datoVal])) {
        that.getitemsSelected(that.dati[dato][datoVal][0]);
      } else {
        that.single_item[0][datoVal] = that.dati[dato][datoVal];
      }
    }
  }

  var rich = {};
  if (that.filter != undefined) {
    rich = {
      action: that.getaction,
      token: localStorage.user_token,
      tipo: that.tipo,
      filter: that.filter,
      assistenza: that.assistenza,
    };
  } else {
    rich = {
      action: that.getaction,
      token: localStorage.user_token,
      tipo: that.tipo,
      assistenza: that.assistenza,
    };
  }

  var request2 = {
    controller: that.controller,
    method: "POST",
    richiesta: rich,
  };

  that.$store.dispatch("api", request2).then((res) => {
    if (that.getaction == "gettabella") {
      that.all = res.data;
    } else {
      if (res.data != "") {
        that.all = res.data;
      }
    }

    let thet = that;
    if (Object.keys(that.item).length !== 0) {
      that.single_item = that.all.filter(function (elem) {
        return (
          JSON.stringify(thet.item.id_tabella) ==
          JSON.stringify(elem.id_tabella)
        );
      });
      that.id = that.all.indexOf(that.single_item[0]);
    }
  });
}

export default {
  name: "AddModule",

  data: () => ({
    single_item: [
      {
        id_tabella: -1,
        tipo: "",
        des: "",
        cod: "",
        val_int: "",
        val_txt: "",
        val_dbl: null,
        id_azienda: null,
        des_lingua3: "",
        validita: 1,
      },
    ],
    dialogAggiungiModulo: false,
    all: [],
    id: -1,
    itemsSelected: [],
  }),
  props: {
    assistenza: {
      type: Number,
      default: 0,
    },
    item: Object,
    tipo: String,
    nome: String,
    getaction: String,
    setaction: String,
    controller: String,
    filter: String,
    id_azienda: String,
    dati: Array,
    risorsa_preferenziale: {
      type: Boolean,
      default: false,
    },
    controllaCodice: {
      type: Boolean,
      default: false,
    },
    all_label: {
      type: Array,
      default: () => [],
    },
     bg_color:{
      type:String,
      default:"indigo"
    },
    icon_color:{
      type:String,
      default:"primary"
    }
  },
  created() {},
  watch: {
    dialogAggiungiModulo: function (newI, oldI) {
      if (oldI != newI) {
        for (var dato in this.dati) {
          for (var datoVal in this.dati[dato]) {
            if (Array.isArray(this.dati[dato][datoVal])) {
              this.getitemsSelected(this.dati[dato][datoVal][0]);
            } else {
              this.single_item[0][datoVal] = this.dati[dato][datoVal];
            }
          }
        }

        if (newI != false) {
          updateList(this);
        } else {
          this.$emit("dialogModule", true);
          this.single_item = [
            {
              id_tabella: -1,
              tipo: "",
              des: "",
              cod: "",
              val_int: "",
              val_txt: "",
              val_dbl: null,
              id_azienda: null,
              des_lingua3: "",
              validita: 1,
            },
          ];
        }
      }
    },
  },
  components: {
    //leave the import with a lambda function for the recursive call of Modules -> AddModule -> Modules etc..
    Modules: () => import("./Modules.vue"),
  },
  computed: {
    valid() {
      return this.single_item[0].des != "" && !this.exist
        ? this.controllaCodice &&
          this.single_item[0].des_lingua3 != null &&
          this.single_item[0].des_lingua3 != ""
          ? !this.exist2 && this.single_item[0].des_lingua3.length == 8
          : true
        : false;
    },
    exist() {
      for (var i = 0; i < this.all.length; i++) {
        if (
          this.all[i].des == this.single_item[0].des &&
          this.all[i].id_tabella != this.single_item[0].id_tabella
        )
          return true;
      }
      return false;
    },
    exist2() {
      if (
        this.single_item[0].des_lingua3 != null &&
        this.single_item[0].des_lingua3 != ""
      ) {
        for (var i = 0; i < this.all.length; i++) {
          if (
            this.all[i].des_lingua3 == this.single_item[0].des_lingua3 &&
            this.all[i].id_tabella != this.single_item[0].id_tabella
          )
            return true;
        }
      }
      return false;
    },
    int_val_dbl: {
      get: function () {
        return parseInt(this.single_item[0].int_val_dbl);
      },
      set: function (newValue) {
        this.single_item.id_modulo = newValue;
        return parseInt(this.single_item.id_modulo);
      },
    },
  },
  methods: {
    getitemsSelected(dataSelect) {
      var request2 = {
        controller: dataSelect.controller,
        method: "POST",
        richiesta: {
          action: dataSelect.getaction,
          token: localStorage.user_token,
          tipo: dataSelect.tipo,
          filter: dataSelect.filter,
          assistenza: this.assistenza,
        },
      };
      this.$store.dispatch("api", request2).then((res) => {
        if (res.data != "") {
          if (this.risorsa_preferenziale) {
            this.itemsSelected = res.data.result.gp_personale_utenti;
          } else {
            this.itemsSelected = res.data;
          }
        }
      });
    },
    validate() {
      if (this.id == -1) {
        this.all = this.single_item;
      } else {
        this.all[this.id] = this.single_item[0];
      }
      this.single_item[0].tipo = this.tipo;

      let rich = {
        action: this.setaction,
        token: localStorage.user_token,
        id_utente: localStorage.id,
        tabella: this.single_item,
        assistenza: this.assistenza,
      };
      if (this.setaction == "setambitobyazienda") {
        rich = {
          action: this.setaction,
          token: localStorage.user_token,
          tabella: this.single_item,
          id_azienda: this.id_azienda,
        };
      }
      var request = {
        controller: this.controller,
        method: "PUT",
        richiesta: rich,
      };
      // console.log(JSON.stringify(request.richiesta))
      this.$store.dispatch("api", request).then((res) => {
        this.dialogAggiungiModulo = false;
        this.$emit("dialogModule", true);
        console.log("res dialog " + this.nome, res);
      });
    },
    emitDialog() {
      updateList(this);
    },
  },
};
</script>