<template>
    <div>
        <v-card>
            <!-- TOOLBAR -->
            <v-toolbar dense dark color="var(--gantt_accent)">
                <v-row align="center">
                    <!-- TITOLO -->
                    <v-col cols="5" align="left">
                        <v-toolbar-title>Progetti</v-toolbar-title>
                    </v-col>
                    <!-- NUOVO -->
                    <v-col cols="2">
                        <v-btn small light @click="createProject">
                            <v-icon color="success">mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <!-- AGGIORNA -->
                    <v-col cols="4" align="right">
                        <v-btn small light @click="getProjects">
                            <v-icon color="blue" class="gantt_sync" :style="'animation-play-state:'+(syncing?'running':'paused')">mdi-cached</v-icon>
                        </v-btn>
                    </v-col>
                    <!-- INDIETRO -->
                    <v-col cols="1" align="right">                        
                        <v-btn small light @click="$router.push('/home')">
                            <v-icon color="error">mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>

            <v-container>
                <!-- FILTRI -->
                <v-row align="center" justify="center">
                    <v-col cols="5">
                        <v-text-field color="var(--gantt_accent)" label="Filtra per nome progetto" prepend-icon="mdi-filter" v-model="filter" />
                    </v-col>
                </v-row>

                <!-- TABELLA PROGETTI -->
                <v-data-table hide-default-footer disable-pagination disable-sort :items="filterProjects(filter)" :headers="headers" no-data-text="Nessun progetto disponibile">
                    
                    <!-- BOTTONI APRI/MODIFICA -->
                    <template v-slot:[`item.actions`]="{ item }">                
                        <!-- BOTTONE APRI -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" :disabled="syncing" v-on="on" icon @click="$emit('project',item.id_progetto)">
                                    <v-icon>mdi-file-eye-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Apri {{item.titolo}}</span>
                        </v-tooltip>

                        <!-- BOTTONE MODIFICA -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" :disabled="syncing" v-on="on" icon @click="editProject(item)">
                                    <v-icon>mdi-file-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Modifica {{item.titolo}}</span>
                        </v-tooltip>
                    </template>

                    <!-- BOTTONE ELIMINA -->
                    <template v-slot:[`item.remove`]="{ item }">                
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" :disabled="syncing" v-on="on" icon style="float:right" @click="deleteProjectPrompt(item)">
                                    <v-icon color="error">mdi-file-remove-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Elimina {{item.titolo}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
        
        <!-- MODIFICA ATTRIBUTI PROGETTO -->
        <v-dialog v-model="dialog.show" persistent width="800" >
            <v-card class="gantt_container" >
                <!-- TOOLBAR -->
                <v-toolbar color="var(--gantt_accent)" dark dense>
                   <v-row align="center">
                       <v-col cols="5" align="left">
                           <v-toolbar-title>Modifica</v-toolbar-title>
                       </v-col>
                       <v-col cols="2">
                           <v-btn small light @click="setProject">
                               <v-icon color="success">mdi-floppy</v-icon>
                           </v-btn>
                       </v-col>
                       <v-col cols="5" align="right">
                           <v-btn small light @click="dialog.show = false">
                               <v-icon color="error">mdi-close</v-icon>
                           </v-btn>
                       </v-col>
                   </v-row> 
                </v-toolbar>
                <!-- BODY -->
                <v-container>
                    <v-row justify="center">
                        <v-col cols="8">
                            <v-text-field v-model="dialog.template.titolo" color="var(--gantt_accent)" label="Titolo Progetto" />
                        </v-col>                        
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="8">
                            <v-textarea v-model="dialog.template.descrizione" color="var(--gantt_accent)" class="gantt_scrollbar" outlined no-resize rows="4" dense label="Descrizione" />
                        </v-col>                        
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- CONFERMA ELIMINAZIONE PROGETTO -->
        <v-dialog v-model="del.show" width="600" persistent>
            <v-card class="gantt_container" v-if="del.project != null">
                <v-container>
                    <v-card-title>Eliminare il progetto "{{del.project.titolo}}"?</v-card-title>
                </v-container>
                <v-card-actions>
                    <v-btn text color="error" @click="del.show=false">Annulla</v-btn>
                    <v-spacer />
                    <v-btn text color="var(--gantt_accent)" @click="deleteProject">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name : 'GanttOverview',
    data(){
        return {
            del : {
                project : null,
                show : false
            },
            dialog : {
                show: false,
                template : new Object()
            }, 
            filter: null,           
            headers : [
                {text:null, value:'actions'},
                {text:'Progetto', value:'titolo'},
                {text:'Creazione', value:'data_creazione'},
                {text:'Ultima Modifica', value:'data_modifica'},
                {text:null, value:'remove'},
            ],
            NETWORK : null,
            projects : new Array(),
            syncing : false
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.getProjects();
    },
    methods : {
        createProject(){            
            this.dialog.template = new Object();
            this.dialog.show = true;
        },
        deleteProject(){
            this.del.project.validita = 0;
            this.dialog.template = this.del.project;            
            this.setProject();
        },
        deleteProjectPrompt(item){
            this.del.project = item;
            this.del.show = true;
        },
        editProject(item){
            this.dialog.template = Object.assign({},item);
            this.dialog.show = true; 
        },
        filterProjects(filter){
            if(filter == null){ return this.projects; }
            const result = new Array();
            const length = this.projects.length;
            for(let i=0; i < length; i++){
                const project = this.projects[i];
                if(project.titolo.toLowerCase().indexOf(filter.toLowerCase()) >= 0){
                    result.push(project);
                }
            }
            return result;
        },
        getProjects(){
            const that = this;
            const request = {
                controller : 'Gantt',
                showloading : false,
                body : {
                    action: "getProjects",
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : function(response){
                        that.projects = response.progetti;
                        that.syncing = false;
                    },
                    error : function(){
                        that.syncing = false;
                    }
                }
            }
            this.syncing = true;
            this.NETWORK.AjaxRequest(request);
        },
        setProject(){
            const that = this;
            const request = {
                controller : 'Gantt',
                body : {
                    action: "setProject",
                    token: localStorage.user_token,
                    project : [that.dialog.template]
                },
                method: 'PUT',
                on : {
                    load : function(){
                        that.dialog.show = false;
                        that.del.show = false;
                        that.getProjects();
                    }
                }
            }
            that.NETWORK.AjaxRequest(request);
        }
    }
}
</script>
<style>
@keyframes sync_anim{
    from {transform: rotate(360deg);}
    to {transform: rotate(0deg);}
}
.gantt_sync{
    animation-name: sync_anim;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: linear;
}
</style>