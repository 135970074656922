<template>
  <div style="width:100%;height:100%;">
      <Plan></Plan>
  </div>
</template>
<script>

import Plan from "@/components/TOOLS/Plan.vue";
export default {
     components: {
    Plan,
  },
  data() {
      
    return {
        
        
    };
  },
};
</script>