<template>
  <v-container fluid class="py-0">
    <!-- Loader -->
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Deletion confirmation dialog -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogElimina"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Questo elemento verrà eliminato definitivamente</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="green" dark @click="deleteFolder">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Articolo Lista -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogArticolo"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Articolo Dettaglio</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="
                  closedetailModal();
                  dialogArticolo = false;
                "
                small
              >
                <v-icon color="red" depressed class="">mdi-window-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text
          style="max-height: 600px; overflow-y: auto; overflow-x: hidden"
          class="mt-6"
        >
          <Articolo
            :articoloId="this.recursiveId"
            :key="this.recursiveId"
            :name="this.recursiveId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Distinta Base Update dialog -->
    <v-dialog content-class="artDialog" persistent v-model="dialog" width="700">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Distinta Base</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            bottone_class="saveIcon"
            colore_icona="success"
            idForm="ARTICOLO_DISTINTA_BASE"
            idOggetto="SALVA_ARTICOLO_DISTINTABASE"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva distinta"
            v-on:buttonClick="saveDistinta"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" small @click="hideModal()">
                <v-icon color="red">mdi-window-close</v-icon>
              </v-btn>
            </template>
            <span>Cancallare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-subtitle>
          <v-form v-on:submit.prevent="searchDistintaBase">
            <v-container>
              <v-row>
                <v-col class="d-flex" cols="12" md="3">
                  <v-text-field
                    :disabled="this.disablesearch === true"
                    type="text"
                    v-model="cod_articolo"
                    class="shrink"
                    label="Ricerca Componente"
                    placeholder="Ricerca Componente"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-5" @click="searchDistintaBase" small>
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          color="#1a80b6"
                          size="32"
                          >mdi-magnify</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-subtitle>
        <v-card-text class="mt-n16" style="max-height: 450px; overflow-y: auto">
          <v-container fluid>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :loading="LoadingVal"
                  :headers="headers"
                  :items="items"
                  class="elevation-1"
                  :dense="true"
                >
                  <v-progress-linear
                    v-show="progressBar"
                    slot="progress"
                    color="blue"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:[`item.quantita`]="props">
                    <v-text-field
                      solo
                      flat
                      min="0"
                      dense
                      v-model="props.item.quantita"
                      type="number"
                      name="quantita"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--   Distinta Base create dialog -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogDistintaBase"
      width="700"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Distinta Base</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            bottone_class="saveIcon"
            colore_icona="success"
            idForm="ARTICOLO_DISTINTA_BASE"
            idOggetto="SALVA_ARTICOLO_DISTINTABASE"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva distinta"
            v-on:buttonClick="updateDistinta"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="closedialogDistintaBase" small>
                <v-icon class="" v-on="on" v-bind="attrs" color="red"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancallare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-container fluid>
            <v-form
              v-on:submit.prevent="searchDistintaBase"
              class="mt-n9 ml-n1"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-text-field
                      :disabled="this.disablesearch === true"
                      type="text"
                      v-model="cod_articolo"
                      label="Ricerca Componente"
                      placeholder="Ricerca Componente"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class md="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mt-5" @click="searchDistintaBase" small>
                          <v-icon
                            v-on="on"
                            v-bind="attrs"
                            color="#1a80b6"
                            size="32"
                            >mdi-magnify</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Cerca </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider></v-divider>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :loading="LoadingVal"
                  :headers="headers"
                  :items="items"
                  class="elevation-1"
                  :dense="true"
                >
                  <v-progress-linear
                    v-show="progressBar"
                    slot="progress"
                    color="blue"
                    indeterminate
                  ></v-progress-linear>
                  <template v-slot:[`item.quantita`]="props">
                    <v-text-field
                      solo
                      flat
                      min="0"
                      dense
                      v-model="props.item.quantita"
                      type="number"
                      name="quantita"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Deletion distinta base dialog -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogEliminaDistintaBase"
      width="400"
    >
      <v-card>
        <v-toolbar dense class="headline red lighten-2 ml-n2" primary-title>
          <v-toolbar-title class="white--text"
            >elimina la distinta base</v-toolbar-title
          >
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col md="1">
              <v-icon color="red">mdi-exclamation-thick</v-icon>
            </v-col>
            <v-col>
              <p>
                Attenzione. Confermi la rimozione del componenti: N':
                {{ this.deletingQuantity }}-{{ this.deletingItemDetail }}
              </p>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col md="5"></v-col>
            <v-col md="3">
              <v-btn
                dark
                tile
                class="mx-auto"
                @click="
                  deleteItem();
                  dialogEliminaDistintaBase = false;
                "
                color="green"
                >Invia</v-btn
              >
            </v-col>
            <v-col md="2">
              <v-btn
                dark
                tile
                class="mr-5"
                color="red"
                @click="dialogEliminaDistintaBase = false"
                >Anulla</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Distinta Base TreeView -->
    <v-row>
      <v-col>
        <v-hover>
          <template v-slot="{ hover }">
            <v-card :elevation="hover ? 24 : 2">
              <v-snackbar
                content-class="snackbar"
                :color="color"
                right
                v-model="snackbar"
                :timeout="3000"
              >
                <v-icon :size="64">{{ mainIcon }}</v-icon>
                <h2 class="ml-16">{{ snackbar_text }}</h2>
                <v-btn
                  @click="snackbar = false"
                  fab
                  dark
                  text
                  small
                  style="
                    margin-top: -6rem;
                    float: right !important;
                    margin-right: -1rem !important;
                  "
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-snackbar>
              <v-toolbar primary-title dense class="indigo darken-1 rounded-t">
                <v-toolbar-title class="white--text"
                  >Distinta Base</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!on"
                  @click="showIcons"
                  class="ml-16"
                  color="white"
                  dark
                  fab
                  top
                  tile
                  small
                >
                  <v-icon color="primary">mdi-arrow-collapse</v-icon>
                </v-btn>
                <v-btn
                  v-if="on"
                  dark
                  fab
                  top
                  tile
                  @click="hideIcons"
                  class="ml-16"
                  color="white"
                  small
                >
                  <v-icon color="primary">mdi-arrow-expand</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text
                class="text-left"
                style="overflow: auto; max-height: 600px"
              >
                <v-progress-circular
                  class=""
                  v-show="progressBar1"
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-circular>
                <v-treeview
                  activatable
                  shaped
                  hoverable
                  :open.sync="open"
                  :items="distintaBaseList"
                  item-key="id"
                  content="6"
                  open-on-click
                >
                  <template v-slot:prepend="{ item, open }">
                    <b class="mr-2">{{ item.indice }}:</b
                    ><v-icon v-if="item.id == 0">{{
                      open ? "mdi-buffer" : "mdi-buffer"
                    }}</v-icon>
                    <v-icon v-if="item.id > 0">{{
                      open ? "mdi-text-short" : "mdi-text-short"
                    }}</v-icon>
                    <template v-if="item.id >= -1"
                      >N° {{ item.quantita }} {{ item.cod_articolo }}
                    </template>
                  </template>
                  <template
                    v-if="hideicons === true"
                    slot="append"
                    slot-scope="{ item }"
                  >
                    <span v-show="item.id_rif == -1">
                      <Button
                        colore_icona="primary"
                        idForm="ARTICOLO_DISTINTA_BASE"
                        idOggetto="CREA_ARTICOLO_DISTINTABASE"
                        mdi_icona="mdi-plus"
                        testo_tooltip="Inserisci Componente"
                        v-on:buttonClick="showModal"
                      />
                    </span>
                    <span v-show="item.id_rif == 0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="getDetail(item)" v-on="on" small>
                            <v-icon color="blue">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Dettaglio Componente</span>
                      </v-tooltip>
                    </span>
                    <span v-show="item.id_rif == 0">
                      <Button
                        classe_bottone="ml-1"
                        colore_icona="primary"
                        idForm="ARTICOLO_DISTINTA_BASE"
                        idOggetto="MODIFICA_ARTICOLO_DISTINTABASE"
                        mdi_icona="mdi-pencil"
                        testo_tooltip="Modifica Componente"
                        v-on:buttonClick="updateItem(item)"
                      />
                    </span>
                    
                    <span v-show="item.id_rif == 0">
                      <Button
                        classe_bottone="ml-1"
                        colore_icona="error"
                        idForm="ARTICOLO_DISTINTA_BASE"
                        idOggetto="ELIMINA_ARTICOLO_DISTINTABASE"
                        mdi_icona="mdi-window-close"
                        testo_tooltip="Cancella Componente"
                        v-on:buttonClick="selectDistinta(item)"
                      />
                    </span>
                  </template>
                </v-treeview>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
export default {
  name: "distintaBase",
  props: ["pdata"],
  components: {
    Button,
    Articolo: () => import("../Articolo"),
  },
  data() {
    return {
      // Search Articolo Distina base header
      headers: [
        {
          text: "Q.ta Distinta",
          value: "quantita",
        },
        {
          text: "Codice Componente",
          value: "cod_articolo",
        },
        {
          text: "Tipo Articolo",
          value: "id_tipo",
        },
        {
          text: "Descrizione",
          value: "des_articolo",
        },
      ],
      // overlay variable
      overlay: false,
      // delete confirmation dialog
      dialogElimina: false,
      selectedDistinta: {},
      item: {},
      ids: [],
      dialogDistintaBase: false,
      LoadingVal: false,
      progressBar: true,
      progressBar1: true,
      recursiveId: null,
      dialogArticolo: false,
      openAll: false,
      on: true,
      deletingQuantity: 0,
      deletingItemDetail: "",
      dialogEliminaDistintaBase: false,
      disablesearch: false,
      open: [0],
      hideicons: true,
      quantita: 0,
      items: [],
      cod_articolo: "",
      distintaBaseList: [], //array cartelle
      dialog: false,
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_text: "",
      id_articolo: this.pdata.id_articolo,
    };
  },
  mounted() {
    this.GetDistintaBase();
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    // Get Distinta Base
    GetDistintaBase() {
      // In order to get distinta base of the Articolo to populate treeview
      let getdistintabase = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolidistintabase",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo,
        },
      };
      this.$store.dispatch("api", getdistintabase).then((res) => {
        this.distintaBaseList = res.data;
        if (this.distintaBaseList.length > 0) {
          this.progressBar1 = false;
        }
        if (this.distintaBaseList.length === 0) {
          this.distintaBaseList = [];
        }
        this.distintaBaseList.map((el) => {
          if (el.children.length > 0) {
            el.children.map((child) => {
              this.ids.push(child.id);
            });
          }
          this.ids.push(el.id);
        });
        /* Assegno un indice a tutti gli elementi di "distintaBaseList"
         * e ai loro figli per stampare il numero di riga nella treeview. */
        this.setIndiceRicorsivo(this.distintaBaseList);
      });
    },
    //Function to select distinta to delete before delete
    selectDistinta(item) {
      this.selectedDistinta = item;
      this.dialogElimina = true;
    },
    // in order to deselect selected item
    delSelectedItem() {
      this.selectedDistinta = {};
      this.dialogElimina = false;
    },
    // clsoing modal
    closedialogDistintaBase() {
      this.dialogDistintaBase = false;
      this.item = null;
      this.items = [];
      this.GetDistintaBase();
    },
    // In order to update a single component
    updateDistinta() {
      this.overlay = true;
      this.distintaBaseList[0].children.map((it) => {
        it.validita = 1;
        it.children = [];
      });
      this.dialogDistintaBase = false;
      let setdistintabase1 = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.id_articolo,
          token: localStorage.getItem("user_token"),
          distinta_base: this.distintaBaseList,
        },
      };
      this.$store.dispatch("api", setdistintabase1).then(() => {
        this.snackbar = true;
        this.overlay = false;
        this.snackbar_text = "Modifica effettuata con successo!";
        this.color = "green darken-3";
        this.mainIcon = "mdi-check-circle";
        this.item = null;
        this.items = [];
      });
      this.dialogDistintaBase = false;
    },
    // opening recursive modal for element detail
    getDetail(item) {
      this.dialogArticolo = true;
      this.recursiveId = item.id;
    },
    //close detaol modal
    closedetailModal() {
      this.dialogArticolo = false;
    },
    // removing single element
    removefromDistinta(i) {
      this.distintaBaseList[0].children.splice(i, 1);
    },
    // In order to delete one componente of distinta base
    deleteFolder() {
      this.overlay = true;
      let data = this.selectedDistinta;
      data.validita = 0;
      let i = this.distintaBaseList[0].children.findIndex((it) => {
        return it.id_distinta_base === data.id_distinta_base;
      });
      this.distintaBaseList[0].children.splice(i, 1, data);
      this.distintaBaseList[0].children.map((it) => (it.children = []));
      let deldistintabase = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.id_articolo,
          token: localStorage.getItem("user_token"),
          distinta_base: this.distintaBaseList,
        },
      };
      this.$store.dispatch("api", deldistintabase).then((res) => {
        this.overlay = false;
        if (res.data) {
          this.snackbar = true;
          this.snackbar_text = "Modifica effettuata con successo!";
          this.color = "green darken-3";
          this.mainIcon = "mdi-check-circle";
          this.removefromDistinta(i);
        }
      });
      this.dialogElimina = false;
    },
    // to taggle open all icon
    hideIcons() {
      this.hideicons = false;
      this.on = false;
      this.openAll = true;
      this.open = this.ids;
    },
    // to togggle close all icon
    showIcons() {
      this.hideicons = true;
      this.openAll = false;
      this.open = [];
      this.on = true;
    },
    // in order to disable the search field when in update mode
    updateItem(val) {
      this.dialogDistintaBase = true;
      this.disablesearch = true;
      this.item = val;
      val.cod_articolo = val.id;
      val.des_articolo = val.name;
      this.items.push(val);
    },
    // search articolo to add as component of distinta
    searchDistintaBase(evt) {
      this.LoadingVal = true;
      this.progressBar = true;
      this.overlay = true;
      evt.preventDefault();
      let getdistintabase = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          cod_articolo: this.cod_articolo,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", getdistintabase).then((res) => {
        this.items = res.data.lista;
        this.overlay = false;
        if (this.items.length === 0) {
          this.snackbar = true;
          this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
          this.mainIcon = "mdi-close-circle";
          this.color = "red accent-4";
          this.progressBar = false;
          this.LoadingVal = false;
        }
        // In order to remove the main articolo from search it is not possible to add parent component to add as a children
        if (this.items.length > 0) {
          this.progressBar = false;
          this.LoadingVal = false;
          let i = this.items.findIndex(
            (it) => it.cod_articolo == this.pdata.cod_articolo
          );
          if (i > -1) {
            this.items.splice(i, 1);
          }
        }
        this.items.map((el) => (el.quantita = 0));
      });
    },
    // search articolo modal
    showModal() {
      this.dialog = true;
      this.disablesearch = false;
      this.items = [];
    },
    // in order to get exact distinta before save
    setDistinta(val) {
      this.overlay = true;
      const newDistinta = val;
      newDistinta[0].children.map((el) => (el.children = []));
      let setdistintabase = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolidistintabase",
          id_articolo: this.id_articolo,
          token: localStorage.getItem("user_token"),
          distinta_base: newDistinta,
        },
      };
      this.$store.dispatch("api", setdistintabase).then((res) => {
        this.overlay = false;
        this.distintaBaseList = res.data;
        this.setIndiceRicorsivo(this.distintaBaseList);
        this.snackbar = true;
        this.snackbar_text = "Modifica effettuata con successo!";
        this.color = "green darken-3";
        this.mainIcon = "mdi-check-circle";
      });
    },
    // to add new components to distinta base
    saveDistinta() {
      let filteredObject = [];
      this.overlay = true;
      this.items.map((el) => {
        if (el.quantita > 0) {
          // Forzo un numero in virgola mobile
          el.quantita = parseFloat(el.quantita);
          filteredObject.push(el);
        }
      });
      var ctr = 0;
      filteredObject.map((el) => {
        let getdistintabase = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getarticolidistintabase",
            token: localStorage.getItem("user_token"),
            id_articolo: el.id_articolo,
          },
        };
        this.$store.dispatch("api", getdistintabase).then((res) => {
          this.overlay = false;
          let getresult = res.data;
          let element = {};
          element.id_distinta_base = -1;
          element.id_articolo = this.id_articolo;
          element.id_componente = el.id_articolo;
          element.id = parseInt(getresult[0].id);
          element.name = getresult[0].name;
          element.quantita = el.quantita;
          element.validita = 1;
          element.id_rif = 0;
          element.children = [];
          ctr++;
          this.distintaBaseList[0].children.push(element);
          this.distintaBaseList[0].validita = 1;
          if (filteredObject.length === ctr) {
            this.setDistinta(this.distintaBaseList);
          }
        });
      });
      this.dialog = false;
      this.items = [];
      this.item = null;
      this.cod_articolo = "";
    },
    hideModal() {
      this.items = [];
      this.dialog = false;
      this.disablesearch = false;
      this.cod_articolo = "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // In order to add numbering to the distinta base
    setIndiceRicorsivo(array) {
      array.forEach((elem) => {
        elem.indice = array.indexOf(elem) + 1;
        if (elem.children && elem.children.length > 0) {
          this.setIndiceRicorsivo(elem.children);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.artDialog {
  overflow-x: hidden;
}
</style>