var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-overlay',{staticStyle:{"z-index":"10"},attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 24 : 2}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Ciclo Standard "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-cog-outline")])],1),_c('v-text-field',{staticClass:"ml-2",staticStyle:{"background-color":"white !important"},attrs:{"label":"Cerca","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{attrs:{"slot":"append","color":"primary"},slot:"append"},[_vm._v(" mdi-magnify ")])],1),_c('v-spacer'),_c('Button',{attrs:{"colore_icona":"primary","idForm":"IMPOSTAZIONI","idOggetto":"CREA_CICLO_STANDARD","mdi_icona":"mdi-plus","testo_tooltip":"Nuova Ciclo Standard"},on:{"buttonClick":_vm.newCicloStd}}),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}],null,true)},[_c('span',[_vm._v("Vai alla Dashboard")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"fixed-header":"","height":"55vh","search":_vm.search},scopedSlots:_vm._u([(_vm.cambia_stato_sel === true)?{key:"item.edit_stato",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.edit_stato),callback:function ($$v) {_vm.$set(item, "edit_stato", $$v)},expression:"item.edit_stato"}})]}}:null,{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"classe_bottone":"ml-2","colore_icona":"primary","idForm":"IMPOSTAZIONI","idOggetto":"MODIFICA_CICLO_STANDARD","mdi_icona":"mdi-pencil","testo_tooltip":"Dettaglio CicloStd"},on:{"buttonClick":function($event){return _vm.goToCicloStd(item)}}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"classe_bottone":"ml-2","colore_icona":"error","idForm":"IMPOSTAZIONI","idOggetto":"ELIMINA_CICLO_STANDARD","mdi_icona":"mdi-window-close","testo_tooltip":"Dettaglio CicloStd"},on:{"buttonClick":function($event){return _vm.deleteCiclo(item)}}})]}}],null,true)})],1)],1)]}}])})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar_background,"timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{style:({
        fontSize: _vm.snackbar_font_size,
        lineHeight: _vm.snackbar_font_size,
      })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }