<template>
  <svg
    class="ma-0 pa-0"
    :style="{
      backgroundColor: 'white',
      border: '1px solid black',
      display: 'none',
      height: '0px',
      position: 'relative',
      width: '0px',
    }"
    id="svg2"
  >
    <!-- Template della porta destra -->
    <g
      class="porta_destra_template"
      style="display: none"
      transform-origin="100 50"
      transform="translate(100, 100)"
    >
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="svg10"
        xml:space="preserve"
        width="100"
        height="100"
        viewBox="0 0 379.28134 379.28134"
      >
        <metadata id="metadata16">
          <rdf:RDF
            ><cc:Work rdf:about=""
              ><dc:format>image/svg+xml</dc:format
              ><dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work
          ></rdf:RDF>
        </metadata>
        <defs id="defs14">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath46">
            <path
              d="M 0,269.29468 V 0 h -269.29468 c 0,140.9014 114.21988,255.12128 255.12129,255.12128 z"
              id="path44"
            />
          </clipPath>
          <pattern
            patternTransform="translate(-283.963,-14.671)"
            patternUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="4.98138"
            height="4.98138"
            id="pattern54"
          >
            <g id="g52">
              <path
                d="M 0,0 3.08846,3.08846"
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-opacity: 1;
                  stroke-width: 0.3985;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  stroke-dasharray: none;
                "
                id="path50"
              />
            </g>
          </pattern>
        </defs>
        <g id="g18" transform="matrix(1.3333333,0,0,-1.3333333,0,379.28133)">
          <g id="g20" transform="translate(283.963,14.671)">
            <g id="g22">
              <g id="g24">
                <g id="g26">
                  <g id="g28">
                    <path
                      d="M -283.4681,-14.17339 V 0 H 0 v -14.17339 z"
                      style="
                        fill: none;
                        stroke: #000000;
                        stroke-width: 0.99628;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        stroke-dasharray: none;
                        stroke-opacity: 1;
                      "
                      id="path30"
                    />
                  </g>
                  <g id="g32">
                    <path
                      d="M 0,269.29468 V -14.1734 h -14.17339 v 283.46808 z"
                      style="
                        fill: none;
                        stroke: #000000;
                        stroke-width: 0.99628;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        stroke-dasharray: none;
                        stroke-opacity: 1;
                      "
                      id="path34"
                    />
                  </g>
                  <g id="g36">
                    <path
                      d="m -269.29468,0 c 0,140.9014 114.21988,255.12128 255.12129,255.12128"
                      style="
                        fill: none;
                        stroke: #000000;
                        stroke-width: 0.99628;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        stroke-dasharray: 2.98883, 2.98883;
                        stroke-dashoffset: 0;
                        stroke-opacity: 1;
                      "
                      id="path38"
                    />
                  </g>
                  <g id="g40">
                    <g id="g42" clip-path="url(#clipPath46)">
                      <g id="g48">
                        <path
                          d="M -14.17339,0 H -269.29468 V 255.12128 H -14.17339 Z"
                          style="
                            fill: url(#pattern54);
                            fill-opacity: 1;
                            fill-rule: nonzero;
                            stroke: none;
                          "
                          id="path56"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </g>

    <!-- Template della porta doppia -->
    <g
      class="porta_doppia_template"
      style="display: none"
      transform-origin="100 50"
      transform="translate(100, 100)"
    >
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="svg10"
        xml:space="preserve"
        width="200"
        height="100"
        viewBox="0 0 757.23334 379.28134"
      >
        <metadata id="metadata16">
          <rdf:RDF
            ><cc:Work rdf:about=""
              ><dc:format>image/svg+xml</dc:format
              ><dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work
          ></rdf:RDF>
        </metadata>
        <defs id="defs14">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath44">
            <path
              d="M 0,269.29468 V 0 h 269.29468 c 0,140.9014 -114.21988,255.12128 -255.12129,255.12128 z"
              id="path42"
            />
          </clipPath>
          <pattern
            patternTransform="translate(-0.498,-14.671)"
            patternUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="4.98138"
            height="4.98138"
            id="pattern52"
          >
            <g id="g50">
              <path
                d="M 0,0 3.08846,3.08846"
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-opacity: 1;
                  stroke-width: 0.3985;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  stroke-dasharray: none;
                "
                id="path48"
              />
            </g>
          </pattern>
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath78">
            <path
              d="M 566.93619,269.29468 V 0 H 297.64151 c 0,140.9014 114.21988,255.12128 255.12128,255.12128 z"
              id="path76"
            />
          </clipPath>
          <pattern
            patternTransform="translate(-0.498,-14.671)"
            patternUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="4.98138"
            height="4.98138"
            id="pattern86"
          >
            <g id="g84">
              <path
                d="M 0,0 3.08846,3.08846"
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-opacity: 1;
                  stroke-width: 0.3985;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  stroke-dasharray: none;
                "
                id="path82"
              />
            </g>
          </pattern>
        </defs>
        <g id="g18" transform="matrix(1.3333333,0,0,-1.3333333,0,379.28133)">
          <g id="g20" transform="translate(0.498,14.671)">
            <g id="g22">
              <g id="g24">
                <g id="g26">
                  <path
                    d="M 283.4681,-14.17339 V 0 H 0 v -14.17339 z"
                    style="
                      fill: none;
                      stroke: #000000;
                      stroke-width: 0.99628;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                      stroke-opacity: 1;
                    "
                    id="path28"
                  />
                </g>
                <g id="g30">
                  <path
                    d="M 0,269.29468 V -14.1734 h 14.17339 v 283.46808 z"
                    style="
                      fill: none;
                      stroke: #000000;
                      stroke-width: 0.99628;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                      stroke-opacity: 1;
                    "
                    id="path32"
                  />
                </g>
                <g id="g34">
                  <path
                    d="m 269.29468,0 c 0,140.9014 -114.21988,255.12128 -255.12129,255.12128"
                    style="
                      fill: none;
                      stroke: #000000;
                      stroke-width: 0.99628;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: 2.98883, 2.98883;
                      stroke-dashoffset: 0;
                      stroke-opacity: 1;
                    "
                    id="path36"
                  />
                </g>
                <g id="g38">
                  <g id="g40" clip-path="url(#clipPath44)">
                    <g id="g46">
                      <path
                        d="M 14.17339,0 H 269.29468 V 255.12128 H 14.17339 Z"
                        style="
                          fill: url(#pattern52);
                          fill-opacity: 1;
                          fill-rule: nonzero;
                          stroke: none;
                        "
                        id="path54"
                      />
                    </g>
                  </g>
                </g>
                <g id="g56">
                  <g id="g58">
                    <g id="g60">
                      <path
                        d="M 283.4681,-14.17339 V 0 h 283.46809 v -14.17339 z"
                        style="
                          fill: none;
                          stroke: #000000;
                          stroke-width: 0.99628;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          stroke-dasharray: none;
                          stroke-opacity: 1;
                        "
                        id="path62"
                      />
                    </g>
                    <g id="g64">
                      <path
                        d="M 566.93619,269.29468 V -14.1734 h -14.1734 v 283.46808 z"
                        style="
                          fill: none;
                          stroke: #000000;
                          stroke-width: 0.99628;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          stroke-dasharray: none;
                          stroke-opacity: 1;
                        "
                        id="path66"
                      />
                    </g>
                    <g id="g68">
                      <path
                        d="m 297.64151,0 c 0,140.9014 114.21988,255.12128 255.12128,255.12128"
                        style="
                          fill: none;
                          stroke: #000000;
                          stroke-width: 0.99628;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          stroke-dasharray: 2.98883, 2.98883;
                          stroke-dashoffset: 0;
                          stroke-opacity: 1;
                        "
                        id="path70"
                      />
                    </g>
                    <g id="g72">
                      <g id="g74" clip-path="url(#clipPath78)">
                        <g id="g80">
                          <path
                            d="M 552.76279,0 H 297.64151 v 255.12128 h 255.12128 z"
                            style="
                              fill: url(#pattern86);
                              fill-opacity: 1;
                              fill-rule: nonzero;
                              stroke: none;
                            "
                            id="path88"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </g>

    <!-- Template della porta sinistra -->
    <g
      class="porta_sinistra_template"
      style="display: none"
      transform-origin="50 50"
      transform="translate(100, 100)"
    >
      <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="svg10"
        xml:space="preserve"
        width="100"
        height="100"
        viewBox="0 0 379.28583 379.2858"
      >
        <metadata id="metadata16">
          <rdf:RDF
            ><cc:Work rdf:about=""
              ><dc:format>image/svg+xml</dc:format
              ><dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work
          ></rdf:RDF>
        </metadata>
        <defs id="defs14">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath44">
            <path
              d="M 0,269.29468 V 0 h 269.29468 c 0,140.9014 -114.21988,255.12128 -255.12129,255.12128 z"
              id="path42"
            />
          </clipPath>
          <pattern
            patternTransform="translate(-0.498,-14.671)"
            patternUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="4.98138"
            height="4.98138"
            id="pattern52"
          >
            <g id="g50">
              <path
                d="M 0,0 3.08846,3.08846"
                style="
                  fill: none;
                  stroke: #000000;
                  stroke-opacity: 1;
                  stroke-width: 0.3985;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  stroke-dasharray: none;
                "
                id="path48"
              />
            </g>
          </pattern>
        </defs>
        <g
          id="g18"
          transform="matrix(1.3333333,0,0,-1.3333333,1.8667634e-4,379.28508)"
        >
          <g id="g20" transform="translate(0.498,14.671)">
            <g id="g22">
              <g id="g24">
                <g id="g26">
                  <path
                    d="M 283.4681,-14.17339 V 0 H 0 v -14.17339 z"
                    style="
                      fill: none;
                      stroke: #000000;
                      stroke-width: 0.99628;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                      stroke-opacity: 1;
                    "
                    id="path28"
                  />
                </g>
                <g id="g30">
                  <path
                    d="M 0,269.29468 V -14.1734 h 14.17339 v 283.46808 z"
                    style="
                      fill: none;
                      stroke: #000000;
                      stroke-width: 0.99628;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                      stroke-opacity: 1;
                    "
                    id="path32"
                  />
                </g>
                <g id="g34">
                  <path
                    d="m 269.29468,0 c 0,140.9014 -114.21988,255.12128 -255.12129,255.12128"
                    style="
                      fill: none;
                      stroke: #000000;
                      stroke-width: 0.99628;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: 2.98883, 2.98883;
                      stroke-dashoffset: 0;
                      stroke-opacity: 1;
                    "
                    id="path36"
                  />
                </g>
                <g id="g38">
                  <g id="g40" clip-path="url(#clipPath44)">
                    <g id="g46">
                      <path
                        d="M 14.17339,0 H 269.29468 V 255.12128 H 14.17339 Z"
                        style="
                          fill: url(#pattern52);
                          fill-opacity: 1;
                          fill-rule: nonzero;
                          stroke: none;
                        "
                        id="path54"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </g>
  </svg>
</template>
<script>
export default {
  name: "SvgStore",
  components: {},
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>