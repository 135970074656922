<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-data-table
          :headers="headers_interventi"
          :items="items_interventi"
          class="elevation-1"
          fixed-header
          height="75vh"
        >
          <!-- Titolo tabella -->
          <template v-slot:top>
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text">
                Interventi di Manutenzione
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToManutenzione"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-keyboard-backspace </v-icon>
                  </v-btn>
                </template>
                <span>Indietro</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- Bottone dialog di modifica intervento -->
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaIntervento(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                >
              </template>
              <span>Mostra Dettagli</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="modifica_dialog_visible">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              {{
                intervento_sel.id_intervento === -1
                  ? "Nuovo Intervento"
                  : "Modifica Intervento"
              }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="salvaModificaIntervento"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span>Salva Intervento</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickChiudiModificaIntervento"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-keyboard-backspace </v-icon>
                </v-btn>
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="pb-5 px-5 py-0">
            <v-tabs class="mt-5" v-model="tab_intervento" align-with-title>
              <v-tabs-slider color="transparent"></v-tabs-slider>
              <v-tab
                active-class="py-0 my-0 indigo white--text"
                class="py-0 my-0"
                v-for="item in ['Generale']"
                :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab_intervento">
              <v-tab-item>
                <v-card elevation="2" outlined class="mx-0 py-4">
                  <v-row class="mx-5">
                    <v-col cols="2">
                      <v-text-field
                        disabled
                        label="Cod. Intervento"
                        v-model="intervento_sel.cod_intervento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        :disabled="intervento_sel.id_stato_operativo == null"
                        :items="stato_intervento_lista_disponibile"
                        label="Stato Intervento"
                        placeholder="Seleziona"
                        item-text="des"
                        item-value="id_tabella"
                        required
                        v-model="intervento_sel.id_stato_operativo"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        disabled
                        label="Referente"
                        v-model="intervento_sel.des_referente"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" id="listener_costo_stimato">
                      <v-text-field
                        label="Costo Stimato (€)"
                        type="number"
                        v-model="intervento_sel.costo_stimato"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        v-model="intervento_sel.id_tipo_intervento"
                        :items="ambito_manutentivo_lista"
                        label="Ambito Manutentivo"
                        disabled
                        placeholder="Seleziona"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-menu
                        disabled
                        v-model="calendario_data_prevista_intervento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="289"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            disabled
                            background-color="#ffffff"
                            hide-details
                            label="Data prevista"
                            v-model="intervento_sel.data_prevista"
                            readonly
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          disabled
                          v-model="intervento_sel.data_prevista"
                          @input="
                            (calendario_data_prevista_intervento = false),
                              checkData(intervento_sel)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="mx-5">
                    <v-col cols="2">
                      <v-autocomplete
                        disabled
                        v-model="intervento_sel.id_tipo_manutenzione"
                        :items="tipo_manutenzione_lista"
                        label="Tipo Manutenzione"
                        placeholder="Seleziona"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      >
                        <template #label>
                          <span class="">Tipo Manutenzione</span>
                          <span
                            @click.stop
                            style="min-width: 500px; pointer-events: all"
                          >
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        disabled
                        v-model="intervento_sel.id_urgenza"
                        :items="urgenza_lista"
                        label="Urgenza"
                        placeholder="Seleziona"
                        item-text="des"
                        item-value="id_tabella"
                        required
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <!--<v-select
                v-model="intervento_sel.fornitori"
                :items="fornitore_lista"
                item-text="ragione_sociale"
                return-object
                :menu-props="{ maxHeight: '400' }"
                key="id_fornitore"
                label="Fornitori"
                multiple
              ></v-select>-->
                      <v-select
                        disabled
                        v-model="fornitore_sel"
                        :items="fornitore_lista"
                        item-text="ragione_sociale"
                        item-value="id_fornitore"
                        :menu-props="{ maxHeight: '400' }"
                        @change="associaFornitore(fornitore_sel)"
                        label="Fornitori"
                      ></v-select>
                    </v-col>

                    <v-col cols="2">
                      <v-text-field
                        disabled
                        label="Annotazioni"
                        v-model="intervento_sel.note"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-checkbox
                        disabled
                        v-model="intervento_sel.preventivo"
                        @change="checkInterventosel()"
                        label="Preventivo"
                        color="primary"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col cols="2">
                      <v-menu
                        disabled
                        v-model="calendario_data_schedulata_intervento"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="289"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            disabled
                            background-color="#ffffff"
                            hide-details
                            label="Data schedulata"
                            v-model="intervento_sel.data_schedulata"
                            readonly
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          disabled
                          v-model="intervento_sel.data_schedulata"
                          :min="intervento_sel.data_prevista"
                          @input="calendario_data_schedulata_intervento = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="mx-5">
                    <v-col cols="6" md="3">
                      <v-text-field
                        disabled
                        v-model="intervento_sel.des_sede"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        disabled
                        v-model="intervento_sel.des_struttura"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        disabled
                        v-model="intervento_sel.des_area"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        disabled
                        v-model="intervento_sel.des_zona"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mx-5"
                    v-if="
                      intervento_sel.id_stato_operativo == id_stato_ordinato
                    "
                  >
                    <v-col cols="3">
                      <v-text-field
                        label="Numero d'Ordine"
                        v-model="intervento_sel.numero_ordine"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-5">
                    <v-col cols="12">
                      <DettaglioAttivita
                        @aggiorna_attivita="aggiornaAttivita"
                        :intervento="intervento_sel"
                        :fornitori="intervento_sel.fornitori"
                        ref="dettaglio_attivita"
                        :attivita="attivita_intervento"
                        :stato_approvato_id="stato_approvato_id"
                        :stato_rifiutato_id="stato_rifiutato_id"
                        :stato_sospeso_id="stato_sospeso_id"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="modifica_dialog_visible">
        <v-card>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text"
              >Dettagli Intervento</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text style="max-height: 500px; overflow-y: auto">
            <v-col cols="12" class="py-0">
              <DettaglioAttivita
                @aggiorna_attivita="aggiornaAttivita"
                ref="dettaglio_attivita"
                :attivita="attivita_intervento"
                :intervento="intervento_sel"
                :fornitori="intervento_sel.fornitori"
                :stato_approvato_id="stato_approvato_id"
                :stato_rifiutato_id="stato_rifiutato_id"
                :stato_sospeso_id="stato_sospeso_id"
              />
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog> -->
    </v-row>
  </div>
</template>
<script>
import DettaglioAttivita from "@/components/MANUTENZIONE/DettaglioAttivita.vue";
import Intervention from "@/classes/Intervention.js";
export default {
  name: "Attivita",
  components: { DettaglioAttivita },
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    rotta_back: {
      type: String,
      default: "/home/manutenzione/main",
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      attivita_intervento: [],
      intervento_sel: new Intervention(),
      stato_approvato_id: null,
      stato_rifiutato_id: null,
      stato_sospeso_id: null,

      // Modal
      modifica_dialog_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      headers_interventi: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Cod. Intervento",
          sortable: false,
          value: "cod_intervento",
        },
        {
          text: "Tipo Intervento",
          sortable: false,
          value: "des_tipo_intervento",
        },
        {
          text: "Referente",
          sortable: false,
          value: "des_referente",
        },
        {
          text: "Stato",
          sortable: false,
          value: "des_stato",
        },
      ],
      items_interventi: [],
      calendario_data_prevista_intervento: false,
      calendario_data_schedulata_intervento: false,
      items_allegati: [],
      items_regole: [],
      items_richieste: [],
      items_richieste_visualizzate: [],
      sede_lista: [],
      stato_intervento_lista: [],
      stato_intervento_lista_disponibile: [],
      steps_sel: [],
      struttura_lista: [],
      tab_consuntivi: [],
      tab_intervento: [],
      tipo_manutenzione_lista: [],
      urgenza_lista: [],
      zona_lista: [],
      fornitore_sel: null,
      ambito_manutentivo_lista: [],
      area_lista: [],
      consuntivo_sel_extra: [],
      consuntivo_sel_materiale: [],
      consuntivo_sel_personale: [],
      expanded_richieste: [],
      expanded_steps: [],
      fornitore_lista: [],
      fornitori_confronto: [],
      des_area_intervento_sel: "",
      des_sede_intervento_sel: "",
      des_struttura_intervento_sel: "",
      des_zona_intervento_sel: "",
      id_stato_ordinato: null,
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "'MAN_TIPO_MANUTENZIONE','MAN_URGENZA','MAN_AMBITO_MANUTENTIVO','MAN_STATO_INTERVENTO_APPROVATIVO','MAN_STATO_INTERVENTO_OPERATIVO'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.tipo_manutenzione_lista =
          res.data.MAN_TIPO_MANUTENZIONE != undefined
            ? res.data.MAN_TIPO_MANUTENZIONE
            : [];
        this.urgenza_lista =
          res.data.MAN_URGENZA != undefined ? res.data.MAN_URGENZA : [];
        this.ambito_manutentivo_lista =
          res.data.MAN_AMBITO_MANUTENTIVO != undefined
            ? res.data.MAN_AMBITO_MANUTENTIVO
            : [];
        this.stato_intervento_lista =
          res.data.MAN_STATO_INTERVENTO_OPERATIVO != undefined
            ? res.data.MAN_STATO_INTERVENTO_OPERATIVO
            : [];
        console.log("STATO INTERVENTO LISTA:", this.stato_intervento_lista);
        // Registro l'id dello stato intervento ordinato
        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "ORDINATO") {
            this.id_stato_ordinato = elem.id_tabella;
          }
        });

        // Registro l'id dello stato intervento richiesto
        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "RICHIESTO") {
            this.id_stato_richiesto = elem.id_tabella;
          }
        });

        // Registro l'id dello stato intervento in lavorazione
        this.stato_intervento_lista.forEach((elem) => {
          if (elem.des === "IN LAVORAZIONE") {
            this.id_stato_in_lavorazione = elem.id_tabella;
          }
        });

        // Recupero dinamicamente gli id degli stati approvativi degli interventi
        if (res.data.MAN_STATO_INTERVENTO_APPROVATIVO != undefined) {
          res.data.MAN_STATO_INTERVENTO_APPROVATIVO.forEach((ambito) => {
            if (ambito.des === "RIFIUTATO") {
              this.stato_rifiutato_id = ambito.id_tabella;
            } else if (ambito.des === "DA APPROVARE") {
              this.stato_sospeso_id = ambito.id_tabella;
            } else if (ambito.des === "APPROVATO") {
              this.stato_approvato_id = ambito.id_tabella;
            }
          });
        }
      });
    },
    getFornitoreIntervento(arg) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getfornitoreintervento",
          token: localStorage.user_token,
          id_sede: arg.id_sede,
          id_struttura: arg.id_struttura,
          id_ambito_manutenzione: arg.id_tipo_intervento,
        },
      };
      console.log("arg", arg);
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.fornitore_lista = res.data.result;
          // Rinomino il campo id_anagrafica per convenienza di BE sul salvataggio
          this.fornitore_lista.forEach((fornitore) => {
            fornitore.id_fornitore = fornitore.id_anagrafica;
            delete fornitore.id_anagrafica;
          });
          //this.intervento_sel.fornitori = this.fornitore_lista;
        } else {
          this.fornitore_lista = [];
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non è stato trovato nessun fornitore autorizzato a operare in questa sede / struttura specializzato per questo ambito manutentivo.";
          this.snackbar_text_color = "white";
        }
      });
    },
    salvaModificaIntervento() {
      // Eseguo una serie di controlli prima di salvare.

      // Controllo la presenza di un referente
      if (this.intervento_sel.des_referente === "") {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire il Referente prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo la presenza di un costo stimato
      if (this.intervento_sel.costo_stimato === "") {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Inserire il Costo Stimato prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che il costo stimato non sia negativo
      if (this.intervento_sel.costo_stimato <= 0) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Il Costo Stimato deve essere superiore a 0.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo la presenza di un Ambito Manutentivo
      if (this.intervento_sel.id_tipo_intervento === null) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Definire l'Ambito Manutentivo prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Se lo Stato è RICHIESTO e c'è una attività, lo stato operativo passa in IN LAVORAZIONE
      if (
        this.intervento_sel.id_stato_operativo == this.id_stato_richiesto &&
        this.$refs.dettaglio_attivita.items_attivita.length > 0
      ) {
        this.intervento_sel.id_stato_operativo = this.id_stato_in_lavorazione;
        this.intervento_sel.des_stato_operativo = "IN LAVORAZIONE";
      }

      // Controllo la presenza dell'Urgenza
      if (this.intervento_sel.id_urgenza === null) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Definire l'Urgenza prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      if (
        this.intervento_sel.id_stato_operativo === this.id_stato_ordinato &&
        (this.intervento_sel.numero_ordine === null ||
          this.intervento_sel.numero_ordine === "")
      ) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Indicare un Numero d'Ordine prima di salvare.";
        this.snackbar_text_color = "white";
        return;
      }

      // Ricavo la lista dei fornitori da salvare
      let fornitori_tmp = [];
      this.intervento_sel.fornitori.forEach((id) => {
        this.fornitore_lista.forEach((fornitore) => {
          if (fornitore.id_fornitore === id.id_fornitore) {
            fornitori_tmp.push(fornitore);
          }
        });
      });

      // Associo la des_urgenza
      this.urgenza_lista.forEach((urgenza) => {
        if (urgenza.id_tabella === this.intervento_sel.id_urgenza) {
          this.intervento_sel.des_urgenza = urgenza.des;
        }
      });

      // Genero un oggetto intervento che andrò a salvare
      let intervento_tmp = new Intervention(this.intervento_sel);

      // Se l'intervento è nuovo ne setto lo stato a "sospeso".
      if (intervento_tmp.id_intervento === -1) {
        intervento_tmp.id_stato = this.stato_sospeso_id;
      }
      //console.log("FORNITORI TMP:", fornitori_tmp);
      // Resetto i fornitori dell'intervento temporaneo di salvataggio
      intervento_tmp.fornitori = [];

      /* Valorizzo i fornitori dell'intervento temporaneo
       * con la lista dei fornitori da salvare. */
      intervento_tmp.fornitori = fornitori_tmp;

      // Assegno un id_fornitore_dettaglio a ogni fornitore
      intervento_tmp.fornitori.forEach((fornitore) => {
        // Se non c'è un id_anagrafica lo assegno in base all'id_fornitore
        if (fornitore.id_anagrafica === undefined) {
          fornitore.id_anagrafica = fornitore.id_fornitore;
        }

        // Se non c'è un id_fornitore_dettaglio lo inserisco
        if (fornitore.id_fornitore_dettaglio === undefined) {
          // Assegno un id_fornitore_dettaglio a -1
          fornitore.id_fornitore_dettaglio = -1;
          this.fornitori_confronto.forEach((confronto) => {
            /* Se il fornitore era già presente sostituisco il -1 con
             * l'id_fornitore_dettaglio debito. */
            if (confronto.id_fornitore === fornitore.id_fornitore) {
              fornitore.id_fornitore_dettaglio =
                confronto.id_fornitore_dettaglio;
            }
          });
        }

        // Assegno un id_intervento a ogni fornitore
        fornitore.id_intervento = intervento_tmp.id_intervento;
      });

      /* Di seguito faccio in modo che se un fornitore era presente
       * ed è stato deflaggato, gli metto la validità a 0. */
      // Per ogni fornitore originario nell'array di confronto...
      this.fornitori_confronto.forEach((confronto) => {
        // ... Faccio un semaforo per sapere se il fornitore ricorre...
        let ricorre = false;

        // ... E ciclo sull'array dei fornitori attuali...
        intervento_tmp.fornitori.forEach((fornitore) => {
          // Se il fornitore originario è ancora selezionato, accendo il semaforo
          if (confronto.id_fornitore == fornitore.id_fornitore) {
            ricorre = true;
          }
        });

        /* Se il fornitore originario non è più presente lo metto nell'array
         * dei fornitori da salvare, modificandogli la validità a 0. */
        if (ricorre === false) {
          let nuovo_fornitore = new Object();
          nuovo_fornitore = Object.assign({}, confronto);
          nuovo_fornitore.validita = 0;
          intervento_tmp.fornitori.push(nuovo_fornitore);
        }
      });
      //intervento_tmp.allegati = this.$refs.componenteAllegati.lista_allegati.filter(f=>f.tipo=="INTERVENTO");
      //Faccio un check sugli identificativi degli allegati
      intervento_tmp.allegati.forEach((allegato) => {
        if (allegato.id_allegato < 0) {
          allegato.id_allegato = -1;
        }
      });
      //console.log("intervento tmp allegati:", intervento_tmp.allegati);
      // Innesto le attività nell'intervento per il salvataggio
      intervento_tmp.attivita = this.$refs.dettaglio_attivita.items_attivita;
      //console.log("items attivita:", intervento_tmp.attivita);

      // Faccio un check sugli identificativi delle attività
      intervento_tmp.attivita.forEach((attivita) => {
        if (attivita.id_attivita < 0) {
          attivita.id_attivita = -1;
        }

        /* Per ogni attività, faccio un check sugli
         * identificativi dei suoi allegati. */
        if (attivita.allegati != undefined) {
          attivita.allegati.forEach((allegato) => {
            if (allegato.id_allegato < 0) {
              allegato.id_allegato = -1;
              allegato.id_riferimento = -1;
            }
          });
        }
        // delete attivita.lista_files;
      });
      /* Se l'intervento è nuovo e non ha fornitori,
       * gli assegno lo stato APERTO... */
      if (
        intervento_tmp.fornitori.length == 0 &&
        intervento_tmp.id_intervento === -1
      ) {
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.des === "APERTO") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      } else if (
        intervento_tmp.fornitori.length > 0 &&
        intervento_tmp.id_intervento === -1
      ) {
        // ... Altrimenti gli assegno lo stato RICHIESTO
        this.stato_intervento_lista.forEach((stato_intervento) => {
          if (stato_intervento.des === "RICHIESTO") {
            intervento_tmp.id_stato_operativo = stato_intervento.id_tabella;
            intervento_tmp.des_stato_operativo = stato_intervento.des;
          }
        });
      }

      let request = {
        controller: "Manutenzione",
        method: "PUT",
        richiesta: {
          action: "setintervento",
          token: localStorage.user_token,
          id_intervento: intervento_tmp.id_intervento,
          intervento: [intervento_tmp],
          id_fornitore: intervento_tmp.fornitori[0].id_anagrafica,
          id_richiesta: intervento_tmp.id_richiesta,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            if (this.$refs.componenteAllegati) {
              // Salvo gli allegati dell'intervento.
              this.$refs.componenteAllegati.id_riferimento_creazione_genitore =
                res.data[0].id_intervento;
              this.$refs.componenteAllegati.salvaTuttiAllegati();
            }

            // Eseguo una setstatointervento.
            let setstatointervento = {
              controller: "Manutenzione",
              method: "PUT",
              richiesta: {
                action: "setstatointervento",
                token: localStorage.user_token,
                id_intervento: intervento_tmp.id_intervento,
                id_stato_operativo: intervento_tmp.id_stato_operativo,
                cod_intervento: intervento_tmp.cod_intervento,
                id_fornitore: intervento_tmp.fornitori[0].id_anagrafica,
                id_richiesta: intervento_tmp.id_richiesta,
              },
            };

            this.$store.dispatch("api", setstatointervento);

            // Genero un oggetto resettato
            let nuovo_intervento = new Intervention();

            // Uso l'oggetto resettato per resettare intervento_sel
            this.intervento_sel = Object.assign({}, nuovo_intervento);

            /* Resetto le variabili di supporto che rappresentano
             * i campi geografici dell'intervento selezionato. */
            this.des_sede_intervento_sel = "";
            this.des_struttura_intervento_sel = "";
            this.des_area_intervento_sel = "";
            this.des_zona_intervento_sel = "";
            this.id_sede_intervento_sel = null;
            this.id_struttura_intervento_sel = null;

            // Resetto le attività dell'intervento selezionato
            this.attivita_intervento = [];

            // Resetto la lista dei fornitori passibili di incarico
            this.fornitore_lista = [];

            // Chiudo il dialog di modifica dell'intervento
            this.modifica_dialog_visible = false;
            this.getInterventiManutenzione();
            // Ricarico la tabella generale delle richieste di intervento
          }
        })
        .catch((ex) => {
          if (ex.response.status == 404 || ex.response.status == undefined) {
            // console.log("EX:", ex.response);
            this.snackbar = true;
            this.snackbar_background = "error";
            this.snackbar_text_color = "white";
            this.snackbar_icon = "mdi-close-outline";
            this.snackbar_text = ex.response.data.result;
            this.snackbar_icon_color = "white";
          }
        });
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    getInterventoDettaglio(arg) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getinterventodettaglio",
          token: localStorage.user_token,
          id_regola: arg.id_regola,
          id_intervento: arg.id_intervento,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          if (res.data.result.fornitori) {
            res.data.result.fornitori.forEach((fornitore) => {
              arg.fornitori.push(fornitore.id_fornitore);
            });
            arg.consuntivo = res.data.result.consuntivo;
            this.attivita_intervento = res.data.result.attivita;

            /* Inserisco nelle attività un campo che le collega al
             * consuntivo dell'intervento; questa informazione risulta
             * utile al BE in fase di salvataggio (chiamata setattivita).*/
            this.attivita_intervento.forEach((attivita) => {
              attivita.id_consuntivo = arg.id_consuntivo;
            });
            console.log("RES.DATAAA:", res.data);
            this.fornitori_confronto = res.data.result.fornitori;
            this.fornitore_sel = res.data.result.fornitori[0];
            this.intervento_sel.fornitori = res.data.result.fornitori;

            this.items_allegati = res.data.result.allegati_intervento;
            this.intervento_sel.allegati = res.data.result.allegati_intervento;

            //creazione lista delle src usando il $set perchè altrimenti si perdono informazioni e risulta array vuoto su this.lista_src
            //   this.items_allegati.forEach(async (el, i) => {
            //     this.lista_files.push(el);
            //     if (el.validita == 0) {
            //       this.$set(this.lista_src, i, null);
            //     } else {
            //       let blob;
            //       blob = await this.getImagePreviewDb(el.id_allegato + el.estensione.toString());
            //       this.$set(this.lista_src, i, URL.createObjectURL(blob));
            //     }
            //   });
          }

          /* Faccio una chiamata per valorizzare la select
           * dei fornitori passibili di chiamata per le attività. */
          this.getFornitoreIntervento(arg);
        }
      });
    },
    clickChiudiModificaIntervento() {
      // Genero un oggetto resettato
      let nuovo_intervento = new Intervention();

      // Uso l'oggetto resettato per resettare intervento_sel
      this.intervento_sel = Object.assign({}, nuovo_intervento);

      // Chiudo il dialog di modifica / creazione intervento
      this.modifica_dialog_visible = false;

      // Resetto gli array di supporto che servono per gli allegati
      this.lista_src = [];
      this.lista_files = [];

      /* Resetto le variabili di supporto che rappresentano
       * i campi geografici dell'intervento selezionato. */
      this.des_sede_intervento_sel = "";
      this.des_struttura_intervento_sel = "";
      this.des_area_intervento_sel = "";
      this.des_zona_intervento_sel = "";
      this.id_sede_intervento_sel = null;
      this.id_struttura_intervento_sel = null;

      // Resetto l'array dei fornitori passibili di incarico
      this.fornitore_lista = [];

      // Resetto le attività dell'intervento selezionato
      this.attivita_intervento = [];
    },
    aggiornaAttivita() {
      this.getInterventoDettaglio(this.intervento_sel);
    },
    chiudiDialog() {
      this.modifica_dialog_visible = false;

      // Uso l'oggetto resettato per valorizzare l'intervento_sel
      this.intervento_sel = Object.assign({}, new Intervention());
    },
    clickModificaIntervento(item) {
      if (
        item.des_stato_operativo === "IN LAVORAZIONE" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "OPJ" ||
          localStorage.cod_profilo == "OPS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.des === "NON DEFINIBILE" ||
            elem.des === "ATTESA MATERIALE" ||
            elem.des === "PROGRAMMATO" ||
            elem.des === "NON RISOLVIBILE" ||
            elem.des === "IN LAVORAZIONE" ||
            elem.des === "COMPLETATO"
        );
      } else if (
        (item.des_stato_operativo === "NON DEFINIBILE" ||
          item.des_stato_operativo === "ATTESA MATERIALE" ||
          item.des_stato_operativo === "PROGRAMMATO" ||
          item.des_stato_operativo === "NON RISOLVIBILE") &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "OPJ" ||
          localStorage.cod_profilo == "OPS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.des === "NON DEFINIBILE" ||
            elem.des === "ATTESA MATERIALE" ||
            elem.des === "PROGRAMMATO" ||
            elem.des === "NON RISOLVIBILE" ||
            elem.des === "COMPLETATO"
        );
      } else if (
        item.des_stato_operativo === "COMPLETATO" &&
        localStorage.cod_profilo == "GOD"
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.des == "CONSUNTIVO FORNITORE" || elem.des == "COMPLETATO"
        );
        console.log(
          "ENTRATO COMPLETATO",
          this.stato_intervento_lista_disponibile
        );
      } else if (
        item.des_stato_operativo === "CONSUNTIVO FORNITORE" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "AMM" ||
          localStorage.cod_profilo == "BOSS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) =>
            elem.des === "CONSUNTIVO FORNITORE" ||
            elem.des === "VALIDATO" ||
            elem.des === "REVISIONE"
        );
      } else if (
        item.des_stato_operativo === "VALIDATO" &&
        (localStorage.cod_profilo == "GOD" ||
          localStorage.cod_profilo == "AMM" ||
          localStorage.cod_profilo == "BOSS")
      ) {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.des === "ORDINATO" || elem.des === "VALIDATO"
        );
      } else {
        this.stato_intervento_lista_disponibile = this.stato_intervento_lista.filter(
          (elem) => elem.des === item.des_stato_operativo
        );
        // console.log(item.des_stato_operativo + " " + localStorage.id_profilo);
      }
      this.intervento_sel = Object.assign({}, new Intervention(item));
      console.log("INTERVENTO SEL:", this.intervento_sel);
      this.getInterventoDettaglio(this.intervento_sel);
      this.modifica_dialog_visible = true;
    },
    // getInterventoDettaglio(arg) {
    //   let request = {
    //     controller: "Manutenzione",
    //     method: "POST",
    //     richiesta: {
    //       action: "getinterventodettaglio",
    //       token: localStorage.user_token,
    //       id_regola: arg.id_regola,
    //       id_intervento: arg.id_intervento,
    //     },
    //   };

    //   this.$store.dispatch("api", request).then((res) => {
    //     if (res.status === 200) {
    //       this.attivita_intervento = res.data.result.attivita;
    //       this.intervento_sel.fornitori = res.data.result.fornitori;
    //       this.getListaRichieste();
    //     }
    //   });
    // },
    async getInterventiManutenzione() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getinterventimanutenzionelist",
          token: localStorage.user_token,
        },
      };
      await this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.items_interventi = res.data.result.interventi;
        }
      });
    },
    getListaRichieste() {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getlistarichieste",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          id_profilo_utente: localStorage.id_profilo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          var items_richieste = res.data.result;
          items_richieste.forEach((elem) => {
            if (elem.id_richiesta === this.intervento_sel.id_richiesta) {
              this.intervento_sel.des_sede = elem.des_sede;
              this.intervento_sel.des_struttura = elem.des_struttura;
              this.intervento_sel.des_area = elem.des_area;
              this.intervento_sel.des_zona = elem.des_zona;
              this.intervento_sel.id_sede = elem.id_sede;
              this.intervento_sel.id_struttura = elem.id_struttura;
            }
          });
        }
      });
    },
    // gettabelle() {
    //   let request = {
    //     controller: "Global",
    //     method: "POST",
    //     richiesta: {
    //       action: "gettabella",
    //       token: localStorage.getItem("user_token"),
    //       tipo: "MAN_STATO_INTERVENTO_APPROVATIVO",
    //       filter: "validita = 1",
    //     },
    //   };

    //   this.$store.dispatch("api", request).then((res) => {
    //     // Recupero dinamicamente gli id degli stati approvativi degli interventi
    //     if (res.data != undefined) {
    //       res.data.forEach((ambito) => {
    //         if (ambito.des === "RIFIUTATO") {
    //           this.stato_rifiutato_id = ambito.id_tabella;
    //         } else if (ambito.des === "DA APPROVARE") {
    //           this.stato_sospeso_id = ambito.id_tabella;
    //         } else if (ambito.des === "APPROVATO") {
    //           this.stato_approvato_id = ambito.id_tabella;
    //         }
    //       });
    //     }
    //   });
    // },
    goToManutenzione() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        this.$router.push("/home/manutenzione/main");
      }
    },
  },
  created() {
    this.getInterventiManutenzione();
    this.gettabelle();
  },
  mounted() {},
};
</script>
<style scoped>
</style>