<template>
    <v-dialog v-model="SHOW" width="700">
        <div class="graph_compiled_query">
            <header>
                <h1>{{DATA.nome}} - Query Compilata</h1>
                <GraphSVG name="close" @click="close_query"  />
                <GraphSVG name="copy" @click="copy_query"  />
            </header>
            <main>
                <textarea readonly ref="host" class="graph_scrollbar_input" v-model="DATA.query"/>
                <div ref="snackbar">
                    <span>Elemento copiato negli appunti</span>
                </div>
            </main>           
        </div>
    </v-dialog>
</template>
<script>

import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name : "GraphCompiledQuery",
    components:{
        GraphSVG
    },
    data(){
        return{
            SHOW : false,
            DATA : {},
        }
    },
    mounted(){
        document.body.addEventListener('graph_compiled_query',this.show_query);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_compiled_query',this.show_query);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        show_query(event){
            this.SHOW = true;
            this.DATA = event.detail;               
        },
        copy_query(){
            this.$refs.host.select();
            document.execCommand('copy'); 
            const snackbar = this.$refs.snackbar;

            snackbar.removeAttribute('class');
            snackbar.style.opacity = 1;
            setTimeout(function(){
                snackbar.setAttribute('class', 'transition');
                snackbar.style.opacity = 0;   
            }, 50);
        },
        close_query(){            
            this.SHOW = false;
            this.DATA = {};
        }
    }
}
</script>
<style src="./Query.css" scoped></style>