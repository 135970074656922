<template>
  <v-container fluid class="py-0">
    <!--Add Unita Misura Gestione  Dialog-->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="add_misura_dialog"
      width="700"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Unità di misura</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="ARTICOLO_CARATTERISTICHE"
            idOggetto="MODIFICA_ARTICOLO_UM"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva Misura"
            v-on:buttonClick="salvaMisura(item)"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                @click="
                  add_misura_dialog = false;
                  getunitmisura();
                "
              >
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="pa-0">
              <v-col md="3">Unità standard (di gestione)</v-col>
              <v-col md="5">Unità derivate</v-col>
              <v-col>Fattore di conversione</v-col>
            </v-row>
            <v-row
              class="pa-0"
              v-for="item in unita_misura_mod_list"
              :key="item.id"
            >
              <v-col md="3" class="py-0">
                <TextField
                  :readonly="true"
                  idForm="CARATTERISTICHE"
                  idOggetto="CE_UNITA_MISURA"
                  label_input="Unita Misura"
                  palceholder_input="Unita Misura"
                  :vmodel="unita_standard"
                />
              </v-col>
              <v-col md="5" class="py-0">
                <v-autocomplete
                  class="ml-1 input-class centered-input"
                  v-model="item.id_um"
                  :items="mizura_list"
                  :placeholder="dataChar.um_articolo"
                  required
                  item-text="des"
                  item-value="id_tabella"
                >
                </v-autocomplete>
              </v-col>
              <v-col class="py-0">
                <TextField
                  idForm="CARATTERISTICHE"
                  idOggetto="CE_FATTORE_CONVERSIONE"
                  :vmodel="item.fattore_conversione"
                  v-on:changeText="(val) => (item.fattore_conversione = val)"
                  :min_input="0"
                  type_input="number"
                />
              </v-col>
            </v-row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mt-2" @click="addNewMisura" small>
                  <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                    >mdi-plus</v-icon
                  >
                </v-btn>
              </template>
              <span> Aggiungi Nuova Riga</span>
            </v-tooltip>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-form action>
      <v-container fluid class="px-0">
        <v-row>
          <v-col cols="12" md="6">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card :elevation="hover ? 24 : 2">
                  <v-toolbar
                    dense
                    class="indigo darken-1 headline"
                    primary-title
                  >
                    <v-toolbar-title class="white--text"
                      >Caratteristiche Fisiche</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-container>
                    <v-row class="ml-1">
                      <v-col class="py-0" cols="12" sm="6" xs="7" md="7">
                        <v-autocomplete
                          @change="consoleChange"
                          v-model="dataChar.id_um"
                          :items="mizura_list"
                          :placeholder="des"
                          item-text="des"
                          required
                          item-value="id_tabella"
                          label="Unità di misura gestione"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col md="2" class="py-0 d-flex align-center">
                        <Button
                          colore_icona="primary"
                          idForm="ARTICOLO_CARATTERISTICHE"
                          idOggetto="CREA_ARTICOLO_UM"
                          mdi_icona="mdi-plus"
                          :disabled="!dataChar.id_articolo"
                          testo_tooltip="Aggiungi Unità Misura"
                          v-on:buttonClick="
                            add_misura_dialog = true;
                            getunitmisura();
                          "
                        />
                      </v-col>
                      <v-col class="py-0" cols="2" xs="2" sm="4" md="3">
                        <span
                          style="font-size: 11px; color: rgba(0, 0, 0, 0.6)"
                          class="ml-n12"
                        >
                          Venduto in kit
                        </span>
                        <v-switch
                          v-model="dataChar.vendita_kit"
                          class="mt-n1 ml-3"
                          :false-value="0"
                          :true-value="1"
                        ></v-switch>
                      </v-col>
                      <v-col class="py-0" cols="2" sm="2" md="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_LUNGHEZZA"
                          label_input="Lunghezza"
                          palceholder_input="Lunghezza"
                          :vmodel="dataChar.l"
                          v-on:changeText="(val) => (dataChar.l = val)"
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" cols="2" sm="2" md="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_LARGHEZZA"
                          label_input="Larghezza"
                          palceholder_input="Larghezza"
                          :vmodel="dataChar.p"
                          v-on:changeText="(val) => (dataChar.p = val)"
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" cols="2" sm="2" md="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_ALTEZZA"
                          label_input="Altezza/spessore"
                          palceholder_input="Altezza"
                          :vmodel="dataChar.h"
                          v-on:changeText="(val) => (dataChar.h = val)"
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" cols="12" sm="5" xs="7" md="5">
                        <v-autocomplete
                          v-model="dataChar.id_um_fisica"
                          :items="unita_misura_larghezza"
                          item-text="des"
                          placeholder="Unità Misura"
                          required
                          item-value="id_tabella"
                          label="UM Larghezza/Lunghezza"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0" cols="3" sm="2" md="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_PESO_NETTO"
                          label_input="Peso Netto"
                          palceholder_input="Peso Netto"
                          :vmodel="dataChar.peso_netto"
                          v-on:changeText="(val) => (dataChar.peso_netto = val)"
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" md="2" sm="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_PESO_LORDO"
                          label_input="Peso Lordo"
                          palceholder_input="Peso Lordo"
                          :vmodel="dataChar.peso_lordo"
                          v-on:changeText="(val) => (dataChar.peso_lordo = val)"
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" cols="3" sm="2" md="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_PESO_SPECIFICO"
                          label_input="Peso Specifico"
                          palceholder_input="Peso Specifico"
                          :vmodel="dataChar.peso_specifico"
                          v-on:changeText="
                            (val) => (dataChar.peso_specifico = val)
                          "
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" cols="12" sm="5" xs="7" md="5">
                        <v-autocomplete
                          v-model="dataChar.id_um_peso"
                          :items="unita_misura_peso"
                          item-text="des"
                          placeholder="UM Peso"
                          required
                          item-value="id_tabella"
                          label="UM Peso"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0" cols="12" sm="5" md="4">
                        <v-autocomplete
                          v-model="dataChar.id_materiale"
                          :items="materiale_list"
                          placeholder="Materiale"
                          item-text="des"
                          required
                          item-value="id_tabella"
                          label="Materiale"
                        >
                          <template #label>
                            <span class="">Materiale<strong>* </strong></span>
                            <a @click.stop style="pointer-events: all">
                              <Modules
                                v-on:dialogModules="aggiornaMateriale"
                                :tipo="Tipo"
                                nome="Materiale"
                                setaction="settabelle"
                                getaction="gettabelle"
                                controller="Magazzino"
                                filter="validita = 1"
                                :dati="[{ des: '' }]"
                              />
                            </a>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0" sm="1" md="2">
                        <TextField
                          classe_input="input-class centered-input"
                          idForm="CARATTERISTICHE"
                          idOggetto="CE_VOLUME"
                          label_input="Volume"
                          palceholder_input="Volume"
                          :vmodel="dataChar.volume"
                          v-on:changeText="(val) => (dataChar.volume = val)"
                          :min_input="0"
                          type_input="number"
                        />
                      </v-col>
                      <v-col class="py-0" cols="12" sm="4" xs="7" md="4">
                        <v-autocomplete
                          v-model="dataChar.id_um_volume"
                          :items="unita_misura_volume"
                          item-text="des"
                          :placeholder="des"
                          required
                          item-value="id_tabella"
                          label="UM Volume"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col sm="12" xs="6" md="6">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card :elevation="hover ? 24 : 2">
                  <v-toolbar
                    dense
                    class="indigo darken-1 headline"
                    primary-title
                  >
                    <v-toolbar-title class="white--text"
                      >Annotazioni</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-container>
                    <v-row>
                      <v-col class="py-0" md="12">
                        <v-textarea
                          v-model="dataChar.note"
                          inverted
                          flat
                          rows="8"
                          placeholder="Note"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col cols="12">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  style="width: 100% !important"
                  :elevation="hover ? 24 : 2"
                >
                  <Fornitura
                    FORM_ID="ARTICOLO"
                    SOTTO_FORM="ARTICOLO_CARATTERISTICHE"
                    :FORM_BUTTON_ARRAY="FORM_BUTTON_ARRAY"
                    Tipo_component="Cara"
                    :ID_ARTICOLO="id_articolo"
                    :key="id_articolo"
                    :data="[]"
                  />
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col md="12" sm="12" xs="12">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card :elevation="hover ? 24 : 2">
                  <v-toolbar dense color="indigo darken-1 headline" dark flat>
                    <v-toolbar-title class="white--text"
                      >Dati Contabili</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-row>
                    <v-col md="3.5" class="ml-2">
                      <v-autocomplete
                        v-model="id_conto_acquisto"
                        :items="contro_partita_acquisto_list"
                        item-text="text"
                        required
                        item-value="id_tabella"
                        label="Contro Partita Acquisto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3.5" class="ml-2">
                      <v-autocomplete
                        v-model="id_conto_vendita"
                        :items="contro_partita_venduto_list"
                        item-text="text"
                        required
                        item-value="id_tabella"
                        label="Contro Partita Venduto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3.5" class="mr-2">
                      <v-autocomplete
                        v-model="aliquota_iva"
                        :items="aliquota_iva_list"
                        item-text="text"
                        required
                        item-value="id_tabella"
                        label="Aliquota I.V.A"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!-- in order to show alert of the page -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import Fornitura from "../../Common/Fornitura.vue";

export default {
  // Data getting as props from articolo Component
  props: ["dataChar", "CodEnable"],
  components: {
    TextField,
    Button,
    Fornitura,
    // Modules to show details of v-autocomplete list
    Modules: () => import("../../../../components/TOOLS/Modules"),
  },
  data() {
    return {
      mainIcon: "",
      FORM_BUTTON_ARRAY: [
        "CREA_ARTICOLO_FORNITURA",
        "MODIFICA_ARTICOLO_FORNITURA",
        "ELIMINA_ARTICOLO_FORNITURA",
        "SALVA_ARTICOLO_FORNITURA",
      ],
      // Modal
      color: "",
      add_misura_dialog: false,
      snackbar: false,
      snackbar_color: "",
      snackbar_icon: "",
      itemtodel: {},
      snackbar_text: "",
      dialogEliminazione: false,
      // Variabili d'appoggio
      Tipo: "MATERIALE",
      item: "",
      des: "",
      unita_standard: this.dataChar.um_articolo,
      misura_id: 0,
      unita_misura_mod_list: [],
      lead_time: 0,
      id_um1: "",
      materiale_list: [],
      mizura_list: [],
      id_articolo: this.dataChar.id_articolo,
      lunghezza: 0,
      larghezza: 0,
      search: "",
      contro_partita_acquisto_list: [],
      aliquota_iva_list: [],
      contro_partita_venduto_list: [],
      aliquota_iva: "",
      ubicazione_list: [],
      barcode: this.dataChar.barcode,
      check_inventario: this.dataChar.check_inventario,
      cod_articolo: this.dataChar.cod_articolo,
      costo_imballo: this.dataChar.costo_imballo,
      costo_standard: this.dataChar.costo_standard,
      costo_trasporto: this.dataChar.costo_trasporto,
      date_ins: this.dataChar.date_ins,
      date_mod: this.dataChar.date_mod,
      h: this.dataChar.h,
      id_conto_acquisto: this.dataChar.id_conto_acquisto,
      id_conto_vendita: this.dataChar.id_conto_vendita,
      id_materiale: this.dataChar.id_materiale,
      id_um: this.dataChar.id_um,
      unita_misura_gestione: this.dataChar.id_um,
      id_um_fisica: this.dataChar.id_um_fisica,
      id_um_peso: this.dataChar.id_um_peso,
      id_utente: this.dataChar.id_utente,
      l: this.dataChar.l,
      lotto_standard: this.dataChar.lotto_standard,
      magazzino_articolo: this.dataChar.magazzino_articolo,
      materiale_articolo: this.dataChar.materiale_articolo,
      note: this.dataChar.note,
      p: this.dataChar.p,
      particolare_sicurezza: this.dataChar.particolare_sicurezza,
      peso_lordo: this.dataChar.peso_lordo,
      peso_netto: this.dataChar.peso_netto,
      peso_specifico: this.dataChar.peso_specifico,
      prefisso_lotto: this.dataChar.prefisso_lotto,
      prelievo_lotti_successivi: this.dataChar.prelievo_lotti_successivi,
      prezzo_standard: this.dataChar.prezzo_standard,
      qta_contenitore_standard: this.dataChar.qta_contenitore_standard,
      qta_imballo_standard: this.dataChar.qta_imballo_standard,
      specifiche: this.dataChar.specifiche,
      standard: this.dataChar.standard,
      timbro: this.dataChar.timbro,
      tipo_articolo: this.dataChar.tipo_articolo,
      ubicazione_articolo: this.dataChar.ubicazione_articolo,
      um_articolo: this.dataChar.um_articolo,
      umfisica_articolo: this.dataChar.umfisica_articolo,
      umpeso_articolo: this.dataChar.umpeso_articolo,
      validita: 1,
      vendita_kit: null,
      volume: null,
      overlay: false,
      items: [],
      predefinito: false,
      cod_anagrafica: "",
      dialogFornitura: false,
      client: false,
      unita_misura: "",
      selectedItem: {},
      unita_misura_larghezza: [],
      unita_misura_peso: [],
      unita_misura_volume: [],
    };
  },
  methods: {
    // Function to add Materiale to materiale lista
    aggiornaMateriale() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "MATERIALE",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        res.data.map((el) => {
          this.materiale_list.push(el);
          this.materiale_list.map((el) => {
            if (el.id_tabella === this.id_materiale) {
              this.id_materiale = el.des;
            }
          });
        });
      });
    },
    consoleChange() {
      this.mizura_list.map((mi) => {
        if (this.dataChar.id_um === mi.id_tabella) {
          this.unita_standard = mi.des;
        }
      });
    },

    // TO save the unita misura in caratterristice
    salvaMisura() {
      this.overlay = true;
      this.unita_misura_mod_list.map((el) => {
        el.fattore_conversione = parseInt(el.fattore_conversione);
        this.mizura_list.map((mi) => {
          if (el.id_um === mi.id_tabella) {
            el.des_um = mi.des;
            return el;
          }
        });
      });
      const filteredArr = this.unita_misura_mod_list.reduce(
        (thing, current) => {
          const x = thing.find((item) => item.id_um === current.id_um);
          if (!x) {
            return thing.concat([current]);
          } else {
            let that = this;
            setTimeout(function() {
              that.snackbar = true;
              this.mainIcon = "mdi-alert-outline";
              this.color = "yellow accent-4";
              that.snackbar_icon = "mdi-close";
              that.snackbar_text = "Eliminate le unità di misura duplicate!";
            }, 1000);
            return thing;
          }
        },
        []
      );
      this.unita_misura_mod_list = filteredArr;
      if (!this.snackbar) {
        const request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticoloum",
            token: localStorage.getItem("user_token"),
            id_articolo: this.id_articolo,
            articolo_um: this.unita_misura_mod_list,
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          this.overlay = false;
          if (res.status == 200) {
            this.snackbar = true;
            // this.snackbar_color = "success";
            this.color = "green darken-3";
            this.snackbar_icon = "mdi-close-check";
            this.mainIcon = "mdi-check-circle";
            this.snackbar_text = "Misure salvate con successo!";
          }
        });
        this.add_misura_dialog = false;
        this.getunitmisura();
      }
    },
    // In order to add a new row in unita misura modal
    addNewMisura() {
      const newRow = {
        id_articolo_um: -1,
        id_articolo: this.id_articolo,
        id_um: this.id_um1,
        fattore_conversione: "",
        des_um: this.id_um1,
      };
      this.unita_misura_mod_list.push(newRow);
      this.misura_id++;
    },
    // To populate the unita misura table
    getunitmisura() {
      let listaUnitaMisura = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoloum",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo,
        },
      };
      this.$store.dispatch("api", listaUnitaMisura).then((res) => {
        this.unita_misura_mod_list = res.data;
        if (this.unita_misura_mod_list.slice[-1] !== undefined) {
          const lastItem = this.unita_misura_mod_list.slice[-1];
          this.misura_id = lastItem.id_articolo_um + 1;
        }
      });
    },
  },
  mounted() {
    // Condition if there is no Id Articolo Tabs will not Appear
    if (!this.id_articolo) {
      this.data = {};
    }
    // In order to populate the lista of V-selects
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'UNITA_MISURA','MATERIALE','UBICAZIONE_STANDARD'",
        filter: "validita = 1",
      },
    };
    // Gestisco la tabella UNITA_MISURA che ricevo dal BE
    this.$store.dispatch("api", request).then((res) => {
      res.data.UNITA_MISURA.map((el) => {
        this.mizura_list.push(el);
        this.mizura_list.map((el) => {
          if (el.val_txt === "M") {
            this.unita_misura_larghezza.push(el);
          }
          if (el.val_txt === "P") {
            this.unita_misura_peso.push(el);
          }
          if (el.val_txt === "V") {
            this.unita_misura_volume.push(el);
          }

          if (el.id_tabella === this.id_um) {
            this.id_um = el.des;
            this.unita_misura_gestione = el.des;
          }
        });
      });
      // Gestisco la tabella MATERIALE che ricevo dal BE
      res.data.MATERIALE.map((el) => {
        this.materiale_list.push(el);
        this.materiale_list.map((el) => {
          if (el.id_tabella === this.id_materiale) {
            this.id_materiale = el.des;
          }
        });
      });
      // Gestisco la tabella UBICAZIONE_STANDARD che ricevo dal BE
      // Gestisco la tabella CAUSALE_TRASPORTO che ricevo dal BE
      this.incoterms_list = res.data.CAUSALE_TRASPORTO;
      // console.log(res.data.CAUSALE_TRASPORTO);
      // Gestisco la tabella PORTO che ricevo dal BE
      this.modalita_list = res.data.PORTO;
    });
    // In order to get Lista Unita Misura In Caratteristische
    this.getunitmisura();
  },
  watch: {
    data: function(val) {
      const DATA = this.data;
      this[DATA] = !this[DATA];
      this.$emit("update", val);
    },
    // Checking when the values of the Following Variables Changes the Cod articolo will be disabled
    "dataChar.id_um": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.vendita_kit": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.l": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.p": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.h": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.id_um_fisica": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.peso_netto": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.peso_lordo": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.peso_specifico": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.id_um_peso": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.id_materiale": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.volume": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.id_um_volume": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
    "dataChar.note": function(val, oldVal) {
      if (!this.CodEnable) {
        if (val == oldVal) {
          this.$emit("ValChanged", false);
        }
        if (val !== oldVal) {
          this.$emit("ValChanged", true);
        }
      }
    },
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table {
  max-width: 80vw !important;
  width: 80vw !important;
}
#CaratteristicheFornitura {
  max-width: 80vw !important;
  width: 80vw !important;
}
.centered-input input {
  text-align: center !important;
}
.removeScroll {
  overflow-x: hidden !important;
}
.artDialog {
  overflow-x: hidden;
}
.Anagrafica {
  position: fixed;
  width: 100% !important;
}
.snackbar {
  height: 150px !important;
}
</style>
