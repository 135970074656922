<template>
    <v-dialog persistent v-model="ui.show" width="800">
        <v-card>
            <!-- HEADER -->
            <v-toolbar dark dense>
                <v-toolbar-title>Opzioni click utente</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="ui.show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <v-row justify="center" v-if="store.click!=undefined">
                    <v-col cols="4" align="center">
                        <v-select @change="changeDisplayed" v-model="store.click.type" :items="options" item-text="text" item-value="value"/>
                    </v-col>
                    <v-col cols="4" align="center" v-if="ui.selected_type==0">
                        <v-select :items="dashboards" item-text="nome" item-value="id_ds" v-model="store.click.value"/>
                    </v-col>
                    
                </v-row>
            </v-container>
            <!-- TASTO SALVA -->
            <v-card-actions>
                <v-spacer />
                <v-btn text color="success" @click="save">
                    <v-icon class="mr-2">mdi-floppy</v-icon>Salva modifiche</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
<script>
export default {
    name : 'GraphEditorClick',
    props : {
        value: Object
    },
    data(){
        return{
            dashboards: new Array(),
            store : new Object(),
            options : [
                {text : 'Naviga a dashboard', value : 0},
                {text : 'Esegui query secondaria', value : 1}
            ],
            template : {
                type : -1,
                value : null
            },
            ui : {
                show : false,
                selected_type : -1,
            }
        }
    },
    mounted(){
        document.body.addEventListener('graph_editor_click',this.show_editor);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_editor_click',this.show_editor);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        changeDisplayed(){
            this.ui.selected_type = this.store.click.type;
        },
        save(){
            let value = Object.assign({},this.value);
            let encoded = JSON.stringify(this.store);
            // codifica del campo EXTRA come json codificato in base64 (con supporto per UTF-8)
            value.extra = btoa(unescape(encodeURIComponent(encoded)));
            this.$emit('input',value);
            this.ui.show = false;
        },
        show_editor(){
            const msg = new CustomEvent('graph_dashboard_list_request',{detail:this});
            document.body.dispatchEvent(msg);

            for(let i = 0; i < this.options.length; i++){
                const option = this.options[i];
                switch(option.value){
                    case 0:
                        option.disabled = this.value.famiglia_grafici != 5;
                        break;
                    case 1:
                        option.disabled = this.value.famiglia_grafici == 5;
                        break;
                }
            }
            
            try{
                // il campo EXTRA è un json codificato in base64 (con supporto per UTF-8)
                const keys = Object.keys(this.store);
                for(let i = 0; i < keys.length; i++){ delete this.store[keys[i]]; }
                const temp = JSON.parse(decodeURIComponent(escape(atob(this.value.extra))));                
                Object.assign(this.store, temp);
                if(this.store.click==undefined){
                    this.store.click = new Object();
                    Object.assign(this.store.click,this.template);
                }
                this.ui.selected_type = this.store.click.type;
            }catch(error){
                console.warn(error);
                Object.assign(this.store,{click:this.template});         
            } 
            this.ui.show = true;
        }
    }
}
</script>