<template>
    <div>
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <v-row>
            <v-col cols="6" align="left">
                <v-text-field
                    v-model="codice_fao"
                    :rules="[rules.required, rules.counter]"
                    label="Codice FAO"
                    v-on:keydown.enter.prevent="selectArticolo"
                    outlined
                    clearable
                ></v-text-field>
            </v-col>
            <v-col cols="6" align="left">
                <v-btn
                    class="ma-2" outlined color="indigo"
                    dark
                    @click="selectArticolo"
                >Cerca</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="ViewInventarioArticolo"> 
            <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="articolo.codice_articolo" label="Codice Articolo" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="articolo.cod_lotto" label="Cod. Lotto" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="8">
                <v-text-field v-model="articolo.des_articolo" label="Descrizione Articolo" readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.quantita" label="Quantità" type="number" :rules="[rules.required]" ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6"  md="4">
                <v-select
                :items="ubicazioni"
                item-text='des'
                item-value='id_tabella'
                label="Ubicazione"
                autocomplete
                v-model="articolo.id_ubicazione"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="12" align="center">
                <v-btn outlined color="blue darken-1" text @click="save('new')">Salva</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="ViewTableArticoli">
            <v-col cols="12" align="center">
                <v-data-table
                    :headers="headers_lotti_articolo"
                    :items="lotti_articolo"  
                    item-key="cod_lotto"
                    class="elevation-1"
                    hide-default-footer
                >
                    <template  v-slot:[`item.actions`]="{ item }" >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined small v-bind="attrs" v-on="on" @click="getartilololotto(item)">
                                    <v-icon color="indigo"
                                    >mdi mdi-check-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Seleziona il Codice Lotto</span>
                        </v-tooltip>    
                    </template>
                </v-data-table>
               
            </v-col>
        </v-row>

        <v-row v-if="ViewTableArticoliInventariati" >
            <v-col cols="12" align="center">
                <v-progress-linear
                color="indigo"
                rounded
                value="100"
                ></v-progress-linear>
                <v-data-table
                    :headers="headers_cod_lotto"
                    :items="articoli"  
                    item-key="id_magazzino_inventario"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [10, 50, 100], 
                        showFirstLastPage: true,
                    }"
                />
            </v-col>
        </v-row>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>    
</template>
<script>
    export default {
        data() {
            return{
                overlay: false,
                codice_lotto: '', 
                codice_fao: '',
                modalTab: "cod_lotto",

                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",

                ViewInventarioArticolo: false,
                ViewTableArticoli: false,
                ViewTableArticoliInventariati: false,
                timestamp: '',
                id_ubicazione: '', 
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 50 || 'Max 50 Caratteri',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                articoli:[],
                articolo: { id_magazzino_inventario: '', id_articolo: '', codice_articolo: '', descr_articolo: '', id_lotto: '', cod_lotto: '', ubicazione: '', quantita: '', validita: '', data:'', l:'',  p:'', h:'', materiale:'', },
                ubicazioni: [],
                headers_cod_lotto: [
                    { text: 'Cod Articolo', value: 'codice_articolo' },
                    { text: 'Descrizione', value: 'des_articolo' },
                    { text: 'Q.ta', value: 'quantita' },
                    { text: 'ID Ubicazione', value: 'id_ubicazione' },
                ],
                headers_lotti_articolo: [
                    { text: 'Cod Lotto', value: 'cod_lotto', width: '10%'},
                    { text: 'Cod Articolo', value: 'cod_articolo', width: '10%' },
                    { text: 'ID Articolo', value: 'id_articolo', width: '10%' },
                    { text: 'Descrizione', value: 'des_articolo', width: '60%'},
                    { text: 'Actions', value: 'actions', sortable: false, width: '10%' },
                ],
                lotti_articolo:[],
                lotto_articolo: { cod_lotto: '', id_articolo: '', id_lotto: '', codice_articolo: '', des_articolo: ''},

            }
        },


        mounted() { 
           this.getUbicazioni()
        },
        methods: {  
            selectArticolo(){
                if (this.codice_fao != null &&  this.codice_fao != ''  ){
                    let request = {
                        controller: "Logistica",
                        method: "POST",
                        richiesta: {
                            action: "getArticoliFAO",
                            token: localStorage.getItem("user_token"),
                            id_utente: localStorage.getItem("id"),
                            cod_FAO: this.codice_fao,
                            db_name: "geclink_durbiano",
                        },
                    }
                    this.$store.dispatch("api", request).then((res) => {
                        if (res.data.response){
                            this.lotti_articolo = res.data.result.articoloFAO
                            this.ViewTableArticoli =  true

                        }else{
                            this.snackbar_text = "Codice Lotto non presenti.";
                            this.snackbar_color = "error";
                            this.snackbar = true;
                            this.ViewInventarioArticolo = false
                            this.ViewTableArticoli =  false
                            this.ViewTableArticoliInventariati = false
                        }
                    
                    });
                    }else{
                            this.snackbar_text = "Codice FAO non presente.";
                            this.snackbar_color = "error";
                            this.snackbar = true;
                            this.ViewInventarioArticolo = false
                            this.ViewTableArticoli =  false
                            this.ViewTableArticoliInventariati = false
                            this.overlay = false
                        }
            },
            getartilololotto(item){
                    this.lotto_articolo = Object.assign({}, item)
                    this.codice_lotto = this.lotto_articolo.cod_lotto
                    let request = {
                        controller: "Logistica",
                        method: "POST",
                        richiesta: {
                            action: "getartilololotto",
                            token: localStorage.getItem("user_token"),
                            id_utente: localStorage.getItem("id"),
                            cod_lotto: this.lotto_articolo.cod_lotto,
                            db_name: "geclink_durbiano",
                        }
                    }

                    this.$store.dispatch("api", request).then((res) => {
                        if (res.data.response){
                            this.articoli = []
                            this.articolo = res.data.result.articoloLotto[0]
                            this.getMagazzinoInventario(this.codice_lotto)
                            this.ViewTableArticoli = false
                            this.ViewInventarioArticolo = true
                            this.ViewTableArticoliInventariati = true
                            this.dialog_cod_lotti = false
                        }else{
                            this.snackbar_text = "Codice lotto non presente.";
                            this.snackbar_color = "error";
                            this.snackbar = true;
                        }
                    });
            },
            getCodiciArticolo(){
                if (this.codice_articolo != null &&  this.codice_articolo != ''  ){
                    this.overlay = true
                    let request = {
                        controller: "Logistica",
                        method: "POST",
                        richiesta: {
                            action: "getCodiciArticolo",
                            token: localStorage.getItem("user_token"),
                            id_utente: localStorage.getItem("id"),
                            codice_articolo: this.codice_articolo.trim(),
                            db_name: "geclink_durbiano",
                        },
                    }

                    this.$store.dispatch("api", request).then((res) => {
                        //console.log(res.data.result.lista_articoli)
                        if (res.data.response){
                            this.articoli = res.data.result.lista_articoli
                            this.ViewTableArticoli = true
                            this.ViewInventarioArticolo = false
                            this.ViewTableArticoliInventariati = false
                            this.overlay = false
                            //this.getMagazzinoInventario(this.codice_lotto)
                        }else{
                            this.snackbar_text = "Codice Articolo non presente.";
                            this.snackbar_color = "error";
                            this.snackbar = true;
                            this.overlay = false
                        }
                    });
                }else{
                    this.articoli = []
                    this.articolo = []
                }
            },
            getUbicazioni(){
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getUbicazioni",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    this.ubicazioni = response.data.result.tblubicazioni
                });
            },
            getMagazzinoInventario(item){
                
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getMagazzinoInventario",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        cod_lotto: item,
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    if (response.data != "")
                        this.articoli = response.data.result.magazzinoInventario
                });
            },
            save(item){
                if (this.articolo.codice_articolo != null && this.articolo.quantita != null && this.articolo.quantita > 0 ){
                    this.overlay = true
                    var id_pk
                    this.getNow()
                    this.articolo.data = this.timestamp
                    if (item == 'new')  id_pk = "-1"; else  id_pk = this.articolo.id_magazzino_inventario;
                    var request = {
                        controller: "Logistica",
                        method: "PUT",
                        richiesta: {
                        action: "insertInventario",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        id_magazzino_inventario: id_pk,
                        db_name: "geclink_durbiano",
                        tabella: [this.articolo]
                        }
                    };
                    this.$store.dispatch("api", request).then(() => {
                            window.location.href = '/home/Inventario'
                            this.articoli = []
                            this.overlay = false
                    });
                }else{
                        this.snackbar_text = "Non è possibile salvare: dati incompleti.";
                        this.snackbar_color = "error";
                        this.snackbar = true;
                        this.overlay = false
                }
            },
            getNow() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            },

            getHeadersInventario(){
                this.overlay = true
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getHeadersInventario",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        id_profilo: localStorage.id_profilo,
                        utente: localStorage.nominativo,
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    console.log(response.data.result.headers);
                    this.headers = response.data.result.headers
                    this.inventari = response.datad.result.lista
                    if (localStorage.id_profilo == 3){
                            this.headers.push({ text: 'Actions', value: 'actions', sortable: false })
                    }
                    this.overlay = false
                });
            },


         }

    }
</script>
