<template>
  <v-card>
    <v-card>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="3" id="listener_consuntivi_massimalePersonale">
            <!-- <v-text-field label="Massimale" v-model="massimale_tmp"></v-text-field> -->
            <InputSeparatore
              :decimali_fissi="true"
              :destinazione="'listener_consuntivi_massimalePersonale'"
              :label="'Massimale'"
              :id="'1'"
            />
          </v-col>
          <v-col cols="3" id="listener_consuntivoPersonale">
            <!-- <v-text-field
              disabled
              label="Consuntivo"
              v-model="consuntivo"
            ></v-text-field> -->
            <InputSeparatore
              :decimali_fissi="true"
              :disabled="true"
              :destinazione="'listener_consuntivoPersonale'"
              :label="'Consuntivo'"
              :id="'2'"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers_personale"
              :items="items_personale.filter(f=>f.validita !=0)"
              class="elevation-1 mx-5"
              :single-expand="true"
              :expanded.sync="expanded_fascie_orarie_lavorate"
              height="70vh"
              item-key="id_operatore_dettaglio"
              show-expand
              :key="contatore_personale"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  class="pa-0"
                  style="background-color: white"
                  :colspan="headers.length"
                >
                  <v-data-table
                    class="elevation-3"
                    :headers="
                      headers_fascie_orarie_lavorate.filter(
                        (elem) =>
                          elem.text != 'Modifica' && elem.text != 'Elimina'
                      )
                    "
                    hide-default-footer
                    :items="
                      item.fasce_orarie_lavorate.filter((f) => f.validita != 0)
                    "
                  >
                    <!-- Titolo tabella -->
                    <template v-slot:top>
                      <v-toolbar color="indigo" dense>
                        <v-toolbar-title class="white--text"
                          >Fasce Orarie Lavorate
                        </v-toolbar-title>
                      </v-toolbar>
                    </template>

                    <!-- Colonna dell'importo dell'approvatore -->
                  </v-data-table>
                </td>
              </template>
              <!-- Titolo tabella -->
              <template v-slot:top>
                <v-toolbar color="indigo" dense>
                  <v-toolbar-title class="white--text">
                    Spese del Personale Operativo
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-5"
                        @click="emettiEvento()"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="success"> mdi-floppy </v-icon>
                      </v-btn>
                    </template>
                    <span>Salva personale</span>
                  </v-tooltip> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-5"
                        @click="clickGestisciOperatore(false)"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary"> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Aggiungi Personale</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:[`item.modifica`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="clickModificaOreLavorateOperatore(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">
                        mdi-clock-time-eight-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Ore lavorate</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.elimina`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="clickEliminaOperatore(item)"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-dialog v-model="dialog_dettaglio_operatore">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            <span>Dettaglio operatore: <b><i>{{operatore_sel.nominativo}}</i></b></span>  
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-row class="mt-0">
                  <v-col class="pb-0 pt-0" cols="3"
                    ><div>
                      <span>
                        <b><i>Costo Orario Feriale:</i></b>
                      </span>
                    </div></v-col
                  >
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Mattino"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_feriale_mattino"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Pomeriggio"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_feriale_pomeriggio"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Notte"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_feriale_notte"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0 pt-0" cols="3"
                    ><div>
                      <span>
                        <b><i>Costo Orario Festivo:</i></b>
                      </span>
                    </div></v-col
                  >
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Mattino"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_festivo_mattino"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Pomeriggio"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_festivo_pomeriggio"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Notte"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_festivo_notte"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0 pt-0" cols="3"
                    ><div>
                      <span>
                        <b><i>Costo Orario Prefestivo:</i></b>
                      </span>
                    </div></v-col
                  >
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Mattino"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_prefestivo_mattino"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Pomeriggio"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_prefestivo_pomeriggio"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Notte"
                      type="number"
                      readonly
                      v-model="operatore_sel.costo_orario_prefestivo_notte"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="mt-0">
                  <v-col class="pb-0 pt-0" cols="3"
                    ><div>
                      <span>
                        <b><i>Ore Lavoro Feriali:</i></b>
                      </span>
                    </div></v-col
                  >
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Mattino"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_feriali_mattino"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Pomeriggio"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_feriali_pomeriggio"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Notte"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_feriali_notte"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0 pt-0" cols="3"
                    ><div>
                      <span>
                        <b><i>Ore Lavoro Festive:</i></b>
                      </span>
                    </div></v-col
                  >
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Mattino"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_festive_mattino"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Pomeriggio"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_festive_pomeriggio"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Notte"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_festive_notte"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0 pt-0" cols="3"
                    ><div>
                      <span>
                        <b><i>Ore Lavoro Prefestive:</i></b>
                      </span>
                    </div></v-col
                  >
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Mattino"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_prefestive_mattino"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Pomeriggio"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_prefestive_pomeriggio"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="3">
                    <v-text-field
                      label="Notte"
                      type="number"
                      readonly
                      v-model="operatore_sel.ore_lavorate_prefestive_notte"
                      dense
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialog_gestione_operatore" width="50%" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Aggiungi operatore</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaOperatore()" small v-bind="attrs" v-on="on">
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi operatori</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="dialog_gestione_operatore = false"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col></v-col>
              <v-col cols="5">
                <v-autocomplete
                  v-model="attivita_selezionata"
                  :items="attivita"
                  item-text="titolo"
                  item-value="id_attivita"
                  label="Attivita"
                  @change="selezionaOperatori()"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  :disabled="attivita_selezionata.length == 0"
                  v-model="operatori_selezionati"
                  :items="attivita_selezionata.operatori"
                  item-text="nominativo"
                  item-value="id_operatore"
                  multiple
                  chips
                  deletable-chips
                  label="Operatori"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_selezione_fascie_orarie">
      <v-data-table
        fixed-header
        :headers="headers_fascie_orarie_lavorate"
        height="40vh"
        :items="
          operatore_sel.fasce_orarie_lavorate.filter(
            (elem) => elem.validita != 0
          )
        "
        :key="contatore_fascie"
      >
        <template v-slot:top>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              Fasce Orarie Lavorate
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiFasciaOrariaLavorata()"
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi Fascia Oraria</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    (dialog_selezione_fascie_orarie = false),
                      calcolaConsuntivo()
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="error">mdi-close</v-icon></v-btn
                >
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:[`item.modifica`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickModificaFasciaOrariaLavorata(item)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Modifica</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.elimina`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="item.disabilita_eliminazione"
                @click="clickEliminaFasciaOrariaLavorata(item)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Elimina</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialog_ore_lavorate" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Ore lavorate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaFasciaOrariaLavorata()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva Fascia Oraria</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="dialog_ore_lavorate = false"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="2">
                <v-autocomplete
                  v-model="fascia_oraria_sel"
                  :items="operatore_sel.fasce_orarie_disponibili"
                  :item-text="
                    (item) =>
                      `${item.fascia_oraria_inizio} - ${item.fascia_oraria_fine}`
                  "
                  item-value="id_anagrafica_tipologia_fascia_oraria"
                  label="Fascia Oraria"
                  @change="adattaGiorniEDeltaOre()"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  :disabled="
                    fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                    null
                  "
                  v-model="fascia_oraria_sel.des_tipo_giorno"
                  label="Tipo giorno"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  :disabled="
                    fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                    null
                  "
                  readonly
                  v-model="fascia_oraria_sel.des_tipo_maggiorazione"
                  label="Tipo maggiorazione"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :disabled="
                    fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                    null
                  "
                  readonly
                  :items="fascia_oraria_sel.giorni"
                  :item-text="(item) => `${item.des_giorno}`"
                  v-model="fascia_oraria_sel.giorni"
                  multiple
                  label="Giorni fascia"
                  item-value="id_giorno"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :disabled="
                    fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                    null
                  "
                  readonly
                  type="number"
                  v-model="fascia_oraria_sel.costo"
                  label="Costo Orario"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="menu_giorno_lavorato"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :disabled="
                    fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                    null
                  "
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fascia_oraria_sel.giorno_lavorato"
                      label="Giorno lavorato"
                      readonly
                      :disabled="
                        fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                        null
                      "
                      v-on="on"
                    >
                      <template v-slot:append>
                        <v-icon color="indigo">mdi-calendar-today</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    :allowed-dates="allowedDates(this.giorni_disponibili)"
                    color="indigo"
                    style="width: 100%"
                    v-model="fascia_oraria_sel.giorno_lavorato"
                    @input="menu_giorno_lavorato = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :min="0"
                  type="number"
                  :disabled="
                    fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                    null
                  "
                  v-model="fascia_oraria_sel.ore_lavorate"
                  :max="delta_ore"
                  label="Ore lavorate"
                  :key="contatore_key_ore"
                  @input="riadattaOreLavorate(fascia_oraria_sel.ore_lavorate)"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="conferma_elimina_fascia_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="margin-top: 20px; overflow-y: auto">
          Sei sicuro di voler eliminare questa fascia oraria?
          <v-divider class="my-5"></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickConfermaEliminaFascia()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="conferma_elimina_fascia_dialog = false"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="conferma_elimina_operatore_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="margin-top: 20px; overflow-y: auto">
          Sei sicuro di voler eliminare questo operatore?
          <v-divider class="my-5"></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickConfermaEliminaOperatore()"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="conferma_elimina_operatore_dialog = false"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import InputSeparatore from "@/components/TOOLS/InputSeparatore.vue";
import OperatorDetail from "@/classes/OperatorDetail.js";
import FasciaOraria from "@/classes/FasciaOraria.js";
export default {
  name: "ConsuntiviPersonale",
  components: {
    InputSeparatore,
  },
  props: {
    consuntivo_personale: { type: Array, default: () => {} },
    id_consuntivo: { type: Number, default: 0 },
    massimale: { type: Number, default: 0 },
    attivita: { type: Array, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      consuntivo: 0,
      massimale_tmp: 0,
      dialog_dettaglio_operatore: false,
      operatore_sel: new OperatorDetail(),
      dialog_gestione_operatore: false,
      dialog_ore_lavorate: false,
      modifica_operatore: false,
      attivita_selezionata: [],
      operatori_selezionati: [],
      expanded_fascie_orarie_lavorate: [],
      fascia_oraria_sel: new FasciaOraria(),
      dialog_selezione_fascie_orarie: false,
      contatore_fascie: 0,
      menu_giorno_lavorato: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      giorni_disponibili: [],
      indice_fascia_sel: null,
      conferma_elimina_fascia_dialog: false,
      conferma_elimina_operatore_dialog: false,
      delta_ore: null,
      contatore_personale: 0,
      contatore_key_ore: 0,
      indice_operatore_sel: null,

      // Modal
      // Array
      headers_fascie_orarie_lavorate: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        {
          text: "Fascia Oraria Inizio",
          sortable: false,
          value: "fascia_oraria_inizio",
        },
        {
          text: "Fascia Oraria Fine",
          sortable: false,
          value: "fascia_oraria_fine",
        },
        { text: "Ore Lavorate", sortable: false, value: "ore_lavorate" },
        { text: "Giorno", sortable: false, value: "giorno_lavorato" },
        { text: "Costo orario", sortable: false, value: "costo" },
        { text: "Costo effettivo", sortable: false, value: "costo_effettivo" },
        {
          text: "Elimina",
          sortable: false,
          value: "elimina",
        },
      ],
      headers_personale: [
        {
          text: "Modifica",
          sortable: false,
          value: "modifica",
        },
        { text: "Operatore", sortable: false, value: "nominativo" },
        { text: "Tipologia", sortable: false, value: "nome_tipologia" },
        { text: "Attivita", sortable: false, value: "des_attivita" },
        { text: "Cod. Attivita", sortable: false, value: "cod_attivita" },
        {
          text: "Tot. Costo Operatore(€)",
          sortable: false,
          value: "importo_personale",
        },
        {
          text: "Elimina",
          sortable: false,
          value: "elimina",
        },
      ],
      items_personale: [],
    };
  },
  computed: {},
  watch: {
    attivita: {
      immediate: true,
      handler() {
        console.log("attivita:", this.attivita);
      },
    },
    consuntivo_personale: {
      immediate: true,
      handler() {
        this.items_personale = this.consuntivo_personale;
        this.calcolaConsuntivo();
        if (this.consuntivo != 0) {
          let messaggio = {
            detail: {
              numero: this.consuntivo,
            },
            src: null,
          };

          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("numero_iniziale", messaggio);

          // Consegno alla mia destinazione l'evento
          document
            .getElementById("listener_InputSeparatore2")
            .dispatchEvent(evento);
        }
      },
    },
    massimale: {
      immediate: true,
      handler() {
        this.massimale_tmp = this.massimale;
        if (this.massimale_tmp != 0) {
          console.log("MASSIMALE TMP PERSONALE:", this.massimale_tmp);
          let messaggio = {
            detail: {
              numero: this.massimale_tmp,
            },
            src: null,
          };

          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("numero_iniziale", messaggio);

          // Consegno alla mia destinazione l'evento
          document
            .getElementById("listener_InputSeparatore1")
            .dispatchEvent(evento);
        }
      },
    },
  },
  methods: {
    emettiEvento(){
      this.$emit("salvaConsuntivo","donotclose");
    },
    riadattaOreLavorate(arg) {
      console.log("arg", parseFloat(arg), "delta", this.delta_ore);
      if (parseFloat(arg) > this.delta_ore) {
        this.fascia_oraria_sel.ore_lavorate = this.delta_ore.toString();
        console.log("nsflnm:", this.fascia_oraria_sel.ore_lavorate);
        this.contatore_key_ore++;
      }
    },
    allowedDates(a) {
      return (val) => a.includes(new Date(val).getDay());
    },
    clickEliminaFasciaOrariaLavorata(arg) {
      this.indice_fascia_sel = this.operatore_sel.fasce_orarie_lavorate.indexOf(
        arg
      );
      this.conferma_elimina_fascia_dialog = true;
    },
    clickConfermaEliminaFascia() {
      this.operatore_sel.fasce_orarie_lavorate[
        this.indice_fascia_sel
      ].validita = 0;
      this.operatore_sel.importo_personale = 0;
      this.operatore_sel.fasce_orarie_lavorate.forEach((fascia) => {
        if (fascia.validita != 0) {
          this.operatore_sel.importo_personale += fascia.costo_effettivo;
        }
      });
      this.items_personale.forEach((e) => {
        if (
          e.id_operatore_dettaglio == this.operatore_sel.id_operatore_dettaglio
        ) {
          e.importo_personale = this.operatore_sel.importo_personale;
        }
      });
      this.conferma_elimina_fascia_dialog = false;
    },
    clickModificaFasciaOrariaLavorata(arg) {
      this.modifica = true;
      console.log("FASCIA ORARIA SEL:", arg);
      if (arg.id_consuntivo_personale_dettaglio == -1) {
        this.fascia_oraria_sel = Object.assign({}, new FasciaOraria(arg));
        this.indice_fascia_sel = this.operatore_sel.fasce_orarie_lavorate.indexOf(
          arg
        );
        this.adattaGiorniEDeltaOre();
        console.log("INDICE FASCIA:", this.indice_fascia_sel);
        this.giorni_disponibili = [];
        this.fascia_oraria_sel.giorni.forEach((giorno) => {
          this.giorni_disponibili.push(giorno.int_day);
        });
        this.dialog_ore_lavorate = true;
      } else {
        let request = {
          controller: "Manutenzione",
          method: "POST",
          richiesta: {
            action: "getgiornibyfasciaesistente",
            token: localStorage.user_token,
            id_anagrafica_tipologia_fascia_oraria:
              arg.id_anagrafica_tipologia_fascia_oraria,
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          if (res.status === 200) {
            console.log("RES.DATA:", res.data);
            this.fascia_oraria_sel = Object.assign({}, new FasciaOraria(arg));
            this.adattaGiorniEDeltaOre();
            this.fascia_oraria_sel.giorni = res.data.result.giorni;
            this.giorni_disponibili = [];
            this.fascia_oraria_sel.giorni.forEach((giorno) => {
              this.giorni_disponibili.push(giorno.int_day);
            });
            this.dialog_ore_lavorate = true;
          }
        });
      }
    },
    salvaFasciaOrariaLavorata() {
      if (
        this.fascia_oraria_sel.costo == null ||
        this.fascia_oraria_sel.costo == "" ||
        this.fascia_oraria_sel.giorno_lavorato == "" ||
        this.fascia_oraria_sel.giorno_lavorato == null ||
        this.fascia_oraria_sel.ore_lavorate == "" ||
        this.fascia_oraria_sel.ore_lavorate == null
      ) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Compilare tutti i campi prima di salvare la fascia oraria!";
        this.snackbar_text_color = "white";
      } else {
        if (this.modifica == false) {
          this.fascia_oraria_sel.riga = this.operatore_sel.riga;
          this.fascia_oraria_sel.costo_effettivo =
            parseFloat(this.fascia_oraria_sel.costo) *
            parseFloat(this.fascia_oraria_sel.ore_lavorate);
          this.fascia_oraria_sel.id_consuntivo_personale_dettaglio = -1;
          this.fascia_oraria_sel.id_consuntivo_personale = -1;
          let fascia_tmp = Object.assign({}, this.fascia_oraria_sel);
          console.log("FASCIA_TMP", fascia_tmp);
          this.operatore_sel.fasce_orarie_lavorate.push(fascia_tmp);
          this.operatore_sel.importo_personale = 0;
          this.operatore_sel.fasce_orarie_lavorate.forEach((fascia) => {
            if (fascia.validita != 0) {
              this.operatore_sel.importo_personale += fascia.costo_effettivo;
            }
          });
          this.items_personale.forEach((e) => {
            if (
              e.id_operatore_dettaglio ==
              this.operatore_sel.id_operatore_dettaglio
            ) {
              e.importo_personale = this.operatore_sel.importo_personale;
            }
          });
        } else {
          this.fascia_oraria_sel.costo_effettivo =
            parseFloat(this.fascia_oraria_sel.costo) *
            parseFloat(this.fascia_oraria_sel.ore_lavorate);
          this.operatore_sel.importo_personale = 0;
          this.operatore_sel.fasce_orarie_lavorate.forEach((fascia) => {
            if (fascia.validita != 0) {
              this.operatore_sel.importo_personale += fascia.costo_effettivo;
            }
          });
          this.items_personale.forEach((e) => {
            if (
              e.id_operatore_dettaglio ==
              this.operatore_sel.id_operatore_dettaglio
            ) {
              e.importo_personale = this.operatore_sel.importo_personale;
            }
          });
          this.fascia_oraria_sel.id_consuntivo_personale_dettaglio == undefined
            ? -1
            : this.fascia_oraria_sel.id_consuntivo_personale_dettaglio;
          let fascia_tmp = Object.assign({}, this.fascia_oraria_sel);
          console.log("FASCIA_TMP", fascia_tmp);
          this.operatore_sel.fasce_orarie_lavorate[
            this.indice_fascia_sel
          ] = fascia_tmp;
        }
        this.contatore_personale++;
        console.log("OPERATORE SEL:", this.operatore_sel);
        this.dialog_ore_lavorate = false;
        this.fascia_oraria_sel = new FasciaOraria();
      }
    },
    clickAggiungiFasciaOrariaLavorata() {
      this.modifica = false;
      this.fascia_oraria_sel = Object.assign({}, new FasciaOraria());

      this.dialog_ore_lavorate = true;
    },
    clickModificaOreLavorateOperatore(arg) {
      let request = {
        controller: "Manutenzione",
        method: "POST",
        richiesta: {
          action: "getfascieorarieoperatoreselezionato",
          token: localStorage.user_token,
          id_anagrafica_tipologia: arg.id_anagrafica_tipologia,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          console.log("RES.DATA:", res.data);
          this.dialog_selezione_fascie_orarie = true;

          this.operatore_sel = Object.assign({}, new OperatorDetail(arg));
          this.operatore_sel.fasce_orarie_disponibili =
            res.data.result.fasce_orarie;
          console.log("OPERATORE SEL:", this.operatore_sel);
        }
      });
    },
    clickEliminaOperatore(arg) {
      this.indice_operatore_sel = this.items_personale.indexOf(arg);
      this.conferma_elimina_operatore_dialog = true;
    },
    clickConfermaEliminaOperatore() {
      this.items_personale[this.indice_operatore_sel].validita = 0;
      this.items_personale[
        this.indice_operatore_sel
      ].fasce_orarie_lavorate.forEach((e) => {
        e.validita = 0;
      });
      this.conferma_elimina_operatore_dialog = false;
    },
    adattaGiorniEDeltaOre() {
      this.giorni_disponibili = [];
      this.operatori_selezionati = [];
      this.fascia_oraria_sel.giorni.forEach((giorno) => {
        this.giorni_disponibili.push(giorno.int_day);
      });
      if (
        this.fascia_oraria_sel.fascia_oraria_inizio == null ||
        this.fascia_oraria_sel.fascia_oraria_fine == null
      ) {
        return;
      }
      let minuteStart = parseFloat(
        this.fascia_oraria_sel.fascia_oraria_inizio.split(":")[1] / 60
      );
      let minuteEnd = parseFloat(
        this.fascia_oraria_sel.fascia_oraria_fine.split(":")[1] / 60
      );
      let hourStart =
        parseFloat(this.fascia_oraria_sel.fascia_oraria_inizio.split(":")[0]) +
        parseFloat(minuteStart.toFixed(1));
      let hourEnd =
        parseFloat(this.fascia_oraria_sel.fascia_oraria_fine.split(":")[0]) +
        parseFloat(minuteEnd.toFixed(1));
      
      this.delta_ore = parseFloat((hourEnd-hourStart ).toFixed(1));
      console.log("DELTA ORE:", this.delta_ore);
    },
    selezionaOperatori() {
      console.log(
        "ATTIVITA SELEZIONATA:",
        this.attivita_selezionata.id_attivita
      );
      this.items_personale.forEach((e) => {
        if (e.id_attivita == this.attivita_selezionata.id_attivita) {
          this.attivita_selezionata.operatori.forEach((f) => {
            if (e.id_operatore_dettaglio == f.id_operatore_dettaglio) {
              this.operatori_selezionati.push(e);
            }
          });
        }
      });
      console.log("OPERATORI SELEZIONATI:", this.operatori_selezionati);
    },
    salvaOperatore() {
      this.operatori_selezionati.forEach((operatore) => {
        if (
          this.items_personale.filter(
            (f) => f.id_operatore_dettaglio == operatore.id_operatore_dettaglio
          ).length == 0
        ) {
          let operatore_tmp = Object.assign({}, new OperatorDetail(operatore));
          this.items_personale.push(operatore_tmp);
        }
      });
      this.items_personale.forEach((operatore) => {
        operatore.riga = this.items_personale.indexOf(operatore);
      });
      console.log("ITEMS PERSONALE:", this.items_personale);
      this.attivita_selezionata = [];
      this.operatori_selezionati = [];
      this.dialog_gestione_operatore = false;
    },
    clickGestisciOperatore(modifica) {
      this.modifica_operatore = modifica;
      this.dialog_gestione_operatore = true;
    },
    chiudiDialog() {
      this.operatore_sel = Object.assign({}, new OperatorDetail());
      this.dialog_dettaglio_operatore = false;
    },
    apriDialogDettaglioOperatore(item) {
      console.log("ITEM", item);
      this.operatore_sel = Object.assign({}, new OperatorDetail(item));
      this.dialog_dettaglio_operatore = true;
    },
    calcolaConsuntivo() {
      this.consuntivo = 0;
      this.items_personale.forEach((operatore) => {
        this.consuntivo += operatore.importo_personale;
      });
    },
  },
  created() {},
  mounted() {
    let that = this;
    document
      .getElementById("listener_consuntivi_massimalePersonale")
      .addEventListener("nuovo_numero", function (elem) {
        that.massimale_tmp = elem.detail.numero;
      });
    document
      .getElementById("listener_consuntivoPersonale")
      .addEventListener("nuovo_numero", function (elem) {
        that.consuntivo = elem.detail.numero;
      });

    this.calcolaConsuntivo();
  },
};
</script>
<style scoped>
</style>