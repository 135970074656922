var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":false,"persistent":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(Object.keys(_vm.item).length !== 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ml0",attrs:{"color":"#009999"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungere")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"slot":"badge","small":"","icon":_vm.icon_color == 'primary' ? false:true},slot:"badge"},on),[_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"#1a80b6"},attrs:{"color":_vm.icon_color == 'primary' ? '#1a80b6' : _vm.icon_color}},'v-icon',attrs,false),on),[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi "+_vm._s(_vm.nome))])])],1)]}}]),model:{value:(_vm.dialogAggiungiModulo),callback:function ($$v) {_vm.dialogAggiungiModulo=$$v},expression:"dialogAggiungiModulo"}},[_c('div',{staticClass:"main-div ml-auto mr-auto"},[_c('v-card',{staticStyle:{"width":"89vw !important"}},[_c('v-toolbar',{attrs:{"color":_vm.bg_color,"dense":""}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"py-1 d-flex justify-start align-center",attrs:{"cols":"4"}},[_c('v-toolbar-title',{staticClass:"white--text",staticStyle:{"font-size":"unset"}},[_vm._v(" "+_vm._s(_vm.single_item[0].id_tabella == -1 ? "Crea " + _vm.nome : "Modifica " + _vm.nome)+" ")])],1),_c('v-col',{staticClass:"py-1 d-flex justify-center",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.valid,"icon":_vm.icon_color=='primary' ? false:true,"small":""},on:{"click":function($event){return _vm.validate()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.icon_color=='primary' ? 'success' : _vm.icon_color}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Salva")])])],1),_c('v-col',{staticClass:"py-1 d-flex justify-end px-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"small":"","icon":_vm.icon_color=='primary' ? false:true},on:{"click":function($event){_vm.dialogAggiungiModulo = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"depressed":"","color":_vm.icon_color=='primary' ? '#c00000' : _vm.icon_color}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1)],1)],1)],1),_c('v-card-title',[_c('v-container',{staticClass:"py-0 pt-2",attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-start"},_vm._l((_vm.dati),function(dato,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"12"}},_vm._l((dato),function(value,name){return _c('div',{key:name},[(!Array.isArray(value) && dato[name] == '')?_c('v-text-field',{attrs:{"outlined":"","dense":"","value":_vm.single_item[0][name],"rules":name == 'des'
                          ? [
                              _vm.exist
                                ? 'Descrizione già presente in tabella'
                                : true ]
                          : name == 'des_lingua3'
                          ? [
                              _vm.exist2
                                ? 'Codice già presente in tabella'
                                : _vm.single_item[0][name] != null &&
                                  _vm.single_item[0][name].length != 8 &&
                                  _vm.single_item[0][name].length != 0
                                ? 'Il codice deve avere 8 caratteri'
                                : true ]
                          : [],"type":"text","label":_vm.all_label.length > index
                          ? _vm.all_label[index]
                          : (_vm.nome + " " + name),"required":""},model:{value:(_vm.single_item[0][name]),callback:function ($$v) {_vm.$set(_vm.single_item[0], name, $$v)},expression:"single_item[0][name]"}}):(Array.isArray(value))?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","items":_vm.itemsSelected,"item-value":_vm.risorsa_preferenziale ? 'id_personale' : 'id_tabella',"item-text":_vm.risorsa_preferenziale ? function (item) { return ((item.nome) + "   " + (item.cognome)); } : 'des',"placeholder":"Digita per cercare"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{},[_vm._v(_vm._s(value[0].nome)),_c('strong',[_vm._v("* ")])]),_c('a',{staticStyle:{"pointer-events":"all"},on:{"click":function($event){$event.stopPropagation();}}},[(!_vm.risorsa_preferenziale)?_c('Modules',{attrs:{"assistenza":_vm.assistenza,"tipo":value[0].tipo,"nome":value[0].nome,"setaction":value[0].setaction,"getaction":value[0].getaction,"controller":value[0].controller,"dati":value[0].dati,"filter":value[0].filter},on:{"dialogModules":_vm.emitDialog}}):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.single_item[0][value[0].vmodel]),callback:function ($$v) {_vm.$set(_vm.single_item[0], value[0].vmodel, $$v)},expression:"single_item[0][value[0].vmodel]"}}):_vm._e()],1)}),0)}),1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }