var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type == 'file')?_c('v-file-input',{attrs:{"label":_vm.label},on:{"change":_vm.processFile}}):_vm._e(),(_vm.type == 'string')?_c('v-text-field',{attrs:{"label":_vm.label,"type":"text","readonly":_vm.access == 1,"suffix":_vm.showSuffix(_vm.access),"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}}):_vm._e(),(_vm.type == 'number')?_c('v-text-field',{attrs:{"label":_vm.label,"type":"number","readonly":_vm.access == 1,"suffix":_vm.showSuffix(_vm.access),"min":_vm.min,"max":_vm.max,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}}):_vm._e(),(_vm.type == 'textarea')?_c('v-textarea',{attrs:{"label":_vm.label,"suffix":_vm.showSuffix(_vm.access),"readonly":_vm.access == 1,"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}}):_vm._e(),(_vm.type == 'boolean')?_c('v-select',{attrs:{"label":_vm.label,"value":_vm.value,"items":_vm.boolArray},on:{"change":function($event){return _vm.$emit('input', $event)}}}):_vm._e(),(_vm.type == 'enum')?_c('v-select',{attrs:{"label":_vm.label,"value":_vm.value,"items":_vm.items},on:{"change":function($event){return _vm.$emit('input', $event)}}}):_vm._e(),(_vm.type == 'keyvalue')?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.table_items},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"flat":"","dense":"","primary-title":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Headers")]),_c('v-spacer'),_c('Button',{attrs:{"colore_icona":"primary","mdi_icona":"mdi-plus","testo_tooltip":"Aggiornare"},on:{"buttonClick":function($event){_vm.headerdialog = true;
            _vm.createHeaderForm();}}}),_c('v-spacer')],1)]},proxy:true},{key:"item.key",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Key","placeholder":"Key"},model:{value:(item.key),callback:function ($$v) {_vm.$set(item, "key", $$v)},expression:"item.key"}})]}},{key:"item.value",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Value","placeholder":"value"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [_c('Button',{attrs:{"colore_icona":"error","mdi_icona":"mdi-window-close","testo_tooltip":"Cancellare"},on:{"buttonClick":function($event){return _vm.deleteHeader(item)}}})]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }