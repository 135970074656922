<template>
  <v-card>
    <v-card-text class="ma-0 pa-0">
      <v-data-table
        fixed-header
        :headers="headers_operatori"
        height="40vh"
        :items="items_operatori.filter((elem) => elem.validita === 1)"
        :key="contatore_operatore"
      >
        <template v-slot:top>
          <v-toolbar color="indigo" dense>
            <v-toolbar-title class="white--text">
              Dettagli Operatori
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickAggiungiOperatore"
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi Operatore</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.modifica`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickModificaOperatore(item)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Modifica</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.specializzazioni`]="{ item }">
          <span
            class="mr-2"
            v-for="(elem, index) in item.specializzazioni"
            :key="index"
            >{{ elem.des_ambito_manutenzione }};</span
          >
        </template>
        <template v-slot:[`item.elimina`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickEliminaOperatore(item)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Elimina</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="aggiungi_operatore_dialog" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Dettagli Operatore</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaModificheOperatore"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
                ><v-icon color="error">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col cols="3">
              <v-text-field
                label="Nominativo"
                v-model="operatore_sel.nominativo"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="1">
              <v-text-field
                label="Livello"
                v-model="operatore_sel.livello"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Costo Orario (€)"
                v-model="operatore_sel.costo_orario"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Costo Orario Feriale (€)"
                v-model="operatore_sel.costo_orario_feriale"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Costo Orario Prefestivo (€)"
                v-model="operatore_sel.costo_orario_prefestivo"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Costo Orario Festivo (€)"
                v-model="operatore_sel.costo_orario_festivo"
                type="number"
              ></v-text-field>
            </v-col> -->
            <v-col cols="3">
              <v-text-field
                label="Indirizzo"
                v-model="operatore_sel.indirizzo"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Telefono"
                v-model="operatore_sel.telefono"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                label="Tipologia"
                v-model="operatore_sel.id_anagrafica_tipologia"
                :items="anagrafica_sel.tipologie.filter(f=>f.id_anagrafica_tipologia>0)"
                item-text="nome_tipologia"
                item-value="id_anagrafica_tipologia"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Email"
                v-model="operatore_sel.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" align="center" justify="center">
            <!-- <v-col cols="2">
              <v-autocomplete
                @change="
                  tipologia_operatore_lista.forEach((elem) => {
                    if (elem.id_tabella === operatore_sel.id_tipologia) {
                      operatore_sel.des_tipologia = elem.des;
                    }
                  })
                "
                v-model="operatore_sel.id_tipologia"
                :items="tipologia_operatore_lista"
                label="Tipologia Operatore"
                placeholder="Seleziona"
                item-text="des"
                item-value="id_tabella"
                required
              >
                <template #label>
                  <span class="mr-2">Tipologia</span>
                  <span
                    @click.stop
                    style="min-width: 500px; pointer-events: all"
                  >
                    <Modules
                      v-on:dialogModules="gettabelle"
                      tipo="MAN_TIPOLOGIA_OPERATORE"
                      nome="Tipologia operatore"
                      setaction="settabella"
                      getaction="gettabella"
                      controller="Global"
                      :controllaCodice="true"
                      :filter="'validita = 1'"
                      :all_label="['des', 'codice']"
                      :dati="[{ des: '' }, { des_lingua3: '' }]"
                    />
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="operatore_sel.specializzazioni"
                :items="
                  tipo_intervento_lista.filter((elem) => elem.validita === true)
                "
                item-text="des_ambito_manutenzione"
                return-object
                :menu-props="{ maxHeight: '400' }"
                label="Specializzazioni"
                placeholder="Associa"
                multiple
              >
              </v-select>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="conferma_elimina_operatore_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="margin-top: 20px; overflow-y: auto">
          Sei sicuro di voler eliminare questo operatore?
          <v-divider class="my-5"></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickConfermaEliminaOperatore"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickAnnullaEliminaOperatore"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="conferma_chiudi_dettaglio_operatore_dialog"
      width="unset"
    >
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Chiusura</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="margin-top: 20px; overflow-y: auto">
          Sei sicuro di voler chiudere questa finestra senza salvare i dati?
          <v-divider class="my-5"></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="clickConfermaChiudiDettaglioOperatore"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickAnnullaChiudiDettaglioOperatore"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import moment from "moment/moment";
import Worker from "@/classes/Worker.js";

export default {
  name: "AnagraficheDettaglioOperatori",
  components: {
    //Modules: () => import("@/components/TOOLS/Modules"),
  },
  props: {
    anagrafica_sel: { type: Object, default: null },
    tipologie: { type: Array, default: () => [] },
  },
  data() {
    return {
      // Variabili d'appoggio
      operatore_sel: new Worker(),
      tipologia_operatore_lista: [],
      contatore_operatore:0,
      // Modal
      aggiungi_operatore_dialog: false,
      conferma_elimina_operatore_dialog: false,
      conferma_chiudi_dettaglio_operatore_dialog: false,

      // Array
      headers_operatori: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Nominativo", sortable: false, value: "nominativo" },
        { text: "Indirizzo", sortable: false, value: "indirizzo" },
        { text: "Tipologia", sortable: false, value: "nome_tipologia" },
        { text: "Telefono", sortable: false, value: "telefono" },
        { text: "Email", sortable: false, value: "email" },
        // {
        //   text: "Livello",
        //   sortable: false,
        //   value: "livello",
        // },
        // {
        //   text: "Specializzazione",
        //   sortable: false,
        //   value: "specializzazioni",
        // },
        // { text: "Costo Orario", sortable: false, value: "costo_orario" },
        // {
        //   text: "Costo Orario Feriale",
        //   sortable: false,
        //   value: "costo_orario_feriale",
        // },
        // {
        //   text: "Costo Orario Prefestivo",
        //   sortable: false,
        //   value: "costo_orario_prefestivo",
        // },
        // {
        //   text: "Costo Orario Festivo",
        //   sortable: false,
        //   value: "costo_orario_festivo",
        // },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
      items_operatori: [],
      tipo_intervento_lista: [],
    };
  },
  computed: {},
  watch: {
    anagrafica_sel: {
      immediate: true,
      handler() {
        if (this.anagrafica_sel.operatori) {
          this.items_operatori = this.anagrafica_sel.operatori;
          this.items_operatori.forEach((operatore) => {
            operatore.specializzazioni.forEach((s) => {
              s.des_ambito_manutenzione = s.des_specializzazione;
              s.id_ambito_manutenzione = s.id_specializzazione;
            });

            /* Creo un array di confronto che mi serve
             * per il salvataggio, in modo da sapere quali
             * specializzazioni eventualmente sono state tolte. */
            operatore.specializzazioni_confronto = operatore.specializzazioni;
          });
        }
      },
    },
    tipologie: {
      immediate: true,
      handler() {
        console.log("entrato handler delle tipologie");
        this.tipo_intervento_lista = [];

        // Se la prop "tipologie" ha già degli elementi, li uso
        if (this.tipologie.length > 0) {
          this.tipologie.forEach((elem) => {
            if (elem.validita === true) {
              this.tipo_intervento_lista.push(elem);
            }
          });
        } else {
          /* Se non ho ancora elementi all'interno della lista dei tipi intervento per
           * la select, faccio una chiamata gettabelle molto simile a quella di
           * AnagraficheDettaglioTipologia.vue per popolarmi i dati per conto mio,
           * perché vuol dire che il componente fratello non è ancora stato richiamato
           * e non gli ha ancora passato i dati. */
          if (this.tipo_intervento_lista.length === 0) {
            this.gettabelleTipoIntervento();
          }
        }
      },
    },
  },
  methods: {
    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "TIPO_PAGAMENTO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.tipologia_operatore_lista = res.data != undefined ? res.data : [];
      });
    },
    clickAggiungiOperatore() {
      this.gettabelle();
      this.aggiungi_operatore_dialog = true;

      // Faccio un operatore resettato
      this.operatore_sel = new Worker();
    },
    chiudiDialog() {
      this.conferma_chiudi_dettaglio_operatore_dialog = true;
    },
    clickAnnullaEliminaOperatore() {
      this.conferma_elimina_operatore_dialog = false;

      // Faccio un operatore resettato
      this.operatore_sel = new Worker();
    },
    clickAnnullaChiudiDettaglioOperatore() {
      this.conferma_chiudi_dettaglio_operatore_dialog = false;
    },
    clickConfermaChiudiDettaglioOperatore() {
      // Chiudo il dialog d'avvertimento.
      this.conferma_chiudi_dettaglio_operatore_dialog = false;

      // Chiudo il dialog del dettaglio operatore.
      this.aggiungi_operatore_dialog = false;

      // Uso l'operatore resettato per modificare l'operatore_sel
      this.operatore_sel = new Worker();
    },
    clickConfermaEliminaOperatore() {
      this.items_operatori.forEach((o) => {
        if (
          o.id_anagrafica_contatto === this.operatore_sel.id_anagrafica_contatto
        ) {
          o.validita = 0;
        }
      });
      this.$emit("tipologie_aggiornate");
      this.conferma_elimina_operatore_dialog = false;

      // Uso l'operatore resettato per modificare l'operatore_sel
      this.operatore_sel = new Worker();
    },
    clickEliminaOperatore(arg) {
      this.operatore_sel = new Worker(arg);
      this.conferma_elimina_operatore_dialog = true;
    },
    clickModificaOperatore(arg) {
      this.aggiungi_operatore_dialog = true;
      this.gettabelle();
      this.operatore_sel = new Worker(arg);
    },
    gettabelleTipoIntervento() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "MAN_AMBITO_MANUTENTIVO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        let risposta_tmp = [];
        res.data.forEach((elem) => {
          /* Costruisco un oggetto come mi serve per gestire
           * le tipologie di intervento da FE. */
          let obj = {
            id_anagrafica_dettaglio: -1,
            id_anagrafica: this.anagrafica_sel.id_anagrafica,
            des_ambito_manutenzione: elem.des,
            id_ambito_manutenzione: elem.id_tabella,
            validita: false,
          };

          /* Controllo se devo assegnare un id_anagrafica_dettaglio
           * diverso da -1 perché magari l'utente aveva già selezionato
           * quell'ambito manutentivo. */
          if (this.anagrafica_sel.ambiti_manutenzione) {
            this.anagrafica_sel.ambiti_manutenzione.forEach((ambito) => {
              if (
                ambito.id_ambito_manutenzione === obj.id_ambito_manutenzione
              ) {
                obj.id_anagrafica_dettaglio = ambito.id_anagrafica_dettaglio;
                obj.validita = true;
              }
            });
          }

          risposta_tmp.push(obj);
        });

        this.tipo_intervento_lista = [];

        risposta_tmp.forEach((elem) => {
          if (elem.validita === true) {
            this.tipo_intervento_lista.push(elem);
          }
        });
      });
    },
    salvaModificheOperatore() {
      this.anagrafica_sel.tipologie.forEach(e=>{
        if(e.id_anagrafica_tipologia == this.operatore_sel.id_anagrafica_tipologia)
        {
          this.operatore_sel.nome_tipologia = e.nome_tipologia;
        }
      })

      if (this.operatore_sel.id_anagrafica_contatto === -1) {
        /* Assegno un id semi-provvisorio in caso di
         * ulteriori modifiche dell'utente prima del salvataggio. */
        this.operatore_sel.id_anagrafica_contatto = -moment().valueOf();
        this.anagrafica_sel.tipologie.forEach((e) => {
          if (
            e.id_anagrafica_tipologia ==
            this.operatore_sel.id_anagrafica_tipologia
          ) {
            this.operatore_sel.nome_tipologia = e.nome_tipologia;
          }
        });
          this.items_operatori.push(this.operatore_sel);
        
      } else {
        this.items_operatori.forEach((elem) => {
          if (
            elem.id_anagrafica_contatto ===
            this.operatore_sel.id_anagrafica_contatto
          ) {
            elem.email = this.operatore_sel.email;
            elem.id_anagrafica = this.operatore_sel.id_anagrafica;
            elem.id_anagrafica_tipologia = this.operatore_sel.id_anagrafica_tipologia;
            elem.indirizzo = this.operatore_sel.indirizzo;
            elem.livello = this.operatore_sel.livello;
            elem.nome_tipologia = this.operatore_sel.nome_tipologia;
            elem.nominativo = this.operatore_sel.nominativo;
            elem.telefono = this.operatore_sel.telefono;
            elem.validita = this.operatore_sel.validita;
          }
        });
        this.contatore_operatore++;
      }
      this.aggiungi_operatore_dialog = false;

      this.anagrafica_sel.anagrafica_contatti = this.items_operatori
      // Uso l'operatore resettato per modificare l'operatore_sel
      this.operatore_sel = new Worker();
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>