<template>
  <v-card class="mt-5">
    <v-toolbar class="white--text" color="indigo" dense flat>
      <v-toolbar-title>IMPOSTAZIONI</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="gotoDashboard" v-bind="attrs" v-on="on" small>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Vai alla Dashboard</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="" cols="12">
            <v-btn class="elevation-5" @click="clickCausaliScarto" medium>
              <span class="primary--text" style="font-size: 35px"
                >Causali di Scarto</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12" class="my-1">
            <v-btn class="elevation-5" @click="clickCausaliFermo" medium>
              <span class="primary--text" style="font-size: 35px"
                >Causali di Fermo</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn class="elevation-5" @click="clickFaseStandard" medium>
              <span class="primary--text" style="font-size: 35px"
                >Fasi Standard</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn class="elevation-5" @click="clickCicloStandard" medium>
              <span class="primary--text" style="font-size: 35px"
                >Ciclo Standard</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn class="elevation-5" @click="clickRicercaMagazzino" medium>
              <span class="primary--text" style="font-size: 35px"
                >Ricerca Magazzino</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn class="elevation-5" @click="clickMacchinaProtocol" medium>
              <span class="primary--text" style="font-size: 35px"
                >Anagrafica QC Box</span
              >
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn class="elevation-5" @click="clickMacchinaPartProgram" medium>
              <span class="primary--text" style="font-size: 35px"
                >Part Program Config</span
              >
            </v-btn>
          </v-col>
          <!--<v-col cols="12">
            <v-btn class="elevation-5" @click="clickGestioneReparto" medium>
              <span class="primary--text" style="font-size: 35px"
                >Gestione Reparto</span
              >
            </v-btn>
          </v-col>-->
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    gotoDashboard() {
      this.$router.push("/home/production");
    },
    clickCausaliFermo() {
      this.$emit("click_causali_fermo");
    },
    clickCausaliScarto() {
      this.$emit("click_causali_scarto");
    },
    clickCicloStandard() {
      this.$emit("click_ciclo_standard");
    },
    clickFaseStandard() {
      this.$emit("click_fase_standard");
    },
    clickGestioneReparto() {
      this.$emit("click_gestione_reparto");
    },
    clickMacchinaPartProgram() {
      this.$emit("click_macchina_part_program");
    },
    clickMacchinaProtocol() {
      this.$emit("click_macchina_protocol");
    },
    clickRicercaMagazzino() {
      this.$emit("click_ricerca_magazzino");
    },
  },
};
</script>
