<template>
<div>
    <v-dialog content-class="prDialog" hide-overlay style="overflow-x:none;" persistent v-model="dialog" width="1100">
        <template v-slot:activator="{ on }">

            <v-icon color="#1a80b6" v-on="on" class="mdi-icons">mdi-magnify</v-icon>
        </template>

        <v-card>
            <v-row>

                <v-col md="12">

                    <v-card-title style="height:1rem !important;" class="mt-n3 blue-grey darken-2 headline" primary-title>

                        <h6 class="mt-n4 white--text"> Dettagli: K49.3199.2 [PRODUZIONE GENERICA]
                            Lotto[20/F565]
                            8000 pezzi totalli</h6>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs" @click="dialog=false" depressed class="mt-n5 mr-n4" color="#c00000">mdi-window-close</v-icon>
                            </template>
                            <span>Cancellare
                            </span>
                        </v-tooltip>
                    </v-card-title>
                </v-col>

                <v-col md='12'>
                    <v-data-table :hide-default-footer="true" :items="items" :headers="headers">

                        <template v-slot:item.sel="">
                            <v-btn small class="mx-2" tile color="primary">
                                <v-icon dark>mdi-check</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>

                </v-col>

                <v-col md='12'>
                    <v-data-table :hide-default-footer="true" :items="items1" :headers="headers1">

                        <template v-slot:item.dettagli="">
                            <PrelievoModal />
                        </template>
                    </v-data-table>

                </v-col>

                <!--  <div class="mt-4  mb-4 ml-auto">

                    <v-btn dark color="red" class="mt-n2 mr-6" @click="dialog = false">Annulla</v-btn>
                </div>-->

            </v-row>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            headers: [

                {
                    text: "Sel.",
                    value: "sel",
                    sortable: false
                },
                {
                    text: "Lotto",
                    value: "lotto",
                    sortable: false
                },
                {
                    text: "Articolo",
                    value: "articolo",
                    sortable: false
                },
                {
                    text: "Esito Controllo",
                    value: "esito_controllo",
                    sortable: false
                },
                {
                    text: "Esito Qualitá",
                    value: "esito_qualita",
                    sortable: false
                },
                {
                    text: "Q.ta Residua",
                    value: "qty_residua",
                    sortable: false
                },
                {
                    text: "Q.ta Impegnata",
                    value: "qty_impegnata",
                    sortable: false
                },
                {
                    text: "Des. Magazzino",
                    value: "des_magazzino",
                    sortable: false
                },

            ],
            headers1: [

                {
                    text: "Lotto",
                    value: "lotto",
                    sortable: false
                },
                {
                    text: "Q.ta",
                    value: "Q.ta",
                    sortable: false
                },
                {
                    text: "Q.ta Giacenza",
                    value: "qty_giacenza",
                    sortable: false
                },
                {
                    text: "Q.ta Disponible",
                    value: "qty_disponible",
                    sortable: false
                },
                {
                    text: "Q.ta in Produzione",
                    value: "Q.ta_produzione",
                    sortable: false
                },
                {
                    text: "Q.ta in Packaging",
                    value: "qty_packaging",
                    sortable: false
                },

            ],
            items1: [{
                    lotto: "20/A442",
                    qta: "7,4880.00",
                    qty_giacenza: 0,
                    qty_disponible: 14880,
                    qta_produzione: 60.00000,
                    qty_packaging: 0.00,

                },
                {
                    lotto: "20/E227",
                    qta: "200,000.00",
                    qty_giacenza: 0,
                    qty_disponible: 200000,
                    qta_produzione: 0.00,
                    qty_packaging: 0.00,

                }
            ],
            items: [

                {
                    lotto: "20/A442",
                    articolo: "49,93.0021",
                    esito_controllo: "COLLAUDATO",
                    esito_qualita: "ACCETTATO",
                    qty_residua: 6880,
                    qty_impegnata: 60000,
                    des_magazzino: "Magazzino Sede"

                }
            ]
        }
    }

}
</script>
<style lang="scss" >
    .prDialog{
        overflow-x: hidden !important;
    }
</style>
