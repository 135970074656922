<template>
    <div>
        <v-dialog v-model="SHOW">
            <div class="graph_params">
                <header>
                    <h1>Impostazione parametri</h1>
                    <GraphSVG name="close" @click="SHOW=false"  />
                </header>
                <main>
                    <table>
                       <thead>
                           <tr>
                               <th>Nome</th>
                               <th>Valore</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr v-for="(param,i) in PARAM" :key="i" >
                               <td>{{param.nome}}</td> 
                               <td><input :type="getType(param.tipo)" v-model="param.valore" ></td>
                           </tr>
                       </tbody>                    
                    </table>
                </main>
                <footer>
                   <span @click="SHOW=false">ANNULLA</span>
                   <span @click="save_params">SALVA PARAMETRI</span> 
                </footer>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
    name : 'GraphParametri',
    components : {
        GraphSVG
    },
    data(){
        return{
            SHOW : false,
            NETWORK : null,
            PARAM : [],
        }
    },
    mounted(){
        document.body.addEventListener('graph_params',this.show_params);
        this.NETWORK = this.$store.state.Network;
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_params',this.show_params);
    },
    destroyed(){ window.freeComponent(this); },
    methods : {
        show_params(evt){
            let index = evt.detail.id;
            let that = this;

            let graph_detail_request = {
                controller : 'Graph',
                body : {
                    action: 'getParametriGrafico',
                    id_ds_dettaglio: index,
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : function(response){ 
                        that.PARAM = response.parametri;  
                        that.SHOW = true;                                  
                    }
                }
            }            
            this.NETWORK.AjaxRequest(graph_detail_request);            
        },
        getType(type){
            type = Number(type);
            switch(type){
                case 1:
                    return 'number';
                case 2:
                    return 'date';
                case 0:
                default:
                    return 'text';
            }
        },
        save_params(){
            let that = this;   
            let request = {
                controller : 'Graph',
                body : {
                    action: 'setParametriGrafico',
                    token: localStorage.user_token,
                    parametri: this.PARAM,
                },
                method: 'PUT',
                on : {
                    load : function(){   
                        let msg = new CustomEvent('dashboardrefresh',{detail:null});
                        document.getElementById('quickchart_dashboard_main').dispatchEvent(msg);
                    },
                    error : function(){ alert("Si è verificato un errore durante il salvataggio dei dati"); } 
                }
            }
            that.NETWORK.AjaxRequest(request);
            this.SHOW = false;
        }
    }
}
</script>
<style src="./Parametri.css" scoped></style>