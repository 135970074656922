<template>
  <div>
    <v-dialog
      :scrollable="false"
      persistent
      v-model="dialog_activities"
      width="800"
      v-if="showDialog"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-on="on" v-bind="attrs">
                <v-icon color="primary" v-on="on">mdi-view-list</v-icon>
              </v-btn>
            </template>
            <span>Lista Attivita</span>
          </v-tooltip>
        </div>
      </template>
      <Activities
        :dialogActivities="dialog_activities"
        :showDialog="true"
        :ticket="ticket"
        v-on:dialogActivities="emitCloseDialogActivitiesInterface"
        :assistenza="assistenza"
      ></Activities>
    </v-dialog>
    <Activities
      v-else
      :ticket="ticket"
      :showDialog="false"
      :assistenza="assistenza"
    ></Activities>
  </div>
</template>

<script>
import Activities from "./Activities";

export default {
  name: "ActivitiesInterface",

  data: () => ({
    dialog_activities: false,
  }),
  props: {
    ticket: Object,
    showDialog: Boolean,
    assistenza: Number,
  },
  components: {
    Activities,
  },
  watch: {},
  computed: {},
  methods: {
    emitCloseDialogActivitiesInterface() {
      this.dialog_activities = false;
      this.$emit("emitClose");
    },
  },
};
</script>