<template>
    <div>

    <v-row>
    <v-col md="12">
       <v-data-table :hide-default-footer="true"   
      v-model="selected"
    :single-select="singleSelect"
    item-key="id"
    show-select
    class="elevation-1" :headers="headers">
      
      
      </v-data-table>
    </v-col>
    </v-row>
        
    </div>
</template>


<script>
export default {

    data() {
        return {
            headers:[
                {
          text: "Dettagli",
          value: "dettagli",
          sortable: false
        },
        {
          text: "Lotto Pedana",
          value: "lotto_pedana",
          sortable: false
        },
        {
          text: "Articoli",
          value: "articoli",
          sortable: false
        },
        {
          text: "Imballo",
          value: "imballo",
          sortable: false
        },
        {
          text: "Larghezza",
          value: "larghezza",
          sortable: false
        },
        {
          text: "Lunghezza",
          value: "lunghezza",
          sortable: false
        },
        { 
          text:"Altezza",
          value: "altezza",
          sortable:false
        },
        { 
          text:"Peso",
          value: "peso",
          sortable:false
        },
            { 
          text:"Q.ta Pezzi",
          value: "qty_pezzi",
          sortable:false
        }, 
            { 
          text:"Rimuovi",
          value: "rimuovi",
          sortable:false
        }, 
         { 
          
          value: "rimuovi",
          sortable:false
        }, 
            ]
        }
    },
    
}
</script>


<style css scoped>

</style>