<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      style="z-index: 99"
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar_step = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-data-table
      :items="task_utente.filter((f) => f.validita != 0)"
      :headers="header_tbl_task"
      fixed-header
      class="elevation-3 pt-0"
      no-data-text="Non hai nessun task assegnato!"
      style="border-radius: 5px"
    >
      <template v-slot:top>
        <!-- Toolbar di Myrepair -->
        <v-toolbar style="border-radius: 5px 5px 0px 0px" color="#990033" dense>
          <v-toolbar-title class="white--text">
            Task assegnati
          </v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.modifica`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickModificaRisorsa(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="purple"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Modifica Risorsa</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.elimina`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="clickEliminaRisorsa(item)"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Elimina Risorsa</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog_gestione_risorsa" width="1400" persistent>
      <v-card style="--accent: #990033">
        <v-toolbar dark color="var(--accent)" dense>
          <v-toolbar-title class="white--text">
            Consuntiva Risorsa
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="ConsuntivaRisorsa()"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Consuntiva risorsa</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-1"
                @click="chiudiDialogGestioneRisorsa()"
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="2">
                <v-text-field
                  disabled
                  outlined
                  dense
                  v-model="risorsa.nominativo"
                  label="Risorsa"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  disabled
                  outlined
                  dense
                  label="Attività"
                  v-model="titolo_task"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  label="Percentuale Unità"
                  :min="0"
                  :max="100"
                  v-model.number="risorsa.percentuale_unita"
                  @change="checkPercentualeUnita()"
                  @input="checkPercentualeUnita()"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="Inizio task"
                  v-model="data_inizio_task"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="Fine task"
                  v-model="data_fine_task"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-menu
                  v-model="menu_inizio_lavoro"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="289"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      background-color="#ffffff"
                      dense
                      hide-details
                      label="Inizio Lavoro"
                      outlined
                      readonly
                      color="#990033"
                      style="max-width: 200px"
                      v-model="inizio_lavoro_formattato"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    color="#990033"
                    v-model="risorsa.data_inizio_lavoro"
                    locale="IT"
                    first-day-of-week="1"
                    :min="data_inizio_taks_unf"
                    @change="checkDate()"
                    @input="menu_inizio_lavoro = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-menu
                  v-model="menu_fine_lavoro"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="289"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      background-color="#ffffff"
                      dense
                      hide-details
                      label="Fine Lavoro"
                      outlined
                      :disabled="risorsa.data_inizio_lavoro == ''"
                      readonly
                      color="#990033"
                      style="max-width: 200px"
                      v-model="fine_lavoro_formattato"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    color="#990033"
                    :min="risorsa.data_inizio_lavoro"
                    v-model="risorsa.data_fine_lavoro"
                    locale="IT"
                    first-day-of-week="1"
                    @change="checkDate()"
                    @input="menu_fine_lavoro = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  outlined
                  dense
                  :disabled="
                    risorsa.data_inizio_lavoro == '' ||
                    risorsa.data_fine_lavoro == ''
                  "
                  type="number"
                  label="Ore lavoro"
                  :min="0"
                  :max="delta_ore"
                  @change="checkOre()"
                  @input="checkOre()"
                  v-model.number="risorsa.ore_lavoro"
                  :key="key"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="Inizio Reale task"
                  v-model="data_inizio_reale_task"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="Fine Reale task"
                  v-model="data_fine_reale_task"
                  color="var(--accent)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_conferma_elimina_risorsa"
      width="unset"
      persistent
    >
      <v-card style="--accent: #990033">
        <v-toolbar dark color="var(--accent)" dense>
          <v-toolbar-title style="font-size: 26px" class="white--text">
            Eliminazione risorsa
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-container>
            <v-row>
              <v-col cols="12">
                <span style="font-size: 22px"
                  >Sei sicuro di voler eliminare la risorsa selezionata?</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="4">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      @click="confermaEliminaRisorsa()"
                      icon
                      small
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon large color="var(--accent)">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      @click="dialog_conferma_elimina_risorsa = false"
                      icon
                      small
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon large color="var(--accent)">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RisorsaTask from "@/components/GANTT/Classes/Task/risorsa_task.js";
export default {
  data() {
    return {
      task_utente: [],
      risorsa: new RisorsaTask(),
      dialog_gestione_risorsa: false,
      dialog_conferma_elimina_risorsa: false,
      titolo_task: null,
      data_inizio_task: null,
      data_inizio_taks_unf: null,
      data_fine_task: null,
      data_inizio_reale_task: null,
      data_fine_reale_task: null,
      menu_inizio_lavoro: false,
      overlay: false,
      key: 0,
      key_unita: 0,
      menu_fine_lavoro: false,
      delta_ore: 0,
      snackbar: false,
      indice_risorsa: 0,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      header_tbl_task: [
        { text: "Modifica", sortable: false, value: "modifica" },
        { text: "Nome risorsa", sortable: true, value: "nominativo" },
        { text: "Attività", sortable: true, value: "titolo_task" },
        {
          text: "Unità (%)",
          sortable: true,
          align: "center",
          value: "percentuale_unita",
        },
        { text: "Inizio Task", sortable: true, value: "inizio_task" },
        { text: "Fine Task", sortable: true, value: "fine_task" },
        {
          text: "Inizio Task Reale",
          sortable: true,
          value: "inizio_task_reale",
        },
        { text: "Fine Task Reale", sortable: true, value: "fine_task_reale" },
        {
          text: "Inizio Lavoro",
          sortable: true,
          value: "data_inizio_lavoro_formattata",
        },
        {
          text: "Fine Lavoro",
          sortable: true,
          value: "data_fine_lavoro_formattata",
        },
        { text: "Ore lavoro", sortable: true, value: "ore_lavoro" },
        { text: "Elimina", sortable: false, value: "elimina" },
      ],
    };
  },
  computed: {
    inizio_lavoro_formattato() {
      return this.formattaData(this.risorsa.data_inizio_lavoro);
    },
    fine_lavoro_formattato() {
      return this.formattaData(this.risorsa.data_fine_lavoro);
    },
  },
  watch: {
    "this.risorsa.ore_lavoro": {
      immediate: true,
      handler() {
        if (parseInt(this.risorsa.ore_lavoro) > this.delta_ore)
          this.risorsa.ore_lavoro = this.delta_ore;
      },
    },
  },
  methods: {
    getCheckTask() {
      let request = {
        controller: "global",
        method: "POST",
        richiesta: {
          action: "getchecktask",
          token: localStorage.user_token,
          id_utente: localStorage.id,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$store.state.task_validi = res.data.result.task_validi;
            this.$store.state.task_scaduti = res.data.result.task_scaduti;
          } 
        })
        .catch((e) => {
          if (e.response.status == 400) {
            this.snackbar = true;
            this.message_snackbar = "Errore : " + e.response.status;
            this.caricamento_dati = false;
          }
          console.log(e);
        });
    },
    clickEliminaRisorsa(item) {
      this.dialog_conferma_elimina_risorsa = true;
      this.risorsa = Object.assign({}, new RisorsaTask(item));
      // this.task_utente.forEach((e) => {
      //   if ((e.id_risorsa = item.id_risorsa)) {
      //     this.indice_risorsa= this.task_utente.indexOf(e);
      //   }
      // });
    },
    confermaEliminaRisorsa() {
      this.risorsa.validita = 0;
      this.dialog_conferma_elimina_risorsa = false;
      this.ConsuntivaRisorsa(2);
    },
    ConsuntivaRisorsa(arg = 1) {
      this.overlay = true;
      let request = {
        controller: "gantt",
        method: "PUT",
        richiesta: {
          action: "setconsuntivorisorsa",
          token: localStorage.user_token,
          risorsa: [this.risorsa],
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.dialog_gestione_risorsa = false;
          this.getTaskUtente(arg);
          this.getCheckTask(localStorage.id);
        }
      });
    },
    checkOre() {
      if (this.risorsa.ore_lavoro > this.delta_ore) {
        this.risorsa.ore_lavoro = this.delta_ore;
        this.key++;
      } else if (this.risorsa.ore_lavoro < 0) {
        this.risorsa.ore_lavoro = 0;
        this.key++;
      }
    },
    checkPercentualeUnita() {
      if (this.risorsa.percentuale_unita > 100) {
        this.risorsa.percentuale_unita = 100;
        this.key_unita++;
      } else if (this.risorsa.percentuale_unita < 0) {
        this.risorsa.percentuale_unita = 0;
        this.key++;
      }
    },

    checkDate() {
      if (this.risorsa.data_inizio_lavoro > this.risorsa.data_fine_lavoro) {
        this.risorsa.data_fine_lavoro = this.risorsa.data_inizio_lavoro;
      }
      const date1 = new Date(this.risorsa.data_inizio_lavoro);
      const date2 = new Date(this.risorsa.data_fine_lavoro);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays);
      this.delta_ore =
        parseInt(diffDays) == 0 ? 24 : (parseInt(diffDays) + 1) * 24;
      this.checkOre();
    },
    formattaData(data) {
      return data != ""
        ? new Date(data).toLocaleDateString("it-IT", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : "";
    },
    clickModificaRisorsa(item) {
      this.risorsa = Object.assign({}, new RisorsaTask(item));
      this.task_utente.forEach((e) => {
        if (e.id_task == this.risorsa.id_task) {
          let tmp = [];
          tmp = e.inizio_task.split("/");
          this.data_inizio_taks_unf = tmp[2] + "-" + tmp[1] + "-" + tmp[0];
          this.titolo_task = e.titolo_task;
          this.data_inizio_task = e.inizio_task;
          this.data_fine_task = e.fine_task;
          this.data_inizio_reale_task = e.inizio_task_reale;
          this.data_fine_reale_task = e.fine_task_reale;
        }
      });
      console.log(this.risorsa);
      this.dialog_gestione_risorsa = true;
    },
    chiudiDialogGestioneRisorsa() {
      this.dialog_gestione_risorsa = false;
    },
    formatDate(date) {
      const year = String(date.getFullYear()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      // const hours = String(date.getHours()).padStart(2, "0");
      // const minutes = String(date.getMinutes()).padStart(2, "0");
      // const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getTaskUtente(arg = 0) {
      var request = {
        controller: "Gantt",
        method: "POST",
        richiesta: {
          action: "gettaskutente",
          token: localStorage.user_token,
          id_personale: localStorage.id,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.overlay = false;
          if (arg == 1) {
            this.snackbar = true;
            this.snackbar_background = "green";
            this.snackbar_icon = "mdi-check-outline";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Consutivo effettuato con successo";
            this.snackbar_text_color = "white";
          } else if (arg == 2) {
            this.snackbar = true;
            this.snackbar_background = "green";
            this.snackbar_icon = "mdi-check-outline";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Eliminazione avvenuta con successo";
            this.snackbar_text_color = "white";
          }
          this.task_utente = res.data.result.task_risorsa;
          this.task_utente.forEach((e) => {
            e.inizio_task = new Date(e.inizio_task).toLocaleDateString(
              "it-IT",
              {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }
            );
            e.fine_task = new Date(e.fine_task).toLocaleDateString("it-IT", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            e.inizio_task_reale = new Date(
              e.inizio_task_reale
            ).toLocaleDateString("it-IT", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            e.fine_task_reale = new Date(e.fine_task_reale).toLocaleDateString(
              "it-IT",
              {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }
            );
            if (e.data_inizio_lavoro != null) {
              e.data_inizio_lavoro_formattata = new Date(
                e.data_inizio_lavoro
              ).toLocaleDateString("it-IT", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
               e.data_inizio_lavoro = this.formatDate(new Date(e.data_inizio_lavoro));
            }
            if (e.data_fine_lavoro != null) {
              e.data_fine_lavoro_formattata = new Date(
                e.data_fine_lavoro
              ).toLocaleDateString("it-IT", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              });
              e.data_fine_lavoro = this.formatDate(new Date(e.data_fine_lavoro));
            }
          });
        }
        console.log("task_utente", this.task_utente);
      });
    },
  },
  mounted() {
    this.overlay = true;
    this.getTaskUtente();
  },
};
</script>

