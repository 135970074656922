<template>
  <div>
    <!-- @focus="focus_input" -->
    <v-text-field
      v-if="!visibilita_disabilitata"
      :append-icon="append_icon"
      :append-outer-icon="append_outer_icon"
      :autofocus="autofocus_input"
      :background-color="background_color"
      :class="classe_input"
      :clear-icon="clearable_input == true && clear_icon != '' ? clear_icon : '$clear'"
      :clearable="clearable_input"
      :color="color_input"
      :counter="counter_input"
      :dark="dark_input"
      :dense="dense_input"
      :disabled="permessi_disabilitati != undefined ? permessi_disabilitati : readonly"
      :filled="filled_input"
      :flat="flat_input"
      :hide-details="hide_details_input"
      :id="id_input"
      :label="obbligatorio ? label_input + '*' : label_input"
      :placeholder="obbligatorio ? palceholder_input + '*' : palceholder_input"
      :max="max_input"
      :min="min_input"
      :outlined="outlined_input"
      :prepend-icon="prepend_icon"
      :prepend-inner-icon="prepend_inner_icon"
      :rules="new_rules"
      :single-line="single_line"
      :solo-inverted="solo_inverted"
      :style="stile_input"
      :type="type_input"
      v-model="model"
      v-on:keydown.enter.prevent="keyEnter"
      @click:append="iconClick"
      @click:append-outer="iconClick"
    ></v-text-field>
  </div>
</template>
<script>
import { decrypt } from "@/components/TOOLS/FormComponentPermissions/PermissionCrypto.js";
export default {
  name: "TextField",
  components: {},
  props: {
    append_icon: {
      type: String,
      default: "",
    },
    append_outer_icon: {
      type: String,
      default: "",
    },
    autofocus_input: {
      type: Boolean,
      default: false,
    },
    background_color: {
      type: String,
      default: "",
    },
    classe_input: {
      type: String,
      default: "",
    },
    clear_icon: {
      type: String,
      default: "",
    },
    clearable_input: {
      type: Boolean,
      default: false,
    },
    color_input: {
      type: String,
      default: "",
    },
    counter_input: {
      type: Number,
      default: undefined,
    },
    dark_input: {
      type: Boolean,
      default: false,
    },
    dense_input: {
      type: Boolean,
      default: false,
    },
    filled_input: {
      type: Boolean,
      default: false,
    },
    flat_input: {
      type: String,
      default: "",
    },
    focus_input: {
      type: Boolean,
      default: false,
    },
    hide_details_input: {
      type: Boolean,
      default: false,
    },
    idForm: {
      type: String,
      default: "",
    },
    id_input: {
      type: String,
      default: "",
    },
    idOggetto: {
      type: String,
      default: "",
    },
    label_input: {
      type: String,
      default: "",
    },
    max_input: {
      type: Number,
      default: undefined,
    },
    min_input: {
      type: Number,
      default: undefined,
    },
    outlined_input: {
      type: Boolean,
      default: false,
    },
    palceholder_input: {
      type: String,
      default: "",
    },
    prepend_icon: {
      type: String,
      default: "",
    },
    prepend_inner_icon: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules_input: {
      type: Array,
      default: () => [],
    },
    single_line: {
      type: Boolean,
      default: false,
    },
    solo_inverted: {
      type: Boolean,
      default: false,
    },
    stile_input: {
      type: String,
      default: "",
    },
    type_input: {
      type: String,
      default: "text",
    },
    vmodel: {},
  },
  data() {
    return {
      // Variabili d'appoggio
      model: "",
      permessi_disabilitati: undefined,
      visibilita_disabilitata: false,
      obbligatorio: false,
      // Modal
      // Array
    };
  },
  computed: {
    new_rules() {
      var rules = [];
      if (this.obbligatorio) {
        if (this.rules_input.length == 0) {
          rules.push((v) => v == null || v.length > 0 || "Campo obbligatorio");
        } else {
          rules = this.rules_input;
          rules.push((v) => v == null || v.length > 0 || "Campo obbligatorio");
        }
      } else {
        rules = this.rules_input;
      }
      return rules;
    },
  },
  watch: {
    vmodel: {
      immediate: true,
      handler() {
        this.model = this.vmodel;
      },
    },
    model: {
      immediate: true,
      handler() {
        this.$emit("changeText", this.model);
      },
    },
  },
  methods: {
    iconClick(){
      this.$emit("iconClick");
    },
    keyEnter() {
      this.$emit("keyEnter");
    },
  },
  created() {},
  async mounted() {
    var matrice = await decrypt();
    if (JSON.parse(matrice) != null) {
      JSON.parse(matrice).forEach((element) => {
        if (
          element["subform_id"] != undefined &&
          element["nome_oggetto"] != undefined &&
          element["subform_id"].trim() == this.idForm.toUpperCase() &&
          element["nome_oggetto"].trim() == this.idOggetto.toUpperCase()
        ) {
          this.permessi_disabilitati = element["flag_readonly"] == 1;
          this.visibilita_disabilitata = element["flag_hide"] == 1;
          this.obbligatorio = element["flag_obbligatorio"] == 1;
        }
      });
    }
  },
};
</script>
<style scoped></style>
