<template>
  <v-tooltip
    :bottom="direzione_tooltip == 'bottom'"
    :left="direzione_tooltip == 'left'"
    :right="direzione_tooltip == 'right'"
    :top="direzione_tooltip == 'top'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!visibilita_disabilitata"
        v-on="on"
        v-bind="attrs"
        @click="buttonClick()"
        :class="classe_bottone"
        :color="colore_bottone"
        :dark="dark_bottone"
        :disabled="disabilitato || permessi_disabilitati"
        :light="light_bottone"
        :style="stile_bottone"
        :small="small_bottone"
      >
        <v-icon
          :color="colore_icona"
          :class="classe_icona"
          :dark="dark_icona"
          :style="stile_icona"
          :size="size_icon"
          >{{ mdi_icona }}</v-icon
        >{{ testo_bottone }}
      </v-btn>
    </template>
    <span>{{ testo_tooltip }}</span>
  </v-tooltip>
</template>
<script>
import {
  decrypt,
} from "@/components/TOOLS/FormComponentPermissions/PermissionCrypto.js";
export default {
  name: "Button",
  components: {},
  props: {
    classe_bottone: {
      type: String,
      default: "",
    },
    classe_icona: {
      type: String,
      default: "",
    },
    colore_bottone: {
      type: String,
      default: "",
    },
    colore_icona: {
      type: String,
      default: "",
    },
    dark_bottone: {
      type: Boolean,
      default: false,
    },
    dark_icona: {
      type: Boolean,
      default: false,
    },
    direzione_tooltip: {
      type: String,
      default: "bottom",
    },
    disabilitato: {
      type: Boolean,
      default: false,
    },
    idForm: {
      type: String,
      default: "",
    },
    idOggetto: {
      type: String,
      default: "",
    },
    light_bottone: {
      type: Boolean,
      default: false,
    },
    mdi_icona: {
      type: String,
      default: "mdi-floppy",
    },
    size_icon: {
      type: String,
      default: undefined,
    },
    small_bottone: {
      type: Boolean,
      default: true,
    },
    stile_bottone: {
      type: String,
      default: "",
    },
    stile_icona: {
      type: String,
      default: "",
    },
    testo_bottone: {
      type: String,
      default: "",
    },
    testo_tooltip: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      permessi_disabilitati: false,
      visibilita_disabilitata: false,
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {},
  methods: {
    buttonClick() {
      this.$emit("buttonClick");
    },
  },
  created() {
  },
  async mounted() {
    var matrice =await decrypt();
    if (JSON.parse(matrice) != null) {
      JSON.parse(matrice).forEach(
        (element) => {
          if (
            element["subform_id"] == this.idForm.toUpperCase() &&
            element["nome_oggetto"] == this.idOggetto.toUpperCase()
          ) {
            this.permessi_disabilitati = element["flag_readonly"] == 1;
            this.visibilita_disabilitata = element["flag_hide"] == 1;
          }
        }
      );
    }
  },
};
</script>
<style scoped>
</style>