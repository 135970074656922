//import moment from "moment/moment";
export default class Questionario {
    constructor(arg = undefined) {
      if (arg != undefined) {
        const campi = this.setControlli();
        campi.forEach((c) => {
          this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
        });
      } else {
        const campi = this.setControlli();
        campi.forEach((c) => {
          this[c.nome] = c.default_val;
        });
      }
    }
  
    // Metodo per resettare tutte i campi
    reset() {
      const campi = this.setControlli();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  
    setControlli() {
      const campi = [
        { nome: "cod_articolo", default_val: "" },
        { nome: "cod_checklist", default_val: "" },
        { nome: "date_ins", default_val: "" },
        { nome: "date_mod", default_val: "" },
        { nome: "des_checklist", default_val: "" },
        { nome: "des_impianto", default_val: "" },
        { nome: "des_mercato", default_val: "" },
        { nome: "des_motori", default_val: "" },
        { nome: "fine_collaudo", default_val: "" },
        { nome: "id_articolo", default_val: null },
        { nome: "id_checklist", default_val: -1 },
        { nome: "id_utente", default_val: null },
        { nome: "nominativo", default_val: "" },
        { nome: "validita", default_val: 1 },
        { nome: "aree", default_val: [] },
      ];
      return campi;
    }
  }