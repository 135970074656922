<template>
  <v-app id="geolocation_listener">
    <v-card class="pa-6">
      <v-toolbar color="indigo" dense>
        <v-toolbar-title class="white--text">Geolocalizzazione</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on"
              ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
            >
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>

      <div id="map"></div>

      <GmapMap
        ref="mapRef"
        name="d"
        :center="center"
        :zoom="15"
        :clickable="true"
        @click="clickMap"
        style="width: 100%; height: 70vh"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="{
            lat: m.latitudine,
            lng: m.longitudine,
          }"
          :clickable="true"
          :optimezed="true"
          :animation="2"
          @click="openInfoWindow(m)"
          :icon="setMarkerIcon()"
        />

        <GmapInfoWindow
          @closeclick="closeInfoWindow()"
          @domready="setInfoWindow"
          :position="{
            lat: selectedMarker.latitudine,
            lng: selectedMarker.longitudine,
          }"
          ref="infoWindow"
          v-if="show_info_window === true"
        >
          <v-card
            style="margin: 0px; padding: 0px"
            v-show="setted_info_window === true"
          >
            <v-toolbar color="indigo" dense>
              <v-toolbar-title class="white--text">
                Info Impianto
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      @click="clickChiudiInfoWindow"
                      v-on="on"
                      v-bind="attrs"
                      class="btn-c"
                    >
                      <v-icon class="mt-1" color="error">mdi-close</v-icon>
                    </div>
                  </template>
                  <span>Chiudi</span>
                </v-tooltip>
              </div>
              <!-- <v-btn @click="clickChiudiInfoWindow" small>
                <v-icon color="error">mdi-close</v-icon>
              </v-btn> -->
            </v-toolbar>
            <div class="container">
              <div class="infoWindow">
                <v-row class="ma-1 mb-2">
                  <h3 class="infoWindowData">
                    Mezzo : {{ selectedMarker.des_macchina }}
                  </h3>
                </v-row>
                <v-row class="ma-1 mb-2">
                  <h3 class="infoWindowData">
                    Tipologia: {{ selectedMarker.des }}
                  </h3>
                </v-row>
                <v-row class="ma-1 mb-2">
                  <h3 class="infoWindowData">
                    Latitudine: {{ selectedMarker.latitudine }}
                  </h3>
                </v-row>
                <v-row class="ma-1 mb-5">
                  <h3 class="infoWindowData">
                    Longitutdine: {{ selectedMarker.longitudine }}
                  </h3>
                </v-row>

                <div class="divider"></div>
                <v-row align="center" justify="center">
                  <div class="button">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          @click="clickModifica(selectedMarker)"
                          v-on="on"
                          v-bind="attrs"
                          class="btn"
                        >
                          <v-icon class="mt-1" color="primary"
                            >mdi-pencil</v-icon
                          >
                        </div>
                      </template>
                      <span>Modifica</span>
                    </v-tooltip>
                  </div>

                  <div class="divider"></div>

                  <div class="button">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          @click="clickElimina(selectedMarker)"
                          v-on="on"
                          v-bind="attrs"
                          class="btn"
                        >
                          <v-icon class="mt-1" color="error">mdi-delete</v-icon>
                        </div>
                      </template>
                      <span>Elimina</span>
                    </v-tooltip>
                  </div>
                </v-row>
              </div>
            </div>
          </v-card>
        </GmapInfoWindow>
      </GmapMap>
    </v-card>

    <!-- DIALOG CREA MARKER  -->
    <v-dialog width="400" v-model="dialog_crea_marker">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text"> Aggiungi Impianto</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="salvaMarker(marker)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="chiudiDialogMarker" small v-bind="attrs" v-on="on">
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="ma-1 mt-3"
                hide-details
                dense
                v-model="marker.des_macchina"
                label=" Nome Impianto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                class="ma-1"
                :items="tipos"
                v-model="marker.id_tipo_get_macchina"
                dense
                label="Descrizione Tipo"
                item-text="des"
                item-value="id_tabella"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="ma-1"
                v-model="marker.latitudine"
                readonly
                hide-details
                dense
                label="Latitudine"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="ma-1"
                v-model="marker.longitudine"
                readonly
                hide-details
                dense
                label="Longitudine"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOG MODIFIA MARKER  -->
    <v-dialog width="400" v-model="dialog_edita_marker">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text"> Modifica Impianto</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="modificaMarker(markerEdit)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="chiudiDialogMarkerEdit"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>

        <v-container v-if="markerEdit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="ma-1 mt-3"
                hide-details
                dense
                v-model="markerEdit.des_macchina"
                label="Nome Impianto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="ma-1"
                hide-details
                v-model="markerEdit.des"
                dense
                label="Descrizione Tipo"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="ma-1"
                v-model="markerEdit.latitudine"
                readonly
                hide-details
                dense
                label="Latitudine"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="ma-1"
                v-model="markerEdit.longitudine"
                readonly
                hide-details
                dense
                label="Longitudine"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- DIALOG ELIMINA MARKER  -->
    <v-dialog width="300" v-model="dialog_conferma_eliminazione">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title>
            <span class="white--text">Conferma Eliminazione</span>
          </v-toolbar-title>
        </v-toolbar>

        <v-container>
          <v-row class="ma-4">
            Vuoi eliminare
            {{ selectedMarker ? selectedMarker.des_macchina : null }}?
          </v-row>

          <v-divider></v-divider>
          <v-row class="ma-0 pa-0">
            <v-col style="text-align: right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="annullaEliminazione"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="error"> mdi-close </v-icon>
                  </v-btn>
                </template>
                <span>Annulla</span>
              </v-tooltip>
            </v-col>
            <v-col></v-col>
            <v-col style="text-align: left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="confermaElimina"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="success"> mdi-check </v-icon>
                  </v-btn>
                </template>
                <span>Conferma</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import GmapMap from "vue2-google-maps/dist/components/map.vue";

const scaledSize = { width: 40, height: 48, f: "px", b: "px" };

class Marker {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "des", default_val: "" },
      { nome: "des_macchina", default_val: "" },
      { nome: "id_macchina", default_val: -1 },
      { nome: "id_tipo_get_macchina", default_val: null },
      { nome: "latitudine", default_val: 0 },
      { nome: "longitudine", default_val: 0 },
      { nome: "validita", default_val: 1 },
    ];

    return campi;
  }
}

export default {
  name: "Geolocation",
  components: {
    GmapMap,
  },
  data() {
    return {
      btn: document.getElementById("btnBlur"),
      center: {
        lat: 44.25550870571865,
        lng: 7.78652462496758,
      },
      marker: new Marker(),
      markers: [],
      tipos: [],
      dialog_crea_marker: false,
      dialog_edita_marker: false,
      setted_info_window: false,
      show_info_window: false,
      dialog_conferma_eliminazione: false,
      selectedMarker: new Marker(),
      markerEdit: null,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getImpianti();
    this.getTipo();
  },
  methods: {
    annullaEliminazione() {
      this.markerEdit = null;
      this.dialog_conferma_eliminazione = false;
    },
    clickChiudiInfoWindow() {
      this.$refs.infoWindow.$emit("closeclick");
    },
    confermaElimina() {
      this.dialog_conferma_eliminazione = false;
      this.$refs.infoWindow.$emit("closeclick");
      this.markerEdit.validita = 0;
      this.setImpianto(this.markerEdit);

      this.selectedMarker = new Marker();
    },
    chiudiDialogMarker() {
      this.marker = new Marker();
      this.dialog_crea_marker = false;
    },
    chiudiDialogMarkerEdit() {
      let marker_tmp = {
        id_macchina: -1,
        des_macchina: "",
        des: "",
        id_tipo_get_macchina: null,
        latitudine: 0,
        longitudine: 0,
        validita: 1,
      };
      this.markerEdit = Object.assign({}, marker_tmp);
      this.dialog_edita_marker = false;
    },
    clickElimina(selectedMarker) {
      this.dialog_conferma_eliminazione = true;
      this.markerEdit = new Marker(selectedMarker);
    },
    clickModifica(selectedMarker) {
      this.markerEdit = new Marker(selectedMarker);
      this.dialog_edita_marker = true;
    },
    closeInfoWindow() {
      this.selectedMarker = new Marker();
      this.show_info_window = false;
      this.setted_info_window = false;
    },
    clickMap(i) {
      if (
        this.selectedMarker.latitudine == 0 &&
        this.selectedMarker.longitudine == 0
      ) {
        this.marker.latitudine = i.latLng.lat();
        this.marker.longitudine = i.latLng.lng();
        this.dialog_crea_marker = true;
      }
    },
    openInfoWindow(m) {
      this.selectedMarker = new Marker(m);
      this.show_info_window = true;
    },
    getImpianti() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getmacchineprato",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.markers = res.data.result.macchine;
        this.markers.forEach((e) => {
          e.latitudine = parseFloat(e.latitudine);
          e.longitudine = parseFloat(e.longitudine);
        });
        console.log(this.markers);
      });
    },
    getTipo() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "TIPO_GET_MACCHINA",
          filter: "cod = 'PRTNVS' AND validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.tipos = res.data;
      });
    },
    modificaMarker(markerEdit) {
      this.setImpianto(markerEdit);
      this.dialog_edita_marker = false;
      this.$refs.infoWindow.$emit("closeclick");

      this.markerEdit = new Marker();
    },
    salvaMarker(marker) {
      this.setImpianto(marker);

      this.dialog_crea_marker = false;

      this.marker = new Marker();
    },
    setImpianto(arg) {
      let arr = [];
      arr.push(arg);

      let richiesta = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setmacchineprato",
          token: localStorage.user_token,
          macchine: arr,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        this.getImpianti();
      });
    },
    setInfoWindow() {
      // Nascondo il pulsante standard di chiusura della infowindow
      document.getElementsByClassName(
        "gm-ui-hover-effect"
      )[0].style.opacity = 0;

      // Azzero i padding della infowindow
      document.getElementsByClassName("gm-style-iw")[0].style.paddingBottom =
        "0px";
      document.getElementsByClassName("gm-style-iw")[0].style.paddingLeft =
        "0px";
      document.getElementsByClassName("gm-style-iw")[0].style.paddingRight =
        "0px";
      document.getElementsByClassName("gm-style-iw")[0].style.paddingTop =
        "0px";
      document.getElementsByClassName("gm-style-iw-d")[0].style.overflow =
        "hidden";

      // Registro di avere finito la configurazione della infowindow
      this.setted_info_window = true;
    },
    setMarkerIcon() {
      return {
        url: require("../../assets/iconsMap/gps.png"),
        scaledSize,
      };
    },
  },
};
</script>

<style scoped>
.divider {
  width: 60px;
}
.btn {
  background-color: white;
  height: 32px;
  border-radius: 4px;
  box-shadow: 1px 2px 3px 0.5px rgb(172, 172, 172);
  text-align: center;
}
.btn:hover {
  transform: scale(1);
  display: block;
  background-color: #e9e6e6;
}
.btn-c {
  background-color: white;
  height: 30px;
  width: 45px;
  border-radius: 4px;
  text-align: center;
}
.btn-c:hover {
  transform: scale(1);
  display: block;
  background-color: #e9e6e6;
}
.button {
  width: 60px;
  margin: 10px;
}
</style>

