<template>
  <Articolo
    :back_as_landing_page="true"
    class="pa-5"
    :rotta_back="'/home/electrical'"
    style="border-radius: 0px"
  />
</template>
<script>
import Articolo from "@/views/PRODUCTION/Articolo/Articolo.vue";
export default {
  name: "EMArticolo",
  components: {
    Articolo,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>