<template>
  <div id="listener_immagine_conversazione">
    <v-container fluid>
      <v-row class="aling-center justify-center">
        <v-col cols="12" class="d-flex align-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab>
                <v-badge
                  bordered
                  bottom
                  :color="colorStatus"
                  dot
                  offset-x="10"
                  offset-y="10"
                >
                  <v-avatar size="40">
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                    ></v-img>
                  </v-avatar>
                </v-badge>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                      alt="John"
                    />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ nominativo }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      des_profilo
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-col class="d-flex" cols="12">
                <v-select
                  class="custom"
                  style="border-style: none"
                  :items="status"
                  item-value="name"
                  :hide-details="true"
                  v-model="changeState"
                >
                  <template slot="selection" slot-scope="data">
                    <v-icon size="10" class="mr-2" :color="data.item.color"
                      >mdi-circle</v-icon
                    >
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-icon size="10" class="mr-2" :color="data.item.color"
                      >mdi-circle</v-icon
                    >
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-card>
          </v-menu>
          <span class="pl-2">{{ stateLabel }}</span>
        </v-col>
        <v-col cols="12" md="8">
          <v-autocomplete
            style="width: 70%; margin: auto"
            v-model="destinatario"
            class="sendMessage"
            :disabled="disabled"
            :items="utenti.filter((el) => el.id_personale != id_utente)"
            :item-text="(item) => `${item.nome} - ${item.cognome}`"
            item-value="id_personale"
            dense
            filled
            flat
            label="Filled"
          ></v-autocomplete>
          <v-btn
            fab
            small
            v-if="!chiamataInArrivo && CONNECTION == null"
            class="white--text"
            color="green"
            :disabled="destinatario == '' || disabled"
            @click="callFun"
            ><v-icon>mdi-phone</v-icon></v-btn
          >
          <v-btn
            fab
            small
            v-if="chiamataInArrivo"
            class="white--text mr-5"
            color="green"
            @click="responseFun(true)"
            :disabled="disabled"
            ><v-icon>mdi-phone-in-talk</v-icon></v-btn
          >
          <v-btn
            fab
            small
            v-if="chiamataInArrivo || rispostaChiamata"
            class="white--text"
            color="red"
            @click="responseFun(false)"
            :disabled="disabled"
            ><v-icon>mdi-phone-hangup</v-icon></v-btn
          >
          <v-btn
            fab
            small
            v-if="rispostaChiamata"
            class="ml-5"
            @click="changePermission(!microphone, video)"
            ><v-icon>{{
              microphone ? "mdi-microphone" : "mdi-microphone-off"
            }}</v-icon></v-btn
          >
          <v-btn
            fab
            small
            v-if="rispostaChiamata"
            class="ml-5"
            @click="changePermission(microphone, !video)"
            ><v-icon>{{ video ? "mdi-video" : "mdi-video-off" }}</v-icon></v-btn
          >
          <!-- <v-btn
            fab
            small
            v-if="rispostaChiamata"
            class="ml-5"
            @click="startCapture()"
            ><v-icon>mdi-monitor-share</v-icon></v-btn
          > -->
          <v-btn
            fab
            small
            v-if="rispostaChiamata"
            class="ml-5"
            @click="screenshot()"
            ><v-icon>mdi-monitor-screenshot</v-icon></v-btn
          >
          <!-- <v-btn
            fab
            small
            v-if="rispostaChiamata"
            class="ml-5"
            @click="openImage()"
            ><v-icon>mdi-image-edit</v-icon></v-btn
          > -->
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="6">
          <video
            autoplay="true"
            id="me"
            style="width: inherit"
            muted="muted"
          ></video>
        </v-col>
        <v-col cols="6">
          <video autoplay="true" id="te" style="width: inherit"></video>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="rispostaChiamata">
          <Controllo
            ref="controlloScreen"
            @click_indietro="clearArrrayScreen"
            :destinazione="'listener_immagine_conversazione'"
            :disegni_iniziali="disegni_iniziali"
            :return_array="true"
            :riferimento="0"
            tabella_supporto="disegno"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
<script>
import axios from "axios";
import moment from "moment/moment";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
export default {
  name: "",
  components: {
    Controllo,
  },
  data() {
    return {
      // Variabili d'appoggio
      candidati: [],
      changeState: null,
      chiamataInArrivo: false,
      colorStatus: "deep-purple accent-4",
      connectionUrl: "",
      CONNECTION: null,
      des_profilo: localStorage.des_profilo,
      destinatario: "",
      disabled: true,
      disegni_iniziali: [],
      id_utente: localStorage.id,
      id_websocketuser: null,
      IS_CALLER: false,
      key: null,
      menu: false,
      messaggio: "",
      microphone: true,
      mittente: "",
      nominativo: localStorage.nominativo,
      port: document.location.port ? ":" + document.location.port : "",
      rispostaChiamata: false,
      scheme: document.location.protocol === "https:" ? "wss" : "ws",
      socket: null,
      src: null,
      stateLabel: "Ready to connect...",
      status: [
        { name: "Open", color: "green" },
        { name: "Closed", color: "red" },
      ],
      utenti: [],
      video: true,
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    changeState: {
      immediate: true,
      async handler() {
        if (this.changeState == "Closed") {
          console.log("SOCKET CLOSED chiama closeButtonFun");
          this.closeButtonFun(true);
          this.stateLabel = this.changeState;
        }
        if (this.changeState == "Open") {
          this.connectButtonFun();
          this.stateLabel = this.changeState;
        }
      },
    },
    CONNECTION: {
      immediate: true,
      async handler() {
        if (this.CONNECTION == null || this.CONNECTION == undefined) {
          console.log("CONNECTION null chiama closeConnection");
          this.closeConnection(false);
        }
      },
    },
    stateLabel: {
      immediate: true,
      async handler() {
        this.changeState = this.stateLabel;
      },
    },
  },
  methods: {
    aggiungiImg(messaggio) {
      this.disegni_iniziali = [];
      messaggio.detail.imgs.forEach((element) => {
        this.disegni_iniziali.push({
          disegno: element,
          id_img: moment().valueOf(),
        });
        this.fileDownload(element, "screenshot_modifica");
      });
    },
    callFun() {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        alert("socket not connected");
      }
      this.IS_CALLER = true;
      let formData = new FormData();
      formData.append("action", "callWebSocket");
      formData.append("mittente", localStorage.id);
      formData.append("destinatario", this.destinatario);
      formData.append("key", this.key);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      let that = this;
      axios
        .post(this.$store.state.config.ip + "api/ws", formData, config)
        .then((result) => {
          if (result.status == 204) {
            alert("Utente offline");
          } else {
            let video = document.getElementById("me");
            // video.volume = 0;
            // video.muted = 0;
            let mediaConstraints = {
              audio: true,
              video: true,
            };
            that.createPeerConnection();
            let thet = that;
            navigator.mediaDevices
              .getUserMedia(mediaConstraints)
              .then(function (localStream) {
                video.srcObject = localStream;
                localStream
                  .getTracks()
                  .forEach((track) =>
                    thet.CONNECTION.addTrack(track, localStream)
                  );
              });
          }
        })
        .catch((e) => {
          console.log(e);
          alert(
            "Un altro utente è loggato con questo account e ha un websocket aperto"
          );
        });
    },
    async changePermission(mic, vid) {
      let video = document.getElementById("me");
      const stream = video.srcObject;

      if (vid != this.video) {
        this.video = vid;
        // const canvasTrack = this.makeCanvasTrack(
        //   video.videoWidth,
        //   video.videoHeight
        // );
        stream.getVideoTracks().forEach((t) => {
          t.enabled = !t.enabled;
        });
        // const new_stream = new MediaStream(
        //   stream.getVideoTracks().concat(canvasTrack)
        // );

        // video.srcObject = new_stream;
      }
      if (mic != this.microphone) {
        this.microphone = mic;
        stream.getAudioTracks().forEach((t) => {
          t.enabled = !t.enabled;
        });
      }
    },
    clearArrrayScreen() {
      this.disegni_iniziali = [];
    },
    closeButtonFun(alert) {
      this.chiamataInArrivo = false;

      // if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      if (alert) {
        alert("socket not connected");
      }
      if (this.socket != null) {
        this.socket.close(1000, "Closing from client");
      }
      this.closeConnection(false);
    },
    closeConnection(continueConnection) {
      if (this.socket != null && this.rispostaChiamata != false) {
        this.responseFun(false);
      }

      if (this.CONNECTION != null && !continueConnection) {
        console.log("--> Closing the peer connection");
        this.microphone = true;
        this.IS_CALLER = false;
        this.rispostaChiamata = false;
        var localVideo = document.getElementById("me");
        var remoteVideo = document.getElementById("te");
        console.log(this.CONNECTION);

        // Disconnect all our event listeners; we don't want stray events
        // to interfere with the hangup while it's ongoing.
        this.CONNECTION.ontrack = null;
        this.CONNECTION.onnicecandidate = null;
        this.CONNECTION.oniceconnectionstatechange = null;
        this.CONNECTION.onsignalingstatechange = null;
        this.CONNECTION.onicegatheringstatechange = null;
        this.CONNECTION.onnotificationneeded = null;

        // Stop all transceivers on the connection

        this.CONNECTION.getTransceivers().forEach((transceiver) => {
          transceiver.stop();
        });

        // Close the peer connection

        this.CONNECTION.close();
        this.CONNECTION = null;
      }

      if (this.CONNECTION == null) {
        this.microphone = true;
        this.IS_CALLER = false;
        this.rispostaChiamata = false;
        // Stop the webcam preview as well by pausing the <video>
        // element, then stopping each of the getUserMedia() tracks
        // on it.

        if (localVideo != null && localVideo.srcObject) {
          localVideo.pause();
          localVideo.srcObject.getTracks().forEach((track) => {
            track.stop();
          });
          localVideo.srcObject = null;
        }
        if (remoteVideo != null) {
          remoteVideo.srcObject = null;
        }
      }
    },
    connectButtonFun() {
      this.stateLabel = "Connecting";
      this.socket = new WebSocket(this.connectionUrl);
      let that = this;
      this.socket.onopen = function () {
        that.updateState();
      };

      this.socket.onclose = function () {
        that.updateState();
      };

      this.socket.onerror = this.updateState();

      this.socket.onmessage = function (event) {
        try {
          var dato = JSON.parse(event.data);
          if (dato.key != undefined && dato.socketOpened == true) {
            that.key = dato.key;
          }
          //negoziazione
          if (dato.negoziazione != undefined && dato.negoziazione == true) {
            that.src = dato.src;
            that.mittente = dato.idMittente;

            let localStream = null;
            that.createPeerConnection();

            console.log("SDP REMOTE MITTENTE", dato.src.sdp_mittente);
            let desc = new RTCSessionDescription(
              that.decode(dato.src.sdp_mittente)
            );
            let mediaConstraints = {
              audio: true,
              video: true,
            };

            that.CONNECTION.setRemoteDescription(desc)
              .then(function () {
                return navigator.mediaDevices.getUserMedia(mediaConstraints);
              })
              .then(function (stream) {
                localStream = stream;
                localStream
                  .getTracks()
                  .forEach((track) =>
                    that.CONNECTION.addTrack(track, localStream)
                  );
                let video = document.getElementById("me");
                video.srcObject = localStream;
                // video.volume= 0;
                // video.mouted= 0;
              })
              .then(function () {
                return that.CONNECTION.createAnswer();
              })
              .then(function (answer) {
                console.log("SDP LOCAL DESTIN", answer);
                return that.CONNECTION.setLocalDescription(answer);
              })
              .then(function () {
                that.chiamataInArrivo = true;
                that.setCandidates();
              });
          }
          //risposta
          if (dato.risposta != undefined && dato.risposta == true) {
            that.src.sdp_destinatario = dato.sdp_destinatario;
            let sdp = that.decode(dato.sdp_destinatario);
            if (sdp != null) {
              that.rispostaChiamata = true;
              console.log("connection state", that.CONNECTION.signalingState);
              console.log("SDP REMOTE DESTINATARIO", sdp);
              that.CONNECTION.setRemoteDescription(
                new RTCSessionDescription(sdp)
              ).then(function () {
                that.setCandidates();
              });
            }
          } else if (dato.risposta != undefined && dato.risposta == false) {
            console.log("RISPOSTA FALSE chiama closeConnection");
            that.closeConnection(false);
            alert(dato.messaggio);
          }
          //candidati
          if (dato.candidati != undefined && dato.candidati == true) {
            console.log("CANDIDATI", dato);
            for (let i = 0; i < dato.lista_candidati.length; i++) {
              let cand_decoded = that.decode(dato.lista_candidati[i]);
              if (cand_decoded != null) {
                let candidate = new RTCIceCandidate(cand_decoded);
                if (that.CONNECTION.remoteDescription != null && candidate) {
                  console.log("CAND", candidate);
                  that.CONNECTION.addIceCandidate(candidate);
                }
              }
            }
          }
          //sdp_destinatario
          // if (
          //   dato.sdp_destinatario_value != undefined &&
          //   dato.sdp_destinatario_value == true
          // ) {
          //   console.error("AAAAAAAAAAAAAAAAAAA QUAAAAA");
          //   that.src.sdp_destinatario = dato.sdp_destinatario;
          //   clearInterval(that.intervalSdp);
          //   let sdp = null;
          //   try {
          //     sdp = that.decode(dato.sdp_destinatario);
          //   } catch (error) {
          //     console.error("Errore", error);
          //   }
          //   if (sdp == null) {
          //     console.log("SDPPPPPPPPP REMOTE DESTIN", that.src.sdp_destinatario);
          //     that.CONNECTION.setRemoteDescription(
          //       new RTCSessionDescription(sdp)
          //     ).then(function () {
          //       that.setCandidates();
          //     });
          //   }
          // }
        } catch (ex) {
          console.error("Errore", ex);
        }
      };
    },
    createPeerConnection() {
      this.CONNECTION = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun4.l.google.com:19302" }],
      });

      this.CONNECTION.onicecandidate = this.handleICECandidateEvent;
      this.CONNECTION.oniceconnectionstatechange = this.handleICEConnectionStateChangeEvent;
      this.CONNECTION.onicegatheringstatechange = this.handleICEGatheringStateChangeEvent;
      this.CONNECTION.onnegotiationneeded = this.handleNegotiationNeededEvent;
      // this.CONNECTION.onremovetrack = this.handleRemoveTrackEvent;
      this.CONNECTION.onsignalingstatechange = this.handleSignalingStateChangeEvent;
      this.CONNECTION.ontrack = this.handleTrackEvent;
    },
    decode(data) {
      try {
        return JSON.parse(decodeURIComponent(escape(atob(data))));
      } catch (error) {
        console.errir(error, data);
      }
    },
    encode(data) {
      return btoa(unescape(encodeURIComponent(JSON.stringify(data))));
    },
    fileDownload(downloadUrl, downloadName) {
      let aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = downloadUrl;
      aLink.download = `${downloadName}.jpg`;
      // Trigger click-then remove
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    },
    handleICECandidateEvent(event) {
      let candidate = this.encode(event.candidate);
      let json = {
        action: "add_candidate",
        id_mittente: this.IS_CALLER ? localStorage.id : this.mittente,
        id_destinatario: this.IS_CALLER ? this.destinatario : localStorage.id,
        candidato: candidate,
        caller: this.IS_CALLER.toString(),
        key: this.key,
      };
      this.sendButtonFun(JSON.stringify(json));
    },
    handleICEConnectionStateChangeEvent() {
      console.log(
        "*** ICE connection state changed to " +
          this.CONNECTION.iceConnectionState
      );

      switch (this.CONNECTION.iceConnectionState) {
        case "closed":
        case "disconnected":
        case "failed":
          console.log("CONNECTION FAILD STATE chiama closeConnection");
          this.closeConnection(true);
          break;
      }
    },
    handleICEGatheringStateChangeEvent() {
      console.log(
        "*** ICE gathering state changed to: " +
          this.CONNECTION.iceGatheringState
      );
    },
    handleNegotiationNeededEvent() {
      if (!this.IS_CALLER) {
        return;
      }
      let that = this;
      this.CONNECTION.createOffer()
        .then(function (offer) {
          console.log("SDP LOCAL MITTENTE", offer);
          return that.CONNECTION.setLocalDescription(offer);
        })
        .then(function () {
          let json = {
            id_mittente: that.IS_CALLER ? localStorage.id : that.mittente,
            id_destinatario: that.IS_CALLER
              ? that.destinatario
              : localStorage.id,
            sdp_mittente: that.encode(that.CONNECTION.localDescription),
            action: "negoziazione",
            key: that.key,
          };

          that.sendButtonFun(JSON.stringify(json));

          that.src = {
            id_mittente: that.IS_CALLER ? localStorage.id : that.mittente,
            id_destinatario: that.IS_CALLER
              ? that.destinatario
              : localStorage.id,
            sdp_mittente: that.encode(that.CONNECTION.localDescription),
          };
        });
    },
    handleSignalingStateChangeEvent() {
      console.log(
        "*** WebRTC signaling state changed to: " +
          this.CONNECTION.signalingState
      );
      switch (this.CONNECTION.signalingState) {
        case "closed":
          console.log("CONNECTION CLOSED chiama closeConnection");
          this.closeConnection(false);
          break;
      }
    },
    handleTrackEvent(event) {
      console.log("STREAM te", event.streams[0]);
      document.getElementById("te").srcObject = event.streams[0];

      let that = this;
      var intervalGetCandidates = setInterval(() => {
        if (this.socket == null || this.CONNECTION == null) {
          clearInterval(intervalGetCandidates);
        }
        that.getCandidates();
      }, 5000);
    },
    htmlEscape(str) {
      return str
        .toString()
        .replace(/&/g, "&amp;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    },
    getCandidates() {
      let json = {
        action: "get_candidate",
        id_mittente: this.IS_CALLER ? localStorage.id : this.mittente,
        id_destinatario: this.IS_CALLER ? this.destinatario : localStorage.id,
        caller: this.IS_CALLER.toString(),
        key: this.key,
      };
      this.sendButtonFun(JSON.stringify(json));
    },
    getUsers() {
      let that = this;
      let request = {
        controller: "User",
        body: {
          action: "getutenti",
          token: localStorage.user_token,
        },
        method: "POST",
        on: {
          load: function (data) {
            that.utenti = data.utenti;
          },
        },
        showloading: false,
      };
      window.NETWORK.AjaxRequest(request);
    },
    makeCanvasTrack(width, height) {
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").fillRect(0, 0, width, height);
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "blue";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.arc(95, 50, 40, 0, 2 * Math.PI);
      ctx.stroke();
      return canvas.captureStream(0).getVideoTracks()[0];
    },
    responseFun(risposta) {
      this.chiamataInArrivo = false;
      if (this.rispostaChiamata == false) {
        if (risposta) {
          this.rispostaChiamata = true;
          let sdp_destinatario = this.encode(this.CONNECTION.localDescription);
          let json = {
            id_mittente: this.IS_CALLER ? localStorage.id : this.mittente,
            id_destinatario: this.IS_CALLER
              ? this.destinatario
              : localStorage.id,
            sdp_destinatario: sdp_destinatario,
            risposta: "true",
            action: "set_negoziazione",
            key: this.key,
          };

          this.sendButtonFun(JSON.stringify(json));
          this.src.sdp_destinatario = sdp_destinatario;
        } else {
          let json = {
            id_mittente: this.IS_CALLER ? localStorage.id : this.mittente,
            id_destinatario: this.IS_CALLER
              ? this.destinatario
              : localStorage.id,
            sdp_destinatario: null,
            risposta: "false",
            action: "set_negoziazione",
            key: this.key,
          };

          this.sendButtonFun(JSON.stringify(json));
          console.log("NEGOZIAZIONE FALSE chiama closeConnection");
          this.closeConnection(false);
        }
      } else {
        console.log("CHIAMATA TEMRINATA", this.rispostaChiamata);
        let json = {
          id_mittente: this.IS_CALLER ? localStorage.id : this.mittente,
          id_destinatario: this.IS_CALLER ? this.destinatario : localStorage.id,
          action: "chiamata_terminata",
          key: this.key,
        };
        this.sendButtonFun(JSON.stringify(json));
        this.rispostaChiamata = false;
        console.log("CHIAMATA TERMINATA chiama closeConnection");
        this.closeConnection(false);
      }
    },
    sendButtonFun(messaggio) {
      if (
        this.CONNECTION &&
        !this.socket &&
        this.socket.readyState !== WebSocket.OPEN
      ) {
        console.log(messaggio);
        alert("socket not connected");
      }
      this.socket.send(messaggio);
    },
    setCandidates() {
      for (let i = 0; i < this.candidati.length; i++) {
        let cand_decoded = this.decode(this.candidati[i]);
        if (cand_decoded != null) {
          let candidate = new RTCIceCandidate(cand_decoded);
          if (this.CONNECTION.remoteDescription != null && candidate) {
            console.log("SET CAND", candidate);
            this.CONNECTION.addIceCandidate(candidate);
          }
        }
      }
    },
    screenshot() {
      const video = document.getElementById("te");
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      // Other browsers will fall back to image/png
      var src = canvas.toDataURL("image/png");

      const img = new Image();

      img.src = src;

      let that = this;
      img.onload = function () {
        let svg = that.screenSvg(img);
        that.$refs.controlloScreen.$refs.Visualizzatore.svg.appendChild(svg);

        that.disegni_iniziali.push({
          disegno: src,
          id_img: moment().valueOf(),
        });
      };
    },
    screenSvg(img) {
      let svg = this.$refs.controlloScreen.$refs.Visualizzatore.svg;

      if (img.naturalWidth === img.naturalHeight && img.naturalWidth > 450) {
        let width_nuova = 337.5;
        // Proporzione -> width_nuova : img.naturalWidth = height_nuova : img.naturalHeight
        let height_nuova = (width_nuova * img.naturalHeight) / img.naturalWidth;
        svg.style.width = width_nuova;
        svg.style.height = height_nuova;
      } else if (
        img.naturalWidth > img.naturalHeight &&
        img.naturalWidth > 450
      ) {
        let width_nuova = 450;
        // Proporzione -> width_nuova : img.naturalWidth = height_nuova : img.naturalHeight
        let height_nuova = (width_nuova * img.naturalHeight) / img.naturalWidth;
        svg.style.width = width_nuova;
        svg.style.height = height_nuova;
      } else if (
        img.naturalHeight > img.naturalWidth &&
        img.naturalHeight > 337.5
      ) {
        let height_nuova = 337.5;
        // Proporzione -> width_nuova : img.naturalWidth = height_nuova : img.naturalHeight
        let width_nuova = (height_nuova * img.naturalWidth) / img.naturalHeight;

        svg.style.width = width_nuova;
        svg.style.height = height_nuova;
      } else {
        svg.style.width = img.naturalWidth;
        svg.style.height = img.naturalHeight;
      }
      // Associo l'immagine allo sfondo.
      // that.svg.style.backgroundImage =
      //   'url("' + immagine_da_ridimenionare.src + '")';

      let svgImg = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );

      svgImg.setAttribute("x", 0);
      svgImg.setAttribute("y", 0);
      svgImg.setAttribute("z", -1);
      svgImg.setAttribute("width", svg.style.width);
      svgImg.setAttribute("height", svg.style.height);
      svgImg.setAttribute("href", img.src);
      return svgImg;
    },
    async startCapture(displayMediaOptions) {
      let captureStream = null;

      try {
        captureStream = await navigator.mediaDevices.getDisplayMedia(
          displayMediaOptions
        );
      } catch (err) {
        console.error("Error: " + err);
      }
      return captureStream;
    },
    updateState() {
      if (!this.socket) {
        this.disabled = true;
      } else {
        switch (this.socket.readyState) {
          case WebSocket.CLOSED:
            this.stateLabel = "Closed";
            this.colorStatus = "red accent-4";
            this.disabled = true;
            break;
          case WebSocket.CLOSING:
            this.stateLabel = "Closing...";
            this.colorStatus = "red darken-4";
            this.disabled = true;
            break;
          case WebSocket.CONNECTING:
            this.stateLabel = "Connecting...";
            this.colorStatus = "indigo darken-1";
            this.disabled = true;
            break;
          case WebSocket.OPEN:
            this.stateLabel = "Open";
            this.colorStatus = "green darken-3";
            this.disabled = false;
            break;
          default:
            this.stateLabel =
              "Unknown WebSocket State: " +
              this.htmlEscape(this.socket.readyState);
            this.colorStatus = "brown lighten-2";
            this.disabled = true;
            break;
        }
      }
    },
  },
  created() {},
  mounted() {
    document
      .getElementById("listener_immagine_conversazione")
      .addEventListener("nuova_img", this.aggiungiImg);
    window.addEventListener("beforeunload", this.closeButtonFun(false));
    this.getUsers();
    let url = this.$store.state.config.ip.substring(
      this.$store.state.config.ip.indexOf("/") + 2,
      this.$store.state.config.ip.length - 1
    );
    this.connectionUrl =
      "wss://" + url + "/ws?id_utente=" + localStorage.id + "&key=" + this.key;
    this.connectButtonFun();
  },
  beforeDestroy() {
    // Pulizia evento
    window.removeEventListener("beforeunload", this.closeButtonFun(false));
  },
};
</script>