<template>
    <div>
        <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <v-row>
            <v-col cols="6" align="left">
                <v-text-field
                    v-model="codice_lotto"
                    :rules="[rules.required, rules.counter]"
                    label="Codice Lotto"
                    v-on:keydown.enter.prevent="getarticololotto"
                    outlined
                    clearable
                ></v-text-field>
            </v-col>
            <v-col cols="6" align="left">
                <v-btn
                    class="ma-2" outlined color="indigo"
                    dark
                    @click="getarticololotto"
                >Cerca</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="articolo.codice_articolo" label="Codice Articolo" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="articolo.cod_lotto" label="Cod. Lotto" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="8">
                <v-text-field v-model="articolo.des_articolo" label="Descrizione Articolo" readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.quantita" label="Quantità" :rules="[rules.required,rules.numberRule]" ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6"  md="4">
                <v-select
                :items="ubicazioni"
                item-text='des'
                item-value='id_tabella'
                label="Ubicazione"
                autocomplete
                v-model="articolo.id_ubicazione"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="articolo.note" label="Note" ></v-text-field>
            </v-col>            
            <v-col cols="12" sm="6" md="12" align="center">
                <v-btn outlined color="blue darken-1" text @click="save('new')">Salva</v-btn>
            </v-col>
        </v-row>
        <br>
        <v-row v-if="table_cod_lotto" >
            <v-col cols="12" align="center">
                <v-progress-linear
                color="indigo"
                rounded
                value="100"
                ></v-progress-linear>
                    <br>
                <v-data-table
                    :headers="headers_cod_lotto"
                    :items="articoli"  
                    item-key="id_magazzino_inventario"
                    class="elevation-1"
                    :footer-props="{
                        itemsPerPageOptions: [10, 50, 100], 
                        showFirstLastPage: true,
                    }"
                />
            </v-col>
        </v-row>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>    
</template>
<script>
    export default {
        data() {
            return{
                overlay: false,
                codice_lotto: '', 
                modalTab: "cod_lotto",

                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",

                table_cod_lotto: false,
                timestamp: '',
                id_ubicazione: '', 
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 50 || 'Max 50 Caratteri',
                    numberRule: value => (!isNaN(parseFloat(value)) && value >= 0 && value <= 1000000) || 'Numero intero maggione di zero',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
                articoli:[],
                articolo: { id_magazzino_inventario: '', id_articolo: '', codice_articolo: '', des_articolo: '', id_lotto: '', cod_lotto: '', ubicazione: '', quantita: '', validita: '', data:'',note:''},
                ubicazioni: [],
                headers_cod_lotto: [
                    { text: 'Cod Articolo', value: 'codice_articolo' },
                    { text: 'Descrizione', value: 'des_articolo' },
                    { text: 'Q.ta', value: 'quantita' },
                    { text: 'ID Ubicazione', value: 'id_ubicazione' },
                ],
            }
        },


        mounted() { 
           this.getUbicazioni()
        },
        methods: {  
            getarticololotto(){
                if (this.codice_lotto != null &&  this.codice_lotto != ''  ){
                    let request = {
                        controller: "Logistica",
                        method: "POST",
                        richiesta: {
                            action: "getarticololotto",
                            token: localStorage.getItem("user_token"),
                            id_utente: localStorage.getItem("id"),
                            cod_lotto: this.codice_lotto.trim(),
                            db_name: "geclink_durbiano",
                        },
                    }

                    this.$store.dispatch("api", request).then((res) => {
                        console.log(res.data.result.response)
                        if (res.data.result.response != 'ko'){
                            this.articolo = res.data.result.articoloLotto[0]
                            this.getMagazzinoInventario(this.codice_lotto)
                            this.table_cod_lotto = true
                        }else{
                            this.snackbar_text = "Codice lotto non presente.";
                            this.snackbar_color = "error";
                            this.snackbar = true;
                        }
                    });
                }
            },
            getUbicazioni(){
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getUbicazioni",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    this.ubicazioni = response.data.result.tblubicazioni
                });
            },
            getMagazzinoInventario(item){
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getMagazzinoInventario",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        cod_lotto: item,
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    console.log(response.data.result.magazzinoInventario);
                    this.articoli = response.data.result.magazzinoInventario
                });
            },
            save(item){
                if (this.articolo.codice_articolo != null && this.articolo.quantita != null && !isNaN(this.articolo.quantita) && this.articolo.quantita > 0 ){
                    this.overlay = true
                    var id_pk
                    this.getNow()
                    this.articolo.data = this.timestamp
                    if (item == 'new')  id_pk = "-1"; else  id_pk = this.articolo.id_magazzino_inventario;
                    var request = {
                        controller: "Logistica",
                        method: "PUT",
                        richiesta: {
                        action: "insertInventario",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        id_magazzino_inventario: id_pk,
                        db_name: "geclink_durbiano",
                        tabella: [this.articolo]
                        }
                    };
                    console.log(this.articolo)
                    this.$store.dispatch("api", request).then(() => {
                            window.location.href = '/home/Inventario'
                            this.overlay = false
                    });
                }else{
                        this.snackbar_text = "Non è possibile salvare: dati incompleti o errati.";
                        this.snackbar_color = "error";
                        this.snackbar = true;
                        this.overlay = false
                }
            },
            getNow() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            },
            getHeadersInventario(){
                this.overlay = true
                let request = {
                    controller: "Logistica",
                    method: "POST",
                    richiesta: {
                        action: "getHeadersInventario",
                        token: localStorage.getItem("user_token"),
                        id_utente: localStorage.getItem("id"),
                        id_profilo: localStorage.id_profilo,
                        utente: localStorage.nominativo,
                        db_name: "geclink_durbiano",
                    },
                }
                this.$store.dispatch("api", request).then((response) => {
                    console.log(response.data.result.headers);
                    this.headers = response.data.result.headers
                    this.inventari = response.data.result.lista
                    if (localStorage.id_profilo == 3){
                            this.headers.push({ text: 'Actions', value: 'actions', sortable: false })
                    }
                    this.overlay = false
                });
            },
            


         }

    }
</script>
