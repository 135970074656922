 <template>
  <v-card>
    <v-snackbar :color="colore_snack" v-model="snackbar">
      {{this.messaggio_snackbar}}
      <v-btn color="white" text @click="snackbar = false">Chiudi</v-btn>
    </v-snackbar>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="updateValue">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>GESTIONE PROFILO</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-items>
        <v-btn dark text @click="updateValue">Salva</v-btn>
      </v-toolbar-items>-->
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col md="1" sm="12"></v-col>
        <v-col md="10">
          <v-data-table
            :headers="headers"
            :items="dipendenti"
            class="elevation-1"
            style="overflow-y:auto;max-height:700px;cursor:pointer"
            :footer-props="{ disablePagination : true, disableItemsPerPage : true }"
            :hide-default-footer="true "
            disable-pagination
          >
            <template v-slot:item.stato="{ item }">
              <v-chip :color="setColoreStatoDipendenti(item)" dark>{{ setStatoDipendenti(item) }}</v-chip>
            </template>

            <template v-slot:item.gestione="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="lime darken-1"
                    @click="insertUtenti(item,'modifica')"
                    v-on="on"
                  >
                    <v-icon medium>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Modifica Utente</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col md="1" sm="12"></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    titolo_dialog: "",
    dialog_conferma: false,
    dialog_users: false,
    stati: [
      {
        id_stato: 1,
        testo: "BLOCCATO",
      },
      {
        id_stato: 3,
        testo: "ATTIVO",
      },
      {
        id_stato: 2,
        testo: "SBLOCCATO",
      },
      {
        id_stato: 4,
        testo: "DISATTIVO",
      },
    ],
    stato_sel: null,
    messaggio_snackbar: "",
    colore_snack: "green",
    snackbar: false,
    item_sel: {},
    backup_item: [],
    arrivo: "",
    dipendenti: [],
    headers: [
      {
        text: "Nominativo",
        align: "start",
        sortable: true,
        value: "nominativo",
      },
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Gruppo", value: "cod_personale" },
      { text: "Profilo", value: "des_profilo_utente" },
      { text: "Badge", value: "badge" },

      { text: "cod. Fiscale", value: "cod_fiscale" },
      { text: "Stato", value: "stato" },
      { text: "Azioni", value: "action" },
      { text: "Gestione", value: "gestione" },
    ],
  }),
  components: {},
  watch: {},
  mounted() {
    this.getInfo();
  },
  methods: {
    cancella_autocomplete() {
      this.stato_sel = null;
    },
    handleSelectedFile(convertedData) {
      console.log(convertedData);
    },
    updateValue: function () {
      this.$emit("dialogUtenti", false);
    },
    getInfo() {
      this.values = [];
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "getlistadipendenti",
      //   token: localStorage.user_token,
      //   tipo: "a",
      //   id_utente: localStorage.id,
      // };
      // var data = JSON.stringify(finale);

      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getlistadipendenti",
          token: localStorage.user_token,
          tipo: "a",
          id_utente: localStorage.id,
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((response) => {
        //console.log("settabelle response: ", JSON.stringify(res));
        this.dipendenti = response.data.dipendenti.filter(
          (i) => i.id_personale == parseInt(localStorage.id)
        );
      });
      // axios
      //   .post(this.$store.state.config.ip + "api/Medical", data, config)
      //   .then((response) => {
      //     // JSON responses are automatically parsed.
      //     this.dipendenti = response.data.dipendenti.filter(
      //       (i) => i.id_personale == parseInt(localStorage.id)
      //     );
      //   })
      //   .catch((e) => {
      //     if (e.response.status == 401) {
      //       localStorage.clear();
      //       this.$router.replace("login");
      //     }
      //     console.log(e);
      //   });
    },
    setColoreStatoDipendenti(item) {
      if (item.attivo == 0) {
        return "red";
      } else if (item.blocco_utente == 1) {
        return "orange";
      } else {
        return "green";
      }
    },
    setStatoDipendenti(item) {
      if (item.attivo == 0) {
        return "Disattivo";
      } else if (item.blocco_utente == 1) {
        return "Bloccato";
      } else {
        return "Attivo";
      }
    },
    setUtente(item, arrivo) {
      this.item_sel = item;
      this.arrivo = arrivo;
      var data = {
        arrivo: arrivo,
        item: item,
      };
      this.$emit("confermaUsers", data);
    },
    conferma_azioni(item, arrivo) {
      console.log("arrivato");
      console.log(item);
      if (arrivo == "sblocca") {
        this.dipendenti[this.dipendenti.indexOf(item)].blocco_utente = 0;
      } else if (arrivo == "blocca") {
        this.dipendenti[this.dipendenti.indexOf(item)].blocco_utente = 1;
      } else if (arrivo == "disattiva") {
        this.dipendenti[this.dipendenti.indexOf(item)].attivo = 0;
      } else if (arrivo == "attiva") {
        this.dipendenti[this.dipendenti.indexOf(item)].attivo = 1;
      }
      console.log(this.dipendenti[this.dipendenti.indexOf(item)]);
      this.colore_snack = "green";
      this.messaggio_snackbar = "Azione effettuata con successo!";
      this.snackbar = true;
    },
    errore_azioni() {
      console.log("arrivato");
      this.colore_snack = "red";
      this.messaggio_snackbar =
        "Errore, non è stato possibile effettuare l'azione!";
      this.snackbar = true;
    },
    insertUtenti(item, arrivo) {
      var data = {
        item: item,
        arrivo: arrivo,
      };
      this.$emit("insertUtenti", data);
    },
  },
};
</script>