<template>
    <div style="margin:20px">
        <v-card elevation="0">
            <v-toolbar dense color="primary" dark align="center">
                <v-row>
                    <v-col cols="5" align="left">
                        
                    </v-col>
                    <v-col cols="2">
                        <v-btn small light @click="save">
                            <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>                        
                    </v-col>
                    <v-col cols="4" align="right">

                    </v-col>
                    <v-col cols="1" align="right">
                        <v-btn small light @click="getAziende">
                            <v-icon color="primary">mdi-autorenew</v-icon>
                        </v-btn>
                    </v-col>                    
                </v-row>
            </v-toolbar>
            <v-card-text style="max-height:80vh; overflow-y:scroll;">
            <v-container>
                <v-row align="center">
                    <v-col cols="4" />
                    <v-col cols="4">
                        <v-select :items="aziende" item-text="ragione_sociale" item-value="id_azienda" solo label="Seleziona Azienda" v-model="azienda_sel" @change="getModuli"></v-select>
                    </v-col>    
                    <v-col cols="4">
                        <v-btn @click="show_prompt=true" class="mt-n7" :disabled="azienda_sel<0||azienda_sel==undefined||!loaded">
                            <v-icon class="mr-2">mdi-database-remove</v-icon>
                            Reset Moduli Azienda
                        </v-btn>
                    </v-col>                
                </v-row>
                <main id="tree_view" v-if="loaded==true">
                    <section v-for="(modulo,i) in moduli" :key="modulo.cod_modulo" class="tree_section">
                        <div>                     
                            <img :src="modulo.img_modulo" v-if="modulo.img_modulo!=null">
                            <v-icon data-lazy v-if="modulo.img_modulo==null">mdi-image-off</v-icon>                    
                            <v-checkbox class="tree_checkbox" v-model="modulo.checked"></v-checkbox>
                            <h3>{{modulo.des_modulo}}</h3>
                            <v-btn icon data-move v-if="i>0" @click="move(true,i)" max-height="30" max-width="30">
                                <v-icon >mdi-arrow-up</v-icon> 
                            </v-btn>
                            <v-btn icon data-move v-if="i < moduli.length-1" @click="move(false,i)" max-height="30" max-width="30">
                                <v-icon >mdi-arrow-down</v-icon> 
                            </v-btn>
                        </div>
                        <section class="tree_section">
                            <div style="height:3px" :id="'tree_section_'+i+'_-1'"></div>
                            <div v-for="(modulo_menu,j) in modulo.moduli_menu" :key="modulo_menu.path" :id="'tree_section_'+i+'_'+j">
                                <v-icon data-lazy >{{modulo_menu.icon}}</v-icon>
                                <v-checkbox class="tree_checkbox" v-model="modulo_menu.checked"></v-checkbox>
                                <h4 @mousedown="dragStart(i,j)">{{modulo_menu.text}}</h4>
                                <input type="text" v-model="modulo_menu.des" placeholder="Descrizione" spellcheck="false">
                            </div>
                        </section>
                    </section>
                </main>
            </v-container>
            </v-card-text>
        </v-card>  
        <v-snackbar style="position:fixed;bottom:10px;" dark :color="(alert.error==true)?'error':'light-green darken-2'" v-model="alert.show">
            {{alert.message}}
            <template v-slot:action="{attrs}">
                <v-btn v-bind="attrs" text @click="alert.show=false">Chiudi</v-btn>
            </template>
        </v-snackbar>   
        <v-dialog width="300" v-model="show_prompt" persistent >
            <v-card>
                <v-container>
                    <v-card-text>I moduli ed i relativi permessi dei profili dell'azienda verranno resettati.</v-card-text>
                    <v-card-text>Procedere?</v-card-text>
                </v-container>
                <v-card-actions>
                    <v-btn @click="show_prompt=false" text color="error">Annulla</v-btn>
                    <v-spacer />
                    <v-btn @click="resetAziende" text color="success">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>   
    </div>
</template>
<script>
export default {
    name : 'GestioneModuliAziende',
    data(){
        return {
            alert : {
                show : false,
                message : null,
                error : false
            },
            show_prompt : false,
            aziende : [],
            azienda_sel : -1,
            moduli : [],
            loaded : false,
            NETWORK : null,
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.getAziende();               
    },
    methods:{
        resetAziende(){
            this.show_prompt = false;
            let that = this;
            let request = {
                controller : 'Modulo',
                body : {
                    action : 'resetModuliAzienda',
                    token : localStorage.user_token,
                    id_azienda : this.azienda_sel,
                },
                method: 'PUT',
                on : {
                    load: function () {
                            that.getModuli();
                            that.alert = {
                            message : 'Dati salvati con successo',
                            show : true,
                            error : false,
                        }
                    },
                    error: function(){
                            that.alert = {
                            message : 'Errore durante il salvataggio',
                            show : true,
                            error : true,
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },
        getAziende_(){
            let that = this;
            let request = {
                controller : 'Modulo',
                body : {
                    action : 'getAzienda',
                    token : localStorage.user_token
                },
                method: 'POST',
                on : {
                    load : function(response){
                        that.aziende = [];
                        that.moduli = [];
                        that.azienda_sel = -1;
                        that.aziende = response.azienda;
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },


        getAziende() {
            //let AAA = []
            let request = {
                controller: "Modulo",
                method: "POST",
                richiesta: {
                action: "getAzienda",
                token: localStorage.getItem("user_token"),
                },
            };
            this.$store.dispatch("api", request).then((response) => {
                this.aziende = [];
                this.moduli = [];
                this.azienda_sel = -1;
                this.aziende = response.data.result.azienda;

            });
        }, 

        getModuli_(){
            this.loaded = false;
            let that = this;
            let request = {
                controller : 'Modulo',
                body : {
                    action : 'getModuli',
                    token : localStorage.user_token
                },
                method: 'POST',
                on : {
                    load : function(response){
                        that.moduli = [];
                        try{
                            console.log(response.moduli)
                            that.moduli = response.moduli;
                            for(let i=0;i<that.moduli.length;i++){
                                for(let j=0;j<that.moduli[i].moduli_menu.length;j++){
                                    that.moduli[i].moduli_menu[j].checked = false;
                                    that.moduli[i].moduli_menu[j].id_modulo_menu = -1;
                                    that.moduli[i].moduli_menu[j].pagina = j;
                                }
                                that.moduli[i].checked = false;
                                that.moduli[i].id_modulo = -1;
                                that.moduli[i].ordine = i;
                            }
                            if(that.aziende.length>0){
                                that.getModuliAzienda(that.azienda_sel);
                            }
                        }catch(error){
                            console.log(error);
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },

        getModuli() {
            let request = {
                controller: "Modulo",
                method: "POST",
                richiesta: {
                action: "getModuli",
                token: localStorage.getItem("user_token"),
                },
            };
            this.$store.dispatch("api", request).then((response) => {
                this.moduli = response.data.result.moduli;
                for(let i=0;i<this.moduli.length;i++){
                    for(let j=0;j<this.moduli[i].moduli_menu.length;j++){
                        this.moduli[i].moduli_menu[j].checked = false;
                        this.moduli[i].moduli_menu[j].id_modulo_menu = -1;
                        this.moduli[i].moduli_menu[j].pagina = j;
                    }
                    this.moduli[i].checked = false;
                    this.moduli[i].id_modulo = -1;
                    this.moduli[i].ordine = i;
                }
                if(this.aziende.length>0){
                    this.getModuliAzienda(this.azienda_sel);
                }


            });
        }, 


       getModuliAzienda_(index_azienda){
            //let AAA = []
            let that = this;
            let request = {
                controller: "Modulo",
                method: "POST",
                richiesta: {
                action: "getModuliAzienda",
                token: localStorage.getItem("user_token"),
                id_azienda : index_azienda,
                },
            };
            this.$store.dispatch("api", request).then((response) => {
                console.log(response)
                let moduli_aziende = response.moduli;
                for(let i=0;i<moduli_aziende.length;i++){
                    let modulo_azienda = moduli_aziende[i];                        
                    for(let i_s = 0; i_s<that.moduli.length;i_s++){
                        let modulo = that.moduli[i_s];                            
                        if(modulo.cod_modulo == modulo_azienda.cod_modulo){
                            that.moduli[i_s].checked = (modulo_azienda.validita==1)?true:false;
                            that.moduli[i_s].id_modulo = modulo_azienda.id_modulo;
                            that.moduli[i_s].ordine = modulo_azienda.ordine;
                            for(let j=0; j<modulo_azienda.moduli_menu.length;j++){
                                let modulo_azienda_menu = modulo_azienda.moduli_menu[j];
                                for(let j_s = 0; j_s < modulo.moduli_menu.length; j_s++){
                                    let modulo_menu = modulo.moduli_menu[j_s];
                                    if(modulo_menu.path == modulo_azienda_menu.path){
                                        that.moduli[i_s].moduli_menu[j_s].id_modulo_menu = modulo_azienda_menu.id_modulo_menu;
                                        that.moduli[i_s].moduli_menu[j_s].checked = (modulo_azienda_menu.validita==1)?true:false;
                                        that.moduli[i_s].moduli_menu[j_s].pagina = modulo_azienda_menu.pagina;
                                        that.moduli[i_s].moduli_menu[j_s].des = modulo_azienda_menu.des;
                                    }
                                }
                            }
                            that.moduli[i_s].moduli_menu.sort((a, b) => a.pagina - b.pagina);
                        } 
                    }
                }
                that.moduli.sort((a, b) => a.ordine - b.ordine);              
                that.loaded=true;

            });
        },

        getModuliAzienda(index_azienda){
            let that = this;
            let request = {
                controller : 'Modulo',
                body : {
                    action : 'getModuliAzienda',
                    token : localStorage.user_token,
                    id_azienda : index_azienda,
                },
                method: 'POST',
                on : {
                    load : function(response){
                        try{
                            let moduli_aziende = response.moduli;
                            for(let i=0;i<moduli_aziende.length;i++){
                                let modulo_azienda = moduli_aziende[i];                        
                                for(let i_s = 0; i_s<that.moduli.length;i_s++){
                                    let modulo = that.moduli[i_s];                            
                                    if(modulo.cod_modulo == modulo_azienda.cod_modulo){
                                        that.moduli[i_s].checked = (modulo_azienda.validita==1)?true:false;
                                        that.moduli[i_s].id_modulo = modulo_azienda.id_modulo;
                                        that.moduli[i_s].ordine = modulo_azienda.ordine;
                                        for(let j=0; j<modulo_azienda.moduli_menu.length;j++){
                                            let modulo_azienda_menu = modulo_azienda.moduli_menu[j];
                                            for(let j_s = 0; j_s < modulo.moduli_menu.length; j_s++){
                                                let modulo_menu = modulo.moduli_menu[j_s];
                                                if(modulo_menu.path == modulo_azienda_menu.path){
                                                    that.moduli[i_s].moduli_menu[j_s].id_modulo_menu = modulo_azienda_menu.id_modulo_menu;
                                                    that.moduli[i_s].moduli_menu[j_s].checked = (modulo_azienda_menu.validita==1)?true:false;
                                                    that.moduli[i_s].moduli_menu[j_s].pagina = modulo_azienda_menu.pagina;
                                                    that.moduli[i_s].moduli_menu[j_s].des = modulo_azienda_menu.des;
                                                }
                                            }
                                        }
                                        that.moduli[i_s].moduli_menu.sort((a, b) => a.pagina - b.pagina);
                                    } 
                                }
                            }
                            that.moduli.sort((a, b) => a.ordine - b.ordine);              
                            that.loaded=true;
                        }catch(error){
                            console.log(error);
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },
        save(){
            let moduli = [];
            let moduli_menu = [];
            for(let i=0;i<this.moduli.length;i++){
                let modulo = this.moduli[i];
                modulo.validita = (modulo.checked==true)?1:0;
                modulo.ordine = i;
                modulo.id_azienda = 1;                
                for(let j=0; j<modulo.moduli_menu.length;j++){
                    let modulo_menu = modulo.moduli_menu[j];
                    if(modulo_menu.checked==true){
                        modulo_menu.validita = 1;
                        modulo.validita = 1;
                        modulo.checked = true;
                    }else{
                        modulo_menu.validita = 0;
                    }
                    modulo_menu.pagina = j;
                    modulo_menu.index_reference = modulo.cod_modulo;
                    moduli_menu.push(modulo_menu);
                }
                delete modulo.moduli_menu;
                moduli.push(modulo);
            }
            let that = this;
            let request = {
                controller : 'Modulo',
                body : {
                    action : 'setModuliAzienda',
                    token : localStorage.user_token,
                    moduli : moduli,
                    moduli_menu : moduli_menu,
                    id_azienda : this.azienda_sel,
                },
                method: 'PUT',
                on : {
                    load: function () {
                            that.getModuli();
                            that.alert = {
                            message : 'Dati salvati con successo',
                            show : true,
                            error : false,
                        }
                    },
                    error: function(){
                            that.alert = {
                            message : 'Errore durante il salvataggio',
                            show : true,
                            error : true,
                        }
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
            
        },


        dragStart(i,j){
            let tree_container =  document.getElementById('tree_view');
            document.body.addEventListener('mousemove',this.dragMove);
            document.body.addEventListener('mouseup',this.dragEnd);
            document.body.addEventListener('mouseleave',this.dragEnd);

            let original = document.getElementById('tree_section_'+i+'_'+j);
            let element = original.cloneNode(true);
            element.removeAttribute('id');
            let container = document.createElement('div'); 
            container.setAttribute('class','tree_section');
            
            container.style.position = 'absolute';
            container.appendChild(element);
            tree_container.appendChild(container);

            container.style.top = original.getBoundingClientRect().y-tree_container.getBoundingClientRect().y +tree_container.scrollTop -5+ 'px'; 
            container.style.left='27px';
            container.style.backgroundColor = 'white';
            container.style.padding = '5px 12px';
            container.style.boxShadow = '0 2px 4px -1px rgb(0 0 0 / 20%)';
            container.style.opacity = '0.8';
            container.childNodes[0].style.pointerEvents = 'none';

            document.body.style.cursor = 'grabbing';

            
            this.tree_data = {
                element : container,
                container:tree_container,
                active_element:null,
                cache : Object.assign({},this.moduli[i].moduli_menu[j]),
                position : {i:i,j:j},
            }
            this.moduli[i].moduli_menu.splice(j,1);
        },
        dragMove(event){
            let tree_container = this.tree_data.container;
            this.tree_data.element.style.top = event.clientY-tree_container.getBoundingClientRect().y +tree_container.scrollTop -5+ 'px';

            let path = document.elementsFromPoint(event.clientX, event.clientY);
            for(let i=0;i<path.length;i++){
                let element = path[i];
                if(element.tagName.toLowerCase() == 'body'){return;}        
                let id = element.getAttribute('id');
                if(id==undefined||id==null){continue;}
                if(id.indexOf('tree_section_')<0){continue;}
                let range = Number(id.substring(13).split('_')[0]);
                if(range!=this.tree_data.position.i){return;}       
                if(this.tree_data.active_element!=null){
                    this.tree_data.active_element.style.borderBottom='none';
                }
                element.style.borderBottom = '1px solid black';
                this.tree_data.active_element = element;
            }

        },
        dragEnd(){
            let element = this.tree_data.element;
            document.body.removeEventListener('mousemove',this.dragMove);
            document.body.removeEventListener('mouseup',this.dragEnd);
            document.body.removeEventListener('mouseleave',this.dragEnd);

            document.body.style.cursor = 'auto';

            this.tree_data.container.removeChild(element);      
            let i,j;    
            
            if(this.tree_data.active_element!=null){
                this.tree_data.active_element.style.borderBottom='none';
                let id =  this.tree_data.active_element.getAttribute('id').substring(13).split('_');
                i=Number(id[0]);
                j=Number(id[1]);
            }else{
                i = this.tree_data.position.i;
                j = this.tree_data.position.j;
            }
            this.moduli[i].moduli_menu.splice(j+1,0,this.tree_data.cache);           
            delete this.tree_data;
        },

        move(up,index){
            let clone = Object.assign({},this.moduli[index]);
            if(up){
                if(index == 0){return;}
                this.moduli.splice(index,1);
                this.moduli.splice(index-1,0,clone);
            }else{
                if(index == this.moduli.length-1){return;}
                this.moduli.splice(index,1);
                this.moduli.splice(index+1,0,clone);
            }
        }
    }
}
</script>
<style scoped>
.gestione_mod_az{
  width:100%;
  height:calc(100vh - 48px);
  overflow: hidden;
  position: relative;
  user-select: none;
  white-space: nowrap;
}

.gestione_mod_az > header{
    padding:0;
    margin:0;
    height:100px;
    overflow: hidden;
}

.gestione_mod_az > div{
    position:relative;
    width:100%;
    height:calc(100% - 100px);
    overflow: auto;
}

#tree_view{
  position:relative;
  display: inline-block;
  vertical-align: top;
  min-width:400px;
  text-align: left;
  padding:15px;  
  margin:0 auto;
  transition: margin-left 0.5s linear;
}

#tree_view .tree_section{
  display: block;
  margin-left:12px;
  margin-bottom:10px;
}

#tree_view .tree_section > div{
  height:30px;
  display: block;
  border-radius:3px;
  padding:0 5px;
}
#tree_view .tree_section > div:hover{
    background-color: rgba(0,0,0,0.05);
}
#tree_view .tree_section > div > *{
  display: inline-block;
  vertical-align: middle;  
}
#tree_view .tree_section > div > .tree_checkbox{
  height:30px;
  width:30px;
  margin:0;  
}
#tree_view .tree_section > div > h3{ font-weight: bold;}
#tree_view .tree_section > div > h4{ 
    font-weight: normal;
    cursor: grab;
    width:190px;
}

#tree_view .tree_section > div > *[data-lazy],
#tree_view .tree_section > div > img{
  width:24px;
  margin:0 12px 0 0;
}
#tree_view .tree_section > div > *[data-move]{
    float:right;
}
#tree_view .tree_section > div > input{ 
    outline:none!important;
    border-bottom:1px solid grey;
    appearance: none;
    border-radius: 0;
    width:120px;
    margin-left: 10px;
}
</style>