<template>
  <v-dialog :scrollable="false" persistent v-model="dialog" width="1200">
    <template v-slot:activator="{ on }">
      <div v-if="id_task == -1" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-on="on" v-bind="attrs">
              <v-icon color="primary" v-on="on">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi Task {{ assistenza == 1 ? "Assistenza" : "" }}</span>
        </v-tooltip>
      </div>
      <v-btn
        v-else
        style="background-color: transparent; box-shadow: none; min-width: 15px"
        class="px-0 normal"
        slot="badge"
        v-on="on"
      >
        <v-icon medium class="white--text"> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <div class="main-div ml-auto mr-auto">
      <v-card class="">
        <v-toolbar class="indigo white--text" dense primary-title>
          <v-toolbar-title>
            {{ id_task == -1 ? "Crea Task" : "Modifica Task" }}
            {{ assistenza == 1 ? "Assistenza" : "" }}
            {{ id_task == -1 ? "" : "- " + this.single_item.num_ticket }}
          </v-toolbar-title>
          <v-spacer v-if="id_task != -1"></v-spacer>
          <span v-if="id_task != -1"> del: {{ this.mod_date }}</span>
          <v-spacer></v-spacer>
          <span
            >Stato :
            <v-icon :color="color">{{
              getIcon(this.single_item.id_stato)
            }}</v-icon></span
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!valid"
                @click="validate()"
                small
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Invia Task</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <span>Progetto: {{ single_item.des_progetto }}</span>
          <v-spacer></v-spacer>
          <span>Modulo: {{ single_item.des_modulo }}</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="dialog = false" small>
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="cyan"
          v-if="loading"
          id="progress"
        >
        </v-progress-linear>
        <v-container fluid>
          <v-tabs
            v-model="tab"
            background-color="indigo"
            color="white"
            class="rounded-t"
          >
            <v-tab>Generale</v-tab>
            <!-- <v-tab>Predecessori</v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-form ref="form" lazy-validation>
                <v-container c class="pt-3 pt-md-0" fluid>
                  <v-row class="text-start" v-if="errors.length">
                    <v-col cols="12">
                      <p>
                        <v-alert type="error">
                          <ul>
                            <li v-for="(error, index) in errors" :key="index">
                              {{ error }}
                            </li>
                          </ul>
                        </v-alert>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="9">
                      <v-container>
                        <v-row class="align-end">
                          <v-col cols="12" md="4" class="py-0">
                            <v-text-field
                              dense
                              v-model="single_item.titolo_task"
                              type="text"
                              label="Titolo Task"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" class="py-0">
                            <v-autocomplete
                              dense
                              v-model="int_id_responsabile"
                              :items="utenti"
                              label="Responsabile"
                              item-value="id_personale"
                              :item-text="
                                (item) => `${item.nome} ${item.cognome}`
                              "
                              placeholder="Scrivi per avviare la ricerca"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4" class="py-0">
                            <v-switch
                              v-model="single_item.milestone"
                              label="Milestone"
                            ></v-switch>
                          </v-col>
                          <v-col
                            v-if="id_task != -1"
                            md="2"
                            cols="12"
                            class="py-0"
                          >
                            <v-select
                              dense
                              v-model="int_id_stato"
                              :items="status"
                              label="Selezione Stato"
                              item-text="des"
                              item-value="val_int"
                            >
                              <template #label>
                                <span class="">Stato<strong>* </strong></span>
                                <a @click.stop style="pointer-events: all">
                                  <!-- v-on:dialogModules="emitModules"  -->
                                  <Modules
                                    tipo="PROGETTO_STATO"
                                    nome="Stato"
                                    setaction="settabella"
                                    getaction="gettabella"
                                    filter="validita = 1"
                                    controller="Global"
                                  />
                                </a>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col md="2" cols="12" class="py-0">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  dense
                                  autocomplete="off"
                                  v-model="start_date"
                                  label="Inizio"
                                  aria-autocomplete="false"
                                  persistent-hint
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="start_date"
                                no-title
                                @input="menu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col
                            md="2"
                            cols="12"
                            class="py-0"
                            v-if="!single_item.milestone"
                          >
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  dense
                                  autocomplete="off"
                                  v-model="end_date"
                                  label="Fine"
                                  aria-autocomplete="false"
                                  persistent-hint
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="end_date"
                                no-title
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col
                            md="2"
                            cols="12"
                            class="py-0"
                          >
                            <v-menu
                              ref="menu3"
                              v-model="menu3"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  dense
                                  autocomplete="off"
                                  v-model="end_prefix_date"
                                  label="Fine Prevista"
                                  aria-autocomplete="false"
                                  persistent-hint
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="end_prefix_date"
                                no-title
                                @input="menu3 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" :md="2" class="py-0">
                            <v-select
                              dense
                              v-model="int_id_um_ore"
                              :items="misure"
                              label="Unità"
                              item-text="des"
                              item-value="id_tabella"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" :md="2" class="py-0">
                            <v-text-field
                              dense
                              v-model="single_item.ore_previste"
                              label="Quantità"
                              type="number"
                              min="0"
                              max="1000"
                              step="1"
                              onkeydown="javascript: return event.keyCode == 69 ? false : true"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            class="py-0"
                          >
                            <v-text-field
                              dense
                              v-model="single_item.email"
                              label="Email"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-textarea
                              dense
                              rows="4"
                              v-model="single_item.des_ticket"
                              label="Descrizione"
                              hint="Hint text"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="text-start py-0">
                            <v-textarea
                              dense
                              rows="4"
                              v-model="single_item.esito_ticket"
                              label="Risposta Task"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row
                          class="text-start py-0"
                          v-if="single_item.id_ticket != -1"
                        >
                          <v-col cols="12" class="py-0 mt-2">
                            <ActivitiesInterface
                              :showDialog="false"
                              :ticket="single_item"
                              :tipo="parseInt(single_item.id_tipo_ticket)"
                            ></ActivitiesInterface>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-container>
                        <v-row class="align-end">
                          <v-col
                            v-if="!single_item.immagine"
                            :cols="!single_item.immagine ? 12 : 6"
                          >
                            <!-- <v-icon @click="pasteIcon">mdi-clipboard</v-icon> -->
                            <v-text-field
                              dense
                              v-model="allegato"
                              @paste="onPaste"
                              accept="image/*"
                              label="Immagine"
                              prepend-icon="mdi-camera"
                              @click:prepend="openImagePicker"
                            ></v-text-field>
                            <input
                              type="file"
                              v-on:change="onFileChange"
                              ref="allegato"
                              name="allegato"
                              class="d-none"
                            />
                          </v-col>

                          <v-col
                            v-if="single_item.immagine"
                            class="py-0 text-center align-self-end"
                          >
                            <v-icon small @click="deleteAttach()">
                              mdi-close
                            </v-icon>
                            <div
                              v-viewer="{ navbar: false, toolbar: false }"
                              class="images clearfix d-flex justify-center"
                            >
                              <img
                                @load="onLoadImage()"
                                :ref="'image_prev_task' + single_item.id_ticket"
                                :id="'image_prev_task' + single_item.id_ticket"
                                :src="this.single_item.immagine"
                                class="white--text image align-end"
                                style="width: 150px"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <GestioneAllegato
                        ref="componenteAllegati"
                        controller_allegati="Global"
                        controller_caratteristiche_tabella="Manutenzione"
                        :id_riferimento="parseInt(single_item.id_ticket)"
                        :assistenza="assistenza.toString()"
                        nome_form_chiamante="task"
                        tipo_allegato="TICKET"
                        @snackbarOpen="snackbarOpen"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
            <!-- <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="predecessori"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.bar`]="{ item }">
                        <v-progress-linear
                          color="light-blue"
                          height="25"
                          striped
                          :value="
                            item.nr_task == 0
                              ? 100
                              : (item.nr_task_chiusi * 100) / item.nr_task
                          "
                        >
                          <template
                            :v-slot="(item.nr_task_chiusi * 100) / item.nr_task"
                          >
                            <v-row class="d-flex align-center">
                              <v-col cols="12" md="6" class="py-0">
                                <strong
                                  >{{
                                    item.nr_task == 0
                                      ? 100
                                      : Math.ceil(
                                          (item.nr_task_chiusi * 100) /
                                            item.nr_task
                                        )
                                  }}%</strong
                                >
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                                class="py-0 d-flex justify-end"
                              >
                                <strong
                                  >{{ item.nr_task_chiusi }}/{{
                                    item.nr_task
                                  }}</strong
                                >
                              </v-col>
                            </v-row>
                          </template>
                        </v-progress-linear>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item> -->
          </v-tabs-items>
        </v-container>
      </v-card>
    </div>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// import Base64 from "b64-to-blob";
import moment from "moment";
import Modules from "@/components/TOOLS/Modules";
// import AllegatoList from "../CHECK/Allegato/AllegatoList.vue";
import ActivitiesInterface from "./ActivitiesInterface";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";

function open(that) {
  var getUtenti = {
    controller: "Utente",
    method: "POST",
    richiesta: {
      action: "getutentelist",
      token: localStorage.user_token,
      id_utente: localStorage.id,
    },
  };

  that.$store.dispatch("api", getUtenti).then((res) => {
    that.utenti = res.data.result.gp_personale_utenti;
  });

  that.single_item.id_ticket = that.id_task;

  var getStatus = {
    controller: "Global",
    method: "POST",
    richiesta: {
      action: "gettabella",
      token: localStorage.user_token,
      filter: "validita = 1",
      tipo: "PROGETTO_STATO",
      assistenza: that.assistenza
    },
  };

  that.$store.dispatch("api", getStatus).then((res) => {
    that.status = res.data;

    var single_status = that.status.filter((item) => {
      if (item.val_int == that.single_item.id_stato) {
        return item;
      }
    });
    that.color = single_status[0].val_txt;
  });

  var getMisure = {
    controller: "Global",
    method: "POST",
    richiesta: {
      action: "gettabella",
      token: localStorage.user_token,
      filter: "validita = 1",
      tipo: "UNITA_MISURA",
      assistenza: that.assistenza
    },
  };

  that.$store.dispatch("api", getMisure).then((res) => {
    if (res.data != undefined) {
      res.data.map((el) => {
        if (el.val_txt === "H") {
          that.misure.push(el);
        }
      });
    }
  });

  var getParent = {
    controller: "Ticket",
    method: "POST",
    richiesta: {
      action: "getticket",
      token: localStorage.user_token,
      id_ticket: that.id_parent,
      id_stato: "",
      assistenza: that.assistenza,
    },
  };

  if (that.single_item.id_ticket == -1) {
    that.$store.dispatch("api", getParent).then((res) => {
      that.risorse = res.data.result.tickets_risorsa;
      that.single_item.id_ambito = res.data.result.tickets[0].id_ambito;
      that.single_item.des_ambito = res.data.result.tickets[0].des_ambito;
      that.single_item.des_progetto = res.data.result.tickets[0].des_progetto;
      that.single_item.id_progetto = res.data.result.tickets[0].id_progetto;
      that.single_item.des_modulo = res.data.result.tickets[0].des_modulo;
      that.single_item.id_modulo = res.data.result.tickets[0].id_modulo;

      that.single_item.id_ticket_padre = that.id_parent;
    });

    that.loading = false;
  } else {
    that.$store.dispatch("api", getParent).then((res) => {
      that.attivita = res.data.attivita;
    });

    var getTask = {
      controller: "Ticket",
      method: "POST",
      richiesta: {
        action: "getticket",
        token: localStorage.user_token,
        id_ticket: that.single_item.id_ticket,
        id_stato: "",
        assistenza: that.assistenza,
      },
    };

    that.$store.dispatch("api", getTask).then((res) => {
      that.single_item = res.data.result.tickets[0];
      if (that.single_item.data_inizio != null) {
        that.start_date = moment(that.single_item.data_inizio).format(
          "YYYY-MM-DD"
        );
      }
      if (that.single_item.data_fine != null) {
        that.end_date = moment(that.single_item.data_fine).format("YYYY-MM-DD");
      }
      if (that.single_item.data_fine_prevista != null) {
        that.end_prefix_date = moment(that.single_item.data_fine_prevista).format("YYYY-MM-DD");
      }
      that.date = that.single_item.data_scadenza
        ? moment(that.single_item.data_scadenza).format("YYYY-MM-DD")
        : null;

      that.loading = false;
    });
  }
}

function save(that) {
  const today = new Date();
  if (that.single_item.id_ticket == -1 && that.id_responsabile == -1) {
    that.single_item.id_responsabile = localStorage.id;
  }
  if (
    that.single_item.data_ticket == null ||
    that.single_item.data_ticket == ""
  ) {
    that.single_item.data_ticket =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      (today.getDate() < 10 ? "0" : "") +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();
  }

  if (that.date != null) {
    that.single_item.data_scadenza = that.date + "T00:00:00";
  }
  that.single_item.data_inizio = that.start_date;
  that.single_item.data_fine = that.end_date;
  that.single_item.data_fine_prevista = that.end_prefix_date;
  that.single_item.id_utente = localStorage.id;

  var request2 = {
    controller: "Ticket",
    method: "PUT",
    richiesta: {
      action: "setticket",
      token: localStorage.user_token,
      id_utente: localStorage.id,
      id_azienda: localStorage.id_azienda,
      tipologia: "",
      tipo_ticket: 0,
      assistenza: that.assistenza,
      tabella: [that.single_item],
    },
  };

  if (
    that.single_item.task != undefined &&
    that.single_item.task.length == undefined
  ) {
    that.single_item.task = [that.single_item.task];
  }

  that.$store.dispatch("api", request2).then(async (res) => {
    if (res.status == 200) {
      that.$refs.componenteAllegati.id_riferimento_creazione_genitore =
        res.data.result.id_ticket;
      if (that.$refs.componenteAllegati.lista_allegati.length > 0) {
        var resSalvataggio = await that.$refs.componenteAllegati.salvaTuttiAllegati();
        if (resSalvataggio) {
          that.dialog = false;
          that.$emit("dialogTask", true);
        }
      } else {
        that.dialog = false;
        that.$emit("dialogTask", true);
      }
    }
  });
}

export default {
  name: "AddTask",
  props: {
    id_parent: Number,
    id_task: Number,
    assistenza: { type: Number, default: 0 },
  },
  data: () => ({
    color: "blue",
    date: null,
    des: "",
    end_date: null,
    end_prefix_date: null,
    id_utente: localStorage.id,
    single_item: {
      id_ticket: -1,
      id_ticket_padre: -1,
      id_azienda: null,
      num_ticket: "",
      id_tipo_ticket: 1,
      data_ticket: "",
      id_progetto: null,
      id_ambito: null,
      id_modulo: null,
      id_stato: 0,
      id_responsabile: -1,
      des_ticket: "",
      des_ambito: "",
      des_progetto: "",
      des_modulo: "",
      titolo_task: "",
      esito_ticket: "",
      data_inizio: null,
      data_fine: null,
      data_fine_prevista: null,
      email: "",
      milestone: false,
      data_ins: "",
      data_mod: "",
      des_stato: "Creato",
      immagine: null,
      validita: 1,
    },
    start_date: null,
    tab: null,

    dialog: false,
    dialogImage: false,
    loading: true,
    menu: false,
    menu2: false,
    menu3: false,
    snackbar: false,
    snackbar_background: "",
    snackbar_icon: "",
    snackbar_icon_color: "",
    snackbar_text: "",
    snackbar_text_color: "",

    allegato: [],
    errors: [],
    headers: [
      {
        text: "Codice Ticket",
        value: "numero",
      },
      {
        text: "Responsabile",
        value: "nominativo",
      },
      {
        text: "Avanzamento lavori",
        value: "bar",
      },
    ],
    misure: [],
    predecessori: [],
    risorse: [],
    status: [],
    utenti: [],
  }),
  components: {
    // AllegatoList,
    ActivitiesInterface,
    Modules,
    GestioneAllegato,
  },
  created() {},
  watch: {
    milestone: function (val) {
      if (!val) {
        this.single_item.data_fine = this.single_item.data_inizio;
      }
    },
    dialog: function (newM, oldM) {
      if (newM != oldM) {
        if (newM != false) {
          this.loading = true;
          open(this);
        } else {
          this.end_date = null;
          this.end_prefix_date = null;
          this.start_date = null;
          this.allegato = [];
          this.single_item = {
            id_ticket: -1,
            id_ticket_padre: -1,
            num_ticket: "",
            id_tipo_ticket: 1,
            data_ticket: "",
            id_ambito: null,
            id_progetto: null,
            id_modulo: null,
            id_stato: 0,
            id_responsabile: -1,
            des_ticket: "",
            des_ambito: "",
            des_progetto: "",
            des_modulo: "",
            titolo_task: "",
            esito_ticket: "",
            data_inizio: null,
            data_fine: null,
            data_fine_prevista: null,
            email: "",
            milestone: false,
            data_ins: "",
            data_mod: "",
            des_stato: "Creato",
            immagine: null,
            validita: 1,
          };
        }
        
        if (this.assistenza == 1) {
          this.single_item.id_azienda = localStorage.id_azienda;
        }
      }
    },
  },
  computed: {
    valid() {
      return this.single_item.titolo_task != "" ? true : false;
    },
    int_id_responsabile: {
      get: function () {
        return parseInt(this.single_item.id_responsabile);
      },
      set: function (newValue) {
        this.single_item.id_responsabile = newValue;
        return parseInt(this.single_item.id_responsabile);
      },
    },
    int_id_stato: {
      get: function () {
        return parseInt(this.single_item.id_stato);
      },
      set: function (newValue) {
        this.single_item.id_stato = newValue;
        return parseInt(this.single_item.id_stato);
      },
    },
    int_id_um_ore: {
      get: function () {
        return parseInt(this.single_item.id_um_ore);
      },
      set: function (newValue) {
        this.single_item.id_um_ore = newValue;
        return parseInt(this.single_item.id_um_ore);
      },
    },
    mod_date() {
      if (this.single_item.data_mod) {
        return moment(this.single_item.data_mod).format("MM/DD hh:mm");
      } else {
        return "Non presente";
      }
    },
  },
  methods: {
    dataURItoBlob(dataURI) {
      const bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    },
    deleteAttach() {
      this.allegato = [];
      this.single_item.immagine = null;
    },
    emitActivities() {
      console.log("caaaaa");
    },
    getIcon(id_stato) {
      var icon = this.status.filter(function (st) {
        if (st.val_int == id_stato) {
          return st;
        }
      });
      this.color =
        icon.length != 0 && icon[0].val_txt ? icon[0].val_txt : "blue";
      return icon.length != 0 && icon[0].nota_interna
        ? "mdi-" + icon[0].nota_interna
        : "mdi-help-circle";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.allegato = files[0];

      let that = this;
      this.resizeImageBackEnd(this.allegato, 600)
        .then((resizedImage) => {
          var reader = new FileReader();
          reader.readAsDataURL(resizedImage);

          let thet = that;
          reader.onload = function () {
            thet.single_item.immagine = reader.result;
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onLoadImage() {
      this.resizeImageDocument(
        this.$refs["image_prev_task" + this.single_item.id_ticket],
        "image_prev_task" + this.single_item.id_ticket
      );
    },
    onPaste(e) {
      // if (e.clipboardData != false) {
      //   var items = e.clipboardData.items;

      //   if (items != undefined) {
      //     for (var i = 0; i < items.length; i++) {
      //       // Skip content if not image
      //       if (items[i].type.indexOf("image") == -1) continue;

      //       // Retrieve image on clipboard as blob
      //       var blob = items[i].getAsFile();
      //       console.log(blob);
      //     }
      //   }
      // }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.single_item.immagine = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    resizeImageDocument(img, id) {
      if (document.getElementById(id) != null) {
        if (img.width > img.height && img.width > 200) {
          let width_nuova = 200;
          // Proporzione -> width_nuova : width = height_nuova : height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(id).style.width = width_nuova + "px";
          document.getElementById(id).style.height = height_nuova + "px";
        } else if (img.height > img.width && img.height > 200) {
          let height_nuova = 200;
          // Proporzione -> width_nuova : width = height_nuova : height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(id).style.width = width_nuova + "px";
          document.getElementById(id).style.height = height_nuova + "px";
        } else {
          document.getElementById(id).style.width = img.width + "px";
          document.getElementById(id).style.height = img.height + "px";
        }
      }
    },
    resizeImageBackEnd(file, maxSize) {
      const reader = new FileReader();
      const image = new Image();
      const canvas = document.createElement("canvas");

      const resize = () => {
        let { width, height } = image;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);

        const dataUrl = canvas.toDataURL("image/jpeg");

        return this.dataURItoBlob(dataUrl);
      };

      return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = (readerEvent) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };

        reader.readAsDataURL(file);
      });
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        this.resizeImageBackEnd(blob, 600)
          .then((resizedImage) => {
            // Create an abstract canvas and get context
            var mycanvas = document.createElement("canvas");
            var ctx = mycanvas.getContext("2d");

            // Create an image
            var img = new Image();

            // Once the image loads, render the img on the canvas
            img.onload = function () {
              // Update dimensions of the canvas with the dimensions of the image
              mycanvas.width = this.width;
              mycanvas.height = this.height;

              // Draw the image
              ctx.drawImage(img, 0, 0);

              // Execute callback with the base64 URI of the image
              if (typeof callback == "function") {
                callback(mycanvas.toDataURL(imageFormat || "image/png"));
              }
            };

            var reader = new FileReader();
            reader.readAsDataURL(resizedImage);

            reader.onload = function () {
              img.src = reader.result;
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
            // that.single_item.immagine = this.resizedImg;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    validate() {
      if (this.single_item.email && !this.validEmail(this.single_item.email)) {
        this.errors.push("Email non valida.");
        return true;
      }
      if (
        this.single_item.id_responsabile != -1 &&
        this.single_item.id_stato == 0
      ) {
        this.single_item.id_stato = 1;
      }
      if (this.milestone != undefined && !this.milestone) {
        this.end_date = this.start_date;
      }
      save(this);
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>