var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-1 pt-0",attrs:{"headers":_vm.headers_responsabili,"items":_vm.items_responsabili,"single-expand":true,"expanded":_vm.expanded_responsabili,"fixed-header":"","height":"70vh","item-key":"id_utente","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_responsabili=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Responsabili di Reparto ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.aggiungiResponsabile()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Responsabile")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1)]},proxy:true},{key:"item.elimina",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickEliminaResponsabile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina Responsabile")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers_team,"hide-default-footer":"","items":item.team},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Team ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.aggiungiMembro(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi Membro")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.elimina",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickEliminaResponsabile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina Responsabile")])])]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }