<template>
  <v-card>
    <v-toolbar color="indigo" dense fixed>
      <v-toolbar-title class="white--text">Modifica Area</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Ordine -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaModifica" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Area</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialogArea" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-2 pa-0">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="4">
            <v-text-field
              label="Codice"
              v-model="area_sel_tmp.cod_quesito"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Descrizione"
              v-model="area_sel_tmp.des_quesito"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import Area from "@/classes/EMArea.js";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ModificaArea",
  components: { SnackbarQV },
  props: {
    area_sel: { type: Object, default: () => new Area() },
    visible: { type: Boolean, default: true },
  },
  data() {
    return {
      // Variabili d'appoggio
      area_sel_tmp: new Area(),

      // Modal bool
      // Array
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.area_sel_tmp = new Area(this.area_sel);
        }
      },
    },
  },
  methods: {
    chiudiDialogArea() {
      this.$emit("click_chiudi");
    },
    salvaModifica() {
      const area_save = Object.assign({}, this.area_sel_tmp);
      this.$emit("salva_area", area_save);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style> 
