<template>
    <DistintaMultilevel class="ma-5" @go_back="goBack" style="border-radius:0px;" />
</template>
<script>
import DistintaMultilevel from "@/components/ELECTRICALMARINE/DistintaMultilevel.vue";
export default {
  name: "EMDistinta",
  components: {
    DistintaMultilevel
  },
  props: {},
  data() {
    return {
        // Variabili d'appoggio
        // Modal
        // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    goBack(){
      this.$router.push("/home/electrical");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>