import { render, staticRenderFns } from "./CarrelloCristal.vue?vue&type=template&id=92fbff42&scoped=true&"
import script from "./CarrelloCristal.vue?vue&type=script&lang=js&"
export * from "./CarrelloCristal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92fbff42",
  null
  
)

export default component.exports