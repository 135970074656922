import { render, staticRenderFns } from "./Visualizzatore.vue?vue&type=template&id=946ab5ee&scoped=true&"
import script from "./Visualizzatore.vue?vue&type=script&lang=js&"
export * from "./Visualizzatore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "946ab5ee",
  null
  
)

export default component.exports