<template>
  <div>
    <v-data-table
      :headers="
        read_only
          ? headers_allegati.filter((f) => f.value != 'elimina')
          : headers_allegati
      "
      :items="lista_allegati.filter((el) => el.validita != 0)"
      item-key="id_allegato"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar :color="color" dense>
          <v-toolbar-title class="white--text"
            >{{toolbar_title}}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="!read_only || consenti_upload === true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-if="!read_only"
                @click="aggiungiAllegato"
                v-bind="attrs"
                v-on="on"
                :icon="icon_color == 'primary' ? false:true"
                :color="color == 'indigo' ? '' : color"
              >
                <v-icon :color="icon_color">mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Carica Allegato</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!read_only || consenti_upload === true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5"
                small
                @click="clickSalvaAllegati"
                :disabled="lista_allegati.length === 0"
                v-bind="attrs"
                v-if="show_save_button === true && !read_only"
                v-on="on"

              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva Allegati</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="show_close" @click="clickChiudi" small v-bind="attrs" v-on="on">
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <!-- Header Famiglia con occhio di Modules -->
      <template v-slot:[`header.famiglia`]="{}">
        <span>Tipologia</span>
        <span @click.stop style="min-width: 500px; pointer-events: all">
          <Modules
            v-on:dialogModules="getTabelle"
            tipo="FAMIGLIA_ALLEGATI"
            nome="Tipologia Allegati"
            setaction="settabella"
            getaction="gettabella"
            controller="Global"
            filter="validita = 1"
            :bg_color="color"
            :icon_color="icon_color"
            :dati="[{ des: '' }]"
          />
        </span>
      </template>

      <!-- Bottone dei Download -->
      <template v-slot:[`item.scarica`]="{ item }">
        <v-tooltip bottom v-if="item.id_allegato > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              :disabled="lista_src[lista_allegati.indexOf(item)] == null"
              v-on="on"
              @click="
                DownloadFile(
                  tipo_allegato +
                    '_' +
                    item.id_allegato.toString() +
                    '.' +
                    item.estensione.toString(),
                  lista_download_files[lista_allegati.indexOf(item)]
                )
              "
            >
              <v-icon color="primary">mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Scarica</span>
        </v-tooltip>
      </template>

      <!-- Famiglia -->
      <template v-slot:[`item.famiglia`]="{ item }">
        <v-select
          v-model="item.id_categoria"
          :items="famiglia_allegati_lista"
          placeholder="Seleziona"
          required
          item-text="des"
          item-value="id_tabella"
          @change="
            famiglia_allegati_lista.filter((e) =>
              e.id_tabella == item.id_categoria
                ? (item.des_categoria = e.des)
                : null
            )
          "
        >
        </v-select>
      </template>

      <!-- Descrizione -->
      <template v-slot:[`item.des_allegato`]="{ item }">
        <v-text-field
          placeholder="Inserire"
          v-model="item.des_allegato"
          required
          :ref="
            nome_form_chiamante +
            '_text_allegato' +
            lista_allegati.indexOf(item)
          "
          @input="
            changeDes(
              nome_form_chiamante +
                '_text_allegato' +
                lista_allegati.indexOf(item)
            )
          "
          @change="
            changeDes(
              nome_form_chiamante +
                '_text_allegato' +
                lista_allegati.indexOf(item)
            )
          "
        ></v-text-field>
      </template>

      <!-- Data Validita -->
      <template v-slot:[`item.data_validita`]="{ item }">
        <v-menu
          v-model="date_validita_tabella[lista_allegati.indexOf(item)]"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field v-model="item.data_validita" readonly v-on="on">
              <template v-slot:append>
                <v-icon color="primary">mdi-calendar-today</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="item.data_validita"
            @input="date_validita_tabella[lista_allegati.indexOf(item)] = false"
          ></v-date-picker>
        </v-menu>
      </template>

      <!-- style="width: 60px; height: 60px" -->
      <template v-slot:[`item.preview`]="{ item }">
        <div v-if="lista_src[lista_allegati.indexOf(item)] == null">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div
            v-viewer
            v-if="
              (lista_allegati[lista_allegati.indexOf(item)].estensione !=
                undefined &&
                lista_allegati[
                  lista_allegati.indexOf(item)
                ].estensione.toUpperCase() == 'PNG') ||
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'JPEG' ||
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'JPG' ||
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'GIF'
            "
          >
            <img
              @load="onLoadImage(lista_allegati.indexOf(item))"
              :ref="
                nome_form_chiamante +
                '_image_prev_allegato' +
                lista_allegati.indexOf(item)
              "
              :id="
                nome_form_chiamante +
                '_image_prev_allegato' +
                lista_allegati.indexOf(item)
              "
              :src="lista_src[lista_allegati.indexOf(item)]"
            />
          </div>
          <v-icon
            v-else-if="
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'PDF'
            "
            class="justify-center d-flex"
            color="error"
            x-large
            @click="openPdf(lista_src[lista_allegati.indexOf(item)])"
          >
            <!-- @click="
              this.selectedPdf = lista_src[lista_allegati.indexOf(item)];
              this.pdfdialog = true;
            " -->
            mdi-file-pdf-box
          </v-icon>
          <div
            v-else-if="
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'MP4' ||
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase() == 'MOV'
            "
            @click="openVideo(lista_src[lista_allegati.indexOf(item)])"
          >
            <v-icon class="justify-center d-flex" color="error" x-large>
              mdi-play-box
            </v-icon>
            <span class="justify-center d-flex">{{
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase()
            }}</span>
          </div>
          <div v-else>
            <v-icon class="justify-center d-flex" color="error" x-large>
              mdi-file
            </v-icon>
            <span class="justify-center d-flex">{{
              lista_allegati[
                lista_allegati.indexOf(item)
              ].estensione.toUpperCase()
            }}</span>
          </div>
        </div>
      </template>

      <!-- Bottone di eliminazione -->
      <template v-if="!read_only" v-slot:[`item.elimina`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="removeAllegato(lista_allegati.indexOf(item))"
              small
              v-bind="attrs"
              v-on="on"
              :disabled="read_only"
              ><v-icon color="error">mdi-close</v-icon></v-btn
            >
          </template>
          <span>Elimina Allegato</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="dettaglio_allegato_visible" width="800">
      <v-card>
        <v-toolbar :color="color" dense>
          <v-toolbar-title class="white--text"
            >Dettagli Allegato</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :icon="icon_color == 'primary' ? false:true" @click="salvaAllegato" small v-bind="attrs" v-on="on"
                ><v-icon :color="icon_color">mdi-floppy</v-icon></v-btn
              >
            </template>
            <span>Seleziona File</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              :icon="icon_color == 'primary' ? false:true"
                @click="chiudiDialogAllegato"
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon :color="icon_color">mdi-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pa-0 pt-3">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="files"
                  counter
                  label="File input"
                  multiple
                  placeholder="Seleziona i tuoi file"
                  prepend-icon="mdi-camera"
                  :show-size="1000"
                  ref="allegato"
                  class="d-none"
                >
                </v-file-input>
                <v-text-field
                  dense
                  @paste="onPaste"
                  :value="comp_change(files)"
                  accept="image/*"
                  label="Files"
                  prepend-icon="mdi-camera"
                  clearable
                  @click:clear="clearFiles"
                  @click:prepend="openImagePicker"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="videoPlayer">
      <video
        width="800"
        controls
        style="max-height: 400px; background-color: black"
      >
        <source :src="linkVideo" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </v-dialog>
    <!-- Dialog di visualizzazione delle immagini PDF -->
    <v-dialog v-model="dialogPdf">
      <iframe
        :src="selectedPdf"
        frameborder="0"
        style="height: 90vh !important"
      >
      </iframe>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment/moment";
import Modules from "@/components/TOOLS/Modules.vue";
import { saveAs } from "file-saver";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "GestioneAllegati",
  components: { Modules, SnackbarQV },
  props: {
    consenti_upload: { type: Boolean, default: true },
    controller_allegati: {
      type: String,
      default: () => "",
    },
    controller_caratteristiche_tabella: {
      type: String,
      default: () => "",
    },
    id_riferimento: {
      type: Number,
      default: null,
    },
    /* se ho sia in componente padre GestioneAllegato che nel figlio mostrato a video come dialog, se in entrambi ho 1 image
     * entrambe sarebbero image_prev_allegato0 e quindi fa la resize solo sul padre e non su entrambi
     * perciò per distinzione si usa nome_form_chiamante che di defualt sarà form
     */
    nome_form_chiamante: {
      type: String,
      default: () => "form",
    },
    read_only: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: false,
    },
    tipo_allegato: {
      type: String,
      default: () => "",
    },
    tipo_headers: {
      type: String,
      default: "normali",
    },
    assistenza: {
      type: String,
      default: "0",
    },
    show_close:{
      type: Boolean,
      default:true,
    },
    toolbar_title:{
      type: String,
      default: "Documenti Associati"
    },
    color:{
      type:String,
      default:"indigo"
    },
    icon_color:{
      type:String,
      default:"primary"
    }
  },
  data() {
    return {
      // Variabili d'appoggio
      allegato_sel: {},
      dettaglio_allegato_visible: false,
      //proprieta per osservare i cambiamenti, se mantenessi solo id_riferimento non riuscire a fare creazione quindi setGenitore e dopo cambiare la prop dell' id_riferimento
      // e quindi richiamare tutto il set del Componente
      id_riferimento_creazione_genitore: "",
      linkVideo: null,
      selectedPdf: "",
      // Modal
      dialogPdf: false,
      videoPlayer: false,
      // Array
      date_validita_tabella: [],
      famiglia_allegati_lista: [],
      files: [],
      headers_allegati: [
        {
          text: "Scarica",
          value: "scarica",
          sortable: false,
        },
        {
          text: "Tipologia",
          value: "famiglia",
          sortable: false,
        },
        {
          text: "Descrizione",
          value: "des_allegato",
          sortable: false,
        },
        {
          text: "Data Validità",
          value: "data_validita",
          sortable: false,
        },
        {
          text: "Caricato da",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Preview",
          value: "preview",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
      lista_allegati: [],
      lista_download_files: [],
      lista_files: [],
      lista_src: [],
    };
  },
  computed: {
    nominativo() {
      return localStorage.nominativo;
    },
  },
  watch: {
    id_riferimento: {
      immediate: true,
      handler() {
        if (this.id_riferimento != -1 && this.id_riferimento != null) {
          this.lista_files = [];
          this.lista_src = [];
          this.id_riferimento_creazione_genitore = this.id_riferimento;
          //creazione lista delle src usando il $set perchè altrimenti si perdono informazioni e risulta array vuoto su this.lista_src
          if (this.lista_allegati.length == 0) {
            this.lista_src = [];
          }
          if (this.controller_allegati != "") {
            this.getAllegati();
          } else {
            this.lista_allegati.forEach((el, i) => {
              if (el.validita == 0) {
                this.$set(this.lista_src, i, null);
              } else {
                if (el.id_allegato > 0) {
                  let blob;
                  blob = this.getImagePreviewDb(
                    this.tipo_allegato +
                      "_" +
                      el.id_allegato.toString() +
                      "." +
                      el.estensione.toString()
                  );
                  this.$set(this.lista_src, i, URL.createObjectURL(blob));
                  this.$set(this.lista_download_files, i, blob);
                } else {
                  this.lista_src.push(URL.createObjectURL(this.lista_files[i]));
                  this.$set(this.lista_download_files, i, this.lista_files[i]);
                }
              }
            });
          }
        }
      },
    },
    tipo_headers: {
      immediate: true,
      handler() {
        if (this.tipo_headers === "minimi") {
          let headers_tmp = [];

          this.headers_allegati.forEach((elem) => {
            if (elem.text != "Scarica" && elem.text != "Elimina") {
              headers_tmp.push(elem);
            }
          });

          this.headers_allegati = [];
          this.headers_allegati = headers_tmp;
        }
      },
    },
  },
  methods: {
    aggiungiAllegato() {
      this.allegato_sel = {
        data_validita: moment().add(1, "M").format("YYYY-MM-DD"),
        des_allegato: "",
        des_categoria: null,
        id_allegato: -moment().valueOf(),
        id_categoria: null,
        id_utente: localStorage.id,
        tipo_riga: "",
      };
      this.files = [];

      this.dettaglio_allegato_visible = true;
    },
    changeDes(id) {
      this.$nextTick(() => {
        this.$refs[id].focus();
      });
    },
    chiudiDialogAllegato() {
      this.dettaglio_allegato_visible = false;

      // Dichiaro un oggetto resettato
      let nuovo_allegato = {};

      // Uso l'oggetto resettato per valorizzare l'allegato_sel
      this.allegato_sel = Object.assign({}, nuovo_allegato);
    },
    clearFiles() {
      this.files = [];
    },
    clickChiudi() {
      /* Nel caso in cui la prop show_save_button sia
       * valorizzata a true, l'utente si aspetta che
       * chiudendo il dialog gli allegati non ancora
       * salvati siano rimossi dalla tabella. Faccio in
       * modo che ciò effettivamente avvenga. Nel caso
       * in cui invece show_save_button non sia true, non
       * faccio nulla ed emetto solo l'evento click_chiudi,
       * che rappresenta la vecchia logica standard del componente. */
      if (this.show_save_button === true) {
        const lista_tmp = Array.from(
          this.lista_allegati.filter((e) => e.id_allegato > 0)
        );
        this.lista_allegati = Array.from(lista_tmp);
      }

      this.$emit("click_chiudi");
    },
    clickSalvaAllegati() {
      // Se ci sono allegati senza descrizione blocco il salvataggio
      if (
        this.lista_allegati.filter(
          (e) => e.des_allegato === "" || e.des_allegato === null
        ).length > 0
      ) {
        this.$refs.SnackbarQV.print(
          "orange",
          `Inserire una descrizione per ogni Allegato.`
        );
        return;
      }

      // Emetto un evento per lanciare il salvataggio nel padre
      this.$emit("salva_allegati");
      console.log("emesso salva_allegati");
    },
    comp_change(val) {
      return val.map((e) => e.name).join(", ");
    },
    dataURItoBlob(dataURI) {
      const bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    },
    DownloadFile(nome_file, file) {
      // nome_file = nome_file.replaceAll(" ", "_");
      // let request = {
      //   controller: this.controller_allegati,
      //   method: "POST",
      //   richiesta: {
      //     action: "download_file",
      //     nome_file: nome_file,
      //     token: localStorage.user_token,
      //     assistenza: this.assistenza,
      //   },
      // };

      // //ricevo nome_file ovvero nome_file.estensione e per ogni chiedo il blob dell' immagine
      // axios.defaults.headers.post["responseType"] = "blob";
      // this.$store.dispatch("api_blob", request).then((res) => {
      //   if (res.status === 200) {
      //     //fa partire la richiesta di download del file ricevuto
      // saveAs(res.data, nome_file.toLowerCase());
      saveAs(file, nome_file.toLowerCase());
      //   }
      // });
    },
    async getImagePreviewDb(nome_file) {
      nome_file = nome_file.replaceAll(" ", "_");
      let request = {
        controller: this.controller_allegati,
        method: "POST",
        richiesta: {
          action: "download_file",
          nome_file: nome_file,
          token: localStorage.user_token,
          assistenza: this.assistenza,
        },
      };
      axios.defaults.headers.post["responseType"] = "blob";
      let file_db = new Blob();
      // richiedo il file blob del file selezionato per ricevere una preview da inserire nella tabella allegati, per tutti tranne i pdf
      // if (nome_file.split(".")[1].toUpperCase() != "PDF") {
      await this.$store.dispatch("api_blob", request).then((res) => {
        if (res.status === 200) {
          file_db = res.data;
          return;
        } else {
          file_db = null;
          return;
        }
      });
      // }
      return file_db;
    },
    async getAllegati() {
      if (this.id_riferimento == null || isNaN(this.id_riferimento)) {
        return;
      }
      let request = {
        controller: this.controller_allegati,
        method: "POST",
        richiesta: {
          action: "getallegati",
          assistenza: this.assistenza,
          id_riferimento: this.id_riferimento,
          tipo: this.tipo_allegato,
          token: localStorage.user_token,
        },
      };

      await this.$store.dispatch("api", request).then(async (res) => {
        if (res.status === 200 && res.data != "") {
          this.lista_allegati = res.data.result.allegati;
          console.log("ALLEGATI GANG:", this.lista_allegati);
          if (this.lista_allegati != "") {
            //creazione lista delle src usando il $set perchè altrimenti si perdono informazioni e risulta array vuoto su this.lista_src
            this.lista_allegati.forEach(async (el, i) => {
              // this.lista_files.push(el);
              if (el.validita == 0) {
                this.$set(this.lista_src, i, null);
              } else {
                let blob;
                blob = await this.getImagePreviewDb(
                  this.tipo_allegato +
                    "_" +
                    el.id_allegato.toString() +
                    "." +
                    el.estensione.toString()
                );
                this.$set(this.lista_src, i, URL.createObjectURL(blob));
                this.$set(this.lista_download_files, i, blob);
              }
            });
          }
        }
      });
    },
    getTabelle() {
      let request = {
        controller: this.controller_caratteristiche_tabella,
        method: "POST",
        richiesta: {
          action: "gettabella",
          tipo: "FAMIGLIA_ALLEGATI",
          filter: "validita = 1",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200 && res.data != "") {
          this.famiglia_allegati_lista = res.data;
        }
      });
    },
    onLoadImage(id) {
      this.resizeImageDocument(
        this.$refs[this.nome_form_chiamante + "_image_prev_allegato" + id],
        this.nome_form_chiamante + "_image_prev_allegato" + id,
        60,
        60
      );
    },
    onPaste(e) {
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        const byteString = atob(imageDataBase64.split(",")[1]);
        const mimeString = imageDataBase64
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ab], { type: mimeString });
        blob.name = "cattura.PNG";
        blob.lastModified = moment().valueOf();
        that.files.push(blob);
        that.salvaAllegato();
      });
    },
    openVideo(link) {
      this.videoPlayer = true;
      this.linkVideo = link;
    },
    getImgFromScreen(imageDataBase64) {
      const byteString = atob(imageDataBase64.split(",")[1]);
      const mimeString = imageDataBase64
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      let blob = new Blob([ab], { type: mimeString });
      blob.name = "cattura.PNG";
      blob.lastModified = moment().valueOf();
      this.files.push(blob);
      this.salvaAllegato();
    },
    openImagePicker() {
      this.$refs.allegato.$refs.input.click();
    },
    openPdf(blob) {
      this.selectedPdf = blob;
      this.dialogPdf = true;
    },
    removeAllegato(item) {
      this.lista_allegati[item].validita = 0;
    },
    resizeImageBackEnd(file, maxSize) {
      const reader = new FileReader();
      const image = new Image();
      const canvas = document.createElement("canvas");

      const resize = () => {
        let { width, height } = image;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);

        const dataUrl = canvas.toDataURL("image/jpeg");

        return this.dataURItoBlob(dataUrl);
      };

      return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = (readerEvent) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };

        reader.readAsDataURL(file);
      });
    },
    resizeImageDocument(img, id, width, height) {
      if (document.getElementById(id) != null) {
        if (img.width > img.height && img.width > width) {
          let width_nuova = width;
          // Proporzione -> width_nuova : width = height_nuova : height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(id).style.width = width_nuova + "px";
          document.getElementById(id).style.height = height_nuova + "px";
        } else if (img.height > img.width && img.height > height) {
          let height_nuova = height;
          // Proporzione -> width_nuova : width = height_nuova : height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(id).style.width = width_nuova + "px";
          document.getElementById(id).style.height = height_nuova + "px";
        } else {
          document.getElementById(id).style.width = width + "px";
          document.getElementById(id).style.height = height + "px";
        }
      }
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      var items = pasteEvent.clipboardData.items;

      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }

      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();

        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        this.resizeImageBackEnd(blob, 1800)
          .then((resizedImage) => {
            // Create an abstract canvas and get context
            var mycanvas = document.createElement("canvas");
            var ctx = mycanvas.getContext("2d");

            // Create an image
            var img = new Image();

            // Once the image loads, render the img on the canvas
            img.onload = function () {
              // Update dimensions of the canvas with the dimensions of the image
              mycanvas.width = this.width;
              mycanvas.height = this.height;

              // Draw the image
              ctx.drawImage(img, 0, 0);

              // Execute callback with the base64 URI of the image
              if (typeof callback == "function") {
                callback(mycanvas.toDataURL(imageFormat || "image/png"));
              }
            };

            var reader = new FileReader();
            reader.readAsDataURL(resizedImage);

            reader.onload = function () {
              img.src = reader.result;
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
            // that.single_item.immagine = this.resizedImg;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    salvaAllegato() {
      if (this.files.length == 0) {
        this.$refs.SnackbarQV.print(
          "orange",
          `Inserire il file dell'Allegato.`
        );
        return;
      }

      /* Stampo una snackbar di avviso di caricamento in corso
       * se show_save_button è false, che vuol dire che uso la
       * vecchia logica. Se invece è true, l'utentenon si aspetta
       * questo dialog e non lo stampo. */
      if (this.show_save_button === false) {
        this.$refs.SnackbarQV.print("success", `Caricamento in corso.`);
      }

      this.files.forEach((el) => {
        this.allegato_sel = {
          data_validita: moment().add(1, "M").format("YYYY-MM-DD"),
          des_allegato: "",
          des_categoria: null,
          estensione: el.name.split(".")[el.name.split(".").length - 1],
          // id_allegato: -moment().valueOf() - this.lista_allegati.indexOf(el),
          id_allegato: -el.lastModified,
          id_categoria: null,
          id_riferimento: this.id_riferimento,
          id_utente: localStorage.id,
          path: el.name,
          tipo: this.tipo_allegato,
          validita: 1,
        };

        this.lista_allegati.push(this.allegato_sel);
        this.lista_src.push(URL.createObjectURL(el));
      });
      this.lista_allegati.forEach(() => {
        this.date_validita_tabella.push(false);
      });
      this.files.forEach((el) => {
        let esiste = false;
        this.lista_files.forEach((el2) => {
          if (el2.name == el.name) {
            esiste = true;
          }
        });
        if (esiste == false) {
          this.lista_files.push(el);
        }
      });

      this.dettaglio_allegato_visible = false;
    },
    checkDescrizioniAllegati() {
      let descrizione_allegato_mancante = false;
      this.lista_allegati.forEach((el) => {
        if (el.validita != 0 && el.des_allegato == "") {
          descrizione_allegato_mancante = true;
        }
      });
      if (descrizione_allegato_mancante) {
        return false;
      } else {
        return true;
      }
    },
    salvaTuttiAllegati() {
      let descrizione_allegato_mancante = false;

      this.lista_allegati.forEach((el) => {
        if (this.id_riferimento == null || this.id_riferimento < 0) {
          el.id_riferimento = this.id_riferimento_creazione_genitore;
        }
        //per non avere errori sulla tabella per key duplicate, metto la primari key come -timestamp e poi riconverto a -1 prima del salvataggio
        if (el.id_allegato < 0) {
          el.id_allegato = -1;
        }
        if (el.validita != 0 && el.des_allegato == "") {
          descrizione_allegato_mancante = true;
        }
        el.id_azienda = localStorage.id_azienda;
        //rimuovo allegati che sono stati cestinati e non sono in creazione
        if (el.validita == 0 && el.id_allegato == -1) {
          delete this.lista_allegati[this.lista_allegati.indexOf(el)];
        }
      });
      if (descrizione_allegato_mancante) {
        this.$emit("snackbarOpen");
        return false;
      }

      let request = {
        controller: this.controller_allegati,
        method: "PUT",
        richiesta: {
          action: "setallegati",
          allegati: this.lista_allegati,
          assistenza: this.assistenza,
          token: localStorage.user_token,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          //prendo la lista degli allegati ricevuti dopo la set ovvero tutti quelli appartenenti al tipo specificato e al suo id_riferimento
          let id_allegati = res.data;
          //so che la lista degli allegati presenta come ultime voci gli allegati che sono stati salvati in questa setallegati nelle ultime posizioni
          //ovvero sono passati da -1 a ricevere un identificativo
          //per averli tutti e poterli inserire sul serve calocolo da quando cominciano come posizione gli allegati nuovi
          //pertanto prendo gli allegati dall' indice che si trova a posizione totale_allegati_ricevuti_dalla_setallegati - totale_file_inseriti_in_questo_form
          let inizio_identificativi_nuovi_file =
            id_allegati.length - this.lista_files.length;
          let form_data = new FormData();
          //inserisco i file con nome_file = primarykey.estensione
          this.lista_files.forEach((ele) => {
            form_data.append(
              this.tipo_allegato +
                "_" +
                id_allegati[
                  inizio_identificativi_nuovi_file
                ].id_allegato.toString() +
                "." +
                id_allegati[
                  inizio_identificativi_nuovi_file
                ].estensione.toString(),
              ele
            );

            //incremento il contatore dei miei allegati nuovi
            inizio_identificativi_nuovi_file++;
          });
          form_data.append("assistenza", this.assistenza);
          if (this.lista_files.length != 0) {
            new Promise((resolve, reject) => {
              axios
                .post(
                  this.$store.state.config.ip +
                    "api/" +
                    this.controller_allegati +
                    "/inserisci",
                  form_data
                )
                .then((result) => {
                  console.log(result);
                  resolve(result);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }
        }
      });

      return true;
    },
  },
  created() {},
  mounted() {
    if (this.controller_caratteristiche_tabella != "") {
      this.getTabelle();
    }
  },
};
</script>