var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Configurazione "+_vm._s(_vm.conf.tipologia))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salva}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.annulla}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Annulla")])])],1),_c('v-container',[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticStyle:{"display":"inline-block","man-width":"400px"},attrs:{"items":[
            { name: 'Email', id: 0 },
            { name: 'Link', id: 1 },
            { name: 'API', id: 2 } ],"item-text":"name","item-value":"id","id":"tipo","label":"Tipo"},model:{value:(_vm.conf.tipo),callback:function ($$v) {_vm.$set(_vm.conf, "tipo", $$v)},expression:"conf.tipo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"counter":30,"id":"inputLink","label":"Link","placeholder":_vm.conf.link,"required":""},model:{value:(_vm.conf.link),callback:function ($$v) {_vm.$set(_vm.conf, "link", $$v)},expression:"conf.link"}})],1),_c('v-col',{staticStyle:{"padding-left":"30px","padding-right":"30px"},attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"counter":30,"id":"inputCodice","label":"Codice","placeholder":_vm.conf.codice,"required":""},model:{value:(_vm.conf.codice),callback:function ($$v) {_vm.$set(_vm.conf, "codice", $$v)},expression:"conf.codice"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }