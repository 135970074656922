<template>
    <div id="quickgraphs_app">
        <GraphToolbar />
        <GraphMain />
        <GraphContextMenu />
    </div>
</template>
<script>

const LOCALDB = 'QuickGraphs';

import GraphToolbar from "@/components/GRAPH/Toolbar/GraphToolbar.vue";
import GraphContextMenu from "@/components/GRAPH/ContextMenu/GraphContextMenu.vue";
import GraphMain from "@/components/GRAPH/Main/GraphMain.vue";

export default {
    name : 'QuickGraphs',
    components : {
        GraphMain,
        GraphToolbar,
        GraphContextMenu
    },
    data(){
        return {
            SVG : window.SVG
        }
    },
    mounted(){
        /*
        this.buildDbSchema().then(function(){
            console.log('done');
        }).catch(function(){
            console.log('error');
        });
        */
    },
    methods : {
        buildDbSchema(){
            return new Promise(function(resolve,reject){
                const request = indexedDB.open(LOCALDB, 1);

                request.onerror = function(event){
                    console.error('Could not open Graph DB: ' + event.target.errorCode);
                    reject();
                };

                request.onupgradeneeded = function(event){
                    console.info('Building QuickGraphs local DB...');
                    const db = event.target.result;
                    const tables = [
                        { name : 'directories', key : 'id_cartella' },
                        { name : 'general_properties', key : 'type' },
                        { name : 'dir_properties', key : 'id_cartella' },
                        { name : 'ds_properties', key : 'id_ds' },
                        { name : 'files', key : 'id_file' },
                    ];
                    for(let i = 0; i < tables.length; i++){                       
                        const item = tables[i];
                        db.createObjectStore(item.name, { keyPath: item.key });
                    }   
                    db.close();                   
                    resolve();
                };

                request.onsuccess = function(event){
                    console.info('QuickGraphs local DB was found!');
                    event.target.result.close();
                    resolve();
                }
            });
        },
    }
}
</script>
<style src="./QuickGraphs.css" /> 