

class GRAPH_EDITOR{
    assi = [
        {nome:'X',valore:0},
        {nome:'Y',valore:1},
        {nome:'X2',valore:2},
        {nome:'Y2',valore:3},
    ];
    dettaglio = {};
    grafici = [
        {
            assi : {                
                mostra : true,
                no_opzioni : [0,2],
                regola : [1,-1,1,-1]                
            },
            multiserie : true,
            nome:'Cartesiano 2D',
            tipi : [
                {nome:'Linea',valore:0},
                {nome:'Area',valore:1},
                {nome:'Curva',valore:2},
                {nome:'Area Curva',valore:3},
                {nome:'Istogramma',valore:4},
            ],
            valore:0
        },
        {
            assi : {                
                mostra : true,
                no_opzioni : [1,3],
                regola : [-1,1,-1,1]
            },
            multiserie : true,
            nome:'Istogramma Orizzontale',
            tipi : [],
            valore:1
        },
        {
            assi : {                
                mostra : false,
                no_opzioni : [],
                regola : []
            },
            multiserie : false,
            nome:'Torta 2D',
            tipi : [],
            valore:2
        },
        {
            assi : {
                mostra : false,
                no_opzioni : [],
                regola : []
            },
            multiserie : true,
            nome:'Cruscotto',
            tipi : [],
            valore:3
        },
        {
            assi : {
                mostra : false,
                no_opzioni : [],
                regola : []
            },
            multiserie : true,
            nome:'Tabella',
            tipi : [
                {nome:'Aggregata Nascosta',valore:0},
                {nome:'Aggregata',valore:1},                
                {nome:'Colonna Pivot',valore:2},                
                {nome:'Non Aggregata',valore:3},               
            ],
            valore:4
        },
        {
            assi : {
                mostra : false,
                no_opzioni : [],
                regola : []
            },
            multiserie : true,
            nome:'Card',
            tipi : [],
            valore:5
        },
    ];
    tabelle = [];
    tipi_dati = [
        {nome:'Stringa',valore:0},
        {nome:'Numero',valore:1},
        {nome:'Data',valore:2},
    ];
    testata_parametri = [
        {text:'Simbolo',value:'cod'},
        {text:'Nome',value:'nome'},
        {text:'Tipo Dati',value:'tipo'},
        {text:'Valore',value:'valore'},        
    ]
    testata_serie = [
        {text:'Simbolo',value:'cod'},
        {text:'Nome',value:'nome'},
        {text:'Asse',value:'valore'},
        {text:'Tipo Dati',value:'tipo_dati'},
        {text:'Tipo Serie',value:'tipo_grafico'},
        {text:null,value:'formato'},   
        {text:null,value:'numero'},    
        {text:null,value:'cartesiano'}, 
        {text:null,value:'macro'},
    ];
    ui = {
        show : false,
        exit_prompt : false,
        tab : 0
    }

    // Impostazioni per l'editor
    editor = [{
        targetProperty : 'cod',
        color : 'red',
        keyword : '@',
        targetField : 'parametri',
        template : {
                        id_ds_dettaglio_param: -1,
                        id_ds_dettaglio_rif: -1,
                        cod: null,
                        nome: null,
                        valore: null,
                        tipo: 0,                        
                        validita: 1,
                    },
    },
    {
        targetProperty : 'cod',
        color : 'blue',
        keyword : '#',
        targetField : 'serie',
        template : {
                        id_ds_dettaglio_serie: -1,
                        id_ds_dettaglio_rif: -1,
                        cod: null,
                        nome: null,
                        valore: null,                        
                        tipo_dati: 0,
                        tipo_grafico: 0,
                        extra : null,
                        validita: 1,
                    }
    },
    {
        targetProperty : 'cod',
        color : 'orange',
        keyword : '&',
        targetField : 'condizioni',
        template : {
            id_ds_dettaglio_serie: -1,
            id_ds_dettaglio_rif: -1,
            cod : null,
            validita: 1,
            tipo_grafico : -1,
        }
    },
    {
        targetProperty : 'cod',
        color : '#660066',
        keyword : '$',
        targetField : null,
        template : {}
    },
    {
        targetProperty : 'cod',
        color : '#ff0066',
        keyword : '£',
        targetField : null,
        template : {}
    }
    ];

    // Metodi per il rendering condizionale



    getAssi(riga){
        if(this.grafici[this.dettaglio.famiglia_grafici]==undefined){return{};}
        let regola = this.grafici[this.dettaglio.famiglia_grafici].assi.regola;
        let result = [];
        for(let i=0; i < this.assi.length;i++){
            if(!this.dettaglio.assi[i].e){continue;}
            if(regola[i]==-1){
                result.push(this.assi[i]);
                continue;
            }            
            let indice = this.dettaglio.serie.indexOf(riga);
            let occorrenze = 0;
            let skip = false;
            for(let j=0; j < this.dettaglio.serie.length; j++){
                if(this.dettaglio.serie[j].valore == this.assi[i].valore){occorrenze++;}
                if(occorrenze >= regola[i]){
                    if(j == indice){
                        skip = false
                    }else{
                        skip = true;
                    }                    
                    break;
                }
            }
            if(skip){continue;}
            result.push(this.assi[i]);
        }
        return result;
    }
    getTestata(dettaglio){
        if(this.grafici[dettaglio.famiglia_grafici]==undefined){return [];}              
        let copy = [];
        let famiglia_grafici = dettaglio.famiglia_grafici;
        for(let i=0; i < this.testata_serie.length; i++){
            switch(this.testata_serie[i].value){
                case 'valore':{
                    if(!this.grafici[famiglia_grafici].assi.mostra){continue;}
                }
                break;
                case 'macro':{
                    if(famiglia_grafici != 4){continue;}
                }
                break;
                case 'cartesiano':{
                    if(famiglia_grafici != 0 && famiglia_grafici != 1){continue;}
                }
                break;
                case 'tipo_grafico':{
                    if(this.grafici[famiglia_grafici].tipi.length == 0){continue;}
                }
                break;
            }
            copy.push(this.testata_serie[i]);
        }
        return copy;
    }
    getTipoInput(tipo_dati){
        switch(tipo_dati){
            case 1:
                return 'number';
            case 2:
                return 'date';
            default:
                return 'text';
        }
    }
    getValiditaOpzioni(riga){
        if(this.grafici[this.dettaglio.famiglia_grafici]==undefined){return true;}
        let no_opzioni = this.grafici[this.dettaglio.famiglia_grafici].assi.no_opzioni;
        for(let i=0; i < no_opzioni.length; i++){
            if(no_opzioni[i] == riga.valore){
                return true;
            }
        }
        return false;
    }
    mostraAssi(dettaglio){
        if(this.grafici[dettaglio.famiglia_grafici]==undefined){return false;}
        return this.grafici[dettaglio.famiglia_grafici].assi.mostra;
    }
    is_bar(dettaglio,item){
        return(!this.getValiditaOpzioni(item) && (dettaglio.famiglia_grafici==0 || dettaglio.famiglia_grafici==1));
    }
    is_macroable(dettaglio,item){
        return (dettaglio.famiglia_grafici == 4 && item.tipo_grafico <= 1);
    }
}
export default GRAPH_EDITOR;