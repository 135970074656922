<template>
  <div style="width: 100%">
    <v-row> <CicloAutoControllo /></v-row>
  </div>
</template>
<script>
import CicloAutoControllo from "../../../components/Qualita/CicloAutoControllo/CicloAutocontrollo";
export default {
  components: {
    CicloAutoControllo,
  },
  data() {
    return {};
  },
};
</script>
