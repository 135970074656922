<template>
    <div class="gantt_container" style="padding:20px">       
        <GanttOverview @project="goTo" v-if="project==null" />               
        <GanttDiagram v-if="project!=null" @project="goTo(null)" :project="project"/>
    </div>
</template>
<script>
import GanttOverview from "@/components/GANTT/Views/Overview/Overview.vue";
import GanttDiagram from "@/components/GANTT/Views/Diagram/Diagram.vue";
export default {
    name : 'Ganttview',
    components : { GanttDiagram, GanttOverview },
    data(){
        return{ project : null }
    },
    methods : {
        goTo(project){ this.project = project; }
    }
}
</script>
<style>
    .gantt_container{ --gantt_accent : #00897b; }

    .gantt_container *{
        scrollbar-color: var(--gantt_accent) transparent!important;
        scrollbar-width: thin!important;
    }

    .gantt_container *::-webkit-scrollbar{
        width:6px!important;
        height:6px!important;
        overflow:overlay!important;
        position: relative!important;          
    }

    .gantt_container *::-webkit-scrollbar-track{ background: transparent!important ; }
    .gantt_container *::-webkit-scrollbar-corner{ background: transparent!important ; }
    .gantt_container *::-webkit-scrollbar-thumb{ background: var(--gantt_accent)!important; }
</style>