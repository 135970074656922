<template>
  <v-card id="listener_tabella_disegni">
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">Immagini </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="return_array === true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaArrayImmagini" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text style="max-height: 60vh; overflow-y: scroll">
      <v-container>
        <v-row
          align="center"
          justify="center"
          v-for="(item, i) in items_disegno"
          :key="i"
        >
          <v-btn
            style="overflow: hidden; margin: 5px"
            width="100"
            height="70"
            elevation="5"
            @click="reloadImage(item)"
          >
            <!--<div
            :style="`
              width:90px;height:70px;background:url('${item.disegno}');background-size:cover;background-position:center
            `"
            class="miniatura"
          ></div>-->
            <img
              width="100"
              height="70"
              :src="item.disegno"
              style="object-fit: contain; object-position: center"
            />
          </v-btn>
          <v-tooltip bottom v-if="return_array === false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5"
                @click="inviaImmagine(item.disegno)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Scegli Immagine</span>
          </v-tooltip>
          <v-tooltip bottom v-if="return_array === true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5"
                @click="clickEliminaImmagine(item)"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Elimina Immagine</span>
          </v-tooltip>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="conferma_elimina_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Elimina Immagine</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 20px">
            Sei sicuro di voler eliminare l'immagine?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-5"
                @click="chiudiConfermaDialog"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Annulla</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="eliminaImmagine" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  name: "TabellaDisegni",
  components: {},
  props: {
    disegni_iniziali: { type: Array, default: () => [] },
    destinazione: { type: String, default: "" },
    return_array: { type: Boolean, default: true },
    riferimento: { type: String, default: "" },
  },
  data() {
    return {
      // Variabili d'appoggio
      img_sel: new Object(),

      // Modal
      conferma_elimina_dialog: false,

      // Array
      items_disegno: [],
    };
  },
  computed: {},
  watch: {
    disegni_iniziali: {
      immediate: true,
      handler() {
        this.items_disegno = new Array();
        this.disegni_iniziali.forEach((elem) => this.items_disegno.push(elem));
      },
    },
  },
  methods: {
    aggiungiImmagine(arg) {
      // Cerco l'id_immagine che ricevo fra gli id_immagine degli oggetti che ho già.
      let i = -1;

      this.items_disegno.forEach((elem) => {
        if (elem.id_img == arg.detail.id_img) {
          i = this.items_disegno.indexOf(elem);
        } else {
          console.log("no buono: " + elem.id_img + " " + arg.detail.id_img);
        }
      });

      if (i == -1) {
        // Se l'id_img non è già ricorrente salvo una nuova immagine.
        let nuova_img = new Object();
        nuova_img.disegno = arg.detail.img;
        nuova_img.id_img = arg.detail.id_img;
        this.items_disegno.push(nuova_img);
      } else {
        // Se l'id_img ricorre, sostituisco l'immagine
        let nuova_img = new Object();
        nuova_img.disegno = arg.detail.img;
        nuova_img.id_img = arg.detail.id_img;

        this.items_disegno.splice(i, 1);
        this.$nextTick(() => {
          this.items_disegno.push(nuova_img);
        });
      }
    },
    chiudiConfermaDialog() {
      this.conferma_elimina_dialog = false;
      this.img_sel = new Object();
    },
    clickEliminaImmagine(arg) {
      this.img_sel = Object.assign({}, arg);
      this.conferma_elimina_dialog = true;
    },
    eliminaImmagine() {
      let i = this.items_disegno.indexOf(
        (elem) => elem.id_img === this.img_sel.id_img
      );
      this.items_disegno.splice(i, 1);
      this.img_sel = new Object();
      this.conferma_elimina_dialog = false;
    },
    reloadImage(item) {
      let messaggio = {
        detail: {
          id_img: item.id_img,
          img: item.disegno,
        },
        src: null,
      };

      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("ricarica_img", messaggio);

      // Recapito l'evento alla destinazione fuori del componente.
      document.getElementById("listener_visualizzatore").dispatchEvent(evento);
    },
    inviaImmagine(arg) {
      let messaggio = {
        detail: {
          img: arg,
          id: this.riferimento,
        },
        src: null,
      };

      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("nuova_img", messaggio);

      // Recapito l'evento alla destinazione fuori del componente.
      document.getElementById(this.destinazione).dispatchEvent(evento);
      this.$emit("scelta_immagine");
    },
    salvaArrayImmagini() {
      let imgs = new Array();

      this.items_disegno.forEach((elem) => {
        imgs.push(elem.disegno);
      });

      let messaggio = {
        detail: {
          imgs: imgs,
          id: this.riferimento,
        },
        src: null,
      };

      // Inserisco il messaggio in un evento custom.
      let evento = new CustomEvent("nuova_img", messaggio);

      // Recapito l'evento alla destinazione fuori del componente.
      document.getElementById(this.destinazione).dispatchEvent(evento);
      this.$emit("scelta_immagine");
    },
  },
  created() {},
  mounted() {
    document
      .getElementById("listener_tabella_disegni")
      .addEventListener("nuova_img", this.aggiungiImmagine);
  },
};
</script>
<style scoped>
</style>