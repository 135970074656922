import moment from "moment/moment";


/* Questa classe fornisce l'oggetto di riferimento
 * per gli Interventi di Manutenzione all'interno del modulo
 * Manutenzione per EduCatt e altri. */
//import moment from "moment/moment";
export default class Intervention {
    constructor(arg) {
        if (arg != undefined) {
            this.allegati = arg.allegati != undefined ? arg.allegati : [];
            this.attivita = arg.attivita != undefined ? arg.attivita : [];
            this.cod_intervento = arg.cod_intervento != undefined ? arg.cod_intervento : '';
            this.cod_regola = arg.cod_regola != undefined ? arg.cod_regola : '';
            this.cod_stato = arg.cod_stato != undefined ? arg.cod_stato : "";
            this.cod_stato_operativo = arg.cod_stato_operativo != undefined ? arg.cod_stato_operativo : "";
            this.cod_stato_richiesta = arg.cod_stato_richiesta != undefined ? arg.cod_stato_richiesta : "";
            this.consuntivo = arg.consuntivo != undefined ? arg.consuntivo : [{
                id_consuntivo: -1,
                id_intervento: -1,
                note: "",
                validita: 1
            }];
            this.costo_stimato = arg.costo_stimato != undefined ? arg.costo_stimato : 0;
            this.data_prevista = arg.data_prevista != undefined ? arg.data_prevista : "";
            this.data_richiesta = arg.data_richiesta != undefined ? arg.data_richiesta : "";
            this.data_schedulata = arg.data_schedulata != undefined ? arg.data_schedulata : "";
            this.des_area = arg.des_area != undefined ? arg.des_area : '';
            this.descrizione = arg.descrizione != undefined ? arg.descrizione : '';
            this.des_fornitori = arg.des_fornitori != undefined ? arg.des_fornitori : [];
            this.des_intervento = arg.des_intervento === undefined ? "" : arg.des_intervento;
            this.des_macchinario = arg.des_macchinario === undefined ? "" : arg.des_macchinario;
            this.des_referente = arg.des_referente != undefined ? arg.des_referente : '';
            this.des_stanza = arg.des_stanza === undefined ? "" : arg.des_stanza;
            this.des_regola = arg.des_regola != undefined ? arg.des_regola : '';
            this.des_sede = arg.des_sede != undefined ? arg.des_sede : '';
            this.des_stato = arg.des_stato != undefined ? arg.des_stato : '';
            this.des_stato_operativo = arg.des_stato_operativo != undefined ? arg.des_stato_operativo : '';
            this.des_struttura = arg.des_struttura != undefined ? arg.des_struttura : '';
            this.des_tipo_intervento = arg.des_tipo_intervento != undefined ? arg.des_tipo_intervento : '';
            this.des_tipo_manutenzione = arg.des_tipo_manutenzione != undefined ? arg.des_tipo_manutenzione : '';
            this.des_urgenza = arg.des_urgenza != undefined ? arg.des_urgenza : '';
            this.des_zona = arg.des_zona != undefined ? arg.des_zona : '';
            this.fornitori = arg.fornitori != undefined ? arg.fornitori : [];
            this.frequenza = arg.frequenza != undefined ? arg.frequenza : null;
            this.id_area = arg.id_area != undefined ? arg.id_area : null;
            this.id_stanza = arg.id_stanza != undefined ? arg.id_stanza : null;
            this.id_concessionario = arg.id_concessionario != undefined ? arg.id_concessionario : null;
            this.id_consuntivo = arg.id_consuntivo != undefined ? arg.id_consuntivo : null;
            this.id_intervento = arg.id_intervento != undefined ? arg.id_intervento : -1;
            this.id_macchinario = arg.id_macchinario != undefined ? arg.id_macchinario : null;
            this.id_regola = arg.id_intervento != undefined ? arg.id_regola : null;
            this.id_richiesta = arg.id_richiesta != undefined ? arg.id_richiesta : null;
            this.id_sede = arg.id_sede != undefined ? arg.id_sede : null;
            this.id_stato = arg.id_stato != undefined ? arg.id_stato : null;
            this.id_stato_operativo = arg.id_stato_operativo != undefined ? arg.id_stato_operativo : null;
            this.id_struttura = arg.id_struttura != undefined ? arg.id_struttura : null;
            this.id_tipo_intervento = arg.id_tipo_intervento != undefined ? arg.id_tipo_intervento : null;
            this.id_tipo_manutenzione = arg.id_tipo_manutenzione != undefined ? arg.id_tipo_manutenzione : '';
            this.id_urgenza = arg.id_urgenza != undefined ? arg.id_urgenza : null;
            this.id_utente = localStorage.id;
            this.id_zona = arg.id_zona != undefined ? arg.zona : null;
            this.interno = arg.interno != undefined ? arg.interno : false;
            this.note = arg.note != undefined ? arg.note : '';
            this.numero_ordine = arg.numero_ordine != undefined ? arg.numero_ordine : null;
            this.ora_schedulata = arg.ora_schedulata != undefined ? moment(arg.ora_schedulata, "HH:mm").format("HH:mm") : moment().format("HH:mm");
            this.preventivo = arg.preventivo != undefined ? arg.preventivo : false;
            this.soluzione = arg.soluzione != undefined ? arg.soluzione : '';
            this.um_frequenza = arg.um_frequenza != undefined ? arg.um_frequenza : null;
            this.validita = arg.validita != undefined ? arg.validita : 1;
        } else {
            this.allegati = [];
            this.attivita = [];
            this.cod_intervento = "";
            this.cod_regola = "";
            this.cod_stato_richiesta = "";
            this.costo_stimato = 0;
            this.consuntivo = [{
                id_consuntivo: -1,
                id_intervento: -1,
                note: "",
                validita: 1
            }];
            this.des_area = "";
            this.descrizione = '';
            this.des_fornitori = [];
            this.des_intervento = "";
            this.des_macchinario = "";
            this.des_referente = "";
            this.des_regola = "";
            this.des_sede = "";
            this.des_stanza = "";
            this.des_stato = "";
            this.des_stato_operativo = '';
            this.des_struttura = "";
            this.des_tipo_intervento = "";
            this.des_tipo_manutenzione = "";
            this.des_urgenza = "";
            this.data_prevista = "",
                this.data_richiesta = "";
            this.data_schedulata = "",
                this.des_zona = "";
            this.fornitori = [];
            this.frequenza = null;
            this.id_area = null;
            this.id_concessionario = null;
            this.id_consuntivo = null;
            this.id_intervento = -1;
            this.id_macchinario = null;
            this.id_regola = null;
            this.id_richiesta = null;
            this.id_sede = null;
            this.id_stato = null;
            this.id_stato_operativo = null;
            this.id_struttura = null;
            this.id_tipo_intervento = null;
            this.id_tipo_manutenzione = null;
            this.id_urgenza = null;
            this.id_utente = localStorage.id;
            this.id_zona = null;
            this.id_stanza = null;
            this.interno = false;
            this.note = "";
            this.numero_ordine = null;
            this.ora_schedulata = moment().format("HH:mm");
            this.preventivo = false;
            this.soluzione = '';
            this.um_frequenza = null;
            this.validita = 1;
        }
    }
}