
<template>
  <div id="gantt_context_menu" ref="contextmenu">
    <p @click="select('add')" v-show="type == 0">
      <GraphSVG name="plus" />
      <span>Aggiungi sottoattività</span>
    </p>
    <p @click="select('edit')" v-show="type == 0">
      <GraphSVG name="pencil" />
      <span>Modifica attività</span>
    </p>
    <p @click="select('delete')" v-show="type == 0">
      <GraphSVG name="delete" />
      <span>Elimina attività</span>
    </p>
    <p @click="select('unlink')" v-show="type == 1">
      <GraphSVG name="link_off" />
      <span>Elimina collegamento</span>
    </p>
  </div>
</template>
<script>
let REF;
let MENU;
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
  name: "GanttContextMenu",
  components: {
    GraphSVG,
  },
  data() {
    return {
      type: 0,
    };
  },
  mounted() {
    REF = this;
    MENU = this.$refs.contextmenu;
    document.body.addEventListener("contextmenu", REF.show);
  },
  beforeDestroy() {
    document.body.removeEventListener("contextmenu", REF.show);
  },
  methods: {
    hide() {
      MENU.style.display = "none";
      document.body.removeEventListener("click", REF.hide);
    },
    select(action) {
      REF.$emit("contextmenu", {
        reference: REF.target,
        action: action,
      });
    },
    show(event) {
      event.preventDefault();
      const path = document.elementsFromPoint(event.clientX, event.clientY);
      const length = path.length;
      let target;
      for (let i = 0; i < length; i++) {
        target = path[i];
        const tag = target.tagName.toLowerCase();
         if (tag == "body") {
          return;
        }
        let element = target;
        let exit = false;
        while (element.tagName.toLowerCase() != "body") {
          if (element.connectionreference != null) {
            REF.type = 1;
            REF.target = element;
            exit= true;
            break;
          }
          if (element.taskreference != null) {
            REF.type = 0;
            REF.target = element.taskreference;
            exit=true;
            break;
          }
          element = element.parentElement;
        }
        if(exit) break
      }
       
      Object.assign(MENU.style, {
        display: "block",
        left: event.clientX + "px",
        top: event.clientY + "px",
      });
      document.body.addEventListener("click", REF.hide);
    },
  },
};
</script>
<style scoped>
#gantt_context_menu {
  position: fixed;
  background-color: white;
  display: none;
  z-index: 2;
  text-align: left;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
#gantt_context_menu > p {
  display: block;
  margin: 0 auto;
  padding: 10px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}
#gantt_context_menu > p:hover {
  background-color: rgba(153, 0, 51, 0.3);
}
#gantt_context_menu > p:active {
  transition: background-color 0s ease;
  background-color: rgba(153, 0, 51, 0.5);
  color: white;
}
#gantt_context_menu > p > * {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
</style>