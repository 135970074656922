<template>
    <div>
        <v-dialog v-model="SHOW" width="400px" persistent>
            <div class="graph_layout">
                <p>Salvare il layout corrente?</p>
                <span @click="SHOW=false">ANNULLA</span>
                <span @click="save">CONFERMA</span>
            </div>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name : 'GraphLayout',
    data(){
        return{
            SHOW : false,
            NETWORK : null,
            LAYOUTS : []
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        document.body.addEventListener('graph_layout',this.show_layout);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_layout',this.show_layout);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        show_layout(event){
            this.LAYOUTS = [];
            let data = event.detail; 
            if(data.length<=0){return;}                     
            for(let i=0;i<data.length;i++){
                let widget = document.getElementById('widget-'+data[i].index);
                if(widget==null||widget==undefined){continue;}
                let layout_item = JSON.stringify({
                        x       : widget.offsetLeft,
                        y       : widget.offsetTop,
                        w       : widget.offsetWidth,
                        h       : widget.offsetHeight
                });
                this.LAYOUTS.push({
                    id_ds_dettaglio : data[i].id_ds_dettaglio,
                    layout : btoa(unescape(encodeURIComponent(layout_item)))
                })
            }
            this.SHOW = true;
        },
        save(){
            let that = this;
            let request = {
                controller : 'Graph',
                body : {
                    action: "setOverall",
                    token: localStorage.user_token,
                    overall: this.LAYOUTS,
                },
                method: 'PUT',
                on : {
                    load : function(){ that.SHOW = false; }
                }
            }
            this.NETWORK.AjaxRequest(request);          
        },
    }
}
</script>
<style scoped>
@import "../../Generale/Temi/Default.css";
.graph_layout{
    background-color: white;
    position: relative;
    width:100%;
    padding:10px;
    text-align: left;
    user-select: none;
    font-weight: bold;
}
.graph_layout > p{
    position: relative;
    font-size:15px;
    text-align: center;
}
.graph_layout > nav{
    display: block;
    width:100%;
    height:5px;
    border-radius: 10px;
    position: relative;
    margin-bottom:15px;
    text-align: left;
    overflow: hidden;
}
.graph_layout > nav > div{
    position: relative;
    margin:0;
    height:100%;
    border-radius:10px;
    background-color:var(--widget_title_background);
    width:0;
}
.graph_layout > span{
    display: inline-block;
    vertical-align: middle;
    color:#C63A02;
    font-size: 14px;
    cursor: pointer;
}
.graph_layout > span:nth-of-type(2){float:right;color:#03B644}
.graph_layout > span[data-disabled]{
    cursor:default;
    pointer-events: none;
    color: grey;
}
</style>