<template>
  <div style="width: 100%" id="divz" class="px-5">
    <v-overlay v-model="caricamento">
      <v-progress-circular
        indeterminate
        size="60"
        color="teal accent-2"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="2">
        <div class="glass px-5 mt-2">
          <v-switch
            color="teal accent-2"
            dark
            label="Nascondi completate"
            @change="filtraCompletati()"
            v-model="mostra_completati"
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="glass px-5 mt-2">
          <v-container>
            <v-row>
              <v-text-field
                color="teal accent-2"
                dark
                label="Cerca"
                v-model="filtro_title"
              >
              </v-text-field>

              <v-btn fab small class="mt-3 ml-3">
                <v-icon
                  :disabled="empty_field"
                  v-if="mostra_reset_filtro == false"
                  @click="filtraByTitle()"
                  >mdi-magnify</v-icon
                >
                <v-icon v-else class="" @click="resetFiltro()"
                  >mdi-filter-off-outline</v-icon
                >
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="glass px-5 mt-2">
          <v-select
            color="teal accent-2"
            dark
            label="Ambito"
            :items="ambiti"
            item-text="des"
            item-value="id_ambito"
            @change="filtraByAmbito()"
            v-model="ambito_sel"
          >
          </v-select>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="glass px-5 mt-2">
          <v-select
            color="teal accent-2"
            dark
            label="Tipo"
            :items="[
              { value: 0, text: 'Cliente' },
              { value: 1, text: 'Assitenza' },
            ]"
            item-text="text"
            item-value="value"
            @change="getTasks(tipo_sel)"
            v-model="tipo_sel"
          >
          </v-select>
        </div>
      </v-col>
      <v-col cols="1">
        <div class="glass mt-2" style="padding: 15px">
          <v-btn fab small>
            <v-icon @click="resetFiltro()">mdi-filter-off-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row id="rowz" style="min-height: 100vh">
      <!-- <v-col
        cols="2"
        class="pr-0 pt-0 pl-0"
        style="border-right: 1px solid grey; max-height: 90vh"
      >
        <div style="border-bottom: 1px solid grey; height: 50px">
          <h3 class="mt-5 mr-3">Attività/risorse</h3>
        </div>
        <v-row
          class="mr-0"
          style="height: 85vh; max-width: 100%; overflow-x: scroll"
        >
          <draggable
            v-model="tasks"
            group="people"
            class="mt-3"
            @start="drag = true"
            @end="(drag = false), assegnaTask()"
            v-bind:style="{
              margin: margine,
              height: altezza,
              width: larghezza,
            }"
          >
            <div
              v-for="element in tasks"
              :key="element.id"
              class="draggable mt-1"
              style="
                height: 60px;
                width: 100%;
                border-radius: 10px;
                padding: 10px;
                margin-bottom: 10px;
              "
            >
              <span>{{ element.des_attivita }}</span>
              <br />
              <i style="opacity: 0.7; font-size: 12px"
                >{{ element.nominativo }}
              </i>
            </div>
          </draggable>
        </v-row>
      </v-col> -->
      <v-col cols="12" class="pt-0" style="overflow-x: scroll"
        ><v-row style="width: max-content" :key="aggiornarisorse">
          <div class="pt-2" v-for="(item, index) in risorse" :key="index">
            <div
              v-if="index == 0"
              class="mb-4"
              style="
                border-bottom: 1px solid grey;
                height: 50px;
                background-color: #3f51b5;
                border-radius: 5px 0px 0px 5px;
              "
            >
              <h3 style="color: white" class="mr-10 ml-5 mt-8 pt-3">
                {{ item.nominativo }}
              </h3>
            </div>
            <div
              v-if="index == risorse.length - 1"
              class="mb-4"
              style="
                border-bottom: 1px solid grey;
                height: 50px;
                background-color: #3f51b5;
                border-radius: 0px 5px 5px 0px;
              "
            >
              <h3 style="color: white" class="mr-10 ml-5 mt-8 pt-3">
                {{ item.nominativo }}
              </h3>
            </div>
            <div
              v-if="index != 0 && index != risorse.length - 1"
              class="mb-4"
              style="
                border-bottom: 1px solid grey;
                height: 50px;
                background-color: #3f51b5;
              "
            >
              <h3 style="color: white" class="mr-10 ml-5 mt-8 pt-3">
                {{ item.nominativo }}
              </h3>
            </div>
            <draggable
              v-model="item.tasks"
              group="people"
              @start="drag = true"
              @end="(drag = false), assegnaTask()"
              style="
                height: 100%;
                width: 250px;
                max-height: 85vh;
                overflow-y: scroll;
              "
            >
              <div
                v-for="(element, index) in item.tasks"
                :key="index"
                :class="element.class"
                @dblclick="OpenDialogComponente(element)"
                style="
                  height: fit-content;
                  width: 80%;
                  border-radius: 10px;
                  padding: 10px;
                  margin-bottom: 10px;
                "
              >
                <span>
                  <b>{{ element.numero }}</b>
                </span>
                <br />
                <i
                  v-if="element.des_progetto != null"
                  style="opacity: 0.7; font-size: 14px"
                  >{{ element.des_prgogetto }}
                </i>
                <i
                  v-if="element.titolo_task != null"
                  style="opacity: 0.7; font-size: 14px"
                  >{{ element.titolo_task }}
                </i>
                <br />
                <i style="opacity: 0.7; font-size: 12px"
                  >{{ element.des_stato }}
                </i>
                <v-progress-linear
                  striped
                  :color="calcolaColore(element.percentuale_completamento)"
                  v-model="element.percentuale_completamento"
                  height="13"
                >
                  <strong
                    >{{ Math.ceil(element.percentuale_completamento) }}%</strong
                  >
                </v-progress-linear>
              </div>
            </draggable>
          </div>
        </v-row></v-col
      >
    </v-row>
    <v-dialog content-class="dialogX" v-model="dialog_componente" style="max-width:fit-content;">
      <v-card>
        <v-toolbar color="indigo">
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab small
            ><v-icon color="error" @click="dialog_componente = false"
              >mdi-close</v-icon
            ></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <SingleTicket
                ref="single"
                :key="renderSingle"
                :tipo_permesso="tipo_permesso"
                :hide_back_arrow="true"
                :assistenza="tipo_assistenza"
                v-show="clicked == 'TCK'"
              />
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AddTask
      :id_task="id_task"
      :assistenza="tipo_assistenza"
      :id_parent="id_ticket"
      :key="renderTask"
      ref="tsk"
      v-show="clicked == 'TSK'"
    />

    <SingleTicket ref="single" v-show="false" />

    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import _ from "lodash";
import SingleTicket from "../TICKET/SingleTicket.vue";
import AddTask from "../TICKET/AddTask.vue";
export default {
  components: {
    draggable,
    SingleTicket,
    AddTask,
  },
  data() {
    return {
      mostra_completati: false,
      mostra_reset_filtro: false,
      tasks: [],
      risorse: [],
      filtro_title: "",
      risorse_backup: [],
      tasks_backup: [],
      margine: "0 auto",
      altezza: "100%",
      larghezza: "70%",
      aggiornarisorse: 0,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      empty_field: false,
      dialog_componente: false,
      ambito_sel: null,
      tipo_assistenza: 0,
      tipo_permesso: "",
      id_parent: 0,
      id_ticket: 0,
      id_task: 1,
      ambiti: [],
      caricamento: false,
      scala: 1,
      renderSingle: 0,
      renderTask: false,
      tipo_sel: 1,
      clicked: null,
    };
  },
  mounted() {
    // this.getTask();
    this.getRisorse();
    this.getambiti();
  },
  watch: {
    filtro_title: {
      immediate: true,
      handler() {
        if (this.filtro_title == "" || this.filtro_title == null) {
          this.empty_field = true;
        } else {
          this.empty_field = false;
        }
      },
    },
    tipo_sel: {
      immediate: true,
      handler() {
        if (this.tipo_sel == 1) {
          this.getambiti();
        } else {
          this.gettabelle();
        }
      },
    },
  },
  methods: {
    OpenDialogComponente(element) {
      if (element.numero != undefined) {
        if (element.numero.includes("TC")) {
          this.dialog_componente = true;
          this.tipo_assistenza = this.tipo_sel;
          this.$refs.single.$route.params.id = element.id_ticket;
          this.$refs.single.drawer = false;
          this.renderSingle++;
          this.clicked = "TCK";
        } else {
          this.id_ticket = element.id_ticket_padre;
          this.id_task = element.id_ticket;
          this.tipo_assistenza = this.tipo_sel;
          this.$refs.tsk.dialog = true;
          this.clicked = "TSK";
          this.renderTask == false ? true : false;
        }
      } else {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Ticket o task non valido!";
        this.snackbar_text_color = "white";
        return;
      }
      console.log("CLICKED:", this.clicked);
    },
    filtraByAmbito() {
      this.risorse = _.cloneDeep(this.risorse_backup);
      this.risorse.forEach((risorsa) => {
        console.log(risorsa.tasks);
        risorsa.tasks = risorsa.tasks.filter(
          (task) => task.id_ambito == this.ambito_sel
        );
      });
    },
    filtraByTitle() {
      this.risorse.forEach((risorsa) => {
        console.log(risorsa.tasks);
        risorsa.tasks = risorsa.tasks.filter((task) =>
          task.des_progetto == null
            ? task.titolo_task
                .toLowerCase()
                .includes(this.filtro_title.toLowerCase())
            : task.titolo_task
                .toLowerCase()
                .includes(this.filtro_title.toLowerCase()) ||
              task.des_progetto
                .toLowerCase()
                .includes(this.filtro_title.toLowerCase())
        );
      });
      this.mostra_reset_filtro = true;
    },
    resetFiltro() {
      this.risorse = _.cloneDeep(this.risorse_backup);
      this.mostra_reset_filtro = false;
      this.mostra_completati = false;
      this.ambito_sel = null;
      this.aggiornarisorse++;
    },
    filtraCompletati() {
      if (this.mostra_completati) {
        this.risorse.forEach((risorsa) => {
          console.log(risorsa.tasks);
          risorsa.tasks = risorsa.tasks.filter(
            (task) => task.percentuale_completamento < 100
          );
        });
      } else {
        this.resetFiltro();
      }
    },
    calcolaColore(percentuale) {
      var colore = null;
      if (percentuale < 20) {
        colore = "red";
        return colore;
      } else if (percentuale >= 20 && percentuale < 70) {
        colore = "orange";
        return colore;
      } else if (percentuale >= 70 && percentuale < 100) {
        colore = "success";
        return colore;
      } else if (percentuale == 100) {
        colore = "green";
        return colore;
      }
    },

    gettabelle() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo: "AMBITO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.ambiti = res.data;
      });
    },
    getambiti() {
      let request = {
        controller: "Ticket",
        method: "POST",
        richiesta: {
          action: "getambito",
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        // Recupero dinamicamente gli id degli stati approvativi degli interventi
        if (res.data.result != undefined) {
          this.ambiti = res.data.result.ambiti;
        }
      });
    },
    // getTask() {
    //   var request = {
    //     controller: "Modulo",
    //     method: "POST",
    //     richiesta: {
    //       action: "getattivitaview",
    //       id_utente: localStorage.id,
    //       token: localStorage.user_token,
    //       prefisso_utente: localStorage.prefisso_utente,
    //     },
    //   };
    //   this.$store.dispatch("api", request).then((res) => {
    //     if (res.status == 200) {
    //       console.log("res ge task:", res.data);
    //       this.tasks = res.data.result.attivita;
    //       this.getRisorse();
    //     } else {
    //       this.$store.state.snackbar.stato = true;
    //       this.$store.state.snackbar.messaggio = "Nessun elemento trovato!";
    //       this.$store.state.snackbar.colore = "#FFB300";
    //       this.$store.state.snackbar.timeout = 2000;
    //     }
    //   });
    //},
    getRisorse() {
      var getUtenti = {
        controller: "Utente",
        method: "POST",
        richiesta: {
          action: "getutentelist",
          token: localStorage.user_token,
          id_utente: localStorage.id,
        },
      };
      this.$store.dispatch("api", getUtenti).then((res) => {
        if (res.status == 200) {
          let resTmp = res.data.result.gp_personale_utenti;
          console.log("res", resTmp);
          this.risorse = resTmp.filter((e) => e.id_profilo_utente == 10);
          this.risorse_backup = resTmp.filter((e) => e.id_profilo_utente == 10);
          this.risorse.forEach((e) => {
            e.nominativo = e.nome + " " + e.cognome;
            e.tasks = [];
          });
          this.getTasks();
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Nessun elemento trovato";
          this.snackbar_text_color = "white";
        }
      });
    },
    getTasks(tipo_sel = 1) {
      this.caricamento = true;
      var request = {
        controller: "Ticket",
        method: "POST",
        richiesta: {
          action: "getticket",
          token: localStorage.user_token,
          id_stato: "",
          id_ticket: null,
          assistenza: tipo_sel,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status == 200) {
            this.tipo_permesso = res.data.result.tipo_permesso;
            this.caricamento = false;
            console.log("TICKETS:", res.data);
            let tickets_tmp = res.data.result.lista;
            this.risorse.forEach((e) => {
              if (e.tasks) {
                e.tasks = [];
              }
            });
            this.risorse_backup.forEach((e) => {
              if (e.tasks_backup) {
                e.tasks = [];
              }
            });
            this.risorse.forEach((risorse) => {
              tickets_tmp.forEach((ticket) => {
                if (risorse.id_personale == ticket.id_responsabile) {
                  if (ticket.numero != null) {
                    if (ticket.numero.includes("TC")) {
                      ticket.class = "draggableTicket mx-3 ml-5 mt-1";
                    } else {
                      ticket.class = "draggableTask mx-3 ml-5 m5-1";
                    }
                  } else {
                    ticket.class = "draggableEmpty mx-3 ml-5 m5-1";
                  }
                  if (ticket.min_previsti != 0) {
                    ticket.percentuale_completamento =
                      (100 * ticket.min_lavorati) / ticket.min_previsti;
                  } else {
                    ticket.percentuale_completamento = 100;
                  }
                  risorse.tasks.push(Object.assign({}, ticket));
                }
              });
            });
            this.risorse_backup = _.cloneDeep(this.risorse);

            this.risorse.forEach((e) => {
              e.tasks.sort((a, b) => a.priorita - b.priorita);
            });
            this.aggiornarisorse++;
            console.log("RISORSE BACKUP:", this.risorse_backup);
          } else {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Nessun elemento trovato";
            this.snackbar_text_color = "white";
          }
        })
        .catch(() => {
          this.caricamento = false;
        });
    },
    assegnaTask() {
      for (let i = 0; i < this.risorse.length; i++) {
        for (let j = 0; j < this.risorse[i].tasks.length; j++) {
          this.risorse[i].tasks[j].priorita = j;
        }
      }
      let tasks_tmp = [];
      this.risorse.forEach((e) => {
        if (e.tasks.length != 0) {
          e.tasks.forEach((f) => {
            f.id_responsabile = e.id_personale;
            delete f.id_responsabile1;
            f.id_risorsa = e.id_personale;
            let obj_tmp = Object.assign({}, f);
            tasks_tmp.push(obj_tmp);
          });
        }
      });
      tasks_tmp.forEach((e) => {
        e.numero = e.numero.substring(4);
      });
      var request = {
        controller: "Modulo",
        method: "PUT",
        richiesta: {
          action: "setrisorsetaskviewupdated",
          id_utente: localStorage.id,
          token: localStorage.user_token,
          prefisso_utente: localStorage.prefisso_utente,
          task: tasks_tmp,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check-outline";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Ticket/Task assegnato con successo!";
          this.snackbar_text_color = "white";
          this.getTasks();
        } else {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Errore tecnico. Non è stato possibile salvare i dati!";
          this.snackbar_text_color = "white";
        }
      });
      console.log("RISORSE AGGIORNATE:", this.risorse);
      console.log("TASK RIMASTI:", this.tasks);
      this.aggiornarisorse++;
      //chiamata assegnaTask al BE
    },
  },
};
</script>
<style >
.draggableTicket:hover {
  cursor: grab;
  box-shadow: 0px 0px 10px cornflowerblue;
  transition: 0.25s;
}
.draggableTicket:active {
  cursor: grabbing;
  box-shadow: 0px 0px 12px aqua;
  transition: 0.25s;
}

.draggableEmpty {
  background-color: rgb(134, 132, 132);
  transition: 0.25s;
  box-shadow: 0px 0px 3px grey;
}

.draggableTicket {
  background-color: bisque;
  transition: 0.25s;
  box-shadow: 0px 0px 3px grey;
}

.draggableTask {
  background-color: lightgreen;
  transition: 0.25s;
  box-shadow: 0px 0px 3px grey;
}
.draggableTask:hover {
  cursor: grab;
  box-shadow: 0px 0px 10px rgb(60, 212, 67);
  transition: 0.25s;
}
.draggableTask:active {
  cursor: grabbing;
  box-shadow: 0px 0px 12px rgb(208, 255, 0);
  transition: 0.25s;
}
.glass {
  background: rgba(59, 59, 59, 0.7) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(5.5px) !important;
  -webkit-backdrop-filter: blur(5.5px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}
.icon {
  color: white;
  transition: 0.25s;
}
.icon:hover {
  color: #64ffda;
  transition: 0.25s;
}
.btn:hover {
  color: #64ffda !important;
  transition: 0.25s;
  transform: scale(1.1);
}
.dialogX{
  max-width: fit-content;
}
</style>