 <template>
  <div style="padding: 20px">
    <v-card>
      <v-snackbar :color="colore_snack" v-model="snackbar">
        {{ this.messaggio_snackbar }}
        <v-btn color="white" text @click="snackbar = false">Chiudi</v-btn>
      </v-snackbar>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>GESTIONE UTENTI</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark text @click="$router.replace('/home')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <v-toolbar-items>
        <v-btn dark text @click="updateValue">Salva</v-btn>
      </v-toolbar-items>-->
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-toolbar color="blue darken-4" dark>
              <v-toolbar-title>Tabella Utenti</v-toolbar-title>
              <v-row>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="nominativi_sel"
                    :items="nominativi_item"
                    item-text="nominativo"
                    hide-no-data
                    hide-details
                    chips
                    multiple
                    label="Filtra Utenti"
                    solo-inverted
                    class="ml-5"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="cancella_autocomplete2(data.item)"
                        >{{ data.item.nominativo }}</v-chip
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="stato_sel"
                    :items="stati"
                    item-text="testo"
                    flat
                    hide-no-data
                    hide-details
                    chips
                    return-object
                    label="Filtra Stato"
                    solo-inverted
                    class="ml-5"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="cancella_autocomplete(data.item)"
                        >{{ data.item.testo }}</v-chip
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-btn
                v-if="utentiSelezionati.length == 0"
                color="primary"
                class="ml-2"
                small
                fab
                dark
                @click="gestisciInsertUtenti('', 'inserisci')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-menu bottom left v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    color="teal"
                    class="ml-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="gestisciInsertUtenti('', 'inserisci')">
                    <v-list-item-action>
                      <v-btn color="primary" class="ml-2" small fab dark>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Inserisci Utenti</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-divider></v-divider>
                  <v-list-item @click="setUtente(null, 'password')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="blue">
                        <v-icon medium>mdi-lock-reset</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Forza Cambio Password</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="setUtente(null, 'resetpassword')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="grey">
                        <v-icon medium>mdi-lock-question</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Reset Password</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="setUtente(null, 'blocca')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="orange">
                        <v-icon medium>mdi-account-lock</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Blocca Utente</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="setUtente(null, 'sblocca')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="accent">
                        <v-icon medium>mdi-account-key</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Sblocca Utente</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="setUtente(null, 'attiva')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="green">
                        <v-icon medium>mdi-account-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Attiva Utente</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="setUtente(null, 'disattiva')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="red">
                        <v-icon medium>mdi-account-off</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Disattiva Utente</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="setUtente(null, 'elimina')">
                    <v-list-item-action>
                      <v-btn class="mx-2" fab dark small color="black">
                        <v-icon medium>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Elimina Utente</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
              <!-- :loading="dipendenti.length == 0 ? true : false"
              loading-text="Caricamento... attendere" -->
            <v-data-table
              v-model="utentiSelezionati"
              :headers="headers"
              @click:row="rowClick"
              :items="dipendenti"
              class="elevation-1"
              item-key="id_personale"
              :single-select="false"
              show-select
              style="overflow-y: auto; max-height: 700px; cursor: pointer"
              :footer-props="footerProps"
            >
              <template v-slot:[`item.stato`]="{ item }">
                <v-chip :color="setColoreStatoDipendenti(item)" dark>{{
                  setStatoDipendenti(item)
                }}</v-chip>
              </template>
              <template v-slot:[`item.gestione`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="yellow darken-3"
                      @click="gestisciInsertUtenti(item, 'modifica')"
                      v-on="on"
                    >
                      <v-icon medium>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Utente</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="red darken-2"
                      @click="setUtente(item, 'elimina')"
                      v-on="on"
                    >
                      <v-icon medium>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina Utente</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog v-model="dialog_conferma" max-width="40%" persistent>
        <v-card>
          <v-card-title v-html="titolo_conferma"></v-card-title>
          <v-card-text>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-textarea
                    v-show="arrivo == 'sblocca' || arrivo == 'blocca'"
                    outlined
                    model="note_blocco"
                    name="input-7-4"
                    label="Note"
                    value
                  ></v-textarea>
                  <v-text-field
                    filled
                    v-show="arrivo == 'resetpassword'"
                    v-model="newpassword"
                    style="max-width: 75%; margin: 0 auto"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    prepend-inner-icon="mdi-lock"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    class="input-group--focused"
                    @click:append="show3 = !show3"
                    :rules="[rules.required, rules.min, rules.password]"
                  ></v-text-field>
                </v-container>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_conferma = false"
              >Annulla</v-btn
            >
            <v-btn color="blue darken-1" text @click="setUtenteConferma()"
              >Conferma</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog_inserUtenti"
        max-width="80%"
        min-height="500px"
        persistent
      >
        <v-card>
          <v-card-title v-html="titolo_conferma"></v-card-title>
          <v-card-text>
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="primary accent-4"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1" v-if="arrivo_utente == 'inserisci'">
                  Aggiungi Utente
                  <v-icon>mdi-account</v-icon>
                </v-tab>

                <v-tab href="#tab-2" v-if="arrivo_utente == 'inserisci'">
                  Carica Utenti
                  <v-icon>mdi-account-group</v-icon>
                </v-tab>

                <v-tab href="#tab-3" v-if="arrivo_utente == 'modifica'">
                  Modifica Utente
                  <v-icon>mdi-account</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-' + 1" style="min-height: 500px">
                  <v-card flat>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.nome"
                              label="Nome"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.cognome"
                              label="Cognome"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="4">
                            <v-autocomplete
                              v-model="nuovo_utente.id_qualifica"
                              :items="lista_ruoli"
                              item-text="des"
                              item-value="val_int"
                              label="Ruolo"
                            ></v-autocomplete>
                          </v-col>

                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.email"
                              label="Email"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.cod_fiscale"
                              label="Codice Fiscale"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-' + 2" style="min-height: 500px">
                  <v-card flat>
                    <v-card-text style>
                      <v-row>
                        <v-col md="10">
                          <vue-xlsx-table @on-select-file="handleSelectedFile"
                            >CARICA FILE</vue-xlsx-table
                          >
                        </v-col>
                        <v-col md="2">
                          <a href="https://www.quickviewsafety.com/esempio.xls">
                            <v-btn color="green darken-2" dark flat>
                              <v-icon>mdi-file-excel</v-icon>Scarica Esempio
                            </v-btn>
                          </a>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-data-table
                            :headers="headers_utenti"
                            :items="items_utenti"
                            class="elevation-1"
                            :footer-props="{
                              disablePagination: true,
                              disableItemsPerPage: true,
                            }"
                            :hide-default-footer="true"
                            disable-pagination
                          >
                            <template v-slot:[`item.des_esito`]="{ item }">
                              <v-chip
                                :color="getAllerta(item.esito_temperatura)"
                                dark
                                >{{ item.des_esito }}</v-chip
                              >
                            </template>
                            <template
                              v-slot:[`item.data_rilevazione`]="{ item }"
                              >{{
                                item.data_rilevazione | formatDateTime
                              }}</template
                            >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-' + 3" style="min-height: 500px">
                  <v-card flat>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.nome"
                              label="Nome"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.cognome"
                              label="Cognome"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="4">
                            <v-autocomplete
                              v-model="nuovo_utente.id_qualifica"
                              :items="lista_ruoli"
                              item-text="des"
                              item-value="val_int"
                              label="Ruolo"
                            ></v-autocomplete>
                          </v-col>
                          <v-col md="4">
                            <v-autocomplete
                              v-if="id_profilo == 7"
                              v-model="nuovo_utente.id_profilo_utente"
                              :items="tipi_profili"
                              item-text="des_profilo_utente"
                              item-value="id_profilo_utente"
                              label="Tipo Profilo"
                            ></v-autocomplete>
                          </v-col>

                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.email"
                              label="Email"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col md="4">
                            <v-text-field
                              v-model="nuovo_utente.cod_fiscale"
                              label="Codice Fiscale"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" dark @click="dialog_inserUtenti = false"
              >Annulla</v-btn
            >
            <v-btn
              color="green darken-1"
              :disabled="
                !(
                  nuovo_utente.cognome != undefined &&
                  nuovo_utente.cognome != '' &&
                  nuovo_utente.nome != undefined &&
                  nuovo_utente.nome != '' &&
                  nuovo_utente.id_qualifica != undefined &&
                  nuovo_utente.id_qualifica != ''
                ) || saving
              "
              @click="uploadUtente()"
              >Conferma</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    show3: false,
    newpassword: "",
    dialog_conferma: false,
    titolo_conferma: "",
    dialog_inserUtenti: false,
    headers_utenti: [
      {
        text: "Nome",
        value: "Nome",
      },
      { text: "Cognome", value: "Cognome" },
      { text: "Cod. Fiscale", value: "Cod_Fiscale" },
      //{ text: "Badge", value: "Badge" },
      { text: "Email", value: "email" },
    ],
    rules: {
      required: (value) => !!value || "Richiesta.",
      min: (v) => v.length >= 8 || "Min 8 caratteri",
      password: (value) => {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 caratteri con almeno una lettera maiuscola, un numero, e un carattere speciale."
        );
      },
    },
    items_utenti: [],
    utentiSelezionati: [],
    nominativi_item: [],
    nominativi_sel: [],
    titolo_dialog: "",
    dialog_users: false,
    stati: [
      {
        id_stato: 1,
        testo: "BLOCCATO",
      },
      {
        id_stato: 3,
        testo: "ATTIVO",
      },
      {
        id_stato: 2,
        testo: "SBLOCCATO",
      },
      {
        id_stato: 4,
        testo: "DISATTIVO",
      },
    ],
    stato_sel: null,
    nuovo_utente: {
      nome : "",
      cognome : "",
      id_profilo_utente : null,
      email : "",
      id_personale : null,
      cod_fiscale : "",
      id_qualifica : null,
    },
    messaggio_snackbar: "",
    colore_snack: "green",
    snackbar: false,
    item_sel: {},
    backup_item: [],
    arrivo_utente: "",
    lista_ruoli: [],
    tab: null,
    id_profilo: localStorage.id_profilo,
    backup_item2: [],
    arrivo: "",
    dipendenti: [],
    tipi_profili: [],
    itemPage: [50, 100, 150, -1],
    footerProps: { "items-per-page-options": [10, 30, 50, 100] },
    pagination: {
      itemsPerPage: 50,
    },
    headers: [
      {
        text: "Nominativo",
        align: "start",
        sortable: true,
        value: "nominativo",
      },
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Gruppo", value: "cod_personale" },
      { text: "Des Qualifica", value: "des_qualifica" },

      { text: "Profilo", value: "des_profilo_utente" },

      { text: "cod. Fiscale", value: "cod_fiscale" },
      { text: "Stato", value: "stato" },
      { text: "Gestione", value: "gestione" },
    ],
    saving: false,
    valid: false,
  }),
  components: {},
  watch: {
    utentiSelezionati(val) {
      console.log("utentisel", val);
    },

    nominativi_sel(val) {
      //filtro la tabella
      if (this.backup_item2.length > 0) this.dipendenti = this.backup_item2;
      if (val.length > 0) {
        this.backup_item2 = this.dipendenti;
        this.dipendenti = this.dipendenti.filter(function (el) {
          console.log(val.indexOf(el.nominativo));
          return val.indexOf(el.nominativo) >= 0;
        });
      }
    },
    stato_sel(val) {
      //filtro la tabella
      if (this.backup_item.length > 0) this.dipendenti = this.backup_item;
      if (val != null) {
        this.backup_item = this.dipendenti;
        if (val.id_stato == 1) {
          console.log("val1");
          this.dipendenti = this.dipendenti.filter((i) => i.blocco_utente == 1);
          console.log(this.dipendenti.filter((i) => i.blocco_utente == 1));
        } else if (val.id_stato == 2) {
          this.dipendenti = this.dipendenti.filter((i) => i.blocco_utente == 0);
        } else if (val.id_stato == 3) {
          this.dipendenti = this.dipendenti.filter((i) => i.attivo == 1);
        } else if (val.id_stato == 4) {
          this.dipendenti = this.dipendenti.filter((i) => i.attivo == 0);
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.getInfo();
    this.getTabella("UTENTE_QUALIFICA");
    this.getProfili();
  },
  methods: {
    rowClick(item, row) {
      if (row.isSelected == true) {
        row.select(false);
      } else {
        row.select(true);
      }
    },
    cancella_autocomplete() {
      this.stato_sel = null;
    },
    cancella_autocomplete2(item) {
      const index = this.nominativi_sel.indexOf(item.nominativo);
      if (index >= 0) this.nominativi_sel.splice(index, 1);
    },
    handleSelectedFile(convertedData) {
      console.log(convertedData);
    },

    conferma_users_edit(data) {
      this.dialog_conferma = true;
      if (data.item == -1) {
        var nominativi =
          "<div style='margin-left:10px;margin-right:10px;max-height:100px;overflow-y:auto'>";
        data.utenti.forEach((el) => {
          nominativi += el.nominativo + "<br>";
        });
        nominativi += "</div>";
        if (data.arrivo == "sblocca") {
          this.titolo_conferma =
            "Gli utenti:<br> " + nominativi + " verranno sbloccati.";
        } else if (data.arrivo == "blocca") {
          this.titolo_conferma =
            "Gli utenti:<br> " + nominativi + " verranno bloccati.";
        } else if (data.arrivo == "disattiva") {
          this.titolo_conferma =
            "Disattivazione degli utenti:<br> " + nominativi;
        } else if (data.arrivo == "attiva") {
          this.titolo_conferma = "Attivazione degli utenti:<br> " + nominativi;
        } else if (data.arrivo == "elimina") {
          this.titolo_conferma =
            "Gli utenti:<br> " + nominativi + " verranno eliminati";
        } else {
          this.titolo_conferma =
            "Reset password per gli utenti:<br> " + nominativi;
        }
      } else {
        if (data.arrivo == "sblocca") {
          this.titolo_conferma =
            "L' utente " + data.item.nominativo + " verrà sbloccato.";
        } else if (data.arrivo == "blocca") {
          this.titolo_conferma =
            "L 'utente " + data.item.nominativo + " verrà bloccato.";
        } else if (data.arrivo == "disattiva") {
          this.titolo_conferma =
            "Disattivazione dell'utente: " + data.item.nominativo;
        } else if (data.arrivo == "attiva") {
          this.titolo_conferma =
            "Attivazione dell'utente: " + data.item.nominativo;
        } else if (data.arrivo == "elimina") {
          this.titolo_conferma =
            "L'utente: " + data.item.nominativo + " verrà eliminato";
        } else {
          this.titolo_conferma =
            "Reset password per l'utente: " + data.item.nominativo;
        }
      }

      this.item_sel = data.item;
      this.utenti_sel = data.utenti;
      this.arrivo = data.arrivo;
    },
    uploadUtente() {
      this.saving = true;
      var tabella = [];
      if (this.tab == "tab-1") {
        this.nuovo_utente.id_personale = -1;
      }
      if (this.tab == "tab-2") {
        tabella = this.items_utenti;
      } else {
        if (
          this.nuovo_utente.nome != "" &&
          this.nuovo_utente.cognome != "" &&
          this.nuovo_utente.id_profilo != ""
        ) {
          tabella = [this.nuovo_utente];
        } else {
          return;
        }
      }
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // console.log(tabella);
      // var finale = {
      //   action: "putsetutenti",
      //   token: localStorage.user_token,
      //   id_utente: localStorage.id,
      //   dbName: "medical",
      //   tabella: tabella,
      // };
      // var data = JSON.stringify(finale);

      var request = {
        controller: "User",
        method: "PUT",
        richiesta: {
          action: "putsetutenti",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          dbName: "medical",
          tabella: tabella,
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        //console.log("settabelle response: ", JSON.stringify(res));
        this.dialog_inserUtenti = false;
        // JSON responses are automatically parsed.
        this.items_utenti = [];
        this.conferma_azioni(res.data, "utenti");
        this.getInfo();
      });

      // axios
      //   .put(this.$store.state.config.ip + "api/User", data, config)
      //   .then((response) => {
      //     this.dialog_inserUtenti = false;
      //     // JSON responses are automatically parsed.
      //     this.items_utenti = [];
      //     this.conferma_azioni(response.data, "utenti");
      //     console.log(response);
      //   })
      //   .catch((e) => {
      //     if (e.response.status == 401) {
      //       localStorage.clear();
      //       this.$router.replace("login");
      //     }
      //     console.log(e);
      //   });
    },
    setUtenteConferma() {
      var item = this.item_sel;
      var id = -1;

      if (item != -1) {
        id = item.id_personale;
      } else {
        id = "";
        this.utenti_sel.forEach((el) => {
          id += el.id_personale + ",";
        });
        id = id.substring(0, id.length - 1);
      }
      console.log(id);

      var arrivo = this.arrivo;
      this.values = [];

      var finale = {};
      if (arrivo == "sblocca") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: item.attivo,
          sblocco: 1,
          note_sblocco: this.note_blocco,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "blocca") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: item.attivo,
          cambio_password: item.cambio_password,
          sblocco: 0,
          note_sblocco: this.note_blocco,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "disattiva") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: 0,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "attiva") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: 1,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else if (arrivo == "elimina") {
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: 1,
          validita: 0,
          cambio_password: item.cambio_password,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      } else {
        var passw = "";
        if (arrivo == "resetpassword") {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          if (this.newpassword.length < 8 || !pattern.test(this.newpassword)) {
            this.colore_snack = "error";
            this.messaggio_snackbar = "Inserire una password corretta";
            this.snackbar = true;
            return;
          }
          passw = this.newpassword;
        }
        finale = {
          action: "putsetutente",
          token: localStorage.user_token,
          id: id,
          attivo: item.attivo,
          newpassword: passw,
          cambio_password: 1,
          dbname: "medical",
          id_utente: localStorage.id,
        };
      }
      var request = {
        controller: "Medical",
        method: "PUT",
        richiesta: finale,
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store
        .dispatch("api", request)
        .then((res) => {
          console.log("settabelle response: ", JSON.stringify(res));
          if (res.status == 200) {
            this.newpassword = "";
            this.dialog_conferma = false;
            this.conferma_azioni(item, arrivo, this.utenti_sel);
            this.note_blocco = "";
          }
          this.colore_snack = "green";
        })
        .catch((e) => {
          if (e.response != undefined) {
            this.colore_snack = "red";
            console.log("eRORR", e.response);
            this.messaggio_snackbar = JSON.parse(e.response.data.message)[
              "result"
            ];
            this.snackbar = true;
          }
        })
        .finally(() => {
          this.getInfo();
        });

      // var data = JSON.stringify(finale);
      // axios
      //   .put(this.$store.state.config.ip + "api/Medical", data, config)
      //   .then(() => {
      //     // JSON responses are automatically parsed.
      //     this.dialog_conferma = false;
      //     this.conferma_azioni(item, arrivo, this.utenti_sel);
      //     this.note_blocco = "";
      //     this.getInfo();
      //   })
      //   .catch((e) => {
      //     console.log("errore", e);

      //     this.$refs.dialog_mod_user.errore_azioni();
      //     if (e.response.status == 401) {
      //       localStorage.removeItem("id_user_sel");
      //       localStorage.removeItem("nominativo_sel");
      //       localStorage.removeItem("user_token");
      //       localStorage.removeItem("arrivo");
      //       this.$router.replace("login");
      //     }
      //   });
    },
    getProfili() {
      var request = {
        controller: "User",
        method: "POST",
        richiesta: {
          action: "getprofili",
          token: localStorage.user_token,
          idUtente: localStorage.id,
          dbName: "medical",
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((response) => {
        //console.log("settabelle response: ", JSON.stringify(res));
        var sup = response.data.result.profili.filter(
          (f) => f.id_profilo_utente == parseInt(this.id_profilo)
        );
        this.tipi_profili = response.data.result.profili.filter(
          (f) => f.livello >= sup[0].livello
        );
      });
    },
    insertUtenti(arrivo) {
      console.log("ARRIVO", arrivo);
      //chiamata per i profili utente
      if (arrivo.arrivo == "modifica") {
        this.arrivo_utente = "modifica";
      } else {
        this.tab = "tab-1";
        this.arrivo_utente = "inserisci";
        this.nuovo_utente.nome = "";
        this.nuovo_utente.cognome = "";
        this.nuovo_utente.email = "";
        this.nuovo_utente.cod_fiscale = "";
      }
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // var finale = {
      //   action: "getprofili",
      //   token: localStorage.user_token,
      //   idUtente: localStorage.id,
      //   dbName: "medical",
      // };

      //var data = JSON.stringify(finale);
      // axios
      //   .post(this.$store.state.config.ip + "api/User", data, config)
      //   .then((response) => {
      //     // JSON responses are automatically parsed.
      //     console.log(response);
      //     this.tipi_profili = response.data;
      //   })
      //   .catch((e) => {
      //     if (e.response.status == 401) {
      //       localStorage.removeItem("id_user_sel");
      //       localStorage.removeItem("nominativo_sel");
      //       localStorage.removeItem("user_token");
      //       localStorage.removeItem("arrivo");
      //       this.$router.replace("login");
      //     }
      //     console.log(e);
      //   });
      if (arrivo.arrivo == "modifica") {
        this.nuovo_utente.nome = arrivo.item.nome;
        this.nuovo_utente.cognome = arrivo.item.cognome;
        this.nuovo_utente.id_profilo_utente = arrivo.item.id_profilo_utente;
        this.nuovo_utente.email = arrivo.item.email;
        this.nuovo_utente.id_personale = arrivo.item.id_personale;
        this.nuovo_utente.cod_fiscale = arrivo.item.cod_fiscale;
        this.nuovo_utente.id_qualifica = arrivo.item.id_qualifica;
        //this.nuovo_utente.badge = arrivo.item.badge;
      }
      this.titolo_conferma = "";
      this.dialog_inserUtenti = true;
    },
    getInfo() {
      this.values = [];

      var request = {
        controller: "Medical",
        method: "POST",
        richiesta: {
          action: "getlistadipendenti",
          token: localStorage.user_token,
          tipo: "ad",
          id_utente: localStorage.id,
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store.dispatch("api", request).then((response) => {
        //console.log("settabelle response: ", JSON.stringify(res));

        this.dipendenti = response.data.dipendenti;
        this.nominativi_item = response.data.dipendenti;
      });
    },
    getTabella(tipo) {
      var getStatus = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.user_token,
          id_utente: localStorage.id,
          tipo: tipo,
          db_name: "medical",
        },
      };
      //console.log("settabelle request: ", JSON.stringify(request));
      this.$store.dispatch("api", getStatus).then((response) => {
        // console.log("settabelle response: ", JSON.stringify(response));
        this.lista_ruoli = response.data;
      });
    },
    setColoreStatoDipendenti(item) {
      if (item.attivo == 0) {
        return "red";
      } else if (item.blocco_utente == 1) {
        return "orange";
      } else {
        return "green";
      }
    },
    setStatoDipendenti(item) {
      if (item.attivo == 0) {
        return "Disattivo";
      } else if (item.blocco_utente == 1) {
        return "Bloccato";
      } else {
        return "Attivo";
      }
    },
    setUtente(item, arrivo) {
      if (item != null) {
        this.item_sel = item;
      } else {
        this.item_sel = -1;
      }
      var utenti_sel = [];
      if (this.utentiSelezionati.length > 0) {
        utenti_sel = this.utentiSelezionati;
      }
      this.arrivo = arrivo;
      var data = {
        arrivo: arrivo,
        item: this.item_sel,
        utenti: utenti_sel,
      };
      this.conferma_users_edit(data);
      //this.$emit("confermaUsers", data);
    },
    conferma_azioni(item, arrivo, utenti) {
      if (item != -1) {
        if (arrivo == "sblocca") {
          this.dipendenti[this.dipendenti.indexOf(item)].blocco_utente = 0;
        } else if (arrivo == "blocca") {
          this.dipendenti[this.dipendenti.indexOf(item)].blocco_utente = 1;
        } else if (arrivo == "disattiva") {
          this.dipendenti[this.dipendenti.indexOf(item)].attivo = 0;
        } else if (arrivo == "attiva") {
          this.dipendenti[this.dipendenti.indexOf(item)].attivo = 1;
        }
      } else {
        utenti.forEach((el) => {
          console.log(this.dipendenti[this.dipendenti.indexOf(el)]);

          if (arrivo == "sblocca") {
            this.dipendenti[this.dipendenti.indexOf(el)].blocco_utente = 0;
          } else if (arrivo == "blocca") {
            this.dipendenti[this.dipendenti.indexOf(el)].blocco_utente = 1;
          } else if (arrivo == "disattiva") {
            this.dipendenti[this.dipendenti.indexOf(el)].attivo = 0;
          } else if (arrivo == "attiva") {
            this.dipendenti[this.dipendenti.indexOf(el)].attivo = 1;
          }
        });
      }

      this.colore_snack = "green";
      this.messaggio_snackbar = "Azione effettuata con successo!";
      this.snackbar = true;
    },
    errore_azioni() {
      console.log("arrivato");
      this.colore_snack = "red";
      this.messaggio_snackbar =
        "Errore, non è stato possibile effettuare l'azione!";
      this.snackbar = true;
    },
    gestisciInsertUtenti(item, arrivo) {
      var data = {
        item: item,
        arrivo: arrivo,
      };
      this.insertUtenti(data);
      //this.$emit("insertUtenti", data);
    },
  },
};
</script>