import { render, staticRenderFns } from "./Parametri.vue?vue&type=template&id=48698df8&scoped=true&"
import script from "./Parametri.vue?vue&type=script&lang=js&"
export * from "./Parametri.vue?vue&type=script&lang=js&"
import style0 from "./Parametri.css?vue&type=style&index=0&id=48698df8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48698df8",
  null
  
)

export default component.exports