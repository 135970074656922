<template>
  <v-card id="listener_registra">
    <v-toolbar dark color="indigo" dense>
      <v-row align="center">
        <v-col cols="4" align="left">
          <v-toolbar-title dense>Registra</v-toolbar-title>
        </v-col>
        <v-col cols="4" align="left">
          <span
            >Cod. Articolo: <b>{{ fase_sel.cod_articolo }}</b></span
          >
        </v-col>
        <v-col cols="3" align="left">
          <span
            >Cod. Lotto: <b>{{ fase_sel.cod_lotto }}</b></span
          >
        </v-col>
        <v-col cols="1" align="right">
          <v-btn @click="clickChiudi" small light>
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-card-text style="height: 80vh; overflow-y: auto">
      <v-row class="mt-5" align="center">
        <v-col cols="3">
          <v-row justify="center">
            <v-text-field
              class="mr-3"
              dense
              label="Numero Pezzi"
              min="0"
              outlined
              type="number"
              v-model="pezzi_prodotti"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
              class="mr-3"
              dense
              item-text="des"
              item-value="id_tabella"
              :items="causali_conformita"
              label="Conformità"
              outlined
              v-model="causale_conformita_sel"
            ></v-select>
          </v-row>
          <v-row>
            <v-select
              class="mr-3"
              dense
              item-text="des"
              item-value="id_tabella"
              :items="causali_non_conformita"
              label="Causale non conformità"
              outlined
              v-if="causale_conformita_sel == id_causale_scarto"
              v-model="causale_non_conformita_sel"
            ></v-select>
          </v-row>
          <v-row
            align="center"
            justify="center"
            v-if="causale_conformita_sel === id_causale_scarto"
          >
            <v-col cols="6">
              <v-btn @click="clickControlloWebcam" small>
                <v-icon color="primary"> mdi-camera </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <div
                :id="fase_sel.id_articolo"
                style="
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  border: 1px solid black;
                  height: 100px;
                  margin-top: 5px;
                  width: 100px;
                "
              ></div>
            </v-col>
          </v-row>
          <v-row class="mr-1">
            <v-textarea
              v-model="fase_sel.note_workorder"
              label="Note Work Order"
              outlined
              :rows="1"
              readonly
            >
            </v-textarea>
          </v-row>
          <v-row class="mr-1">
            <v-textarea
              v-model="fase_sel.note_tempi"
              placeholder="Note"
              label="Note"
              outlined
              :rows="2"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-checkbox label="Saldo Fase Commessa"></v-checkbox>
          </v-row>
          <v-row>
            <span> <b>Quantità Totale Fase: </b>{{ fase_sel.quantita }} </span>
          </v-row>
          <v-row>
            <span>
              <b>Quantità Prodotta: </b>{{ fase_sel.pezzi_registrati }}
            </span>
          </v-row>
          <br />
          <v-row class="mt-5" justify="center">
            <v-btn 
              @click="registra" 
              color="primary" 
              dark 
              :disabled = "fase_sel.qta_realizzabile < pezzi_prodotti && fase_sel.materia_prima == 1">
                REGISTRA
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="9">
          <v-row class="mb-8">
            <PezziRegistrati :visibile="visibile" style="width: 100%" />
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-5">
            <ComponentiScartati />
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="mt-8 mb-10"></v-divider>

      <v-row align="center" justify="center">
        <v-col cols="12">
          <DistintaDiPrelievo
            :items_distinta_prelievo="items_distinta_prelievo"
            :show_add_form="false"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Dialog della webcam della qualità -->
    <div
      style="
        background-color: rgba(33, 33, 33, 0.46);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
      "
      v-if="controllo_webcam_visible"
    >
      <div
        style="height: 90%; left: 5%; position: absolute; top: 5%; width: 90%"
      >
        <Controllo
          @click_indietro="controllo_webcam_visible = false"
          :destinazione="'listener_registra'"
          :disegni_iniziali="disegni_iniziali"
          :id_articolo="fase_sel.id_articolo"
          :riferimento="fase_sel.id_articolo"
          :tabella_supporto="'disegno'"
        />
      </div>
    </div>
  </v-card>
</template>
<script>
//import Base64 from "b64-to-blob";
import ComponentiScartati from "@/components/PRODUCTION/Produzione/ComponentiScartati.vue";
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";
import DistintaDiPrelievo from "@/components/PRODUCTION/Produzione/DistintaDiPrelievo.vue";
import moment from "moment/moment";
import PezziRegistrati from "@/components/PRODUCTION/Produzione/PezziRegistrati.vue";
export default {
  name: "Registra",
  components: {
    Controllo,
    ComponentiScartati,
    DistintaDiPrelievo,
    PezziRegistrati,
  },
  props: {
    fase_sel: { type: Object, default: () => {} },
    visibile: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      causale_conformita_sel: null,
      causale_non_conformita_sel: null,
      fase_sel_tmp: {},
      id_causale_scarto: null,
      pezzi_prodotti: 0,

      // Modal
      controllo_webcam_visible: false,

      // Array
      causali_conformita: [],
      causali_non_conformita: [],
      disegni_iniziali: [],
      items_distinta_prelievo: [],
    };
  },
  methods: {
    aggiungiImg(messaggio) {
      // Recupero l'immagine nel messaggio
      let photo = messaggio.detail.img;
      this.disegni_iniziali.splice(0,1,{disegno: messaggio.detail.img, id_img: moment().valueOf()});
      console.log(JSON.stringify(this.disegni_iniziali));

      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width =
            img.width + "px";
          document.getElementById(messaggio.detail.id).style.height =
            img.height + "px";
        }
      };
      
      img.src = photo;

      document.getElementById(messaggio.detail.id).style.backgroundImage =
        "url('" + img.src + "')";
    },
    clickChiudi() {
      this.$emit("click_chiudi");

      // Resetto le variabili d'appoggio
      this.causale_conformita_sel = null;
      this.causale_non_conformita_sel = null;
      this.pezzi_prodotti = 0;
    },
    clickControlloWebcam() {
      this.controllo_webcam_visible = true;
      console.log("id_articolo: " + this.fase_sel.id_articolo);
    },
    gettabelle() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "'CAUSALE_REGISTRAZIONE_FINITO','PRODUZIONE_CAUSALI'",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Popolo le causali di non conformità.
          this.causali_non_conformita =
            res.data.PRODUZIONE_CAUSALI != undefined
              ? res.data.PRODUZIONE_CAUSALI
              : [];

          // Popolo le causali di conformità.
          this.causali_conformita =
            res.data.CAUSALE_REGISTRAZIONE_FINITO != undefined
              ? res.data.CAUSALE_REGISTRAZIONE_FINITO
              : [];

          res.data.CAUSALE_REGISTRAZIONE_FINITO.forEach((elem) => {
            // Valorizzo la causale di registrazione di default come "conforme"
            if (elem.cod === "OK") {
              this.causale_conformita_sel = elem.id_tabella;
            }

            // Recupero l'id_tabella della causale "scarto" per necessità di FE
            if (elem.cod === "NC") {
              this.id_causale_scarto = elem.id_tabella;
            }
          });
        }
      });
    },
    getPrelieviCommessa() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getprelievicommessa",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: this.fase_sel.id_commessa_dettaglio,
          quantita: this.fase_sel.quantita,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.items_distinta_prelievo = res.data.Prelievi;
      });
    },
    registra() {
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessaregistrazione",
          token: localStorage.getItem("user_token"),
          causali: [],
          chiudi_workorder:
            parseInt(this.pezzi_prodotti) +
              parseInt(this.fase_sel.pezzi_registrati) <
            parseInt(this.fase_sel.quantita)
              ? 0
              : 1,
          flag_fase_ultima: this.fase_sel.flag_fase_ultima,
          id_articolo: this.fase_sel.id_articolo,
          id_commessa: this.fase_sel.id_commessa,
          id_commessa_dettaglio: this.fase_sel.id_commessa_dettaglio,
          id_macchina: this.fase_sel.id_macchina,
          note: this.fase_sel.note_tempi,
          quantita: this.pezzi_prodotti,
        },
      };
      //console.log(JSON.stringify(request));
      this.$store.dispatch("api", request).then(() => {
        // Resetto i pezzi prodotti.
        this.pezzi_prodotti = 0;
        // Emetto un evento per aggiornare il componente padre
        this.$emit("eseguita_registrazione");

        /* Quando arriva la response segnalo alla tabella
         * PezziRegistrati che ho fatto una registrazione. */
        let evento = new CustomEvent("effettuata_registrazione");
        document
          .getElementById("listener_pezzi_registrati")
          .dispatchEvent(evento);
      });
    },
  },
  mounted() {
    document
      .getElementById("listener_registra")
      .addEventListener("nuova_img", this.aggiungiImg);
  },
  watch: {
    visibile: {
      immediate: true,
      handler() {
        if (this.visibile == true) {
          this.gettabelle();
          this.getPrelieviCommessa();
        }
      },
    },
  },
};
</script>
<style>
</style>