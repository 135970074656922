<template>
  <v-card id="listener_controllo_qualita">
    <v-toolbar color="indigo" dense>
      <v-row align="center">
        <v-col cols="5" align="left">
          <v-toolbar-title class="white--text">
            Controllo Qualità
          </v-toolbar-title>
        </v-col>
        <v-col cols="2" align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setControlli" small v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
        </v-col>
        <v-col cols="5" align="right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on">
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-card-text
      class="mt-5"
      style="max-height: 78vh; overflow-y: auto !important"
    >
      <v-data-table
        class="elevation-2"
        :headers="headers_controllo"
        height="50vh"
        :items="items_controllo"
      >
        <template v-slot:top>
          <v-toolbar class="mb-2" color="indigo" dense dark>
            <v-toolbar-title>Caratteristiche</v-toolbar-title>
          </v-toolbar>
        </template>

        <!-- Colonna della Frequenza -->
        <template v-slot:[`item.frequenza`]="{ item }">
          {{ item.frequenza }} {{ item.des_frequenza }}
        </template>

        <!-- Colonna della Misura -->
        <template v-slot:[`item.misura`]="{ item }">
          <v-text-field
            style="max-width: 80px; margin: 0"
            class="centered-input pa-0 ma-0"
            v-show="
              item.valore !== null &&
              item.valore !== undefined &&
              !isNaN(item.valore)
            "
            :background-color="coloreSuValore(item)"
            dense
            hide-details
            outlined
            v-model="item.misura"
          />
          <div
            style="text-align: left"
            v-show="
              item.valore == null ||
              item.valore == undefined ||
              isNaN(item.valore)
            "
          >
            <v-btn
              icon
              small
              style="display: inline-block; vertical-align: middle"
              @click="item.misura = item.misura == 1 ? 0 : 1"
            >
              <v-icon :color="item.misura == 1 ? 'green' : 'red'" large>{{
                item.misura == 1 ? "mdi-checkbox-marked" : "mdi-close-box"
              }}</v-icon>
            </v-btn>
            <span
              :style="
                'margin-left:5px;display:inline-block;vertical-align:middle;font-size:18px;font-weight:bold;color:' +
                (item.misura == 1 ? 'green' : 'red')
              "
              >{{ item.misura == true ? "OK" : "NOK" }}</span
            >
          </div>
        </template>

        <!-- Colonna del bottone per il controllo della webcam -->
        <template v-slot:[`item.controllo_webcam`]="{ item }">
          <v-btn @click="clickControllo(item)">
            <v-icon color="primary" large> mdi-camera </v-icon>
          </v-btn>
        </template>

        <!-- Spazio per la miniatura della foto da webcam -->
        <template v-slot:[`item.miniatura`]="{ item }">
          <div
            :id="item.id_ciclo_dettaglio"
            style="
              background-repeat: no-repeat;
              background-size: contain;
              border: 1px solid black;
              height: 100px;
              margin-top: 5px;
              position: relative;
              width: 100px;
            "
          ></div>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Dialog della webcam della qualità -->
    <div
      style="
        background-color: rgba(33, 33, 33, 0.46);
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 2;
      "
      v-if="controllo_webcam_visible"
    >
      <div
        style="height: 90%; left: 5%; position: absolute; top: 5%; width: 90%"
      >
        <Controllo
          @click_indietro="controllo_webcam_visible = false"
          :destinazione="'listener_controllo_qualita'"
          :id_articolo="work_order_sel.id_articolo"
          :immagine_standard_editor="immagine_standard_editor"
          :riferimento="id_ciclo_controllo_dettaglio_sel"
          :visible="visible"
        />
      </div>
    </div>
  </v-card>
</template>
<script>
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";

export default {
  name: "ControlloQualita",
  components: {
    Controllo,
  },
  props: {
    visible: { type: Boolean, default: false },
    work_order_sel: { type: Object, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      id_ciclo_controllo_dettaglio_sel: null,
      immagine_standard_editor: "",

      // Modal
      controllo_webcam_visible: false,

      // Array
      headers_controllo: [
        { text: "Controllo", sortable: false, value: "controllo" },
        {
          text: "Des. Caratteristica",
          sortable: false,
          value: "des_caratteristica",
        },
        { text: "Frequenza", sortable: false, value: "frequenza" },
        { text: "Valore Min.", sortable: false, value: "valore_min" },
        { text: "Valore", sortable: false, value: "valore" },
        { text: "Valore Max.", sortable: false, value: "valore_max" },
        { text: "Tolleranza", sortable: false, value: "tolleranza" },
        { text: "Des. Strumento", sortable: false, value: "des_strumento" },
        { text: "Note", sortable: false, value: "note" },
        { text: "Misura", sortable: false, value: "misura" },
        //{ text: "Conforme", sortable: false, value: "conforme" },
        { text: "Webcam", sortable: false, value: "controllo_webcam" },
        { text: "Miniatura", sortable: false, value: "miniatura" },
      ],
      items_controllo: [
        /*{
          id_ciclo_dettaglio: 1,
          controllo: "Manico",
          des_caratteristica: "Temperatura",
          tolleranza: 2,
          des_strumento: "Termometro",
          frequenza: 2,
          des_frequenza: "Giorni",
          misura: 1,
          valore_max: 5,
          valore_min: 1,
          valore: 2,
        },
        {
          id_ciclo_dettaglio: 2,
          controllo: "Corpo intero",
          des_caratteristica: "Bellezza",
          tolleranza: null,
          des_strumento: "Rilevazione autoptica",
          frequenza: 1,
          des_frequenza: "Ore",
          misura: 1,
          valore_max: null,
          valore_min: null,
          valore: null,
        },
        {
          id_ciclo_dettaglio: 3,
          controllo: "Muso anteriore",
          des_caratteristica: "Lunghezza",
          tolleranza: 2,
          des_strumento: "Metro",
          frequenza: 4,
          des_frequenza: "Ore",
          misura: 1,
          valore_max: 3,
          valore_min: 2,
          valore: 2.5,
        },*/
      ],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true && this.work_order_sel != null) {
          this.getControlli();
        }
      },
    },
  },
  methods: {
    aggiungiImg(messaggio) {
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.detail.img;

      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width >= img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
          img.width = width_nuova;
          img.height = height_nuova;
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width =
            img.width + "px";
          document.getElementById(messaggio.detail.id).style.height =
            img.height + "px";
        }
      };

      img.src = photo;

      document.getElementById(messaggio.detail.id).style.backgroundImage =
        'url("' + img.src + '")';

      this.items_controllo.forEach((e) => {
        if (e.id_ciclo_dettaglio == messaggio.detail.id) {
          e.img = messaggio.detail.img;
          console.log("beccato");
        } else {
          console.log(
            `no no no ${e.id_ciclo_dettaglio} ${messaggio.detail.id}`
          );
        }
      });
      console.log("end aggiungiImg");
    },
    coloreSuValore(item) {
      let misura = Number(item.misura);
      let max = Number(item.valore_max);
      let min = Number(item.valore_min);
      if (isNaN(misura) || isNaN(min) || isNaN(max)) {
        return "red accent-2";
      }

      if (misura < min || misura > max) {
        return "red accent-2";
      } else if (misura == min || misura == max) {
        return "amber lighten-3";
      } else {
        return "green accent-2";
      }
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    clickControllo(item) {
      /* Se l'oggetto cliccato ha un'immagine, la metto in
       * prop per il componente delll'editor grafico, altrimenti
       * cancello l'eventuale immagine standard precedente. */
      if (
        item.img != null &&
        (item.img != null) != undefined &&
        item.img != ""
      ) {
        this.immagine_standard_editor = item.img;
      } else {
        this.immagine_standard_editor = "";
      }

      this.id_ciclo_controllo_dettaglio_sel = item.id_ciclo_dettaglio;
      this.controllo_webcam_visible = true;
    },
    getControlli() {
      if (this.work_order_sel === null) {
        return;
      }

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getciclocontrolli",
          token: localStorage.getItem("user_token"),
          id_articolo: this.work_order_sel.id_articolo,
          id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
          id_fase: this.work_order_sel.id_fase,
          quantita: this.work_order_sel.pezzi_registrati,
          ora_start:
            this.work_order_sel.ora_start.substring(6, 10) +
            "-" +
            this.work_order_sel.ora_start.substring(3, 5) +
            "-" +
            this.work_order_sel.ora_start.substring(0, 2) +
            this.work_order_sel.ora_start.substring(10, 16),
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.items_controllo = Array.from(res.data);
      });
    },
    setControlli() {
      let request = {
        controller: "Qualita",
        method: "PUT",
        richiesta: {
          action: "setciclocontrolli",
          token: localStorage.getItem("user_token"),
          ciclo: this.items_controllo,
        },
      };

      this.$store.dispatch("api", request).then(() => {
        this.$emit("eseguito_controllo");
      });
    },
  },
  created() {},
  mounted() {
    document
      .getElementById("listener_controllo_qualita")
      .addEventListener("nuova_img", this.aggiungiImg);
  },
};
</script>
<style scoped>
</style>