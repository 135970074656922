<template>
  <v-card class="mt-5 mx-5">
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text">
        Contenitori Registrati
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Filtri (mostra campi) -->
      <v-tooltip bottom v-if="filtri_visible === false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="filtri_visible = true"
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter</v-icon></v-btn
          >
        </template>
        <span>Filtri</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" @click="ripristina" small v-bind="attrs" v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter-off</v-icon></v-btn
          >
        </template>
        <span>Ripristina</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Bottone per tornare indietro -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" small v-bind="attrs" v-on="on">
            <v-icon color="error"> mdi-keyboard-backspace </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers_registrazioni"
        :items="items_registrazioni"
        height="70vh"
      >
        <template v-slot:top>
          <v-row align="center" justify="center" v-if="filtri_visible">
            <!-- Filtro del codice articolo -->
            <v-col cols="3">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni"
                :item-text="(e) => e.cod_articolo"
                item-value="cod_articolo"
                label="Cod. Articolo"
                v-model="cod_articolo_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      cod_articolo_search = '';
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Filtro dell'operatore -->
            <v-col cols="3">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni"
                :item-text="(e) => e.nominativo"
                item-value="nominativo"
                label="Operatore"
                v-model="nominativo_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      nominativo_search = '';
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- Filtro della data da -->
            <v-col cols="2">
              <v-menu
                v-model="calendario_data_da"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    label="Data Da"
                    v-model="data_da_search_formattata"
                    readonly
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="data_da_search"
                  @input="calendario_data_da = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Filtro della data a -->
            <v-col cols="2">
              <v-menu
                v-model="calendario_data_a"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    dense
                    hide-details
                    label="Data A"
                    v-model="data_a_search_formattata"
                    readonly
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="data_a_search"
                  @input="calendario_data_a = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Filtro della validazione -->
            <v-col cols="2">
              <v-autocomplete
                @change="filtra"
                :items="items_registrazioni"
                :item-text="
                  (e) =>
                    e.conformita === null
                      ? 'indefinito'
                      : e.conformita === 1
                      ? 'approvato'
                      : 'respinto'
                "
                item-value="conformita"
                label="Conformità"
                v-model="conformita_search"
              >
                <template slot="append">
                  <v-icon
                    @click="
                      conformita_search = 2;
                      filtra();
                    "
                    color="primary"
                  >
                    mdi-filter-off
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </template>

        <!-- Colonna della quantità contenitori -->
        <template v-slot:[`item.qta_box`]="{ item }">
          {{item.qta_box != null ? item.qta_box : "N/D"}}
        </template>

        <!-- Colonna della quantità pezzi per scatola -->
        <template v-slot:[`item.qta_pz`]="{ item }">
          {{item.qta_box != null ? item.qta_pz : "N/D"}}
        </template>

        <!-- Colonna della quantità totale -->
        <template v-slot:[`item.quantita`]="{ item }">
          {{item.qta_box != null ? item.quantita : "N/D"}}
        </template>

        <!-- Colonna della data -->
        <template v-slot:[`item.data`]="{ item }">
          {{
            `${item.data.substring(8, 10)}/${item.data.substring(
              5,
              7
            )}/${item.data.substring(0, 4)} - ${item.data.substring(
              11,
              13
            )}:${item.data.substring(14, 16)}`
          }}
        </template>

        <!-- Colonna della conformità -->
        <template v-slot:[`item.conformita`]="{ item }">
          <v-icon
            :color="item.conformita === 1 ? 'success' : 'grey'"
            @click="
              $set(item, 'conformita', 1);
              setContenitori(item);
            "
          >
            {{
              item.conformita === 1
                ? "mdi-checkbox-marked"
                : "mdi-checkbox-blank-outline"
            }}
          </v-icon>
          <v-icon
            :color="item.conformita === 0 ? 'error' : 'grey'"
            @click="
              $set(item, 'conformita', 0);
              setContenitori(item);
            "
          >
            {{
              item.conformita === 0
                ? "mdi-close-box"
                : "mdi-checkbox-blank-outline"
            }}
          </v-icon>
          <v-icon
            :color="item.conformita === null ? 'primary' : 'grey'"
            @click="
              $set(item, 'conformita', null);
              setContenitori(item);
            "
          >
            {{
              item.conformita === null
                ? "mdi-checkbox-intermediate"
                : "mdi-checkbox-blank-outline"
            }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import moment from "moment/moment";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ControlloQualita",
  components: { SnackbarQV },
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo_search: "",
      conformita_search: 2,
      data_da_search: moment().subtract(3, "days").format("YYYY-MM-DD"),
      data_a_search: moment().format("YYYY-MM-DD"),
      nominativo_search: "",

      // Modal
      calendario_data_da: false,
      calendario_data_a: false,
      filtri_visible: false,

      // Array
      headers_registrazioni: [
        { text: "Cod. Commessa", value: "cod_commessa", sortable: false },
        { text: "Cod. Articolo", value: "cod_articolo", sortable: false },
        { text: "Des. Fase", value: "des_fase", sortable: false },
        { text: "Lotto", value: "lotto", sortable: false },
        { text: "Contenitori", value: "qta_box", sortable: false },
        { text: "Pz. / Contenitore", value: "qta_pz", sortable: false },
        { text: "Tot. Pz.", value: "quantita", sortable: false },
        {
          text: "Operatore",
          value: "nominativo",
          sortable: false,
        },

        { text: "Data", value: "data", sortable: false },
        { text: "Conformità", value: "conformita", sortable: false },
      ],
      items_registrazioni: [
        {
          cod_commessa: "C0001",
          cod_articolo: "A00001090",
          des_fase: "Piegatura",
          lotto: "I/003",
          qta_contenitori: 200,
          pz_contenitore: 10,
          quantita: 2000,
          nominativo: "Mario Rossi",
          data: "2021-01-10 11:20:31",
          conformita: null,
        },
        {
          cod_commessa: "C0002",
          cod_articolo: "A00001091",
          des_fase: "Piegatura",
          lotto: "I/004",
          qta_contenitori: 100,
          pz_contenitore: 10,
          quantita: 1000,
          nominativo: "Luca Bianchi",
          data: "2021-03-15 12:29:11",
          conformita: null,
        },
        {
          cod_commessa: "C0003",
          cod_articolo: "A00001092",
          des_fase: "Piegatura",
          lotto: "I/005",
          qta_contenitori: 200,
          pz_contenitore: 20,
          quantita: 4000,
          nominativo: "Bruno Neri",
          data: "2021-06-31 10:32:32",
          conformita: null,
        },
      ],
      items_registrazioni_totali: [
        {
          cod_commessa: "C0001",
          cod_articolo: "A00001090",
          des_fase: "Piegatura",
          lotto: "I/003",
          qta_contenitori: 200,
          pz_contenitore: 10,
          quantita: 2000,
          nominativo: "Mario Rossi",
          data: "2021-01-10 11:20:31",
          conformita: null,
        },
        {
          cod_commessa: "C0002",
          cod_articolo: "A00001091",
          des_fase: "Piegatura",
          lotto: "I/004",
          qta_contenitori: 100,
          pz_contenitore: 10,
          quantita: 1000,
          nominativo: "Luca Bianchi",
          data: "2021-03-15 12:29:11",
          conformita: null,
        },
        {
          cod_commessa: "C0003",
          cod_articolo: "A00001092",
          des_fase: "Piegatura",
          lotto: "I/005",
          qta_contenitori: 200,
          pz_contenitore: 20,
          quantita: 4000,
          nominativo: "Bruno Neri",
          data: "2021-06-31 10:32:32",
          conformita: null,
        },
      ],
    };
  },
  computed: {
    data_a_search_formattata() {
      return this.data_a_search != ""
        ? moment(this.data_a_search).format("DD/MM/YYYY")
        : "";
    },
    data_da_search_formattata() {
      return this.data_da_search != ""
        ? moment(this.data_da_search).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    filtra() {
      /* Preparo un array temporaneo in cui
       * collezionare gli elementi filtrati */
      let elementi_tmp = [];
      this.items_registrazioni_totali.forEach((e) => {
        elementi_tmp.push(Object.assign({}, e));
      });

      // Filtro per codice articolo
      if (this.cod_articolo_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.cod_articolo.includes(this.cod_articolo_search)) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per nominativo
      if (this.nominativo_search != "") {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.nominativo === this.nominativo_search) {
            res.push(Object.assign({}, elem));
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      /* Filtro per conformità. Il suo valore neutro è 2, perché
       * il BE potrebbe già valorizzare la proprietà con null. */
      if (this.conformita_search != 2) {
        let res = [];
        elementi_tmp.forEach((elem) => {
          if (elem.conformita === this.conformita_search) {
            res.push(Object.assign({}, elem));
          } else {
            console.log(
              `no match: ${elem.conformita} ${this.conformita_search}`
            );
          }
        });
        elementi_tmp = [];
        res.forEach((e) => {
          elementi_tmp.push(Object.assign({}, e));
        });
      }

      // Ricostruisco gli elementi visualizzati in base agli elementi filtrati.
      this.items_registrazioni = Array.from(elementi_tmp);
    },
    getContenitori() {
      const request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcontenitoricristal",
          token: localStorage.user_token,
          data_da: this.data_da_search + " 00:00:00",
          data_a: this.data_a_search + " 00:00:00",
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.items_registrazioni = Array.from(res.data.result.contenitori);
            this.items_registrazioni_totali = Array.from(
              res.data.result.contenitori
            );
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(JSON.stringify(err));
            // Stampo una snackbar di errore
            this.$refs.SnackbarQV.print(
              "error",
              `Errore critico nello scambio di dati col server.`
            );
          }
        });
    },
    resetProperties() {},
    ripristina() {
      // Rendo invisibili i filtri e resetto le chiavi di filtro
      this.cod_articolo_search = "";
      this.data_da_search = moment().subtract(3, "days").format("YYYY-MM-DD");
      this.data_a_search = moment().format("YYYY-MM-DD");
      this.nominativo_search = "";
      this.filtri_visible = false;

      // Resetto gli elementi visualizzati in tabella
      this.items_registrazioni = [];
      this.items_registrazioni_totali.forEach((e) => {
        this.items_registrazioni.push(Object.assign({}, e));
      });

      this.filtra();
    },
    setContenitori(arg) {
      const request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcontenitorecristal",
          token: localStorage.user_token,
          contenitore: [arg],
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.getContenitori();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità di dialogo col server, codice errore: ${res.status}.`
            );
          }
        })
        .catch(() => {
          this.$refs.SnackbarQV.print(
            "error",
            "Errore critico nello scambio di dati col server."
          );
        });
    },
  },
  created() {},
  mounted() {
    this.getContenitori();
  },
};
</script>
<style scoped>
</style>