<template>
  <div style="width: 100%" class="pt-5">
    <!-- Div contenente lo sfondo (fisso) di questa videata -->
    <div class="sfondo" style="position: fixed; left: 0px; top: 0px"></div>
    <v-row v-if="menu_visible">
      <v-col cols="0" md="6" class="py-0"></v-col>
      <v-col class="pr-md-10 px-10 py-0" cols="12" md="6">
        <!-- Menu di selezione -->
        <Menu
          style="display: inline-block; width: 100%"
          @click_form_auth="(Prod_TextFieldAuth_visible = true), (menu_visible = false)"
          @click_action_auth="(Prod_ButtonAuth_visible = true), (menu_visible = false)"
          v-if="menu_visible"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="Prod_ButtonAuth_visible">
      <v-col cols="10" class="py-0">
        <TabellaButtonAuth
          @click_chiudi="(Prod_ButtonAuth_visible = false), (menu_visible = true)"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="Prod_TextFieldAuth_visible">
      <v-col cols="10" class="py-0">
        <TabellaTextFieldAuth
          @click_chiudi="(Prod_TextFieldAuth_visible = false), (menu_visible = true)"
        />
      </v-col>
    </v-row>
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Menu from "./AuthSettingMenu";
import TabellaButtonAuth from "./TabellaButtonAuth";
import TabellaTextFieldAuth from "./TabellaTextFieldAuth";

export default {
  name: "Avanzamento",
  components: {
    Menu,
    TabellaButtonAuth,
    TabellaTextFieldAuth,
  },
  data() {
    return {
      // Variabili d'appoggio

      // Modal
      caricamento_dati: false,
      Prod_ButtonAuth_visible: false,
      Prod_TextFieldAuth_visible: false,
      menu_visible: true,

      // Array
    };
  },
  methods: {},
};
</script>
<style scoped>
.sfondo {
  width: 100vw !important;
  background-image: url("../../../assets/Ind.jpg");

  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100vh !important; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden !important; /* Resize the background image to cover the entire container */
}
</style>
