/* Questa classe fornisce l'oggetto di riferimento
 * per le Sedi di Manutenzione all'interno del modulo
 * Manutenzione per EduCatt e altri. */
export default class Machine {
    constructor(arg) {
        if (arg != undefined) {
            this.id_macchinario = arg.id_macchinario === undefined ? -1 : arg.id_macchinario;
            this.id_famiglia = arg.id_famiglia === undefined ? -1 : arg.id_famiglia;
            this.des_famiglia = arg.des_famiglia === undefined ? "" : arg.des_famiglia;
            this.des_macchinario = arg.des_macchinario === undefined ? "" : arg.des_macchinario;
            this.cod_macchinario = arg.cod_macchinario === undefined ? "" : arg.cod_macchinario;
            this.frequenze = arg.frequenze === undefined ? [] : arg.frequenze;
        } else {
            this.id_macchinario = -1;
            this.id_famiglia = null;
            this.des_famiglia = "";
            this.des_macchinario = "";
            this.cod_macchinario = "";
            this.frequenze = [];
        }
    }
}