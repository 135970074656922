var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Montaggio Bobina ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","large":""}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-5"},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("Inserire manualmente il codice della bobina da montare, oppure usare il lettore di QR Code")]),_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"12","justify":"center"}},[_c('div',{staticClass:"mr-5",staticStyle:{"display":"inline-block"}},[_c('v-btn',{attrs:{"large":""},on:{"click":_vm.clickQRCode}},[_c('v-icon',{attrs:{"color":"indigo","large":""}},[_vm._v(" mdi-qrcode-scan ")])],1)],1),_c('div',{staticStyle:{"display":"inline-block"}},[_c('v-text-field',{staticClass:"input-class centered-input",staticStyle:{"display":"inline-block","font-size":"20px","font-weight":"bold","line-height":"25px","padding":"0px"},attrs:{"background-color":"#ffffff","dense":"","height":"50px","hide-details":"","label":""},model:{value:(_vm.cod_bobina),callback:function ($$v) {_vm.cod_bobina=$$v},expression:"cod_bobina"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-10",on:{"click":function($event){return _vm.confermaMontaggio('manuale')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success","large":""}},[_vm._v(" mdi-check")])],1)]}}])},[_c('span',[_vm._v("Conferma")])])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.qr_reader_visible),callback:function ($$v) {_vm.qr_reader_visible=$$v},expression:"qr_reader_visible"}},[_c('v-toolbar',[_c('v-toolbar-title'),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.handleCloseQRDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","large":""}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('qrcode-stream',{staticClass:"qr",attrs:{"camera":_vm.camera},on:{"decode":_vm.handleOnDecode}})],1),_c('v-overlay',{attrs:{"value":_vm.caricamento_dati}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }