<template>
    <figure class="graph_appitem">
        <div class="graph_card" @click="$emit(data.type == 0 ? 'click_folder' : 'click_ds', data)" :data-test="data.image">
            <datalist>
                <option data-name="Modifica" data-icon="edit" v-if="isAdmin() || data.type == 0" @contextmenu="$emit(data.type == 0 ? 'edit_folder' : 'edit_ds', data)"/>
                <option data-name="Elimina" data-icon="delete" v-if="isAdmin() || data.type == 0" @contextmenu="$emit(data.type == 0 ? 'delete_folder' : 'delete_ds', data)" />
                <option data-name="Sposta In" data-icon="move" v-if="data.type != 0 && !in_folder" @contextmenu="$emit('moveitem', data)" />
                <option data-name="Rimuovi dalla cartella" data-icon="remove" v-if="in_folder==true" @contextmenu="$emit('remove', data)" />
            </datalist>
            <GraphIcon :icon="data.type == 0 ? 'folder' : 'dashboard'" v-if="data.image==null" />
            <img :src="getImage(data)" v-else />
            <!-- img src="https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png" / -->
        </div>
        <span>{{data.name}}</span>
    </figure>
</template>
<script>
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";
export default {
    name : 'GraphAppItem',
    props : { 
        data : Object, 
        in_folder : Boolean,
        images : Array
    },
    components : { GraphIcon },
    methods : {
        isAdmin(){return Number(localStorage.id_profilo)==1;},
        getImage(item){
            if(item.type==0){
                if(this.images == null){ return null; }
                for(let i=0; i < this.images.length;i++){
                    const image = this.images[i];
                    if(image.id_file == item.image){
                        return image.contenuto;
                    }
                }
                return null;
            }else{
                return item.image
            }
        }
    }
}
</script>
<style scoped>
    .graph_appitem{
        width: 240px;
        height: 270px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin: 15px;
    }

    .graph_appitem > div{
        position: relative;
        width:240px;
        height: 240px;
        display: block;
        border-radius: 8px;
        cursor: pointer;
        overflow:hidden;
    }

    .graph_appitem > div > img{
        display: block;
        width:100%;
        height: 100%;
        margin:0;
        padding: 0;
        object-fit: contain;
        object-position: center;
        filter: drop-shadow(0 8px 5px rgba(0,0,0,0.2));
    }

    .graph_appitem > span{
        width:100%;
        height: 30px;
        line-height: 13px;
        overflow: hidden;
        font-size: 13px;
        font-weight: bold;
    }
</style>