var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{staticStyle:{"position":"sticky","top":"0px","z-index":"1"},attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Modifica Barche")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaModificaBarca}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva Modifica")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","dense":""}},[_c('v-text-field',{attrs:{"label":"Nome Barca"},model:{value:(_vm.barca_sel_tmp.des_macchina),callback:function ($$v) {_vm.$set(_vm.barca_sel_tmp, "des_macchina", $$v)},expression:"barca_sel_tmp.des_macchina"}})],1),_c('v-col',{attrs:{"cols":"4","dense":""}},[_c('v-text-field',{attrs:{"label":"Matricola"},model:{value:(_vm.barca_sel_tmp.matricola),callback:function ($$v) {_vm.$set(_vm.barca_sel_tmp, "matricola", $$v)},expression:"barca_sel_tmp.matricola"}})],1),_c('v-col',{attrs:{"cols":"4","dense":""}},[_c('v-text-field',{attrs:{"label":"Modello"},model:{value:(_vm.barca_sel_tmp.modello),callback:function ($$v) {_vm.$set(_vm.barca_sel_tmp, "modello", $$v)},expression:"barca_sel_tmp.modello"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","dense":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","hide-details":"","label":"Inizio Garanzia","readonly":""},model:{value:(_vm.inizio_garanzia_formattata),callback:function ($$v) {_vm.inizio_garanzia_formattata=$$v},expression:"inizio_garanzia_formattata"}},on))]}}]),model:{value:(_vm.calendario_inizio_garanzia),callback:function ($$v) {_vm.calendario_inizio_garanzia=$$v},expression:"calendario_inizio_garanzia"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario_inizio_garanzia = false}},model:{value:(_vm.barca_sel_tmp.inizio_garanzia),callback:function ($$v) {_vm.$set(_vm.barca_sel_tmp, "inizio_garanzia", $$v)},expression:"barca_sel_tmp.inizio_garanzia"}})],1)],1),_c('v-col',{attrs:{"cols":"4","dense":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","hide-details":"","label":"Fine Garanzia","readonly":""},model:{value:(_vm.fine_garanzia_formattata),callback:function ($$v) {_vm.fine_garanzia_formattata=$$v},expression:"fine_garanzia_formattata"}},on))]}}]),model:{value:(_vm.calendario_fine_garanzia),callback:function ($$v) {_vm.calendario_fine_garanzia=$$v},expression:"calendario_fine_garanzia"}},[_c('v-date-picker',{attrs:{"min":_vm.barca_sel_tmp.inizio_garanzia},on:{"input":function($event){_vm.calendario_fine_garanzia = false}},model:{value:(_vm.barca_sel_tmp.fine_garanzia),callback:function ($$v) {_vm.$set(_vm.barca_sel_tmp, "fine_garanzia", $$v)},expression:"barca_sel_tmp.fine_garanzia"}})],1)],1),_c('v-col',{attrs:{"cols":"4","dense":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"background-color":"#ffffff","hide-details":"","label":"Data Consegna","readonly":""},model:{value:(_vm.data_acquisto_formattata),callback:function ($$v) {_vm.data_acquisto_formattata=$$v},expression:"data_acquisto_formattata"}},on))]}}]),model:{value:(_vm.calendario_data_consegna),callback:function ($$v) {_vm.calendario_data_consegna=$$v},expression:"calendario_data_consegna"}},[_c('v-date-picker',{on:{"input":function($event){_vm.calendario_data_consegna = false}},model:{value:(_vm.barca_sel_tmp.data_acquisto),callback:function ($$v) {_vm.$set(_vm.barca_sel_tmp, "data_acquisto", $$v)},expression:"barca_sel_tmp.data_acquisto"}})],1)],1)],1)],1)],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }