<template>
    <div class="anagrafica_quickconnext" style="margin:20px">
        <v-card>
            <v-toolbar dense color="primary" dark align="center">
                <v-row>
                    <v-col cols="5" align="left">
                        <v-toolbar-title>Anagrafica QuickConnext</v-toolbar-title>
                    </v-col>
                    <v-col cols="1">
                        <v-btn small light @click="setAnagrafica">
                            <v-icon color="success">mdi-floppy</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn small light @click="mostraDialog(null)" >
                            <v-icon color="primary">mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" align="right">
                        <v-btn small light @click="refresh">
                            <v-icon color="primary">mdi-autorenew</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="1" align="right">
                        <v-btn small light @click="$router.push('/home')">
                            <v-icon color="error">mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-container v-if="clienti!=null&&dispositivi!=null">
                <v-data-table :headers="testata" :items="dispositivi" disable-pagination hide-default-footer>
                    <!-- Bottone aggiorna -->
                    <template v-slot:[`item._update`]="{ item }">
                        <v-btn small @click="aggiornaConfigurazione(item.device_id)">
                            <v-icon color="primary">mdi-refresh-circle</v-icon>
                        </v-btn>
                    </template>
                    <!-- Bottone impostazioni -->
                    <template v-slot:[`item._manage`]="{ item }">
                        <v-btn class="ml-4" small @click="mostraDialog(item)">
                            <v-icon color="primary">mdi-cog</v-icon>
                        </v-btn>
                    </template>
                    <!-- Icona attivo -->
                    <template v-slot:[`item.enabled`]="{ item }">
                        <v-icon :color="item.enabled==1?'success':'error'">{{ item.enabled==1?'mdi-check':'mdi-close'}}</v-icon>
                    </template>
                    <!-- Cliente -->
                    <template v-slot:[`item.id_azienda`]="{ item }">
                        {{ getAzienda(item.id_azienda) }}
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
        <v-dialog persistent v-model="dialog.mostra" width="900">
            <v-card v-if="dialog.mostra==true">
                <v-toolbar dense color="primary" dark>
                    <v-row align="center" dark>
                        <v-col cols="5" align="left">
                            <v-toolbar-title>Modifica Dispositivo</v-toolbar-title>
                        </v-col>
                        <v-col cols="1">
                            <v-btn small light @click="salvaDettaglioDispositivo">
                                <v-icon color="success">mdi-floppy</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="1">
                            <v-btn small light @click="aggiornaChiavi(dialog.template.device_id)">
                                <v-icon color="primary">mdi-shield-refresh</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="5" align="right">
                            <v-btn small light @click="dialog.mostra = false">
                                <v-icon color="error">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-container>
                    <v-row justify="center">
                        <v-col cols = "3" md="3" sm="3" lg="3" align="center">
                            <v-text-field v-model="dialog.template.device_name" label="Nome Dispositivo" />
                        </v-col>
                        <v-col cols = "3" md="3" sm="3" lg="3" align="center">
                            <v-text-field v-model="dialog.template.serial" label="Seriale" />
                        </v-col>
                        <v-col cols = "3" md="3" sm="3" lg="3" align="center">
                            <v-select v-model="dialog.template.id_azienda" :items="clienti" item-text="ragione_sociale" item-value="id_azienda" label="Cliente" />
                        </v-col>
                        <v-col cols = "3" md="3" sm="3" lg="3"  align="center">
                            <v-text-field type="number" v-model="dialog.template.settings.log_level" background-color="transparent" label="Log level" />
                        </v-col>
                    </v-row>
                    <v-row justify="start">
                        <v-col cols="4" align="center">
                            <h3 class="mb-5" style="text-align:left">Interfaccia Input</h3>
                            <v-checkbox class="ma-0 pa-0" label="QuickProduction" v-model="dialog.template.settings.input.use_default" />
                            <v-checkbox class="ma-0 pa-0" label="Custom" v-model="dialog.template.settings.input.use_external" />
                        </v-col>
                        <v-col cols="4" align="center">
                            <h3 class="mb-5" style="text-align:left">Interfaccia Output</h3>
                            <v-checkbox class="ma-0 pa-0" label="QuickProduction" v-model="dialog.template.settings.output.use_default" />
                            <v-checkbox class="ma-0 pa-0" label="Custom" v-model="dialog.template.settings.output.use_external" />
                        </v-col>
                        <v-col cols="4" align="center">
                            <v-checkbox class="ma-0" label="Attivo" v-model="dialog.template.enabled" :true-value="1" :false-value="0" />
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="4">
                                <v-btn text color="error" @click="dialog.mostra_prompt=true" class="mb-n4">Elimina Dispositivo</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="400" v-model="dialog.mostra_prompt">
            <v-card>
                <v-container>
                    <v-card-title>Eliminare il dispositivo?</v-card-title>
                </v-container>
                <v-card-actions>
                    <v-btn text color="primary" @click="dialog.mostra_prompt=false">Annulla</v-btn>
                    <v-spacer />
                    <v-btn text color="error" @click="eliminaDispositivo">Elimina</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="400" v-model="alert.mostra">
            <v-card>
                <v-container>
                    <v-row align="center" justify="center">
                        <v-col cols="1" align="center">
                            <v-icon large>{{ alert.icona }}</v-icon>
                        </v-col>
                        <v-col cols="11">
                            <h3 style="word-break:keep-all;text-align:left;padding:0 20px">{{ alert.messaggio }}</h3>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="alert.mostra=false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name : 'AnagraficaQuickConnext',
    data(){
        return{
            alert : {
                icona : null,
                messaggio : null,
                mostra : false,
            },
            clienti : null,
            dialog : {
                mostra : false,
                mostra_prompt:false,
                src : null,
                template : {},
            },
            dispositivi : null,
            NETWORK : null,
            testata : [
                { text: null, value : '_update'},
                { text: null, value : '_manage'},
                { text: 'Nome dispositivo', value : 'device_name'},
                { text: 'Cliente', value : 'id_azienda'},
                { text: 'Seriale', value : 'serial'},
                { text: 'Attivo', value : 'enabled'},
                { text: 'Online', value : 'last_time_online'}
            ]
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.refresh();
    },
    methods : {
        aggiornaChiavi(device_id){
            const that = this;
            const request = {
                controller: 'quickconnext',
                response : { parse : false },
                showdialog : false,
                body: {
                    action: 'updatedevicekey',
                    token: localStorage.user_token,
                    device_id : device_id
                },
                method: 'PUT',
                on: {
                    load: function(){
                        Object.assign(that.alert,{
                            icona : 'mdi-shield-check',
                            messaggio : 'Chiavi aggiornate correttamente',
                            mostra : true
                        });
                    },
                    error : function(response){
                        response = response.target;
                        Object.assign(that.alert,{
                            icona : 'mdi-alert-box',
                            messaggio : response.responseText,
                            mostra : true
                        });
                    }
                },
            };
            this.NETWORK.AjaxRequest(request);
        },
        aggiornaConfigurazione(device_id){
            const that = this;
            const request = {
                controller: 'quickconnext',
                response : { parse : false },
                showdialog : false,
                body: {
                    action: 'updatedeviceconfig',
                    token: localStorage.user_token,
                    device_id : device_id
                },
                method: 'PUT',
                on: {
                    load: function(){
                        Object.assign(that.alert,{
                            icona : 'mdi-check-network',
                            messaggio : 'Refresh configurazione eseguito correttamente',
                            mostra : true
                        });
                    },
                    error : function(response){
                        response = response.target;
                        Object.assign(that.alert,{
                            icona : 'mdi-alert-box',
                            messaggio : response.responseText,
                            mostra : true
                        });
                    }
                },
            };
            this.NETWORK.AjaxRequest(request);
        },
        getAnagrafica(){
            const that = this;
            const request = {
                controller: 'quickconnext',
                response : { parse : false},
                body: {
                    action: 'getdevicelist',
                    token: localStorage.user_token,
                },
                method: 'POST',
                on: {
                    load: function(response){
                        that.dispositivi = JSON.parse(response);
                    },
                },
            };
            this.NETWORK.AjaxRequest(request);
        },
        getAzienda(id){
            for(let i = 0; i < this.clienti.length; i++){
                const azienda = this.clienti[i];
                if(azienda.id_azienda==id){ return azienda.ragione_sociale; }
            }
            return null;
        },
        getAziende() {
            const that = this;
            const request = {
                controller: 'Modulo',
                body: {
                    action: 'getAzienda',
                    token: localStorage.user_token,
                },
                method: 'POST',
                on: {
                    load: function(response){ that.clienti = response.azienda },
                },
            };
            this.NETWORK.AjaxRequest(request);
        },
        eliminaDispositivo(){
            const device = this.dialog.src;
            const that = this;
            function closeAll(){
                that.dialog.mostra = false;
                that.dialog.mostra_prompt = false;
            }
            if(device == null){
                closeAll();
            }else if(device.device_id == -1){
                this.dispositivi.splice(this.dispositivi.indexOf(device),1);
                closeAll();
            }else{
                const request = {
                    controller: 'quickconnext',
                    body: {
                        action: 'setdeviceinfo',
                        token: localStorage.user_token,
                        devices : [{
                            device_id : device.device_id,
                            validita : 0
                        }]
                    },
                    response : { parse : false },
                    method: 'PUT',
                    on: {
                        load: function(){
                            closeAll();
                            that.dispositivi.splice(that.dispositivi.indexOf(device),1);
                        },
                    },
                };
                this.NETWORK.AjaxRequest(request);
            }
        },
        mostraDialog(item = null){
            const template = this.dialog.template;
            const dialog_keys = Object.keys(template);
            for(let i = 0; i < dialog_keys.length; i++){
                delete template[dialog_keys[i]];
            }
            if(item == null){
                this.dialog.src = null;
                Object.assign(template, {
                    device_id : -1,
                    device_name : null,
                    id_azienda : null,
                    settings : null,
                    serial: null,
                    enabled : 1,
                });
            }else{
                this.dialog.src = item;
                Object.assign(template, item);
            }
            try{
                template.settings = JSON.parse(decodeURIComponent(escape(atob(template.settings))));
            }catch{
                template.settings = {
                    output : { use_external : false, use_default : true },
                    input : { use_external : false, use_default : true },
                    log_level : 0
                }
            }
            this.dialog.mostra = true;
        },
        refresh(){
            this.getAziende();
            this.getAnagrafica();
        },
        salvaDettaglioDispositivo(){
            this.dialog.mostra = false;
            let device = this.dialog.src;
            if(device==null){
                device = new Object();
                this.dispositivi.push(device);
            }
            this.dialog.template.settings = btoa(unescape(encodeURIComponent(JSON.stringify(this.dialog.template.settings))));
            Object.assign(device,this.dialog.template);
        },
        setAnagrafica(){
            const that = this;
            const request = {
                controller: 'quickconnext',
                body: {
                    action: 'setdeviceinfo',
                    token: localStorage.user_token,
                    devices : this.dispositivi
                },
                response : { parse : false },
                method: 'PUT',
                on: {
                    load: that.getAnagrafica,
                },
            };
            this.NETWORK.AjaxRequest(request);
        }
    }
}
</script>