var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"listener_controllo_qualita"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"5","align":"left"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Controllo Qualità ")])],1),_c('v-col',{attrs:{"cols":"2","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.setControlli}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])])],1),_c('v-col',{attrs:{"cols":"5","align":"right"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickChiudi}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1)],1)],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"max-height":"78vh","overflow-y":"auto !important"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers_controllo,"height":"50vh","items":_vm.items_controllo},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"indigo","dense":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Caratteristiche")])],1)]},proxy:true},{key:"item.frequenza",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frequenza)+" "+_vm._s(item.des_frequenza)+" ")]}},{key:"item.misura",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
            item.valore !== null &&
            item.valore !== undefined &&
            !isNaN(item.valore)
          ),expression:"\n            item.valore !== null &&\n            item.valore !== undefined &&\n            !isNaN(item.valore)\n          "}],staticClass:"centered-input pa-0 ma-0",staticStyle:{"max-width":"80px","margin":"0"},attrs:{"background-color":_vm.coloreSuValore(item),"dense":"","hide-details":"","outlined":""},model:{value:(item.misura),callback:function ($$v) {_vm.$set(item, "misura", $$v)},expression:"item.misura"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            item.valore == null ||
            item.valore == undefined ||
            isNaN(item.valore)
          ),expression:"\n            item.valore == null ||\n            item.valore == undefined ||\n            isNaN(item.valore)\n          "}],staticStyle:{"text-align":"left"}},[_c('v-btn',{staticStyle:{"display":"inline-block","vertical-align":"middle"},attrs:{"icon":"","small":""},on:{"click":function($event){item.misura = item.misura == 1 ? 0 : 1}}},[_c('v-icon',{attrs:{"color":item.misura == 1 ? 'green' : 'red',"large":""}},[_vm._v(_vm._s(item.misura == 1 ? "mdi-checkbox-marked" : "mdi-close-box"))])],1),_c('span',{style:('margin-left:5px;display:inline-block;vertical-align:middle;font-size:18px;font-weight:bold;color:' +
              (item.misura == 1 ? 'green' : 'red'))},[_vm._v(_vm._s(item.misura == true ? "OK" : "NOK"))])],1)]}},{key:"item.controllo_webcam",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.clickControllo(item)}}},[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v(" mdi-camera ")])],1)]}},{key:"item.miniatura",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticStyle:{"background-repeat":"no-repeat","background-size":"contain","border":"1px solid black","height":"100px","margin-top":"5px","position":"relative","width":"100px"},attrs:{"id":item.id_ciclo_dettaglio}})]}}],null,true)})],1),(_vm.controllo_webcam_visible)?_c('div',{staticStyle:{"background-color":"rgba(33, 33, 33, 0.46)","bottom":"0","height":"100%","left":"0","position":"absolute","right":"0","top":"0","width":"100%","z-index":"2"}},[_c('div',{staticStyle:{"height":"90%","left":"5%","position":"absolute","top":"5%","width":"90%"}},[_c('Controllo',{attrs:{"destinazione":'listener_controllo_qualita',"id_articolo":_vm.work_order_sel.id_articolo,"immagine_standard_editor":_vm.immagine_standard_editor,"riferimento":_vm.id_ciclo_controllo_dettaglio_sel,"visible":_vm.visible},on:{"click_indietro":function($event){_vm.controllo_webcam_visible = false}}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }