var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3","id":"listener_consuntivi_massimalePersonale"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"destinazione":'listener_consuntivi_massimalePersonale',"label":'Massimale',"id":'1'}})],1),_c('v-col',{attrs:{"cols":"3","id":"listener_consuntivoPersonale"}},[_c('InputSeparatore',{attrs:{"decimali_fissi":true,"disabled":true,"destinazione":'listener_consuntivoPersonale',"label":'Consuntivo',"id":'2'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.contatore_personale,staticClass:"elevation-1 mx-5",attrs:{"headers":_vm.headers_personale,"items":_vm.items_personale.filter(function (f){ return f.validita !=0; }),"single-expand":true,"expanded":_vm.expanded_fascie_orarie_lavorate,"height":"70vh","item-key":"id_operatore_dettaglio","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_fascie_orarie_lavorate=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers_fascie_orarie_lavorate.filter(
                      function (elem) { return elem.text != 'Modifica' && elem.text != 'Elimina'; }
                    ),"hide-default-footer":"","items":item.fasce_orarie_lavorate.filter(function (f) { return f.validita != 0; })},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Fasce Orarie Lavorate ")])],1)]},proxy:true}],null,true)})],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Spese del Personale Operativo ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.clickGestisciOperatore(false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Personale")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaOreLavorateOperatore(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-clock-time-eight-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ore lavorate")])])]}},{key:"item.elimina",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickEliminaOperatore(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"50%","persistent":""},model:{value:(_vm.dialog_gestione_operatore),callback:function ($$v) {_vm.dialog_gestione_operatore=$$v},expression:"dialog_gestione_operatore"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Aggiungi operatore")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.salvaOperatore()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Aggiungi operatori")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.dialog_gestione_operatore = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col'),_c('v-col',{attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"items":_vm.attivita,"item-text":"titolo","item-value":"id_attivita","label":"Attivita","return-object":""},on:{"change":function($event){return _vm.selezionaOperatori()}},model:{value:(_vm.attivita_selezionata),callback:function ($$v) {_vm.attivita_selezionata=$$v},expression:"attivita_selezionata"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.attivita_selezionata.length == 0,"items":_vm.attivita_selezionata.operatori,"item-text":"nominativo","item-value":"id_operatore","multiple":"","chips":"","deletable-chips":"","label":"Operatori","return-object":""},model:{value:(_vm.operatori_selezionati),callback:function ($$v) {_vm.operatori_selezionati=$$v},expression:"operatori_selezionati"}})],1),_c('v-col')],1)],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.dialog_selezione_fascie_orarie),callback:function ($$v) {_vm.dialog_selezione_fascie_orarie=$$v},expression:"dialog_selezione_fascie_orarie"}},[_c('v-data-table',{key:_vm.contatore_fascie,attrs:{"fixed-header":"","headers":_vm.headers_fascie_orarie_lavorate,"height":"40vh","items":_vm.operatore_sel.fasce_orarie_lavorate.filter(
          function (elem) { return elem.validita != 0; }
        )},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Fasce Orarie Lavorate ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickAggiungiFasciaOrariaLavorata()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Fascia Oraria")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){(_vm.dialog_selezione_fascie_orarie = false),
                    _vm.calcolaConsuntivo()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaFasciaOrariaLavorata(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])])]}},{key:"item.elimina",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.disabilita_eliminazione,"small":""},on:{"click":function($event){return _vm.clickEliminaFasciaOrariaLavorata(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog_ore_lavorate),callback:function ($$v) {_vm.dialog_ore_lavorate=$$v},expression:"dialog_ore_lavorate"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Ore lavorate")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.salvaFasciaOrariaLavorata()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Salva Fascia Oraria")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.dialog_ore_lavorate = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.operatore_sel.fasce_orarie_disponibili,"item-text":function (item) { return ((item.fascia_oraria_inizio) + " - " + (item.fascia_oraria_fine)); },"item-value":"id_anagrafica_tipologia_fascia_oraria","label":"Fascia Oraria","return-object":""},on:{"change":function($event){return _vm.adattaGiorniEDeltaOre()}},model:{value:(_vm.fascia_oraria_sel),callback:function ($$v) {_vm.fascia_oraria_sel=$$v},expression:"fascia_oraria_sel"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                  null,"label":"Tipo giorno","readonly":""},model:{value:(_vm.fascia_oraria_sel.des_tipo_giorno),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "des_tipo_giorno", $$v)},expression:"fascia_oraria_sel.des_tipo_giorno"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                  null,"readonly":"","label":"Tipo maggiorazione"},model:{value:(_vm.fascia_oraria_sel.des_tipo_maggiorazione),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "des_tipo_maggiorazione", $$v)},expression:"fascia_oraria_sel.des_tipo_maggiorazione"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                  null,"readonly":"","items":_vm.fascia_oraria_sel.giorni,"item-text":function (item) { return ("" + (item.des_giorno)); },"multiple":"","label":"Giorni fascia","item-value":"id_giorno"},model:{value:(_vm.fascia_oraria_sel.giorni),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "giorni", $$v)},expression:"fascia_oraria_sel.giorni"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                  null,"readonly":"","type":"number","label":"Costo Orario"},model:{value:(_vm.fascia_oraria_sel.costo),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "costo", $$v)},expression:"fascia_oraria_sel.costo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                  null,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Giorno lavorato","readonly":"","disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                      null},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-calendar-today")])]},proxy:true}],null,true),model:{value:(_vm.fascia_oraria_sel.giorno_lavorato),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "giorno_lavorato", $$v)},expression:"fascia_oraria_sel.giorno_lavorato"}},on))]}}]),model:{value:(_vm.menu_giorno_lavorato),callback:function ($$v) {_vm.menu_giorno_lavorato=$$v},expression:"menu_giorno_lavorato"}},[_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"first-day-of-week":"1","allowed-dates":_vm.allowedDates(this.giorni_disponibili),"color":"indigo"},on:{"input":function($event){_vm.menu_giorno_lavorato = false}},model:{value:(_vm.fascia_oraria_sel.giorno_lavorato),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "giorno_lavorato", $$v)},expression:"fascia_oraria_sel.giorno_lavorato"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{key:_vm.contatore_key_ore,attrs:{"min":0,"type":"number","disabled":_vm.fascia_oraria_sel.id_anagrafica_tipologia_fascia_oraria ==
                  null,"max":_vm.delta_ore,"label":"Ore lavorate"},on:{"input":function($event){return _vm.riadattaOreLavorate(_vm.fascia_oraria_sel.ore_lavorate)}},model:{value:(_vm.fascia_oraria_sel.ore_lavorate),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "ore_lavorate", $$v)},expression:"fascia_oraria_sel.ore_lavorate"}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_fascia_dialog),callback:function ($$v) {_vm.conferma_elimina_fascia_dialog=$$v},expression:"conferma_elimina_fascia_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Eliminazione")]),_c('v-spacer')],1),_c('v-card-text',{staticStyle:{"margin-top":"20px","overflow-y":"auto"}},[_vm._v(" Sei sicuro di voler eliminare questa fascia oraria? "),_c('v-divider',{staticClass:"my-5"}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.clickConfermaEliminaFascia()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.conferma_elimina_fascia_dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_operatore_dialog),callback:function ($$v) {_vm.conferma_elimina_operatore_dialog=$$v},expression:"conferma_elimina_operatore_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Eliminazione")]),_c('v-spacer')],1),_c('v-card-text',{staticStyle:{"margin-top":"20px","overflow-y":"auto"}},[_vm._v(" Sei sicuro di voler eliminare questo operatore? "),_c('v-divider',{staticClass:"my-5"}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.clickConfermaEliminaOperatore()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.conferma_elimina_operatore_dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-spacer')],1)],1)],1),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }