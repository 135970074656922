<template>
  <v-card>
    <v-snackbar v-model="snackbar" :vertical="true">
      {{ message_snackbar }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-toolbar color="primary" dark flat></v-toolbar>

    <v-dialog v-model="blnRenameFolder" max-width="290">
      <v-card>
        <v-card-title class="headline">Edit Cartella</v-card-title>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="strNameFolder" label="Nome Cartella"></v-text-field>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="blnRenameFolder=false">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="saveRenameFolder">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card-text>
          <v-treeview
            color="warning"
            activatable
            shaped
            hoverable
            :items="folder"
            item-key="id"
            open-all
            @update:active="onUpdate"
            content="6"
          >
            <template v-slot:prepend="{ item, open}">
              <v-icon>{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
            </template>
            <template slot="append" slot-scope="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" slot="append" icon color="blue" @click="saveFolder(item);">
                    <v-icon>mdi-folder-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuova Cartella</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    v-if="item.id > 0"
                    icon
                    color="blue"
                    @click="renameFolder(item);"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Rinomina Cartella</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" v-if="item.id > 0" icon color="blue" @click="deleteFolder(item)">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Cancella Cartella</span>
              </v-tooltip>
            </template>
          </v-treeview>

          <v-dialog v-model="dialog_delete_folder" max-width="290">
            <v-card>
              <v-card-title class="headline">Attenzione</v-card-title>
              <v-card-text>Cancella la cartella '{{editedFolder.name}}' ?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog_delete_folder = false">Disagree</v-btn>
                <v-btn color="green darken-1" text @click="deleteFolderItem(editedFolder)">Agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" md="6">
        <div v-if="blnViewAllegato" id="pnlAllegati">
          <v-toolbar flat color="white">
            <v-toolbar-title>{{strPath}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" v-on:click="openNuovoAllegato">Allega File</v-btn>
          </v-toolbar>
          <v-dialog v-model="dialog_new_allegato" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Caricamento nuovo allegato</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="files"
                        color="deep-purple accent-4"
                        counter
                        label="File input"
                        multiple
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >{{ text }}</v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >+{{ files.length - 2 }} File(s)</span>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="select"
                        :items="cmbCategoria"
                        :rules="[v => !!v || 'Item is required']"
                        label="Categoria"
                        required
                        item-text="des"
                        return-object
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            label="Valido al"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title @input="menu2 = false">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_new_allegato = false">Esci</v-btn>
                <v-btn color="blue darken-1" text @click="salvaNuovoAllegato()">Upload</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog_sostituzione_allegato" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Sostituzione allegato</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="files"
                        color="deep-purple accent-4"
                        counter
                        label="File input"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >{{ text }}</v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >+{{ files.length - 2 }} File(s)</span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_sostituzione_allegato = false">Esci</v-btn>
                <v-btn color="blue darken-1" text @click="salvaSostituzioneAllegato()">Upload</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog_revisione_allegato" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Revisione allegato</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="files"
                        color="deep-purple accent-4"
                        counter
                        label="File input"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >{{ text }}</v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >+{{ files.length - 2 }} File(s)</span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_revisione_allegato = false">Esci</v-btn>
                <v-btn color="blue darken-1" text @click="salvaRevisioneAllegato()">Upload</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog_history_allegato" max-width="1200px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <AllegatoList
                    :tipo="editedAllegato.tipo"
                    :id_rif="editedAllegato.id_rif"
                    :blnViewButtonUpload="false"
                    :famiglia="editedAllegato.famiglia"
                    :strPath="this.strPath"
                  ></AllegatoList>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_history_allegato=false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialog_new_allegato_waiting"
            persistent
            content
            content-class="centered-dialog"
            max-width="500px"
          >
            <v-card color="primary" dark>
              <v-container>
                <v-card-text>
                  {{this.message_new_allegato_waiting}}
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <!-- <v-layout column justify-center align-center>
                  <v-progress-circular indeterminate :size="50" :color="primary"></v-progress-circular>
                  <span
                    v-if="message_new_allegato_waiting != null"
                  >{{this.message_new_allegato_waiting}}</span>
                </v-layout>-->
              </v-container>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headers"
            :items="selectListAllegato"
            sort-by="des_allegato"
            class="elevation-1"
            :key="selectListAllegato"
          >
            <template v-slot:[`item.estensione`]="{ item }">
              <v-icon
                v-if="item.estensione == '.pdf' "
                class="mr-2"
                color="red darken-2"
              >mdi-file-pdf</v-icon>
              <v-icon
                v-if="item.estensione == '.jpg'   || item.estensione == '.jpeg'|| item.estensione == '.png'"
                class="mr-2"
                color="yellow darken-2"
              >mdi-file-image</v-icon>
              <v-icon
                v-if="item.estensione == '.xlsx'  || item.estensione == '.xls' || item.estensione == '.csv'"
                class="mr-2"
                color="green darken-2"
              >mdi-microsoft-excel</v-icon>
              <v-icon
                v-if="item.estensione == '.doc'   || item.estensione == '.docx'|| item.estensione == '.dot'"
                class="mr-2"
                color="blue darken-2"
              >mdi-microsoft-word</v-icon>
              <v-icon
                v-if="item.estensione != '.doc'  && item.estensione != '.docx' && item.estensione != '.dot'   && 
                           item.estensione != '.jpg'  && item.estensione != '.jpeg' && item.estensione != '.png'   &&
                           item.estensione != '.xlsx' && item.estensione != '.xls'  && item.estensione != '.csv'   &&
                           item.estensione != '.doc'  && item.estensione != '.docx' && item.estensione != '.dot'   &&
                           item.estensione != '.pdf'"
                class="mr-2"
              >mdi-file</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openHistoryAllegato(item)"
                    color="black darken-2"
                  >mdi-history</v-icon>
                </template>
                <span>History</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openEditAllegato(item)"
                    color="green darken-2"
                  >mdi-pencil</v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="downloadAllegato(item)"
                    color="blue darken-2"
                  >mdi-download</v-icon>
                </template>
                <span>Download</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openRevisioneAllegato(item)"
                    color="olive darken-2"
                  >mdi-file-replace-outline</v-icon>
                </template>
                <span>Revisiona</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mr-2"
                    @click="openSostituzioneAllegato(item)"
                    color="cyan darken-2"
                  >mdi-cloud-upload</v-icon>
                </template>
                <span>Sostituisci</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog_delete_file" max-width="290">
            <v-card>
              <v-card-title class="headline">Attenzione</v-card-title>
              <v-card-text>Cancella il file '{{editedAllegato.des_allegato}}' ?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog_delete_file = false">Disagree</v-btn>
                <v-btn color="green darken-1" text @click="deleteAllegatoItem(editedAllegato)">Agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>

      <v-dialog v-model="dialog_edit_allegato" max-width="500px">
        <v-card>
          <v-card-text>
            <v-card-title class="headline">Edit Allegato</v-card-title>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editedAllegato.des_allegato" label="Allegato" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="editedAllegato.des_categoria"
                  :items="cmbCategoria"
                  :rules="[v => !!v || 'Item is required']"
                  label="Categoria"
                  required
                  item-text="des"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      label="Data Validità"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text v-on:click="dialog_edit_allegato = false">Esci</v-btn>
              <v-btn color="green darken-1" text v-on:click="saveEditAllegato">Upload</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-divider></v-divider>
  </v-card>
</template>

<script>
import config from "../../../public/config";
import axios from "axios";
import AllegatoList from "./AllegatoList.vue";

export default {
  components: {
    AllegatoList
  },
  props: {
    id_rif: {
      type: Number,
      required: true
    },
    tipo: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      config: config,
      dialog_edit_allegato: false, //bool per visualizzare dialog edit allegato
      dialog_data_picker: false,
      blnViewAllegato: false, //bool per visualizzare panel allegati
      blnRenameFolder: false, //bool per visualizzare dialog rename folder
      dialog_new_allegato: false,
      dialog_revisione_allegato: false,
      dialog_sostituzione_allegato: false,
      dialog_history_allegato: false,
      dialog_delete_folder: false,
      dialog_delete_file: false,
      modal: false,
      dialog_new_allegato_waiting: false,
      allegato: [], //array tutti gli allegati per id_rif e tipo
      selectListAllegato: [], //array allegati specifici della cartella selezionata
      selectListChildrenFolder: [], //array della cartella selezionata e delle sue sottocartelle
      folder: [], //array cartelle
      arrayPadre: [], //array con la lista dei nodi tra la cartella selezionata e la root

      strPath: "", //string path della cartella
      strNameFolder: "", //string nuovo nome cartella
      editIdCartella: -1, //int id cartella selezionata
      files: [],
      editedIndexAllegato: -1,
      message_new_allegato_waiting: "",
      date: new Date().toISOString().substr(0, 10),
      snackbar: false,
      message_snackbar: "",

      headers: [
        { text: "", value: "estensione", type: "string" },
        { text: "Allegato", value: "des_allegato" },
        { text: "Rev", value: "num_revisione" },
        { text: "Categoria", value: "des_categoria" },
        { text: "Validità", value: "data_validita", type: "date" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedAllegato: {
        id_padre: 0,
        des_allegato: "",
        num_revisione: 0,
        estensione: "",
        data_validita: new Date().toISOString().substr(0, 10),
        famiglia: 0,
        des_azione: "",
        des_categoria: ""
      },
      editedFolder: {},
      data: vm => ({
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        menu2: false
      }),
      select: [],
      cmbCategoria: []
    };
  },

  created() {
    console.log(this.tipo);
    this.blnRenameFolder = false;
    this.strNameFolder = "";
    this.files = [];
    this.loadFolder();
    this.loadAllengati();
    this.loadCategoria();
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },

  watch: {},

  methods: {
    /* METODO PER CANCELLARE LA CARTELLA */
    deleteFolder(item) {
      this.dialog_delete_folder = true;
      this.editedFolder = item;
    },

    deleteFolderItem(item) {
      this.dialog_new_allegato_waiting = true;
      this.message_new_allegato_waiting =
        "Cancellazione in corso della cartella " + item.des_allegato;
      this.selectListChildrenFolder = [];
      this.getChildren(item);
      var deleteFolder = this.selectListChildrenFolder.filter(function(el) {
        return el != null;
      });
      console.log(deleteFolder);
      this.blnViewAllegato = false;

      this.selectListChildrenFolder.filter(function(el) {
        return el != null;
      });
      this.setDelete(item);
      for (var i = 0; i < deleteFolder.length; i++) {
        const config = {
          headers: {
            "Content-Type": "application/json"
          }
        };
        var object = {
          action: "deleteallegato",
          token: localStorage.user_token,
          id_allegato: deleteFolder[i]["id"],
          id_utente: localStorage.id,
          db_name: "medical",
        };

        var data = JSON.stringify(object);
        axios
          .put(this.$store.state.config.ip + "api/Allegato", data, config)
          .then(response => {
            // JSON responses are automatically parsed.
            if (response.statusText != "No Content") {
              console.log("ok");
              this.dialog_delete_folder = false;
              this.editedFolder = {};
              this.dialog_new_allegato_waiting = false;
              this.message_new_allegato_waiting = "";
              this.snackbar = true;
              this.message_snackbar = "Cartella cancellata correttamente";
            } else {
              console.log(response.statusText);
              this.snackbar = true;
              this.message_snackbar = "Errore : " + response.statusText;
              this.dialog_new_allegato_waiting = false;
              this.message_new_allegato_waiting = "";
            }
          })
          .catch(e => {
            if (e.response.status == 401) {
              alert("Error" + e.response.status);
              this.snackbar = true;
              this.message_snackbar = "Errore : " + e.response.status;
              this.dialog_new_allegato_waiting = false;
              this.message_new_allegato_waiting = "";
              this.folder = [
                {
                  id: 0,
                  name: "root",
                  id_rif: -1
                }
              ];
            }
            console.log(e);
          });
      }
    },

    //METODO GET ARRAY DEI NODI PADRE
    setDelete(item) {
      this.arrayPadre = [];

      this.getFatherDelete(item);
      var albero = {};
      this.arrayPadre.forEach(element => {
        if (element.id != item.id) albero = this.deleteNodo(albero, element);
      });
      var index = albero.children.indexOf(item);
      albero.children.splice(index, 1);
    },

    deleteNodo(array, element) {
      if (Object.keys(array).length === 0) return this.folder[0];
      else {
        var index = array.children.indexOf(element);
        return array.children[index];
      }
    },

    //METODO GET ARRAY PARENT
    getFatherDelete(item) {
      if (item.id_rif == -1) {
        this.arrayPadre.push(item);
        return item;
      } else {
        this.getFatherDelete(this.findItem(item.id_rif, this.folder));
        this.arrayPadre.push(item);
      }
    },

    //METODO PER APRIRE IL DIALOG PER RINOMINARE LA CARTELLA
    renameFolder(item) {
      this.strNameFolder = item.name;
      this.blnRenameFolder = true;
      this.editIdCartella = item.id;
      console.log("renameItem: " + this.editedIndex);
    },

    //METODO PER SALVARE IL NUOVO NOME DELLA CArTELLA
    saveRenameFolder() {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var renameCartella = {
        tipo: this.tipo,
        id_allegato: this.editIdCartella,
        des_allegato: this.strNameFolder
      };

      var object = {
        action: "setAllegato",
        tabella: renameCartella,
        tipo_riga: 1,
        id_utente: localStorage.id,
        token: localStorage.user_token,
        db_name: "medical"
      };

      var data = JSON.stringify(object);
      axios
        .put(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          // JSON responses are automatically parsed.
          console.log(response.statusText);
          if (response.statusText == "OK") {
            this.selectListChildrenFolder = [];
            this.findItem(this.editIdCartella, this.folder)[
              "name"
            ] = this.strNameFolder;
            this.blnRenameFolder = false;
            console.log("saveNewName: " + this.strNameFolder);
          } else console.log(response.statusText);
        })
        .catch(e => {
          if (e.response.status == 401) {
            alert("Error" + e.response.status);
          }
          console.log(e);
        });
    },

    //METODO GET ARRAY DEI FIGLI
    getChildren(item) {
      if (item.children == null) {
        if (this.selectListChildrenFolder.length == 0)
          this.selectListChildrenFolder.push(item);
        return item;
      } else {
        var i;
        if (item != undefined) this.selectListChildrenFolder.push(item);
        for (i = 0; i < item.children.length; i++) {
          this.selectListChildrenFolder.push(
            this.getChildren(item.children[i])
          );
        }
      }
    },

    //METODO GET ARRAY PARENT
    getFather(item) {
      if (item.id_rif == -1) {
        this.strPath += item.name;
        return item;
      } else {
        this.getFather(this.findItem(item.id_rif, this.folder));
        this.strPath += "/" + item.name;
      }
    },

    //METODO VIEW ALLEGATI DELLA CARTELLA
    onUpdate(activatable) {
      if (activatable.length > 0 && activatable[0] > 0) {
        this.selectListAllegato = [];
        var result = this.allegato.filter(obj => {
          return obj.id_padre === activatable[0];
        });
        this.selectListAllegato = result;
        this.strPath = "";
        this.editIdCartella = activatable[0];
        this.getFather(this.findItem(activatable[0], this.folder));
        this.blnViewAllegato = true;
        console.log(activatable);
      } else {
        this.selectListAllegato = [];
        this.blnViewAllegato = false;
      }
    },

    //METODO LOAD COMBO CATEGORIA
    loadCategoria() {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var object = {
        tipo: "CATEGORIA_ALLEGATO",
        token: localStorage.user_token,
      };

      var data = JSON.stringify(object);
      axios
        .post(this.$store.state.config.ip + "api/Global/getTabella", data, config)
        .then(response => {
          // JSON responses are automatically parsed.
          if (response.statusText != "No Content") {
            this.cmbCategoria = response.data;
          } else {
            console.log(response.statusText);
          }
        })
        .catch(e => {
          if (e.response.status == 401) {
            alert("Error" + e.response.status);
            this.cmbCategoria = [];
          }
          console.log(e);
        });
    },

    //METODO LOAD CARTELLE
    loadFolder() {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var object = {
        action: "getallegato",
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        tipo_riga: "1",
        db_name: "medical"
      };

      var data = JSON.stringify(object);
      axios
        .post(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          // JSON responses are automatically parsed.
          if (response.statusText != "No Content") {
            this.folder = response.data;
          } else {
            console.log(response.statusText);
          }
        })
        .catch(e => {
          if (e.response.status == 401) {
            alert("Error" + e.response.status);
            this.folder = [
              {
                id: 0,
                name: "root",
                id_rif: -1
              }
            ];
          }
          console.log(e);
        });
    },

    //METODO SAVE NUOVA CARTELLE
    saveFolder(item) {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var nuovaCartella = {
        tipo: this.tipo,
        id_rif: this.id_rif,
        id_padre: item.id,
        des_allegato: "Nuova Cartella",
        id_allegato: -1,
        tipo_riga: 1
      };

      var object = {
        action: "setallegato",
        tipo_riga: 1,
        id_utente: localStorage.id,
        tabella: nuovaCartella,
        token: localStorage.user_token,
        db_name: "medical"
      };

      var data = JSON.stringify(object);
      axios
        .put(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          // JSON responses are automatically parsed.
          console.log(response.statusText);
          if (response.statusText == "OK") {
            this.loadFolder();
          } else console.log(response.statusText);
        })
        .catch(e => {
          if (e.response.status == 401) {
            alert("Error" + e.response.status);
          }
          console.log(e);
        });
    },

    //METODO PER FILTRARE GLI ALLEGATI
    selectAllegato(item) {
      var result = this.allegato.filter(obj => {
        return obj.id_padre === item.id_rif;
      });
      this.selectListAllegato = result;
    },

    //METODO LOAD ALLEGATI DELLA CARTELLA
    loadAllengati() {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var object = {
        action: "getallegato",
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        tipo_riga: "2",
        db_name: "medical"
      };

      var data = JSON.stringify(object);
      axios
        .post(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          // JSON responses are automatically parsed.
          if (response.statusText != "No Content")
            this.allegato = response.data;
          else this.allegato = [];
        })
        .catch(e => {
          if (e.response.status == 401) {
            alert("Error" + e.response.status);
          }
          console.log(e);
        });
    },

    //METODO EDIT SINGOLA RIGA ALLEGATO
    openEditAllegato(item) {
      this.editedAllegato = Object.assign({}, item);
      this.editedIndexAllegato = this.allegato.indexOf(item);
      this.date = new Date(item.data_validita).toISOString().substr(0, 10);
      this.editedIndexSelectListAllegato = this.selectListAllegato.indexOf(
        item
      );
      this.dialog_edit_allegato = true;
    },

    //METODO PER SCARICARE IL FILE
    downloadAllegato(item) {
      this.message_new_allegato_waiting = item.des_allegato + " in download";
      this.dialog_new_allegato_waiting = true;
      // axios({
      //   url:
      //     this.$store.state.config.ip +
      //     "api/Allegato/DownloadDocument?idAllegato=" +
      //     item.id_allegato, //your url
      //   method: "GET",
      //   responseType: "blob" // important
      // }).then(response => {
      //   let blob = new Blob([response.data]),
      //     downloadUrl = window.URL.createObjectURL(blob);

      //   let a = document.createElement("a");

      //   a.href = downloadUrl;
      //   a.download = item.des_allegato.replace(" ", "_") + item.estensione;
      //   document.body.appendChild(a);
      //   a.click();
      //   this.dialog_new_allegato_waiting = false;
      // });

      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var finale = {
        action: "downloadfile",
        token: localStorage.user_token,
        idAllegato: item.id_allegato,
        db_name: "medical"
      };
      var data = JSON.stringify(finale);
      axios
        .post(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          console.log(response);
          const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];
            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };

          // const contentType = "application/pdf";
          const b64Data = response.data.file;

          const blob = b64toBlob(b64Data);
          const blobUrl = URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = blobUrl;
          a.download = item.des_allegato.replace(" ", "_") + item.estensione;
          document.body.appendChild(a);
          a.click();
          //window.open(blobUrl);
          this.dialog_new_allegato_waiting = false;
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            localStorage.clear();
            this.dialog_new_allegato_waiting = false;
          }
          this.dialog_new_allegato_waiting = false;
        });
    },

    //METODO PER CANCELLARE IL FILE
    openDeleteAllegato(item) {
      this.dialog_delete_file = true;
      this.editedAllegato = item;
    },

    deleteAllegatoItem(item) {
      this.dialog_edit_allegato = false;
      this.dialog_new_allegato_waiting = true;
      this.message_new_allegato_waiting =
        "Cancellazione in corso del file " + item.des_allegato;
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      var finale = {
        action: "deleteallegato",
        token: localStorage.user_token,
        id_allegato: item.id_allegato,
        db_name: "medical"
      };
      var data = JSON.stringify(finale);
      axios
        .put(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          console.log(response);
          this.dialog_new_allegato_waiting = false;
          this.message_new_allegato_waiting = "";
          this.dialog_delete_file = false;
          this.editedAllegato = {};
          this.allegato.splice(this.editedIndexAllegato, 1);
          this.selectListAllegato.splice(this.editedIndexSelectListAllegato, 1);
          this.dialog_new_allegato_waiting = false;
          this.message_new_allegato_waiting = "";
          this.snackbar = true;
          this.message_snackbar = "File cancellato correttamente";
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            this.snackbar = true;
            this.message_snackbar = "Errore: " + e.response.status;
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
          }
        });

      console.log("delete " + item.id);
    },

    openNuovoAllegato() {
      this.select = [];
      this.dialog_new_allegato = true;
      this.file = [];
    },
    //METODO PER SALVARE IL NUOVO ALLEGATO
    salvaNuovoAllegato() {
      if (this.select.length == 0 || this.files.length == 0) {
        this.snackbar = true;
        this.message_snackbar = "Compila tutti i dati.";
        return;
      }
      this.message_new_allegato_waiting = "Caricamento in corso";
      this.dialog_new_allegato_waiting = true;
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        formData.append("file" + i, file);
      }
      var finale = {
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        tipo_riga: "file",
        id_padre: this.editIdCartella,
        id_categoria: this.select["id_tabella"],
        data_validita: this.date,
        id_utente: localStorage.id
      };
      formData.append("json", JSON.stringify(finale));

      this.select == [];
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(this.$store.state.config.ip + "api/Allegato/putAllegato", formData, config)
        .then(response => {
          console.log("save");
          if (response.status == 200) {
            this.data = response.data;
            this.data.forEach(item => {
              this.allegato.push({
                id_allegato: item.id_allegato,
                des_allegato: item.des_allegato,
                id_padre: item.id_padre,
                num_revisione: item.num_revisione,
                data_validita: item.data_validita,
                estensione: item.estensione
              });
              this.selectListAllegato.push({
                id_allegato: item.id_allegato,
                des_allegato: item.des_allegato,
                id_padre: item.id_padre,
                num_revisione: item.num_revisione,
                data_validita: item.data_validita,
                estensione: item.estensione
              });
              console.log("found: ", item.id_allegato);
            });
            this.snackbar = true;
            this.message_snackbar = "File caricato";
          } else {
            this.snackbar = true;
            this.message_snackbar =
              "File non caricato.\nProblemi di connessione.";
          }
          this.dialog_new_allegato_waiting = false;
          this.message_new_allegato_waiting = "";
          this.files = [];
          this.dialog_new_allegato = false;
          this.blnViewAllegato = true;
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            this.snackbar = true;
            this.message_snackbar = "Errore: " + e.response.status;
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
            this.files = [];
          }
        });
    },

    //METODO PER VISUALIZZARE LA STORIA DELL'ALLEGATO
    openHistoryAllegato(item) {
      this.files = [];
      this.editedAllegato = Object.assign({}, item);
      this.editedIndexAllegato = this.allegato.indexOf(item);
      this.editedIndexSelectListAllegato = this.selectListAllegato.indexOf(
        item
      );
      this.dialog_history_allegato = true;
    },

    //METODO PER REVISIONARE L'ALLEGATO
    openRevisioneAllegato(item) {
      this.editedAllegato = Object.assign({}, item);
      this.editedIndexAllegato = this.allegato.indexOf(item);
      this.editedIndexSelectListAllegato = this.selectListAllegato.indexOf(
        item
      );
      this.dialog_revisione_allegato = true;
    },

    //METODO PER SALVARE LA NUOVA REVISIONE
    salvaRevisioneAllegato() {
      console.log(this.editedAllegato);
      this.message_new_allegato_waiting =
        "Caricamento nuova revisione in corso";
      this.dialog_new_allegato_waiting = true;
      let formData = new FormData();
      formData.append("file", this.files);
      var finale = {
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        tipo_riga: "file",
        id_padre: this.editIdCartella,
        des_azione: "Upload nuova revisione",
        famiglia: this.editedAllegato.famiglia,
        id_categoria: this.editedAllegato.id_categoria,
        num_revisione: this.editedAllegato.num_revisione
      };
      formData.append("json", JSON.stringify(finale));

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(
          this.$store.state.config.ip + "api/Allegato/putRevisioneAllegato",
          formData,
          config
        )
        .then(response => {
          console.log("save");
          this.data = response.data;
          this.data.forEach(item => {
            this.allegato.push({
              id_allegato: item.id_allegato,
              des_allegato: item.des_allegato,
              id_padre: item.id_padre,
              num_revisione: item.num_revisione,
              data_validita: item.data_validita,
              famiglia: item.famiglia,
              estensione: item.estensione
            });
            this.selectListAllegato.push({
              id_allegato: item.id_allegato,
              des_allegato: item.des_allegato,
              id_padre: item.id_padre,
              num_revisione: item.num_revisione,
              data_validita: item.data_validita,
              famiglia: item.famiglia,
              estensione: item.estensione
            });
            console.log("found: ", item.id_allegato);
            this.editedAllegato = {};
            this.allegato.splice(this.editedIndexAllegato, 1);
            this.selectListAllegato.splice(
              this.editedIndexSelectListAllegato,
              1
            );
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
            this.dialog_revisione_allegato = false;
          });
          this.files = [];
          this.dialog_new_allegato = false;
          this.blnViewAllegato = true;
          this.snackbar = true;
          this.message_snackbar = "File revisionato";
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            this.snackbar = true;
            this.message_snackbar = "Errore: " + e.response.status;
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
            this.files = [];
          }
        });
    },

    //METODO PER SOSTITUIRE L'ALLEGATO
    openSostituzioneAllegato(item) {
      this.files = [];
      this.editedAllegato = Object.assign({}, item);
      this.editedIndexAllegato = this.allegato.indexOf(item);
      this.editedIndexSelectListAllegato = this.selectListAllegato.indexOf(
        item
      );
      this.dialog_sostituzione_allegato = true;
    },

    //METODO PER SALVARE LA SOSTITUZIONE
    salvaSostituzioneAllegato() {
      this.message_new_allegato_waiting = "Caricamento in corso";
      this.dialog_new_allegato_waiting = true;
      let formData = new FormData();
      formData.append("file", this.files);
      var finale = {
        tipo: this.tipo,
        token: localStorage.user_token,
        id_rif: this.id_rif,
        tipo_riga: "file",
        id_padre: this.editIdCartella,
        des_azione: "Upload sostituzione",
        famiglia: this.editedAllegato.famiglia,
        id_categoria: this.editedAllegato.id_categoria,
        num_revisione: this.editedAllegato.num_revisione
      };
      formData.append("json", JSON.stringify(finale));

      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axios
        .post(
          this.$store.state.config.ip + "api/Allegato/putSostituzioneAllegato",
          formData,
          config
        )
        .then(response => {
          console.log("save");
          this.data = response.data;
          this.data.forEach(item => {
            this.allegato.push({
              id_allegato: item.id_allegato,
              des_allegato: item.des_allegato,
              id_padre: item.id_padre,
              num_revisione: item.num_revisione,
              data_validita: item.data_validita,
              famiglia: item.famiglia,
              estensione: item.estensione
            });
            this.selectListAllegato.push({
              id_allegato: item.id_allegato,
              des_allegato: item.des_allegato,
              id_padre: item.id_padre,
              num_revisione: item.num_revisione,
              data_validita: item.data_validita,
              famiglia: item.famiglia,
              estensione: item.estensione
            });
            console.log("found: ", item.id_allegato);
            this.editedAllegato = {};
            this.allegato.splice(this.editedIndexAllegato, 1);
            this.selectListAllegato.splice(
              this.editedIndexSelectListAllegato,
              1
            );
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
            this.dialog_sostituzione_allegato = false;
          });
          this.files = [];
          this.dialog_new_allegato = false;
          this.blnViewAllegato = true;
          this.snackbar = true;
          this.message_snackbar = "File sostituito";
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
            this.snackbar = true;
            this.message_snackbar = "Errore: " + e.response.status;
            this.dialog_new_allegato_waiting = false;
            this.message_new_allegato_waiting = "";
            this.files = [];
          }
        });
    },

    //METODO PER SALVARE LE MODIFICHE DELLA SINGOLA RIGA DI ALLEGATO
    saveEditAllegato() {
      if (
        this.editedAllegato["des_categoria"].length == 0 ||
        this.date == null ||
        this.editedAllegato.des_allegato == null
      ) {
        this.snackbar = true;
        this.message_snackbar = "Compila tutti i dati.";
        return;
      }
      this.dialog_edit_allegato = false;
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      this.editedAllegato.data_validita = this.date;

      var nuovaCartella = {
        tipo: this.tipo,
        id_rif: this.id_rif,
        id_categoria: this.editedAllegato["des_categoria"]["id_tabella"],
        id_allegato: this.editedAllegato.id_allegato,
        des_allegato: this.editedAllegato.des_allegato,
        data_validita: this.editedAllegato.data_validita
      };

      var object = {
        action: "setallegato",
        tipo_riga: 2,
        id_utente: localStorage.id,
        tabella: nuovaCartella,
        token: localStorage.user_token,
        db_name: "medical"
      };

      var data = JSON.stringify(object);
      axios
        .post(this.$store.state.config.ip + "api/Allegato", data, config)
        .then(response => {
          console.log(response);
          Object.assign(
            this.allegato[this.editedIndexAllegato],
            this.editedAllegato
          );
          Object.assign(
            this.selectListAllegato[this.editedIndexSelectListAllegato],
            this.editedItem
          );
        })
        .catch(e => {
          if (e.response.status == 401) {
            console.log(e);
          }
        });
    },

    //METODO GET NODO SPECIFICO
    findItem(id, items = null) {
      if (!items) {
        items = this.items;
      }

      return items.reduce((acc, item) => {
        if (acc) {
          return acc;
        }

        if (item.id === id) {
          return item;
        }

        if (item.children) {
          return this.findItem(id, item.children);
        }

        return acc;
      }, null);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
    /*
        Handles a change on the file upload
      */
  }
};
</script>