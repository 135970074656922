<template>
  <v-card class="mt-5 mx-5 my-0 pa-0" rounded="0">
    <!-- Spazio della toolbar -->
    <v-toolbar color="indigo" dense>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="dialogModFlowchart = true"
            v-bind="attrs"
            v-on="on"
            small
          >
            <v-icon color="primary">mdi-file-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Modifica Questionario</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-chip class="my-auto" color="white" text-color="black">
        Titolo:
        <b class="ml-2">{{ questionario[0].titolo_questionario }}</b>
      </v-chip>

      <v-spacer></v-spacer>

      <v-chip class="my-auto" color="white" text-color="black">
        Tipologia:
        <b class="ml-2">{{ questionario[0].des_tipo_questionario }}</b>
      </v-chip>

      <v-spacer></v-spacer>

      <v-chip class="my-auto" color="white" text-color="black">
        Data di scadenza del test:
        <b class="ml-2">
          {{
            questionario[0].data_questionario.toString().substring(8, 10) +
            "/" +
            questionario[0].data_questionario.toString().substring(5, 7) +
            "/" +
            questionario[0].data_questionario.toString().substring(0, 4)
          }}
        </b>
      </v-chip>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="esci" small v-bind="attrs" v-on="on">
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Torna all'Elenco</span>
      </v-tooltip>
    </v-toolbar>

    <!-- Spazio del flowchart-->
    <flowchart
      ref="flowchart"
      @aggiungi_nodo="aggiungiNodo"
      :allow_aggiungi_linea="false"
      :allow_aggiungi_immagine="false"
      :connessioni="questionario[0].connections"
      @copiaNodo="copiaNodo"
      @editconnection="handleEditConnection"
      @editnode="handleEditNode"
      @eliminaFreccia="handleRimuovi"
      @eliminaNodo="handleRimuovi"
      :height="height_flowchart"
      :key="key_questionario"
      @movimentoNodo="handleEditNode"
      :nodes_object="{
        linee: [],
        nodi_immagine: [],
        rettangoli_smussati: questionario[0].nodes,
      }"
      @save="handleChartSave"
      :var_id_nodo="'id_questionario_domanda'"
      :var_id_nodo_end="'id_questionario_domanda_successiva'"
      :var_id_nodo_start="'id_questionario_domanda'"
      :var_id_connessione="'id_questionario_connessione'"
      :var_maniglia_end="'position_destination'"
      :var_maniglia_start="'position_source'"
      :width="width_flowchart"
    >
    </flowchart>

    <!-- Dialog di modifica dei nodi -->
    <v-dialog v-model="node_dialog" width="75%" persistent>
      <node-dialog
        :nodo_sel="nodo_selezionato"
        :arrivo="arrivo_funzione"
        :key="contatore_key"
        @annulla="node_dialog = false"
        @salva="salvaNode"
      ></node-dialog>
    </v-dialog>

    <!-- Dialog di modifica delle connessioni -->
    <v-dialog v-model="connection_dialog" width="500" persistent>
      <connection-dialog
        :connessione_sel="connessione_selezionata"
        :nodes="questionario[0].nodes"
        :arrivo="arrivo_funzione"
        :key="contatore_key"
        @annulla="connection_dialog = false"
        @salva="salvaConnection"
      ></connection-dialog>
    </v-dialog>

    <!-- Dialog di modifica del questionario -->
    <v-dialog class="ma-0 pa-0" v-model="dialogModFlowchart" width="75%">
      <v-card class="ma-0 text-center pa-0">
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Modifica i dati del flowchart
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="modificaFlowchart" small v-bind="attrs" v-on="on">
                <v-icon color="success">mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="annullaModificaFlowchart"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-form class="mx-8" lazy-validation ref="form">
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  class="mb-5"
                  :counter="50"
                  label="Titolo del questionario"
                  :placeholder="questionario[0].titolo_questionario"
                  required
                  v-model="modTitoloQuestionario"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  class="mb-5"
                  label="Data di scadenza"
                  required
                  type="date"
                  v-model="modDataQuestionario"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <!--<v-text-field
                  label="Tipo di questionario"
                  :placeholder="questionario[0].id_tipo_questionario.toString()"
                  required
                  type="number"
                  v-model="modIdTipoQuestionario"
              ></v-text-field>-->
                <v-select
                  :items="tipi_questionario"
                  v-model="modIdTipoQuestionario"
                  item-text="des"
                  item-value="id_tabella"
                  label="Tipo di questionario"
                />
              </v-col>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="nuovoTipoQuestDialogVisible = true"
                      class="mt-5"
                      color="indigo"
                      dark
                      fab
                      v-bind="attrs"
                      v-on="on"
                      x-small
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Nuovo tipo</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row>
              <v-col cols="6" style="padding-left: 30px; padding-right: 30px">
                <v-text-field
                  :counter="50"
                  label="Testo finale"
                  :placeholder="questionario[0].testo_finale"
                  required
                  v-model="modTestoFinale"
                ></v-text-field>
              </v-col>
              <v-col cols="6" style="padding-left: 30px; padding-right: 30px">
                <v-text-field
                  :counter="30"
                  id="inputModificaLinkFinale"
                  label="Link finale"
                  required
                  v-model="modLinkFinale"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <div>
                  <v-textarea
                    class="mt-10 mx-auto"
                    id="testoDescrizioneQuestionario"
                    label="Descrizione questionario"
                    outlined
                    :placeholder="questionario[0].des_questionario"
                    v-model="modDesQuestionario"
                    style="display: inline-block; width: 500px"
                  ></v-textarea>
                </div>
              </v-col>
              <v-col cols="6">
                <!-- Qui sotto c'è il form per caricare le
                   immagini che l'utente vuole usare come logo.
              -->
                <div style="max-width: 510px; min-width: 235px">
                  <v-file-input
                    accept="image/*"
                    @change="renderizzaLogo()"
                    class="mb-n3 pb-0 pr-2"
                    id="caricaImmagini"
                    label="Carica un'immagine (max 500 × 500 px)"
                    style="max-width: 500px; min-width: 325px"
                    v-model="img_logoNuovo"
                  ></v-file-input>
                </div>
                <div
                  display="inline-block"
                  height="150"
                  id="chartLogo"
                  style="
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 5px;
                    max-height: 150px;
                    max-width: 150px;
                    min-height: 150px;
                    min-width: 150px;
                  "
                  width="150"
                >
                  <svg
                    id="svgImmagine"
                    height="150"
                    style="border: 1px solid black"
                    width="150"
                  >
                    <!--<rect class="selection" height="0" width="0" /> -->
                  </svg>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <div>
                  <!--<v-checkbox
                  class="ml-auto mr-auto"
                  label="Abilita l'utente a revisionare il questionario"
                  v-model="modAbilita_indietro"
                ></v-checkbox>-->
                  <v-checkbox
                    class="ml-auto mr-auto"
                    label="Rendi visibili le domande del questionario"
                    v-model="modAbilita_lista_domande"
                  ></v-checkbox>
                </div>
              </v-col>
              <!--<v-col cols="6">
              <div style="display:inline-block;text-align:center;margin-right:10px;">
                <label
                  for="tempoMin"
                  style="display:inline-block;margin-right:5px;"
                >Tempo minimo (min):</label>
                <v-text-field
                  id="tempoMin"
                  single-line
                  style="display:inline-block;margin-left:5px;width:50px;"
                  type="number"
                  v-model="tempo_minInModifica"
                />
              </div>
              <div style="display:inline-block;text-align:center;">
                <label
                  for="tempoMax"
                  style="display:inline-block;margin-right:5px;"
                >Tempo massimo (min):</label>
                <v-text-field
                  id="tempoMax"
                  single-line
                  style="display:inline-block;margin-left:5px;width:50px;"
                  type="number"
                  v-model="tempo_maxInModifica"
                />
              </div>
            </v-col>-->
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog di reload opzionale del questionario -->
    <v-dialog v-model="ricaricaDialog" width="500">
      <v-card class="text-center">
        <v-card-title style="background-color: #546e7a; color: #ffffff"
          >Conferma</v-card-title
        >
        <v-card-text class="pt-5"
          >C'è un questionario salvato in memoria, caricarlo?</v-card-text
        >
        <v-divider></v-divider>
        <div style="padding-right: 10px; text-align: right">
          <v-btn
            class="mr-2 my-2"
            color="error"
            @click="ricaricaDialog = false"
            fab
            small
            outlined
          >
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
          <v-btn
            class="my-2"
            color="success"
            @click="caricaQuestionario"
            fab
            small
            outlined
          >
            <v-icon>mdi-check-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog per il nuovo tipo di questionario -->
    <v-dialog persistent v-model="nuovoTipoQuestDialogVisible" width="75%">
      <v-card class="text-center">
        <v-card-title style="background-color: #546e7a; color: #ffffff">
          <div>Nuovo tipo di questionario</div>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 my-2"
            color="error"
            @click="nuovoTipoQuestDialogVisible = !nuovoTipoQuestDialogVisible"
            fab
            outlined
            small
            style="background-color: #ffffff"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <form class="px-10">
          <v-row>
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Codice</th>
                      <th class="text-left">Descrizione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tipo in tipi_questionario"
                      :key="tipo.id_tabella"
                    >
                      <td style="text-align: left">{{ tipo.cod }}</td>
                      <td style="text-align: left">{{ tipo.des }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" class="pa-5">
              <v-row>
                <v-text-field
                  id="nuovoCod"
                  label="Nuovo codice"
                  required
                  type="text"
                  v-model="nuovo_cod"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  id="nuovaDes"
                  label="Nuova descrizione"
                  required
                  type="text"
                  v-model="nuova_des"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-btn
                  class="mr-2 my-2"
                  color="#546E7A"
                  @click="addTipoQuestionario(nuovo_cod, nuova_des)"
                  dark
                  small
                  >Aggiungi</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- Overlay di caricamento -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import Base64 from "b64-to-blob";
import ConnectionDialog from "../../components/CHECK2/ConnectionDialog";
import Flowchart from "../../components/CHECK2/flowchart/Flowchart";
import moment from "moment/moment";
import NodeDialog from "../../components/CHECK2/NodeDialog";
import QCQuestion from "@/classes/QCQuestion.js";
import QCConnection from "@/classes/QCConnection.js";
//import _ from "lodash";

export default {
  components: {
    ConnectionDialog,
    Flowchart,
    NodeDialog,
  },
  data: function () {
    return {
      // Variabili di supporto
      connection_dialog: false,
      connectionDialogVisible: false,
      connectionForm: { target: null, operation: null },
      connessione_selezionata: {},
      contatore_key: 0,
      fileCaricato: "",
      height_flowchart: "500px",
      img_logoNuovo: null,
      indice_questionario_backup: 0,
      key_questionario: 0,
      modAbilita_lista_domande: false,
      modTitoloQuestionario: "",
      modDesQuestionario: "",
      modDataQuestionario: "",
      modIdTipoQuestionario: 0,
      modificheNonSalvate: false,
      modLinkFinale: "",
      modTestoFinale: "",
      nodeForm: { target: null },
      node_sel: {},
      nodo_selezionato: new QCQuestion(),
      nuovo_cod: "",
      nuova_des: "",
      tipi_questionario: [],
      width_flowchart: "100%",

      // Modal
      arrivo_funzione: "",
      dialogModFlowchart: false,
      node_dialog: false,
      nodeDialogVisible: false,
      nuovoTipoQuestDialogVisible: false,
      overlay: false,
      redo_visibile: false,
      ricaricaDialog: false,
      salvataggio: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      questionario: [
        {
          id_questionario: null,
          id_stato_questionario: null,
          id_tipo_questionario: "",
          titolo_questionario: "",
          des_questionario: "",
          data_questionario: "",
          img_logo: "",
          tempo_max: null,
          tempo_min: null,
          testo_finale: "",
          link_finale: "",
          abilita_indietro: false,
          abilita_lista_domande: false,
          nodes: [],
          connections: [],
          aree_delete: [],
          frecce_delete: [],
          connections_delete: [],
          nodes_delete: [],
          risposte_delete: [],
        },
      ],
    };
  },
  watch: {
    modificheNonSalvate(val) {
      if (val == true) {
        this.$store.state.check_paginaAttuale = 1;
      }
    },
    indice_questionario_backup(val) {
      this.redo_visibile = false;
      var questionari = JSON.parse(localStorage.questionario_back);
      if (val > 0 || questionari.length > 1) this.redo_visibile = true;
      if (questionari.length <= val + 1) this.redo_visibile = false;
    },
  },
  mounted() {},
  methods: {
    checkDimensioniCanvas(arg_nodo) {
      let wrapper_svg = document.getElementById("wrapper_svg");

      /* Controllo se devo ridimensionare i canvas, prendendo come riferimento le dimensioni del
       * wrapper dell'SVG. Nel caso debba ridimensionare, ridimensiono sulle variabili width e height
       * in modo da ridimensionare in un colpo solo il canvas SVG, HTML e il div contenitore generale.*/
      if (wrapper_svg.offsetWidth < arg_nodo.x + arg_nodo.width) {
        console.log("allargato a " + arg_nodo.x + arg_nodo.width + 50 + "px");
        this.width_flowchart = arg_nodo.x + arg_nodo.width + 50 + "px";
      }

      if (wrapper_svg.offsetHeight < arg_nodo.y + arg_nodo.height) {
        this.height_flowchart =
          parseInt(arg_nodo.y) + parseInt(arg_nodo.height) + 50 + "px";
      }
    },
    copiaNodo(arg_id, arg_x, arg_y) {
      this.questionario[0].nodes.forEach((n) => {
        // Quando trovo il nodo da copiare...
        if (n.id_questionario_domanda == arg_id) {
          // Copio il nodo e gli riassegno gli identificativi
          let id_nuovo_nodo = moment().valueOf();
          let qcq = new QCQuestion(n);
          qcq.id_questionario_domanda = id_nuovo_nodo;
          qcq.num_questionario_domanda = id_nuovo_nodo;

          // Riassegno i parametri delle sue frecce
          for (let i = 0; i < qcq.frecce.length; i++) {
            let id_nuova_freccia = moment().valueOf() + 5 + i;
            qcq.frecce[i].id_questionario_domanda = id_nuovo_nodo;
            qcq.frecce[i].id_questionario_freccia = id_nuova_freccia;
            qcq.frecce[i].num_questionario_freccia = id_nuova_freccia;
            qcq.frecce[i].assegnata = 0;
          }

          // Riassegno i parametri delle sue risposte
          for (let i = 0; i < qcq.risposte.length; i++) {
            let id_nuova_risposta = moment().valueOf() + 5 + i;
            qcq.risposte[i].id_questionario_domanda = id_nuovo_nodo;
            qcq.risposte[i].num_questionario_domanda = id_nuovo_nodo;
            qcq.risposte[i].id_questionario_risposta = id_nuova_risposta;
            qcq.risposte[i].num_questionario_risposta = id_nuova_risposta;
          }

          // Riassegno l'ascissa e l'ordinata del nodo.
          qcq.x = arg_x;
          qcq.y = arg_y;

          // Definisco testo e titolo del nodo per il flowchart
          //qcq.setTitoloNodo();
          //qcq.setTestoNodo();

          // Aggiungo il nuovo nodo ai nodi.
          this.questionario[0].nodes.push(qcq);

          return;
        }
      });

      // Aggiorno le prop e segnalo che sono state fatte modifiche.
      this.key_questionario++;
      this.modificheNonSalvate = true;
    },
    aggiungiNodo(elem) {
      this.nodo_selezionato = new QCQuestion();
      this.nodo_selezionato.x = elem.x;
      this.nodo_selezionato.y = elem.y;

      //this.nodo_selezionato.setTitoloNodo();
      //this.nodo_selezionato.setTestoNodo();

      //apro modal di inserimento
      this.arrivo_funzione = "nuovo";
      this.contatore_key++;
      this.node_dialog = true;
    },
    addTipoQuestionario(codice, descrizione) {
      let nuovo_tipo = {
        tipo: "QUESTIONARIO_TIPO",
        id_tabella: -1,
        cod: codice,
        des: descrizione,
        validita: 1,
      };

      this.tipi_questionario.push(nuovo_tipo);
      this.nuovo_cod = "";
      this.nuova_des = "";
      this.snackbar = true;
      this.snackbar_background = "success";
      this.snackbar_icon = "mdi-check";
      this.snackbar_icon_color = "white";
      this.snackbar_text = "Tipologia aggiunta con successo!";
      this.snackbar_text_color = "white";

      var request = {
        controller: "Questionario",
        method: "PUT",
        richiesta: {
          action: "settabelle",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          tipo: "QUESTIONARIO_TIPO",
          db_name: "quickcheck",
          tabella: [nuovo_tipo],
        },
      };
      this.$store.dispatch("api", request).then(() => {
        this.getTipiQuestionario();
      });
    },
    annullaModificaFlowchart() {
      // Riporto i campi allo stato precedente l'apertura della finestra
      this.modTitoloQuestionario = this.questionario[0].titolo_questionario;
      this.modDesQuestionario = this.questionario[0].des_questionario;
      this.modDataQuestionario = this.questionario[0].data_questionario;

      this.modIdTipoQuestionario = this.questionario[0].id_tipo_questionario.toString();
      this.modTestoFinale = this.questionario[0].testo_finale;
      this.modLinkFinale = this.questionario[0].link_finale;
      this.modAbilita_lista_domande = this.questionario[0].abilita_lista_domande;
      //this.img_logoNuovo = this.questionario[0].img_logo;
      // Ricarico l'immagine del logo nel caso l'utente l'avesse cambiata
      document.getElementById("caricaImmagini").value = null;
      if (
        this.questionario[0].img_logo != null &&
        this.questionario[0].img_logo != ""
      ) {
        let that = this;
        var checkExist = setInterval(function () {
          if (document.getElementById("chartLogo")) {
            clearInterval(checkExist);
            var base = that.questionario[0].img_logo.split(",");
            var blob = Base64(base[1], "image/png");
            that.img_logoNuovo = blob;
            document.getElementById("chartLogo").style.backgroundImage =
              "url(" + URL.createObjectURL(that.img_logoNuovo) + ")";
          }
        }, 100);
      } else {
        this.img_logoNuovo = null;
        document.getElementById("chartLogo").style.backgroundImage = "url('')";
      }

      // Chiudo la finestra
      this.dialogModFlowchart = false;
    },
    avantiModifica() {
      var quest = JSON.parse(localStorage.questionario_back);
      this.questionario = quest[this.indice_questionario_backup + 1];
      this.indice_questionario_backup++;
      this.key_questionario++;
    },
    carica() {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "getdiagramma",
          token: localStorage.getItem("user_token"),
          db_name: "quickcheck",
          id_questionario: this.$route.params.id,
          id_utente: localStorage.getItem("id"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.questionario = [];
        /* Il BE ogni tanto mi restituisce array con oggetti di comodo:
         * tolgo questi oggetti dagli array perché sarebbero critici per me */
        if (res.data[0].nodes[0].id == -1) {
          res.data[0].nodes = [];
        } else {
          try {
            res.data[0].nodes.forEach((nodo) => {
              if (nodo.parametri[0].id == -1) {
                nodo.parametri = [];
              }

              /*for (let i = 0; i < nodo.risposte.length; i++) {
              if (nodo.risposte[i].parametri.length > 0 && nodo.risposte[i].parametri[0].id == -1) {
                nodo.risposte[i].parametri = [];
              }
            }*/
              nodo.risposte.forEach((risposta) => {
                if (risposta.id != -1) {
                  if (risposta.parametri[0].id == -1) {
                    risposta.parametri = [];
                  }
                } else {
                  risposta = [];
                }
              });
            });
          } catch (e) {
            console.log("errore: ", e);
          }
        }
        if (res.data[0].connections[0].id == -1) {
          res.data[0].connections = [];
        }

        /*res.data[0].connections.forEach(connessione => {
          if (connessione.parametri[0].id == -1) {
            connessione.parametri = [];
          }
        });*/

        res.data[0].nodes.forEach((nodo) => {
          if (nodo.aree[0].id == -1) {
            nodo.aree = [];
          }
        });

        /* Il backend mi restituisce 0 / 1 al posto dei booleani
         * true / false; faccio in modo di avere i booleani */
        if (res.data[0].abilita_lista_domande == 1) {
          res.data[0].abilita_lista_domande = true;
        } else {
          res.data[0].abilita_lista_domande = false;
        }

        res.data[0].nodes.forEach((nodo) => {
          if (nodo.mostra_contorno_aree === 0) {
            nodo.mostra_contorno_aree = false;
          } else if (nodo.mostra_contorno_aree === 1) {
            nodo.mostra_contorno_aree = true;
          }

          if (nodo.accetta_allegati === 0) {
            nodo.accetta_allegati = false;
          } else if (nodo.accetta_allegati === 1) {
            nodo.accetta_allegati = true;
          }
        });

        // Introduco i dati della response per valorizzare il questionario
        this.questionario.push(res.data[0]);

        /* Aggiungo i cestini per avere un oggetto completo
         * (il BE non me li restituisce da solo)*/
        this.questionario[0].aree_delete = [];
        this.questionario[0].connections_delete = [];
        this.questionario[0].frecce_delete = [];
        this.questionario[0].nodes_delete = [];
        this.questionario[0].risposte_delete = [];

        // Reimposto la data_questionario per convenienza di formato
        let data_questionario_tmp =
          this.questionario[0].data_questionario.toString().substring(6, 10) +
          "-" +
          this.questionario[0].data_questionario.toString().substring(3, 5) +
          "-" +
          this.questionario[0].data_questionario.toString().substring(0, 2);

        this.questionario[0].data_questionario = data_questionario_tmp;

        // Uso la data_questionario per formattare la modDataQuestionario come serve
        this.modDataQuestionario = this.questionario[0].data_questionario;
        this.modTitoloQuestionario = this.questionario[0].titolo_questionario;
        this.modIdTipoQuestionario = this.questionario[0].id_tipo_questionario;
        this.modDesQuestionario = this.questionario[0].des_questionario;
        this.modTestoFinale = this.questionario[0].testo_finale;
        this.modLinkFinale = this.questionario[0].link_finale;
        this.modAbilita_lista_domande = this.questionario[0].abilita_lista_domande;

        // Carico l'immagine del logo.
        if (
          this.questionario[0].img_logo != null &&
          this.questionario[0].img_logo != ""
        ) {
          let that = this;
          var checkExist = setInterval(function () {
            if (document.getElementById("chartLogo")) {
              clearInterval(checkExist);
              var base = that.questionario[0].img_logo.split(",");
              var blob = Base64(base[1], "image/png");
              that.img_logoNuovo = blob;
              document.getElementById("chartLogo").style.backgroundImage =
                "url(" + URL.createObjectURL(that.img_logoNuovo) + ")";
            }
          }, 100);
        }
        /* Azzero modificheNonSalvate per sapere se chiedere
         * o meno di salvare quanto l'utente esce. */
        this.modificheNonSalvate = false;

        // Interrompo l'overlay di caricamento
        this.overlay = false;
      });
    },
    caricaQuestionario() {
      this.questionario = JSON.parse(localStorage.questionario);
      this.key_questionario++;
      this.ricaricaDialog = false;
    },
    checkUsciteUsate(identificativo) {
      let usciteUsate = 0;
      this.questionario[0].connections.forEach((elem) => {
        if (elem.id_questionario_domanda === identificativo) {
          usciteUsate = usciteUsate + 1;
        }
      });
      return usciteUsate;
    },
    createNodo() {
      let id = +new Date();
      let id_per_freccia = +new Date() + 5;
      let nuovoNodo = {
        caricamento_immagini: 0,
        accetta_allegati: 0,
        aree: [],
        frecce: [
          {
            id_questionario: this.questionario[0].id_questionario,
            id_questionario_freccia: id_per_freccia,
            num_questionario_freccia: id_per_freccia,
            id_questionario_domanda: id,
            assegnata: 0,
            punteggio: 100,
          },
        ],
        des_questionario_domanda: "",
        caratteri_min: 0,
        caratteri_max: 255,
        entry_point: 1,
        img_caricata: "",
        id_questionario: this.questionario[0].id_questionario,
        id_questionario_domanda: id,
        id_tipo_esportazione: 0,
        id_tipo_modifica_immagine: 0,
        mostra_contorno_aree: 0,
        num_questionario_domanda: id,
        risposte: [],
        x: 100,
        y: 10,
        width: 120,
        height: 60,
        tipologia: "domanda",
        tipo: null,
        link: "",
        codice: "",
        parametri: [],
      };

      //   this.$refs.chart.add(nuovoNodo);
      //   this.questionario[0].nodes.push(nuovoNodo);
      //   this.$refs.chart.editNode(nuovoNodo);

      this.nodo_selezionato = nuovoNodo;
      //apro modal di inserimento
      this.arrivo_funzione = "nuovo";
      this.contatore_key++;
      this.node_dialog = true;
    },
    delFigliNoPadri(connectionsDiValidazione) {
      /* Dall'array di validazione ricavo due array che collezionano origini
       * e destinazioni delle connessioni*/
      let originiConDoppioni = [];
      let destinazioniConDoppioni = [];
      connectionsDiValidazione.forEach((elem) => {
        originiConDoppioni.push(elem.id_questionario_domanda);
      });

      connectionsDiValidazione.forEach((elem) => {
        destinazioniConDoppioni.push(elem.id_questionario_domanda_successiva);
      });

      // Faccio un nuovo array di origini che non contiene dati ripetuti
      let originiSenzaDoppioni = [];
      for (let i = 0; i < originiConDoppioni.length; i++) {
        let ricorre = false;
        for (let j = 0; j < originiSenzaDoppioni.length; j++) {
          if (originiConDoppioni[i] == originiSenzaDoppioni[j]) {
            ricorre = true;
          }
        }
        if (ricorre == false) {
          originiSenzaDoppioni.push(originiConDoppioni[i]);
        }
      }

      // Faccio un nuovo array di destinazioni che non contiene dati ripetuti
      let destinazioniSenzaDoppioni = [];
      for (let i = 0; i < destinazioniConDoppioni.length; i++) {
        let ricorre = false;
        for (let j = 0; j < destinazioniSenzaDoppioni.length; j++) {
          if (destinazioniConDoppioni[i] == destinazioniSenzaDoppioni[j]) {
            ricorre = true;
          }
        }
        if (ricorre == false) {
          destinazioniSenzaDoppioni.push(destinazioniConDoppioni[i]);
        }
      }

      /* Verifico che ogni id nelle destinazioni compaia nelle origini
       * e se non compare cancello le frecce che hanno il nodo come destinazione*/

      for (let i = 0; i < destinazioniSenzaDoppioni.length; i++) {
        let idRicorrenteComeOrigine = false;
        for (let j = 0; j < originiSenzaDoppioni.length; j++) {
          if (destinazioniSenzaDoppioni[i] == originiSenzaDoppioni[j]) {
            idRicorrenteComeOrigine = true;
          }
        }
        if (idRicorrenteComeOrigine == false) {
          let connectionsDiValidazioneAggiornate = connectionsDiValidazione.filter(
            (elem) =>
              elem.id_questionario_domanda_successiva !==
              destinazioniSenzaDoppioni[i]
          );
          connectionsDiValidazione = connectionsDiValidazioneAggiornate;
        }
      }
      return connectionsDiValidazione;
    },
    delPadriNoFigli(connectionsDiValidazione) {
      /* Dall'array di validazione ricavo due array che collezionano origini
       * e destinazioni delle connessioni*/
      let originiConDoppioni = [];
      let destinazioniConDoppioni = [];
      connectionsDiValidazione.forEach((elem) => {
        originiConDoppioni.push(elem.id_questionario_domanda);
      });

      connectionsDiValidazione.forEach((elem) => {
        destinazioniConDoppioni.push(elem.id_questionario_domanda_successiva);
      });

      // Faccio un nuovo array di origini che non contiene dati ripetuti
      let originiSenzaDoppioni = [];
      for (let i = 0; i < originiConDoppioni.length; i++) {
        let ricorre = false;
        for (let j = 0; j < originiSenzaDoppioni.length; j++) {
          if (originiConDoppioni[i] == originiSenzaDoppioni[j]) {
            ricorre = true;
          }
        }
        if (ricorre == false) {
          originiSenzaDoppioni.push(originiConDoppioni[i]);
        }
      }

      // Faccio un nuovo array di destinazioni che non contiene dati ripetuti
      let destinazioniSenzaDoppioni = [];
      for (let i = 0; i < destinazioniConDoppioni.length; i++) {
        let ricorre = false;
        for (let j = 0; j < destinazioniSenzaDoppioni.length; j++) {
          if (destinazioniConDoppioni[i] == destinazioniSenzaDoppioni[j]) {
            ricorre = true;
          }
        }
        if (ricorre == false) {
          destinazioniSenzaDoppioni.push(destinazioniConDoppioni[i]);
        }
      }

      /* Verifico che ogni id nelle origini compaia nelle destinazioni
       * e se non compare cancello le frecce che hanno il nodo come origine*/

      for (let i = 0; i < originiSenzaDoppioni.length; i++) {
        let idRicorrenteComeOrigine = false;
        for (let j = 0; j < destinazioniSenzaDoppioni.length; j++) {
          if (originiSenzaDoppioni[i] == destinazioniSenzaDoppioni[j]) {
            idRicorrenteComeOrigine = true;
          }
        }
        if (idRicorrenteComeOrigine == false) {
          let connectionsDiValidazioneAggiornate = connectionsDiValidazione.filter(
            (elem) => elem.id_questionario_domanda !== originiSenzaDoppioni[i]
          );
          connectionsDiValidazione = connectionsDiValidazioneAggiornate;
        }
      }
      return connectionsDiValidazione;
    },
    esci() {
      this.$router.push("/home");
    },
    getTipiQuestionario() {
      let request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          id_utente: "365",
          tipo: "questionario_tipo",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.tipi_questionario = [];
        res.data.forEach((elem) => {
          this.tipi_questionario.push(elem);
        });
      });
    },
    async handleChartSave(tipo, oggetto) {
      if (tipo == "posizione") {
        this.questionario[0].nodes.forEach((f) => {
          if (f.id_questionario_domanda == oggetto.id_questionario_domanda) {
            this.questionario[0].nodes[
              this.questionario[0].nodes.indexOf(f)
            ].x = oggetto.x;
            this.questionario[0].nodes[
              this.questionario[0].nodes.indexOf(f)
            ].y = oggetto.y;
          }
        });
      }
      if (tipo == "nuovaConnessione") {
        // Verifico che il nodo abbia ancora frecce disponibili
        let check_f_libera = false;

        this.questionario[0].nodes.forEach((n) => {
          if (n.id_questionario_domanda == oggetto.id_nodo_start) {
            n.frecce.forEach((f) => {
              if (f.assegnata == 0) {
                check_f_libera = true;
              }
            });
          }
        });

        if (check_f_libera === false) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Questo nodo non ha più connessioni disponibili.";
          this.snackbar_text_color = "white";
          return;
        }

        // Se il nodo ha ancora frecce disponibili, creo la connessione.
        this.arrivo_funzione = "nuovo";
        this.handleEditConnection(oggetto);
      }
      //this.salvaQuestionariLocal();
    },
    handleEditConnection(connection) {
      // Se è un nuovo inserimento...
      if (this.arrivo_funzione === "nuovo") {
        // Genero una nuova connessione
        this.connessione_selezionata = new QCConnection();

        // Valorizzo i parametri della connessione di QC.
        this.connessione_selezionata.id_questionario = this.questionario[0].id_questionario;
        this.connessione_selezionata.id_questionario_connessione =
          connection.id_connessione;
        this.connessione_selezionata.id_questionario_domanda =
          connection.id_nodo_start;
        this.connessione_selezionata.num_questionario_domanda =
          connection.id_nodo_start;
        this.connessione_selezionata.id_questionario_domanda_successiva =
          connection.id_nodo_end;
        this.connessione_selezionata.num_questionario_domanda_successiva =
          connection.id_nodo_end;
        this.connessione_selezionata.id_questionario_freccia = -1;
        this.connessione_selezionata.id_utente = this.questionario[0].id_utente;
        this.connessione_selezionata.tipologia = "connessione";
        this.connessione_selezionata.tipo = null;
        this.connessione_selezionata.link = "";
        this.connessione_selezionata.codice = "";
        this.connessione_selezionata.parametri = [];
        this.connessione_selezionata.position_destination =
          connection.maniglia_end;
        this.connessione_selezionata.position_source =
          connection.maniglia_start;
        this.connessione_selezionata.type = null;
        this.connessione_selezionata.validita = 1;
        this.connessione_selezionata.punteggio = null;

        /* Eseguo le operazioni di logica per
         * far progredire il flusso operativo. */
        this.contatore_key++;
        this.modificheNonSalvate = true;
        this.connection_dialog = true;
      } else {
        // Se invece la connessione esisteva e la voglio modificare...
        this.questionario[0].connections.forEach((c) => {
          if (
            c.id_questionario_connessione ===
            connection.id_questionario_connessione
          ) {
            this.connessione_selezionata = new QCConnection(c);

            /* Eseguo le operazioni di logica per
             * far progredire il flusso operativo. */
            this.contatore_key++;
            this.modificheNonSalvate = true;
            this.connection_dialog = true;
          }
        });
      }
    },
    handleEditNode(tipo, id_nodo_sel, arg_x = undefined, arg_y = undefined) {
      if (tipo === "modificaDati") {
        // Se voglio modificare i dati di un nodo, apro il suo dialog
        this.questionario[0].nodes.forEach((nodo) => {
          if (nodo.id_questionario_domanda === id_nodo_sel) {
            this.nodo_selezionato = new QCQuestion(nodo);
          }
        });

        this.contatore_key++;
        this.modificheNonSalvate = true;
        this.arrivo_funzione = "modifica";
        this.node_dialog = true;
      } else if (tipo === "spostamento") {
        // Se invece ne devo aggiornare la posizione, la aggiorno
        this.questionario[0].nodes.forEach((nodo) => {
          if (nodo.id_questionario_domanda === id_nodo_sel) {
            nodo.x = arg_x;
            nodo.y = arg_y;

            // Faccio un controllo sulle dimensioni del canvas.
            this.checkDimensioniCanvas(nodo);
          }
        });

        this.key_questionario++;
        this.modificheNonSalvate = true;
        this.arrivo_funzione = "modifica";
      }
    },
    handleRimuovi(tipo, id) {
      if (tipo == "nodi") {
        // Mi segno una variabile per l'indice del nodo
        let index;

        for (let i = 0; i < this.questionario[0].nodes.length; i++) {
          if (this.questionario[0].nodes[i].id_questionario_domanda == id) {
            // Registro l'indice del nodo
            index = i;

            // Metto l'id del nodo nel cestino debito.
            this.questionario[0].nodes_delete.push({
              id: this.questionario[0].nodes[i].id_questionario_domanda,
            });
            // Metto le aree del nodo nel cestino debito.
            this.questionario[0].nodes[i].aree.forEach((area) => {
              this.questionario[0].aree_delete.push({
                id: area.id_questionario_area,
              });
            });

            // Metto le frecce del nodo nel cestino debito.
            this.questionario[0].nodes[i].frecce.forEach((freccia) => {
              this.questionario[0].frecce_delete.push({
                id: freccia.id_questionario_freccia,
              });
            });

            // Metto le risposte del nodo nel cestino debito.
            this.questionario[0].nodes[i].risposte.forEach((risposta) => {
              this.questionario[0].risposte_delete.push({
                id: risposta.id_questionario_risposta,
              });
            });
          }
        }

        // Cancello il nodo dai nodes usando l'indice.
        this.questionario[0].nodes.splice(index, 1);

        /* Aggiungo al cestino debito le connessioni
         * in uscita e in entrata dal nodo. */
        this.questionario[0].connections.forEach((c) => {
          if (c.id_questionario_domanda == id) {
            // Le frecce in uscita le cestino e basta...
            this.questionario[0].connections_delete.push({
              id: c.id_questionario_connessione,
            });
          } else if (c.id_questionario_domanda_successiva == id) {
            // ... Le frecce in entrata le cestino e le dichiaro non-assegnate.
            this.questionario[0].connections_delete.push({
              id: c.id_questionario_connessione,
            });

            this.questionario[0].nodes.forEach((nodo) => {
              nodo.frecce.forEach((freccia) => {
                if (
                  freccia.id_questionario_freccia == c.id_questionario_freccia
                ) {
                  freccia.assegnata = 0;
                }
              });
            });
          }
        });

        // Elimino le connessioni in entrata e in uscita dal nodo.
        let connections_tmp = this.questionario[0].connections.filter(
          (c) =>
            c.id_questionario_domanda != id &&
            c.id_questionario_domanda_successiva != id
        );

        this.questionario[0].connections = new Array();
        connections_tmp.forEach((c) => {
          this.questionario[0].connections.push(c);
        });

        // Riallineo gli entry point.
        this.setEntryPoint();
      } else if (tipo == "connessioni") {
        // Cerco la connessione da eliminare
        let index;
        for (let i = 0; i < this.questionario[0].connections.length; i++) {
          // Quando trovo la connessione che cerco...
          if (
            this.questionario[0].connections[i].id_questionario_connessione ==
            id
          ) {
            // Registro il suo indice
            index = i;

            // Aggiungo la connessione al cestino debito
            this.questionario[0].connections_delete.push({
              id: this.questionario[0].connections[i]
                .id_questionario_connessione,
            });

            // Libero la freccia del suo nodo di partenza
            this.questionario[0].nodes.forEach((n) => {
              n.frecce.forEach((f) => {
                if (
                  f.id_questionario_freccia ==
                  this.questionario[0].connections[i].id_questionario_freccia
                ) {
                  f.assegnata = 0;
                }
              });
            });
          }
        }

        // Elimino la connessione usando l'indice
        this.questionario[0].connections.splice(index, 1);

        // Riallineo gli entry point.
        this.setEntryPoint();
      }

      // Aggiorno le prop e segnalo che sono state effettuate modifiche.
      this.key_questionario++;
      this.modificheNonSalvate = true;
      //this.salvaQuestionariLocal();
    },
    indietroModifica() {
      var quest = JSON.parse(localStorage.questionario_back);
      if (this.indice_questionario_backup != 0) {
        this.questionario = quest[this.indice_questionario_backup - 1];
        this.indice_questionario_backup--;
        this.key_questionario++;
      }
    },
    modificaFlowchart() {
      if (this.modTitoloQuestionario != "") {
        this.questionario[0].titolo_questionario = this.modTitoloQuestionario;
      }

      if (this.modDesQuestionario != "") {
        this.questionario[0].des_questionario = this.modDesQuestionario;
      }

      if (this.modDataQuestionario != "") {
        this.questionario[0].data_questionario = this.modDataQuestionario;
      }

      if (this.modIdTipoQuestionario != "") {
        this.questionario[0].id_tipo_questionario = this.modIdTipoQuestionario;

        // Assegno la des_tipo_questionario in base all'id_tipo_questionario scelto
        this.tipi_questionario.forEach((tipo) => {
          if (tipo.id_tabella == this.questionario[0].id_tipo_questionario) {
            this.questionario[0].des_tipo_questionario = tipo.des;
          }
        });
      }

      if (this.modTestoFinale != null) {
        this.questionario[0].testo_finale = this.modTestoFinale;
      }

      if (this.modLinkFinale != null) {
        this.questionario[0].link_finale = this.modLinkFinale;
      }

      if (this.fileCaricato != null) {
        this.questionario[0].img_logo = this.fileCaricato;
      }

      if (this.modAbilita_lista_domande != null) {
        this.questionario[0].abilita_lista_domande =
          this.modAbilita_lista_domande == true ? 1 : 0;
      }

      // Segnalo che ci sono modifiche non salvate
      this.modificheNonSalvate = true;

      // Chiudo la finestra
      this.dialogModFlowchart = false;
    },
    renderizzaLogo() {
      document.getElementById("chartLogo").style.backgroundImage =
        "url(" + URL.createObjectURL(this.img_logoNuovo) + ")";

      var dataBase64;
      var reader = new FileReader();
      let that = this;
      reader.readAsDataURL(this.img_logoNuovo);
      reader.onload = function () {
        dataBase64 = reader.result;
        that.fileCaricato = dataBase64;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    renderizzaLogoSalvato() {
      var base = this.fileCaricato.split(",");
      var blob = Base64(base[1], "image/png");
      document.getElementById("chartLogo").style.backgroundImage =
        "url(" + URL.createObjectURL(blob) + ")";
    },
    salva() {
      /* Se la validazione restituisce false vuol dire che c'è qualcosa che non va
       * e non salvo */
      if (!this.valida()) {
        return;
      }

      /* Faccio partire l'overlay di salvataggio, che sarà interrotto
         alla fine del caricamento (nel metodo "carica()" ). */
      this.overlay = true;

      var request = {
        controller: "Questionario",
        method: "PUT",
        richiesta: {
          action: "setdiagramma",
          token: localStorage.getItem("user_token"),
          id_questionario: this.questionario[0].id_questionario,
          id_utente: localStorage.getItem("id"),
          db_name: "quickcheck",
          questionario: this.questionario,
        },
      };

      // Riempio gli array vuoti della request per convenienza di backend
      // Controllo che l'array dei nodi non sia vuoto
      if (request.richiesta.questionario[0].nodes.length == 0) {
        request.richiesta.questionario[0].nodes.push({ id: -1 });
      } else {
        // Controllo ogni nodo
        request.richiesta.questionario[0].nodes.forEach((nodo) => {
          // Aree del nodo
          if (nodo.aree.length == 0) {
            nodo.aree.push({ id: -1 });
          }
          // Parametri del nodo
          if (nodo.parametri.length == 0) {
            nodo.parametri.push({ id: -1 });
          }
          // Parametri delle risposte del nodo
          nodo.risposte.forEach((risposta) => {
            if (risposta.id != -1) {
              if (risposta.parametri.length == 0) {
                risposta.parametri.push({ id: -1 });
              }
            }
          });
        });
      }

      // Controllo che l'array delle connessioni non sia vuoto
      if (request.richiesta.questionario[0].connections.length == 0) {
        request.richiesta.questionario[0].connections.push({ id: -1 });
      }

      /* Se connections non è vuoto e riempito con un oggetto di comodo,
       * controllo i suoi parametri */
      if (request.richiesta.questionario[0].connections[0].id != -1) {
        request.richiesta.questionario[0].connections.forEach((connessione) => {
          if (connessione.parametri.length == 0) {
            connessione.parametri.push({ id: -1 });
          }
        });
      }

      // Da qui controllo tutti i cestini
      if (request.richiesta.questionario[0].aree_delete.length == 0) {
        request.richiesta.questionario[0].aree_delete.push({
          id: -1,
        });
      }
      if (request.richiesta.questionario[0].connections_delete.length == 0) {
        request.richiesta.questionario[0].connections_delete.push({
          id: -1,
        });
      }
      if (request.richiesta.questionario[0].frecce_delete.length == 0) {
        request.richiesta.questionario[0].frecce_delete.push({
          id: -1,
        });
      }
      if (request.richiesta.questionario[0].nodes_delete.length == 0) {
        request.richiesta.questionario[0].nodes_delete.push({
          id: -1,
        });
      }
      if (request.richiesta.questionario[0].risposte_delete.length == 0) {
        request.richiesta.questionario[0].risposte_delete.push({
          id: -1,
        });
      }

      /* Il backend non gestisce i booleani true / false, al
       * loro posto vuole 0 / 1, per cui sostituisco questi valori*/
      request.richiesta.questionario[0].abilita_lista_domande =
        request.richiesta.questionario[0].abilita_lista_domande == true ? 1 : 0;

      request.richiesta.questionario[0].nodes.forEach((nodo) => {
        if (nodo.mostra_contorno_aree === false) {
          nodo.mostra_contorno_aree = 0;
        } else if (nodo.mostra_contorno_aree === true) {
          nodo.mostra_contorno_aree = 1;
        }

        if (nodo.accetta_allegati === false) {
          nodo.accetta_allegati = 0;
        } else if (nodo.accetta_allegati === true) {
          nodo.accetta_allegati = 1;
        }
      });
      // Spedisco la request e gestisco la response
      this.$store.dispatch("api", request).then((res) => {
        this.salvataggio = false;
        if (res.status == 200) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Salvataggio eseguito con successo!";
          this.snackbar_text_color = "white";
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Criticità rilevata, risposta del server in stato " +
            res.status +
            ".";
          this.snackbar_text_color = "white";
        }

        /* Devo ricaricare appena fatto il salvataggio, altrimenti se si facessero
         * più salvataggi di seguito nella stessa sessione nodi e connessioni nuovi
         * sarebbero restituiti duplicati quando si esce e si rientra nel flowchart. */
        this.carica();
      });
    },
    salvaConnection(elem) {
      // Se la freccia è nuova la aggiungo
      if (elem.arrivo === "nuovo") {
        this.questionario[0].connections.push(elem.connessione);
        // Metto a "true" il parametro "assegnata" della freccia nel nodo
        this.questionario[0].nodes.forEach((nodo) => {
          if (
            nodo.id_questionario_domanda ==
            elem.connessione.id_questionario_domanda
          ) {
            nodo.frecce.forEach((freccia) => {
              if (freccia.punteggio == elem.connessione.punteggio) {
                freccia.assegnata = 1;
                this.setEntryPoint();
              }
            });
          }
        });
        // Riporto l'arrivo_funzione in "modifica", come è di default.
        this.arrivo_funzione = "modifica";
      } else if (elem.arrivo === "modifica") {
        // Se la freccia è vecchia la modifico
        for (let i = 0; i < this.questionario[0].connections.length; i++) {
          if (
            this.questionario[0].connections[i].id_questionario_connessione ==
            elem.connessione.id_questionario_connessione
          ) {
            this.questionario[0].connections.splice(
              i,
              1,
              new QCConnection(elem.connessione)
            );
          }
        }

        /* Se è stato riassegnato il punteggio, metto
         * a 1 il parametro "assegnata" della freccia
         * debita e libero la vecchia.*/
        if (
          elem.connessione.id_questionario_freccia !=
          elem.connessione_sostituita.id_questionario_freccia
        ) {
          this.questionario[0].nodes.forEach((nodo) => {
            if (
              nodo.id_questionario_domanda ==
              elem.connessione.id_questionario_domanda
            ) {
              nodo.frecce.forEach((freccia) => {
                if (freccia.punteggio == elem.connessione.punteggio) {
                  freccia.assegnata = 1;
                  this.setEntryPoint();
                } else if (
                  freccia.punteggio == elem.connessione_sostituita.punteggio
                ) {
                  freccia.assegnata = 0;
                }
              });
            }
          });
        }
      }

      this.arrivo_funzione = "modifica";
      this.connection_dialog = false;
      //this.salvaQuestionariLocal();
    },
    salvaNode(elem) {
      let nodo = new QCQuestion(elem.nodo);
      //nodo.setTitoloNodo();
      //nodo.setTestoNodo();

      let arrivo = elem.arrivo;

      // Aggiorno i cestini
      elem.aree_delete.forEach((elem) =>
        this.questionario[0].aree_delete.push(elem)
      );
      elem.connections_delete.forEach((elem) =>
        this.questionario[0].connections_delete.push(elem)
      );
      elem.frecce_delete.forEach((elem) =>
        this.questionario[0].frecce_delete.push(elem)
      );
      elem.nodes_delete.forEach((elem) =>
        this.questionario[0].nodes_delete.push(elem)
      );
      elem.risposte_delete.forEach((elem) =>
        this.questionario[0].risposte_delete.push(elem)
      );

      /* Faccio un controllo sul tipo di domanda: se è variato,
       * cancello le frecce e l'utente le deve riassegnare*/
      this.questionario[0].nodes.forEach((item) => {
        if (
          item.id_questionario_domanda == nodo.id_questionario_domanda &&
          item.id_tipo_domanda != nodo.id_tipo_domanda
        ) {
          let array_sup = this.questionario[0].connections.filter(
            (f) => f.id_questionario_domanda == nodo.id_questionario_domanda
          );

          if (array_sup.length > 0) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text =
              "Il tipo della domanda è stato modificato e le frecce ad essa collegate sono state rimosse.";
            this.snackbar_text_color = "white";
          }

          array_sup.forEach((element) => {
            let index = this.questionario[0].connections.indexOf(element);
            this.questionario[0].connections_delete.push({
              id: element.id_questionario_connessione,
            });
            this.questionario[0].connections.splice(index, 1);
          });
          this.key_questionario++;
        }
      });

      /* Faccio un controllo sulle frecce disponibili nel nodo: se il punteggio di una
       * freccia è variato, la cancello dal grafico e l'utente la deve riassegnare*/
      let check_variato_punteggio = false;
      for (let i = 0; i < nodo.frecce.length; i++) {
        for (let j = 0; j < this.questionario[0].connections.length; j++) {
          if (
            this.questionario[0].connections[j].id_questionario_freccia ==
            nodo.frecce[i].id_questionario_freccia
          ) {
            if (
              this.questionario[0].connections[j].punteggio !=
              nodo.frecce[i].punteggio
            ) {
              this.$refs.chart.removeConnection(
                this.questionario[0].connections[j]
              );
              check_variato_punteggio = true;
            }
          }
        }
      }
      /* Se effettivamente c'è stata una cancellazione
       * per variazione di punteggio stampo la snackbar che me ne da notizia */
      if (check_variato_punteggio == true) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il punteggio di alcune frecce è stato modificato e le connessioni relative sono state rimosse.";
        this.snackbar_text_color = "white";
      }

      /* Faccio un controllo sulle connessioni: se modificando il nodo
       * ho eliminato una freccia aggiungendo il suo identificativo
       * in frecce_delete, la connessione relativa deve essere eliminata */
      let check_freccia_eliminata = false;
      this.questionario[0].connections.forEach((connessione) => {
        for (let i = 0; i < this.questionario[0].frecce_delete.length; i++) {
          if (
            connessione.id_questionario_freccia ==
            this.questionario[0].frecce_delete[i]
          ) {
            check_freccia_eliminata = true;
            this.$refs.chart.removeConnection(connessione);
          }
        }
      });
      /* Se effettivamente una connessione è stata
       * cancellata perché nel nodo è stata eliminata una freccia
       * stampo la snackbar che me ne da notizia */
      if (check_freccia_eliminata == true) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Una freccia all'interno della domanda è stata cancellata e la connessione relativa è stata rimossa.";
        this.snackbar_text_color = "white";
      }

      if (arrivo == "nuovo") {
        this.questionario[0].nodes.push(nodo);
      }
      if (arrivo == "modifica") {
        /*for(let i=0; i<nodo.frecce.length; i++){
          for(let j=0; j<this.questionario[0].nodes.length; j++){
            if(nodo.frecce[i].id_questionario_freccia == this.questionario[0].nodes[j].id_questionario_freccia){
              if(nodo.frecce[i].punteggio != this.questionario[0].nodes[j].punteggio){
                nodo.frecce[i].assegnata = 0;
              }
            }
          }
        }*/

        for (let i = 0; i < this.questionario[0].nodes.length; i++) {
          if (
            this.questionario[0].nodes[i].id_questionario_domanda ==
            elem.nodo.id_questionario_domanda
          ) {
            this.questionario[0].nodes.splice(i, 1, nodo);
          }
        }
      }
      this.node_dialog = false;
      this.key_questionario++;
      this.modificheNonSalvate = true;
      //this.salvaQuestionariLocal();
    },
    salvaEdEsci() {
      this.salva();
      this.esci();
    },
    /*salvaQuestionariLocal() {
      localStorage.setItem("questionario", JSON.stringify(this.questionario));
      var questionari = JSON.parse(localStorage.questionario_back);
      if (this.indice_questionario_backup != questionari.length - 1) {
        questionari.splice(
          this.indice_questionario_backup,
          questionari.length - this.indice_questionario_backup + 1
        );
      }

      questionari.push(this.questionario);

      localStorage.setItem("questionario_back", JSON.stringify(questionari));
      this.indice_questionario_backup++;
    },*/
    setEntryPoint() {
      /* Marco a true il parametro entry_point di tutti
       * i nodi che non sono figli di un altro nodo. */
      this.questionario[0].nodes.forEach((nodo) => {
        let id_nodo = nodo.id_questionario_domanda;
        let num_destinazione = 0;
        this.questionario[0].connections.forEach((connessione) => {
          if (connessione.id_questionario_domanda_successiva == id_nodo) {
            num_destinazione = num_destinazione + 1;
          }
        });
        if (num_destinazione > 0) {
          nodo.entry_point = 0;
        } else {
          nodo.entry_point = 1;
        }
      });
    },
    valida() {
      // Di seguito Valido la presenza o meno di anelli
      // Copio l'array delle connessioni in un array provvisorio di validazione
      let connectionsDiValidazione = this.questionario[0].connections;

      // Prendo nota della dimensione iniziale di "connectionsDiValidazione"
      let dimensioneIniziale = connectionsDiValidazione.length;

      /* Elimino dall'array tutte le connessioni che
       * portano a nodi da cui non parte nessuna freccia*/
      connectionsDiValidazione = this.delFigliNoPadri(connectionsDiValidazione);

      // Prendo nota della dimensione finale di "connectionsDiValidazione"
      let dimensioneFinale = connectionsDiValidazione.length;

      /* Do ricorsività alla funzione: se la dimensione iniziale e quella finale
       * sono diverse, rieseguo la procedura di eliminazione delle frecce
       * sulla versione aggiornata di "connectionsDiValidazione"*/
      while (dimensioneIniziale != dimensioneFinale) {
        dimensioneIniziale = dimensioneFinale;
        connectionsDiValidazione = this.delFigliNoPadri(
          connectionsDiValidazione
        );
        dimensioneFinale = connectionsDiValidazione.length;
      }

      /* Ora devo eliminare ricorsivamente tutte le frecce che provengono
       * da nodi che non hanno connessioni in ingresso. Riprendo nota della
       * dimensione attuale di "connectionsDiValidazione"*/
      dimensioneIniziale = connectionsDiValidazione.length;

      /* Elimino dall'array tutte le connessioni che
       * portano a nodi in cui non entra nessuna freccia*/
      connectionsDiValidazione = this.delPadriNoFigli(connectionsDiValidazione);

      dimensioneFinale = connectionsDiValidazione.length;

      /* Do ricorsività alla funzione: se la dimensione iniziale e quella finale
       * sono diverse, rieseguo la procedura di eliminazione delle frecce
       * sulla versione aggiornata di "connectionsDiValidazione"*/
      while (dimensioneIniziale != dimensioneFinale) {
        dimensioneIniziale = dimensioneFinale;
        connectionsDiValidazione = this.delPadriNoFigli(
          connectionsDiValidazione
        );

        dimensioneFinale = connectionsDiValidazione.length;
      }

      /* Alla fine di questo processo, se sono rimaste delle connessioni,
       * esse sono evidentemente iscritte in una struttura ad anello.*/

      // Questi sono i messaggi da stampare a seconda del caso
      /*let msgAnelloPresente =
        "Sono stati rilevati dei difetti logici nella struttura del grafo: ci sono " +
        connectionsDiValidazione.length +
        " frecce che collegano dei nodi in maniera ricorsiva; questo potrebbe impedire la pubblicazione del tuo questionario.";
      let msgFrecceNonAssegnate =
        "Ci sono delle domande (a risposta multipla) le cui uscite non sono state tutte assegnate; ciò potrebbe impedire la pubblicazione del tuo quesgtionario.";
      let msgTuttoOk =
        "Non sono stati rilevati difetti logici nella struttura del tuo questionario";*/
      if (
        connectionsDiValidazione.length ==
        0 /*&&
        esistonoFrecceNonAssegnate == false*/
      ) {
        return true;
      } /*else if (esistonoFrecceNonAssegnate == true) {
        this.id_stato_questionario = -1;
        this.msgValidazione = msgFrecceNonAssegnate;
        this.validazioneDialogVisible = true;
      }*/ else {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "È presente una struttura ricorsiva, salvataggio non eseguito.";
        this.snackbar_text_color = "white";
        return false;
      }
    },
  },
};
</script>
<style scoped>
</style>
