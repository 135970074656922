<template>
  <div style="width: 100%">
    
    <Dashboard />
  </div>
</template>
<script>
import Dashboard from "../../components/PRODUCTION/LOGISTICA/Dashboard.vue";
export default {
  data() {
    return {
      modalTab: "produzione",
    };
  },
  components: {
    Dashboard,
  },
};
</script>