<template>
  <v-container fluid class="py-0">
    <form>
      <v-row>
        <v-col cols="12">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card :elevation="hover ? 24 : 2">
                <v-toolbar
                  dense
                  class="indigo darken-1 headline"
                  primary-title
                  uppercase
                >
                  <v-toolbar-title class="white--text"
                    >Magazzino</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-5"
                        @click="clickAggiungiMagazzino"
                        small
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon color="primary">mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Aggiungi</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mr-5" small v-bind="attrs" v-on="on"
                        ><v-icon color="success">mdi-floppy</v-icon></v-btn
                      >
                    </template>
                    <span>Salva</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="getArticoloMagazzini"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary">mdi-refresh-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Ricarica</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-data-table
                      :headers="headers_magazzini"
                      :items="items_magazzini"
                    >
                      <!-- Pulsante per la modifica del magazzino -->
                      <template v-slot:[`item.modifica`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="clickModificaMagazzino(item)"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="primary">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Modifica Magazzino</span>
                        </v-tooltip>
                      </template>

                      <!-- Pulsante per il dettaglio dei movimenti -->
                      <template v-slot:[`item.movimenti`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="clickDettaglioMovimenti(item)"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="primary">mdi-magnify</v-icon>
                            </v-btn>
                          </template>
                          <span>Dettagli Movimenti</span>
                        </v-tooltip>
                      </template>

                      <!-- Pulsante per il dettaglio dei movimenti -->
                      <template
                        v-slot:[`item.magazzino_predefinito`]="{ item }"
                      >
                        <v-icon>
                          {{
                            item.magazzino_predefinito === true
                              ? "mdi-check-underline"
                              : ""
                          }}
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </form>
    <v-dialog v-model="movimenti_dialog" persistent>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Movimenti</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="movimenti_dialog = false" small>
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          style="max-height: 500px; margin-top: 10px; overflow-y: auto"
        >
          <v-data-table :headers="headers_movimenti" :items="items_movimenti">
            <template v-slot:top>
              <v-container>
                <v-row>
                  <v-col class="justify-end d-flex" cols="6">
                    <!-- Filtro inizio periodo -->
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="289"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          background-color="#ffffff"
                          dense
                          hide-details
                          label="Data Inizio Periodo"
                          style="display: inline-block; max-width: 350px"
                          v-model="data_da"
                          readonly
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon
                              class="ml-2"
                              @click="data_da = ''"
                              color="primary"
                              >{{
                                data_da == ""
                                  ? "mdi-calendar-month"
                                  : "mdi-calendar-remove"
                              }}</v-icon
                            >
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="data_da"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6" class="justify-start d-flex">
                    <!-- Filtro fine periodo -->
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="289"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          background-color="#ffffff"
                          dense
                          hide-details
                          label="Data Fine Periodo"
                          style="
                            display: inline-block;
                            margin-right: 20px;
                            max-width: 350px;
                          "
                          v-model="data_a"
                          readonly
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon
                              class="ml-2"
                              @click="data_a = ''"
                              color="primary"
                              >{{
                                data_a == ""
                                  ? "mdi-calendar-month"
                                  : "mdi-calendar-remove"
                              }}</v-icon
                            >
                          </template></v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="data_a"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>

                    <!-- Pulsante per la ricerca dei movimenti -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getMovimentiMagazzino(magazzino_sel)"
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>Cerca</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:[`item.data`]="{ item }">
              {{ item.data != null ? `${item.data.substring(0, 16).replace('-','/')}` : "" }}
            </template>
            <template v-slot:[`item.data_documento`]="{ item }">
              {{
                item.data_documento != null
                  ? `${item.data_documento.substring(
                      8,
                      10
                    )}/${item.data_documento.substring(
                      5,
                      7
                    )}/${item.data_documento.substring(0, 4)}`
                  : ""
              }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="nuovo_magazzino_dialog" persistent width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Nuovo Magazzino</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaMagazzino" small v-bind="attrs" v-on="on">
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Salva Nuovo Magazzino</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn @click="nuovo_magazzino_dialog = !nuovo_magazzino_dialog" small
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text style="max-height: 500px; overflow-y: auto">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  background-color="#ffffff"
                  dense
                  hide-details
                  :items="items_magazzini_disponibili"
                  item-text="des_magazzino"
                  item-value="des_magazzino"
                  label="Des. Magazzino"
                  style="display: inline-block; max-width: 350px"
                  v-model="des_magazzino_nuovo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4" class="mt-n3">
                <v-switch
                  v-model="magazzino_predefinito_nuovo"
                  label="Predefinito"
                ></v-switch>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  background-color="#ffffff"
                  dense
                  hide-details
                  label="Lotto Economico"
                  style="display: inline-block; max-width: 350px"
                  type="text"
                  v-model="lotto_economico_nuovo"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  background-color="#ffffff"
                  dense
                  hide-details
                  label="Qtà Scorta"
                  style="display: inline-block; max-width: 350px"
                  type="number"
                  v-model="qta_scorta_nuovo"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  background-color="#ffffff"
                  dense
                  hide-details
                  label="Qtà Sottoscorta"
                  style="display: inline-block; max-width: 350px"
                  type="number"
                  v-model="qta_sottoscorta_nuovo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment/moment";
import Warehouse from "@/classes/Warehouse.js";

export default {
  props: {
    articolo_sel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      data_a: "",
      data_da: "",
      id_articolo_magazzino_nuovo: -1,
      id_articolo_nuovo: null,
      id_magazzino_nuovo: null,
      note_magazzino_nuovo: null,
      magazzino_predefinito_nuovo: null,
      lotto_economico_nuovo: null,
      qta_scorta_nuovo: null,
      qta_sottoscorta_nuovo: null,
      date_ins_nuovo: null,
      date_mod_nuovo: null,
      id_utente_nuovo: null,
      cod_magazzino_nuovo: null,
      des_magazzino_nuovo: null,
      tipo_magazzino_nuovo: "I",
      magazzino_sel: {},

      // Modal
      menu1: false,
      menu2: false,
      movimenti_dialog: false,
      nuovo_magazzino_dialog: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      headers_magazzini: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "Movimenti",
          value: "movimenti",
          sortable: false,
        },
        {
          text: "Predefinito",
          value: "magazzino_predefinito",
          sortable: false,
        },
        {
          text: "Magazzino",
          value: "des_magazzino",
          sortable: false,
        },
        {
          text: "Giacenza",
          value: "giacenza",
          sortable: false,
        },
        {
          text: "Qtà Impegnata",
          value: "qunatita_impegnata",
          sortable: false,
        },
        {
          text: "Qtà Disponibile",
          value: "qunatita_disponibile",
          sortable: false,
        },
        {
          text: "Lotto Economico",
          value: "lotto_economico",
          sortable: false,
        },
        {
          text: "Scorta",
          value: "qta_scorta",
          sortable: false,
        },
        {
          text: "Sotto Scorta",
          value: "qta_sottoscorta",
          sortable: false,
        },
        {
          text: "Note",
          value: "note_magazzino",
          sortable: false,
        },
      ],
      headers_movimenti: [
        {
          text: "",
          value: "",
          align: "start",
          sortable: false,
        },
        {
          text: "Data",
          value: "data",
          sortable: false,
        },
        {
          text: "Magazzino",
          value: "magazzino",
          sortable: false,
        },
        {
          text: "Qtà Movimento",
          value: "quantita",
          sortable: false,
        },
        {
          text: "Esito Controllo",
          value: "esito_controllo",
          sortable: false,
        },
        {
          text: "Esito Qualita",
          value: "esito_qualita",
          sortable: false,
        },
        {
          text: "Lotto",
          value: "cod_lotto",
          sortable: false,
        },
        {
          text: "Lotto Fornitore",
          value: "lotto_fornitore",
          sortable: false,
        },
        {
          text: "Codice Timbro",
          value: "codice_timbro",
          sortable: false,
        },
        {
          text: "Tipo Documento",
          value: "des_documento",
          sortable: false,
        },
        {
          text: "Numero Documento",
          value: "num_documento",
          sortable: false,
        },
        {
          text: "Data Documento",
          value: "data_documento",
          sortable: false,
        },
        {
          text: "Ragione Sociale",
          value: "ragione_sociale",
          sortable: false,
        },
      ],
      items_magazzini: [],
      items_magazzini_disponibili: [],
      items_movimenti: [],
    };
  },
  methods: {
    clickAggiungiMagazzino() {
      this.id_articolo_magazzino_nuovo = -1;
      this.id_articolo_nuovo = this.articolo_sel.id_articolo;
      this.id_magazzino_nuovo = null;
      this.note_magazzino_nuovo = null;
      this.magazzino_predefinito_nuovo = false;
      this.lotto_economico_nuovo = null;
      this.qta_scorta_nuovo = null;
      this.qta_sottoscorta_nuovo = null;
      this.date_ins_nuovo = null;
      this.date_mod_nuovo = null;
      this.id_utente_nuovo = null;
      this.cod_magazzino_nuovo = null;
      this.des_magazzino_nuovo = null;
      this.tipo_magazzino_nuovo = "I";

      this.nuovo_magazzino_dialog = true;
    },
    clickDettaglioMovimenti(item) {
      console.log("entrattoo");
      // Definisco il tempo JS
      let data_da_js = moment().subtract(1, "months");
      let data_a_js = moment();

      // Definisco il tempo umano con cui valorizzare il calendario
      this.data_da = `${data_da_js.year()}-${
        data_da_js.month() + 1
      }-${data_da_js.date()}`;
      this.data_a = `${data_a_js.year()}-${
        data_a_js.month() + 1
      }-${data_a_js.date()}`;

      // Registro il magazzino selezionato
      this.magazzino_sel = Object.assign({}, item);

      // Mostro il dialog dei movimenti
      this.movimenti_dialog = true;

      // Svuoto la tabella dei movimenti e chiamo il BE per avere i nuovi dati.
      this.items_movimenti = [];
      this.getMovimentiMagazzino(item);
    },
    clickModificaMagazzino(item) {
      this.magazzino_sel = Object.assign({}, item);

      this.id_articolo_magazzino_nuovo = this.magazzino_sel.id_articolo_magazzino;
      this.id_articolo_nuovo = this.magazzino_sel.id_articolo;
      this.id_magazzino_nuovo = this.magazzino_sel.id_magazzino;
      this.note_magazzino_nuovo = this.magazzino_sel.note_magazzino;
      this.magazzino_predefinito_nuovo = this.magazzino_sel.magazzino_predefinito;
      this.lotto_economico_nuovo = this.magazzino_sel.lotto_economico;
      this.qta_scorta_nuovo = this.magazzino_sel.qta_scorta;
      this.qta_sottoscorta_nuovo = this.magazzino_sel.qta_sottoscorta;
      this.date_ins_nuovo = this.magazzino_sel.date_ins;
      this.date_mod_nuovo = this.magazzino_sel.date_mod;
      this.id_utente_nuovo = this.magazzino_sel.id_utente;
      this.cod_magazzino_nuovo = this.magazzino_sel.cod_magazzino;
      this.des_magazzino_nuovo = this.magazzino_sel.des_magazzino;
      this.tipo_magazzino_nuovo = this.magazzino_sel.tipo_magazzino;

      this.nuovo_magazzino_dialog = true;
    },
    getArticoloMagazzini() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolomagazzini",
          token: localStorage.getItem("user_token"),

          id_articolo: this.articolo_sel.id_articolo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.items_magazzini = [];

        // Porto il campo predefinito degli oggetti nella response da numero a booleano
        console.log(JSON.stringify(res.data));
        res.data.forEach((elem) => {
          if (elem.magazzino_predefinito === 0) {
            console.log("fatto 1" + elem.magazzino_predefinito);
            elem.magazzino_predefinito = false;
          } else {
            console.log("fatto 2" + elem.magazzino_predefinito);
            elem.magazzino_predefinito = true;
          }
        });

        res.data.forEach((elem) => {
          this.items_magazzini.push(elem);
        });
      });
    },
    getMagazzini() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getmagazzini",
          id_articolo: this.articolo_sel.id_articolo,
          token: localStorage.getItem("user_token"),
        },
      };

      console.log(`request`, request);

      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200) {
          // Registro i magazzini della response
          this.items_magazzini_disponibili = res.data;
        }
      });
    },
    getMovimentiMagazzino(item) {
      // Se le date sono valorizzate, eseguo un controllo
      if (this.data_da != "" && this.data_a != "") {
        // Se la data_a è prima della data_da, mi blocco
        let inizio = moment(this.data_da);
        let fine = moment(this.data_a);

        if (inizio.isAfter(fine)) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "La data di inizio periodo deve precedere la data di fine periodo.";
          this.snackbar_text_color = "white";
          return;
        }
      }

      // Se le date passano il controllo, preparo la request
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolomovimentimagazzino",
          token: localStorage.getItem("user_token"),

          id_articolo: item.id_articolo,
          id_magazzino: item.id_magazzino,
          data_da: this.data_da,
          data_a: this.data_a,
        },
      };

      // Spedisco la request al BE
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Se la risposta è in 200 ricarico i movimenti
          this.items_movimenti = [];
          res.data.forEach((elem) => {
            this.items_movimenti.push(elem);
          });
        } else {
          // Se la risposta non è in 200 stampo una snackbar di errore
          this.snackbar = true;
          this.snackbar_background = "grey lighten-5";
          this.snackbar_icon = "mdi-information";
          this.snackbar_icon_color = "indigo";
          this.snackbar_text =
            "Errore tecnico durante il caricamento dei movimenti, codice: " +
            res.status +
            ".";
          this.snackbar_text_color = "black";
        }
      });
    },
    pulisciCampi() {
      this.id_articolo_magazzino_nuovo = -1;
      this.id_articolo_nuovo = this.articolo_sel.id_articolo;
      this.id_magazzino_nuovo = null;
      this.note_magazzino_nuovo = null;
      this.magazzino_predefinito_nuovo = null;
      this.lotto_economico_nuovo = null;
      this.qta_scorta_nuovo = null;
      this.qta_sottoscorta_nuovo = null;
      this.date_ins_nuovo = null;
      this.date_mod_nuovo = null;
      this.id_utente_nuovo = null;
      this.cod_magazzino_nuovo = null;
      this.des_magazzino_nuovo = null;
      this.tipo_magazzino_nuovo = "I";
    },
    salvaMagazzino() {
      // Se la des_magazzino_nuovo non è assegnata, stampo errore e mi fermo.
      if (this.des_magazzino_nuovo === null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Deve essere definita una Des Magazzino.";
        this.snackbar_text_color = "white";

        return;
      }

      // Se il lotto_economico_nuovo non è assegnato, stampo errore e mi fermo.
      if (this.lotto_economico_nuovo === null) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Deve essere definito un Lotto Economico.";
        this.snackbar_text_color = "white";

        return;
      }

      /* Se il magazzino attuale è predefinito e fra gli altri magazzini
       * uno con diverso id_articolo_magazzino è già prefedinito, non permetto
       * di salvare, converto l'attuale flag predefinito e stampo una snackbar. */

      // Per prima cosa eseguo il check
      let check_predefinito_esiste = false;

      this.items_magazzini.forEach((elem) => {
        if (
          this.magazzino_predefinito_nuovo === true &&
          elem.magazzino_predefinito === true &&
          elem.id_articolo_magazzino != this.id_articolo_magazzino_nuovo
        ) {
          check_predefinito_esiste = true;
        }
      });

      // Se il check becca una predefinizione in un altro magazzino, mi regolo come devo.
      if (check_predefinito_esiste == true) {
        this.magazzino_predefinito_nuovo = false;

        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Esiste già un magazzino predefinito.";
        this.snackbar_text_color = "white";

        return;
      }
      // Costruisco un oggetto Warehouse
      let magazzino_tmp = new Warehouse(
        this.id_articolo_magazzino_nuovo,
        this.id_articolo_nuovo,
        this.id_magazzino_nuovo,
        this.note_magazzino_nuovo,
        this.magazzino_predefinito_nuovo,
        this.lotto_economico_nuovo,
        this.qta_scorta_nuovo,
        this.qta_sottoscorta_nuovo,
        this.date_ins_nuovo,
        this.date_mod_nuovo,
        this.id_utente_nuovo,
        this.cod_magazzino_nuovo,
        this.des_magazzino_nuovo,
        this.tipo_magazzino_nuovo
      );

      // Mi faccio un array temporaneo di magazzini per il salvataggio.
      let items_magazzini_tmp = Array.from(this.items_magazzini);

      // Se il magazzino è nuovo...
      if (this.id_articolo_magazzino_nuovo < 0) {
        // Aggiungo il nuovo magazzino alla lista temporanea, in modo da poterlo salvare.
        items_magazzini_tmp.push(magazzino_tmp);
      } else {
        // Se il magazzino non è nuovo, modifico il suo originale all'interno dell'array temporaneo.
        items_magazzini_tmp.forEach((elem) => {
          if (
            elem.id_articolo_magazzino === magazzino_tmp.id_articolo_magazzino
          ) {
            let i = items_magazzini_tmp.indexOf(elem);
            items_magazzini_tmp.splice(i, 1, magazzino_tmp);
          }
        });
      }

      // Porto il campo predefinito dei magazzini temporanei da booleano a numero
      items_magazzini_tmp.forEach((elem) => {
        elem.magazzino_predefinito =
          elem.magazzino_predefinito === false ? 0 : 1;
      });

      // Compongo la request
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolomagazzino",
          token: localStorage.getItem("user_token"),

          id_articolo: this.articolo_sel.id_articolo,
          magazzino: items_magazzini_tmp,
        },
      };

      // Mando la request al BE e aspetto la response
      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          /* Se la risposta è in 200 svuoto la lista
           * dei magazzini e la ricarico. */
          this.items_magazzini = [];
          this.getArticoloMagazzini();
        } else {
          // Se la risposta non è in 200 stampo una snackbar di errore
          this.snackbar = true;
          this.snackbar_background = "grey lighten-5";
          this.snackbar_icon = "mdi-information";
          this.snackbar_icon_color = "indigo";
          this.snackbar_text =
            "Errore tecnico durante il salvataggio, codice: " +
            res.status +
            ".";
          this.snackbar_text_color = "black";
        }

        // In ogni caso, chiudo il dialog e lo vuoto
        this.nuovo_magazzino_dialog = false;
        this.pulisciCampi();
      });
    },
  },
  watch: {
    des_magazzino_nuovo: {
      immediate: true,
      handler() {
        this.items_magazzini_disponibili.forEach((elem) => {
          if (elem.des_magazzino === this.des_magazzino_nuovo) {
            this.id_magazzino_nuovo = elem.id_magazzino;
          }
        });
      },
    },
  },
  mounted() {
    this.getArticoloMagazzini();
    this.getMagazzini();
  },
};
</script>

<style scoped></style>
