<template>
  <div>
    <!-- Peronsale Modal in order to Add/Edit Personale -->
    <v-dialog persistent :hide-overlay="true" v-model="personale_dialog" width="700">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Personale</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            :idForm="SOTTO_FORM"
            :idOggetto="PERSONALE_BUTTON_ARRAY[3]"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="salvaPersonale"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="personale_dialog = false" small>
                <v-icon color="error" v-on="on" v-bind="attrs" depressed>
                  mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row class="pa-0">
            <v-col md="6">
              <v-autocomplete
                v-model="id_personale"
                :items="utenteList"
                label="Nominativo"
                placeholder="Nominativo"
                item-text="nominativo"
                item-value="nominativo"
                required
              >
              </v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                v-model="id_competenza"
                :items="competenzaLista"
                label="Competenza"
                placeholder="Competenza"
                item-text="des"
                required
              >
                <template #label>
                  <span>Competenza<strong>* </strong></span>
                  <a @click.stop style="pointer-events: all">
                    <Modules
                      v-on:dialogModules="aggiornaCompetenza"
                      tipo="COMPETENZA"
                      nome="Competenza"
                      setaction="settabelle"
                      getaction="gettabelle"
                      controller="Magazzino"
                      filter="validita = 1"
                      :dati="[{ des: '' }]"
                    />
                  </a>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-toolbar dense class="indigo darken-1 headline" primary-title uppercase>
      <v-toolbar-title class="white--text">Personale</v-toolbar-title>
      <v-spacer></v-spacer>
      <Button
        colore_icona="primary"
        :idForm="SOTTO_FORM"
        :idOggetto="PERSONALE_BUTTON_ARRAY[0]"
        mdi_icona="mdi-plus"
        testo_tooltip="Aggiungi Personale"
        v-on:buttonClick="openPersonale"
      />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :loading="loadingPersonale"
      :headers="PersonaleHeaders"
      :items="this.articolociclipersonale"
    >
      <v-progress-linear
        v-show="progressBarPersonale"
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template v-slot:[`item.edit`]="{ item }">
        <Button
          colore_icona="primary"
          :idForm="SOTTO_FORM"
          :idOggetto="PERSONALE_BUTTON_ARRAY[1]"
          mdi_icona="mdi-pencil"
          testo_tooltip="Aggiornare"
          v-on:buttonClick="cicloPersonaleEdit(item)"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <Button
          colore_icona="error"
          :idForm="SOTTO_FORM"
          :idOggetto="PERSONALE_BUTTON_ARRAY[2]"
          mdi_icona="mdi-window-close"
          testo_tooltip="Cancellare"
          v-on:buttonClick="deletePersonale(item)"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import moment from "moment";
export default {
  props: [
    "data",
    "SOTTO_FORM",
    "PERSONALE_BUTTON_ARRAY",
    "ID_ARTICOLO",
    "ID_CICLO_FASI",
    "id_ciclostd_dettaglio",
    "id_commessa_dettaglio",
  ],
  components: {
    Button,
    Modules: () => import("@/components/TOOLS/Modules"),
  },
  data() {
    return {
      // Personale Variables
      articolociclipersonale: [],
      idciclo_fasi_personale: -1,
      id_ciclostd_fasi_personale: -1,
      competenza: "",
      id_temp: -1,
      deletedPersonale: [],
      nominativo: "",
      id_competenza: "",
      competenzaLista: [],
      personale_dialog: false,
      utenteList: [],
      id_personale: "",
      progressBarPersonale: false,
      loadingPersonale: false,
      singlePerson: {},
      // Variabili d'appoggio
      index_sel: null,
      // Header of person table
      PersonaleHeaders: [
        {
          value: "edit",
          sortable: false,
        },
        {
          text: "Nominativo",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Competenza",
          value: "competenza",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    // In order to open Personale form
    openPersonale() {
      this.personale_dialog = true;
      this.id_personale = "";
      this.id_competenza = "";
      this.id_temp = -1;
    },
    // In order to save a new or update an existing Personale
    salvaPersonale() {
      if (
        (this.idciclo_fasi_personale == -1 || this.id_ciclostd_fasi_personale == -1) &&
        this.id_temp === -1
      ) {
        if (this.id_personale == "" && this.id_competenza == "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "non puoi salvare il modulo vuoto";
          return;
        }
        // Procedura per salvataggio nuovo elemento
        const data = {};
        data.id_articolo = this.id_articolo;
        if (this.id_ciclo_fasi) {
          data.id_ciclo_fasi = this.id_ciclo_fasi;
        } else {
          data.id_ciclo_fasi = -1;
        }
        data.id_ciclostd_dettaglio = this.id_ciclostd_dettaglio
          ? this.id_ciclostd_dettaglio
          : -1;
        data.idciclo_fasi_personale = -1;
        data.id_ciclostd_fasi_personale = -1;
        data.nominativo = this.id_personale;
        if (data.nominativo === "") {
          data.nominativo = null;
        }
        data.validita = 1;
        data.nome = null;
        data.cognome = null;
        data.data_ins = null;
        data.data_mod = null;
        data.id_utente = localStorage.id;
        data.id_competenza = this.id_competenza;
        this.competenzaLista.map((it) => {
          if (data.id_competenza === it.des) {
            data.id_competenza = it.id_tabella;
            data.competenza = it.des;
          }
        });
        if (this.id_competenza === "") {
          data.id_competenza = null;
          data.competenza = null;
        }
        this.utenteList.map((ut) => {
          if (data.nominativo === ut.nominativo) {
            data.id_personale = ut.id_personale;
            return data;
          }
        });
        if (this.id_personale === "") {
          data.id_personale = null;
        }
        this.articolociclipersonale.push(data);
        // Evito che lo stesso nominativo abbia la stessa competenza più di una volta.
        const filteredArr = this.articolociclipersonale.reduce((thing, current) => {
          const x = thing.find(
            (item) =>
              item.competenza === current.competenza &&
              item.nominativo === current.nominativo
          );
          if (!x) {
            return thing.concat([current]);
          } else {
            return thing;
          }
        }, []);
        this.articolociclipersonale = filteredArr;
        this.articolociclipersonale.sort((a, b) => {
          if (a.competenza < b.competenza) {
            return -1;
          }
          if (a.competenza > b.competenza) {
            return 1;
          }
          return 0;
        });
        this.$emit("updatePersonale", this.articolociclipersonale);
        this.personale_dialog = false;
        this.id_temp = -1;
        this.idciclo_fasi_personale = -1;
        this.id_ciclostd_fasi_personale = -1;
      } else {
        if (this.id_personale == "" && this.id_competenza == "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert";
          this.color = "yellow accent-4";
          this.snackbar_text = "non puoi salvare il modulo vuoto";
          return;
        }
        const data = {};
        data.id_articolo = this.id_articolo;
        data.id_ciclo_fasi = this.id_ciclo_fasi;
        data.idciclo_fasi_personale = this.idciclo_fasi_personale;
        data.id_ciclostd_fasi_personale = this.id_ciclostd_fasi_personale;
        data.id_ciclostd_dettaglio = this.id_ciclostd_dettaglio;
        data.nominativo = this.id_personale;
        if (data.nominativo === "") {
          data.nominativo = null;
        }
        data.id_competenza = this.id_competenza;
        this.competenzaLista.map((it) => {
          if (data.id_competenza === it.des) {
            data.id_competenza = it.id_tabella;
            data.competenza = it.des;
          }
        });
        if (this.id_competenza === "") {
          data.id_competenza = null;
          data.competenza = null;
        }
        this.utenteList.map((ut) => {
          if (data.nominativo === ut.nominativo) {
            data.id_personale = ut.id_personale;
            return data;
          }
        });
        if (this.id_personale === "") {
          data.id_personale = null;
        }
        data.validita = 1;
        // Cancello l'indice dell'oggetto vecchio
        this.articolociclipersonale[this.index_sel] = Object.assign({}, null);
        // Assegno all'indice dell'oggetto selezionato le modifiche stabilite dall'utente.
        this.articolociclipersonale[this.index_sel] = Object.assign({}, data);
        // Evito che lo stesso nominativo abbia la stessa competenza più di una volta.
        const filteredArr = this.articolociclipersonale.reduce((thing, current) => {
          const x = thing.find(
            (item) =>
              item.competenza === current.competenza &&
              item.nominativo === current.nominativo
          );
          if (!x) {
            return thing.concat([current]);
          } else {
            return thing;
          }
        }, []);
        this.articolociclipersonale = filteredArr;
        this.articolociclipersonale.sort((a, b) => {
          if (a.competenza < b.competenza) {
            return -1;
          }
          if (a.competenza > b.competenza) {
            return 1;
          }
          return 0;
        });
        this.$emit("updatePersonale", this.articolociclipersonale);
        this.personale_dialog = false;
        this.id_temp = -1;
        this.idciclo_fasi_personale = -1;
        this.id_ciclostd_fasi_personale = -1;
      }
    }, // In order to delete single Person
    deletePersonale(item) {
      if (item.idciclo_fasi_personale > -1 || item.id_ciclostd_fasi_personale > -1) {
        item.validita = 0;
        this.deletedPersonale.push(item);
        if (item.idciclo_fasi_personale) {
          let index = this.articolociclipersonale.findIndex(
            (it) => it.idciclo_fasi_personale == item.idciclo_fasi_personale
          );
          this.articolociclipersonale.splice(index, 1);
          this.$emit("delPersonale", this.deletedPersonale);
        }
        if (item.id_ciclostd_fasi_personale) {
          let index = this.articolociclipersonale.findIndex(
            (it) => it.id_ciclostd_fasi_personale == item.id_ciclostd_fasi_personale
          );
          this.articolociclipersonale.splice(index, 1);
          this.$emit("delPersonale", this.deletedPersonale);
        }
      } else {
        let i = this.articolociclipersonale.findIndex((it) => it === item);
        this.articolociclipersonale.splice(i, 1);
      }
    },
    // In order to update Comptenza lista
    aggiornaCompetenza() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
           tipo: localStorage.prefisso_user == 'crystal' ? "PERSONALE_CRISTALPACK" : "COMPETENZA",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.competenzaLista = res.data;
      });
    },
    // In order to select single Person
    cicloPersonaleEdit(item) {
      this.personale_dialog = true;
      this.singlePerson = item;
      this.nominativo = item.nominativo;
      this.id_personale = item.nominativo;
      this.username = item.username;
      this.idciclo_fasi_personale = item.idciclo_fasi_personale;
      this.id_ciclostd_fasi_personale = item.id_ciclostd_fasi_personale;
      this.competenza = item.competenza;
      this.id_competenza = item.competenza;
      this.index_sel = this.articolociclipersonale.findIndex((it) => it === item);
      this.id_temp = moment().unix();
    },
    // in order to get ciclo personale lista
    getArticoloPersonale() {
      this.loadingPersonale = true;
      this.progressBarPersonale = true
      let getarticoliciclopersonale = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliciclopersonale",
          token: localStorage.getItem("user_token"),
          id_ciclo_fasi: this.ID_CICLO_FASI,
          id_articolo: this.ID_ARTICOLO,
        },
      };
      this.$store.dispatch("api", getarticoliciclopersonale).then((res) => {
        this.articolociclipersonale = res.data;
        this.articolociclipersonale.sort((a, b) => {
          if (a.competenza < b.competenza) {
            return -1;
          }
          if (a.competenza > b.competenza) {
            return 1;
          }
          return 0;
        });
        this.$emit("updatePersonale", this.articolociclipersonale);
        if (this.articolociclipersonale.length > 0) {
          this.progressBarPersonale = false;
          this.loadingPersonale = false;
        }
        if (this.articolociclipersonale.length === 0) {
          this.progressBarPersonale = false;
          this.loadingPersonale = false;
        }
      });
    },
  },
  mounted() {
    //   Getting list of Macchina
    if (this.ID_ARTICOLO) {
      this.getArticoloPersonale();
    } else {
      this.articolociclipersonale = this.data;
    }
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: localStorage.prefisso_user == 'crystal' ? "PERSONALE_CRISTALPACK" : "COMPETENZA",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array Comptenza che mi arriva dal BE
      this.competenzaLista = res.data;
    });
    // In order to get utentelist to populate the Utente lista.
    let getUtenteList = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "getutenti",
        token: localStorage.getItem("user_token"),
      },
    };
    this.$store.dispatch("api", getUtenteList).then((res) => {
      this.utenteList = res.data;
    });
  },
};
</script>
