<template>
  <div>
    <v-data-table
      class="elevation-1"
      :headers="headers_distinta_base"
      :items="items_distinta_base"
      style="width: 100%"
    >
      <!-- Titolo tabella -->
      <template v-slot:top>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title>Distinta Base</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title
            >Quantità realizzabile: {{ num_min_realizzabile }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr
          :class="item.totale <= item.qta_impegnata ? 'record_green' : ''"
          v-if="items_distinta_base[0].cod_articolo !== null"
        >
          <td
            v-for="(colonna, i) in headers_distinta_base"
            :key="i"
            v-show="colonna.value != null"
            style="text-align: left"
          >
            {{ item[colonna.value] }}
          </td>
          <!-- Bottone dettagli -->
          <td style="text-align: left">
            <v-btn
              v-if="item.residuo > 0"
              @click="dettaglioArticolo(item)"
              small
            >
              <v-icon color="primary">mdi-magnify</v-icon>
            </v-btn>
          </td>
          <!-- Bottone qr code -->
          <td style="text-align: left">
            <v-btn
              v-if="item.residuo > 0"
              @click="showPrelievi(item)"
              fab
              elevation="0"
              small
            >
              <DataMatrixReader v-on:detectQrCode="detectQrCode" />
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Dialog dei dettagli -->
    <v-dialog v-model="dettagli_visible" width="70%">
      <v-card>
        <v-toolbar
          dark
          color="#6a74ad"
          style="border-radius: 5px 5px 0px 0px"
          class="elevation-0"
          ><v-toolbar-title>Articoli nei Lotti</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn light @click="close_dialog_dettaglio_articolo()"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-row>
          <v-col cols="12" sm="6" md="12">
            <PickingArticoloLotti
              :ID_COMMESSA="id_commessa_dettaglio_selezionata"
              :DA="this.provenienza"
              :CAMBIO_STATO="this.CAMBIO_STATO"
              :COD_ARTICOLO="this.cod_articolo_selezionato"
              :ID_ARTICOLO="id_articolo_selezionato"
              :NUM_TOT_ARTICOLI="tot_articoli_da_utilizzare"
              @prelievo="valori_di_ritorno_picking"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Dialog dei prelievi -->
    <v-dialog v-model="prelievi_visible">
      <v-card>
        <v-toolbar
          class="white--text"
          color="indigo"
          dense
          style="position: sticky; top: 0px; z-index: 1"
        >
          <v-toolbar-title dense>Prelievo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="prelievi_visible = !prelievi_visible" small>
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row justify="center" align="center" class="ma-4 mb-2">
          <v-col cols="2">
            <v-checkbox
              v-model="componente_extra"
              :label="`Componente extra`"
            ></v-checkbox>
          </v-col>
          <v-col cols="3" v-if="componente_extra">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 ml-1 my-1"
              dense
              hide-details
              label="Coefficiente"
              style="display: inline-block; max-width: 350px"
              v-model="coefficiente_tmp"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 my-1"
              dense
              hide-details
              label="Codice Lotto"
              style="display: inline-block; max-width: 350px"
              v-model="lotto_tmp"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 ml-1 my-1"
              dense
              :disabled="lotto_tmp == ''"
              hide-details
              label="Numero Pezzi"
              style="display: inline-block; max-width: 350px"
              type="number"
              v-model="numero_pezzi_tmp"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="addComponentePrelevato"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Aggiungi Prelievo</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Dialog delle info -->
    <!-- <v-dialog v-model="info_dialog_visible">
      <v-card>
        <v-toolbar
          class="white--text"
          color="indigo"
          dense
          style="position: sticky; top: 0px; z-index: 1"
        >
          <v-toolbar-title dense>Info</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="info_dialog_visible = !info_dialog_visible"
            color="#ffffff"
            fab
            outlined
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row></v-row>
      </v-card>
    </v-dialog> -->

    <!-- SNACKBAR -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import DataMatrixReader from "../../TOOLS/DataMatrixReader.vue";
import PickingArticoloLotti from "@/components/PRODUCTION/LOGISTICA/ArticoloLottiPicking.vue";

export default {
  name: "DistintaBase",
  components: {
    DataMatrixReader,
    PickingArticoloLotti,
  },
  props: {
    items_distinta_base: { type: Array, default: () => [] },
    num_min_realizzabile: { type: Number, default: null },
    fase_sel: { type: Object, default: () => {} },
  },
  data() {
    return {
      // Variabili d'appoggio
      CAMBIO_STATO: 0,
      provenienza: "DistintaBase",
      tipo_prelievo: "prelievo_commessa",
      id_commessa_dettaglio_selezionata: null,
      id_articolo_selezionato: null,
      cod_articolo_selezionato: null,
      tot_articoli_da_utilizzare: null,
      qr_code_sel: null,
      coefficiente_tmp: 0,
      componente_extra: false,
      lotto_tmp: "",
      numero_pezzi_tmp: null,
      dettagli_visible: false,
      prelievi_visible: false,
      info_dialog_visible: false,
      row_sel: null,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      // array dettaglio lotto
      lotto_dettaglio: [],

      // Array
      headers_distinta_base: [
        { text: "Coefficiente", sortable: false, value: "coeff" },
        { text: "Codice Componente", sortable: false, value: "cod_articolo" },

        {
          text: "Descrizione Componente",
          sortable: false,
          value: "des_articolo",
        },
        { text: "Quantità Totale", sortable: false, value: "totale" },
        { text: "Quantità Impegno", sortable: false, value: "qta_impegnata" },
        { text: "Quantità Fabbisogno", sortable: false, value: "fabbisogno" },
        { text: "Quantità Prelevata", sortable: false, value: "qta_prelevata" },
        { text: "Dettaglio", sortable: false, value: null },
        { text: "Prelievo", sortable: false, value: null },
      ],
      headers_dettaglio: [
        { text: "Cod. Lotto", sortable: false, value: "cod_lotto" },
        { text: "Cod. Articolo", sortable: false, value: "cod_articolo" },
        { text: "Lotto Fornitore", sortable: false, value: "lotto_fornitore" },
        { text: "Giacenza", sortable: false, value: "giacenza" },
      ],
    };
  },
  methods: {
    addComponentePrelevato() {
      // Controllo che i componenti esterni abbiano il coefficiente assegnato.
      if (
        (this.componente_extra === true && this.coefficiente_tmp > 0) ||
        (this.componente_extra === false && this.coefficiente_tmp === 0) ||
        (this.componente_extra === false && this.coefficiente_tmp == null)
      ) {
        // Controllo che i campi siano valorizzati
        if (this.lotto_tmp != "" && this.numero_pezzi_tmp > 0) {
          // Se è tutto a posto, segno i dati del prelievo in un oggetto
          let componente_prelevato = {
            cod_lotto: this.lotto_tmp,
            qta_prelevata: this.numero_pezzi_tmp,
            coeff: this.coefficiente_tmp,
          };

          /* Emetto un evento così il padre usa questi dati
           * per effettuare una chiamata al BE. */
          this.$emit("prelievo_registrato", componente_prelevato);
          this.lotto_tmp = null;
          this.numero_pezzi_tmp = null;
          this.coefficiente_tmp = null;

          this.prelievi_visible = false;
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            'Entrambi i campi "Codice Lotto" e "Numero Pezzi" devono essere valorizzati.';
          this.snackbar_text_color = "white";
        }
      } else {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il coefficiente dei componenti extra deve essere superiore a 0.";
        this.snackbar_text_color = "white";
      }
    },
    detectQrCode(
      snackbar_text,
      snackbar_background,
      snackbar_icon,
      snackbar_icon_color,
      snackbar_text_color
    ) {
      //stringa qrcode in snackbar_text
      this.snackbar_text = "QR CODE rilevato";
      this.snackbar = true;
      this.snackbar_background = snackbar_background;
      this.snackbar_icon = snackbar_icon;
      this.snackbar_icon_color = snackbar_icon_color;
      this.snackbar_text_color = snackbar_text_color;

      this.qr_code_sel = snackbar_text;

      this.getQrPrelievo(this.qr_code_sel);
    },
    getQrPrelievo(arg) {
      let richiesta = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getqrprelievo",
          token: localStorage.user_token,
          qr_code: arg,
          id_articolo: this.row_sel.id_articolo,
        },
      };
      this.$store.dispatch("api", richiesta).then((risposta) => {
        console.log(risposta);
        if (!risposta.data.length) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "QR CODE non valido";
          this.snackbar_text_color = "white";
        } else {
          this.lotto_tmp = risposta.data[0].cod_lotto;
          this.prelievi_visible = true;
        }
      });
    },
    valori_di_ritorno_picking(value) {
      console.log(value);
      //value è argomento passato come ritorno contenete l'array con i codici lotto e la quantità prelevate in funzione della selezione
      //richiamo il padre allo stesso modo di
      //addComponentePrelevato effettundo un ciclo di richieste per tutti i cod lotto e ricaricando la pagina al termine sempre passando dal padre
      // rifacendo la chiamata della distinta base.
      //console.log("AAAAAAAAAAAAAAAAAA", value)

      value.forEach((elem) => {
        console.log("entrato nel primo metodo");
        // debugger;
        if (elem.qta_prelevata > 0 && elem.cod_lotto != null) {
          let componente_prelevato = {
            cod_lotto: elem.cod_lotto,
            qta_prelevata: elem.qta_prelevata,
            coeff: null,
          };
          console.log(componente_prelevato);
          // Si blocca perché il cod è null
          this.$emit("prelievo_multiplo", componente_prelevato);
        }
      });

      this.$emit("ricarica_pagina");
      this.dettagli_visible = false;
    },
    dettaglioArticolo(item) {
      this.dettagli_visible = true;
      this.CAMBIO_STATO = Math.round(Math.random() * 100);
      this.id_articolo_selezionato = item.id_articolo;
      this.cod_articolo_selezionato = item.cod_articolo;
      this.tot_articoli_da_utilizzare = item.fabbisogno;
      this.id_commessa_dettaglio_selezionata =
        this.fase_sel.id_commessa_dettaglio;
    },
    close_dialog_dettaglio_articolo() {
      this.dettagli_visible = false;
      this.CAMBIO_STATO = Math.round(Math.random() * 100);
    },
    showPrelievi(item) {
      this.row_sel = Object.assign({}, item);
    },
    // chiamata per il dettaglio lotto magazzino
    getGiacenzaArticolo(arg) {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getgiacenzaarticolo",
          token: localStorage.getItem("user_token"),
          id_articolo: arg.id_articolo,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.lotto_dettaglio = res.data;
      });
    },
  },
};
</script>

<style>
.record_green {
  background-color: #5add65 !important;
}
.record_green:hover {
  background-color: #45c450 !important;
}
</style>
