<template>
  <v-row style="heigth:80%;max-height:80%" class="ml-0 mb-0 pb-0 mr-0">
    <v-col class="pa-0 mr-3 mb-0 mt-0">
      <v-card rounded style="background-color: #E8EAF6;height:100%;">
        <v-row>
          <v-col class="fill-height pa-0" cols="0" sm="0" md="3">
            <ChatList
              v-on:aggiornaUtente="aggiornaUtente"
              v-on:aggiornaGruppo="aggiornaGruppo"
              ref="chatlist"
            />
          </v-col>
          <v-col
            class="fill-height pt-0 pb-0 ma-0 pa-0"
            style="overflow-y:auto"
            sm="12"
            cols="12"
            md="9"
          >
            <ChatRoom
              v-on:aggiornaModelItems="aggiornaModelItems"
              :utente_chat="utente_chat"
              :gruppo_chat="gruppo_chat"
              :key="contatore_chatRoom"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ChatList from "./ChatList";
import ChatRoom from "./ChatRoom";
export default {
  components: {
    ChatList,
    ChatRoom,
  },
  data: () => ({
    utente_chat: {},
    gruppo_chat: {},
    contatore_chatRoom: 0,
    overlay: true,
  }),
  methods: {
    aggiornaModelItems(item) {
      this.$refs.chatlist.aggiornaModelItems(item);
      // this.contatore_chatRoom++;
    },
    aggiornaUtente(val) {
      this.utente_chat = val;
      this.gruppo_chat = null;
      this.contatore_chatRoom++;
    },
    aggiornaGruppo(val) {
      this.gruppo_chat = val;
      this.utente_chat = null;
      this.contatore_chatRoom++;
    },
  },
};
</script>