<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-row style="margin-left: 10px">
          <v-text-field
            label="Cerca fornitore"
            outlined
            v-model="fornitore_cercato"
            dense
          >
          </v-text-field>
          <v-btn
            style="margin-left: 10px"
            @click="checkTextfield()"
            ><v-icon color="primary">mdi-magnify</v-icon></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <div v-if="dettaglio_fornitore == true">
      <v-row>
        <v-col cols="3">
          <span style="font-size: 24px"
            >Ordini fornitore: [ <b>{{ fornitore_cercato }}</b> ]</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field
            class="ml-3"
            v-model="filtro"
            type="text"
            outlined
            color="primary"
            dense
            label="Filtra tabella"
             append-icon="mdi-filter"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-5">
          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            ><v-toolbar-title>Ordini fornitore</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table
            :headers="headers_ordini_fornitori"
            :items="
              ordini_fornitori.filter(
                (f) =>
                  f.num_ordine.includes(filtro) ||
                  f.data.includes(filtro) ||
                  f.riferimento.includes(filtro)
              )
            "
            class="elevation-3"
          >
            <template v-slot:item.dettagli="{ item }">
              <v-btn
                @click="dettaglioOrdine(item)"
              >
                <v-icon color="primary">mdi-eye &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialog_vedi_fornitori" width="800">
      <v-toolbar
        dark
        color="#3F51B5"
        style="border-radius: 5px 5px 0px 0px"
        class="elevation-0"
        ><v-toolbar-title>Seleziona fornitore</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn light @click="dialog_vedi_fornitori = false"
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers_fornitori"
                :items="fornitori"
                :items-per-page="10"
              >
                <template v-slot:item.seleziona="{ item }">
                  <v-btn
                    @click="selezionaFornitore(item)"
                  >
                    <v-icon color="success">mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
                  </v-btn>
                </template>
              </v-data-table></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_dettaglio_ordine" fullscreen>
      <v-toolbar
        dark
        color="#3F51B5"
        style="border-radius: 5px 5px 0px 0px"
        class="elevation-0"
      >
        <v-spacer></v-spacer>
        <v-toolbar-title
          >Ordine N°:
          <b style="font-size: 24px">{{ num_ordine }}</b></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn light @click="dialog_dettaglio_ordine = false"
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-toolbar
              dark
              color="#3F51B5"
              style="border-radius: 5px 5px 0px 0px"
              class="elevation-0"
            >
              <v-toolbar-title>Articoli ordine </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-data-table
              :headers="headers_dettaglio_ordine"
              :items="items_dettaglio_ordini"
              class="elevation-3"
            >
              <template v-slot:item.seleziona="{ item }">
                <v-btn
                  @click="selezionaArticoloOrdine(item)"
                >
                  <v-icon color="success">mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
          <v-divider></v-divider>
          <v-container>
            <v-toolbar
              dark
              color="#3F51B5"
              style="border-radius: 5px 5px 0px 0px"
              class="elevation-0"
            >
              <v-toolbar-title>Dettaglio bolla </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-data-table
              :headers="headers_bolla"
              :items="items_bolla"
              class="elevation-3"
            >
              <template v-slot:item.del="{ item }">
                <v-btn
                  @click="eliminaItemBolla(item)"
                >
                  <v-icon color="error">mdi-delete &nbsp;&nbsp;&nbsp;&nbsp;</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="error" dark @click="dialog_dettaglio_ordine = false"
            >Annulla</v-btn
          >
          <v-btn color="success" dark @click="dialog_dettaglio_ordine = false"
            >Conferma</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_elimina_bolla" width="500">
      <v-toolbar dark color="red">
        <v-spacer></v-spacer>
        <v-toolbar-title style="font-size: 30px">Attenzione!</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card>
        <v-card-text style="font-size: 18px"
          >Sei sicuro di voler eliminare la riga selezioanta?</v-card-text
        >
        <v-card-actions>
          <v-container>
            <v-row>
              <v-spacer> </v-spacer>
              <v-btn dark color="success" @click="dialog_elimina_bolla = false"
                >indietro</v-btn
              >
              <v-btn
                dark
                color="red"
                style="margin-left: 5px"
                @click="confermaEliminaItemBolla()"
                >conferma</v-btn
              >
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_seleziona_articolo_ordine">
      <v-toolbar
        dark
        color="#3F51B5"
        style="border-radius: 5px 5px 0px 0px"
        class="elevation-0"
      >
        <v-spacer></v-spacer>
        <v-toolbar-title>Inserisci il numero di pezzi </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
        light
          @click="dialog_seleziona_articolo_ordine = false"
          ><v-icon color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="2">
                <v-row>
                  <v-btn
                    class="mr-2"
                   
                    @click="calcolaPeso()"
                    ><v-icon   color="#3F51B5">mdi-weight</v-icon></v-btn
                  >
                  <v-text-field
                    dense
                    outlined
                    label="Quantità pezzi"
                    type="number"
                    min="0"
                    style="max-width: 120px"
                    v-model="qta_pezzi_articolo"
                  ></v-text-field
                ></v-row>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  outlined
                  label="Peso unitario"
                  type="number"
                  min="0"
                  v-model="peso_articolo"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  outlined
                  label="Peso lordo"
                  type="number"
                  min="0"
                  v-model="peso_lordo_articolo"
                ></v-text-field>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="contenitori"
                  item-text="text"
                  item-value="id"
                  label="Contenitori"
                  min="0"
                  v-model="contenitore_selezionato"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Quantita colli"
                  type="number"
                  min="0"
                  v-model="qta_colli_articolo"
                ></v-text-field>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Lotto fornitore"
                  min="0"
                  v-model="lotto_fornitore_articolo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="success"
            @click="
              (dialog_seleziona_articolo_ordine = false),
                (peso_articolo = null),
                (peso_lordo_articolo = null),
                (contenitore_selezionato = null),
                (lotto_fornitore_articolo = null)
            "
            >indietro</v-btn
          >
          <v-btn
            dark
            color="red"
            style="margin-left: 5px"
            @click="confermaInsersciPezzi()"
            >conferma</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  watch: {
    fornitore_cercato(val) {
      if (val == "" || val == null) {
        this.dettaglio_fornitore = false;
      }
    },
  },
  data() {
    return {
      contenitori: [
        {
          id: 1,
          text: "CONTENITORE BELLO",
        },
        {
          id: 2,
          text: "CONTENITORE MEH",
        },
        {
          id: 3,
          text: "CONTENITORE BRUTTO",
        },
      ],
      filtro: "",
      num_ordine: null,
      qta_colli_articolo: null,
      qta_pezzi_articolo: null,
      peso_articolo: null,
      lotto_fornitore_articolo: null,
      peso_lordo_articolo: null,
      data_ordine: null,
      contenitore_selezionato: null,
      indice_bolla: null,
      dialog_seleziona_articolo_ordine: false,
      dialog_elimina_bolla: false,
      riferimento_ordine: null,
      dialog_dettaglio_ordine: false,
      dialog_vedi_fornitori: false,
      fornitore_cercato: null,
      dettaglio_fornitore: false,
      fornitore_selezionato: null,
      ordini_fornitori: [
        {
          num_ordine: "404",
          data: "24/07/2020",
          riferimento: "Bla bla bla",
        },
        {
          num_ordine: "620",
          data: "24/07/2020",
          riferimento: "Bla bla bla",
        },
        {
          num_ordine: "981",
          data: "24/07/2020",
          riferimento: "Bla bla bla",
        },
      ],
      headers_ordini_fornitori: [
        {
          text: "Num ordine ",
          value: "num_ordine",
          sortable: false,
          witdh: "50px!important",
        },
        {
          text: "Data",
          value: "data",
          sortable: false,
        },
        {
          text: "Riferimento",
          value: "riferimento",
        },
        {
          text: "Dettagli",
          value: "dettagli",
        },
      ],
      headers_fornitori: [
        {
          text: "Codice",
          value: "cod",
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
        },
        {
          text: "Località",
          value: "localita",
        },
        {
          text: "Seleziona",
          value: "seleziona",
        },
      ],
      fornitori: [
        {
          cod: 1,
          ragione_sociale: "pippo srl",
          localita: "gerusalemme",
        },
      ],
      headers_dettaglio_ordine: [
        {
          text: "Articolo",
          value: "articolo",
        },
        {
          text: "Lotto fornitore",
          value: "lotto_fornitore",
        },
        {
          text: "Qta residua",
          value: "qta_residua",
        },
        {
          text: "Data consegna",
          value: "data_consegna",
        },
        {
          text: "Qta DDT",
          value: "qta_ddt",
        },
        {
          text: "Numero colli",
          value: "num_colli",
        },
        {
          text: "Seleziona",
          value: "seleziona",
        },
      ],
      items_dettaglio_ordini: [
        {
          articolo: "ZP4455",
          lotto_fornitore: "",
          qta_residua: "45",
          data_consegna: "26/10/2020",
          qta_ddt: "3",
          num_colli: "5",
        },
        {
          articolo: "BC7676",
          lotto_fornitore: "",
          qta_residua: "4",
          data_consegna: "30/10/2020",
          qta_ddt: "3",
          num_colli: "5",
        },
        {
          articolo: "LPSDSR",
          lotto_fornitore: "",
          qta_residua: "22",
          data_consegna: "11/10/2020",
          qta_ddt: "10",
          num_colli: "12",
        },
      ],
      headers_bolla: [
        {
          text: "Codice articolo",
          value: "cod_articolo",
        },
        {
          text: "Quantità",
          value: "qta",
        },
        {
          text: "Descrizione",
          value: "des",
        },
        {
          text: "Rimuovi",
          value: "del",
        },
      ],
      items_bolla: [
        {
          cod_articolo: "ABCDEF",
          qta: "150",
          des: "Viti M 10",
        },
        {
          cod_articolo: "EEFGHI",
          qta: "200",
          des: "Dadi 12Ø",
        },
        {
          cod_articolo: "ABCDEF",
          qta: "25",
          des: "Barre di acciaio 8Ø lungh. 3000",
        },
      ],
    };
  },
  computed: {},
  methods: {
    checkTextfield() {
      if (this.fornitore_cercato == "" || this.fornitore_cercato == null) {
        this.$store.state.snackbar.stato = true;
        this.$store.state.snackbar.messaggio = "Compilare il campo di ricerca!";
        this.$store.state.snackbar.colore = "#E53935";
        this.$store.commit("close_snackbar");
      } else {
        this.dialog_vedi_fornitori = true;
      }
    },
    selezionaFornitore(item) {
      this.fornitore_cercato = item.ragione_sociale;
      this.dettaglio_fornitore = true;
      this.dialog_vedi_fornitori = false;
    },
    dettaglioOrdine(item) {
      (this.num_ordine = item.num_ordine),
        (this.data_ordine = item.data),
        (this.riferimento_ordine = item.riferimento),
        (this.dialog_dettaglio_ordine = true);
    },
    eliminaItemBolla(item) {
      this.dialog_elimina_bolla = true;
      this.indice_bolla = this.items_bolla.indexOf(item);
    },
    confermaEliminaItemBolla() {
      this.items_bolla.splice(this.indice_bolla, 1);
      this.dialog_elimina_bolla = false;
    },
    selezionaArticoloOrdine(item) {
      console.log("item", item);
      this.dialog_seleziona_articolo_ordine = true;
      this.qta_pezzi_articolo = item.qta_residua;
      this.qta_colli_articolo = item.num_colli;
      this.lotto_fornitore_articolo = item.lotto_fornitore;
    },
    calcolaPeso() {
      this.peso_lordo_articolo =
        parseFloat(this.peso_articolo) * parseInt(this.qta_pezzi_articolo);
    },
  },
};
</script>