<template>
    <GraphDialog ref="dialog" title="Modifica Dashboard">
        <div class="graph_dashboard_dialog">
            <input type="text" placeholder="Nome Dashboard" v-model="name" />
            <main>
                <section>
                    <GraphButton icon="swap" @click="auth_type=!auth_type" />
                    <h4>Autorizzazione {{auth_type ? 'per profilo' : 'per utente'}}</h4>
                    <div>
                        <label v-for="(item, i) in (auth_type ? profiles : users)" :key="i">
                            <input type="checkbox" v-model="item.checked" />
                            <GraphIcon :icon="item.checked? 'check' : null" />
                            <span>{{item.name}}</span>
                        </label>
                    </div>
                </section>
                <section>
                    <h4>Icona</h4>
                    <div>
                        <img :src="image" @click="uploadAndCompress"/>
                        <GraphButton icon="remove" @click="image=null" />                        
                    </div>                    
                </section>
            </main>            
            <GraphButton text="Salva Modifiche" @click="save()" :disabled="loading" />
        </div>
    </GraphDialog>
</template>
<script>
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";
import GraphButton from "@/components/GRAPH/.common/Buttons/GraphButton.vue";
import GraphDialog from "@/components/GRAPH/.common/Dialog/GraphDialog.vue";
export default {
    name : 'GraphDashboardEditor',
    components : {
        GraphDialog,
        GraphIcon,
        GraphButton
    },
    data(){
        return{
            NETWORK : null,
            auth_type : false,
            id : null,
            image : null,
            loading : false,
            name : null,
            profiles : null,
            users : null,
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.getList('users','getutenti','utenti');
        this.getList('profiles','getprofili','profili');
    },
    methods : {
        remove(item){ 
            this.save(item.data.id_ds); 
        },
        getList(list, action, resp_field){
            const that = this;
            const request = {
                controller : 'user',
                showloading : false,
                body : {
                    action: action,
                    token: localStorage.user_token
                },
                method: 'POST',
                on : {
                    load : function(response) {
                        const items = response[resp_field];
                        that[list] = new Array();
                        for(let i=0;i< items.length;i++){
                            const item = items[i]; 
                            that[list].push({
                                id : Number(list == 'users' ? item.id_personale : item.id_profilo_utente),
                                name : list == 'users' ? item.cognome+' '+item.nome : item.cod_profilo_utente,
                                checked : false
                            });
                        }
                    },
                    error : function(response) {
                        console.error(response);
                    }
                }
            }
            this.NETWORK.AjaxRequest(request);
        },
        checkPermissions(item, field, list){
            let permissions;
            try{
                permissions = item[field].split(',');
            }catch{
                permissions = new Array();
            } 
            for(let i = 0; i < this[list].length; i++){
                const item = this[list][i];
                for(let j = 0; j < permissions.length; j++){
                    if(Number(permissions[j]) == item.id){
                        item.checked = true;
                        break;
                    }
                }
            }
        },
        open(item = null){
            for(let i = 0; i < this.users.length; i++){ this.users[i].checked = false; }
            for(let i = 0; i < this.profiles.length; i++){ this.profiles[i].checked = false; }
            if(item == null){
                this.name = null; 
                this.image = null;       
                this.id = -1;        
            }else{
                item = item.data;
                this.name = item.nome;
                this.id = item.id_ds;
                this.image = item.thumb;
                this.checkPermissions(item, 'id_utente_ds', 'users');
                this.checkPermissions(item, 'id_profilo', 'profiles');               
            }
            this.$refs.dialog.display();
        },
        save(remove = undefined){
            const that = this;
            const request = {
                controller : 'graph',
                body : {
                    action : 'setdashboard',
                    token : localStorage.user_token,
                    id_utente : 0,
                },
                method: 'PUT',
                on : {
                    load : function() {
                        that.$emit('refresh');
                        that.loading = false;
                        that.$refs.dialog.unlock();
                        that.$refs.dialog.hide();
                    },
                    error : function(response) {
                        that.loading = false;
                        that.$refs.dialog.unlock();
                        console.error(response);
                    }
                }
            }
            if(remove){
                request.body.tabella = [{
                    id_ds : remove,
                    validita : 0,
                    sfondo : null,
                }];
            }else{
                const permissions = this.auth_type ? this.profiles : this.users;
                const checked = new Array();
                for(let i = 0; i < permissions.length; i++){ 
                    if(permissions[i].checked){ checked.push(permissions[i].id); }  
                }
                request.body.tabella = [{
                    id_utente_ds : this.auth_type ? null : checked.join(','),
                    id_profilo : this.auth_type ? checked.join(',') : null,
                    id_ds: this.id,
                    nome: this.name,
                    tipo: null,
                    sfondo : null,
                    thumb : this.image
                }];
            }
            this.loading = true;
            this.$refs.dialog.lock();      
            this.NETWORK.AjaxRequest(request);
        },
        uploadAndCompress() {
            const that = this;
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/png, image/jpeg');
            input.onchange = function () {
                const reader = new FileReader();
                reader.onload = function () {
                const img = new Image();
                img.onload = function () {
                    let width, height;
                    let ratio = img.width / img.height;
                    if (img.width > img.height) {
                        width = Math.min(400, img.width);
                        height = width / ratio;
                    } else {
                        height = Math.min(400, img.height);
                        width = height * ratio;
                    }
                    const canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    that.image = ctx.canvas.toDataURL();
                };
                img.src = reader.result;
                };
                reader.readAsDataURL(input.files[0]);
            };
            input.click();
        }
    }
}
</script>
<style scoped src="./GraphDashboardEditor.css" />