<template>
  <v-card class="pa-6">
    <v-toolbar dense dark color="indigo" primary-title>
      <v-toolbar-title class="white--text">Ocr</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col>
        <v-select
          style="background: green !important"
          solo
          light
          dense
          hide-details
          :items="arrayModelli"
          item-text="des_modello"
          v-model="modello_sel"
          label="Vuoi selezionare un modello?"
        >
        </v-select>
      </v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            light
            v-bind="attrs"
            v-on="on"
            @click="clickAggiungiFile(1)"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Aggiungi Modello</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small v-on="on" v-bind="attrs" light>
            <v-icon color="error">mdi-keyboard-backspace</v-icon>
          </v-btn>
        </template>
        <span>Indietro</span>
      </v-tooltip>
    </v-toolbar>

    <v-container>
      <v-row align="center" justify="center" class="ma-2">
        <v-col cols="">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickAggiungiFile(2)"
                small
                v-on="on"
                v-bind="attrs"
                light
              >
                <v-icon color="primary">mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Carica File</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="arrayFile.length">
          <v-row>
            <v-col v-for="(elem, i) in arrayFile" :key="i">
              <v-row justify="center">
                {{ elem.des_file }}
              </v-row>

              <v-row justify="center">
                <div
                  :style="`
              width:100px;
              height:140px;
              background:url('${elem.img_file}');
              background-size:cover;
              background-position:center
            `"
                  class="miniatura"
                ></div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="arrayFile.length">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaFile" small light v-bind="attrs" v-on="on">
                <v-icon color="success">mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Conferma</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Risultato estrazione</v-toolbar-title
          >
        </v-toolbar>

        <v-data-table :headers="headers" :items="array_result"></v-data-table>
      </v-card>
    </v-container>

    <!-- DIALOG MODELLO -->
    <v-dialog
      v-model="dialog_agg_modello"
      :width="img_upload ? 1100 : 600"
      persistent
    >
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>{{
            modelloBool ? "Aggiungi Modello" : "Aggiungi File"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clickSalvaModello"
                small
                light
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success"> mdi-floppy </v-icon>
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                light
                small
                v-bind="attrs"
                v-on="on"
                @click="chiudiDialogModello"
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="overflow: scroll" justify="center" align="center">
          <v-container>
            <v-row justify="center" align="center">
              <v-col :cols="img_upload ? 5 : 6">
                <v-file-input
                  accept="image/*"
                  @change="uploadImage(img_upload)"
                  type="file"
                  v-model="img_upload"
                  :label="
                    modelloBool ? 'Seleziona un modello' : 'Seleziona file'
                  "
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col :cols="img_upload ? 4 : 6">
                <v-text-field
                  v-if="modelloBool"
                  hide-details
                  dense
                  label="Descrizione un modello
                  "
                  v-model="modello.des_modello"
                />
                <v-text-field
                  v-else
                  hide-details
                  dense
                  label="Descrizione file"
                  v-model="file.des_file"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="mt-4" v-if="img_upload && modelloBool">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="clickSelezionaAree"
                      small
                      v-on="on"
                      v-bind="attrs"
                      light
                      :depressed="clickArea"
                    >
                      <v-icon :color="clickArea ? 'grey' : 'primary'"
                        >mdi-crop</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Seleziona aree</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row justify="center" align="center" v-show="img_upload">
              <v-col>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:svg="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  id="svg"
                  style="
                    border: 1px solid grey;
                    background-size: cover;
                    display: inline-block;
                  "
                  @mousedown="mouseDown($event)"
                  @mousemove="mouseMove($event)"
                  @mouseup="mouseUp()"
                >
                  <defs id="defs_svg"></defs>
                </svg>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG SELEZIONE AREE -->
    <v-dialog v-model="dialog_sel_aree" width="400">
      <v-card>
        <v-toolbar color="indigo" dense dark>
          <v-toolbar-title>Seleziona Area</v-toolbar-title>
        </v-toolbar>
        <v-card-text justify="center" align="center">
          <v-row>
            <v-col class="pb-0">
              <v-text-field label="Label Area" v-model="area.des_area" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field
                readonly
                label="Coordinate (Primo punto x)"
                v-model="area.x1"
              />
            </v-col>
            <v-col class="pt-0 pb-0">
              <v-text-field
                readonly
                label="Coordinate (Primo punto y)"
                v-model="area.y1"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field
                readonly
                label="Coordinate (Secondo punto x)"
                v-model="area.x2"
              />
            </v-col>
            <v-col class="pt-0 pb-0">
              <v-text-field
                readonly
                label="Coordinate (Secondo punto y)"
                v-model="area.y2"
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col style="text-align: right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="chiudiSelArea" small v-bind="attrs" v-on="on">
                    <v-icon color="error"> mdi-close </v-icon>
                  </v-btn>
                </template>
                <span>Annulla</span>
              </v-tooltip>
            </v-col>
            <v-col></v-col>
            <v-col style="text-align: left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="confermaArea" small v-bind="attrs" v-on="on">
                    <v-icon color="success"> mdi-check </v-icon>
                  </v-btn>
                </template>
                <span>Conferma</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
class Modello {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "id_modello", default_val: -1 },
      { nome: "img_modello", default_val: "" },
      { nome: "des_modello", default_val: "" },
      {
        nome: "aree",
        default_val: [],
      },
    ];

    return campi;
  }
}

class Area {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "id_modello_area", default_val: -1 },
      { nome: "id_modello", default_val: -1 },
      { nome: "des_area", default_val: "" },
      { nome: "x1", default_val: 0 },
      { nome: "y1", default_val: 0 },
      { nome: "x2", default_val: 0 },
      { nome: "y2", default_val: 0 },
    ];

    return campi;
  }
}

class File {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutti i campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "id_file", default_val: -1 },
      { nome: "des_file", default_val: "" },
      { nome: "img_file", default_val: "" },
    ];

    return campi;
  }
}

export default {
  name: "Ocr",
  components: {},
  data() {
    return {
      fileBool: false,
      modelloBool: false,
      modello_sel: null,
      dataBase64: null,
      modello: new Modello(),
      area: new Area(),
      file: new File(),
      mouseDrag: false,
      clickArea: false,
      click_down_x: 0,
      click_down_y: 0,
      click_up_x: 0,
      click_up_y: 0,
      dialog_agg_modello: false,
      dialog_agg_file: false,
      dialog_sel_aree: false,
      img_upload: null,
      headers: [],
      array_result: [],
      arrayModelli: [],
      arrayFile: [],
    };
  },
  watch: {
    img_upload: {
      immediate: true,
      handler() {
        if (this.img_upload) {
          this.$nextTick(() => {
            this.svg = document.getElementById("svg");
          });
        }
      },
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.getModelli();
  },
  methods: {
    clickAggiungiFile(val) {
      if (val === 1) {
        this.modelloBool = true;
      }
      if (val === 2) {
        this.fileBool = true;
      }

      this.dialog_agg_modello = true;
    },
    clickSelezionaAree() {
      this.clickArea = !this.clickArea;
    },
    clickSalvaModello() {
      if (this.modelloBool) {
        console.log(this.modello);
        this.modello.img_modello = this.dataBase64;
        this.setModello(this.modello);
        this.dialog_agg_modello = false;
        this.modello = new Modello();
        this.modelloBool = false;
        this.img_upload = null;
        this.dataBase64 = null;
        document.getElementById("svg").style.backgroundImage = "none";
        while (this.svg.lastChild) {
          this.svg.removeChild(this.svg.lastChild);
        }
      }
      if (this.fileBool) {
        this.file.img_file = this.dataBase64;
        this.arrayFile.push(this.file);
        this.dialog_agg_modello = false;
        this.file = new File();
        this.fileBool = false;
        this.img_upload = null;
        this.dataBase64 = null;
        document.getElementById("svg").style.backgroundImage = "none";
      }
    },
    chiudiDialogModello() {
      if (this.fileBool) {
        this.fileBool = false;
        this.file.des_file = null;
        this.file = new File();
      }
      if (this.modelloBool) {
        this.modelloBool = false;
        this.modello.des_modello = null;
        this.modello = new Modello();
      }

      if (this.img_upload) {
        this.img_upload = null;
        this.dataBase64 = null;
        document.getElementById("svg").style.backgroundImage = "none";
        while (this.svg.lastChild) {
          this.svg.removeChild(this.svg.lastChild);
        }
      }

      this.dialog_agg_modello = false;
    },
    chiudiSelArea() {
      this.area = new Area();
      this.dialog_sel_aree = false;
      this.svg.removeChild(this.svg.lastChild);
    },
    confermaArea() {
      // this.area.x1 = this.area.x1 * 2;
      // this.area.y1 = this.area.y1 * 2;
      // this.area.x2 = this.area.x2 * 2;
      // this.area.y2 = this.area.y2 * 2;
      console.log(this.area);
      this.modello.aree.push(this.area);
      this.area = new Area();

      this.dialog_sel_aree = false;
    },
    getModelli() {
      let request = {
        controller: "Documento",
        method: "POST",
        richiesta: {
          action: "getmodelli",
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        console.log(res);
        this.arrayModelli = res.data.result.modelli;
        console.log(this.arrayModelli);
      });
    },
    mouseDown(e) {
      if (this.clickArea === true) {
        this.mouseDrag = true;

        this.click_down_x = Math.round(
          e.clientX - document.getElementById("svg").getBoundingClientRect().x
        );

        this.click_down_y = Math.round(
          e.clientY - document.getElementById("svg").getBoundingClientRect().y
        );

        this.area.x1 = this.click_down_x;
        this.area.y1 = this.click_down_y;

        let quadrato = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );
        quadrato.setAttribute("stroke", "#00ff00");
        quadrato.setAttribute("stroke-width", 2);
        quadrato.setAttribute("fill", "rgba(0,0,0,0)");
        quadrato.setAttribute("height", "0");
        quadrato.setAttribute("width", "0");
        quadrato.setAttribute("x", this.click_down_x);
        quadrato.setAttribute("y", this.click_down_y);

        this.drawingLine = quadrato;
        this.svg.appendChild(quadrato);
      }
    },
    mouseMove(e) {
      if (this.mouseDrag === true && this.clickArea) {
        this.click_up_x = Math.round(
          e.clientX - document.getElementById("svg").getBoundingClientRect().x
        );
        this.click_up_y = Math.round(
          e.clientY - document.getElementById("svg").getBoundingClientRect().y
        );

        // Disegno il quadrato.
        this.drawingLine.setAttribute(
          "width",
          this.click_down_x >= this.click_up_x
            ? Math.round(this.click_down_x - this.click_up_x)
            : Math.round(this.click_up_x - this.click_down_x)
        );
        this.drawingLine.setAttribute(
          "height",
          this.click_down_y >= this.click_up_y
            ? Math.round(this.click_down_y - this.click_up_y)
            : Math.round(this.click_up_y - this.click_down_y)
        );
      }
    },
    mouseUp() {
      if (this.clickArea) {
        this.area.x2 = this.click_up_x;
        this.area.y2 = this.click_up_y;
        this.mouseDrag = false;
        delete this.drawingLine;
        this.dialog_sel_aree = true;
      }
    },
    salvaFile() {
      this.setFile(this.arrayFile);
      this.arrayFile = [];
    },
    setFile(arg) {
      arg.forEach((elem) => {
        // rendere questa cosa dinamica per i diversi tipi di estensioni
        elem.img_file = elem.img_file.replace("data:image/png;base64,", "");
      });

      let richiesta = {
        controller: "Documento",
        method: "POST",
        richiesta: {
          action: "parsedocument",
          token: localStorage.user_token,
          files: arg,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        res.result.forEach((elem) => {
          this.headers.push({ value: "value", text: elem.name });
          this.array_result.push(elem.value);
        });
      });
    },
    setModello(arg) {
      // rendere questa cosa dinamica per i diversi tipi di estensioni
      arg.img_modello = arg.img_modello.replace("data:image/jpeg;base64,", "");
      let arr = [];
      arr.push(arg);

      let richiesta = {
        controller: "Documento",
        method: "PUT",
        richiesta: {
          action: "setmodello",
          token: localStorage.user_token,
          modello: arr,
        },
      };
      this.$store.dispatch("api", richiesta).then((res) => {
        console.log(res);
        this.getModelli();
      });
    },
    uploadImage(val) {
      var reader = new FileReader();
      reader.readAsDataURL(val);
      let that = this;
      reader.onload = function () {
        that.dataBase64 = reader.result;
        console.log(that.dataBase64);
        let img = new Image();
        img.onload = function () {
          // let width = img.width / 2;
          // let height = img.height / 2;
          // img.width = width;
          // img.height = height;
          // document.getElementById("svg").style.height = height;
          // document.getElementById("svg").style.width = width;
          let width = img.width;
          let height = img.height;
          document.getElementById("svg").style.height = height;
          document.getElementById("svg").style.width = width;
        };
        img.src = that.dataBase64;
        document.getElementById("svg").style.backgroundImage =
          "url(" + img.src + ")";
      };
    },
  },
};
</script>

<style scoped>
</style>

