<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Termina Produzione
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Bottone di chiamata BE per terminare la produzione -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="terminaProduzione" v-bind="attrs" v-on="on">
            <v-icon color="success" large> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Interruzione</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <!-- Bottone per tornare indietro -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" v-bind="attrs" v-on="on">
            <v-icon color="error" large> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-5">
      <!-- Prompt del form -->
      <span style="font-size: 20px">
        Indicare l'avanzo di bobina per terminare la produzione
      </span>

      <!-- Alternative di presenza / assenza avanzo -->
      <v-container class="mt-3">
        <!-- Testi delle alternative -->
        <v-row align="center" justify="center">
          <v-col cols="6">
            <span style="font-size: 20px">
              <b>Nessun avanzo</b>
            </span>
          </v-col>
          <v-col cols="6">
            <span style="font-size: 20px">
              <b>Avanzo presente</b>
            </span>
          </v-col>
        </v-row>

        <!-- Pseudo-checkbox delle alternative -->
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-icon
              @click="
                avanzo_presente = 0;
                percentuale_avanzo = 0;
              "
              :color="avanzo_presente === 0 ? `success` : ``"
              large
            >
              {{
                avanzo_presente === 0
                  ? `mdi-checkbox-marked`
                  : `mdi-checkbox-blank-outline`
              }}
            </v-icon>
          </v-col>
          <v-col cols="6">
            <v-icon
              @click="
                avanzo_presente = 1;
                percentuale_avanzo = items_percentuali[0].value;
              "
              :color="avanzo_presente === 1 ? `success` : ``"
              large
            >
              {{
                avanzo_presente === 1
                  ? `mdi-checkbox-marked`
                  : `mdi-checkbox-blank-outline`
              }}
            </v-icon>
          </v-col>
        </v-row>
      </v-container>

      <!-- Form di selezione della percentuale di avanzo bobina -->
      <v-card class="elevation-0 mt-3" outlined v-if="avanzo_presente === 1">
        <v-card-text>
          <v-radio-group v-model="percentuale_avanzo">
            <!-- Intestazione del form -->
            <template>
              <span class="mb-5" style="font-size: 20px">
                Indicare la percentuale di avanzo bobina
              </span>
            </template>
            <!-- Testo delle alternative -->
            <v-radio
              v-for="(item, i) in this.items_percentuali"
              :key="i"
              :value="item.value"
            >
              <template v-slot:label>
                <span style="font-size: 20px">{{ `${item.value}%` }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-card-text>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "TerminaProduzione",
  components: {SnackbarQV},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    work_order_sel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      percentuale_avanzo: 0,
      avanzo_presente: 0,

      // Modal

      // Array
      items_percentuali: [{ value: 25 }, { value: 50 }, { value: 75 }],
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.resetProperties();
      this.$emit("click_chiudi");
    },
    resetProperties() {
      this.percentuale_avanzo = 0;
      this.avanzo_presente = 0;
      this.radio_percentuali_visible = false;
    },
    terminaProduzione() {
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "gestiscibobina",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: this.work_order_sel.id_commessa_dettaglio,
          quantita: this.percentuale_avanzo,
          id_commessa: this.work_order_sel.id_commessa,
          id_articolo_wo: this.work_order_sel.id_articolo,
          tipo_operazione: "s",
          id_macchina: this.work_order_sel.id_macchina,
          cod_lotto: this.work_order_sel.cod_lotto,
        },
      };

      return this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            // Resetto le variabili di supporto
            this.resetProperties();

            /* Emetto un evento per segnalare
             * al componente padre di aggiornarsi */
            this.$emit("terminata_produzione");
          } else {
            // Stampo una snackbar di errore
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );

          // Fermo l'overlay di caricamento.
          this.caricamento_dati = false;
        });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>