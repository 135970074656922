<template>
    <GraphDialog ref="dialog" title="Modifica Cartella" >
        <input type="text" placeholder="Nome Cartella" style="width:350px;display:block" v-model="name" />
        <h4 class="graph_folder_image_select_title">Seleziona icona cartella</h4>
        <section class="graph_folder_image_select" :key="key">
            <div v-for="(image,i) in images" :key="i" class="clickable" :data-checked="image.checked==true" @click="check(image)">          
                <img :src="image.contenuto" />  
                <span>{{image.nome}}</span>
            </div>
        </section>
        <GraphButton text="Salva Modifiche" style="float:right;margin-top:25px" @click="save()" />
    </GraphDialog>
</template>
<script>
import GraphButton from "@/components/GRAPH/.common/Buttons/GraphButton.vue";
import GraphDialog from "@/components/GRAPH/.common/Dialog/GraphDialog.vue";
export default {
    name : 'GraphFolderEditor',
    props : {
        images : Array,
        items : Array
    },
    components : {
        GraphButton,
        GraphDialog
    },
    data(){
        return{
            key : 0,
            name : null,
        }
    },
    methods : {
        check(image){
            if(image.checked == true){ 
                image.checked = false;
            }else{
                for(let i=0; i < this.images.length; i++){
                    this.images[i].checked = false;
                }
                image.checked = true;
            }
            this.key++;
        },
        remove(folder){
            this.items.splice(this.items.indexOf(folder),1);
            for(let i = 0; i < folder.data.length; i++){
                this.items.push(folder.data[i]);
            }
        },
        open(item = null){                                    
            if(item == null){
                this.name = null;
                for(let i=0; i < this.images.length; i++){
                    this.images[i].checked = false;
                }
            }else{
                this.name = item.name;
                for(let i=0; i < this.images.length; i++){
                    const image = this.images[i];
                    image.checked = image.id_file == item.image;
                }
            }            
            this.reference = item;
            this.$refs.dialog.display();
        },
        save(){
            let image = null;
            for(let i=0; i < this.images.length; i++){
                const img = this.images[i];
                if(img.checked==true){
                    image = img.id_file;
                    break;
                }
            }
            const folder = this.reference;
            if(folder == null){
                this.items.push({
                    type : 0, 
                    data : new Array(), 
                    name : this.name,
                    image : image
                });
            }else{
                folder.name = this.name;
                folder.image = image;
            }
            this.$refs.dialog.hide();
        },
    }
}
</script>
<style scoped>
    .graph_folder_image_select_title{
        text-align:left;
        margin: 15px 0;
    }
    .graph_folder_image_select{
        position: relative;
        max-height: calc(80vh - 200px);
        text-align:left;
    }
    .graph_folder_image_select > div{
        display: block;
        position: relative;
        width:400px;
        border-radius: 8px;
        cursor: pointer;
        margin: 5px 0;
    }
    .graph_folder_image_select > div:hover{ background-color: rgba(0,0,0,0.1);}
    .graph_folder_image_select > div:active{ background-color: rgba(0,0,0,0.15);}

    .graph_folder_image_select > div[data-checked]{ background-color: #8cb3d9;}

    .graph_folder_image_select > div > img{
        display: inline-block;
        vertical-align: middle;
        height: 100px;
        width: 100px;
        object-fit: contain;
        object-position: center;
    }
    .graph_folder_image_select > div > span{
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-left: 15px;
    }

</style>