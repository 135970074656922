<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text">
        Modifica Dichiarazione
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Ordine -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaModifica" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Modifica</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-2 pa-0">
      <v-container>
        <v-row justify="center">
          <v-col cols="4">
            <v-text-field
              label="Data"
              readonly
              v-model="date_mod_formattata"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="riga_sel.barca"
              label="Barca"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="riga_sel.matricola"
              label="Matricola"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="riga_sel_tmp.ore"
              type="number"
              step="0.5"
              min="0"
              label="Ore"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="4">
            <v-checkbox
              color="success"
              :true-value="1"
              :false-value="0"
              v-model="riga_sel_tmp.rif_prem"
              label="Rif. Prem."
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              color="success"
              :true-value="1"
              :false-value="0"
              v-model="riga_sel_tmp.guscio"
              label="Guscio"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              color="success"
              :true-value="1"
              :false-value="0"
              v-model="riga_sel_tmp.coperta"
              label="Coperta"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="4">
            <v-checkbox
              color="success"
              :true-value="1"
              :false-value="0"
              v-model="riga_sel_tmp.vassoio"
              label="Vassoio"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              color="success"
              :true-value="1"
              :false-value="0"
              v-model="riga_sel_tmp.calzata"
              label="Calzata"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              color="success"
              :true-value="1"
              :false-value="0"
              v-model="riga_sel_tmp.magazzino"
              label="Magazzino"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>

<script>
import moment from "moment/moment";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ModificaDiario",
  components: {
    SnackbarQV,
  },
  props: {
    riga_sel: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      riga_sel_tmp: {},
      // Modal bool
      // Array
    };
  },
  computed: {
    date_mod_formattata() {
      if (this.riga_sel.date_mod != null && this.riga_sel.date_mod != "") {
        return moment(this.riga_sel.date_mod).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.riga_sel_tmp = Object.assign({}, this.riga_sel);
        }
      },
    },
  },
  methods: {
    chiudiDialog() {
      this.$emit("click_chiudi");
    },
    salvaModifica() {
      // Compongo un oggetto adeguato per il salvataggio sul DB.
      const diario_save = Object.assign({}, this.riga_sel_tmp);

      // Eseguo la chiamata.
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setdiario",
          token: localStorage.user_token,
          diario: [diario_save],
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("salva_dichiarazione");
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style> 
