import moment from "moment/moment";

export default class QCQuestion {
    caricamento_immagini = 0;
    accetta_allegati = 0;
    aree = [];
    caratteri_max = 255;
    caratteri_min = 0;
    codice = "";
    des_questionario_domanda = "";
    entry_point = 1;
    frecce = [
      {
        id_questionario: null,
        id_questionario_freccia: moment().valueOf() + 5,
        num_questionario_freccia: moment().valueOf() + 5,
        id_questionario_domanda: moment().valueOf(),
        assegnata: 0,
        punteggio: 100,
      },
    ];
    height = 60;
    id_questionario = null;
    id_questionario_domanda = moment().valueOf();
    id_tipo_domanda = 0;
    id_tipo_esportazione = 0;
    id_tipo_modifica_immagine = 0;
    img_caricata = "";
    link = "";
    mostra_contorno_aree = 0;
    num_questionario_domanda = moment().valueOf();
    parametri = [];
    risposte = [];
    tipologia = "domanda";
    tipo = null;
    width = 120;
    x = 100;
    y = 10;
  
    constructor(arg) {
      if (arg != undefined) {
        this.accetta_allegati =
          arg.accetta_allegati != undefined ? arg.accetta_allegati : 0;
        this.aree = arg.aree != undefined ? arg.aree : [];
        this.caratteri_min =
          arg.caratteri_min != undefined ? arg.caratteri_min : 0;
        this.caratteri_max =
          arg.caratteri_max != undefined ? arg.caratteri_max : 255;
        this.caricamento_immagini =
          arg.caricamento_immagini != undefined ? arg.caricamento_immagini : 0;
        this.des_questionario_domanda =
          arg.des_questionario_domanda != undefined
            ? arg.des_questionario_domanda
            : "";
        this.entry_point = arg.entry_point != undefined ? arg.entry_point : 1;
        this.frecce =
          arg.frecce != undefined
            ? arg.frecce
            : [
                {
                  id_questionario: null,
                  id_questionario_freccia: moment().valueOf() + 5,
                  num_questionario_freccia: moment().valueOf() + 5,
                  id_questionario_domanda: moment().valueOf(),
                  assegnata: 0,
                  punteggio: 100,
                },
              ];
        this.height = arg.height != undefined ? arg.height : 60;
        this.img_caricata = arg.img_caricata != undefined ? arg.img_caricata : "";
        this.id_questionario =
          arg.id_questionario != undefined ? arg.id_questionario : null;
        this.id_questionario_domanda =
          arg.id_questionario_domanda != undefined
            ? arg.id_questionario_domanda
            : moment().valueOf();
        this.id_tipo_domanda =
          arg.id_tipo_domanda != undefined ? arg.id_tipo_domanda : 0;
        this.id_tipo_esportazione =
          arg.id_tipo_esportazione != undefined ? arg.id_tipo_esportazione : 0;
        this.id_tipo_modifica_immagine =
          arg.id_tipo_modifica_immagine != undefined
            ? arg.id_tipo_modifica_immagine
            : 0;
        this.link = arg.link != undefined ? arg.link : "";
        this.mostra_contorno_aree =
          arg.mostra_contorno_aree != undefined ? arg.mostra_contorno_aree : 0;
        this.num_questionario_domanda =
          arg.num_questionario_domanda != undefined
            ? arg.num_questionario_domanda
            : moment().valueOf();
        this.parametri = arg.parametri != undefined ? arg.parametri : [];
        this.risposte = arg.risposte != undefined ? arg.risposte : [];
        this.tipologia = arg.tipologia != undefined ? arg.tipologia : "domanda";
        this.tipo = arg.tipo != undefined ? arg.tipo : null;
        this.codice = arg.codice != undefined ? arg.codice : "";
        this.width = arg.width != undefined ? arg.width : 120;
        this.x = arg.x != undefined ? arg.x : 100;
        this.y = arg.y != undefined ? arg.y : 10;
      } else {
        {
          this.accetta_allegati = 0;
          this.aree = [];
          this.caratteri_min = 0;
          this.caratteri_max = 255;
          this.caricamento_immagini = 0;
          this.codice = "";
          this.des_questionario_domanda = "";
          this.entry_point = 1;
          this.frecce = [
            {
              id_questionario: null,
              id_questionario_freccia: moment().valueOf() + 5,
              num_questionario_freccia: moment().valueOf() + 5,
              id_questionario_domanda: moment().valueOf(),
              assegnata: 0,
              punteggio: 100,
            },
          ];
          this.height = 60;
          this.img_caricata = "";
          this.id_questionario = null;
          this.id_questionario_domanda = moment().valueOf();
          this.id_tipo_domanda = 0;
          this.id_tipo_esportazione = 0;
          this.id_tipo_modifica_immagine = 0;
          this.link = "";
          this.mostra_contorno_aree = 0;
          this.num_questionario_domanda = moment().valueOf();
          this.parametri = [];
          this.risposte = [];
          this.tipo = null;
          this.tipologia = "domanda";
          this.x = 100;
          this.y = 10;
          this.width = 120;
        }
      }
    }
  
    setTitoloNodo() {
      // Genero un titolo da stampare nel nodo del flowchart.
      this.titolo_nodo_flowchart =
        this.id_tipo_domanda == 0
          ? "[T] "
          : this.id_tipo_domanda == 1
          ? "[S] "
          : this.id_tipo_domanda == 2
          ? "[M] "
          : this.id_tipo_domanda == 3
          ? "[I] "
          : "[A] ";
  
      this.titolo_nodo_flowchart += "Risposte: " + this.risposte.length;
    }
  
    setTestoNodo() {
      // Definisco il testo del nodo.
      this.testo_nodo_flowchart = this.des_questionario_domanda;
    }
  }