<template>
  <v-card id="listener_prelievi">
    <!-- Titolo del form -->
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text">Prelievi</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickProsegui()" small v-bind="attrs" v-on="on">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
        </template>
        <span>Conferma</span>
      </v-tooltip> -->
      <v-spacer></v-spacer>
      <v-btn @click="clickChiudi" small>
        <v-icon color="error">mdi-keyboard-backspace</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <!-- Distinta base -->
      <v-row class="mb-10">
        <DistintaBase
          :items_distinta_base="items_distinta_base"
          style="width: 100%"
          :num_min_realizzabile="num_min_realizzabile"
          @prelievo_registrato="setCommessaImpegno"
          @prelievo_multiplo="setCommessaImpegnoMultiplo"
          @ricarica_pagina="reload"
          :fase_sel="fase_sel"
        />
      </v-row>

      <v-divider class="mt-5"></v-divider>

      <!-- Distinta di prelievo -->
      <DistintaDiPrelievo
        :items_distinta_prelievo="filtered_items_distinta_prelievo"
        :fase_sel="fase_sel"
        @prelievo_registrato="setCommessaImpegno"
        @get_prelievi="getPrelieviCommessa"
        :show_add_form="true"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import DistintaBase from "@/components/PRODUCTION/Produzione/DistintaBase.vue";
import DistintaDiPrelievo from "@/components/PRODUCTION/Produzione/DistintaDiPrelievo.vue";
export default {
  name: "Prelievi",
  components: {
    DistintaBase,
    DistintaDiPrelievo,
  },
  props: {
    fase_sel: { type: Object, default: () => {} },
    visibile: { type: Boolean, default: false },
  },
  data() {
    return {
      items_distinta_base: [],
      items_distinta_prelievo: [],
      num_min_realizzabile: 0,
    };
  },
  computed: {
    filtered_items_distinta_prelievo() {
      //debugger
      return this.items_distinta_prelievo.filter((i) => {
        return i.quantita_impegno > null;
      });
    },
  },
  methods: {
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    // clickProsegui() {
    //   this.$emit("click_prosegui");
    // },
    getPrelieviCommessa() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getprelievicommessa",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: this.fase_sel.id_commessa_dettaglio,
          quantita: this.fase_sel.quantita,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.items_distinta_base = res.data.Distinta;
        this.items_distinta_prelievo = res.data.Prelievi;

        // valorizzo la quantità realizzabile con il minimo numero delle qta realizzabili nell'array
        let arr = Array.from(this.items_distinta_base);
        arr.sort(function (a, b) {
          return a.qta_realizzabile - b.qta_realizzabile;
        });
        this.num_min_realizzabile = arr[0].qta_realizzabile;
      });
    },
    setCommessaImpegno(arg) {
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessaimpegno",
          token: localStorage.getItem("user_token"),
          id_commessa: this.fase_sel.id_commessa,
          id_commessa_dettaglio: this.fase_sel.id_commessa_dettaglio,
          id_articolo: this.fase_sel.id_articolo,
          cod_lotto: arg.cod_lotto,
          quantita: arg.qta_prelevata,
          coefficiente: arg.coeff !== 0 ? arg.coeff : undefined,
          // cod_articolo: this.fase_sel.cod_articolo,
          // id_componente: this.items_distinta_base[0].id_articolo,
        },
      };

      console.log(JSON.stringify(request.richiesta));
      this.$store.dispatch("api", request).then(() => {
        this.getPrelieviCommessa();
      });
    },

    setCommessaImpegnoMultiplo(arg) {
      console.log("entrato nella set finale");
      console.log(arg);
      // debugger;
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessaimpegno",
          token: localStorage.getItem("user_token"),
          id_commessa: this.fase_sel.id_commessa,
          id_commessa_dettaglio: this.fase_sel.id_commessa_dettaglio,
          id_articolo: this.fase_sel.id_articolo,
          cod_lotto: arg.cod_lotto,
          quantita: arg.qta_prelevata,
          coefficiente: arg.coeff !== 0 ? arg.coeff : undefined,
        },
      };

      this.$store.dispatch("api", request).then(() => {
        console.log("OK");
      });
    },

    reload() {
      this.getPrelieviCommessa();
    },
  },
  mounted() {
    document
      .getElementById("listener_prelievi")
      .addEventListener("aggiornamento", this.getPrelieviCommessa);
  },
  watch: {
    visibile: {
      immediate: true,
      handler() {
        if (this.visibile === true) {
          this.getPrelieviCommessa();
        }
      },
    },
  },
};
</script>
<style>
</style>