
class Network{

    _allowed_methods = ['GET','POST','PUT','HEAD'];
    _load_state = -1;

    //  URL di default utilizzato se non ne viene specificato uno nei parametri
    _baseURL = ''; 

    _default_options = {
        allowed : [200],
        headers : [{field:'Content-Type',value:'application/json'}],
        method : 'POST',   
        response : {
            parse : true,
            check : true
        },             
        showerrors : true,
        showdialog : true,
        showloading : true        
    }
       
    ui = null;
    constructor(ui){
        this.ui = ui;
        this._getConfig();
    }

    _getConfig(){
        let requestHandler = new XMLHttpRequest();
        requestHandler.open('GET','/config.json');
        this._load_state = 0;
        let that = this;

        requestHandler.onload = function(response){
            try{
                response = JSON.parse(response.target.responseText);
                let refIP = response.config.ip;                
                that._baseURL = refIP + ((refIP[refIP.length-1] == '/')?'':'/') + 'api/';              
                that._load_state = 1;
            }catch(error){
                that._load_state = -1;
            }
        }
        requestHandler.onerror = function(){this._load_state = -1;}
        requestHandler.send();
    }

    

    AjaxRequest(options){

        if(options == undefined){ 
            console.warn('Network: opzioni non valide'); 
            return;
        }

        /** Controllo URL (o controller) **/
        let URL = '';
        if( options.URL!=undefined ){
            URL = options.URL;
        }else if( options.controller!=undefined ){
            URL = this._baseURL + options.controller;
        }else{
            console.warn('Network: nessun URL o Controller specificato');
            return;
        }

        /** Valorizzazione campi undefined con valori di default */
        const default_fields = Object.keys(this._default_options);
        const num_defaults = default_fields.length;
        let field;
        for(let i=0; i < num_defaults; i++){
            field = default_fields[i];
            if(options[field]==undefined){options[field] = this._default_options[field];}  
        }

        /** Controllo metodo **/              
        const method_index = this._allowed_methods.indexOf(options.method.toUpperCase());
        if(method_index<0){
            console.warn('Network: il metodo specificato non è valido');
            return;
        }

        /** Controllo dello stato delle impostazioni (attesa completamento recupero di config.json) **/
        if(this._load_state==0){
            let that = this;
            setTimeout(function(){ that.AjaxRequest(Object.assign({},options)); },500);
            return;
        }else if(this._load_state==-1){
            this._getConfig();
            return;
        }

        let requestHandler = new XMLHttpRequest();
        requestHandler.open(this._allowed_methods[method_index],URL);        
        
        /** Impostazione header chiamata **/
        for(let i=0;i<options.headers.length;i++){ requestHandler.setRequestHeader(options.headers[i].field,options.headers[i].value); }        
        
        /** Impostazione timeout **/
        if( options.timeout!=undefined && options.timeout>0){ requestHandler.timeout=options.timeout; }
        
        /** Impostazione callback **/
        if(options.on!=undefined){
            const that = this;
            const showerrors = options.showerrors == true;
            const parse = options.response.parse == true;
            const check = options.response.check == true;
            
            // Error
            requestHandler.addEventListener('error',function(response){
                if(showerrors){
                    that.ui.code = 0;
                    that.ui.show = true;
                }
                if(options.on.error!=undefined){options.on.error(response);}
            });

            // Abort
            requestHandler.addEventListener('abort',function(response){
                if(showerrors){
                    that.ui.code = 1;
                    that.ui.show = true;
                }
                if(options.on.abort!=undefined){options.on.abort(response);}
            });

            // Load
            const loadExec = function(response){
                if(parse){
                    let parsed = null;
                    try{
                        parsed = JSON.parse(response.currentTarget.responseText);
                        if(parsed.result === undefined){ throw null; }
                    }catch(error){
                        that.ui.code = 2;
                        that.ui.show = true;
                        that.ui.server_msg = {
                            message : 'Non è stato possibile elaborare la riposta del server',
                            detail : {
                                URL : URL,
                                action : options.body.action
                            }
                        };
                        if(options.on.error!=undefined){options.on.error(response);}
                        return;
                    }
                    if(check){
                        if(parsed.response != true){
                            that.ui.code = 2;
                            that.ui.show = true;
                            that.ui.server_msg = {
                                message : parsed.result,
                                detail : {
                                    URL : URL,
                                    action : options.body.action
                                }
                            };
                            if(options.on.error!=undefined){options.on.error(response);}
                            return;
                        }else{
                            if(options.on.load!=undefined){options.on.load(parsed.result);}
                        }
                    }else{
                        if(options.on.load!=undefined){options.on.load(parsed.result);}
                    }
                }else{
                    if(options.on.load!=undefined){options.on.load(response.target.responseText);}
                }
            }

            requestHandler.addEventListener('load',function(response){ 
                if(showerrors){
                    if(options.allowed.indexOf(response.target.status)<0){
                        that.ui.code = response.target.status;
                        that.ui.show = options.showdialog;
                        if(options.on.error!=undefined){options.on.error(response);}
                    }else{
                        loadExec(response);
                    }
                }else{
                    loadExec(response);
                }
            });


            
            if(options.on.loadstart!=undefined&&options.on.loadstart!=null){requestHandler.addEventListener('loadstart',options.on.loadstart);}
            if(options.on.loadend!=undefined&&options.on.loadend!=null){requestHandler.addEventListener('loadend',options.on.loadend);}
            if(options.on.readystatechange!=undefined&&options.on.readystatechange!=null){requestHandler.addEventListener('readystatechange',options.on.readystatechange);}
            if(options.on.progress!=undefined&&options.on.progress!=null){requestHandler.addEventListener('progress',options.on.progress);}
            if(options.on.timeout!=undefined&&options.on.timeout!=null){requestHandler.addEventListener('timeout',options.on.timeout);}
            if(options.on.upload!=undefined&&options.on.upload!=null){
                if(options.on.upload.abort!=undefined&&options.on.upload.abort!=null){requestHandler.upload.addEventListener('abort',options.on.upload.abort);}
                if(options.on.upload.error!=undefined&&options.on.upload.error!=null){requestHandler.upload.addEventListener('error',options.on.upload.error);}
                if(options.on.upload.load!=undefined&&options.on.upload.load!=null){requestHandler.upload.addEventListener('load',options.on.upload.load);}
                if(options.on.upload.loadstart!=undefined&&options.on.upload.loadstart!=null){requestHandler.upload.addEventListener('loadstart',options.on.upload.loadstart);}
                if(options.on.upload.loadend!=undefined&&options.on.upload.loadend!=null){requestHandler.upload.addEventListener('loadend',options.on.upload.loadend);}
                if(options.on.upload.progress!=undefined&&options.on.upload.progress!=null){requestHandler.upload.addEventListener('progress',options.on.upload.progress);}
                if(options.on.upload.timeout!=undefined&&options.on.upload.timeout!=null){requestHandler.upload.addEventListener('timeout',options.on.upload.timeout);}
            }

        }

        if(options.showloading==true){
            if(this.ui.loading_queue < 0){this.ui.loading_queue=0;}
            this.ui.loading_queue++;
            this.ui.show_loader();
            let that = this;
            requestHandler.addEventListener('loadend',function(){
                that.ui.loading_queue--;
                if(that.ui.loading_queue<=0){
                    that.ui.loading_queue=0;
                    that.ui.hide_loader();
                }else{
                    that.ui.show_loader();
                }
            });
        }
        requestHandler.send(JSON.stringify(options.body));        
    }
}
export default Network;