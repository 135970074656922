<template>
<v-app id="inspire">
    <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>

    <v-card class="mt-2">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                <v-tabs
                    v-model="modalTab"
                    grow
                    style="color: black"
                    slider-size="5"
                    slider-color="primary accent-3 "
                    color="black"
                >
                    <v-tab :href="`#utenti_attivi`" style="max-width: 150px"
                    >Utenti Attivi</v-tab >
                    <v-tab :href="`#utenti_disabilitati`" style="max-width: 200px"
                    >Utenti Disabilitati</v-tab>
                    
                </v-tabs>
                <v-tabs-items v-model="modalTab" class="no-transition" touchless>
                    <v-tab-item
                    :value="`utenti_attivi`"
                    transition="false"
                    class="no-transition"
                    >
                        <v-card>
                            <v-card-title>
                                Gestione Utenti Attivi
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Cerca"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            
                            </v-card-title>
                            <v-data-table
                                :height="height"
                                fixed-header
                                :headers="headers"
                                :items="utentivalidi"  
                                :search="search"
                                item-key="id"
                                class="elevation-1"
                                hide-default-footer
                                :items-per-page="12000"
                            >

                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <!--
                                        <v-toolbar-title>Tabella Anagrafica</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        -->
                                        <v-btn
                                        class="ma-2" outlined color="indigo" dark
                                        @click="nuovo_utente()"
                                        >Nuovo Utente
                                              <v-icon
                                                right
                                                dark
                                            >
                                                mdi-account-plus
                                            </v-icon>
                                        </v-btn>
                                    </v-toolbar>

                                        <v-overlay :value="overlay">
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                        </v-overlay>

                                </template>
                                <template  v-slot:[`item.actions`]="{ item }" >                
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined small v-bind="attrs" v-on="on" @click="modifica_utente(item)">
                                                <v-icon color="indigo"
                                                > mdi-account-edit
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Modifica Utente</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined small v-bind="attrs" v-on="on" @click="disabilita_utente(item)">
                                                <v-icon color="red"
                                                > mdi-account-cancel
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Disabilita Utente</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>

                            <!-- Gestione dialog Utenti --> 
                            <v-dialog v-model="dialog_GestioneUtenti" max-width="85%" persistent>
                                <v-card>
                                    <v-toolbar
                                            class="white--text"
                                            color="blue"
                                            dense
                                            style="position: sticky; top: 0px; z-index: 1"
                                            >
                                            <v-toolbar-title> {{this.tipo_azione}} </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="red--text"
                                                @click="dialog_GestioneUtenti = false"
                                                color="blue-grey lighten-5"
                                                small
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>

                                            <GestioneUtente
                                                :ID_UTENTE= this.id_utente_selezionato
                                                v-on:gestioneutente="valori_di_ritorno_utenti"                                                        
                                            /> 
                                </v-card>
                            </v-dialog>                            

                
                        </v-card>
                    </v-tab-item>

                    <v-tab-item
                    :value="`utenti_disabilitati`"
                    transition="false"
                    class="no-transition"
                    >
                        <v-card>
                        <v-card-title>
                            Gestione Utenti Disabilitati
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Cerca"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-card-title>

                        <v-data-table
                            :headers="headers"
                            :items="utenti_disabilitati"  
                            :search="search"
                            item-key="id"
                            class="elevation-1"
                            hide-default-footer
                        :items-per-page="12000"
                        >
                        
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <!--
                                    <v-toolbar-title>Tabella Anagrafica</v-toolbar-title>
                                    
                                    <v-spacer></v-spacer>
                                     -->
                                   
                                </v-toolbar>

                                    <v-overlay :value="overlay">
                                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>

                            </template>
                            <template  v-slot:[`item.actions`]="{ item }" > 
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn outlined small v-bind="attrs" v-on="on" @click="abilita_utente(item)">
                                        <v-icon color="green"
                                        > mdi-account-check
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Abilita Utente</span>
                            </v-tooltip>

                            </template>

                        </v-data-table>

                        </v-card>
                    </v-tab-item>

                </v-tabs-items>
                </v-col>
            </v-row>

        </v-card-text>

    </v-card>


</v-app>
</template>

<style scoped>
 
    th {
    font-size: 1.1rem;
    }
</style>

<script>

    import GestioneUtente from "./GestioneUtente.vue";
    export default {
    components: {
        GestioneUtente
    }, 
       name: "Utente",
        data() {
            return{
                modalTab: "utenti_attivi",
                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",


                dialog_GestioneUtenti: false,
                height: 800,
                tipo_azione:'',
                id_utente_selezionato: 0,


                overlay: false,

                token : '',
                search: '',
                headers: [
                    { text: 'Nome', value: 'nome', sortable: true },
                    { text: 'Cognome', value: 'cognome' },
                    { text: 'Inquadramento', value: 'des_inquadramento' },
                    { text: 'Qualifica', value: 'des_qualifica' },
                    { text: 'Sede', value: 'des_sede' },
                    { text: 'Dispositivo', value: 'des_dispositivo' },
                    { text: 'Azioni', value: 'actions', sortable: false, width:'10%' },
                ],
                utenti: [],
                utente: { id_personale: '', nome: '', cognome: '', id_azienda: '', id_sede: '', tipo_cartellino: '', des_inquadramento: '', des_qualifica: '', des_sede: '', des_dispositivo: '', validita: '' },

            }
        },
        computed: {
            utentivalidi() {
                return this.utenti.filter((i) => {
                    return (i.validita === null) || (i.validita === 1) || (i.validita === "");
                })
            },
            utenti_disabilitati() {
                return this.utenti.filter((i) => {
                    return (i.validita === 0) ;
                })
            },

        },
        mounted() {
            this.getData()
        },
        methods: {
            getData(){
                this.overlay = true
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                        action: "getpersonaleall",
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {   
                    this.utenti = res.data
                    this.overlay = false   
                });
            },

            valori_di_ritorno_utenti(){
                //necessario reload dell'id selezionato e chiusura dialog per ricaricare il salvataggio fatto nel componente
                //if(value == "") console.log("")
               // console.log("Salva da qui!")
                this.getData()
                this.dialog_GestioneUtenti =  false
            },

            nuovo_utente(){
                this.tipo_azione = 'Nuovo Utente'
                this.dialog_GestioneUtenti = true  
                this.id_utente_selezionato = this.ricarica = Math.round(Math.random()*100)+99999
            },

            modifica_utente(item){
                this.tipo_azione = 'Modifica Utente'
                this.dialog_GestioneUtenti = true  
                this.id_utente_selezionato = item.id_personale
            },
            disabilita_utente(item){
                this.overlay = true
                let request = {
                    controller: "Presenze",
                    method: "PUT",
                    richiesta: {
                        action: "setvaliditapersonale",
                        id_personale: item.id_personale,
                        validita: 0 ,
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {   
                    if (res.status == 200){
                        this.getData()
                        this.overlay = false   
                    }
                });
            },
            abilita_utente(item){
                this.overlay = true
                let request = {
                    controller: "Presenze",
                    method: "PUT",
                    richiesta: {
                        action: "setvaliditapersonale",
                        id_personale: item.id_personale,
                        validita: 1 ,
                        token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {   
                    if (res.status == 200){
                        this.getData()
                        this.overlay = false  
                    } 
                });
            },
        }
    }
</script>
