<template>
  <v-card>
    <v-toolbar color="indigo" dense>
      <v-toolbar-title class="white--text"> Subentro </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- Bottone per l'invio di username e password -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickInviaCredenziali" v-bind="attrs" v-on="on">
            <v-icon color="success" large> mdi-check </v-icon>
          </v-btn>
        </template>
        <span>Invia</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <!-- Bottone di chiusura -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="clickChiudi" v-bind="attrs" v-on="on">
            <v-icon color="error" large> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-5">
      <v-card outlined>
        <v-card-text>
          <span style="font-size: 20px">
            Immettere le credenziali del nuovo operatore per registrare il
            subentro
          </span>
          <v-container>
            <v-row align="center" justify="center">
              <v-col align="center" cols="12" justify="center">
                <!-- Text field dello username -->
                <v-text-field
                  background-color="#ffffff"
                  class="input-class centered-input"
                  dense
                  height="50px"
                  hide-details
                  label="Username"
                  style="
                    display: inline-block;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 25px;
                    padding: 0px;
                    min-width: 150px;
                  "
                  v-model="username"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col align="center" cols="12" justify="center">
                <!-- Text field della password -->
                <v-text-field
                  background-color="#ffffff"
                  class="input-class centered-input"
                  dense
                  height="50px"
                  hide-details
                  label="Password"
                  style="
                    display: inline-block;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 25px;
                    padding: 0px;
                    min-width: 150px;
                  "
                  type="password"
                  v-model="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-container>
        <v-row align="center" class="mt-2" justify="center">
          <v-col align="center" cols="12" justify="center">
            <!-- Bottone per attivare il lettore di QR code -->
            <v-btn @click="clickQRCode" icon>
              <v-icon color="primary" x-large> mdi-qrcode-scan </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Dialog del QR code reader -->
    <v-dialog v-model="qr_reader_visible" persistent>
      <v-toolbar>
        <v-toolbar-title> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="handleCloseQRDialog" v-bind="attrs" v-on="on">
              <v-icon color="error" large> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Chiudi</span>
        </v-tooltip>
      </v-toolbar>
      <qrcode-stream
        :camera="camera"
        @decode="handleOnDecode"
      ></qrcode-stream>
    </v-dialog>

    <!-- Overlay di caricamento -->
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "CambioBobina",
  components: { SnackbarQV },
  props: {
    work_order_sel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      password: "",
      username: "",

      // Modal
      qr_reader_visible: false,
      camera: "off",
      caricamento_dati: false,

      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    clickChiudi() {
      this.resetProperties();
      this.$emit("click_chiudi");
    },
    clickInviaCredenziali() {
      // Controllo che username e password siano specificati
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined ||
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.SnackbarQV.print("error", "Valorizzare username e password");
        return;
      }

      /* Controllo che il nome utente sia valido 
       * (ovvero che contenga il noe di un DB). */
      if (!this.username.includes("/")) {
        this.SnackbarQV.print("error", "Username non valido.");
        return;
      }

      const username_tmp = this.username.split("/")[1];
      const prefisso_utente_tmp = this.username.split("/")[0];

      const request = {
        controller: "Token",
        method: "POST",
        richiesta: {
          username: username_tmp,
          password: this.password,
          prefisso_utente: prefisso_utente_tmp,
        },
      };

      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("user_token",res.data.jwt);

            // Faccio la chiamata setsubentro
            const request = {
              controller: "Magazzino",
              method: "PUT",
              richiesta: {
                action: "setsubentro",
                token: localStorage.getItem("user_token"),
                id_utente_old: this.work_order_sel.id_personale,
                id_utente_new: res.data.id,
                id_commessa: this.work_order_sel.id_commessa,
                id_commessa_dettaglio: this.work_order_sel
                  .id_commessa_dettaglio,
                id_macchina:
                  localStorage.getItem("id_macchina") != null
                    ? localStorage.getItem("id_macchina")
                    : 65,
              },
            };

            this.$store
              .dispatch("api", request)
              .then((res2) => {
                if (res2.status === 200) {
                  this.resetProperties();
                  this.$emit("credenziali_inviate");
                } else {
                  this.$refs.SnackbarQV.print(
                    "error",
                    `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
                  );
                }
              })
              .catch((err) => {
                // Stampo una snackbar di errore
                this.$refs.SnackbarQV.print(
                  "orange",
                  `${JSON.parse(err.response.data.message).result}.`
                );
              });
          } else {
            this.$refs.SnackbarQV.print(
              "error",
              `Non è stato possibile caricare i dati, codice errore: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          // Stampo una snackbar di errore
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    clickQRCode() {
      this.qr_reader_visible = true;
      this.camera = "auto";
    },
    handleCloseQRDialog() {
      this.qr_reader_visible = false;
      this.camera = "off";
    },
    handleOnDecode(arg) {
      this.username = arg.parse('(Q)')[1].parse(";")[0];
      this.password = arg.parse('(H)')[1].parse(";")[0];
      this.camera = "off";
      this.qr_reader_visible = false;
      
      this.clickInviaCredenziali();
    },
    resetProperties() {
      this.username = "";
      this.password = "";
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>