class WIDGET{
    resize_active = -1;
    move_active = -1;
    move_offset_X = -1;
    move_offset_Y = -1;
    scale = null;

    resize(event) {
        if (this.resize_active < 0) { return; }
        if(this.scale==null){this.scale=Number(document.getElementById('graph_dashboard_zoom').value)/100;}
        let element = document.getElementById('widget-' + this.resize_active);
        let rect = element.getBoundingClientRect();
        let width = (Number(event.clientX) - Number(rect.x)) / this.scale;
        if (width < 100) { width = 100; }
        let height = (Number(event.clientY) - Number(rect.y)) / this.scale;
        if (height < 100) { height = 100; }

        requestAnimationFrame(function () {
            element.style.width = width.toString() + 'px';
            element.style.height = height.toString() + 'px';            
        })
    }    

    start_moving(index,event){
        if(isNaN(index)||index<0){return;}
        let widget = document.getElementById('widget-' + index);
        this.move_active = index;
        this.setZ();
        this.move_offset_X = event.clientX - widget.getBoundingClientRect().x;
        this.move_offset_Y = event.clientY - widget.getBoundingClientRect().y;
        
    }
    move(event){
        if (this.move_active < 0) { return; }
        let widget = document.getElementById('widget-' + this.move_active);
        let dashboard = widget.parentElement;

        if(this.scale==null){this.scale=Number(document.getElementById('graph_dashboard_zoom').value)/100;}

        let x = (event.clientX - dashboard.getBoundingClientRect().x + dashboard.scrollLeft - this.move_offset_X) / this.scale;
        let y = (event.clientY - dashboard.getBoundingClientRect().y + dashboard.scrollTop - this.move_offset_Y) / this.scale;

        if(x>0){
            widget.style.left = x + 'px';
        }else{
            this.move_offset_X = Math.max(event.clientX - widget.getBoundingClientRect().x,0);
        }
        if(y>0){widget.style.top = y + 'px';}        
    }
    setZ() {
        let index;
        if (this.move_active >= 0) {
            index = this.move_active;
        } else if (this.resize_active >= 0) {
            index = this.resize_active;
        } else {
            return;
        }
        let widget = document.getElementById('widget-' + index);
        requestAnimationFrame(function () {
            let clone = widget.getElementsByClassName('table_clone')[0];
            if(clone!=null&&clone!=undefined){
                clone.style.left = '0';
            }
            let parent = widget.parentElement;
            parent.removeChild(widget);
            parent.appendChild(widget);
        });
    }
    stop() {
        let index = this.resize_active;
        this.resize_active = -1;
        this.move_active = -1;
        this.move_offset_X = null;
        this.move_offset_Y = null;
        this.scale=null;
        let dashboard = document.getElementById("dashboard");
        if(dashboard != null && dashboard != undefined){dashboard.style.pointerEvents = "all";}
        return index;
    }
}
export default WIDGET;