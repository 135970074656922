<template>
  <v-app id="inspire">   
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
        <!-- v-navigation-drawer con Tree in funzione dei dati ricevuti dal json -->   
        <v-navigation-drawer
              class=" pt-1 "
              app
              v-model="drawer"
              :mini-variant.sync="mini"
              mini-variant-width= 85
              width="370"
              permanent
              >
              <v-card class="mx-2 mt-2">
                <v-list-item class="px-2">
                  <v-btn v-if="mini"
                    icon
                  >
                    <v-icon>mdi-menu</v-icon>
                  </v-btn> 
                    <v-list-item-title v-if="!mini">Menù</v-list-item-title>
                  <v-btn v-if="!mini"
                    icon
                    @click.stop="mini = !mini"
                  >
                    <v-icon>mdi mdi-menu-open</v-icon>
                  </v-btn>                              
                </v-list-item>
              </v-card> 

                <v-divider ></v-divider>
                <v-container v-if="!mini">
                    <v-text-field
                        v-model="search"
                        label="Cerca ..."
                        outlined
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                    <v-divider vertical ></v-divider>
                    <!-- Gestione Treeview: Utilizzo della v-card per la gestione della scroll bar nel menu del treeview-->
                    <v-card
                          class="overflow-y-auto"
                          height="size_height"
                          max-height="1010"
                      >
                        <v-treeview
                            v-model="tree"
                            :active.sync="active"
                            :open.sync="open"
                            :items="items"
                            activatable
                            transition
                            dense
                            item-key="name"
                            :search="search"
                            :filter="filter"
                        >
                            <template v-slot:prepend="{ item, open }">
                                <v-icon v-if="!item.file">
                                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                </v-icon>
                                <v-icon v-else>
                                    {{ files[item.file] }}
                                </v-icon>
                            </template>

                        </v-treeview> 
                    </v-card> 
                    <v-divider > </v-divider>


                </v-container> 
        </v-navigation-drawer>


        <v-main> 
          <v-container class=" px-2 " fluid>
              <template v-if="!selected"> <!-- Nessuna sezione nodi figli: solo toolbar--> 
                    <v-toolbar dense  color="white" elevation="1">
                          <GlobalToolBar 
                              :NOME_AZIENDA = this.nome_azienda
                              :NOME_UTENTE = this.nome_utente  
                              :DES_PROFILO = this.des_profilo
                          /> 
                    </v-toolbar>                         
              </template>
              <template v-else> 
                  <template v-if="!parentNode">  <!-- Sezione nodi figli -->   
                    <v-toolbar dense  color="white" elevation="1">
                          <GlobalToolBar 
                              :NOME_AZIENDA = this.nome_azienda
                              :NOME_UTENTE = this.nome_utente  
                              :DES_PROFILO = this.des_profilo
                          /> 
                    </v-toolbar>                      
                    <!-- Aggancio il componenete Documento la voce se il nome componenete inviato dal Backend è = in questo esempio a 'Comp_Fornitori' -->
                    
                    <div v-if="this.component_name == 'ListaArticoli'"> 
                      <ListaArticoli 
                          :PROPRIETA="this.component_prop"
                          :VOCE_MENU="this.menu_name"
                          :NOME_COMPONENTE=this.component_name
                      /> 
                    </div>  
                    <div v-if="this.component_name == 'GestioneInventario'"> 
                      <GestioneInventario 
                          :PROPRIETA="this.component_prop"
                          :VOCE_MENU="this.menu_name"
                          :NOME_COMPONENTE=this.component_name
                      /> 
                    </div>  

                  </template>
                  <template v-else>   <!-- Sezione Nodi  -->  
                    <v-toolbar dense>
                      <v-toolbar-title>Gestione Accessi Rifiuti</v-toolbar-title>
                      <v-spacer></v-spacer>


                      <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                          <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-btn  small v-bind="attrs" v-on="on" @click="chiudi_finestra()">
                                      <v-icon color="red"
                                          >mdi mdi-close
                                      </v-icon>
                                      
                                  </v-btn>
                              </template>
                              <span>Chiudi Finestra</span>
                          </v-tooltip>                                 
                    </v-toolbar>  

                  </template>    

              </template>
          </v-container>
        </v-main>
  </v-app>

</template>

<style>
    /* non usare style scoped*/
    .v-treeview--dense .v-treeview-node__root {
        text-align: left;
        font-size: 16px;
    }

    #mytable table {
        width: auto;
        line-height: 1;
        font-size: 16px;
        }
        html ::-webkit-scrollbar {
            width: 2px;
            display: block; 
        }
        ::-webkit-scrollbar {
            width: 2px;
        }
        ::-webkit-scrollbar-thumb {
            background: rgb(221, 221, 221); 
        }
        ::-webkit-scrollbar-track {
            background: rgb(221, 221, 221); 
        }
</style>

<script>
    import GlobalToolBar from "@/components/GlobalToolBar.vue";    
    import GestioneInventario from "./components/GestioneInventario.vue";    
    import ListaArticoli from "./components/listaArticoli.vue";  
    export default {
        components: {
            GestioneInventario,
            ListaArticoli,
            GlobalToolBar,
        },    

        data: () => ({
            snackbar: false,
            snackbar_color: "",
            snackbar_text: "",
            overlay: false,
            nome_azienda: "Utente",
            des_profilo: localStorage.des_profilo,
            nome_utente: localStorage.nominativo,

            modifica: 0, 

            date: new Date().toISOString().substr(0, 7),
            data_selezionata: '',
            mese_selezionato: '',
            anno_selezionato: '',

            menu: false,
            
            

            // Visualizzazione e Gestione Tree
            search: null,
            caseSensitive: false,
            active: [],
            open: [],
          


            parentNode: false,

            //navigation-drawer
            mini: false,
            drawer: true,

            //Gestiore Icone Tree
            files: {
                html: 'mdi-language-html5',
                js: 'mdi-nodejs',
                json: 'mdi-code-json',
                md: 'mdi-language-markdown',
                pdf: 'mdi-file-pdf',
                png: 'mdi-file-image',
                txt: 'mdi-file-document-outline',
                xls: 'mdi-file-excel',
                utenti: 'mdi-account',
                ico_doc:'mdi mdi-vuejs',
            },
            preferiti:[],
            selectedItemPreferiti:null,
            voce_menu_preferito: '',

            preferito: false,
            tree:[],
            items:[],
            nodi:[],
            leaf:[],
            id_nodo: null,
            id_leaf: null,      
            menu_name: null,     
            component_name: null,
            component_prop: null,   
        }),

        computed: {
          filter () {
              return this.caseSensitive
              ? (item, search, textKey) => item[textKey].indexOf(search) > -1
              : undefined
          },
          selected () {
              if (!this.active.length && !this.voce_menu_preferito.length) return undefined
              const voce_menu = this.voce_menu_preferito.length > 0 ? this.voce_menu_preferito : this.active[0]
              if (this.check_parent (voce_menu)){
                  this.apri_parent_page()
              }else{
                  this.find_leaf_tree (voce_menu)
                  this.apri_component_page()
              } 
              //console.log("Voce Menu",voce_menu )
              
              return voce_menu
              
          },
        },
        watch: {
        },

        mounted() {
            this.permitsCheck()
            this.gettree()
        },
        methods: {

            permitsCheck(){              
              var request = {
                  richiesta: {
                    moduli: localStorage.menu,
                    modulo: window.location.pathname,
                  },
                };
                this.$store.dispatch("modulepermits", request).then((res) => {
                    //console.log("Variabile globake di stato", this.$store.state.permesso_accesso_modulo)
                    //console.log("Ritorno",res)
                    if(!res) this.$router.push('/home/dashboard')
                });              
            },

            gettree(){
             //let tree = {tree:[
              
              this.items = [
              {
                id: 4000,
                name: 'Inventario',
                children: 
                [               
                 {
                    id: 1,
                    name: 'Lista Articoli',
                    file: 'ico_doc',
                    componente: 'ListaArticoli',
                    prop:[{
                      p1: 'v1',
                      p2: 'v2'
                    }],
                  },   
                 {
                    id: 2,
                    name: 'Gestione Inventario',
                    file: 'ico_doc',
                    componente: 'GestioneInventario',
                    prop:[{
                      p1: 'v1',
                      p2: 'v2'
                    }],
                  },                                  

                ],
              },

              ];
              //]};


              //console.log("TREE:",this.items)
              this.map_tree (this.items)
              //console.log ("nodi", this.nodi)
              //console.log ("leaf", this.leaf)
            },

            map_tree(array_tree){
                var h_albero = array_tree.length
                if (h_albero > 0 ){
                    for (var i = 0; i <=h_albero - 1; i++){
                        if('children' in array_tree[i]) 
                        {
                          if (array_tree[i].children.length > 0)
                          {//Popolo l'array dei nodi
                            this.nodi.push ({  
                                name: array_tree[i].name,  
                                id: array_tree[i].id,
                            });
                            this.map_tree(array_tree[i].children)
                          }                           
                        }
                        else{// does not exist --> leaf Popolo l'array foglie
                            this.leaf.push ({  
                                name: array_tree[i].name,  
                                id: array_tree[i].id,
                                file: array_tree[i].file,
                                componente: array_tree[i].componente,
                                prop: array_tree[i].prop,                              
                            });
                            
                        }                           
                    }
                }
            },

            find_leaf_tree(voce_menu){
                this.leaf.forEach(tree_leaf => {
                    if (voce_menu == tree_leaf.name) {
                          this.id_leaf = tree_leaf.id
                          this.menu_name = tree_leaf.name
                          this.component_name = tree_leaf.componente
                          this.component_prop = tree_leaf.prop
                    }
                });
                //console.log( "Voci Leaf",this.menu_name,this.component_name,this.component_prop )
            },

            check_parent(nodo){
                //Controllo che il nodo sia = al nome della voce di menu 
                var ritorno = false
                this.nodi.forEach(tree_node => {
                    
                    if (nodo == tree_node.name) {
                            this.id_nodo = tree_node.id
                            ritorno = true
                    }
                });
                return ritorno 
            },
            apri_parent_page(){
                //Se parentNode = true si aprirà la pagina del nodo ... se necessario.
                this.parentNode = true
            },
            apri_component_page(){
                this.parentNode = false
                this.voce_menu_preferito = '' //azzero/inizializzo la scelta della voce menu preferiti altrimrnti la variabile computed select rimane bloccata  sul preferito non considerando le voci active[0] del tree 
            },            

            getNow() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            },

            aggiungi_preferiti(){

                let trovato = false
                this.preferiti.forEach(preferito => {
                    if (this.menu_name == preferito.name) 
                      trovato = true
                }); 
                if(!trovato){  
                  this.preferiti.push ({  
                      name: this.menu_name,  
                      id: this.id_leaf,
                      componenete: this.component_name,
                      prop: this.component_prop,
                  });             
                }
            },

            cancella_da_preferiti(){ 
                //console.log("cancella da preferiti", this.selectedItemPreferiti)
                let index = this.preferiti.findIndex(item => item.id === this.selectedItemPreferiti)
                this.preferiti.splice(index, 1)
            },

            apri_preferito(){
              console.log("Apri preferito")
              //L'apertura del componente avviene settando i valori id, name, nome componente e prop che abiliteranno il div (con v-if) del relativo compomente
              this.id_leaf =  this.preferiti[this.selectedItemPreferiti].id     
              this.menu_name =  this.preferiti[this.selectedItemPreferiti].name     
              this.component_name =  this.preferiti[this.selectedItemPreferiti].componenete
              this.component_prop =  this.preferiti[this.selectedItemPreferiti].prop

              this.parentNode = false
              this.voce_menu_preferito =  this.menu_name
               //devo inizializzare la variabile computed Select con il nome della voce di menu del tree per aprire la sezione componeneti nella pagina.
            },


            chiudi_finestra(){
              this.$router.push("/home/dashboard");
            },

        },
    };
</script>