<template>
  <div id="listener_distinta_di_prelievo">
    <v-row>
      <v-data-table
        class="elevation-1"
        :headers="headers_distinta_prelievo"
        :items="items_distinta_prelievo"
        style="width: 100%"
      >
        <!-- Titolo tabella -->
        <template v-slot:top>
          <v-toolbar class="white--text" color="indigo" dense>
            <v-toolbar-title>Distinta di Prelievo</v-toolbar-title>
          </v-toolbar>
          <!-- Inserimento del nuovo componente nella distinta di prelievo -->
          <v-container>
            <v-row align="center" justify="center" v-if="show_add_form">
              <v-col cols="2">
                <v-checkbox
                  v-model="componente_extra"
                  :label="`Componente extra`"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" v-if="componente_extra">
                <v-text-field
                  background-color="#ffffff"
                  class="pa-1 ml-1 my-1"
                  dense
                  hide-details
                  label="Coefficiente"
                  style="display: inline-block; max-width: 350px"
                  v-model="coefficiente_tmp"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  background-color="#ffffff"
                  class="pa-1 my-1"
                  dense
                  hide-details
                  label="Codice Lotto"
                  style="display: inline-block; max-width: 350px"
                  v-model="lotto_tmp"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  background-color="#ffffff"
                  class="pa-1 ml-1 my-1"
                  dense
                  :disabled="lotto_tmp == ''"
                  hide-details
                  label="Numero Pezzi"
                  style="display: inline-block; max-width: 350px"
                  type="number"
                  v-model="numero_pezzi_tmp"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="addComponentePrelevato"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi Prelievo</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <!-- Bottone Rimuovi -->
        <template v-slot:[`item.rimuovi`]="{ item }">
          <v-btn
            @click="clickRimuovi(item)"
            small
            :disabled="
              item.quantita_prelevato !== 0 || item.quantita_impegno <= 0
            "
          >
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </template>

        <!-- Bottone Scarti -->
        <template v-slot:[`item.scarto`]="{ item }">
          <v-btn @click="clickScarta(item)" small>
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn>
        </template>

        <!-- Bottone Reso -->
        <template v-slot:[`item.reso`]="{ item }">
          <v-btn
            @click="clickReso(item)"
            small
            :disabled="item.quantita_impegno - item.quantita_prelevato <= 0"
          >
            <v-icon color="primary">mdi-restore</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-row>

    <!-- SNACKBAR -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- DIALOG DI RESO -->
    <v-dialog v-model="dialog_reso" width="25%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Seleziona il reso</v-toolbar-title
          >
        </v-toolbar>
        <v-row justify="center">
          <v-col cols="6">
            <v-text-field
              class="ma-2"
              type="number"
              label="Quantità"
              min="0"
              :max="max_num"
              v-model="numero_reso"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0" justify="center" align="center">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="chiudiDialogReso()"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" @click="reso()">
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG DI CANCELLAZIONE -->
    <v-dialog v-model="dialog_elimina" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          Sei sicuro di voler eliminare la quantità impegnata?
        </v-card-text>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickChiudiElimina"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cancellaQtaImpegno(item_sel)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG DI SCARTO -->
    <v-dialog v-model="dialog_scarto" width="70%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Quantità da scartare</v-toolbar-title
          >
        </v-toolbar>
        <v-row justify="center" align="center" class="mt-2">
          <v-col cols="2">
            <v-text-field
              type="number"
              label="Quantità"
              min="0"
              :max="max_num"
              v-model="numero_scarto"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              item-text="des"
              item-value="id_tabella"
              :items="causali_non_conformita"
              label="Causale non conformità"
              v-model="causale_non_conformita_sel"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-btn @click="clickFotocamera">
              <v-icon color="primary">mdi-camera</v-icon>
            </v-btn>
          </v-col>
          <!-- Spazio per la miniatura della foto da webcam -->
          <v-col cols="1" v-show="img_in_uso != ''">
            <div
              :id="id_commessa_associalotti_sel"
              style="
                background-repeat: no-repeat;
                background-size: 100% 100%;
                height: 100px;

                width: 100px;
              "
            ></div>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0" justify="center" align="center">
          <v-col cols="6" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="chiudiDialogScarto()"
                >
                  <v-icon color="error"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Annulla</span>
            </v-tooltip>
          </v-col>

          <v-col cols="6" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" @click="scarto()">
                  <v-icon color="success"> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Conferma</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
      
      <!-- Dialog della webcam della qualità -->
      <div
        style="
          background-color: rgba(33, 33, 33, 0.46);
          bottom: 0;
          height: 100%;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 5;
        "
        v-if="controllo_webcam_visible"
      >
        <div
          style="height: 90%; left: 5%; position: absolute; top: 5%; width: 90%"
        >
          <Controllo
            @click_indietro="controllo_webcam_visible = false"
            :destinazione="'listener_distinta_di_prelievo'"
            :id_articolo="fase_sel.id_articolo"
            :riferimento="id_commessa_associalotti_sel"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import Controllo from "@/views/PRODUCTION/Controllo/Controllo.vue";

export default {
  name: "DistintaDiPrelievo",
  components: {
    Controllo,
  },
  props: {
    fase_sel: { type: Object, default: () => {} },
    show_add_form: {
      type: Boolean,
      default: false,
    },
    items_distinta_prelievo: { type: Array, default: () => [] },
  },
  data() {
    return {
      // Variabili d'appoggio
      causale_non_conformita_sel: null,
      img_in_uso: "",
      id_commessa_associalotti_sel: null,
      item_sel: null,
      max_num: null,
      coefficiente_tmp: 0,
      componente_extra: false,
      lotto_tmp: "",
      numero_pezzi_tmp: null,
      numero_reso: null,
      numero_scarto: null,

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      dialog_reso: false,
      dialog_scarto: false,
      dialog_elimina: false,
      controllo_webcam_visible: false,

      // Array
      causali_non_conformita: [],
      headers_distinta_prelievo: [
        { text: "Rimuovi", sortable: false, value: "rimuovi" },
        { text: "Lotto", sortable: false, value: "cod_lotto" },
        { text: "Cod. Comp.", sortable: false, value: "cod_articolo" },
        { text: "Des. Comp.", sortable: false, value: "des_articolo" },
        {
          text: "Quantità Impegnata",
          sortable: false,
          value: "quantita_impegno",
        },
        { text: "Scarto", sortable: false, value: "scarto" },
        {
          text: "Quantità Prelevata",
          sortable: false,
          value: "quantita_prelevato",
        },
        { text: "Ubicazione", sortable: false, value: "des_ubicazione" },
        { text: "Magazzino", sortable: false, value: "des_magazzino" },
        { text: "Reso", sortable: false, value: "reso" },
      ],
    };
  },
  methods: {
    addComponentePrelevato() {
      // Controllo che i componenti esterni abbiano il coefficiente assegnato.
      if (
        (this.componente_extra === true && this.coefficiente_tmp > 0) ||
        (this.componente_extra === false && this.coefficiente_tmp === 0) ||
        (this.componente_extra === false && this.coefficiente_tmp == null)
      ) {
        // Controllo che i campi siano valorizzati
        if (this.lotto_tmp != "" && this.numero_pezzi_tmp > 0) {
          // Se è tutto a posto, segno i dati del prelievo in un oggetto
          let componente_prelevato = {
            cod_lotto: this.lotto_tmp,
            qta_prelevata: this.numero_pezzi_tmp,
            coeff: this.coefficiente_tmp,
          };

          /* Emetto un evento così il padre usa questi dati
           * per effettuare una chiamata al BE. */
          this.$emit("prelievo_registrato", componente_prelevato);

          this.lotto_tmp = null;
          this.numero_pezzi_tmp = null;
          this.coefficiente_tmp = null;
        } else {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            'Entrambi i campi "Codice Lotto" e "Numero Pezzi" devono essere valorizzati.';
          this.snackbar_text_color = "white";
        }
      } else {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "Il coefficiente dei componenti extra deve essere superiore a 0.";
        this.snackbar_text_color = "white";
      }
    },
    aggiungiImg(messaggio) {
      console.log("messaggio ricevuto" + messaggio);
      /* Recupero l'immagine nel messaggio
       * che è un base64 non splittato. */
      let photo = messaggio.detail.img;

      // Uso il base64 per valorizzare la src di un oggetto immagine
      let img = new Image();

      img.onload = function () {
        /* Ora ho un'immagine di cui posso conoscere altezza e larghezza,
         * e dimensiono il div in maniera da accoglierla senza stretcharla.
         * Voglio che le dimensioni dell'immagine non siano mai
         * superiori a 100 px; se lo sono scalo le dimesioni.*/
        if (img.width > img.height && img.width > 100) {
          let width_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let height_nuova = (width_nuova * img.height) / img.width;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else if (img.height > img.width && img.height > 100) {
          let height_nuova = 100;
          // Proporzione -> width_nuova : img.width = height_nuova : img.height
          let width_nuova = (height_nuova * img.width) / img.height;
          document.getElementById(messaggio.detail.id).style.width =
            width_nuova + "px";
          document.getElementById(messaggio.detail.id).style.height =
            height_nuova + "px";
        } else {
          document.getElementById(messaggio.detail.id).style.width =
            img.width + "px";
          document.getElementById(messaggio.detail.id).style.height =
            img.height + "px";
        }
      };

      img.src = photo;

      document.getElementById(messaggio.detail.id).style.backgroundImage =
        "url(" + img.src + ")";
    },
    clickFotocamera() {
      this.controllo_webcam_visible = true;
    },
    clickRimuovi(item) {
      console.log(JSON.stringify(item));
      this.item_sel = Object.assign({}, item);
      console.log("rimuovi");
      this.dialog_elimina = true;
    },
    cancellaQtaImpegno(item_sel) {
      this.setRettificaImpegno(item_sel, "d");

      this.dialog_elimina = false;
      this.item_sel = null;
    },
    clickChiudiElimina() {
      this.item_sel = null;
      this.dialog_elimina = false;
    },
    clickReso(item) {
      this.max_num = item.quantita_impegno - item.quantita_prelevato;
      this.item_sel = Object.assign({}, item);
      this.dialog_reso = true;
    },
    clickScarta(item) {
      this.max_num = item.quantita_impegno;
      this.item_sel = Object.assign({}, item);
      this.id_commessa_associalotti_sel = item.id_commessa_associalotti;
      this.dialog_scarto = true;
    },
    reso() {
      if (this.numero_reso > this.max_num) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = `La quantità selezionata non è disponibile. Numero massimo: ${this.max_num}`;
        this.snackbar_text_color = "white";

        this.numero_reso = this.max_num;
        return;
      } else {
        this.dialog_reso = false;
        this.max_num = null;
        this.setRettificaImpegno();
      }
    },
    scarto(item_sel) {
      if (this.numero_scarto > this.max_num) {
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = `La quantità selezionata non è disponibile. Numero massimo: ${this.max_num}`;
        this.snackbar_text_color = "white";
        this.numero_scarto = this.max_num;
        return;
      } else {
        this.dialog_scarto = false;
        this.max_num = null;
        this.setRettificaImpegno(item_sel, "s");
      }
    },
    chiudiDialogReso() {
      this.max_num = null;
      this.numero_reso = null;
      this.dialog_reso = false;
    },
    chiudiDialogScarto() {
      this.max_num = null;
      this.img_in_uso = "";
      this.numero_scarto = null;
      this.causale_non_conformita_sel = null;
      this.dialog_scarto = false;
    },
    getCausaliNonConformita() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "PRODUZIONE_CAUSALI",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        res.data.forEach((elem) => {
          this.causali_non_conformita.push(elem);
        });
      });
    },
    setRettificaImpegno(item, tipo) {
      if (tipo == "d") {
        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setrettificaimpegno",
            token: localStorage.getItem("user_token"),
            tipo: "D",
            id_commessa_associalotti: item.id_commessa_associalotti,
          },
        };

        console.log(JSON.stringify(request.richiesta));
        this.$store.dispatch("api", request).then(() => {
          // emit della get prelievi
          this.$emit("get_prelievi");
        });
      } else {
        if (tipo == "s") {
          let request = {
            controller: "Magazzino",
            method: "PUT",
            richiesta: {
              action: "setscartoimpegno",
              token: localStorage.getItem("user_token"),
              tipo: "S",
              id_commessa_associalotti: this.item_sel.id_commessa_associalotti,
              quantita: this.numero_scarto,
            },
          };

          console.log(JSON.stringify(request.richiesta));
          this.$store.dispatch("api", request).then(() => {
            this.$emit("get_prelievi");
          });
          this.numero_reso = null;
        }
        else {
          let request = {
            controller: "Magazzino",
            method: "PUT",
            richiesta: {
              action: "setrettificaimpegno",
              token: localStorage.getItem("user_token"),
              tipo: "R",
              id_commessa_associalotti: this.item_sel.id_commessa_associalotti,
              quantita: this.numero_reso,
            },
          };

          console.log(JSON.stringify(request.richiesta));
          this.$store.dispatch("api", request).then(() => {
            this.$emit("get_prelievi");
          });
          this.numero_reso = null;
        }
      }
    },
  },
  watch: {
    componente_extra: {
      immediate: true,
      handler() {
        if (this.componente_extra === false) {
          this.coefficiente_tmp = 0;
        }
      },
    },
  },
  mounted() {
    document
      .getElementById("listener_distinta_di_prelievo")
      .addEventListener("nuova_img", this.aggiungiImg);

    this.getCausaliNonConformita();
  },
};
</script>
<style scoped>
</style>