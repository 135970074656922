<template>
  <div class="mt-3 ml-2">
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card dense>
      <v-toolbar dense class="primary white--text" primary-title>
        <v-toolbar-title class="ml-2"
          >Permission Forms Action Buttons</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loadingButton"
              @click="salvaPermission"
              v-on="on"
              v-bind="attrs"
              small
              dense
            >
              <v-icon color="success">mdi-floppy</v-icon>
            </v-btn>
          </template>
          <span>Crea Permissions</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn small light @click="clickChiudi">
          <v-icon color="error">mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-subtitle>
        <v-row>
          <v-col md="3" sm="3" cols="6">
            <v-autocomplete
              v-model="id_utente"
              :items="user_list"
              clearable
              @change="getPermission"
              label="Utente"
              placeholder="Utente"
              :item-text="(item) => item.nome + ' ' + item.cognome"
              required
              item-value="id_personale"
            >
            </v-autocomplete
          ></v-col>
          <v-col md="3" sm="3" cols="6">
            <v-autocomplete
              v-model="id_profilo"
              :items="profilo_list"
              @change="getPermission"
              clearable
              label="Profilo"
              placeholder="Profilo"
              item-text="des_profilo_utente"
              required
              item-value="id_profilo_utente"
            >
            </v-autocomplete
          ></v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="ModulesHeaders"
          :expanded.sync="expanded"
          :single-expand="true"
          item-key="form_id"
          show-expand
          :items="PermissionButton.result.form"
        >
          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <v-icon @click="expand(!isExpanded)" color="primary"
              >mdi-file-tree</v-icon
            >
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="pa-0"
              style="background-color: white"
              :colspan="headers.length"
            >
              <v-data-table
                :items="item.subform"
                :expanded.sync="expanded1"
                item-key="subform_id"
                :headers="ModulesSubHeaders"
                :single-expand="true"
                :items-per-page="50"
                show-expand
                :hide-default-header="true"
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    class="pa-0"
                    style="background-color: white"
                    :colspan="headers.length"
                  >
                    <v-data-table
                      :headers="headersSingleForm"
                      :items="item.oggetto"
                      item-key="id_form_autorizzazione"
                      :items-per-page="50"
                      :hide-default-footer="true"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.flag_readonly`]="{ item }">
                        <v-checkbox
                          :false-value="0"
                          :true-value="1"
                          v-model="item.permessi[0].flag_readonly"
                        ></v-checkbox>
                      </template>
                      <template v-slot:[`item.flag_hide`]="{ item }">
                        <v-checkbox
                          :false-value="0"
                          :true-value="1"
                          v-model="item.permessi[0].flag_hide"
                        ></v-checkbox>
                      </template>
                      <template v-slot:[`item.flag_obbligatorio`]="{ item }">
                        <v-checkbox
                          :false-value="0"
                          :true-value="1"
                          v-model="item.permessi[0].flag_obbligatorio"
                        ></v-checkbox>
                      </template>
                    </v-data-table>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: [],
      expanded1: [],
      loadingButton: false,
      id_profilo: null,
      id_utente: null,

      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      user_list: [],
      profilo_list: [],
      overlay: false,
      PermissionButton: {
        result: {
          form: [],
        },
      },
      headersSingleForm: [
        {
          text: "Button-Name",
          value: "nome_oggetto",
        },
        {
          text: "Read-only",
          value: "flag_readonly",
        },
        {
          text: "Flag-hide",
          value: "flag_hide",
        },
        {
          text: "Flag_obbligatorio",
          value: "flag_obbligatorio",
        },
      ],
      ModulesHeaders: [
        {
          value: "edit",
        },
        {
          text: "nome_module",
          value: "form_id",
        },
      ],
      ModulesSubHeaders: [
        {
          value: "edit",
        },
        {
          text: "",
          value: "subform_id",
        },
      ],
    };
  },

  methods: {
    // get Permission
    getPermission() {
      this.overlay = true;
      let getButtonPermission = {
        controller: "Auth",
        method: "POST",
        richiesta: {
          action: "getbuttonauth",
          id_utente: this.id_utente > -1 ? this.id_utente : null,
          id_profilo_utente: this.id_profilo > -1 ? this.id_profilo : null,
          token: localStorage.getItem("user_token"),
        },
      };

      this.$store.dispatch("api", getButtonPermission).then(async (res) => {
        if (res.status == 200) {
          this.overlay = false;
          this.PermissionButton = await res.data;
        }
      });
    },

    // UserList Getter
    getUtente() {
      let getUser = {
        controller: "User",
        method: "POST",
        richiesta: {
          action: "getUtenti",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", getUser).then((res) => {
        this.user_list = res.data.result.utenti;
      });
    },
    // Profile List Getter
    getProfili() {
      let getProfili = {
        controller: "Modulo",
        method: "POST",
        richiesta: {
          action: "getProfili",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", getProfili).then((res) => {
        this.profilo_list = res.data.result.profili;
      });
    },
    // Redirecting to Main Page
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    // Saving the Permissions on BE
    salvaPermission() {
      this.loadingButton = true;
      // Array to store the form after assigning the permissions
      let form = [];
      // Mapping through the get json in order to convert to make json save on BE.
      this.PermissionButton.result.form.map((it) => {
        it.subform.map((i) => {
          i.oggetto.map((item) => {
            form.push(item);
          });
        });
      });

      let setPermission = {
        controller: "Auth",
        method: "PUT",
        richiesta: {
          action: "setpermission",
          token: localStorage.user_token,
          id_utente: this.id_utente,
          form: form,
        },
      };

      this.$store.dispatch("api", setPermission).then((res) => {
        if (res.status == 200 || res.status == 204) {
          if (res.status == 200) {
            this.snackbar = true;
            this.snackbar_background = "success";
            this.snackbar_icon = "mdi-check";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Permessi settati correttamente";
            this.snackbar_text_color = "white";
          }
          if (res.status == 204) {
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = "Errore nel salvataggio";
            this.snackbar_text_color = "white";
          }
          this.getPermission();
          setTimeout(() => {
            this.loadingButton = false;
          }, 3000);
        }
        if (res.status == 204) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Errore nel salvataggio";
          this.snackbar_text_color = "white";
        }
      });
    },
  },

  watch: {
    id_profilo: {
      immediate: true,
      deep: true,
      handler() {
        const val = this.id_profilo;
        if (val && val > -1) {
          this.id_utente = null;
        } else {
          this.id_profilo = val;
        }
      },
    },
    id_utente: {
      immediate: true,
      deep: true,
      handler() {
        const val = this.id_utente;
        if (val && val > -1) {
          this.id_profilo = null;
        } else {
          this.id_utente = val;
        }
      },
    },
  },

  mounted() {
    this.getPermission();
    this.getUtente();
    this.getProfili();
  },
};
</script>
