var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-0 mb-0 mx-5 pt-3",staticStyle:{"background-color":"rgba(0, 0, 0, 0)","height":"85vh","max-height":"85vh"}},[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" QuickPlant")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.esci}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Torna alla Home")])])],1),(_vm.tabella_disegni_visible === true)?_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers_disegni,"height":"65vh","items":_vm.items_disegni},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-4 pt-2 mb-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":"blue-grey lighten-5","small":""},on:{"click":_vm.aggiungiDisegno}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}],null,false,1419501608)},[_c('span',[_vm._v("Aggiungi Disegno")])])],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_disegni,"item-text":"des_disegno","item-value":"des_disegno","label":"Titolo"},on:{"change":_vm.filtra},model:{value:(_vm.des_disegno_search),callback:function ($$v) {_vm.des_disegno_search=$$v},expression:"des_disegno_search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":0,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Dalla Data (Creazione)","value":_vm.formatDataDa(_vm.data_da_search),"readonly":""},on:{"change":_vm.filtra}},on))]}}],null,false,477100946),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.data_da_search),callback:function ($$v) {_vm.data_da_search=$$v},expression:"data_da_search"}})],1)],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.ripristina}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-filter-off ")])],1)]}}],null,false,1768138586)},[_c('span',[_vm._v("Annulla Filtro")])])],1)],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModifica(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica Disegno")])])]}},{key:"item.configurazione",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaConfigurazione(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-axis-arrow-info ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiorna Configurazione")])])]}}],null,true)}):_vm._e(),(_vm.plantchart_visible)?_c('Plantchart',{key:_vm.key_flowchart,attrs:{"id_disegno":_vm.disegno_sel.id_disegno,"visible":_vm.plantchart_visible},on:{"chiudi_disegno":_vm.handleChiudiDisegno}}):_vm._e(),_c('v-dialog',{attrs:{"width":"75%"},model:{value:(_vm.modifica_disegno_dialog),callback:function ($$v) {_vm.modifica_disegno_dialog=$$v},expression:"modifica_disegno_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Caratteristiche Disegno")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaDisegno}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiModificaDisegno}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Titolo"},model:{value:(_vm.disegno_sel.des_disegno),callback:function ($$v) {_vm.$set(_vm.disegno_sel, "des_disegno", $$v)},expression:"disegno_sel.des_disegno"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Autore"},model:{value:(_vm.disegno_sel.des_utente),callback:function ($$v) {_vm.$set(_vm.disegno_sel, "des_utente", $$v)},expression:"disegno_sel.des_utente"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Data Creazione","readonly":""},model:{value:(_vm.disegno_sel.data_ins),callback:function ($$v) {_vm.$set(_vm.disegno_sel, "data_ins", $$v)},expression:"disegno_sel.data_ins"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Data Ultima Modifica","readonly":""},model:{value:(_vm.disegno_sel.data_mod),callback:function ($$v) {_vm.$set(_vm.disegno_sel, "data_mod", $$v)},expression:"disegno_sel.data_mod"}})],1)],1)],1)],1)],1)],1),_c('SnackbarQV',{ref:"snackbarqv"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }