<template>
  <v-container fluid class="py-0">
    <!-- Loader -->
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Alert or notification display -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!--Dialog to Read Rdf File -->
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="display: block; height: 80vh !important; width: 90vw !important"
      ></iframe>
    </v-dialog>
    <!-- Disegno Delete Confirmation Dialog -->
    <v-dialog content-class="artDialog" persistent v-model="dialogElimina" width="unset">
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">
            Questo elemento verrà eliminato definitivamente
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="delSelectedItem">ANNULLA</v-btn>
          <v-btn color="green" dark @click="deldisegno">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Firma Disegno Dialog -->
    <v-dialog content-class="artDialog" persistent v-model="dialogFirma" width="400">
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text">Firma Disegno</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">Firmare il disegno selezionato?</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="dialogFirma = false">ANNULLA</v-btn>
          <v-btn color="green" dark @click="signdrawing">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal to Add Files or Images -->
    <v-dialog content-class="artDialog" persistent v-model="dialogAllegati" width="400">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Allegati</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogAllegati = false" small>
                <v-icon v-on="on" v-bind="attrs" color="error" depressed
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image/pdf"
            label="File"
            prepend-icon="mdi-paperclip"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Model to Add or Update Disegno -->
    <v-dialog content-class="artDialog" persistent v-model="dialog" width="unset">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Aggiungi Disegno</v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            idForm="ARTICOLO_MODIFICA_DISEGNO"
            idOggetto="SALVA_DISEGNO_ARTICOLO"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="slavaDesigno(item)"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="closeModal" small>
                <v-icon v-on="on" v-bind="attrs" color="error" depressed
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row class="pa-0">
            <v-col cols="6" md="3">
              <TextField
                idForm="DISEGNO"
                idOggetto="DISEGNO_COD_ALTERNATIVO"
                label_input="Codice Alternativo"
                palceholder_input="Codice Alt."
                :vmodel="cod_alternativo"
                v-on:changeText="(val) => (this.cod_alternativo = val)"
              />
            </v-col>
            <v-col cols="6" md="2">
              <TextField
                idForm="DISEGNO"
                idOggetto="DISEGNO_REVISIONE_ARTICOLO"
                label_input="Revisione"
                palceholder_input="Revisione"
                :vmodel="revisione_articolo"
                v-on:changeText="(val) => (this.revisione_articolo = val)"
                :min_input="0"
                type_input="number"
              />
            </v-col>
            <v-col cols="6" md="2">
              <TextField
                idForm="DISEGNO"
                idOggetto="DISEGNO_INDICE_MODIFICA"
                label_input="Indice"
                palceholder_input="Indice Modifica"
                :vmodel="indice_modifica"
                v-on:changeText="(val) => (this.indice_modifica = val)"
              />
            </v-col>
            <v-col cols="6" md="2">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="dum_format"
                    label="DUM"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dum" @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mt-4" cols="6" md="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on" @click="dialogAllegati = true">
                    <v-icon color="#1a80b6">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Nuovo Disegno</span>
              </v-tooltip>
            </v-col>
            <v-col class="mt-3" cols="6" md="1" v-if="disegno != ''">
              <!-- Card per le immagini -->
              <v-card v-if="!isPdf(disegno)" class="d-flex justify-center">
                <div v-viewer class="images clearfix d-flex align-items-center">
                  <img
                    id="immagineDisegno"
                    :src="disegno"
                    :lazy-src="disegno"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  />
                </div>
              </v-card>
              <!-- Card per i PDF -->
              <v-card
                @click="openPdfAppenaCaricato(disegno)"
                style="
                  height: 40px !important;
                  width: 80px !important;
                  margin-right: -10rem !important;
                "
                v-if="isPdf(disegno)"
              >
                <div style="text-align: center">
                  <v-spacer></v-spacer>
                  <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <form>
      <v-row>
        <v-col cols="8" md="8" sm="12">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card style="width: 100%" class="mr-4" :elevation="hover ? 24 : 2">
                <v-toolbar color="indigo" dense>
                  <v-toolbar-title class="white--text">Disegno</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <Button
                    classe_bottone="mr-5"
                    colore_icona="primary"
                    idForm="ARTICOLO_DISEGNO"
                    idOggetto="CREA_DISEGNO_ARTICOLO"
                    mdi_icona="mdi-plus"
                    testo_tooltip="Aggiungi Disegno"
                    v-on:buttonClick="handleclick(null)"
                  />
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-row>
                  <v-col md="12">
                    <v-data-table :loading="loading" :items="items" :headers="headers">
                      <v-progress-linear
                        v-show="progressBar"
                        slot="progress"
                        color="blue"
                        indeterminate
                      ></v-progress-linear>
                      <template v-slot:[`item.firma`]="{ item }">
                        <Button
                          colore_icona="blue"
                          idForm="ARTICOLO_DISEGNO"
                          idOggetto="FIRMA_DISEGNO_ARTICOLO"
                          mdi_icona="mdi-file-question-outline"
                          testo_tooltip="Firma Disegno"
                          v-on:buttonClick="signatureDisegno(item)"
                        />
                      </template>
                      <template v-slot:[`item.edit`]="{ item }">
                        <Button
                          classe_bottone="ml-n8"
                          colore_icona="primary"
                          idForm="ARTICOLO_DISEGNO"
                          idOggetto="MODIFICA_DISEGNO_ARTICOLO"
                          mdi_icona="mdi-pencil"
                          testo_tooltip="Aggiornare"
                          v-on:buttonClick="handleclick(item)"
                        />
                      </template>

                      <template v-slot:[`item.dum`]="{ item }">
                        {{ item.dum | formatDate }}
                      </template>
                      <template v-slot:[`item.disegno`]="{ item }">
                        <!-- Card per le immagini -->
                        <v-card
                          style="
                            height: 40px !important;
                            width: 80px !important;
                            margin-right: -10rem !important;
                          "
                          v-if="!isPdf(item.disegno)"
                        >
                          <div v-viewer class="images clearfix">
                            <img
                              style="
                                height: 40px !important;
                                display: block !important;
                                width: 60px !important;
                                margin-right: -10rem !important;
                              "
                              :src="item.disegno"
                              :lazy-src="item.disegno"
                              aspect-ratio="1"
                              class="grey lighten-2"
                            />
                          </div>
                        </v-card>
                        <!-- Card per i PDF -->
                        <v-card
                          @click="openPdf(item)"
                          style="
                            height: 40px !important;
                            width: 80px !important;
                            margin-right: -10rem !important;
                          "
                          v-if="isPdf(item.disegno)"
                        >
                          <div style="text-align: center">
                            <v-spacer></v-spacer>
                            <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                          </div>
                        </v-card>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <Button
                          colore_icona="error"
                          idForm="ARTICOLO_DISEGNO"
                          idOggetto="ELIMINA_DISEGNO_ARTICOLO"
                          mdi_icona="mdi-window-close"
                          testo_tooltip="Cancellare"
                          v-on:buttonClick="disegnoToDel(item)"
                        />
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-hover>
        </v-col>
        <v-col cols="4" md="4" sm="12">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card :elevation="hover ? 24 : 2">
                <v-toolbar color="indigo" dense>
                  <v-toolbar-title class="white--text"
                    >Specifiche Tecniche</v-toolbar-title
                  >
                </v-toolbar>
                <v-container>
                  <v-row class="mx-1">
                    <v-col class="py-0" cols="12">
                      <v-autocomplete
                        class="ml-1"
                        v-model="certificato_prodotto"
                        :items="certificato_prodotto_list"
                        placeholder="Certificato Prodotto"
                        required
                        item-text="des"
                        item-value="id_tabella"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0" cols="6">
                      <TextField
                        idForm="DISEGNO"
                        idOggetto="SPECIFICHE_TIMBRO"
                        label_input="Codice Timbro"
                        palceholder_input="Codice Timbro"
                        :vmodel="data.timbro"
                        v-on:changeText="(val) => (data.timbro = val)"
                      />
                    </v-col>
                    <v-col class="py-0" cols="6">
                      <TextField
                        idForm="DISEGNO"
                        idOggetto="SPECIFICHE_PREFISSO_LOTTO"
                        label_input="Prefisso Lotto"
                        palceholder_input="Prefisso Lotto"
                        :vmodel="data.prefisso_lotto"
                        v-on:changeText="(val) => (data.prefisso_lotto = val)"
                      />
                    </v-col>
                    <v-col class="py-0 d-flex align-center" cols="6">
                      <v-checkbox
                        v-model="data.gestione_lotto"
                        label="Gestione Lotti"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="py-0 d-flex align-center" cols="6">
                      <v-checkbox
                        v-model="data.prelievo_lotti_successivi"
                        label="Prelievo Lotti Successivi"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        v-model="data.specifiche"
                        label="Specifiche"
                        placeholder="Specifiche"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>
<script>
import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";
import moment from "moment";
import panzoom from "panzoom";
export default {
  props: ["data"],
  components: { Button, TextField },
  data() {
    return {
      // overlay var
      overlay: false,
      // Snackbar Variables//
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      pdfdialog: false,
      selectedPdf: "",
      //Delete confirmation dialog
      dialogElimina: false,
      deletedDisegno: {},
      selectedDisegno: "",
      singleItem: "",
      item: "",
      dum: new Date().toISOString().substr(0, 10),
      dialog: false,
      dialogAllegati: false,
      dialogFirma: false,
      items: [],
      progressBar: false,
      loading: false,
      disegno: "",
      id_disegno: "",
      id_articolo: "",
      cod_alternativo: "",
      indice_modifica: "",
      menu1: false,
      revisione_articolo: "",
      certificato_prodotto: "",
      certificato_prodotto_list: [],
      prefisso_lotto: "",
      prelievo_lotti_successivi: "",
      specifiche: "",
      allegato: [],
      // Table header for Disegno
      headers: [
        {
          text: "",
          value: "firma",
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
        {
          text: "Firma",
          value: "nominativo",
          sortable: false,
        },
        {
          text: "Data firma",
          value: "date_firma",
          sortable: false,
        },
        {
          text: "Cod. Alternativo",
          value: "cod_alternativo",
          sortable: false,
        },
        {
          text: "Revisione",
          value: "revisione_articolo",
          sortable: false,
        },
        {
          text: "Indice",
          value: "indice_modifica",
          sortable: false,
        },
        {
          text: "D.U.M",
          value: "_dum",
          sortable: false,
        },
        {
          text: "Allegati",
          value: "disegno",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    // To resize Images
    resizeImage(img, id) {
      if (img.width > img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    // TO zoom PDF
    onLoad() {
      panzoom(this.$refs.pdf);
    },
    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    openPdfAppenaCaricato(item) {
      if (item.startsWith("data:application/pdf")) {
        const byteCharacters = atob(item.substring(28));
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        this.pdfdialog = true;
      }
    },
    openPdf(item) {
      const byteCharacters = atob(item.disegno.substring(28));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.selectedPdf = blobUrl;
      this.pdfdialog = true;
    },
    // Del Selected Item
    delSelectedItem() {
      this.deletedDisegno = {};
      this.dialogElimina = false;
    },
    // Delete Confirmation Dialog
    disegnoToDel(item) {
      this.dialogElimina = true;
      this.deletedDisegno = item;
    },
    // In order to sign disegno
    signdrawing() {
      this.overlay = true;
      let req = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setfirmadisegno",
          token: localStorage.user_token,
          id_disegno: this.selectedDisegno,
        },
      };
      this.$store.dispatch("api", req).then(() => {
        this.snackbar = true;
        this.snackbar_background = "success";
        this.snackbar_icon = "mdi-check";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
        this.caricaDisegni();
      });
      this.overlay = false;
      this.dialogFirma = false;
    },
    // open firma disegno modal
    signatureDisegno(item) {
      this.dialogFirma = true;
      this.selectedDisegno = item.id_disegno;
    },
    clearImage() {
      this.dialogAllegati = false;
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");
        // Create an image
        var img = new Image();
        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;
          // Draw the image
          ctx.drawImage(img, 0, 0);
          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };
        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;
        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
    },
    onPaste(e) {
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;
        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.disegno = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name.toLowerCase()
      );
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (this.hasExtension(files[0], [".jpeg", ".gif", ".png", ".jpg", ".pdf"])) {
        this.allegato = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;
        reader.onload = function () {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            that.resizeImage(image, "immagineDisegno");
          };
          that.disegno = reader.result;
        };
        reader.onerror = function (error) {
          throw error;
        };
      } else {
        this.dialogAllegati = false;
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = " Tipo di file non accettato.";
        this.snackbar_text_color = "white";
      }
      this.dialogAllegati = false;
    },
    // Setting value to show in edit form of Disegno
    handleclick(item) {
      this.dialog = true;
      this.singleItem = item != null ? item : {};
      this.id_disegno = item != null ? item.id_disegno : "";
      this.cod_alternativo = item != null ? item.cod_alternativo : "";
      this.indice_modifica = item != null ? item.indice_modifica : "";
      this.revisione_articolo = item != null ? item.revisione_articolo : "";
      this.disegno = item != null ? item.disegno : "";
      let that = this;
      var image = new Image();
      image.src = this.disegno;
      image.onload = function () {
        that.resizeImage(image, "immagineDisegno");
      };
      this.dum = item != null ? moment(item._dum, "DD/MM/YYYY").format("YYYY-MM-DD") : "";
      this.tipo_disegno = item != null ? item.tipo_disegno : "";
      this.validita = item != null ? item.validita : 1;
      this.allegato = [];
    },
    // when closing modal setting form values to empty
    closeModal() {
      this.id_disegno = "";
      this.cod_alternativo = "";
      this.indice_modifica = "";
      this.revisione_articolo = "";
      this.disegno = "";
      this.dum = "";
      this.dialog = false;
    },
    //In order to Delete single disegno
    deldisegno() {
      this.overlay = true;
      let data = {};
      data.id_disegno = this.deletedDisegno.id_disegno;
      data.validita = 0;
      let dataArray = [data];
      let delreq = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolodisegno",
          id_articolo: this.data.id_articolo,
          articolo_disegni: dataArray,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", delreq).then((res) => {
        this.overlay = false;
        if (res.data) {
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_text_color = "white";
        }
      });
      const i = this.items.findIndex((it) => it.id_disegno === data.id_disegno);
      this.items.splice(i, 1);
      this.dialogElimina = false;
    },
    // In order to save or update disegno
    slavaDesigno() {
      this.overlay = true;
      if (!this.id_disegno) {
        const data = {};
        if (this.cod_alternativo === "") {
          return;
        }
        if (this.indice_modifica === "") {
          return;
        }
        if (this.dum === "") {
          return;
        }
        data.id_disegno = -1;
        data.id_articolo = this.data.id_articolo;
        data.cod_alternativo = this.cod_alternativo;
        data.indice_modifica = this.indice_modifica;
        data.revisione_articolo = this.revisione_articolo;
        data.disegno = this.disegno;
        data.dum = this.dum;
        data.validita = 1;
        data.tipo_disegno = 1;
        let dataArray = [];
        dataArray.push(data);
        let saveDesigno = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolodisegno",
            token: localStorage.getItem("user_token"),
            id_articolo: this.data.id_articolo,
            articolo_disegni: dataArray,
          },
        };
        this.$store.dispatch("api", saveDesigno).then(() => {
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_text_color = "white";
          this.caricaDisegni();
        });
        this.dialog = false;
      }
      if (this.singleItem.id_disegno) {
        const data = {};
        data.validita = 1;
        data.id_disegno = this.id_disegno;
        data.id_articolo = this.data.id_articolo;
        data.cod_alternativo = this.cod_alternativo;
        data.indice_modifica = this.indice_modifica;
        data.revisione_articolo = this.revisione_articolo;
        data.disegno = this.disegno;
        data.dum = this.dum;
        data.validita = 1;
        data.tipo_disegno = 1;
        let dataArray = [];
        dataArray.push(data);
        let saveDesigno = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolodisegno",
            token: localStorage.getItem("user_token"),
            id_articolo: this.data.id_articolo,
            articolo_disegni: dataArray,
          },
        };
        this.$store.dispatch("api", saveDesigno).then(() => {
          this.overlay = false;
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Modifica effettuata con successo!";
          this.snackbar_text_color = "white";
          this.caricaDisegni();
        });
        //  this.caricaDisegni();
        const i = this.items.findIndex((it) => it.id_disegno === data.id_disegno);
        this.items.splice(i, 1, data);
        this.dialog = false;
        this.id_disegno = "";
        this.cod_alternativo = "";
        this.indice_modifica = "";
        this.revisione_articolo = "";
        this.disegno = "";
        this.dum = "";
        this.tipo_disegno = "";
        this.validita = 0;
        this.singleItem = {};
      }
      this.items.map((it) => {
        var dum = moment(it.dum, "YYYY-MM-DD");
        it.dum = dum;
      });
    },
    // In order to get list of Disegni to populate the table
    caricaDisegni() {
      this.progressBar = true;
      this.loading = true;
      let disegnoReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolidisegno",
          token: localStorage.getItem("user_token"),
          id_articolo: this.data.id_articolo,
        },
      };
      this.$store.dispatch("api", disegnoReq).then((res) => {
        this.progressBar = false;
        this.loading = false;
        this.items = res.data;
      });
    },
  },
  computed: {
    dum_format() {
      return this.dum ? moment(this.dum).format("DD/MM/YYYY") : "";
    },
  },
  mounted() {
    this.caricaDisegni();
  },
};
</script>
