<template>
  <v-container>
    <ConsuntivoRisorse />
  </v-container>
</template>
<script>
import ConsuntivoRisorse from "@/components/GANTT/ConsuntivoRisorse/ConsuntivoRisorse.vue";
export default {
  name: "ConsuntivoRisorseView",
  components: { ConsuntivoRisorse },
  data() {
    return { project: null };
  },
  methods: {},
};
</script>
<style>
</style>