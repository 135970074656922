<template>
  <div style="width: 100%">
    
    <Dashboard />
  </div>
</template>
<script>
import Dashboard from "../../components/BENESTARE/Dashboard.vue";
export default {
  data() {
    return {
    };
  },
  components: {
    Dashboard,
  },
};
</script>