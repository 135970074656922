<template>
  <div class="articolo" style="padding: 20px">
    <!-- to visualize the pdf dialog -->
    <v-dialog content-class="artDialog" v-model="pdfdialog" width="unset">
      <iframe
        :src="this.selectedPdf"
        frameborder="0"
        style="height: 80vh !important; width: 90vw !important"
      >
      </iframe>
    </v-dialog>
    <!-- Dialog To warn you When you try to navigate to other component and you have Unsaved Values  -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogModificheDaSalvare"
      width="800"
    >
      <v-card>
        <v-toolbar color="indigo darken-1" class="ModalColor" dense>
          <v-toolbar-title class="mt-n5 white--text"
            >Avviso di modifiche non salvate</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3>Hai modifiche non salvate nell'articolo, vuoi salvarle</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            colore_bottone="green"
            idForm="ARTICOLO"
            idOggetto="CONFERMA_SALVA_MODIFICHE_ARTICOLO"
            mdi_icona=""
            testo_bottone="Si"
            testo_tooltip="Si"
            v-on:buttonClick="dialogModificheDaSalvare = false"
          />
          <Button
            colore_bottone="red"
            idForm="ARTICOLO"
            idOggetto="NON_CONFERMA_SALVA_MODIFICHE_ARTICOLO"
            mdi_icona=""
            testo_bottone="No"
            testo_tooltip="No"
            v-on:buttonClick="clearFromFields"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Returnig back with unsaved form will show this alert -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAvvisoModificheNonSalvate"
      width="800"
    >
      <v-card>
        <v-toolbar dense class="indigo">
          <v-toolbar-title class="white--text"
            >Avviso di modifiche non salvate</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3>Hai modifiche non salvate nell'articolo, vuoi salvarle</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="
              dialogAvvisoModificheNonSalvate = false;
              salvaArticolo();
            "
            >Si</v-btn
          >
          <v-btn color="red" dark @click="GobacktoLista">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to Add new File to Articolo -->
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogAggiungiAllegato"
      width="500"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Allegati all'Articolo</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="setImmagine()" small>
                <v-icon v-on="on" color="success" v-bind="attrs"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-n4"
                @click="dialogAggiungiAllegato = false"
                small
              >
                <v-icon v-on="on" v-bind="attrs" depressed color="error"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Cancellare</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="allegato"
            @paste="onPaste"
            accept="image/x-png,image/gif,image/jpeg,image/jpg,image,image/PNG"
            label="File"
            prepend-icon="mdi-paperclip"
            @click:prepend="openImagePicker"
          ></v-text-field>
          <input
            type="file"
            v-on:change="onFileChange"
            ref="allegato"
            name="allegato"
            class="d-none"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar to show alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!-- Modal When you search an Articolo inside a Articolo Detail -->
    <ArticoloSearchModal
      :key="dialog"
      :headers="this.header"
      v-on:closeModal="hideDetail"
      v-on:onDetail="onButtonClick($event)"
      v-on:modalSearch="searchResult1($event)"
      v-on:sendVal="sendVal($event)"
      :compdata="this.completeData.lista"
      :dialog="dialog"
      :tipoList="this.tipoList"
      :famigliaList="this.famiglia_list"
      :codArticolo="this.codArticolo"
    />
    <!-- Main Search Articolo Form on landing Page of Articolo -->
    <div class="hoverDiv" v-show="this.topDiv" :elevation="24">
      <v-card class="cercaArt">
        <v-toolbar color="indigo" dense primary-title>
          <v-toolbar-title class="white--text"
            >Articolo <v-icon color="white">mdi-dolly</v-icon>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            classe_bottone="mr-5"
            colore_icona="primary"
            idForm="ARTICOLO"
            idOggetto="CREA_ARTICOLO"
            mdi_icona="mdi-plus"
            stile_icona=""
            testo_tooltip="Nuovo Articolo"
            v-on:buttonClick="showArticoloForm"
          />

          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class=""
                @click="gotoDashboard"
                small
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Vai alla Dashboard</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-subtitle>
          <v-row>
            <v-col class="py-0" cols="8" md="2">
              <TextField
                idForm="ARTICOLO"
                idOggetto="CODICE_ARTICOLO"
                label_input="Cod. Articolo"
                palceholder_input="Inserisci il Codice"
                :vmodel="cod_articolo"
                :clearable_input="this.showicon ? true : false"
                v-on:changeText="
                  (val) => ((this.cod_articolo = val), changeHandler())
                "
                v-on:keyEnter="searchArticolo"
              />
            </v-col>
            <v-col class="py-0 d-flex justify-center" cols="1" md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-6"
                    :disabled="disabled"
                    @click="searchArticolo"
                    small
                  >
                    <v-icon
                      v-on="on"
                      class
                      v-bind="attrs"
                      size="32"
                      color="#1a80b6"
                      >mdi-magnify</v-icon
                    >
                  </v-btn>
                </template>
                <span>Cerca Articolo</span>
              </v-tooltip>
            </v-col>
            <v-col class="py-0" cols="4" md="3">
              <v-autocomplete
                v-model="id_tipo"
                :items="tipoList"
                clearable
                label="Tipo Articolo"
                :placeholder="des"
                item-text="des"
                required
                item-value="id_tabella"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0" cols="4" md="3">
              <v-autocomplete
                v-model="id_famiglia"
                :items="famiglia_list"
                item-text="des"
                clearable
                required
                item-value="id_tabella"
                :placeholder="des"
                label="Tipo Famiglia"
              ></v-autocomplete>
            </v-col>
            <v-col class="py-0" cols="4" md="3">
              <v-autocomplete
                v-model="id_tipo_fornitura"
                :items="fornitura_list"
                clearable
                item-text="des"
                required
                item-value="id_tabella"
                :placeholder="des"
                label="Tipo Fornitura"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text class="mt-n4">
          <v-data-table
            :loading="loadingVal"
            :headers="headers2"
            :items="items"
            fixed-header
            height="60vh"
          >
            <v-progress-linear
              v-show="progressBar"
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template v-slot:[`item.detail`]="{ item }">
              <Button
                classe_bottone="mr-5"
                colore_icona="primary"
                idForm="ARTICOLO"
                idOggetto="MODIFICA_ARTICOLO"
                mdi_icona="mdi-pencil"
                testo_tooltip="Dettaglio Articolo"
                v-on:buttonClick="onButtonClick(item)"
              />
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <Button
                classe_bottone="mr-5"
                colore_icona="error"
                idForm="ARTICOLO"
                idOggetto="ELIMINA_ARTICOLO"
                mdi_icona="mdi-window-close"
                stile_icona="float: left"
                testo_tooltip="Rimuovere Articolo"
                v-on:buttonClick="
                  confermaDelArticolo = true;
                  selectedArticolo = item;
                "
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <!-- Menu per la main dashboard dell'articolo dopo la selezione -->
    <div v-if="!this.topDiv">
      <v-card>
        <v-toolbar
          primary-title
          dense
          v-if="this.disableAppBar"
          class="indigo darken-1 headline"
        >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                class="mr-5"
                small
                v-show="showadd"
                @click="hideIcons"
              >
                <v-icon style="color: #1a80b6">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Per Aggiungere Un Nuovo Articolo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                class="mr-5"
                small
                v-show="showupdate"
                @click="hideIconS"
              >
                <v-icon color="#1a80b6">mdi-replay</v-icon>
              </v-btn>
            </template>
            <span>Vai all'aggiornamento dell'articolo</span>
          </v-tooltip>
          <Button
            v-if="savebutton"
            classe_bottone="mr-5"
            colore_icona="success"
            idForm="ARTICOLO"
            idOggetto="SALVA_ARTICOLO"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva Articolo"
            v-on:buttonClick="salvaArticolo"
          />
          <Button
            v-if="
              id_articolo != null &&
              id_articolo != undefined &&
              id_articolo != ''
            "
            classe_bottone="mr-5"
            colore_icona="primary"
            idForm="ARTICOLO"
            idOggetto="DUPLICA_ARTICOLO"
            mdi_icona="mdi-content-copy"
            testo_tooltip="Duplica Articolo"
            v-on:buttonClick="clickDuplicaArticolo"
          />
          <Button
            v-if="
              id_articolo != null &&
              id_articolo != undefined &&
              id_articolo != ''
            "
            classe_bottone="mr-5"
            colore_icona="primary"
            idForm="ARTICOLO"
            idOggetto="GESTIONE_ALLEGATI"
            mdi_icona="mdi-paperclip"
            testo_tooltip="Allegati Articolo"
            v-on:buttonClick="clickGestioneAllegati"
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" @click="GoBack" small>
                <v-icon color="red">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-form action>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="10" class="py-0">
                <v-container>
                  <v-row>
                    <v-col class="py-0" cols="4" md="3">
                      <TextField
                        :readonly="cod_articolo_alteration"
                        idForm="ARTICOLO"
                        idOggetto="CODICE_ARTICOLO"
                        label_input="Cod. Articolo"
                        palceholder_input="Inserisci il Codice"
                        :vmodel="cod_articolo"
                        v-on:changeText="(val) => (this.cod_articolo = val)"
                        v-on:keyEnter="searchResult"
                      />
                    </v-col>
                    <v-col class="py-0" cols="1">
                      <v-icon
                        v-if="showicon"
                        class="mt-6"
                        :disabled="disabled"
                        @click="searchResult"
                        color="#1a80b6"
                        size="24"
                        >mdi-magnify</v-icon
                      >
                    </v-col>
                    <v-col class="py-0" cols="7" md="5">
                      <TextField
                        :readonly="disabledes"
                        idForm="ARTICOLO"
                        idOggetto="DES_ARTICOLO"
                        label_input="Descrizione"
                        palceholder_input="Inserisci il Descrizione"
                        :vmodel="des_articolo"
                        v-on:changeText="(val) => (this.des_articolo = val)"
                      />
                    </v-col>
                    <!-- :items="articolo_fake_list" -->
                    <v-col class="py-0" cols="6" md="3">
                      <v-autocomplete
                        v-model="id_tipo1"
                        :items="tipoList"
                        :disabled="disabletipo"
                        label="Tipo Articolo"
                        placeholder="Tipo Articolo"
                        item-text="des"
                        item-value="id_tabella"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0" cols="6" md="4">
                      <v-autocomplete
                        v-model="id_sub_tipo"
                        :disabled="
                          subtipoList.filter(
                            (elem) => elem.val_int === id_tipo1
                          ).length === 0
                        "
                        :items="
                          subtipoList.filter(
                            (el) => el.val_int === id_tipo1
                          )
                        "
                        label="Sub Tipo Articolo"
                        placeholder="Sub Tipo Articolo"
                        item-text="des"
                        required
                        item-value="id_tabella"
                      >
                        <template #label>
                          <span class="">Sub Tipo Art.<strong>* </strong></span>
                          <span
                            @click.stop
                            style="min-width: 500px; pointer-events: all"
                          >
                            <Modules
                              v-on:dialogModules="aggiornaSubTipoArticolo"
                              tipo="SUB_TIPO_ARTICOLO"
                              nome="SubTipoArticolo"
                              setaction="settabelle"
                              getaction="gettabelle"
                              controller="Magazzino"
                              :filter="
                                '(val_int = ' + id_tipo1 + ') AND validita = 1'
                              "
                              :dati="[{ des: '' }, { val_int: id_tipo1 }]"
                            />
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0" cols="6" md="4">
                      <v-autocomplete
                        v-model="id_famiglia1"
                        :items="famiglia_list"
                        item-text="des"
                        :disabled="disableFam"
                        required
                        item-value="id_tabella"
                        class="input-group--focused"
                        placeholder="Tipo Famiglia"
                        label="Tipo Famiglia"
                      >
                        <template #label>
                          <span class="">Famiglia<strong>* </strong></span>
                          <a @click.stop style="pointer-events: all">
                            <Modules
                              v-on:dialogModules="aggiornaFamiglia"
                              tipo="Famiglia"
                              nome="Famiglia"
                              setaction="settabelle"
                              getaction="gettabelle"
                              controller="Magazzino"
                              filter="validita = 1"
                              :dati="[{ des: '' }]"
                            />
                          </a>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0" cols="6" md="4">
                      <v-autocomplete
                        v-model="id_sotto_famiglia"
                        :disabled="
                          sottoFamList.filter(
                            (elem) => elem.val_int === this.id_famiglia1
                          ).length === 0
                        "
                        :items="
                          sottoFamList.filter(
                            (el) => el.val_int === this.id_famiglia1
                          )
                        "
                        label="Sotto Fam."
                        placeholder="Sotto Fam."
                        item-text="des"
                        required
                        item-value="id_tabella"
                      >
                        <template #label>
                          <span class=""
                            >Sub Famiglia Art.<strong>* </strong></span
                          >
                          <span
                            @click.stop
                            style="min-width: 500px; pointer-events: all"
                          >
                            <Modules
                              v-on:dialogModules="aggiornaSottoFamiglia"
                              tipo="SOTTO_FAMIGLIA"
                              nome="SottoFamiglia"
                              setaction="settabelle"
                              getaction="gettabelle"
                              controller="Magazzino"
                              :filter="
                                '(val_int = ' +
                                id_famiglia1 +
                                ') AND validita = 1'
                              "
                              :dati="[{ des: '' }, { val_int: id_famiglia1 }]"
                            />
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0" cols="5" md="4">
                      <v-autocomplete
                        v-model="id_tipo_fornitura"
                        :items="fornitura_list"
                        item-text="des"
                        :disabled="disableForn"
                        required
                        item-value="des"
                        placeholder="Tipo Fornitura"
                        label="Tipo Fornitura"
                      >
                        <template #label>
                          <span class="">Fornitura<strong>* </strong></span>
                          <a @click.stop style="pointer-events: all">
                            <Modules
                              v-on:dialogModules="aggiornaFornitura"
                              tipo="FORNITURA_STANDARD"
                              nome="Fornitura"
                              setaction="settabelle"
                              getaction="gettabelle"
                              controller="Magazzino"
                              filter="validita = 1"
                              :dati="[{ des: '' }]"
                            />
                          </a>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0" cols="7" md="6">
                      <TextField
                        :readonly="disableAggun"
                        idForm="ARTICOLO"
                        idOggetto="DES_AGGIUNTIVA"
                        label_input="Descrizione Aggiuntiva"
                        palceholder_input="Inserisci la Des. Aggiuntiva"
                        :vmodel="des_aggiuntiva"
                        v-on:changeText="(val) => (this.des_aggiuntiva = val)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col class="mt-2 mt-sm-0" cols="12" md="2">
                <v-container>
                  <v-row class="justify-center">
                    <v-col cols="12">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogAggiungiAllegato = true"
                          >
                            <v-icon color="#1a80b6">mdi-image</v-icon>
                          </v-btn>
                        </template>
                        <span>Nuova Immagine dell'Articolo</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <div
                        v-if="!isPdf(immagine_preview)"
                        v-viewer
                        class="images clearfix"
                      >
                        <img
                          id="immagine"
                          style="
                            height: 100% !important;
                            width: 100%;
                            display: block !important;
                          "
                          :src="immagine_preview"
                          :lazy-src="immagine_preview"
                          aspect-ratio="1"
                          class="grey lighten-2"
                        />
                      </div>
                      <v-card
                        @click="openPdfAppenaCaricato(immagine_preview)"
                        style="height: 40px !important; width: 80px !important"
                        v-if="isPdf(immagine_preview)"
                      >
                        <div style="text-align: center">
                          <v-spacer></v-spacer>
                          <v-icon color="error" x-large> mdi-file-pdf-box </v-icon>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-divider class=""></v-divider>
      <!-- Tabs Drawer in order To Show the Sub components of Articolo  -->
      <ArticoloDrawer
        v-on:valChanged="disabled_Codice($event)"
        v-on:updateDrawer="updateArticle($event)"
        v-on:updatePackging="updatePackging($event)"
        :contentConte="this.content_cod_articolo"
        :imballoCod="this.imballo_cod_articolo"
        :data="this.articoloDetail"
        :key="this.articoloDetail.id_articolo"
        :CodEnable="this.cod_articolo_enable"
      />
    </div>
    <!-- Confirmation Dialog For deletion of an articolo -->
    <v-dialog persistent v-model="confermaDelArticolo" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"> Cancellazione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-10">
          <h3>Vuoi veramente cancellare questo articolo?</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            colore_bottone="error"
            idForm="ARTICOLO"
            idOggetto="NON_CONFERMA_ELIMINA_ARTICOLO"
            mdi_icona=""
            testo_bottone="Annulla"
            testo_tooltip="Annulla"
            v-on:buttonClick="confermaDelArticolo = false"
          />
          <Button
            colore_bottone="success"
            idForm="ARTICOLO"
            idOggetto="CONFERMA_ELIMINA_ARTICOLO"
            :dark_bottone="true"
            mdi_icona=""
            testo_bottone="Conferma"
            testo_tooltip="Conferma"
            v-on:buttonClick="
              deleteArticolo(selectedArticolo);
              confermaDelArticolo = false;
            "
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog di conferma duplicazione articolo -->
    <v-dialog v-model="conferma_duplica_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Conferma Duplicazione
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  duplicaArticolo();
                  conferma_duplica_dialog = false;
                "
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">mdi-floppy</v-icon>
              </v-btn>
            </template>
            <span>Duplica</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <!-- Bottone di annullamento -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  conferma_duplica_dialog = false;
                  cod_articolo_duplicazione = '';
                  des_articolo_duplicazione = '';
                  des_aggiuntiva_duplicazione = '';
                "
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px"
            >Per confermare la duplicazione, inserire il Codice e/o la
            Descrizione del nuovo articolo da creare.</span
          >
          <v-container>
            <v-row align="center" justify="center">
              <v-col class="align-center d-flex justify-end" cols="4">
                <v-text-field
                  class="mr-5"
                  hide-details
                  label="Cod. Articolo"
                  style="max-width: 200px"
                  v-model="cod_articolo_duplicazione"
                ></v-text-field>
              </v-col>
              <v-col class="align-center d-flex justify-start" cols="4">
                <v-text-field
                  class="ml-5"
                  hide-details
                  label="Des. Articolo"
                  style="max-width: 200px"
                  v-model="des_articolo_duplicazione"
                ></v-text-field>
              </v-col>
              <v-col class="align-center d-flex justify-start" cols="4">
                <v-text-field
                  class="ml-5"
                  hide-details
                  label="Descrizione Aggiuntiva"
                  style="max-width: 200px"
                  v-model="des_aggiuntiva_duplicazione"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog degli allegati -->
    <v-dialog v-model="gestione_allegati_visible">
      <GestioneAllegato
        @click_chiudi="gestione_allegati_visible = false"
        controller_allegati="Global"
        controller_caratteristiche_tabella="Manutenzione"
        :id_riferimento="id_articolo"
        :read_only="false"
        ref="componenteAllegati"
        @salva_allegati="salvaAllegati"
        :show_save_button="true"
        @snackbarOpen="snackbarOpen"
        tipo_allegato="ARTICOLO"
      />
    </v-dialog>

    <!-- TO show loader in Loading state -->
    <v-overlay :value="overlay" style="z-index: 10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import ArticoloDrawer from "./ArticoloDrawer/ArticoloDrawer.vue";
import ArticoloSearchModal from "./ArticoloSearchModal";
import Button from "../../../components/TOOLS/FormComponentPermissions/Button.vue";
import GestioneAllegato from "@/components/TOOLS/GestioneAllegato.vue";
import TextField from "../../../components/TOOLS/FormComponentPermissions/TextField.vue";

export default {
  name: "Articolo",
  // Child Components of Articolo Component
  components: {
    ArticoloDrawer,
    ArticoloSearchModal,
    Button,
    GestioneAllegato,
    Modules: () => import("../../../components/TOOLS/Modules"),
    TextField,
  },
  // ArticoloId if it is called in distinta base for detail of component
  props: {
    articoloId: { type: Number, default: null },
    back_as_landing_page: { type: Boolean, default: true },
    rotta_back: { type: String, default: "/home/production" },
  },
  data() {
    return {
      // for testing
      id_famiglia1: -1,
      id_tipo1: -1,
      // Variables needed for this page
      selectedArticolo: null,
      // Dialog Variable to delete An articolo
      confermaDelArticolo: false,
      // PDF Values
      pdfdialog: false,
      selectedPdf: "",
      item: "",
      des: "",
      progressBar: true,
      loadingVal: false,
      dialogModificheDaSalvare: false,
      dialogAvvisoModificheNonSalvate: false,
      header: [],
      // Render Copy Articolo button
      isCopy: false,
      // New var for sub Tipo
      disSubTipo: true,
      subtipoList: [],
      id_sub_tipo: null,
      id_sotto_famiglia: null,
      sottoFamList: [], // Header for table of the main search page or main articolo page
      headers2: [
        {
          align: "",
          value: "detail",
          text: "",
          sortable: false,
        },
        {
          text: "Cod. Articolo",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. articolo",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "id_tipo",
          sortable: false,
        },
        {
          text: "Famiglia",
          value: "id_famiglia",
          sortable: false,
        },
        {
          text: "Fornitura",
          value: "id_tipo_fornitura",
          sortable: false,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
        },
      ],
      actualItems: [],
      items: [],
      mizura_list: [],
      materiale_list: [],
      contentoreList: [],
      imballoList: [],
      fornitura_list: [],
      headers: [],
      famiglia_list: [],
      tipoList: [],
      completeData: [],
      copyInfo: [],
      allegato: [],
      dialogAggiungiAllegato: false,
      cod_articolo_alteration: false,

      packging: {},
      cod_articolo_enable: false,
      immagine_preview: "",
      topDiv: true,

      showadd: true,
      showupdate: false,
      overlay: false,
      dialog: false,
      conferma_duplica_dialog: false,
      cod_articolo_duplicazione: "",
      des_articolo_duplicazione: "",
      des_aggiuntiva_duplicazione: "",
      hideField: true,
      savebutton: false,
      showicon: true,
      disableAppBar: true,
      gestione_allegati_visible: false,

      search: "",
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      id_tipo_fornitura: "",

      des_articolo: "",
      codArticolo: "",
      cod_articolo: "",
      id_articolo: "",
      id_tipo: "",
      id_famiglia: "",

      articoloDetail: "",
      tableData: "",
      content_cod_articolo: "",
      imballo_cod_articolo: "",
      des_aggiuntiva: "",
      title: "Image Upload",
      tipo_articolo: "",
      famiglia_articolo: "",
    };
  },
  methods: {
    // To Resize Image
    resizeImage(img, id) {
      if (img.width >= img.height && img.width > 100) {
        let width_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let height_nuova = (width_nuova * img.height) / img.width;

        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else if (img.height > img.width && img.height > 100) {
        let height_nuova = 100;
        // Proporzione -> width_nuova : width = height_nuova : height
        let width_nuova = (height_nuova * img.width) / img.height;
        document.getElementById(id).style.width = width_nuova + "px";
        document.getElementById(id).style.height = height_nuova + "px";
      } else {
        document.getElementById(id).style.width = img.width + "px";
        document.getElementById(id).style.height = img.height + "px";
      }
    },
    // PDF MOdal
    isPdf(elem) {
      if (elem) {
        if (elem.startsWith("data:application/pdf")) {
          return true;
        } else {
          return false;
        }
      }
    },
    // to open Modal for PDF
    openPdfAppenaCaricato(item) {
      if (item.startsWith("data:application/pdf")) {
        this.selectedPdf = item;
        this.pdfdialog = true;
      }
    },
    // Function to add sub tipo
    aggiornaSubTipoArticolo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "SUB_TIPO_ARTICOLO",
          filter: `validita = 1`,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.subtipoList = res.data;
      });
    },
    // Function to add Sub Famiglia
    aggiornaSottoFamiglia() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "SOTTO_FAMIGLIA",
          filter: `validita = 1`,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.sottoFamList = res.data;
      });
    },
    // Function to add new famiglia in famiglia list
    aggiornaFamiglia() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "Famiglia",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.famiglia_list = res.data;
      });
    },
    // Function to add a new fornitura in fornitura list
    aggiornaFornitura() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "FORNITURA_STANDARD",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.fornitura_list = res.data;
      });
    },
    // Function to add a new tipo in tipo list
    aggiornaTipoArticolo() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          tipo: "TIPOARTICOLO",
          filter: "validita = 1",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.tipoList = res.data;
      });
    },
    // in order to close allegati modal
    clearImage() {
      this.dialogAggiungiAllegato = false;
    },
    clickDuplicaArticolo() {
      this.conferma_duplica_dialog = true;
    },
    // In order to clear all the form fields
    clearFromFields() {
      this.showupdate = false;
      this.showadd = true;
      this.savebutton = true;
      this.showicon = true;
      this.hideField = false;
      this.cod_articolo_enable = true;
      this.cod_articolo_alteration = false;
      this.completeData = [];
      this.articoloDetail = {};
      this.cod_articolo = "";
      this.des_articolo = "";
      this.des_aggiuntiva = "";
      this.dialogModificheDaSalvare = false;
    },
    clickGestioneAllegati() {
      this.gestione_allegati_visible = true;
    },
    // In order to disable Codice Articolo
    disabled_Codice(val) {
      if (val !== false) {
        this.cod_articolo_enable = true;
        this.cod_articolo_alteration = true;
      } else {
        this.cod_articolo_alteration = false;
        this.cod_articolo_enable = false;
      }
    },
    duplicaArticolo() {
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "duplicatearticle",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo,
          cod_articolo: this.cod_articolo_duplicazione,
          des_articolo: this.des_articolo_duplicazione,
          des_aggiuntiva: this.des_aggiuntiva_duplicazione,
        },
      };
      // Attivo l'overlay di caricamento.
      this.overlay = true;
      // Eseguo la chiamata al BE
      this.$store
        .dispatch("api", search_Articolo)
        .then((res) => {
          this.cod_articolo_duplicazione = "";
          this.des_articolo_duplicazione = "";
          this.des_aggiuntiva_duplicazione = "";

          // Appena ricevo una risposta, blocco l'overlay di caricamento
          this.overlay = false;

          // Se la risposta è in stato 200...
          if (res.status === 200) {
            console.log("ok");
          } else {
            // Se invece non è in stato 200 stampo una snackbar di errore.
            this.snackbar = true;
            this.snackbar_background = "orange";
            this.snackbar_icon = "mdi-alert";
            this.snackbar_icon_color = "white";
            this.snackbar_text = `Una criticità tecnica ha impedito di duplicare l'articolo; codice errore: ${res.status}`;
            this.snackbar_text_color = "white";
          }
        })
        .catch((e) => {
          this.cod_articolo_duplicazione = "";
          this.des_articolo_duplicazione = "";
          this.des_aggiuntiva_duplicazione = "";
          this.overlay = false;
          if (
            e.response.status == 400 ||
            e.response.status == 404 ||
            e.response.status == undefined
          ) {
            this.snackbar = true;
            this.snackbar_background = "red";
            this.snackbar_icon = "mdi-close-outline";
            this.snackbar_icon_color = "white";
            this.snackbar_text = JSON.parse(e.response.data.message)["result"];
            this.snackbar_text_color = "white";
          }
        });
    },
    // Function triggered when search is being triggered in main articolo page
    searchArticolo() {
      this.loadingVal = true;
      this.progressBar = true;
      this.items = [];
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
          id_famiglia: this.id_famiglia || "",
          id_tipo: this.id_tipo || "",
          id_tipo_fornitura: this.id_tipo_fornitura || "",
        },
      };
      // Attivo l'overlay di caricamento.
      this.overlay = true;
      // Eseguo la chiamata al BE
      this.$store.dispatch("api", search_Articolo).then((res) => {
        this.overlay = false;
        this.actualItems = res.data.lista;
        if (this.actualItems.length > 0) {
          this.loadingVal = false;
          this.progressBar = false;
        }
        if (this.actualItems.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
          this.snackbar_text_color = "white";
          this.loadingVal = false;
          this.progressBar = false;
        }
        this.items = this.actualItems;
        if (this.items.length > 0) {
          this.progressBar = false;
        }
        if (this.items.length === 1) {
          this.cod_articolo_alteration = false;
          this.cod_articolo_enable = false;
          this.topDiv = false;
          this.dialog = false;
          this.isCopy = true;
          this.savebutton = true;
          this.showupdate = true;
          this.showadd = false;
          this.articoloDetail = JSON.parse(
            JSON.stringify(res.data.dettaglio[0])
          );
          this.hideField = false;
          this.des_articolo = this.articoloDetail.des_articolo;
          this.id_tipo_fornitura = this.articoloDetail.fornitura_articolo;
          this.id_tipo1 = this.articoloDetail.id_tipo;
          this.id_famiglia1 = this.articoloDetail.id_famiglia;
          this.des_aggiuntiva = this.articoloDetail.des_aggiuntiva;
          this.immagine_preview = this.articoloDetail.immagine_preview;
          var image = new Image();
          image.src = this.immagine_preview;
          let that = this;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          this.cod_articolo = this.articoloDetail.cod_articolo;
          this.id_articolo = this.articoloDetail.id_articolo
            ? this.articoloDetail.id_articolo
            : "";
          if (this.id_tipo_fornitura === "") {
            this.id_tipo_fornitura = 1;
            return this.id_tipo_fornitura;
          }
          this.tipoList.map((el) => {
            if (this.id_tipo === el.id_tabella) {
              this.id_tipo = el.des;
              return this.id_tipo;
            }
          });
          this.famiglia_list.map((el) => {
            if (this.id_famiglia === el.id_tabella) {
              this.id_famiglia = el.des;
              return this.id_famiglia;
            }
          });
        }
        // Dato che ho avuto la risposta dal BE nascondo l'overlay di caricamento.
        this.overlay = false;
      });
    },
    salvaAllegati() {
      console.log("entrato salvaAllegati");
      // Salvo gli allegati dell'intervento.
      this.$refs.componenteAllegati.id_riferimento_creazione_genitore = this.id_articolo;
      this.$refs.componenteAllegati.salvaTuttiAllegati();

      // Chiudo il dialog degli allegati.
      this.gestione_allegati_visible = false;
    },
    // In order to go back to search articolo lista from detail page
    GobacktoLista() {
      this.topDiv = true;
      this.isCopy = false;
      this.dialog = false;
      this.hideField = true;
      this.cod_articolo = "";
      this.des_articolo = "";
      this.des_aggiuntiva = "";
      this.id_tipo = "";
      this.id_tipo_fornitura = "";
      this.id_famiglia = "";
      this.articoloDetail = {};
      this.dialogAvvisoModificheNonSalvate = false;
      this.cod_articolo_enable = false;
      this.cod_articolo_alteration = false;
      this.progressBar = true;
    },
    // redirect to articolo main page
    GoBack() {
      // this.showicon = true
      this.dialog = false;
      if (this.copyInfo.length > 0) {
        this.actionItems = this.copyInfo;
      }
      if (this.cod_articolo_alteration) {
        this.dialogAvvisoModificheNonSalvate = true;
      }
      if (!this.cod_articolo_alteration) {
        if (this.items.length > 0) {
          this.loadingVal = false;
        }
        this.topDiv = true;
        this.showupdate = false;
        this.showadd = true;
        this.isCopy = false;
        this.showicon = true;
        this.hideField = true;
        this.cod_articolo = "";
        this.des_articolo = "";
        this.des_aggiuntiva = "";
        this.id_tipo = "";
        this.id_tipo_fornitura = "";
        this.id_famiglia = "";
        this.articoloDetail = {};
        this.cod_articolo_enable = false;
        this.cod_articolo_alteration = false;
        this.progressBar = true;
      }
    },
    // TO create a view in order to have an empty form to create a new articolo
    showArticoloForm() {
      this.topDiv = false;
      this.dialog = false;
      this.isCopy = false;
      this.hideField = false;
      this.showicon = false;
      this.showupdate = true;
      this.id_famiglia1 = "";
      this.id_tipo1 = "";
      this.showadd = false;
      this.completeData = [];
      this.articoloDetail = {};
      this.immagine_preview = "";
      this.cod_articolo = "";
      this.des_articolo = "";
      this.des_aggiuntiva = "";
      this.id_articolo = "";
      this.id_tipo_fornitura = "";
      this.cod_articolo_enable = true;
      this.cod_articolo_alteration = false;
      this.savebutton = true;
    },
    // redirected to production dashboard
    gotoDashboard() {
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back);
      } else {
        this.$emit("click_chiudi");
      }
    },
    // Function to handle pasted url of image
    onPaste(e) {
      if (e.clipboardData != false) {
        var items = e.clipboardData.items;
        if (items != undefined) {
          for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            console.log(blob);
          }
        }
      }
      let that = this;
      this.retrieveImageFromClipboardAsBase64(e, function (imageDataBase64) {
        that.immagine_preview = imageDataBase64;
      });
    },
    openImagePicker() {
      this.$refs.allegato.click();
    },
    hasExtension(file, exts) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        file.name
      );
    },
    // Function to select an image and change it to base64
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (
        this.hasExtension(files[0], [
          ".jpeg",
          ".gif",
          ".png",
          ".jpg",
          ".pdf",
          ".PNG",
        ])
      ) {
        this.allegato = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.allegato);
        let that = this;
        reader.onload = function () {
          var image = new Image();
          image.src = event.target.result;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          that.immagine_preview = reader.result;
          localStorage.setItem("ArticoloImage", that.immagine_preview);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        this.dialogAggiungiAllegato = false;
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Formato non valido";
        this.snackbar_text_color = "white";
      }
      this.dialogAggiungiAllegato = false;
    },
    retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat) {
      if (pasteEvent.clipboardData == false) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if (items == undefined) {
        if (typeof callback == "function") {
          callback(undefined);
        }
      }
      for (var i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = items[i].getAsFile();
        // Create an abstract canvas and get context
        var mycanvas = document.createElement("canvas");
        var ctx = mycanvas.getContext("2d");
        // Create an image
        var img = new Image();
        // Once the image loads, render the img on the canvas
        img.onload = function () {
          // Update dimensions of the canvas with the dimensions of the image
          mycanvas.width = this.width;
          mycanvas.height = this.height;
          // Draw the image
          ctx.drawImage(img, 0, 0);
          // Execute callback with the base64 URI of the image
          if (typeof callback == "function") {
            callback(mycanvas.toDataURL(imageFormat || "image/png"));
          }
        };
        // Crossbrowser support for URL
        var URLObj = window.URL || window.webkitURL;
        // Creates a DOMString containing a URL representing the object given in the parameter
        // namely the original Blob
        img.src = URLObj.createObjectURL(blob);
      }
      this.dialogAggiungiAllegato = false;
    },
    // Switching back and forth between main page and detail page
    hideIcons() {
      if (this.cod_articolo_alteration) {
        this.dialogModificheDaSalvare = true;
      }
      if (!this.cod_articolo_alteration) {
        this.showupdate = true;
        this.showadd = false;
        this.savebutton = true;
        this.showicon = false;
        this.hideField = false;
        this.cod_articolo_enable = true;
        this.completeData = [];
        this.articoloDetail = {};
        this.cod_articolo = "";
        this.des_articolo = "";
        this.des_aggiuntiva = "";
        this.id_tipo = "";
        this.id_tipo_fornitura = "";
        this.id_famiglia = "";
      }
    },
    // Changing the view and hiding the corresponding fields or icons
    hideIconS() {
      this.showupdate = false;
      this.showadd = true;
      this.savebutton = false;
      this.hideField = true;
      // this.overlay = true;
      this.showicon = true;
      this.des_articolo = "";
      this.id_tipo_fornitura = "";
      this.id_tipo = "";
      this.id_famiglia = "";
      this.des_aggiuntiva = "";
      this.articoloDetail = {};
      this.articoloDetail = [];
      this.cod_articolo_enable = true;
      this.cod_articolo_alteration = false;
    },
    remove() {
      this.id_famiglia = {};
    },
    onClearClicked() {
      this.cod_articolo = "";
      // this.overlay = true;
      this.completeData = [];
      this.id_articolo = "";
      this.dialog = false;
      this.hideField = true;
      this.savebutton = false;
      this.articoloDetail = {};
      this.des_articolo = "";
      this.id_tipo_fornitura = "";
      this.id_tipo = "";
      this.id_famiglia = "";
      this.des_aggiuntiva = "";
      this.cod_articolo_alteration = false;
    },
    sendVal() {
      this.topDiv = false;
    },
    changeHandler() {
      if (this.cod_articolo.length < 1) {
        this.cod_articolo_alteration = false;
      }
    },
    hideDetail() {
      this.hideField = true;
      this.dialog = false;
      // this.overlay = true;
      this.cod_articolo_alteration = false;
      this.articoloDetail = {};
      this.des_articolo = "";
      this.id_tipo = "";
      this.id_famiglia = "";
      this.id_tipo_fornitura = "";
      this.des_aggiuntiva = "";
    },
    updateArticle(val) {
      this.articoloDetail = val;
    },
    updatePackging(val) {
      this.packging = val;
      this.savebutton = true;
    },
    snackbarOpen() {
      //funzione triggerata per cogliere l' errore degli allegati che non hanno la descrizione inserita
      this.snackbar = true;
      this.snackbar_background = "orange";
      this.snackbar_icon = "mdi-alert";
      this.snackbar_icon_color = "white";
      this.snackbar_text =
        "Aggiungere ad ogni Allegato dell'attività una descrizione.";
      this.snackbar_text_color = "white";
    },
    // To delete an  articolo
    deleteArticolo(val) {
      this.overlay = true;
      let data = {};
      data.id_articolo = val.id_articolo;
      data.validita = 0;
      let articolo = [data];
      let delArticolo = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolo",
          token: localStorage.getItem("user_token"),
          articolo: articolo,
        },
      };
      this.$store.dispatch("api", delArticolo).then(() => {
        this.overlay = false;
        this.snackbar = true;
        this.snackbar_background = "success";
        this.snackbar_icon = "mdi-check";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Modifica effettuata con successo!";
        this.snackbar_text_color = "white";
      });
      const i = this.items.findIndex(
        (it) => it.id_articolo === data.id_articolo
      );
      this.items.splice(i, 1);
    },
    // In order to save a new articolo or update an existing articolo
    salvaArticolo() {
      this.overlay = true;
      let search_Articolo = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
        },
      };
      this.$store.dispatch("api", search_Articolo).then((res) => {
        if (res.status === 200) {
          // Salvo gli allegati dell'articolo
          // if (this.$refs.componenteAllegati) {
          //   // Salvo gli allegati dell'intervento.
          //   this.$refs.componenteAllegati.id_riferimento_creazione_genitore = this.id_articolo;
          //   this.$refs.componenteAllegati.salvaTuttiAllegati();
          // }

          // Si procede con il resto della logica
          if (
            res.data.lista.length == 0 ||
            this.articoloDetail.cod_articolo == this.cod_articolo
          ) {
            if (!this.articoloDetail.id_articolo) {
              if (!this.cod_articolo) {
                this.snackbar = true;
                this.snackbar_background = "orange";
                this.snackbar_icon = "mdi-alert";
                this.snackbar_icon_color = "white";
                this.snackbar_text = "Il campo Codice Articolo è richiesto!";
                this.snackbar_text_color = "white";
                this.loadingVal = false;
                this.progressBar = false;
                return;
              }
              const data = {};
              data.note = this.articoloDetail.note;
              data.cod_articolo = this.cod_articolo;
              data.des_articolo = this.des_articolo;
              data.id_tipo = this.id_tipo1;
              data.id_famiglia = this.id_famiglia1;
              data.id_tipo_fornitura = this.id_tipo_fornitura;
              data.famiglia_articolo = this.id_famiglia.des;
              data.fornitura_articolo = this.id_tipo_fornitura.des;
              data.tipo_articolo = this.id_tipo.des;
              data.des_aggiuntiva = this.des_aggiuntiva;
              data.immagine_preview = localStorage.getItem("ArticoloImage");
              data.id_articolo_contenitore_standard = this.articoloDetail.id_articolo_contenitore_standard;
              data.id_articolo_imballo_standard = this.articoloDetail.id_articolo_imballo_standard;
              data.id_materiale = this.articoloDetail.id_materiale;
              data.id_um = this.articoloDetail.id_um;
              data.vendita_kit = this.articoloDetail.vendita_kit;
              data.l = parseInt(this.articoloDetail.l);
              data.p = this.articoloDetail.p;
              data.h = this.articoloDetail.h;
              data.id_um_fisica = this.articoloDetail.id_um_fisica;
              data.peso_netto = this.articoloDetail.peso_netto;
              data.peso_lordo = this.articoloDetail.peso_lordo;
              data.peso_specifico = this.articoloDetail.peso_specifico;
              data.id_um_peso = this.articoloDetail.id_um_peso;
              data.volume = this.articoloDetail.volume;
              data.id_um_volume = this.articoloDetail.id_um_volume;
              this.contentoreList.map((el) => {
                if (data.id_articolo_contenitore_standard === el.cod_articolo) {
                  data.id_articolo_contenitore_standard = el.id_articolo;
                }
              });
              this.imballoList.map((el) => {
                if (data.id_articolo_imballo_standard === el.cod_articolo) {
                  data.id_articolo_imballo_standard = el.id_articolo;
                  // return this.articoloDetail;
                }
              });
              this.tipoList.map((el) => {
                if (this.id_tipo === el.id_tabella) {
                  data.tipo_articolo = el.des;
                  data.id_tipo = el.id_tabella;
                }
              });
              this.famiglia_list.map((el) => {
                if (this.id_famiglia === el.id_tabella) {
                  data.famiglia_articolo = el.des;
                  data.id_famiglia = el.id_tabella;
                }
              });
              this.fornitura_list.map((el) => {
                if (this.id_tipo_fornitura === el.des) {
                  data.fornitura_articolo = el.des;
                  data.id_tipo_fornitura = el.id_tabella;
                }
              });
              this.materiale_list.map((el) => {
                if (data.id_materiale === el.id_tabella) {
                  data.materiale_articolo = el.des;
                  data.id_materiale = el.id_tabella;
                }
              });
              this.mizura_list.map((el) => {
                if (data.id_um === el.id_tabella) {
                  data.um_articolo = el.des;
                  data.id_um = el.id_tabella;
                }
              });
              data.id_articolo = -1;
              data.validita = 1;
              let dataToPush = {};
              dataToPush.cod_articolo = this.cod_articolo;
              dataToPush.des_articolo = this.des_articolo;
              dataToPush.id_tipo = this.id_tipo;
              dataToPush.id_famiglia = this.id_famiglia;
              dataToPush.id_tipo_fornitura = this.id_tipo_fornitura;
              this.items.push(dataToPush);
              let articolo = [];
              articolo.push(data);
              let setArticolo = {
                controller: "Magazzino",
                method: "PUT",
                richiesta: {
                  action: "setarticolo",
                  token: localStorage.getItem("user_token"),
                  articolo: articolo,
                },
              };
              this.$store.dispatch("api", setArticolo).then((res) => {
                this.overlay = false;
                if (res.data) {
                  this.snackbar = true;
                  this.snackbar_background = "success";
                  this.snackbar_icon = "mdi-check";
                  this.snackbar_icon_color = "white";
                  this.snackbar_text = "Modifica effettuata con successo!";
                  this.snackbar_text_color = "white";
                }
              });
              this.immagine_preview = "";
              localStorage.removeItem("ArticoloImage");
              this.cod_articolo_enable = false;
              this.cod_articolo_alteration = false;
              this.hideIconS();
              this.topDiv = true;
              this.cod_articolo = "";
            }
            if (this.articoloDetail.id_articolo) {
              this.articoloDetail.cod_articolo = this.cod_articolo;
              this.articoloDetail.des_articolo = this.des_articolo;
              this.articoloDetail.id_tipo = this.id_tipo1;
              this.articoloDetail.id_famiglia = this.id_famiglia1;
              this.articoloDetail.id_tipo_fornitura = this.id_tipo_fornitura;
              this.articoloDetail.famiglia_articolo = this.id_famiglia;
              this.articoloDetail.fornitura_articolo = this.id_tipo_fornitura;
              this.articoloDetail.tipo_articolo = this.id_tipo;
              this.articoloDetail.des_aggiuntiva = this.des_aggiuntiva;
              this.articoloDetail.l = parseInt(this.articoloDetail.l);

              this.articoloDetail.immagine_preview =
                this.immagine_preview == ""
                  ? localStorage.getItem("ArticoloImage")
                  : this.immagine_preview;
              this.articoloDetail.validita = 1;
              this.contentoreList.map((el) => {
                if (
                  this.articoloDetail.id_articolo_contenitore_standard ===
                  el.cod_articolo
                ) {
                  this.articoloDetail.id_articolo_contenitore_standard =
                    el.id_articolo;
                  return this.articoloDetail;
                }
              });
              this.imballoList.map((el) => {
                if (
                  this.articoloDetail.id_articolo_imballo_standard ===
                  el.cod_articolo
                ) {
                  this.articoloDetail.id_articolo_imballo_standard =
                    el.id_articolo;
                  return this.articoloDetail;
                }
              });
              this.tipoList.map((el) => {
                if (this.articoloDetail.id_tipo === el.id_tabella) {
                  this.articoloDetail.tipo_articolo = el.des;
                  this.articoloDetail.id_tipo = el.id_tabella;
                  return this.articoloDetail;
                }
              });
              this.famiglia_list.map((el) => {
                if (this.articoloDetail.id_famiglia === el.id_tabella) {
                  this.articoloDetail.famiglia_articolo = el.des;
                  this.articoloDetail.id_famiglia = el.id_tabella;
                  return this.articoloDetail;
                }
              });
              this.fornitura_list.map((el) => {
                if (this.id_tipo_fornitura === el.des) {
                  this.articoloDetail.fornitura_articolo = el.des;
                  this.articoloDetail.id_tipo_fornitura = el.id_tabella;
                  return this.articoloDetail;
                }
              });
              this.materiale_list.map((el) => {
                if (this.articoloDetail.id_materiale === el.id_tabella) {
                  this.articoloDetail.materiale_articolo = el.des;
                  return this.articoloDetail;
                }
              });
              this.mizura_list.map((el) => {
                if (this.articoloDetail.id_um === el.id_tabella) {
                  this.articoloDetail.um_articolo = el.des;
                  return this.articoloDetail;
                }
              });
              let articolo = [];
              articolo.push(this.articoloDetail);
              let setArticolo = {
                controller: "Magazzino",
                method: "PUT",
                richiesta: {
                  action: "setarticolo",
                  articolo: articolo,
                  token: localStorage.getItem("user_token"),
                },
              };
              this.$store.dispatch("api", setArticolo).then((res) => {
                this.overlay = false;
                if (res.data) {
                  this.snackbar = true;
                  this.snackbar_background = "success";
                  this.snackbar_icon = "mdi-check";
                  this.snackbar_icon_color = "white";
                  this.snackbar_text = "Modifica effettuata con successo!";
                  this.snackbar_text_color = "white";
                }
              });
              localStorage.removeItem("ArticoloImage");
              this.immagine_preview = "";
              if (this.items.length > 0) {
                const data = {};
                data.cod_articolo = this.articoloDetail.cod_articolo;
                data.des_articolo = this.articoloDetail.des_articolo;
                this.tipoList.map((it) => {
                  if (it.id_tabella === this.articoloDetail.id_tipo) {
                    data.id_tipo = it.des;
                    data.tipo_articolo = it.des;
                  }
                });
                this.famiglia_list.map((it) => {
                  if (it.id_tabella === this.articoloDetail.id_famiglia) {
                    data.id_famiglia = it.des;
                    data.famiglia_articolo = it.des;
                  }
                });
                this.fornitura_list.map((it) => {
                  if (it.id_tabella === this.articoloDetail.id_tipo_fornitura) {
                    data.id_tipo_fornitura = it.des;
                    data.fornitura_articolo = it.des;
                  }
                });
                const i = this.items.findIndex(
                  (it) => it.id_articolo === this.articoloDetail.id_articolo
                );
                this.items.splice(i, 1, data);
                this.hideIconS();
                this.topDiv = true;
                this.cod_articolo_enable = true;
                this.cod_articolo_alteration = false;
              }
            }
          }
          if (res.data.lista.length > 0 && this.id_articolo > -1) {
            if (this.articoloDetail.cod_articolo != this.cod_articolo) {
              this.snackbar = true;
              this.snackbar_background = "orange";
              this.snackbar_icon = "mdi-alert";
              this.snackbar_icon_color = "white";
              this.snackbar_text = "Codice articolo già esistente";
              this.snackbar_text_color = "white";
            }
          }
        }
      });
    },
    // Search Function triggered within the detail page
    searchResult() {
      this.cod_articolo_alteration = false;
      // this.loadingVal = true
      this.articoloDetail = {};
      this.completeData = [];
      this.des_articolo = "";
      this.des_aggiuntiva = "";
      this.id_tipo = "";
      this.id_famiglia = "";
      this.id_tipo_fornitura = "";
      if (this.cod_articolo === "") {
        return;
      }
      if (this.showicon === false) {
        return;
      }
      this.codArticolo = this.cod_articolo;
      this.tipoList.map((ti) => {
        if (ti.des === this.id_tipo) {
          this.id_tipo = ti.id_tabella;
        }
      });
      let search_Arti_req2 = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo,
          id_famiglia:
            this.id_famiglia.id_tabella == undefined
              ? ""
              : this.id_famiglia.id_tabella,
          id_tipo:
            this.id_tipo.id_tabella == undefined ? "" : this.id_tipo.id_tabella,
          id_tipo_fornitura:
            this.id_tipo_fornitura == undefined ? "" : this.id_tipo_fornitura,
        },
      };
      // Attivo l'overlay di caricamento
      this.overlay = true;
      // Eseguo la chiamata al BE
      this.$store.dispatch("api", search_Arti_req2).then((res) => {
        this.completeData = res.data;
        if (this.completeData.lista.length > 1) {
          this.tableData = this.completeData.lista;
          if (!this.topDiv) {
            this.topDiv = false;
          } else {
            this.topDiv = true;
          }
          this.savebutton = false;
          this.dialog = true;
        }
        if (this.completeData.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "L'articolo non è stato trovato. Riprova !";
          this.snackbar_text_color = "white";
          this.loadingVal = false;
          this.progressBar = false;
          this.savebutton = false;
          this.topDiv = true;
        }
        if (this.completeData.lista.length === 1) {
          this.overlay = false;
          this.cod_articolo_alteration = false;
          this.cod_articolo_enable = false;
          this.isCopy = true;
          this.topDiv = false;
          this.dialog = false;
          this.savebutton = true;
          this.showupdate = true;
          this.showadd = false;
          this.articoloDetail = JSON.parse(
            JSON.stringify(this.completeData.dettaglio[0])
          );
          this.cod_articolo = this.articoloDetail.cod_articolo;
          this.id_articolo = this.articoloDetail.id_articolo
            ? this.articoloDetail.id_articolo
            : "";
          this.hideField = false;
          this.des_articolo = this.articoloDetail.des_articolo;
          this.id_tipo_fornitura = this.articoloDetail.fornitura_articolo;
          this.id_tipo1 = this.articoloDetail.id_tipo;
          this.id_famiglia1 = this.articoloDetail.id_famiglia;
          this.des_aggiuntiva = this.articoloDetail.des_aggiuntiva;
          this.immagine_preview = this.articoloDetail.immagine_preview;
          let that = this;
          var image = new Image();
          image.src = this.immagine_preview;
          image.onload = function () {
            that.resizeImage(image, "immagine");
          };
          this.savebutton = true;
          this.codArticolo = this.cod_articolo;
          if (this.id_tipo_fornitura === "") {
            this.id_tipo_fornitura = 1;
            return this.id_tipo_fornitura;
          }
          this.tipoList.map((el) => {
            if (this.id_tipo === el.id_tabella) {
              this.id_tipo = el.des;
              return this.id_tipo;
            }
          });
          this.famiglia_list.map((el) => {
            if (this.id_famiglia === el.id_tabella) {
              this.id_famiglia = el.des;
              return this.id_famiglia;
            }
          });
        }
        // Disattivo l'overlay di caricamento
        this.overlay = false;
      });
    },
    //In order to get detail of single articolo when button is clicked
    onButtonClick(value) {
      // this.cod_articolo_enable = true
      // this.cod_articolo_alteration = false
      this.overlay = true;
      let search_Arti_req = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: value.cod_articolo,
          // id_articolo:value.id_articolo,
          id_famiglia: (this.id_famiglia = ""),
          id_tipo: (this.id_tipo = ""),
        },
      };
      this.$store.dispatch("api", search_Arti_req).then((res) => {
        this.completeData = res.data;
        this.overlay = false;
        if (this.completeData.lista.length > 1) {
          this.topDiv = true;
          this.savebutton = false;
        }
        if (this.completeData.lista.length === 0) {
          this.topDiv = true;
          this.savebutton = false;
        }
        this.topDiv = false;
        this.dialog = false;
        this.savebutton = true;
        this.showupdate = true;
        this.showadd = false;
        this.isCopy = true;
        this.articoloDetail = this.completeData.dettaglio[0];
        this.hideField = false;
        this.des_articolo = this.articoloDetail.des_articolo;
        this.cod_articolo = this.articoloDetail.cod_articolo;
        this.id_articolo = this.articoloDetail.id_articolo
          ? this.articoloDetail.id_articolo
          : "";
        this.id_tipo1 = this.articoloDetail.id_tipo;
        this.id_famiglia1 = this.articoloDetail.id_famiglia;
        this.id_tipo_fornitura = this.articoloDetail.fornitura_articolo;
        this.des_aggiuntiva = this.articoloDetail.des_aggiuntiva;
        this.immagine_preview = this.articoloDetail.immagine_preview;
        let that = this;
        var image = new Image();
        image.src = this.immagine_preview;
        image.onload = function () {
          that.resizeImage(image, "immagine");
        };
      });
      this.dialog = false;
      this.cod_articolo_enable = false;
      this.cod_articolo_alteration = false;
    },
    // Search Function triggered in Articolo Detail
    searchResult1(val) {
      let search_Arti_req = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          cod_articolo: val.cod_articolo,
          id_famiglia:
            val.id_famiglia.id_tabella == undefined
              ? ""
              : this.id_famiglia.id_tabella,
          id_tipo:
            val.id_tipo.id_tabella == undefined ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", search_Arti_req).then((res) => {
        this.completeData = res.data;
        if (this.completeData.lista.length > 1) {
          // this.overlay = false;
          this.topDiv = true;
          this.dialog = true;
          this.tableData = this.completeData.lista;
        }
        if (this.completeData.lista.length === 0) {
          this.tableData = [];
          this.snackbar = true;
          this.dialog = false;
        }
        this.articoloDetail = JSON.parse(
          JSON.stringify(this.completeData.dettaglio[0])
        );
        this.overlay = false;
        this.isCopy = true;
        this.des_articolo = this.articoloDetail.des_articolo;
        this.id_tipo_fornitura = this.articoloDetail.id_tipo_fornitura;
        this.id_tipo1 = this.articoloDetail.id_tipo;
        this.id_famiglia1 = this.articoloDetail.id_famiglia;
        this.id_tipo = this.articoloDetail.id_tipo;
        this.id_famiglia = this.articoloDetail.id_famiglia;
        this.des_aggiuntiva = this.articoloDetail.des_aggiuntiva;
        this.immagine_preview = this.articoloDetail.immagine_preview;
        let that = this;
        var image = new Image();
        image.src = this.immagine_preview;
        image.onload = function () {
          that.resizeImage(image, "immagine");
        };
        this.tipoList.map((el) => {
          if (this.id_tipo === el.id_tabella) {
            this.id_tipo = el.des;
            return this.id_tipo;
          }
        });
        this.famiglia_list.map((el) => {
          if (this.id_famiglia === el.id_tabella) {
            this.id_famiglia = el.des;
            return this.id_famiglia;
          }
        });
        this.fornitura_list.map((el) => {
          if (this.id_tipo_fornitura === el.id_tipo_fornitura) {
            this.id_tipo_fornitura = el.des;
            return this.id_tipo_fornitura;
          }
        });
        this.hideField = false;
      });
    },
  },
  watch: {
    cod_articolo: function (val) {
      if (val === "") {
        this.cod_articolo_alteration = false;
      }
    },
    immagine_preview: function (val, oldVal) {
      if (!this.cod_articolo_enable) {
        if (!oldVal) {
          this.cod_articolo_alteration = false;
        }
        if (val !== oldVal && oldVal) {
          this.cod_articolo_alteration = true;
        }
      }
    },
    des_articolo: function (val, oldVal) {
      if (!this.cod_articolo_enable) {
        if (!oldVal) {
          this.cod_articolo_alteration = false;
        }
        if (val !== oldVal && oldVal) {
          this.cod_articolo_alteration = true;
        }
      }
    },
    id_tipo1: function (val, oldVal) {
      if (!this.cod_articolo_enable) {
        if (!oldVal) {
          this.cod_articolo_alteration = false;
        }
        if (val !== oldVal && oldVal) {
          this.cod_articolo_alteration = true;
        }
      }
    },
    id_famiglia1: function (val, oldVal) {
      if (!this.cod_articolo_enable) {
        if (!oldVal) {
          this.cod_articolo_alteration = false;
        }
        if (val !== oldVal && oldVal) {
          this.cod_articolo_alteration = true;
        }
      }
    },
    des_aggiuntiva: function (val, oldVal) {
      if (!this.cod_articolo_enable) {
        if (!oldVal) {
          this.cod_articolo_alteration = false;
        }
        if (val !== oldVal && oldVal) {
          this.cod_articolo_alteration = true;
        }
      }
    },
    id_tipo_fornitura: function (val, oldVal) {
      if (!this.cod_articolo_enable) {
        if (!oldVal) {
          this.cod_articolo_alteration = false;
        }
        if (val !== oldVal && oldVal) {
          this.cod_articolo_alteration = true;
        }
      }
    },
  },
  computed: {
    disabled() {
      return this.cod_articolo < 1;
    },
    disabledes() {
      return this.hideField;
    },
    disabletipo() {
      return this.hideField;
    },
    disableFam() {
      return this.hideField;
    },
    disableForn() {
      return this.hideField;
    },
    disableAggun() {
      return this.hideField;
    },
  },
  mounted() {
    /* I emit an event for the sake of correct loading process,
     * when the component is called in an external dynamic form.  */
    this.$emit("whoIsMyFather", {
      customHandler: (arg) => {
        this.onButtonClick(arg[0]);
      },
    });

    // In order to table headers
    let listaArticoli = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "getheader",
        nome_modulo: "lista_articoli",
        token: localStorage.getItem("user_token"),
      },
    };
    let dataarray = [];
    this.$store.dispatch("api", listaArticoli).then((res) => {
      dataarray = res.data;
      dataarray.map((el) => {
        this.header.push({
          text: el.text,
          value: el.value,
        });
      });
      this.header.splice(0, 0, {
        text: "dettaglio",
        value: "actions",
      });
    });
    // In order to get all the lists for select fields
    let fam_list = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo:
          "'Famiglia','TIPOARTICOLO','FORNITURA_STANDARD','SUB_TIPO_ARTICOLO','SOTTO_FAMIGLIA'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", fam_list).then((res) => {
      this.famiglia_list =
        res.data.FAMIGLIA != undefined ? res.data.FAMIGLIA : [];
      this.fornitura_list =
        res.data.FORNITURA_STANDARD != undefined
          ? res.data.FORNITURA_STANDARD
          : [];
      this.tipoList =
        res.data.TIPOARTICOLO != undefined ? res.data.TIPOARTICOLO : [];
      this.subtipoList =
        res.data.SUB_TIPO_ARTICOLO != undefined
          ? res.data.SUB_TIPO_ARTICOLO
          : [];
      this.sottoFamList =
        res.data.SOTTO_FAMIGLIA != undefined ? res.data.SOTTO_FAMIGLIA : [];
    });
    if (this.articoloId) {
      this.disableAppBar = false;
      let search_recursive = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",
          token: localStorage.getItem("user_token"),
          id_articolo: this.articoloId,
          id_famiglia:
            this.id_famiglia.id_tabella == undefined
              ? ""
              : this.id_famiglia.id_tabella,
          id_tipo:
            this.id_tipo.id_tabella == undefined ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", search_recursive).then((res) => {
        this.completeData = res.data;
        this.topDiv = false;
        this.dialog = false;
        this.articoloDetail = JSON.parse(
          JSON.stringify(this.completeData.articolo[0])
        );
        this.hideField = false;
        // this.overlay = false
        this.des_articolo = this.articoloDetail.des_articolo;
        this.id_tipo_fornitura = this.articoloDetail.id_tipo_fornitura;
        this.id_tipo1 = this.articoloDetail.id_tipo;
        this.id_famiglia1 = this.articoloDetail.id_famiglia;
        this.des_aggiuntiva = this.articoloDetail.des_aggiuntiva;
        this.cod_articolo = this.articoloDetail.cod_articolo;
        this.id_articolo = this.articoloDetail.id_articolo
          ? this.articoloDetail.id_articolo
          : "";
        this.immagine_preview = this.articoloDetail.immagine_preview;
        let that = this;
        var image = new Image();
        image.src = this.immagine_preview;
        image.onload = function () {
          that.resizeImage(image, "immagine");
        };
        this.savebutton = true;
      });
    }
  },
};
</script>
<style lang="scss">
.cercaArt:hover {
  background-color: green($color: #000000) !important;
}
.image {
  width: 100%;
  height: 100%;
}
.hoverDiv:hover {
  box-shadow: 20px 10px 100px rgba(33, 33, 33, 0.2);
  z-index: 9;
}
.ModalColor {
  background-color: red;
  color: red;
}
.artDialog {
  overflow-x: hidden;
}
.snackbar {
  height: 150px !important;
}
</style>
