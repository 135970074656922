<template>
  <v-card class="ma-0 pa-0">
    <v-card-text class="ma-0 pa-0">
      <v-data-table
        :headers="headers"
        :items="item_selezionati"
        item-key="nome"
        class="elevation-0"
        height="75vh"
      >
        <template v-slot:top>
          <v-toolbar
            color="indigo"
            dense
            style="position: sticky; top: 0px; z-index: 1"
          >
            <v-toolbar-title class="white--text">
              {{ titolo }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- Salva e Invia Ordine -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="clickSalva" small v-bind="attrs" v-on="on">
                  <v-icon color="success"> mdi-floppy </v-icon>
                </v-btn>
              </template>
              <span>Salva</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <!-- Chiudi Dialog -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on">
                  <v-icon color="red"> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
        </template>

        <!-- Colonna del gruppo -->
        <template v-slot:[`item.des_articolo1`]="{ item }">
          {{ `${item.cod_articolo1} - ${item.des_articolo1}` }}
        </template>

        <!-- Colonna della des. componente -->
        <template v-slot:[`item.des_articolo2`]="{ item }">
          {{ `${item.cod_articolo2} - ${item.des_articolo2}` }}
        </template>

        <!-- Colonna della data produzione -->
        <template v-slot:[`item.data_produzione`]="{ item }">
          {{
            item.data_produzione && item.data_produzione.length > 0
              ? `${item.data_produzione.substring(
                  8,
                  10
                )}/${item.data_produzione.substring(
                  5,
                  7
                )}/${item.data_produzione.substring(0, 4)}`
              : "N/A"
          }}
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Dialog di conferma per invio ordine -->
    <v-dialog v-model="dialog_conferma_ordine" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Conferma Invio</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-4" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi l'ordine delle commesse selezionate?
          </span>
          <v-divider class="mt-3 mb-5"></v-divider>
          <v-container>
            <v-row align="center" justify="center">
              <v-btn class="mr-5" @click="chiudiDialogConfermaOrdine" small>
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
              <v-btn @click="salvaOrdine" small>
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- Snackbar -->
      <SnackbarQV ref="SnackbarQV_dialog_conferma_ordine" />
    </v-dialog>

    <!-- Dialog di conferma per invio spedizione -->
    <v-dialog v-model="dialog_conferma_spedizione" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">Conferma Invio</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-4" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi la spedizione delle commesse selezionate?
          </span>
          <v-divider class="mt-3 mb-5"></v-divider>
          <v-container>
            <v-row align="center" justify="center">
              <v-btn class="mr-5" @click="chiudiDialogConfermaSpedizione" small>
                <v-icon color="error"> mdi-close </v-icon>
              </v-btn>
              <v-btn @click="salvaSpedizione" small>
                <v-icon color="success"> mdi-check </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- Snackbar -->
      <SnackbarQV ref="SnackbarQV_dialog_conferma_spedizione" />
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>
<script>
import moment from "moment/moment";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

export default {
  name: "ListaOrdini",
  components: {
    SnackbarQV,
  },
  props: {
    item_selezionati: { type: Array, default: () => [] },
    tipo: { type: String, default: "" },
    titolo: { type: String, default: "" },
  },
  data() {
    return {
      // Variabili d'appoggio

      // Modal bool
      dialog_conferma_ordine: false,
      dialog_conferma_spedizione: false,
      filtri_visible: false,

      // Array
      headers: [
        {
          text: "Matricola",
          value: "cod_articolo",
          sortable: false,
        },
        {
          text: "Des. Barca",
          value: "des_articolo",
          sortable: false,
        },
        {
          text: "Gruppo",
          value: "des_articolo1",
          sortable: false,
        },
        {
          text: "Des. Componente",
          value: "des_articolo2",
          sortable: false,
        },
        {
          text: "Data Produzione",
          value: "data_produzione",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    chiudiDialog() {
      this.$emit("click_chiudi");
    },
    chiudiDialogConfermaOrdine() {
      this.dialog_conferma_ordine = false;
    },
    chiudiDialogConfermaSpedizione() {
      this.dialog_conferma_spedizione = false;
    },
    clickSalva() {
      if (this.tipo === "ordine") {
        this.dialog_conferma_ordine = true;
      } else if (this.tipo === "spedizione") {
        this.dialog_conferma_spedizione = true;
      }
    },
    salvaOrdine() {
      // Se la data di spedizione è stata indicata, procedo a salvare
      const commesse_save = [];
      this.item_selezionati.forEach((e) => {
        commesse_save.push(e.id_commessa);
      });

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessepreparazione",
          token: localStorage.getItem("user_token"),
          commesse: commesse_save,
          data_preparazione: new moment().format("YYYY-MM-DD"),
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("salva_ordine");
            this.dialog_conferma_ordine = false;
          } else {
            this.$refs.SnackbarQV_dialog_conferma_ordine.print(
              "orange",
              `Criticità tecnica rilevata, codice ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV_dialog_conferma_ordine.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    salvaSpedizione() {
      // Se la data di spedizione è stata indicata, procedo a salvare
      const commesse_save = [];
      this.item_selezionati.forEach((e) => {
        commesse_save.push(e.id_commessa);
      });

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessespedizione",
          token: localStorage.getItem("user_token"),
          commesse: commesse_save,
          data_spedizione: new moment().format("YYYY-MM-DD"),
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("salva_spedizione");
            this.dialog_conferma_spedizione = false;
          } else {
            this.$refs.SnackbarQV_dialog_conferma_spedizione.print(
              "orange",
              `Criticità tecnica rilevata, codice ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV_dialog_conferma_spedizione.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style> 
