<template>
  <div width="100%" class="ma-2">
    <v-card>
      <v-toolbar class="indigo white--text" dense primary-title>
        <v-toolbar-title>File pdf</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Bottone per l'aggiunta di un file -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              small
              @click="uploadNewFiles = true"
            >
              <v-icon style="color: #1a80b6">mdi-file-plus</v-icon>
            </v-btn>
          </template>
          <span>Upload File</span>
        </v-tooltip>

        <!-- Bottone per la comparazione delle versioni -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-5"
              v-on="on"
              v-bind="attrs"
              small
              @click="compare_files = true"
            >
              <v-icon style="color: #1a80b6">mdi-file-compare</v-icon>
            </v-btn>
          </template>
          <span>Comparazione Versioni</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="goBack" small>
              <v-icon color="error">mdi-keyboard-backspace</v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row class="justify-center">
            <v-col
              cols="4"
              class="d-flex align-center"
              v-for="(file, i) in pdfFiles"
              :key="i"
            >
              <v-container fluid style="border: solid 1px black" class="pa-4">
                <v-row justify="center">
                  <v-btn small @click="openComparisonByVersions(file.codice)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-row>
                <v-row @click="openSingleFile(file)">
                  <v-icon>mdi-file-pdf-box</v-icon>
                  {{ file["nome_file"] }}
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <SnackbarQV ref="snackbarqv" />
    <v-dialog v-model="uploadNewFiles" width="800" persistent>
      <v-card style="min-height: 500px">
        <v-toolbar class="indigo white--text" dense primary-title>
          <v-toolbar-title>Inserisci nuovi file pdf</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                :disabled="loading"
                small
                @click="fileUpload()"
              >
                <v-icon style="color: #1a80b6">mdi-file-upload</v-icon>
              </v-btn>
            </template>
            <span>Upload File</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="closeDialog"
                small
              >
                <v-icon color="error">mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="10">
                <v-file-input
                  outlined
                  dense
                  accept="application/pdf"
                  hide-details
                  v-model="files"
                  multiple
                />
                <v-progress-linear
                  v-if="loading"
                  id="progress"
                  v-model="value"
                  style="height: 25px"
                >
                  <span>Upload in corso.. {{ this.value }}/100</span>
                </v-progress-linear>
              </v-col>
            </v-row>
            <div v-if="array_diff_ordini.length > 0">
              <v-row v-for="(differenza, i) in array_diff_ordini" :key="i">
                <v-col
                  cols="12"
                  class="pb-0 mt-5"
                  v-if="differenza['ordini'][0]['codice'] != null"
                >
                  <v-btn @click="changeShowContent(i)">
                    <h4>{{ differenza["ordini"][0]["codice"] }}</h4>
                    <v-icon>{{
                      showContent[i] == true ? "mdi-arrow-down" : "mdi-arrow-up"
                    }}</v-icon>
                  </v-btn>
                </v-col>
                <v-container fluid v-show="showContent[i]">
                  <v-row>
                    <v-col
                      cols="6"
                      v-for="(ordine, i2) in differenza['ordini']"
                      :key="ordine.id_ordine"
                    >
                      <i
                        >Versione {{ i2 == 0 ? "Corrente " : "Precedente" }}
                        {{ "n° " + ordine.n_corrente }}</i
                      >
                      <v-container fluid>
                        <v-row
                          v-for="categoria in ordine.ordini_categoria"
                          :key="categoria.id_ordine_categoria"
                          class="justify-center"
                        >
                          <v-col cols="12" class="pb-0">
                            <h3
                              :style="
                                'color:' +
                                getColor(
                                  i,
                                  i2,
                                  categoria.codice1,
                                  categoria.nome_categoria
                                )
                              "
                            >
                              {{ i2 == 0 ? "+" : "-" }}
                              {{ categoria.nome_categoria }}
                            </h3>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <span v-if="categoria.codice1 != null"
                              ><strong>codice:</strong> {{ categoria.codice1 }}
                              <br
                            /></span>
                            <span v-if="categoria.elemento != null"
                              ><strong>elemento:</strong>
                              {{ categoria.elemento }} <br
                            /></span>
                            <span v-if="categoria.info != null"
                              ><strong>info:</strong> {{ categoria.info }} <br
                            /></span>
                            <span v-if="categoria.quantita != null"
                              ><strong>quantita:</strong>
                              {{ categoria.quantita }} <br
                            /></span>
                            <span v-if="categoria.des != null"
                              ><strong>des:</strong> {{ categoria.des }} <br
                            /></span>
                            <span v-if="categoria.data != null"
                              ><strong>data:</strong> {{ categoria.data }} <br
                            /></span>
                            <span v-if="categoria.firma != null"
                              ><strong>firma:</strong> {{ categoria.firma }}
                              <br
                            /></span>
                            <span v-if="categoria.elemento_padre != null"
                              ><strong>elemento padre:</strong>
                              {{ categoria.elemento_padre }} <br
                            /></span>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </div>
            <div v-if="codici_ordini.length > 0">
              <v-btn
                v-for="(codice, idOrdine) in codici_ordini"
                :key="idOrdine"
                color="blue-grey"
                class="ma-2 white--text"
                @click="openComparison(codice['codice'])"
              >
                {{ codice["codice"] }}
                <v-icon right dark> mdi-magnify </v-icon>
              </v-btn>
            </div>
            <div v-if="nomi_file.length > 0">
              <v-col
                class="pb-0 pt-1"
                v-for="(nome, idNomi) in nomi_file"
                :key="idNomi"
              >
                <v-chip>{{ nome["file_path"] }}</v-chip>
              </v-col>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="singleFile" width="1000">
      <VisualizzaFilePdf
        ref="visualizzaFilePdf"
        @chiudi_dialog="chiudiVisualizzaPdf"
      />
    </v-dialog>

    <!-- Dialog di comparazione delle versioni -->
    <v-dialog persistent v-model="compare_files">
      <ComparazioneVersioni
        ref="comparazioneVersioni"
        @chiudi_dialog="chiudiCompareDialog"
      />
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";
import ComparazioneVersioni from "@/views/TOOLS/ComparazioneVersioni.vue";
import VisualizzaFilePdf from "@/views/TOOLS/VisualizzaFilePdf.vue";

export default {
  name: "PdfConvert",
  components: {
    ComparazioneVersioni,
    SnackbarQV,
    VisualizzaFilePdf,
  },
  props: {
    back_as_landing_page: {
      type: Boolean,
      default: true,
    },
    rotta_back: {
      type: String,
      default: "/home/dashboard",
    },
  },
  data() {
    return {
      // Variabili di supporto
      codice_search: "",
      value: 0,

      // Array
      array_diff_ordini: [],
      codici_ordini: [],
      diff_ordini: [],
      files: [],
      nomi_file: [],
      pdfFiles: [],
      showContent: [],

      // Modal
      caricamento_dati: false,
      compare_files: false,
      loading: false,
      singleFile: false,
      uploadNewFiles: false,
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    changeShowContent(i) {
      this.$set(this.showContent, i, !this.showContent[i]);
    },
    chiudiCompareDialog() {
      this.compare_files = false;
      this.getFiles();
    },
    closeDialog() {
      this.getFiles();
      this.uploadNewFiles = false;
      this.array_diff_ordini = [];
      this.showContent = Array.from([]);
    },
    chiudiVisualizzaPdf() {
      this.singleFile = false;
    },
    async fileUpload() {
      if (this.files.length === 0) {
        return;
      }
      let form_data = new FormData();
      this.loading = true;
      //inserisco i file con nome_file = primarykey.estensione
      this.files.forEach((ele) => {
        form_data.append("file" + this.files.indexOf(ele), ele);
      });
      if (this.files.length != 0) {
        new Promise((resolve, reject) => {
          axios
            .post(
              this.$store.state.config.ip + "api/Global/upload_and_parse",
              form_data,
              {
                onUploadProgress: (progressEvent) => {
                  let progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                  this.value = progress;
                },
              }
            )
            .then((result) => {
              if (result.status === 200) {
                this.value = 0;
                this.loading = false;
                this.codici_ordini = result.data.result.codici_ordini;
                this.nomi_file = result.data.result.nomi_file;

                // Chiudo il dialog di caricamento file
                this.uploadNewFiles = false;

                // Aggiorno la tabella dei documenti
                this.getFiles();

                // Stampo la snackbar di conferma
                this.$refs.snackbarqv.print(
                  "success",
                  "Documento caricato correttamente."
                );
                // this.showContent = Array.from([]);
                // this.array_diff_ordini = result.data.response;
                // this.array_diff_ordini.forEach((ele) => {
                //   this.$set(
                //     this.showContent,
                //     this.array_diff_ordini.indexOf(ele),
                //     false
                //   );
                // });
                resolve(result);
              } else {
                this.$refs.snackbarqv.print(
                  "Orange",
                  `Criticità rilevata: codice ${result.status}.`
                );
              }
            })
            .catch((err) => {
              this.$refs.SnackbarQV.print(
                "orange",
                `${JSON.parse(err.response.data.message).result}.`
              );
              this.loading = false;
              reject(err);
            });
        });
      }
    },
    getFiles() {
      var getFiles = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "getPdfFiles",
          token: localStorage.user_token,
        },
      };

      this.$store.dispatch("api", getFiles).then((res) => {
        if (res.status == 200) {
          this.pdfFiles = res.data.result.ordini;
        }
      });
    },
    goBack() {
      console.log(`this.back_as_landing_page`, this.back_as_landing_page);
      this.uploadNewFiles = false;
      if (this.back_as_landing_page === true) {
        this.$router.push(this.rotta_back).catch(() => {});
      } else {
        this.$emit("click_indietro");
      }
    },
    openComparison(codice) {
      this.codice_search = codice;
      this.compare_files = true;
      this.$refs.comparazioneVersioni.codice_search = codice;
      setTimeout(() => {
        this.$refs.comparazioneVersioni.compareVersion();
      }, 10);
    },
    openComparisonByVersions(codice) {
      this.compare_files = true;
      this.codice_search = codice;
      this.$nextTick(function () {
        this.$refs.comparazioneVersioni.codice_search = codice;
        this.$refs.comparazioneVersioni.multiple = true;
        setTimeout(() => {
          this.$refs.comparazioneVersioni.compareVersion();
        }, 10);
      });
    },
    openSingleFile(file) {
      this.singleFile = true;
      this.$nextTick(function () {
        this.$refs.visualizzaFilePdf.infoFile = file;
      });
    },
  },
};
</script>