<template>
  <div style="width: 100%">
    <v-overlay :value="overlay"></v-overlay>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      absolute
      center
      shaped
      bottom
    >
      {{ snack_text }}
      <v-progress-circular
        class="ml-5"
        indeterminate
        size="25"
      ></v-progress-circular>
    </v-snackbar>
    <v-toolbar color="blue-grey darken-3" dark>
      <v-btn class="px-0" color="error" @click="indietro">
        <v-icon>mdi-undo</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <v-list-item-avatar
          class="hidden-md-and-up"
          tile
          size="35"
          color="grey"
        >
          <v-img :src="immagine_test"></v-img>
        </v-list-item-avatar>
        Questionario: {{ this.titolo_questionario }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="abilita_lista_domande == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="amber darken-3" v-bind="attrs" v-on="on">
            <v-icon>mdi-history</v-icon>
          </v-btn>
        </template>
        <v-list
          v-for="domanda in domande"
          :key="domanda.id_questionario_domanda"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ domanda.des_questionario_domanda }}
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="amber darken-3"
                      fab
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-note-text-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          v-for="risposta in domanda.risposte"
                          :key="risposta.id_questionario_risposta"
                        >
                          <span v-if="risposta.flag_risposta == 1">
                            <b>{{
                              risposta.des_risposta == "Risposta testuale"
                                ? risposta.testo_risposta
                                : risposta.des_risposta
                            }}</b>
                          </span>
                          <span v-else>{{
                            risposta.des_risposta == "Risposta testuale"
                              ? risposta.testo_risposta
                              : risposta.des_risposta
                          }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-row justify="center">
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" sm="12" md="6">
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > contatore" :step="contatore"
            >Domanda</v-stepper-step
          >
          <v-stepper-content :step="contatore">
            <v-card
              class="mx-auto"
              outlined
              v-if="this.quiz.id_tipo_domanda == 0"
            >
              <v-card-title class="headline">
                <pre
                  v-html="quiz.des_questionario_domanda"
                  style="white-space: pre-wrap; word-break: keep-all"
                ></pre>
              </v-card-title>
              <v-card-subtitle class="ml-4">
                <small
                  >Tipo Domanda:
                  {{
                    this.quiz.id_tipo_domanda == 0
                      ? "Domanda Aperta"
                      : this.quiz.id_tipo_domanda == 1
                      ? "Selezionare solo una risposta"
                      : this.quiz.id_tipo_domanda == 2
                      ? "Domanda risposta multipla"
                      : "Seleziona l'area corretta"
                  }}</small
                >
              </v-card-subtitle>
              <v-card-text>
                <v-textarea
                  :counter="quiz.caratteri_max"
                  outlined
                  label="Inserire risposta"
                  v-model="quiz.risposte[0].testo_risposta"
                ></v-textarea>
                <div v-show="quiz.accetta_allegati == 1">
                  <AllegatoList
                    :tipo="'QUESTIONARIO'"
                    :id_rif="this.quiz.risposte[0].id_questionario"
                    :id_rif_dettaglio="
                      this.quiz.risposte[0].id_questionario_domanda
                    "
                    :famiglia="-1"
                    :blnViewButtonUpload="false"
                    :blnViewButtonDelete="false"
                    :strPath="'root'"
                  ></AllegatoList>
                  <v-divider class="my-5"></v-divider>
                  <h2>Il tuo allegato:</h2>
                  <v-file-input
                    v-model="allegati"
                    small-chips
                    multiple
                    label="Carica File"
                  ></v-file-input>
                </div>
              </v-card-text>
            </v-card>

            <v-card
              class="mx-auto"
              outlined
              v-if="this.quiz.id_tipo_domanda == 1"
            >
              <v-card-title class="headline">
                <pre
                  v-html="quiz.des_questionario_domanda"
                  style="white-space: pre-wrap; word-break: keep-all"
                ></pre>
              </v-card-title>
              <v-card-subtitle class="ml-4">
                <small>{{
                  this.quiz.id_tipo_domanda == 0
                    ? "Domanda Aperta"
                    : this.quiz.id_tipo_domanda == 1
                    ? "Selezionare solo una risposta"
                    : this.quiz.id_tipo_domanda == 2
                    ? "Domanda risposta multipla"
                    : "Seleziona l'area corretta"
                }}</small>
              </v-card-subtitle>
              <v-card-text>
                <v-radio-group v-model="variabile_radio">
                  <v-radio
                    v-for="(item, index) in this.quiz.risposte"
                    :key="index"
                    :label="`${item.des_risposta}`"
                    :value="index"
                  ></v-radio>
                </v-radio-group>
                <div v-show="quiz.accetta_allegati == 1">
                  <AllegatoList
                    :tipo="'QUESTIONARIO'"
                    :id_rif="this.quiz.risposte[0].id_questionario"
                    :id_rif_dettaglio="
                      this.quiz.risposte[0].id_questionario_domanda
                    "
                    :famiglia="-1"
                    :blnViewButtonUpload="false"
                    :blnViewButtonDelete="false"
                    :strPath="'root'"
                  ></AllegatoList>
                  <v-divider class="my-5"></v-divider>
                  <h2>Il tuo allegato:</h2>
                  <v-file-input
                    v-model="allegati"
                    small-chips
                    multiple
                    label="Carica File"
                  ></v-file-input>
                </div>
              </v-card-text>
            </v-card>

            <v-card
              class="mx-auto"
              outlined
              v-if="this.quiz.id_tipo_domanda == 2"
            >
              <pre
                v-html="quiz.des_questionario_domanda"
                style="white-space: pre-wrap; word-break: keep-all"
              ></pre>
              <v-card-subtitle class="ml-4">
                <small>{{
                  this.quiz.id_tipo_domanda == 0
                    ? "Domanda Aperta"
                    : this.quiz.id_tipo_domanda == 1
                    ? "Selezionare solo una risposta"
                    : this.quiz.id_tipo_domanda == 2
                    ? "Domanda risposta multipla"
                    : "Seleziona l'area corretta"
                }}</small>
              </v-card-subtitle>
              <v-card-text>
                <v-checkbox
                  v-for="(item, index) in this.quiz.risposte"
                  :key="index"
                  v-model="item.testo_risposta"
                  class="mx-2"
                  :label="`${item.des_risposta}`"
                ></v-checkbox>
                <div v-show="quiz.accetta_allegati == 1">
                  <AllegatoList
                    :tipo="'QUESTIONARIO'"
                    :id_rif="this.quiz.risposte[0].id_questionario"
                    :id_rif_dettaglio="
                      this.quiz.risposte[0].id_questionario_domanda
                    "
                    :famiglia="-1"
                    :blnViewButtonUpload="false"
                    :blnViewButtonDelete="false"
                    :strPath="'root'"
                  ></AllegatoList>
                  <v-divider class="my-5"></v-divider>
                  <h2>Il tuo allegato:</h2>
                  <v-file-input
                    v-model="allegati"
                    small-chips
                    multiple
                    label="Carica File"
                  ></v-file-input>
                </div>
              </v-card-text>
            </v-card>

            <v-card
              class="mx-auto"
              outlined
              v-if="this.quiz.id_tipo_domanda == 3"
            >
              <pre
                v-html="quiz.des_questionario_domanda"
                style="white-space: pre-wrap; word-break: keep-all"
              ></pre>
              <v-card-subtitle class="ml-4">
                <small>{{
                  this.quiz.id_tipo_domanda == 0
                    ? "Domanda Aperta"
                    : this.quiz.id_tipo_domanda == 1
                    ? "Selezionare solo una risposta"
                    : this.quiz.id_tipo_domanda == 2
                    ? "Domanda risposta multipla"
                    : "Seleziona l'area corretta"
                }}</small>
              </v-card-subtitle>
              <v-card-text>
                <div
                  display="inline-block"
                  height="500"
                  id="chartImmagine"
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 5px;
                    max-height: 500px;
                    max-width: 500px;
                    min-height: 500px;
                    min-width: 500px;
                    background-size: 100% 100%;
                  "
                  width="500"
                >
                  <svg
                    id="svgImmagine"
                    height="500"
                    style="border: 1px solid black"
                    width="500"
                  >
                    <!--<rect class="selection" height="0" width="0" /> --->
                  </svg>
                </div>
                <div v-show="quiz.accetta_allegati == 1">
                  <AllegatoList
                    :tipo="'QUESTIONARIO'"
                    :id_rif="this.quiz.risposte[0].id_questionario"
                    :id_rif_dettaglio="
                      this.quiz.risposte[0].id_questionario_domanda
                    "
                    :famiglia="-1"
                    :blnViewButtonUpload="false"
                    :blnViewButtonDelete="false"
                    :strPath="'root'"
                  ></AllegatoList>
                  <v-divider class="my-5"></v-divider>
                  <h2>Il tuo allegato:</h2>
                  <v-file-input
                    v-model="allegati"
                    small-chips
                    multiple
                    label="Carica File"
                  ></v-file-input>
                </div>
              </v-card-text>
            </v-card>
            <v-card
              class="mx-auto"
              outlined
              v-if="this.quiz.id_tipo_domanda == 4"
            >
              <pre
                v-html="quiz.des_questionario_domanda"
                style="white-space: pre-wrap; word-break: keep-all"
              ></pre>
              <v-card-subtitle class="ml-4">
                <small>{{
                  this.quiz.id_tipo_domanda == 0
                    ? "Domanda Aperta"
                    : this.quiz.id_tipo_domanda == 1
                    ? "Selezionare solo una risposta"
                    : this.quiz.id_tipo_domanda == 2
                    ? "Domanda risposta multipla"
                    : "Seleziona l'area corretta"
                }}</small>
              </v-card-subtitle>
              <v-card-text>
                <div class="main">
                  <div class="editor-container">
                    <div
                      class="editor"
                      v-if="this.quiz.id_tipo_modifica_immagine != 0"
                    >
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="tool current-color"
                            :style="{ backgroundColor: color }"
                            v-bind="attrs"
                            v-on="on"
                          ></div>
                        </template>
                        <v-color-picker
                          v-model="color"
                          class="ma-2"
                          hide-inputs
                        ></v-color-picker>
                      </v-menu>

                      <div class="tool undo" @click="undo">
                        <i class="fas fa-undo-alt fa-lg"></i>
                      </div>
                      <div class="tool redo" @click="redo">
                        <i class="fas fa-redo-alt fa-lg"></i>
                      </div>
                      <div class="tool clear" @click="clear">
                        <i class="fas fa-trash-alt fa-lg"></i>
                      </div>
                      <div
                        class="tool free-drawing"
                        @click="setTool('freeDrawing')"
                        :class="{
                          'active-tool': currentActiveMethod === 'freeDrawing',
                        }"
                      >
                        <i class="fas fa-pencil-alt fa-lg"></i>
                      </div>
                      <div
                        class="tool add-text"
                        @click="setTool('text')"
                        :class="{
                          'active-tool': currentActiveMethod === 'text'
                        }"
                      >
                        <i class="fas fa-font fa-lg"></i>
                      </div>
                      <div
                        class="tool custom-circle"
                        @click="setTool('circle')"
                        :class="{
                          'active-tool': currentActiveMethod === 'circle',
                        }"
                      >
                        <i class="far fa-circle fa-lg"></i>
                      </div>
                      <div
                        class="tool custom-rect"
                        @click="setTool('rect')"
                        :class="{
                          'active-tool': currentActiveMethod === 'rect',
                        }"
                      >
                        <i class="far fa-square fa-lg"></i>
                      </div>

                      <div
                        class="tool arrow"
                        @click="setTool('arrow')"
                        :class="{
                          'active-tool': currentActiveMethod === 'arrow',
                        }"
                      >
                        <i class="fas fa-long-arrow-alt-down fa-lg"></i>
                      </div>

                      <div
                        class="tool drag"
                        @click="setTool('selectMode')"
                        :class="{
                          'active-tool': currentActiveMethod === 'selectMode',
                        }"
                      >
                        <i class="fas fa-arrows-alt fa-lg"></i>
                      </div>

                      <div
                        class="tool crop-apply"
                        v-show="croppedImage"
                        @click="applyCropping()"
                        :class="{
                          'active-tool': currentActiveMethod === 'crop',
                        }"
                      >
                        <i class="far fa-check-circle fa-lg"></i>
                      </div>
                      <div
                        class="tool crop"
                        v-show="!croppedImage"
                        @click="cropImage()"
                      >
                        <i class="fas fa-crop fa-lg"></i>
                      </div>

                      <div class="tool upload-image">
                        <label for="chooseImage">
                          <i class="fas fa-cloud-upload-alt fa-lg"></i>
                        </label>
                        <input
                          id="chooseImage"
                          style="visibility: hidden"
                          type="file"
                          @change="uploadImage"
                          accept="image/*"
                        />
                      </div>

                      <!-- <div class="tool save-image" @click="saveImage">
                        <i class="fas fa-save fa-lg"></i>
                      </div>-->
                    </div>
                    <Editor
                      :canvasWidth="canvasWidth"
                      :canvasHeight="canvasHeight"
                      ref="editor"
                    />
                  </div>
                </div>
                <v-textarea
                  class="mt-2"
                  outlined
                  label="Inserire risposta"
                  v-model="quiz.risposte[0].testo_risposta"
                ></v-textarea>
                <div v-show="quiz.accetta_allegati == 1">
                  <AllegatoList
                    :tipo="'QUESTIONARIO'"
                    :id_rif="this.quiz.risposte[0].id_questionario"
                    :id_rif_dettaglio="
                      this.quiz.risposte[0].id_questionario_domanda
                    "
                    :famiglia="-1"
                    :blnViewButtonUpload="false"
                    :blnViewButtonDelete="false"
                    :strPath="'root'"
                  ></AllegatoList>
                  <v-divider class="my-5"></v-divider>
                  <h2>Il tuo allegato:</h2>
                  <v-file-input
                    v-model="allegati"
                    small-chips
                    multiple
                    label="Carica File"
                  ></v-file-input>
                </div>
              </v-card-text>
            </v-card>
            <v-btn
              v-if="this.quiz.uscite >= 1"
              class="mt-1"
              color="primary"
              @click="confermaDomanda()"
              >Conferma</v-btn
            >
            <v-btn
              v-else
              class="mt-1"
              color="primary"
              @click="concludiQuestionario()"
              >Concludi questionario</v-btn
            >
          </v-stepper-content>

          <v-stepper-step
            v-if="this.quiz.uscite >= 1"
            :complete="e6 > 2"
            step="?"
          >
            Prossima Domanda
            <small>Rispondi per accedere alla prossima domanda</small>
          </v-stepper-step>
        </v-stepper>
      </v-col>
      <v-col cols="12" sm="12" md="3" class="hidden-sm-and-down text-center">
        <v-card class="text-center">
          <v-list-item-avatar tile size="160" color="grey">
            <v-img :src="immagine_test"></v-img>
          </v-list-item-avatar>
          <v-card-text class="text-center headline">{{
            titolo_questionario
          }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_conferma" max-width="350">
      <v-card>
        <v-card-title class="headline">{{ titolo_dialog }}</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" dark @click="dialog_conferma = false"
            >Annnulla</v-btn
          >

          <v-btn color="success darken-1" dark @click="confermaDialog()"
            >Conferma</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_fine" max-width="600" persistent>
      <v-card class="text-center justify-center">
        <v-card-text
          class="pa-5 headline"
          v-html="dialog_fine_testo"
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center justify-center" align="center">
          <v-btn color="success darken-1" dark @click="esci()">ESCI</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Editor from "vue-image-markup";
import * as d3 from "d3";
import Base64 from "b64-to-blob";
import AllegatoList from "../Allegato/AllegatoList.vue";

export default {
  components: { AllegatoList, Editor },
  props: ["test"],
  data() {
    return {
      domande: [],
      overlay: true,
      currentActiveMethod: null,
      params: {},
      color: "#ff3c00",
      imageUrl: null,
      croppedImage: false,
      variabile_radio: "",
      immagine_test: "",
      snackbar: false,
      snack_color: "gray",
      snack_text: "",
      timeout: 2000,
      canvasWidth: 600,
      canvasHeight: 600,
      contatore: 1,
      allegati: [],
      e6: 1,
      files: [],
      quiz: [],
      link_to: "",
      tipo_domanda: "multi",
      textarea: "",
      checkbox: false,
      id_quiz: this.$route.params.id,
      img: "",
      titolo_questionario: "",
      abilita_lista_domande: 0,
      tipo_dialog: "",
      dialog_conferma: false,
      dialog_fine_testo: "",
      dialog_fine: false,
      titolo_dialog: "",
      check_modifica: false,
    };
  },
  mounted() {
    this.getQuestionari();
    var variabili = JSON.parse(localStorage.quiz_detail);
    this.img = variabili.immagine;
    this.titolo_questionario = variabili.titolo;
    this.abilita_lista_domande = variabili.abilita_lista_domande;
    //this.canvasWidth = document.getElementsByClassName("editor")[0].offsetWidth;
    this.immagine_test = this.getImg();
    if (this.immagine_test && this.$refs.editor != undefined) {
      this.$refs.editor.setBackgroundImage(this.immagine_test);
      this.croppedImage = this.$refs.editor.croppedImage;
    }
    this.$watch(
      () => {
        if (this.$refs.editor != null) {
          return this.$refs.editor.croppedImage;
        }
      },
      (val) => {
        //console.log("val", val);
        this.croppedImage = val;
      }
    );
  },
  watch: {
    color(val) {
      //console.log("aa", this.currentActiveMethod);

      if (this.currentActiveMethod == "text") {
        // this.$refs.editor.addText('addText');
        this.$refs.editor.set(this.currentActiveMethod, {
          fontStyle: "normal",
          fontFamily: "Arial",
          fontSize: "22",
          fontWeight: "15",
          stroke: val,
          fill: val,
        });
      } else {
        this.$refs.editor.set(this.currentActiveMethod, {
          fontStyle: "normal",
          fontFamily: "Arial",
          fontSize: "22",
          fontWeight: "15",
          stroke: val,
        });
      }
    },
    quiz() {},
    "quiz.risposte"() {},
  },
  methods: {
    getImg() {
      if (this.img == null) {
        return "";
      }
      var base = this.img.split(",");
      var blob = Base64(base[1], "image/png");
      //console.log("entrato", blob);

      return URL.createObjectURL(blob);
    },
    indietro() {
      this.tipo_dialog = "indietro";
      this.titolo_dialog = "Sei sicuro di voler uscire?";
      this.dialog_conferma = true;
    },
    confermaDialog() {
      if (this.tipo_dialog == "indietro") {
        this.$router.replace("/home/check/quiz");
      }
    },

    displayArea(item) {
      let that = this;
      d3.selectAll("#svgImmagine > rect").remove();
      var cont = 0;
      item.forEach((elem) => {
        var tot = elem.des_risposta.split(" ");
        var prime = tot[0].replace("(", "").replace(")", "").split(",");
        var seconde = tot[1].replace("(", "").replace(")", "").split(",");
        //console.log(tot);
        //console.log(prime);
        //console.log(seconde);
        d3.select("#svgImmagine")
          .append("rect")
          .attr("id", cont)
          .attr("x", parseInt(prime[0]))
          .attr("y", parseInt(prime[1]))
          .attr("width", parseInt(seconde[0]) - parseInt(prime[0]))
          .attr("height", parseInt(seconde[1]) - parseInt(prime[1]))
          .attr(
            "style",
            that.quiz.mostra_contorno_aree == 0
              ? "stroke: #000000;stroke-width='5'; fill: #ff6666;stroke-opacity:0;fill-opacity:0.0;cursor:cursor;"
              : "stroke: #000000;stroke-width='5'; fill: #ff6666;fill-opacity: 0.0;cursor:pointer;"
          )
          .on("click", function () {
            var classi = d3.select(this).attr("class");
            if (classi == "selezionato") {
              d3.select(this).style("fill", "#ff6666");
              d3.select(this).style("fill-opacity", 0);
              d3.select(this).attr("class", null);
              var id = d3.select(this).attr("id");
              //console.log(id);
              that.quiz.risposte[id].testo_risposta = "";
              //console.log(that.quiz);
            } else {
              d3.select(this).style("fill", "#ffffff");
              d3.select(this).attr("class", "selezionato");
              d3.select(this).style("fill-opacity", 0.5);
              var id2 = d3.select(this).attr("id");
              //console.log(id2);
              that.quiz.risposte[id2].testo_risposta = "OK";
              //console.log(that.quiz);
            }
          });
        cont++;
      });
    },
    getQuestionari() {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "startquestionario",
          id_questionario: this.id_quiz,
          id_utente: localStorage.id,
          token: localStorage.getItem("user_token"),
          db_name: "quickcheck",
        },
      };

      //console.log("startquestionario request", JSON.stringify(request));
      this.$store.dispatch("api", request).then((res) => {
        //console.log("startquestionario response", JSON.stringify(res.data));
        if (res.data.length == 0) {
          //console.log("Entrato nella if");
          this.snack_text = "Non ci sono domande per questo test.";
          this.snackbar = true;
          let that = this;
          setTimeout(function () {
            that.$router.replace("/home/check/quiz");
          }, 2100);
        } else {
          this.overlay = false;
          this.renderDomande(res.data[0]);
        }
      });
    },
    concludiQuestionario() {
      if (
        this.check_modifica == false &&
        this.quiz.id_tipo_modifica_immagine == 2 &&
        this.quiz.id_tipo_domanda == 4
      ) {
        this.snackbar = true;
        this.snack_text =
          "Effettuare una modifica dell'immagine prima di proseguire!";
        this.snack_color = "error";
        return;
      }
      //console.log("risposte", this.quiz.risposte);

      /* Nelle domande testuali se il testo della risposta è vuoto l'esecuzione
       * del questionario entra in stallo; faccio un controllo per evitare che
       * la riasposta sia vuota. */
      if (
        this.quiz.id_tipo_domanda == 0 &&
        this.quiz.risposte[0].testo_risposta.length == 0
      ) {
        this.snackbar = true;
        this.snack_text =
          "Prima di proseguire è necessario inserire una risposta.";
        this.snack_color = "error";
        return;
      }

      // Controllo la lunghezza minima delle risposte
      if (
        this.quiz.risposte[0].testo_risposta.length < this.quiz.caratteri_min
      ) {
        this.snackbar = true;
        this.snack_text =
          "Il testo è troppo breve, lunghezza minima: " +
          this.quiz.caratteri_min +
          " caratteri.";
        this.snack_color = "error";
        return;
      }

      // Controllo la lunghezza massima delle risposte
      if (
        this.quiz.risposte[0].testo_risposta.length > this.quiz.caratteri_max
      ) {
        this.snackbar = true;
        this.snack_text =
          "Il testo è troppo lungo, lunghezza massima: " +
          this.quiz.caratteri_max +
          " caratteri.";
        this.snack_color = "error";
        return;
      }

      /* Nelle domande di acquisizione / modifica immagine se il testo
       * della risposta è vuoto l'esecuzione del questionario si blocca;
       * faccio un controllo per evitare che testo_risposta sia vuoto. */
      if (
        this.quiz.id_tipo_domanda == 4 &&
        this.quiz.risposte[0].testo_risposta.length == 0
      ) {
        this.quiz.risposte[0].testo_risposta = "Campo testuale vuoto";
      }

      this.quiz.risposte[0].img_risposta = "";
      if (this.quiz.id_tipo_domanda == 1) {
        this.quiz.risposte[this.variabile_radio].testo_risposta = "OK";
      } else {
        if (this.quiz.id_tipo_domanda == 4) {
          this.quiz.risposte[0].img_risposta = this.saveImage();
        }

        this.quiz.risposte.forEach((element) => {
          if (element.testo_risposta == true) {
            //console.log("entrato true");
            element.testo_risposta = "OK";
          } else if (element.testo_risposta == false) {
            //console.log("entrato false");
            element.testo_risposta = "";
          }
        });
      }
      if (this.allegati.length == 0) {
        var request = {
          controller: "Questionario",
          method: "POST",
          richiesta: {
            action: "nextquestionario",
            token: localStorage.user_token,
            db_name: "quickcheck",
            id_questionario: this.id_quiz,
            id_utente: localStorage.id,
            risposte: this.quiz.risposte,
            allegati: [],
          },
        };

        //console.log("nextquestionario request", JSON.stringify(request));
        this.$store.dispatch("api", request).then((res) => {
          //console.log("nextquestionario response", JSON.stringify(res));
          if (res.data[0].id_tipo_domanda == 99) {
            this.dialog_fine = true;
            this.dialog_fine_testo = res.data[0].des_questionario_domanda;
            this.link_to = res.data[0].link_finale;
          } else {
            this.snack_color = "success";
            this.snack_text =
              "QUESTIONARIO TERMINATO, VERRAI RIPORTATO ALLA SCHERMATA INIZIALE";
            this.snackbar = true;
            if (localStorage.id == "-1") {
              let that = this;
              setTimeout(function () {
                localStorage.clear();
                that.$router.replace("/login");
              }, 2100);
              return;
            } else {
              let that = this;
              setTimeout(function () {
                that.$router.replace("/home/check/quiz");
              }, 2100);
            }
          }
        });
      } else {
        var allegati_send = [];
        var cont = 0;
        this.allegati.forEach((element) => {
          var reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onloadend = () => {
            var base64data = reader.result;
            allegati_send.push({
              name: element.name,
              type: element.type,
              base: base64data,
            });
            cont++;

            if (cont == this.allegati.length) {
              //console.log("allegati", allegati_send);
              var request = {
                controller: "Questionario",
                method: "POST",
                richiesta: {
                  action: "nextquestionario",
                  db_name: "quickcheck",
                  id_questionario: this.id_quiz,
                  id_utente: localStorage.id,
                  token: localStorage.user_token,
                  risposte: this.quiz.risposte,
                  allegati: allegati_send,
                },
              };

              //console.log("nextquestionario request", JSON.stringify(request));
              this.$store.dispatch("api", request).then((res) => {
                /*console.log(
                  "nextquestionario response",
                  JSON.stringify(res)
                );*/
                this.allegati = [];
                if (res.data[0].id_tipo_domanda == 99) {
                  this.dialog_fine = true;
                  this.dialog_fine_testo = res.data[0].des_questionario_domanda;
                  this.link_to = res.data[0].link_finale;
                } else {
                  this.snack_color = "success";
                  this.snack_text =
                    "QUESTIONARIO TERMINATO, VERRAI RIPORTATO ALLA SCHERMATA INIZIALE";
                  this.snackbar = true;
                  if (localStorage.id == "-1") {
                    let that = this;
                    setTimeout(function () {
                      localStorage.clear();
                      that.$router.replace("/login");
                    }, 2100);
                    return;
                  } else {
                    let that = this;
                    setTimeout(function () {
                      that.$router.push("/home/check/quiz");
                    }, 2100);
                  }
                }
              });
            }
          };
        });
      }
    },
    confermaDomanda() {
      if (
        this.check_modifica == false &&
        this.quiz.id_tipo_modifica_immagine == 2 &&
        this.quiz.id_tipo_domanda == 4
      ) {
        this.snackbar = true;
        this.snack_text =
          "Effettuare prima una modifica dell'immagine prima di proseguire!";
        this.snack_color = "error";
        return;
      }
      /* Nelle domande testuali se il testo della risposta è vuoto l'esecuzione
       * del questionario entra in stallo; faccio un controllo per evitare che
       * la riasposta sia vuota. */
      if (
        this.quiz.id_tipo_domanda == 0 &&
        this.quiz.risposte[0].testo_risposta.length == 0
      ) {
        this.snackbar = true;
        this.snack_text =
          "Prima di proseguire è necessario inserire una risposta.";
        this.snack_color = "error";
        return;
      }

      // Controllo la lunghezza minima delle risposte
      if (
        this.quiz.risposte[0].testo_risposta.length < this.quiz.caratteri_min
      ) {
        this.snackbar = true;
        this.snack_text =
          "Il testo è troppo breve, lunghezza minima: " +
          this.quiz.caratteri_min +
          " caratteri.";
        this.snack_color = "error";
        return;
      }

      // Controllo la lunghezza massima delle risposte
      if (
        this.quiz.risposte[0].testo_risposta.length > this.quiz.caratteri_max
      ) {
        this.snackbar = true;
        this.snack_text =
          "Il testo è troppo lungo, lunghezza massima: " +
          this.quiz.caratteri_max +
          " caratteri.";
        this.snack_color = "error";
        return;
      }

      /* Nelle domande di acquisizione / modifica immagine se il testo
       * della risposta è vuoto l'esecuzione del questionario si blocca;
       * faccio un controllo per evitare che testo_risposta sia vuoto. */
      if (
        this.quiz.id_tipo_domanda == 4 &&
        this.quiz.risposte[0].testo_risposta.length == 0
      ) {
        this.quiz.risposte[0].testo_risposta = "Campo testuale vuoto";
      }

      this.quiz.risposte[0].img_risposta = "";
      //console.log("risposte", this.quiz.risposte);
      if (this.quiz.id_tipo_domanda == 1) {
        this.quiz.risposte[this.variabile_radio].testo_risposta = "OK";
      } else {
        if (this.quiz.id_tipo_domanda == 4) {
          this.quiz.risposte[0].img_risposta = this.saveImage();
        }

        this.quiz.risposte.forEach((element) => {
          if (element.testo_risposta == true) {
            //console.log("entrato true");
            element.testo_risposta = "OK";
          } else if (element.testo_risposta == false) {
            //console.log("entrato false");
            element.testo_risposta = "";
          }
        });
      }
      if (this.allegati.length == 0) {
        var request = {
          controller: "Questionario",
          method: "POST",
          richiesta: {
            action: "nextquestionario",
            db_name: "quickcheck",
            id_questionario: this.id_quiz,
            id_utente: localStorage.id,
            token: localStorage.user_token,
            risposte: this.quiz.risposte,
            allegati: [],
          },
        };

        //console.log("nextquestionario request", JSON.stringify(request));
        this.$store.dispatch("api", request).then((res) => {
          //console.log("nextquestionario response", JSON.stringify(res));
          this.variabile_radio = "";
          // Se c'era un'area di modifiche grafiche la cancello
          if (this.quiz.id_tipo_domanda == 4) {
            this.$refs.editor.clear();
          }

          // Se c'era un'immagine da selezionare la cancello
          if (document.getElementById("chartImmagine")) {
            document.getElementById("chartImmagine").style.backgroundImage =
              "url()";
          }
          if (res.data[0].id_tipo_domanda == 99) {
            this.dialog_fine = true;
            this.dialog_fine_testo = res.data[0].des_questionario_domanda;
            this.link_to = res.data[0].link_finale;
          } else {
            this.contatore++;
            this.renderDomande(res.data[0]);
          }

          // Chiedo e popolo la cronologia domande-risposte
          this.getHistory();
        });
      } else {
        var allegati_send = [];
        var cont = 0;
        this.allegati.forEach((element) => {
          var reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onloadend = () => {
            var base64data = reader.result;
            allegati_send.push({
              name: element.name,
              type: element.type,
              base: base64data,
            });
            cont++;

            if (cont == this.allegati.length) {
              //console.log("allegati", allegati_send);
              var request = {
                controller: "Questionario",
                method: "POST",
                richiesta: {
                  action: "nextquestionario",
                  db_name: "quickcheck",
                  id_questionario: this.id_quiz,
                  id_utente: localStorage.id,
                  token: localStorage.user_token,
                  risposte: this.quiz.risposte,
                  allegati: allegati_send,
                },
              };

              //console.log("nextquestionario request", JSON.stringify(request));
              this.$store.dispatch("api", request).then((res) => {
                /*console.log(
                  "nextquestionario response",
                  JSON.stringify(res.data)
                );*/
                if (document.getElementById("chartImmagine")) {
                  document.getElementById(
                    "chartImmagine"
                  ).style.backgroundImage = "url()";
                }

                this.variabile_radio = "";
                this.allegati = [];
                if (res.data[0].id_tipo_domanda == 99) {
                  this.dialog_fine = true;
                  this.dialog_fine_testo = res.data[0].des_questionario_domanda;
                  this.link_to = res.data[0].link_finale;
                } else {
                  this.contatore++;
                  this.renderDomande(res.data[0]);
                }
              });
            }
          };
        });
      }
    },
    getHistory() {
      var request = {
        controller: "Questionario",
        method: "POST",
        richiesta: {
          action: "gethistory",
          token: localStorage.user_token,
          id_questionario_test: this.id_quiz,
        },
      };
      //console.log(JSON.stringify("gethistory request", request));
      this.$store.dispatch("api", request).then((res) => {
        //console.log("gethistory response", JSON.stringify(res));
        this.domande = [];
        res.data.forEach((elem) => {
          this.domande.push(elem);
        });
      });
    },
    //FUNZIONI SUPPORTO
    renderDomande(item) {
      this.quiz = item;
      this.quiz.risposte = JSON.parse(this.quiz.risposte);
      if (this.quiz.id_tipo_domanda == 3) {
        //console.log("ENTRATOO");
        let that = this;
        var checkExist = setInterval(function () {
          if (document.getElementById("chartImmagine")) {
            clearInterval(checkExist);
            var base = that.quiz.img_caricata.split(",");
            var blob = Base64(base[1], "image/png");
            //console.log(blob);
            document.getElementById("chartImmagine").style.backgroundImage =
              "url(" + URL.createObjectURL(blob) + ")";
            that.displayArea(that.quiz.risposte);
          }
        }, 100);
      }
    },
    cropImage() {
      this.currentActiveMethod = "crop";
      //console.log("crop");
      this.croppedImage = !this.croppedImage;
      this.setTool("crop");
      this.check_modifica = true;
    },
    applyCropping() {
      this.currentActiveMethod = "";
      this.$refs.editor.applyCropping();
      this.check_modifica = true;
    },
    changeColor(colorHex) {
      this.color = colorHex;
      this.$refs.editor.changeColor(colorHex);
      //console.log("trest", this.$refs.editor.changeColor(colorHex));
      this.check_modifica = true;
    },
    saveImage() {
      let image = this.$refs.editor.saveImage();
      return image;
      //this.saveImageAsFile(image);
    },
    saveImageAsFile(base64) {
      let link = document.createElement("a");
      link.setAttribute("href", base64);
      link.setAttribute("download", "image-markup");
      link.click();
    },
    setTool(type, params) {
      this.check_modifica = true;
      this.currentActiveMethod = type;
      console.log(type, params, this.$refs.editor);

      if (type == "text") {
        // this.$refs.editor.addText('addText');
        this.$refs.editor.createText = true;
        this.$refs.editor.set(type, {
          fontStyle: "normal",
          fontFamily: "Arial",
          fontSize: "22",
          fontWeight: "12",
          stroke: this.color,
          color: this.color,
          fill: this.color,
        });
      } else {
        this.$refs.editor.set(type, {
          fontStyle: "normal",
          fontFamily: "Arial",
          fontSize: "22",
          fontWeight: "12",
          stroke: this.color,
        });
      }
      //this.$refs.editor.set(type, params);
    },
    uploadImage(e) {
      this.$refs.editor.uploadImage(e);
    },
    clear() {
      this.currentActiveMethod = this.clear;
      this.$refs.editor.clear();
      this.check_modifica = false;
    },
    undo() {
      this.currentActiveMethod = this.undo;
      this.$refs.editor.undo();
    },
    redo() {
      this.currentActiveMethod = this.redo;
      this.$refs.editor.redo();
    },
    esci() {
      //console.log("link finale: ", this.link_to);
      if (this.link_to != "" && this.link_to != null) {
        if (this.link_to.includes("http")) {
          window.open(
            this.link_to,
            "_blank" // <- This is what makes it open in a new window.
          );
          this.dialog_fine = false;
        } else {
          window.open(
            "http://" + this.link_to,
            "_blank" // <- This is what makes it open in a new window.
          );
          this.dialog_fine = false;
        }
      } else {
        this.$router.replace("/home/check/quiz");
      }
    },
  },
};
</script>
<style >
.editor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.editor {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 40px 25px 0 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.color-container {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 5px 0;
}
.editor-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.editor-container,
.tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tool {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  padding: 2px 0px;
}
.current-color {
  border-radius: 5px;
  min-width: 28px;
  min-height: 28px;
}
.active-tool {
  cursor: pointer;
  color: #4287f5;
}
.red {
  background-color: #e40000;
}
.yellow {
  background-color: #e8eb34;
}
.purple {
  background-color: #a834eb;
}
.green {
  background-color: #65c31a;
}
.lightblue {
  background-color: #34b7eb;
}
.pink {
  background-color: #eb34df;
}
.blue {
  background-color: #1a10ad;
}
.black {
  background-color: #000;
}
.upload-image {
  max-width: 15px;
}
.custom-editor {
  margin-top: 20px;
}
canvas {
  border: 1px solid rgba(0, 0, 0, 0.13);
}
.slider-container {
  margin-top: 20px;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #50555c;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #50555c;
  cursor: pointer;
}
.tool-icon {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
  object-fit: contain;
}
.fa-lg:hover {
  cursor: pointer;
  color: #4287f5;
}
</style>