<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-5 px-5">
      <TabellaRichieste />
    </v-col>
  </v-row>
</template>
<script>
import TabellaRichieste from "@/components/MANUTENZIONE/TabellaRichieste.vue";

export default {
  name: "Manutenzione",
  components: { TabellaRichieste },
  props: {},
  data() {
    return {
      // Variabili d'appoggio

      // Modal

      // Array
      tab: null,
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.noBottomRule >>> .v-input__slot::before {
  border-style: none !important;
}
.v-text-field fieldset {
  color: red !important;
}
.bg-red {
  background: linear-gradient(
    90deg,
    rgba(97, 1, 1, 1) 0%,
    rgba(254, 0, 0, 1) 100%
  );
  color: white !important;
}
.sfondoStandard {
  background: linear-gradient(
    90deg,
    rgba(63, 81, 181, 1) 0%,
    rgba(63, 81, 181, 1) 100%
  );
  color: white !important;
}
</style>