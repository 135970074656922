<template>
  <v-card>
    <v-toolbar
      color="indigo"
      dense
      style="position: sticky; top: 0px; z-index: 1"
    >
      <v-toolbar-title class="white--text"
        >Configurazione {{ conf.tipologia }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salva" small v-bind="attrs" v-on="on">
            <v-icon color="success">mdi-floppy</v-icon>
          </v-btn>
        </template>
        <span>Salva</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="annulla" small v-bind="attrs" v-on="on">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Annulla</span>
      </v-tooltip>
    </v-toolbar>
    <v-container>
      <v-row class="ma-2">
        <v-col cols="4">
          <v-select
            :items="[
              { name: 'Email', id: 0 },
              { name: 'Link', id: 1 },
              { name: 'API', id: 2 },
            ]"
            item-text="name"
            item-value="id"
            id="tipo"
            label="Tipo"
            style="display: inline-block; man-width: 400px"
            v-model="conf.tipo"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :counter="30"
            id="inputLink"
            label="Link"
            :placeholder="conf.link"
            required
            v-model="conf.link"
          ></v-text-field>
        </v-col>
        <v-col cols="4" style="padding-left: 30px; padding-right: 30px">
          <v-text-field
            :counter="30"
            id="inputCodice"
            label="Codice"
            :placeholder="conf.codice"
            required
            v-model="conf.codice"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <!--<v-row class="ma-2">
      <v-col cols="4" style="padding-left:30px;padding-right:30px;">
        <v-text-field
          :counter="30"
          id="inputNomeParametro"
          label="Nome parametro"
          :placeholder="nuovo_nome_parametro"
          required
          v-model="nuovo_nome_parametro"
        ></v-text-field>
      </v-col>
      <v-col cols="4" style="padding-left:30px;padding-right:30px;">
        <v-text-field
          :counter="30"
          id="inputValoreParametro"
          label="Valore parametro"
          :placeholder="nuovo_valore_parametro"
          required
          v-model="nuovo_valore_parametro"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          class="my-2"
          @click="createParametro(nuovo_nome_parametro,nuovo_valore_parametro)"
          color="indigo"
          fab
          outlined
          small
          style="background-color:#ffffff;margin-right:20px;"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-col cols="6">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nome</th>
                <th class="text-left">Valore</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="parametro in conf.parametri" :key="parametro.nome_parametro">
                <td>{{ parametro.nome_parametro }}</td>
                <td>{{ parametro.valore_parametro }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>-->
  </v-card>
</template>
<script>
import _ from "lodash";

export default {
  props: {
    oggetto: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      parametri: [],
      nuovo_nome_parametro: "",
      nuovo_valore_parametro: null,
      conf: {
        tipologia: "",
        link: "",
        codice: "",
        parametri: [],
      },
    };
  },
  watch: {
    oggetto: {
      immediate: true,
      deep: true,
      handler() {
        if (this.oggetto != null) {
          this.conf = _.cloneDeep(this.oggetto);
        }
      },
    },
  },
  methods: {
    createParametro(nuovo_nome_parametro, nuovo_valore_parametro) {
      // Creo il nuovo parmetro e lo valorizzo
      let nouvoParametro = {
        id_questionario: this.conf.id_questionario,
        id_questionario_parametro: -1,
        nome_parametro: nuovo_nome_parametro,
        valore_parametro: nuovo_valore_parametro,
      };
      if (this.conf.tipologia == "domanda") {
        nouvoParametro.id_questionario_domanda = this.conf.id_questionario_domanda;
      } else if (this.conf.tipologia == "risposta") {
        nouvoParametro.id_questionario_risposta = this.conf.id_questionario_risposta;
      } else {
        nouvoParametro.id_questionario_connessione = this.conf.id_questionario_connessione;
      }
      // Elimino l'eventuale oggetto vuoto
      if (this.conf.parametri[0].id == -1) {
        this.conf.parametri.shift();
      }
      this.conf.parametri.push(nouvoParametro);
      //console.log(JSON.stringify(nouvoParametro));
      this.nuovo_nome_parametro = "";
      this.nuovo_valore_parametro = null;
    },
    // Salvataggio e uscita
    annulla() {
      this.$emit("annulla");
    },
    salva() {
      this.$emit("salva", this.conf);
    },
  },
};
</script>