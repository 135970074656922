<template>
  <div style="width: 100%">
    <!-- Div contenente lo sfondo (fisso) di questa videata -->
    <div class="sfondo" style="position: fixed; left: 0px; top: 0px"></div>
    <!-- Menu di selezione -->
    <v-row  align="center" justify="end" class="mt-10" v-if="menu_visible">
      <v-col cols="0" md="6"> </v-col>
      <v-col cols="7" md="5" lg="4">
        <!-- Menu di selezione -->
        <Menu
          style="display: inline-block; width: 75%"
          @click_pezzi="clickPezzi"
          @click_tempi="clickTempi"
          @click_scarti="clickScarti"
          @click_causali_fermo="clickCausaliFermo"
          @click_contenitori="clickContenitori"
          @click_autocontrolli="clickAutocontrolli"
        />
      </v-col>
      <v-col cols="1"> </v-col>
    </v-row>

    <TabellaPezzi
      @click_chiudi="(pezzi_visible = false), (menu_visible = true)"
      v-if="pezzi_visible"
    />
    <TabellaTempi
      @click_chiudi="(tempi_visible = false), (menu_visible = true)"
      v-if="tempi_visible"
    />
    <TabellaScarti
      @click_chiudi="(scarti_visible = false), (menu_visible = true)"
      v-if="scarti_visible"
    />
    <TabellaFermi
      @click_chiudi="(causali_fermo_visible = false), (menu_visible = true)"
      v-if="causali_fermo_visible"
    />

    <ContenitoriCristal
      @click_chiudi="(contenitori_visible = false), (menu_visible = true)"
      v-if="contenitori_visible"
      :visible="contenitori_visible"
    />

    <ControlloQualitaCristal
      @click_chiudi="(autocontrolli_visible = false), (menu_visible = true)"
      v-if="autocontrolli_visible"
      :visible="autocontrolli_visible"
    />
    <v-overlay :value="caricamento_dati">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import ContenitoriCristal from "@/components/PRODUCTION/Avanzamento/ContenitoriCristal.vue";
import ControlloQualitaCristal from "@/components/PRODUCTION/Avanzamento/ControlloQualitaCristal.vue";
import Menu from "@/components/PRODUCTION/Avanzamento/Menu.vue";
import TabellaFermi from "@/components/PRODUCTION/Avanzamento/TabellaFermi.vue";
import TabellaPezzi from "@/components/PRODUCTION/Avanzamento/TabellaPezzi.vue";
import TabellaScarti from "@/components/PRODUCTION/Avanzamento/TabellaScarti.vue";
import TabellaTempi from "@/components/PRODUCTION/Avanzamento/TabellaTempi.vue";

export default {
  name: "Avanzamento",
  components: {
    ContenitoriCristal,
    ControlloQualitaCristal,
    Menu,
    TabellaFermi,
    TabellaPezzi,
    TabellaScarti,
    TabellaTempi,
  },
  data() {
    return {
      // Variabili d'appoggio
      caricamento_dati: false,

      // Modal
      autocontrolli_visible: false,
      causali_fermo_visible: false,
      contenitori_visible: false,
      pezzi_visible: false,
      scarti_visible: false,
      tempi_visible: false,
      menu_visible: true,

      // Array
    };
  },
  methods: {
    clickAutocontrolli(){
      this.autocontrolli_visible = true;
      this.menu_visible = false;
    },
    clickCausaliFermo() {
      this.causali_fermo_visible = true;
      this.menu_visible = false;
    },
    clickContenitori(){
      this.contenitori_visible = true;
      this.menu_visible = false;
    },
    clickPezzi() {
      this.pezzi_visible = true;
      this.menu_visible = false;
    },
    clickScarti() {
      this.scarti_visible = true;
      this.menu_visible = false;
    },
    clickTempi() {
      this.tempi_visible = true;
      this.menu_visible = false;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.sfondo {
  width: 100vw !important;
  background-image: url("../../../assets/Ind.jpg");

  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100vh !important; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
</style>