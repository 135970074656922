var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo white--text",attrs:{"dense":"","primary-title":""}},[_c('v-toolbar-title',[_vm._v("Magazzino")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","elevation":"1"},on:{"click":function($event){return _vm.$router.push({ name: 'edit-magazzino', params: { id: -1 } })}}},on),[_c('v-icon',{attrs:{"color":"#1a80b6"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Crea Magazzino")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-10",attrs:{"small":"","elevation":"1"},on:{"click":_vm.toggleFilters}},on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v(" mdi-filter ")])],1)]}}])},[_c('span',[_vm._v("Filtri")])]),_c('v-spacer')],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan","id":"progress"}}):_vm._e(),_c('v-card-text',[(_vm.show_filters)?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Tipologia","dense":"","outlined":"","hide-details":"","items":_vm.tipologieFiltri,"item-text":"des","item-value":"id_tabella"},model:{value:(_vm.tipologia),callback:function ($$v) {_vm.tipologia=$$v},expression:"tipologia"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Cod. Magazzino","dense":"","outlined":"","hide-details":"","items":_vm.codiciMagazzino,"item-text":"cod_magazzino","item-value":"cod_magazzino"},model:{value:(_vm.codice_magazzino),callback:function ($$v) {_vm.codice_magazzino=$$v},expression:"codice_magazzino"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Descrizione","dense":"","outlined":"","hide-details":"","items":_vm.descrizioni,"item-text":"des_magazzino","item-value":"des_magazzino"},model:{value:(_vm.descrizione),callback:function ($$v) {_vm.descrizione=$$v},expression:"descrizione"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Sede","dense":"","outlined":"","hide-details":"","items":_vm.sedi,"item-text":"sede_magazzino","item-value":"sede_magazzino"},model:{value:(_vm.sede),callback:function ($$v) {_vm.sede=$$v},expression:"sede"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lista_tabella.filter(function (e) { return e.validita === 1; })},scopedSlots:_vm._u([{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","elevation":"1"},on:{"click":function($event){return _vm.$router.push({ name: 'edit-magazzino', params: { id: item.id_magazzino } })}}},on),[_c('v-icon',{attrs:{"color":"#1a80b6"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","elevation":"1"},on:{"click":function($event){return _vm.$router.push({ name: 'edit-magazzino', params: { id: '-1', duplicateId: item.id_magazzino } })}}},on),[_c('v-icon',{attrs:{"color":"#2196f3"}},[_vm._v(" mdi-plus-box-multiple-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplica")])])]}},{key:"item.tipo_magazzino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTipologia(item.tipo_magazzino))+" ")]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","elevation":"1"},on:{"click":function($event){return _vm.showDeleteMagazzino(item)}}},on),[_c('v-icon',{staticClass:"error--text"},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","persistent":"","width":"unset"},model:{value:(_vm.dialogEliminaMagazzino),callback:function ($$v) {_vm.dialogEliminaMagazzino=$$v},expression:"dialogEliminaMagazzino"}},[_c('v-card',[_c('v-toolbar',{staticClass:"indigo darken-1 headline",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Eliminazione ")])],1),_c('v-card-text',{staticClass:"mt-6"},[_c('h3',{staticStyle:{"text-align":"left !important"}},[_vm._v(" Sei sicuro di voler eliminare questo magazzino? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.dialogEliminaMagazzino = false}}},[_vm._v(" ANNULLA ")]),_c('v-btn',{attrs:{"color":"#1a80b6","dark":""},on:{"click":_vm.deleteMagazzino}},[_vm._v(" CONFERMA ")])],1)],1)],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }