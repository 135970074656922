<template>
  <div style="width: 100%">
    <Packaging />
  </div>
</template>
<script>
import Packaging from "../../../components/PRODUCTION/LOGISTICA/Packaging.vue";
export default {
  components: {
    Packaging,
  },
  data() {
    return { drawer: false, group: null, menu_sel: null };
  },
};
</script>