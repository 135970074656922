<template>
  <v-app id="inspire">   
      <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
   
                  <!-- Nei Tag v-navigation-drawer è presente la visualizzazione del Tree in funzione dei dati inviati dal json -->   
                  <v-navigation-drawer
                      app
                      v-model="drawer"
                      :mini-variant.sync="mini"
                      mini-variant-width= 75
                      width="350"
                      permanent
                      >
                      <v-card class="mx-2 mt-2">
                      <v-list-item class="px-2">
                          <v-btn v-if="mini"
                          icon
                          >
                          <v-icon>mdi-menu</v-icon>
                          </v-btn> 
                          <v-list-item-title v-if="!mini">Presenze</v-list-item-title>
                          <v-btn v-if="!mini"
                          icon
                          @click.stop="mini = !mini"
                          >
                          <v-icon>mdi mdi-menu-open</v-icon>
                          </v-btn>                              
                      </v-list-item>
                      </v-card>

                      <v-divider></v-divider>
                      <v-container v-if="!mini">
                          <v-text-field
                              v-model="search"
                              label="Cerca ..."
                              outlined
                              hide-details
                              clearable
                              clear-icon="mdi-close-circle-outline"
                              @click="sezione_presenze = true"
                          ></v-text-field>
                          <!-- Gestione Treeview: Utilizzo della v-card per la gestione della scroll bar nel menu del treeview-->
                          <v-card
                              class="overflow-y-auto"
                              height="size_height"
                              max-height="1010"
                              click="sezione_presenze = true"
                          >
                              <v-treeview
                                  :active.sync="active"
                                  :open.sync="open"
                                  :items="OutArrayTree"
                                  activatable
                                  transition
                                  dense
                                  item-key="name"
                                  :search="search"
                                  :filter="filter"
                              >
                                  <template v-slot:prepend="{ item, open }">
                                  <v-icon v-if="!item.file">
                                      {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                  </v-icon>
                                  
                                      <v-btn v-else
                                          class="ma-2"
                                          text
                                          icon
                                          color="green"
                                      >
                                          <v-icon> {{ files[item.file] }}</v-icon>
                                      </v-btn>
                              
                                  </template>
                              </v-treeview> 
                          </v-card>

                          <v-divider vertical> </v-divider>
                          
                          <v-card>
                              <v-list-item class="px-2">
                              <v-btn v-if="!mini"
                                  icon
                              >
                                  <v-icon>mdi-infinity</v-icon>
                              </v-btn> 
                              <v-list-item-title v-if="!mini" class="mr-10 pr-5" >Admin Panel</v-list-item-title>
                          
                              </v-list-item>
                              
                              <v-list dense >
                                  
                                  <v-list-item-group 
                                  v-model="selectedItemPreferiti"
                                  color="primary"
                                  >
                                  <v-list-item
                                      v-for="(item, i) in menu_secondario"
                                      :key="i"
                                  >
                                  <template>
                                      <v-list-item-icon>
                                      <v-icon >mdi-account</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content
                                      @click="dialog_Gest_Utenti=true"
                                      >
                                      {{item.name}}
                                      </v-list-item-content>

                                  </template>
                                  </v-list-item>
                                  
                                  
                                  </v-list-item-group>
                              </v-list>
                          </v-card>
                      </v-container>  
                  </v-navigation-drawer>
                  <v-main>
                    <v-container v-if="sezione_presenze" class=" px-6 " fluid>
                        <template v-if="!selected">  <!-- {{size_width}} {{size_height}} visualizzo la larghezza dinamicamente-->
                            <v-data-table
                                :headers="headers_cartellino"
                                :items="cartellini_no_sel"
                                :expanded.sync="expanded"
                                item-key="name"
                                class="elevation-1"
                                hide-default-footer
                                :items-per-page="12000"
                            >
                                <template v-slot:top>
                                    <v-toolbar dense  color="white" elevation="0">
                                        <v-toolbar-title>Seleziona un dipendente ...</v-toolbar-title>
                                        <v-spacer></v-spacer>

                                        <v-btn @click="apriDashboard" icon align="center">
                                        <v-icon >mdi-home</v-icon>
                                        </v-btn>
                                        <v-btn icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        <v-menu
                                        :close-on-content-click="true"
                                        :nudge-width="200"
                                        offset-y
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            
                                            color="white"
                                            light
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ml-4 mr-1 pr-1 pl-1"
                                            elevation= 0
                                            >
                                            <v-avatar size="30">
                                                <v-icon>mdi-account</v-icon>
                                            </v-avatar>
                                            <v-icon>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>

                                        <!-- CARD UTENTE -->
                                        <v-card>
                                            <v-card-title>{{ nome_utente }}</v-card-title>
                                            <v-card-subtitle class="text-left">{{des_profilo}}</v-card-subtitle>
                                            <v-divider />
                                            <v-card-actions>
                                            <!-- IMPOSTAZIONI -->
                                            <v-btn
                                                icon
                                                x-small
                                                title="Impostazioni Account"
                                                @click="dialogGestioneAccount = true"
                                            >
                                                <v-icon>mdi-cog</v-icon>
                                            </v-btn>
                                            <!-- AMMINISTRAZIONE -->
                                            <v-btn
                                                icon
                                                x-small
                                                title="Amministrazione"
                                                v-if="show_admin_tools == true"
                                                @click="$router.push('/amministrazione/dashboard')"
                                            >
                                                <v-icon>mdi-shield-account</v-icon>
                                            </v-btn>

                                            <v-spacer />
                                            <v-btn color="error" text @click="logout">LOGOUT</v-btn>
                                            </v-card-actions>

                                        </v-card>
                                        </v-menu>    
                                        <v-dialog
                                        v-model="dialogGestioneAccount" persistent width="600px">
                                        <v-card>
                                            <v-toolbar dense  color="primary" elevation="3">
                                            <span>Gestione Utente</span>
                                            <v-spacer></v-spacer>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn  small v-bind="attrs" v-on="on" @click="dialogGestioneAccount=false">
                                                            <v-icon color="red"
                                                            >mdi-close-circle-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Chiudi.</span>
                                                </v-tooltip>            
                                            </v-toolbar>
                                            <GestioneAccount/> 
                                        </v-card>
                                        </v-dialog>                                        
                                    </v-toolbar>
                                </template>

                            </v-data-table>                            
                        </template>
                        <template v-else> <!-- Sezione nodi Figli {{size_width}} {{size_height}} visualizzo la larghezza dinamicamente-->
                            <template v-if="!parentNode">  <!-- Sezione nodi figli -->   
                                <!-- Gestione riepilogo cartellino gestito dal bottone ... vedi_cartellino --> 
                                <v-container v-if="vedi_riepilogo">
                                    <v-row>
                                        
                                        <v-col cols="6" >
                                            <v-card outlined>
                                                <v-row>    
                                                    <v-col cols="12">
                                                        <template>
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                <thead>
                                                                    <tr>
                                                                    <th class="text-left">
                                                                        <b>Tipo Permesso</b>
                                                                    </th>
                                                                    <th class="text-left">
                                                                        <b> Ore </b>
                                                                    </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                    v-for="item in riepilogo_permessi"
                                                                    :key="item.des_permesso"
                                                                    >
                                                                    <td class="text-left">{{ item.des_permesso }}</td>
                                                                    <td class="text-left">{{ item.ore_permesso }}</td>
                                                                    </tr>
                                                                </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        
                                        <v-col cols="6" >
                                            <v-card outlined>
                                                <v-row>
    
                                                    <v-col cols class="ml-2">
                                                        <v-text-field v-model="riepilogo_cartellino[0].giorni_lavorati" label="Giorni Lavorati" readonly ></v-text-field>
                                                    </v-col>
                                                    <v-col cols>
                                                        <v-text-field v-model="riepilogo_cartellino[0].numero_sabati" label="Numero Sabati" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols>
                                                    <v-text-field v-model="riepilogo_cartellino[0].ore_normali" label="Ore Normali" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols>
                                                        <v-text-field v-model="riepilogo_cartellino[0].ore_notturno" label="Ore Notturno" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols class="mr-2">
                                                        <v-text-field v-model="riepilogo_cartellino[0].bonus_mensa" label="Bonus Mensa" readonly></v-text-field>
                                                    </v-col>
                                                </v-row>  
                                                <v-row>

                                                    <v-col cols class="ml-2">
                                                        <v-text-field v-model="riepilogo_cartellino[0].ore_straordinario" label="Ore Straordinario%" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols>
                                                        <v-text-field v-model="riepilogo_cartellino[0].ore_straordinario25" label="Ore Straordinario 25%" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols>
                                                        <v-text-field v-model="riepilogo_cartellino[0].ore_straordinario30" label="Ore Straordinario 30%" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols>
                                                        <v-text-field v-model="riepilogo_cartellino[0].ore_straordinario40" label="Ore Straordinario 40%" readonly></v-text-field>
                                                    </v-col>
                                                    <v-col cols class="mr-2">
                                                        <v-text-field v-model="riepilogo_cartellino[0].ore_straordinario50" label="Ore Straordinario 50%" readonly></v-text-field>
                                                    </v-col>
                                                </v-row>   
                                            </v-card>   
                                        </v-col>

                                    </v-row>    

                                </v-container>

                                <!-- Gestione Tabella con dipendente selezionato selected: gestione trappata nella computed .. selected() --> 
                                                         
                                        <v-data-table
                                            id="mytable"
                                            disable-sort
                                            dense
                                            fixed-header
                                            :headers="headers_cartellino"
                                            :items="cartellini"
                                            :single-expand="singleExpand"
                                            :expanded.sync="expanded"
                                            item-key="data"
                                            class="elevation-1"
                                            hide-default-footer
                                            :items-per-page="12000"
                                            :item-class= "row_classes"   
                                        >
                                            <template v-slot:top>
                                                <v-toolbar flat >

                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn  small v-bind="attrs" v-on="on" @click="vedi_cartellino()">
                                                                <v-icon color="blue" v-if="vedi_riepilogo"
                                                                    >mdi mdi-arrow-collapse-up
                                                                </v-icon>
                                                                <v-icon color="blue" v-if="!vedi_riepilogo"
                                                                    >mdi mdi-arrow-collapse-down
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Riepilogo Cartellino</span>
                                                    </v-tooltip>
                                                    <v-spacer></v-spacer>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn  small v-bind="attrs" v-on="on" @click="stampa_cartellino()">
                                                                <v-icon color="blue"
                                                                    >mdi mdi-printer
                                                                </v-icon>
                                                                
                                                            </v-btn>
                                                        </template>
                                                        <span>Stampa Cartellino</span>
                                                    </v-tooltip>


                                                    <v-spacer></v-spacer>
                                                    
                                                    <v-toolbar-title> {{selected}}</v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                    <!-- Gestione campo testo e finestra modale per selezione data --> 
                                                    <v-col>
                                                        <v-dialog
                                                            ref="dialog"
                                                            v-model="modal"
                                                            :return-value.sync="date"
                                                            persistent
                                                            width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="data_selezionata"
                                                                label="Selezione Periodo"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                            v-model="date"
                                                            type="month"
                                                            scrollable
                                                            >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="modal = false"
                                                            >
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="cambia_data(date)"
                                                            >
                                                                OK
                                                            </v-btn>
                                                            <!-- "$refs.dialog.save(date)" --> 
                                                            </v-date-picker>
                                                        </v-dialog>
                                                    </v-col>
                                                    <v-spacer></v-spacer>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn  small v-bind="attrs" v-on="on" @click="chiudi_finestra()">
                                                                <v-icon color="red"
                                                                    >mdi mdi-close
                                                                </v-icon>
                                                                
                                                            </v-btn>
                                                        </template>
                                                        <span>Chiudi Finestra</span>
                                                    </v-tooltip>
                                                </v-toolbar>
                                            </template>
                                            <!-- Gestione Campo extend con Note 
                                            <template v-slot:expanded-item="{ headers, item }">
                                            <td :colspan="headers.length">
                                                NOTE: {{ item.note }}
                                            </td>
                                            </template>
                                            --> 
                                            <!-- Gestione pulsanti aggiuntivi per tibrature e permessi collocati dall' headers_cartellino --> 
                                            <template  v-slot:[`item.timbrature`]="{ item }" >
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="gestisci_timbrature(item)">
                                                            <v-icon color="blue"
                                                            >mdi-tag-text-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Gestione Timbrature</span>
                                                </v-tooltip>
                                            </template>

                                            <template  v-slot:[`item.permessi`]="{ item }">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn outlined small v-bind="attrs" v-on="on" @click="gestione_permessi(item)">
                                                            <v-icon color="green"
                                                            >mdi-briefcase-check
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Gestione Permessi</span>
                                                </v-tooltip>
                                            </template>

                                            <template  v-slot:[`item.ore_presenza`]="{ item }">
                                                <v-chip
                                                    :color="getColor(item)">
                                                    {{ item.ore_presenza }}
                                                </v-chip>
                                            </template>

                                            <template  v-slot:[`item.ingresso1_hh_mm`]="{ item }">
                                                <v-chip
                                                    :color="getColorAuth(item)">
                                                    {{ item.ingresso1_hh_mm }}
                                                </v-chip>
                                            </template>

                                            <template  v-slot:[`item.ingresso2_hh_mm`]="{ item }">
                                                <v-chip
                                                    :color="getColorAuth(item)">
                                                    {{ item.ingresso2_hh_mm }}
                                                </v-chip>
                                            </template>

                                            <template  v-slot:[`item.ingresso3_hh_mm`]="{ item }">
                                                <v-chip
                                                    :color="getColorAuth(item)">
                                                    {{ item.ingresso3_hh_mm }}
                                                </v-chip>
                                            </template>

                                            <template  v-slot:[`item.ingresso4_hh_mm`]="{ item }">
                                                <v-chip
                                                    :color="getColorAuth(item)">
                                                    {{ item.ingresso4_hh_mm }}
                                                </v-chip>
                                            </template>

                                        </v-data-table>
                               

                                <!-- Gestione dialog timbrature --> 
                                <v-dialog v-model="dialog_timbrature" max-width="70%" persistent>
                                    <v-card>
                                        <v-toolbar
                                                class="white--text"
                                                color="blue"
                                                dense
                                                style="position: sticky; top: 0px; z-index: 1"
                                                >
                                                <v-toolbar-title> Gestione Timbrature </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    class="red--text"
                                                    @click="dialog_timbrature = false"
                                                    color="blue-grey lighten-5"
                                                    small
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                                <GestioneTimbrature 
                                                    :ID_UTENTE=this.timbratura.id_personale 
                                                    :DATA=this.timbratura.data  
                                                    :TRIGGER = this.modifica
                                                    v-on:timbrature="valori_di_ritorno_timbrature"
                                                />
                                    </v-card>
                                </v-dialog>

                                <!-- Gestione dialog presenze --> 
                                <v-dialog v-model="dialog_presenze" max-width="70%" persistent>
                                    <v-card>
                                        <v-toolbar
                                                class="white--text"
                                                color="blue"
                                                dense
                                                style="position: sticky; top: 0px; z-index: 1"
                                                >
                                                <v-toolbar-title> Gestione Premessi </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    class="red--text"
                                                    @click="dialog_presenze = false"
                                                    color="blue-grey lighten-5"
                                                    small
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                                <GestionePermessi 
                                                    :ID_UTENTE=this.presenze.id_personale 
                                                    :DATA=this.presenze.data  
                                                    :TRIGGER = this.modifica
                                                    v-on:permessi="valori_di_ritorno_presenze"
                                                /> 
                                    </v-card>
                                </v-dialog>
        
                                <!-- Gestione dialog Stampa Cartellino -->
                                <v-dialog  v-model="pdfdialog" width="unset">
                                    <v-card>
                                        <v-toolbar
                                            class="white--text"
                                            color="blue"
                                            dense
                                            style="position: sticky; top: 0px; z-index: 1"
                                            >
                                            <v-toolbar-title> Stampa Cartellino </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="red--text"
                                                @click="pdfdialog = false"
                                                color="blue-grey lighten-5"
                                                small
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <iframe
                                                    :src="this.selectedPdf"
                                                    frameborder="0"
                                                    style="height: 90vh !important; width: 90vw !important"
                                                >
                                                </iframe>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    
                                </v-dialog>  
                                
                                <v-overlay :value="overlay">
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>

                            </template>
                            <template v-else>   <!-- Sezione Nodi Padre -->  
                                <v-toolbar dense  color="white" elevation="1">
                                    <GlobalToolBar 
                                        :NOME_AZIENDA = this.nome_azienda
                                        :NOME_UTENTE = this.nome_utente  
                                        :DES_PROFILO = this.des_profilo
                                    /> 
                                </v-toolbar>   
                                <v-divider vertical />
                                <v-card> 
                                    <v-row> <!-- Sezione data -->
                                       
                                        <v-col cols="12" class="ml-10" sm="6" md="7" align="left">
                                            <h2> Report e Stampe - Invio Mail </h2>  
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-card-text>
                                                <v-dialog
                                                    ref="dialog_data_pag_stampe"
                                                    v-model="modal_pag_stampe"
                                                    :return-value.sync="data_pag_stampe"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="data_selezionata_stampe"
                                                        label="Selezione Periodo"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="data_pag_stampe"
                                                    type="month"
                                                    scrollable
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="modal_pag_stampe = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="cambia_data_pag_stampe(data_pag_stampe)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    <!-- "$refs.dialog.save(date)" --> 
                                                    </v-date-picker>
                                                </v-dialog>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>   

                                    <v-container v-if="Sono_Sede"> <!-- Sono pagina stampe Sede-->
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <v-card-text>
                                                <v-card outlined>
                                                    <v-card-title>
                                                        Report
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                        <v-col cols="12" sm="6" md="4" align="left">
                                                            <v-btn
                                                            color="black"
                                                            outlined
                                                            dark
                                                            @click="stampa_cartellino_sede()"
                                                            >      <v-icon left>
                                                                        mdi-printer
                                                                    </v-icon>
                                                                Stampa Cartellino Sede
                                                            </v-btn>
                                                        </v-col>

                                                        </v-row>
                                                    </v-card-text>   
                                                
                                                </v-card>    
                                                </v-card-text>             
                                            </v-col>    
                                            <v-col cols="12" sm="6" md="12">
                                                <v-card-text>
                                                <v-card outlined>
                                                    <v-card-title>
                                                        Invio Mail
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                        <v-col cols="12" sm="6" md="4" align="left">
                                                            <v-btn
                                                            color="black"
                                                            outlined
                                                            dark
                                                            @click="mail_dipendenti_sede()"
                                                            >      <v-icon left>
                                                                        mdi-mail
                                                                    </v-icon>
                                                                Invio Mail Dipendenti Sede
                                                            </v-btn>
                                                        </v-col>

                                                        </v-row>
                                                    </v-card-text>   
                                                
                                                </v-card>    
                                                </v-card-text>             
                                            </v-col>                   
                                        </v-row>    
                                    </v-container>    
                                    <v-container v-else> <!-- Sono pagina stampe Azienda-->
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <v-card-text>
                                                <v-card outlined>
                                                    <v-card-title>
                                                        Report
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                        <v-col cols="12" sm="6" md="4" align="left">
                                                            <v-btn
                                                            color="black"
                                                            outlined
                                                            dark
                                                            @click="stampa_cartellino_azienda()"
                                                            >      <v-icon left>
                                                                        mdi-printer
                                                                    </v-icon>
                                                                Stampa Cartellino Azienda
                                                            </v-btn>
                                                        </v-col>

                                                        </v-row>
                                                    </v-card-text>   
                                                
                                                </v-card>    
                                                </v-card-text>             
                                            </v-col>                      
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12" align="left">
                                                <v-card-text>
                                                <v-card outlined>
                                                    <v-card-title>
                                                        Export
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                        <v-col cols="12" sm="6" md="4">
                                                            <v-btn
                                                            color="black"
                                                            outlined
                                                            dark
                                                            @click="Download_Cartellino_Azienda()"
                                                            >      <v-icon left>
                                                                        mdi-export
                                                                    </v-icon>
                                                                Esporta Cartellino Azienda
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="12" sm="6" md="4" align="left">

                                                            <v-btn
                                                            color="black"
                                                            outlined
                                                            dark
                                                            @click="Download_LUL_Azienda()"
                                                            > 
                                                                    <v-icon left>
                                                                        mdi-export
                                                                    </v-icon>
                                                                Esporta LUL Azienda
                                                            </v-btn>
                                                        </v-col>
                                                        </v-row>
                                                    </v-card-text>   
                                                
                                                </v-card>    
                                                </v-card-text>             
                                            </v-col>                      
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <v-card-text>
                                                <v-card outlined>
                                                    <v-card-title>
                                                        Bollato  ( data ultimo bollato:  {{Data_ultimo_bollato}} )
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col cols="12" sm="6" md="4" align="left">
                                                                <v-btn
                                                                color="black"
                                                                outlined
                                                                dark
                                                                @click="genera_bollato_azienda()"
                                                                >      <v-icon left>
                                                                            mdi-menu
                                                                        </v-icon>
                                                                    Genera Bollato Azienda
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="4" align="left">
                                                                <v-btn
                                                                color="black"
                                                                outlined
                                                                dark
                                                                @click="stampa_bollato_azienda()"
                                                                >      <v-icon left>
                                                                            mdi-printer
                                                                        </v-icon>
                                                                    Stampa Bollato Azienda
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>   
                                                
                                                </v-card>    
                                                </v-card-text>             
                                            </v-col>                      
                                        </v-row>  
                                    </v-container> 

                                </v-card>  

                                <v-dialog  v-model="pdfdialog_sedi" width="unset">
                                    <v-card>
                                        <v-toolbar
                                            class="white--text"
                                            color="blue"
                                            dense
                                            style="position: sticky; top: 0px; z-index: 1"
                                            >
                                            <v-toolbar-title> Stampa Cartellino Sede </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="red--text"
                                                @click="pdfdialog_sedi = false"
                                                color="blue-grey lighten-5"
                                                small
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <iframe
                                                    :src="this.selectedPdf"
                                                    frameborder="0"
                                                    style="height: 90vh !important; width: 90vw !important"
                                                >
                                                </iframe>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    
                                </v-dialog>

                                <v-dialog  v-model="pdfdialog_azienda" width="unset">
                                    <v-card>
                                        <v-toolbar
                                            class="white--text"
                                            color="blue"
                                            dense
                                            style="position: sticky; top: 0px; z-index: 1"
                                            >
                                            <v-toolbar-title> Stampa Cartellino Azienda </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="red--text"
                                                @click="pdfdialog_azienda = false"
                                                color="blue-grey lighten-5"
                                                small
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <iframe
                                                    :src="this.selectedPdf"
                                                    frameborder="0"
                                                    style="height: 90vh !important; width: 90vw !important"
                                                >
                                                </iframe>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    
                                </v-dialog>

                                <v-dialog  v-model="pdfdialog_bollato" width="unset">
                                    <v-card>
                                        <v-toolbar
                                            class="white--text"
                                            color="blue"
                                            dense
                                            style="position: sticky; top: 0px; z-index: 1"
                                            >
                                            <v-toolbar-title> Stampa Bollato Azienda </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="red--text"
                                                @click="pdfdialog_bollato = false"
                                                color="blue-grey lighten-5"
                                                small
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                                <iframe
                                                    :src="this.selectedPdf"
                                                    frameborder="0"
                                                    style="height: 90vh !important; width: 90vw !important"
                                                >
                                                </iframe>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    
                                </v-dialog>

                                <v-dialog  v-model="dialog_mail_dipendenti" width="500px">
                                    <v-card>
                                        <v-toolbar
                                            class="white--text"
                                            color="blue"
                                            dense
                                            style="position: sticky; top: 0px; z-index: 1"
                                            >
                                            <v-toolbar-title> Invio Mail Dipendenti </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                class="red--text"
                                                @click="dialog_mail_dipendenti = false"
                                                color="blue-grey lighten-5"
                                                small
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-card-title>Seleziona dipendenti Sede:</v-card-title>
                                                <template>
                                                    <v-data-table
                                                        v-model="dipendenti_selezionati"
                                                        :headers="headers"
                                                        fixed-header
                                                        height="60vh"
                                                        :items="personale_sede"
                                                        :single-select="singleSelect"
                                                        item-key="name"
                                                        show-select
                                                        class="elevation-1"
                                                        hide-default-footer
                                                        :items-per-page="12000"
                                                    >

                                                    </v-data-table>
                                                </template>
                                                   <v-row> 
                                                    <v-col cols="12" sm="5" md="5"/>
                                                    <v-col cols="12" sm="3" md="3">
                                                        <v-card-actions >
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="invia_mail()"
                                                            >
                                                                Invia
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-col>
                                                   </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    
                                </v-dialog>                                

                                <v-overlay :value="overlay">
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>

                            </template>    
                        </template>
                    </v-container>

                    <v-dialog v-model="dialog_Gest_Utenti" max-width="95%" persistent>
                        <v-card>
                            <v-toolbar
                                    class="white--text"
                                    color="blue"
                                    dense
                                    style="position: sticky; top: 0px; z-index: 1"
                                    >
                                    <v-toolbar-title> Gestione Utenti </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        class="red--text"
                                        @click="dialog_Gest_Utenti = false"
                                        color="blue-grey lighten-5"
                                        small
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                    <GestioneUtenti/> 
                        </v-card>
                    </v-dialog>     




                  </v-main>
       


    
  </v-app>

</template>

<style>
    /* non usare style scoped*/
    .v-treeview--dense .v-treeview-node__root {
        text-align: left;
        font-size: 14px;
    }

    #mytable table {
        width: auto;
        line-height: 1;
        font-size: 14px;
        }
        html ::-webkit-scrollbar {
            width: 2px;
            display: block; 
        }
        ::-webkit-scrollbar {
            width: 2px;
        }
        ::-webkit-scrollbar-thumb {
            background: rgb(221, 221, 221); 
        }
        ::-webkit-scrollbar-track {
            background: rgb(221, 221, 221); 
        }

</style>

<script>
    import GlobalToolBar from "@/components/GlobalToolBar.vue";
    import GestioneTimbrature from "./components/GestioneTimbrature.vue";
    import GestionePermessi from "./components/GestionePermessi.vue";
    import GestioneUtenti from "./components/GestioneUtenti.vue";
    import GestioneAccount from "@/components/AMMINISTRAZIONE/GestioneAccount.vue";
    export default {
    components: {
        GestioneTimbrature,
        GestionePermessi,
        GestioneUtenti,
        GlobalToolBar,
        GestioneAccount,
    },    
        data: () => ({

            snackbar: false,
            snackbar_color: "",
            snackbar_text: "",
            overlay: false,
            selectedItemPreferiti:null,
            sezione_presenze: true,

            nome_azienda: 'Gestione Presenze',
            des_profilo: localStorage.des_profilo,
            nome_utente: localStorage.nominativo,
            id_profilo: localStorage.id_profilo,

            dialogGestioneAccount:false,
            show_admin_tools: localStorage.show_admin_tools == "true",

            modifica: 0, 
            dialog_Gest_Utenti: false,

            date: new Date().toISOString().substr(0, 7),
            data_selezionata: '',
            mese_selezionato: '',
            anno_selezionato: '',

            data_pag_stampe: new Date().toISOString().substr(0, 7),
            data_selezionata_stampe: '',
            mese_selezionato_stampe: '',
            anno_selezionato_stampe: '',

            utente_selezionato: -1, //contiene id_personale
            menu: false,
            modal: false,
            modal_pag_stampe: false,
            
            rules: {
            required: value => !!value || 'Required.',
            counter: value => value.length <= 50 || 'Max 50 Caratteri',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            },

            menu_secondario: [
                      {
                        name: 'Gestione Utenti',
                      },
            ],
            //example Array for tree
            /*
            items: [
                {
                name: '.git',
                },
                {
                name: 'node_modules',
                },
                {
                    
                name: 'public',
                children: [
                    {
                    name: 'static',
                    children: [{
                        name: 'logo.png',
                        file: 'png',
                    }],
                    },
                    {
                    name: 'favicon.ico',
                    file: 'png',
                    },
                    {
                    name: 'index.html',
                    file: 'html',
                    },
                ],
                },
                {
                name: '.gitignore',
                file: 'txt',
                },
                {
                name: 'babel.config.js',
                file: 'js',
                },
                {
                name: 'package.json',
                file: 'json',
                },
                {
                name: 'README.md',
                file: 'md',
                },
                {
                name: 'vue.config.js',
                file: 'js',
                },
                {
                name: 'yarn.lock',
                file: 'txt',
                },
            ],
            */
            // Visualizzazione e Gestione Tree
            search: null,
            caseSensitive: false,
            active: [],
            open: [],
            array_tree: [],
            personale:[],
            OutArrayTree: [],
            Aziende_Sedi: [], //Array con i nomi delle sedi e delle aziende
            Sono_Sede: '',
            Solo_Sedi: [],  //Array delle sedi relativi id
            Sede_id: 0,
            Solo_Aziende:[], // Array delle aziende con relativi id
            personale_tot:[],
            
            Azienda_id: 0,
            Data_ultimo_bollato: '',
            Ultima_pagina_bollato: 0,

            parentNode: false,

            //navigation-drawer
            mini: false,
            drawer: true,

            //Gestiore Icone Tree
        files: {
                html: 'mdi-language-html5',
                js: 'mdi-nodejs',
                json: 'mdi-code-json',
                md: 'mdi-language-markdown',
                pdf: 'mdi-file-pdf',
                png: 'mdi-file-image',
                txt: 'mdi-file-document-outline',
                xls: 'mdi-file-excel',
                utenti: 'mdi-account',
            },

            headers_cartellino: [
                { text: 'Data', value: 'data_str', width: "5%" },
                { data: 'Actions', value: 'timbrature', orderable: false, searchable: false, width: "5%"},
                { text: 'Ingr.1', value: 'ingresso1_hh_mm' },
                { text: 'Usc.1', value: 'uscita1_hh_mm' },
                { text: 'Ingr.2', value: 'ingresso1_hh_mm' },
                { text: 'Usc.2', value: 'uscita2_hh_mm' },
                { text: 'Permessi', value: 'des_permesso',width: "15%" },
                { data: 'Actions', value: 'permessi', orderable: false, searchable: false, width: "5%"},
                { text: 'Ore Presenza', value: 'ore_presenza', width:'5%' },
                { text: 'Ore Permesso', value: 'ore_permesso', width:'5%' },
                { text: 'Ore Norm...', value: 'ore_normali', width:'5%' },
                { text: 'Ore Straor...', value: 'ore_straordinario', width:'5%' },
                { text: 'Ore Notturno', value: 'ore_notturno', width:'5%' },
                { text: '', value: 'data-table-expand' },
            ],
            expanded: [],
            singleExpand: false,
            cartellini: [],
            cartellini_no_sel: [],
            height: 850,

            riepilogo_cartellino: [],
        // riepilogo_cartellino: { giorni_lavorati: 0, numero_sabati: 0, ore_normali: 0, ore_notturno: 0, bonus_mensa: 0, ore_straordinario: 0, ore_straordinario25: 0, ore_straordinario30: 0, ore_straordinario40: 0, ore_straordinario50: 0},
            vedi_riepilogo: false,

            riepilogo_permessi:[],

            //Gestione Tibrature
            dialog_timbrature: false,
            timbratura: [],
            //Gestione Presenze
            dialog_presenze: false,
            presenze:[],

            file_b64:'',
            selectedPdf:'',
            pdfdialog: false,
            pdfdialog_sedi:false,
            pdfdialog_azienda:false,
            pdfdialog_bollato:false,
           
            

            windowHeight: window.innerHeight,
            size_width: '',
            size_height: '',

            //--------------Sezione Invio Mail

                dialog_mail_dipendenti:false,
                singleSelect: false,
                dipendenti_selezionati: [],
                headers: [
                {text: 'Cognome', align: 'start',sortable: true, value: 'cognome'},
                { text: 'Nome', align: 'start',sortable: true, value: 'nome' },
                ],
                personale_sede:[],
            

        }),
        computed: {
        filter () {
            return this.caseSensitive
            ? (item, search, textKey) => item[textKey].indexOf(search) > -1
            : undefined
        },
            selected () {
            if (!this.active.length) 
                return undefined
            const nome_dipendente = this.active[0]
            if (this.check_parent (nome_dipendente)){
                this.apri_parent_page()
            }else{
                var utente = this.find_user_tree (nome_dipendente)                   

                this.getschedapersonale(utente.id_personale)
            } 
            return nome_dipendente
        },
        },
        watch: {

            /*
            size_width: {
                immediate: true,
                handler() {
                        this.onResize()
                    }
                },
            size_height: {
                immediate: true,
                handler() {
                        this.onResize()
                    }
                },
            height:   {
                immediate: true,
                handler() {
                    this.height = this.size_height
                    }
                },
            */            
        },
        beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
        },
        mounted() {
            this.data_selezionata = this.date
            this.anno_selezionato = this.date.substr(0, 4)
            this.mese_selezionato = this.date.substr(5, 2)

            this.data_selezionata_stampe = this.data_pag_stampe
            this.anno_selezionato_stampe = this.data_pag_stampe.substr(0, 4)
            this.mese_selezionato_stampe = this.data_pag_stampe.substr(5, 2)


            this.getdata()

            this.onResize()
            window.addEventListener('resize', this.onResize, { passive: true })

        },
        methods: {
            onResize () {
                this.size_width = this.$vuetify.breakpoint.width
                this.size_height = this.$vuetify.breakpoint.height
                //console.log(this.size_height)
                //if (this.size_width < 1600) this.mini = true
            },

            getdata(){
                    let request = {
                        controller: "Presenze",
                        method: "POST",
                        richiesta: {
                            action: "getlistapersonale",
                            mese: this.mese_selezionato,
                            anno: this.anno_selezionato,
                            token: localStorage.getItem("user_token"),
                        },
                    }
                    this.$store.dispatch("api", request).then((res) => {                    
                    this.array_tree = res.data["azienda"];
                    this.Solo_Sedi = []
                    this.Aziende_Sedi = []
                    this.OutArrayTree = []
                    this.map_tree_aziende(res.data["azienda"]);  

                });
            },

            getschedapersonale(id){

                if(id == "" || id == null)
                {   
                    this.snackbar_text = "Utente non attivo in questo mese .. la pagina viene ricaricata";
                    this.snackbar_color = "error";
                    this.snackbar = true;  
                    window.location.reload(false);                  
                }

                this.utente_selezionato = id
                this.parentNode = false

                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                    action: "getschedapersonale",
                    anno: this.anno_selezionato,
                    mese: this.mese_selezionato,
                    id_personale: id, 
                    token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {           
                    this.cartellini = res.data["cartellino"];
                    this.set_headers_cartellino();
                    this.riepilogo_cartellino = [];
                    this.load_riepilogo_cartellino(res.data["riepilogo_cartellino"]);
                    this.riepilogo_permessi= [];
                    res.data["permessi"].forEach((elem) => {
                        this.riepilogo_permessi.push(elem);
                          
                    });    
                    this.overlay = false            
                    //console.log(this.riepilogo_cartellino)
                })
                .catch(e => {
                    window.location.href = '/home/presenze/dashboard'
                    console.log(e);
                     this.overlay = false 
                });
            },
            //https://www.cloudhadoop.com/2018/09/how-to-convert-array-to-json-and-json.html#:~:text=%20How%20to%20Convert%20JSON%20to%20Array%20Objects,to%20Array%20using%20the%20push%20method%20More%20
            map_tree_aziende(array_aziende){
                var numero_aziende = array_aziende.length
                if (numero_aziende > 0 ){
                    for (var i = 0; i <=numero_aziende - 1; i++){
                    this.OutArrayTree.push({  
                            name: array_aziende[i].ragione_sociale,  
                            children: this.map_tree_sedi(array_aziende[i]['sedi'])
                        });  
                    this.Aziende_Sedi.push({  
                            name: array_aziende[i].ragione_sociale,  
                        });  
                    this.Solo_Aziende.push({  
                            name: array_aziende[i].ragione_sociale,  
                            id_azienda: array_aziende[i].id_azienda,
                            data_ultimo_bollato: array_aziende[i].data_ultimo_bollato,
                            ultima_pagina_bollato: array_aziende[i].ultima_pagina_bollato,
                        });
                    }
                }
            // console.log(this.OutArrayTree)
            },        
            map_tree_sedi(array_sedi){
                var sedi = new Array();
                var numero_sedi = array_sedi.length
                if (numero_sedi > 0 ){
                    for (var i = 0; i <=numero_sedi - 1; i++){
                    sedi.push({  
                            name: array_sedi[i].des_sede,  
                            children: this.map_tree_personale(array_sedi[i]['personale'])
                        });  
                        this.Aziende_Sedi.push({  
                            name: array_sedi[i].des_sede,  
                        });
                        this.Solo_Sedi.push({  
                            name: array_sedi[i].des_sede,  
                            id_sede: array_sedi[i].id_sede
                        });

                    }
                }
                return sedi;
            },
            map_tree_personale(array_personale){
                var personale = new Array();
                var numero_personale = array_personale.length
                if (numero_personale > 0 ){
                    for (var i = 0; i <numero_personale ; i++){
                        personale.push({  
                            name: array_personale[i].cognome + " " + array_personale[i].nome ,
                            file: 'utenti',
                            id_personale: array_personale[i].id_personale,
                            id_azienda: array_personale[i].id_azienda,
                            id_sede: array_personale[i].id_sede,
                            tipo_cartellino: array_personale[i].tipo_cartellino,
                            nome: array_personale[i].nome,
                            cognome: array_personale[i].cognome,
                        }); 
                    }
                }
                this.personale_tot = personale
                return personale
            }, 
            find_user_tree(nome_dipendente){
                this.overlay =true
                var array_aziende = this.array_tree
                var numero_aziende = array_aziende.length
                if (numero_aziende > 0 ){
                    for (var a = 0; a <=numero_aziende - 1; a++){
                        var array_sedi = array_aziende[a]['sedi']
                        var numero_sedi = array_sedi.length
                        if (numero_sedi > 0 ){
                            for (var s = 0; s <=numero_sedi - 1; s++){
                                var array_personale = array_sedi[s]['personale']
                                var numero_personale = array_personale.length
                                if (numero_personale > 0 ){
                                    for (var p = 0; p <numero_personale; p++){
                                        if ((array_personale[p].cognome + " " + array_personale[p].nome) == nome_dipendente)
                                            return array_personale[p]
                                        
                                    } 
                                }               
                            }
                        }
                    }
                }
            },
            row_classes(item) {
                if (item.festivita == 1) {
                    return "grey lighten-2";
                } 
                else
                if (item.giorno_settimana == "Sab" ) {
                    return "grey lighten-4";
                }  
            },
            getColor (item) {
                if (item.ore_presenza_default > item.ore + item.ore_permesso) return 'yellow'
                //else if (calories > 200) return 'orange'
                //else return 'green'
            },
            getColorAuth (item) {
                if (item.auth_ingresso1 === 1) return 'orange'
                else 
                if (item.festivita == 1) {
                    return "grey lighten-2";
                } 
                else
                if (item.giorno_settimana == "Sab" ) {
                    return "grey lighten-4";
                }
                else return 'white'
            },
            set_headers_cartellino(){
                var lung_array_cartellino = this.cartellini.length
                var num_max_timbrature = 1
                    for (var i = 0; i <=lung_array_cartellino - 1; i++){
                        if (this.cartellini[i].num_timbrature > num_max_timbrature)
                            num_max_timbrature = this.cartellini[i].num_timbrature
                    }
                    if (num_max_timbrature == 1 ){
                        if (this.size_width < 1500) this.mini = true
                        this.headers_cartellino = [
                            { text: 'Data', value: 'data_str', width: "5%" },
                            { data: 'Actions', value: 'timbrature', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ingr.1', value: 'ingresso1_hh_mm', width: "5%" },
                            { text: 'Usc.1', value: 'uscita1_hh_mm', width: "5%" },
                            { text: 'Permessi', value: 'des_permesso',width: "10%" },
                            { data: 'Actions', value: 'permessi', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ore Presenza', value: 'ore_presenza', width:'5%' },
                            { text: 'Ore Permesso', value: 'ore_permesso', width:'5%' },
                            { text: 'Ore Norm...', value: 'ore_normali', width:'5%' },
                            { text: 'Ore Straor...', value: 'ore_straordinario', width:'5%' },
                            { text: 'Ore Notturno', value: 'ore_notturno', width:'5%' },
                            { text: 'Note', value: 'note', width:'5%' }
                        ]
                    }
                    if (num_max_timbrature == 2 ){
                    if (this.size_width < 1500) this.mini = true
                        this.headers_cartellino = [
                            { text: 'Data', value: 'data_str', width: "5%" },
                            { data: 'Actions', value: 'timbrature', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ingr.1', value: 'ingresso1_hh_mm' , width: "5%"},
                            { text: 'Usc.1', value: 'uscita1_hh_mm', width: "5%" },
                            { text: 'Ingr.2', value: 'ingresso2_hh_mm', width: "5%" },
                            { text: 'Usc.2', value: 'uscita2_hh_mm', width: "5%" },
                            { text: 'Permessi', value: 'des_permesso',width: "10%" },
                            { data: 'Actions', value: 'permessi', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ore Presenza', value: 'ore_presenza', width:'5%' },
                            { text: 'Ore Permesso', value: 'ore_permesso', width:'5%' },
                            { text: 'Ore Norm...', value: 'ore_normali', width:'5%' },
                            { text: 'Ore Straor...', value: 'ore_straordinario', width:'5%' },
                            { text: 'Ore Notturno', value: 'ore_notturno', width:'5%' },
                            { text: 'Note', value: 'note', width:'5%' }
                        ]
                        
                    }
                    if (num_max_timbrature == 3 ){
                        if (this.size_width < 1700) this.mini = true
                        this.headers_cartellino = [
                            { text: 'Data', value: 'data_str', width: "5%" },
                            { data: 'Actions', value: 'timbrature', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ingr.1', value: 'ingresso1_hh_mm', width: "5%" },
                            { text: 'Usc.1', value: 'uscita1_hh_mm', width: "5%" },
                            { text: 'Ingr.2', value: 'ingresso2_hh_mm', width: "5%" },
                            { text: 'Usc.2', value: 'uscita2_hh_mm', width: "5%" },
                            { text: 'Ingr.3', value: 'ingresso3_hh_mm', width: "5%" },
                            { text: 'Usc.3', value: 'uscita3_hh_mm' , width: "5%"},
                            { text: 'Permessi', value: 'des_permesso' ,width: "10%"},
                            { data: 'Actions', value: 'permessi', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ore Presenza', value: 'ore_presenza', width:'5%' },
                            { text: 'Ore Permesso', value: 'ore_permesso', width:'5%' },
                            { text: 'Ore Norm...', value: 'ore_normali', width:'5%' },
                            { text: 'Ore Straor...', value: 'ore_straordinario', width:'5%' },
                            { text: 'Ore Notturno', value: 'ore_notturno', width:'5%' },
                            { text: 'Note', value: 'note', width:'5%' }
                        ]
                    }
                    if (num_max_timbrature == 4 ){
                        if (this.size_width < 1900) this.mini = true
                        this.headers_cartellino = [
                            { text: 'WK', value: 'num_settimana', width: "5%" },
                            { text: 'Data', value: 'data_str', width: "8%" },
                            { data: 'Actions', value: 'timbrature', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ingr.1', value: 'ingresso1_hh_mm', width: "5%" },
                            { text: 'Usc.1', value: 'uscita1_hh_mm', width: "5%" },
                            { text: 'Ingr.2', value: 'ingresso1_hh_mm' , width: "5%"},
                            { text: 'Usc.2', value: 'uscita2_hh_mm', width: "5%" },
                            { text: 'Ingr.3', value: 'ingresso3_hh_mm', width: "5%" },
                            { text: 'Usc.3', value: 'uscita3_hh_mm', width: "5%" },
                            { text: 'Ingr.4', value: 'ingresso4_hh_mm', width: "5%" },
                            { text: 'Usc.4', value: 'uscita4_hh_mm', width: "5%"},
                            { text: 'Permessi', value: 'des_permesso' ,width: "10%" },
                            { data: 'Actions', value: 'permessi', orderable: false, searchable: false, width: "5%"},
                            { text: 'Ore Presenza', value: 'ore_presenza', width:'5%' },
                            { text: 'Ore Permesso', value: 'ore_permesso', width:'5%' },
                            { text: 'Ore Norm...', value: 'ore_normali', width:'5%' },
                            { text: 'Ore Straor...', value: 'ore_straordinario', width:'5%' },
                            { text: 'Ore Notturno', value: 'ore_notturno', width:'5%' },
                            { text: 'Note', value: 'note', width:'5%' }
                        ]
                    }
            },
            load_riepilogo_cartellino(array_cartellino){
                var lung = array_cartellino.length
                if (lung > 0 ){
                    for (var i = 0; i <=lung - 1; i++){
                    this.riepilogo_cartellino.push({  
                            giorni_lavorati: array_cartellino[i].giorni_lavorati,
                            numero_sabati: array_cartellino[i].num_sabati,
                            ore_normali: array_cartellino[i].ore_normali,
                            ore_notturno: array_cartellino[i].ore_notturno,
                            bonus_mensa: array_cartellino[i].bonus_mensa,
                            ore_straordinario: array_cartellino[i].ore_straordinario,
                            ore_straordinario25: array_cartellino[i].ore_straordinario25,
                            ore_straordinario30: array_cartellino[i].ore_straordinario30,
                            ore_straordinario40: array_cartellino[i].ore_straordinario40,
                            ore_straordinario50: array_cartellino[i].ore_straordinario50,
                        });  
                    }
                }
            // console.log(this.OutArrayTree)
            },
            vedi_cartellino(){
                if (this.vedi_riepilogo == true) 
                    this.vedi_riepilogo = false
                else
                    this.vedi_riepilogo = true
            },
            gestisci_timbrature(item){
                //this.editedIndex = this.anagrafiche.indexOf(item)
                this.timbratura = Object.assign({}, item)
                this.modifica = Math.round(Math.random()*100);   
                console.log (this.modifica)
                this.dialog_timbrature = true
            },
            gestione_permessi(item){
                this.presenze = Object.assign({}, item)
                this.modifica = Math.round(Math.random()*100);  
                this.dialog_presenze = true
            },
            valori_di_ritorno_presenze(value){
                //necessario reload dell'id selezionato e chiusura dialog per ricaricare il salvataggio fatto nel componente
                this.getschedapersonale(value[0].id_personale)
                this.dialog_presenze =  false
            },
            valori_di_ritorno_timbrature(value){
                //necessario reload dell'id selezionato e chiusura dialog per ricaricare il salvataggio fatto nel componente
                
                this.getschedapersonale(value[0].id_personale)
                this.dialog_timbrature =  false
            },
            getNow() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.timestamp = dateTime;
            },
            cambia_data(){
                this.data_selezionata = this.date 
                this.anno_selezionato = this.date.substr(0, 4)
                this.mese_selezionato = this.date.substr(5, 2)
            // this.OutArrayTree = []
                this.getdata()
                this.getschedapersonale(this.utente_selezionato)
                this.modal = false
            },
            cambia_data_pag_stampe(){
                this.data_selezionata_stampe = this.data_pag_stampe
                this.anno_selezionato_stampe = this.data_pag_stampe.substr(0, 4)
                this.mese_selezionato_stampe = this.data_pag_stampe.substr(5, 2)
            // this.OutArrayTree = []
                this.modal_pag_stampe = false
            },
            check_parent(nodo){
                //Controllo che il nodo sia = al nome azienda o di una sede setto la relativa variabile Azienda_id/Seda_id
                
                var ritorno = false

                this.Aziende_Sedi.forEach(azienda_sede => {
                    
                    if (nodo == azienda_sede.name) {
                            
                            ritorno = true
                    }
                });

                //controllo se il nodo è una sede e prendo l'ID
                let id = 0
                this.Solo_Sedi.forEach(sede => {
                    if (nodo == sede.name) {
                        id = sede.id_sede
                        this.Sono_Sede = true
                    }
                });
                this.Sede_id = id
                
                //controllo se il nodo è un'azienda e prendo l'ID
                let id_azienda = 0
                let data_ultimo_bollato = ''
                let ultima_pagina_bollato = 0

                this.Solo_Aziende.forEach(azienda => {
                    if (nodo == azienda.name) {
                        id_azienda = azienda.id_azienda
                        data_ultimo_bollato = azienda.data_ultimo_bollato
                        ultima_pagina_bollato = azienda.ultima_pagina_bollato
                        this.Sono_Sede = false
                    }
                });
                this.Azienda_id = id_azienda
                this.Data_ultimo_bollato = data_ultimo_bollato
                this.Ultima_pagina_bollato = ultima_pagina_bollato

                return ritorno 
            },
            apri_parent_page(){
                //Se parentNode = true si aprirà la pagina dei report e delle stampe aziendali/sedi e non la pagina dell'utente con le relative timbrature e permessi.
                this.parentNode = true
                //console.log ("sono parent")
            },

            async stampa_cartellino(){
                await this.getStampaCartellino();
                const byteCharacters = atob(this.file_b64);
                const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "application/pdf" });
                const blobUrl = URL.createObjectURL(blob);
                this.selectedPdf = blobUrl;
                this.pdfdialog = true;   
            },
            getStampaCartellino() {
                return new Promise((resolve, reject) => {
                    this.overlay = true;
                    let disegnoReq = {
                        controller: "Presenze",
                        method: "POST",
                        richiesta: {
                        action: "getcartellinopdf",
                        anno: this.anno_selezionato,
                        mese: this.mese_selezionato,
                        id_personale: this.utente_selezionato,
                        token: localStorage.getItem("user_token"),
                        },
                    };
                    this.file_b64 = '';
                    this.$store.dispatch("api", disegnoReq).then((res) => {
                        this.file_b64 = res.data
                        this.overlay = false;
                        resolve(res);
                        return;
                    })
                    .catch((e) => {
                        this.overlay = false;
                        reject(e);
                        console.log(e);
                    });
                });
            },  

            async stampa_cartellino_sede(){
                if (this.Sede_id > 0){
                    await this.getStampaCartellino_sede();
                    const byteCharacters = atob(this.file_b64);
                    const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: "application/pdf" });
                    const blobUrl = URL.createObjectURL(blob);
                    this.selectedPdf = blobUrl;
                    this.pdfdialog_sedi = true;
                }   
            },
            getStampaCartellino_sede() {
                return new Promise((resolve, reject) => {
                    this.overlay = true;
                    let disegnoReq = {
                        controller: "Presenze",
                        method: "POST",
                        richiesta: {
                        action: "getcartellinopdf",
                        anno: this.anno_selezionato_stampe,
                        mese: this.mese_selezionato_stampe,
                        sedi: this.Sede_id,
                        token: localStorage.getItem("user_token"),
                        },
                    };
                    this.file_b64 = '';
                    this.$store.dispatch("api", disegnoReq).then((res) => {
                        this.file_b64 = res.data
                        this.overlay = false
                        resolve(res);
                        return;
                    })
                    .catch((e) => {
                        this.overlay = false;
                        reject(e);
                        console.log(e);
                    });
                });
            },  

            mail_dipendenti_sede(){
               // console.log("array",this.personale_tot, this.Azienda_id, this.Sede_id),
                this.dialog_mail_dipendenti=true;
                this.personale_sede = this.alasql("SELECT * FROM ? where  id_azienda = 1 and  id_sede  = " + this.Sede_id ,
                  [this.personale_tot]
              );

            },
            invia_mail(){
              this.dipendenti_selezionati;
                    let request = {
                        controller: "Presenze",
                        method: "POST",
                        richiesta: {
                            action: "inviamaildipendenti",
                            mese: this.mese_selezionato,
                            anno: this.anno_selezionato,
                            dipendenti: this.dipendenti_selezionati,
                            token: localStorage.getItem("user_token"),
                        },
                    }
                    this.$store.dispatch("api", request).then((res) => { 
                      console.log(res)    
                      this.dialog_mail_dipendenti= false

  

                });              

            },

            Download_Cartellino() {
                this.overlay = true;
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                    action: "getcartellinoexport",
                    anno: this.anno_selezionato_stampe,
                    mese: this.mese_selezionato_stampe,
                    sedi: this.Sede_id,
                    token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {
                       
                        // console.log(response.headers);
                        this.overlay = false
                        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'cartellino.txt');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        
                        })
                        .catch(e => {
                            this.overlay = false
                            console.log(e);
                        });
                    
            },
            Download_LUL() {
               // console.log(this.mese_selezionato)
               this.overlay = true;
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                    action: "getcartellinolul",
                    anno: this.anno_selezionato_stampe,
                    mese: this.mese_selezionato_stampe,
                    sedi: this.Sede_id,
                    token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {
                       
                        // console.log(response.headers);
                        this.overlay = false
                        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'lul.txt');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        
                        })
                        .catch(e => {
                            this.overlay = false
                            console.log(e);
                        });
                    
            },
            
            async stampa_cartellino_azienda(){
                await this.getStampaCartellino_azienda();
                const byteCharacters = atob(this.file_b64);
                const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "application/pdf" });
                const blobUrl = URL.createObjectURL(blob);
                this.selectedPdf = blobUrl;
                this.pdfdialog_azienda = true;   
            },
            getStampaCartellino_azienda() {
                return new Promise((resolve, reject) => {
                    this.overlay = true;
                    let disegnoReq = {
                        controller: "Presenze",
                        method: "POST",
                        richiesta: {
                        action: "getcartellinopdf",
                        anno: this.anno_selezionato_stampe,
                        mese: this.mese_selezionato_stampe,
                        azienda: this.Azienda_id,
                        token: localStorage.getItem("user_token"),
                        },
                    };
                    this.file_b64 = '';
                    this.$store.dispatch("api", disegnoReq).then((res) => {
                        this.file_b64 = res.data
                        this.overlay = false;
                        resolve(res);
                        return;
                    })
                    .catch((e) => {
                        this.overlay = false;
                        reject(e);
                        console.log(e);
                    });
                });
            }, 
            Download_Cartellino_Azienda() {
                this.overlay = true;
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                    action: "getcartellinoexport",
                    anno: this.anno_selezionato_stampe,
                    mese: this.mese_selezionato_stampe,
                    azienda: this.Azienda_id,
                    token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {
                       
                        // console.log(response.headers);
                        this.overlay = false
                        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'cartellino_azienda.txt');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        
                        })
                        .catch(e => {
                            this.overlay = false
                            console.log(e);
                        });
                    
            },
            Download_LUL_Azienda() {
               // console.log(this.mese_selezionato)
                this.overlay = true;
                let request = {
                    controller: "Presenze",
                    method: "POST",
                    richiesta: {
                    action: "getcartellinolul",
                    anno: this.anno_selezionato_stampe,
                    mese: this.mese_selezionato_stampe,
                    azienda: this.Azienda_id,
                    token: localStorage.getItem("user_token"),
                    },
                }
                this.$store.dispatch("api", request).then((res) => {
                       
                        // console.log(response.headers);
                        this.overlay = false
                        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'lul_azienda.txt');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        
                        })
                        .catch(e => {
                            this.overlay = false
                            console.log(e);
                        });
                    
            },            
            async stampa_bollato_azienda(){

                let anno_ultimo_bollato = this.Data_ultimo_bollato.substr(0, 4)
                let mese_ultimo_bollato = this.Data_ultimo_bollato.substr(5, 2)
                
                if (this.anno_selezionato_stampe <= anno_ultimo_bollato){
                    if (this.mese_selezionato_stampe <= mese_ultimo_bollato){
                        await this.getBollato_azienda();
                        this.overlay = true;
                        const byteCharacters = atob(this.file_b64);
                        const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "application/pdf" });
                        const blobUrl = URL.createObjectURL(blob);
                        this.selectedPdf = blobUrl;
                        this.overlay = false;
                        this.pdfdialog_bollato = true;   
                    }else{
                    this.snackbar_text = "Bollato per il mese selezionato non presente. ";
                    this.snackbar_color = "error";
                    this.snackbar = true;
                    }
                }else{
                    this.snackbar_text = "Anno selezionato non valido. Occorre generare il Bollato";
                    this.snackbar_color = "error";
                    this.snackbar = true;
                }

            },
            getBollato_azienda() {
                return new Promise((resolve, reject) => {
                    this.overlay = true;
                    let param = {
                        controller: "Presenze",
                        method: "POST",
                        richiesta: {
                        action: "getbollatopdf",
                        anno: this.anno_selezionato_stampe,
                        mese: this.mese_selezionato_stampe,
                        azienda: this.Azienda_id,
                        token: localStorage.getItem("user_token"),
                        },
                    };
                    this.file_b64 = '';
                    this.$store.dispatch("api", param).then((res) => {
                        this.file_b64 = res.data
                        this.overlay = false;
                        resolve(res);
                        return;
                    })
                    .catch((e) => {
                        this.overlay = false;
                        reject(e);
                        console.log(e);
                    });
                });
            }, 
            genera_bollato_azienda(){
                let anno_ultimo_bollato = this.Data_ultimo_bollato.substr(0, 4)
                let mese_ultimo_bollato = this.Data_ultimo_bollato.substr(5, 2)
                if (this.anno_selezionato_stampe >= anno_ultimo_bollato){
                    if (this.mese_selezionato_stampe > (mese_ultimo_bollato + 1)){
                        let request = {
                            controller: "Presenze",
                            method: "PUT",
                            richiesta: {
                                action: "setbollato",
                                mese: this.mese_selezionato_stampe,
                                anno: this.anno_selezionato_stampe,
                                token: localStorage.getItem("user_token"),
                            },
                        }
                        this.$store.dispatch("api", request).then((res) => {                    
                        console.log(res)
                        })
                    }else{
                    this.snackbar_text = "Bollato già generato";
                    this.snackbar_color = "error";
                    this.snackbar = true;
                    }
                }else{
                    this.snackbar_text = "Bollato già generato";
                    this.snackbar_color = "error";
                    this.snackbar = true;
                }
            },

            chiudi_finestra(){
              //localStorage.removeItem("menu"),  
              this.$router.push("/home/dashboard");
            },
            logout() {
              localStorage.removeItem("user_token");
              localStorage.removeItem("id");
              localStorage.removeItem("nominativo");
              localStorage.removeItem("des_profilo");
              localStorage.removeItem("id_profilo");
              localStorage.removeItem("des_azienda");
              localStorage.removeItem("id_azienda");
              this.$router.replace("/login");
            },
            apriDashboard() {
              this.$router.push("/home/dashboard").catch(() => {});
              localStorage.setItem("menu", []);
              this.$store.state.menu_sx = [];
            },                 

        },
    };
</script>