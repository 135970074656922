const config = {
  // db: 'medical',
  //"ip": "https://localhost:5001/"
  //"ip": "http://192.168.0.240:90/" Cavallo Prod Qerp
  //"ip": "http://192.168.0.240:94/" Cavallo Prod 94 Presenze
  // ip: "http://192.168.10.90/Geclink_api/",
  // ip:"http://giri.bivi.eu:90/",
  // ip: "https://api.quickviewsafety.com/",
  // ip:"https://api.quickviewsafety.com:443/",
  // ip: 'https://api.presenzeweb.com/',
  //"ip": "https://apitest.quickview.biz/",
  // "ip": "https://localhost:5001/",
  // ip: "https://api.quickviewsafety.com/"
  // ip: "http://apitest.quickview.biz:91/",
  // ip: "https://api2.quickview.biz/",
  // ip: "http://192.168.10.90/QuickViewAPI/",
  // ip: "http://test.quickview.biz:91/",
  // ip: "http://192.168.10.200:91/",
  // ip: "https://47.53.12.100:5001/",
  // ip: "http://188.219.23.166:90/"
  // ip: "http://192.168.10.90:5001/",
  // ip: "https://47.53.12.100:5001/"
};

export default config;
