var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-data-table',{key:_vm.contatore_tipologie,staticClass:"elevation-1 mx-5 mt-5 pt-0",attrs:{"fixed-header":"","headers":_vm.headers_tipologie,"items":_vm.items_tipologie.filter(function (elem) { return elem.validita === 1; }),"single-expand":true,"expanded":_vm.expanded_tipologie,"height":"70vh","item-key":"id_anagrafica_tipologia","show-expand":""},on:{"update:expanded":function($event){_vm.expanded_tipologie=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers_fascie_orarie.filter(
                  function (elem) { return elem.text != 'Modifica' && elem.text != 'Elimina'; }
                ),"hide-default-footer":"","items":item.fascie_orarie},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Fasce Orarie ")])],1)]},proxy:true}],null,true)})],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Dettagli Tipologie ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickAggiungiTipologia}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Tipologia")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"disabled":_vm.anagrafica_sel.id_anagrafica == -1 ||
                    _vm.items_tipologie.length == 0
                      ? true
                      : false,"small":""},on:{"click":function($event){return _vm.salvaTipologie()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-floppy")])],1)]}}])},[_c('span',[_vm._v("Salva tipologie")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaTipologia(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])])]}},{key:"item.specializzazioni",fn:function(ref){
                      var item = ref.item;
return _vm._l((item.specializzazioni),function(elem,index){return _c('span',{key:index,staticClass:"mr-2"},[_vm._v(_vm._s(elem.des_ambito_manutenzione)+";")])})}},{key:"item.elimina",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.disabilita_eliminazione,"small":""},on:{"click":function($event){return _vm.clickEliminaOperatore(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.aggiungi_tipologia_dialog),callback:function ($$v) {_vm.aggiungi_tipologia_dialog=$$v},expression:"aggiungi_tipologia_dialog"}},[_c('v-snackbar',{style:({}),attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar_dialog),callback:function ($$v) {_vm.snackbar_dialog=$$v},expression:"snackbar_dialog"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar_dialog = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1),_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Dettagli Tipologia")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaModificheTipologia}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva Tipologia")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Descrizione"},model:{value:(_vm.tipologia_sel.nome_tipologia),callback:function ($$v) {_vm.$set(_vm.tipologia_sel, "nome_tipologia", $$v)},expression:"tipologia_sel.nome_tipologia"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"label":"Livello","type":"number"},model:{value:(_vm.tipologia_sel.livello),callback:function ($$v) {_vm.$set(_vm.tipologia_sel, "livello", $$v)},expression:"tipologia_sel.livello"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{key:_vm.contatore_render_tipologia_lista,attrs:{"items":_vm.tipo_intervento_lista,"item-text":"des_ambito_manutenzione","return-object":"","menu-props":{ maxHeight: '400' },"label":"Specializzazioni","placeholder":"Associa","multiple":""},model:{value:(_vm.tipologia_sel.specializzazioni),callback:function ($$v) {_vm.$set(_vm.tipologia_sel, "specializzazioni", $$v)},expression:"tipologia_sel.specializzazioni"}})],1)],1),_c('v-row',[_c('v-container',[_c('v-data-table',{key:_vm.contatore_fascie,attrs:{"fixed-header":"","headers":_vm.headers_fascie_orarie,"height":"40vh","items":_vm.tipologia_sel.fascie_orarie.filter(
                    function (elem) { return elem.validita != 0; }
                  )},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Fasce Orarie ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickAggiungiFasciaOraria()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Fascia Oraria")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.modifica",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaFasciaOraria(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])])]}},{key:"item.specializzazioni",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.specializzazioni),function(elem,index){return _c('span',{key:index,staticClass:"mr-2"},[_vm._v(_vm._s(elem.des_ambito_manutenzione)+";")])})}},{key:"item.elimina",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.disabilita_eliminazione,"small":""},on:{"click":function($event){return _vm.clickEliminaFasciaOraria(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_tipologia_dialog),callback:function ($$v) {_vm.conferma_elimina_tipologia_dialog=$$v},expression:"conferma_elimina_tipologia_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Eliminazione")]),_c('v-spacer')],1),_c('v-card-text',{staticStyle:{"margin-top":"20px","overflow-y":"auto"}},[_vm._v(" Sei sicuro di voler eliminare questa tipologia? "),_c('v-divider',{staticClass:"my-5"}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.clickConfermaEliminaOperatore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickAnnullaEliminaOperatore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_chiudi_tipologia_dettaglio_dialog),callback:function ($$v) {_vm.conferma_chiudi_tipologia_dettaglio_dialog=$$v},expression:"conferma_chiudi_tipologia_dettaglio_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Chiusura")]),_c('v-spacer')],1),_c('v-card-text',{staticStyle:{"margin-top":"20px","overflow-y":"auto"}},[_vm._v(" Sei sicuro di voler chiudere questa finestra senza salvare i dati? "),_c('v-divider',{staticClass:"my-5"}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.clickConfermaChiudiDettaglioOperatore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickAnnullaChiudiDettaglioOperatore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog_aggiungi_fascia_oraria),callback:function ($$v) {_vm.dialog_aggiungi_fascia_oraria=$$v},expression:"dialog_aggiungi_fascia_oraria"}},[_c('v-snackbar',{style:({}),attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar_dialog_fasce),callback:function ($$v) {_vm.snackbar_dialog_fasce=$$v},expression:"snackbar_dialog_fasce"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar_dialog_fascie = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1),_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[(!_vm.modifica_fascia)?_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Aggiungi Fascia oraria")]):_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Modifica Fascia oraria")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.salvaModificheFasciaOraria()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1)]}}])},[_c('span',[_vm._v("Salva")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.dialog_aggiungi_fascia_oraria = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticStyle:{"margin-top":"20px","overflow-y":"auto"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.tipo_giorno_lista,"label":"Tipo giorno","placeholder":"Seleziona","item-text":"des","item-value":"id_tabella","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-2"},[_vm._v("Tipo giorno")]),_c('span',{staticStyle:{"min-width":"500px","pointer-events":"all"},on:{"click":function($event){$event.stopPropagation();}}},[_c('Modules',{attrs:{"tipo":"MAN_TIPO_GIORNO","nome":"Tipo giorno","setaction":"settabella","getaction":"gettabella","controller":"Global","controllaCodice":true,"filter":'validita = 1',"all_label":['des'],"dati":[
                          { des: '' } ]},on:{"dialogModules":_vm.gettabelleFasce}})],1)]},proxy:true}]),model:{value:(_vm.fascia_oraria_sel.id_tipo_giorno),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "id_tipo_giorno", $$v)},expression:"fascia_oraria_sel.id_tipo_giorno"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu_ora_inizio",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fascia_oraria_sel.fascia_oraria_inizio,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_inizio", $event)},"update:return-value":function($event){return _vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_inizio", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fascia oraria inizio","prepend-inner-icon":"access_time","readonly":""},model:{value:(_vm.fascia_oraria_sel.fascia_oraria_inizio),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_inizio", $$v)},expression:"fascia_oraria_sel.fascia_oraria_inizio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_ora_inizio),callback:function ($$v) {_vm.menu_ora_inizio=$$v},expression:"menu_ora_inizio"}},[(_vm.menu_ora_inizio)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu_ora_inizio.save(
                        _vm.fascia_oraria_sel.fascia_oraria_inizio
                      )}},model:{value:(_vm.fascia_oraria_sel.fascia_oraria_inizio),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_inizio", $$v)},expression:"fascia_oraria_sel.fascia_oraria_inizio"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu_ora_fine",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fascia_oraria_sel.fascia_oraria_fine,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_fine", $event)},"update:return-value":function($event){return _vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_fine", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fascia oraria fine","prepend-inner-icon":"access_time","readonly":""},model:{value:(_vm.fascia_oraria_sel.fascia_oraria_fine),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_fine", $$v)},expression:"fascia_oraria_sel.fascia_oraria_fine"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_ora_fine),callback:function ($$v) {_vm.menu_ora_fine=$$v},expression:"menu_ora_fine"}},[(_vm.menu_ora_fine)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu_ora_fine.save(
                        _vm.fascia_oraria_sel.fascia_oraria_fine
                      )}},model:{value:(_vm.fascia_oraria_sel.fascia_oraria_fine),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "fascia_oraria_fine", $$v)},expression:"fascia_oraria_sel.fascia_oraria_fine"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.maggiorazione_lista,"label":"Tipo maggiorazione","placeholder":"Seleziona","item-text":"des","item-value":"id_tabella","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-2"},[_vm._v("Tipo maggiorazione")]),_c('span',{staticStyle:{"min-width":"500px","pointer-events":"all"},on:{"click":function($event){$event.stopPropagation();}}},[_c('Modules',{attrs:{"tipo":"MAN_MAGGIORAZIONE","nome":"Tipo maggiorazione","setaction":"settabella","getaction":"gettabella","controller":"Global","controllaCodice":true,"filter":'validita = 1',"all_label":['des'],"dati":[
                          { des: '' } ]},on:{"dialogModules":_vm.gettabelleFasce}})],1)]},proxy:true}]),model:{value:(_vm.fascia_oraria_sel.id_tipo_maggiorazione),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "id_tipo_maggiorazione", $$v)},expression:"fascia_oraria_sel.id_tipo_maggiorazione"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.giorno_lista,"label":"Giorno","placeholder":"Seleziona","item-text":"des","item-value":"id_tabella","required":"","multiple":"","return-object":""},model:{value:(_vm.fascia_oraria_sel.giorni),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "giorni", $$v)},expression:"fascia_oraria_sel.giorni"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Costo (€)","type":"number","min":0},model:{value:(_vm.fascia_oraria_sel.costo),callback:function ($$v) {_vm.$set(_vm.fascia_oraria_sel, "costo", $$v)},expression:"fascia_oraria_sel.costo"}})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_elimina_fascia_dialog),callback:function ($$v) {_vm.conferma_elimina_fascia_dialog=$$v},expression:"conferma_elimina_fascia_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Eliminazione")]),_c('v-spacer')],1),_c('v-card-text',{staticStyle:{"margin-top":"20px","overflow-y":"auto"}},[_vm._v(" Sei sicuro di voler eliminare questa fascia oraria? "),_c('v-divider',{staticClass:"my-5"}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.clickConfermaEliminaFascia()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.conferma_elimina_fascia_dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])]),_c('v-spacer')],1)],1)],1),_c('v-snackbar',{style:({}),attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({ color: _vm.snackbar_text_color + '!important' })},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }