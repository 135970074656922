<template>
    <div class="dashboardOverview" :data-nonadmin="(DS.isAdmin()==true)?false:true">
        <!--    Corpo principale dove sono ospitate le card delle dashboard     -->
        <main v-if="DS.error.occurred == false">
            <!--    Modello di una singola card     -->
            <div    v-for="(_ds,i) in DASHBOARDS" :key="_ds.id" 
                    :variante="_ds.variante" 
                    :id="_ds.id" 
                    :style="'background:'+_ds.bg+';background-size:cover;background-position:center;'">
                        <header>
                            <h3>{{_ds.title}}</h3>
                            <GraphSVG @click="DS.showDialog(i,DS,_ds)" name="pencil" :use_style="true"/>
                            <GraphSVG @click="$router.push(MODES[MODE].getURI(_ds.id))" name="arrow_right" :use_style="true"/>
                        </header>
                        <p>{{_ds.desc}}</p>
            </div>
            <!--    Pulsante Aggiungi (+)    -->
            <nav @click="DS.showDialog(-1,DS,null)" >
                <GraphSVG name="plus" data-add="alert_add"  :use_style="true"/>
            </nav>            
        </main>
        <v-dialog v-model="DS.dialog">
            <section class="dsow_dialog">
                <header>
                    <span>{{(DS.dialogElement == -1) ? 'Crea' : 'Modifica'}} Dashboard</span>
                    <GraphSVG @click="DS.dialog=false"  name="close" :use_style="true"/>
                </header>
                <div>
                    <input id="dsovw_name_input" placeholder="Nome" type="text" :value="(DS.dialogElement >= 0 && DS.dialogElement!=null && DASHBOARDS[DS.dialogElement] !== undefined) ? DASHBOARDS[DS.dialogElement].title : ''">
                    <input id="dsovw_desc_input" placeholder="Descrizione" type="text" :value="(DS.dialogElement >= 0 && DS.dialogElement!=null && DASHBOARDS[DS.dialogElement] !== undefined) ? DASHBOARDS[DS.dialogElement].desc : ''">
                   <!-- select -->
                   <div style="width: 100%; display: flex">
                    <v-select style="margin: 18px;box-sizing: border-box;"
                        v-model="DS.model_profili"
                        :items="DS.profili"
                        :item-text="'nome'"
                        :item-value="'id'"
                        label="id profilo"
                        multiple
                        required
                        ></v-select>
                    <v-select style="margin: 18px;box-sizing: border-box;"
                        v-model="DS.model_utenti"
                        :items="DS.utenti"
                        item-text="nome"
                        item-value="id"
                        multiple
                        label="id utente"
                        required
                        ></v-select>
                   </div>
                   <!-- end select -->
                    <article>
                        <input type="radio" id="bckg_sel_1" name="bckg_sel">
                        <section>                            
                            <label for="bckg_sel_1">
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M22.11 21.46L2.39 1.73L1.11 3L3 4.9V19C3 20.11 3.9 21 5 21H19.1L20.84 22.73L22.11 21.46M5 19V6.89L17.11 19H5M8.2 5L6.2 3H19C20.1 3 21 3.89 21 5V17.8L19 15.8V5H8.2Z" /></svg>
                                <span>Nessuno sfondo</span>
                            </label>
                        </section>
                        <input type="radio" id="bckg_sel_2" name="bckg_sel">
                        <section>                            
                            <label for="bckg_sel_2">
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M17.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,9A1.5,1.5 0 0,1 19,10.5A1.5,1.5 0 0,1 17.5,12M14.5,8A1.5,1.5 0 0,1 13,6.5A1.5,1.5 0 0,1 14.5,5A1.5,1.5 0 0,1 16,6.5A1.5,1.5 0 0,1 14.5,8M9.5,8A1.5,1.5 0 0,1 8,6.5A1.5,1.5 0 0,1 9.5,5A1.5,1.5 0 0,1 11,6.5A1.5,1.5 0 0,1 9.5,8M6.5,12A1.5,1.5 0 0,1 5,10.5A1.5,1.5 0 0,1 6.5,9A1.5,1.5 0 0,1 8,10.5A1.5,1.5 0 0,1 6.5,12M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A1.5,1.5 0 0,0 13.5,19.5C13.5,19.11 13.35,18.76 13.11,18.5C12.88,18.23 12.73,17.88 12.73,17.5A1.5,1.5 0 0,1 14.23,16H16A5,5 0 0,0 21,11C21,6.58 16.97,3 12,3Z" /></svg>
                                <span>Colore solido</span>
                            </label>
                            <div>                                
                                <label><input id="dsovw_color_input" type="color"></label>
                            </div>
                        </section>
                        <input type="radio" id="bckg_sel_3" name="bckg_sel">
                        <section>                            
                            <label for="bckg_sel_3">
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" /></svg>
                                <span>Immagine</span>
                            </label>
                            <div>
                                <label>
                                    <svg width="24" height="24" viewBox="0 0 24 24"><path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
                                    <span>Carica Immagine</span>
                                    <input type="file" accept="image/*" @change="DS.process_image($event, DS)">
                                </label>
                                <img id="dsovw_img_input" src="" data-fullres="" data-variant="">
                            </div>                            
                        </section>
                    </article> 
                </div>
                <footer>
                    <span @click="DS.dialog=false">ANNULLA</span>
                    <span @click="set_dashboards(0)">ELIMINA</span>
                    <span @click="set_dashboards(1)">OK</span>
                </footer>
            </section>
        </v-dialog>
    </div>
</template>
<script>
import DASHBOARD_OVERVIEW from "./DashboardOverview.js";
import GraphSVG from "@/components/GRAPH/Generale/Componenti/SVG/SVG.vue";
export default {
  name: "DASHBOARDOVERVIEW",
  props: {
      MODE : Number,
  },
  components : {
      GraphSVG
  },
  data() {
    return {
        DS : new DASHBOARD_OVERVIEW(),
        NETWORK : null,
        DASHBOARDS : [],
        MODES : [ 
            {
                controllers : ["Graph","Graph"],
                actions : ["getDashboard","setDashboard"],
                methods : ["POST","PUT"],
                getURI : function(id){return "/home/graph/dashboard/"+id;},
            },
            {
                controllers : ["Import","Import"],
                actions : ["getDashboard","setDashboard"],
                methods : ["POST","PUT"],
                getURI : function(id){return "/home/import/"+id;}
            },
        ],
    };
  },
  mounted(){
    this.NETWORK = this.$store.state.Network;    
    //this.DS.loading = true;    
    

    let get_utenti = {
        controller : 'User',
        body : {
            action: "getUtenti",
            token: localStorage.user_token
        },
        method: 'POST',
        on : {
            load : this.getUtenti,
            error : this.die
        }
    }

    this.NETWORK.AjaxRequest(get_utenti);

    let get_profili = {
        controller : 'User',
        body : {
            action: "getProfili",
            token: localStorage.user_token
        },
        method: 'POST',
        on : {
            load : this.getProfili,
            error : this.die
        }
    }   
    
    this.NETWORK.AjaxRequest(get_profili);
    this.get_dashboards();
    

  },
  methods : {
      getUtenti(response){ 
          response = response.utenti;
          this.DS.utenti = [];
          for(let i=0;i<response.length;i++){
            this.DS.utenti.push({
                id : response[i].id_personale.toString(),
                nome : response[i].cognome+' '+response[i].nome
            });
          }
          this.id_utente_ds = response;
      },
      getProfili(response){  
          response = response.profili;
          this.DS.profili = [];
          for(let i=0;i<response.length;i++){
            this.DS.profili.push({
                id : response[i].id_profilo_utente.toString(),
                nome : response[i].cod_profilo_utente
            });
          }
      },
      die(){
        this.DS.loading = false;
        this.DS.dialog = false;
        this.DS.error.occurred = true;
      },
      get_dashboards() {
        let mode = this.MODE;
        if(mode < 0 || mode >= this.MODES.length){return;}   

        let request = {
            controller : this.MODES[mode].controllers[0],
            body : {
                action: this.MODES[mode].actions[0],
                prefisso_utente: localStorage.prefisso_utente,
                id_azienda: localStorage.id_azienda,
                token: localStorage.user_token,
            },
            method: this.MODES[mode].methods[0],
            on : {
                load : this.show_dashboards,
                error : this.die
            },
        }
        this.NETWORK.AjaxRequest(request);
    },
    show_dashboards(result){
        let that = this;
        if(this.MODE == 0){ result = result.dashboards; }       
        this.DASHBOARDS = [];

        for(let i=0;i<result.length;i++){
            let bg = result[i].thumb;
            if(bg == null || bg == undefined){bg = '#ffffff';}
            if(bg.substring(0,3) == 'rgb'){
                bg = bg.substring(4,bg.length-1);
                let bgs = bg.split(',');
                bg = '#'+('0'+Number(bgs[0]).toString(16)).slice(-2);
                bg+= ('0'+Number(bgs[1]).toString(16)).slice(-2);
                bg+= ('0'+Number(bgs[2]).toString(16)).slice(-2);
            }
            let item = {
                variante : result[i].variante,
                id : result[i].id_ds,
                title : result[i].nome,
                desc : result[i].descrizione,
                val : result[i].validita,
                theme : result[i].tema,
                bg : bg,
                id_profilo: (result[i].id_profilo!=null&&result[i].id_profilo!=undefined)?result[i].id_profilo.toString().split(','):[],
                id_utente: (result[i].id_utente_ds!=null&&result[i].id_utente_ds!=undefined)?result[i].id_utente_ds.toString().split(','):[]
            };
            this.DASHBOARDS.push(item);
        }        
        setTimeout(function(){that.DS.loading=false;},1000);
    },
    set_dashboards(validita = 1){

        let mode = this.MODE;
        if(mode < 0 || mode >= this.MODES.length){return;} 
        let bgRadios = document.getElementsByName('bckg_sel');
        let selected = null;
        let sfondo, thumb, variante, aux, id, nome;

        if(this.DS.dialogElement >= 0){id = this.DASHBOARDS[this.DS.dialogElement].id;}else{id=-1;}
        if(validita == 0 && id == -1){return;}

        nome = document.getElementById('dsovw_name_input').value;
        if(nome == '' || nome == null || nome == undefined){nome = "Nuova Dashboard";}

        for(let i=0;i<bgRadios.length;i++){if(bgRadios[i].checked == true){selected=i;}}
        switch(selected){
            case 1:{
                    aux = document.getElementById('dsovw_color_input').value;
                    let r = parseInt(aux.substring(1, 3), 16);
                    let g = parseInt(aux.substring(3, 5), 16);
                    let b = parseInt(aux.substring(5, 7), 16);
                    let brightness = Math.floor((r + g + b) / 3);
                    sfondo = aux;
                    thumb = aux;
                    variante = (brightness > 150) ? 's' : 'c';
                }
                break;
            case 2:
                aux = document.getElementById('dsovw_img_input');
                thumb = 'url('+aux.src+')';
                sfondo = 'url('+aux.getAttribute('data-fullres')+')';
                variante = aux.getAttribute('data-variant');

                if(thumb == null || thumb == 'url()' || thumb == undefined){
                        sfondo = '#ffffff';
                        thumb = sfondo;
                        variante = 's';
                } else if(sfondo == null || sfondo == 'url()' || sfondo == undefined){
                        sfondo = 'keep';
                }           
                break;
            default:
                sfondo = '#ffffff';
                thumb = sfondo;
                variante = 's';
                break;
        }       
        let request = {
        action: this.MODES[mode].actions[1],
        id_utente: localStorage.id,
        token: localStorage.user_token,
        prefisso_utente: localStorage.prefisso_utente,
        tabella: [
          {
            id_utente_ds : this.DS.model_utenti.toString(),
            id_profilo: this.DS.model_profili.toString(),
            id_ds: id,
            nome: nome,
            descrizione: document.getElementById('dsovw_desc_input').value,
            sfondo: sfondo,
            thumb: thumb,
            tipo:(this.MODE>0)?this.MODE:null,
            variante: variante,
            id_azienda: localStorage.id_azienda,
            validita: validita,
          },
        ],
      };

      this.DS.cached = request;

      let set_request = {
            controller : this.MODES[mode].controllers[1],
            body : request,
            method: this.MODES[mode].methods[1],
            on : {
                load : this.update_dashboards,
                error : this.die
            }
        }
      this.NETWORK.AjaxRequest(set_request);
    },
    update_dashboards(response){
        let id = Number(response);
        if(!isNaN(id)){
            let data = this.DS.cached.tabella[0];
            let elementNum = this.DS.dialogElement;
            if(data.validita == 0){
                this.DASHBOARDS.splice(elementNum,1);
                this.DS.dialog = false;
                return;
            } 
         
            let element;
            if(elementNum >=0){
                element = this.DASHBOARDS[elementNum];
                element.variante = data.variante;
                element.title = data.nome;
                element.desc = data.descrizione;
                element.bg = data.thumb;
                element.id_profilo = this.DS.model_profili;
                element.id_utente = this.DS.model_utenti;
            }else{
                let item = {
                    variante : data.variante,
                    id :id,
                    title : data.nome,
                    desc : data.descrizione,
                    val : 1,
                    theme : null,
                    bg : data.thumb,
                    id_utente : this.DS.model_utenti,
                    id_profilo : this.DS.model_profili,

                };
                this.DASHBOARDS.push(item);
            }
            this.DS.dialog = false;            
        }else{
           alert("Si è verificato un errore"); 
        }
    }
  },
}

</script>
<style src="./DashboardOverview.css" scoped></style>