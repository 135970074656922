<template>
  <v-card>
    <!-- MAIN TOOLBAR -->
    <v-toolbar dense class="indigo darken-1 headline" primary-title>
      <v-toolbar-title class="white--text">Part Program Sender</v-toolbar-title>

      <v-spacer></v-spacer>
      <Button
        colore_icona="success"
        mdi_icona="mdi-floppy"
        testo_tooltip="Send Part Program"
        v-on:buttonClick="sendPartProgram"
      />
      <v-spacer></v-spacer>
      <Button
        colore_icona="error"
        mdi_icona="mdi-window-close"
        testo_tooltip="Cancellare"
        v-on:buttonClick="$emit('change')"
      />
    </v-toolbar>
    <!-- <v-row class="mt-1">
      <v-col class="ml-1" md="4" sm="4" lg="4">
        <v-select
          @change="selectPP"
          outlined
          v-if="tipo == 'macchina'"
          v-model="program_id"
          :items="initialJson"
          item-text="program_name"
          item-value="program_id"
          label="Part Programs"
        >
        </v-select>
      </v-col>
    </v-row> -->

    <v-card-text>
      <v-data-table :headers="PPHeader" :items="PPItems">
        <template v-slot:[`item.default_value`]="{ item }">
          <DynamicInputVue
            style="max-width: 270px"
            v-model="item.default_value"
            :type="getInputType(item.type)"
          />
        </template>

        <template v-slot:[`item.boundaries`]="{ item }">
          <span v-show="item.type == 2 || item.type < 13"
            >Min:{{ item.boundaries.hard_min_value }}</span
          >
          <span v-show="item.type == 2 || item.type < 13" class="ml-2"
            >Max:{{ item.boundaries.hard_max_value }}</span
          >
        </template>
        <template v-slot:[`item.min`]="{ item }">
          <v-text-field
            v-if="item.type == 2 || item.type < 13"
            v-model="item.boundaries.min_value"
            label="Min"
            placeholder="Min"
            type="number"
          ></v-text-field>
        </template>
        <template v-slot:[`item.max`]="{ item }">
          <v-text-field
            v-if="item.type == 2 || item.type < 13"
            v-model="item.boundaries.max_value"
            label="Max"
            placeholder="Max"
            type="number"
          ></v-text-field>
        </template>

        <template v-slot:[`item.access_type`]="{ item }">
          <v-select
            v-model="item.access_type"
            :items="access_lista"
            item-text="name"
            item-value="id"
          >
          </v-select>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog persistent width="400" v-model="alert.mostra">
      <v-card>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="1" align="center">
              <v-icon large>{{ alert.icona }}</v-icon>
            </v-col>
            <v-col cols="11">
              <h3 style="word-break: keep-all; text-align: left; padding: 0 20px">
                {{ alert.messaggio }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" @click="alert.mostra = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Button from "../../TOOLS/FormComponentPermissions/Button.vue";
import DynamicInputVue from "./DynamicInput.vue";
export default {
  props: ["tipo", "data"],
  components: {
    Button,
    DynamicInputVue,
  },
  data() {
    return {
      alert: {
        icona: null,
        messaggio: null,
        mostra: false,
      },
      partprogramelemto: false,
      singleProgram: null,
      program_id: null,
      PPHeader: [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },

        {
          text: "Default Value",
          value: "default_value",
          sortable: false,
        },
        {
          text: "Min",
          value: "min",
          sortable: false,
        },
        {
          text: "Max",
          value: "max",
          sortable: false,
        },

        {
          text: "Boundaries",
          value: "boundaries",
          sortable: false,
        },
        {
          text: "Access Type",
          value: "access_type",
          sortable: false,
        },
      ],
      access_lista: [
        {
          id: 0,
          name: "hidden Content",
        },
        {
          id: 1,
          name: "Read Content",
        },
        {
          id: 2,
          name: "Write Content",
        },
      ],
      PPItems: [],
      initialJson: [this.data],
    };
  },
  mounted() {
    // console.log(this.data);
    // if (this.tipo == "articolo") {
    //    this.singleProgram = this.initialJson[0];
    //   this.PPItems = this.initialJson[0].steps;
    // }
  },
  methods: {
    // selectPP() {
    //   let i = this.initialJson.findIndex(
    //     (it) => it.program_id == this.program_id
    //   );
    //   this.singleProgram = this.initialJson[0];
    //   this.PPItems = this.initialJson[i].steps;
    // },
    UpdateDialog(single_part_program, associatePartPrgoram) {
      let clonedPartProgram;

      try {
        clonedPartProgram = JSON.parse(JSON.stringify(single_part_program));
        this.singleProgram = clonedPartProgram;
        this.PPItems = this.singleProgram.steps;
      } catch (error) {
        try {
          let temp;
          temp = JSON.parse(
            decodeURIComponent(escape(atob(associatePartPrgoram.dettaglio_part_program)))
          );
          if (single_part_program.id == temp.id) {
            this.singleProgram = temp;
            this.PPItems = this.singleProgram.steps;
          }
        } catch (error) {
          console.log(error);
        }
      }
      console.log("single_part_program--->", single_part_program);
    },

    getInputType(type) {
      if (type == 0) {
        return "boolean";
      }
      if (type >= 1 && type <= 11) {
        return "number";
      }
      if (type == 12) {
        return "string";
      }
      if (type == 13) {
        return "file";
      }
      return null;
    },
    getNum(item) {
      if (item === null || item === undefined) {
        return NaN;
      }
      const num = Number(item);
      if (isNaN(num)) {
        return NaN;
      }
      return num;
    },
    sendPartProgram() {
      if (this.getNum(this.singleProgram.id) < 0) {
        Object.assign(this.alert, {
          icona: "mdi-alert-box",
          messaggio: `Program Id Must be Included`,
          mostra: true,
        });
        return null;
      } else {
        for (let index = 0; index < this.singleProgram.steps.length; index++) {
          const element = this.singleProgram.steps[index];
          console.log(`element.default_value`, typeof this.getNum(element.default_value));
          if (
            element.default_value === "" ||
            element.default_value === null ||
            element.default_value === undefined
          ) {
            Object.assign(this.alert, {
              icona: "mdi-alert-box",
              messaggio: `${element.name} Must be Included`,
              mostra: true,
            });
            return null;
          }
          console.log(`element.default_value`, element.default_value);
          if (
            this.getInputType(element.type) == "number" &&
            element.default_value < this.getNum(element.boundaries.hard_min_value)
          ) {
            Object.assign(this.alert, {
              icona: "mdi-alert-box",
              messaggio: `${element.name} can not be lower than ${element.boundaries.hard_min_value}`,
              mostra: true,
            });
            return;
          }
          if (
            this.getInputType(element.type) == "number" &&
            element.default_value > this.getNum(element.boundaries.hard_max_value)
          ) {
            Object.assign(this.alert, {
              icona: "mdi-alert-box",
              messaggio: `${element.name} can not be greater than ${element.boundaries.hard_max_value}`,
              mostra: true,
            });
            return;
          }
          console.log(`this.singleProgram`, this.singleProgram);
          const encoded = btoa(
            unescape(encodeURIComponent(JSON.stringify(this.singleProgram)))
          );
          this.$emit("EncodedPartProgram", encoded);
        }
      }
    },
  },
};
</script>
