<template>
  <div >
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="dialogDelete"
      width="unset"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline">
          <v-toolbar-title class="white--text"
            >Questo elemento verrà eliminato definitivamente</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-6">
          <h3 style="text-align: left !important">
            Sei sicuro? vuoi eliminare questa elemento?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" dark @click="dialogDelete = false">ANNULLA</v-btn>
          <v-btn color="green" dark @click="deleteFornitura">CONFERMA</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="artDialog"
      persistent
      v-model="add_misura_dialog"
      width="700"
    >
      <v-card>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text"
            >Aggiungi Unità di misura</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaMisura()" small>
                <v-icon v-on="on" v-bind="attrs" color="success"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva Misura</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                @click="
                  add_misura_dialog = false;
                  getunitmisura();
                "
              >
                <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-row class="pa-0">
            <v-col md="3">Unità standard (di gestione)</v-col>
            <v-col md="5">Unità derivate</v-col>
            <v-col>Fattore di conversione</v-col>
          </v-row>
          <v-row
            class="pa-0 mt-n10"
            v-for="item in unita_misura_mod_list"
            :key="item.id"
          >
            <v-col md="3">
              <v-text-field
                type="text"
                v-model="data.um_articolo"
                class="input-class centered-input"
                label
                required
                min="0"
                align="right"
                readonly
              ></v-text-field>
            </v-col>
            <v-col md="5">
              <v-autocomplete
                class="ml-1 input-class centered-input"
                v-model="item.id_um"
                :items="misura_list"
                required
                item-text="des"
                item-value="id_tabella"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                v-model="item.fattore_conversione"
                class="shrink input-class centered-input"
                label="Fattore di Conversione"
                required
                min="0"
                align="right"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mt-n11 ml-n1" @click="addNewMisura" small>
                <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                  >mdi-plus</v-icon
                >
              </v-btn>
            </template>
            <span> Aggiungi Nuova Riga</span>
          </v-tooltip>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="artDialog"
      persistent
      class="removeScroll"
      v-model="dialogNuovaFornitura"
      width="1100"
    >
      <v-dialog persistent v-model="dialogForniture" width="700">
        <v-overlay :value="overlay" style="z-index: 10">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card>
          <v-toolbar class="indigo darken-1 headline" primary-title>
            <v-toolbar-title class="white--text"
              >Risultato della ricerca</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="dialogForniture = false" small>
                  <v-icon v-on="on" v-bind="attrs" depressed color="#c00000"
                    >mdi-window-close</v-icon
                  >
                </v-btn>
              </template>
              <span>Cancellare </span>
            </v-tooltip>
          </v-toolbar>

          <v-card-text style="max-height: 600px; overflow-y: auto">
            <v-data-table
              :loading="false"
              :headers="headers1"
              :items="searchRes"
            >
              <v-progress-linear
                v-show="progressBar"
                slot="progress"
                color="blue"
                indeterminate
              ></v-progress-linear>
              <template v-slot:[`item.actions`]="props">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="onSetItem(props.item)"
                      small
                    >
                      <v-icon class="mx-2" dark color="#009900">
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> imposta Fornitura </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-card>
        <v-overlay :value="overlay" style="z-index: 10">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-toolbar dense class="indigo darken-1 headline" primary-title>
          <v-toolbar-title class="white--text">Anagrafica</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="salvaAnagrafica" small>
                <v-icon v-on="on" v-bind="attrs" color="success"
                  >mdi-floppy</v-icon
                >
              </v-btn>
            </template>
            <span>Salva</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small @click="closeNuovaFornitura">
                <v-icon v-on="on" v-bind="attrs" color="#c00000" depressed
                  >mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form @keyup.enter.native="searchAnagrafica">
            <v-container fluid>
              <v-row>
                <v-col cols="2" class="py-0 my-0 align-self-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        v-on="on"
                        v-bind="attrs"
                        @click="searchAnagrafica"
                        @keyup.enter.native="searchAnagrafica"
                      >
                        <v-icon color="#1a80b6">mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>Cerca </span>
                  </v-tooltip>
                </v-col>
                <v-col md="2" cols="5" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    class="input-class"
                    v-model="cod_anagrafica"
                    label="Codice"
                    placeholder="Codice"
                    required
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="5" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    class="input-class"
                    v-model="ragione_sociale"
                    label="Ragione Sociale"
                    placeholder="Ragione Sociale"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0 my-0">
                  <div>
                    <span style="font-size: 11px; color: rgba(0, 0, 0, 0.6)"
                      >Predefinito</span
                    >
                    <v-switch
                      v-model="predefinito"
                      class="mt-n1"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="2" class="py-0 my-0">
                  <div>
                    <span style="font-size: 11px; color: rgba(0, 0, 0, 0.6)"
                      >Trasf. Diretto</span
                    >
                    <v-switch
                      v-model="trasferimento_diretto"
                      class="mt-n1"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="3" md="6" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    class="input-class"
                    v-model="indirizzo"
                    label="Indirizzo"
                    placeholder="Indirizzo"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="6" class="py-0 my-0">
                  <v-text-field
                    type="text"
                    class="input-class"
                    v-model="localita"
                    label="Località"
                    placeholder="Località"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="1" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    class="input-class"
                    v-model="qta_minima_ordine"
                    label="MOQ"
                    min="0"
                    placeholder="MOQ"
                    required
                    @input="$emit('update:moq', Number($event))"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="1" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    class="input-class"
                    v-model="qta_lotto_minimo"
                    label="Q.ta Lotto"
                    min="0"
                    placeholder="Q.ta Lotto"
                    required
                    @input="$emit('update:lot_size', Number($event))"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="3" class="py-0 my-0">
                  <v-autocomplete
                    class="ml-4"
                    v-model="id_um_lotto"
                    :items="misura_list"
                    item-text="des"
                    placeholder="UM Q.ta Lotto"
                    required
                    item-value="des"
                    label="UM Q.ta Lotto"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3" md="3" class="py-0 my-0">
                  <v-autocomplete
                    class=""
                    v-model="data.id_tipo_fornitura"
                    :items="modalita_list"
                    item-text="des"
                    placeholder="Modalità"
                    required
                    item-value="des"
                    label="Modalità"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="4" class="py-0 my-0">
                  <v-autocomplete
                    v-model="id_tipo_trasporto"
                    :items="incoterms_list"
                    item-text="des"
                    placeholder="Incoterms"
                    required
                    item-value="des"
                    label="Incoterms"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" md="2" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    class="input-class"
                    v-model="tempo_produzione"
                    label="Production Time"
                    min="0"
                    placeholder="Prod Time"
                    required
                    @input="$emit('update:tempo_produzione', Number($event))"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="2" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    class="input-class"
                    v-model="tempo_transito"
                    label="Transit Time"
                    min="0"
                    placeholder="Transit Time"
                    required
                    @input="$emit('update:tempo_transito', Number($event))"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="2" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    class="input-class"
                    v-model="tempo_specifico"
                    label="Custom Time"
                    min="0"
                    placeholder="Custom Time"
                    required
                    @input="$emit('update:tempo_specifico', Number($event))"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="1" class="py-0 my-0">
                  <v-text-field
                    type="number"
                    readonly
                    class="input-class"
                    disabled
                    v-model="lead_time"
                    label="Lead Time"
                    min="0"
                    placeholder="Lead Time"
                    required
                    @input="$emit('update:tempo_specifico', parseFloat($event))"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3" class="py-0 my-0">
                  <v-autocomplete
                    class="ml-1"
                    v-model="id_um_lead_time"
                    :items="unita_misura_lead_time"
                    item-text="des"
                    placeholder="Unità Misura Lead Time"
                    required
                    item-value="des"
                    label="Unità Misura Lead Time"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-form action>
        <v-row>
          <v-col cols="12" sm="12" xs="6" md="6">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card :elevation="hover ? 24 : 2">
                  <v-toolbar
                    dense
                    class="indigo darken-1 headline"
                    primary-title
                  >
                    <v-toolbar-title class="white--text"
                      >Caratteristiche Fisiche</v-toolbar-title
                    >
                  </v-toolbar>

                  <v-row>
                    <v-col cols="12" sm="6" xs="7" md="7">
                      <v-autocomplete
                        class="ml-1"
                        v-model="data.id_um"
                        :items="misura_list"
                        required
                        :disabled="CodEnable"
                        item-text="des"
                        item-value="id_tabella"
                        label="Unità di misura gestione"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col md="2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mb-n11 ml-n1"
                            @click="
                              add_misura_dialog = true;
                              getunitmisura();
                            "
                            :disabled="CodEnable"
                            small
                          >
                            <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                              >mdi-plus</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Aggiungi Unità Misura</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="mt-2" cols="2" xs="2" sm="4" md="3">
                      <span
                        style="font-size: 11px; color: rgba(0, 0, 0, 0.6)"
                        class="ml-n12 pt-10"
                      >
                        Venduto in kit
                      </span>
                      <v-switch
                        :disabled="CodEnable"
                        v-model="data.vendita_kit"
                        class="mt-n1 ml-3"
                        :false-value="0"
                        :true-value="1"
                      ></v-switch>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="ml-1 mt-n11">
                      <v-text-field
                        type="number"
                        class="input-class centered-input"
                        v-model="data.l"
                        label="Lunghezza"
                        placeholder="Lunghezza"
                        required
                        min="0"
                        align="right"
                        :disabled="CodEnable"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" md="2" class="mt-n11">
                      <v-text-field
                        type="number"
                        class="input-class centered-input"
                        v-model="data.p"
                        label="Larghezza"
                        placeholder="Larghezza"
                        required
                        min="0"
                        align="right"
                        :disabled="CodEnable"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" md="2" class="mt-n11">
                      <v-text-field
                        type="number"
                        class="input-class centered-input"
                        v-model="data.h"
                        :disabled="CodEnable"
                        label="Altezza/spessore"
                        placeholder="Altezza"
                        required
                        min="0"
                        align="right"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="ml-1 mt-n11" sm="5" xs="7" md="5">
                      <v-autocomplete
                        class="ml-1"
                        v-model="data.id_um_fisica"
                        :items="unita_misura_larghezza"
                        :disabled="CodEnable"
                        item-text="des"
                        placeholder="Unità Misura"
                        required
                        item-value="id_tabella"
                        label="UM Larghezza/Lunghezza"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="3" sm="2" md="2" class="ml-1 mt-n11">
                      <v-text-field
                        type="number"
                        class="input-class centered-input"
                        v-model="data.peso_netto"
                        :disabled="CodEnable"
                        label="Peso Netto"
                        placeholder="Peso Netto"
                        required
                        align="right"
                        min="0"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" sm="2" class="mt-n11">
                      <v-text-field
                        type="number"
                        class="input-class centered-input"
                        v-model="data.peso_lordo"
                        :disabled="CodEnable"
                        label="Peso Lordo"
                        placeholder="Peso Lordo"
                        required
                        align="right"
                        min="0"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3" sm="2" md="2" class="mt-n11">
                      <v-text-field
                        type="number"
                        align="right"
                        class="input-class centered-input"
                        v-model="data.peso_specifico"
                        :disabled="CodEnable"
                        label="Peso Specifico"
                        placeholder="Peso Specifico"
                        required
                        min="0"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="ml-1 mt-n11" sm="5" xs="7" md="5">
                      <v-autocomplete
                        class="ml-1"
                        v-model="data.id_um_peso"
                        :disabled="CodEnable"
                        :items="unita_misura_peso"
                        item-text="des"
                        placeholder="UM Peso"
                        required
                        item-value="id_tabella"
                        label="UM Peso"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="mt-n11" cols="12" sm="5" md="4">
                      <v-autocomplete
                        class="mr-1 ml-1"
                        v-model="data.id_materiale"
                        :disabled="CodEnable"
                        :items="materiale_list"
                        placeholder="Materiale"
                        item-text="des"
                        required
                        item-value="id_tabella"
                        label="Materiale"
                      >
                        <template #label>
                          <span class="">Materiale<strong>* </strong></span>
                          <a @click.stop style="pointer-events: all">
                            <Modules
                              v-on:dialogModules="aggiornaMateriale"
                              :tipo="Tipo"
                              nome="Materiale"
                              setaction="settabelle"
                              getaction="gettabelle"
                              controller="Magazzino"
                              filter="validita = 1"
                              :dati="[{ des: '' }]"
                            />
                          </a>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col sm="1" md="2" class="ml-1 mt-n11">
                      <v-text-field
                        type="number"
                        class="input-class centered-input"
                        v-model="data.volume"
                        :disabled="CodEnable"
                        label="Volume"
                        placeholder="Volume"
                        required
                        align="right"
                        min="0"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="mt-n11" sm="4" xs="7" md="4">
                      <v-autocomplete
                        class="ml-1"
                        v-model="data.id_um_volume"
                        :items="unita_misura_volume"
                        :disabled="CodEnable"
                        item-text="des"
                        required
                        item-value="id_tabella"
                        label="UM Volume"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>

          <v-col sm="12" xs="6" md="6">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card height="292" :elevation="hover ? 24 : 2">
                  <v-toolbar
                    dense
                    class="indigo darken-1 headline"
                    primary-title
                  >
                    <v-toolbar-title class="white--text"
                      >Annotazioni</v-toolbar-title
                    >
                  </v-toolbar>

                  <v-row>
                    <v-col class="" md="12">
                      <v-textarea
                        style="padding-bottom: 0px !important"
                        v-model="data.note"
                        :disabled="CodEnable"
                        solo
                        inverted
                        flat
                        placeholder="Note"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>

          <v-col
            v-show="data.id_articolo"
            cols="12"
            xs="12"
            md="12"
            sm="12"
            style=""
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card content-class="Anagrafica" :elevation="hover ? 24 : 2">
                  <v-toolbar
                    class="indigo darken-1 headline"
                    primary-title
                    uppercase
                    dense
                  >
                    <v-toolbar-title class="white--text"
                      >Fornitura</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openModal"
                          :disabled="CodEnable"
                          class="ml-2"
                          small
                        >
                          <v-icon v-on="on" v-bind="attrs" color="#1a80b6"
                            >mdi-plus</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Aggiungi Nuova Fornitura</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <v-data-table
                      :loading="false"
                      :headers="headers"
                      class="elevation-1 tablestyle"
                      :items="items"
                      :hide-default-footer="true"
                    >
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              style="float: left"
                              @click="editFornitura(item)"
                              :disabled="CodEnable"
                              small
                            >
                              <v-icon v-on="on" v-bind="attrs" color="#009999"
                                >mdi-pencil</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Aggiornare</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:[`item.predefinito`]="{ item }">
                        <v-checkbox
                          class=""
                          :disabled="CodEnable"
                          v-model="item.predefinito"
                          :value="item.predefinito"
                          @change="doalert(item)"
                        >
                        </v-checkbox>
                      </template>
                      <template
                        v-slot:[`item.trasferimento_diretto`]="{ item }"
                      >
                        <v-icon v-if="item.trasferimento_diretto === 1"
                          >mdi-check-underline</v-icon
                        >
                      </template>
                      <template v-slot:[`item.lead_time`]="{ item }">
                        <v-chip
                          style="font-style: bold; font-weight: 700"
                          outlined
                          color="green"
                          >{{ item.lead_time }}</v-chip
                        >
                      </template>
                      <template v-slot:[`item.delete`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              @click="openDialogDeleteFornitura(item)"
                              :disabled="CodEnable"
                              small
                            >
                              <v-icon v-on="on" v-bind="attrs" color="#c00000">
                                mdi-window-close
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Cancellare</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-col>

          <v-col class="mt-n3" md="12" sm="12" xs="12">
            <v-hover>
              <template v-slot="{ hover }">
                <v-card :elevation="hover ? 24 : 2">
                  <v-toolbar dense color="indigo darken-1 headline" dark flat>
                    <v-toolbar-title class="white--text"
                      >Dati Contabili</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-row>
                    <v-col md="3.5" class="ml-2">
                      <v-autocomplete
                        v-model="data.id_conto_acquisto"
                        :items="contro_partita_acquisto_list"
                        :disabled="CodEnable"
                        item-text="text"
                        required
                        item-value="id_tabella"
                        label="Contro Partita Acquisto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3.5" class="ml-2">
                      <v-autocomplete
                        v-model="data.id_conto_vendita"
                        :items="contro_partita_venduto_list"
                        :disabled="CodEnable"
                        item-text="text"
                        required
                        item-value="id_tabella"
                        label="Contro Partita Venduto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3.5" class="mr-2">
                      <v-autocomplete
                        v-model="data.aliquota_iva"
                        :items="aliquota_iva_list"
                        :disabled="CodEnable"
                        item-text="text"
                        required
                        item-value="id_tabella"
                        label="Aliquota I.V.A"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  // Data getting as props from articolo Component
  props: ["data", "CodEnable"],
  components: {
    // Modules to show details of v-autocomplete list
    Modules: () => import("../../../../components/TOOLS/Modules"),
  },
  data() {
    return {
      // Modal
      color: "",
      add_misura_dialog: false,
      itemtodel: {},
      dialogDelete: false,
      // Variabili d'appoggio
      Tipo: "MATERIALE",

      id_um_lead_time: "",
      progressBar: true,

      id_tipo_trasporto: "",
      anagraficaItem: {},
      id_um_lotto: "",
      modalita_list: [],
      incoterms_list: [],
      unita_misura_mod_list: [],
      lead_time: 0,
      dialogForniture: false,
      searchRes: [],
      materiale_list: [],
      misura_list: [],
      contro_partita_acquisto_list: [],
      aliquota_iva_list: [],
      contro_partita_venduto_list: [],
      ubicazione_list: [],
      overlay: false,
      items: [],
      predefinito: false,
      cod_anagrafica: "",

      dialogNuovaFornitura: false,

      indirizzo: "",
      localita: "",
      qta_minima_ordine: 0,
      qta_lotto_minimo: 0,
      tempo_produzione: 0,
      tempo_transito: 0,
      tempo_specifico: 0,
      // lead_time: "",
      trasferimento_diretto: 0,
      unita_misura_larghezza: [],
      unita_misura_peso: [],
      unita_misura_volume: [],
      unita_misura_lead_time: [],
      ragione_sociale: "",
      dataToUpdate: {},
      id_anagrafica: "",

      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      //  Headers Lista For Fornitura Table //
      headers: [
        {
          align: "start",
          value: "edit",
          sortable: false,
        },
        {
          text: "Predefinito",
          align: "start",
          value: "predefinito",
          sortable: false,
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Indirizzo",
          value: "indirizzo",
          sortable: false,
        },
        {
          text: "Località",
          value: "localita",
          sortable: false,
        },
        {
          text: "MOQ",
          value: "qta_minima_ordine",
          sortable: false,
        },
        {
          text: "Q.ta Lotto",
          value: "qta_lotto_minimo",
          sortable: false,
        },
        {
          text: "UM Lotto",
          value: "_umlotto",
          sortable: false,
        },
        {
          text: "Transit Time",
          value: "tempo_transito",
          sortable: false,
        },
        {
          text: "Production Time",
          value: "tempo_produzione",
          sortable: false,
        },
        {
          text: "Custom Time",
          value: "tempo_specifico",
          sortable: false,
        },
        {
          text: "Lead Time",
          value: "lead_time",
          sortable: false,
        },
        {
          text: "Trasferimento Diretto",
          value: "trasferimento_diretto",
          sortable: false,
        },
        {
          text: "UM Lead Time",
          value: "_umleadtime",
          sortable: false,
        },
        {
          text: "Modalità",
          value: "_umfornitura",
          sortable: false,
        },
        {
          text: "Incoterms",
          value: "_umtrasporto",
          sortable: false,
        },
        {
          text: "",
          value: "delete",
          // text: '', fixed: true, width: '100px',
          sortable: false,
        },
      ],
      // Table headers for Forntiura search Modal
      headers1: [
        {
          align: "start",
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "Tipo",
          value: "_tipo",
          sortable: false,
        },
        {
          text: "Codice",
          value: "codice",
          sortable: false,
        },
        {
          text: "Ragione sociale",
          value: "ragione_sociale",
          sortable: false,
        },
        {
          text: "Indrizzo",
          value: "indirizzo",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    // Confirmation of deletion of single disegno
    openDialogDeleteFornitura(item) {
      this.dialogDelete = true;
      this.itemtodel = item;
    },
    // Function to add Materiale to materiale lista
    aggiornaMateriale() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "gettabelle",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          tipo: "MATERIALE",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        res.data.map((el) => {
          this.materiale_list.push(el);
          this.materiale_list.map((el) => {
            if (el.id_tabella === this.id_materiale) {
              this.id_materiale = el.des;
            }
          });
        });
      });
    },
    // In order to set Predefinito to only one item of the table
    doalert(item) {
      this.items.filter((it) => {
        if (it !== item) {
          it.predefinito = 0;
          return this.items;
        }
        if (it === item) {
          it.predefinito = 1;
          return this.items;
        }
      });

      let setCheckBoxes = {
        controller: "Magazzino",
        method: "PUT",

        richiesta: {
          action: "setarticolofornitura",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_articolo: this.data.id_articolo,
          id_utente: localStorage.id,
          articolo_fornitura: this.items,
        },
      };

      this.$store.dispatch("api", setCheckBoxes);
    },

    // to open the modal of Fornitura
    openModal() {
      if (this.data.id_articolo == -1) {
        this.snackbar = true;
        (this.snackbar_background = "error"),
          (this.snackbar_icon = "mdi-close"),
          (this.snackbar_icon_color = "white"),
          (this.snackbar_text_color = "white"),
          (this.snackbar_text =
            "Generare l'articolo prima di modificare la fornitura.");
      } else {
        this.predefinito = 0;
        this.trasferimento_diretto = false;
        this.dialogNuovaFornitura = true;
        this.dataToUpdate = {};
      }
    },

    // In order to close the fornitura modal
    closeNuovaFornitura() {
      this.indirizzo = "";
      this.localita = "";
      this.qta_minima_ordine = "";
      this.ragione_sociale = "";
      this.qta_lotto_minimo = 0;
      this.tempo_produzione = 0;
      this.tempo_transito = 0;
      this.tempo_specifico = 0;
      this.lead_time = 0;
      this.predefinito = false;
      this.dialogNuovaFornitura = false;
      this.modalita = "";
      this.trasferimento_diretto = false;
      this.cod_anagrafica = "";
      (this.id_tipo_trasporto = ""),
        (this.id_tipo_fornitura = ""),
        (this.id_um_lead_time = ""),
        (this.id_um_lotto = "");
    },
    // TO save the unita misura in caratterristice
    salvaMisura() {
      this.unita_misura_mod_list.map((el) => {
        el.fattore_conversione = parseInt(el.fattore_conversione);

        this.misura_list.map((mi) => {
          if (el.id_um === mi.id_tabella) {
            el.des_um = mi.des;
            return el;
          }
        });
      });

      const filteredArr = this.unita_misura_mod_list.reduce(
        (thing, current) => {
          const x = thing.find((item) => item.id_um === current.id_um);
          if (!x) {
            return thing.concat([current]);
          } else {
            let that = this;
            setTimeout(function () {
              that.snackbar = true;
              (that.snackbar_background = "orange"),
                (that.snackbar_icon = "mdi-alert"),
                (that.snackbar_icon_color = "white"),
                (that.snackbar_text_color = "white"),
                (that.snackbar_text =
                  "Eliminate le unità di misura duplicate!");
            }, 1000);
            return thing;
          }
        },
        []
      );

      this.unita_misura_mod_list = filteredArr;

      if (!this.snackbar) {
        const request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticoloum",
            token: localStorage.getItem("user_token"),
            db_name: "quickproduction",
            id_articolo: this.data.id_articolo,
            id_utente: localStorage.id,
            articolo_um: this.unita_misura_mod_list,
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          if (res.status == 200) {
            this.snackbar = true;
            (this.snackbar_background = "success"),
              (this.snackbar_icon = "mdi-check"),
              (this.snackbar_icon_color = "white"),
              (this.snackbar_text_color = "white"),
              (this.snackbar_text = "Misure salvate con successo!");
          }
        });
        this.add_misura_dialog = false;
        this.getunitmisura();
      }
    },
    // In order to add a new row in unita misura modal
    addNewMisura() {
      const newRow = {
        id_articolo_um: -1,
        id_articolo: this.data.id_articolo,
        id_um: this.data.id_um,
        fattore_conversione: "",
        des_um: "",
      };
      this.unita_misura_mod_list.push(newRow);
    },
    
    editFornitura(val) {
      this.dataToUpdate = val;

      if (val.predefinito) {
        this.predefinito = true;
      }
      if (val.predefinito === 0) {
        this.predefinito = false;
      }
      this.dialogNuovaFornitura = true;
      this.ragione_sociale = val.ragione_sociale;
      this.indirizzo = val.indirizzo;
      this.localita = val.localita;
      this.qta_minima_ordine = val.qta_minima_ordine;
      this.qta_lotto_minimo = val.qta_lotto_minimo;
      this.tempo_produzione = val.tempo_produzione;
      this.tempo_transito = val.tempo_transito;
      this.tempo_specifico = val.tempo_specifico;
      this.lead_time = val.lead_time;

      this.predefinito = val.predefinito;
      this.id_um_lead_time = val._umleadtime;
      this.id_um_lotto = val._umlotto;
      this.id_tipo_fornitura = val._umfornitura;
      this.id_tipo_trasporto = val._umtrasporto;
      this.cod_anagrafica = val.codice;
      this.trasferimento_diretto = val.trasferimento_diretto;
      this.id_anagrafica = val.id_anagrafica;
    },
    // Setting the value of single fornitura
    onSetItem(val) {
      this.anagraficaItem = val;
      this.cod_anagrafica = val.codice;
      this.indirizzo = val.indirizzo;
      this.localita = val.localita;
      this.ragione_sociale = val.ragione_sociale;
      this.dialogForniture = false;
    },

    // In order to search Anagraficai
    searchAnagrafica() {
      // console.log("searchanagrafica happens");
      let searchReq = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getragionesociale",
          db_name: "quickproduction",
          cod_anagrafica: this.cod_anagrafica,
          des_anagrafica: this.ragione_sociale,
          tipo_anagrafica: "F",
          token: localStorage.getItem("user_token"),
        },
      };
      this.overlay = true;
      // console.log("overlay: ", this.overlay);
      this.$store.dispatch("api", searchReq).then((res) => {
        this.dialogForniture = true;
        // if(res.data.length > 0){
        //   this.dialogForniture = true
        // }
        this.overlay = false;
        // console.log("overlay: ", this.overlay);
        this.searchRes = res.data;
        if (this.searchRes.length === 0) {
          this.snackbar = true;
          (this.snackbar_background = "error"),
            (this.snackbar_icon = "mdi-close"),
            (this.snackbar_icon_color = "white"),
            (this.snackbar_text_color = "white"),
            (this.snackbar_text =
              "Ragione Sociale non è stato trovato. Riprova !");
          this.progressBar = false;
          this.dialogForniture = false;
        }
        if (this.searchRes.length > 0) {
          this.progressBar = true;
        }
      });
    },
    // TO Delete Fornitura
    deleteFornitura() {
      let data = {};
      data.id_articolo_fornitura = this.itemtodel.id_articolo_fornitura;
      data.validita = 0;

      let DeleteFornitura = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarticolofornitura",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_articolo: this.data.id_articolo,
          id_utente: localStorage.id,
          articolo_fornitura: [data],
        },
      };
      this.$store.dispatch("api", DeleteFornitura).then((res) => {
        if (res.data) {
          this.snackbar = true;
          (this.snackbar_background = "success"),
            (this.snackbar_icon = "mdi-check"),
            (this.snackbar_icon_color = "white"),
            (this.snackbar_text_color = "white"),
            (this.snackbar_text = "Modifica effettuata con successo!");
        }
      });
      this.dialogDelete = false;
      let i = this.items.findIndex(
        (it) => it.id_articolo_fornitura === data.id_articolo_fornitura
      );
      this.items.splice(i, 1);
    },
    // To Save An Anagrafica
    salvaAnagrafica() {
      /* Controllo che siano stati compilati almeno
       * il codice e la ragione sociale. */
      if (this.cod_anagrafica == "") {
        this.snackbar = true;
        (this.snackbar_background = "orange"),
          (this.snackbar_icon = "mdi-alert"),
          (this.snackbar_icon_color = "white"),
          (this.snackbar_text_color = "white"),
          (this.snackbar_text =
            "Inserire il Codice e la Ragione Sociale prima di salvare.");
        return;
      }

      if (this.dataToUpdate.id_articolo_fornitura) {
        let data = {
          codice: parseInt(this.cod_anagrafica),
          id_articolo: this.data.id_articolo,
          id_anagrafica: this.id_anagrafica,
          id_articolo_fornitura: this.dataToUpdate.id_articolo_fornitura,
          ragione_sociale: this.ragione_sociale,
          indirizzo: this.indirizzo,
          localita: this.localita,
          predefinito: this.predefinito,
          qta_minima_ordine: this.qta_minima_ordine,
          qta_lotto_minimo: this.qta_lotto_minimo,
          id_um_lotto: this.id_um_lotto,
          trasferimento_diretto: this.trasferimento_diretto,
          lead_time:
            parseFloat(this.tempo_produzione) +
            parseFloat(this.tempo_transito) +
            parseFloat(this.tempo_specifico),

          tempo_produzione: parseFloat(this.tempo_produzione),
          tempo_transito: parseFloat(this.tempo_transito),
          tempo_specifico: parseFloat(this.tempo_specifico),
          id_um_lead_time: this.id_um_lead_time,
          id_tipo_trasporto: this.id_tipo_trasporto,
          id_tipo_fornitura: this.data.id_tipo_fornitura,
          validita: 1,
        };

        let dataToPush = data;

        this.misura_list.map((mo) => {
          if (this.id_um_lotto === mo.des) {
            data.id_um_lotto = mo.id_tabella;
            // dataToPush.id_um_lotto = mo.des

            return data;
          }
        });

        this.misura_list.map((mo) => {
          if (this.id_um_lead_time === mo.des) {
            data.id_um_lead_time = mo.id_tabella;
            data._umleadtime = mo.des;
            // dataToPush.id_um_lead_time = mo.des

            return data;
          }
        });

        // this.modalita_list.map((mo) => {
        //   if (this.id_tipo_fornitura === mo.des) {
        //     data.id_tipo_fornitura = mo.id_tabella;
        //     dataToPush._umfornitura = mo.des;
        //   }
        // });

        this.incoterms_list.map((mo) => {
          if (this.id_tipo_trasporto === mo.des) {
            data.id_tipo_trasporto = mo.id_tabella;
            dataToPush._umtrasporto = mo.des;
          }
        });

        let dataArray = [];
        dataArray.push(data);

        let setFornitura = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolofornitura",
            token: localStorage.getItem("user_token"),
            db_name: "quickproduction",
            id_articolo: this.data.id_articolo,
            id_utente: localStorage.id,
            articolo_fornitura: dataArray,
          },
        };

        this.$store.dispatch("api", setFornitura).then((res) => {
          if (res.data) {
            this.snackbar = true;
            (this.snackbar_background = "success"),
              (this.snackbar_icon = "mdi-check"),
              (this.snackbar_icon_color = "white"),
              (this.snackbar_text_color = "white"),
              (this.snackbar_text = "Modifica effettuata con successo!");
          }
        });
        let i = this.items.findIndex(
          (it) => it.id_articolo_fornitura === data.id_articolo_fornitura
        );

        this.items.splice(i, 1, dataToPush);
        // this.getFornitura()
        if (dataToPush.predefinito) {
          this.doalert(data);
        }
      }

      if (!this.dataToUpdate.id_articolo_fornitura) {
        if (this.ragione_sociale === "") {
          return;
        }
        let data = {
          codice: this.anagraficaItem.codice,
          id_anagrafica: this.anagraficaItem.id_anagrafica,
          id_articolo: this.data.id_articolo,
          id_articolo_fornitura: -1,
          ragione_sociale: this.anagraficaItem.ragione_sociale,
          indirizzo: this.anagraficaItem.indirizzo,
          localita: this.anagraficaItem.localita,
          qta_minima_ordine: this.qta_minima_ordine,
          qta_lotto_minimo: this.qta_lotto_minimo,
          id_um_lotto: this.id_um_lotto,
          trasferimento_diretto: this.trasferimento_diretto,
          tempo_produzione: parseFloat(this.tempo_produzione),
          tempo_transito: parseFloat(this.tempo_transito),
          tempo_specifico: parseFloat(this.tempo_specifico),
          lead_time:
            parseFloat(this.tempo_produzione) +
            parseFloat(this.tempo_transito) +
            parseFloat(this.tempo_specifico),
          predefinito: this.predefinito,
          id_um_lead_time: this.id_um_lead_time,
          id_tipo_trasporto: this.id_tipo_trasporto,
          id_tipo_fornitura: this.id_tipo_fornitura,
          validita: 1,
        };

        // console.log('data before Update :>> ', data);

        let dataToadd = data;

        this.misura_list.map((mo) => {
          if (data.id_um_lotto === mo.des) {
            data.id_um_lotto = mo.id_tabella;
            dataToadd.id_materiale = mo.id_tabella;
            dataToadd._umlotto = mo.des;

            return data;
          }
        });

        this.misura_list.map((mi) => {
          if (data.id_um_lead_time === mi.des) {
            dataToadd._umleadtime = mi.des;
            dataToadd.id_um_lead_time = mi.id_tabella;
            data._umleadtime = mi.des;
            data.id_um_lead_time = mi.id_tabella;
          }
        });

        // this.modalita_list.map((mo) => {
        //   if (data.id_tipo_fornitura === mo.des) {
        //     data.id_tipo_fornitura = mo.id_tabella;
        //     dataToadd._umfornitura = mo.des;
        //   }
        // });

        this.incoterms_list.map((mo) => {
          if (data.id_tipo_trasporto === mo.des) {
            data.id_tipo_trasporto = mo.id_tabella;
            dataToadd._umtrasporto = mo.des;
          }
        });

        let dataArray = [];
        dataArray.push(data);

        let addFornitura = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setarticolofornitura",
            token: localStorage.getItem("user_token"),
            db_name: "quickproduction",
            id_articolo: this.data.id_articolo,
            id_utente: localStorage.id,
            articolo_fornitura: dataArray,
          },
        };

        this.$store.dispatch("api", addFornitura).then((res) => {
          this.items = res.data.filter((e) => e.validita == 1);
          if (res.data) {
            this.snackbar = true;
            this.snackbar_text = "Modifica effettuata con successo!";
            this.color = "green darken-3";
            this.mainIcon = "mdi-check-circle";
          }
          if (data.predefinito) {
            this.doalert(data);
          }
        });
      }

      this.cliente = "";
      this.indirizzo = "";
      this.localita = "";
      this.qta_minima_ordine = "";

      this.ragione_sociale = "";
      this.qta_lotto_minimo = 0;
      this.tempo_produzione = 0;
      this.tempo_transito = 0;
      this.id_um_lotto = "";
      this.id_tipo_trasporto = "";
      this.id_um_lead_time = "";
      this.tempo_specifico = 0;
      this.lead_time = 0;
      this.predefinito = false;
      this.id_tipo_fornitura = "";
      this.dialogNuovaFornitura = false;
      this.cod_anagrafica = "";
      this.modalita = "";
    },
    // To populate the unita misura table
    getunitmisura() {
      let listaUnitaMisura = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoloum",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_articolo: this.data.id_articolo,
          id_utente: localStorage.id,
        },
      };

      this.$store.dispatch("api", listaUnitaMisura).then((res) => {
        this.unita_misura_mod_list = res.data;
      });
    },
    // Getting list of fornitura to populate data table
    getFornitura() {
      let getFornitura = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticolofornitura",
          token: localStorage.getItem("user_token"),
          db_name: "quickproduction",
          id_articolo: this.data.id_articolo,
          id_utente: localStorage.id,
        },
      };

      this.$store.dispatch("api", getFornitura).then((res) => {
        this.items = res.data;

        this.items.map((it) => {
          this.incoterms_list.map((inc) => {
            if (it.id_tipo_trasporto === inc.id_tabella) {
              it.id_tipo_trasporto = inc.des;
            }
          });
          this.misura_list.map((mi) => {
            if (it.id_um_lotto === mi.id_tabella) {
              it.id_um_lotto = mi.des;
            }
          });
          this.misura_list.map((mii) => {
            if (it.id_um_lead_time === mii.id_tabella) {
              it.id_um_lead_time = mii.des;
            }
          });
          return this.items;
        });
      });
    },
  },

  mounted() {
    // In order to populate the lista of V-selects
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        db_name: "quickproduction",
        tipo:
          "'UNITA_MISURA','MATERIALE','UBICAZIONE_STANDARD','CAUSALE_TRASPORTO','PORTO'",
        filter: "validita = 1",
      },
    };

    // Gestisco la tabella UNITA_MISURA che ricevo dal BE
    this.$store.dispatch("api", request).then((res) => {
      res.data.UNITA_MISURA.map((el) => {
        this.misura_list.push(el);
        this.misura_list.map((el) => {
          if (el.val_txt === "M") {
            this.unita_misura_larghezza.push(el);
          }
          if (el.val_txt === "P") {
            this.unita_misura_peso.push(el);
          }
          if (el.val_txt === "V") {
            this.unita_misura_volume.push(el);
          }
          if (el.val_txt === "H") {
            this.unita_misura_lead_time.push(el);
          }
          // if (el.id_tabella === this.data.id_um) {
          // this.unita_misura_gestione = el.des;
          // }
        });
      });

      // Gestisco la tabella MATERIALE che ricevo dal BE
      res.data.MATERIALE.map((el) => {
        this.materiale_list.push(el);
        this.materiale_list.map((el) => {
          if (el.id_tabella === this.id_materiale) {
            this.id_materiale = el.des;
          }
        });
      });

      // Gestisco la tabella UBICAZIONE_STANDARD che ricevo dal BE
      this.ubicazione_list = res.data.UBICAZIONE_STANDARD;

      // Gestisco la tabella CAUSALE_TRASPORTO che ricevo dal BE
      this.incoterms_list = res.data.CAUSALE_TRASPORTO;
      //   console.log(res.data.CAUSALE_TRASPORTO);

      // Gestisco la tabella PORTO che ricevo dal BE
      this.modalita_list = res.data.PORTO;
    });

    // In order to get Lista Unita Misura In Caratteristische
    this.getunitmisura();

    // In order to Populate the data
    this.getFornitura();
  },
  watch: {
    data: function (val) {
      const DATA = this.data;
      this[DATA] = !this[DATA];
      this.$emit("update", val);
    },
    tempo_produzione() {
      const I = this.tempo_produzione;
      this.lead_time =
        parseFloat(this.tempo_produzione) +
        parseFloat(this.tempo_transito) +
        parseFloat(this.tempo_specifico);
      this[I] = !this[I];
    },
    tempo_transito() {
      const T = this.tempo_transito;
      this.lead_time =
        parseFloat(this.tempo_produzione) +
        parseFloat(this.tempo_transito) +
        parseFloat(this.tempo_specifico);
      this[T] = !this[T];
    },
    tempo_specifico() {
      const S = this.tempo_specifico;
      this.lead_time =
        parseFloat(this.tempo_produzione) +
        parseFloat(this.tempo_transito) +
        parseFloat(this.tempo_specifico);
      this[S] = !this[S];
    },
  },
};
</script>

<style lang="scss">
.centered-input input {
  text-align: center !important;
}

.removeScroll {
  overflow-x: hidden !important;
}
.artDialog {
  overflow-x: hidden;
}
.Anagrafica {
  position: fixed;
  width: 100% !important;
}
.snackbar {
  height: 150px !important;
}
</style>
