<template>
  <div>
    <v-row>
          <v-col cols="12" class="pa-5">
            <v-toolbar
              dark
              color="#6a74ad"
              style="border-radius: 5px 5px 0px 0px"
              class="elevation-0"
              ><v-toolbar-title>Spedizione articoli per {{this.DESTINAZIONE}} </v-toolbar-title></v-toolbar
            >
            <v-data-table
              :headers="headers"
              :items="articoli"
              :items-per-page="5"
              v-model="articoli_imballo_first"
              class="elevation-3"
              item-key="cod_articolo"
            >

              <template v-slot:item.seleziona="{ item }"  >
                <v-tooltip left v-if="item.qta_residuo > 0" > 
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="dettaglioArticolo(item)">
                      <v-icon color="success"
                        >mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Seleziona Articolo nei lotti</span>
                </v-tooltip>
              </template>

              <template v-slot:item.contenitori="{ item }">
                  <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn  small v-bind="attrs" v-on="on" @click="gestione_contenitori(item)">
                              <v-icon color="green"
                              >mdi mdi-scale-bathroom
                              </v-icon>
                          </v-btn>
                      </template>
                      <span>Gestione Contenitori</span>
                  </v-tooltip>
              </template>

            </v-data-table>
          </v-col>
       
        <v-divider></v-divider>
     
          <v-col cols="4">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="copiaArticoli()"
                  ><v-icon color="primary">mdi-content-copy</v-icon></v-btn
                >
              </template>
              <span>Copia righe tabella imballi</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4"><span style="margin: 0 auto"> <h2>Componi Imballo </h2></span></v-col>
          <v-col cols="4">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }"
                ><v-btn v-bind="attrs" v-on="on"
                  ><v-icon color="primary">mdi-printer</v-icon></v-btn
                >
              </template>
              <span>Stampa righe tabella imballi</span>
            </v-tooltip></v-col
          >
       
   
          <v-col cols="12" class="pa-5">
            <v-toolbar
              dark
              color="#6a74ad"
              style="border-radius: 5px 5px 0px 0px"
              class="elevation-0"
              ><v-toolbar-title>PACKAGING</v-toolbar-title></v-toolbar
            >
            <v-data-table
              :headers="headers_imballo"
              :items="articoli_imballo"
              :items-per-page="5"
              v-model="selected"
              class="elevation-1"
              show-select
              item-key="id_documento_packaging"
            >
              <template v-slot:item.del="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="eliminaArticolo(item)"
                    >
                      <v-icon color="red"
                        >mdi-delete &nbsp;&nbsp;&nbsp;&nbsp;</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Elimina articolo</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <br>
        <v-btn dark color="#6a74ad" @click="chiudiImballo()">chiudi imballo</v-btn>
        <br> <br> <br>

      
        <v-dialog v-model="dialog_gest_contenitori" width="1000" persistent>
          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>Gestione Contenitori</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn light @click="dialog_gest_contenitori = false"
              ><v-icon color="red">mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="9"
                  ><v-autocomplete
                    outlined
                    dense
                    v-model="contenitore_selezionato"
                    label="Seleziona imballo"
                    :items="array_contenitori"
                    item-text="des_imballo"
                    item-value="id_articolo"
                    @change="cambiaSelezioneContenitore"
                  >
                  </v-autocomplete
                ></v-col>
                <v-col cols="3"
                  ><v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="qta_contenitore"
                    label="Quantita"
                  >
                  </v-text-field
                ></v-col>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="error" @click="dialog_gest_contenitori = false"
                >annulla</v-btn
              >
              <v-btn dark color="success" @click="confermaCambioContenitore()"
                >conferma</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_chiudi_imballo" width="900" persistent>
          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>Conferma imballo</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn light @click="dialog_chiudi_imballo = false"
              ><v-icon color="red">mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6"
                  ><v-select
                    outlined
                    dense
                    v-model="imballo_selezionato"
                    label="Seleziona imballo"
                    :items="imballi"
                    item-text="des_imballo"
                    item-value="id_articolo"
                    selected
                    @change="cambiaSelezione"
                  >
                  </v-select
                ></v-col>
                <v-col cols="6"
                  ><v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="peso_imballo"
                    label="Peso"
                    readonly
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="lunghezza_imballo"
                    label="Lunghezza"
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4"
                  ><v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="larghezza_imballo"
                    label="Larghezza"
                    readonly
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="4"
                  ><v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="altezza_imballo"
                    label="Altezza"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="error" @click="dialog_chiudi_imballo = false"
                >annulla</v-btn
              >
              <v-btn dark color="success" @click="confermaChiudiImballo()"
                >conferma</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialog_dettaglio_articolo" width="60%">
          <v-card>
            <v-toolbar
              dark
              color="#6a74ad"
              style="border-radius: 5px 5px 0px 0px"
              class="elevation-0"
              ><v-toolbar-title>Articoli nei Lotti</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn light @click="close_dialog_dettaglio_articolo()"
                ><v-icon color="red">mdi-close</v-icon></v-btn
              >
            </v-toolbar>

              <PickingArticoloLotti 
                  :DA= this.provenienza
                  :COD_ARTICOLO = this.articolo_selezionato 
                  :CAMBIO_STATO = this.CAMBIO_STATO
                  :ARRAY_ID_DOC_DETTAGLIO = this.ARRAY_ID_DOC_DETTAGLIO 
                  :ID_ARTICOLO = this.id_articolo_selezionato
                  :NUM_TOT_ARTICOLI = this.tot_articoli_da_spedire
                  :ID_CONTENITORE = this.id_contenitore
                  :QTA_CONTENITORE = this.qta_contenitore
                  v-on:imballo="valori_di_ritorno_picking"
              /> 

            <!--      
            <v-card-text>
              <v-container>
                <v-row style="text-align: initial">
                  <v-col cols="3">
                    <span
                      >Codice articolo: <b>{{ codice_articolo_selected }}</b></span
                    >
                    <br />
                    <span
                      >Quantità da prelevare:
                      <b>{{ qta_da_prelevare_selected }}</b></span
                    >
                    <br />
                    <span
                      >Codice contenitore:
                      <b>{{ cod_contenitore_selected }}</b></span
                    >
                    <br />
                    <span
                      >Qta. Contenitori per qta indicata:
                      <b>{{
                        qta_contenitori_x_quantita_indicata_selected
                      }}</b></span
                    >
                  </v-col>
                  <v-col cols="6"></v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-btn class="mr-3" dark color="primary"
                      ><v-icon>mdi-plus</v-icon>Aggiungi</v-btn
                    >
                    <v-btn class="mr-3" dark color="error"
                      ><v-icon>mdi-delete</v-icon>Rimuovi sel.</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pa-5">
                    <v-toolbar
                      dark
                      color="#3F51B5"
                      style="border-radius: 5px 5px 0px 0px"
                      class="elevation-0"
                      ><v-toolbar-title>Lotti</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table
                      :headers="headers_dettaglio_articolo"
                      :items="items_dettaglio_articolo"
                      class="elevation-3"
                    >
                      <template v-slot:item.selezione="{ item }">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="dettaglioArticolo(item)"
                            >
                              <v-icon color="success"
                                >mdi-check-outline &nbsp;&nbsp;&nbsp;&nbsp;</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Dettaglio articolo</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.contenitore_imballo="{ item }">
                        <v-checkbox
                          style="margin-left: 50px"
                          v-model="item.contenitore_imballo"
                        ></v-checkbox>
                      </template> </v-data-table
                  ></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" dark @click="dialog_dettaglio_articolo = false"
                >Annulla</v-btn
              >
              <v-btn color="success">Conferma</v-btn>
            </v-card-actions>

            -->
          </v-card>
        </v-dialog>



  </div>
</template>
<script>

import PickingArticoloLotti from "./ArticoloLottiPicking.vue";
export default {
  components: {
        PickingArticoloLotti,
  },
  data() {
    return {
      CAMBIO_STATO:0,
      provenienza: "Packaging",
      ARRAY_ID_DOC_DETTAGLIO:[],
      dialog_dettaglio_articolo_lotto: false,
      dialog_chiudi_imballo: false,
      dialog_gest_contenitori: false,

      ID_documento_dettaglio:null,

      articolo_selezionato: null,
      id_articolo_selezionato: null,
      tot_articoli_da_spedire:0,
      id_contenitore: null,
      qta_contenitore: null,
      array_contenitori: [],

      contenitore_selezionato: null,

      
      modalTab: "packaging",
      peso_imballo: null,
      lunghezza_imballo: null,
      altezza_imballo: null,
      larghezza_imballo: null,
      imballo_selezionato: null,
      imballi: [],
      dettaglio_articolo: false,
      codice_articolo_selected: null,
      qta_da_prelevare_selected: null,
      cod_contenitore_selected: null,
      qta_contenitori_x_quantita_indicata_selected: null,
      dialog_dettaglio_articolo: false,
      headers: [
        { text: "Cod. Articolo", value: "cod_articolo", align: "start", sortable: false},
        { text: "PMU", value: "pmu" },
        { text: "Articolo", value: "des_articolo" },
        { text: "Qta da Preparare", value: "quantita" },
        { text: "Qta Prelevata", value: "qta_pick" },
        { text: "Qta Residua", value: "qta_residuo" },
        { text: "Peso Tot Art.", value: "peso_tot_articoli" },
        { text: "Azione", value: 'contenitori'},        
        { text: "Cod. Contenitore", value: "cod_contenitore" },
        { text: "Qta X Contenitore", value: "qta_contenitore" },
        { text: "Num Contenitori", value: "qta_contenitori" },
        { text: "Seleziona", value: "seleziona" },
       // { text: "Qta Magazzino", value: "qta_magazzino" },
       // { text: "Cod cliente", value: "cod_cliente" },
       // { text: "Cod Contenitore", value: "cod_contenitore" },
       // { text: "Qta x Cod Contenitore", value: "qta_x_cod_contenitore" },
       // { text: "Num Contenitori", value: "num_contenitori" },
      ],
      articoli: [],
      articolo: { cod_articolo: '', pmu: '', articolo: '', quantita: '', peso_tot_articoli: ''},
     // articolo: { cod_articolo: '', pmu: '', articolo: '', qta_da_preparare: '', qta_magazzino: '', cod_cliente: '', cod_contenitore: '', qta_x_cod_contenitore: '', num_contenitori: ''},
      
      headers_imballo: [
        { text: "ID", value: "id_documento_packaging" },
        { text: "Cod. Articolo", value: "cod_articolo" },
        { text: "Des Articolo", value: "des_articolo" },
        { text: "Cod Lotto", value: "cod_lotto" },
        { text: "Quantità", value: "quantita" },
        { text: "PMU", value: "pmu" },
        { text: "Peso tot. Articoli", value: "peso_tot_articoli" },
        { text: "Cod.Contenitore", value: "cod_contenitore" },
        { text: "Q.ta X Contenitore", value: "qta_contenitore" },
        { text: "Num. Contenitori", value: "qta_contenitori" },
        { text: "Q.ta Prelevata", value: "qta_pick" },
        { text: "Elimina", value: "del" },
      ],
      articoli_imballo_first: [],
      articoli_imballo: [],
      selected: [],

      //Da cancellare da qui perchè fatto componente
      headers_dettaglio_articolo: [
        {
          text: "Lotto",
          value: "lotto",
        },
        {
          text: "Articolo",
          value: "cod_articolo",
        },
        {
          text: "PMU",
          value: "pmu",
        },
        {
          text: "Num pezzi",
          value: "num_pezzi",
        },
        {
          text: "Num contenitori",
          value: "num_contenitori",
        },
        {
          text: "Peso x Contenitore",
          value: "peso_x_contenitore",
        },
        {
          text: "Contenitore",
          value: "contenitore",
        },
        {
          text: "Imballo",
          value: "imballo",
        },
        {
          text: "Riferimento",
          value: "riferimento",
        },
        {
          text: "Contenitore/imballo",
          value: "contenitore_imballo",
        },
        {
          text: "Dettaglio",
          value: "selezione",
        },
      ],

      //Da cancellare da qui perchè fatto componente
      items_dettaglio_articolo: [],



      headers_dettaglio_articolo_articolo_tabella_1: [
        {
          text: "Lotto",
          value: "lotto",
        },
        {
          text: "Articolo",
          value: "articolo",
        },
        {
          text: "Quantita",
          value: "qta",
        },
        {
          text: "Des ubicazione",
          value: "des_ubicazione",
        },
        {
          text: "Des magazzino",
          value: "des_magazzino",
        },
        {
          text: "Esito controllo",
          value: "esito_controllo",
        },
        {
          text: "Esito quantità",
          value: "esito_quantita",
        },
      ],


      items_dettaglio_articolo_articolo_tabella_1: [],
      headers_dettaglio_articolo_articolo_tabella_2: [
        {
          text: "Lotto",
          value: "lotto",
        },
        {
          text: "Quantità",
          value: "qta",
        },
        {
          text: "Quantita giacenza",
          value: "qta_giacenza",
        },
        {
          text: "Quantita disponibile",
          value: "qta_disponibile",
        },
        {
          text: "Quantita in produzione",
          value: "qta_in_produzione",
        },
        {
          text: "Quantità packaging",
          value: "qta_packaging",
        },
      ],
      items_dettaglio_articolo_articolo_tabella_2: [],
      
    };
  },
 props: {
    id_anagrafica: Number,
    DESTINAZIONE: String,
    TRIGGER: Number,
  },
 watch: {
    id_anagrafica: {
      immediate: true,
      handler() {
        if (this.id_anagrafica != null) {
          this.getDettaglioOrdine();
        }
      }
    },
    TRIGGER: {
      immediate: true,
      handler() {
        if (this.id_anagrafica != null) {
          this.getDettaglioOrdine();
        }
      }
    },
    
  },
  methods: {

    getDettaglioOrdine() {
      let request = {
        controller: "Logistica",
        method: "POST",
        richiesta: {
          action: "getdettaglioordine",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_anagrafica: this.id_anagrafica,
          tipo: "",
          db_name: "geclink_cavallo",
        },
      };

      this.articoli = []
      this.articoli_imballo= []
      this.$store.dispatch("api", request).then((res) => {
      this.articoli = res.data.result.picking
      this.articoli_imballo = res.data.result.picked
      
      
      });
    },

    dettaglioArticolo(item) {
      this.dialog_dettaglio_articolo = true; //apre Dialog Articoli  e carica componente PickingArticoloLotti
      this.CAMBIO_STATO = Math.round(Math.random()*100); 
      this.articolo_selezionato = item.cod_articolo;
      this.id_articolo_selezionato = item.id_articolo
      this.id_contenitore = item.id_contenitore
      this.qta_contenitore = item.qta_contenitore
      
      let array_tot_articoli_da_spedire = this.alasql(
        "SELECT  SUM(qta_residuo) as quantita FROM ? where cod_articolo = '" + item.cod_articolo + "' and  qta_residuo > 0 ",
        [this.articoli]
        
      );
      this.tot_articoli_da_spedire = array_tot_articoli_da_spedire[0].quantita
      let array_id_documento_dettaglio = this.alasql(
        "SELECT  id_documento_dettaglio FROM ? where cod_articolo = '" + item.cod_articolo + "'",
        [this.articoli]
      );
      

      //this.tot_articoli_da_spedire = item.qta_residuo
      this.ARRAY_ID_DOC_DETTAGLIO = []
      this.ARRAY_ID_DOC_DETTAGLIO = array_id_documento_dettaglio
    },

    close_dialog_dettaglio_articolo(){
      this.dialog_dettaglio_articolo = false;
      this.CAMBIO_STATO = Math.round(Math.random()*100);
    },
    eliminaArticolo(item) {
      //var indice_articolo = this.articoli_imballo.indexOf(item);
      //console.log ("aaaaaaaa",indice_articolo,item.id_documento_packaging)
      let request = {
        controller: "Logistica",
        method: "PUT",
        richiesta: {
          action: "eliminaimballo",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_documento_packaging: item.id_documento_packaging,
          db_name: "geclink_cavallo",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
            this.getDettaglioOrdine()
        }
      });
      //da fare chiamata per eliminare artcolo
      //this.getDettaglioOrdine() aggiorno la pagina con le modifiche fatte dal backend
     // this.articoli.push(item);
      //this.articoli_imballo.splice(indice_articolo, 1);
    },    
    chiudiImballo() {
            
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getimballi",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_anagrafica: this.id_anagrafica,
          picking: this.selected,
          db_name: "geclink_cavallo",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          
          this.imballi = [];
          res.data.forEach((e) => {
            this.imballi.push(e);
          });
          
        this.imballo_selezionato = res.data[0].id_articolo
        this.peso_imballo = res.data[0].peso_lordo
        this.larghezza_imballo =  res.data[0].l
        this.altezza_imballo =  res.data[0].h
        this.lunghezza_imballo = res.data[0].p
        this.dialog_chiudi_imballo = true
        }
      });
    },

    gestione_contenitori(item) {
     //console.log(item)
      this.ID_documento_dettaglio =  item.id_documento_dettaglio
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcontenitori",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          id_anagrafica: this.id_anagrafica,
          db_name: "geclink_cavallo",
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          
          this.array_contenitori = [];
          res.data.forEach((e) => {
            this.array_contenitori.push(e);
          });
          
        this.contenitore_selezionato = res.data[0].id_articolo
        this.qta_contenitore = res.data[0].qta_contenitore
          
        this.dialog_gest_contenitori= true

        }
      
      });
      
    },

    //da cancellare
    dettaglio(item) {
      this.dialog_dettaglio_articolo = true;
      this.codice_articolo_selected = item.cod_articolo;
      this.qta_da_prelevare_selected = item.qta_da_preparare;
      this.cod_contenitore_selected = item.cod_contenitore;
      this.qta_contenitori_x_quantita_indicata_selected = item.num_contenitori;
      this.items_dettaglio_articolo = [];
      var obj = {
        cod_articolo: this.codice_articolo_selected,
        qta_da_preparare: this.qta_da_prelevare_selected,
        cod_contenitore: this.cod_contenitore_selected,
        num_contenitori: this.qta_contenitori_x_quantita_indicata_selected,
        contenitore_imballo: false,
      };
      this.items_dettaglio_articolo.push(obj);
    },
    //da cancellare
    copiaArticoli() {
      this.articoli_imballo = [];
      this.articoli_imballo_first.forEach((e) => {
        this.articoli_imballo.push(e);
        this.articoli.splice(e, 1);
      });
    },
  
    cambiaSelezione(){
        let Imb_selezionato = this.alasql(
            "SELECT * FROM ? WHERE id_articolo = " + this.imballo_selezionato ,
            [this.imballi]
        );
        
        this.peso_imballo = Imb_selezionato[0].peso_lordo
        this.larghezza_imballo =  Imb_selezionato[0].l
        this.altezza_imballo =  Imb_selezionato[0].h
        this.lunghezza_imballo = Imb_selezionato[0].p

    },

    cambiaSelezioneContenitore(){
        let Cont_selezionato = this.alasql(
            "SELECT * FROM ? WHERE id_articolo = " + this.contenitore_selezionato ,
            [this.array_contenitori]
        );
        
        this.qta_contenitore = Cont_selezionato[0].qta_contenitore

    },

    confermaChiudiImballo() {
      if (
        this.peso_imballo != null &&
        this.peso_imballo != "" &&
        this.imballo_selezionato != null &&
        this.larghezza_imballo != null &&
        this.larghezza_imballo != "" &&
        this.altezza_imballo != null &&
        this.altezza_imballo != "" &&
        this.lunghezza_imballo != null &&
        this.lunghezza_imballo != ""
      ) 
      {


        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "chiudiimballo",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.getItem("id"),
            id_imballo: this.imballo_selezionato,
            peso: this.peso_imballo,
            lunghezza: this.lunghezza_imballo,
            altezza: this.altezza_imballo,
            larghezza: this.larghezza_imballo,
            selezionati: this.selected,
            db_name: "geclink_cavallo",
          },
        };
        this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
            this.getDettaglioOrdine()
            this.$emit("changeTab", "pre_shipping");
        }
        
        });
      
        this.dialog_chiudi_imballo = false;
        this.getDettaglioOrdine()
       // this.$emit("changeTab", "pre_shipping");
      } else {
        this.$store.state.snackbar.stato = true;
        this.$store.state.snackbar.messaggio =
          "Compilare tutti i campi richiesti!";
        this.$store.state.snackbar.colore = "#E53935";
        this.$store.commit("close_snackbar");
      }
    },

    confermaCambioContenitore() {
      if (
        this.qta_contenitore != null &&
        this.qta_contenitore != "" &&
        this.contenitore_selezionato != null
      ) 
      {
        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "set_contenitore",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.getItem("id"),
            id_contenitore: this.contenitore_selezionato,
            qta_contenitore: this.qta_contenitore,
            id_documento_dettaglio: this.ID_documento_dettaglio,
            db_name: "geclink_cavallo",
          },
        };
        this.$store.dispatch("api", request).then((res) => {
          if (res.status == 200){
              this.getDettaglioOrdine()
          }
        });
      
        this.dialog_gest_contenitori = false;
        this.getDettaglioOrdine()

      } else {
        this.$store.state.snackbar.stato = true;
        this.$store.state.snackbar.messaggio =
          "Compilare tutti i campi richiesti!";
        this.$store.state.snackbar.colore = "#E53935";
        this.$store.commit("close_snackbar");
      }
    },

    valori_di_ritorno_picking(){
      this.getDettaglioOrdine() 
      this.dialog_dettaglio_articolo = false

    }
  },
};
</script>