<template>
  <div>
    <!-- Part program customizer Modal -->
    <v-dialog
      :hide-overlay="true"
      content-class="HideOverflow"
      v-model="dialogpartprogram"
      width="unset"
    >
      <!-- <PartProgramCustomizer @closeModal="closeModal"  :singleProgram="single_part_program" :key="single_part_program.id"/> -->
      <PartProgramSender
        tipo="articolo"
        :key="single_part_program.id"
        ref="PartProgramSender"
        @change="dialogpartprogram = false"
        @EncodedPartProgram="EncodedPartProgram($event)"
      />
    </v-dialog>

    <!--Macchina Modal to Add/Update Macchina -->
    <v-dialog persistent :hide-overlay="true" v-model="dialogMacchina" width="700">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Aggiungi Macchina </v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            :idForm="SOTTO_FORM"
            :idOggetto="MACCHINA_BUTTON_ARRAY[3]"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva"
            v-on:buttonClick="
              savePartProgram();
              salvaMacchina();
            "
          />
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialogMacchina = false" small>
                <v-icon v-on="on" v-bind="attrs" depressed color="error">
                  mdi-window-close</v-icon
                >
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row class="pa-0">
                <v-col cols="10" sm="10" md="10">
                  <v-autocomplete
                    v-model="id_macchina"
                    @change="
                      getPartProgramsMacchina();
                      resetAssociatedPartProgram();
                    "
                    :items="macchinaList"
                    label="Macchina"
                    :item-text="(item) => `${item.des_macchina} - ${item.cod_macchina}`"
                    placeholder="Des. Macchina"
                    item-value="id_macchina"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <div class="ml-1">
                    <span
                      style="
                        font-size: 11px;
                        color: rgba(0, 0, 0, 0.6);
                        margin-left: -0.2rem;
                        padding-left: -0.2rem;
                      "
                      >Predefinito</span
                    >
                    <v-switch
                      v-model="macchina_predefinito"
                      style="margin-top: -0.3rem !important"
                      class="mt-n1 ml-4"
                      :false-value="0"
                      :true-value="1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col class="py-0" sm="3" cols="3" md="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_TEMPO_SETUP"
                    label_input="Tempo Setup"
                    palceholder_input="Tempo Setup"
                    :vmodel="tempo_setup"
                    v-on:changeText="(val) => (this.tempo_setup = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" md="2" cols="3" sm="3">
                  <v-autocomplete
                    v-model="des_um_tempo_setup"
                    :items="unita_misura_lead_time"
                    label="UM Tempo Setup"
                    item-text="des"
                    placeholder="UM Tempo"
                    item-value="des"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="2" sm="3" cols="3">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_PEZZI_MIN"
                    label_input="Min Pezzi"
                    palceholder_input="Min Pezzi"
                    :vmodel="pezzi_min"
                    v-on:changeText="(val) => (this.pezzi_min = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" md="2" sm="3" cols="3">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_PEZZI_MAX"
                    label_input="Max Pezzi"
                    ref="pezzi_max"
                    palceholder_input="Max Pezzi"
                    :vmodel="pezzi_max"
                    :rules_input="[rules.min]"
                    v-on:changeText="(val) => (this.pezzi_max = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
                <v-col class="py-0" md="2" cols="2" sm="2">
                  <v-autocomplete
                    v-model="id_um"
                    :items="unita_misura_mod_list"
                    label="UM quantità"
                    item-text="des"
                    placeholder="UM quantità"
                    item-value="des"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="2" cols="3" sm="2">
                  <v-autocomplete
                    v-model="des_um_produzione"
                    :items="unita_misura_lead_time"
                    label="UM tempo."
                    item-text="des"
                    placeholder="UM tempo"
                    item-value="des"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="4" v-if="commessa_form" class="py-0">
                  <v-autocomplete
                    v-model="id_macchina_program"
                    :items="part_program_list"
                    @change="
                      getpartprogram(id_macchina_program);
                      resetAssociatedPartProgram();
                    "
                    label="Part Program Macchina "
                    item-text="name"
                    placeholder="Part Program Macchina"
                    item-value="id"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="4" v-if="!commessa_form" class="py-0">
                  <v-autocomplete
                    v-model="id_macchina_program"
                    :items="part_program_list"
                    @change="
                      getpartprogram(id_macchina_program);
                      resetAssociatedPartProgram();
                    "
                    label="Part Program Macchina "
                    item-text="name"
                    placeholder="Part Program Macchina"
                    item-value="id"
                    required
                  >
                  </v-autocomplete>
                  <!--<TextField
										idForm="CICLOFASI"
										idOggetto="CF_DES_PROGRAMMA"
										label_input="Programma"
										palceholder_input="Programma"
										:vmodel="des_programma"
										v-on:changeText="(val) => (this.des_programma = val)"
									/>-->
                </v-col>

                <v-col v-show="id_macchina_program != null" md="2">
                  <Button
                    colore_icona="success"
                    mdi_icona="mdi-cog"
                    testo_tooltip="Part Program Config"
                    v-on:buttonClick="openPartProgramSender"
                  />
                </v-col>
                <v-col md="3" class="py-0">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_DES_UTENSILE"
                    label_input="Utensile"
                    palceholder_input="Utensile"
                    :vmodel="des_utensile"
                    v-on:changeText="(val) => (this.des_utensile = val)"
                  />
                </v-col>
                <v-col md="3" class="py-0">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_FATTORE_UTILIZZO"
                    label_input="Fattore Orario Utilizzo"
                    palceholder_input="Fattore Orario Utilizzo"
                    :vmodel="fattore_utilizzo"
                    v-on:changeText="(val) => (this.fattore_utilizzo = val)"
                    :min_input="0"
                    type_input="number"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!--Dialog Istruzioni di Lavoro -->
    <v-dialog persistent :hide-overlay="true" v-model="dialogIstruzioniLavoro" width="1200">
      <v-card>
        <v-toolbar dense class="indigo darken-1" primary-title>
          <v-toolbar-title class="white--text">Istruzioni Lavoro </v-toolbar-title>
          <v-spacer></v-spacer>
          <Button
            colore_icona="success"
            :idForm="SOTTO_FORM"
            :idOggetto="MACCHINA_BUTTON_ARRAY[3]"
            mdi_icona="mdi-floppy"
            testo_tooltip="Salva Istruzioni Lavoro"
            v-on:buttonClick="
              salvaIstruzioniLavoroMacchima();
            "
          />
          <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="chiudi_istruzioni" small>
                  <v-icon v-on="on" v-bind="attrs" depressed color="error">
                    mdi-window-close</v-icon
                  >
                </v-btn>
              </template>
              <span>Chiudi</span>
            </v-tooltip>
          </v-toolbar>
        <v-card-text>
          
              <v-row class="pa-5">
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete
                    v-model="id_macchina"
                    @change="
                      getPartProgramsMacchina();
                      resetAssociatedPartProgram();
                    "
                    :items="macchinaList"
                    label="Macchina"
                    :item-text="(item) => `${item.des_macchina} - ${item.cod_macchina}`"
                    placeholder="Des. Macchina"
                    item-value="id_macchina"
                    required
                    readonly
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Articolo"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Fase Lavorazione"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Tipo Imballo"
                  >
                  </v-text-field>
                </v-col>                                


                <v-col class="py-0" sm="3" cols="3" md="2">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_TEMPO_SETUP"
                    label_input="Tempo Setup"
                    palceholder_input="Tempo Setup"
                    :vmodel="tempo_setup"
                    v-on:changeText="(val) => (this.tempo_setup = val)"
                    :min_input="0"
                    type_input="number"
                    readonly
                  />
                </v-col>
                <v-col class="py-0" md="2" cols="3" sm="3">
                  <v-autocomplete
                    v-model="des_um_tempo_setup"
                    :items="unita_misura_lead_time"
                    label="UM Tempo Setup"
                    item-text="des"
                    placeholder="UM Tempo"
                    item-value="des"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="2" sm="3" cols="3">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_PEZZI_MIN"
                    label_input="Min Pezzi"
                    palceholder_input="Min Pezzi"
                    :vmodel="pezzi_min"
                    v-on:changeText="(val) => (this.pezzi_min = val)"
                    :min_input="0"
                    type_input="number"
                    readonly
                  />
                </v-col>
                <v-col class="py-0" md="2" sm="3" cols="3">
                  <TextField
                    idForm="CICLOFASI"
                    idOggetto="CF_PEZZI_MAX"
                    label_input="Max Pezzi"
                    ref="pezzi_max"
                    palceholder_input="Max Pezzi"
                    :vmodel="pezzi_max"
                    :rules_input="[rules.min]"
                    v-on:changeText="(val) => (this.pezzi_max = val)"
                    :min_input="0"
                    type_input="number"
                    readonly
                  />
                </v-col>
                <v-col class="py-0" md="2" cols="2" sm="2">
                  <v-autocomplete
                    v-model="id_um"
                    :items="unita_misura_mod_list"
                    label="UM quantità"
                    item-text="des"
                    placeholder="UM quantità"
                    item-value="des"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="py-0" md="2" cols="3" sm="2">
                  <v-autocomplete
                    v-model="des_um_produzione"
                    :items="unita_misura_lead_time"
                    label="UM tempo."
                    item-text="des"
                    placeholder="UM tempo"
                    item-value="des"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-divider/>
        
          <!-- datatable Itruzioni di Lavoro -->
          <v-col cols="12" sm="12" md="12">
              <template>
                  <v-data-table
                      :headers="headers_codici"
                      :items="istruzioni"
                      hide-default-footer
                      :items-per-page="12000"
                      class="elevation-1"
                                          >
                      <template v-slot:top>
                          <v-toolbar flat>
                              <v-app-bar-nav-icon></v-app-bar-nav-icon>
                              <v-toolbar-title>Istruzioni Lavoro</v-toolbar-title>
                              <v-divider
                              class="mx-4"
                              inset
                              vertical
                              ></v-divider>
                              <v-spacer></v-spacer>

                              <template>
                                <v-sheet class="pa-5">
                                  <v-switch
                                    v-model="switch_fresa"
                                    inset
                                    :label="`Fresa: ${switch_fresa.toString()}`"
                                    @change="gestione_header"

                                  ></v-switch>
                                </v-sheet>
                              </template>
                              <v-spacer></v-spacer>
                              <v-text-field
                                v-model="des_lavoro"
                                label="Descrizione Lavoro"
                              >
                              </v-text-field>
                              <v-spacer></v-spacer>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-btn outlined small v-bind="attrs" v-on="on" @click="nuova_istruzione_lavoro">
                                              <v-icon color="blue"
                                              >mdi mdi-file-plus-outline
                                              </v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Nuova Istruzione di Lavoro</span>
                                  </v-tooltip>
                          </v-toolbar>
                      </template>     
                      <template  v-slot:[`item.fr_etichetta`]="{ item }" >                                           
                          <v-text-field
                              v-model="item.fr_etichetta"
                          ></v-text-field>        
                      </template>
                      <template  v-slot:[`item.fr_valore`]="{ item }" > 
                          <v-text-field
                              v-model="item.fr_valore"
                          ></v-text-field>        
                      </template>

                      <template  v-slot:[`item.mon`]="{ item }" > 
                          <v-text-field
                              v-model="item.mon"
                          ></v-text-field>        
                      </template>
                      <template  v-slot:[`item.grup`]="{ item }" > 
                          <v-text-field
                              v-model="item.grup"
                          ></v-text-field>        
                      </template>
                      <template  v-slot:[`item.n_car`]="{ item }" > 
                          <v-text-field
                              v-model="item.n_car"
                          ></v-text-field>        
                      </template>
                      <template  v-slot:[`item.ut_corr`]="{ item }" > 
                          <v-text-field
                              v-model="item.ut_corr"
                          ></v-text-field>        
                      </template> 
                      <template  v-slot:[`item.lavorazione`]="{ item }" >                    
                          <v-select
                          :items="tipo_lavorazione"
                          item-text='descrizione'
                          item-value='id'
                          label="Tipo Lavorazione"
                          v-model="item.id_tipo_lavorazione"
                          ></v-select>      
                      </template>
                      <template  v-slot:[`item.des_lavorazione`]="{ item }" > 
                          <v-text-field
                              v-model="item.inserto"
                          ></v-text-field>        
                      </template>
                      <template  v-slot:[`item.inserto`]="{ item }" > 
                          <v-text-field
                              v-model="item.inserto"
                          ></v-text-field>        
                      </template>
                      <template  v-slot:[`item.durata`]="{ item }" > 
                          <v-text-field
                              v-model="item.durata"
                          ></v-text-field>        
                      </template> 
                      <template  v-slot:[`item.actions`]="{ item }" >
                          <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-btn outlined small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                      <v-icon color="purple"
                                      >mdi-delete-outline
                                      </v-icon>
                                  </v-btn>
                              </template>
                              <span>Elimina Associzione </span>
                          </v-tooltip>
                      </template>      

                      <template v-slot:no-data>
                          <v-btn
                              color="primary"
                              @click="nuova_istruzione_lavoro"
                          >
                              Nuovo
                          </v-btn>
                      </template>                                                                                                  

                  </v-data-table>
              </template>       
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              outlined
              name="input-7-4"
              label="Note per Lavoro"
              v-model="des_nota_lavoro"
            ></v-textarea>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Main Table of Fase Standard -->
    <v-toolbar dense class="indigo darken-1 headline" primary-title uppercase>
      <v-toolbar-title class="white--text">Macchina</v-toolbar-title>
      <v-spacer></v-spacer>
      <Button v-if="!Istruzioni_Lavoro"
        colore_icona="primary"
        :idForm="SOTTO_FORM"
        :idOggetto="MACCHINA_BUTTON_ARRAY[0]"
        mdi_icona="mdi-plus"
        testo_tooltip="Aggiungi Macchina"
        v-on:buttonClick="
          dialogMacchina = true;
          clearFields();
        "
      />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      :loading="loadingMacchina"
      :headers="MacchinaHeaders"
      :items="this.articolo_ciclo_fasi_macchina"
    >
      <v-progress-linear
        v-show="progressBarMacchina"
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>

      <template v-if="!Istruzioni_Lavoro" v-slot:[`item.edit`]="{ item }">
        <Button
          style="float: left"
          colore_icona="primary"
          :idForm="SOTTO_FORM"
          :idOggetto="MACCHINA_BUTTON_ARRAY[1]"
          mdi_icona="mdi-pencil"
          testo_tooltip="Aggiornare"
          v-on:buttonClick="editciclimacchina(item)"
        />
      </template>
      <template  v-slot:[`item.istruzioni`]="{ item }" >
          <Button
          style="float: left"
          colore_icona="green"
          :idForm="SOTTO_FORM"
          :idOggetto="aa"
          mdi_icona="mdi mdi-information-variant"
          testo_tooltip="Istruzioni Lavoro"
          v-on:buttonClick="istruzionilavoro(item)"
        />

      </template>
      <template    v-slot:[`item.predefinito`]="{ item }">
        <v-checkbox  v-if="!Istruzioni_Lavoro"
          v-model="item.predefinito"
          :value="item.predefinito"
          @click="changeMacchinaPredefinito(item)"
        >
        </v-checkbox>
        <v-checkbox  v-if="Istruzioni_Lavoro"
          v-model="item.predefinito"
          :value="item.predefinito"
          readonly
        >
        </v-checkbox>
      </template>
      <template v-if="!Istruzioni_Lavoro" v-slot:[`item.actions`]="{ item }">
        <Button
          colore_icona="error"
          :idForm="SOTTO_FORM"
          :idOggetto="MACCHINA_BUTTON_ARRAY[2]"
          mdi_icona="mdi-window-close"
          testo_tooltip="Cancellare"
          v-on:buttonClick="deleteMacchina(item)"
        />
      </template>
    </v-data-table>

    <!-- In order to show All the Alerts -->
    <v-snackbar
      content-class="snackbar"
      :color="color"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :size="64">{{ mainIcon }}</v-icon>
      <h2 class="ml-16">{{ snackbar_text }}</h2>
      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="margin-top: -6rem; float: right !important; margin-right: -1rem !important"
        color="white"
      >
        <v-icon>mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import Button from "@/components/TOOLS/FormComponentPermissions/Button.vue";

import TextField from "@/components/TOOLS/FormComponentPermissions/TextField.vue";
import moment from "moment";
// import PartProgramCustomizer from './Protocols/PartProgramCustomizer.vue';
import PartProgramSender from "../../../components/PRODUCTION/Impostazioni/PartProgramconfigurator.vue";
export default {
  props: {
    Istruzioni_Lavoro: {
      type: Boolean,
      default: false,
    },
    commessa_form: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
    },
    ID_ARTICOLO: {
      type: Number,
    },
    ID_CICLO_FASI: {
      type: Number,
    },
    id_ciclostd_dettaglio: {
      type: Number,
    },
    id_commessa_dettaglio: {
      type: Number,
    },
    MACCHINA_BUTTON_ARRAY: {
      type: Array,
    },
    FORM_ID: {
      type: String,
    },
    SOTTO_FORM: {
      type: String,
    },
    commessa_data: {
      type: Object,
    },
  },

  components: {
    Button,
    TextField,
    // PartProgramCustomizer,
    PartProgramSender,
  },
  data() {
    return {
      rules: {
        min: (value) => {
          if (this.pezzi_min != null) {
            if (this.pezzi_min != null && value != null) {
              return parseInt(value) > parseInt(this.pezzi_min);
            } else return false;
          } else return true;
        },
      },

      // Macchina Variables
      associatePartPrgoram: {},
      // variable to keep the original part program
      localassociatedPartProgram: {},
      id_macchina_program: -1,
      macchina_predefinito: false,
      dialogpartprogram: false,
      single_part_program: {},
      articolo_ciclo_fasi_macchina: [],
      singleMacchina: {},
      deletedMacchina: [],
      des_um_produzione: "",
      progressBarMacchina: false,
      loadingMacchina: false,
      id_macchina: "",
      macchinaList: [],
      idciclo_fasi_macchina: -1,
      id_ciclostd_fasi_macchina: -1,
      id_temp: -1,
      tempo_setup: 0,
      um_tempo: "",
      items_part_program: [],
      unita_misura_lead_time: [],
      unita_misura_mod_list: [],
      part_program_list: [
        {
          name: "Prova 1",
          id: 1,
          steps: [
            {
              symbol: "{0}",
              name: "Parametro #1",
              default_value: false,
              type: 1,
              id: "1-1-1",
              access_type: 0,
              boundaries: {
                min_value: null,
                max_value: null,
                hard_min_value: null,
                hard_max_value: null,
              },
            },
            {
              name: "Passaggio #1",
              access_type: 1,
              type: 2,
              id: "1-1",
              default_value: "20",
              boundaries: {
                min_value: "5",
                max_value: "15",
                hard_min_value: "0",
                hard_max_value: "20",
              },
            },
          ],
        },
        {
          name: "Prova 2",
          id: 2,
          steps: [
            {
              symbol: "{0}",
              name: "Parametro #3",
              default_value: false,
              type: 1,
              id: "1-1-1",
              access_type: 0,
              boundaries: {
                min_value: null,
                max_value: null,
                hard_min_value: null,
                hard_max_value: null,
              },
            },
            {
              name: "Passaggio #4",
              access_type: 1,
              type: 2,
              id: "1-1",
              default_value: "40",
              boundaries: {
                min_value: "5",
                max_value: "35",
                hard_min_value: "0",
                hard_max_value: "50",
              },
            },
          ],
        },
      ],
      id_um_tempo_setup: "",
      des_um_tempo_setup: "",
      id_um: "Pezzi",
      des_um_pezzi: "",
      pezzi_min: 0,
      pezzi_max: 1,
      des_programma: "",
      des_utensile: "",
      fattore_utilizzo: "",
      des_macchina: "",
      dialogMacchina: false,
      dialogIstruzioniLavoro: false,
      overlay: false,
      id_articolo: this.ID_ARTICOLO ? this.ID_ARTICOLO : -1,
      validita: 1,
      // Snackbar Variables
      color: "",
      snackbar: false,
      mainIcon: "",
      snackbar_icon: "",
      snackbar_text: "",
      // Header of macchina table
      MacchinaHeaders: [
        {
          value: "edit",
          sortable: false,
        },
        {
          value: "istruzioni",
          sortable: false,
        },
        {
          text: "Predefinito",
          value: "predefinito",
          sortable: false,
        },
        {
          text: "Macchina",
          value: "des_macchina",
          sortable: false,
        },
        {
          text: "Cod. Macchina",
          value: "cod_macchina",
          sortable: false,
        },
        {
          text: "Tempo Setup",
          value: "tempo_setup",
          sortable: false,
        },
        {
          text: "UM Tempo",
          value: "des_um_tempo_setup",
          sortable: false,
        },
        {
          text: "UM Produzione",
          value: "des_um_produzione",
          sortable: false,
        },
        {
          text: "Min Pezzi",
          value: "pezzi_min",
          sortable: false,
        },
        {
          text: "Max Pezzi",
          value: "pezzi_max",
          sortable: false,
        },
        {
          text: "Unità Misura",
          value: "des_um_pezzi",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],

      headers_codici: [
          { text: 'MON', value: 'mon' , sortable: false },
          { text: 'GRUP', value: 'grup' , sortable: false },
          { text: 'N CAR', value: 'n_car' , sortable: false },
          { text: 'UT. CORR.', value: 'ut_corr' , sortable: false },
          { text: 'TIPO LAVORAZIONE', value: 'lavorazione' , sortable: false, width: "20%" },
          { text: 'DES LAVORAZIONE', value: 'des_lavorazione' , sortable: false, width: "30%"},
          { text: 'INSERTO', value: 'inserto' , sortable: false },
          { text: 'DURATA', value: 'durata' , sortable: false },
          { text: '', value: 'actions',  width: "8%", sortable: false },
      ],                
      istruzioni:[],

      tipo_lavorazione: [
        {
          id: 0,
          descrizione: '',
        },
        {
          id: 1,
          descrizione: 'Lavorazione Unica',
        },
        {
          id: 2,
          descrizione: 'Lavorazione DX',
        },
        {
          id: 3,
          descrizione: 'Lavorazione SX',
        },          
      ],  
      des_lavoro: '',
      des_nota_lavoro: '',
      switch_fresa: false,
      

    };
  },
  methods: {
    savePartProgram() {
      let request = {
        controller: "quickconnext",
        method: "PUT",
        richiesta: {
          action: "associatepartprogram",
          token: localStorage.getItem("user_token"),
          id_macchina: this.id_macchina,
          call_type: this.commessa_form ? 2 : 1,
          associated_part_program: [this.localassociatedPartProgram],
        },
      };
      this.$store.dispatch("api", request).then((res) => {
       console.log(res.data);
      });
    },
    EncodedPartProgram(ev) {
      this.dialogpartprogram = false;
      this.localassociatedPartProgram.dettaglio_part_program = ev;
    },
    openPartProgramSender() {
      this.dialogpartprogram = true;
      let that = this;
      this.$nextTick(function () {
        that.$refs.PartProgramSender.UpdateDialog(
          this.single_part_program,
          this.localassociatedPartProgram
        );
      });
    },
    resetAssociatedPartProgram() {
      this.localassociatedPartProgram = {
        id_articolo_dettaglio_4_0: this.commessa_form ? undefined : -1,
        id_commessa_dettaglio_4_0: !this.commessa_form ? undefined : -1,
        id_ciclo_fasi_macchina: this.singleMacchina.idciclo_fasi_macchina,
        dettaglio_part_program: null,
        validita: 1,
      };
      console.log(this.localassociatedPartProgram);
    },
    //  Get associated Part Program
    getassociatedPartPrograms(id) {
      let request = {
        controller: "quickConnext",
        method: "POST",
        richiesta: {
          action: "getassociatedpartprogram",
          token: localStorage.getItem("user_token"),
          id_ciclo_fasi_macchina: id,
          call_type: this.commessa_form ? 2 : 1,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.data.associated_part_program == null) {
          console.log("id if no assicuated pp", id);

          this.associatePartPrgoram = {
            id_articolo_dettaglio_4_0: this.commessa_form ? undefined : -1,
            id_commessa_dettaglio_4_0: !this.commessa_form ? undefined : -1,
            id_ciclo_fasi_macchina: id,
            dettaglio_part_program: null,
            validita: 1,
          };
        } else {
          this.associatePartPrgoram = res.data.associated_part_program;
        }
        try {
          this.single_part_program = JSON.parse(
            decodeURIComponent(
              escape(atob(this.associatePartPrgoram.dettaglio_part_program))
            )
          );
        } catch (error) {
          this.single_part_program = {};
        }
        Object.assign(this.localassociatedPartProgram, this.associatePartPrgoram);
        this.id_macchina_program = this.single_part_program.id;
        console.log(this.associatePartPrgoram);
      });
    },



    // To close Part program Customizer Modal
    closeModal() {
      this.dialogpartprogram = false;
    },
    // getting the selected part program var
    getpartprogram(id) {
      console.log(`id`, id);

      this.single_part_program = null;
      this.part_program_list.map((it) => {
        console.log(id);
        if (it.id == id) {
          this.single_part_program = it;
          this.console.log(`this.single_part_program`, this.single_part_program);
        }
      });
    },
    // in order to deselected the previous predefinito
    changeMacchinaPredefinito(item) {
      if (item.predefinito) {
        item.predefinito = 1;
      }
      let dataArray = this.articolo_ciclo_fasi_macchina.map((it) => {
        it.validita = 1;
        if (it === item) {
          if (item.predefinito === 1) {
            it.predefinito = 1;
          } else {
            it.predefinito = 0;
          }
        } else {
          it.predefinito = 0;
        }
        return it;
      });
      this.articolo_ciclo_fasi_macchina = dataArray;
    },
    // In order to delete single Fornitura
    deleteMacchina(item) {
      if (
        item.id_ciclostd_fasi_macchina > -1 ||
        item.idciclo_fasi_macchina > -1 ||
        item.id_ciclo_fasi_macchina
      ) {
        item.validita = 0;
        this.deletedMacchina.push(item);
        if (item.id_ciclo_fasi_macchina) {
          this.articolo_ciclo_fasi_macchina.splice(
            this.articolo_ciclo_fasi_macchina.indexOf(item.id_ciclo_fasi_macchina),
            1
          );
          this.$emit("delMacchina", this.deletedMacchina);
        }
        if (item.idciclo_fasi_macchina) {
          let index = this.articolo_ciclo_fasi_macchina.findIndex(
            (it) => it.idciclo_fasi_macchina == item.idciclo_fasi_macchina
          );
          this.articolo_ciclo_fasi_macchina.splice(index, 1);

          this.$emit("delMacchina", this.deletedMacchina);
          this.$emit("updateMacchina", this.articolo_ciclo_fasi_macchina);
        }
        if (item.id_ciclostd_fasi_macchina) {
          let index = this.articolo_ciclo_fasi_macchina.findIndex(
            (it) => it.id_ciclostd_fasi_macchina == item.id_ciclostd_fasi_macchina
          );
          this.articolo_ciclo_fasi_macchina.splice(index, 1);
          this.$emit("delMacchina", this.deletedMacchina);
          this.$emit("updateMacchina", this.articolo_ciclo_fasi_macchina);
        }
        item = {};
      } else if (item.id_ciclostd_fasi_macchina < 0 || item.idciclo_fasi_macchina < 0) {
        let i = this.articolo_ciclo_fasi_macchina.findIndex((it) => it === item);
        this.articolo_ciclo_fasi_macchina.splice(i, 1);
        item = {};
      }
    },
    // Clearing Macchina form fields
    clearFields() {
      this.id_macchina_program = null;
      this.id_macchina = "";
      this.id_um = "Pezzi";
      this.tempo_setup = "";
      this.pezzi_min = null;
      this.pezzi_max = null;
      this.des_um_tempo_setup = "";
      this.des_programma = "";
      this.des_utensile = "";
      this.fattore_utilizzo = null;
      this.des_um_produzione = "Ore";
      this.macchina_predefinito = false;
      this.idciclo_fasi_fornitori = -1;
      this.id_ciclostd_fasi_macchina = -1;
      this.singleMacchina = Object.assign({});
    },
    // In order to save new Macchina or update existing one
    salvaMacchina() {
      if (this.id_macchina === "") {
        if (this.codice == "") {
          this.snackbar = true;
          this.mainIcon = "mdi-alert-outline";
          this.color = "yellow accent-4";
          this.snackbar_text = "Inserire il Macchina prima di salvare.";
        }
        return;
      }
      if (
        (this.idciclo_fasi_macchina == -1 || this.id_ciclostd_fasi_macchina == -1) &&
        this.id_temp === -1
      ) {
        const data = {};
        data.idciclo_fasi_macchina = -1;
        data.id_ciclo_fasi_macchina = -1;
        data.date_ins = null;
        data.date_mod = null;
        data.id_ciclostd_fasi_macchina = -1;
        data.id_ciclostd_dettaglio = this.id_ciclostd_dettaglio
          ? this.id_ciclostd_dettaglio
          : -1;
        data.id_ciclo_fasi = this.ID_CICLO_FASI ? this.ID_CICLO_FASI : -1;
        data.id_macchina_program = this.id_macchina_program;
        data.id_temp = moment().unix();
        this.unita_misura_lead_time.map((ui) => {
          if (ui.des === this.des_um_tempo_setup) {
            data.id_um_tempo_setup = ui.id_tabella;
            data.des_um_tempo_setup = ui.des;
          }
        });

        this.unita_misura_lead_time.map((it) => {
          if (it.des === this.des_um_produzione) {
            data.id_um_produzione = it.id_tabella;
            data.des_um_produzione = it.des;
          }
        });
        if (!data.id_um_produzione) data.des_um_produzione = null;

        data.predefinito = this.macchina_predefinito;
        data.id_articolo = this.id_articolo;
        data.id_macchina = this.id_macchina;
        data.id_um = this.id_um;
        this.unita_misura_mod_list.map((it) => {
          if (it.des === data.id_um) {
            data.id_um = it.id_tabella;
            data.des_um_pezzi = it.des;
          }
        });
        if (!data.id_um) data.des_um_pezzi = null;

        data.tempo_setup = this.tempo_setup;
        data.pezzi_min = this.pezzi_min;
        data.pezzi_max = this.pezzi_max;
        if (data.pezzi_max < data.pezzi_min) {
          return;
        }
        data.des_programma = this.des_programma;
        data.des_utensile = this.des_utensile;
        data.fattore_utilizzo = this.fattore_utilizzo;
        data.validita = 1;
        this.macchinaList.map((ma) => {
          if (ma.id_macchina === data.id_macchina) {
            data.des_macchina = ma.des_macchina;
            data.cod_macchina = ma.cod_macchina;
          }
        });
        this.unita_misura_mod_list.map((it) => {
          if (it.id_tabella === data.id_um) {
            data.des_um_pezzi = it.des;
          }
        });
        console.log(`data`, data);
        this.articolo_ciclo_fasi_macchina.push(data);
        this.articolo_ciclo_fasi_macchina.sort((a, b) => {
          if (a.des_macchina < b.des_macchina) {
            return -1;
          }
          if (a.des_macchina > b.des_macchina) {
            return 1;
          }
          return 0;
        });
        if (data.predefinito) {
          this.changeMacchinaPredefinito(data);
        }
        this.articolo_ciclo_fasi_macchina.sort((a, b) => {
          if (a.des_macchina < b.des_macchina) {
            return -1;
          }
          if (a.des_macchina > b.des_macchina) {
            return 1;
          }
          return 0;
        });
        this.$emit("updateMacchina", this.articolo_ciclo_fasi_macchina);
        this.id_macchina = "";
        this.id_um = "";
        this.tempo_setup = "";
        this.pezzi_min = "";
        this.pezzi_max = "";
        this.des_programma = "";
        this.des_utensile = "";
        this.fattore_utilizzo = null;
        this.idciclo_fasi_macchina = "";
        this.dialogMacchina = false;
      }
      if (
        this.singleMacchina.idciclo_fasi_macchina > 0 ||
        this.singleMacchina.id_ciclo_fasi_macchina > 0 ||
        this.singleMacchina.id_ciclostd_fasi_macchina > -1 ||
        this.singleMacchina.id_temp > 0
      ) {
        const data = {};
        data.idciclo_fasi_macchina = this.singleMacchina.idciclo_fasi_macchina
          ? this.singleMacchina.idciclo_fasi_macchina
          : "";
        data.id_ciclo_fasi_macchina = this.singleMacchina.id_ciclo_fasi_macchina
          ? this.singleMacchina.id_ciclo_fasi_macchina
          : "";
        data.id_ciclostd_fasi_macchina = this.singleMacchina.id_ciclostd_fasi_macchina
          ? this.singleMacchina.id_ciclostd_fasi_macchina
          : "";
        data.id_ciclostd_dettaglio = this.id_ciclostd_dettaglio;
        data.id_ciclo_fasi = this.id_ciclo_fasi;
        data.id_articolo = this.id_articolo;
        data.id_macchina_program = this.id_macchina_program;
        data.id_macchina = this.id_macchina;
        data.id_um = this.id_um ? this.id_um : null;
        this.unita_misura_mod_list.map((it) => {
          if (it.des === data.id_um) {
            data.id_um = it.id_tabella;
            data.des_um_pezzi = it.des;
          }
        });
        if (!data.id_um) data.des_um_pezzi = null;

        data.id_temp = this.singleMacchina.id_temp
          ? this.singleMacchina.id_temp
          : moment().unix();
        data.tempo_setup = this.tempo_setup;
        data.predefinito = this.macchina_predefinito;
        data.id_um_tempo_setup = this.des_um_tempo_setup;
        this.unita_misura_lead_time.map((ui) => {
          if (ui.des === data.id_um_tempo_setup) {
            data.id_um_tempo_setup = ui.id_tabella;
            data.des_um_tempo_setup = ui.des;
          }
        });
        if (!data.id_um_tempo_setup) data.des_um_tempo_setup = null;

        this.unita_misura_lead_time.map((it) => {
          if (it.des === this.des_um_produzione) {
            data.id_um_produzione = it.id_tabella;
            data.des_um_produzione = it.des;
          }
        });
        if (!data.id_um_produzione) data.des_um_produzione = null;

        data.pezzi_min = this.pezzi_min;
        data.pezzi_max = this.pezzi_max;
        if (data.pezzi_max < data.pezzi_min) {
          return;
        }
        data.des_programma = this.des_programma;
        data.des_utensile = this.des_utensile;
        data.fattore_utilizzo = this.fattore_utilizzo;
        data.validita = 1;
        console.log(`data====>update`, data);
        this.macchinaList.map((ma) => {
          if (ma.id_macchina === data.id_macchina) {
            data.des_macchina = ma.des_macchina;
            data.cod_macchina = ma.cod_macchina;
          }
        });

        if (this.singleMacchina.idciclo_fasi_macchina > -1) {
          const i = this.articolo_ciclo_fasi_macchina.findIndex(
            (mi) => mi.idciclo_fasi_macchina === this.idciclo_fasi_macchina
          );

          this.articolo_ciclo_fasi_macchina.splice(i, 1, data);
          if (data.predefinito) {
            this.changeMacchinaPredefinito(data);
          }
          this.articolo_ciclo_fasi_macchina.sort((a, b) => {
            if (a.des_macchina < b.des_macchina) {
              return -1;
            }
            if (a.des_macchina > b.des_macchina) {
              return 1;
            }
            return 0;
          });
          this.$emit("updateMacchina", this.articolo_ciclo_fasi_macchina);
        }
        if (this.singleMacchina.id_temp > -1) {
          let index = this.articolo_ciclo_fasi_macchina.findIndex(
            (ma) => ma.id_temp == this.singleMacchina.id_temp
          );
          this.articolo_ciclo_fasi_macchina.splice(index, 1, data);
          if (data.predefinito) {
            this.changeMacchinaPredefinito(data);
          }
          this.articolo_ciclo_fasi_macchina.sort((a, b) => {
            if (a.des_macchina < b.des_macchina) {
              return -1;
            }
            if (a.des_macchina > b.des_macchina) {
              return 1;
            }
            return 0;
          });
          this.$emit("updateMacchina", this.articolo_ciclo_fasi_macchina);
        }

        this.idciclo_fasi_macchina = -1;
        this.id_macchina = "";
        this.id_um = "";
        this.tempo_setup = "";
        this.pezzi_min = "";
        this.pezzi_max = "";
        this.des_programma = "";
        this.des_utensile = "";
        this.fattore_utilizzo = null;
        this.dialogMacchina = false;
        this.singleMacchina = Object.assign({});
        this.id_temp = -1;
      }
    },



    // Getting single Macchina Detail for Update
    editciclimacchina(item) {
      this.singleMacchina = item;

      // if (this.commessa_data) {
      //   if (this.commessa_data.programmi_macchina) {
      //     this.part_program_list = this.part_program_list.filter(
      //       (it) => it.id_macchina === this.singleMacchina.id_macchina
      //     );
      //   } else {
      //     this.commessa_data.programmi_macchina = [];
      //     this.part_program_list = [];
      //   }
      // }

      this.id_macchina_program = item.id_macchina_program;
      this.id_ciclo_fasi = item.id_ciclo_fasi;
      this.id_articolo = item.id_articolo;

      this.id_macchina = item.id_macchina;
      this.macchina_predefinito = item.predefinito;
      this.id_um = item.des_um_pezzi;
      this.id_um_tempo_setup = item.id_um_tempo_setup;
      this.des_um_tempo_setup = item.des_um_tempo_setup;
      this.tempo_setup = item.tempo_setup;
      this.pezzi_min = item.pezzi_min;
      this.pezzi_max = item.pezzi_max;
      this.des_programma = item.des_programma;
      this.des_utensile = item.des_utensile;
      this.fattore_utilizzo = item.fattore_utilizzo;
      this.des_um_produzione = item.des_um_produzione;
      this.idciclo_fasi_macchina = item.idciclo_fasi_macchina;
      this.id_temp = item.id_temp ? item.id_temp : moment().unix();
      this.single_part_program = this.part_program_list[0];
      this.id_macchina_program = 1;
      console.log(item);
      this.getassociatedPartPrograms(item.idciclo_fasi_macchina);

      this.dialogMacchina = true;
    },
    // Getting the single ciclo value
    getarticolicicloMacchina() {
      this.loadingMacchina = true;
      this.progressBarMacchina = true;
      // IN order to get Ciclo fornitori list List to p
      let getarticoliciclofornitori = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoliciclomacchina",
          token: localStorage.getItem("user_token"),
          id_ciclo_fasi: this.ID_CICLO_FASI,
          id_articolo: this.ID_ARTICOLO,
        },
      };
      this.$store.dispatch("api", getarticoliciclofornitori).then((res) => {
        this.loadingMacchina = false;
        this.progressBarMacchina = false;
        this.articolo_ciclo_fasi_macchina = res.data;
        this.$emit("updateMacchina", this.articolo_ciclo_fasi_macchina);
      });
    },
    getPartProgramsMacchina() {
      let request = {
        controller: "quickConnext",
        method: "POST",
        richiesta: {
          action: "getmachineprogramparameters",
          token: localStorage.getItem("user_token"),
          id_macchina: this.id_macchina,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.part_program_list = res.data;
      });
    },

    //Sezione Istruzioni di Lavoro

    getIstruzioniLavoro(id) {
      let request = {
        controller: "quickConnext",
        method: "POST",
        richiesta: {
          action: "getIstruzioniLavoro",
          token: localStorage.getItem("user_token"),
          id_ciclo_fasi_macchina: id,
        },
      };
      console.log("Request:",request)
      /*
      this.$store.dispatch("api", request).then((res) => {
        this.istruzioni = res.data.istruzioni
      });
      */
      
      },

    istruzionilavoro(item) {
      debugger
      this.dialogIstruzioniLavoro = false;
      this.singleMacchina = item;
      this.id_macchina_program = item.id_macchina_program;
      this.id_ciclo_fasi = item.id_ciclo_fasi;
      this.id_articolo = item.id_articolo;
      this.id_macchina = item.id_macchina;
      this.macchina_predefinito = item.predefinito;
      this.id_um = item.des_um_pezzi;
      this.id_um_tempo_setup = item.id_um_tempo_setup;
      this.des_um_tempo_setup = item.des_um_tempo_setup;
      this.tempo_setup = item.tempo_setup;
      this.pezzi_min = item.pezzi_min;
      this.pezzi_max = item.pezzi_max;
      this.des_programma = item.des_programma;
      this.des_utensile = item.des_utensile;
      this.fattore_utilizzo = item.fattore_utilizzo;
      this.des_um_produzione = item.des_um_produzione;
      this.idciclo_fasi_macchina = item.idciclo_fasi_macchina;
      this.id_temp = item.id_temp ? item.id_temp : moment().unix();
      this.single_part_program = this.part_program_list[0];
      this.id_macchina_program = 1;

      //this.getIstruzioniLavoro(item.idciclo_fasi_macchina); 

      this.dialogIstruzioniLavoro = true;
    },

    nuova_istruzione_lavoro(){
      this.istruzioni.push ({  
            id_istruzione : -1 ,
            fr_etichetta: "",
            fr_valore: "",
            mon: "",
            grup: "",
            n_car: "",
            ut_corr: "",
            lavorazione: "",
            des_lavorazione: "",
            inserto: "",
            durata: "",
          });
    },  
    deleteItem(associazione) {
        let index = this.istruzioni.findIndex(item => item.id === associazione.id)
        this.istruzioni.splice(index, 1)
    },    

    salvaIstruzioniLavoroMacchima(){
                let request = {
                    controller: "Quello_che_Vuoi",
                    method: "PUT",
                    richiesta: {
                        action: "setIstruzioni",
                        idciclo_fasi_macchina: this.idciclo_fasi_macchina,
                        istruzioni: this.istruzioni,
                        Descrizione: this.des_lavoro,
                        Nota_Lavoro: this.des_nota_lavoro,
                        token: localStorage.getItem("user_token"),
                    },
                }
                console.log( request)
                this.$store.dispatch("api", request).then((res) => { 
                            if (res.statusText == "OK"){
                              this.dialogIstruzioniLavoro = false
                            }      

                    });
    },

    chiudi_istruzioni(){
      this.dialogIstruzioniLavoro = false
    },

    gestione_header(){

      if(!this.switch_fresa) { 
        this.headers_codici = [
          { text: 'MON', value: 'mon' , sortable: false },
          { text: 'GRUP', value: 'grup' , sortable: false },
          { text: 'N CAR', value: 'n_car' , sortable: false },
          { text: 'UT. CORR.', value: 'ut_corr' , sortable: false },
          { text: 'TIPO LAVORAZIONE', value: 'lavorazione' , sortable: false, width: "20%" },
          { text: 'DES LAVORAZIONE', value: 'des_lavorazione' , sortable: false, width: "30%"},
          { text: 'INSERTO', value: 'inserto' , sortable: false },
          { text: 'DURATA', value: 'durata' , sortable: false },
          { text: '', value: 'actions',  width: "8%", sortable: false },
        ]
      }else{
        this.headers_codici = [
          { text: 'Fresa Etichetta', value: 'fr_etichetta', sortable: false },
          { text: 'Fresa Valore', value: 'fr_valore', sortable: false },
          { text: '', value: 'actions',  width: "8%", sortable: false },
        ]

      }

    },




  },
  watch: {
    id_macchina: {
      immediate: true,
      handler() {
        if (this.id_macchina > 0 && this.id_macchina != "" && this.id_macchina != null) {
          this.getPartProgramsMacchina();
        }
      },
    },
    pezzi_min: {
      immediate: true,
      async handler() {
        if (this.$refs.form != undefined) {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      },
    },
  },
  mounted() {
    if (this.commessa_data) {
      this.part_program_list = this.commessa_data.programmi_macchina;
    }

    //   Getting list of Macchina
    if (this.ID_ARTICOLO) {
      this.getarticolicicloMacchina();
    } else {
      this.articolo_ciclo_fasi_macchina = this.data;
    }
    // Getting all the lista value for select fields
    this.progressBarCiclo = true;
    this.loadingCiclo = true;
    let request = {
      controller: "Magazzino",
      method: "POST",
      richiesta: {
        action: "gettabelle",
        token: localStorage.getItem("user_token"),
        tipo: "'PORTO','UNITA_MISURA','CAUSALE_TRASPORTO'",
        filter: "validita = 1",
      },
    };
    this.$store.dispatch("api", request).then((res) => {
      // Gestisco l'array PORTO che mi arriva dal BE
      this.modalita_list = res.data.PORTO;
      this.incoterms_list = res.data.CAUSALE_TRASPORTO;
      // Gestisco l'array UNITA_MISURA che mi arriva dal BE
      res.data.UNITA_MISURA.map((el) => {
        if (el.val_txt === "H") {
          this.unita_misura_lead_time.push(el);
        }
      });
      this.unita_misura_mod_list = res.data.UNITA_MISURA;
    });
    let manu_detail = {
      controller: "Manutenzione",
      method: "POST",
      richiesta: {
        action: "getlistamacchine",
        token: localStorage.getItem("user_token"),
      },
    };
    this.$store.dispatch("api", manu_detail).then((res) => {
      let macchinaData = res.data;
      macchinaData.filter((it) => {
        const updateobject = {};
        updateobject.id_macchina = it.id_macchina;
        updateobject.des_macchina = it.des_macchina;
        updateobject.cod_macchina = it.cod_macchina;
        this.macchinaList.push(updateobject);
        return this.macchinaList;
      });
    });

  },

};
</script>
<style lang="css">
.HideOverflow {
  overflow: hidden !important;
}
</style>
