<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar color="indigo" dense fixed>
      <v-toolbar-title class="white--text">
        Dichiarazioni del Capobarca
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Filtri (mostra commesse) -->
      <v-tooltip bottom v-if="filtri_visible === false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-5"
            @click="filtri_visible = true"
            small
            v-bind="attrs"
            v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter</v-icon></v-btn
          >
        </template>
        <span>Filtri</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5" @click="ripristina" small v-bind="attrs" v-on="on"
            ><v-icon color="purple lighten-2">mdi-filter-off</v-icon></v-btn
          >
        </template>
        <span>Ripristina</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goBack" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-keyboard-backspace</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text class="mt-2 pa-0"></v-card-text>
    <template>
      <div>
        <v-data-table
          :headers="headers"
          :items="commesse"
          item-key="nome"
          class="elevation-0"
          :search="search"
          height="80vh"
          fixed-header
        >
          <!-- Bottone di modifica delle dichiarazioni -->
          <template v-slot:[`item.modifica`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickModificaCommessa(item)"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary"> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Modifica Dichiarazione</span>
            </v-tooltip>
          </template>

          <!-- Colonna della data di ultima modifica -->
          <template v-slot:[`item.date_mod`]="{ item }">
            {{
              item.date_mod && item.date_mod.length > 0
                ? `${item.date_mod.substring(8, 10)}/${item.date_mod.substring(
                    5,
                    7
                  )}/${item.date_mod.substring(0, 4)}`
                : "N/A"
            }}
          </template>

          <!-- Colonna della checklist -->
          <template v-slot:[`item.checklist`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickChecklist(item)"
                  :disabled="
                    item.guscio === 0 ||
                    item.coperta === 0 ||
                    item.rif_prem === 0 ||
                    item.vassoio === 0 ||
                    item.calzata === 0 ||
                    item.magazzino === 0
                  "
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    :color="item.completata === 1 ? 'success' : 'primary'"
                  >
                    mdi-file-question
                  </v-icon>
                </v-btn>
              </template>
              <span>Rispondi al Questionario</span>
            </v-tooltip>
          </template>

          <!-- Colonna di eliminazione -->
          <template v-slot:[`item.elimina`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="clickElimina(item)"
                  small
                  v-bind="attrs"
                  v-if="
                    cod_profilo === 'SVIL' ||
                    cod_profilo === 'CNXBOX' ||
                    cod_profilo === 'Admin' ||
                    cod_profilo === 'manager'
                  "
                  v-on="on"
                >
                  <v-icon
                    color="error"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Rispondi al Questionario</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.rif_prem`]="{ item }">
            <v-icon color="success">
              {{ item.rif_prem === 1 ? "mdi-check-circle" : "" }}</v-icon
            >
          </template>

          <template v-slot:[`item.guscio`]="{ item }">
            <v-icon color="success">
              {{ item.guscio === 1 ? "mdi-check-circle" : "" }}</v-icon
            >
          </template>
          <template v-slot:[`item.coperta`]="{ item }">
            <v-icon color="success">
              {{ item.coperta === 1 ? "mdi-check-circle" : "" }}</v-icon
            >
          </template>
          <template v-slot:[`item.vassoio`]="{ item }">
            <v-icon color="success">
              {{ item.vassoio === 1 ? "mdi-check-circle" : "" }}</v-icon
            >
          </template>
          <template v-slot:[`item.calzata`]="{ item }">
            <v-icon color="success">
              {{ item.calzata === 1 ? "mdi-check-circle" : "" }}</v-icon
            >
          </template>
          <template v-slot:[`item.magazzino`]="{ item }">
            <v-icon color="success">
              {{ item.magazzino === 1 ? "mdi-check-circle" : "" }}</v-icon
            >
          </template>
          <template v-slot:top>
            <v-row align="center" justify="center" v-if="filtri_visible">
              <v-col cols="3">
                <v-autocomplete
                  @change="filtra"
                  :items="elem_commessa"
                  :item-text="(e) => e.barca"
                  item-value="barca"
                  label="Barca"
                  v-model="barca_search"
                >
                  <template slot="append">
                    <v-icon
                      @click="
                        barca_search = '';
                        filtra();
                      "
                      color="primary"
                    >
                      mdi-filter-off
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="commessa_search"
                  label="Matricola"
                  @change="filtra"
                >
                  <template slot="append">
                    <v-icon
                      @click="
                        commessa_search = '';
                        filtra();
                      "
                      color="primary"
                    >
                      mdi-filter-off
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  @change="filtra"
                  :items="elem_commessa"
                  :item-text="(e) => e.des_commessa"
                  item-value="des_commessa"
                  label="Descrizione"
                  v-model="cod_search"
                >
                  <template slot="append">
                    <v-icon
                      @click="
                        cod_search = '';
                        filtra();
                      "
                      color="primary"
                    >
                      mdi-filter-off
                    </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- data calendar -->
              <v-col cols="3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="289"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="data_formattata"
                      label="Data"
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      readonly
                    >
                      <template slot="append">
                        <v-icon
                          @click="
                            date = '';
                            filtra();
                          "
                          color="primary"
                        >
                          mdi-filter-off
                        </v-icon>
                      </template></v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="
                      menu = false;
                      filtra();
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </div>
    </template>
    <v-dialog v-model="specifica_barca">
      <ModificaDiario
        @click_chiudi="chiudiDialog"
        :riga_sel="elemento_sel"
        @salva_dichiarazione="handleSalvaDichiarazione"
        :visible="specifica_barca"
      />
    </v-dialog>

    <!-- Dialog Questionario -->
    <v-dialog v-model="questionario_visible">
      <RisposteDialog
        @click_chiudi="chiudiQuestionario"
        :readonly="elemento_sel.completata === 1"
        :riga_sel="elemento_sel"
        @salvato_questionario="handleSalvatoQuestionario"
        :visible="questionario_visible"
      />
    </v-dialog>

    <!-- Dialog di conferma eliminazione -->
    <v-dialog v-model="conferma_eliminazione_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"
            >Conferma Eliminazione</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="mt-5" style="overflow-y: auto">
          <span style="font-size: 18px">
            Confermi l'eliminazione della Dichiarazione selezionata?
          </span>
          <v-divider class="my-5"></v-divider>
          <v-container class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <!-- Bottone di annullamento -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="conferma_eliminazione_dialog = false"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Annulla</span>
                </v-tooltip>

                <!-- Bottone di conferma -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-5"
                      @click="eliminaDichiarazione"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="success"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Conferma</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <SnackbarQV ref="SnackbarQV" />
  </v-card>
</template>

<script>
import ModificaDiario from "@/components/ELECTRICALMARINE/ModificaDiario.vue";
import moment from "moment/moment";
import RisposteDialog from "@/components/ELECTRICALMARINE/RisposteDialog.vue";
import SnackbarQV from "@/components/TOOLS/SnackbarQV.vue";

class Elemento {
  constructor(arg = undefined) {
    if (arg != undefined) {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = arg[c.nome] != undefined ? arg[c.nome] : c.default_val;
      });
    } else {
      const campi = this.setCampi();
      campi.forEach((c) => {
        this[c.nome] = c.default_val;
      });
    }
  }

  // Metodo per resettare tutte le campi
  reset() {
    const campi = this.setCampi();

    campi.forEach((c) => {
      this[c.nome] = c.default_val;
    });
  }

  setCampi() {
    const campi = [
      { nome: "barca", default_val: null },
      { nome: "calzata", default_val: 0 },
      { nome: "completata", default_val: 0 },
      { nome: "coperta", default_val: 0 },
      { nome: "data_consegna", default_val: "" },
      { nome: "date_mod", default_val: "" },
      { nome: "cod_articolo_child", default_val: "" },
      { nome: "des_articolo_child", default_val: "" },
      { nome: "des_commessa", default_val: "" },
      { nome: "guscio", default_val: 0 },
      { nome: "id_articolo_avo", default_val: null },
      { nome: "id_articolo_child", default_val: null },
      { nome: "id_checklist", default_val: null },
      { nome: "id_commessa", default_val: null },
      { nome: "id_commessa_avo", default_val: null },
      { nome: "id_commessa_riepilogo", default_val: null },
      { nome: "magazzino", default_val: 0 },
      { nome: "matricola", default_val: "" },
      { nome: "ore", dafault_val: null },
      { nome: "rif_prem", default_val: 0 },
      { nome: "vassoio", dafault_val: 0 },
    ];

    return campi;
  }
}

export default {
  name: "Diario",
  components: {
    ModificaDiario,
    RisposteDialog,
    SnackbarQV,
  },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      cod_profilo: null,
      item_sel: null,
      date: "",
      filtri_visible: false,
      search: "",
      commessa_search: "",
      barca_search: "",
      cod_search: "",
      elemento_sel: new Elemento(),

      // Modal bool
      conferma_eliminazione_dialog: false,
      specifica_barca: false,
      menu: false,
      questionario_visible: false,

      // Array
      commesse: [],
      elem_commessa: [],
      headers: [
        {
          text: "Modifica",
          value: "modifica",
          sortable: false,
        },
        {
          text: "Checklist",
          value: "checklist",
          sortable: false,
        },
        {
          text: "Data Ultimo Montaggio",
          value: "date_mod",
          sortable: false,
        },
        {
          text: "Barca",
          value: "barca",
          sortable: false,
        },
        {
          text: "Matricola",
          value: "matricola",
          sortable: false,
        },
        {
          text: "Ore",
          value: "ore",
          sortable: false,
        },
        {
          text: "Rif. Prem.",
          value: "rif_prem",
          sortable: false,
        },
        {
          text: "Guscio",
          value: "guscio",
          sortable: false,
        },
        {
          text: "Coperta",
          value: "coperta",
          sortable: false,
        },
        {
          text: "Vassoio",
          value: "vassoio",
          sortable: false,
        },
        {
          text: "Calzata",
          value: "calzata",
          sortable: false,
        },
        {
          text: "Magazzino",
          value: "magazzino",
          sortable: false,
        },
        {
          text: "Elimina",
          value: "elimina",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    data_formattata() {
      return this.date != "" ? moment(this.date).format("DD/MM/YYYY") : "";
    },
  },
  watch: {},
  methods: {
    chiudiQuestionario() {
      this.questionario_visible = false;
    },
    clickChecklist(arg) {
      this.elemento_sel = new Elemento(arg);
      this.questionario_visible = true;
    },
    chiudiDialog() {
      this.specifica_barca = false;
    },
    clickElimina(arg) {
      this.elemento_sel = Object.assign({}, arg);
      this.conferma_eliminazione_dialog = true;
    },
    clickModificaCommessa(item) {
      this.elemento_sel = new Elemento(item);
      this.specifica_barca = true;
    },
    data_search() {
      return this.data_search != ""
        ? moment(this.data_search).format("DD/MM/YYYY")
        : "";
    },
    eliminaDichiarazione() {
      // Eseguo la chiamata per eliminare una dichiarazione.
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "deletediario",
          token: localStorage.user_token,
              id_commessa: this.elemento_sel.id_commessa,
        },
      };


      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
      this.conferma_eliminazione_dialog = false;
            this.getDiario();
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Criticità tecnica rilevata, codice: ${res.status}.`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    goBack() {
      this.$router.push("/home/electrical");
    },
    handleSalvatoQuestionario() {
      // Chiudo il dialog della checklist
      this.questionario_visible = false;

      // Stampo una snackbar di conferma
      this.$refs.SnackbarQV.print(
        "success",
        `Checklist di collaudo aggiornata con successo!`
      );

      // Aggiorno la tabella
      this.getDiario();
    },
    filtra() {
      /* Preparo un array temporaneo in cui
       * collezionare gli commesse filtrate */
      let commesse_tmp = [];
      this.elem_commessa.forEach((e) => {
        commesse_tmp.push(Object.assign({}, e));
      });

      // Filtro per data
      if (this.data_formattata != "") {
        let res = [];
        commesse_tmp.forEach((elem) => {
          const data_formattata = moment(elem.data_spedizione).format(
            "DD/MM/YYYY"
          );
          if (data_formattata === this.data_formattata) {
            res.push(Object.assign({}, elem));
          } else
            console.log(`no buono: ${data_formattata} ${this.data_formattata}`);
        });
        commesse_tmp = [];
        res.forEach((e) => {
          commesse_tmp.push(Object.assign({}, e));
        });
      }

      //Filtro per barca
      if (this.barca_search != "") {
        let res = [];
        commesse_tmp.forEach((elem) => {
          if (elem.barca === this.barca_search) {
            res.push(Object.assign({}, elem));
          }
        });
        commesse_tmp = [];
        res.forEach((e) => {
          commesse_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per Matricola
      if (this.commessa_search != "") {
        let res = [];
        commesse_tmp.forEach((elem) => {
          if (elem.cod_articolo === this.commessa_search) {
            res.push(Object.assign({}, elem));
          }
        });
        commesse_tmp = [];
        res.forEach((e) => {
          commesse_tmp.push(Object.assign({}, e));
        });
      }

      // Filtro per Descrizione
      if (this.cod_search != "") {
        let res = [];
        commesse_tmp.forEach((elem) => {
          if (elem.des_commessa == this.cod_search) {
            res.push(Object.assign({}, elem));
          }
        });

        commesse_tmp = [];
        res.forEach((e) => {
          commesse_tmp.push(Object.assign({}, e));
        });
      }

      // Ricostruisco le commesse visualizzate in base agli commesse filtrati.
      this.commesse = Array.from(commesse_tmp);
    },
    getDiario() {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getdiario",
          token: localStorage.user_token,
        },
      };
      this.$store
        .dispatch("api", request)
        .then((res) => {
          if (res.status === 200) {
            this.elem_commessa = Array.from(res.data.result.checklist);
            this.commesse = [];
            this.elem_commessa.forEach((e) => {
              this.commesse.push(Object.assign({}, e));
            });
          } else {
            this.$refs.SnackbarQV.print(
              "orange",
              `Errore tecnico con codice ${res.status}`
            );
          }
        })
        .catch((err) => {
          this.$refs.SnackbarQV.print(
            "orange",
            `${JSON.parse(err.response.data.message).result}.`
          );
        });
    },
    handleSalvaDichiarazione() {
      this.specifica_barca = false;
      this.getDiario();
    },
    ripristina() {
      // Rendo invisibili i filtri e resetto le chiavi di filtro
      this.search = "";
      this.cod_search = "";
      this.commessa_search = "";
      this.filtri_visible = false;

      // Resetto gli commesse visualizzati in tabella
      this.commesse = [];
      this.elem_commessa.forEach((e) => {
        this.commesse.push(Object.assign({}, e));
      });
    },
  },
  created() {},
  mounted() {
    // Valorizzo il cod_profilo per sapere quali bottoni si vedono
    this.cod_profilo = localStorage.cod_profilo;

    // Carico la lista delle dichiarazioni
    this.getDiario();
  },
};
</script>
<style scoped>
</style> 