<template>
    <v-row class="pt-10">
        <v-col cols="3"> </v-col>
            <v-col cols="6">
                <v-card class="pa-3">
                    <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>


                    <v-card-title> Gestione Movimenti Strumento </v-card-title>
                    <v-divider inset></v-divider>
                    <br> <br>
                        <v-row>
                            <v-col cols="2"></v-col>

                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    label="Codice Strumento"
                                    dense
                                    v-model="cod_strumento_selected"
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-btn
                                tile
                                color="success"
                                @click="getStatoStrumento"
                                >
                                <v-icon left>
                                    mdi-eye
                                </v-icon>
                                    Controlla Stato
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    label="Articolo"
                                    dense
                                    v-model="cod_articolo_selected"
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4"></v-col>

                            <v-col cols="2"></v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    label="Macchina"
                                    dense
                                    v-model="cod_macchina_selected"
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4"></v-col>

                            <v-col cols="2"></v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    label="Fornitore"
                                    dense
                                    v-model="cod_fornitore_selected"
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4"></v-col>                            

                        </v-row>
                        <v-row>
                            <v-col cols="3"></v-col>
                            <v-col cols="3">
                                <v-btn
                                tile
                                color="Primary"
                                >
                                <v-icon left>
                                    mdi-check
                                </v-icon>
                                    Start
                                </v-btn>
                           </v-col>
                            <v-col cols="6"></v-col>

                            <v-col cols="3"></v-col>
                            <v-col cols="3">
                                <v-btn
                                tile
                                color="Primary"
                                >
                                <v-icon left>
                                    mdi-check
                                </v-icon>
                                    Stop
                                </v-btn>
                          </v-col>
                            <v-col cols="6"></v-col>
                        </v-row>                        
            

                </v-card>
        </v-col>
    </v-row>
</template>

<script>

    export default {
        data() {
            return {
                snackbar: false,
                snackbar_color: "",
                snackbar_text: "",

                //data_bolla: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                //search: "",
                overlay: false,

                cod_strumento_selected : "",
                cod_articolo_selected: "",
                cod_macchina_selected:"",
                cod_fornitore_selected:"",

            };
        },        
        mounted() {
        },

        methods: {


            //Sezione Istruzioni di Lavoro

            getStatoStrumento() {
              let request = {
                controller: "produzione",
                method: "POST",
                richiesta: {
                  action: "getStatoStrumento ",
                  cod_strumento: this.cod_strumento_selected,
                  cod_articolo: this.cod_articolo_selected,
                  cod_macchina: this.cod_macchina_selected,
                  cod_fornitore: this.cod_fornitore_selected,
                  token: localStorage.getItem("user_token"),
                },
              };
              console.log("Request:",request)
                this.$store.dispatch("api", request).then((res) => {
                    this.istruzioni = res.data.istruzioni
                });
              },

        }

    }
</script>