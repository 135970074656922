<template>
  <v-card>
    <v-toolbar class="white--text" color="indigo" dense>
      <v-toolbar-title>
        Dettagli Anagrafica
        {{
          anagrafica_sel.tipo == "C"
            ? "Cliente"
            : anagrafica_sel.tipo == "F"
            ? "Fornitore"
            : "Nuovo Inserimento"
        }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Bottone di salvataggio -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="salvaAnagrafica" small>
            <v-icon color="success">mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="clickChiudi" small>
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details
              label="Codice"
              style="display: inline-block; width: 100%"
              v-model="codice_tmp"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details
              label="Ragione Sociale"
              style="display: inline-block; width: 100%"
              v-model="ragione_sociale_tmp"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2"> </v-col>
          <v-col cols="3">
            <v-select
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details="auto"
              item-text="text"
              item-value="id"
              :items="[
                { id: 'C', text: 'Cliente' },
                { id: 'F', text: 'Fornitore' },
              ]"
              label="Tipo"
              outlined
              style="border-radius: 0px; display: inline-block; width: 100%"
              v-if="anagrafica_sel.tipo == 'N'"
              v-model="tipo_tmp"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details
              label="Indirizzo"
              style="display: inline-block; width: 100%"
              v-model="indirizzo_tmp"
            >
            </v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details
              label="Località"
              style="display: inline-block; width: 100%"
              v-model="localita_tmp"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              min="0"
              background-color="#ffffff"
              class="pa-1 mr-5"
              dense
              hide-details
              label="CAP"
              style="display: inline-block; width: 100%"
              v-model="cap_tmp"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ snackbar_text }}
      <v-btn
        @click="snackbar = false"
        class="ml-5"
        :color="snackbar_color"
        fab
        outlined
        x-small
        style="background-color: #ffffff"
      >
        <v-icon>{{ snackbar_icon }}</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  name: "DettagliAnagrafica",
  props: {
    anagrafica_sel: { type: Object, default: null },
  },
  data() {
    return {
      // Variabili d'appoggio
      codice_tmp: "",
      ragione_sociale_tmp: "",
      id_anagrafica_tmp: "",
      indirizzo_tmp: "",
      localita_tmp: "",
      cap_tmp: "",
      tipo_tmp: "",
      // Modal
      snackbar: false,
      snackbar_color: "",
      snackbar_icon: "",
      snackbar_text: "",

      // Array
    };
  },
  watch: {
    anagrafica_sel: {
      immediate: true,
      handler() {
        // Ogni volta che cambia l'anagrafica selezionata, aggiorno i dati.
        this.codice_tmp = this.anagrafica_sel.codice;
        this.id_anagrafica_tmp = this.anagrafica_sel.id_anagrafica;
        this.ragione_sociale_tmp = this.anagrafica_sel.ragione_sociale;
        this.indirizzo_tmp = this.anagrafica_sel.indirizzo;
        this.localita_tmp = this.anagrafica_sel.localita;
        this.cap_tmp = this.anagrafica_sel.cap;

        /* Se il tipo dell'anagrafica è nuovo, valorizzo
         * "tipo_tmp" come stringa vuota; altrimenti lo
         * valorizzo con il tipo dell'oggetto selezionato. */
        this.tipo_tmp =
          this.anagrafica_sel.tipo == "N" ? "" : this.anagrafica_sel.tipo;
      },
    },
  },
  methods: {
    salvaAnagrafica() {
      if (
        this.codice_tmp != "" &&
        this.ragione_sociale_tmp != "" &&
        this.tipo_tmp != ""
      ) {
        let request = {
          controller: "Magazzino",
          method: "PUT",
          richiesta: {
            action: "setanagrafica",
            token: localStorage.getItem("user_token"),
            id_utente: localStorage.getItem("id"),
            id_anagrafica: this.anagrafica_sel.id_anagrafica,
            db_name: "quickproduction",
            codice: this.codice_tmp,
            ragione_sociale: this.ragione_sociale_tmp,
            indirizzo: this.indirizzo_tmp,
            localita: this.localita_tmp,
            cap: this.cap_tmp,
            tipo: this.tipo_tmp,
          },
        };

        console.log(JSON.stringify(request));
        this.$store.dispatch("api", request).then(() => {
          /* Emetto l'evento con la tipologia dell'anagrafica aggiunta,
           * in modo che se è lo stesso della tabella anagrafiche attualmente
           * visualizzata nel componente padre, la riaggiorno. */
          this.$emit("aggiungi_anagrafica", this.tipo_tmp);
        });
      } else {
        /* Se non ho compilato i campi essenziali,
         * stampo una snackbar d errore. */
        this.snackbar = true;
        this.snackbar_color = "error";
        this.snackbar_icon = "close";
        this.snackbar_text =
          "È necessario valorizzare almeno i campi Codice, Ragione Sociale e Tipo.";
      }
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
  },
};
</script>
<style>
</style>