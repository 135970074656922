<template>
  <div class="edit-magazzino" style="padding: 20px">
    <v-card>
      <v-toolbar
        class="indigo white--text"
        dense
        primary-title
      >
        <v-toolbar-title>Magazzino</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              :disabled="!valid"
              @click="salvaMagazzino"
            >
              <v-icon color="#1a80b6">
                mdi-floppy
              </v-icon>
            </v-btn>
          </template>
          <span>Salva Magazzino</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              elevation="1"
              @click="$router.push({ name: 'home-magazzino' })"
            >
              <v-icon class="error--text">
                mdi-keyboard-backspace
              </v-icon>
            </v-btn>
          </template>
          <span>Indietro</span>
        </v-tooltip>
      </v-toolbar>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
        id="progress"
      >
      </v-progress-linear>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              label="Sede"
              dense
              outlined
              v-model="sede_magazzino"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              label="Tipologia"
              dense
              outlined
              hide-details
              v-model="tipologia_magazzino"
              :items="tipologie"
              item-text="des"
              item-value="id_tabella"
              placeholder="Digita per cercare"
            >
              <template #label>
                <span>Tipologia</span>
                <a
                  @click.stop
                  style="pointer-events: all"
                >
                  <Modules
                    v-on:dialogModules="getTabella"
                    tipo="TIPO_MAGAZZINO"
                    nome="Tipo"
                    setaction="settabella"
                    getaction="gettabella"
                    controller="Global"
                    filter="validita = 1"
                    :dati="[{ des: '' }]"
                  />
                </a>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              label="Cod. Magazzino"
              dense
              outlined
              v-model="codice_magazzino"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Descrizione Magazzino"
              dense
              outlined
              v-model="descrizione_magazzino"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-treeview
            :items="struttura_magazzino"
            :open.sync="openIds"
          >
            <template v-slot:prepend="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="mr-2"
                    small
                    elevation="1"
                    @click="addChildren(item)"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi livello</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="mr-2"
                    small
                    elevation="1"
                    @click="showDialogPosizioniMultiple(item)"
                  >
                    <v-icon>
                      mdi-view-grid-plus-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi livello multiplo</span>
              </v-tooltip>
            </template>
            <template v-slot:append="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="item.level > 1"
                    v-on="on"
                    class="ml-5 error--text"
                    small
                    elevation="1"
                    @click="deleteItem(item)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Elimina</span>
              </v-tooltip>
            </template>
            <template v-slot:label="{ item }">
              <template v-if="item.edit">
                <v-text-field
                  dense
                  autofocus
                  v-model="item.des_dettaglio"
                  @keyup.enter="saveName(item)"
                >
                </v-text-field>
              </template>
              <template v-else>
                <span @click="editItem(item)">
                  {{ item.des_dettaglio }}
                </span>
              </template>
            </template>
          </v-treeview>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="inserimento_posizioni_multiple_visible" width="800">
      <v-card>
        <v-toolbar class="indigo white--text" dense>
          <v-toolbar-title class="white--text">
            Inserimento posizioni multiple
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                :disabled="!validAdd"
                @click="addPosizioniMultiple"
              >
                <v-icon color="#1a80b6">
                  mdi-floppy
                </v-icon>
              </v-btn>
            </template>
            <span>Inserisci</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="chiudiDialogPosizioniMultiple"
                small
              >
                <v-icon class="error--text">
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pa-0 pt-3">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Sigla"
                  dense
                  outlined
                  v-model="sigla"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-combobox
                  label="Cifra iniziale"
                  dense
                  outlined
                  hide-details
                  v-model="cifra_iniziale"
                  :items="lista_cifre"
                >
                </v-combobox>
              </v-col>
              <v-col cols="4">
                <v-combobox
                  label="Cifra finale"
                  dense
                  outlined
                  hide-details
                  v-model="cifra_finale"
                  :items="lista_cifre"
                >
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SnackbarQV ref="SnackbarQV" />
  </div>
</template>

<script>
import moment from "moment";

import Modules from "@/components/TOOLS/Modules";
import SnackbarQV from '@/components/TOOLS/SnackbarQV.vue';

export default {
  name: 'EditMagazzino',
  props: ['id', 'duplicateId'],
  components: {
    Modules,
    SnackbarQV,
  },
  data() {
    return {
      tipologie: [],
      loading: false,
      sede_magazzino: null,
      tipologia_magazzino: null,
      codice_magazzino: null,
      descrizione_magazzino: null,
      sigla: null,
      cifra_iniziale: null,
      cifra_finale: null,
      currentItem: null,
      currentEditItem: null,
      inserimento_posizioni_multiple_visible: false,
      maxId: 1,
      openIds: [],
      lista_cifre: [],
      struttura_magazzino: [
        {
          id: 1,
          id_magazzino_ubicazione: -1,
          id_magazzino: this.id,
          id_padre: null,
          cod_dettaglio: 'D1',
          des_dettaglio: 'Struttura Magazzino',
          id_temp: moment().valueOf(),
          id_temp_padre: null,
          validita: 1,
          id_utente: localStorage.id,
          level: 1,
          edit: false,
        },
      ],
    };
  },
  watch: {
    "$route.params.id": function (newT, oldT) {
      if (newT != oldT) {
        this.inizializza();
      }
    },
  },
  computed: {
    valid() {
      if (
        this.sede_magazzino != null &&
        this.tipologia_magazzino != null &&
        this.codice_magazzino != null &&
        this.descrizione_magazzino != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    validAdd() {
      if (
        this.cifra_iniziale && !isNaN(this.cifra_iniziale) &&
        this.cifra_finale && !isNaN(this.cifra_finale) &&
        this.cifra_iniziale >= 1 && this.cifra_iniziale <= 999 &&
        this.cifra_finale >= 1 && this.cifra_finale <= 999 &&
        this.sigla
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.inizializza();
  },
  methods: {
    inizializza() {
      this.loading = true;

      this.lista_cifre = Array.from({ length: 999 }, (x, i) => i + 1);

      if (this.id == -1) {
        if (this.duplicateId) {
          let request = {
            controller: "Magazzino",
            method: "POST",
            richiesta: {
              action: "getmagazzinoubicazione",
              token: localStorage.user_token,
              id_utente: localStorage.id,
              id_magazzino: this.duplicateId,
            },
          };

          this.$store.dispatch("api", request).then(async (res) => {
            if (res.status == 200) {
              if (res.data != undefined) {
                this.sede_magazzino = res.data.magazzino[0].sede_magazzino;
                this.tipologia_magazzino = parseInt(res.data.magazzino[0].tipo_magazzino);
                this.codice_magazzino = res.data.magazzino[0].cod_magazzino;
                this.descrizione_magazzino = res.data.magazzino[0].des_magazzino;
                this.struttura_magazzino = this.parseStrutturaMagazzino(res.data.dettaglio0, 1);
                this.loading = false;
              }
            }
          });
        }
      } else {
        var request2 = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getmagazzinoubicazione",
            token: localStorage.user_token,
            id_utente: localStorage.id,
            id_magazzino: this.id,
          },
        };

        this.$store.dispatch("api", request2).then(async (res) => {
          if (res.status == 200) {
            if (res.data != undefined) {
              this.sede_magazzino = res.data.magazzino[0].sede_magazzino;
              this.tipologia_magazzino = parseInt(res.data.magazzino[0].tipo_magazzino);
              this.codice_magazzino = res.data.magazzino[0].cod_magazzino;
              this.descrizione_magazzino = res.data.magazzino[0].des_magazzino;
              this.struttura_magazzino = this.parseStrutturaMagazzino(res.data.dettaglio0, 1);
              this.loading = false;
            }
          }
        });
      }

      this.getTabella();
    },
    parseStrutturaMagazzino(struttura, level) {
      struttura.forEach((item) => {
        this.maxId = Math.max(this.maxId, item.id_magazzino_ubicazione);

        item.id = item.id_magazzino_ubicazione;

        item.edit = false;
        item.level = level;

        if (item['dettaglio' + level]) {
          item.children = item['dettaglio' + level];
          delete item['dettaglio' + level];

          this.parseStrutturaMagazzino(item.children, level + 1);
        }
      });

      return struttura;
    },
    getTabella() {
      let request = {
        controller: "Global",
        method: "POST",
        richiesta: {
          action: "gettabella",
          token: localStorage.getItem("user_token"),
          tipo:
            "TIPO_MAGAZZINO",
          filter: "validita = 1",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.tipologie = res.data != undefined
                    ? res.data
                    : [];
      });
    },
    addChildren(item) {
      if (this.currentEditItem) {
        this.currentEditItem.edit = false;
      }

      this.maxId += 1;

      if (!item.children) {
        this.$set(item, 'children', []);
      }

      item.children.push({
        id: this.maxId,
        id_magazzino: this.id,
        id_magazzino_ubicazione: -1,
        des_dettaglio: 'Nome',
        cod_dettaglio: 'D' + this.maxId,
        validita: 1,
        id_padre: (item.id_magazzino_ubicazione != -1) ? item.id_magazzino_ubicazione : null,
        id_temp: moment().valueOf(),
        id_temp_padre: item.id_temp,
        id_utente: localStorage.id,
        level: item.level + 1,
        edit: true,
      });

      this.currentEditItem = item.children[item.children.length - 1];

      this.openIds.push(item.id);
    },
    showDialogPosizioniMultiple(item) {
      this.currentItem = item;
      this.inserimento_posizioni_multiple_visible = true;
    },
    chiudiDialogPosizioniMultiple() {
      this.inserimento_posizioni_multiple_visible = false;

      this.sigla = '';
      this.cifra_iniziale = '';
      this.cifra_finale = '';
    },
    addPosizioniMultiple() {
      if (this.currentEditItem) {
        this.currentEditItem.edit = false;
      }

      const cifra_iniziale = parseInt(this.cifra_iniziale);
      const cifra_finale = parseInt(this.cifra_finale);

      if (cifra_iniziale >= cifra_finale) {
        this.$refs.SnackbarQV.print(
          "error",
          'Il valore di "Cifra finale" deve essere maggiore di "Cifra iniziale"'
        );
        return;
      }

      if (!this.currentItem.children) {
        this.$set(this.currentItem, 'children', []);
      }

      for (let i = cifra_iniziale; i <= cifra_finale; i++) {
        this.maxId += 1;

        const name = (cifra_finale >= 100) ? this.sigla + (i + '').padStart(3, '0') : this.sigla + (i + '').padStart(2, '0');

        this.currentItem.children.push({
          id: this.maxId,
          id_magazzino: this.id,
          id_magazzino_ubicazione: -1,
          des_dettaglio: name,
          cod_dettaglio: 'D' + this.maxId,
          validita: 1,
          id_padre: (this.currentItem.id_magazzino_ubicazione != -1) ? this.currentItem.id_magazzino_ubicazione : null,
          id_temp: moment().valueOf(),
          id_temp_padre: this.currentItem.id_temp,
          id_utente: localStorage.id,
          level: this.currentItem.level + 1,
          edit: false,
        });
      }

      this.openIds.push(this.currentItem.id);

      this.chiudiDialogPosizioniMultiple();
    },
    saveName(item) {
      item.edit = false;
    },
    editItem(item) {
      if (this.currentEditItem) {
        this.currentEditItem.edit = false;
      }

      item.edit = true;
      this.currentEditItem = item;
    },
    deleteItem(item) {
      if (item.level == 2) {
        this.struttura_magazzino[0].children = this.struttura_magazzino[0].children.filter((el) => {
          return el.id != item.id;
        });
      } else {
        this.removeItem(this.struttura_magazzino[0], item.id, item.level);
      }
    },
    removeItem(item, id, level) {
      item.children.forEach((el) => {
        if (el.level == level) {
          if (el.id == id) {
            item.children = item.children.filter((el2) => {
              return el2.id != id;
            });
          }
        } else {
          if (el.children) {
            return this.removeItem(el, id, level);
          }
        }
      });
    },
    convertStrutturaMagazzino(struttura) {
      if (struttura.children) {
        struttura['dettaglio' + struttura.level] = struttura.children;
        delete struttura.children;

        struttura['dettaglio' + struttura.level].forEach((el) => {
          this.convertStrutturaMagazzino(el);
        });
      }
    },
    salvaMagazzino() {
      this.convertStrutturaMagazzino(this.struttura_magazzino[0]);

      var itemRequest = {
        magazzino: [
          {
            id_magazzino: this.id,
            sede_magazzino: this.sede_magazzino,
            tipo_magazzino: this.tipologia_magazzino,
            cod_magazzino: this.codice_magazzino,
            des_magazzino: this.descrizione_magazzino,
            visibile: 1,
            validita: 1,
            giacenza: 1,
            disponibilita: 1,
            conto_lavoro: null,
            ordine: null,
          },
        ],
        dettaglio0: this.struttura_magazzino,
      };

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setmagazzinoubicazione",
          token: localStorage.user_token,
          ...itemRequest,
        },
      };

      this.$store.dispatch("api", request).then(async (res) => {
        if (res.status == 200) {
          if (this.id == -1) {
            this.$refs.SnackbarQV.print(
              "success",
              `Creazione magazzino N. ${res.data} avvenuta con successo.`
            );

            const that = this;
            let timer = setTimeout(function () {
              that.$router.push({ name: 'edit-magazzino', params: { id: res.data } });
              clearTimeout(timer);
            });
          } else {
            this.$refs.SnackbarQV.print(
              "success",
              `Salvataggio magazzino N. ${res.data} avvenuta con successo.`
            );
          }
        }
      });
    },
  },
};
</script>

<style>
.edit-magazzino .v-treeview-node__label {
  font-size: 16px
}
</style>
