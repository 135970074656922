class RASTER{
    cloneWithStyle(element){
        let clone = element.cloneNode(false);
        let child_nodes = element.childNodes;
        for(let i=0;i<child_nodes.length;i++){
            let child_clone = this.cloneWithStyle(child_nodes[i]);
            clone.appendChild(child_clone);
        }
        if(clone.nodeType!=Node.TEXT_NODE && clone.nodeType!=Node.COMMENT_NODE){
            let style = window.getComputedStyle(element);
            let inline_style = '';
            for(let i=0;i<style.length;i++){
                inline_style += style[i] + ':' + style.getPropertyValue(style[i]) + ';';            
            }
            clone.style.cssText = inline_style;
        }
        return clone;        
    }
    toDataURL(element,callback=null,width='auto',height='auto',quality=1,format='png'){
        // Controllo dati
        if(element==null||element==undefined){console.warn('toRaster: elemento non valido');return;}
        if(width<=0||width==null||width==undefined||(isNaN(width)&&width!='auto')){console.warn('toRaster: larghezza specificata non valida');return;}
        if(height<=0||height==null||height==undefined||(isNaN(height)&&height!='auto')){console.warn('toRaster: altezza specificata non valida');return;}


        // Normalizzazione dati
        if(width=='auto'&&height=='auto'){
            width=element.offsetWidth;
            height=element.offsetHeight;
        }else if(width=='auto'&&height!='auto'){
            let ratio = element.offsetWidth/element.offsetHeight;
            width = height*ratio;
        }else if(width!='auto'&&height=='auto'){
            let ratio = element.offsetHeight/element.offsetWidth;
            height = width*ratio;
        }


        if(!(quality>0&&quality<=100)){quality=1;}else{quality/=100;}
        let type = 'image/png'
        switch(format){
            case 'png':
            default:
                format = 'png';
                type='image/png';
                break;
            case 'jpg':
            case 'jpeg':
                type='image/jpeg';
                break;
            case 'svg':{
                    format = 'svg';
                    type = 'image/svg';

                    // Su Safari (14 e precedenti) è presente un bug non risolto di WebKit che impedisce il ridimensionamento dinamico dei ForeignObjects
                    // nelle immagini SVG, verrà usato il formato PNG

                    let UA = navigator.userAgent.toLowerCase();
                    if(UA.indexOf('macintosh') >=0 && UA.indexOf('safari') >= 0){
                        format = 'png';
                        type='image/png';
                        let ratio = height/width;
                        width=Math.max(width,4000);
                        height=width*ratio;
                    }
                }              
                break; 
        }

        // Creazione del clone (con stile)
        let inline_cloned = this.cloneWithStyle(element);

        let data =  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 ${element.offsetWidth} ${element.offsetHeight}" preserveAspectRatio="xMidYMid meet" >
                    <foreignObject width="100%" height="100%">
                        <div xmlns="http://www.w3.org/1999/xhtml" >${inline_cloned.outerHTML}</div>
                        </foreignObject>
                    </svg>`;  
                  
        let data_url = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(data);
        if(format == 'svg'){
            if(callback!=null&&callback!=undefined){callback(data_url,data);}
            return;
        }
        // Creazione dell'immagine
        let img = new Image();        
        data = null;
        img.onload = function(){    
            let canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0,width,height);
            let raster_data = canvas.toDataURL(type,quality);            
            if(callback!=null&&callback!=undefined){callback(raster_data,data);}
            data_url = null;                  
        }
        img.src=data_url;
    }
    download(element,name='download',width='auto',height='auto',quality=1,format='png'){
        if(name==null||name==undefined){name='download';}
        this.toDataURL(element,function(data){
            let tmp = document.createElement('a');
            tmp.href = data;
            tmp.download = name;
            tmp.click();
            tmp = null;
        },width,height,quality,format);
    }
}
export default RASTER;