<template>
    <div :id="'chart-'+index" class="chart_container" @click="processClick"></div>
</template>
<script>

import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/map";
import "echarts/lib/chart/radar";
import "echarts/lib/chart/scatter";
import "echarts/lib/chart/effectScatter";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/geo";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataset";
import "echarts/map/js/world";
import "zrender/lib/svg/svg";
import "echarts-gl";

//import CHART_3D_PIE from "./Modelli/3DPie";
//import CHART_3D_BAR from "./Modelli/3DBar";
import CHART_2D_GENERAL from "./Modelli/2DGeneral";
import CHART_2D_PIE from "./Modelli/2DPie";
import CHART_2D_H_BAR from "./Modelli/2DHBar";
import CHART_TABLE from "./Modelli/Table";
import CHART_GAUGE from "./Modelli/Gauge";
import CHART_CARD from "./Modelli/Card";

import RASTER from "@/components/GRAPH/Generale/Componenti/Rasterize/Rasterize.js";

export default {
    name : "Grafico",
    props : {
        index : [Number,String],
    },
    data(){
        return{
            databuffer : [],
            chart_instance : null,
            chart_container : null,
            click_listener : null,
            chart : null,
            options : {},
            extra_border:false,
            RASTER : new RASTER(),
            use_RASTER : false,
        }
    },
    mounted(){
        this.chart_container = document.getElementById('chart-'+this.index);        
        this.chart_instance = echarts.init(this.chart_container, { renderer: "svg" });        
        this.chart_container.addEventListener('graph_update',this.setChart);
        this.chart_container.addEventListener('graph_resize',this.resize);
        this.chart_container.addEventListener('graph_raster',this.render);

        const that = this;
        this.setClickListener = function(listener){ that.click_listener = listener; }
    },
    beforeDestroy(){
        this.chart_container.removeEventListener('graph_update',this.setChart);
        this.chart_container.removeEventListener('graph_resize',this.resize);
        this.chart_container.removeEventListener('graph_raster',this.render);        
        this.chart_instance.dispose();
        this.chart_container.parentElement.removeChild(this.chart_container);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{        
        processClick(event){
            const router = this.$router;
            if(this.click_listener != null){ 
                const coords = this.click_listener(event, router); 
                if(coords != null){this.processCoordinates(coords);}
            }
        },
        processCoordinates(coords){
            const data = this.databuffer;
            if(!isFinite(Number(data.derivato)) || data.derivato == null){
                return;
            }

            const transport = new Object();
            const msg = new CustomEvent('graph_overall_export',{detail:transport});
            document.body.dispatchEvent(msg);

            const result = new Array();
            result.push({serie:'£SERIE', value:coords.cod });
            result.push({serie:'£VALORE', value:coords.value });
            const index = Number(coords.index);
            
            for(let i = 0; i < data.serie.length; i++){
                const serie = data.serie[i];
                let value = serie.serie[index];
                switch(serie.tipo_dati){
                    case 1:
                        value = Number(value.toString().replaceAll(',','.'));                                                                
                    break;
                    case 2:
                        value = value.toString().split(' ')[0];
                        break;
                    case 0:
                    default:
                        value = value.toString();
                        break;
                }
                result.push({
                    serie : serie.cod.replaceAll('#','£'),
                    value : value
                });
            }

            //const that = this;
            const request =  {
                controller : 'Graph',
                body : {
                    action: "getGraficoParametrico",
                    token: localStorage.user_token,
                    params:result, 
                    overall: transport.overall, 
                    id_grafico:data.derivato
                },
                method: 'POST',
                on : {
                    load : function(response){
                        const fullscreen_event = new CustomEvent('graph_fullscreen',{detail:response.grafici[0]});
                        document.body.dispatchEvent(fullscreen_event);                 
                    }
                }
            }
            window.NETWORK.AjaxRequest(request);
        },
        render(data){
            data = data.detail;
            if(!this.use_RASTER){
                if(data.download){
                    let tmp = document.createElement('a');
                    tmp.href = this.chart_instance.getDataURL({type:data.options.type,backgroundColor:data.options.background});
                    tmp.download = data.name;
                    tmp.click();
                    tmp = null;
                }else{
                    let raster = this.chart_instance.getDataURL({type:data.options.type,backgroundColor:data.options.background});
                    data.callback(raster);
                }                
            }else{
                let target = this.chart_container.getElementsByClassName('graph_render_target')[0];
                if(data.download){                    
                    this.RASTER.download(target,data.name,data.options.width,data.options.height,data.options.quality,data.options.type);
                }else{
                    this.RASTER.toDataURL(target,data.callback,data.options.width,data.options.height,data.options.quality,data.options.type);
                }
            }
        },
        resize(){
            this.chart_instance.resize();
        },
        setChart(data){
            data = data.detail;
            this.databuffer = data;

            // Pulizia area grafico
            this.use_RASTER = false;
            this.chart_instance.clear(); 
            this.chart_instance.off('click');
            // Pulizia messaggi d'errore
            const msg = this.chart_container.getElementsByClassName('graph_widget_error');
            if(msg.length > 0){
                this.chart_container.removeChild(msg[0]);
            }
            // Rimozione tabelle
            let table = this.chart_container.getElementsByClassName('graph_pivot_table')[0];
            if(table!=null){
                this.chart_container.removeChild(table);
                table = null;
            }

            // Rimozione card
            let card = this.chart_container.getElementsByClassName('graph_carditem')[0];
            if(card!=null){
                this.chart_container.removeChild(card);
                card = null;
            }

            if(data.error != null) {
                const msg = document.createElement('div');
                msg.setAttribute('class', 'graph_widget_error');
                msg.innerHTML = `<span></span><p>${data.error}</p>`;
                this.chart_container.appendChild(msg);
                return;
            }

            // pulizia gestore click
            this.click_listener = null;
            
            switch(data.famiglia_grafici){
                case 1:
                    this.chart = new CHART_2D_H_BAR(this.setClickListener);
                    break;
                case 2:
                    this.chart = new CHART_2D_PIE(this.setClickListener);
                    break;
                case 3:
                    this.chart = new CHART_GAUGE(this.setClickListener);
                    break;
                case 4:
                    this.use_RASTER = true;
                    this.chart = new CHART_TABLE(this.chart_container, this.setClickListener);
                    break;
                case 5:
                    this.use_RASTER = true;
                    this.chart = new CHART_CARD(this.chart_container, this.setClickListener);
                    break;
                case 0:
                default:
                    this.chart = new CHART_2D_GENERAL(this.setClickListener);
                    break;
            }
            this.options = this.chart.setOptions(data);

            if(this.options!=null){
                this.chart_instance.setOption(this.options);
                const that = this;
                this.chart_instance.on('click', function(data){
                    that.processCoordinates(data.data.coords);
                });
            }

            // Creazione anteprima
            let target = document.getElementById('nav_slide_'+this.index);
            if(target==null||target==undefined){return;}
            target = target.getElementsByTagName('img')[0];
            if(target==null||target==undefined){return;}
            let that = this;

            setTimeout(function(){
                that.render({detail:{
                    options : {
                        quality : 50,
                        type : 'svg',
                        width : 400,
                        height : 'auto',
                        background : '#ffffff',
                    },
                    download : false,
                    callback : function(result){target.src=result;}
                }})
            },10);            
        },
    }
}
</script>
<style>
@import "../Generale/Temi/Default.css";
.chart_container{
    width:100%;
    height:100%;
    position: relative;
    overflow: hidden;
}

.graph_carditem{
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    overflow:hidden;
    margin:0;
    padding:0;
    white-space: nowrap;
}
.graph_carditem > span{
    height:100%;
    width:0;
    display: inline-block;
    vertical-align: middle;
}
.graph_carditem > p {
    position:relative;
    margin:0;
    width:100%;
    padding:10px;
    display: inline-block;
    vertical-align: middle;
}

.graph_pivot_table {
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    overflow-y: auto;
    overflow-x:auto;
    margin:0;
    padding:0;
}

.graph_pivot_table table,
.graph_pivot_table table > tr > th,
.graph_pivot_table table > tr,
.graph_pivot_table table > tr > td{
    border-collapse: collapse;
    border:1px solid grey;
    padding:2px;
    text-transform: capitalize;
    white-space: normal;
    text-overflow: ellipsis;
    position: relative;
    background-clip: padding-box;
}

.graph_pivot_table table > tr > th,
.graph_pivot_table table > tr > td{
    background-color: var(--widget_body_background);
}
.graph_pivot_table table{
    position: relative;
    width:100%;    
    top:0;
    left:0;  
    margin:0;
    padding:0;
    font-family : helvetica;
}

.graph_pivot_table table > tr[data-outscroll] > *{
    display : none;
}

.graph_pivot_table > div{
    position: absolute;
    left:0;
    width:100%; 
    top:0; 
    pointer-events: none;    
    margin:0;
    padding:0;
    overflow: hidden;
}

.graph_pivot_table > div > table{
    background-color: transparent;
}

.graph_pivot_table > div > table *[name="datum"]{
    opacity:0;
    border-color: transparent;
}

.graph_widget_error {
    position: absolute;
    top : 0;
    left:0;
    width:100%;
    height:100%;
    text-align: center;
    margin : 0;
}
.graph_widget_error > span {
    height:100%;
    width:0;
    display: inline-block;
    vertical-align: middle;
}
.graph_widget_error > p {
    width:calc(100% - 30px);
    display: inline-block;
    vertical-align: middle;
}

</style>