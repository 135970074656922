<template>
    <div>

    <v-container fluid>
    <v-row  justify="center" align="center">
    <v-col md="2"></v-col>
    <v-col class="mt-n6 text--center"  md="6">
    
     <v-btn tile dark color="amber lighten-2
">
     SPEDISCI PEDANE
     </v-btn>

     <v-btn class="ml-1" tile dark color="primary
">
     ESTRAI PACKGING LIST
     </v-btn>

      <v-btn class="ml-1" tile dark color="primary
">
    <v-icon>mdi-menu</v-icon>
     </v-btn>

    </v-col>
    <v-col md="1"></v-col>
    
    </v-row>

   
     <v-row>
      
      <v-col md="12">
      
      <v-data-table :hide-default-footer="true"   
      v-model="selected"
    :single-select="singleSelect"
    item-key="id"
    show-select
    class="elevation-1" :headers="headers">
      
      
      </v-data-table>
      </v-col>
      </v-row>
    </v-container>
        
    </div>
</template>



<script>
export default {
    data() {
        return {
              headers:[
              {
          text: "Dettagli",
          value: "dettagli",
          sortable: false
        },
        {
          text: "Spedisci",
          value: "spedisci",
          sortable: false
        },
        {
          text: "Etichetta",
          value: "etichetta",
          sortable: false
        },
        {
          text: "Lotto Pedana",
          value: "lotto_pedana",
          sortable: false
        },
        {
          text: "Articoli",
          value: "articoli",
          sortable: false
        },
        {
          text: "Imballo",
          value: "imballo",
          sortable: false
        },
        { 
          text:"Larghezza",
          value: "larghezza",
          sortable:false
        },
        { 
          text:"Lunghezza",
          value: "lunghezza",
          sortable:false
        },
            { 
          text:"Altezza",
          value: "altezza",
          sortable:false
        }, 
            { 
          text:"Peso",
          value: "peso",
          sortable:false
        }, 
        { 
          text:"Q.ta Pezzi",
          value: "qty_pezzi",
          sortable:false
        }, 
        { 
          text:"Rimuovi",
          value: "rimuovi",
          sortable:false
        }, 
         ],
        }
    },
}
</script>