<template>
  <div style="width:100%;height:100%;">
      <GestionePriorita></GestionePriorita>
  </div>
</template>
<script>

import GestionePriorita from "@/components/TOOLS/Gestionepriorita.vue";
export default {
     components: {
    GestionePriorita,
  },
  data() {
      
    return {
        
        
    };
  },
};
</script>