var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"color":"indigo","dense":"","fixed":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Dichiarazioni del Capobarca ")]),_c('v-spacer'),(_vm.filtri_visible === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.filtri_visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter")])],1)]}}],null,false,3068551005)},[_c('span',[_vm._v("Filtri")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.ripristina}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter-off")])],1)]}}])},[_c('span',[_vm._v("Ripristina")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-2 pa-0"}),[_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.commesse,"item-key":"nome","search":_vm.search,"height":"80vh","fixed-header":""},scopedSlots:_vm._u([{key:"item.modifica",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickModificaCommessa(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica Dichiarazione")])])]}},{key:"item.date_mod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_mod && item.date_mod.length > 0 ? ((item.date_mod.substring(8, 10)) + "/" + (item.date_mod.substring( 5, 7 )) + "/" + (item.date_mod.substring(0, 4))) : "N/A")+" ")]}},{key:"item.checklist",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.guscio === 0 ||
                  item.coperta === 0 ||
                  item.rif_prem === 0 ||
                  item.vassoio === 0 ||
                  item.calzata === 0 ||
                  item.magazzino === 0,"small":""},on:{"click":function($event){return _vm.clickChecklist(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.completata === 1 ? 'success' : 'primary'}},[_vm._v(" mdi-file-question ")])],1)]}}],null,true)},[_c('span',[_vm._v("Rispondi al Questionario")])])]}},{key:"item.elimina",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(
                  _vm.cod_profilo === 'SVIL' ||
                  _vm.cod_profilo === 'CNXBOX' ||
                  _vm.cod_profilo === 'Admin' ||
                  _vm.cod_profilo === 'manager'
                )?_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickElimina(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rispondi al Questionario")])])]}},{key:"item.rif_prem",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.rif_prem === 1 ? "mdi-check-circle" : ""))])]}},{key:"item.guscio",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.guscio === 1 ? "mdi-check-circle" : ""))])]}},{key:"item.coperta",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.coperta === 1 ? "mdi-check-circle" : ""))])]}},{key:"item.vassoio",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.vassoio === 1 ? "mdi-check-circle" : ""))])]}},{key:"item.calzata",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.calzata === 1 ? "mdi-check-circle" : ""))])]}},{key:"item.magazzino",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.magazzino === 1 ? "mdi-check-circle" : ""))])]}},{key:"top",fn:function(){return [(_vm.filtri_visible)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.elem_commessa,"item-text":function (e) { return e.barca; },"item-value":"barca","label":"Barca"},on:{"change":_vm.filtra},model:{value:(_vm.barca_search),callback:function ($$v) {_vm.barca_search=$$v},expression:"barca_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.barca_search = '';
                      _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Matricola"},on:{"change":_vm.filtra},model:{value:(_vm.commessa_search),callback:function ($$v) {_vm.commessa_search=$$v},expression:"commessa_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.commessa_search = '';
                      _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.elem_commessa,"item-text":function (e) { return e.des_commessa; },"item-value":"des_commessa","label":"Descrizione"},on:{"change":_vm.filtra},model:{value:(_vm.cod_search),callback:function ($$v) {_vm.cod_search=$$v},expression:"cod_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_search = '';
                      _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"289","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.data_formattata),callback:function ($$v) {_vm.data_formattata=$$v},expression:"data_formattata"}},on),[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.date = '';
                          _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)]}}],null,false,3070910501),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false;
                    _vm.filtra();}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1):_vm._e()]},proxy:true}],null,true)})],1)],_c('v-dialog',{model:{value:(_vm.specifica_barca),callback:function ($$v) {_vm.specifica_barca=$$v},expression:"specifica_barca"}},[_c('ModificaDiario',{attrs:{"riga_sel":_vm.elemento_sel,"visible":_vm.specifica_barca},on:{"click_chiudi":_vm.chiudiDialog,"salva_dichiarazione":_vm.handleSalvaDichiarazione}})],1),_c('v-dialog',{model:{value:(_vm.questionario_visible),callback:function ($$v) {_vm.questionario_visible=$$v},expression:"questionario_visible"}},[_c('RisposteDialog',{attrs:{"readonly":_vm.elemento_sel.completata === 1,"riga_sel":_vm.elemento_sel,"visible":_vm.questionario_visible},on:{"click_chiudi":_vm.chiudiQuestionario,"salvato_questionario":_vm.handleSalvatoQuestionario}})],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_eliminazione_dialog),callback:function ($$v) {_vm.conferma_eliminazione_dialog=$$v},expression:"conferma_eliminazione_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Conferma Eliminazione")])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"overflow-y":"auto"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" Confermi l'eliminazione della Dichiarazione selezionata? ")]),_c('v-divider',{staticClass:"my-5"}),_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.conferma_eliminazione_dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Annulla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.eliminaDichiarazione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]}}])},[_c('span',[_vm._v("Conferma")])])],1)],1)],1)],1)],1)],1),_c('SnackbarQV',{ref:"SnackbarQV"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }