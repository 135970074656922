<template>
    <GraphDialog ref="dialog" title="Gestione Immagini Predefinite">
        <div class="graph_home_settings">
            <section>
                <GraphButton icon="upload" @click="uploadAndCompress"/>
                <span>Carica nuova immagine</span>
            </section>
            <main>
                <div v-for="(image,i) in images" :key="i" :data-locked="image.privato==1" :data-deleted="image.validita!=1">          
                    <img :src="image.contenuto" />  
                    <input type="text" placeholder="Nome Immagine" v-model="image.nome" />
                    <GraphIcon icon="lock" @click="image.privato=image.privato==0?1:0"/>
                    <GraphIcon :icon="image.validita==1?'delete':'restore'" @click="image.validita=image.validita==1?0:1"/>
                </div>
            </main>
            <GraphButton text="Salva Modifiche" @click="setImages" :disabled="loading" />            
        </div>         
    </GraphDialog>
</template>
<script>
import GraphDialog from "@/components/GRAPH/.common/Dialog/GraphDialog.vue";
import GraphIcon from "@/components/GRAPH/.common/Icons/GraphIcon.vue";
import GraphButton from "@/components/GRAPH/.common/Buttons/GraphButton.vue";
export default {
    name : 'GraphHomeSettings',
    props : {
        items : Array
    },
    components : { 
        GraphDialog,
        GraphIcon,
        GraphButton
    },
    data(){
        return{
            images : null,
            loading : false,
            NETWORK : null
        }
    },
    mounted(){
        this.NETWORK = this.$store.state.Network;
        this.getImages();
    },
    methods : {
        getImageById(id){
            if(this.images == null){ return null; }
            for(let i=0; i < this.images.length;i++){
                const image = this.images[i];
                if(image.id_file == id){
                    return image;
                }
            }
            return null;
        },
        getImages(){
            const that = this;
            const request = {
                controller : 'Graph',
                showloading : false,
                body : {
                    action: 'getfiles',
                    token: localStorage.user_token,
                },
                method: 'POST',
                on : {
                    load : function(response) {
                        that.images = response.files;                        
                        that.$emit('image_event', that.images);
                    },
                    error : function(response) {
                        console.error(response);
                    }
                },
            }
            this.NETWORK.AjaxRequest(request);
        },
        open(){
            this.$refs.dialog.display();
        },
        setImages(){
            const that = this;
            const request = {
                controller : 'Graph',
                showloading : false,
                body : {
                    action: 'setfiles',
                    token: localStorage.user_token,
                    files : this.images
                },
                method: 'PUT',
                on : {
                    load : function() {
                        that.$refs.dialog.hide();
                        that.loading = false;
                        that.$refs.dialog.unlock();
                        that.getImages();
                    },
                    error : function(response) {
                        that.loading = false;
                        that.$refs.dialog.unlock();
                        console.error(response);
                    }
                },
            }
            this.loading = true;
            this.$refs.dialog.lock();
            this.NETWORK.AjaxRequest(request);
        },
        uploadAndCompress() {
            const that = this;
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/png, image/jpeg');
            input.onchange = function () {
                const reader = new FileReader();
                reader.onload = function () {
                const img = new Image();
                img.onload = function () {
                    let width, height;
                    let ratio = img.width / img.height;
                    if (img.width > img.height) {
                        width = Math.min(400, img.width);
                        height = width / ratio;
                    } else {
                        height = Math.min(400, img.height);
                        width = height * ratio;
                    }
                    const canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    that.images.push({
                        name : null,
                        id_file : -1,
                        contenuto : ctx.canvas.toDataURL(),
                        validita : 1,
                        privato : 0
                    });
                };
                img.src = reader.result;
                };
                reader.readAsDataURL(input.files[0]);
            };
            input.click();
        }
    }
}
</script>
<style scoped>
    .graph_home_settings{
        position: relative;
        width:400px;
        text-align: left;
    }
    .graph_home_settings > section{
        position: relative;
        display: block;
        margin: 5px 0 20px;
        white-space: nowrap;
        text-align: center;
    }
    .graph_home_settings > section > .graph_button{
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
    }
    .graph_home_settings > section > span{
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }
    .graph_home_settings > main{
        position: relative;
        max-height: calc(80vh - 200px);
        overflow-x: hidden;
        overflow-y:auto;
    }
    .graph_home_settings > main > div{
        position: relative;
        display: block;
        margin: 5px 0;
    }
    .graph_home_settings > main > div > *{
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    .graph_home_settings > main > div > input{
        width:150px;
        margin-right: calc(100% - 350px);
    }
    .graph_home_settings > main > div > svg{
        width:24px;
        margin: 0 5px;
        height: 24px;
        cursor:pointer;
        color:black;
    }
    .graph_home_settings > main > div[data-locked] > svg:nth-of-type(1){
        color:#cca300;
    }
    .graph_home_settings > main > div > img{
        width: 100px;
        height: 100px;
        object-position: center;
        object-fit: contain;
        margin-right: 15px;
    }
    .graph_home_settings > .graph_button{
        float:right;
        margin-top:15px;
    }
</style>