<template>
  <v-card class="ma-0 pa-0">
    <v-toolbar color="indigo" dense fixed>
      <v-toolbar-title class="white--text"> Annotazioni </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Salva e Invia Risposta -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="salvaNota" small v-bind="attrs" v-on="on">
            <v-icon color="success"> mdi-floppy </v-icon>
          </v-btn>
        </template>
        <span>Salva Nota</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- Chiudi Diario -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="chiudiDialog" small v-bind="attrs" v-on="on"
            ><v-icon color="error">mdi-close</v-icon></v-btn
          >
        </template>
        <span>Chiudi</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text class="mt-5">
      <v-container>
        <v-row align="center" justify="center">
          <v-col align="center" cols="10" justify="center">
            <v-textarea
              v-model="testo_note"
              outlined
              label="Testo"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    area_sel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Variabili d'appoggio
      testo_note: "",
      // Modal bool
      // Array
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible === true) {
          this.testo_note =
            this.area_sel.note != null ? this.area_sel.note : "";
        }
      },
    },
  },
  methods: {
    chiudiDialog() {
      this.testo_note = "";
      this.$emit("click_chiudi");
    },
    salvaNota() {
      this.$emit("salva_nota");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style> 
