<template>
  <div>
    <div v-if="dettaglio_ordine == false">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <v-row>
                      <v-col cols="4"
                        ><v-select
                          class="ml-4"
                          label="Operatore selezionato"
                          outlined
                          :items="operatori"
                          dense
                        ></v-select
                      ></v-col>
                    </v-row>
                    <v-row class="pa-3">
                      <v-col cols="12">
                        <v-toolbar
                          dark
                          color="blue"
                          style="border-radius: 5px 5px 0px 0px"
                          class="elevation-0"
                          ><v-toolbar-title>Lista Spedizioni</v-toolbar-title></v-toolbar
                        >
                        <v-data-table
                          :headers="headers"
                          :items="ordini"
                          :items-per-page="5"
                          class="elevation-3"
                        >
                          <template v-slot:item.dettagli="{ item }">
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="dettaglio(item)"
                                >
                                  <v-icon color="primary"
                                    >mdi-eye &nbsp;&nbsp;&nbsp;&nbsp;</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Dettaglio Spedizione</span>
                            </v-tooltip>
                          </template>
                        </v-data-table></v-col
                      >
                    </v-row>
                </div>
            </div>
        </div>

    </div>
    <div class="card" v-else>
        <div class="card-text">
          <br>
          <v-row>
            
            <v-col  cols="2" align="left">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="dettaglio_ordine = false"
                    ><v-icon color="blue">mdi-format-indent-increase</v-icon> Lista Completa</v-btn
                  >
                </template>
                <span>Lista Spedizioni Completa</span>
              </v-tooltip>
            </v-col>   
            <v-col cols="6"></v-col>                     
            <v-col cols="4" align="center">
            </v-col>


          </v-row>
        

          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="modalTab"
                grow
                style="color: black"
                slider-size="5"
                slider-color="primary accent-3 "
                color="black"
                @change="changeTab"
              >
                <v-tab :href="`#packaging`" style="max-width: 150px"
                  >Packaging</v-tab
                >
                <v-tab :href="`#pre_shipping`" style="max-width: 200px"
                  >PRE-SHIPPING</v-tab
                >
                <v-tab :href="`#shipping`" style="max-width: 200px">SHIPPING</v-tab>
              </v-tabs>
              <v-tabs-items v-model="modalTab" class="no-transition" touchless>
                <v-tab-item
                  :value="`packaging`"
                  transition="false"
                  class="no-transition"
                >
                  <PackagingDettaglio 
                    :id_anagrafica = this.id_anagrafica
                    :DESTINAZIONE = this.destinazione 
                    :TRIGGER = this.ricarica
                    @changeTab="changeTab" />
                </v-tab-item>

                <v-tab-item
                  :value="`pre_shipping`"
                  transition="false"
                  class="no-transition"
                >
                  <PreShipping 
                    :id_anagrafica = this.id_anagrafica
                    :TRIGGER = this.ricarica
                    @changeTab="changeTab"                    
                  />
                </v-tab-item>

                <v-tab-item
                  :value="`shipping`"
                  transition="false"
                  class="no-transition"
                >
                  <Shipping 
                    :id_anagrafica = this.id_anagrafica
                    :TRIGGER = this.ricarica
                    @changeTab="changeTab"                    
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </div> 
    </div>
  </div>
</template>
<script>
import PackagingDettaglio from "../../../components/PRODUCTION/LOGISTICA/Packaging_dettaglio.vue";
import PreShipping from "../../../components/PRODUCTION/LOGISTICA/Pre_shipping.vue";
import Shipping from "../../../components/PRODUCTION/LOGISTICA/Shipping.vue";
export default {
  components: {
    PackagingDettaglio,
    PreShipping,
    Shipping,
  },

  data() {
    return {
      articoli_imballo: [],
      headers_imballo: [],
      modalTab: "packaging",
      dettaglio_ordine: false,
      operatori: [],
      id_anagrafica:'',
      destinazione:'',
      ricarica: null,   
      headers: [
        {text: "Codice cliente", align: "start", sortable: false, value: "codice",},
        { text: "Ragione", value: "ragione_sociale" },
        { text: "Data Consegna", value: "data_consegna" },
        { text: "Peso Totale", value: "peso_totale" },
        { text: "Destinazione", value: "destinazione" },
        { text: "Dettagli", value: "dettagli", width: "50" },
      ],
      ordini: [],
      ordine: { cod_cliente: '', ragione_sociale: '', id_anagrafica: '',  data_consegna: '', destinazione_id_anagrafica: '', peso_totale: '', destinazione: ''},
      ordine_ragione_sociale: "   -   ",
    };
  },
  mounted() {
    this.getOperatori();
    this.getSpedizioni();
  },
  methods: {
    getOperatori() {
      let request = {
        controller: "Logistica",
        method: "POST",
        richiesta: {
          action: "getoperatori",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          tipo: "",
          db_name: "geclink_cavallo",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        //console.log(res.data.result.operatori)
        // Svuoto l'array che contiene le commesse in gestione
        this.operatori = [];

        // Popolo l'array 
        res.data.result.operatori.forEach((elem) => {
          this.operatori.push(elem.cognome);

        });
      });
    },
    getSpedizioni() {
      let request = {
        controller: "Logistica",
        method: "POST",
        richiesta: {
          action: "getspedizioni",
          token: localStorage.getItem("user_token"),
          id_utente: localStorage.getItem("id"),
          tipo: "",
          db_name: "geclink_cavallo",
        },
      };

      this.$store.dispatch("api", request).then((res) => {
      console.log(res.data.result.spedizioni)
      this.ordini = res.data.result.spedizioni
      });
    },
    changeTab(tab) {
      this.modalTab = tab;
      this.ricarica = Math.round(Math.random()*100)

    },

    dettaglio(item) {
      this.dettaglio_ordine = true;
      this.id_anagrafica = item.id_anagrafica;
      this.destinazione = item.destinazione

    },
  },
};
</script>