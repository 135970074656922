<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-8 px-5">
      <Attivita />
    </v-col>
  </v-row>
</template>
<script>
import Attivita from "@/components/MANUTENZIONE/Attivita.vue";
export default {
  name: "ManutenzioneAttivita",
  components: { Attivita },
  props: {},
  data() {
    return {
      // Variabili d'appoggio
      // Modal
      // Array
    };
  },
  computed: {},
  watch: {
    variabile: {
      immediate: true,
      handler() {},
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>