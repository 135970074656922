//
// Configure the encryption algorithm to use
//

const iv = window.crypto.getRandomValues(new Uint8Array(12));
const algorithm = {
  iv,
  name: 'AES-GCM',
};


function strToArrayBuffer(str) {
  var buf = new ArrayBuffer(str.length);
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToString(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

async function encrypt(matrice_permessi) {
  const encoder = new TextEncoder();
  const messageUTF8 = encoder.encode(matrice_permessi);

  //
  // Generate/fetch the cryptographic key
  //

  const key = await window.crypto.subtle.generateKey({
      name: 'AES-GCM',
      length: 256
    },
    true, [
      'encrypt',
      'decrypt'
    ]
  );

  //
  // Run the encryption algorithm with the key and data.
  //

  const messageEncryptedUTF8 = await window.crypto.subtle.encrypt(
    algorithm,
    key,
    messageUTF8,
  );

  //
  // Export Key
  //
  const exportedKey = await window.crypto.subtle.exportKey(
    'raw',
    key,
  );

  // This is where to save the exported key to the back-end server,
  // and then to fetch the exported key from the back-end server.
  localStorage.setItem("key", arrayBufferToString(exportedKey));
  localStorage.setItem("matrice_autorizzazioni", arrayBufferToString(messageEncryptedUTF8));
}

async function decrypt() {
  if(localStorage.getItem("key") != null){

    var key = strToArrayBuffer(localStorage.getItem("key"))
    var matrice_autorizzazioni =
      strToArrayBuffer(localStorage.getItem("matrice_autorizzazioni"));
  
    //
    // Import Key
    //
    const importedKey = await window.crypto.subtle.importKey(
      'raw',
      key,
      "AES-GCM",
      true, [
        "encrypt",
        "decrypt"
      ]
    );
  
    //
    // Run the decryption algorithm with the key and cyphertext.
    //
    try{
      const messageDecryptedUTF8 = await window.crypto.subtle.decrypt(
        algorithm,
        importedKey,
        matrice_autorizzazioni,
      );
    
      //
      // Decode the decryped data.
      //
      
      const decoder = new TextDecoder();
      const messageDecryptedDOMString = decoder.decode(messageDecryptedUTF8);
      
      return messageDecryptedDOMString;
    }catch(ex){
      return null;
    }
  }
}

export {
  encrypt,
  decrypt
}