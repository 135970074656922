<template>
  <v-card>
     <v-snackbar v-model="snackbar" :color="snackbar_color">{{ snackbar_text }}</v-snackbar>
      <v-row class="pa-3">
        <v-col cols="3">
            <v-text-field
            outlined
            label="Filtra tabella"
            v-model="search"
            append-icon="mdi-magnify"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">

          <v-toolbar
            dark
            color="#3F51B5"
            style="border-radius: 5px 5px 0px 0px"
            class="elevation-0"
            ><v-toolbar-title>Controllo DDT</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="array_controlloDDT"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
            item-key="id_magazzino_movimenti"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
          >
            <!-- Gestione datatable inteno a Tabella per il cambio di sottotabella vedi Watch su expanded-->
            <template v-slot:expanded-item="{headers}">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="headers_dettaglio"
                  :items="array_dettagliDDT"
                  hide-default-footer
                  :items-per-page="1000" 
                >
                <template v-slot:item.azioni="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined small v-bind="attrs" v-on="on" @click="stampaSingleDDT(item)">
                                <v-icon color="success"
                                >mdi-printer
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Stampa</span>
                    </v-tooltip> 
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined small v-bind="attrs" v-on="on" @click="Modifica_dettaglio(item)">
                                <v-icon color="success"
                                >mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Modifica Dettaglio</span>
                    </v-tooltip>  
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined small v-bind="attrs" v-on="on" @click="Conferma_Cancella_dettaglio(item)">
                                <v-icon color="red"
                                >mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Cancella Dettaglio</span>
                    </v-tooltip>  
                     
                    <v-tooltip top v-if="item.des_ubicazione == null">
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn outlined small v-bind="attrs" v-on="on" @click="Ubica_dettaglio(item)">
                              <v-icon color="blue"
                              >mdi-home-import-outline
                              </v-icon>
                          </v-btn>
                      </template>
                      <span>Ubica Pedana/Imballo</span>
                  </v-tooltip>

                </template>
                </v-data-table>
              </td>
            </template>   


            <template v-slot:item.stampa="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined small v-bind="attrs" v-on="on" @click="stampaAllDDT(item)">
                        <v-icon color="blue"
                        >mdi-printer
                        </v-icon>
                    </v-btn>
                </template>
                <span>Stampa</span>
            </v-tooltip>            
            </template>

            <template v-slot:item.saldo="{ item }">
              <v-tooltip top v-if="item.qtaRegistrata != null">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined small v-bind="attrs" v-on="on" @click="Rettifica_Movimento(item)">
                        <v-icon color="blue"
                        >mdi-stop-circle
                        </v-icon>
                    </v-btn>
                </template>
                <span>Rettifica Movimento</span>
            </v-tooltip>            
            </template>

            <template v-slot:item.seleziona="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined small v-bind="attrs" v-on="on" @click="AggiungiPedanaDDT(item)">
                        <v-icon color="success"
                        >mdi-check-outline
                        </v-icon>
                    </v-btn>
                </template>
                <span>Aggiungi Pedana a DDT</span>
            </v-tooltip>            
            </template>

          </v-data-table>
        </v-col>

      </v-row>

      <v-dialog v-model="dialog_dettaglio_controllo_DDT" width="80%" persistent>
        <v-toolbar
          dark
          color="#3F51B5"
          style="border-radius: 5px 5px 0px 0px"
          class="elevation-0"
          ><v-toolbar-title>Controllo DDT N°: {{DDTSelected.num_ddt}} per conto di {{ DDTSelected.ragione_sociale}} del {{ DDTSelected.data}} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
          light 
            @click="dialog_dettaglio_controllo_DDT = false"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-text-field dense label="Fornitore" v-model="DDTSelected.ragione_sociale" outlined readonly/>
              </v-col>
              <v-col cols="4">
                <v-text-field dense label="Ordine" v-model="DDTSelected.num_ordine" outlined readonly/>
              </v-col>
              <v-col cols="4">
                <v-text-field dense label="Articolo" v-model="DDTSelected.cod_articolo" outlined readonly/>
              </v-col>
              <v-col cols="8">
                <v-text-field dense label="Des. Articolo" v-model="DDTSelected.des_articolo" outlined readonly/>
              </v-col>
              <v-col cols="3">
                <v-text-field dense label="Lotto" v-model="DDTSelected.cod_lotto" outlined readonly/>
              </v-col>
              <v-col cols="3">
                <v-text-field dense label="Peso Netto" v-model="DDTSelected.peso_netto" outlined readonly/>
              </v-col>
              <v-col cols="3">
                <v-text-field dense label="Qta DDT" v-model="DDTSelected.qta_ddt" outlined readonly/>
              </v-col>
              <v-col cols="3">
                <v-text-field dense label="Qta Ordine" v-model="DDTSelected.qta_ordine" outlined readonly/>
              </v-col>  

              <v-col cols="3">
                <v-text-field dense label="Nome Imballo" v-model="dettaglio.nome_pedana" outlined />
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.n_pedana" min="0" label="N° Imballi/Pedane"/>
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.n_colli" min="0" label="N° Colli"/>
              </v-col> 
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.quantita" min="0" label="Quantità Riscontrata"/>
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.pmu" min="0" label="PMU"/>
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.peso_lordo" min="0" label="Peso Lordo"/>
              </v-col> 
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.peso_netto" min="0" label="Peso Netto"/>
              </v-col>
            </v-row>
          </v-card-text>
            <v-container>
              <v-btn
                color="success"
                dark
                @click="salva_dettaglioDDT"
                >conferma</v-btn
              >
            </v-container>
            <v-container v-if="vedi_dettaglio">
             <v-divider/>
             <v-card-title>
                Imballi/Pedane Selezionate
             </v-card-title>
              <v-card-text>
                <v-row>  
                  <v-col cols="1"> 
                  </v-col>
                  <v-col cols="10" >
                    <v-data-table
                      :headers="headers_aggiungi_Pedana"
                      :items="array_dettagliDDT"
                      hide-default-footer
                      :items-per-page="1000" 
                    />
                  </v-col>
                </v-row>  
              </v-card-text>
            </v-container>

        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_modifica_dettaglio_ddt" width="80%" persistent>
        <v-toolbar
          dark
          color="#3F51B5"
          style="border-radius: 5px 5px 0px 0px"
          class="elevation-0"
          ><v-toolbar-title>Modifica Pedana/Imballo </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
          light
            @click="dialog_modifica_dettaglio_ddt = false"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-text-field dense label="Nome Imballo" v-model="dettaglio.nome_pedana" outlined />
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.n_pedana" min="0" label="N° Imballi/Pedane"/>
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.n_colli" min="0" label="N° Colli"/>
              </v-col> 
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.quantita" min="0" label="Quantità Riscontrata"/>
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.pmu" min="0" label="PMU"/>
              </v-col>   
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.peso_lordo" min="0" label="Peso Lordo"/>
              </v-col> 
              <v-col cols="3">
                <v-text-field type="number" outlined v-model="dettaglio.peso_netto" min="0" label="Peso Netto"/>
              </v-col>  
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-btn
                color="success"
                dark
                @click="salva_dettaglioDDT"
                >conferma</v-btn
              >
            </v-container>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_ubica_pedana" width="60%" persistent>
        <v-toolbar
          dark
          color="#3F51B5"
          style="border-radius: 5px 5px 0px 0px"
          class="elevation-0"
          ><v-toolbar-title>Ubicazione Pedana/Imballo  </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
          light
            @click="dialog_ubica_pedana = false"
            ><v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field dense label="Nome Imballo" v-model="dettaglio.nome_pedana" outlined readonly/>
              </v-col>  
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  v-model="id_ubicazione_selected"
                  :items="ubicazioni"
                  dense
                      item-text="des"
                      item-value="id_tabella"
                  label="Seleziona Ubicazione"
                ></v-autocomplete>
              </v-col> 
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-btn
                color="success"
                dark
                @click="salva_ubicazione"
                >Ubica Pedana</v-btn
              >
            </v-container>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_del_conf" persistent max-width="500">
          <v-card>
              <v-card-title class="headline">
              Conferma Cancellazione.
              </v-card-title >
              <v-card-text align="left">Confermi la cancellazione dell'Imballo?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="dialog_del_conf = false">No</v-btn>
                  <v-btn color="blue darken-1" outlined text @click="Cancella_dettaglio">Si</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_rettifica" persistent max-width="500">
          <v-card>
              <v-card-title >
              Rettifica Movimento.
              </v-card-title >
              <br>
              <v-card-text align="left">Vuoi rettificare il movimento?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="dialog_rettifica = false">No</v-btn>
                  <v-btn color="blue darken-1" outlined text @click="salva_rettifica">Si</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>      

      <!-- Gestione dialog Stampa DDT -->
      <v-dialog  v-model="pdfdialog_AllDDT" width="unset">
          <v-card>
              <v-toolbar
                  class="white--text"
                  color="blue"
                  dense
                  style="position: sticky; top: 0px; z-index: 1"
                  >
                  <v-toolbar-title> Stampa Cartellini </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="red--text"
                      @click="pdfdialog_AllDDT = false"
                      color="blue-grey lighten-5"
                      small
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-toolbar>
              <v-row>
                  <v-col cols="12" sm="6" md="12">
                      <iframe
                          :src="this.selectedPdf"
                          frameborder="0"
                          style="height: 90vh !important; width: 90vw !important"
                      >
                      </iframe>
                  </v-col>
              </v-row>
          </v-card>
          
      </v-dialog>  

      <!-- Gestione dialog Stampa DDT Singolo -->
      <v-dialog  v-model="pdfdialog_SingleDDT" width="unset">
          <v-card>
              <v-toolbar
                  class="white--text"
                  color="blue"
                  dense
                  style="position: sticky; top: 0px; z-index: 1"
                  >
                  <v-toolbar-title> Stampa Cartellini </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="red--text"
                      @click="pdfdialog_SingleDDT = false"
                      color="blue-grey lighten-5"
                      small
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-toolbar>
              <v-row>
                  <v-col cols="12" sm="6" md="12">
                      <iframe
                          :src="this.selectedPdf"
                          frameborder="0"
                          style="height: 90vh !important; width: 90vw !important"
                      >
                      </iframe>
                  </v-col>
              </v-row>
          </v-card>
          
      </v-dialog>

      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>



  </v-card>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_text: "",

      file_b64:'',
      selectedPdf:'',
      pdfdialog_AllDDT: false,
      pdfdialog_SingleDDT: false,

      singleExpand: true,
      expanded: [],
      AAA:[],

      search: "",
      overlay: false,

      dialog_dettaglio_controllo_DDT: false,
      dialog_modifica_dettaglio_ddt: false,
      dialog_del_conf: false,
      dialog_ubica_pedana: false,
      dialog_rettifica: false,
      
      id_ubicazione_selected: 0,
      ubicazioni: [],

      vedi_dettaglio: false,
 
      headers: [
        { text: "", value: "stampa" },
        { text: "Fornitore", value: "ragione_sociale" },
        { text: "Articolo", value: "cod_articolo" },
        { text: "Des Articolo", value: "des_articolo" },
        { text: "Cod Lotto", value: "cod_lotto" },
        { text: "N° DDT", value: "num_ddt" },
        { text: "Qtà DDT", value: "qta_ddt" },
        { text: "N° Ordine", value: "num_ordine" },
        { text: "Qtà Ordine", value: "qta_ordine" },
        { text: "", value: "saldo" },
        { text: "Qtà Controllata", value: "qtaRegistrata" },
        { text: "Data", value: "data" },
        { text: "", value: "seleziona" },
      ],
      array_controlloDDT: [],

      DDTSelected : {id_magazzino_movimenti: 0, cod_articolo: "", cod_lotto: "", data: "", des_articolo: "", id_articolo: 0, id_lotto: 0, num_ddt: "", num_ordine: 0, peso_netto: 0, qta_ddt: 0, qta_ordine: "", ragione_sociale: ""},
      

      headers_dettaglio: [
        { text: "Nome Imballo ", value: "nome_pedana" },
        { text: "N°Colli x Imballo", value: "n_colli" },
        { text: "N° Imballi", value: "n_pedana" },
        { text: "PMU", value: "pmu" },
        { text: "Peso Lordo", value: "peso_lordo" },
        { text: "Peso Netto", value: "peso_netto" },
        { text: "Quantità", value: "quantita" },
        { text: "Ubicazione", value: "des_ubicazione" },
        { text: "", value: "azioni", width: "20%" },
      ],

      array_dettagliDDT: [],
      array_dettagliDDT_ORI: [],

      dettaglio: { id_magazzino_movimenti_controllo_arrivi: -1 , id_magazzino_movimento_rif: null, id_pedana: 0, n_colli: 0, n_pedana: 0, des_ubicazione: '', nome_pedana: '', peso_lordo: 0, peso_netto: 0, pmu: 0, quantita: 0, validita: 1},

      headers_aggiungi_Pedana: [
        { text: "Nome Imballo ", value: "nome_pedana" },
        { text: "N°Colli x Imballo", value: "n_colli" },
        { text: "N° Imballi", value: "n_pedana" },
        { text: "PMU", value: "pmu" },
        { text: "Peso Lordo", value: "peso_lordo" },
        { text: "Peso Netto", value: "peso_netto" },
        { text: "Quantità", value: "quantita" },
        { text: "Ubicazione", value: "des_ubicazione" },
      ],

      rettifica_qtaRegistrata: 0,
      rettifica_id_magazzino_movimenti: 0,
  
    };
  },
  props: {
    TRIGGER: Number,
  },
  watch: {
    expanded: {
      immediate: true,
      handler() {
          this.setItemsDettaglioDDT();
      }
    },
    TRIGGER: {
      immediate: true,
      handler() {
          this.getData();
      }
    },
  },
  mounted() {
      //console.log ("componente controllo DDT montato")
      this.getubicazioni()
  },  
  methods: {
    
    getubicazioni(){
        this.overlay = true
        let request = {
          controller: "Global",
          method: "POST",
          richiesta: {
            action: "gettabella",
            tipo: "UBICAZIONE_STANDARD",
            filter: "validita = 1",            
            token: localStorage.getItem("user_token"),
          },
        };

        this.$store.dispatch("api", request).then((res) => {
          this.ubicazioni = res.data
          this.overlay = false
        })
        .catch(e => {
            this.overlay = false
            console.log(e);
        });
    },

    getData() {
        this.overlay = true
        let request = {
          controller: "Magazzino",
          method: "POST",
          richiesta: {
            action: "getcontrolloddt",
            //stato_controllo: 1, non controllati non serve, = 1 se controllati altro tab
            token: localStorage.getItem("user_token"),
          },
        };

        this.array_controlloDDT = []
        this.expanded = [] //chiudo tu tutti gli expanded
        this.$store.dispatch("api", request).then((res) => {
          this.array_controlloDDT = res.data.DDT
          this.array_dettagliDDT = res.data.dettaglio
          this.array_dettagliDDT_ORI = res.data.dettaglio

          this.overlay = false
        })
        .catch(e => {
            this.overlay = false
            console.log(e);
        });
    },

    AggiungiPedanaDDT(item) { //nuovo controllo DDT

     let obj_DDTSelected = this.alasql(
          "SELECT * FROM ? WHERE id_magazzino_movimenti = " + item.id_magazzino_movimenti ,
          [this.array_controlloDDT],
      );
     this.DDTSelected = obj_DDTSelected[0]

      this.dettaglio.id_magazzino_movimento_rif = item.id_magazzino_movimenti
      this.dettaglio.id_magazzino_movimenti_controllo_arrivi = -1
      this.dettaglio.nome_pedana = ""
      this.dettaglio.n_pedana = 0
      this.dettaglio.n_colli = 0
      this.dettaglio.quantita = 0
      this.dettaglio.pmu = 0
      this.dettaglio.peso_lordo = 0
      this.dettaglio.peso_netto = 0
      this.dettaglio.des_ubicazione = ""
      
      this.array_dettagliDDT = this.alasql(
          "SELECT * FROM ? WHERE id_magazzino_movimento_rif = " + item.id_magazzino_movimenti ,
          [this.array_dettagliDDT_ORI],
      );

      this.vedi_dettaglio =  (this.array_dettagliDDT.length > 0) ? true : false; 

      this.dialog_dettaglio_controllo_DDT = true;
    },

    setItemsDettaglioDDT(){
      //Setto il nuovo datasource ogni volta che viene selezionato l'expanded
      if (this.expanded[0] != null){
        //console.log( "id_magazzino_movimenti selezionato: ", this.expanded[0].id_magazzino_movimenti )
        let id = (this.expanded[0].id_magazzino_movimenti != null) ? this.expanded[0].id_magazzino_movimenti : -1
        this.array_dettagliDDT = this.alasql(
            "SELECT * FROM ? WHERE id_magazzino_movimento_rif = " + id ,
            [this.array_dettagliDDT_ORI],
        );
       // localStorage.setItem("id_magazzino_movimenti", id);
       // this.AAA = this.expanded
      }

    },

    salva_dettaglioDDT(){

      //console.log(this.dettaglio)

      let dettaglio_in_array = [];
      dettaglio_in_array.push(this.dettaglio);
             
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcontrollodettaglioddt",
          ddt: dettaglio_in_array,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          this.dialog_modifica_dettaglio_ddt = false;
          this.dialog_dettaglio_controllo_DDT= false;
          this.getData();
         // this.expanded = this.AAA 
        //  this.array_dettagliDDT = this.alasql(
        //    "SELECT * FROM ? WHERE id_magazzino_movimento_rif = " + localStorage.getItem("id_magazzino_movimenti") ,
         //   [this.array_dettagliDDT_ORI],
         // )
         
        }
      })
      .catch(e => {
          console.log(e);
      });

    },


    Modifica_dettaglio(item){
      //  console.log(item)
        
          this.dettaglio.id_magazzino_movimenti_controllo_arrivi = item.id_magazzino_movimenti_controllo_arrivi
          this.dettaglio.id_magazzino_movimento_rif = item.id_magazzino_movimento_rif
          this.dettaglio.id_pedana = item.id_pedana
          this.dettaglio.validita = item.validita 
          
          this.dettaglio.n_colli = item.n_colli
          this.dettaglio.n_pedana = item.n_pedana
          this.dettaglio.nome_pedana = item.nome_pedana
          this.dettaglio.peso_lordo = item.peso_lordo
          this.dettaglio.peso_netto = item.peso_netto
          this.dettaglio.pmu = item.pmu
          this.dettaglio.quantita = item.quantita
          this.dettaglio.des_ubicazione = item.des_ubicazione
                 
        
       this.dialog_modifica_dettaglio_ddt = true;
       
    },

    Conferma_Cancella_dettaglio(item){

      this.dettaglio.id_magazzino_movimenti_controllo_arrivi = item.id_magazzino_movimenti_controllo_arrivi
      this.dettaglio.id_magazzino_movimento_rif = item.id_magazzino_movimento_rif
      this.dettaglio.id_pedana = item.id_pedana
      
      this.dettaglio.n_colli = item.n_colli
      this.dettaglio.n_pedana = item.n_pedana
      this.dettaglio.nome_pedana = item.nome_pedana
      this.dettaglio.peso_lordo = item.peso_lordo
      this.dettaglio.peso_netto = item.peso_netto
      this.dettaglio.pmu = item.pmu
      this.dettaglio.quantita = item.quantita
      this.dettaglio.des_ubicazione = item.des_ubicazione
      this.dialog_del_conf = true
    },

    Cancella_dettaglio(){
      this.dettaglio.validita = 0
      this.salva_dettaglioDDT()
      this.dialog_del_conf = false
    },

    Rettifica_Movimento(item){
      this.rettifica_qtaRegistrata = item.qtaRegistrata
      this.rettifica_id_magazzino_movimenti = item.id_magazzino_movimenti
      this.dialog_rettifica = true
    },


    salva_rettifica(){
      //console.log("ID_ubicazione: ", this.id_ubicazione_selected)
      //console.log("ID_movim_dett: ", this.dettaglio.id_magazzino_movimenti_controllo_arrivi)

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setarrivoddt",
          qtaRegistrata: this.rettifica_qtaRegistrata,
          id_magazzino_movimenti: this.rettifica_id_magazzino_movimenti,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          this.dialog_rettifica= false;
          this.getData()

        }
      })
      .catch(e => {
          console.log(e);
      });

    },



    Ubica_dettaglio(item){
      this.dettaglio.id_magazzino_movimenti_controllo_arrivi = item.id_magazzino_movimenti_controllo_arrivi
      this.dettaglio.id_magazzino_movimento_rif = item.id_magazzino_movimento_rif
      this.dettaglio.id_pedana = item.id_pedana
      
      this.dettaglio.n_colli = item.n_colli
      this.dettaglio.n_pedana = item.n_pedana
      this.dettaglio.nome_pedana = item.nome_pedana
      this.dettaglio.peso_lordo = item.peso_lordo
      this.dettaglio.peso_netto = item.peso_netto
      this.dettaglio.pmu = item.pmu
      this.dettaglio.quantita = item.quantita
      this.dettaglio.des_ubicazione = item.des_ubicazione
      this.dialog_ubica_pedana = true
    },

    salva_ubicazione(){
      //console.log("ID_ubicazione: ", this.id_ubicazione_selected)
      //console.log("ID_movim_dett: ", this.dettaglio.id_magazzino_movimenti_controllo_arrivi)

      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setubicazione",
          id_tabella: this.id_ubicazione_selected,
          id_movimento_magazzino: this.dettaglio.id_magazzino_movimenti_controllo_arrivi,
          token: localStorage.getItem("user_token"),
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        if (res.status == 200){
          this.dialog_ubica_pedana= false;
          this.getData()

        }
      })
      .catch(e => {
          console.log(e);
      });

    },

    async stampaAllDDT(item){
        await this.getStampaAllDDT(item.id_magazzino_movimenti);
        const byteCharacters = atob(this.file_b64);
        const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        this.pdfdialog_AllDDT = true;
    },
    getStampaAllDDT(_id_magazzino_movimenti) {
        return new Promise((resolve, reject) => {
            this.overlay = true;
            let disegnoReq = {
                controller: "Magazzino",
                method: "POST",
                richiesta: {
                action: "printcartellino",
                id_magazzino_movimenti: _id_magazzino_movimenti,
                token: localStorage.getItem("user_token"),
                },
            };
            this.file_b64 = '';
            this.$store.dispatch("api", disegnoReq).then((res) => {
                this.file_b64 = res.data
                this.overlay = false
                resolve(res);
                return;
            })
            .catch((e) => {
                this.overlay = false;
                reject(e);
                console.log(e);
            });
        });
    },  

    async stampaSingleDDT(item){
        await this.getStampaSingleDDT(item.id_magazzino_movimenti_controllo_arrivi);
        const byteCharacters = atob(this.file_b64);
        const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        this.selectedPdf = blobUrl;
        this.pdfdialog_SingleDDT = true;
    },
    getStampaSingleDDT(_id_magazzino_movimenti_controllo_arrivi) {
        return new Promise((resolve, reject) => {
            this.overlay = true;
            let disegnoReq = {
                controller: "Magazzino",
                method: "POST",
                richiesta: {
                action: "printcartellino",
                id_magazzino_controllo_arrivi: _id_magazzino_movimenti_controllo_arrivi,
                token: localStorage.getItem("user_token"),
                },
            };
            this.file_b64 = '';
            this.$store.dispatch("api", disegnoReq).then((res) => {
                this.file_b64 = res.data
                this.overlay = false
                resolve(res);
                return;
            })
            .catch((e) => {
                this.overlay = false;
                reject(e);
                console.log(e);
            });
        });
    },  
  
  }


};
</script>