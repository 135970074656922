var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"color":"indigo","dense":"","fixed":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Descrizione")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.readonly===false)?_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.salvaRisposta}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-floppy ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Salva Risposta")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"10"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.domanda_sel_tmp.des_quesito))]),_c('v-divider',{staticClass:"mt-4 mb-5"}),_c('v-container',{staticClass:"ma-0"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"mx-3",staticStyle:{"font-size":"18px"}},[_vm._v("Esito:")]),_c('v-icon',{attrs:{"color":_vm.domanda_sel_tmp.esito == '1' ? 'success' : ''},on:{"click":function($event){_vm.readonly === false
                      ? (_vm.domanda_sel_tmp.esito = '1')
                      : _vm.$refs.SnackbarQV.print(
                          'success',
                          "Collaudo terminato: la checklist è in modalità di sola lettura."
                        )}},model:{value:(_vm.domanda_sel_tmp.esito),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "esito", $$v)},expression:"domanda_sel_tmp.esito"}},[_vm._v(" "+_vm._s(_vm.domanda_sel_tmp.esito === "1" ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('v-icon',{attrs:{"color":_vm.domanda_sel_tmp.esito == '0' ? 'error' : ''},on:{"click":function($event){_vm.readonly === false
                      ? (_vm.domanda_sel_tmp.esito = '0')
                      : _vm.$refs.SnackbarQV.print(
                          'success',
                          "Collaudo terminato: la checklist è in modalità di sola lettura."
                        )}},model:{value:(_vm.domanda_sel_tmp.esito),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "esito", $$v)},expression:"domanda_sel_tmp.esito"}},[_vm._v(" "+_vm._s(_vm.domanda_sel_tmp.esito === "0" ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")]),_c('v-icon',{attrs:{"color":_vm.domanda_sel_tmp.esito != null &&
                    _vm.domanda_sel_tmp.esito != undefined &&
                    _vm.domanda_sel_tmp.esito != ''
                      ? ''
                      : 'primary'},on:{"click":function($event){_vm.readonly === false
                      ? (_vm.domanda_sel_tmp.esito = null)
                      : _vm.$refs.SnackbarQV.print(
                          'success',
                          "Collaudo terminato: la checklist è in modalità di sola lettura."
                        )}},model:{value:(_vm.domanda_sel_tmp.esito),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "esito", $$v)},expression:"domanda_sel_tmp.esito"}},[_vm._v(" "+_vm._s(_vm.domanda_sel_tmp.esito != null && _vm.domanda_sel_tmp.esito != undefined && _vm.domanda_sel_tmp.esito != "" ? "mdi-checkbox-blank-outline" : "mdi-help-box")+" ")])],1)],1)],1),(_vm.domanda_sel_tmp.tipo_quesito === _vm.id_domanda_descrittiva)?_c('v-textarea',{attrs:{"outlined":"","label":"Risposta","disabled":_vm.readonly},model:{value:(_vm.domanda_sel_tmp.note),callback:function ($$v) {_vm.$set(_vm.domanda_sel_tmp, "note", $$v)},expression:"domanda_sel_tmp.note"}}):_vm._e()],1)],1)],1),(
        _vm.domanda_sel_tmp.tipo_quesito === _vm.id_domanda_immagine &&
        _vm.id_domanda_immagine != null
      )?_c('Controllo',{attrs:{"clear_on_save":false,"immagine_standard_editor":_vm.domanda_sel_tmp.img != null ? _vm.domanda_sel_tmp.img : '',"show_close_button":false,"show_editor_arrow_button":!_vm.readonly,"show_editor_camera_button":!_vm.readonly,"show_editor_circle_button":!_vm.readonly,"show_editor_color_picker_button":!_vm.readonly,"show_editor_crop_button":!_vm.readonly,"show_editor_edit_back_button":!_vm.readonly,"show_editor_edit_forward_button":!_vm.readonly,"show_editor_eraser_button":!_vm.readonly,"show_editor_free_draw_button":!_vm.readonly,"show_editor_large_size_button":!_vm.readonly,"show_editor_letter_case_button":!_vm.readonly,"show_editor_medium_size_button":!_vm.readonly,"show_editor_save_button":false,"show_editor_small_size_button":!_vm.readonly,"show_editor_square_button":!_vm.readonly,"show_editor_tool_columns":!_vm.readonly,"show_editor_upload_button":!_vm.readonly,"tabella_supporto":'',"titolo_editor":"Editor d'Immagine","visible":true}}):_vm._e()],1),_c('SnackbarQV',{ref:"SnackbarQV"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }