var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",attrs:{"id":"listener_visualizzatore"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticStyle:{"height":"337.5px","padding":"0px","text-align":"center"},attrs:{"cols":"8"}},[_c('svg',{staticStyle:{"background-size":"cover","display":"inline-block","height":"337.5px","outline":"1px solid black","width":"450px"},attrs:{"id":"svg","xmlns":"http://www.w3.org/2000/svg","xmlns:svg":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"},on:{"mousedown":function($event){return _vm.mouseDownCanvas($event)},"mousemove":function($event){return _vm.mouseMoveCanvas($event)},"mouseup":function($event){return _vm.mouseUpCanvas()}}},[_c('defs',{attrs:{"id":"defs_svg"}})]),_c('figure',[_c('svg',[_c('marker',{attrs:{"id":"arrowend","viewBox":"0 0 24 24","refX":"12","refY":"12","markerWidth":"6","markerHeight":"6","orient":"auto-start-reverse"}},[_c('path',{attrs:{"d":"M2,21L23,12L2,3V10L17,12L2,14V21Z"}})])])])]),(_vm.show_editor_tool_columns)?_c('v-col',{attrs:{"cols":"2"}},[(_vm.show_editor_save_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.setImmagine()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-floppy")])],1)]}}],null,false,474548809)},[_c('span',[_vm._v("Salva")])])],1):_vm._e(),(_vm.show_editor_upload_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.caricaDaLocale()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-upload")])],1)]}}],null,false,2490686927)},[_c('span',[_vm._v("Carica Immagine")])])],1):_vm._e(),(_vm.show_editor_camera_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"medium":""},on:{"click":function($event){_vm.fotocamera_dialog = !_vm.fotocamera_dialog}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-camera ")])],1)]}}],null,false,1991987267)},[_c('span',[_vm._v("Cattura Immagine")])])],1):_vm._e(),(_vm.show_editor_small_size_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.width_draw === 2 ? 'primary' : '',"depressed":_vm.width_draw === 2},on:{"click":function($event){_vm.width_draw = 2}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.width_draw === 2 ? 'white' : 'primary'}},[_vm._v("mdi-size-s")])],1)]}}],null,false,3214872039)},[_c('span',[_vm._v("Tratto Sottile")])])],1):_vm._e(),(_vm.show_editor_medium_size_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.width_draw === 5 ? 'primary' : '',"depressed":_vm.width_draw === 5},on:{"click":function($event){_vm.width_draw = 5}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.width_draw === 5 ? 'white' : 'primary'}},[_vm._v("mdi-size-m")])],1)]}}],null,false,1521945209)},[_c('span',[_vm._v("Tratto Medio")])])],1):_vm._e(),(_vm.show_editor_large_size_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.width_draw === 10 ? 'primary' : '',"depressed":_vm.width_draw === 10},on:{"click":function($event){_vm.width_draw = 10}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.width_draw === 10 ? 'white' : 'primary'}},[_vm._v("mdi-size-l")])],1)]}}],null,false,1334653656)},[_c('span',[_vm._v("Tratto Largo")])])],1):_vm._e(),(_vm.show_editor_eraser_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.clickPulisciEditor}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eraser")])],1)]}}],null,false,2452585144)},[_c('span',[_vm._v("Pulisci Editor")])])],1):_vm._e(),(_vm.show_editor_color_picker_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:({
                  backgroundColor: _vm.colore,
                  border: '1px solid white',
                  display: 'inline-block',
                  height: '35px',
                  width: '35px',
                  borderRadius: '5px',
                  marginLeft: '15px',
                  marginRight: '15px',
                })},'div',attrs,false),on))]}}],null,false,2680620965)},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.colore),callback:function ($$v) {_vm.colore=$$v},expression:"colore"}})],1)],1):_vm._e()],1):_vm._e(),(_vm.show_editor_tool_columns)?_c('v-col',{attrs:{"cols":"2"}},[(_vm.show_editor_crop_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.currentActiveMethod === 'ritaglia' ? 'primary' : '',"depressed":_vm.currentActiveMethod === 'ritaglia'},on:{"click":_vm.clickRitaglia}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.currentActiveMethod === 'ritaglia' ? 'white' : 'primary'}},[_vm._v("mdi-crop")])],1)]}}],null,false,2528043638)},[_c('span',[_vm._v("Ritaglia")])])],1):_vm._e(),(_vm.show_editor_arrow_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.currentActiveMethod === 'aggiungi_freccia' ? 'primary' : '',"depressed":_vm.currentActiveMethod === 'aggiungi_freccia'},on:{"click":_vm.clickAggiungiFreccia}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.currentActiveMethod === 'aggiungi_freccia'
                      ? 'white'
                      : 'primary'}},[_vm._v("mdi-arrow-down")])],1)]}}],null,false,2340861633)},[_c('span',[_vm._v("Inserisci Freccia")])])],1):_vm._e(),(_vm.show_editor_square_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.currentActiveMethod === 'aggiungi_quadrato' ? 'primary' : '',"depressed":_vm.currentActiveMethod === 'aggiungi_quadrato'},on:{"click":_vm.clickAggiungiQuadrato}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.currentActiveMethod === 'aggiungi_quadrato'
                      ? 'white'
                      : 'primary'}},[_vm._v("mdi-square-outline")])],1)]}}],null,false,373018731)},[_c('span',[_vm._v("Inserisci Quadrato")])])],1):_vm._e(),(_vm.show_editor_circle_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.currentActiveMethod === 'aggiungi_cerchio' ? 'primary' : '',"depressed":_vm.currentActiveMethod === 'aggiungi_cerchio'},on:{"click":_vm.clickAggiungiCerchio}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.currentActiveMethod === 'aggiungi_cerchio'
                      ? 'white'
                      : 'primary'}},[_vm._v("mdi-circle-outline")])],1)]}}],null,false,3379462136)},[_c('span',[_vm._v("Inserisci Cerchio")])])],1):_vm._e(),(_vm.show_editor_letter_case_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.currentActiveMethod === 'aggiungi_testo' ? 'primary' : '',"depressed":_vm.currentActiveMethod === 'aggiungi_testo'},on:{"click":_vm.clickAggiungiTesto}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.currentActiveMethod === 'aggiungi_testo'
                      ? 'white'
                      : 'primary'}},[_vm._v("mdi-format-letter-case")])],1)]}}],null,false,3109576942)},[_c('span',[_vm._v("Inserisci Testo")])])],1):_vm._e(),(_vm.show_editor_free_draw_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.currentActiveMethod === 'aggiungi_disegno_libero'
                    ? 'primary'
                    : '',"depressed":_vm.currentActiveMethod === 'aggiungi_disegno_libero'},on:{"click":_vm.clickAggiungiDisegnoLibero}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.currentActiveMethod === 'aggiungi_disegno_libero'
                      ? 'white'
                      : 'primary'}},[_vm._v("mdi-pencil")])],1)]}}],null,false,2853697637)},[_c('span',[_vm._v("Disegno Libero")])])],1):_vm._e(),(_vm.show_editor_edit_back_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.clickUndo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-undo")])],1)]}}],null,false,2391038802)},[_c('span',[_vm._v("Annulla Modifica")])])],1):_vm._e(),(_vm.show_editor_edit_forward_button === true)?_c('v-row',{staticStyle:{"margin":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":_vm.clickRedo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-redo")])],1)]}}],null,false,1634377362)},[_c('span',[_vm._v("Ripristina Modifica")])])],1):_vm._e()],1):_vm._e()],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_input_testo_canvas === true),expression:"show_input_testo_canvas === true"}],staticClass:"elevation-3 px-5",style:({
      borderRadius: 12 + 'px',
      backgroundColor: 'white',
      left: _vm.click_down_x + 'px',
      position: 'absolute',
      top: _vm.click_down_y + 'px',
      zIndex: 8,
    }),attrs:{"id":"container_input_canvas"}},[_c('div',[_c('v-text-field',{staticStyle:{"z-index":"0"},attrs:{"id":"input_testo_canvas"},model:{value:(_vm.testo_canvas),callback:function ($$v) {_vm.testo_canvas=$$v},expression:"testo_canvas"}})],1),_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"9"}},[_vm._v(" Dimensione: "),_c('v-text-field',{staticClass:"mx-1",staticStyle:{"display":"inline-block","width":"40px"},attrs:{"type":"number"},model:{value:(_vm.testo_canvas_dimensione),callback:function ($$v) {_vm.testo_canvas_dimensione=$$v},expression:"testo_canvas_dimensione"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mx-2",style:({
                  borderRadius: 5 + 'px',
                  backgroundColor: _vm.testo_canvas_colore,
                  border: '1px solid white',
                  display: 'inline-block',
                  height: '28px',
                  width: '28px',
                })},'div',attrs,false),on))]}}])},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.testo_canvas_colore),callback:function ($$v) {_vm.testo_canvas_colore=$$v},expression:"testo_canvas_colore"}})],1)],1)],1)],1)]),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.upload_file_visible),callback:function ($$v) {_vm.upload_file_visible=$$v},expression:"upload_file_visible"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Carica Immagine")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.chiudiUploadFile}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"text-align":"center"}},[_c('v-file-input',{attrs:{"accept":"image/*","type":"file"},on:{"change":function($event){return _vm.uploadImage(_vm.img_upload_editor)}},model:{value:(_vm.img_upload_editor),callback:function ($$v) {_vm.img_upload_editor=$$v},expression:"img_upload_editor"}})],1)],1)],1),_c('v-dialog',{model:{value:(_vm.fotocamera_dialog),callback:function ($$v) {_vm.fotocamera_dialog=$$v},expression:"fotocamera_dialog"}},[_c('Camera',{attrs:{"visible":_vm.fotocamera_dialog},on:{"click_chiudi":function($event){_vm.fotocamera_dialog = false},"getimage":function($event){return _vm.riceviImmagineDaCamera($event)},"scattata_foto":function($event){_vm.fotocamera_dialog = false}}})],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.scegli_salvataggio_dialog),callback:function ($$v) {_vm.scegli_salvataggio_dialog=$$v},expression:"scegli_salvataggio_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Modalità di Salvataggio Immagine")])],1),_c('v-card-text',{staticClass:"mt-5",staticStyle:{"overflow-y":"auto"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Vuoi sostituire la versione precedente (S) o salvare una nuova immagine (N)? ")]),_c('v-divider',{staticClass:"my-5"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.setImmagineSostituzione}},'v-btn',attrs,false),on),[_vm._v(" S ")])]}}])},[_c('span',[_vm._v("Sostituisci")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.setImmagineNuova}},'v-btn',attrs,false),on),[_vm._v(" N ")])]}}])},[_c('span',[_vm._v("Nuovo Salvataggio")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }