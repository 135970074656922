<template>
    <v-dialog persistent v-model="ui.show" width="800">
        <v-card>
            <!-- HEADER -->
            <v-toolbar dark dense>
                <v-toolbar-title>Opzioni Numero</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="ui.show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <v-row justify="center" v-if="store.number!=undefined">
                    <v-col cols="4">
                        <v-checkbox v-model="store.number.divider" label="Separa le migliaia" />
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox v-model="store.number.limit" label="Limita cifre decimali" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="number" min="0" :disabled="!store.number.limit" v-model="store.number.amount" />
                    </v-col>
                </v-row>
            </v-container>
            <!-- TASTO SALVA -->
            <v-card-actions>
                <v-spacer />
                <v-btn text color="success" @click="save">
                    <v-icon class="mr-2">mdi-floppy</v-icon>Salva modifiche</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
<script>
export default {
    name : 'GraphEditorNumero',
    props : {
        value: Object
    },
    data(){
        return{
            index : -1,
            store : {},
            template : {
                divider : false,
                limit : false,
                amount : 0
            },
            ui : {
                show : false
            }
        }
    },
    mounted(){
        document.body.addEventListener('graph_editor_numero',this.show_editor);
    },
    beforeDestroy(){
        document.body.removeEventListener('graph_editor_numero',this.show_editor);
    },
    destroyed(){ window.freeComponent(this); },
    methods:{
        save(){
            let value = Object.assign({},this.value);
            let encoded = JSON.stringify(this.store);
            // codifica del campo EXTRA come json codificato in base64 (con supporto per UTF-8)
            value.serie[this.index].extra = btoa(unescape(encodeURIComponent(encoded)));
            this.$emit('input',value);
            this.ui.show = false;
        },
        show_editor(data){
            data = data.detail;
            this.index = this.value.serie.indexOf(data);
            if(this.index < 0){return}
            try{
                // il campo EXTRA è un json codificato in base64 (con supporto per UTF-8)
                this.store = JSON.parse(decodeURIComponent(escape(atob(this.value.serie[this.index].extra))));                
                if(this.store.number==undefined){
                    this.store.number = Object.assign({},this.template);
                }
            }catch(error){
                this.store = {
                    number : Object.assign({},this.template)
                }         
            } 
            this.ui.show = true;
        }
    }
}
</script>