var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"color":"indigo","dense":"","fixed":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Lista di Picking ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
            _vm.cod_profilo === 'capobarca' ||
            _vm.cod_profilo === 'capofficina' ||
            _vm.cod_profilo === 'Admin' ||
            _vm.cod_profilo === 'manager' ||
            _vm.cod_profilo === 'CNXBOX' ||
            _vm.cod_profilo === 'SVIL'
          )?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.clickSpecificheOrdine}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-clipboard-list")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Specifiche Ordine")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(
            _vm.cod_profilo === 'magazziniere' ||
            _vm.cod_profilo === 'capofficina' ||
            _vm.cod_profilo === 'manager' ||
            _vm.cod_profilo === 'Admin' ||
            _vm.cod_profilo === 'CNXBOX' ||
            _vm.cod_profilo === 'SVIL'
          )?_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.clickSpecificheSpedizione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-cube-send")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Specifiche Spedizione")])]),_c('v-spacer'),(_vm.filtri_visible === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.filtri_visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter")])],1)]}}],null,false,3068551005)},[_c('span',[_vm._v("Filtri")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.ripristina}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple lighten-2"}},[_vm._v("mdi-filter-off")])],1)]}}])},[_c('span',[_vm._v("Ripristina")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":_vm.filtra_prenotazione === true ? 'purple lighten-2' : '',"small":""},on:{"click":_vm.filtraProdotti}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filtra_prenotazione === true ? 'white' : 'purple lighten-2'}},[_vm._v("mdi-package-up")])],1)]}}])},[_c('span',[_vm._v("Filtra Pezzi Prodotti")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":_vm.filtra_ordinati === true ? 'purple lighten-2' : '',"small":""},on:{"click":_vm.filtraOrdinati}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filtra_ordinati === true ? 'white' : 'purple lighten-2'}},[_vm._v(" mdi-cart ")])],1)]}}])},[_c('span',[_vm._v("Filtra Pezzi Ordinati")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":_vm.filtra_spedizione === true ? 'purple lighten-2' : '',"small":""},on:{"click":_vm.filtraSpedizione}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filtra_spedizione === true ? 'white' : 'purple lighten-2'}},[_vm._v("mdi-truck-delivery")])],1)]}}])},[_c('span',[_vm._v("Filtra Pezzi Spediti")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-2 pa-0"}),[_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.elementi,"item-key":"nome","height":"75vh","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.filtri_visible)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Barca"},on:{"input":_vm.filtra},model:{value:(_vm.matricola_search),callback:function ($$v) {_vm.matricola_search=$$v},expression:"matricola_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.matricola_search = '';
                      _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.elementi_barca,"item-text":function (e) { return e.cod_articolo1; },"item-value":"cod_articolo1","label":"Gruppo"},on:{"change":_vm.filtra},model:{value:(_vm.gruppo_search),callback:function ($$v) {_vm.gruppo_search=$$v},expression:"gruppo_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.gruppo_search = '';
                      _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.elementi_barca,"item-text":function (e) { return e.cod_articolo2; },"item-value":"cod_articolo2","label":"Cod. Articolo"},on:{"change":_vm.filtra},model:{value:(_vm.cod_articolo_search),callback:function ($$v) {_vm.cod_articolo_search=$$v},expression:"cod_articolo_search"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.cod_articolo_search = '';
                      _vm.filtra();}}},[_vm._v(" mdi-filter-off ")])],1)],2)],1)],1):_vm._e()]},proxy:true},{key:"item.des",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.des == 'Aperta' && item.data_produzione === null
                    ? 'error'
                    : item.des == 'Aperta' && item.data_produzione != null
                    ? 'cyan darken-3'
                    : item.des == 'Chiusa' && item.data_preparazione === null
                    ? 'primary'
                    : item.des == 'Chiusa' &&
                      item.data_preparazione != null &&
                      item.data_spedizione === null
                    ? 'indigo'
                    : 'teal darken-4'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.des == "Aperta" && item.data_produzione === null ? "mdi-calendar-clock" : item.des == "Aperta" && item.data_produzione != null ? "mdi-factory" : item.des == "Chiusa" && item.data_preparazione === null ? "mdi-package-up" : item.des == "Chiusa" && item.data_preparazione != null && item.data_spedizione === null ? "mdi-cart" : "mdi-truck-delivery")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.des == "Aperta" && item.data_produzione === null ? "Programmato" : item.des == "Aperta" && item.data_produzione != null ? "In Produzione" : item.des == "Chiusa" && item.data_preparazione === null ? "Completato" : item.des == "Chiusa" && item.data_preparazione != null && item.data_spedizione === null ? "Ordinato" : "Spedito")+" ")])])]}},{key:"item.selezionato",fn:function(ref){
                    var item = ref.item;
return [_c('span',[(item.des === 'Chiusa' && item.data_preparazione === null)?_c('v-simple-checkbox',{attrs:{"color":"success"},model:{value:(item.selezionato),callback:function ($$v) {_vm.$set(item, "selezionato", $$v)},expression:"item.selezionato"}}):_vm._e()],1),_c('span',[(item.des === 'Chiusa' && item.data_preparazione != null)?_c('v-simple-checkbox',{attrs:{"color":"success"},model:{value:(item.selezionato),callback:function ($$v) {_vm.$set(item, "selezionato", $$v)},expression:"item.selezionato"}}):_vm._e()],1)]}},{key:"item.des_articolo1",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(((item.cod_articolo1) + " - " + (item.des_articolo1)))+" ")]}},{key:"item.des_articolo2",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(((item.cod_articolo2) + " - " + (item.des_articolo2)))+" ")]}},{key:"item.data_produzione",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_produzione && item.data_produzione.length > 0 ? ((item.data_produzione.substring( 8, 10 )) + "/" + (item.data_produzione.substring( 5, 7 )) + "/" + (item.data_produzione.substring(0, 4))) : "N/A")+" ")]}},{key:"item.data_preparazione",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_preparazione && item.data_preparazione.length > 0 ? ((item.data_preparazione.substring( 8, 10 )) + "/" + (item.data_preparazione.substring( 5, 7 )) + "/" + (item.data_preparazione.substring(0, 4))) : "N/A")+" ")]}},{key:"item.data_spedizione",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_spedizione && item.data_spedizione.length > 0 ? ((item.data_spedizione.substring( 8, 10 )) + "/" + (item.data_spedizione.substring( 5, 7 )) + "/" + (item.data_spedizione.substring(0, 4))) : "N/A")+" ")]}}],null,true)})],1)],_c('v-dialog',{model:{value:(_vm.specifica_ordine),callback:function ($$v) {_vm.specifica_ordine=$$v},expression:"specifica_ordine"}},[_c('ListaOrdini',{attrs:{"item_selezionati":_vm.elementi.filter(
          function (e) { return e.selezionato === true && e.data_preparazione === null; }
        ),"tipo":'ordine',"titolo":'Riepilogo Ordine'},on:{"click_chiudi":_vm.chiudiDialogOrdini,"salva_ordine":_vm.salvaOrdine}})],1),_c('v-dialog',{model:{value:(_vm.specifica_spedizione),callback:function ($$v) {_vm.specifica_spedizione=$$v},expression:"specifica_spedizione"}},[_c('ListaOrdini',{attrs:{"item_selezionati":_vm.elementi.filter(
          function (e) { return e.selezionato === true && e.data_preparazione != null; }
        ),"tipo":'spedizione',"titolo":'Riepilogo Spedizione'},on:{"click_chiudi":_vm.chiudiDialogSpedizione,"salva_spedizione":_vm.salvaSpedizione}})],1),_c('SnackbarQV',{ref:"SnackbarQV"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }