<template>
  <div class="mainDash">
    <v-container id="app">
      <div style="position: absolute; top: 10px; right: 20px">
        v.{{ this.$store.state.versione }}
      </div>
      <svg id="icons">
        <symbol id="richieste" viewBox="0 0 93.646 93.646">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M13,3.5L18.5,9H13V3.5M12,11A3,3 0 0,1 15,14C15,15.88 12.75,16.06 12.75,17.75H11.25C11.25,15.31 13.5,15.5 13.5,14A1.5,1.5 0 0,0 12,12.5A1.5,1.5 0 0,0 10.5,14H9A3,3 0 0,1 12,11M11.25,18.5H12.75V20H11.25V18.5Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="regole" viewBox="0 0 170 199">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M14 2H7A2 2 0 0 0 5 4V18A2 2 0 0 0 7 20H10V23L12 22L14 23V20H17A2 2 0 0 0 19 18V7M14 15V17L12 16L10 17V15L8 14L10 13V11L12 12L14 11V13L16 14M13 8V3.5L17.5 8Z"
            />
          </svg>
        </symbol>
        <symbol id="interventi" viewBox="0 0 170 199">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M19,19H5V8H19M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M16.53,11.06L15.47,10L10.59,14.88L8.47,12.76L7.41,13.82L10.59,17L16.53,11.06Z"
            />
          </svg>
        </symbol>
        <symbol id="attivita" viewBox="0 0 32 32">
          <g>
            <svg
              class="shadow"
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M16.82,15.19L12.71,11.08C13.12,10.04 12.89,8.82 12.03,7.97C11.13,7.06 9.78,6.88 8.69,7.38L10.63,9.32L9.28,10.68L7.29,8.73C6.75,9.82 7,11.17 7.88,12.08C8.74,12.94 9.96,13.16 11,12.76L15.11,16.86C15.29,17.05 15.56,17.05 15.74,16.86L16.78,15.83C17,15.65 17,15.33 16.82,15.19Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="manutenzionemacchinari" viewBox="0 0 32 32">
          <g>
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M18.41,4L16,6.41V6.59L18.41,9H22V11H17.59L16,9.41V12H15A2,2 0 0,1 13,10V7.5H9.86C9.77,7.87 9.62,8.22 9.42,8.55L15.18,19H20A2,2 0 0,1 22,21V22H2V21A2,2 0 0,1 4,19H10.61L5.92,10.5C4.12,10.47 2.56,9.24 2.11,7.5C1.56,5.36 2.85,3.18 5,2.63C7.13,2.08 9.31,3.36 9.86,5.5H13V3A2,2 0 0,1 15,1H16V3.59L17.59,2H22V4H18.41M6,4.5A2,2 0 0,0 4,6.5A2,2 0 0,0 6,8.5A2,2 0 0,0 8,6.5A2,2 0 0,0 6,4.5Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="gestioneluoghiintervento" viewBox="0 0 32 32">
          <g>
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="anagrafiche" viewBox="0 0 408 408">
          <g>
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z"
              />
            </svg>
          </g>
        </symbol>
        <symbol id="manutenzionerecursiva" viewBox="0 0 408 408">
          <g>
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M18,11V12.5C21.19,12.5 23.09,16.05 21.33,18.71L20.24,17.62C21.06,15.96 19.85,14 18,14V15.5L15.75,13.25L18,11M18,22V20.5C14.81,20.5 12.91,16.95 14.67,14.29L15.76,15.38C14.94,17.04 16.15,19 18,19V17.5L20.25,19.75L18,22M19,3H18V1H16V3H8V1H6V3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14C13.36,20.45 12.86,19.77 12.5,19H5V8H19V10.59C19.71,10.7 20.39,10.94 21,11.31V5A2,2 0 0,0 19,3Z"
              />
            </svg>
          </g>
        </symbol>

        Type a message
      </svg>
      <Radial_Menu
        ref="radialMenu"
        @clicked="menuClicked"
        @touchend="menuClicked"
        :menu-items="menuItems"
        :size="altezza - 55"
      ></Radial_Menu>
      <v-snackbar
        content-class="snackbar"
        :color="snackbar_background"
        right
        v-model="snackbar"
        :timeout="3000"
      >
        <v-icon :size="64">{{ snackbar_icon }}</v-icon>
        <h2 class="ml-16">{{ snackbar_text }}</h2>

        <v-btn
          @click="snackbar = false"
          fab
          dark
          text
          small
          style="
            margin-top: -6rem;
            float: right !important;
            margin-right: -1rem !important;
          "
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// import image from '../src/img/Gestione.png'
import RadialMenu from "./ManutenzioneDashComp/ManutenzioneRadialMenu.vue";

export default {
  name: "app",
  data: function () {
    return {
      // Variabili d'appoggio
      altezza: 0,

      // Modal
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_text: "",

      // Array
      menuItems: [],
    };
  },
  computed: {
    id_profilo() {
      return localStorage.id_profilo;
    },
  },
  methods: {
    isModuloAutorizzato(id_modulo_menu) {
      let menu_presente = false;
      this.$store.state.menu_sx.forEach((elem) => {
        if (elem.id_modulo_menu === id_modulo_menu) {
          menu_presente = true;
        }
      });

      if (menu_presente === true) {
        return true;
      } else {
        return false;
      }
    },
    goToManutenzione() {
      this.$router.push("/home/manutenzione/main");
    },
    menuClicked: function (menuItem) {
      this.$router.push(menuItem.path);
      // if (menuItem.title === "Richieste") {
      //   this.$router.push("/home/manutenzione/richieste");
      // }
      // if (menuItem.title === "Gestione intervento") {
      //   this.$router.push("/home/manutenzione/interventi");
      // }
      // if (menuItem.title === "Regole") {
      //   this.$router.push("/home/manutenzione/regole");
      // }
      // if (menuItem.title === "Attività") {
      //   this.$router.push("/home/manutenzione/attivita");
      // }
      // if (menuItem.title === "Gestione luoghi d'intervento/ fornitori") {
      //   this.$router.push("/home/manutenzione/gestionesedifornitori");
      // }
      // if (menuItem.title === "Anagrafiche") {
      //   this.$router.push("/home/manutenzione/anagrafiche");
      // }
      // if (menuItem.title === "Manutenzione recursiva") {
      //   this.$router.push("/home/manutenzione/manutenzionerecursiva");
      // }
      // if (menuItem.title === "Gestione Macchinari") {
      //   this.$router.push("/home/manutenzione/gestionemacchinari");
      // }
    },
    openMenu: function () {
      this.$refs.radialMenu.open();
    },
    closeMenu: function () {
      this.$refs.radialMenu.close();
    },
  },
  created() {
    // Popolo il radial menu.
    let menuArr = JSON.parse(localStorage.menu);

    for (let index = 0; index < menuArr.length; index++) {
      if (menuArr[index].text != "Manutenzione") {
        const element = menuArr[index];
        let strval = "";
        const newEl = {};
        
        newEl.id = element.des;

        strval = element.text.toLowerCase();
        newEl.title = strval.charAt(0).toUpperCase() + strval.slice(1);
        newEl.title.charAt(0).toUpperCase();
        newEl.icon = `#${element.des}`.toLowerCase().replaceAll(" ","");

        newEl.path = element.path;
        this.menuItems.push(newEl);
      }
    }

    // if (localStorage.id_profilo != 3 && localStorage.id_profilo != 4) {
    //   this.menuItems = [
    //     {
    //       id: "Richieste",
    //       title: "Richieste",
    //       icon: "#richieste",
    //     },
    //     {
    //       id: "Interventi",
    //       title: "Gestione Intervento",
    //       icon: "#interventi",
    //     },
    //     {
    //       id: "Regole",
    //       title: "Regole",
    //       icon: "#regole",
    //     },
    //     {
    //       id: "Attivita",
    //       title: "Attività",
    //       icon: "#attivita",
    //     },
    //     // {
    //     //   id: "ManutenzioneMacchinari",
    //     //   title: "Gestione Macchinari",
    //     //   icon: "#manutenzionemacchinari",
    //     // },
    //     {
    //       id: "Anagrafiche",
    //       title: "Anagrafiche",
    //       icon: "#anagrafiche",
    //     },
    //     {
    //       id: "GestioneSediFornitori",
    //       title: "Gestione Luoghi d'Intervento/ Fornitori",
    //       icon: "#gestionesedifornitori",
    //     },
    //     {
    //       id: "ManutenzioneRecursiva",
    //       title: "Manutenzione Recursiva",
    //       icon: "#manutenzionerecursiva",
    //     },
    //   ];
    //   // } else {
    //   //   this.menuItems = [
    //   //     {
    //   //       id: "Interventi",
    //   //       title: "Gestione Intervento",
    //   //       icon: "#interventi",
    //   //     },
    //   //     {
    //   //       id: "Attivita",
    //   //       title: "Attività",
    //   //       icon: "#attivita",
    //   //     },
    //   //     {
    //   //       id: "Anagrafiche",
    //   //       title: "Anagrafiche",
    //   //       icon: "#anagrafiche",
    //   //     },
    //   //     {
    //   //       id: "GestioneSediFornitori",
    //   //       title: "Gestione Luoghi d'Intervento/ Fornitori",
    //   //       icon: "#gestionesedifornitori",
    //   //     },
    //   //   ];
    // }
  },
  mounted() {
    let that = this;

    this.altezza = window.innerHeight;

    window.addEventListener("resize", function () {
      that.altezza = window.innerHeight;
    });
  },
  components: {
    Radial_Menu: RadialMenu,
  },
  watch: {},
};
</script>

<style scoped>
.allegati {
  width: 1rem !important;
  height: 1rem !important;
}

.icons {
  width: 15rem !important;
  height: 5rem !important;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

#icons {
  display: none;
  size: 16px;
}

#app {
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: white;
}

div.info {
  margin: 15px 0;
}

div.info * {
  color: white;
  text-shadow: 1px 1px 0 #000000d0;
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.mainDash {
  width: 100% !important;
  background-image: url("../../assets/Ind.jpg");

  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100% !important; /* You must set a specified height */
  background-position: cover; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  overflow: hidden !important; /* Resize the background image to cover the entire container */
}
svg #shadow {
  transition: all 0.65s;
  opacity: 1;
}
svg:hover #shadow {
  opacity: 0;
}
symbol:hover {
  color: aliceblue;
  box-shadow: 1000px;
}
</style>
