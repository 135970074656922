var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_anagrafiche,"items":_vm.items_anagrafiche},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"white--text rounded-t",attrs:{"primary-title":"","color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v("Anagrafiche")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.clickAdd}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Nuova Anagrafica")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){_vm.filtro_visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Visualizza Filtri")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.resetFiltro}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-filter-off")])],1)]}}])},[_c('span',[_vm._v("Resetta Filtro")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),_c('v-card-text',[(_vm.filtro_visible)?_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-autocomplete',{staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","items":_vm.items_anagrafiche,"item-text":"ragione_sociale","item-value":"ragione_sociale","label":"Ragione sociale"},on:{"change":_vm.filtra},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.ragione_sociale_search = '';
                          _vm.filtra();}}},[_vm._v(_vm._s(_vm.ragione_sociale_search == "" ? "mdi-filter" : "mdi-filter-off"))])]},proxy:true}],null,false,1538637319),model:{value:(_vm.ragione_sociale_search),callback:function ($$v) {_vm.ragione_sociale_search=$$v},expression:"ragione_sociale_search"}})],1)],1):_vm._e()],1)]},proxy:true},{key:"item.dettagli",fn:function(ref){
                          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.clickDettagli(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Dettaglio Anagrafica")])])]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dettagli_anagrafica_visible),callback:function ($$v) {_vm.dettagli_anagrafica_visible=$$v},expression:"dettagli_anagrafica_visible"}},[_c('DettagliAnagrafica',{attrs:{"abilita_codice_fornitore":_vm.abilita_codice_fornitore,"anagrafica_sel":_vm.anagrafica_sel,"check_codice_fiscale":_vm.check_codice_fiscale,"check_p_iva":_vm.check_p_iva,"check_p_iva_mini":_vm.check_p_iva_mini,"concessionari_visible":_vm.concessionari_visible,"visible":_vm.dettagli_anagrafica_visible},on:{"aggiorna_anagrafica":_vm.aggiornaAnagrafica,"AggiornaAnagraficaFinal":_vm.getAnagraficaDettaglio,"chiudiTabAnagrafica":_vm.chiudiAnagrafica}})],1),_c('v-overlay',{attrs:{"value":_vm.caricamento_dati}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }