<template>
  <div id="consuntivo_commesse">
    <v-data-table
      :headers="headers_consuntivo"
      :items="items_visualizzati"
      class="elevation-1"
    >
      <!-- Titolo tabella -->
      <template v-slot:top>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Consuntivo Commesse
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- Botone di aggiunta commesse multiple -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="flag_multi === true ? 'primary' : ''"
                small
                @click="switchHeaders"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :color="flag_multi === true ? 'white' : 'primary'">
                  mdi-plus-box-multiple
                </v-icon>
              </v-btn>
            </template>
            <span>Aggiungi Multicommessa</span>
          </v-tooltip>

          <!-- Bottone di salvataggio commesse multiple -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-5"
                @click="salvaMulti"
                small
                v-bind="attrs"
                v-show="flag_multi === true"
                v-on="on"
              >
                <v-icon color="success"> mdi-content-save-all </v-icon>
              </v-btn>
            </template>
            <span>Salva Multicommessa</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <!-- Bottone dei filtri -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="mr-5"
                @click="filtri_visible = !filtri_visible"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-filter</v-icon></v-btn
              >
            </template>
            <span>Mostra / Nascondi Filtri</span>
          </v-tooltip>

          <!-- Bottone di reset dei filtri -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small @click="ripristina" v-bind="attrs" v-on="on"
                ><v-icon color="primary">mdi-filter-off</v-icon>
              </v-btn>
            </template>
            <span>Ripristina</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-btn @click="clickChiudi" small>
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Filtri tabella -->
        <div class="mt-3 mb-1" v-if="filtri_visible === true">
          <v-autocomplete
            background-color="#ffffff"
            @change="filtra"
            class="pa-1 mr-5"
            dense
            hide-details
            label="Cod. Articolo"
            style="display: inline-block; max-width: 350px"
            :items="items_totali"
            item-text="cod_articolo"
            item-value="cod_articolo"
            v-model="cod_articolo_search"
          >
            <template v-slot:append>
              <v-icon class="ml-2" @click="filtra" color="primary"
                >mdi-magnify</v-icon
              >
            </template>
          </v-autocomplete>
          <v-autocomplete
            background-color="#ffffff"
            @change="filtra"
            class="mr-5"
            dense
            hide-details
            :items="macchine_lista"
            item-text="cod_macchina"
            item-value="cod_macchina"
            label="Cod. Macchina"
            style="display: inline-block; max-width: 350px"
            v-model="cod_macchina_search"
          >
            <template v-slot:append>
              <v-icon class="ml-2" @click="filtra" color="primary"
                >mdi-magnify</v-icon
              >
            </template>
          </v-autocomplete>
          <v-autocomplete
            background-color="#ffffff"
            @change="filtra"
            class="pa-1 mr-5"
            dense
            hide-details="auto"
            item-text="text"
            item-value="id"
            :items="reparti_lista.filter((elem) => elem != null)"
            label="Reparto"
            style="display: inline-block; max-width: 350px"
            v-model="reparto_search"
            ><template v-slot:append>
              <v-icon class="ml-2" @click="filtra" color="primary"
                >mdi-magnify</v-icon
              >
            </template>
          </v-autocomplete>
        </div>
      </template>

      <!-- Bottone di aggiunta singola fase al carrello -->
      <template v-slot:[`item.aggiungi`]="{ item }">
        <v-btn @click="clickConsuntivoRow(item)" color="primary" dark fab small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <!-- Bottone Stampa -->
      <template v-slot:[`item.stampa`]="{ item }">
        <v-btn @click="clickStampa(item)" color="primary" dark fab small>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>

      <!-- Colonna per inserimento Q.tà base -->
      <template v-slot:[`item.qta_base`]="{ item }">
        <v-text-field type="number" v-model="item.qta_base"> </v-text-field>
      </template>
    </v-data-table>
    <v-dialog persistent v-model="conferma_dialog" width="unset">
      <v-card>
        <v-toolbar class="white--text" color="indigo" flat>
          <v-toolbar-title>Conferma Attivazione</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          Vuoi gestire la commessa n.
          <b>{{ commessa_sel == null ? "" : commessa_sel.num_commessa }}</b>
          dell'articolo
          <b>{{ commessa_sel == null ? "" : commessa_sel.cod_articolo }}</b
          >?
        </v-card-text>
        <v-divider></v-divider>
        <v-btn
          class="mx-2 my-2"
          @click="
            (conferma_dialog = !conferma_dialog),
              (commessa_sel = Object.assign({}))
          "
          small
        >
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
        <v-btn class="mx-2 my-2" @click="addToCarrello(commessa_sel)" small>
          <v-icon color="success">mdi-check</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{
        snackbar_icon
      }}</v-icon>
      <h2 :style="{ color: snackbar_text_color + '!important' }" class="ml-16">
        {{ snackbar_text }}
      </h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "ConsuntivoCommesse",
  props: {
    cod_macchina_sel: { type: String, default: "" },
    items_totali: { type: Array, default: () => [] },
    macchine_lista: { type: Array, default: () => [] },
    reparti_lista: { type: Array, default: () => [] },
    reparto_sel: { type: String, default: "" },
    visibile: { type: Boolean, default: false },
  },
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo_search: "",
      cod_macchina_search: "",
      commessa_sel: {},
      flag_multi: false,
      reparto_search: null,

      // Modal
      conferma_dialog: false,
      evasione_commessa: false,
      filtri_visible: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",

      // Array
      headers_consuntivo: [
        { text: "Aggiungi", sortable: false, value: "aggiungi" },
        { text: "Stampa", sortable: false, value: "stampa" },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Cod. Commessa",
          sortable: false,
          value: "cod_commessa",
        },
        {
          text: "Work Order",
          sortable: false,
          value: "cod_fase",
        },
        {
          text: "Cod. Macchina",
          sortable: false,
          value: "cod_macchina",
        },
        {
          text: "Descrizione",
          sortable: false,
          value: "des",
        },
        {
          text: "Qtà Totale",
          sortable: false,
          value: "quantita",
        },
        {
          text: "Qtà Prodotta",
          sortable: false,
          value: "pezzi_registrati",
        },
      ],
      items_visualizzati: [],
    };
  },
  methods: {
    addToCarrello(arg) {
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getworkordersutente",
          token: localStorage.getItem("user_token"),
          id_commessa_dettaglio: arg.id_commessa_dettaglio,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          // Compongo un messaggio con il contenuto del row cliccato.
          let messaggio = {
            detail: {
              commessa_sel: res.data.workorders[0],
            },
            src: null,
          };

          // Inserisco il messaggio in un evento custom.
          let evento = new CustomEvent("clicked_row", messaggio);

          /* Consegno alla mia destinazione l'evento con il
           * contenuto del row che ho cliccato. */
          document.getElementById("listener_produzione").dispatchEvent(evento);

          /* Segnalo al padre che è stato scelta una commessa,
           * così lui può chiudermi se vuole. */
          this.$emit("inviato_elemento");
        }
      });
      // Chiudo il dialog di conferma
      this.conferma_dialog = !this.conferma_dialog;
    },
    aggiungiInputQuantita() {
      /* Se non c'è già, aggiungo l'header della qta_base
       * in ultima posizione nella testata della tabella. */
      if (
        this.headers_consuntivo[this.headers_consuntivo.length - 1].text !=
        "Q.tà Base"
      ) {
        this.headers_consuntivo.push({
          text: "Q.tà Base",
          sortable: false,
          value: "qta_base",
          width: "150px",
        });
      }

      // Se c'è, elimino l'header dell'aggiunta delle fasi singole.
      if (this.headers_consuntivo[0].text === "Aggiungi") {
        this.headers_consuntivo.splice(0, 1);
      }

      // Aggiungo il campo della qta_base a ogni oggetto attualmente visualizzato.
      this.items_visualizzati.forEach((elem) => {
        elem.qta_base = 0;
      });
    },
    clickChiudi() {
      this.$emit("click_chiudi");
    },
    clickConsuntivoRow(item) {
      this.conferma_dialog = true;
      this.commessa_sel = Object.assign({}, item);
    },
    clickStampa(item) {
      console.log(JSON.stringify(item));
    },
    eliminaInputQuantita() {
      if (
        this.headers_consuntivo[this.headers_consuntivo.length - 1].text ===
        "Q.tà Base"
      )
        this.headers_consuntivo.pop();

      // Se non c'è già, aggiungo l'header dell'aggiunta delle fasi singole.
      if (this.headers_consuntivo[0].text != "Aggiungi") {
        this.headers_consuntivo.splice(0, 0, {
          text: "Aggiungi",
          sortable: false,
          value: "aggiungi",
        });
      }

      // Elimino la qta_base dalle fasi, che tanto non mi serve.
      this.items_visualizzati.forEach((elem) => {
        if (elem.qta_base != undefined) {
          delete elem.qta_base;
        }
      });
    },
    filtra() {
      console.log("entrato filtra");

      // Dichiaro un array temporaneo per i filtraggi intermedi
      let items_consuntivo_tmp = [];

      // Assegno all'array temporaneo gli oggetti attualmente visualizzati
      this.items_totali.forEach((elem) => {
        items_consuntivo_tmp.push(Object.assign({}, elem));
      });

      // Filtro il codice articolo
      if (this.cod_articolo_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_consuntivo_tmp.forEach((elem) => {
          if (elem.cod_articolo == this.cod_articolo_search) {
            res.push(Object.assign({}, elem));
          }
        });

        // Modello la risposta provvisoria su "res".
        items_consuntivo_tmp = Array.from(res);
      }

      // Filtro il codice macchina
      if (this.cod_macchina_search != null && this.cod_macchina_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_consuntivo_tmp.forEach((elem) => {
          if (elem.cod_macchina == this.cod_macchina_search) {
            res.push(Object.assign({}, elem));
          }
        });

        // Modello la risposta provvisoria su "res".
        items_consuntivo_tmp = Array.from(res);
      }

      // Filtro il reparto
      if (this.reparto_search != null && this.reparto_search != "") {
        // Faccio un array temporaneo
        let res = [];

        /* Filtro gli elementi visualizzati dopo i passaggi precedenti
         * e li inserisco in "res". */
        items_consuntivo_tmp.forEach((elem) => {
          if (elem.reparto == this.reparto_search) {
            res.push(Object.assign({}, elem));
          }
        });

        // Modello la risposta provvisoria su "res".
        items_consuntivo_tmp = Array.from(res);
      }

      this.items_visualizzati = [];
      this.items_visualizzati = Array.from(items_consuntivo_tmp);

      /* Se il flag_multi è true ed è appena finita un'iterazione di filtraggio,
       * potrebbe essere stata inserita una macchina non multicommessa (mentre al
       * filtraggio precedente ce n'era una abilitata per le multicommesse);
       * quindi controllo di nuovo che la macchina attuale sia abilitata . */
      if (this.flag_multi === true) {
        let check_multicommessa = false;

        this.macchine_lista.forEach((elem) => {
          if (elem.cod_macchina === this.cod_macchina_search) {
            check_multicommessa = elem.multi_commessa === 1 ? true : false;
          }
        });

        if (check_multicommessa === false) {
          /* Se la macchina non è multicommessa, cambio gli headers
           * e poi stampo una snackbar. */
          this.switchHeaders();

          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "La macchina selezionata non risulta essere abilitata per la registrazione multicommessa.";
          this.snackbar_text_color = "white";
          return;
        }

        /* Se il flag_multi è true aggiungo la qta_base agli
         * elementi visualizzati perché sto facendo una multicommessa. */
        this.items_visualizzati.forEach((elem) => {
          elem.qta_base = 0;
        });
      }
    },
    ripristina() {
      // Resetto le variabili di filtro.
      (this.cod_macchina_search = ""),
        (this.cod_articolo_search = ""),
        (this.reparto_search = "");

      this.items_visualizzati = [];
      this.items_totali.forEach((elem) => {
        this.items_visualizzati.push(Object.assign({}, elem));
      });
    },
    salvaMulti() {
      /* Controllo che sia renderizzata a schermo almeno una riga,
      altrimenti non c'è un accipicchia di nulla da salvare.*/
      if (this.items_visualizzati.length === 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Non c'è nessuna fase da prendere in gestione.";
        this.snackbar_text_color = "white";
        return;
      }

      // Controllo che ci sia almeno una fase con qta_base superiore a 0.
      let check_scelte = 0;
      this.items_visualizzati.forEach((f) => {
        if (f.qta_base > 0) {
          check_scelte += 1;
        }
      });
      if (check_scelte <= 0) {
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "È necessario impostare con un valore superiore a 0 la Q.tà Base delle fasi da gestire.";
        this.snackbar_text_color = "white";
        return;
      }

      /* Definisco il cod_macchina in base al filtro
       * (tanto tutte le fasi a questo punto hanno la stessa macchina) e
       * popolo l'array da inserire nella request.*/
      const id_macchina_sel = this.items_visualizzati[0].id_macchina;
      const fasi_sel = new Array();
      this.items_visualizzati.forEach((f) => {
        if (f.qta_base > 0) {
          fasi_sel.push({
            id_commessa_dettaglio: f.id_commessa_dettaglio,
            qta_base: f.qta_base,
          });
        }
      });

      const request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setregistrazionemultipla",
          token: localStorage.getItem("user_token"),
          id_macchina: id_macchina_sel,
          fasi: fasi_sel,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        if (res.status === 200) {
          this.$emit("inviato_elemento");
        }
      });
    },
    switchHeaders() {
      if (this.flag_multi === true) {
        this.flag_multi = false;
        this.eliminaInputQuantita();
      } else {
        /* Qui sto passando alla modalità multicommessa.
         * Per non generare criticità ho bisogno che in modalità
         * multicommessa sia sempre selezionato un filtro sulla macchina.
         * Se non è così, stampo un avviso che dice di valorizzarlo e mi fermo. */
        if (this.cod_macchina_search === "") {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "È necessario selezionare un Cod. Macchina con cui filtrare le fasi prima di eseguire la selezione multipla.";
          this.snackbar_text_color = "white";
          return;
        }

        /* Controllo che la macchina selezionata sia effettivamente
         * una macchina per multicommessa, altrimenti mi blocco. */
        let check_multicommessa = false;

        this.macchine_lista.forEach((elem) => {
          if (elem.cod_macchina === this.cod_macchina_search) {
            check_multicommessa = elem.multi_commessa === 1 ? true : false;
          }
        });

        if (check_multicommessa === false) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "La macchina selezionata non risulta essere abilitata per la registrazione multicommessa.";
          this.snackbar_text_color = "white";
          return;
        }

        this.flag_multi = true;
        this.aggiungiInputQuantita();
      }
    },
  },
  watch: {
    items_totali: {
      immediate: true,
      handler() {
        console.log("entrato handler watch su items_totali");
        this.items_totali.forEach((elem) => {
          let elem_tmp = Object.assign({}, elem);
          elem_tmp.qta_base = 0;
          this.items_visualizzati.push(Object.assign({}, elem_tmp));
        });
      },
    },
    visibile: {
      immediate: true,
      handler() {
        if (this.visibile === true) {
          /* Controllo che il flag_multi sia false e che non ci sia la colonna
           * per inserire la quantità base dell'avanzamento produzione per
           * fasi specifiche, in modo da avere il cruscotto resettato. */
          if (this.flag_multi === true) this.flag_multi = false;
          this.eliminaInputQuantita();

          // Eseguo il filtraggio per cod. macchina e reparto
          if (this.cod_macchina_sel != null && this.cod_macchina_sel != "") {
            this.cod_macchina_search = this.cod_macchina_sel;
          }
          if (this.reparto_sel != null && this.reparto_sel != "") {
            this.reparto_search = this.reparto_sel;
          }
          this.filtra();
        }
      },
    },
  },
  mounted() {},
};
</script>
<style scoped>
th,
td {
  border-right: 1px solid grey;
}
</style>